import React from "react";
import PropTypes from "prop-types";
import {
  Modal,
  ModalBody,
  Button,
  Row,
  Col,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";
import bn from "utils/bemnames";
import { FormattedMessage } from "react-intl";
import { Formik } from "formik";
import * as Yup from "yup";
import { CloseIcon, DeleteIcon } from "components/CustomIcons";
import { get, map } from "lodash";
import TextInput from "components/TextInput";
import useInfiniteScroll from "utils/useInfiniteScroll";
import Spinner from "components/Spinner";
const bem = bn.create("save-search-modal");
function SaveSearchForm(props) {
  const { onSubmit, initialValues, intl, is1099Report } = props;
  return (
    <Formik
      initialValues={{
        title: get(initialValues, "title", ""),
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string().required(
          intl.formatMessage({ id: "validate > name is required" })
        ),
      })}
      onSubmit={onSubmit}
    >
      {(formProps) => {
        const { values, touched, errors } = formProps;
        return (
          <Form onSubmit={formProps.handleSubmit}>
            <Row>
              <Col>
                <FormGroup>
                  <TextInput
                    label={
                      is1099Report ? (
                        <FormattedMessage id="save search > list name" />
                      ) : (
                        <FormattedMessage id="save search > name" />
                      )
                    }
                    required
                    type="text"
                    name="title"
                    placeholder={intl.formatMessage({
                      id: "save search > enter text",
                    })}
                    aria-multiline="true"
                    value={values.title}
                    onChange={formProps.handleChange}
                    onBlur={formProps.handleBlur}
                    error={touched.title && errors.title}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className={bem.e("buttons")}>
                  <Button
                    color="primary"
                    className={"btn-radius"}
                    type="submit"
                  >
                    <FormattedMessage id="process > button save" />
                  </Button>
                  <Button
                    color="primary"
                    outline
                    onClick={() => formProps.setFieldValue("title", "")}
                    className={"btn-radius"}
                  >
                    <FormattedMessage id="process > button clear" />
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
}
const SaveSearchModal = (props) => {
  const {
    isOpen,
    onToggle,
    onDelete,
    handleLoadMore,
    hasMoreItems,
    isLoading,
    onSelectItem,
    isSelectableItem,
  } = props;
  const savedSearchesItems = get(props, "savedSearches", []);
  useInfiniteScroll({
    useWindow: false,
    elementId: "saved-searches",
    onLoadMore: handleLoadMore,
    shouldLoadMore: hasMoreItems,
  });
  return (
    <Modal isOpen={isOpen} className={bem.b()} centered>
      <ModalHeader toggle={onToggle} className={bem.e("header")}>
        <FormattedMessage id="save search > title modal save search" />
        <span className={bem.e("close-button")} onClick={onToggle}>
          <CloseIcon />
        </span>
      </ModalHeader>
      <ModalBody className={bem.e("body")}>
        <SaveSearchForm {...props} />
        <div className={bem.e("saved-searches")}>
          <span className={bem.e("saved-title")}>
            <FormattedMessage id="save search > saved searches" />
          </span>
          <div className={bem.e("saved-searches-list")} id="saved-searches">
            {savedSearchesItems.length > 0
              ? map(savedSearchesItems, (item, index) => (
                  <span key={index} className={bem.e("search-item")}>
                    <span
                      className={bem.e("search-item-title")}
                      onClick={() => {
                        if (isSelectableItem && onSelectItem)
                          onSelectItem(item);
                      }}
                    >
                      {item.title}
                    </span>
                    <span
                      className={bem.e("delete-search")}
                      onClick={() => onDelete(item)}
                    >
                      <DeleteIcon />
                    </span>
                  </span>
                ))
              : null}
          </div>
        </div>
        <Spinner isLoading={isLoading} />
      </ModalBody>
    </Modal>
  );
};

SaveSearchModal.propTypes = {
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
  savedSearches: PropTypes.array,
  isLoading: PropTypes.bool,
  isSelectableItem: PropTypes.bool,
  onSelectItem: PropTypes.func,
  is1099Report: PropTypes.bool,
};

SaveSearchModal.defaultProps = {
  isOpen: false,
  onToggle: () => {},
  onSubmit: () => {},
  onDelete: () => {},
  onSelectItem: () => {},
  savedSearches: [],
  isLoading: false,
  isSelectableItem: false,
  is1099Report: false,
};

export default SaveSearchModal;
