import { connect } from "react-redux";
import View from "./view";
import { get } from "lodash";
const Enhanced = connect((state, props) => {
  return {
    dateFormatByServer: get(
      state,
      "servers.currentServer.data.date_format",
      "MM/DD/YYYY"
    ),
    ...props,
  };
}, {})(View);
export default Enhanced;
