import { FormattedMessage } from "react-intl";
import React from "react";
import { get, find, filter, delay } from "lodash";
import {
  labelToNameField,
  getFormFieldsWithConditionLogic,
} from "utils/helpers";
import history from "components/History";

const design = {
  process_step_index: 3,
  key: "design",
  title: <FormattedMessage id="process > title design" />,
  caption_color: "#6C08CE",
  description: null,
  step_roles: [13, 14],
  step_viewers_roles: [13, 14],
  step_editors_roles: [14],
  step_viewers_privileges: {
    or: ["add_design", "edit_all_digital_orders"],
  },
  step_editors_privileges: {
    or: ["add_design", "edit_all_digital_orders"],
  },
  is_check_hidden_tab: true,
  status: "initial",
  is_hidden: function(user, process, step) {
    const isOrderDetailsDraft =
      get(process, "data.fields.order_details_status.value") === "draft";
    if (!process.data.fields.for_marketing_channel || isOrderDetailsDraft)
      return false;
    const forMarketingChannel = get(
      process,
      "data.fields.for_marketing_channel.value"
    );
    const marketingChannel = find(
      get(process, "data.fields.marketing_channels.value", []),
      (channel) => channel.id === forMarketingChannel.id
    );
    if (!marketingChannel || !marketingChannel.production) return true;
    // If a channel requires production
    return (
      marketingChannel &&
      marketingChannel.production !== "Requires Production" &&
      marketingChannel.production !== "Production Needed"
    );
  },
  is_disabled: function(user, process, step) {
    const processKey = get(process, "data.fields.key.value", false);
    return !processKey;
  },
  is_approval_step: false,
  className: "design-container",
  form_class_name: "design-media-tab-container",
  fields: [
    {
      component: "design",
    },
    {
      field: "marketing_channels",
    },
    {
      field: "media_review_history",
    },
    {
      key: "save_button",
      // component: "button",
      // container: "bottom_buttons",
      text: <FormattedMessage id="process > button submit" />,
      props: { color: "blue" },
      is_disabled: function(user, process, step) {
        let isDisabled = false;
        let isMissingUpload = false;
        const forMarketingChannel = get(
          process,
          "data.fields.for_marketing_channel.value"
        );
        if (!forMarketingChannel) return true;
        const marketingChannel = find(
          get(process, "data.fields.marketing_channels.value", []),
          (channel) => channel.id === forMarketingChannel.id
        );
        // case: missing upload
        let newFields = getFormFieldsWithConditionLogic(marketingChannel);
        isMissingUpload = !!filter(
          newFields,
          (field) => field.key === "media_for_review_approval"
        ).find((field) => {
          const fieldName = labelToNameField(get(field, "label", ""));
          return get(marketingChannel, `${fieldName}.length`, 0) === 0;
        });
        // case: field rejected
        filter(
          newFields,
          (field) => field.key === "media_for_review_approval"
        ).forEach((field) => {
          const valueArr = get(field, "value_arr", []);
          const filesRejected = filter(
            valueArr,
            (itemValue) => itemValue.review_media_status === "rejected"
          );
          if (filesRejected.length > 0) {
            isDisabled = true;
          }
        });
        return isDisabled || isMissingUpload;
      },
      confirmation_dialog_props: {
        description: (
          <FormattedMessage id="process > description to submit these design" />
        ),
        confirmTitle: <FormattedMessage id="process > button yes" />,
        cancelTitle: <FormattedMessage id="process > button no" />,
      },
      success_dialog_props: {
        title: <FormattedMessage id="process > title submitted" />,
        state: "success",
      },
      class: null,
      on_click: {
        validate: true,
        save: true,
        go_to: function(_this, step, item, process, template, user) {
          if (!user.return_to_current_tasks) return;
          // set delay when after success message
          delay(() => {
            if (_this.props.setSidebarActive) {
              _this.props.setSidebarActive(`/processes/my_action_items`);
            }
            history.replace(`/processes/my_action_items`);
          }, 4000);
        },
      },
      fields: [
        {
          key: "design_status",
          // "value": "submitted"
        },
        {
          key: "next_users",
          value: (user, template, process, step, item) => {
            let nextUsers = get(process, "data.fields.next_users.value", []);
            if (
              get(process, "data.fields.review_media_status.value") !==
              "completed"
            ) {
              // Next users is digital sales. When digital producers submitted the media.
              const digitalAccountExecutive = get(
                process,
                "data.fields.role_digital_account_executive.value.id"
              );
              nextUsers = [digitalAccountExecutive];
            }
            return nextUsers;
          },
        },
        {
          key: "process_step",
          value: (user, template, process, step, item) => {
            if (
              get(process, "data.fields.review_media_status.value") ===
              "completed"
            ) {
              return "first_undone";
            }
            return "review_media";
          },
        },
        {
          key: "review_media_status",
          value: (user, template, process, step, item) => {
            if (
              get(process, "data.fields.design_status.value") !== "completed"
            ) {
              return "";
            }
            return get(process, "data.fields.review_media_status.value");
          },
        },
      ],
    },
  ],
};
export default design;
