import { get, pick } from "lodash";
export function getFileInstructions(process, intl) {
  const spotInfo = get(process, "_source.spot_info", []).find(
    (spot) => spot.key === process._id
  );
  const isSpecOrder = get(process, "_source.order_sold") === false;
  const spotsInfo = get(process, "_source.spot_info", []);
  const isBookend = get(spotInfo, "spot_type", "Standard") === "Book-ends";
  const draftScript =
    get(spotInfo, "script_type", "draft") === "final"
      ? get(spotInfo, "script", "")
      : get(spotInfo, "draft_script", "");
  const draftScriptB =
    get(spotInfo, "script_type", "draft") === "final"
      ? get(spotInfo, "script_part_b", "")
      : get(spotInfo, "draft_script_part_b", "");
  const assets = get(spotInfo, "files", []);
  const approveScriptStatus =
    get(spotInfo, "script_type", "") === "final"
      ? true
      : get(process, "_source.approve_script_status", "") === "approved" &&
        get(spotInfo, "script_requires_approval", false)
      ? true
      : !get(spotInfo, "script_requires_approval", false) &&
        get(process, "_source.write_script_status", "") === "completed"
      ? true
      : false;
  const approveRecordingStatus =
    get(process, "_source.approve_recording_status", "") === "approved" &&
    get(spotInfo, "recording_requires_approval", false)
      ? true
      : !get(spotInfo, "recording_requires_approval", false) &&
        get(process, "_source.record_script_status", "") === "completed"
      ? true
      : false;
  if (isSpecOrder) {
    return [
      {
        key: "creative_details",
        title: intl.formatMessage({ id: "process > creative details" }),
        data: {
          voice_preference: get(spotInfo, "voice_preference", ""),
          copy_points: get(spotInfo, "copy_points", ""),
          draft_script: draftScript,
          draft_script_part_b: draftScriptB,
        },
        isCompleted:
          get(spotInfo, "voice_preference", "") ||
          get(spotInfo, "copy_points", "") ||
          (isBookend
            ? (draftScript && draftScript !== "<p><br></p>") ||
              (draftScriptB && draftScriptB !== "<p><br></p>")
            : draftScript && draftScript !== "<p><br></p>"),
        isBookend,
      },
      {
        key: "assets",
        title: `${intl.formatMessage({ id: "process > assets" })} ${
          assets.length > 0 ? `(${assets.length})` : ""
        }`,
        data: assets,
        isCompleted: assets.length > 0,
      },
      {
        key: "approved_script",
        title: intl.formatMessage({ id: "process > approved script" }),
        data: approveScriptStatus
          ? {
              script: get(spotInfo, "script", ""),
              script_part_b: get(spotInfo, "script_part_b", ""),
            }
          : null,
        isCompleted: approveScriptStatus,
        isBookend,
      },
      {
        key: "approved_recoding",
        title: intl.formatMessage({ id: "process > approved recording" }),
        data: spotsInfo.map((spot) => {
          return pick(spot, [
            "key",
            "length1",
            "length1",
            "recording_audit",
            "recording_path",
            "recording_path_b",
            "recording_path_b_audit",
            "spot_type",
          ]);
        }),
        isCompleted: approveRecordingStatus,
      },
    ];
  }
  return [
    {
      key: "traffic_instructions",
      title: intl.formatMessage({ id: "process > field traffic instructions" }),
      data: get(process, "_source.traffic_instructions", ""),
      isCompleted: !!get(process, "_source.traffic_instructions", ""),
    },
    {
      key: "creative_details",
      title: intl.formatMessage({ id: "process > creative details" }),
      data: {
        voice_preference: get(spotInfo, "voice_preference", ""),
        copy_points: get(spotInfo, "copy_points", ""),
        draft_script: draftScript,
        draft_script_part_b: draftScriptB,
      },
      isCompleted:
        get(spotInfo, "voice_preference", "") ||
        get(spotInfo, "copy_points", "") ||
        (isBookend
          ? (draftScript && draftScript !== "<p><br></p>") ||
            (draftScriptB && draftScriptB !== "<p><br></p>")
          : draftScript && draftScript !== "<p><br></p>"),
      isBookend,
    },
    {
      key: "assets",
      title: `${intl.formatMessage({ id: "process > assets" })} ${
        assets.length > 0 ? `(${assets.length})` : ""
      }`,
      data: assets,
      isCompleted: assets.length > 0,
    },
    {
      key: "approved_script",
      title: intl.formatMessage({ id: "process > approved script" }),
      data: approveScriptStatus
        ? {
            script: get(spotInfo, "script", ""),
            script_part_b: get(spotInfo, "script_part_b", ""),
          }
        : null,
      isCompleted: approveScriptStatus,
      isBookend,
    },
    {
      key: "approved_recoding",
      title: intl.formatMessage({ id: "process > approved recording" }),
      data: spotsInfo.map((spot) => {
        return pick(spot, [
          "key",
          "length1",
          "length1",
          "recording_audit",
          "recording_path",
          "recording_path_b",
          "recording_path_b_audit",
          "spot_type",
        ]);
      }),
      isCompleted: approveRecordingStatus,
    },
  ];
}
