import React from "react";
import { get } from "lodash";
import ScriptReview from "./ScriptReview";
import SpotReview from "./SpotReview";
import Spinner from "components/Spinner";
import { uniq } from "lodash";
const getRecomputeFields = (step, item, process, template, user) => {
  const nextUsers = get(process, "data.fields.next_users.value", []).map(
    Number
  );
  let all_changed_users = [];
  // Script review
  if (step.key === "approve_script") {
    const producerAssignsVo = get(
      process,
      "data.fields.producer_assigns_vo.value",
      false
    );
    const writerScriptStatus = get(
      process,
      "data.fields.write_script_status.value",
      ""
    );
    if (writerScriptStatus === "rejected") {
      // After Requesting Change script review, I'd expect the writer to get a TASK email alerting them that a change is requested
      const writerUsers = get(
        process,
        "data.fields.role_writer.value.users",
        []
      ).map((u) => Number(u.id));
      all_changed_users = writerUsers;
    } else {
      if (producerAssignsVo) {
        // After approving script review, I'd expect the Producer to get a TASK email alerting them that the spot is ready to record
        const producerUsers = get(
          process,
          "data.fields.role_producer.value.users",
          []
        ).map((u) => Number(u.id));
        all_changed_users = producerUsers;
      } else {
        // After approving script review, I'd expect the Voice to get a TASK email alerting them that the spot is ready to record
        const voiceUsers = get(
          process,
          "data.fields.role_voice_talent.value.users",
          []
        ).map((u) => Number(u.id));
        all_changed_users = voiceUsers;
      }
    }
    return { all_changed_users: uniq([...all_changed_users, ...nextUsers]) };
  } else if (step.key === "approve_recording") {
    const approveRecordingStatus = get(
      process,
      "data.fields.approve_recording_status.value",
      ""
    );
    if (approveRecordingStatus === "rejected") {
      // After requesting change spot review, I'd expect the producer to get a TASK email alerting them that a change is requested
      const producerUsers = get(
        process,
        "data.fields.role_producer.value.users",
        []
      ).map((u) => Number(u.id));
      all_changed_users = producerUsers;
    } else {
      // After approving spot review, I'd expect the Continuity (or Dubber) to get a TASK email alerting them that it's ready for the next step
      const continuityStatus = get(
        process,
        "data.fields.continuity_status.value",
        ""
      );
      const dubberUsers = get(
        process,
        "data.fields.role_dubber.value.users",
        []
      ).map((u) => Number(u.id));
      const continuityUsers = get(
        process,
        "data.fields.role_continuity.value.users",
        []
      ).map((u) => Number(u.id));

      if (continuityStatus !== "completed") {
        all_changed_users = continuityUsers;
      } else {
        all_changed_users = dubberUsers;
      }
    }
    return { all_changed_users: uniq([...all_changed_users, ...nextUsers]) };
  }

  return { all_changed_users: uniq(nextUsers) };
};
function ProductionClientReview(props) {
  const {
    process,
    template,
    intl,
    extraReviewProcess,
    onGetReviewProcess,
    messages,
    formValueChanged,
    saveFormValues,
    valueChanged,
    dateFormatByServer,
  } = props;

  if (!template) {
    return <Spinner isLoading={process.loading} />;
  }
  const isRecordingFeedback =
    get(process, "data.fields.process_step.value", "") ===
      "approve_recording" ||
    get(process, "data.fields.approve_recording_status.value", false);
  if (isRecordingFeedback)
    return (
      <SpotReview
        process={process}
        template={template}
        intl={intl}
        extraReviewProcess={extraReviewProcess}
        onGetReviewProcess={onGetReviewProcess}
        messages={messages}
        formValueChanged={formValueChanged}
        saveFormValues={saveFormValues}
        dateFormatByServer={dateFormatByServer}
        getRecomputeFields={getRecomputeFields}
      />
    );
  return (
    <ScriptReview
      process={process}
      template={template}
      intl={intl}
      extraReviewProcess={extraReviewProcess}
      onGetReviewProcess={onGetReviewProcess}
      messages={messages}
      formValueChanged={formValueChanged}
      saveFormValues={saveFormValues}
      valueChanged={valueChanged}
      dateFormatByServer={dateFormatByServer}
      getRecomputeFields={getRecomputeFields}
    />
  );
}
export default ProductionClientReview;
