import React from "react";
import NotificationSystem from "react-notification-system";
import { NOTIFICATION_SYSTEM_STYLE } from "utils/constants";

class ToastManager extends React.Component {
  show({ title, message, level, onAdd, onRemove }) {
    return this.notificationSystem.addNotification({
      title,
      message,
      level,
      onAdd,
      onRemove
    });
  }

  hide(notif) {
    this.notificationSystem.removeNotification(notif);
  }

  render() {
    return (
      <NotificationSystem
        dismissible={false}
        ref={notificationSystem =>
          (this.notificationSystem = notificationSystem)
        }
        style={NOTIFICATION_SYSTEM_STYLE}
      />
    );
  }
}

export default ToastManager;
