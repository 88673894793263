import React from "react";
import ChannelManagerForm from "components/ChannelManagerForm";
import { get, omit } from "lodash";
import { SortableElement } from "react-sortable-hoc";
import classNames from "classnames";

const Item = (props) => {
  const {
    bem,
    channelName,
    setChannelName,
    onDelete,
    onToggleFieldsFormBuilderModal,
    currentFormField,
    currentOpenForm,
    item,
    setCurrentOpenForm,
    setCurrentChannelDelete,
    setIsOpenConfirmationModal,
    renderItem,
    onHandledChannelForm,
    isViewModeForms,
    setIsViewModeForms,
    isHiddenForEdit,
    setCurrentPreviewChannel,
    setIsOpenChannelPreviewModal,
    isOpenChannelPreviewModal,
    onCloneChannel,
    onSaveHiddenChannel,
    channelIcon,
    setChannelIcon,
    currentStation,
    setShouldBlockNavigation,
    shouldBlockNavigation,
    setIsOpenConfirmationActionsUnsavedModalNewChannel,
    setCurrentPublishChannel,
    onGetChannels,
    ...rest
  } = props;
  // const is_new = get(item, "_source.id", "").indexOf("_new_") === 0;
  const isOpenForm = currentOpenForm === get(item, "_source.id");
  const setIsOpenForm = (id) => {
    setCurrentOpenForm(currentOpenForm === id ? null : id);
  };
  const isView = get(isViewModeForms, `${get(item, "_source.id")}`, false);
  const setIsView = () => {
    setIsViewModeForms({
      [get(item, "_source.id")]: !get(
        isViewModeForms,
        `${get(item, "_source.id")}`,
        false
      ),
    });
  };
  const propsForm = {
    onHandledChannelForm: onHandledChannelForm,
    setChannelName: setChannelName,
    channel: get(item, "_source", {}),
    onClose: setIsOpenForm,
    onToggleFieldsFormBuilderModal: onToggleFieldsFormBuilderModal,
    onDelete: (deleted) => {
      setIsOpenConfirmationModal(true);
      setCurrentChannelDelete(deleted);
    },
    setIsOpenForm: setIsOpenForm,
    isView,
    setIsView,
    currentFormField,
    setCurrentPreviewChannel,
    setIsOpenChannelPreviewModal,
    isOpenChannelPreviewModal,
    onCloneChannel,
    onSaveHiddenChannel,
    channelIcon,
    currentStation,
    shouldBlockNavigation,
    setShouldBlockNavigation,
    setIsOpenConfirmationActionsUnsavedModalNewChannel,
    setCurrentPublishChannel,
    onGetChannels,
  };
  return (
    <div
      className={classNames(
        bem.e("channel-sort-item"),
        bem.e(`channel-sort-item-${rest.sortableIndex}`)
      )}
    >
      <div
        {...omit(rest, [
          "setIsOpenConfirmationUnsavedModal",
          "onUpdateOrderChannels",
          "setList",
          "channels",
          "workflows",
        ])}
      >
        {renderItem({
          channel: item,
          channelName,
          channelIcon,
          setChannelIcon,
        })}
      </div>
      {isOpenForm && (
        <div className={bem.e("channel-form")}>
          <ChannelManagerForm {...propsForm} />
        </div>
      )}
    </div>
  );
};
export default SortableElement(Item);
