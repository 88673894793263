import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "reactstrap";
import { MODE_EDIT } from "../../view";
import classnames from "classnames";
import CreativeDetails from "components/EditProfileForm/CreativeDetails";
import OutOfOffice from "components/EditProfileForm/OutOfOffice";
import PersonalDetails from "../PersonalDetails";

const ProfileView = (props) => {
  const {
    bem,
    user,
    isSpecialUser,
    setCurrentMode,
    intl,
    dateFormatByServer,
  } = props;

  return (
    <div className={bem.e("view-container")}>
      <PersonalDetails bem={bem} user={user} intl={intl} />
      {isSpecialUser && (
        <CreativeDetails isView={true} user={user} intl={intl} />
      )}
      <OutOfOffice
        intl={intl}
        user={user}
        isView={true}
        dateFormatByServer={dateFormatByServer}
      />
      <div className={bem.e("buttons")}>
        <Button
          color="blue"
          type="button"
          className={classnames("btn-radius", bem.e("button-edit"))}
          onClick={() => setCurrentMode(MODE_EDIT)}
        >
          <FormattedMessage id="my profile > button edit" />
        </Button>
      </div>
    </div>
  );
};

export default ProfileView;
