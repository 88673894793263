import React, { useRef } from "react";
import { Form, FormGroup, Button, Row } from "reactstrap";
import { FormattedMessage } from "react-intl";
import * as Yup from "yup";
import { Formik } from "formik";
import { get } from "lodash";
import cx from "classnames";
import { CheckedIcon, ErrorCrossIcon } from "components/CustomIcons";
import bn from "utils/bemnames";
import { createYupSchema } from "components/SystemForms/yupSchemaCreatorForForm";
import renderCustomFields, {
  getFormFields,
  getInitialValues,
} from "components/SystemForms/renderCustomFieldsForForm";
const bem = bn.create("jock-console-winner-modal");

function WinnerForm(props) {
  const {
    intl,
    onCancel,
    onSubmit,
    isEdit,
    onEdit,
    returnToSearch,
    selectedWinner,
    isStandardCash,
    keyword,
    systemForm,
    currentServer,
    isJockConsole,
  } = props;
  const formikRef = useRef();
  const handleOnSubmit = (values) => {
    if (isEdit) {
      onEdit(values, onCancel);
    } else {
      onSubmit(values, onCancel);
    }
  };

  const getValue = (key, defaultValue = "") => {
    if (isEdit) {
      return get(selectedWinner, `${key}`, defaultValue);
    }
    return defaultValue;
  };

  let lastName = "";
  let firstName = "";
  let phone = "";
  let email = "";

  // detect email
  if (keyword) {
    const emailReg = /\S+@\S+\.\S+/;
    const phoneReg = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
    if (emailReg.test(keyword)) {
      email = keyword;
    } else if (phoneReg.test(keyword)) {
      phone = keyword;
    } else {
      let aName = keyword.split(" ");
      if (aName.length > 1) {
        lastName = aName.pop();
      }
      firstName = aName.join(" ");
    }
  }
  const serverCountries = [
    currentServer.country,
    ...currentServer.neighboring_countries,
  ];
  const formFields = getFormFields(systemForm);
  const initialValues = getInitialValues(
    formFields,
    {
      first_name: getValue("first_name", firstName),
      last_name: getValue("last_name", lastName),
      phone: getValue("phone", phone),
      email: getValue("email", email),
      primary_contact: getValue("primary_contact"),
    },
    {
      countries: serverCountries,
    }
  );
  const yepSchema = formFields.reduce(
    (schema, config) => createYupSchema(schema, config, intl),
    {}
  );

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      ref={formikRef}
      validationSchema={Yup.object().shape({
        ...yepSchema,
      })}
      validateOnChange={true}
      validateOnBlur={false}
      onSubmit={handleOnSubmit}
    >
      {(formProps) => {
        return (
          <Form
            onSubmit={formProps.handleSubmit}
            noValidate
            className={bem.e("winner-form")}
          >
            <Row className={bem.e("content-form")}>
              {renderCustomFields(systemForm, {
                formProps,
                currentServer,
                isRenderForm: true,
                isFulfillment: false,
                isConsoleForm: true,
                isPreview: true,
              })}
            </Row>
            {isJockConsole && isEdit && (
              <div
                className={cx("eligibility-notice", {
                  eligible: get(selectedWinner, "eligible"),
                })}
              >
                <div className="eligibility-status d-flex align-items-center">
                  {get(selectedWinner, "eligible") ? (
                    <CheckedIcon />
                  ) : (
                    <ErrorCrossIcon danger />
                  )}
                  <FormattedMessage
                    id={`jock console > ${
                      get(selectedWinner, "eligible") ? "" : "in"
                    }eligible`}
                  />
                  :
                </div>
                <div className="eligibility-message d-flex align-items-center">
                  <FormattedMessage
                    id={`jock console > ${
                      get(selectedWinner, "eligible") ? "not " : ""
                    }listener won`}
                  />
                </div>
              </div>
            )}
            <div className={bem.e("form-buttons")}>
              <FormGroup>
                {isJockConsole && isEdit && !get(selectedWinner, "eligible") ? (
                  <Button
                    color="blue"
                    className="btn btn-radius"
                    onClick={returnToSearch}
                  >
                    <FormattedMessage id="jock console > button return search" />
                  </Button>
                ) : (
                  <Button color="blue" type="submit" className="btn btn-radius">
                    <FormattedMessage
                      id={
                        isStandardCash
                          ? "jock console > button save player"
                          : isEdit
                          ? "jock console > button save winner"
                          : "jock console > button submit"
                      }
                    />
                  </Button>
                )}
                <Button
                  color="blue"
                  outline
                  className="btn btn-radius"
                  onClick={() => {
                    onCancel();
                  }}
                >
                  <FormattedMessage id={`jock console > button cancel`} />
                </Button>
              </FormGroup>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
WinnerForm.defaultProps = {
  isJockConsole: true,
};
export default WinnerForm;
