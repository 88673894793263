import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { times } from "lodash";

import bn from "utils/bemnames";
const bem = bn.create("jock-console");
const numberOfBarsInGroup = 3;

export default function JockPreviewCard(props) {
  const { widget, onClick } = props;
  const { color, empty } = widget;

  return (
    <div
      className={classnames(bem.e("jock-tag-preview"), { "is-empty": empty })}
      onClick={onClick}
    >
      <div className={bem.e("jock-tag-preview-title")} style={{
        backgroundColor: color || "#DFECF5",
      }}>{widget.name}</div>
      <div className={bem.e("jock-tag-preview-content")}>
        {times(2, (index) => (
          <div key={`content-group-${index}`} className={bem.e("jock-tag-preview-content-group")}>
            {times(numberOfBarsInGroup, (barIndex) => (<div key={`content-group-${index}-bar-${barIndex}`} className={bem.e("jock-tag-preview-content-bar")} />))}
          </div>
        ))}
      </div>
      <div className={bem.e("jock-tag-preview-overlay")} />
    </div>
  );
}

JockPreviewCard.propTypes = {
  widget: PropTypes.any,
  onClick: PropTypes.func,
};

JockPreviewCard.defaultProps = {
  onClick: () => { },
};
