import React, { useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { includes, isEqual } from "lodash";
import Checkbox from "components/Checkbox";
import bn from "utils/bemnames";
import { Asterisk } from "components/Elements";
import { Row, Col } from "reactstrap";
import classnames from "classnames";

const bem = bn.create("days-of-week");
const weekArray = moment.weekdaysMin();

const DaysOfWeek = ({
  value,
  onClick,
  disabled,
  isRequired,
  label,
  showLabel,
  intl,
  isWeekDaysSelected,
  setIsWeekDaysSelected,
  isWeekendSelected,
  setIsWeekendSelected,
  onClickWeekend,
  onClickWeekdays,
  checkStyle,
  enableWeekendWeekdayCheckboxes,
  mustInclude,
  error,
  disabledDays,
  isView,
}) => {
  const days = value || [];
  if (isView) {
    return days
      .map((day) => {
        return intl.formatMessage({
          id: `days of week > ${weekArray[day - 1].toLocaleLowerCase()}`,
        });
      })
      .join(", ");
  }
  let fieldLabel = label || intl.formatMessage({ id: "days of week > title" });
  let showFieldLabel = typeof showLabel === "undefined" ? true : showLabel;

  useEffect(() => {
    const weekend = [1, 7];
    if (
      isEqual(
        days.filter((d) => includes(weekend, d)),
        weekend
      )
    ) {
      setIsWeekendSelected(true);
    }

    const weekdays = [2, 3, 4, 5, 6];
    if (
      isEqual(
        days.filter((d) => includes(weekdays, d)),
        weekdays
      )
    ) {
      setIsWeekDaysSelected(true);
    }
  }, []);

  return (
    <React.Fragment>
      <Row className={classnames("p-0 m-0", bem.b())}>
        <Col className="p-0 m-0" xs={6}>
          <div>
            {showFieldLabel && (
              <label className={bem.e("label-text")}>
                <span>{fieldLabel}</span>
                {isRequired && <Asterisk>*</Asterisk>}
              </label>
            )}
            <ul className={bem.e("days")}>
              {(weekArray || []).map((label, index) => {
                let day = index + 1;
                return (
                  <li key={index} className={bem.e("day")}>
                    <span className={bem.e("day-text")}>
                      <FormattedMessage
                        id={`days of week > ${label.toLocaleLowerCase()}`}
                      />
                    </span>
                    <Checkbox
                      checked={includes(days, day)}
                      disabled={
                        disabled ||
                        day === mustInclude ||
                        includes(disabledDays, day)
                      }
                      onChange={(checked) => {
                        if (!disabled) onClick(day, checked);
                      }}
                      checkStyle={checkStyle}
                    />
                  </li>
                );
              })}
              {enableWeekendWeekdayCheckboxes && (
                <React.Fragment>
                  <li className={bem.e("extra-checkbox-container")}>
                    <Checkbox
                      checked={isWeekDaysSelected}
                      text={intl.formatMessage({
                        id: "days of week > weekdays",
                      })}
                      onChange={(checked) => {
                        setIsWeekDaysSelected(!isWeekDaysSelected);
                        onClickWeekdays(checked);
                      }}
                    />
                  </li>
                  <li className={bem.e("extra-checkbox-container")}>
                    <Checkbox
                      checked={isWeekendSelected}
                      text={intl.formatMessage({
                        id: "days of week > weekend",
                      })}
                      onChange={(checked) => {
                        setIsWeekendSelected(!isWeekendSelected);
                        onClickWeekend(checked);
                      }}
                    />
                  </li>
                </React.Fragment>
              )}
            </ul>
          </div>
        </Col>
      </Row>
      {error && <span className="text-danger">{error}</span>}
    </React.Fragment>
  );
};
DaysOfWeek.propTypes = {
  disabled: PropTypes.bool.isRequired,
  value: PropTypes.array.isRequired,
  days: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
  disabledDays: PropTypes.array,
  isView: PropTypes.bool,
};
DaysOfWeek.defaultProps = {
  disabled: false,
  value: [],
  days: [],
  onClick: () => {},
  onChange: () => {},
  isRequired: false,
  disabledDays: [],
  isView: false,
};
export default DaysOfWeek;
