import React from "react";
import { get, map } from "lodash";
import { FormattedMessage } from "react-intl";
import UploadFile from "components/UploadFile";
import bn from "utils/bemnames";
const bem = bn.create("process-dialog");

function ContentProductionProvided(props) {
  const { process } = props;
  const spotInfo = get(process, "_source.spot_info", []);
  const totalSpots = get(spotInfo, "length", 0);
  const isProductionProvidedNeededTags =
    get(process, "_source.contract_status", "") ===
    "Production Provided - Needs tags";
  return (
    <React.Fragment>
      {map(spotInfo, (spot, spotIndex) => {
        const isBookend = get(spot, "spot_type", "Standard") === "Book-ends";

        let recordingUrl = get(spot, "recording_path");

        let recordingUrlB = get(spot, "recording_path_b");
        let recordingAudit = get(spot, "recording_audit");
        const recordingBAudit = get(spot, "recording_path_b_audit");
        // get recording from final recording (Record tab)
        if (isProductionProvidedNeededTags) {
          recordingUrl = get(spot, "final_recording_path", "");
          recordingAudit = get(spot, "final_recording_audit", "");
        }
        return (
          <div className={bem.e("spot-wrapper")} key={spotIndex}>
            {totalSpots > 1 && (
              <span className={bem.e("spot-title")}>Spot {spotIndex + 1}</span>
            )}
            <UploadFile
              actions={[]}
              positionActions="bottom"
              mode={"view"}
              showRemoveIcon={false}
              process={process}
              spot={spot}
              url={recordingUrl}
              isShowFileNameInEditMode={true}
              isBookend={isBookend}
              path={isBookend ? "a" : ""}
              hideSpotLength={true}
              auditInfo={recordingAudit}
            >
              <FormattedMessage id="process > button upload" />
            </UploadFile>
            {recordingUrlB && (
              <UploadFile
                actions={[]}
                positionActions="bottom"
                mode={"view"}
                showRemoveIcon={false}
                process={process}
                spot={spot}
                url={recordingUrlB}
                isShowFileNameInEditMode={true}
                isBookend={isBookend}
                path={isBookend ? "b" : ""}
                hideSpotLength={true}
                auditInfo={recordingBAudit}
              >
                <FormattedMessage id="process > button upload" />
              </UploadFile>
            )}
          </div>
        );
      })}
    </React.Fragment>
  );
}

export default ContentProductionProvided;
