import React, { useRef } from "react";
import { Formik } from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";
import TextInput from "components/TextInput";
import { FormattedMessage } from "react-intl";
import { Asterisk } from "components/Elements";
import { Button, Form, FormGroup } from "reactstrap";
import { injectIntl } from "react-intl";
import Spinner from "components/Spinner";
import bn from "utils/bemnames";
const bem = bn.create("group-admin-form");

const ChangePasswordForm = (props) => {
  const {
    intl,
    onSubmit,
    onCancel,
    isLoading,
    user,
    isAuthChangePassword,
    currentServer,
  } = props;
  const formikRef = useRef();
  const initialValues = {
    old_password: "",
    password: "",
    password_confirmation: "",
  };

  const form = (
    <Formik
      ref={formikRef}
      enableReinitialize
      className={bem.b()}
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        old_password: Yup.string().required(
          intl.formatMessage({ id: "validate > old password is required" })
        ),
        password: currentServer.password_complexity_pattern
          ? Yup.string().matches(
              new RegExp(currentServer.password_complexity_pattern),
              intl.formatMessage({
                id: "validate > password does not match pattern",
              })
            )
          : Yup.string()
              .required(
                intl.formatMessage({ id: "validate > password is required" })
              )
              .min(
                6,
                intl.formatMessage({
                  id: "validate > minlength password is 6 characters",
                })
              ),
        password_confirmation: Yup.string()
          .required(
            intl.formatMessage({
              id: "validate > password confirmation is required",
            })
          )
          .oneOf(
            [Yup.ref("password"), null],
            intl.formatMessage({
              id: "validate > password confirmation must match",
            })
          ),
      })}
      onSubmit={onSubmit}
    >
      {(formProps) => {
        const errors = formProps.errors;
        const touched = formProps.touched;
        const values = formProps.values;

        return (
          <Form
            onSubmit={formProps.handleSubmit}
            noValidate
            className={bem.b()}
          >
            <div className={bem.e("content-form")}>
              <FormGroup>
                <TextInput
                  label={
                    <span>
                      {isAuthChangePassword ? (
                        <FormattedMessage id="change password > old password" />
                      ) : (
                        <FormattedMessage id="change password > re-enter own password" />
                      )}
                      <Asterisk>*</Asterisk>
                    </span>
                  }
                  type="password"
                  name="old_password"
                  placeholder={intl.formatMessage({
                    id: isAuthChangePassword
                      ? "change password > old password"
                      : "change password > re-enter own password",
                  })}
                  value={values.old_password}
                  error={touched.old_password && errors.old_password}
                  onChange={formProps.handleChange}
                  onBlur={formProps.handleBlur}
                />
              </FormGroup>
              <FormGroup>
                <TextInput
                  label={
                    <span>
                      <FormattedMessage id="change password > new password" />
                      <Asterisk>*</Asterisk>
                    </span>
                  }
                  type="password"
                  name="password"
                  placeholder={intl.formatMessage({
                    id: "change password > new password",
                  })}
                  value={values.password}
                  error={touched.password && errors.password}
                  onChange={formProps.handleChange}
                  onBlur={formProps.handleBlur}
                />
              </FormGroup>
              <FormGroup className={bem.e("form-group-reset")}>
                <TextInput
                  label={
                    <span>
                      <FormattedMessage id="change password > re-type new password" />
                      <Asterisk>*</Asterisk>
                    </span>
                  }
                  type="password"
                  name="password_confirmation"
                  placeholder={intl.formatMessage({
                    id: "change password > re-type new password",
                  })}
                  value={values.password_confirmation}
                  error={
                    touched.password_confirmation &&
                    errors.password_confirmation
                  }
                  onChange={formProps.handleChange}
                  onBlur={formProps.handleBlur}
                />
              </FormGroup>
            </div>
            <div className={bem.e("form-buttons")}>
              <Button
                color="blue"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  formikRef.current.handleSubmit(e);
                }}
                className="btn btn-radius"
              >
                <FormattedMessage id={`change password  > button submit`} />
              </Button>
              <Button
                color="blue"
                outline
                className="btn btn-radius"
                onClick={onCancel}
              >
                <FormattedMessage id={`change password  > button cancel`} />
              </Button>
              <input
                className="no-display"
                type="password"
                name="fakePasswordRemembered"
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
  return (
    <>
      {form}
      <Spinner isLoading={isLoading} />
    </>
  );
};
ChangePasswordForm.propTypes = {
  updateValues: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  isAuthChangePassword: PropTypes.bool,
};
ChangePasswordForm.defaultProps = {
  updateValues: {},
  onCancel: () => {},
  isAuthChangePassword: false,
};
export default injectIntl(ChangePasswordForm);
