import View from "./view";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { get, first, pick, toLower } from "lodash";
import { setIsSidebarOpen, setSidebarActive } from "store/actions/auth";
import { compose, lifecycle, withHandlers, withState } from "recompose";
import {
  getClocksData,
  getSchedules,
  swapOverrideCheck,
  swapSchedule,
  getSwappableSlots,
  deleteSlot,
  undoDeleteSlot,
  getFillSettings,
  fillSchedulingManually,
  fillSchedulingUndo,
} from "store/actions/programmings";
import { setActiveTab } from "store/actions/processes";
import { setScheduleCalendarFilter } from "store/actions/auth";
import history from "components/History";
import URL from "utils/urls";
import moment from "moment";
import { withRouter } from "react-router";
export default withRouter(
  injectIntl(
    compose(
      connect(
        (state, props) => {
          const selectedStationId = get(
            state,
            "auth.schedule_calendar_filter.selected_station_id"
          );
          const scheduleCalendarFilter = get(
            state,
            `auth.schedule_calendar_filter.${selectedStationId}`,
            {}
          );
          return {
            allScheduleCalendarFilter: get(
              state,
              "auth.schedule_calendar_filter",
              {}
            ),
            scheduleCalendarFilter,
            lang: toLower(get(state, "auth.user.lang", "en-us")),
            clocksData: get(state, "programmings.clocksData.data", {}),
            schedules: get(state, "programmings.schedules.data", []),
            swappableSlots: get(
              state,
              "programmings.swappableSlots.data.swappable_slots",
              []
            ),
            sameTypeSlots: get(
              state,
              "programmings.swappableSlots.data.same_type_slots",
              []
            ),
            fillSettings: get(state, "programmings.fillSettings.data", {}),
            loading:
              get(state, "programmings.schedules.loading", false) ||
              get(state, "programmings.swappableSlots.loading", false) ||
              get(state, "programmings.swap.loading", false) ||
              get(state, "programmings.deleteSlot.loading", false) ||
              get(state, "programmings.fillSettings.loading", false) ||
              get(state, "programmings.fillScheduling.loading", false),
            ...props,
          };
        },
        {
          setScheduleCalendarFilter,
          setIsSidebarOpen,
          getClocksData,
          getSchedules,
          swapOverrideCheck,
          swapSchedule,
          getSwappableSlots,
          setActiveTab,
          setSidebarActive,
          deleteSlot,
          undoDeleteSlot,
          getFillSettings,
          fillSchedulingManually,
          fillSchedulingUndo,
        }
      ),
      withState(
        "isOpenConfirmDeleteModal",
        "setIsOpenConfirmDeleteModal",
        false
      ),
      withState("selectedAddFillItems", "setSelectedAddFillItems", null),
      withState("selectedDeleteItem", "setSelectedDeleteItem", null),
      withState("timerId", "setTimerId", null),
      withState("itemSwapped", "setItemSwapped", null),
      withState("statementType", "setStatementType", null),
      withState("successMessage", "setSuccessMessage", ""),
      withState(
        "isScheduleSettingsModalOpen",
        "setIsScheduleSettingsModalOpen",
        false
      ),
      withState("isSuccessModalOpen", "setIsSuccessModalOpen", false),
      withHandlers({
        onSubmitScheduleSettings: ({ setIsScheduleSettingsModalOpen }) => (
          values
        ) => {
          setIsScheduleSettingsModalOpen(false);
        },
        onGetSchedulesByFilter: (props) => (filter) => {
          props.getSchedules({
            data: {
              ...pick(filter, [
                "end_date",
                "start_date",
                "station_id",
                "weekdays",
              ]),
              tz: moment.tz.guess(),
              tzOffset: new Date().getTimezoneOffset(),
            },
          });
        },
        onGetClocksData: ({ getClocksData }) => (stationKey) => {
          getClocksData({
            data: {
              station_id: stationKey,
            },
          });
        },
        onSelectItemTargetSwap: ({
          swapOverrideCheck,
          scheduleCalendarFilter,
          setScheduleCalendarFilter,
        }) => ({ data, cb }) => {
          const stationKey = get(scheduleCalendarFilter, "station_id");
          const selectedItemSwap = get(
            scheduleCalendarFilter,
            "selectedItemSwap",
            {}
          );
          // save target swap item to store
          const filter = {
            ...scheduleCalendarFilter,
            selectedItemTargetSwap: data,
          };
          setScheduleCalendarFilter(filter);
          let destinated_slot_id = data.slot_id;
          swapOverrideCheck({
            data: {
              station_id: stationKey,
              targetted_slot_id: selectedItemSwap.slot_id,
              targetted_slot_date: moment(selectedItemSwap.date).format(
                "YYYY-MM-DD"
              ),
              destinated_slot_id,
              destinated_slot_date: moment(data.date).format("YYYY-MM-DD"),
            },
            cb: ({ override, rules }) => {
              cb({ override, rules });
            },
          });
        },
        onSelectItemSwap: ({
          setStatementType,
          scheduleCalendarFilter,
          setScheduleCalendarFilter,
          getSwappableSlots,
        }) => (item) => {
          setStatementType("swapping");
          let filter = {
            ...scheduleCalendarFilter,
            selectedItemSwap: item,
          };
          if (!item || Object.keys(item).length === 0) {
            filter = {
              ...filter,
              selectedItemTargetSwap: {}, // reset target swap
            };
          }
          setScheduleCalendarFilter(filter);
          if (item.slot_id) {
            const stationKey = get(scheduleCalendarFilter, "station_id");
            getSwappableSlots({
              data: {
                station_id: stationKey,
                order_process_id: item.order_process_id,
                start_date: scheduleCalendarFilter.start_date,
                end_date: scheduleCalendarFilter.end_date,
              },
              cb: () => {},
            });
          }
        },
        onViewItem: ({ setActiveTab, setSidebarActive }) => (item) => {
          const template_id = item.order_template_key;
          const activeTab = "order_details";
          const sidebarRoute =
            template_id === "contest"
              ? "/processes/promotion_contests"
              : "/processes/liners";
          history.push(
            URL.VIEW_PROCESS({
              template_id,
              process_id: item.order_process_id,
            })
          );
          setActiveTab(activeTab);
          setSidebarActive(sidebarRoute);
        },
        onDeleteItem: ({
          setSelectedDeleteItem,
          setIsOpenConfirmDeleteModal,
        }) => (item) => {
          setSelectedDeleteItem(item);
          setIsOpenConfirmDeleteModal(true);
        },
      }),
      withHandlers({
        onAddFillItems: ({
          setStatementType,
          setSuccessMessage,
          intl,
          setTimerId,
          setSelectedAddFillItems,
          setIsSuccessModalOpen,
          fillSchedulingManually,
          scheduleCalendarFilter,
        }) => (date) => {
          const stationKey = get(scheduleCalendarFilter, "station_id");
          setSelectedAddFillItems(date);
          fillSchedulingManually({
            data: {
              date: moment(date).format("YYYY-MM-DD"),
              station_id: stationKey,
            },
            cb: () => {
              setStatementType("filled");
              setSuccessMessage(
                intl.formatMessage({
                  id:
                    "process > promotion > liner > schedule > fill successful",
                })
              );
              setIsSuccessModalOpen(true);
              // wait 8s to clear undo action
              const timerId = setTimeout(() => {
                setStatementType(null);
                setSelectedAddFillItems(null);
              }, 8000);
              setTimerId(timerId);
            },
          });
        },
        onUndoAddFillItems: ({
          setStatementType,
          setSuccessMessage,
          intl,
          setSelectedAddFillItems,
          setIsSuccessModalOpen,
          fillSchedulingUndo,
          selectedAddFillItems,
          scheduleCalendarFilter,
        }) => () => {
          const stationKey = get(scheduleCalendarFilter, "station_id");

          fillSchedulingUndo({
            data: {
              date: moment(selectedAddFillItems).format("YYYY-MM-DD"),
              station_id: stationKey,
            },
            cb: () => {
              setStatementType(null);
              setSelectedAddFillItems(null);
              setSuccessMessage(
                intl.formatMessage({
                  id: "process > promotion > liner > schedule > fill undone",
                })
              );
              setIsSuccessModalOpen(true);
            },
          });
        },

        onConfirmDeleteItem: ({
          setIsOpenConfirmDeleteModal,
          setIsSuccessModalOpen,
          setSuccessMessage,
          intl,
          setStatementType,
          setSelectedDeleteItem,
          setTimerId,
          deleteSlot,
          selectedDeleteItem,
          scheduleCalendarFilter,
          onGetSchedulesByFilter,
        }) => () => {
          setIsOpenConfirmDeleteModal(false);
          const stationKey = get(scheduleCalendarFilter, "station_id");
          deleteSlot({
            data: {
              station_id: stationKey,
              process_id: selectedDeleteItem.order_process_id,
              slot_id: selectedDeleteItem.slot_id,
              start_date: moment(selectedDeleteItem.date).format("YYYY-MM-DD"),
            },
            cb: (response) => {
              setStatementType("deleted");
              setSuccessMessage(
                intl.formatMessage({
                  id:
                    "process > promotion > liner > schedule > element deleted",
                })
              );
              setIsSuccessModalOpen(true);
              setSelectedDeleteItem(get(response, "slot._source", {}));
              onGetSchedulesByFilter(scheduleCalendarFilter);
              // wait 8s to clear undo action
              const timerId = setTimeout(() => {
                setStatementType(null);
                setSelectedDeleteItem(null);
              }, 8000);
              setTimerId(timerId);
            },
          });
        },
        onUndoDeletedItem: ({
          setSuccessMessage,
          setStatementType,
          intl,
          setIsSuccessModalOpen,
          setSelectedDeleteItem,
          undoDeleteSlot,
          scheduleCalendarFilter,
          selectedDeleteItem,
          onGetSchedulesByFilter,
        }) => () => {
          const stationKey = get(scheduleCalendarFilter, "station_id");
          undoDeleteSlot({
            data: {
              station_id: stationKey,
              slot_data: selectedDeleteItem,
            },
            cb: () => {
              onGetSchedulesByFilter(scheduleCalendarFilter);
              setStatementType(null);
              setSelectedDeleteItem(null);
              setSuccessMessage(
                intl.formatMessage({
                  id: "process > promotion > liner > schedule > delete undone",
                })
              );
              setIsSuccessModalOpen(true);
            },
          });
        },
        onUndoSwappedItem: ({
          setSuccessMessage,
          setIsSuccessModalOpen,
          scheduleCalendarFilter,
          setScheduleCalendarFilter,
          setStatementType,
          intl,
          setItemSwapped,
          itemSwapped,
          swapSchedule,
          timerId,
          setTimerId,
          onGetSchedulesByFilter,
        }) => () => {
          setStatementType(null);
          swapSchedule({
            data: {
              ...itemSwapped,
              destinated_slot_date: itemSwapped.targetted_slot_date,
              destinated_slot_id: itemSwapped.targetted_slot_id,
              targetted_slot_date: itemSwapped.destinated_slot_date,
              targetted_slot_id: itemSwapped.destinated_slot_id,
              is_override: false,
            },
            cb: () => {
              const newFilter = {
                ...scheduleCalendarFilter,
                selectedItemTargetSwap: {},
                selectedItemSwap: {},
              };
              onGetSchedulesByFilter(newFilter);
              setScheduleCalendarFilter(newFilter);
              setSuccessMessage(
                intl.formatMessage({
                  id: "process > promotion > liner > schedule > swap undone",
                })
              );
              setIsSuccessModalOpen(true);
              setItemSwapped(null);
            },
          });
          if (timerId) clearTimeout(timerId);
          setTimerId(null);
        },
        onSwapItem: ({
          setSuccessMessage,
          setIsSuccessModalOpen,
          swapSchedule,
          scheduleCalendarFilter,
          onGetSchedulesByFilter,
          setScheduleCalendarFilter,
          setStatementType,
          intl,
          setItemSwapped,
          setTimerId,
        }) => ({ data, cb }) => {
          cb(); // close the tooltip
          const stationKey = get(scheduleCalendarFilter, "station_id");
          const selectedItemSwap = get(
            scheduleCalendarFilter,
            "selectedItemSwap",
            {}
          );
          let destinated_slot_id = data.slot_id;
          const swapData = {
            station_id: stationKey,
            targetted_slot_id: selectedItemSwap.slot_id,
            targetted_slot_date: moment(selectedItemSwap.date).format(
              "YYYY-MM-DD"
            ),
            destinated_slot_id,
            destinated_slot_date: moment(data.date).format("YYYY-MM-DD"),
            is_override: data.is_override || false,
          };
          setItemSwapped(swapData); // keep swap data to undo it later
          swapSchedule({
            data: swapData,
            cb: () => {
              const newFilter = {
                ...scheduleCalendarFilter,
                selectedItemTargetSwap: {},
                selectedItemSwap: {},
              };
              setScheduleCalendarFilter(newFilter);
              onGetSchedulesByFilter(newFilter);
              setStatementType("swapped");
              setSuccessMessage(
                intl.formatMessage({
                  id:
                    "process > promotion > liner > schedule > elements swapped",
                })
              );
              setIsSuccessModalOpen(true);
              // wait 8s to clear undo action
              const timerId = setTimeout(() => {
                setStatementType(null);
                setItemSwapped(null);
              }, 8000);
              setTimerId(timerId);
            },
          });
        },
        onChangeSelectedStation: ({
          onGetClocksData,
          onGetSchedulesByFilter,
          allScheduleCalendarFilter,
          setScheduleCalendarFilter,
        }) => (station) => {
          const scheduleCalendarFilterForStation = get(
            allScheduleCalendarFilter,
            station,
            {}
          );
          const filter = {
            ...scheduleCalendarFilterForStation,
            station_id: station,
          };
          setScheduleCalendarFilter(filter); // update current filter to new station
          onGetClocksData(station);
          onGetSchedulesByFilter(filter); // call api get schedules
        },
        onGetDataByRangeDates: ({
          scheduleCalendarFilter,
          setScheduleCalendarFilter,
          onGetSchedulesByFilter,
          stations,
          getSwappableSlots,
          statementType,
        }) => ({ start_date, end_date }) => {
          // handle api first call
          const selectedStation = scheduleCalendarFilter.station_id
            ? scheduleCalendarFilter.station_id
            : get(first(stations), "key");
          const filter = {
            ...scheduleCalendarFilter,
            station_id: selectedStation,
            start_date,
            end_date,
          };
          setScheduleCalendarFilter(filter);
          onGetSchedulesByFilter(filter); // call api get schedules
          // get swappable slots
          const selectedItemSwap = scheduleCalendarFilter.selectedItemSwap;
          if (statementType === "swapping" && selectedItemSwap.slot_id) {
            const stationKey = get(scheduleCalendarFilter, "station_id");
            getSwappableSlots({
              data: {
                station_id: stationKey,
                order_process_id: selectedItemSwap.order_process_id,
                start_date,
                end_date,
              },
              cb: () => {},
            });
          }
        },
      }),
      lifecycle({
        componentDidMount() {
          const query = new URLSearchParams(this.props.location.search);
          // call api get clock data. We dont need to call api when schedule tab. Because we called at order tab.
          const isScheduleTab = get(this.props, "isScheduleTab", false);
          const selectedStation = this.props.scheduleCalendarFilter.station_id
            ? this.props.scheduleCalendarFilter.station_id
            : get(first(this.props.stations), "key");
          if (!isScheduleTab || query.get("tab")) {
            this.props.onGetClocksData(selectedStation);
          }

          this.props.getFillSettings({
            station_id: selectedStation,
          });
        },
      })
    )(View)
  )
);
