import { connect } from "react-redux";
import { compose, lifecycle, withHandlers, withState } from "recompose";
import { get } from "lodash";
import { injectIntl } from "react-intl";
import SearchResults from "./view";
import {
  getAssociateElements,
  setAssociateElements,
} from "store/actions/programmings";
import {
  typeDisplay,
  spotDisplay,
  stationDisplay,
  flightDatesDisplay,
  titleDisplay,
} from "./view";
export {
  typeDisplay,
  spotDisplay,
  stationDisplay,
  flightDatesDisplay,
  titleDisplay,
};
const pageSize = 5;
const Enhanced = compose(
  connect(
    (state, props) => {
      const searchData = get(state, "programmings.associateElements.data", {});
      const clocksData = get(state, "programmings.clocksData.data", {});
      return {
        searchData,
        searchParams: get(state, "programmings.associateElements.params", {}),
        isDirty: get(state, "programmings.associateElements.is_dirty", false),
        hasMore:
          parseInt(get(searchData, "pages", 1)) >
          parseInt(get(searchData, "page", 1)),
        definitions: get(clocksData, "definitions", []),
        selectedStation: get(
          props.process,
          "data.fields.order_stations_array.value.0"
        ),
        dateFormatByServer: get(
          state,
          "servers.currentServer.data.date_format",
          "MM/DD/YYYY"
        ),
        ...props,
      };
    },
    {
      getAssociateElements,
      setAssociateElements,
    }
  ),
  withState("filtered", "setFiltered", []),
  withState("typeId", "setTypeId", null),
  withState("sorted", "setSorted", { id: "updated_ts", desc: true }),
  withState("selectedItems", "setSelectedItems", []),
  withState("searchText", "setSearchText", ""),
  withHandlers({
    getItemsList: ({
      getAssociateElements,
      sorted,
      filtered,
      searchParams,
      selectedStation,
      typeId,
      searchText,
      process,
    }) => async (params = {}) => {
      const associatedItemsIds = get(
        process,
        "data.fields.associated_items_ids.value",
        []
      );
      getAssociateElements({
        data: {
          ...searchParams,
          rpp: pageSize,
          page: 1,
          sort: `${sorted.id} ${sorted.desc ? "desc" : "asc"}`,
          filtered,
          station_id: selectedStation.key,
          statuses: ["active"],
          type_id: typeId,
          search: searchText,
          exclude_ids: associatedItemsIds,
          ...params, //override
        },
      });
    },
    getMoreItems: ({
      getAssociateElements,
      sorted,
      filtered,
      searchParams,
      searchData,
      selectedStation,
      typeId,
      searchText,
      process,
    }) => () => {
      const associatedItemsIds = get(
        process,
        "data.fields.associated_items_ids.value",
        []
      );
      const page = parseInt(get(searchData, "page", 1));
      getAssociateElements({
        data: {
          ...searchParams,
          rpp: pageSize,
          page: page + 1,
          sort: `${sorted.id} ${sorted.desc ? "desc" : "asc"}`,
          filtered,
          station_id: selectedStation.key,
          statuses: ["active"],
          type_id: typeId,
          search: searchText,
          exclude_ids: associatedItemsIds,
        },
      });
    },
  }),
  withHandlers({
    onSort: ({ setSorted, sorted, getItemsList }) => (field) => {
      let newSort = {
        id: field,
        desc: !sorted.desc,
      };
      let sortString = `${newSort.id} ${newSort.desc ? "desc" : "asc"}`;
      // detect third click
      if (sorted.id === field && sorted.desc && !newSort.desc) {
        sortString = `updated_ts desc`;
        newSort = {
          id: "updated_ts",
          desc: true,
        };
      }
      setSorted(newSort);
      getItemsList({
        sort: sortString,
      });
    },
    onSearch: ({ getItemsList }) => () => {
      getItemsList({});
    },
    onSelect: ({ onSelectItem, searchData, setAssociateElements, isView }) => (
      item
    ) => {
      if (isView) return;
      const newItems = get(searchData, "items", []).filter(
        (i) => i._id !== item._id
      );
      onSelectItem(item);
      setAssociateElements(newItems);
    },
  }),
  lifecycle({
    componentDidMount() {
      if (this.props.selectedStation) {
        this.props.getItemsList();
      } else {
        this.props.setAssociateElements([]);
      }
    },
    componentDidUpdate(prevProps) {
      if (
        (this.props.isDirty ||
          get(this.props, "selectedStation.key") !==
            get(prevProps, "selectedStation.key")) &&
        get(this.props, "selectedStation.key")
      ) {
        this.props.getItemsList();
      }
    },
  })
)(SearchResults);
export default injectIntl(Enhanced);
