import ReleseNationalSlots from './view';
import { injectIntl } from 'react-intl';
import { compose, withState, withHandlers } from 'recompose';
import { connect } from 'react-redux';

export default injectIntl(
	compose(
		connect((state, ownProps) => {
	        return {
	          ...ownProps
	        }
	    }),
	)(ReleseNationalSlots)
);