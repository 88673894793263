import React from "react";
import SortableComponent from "components/SortableComponent";
import ContestSchedule from "../ContestSchedule";
import ContestRules from "../ContestRules";
import ContestAssociated from "../ContestAssociated";
import ContestClient from "../ContestClient";
import ContestDocuments from "../ContestDocuments";
const SortItem = (props) => {
  const { sectionKey } = props;
  let content;
  switch (sectionKey) {
    case "schedule":
      content = <ContestSchedule {...props} />;
      break;
    case "rules":
      content = <ContestRules {...props} />;
      break;
    case "associated":
      content = <ContestAssociated {...props} />;
      break;
    case "client":
      content = <ContestClient {...props} />;
      break;
    default:
      content = <ContestDocuments {...props} />;
      break;
  }
  return <div className={props.bem.e("sort-item")}>{content}</div>;
};
const ContestSections = (props) => {
  const { bem, openDetails, sections, ...rest } = props;
  return (
    <div className={bem.e("contest-sections-container")}>
      <SortableComponent
        data={sections}
        onSorted={props.onUpdateOrder}
        lockToContainerEdges={false}
        renderItem={(key, index) => {
          const params = {
            ...rest,
            bem,
            sectionKey: key,
            openDetails,
            key: index,
          };
          return <SortItem {...params} />;
        }}
      />
    </div>
  );
};

export default ContestSections;
