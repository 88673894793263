import * as React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { get, lowerCase, isEqual, upperFirst, uniqBy, has } from "lodash";
import moment from "moment-timezone";
import TextInput from "components/TextInput";
import Dropdown from "components/Dropdown";
import { FormattedMessage } from "react-intl";
import { formatDisplayDate } from "utils/helpers";
import { Row, Col, Label } from "reactstrap";
import NumberFormat from "react-number-format";
import OrderClientDetails from "components/OrderClientDetails";

import {
  ShadowBox,
  Asterisk,
  SectionTitle,
  SectionFields,
} from "components/Elements";
import { PhoneNumberFormat, RotationFormatSuffix } from "utils/config";
import bn from "utils/bemnames";
const bem = bn.create("order-details");
class ClientDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      agencies: [],
      contacts: [],
      fieldRefs: false,
      isOpenDueDateModal: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps.info) !== JSON.stringify(this.props.info)) {
      this.setState({ fieldRefs: false });
      return;
    }
    for (
      let i = 0;
      i < Object.keys(nextProps.process.data.fields).length;
      i++
    ) {
      let key = Object.keys(nextProps.process.data.fields)[i];
      let changed = false;
      if (
        !nextProps.process.data.fields[key] &&
        !this.props.process.data.fields[key]
      )
        continue;
      else if (
        !nextProps.process.data.fields[key] &&
        this.props.process.data.fields[key]
      )
        changed = true;
      else if (
        nextProps.process.data.fields[key] &&
        !this.props.process.data.fields[key]
      )
        changed = true;
      else if (
        !nextProps.process.data.fields[key].value &&
        !this.props.process.data.fields[key].value
      )
        continue;
      else if (
        !nextProps.process.data.fields[key].value &&
        this.props.process.data.fields[key].value
      )
        changed = true;
      else if (
        nextProps.process.data.fields[key].value &&
        !this.props.process.data.fields[key].value
      )
        changed = true;
      else if (
        nextProps.process.data.fields[key].value.toString() !==
        this.props.process.data.fields[key].value.toString()
      )
        changed = true;
      else if (
        !isEqual(
          get(nextProps, `process.data.fields.${key}.value`),
          get(this.props, `process.data.fields.${key}.value`)
        )
      ) {
        changed = true;
      }
      if (changed) {
        if (
          [
            "client_id",
            "order_stations",
            "order_sold",
            "spots",
            "key",
            "role_account_executive",
            "role_sales_person_2",
          ].indexOf(key) > -1
        ) {
          this.setState({ fieldRefs: false });
          break;
        }
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  getFieldRefs() {
    if (this.state.fieldRefs) return this.state.fieldRefs;
    let { process, template, step } = this.props;

    step = template.steps.order_details;
    let client_name = step.fields.find((f) => f.field === "client_name");
    let client_id = step.fields.find((f) => f.field === "client_id");

    let agency_name = step.fields.find((f) => f.field === "agency_name");
    let agency_id = step.fields.find((f) => f.field === "agency_id");

    let contract_start_date = step.fields.find(
      (f) => f.field === "contract_start_date"
    );
    let contract_end_date = step.fields.find(
      (f) => f.field === "contract_end_date"
    );

    // let due_date = step.fields.find((f) => f.field === "due_date");

    let contract_client_contact = step.fields.find(
      (f) => f.field === "contract_client_contact"
    );
    let contract_client_email = step.fields.find(
      (f) => f.field === "contract_client_email"
    );
    let contract_client_nickname = step.fields.find(
      (f) => f.field === "contract_client_nickname"
    );
    let contract_client_phone = step.fields.find(
      (f) => f.field === "contract_client_phone"
    );

    let contract_status = step.fields.find(
      (f) => f.field === "contract_status"
    );
    let contact_id = step.fields.find((f) => f.field === "contact_id");
    // let contract_stations_order_number = step.fields.find(
    //   (f) => f.field === "contract_stations_order_number"
    // );

    let contract_category = step.fields.find(
      (f) => f.field === "contract_category"
    );
    let order_title = step.fields.find((f) => f.field === "order_title");

    let order_start_date = step.fields.find(
      (f) => f.field === "order_start_date"
    );
    let order_end_date = step.fields.find((f) => f.field === "order_end_date");
    let select_stations = step.fields.find((f) => f.field === "order_stations");

    // must have account executive/owner
    let role_account_executive = step.fields.find(
      (f) => f.field === "role_account_executive"
    );
    const role_account_executive_options_by_role =
      this.props.getRoleCandidates(
        role_account_executive,
        process,
        template,
        this.props.user
      ) || [];

    let role_account_executive_options = uniqBy(
      [
        ...role_account_executive_options_by_role,
        {
          label: this.props.user.name,
          role: 2,
          value: this.props.user.id,
          info: this.props.user,
        },
      ].map((item) => ({ ...item, value: parseInt(item.value) })),
      "value"
    );

    let select_account_executive_value;
    if (process.data.fields[role_account_executive.field]) {
      select_account_executive_value = {
        value: process.data.fields[role_account_executive.field].value.id,
        label: process.data.fields[role_account_executive.field].value.name,
        role: process.data.fields[role_account_executive.field].value.role,
      };
      this.props.valueChanged(
        role_account_executive,
        process,
        template,
        this.props.user,
        {
          value: {
            id: parseInt(select_account_executive_value.value),
            name: select_account_executive_value.label,
            role: parseInt(select_account_executive_value.role),
          },
        }
      );
    } else if (
      role_account_executive_options &&
      role_account_executive_options.length === 1 // only 1 choice in ae dop-downs. System should pre-populate with that choice
    ) {
      select_account_executive_value = role_account_executive_options[0];
      this.props.valueChanged(
        role_account_executive,
        process,
        template,
        this.props.user,
        {
          value: {
            id: parseInt(select_account_executive_value.value),
            name: select_account_executive_value.label,
            role: parseInt(select_account_executive_value.role),
          },
        }
      );
    } else {
      select_account_executive_value = {
        label: this.props.user.name,
        value: parseInt(this.props.user.id),
        role: 2,
        info: this.props.user,
      };
      this.props.valueChanged(
        role_account_executive,
        process,
        template,
        this.props.user,
        {
          value: {
            id: parseInt(select_account_executive_value.value),
            name: select_account_executive_value.label,
            role: parseInt(select_account_executive_value.role),
          },
        }
      );
    }

    // must have order stations
    if (!this.props.process.data.fields.contract_stations) {
      let init_stations = []; // this.props.info.stations[0].station_key
      this.props.valueChanged(
        { field: "contract_stations" },
        this.props.process,
        this.props.template,
        this.props.user,
        { value: init_stations }
      );
    }

    // must have order stations
    if (!this.props.process.data.fields.order_stations) {
      let init_stations = [];
      this.props.valueChanged(
        { field: "order_stations" },
        this.props.process,
        this.props.template,
        this.props.user,
        { value: init_stations }
      );
    }

    let contractSaved = process.data.fields.contract_id !== undefined;
    let payload = {
      client_name,
      client_id,
      agency_name,
      agency_id,
      contract_start_date,
      contract_end_date,
      // due_date,
      contract_client_contact,
      contract_client_email,
      contract_client_nickname,
      contract_client_phone,
      contract_status,
      contract_category,
      order_title,
      order_start_date,
      order_end_date,
      select_stations,
      contractSaved,
      role_account_executive_options,
      role_account_executive,
      select_account_executive_value,
      contact_id,
    };

    this.setState({ fieldRefs: payload });

    return payload;
  }

  onValueChanged = (field, value) => {
    const { valueChanged, template, user } = this.props;
    valueChanged(field, process, template, user, { value });
    this.props.setIsFieldsChanged(true);
  };
  onSelectContact = (contact) => {
    const {
      contract_client_contact,
      contract_client_email,
      contract_client_nickname,
      contract_client_phone,
      contact_id,
    } = this.getFieldRefs();

    const { valueChanged, template, user } = this.props;
    valueChanged(contract_client_contact, process, template, user, {
      value: get(contact, "contract_client_contact", ""),
    });

    // populate all contract fields
    if (get(contact, "contact_id", "")) {
      valueChanged(contract_client_email, process, template, user, {
        value: get(contact, "contract_client_email", ""),
      });
      valueChanged(contract_client_nickname, process, template, user, {
        value: get(contact, "contract_client_nickname", ""),
      });
      valueChanged(contract_client_phone, process, template, user, {
        value: get(contact, "contract_client_phone", ""),
      });
      valueChanged(contact_id, process, template, user, {
        value: get(contact, "contact_id", ""),
      });
    } else {
      valueChanged(contract_client_email, process, template, user, {
        value: "",
      });
      valueChanged(contract_client_nickname, process, template, user, {
        value: "",
      });
      valueChanged(contract_client_phone, process, template, user, {
        value: "",
      });
      valueChanged(contact_id, process, template, user, {
        value: "",
      });
    }

    this.props.setIsFieldsChanged(true);
  };

  onSelectAgency = (agency) => {
    const { agency_name, agency_id } = this.getFieldRefs();
    const { valueChanged, template, user } = this.props;
    valueChanged(agency_name, process, template, user, {
      value: agency.agency_name,
    });
    valueChanged(agency_id, process, template, user, {
      value: agency.id,
    });
  };

  renderSoldForm() {
    const {
      process,
      template,
      user,
      valueChanged,
      validationState,
      auth,
      setShouldBlockNavigation,
    } = this.props;

    const {
      client_id,
      contract_start_date,
      contract_end_date,
      // due_date,
      contract_client_contact,
      contract_client_email,
      contract_client_nickname,
      contract_client_phone,
      contract_category,
      contractSaved,
      role_account_executive_options,
      role_account_executive,
      select_account_executive_value,
      order_start_date,
      order_end_date,
      client_name,
      agency_name,
      contact_id,
    } = this.getFieldRefs();
    let bindedValueChanged = valueChanged.bind(this);
    const isSpecOrder = get(process, "data.fields.order_sold.value") === false;
    const isEnableEnterprise = get(auth, "info.production_multimarket", false);
    const isWoTrafficEnabled = get(auth, "info.enable_wo_traffic", false);
    return (
      <ShadowBox className={bem.e("box-client-details")}>
        <SectionTitle className={bem.e("section-title")}>
          <FormattedMessage id="process > title client details" />
        </SectionTitle>
        <OrderClientDetails
          validationState={validationState}
          client_name={client_name}
          intl={this.props.intl}
          template={template}
          user={user}
          client_id={client_id}
          contact_id={contact_id}
          contract_category={contract_category}
          agency_name={agency_name}
          contract_client_contact={contract_client_contact}
          contract_client_nickname={contract_client_nickname}
          contract_client_email={contract_client_email}
          contract_client_phone={contract_client_phone}
          bindedValueChanged={bindedValueChanged}
          setIsFieldsChanged={this.props.setIsFieldsChanged}
          onSelectContact={this.onSelectContact}
          onSelectAgency={this.onSelectAgency}
          process={process}
          isEnableEnterprise={isEnableEnterprise}
          onValueChanged={this.onValueChanged}
          isView={false}
          isWoTrafficEnabled={isWoTrafficEnabled}
          setShouldBlockNavigation={setShouldBlockNavigation}
          isSpecOrder={isSpecOrder}
        />

        <SectionFields className={classnames(bem.e("section-fields"))}>
          {!isSpecOrder && (
            <Row>
              <Col className={bem.e("col-field")}>
                {!contractSaved ? (
                  <TextInput
                    disabled={contractSaved}
                    label={
                      <span>
                        {contract_start_date.title}
                        {contract_start_date.mandatory && (
                          <Asterisk>*</Asterisk>
                        )}
                      </span>
                    }
                    type="date"
                    value={
                      process.data.fields[contract_start_date.field]
                        ? process.data.fields[contract_start_date.field].value
                        : ""
                    }
                    placeholder={this.props.intl.formatMessage({
                      id: "process > placeholder select date",
                    })}
                    onChange={(value) => {
                      setShouldBlockNavigation(true);
                      this.onValueChanged(contract_start_date, value);
                      // clear contact end date if contact start date if after
                      if (
                        value &&
                        get(
                          process,
                          `data.fields.${contract_end_date.field}.value`
                        )
                      ) {
                        const isAfterEndDate = moment(value).isAfter(
                          process.data.fields[contract_end_date.field].value
                        );
                        if (isAfterEndDate) {
                          this.onValueChanged(contract_end_date, "");
                        }
                      }
                      if (
                        !get(
                          this.props,
                          "process.data.fields.order_start_date.value"
                        ) &&
                        value
                      ) {
                        this.onValueChanged(order_start_date, value);
                      }
                    }}
                  />
                ) : (
                  <div>
                    <Label>{contract_start_date.title}</Label>
                    {process.data.fields[contract_start_date.field]
                      ? formatDisplayDate(
                          process.data.fields[contract_start_date.field].value
                        )
                      : ""}
                  </div>
                )}
                <div className="alert-danger">
                  {validationState[contract_start_date.field] &&
                    validationState[contract_start_date.field].validation_error}
                </div>
              </Col>
              <Col className={bem.e("col-field")}>
                {!contractSaved ? (
                  <TextInput
                    disabled={contractSaved}
                    label={
                      <span>
                        {contract_end_date.title}
                        {contract_end_date.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    type="date"
                    min={get(
                      this.props,
                      "process.data.fields.contract_start_date.value",
                      null
                    )}
                    openToDate={
                      get(
                        this.props,
                        "process.data.fields.contract_end_date.value"
                      )
                        ? new Date(
                            moment(
                              get(
                                this.props,
                                "process.data.fields.contract_end_date.value" // open to entered end date if available
                              )
                            ).format("YYYY/MM/DD")
                          )
                        : get(
                            this.props,
                            "process.data.fields.contract_start_date.value"
                          )
                        ? new Date(
                            moment(
                              get(
                                this.props,
                                "process.data.fields.contract_start_date.value" // open to entered start date if available
                              )
                            ).format("YYYY/MM/DD")
                          )
                        : new Date() // open to today
                    }
                    placeholder={this.props.intl.formatMessage({
                      id: "process > placeholder select date",
                    })}
                    value={
                      process.data.fields[contract_end_date.field]
                        ? process.data.fields[contract_end_date.field].value
                        : ""
                    }
                    onChange={(value) => {
                      setShouldBlockNavigation(true);
                      this.onValueChanged(contract_end_date, value);
                      if (
                        !get(
                          this.props,
                          "process.data.fields.order_end_date.value"
                        ) &&
                        value
                      ) {
                        this.onValueChanged(order_end_date, value);
                      }
                    }}
                  />
                ) : (
                  <div>
                    <Label>{contract_end_date.title}</Label>
                    {process.data.fields[contract_end_date.field]
                      ? formatDisplayDate(
                          process.data.fields[contract_end_date.field].value
                        )
                      : ""}
                  </div>
                )}
                <div className="alert-danger">
                  {validationState[contract_end_date.field] &&
                    validationState[contract_end_date.field].validation_error}
                </div>
              </Col>
            </Row>
          )}

          {/* <Row>
            <Col className={bem.e("col-field")}>
              {!contractSaved ? (
                <>
                  <TextInput
                    disabled={contractSaved}
                    label={
                      <span>
                        {due_date.title}
                      </span>
                    }
                    excludeDates={ get(process, 'data.fields[due_date.field].value') ? [get(process, 'data.fields[due_date.field].value')] : []}
                    min={moment().format('YYYY-MM-DD')}
                    type="date"
                    value={
                      process.data.fields[due_date.field]
                        ? process.data.fields[due_date.field].value
                        : ""
                    }
                    placeholder={this.props.intl.formatMessage({
                      id: "process > placeholder select date",
                    })}
                    onChange={(value) => {
                      this.onValueChanged(due_date, value);
                      
                      // if (process.data.fields.order_start_date && Date.parse(value) > Date.parse(process.data.fields.order_start_date.value))
                      //   this.setState({ isOpenDueDateModal: true });

                      if (typeof setShouldBlockNavigation === 'function') {
                        // eslint-disable-next-line no-undef
                        setShouldBlockNavigation(true); 
                      }
                    }}
                  />
                </>
                
              ) : (
                <div>
                  <Label>{due_date.title}</Label>
                  {process.data.fields[due_date.field]
                    ? formatDisplayDate(
                        process.data.fields[due_date.field].value
                      )
                    : ""}
                </div>
              )}
              <div className="alert-danger">
                {validationState[due_date.field] &&
                  validationState[due_date.field].validation_error}
              </div>
            </Col>
            <Col className={bem.e("col-field")}></Col>
          </Row> */}

          <Row>
            <Col xs={6} className={bem.e("col-field")}>
              <Dropdown
                name="role_account_executive"
                {...role_account_executive.props}
                options={role_account_executive_options}
                placeholder={this.props.intl.formatMessage({
                  id: "process > placeholder select",
                })}
                label={
                  <span>
                    {role_account_executive.title}
                    {role_account_executive.mandatory && <Asterisk>*</Asterisk>}
                  </span>
                }
                value={
                  process.data.fields[role_account_executive.field]
                    ? select_account_executive_value
                    : ""
                }
                onChange={(selectedOption) => {
                  setShouldBlockNavigation(true);
                  const value = selectedOption
                    ? {
                        id: selectedOption.value,
                        name: selectedOption.label,
                        role: selectedOption.role,
                      }
                    : null;

                  this.onValueChanged(role_account_executive, value);
                }}
                error={
                  validationState[role_account_executive.field] &&
                  validationState[role_account_executive.field].validation_error
                }
              />
            </Col>
            <Col className={bem.e("col-field")} xs={6}>
              {!get(auth, "info.enable_wo", false) ? (
                <Dropdown
                  isClearable={true}
                  {...contract_category.props}
                  name="product_category"
                  label={
                    <span>
                      <FormattedMessage id="process > field product category" />
                      {contract_category.mandatory && <Asterisk>*</Asterisk>}
                    </span>
                  }
                  placeholder={this.props.intl.formatMessage({
                    id: "process > placeholder select category",
                  })}
                  value={
                    get(process, `data.fields.${contract_category.field}.value`)
                      ? {
                          label: (
                            <FormattedMessage
                              id={`process > option label ${lowerCase(
                                process.data.fields[contract_category.field]
                                  .value
                              )}`}
                            />
                          ),
                          value:
                            process.data.fields[contract_category.field].value,
                        }
                      : null
                  }
                  onChange={(event) => {
                    setShouldBlockNavigation(true);
                    this.onValueChanged(
                      contract_category,
                      get(event, "value", "")
                    );
                  }}
                  error={
                    validationState[contract_category.field] &&
                    validationState[contract_category.field].validation_error
                  }
                />
              ) : (
                <TextInput
                  label={
                    <span>
                      {contract_category.title}
                      {contract_category.mandatory && <Asterisk>*</Asterisk>}
                    </span>
                  }
                  value={
                    process.data.fields[contract_category.field]
                      ? process.data.fields[contract_category.field].value
                      : ""
                  }
                  placeholder={this.props.intl.formatMessage({
                    id: "process > placeholder enter category",
                  })}
                  onChange={(event) => {
                    setShouldBlockNavigation(true);
                    this.onValueChanged(
                      contract_category,
                      get(event, "target.value", "")
                    );
                  }}
                  error={
                    validationState[contract_category.field] &&
                    validationState[contract_category.field].validation_error
                  }
                />
              )}
            </Col>
          </Row>
        </SectionFields>
      </ShadowBox>
    );
  }

  formattedValueOrEmpty(value, item) {
    if (get(item, "formatted")) {
      const formatted = get(item, "formatted");
      switch (formatted) {
        case "number":
          return (
            <NumberFormat
              prefix={get(item, "prefix", null)}
              value={value}
              displayType={"text"}
              thousandSeparator={true}
            />
          );
        case "phone":
          return (
            <NumberFormat
              value={value}
              displayType={"text"}
              format={PhoneNumberFormat}
            />
          );
        case "percentage":
          return (
            <NumberFormat
              value={value}
              displayType={"text"}
              suffix={get(item, "suffix", RotationFormatSuffix)}
            />
          );
        default:
          break;
      }
    }
    return value;
  }

  valueOrEmpty(item) {
    let { process, intl } = this.props;
    let value = null;
    if (has(process, `data.fields.${item.field}.value.name`)) {
      value = process.data.fields[item.field].value.name || "";
    } else if (get(process, `data.fields.${item.field}.value`)) {
      value = process.data.fields[item.field].value;
    } else {
      return intl.formatMessage({ id: "process > none" });
    }
    return this.formattedValueOrEmpty(value, item);
  }

  renderSoldView() {
    const { process } = this.props;

    const {
      client_name,
      agency_name,
      contract_start_date,
      contract_end_date,
      // due_date,
      contract_client_contact,
      contract_client_email,
      contract_client_nickname,
      contract_client_phone,
      contract_category,
      role_account_executive,
    } = this.getFieldRefs();

    let spots = [];
    const isSpecOrder = get(process, "data.fields.order_sold.value") === false;
    const relatedSpots = get(process, "data.fields.related_spots.value", []);
    let prevOrNextSpot = false;
    let passedCurrent = false;
    if (
      process.data.fields.key &&
      this.props.process.data.fields.spots &&
      this.props.process.data.fields.spots.value.length > 1
    ) {
      this.props.process.data.fields.spots.value.map((spot, ind) => {
        if (
          spot !== process.data.fields.key.value &&
          (!passedCurrent || !prevOrNextSpot)
        )
          prevOrNextSpot = spot;
        else passedCurrent = true;
        const spotData = relatedSpots.find((item) => item.id === spot);
        spots.push({
          key: spot,
          component: (
            <span key={ind}>
              {ind + 1}. {upperFirst(spotData.order_title)}
            </span>
          ),
        });
        return true;
      });
    }
    let valueOrEmpty = this.valueOrEmpty.bind(this);

    return (
      <ShadowBox className={bem.e("box-client-details")}>
        <SectionTitle className={bem.e("section-title")}>
          <FormattedMessage id="process > title client details" />
        </SectionTitle>
        <OrderClientDetails
          client_name={client_name}
          intl={this.props.intl}
          contract_category={contract_category}
          agency_name={agency_name}
          contract_client_contact={contract_client_contact}
          contract_client_nickname={contract_client_nickname}
          contract_client_email={contract_client_email}
          contract_client_phone={contract_client_phone}
          setIsFieldsChanged={this.props.setIsFieldsChanged}
          onSelectContact={this.onSelectContact}
          onSelectAgency={this.onSelectAgency}
          process={process}
          onValueChanged={this.onValueChanged}
          isView={true}
          valueOrEmpty={valueOrEmpty}
        />
        <SectionFields className={bem.e("section-fields")}>
          {!isSpecOrder ? (
            <>
              <Row>
                <Col xs={6} className={bem.e("col-view-field")}>
                  <Label>{contract_start_date.title}</Label>
                  <span className={bem.e("order-view-value")}>
                    {formatDisplayDate(valueOrEmpty(contract_start_date))}
                  </span>
                </Col>

                <Col xs={6} className={bem.e("col-view-field")}>
                  <Label>{contract_end_date.title}</Label>
                  <span className={bem.e("order-view-value")}>
                    {formatDisplayDate(valueOrEmpty(contract_end_date))}
                  </span>
                </Col>
              </Row>
              {/* <Row>
                <Col xs={6} className={bem.e("col-view-field")}>
                  <Label>{due_date.title}</Label>
                  <span className={bem.e("order-view-value")}>
                    {formatDisplayDate(valueOrEmpty(due_date))}
                  </span>
                </Col>

                <Col xs={6} className={bem.e("col-view-field")}>
                  
                </Col>
              </Row> */}
            </>
          ) : null}

          <Row>
            <Col className={bem.e("col-view-field")}>
              <Label>{role_account_executive.title}</Label>
              <span className={bem.e("order-view-value")}>
                {valueOrEmpty(role_account_executive)}
              </span>
            </Col>
            <Col className={bem.e("col-view-field")}>
              <Label>{contract_category.title}</Label>
              <span className={bem.e("order-view-value")}>
                {valueOrEmpty(contract_category)}
              </span>
            </Col>
          </Row>
        </SectionFields>
      </ShadowBox>
    );
  }

  render() {
    let form = null;
    if (this.props.isView) {
      form = this.renderSoldView();
    } else {
      form = this.renderSoldForm();
    }
    return form;
  }
}
ClientDetails.defaultProps = {
  isView: false,
  validationState: {},
  valueChanged: () => {},
};
ClientDetails.propTypes = {
  isView: PropTypes.bool,
  validationState: PropTypes.any,
  valueChanged: PropTypes.func,
};
export default ClientDetails;
