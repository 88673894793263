import React, { useState } from "react";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import {
  get,
  find,
  toLower,
  indexOf,
  includes,
  map,
  isEmpty,
  intersection,
} from "lodash";
import { FaSortDesc, FaSortAsc } from "react-icons/lib/fa";
import DeadlineTimer from "components/DeadlineTimer";
import { Table } from "reactstrap";
import { getSortUserName } from "utils/helpers";
import Avatar from "components/Avatar";
import ConfirmationModal from "components/ConfirmationModal";
import EmptyAnnouncement from "components/EmptyAnnouncement";
import NoItemsFound from "components/NoItemsFound";
import useInfiniteScroll from "utils/useInfiniteScroll";
import { BlockIcon } from "components/CustomIcons";

import { default_templates } from "utils/defaults";
import moment from "moment";
import { titleDisplay } from "utils/helpers";

const CommentAvatar = (props) => {
  const { bem, user, isShowAvatar } = props;
  return (
    <div className={bem.e("comment-display")}>
      <div
        className={classnames(bem.e("comment-avatar"), {
          "avatar-sort-name": !isShowAvatar,
        })}
      >
        {isShowAvatar ? (
          <img src={user.avatar_url} alt={user.name} />
        ) : (
          <span>{getSortUserName(get(user, "name", ""))}</span>
        )}
      </div>
    </div>
  );
};
const View = ({
  actionSort,
  sort,
  processList,
  bem,
  onClickRow,
  filter,
  hasMoreItems,
  loadItems,
  isLoading,
  search,
  user,
  intl,
  dateFormatByServer,
}) => {
  const [takeOverOrder, setTakeOverOrder] = useState(null);
  const [isOpenTakeOverModal, setIsOpenTakeOverModal] = useState(null);

  const commentDisplay = (process) => {
    const lastAction = get(process, "_source.last_action");
    if (!lastAction) return null;
    // It SHOULD display the commenter's avatar in the recipients Current Tasks list.
    if (
      /*filter === 'my_action_items' && */ get(lastAction, "m_type") === "chat"
    ) {
      const commenter = get(lastAction, "from", {});
      const isShowAvatar =
        commenter.avatar_url &&
        indexOf(commenter.avatar_url, "avatar_white_circle") === -1;
      return (
        commenter.id !== user.id &&
        (includes(get(lastAction, "tagged", []), user.id) ||
          !isEmpty(
            intersection(
              get(lastAction, "tagged", []),
              map(user.has_access_from_users, (user) => user.id)
            )
          )) && (
          <CommentAvatar
            isShowAvatar={isShowAvatar}
            bem={bem}
            user={commenter}
          />
        )
      );
    }
    /*const mentioned = get(lastAction, "tagged_users.length", 0) > 0;
    if (mentioned) {
      return map(lastAction.tagged_users, (item, index) => {
        const isShowAvatar = item.avatar_url && indexOf(item.avatar_url, "avatar_white_circle") === -1
        return <CommentAvatar isShowAvatar={isShowAvatar} bem={bem} user={item} key={index} />;
      });
    }*/
    return null;
  };
  const timeToAirDisplay = (process) => {
    if (get(process, "_source.order_start_date")) {
      return moment(process._source.order_start_date, "YYYY-MM-DD").format(
        dateFormatByServer
      );
    }
    return "";
  };

  const typeDisplay = (process) => {
    const template_key = get(process, "_source.template_key", true);

    switch (template_key) {
      case "digital":
        const forMarketingChannel = get(
          process,
          "_source.for_marketing_channel",
          {}
        );
        const marketingChannel = find(
          get(process, "_source.marketing_channels", []),
          (channel) => channel.id === forMarketingChannel.id
        );
        const media_type = get(marketingChannel, "media_type.value", "");
        switch (media_type) {
          case "Social Media":
          case "Banner Ad":
          case "Video Ad":
          case "Text Ad":
            return (
              <div
                className={classnames(
                  bem.e("type"),
                  {
                    [bem.e("type-digital")]: true,
                  },
                  bem.e(`type-digital-${media_type.split(" ").join("")}`)
                )}
              >
                <FormattedMessage id={"process > digital " + media_type} />
              </div>
            );
          default:
            return (
              <div
                className={classnames(bem.e("type"), {
                  [bem.e("type-digital")]: true,
                })}
              >
                <FormattedMessage id={"process > digital"} />
              </div>
            );
        }
      case "promotion":
        return (
          <div
            className={classnames(bem.e("type"), {
              [bem.e("type-sold")]: true,
            })}
          >
            <FormattedMessage id={"process > promotion"} />
          </div>
        );
      default:
        const orderSold = get(process, "_source.order_sold", true);
        const usersGranted = process._source.users_granted_me_access
          ? Object.values(process._source.users_granted_me_access)
          : [];
        return (
          <div
            className={classnames(bem.e("type"), {
              [bem.e("type-sold")]: orderSold,
              [bem.e("type-spec")]: !orderSold,
            })}
          >
            <FormattedMessage
              id={orderSold ? "process > order sold" : "process > order spec"}
            />
            {map(usersGranted, (u) => (
              <Avatar
                src={u.avatar_url}
                name={u.name}
                size={24}
                id={u.id}
                className="avatar-small"
              />
            ))}
          </div>
        );
    }
  };
  const statusDisplay = (process) => {
    const processStep = get(process, "_source.process_step", "draft");
    let row_template = default_templates.find(
      (t) => t._id === get(process, "_source.template_key", "basic_production")
    );
    let step = get(row_template, `_source.steps.${processStep}`);
    let title =
      get(process, "_source.imported_from_wo", false) &&
      get(process, "_source.template_selected_for_wo", false) == "" ? (
        <FormattedMessage id={"process > title wo draft"} />
      ) : (
        <FormattedMessage id={"process > title draft"} />
      );
    let color = "#9AA4CA";
    if (title !== "draft") {
      color = get(step, "caption_color", "#9AA4CA");
    }
    if (get(step, "title")) {
      title = get(step, "title");
      if (step.key === "approve_recording") {
        if (get(process, "_source.sent_to_client_for_spot_review")) {
          title = <FormattedMessage id={"process > title client review"} />;
        }
      }
      if (step.key === "approve_script") {
        if (get(process, "_source.sent_to_client_for_script_review")) {
          title = <FormattedMessage id={"process > title client review"} />;
        }
      }
      if (step.key === "order_details") {
        if (get(process, "_source.order_details_status") === "draft")
          title = "draft";
      }
      if (step.key === "record_script") {
        if (get(process, "_source.approve_recording_status") === "rejected") {
          title = <FormattedMessage id={"process > title spot change"} />;
          color = "#E40061";
        }
      }
      if (step.key === "write_script") {
        if (get(process, "_source.approve_script_status") === "rejected")
          title = <FormattedMessage id={"process > title script change"} />;
        color = "#E40061";
      }
      if (step.key === "assign_vo") {
        title = <FormattedMessage id={"process > title assign vo"} />;
        color = "#A82AC0";
      }
      if (step.key === "voice_over") {
        if (get(process, "_source.voice_over_status") === "rejected") {
          title = <FormattedMessage id={"process > title change request"} />;
          color = "#E40061";
        } else {
          title = <FormattedMessage id={"process > title voice over"} />;
        }
      }
      if (step.key === "continuity") {
        if (get(process, "_source.continuity_status") === "change_request") {
          title = <FormattedMessage id={"process > title change request"} />;
          color = "#E40061";
        }
      }
    }
    if (process._source.template_key === "digital") {
      switch (get(process, "_source.process_step")) {
        case "order_details":
          if (get(process, "_source.order_details_status") === "rejected")
            title = <FormattedMessage id={"process > title change request"} />;
          break;
        case "approve_order":
          title = <FormattedMessage id={"process > title approval"} />;
          break;
        case "design":
          title = "PRODUCTION";
          if (get(process, "_source.design_status") === "rejected") {
            title = <FormattedMessage id={"process > title change request"} />;
            color = "#E40061";
          }
          break;
        case "review_media":
          title = <FormattedMessage id={"process > title digital review"} />;
          break;
        default:
          break;
      }
    }
    return (
      <span
        className={classnames(
          bem.e("order-status"),
          bem.e(
            `order-status-${
              title.replace ? toLower(title.replace(/ /g, "-")) : ""
            }`
          )
        )}
        style={{ color }}
      >
        {title}
      </span>
    );
  };


  const dueDateDisplay = (process) => {
    // 	Deadline should be none for Reports task
    if (get(process, "_source.process_step") === "report")
      return <FormattedMessage id="process > none" />;
    const timezone = get(process, "_source.timezone", null);
    const stepDeadline = get(process, "_source.step_deadline");
    if (stepDeadline && filter === "my_action_items") {
      return <DeadlineTimer date={stepDeadline} timezone={timezone} />;
    }
    const orderStartDate = get(process, "_source.order_start_date");
    const dueDate = get(process, "_source.due_date");
    const date = dueDate || orderStartDate;
    if (!date) return null;
    return <DeadlineTimer date={date} timezone={timezone} />;
  };

  const renderEmpty = () => {
    if (filter === "my_action_items" && !search) {
      return <EmptyAnnouncement />;
    } else {
      return <NoItemsFound />;
    }
  };
  const orderLengthDisplay = (process) => {
    const orderLengthCustom = get(process, "_source.order_length_custom");
    let orderLength = get(process, "_source.order_length") || "00";
    if (orderLengthCustom) {
      const momentTime = moment.utc(parseInt(orderLength) * 1000);
      const formatted = momentTime.format(
        momentTime.minutes() > 0 ? "mm:ss" : ":ss"
      );
      return formatted;
    }
    return process._source.order_length
      ? `:${process._source.order_length}`
      : "";
  };

  const isDigitalOrder = filter === "digital_queue";
  useInfiniteScroll({
    useWindow: false,
    elementId: "cr-app-main",
    onLoadMore: loadItems,
    shouldLoadMore: hasMoreItems,
  });
  return (
    <div className={bem.e("table-wrapper")}>
      <Table className={bem.e("table")}>
        <thead>
          <tr>
            <th
              onClick={() => actionSort("order_sold")}
              className={classnames(
                "can-click",
                "col-table-10",
                bem.e("table-first-head")
              )}
            >
              <div className={bem.e("item-sortable")}>
                <FormattedMessage id="process > Type" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_sold",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_sold desc",
                    })}
                  />
                </div>
              </div>
            </th>

            {/* <th onClick={() => actionSort("client_name")} className="col-table-20 can-click">
              <div className={bem.e("item-sortable")}>
                <FormattedMessage id="process > Client" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "client_name"
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "client_name desc"
                    })}
                  />
                </div>
              </div>
            </th> */}
            <th
              onClick={() => actionSort("order_title")}
              className="col-table-20 can-click"
            >
              <div className={bem.e("item-sortable")}>
                <FormattedMessage id="process > client/title" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_title",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_title desc",
                    })}
                  />
                </div>
              </div>
            </th>
            {!isDigitalOrder && (
              <th
                onClick={() => actionSort("order_length")}
                className="col-table-10 can-click"
              >
                <div className={bem.e("item-sortable")}>
                  <FormattedMessage id="process > length" />
                  <div className={bem.e("sortable")}>
                    <FaSortAsc
                      className={classnames(bem.e("sortable-icon"), {
                        [bem.e("sortable-active")]: sort === "order_length",
                      })}
                    />
                    <FaSortDesc
                      className={classnames(bem.e("sortable-icon"), {
                        [bem.e("sortable-active")]:
                          sort === "order_length desc",
                      })}
                    />
                  </div>
                </div>
              </th>
            )}

            <th className="col-table-15">
              <FormattedMessage id="process > comments" />
            </th>
            <th
              onClick={() => actionSort("order_due_date")}
              className="col-table-10 can-click"
            >
              <div className={bem.e("item-sortable")}>
                <FormattedMessage id="process > field due date" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_due_date",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        sort === "order_due_date desc",
                    })}
                  />
                </div>
              </div>
            </th>
            <th
              onClick={() => actionSort("order_start_date")}
              className="col-table-10 can-click"
            >
              <div className={bem.e("item-sortable")}>
                <FormattedMessage id="process > Deadline" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_start_date",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        sort === "order_start_date desc",
                    })}
                  />
                </div>
              </div>
            </th>
            <th
              onClick={() => actionSort("process_step")}
              className="col-table-15 can-click"
            >
              <div className={bem.e("item-sortable-center")}>
                <FormattedMessage id="process > Status" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "process_step",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "process_step desc",
                    })}
                  />
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {processList.length === 0 && !isLoading ? (
            <tr>
              <td colSpan="7">{renderEmpty()}</td>
            </tr>
          ) : (
            processList.map((process) => {
              if (!process._source.template_key) return null;
              const beingEditedBy = get(
                process,
                "_source.being_edited_by",
                false
              );

              let accessGrantedTo = get(
                user,
                "access_granted_to_users",
                []
              ).map((user) => user.id);
              let hasAccessFrom = get(user, "has_access_from_users", []).map(
                (user) => user.id
              );
              const isLocked =
                beingEditedBy &&
                beingEditedBy.id !== user.id &&
                (includes(accessGrantedTo, beingEditedBy.id) ||
                  includes(hasAccessFrom, beingEditedBy.id));

              return (
                <tr
                  key={process._id}
                  onClick={() => {
                    if (isLocked) {
                      setTakeOverOrder(process);
                      setIsOpenTakeOverModal(true);
                    } else {
                      onClickRow(process);
                    }
                  }}
                >
                  {/* type */}
                  <td
                    className={classnames(
                      "align-middle col-table-10 align-middle",
                      bem.e("first-col")
                    )}
                  >
                    <span
                      className={classnames(
                        bem.e("td-value"),
                        bem.e("type-block")
                      )}
                    >
                      {typeDisplay(process)}
                      {isLocked && <BlockIcon />}
                    </span>
                  </td>
                  {/* client */}
                  {/* <td
                    className={classnames(
                      "align-middle col-table-20",
                      bem.e("client-name")
                    )}
                  >
                    <span className={bem.e("td-value")}>
                      {process._source.client_name}
                    </span>
                  </td> */}
                  {/* title */}
                  <td
                    className={classnames(
                      "align-middle col-table-20",
                      bem.e("order-title")
                    )}
                  >
                    <span className={bem.e("td-value")}>
                      <span className={"small"}>
                        {process._source.client_name}
                      </span>
                      {titleDisplay(process)}
                      {/* {spot} */}
                    </span>
                  </td>
                  {/* order_length */}
                  {!isDigitalOrder && (
                    <td className="align-middle col-table-10">
                      <span className={bem.e("td-value")}>
                        {orderLengthDisplay(process)}
                      </span>
                    </td>
                  )}
                  {/* comment */}
                  <td className={classnames("align-middle col-table-15")}>
                    <div
                      className={classnames("align-middle", bem.e("comment"))}
                    >
                      <span className={bem.e("mentions")}>
                        {commentDisplay(process)}
                      </span>
                    </div>
                  </td>
                  {/* due date */}
                  <td className="align-middle col-table-10">
                    <span className={bem.e("td-value")}>
                      {dueDateDisplay(process)}
                    </span>
                  </td>
                  {/* time to air */}
                  <td className="align-middle col-table-10">
                    <span className={bem.e("td-value")}>
                      {timeToAirDisplay(process)}
                    </span>
                  </td>
                  {/* status */}
                  <td className="align-middle col-table-15 text-center">
                    <span className={bem.e("td-value")}>
                      {statusDisplay(process)}
                    </span>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </Table>
      <ConfirmationModal
        isOpen={isOpenTakeOverModal}
        title={
          takeOverOrder
            ? intl.formatMessage(
                {
                  id: "process > title take over order",
                },
                {
                  NAME:
                    takeOverOrder && takeOverOrder._source.being_edited_by
                      ? takeOverOrder._source.being_edited_by.name
                      : "",
                }
              )
            : ""
        }
        isCloseOutside={false}
        className={bem.e("confirmation-modal")}
        confirmTitle={intl.formatMessage({
          id: "process > button take over",
        })}
        cancelTitle={intl.formatMessage({
          id: "process > button cancel",
        })}
        onConfirm={() => {
          onClickRow(takeOverOrder);
          setTakeOverOrder(null);
          setIsOpenTakeOverModal(false);
        }}
        onToggle={() => setIsOpenTakeOverModal(false)}
        onCancel={() => {
          setIsOpenTakeOverModal(false);
        }}
      />
    </div>
  );
};
export default View;
