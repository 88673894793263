import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Input, Label } from "reactstrap";
import { isFunction } from "lodash";
import NumberFormat from "react-number-format";
import bn from "utils/bemnames";
const bem = bn.create("textinput");

class View extends React.Component {
  static propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    error: PropTypes.string,
    name: PropTypes.string,
    leftComponent: PropTypes.node,
    rightComponent: PropTypes.node,
    labelProps: PropTypes.object,
    format: PropTypes.string,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    thousandSeparator: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    disabled: PropTypes.bool,
    isAllowed: PropTypes.any,
    allowNegative: PropTypes.bool,
    placeholder: PropTypes.string,
    className: PropTypes.string,
  };

  static defaultProps = {
    type: "text",
    labelProps: {},
    leftComponent: null,
    rightComponent: null,
    prefix: "",
    format: null,
    suffix: "",
    thousandSeparator: "",
    disabled: false,
    allowNegative: true,
    placeholder: "",
    className: "",
  };

  renderRightComponent = () => {
    const { rightComponent } = this.props;
    if (!rightComponent) {
      return null;
    }
    return <div className={bem.e("right-component")}>{rightComponent}</div>;
  };

  renderLeftComponent = () => {
    const { leftComponent } = this.props;
    if (!leftComponent) {
      return null;
    }
    return <div className={bem.e("left-component")}>{leftComponent}</div>;
  };

  render() {
    const {
      label,
      error,
      name,
      labelProps,
      type,
      format,
      prefix,
      thousandSeparator,
      onChange,
      value,
      disabled,
      suffix,
      isAllowed,
      allowNegative,
      placeholder,
      className,
      decimalScale,
    } = this.props;
    return (
      <div className={cx(bem.b(), className)}>
        {!!label && (
          <Label for={name} {...labelProps}>
            {label}
          </Label>
        )}
        <div
          className={cx(bem.e("input-wrapper"), {
            // invalid: Boolean(error),
            textarea: type === "textarea"
          })}
          style={this.props.style}
        >
          {this.renderLeftComponent()}
          <div
            className={cx(bem.e("input-field"), {
              textarea: type === "textarea"
            })}
          >
            <NumberFormat
              disabled={disabled}
              customInput={Input}
              thousandSeparator={thousandSeparator}
              prefix={prefix}
              suffix={suffix}
              format={format}
              value={value || ""}
              allowNegative={allowNegative}
              isAllowed={isAllowed}
              decimalScale={decimalScale}
              placeholder={placeholder}
              onValueChange={values => {
                const newValue = values.value;
                if(newValue && value && newValue.toString() === value.toString()) return
                if (isFunction(onChange)) onChange(newValue);
              }}
            />
          </div>
          {this.renderRightComponent()}
        </div>
        {!!error && <span className="text-danger">{error}</span>}
      </div>
    );
  }
}

export default View;
