import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { get } from "lodash";
import ServerFormSection from "./ServerFormSection";
import { Form, FormGroup, Button, Row, Col, Label } from "reactstrap";
import TextInput from "components/TextInput";

class SecurityPolicyConfiguration extends Component {

  renderEdit = () => {
    const {
      bem,
      formProps,
      intl,
      isSecurityPolicyModalOpen,
      serverDetails,
      toggleSecurityPolicyModalOpen,
    } = this.props;
    const { values, touched, errors } = formProps;
    return (
      <div className={bem.e("security-content-form")}>
        <Row>
          <Col>
            <FormGroup>
              <TextInput
                label={
                  <FormattedMessage id="user > password complexity pattern" />
                }
                type="text"
                name="password_complexity_pattern"
                value={values.password_complexity_pattern}
                error={
                  touched.password_complexity_pattern &&
                  errors.password_complexity_pattern
                }
                onChange={formProps.handleChange}
                onBlur={formProps.handleBlur}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <TextInput
                label={
                  <FormattedMessage id="user > replace password days" />
                }
                type="text"
                name="replace_password_days"
                value={values.replace_password_days}
                error={
                  touched.replace_password_days &&
                  errors.replace_password_days
                }
                onChange={formProps.handleChange}
                onBlur={formProps.handleBlur}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <TextInput
                label={
                  <FormattedMessage id="user > reuse password days" />
                }
                type="text"
                name="reuse_password_days"
                value={values.reuse_password_days}
                error={
                  touched.reuse_password_days &&
                  errors.reuse_password_days
                }
                onChange={formProps.handleChange}
                onBlur={formProps.handleBlur}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <TextInput
                label={<FormattedMessage id="user > domain filter" />}
                name="domain_filter"
                value={values.domain_filter}
                error={touched.domain_filter && errors.domain_filter}
                onChange={formProps.handleChange}
                onBlur={formProps.handleBlur}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <TextInput
                label={
                  <FormattedMessage id="user > system idle timeout" />
                }
                name="system_idle_timeout"
                value={values.system_idle_timeout}
                error={
                  touched.system_idle_timeout &&
                  errors.system_idle_timeout
                }
                onChange={formProps.handleChange}
                onBlur={formProps.handleBlur}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  };

  renderView = () => {
    const { bem, serverDetails } = this.props;
    return (
      <div className={bem.e("security-content-form")}>
        <Row>
          <Col xs={12} className={bem.e("col-reset")}>
            <FormGroup className={bem.e("form-group-view")}>
              <Label className={bem.e("label")}>
                <FormattedMessage id="user > password complexity pattern" />
              </Label>
              <div className={bem.e("field-view-value")}>
                {serverDetails.password_complexity_pattern ? (
                  serverDetails.password_complexity_pattern
                ) : (
                  <FormattedMessage id="user > none" />
                )}
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col className={bem.e("col-reset")}>
            <FormGroup className={bem.e("form-group-view")}>
              <Label className={bem.e("label")}>
                <FormattedMessage id="user > replace password days" />
              </Label>
              <div className={bem.e("field-view-value")}>
                {serverDetails.replace_password_days ? (
                  serverDetails.replace_password_days
                ) : (
                  <FormattedMessage id="user > none" />
                )}
              </div>
            </FormGroup>
          </Col>
          <Col className={bem.e("col-reset")}>
            <FormGroup className={bem.e("form-group-view")}>
              <Label className={bem.e("label")}>
                <FormattedMessage id="user > reuse password days" />
              </Label>
              <div className={bem.e("field-view-value")}>
                {serverDetails.reuse_password_days ? (
                  serverDetails.reuse_password_days
                ) : (
                  <FormattedMessage id="user > none" />
                )}
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col className={bem.e("col-reset")}>
            <FormGroup className={bem.e("form-group-view")}>
              <Label className={bem.e("label")}>
                <FormattedMessage id="user > domain filter" />
              </Label>
              <div className={bem.e("field-view-value")}>
                {serverDetails.domain_filter ? (
                  serverDetails.domain_filter
                ) : (
                  <FormattedMessage id="user > none" />
                )}
              </div>
            </FormGroup>
          </Col>
          <Col className={bem.e("col-reset")}>
            <FormGroup className={bem.e("form-group-view")}>
              <Label className={bem.e("label")}>
                <FormattedMessage id="user > system idle timeout" />
              </Label>
              <div className={bem.e("field-view-value")}>
                {serverDetails.system_idle_timeout ? (
                  serverDetails.system_idle_timeout
                ) : (
                  <FormattedMessage id="user > none" />
                )}
              </div>
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  };

  render() {
    const { bem, edit, formProps } = this.props;
    return (
      <ServerFormSection
        bem={bem}
        titleId="server > server security policy"
        sectionClass={bem.e("box-server-details")}
        sectionFieldsClass={bem.e("section-view-server-configuration-fields")}
      >
        {edit ? this.renderEdit() : this.renderView()}
      </ServerFormSection>
    )
  }
}

export default injectIntl(SecurityPolicyConfiguration);