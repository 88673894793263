import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { FormattedMessage } from "react-intl";
import bn from "utils/bemnames";
import ChangePasswordForm from "components/ChangePasswordForm";
import { CloseIcon } from "components/CustomIcons";
const bem = bn.create("change-password-modal");

const ChangePasswordModal = (props) => {
  const { onToggle, isOpen, title, isAuthChangePassword, user } = props;
  return (
    <Modal isOpen={isOpen} className={bem.b()} size="lg">
      <ModalHeader toggle={onToggle} className={bem.e("header")}>
        {title}
        <button className={bem.e("close")} onClick={onToggle}>
          <CloseIcon color={"#657894"} />
        </button>
      </ModalHeader>
      <ModalBody className={bem.e("body")}>
        <ChangePasswordForm
          onCancel={onToggle}
          user={user}
          isAuthChangePassword={isAuthChangePassword}
        />
      </ModalBody>
    </Modal>
  );
};
ChangePasswordModal.defaultProps = {
  isOpen: false,
  isAuthChangePassword: false,
  onToggle: () => {},
  title: <FormattedMessage id="change password > modal title" />,
};
ChangePasswordModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  title: PropTypes.any.isRequired,
  isAuthChangePassword: PropTypes.bool,
  user: PropTypes.object.isRequired,
};
export default ChangePasswordModal;
