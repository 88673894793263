import React, { useEffect, useState } from "react";
import { keys, map, sortBy, first, find, orderBy, get } from "lodash";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import bn from "utils/bemnames";
import CalendarHour from "../Common/CalendarHour";
import CalendarFloating from "../Common/CalendarFloating";
import CalendarInline from "../Common/CalendarInline";
import classNames from "classnames";
import ToggleSwitch from "components/ToggleSwitch";

const bem = bn.create("jock-console-left-sidebar");
const closest = (numbers, needle) => {
  return numbers
    .sort((a, b) => {
      return Math.abs(needle - a) - Math.abs(needle - b);
    })
    .filter((item) => item >= needle);
};
function getClosestItem(tasks) {
  const localUserHour = moment().format("HH:mm");
  const localUserHourToMinutes = moment.duration(localUserHour).asMinutes();
  const taskMinutes = map(tasks, (item) => item.schedule.start_time_in_minutes);
  const sortedClosest = closest(taskMinutes, localUserHourToMinutes);
  const sortedClosestItem = first(sortedClosest);
  if (!sortedClosestItem) return null;
  const task = find(
    tasks,
    (item) => item.schedule.start_time_in_minutes === sortedClosestItem
  );
  return task;
}
const checkIsScrollable = () => {
  const eleContainer = document.getElementById("calendar-tasks");
  const eleFloatingContainer = document.getElementById("floating-tasks");

  const verticalScroll = eleContainer.scrollHeight > eleContainer.clientHeight;
  eleFloatingContainer.classList.remove("floating-bottom");
  eleContainer.classList.remove("has-floating-bottom");
  if (verticalScroll) {
    eleContainer.classList.add("has-floating-bottom");
    eleFloatingContainer.classList.add("floating-bottom");
  }
};
function getItems({ schedules, selectedType }) {
  const hourKeys = sortBy(keys(schedules || []));
  let closestTask;
  const hourItems = hourKeys.map((hour) => {
    const tasks = schedules[hour].map((schedule) => ({
      schedule,
      title: schedule.order_title,
      subtitle: schedule.station_name,
      isDone: schedule.completed || false,
      hour,
    }));
    let order = 2;
    if (
      hour !== "completed" &&
      hour !== "incomplete" &&
      !closestTask &&
      selectedType === "hour"
    ) {
      closestTask = getClosestItem(tasks);
    }
    if (hour === "incomplete") order = 1;
    if (hour === "completed") order = 0;
    return {
      hour,
      tasks,
      isDone: hour === "completed",
      isIncomplete: hour === "incomplete",
      order,
      group: selectedType,
    };
  });
  const items = orderBy(hourItems, ["order"], ["desc"]);
  return { items, closestTask, hourKeys };
}
export default function LeftSidebar(props) {
  const {
    selectedDate,
    setSelectedDate,
    schedules,
    selectedSchedule,
    setSelectedSchedule,
    floatingContestSchedules,
    selectedType,
    setSelectedType,
    currentAction,
    setCurrentAction,
  } = props;
  // open floating by default
  const [isOpenFloating, setIsOpenFloating] = useState(true);
  const { items, closestTask, hourKeys } = getItems({
    schedules,
    selectedType,
  });
  const floatingTasks =
    floatingContestSchedules.length > 0
      ? {
          hour: "floating",
          tasks: map(floatingContestSchedules, (schedule) => ({
            schedule: {
              ...schedule,
              floating_contest: true,
            },
            title: schedule.order_title,
            subtitle: schedule.station_name,
            hour: "floating",
          })),
        }
      : null;
  const hasFloatingTasks = !!floatingTasks;
  const isSelectedDatePast = moment(selectedDate).diff(moment(), "days");
  useEffect(() => {
    if (closestTask && !selectedSchedule) {
      setSelectedSchedule(closestTask);
    }
  }, [closestTask]);
  useEffect(() => {
    // active next task
    if (currentAction === "noWinner") {
      const item = items.find((item) => item.hour === selectedSchedule.hour);
      const tasks = get(item, "tasks", []);
      const taskIndex = tasks.findIndex(
        (i) => i.schedule.id === selectedSchedule.schedule.id
      );

      let nextTask = get(tasks, taskIndex + 1);
      if (!nextTask) {
        const itemIndex = items.findIndex(
          (item) => item.hour === selectedSchedule.hour
        );
        // get next task from next hour
        const itemNext = get(items, itemIndex + 1);
        if (itemNext && !["completed", "incomplete"].includes(itemNext.hour)) {
          nextTask = first(itemNext.tasks);
        }
      }
      if (nextTask && !["completed", "incomplete"].includes(nextTask.hour)) {
        setSelectedSchedule(nextTask);
      }
      setCurrentAction("");
    }
  }, [currentAction]);
  useEffect(() => {
    if (!isOpenFloating && hasFloatingTasks) setIsOpenFloating(true);
  }, [setSelectedDate]);
  return (
    <div className={bem.b()}>
      <div className={bem.e("calendar-picker")}>
        <CalendarInline
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      </div>
      <ToggleSwitch
        containerClassName={bem.e("calendar-filter")}
        leftComponent={<FormattedMessage id="jock console > hour" />}
        rightComponent={<FormattedMessage id="jock console > station" />}
        name="selectedType"
        switchProps={{
          checked: selectedType !== "hour",
          onChange: () => {
            setSelectedType(selectedType === "hour" ? "station" : "hour");
          },
          offColor: "#795AFA",
          onColor: "#795AFA",
          uncheckedIcon: false,
          checkedIcon: false,
          boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.282)",
          height: 26.44,
          width: 48.48,
          handleDiameter: 22.92,
        }}
      />
      <div className={bem.e("calendar-hour-tasks")}>
        <div
          className={classNames(bem.e("calendar-tasks"), "scroll-bar-style")}
          id="calendar-tasks"
        >
          {!!hourKeys.length ? (
            map(items, (item) => (
              <CalendarHour
                setSelectedSchedule={setSelectedSchedule}
                selectedSchedule={selectedSchedule}
                {...item}
                key={item.hour}
                hasFloatingTasks={hasFloatingTasks}
                selectedType={selectedType}
                isPast={isSelectedDatePast < 0}
                checkIsScrollable={checkIsScrollable}
              />
            ))
          ) : (
            <div className={bem.e("no-tasks")}>
              <FormattedMessage id="jock console > no tasks for today" />
            </div>
          )}
        </div>
        {hasFloatingTasks && (
          <CalendarFloating
            setSelectedSchedule={setSelectedSchedule}
            selectedSchedule={selectedSchedule}
            {...floatingTasks}
            isOpen={isOpenFloating}
            setIsOpen={(value) => setIsOpenFloating(value)}
            key={floatingTasks.hour}
            checkIsScrollable={checkIsScrollable}
          />
        )}
      </div>
    </div>
  );
}
