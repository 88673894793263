import React from "react";
import bn from "utils/bemnames";
import HubAssignments from "./HubAssignments";
import ShowAssignments from "./ShowAssignments";
import EntityAssignments from "./EntityAssignments";
import FormAssignments from "./FormAssignments";
import { Button } from "reactstrap";
import { FormattedMessage } from "react-intl";
import Spinner from "components/Spinner";
import { getRoleIdByName } from "utils/helpers";

const bem = bn.create("user-assignments");

export default function UserAssignments(props) {
  const { isView, user, setIsView, hubs, isLoading } = props;
  const isShowHubAssignments = !!getRoleIdByName(
    "Hub Manager",
    user.enterprise_roles
  );
  if (isView) {
    return (
      <div className={bem.b()}>
        {isShowHubAssignments && (
          <React.Fragment>
            <HubAssignments isView={isView} user={user} />
          </React.Fragment>
        )}
        <div className={bem.e("view-content")}>
          <EntityAssignments isView={isView} user={user} />
          <ShowAssignments isView={isView} user={user} />
        </div>
        <div className={bem.e("buttons")}>
          <Button
            color="blue"
            className="btn btn-radius"
            onClick={() => {
              setIsView(false);
            }}
          >
            {isView ? (
              <FormattedMessage id={`user > button edit`} />
            ) : (
              <FormattedMessage id={`user > button submit`} />
            )}
          </Button>
          {props.backButton}
        </div>
      </div>
    );
  }
  return (
    <div className={bem.b()}>
      <FormAssignments
        {...props}
        isShowHubAssignments={isShowHubAssignments}
        hubs={hubs}
      />
      <Spinner isLoading={isLoading} />
    </div>
  );
}
