import { connect } from "react-redux";
import { compose, lifecycle, withHandlers, withState } from "recompose";
import { get } from "lodash";
import { injectIntl } from "react-intl";
import WinnersList from "./view";
import {
  getWinnersByFilter,
  pickGrandPrizeWinner,
} from "store/actions/winners";
import { setActiveTab } from "store/actions/processes";
import { pageSize } from "utils/config";
import { setSidebarActive } from "store/actions/auth";

const Enhanced = compose(
  connect(
    (state, props) => {
      const filter = "grand_prize_winner";
      const searchData = get(
        state,
        `winners.filterWinners.filter_${filter}`,
        {}
      );
      return {
        filter,
        searchData,
        isDirty: get(searchData, "is_dirty", false),
        hasMore:
          parseInt(get(searchData, "pages", 1)) >
          parseInt(get(searchData, "page", 1)),
        selectedStation: get(
          props.process,
          "data.fields.order_stations_array.value.0"
        ),
        ...props,
      };
    },
    {
      getWinnersByFilter,
      setSidebarActive,
      setActiveTab,
      pickGrandPrizeWinner,
    }
  ),
  withState("filtered", "setFiltered", []),
  withState("sorted", "setSorted", { id: "created_ts", desc: true }),
  withState("selectedItems", "setSelectedItems", []),
  withHandlers({
    getItemsList: ({
      getWinnersByFilter,
      sorted,
      filtered,
      selectedStation,
      filter,
      process,
    }) => (params = {}) => {
      getWinnersByFilter({
        filter,
        pageSize,
        page: 1,
        sort: `${sorted.id} ${sorted.desc ? "desc" : "asc"}`,
        filtered,
        station: get(selectedStation, "value", ""),
        contest_id: get(process, "data.fields.key.value", ""),
        grand_prize_winner: true,
        ...params, //override
      });
    },
    getMoreItems: ({
      getWinnersByFilter,
      sorted,
      filtered,
      searchData,
      selectedStation,
      filter,
      process,
    }) => () => {
      const page = parseInt(get(searchData, "page", 1));
      getWinnersByFilter({
        filter,
        pageSize,
        page: page + 1,
        sort: `${sorted.id} ${sorted.desc ? "desc" : "asc"}`,
        filtered,
        station: get(selectedStation, "value", ""),
        contest_id: get(process, "data.fields.key.value", ""),
        grand_prize_winner: true,
      });
    },
  }),
  withHandlers({
    onSort: ({ setSorted, sorted, getItemsList }) => (field) => {
      let newSort = {
        id: field,
        desc: !sorted.desc,
      };
      let sortString = `${newSort.id} ${newSort.desc ? "desc" : "asc"}`;
      // detect third click
      if (sorted.id === field && sorted.desc && !newSort.desc) {
        sortString = `updated_ts desc`;
        newSort = {
          id: "updated_ts",
          desc: true,
        };
      }
      setSorted(newSort);
      getItemsList({
        sort: sortString,
      });
    },
    onSearch: ({ getItemsList }) => () => {
      getItemsList({
        page: 1,
      });
    },
    onPickWinnerForOffAir: ({
      pickGrandPrizeWinner,
      process,
      getItemsList,
    }) => () => {
      pickGrandPrizeWinner(
        {
          contest_id: get(process, "data.fields.key.value", ""),
          type: "off_air",
        },
        () => {
          getItemsList();
        }
      );
    },
  }),
  lifecycle({
    componentDidMount() {
      if (this.props.selectedStation) this.props.getItemsList();
    },
    componentDidUpdate(prevProps) {
      if (
        this.props.isDirty ||
        get(this.props, "selectedStation.key") !==
          get(prevProps, "selectedStation.key")
      ) {
        this.props.getItemsList();
      }
    },
  })
)(WinnersList);
export default injectIntl(Enhanced);
