import * as React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import {
  get,
  lowerCase,
  isEqual,
  includes,
  orderBy,
  delay,
  find,
  map,
  join,
  has,
} from "lodash";
import moment from "moment";
import TextInput from "components/TextInput";
import ToggleSwitch from "components/ToggleSwitch";
import Dropdown from "components/Dropdown";
import { FormattedMessage } from "react-intl";
import {
  formatDisplayDate,
  getEditableTabByUser,
  getEditableTabsByStage,
} from "utils/helpers";
import { DeleteIcon } from "components/CustomIcons";
import ToastManager from "components/ToastManager";
import bn from "utils/bemnames";
import "moment-timezone";
import {
  Row,
  Col,
  Form,
  Button,
  Input,
  Label,
  ButtonGroup,
  FormGroup,
  UncontrolledTooltip,
} from "reactstrap";
import NumberFormat from "react-number-format";
import SelectAudience from "components/SelectAudience";

import history from "components/History";
import {
  ShadowBox,
  Asterisk,
  SectionTitle,
  SectionFields,
} from "components/Elements";
import PrivilegedComponent from "components/PrivilegedComponent";
import { PRIVILEGES } from "utils/constants";
import { PhoneNumberFormat, RotationFormatSuffix } from "utils/config";
import ConfirmationModal from "components/ConfirmationModal";
import TrafficDetails from "components/TrafficDetails";
import OrderClientDetails from "components/OrderClientDetails";
import URL from "utils/urls";

const bem = bn.create("digital-order-details");
class DigitalOrderDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      contacts: [],
      isOpenDialog: false,
      isOpenDialogDelete: false,
      isOpenDialogSubmitted: false,
      fieldRefs: false,
    };
  }

  static propTypes = {
    mode: PropTypes.string,
  };

  static defaultProps = {
    mode: "edit",
  };

  componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps.info) !== JSON.stringify(this.props.info)) {
      this.setState({ fieldRefs: false });
      return;
    }
    for (
      let i = 0;
      i < Object.keys(nextProps.process.data.fields).length;
      i++
    ) {
      let key = Object.keys(nextProps.process.data.fields)[i];
      let changed = false;
      if (
        !nextProps.process.data.fields[key] &&
        !this.props.process.data.fields[key]
      )
        continue;
      else if (
        !nextProps.process.data.fields[key] &&
        this.props.process.data.fields[key]
      )
        changed = true;
      else if (
        nextProps.process.data.fields[key] &&
        !this.props.process.data.fields[key]
      )
        changed = true;
      else if (
        !nextProps.process.data.fields[key].value &&
        !this.props.process.data.fields[key].value
      )
        continue;
      else if (
        !nextProps.process.data.fields[key].value &&
        this.props.process.data.fields[key].value
      )
        changed = true;
      else if (
        nextProps.process.data.fields[key].value &&
        !this.props.process.data.fields[key].value
      )
        changed = true;
      else if (
        nextProps.process.data.fields[key].value.toString() !==
        this.props.process.data.fields[key].value.toString()
      )
        changed = true;
      else if (
        !isEqual(
          get(nextProps, `process.data.fields.${key}.value`),
          get(this.props, `process.data.fields.${key}.value`)
        )
      ) {
        changed = true;
      }
      if (changed) {
        if (
          [
            "client_id",
            "contract_stations",
            "contract_stations_order_number_array",
            "key",
            "order_internal",
          ].indexOf(key) > -1
        ) {
          this.setState({ fieldRefs: false });
          break;
        }
      }
    }
  }

  componentDidMount() {
    this.props.getTemplate(
      get(this.props.template, "key", "digital"),
      this.props.auth.info
    );
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if (
      get(this.props.template, "key", "digital") !==
      get(prevProps.template, "key", "digital")
    ) {
      this.props.getTemplate(
        get(this.props.template, "key", "digital"),
        this.props.auth.info
      );
    }
  }

  setIsFieldsChanged = (isFieldsChanged) => {
    if (this.state.isFieldsChanged !== isFieldsChanged) {
      this.setState({ isFieldsChanged });
    }
  };
  onCheckBoxChange = (item, process, template, user, checked) => {
    this.props.checkboxChanged(item, process, template, user, {
      target: { checked },
    });
  };
  renderSoldSpecSwitch() {
    const { process, step, template, user } = this.props;
    let order_internal = step.fields.find((f) => f.field === "order_internal");
    const isChecked = get(
      process,
      `data.fields.${order_internal.field}.value`,
      false
    );
    return (
      <FormGroup className={bem.e("switch-order")}>
        <ToggleSwitch
          leftComponent={
            <span className={!isChecked ? "switch-active-text" : "switch-text"}>
              {this.props.intl.formatMessage({ id: "process > Client" })}
            </span>
          }
          rightComponent={
            <span className={isChecked ? "switch-active-text" : "switch-text"}>
              {this.props.intl.formatMessage({ id: "process > internal" })}
            </span>
          }
          switchProps={{
            onChange: (checked) => {
              this.onCheckBoxChange(
                order_internal,
                process,
                template,
                user,
                checked
              );
              this.setIsFieldsChanged(true);
            },
            disabled: false,
            checked: isChecked,
            uncheckedIcon: false,
            checkedIcon: false,
            offColor: "#795AFA",
            onColor: "#795AFA",
            handleDiameter: 22,
            width: 50,
            height: 26,
          }}
        />
      </FormGroup>
    );
  }
  getFieldRefs() {
    if (this.state.fieldRefs) return this.state.fieldRefs;
    let {
      process,
      template,
      user,
      step,
      valueChanged,
      // getRoleCandidates
    } = this.props;

    step = template.steps.order_details;
    let client_name = step.fields.find((f) => f.field === "client_name");
    let client_id = step.fields.find((f) => f.field === "client_id");
    let contract_start_date = step.fields.find(
      (f) => f.field === "contract_start_date"
    );
    let contract_end_date = step.fields.find(
      (f) => f.field === "contract_end_date"
    );
    // let due_date = step.fields.find(
    //   (f) => f.field === "due_date"
    // );

    let contract_client_contact = step.fields.find(
      (f) => f.field === "contract_client_contact"
    );
    let contract_client_email = step.fields.find(
      (f) => f.field === "contract_client_email"
    );
    let contract_client_nickname = step.fields.find(
      (f) => f.field === "contract_client_nickname"
    );
    let contract_client_phone = step.fields.find(
      (f) => f.field === "contract_client_phone"
    );
    let requested_by = step.fields.find((f) => f.field === "requested_by");

    let contract_stations = step.fields.find(
      (f) => f.field === "contract_stations"
    );
    let contract_stations_order_number_array = step.fields.find(
      (f) => f.field === "contract_stations_order_number_array"
    );

    let contract_category = step.fields.find(
      (f) => f.field === "contract_category"
    );
    let order_title = step.fields.find((f) => f.field === "order_title");

    const campaign_schedule = step.fields.find(
      (f) => f.field === "campaign_schedule"
    );

    let contact_id = step.fields.find((f) => f.field === "contact_id");

    // must have order stations
    if (!this.props.process.data.fields.contract_stations) {
      let init_stations = []; // this.props.info.stations[0].station_key
      this.props.valueChanged(
        { field: "contract_stations" },
        this.props.process,
        this.props.template,
        this.props.user,
        { value: init_stations }
      );
    }
    // set default for current user name into requested by field
    if (
      get(process, "data.fields.order_internal.value", false) &&
      !get(process, "data.fields.requested_by.value")
    ) {
      valueChanged(requested_by, process, template, user, {
        value: user.name,
      });
    }

    let timezone = step.fields.find((f) => f.field === "timezone");

    let contractSaved = process.data.fields.contract_id !== undefined;
    let payload = {
      client_name,
      client_id,
      contract_start_date,
      contract_end_date,
      // due_date,
      contract_client_contact,
      contract_client_email,
      contract_client_nickname,
      contract_client_phone,
      contract_stations,
      contract_stations_order_number_array,
      contract_category,
      order_title,
      contractSaved,
      timezone,
      requested_by,
      campaign_schedule,
      contact_id,
    };

    this.setState({ fieldRefs: payload });
    return payload;
  }

  onValueChanged = (field, value) => {
    const { valueChanged, template, user } = this.props;
    valueChanged(field, process, template, user, { value });
    this.setIsFieldsChanged(true);
  };

  onValueTimezoneChanged = (orderStations) => {
    const { auth } = this.props;
    const { timezone } = this.getFieldRefs();
    let timezoneValue;
    let stations = get(auth, "info.stations", []).filter(
      (station) => station.station_key
    );

    if (orderStations.length === 1) {
      const selectStation = orderStations[0];
      const stationWithData = stations.find(
        (item) => item.station_key === selectStation
      );
      if (stationWithData) {
        timezoneValue = stationWithData.timezone;
      }
    } else {
      let listTimezone = [];
      stations.forEach((item) => {
        if (
          includes(orderStations, item.station_key) &&
          item.timezone &&
          !includes(listTimezone, item.timezone)
        ) {
          const gmt = moment.tz(item.timezone).format("Z");
          listTimezone.push({
            key: parseInt(gmt),
            gmt: gmt,
            timezone: item.timezone,
          });
        }
      });

      listTimezone = orderBy(listTimezone, ["key"], ["desc"]);
      timezoneValue = get(listTimezone, `0.timezone`);
    }
    if (!timezoneValue) {
      timezoneValue = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    this.onValueChanged(timezone, timezoneValue);
  };

  onSetCampaignSchedule = ({ endDate, startDate }) => {
    const { campaign_schedule } = this.getFieldRefs();
    const { process } = this.props;
    // set default campaign schedule

    const campaignSchedule = get(
      process,
      "data.fields.campaign_schedule.value",
      null
    );
    let params = {};
    if (!campaignSchedule) {
      params = {
        days: [2, 3, 4, 5, 6],
        end_date: endDate,
        months_type: "Every",
        months_value: "",
        start_date: startDate,
        weekdays: true,
        weekends: false,
        weeks_type: "Every",
        weeks_value: "",
      };
    } else {
      params = {
        ...campaignSchedule,
        end_date: campaignSchedule.end_date || endDate,
        start_date: campaignSchedule.start_date || startDate,
      };
    }
    this.onValueChanged(campaign_schedule, params);
  };
  onClickSubmit = (event, isDraft = false) => {
    const { process, step, template, user } = this.props;
    let isValidCustomForm = false;
    // trigger click for submit custom form
    get(process, "data.fields.marketing_channels.value", []).forEach(
      (channel) => {
        const buttonSubmit = document.getElementById(
          `custom-form-${channel.id}`
        );
        buttonSubmit && buttonSubmit.click();
      }
    );
    delay(
      () => {
        const customFormValid = get(
          process,
          "data.fields.marketing_channels.value",
          []
        ).filter((channel) => {
          const buttonSubmit = document.getElementById(
            `custom-form-${channel.id}`
          );
          if (!buttonSubmit) {
            return true;
          }
          isValidCustomForm = buttonSubmit.classList.contains("is-valid");
          return isValidCustomForm;
        });
        isValidCustomForm =
          customFormValid.length ===
          get(process, "data.fields.marketing_channels.value.length", 0);
        if (!isValidCustomForm && !isDraft) {
          ToastManager.show({
            title: this.props.intl.formatMessage({
              id: "toast > title not saved",
            }),
            message: this.props.intl.formatMessage({
              id: "toast > message error please correct the hilighted fields",
            }),
            level: "error",
          });
        } else {
          let itemButton = {
            ...step.fields.find((f) => f.key === "save_button"),
          };
          if (isDraft) {
            itemButton.draft = true;
          }
          this.props.buttonClicked(
            step,
            itemButton,
            process,
            template,
            user,
            null,
            null,
            false
          );
        }
      },
      500,
      "later"
    );
  };
  onSelectContact = (contact) => {
    const {
      contract_client_contact,
      contract_client_email,
      contract_client_nickname,
      contract_client_phone,
      contact_id,
    } = this.getFieldRefs();
    const { valueChanged, template, user } = this.props;

    valueChanged(contract_client_contact, process, template, user, {
      value: get(contact, "contract_client_contact", ""),
    });

    if (get(contact, "contact_id", "")) {
      valueChanged(contract_client_email, process, template, user, {
        value: get(contact, "contract_client_email", ""),
      });
      valueChanged(contract_client_nickname, process, template, user, {
        value: get(contact, "contract_client_nickname", ""),
      });
      valueChanged(contract_client_phone, process, template, user, {
        value: get(contact, "contract_client_phone", ""),
      });
      valueChanged(contact_id, process, template, user, {
        value: get(contact, "contact_id", ""),
      });
    } else {
      valueChanged(contract_client_email, process, template, user, {
        value: "",
      });
      valueChanged(contract_client_nickname, process, template, user, {
        value: "",
      });
      valueChanged(contract_client_phone, process, template, user, {
        value: "",
      });
      valueChanged(contact_id, process, template, user, {
        value: "",
      });
    }

    this.setIsFieldsChanged(true);
  };

  renderEditButton = () => {
    const { template, step, process, user } = this.props;
    const isTabCanEditByStage = getEditableTabsByStage(step, process, user);
    const {
      isAssigned,
      isEditor,
      isUserCompletedStep,
      isUserAssignedToStepRoles,
      isStepCanEditAnyTime,
      isChannelManager,
    } = getEditableTabByUser({
      step,
      process,
      user,
      template: template.key,
    });
    let isShowEditButton = false;
    const processStep = get(process, "data.fields.process_step.value");
    const isDraft = processStep === "draft";
    const isTabCanEditByUser = isAssigned && isEditor;
    if (
      (isTabCanEditByStage && isTabCanEditByUser) || // case when user go to the tab from current tasks
      (isEditor && isUserAssignedToStepRoles && isStepCanEditAnyTime) || // case order details can edit any time by config
      (isEditor && isDraft) || // case when order is still in draft should be to be edited by editor
      (isChannelManager && !isDraft) ||
      (isEditor && isUserCompletedStep)
    ) {
      // case when user assigned to step by role and completed this step
      isShowEditButton = true;
    }
    const isDisabled = !isShowEditButton;
    return (
      <div>
        <Button
          color="blue"
          className="button-edit button-edit-auto"
          id="edit-button-row"
          disabled={isDisabled}
          onClick={() => {
            history.push(
              "/processes/edit/" +
                template.key +
                "/" +
                get(process, "data.fields.key.value")
            );
            this.props.setActiveEditTab(step.key);
          }}
        >
          <FormattedMessage id="process > button edit" />
        </Button>
        {isDisabled && (
          <UncontrolledTooltip
            placement="top-start"
            delay={0}
            target="edit-button-row"
          >
            <FormattedMessage id="process > button edit disabled" />
          </UncontrolledTooltip>
        )}
      </div>
    );
  };

  renderForm() {
    let {
      process,
      template,
      user,
      step,
      valueChanged,
      validationState,
      multipleStationSelectionChanged,
      auth,
      setShouldBlockNavigation,
    } = this.props;
    const isAdd =
      document.location.pathname.split("/")[2] === "add" ? true : false;
    let {
      client_name,
      client_id,
      contract_start_date,
      contract_end_date,
      // due_date,
      contract_client_contact,
      contract_client_email,
      contract_client_nickname,
      contract_client_phone,
      contract_stations,
      contract_stations_order_number_array,
      contract_category,
      order_title,
      contractSaved,
      requested_by,
      contact_id,
    } = this.getFieldRefs();
    let bindedValueChanged = valueChanged.bind(this);
    const hasContractStations =
      process.data.fields[contract_stations.field] &&
      process.data.fields[contract_stations.field].value &&
      process.data.fields[contract_stations.field].value.length > 0;
    const processStep = get(process, "data.fields.process_step.value");
    const isDraft = processStep === "draft";
    const stationsData = get(auth, "info.stations", []);

    const renderForClient = () => (
      <ShadowBox className={bem.e("section")}>
        <SectionTitle className={bem.e("section-title")}>
          <FormattedMessage id="process > title order information" />
        </SectionTitle>
        <OrderClientDetails
          validationState={validationState}
          client_name={client_name}
          intl={this.props.intl}
          template={template}
          user={user}
          client_id={client_id}
          contact_id={contact_id}
          contract_category={contract_category}
          order_title={order_title}
          contract_client_contact={contract_client_contact}
          contract_client_nickname={contract_client_nickname}
          contract_client_email={contract_client_email}
          contract_client_phone={contract_client_phone}
          bindedValueChanged={bindedValueChanged}
          setIsFieldsChanged={this.setIsFieldsChanged}
          onSelectContact={this.onSelectContact}
          onSelectAgency={this.onSelectAgency}
          process={process}
          isEnableEnterprise={false}
          onValueChanged={this.onValueChanged}
          isView={false}
          isWoEnabled={false}
          setShouldBlockNavigation={setShouldBlockNavigation}
        />
        <SectionFields
          className={classnames(bem.e("section-fields"), "p-top-0")}
        >
          <Row>
            <Col className={bem.e("col-view-field")}>
              {!contractSaved ? (
                <TextInput
                  disabled={contractSaved}
                  label={
                    <span>
                      {contract_start_date.title}
                      {contract_start_date.mandatory && <Asterisk>*</Asterisk>}
                    </span>
                  }
                  type="date"
                  value={
                    process.data.fields[contract_start_date.field]
                      ? process.data.fields[contract_start_date.field].value
                      : ""
                  }
                  allowShowIcon={true}
                  placeholder={this.props.intl.formatMessage({
                    id: "process > select date placeholder",
                  })}
                  onChange={(value) => {
                    this.onValueChanged(contract_start_date, value);
                    let endDate = get(
                      process,
                      "data.fields.contract_end_date.value",
                      ""
                    );
                    setShouldBlockNavigation(true);
                    this.onValueTimezoneChanged(
                      get(
                        this.props,
                        `process.data.fields.${contract_stations.field}.value`,
                        []
                      )
                    );
                    // clear contact end date if contact start date if after
                    if (
                      value &&
                      get(
                        process,
                        `data.fields.${contract_end_date.field}.value`
                      )
                    ) {
                      const isAfterEndDate = moment(value).isAfter(
                        process.data.fields[contract_end_date.field].value
                      );
                      if (isAfterEndDate) {
                        this.onValueChanged(contract_end_date, "");
                        endDate = "";
                      }
                    }
                    this.onSetCampaignSchedule({
                      endDate: endDate,
                      startDate: value,
                    });
                  }}
                />
              ) : (
                <div>
                  <Label>{contract_start_date.title}</Label>
                  {process.data.fields[contract_start_date.field]
                    ? formatDisplayDate(
                        process.data.fields[contract_start_date.field].value
                      )
                    : ""}
                </div>
              )}
              <div className="alert-danger">
                {validationState[contract_start_date.field] &&
                  validationState[contract_start_date.field].validation_error}
              </div>
            </Col>
            <Col className={bem.e("col-view-field")}>
              {!contractSaved ? (
                <TextInput
                  disabled={contractSaved}
                  label={
                    <span>
                      {contract_end_date.title}
                      {contract_end_date.mandatory && <Asterisk>*</Asterisk>}
                    </span>
                  }
                  allowShowIcon={true}
                  type="date"
                  placeholder={this.props.intl.formatMessage({
                    id: "process > select date placeholder",
                  })}
                  min={get(
                    this.props,
                    "process.data.fields.contract_start_date.value",
                    null
                  )}
                  value={
                    process.data.fields[contract_end_date.field]
                      ? process.data.fields[contract_end_date.field].value
                      : ""
                  }
                  onChange={(value) => {
                    this.onValueChanged(contract_end_date, value);
                    setShouldBlockNavigation(true);
                    const startDate = get(
                      process,
                      "data.fields.contract_start_date.value",
                      ""
                    );
                    this.onSetCampaignSchedule({
                      endDate: value,
                      startDate: startDate,
                    });
                  }}
                />
              ) : (
                <div>
                  <Label>{contract_end_date.title}</Label>
                  {process.data.fields[contract_end_date.field]
                    ? formatDisplayDate(
                        process.data.fields[contract_end_date.field].value
                      )
                    : ""}
                </div>
              )}
              <div className="alert-danger">
                {validationState[contract_end_date.field] &&
                  validationState[contract_end_date.field].validation_error}
              </div>
            </Col>
          </Row>
          {/* <Row>
            <Col className={bem.e("col-view-field")}>
              {!contractSaved ? (
                <TextInput
                  disabled={contractSaved}
                  label={
                    <span>
                      {due_date.title}
                      {due_date.mandatory && <Asterisk>*</Asterisk>}
                    </span>
                  }
                  type="date"
                  value={
                    process.data.fields[due_date.field]
                      ? process.data.fields[due_date.field].value
                      : ""
                  }
                  allowShowIcon={true}
                  placeholder={this.props.intl.formatMessage({
                    id: "process > field due date",
                  })}
                  onChange={(value) => {
                    this.onValueChanged(due_date, value);
                    if (typeof setShouldBlockNavigation === 'function') {
                      // eslint-disable-next-line no-undef
                      setShouldBlockNavigation(true); 
                    }
                  }}
                />
              ) : (
                <div>
                  <Label>{due_date.title}</Label>
                  {process.data.fields[due_date.field]
                    ? formatDisplayDate(
                        process.data.fields[due_date.field].value
                      )
                    : ""}
                </div>
              )}
              <div className="alert-danger">
                {validationState[due_date.field] &&
                  validationState[due_date.field].validation_error}
              </div>
            </Col>
            <Col className={bem.e("col-view-field")}></Col>
          </Row> */}
          <Row>
            <Col xs="12" lg="6" className={bem.e("col-field")}>
              <SelectAudience
                contract={true}
                validationState={validationState}
                item={contract_stations}
                label={contract_stations.title}
                disabled={contractSaved}
                {...contract_stations.props}
                checked={process.data.fields[contract_stations.field]}
                isMulti={true}
                value={get(
                  process,
                  `data.fields.${contract_stations.field}.value`
                )}
                placeholder={this.props.intl.formatMessage({
                  id: "process > placeholder select entities",
                })}
                allowCloseMenuOnSelect={false}
                className={"select-stations-hide-value"}
                template={template}
                isFilterByServerConfig={true}
                onChange={(selectedStations) => {
                  let isSelectedByDefault = false;
                  const value = map(selectedStations, (i) => {
                    // selected default value from SelectAudience component will return array object
                    if (typeof i === "object" && i.value) {
                      isSelectedByDefault = true;
                      return i.value;
                    }
                    return i;
                  });
                  if (!isSelectedByDefault) {
                    setShouldBlockNavigation(true);
                  }
                  multipleStationSelectionChanged(
                    contract_stations,
                    process,
                    template,
                    user,
                    value
                  );
                  this.onValueTimezoneChanged(value);
                  this.setIsFieldsChanged(true);
                }}
              />
            </Col>
            <Col className={bem.e("col-field")}>
              <Dropdown
                isClearable={true}
                {...contract_category.props}
                name="product_category"
                label={
                  <span>
                    <FormattedMessage id="process > field product category" />
                  </span>
                }
                value={
                  process.data.fields[contract_category.field]
                    ? {
                        label: (
                          <FormattedMessage
                            id={`process > option label ${lowerCase(
                              process.data.fields[contract_category.field].value
                            )}`}
                          />
                        ),
                        value:
                          process.data.fields[contract_category.field].value,
                      }
                    : ""
                }
                onChange={(event) => {
                  this.onValueChanged(
                    contract_category,
                    get(event, "value", "")
                  );
                  setShouldBlockNavigation(true);
                }}
                placeholder={this.props.intl.formatMessage({
                  id: "process > select category placeholder",
                })}
              />
            </Col>
          </Row>
          {hasContractStations && (
            <Row>
              <Col xs={12} className={bem.e("rotations")}>
                <Label>{contract_stations_order_number_array.title}</Label>
                {process.data.fields[contract_stations.field].value.map(
                  (station, index) => {
                    let num = "";
                    if (
                      process.data.fields[
                        contract_stations_order_number_array.field
                      ] !== undefined
                    ) {
                      let contract_stations_order_number_field =
                        process.data.fields[
                          contract_stations_order_number_array.field
                        ];
                      if (
                        contract_stations_order_number_field.value !== undefined
                      ) {
                        contract_stations_order_number_field.value.forEach(
                          (item) => {
                            if (item.station === station) {
                              num = item.number;
                            }
                          }
                        );
                      }
                    } else if (
                      process.data.fields["contract_stations_order_number"] !==
                      undefined
                    ) {
                      let contract_stations_order_number_field =
                        process.data.fields["contract_stations_order_number"];
                      if (
                        contract_stations_order_number_field.value !== undefined
                      ) {
                        num =
                          contract_stations_order_number_field.value[station];
                      }
                    }
                    const stationData = find(
                      stationsData,
                      (item) => item.station_key === station
                    );
                    let stationArr = [];
                    if (get(stationData, "call_letters", ""))
                      stationArr.push(stationData.call_letters);
                    if (get(stationData, "name", ""))
                      stationArr.push(stationData.name);
                    return (
                      <div className={bem.e("rotation-table")} key={index}>
                        <div className={bem.e("rotation-name")}>
                          {stationArr.length > 0 ? (
                            join(stationArr, " - ")
                          ) : (
                            <FormattedMessage id="process > none" />
                          )}
                        </div>
                        <div className={bem.e("rotation-value")}>
                          <Input
                            value={num}
                            placeholder={this.props.intl.formatMessage({
                              id: "process > placeholder order number",
                            })}
                            onChange={(event) => {
                              let val = event.target.value;
                              let vals = [];
                              if (
                                process.data.fields[
                                  contract_stations_order_number_array.field
                                ] !== undefined
                              ) {
                                let contract_stations_order_number_field =
                                  process.data.fields[
                                    contract_stations_order_number_array.field
                                  ];
                                if (
                                  contract_stations_order_number_field.value !==
                                  undefined
                                ) {
                                  vals =
                                    contract_stations_order_number_field.value;
                                }
                              } else if (
                                process.data.fields[
                                  "contract_stations_order_number"
                                ] !== undefined
                              ) {
                                let contract_stations_order_number_field =
                                  process.data.fields[
                                    "contract_stations_order_number"
                                  ];
                                if (
                                  contract_stations_order_number_field.value !==
                                  undefined
                                ) {
                                  for (var key in contract_stations_order_number_field.value) {
                                    vals.push({
                                      station: key,
                                      number:
                                        contract_stations_order_number_field
                                          .value[key],
                                    });
                                  }
                                }
                              }
                              let numberIndex = null;
                              vals.forEach((item, key) => {
                                if (item.station === station) {
                                  numberIndex = key;
                                }
                              });
                              if (numberIndex !== null) {
                                vals[numberIndex].number = val;
                              } else {
                                vals.push({ station: station, number: val });
                              }

                              this.onValueChanged(
                                contract_stations_order_number_array,
                                vals
                              );
                              setShouldBlockNavigation(true);
                              this.setIsFieldsChanged(true);
                            }}
                          />
                        </div>
                        <span
                          className={bem.e("delete-order-number")}
                          onClick={(e) => {
                            let vals = [];
                            if (
                              process.data.fields[
                                contract_stations_order_number_array.field
                              ] !== undefined
                            ) {
                              let contract_stations_order_number_field =
                                process.data.fields[
                                  contract_stations_order_number_array.field
                                ];
                              if (
                                contract_stations_order_number_field.value !==
                                undefined
                              ) {
                                vals =
                                  contract_stations_order_number_field.value;
                              }
                            } else if (
                              process.data.fields[
                                "contract_stations_order_number"
                              ] !== undefined
                            ) {
                              let contract_stations_order_number_field =
                                process.data.fields[
                                  "contract_stations_order_number"
                                ];
                              if (
                                contract_stations_order_number_field.value !==
                                undefined
                              ) {
                                for (var key in contract_stations_order_number_field.value) {
                                  vals.push({
                                    station: key,
                                    number:
                                      contract_stations_order_number_field
                                        .value[key],
                                  });
                                }
                              }
                            }

                            vals.forEach((item, key) => {
                              if (item.station === station) {
                                vals.splice(key, 1);
                              }
                            });

                            this.onValueChanged(
                              contract_stations_order_number_array,
                              vals
                            );
                            let value =
                              process.data.fields[contract_stations.field]
                                .value || [];
                            if (value.indexOf(station) > -1)
                              value.splice(value.indexOf(station), 1);
                            multipleStationSelectionChanged(
                              contract_stations,
                              process,
                              template,
                              user,
                              value
                            );
                            this.setIsFieldsChanged(true);
                            setShouldBlockNavigation(true);
                          }}
                        >
                          <DeleteIcon />
                        </span>
                      </div>
                    );
                  }
                )}
              </Col>
            </Row>
          )}
        </SectionFields>
      </ShadowBox>
    );
    const renderForInternal = () => (
      <ShadowBox className={bem.e("section")}>
        <SectionTitle className={bem.e("section-title")}>
          <FormattedMessage id="process > title order information" />
        </SectionTitle>
        <SectionFields className={bem.e("section-fields")}>
          <Row>
            <Col className={bem.e("col-field")}>
              <TextInput
                label={
                  <span>
                    {order_title.title}
                    {order_title.mandatory && <Asterisk>*</Asterisk>}
                  </span>
                }
                value={
                  process.data.fields[order_title.field]
                    ? process.data.fields[order_title.field].value
                    : ""
                }
                onChange={(event) => {
                  this.onValueChanged(
                    order_title,
                    get(event, "target.value", "")
                  );
                  setShouldBlockNavigation(true);
                }}
                placeholder={this.props.intl.formatMessage({
                  id: "process > field title placeholder",
                })}
              />
              <div className="alert-danger">
                {validationState[order_title.field] &&
                  validationState[order_title.field].validation_error}
              </div>
            </Col>
            <Col className={bem.e("col-field")}>
              {!contractSaved ? (
                <TextInput
                  disabled={contractSaved}
                  label={
                    <span>
                      <FormattedMessage id="process > field start date" />
                      {contract_start_date.mandatory && <Asterisk>*</Asterisk>}
                    </span>
                  }
                  type="date"
                  placeholder={this.props.intl.formatMessage({
                    id: "process > select date placeholder",
                  })}
                  value={
                    process.data.fields[contract_start_date.field]
                      ? process.data.fields[contract_start_date.field].value
                      : ""
                  }
                  allowShowIcon={true}
                  onChange={(value) => {
                    setShouldBlockNavigation(true);
                    this.onValueChanged(contract_start_date, value);
                    let endDate = get(
                      process,
                      "data.fields.contract_end_date.value",
                      ""
                    );
                    this.onSetCampaignSchedule({
                      endDate,
                      startDate: value,
                    });
                  }}
                />
              ) : (
                <div>
                  <Label>
                    <FormattedMessage id="process > field start date" />
                  </Label>
                  {process.data.fields[contract_start_date.field]
                    ? formatDisplayDate(
                        process.data.fields[contract_start_date.field].value
                      )
                    : ""}
                </div>
              )}
              <div className="alert-danger">
                {validationState[contract_start_date.field] &&
                  validationState[contract_start_date.field].validation_error}
              </div>
            </Col>
          </Row>
          <Row>
            <Col className={bem.e("col-field")}>
              <TextInput
                label={
                  <span>
                    {requested_by.title}
                    {requested_by.mandatory && <Asterisk>*</Asterisk>}
                  </span>
                }
                value={get(
                  process,
                  `data.fields.${requested_by.field}.value`,
                  user.name
                )}
                onChange={(event) => {
                  setShouldBlockNavigation(true);
                  this.onValueChanged(
                    requested_by,
                    get(event, "target.value", "")
                  );
                }}
                placeholder={this.props.intl.formatMessage({
                  id: "process > field requested by placeholder",
                })}
              />
              <div className="alert-danger">
                {validationState[requested_by.field] &&
                  validationState[requested_by.field].validation_error}
              </div>
            </Col>
            <Col className={bem.e("col-field")}>
              {!contractSaved ? (
                <TextInput
                  disabled={contractSaved}
                  label={
                    <span>
                      <FormattedMessage id="process > field end date" />
                      {contract_end_date.mandatory && <Asterisk>*</Asterisk>}
                    </span>
                  }
                  type="date"
                  placeholder={this.props.intl.formatMessage({
                    id: "process > select date placeholder",
                  })}
                  min={moment().format("YYYY-MM-DD")}
                  allowShowIcon={true}
                  value={
                    process.data.fields[contract_end_date.field]
                      ? process.data.fields[contract_end_date.field].value
                      : ""
                  }
                  onChange={(value) => {
                    setShouldBlockNavigation(true);
                    this.onValueChanged(contract_end_date, value);
                    let startDate = get(
                      process,
                      "data.fields.contract_start_date.value",
                      ""
                    );
                    this.onSetCampaignSchedule({
                      endDate: value,
                      startDate,
                    });
                  }}
                />
              ) : (
                <div>
                  <Label>
                    <FormattedMessage id="process > field end date" />
                  </Label>
                  {process.data.fields[contract_end_date.field]
                    ? formatDisplayDate(
                        process.data.fields[contract_end_date.field].value
                      )
                    : ""}
                </div>
              )}
              <div className="alert-danger">
                {validationState[contract_end_date.field] &&
                  validationState[contract_end_date.field].validation_error}
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="12" lg="6" className={bem.e("col-field")}>
              <SelectAudience
                contract={true}
                validationState={validationState}
                item={contract_stations}
                label={contract_stations.title}
                disabled={contractSaved}
                {...contract_stations.props}
                checked={process.data.fields[contract_stations.field]}
                placeholder={this.props.intl.formatMessage({
                  id: "process > placeholder select entities",
                })}
                isMulti={true}
                value={get(
                  process,
                  `data.fields.${contract_stations.field}.value`
                )}
                template={template}
                isFilterByServerConfig={true}
                allowCloseMenuOnSelect={false}
                className={"select-stations-hide-value"}
                onChange={(selectedStations) => {
                  const value = map(selectedStations, (i) => {
                    // selected default value from SelectAudience component will return array object
                    if (typeof i === "object" && i.value) return i.value;
                    return i;
                  });
                  setShouldBlockNavigation(true);
                  multipleStationSelectionChanged(
                    contract_stations,
                    process,
                    template,
                    user,
                    value
                  );
                  this.setIsFieldsChanged(true);
                }}
              />
            </Col>
          </Row>
          {hasContractStations && (
            <Row>
              <Col xs={12} className={bem.e("rotations")}>
                <Label>{contract_stations_order_number_array.title}</Label>
                {process.data.fields[contract_stations.field].value.map(
                  (station, index) => {
                    let num = "";
                    if (
                      process.data.fields[
                        contract_stations_order_number_array.field
                      ] !== undefined
                    ) {
                      let contract_stations_order_number_field =
                        process.data.fields[
                          contract_stations_order_number_array.field
                        ];
                      if (
                        contract_stations_order_number_field.value !== undefined
                      ) {
                        contract_stations_order_number_field.value.forEach(
                          (item) => {
                            if (item.station === station) {
                              num = item.number;
                            }
                          }
                        );
                      }
                    } else if (
                      process.data.fields["contract_stations_order_number"] !==
                      undefined
                    ) {
                      let contract_stations_order_number_field =
                        process.data.fields["contract_stations_order_number"];
                      if (
                        contract_stations_order_number_field.value !== undefined
                      ) {
                        num =
                          contract_stations_order_number_field.value[station];
                      }
                    }
                    const stationData = find(
                      stationsData,
                      (item) => item.station_key === station
                    );
                    let stationArr = [];
                    if (get(stationData, "call_letters", ""))
                      stationArr.push(stationData.call_letters);
                    if (get(stationData, "name", ""))
                      stationArr.push(stationData.name);
                    return (
                      <div className={bem.e("rotation-table")} key={index}>
                        <div className={bem.e("rotation-name")}>
                          {stationArr.length > 0 ? (
                            join(stationArr, " - ")
                          ) : (
                            <FormattedMessage id="process > none" />
                          )}
                        </div>
                        <div className={bem.e("rotation-value")}>
                          <Input
                            value={num}
                            placeholder={this.props.intl.formatMessage({
                              id: "process > placeholder order number",
                            })}
                            onChange={(event) => {
                              let val = event.target.value;
                              let vals = [];
                              if (
                                process.data.fields[
                                  contract_stations_order_number_array.field
                                ] !== undefined
                              ) {
                                let contract_stations_order_number_field =
                                  process.data.fields[
                                    contract_stations_order_number_array.field
                                  ];
                                if (
                                  contract_stations_order_number_field.value !==
                                  undefined
                                ) {
                                  vals =
                                    contract_stations_order_number_field.value;
                                }
                              } else if (
                                process.data.fields[
                                  "contract_stations_order_number"
                                ] !== undefined
                              ) {
                                let contract_stations_order_number_field =
                                  process.data.fields[
                                    "contract_stations_order_number"
                                  ];
                                if (
                                  contract_stations_order_number_field.value !==
                                  undefined
                                ) {
                                  for (var key in contract_stations_order_number_field.value) {
                                    vals.push({
                                      station: key,
                                      number:
                                        contract_stations_order_number_field
                                          .value[key],
                                    });
                                  }
                                }
                              }
                              let numberIndex = null;
                              vals.forEach((item, key) => {
                                if (item.station === station) {
                                  numberIndex = key;
                                }
                              });

                              if (numberIndex !== null) {
                                vals[numberIndex].number = val;
                              } else {
                                vals.push({ station: station, number: val });
                              }

                              this.onValueChanged(
                                contract_stations_order_number_array,
                                vals
                              );
                              this.setIsFieldsChanged(true);
                              setShouldBlockNavigation(true);
                            }}
                          />
                        </div>
                        <span
                          className={bem.e("delete-order-number")}
                          onClick={(e) => {
                            let vals = [];
                            if (
                              process.data.fields[
                                contract_stations_order_number_array.field
                              ] !== undefined
                            ) {
                              let contract_stations_order_number_field =
                                process.data.fields[
                                  contract_stations_order_number_array.field
                                ];
                              if (
                                contract_stations_order_number_field.value !==
                                undefined
                              ) {
                                vals =
                                  contract_stations_order_number_field.value;
                              }
                            } else if (
                              process.data.fields[
                                "contract_stations_order_number"
                              ] !== undefined
                            ) {
                              let contract_stations_order_number_field =
                                process.data.fields[
                                  "contract_stations_order_number"
                                ];
                              if (
                                contract_stations_order_number_field.value !==
                                undefined
                              ) {
                                for (var key in contract_stations_order_number_field.value) {
                                  vals.push({
                                    station: key,
                                    number:
                                      contract_stations_order_number_field
                                        .value[key],
                                  });
                                }
                              }
                            }

                            vals.forEach((item, key) => {
                              if (item.station === station) {
                                vals.splice(key, 1);
                              }
                            });

                            this.onValueChanged(
                              contract_stations_order_number_array,
                              vals
                            );
                            let value =
                              process.data.fields[contract_stations.field]
                                .value || [];
                            if (value.indexOf(station) > -1)
                              value.splice(value.indexOf(station), 1);
                            multipleStationSelectionChanged(
                              contract_stations,
                              process,
                              template,
                              user,
                              value
                            );
                            this.setIsFieldsChanged(true);
                            setShouldBlockNavigation(true);
                          }}
                        >
                          <DeleteIcon />
                        </span>
                      </div>
                    );
                  }
                )}
              </Col>
            </Row>
          )}
        </SectionFields>
      </ShadowBox>
    );
    const { isEditor } = getEditableTabByUser({
      step,
      process,
      user,
      template: template.key,
    });
    return (
      <div className={bem.e("order-form")}>
        {this.props.mode !== "view" &&
          document.location.href.indexOf("processes/add") > -1 &&
          this.renderSoldSpecSwitch()}
        <Form>
          {get(process, "data.fields.order_internal.value", false)
            ? renderForInternal()
            : renderForClient()}
          <TrafficDetails
            step={step}
            process={process}
            template={template}
            isView={false}
            user={user}
            validationState={validationState}
            valueChanged={this.props.valueChanged}
            setIsFieldsChanged={this.setIsFieldsChanged}
            setShouldBlockNavigation={setShouldBlockNavigation}
          />
          <div className="bottom-actions-sticky">
            <ButtonGroup className={classnames(bem.e("buttons"), "buttons")}>
              <div className="process-buttons-left">
                {/* button submit  */}
                {isEditor && (
                  <React.Fragment>
                    <Button
                      block
                      color="blue"
                      className="button-submit"
                      onClick={(event) => {
                        this.onClickSubmit(event);
                      }}
                    >
                      {/* Note: showing only submit as mimicking production flow */}
                      <FormattedMessage id="process > button submit" />
                    </Button>
                    <Button
                      block
                      color="blue"
                      outline
                      disabled={!hasContractStations}
                      className="button-save-draft"
                      onClick={(event) => {
                        this.onClickSubmit(event, true);
                      }}
                    >
                      <FormattedMessage id="process > button save draft" />
                    </Button>
                  </React.Fragment>
                )}
                {document.location.href.indexOf("/processes/edit/") === -1 &&
                  this.state.isFieldsChanged && (
                    <Button
                      color=""
                      className="process-link-clear-form"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setIsFieldsChanged(false);
                        setShouldBlockNavigation(false);
                        this.props.onPreparePage();
                      }}
                    >
                      <FormattedMessage id="process > button clear form" />
                    </Button>
                  )}
              </div>

              {/* button cancel */}
              {/* {!isAdd && (
									<Button
										block
										color="blue"
										outline
										className="button-cancel"
										onClick={() => {
											history.push(
												"/processes/view/" +
													template.key +
													"/" +
													process.key
											);
										}}
									>
										<FormattedMessage id="process > button cancel" />
									</Button>
								)} */}
              <div className="process-buttons-right">
                {!isAdd && !isDraft && (
                  <PrivilegedComponent
                    requires={{
                      or: [
                        PRIVILEGES.DELETE_PROCESSES,
                        PRIVILEGES.DELETE_DIGITAL,
                        PRIVILEGES.EDIT_ALL_DIGITAL_ORDERS,
                      ],
                    }}
                  >
                    <Button
                      color="blue"
                      outline
                      className="btn btn-radius"
                      onClick={() =>
                        this.setState({
                          isOpenDialogDelete: true,
                        })
                      }
                    >
                      <FormattedMessage id="process > button delete" />
                    </Button>
                  </PrivilegedComponent>
                )}
              </div>
            </ButtonGroup>
          </div>
        </Form>
        {/* delete modal */}
        <ConfirmationModal
          isOpen={this.state.isOpenDialogDelete}
          onToggle={() =>
            this.setState({
              isOpenDialogDelete: !this.state.isOpenDialogDelete,
            })
          }
          onCancel={() => this.setState({ isOpenDialogDelete: false })}
          title={<FormattedMessage id="process > confirm delete order" />}
          className="modal-delete"
          onConfirm={() => {
            this.setState({ isOpenDialogDelete: false });
            this.props.deleteProcess(
              "@andspots@" + get(process, "data.fields.key.value"),
              () => {
                const currentTasks = URL.PROCESSES({
                  filter: "my_action_items",
                });
                this.props.history.push(currentTasks);
              }
            );
          }}
          isCloseOutside={false}
        />
        <ConfirmationModal
          isOpen={this.state.isOpenDialogSubmitted}
          onToggle={() =>
            this.setState({
              isOpenDialogSubmitted: !this.state.isOpenDialogSubmitted,
            })
          }
          onCancel={() => this.setState({ isOpenDialogSubmitted: false })}
          state="success"
          title={<FormattedMessage id={`process > title deleted`} />}
        />
      </div>
    );
  }

  formattedValueOrEmpty(value, item) {
    if (get(item, "formatted")) {
      const formatted = get(item, "formatted");
      switch (formatted) {
        case "number":
          return (
            <NumberFormat
              prefix={get(item, "prefix", null)}
              value={value}
              displayType={"text"}
              thousandSeparator={true}
            />
          );
        case "phone":
          return (
            <NumberFormat
              value={value}
              displayType={"text"}
              format={PhoneNumberFormat}
            />
          );
        case "percentage":
          return (
            <NumberFormat
              value={value}
              displayType={"text"}
              suffix={get(item, "suffix", RotationFormatSuffix)}
            />
          );
        default:
          break;
      }
    }
    return value;
  }

  valueOrEmpty(item) {
    let { process, intl } = this.props;
    let value = null;
    if (has(process, `data.fields.${item.field}.value.name`)) {
      value = process.data.fields[item.field].value.name || "";
    } else if (get(process, `data.fields.${item.field}.value`)) {
      value = process.data.fields[item.field].value;
    } else {
      return intl.formatMessage({ id: "process > none" });
    }
    return this.formattedValueOrEmpty(value, item);
  }

  renderView() {
    let { process, template, user, step, auth } = this.props;
    let {
      client_name,
      contract_start_date,
      contract_end_date,
      contract_client_contact,
      contract_client_email,
      contract_client_nickname,
      contract_client_phone,
      contract_stations,
      contract_stations_order_number_array,
      contract_category,
      order_title,
      requested_by,
      // due_date
    } = this.getFieldRefs();

    let valueOrEmpty = this.valueOrEmpty.bind(this);
    const stationsData = get(auth, "info.stations", []);
    const renderForClient = () => (
      <ShadowBox className={bem.e("section")}>
        <SectionTitle className={bem.e("section-title")}>
          <FormattedMessage id="process > title order information" />
        </SectionTitle>
        <OrderClientDetails
          client_name={client_name}
          intl={this.props.intl}
          contract_category={contract_category}
          order_title={order_title}
          contract_client_contact={contract_client_contact}
          contract_client_nickname={contract_client_nickname}
          contract_client_email={contract_client_email}
          contract_client_phone={contract_client_phone}
          setIsFieldsChanged={this.setIsFieldsChanged}
          onSelectContact={this.onSelectContact}
          onSelectAgency={this.onSelectAgency}
          process={process}
          onValueChanged={this.onValueChanged}
          isView={true}
          valueOrEmpty={valueOrEmpty}
          isDigital={true}
        />
        <SectionFields
          className={classnames(bem.e("section-fields"), "p-top-0")}
        >
          <Row>
            <Col>
              <Label className={bem.e("view-field-label")}>
                {contract_start_date.title}
              </Label>
              <span className="order-view-value">
                {formatDisplayDate(valueOrEmpty(contract_start_date))}
              </span>
            </Col>
            <Col>
              <Label className={bem.e("view-field-label")}>
                {contract_end_date.title}
              </Label>
              <span className="order-view-value">
                {formatDisplayDate(valueOrEmpty(contract_end_date))}
              </span>
            </Col>
          </Row>
          {/* <Row>
            <Col>
              <Label className={bem.e("view-field-label")}>
                {due_date.title}
              </Label>
              <span className="order-view-value">
                {formatDisplayDate(valueOrEmpty(due_date))}
              </span>
            </Col>
            <Col>
              
            </Col>
          </Row> */}
          <Row>
            <Col xs={12} className={bem.e("col-view-rotation-field")}>
              <Label>{contract_stations_order_number_array.title}</Label>
              <div
                className={classnames(bem.e("rotation-container-view"), {
                  [bem.e("rotation-container-view-empty")]:
                    get(
                      process,
                      "data.fields.contract_stations.value.length"
                    ) === 0,
                })}
              >
                {process.data.fields[contract_stations.field] &&
                  process.data.fields[contract_stations.field].value.map(
                    (station, index) => {
                      let num = "";
                      if (
                        process.data.fields[
                          contract_stations_order_number_array.field
                        ] !== undefined
                      ) {
                        let contract_stations_order_number_field =
                          process.data.fields[
                            contract_stations_order_number_array.field
                          ];
                        if (
                          contract_stations_order_number_field.value !==
                          undefined
                        ) {
                          contract_stations_order_number_field.value.forEach(
                            (item) => {
                              if (item.station === station) {
                                num = item.number;
                              }
                            }
                          );
                        }
                      } else if (
                        process.data.fields[
                          "contract_stations_order_number"
                        ] !== undefined
                      ) {
                        let contract_stations_order_number_field =
                          process.data.fields["contract_stations_order_number"];
                        if (
                          contract_stations_order_number_field.value !==
                          undefined
                        ) {
                          num =
                            contract_stations_order_number_field.value[station];
                        }
                      }
                      const stationData = find(
                        stationsData,
                        (item) => item.station_key === station
                      );
                      let stationArr = [];
                      if (get(stationData, "call_letters", ""))
                        stationArr.push(stationData.call_letters);
                      if (get(stationData, "name", ""))
                        stationArr.push(stationData.name);
                      return (
                        <div
                          className={classnames(
                            bem.e("rotation-table"),
                            bem.e("rotation-view-table")
                          )}
                          key={index}
                        >
                          <div className={bem.e("rotation-name")}>
                            {stationArr.length > 0 ? (
                              join(stationArr, " - ")
                            ) : (
                              <FormattedMessage id="process > none" />
                            )}
                          </div>
                          <div className={bem.e("rotation-value")}>
                            <span className={bem.e("rotation-number-view")}>
                              {num
                                ? num
                                : this.props.intl.formatMessage({
                                    id: "process > none",
                                  })}
                            </span>
                          </div>
                        </div>
                      );
                    }
                  )}
                {get(process, "data.fields.contract_stations.value.length") ===
                  0 && (
                  <span className="order-view-value">
                    {this.props.intl.formatMessage({ id: "process > none" })}
                  </span>
                )}
              </div>
            </Col>
          </Row>
        </SectionFields>
      </ShadowBox>
    );
    const renderForInternal = () => (
      <ShadowBox>
        <SectionTitle>
          <FormattedMessage id="process > title order information" />
        </SectionTitle>
        <SectionFields>
          <Row>
            <Col>
              <Label className={bem.e("view-field-label")}>
                {order_title.title}
              </Label>
              <span className="order-view-value">
                {valueOrEmpty(order_title)}
              </span>
            </Col>
            <Col>
              <Label className={bem.e("view-field-label")}>
                <FormattedMessage id="process > field start date" />
              </Label>
              <span className="order-view-value">
                {formatDisplayDate(valueOrEmpty(contract_start_date))}
              </span>
            </Col>
          </Row>
          <Row>
            <Col>
              <Label className={bem.e("view-field-label")}>
                {requested_by.title}
              </Label>
              <span className="order-view-value">
                {valueOrEmpty(requested_by)}
              </span>
            </Col>
            <Col>
              <Label className={bem.e("view-field-label")}>
                <FormattedMessage id="process > field end date" />
              </Label>
              <span className="order-view-value">
                {formatDisplayDate(valueOrEmpty(contract_end_date))}
              </span>
            </Col>
          </Row>
          <Row>
            <Col>
              <Label className={bem.e("view-field-label")}>
                {contract_stations_order_number_array.title}
              </Label>
              {get(process, `data.fields.${contract_stations.field}.value`) ? (
                <table className={bem.e("view-station")}>
                  <tbody>
                    {get(
                      process,
                      `data.fields.${contract_stations.field}.value`,
                      []
                    ).map((station, index) => {
                      let num = "";
                      if (
                        process.data.fields[
                          contract_stations_order_number_array.field
                        ] !== undefined
                      ) {
                        let contract_stations_order_number_field =
                          process.data.fields[
                            contract_stations_order_number_array.field
                          ];
                        if (
                          contract_stations_order_number_field.value !==
                          undefined
                        ) {
                          contract_stations_order_number_field.value.forEach(
                            (item) => {
                              if (item.station === station) {
                                num = item.number;
                              }
                            }
                          );
                        }
                      } else if (
                        process.data.fields[
                          "contract_stations_order_number"
                        ] !== undefined
                      ) {
                        let contract_stations_order_number_field =
                          process.data.fields["contract_stations_order_number"];
                        if (
                          contract_stations_order_number_field.value !==
                          undefined
                        ) {
                          num =
                            contract_stations_order_number_field.value[station];
                        }
                      }
                      const stationData = find(
                        stationsData,
                        (item) => item.station_key === station
                      );
                      return (
                        <tr key={index}>
                          <td className={bem.e("view-station-name")}>
                            {stationData ? (
                              get(stationData, "name", "")
                            ) : (
                              <FormattedMessage id="process > none" />
                            )}
                          </td>
                          <td className={bem.e("view-station-number")}>
                            {num}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : null}
            </Col>
          </Row>
        </SectionFields>
      </ShadowBox>
    );
    return (
      <div className={bem.e("order-view")}>
        <Form>
          {get(process, "data.fields.order_internal.value", false)
            ? renderForInternal()
            : renderForClient()}
          <TrafficDetails
            step={step}
            process={process}
            template={template}
            isTrafficView={this.props.isTrafficView}
            isView={true}
            user={user}
          />
        </Form>
        {this.props.isShowViewModeButtons && (
          <div className="bottom-actions-sticky">
            <ButtonGroup className={classnames(bem.e("buttons"), "buttons")}>
              <PrivilegedComponent
                requires={{
                  or: [
                    PRIVILEGES.EDIT_DIGITAL,
                    PRIVILEGES.EDIT_ALL_DIGITAL_ORDERS,
                  ],
                }}
              >
                {this.renderEditButton()}
              </PrivilegedComponent>
            </ButtonGroup>
          </div>
        )}
      </div>
    );
  }

  render() {
    let { process } = this.props;
    if (!process.data.fields || Object.keys(process.data.fields).length === 0)
      return null;
    let form = null;
    if (this.props.mode === "edit") {
      form = this.renderForm();
    } else {
      form = this.renderView();
    }
    return <span className={classnames("orderForm", bem.b())}>{form}</span>;
  }
}
DigitalOrderDetails.defaultProps = {
  isShowApprove: true,
  isTrafficView: false,
  isShowViewModeButtons: true,
  mode: "edit",
};
export default DigitalOrderDetails;
