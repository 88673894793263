import React from "react";
import { get } from "lodash";
import PropTypes from "prop-types";
import { Row, Col, Card, CardBody } from "reactstrap";
import UserListManual from "./UserListManual";
import UserListStatic from "./UserListStatic";
import bn from "utils/bemnames";
const bem = bn.create("list-users");

const UsersList = (props) => {
  const {
    selectedUsersChanged,
    auth,
    isFocused,
    onSelectedEdit,
    onSelectedView,
    onSelectedAdd,
    locationOptions,
  } = props;
  const renderTableComponent = () => {
    if (
      get(auth, "user.is_god_admin") ||
      get(auth, "user.is_admin") ||
      get(auth, "user.broadcasting_group_admin")
    ) {
      return (
        <UserListManual
          selectedUsersChanged={selectedUsersChanged}
          isFocused={isFocused}
          onSelectedView={onSelectedView}
          onSelectedEdit={onSelectedEdit}
          onSelectedAdd={onSelectedAdd}
          locationOptions={locationOptions}
        />
      );
    } else {
      return (
        <UserListStatic
          selectedUsersChanged={selectedUsersChanged}
          isFocused={isFocused}
          onSelectedView={onSelectedView}
          onSelectedEdit={onSelectedEdit}
          onSelectedAdd={onSelectedAdd}
          locationOptions={locationOptions}
        />
      );
    }
  };
  return (
    <>
      <Row className={bem.b()}>
        <Col>
          <Card>
            <CardBody>{renderTableComponent()}</CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};
UsersList.defaultProps = {
  onSelectedEdit: () => {},
  onSelectedView: () => {},
  onSelectedAdd: () => {},
};
UsersList.propsType = {
  onSelectedEdit: PropTypes.func,
  onSelectedView: PropTypes.func,
  onSelectedAdd: PropTypes.func,
};
export default UsersList;
