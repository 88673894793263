import * as React from "react";
import { Formik } from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";
import {
  Row,
  Col,
  FormGroup,
  Card,
  Form,
  Button,
  CardHeader,
  CardBody,
  Label
} from "reactstrap";
import SelectTags, {
  TAG_TYPE_LOCATION,
  TAG_TYPE_FORMAT,
  TAG_TYPE_EXCEPT_LOCATION_FORMAT
} from "components/SelectTags";
// import NumberFormat from "react-number-format";
import { Asterisk } from "components/Elements";
import Spinner from "components/Spinner";
import bn from "utils/bemnames";
import TextInput from "components/TextInput";
// import DaysOfWeek from "components/DaysOfWeek";
// import TextInputFormatted from "components/TextInputFormatted";
// import { PhoneNumberFormat } from "utils/config";
import { get, map, filter, includes, find, findIndex } from "lodash";
import Dropdown from "components/Dropdown";
import PrivilegedComponent from "components/PrivilegedComponent";
import { PRIVILEGES } from "utils/constants";
import { FormattedMessage } from "react-intl";
import StationsTree from "../StationsTree";
const bem = bn.create("station-form");
export const MODE_EDIT = "edit";
export const MODE_VIEW = "view";
export const MODE_ADD = "add";
class MarketFrom extends React.Component {
  getTagOptionsByType = type => {
    const { auth } = this.props;
    let tagOptions = [];
    let tags = get(auth, "info.tags.tags", []);
    switch (type) {
      case TAG_TYPE_LOCATION:
        tags = filter(tags, tag => includes(tag, "Location"));
        break;
      case TAG_TYPE_FORMAT:
        tags = filter(tags, tag => includes(tag, "Format"));
        break;
      case TAG_TYPE_EXCEPT_LOCATION_FORMAT:
        tags = filter(
          tags,
          tag => !includes(tag, "Format") && !includes(tag, "Location")
        );
        break;
      default:
        break;
    }
    let map_tags = (tag, path) => {
      tagOptions.push({
        value: tag.id,
        label: path
      });
      tag.children &&
        tag.children.map(child => {
          map_tags(child, path + " / " + child.title);
        });
    };
    tags.map(tag => {
      map_tags(tag, tag.title);
    });
    return tagOptions;
  };

  renderForm = () => {
    const { intl, isLoading, station, servers, isUpdate, auth } = this.props;
    const authIsGodAdmin = get(auth, "user.is_god_admin");
    const authIsBroadCastingGroupAdmin = get(
      auth,
      "user.broadcasting_group_admin"
    );
    // const timeOpenOptions = map([...Array(12).keys()], item => {
    //   return {
    //     value: item + 1,
    //     label: `${item + 1} am`
    //   };
    // });
    // const timeCloseOptions = map([...Array(12).keys()], item => {
    //   return {
    //     value: item + 1,
    //     label: `${item + 1} pm`
    //   };
    // });
    let serverList = [];
    if (authIsGodAdmin) {
      serverList = map(servers, server => ({
        value: server.id,
        label: server.name
      }));
    }
    const stationData = get(station, "data", {});
    // const locationOptions = this.getTagOptionsByType(TAG_TYPE_LOCATION);
    const cityOptions = this.getTagOptionsByType(TAG_TYPE_LOCATION);
    const formatOptions = this.getTagOptionsByType(TAG_TYPE_FORMAT);
    const categoryTagOptions = this.getTagOptionsByType(
      TAG_TYPE_EXCEPT_LOCATION_FORMAT
    );
    const selectedFormatOptions = filter(
      formatOptions,
      item =>
        findIndex(
          stationData.format_tags,
          selected => selected.id === item.value
        ) !== -1
    );
    const selectedCategoryTagOptions = filter(
      categoryTagOptions,
      item =>
        findIndex(
          stationData.category_tags,
          selected => selected.id === item.value
        ) !== -1
    );

    // const selectedLocationTagOptions = filter(
    //   cityOptions,
    //   item =>
    //     findIndex(
    //       stationData.location_tags,
    //       selected => selected.id === item.value
    //     ) !== -1
    // );
    return (
      <Formik
        enableReinitialize
        initialValues={{
          isUpdate: isUpdate,
          name: stationData.name || "",
          format: stationData.format_tags ? selectedFormatOptions : [],
          // server: stationData.server
          //   ? {
          //       value: get(stationData, "server.id"),
          //       label: get(stationData, "server.name")
          //     }
          //   : authIsBroadCastingGroupAdmin
          //   ? {
          //       value: get(auth, "user.server_group_admin.id"),
          //       label: get(auth, "user.server_group_admin.name")
          //     }
          //   : null,
          server: stationData.server
        ? {
            value: get(stationData, "server.id"),
            label: get(stationData, "server.name")
          }
        : this.props.authIsBroadCastingGroupAdmin
        ? {
            value: get(auth, "user.server"),
            label: get(auth, "user.server_name")
          }
        : {
            value: get(auth, "user.server"),
            label: 'local'
          },
          slogan: stationData.slogan || "",
          // city: stationData.city_data ? selectedLocationTagOptions : [],
          city: stationData.city
            ? find(cityOptions, item => item.label.includes(stationData.city))
            : "",
          categories: stationData.category_tags
            ? selectedCategoryTagOptions
            : [],
          sub_stations: stationData.sub_stations ? stationData.sub_stations : []
          // locations: stationData.location_tags ? selectedLocationTagOptions : []
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required(
            intl.formatMessage({
              id: "validate > name is required"
            })
          ),
          format: Yup.array().nullable(),
          // server: Yup.string()
          //   .required(
          //     intl.formatMessage({
          //       id: "validate > owner group is required"
          //     })
          //   )
          //   .nullable(),
          slogan: Yup.string(),
          // city: Yup.string().required(
          //   intl.formatMessage({
          //     id: "validate > city is required"
          //   })
          // )
        })}
        onSubmit={this.props.onFormSubmit}
      >
        {formProps => {
          const errors = formProps.errors;
          const touched = formProps.touched;
          const values = formProps.values;
          return (
            <Form onSubmit={formProps.handleSubmit}>
              <Row>
                <Col xs={12}>
                  <FormGroup>
                    <TextInput
                      label={
                        <span>
                          <FormattedMessage id="markets > title" />
                          <Asterisk>*</Asterisk>
                        </span>
                      }
                      type="text"
                      name="name"
                      placeholder={intl.formatMessage({
                        id: "markets > enter title"
                      })}
                      value={values.name}
                      onChange={formProps.handleChange}
                      onBlur={formProps.handleBlur}
                      error={touched.name && errors.name}
                    />
                  </FormGroup>
                </Col>
                {false && authIsGodAdmin ? (
                  <Col xs={6}>
                    <FormGroup>
                      <Dropdown
                        label={
                          <span>
                            <FormattedMessage id="station > owner group" />{" "}
                            <Asterisk>*</Asterisk>
                          </span>
                        }
                        value={values.server}
                        placeholder={intl.formatMessage({
                          id: "station > placeholder select owner group"
                        })}
                        onChange={selectedOption => {
                          formProps.setFieldValue("server", selectedOption);
                        }}
                        options={serverList}
                        name="server"
                        error={touched.server && errors.server}
                      />
                    </FormGroup>
                  </Col>
                ) : null}
              </Row>
              <Row>
                <Col xs={12}>
                  <FormGroup>
                    <TextInput
                      label={<FormattedMessage id="markets > description" />}
                      type="text"
                      name="slogan"
                      placeholder={intl.formatMessage({
                        id: "market > enter description"
                      })}
                      value={values.slogan}
                      onChange={formProps.handleChange}
                      onBlur={formProps.handleBlur}
                      error={touched.slogan && errors.slogan}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <FormGroup>
                    <label>
                      <FormattedMessage id="market > select stations" />
                    </label>
                    <StationsTree
                      value={values.sub_stations}
                      onChange={data => {
                        formProps.setFieldValue("sub_stations", data);
                        formProps.handleChange(data);
                      }}
                      onBlur={formProps.handleBlur}
                      error={touched.sub_stations && errors.sub_stations}
                      isFocused={true}/>
                  </FormGroup>
                </Col>
              </Row>
              {/* <Row>
                <Col xs={6}>
                  <FormGroup>
                    <Label>
                      <FormattedMessage id="station > format" />
                    </Label>
                    <SelectTags
                      isMulti
                      allowSelectAll
                      checked={values.format}
                      onChange={data => {
                        formProps.setFieldValue("format", data);
                      }}
                      type={TAG_TYPE_FORMAT}
                    />
                    <div className="text-danger">
                      {touched.format && errors.format}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <FormGroup>
                    <Label>
                      <FormattedMessage id="markets > locations" />
                      <Asterisk>*</Asterisk>
                    </Label>
                    <SelectTags
                      isMulti={false}
                      checked={values.city}
                      onChange={data => {
                        formProps.setFieldValue("city", data);
                      }}
                      type={TAG_TYPE_LOCATION}
                    />
                    <div className="text-danger">
                      {touched.city && errors.city}
                    </div>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>
                      <FormattedMessage id="station > category tags" />
                    </Label>
                    <SelectTags
                      isMulti
                      allowSelectAll
                      checked={values.categories}
                      onChange={data => {
                        formProps.setFieldValue("categories", data);
                      }}
                      type={TAG_TYPE_EXCEPT_LOCATION_FORMAT}
                    />
                    <div className="text-danger">
                      {touched.categories && errors.categories}
                    </div>
                  </FormGroup>
                </Col>
              </Row> */}
              <FormGroup>
                <Button
                  color="blue"
                  type="submit"
                  className="btn btn-radius"
                  disabled={isLoading}
                >
                  <FormattedMessage
                    id={`process > button ${
                      !isLoading ? "submit" : "submitting"
                    }`}
                  />
                </Button>
                {this.props.deleteButton}
                {this.props.backButton}
              </FormGroup>
            </Form>
          );
        }}
      </Formik>
    );
  };
  renderView = () => {
    const { setIsViewMode, station, intl } = this.props;
    const stationData = get(station, "data", {});
    const cityOptions = this.getTagOptionsByType(TAG_TYPE_LOCATION);
    const formatOptions = this.getTagOptionsByType(TAG_TYPE_FORMAT);
    const categoryTagOptions = this.getTagOptionsByType(
      TAG_TYPE_EXCEPT_LOCATION_FORMAT
    );

    const selectedFormatOptions = filter(
      formatOptions,
      item =>
        findIndex(
          stationData.format_tags,
          selected => selected.id === item.value
        ) !== -1
    );
    const selectedCategoryTagOptions = filter(
      categoryTagOptions,
      item =>
        findIndex(
          stationData.category_tags,
          selected => selected.id === item.value
        ) !== -1
    );
    const selectedLocationTagOptions = filter(
      cityOptions,
      item =>
        findIndex(
          stationData.location_tags,
          selected => selected.id === item.value
        ) !== -1
    );

    return (
      <>
        <Row>
          <Col xs={6}>
            <div className={bem.e("view-wrapper")}>
              <Label>
                <FormattedMessage id="markets > title" />
              </Label>
              <div className={bem.e("view-value")}>
                {get(stationData, "name", "")}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <div className={bem.e("view-wrapper")}>
              <Label>
                <FormattedMessage id="markets > formats" />
              </Label>
              <div className={bem.e("view-value")}>
                {map(selectedFormatOptions, item => item.label).join(", ")}
              </div>
            </div>
          </Col>
          <Col xs={6}>
            <div className={bem.e("view-wrapper")}>
              <Label>
                <FormattedMessage id="station > owner group" />
              </Label>
              <div className={bem.e("view-value")}>
                {get(stationData, "server.name", "")}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className={bem.e("view-wrapper")}>
              <Label>
                <FormattedMessage id="markets > description" />
              </Label>
              <div className={bem.e("view-value")}>
                {get(stationData, "slogan", "")}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <div className={bem.e("view-wrapper")}>
              <Label>
                <FormattedMessage id="markets > locations" />
              </Label>
              <div className={bem.e("view-value")}>
                <div className={bem.e("view-value")}>
                  {/* {map(selectedCategoryTagOptions, item => item.label).join(", ")} */}
                  {stationData.city_data
                    ? get(
                        find(
                          cityOptions,
                          item => item.value === stationData.city_data.id
                        ),
                        "label"
                      )
                    : ""}
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={bem.e("view-wrapper")}>
              <Label>
                <FormattedMessage id="station > category tags" />
              </Label>
              <div className={bem.e("view-value")}>
                {map(selectedCategoryTagOptions, item => item.label).join(", ")}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <PrivilegedComponent requires={[PRIVILEGES.EDIT_STATIONS]}>
              <Button
                color="blue"
                type="button"
                className="btn btn-radius"
                onClick={() => setIsViewMode(MODE_EDIT)}
              >
                <FormattedMessage id={`station > button edit`} />
              </Button>
            </PrivilegedComponent>
            {this.props.deleteButton}
            {this.props.backButton}
          </Col>
        </Row>
      </>
    );
  };
  render() {
    const { isViewMode, isLoading } = this.props;
    return (
      <Row className={bem.b()}>
        <Col>
          <Card>
            <CardHeader>
              <h2 className={bem.e("title")}>
                <FormattedMessage id={"markets > detail title"} />
              </h2>
            </CardHeader>
            <CardBody>
              {isViewMode === MODE_EDIT || isViewMode === MODE_ADD
                ? this.renderForm()
                : this.renderView()}
            </CardBody>
          </Card>
          <Spinner isLoading={isLoading} />
        </Col>
      </Row>
    );
  }
}
MarketFrom.defaultProps = {
  isView: false,
  deleteButton: null,
  backButton: null
};
MarketFrom.propTypes = {
  isView: PropTypes.oneOf([MODE_ADD, MODE_VIEW, MODE_EDIT]),
  deleteButton: PropTypes.any,
  backButton: PropTypes.any
};
export default MarketFrom;
