import { connect } from "react-redux";
// import { withRouter } from "react-router-dom";
import { getUsers } from "../../store/actions/users";
import { get } from "lodash";
import View from "./view";
import { injectIntl } from "react-intl";
const Enhanced = connect(
  (state, props) => {
    return {
      users: get(state, "auth.info.users", [])
    };
  },
  {getUsers}
)(View);

export default injectIntl(Enhanced);
