export const INIT_NEW_RESOURCE = "INIT_NEW_RESOURCE"
export const RESOURCE_VALUE_CHANGED = "RESOURCE_VALUE_CHANGED"
export const SAVE_FORM_VALUES_REQUEST = "SAVE_FORM_VALUES_REQUEST"
export const SAVE_FORM_VALUES_SUCCESS = "SAVE_FORM_VALUES_SUCCESS"
export const SAVE_FORM_VALUES_ERROR = "SAVE_FORM_VALUES_ERROR"

export const FETCH_RESOURCE_REQUEST = "FETCH_RESOURCE_REQUEST";
export const FETCH_RESOURCE_SUCCESS = "FETCH_RESOURCE_SUCCESS"
export const FETCH_RESOURCE_ERROR = "FETCH_RESOURCE_ERROR"

export const DELETE_RESOURCE_REQUEST = "DELETE_RESOURCE_REQUEST";
export const DELETE_RESOURCE_SUCCESS = "DELETE_RESOURCE_SUCCESS"
export const DELETE_RESOURCE_ERROR = "DELETE_RESOURCE_ERROR"

export const UPDATE_RESOURCE_REQUEST = "UPDATE_RESOURCE_REQUEST";
export const UPDATE_RESOURCE_SUCCESS = "UPDATE_RESOURCE_SUCCESS"
export const UPDATE_RESOURCE_ERROR = "UPDATE_RESOURCE_ERROR"

export const FETCH_RESOURCES_REQUEST = "FETCH_RESOURCES_REQUEST";
export const FETCH_RESOURCES_SUCCESS = "FETCH_RESOURCES_SUCCESS"
export const FETCH_RESOURCES_ERROR = "FETCH_RESOURCES_ERROR"

export const ADD_RESOURCE_REQUEST = "ADD_RESOURCE_REQUEST";
export const ADD_RESOURCE_SUCCESS = "ADD_RESOURCE_SUCCESS"
export const ADD_RESOURCE_ERROR = "ADD_RESOURCE_ERROR"

export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB"
export const SET_TAB_TO_EDIT = "SET_TAB_TO_EDIT"
export const RESET_TAB = "RESET_TAB";

export const FORGET_RESOURCES = "FORGET_RESOURCES"
