import * as Types from "store/types/winners";
import * as Const from "utils/constants";
import queryString from "query-string";
import Api from "utils/api";
import { get, isFunction, omit, map, forEach } from "lodash";

export const getReportWinners = (state, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    const { pageSize, page, sorted, filtered, advance_search } = state;

    const params = {
      page: page,
      rpp: pageSize,
    };
    if (sorted && sorted.length > 0) {
      const sortedColumns = map(sorted, "id").join(",");
      params.sort = `${sortedColumns} ${
        get(sorted, `${sorted.length - 1}.desc`) ? "desc" : "asc"
      }`;
    }
    const requestUrl = Const.GET_WINNERS_1099_REPORT();
    if (filtered.length > 0) {
      forEach(filtered, (item) => {
        if (item.value) {
          // keyword
          if (item.key === "keyword") {
            let keyword = item.value;
            // detect phone number and remove special characters
            const phoneReg = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
            if (keyword.match(phoneReg)) {
              keyword = keyword.replace(/[^0-9]/g, "");
            }
            params[item.key] = keyword;
          } else {
            params[item.key] = item.value;
          }
        }
      });
    }

    if (advance_search) {
      params.advance_search = JSON.stringify(advance_search);
    }
    dispatch({ type: Types.FETCH_REPORT_WINNERS_REQUEST });

    const response = await Api.doCall(
      `${requestUrl}?${queryString.stringify(params, {
        arrayFormat: "bracket",
      })}`,
      "GET"
    );

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.FETCH_REPORT_WINNERS_SUCCESS,
          payload: response.data,
        });
        if (isFunction(cb)) cb(response.data);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({
    type: Types.FETCH_REPORT_WINNERS_ERROR,
    payload: { data: errorStr },
  });
};
export const getWinners = (state, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    const {
      pageSize,
      page,
      sorted,
      filtered,
      advance_search,
      isSearch,
    } = state;

    const params = {
      page: page,
      rpp: pageSize,
    };
    if (sorted && sorted.length > 0) {
      const sortedColumns = map(sorted, "id").join(",");
      params.sort = `${sortedColumns} ${
        get(sorted, `${sorted.length - 1}.desc`) ? "desc" : "asc"
      }`;
    }
    const requestUrl = isSearch ? Const.SEARCH_WINNERS() : Const.GET_WINNERS();
    if (filtered.length > 0) {
      forEach(filtered, (item) => {
        if (item.value) {
          // keyword
          if (item.key === "keyword") {
            let keyword = get(item, "value", "");
            // detect phone number and remove special characters
            const phoneReg = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
            if (keyword.match(phoneReg)) {
              keyword = keyword.replace(/[^0-9]/g, "");
            }
            params[item.key] = keyword;
          } else {
            params[item.key] = item.value;
          }
        }
      });
    }

    if (advance_search) {
      params.advance_search = JSON.stringify(advance_search);
    }
    dispatch({ type: Types.FETCH_WINNERS_REQUEST });

    const response = await Api.doCall(
      `${requestUrl}?${queryString.stringify(params, {
        arrayFormat: "bracket",
      })}`,
      "GET"
    );

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.FETCH_WINNERS_SUCCESS,
          payload: response.data,
        });
        if (isFunction(cb)) cb(response.data);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({
    type: Types.FETCH_WINNERS_ERROR,
    payload: { data: errorStr },
  });
};

export const getWinner = (id, cb) => async (dispatch) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.FETCH_WINNER_REQUEST, payload: { id } });

    let response = await Api.doCall(Const.GET_WINNER(id), "GET");

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.FETCH_WINNER_SUCCESS,
          payload: response.data,
        });
        if (cb) cb(response.data);
        return;
      }

      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({
    type: Types.FETCH_WINNER_ERROR,
    payload: { id, error: errorStr },
  });
};

export const updateWinner = (data, cb) => async (dispatch) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.UPDATE_WINNER_REQUEST });

    let response = await Api.doCall(Const.GET_WINNER(data.id), "PUT", data);

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.UPDATE_WINNER_SUCCESS,
          payload: response.data,
        });
        if (isFunction(cb)) {
          cb(response.data);
        }

        return;
      }

      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({ type: Types.UPDATE_WINNER_ERROR, payload: errorStr });
};

export const addWinner = (data, cb) => async (dispatch) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.ADD_WINNER_REQUEST });

    let response = await Api.doCall(Const.GET_WINNERS(), "POST", data);

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.ADD_WINNER_SUCCESS,
          payload: response.data,
        });
        if (isFunction(cb)) {
          cb(response.data);
        }
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({ type: Types.ADD_WINNER_ERROR, payload: errorStr });
};

export const markWinOrLoss = (data, cb) => async (dispatch) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.MARK_WIN_LOSS_REQUEST });

    let response = await Api.doCall(Const.MARK_WIN_LOSS(), "POST", data);

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.MARK_WIN_LOSS_SUCCESS,
          payload: response.data,
        });
        if (isFunction(cb)) {
          cb(response.data);
        }
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({ type: Types.MARK_WIN_LOSS_ERROR, payload: errorStr });
};

export const pickPrizeForWinner = (data, cb) => async (dispatch) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.PICK_PRIZE_FOR_WINNER_REQUEST });

    let response = await Api.doCall(
      Const.PICK_PRIZE_FOR_WINNER(),
      "POST",
      data
    );

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.PICK_PRIZE_FOR_WINNER_SUCCESS,
          payload: response.data,
        });
        if (isFunction(cb)) {
          cb(response.data);
        }
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({ type: Types.PICK_PRIZE_FOR_WINNER_ERROR, payload: errorStr });
};
export const pickGrandPrizeWinner = (data, cb) => async (dispatch) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.PICK_GRAND_PRIZE_WINNER_REQUEST });

    let response = await Api.doCall(
      Const.PICK_GRAND_PRIZE_WINNER(),
      "POST",
      data
    );

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.PICK_GRAND_PRIZE_WINNER_SUCCESS,
          payload: response.data,
        });
        if (isFunction(cb)) {
          cb(response.data);
        }
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({ type: Types.PICK_GRAND_PRIZE_WINNER_ERROR, payload: errorStr });
};

export const addValueForStandardPrize = (data, cb) => async (dispatch) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.ADD_VALUE_FOR_STANDARD_PRIZE_REQUEST });

    let response = await Api.doCall(
      Const.ADD_UNIT_PRIZE_FOR_WINNER(),
      "POST",
      data
    );

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.ADD_VALUE_FOR_STANDARD_PRIZE_SUCCESS,
          payload: response.data,
        });
        if (isFunction(cb)) {
          cb(response.data);
        }
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({
    type: Types.ADD_VALUE_FOR_STANDARD_PRIZE_ERROR,
    payload: errorStr,
  });
};

export const getWinnersByFilter = (payload) => async (dispatch) => {
  let errorStr = "";
  try {
    const { rpp, page, sort, filtered, ...rest } = payload;

    const params = {
      page: page,
      rpp,
      sort,
      ...rest,
    };
    const index = filtered.findIndex((item) => item.id === "q");

    const requestUrl = Const.GET_WINNERS();
    if (index !== -1) {
      params.keyword = filtered[index].value;
    }
    dispatch({ type: Types.FETCH_WINNERS_BY_FILTER_REQUEST, payload });
    let response = await Api.doCall(
      `${requestUrl}?${queryString.stringify(params)}`,
      "GET"
    );
    if (response.status === 200) {
      if (!response.data.error) {
        dispatch({
          type: Types.FETCH_WINNERS_BY_FILTER_SUCCESS,
          payload: {
            ...omit(payload, ["page"]),
            total: get(response, "data.total", 1),
            page: get(response, "data.page", 1),
            pages: get(response, "data.pages", 1),
            data: get(response, "data.items", []),
            total_allocated: get(response, "data.total_allocated"),
          },
        });
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.FETCH_WINNERS_BY_FILTER_ERROR,
    payload: { filter: payload.filter, error: errorStr },
  });
};

export const assignWinnerToContest = (data, cb) => async (dispatch) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.ASSIGN_WINNER_TO_CONTEST_REQUEST });

    let response = await Api.doCall(
      Const.ASSIGN_WINNER_TO_CONTEST(data.winner_id, data.event_id),
      "POST"
    );

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.ASSIGN_WINNER_TO_CONTEST_SUCCESS,
          payload: response.data,
        });
        if (isFunction(cb)) {
          cb(response.data);
        }
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({ type: Types.ASSIGN_WINNER_TO_CONTEST_ERROR, payload: errorStr });
};

export const updateWinnerToContest = (data, cb) => async (dispatch) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.UPDATE_WINNER_TO_CONTEST_REQUEST });

    let response = await Api.doCall(
      Const.UPDATE_WINNER_TO_CONTEST(data.winner_id, data.contest_id),
      "POST"
    );

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.UPDATE_WINNER_TO_CONTEST_SUCCESS,
          payload: response.data,
        });
        if (isFunction(cb)) {
          cb(response.data);
        }
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({ type: Types.UPDATE_WINNER_TO_CONTEST_ERROR, payload: errorStr });
};

export const updateWinnerFulfilled = (data, cb) => async (dispatch) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.UPDATE_WINNER_FULFILLED_REQUEST });

    const response = await Api.doCall(
      Const.WINNER_FULFILL_PRIZE(data.winner_id, data.event_id, data.prize_id),
      "POST",
      data
    );

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.UPDATE_WINNER_FULFILLED_SUCCESS,
          payload: response.data,
        });
        if (isFunction(cb)) {
          cb(response.data);
        }
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({ type: Types.UPDATE_WINNER_FULFILLED_ERROR, payload: errorStr });
};

export const exportWinners = (state, cb) => async (dispatch) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.EXPORT_WINNERS_LIST_REQUEST });
    const {
      pageSize,
      page,
      sorted,
      filtered,
      advance_search,
      selected_fields,
    } = state;

    const params = {
      page: page,
      rpp: pageSize,
    };
    if (sorted && sorted.length > 0) {
      const sortedColumns = map(sorted, "id").join(",");
      params.sort = `${sortedColumns} ${
        get(sorted, `${sorted.length - 1}.desc`) ? "desc" : "asc"
      }`;
    }
    if (filtered.length > 0) {
      forEach(filtered, (item) => {
        if (item.value) {
          // keyword
          if (item.key === "keyword") {
            let keyword = get(item, "value", "");
            // detect phone number and remove special characters
            const phoneReg = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
            if (keyword.match(phoneReg)) {
              keyword = keyword.replace(/[^0-9]/g, "");
            }
            params[item.key] = keyword;
          } else {
            params[item.key] = item.value;
          }
        }
      });
    }

    if (advance_search) {
      params.advance_search = JSON.stringify(advance_search);
    }
    if (selected_fields) {
      params.selected_fields = selected_fields;
    }
    Api.doCall(
      `${Const.EXPORT_WINNERS()}?${queryString.stringify(params, {
        arrayFormat: "bracket",
      })}`,
      "GET",
      null,
      (response) => {
        if (response.status === 200 || response.status === 201) {
          if (!response.data.error) {
            dispatch({
              type: Types.EXPORT_WINNERS_LIST_SUCCESS,
            });
            if (isFunction(cb)) {
              cb(response.data);
            }
            return;
          }
          errorStr = response.data.error;
          dispatch({
            type: Types.EXPORT_WINNERS_LIST_ERROR,
            payload: errorStr,
          });
        }
      },
      true,
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    errorStr = error.toString();
    dispatch({ type: Types.EXPORT_WINNERS_LIST_ERROR, payload: errorStr });
  }
};

export const export1099ReportsWinners = (state, cb) => async (dispatch) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.EXPORT_1099_REPORTS_WINNERS_LIST_REQUEST });
    const {
      pageSize,
      page,
      sorted,
      filtered,
      advance_search,
      selected_fields,
      view,
      year,
      selected_items,
    } = state;

    const params = {
      page: page,
      rpp: pageSize,
      view,
      year,
      selected_items,
      selected_fields,
    };
    if (sorted && sorted.length > 0) {
      const sortedColumns = map(sorted, "id").join(",");
      params.sort = `${sortedColumns} ${
        get(sorted, `${sorted.length - 1}.desc`) ? "desc" : "asc"
      }`;
    }
    if (filtered.length > 0) {
      forEach(filtered, (item) => {
        if (item.value) {
          // keyword
          if (item.key === "keyword") {
            let keyword = item.value;
            // detect phone number and remove special characters
            const phoneReg = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
            if (keyword.match(phoneReg)) {
              keyword = keyword.replace(/[^0-9]/g, "");
            }
            params[item.key] = keyword;
          } else {
            params[item.key] = item.value;
          }
        }
      });
    }

    if (advance_search) {
      params.advance_search = JSON.stringify(advance_search);
    }

    Api.doCall(
      Const.EXPORT_1099_REPORTS_WINNERS(),
      "POST",
      params,
      (response) => {
        if (response.status === 200 || response.status === 201) {
          if (!response.data.error) {
            dispatch({
              type: Types.EXPORT_1099_REPORTS_WINNERS_LIST_SUCCESS,
            });
            if (isFunction(cb)) {
              cb(response.data);
            }
            return;
          }
          errorStr = response.data.error;
          dispatch({
            type: Types.EXPORT_1099_REPORTS_WINNERS_LIST_ERROR,
            payload: errorStr,
          });
        }
      },
      true,
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    errorStr = error.toString();
    dispatch({
      type: Types.EXPORT_1099_REPORTS_WINNERS_LIST_ERROR,
      payload: errorStr,
    });
  }
};

export const saveSearch = (data, cb) => async (dispatch) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.SAVE_WINNERS_SEARCH_REQUEST });

    const response = await Api.doCall(Const.WINNER_SEARCHES(), "POST", data);

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.SAVE_WINNERS_SEARCH_SUCCESS,
        });
        if (isFunction(cb)) {
          cb(response.data);
        }
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.message;
  }
  if (isFunction(cb)) {
    cb(errorStr);
  }
  dispatch({ type: Types.SAVE_WINNERS_SEARCH_ERROR, payload: errorStr });
};

export const getSavedSearches = (state, cb) => async (dispatch) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.GET_WINNERS_SAVED_SEARCHES_REQUEST });
    const { pageSize, page, type } = state;

    const params = {
      page: page,
      rpp: pageSize,
      type,
    };
    const response = await Api.doCall(
      `${Const.WINNER_SEARCHES()}?${queryString.stringify(params)}`,
      "GET"
    );

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.GET_WINNERS_SAVED_SEARCHES_SUCCESS,
          payload: {
            items: get(response, "data.data", []),
            page: get(response, "data.current_page", 1),
            pages: get(response, "data.last_page", 1),
            total: get(response, "data.total", 0),
          },
        });
        if (isFunction(cb)) {
          cb(response.data);
        }
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({ type: Types.GET_WINNERS_SAVED_SEARCHES_ERROR, payload: errorStr });
};

export const getAllSavedSearches = (state, cb) => async (dispatch) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.GET_ALL_WINNERS_SAVED_SEARCHES_REQUEST });
    const { pageSize, page, type } = state;

    const params = {
      page: page,
      rpp: pageSize,
      type,
    };
    const response = await Api.doCall(
      `${Const.WINNER_SEARCHES()}?${queryString.stringify(params)}`,
      "GET"
    );

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.GET_ALL_WINNERS_SAVED_SEARCHES_SUCCESS,
          payload: {
            items: get(response, "data.data", []),
            page: get(response, "data.current_page", 1),
            pages: get(response, "data.last_page", 1),
            total: get(response, "data.total", 0),
          },
        });
        if (isFunction(cb)) {
          cb(response.data);
        }
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({
    type: Types.GET_ALL_WINNERS_SAVED_SEARCHES_ERROR,
    payload: errorStr,
  });
};

export const deleteSavedSearch = (id, cb) => async (dispatch) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.DELETE_WINNERS_SEARCH_REQUEST });

    const response = await Api.doCall(
      `${Const.WINNER_SEARCHES()}/${id}`,
      "DELETE"
    );

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.DELETE_WINNERS_SEARCH_SUCCESS,
        });
        if (isFunction(cb)) {
          cb(response.data);
        }
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({ type: Types.DELETE_WINNERS_SEARCH_ERROR, payload: errorStr });
};
export const setAdvancedSearch = (payload) => (dispatch) => {
  dispatch({ type: Types.SET_ADVANCED_SEARCH_DATA, payload });
};

export const getWinnersNotifications = (state = {}) => async (dispatch) => {
  let errorStr = "";

  try {
    const { pageSize, page, sorted, contestId } = state;
    const params = {
      page,
      rpp: pageSize,
      contest_id: contestId,
    };
    if (sorted) {
      params.sort = `${sorted.id} ${sorted.desc ? "desc" : "asc"}`;
    }
    const query = queryString.stringify(params);
    dispatch({
      type: Types.FETCH_WINNERS_NOTIFICATIONS_REQUEST,
    });

    let response = await Api.doCall(
      `${Const.GET_WINNERS_NOTIFICATIONS()}?${query}`,
      "GET"
    );

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.FETCH_WINNERS_NOTIFICATIONS_SUCCESS,
          payload: response.data,
        });
        return;
      }

      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({
    type: Types.FETCH_WINNERS_NOTIFICATIONS_ERROR,
    payload: { error: errorStr },
  });
};

export const updateWinnerNotification = (data, cb) => async (dispatch) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.UPDATE_WINNER_NOTIFICATION_REQUEST });

    let response = await Api.doCall(
      Const.GET_WINNERS_NOTIFICATIONS(),
      "POST",
      data
    );

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.UPDATE_WINNER_NOTIFICATION_SUCCESS,
          payload: response.data,
        });
        if (isFunction(cb)) {
          cb(response.data);
        }

        return;
      }

      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({ type: Types.UPDATE_WINNER_NOTIFICATION_ERROR, payload: errorStr });
};

export const addWinnerNotification = (data, cb) => async (dispatch) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.ADD_WINNER_NOTIFICATION_REQUEST });

    let response = await Api.doCall(
      Const.GET_WINNERS_NOTIFICATIONS(),
      "POST",
      data
    );

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.ADD_WINNER_NOTIFICATION_SUCCESS,
          payload: response.data,
        });
        if (isFunction(cb)) {
          cb(response.data);
        }

        return;
      }

      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({ type: Types.ADD_WINNER_NOTIFICATION_ERROR, payload: errorStr });
};

export const deleteWinnerNotification = (id, cb) => async (dispatch) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.DELETE_WINNER_NOTIFICATION_REQUEST });

    let response = await Api.doCall(
      `${Const.GET_WINNERS_NOTIFICATIONS()}/${id}`,
      "DELETE"
    );

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.DELETE_WINNER_NOTIFICATION_SUCCESS,
        });
        if (isFunction(cb)) {
          cb();
        }

        return;
      }

      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({ type: Types.DELETE_WINNER_NOTIFICATION_ERROR, payload: errorStr });
};

export const createFloatingContestPlay = (data, cb) => async (dispatch) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.CREATE_FLOATING_CONTEST_REQUEST });

    let response = await Api.doCall(
      Const.CREATE_FLOATING_CONTEST(),
      "POST",
      data
    );

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.CREATE_FLOATING_CONTEST_SUCCESS,
          payload: response.data,
        });
        if (isFunction(cb)) {
          cb(response.data);
        }
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({ type: Types.CREATE_FLOATING_CONTEST_ERROR, payload: errorStr });
};
