import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";
import { injectIntl } from "react-intl";
import ContestAssociated from "./view";
import { forgetAssociateElements } from "store/actions/programmings";
const Enhanced = compose(
  connect((state, props) => ({ ...props }), {
    forgetAssociateElements,
  }),
  withHandlers({
    onRefreshAssociateElements: ({ forgetAssociateElements }) => () => {
      forgetAssociateElements();
    },
  })
)(ContestAssociated);
export default injectIntl(Enhanced);
