import DefineShowModal from "./view";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { compose, withHandlers, withState, lifecycle } from "recompose";
import { get, forEach, includes, map } from "lodash";

import ToastManager from "components/ToastManager";
import { updateClockRelations } from "store/actions/clocks";

export default injectIntl(
  compose(
    connect(
      (state) => {
        return {
          updateClockError: get(state, "clocks.updateClock.error", false),
        };
      },
      {
        updateClockRelations,
      }
    ),
    withState("isLoading", "setIsLoading", false),
    withState(
      "isOpenDeleteConfirmationModal",
      "setIsOpenDeleteConfirmationModal",
      false
    ),
    withState("currentShow", "setCurrentShow", null),
    withHandlers({
      onSaveShow: ({
        intl,
        setIsLoading,
        toggle,
        clockId,
        updateClockRelations,
        selectedShow,
        allShows,
      }) => ({
        name,
        start_time,
        end_time,
        days,
        color,
        related_shows,
        order_color,
        show_type,
      }) => {
        let isOverlapping = false;
        forEach(allShows, (savedShow) => {
          if (
            days.length === 1 &&
            includes(days, parseInt(savedShow.day)) &&
            (!selectedShow ||
              (selectedShow &&
                !includes(
                  map(related_shows, (relShow) => relShow.id),
                  savedShow.id
                ))) &&
            ((start_time > savedShow.start_time &&
              start_time < savedShow.end_time) ||
              (end_time > savedShow.start_time &&
                end_time < savedShow.end_time) ||
              (start_time < savedShow.start_time &&
                end_time > savedShow.start_time))
          ) {
            isOverlapping = true;
          }
        });

        if (isOverlapping) {
          ToastManager.show({
            title: intl.formatMessage({ id: "clock creator > error" }),
            message: intl.formatMessage({
              id: "clock creator > show save error",
            }),
            level: "error",
          });

          return false;
        }

        setIsLoading(true);

        let data = {
          name,
          clock_id: clockId,
          start_time,
          end_time,
          days,
          color,
          order_color,
          show_type,
        };
        if (selectedShow) {
          let shows = [];

          forEach(related_shows, ({ id, deleted }) => {
            let show = { id };
            if (deleted) {
              show.is_delete = deleted;
            }
            shows.push(show);
          });

          data.slots = shows;

          updateClockRelations("update_slots", data, () => {
            setIsLoading(false);
            toggle();
          });
        } else {
          updateClockRelations("add_slots", data, () => {
            setIsLoading(false);
            toggle();
          });
        }
      },
      onDeleteShow: ({
        updateClockRelations,
        setIsLoading,
        toggle,
        clockId,
      }) => ({ related_shows }) => {
        setIsLoading(true);

        let data = {
          clock_id: clockId,
          slots_ids: map(related_shows, (show) => show.id),
        };

        updateClockRelations("delete_slots", data, () => {
          setIsLoading(false);
          toggle();
        });
      },
    }),
    lifecycle({
      componentDidUpdate() {
        if (
          (this.props.updateClockError || this.props.updateClockError) &&
          this.props.isLoading
        ) {
          this.props.setIsLoading(false);
        }
      },
    })
  )(DefineShowModal)
);
