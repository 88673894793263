import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { get } from "lodash";

import { SwipeRightIcon, WinnerIcon } from "components/CustomIcons";
import bn from "utils/bemnames";
const bem = bn.create("jock-console");

export default function CalendarCard(props) {
  const {
    isPast,
    task,
    selectedSchedule,
    setSelectedSchedule,
    isShowWinnerIcon,
    isShowCartStatus,
  } = props;
  const { title, subtitle, schedule } = task;
  const isOpen = get(selectedSchedule, "schedule.id") === schedule.id;
  const hourFormat = moment(schedule.start_time, "HH:mm").format("h:mm a");
  return (
    <div
      className={classnames(bem.e("calendar-card"), {
        [bem.e("calendar-card-open")]: isOpen,
        [bem.e("calendar-card-past")]: isPast,
        [bem.e("calendar-card-action-contest")]: schedule.contests_schedule,
      })}
      onClick={() => setSelectedSchedule(task)}
    >
      <div className={bem.e("calendar-card-action")}>
        {isOpen && <SwipeRightIcon />}
      </div>
      <div className={bem.e("calendar-card-content")}>
        <div className={bem.e("calendar-card-content-left")}>
          {isShowWinnerIcon && (
            <span className={bem.e("winner-icon")}>
              <WinnerIcon />
            </span>
          )}
          <div className={bem.e("calendar-card-content-center")}>
            <div className={bem.e("calendar-card-title")}>{title}</div>
            <div className={bem.e("calendar-card-subtitle")}>{subtitle}</div>
          </div>
        </div>
        {isShowCartStatus && (
          <div className={bem.e("calendar-card-status")}>
            <span className={bem.e("calendar-card-text")}>
              <FormattedMessage id="jock console > live" />
            </span>
            <span className={bem.e("calendar-card-time")}>{hourFormat}</span>
          </div>
        )}
      </div>
    </div>
  );
}
CalendarCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isPast: PropTypes.bool,
  isShowWinnerIcon: PropTypes.bool,
  isShowCartStatus: PropTypes.bool,
};
CalendarCard.defaultProps = {
  subtitle: "",
  title: "",
  isPast: false,
  isShowWinnerIcon: false,
  isShowCartStatus: false,
};
