import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
} from "reactstrap";
import Checkbox from "components/Checkbox";
import classnames from "classnames";
import bn from "utils/bemnames";
import { xorBy, get, includes, map, difference, forEach } from "lodash";
import { CloseIcon } from "components/CustomIcons";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { getHours } from "utils/helpers";
const hours = getHours();
const weekdaysMin = moment.weekdaysShort();

const bem = bn.create("exclude-hours-modal");
function convertValueToObject(data) {
  let result = {};
  if (!data) return result;
  forEach(data, (item) => {
    result[item.key] = item.value;
  });
  return result;
}
function ExcludeHoursModal(props) {
  const { onToggle, isOpen, daysOfWeek, onSubmit, value, activeHours } = props;
  const valueObject = convertValueToObject(value);
  const [excludeHours, setExcludeHours] = useState(valueObject || {});
  useEffect(() => {
    setExcludeHours(valueObject || {});
  }, [value]);
  const [activeTab, setActiveTab] = useState("all");
  const tabs = [
    {
      label: <FormattedMessage id="process > exclude hours > entire flight" />,
      value: "all",
    },
    ...weekdaysMin.map((item, index) => ({
      label: item,
      value: index + 1,
      disabled: !includes(daysOfWeek, index + 1),
    })),
  ];
  return (
    <Modal isOpen={isOpen} className={bem.b()}>
      <ModalHeader>
        <p className={bem.e("title")}>
          <FormattedMessage id="process > exclude hours > exclude hours" />
        </p>
        <p className={bem.e("description")}>
          <FormattedMessage id="process > exclude hours > select hours to exclude from the entire flight or individual days of the week" />
        </p>
        <div
          className={bem.e("close")}
          onClick={() => {
            onToggle();
            setExcludeHours({});
          }}
        >
          <CloseIcon color={"#657894"} />
        </div>
      </ModalHeader>
      <ModalBody className={bem.e("body")}>
        <Nav tabs className={bem.e("tabs")}>
          {map(tabs, (tab, index) => (
            <NavItem key={`nav-item-${index}`}>
              <NavLink
                className={classnames({
                  active: activeTab === tab.value,
                  disabled: tab.disabled,
                })}
                disabled={tab.disabled}
                onClick={() => {
                  setActiveTab(tab.value);
                }}
              >
                {tab.label}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <TabContent activeTab={activeTab}>
          {map(tabs, (tab, index) => (
            <TabPane tabId={tab.value} key={`tab-pane-${index}`}>
              <div className={bem.e("hours")}>
                {map(hours, (hour, index) => {
                  const isChecked =
                    includes(
                      get(excludeHours, tab.value, []).map(
                        (item) => item.value
                      ),
                      hour.value
                    ) && !tab.disabled;
                  const isSelectedHourFromAll =
                    includes(
                      get(excludeHours, "all", []).map((item) => item.value),
                      hour.value
                    ) && tab.value !== "all";
                  const isDisabled =
                    (activeHours &&
                      activeHours.length > 0 &&
                      !includes(activeHours, hour.value)) ||
                    isSelectedHourFromAll;
                  return (
                    <Checkbox
                      key={`checkbox-${index}`}
                      text={hour.label}
                      checked={isChecked}
                      disabled={isDisabled}
                      value={get(excludeHours, tab.value)}
                      onChange={(checked) => {
                        const newValue = xorBy(
                          get(excludeHours, tab.value),
                          [hour],
                          "value"
                        );
                        let extraData = {};
                        if (tab.value === "all") {
                          weekdaysMin.forEach((item, index) => {
                            if (item.disabled) return;
                            const tabKey = index + 1;
                            const currentValue = get(excludeHours, tabKey, []);
                            extraData = {
                              ...extraData,
                              [tabKey]: !checked
                                ? difference(currentValue, [hour], "value")
                                : [...currentValue, hour],
                            };
                          });
                        }
                        setExcludeHours({
                          ...excludeHours,
                          [tab.value]: newValue,
                          ...extraData,
                        });
                      }}
                    />
                  );
                })}
              </div>
            </TabPane>
          ))}
        </TabContent>
      </ModalBody>
      <ModalFooter>
        <Button
          color="blue"
          className="btn btn-radius"
          onClick={() => {
            onSubmit(Object.keys(excludeHours).length > 0 ? excludeHours : "");
            setExcludeHours({});
          }}
        >
          <FormattedMessage id={`process > exclude hours > button exclude`} />
        </Button>
        <Button
          color="blue"
          outline
          className="btn btn-radius btn-cancel"
          onClick={() => {
            onToggle();
            const valueObject = convertValueToObject(value);
            setExcludeHours(valueObject);
          }}
        >
          <FormattedMessage id={`process > exclude hours > button clear`} />
        </Button>
      </ModalFooter>
    </Modal>
  );
}
ExcludeHoursModal.defaultProps = {
  isOpen: false,
  onToggle: () => {},
  onCancel: () => {},
  daysOfWeek: [],
};
ExcludeHoursModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  daysOfWeek: PropTypes.array,
};
export default ExcludeHoursModal;
