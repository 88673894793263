import { FormattedMessage } from "react-intl";
import React from "react";
import { get, delay } from "lodash";
import history from "components/History";
import { checkUserRedirectToCurrentTasks } from "utils/helpers";

const assignTeam = {
  process_step_index: 2,
  key: "assign_team",
  caption_color: "#6803CC",
  title: <FormattedMessage id="process > title assignments" />,
  description: null,
  step_roles: [4],
  step_viewers_roles: [4, 7, 2],
  step_editors_roles: [4],
  step_viewers_privileges: {
    or: ["assign_production_team", "edit_all_production_orders"],
  },
  step_editors_privileges: {
    or: ["assign_production_team", "edit_all_production_orders"],
  },
  status: "initial",
  className: "tab-assign-team",
  workflow: {
    title: "assign production",
    icon: "TeamIcon",
  },
  is_approval_step: true,
  fields: [
    {
      component: "select_team",
    },
    {
      field: "copy_points",
      title: <FormattedMessage id="process > field copy points/instructions" />,
    },
    {
      field: "role_dubber",
      title: <FormattedMessage id="process > field dubber" />,
      roles: [9],
      privileges: { or: ["perform_production_dubbing"] },
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        return !(
          !process.fields.order_sold || process.fields.order_sold.value
        ) ||
          (value !== "" && value.id) ||
          (value.users && value.users.length > 0)
          ? true
          : "Please select a dubber";
      },
    },
    {
      field: "role_voice_talent",
      title: <FormattedMessage id="process > field voice over" />,
      roles: [6],
      privileges: { or: ["edit_vo"] },
      mandatory: false,
    },
    {
      field: "role_sales_person",
      title: <FormattedMessage id="process > field sales" />,
      roles: [1, 2, 3],
      privileges: { or: ["add_production"] },
      mandatory: true,
    },
    {
      field: "role_producer",
      title: <FormattedMessage id="process > field producer" />,
      roles: [5],
      privileges: {
        or: ["produce_production"],
      },
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        return (process.fields.contract_status &&
          process.fields.contract_status.value &&
          process.fields.contract_status.value !== "Production Needed") ||
          (value !== "" && value.id) ||
          (value.users && value.users.length > 0)
          ? true
          : "Please select a producer";
      },
    },
    {
      field: "role_writer",
      title: <FormattedMessage id="process > field writer" />,
      roles: [10],
      privileges: { or: ["submit_production_script"] },
      mandatory: true,
      validation: function(
        step,
        item,
        process,
        template,
        user,
        value,
        processKey
      ) {
        let spotInfo = get(process, "fields.spot_info.value", []).find(
          (spot) => spot.key === processKey
        );

        return (process.fields.contract_status &&
          process.fields.contract_status.value &&
          process.fields.contract_status.value !== "Production Needed") ||
          (value !== "" && value.id) ||
          (value.users && value.users.length > 0) ||
          get(spotInfo, "script_type") === "final" ||
          get(process, "data.fields.contract_status.value") ===
            "Production Provided"
          ? true
          : "Please select a writer";
      },
    },
    {
      field: "producer_assigns_vo",
    },
    {
      field: "assignment_deadline_modified",
    },
    {
      field: "voice_deadline_modified",
    },
    {
      field: "script_deadline_modified",
    },
    {
      field: "record_deadline_modified",
    },
    {
      field: "assignment_deadline",
      title: <FormattedMessage id="process > assignment deadline" />,
    },
    {
      field: "voice_deadline",
      title: <FormattedMessage id="process > voice deadline" />,
    },
    {
      field: "script_deadline",
      title: <FormattedMessage id="process > script deadline" />,
    },
    {
      field: "record_deadline",
      title: <FormattedMessage id="process > record deadline" />,
    },
    {
      key: "save_button",
      component: "button",
      container: "bottom_buttons",
      text: <FormattedMessage id="process > button submit" />,
      props: { color: "blue" },
      confirmation_dialog_props: {
        title: (
          <FormattedMessage id="process > description to submit these assignments" />
        ),
        confirmTitle: <FormattedMessage id="process > button yes" />,
        cancelTitle: <FormattedMessage id="process > button no" />,
      },
      success_dialog_props: {
        title: <FormattedMessage id="process > title great looking team" />,
        description: (
          <FormattedMessage id="process > the members have been notified about their new assignment" />
        ),
        className: "assigned-great-modal",
        state: "success",
      },
      class: null,
      on_click: {
        validate: true,
        save: true,
        go_to: function(_this, step, item, process, template, user, extraData) {
          const return_to_current_tasks = checkUserRedirectToCurrentTasks({
            ...user,
            roles_string: extraData.roles_string,
          });
          if (!return_to_current_tasks) return;
          // set delay when after success message
          delay(() => {
            if (_this.props.setSidebarActive) {
              _this.props.setSidebarActive(`/processes/my_action_items`);
            }
            history.replace(`/processes/my_action_items`);
          }, 4000);
        },
      },
      fields: [
        {
          key: "assign_team_status",
        },
        {
          key: "process_step",
          value: "first_undone",
        },
      ],
    },
  ],
};
export default assignTeam;
