import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import ContentFilterView from "./view";
import { get } from "lodash";
export default injectIntl(
  connect((state, props) => {
    return {
      users: get(state, "auth.info.users", []),
      ...props,
    };
  }, {})(ContentFilterView)
);
