import React from "react";
import { find, get, map } from "lodash";
import { Row, Col, Button, Form, Label } from "reactstrap";
import { FormattedMessage } from "react-intl";

import history from "components/History";
import Page from "components/Page";
import URL from "utils/urls";
import Spinner from "components/Spinner";
import { ShadowBox, SectionTitle, SectionFields } from "components/Elements";
import ConfirmationModal from "components/ConfirmationModal/view";
import ResourceForm from "components/ResourceForm";
import { resourceCategoryList, resourceCategoryValues } from "utils/config";

class AddResourcePage extends React.Component {
  state = {
    resources: [],
    modal: false,
    isUpdate: false,
    isView: false,
    idAdd: false,
    updateValues: {},
    isOpenDialog: false,
    isOpenDialogDelete: false,
    isOpenDialogSubmitted: false
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.resource !== prevProps.resource &&
      this.props.resource.data
    ) {
      let updateValues = this.props.resource.data;
      updateValues.type = resourceCategoryList.find(
        type => type.value === updateValues.type
      );
      this.setState({
        updateValues
      });
    }
  }

  cancel = () => {
    history.push(URL.RESOURCES({ filter: "resources_list" }));
  };

  removeResource = printId => {
    this.setState({ isOpenDialogDelete: true });
  };

  componentDidMount() {
    if (this.props.isView) {
      this.setState({
        isView: true
        // idAdd:false
      });
    }
    if (this.props.resource.data) {
      this.setState({
        updateValues: this.props.resource.data
      });
    }
  }

  editClick = () => {
    this.setState({
      // isUpdate: true,
      isView: false
      // idAdd:false
    });
  };

  getStationName = (ids = []) => {
    const { auth } = this.props;
    const { updateValues } = this.state;
    let stationIDs = updateValues.station_id || [];
    let stations = get(auth, "info.stations") || [];
    let station = stations
      .filter(station => {
        return stationIDs.indexOf(station.key) !== -1;
      })
      .map(station => {
        return station.name;
      });
    if (station.length > 0) {
      return station.join(",");
    }
    return null;
  };

  getTypeName = () => {
    const { updateValues } = this.state;
    let type = updateValues.type || {};
    return type.label;
  };

  render() {
    const { updateValues, isView } = this.state;
    const { match, auth, isUpdate, isLoading } = this.props;

    const customButtonMargin = {
      marginRight: "10px"
    };

    const stations = get(auth, "info.stations") || [];
    const stationsList = stations.map(station => ({
      value: station.station_key,
      label: station.name
    }));
    const selectedStation = map(updateValues.station_id, i => {
      const station = find(stations, item => item.station_key === i);
      return station ? station.name : i;
    });
    return (
      <Page
        title=""
        breadcrumbs={[
          {
            name: this.props.intl.formatMessage({
              id: "breadcrumbs > resources data"
            }),
            active: false
          },
          {
            name:
              match.params.template_id !== "new_resource"
                ? this.state.isUpdate
                  ? this.props.intl.formatMessage({
                      id: "breadcrumbs > resources update"
                    })
                  : this.props.intl.formatMessage({
                      id: "breadcrumbs > resources view"
                    })
                : this.props.intl.formatMessage({ id: "breadcrumbs > add" }),
            active: true
          }
        ]}
        className="cr-page-min-500"
      >
        <Row>
          <Col className="col-sm-12 col-md-8 col-lg-8 offset-md-2 offset-lg-2 col-min-500">
            {isView && (
              <div className="text-left">
                <Form>
                  <ShadowBox>
                    <SectionTitle>
                      <FormattedMessage id="resources > view resources details" />
                    </SectionTitle>
                    <SectionFields>
                      <Row>
                        <Col>
                          <Label className="ps-label">
                            <FormattedMessage id="resources > form title" />:
                          </Label>
                          <div className="ps-view-value">
                            {updateValues.title}
                          </div>
                        </Col>
                        <Col>
                          <Label className="ps-label">
                            <FormattedMessage id="resources > form subtitle" />:
                          </Label>
                          <div className="ps-view-value">
                            {updateValues.subtitle}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Label className="ps-label">
                            <FormattedMessage id="resources > form description" />
                            :
                          </Label>
                          <div className="ps-view-value">
                            {updateValues.description}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Label className="ps-label">
                            <FormattedMessage id="resources > form quantity" />:
                          </Label>
                          <div className="ps-view-value">
                            {updateValues.quantity}
                          </div>
                        </Col>
                        <Col>
                          <Label className="ps-label">
                            <FormattedMessage id="resources > form unit_value" />{" "}
                            :
                          </Label>
                          <div className="ps-view-value">
                            {updateValues.unit_value}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Label className="ps-label">
                            <FormattedMessage id="resources > form category" />:
                          </Label>
                          <div className="ps-view-value">
                            {resourceCategoryValues[updateValues.category]}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Label className="ps-label">
                            <FormattedMessage id="resources > form stations" />:
                          </Label>
                          <div className="ps-view-value">
                            {selectedStation.length > 0 ?
                              map(selectedStation, (item, index) => (
                                <span key={index} className="ps-view-list-item">
                                  {item}
                                </span>
                              )): <FormattedMessage id="process > none" />}
                          </div>
                        </Col>
                        <Col>
                          <Label className="ps-label">
                            <FormattedMessage id="resources > form supplier" />:
                          </Label>
                          <div className="ps-view-value">
                            {updateValues.supplier}
                          </div>
                        </Col>
                      </Row>
                    </SectionFields>
                  </ShadowBox>
                </Form>
                <div
                  className="position-relative form-group"
                  style={{ display: "inline-block", marginLeft: "10px" }}
                >
                  <Button
                    color="blue"
                    className="btn btn-radius"
                    disabled={isLoading}
                    style={customButtonMargin}
                    onClick={this.editClick}
                  >
                    <FormattedMessage id={`resources > button edit`} />
                  </Button>
                </div>
              </div>
            )}

            {!isView && (
              <ResourceForm
                updateValues={updateValues}
                isUpdate={isUpdate}
                stationsList={stationsList}
                isLoading={isLoading}
                onCancel={this.cancel}
                onFormSubmit={this.props.onFormSubmit}
                removeResource={this.removeResource}
              />
            )}
          </Col>
        </Row>
        <ConfirmationModal
          isOpen={this.state.isOpenDialogDelete}
          onToggle={() =>
            this.setState({
              isOpenDialogDelete: !this.state.isOpenDialogDelete
            })
          }
          onCancel={() => this.setState({ isOpenDialogDelete: false })}
          title={
            <FormattedMessage id="resources > are you sure you want to remove resources" />
          }
          className="modal-delete"
          onConfirm={() => {
            this.props.deleteResource(updateValues.id);
            this.setState(
              { isOpenDialogDelete: false, isOpenDialogSubmitted: true },
              () => {
                setTimeout(() => {
                  this.setState({ isOpenDialogSubmitted: false });
                }, 2000);
              }
            );
          }}
          isCloseOutside={false}
        />
        {/* submitted modal */}
        <ConfirmationModal
          isOpen={this.state.isOpenDialogSubmitted}
          onToggle={() =>
            this.setState({
              isOpenDialogSubmitted: !this.state.isOpenDialogSubmitted
            })
          }
          onCancel={() => this.setState({ isOpenDialogSubmitted: false })}
          state="success"
          title={<FormattedMessage id={`resources > title deleted`} />}
        />
        <Spinner isLoading={isLoading} />
      </Page>
    );
  }
}
// AddResourcePage.propTypes = {
//   selectable: PropTypes.bool,
//   onSelectEvent: PropTypes.func
// };
export default AddResourcePage;
