import React from "react";
import {get} from "lodash";
import Page from "components/Page";
import { Link } from "react-router-dom";
import Spinner from "components/Spinner";
import {
  Row,
  Col,
  Table,
  ButtonGroup,
  Button,
  Card,
  CardBody,
} from "reactstrap";
import { FormattedMessage } from "react-intl";
import ConfirmationModal from "components/ConfirmationModal";
// import PrivilegedComponent from "components/PrivilegedComponent";
// import { PRIVILEGES } from "utils/constants";
// import { Redirect } from "react-router";

const TemplatesPage = props => {
  const { templates } = props;
  const templateList = get(templates, "data") || [];
  // return (
    // <PrivilegedComponent requires={[PRIVILEGES.VIEW_TEMPLATES]}>
    //   {({ isValid }) => {
    //     if (isValid) {
          return (
            <Page
              title={<FormattedMessage id="pages > templates" />}
              breadcrumbs={[{ name: props.intl.formatMessage({id: "breadcrumbs > templates"}), active: true }]}>
              {templates.loading ? (
                <Spinner isLoading={templates.loading} />
              ) : (
                  <Row>
                    <Col>
                      <Card>
                        <CardBody>
                          {/* <PrivilegedComponent
                            requires={[PRIVILEGES.ADD_TEMPLATES]}> */}
                            <Button
                              color="blue"
                              tag={Link}
                              to={`/templates/update/new`}
                              className="mb-2">
                              <FormattedMessage id="template > add new template" />
                            </Button>
                          {/* </PrivilegedComponent> */}

                          <Table>
                            <thead>
                              <tr>
                                <th>
                                  <FormattedMessage id="template > Name" />
                                </th>
                                <th>
                                  <FormattedMessage id="template > Status" />
                                </th>
                                <th>
                                  <FormattedMessage id="template > Actions" />
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {templateList.map(template => (
                                <tr key={template.id}>
                                  <td className="align-middle">{template._source.templates_name}</td>
                                  <td className="align-middle">
                                    {template.status}
                                  </td>
                                  <td className="align-middle">
                                    <ButtonGroup>
                                      {/* <Button
                                        color="blue"
                                        tag={Link}
                                        to={`/templates/details/${template._id}`}>
                                        <FormattedMessage id="view" />
                                      </Button> */}
                                      <Button
                                        color="blue"
                                        tag={Link}
                                        to={`/processes/add/${template._id}`}> 
                                        <FormattedMessage id="template > start" />
                                      </Button>
                                      {/* <PrivilegedComponent
                                        requires={[PRIVILEGES.EDIT_TEMPLATES]}> */}
                                        <Button
                                          color="blue"
                                          tag={Link}
                                          to={`/templates/update/${template._id}`}>
                                          <FormattedMessage id="edit" />
                                        </Button>
                                      {/* </PrivilegedComponent> */}
                                      {/* <PrivilegedComponent
                                        requires={[PRIVILEGES.DELETE_TEMPLATES]}> */}
                                        <Button
                                          color="blue"
                                          onClick={() => {
                                            props.openDeleteModal(template._id);
                                          }}>
                                          <FormattedMessage id="delete" />
                                        </Button>
                                      {/* </PrivilegedComponent> */}
                                    </ButtonGroup>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                )}
              <ConfirmationModal
                isOpen={props.isDeleteModalOpen}
                title={props.intl.formatMessage({id: "template > remove template"})}
                description={props.intl.formatMessage({id: "template > are you sure you want to remove template"})}
                onConfirm={props.onDelete}
                onToggle={props.closeDeleteModal}
                onCancel={props.closeDeleteModal}
                isCloseOutside={false}
              />
            </Page>
          );
    //     } else {
    //       return <Redirect to="/" />;
    //     }
    //   }}
    // </PrivilegedComponent>
  // );
};

export default TemplatesPage;
