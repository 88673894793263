import React from "react";
import TextInput from "components/TextInput";
import { Formik } from "formik";
import * as Yup from "yup";
import { FormattedMessage } from "react-intl";
import { Form, FormGroup, Button, Row, Col } from "reactstrap";
import bn from "utils/bemnames";
import {get} from "lodash";
const bem = bn.create("server-security-policy-form");
class SecurityPolicy extends React.Component {
  formikRef = React.createRef();
  render() {
      const serverDetails = get(this.props,"server",{});

    return (
      <div className={bem.b()}>
        <Formik
          enableReinitialize
          ref={ref => (this.formikRef = ref)}

          initialValues={{
              password_complexity_pattern:
                  serverDetails.password_complexity_pattern || "",
              replace_password_days:
                  serverDetails.replace_password_days || "",
              reuse_password_days: serverDetails.reuse_password_days || "",
              domain_filter: serverDetails.domain_filter || "",
              system_idle_timeout: serverDetails.system_idle_timeout || "360"//
          }}

          validationSchema={Yup.object().shape({
            password_complexity_pattern: Yup.string(),
            replace_password_days: Yup.number().typeError(
              this.props.intl.formatMessage({
                id: "validate > replace password days must be number"
              })
            ),
            reuse_password_days: Yup.number().typeError(
              this.props.intl.formatMessage({
                id: "validate > reuse password days must be number"
              })
            ),
            domain_filter: Yup.string(),
            system_idle_timeout: Yup.number().typeError(
              this.props.intl.formatMessage({
                id: "validate > system idle timeout must be number"
              })
            )
          })}
          onSubmit={this.props.onFormSubmit}
        >
          {formProps => {
            const errors = formProps.errors;
            const touched = formProps.touched;
            const values = formProps.values;
            return (
              <Form
                onSubmit={formProps.handleSubmit}
                className="form-add-update-user"
              >
                <div className={bem.e("content-form")}>
                  <Row>
                    <Col>
                      <FormGroup>
                        <TextInput
                          label={
                            <FormattedMessage id="user > password complexity pattern" />
                          }
                          type="text"
                          name="password_complexity_pattern"
                          value={values.password_complexity_pattern}
                          error={
                            touched.password_complexity_pattern &&
                            errors.password_complexity_pattern
                          }
                          onChange={formProps.handleChange}
                          onBlur={formProps.handleBlur}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <TextInput
                          label={
                            <FormattedMessage id="user > replace password days" />
                          }
                          type="text"
                          name="replace_password_days"
                          value={values.replace_password_days}
                          error={
                            touched.replace_password_days &&
                            errors.replace_password_days
                          }
                          onChange={formProps.handleChange}
                          onBlur={formProps.handleBlur}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <TextInput
                          label={
                            <FormattedMessage id="user > reuse password days" />
                          }
                          type="text"
                          name="reuse_password_days"
                          value={values.reuse_password_days}
                          error={
                            touched.reuse_password_days &&
                            errors.reuse_password_days
                          }
                          onChange={formProps.handleChange}
                          onBlur={formProps.handleBlur}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <TextInput
                          label={<FormattedMessage id="user > domain filter" />}
                          name="domain_filter"
                          value={values.domain_filter}
                          error={touched.domain_filter && errors.domain_filter}
                          onChange={formProps.handleChange}
                          onBlur={formProps.handleBlur}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <TextInput
                          label={
                            <FormattedMessage id="user > system idle timeout" />
                          }
                          name="system_idle_timeout"
                          value={values.system_idle_timeout}
                          error={
                            touched.system_idle_timeout &&
                            errors.system_idle_timeout
                          }
                          onChange={formProps.handleChange}
                          onBlur={formProps.handleBlur}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>

                <div className={bem.e("form-buttons")}>
                  <FormGroup>
                    <Button
                      color="blue"
                      type="button"
                      onClick={e => {
                        e.preventDefault();
                        this.formikRef.handleSubmit(e);
                      }}
                      className="btn btn-radius"
                    >
                      <FormattedMessage
                        id={`security policy > button submit`}
                      />
                    </Button>
                    <Button
                      color="blue"
                      outline
                      className="btn btn-radius"
                      onClick={this.props.onCancel}
                    >
                      <FormattedMessage
                        id={`security policy > button cancel`}
                      />
                    </Button>
                  </FormGroup>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

export default SecurityPolicy;
