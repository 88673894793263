import React from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { get, map, find } from "lodash";
export default function ExpandDetails(props) {
  const { bem, item, allStations } = props;
  const prizes = get(item, "_source.prizes", []);
  return (
    <table className={bem.e("expand-details")}>
      <tbody>
        {map(prizes, (prize, index) => {
          const stationData = find(
            allStations,
            (i) => i.station_key === prize.station_key
          );
          return (
            <tr key={index}>
              {/* checkbox */}
              <td
                className={classnames(
                  bem.e("first-col"),
                  "align-middle",
                  "checkbox-col"
                )}
              ></td>
              {/* year */}
              <td className={"align-middle year-col"}></td>
              <td className={classnames("align-middle")}>
                <strong className={bem.e("station-prize-name")}>
                  ({get(stationData, "call_letters", "")}) {prize.title}
                </strong>
              </td>
              <td
                className={classnames(
                  "align-middle",
                  "signed-col",
                  bem.e("text-center")
                )}
              ></td>
              <td
                className={classnames(
                  "align-middle",
                  "cash-col",
                  bem.e("text-center")
                )}
              >
                ${prize.cash}
              </td>
              <td
                className={classnames(
                  "align-middle",
                  "non-cash-col",
                  bem.e("text-center")
                )}
              >
                ${prize.non_cash}
              </td>
              <td
                className={classnames(
                  "align-middle",
                  "total-col",
                  bem.e("text-center")
                )}
              >
                {prize["1099_excluded"] ? (
                  <FormattedMessage id="winners > excluded" />
                ) : null}
              </td>
              <td className={classnames("align-middle action-col")}></td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
