import { injectIntl } from 'react-intl';
import { omit, forEach, find, keys, includes, has, get, map, isEqual, concat, intersection, isEmpty, filter } from "lodash";
import { compose, withState, withHandlers, lifecycle } from "recompose";
import { connect } from "react-redux";

import UserEntitiesAssignmentModal from './view';
import { checkPrivilegesForTeamAssignment, getAllStationsAndTeams } from 'store/actions/teams';
import { STATION } from "utils/constants";

export default injectIntl(
  compose(
    connect((state, ownProps) => {
      return {
        ...ownProps,
        allStationsAndTeams: get(state, "teams.allStationsAndTeams.data.data", []),
        userId: get(state, "auth.user.id")
      };
    }, {
      getAllStationsAndTeams,
      checkPrivilegesForTeamAssignment,
    }),
    withState("selectedTags", "setSelectedTags", {}),
    withState("selectedStations", "setSelectedStations", {}),
    withState("addedStations", "setAddedStations", {}),
    withState("isLoading", "setIsLoading", false),
    withState("isEntitiesSaved", "setIsEntitiesSaved", false),
    withState("isUpdateModalOpen", "setIsUpdateModalOpen", false),
    withHandlers({
      updateSelectedTags: ({
        selectedTags,
        setSelectedTags,
        selectedStations,
        setSelectedStations,
        addedStations,
      }) => (checked, tag, tagType, updateStations = true) => {
        
        const { id, label, stations } = tag;

        if (checked) {

          let tagStations = {};
          
          forEach(stations, station => {
            
            station = has(selectedStations, station.key) ? selectedStations[station.key] : station;

            tagStations[station.key] = {...station, [tagType]: {...station[tagType], [id]: {...station[tagType][id], selected: true}}};

          });

          setSelectedTags((previousSelectedTags) => {

            if(updateStations){

              //if the selected tag is a team, we will just add the team to the selected stations, otherwise we will add the stations of the selected tag to the selected stations list

              if(tagType !== 'team'){
                setSelectedStations({ ...selectedStations, ...tagStations });
              }else{
                  setSelectedStations({ ...selectedStations, [tag.key]: {...omit(tag, ['id', 'label', 'primaryKey', 'showWarning'])}  });
              }
              
            }

            return { ...previousSelectedTags, [id]: { id, label, type: tagType, stations: tagStations }};

          });
        } else {

          setSelectedTags((previousSelectedTags) => {

            let newSelectedTags = omit(previousSelectedTags, id);

            if(updateStations){

              /*
              * if the removed tag is a team, we will just omit it from selected stations, otherwise will follow below rule
              * if the stations of the removed tag are not part of any other tag we need to remove those stations from selected stations list
              * If they were selected using another tag, we will unset the selected property for the deleted tag
              */

              let selectedTagStations = {};

              forEach(newSelectedTags, (tag) => {
                selectedTagStations = concat(selectedTagStations, keys(tag.stations));
              });


              let newSelectedStations = {};

              //all stations other than the removed tag's
              forEach(selectedStations, station => {
                if(!includes(keys(stations), station.key)){
                  newSelectedStations[station.key] = station;
                }
              });

              //now loop through removed tag's stations and remove  their selected attribute for the removed tag if they are part of other tag's stations
              forEach(stations, (station) => {

                station = has(selectedStations, station.key) ? selectedStations[station.key] : station;

                if (includes(selectedTagStations, station.key)) {
                  newSelectedStations[station.key] = {...station, [tagType]: {...station[tagType], [id]: omit(station[tagType][id], 'selected')}};
                }
              });

              if(tagType !== 'team'){
                setSelectedStations(newSelectedStations);
              }else{
                setSelectedStations(omit(selectedStations, tag.key));
              }
            }

            return newSelectedTags;

          });
        }
      },
      closeModal : ({setSelectedStations, setSelectedTags, setAddedStations, onToggle}) => () => {
        setSelectedTags({});
        setSelectedStations({});
        setAddedStations({});
        onToggle();
      }
    }),
    withHandlers({
      updateSelectedStations : ({
        selectedStations, 
        setSelectedStations, 
        selectedTags, 
        updateSelectedTags
      }) => (checked, station) => {
        if(checked){
          setSelectedStations({...selectedStations, [station.key]: station});
        }else{

          let newSelectedStations = omit(selectedStations, station.key);
          setSelectedStations(newSelectedStations);

          forEach(selectedTags, (tag) => {
              if(isEmpty(intersection(keys(tag.stations), keys(newSelectedStations)))){
                updateSelectedTags(false, tag, tag.type, false);
              }
          }); 
        }
      },
      onSaveEntities: ({ checkPrivilegesForTeamAssignment, teams, allStationsAndTeams, setIsLoading, addedStations, closeModal, onSubmit, selectedUserId }) => () => {
        let newTeams = [];
        Object.values(addedStations).forEach((element) => {
          const stationData = Object.values(
            allStationsAndTeams
          ).find((item) => item.key === element.key);
          if (element.type === "team") {
            const teamDataSelected = find(
              teams,
              (team) => team.key === element.key
            );
            if (teamDataSelected) {
              newTeams = [...newTeams, teamDataSelected];
            } else {
              newTeams = [
                ...newTeams,
                { id: stationData.id },
              ];
            }
          }
        });
        if (newTeams.length) {
          checkPrivilegesForTeamAssignment(selectedUserId, map(newTeams, 'id'), () => {
            if (onSubmit) onSubmit(addedStations);
            closeModal();
          });
        } else {
          if (onSubmit) onSubmit(addedStations);
          closeModal();
        }
      },
    }),
    lifecycle({
      componentDidMount () {
        this.props.getAllStationsAndTeams();
      },
      componentDidUpdate(prevProps) {
      
        if(!isEqual(this.props.allStationsAndTeams, prevProps.allStationsAndTeams) || !isEqual(this.props.selectedEntitiesAndTeams, prevProps.selectedEntitiesAndTeams) || (this.props.isOpen && !isEqual(this.props.isOpen, prevProps.isOpen))){

          let selectedEntitiesAndTeams = {};

          filter(this.props.allStationsAndTeams, station => includes(keys(this.props.selectedEntitiesAndTeams), station.key))
                              .forEach(({key, station_key, market, format, team, type, roles, selected_stations, name, call_letters}) => {
                                if(type === STATION){
                                  selectedEntitiesAndTeams[key] = {
                                    key,
                                    station_key,
                                    market,
                                    format,
                                    team,
                                    name,
                                    call_letters
                                  };
                                }else{
                                  selectedEntitiesAndTeams[key] = {
                                    key,
                                    station_key,
                                    market,
                                    format,
                                    team,
                                    type : 'team',
                                    roles,
                                    stations : selected_stations,
                                    name,
                                    call_letters
                                  };
                                }
                              });
          this.props.setAddedStations(selectedEntitiesAndTeams);
        }
      }
    }),
  )(UserEntitiesAssignmentModal)
);
