import * as React from "react";
import PropTypes from "prop-types";
import { default as BaseCheckbox } from "rc-checkbox";
import "rc-checkbox/assets/index.css";
import bn from "utils/bemnames";

const bem = bn.create("radio");

class Radio extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    text: PropTypes.any,
    value: PropTypes.any
  };

  static defaultProps = {
    onChange: () => {},
    checked: false,
    disabled: false,
    text: ""
  };
  render() {
    const { onChange, text, checked, value, disabled } = this.props;
    return (
      <div className={bem.b()} style={this.props.style}>
        <div className={bem.e("radio")}>
          <BaseCheckbox
            type="radio"
            checked={checked}
            onChange={e => {
              onChange(e.target.checked);
            }}
            disabled={disabled}
            value={value}
          />
          {!!text && (
            <div
              className={bem.e("text")}
              onClick={() => (disabled ? "" : onChange(!checked))}
            >
              {text}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Radio;
