import * as React from "react";
import View from "./view";
import { get, find } from "lodash";
import { compose, withHandlers, withState } from "recompose";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { MdImportantDevices } from "react-icons/lib/md";
import ToastManager from "components/ToastManager";
import { updateSystemForm } from "store/actions/systemForms";
export default injectIntl(
  compose(
    connect(
      (state, { currentStation }) => {
        const { auth } = state;
        const stations = get(auth, "info.stations", []);
        return {
          sharedStationData: find(
            stations,
            (item) => item.key === get(currentStation, "value")
          ),
        };
      },
      { updateSystemForm }
    ),
    withState("isDirty", "setIsDirty", false),
    withState("listFields", "setListFields", "masterListenerCreate"),
    withState("deletingField", "setDeletingField", null),
    withState(
      "isOpenChannelPreviewModal",
      "setIsOpenChannelPreviewModal",
      false
    ),
    withState(
      "isOpenConfirmationDeleteModal",
      "setIsOpenConfirmationDeleteModal",
      false
    ),
    withState(
      "isOpenConfirmationActionsUnsavedModal",
      "setIsOpenConfirmationActionsUnsavedModal",
      false
    ),
    withState(
      "isOpenFieldsFormBuilderModal",
      "setIsOpenFieldsFormBuilderModal",
      false
    ),
    withHandlers({
      onSubmitForm: ({ updateSystemForm, intl, setIsDirty }) => (
        values,
        formikProps
      ) => {
        updateSystemForm(values, () => {
          setIsDirty(false);
          ToastManager.show({
            title: <MdImportantDevices />,
            message: intl.formatMessage({
              id: "form manager > update successfully",
            }),
            level: "success",
          });
        });
      },
    })
  )(View)
);
