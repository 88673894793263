import ClockCreator from "./view";
import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { get } from 'lodash';

import { compose, withState, withHandlers } from "recompose";

export default injectIntl(
  compose(
    connect(
      (state, ownProps) => {
        return {
          stations: get(state, "auth.info.stations", []),
        }
      },
    ),
    withState("isClockSaved", "setIsClockSaved", false),
    withState("isEditClicked", "setIsEditClicked", false),
    withHandlers({
      onSaveClock: ({ setIsClockSaved }) => (values) => {
        setIsClockSaved(true);
      },
    }),
  )(ClockCreator)
);