import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import DatePicker from "components/DatePicker";
import { Input, Label } from "reactstrap";
import { FormattedMessage } from "react-intl";
import bn from "utils/bemnames";
import { omit, get } from "lodash";
const bem = bn.create("textinput");

class TextInput extends React.Component {
  static propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    name: PropTypes.string,
    leftComponent: PropTypes.node,
    rightComponent: PropTypes.node,
    labelProps: PropTypes.object,
    ableToAddNew: PropTypes.bool, // whether to give functionality to add new textfield
    onAddNew: PropTypes.func,
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        error: PropTypes.string,
        name: PropTypes.string,
        value: PropTypes.string,
        type: PropTypes.string,
      })
    ), // this is useful in case of we want to support multiple textbox , see `ableToAddNew` prop
  };

  static defaultProps = {
    type: "text",
    labelProps: {},
    leftComponent: null,
    rightComponent: null,
    ableToAddNew: false,
    onAddNew: () => {},
  };

  renderRightComponent = () => {
    const { rightComponent } = this.props;
    if (!rightComponent) {
      return null;
    }
    return <div className={bem.e("right-component")}>{rightComponent}</div>;
  };

  renderLeftComponent = () => {
    const { leftComponent } = this.props;
    if (!leftComponent) {
      return null;
    }
    return <div className={bem.e("left-component")}>{leftComponent}</div>;
  };

  render() {
    const {
      label,
      labelProps,
      leftComponent,
      rightComponent,
      ableToAddNew,
      name,
      error,
      value,
      type,
      fields,
      onAddNew,
      onChange,
      colorIndicator,
      maxCharacters,
      ...rest
    } = this.props;
    let finalFields = fields || [{ name, error, value, type }];
    return (
      <div
        className={bem.b()}
        {...omit(rest, [
          "dateFormat",
          "openToDate",
          "min",
          "disabled",
          "allowShowIcon",
          "maxCharacters",
          "minDate",
          "maxDate",
        ])}
      >
        <div className={bem.e("input-label")}>
          {!!label && (
            <Label for={name} {...labelProps}>
              {label}
            </Label>
          )}
          {maxCharacters && (
            <span
              className={cx(
                bem.e("character-count", {
                  [bem.e("character-max")]:
                    get(value, "length", 0) > parseInt(maxCharacters),
                })
              )}
            >
              <FormattedMessage id="text input > character count" />:{" "}
              <strong>{get(value, "length", 0)}</strong>
            </span>
          )}
        </div>
        {finalFields.map((field, index) => (
          <div
            className="input-container"
            style={{ marginBottom: 5 }}
            key={"txt_" + index}
          >
            <div
              className={cx(bem.e("input-wrapper"), {
                //invalid: Boolean(error),
                textarea: field.type === "textarea",
              })}
            >
              {this.renderLeftComponent()}
              <div
                className={cx(bem.e("input-field"), {
                  textarea: field.type === "textarea",
                })}
              >
                {colorIndicator && (
                  <div
                    className="color-indicator"
                    style={{ backgroundColor: colorIndicator }}
                  ></div>
                )}
                {field.type === "date" ? (
                  <DatePicker
                    field={field}
                    idx={index}
                    onChange={onChange}
                    style={colorIndicator ? { paddingLeft: "33px" } : {}}
                    {...rest}
                    name={field.name}
                    popperModifiers={{
                      preventOverflow: {
                        enabled: true,
                        escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
                        boundariesElement: "viewport",
                      },
                    }}
                    onMonthChange={() => {
                      window.scrollBy(0, -1);
                      window.scrollBy(0, 1);
                    }}
                    onKeyDown={() => {
                      window.scrollBy(0, -1);
                      window.scrollBy(0, 1);
                    }}
                  />
                ) : (
                  <Input
                    type={field.type}
                    name={field.name}
                    //invalid={Boolean(field.error)}
                    value={field.value || ""}
                    style={colorIndicator ? { paddingLeft: "33px" } : {}}
                    onChange={(e) => {
                      onChange(e, index);
                    }}
                    {...rest}
                  />
                )}
              </div>
              {this.renderRightComponent()}
            </div>
            {!!field.error && (
              <span className="text-danger">{field.error}</span>
            )}
          </div>
        ))}
        {ableToAddNew && (
          <div className={bem.e("add-new-link")} onClick={onAddNew}>
            + Add New
          </div>
        )}
      </div>
    );
  }
}

export default TextInput;
