import React from "react";
import PropTypes from "prop-types";
import {
  Modal,
  ModalBody,
  Button,
  Row,
  Col,
  ModalHeader,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import classnames from "classnames";
import TextInput from "components/TextInput";
import bn from "utils/bemnames";
import { CloseIcon } from "components/CustomIcons";
import { FormattedMessage } from "react-intl";
import TextInputFormatted from "components/TextInputFormatted";
import { Formik } from "formik";
import * as Yup from "yup";
import { get, includes, xor } from "lodash";
import Checkbox from "components/Checkbox";
import moment from "moment";
import ToggleSwitch from "components/ToggleSwitch";
const bem = bn.create("advanced-search-modal");
const getPrizeStatus = ({ intl }) => {
  const prizeStatus = [
    {
      label: intl.formatMessage({ id: "winners > cash" }),
      value: "cash",
    },
    {
      label: intl.formatMessage({ id: "winners > non cash" }),
      value: "non_cash",
    },
  ];
  return prizeStatus;
};
const getW9Status = ({ intl }) => {
  const w9Status = [
    {
      label: intl.formatMessage({ id: "winners > signed" }),
      value: "signed",
    },
    {
      label: intl.formatMessage({ id: "winners > unsigned" }),
      value: "unsigned",
    },
  ];
  return w9Status;
};
function SearchForm(props) {
  const { onToggle, intl, onSubmit, initialValues } = props;
  const prizeStatusOptions = getPrizeStatus({ intl });
  const w9StatusOptions = getW9Status({ intl });
  return (
    <Formik
      initialValues={{
        prize_types: get(initialValues, "prize_types", []),
        keyword: get(initialValues, "keyword", ""),
        w9_status: get(initialValues, "w9_status", []),
        min_value: get(initialValues, "min_value", ""),
        start_date: get(initialValues, "start_date", ""),
        end_date: get(initialValues, "end_date", ""),
        prize_date_range: get(initialValues, "prize_date_range", "distributed"),
      }}
      validationSchema={Yup.object().shape({
        w9_status: Yup.mixed().nullable(),
        prize_types: Yup.mixed().nullable(),
        keyword: Yup.string().nullable(),
        start_date: Yup.date().nullable(),
        end_date: Yup.date()
          .when(
            "start_date",
            (startDate, schema) =>
              startDate &&
              schema.min(
                startDate,
                intl.formatMessage({
                  id: `validate > end date should be later than start date`,
                })
              )
          )
          .nullable(),
        min_value: Yup.string().nullable(),
        prize_date_range: Yup.string().nullable(),
      })}
      onSubmit={onSubmit}
    >
      {(formProps) => {
        const { values, touched, errors } = formProps;

        return (
          <Form onSubmit={formProps.handleSubmit}>
            <Row>
              <Col>
                <FormGroup>
                  <Label>
                    <FormattedMessage id="winners > prize type" />
                  </Label>
                  <div className={bem.e("prize-status")}>
                    {prizeStatusOptions.map((option, index) => (
                      <Checkbox
                        checked={!!includes(values.prize_types, option.value)}
                        onChange={() => {
                          const newValue = xor(values.prize_types, [
                            option.value,
                          ]);
                          formProps.setFieldValue("prize_types", newValue);
                        }}
                        text={option.label}
                        key={index}
                      />
                    ))}
                  </div>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>
                    <FormattedMessage id="winners > w9 status" />
                  </Label>
                  <div className={bem.e("prize-status")}>
                    {w9StatusOptions.map((option, index) => (
                      <Checkbox
                        checked={!!includes(values.w9_status, option.value)}
                        onChange={() => {
                          const newValue = xor(values.w9_status, [
                            option.value,
                          ]);
                          formProps.setFieldValue("w9_status", newValue);
                        }}
                        text={option.label}
                        key={index}
                      />
                    ))}
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <TextInput
                    label={<FormattedMessage id="winners > keyword(s)" />}
                    type="text"
                    name="keyword"
                    placeholder={intl.formatMessage({
                      id: "winners > enter keyword",
                    })}
                    value={values.keyword}
                    onChange={formProps.handleChange}
                    onBlur={formProps.handleBlur}
                    error={touched.keyword && errors.keyword}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <TextInputFormatted
                    label={
                      <FormattedMessage id="winners > minimum total winnings" />
                    }
                    type="number"
                    thousandSeparator={true}
                    allowNegative={false}
                    name="min_value"
                    placeholder={intl.formatMessage({
                      id: "winners > enter amount",
                    })}
                    value={values.min_value}
                    onChange={(value) => {
                      formProps.setFieldValue("min_value", value);
                    }}
                    onBlur={formProps.handleBlur}
                    error={touched.min_value && errors.min_value}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label>
                    <FormattedMessage id="winners > prize date range" />
                  </Label>
                  <ToggleSwitch
                    leftComponent={intl.formatMessage({
                      id: "winners > distributed",
                    })}
                    rightComponent={intl.formatMessage({
                      id: "winners > won",
                    })}
                    name="prize_date_range"
                    switchProps={{
                      checked: values.prize_date_range !== "distributed",
                      onChange: (checked) => {
                        formProps.setFieldValue(
                          "prize_date_range",
                          checked ? "won" : "distributed"
                        );
                      },
                      offColor: "#795AFA",
                      onColor: "#795AFA",
                      uncheckedIcon: false,
                      checkedIcon: false,
                      handleDiameter: 22,
                      width: 50,
                      height: 26,
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup className={bem.e("datepicker")}>
                  <TextInput
                    label={<FormattedMessage id="winners > start date" />}
                    type="date"
                    name="start_date"
                    placeholder={intl.formatMessage({
                      id: "winners > placeholder start date",
                    })}
                    value={values.start_date}
                    onChange={(value) => {
                      formProps.setFieldValue("start_date", value);

                      // clear order end date if order start date if after
                      if (value && values.end_date) {
                        const isAfterEndDate = moment(value).isAfter(
                          values.end_date
                        );
                        if (isAfterEndDate) {
                          formProps.setFieldValue("end_date", "");
                        }
                      }
                    }}
                    error={touched.start_date && errors.start_date}
                    allowShowIcon
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className={bem.e("datepicker")}>
                  <TextInput
                    label={<FormattedMessage id="winners > end date" />}
                    type="date"
                    name="end_date"
                    placeholder={intl.formatMessage({
                      id: "winners > placeholder end date",
                    })}
                    min={get(values, "start_date", null)}
                    value={values.end_date}
                    onChange={(value) =>
                      formProps.setFieldValue("end_date", value)
                    }
                    error={touched.end_date && errors.end_date}
                    allowShowIcon
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className={bem.e("buttons")}>
                  <Button
                    color="primary"
                    className={classnames("btn-radius", bem.e("btn-search"))}
                    type="submit"
                  >
                    <FormattedMessage id="process > button search" />
                  </Button>
                  <Button
                    color="primary"
                    outline
                    onClick={onToggle}
                    className={classnames("btn-radius", bem.e("btn-cancel"))}
                  >
                    <FormattedMessage id="process > button cancel" />
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
}
const AdvancedSearchModal = (props) => {
  const { isOpen, onToggle } = props;
  return (
    <Modal isOpen={isOpen} className={bem.b()} centered>
      <ModalHeader toggle={onToggle} className={bem.e("header")}>
        <FormattedMessage id="process > title modal advanced search" />
        <div className={bem.e("close")} onClick={onToggle}>
          <CloseIcon color="#657894" />
        </div>
      </ModalHeader>
      <ModalBody className={bem.e("body")}>
        <SearchForm {...props} />
      </ModalBody>
    </Modal>
  );
};

AdvancedSearchModal.propTypes = {
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
  initialValues: PropTypes.object,
};

AdvancedSearchModal.defaultProps = {
  isOpen: false,
  onToggle: () => {},
  initialValues: {},
};

export default AdvancedSearchModal;
