import View from "./view";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { compose, withState, withHandlers } from "recompose";
import { downloadFiles } from "store/actions/uploads";
import { filter, get, replace, findIndex } from "lodash";
import { saveAs } from "file-saver";
import { labelToNameField, validURL } from "utils/helpers";
import moment from "moment";
import JSZip from "jszip";
function checkIsPlacementConfirmation(channel) {
  if (
    !get(channel, "placement_url_link") &&
    get(channel, "placement_files.length", 0) === 0
  )
    return false;

  return (
    validURL(get(channel, "placement_url_link")) ||
    get(channel, "placement_files.length", 0) > 0
  );
}

export default injectIntl(
  compose(
    connect((state, props) => ({ ...props, user: state.auth.user, dateFormatByServer: get(
      state,
      "servers.currentServer.data.date_format",
      "MM/DD/YYYY"
    ), }), {
      downloadFiles,
    }),
    withState("isOpenConfirmationModal", "setIsOpenConfirmationModal", false),
    withState("isLoading", "setIsLoading", false),
    withState(
      "isCollapse",
      "setIsCollapse",
      ({ channel, process }) => process.key === channel.es_id
    ),
    withState(
      "currentPreviewMediaModalOpen",
      "setCurrentPreviewMediaModalOpen",
      null
    ),
    withHandlers({
      onAutoSubmit: ({ buttonClicked, template, user, step }) => (
        process,
        params = {}
      ) => {
        // Add params to send notification to sales
        let paramButton = {
          ...step.fields.find((f) => f.key === "save_button"),
          ...params,
        };
        buttonClicked(
          step,
          paramButton,
          process,
          template,
          user,
          null,
          () => {},
          true
        );
      },
    }),
    withHandlers({
      downloadAllMedia: ({
        channel,
        downloadFiles,
        setIsLoading,
      }) => async () => {
        let files = [];
        filter(
          channel.fields,
          (item) => item.key === "media_for_review_approval"
        ).forEach((field) => {
          const fieldName = labelToNameField(field.label);
          if (get(channel, fieldName, [])) {
            const value = get(channel, fieldName, []).map((item) => ({
              ...item,
              path: replace(get(item, "path", ""), "https://", "http://"),
            }));
            files = [...files, ...value];
          }
        });
        if (files.length === 0) return;
        const zip = new JSZip();
        setIsLoading(true);
        downloadFiles(files, (response) => {
          if (get(response, "files")) {
            get(response, "files", []).forEach((file, index) => {
              zip.file(`${index}-${file.name}`, file.base64, {
                base64: true,
              });
            });
            zip.generateAsync({ type: "blob" }).then(function(content) {
              const folderName = replace(
                get(channel, "name", ""),
                new RegExp(" ", "g"),
                "_"
              );
              saveAs(content, `${folderName}.zip`);
            });
          }
          setIsLoading(false);
        });
      },
      onValueChanged: ({ step, template, user, valueChanged, process }) => (
        index,
        { field, value }
      ) => {
        const marketingChannels = get(
          process,
          "data.fields.marketing_channels.value",
          []
        );
        const channelField = step.fields.find(
          (f) => f.field === "marketing_channels"
        );
        const channel = marketingChannels[index];
        if (channel) {
          marketingChannels[index] = {
            ...channel,
            [field]: value,
          };
          valueChanged(channelField, process, template, user, {
            value: marketingChannels,
          });
        }
      },
      onSubmit: ({
        channel,
        onAutoSubmit,
        valueChanged,
        template,
        user,
        step,
        process,
        setShouldBlockNavigation,
        setBlockNavigationAction,
      }) => (action = "done") => {
        let buttonParams = {};
        const marketingChannelsField = step.fields.find(
          (f) => f.field === "marketing_channels"
        );
        let channels = get(process, "data.fields.marketing_channels.value", []);
        const channelIndex = findIndex(
          channels,
          (item) => item.id === channel.id
        );
        if (channelIndex === -1) return;
        const isPlacementConfirmation = checkIsPlacementConfirmation(channel);
        const isRequiredPlacement = get(
          channel,
          "is_required_placement",
          false
        );
        // the channel is required placement
        /**
         * If the Channel Manager -> provided the placement confirmation -> this task will remove on Current Tasks
         * If the Channel Manager -> not provide the placement confirmation -> this task will still on Current Tasks
         */
        if (isRequiredPlacement && action === "done") {
          channels[channelIndex] = {
            ...channel,
            completed_ts: moment().unix(),
            channel_step:
              action === "done" && isPlacementConfirmation
                ? "online"
                : "traffic",
          };
          setShouldBlockNavigation(!isPlacementConfirmation);
          if (setBlockNavigationAction) setBlockNavigationAction("trafficked");
        } else {
          channels[channelIndex] = {
            ...channel,
            completed_ts: action === "done" ? moment().unix() : null,
            channel_step: action === "done" ? "online" : "traffic",
          };
        }

        if (action === "done") {
          buttonParams = {
            notification: {
              users: [
                get(
                  process,
                  "data.fields.role_digital_account_executive.value.id"
                ),
              ],
              type: "trafficked",
              is_send_mail: false,
              is_send_notification: true,
            },
          };
        }

        valueChanged(marketingChannelsField, process, template, user, {
          value: channels,
        });
        process.data.fields.marketing_channels = {
          value: channels,
          is_dirty: true,
        };
        // This will ignore validate for placement confirmation
        delete process.data.loaded_fields.channel_id;
        onAutoSubmit(process, buttonParams);
      },
      onSave: ({
        channel,
        buttonClicked,
        process,
        step,
        template,
        user,
        valueChanged,
      }) => () => {
        let channels = get(process, "data.fields.marketing_channels.value", []);
        const channelIndex = findIndex(
          channels,
          (item) => item.id === channel.id
        );
        if (channelIndex === -1) return;
        const isPlacementConfirmation = checkIsPlacementConfirmation(channel);
        const isRequiredPlacement = get(
          channel,
          "is_required_placement",
          false
        );
        if (isRequiredPlacement && isPlacementConfirmation) {
          // if Channel manager trafficked the channel -> then provided the placement confirmation -> the task will remove from Current tasks and move status to online
          const marketingChannelsField = step.fields.find(
            (f) => f.field === "marketing_channels"
          );
          const channelStep = channel.completed_ts
            ? "online"
            : channel.channel_step;
          channels[channelIndex] = {
            ...channel,
            channel_step: channelStep,
          };
          valueChanged(marketingChannelsField, process, template, user, {
            value: channels,
          });
          process.data.fields.marketing_channels = {
            value: channels,
            is_dirty: true,
          };
        }
        // This will mark current channel id for validate
        process.data.loaded_fields.channel_id = {
          value: channel.id,
          is_dirty: true,
        };
        // Add params to send notification + email to sales
        let paramButton = {
          ...step.fields.find((f) => f.key === "save_button"),
          notification: {
            users: [
              get(
                process,
                "data.fields.role_digital_account_executive.value.id"
              ),
            ],
            type: "placement_information",
            is_send_mail: true,
            is_send_notification: true,
          },
        };
        buttonClicked(
          step,
          paramButton,
          process,
          template,
          user,
          null,
          null,
          true
        );
      },
    })
  )(View)
);
