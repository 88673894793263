import React, { useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import bn from "utils/bemnames";
import TextareaEvaluator from "components/TextareaEvaluator";
import classnames from "classnames";
import { setScriptField } from "utils/helpers";
import QuillEditor from "components/QuillEditor";
const bem = bn.create("client-review");

const ScriptItem = (props) => {
  const {
    item,
    isBookend,
    spot,
    process,
    onChangeFeedback,
    isDisabled,
    isOpenFeedback,
    renderButtons,
    intl,
    renderSummary,
  } = props;
  const [value, setValue] = useState("");
  return (
    <div className={bem.e("slide-script-item")}>
      <div className={bem.e("script-recording-header")}>
        <FormattedMessage id={"process > title final script"} />
      </div>
      {isBookend && (
        <div className="script-part-header">
          {item.key === "script" ? (
            <FormattedMessage id="process > part A" />
          ) : (
            <FormattedMessage id="process > part B" />
          )}
        </div>
      )}
      <div className={bem.e("script")}>
        <div className={classnames(bem.e("script-height"), "scroll-bar-style")}>
          <TextareaEvaluator
            readOnly={true}
            isOrderLength={false}
            hideOrderLength={true}
            showScriptLength={false}
            text={setScriptField(item.script)}
            process={process}
            onEvaluatorCallback={() => {}}
            spot={spot}
          />
        </div>
      </div>
      {/* render buttons for mobile */}
      {renderSummary ? renderSummary : null}
      {renderButtons ? renderButtons : null}
      {isOpenFeedback && (
        <div className={bem.e("production-change-request")}>
          <div className={bem.e("script-recording-header")}>
            <FormattedMessage id={"process > feedback"} />
          </div>
          <div className={bem.e("feedback-description")}>
            <FormattedMessage id="process > client review feedback helper text" />
          </div>
          <QuillEditor
            placeholder={intl.formatMessage({
              id: "process > leave feedback here",
            })}
            value={value}
            onChange={(value) => {
              if (isDisabled) return;
              setValue(value);
              onChangeFeedback(item.key, "feedback", value);
            }}
            readOnly
            toolbarOptions={[["bold", "italic", "underline", { color: [] }]]}
          />
          {/* <TextareaEvaluator
            text={setScriptField(value)}
            isOrderLength={false}
            hideOrderLength={true}
            process={process}
            spot={spot}
            placeholder={intl.formatMessage({
              id: "process > leave feedback here",
            })}
            onEvaluatorCallback={(value) => {
              if (isDisabled) return;
              setValue(value);
              onChangeFeedback(item.key, "feedback", value);
            }}
            spellCheck={false}
            isShowSlider={false}
            title={
              <React.Fragment>
                <div className={bem.e("section-title-editor")}>
                  <FormattedMessage id="process > feedback" />
                </div>
                <div className={bem.e("feedback-description")}>
                  <FormattedMessage id="process > client review feedback helper text" />
                </div>
              </React.Fragment>
            }
            isBookend={isBookend}
            part={item.field === "script" ? "a" : "b"}
            hideHelperText={true}
            showInnerTitle={false}
            showScriptLength={false}
          /> */}
        </div>
      )}
    </div>
  );
};
export default injectIntl(ScriptItem);
