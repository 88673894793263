import React from "react";
import bn from "utils/bemnames";
import PropTypes from "prop-types";
import Checkbox from "components/Checkbox";
import {
  AlertIcon,
  SwapIcon,
  ViewIcon,
  CloseSmallIcon,
  DeleteSmallIcon,
} from "components/CustomIcons";
import { FormattedMessage } from "react-intl";
import { Button } from "reactstrap";
import classNames from "classnames";
import truncate from "truncate";
import moment from "moment";
import { first, get, map } from "lodash";
import { convertArrayToString } from "utils/helpers";
const bem = bn.create("schedule-swap");

export default function ScheduleSwap(props) {
  const {
    action,
    type,
    title,
    onViewClick,
    onSwapClick,
    item,
    // status,
    onSelectSwap,
    isShowSwap,
    isShowActionView,
    isShowOverride,
    isConfirmOverride,
    setIsConfirmOverride,
    isShowActionSwap,
    isShowActionDelete,
    rulesOverride,
    intl,
    onClose,
    onDeleteClick,
    dateFormatByServer,
  } = props;
  if (!item) return null;
  const timeFormatted = moment.utc(item.time * 1000 * 60).format("h:mm a");

  const isShowDetails = action === "click";
  const isShowErrorOverride = action === "hover" && type === "override";
  const labelData = item.labels ? first(item.labels) : null;
  const rulesOverrideText =
    rulesOverride.length > 1
      ? rulesOverride
      : map(rulesOverride, (rule) => `${rule} rule`);
  return (
    <div className={bem.b()}>
      {type === "on_hold" ? (
        <span className={bem.e("hold-status")}>
          <FormattedMessage id="process > promotion > liner > schedule > on hold" />
        </span>
      ) : type === "fill" ? (
        <span className={bem.e("hold-status")}>
          <FormattedMessage id="process > promotion > liner > schedule > fill" />
        </span>
      ) : null}

      <div className={bem.e("swap-content")}>
        <span className={bem.e("close-icon")} onClick={onClose}>
          <CloseSmallIcon />
        </span>
        <span className={bem.e("text-time")}>{timeFormatted}</span>
        <span className={bem.e("title")}>{title}</span>
        {/* empty slot with multiple types */}
        {get(item, "labels.length", 0) > 1 ? (
          map(item.labels, (type, index) => (
            <div className={bem.e("slot-item")} key={index}>
              <span
                className={bem.e("slot-color")}
                style={{
                  backgroundColor: get(type, "color"),
                }}
              />
              <span className={bem.e("slot-text")}>
                {truncate(
                  `${get(type, "type_name")} - ${get(type, "type_label")}`,
                  35
                )}
              </span>
            </div>
          ))
        ) : labelData ? (
          <div className={bem.e("slot-item")}>
            <span
              className={bem.e("slot-color")}
              style={{
                backgroundColor: get(labelData, "color"),
              }}
            />
            <span className={bem.e("slot-text")}>
              {truncate(
                `${get(labelData, "type_name")} - ${get(
                  labelData,
                  "type_label"
                )}`,
                35
              )}
            </span>
          </div>
        ) : null}

        {isShowErrorOverride && (
          <span className={bem.e("error-override-hover")}>
            <AlertIcon />
            <FormattedMessage id="process > promotion > liner > schedule > error message swap override hover" />
          </span>
        )}
        {isShowDetails && (
          <React.Fragment>
            {get(item, "order_start_date") && get(item, "order_end_date") ? (
              <div className={bem.e("slot-date-time")}>
                {moment(item.order_start_date, "YYYY-MM-DD").format(
                  dateFormatByServer
                )}{" "}
                -{" "}
                {moment(item.order_end_date, "YYYY-MM-DD").format(
                  dateFormatByServer
                )}
              </div>
            ) : null}

            {get(item, "order_process_id") ? (
              <div className={bem.e("slot-table")}>
                <span className={bem.e("slot-table-row")}>
                  <span className={bem.e("slot-number")}>
                    {get(item, "total_allocated")}
                  </span>
                  <span className={bem.e("slot-text")}>
                    <FormattedMessage id="process > promotion > liner > schedule > total allocated" />
                  </span>
                </span>
                <span className={bem.e("slot-table-row")}>
                  <span className={bem.e("slot-number")}>
                    {get(item, "total_ran")}
                  </span>
                  <span className={bem.e("slot-text")}>
                    <FormattedMessage id="process > promotion > liner > schedule > ran" />
                  </span>
                </span>
                <span className={bem.e("slot-table-row")}>
                  <span className={bem.e("slot-number")}>
                    {get(item, "yet_to_run")}
                  </span>
                  <span className={bem.e("slot-text")}>
                    <FormattedMessage id="process > promotion > liner > schedule > yet to run" />
                  </span>
                </span>
              </div>
            ) : null}
            <div className={bem.e("actions")}>
              {!isShowSwap ? (
                <React.Fragment>
                  {isShowActionDelete ? (
                    <span
                      className={bem.e("action-button")}
                      onClick={() => {
                        onDeleteClick(item);
                      }}
                    >
                      <DeleteSmallIcon />
                      <FormattedMessage id="process > promotion > liner > schedule > delete" />
                    </span>
                  ) : null}
                  <span className={bem.e("action-right-buttons")}>
                    {isShowActionSwap ? (
                      <span
                        className={classNames(
                          bem.e("action-button"),
                          bem.e("action-swap")
                        )}
                        onClick={() => {
                          // setIsShowSwap(true);
                          onSelectSwap(item);
                        }}
                      >
                        <SwapIcon />
                        <FormattedMessage id="process > promotion > liner > schedule > swap" />
                      </span>
                    ) : null}

                    {isShowActionView ? (
                      <span
                        className={bem.e("action-button")}
                        onClick={() => onViewClick(item)}
                      >
                        <ViewIcon />
                        <FormattedMessage id="process > promotion > liner > schedule > view" />
                      </span>
                    ) : null}
                  </span>
                </React.Fragment>
              ) : (
                <div className={bem.e("action-swap")}>
                  {isShowOverride ? (
                    <Checkbox
                      text={
                        <span>
                          {intl.formatMessage({
                            id:
                              rulesOverride.length > 1
                                ? "process > promotion > liner > schedule > override rules"
                                : "process > promotion > liner > schedule > override rule",
                          })}
                          <strong>
                            {convertArrayToString(rulesOverrideText)}
                          </strong>
                          {intl.formatMessage({
                            id:
                              rulesOverride.length > 1
                                ? "process > promotion > liner > schedule > error message swap override rules"
                                : "process > promotion > liner > schedule > error message swap override a rule",
                          })}
                        </span>
                      }
                      checkStyle={{ color: "#fff", width: "8", height: "7.37" }}
                      checked={isConfirmOverride}
                      onChange={(checked) => setIsConfirmOverride(checked)}
                    />
                  ) : null}
                  <Button
                    color="blue"
                    disabled={!isConfirmOverride}
                    className={classNames(bem.e("btn-swap"), "btn-radius")}
                    onClick={() => onSwapClick(item)}
                  >
                    <FormattedMessage id="process > promotion > liner > schedule > swap" />
                  </Button>
                </div>
              )}
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
}
ScheduleSwap.propTypes = {
  type: PropTypes.oneOf(["regular", "on_hold", "override", "fill"]).isRequired,
  item: PropTypes.any,
  title: PropTypes.string.isRequired,
  action: PropTypes.oneOf(["hover", "click"]),
  onViewClick: PropTypes.func,
  onSwapClick: PropTypes.func,
  status: PropTypes.oneOf([
    "collapsed",
    "empty",
    "filled",
    "on_hold",
    "filled-same",
    "swapped",
    "ineligible",
    "fill",
  ]).isRequired,
  onSelectSwap: PropTypes.func,
  isShowActionView: PropTypes.bool,
  isShowActionSwap: PropTypes.bool,
  multipleTypes: PropTypes.array,
};
ScheduleSwap.defaultProps = {
  type: "regular",
  action: "click",
  status: "empty",
  title: (
    <FormattedMessage id="process > promotion > liner > schedule > empty" />
  ),
  onViewClick: () => {},
  onSwapClick: () => {},
  onSelectSwap: () => {},
  onDeleteClick: () => {},
  isShowActionView: false,
  isShowActionSwap: true,
  isShowActionDelete: true,
  multipleTypes: [],
};
