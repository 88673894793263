// cities
export const FETCH_CITIES_REQUEST = "FETCH_CITIES_REQUEST";
export const FETCH_CITIES_SUCCESS = "FETCH_CITIES_SUCCESS";
export const FETCH_CITIES_ERROR = "FETCH_CITIES_ERROR";

export const UPDATE_CITIES_REQUEST = "UPDATE_CITIES_REQUEST";
export const UPDATE_CITIES_SUCCESS = "UPDATE_CITIES_SUCCESS";
export const UPDATE_CITIES_ERROR = "UPDATE_CITIES_ERROR";

export const ADD_CITY_REQUEST = "ADD_CITY_REQUEST";
export const ADD_CITY_SUCCESS = "ADD_CITY_SUCCESS";
export const ADD_CITY_ERROR = "ADD_CITY_ERROR";

export const UPDATE_CITY_REQUEST = "UPDATE_CITY_REQUEST";
export const UPDATE_CITY_SUCCESS = "UPDATE_CITY_SUCCESS";
export const UPDATE_CITY_ERROR = "UPDATE_CITY_ERROR";

export const DELETE_CITY_REQUEST = "DELETE_CITY_REQUEST";
export const DELETE_CITY_ERROR = "DELETE_CITY_ERROR";
export const DELETE_CITY_SUCCESS = "DELETE_CITY_SUCCESS";

export const FETCH_TAG_TYPES_REQUEST = "FETCH_TAG_TYPES_REQUEST";
export const FETCH_TAG_TYPES_SUCCESS = "FETCH_TAG_TYPES_SUCCESS";
export const FETCH_TAG_TYPES_ERROR = "FETCH_TAG_TYPES_ERROR";

export const ORDER_CITIES_REQUEST = "ORDER_CITIES_REQUEST";
export const ORDER_CITIES_SUCCESS = "ORDER_CITIES_SUCCESS";
export const ORDER_CITIES_ERROR = "ORDER_CITIES_ERROR";
// groups

export const FETCH_GROUPS_REQUEST = "FETCH_GROUPS_REQUEST";
export const FETCH_GROUPS_SUCCESS = "FETCH_GROUPS_SUCCESS";
export const FETCH_GROUPS_ERROR = "FETCH_GROUPS_ERROR";

export const UPDATE_GROUPS_REQUEST = "UPDATE_GROUPS_REQUEST";
export const UPDATE_GROUPS_SUCCESS = "UPDATE_GROUPS_SUCCESS";
export const UPDATE_GROUPS_ERROR = "UPDATE_GROUPS_ERROR";

export const ADD_GROUP_REQUEST = "ADD_GROUP_REQUEST";
export const ADD_GROUP_SUCCESS = "ADD_GROUP_SUCCESS";
export const ADD_GROUP_ERROR = "ADD_GROUP_ERROR";

export const UPDATE_GROUP_REQUEST = "UPDATE_GROUP_REQUEST";
export const UPDATE_GROUP_SUCCESS = "UPDATE_GROUP_SUCCESS";
export const UPDATE_GROUP_ERROR = "UPDATE_GROUP_ERROR";

export const DELETE_GROUP_REQUEST = "DELETE_GROUP_REQUEST";
export const DELETE_GROUP_ERROR = "DELETE_GROUP_ERROR";
export const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS";

export const ORDER_GROUPS_REQUEST = "ORDER_GROUPS_REQUEST";
export const ORDER_GROUPS_SUCCESS = "ORDER_GROUPS_SUCCESS";
export const ORDER_GROUPS_ERROR = "ORDER_GROUPS_ERROR";

export const ADD_UNRATED_MARKET_REQUEST = "ADD_UNRATED_MARKET_REQUEST";
export const ADD_UNRATED_MARKET_SUCCESS = "ADD_UNRATED_MARKET_SUCCESS";
export const ADD_UNRATED_MARKET_ERROR = "ADD_UNRATED_MARKET_ERROR";