import React, { useRef } from "react";
import bn from "utils/bemnames";
import { Row, Col, Button, Form, FormGroup } from "reactstrap";
import { Asterisk } from "components/Elements";
import TextInput from "components/TextInput";
import { Formik } from "formik";
import {
  PhoneNumberFormat,
  statesList,
  SocialSecurityFormat,
  genderList,
} from "utils/config";
import RadioButtons from "components/RadioButtons";
import ToastManager from "components/ToastManager";
import Dropdown from "components/Dropdown";
import TextInputFormatted from "components/TextInputFormatted";
import * as Yup from "yup";
import { isEqual, get, find } from "lodash";
import { FormattedMessage } from "react-intl";
import BlockingComponent from "components/BlockingComponent";
import { PRIVILEGES } from "utils/constants";
import PrivilegedComponent from "components/PrivilegedComponent";
import { validSSN } from "utils/helpers";
const bem = bn.create("winner-details");

export default function ParticipantEdit(props) {
  const {
    intl,
    shouldBlockNavigation,
    setShouldBlockNavigation,
    isLoading,
    winner,
    onView,
    serverCountry,
    dateFormatByServer,
  } = props;
  const formikRef = useRef();
  const initialValues = {
    first_name: get(winner, "first_name", ""),
    last_name: get(winner, "last_name", ""),
    id: get(winner, "id", ""),
    home_address_1: get(winner, "home_address_1", ""),
    home_address_2: get(winner, "home_address_2", ""),
    home_city: get(winner, "home_city", ""),
    home_state_province: get(winner, "home_state_province", ""),
    home_zip_postal_code: get(winner, "home_zip_postal_code", ""),
    phone_number: get(winner, "phone_number", ""),
    email_address: get(winner, "email_address", ""),
    date_of_birth: get(winner, "date_of_birth", ""),
    social_security: get(winner, "social_security", ""),
    gender: get(winner, "gender", "female"),
  };
  const formElement = (
    <div className={bem.e("participant-edit")}>
      <Formik
        ref={formikRef}
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          first_name: Yup.string()
            .required(
              props.intl.formatMessage({
                id: "validate > first name is required",
              })
            )
            .min(
              2,
              props.intl.formatMessage({
                id: "validate > the first name must be at least 2 characters.",
              })
            ),
          last_name: Yup.string().required(
            props.intl.formatMessage({
              id: "validate > last name is required",
            })
          ),
          home_address_1: Yup.string().required(
            props.intl.formatMessage({
              id: "validate > address 1 is required",
            })
          ),
          home_address_2: Yup.string().nullable(),
          home_city: Yup.string().required(
            props.intl.formatMessage({
              id: "validate > city is required",
            })
          ),
          home_state_province: Yup.string().required(
            props.intl.formatMessage({
              id:
                serverCountry === "CA"
                  ? "validate > province is required"
                  : "validate > state is required",
            })
          ),
          home_zip_postal_code: Yup.string()
            .required(
              props.intl.formatMessage({
                id:
                  serverCountry === "CA"
                    ? "validate > postal code is required"
                    : "validate > zip is required",
              })
            )
            .test(
              "len",
              intl.formatMessage({
                id:
                  serverCountry === "CA"
                    ? "validate > postal code must be 5 numeric digits"
                    : `validate > zip must be 5 numeric digits`,
              }),
              (val) => !val || val.length === 5
            ),
          email_address: Yup.string()
            .email(
              intl.formatMessage({
                id: "validate > invalid email",
              })
            )
            .required(
              intl.formatMessage({
                id: "validate > email is required",
              })
            ),
          phone_number: Yup.string()
            .required(
              intl.formatMessage({
                id: "validate > phone is required",
              })
            )
            .test(
              "len",
              intl.formatMessage({
                id: "validate > phone number must be 10 digits",
              }),
              (val) => !val || val.length === 10
            ),
          date_of_birth: Yup.string().nullable(),
          gender: Yup.string().nullable(),
          social_security: Yup.string()
            .nullable()
            .test(
              "ssn",
              intl.formatMessage({
                id: `validate > invalid SSN`,
              }),
              (val) => validSSN(val)
            ),
        })}
        onSubmit={props.onSubmit}
      >
        {(formProps) => {
          const { errors, touched, values } = formProps;
          const isChanged = !isEqual(initialValues, values);
          if (isChanged !== shouldBlockNavigation) {
            setTimeout(() => setShouldBlockNavigation(isChanged), 20);
          }
          const selectedState = find(
            statesList,
            (item) => item.value === values.home_state_province
          );
          return (
            <Form onSubmit={formProps.handleSubmit}>
              <div className={bem.e("content-form")}>
                <div className={bem.e("form-fields")}>
                  <Row>
                    <Col xs={6} className={bem.e("col-reset")}>
                      <FormGroup>
                        <TextInput
                          label={
                            <span>
                              <FormattedMessage id="winners > first name" />
                              <Asterisk>*</Asterisk>
                            </span>
                          }
                          type="text"
                          name="first_name"
                          placeholder={props.intl.formatMessage({
                            id: "winners > enter first name",
                          })}
                          value={values.first_name}
                          error={touched.first_name && errors.first_name}
                          onChange={formProps.handleChange}
                          onBlur={formProps.handleBlur}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={6} className={bem.e("col-reset")}>
                      <FormGroup>
                        <TextInput
                          label={
                            <span>
                              <FormattedMessage id="winners > last name" />
                              <Asterisk>*</Asterisk>
                            </span>
                          }
                          type="text"
                          name="last_name"
                          placeholder={props.intl.formatMessage({
                            id: "winners > enter last name",
                          })}
                          value={values.last_name}
                          error={touched.last_name && errors.last_name}
                          onChange={formProps.handleChange}
                          onBlur={formProps.handleBlur}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} className={bem.e("col-reset")}>
                      <FormGroup>
                        <TextInput
                          label={
                            <span>
                              <FormattedMessage id="winners > address 1" />
                              <Asterisk>*</Asterisk>
                            </span>
                          }
                          type="text"
                          name="home_address_1"
                          placeholder={props.intl.formatMessage({
                            id: "winners > enter address",
                          })}
                          value={values.home_address_1}
                          error={
                            touched.home_address_1 && errors.home_address_1
                          }
                          onChange={formProps.handleChange}
                          onBlur={formProps.handleBlur}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={6} className={bem.e("col-reset")}>
                      <FormGroup>
                        <TextInput
                          label={<FormattedMessage id="winners > address 2" />}
                          type="text"
                          name="home_address_2"
                          placeholder={props.intl.formatMessage({
                            id: "winners > enter address",
                          })}
                          value={values.home_address_2}
                          error={
                            touched.home_address_2 && errors.home_address_2
                          }
                          onChange={formProps.handleChange}
                          onBlur={formProps.handleBlur}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={4} className={bem.e("col-reset")}>
                      <FormGroup>
                        <TextInput
                          label={
                            <span>
                              <FormattedMessage id="winners > city" />
                              <Asterisk>*</Asterisk>
                            </span>
                          }
                          type="text"
                          name="home_city"
                          placeholder={props.intl.formatMessage({
                            id: "winners > enter city",
                          })}
                          value={values.home_city}
                          error={touched.home_city && errors.home_city}
                          onChange={formProps.handleChange}
                          onBlur={formProps.handleBlur}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={4} className={bem.e("col-reset")}>
                      <FormGroup>
                        <Dropdown
                          label={
                            <span>
                              <FormattedMessage
                                id={
                                  serverCountry === "CA"
                                    ? "winners > province"
                                    : "winners > state"
                                }
                              />
                              <Asterisk>*</Asterisk>
                            </span>
                          }
                          type="text"
                          name="home_state_province"
                          placeholder={intl.formatMessage({
                            id: "winners > select",
                          })}
                          value={selectedState}
                          error={
                            touched.home_state_province &&
                            errors.home_state_province
                          }
                          onChange={(option) => {
                            formProps.setFieldValue(
                              "home_state_province",
                              option.value
                            );
                          }}
                          onBlur={formProps.handleBlur}
                          options={statesList}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={4} className={bem.e("col-reset")}>
                      <FormGroup>
                        <TextInput
                          label={
                            <span>
                              <FormattedMessage
                                id={
                                  serverCountry === "CA"
                                    ? "winners > postal code"
                                    : "winners > zip"
                                }
                              />
                              <Asterisk>*</Asterisk>
                            </span>
                          }
                          name="home_zip_postal_code"
                          placeholder={props.intl.formatMessage({
                            id:
                              serverCountry === "CA"
                                ? "winners > enter postal code"
                                : "winners > enter zip",
                          })}
                          value={values.home_zip_postal_code}
                          error={
                            touched.home_zip_postal_code &&
                            errors.home_zip_postal_code
                          }
                          onChange={formProps.handleChange}
                          onBlur={formProps.handleBlur}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} className={bem.e("col-reset")}>
                      <FormGroup>
                        <TextInputFormatted
                          format={PhoneNumberFormat}
                          label={
                            <span>
                              <FormattedMessage id="winners > phone" />
                              <Asterisk>*</Asterisk>
                            </span>
                          }
                          type="text"
                          name="phone"
                          placeholder={intl.formatMessage({
                            id: "winners > enter phone",
                          })}
                          value={values.phone_number}
                          error={touched.phone_number && errors.phone_number}
                          onChange={(value) =>
                            formProps.setFieldValue("phone_number", value)
                          }
                          onBlur={formProps.handleBlur}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={6} className={bem.e("col-reset")}>
                      <FormGroup>
                        <TextInput
                          label={
                            <span>
                              <FormattedMessage id="winners > email" />
                              <Asterisk>*</Asterisk>
                            </span>
                          }
                          type="text"
                          name={"email_address"}
                          placeholder={intl.formatMessage({
                            id: "winners > enter email",
                          })}
                          value={values.email_address}
                          onChange={formProps.handleChange}
                          onBlur={formProps.handleBlur}
                          error={touched.email_address && errors.email_address}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} className={bem.e("col-reset")}>
                      <FormGroup>
                        <TextInput
                          label={
                            <FormattedMessage id="winners > date of birth" />
                          }
                          name="date_of_birth"
                          type="date"
                          placeholder={props.intl.formatMessage({
                            id: "winners > enter dob",
                          })}
                          value={values.date_of_birth}
                          error={touched.date_of_birth && errors.date_of_birth}
                          onChange={(value) => {
                            formProps.setFieldValue("date_of_birth", value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={6} className={bem.e("col-reset")}>
                      <FormGroup>
                        <TextInputFormatted
                          format={SocialSecurityFormat}
                          label={
                            <FormattedMessage id="winners > social security number" />
                          }
                          type="text"
                          name="security"
                          placeholder={intl.formatMessage({
                            id: "winners > enter security",
                          })}
                          value={values.social_security}
                          error={
                            touched.social_security && errors.social_security
                          }
                          onChange={(value) =>
                            formProps.setFieldValue("social_security", value)
                          }
                          onBlur={formProps.handleBlur}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} className={bem.e("col-reset")}>
                      <FormGroup>
                        <RadioButtons
                          label={
                            <span>
                              <FormattedMessage id="winners > gender" />
                              <Asterisk>*</Asterisk>
                            </span>
                          }
                          onSelected={(selected) => {
                            formProps.setFieldValue("gender", selected);
                          }}
                          selectedValue={values.gender}
                          buttons={genderList}
                          error={touched.gender && errors.gender}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className={bem.e("form-buttons")}>
                <PrivilegedComponent requires={[PRIVILEGES.EDIT_WINNER]}>
                  <Button
                    color="blue"
                    type="submit"
                    className="btn btn-radius"
                    disabled={isLoading}
                    onClick={() => {
                      setTimeout(() => {
                        const formErrors = get(
                          formikRef,
                          "current.state.errors"
                        );
                        if (formErrors && Object.keys(formErrors).length) {
                          ToastManager.show({
                            title: props.intl.formatMessage({
                              id: "toast > title not saved",
                            }),
                            message: props.intl.formatMessage({
                              id:
                                "toast > message error please correct the hilighted fields",
                            }),
                            level: "error",
                          });
                        }
                      }, 40);
                    }}
                  >
                    <FormattedMessage id={`winners > button submit`} />
                  </Button>
                </PrivilegedComponent>

                <Button
                  color="blue"
                  type="button"
                  outline
                  className="btn btn-radius"
                  onClick={onView}
                >
                  <FormattedMessage id={`winners > button cancel`} />
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
  return (
    <React.Fragment>
      {formElement}
      <BlockingComponent
        isBlocked={props.shouldBlockNavigation}
        modalProps={{
          isShowConfirm: true,
          isShowDiscard: false,
          isCancelConfirmedNavigation: true,
          onCancel: () => {
            props.setShouldBlockNavigation(false); // reset
          },
          // onConfirm: () => {
          //   const buttonSubmit = document.getElementById(`btn-submit`);
          //   // trigger click to submit the form
          //   if (buttonSubmit) buttonSubmit.click();
          // },
        }}
      />
    </React.Fragment>
  );
  // return (
  //   <WarnAboutUnsavedChanges
  //     when={shouldBlockNavigation}
  //     children={formElement}
  //   />
  // );
}
