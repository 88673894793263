import React from "react";
import Page from "components/Page";
import {
  Row,
  Col,
  Card,
  CardBody
} from "reactstrap";
import Editor from "components/Process/editorIndex";
import { FormattedMessage } from "react-intl";

const Template = props => {
  return ( 
    <Page title={<FormattedMessage id="pages > template" />} breadcrumbs={[{ name: "", active: true }]}>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <Editor  {...props}/>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default Template;
