import React from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import bn from "utils/bemnames";
import { get } from "lodash";
import { CopyIcon, CloseIcon } from "components/CustomIcons";
import { FormattedMessage } from "react-intl";
import { copyToClipboard } from "utils/helpers";
import ToastManager from "components/ToastManager";
import { MdImportantDevices } from "react-icons/lib/md";

const bem = bn.create("final-script-modal");
const DisplayScript = (props) => {
  const { script } = props;
  return (
    <div className={bem.e("script-item")}>
      <div
        className={bem.e("script")}
        dangerouslySetInnerHTML={{
          __html: script,
        }}
      />
    </div>
  );
};
const FinalScriptModal = (props) => {
  const { clientName, data } = props;
  const title = get(data, "title");
  const script = get(data, "script");
  const spotIndex = get(data, "spotIndex", 1);
  const orderLength = get(data, "orderLength");
  const onClick = (copyText) => {
    copyToClipboard(copyText);
    ToastManager.show({
      title: <MdImportantDevices />,
      message: props.intl.formatMessage({
        id: "process > copied",
      }),
      level: "success",
    });
  };
  return (
    <Modal isOpen={props.isOpen} toggle={props.onToggle} className={bem.b()}>
      <ModalHeader>
        {clientName && (
          <span className={bem.e("client-name")}>{clientName}</span>
        )}
        <div className={bem.e("head-section")}>
          <div className={bem.e("section-title")}>
            <span className={bem.e("spot-text")}>
              <FormattedMessage id="process > spot" />
              {` `}
              {Number(spotIndex) + 1}:
            </span>
            <span className={bem.e("title")}>{title}</span>
          </div>
          <div className={bem.e("spot-length")}>
            <FormattedMessage id="process > spot length" />:
            <strong>{orderLength}</strong>
          </div>
        </div>
        <div className={bem.e("close-icon")} onClick={props.onToggle}>
          <CloseIcon color="#657894" />
        </div>
      </ModalHeader>
      <ModalBody>
        <div className={bem.e("content-script")}>
          <DisplayScript script={script} />
        </div>
        <Button
          className={"btn btn-radius"}
          color="blue"
          onClick={() => onClick(script)}
        >
          <CopyIcon color="#fff" width={12} height={12} />
          <FormattedMessage id="process > copy to clipboard" />
        </Button>
      </ModalBody>
    </Modal>
  );
};

FinalScriptModal.prototype = {
  onToggle: PropTypes.func,
  onCancel: PropTypes.func,
  clientName: PropTypes.string,
  data: PropTypes.object,
};

FinalScriptModal.defaultProps = {
  onToggle: () => {},
  onCancel: () => {},
  clientName: "",
  data: {},
};

export default FinalScriptModal;
