import React, { useEffect, useRef, useState } from "react";
import bn from "utils/bemnames";
import ToggleSwitch from "components/ToggleSwitch";
import {
  DownIcon,
  UpIcon,
  MenuIcon,
  ReplacementIcon,
} from "components/CustomIcons";
import { get, includes} from "lodash";
import JockConsoleForm from "../Common/JockConsoleForm";
import { FormattedMessage } from "react-intl";
import useOutsideClick from "utils/useOutsideClick";
import classNames from "classnames";
import { formatDisplayDate } from "utils/helpers";
import { PRIVILEGES } from "utils/constants";
import { validatePrivileges } from "utils/helpers";

const bem = bn.create("jock-console-item");
function isAllowActiveWidget(widget) {
  const { type } = widget;
  switch (type) {
    case "social_media":
      return get(widget, "fields.links", []).find((link) => link.url);
    case "phone_numbers":
      return get(widget, "fields.numbers", []).find((link) => link.number);
    case "bulletin_board":
      // case "text_editor":
      return (
        get(widget, "fields.text") &&
        get(widget, "fields.text") !== "<p><br></p>"
      );
    case "bulletin_board_replacement":
      return (
        get(widget, "fields.replacement.text") &&
        get(widget, "fields.replacement.text") !== "<p><br></p>"
      );
    default:
      return true;
  }
}
const disabledDetails = [
  "trending_hashtags",
  "floating_contests",
  // "storm_closings",
  // "event_calendar",
  // "news",
];
export default function JockConsoleItem(props) {
  const {
    isOpen,
    toggleOpen,
    item,
    onSwitchActive,
    setCurrentActiveConfirmation,
    isHaveReplacementWidgets,
    privileges,
    user,
    disabledDragDrop,
    dateFormatByServer
  } = props;
  const detailsRef = useRef();
  const [isEditForm, setIsEditForm] = useState(false);
  const [values, setValues] = useState(item);
  useEffect(() => {
    if (item.id !== values.id) setValues(item);
  }, [item]);
  const [
    isOpenConfirmationActionsUnsavedModal,
    setIsOpenConfirmationActionsUnsavedModal,
  ] = useState(false);

  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
  useOutsideClick(detailsRef, (event) => {
    // if (event.clientX + 50 >= window.innerWidth) {
    //   return;
    // }
    // if (
    //   document.body.classList.contains("modal-open") ||
    //   shouldBlockNavigation
    // ) {
    //   const classNamesDisabled = [
    //     "react-select__clear-indicator",
    //     "disable-unsaved",
    //     "rc-tooltip-inner",
    //     "ignore-click-outside",
    //   ];
    //   const eventClasses = split(
    //     get(event, "target.parentElement.parentElement.className", ""),
    //     " "
    //   );
    //   let isDisabled =
    //     intersection(eventClasses, classNamesDisabled).length > 0 ||
    //     event.target.classList.contains("disable-unsaved");
    //   if (isDisabled) return;
    //   // setIsOpenConfirmationActionsUnsavedModal(shouldBlockNavigation);
    //   return;
    // }
    // if (isOpen && isOpenConfirmationActionsUnsavedModal === false) {
      // const { path } = event;
      // const isClickedActions =
      //   filter(Array.from(path), (item) => {
      //     return (
      //       item &&
      //       item.classList &&
      //       (item.classList.contains("ignore-click-outside") ||
      //         item.classList.contains("disable-unsaved"))
      //     );
      //   }).length > 0;
      // if (isClickedActions) return;
      // toggleOpen();
    // }
  });
  const onToggleOpen = () => {
    const isEditable = validatePrivileges({
      requires: [PRIVILEGES.CONFIGURE_CONSOLE],
      privileges,
      user,
    });
    setIsEditForm(isEditable);
    toggleOpen();
  };
  let replacementDates = [];
  const isShowReplacement =
    get(values, "type") === "bulletin_board_replacement";
  if (isShowReplacement) {
    if (get(values, "fields.replacement.start_date")) {
      replacementDates.push(
        formatDisplayDate(
          get(values, "fields.replacement.start_date"),
          dateFormatByServer
        )
      );
    }
    if (get(values, "fields.replacement.end_date")) {
      replacementDates.push(
        formatDisplayDate(
          get(values, "fields.replacement.end_date"),
          dateFormatByServer
        )
      );
    }
  }
  const isShowBaseline =
    item.type === "bulletin_board" && isHaveReplacementWidgets;

  const isDisabledDetails = includes(disabledDetails, item.type);
  const isNewWidget = get(item, "id", "").indexOf("_new") === 0;

  return (
    <div
      className={classNames(bem.b(), {
        [bem.m("activated")]: get(item, "is_enabled", false),
        [bem.m("none-activated")]: !get(item, "is_enabled", false),
      })}
      ref={detailsRef}
    >
      <div className={bem.e("jock-console-main")}>
        <div className={bem.e("jock-console-head")}>
          <div className={bem.e("jock-console-head-left")}>
            {!disabledDragDrop && (
              <span className={"menu-selection-icon"}>
                <MenuIcon />
              </span>
            )}

            <div className={bem.e("jock-console-title")}>
              <span
                className={bem.e("jock-console-color")}
                style={{
                  backgroundColor: get(values, "color", "")
                    ? get(values, "color", "")
                    : get(item, "color", ""),
                }}
              ></span>
              <span className={bem.e("jock-console-title-text")}>
                {get(values, "name") ? values.name : "Widget name"}
              </span>
            </div>
          </div>
          <div className={bem.e("jock-console-head-right")}>
            {isShowReplacement && (
              <div className={bem.e("replacement")}>
                <span className={bem.e("replacement-icon")}>
                  <ReplacementIcon />
                </span>
                <FormattedMessage id="jock console manager > replacement" />:
                <span className={bem.e("replacement-dates")}>
                  {replacementDates.join("-")}
                </span>
              </div>
            )}
            {isShowBaseline && (
              <div className={bem.e("replacement")}>
                <span className={bem.e("replacement-icon")}>
                  <ReplacementIcon />
                </span>
                <FormattedMessage id="jock console manager > baseline" />
              </div>
            )}
          </div>
          {!isDisabledDetails && (
            <button
              className={classNames(
                bem.e("button-down"),
                "ignore-click-outside"
              )}
              onClick={onToggleOpen}
            >
              {isOpen ? (
                <UpIcon
                  className={bem.e("button-up-icon")}
                  color="#795AFA"
                  width={24}
                  height={15.46}
                />
              ) : (
                <DownIcon
                  className={bem.e("button-down-icon")}
                  color="#795AFA"
                  width={24}
                  height={15.46}
                />
              )}
            </button>
          )}
        </div>
        <div
          className={classNames(bem.e("jock-console-details"), {
            [bem.e("jock-console-details-opened")]: isOpen,
          })}
        >
          <JockConsoleForm
            toggleOpen={toggleOpen}
            jockConsole={item}
            setValues={setValues}
            isOpenConfirmationActionsUnsavedModal={
              isOpenConfirmationActionsUnsavedModal
            }
            setIsOpenConfirmationActionsUnsavedModal={
              setIsOpenConfirmationActionsUnsavedModal
            }
            isOpen={isOpen}
            isEditForm={isEditForm}
            setIsEditForm={setIsEditForm}
            shouldBlockNavigation={shouldBlockNavigation}
            setShouldBlockNavigation={setShouldBlockNavigation}
            setCurrentActiveConfirmation={setCurrentActiveConfirmation}
            isHaveReplacementWidgets={isHaveReplacementWidgets}
            station={props.station}
          />
        </div>
      </div>
      <div className={bem.e("switch-action")}>
        <ToggleSwitch
          leftComponent={null}
          rightComponent={null}
          name="is_hidden"
          switchProps={{
            checked: get(item, "is_enabled", false),
            onChange: (checked) => {
              if (isNewWidget || !isAllowActiveWidget(item)) return;
              onSwitchActive(item, checked);
            },
            disabled: isNewWidget || !isAllowActiveWidget(item),
            offColor: "#C2D4E0",
            onColor: "#795AFA",
            uncheckedIcon: false,
            checkedIcon: false,
            boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.282)",
            height: 26.44,
            width: 48.48,
            handleDiameter: 22.92,
          }}
        />
      </div>
    </div>
  );
}
