import Tooltip from "rc-tooltip";
import React, { useState } from "react";
import { get } from "lodash";

const VoLabel = (props) => {
  const { bem, intl, number, userVo, onUpdateUser, status } = props;
  const [visible, setVisible] = useState(false);
  return (
    <Tooltip
      overlay={
        <div className={bem.e("tooltip-vo-label-text")}>
          {intl.formatMessage({
            id: "select team > click to edit the vo title",
          })}
        </div>
      }
      trigger={status !== "assigned" ? [] : ["hover"]}
      placement="top"
      destroyTooltipOnHide
      visible={visible}
      onVisibleChange={(data) => {
        setVisible(data);
      }}
      overlayClassName="tooltip-vo-label"
    >
      <input
        placeholder={`VO ${number + 1}`}
        className={bem.e("vo-text")}
        value={get(userVo, "label", `VO ${number + 1}`)}
        onFocus={() => setVisible(false)}
        onChange={({ target }) => {
          onUpdateUser(number, {
            label: target.value,
          });
        }}
        disabled={status !== "assigned"}
      />
    </Tooltip>
  );
};
export default VoLabel;
