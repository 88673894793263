import React from "react";
import bn from "utils/bemnames";
import classNames from "classnames";
import LoadMore from "../LoadMore";
import { get, map } from "lodash";
import PropTypes from "prop-types";
const bem = bn.create("jock-console");
function NewsItem(props) {
  const { item } = props;
  return (
    <div className={bem.e("item-row")}>
      <div className={bem.e("rss-content")}>
        <div className={bem.e("rss-title")}>{item.title}</div>
        <div className={bem.e("rss-date")}>{item.pubDate}</div>
        <div
          className={bem.e("rss-subtitle")}
          dangerouslySetInnerHTML={{ __html: item.description }}
        ></div>
      </div>
    </div>
  );
}
export default function RssFeed(props) {
  const { isMultiView, widget, widgetData, loading, onLoadMore } = props;
  const items = get(widgetData, "data", []);
  const isShowLoadMore =
    get(widgetData, "current_page", 0) < get(widgetData, "last_page", 0);
  return (
    <div
      className={classNames(
        bem.e("scroll-container"),
        bem.e("widget-detail-padding")
      )}
    >
      <div
        className={classNames(bem.e("news-head-title"), {
          [bem.e("head-title-multi-view")]: isMultiView,
        })}
        style={{
          backgroundColor: isMultiView ? widget.color : "",
        }}
      >
        {get(widget, "name", "")}
      </div>
      <div className={classNames(bem.e("scroll-content"), "scroll-bar-style")}>
        <div className={bem.e("items-list")}>
          {map(items, (item, index) => (
            <NewsItem key={index} item={item} />
          ))}
        </div>
      </div>
      {isShowLoadMore && <LoadMore onClick={onLoadMore} isLoading={loading} />}
    </div>
  );
}
RssFeed.defaultProps = {
  isMultiView: false,
  widget: {},
};
RssFeed.propsTypes = {
  isMultiView: PropTypes.bool,
  widget: PropTypes.object.isRequired,
};
