import PrizeCloset from "./view";
import { connect } from "react-redux";
import { compose } from "recompose";
import { getPrizes, deletePrize } from "store/actions/prizes";
import { injectIntl } from "react-intl";

export default injectIntl(
  compose(
    connect(
      (state, props) => {
        return {
          ...props,
        };
      },
      {
        getPrizes,
        deletePrize,
      }
    )
  )(PrizeCloset)
);
