import React from "react";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import { get, find, split, first } from "lodash";
import { FaSortDesc, FaSortAsc } from "react-icons/lib/fa";
import { Table } from "reactstrap";
import { formatDisplayDate } from "utils/helpers";
import { CopyIcon } from "components/CustomIcons";
import NoItemsFound from "components/NoItemsFound";
import useInfiniteScroll from "utils/useInfiniteScroll";
import moment from "moment";
import ProcessDialog from "components/ProcessDialog";
import useCountdown from "components/DeadlineTimer/useCountdown";

const ProcessStatus = (props) => {
  const { process, bem } = props;
  const date = get(process, "_source.order_end_date");
  const timezone = get(process, "_source.timezone", null);
  const countdownData = useCountdown(date, timezone)
  if (!countdownData) return null;
  const orderEndDate = get(process, "_source.order_end_date");
  const isDraft = get(process, "_source.process_step") === "draft";
  const isCompleted =
    get(process, "_source.write_script_status") === "completed";
  const publishedValue = get(process, "_source.is_publish");
  const isPublished = !!publishedValue;
  const isUnpublished = publishedValue === false;
  let status;
  if (isCompleted) {
    status = "completed";
  }

  if (isPublished) {
    status = "published";
  }
  // Note: checking like this as we only want to show unpublished if the process was manually unpublished
  // Is the is_publish field does not exists on data unless it is published or unpublished.
  if (isUnpublished) {
    status = "unpublished";
  }
  if (countdownData.isNegative) {
    if (orderEndDate && moment(moment()).diff(orderEndDate, "days") >= 14) {
      status = "archived";
    } else {
      status = "expired";
    }
  }

  if (isDraft || !status) status = "draft";

  let color = "#73A703";
  let title = status;
  switch (status) {
    case "draft":
      color = "#829FB1";
      break;
    case "unpublished":
      color = "#FF006C";
      break;
    case "expired":
      color = "#657894";
      break;
    case "archived":
      color = "#000000";
      break;
    default:
      break;
  }
  return (
    <span className={bem.e("order-status")} style={{ color }}>
      {title}
    </span>
  );
};

const View = ({
  actionSort,
  sort,
  processList,
  bem,
  onClickRow,
  hasMoreItems,
  loadItems,
  isLoading,
  stations,
  slotDefinitions,
  dateFormatByServer,
  ...rest
}) => {
  const spotDisplay = (process) => {
    return get(process, "_source.order_type.label", "");
  };
  const stationDisplay = (process) => {
    const items = get(process, "_source.order_stations", [])
      .map((item) => {
        return find(stations, (i) => i.station_key === item);
      })
      .filter((i) => i);
    if (items.length === 0) return <FormattedMessage id="process > none" />;
    return items
      .map(
        (item) => {
          let stationArr = [];
          if (get(item, "call_letters", ""))
            stationArr.push(item.call_letters);
          if (get(item, "name", ""))
            stationArr.push(item.name);
          return stationArr.join(' - ');
        }
      )
      .join(", ");
  };
  const typeDisplay = (process) => {
    const orderType = get(process, "_source.order_type", "");

    const slotDefinition = split(orderType, " - ");
    const type = first(slotDefinition);
    let title = "";
    let subtitle = "";
    let className = "";
    switch (type) {
      case "Liner":
        title = "L";
        subtitle = "Liners";
        className = "liner";
        break;
      case "Recorded Sponsorship":
        title = "SP";
        subtitle = "REC SPONS";
        className = "rec-spons";
        break;
      case "Recorded PSA":
        title = "PS";
        subtitle = "REC PSA";
        className = "rec-psa";
        break;
      case "Promo":
        title = "P";
        subtitle = "PROMOS";
        className = "promo";
        break;
      case "Live Sponsorship":
        title = "SP";
        subtitle = "LIVE SPONS";
        className = "live-spon";
        break;
      case "Contest":
        title = "C";
        subtitle = "CONTEST";
        className = "contest";
        break;
      case "Live PSA":
        title = "PS";
        subtitle = "LIVE PSA";
        className = "live-psa";
        break;
      case "Call-Ins":
        title = "CI";
        subtitle = "CALL-INS";
        className = "call-in";
        break;
      case "Talk Break":
        title = "TB";
        subtitle = "TALK BRK";
        className = "talk-brk";
        break;
      default:
        break;
    }
    return (
      <div
        className={classnames(bem.e("liner-type"), bem.e(`liner-${className}`))}
      >
        <strong>{title}</strong>
        <span>{subtitle}</span>
      </div>
    );
  };
  const flightDatesDisplay = (process) => {
    const startDate = get(process, "_source.order_start_date");
    const endDate = get(process, "_source.order_end_date");
    return (
      <div className={bem.e("liner-flight-dates")}>
        <span>{startDate ? formatDisplayDate(startDate, dateFormatByServer) : ""}</span>
        <span>
          {endDate ? ` - ${formatDisplayDate(endDate, dateFormatByServer)}` : ""}
        </span>
      </div>
    );
  };

  const titleDisplay = (process) => {
    if (get(process, "_source.order_title")) {
      return process._source.order_title;
    }
    return <FormattedMessage id="process > title of spot" />;
  };
  const renderEmpty = () => {
    return <NoItemsFound />;
  };
  useInfiniteScroll({
    useWindow: false,
    elementId: "cr-app-main",
    onLoadMore: loadItems,
    shouldLoadMore: hasMoreItems,
  });
  return (
    <div className={bem.e("table-wrapper")}>
      <Table className={bem.e("table")}>
        <thead>
          <tr>
            <th
              onClick={() => actionSort("order_sold")}
              className={classnames("can-click", bem.e("table-first-head"))}
            >
              <div className={bem.e("item-sortable")}>
                <FormattedMessage id="process > Type" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_sold",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_sold desc",
                    })}
                  />
                </div>
              </div>
            </th>

            <th onClick={() => actionSort("order_title")} className="can-click">
              <div className={bem.e("item-sortable")}>
                <FormattedMessage id="process > Title" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_title",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_title desc",
                    })}
                  />
                </div>
              </div>
            </th>
            <th className="">
              <FormattedMessage id="process > stations" />
            </th>
            <th
              onClick={() => actionSort("order_start_date")}
              className="can-click"
            >
              <div className={bem.e("item-sortable")}>
                <FormattedMessage id="process > Deadline" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_start_date",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        sort === "order_start_date desc",
                    })}
                  />
                </div>
              </div>
            </th>
            <th className="text-center">
              <FormattedMessage id="process > duplicate" />
            </th>
            <th
              onClick={() => actionSort("order_status")}
              className="can-click text-center"
            >
              <div
                className={classnames(
                  bem.e("item-sortable"),
                  bem.e("liner-sortable")
                )}
              >
                <FormattedMessage id="process > Status" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_status",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_status desc",
                    })}
                  />
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {processList.length === 0 && !isLoading ? (
            <tr>
              <td colSpan="6">{renderEmpty()}</td>
            </tr>
          ) : (
            processList.map((process) => {
              if (!process._source.template_key) return null;
              const spot = spotDisplay(process);
              const isDraft = get(process, "_source.process_step") === "draft";
              return (
                <tr key={process._id}>
                  {/* type */}
                  <td
                    className={classnames(
                      "align-middle col-table-10 align-middle",
                      bem.e("first-col")
                    )}
                    onClick={() => onClickRow(process)}
                  >
                    <span className={bem.e("td-value")}>
                      {typeDisplay(process)}
                    </span>
                  </td>
                  {/* title */}
                  <td
                    className={classnames(
                      "align-middle col-table-20",
                      bem.e("order-title")
                    )}
                    onClick={() => onClickRow(process)}
                  >
                    <span
                      className={classnames(bem.e("td-value"), {
                        [bem.e("td-value-disabled")]: isDraft,
                      })}
                    >
                      {titleDisplay(process)}
                    </span>
                    <span className={bem.e("liner-type-value")}>{spot}</span>
                  </td>
                  {/* station */}
                  <td
                    className={classnames("align-middle col-table-20")}
                    onClick={() => onClickRow(process)}
                  >
                    <span
                      className={classnames(bem.e("td-value"), {
                        [bem.e("td-value-disabled")]: isDraft,
                      })}
                    >
                      {stationDisplay(process)}
                    </span>
                  </td>
                  {/* flight dates */}
                  <td
                    className="align-middle col-table-20"
                    onClick={() => onClickRow(process)}
                  >
                    <span
                      className={classnames(bem.e("td-value"), {
                        [bem.e("td-value-disabled")]: isDraft,
                      })}
                    >
                      {flightDatesDisplay(process)}
                    </span>
                  </td>
                  {/* duplicate */}
                  <td className="align-middle col-table-10 text-center">
                    <span
                      className={bem.e("td-value")}
                      onClick={(e) => {
                        e.preventDefault();
                        rest.setCurrentProcess(process);
                        rest.setIsOpenPreviewModal(true);
                      }}
                    >
                      <CopyIcon />
                    </span>
                  </td>
                  {/* status */}
                  <td
                    className="align-middle col-table-20 text-center"
                    onClick={() => onClickRow(process)}
                  >
                    <span className={bem.e("td-value")}>
                      <ProcessStatus process={process} bem={bem} />
                    </span>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </Table>
      <ProcessDialog
        isOpenDialog={rest.isOpenPreviewModal}
        onToggleDialog={rest.onTogglePreviewModal}
        process={rest.currentProcess}
      />
    </div>
  );
};
export default View;
