import * as React from "react";
import bn from "utils/bemnames";
import Spinner from "components/Spinner";
import { get, find } from "lodash";
import * as Yup from "yup";
import { Formik } from "formik";
import ToggleSwitch from "components/ToggleSwitch";
import ToastManager from "components/ToastManager";
import { Form, Button, Row, Col, FormGroup } from "reactstrap";
import { FormattedMessage } from "react-intl";
import Checkbox from "components/Checkbox";
import CopyStations from "components/StationSettings/CopyStations";
import classNames from "classnames";
import Dropdown from "components/Dropdown";
import { Asterisk } from "components/Elements";

const bem = bn.create("station-settings-form");
const numberOptions = [
  {
    label: 90,
    value: 90,
  },
  {
    label: 180,
    value: 180,
  },
  {
    label: 360,
    value: 360,
  },
];
const AddressVerification = (props) => {
  const {
    loading,
    onFormSubmit,
    intl,
    currentServer,
    stationSettingsData,
  } = props;
  const formikRef = React.useRef(null);
  const initialValues = {
    station_id: props.currentStation.key,
    number_of_days: get(stationSettingsData, "number_of_days", "") || "",
    require_every_time_for_cash_prizes: Boolean(
      get(stationSettingsData, "require_every_time_for_cash_prizes", false)
    ),
    verification: Boolean(get(stationSettingsData, "verification", false)),
    settings_copy_to_stations: get(
      stationSettingsData,
      "settings_copy_to_stations",
      []
    ),
  };

  const form = (
    <div className={bem.b()}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        ref={formikRef}
        validationSchema={Yup.object().shape({
          number_of_days: Yup.string().when("verification", {
            is: true,
            then: Yup.string().required(
              intl.formatMessage({
                id: "validate > number of days is required",
              })
            ),
            otherwise: Yup.string().nullable(),
          }),
          require_every_time_for_cash_prizes: Yup.boolean().nullable(),
          settings_copy_to_stations: Yup.mixed().nullable(),
          verification: Yup.bool().nullable(),
        })}
        onSubmit={onFormSubmit}
      >
        {(formProps) => {
          const { values, touched, errors } = formProps;
          const isDisabled = !get(values, "verification", false);
          return (
            <React.Fragment>
              <Form
                onSubmit={formProps.handleSubmit}
                onChange={() => {
                  props.setShouldBlockNavigation(true);
                }}
                className={bem.e("station-w9-form")}
              >
                <div className={bem.e("station-wrapper")}>
                  <div
                    className={classNames(
                      bem.e("form-title"),
                      bem.e("form-padding")
                    )}
                  >
                    <FormattedMessage id="station settings > address verification" />
                  </div>
                  <div className={bem.e("form-padding")}>
                    <div className={bem.e("session-title")}>
                      <FormattedMessage id="station settings > address verification for contest" />
                    </div>
                    <Row>
                      <Col className={bem.e("col-reset")}>
                        <ToggleSwitch
                          leftComponent={
                            <FormattedMessage id="station settings > off" />
                          }
                          rightComponent={
                            <FormattedMessage id="station settings > on" />
                          }
                          name="verification"
                          switchProps={{
                            checked: get(values, "verification", false),
                            onChange: (checked) => {
                              formProps.setFieldValue("verification", checked);
                              if (!checked) {
                                formProps.setFieldValue("number_of_days", "");
                                formProps.setFieldValue(
                                  "require_every_time_for_cash_prizes",
                                  false
                                );
                              }
                              props.setShouldBlockNavigation(true);
                            },
                            offColor: "#C2D4E0",
                            onColor: "#795AFA",
                            uncheckedIcon: false,
                            checkedIcon: false,
                            handleDiameter: 22,
                            width: 50,
                            height: 26,
                          }}
                        />
                      </Col>
                    </Row>
                    <div className={bem.e("session-title")}>
                      <FormattedMessage id="station settings > all verification remain valid for" />
                    </div>
                    <Row>
                      <Col xs={4} className={bem.e("col-reset")}>
                        <FormGroup>
                          <Dropdown
                            label={
                              <span>
                                <FormattedMessage id="station settings > number of days" />
                                <Asterisk>*</Asterisk>
                              </span>
                            }
                            placeholder={intl.formatMessage({
                              id: "station settings > placeholder select",
                            })}
                            name="number_of_days"
                            onChange={(option) => {
                              formProps.setFieldValue(
                                "number_of_days",
                                option.value
                              );
                              props.setShouldBlockNavigation(true);
                            }}
                            disabled={isDisabled}
                            error={
                              touched.number_of_days && errors.number_of_days
                            }
                            value={
                              find(
                                numberOptions,
                                (item) => item.value === values.number_of_days
                              ) || ""
                            }
                            options={numberOptions}
                            closeMenuOnSelect={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className={bem.e("col-reset")}>
                        <FormGroup>
                          <Checkbox
                            checked={values.require_every_time_for_cash_prizes}
                            onChange={(value) => {
                              formProps.setFieldValue(
                                "require_every_time_for_cash_prizes",
                                value
                              );
                              props.setShouldBlockNavigation(true);
                            }}
                            disabled={isDisabled}
                            text={props.intl.formatMessage({
                              id:
                                "station settings > require verification every time for cash prizes",
                            })}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <CopyStations
                    formProps={formProps}
                    intl={intl}
                    stations={props.stations}
                    currentServer={currentServer}
                    currentStation={props.currentStation}
                    setShouldBlockNavigation={props.setShouldBlockNavigation}
                  />
                </div>

                <div className="bottom-actions-sticky">
                  <div className={"buttons"}>
                    <Button
                      color="blue"
                      type="submit"
                      className="btn btn-radius"
                      disabled={loading}
                      id="btn-submit-address-verification"
                      onClick={() => {
                        setTimeout(() => {
                          const formErrors = get(
                            formikRef,
                            "current.state.errors"
                          );
                          if (formErrors && Object.keys(formErrors).length) {
                            ToastManager.show({
                              title: intl.formatMessage({
                                id: "toast > title not saved",
                              }),
                              message: intl.formatMessage({
                                id:
                                  "toast > message error please correct the hilighted fields",
                              }),
                              level: "error",
                            });
                          }
                        }, 40);
                      }}
                    >
                      <FormattedMessage id={`station settings > button save`} />
                    </Button>
                    <Button
                      color="blue"
                      outline
                      className="btn btn-radius"
                      disabled={loading}
                      onClick={() => {
                        formProps.resetForm(initialValues);
                      }}
                    >
                      <FormattedMessage
                        id={`station settings > button cancel`}
                      />
                    </Button>
                  </div>
                </div>
              </Form>
              <Spinner isLoading={loading} />
            </React.Fragment>
          );
        }}
      </Formik>
    </div>
  );
  return form;
};
export default AddressVerification;
