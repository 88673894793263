import React from "react";
import PropTypes from "prop-types";
import {
  Modal,
  ModalBody,
  Button,
  Row,
  Col,
  ModalHeader,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import classnames from "classnames";
import TextInput from "components/TextInput";
import bn from "utils/bemnames";
import { CloseIcon } from "components/CustomIcons";
import { FormattedMessage } from "react-intl";
import TextInputFormatted from "components/TextInputFormatted";
import { Formik } from "formik";
import * as Yup from "yup";
import { get, includes, xor, find, map } from "lodash";
import CustomMultiSelectDropdown from "components/CustomMultiSelectDropdown";
import Checkbox from "components/Checkbox";
import Dropdown from "components/Dropdown";
import moment from "moment";
import { fulfillmentList } from "utils/config";

const bem = bn.create("advanced-search-modal");
const getPrizeStatus = ({ intl }) => {
  const prizeStatus = [
    {
      label: intl.formatMessage({ id: "winners > signed" }),
      value: "signed",
    },
    {
      label: intl.formatMessage({ id: "winners > unsigned" }),
      value: "unsigned",
    },
    {
      label: intl.formatMessage({ id: "winners > fulfilled" }),
      value: "fulfilled",
    },
    {
      label: intl.formatMessage({ id: "winners > expired" }),
      value: "expired",
    },
  ];
  return prizeStatus;
};
function SearchForm(props) {
  const {
    onToggle,
    intl,
    onSubmit,
    initialValues,
    allStations,
    clocksData,
    onGetClocksData,
  } = props;
  const prizeStatusOptions = getPrizeStatus({ intl });
  const contestTypesOptions = get(clocksData, "contest_types", []).map(
    (contestType) => ({
      label: `${contestType.type} - ${contestType.name}`,
      value: contestType.id,
    })
  );
  return (
    <Formik
      initialValues={{
        stations: get(initialValues, "stations", []),
        prize_status: get(initialValues, "prize_status", []),
        keyword: get(initialValues, "keyword", ""),
        contest_category: get(initialValues, "contest_category", ""),
        min_prize_value: get(initialValues, "min_prize_value", ""),
        prize_won_start_date: get(initialValues, "prize_won_start_date", ""),
        prize_won_end_date: get(initialValues, "prize_won_end_date", ""),
        prize_distribution_method: get(
          initialValues,
          "prize_distribution_method",
          ""
        ),
      }}
      validationSchema={Yup.object().shape({
        stations: Yup.mixed().nullable(),
        prize_status: Yup.mixed().nullable(),
        keyword: Yup.string().nullable(),
        prize_won_start_date: Yup.date().nullable(),
        prize_won_end_date: Yup.date()
          .when(
            "prize_won_start_date",
            (startDate, schema) =>
              startDate &&
              schema.min(
                startDate,
                intl.formatMessage({
                  id: `validate > end date should be later than start date`,
                })
              )
          )
          .nullable(),
        contest_category: Yup.string().nullable(),
        min_prize_value: Yup.string().nullable(),
        prize_distribution_method: Yup.string().nullable(),
      })}
      onSubmit={onSubmit}
    >
      {(formProps) => {
        const { values, touched, errors } = formProps;
        const isHideContestCategory = get(values, "stations.length", 0) > 1;
        const fulfillment =
          fulfillmentList.find(
            (i) => i.value === values.prize_distribution_method
          ) || "";
        const selectedContestCategory =
          find(
            contestTypesOptions,
            (item) => item.value === values.contest_category
          ) || "";

        const selectedStations = get(values, "stations", []).map(
          (stationKey) => {
            return find(allStations, (item) => item.value === stationKey);
          }
        );
        return (
          <Form onSubmit={formProps.handleSubmit}>
            <Row>
              <Col xs={8}>
                <FormGroup>
                  <Label>
                    <FormattedMessage id="winners > stations" />
                  </Label>
                  <CustomMultiSelectDropdown
                    name="stations"
                    value={selectedStations}
                    options={props.optionStations}
                    placeholder={intl.formatMessage({
                      id: "winners > please select",
                    })}
                    isAllowAllOption={true}
                    allOption={{
                      label: intl.formatMessage({
                        id: "winners > all stations",
                      }),
                      value: "*",
                    }}
                    isStation
                    isMulti={true}
                    isAllowSelectGroup
                    error={null}
                    onChange={(newStations) => {
                      formProps.setFieldValue(
                        "stations",
                        map(newStations, (item) => item.value)
                      );
                      if (newStations && newStations.length === 1) {
                        onGetClocksData(newStations[0].value);
                      }
                    }}
                    multiText={`${get(
                      values,
                      "stations.length",
                      0
                    )} ${intl.formatMessage({
                      id: "winners > stations",
                    })}`}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label>
                    <FormattedMessage id="winners > prize status" />
                  </Label>
                  <div className={bem.e("prize-status")}>
                    {prizeStatusOptions.map((option, index) => (
                      <Checkbox
                        checked={!!includes(values.prize_status, option.value)}
                        onChange={() => {
                          const newValue = xor(values.prize_status, [
                            option.value,
                          ]);
                          formProps.setFieldValue("prize_status", newValue);
                        }}
                        text={option.label}
                        key={index}
                      />
                    ))}
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <TextInput
                    label={<FormattedMessage id="winners > keyword" />}
                    type="text"
                    name="keyword"
                    placeholder={intl.formatMessage({
                      id: "winners > enter keyword",
                    })}
                    value={values.keyword}
                    onChange={formProps.handleChange}
                    onBlur={formProps.handleBlur}
                    error={touched.keyword && errors.keyword}
                  />
                </FormGroup>
              </Col>
              {!isHideContestCategory && (
                <Col>
                  <FormGroup>
                    <Dropdown
                      label={
                        <FormattedMessage id="winners > contest category" />
                      }
                      value={selectedContestCategory}
                      placeholder={intl.formatMessage({
                        id: "winners > select",
                      })}
                      onChange={(selectedOption) => {
                        formProps.setFieldValue(
                          "contest_category",
                          selectedOption.value
                        );
                      }}
                      options={contestTypesOptions}
                      name="contest_category"
                      error={
                        touched.contest_category && errors.contest_category
                      }
                    />
                  </FormGroup>
                </Col>
              )}
            </Row>
            <Row>
              <Col>
                <FormGroup className={bem.e("datepicker")}>
                  <TextInput
                    label={
                      <FormattedMessage id="winners > prize won start date" />
                    }
                    type="date"
                    name="prize_won_start_date"
                    placeholder={intl.formatMessage({
                      id: "winners > placeholder select",
                    })}
                    value={values.prize_won_start_date}
                    onChange={(value) => {
                      formProps.setFieldValue("prize_won_start_date", value);

                      // clear order end date if order start date if after
                      if (value && values.prize_won_end_date) {
                        const isAfterEndDate = moment(value).isAfter(
                          values.prize_won_end_date
                        );
                        if (isAfterEndDate) {
                          formProps.setFieldValue("prize_won_end_date", "");
                        }
                      }
                    }}
                    error={
                      touched.prize_won_start_date &&
                      errors.prize_won_start_date
                    }
                    allowShowIcon
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className={bem.e("datepicker")}>
                  <TextInput
                    label={
                      <FormattedMessage id="winners > prize won end date" />
                    }
                    type="date"
                    name="prize_won_end_date"
                    placeholder={intl.formatMessage({
                      id: "winners > placeholder select",
                    })}
                    min={get(values, "prize_won_start_date", null)}
                    value={values.prize_won_end_date}
                    onChange={(value) =>
                      formProps.setFieldValue("prize_won_end_date", value)
                    }
                    error={
                      touched.prize_won_end_date && errors.prize_won_end_date
                    }
                    allowShowIcon
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <FormGroup>
                  <TextInputFormatted
                    label={
                      <FormattedMessage id="winners > minimum prize value" />
                    }
                    type="number"
                    thousandSeparator={true}
                    allowNegative={false}
                    name="min_prize_value"
                    placeholder={intl.formatMessage({
                      id: "winners > enter minimum",
                    })}
                    value={values.min_prize_value}
                    onChange={(value) => {
                      formProps.setFieldValue("min_prize_value", value);
                    }}
                    onBlur={formProps.handleBlur}
                    error={touched.min_prize_value && errors.min_prize_value}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Dropdown
                    label={
                      <FormattedMessage id="winners > prize distribution method" />
                    }
                    value={fulfillment}
                    placeholder={intl.formatMessage({
                      id: "winners > select",
                    })}
                    onChange={(selectedOption) => {
                      formProps.setFieldValue(
                        "prize_distribution_method",
                        selectedOption.value
                      );
                    }}
                    options={fulfillmentList}
                    name="prize_distribution_method"
                    error={
                      touched.prize_distribution_method &&
                      errors.prize_distribution_method
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className={bem.e("buttons")}>
                  <Button
                    color="primary"
                    className={classnames("btn-radius", bem.e("btn-search"))}
                    type="submit"
                  >
                    <FormattedMessage id="process > button search" />
                  </Button>
                  <Button
                    color="primary"
                    outline
                    onClick={onToggle}
                    className={classnames("btn-radius", bem.e("btn-cancel"))}
                  >
                    <FormattedMessage id="process > button cancel" />
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
}
const AdvancedSearchModal = (props) => {
  const { isOpen, onToggle } = props;
  return (
    <Modal isOpen={isOpen} className={bem.b()} centered>
      <ModalHeader toggle={onToggle} className={bem.e("header")}>
        <FormattedMessage id="process > title modal advanced search" />
        <div className={bem.e("close")} onClick={onToggle}>
          <CloseIcon color="#657894" />
        </div>
      </ModalHeader>
      <ModalBody className={bem.e("body")}>
        <SearchForm {...props} />
      </ModalBody>
    </Modal>
  );
};

AdvancedSearchModal.propTypes = {
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
  initialValues: PropTypes.object,
};

AdvancedSearchModal.defaultProps = {
  isOpen: false,
  onToggle: () => {},
  initialValues: {},
};

export default AdvancedSearchModal;
