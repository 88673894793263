import * as React from "react";
import PropTypes from "prop-types";
import Switch from "react-switch";
import { Label } from "reactstrap";
import bn from "utils/bemnames";
import classNames from "classnames";

const bem = bn.create("toggle-switch");

class ToggleSwitch extends React.Component {
  static propTypes = {
    rightComponent: PropTypes.node,
    leftComponent: PropTypes.node,
    switchProps: PropTypes.object,
    label: PropTypes.node,
    labelProps: PropTypes.object,
  };

  static defaultProps = {
    label: "",
    switchProps: {
      offColor: "#7F64F2",
      onColor: "#AFC3D0",
      uncheckedIcon: false,
      checkedIcon: false,
    },
  };

  render() {
    const {
      switchProps,
      leftComponent,
      rightComponent,
      label,
      labelProps,
      className,
      containerClassName,
    } = this.props;
    return (
      <div className={containerClassName}>
        {!!label && <Label {...labelProps}>{label}</Label>}
        <div className={classNames(bem.b(), className)}>
          <div className={classNames(bem.e("left-component"), { active: !switchProps.checked })}>{leftComponent}</div>
          <Switch {...switchProps} className={bem.e("main-component")} />
          <div className={classNames(bem.e("right-component"), { active: switchProps.checked })}>{rightComponent}</div>
        </div>
      </div>
    );
  }
}

export default ToggleSwitch;
