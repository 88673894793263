export const FETCH_HUBS_REQUEST = "FETCH_HUBS_REQUEST";
export const FETCH_HUBS_SUCCESS = "FETCH_HUBS_SUCCESS";
export const FETCH_HUBS_ERROR = "FETCH_HUBS_ERROR";

export const FETCH_MORE_HUBS_REQUEST = "FETCH_MORE_HUBS_REQUEST";
export const FETCH_MORE_HUBS_SUCCESS = "FETCH_MORE_HUBS_SUCCESS";
export const FETCH_MORE_HUBS_ERROR = "FETCH_MORE_HUBS_ERROR";

export const FETCH_HUB_REQUEST = "FETCH_HUB_REQUEST";
export const FETCH_HUB_SUCCESS = "FETCH_HUB_SUCCESS";
export const FETCH_HUB_ERROR = "FETCH_HUB_ERROR";

export const SAVE_HUB_REQUEST = "SAVE_HUB_REQUEST";
export const SAVE_HUB_SUCCESS = "SAVE_HUB_SUCCESS";
export const SAVE_HUB_ERROR = "SAVE_HUB_ERROR";

export const UPDATE_HUB_REQUEST = "UPDATE_HUB_REQUEST";
export const UPDATE_HUB_SUCCESS = "UPDATE_HUB_SUCCESS";
export const UPDATE_HUB_ERROR = "UPDATE_HUB_ERROR";

export const DELETE_HUB_REQUEST = "DELETE_HUB_REQUEST";
export const DELETE_HUB_SUCCESS = "DELETE_HUB_SUCCESS";
export const DELETE_HUB_ERROR = "DELETE_HUB_ERROR";

export const FETCH_ASSIGNED_HUBS_REQUEST = "FETCH_ASSIGNED_HUBS_REQUEST";
export const FETCH_ASSIGNED_HUBS_SUCCESS = "FETCH_ASSIGNED_HUBS_SUCCESS";
export const FETCH_ASSIGNED_HUBS_ERROR = "FETCH_ASSIGNED_HUBS_ERROR";

export const FETCH_HUB_ASSIGNED_STATIONS_REQUEST =
  "FETCH_HUB_ASSIGNED_STATIONS_REQUEST";
export const FETCH_HUB_ASSIGNED_STATIONS_SUCCESS =
  "FETCH_HUB_ASSIGNED_STATIONS_SUCCESS";
export const FETCH_HUB_ASSIGNED_STATIONS_ERROR =
  "FETCH_HUB_ASSIGNED_STATIONS_ERROR";

export const FETCH_HUB_ASSIGNED_USERS_REQUEST =
  "FETCH_HUB_ASSIGNED_USERS_REQUEST";
export const FETCH_HUB_ASSIGNED_USERS_SUCCESS =
  "FETCH_HUB_ASSIGNED_USERS_SUCCESS";
export const FETCH_HUB_ASSIGNED_USERS_ERROR = "FETCH_HUB_ASSIGNED_USERS_ERROR";

export const UPDATE_HUB_ASSIGNED_STATIONS_REQUEST =
  "UPDATE_HUB_ASSIGNED_STATIONS_REQUEST";
export const UPDATE_HUB_ASSIGNED_STATIONS_SUCCESS =
  "UPDATE_HUB_ASSIGNED_STATIONS_SUCCESS";
export const UPDATE_HUB_ASSIGNED_STATIONS_ERROR =
  "UPDATE_HUB_ASSIGNED_STATIONS_ERROR";

export const UPDATE_HUB_ASSIGNED_USERS_REQUEST =
  "UPDATE_HUB_ASSIGNED_USERS_REQUEST";
export const UPDATE_HUB_ASSIGNED_USERS_SUCCESS =
  "UPDATE_HUB_ASSIGNED_USERS_SUCCESS";
export const UPDATE_HUB_ASSIGNED_USERS_ERROR =
  "UPDATE_HUB_ASSIGNED_USERS_ERROR";

export const UPDATE_HUB_ORDER_ASSIGNMENTS_REQUEST =
  "UPDATE_HUB_ORDER_ASSIGNMENTS_REQUEST";
export const UPDATE_HUB_ORDER_ASSIGNMENTS_SUCCESS =
  "UPDATE_HUB_ORDER_ASSIGNMENTS_SUCCESS";
export const UPDATE_HUB_ORDER_ASSIGNMENTS_ERROR =
  "UPDATE_HUB_ORDER_ASSIGNMENTS_ERROR";

export const FETCH_HUB_USERS_REQUEST = "FETCH_HUB_USERS_REQUEST";
export const FETCH_HUB_USERS_SUCCESS = "FETCH_HUB_USERS_SUCCESS";
export const FETCH_HUB_USERS_ERROR = "FETCH_HUB_USERS_ERROR";

export const FETCH_TEAM_MANAGEMENT_REQUEST = "FETCH_TEAM_MANAGEMENT_REQUEST";
export const FETCH_TEAM_MANAGEMENT_SUCCESS = "FETCH_TEAM_MANAGEMENT_SUCCESS";
export const FETCH_TEAM_MANAGEMENT_ERROR = "FETCH_TEAM_MANAGEMENT_ERROR";

export const FETCH_CURRENT_ORDERS_BY_TEAM_REQUEST =
  "FETCH_CURRENT_ORDERS_BY_TEAM_REQUEST";
export const FETCH_CURRENT_ORDERS_BY_TEAM_SUCCESS =
  "FETCH_CURRENT_ORDERS_BY_TEAM_SUCCESS";
export const FETCH_CURRENT_ORDERS_BY_TEAM_ERROR =
  "FETCH_CURRENT_ORDERS_BY_TEAM_ERROR";

export const FETCH_ASSIGNED_ORDERS_BY_TEAM_REQUEST =
  "FETCH_ASSIGNED_ORDERS_BY_TEAM_REQUEST";
export const FETCH_ASSIGNED_ORDERS_BY_TEAM_SUCCESS =
  "FETCH_ASSIGNED_ORDERS_BY_TEAM_SUCCESS";
export const FETCH_ASSIGNED_ORDERS_BY_TEAM_ERROR =
  "FETCH_ASSIGNED_ORDERS_BY_TEAM_ERROR";

export const FETCH_HUB_MEMBERS_REQUEST = "FETCH_HUB_MEMBERS_REQUEST";
export const FETCH_HUB_MEMBERS_SUCCESS = "FETCH_HUB_MEMBERS_SUCCESS";
export const FETCH_HUB_MEMBERS_ERROR = "FETCH_HUB_MEMBERS_ERROR";

export const FETCH_HUB_MEMBERS_ASSIGNMENT_REQUEST =
  "FETCH_HUB_MEMBERS_ASSIGNMENT_REQUEST";
export const FETCH_HUB_MEMBERS_ASSIGNMENT_SUCCESS =
  "FETCH_HUB_MEMBERS_ASSIGNMENT_SUCCESS";
export const FETCH_HUB_MEMBERS_ASSIGNMENT_ERROR =
  "FETCH_HUB_MEMBERS_ASSIGNMENT_ERROR";
