import { FormattedMessage } from "react-intl";
import React from "react";

const Client = {
  process_step_index: 4,
  key: "client",
  title: <FormattedMessage id="process > title client" />,
  caption_color: "#CC1EE6",
  step_roles: [],
  step_viewers_roles: [],
  step_editors_roles: [],
  step_viewers_privileges: ["programming_element_view"],
  step_editors_privileges: {
    or: ["programming_element_edit", "programming_element_delete"],
  },
  step_container: "none",
  no_edit_button: true,
  className: "client",
  fields: [
    {
      component: "promotion_liner_client",
    },
    {
      field: "client_name",
      title: <FormattedMessage id="process > field client" />,
      mandatory: false,
      // validation: function(step, item, process, template, user, value) {
      //   return value.length > 1
      //     ? true
      //     : "Advertiser name must be at least 2 letters long";
      // },
    },
    {
      field: "client_id",
    },
    {
      field: "contract_number",
      mandatory: false,
      title: <FormattedMessage id="process > field contract number" />,
      // validation: function(step, item, process, template, user, value) {
      //   return value && value.length > 2
      //     ? true
      //     : "Contract number must be at least 3 letters long";
      // },
    },
    {
      field: "salesperson",
      title: <FormattedMessage id="process > field salesperson" />,
      // mandatory: false,
      // validation: function(step, item, process, template, user, value) {
      //   return value.length > 0
      //     ? true
      //     : "At least one station has to be selected";
      // },
    },
    {
      field: "client_documents",
    },
    {
      field: "client_note",
    },
    {
      field: "is_publish",
    },
    {
      key: "save_button",
      text: <FormattedMessage id="process > button save" />,
      on_click: {
        validate: true,
        save: true,
      },
      fields: [
        {
          key: "client_status",
          value: "completed",
        },
        {
          key: "process_step",
          value: "first_undone",
        },
      ],
      confirmation_dialog_props: {
        description: (
          <FormattedMessage id="process > description to submit production order" />
        ),
        confirmTitle: <FormattedMessage id="process > button yes" />,
        cancelTitle: <FormattedMessage id="process > button no" />,
      },
      success_dialog_props: {
        title: <FormattedMessage id="process > title submitted" />,
        state: "success",
      },
    },
  ],
};
export default Client;
