import * as React from "react";
import bn from "utils/bemnames";
import Spinner from "components/Spinner";
import { get } from "lodash";
import * as Yup from "yup";
import { Formik } from "formik";
import ToastManager from "components/ToastManager";
import { Form, Button, Row, Col, FormGroup } from "reactstrap";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import ToggleSwitch from "components/ToggleSwitch";
import Dropdown from "components/Dropdown";
import moment from "moment";

const bem = bn.create("production-settings-form");

const WorkflowSettingsForm = (props) => {
  const { loading, onFormSubmit, intl, productionSettings, auth } = props;
  const formikRef = React.useRef(null);
  const productionMultimarket = get(auth, "info.production_multimarket", false);
  const initialValues = {
    station_id: props.currentStation.key,
    producer_assigns_vo: Boolean(
      get(productionSettings, "producer_assigns_vo", false)
    ),
    continuity_queue: Boolean(
      get(productionSettings, "continuity_queue", false)
    ),
    assignments_queue: Boolean(
      get(productionSettings, "assignments_queue", false)
    ),
    default_record_deadline_days: get(
      productionSettings,
      "default_record_deadline_days",
      ""
    ),
    default_record_deadline_time: get(
      productionSettings,
      "default_record_deadline_time",
      ""
    ),
    default_voice_deadline_days: get(
      productionSettings,
      "default_voice_deadline_days",
      ""
    ),
    default_voice_deadline_time: get(
      productionSettings,
      "default_voice_deadline_time",
      ""
    ),
    default_script_deadline_days: get(
      productionSettings,
      "default_script_deadline_days",
      ""
    ),
    default_script_deadline_time: get(
      productionSettings,
      "default_script_deadline_time",
      ""
    ),
    default_assignment_deadline_days: get(
      productionSettings,
      "default_assignment_deadline_days",
      ""
    ),
    default_assignment_deadline_time: get(
      productionSettings,
      "default_assignment_deadline_time",
      ""
    ),
  };

  const isShowContinuityQueue = !productionMultimarket;
  const dayOptions = React.useMemo(() => {
    return [
      {
        label: intl.formatMessage({
          id: "process > promotion > liner > schedule > placeholder select",
        }),
        value: "",
      },
      ...Array.from({ length: 6 }, (_, index) => index).map((item) => {
        const day = item + 1;
        return {
          label:
            day === 1
              ? intl.formatMessage(
                  { id: "production settings > number day" },
                  { day }
                )
              : intl.formatMessage(
                  { id: "production settings > number days" },
                  { day }
                ),
          value: day,
        };
      }),
    ];
  }, []);

  const hourOptions = React.useMemo(() => {
    return [
      {
        label: intl.formatMessage({
          id: "process > promotion > liner > schedule > placeholder select",
        }),
        value: "",
      },
      ...Array.from({ length: 24 }, (_, i) => i).map((item) => {
        return {
          label: moment(item, "HH").format("h a"),
          value: item,
        };
      }),
    ];
  }, []);

  const form = (
    <div className={bem.b()}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        ref={formikRef}
        validationSchema={Yup.object().shape({
          producer_assigns_vo: Yup.bool(),
          continuity_queue: Yup.bool(),
          assignments_queue: Yup.bool(),
          default_record_deadline_days: Yup.string(),
          default_record_deadline_time: Yup.string(),
          default_voice_deadline_days: Yup.string(),
          default_voice_deadline_time: Yup.string(),
          default_script_deadline_days: Yup.string(),
          default_assignment_deadline_time: Yup.string(),
        })}
        onSubmit={onFormSubmit}
      >
        {(formProps) => {
          const { values, setFieldValue } = formProps;
          return (
            <React.Fragment>
              <Form onSubmit={formProps.handleSubmit}>
                <div
                  className={classNames(
                    bem.e("content-wrapper"),
                    bem.e("content-workflow-settings-wrapper")
                  )}
                >
                  <div className={bem.e("section-title")}>
                    <FormattedMessage id="production settings > default settings" />
                  </div>
                  <div className={bem.e("form-padding")}>
                    <Row>
                      <Col className={bem.e("col-reset")} xs={12}>
                        <FormGroup>
                          <ToggleSwitch
                            leftComponent={intl.formatMessage({
                              id: "production settings > producer assigns vo",
                            })}
                            rightComponent={intl.formatMessage({
                              id: "production settings > off",
                            })}
                            name="producer_assigns_vo"
                            switchProps={{
                              checked: !values.producer_assigns_vo,
                              onChange: (checked) => {
                                props.setShouldBlockNavigation(true);
                                setFieldValue("producer_assigns_vo", !checked);
                              },
                              offColor: "#795AFA",
                              onColor: "#C2D4E0",
                              uncheckedIcon: false,
                              checkedIcon: false,
                              boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.282)",
                              height: 26.44,
                              width: 48.48,
                              handleDiameter: 22.92,
                            }}
                          />
                        </FormGroup>
                      </Col>
                      {isShowContinuityQueue && (
                        <Col className={bem.e("col-reset")} xs={12}>
                          <FormGroup>
                            <ToggleSwitch
                              leftComponent={intl.formatMessage({
                                id: "production settings > continuity queue",
                              })}
                              rightComponent={intl.formatMessage({
                                id: "production settings > off",
                              })}
                              name="continuity_queue"
                              switchProps={{
                                checked: !values.continuity_queue,
                                onChange: (checked) => {
                                  props.setShouldBlockNavigation(true);
                                  setFieldValue("continuity_queue", !checked);
                                },
                                offColor: "#795AFA",
                                onColor: "#C2D4E0",
                                uncheckedIcon: false,
                                checkedIcon: false,
                                boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.282)",
                                height: 26.44,
                                width: 48.48,
                                handleDiameter: 22.92,
                              }}
                            />
                          </FormGroup>
                        </Col>
                      )}
                      <Col className={bem.e("col-reset")} xs={12}>
                        <FormGroup>
                          <ToggleSwitch
                            leftComponent={intl.formatMessage({
                              id: "production settings > assignments queue",
                            })}
                            rightComponent={intl.formatMessage({
                              id: "production settings > off",
                            })}
                            name="assignments_queue"
                            switchProps={{
                              checked: !values.assignments_queue,
                              onChange: (checked) => {
                                props.setShouldBlockNavigation(true);
                                setFieldValue("assignments_queue", !checked);
                              },
                              offColor: "#795AFA",
                              onColor: "#C2D4E0",
                              uncheckedIcon: false,
                              checkedIcon: false,
                              boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.282)",
                              height: 26.44,
                              width: 48.48,
                              handleDiameter: 22.92,
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <div className={bem.e("section-title")}>
                    <FormattedMessage id="production settings > order deadlines" />
                  </div>
                  <div className={bem.e("form-padding")}>
                    <div className={bem.e("description-text")}>
                      <FormattedMessage id="production settings > order deadlines description" />
                    </div>
                    <Row>
                      <Col className={bem.e("col-reset")} xs={12}>
                        <FormGroup className={bem.e("deadline-group")}>
                          <Dropdown
                            label={
                              <FormattedMessage id="production settings > default record deadline" />
                            }
                            placeholder={intl.formatMessage({
                              id:
                                "process > promotion > liner > schedule > placeholder select",
                            })}
                            name="default_record_deadline_days"
                            onChange={(option) => {
                              formProps.setFieldValue(
                                "default_record_deadline_days",
                                option.value
                              );
                            }}
                            value={
                              dayOptions.find(
                                (item) =>
                                  item.value ===
                                  values.default_record_deadline_days
                              ) || 0
                            }
                            options={dayOptions}
                            closeMenuOnSelect={true}
                          />
                          <div className={bem.e("deadline-text")}>
                            <FormattedMessage id="production settings > before the due date/deadline by" />
                          </div>
                          <Dropdown
                            placeholder={intl.formatMessage({
                              id:
                                "process > promotion > liner > schedule > placeholder select",
                            })}
                            name="default_record_deadline_time"
                            onChange={(option) => {
                              console.log("option", option);
                              formProps.setFieldValue(
                                "default_record_deadline_time",
                                option.value
                              );
                            }}
                            containerClass={bem.e("dropdown-deadline")}
                            options={hourOptions}
                            value={
                              hourOptions.find(
                                (item) =>
                                  item.value ===
                                  values.default_record_deadline_time
                              ) || ""
                            }
                            closeMenuOnSelect={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col className={bem.e("col-reset")} xs={12}>
                        <FormGroup className={bem.e("deadline-group")}>
                          <Dropdown
                            label={
                              <FormattedMessage id="production settings > default voice deadline" />
                            }
                            placeholder={intl.formatMessage({
                              id:
                                "process > promotion > liner > schedule > placeholder select",
                            })}
                            name="default_voice_deadline_days"
                            onChange={(option) => {
                              formProps.setFieldValue(
                                "default_voice_deadline_days",
                                option.value
                              );
                            }}
                            value={
                              dayOptions.find(
                                (item) =>
                                  item.value ===
                                  values.default_voice_deadline_days
                              ) || 0
                            }
                            options={dayOptions}
                            closeMenuOnSelect={true}
                          />
                          <div className={bem.e("deadline-text")}>
                            <FormattedMessage id="production settings > before the due date/deadline by" />
                          </div>
                          <Dropdown
                            placeholder={intl.formatMessage({
                              id:
                                "process > promotion > liner > schedule > placeholder select",
                            })}
                            name="default_voice_deadline_time"
                            onChange={(option) => {
                              console.log("option", option);
                              formProps.setFieldValue(
                                "default_voice_deadline_time",
                                option.value
                              );
                            }}
                            containerClass={bem.e("dropdown-deadline")}
                            options={hourOptions}
                            value={
                              hourOptions.find(
                                (item) =>
                                  item.value ===
                                  values.default_voice_deadline_time
                              ) || ""
                            }
                            closeMenuOnSelect={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col className={bem.e("col-reset")} xs={12}>
                        <FormGroup className={bem.e("deadline-group")}>
                          <Dropdown
                            label={
                              <FormattedMessage id="production settings > default script deadline" />
                            }
                            placeholder={intl.formatMessage({
                              id:
                                "process > promotion > liner > schedule > placeholder select",
                            })}
                            name="default_script_deadline_days"
                            onChange={(option) => {
                              formProps.setFieldValue(
                                "default_script_deadline_days",
                                option.value
                              );
                            }}
                            value={
                              dayOptions.find(
                                (item) =>
                                  item.value ===
                                  values.default_script_deadline_days
                              ) || 0
                            }
                            options={dayOptions}
                            closeMenuOnSelect={true}
                          />
                          <div className={bem.e("deadline-text")}>
                            <FormattedMessage id="production settings > before the due date/deadline by" />
                          </div>
                          <Dropdown
                            placeholder={intl.formatMessage({
                              id:
                                "process > promotion > liner > schedule > placeholder select",
                            })}
                            name="default_script_deadline_time"
                            onChange={(option) => {
                              formProps.setFieldValue(
                                "default_script_deadline_time",
                                option.value
                              );
                            }}
                            containerClass={bem.e("dropdown-deadline")}
                            options={hourOptions}
                            value={
                              hourOptions.find(
                                (item) =>
                                  item.value ===
                                  values.default_script_deadline_time
                              ) || ""
                            }
                            closeMenuOnSelect={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col className={bem.e("col-reset")} xs={12}>
                        <FormGroup className={bem.e("deadline-group")}>
                          <Dropdown
                            label={
                              <FormattedMessage id="production settings > default assignment deadline" />
                            }
                            placeholder={intl.formatMessage({
                              id:
                                "process > promotion > liner > schedule > placeholder select",
                            })}
                            name="default_assignment_deadline_days"
                            onChange={(option) => {
                              formProps.setFieldValue(
                                "default_assignment_deadline_days",
                                option.value
                              );
                            }}
                            value={
                              dayOptions.find(
                                (item) =>
                                  item.value ===
                                  values.default_assignment_deadline_days
                              ) || 0
                            }
                            options={dayOptions}
                            closeMenuOnSelect={true}
                          />
                          <div className={bem.e("deadline-text")}>
                            <FormattedMessage id="production settings > before the due date/deadline by" />
                          </div>
                          <Dropdown
                            placeholder={intl.formatMessage({
                              id:
                                "process > promotion > liner > schedule > placeholder select",
                            })}
                            name="default_assignment_deadline_time"
                            onChange={(option) => {
                              formProps.setFieldValue(
                                "default_assignment_deadline_time",
                                option.value
                              );
                            }}
                            containerClass={bem.e("dropdown-deadline")}
                            options={hourOptions}
                            value={
                              hourOptions.find(
                                (item) =>
                                  item.value ===
                                  values.default_assignment_deadline_time
                              ) || ""
                            }
                            closeMenuOnSelect={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </div>

                <div className="bottom-actions-sticky">
                  <div className={"buttons"}>
                    <Button
                      color="blue"
                      type="submit"
                      className="btn btn-radius"
                      disabled={loading}
                      id="btn-submit-workflow"
                      onClick={() => {
                        formProps.validateForm(values).then((onfulfilled) => {
                          if (onfulfilled && onfulfilled.isCancelled) return;
                          if (onfulfilled && Object.keys(onfulfilled).length) {
                            ToastManager.show({
                              title: intl.formatMessage({
                                id: "toast > title not saved",
                              }),
                              message: intl.formatMessage({
                                id:
                                  "toast > message error please correct the hilighted fields",
                              }),
                              level: "error",
                            });
                          }
                        });
                      }}
                    >
                      <FormattedMessage id={`station settings > button save`} />
                    </Button>
                  </div>
                </div>
              </Form>
              <Spinner isLoading={loading} />
            </React.Fragment>
          );
        }}
      </Formik>
    </div>
  );
  return form;
};
export default WorkflowSettingsForm;
