import React from "react";
import { Table } from "reactstrap";
import bn from "utils/bemnames";
import { FaSortDesc, FaSortAsc } from "react-icons/lib/fa";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import { get, map } from "lodash";
import useInfiniteScroll from "utils/useInfiniteScroll";
import { EyeIcon, EditIcon, ApproveIcon } from "components/CustomIcons";
import moment from "moment";
const bem = bn.create("winner-notification");
const NotificationTable = (props) => {
  const {
    sorted,
    onSort,
    isLoading,
    notificationsData,
    getMoreItems,
    setSelectedEmailTemplate,
    setIsOpenEmailTemplateModal,
    onPreview,
    dateFormatByServer
  } = props;
  const items = get(notificationsData, "data", []);
  const hasMore =
    get(notificationsData, "current_page", 0) <
    get(notificationsData, "last_page", 0);
  useInfiniteScroll({
    useWindow: false,
    elementId: "notification-table",
    onLoadMore: getMoreItems,
    shouldLoadMore: hasMore,
  });
  return (
    <Table className={bem.e("notification-table")}>
      <thead>
        <tr>
          <th
            className={bem.e("column-notification")}
            onClick={() => onSort("subject")}
          >
            <div className={bem.e("item-sortable")}>
              <FormattedMessage id="process > promotion > contest > notification" />
              <div className={bem.e("sortable")}>
                <FaSortAsc
                  className={classnames(bem.e("sortable-icon"), {
                    [bem.e("sortable-active")]:
                      get(sorted, "id") === "subject" && !get(sorted, "desc"),
                  })}
                />
                <FaSortDesc
                  className={classnames(bem.e("sortable-icon"), {
                    [bem.e("sortable-active")]:
                      get(sorted, "id") === "subject" && get(sorted, "desc"),
                  })}
                />
              </div>
            </div>
          </th>
          <th
            className={bem.e("column-creator")}
            onClick={() => onSort("creator")}
          >
            <div className={bem.e("item-sortable")}>
              <FormattedMessage id="process > promotion > contest > creator" />
              <div className={bem.e("sortable")}>
                <FaSortAsc
                  className={classnames(bem.e("sortable-icon"), {
                    [bem.e("sortable-active")]:
                      get(sorted, "id") === "creator" && !get(sorted, "desc"),
                  })}
                />
                <FaSortDesc
                  className={classnames(bem.e("sortable-icon"), {
                    [bem.e("sortable-active")]:
                      get(sorted, "id") === "creator" && get(sorted, "desc"),
                  })}
                />
              </div>
            </div>
          </th>
          <th
            className={bem.e("column-status")}
            onClick={() => onSort("status")}
          >
            <div className={bem.e("item-sortable")}>
              <FormattedMessage id="process > promotion > contest > status" />
              <div className={bem.e("sortable")}>
                <FaSortAsc
                  className={classnames(bem.e("sortable-icon"), {
                    [bem.e("sortable-active")]:
                      get(sorted, "id") === "status" && !get(sorted, "desc"),
                  })}
                />
                <FaSortDesc
                  className={classnames(bem.e("sortable-icon"), {
                    [bem.e("sortable-active")]:
                      get(sorted, "id") === "status" && get(sorted, "desc"),
                  })}
                />
              </div>
            </div>
          </th>
          <th className={bem.e("column-success")}>
            <FormattedMessage id="process > promotion > contest > success" />
          </th>
          <th className={bem.e("column-notification-actions")} align="center">
            <FormattedMessage id="process > promotion > contest > actions" />
          </th>
        </tr>
      </thead>
      <tbody id="notification-table" className="scroll-bar-style">
        {items.length === 0 && !isLoading ? (
          <tr>
            <td colSpan={5}>
              <div className={bem.e("empty-notifications")}>
                <FormattedMessage id="process > promotion > contest > no notifications scheduled" />
              </div>
            </td>
          </tr>
        ) : (
          <React.Fragment>
            {map(items, (item, index) => {
              return (
                <tr
                  key={index}
                  className={classnames({
                    [bem.e("notification-draft")]: item.status === "draft",
                    [bem.e("notification-scheduled")]:
                      item.status === "scheduled",
                    [bem.e("notification-sent")]: item.status === "sent",
                    [bem.e("notification-automatic")]:
                      item.status === "automatic",
                  })}
                >
                  {/* title */}
                  <td className={bem.e("td-notification")}>
                    <span className={bem.e("notification-title")}>
                      {item.subject}
                    </span>
                    <span className={bem.e("template-text")}>
                      {item.template ? item.template.name : ""}
                    </span>
                  </td>
                  <td className={bem.e("td-creator")}>
                    <span className={bem.e("creator-text")}>
                      {item.creator}
                    </span>
                  </td>
                  <td align="center" className={bem.e("td-status")}>
                    <div className={bem.e("notification-status")}>
                      {item.status === "automatic" ? (
                        <FormattedMessage id="process > promotion > contest > automatic" />
                      ) : null}
                      {item.status === "draft" ? (
                        <FormattedMessage id="process > promotion > contest > draft" />
                      ) : null}
                      {item.status === "sent" ? (
                        <>
                          <span className={bem.e("sent-icon")}>
                            <ApproveIcon color="#fff" />
                          </span>
                          <FormattedMessage id="process > promotion > contest > sent" />
                        </>
                      ) : null}
                      {item.status === "scheduled" ? (
                        <FormattedMessage id="process > promotion > contest > scheduled" />
                      ) : null}
                    </div>
                    <span className={bem.e("timer-text")}>
                      {item.sent_at
                        ? moment(item.sent_at).format(`${dateFormatByServer} HH:mma`)
                        : ""}
                      {item.schedule && item.status === "scheduled"
                        ? moment(item.schedule).format(`${dateFormatByServer} HH:mma`)
                        : ""}
                    </span>
                  </td>
                  <td className={bem.e("td-success")}>
                    <div className={bem.e("notification-success")}>
                      <div className={bem.e("success-sent")}>
                        <strong>{item.sent_count}</strong>
                        <FormattedMessage id="process > promotion > contest > sent" />
                      </div>
                      <div className={bem.e("success-opens")}>
                        <strong>{item.open_count}</strong>
                        <FormattedMessage id="process > promotion > contest > opens" />
                      </div>
                    </div>
                  </td>
                  <td
                    align="center"
                    className={bem.e("td-notification-actions")}
                  >
                    <div className={bem.e("table-buttons")}>
                      {!item.sent_at && (
                        <span
                          className={bem.e("edit-action")}
                          onClick={() => {
                            setSelectedEmailTemplate(item);
                            setIsOpenEmailTemplateModal(true);
                          }}
                        >
                          <EditIcon />
                        </span>
                      )}
                      <span
                        className={bem.e("view-action")}
                        onClick={() => onPreview(item)}
                      >
                        <EyeIcon width={19} height={13} />
                      </span>
                    </div>
                  </td>
                </tr>
              );
            })}
          </React.Fragment>
        )}
      </tbody>
    </Table>
  );
};
export default NotificationTable;
