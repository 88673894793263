import ClockCreatorPage from "./view";
import { connect } from "react-redux";
import { compose } from "recompose";
import { injectIntl } from "react-intl";

export default injectIntl(
  compose(
    connect((state, props) => {
      return {
        ...props,
      };
    })
  )(ClockCreatorPage)
);
