import React from "react";
import {
  Button,
  Row,
  Col,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { FormattedMessage } from "react-intl";
import { Formik } from "formik";
import classnames from "classnames";
import { get, values as getValues, isEqual } from "lodash";
import * as Yup from "yup";

import TextInput from "components/TextInput";
import { Asterisk } from "components/Elements";
import StationSelectors from "components/StationsPicker/StationSelectors";
import AddedStationsList from "components/StationsPicker/AddedStationsList";
import { ArrowRightIcon } from "components/CustomIcons";
import ButtonCircle from "components/ButtonCircle";
import ConfirmationModal from "components/ConfirmationModal";
import Checkbox from "components/Checkbox";
import Spinner from "components/Spinner";
import bn from "utils/bemnames";

const AudiencePickerModal = (props) => {
  const bem = bn.create("create-team-view");

  const {
    intl,
    isOpen,
    closeModal,
    stations,
    groups,
    selectedTags,
    setSelectedTags,
    updateSelectedTags,
    selectedStations,
    setSelectedStations,
    updateSelectedStations,
    addedStations,
    setAddedStations,
    isLoading,
    onSaveGroup,
    isGroupSaved,
    setIsGroupSaved,
    selectedGroupId,
    selectedGroup,
    isAudienceAssigned,
    setIsAudienceAssigned,
    groupBeingReplaced,
    closeReplaceGroupModal,
    replaceGroup,
    isForceUpdateSelect,
  } = props;
  const renderButtonText = (doCreateGroup) => {
    if (doCreateGroup) {
      return (
        <FormattedMessage
          id={`audience picker > button ${!isLoading ? "save" : "saving"}`}
        />
      );
    }

    return (
      <FormattedMessage
        id={`audience picker > button ${!isLoading ? "submit" : "submitting"}`}
      />
    );
  };

  const renderForm = () => {
    if (selectedGroupId && get(selectedGroup, "loading", true)) {
      return (
        <div className="entities-pickert-body" style={{ minHeight: "700px" }}>
          <div className={bem.b()}>
            <Spinner isLoading={true} />
          </div>
        </div>
      );
    }

    const tabs = {
      market: {
        allowStationsSelect: true,
        tabId: "market",
      },
      format: {
        allowStationsSelect: true,
        tabId: "format",
      },
      audience: {
        allowStationsSelect: true,
        tabId: "audience",
      },
      search: {
        tabId: "search",
      },
    };

    let initialValues = {
      name: get(selectedGroup, "data.name", ""),
      create_group: false, //!isEmpty(selectedAudience) ? true : false,
    };

    const validationSchema = Yup.object().shape({
      create_group: Yup.boolean(),
      name: Yup.string().when("create_group", {
        is: true,
        then: Yup.string()
          .trim()
          .required(
            intl.formatMessage({
              id: "validate > name is required",
            })
          ),
      }),
    });
    return (
      <div className="entities-pickert-body">
        <div className={bem.b()}>
          <Formik
            validateOnMount
            validationSchema={validationSchema}
            enableReinitialize
            initialValues={initialValues}
            isInitialValid={validationSchema.isValidSync(initialValues)}
            onSubmit={onSaveGroup}
          >
            {(formProps) => {
              const {
                errors,
                touched,
                values,
                isValid,
                handleSubmit,
                handleBlur,
                handleChange,
              } = formProps;

              return (
                <React.Fragment>
                  <div className={bem.e("form-container")}>
                    <Form onSubmit={handleSubmit}>
                      <div className={classnames(bem.e("section-container"))}>
                        <div className={bem.e("content-view")}>
                          <Row style={{ marginBottom: "0px" }}>
                            <Col>
                              <div className="info">
                                <FormattedMessage id="audience picker > info" />
                                <div className="create-group-checkbox-container">
                                  <Checkbox
                                    checked={values.create_group}
                                    onChange={(checked) => {
                                      formProps.setFieldValue(
                                        "create_group",
                                        checked
                                      );
                                    }}
                                    text={intl.formatMessage({
                                      id: "audience picker > create a group",
                                    })}
                                    checkStyle={{
                                      color: "#fff",
                                      width: "16",
                                      height: "14",
                                    }}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            {values.create_group && (
                              <React.Fragment>
                                <Col
                                  xs={6}
                                  md={6}
                                  className={bem.e("col1")}
                                ></Col>
                                <Col xs={6} md={6} className={bem.e("col2")}>
                                  <FormGroup>
                                    <TextInput
                                      label={
                                        <span>
                                          <FormattedMessage id="audience picker > group name" />
                                          <Asterisk>*</Asterisk>
                                        </span>
                                      }
                                      type="text"
                                      name="name"
                                      placeholder={intl.formatMessage({
                                        id: "audience picker > enter name",
                                      })}
                                      value={values.name}
                                      error={touched.name && errors.name}
                                      onChange={(e) => {
                                        handleChange(e);
                                      }}
                                      onBlur={handleBlur}
                                    />
                                  </FormGroup>
                                </Col>
                              </React.Fragment>
                            )}
                          </Row>
                          <Row>
                            <Col xs={6} md={6} className={bem.e("col1")}>
                              <StationSelectors
                                bem={bem}
                                tabs={tabs}
                                stations={stations}
                                groups={groups}
                                selectedTags={selectedTags}
                                setSelectedTags={setSelectedTags}
                                updateSelectedTags={updateSelectedTags}
                                selectedStations={selectedStations}
                                setSelectedStations={setSelectedStations}
                                updateSelectedStations={updateSelectedStations}
                                selectedRecord={selectedGroup}
                              />
                            </Col>

                            <Col xs={6} md={6} className={bem.e("col2")}>
                              <div className="add-stations-btn">
                                <ButtonCircle
                                  width="52px"
                                  height="52px"
                                  background="#795AFA"
                                  disabled={isEqual(
                                    selectedStations,
                                    addedStations
                                  )}
                                  boxShadow="0px 0px 10px rgba(0, 0, 0, 0.102)"
                                  onClick={() => {
                                    // always override selected
                                    if (isForceUpdateSelect) {
                                      setAddedStations(selectedStations);
                                    } else {
                                      setAddedStations({
                                        ...addedStations,
                                        ...selectedStations,
                                      });
                                    }
                                  }}
                                >
                                  <ArrowRightIcon
                                    color="#fff"
                                    width="14px"
                                    height="16px"
                                  />
                                </ButtonCircle>
                              </div>
                              <div className={bem.e("stations-container")}>
                                <div className="table-container">
                                  <AddedStationsList
                                    addedStations={addedStations}
                                    setAddedStations={setAddedStations}
                                    isView={false}
                                    showHeader={true}
                                    showTrashIcon={true}
                                  />
                                </div>
                                <div className="buttons-container">
                                  <div className={bem.e("form-buttons")}>
                                    <Button
                                      color="blue"
                                      type="submit"
                                      className="btn btn-radius"
                                      disabled={
                                        !isValid ||
                                        getValues(addedStations).length === 0 ||
                                        isLoading
                                      }
                                      onClick={formProps.handleSubmit}
                                    >
                                      {renderButtonText(values.create_group)}
                                    </Button>
                                    <Button
                                      type="button"
                                      outline
                                      disabled={
                                        getValues(addedStations).length === 0
                                      }
                                      className={classnames(
                                        bem.e("button-clear"),
                                        "btn-outline-blue btn-radius"
                                      )}
                                      onClick={(e) => setAddedStations({})}
                                    >
                                      <FormattedMessage id="teams > button clear" />
                                    </Button>
                                    <Button
                                      type="button"
                                      outline
                                      className={classnames(
                                        bem.e("button-cancel"),
                                        "btn-outline-blue btn-radius"
                                      )}
                                      onClick={(e) => closeModal()}
                                    >
                                      <FormattedMessage id="teams > button cancel" />
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Form>
                    {groupBeingReplaced && (
                      <ConfirmationModal
                        className="modal-with-no-title"
                        isOpen={groupBeingReplaced ? true : false}
                        title=""
                        description={intl.formatMessage({
                          id: "audience picker > replace group confirmation",
                        })}
                        confirmTitle={intl.formatMessage({
                          id: "audience picker > button confirm",
                        })}
                        onConfirm={() => replaceGroup(values)}
                        onToggle={closeReplaceGroupModal}
                        onCancel={closeReplaceGroupModal}
                        cancelTitle={intl.formatMessage({
                          id: "audience picker > button cancel",
                        })}
                        isCloseOutside={false}
                      />
                    )}
                  </div>
                </React.Fragment>
              );
            }}
          </Formik>
        </div>
        {isGroupSaved && (
          <ConfirmationModal
            isOpen={isGroupSaved}
            onToggle={() => setIsGroupSaved(false)}
            state="success"
            title={
              <FormattedMessage id={`audience picker > saved successfully`} />
            }
          />
        )}
        {isAudienceAssigned && (
          <ConfirmationModal
            isOpen={isAudienceAssigned}
            onToggle={() => setIsAudienceAssigned(false)}
            state="success"
            title={
              <FormattedMessage
                id={`audience picker > audience assigned successfully`}
              />
            }
          />
        )}
      </div>
    );
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        className={bem.b()}
        backdropClassName="audience-picker"
      >
        <ModalHeader toggle={closeModal}>
          <FormattedMessage id="audience picker > title" />
        </ModalHeader>
        <ModalBody className="audience-picker">{renderForm()}</ModalBody>
      </Modal>
    </>
  );
};

export default AudiencePickerModal;
