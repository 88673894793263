import React from "react";
import ProductionClientReview from "./ProductionReview";
import DigitalClientReview from "./DigitalReview";
function ClientReview(props) {
  const { template_id } = props;

  if (template_id === "basic_production") {
    return <ProductionClientReview {...props} />;
  } else {
    return <DigitalClientReview {...props} />;
  }
}

export default ClientReview;
