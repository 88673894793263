import React from "react";
import bn from "utils/bemnames";
import { MenuIcon } from "components/CustomIcons";
import { FaClose } from "react-icons/lib/fa";
import { FormGroup, Row, Col } from "reactstrap";
import TextInput from "components/TextInput";
import { get } from "lodash";
import classnames from "classnames";
const bem = bn.create("form-builder-fields");

const SectionHeader = ({
  intl,
  isNew,
  field,
  value,
  errors,
  touched,
  onChange,
  onDelete,
  allowDelete,
  isFormBuilder,
  isView,
  disabled,
  isRenderRow,
  colNumber,
}) => {
  // ui for form builder
  if (isFormBuilder) {
    if (isNew) {
      return (
        <Row>
          <Col className="form-builder-field">
            <div className={bem.b()}>
              <div
                className={classnames(
                  bem.e("form-field-group"),
                  bem.e("form-field-group-section-header")
                )}
              >
                <div className={bem.e("form-field-group-head")}>
                  <div className={bem.e("form-field-group-head-single-left")}>
                    <span className={bem.e("menu-icon")}>
                      <MenuIcon />
                    </span>
                    <div className={bem.e("header-line")}></div>
                  </div>
                  {allowDelete && (
                    <div
                      className={bem.e("form-field-group-head-right")}
                      onClick={onDelete}
                    >
                      <FaClose size="23" color="#C2D4E0" />
                    </div>
                  )}
                </div>
                <Row className={bem.e("form-field-group-bottom")}>
                  <Col xs={12}>
                    <FormGroup>
                      <TextInput
                        label={null}
                        name="label"
                        type="text"
                        placeholder={intl.formatMessage({
                          id: "form builder > label text section header",
                        })}
                        disabled={disabled}
                        value={value.label}
                        onChange={({ target }) => {
                          onChange({
                            ...value,
                            label: target.value,
                          });
                        }}
                        error={get(errors, "label", "")}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    // ui for form
  } else {
    const elementField = (
      <div className={classnames(bem.b(), bem.e("section-header"))}>
        <div className={bem.e("form-field-section-header-saved")}>
          <span className={bem.e("section-header-title")}>{field.label}</span>
        </div>
      </div>
    );
    if (isRenderRow)
      return (
        <Row>
          <Col>{elementField}</Col>
        </Row>
      );
    return <Col xs={colNumber}>{elementField}</Col>;
  }
};
SectionHeader.defaultProps = {
  isNew: false,
  errors: {},
  touched: {},
  onChange: () => {},
  onDelete: () => {},
  allowDelete: true,
  isFormBuilder: true,
  disabled: false,
  isRenderRow: true,
  colNumber: 12,
};
export default SectionHeader;
