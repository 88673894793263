import React, { useState, useCallback, useRef } from "react";
import {
  CloseIcon,
  PinAssetIcon,
  MoreEllipsisIcon,
  SendIcon,
  PinDefaultIcon,
  EnlargeIcon,
} from "components/CustomIcons";
import { replace, get, omit, filter, trim } from "lodash";
import classnames from "classnames";
import ReviewComment from "../ReviewComment";
import ReactCursorPosition, { INTERACTIONS } from "react-cursor-position";
import PlacedIcon from "assets/img/icons/placed-icon.png";
import Tooltip from "rc-tooltip";
import Avatar from "components/Avatar";
import { getSortUserName } from "utils/helpers";
import TextInput from "components/TextInput";
import { FormattedMessage } from "react-intl";
import { Button } from "reactstrap";

const PinPosition = (props) => {
  const {
    currentPosition,
    isOpenAddComment,
    setIsOpenAddComment,
    setCurrentPosition,
    onSubmitNewMessage,
    bem,
  } = props;
  let pinPosition = currentPosition;
  if (!pinPosition) {
    return null;
  }
  return (
    <div
      className={classnames(bem.e("pin-asset"), {
        [bem.e("pin-show")]: props.isShowDraggable,
      })}
      style={{
        left: pinPosition.x,
        top: pinPosition.y,
      }}
    >
      <PinAssetIcon color="#005CA0" />
      {isOpenAddComment && (
        <ReviewComment
          currentPosition={currentPosition}
          onClose={() => {
            setIsOpenAddComment(false);
            setCurrentPosition(null);
          }}
          onSubmit={onSubmitNewMessage}
        />
      )}
    </div>
  );
};

const ViewComment = ({
  comment,
  bem,
  setVisibleComments,
  setIsEditComment,
  // onDeleteComment,
  onDeleteCommentConfirmation,
  isView,
}) => {
  const ref = useRef();
  // useOutsideClick(ref, (event) => {
  //   const { path } = event;
  //   const isClickedActions =
  //     filter(Array.from(path), (item) => {
  //       return (
  //         item &&
  //         item.classList &&
  //         item.classList.contains("ignore-click-outside")
  //       );
  //     }).length > 0;
  //   if (isClickedActions) return;
  //   setVisibleComments({
  //     [comment.id]: false,
  //   });
  // });
  const { from } = comment;
  return (
    <div className={bem.e("view-comment-content")} ref={ref}>
      <div className={bem.e("view-comment")}>
        {get(from, "avatar_url") ? (
          <Avatar
            src={from.avatar_url}
            size={40}
            className={bem.e("message-avatar")}
            alt={get(from, "name")}
          />
        ) : (
          <div className={bem.e("message-avatar-text")}>
            {getSortUserName(get(from, "name", ""))}
          </div>
        )}
        <div className={bem.e("view-comment-text")}>{comment.message}</div>
      </div>
      <span
        className={bem.e("view-comment-close")}
        onClick={(e) => {
          e.stopPropagation();
          setVisibleComments({ [comment.id]: false });
          /*if (isView) {
            setVisibleComments({ [comment.id]: false });
          } else {
            onDeleteCommentConfirmation(comment);
          }*/
        }}
      >
        <CloseIcon color="#C2D4E0" />
      </span>
      {!isView && (
        <Tooltip
          trigger={["hover"]}
          placement="bottom"
          overlayClassName={classnames(
            bem.e("tooltip-comment-actions"),
            "ignore-click-outside"
          )}
          overlay={
            <div className={bem.e("comment-actions")}>
              <div
                className={bem.e("comment-button")}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsEditComment(true);
                }}
              >
                <FormattedMessage id="process > title edit comment" />
              </div>
              <div
                className={bem.e("comment-button")}
                onClick={(e) => {
                  e.stopPropagation();
                  onDeleteCommentConfirmation(comment);
                }}
              >
                <FormattedMessage id="process > title delete comment" />
              </div>
            </div>
          }
        >
          <span className={bem.e("comment-more-icon")}>
            <MoreEllipsisIcon />
          </span>
        </Tooltip>
      )}
    </div>
  );
};
const EditComment = ({
  comment,
  bem,
  onSubmit,
  intl,
  setIsEditComment,
  setVisibleComments,
}) => {
  const [message, setMessage] = useState(comment.message);
  const ref = useRef();
  const submitMessage = useCallback(() => {
    let trimMessage = trim(message);
    if (trimMessage)
      onSubmit({
        ...comment,
        message: trimMessage,
      });
  }, [message]);
  // useOutsideClick(ref, (event) => {
  //   const { path } = event;
  //   const isIgnoreOutside =
  //     filter(Array.from(path), (item) => {
  //       return (
  //         item &&
  //         item.classList &&
  //         item.classList.contains("ignore-click-outside")
  //       );
  //     }).length > 0;
  //   if (isIgnoreOutside) return;
  //   setIsEditComment(false);
  //   setVisibleComments({
  //     [comment.id]: false,
  //   });
  // });
  return (
    <div className={bem.e("edit-comment-content")} ref={ref}>
      <div className={bem.e("edit-comment-text")}>
        <TextInput
          type="textarea"
          placeholder={intl.formatMessage({
            id: "process > give us your feedback",
          })}
          value={message}
          onChange={({ target }) => {
            setMessage(target.value);
          }}
          onKeyDown={(e) => {
            const { keyCode } = e;
            if (keyCode === 13) submitMessage();
          }}
        />
        <Button
          color=""
          className={bem.e("edit-submit")}
          disabled={!message}
          onClick={(e) => {
            e.stopPropagation();
            submitMessage();
          }}
        >
          <SendIcon color="#fff" width={10} height={7} />
        </Button>
      </div>
      <span
        className={bem.e("edit-comment-close")}
        onClick={(e) => {
          e.stopPropagation();
          setIsEditComment(false);
          setVisibleComments({
            [comment.id]: false,
          });
        }}
      >
        <CloseIcon color="#C2D4E0" />
      </span>
    </div>
  );
};

const ReviewImage = (props) => {
  const {
    bem,
    mediaField,
    index,
    isShowDraggable,
    isOpenAddComment,
    isView,
    setIsOpenAddComment,
    setCurrentPosition,
    currentPosition,
    setIsOpenEnlargeAssetModal,
    visibleComments,
    setCurrentPreviewMediaModalOpen,
    setWatchPosition,
    watchPosition,
    onSubmitNewMessage,
    isEditComment,
    isClientReviewed,
    setVisibleComments,
    setIsEditComment,
    intl,
    onSubmitEditComment,
    onDeleteComment,
    setCurrentDeleteComment,
    setIsDeleteModalOpen,
  } = props;
  const urlImage = replace(
    get(mediaField, "value.path"),
    /^https:\/\//i,
    "http://"
  );
  const comments = get(mediaField, "value.comments", []);
  const mediaWrapperId = `media-wrapper-${index}`;

  return (
    <>
      <div className={bem.e("media-name")}>
        {mediaField.media_name}
        <span
          className={bem.e("enlarge-icon")}
          onClick={() => {
            setIsOpenEnlargeAssetModal(true);
          }}
        >
          <EnlargeIcon name={`media-svg-${index}`} />
        </span>
      </div>

      <div className={bem.e("slide-media-items")}>
        <div key={index} className={bem.e("media-wrapper")} id={mediaWrapperId}>
          <div
            className={bem.e("media-image")}
            style={{
              backgroundImage: `url("${urlImage}"`,
            }}
            onClick={() => {
              const isVisibleComments = visibleComments
                ? Object.values(visibleComments).filter((status) => status)
                    .length > 0
                : false;
              if (isShowDraggable || isOpenAddComment || isVisibleComments) {
                return;
              }
              setCurrentPreviewMediaModalOpen({
                asset: mediaField.value,
                field: omit(mediaField, ["value"]),
              });
            }}
          >
            <ReactCursorPosition
              activationInteractionMouse={INTERACTIONS.CLICK}
              className={bem.e("react-cursor")}
              style={{
                cursor:
                  isShowDraggable && !isOpenAddComment
                    ? `${PinDefaultIcon}`
                    : "default",
              }}
              isEnabled={!isView && !isOpenAddComment}
              onActivationChanged={({ isActive }) => {
                if (isActive && isShowDraggable) {
                  setCurrentPosition(watchPosition);
                  setIsOpenAddComment(true);
                }
              }}
              onPositionChanged={({
                elementDimensions,
                isPositionOutside,
                position,
              }) => {
                setWatchPosition(position);
                if (isPositionOutside) {
                  setIsOpenAddComment(false);
                }
              }}
            >
              <PinPosition
                isShowDraggable={isShowDraggable}
                currentPosition={currentPosition}
                setCurrentPosition={setCurrentPosition}
                isOpenAddComment={isOpenAddComment}
                setIsOpenAddComment={setIsOpenAddComment}
                onSubmitNewMessage={onSubmitNewMessage}
                bem={bem}
              />
            </ReactCursorPosition>
            {/* render placed for comment */}
            {filter(
              comments,
              (comment) => !get(comment, "is_deleted", false)
            ).map((comment, index2) => {
              if (!comment.number) return null;
              return (
                <div
                  key={index2}
                  className={bem.e("placed-icon")}
                  style={{
                    top: comment.y,
                    left: comment.x,
                  }}
                >
                  <Tooltip
                    trigger={[]}
                    destroyTooltipOnHide={true}
                    placement="bottom"
                    overlayClassName={classnames(
                      {
                        [bem.e("tooltip-edit-comment")]: isEditComment,
                        [bem.e("tooltip-comment")]: !isEditComment,
                      },
                      "ignore-click-outside"
                    )}
                    visible={get(visibleComments, comment.id, false)}
                    getTooltipContainer={() => {
                      let container = document.querySelector(
                        ".process-container"
                      );
                      if (container) return container;
                      return document.querySelector(".cr-app");
                    }}
                    onVisibleChange={(visible) => {
                      setVisibleComments({
                        [comment.id]: visible,
                      });
                    }}
                    overlay={
                      isEditComment && !isClientReviewed ? (
                        <EditComment
                          comment={comment}
                          bem={bem}
                          setIsEditComment={setIsEditComment}
                          setVisibleComments={setVisibleComments}
                          intl={intl}
                          onSubmit={onSubmitEditComment}
                        />
                      ) : (
                        <ViewComment
                          bem={bem}
                          setVisibleComments={setVisibleComments}
                          intl={intl}
                          setIsEditComment={setIsEditComment}
                          comment={comment}
                          onDeleteComment={onDeleteComment}
                          onDeleteCommentConfirmation={(comment) => {
                            setCurrentDeleteComment(comment);
                            setIsDeleteModalOpen(true);
                          }}
                          isView={isView}
                        />
                      )
                    }
                  >
                    <div
                      className={bem.e("placed-wrapper")}
                      onClick={(e) => {
                        e.stopPropagation();
                        setVisibleComments({
                          [comment.id]: !get(visibleComments, comment.id),
                        });
                      }}
                    >
                      <img src={PlacedIcon} alt="" />
                      <span className={bem.e("placed-number")}>
                        {comment.number}
                      </span>
                    </div>
                  </Tooltip>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
export default ReviewImage;
