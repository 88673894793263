import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { get } from "lodash";
function ScrollToTop(props) {
  useEffect(() => {
    const locationState = props.location.state;
    // disable scroll to top for contest or liner
    if (
      props.location.pathname.indexOf("edit/contest") !== -1 ||
      props.location.pathname.indexOf("edit/liner") !== -1 ||
      get(locationState, "isKeepPosition", false)
    )
      return;
    window.scrollTo(0, 0);
    if (document.getElementById("cr-app-main"))
      document.getElementById("cr-app-main").scrollTo(0, 0);
  }, [props.location.pathname]);

  return null;
}
export default withRouter(ScrollToTop);
