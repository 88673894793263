import React, { useState } from "react";
import View from "./view";
import { injectIntl } from "react-intl";
import { get, first } from 'lodash';

function DigitalReport(props) {

	let firstChannel = first(get(
	    props.process,
	    "data.fields.marketing_channels.value",
	    []
	));

  	const [isOpenForm, setIsOpenForm] = useState(firstChannel ? firstChannel.id : null);
  	return (
    	<View {...props} {...Object.assign({}, { isOpenForm, setIsOpenForm })} />
  	);
}
export default injectIntl(DigitalReport);
