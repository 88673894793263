// import * as Types from "../types/template";
// import * as Const from "../../utils/constants";
// import Api from "../../utils/api";
import { default_templates } from "../../utils/defaults";

import {
    FETCH_TEMPLATES_REQUEST, FETCH_TEMPLATES_SUCCESS, FETCH_TEMPLATES_ERROR,
} from "../types/templates"; 




export const getTemplates = () => async dispatch => {
    let err = ''
    try {
        dispatch({ type: FETCH_TEMPLATES_REQUEST });
        // let response = await Api.doCall(Const.GET_TEMPLATES(), "GET")
        let response = {status:200, data:default_templates}
        if (response.status === 200) {
            if (!response.data.error) {
                dispatch(fetchTemplatesSucces(response.data))
                return
            }
            err = response.data.error
        }
    } catch (error) {
        err = error.toString()
    }
    dispatch(fetchTemplatesError({ data: err }))
}

export function fetchTemplatesSucces(payload) {
    return {
        type: FETCH_TEMPLATES_SUCCESS,
        payload
    }
}

export function fetchTemplatesError(payload) {
    return {
        type: FETCH_TEMPLATES_ERROR,
        payload
    }
}
