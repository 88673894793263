import React from "react";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import { find, get } from "lodash";
import ProcessDialog from "components/ProcessDialog";
import { FaSortDesc, FaSortAsc } from "react-icons/lib/fa";
import { formatDisplayDate } from "utils/helpers";
import { Table } from "reactstrap";
import { EyeIcon } from "components/CustomIcons";
import useInfiniteScroll from "utils/useInfiniteScroll";
import NoItemsFound from "components/NoItemsFound";
import useCountdown from "components/DeadlineTimer/useCountdown";
import { titleDisplay } from "utils/helpers";

const ProcessStatus = (props) => {
  const {process,intl} = props
  const date = get(process, "_source.order_end_date");
  const timezone = get(process, "_source.timezone", null);
  const countdownData = useCountdown(date, timezone)
  if (!countdownData) return "";
  if (countdownData && countdownData.isNegative) {
    return intl.formatMessage({
      id: "process > expired",
    });
  }
  return `${get(countdownData, "days", "")} ${intl.formatMessage({
    id: "process > days",
  })}`;
};
const View = ({
  actionSort,
  sort,
  processList,
  bem,
  onClickRow,
  isOpenPreviewModal,
  onTogglePreviewModal,
  currentProcess,
  setCurrentProcess,
  loadItems,
  hasMoreItems,
  isLoading,
  intl,
}) => {
  const flightDatesDisplay = (process, key) => {
    const orderDate = get(process, `_source.${key}`);
    return (
      <div className={bem.e("flight-dates")}>
        <span>
          {orderDate ? (
            formatDisplayDate(orderDate)
          ) : (
            <FormattedMessage id="process > none" />
          )}
        </span>
      </div>
    );
  };

  const cartDisplay = (process) => {
    const spotInfo = get(process, "_source.spot_info", []);
    if (spotInfo.length > 0) {
      const spot = find(spotInfo, (item) => item.key === process._id);
      return (
        <strong className={bem.e("order-cart")}>
          {get(spot, "cart_number", "")}
        </strong>
      );
    }
    return (
      <strong className={bem.e("order-cart")}>
        {get(process, "_source.continuity_cart", "")}
      </strong>
    );
  };
  useInfiniteScroll({
    useWindow: false,
    elementId: "cr-app-main",
    onLoadMore: loadItems,
    shouldLoadMore: hasMoreItems,
  });
  return (
    <div className={bem.e("table-wrapper")}>
      <Table className={bem.e("table")}>
        <thead>
          <tr>
            <th
              onClick={() => actionSort("continuity_cart")}
              className={classnames(
                "can-click col-table-10 col-min-width",
                bem.e("table-first-head")
              )}
            >
              <div className={bem.e("item-sortable")}>
                <FormattedMessage id="process > Cart" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "continuity_cart",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        sort === "continuity_cart desc",
                    })}
                  />
                </div>
              </div>
            </th>
            <th
              onClick={() => actionSort("client_name")}
              className="can-click col-table-15"
            >
              <div className={bem.e("item-sortable")}>
                <FormattedMessage id="process > Client" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "client_name",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "client_name desc",
                    })}
                  />
                </div>
              </div>
            </th>
            <th
              onClick={() => actionSort("order_title")}
              className="can-click col-table-25"
            >
              <div className={classnames(bem.e("item-sortable"))}>
                <FormattedMessage id="process > Title" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_title",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_title desc",
                    })}
                  />
                </div>
              </div>
            </th>
            <th
              onClick={() => actionSort("order_length")}
              className="can-click col-table-10 text-center"
            >
              <div className={bem.e("item-sortable-center")}>
                <FormattedMessage id="process > length" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_length",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_length desc",
                    })}
                  />
                </div>
              </div>
            </th>
            <th className="col-table-15 text-center">
              <div className={bem.e("item-sortable-center")}>
                <FormattedMessage id="process > contract" />
              </div>
            </th>
            <th
              onClick={() => actionSort("order_end_date")}
              className="can-click col-table-15 text-center"
            >
              <div className={bem.e("item-sortable-center")}>
                <FormattedMessage id="process > spot end date" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_end_date",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        sort === "order_end_date desc",
                    })}
                  />
                </div>
              </div>
            </th>
            <th
              className={classnames(
                "col-table-15 text-center col-min-width",
                bem.e("table-last-head")
              )}
            >
              <div className={bem.e("item-center")}>
                <FormattedMessage id="process > Preview" />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {processList.length === 0 && !isLoading ? (
            <tr>
              <td colSpan="7">
                <NoItemsFound />
              </td>
            </tr>
          ) : (
            processList.map((process) => {
              if (!process._source.template_key) return null;
              return (
                <tr
                  key={process._id}
                  onClick={() => onClickRow(process, "expiring_spots")}
                >
                  {/* cart # */}
                  <td
                    className={classnames(
                      "col-table-10 align-middle",
                      bem.e("first-col")
                    )}
                  >
                    <span className={bem.e("td-value")}>
                      {cartDisplay(process)}
                    </span>
                  </td>
                  {/* client */}
                  <td
                    className={classnames(
                      "align-middle",
                      bem.e("client-name"),
                      "col-table-15"
                    )}
                  >
                    <span className={bem.e("td-value")}>
                      {process._source.client_name}
                    </span>
                  </td>
                  {/* title */}
                  <td
                    className={classnames(
                      "align-middle",
                      bem.e("order-title"),
                      "col-table-30"
                    )}
                  >
                    <span className={bem.e("td-value")}>
                      {titleDisplay(process)}
                    </span>
                  </td>
                  {/* order_length */}
                  <td className="align-middle col-table-10 text-center">
                    <span className={bem.e("td-value")}>
                      {process._source.order_length
                        ? `:${process._source.order_length}`
                        : null}
                    </span>
                  </td>
                  {/* contract */}
                  <td className="align-middle col-table-10 text-center">
                    <span className={bem.e("td-value")}>
                      {flightDatesDisplay(process, "contract_start_date")}
                      {flightDatesDisplay(process, "contract_end_date")}
                    </span>
                  </td>
                  {/* spot end date */}

                  <td className="align-middle col-table-10 text-center">
                    <span className={bem.e("td-value")}>
                      <span className={bem.e("spot-end-date-status")}>
                        <ProcessStatus process={process} intl={intl} />
                      </span>
                      {flightDatesDisplay(process, "order_end_date")}
                    </span>
                  </td>

                  {/* preview */}
                  <td
                    className={classnames(
                      "col-table-15 align-middle text-center"
                    )}
                  >
                    <div
                      className={bem.e("preview")}
                      onClick={(e) => {
                        e.stopPropagation();
                        setCurrentProcess(process);
                        onTogglePreviewModal();
                      }}
                    >
                      <EyeIcon />
                    </div>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </Table>
      <ProcessDialog
        isOpen={isOpenPreviewModal}
        onToggle={onTogglePreviewModal}
        process={currentProcess}
        button="expiring_spots"
      />
    </div>
  );
};
export default View;
