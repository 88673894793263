import View from "./view";
import { injectIntl } from "react-intl";
import { get } from "lodash";
import { connect } from "react-redux";
const Enhanced = connect(
  (state) => ({
    info: get(state, "auth.info"),
    dateFormatByServer: get(
      state,
      "servers.currentServer.data.date_format",
      "MM/DD/YYYY"
    ),
  }),
  {}
)(View);

export default injectIntl(Enhanced);
