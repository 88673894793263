import React from "react";
import bn from "utils/bemnames";
import { DAYPART, PROGCLOCK } from "utils/constants";

const ClockType = ({ clock }) => {
  const bem = bn.create("clock-type");

  let type;
  let color;

  switch (clock.type) {
    case DAYPART:
      type = "Day";
      color = "#795AFA";
      break;
    case PROGCLOCK:
      type = "Prog";
      color = "#32BAD5";
      break;
    default:
      type = "Show";
      color = "#469CFF";
  }

  return (
    <div className={bem.b()}>
      <div style={{ color: color }}>
        <div className={bem.e("type-prefix")}>{type[0]}</div>
        <div className={bem.e("type")}>{type}</div>
      </div>
    </div>
  );
};

export default ClockType;
