import React, { useEffect, useState } from "react";
import { Row, Col, FormGroup, Label } from "reactstrap";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { get } from "lodash";
import RadioButtons from "components/RadioButtons";
import { MenuIcon } from "components/CustomIcons";
import { ShadowBox, SectionTitle, SectionFields } from "components/Elements";
import { DownIcon, UpIcon } from "components/CustomIcons";
import { renderFinalScheduleStatement } from "components/ScheduleComponents/SchedulingPreview";
import SchedulingSimple from "components/ScheduleComponents/SchedulingSimple";
import SchedulingTargeted from "components/ScheduleComponents/SchedulingTargeted";
import SchedulingFill from "components/ScheduleComponents/SchedulingFill";

const ContestSchedule = (props) => {
  const {
    validationState,
    process,
    bem,
    step,
    onValueChanged,
    intl,
    isView,
    openDetails,
    setOpenDetails,
    sectionKey,
    defaultSchedulingType,
  } = props;
  const [fieldRefs, setFieldRefs] = useState(null);
  const getFieldRefs = () => {
    if (fieldRefs) return fieldRefs;

    const scheduling_type = step.fields.find(
      (f) => f.field === "scheduling_type"
    );
    const schedule_by = step.fields.find((f) => f.field === "schedule_by");
    const selected_by = step.fields.find((f) => f.field === "selected_by");
    const selected_items = step.fields.find(
      (f) => f.field === "selected_items"
    );
    const excluded_hours = step.fields.find(
      (f) => f.field === "excluded_hours_array"
    );
    const mentions = step.fields.find((f) => f.field === "mentions");

    const per = step.fields.find((f) => f.field === "per");
    if (!get(process, "data.fields.scheduling_type.value")) {
      onValueChanged(scheduling_type, defaultSchedulingType, {
        shouldBlockNavigation: false,
      }); // default value
    }

    if (!get(process, "data.fields.schedule_by.value", "")) {
      onValueChanged(
        schedule_by,
        defaultSchedulingType === "floating" ? "daypart" : "ROS",
        { shouldBlockNavigation: false }
      );
    }
    if (!get(process, "data.fields.selected_by.value", "")) {
      onValueChanged(
        selected_by,
        defaultSchedulingType === "floating" ? "daypart" : "show",
        { shouldBlockNavigation: false }
      );
    }
    const payload = {
      schedule_by,
      scheduling_type,
      selected_by,
      selected_items,
      desired_items_scheduling,
      mentions,
      per,
      excluded_hours,
    };

    setFieldRefs(payload);
    return payload;
  };
  useEffect(() => {
    // reset fields
    if (fieldRefs !== null) setFieldRefs(null);
  }, [get(process, "data.fields.key.value", "")]);
  const {
    scheduling_type,
    selected_by,
    desired_items_scheduling,
    mentions,
    per,
    excluded_hours,
    selected_items,
    schedule_by,
    dateFormatByServer,
  } = getFieldRefs();
  const scheduleMethod = get(process, "data.fields.scheduling_type.value", "");
  const playsValue = get(process, `data.fields.plays.value`, "");
  const isOpen = get(openDetails, sectionKey, false);

  return (
    <ShadowBox className={bem.e("box-script-details")}>
      <SectionTitle
        className={bem.e("contest-script-section-title")}
        onClick={() =>
          setOpenDetails({
            ...openDetails,
            [sectionKey]: !isOpen,
          })
        }
      >
        <div className={bem.e("box-head-title")}>
          <span className={"menu-selection-icon"}>
            <MenuIcon />
          </span>
          <FormattedMessage id="process > promotion > contest > title contest schedule" />
        </div>
        <span className={bem.e("box-action")}>
          {isOpen ? <UpIcon /> : <DownIcon />}
        </span>
      </SectionTitle>
      {isOpen && (
        <React.Fragment>
          <SectionFields
            className={classnames(
              bem.e("section-fields"),
              bem.e("contest-schedule-fields")
            )}
          >
            {isView ? (
              <React.Fragment>
                <Row>
                  <Col xs={12} className={bem.e("reset")}>
                    <FormGroup>
                      <Label>
                        <FormattedMessage id="process > schedule type" />
                      </Label>
                      <span
                        className={classnames(
                          bem.e("order-view-value"),
                          bem.e("text-cap")
                        )}
                      >
                        {scheduleMethod
                          ? scheduleMethod
                          : intl.formatMessage({ id: "process > none" })}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col xs={12} className={bem.e("reset")}>
                    <FormGroup>
                      <Label>
                        <FormattedMessage id="process > schedule statement" />
                      </Label>
                      <strong className={bem.e("order-view-statement")}>
                        {renderFinalScheduleStatement(
                          process,
                          dateFormatByServer
                        )}
                      </strong>
                    </FormGroup>
                  </Col>
                </Row>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Row>
                  <Col xs={12} className={bem.e("reset-col")}>
                    <div className={bem.e("item-schedule-helper-text")}>
                      <FormattedMessage id="process > promotion > contest > schedule helper" />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className={bem.e("reset-col")}>
                    <FormGroup className={bem.e("group-radios")}>
                      <RadioButtons
                        onSelected={(selected) => {
                          onValueChanged(scheduling_type, selected);
                          onValueChanged(selected_items, []);
                          onValueChanged(excluded_hours, []);
                          const selectedByDefault =
                            selected === "simple" ? "show" : "";

                          if (
                            selected === "floating" ||
                            selected === "simple"
                          ) {
                            const scheduleByDefault =
                              selected === "simple" ? "ROS" : "daypart";
                            onValueChanged(schedule_by, scheduleByDefault);
                            onValueChanged(per, "");
                            onValueChanged(mentions, "");
                          } else {
                            onValueChanged(per, "");
                            onValueChanged(mentions, "");
                          }
                          onValueChanged(selected_by, selectedByDefault);
                          props.setSchedulingAvailability({}); // reset availability data
                        }}
                        selectedValue={scheduleMethod}
                        buttons={scheduling_type.props.options}
                        error={get(
                          validationState,
                          "scheduling_type.validation_error"
                        )}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {scheduleMethod === "simple" && (
                  <SchedulingSimple
                    intl={intl}
                    isView={isView}
                    step={step}
                    process={process}
                    fields={{
                      schedule_by,
                      selected_by,
                      selected_items,
                      excluded_hours,
                    }}
                    validationState={validationState}
                    onValueChanged={onValueChanged}
                    user={props.user}
                    template={props.template}
                    buttonClicked={props.buttonClicked}
                    isAllowShowDesiredItems={false}
                    isAllowShowDesiredItemsDescription={false}
                    isSchedulingPreviewRequired={true}
                    isAllowManualDesiredItems={playsValue === "open_ended"}
                    schedulingPreviewHelpText={
                      <FormattedMessage id="process > promotion > contest > schedule preview help text" />
                    }
                  />
                )}
                {scheduleMethod === "targeted" && (
                  <SchedulingTargeted
                    bem={bem}
                    onValueChanged={onValueChanged}
                    fields={{
                      mentions,
                      per,
                      selected_by,
                      selected_items,
                      excluded_hours,
                    }}
                    intl={intl}
                    step={step}
                    process={process}
                    isView={isView}
                    validationState={validationState}
                    user={props.user}
                    template={props.template}
                    buttonClicked={props.buttonClicked}
                    isAllowShowDesiredItems={false}
                    isAllowShowDesiredItemsDescription={false}
                    isSchedulingPreviewRequired={true}
                    isAllowManualDesiredItems={playsValue === "open_ended"}
                    schedulingPreviewHelpText={
                      <FormattedMessage id="process > promotion > contest > schedule preview help text" />
                    }
                  />
                )}
                {scheduleMethod === "floating" && (
                  <SchedulingFill
                    onValueChanged={onValueChanged}
                    fields={{
                      schedule_by,
                      selected_by,
                      selected_items,
                      excluded_hours,
                    }}
                    intl={intl}
                    process={process}
                    isView={isView}
                    validationState={validationState}
                  />
                )}
              </React.Fragment>
            )}
          </SectionFields>
        </React.Fragment>
      )}
    </ShadowBox>
  );
};

export default ContestSchedule;
