import React from "react";
import {
  Nav,
  NavItem,
  NavLink as BSNavLink,
  TabContent,
  TabPane,
  Row,
  Col,
  Button,
} from "reactstrap";
import { NavLink } from "react-router-dom";
import PageAdmin from "components/PageAdmin";
import { get } from "lodash";
import PrivilegedComponent from "components/PrivilegedComponent";
import BroadcastingDetails from "components/BroadcastingDetails";
import AdminsDetails from "components/AdminsDetails";
import {
  MODE_ADD as USER_FORM_MODE_ADD,
  MODE_EDIT as USER_FORM_MODE_EDIT,
  MODE_VIEW as USER_FORM_MODE_VIEW,
} from "components/UserForm";
import {
  MODE_ADD as STATION_FORM_MODE_ADD,
  MODE_EDIT as STATION_FORM_MODE_EDIT,
  MODE_VIEW as STATION_FORM_MODE_VIEW,
} from "components/StationForm";
import StationsDetails from "components/StationsDetails";
import RoleDetails from "components/RoleDetails";
import RolesManager from "components/RolesManager";
import ServersList from "components/ServersList";
import StationsList from "components/StationsList";
import HubsList from "components/HubsList";
// import TeamsList from "components/TeamsList";
// import CitiesList from "components/CitiesList";
// import GroupsList from "components/GroupsList";
import UsersList from "components/UsersList";
import Zetta from "components/Zetta";
import OutOfOfficeList from "components/OutOfOfficeList";
import AdminsList from "components/AdminsList";
import WideOrbitConfigurations from "components/WideOrbitConfigurations";
import SmtpConfigurations from "components/SmtpConfigurations";
import { PRIVILEGES } from "utils/constants";
import { Redirect } from "react-router";
import { FormattedMessage } from "react-intl";
import bn from "utils/bemnames";
import classnames from "classnames";
import { adminTabs } from "utils/config";
import URL from "utils/urls";
// import TeamForm from "components/TeamForm";
// import TeamDetailsView from "components/TeamDetailsView";
import history from "components/History";
import HubDetailsView from "components/HubDetails";
const bem = bn.create("admins");
export const VIEW_MODE_LIST = "lists";
export const VIEW_MODE_ADMIN_DETAILS = "admin_details";
export const VIEW_MODE_STATIONS_DETAILS = "station_details";
// export const VIEW_MODE_VIEW_TEAM = "view_team";
// export const VIEW_MODE_ADD_TEAM = "add_team";
export const VIEW_MODE_TEAMS_DETAILS = "team_details";
export const VIEW_MODE_BROADCASTING_DETAILS = "broadcasting_details";
export const VIEW_MODE_ROLE_DETAILS = "role_details";
export const VIEW_MODE_HUB_DETAILS = "hub_details";

class Admins extends React.Component {
  constructor(props) {
    super();
    this.state = {
      view_mode: VIEW_MODE_LIST,
      selectedUsers: {},
      selectedInlineUser: null,
      selectedStations: {},
      selectedInlineStation: null,
      selectedServers: {},
      selectedInlineServer: null,
      isViewModeServer: false,
      selectedInlineRole: null,
      isViewModeRole: false,
      isViewModeUser: USER_FORM_MODE_EDIT,
      isViewModeStation: STATION_FORM_MODE_EDIT,
      selectedTeamId: null,
      selectedHubId: null,
    };
  }
  componentDidMount() {
    if (document.location.href.indexOf("/users/add") > -1) {
      this.setState({
        view_mode: VIEW_MODE_ADMIN_DETAILS,
        selectedInlineUser: { new: {} },
        isViewModeUser: USER_FORM_MODE_ADD,
      });
      this.props.setNewUser({
        id: "new",
        data: {},
        isForce: true,
      });
    }
    if (document.location.href.indexOf("/stations/add") > -1) {
      this.setState({
        view_mode: VIEW_MODE_STATIONS_DETAILS,
        selectedInlineStation: null,
        isViewModeStation: STATION_FORM_MODE_ADD,
      });
    }

    if (this.props.tab === adminTabs.TAB_UNKNOWN) {
      if (!this.onCheckActiveBroadCastingDetails()) {
        const tab = this.firstVisibleTab();
        const url = URL.ADMINS({ tab });
        if (document.location.href.indexOf(url) === -1) {
          history.replace(url);
          this.onSelectMainTab(tab);
          this.onCheckActiveBroadCastingDetails();
        }
      }
    }
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.tab !== prevProps.tab ||
      this.props.tab === adminTabs.TAB_UNKNOWN
    ) {
      if (
        this.props.tab === adminTabs.TAB_UNKNOWN &&
        (!prevProps.tab || prevProps.tab === adminTabs.TAB_UNKNOWN)
      ) {
        const iniTab = this.isHasPrivilegesForAdmins();
        const url = URL.ADMINS({ tab: iniTab });
        if (document.location.href.indexOf(url) === -1) {
          history.replace(url);
          this.onSelectMainTab(iniTab);
        }
      } else if (this.props.tab === adminTabs.TAB_UNKNOWN) {
        // const url = prevProps.tab
        //   ? URL.ADMINS({ tab: prevProps.tab })
        //   : this.firstVisibleTab();
        const url = this.firstVisibleTab();
        if (document.location.href.indexOf(url) === -1) {
          history.replace(url);
          this.onSelectMainTab(prevProps.tab);
        }
      }
    }
    if (this.props.location.key !== prevProps.location.key) {
      if (this.state.view_mode !== VIEW_MODE_LIST) {
        this.setState({ view_mode: VIEW_MODE_LIST });
      }
    }

    if (
      document.location.href.indexOf("/users/add") > -1 &&
      this.state.view_mode !== "admin_details"
    ) {
      this.setState({
        view_mode: VIEW_MODE_ADMIN_DETAILS,
        selectedInlineUser: { new: {} },
        isViewModeUser: USER_FORM_MODE_ADD,
      });
      this.props.setNewUser({
        id: "new",
        data: {},
        isForce: true,
      });
    }
    if (
      document.location.href.indexOf("/stations/add") > -1 &&
      this.state.view_mode !== VIEW_MODE_STATIONS_DETAILS
    ) {
      this.setState({
        view_mode: VIEW_MODE_STATIONS_DETAILS,
        selectedInlineStation: null,
        isViewModeStation: STATION_FORM_MODE_ADD,
      });
    }

    this.onCheckActiveBroadCastingDetails();
  }
  onCheckActiveBroadCastingDetails = () => {
    const { auth } = this.props;
    if (
      (get(this.props, "auth.user.broadcasting_group_admin") ||
        get(this.props, "auth.user.is_god_admin")) &&
      get(this.props, "tab") === adminTabs.TAB_BROADCASTING_GROUP &&
      (!get(auth, "info.app_flags", "") ||
        auth.info.app_flags.indexOf("SERVERS_LIST") === -1) &&
      this.state.view_mode !== VIEW_MODE_BROADCASTING_DETAILS &&
      auth.user.server
    ) {
      this.setState({
        view_mode: VIEW_MODE_BROADCASTING_DETAILS,
        selectedInlineServer: {
          [auth.user.server]: { id: auth.user.server },
        },
        isViewModeServer: true,
      });
      return true;
    }
  };
  onBackServer = () => {
    this.setState({
      view_mode: VIEW_MODE_LIST,
      selectedInlineServer: null,
      isViewModeServer: false,
      isSingleServer: false,
    });
  };
  onSelectMainTab = (tab) => {
    const { auth } = this.props;
    if (
      tab === adminTabs.TAB_BROADCASTING_GROUP &&
      auth.info.app_flags &&
      auth.info.app_flags.indexOf("SERVERS_LIST") > -1
    ) {
      if (this.state.view_mode !== VIEW_MODE_LIST) {
        this.setState({ view_mode: VIEW_MODE_LIST });
      }
    } else if (
      (get(this.props, "auth.user.broadcasting_group_admin") ||
        get(this.props, "auth.user.is_god_admin")) &&
      tab === adminTabs.TAB_BROADCASTING_GROUP
    ) {
      this.setState({
        view_mode: VIEW_MODE_BROADCASTING_DETAILS,
        selectedInlineServer: {
          [auth.info.server_id]: { id: auth.info.server_id },
        },
        isViewModeServer: true,
      });
    } else {
      if (this.state.view_mode !== VIEW_MODE_LIST) {
        this.setState({ view_mode: VIEW_MODE_LIST });
      }
    }
  };
  getAdminType = () => {
    const { auth, intl } = this.props;
    const userDetails = auth.user;
    const authIsBroadcastingGroupAdmin = get(
      auth,
      "user.broadcasting_group_admin"
    );

    if (userDetails.id === 1) {
      return intl.formatMessage({ id: "admins > type ventive admin" });
    } else if (userDetails.is_god_admin) {
      return intl.formatMessage({ id: "admins > type god admin" });
    } else if (authIsBroadcastingGroupAdmin) {
      return intl.formatMessage(
        { id: "admins > type dynamic" },
        { BC_NAME: get(auth, "info.server_name", "") }
      );
    } else {
      return intl.formatMessage(
        { id: "admins > type dynamic" },
        {
          BC_NAME: "Team",
        }
      );
    }
  };

  isHasPrivilegesForAdmins = () => {
    const privileges = get(this.props, "auth.user.privileges") || [];
    const user = get(this.props, "auth.user");

    // for god admin (is_god_admin = 1) or station admin (is_god_admin = 2) or broadcasting admin always true
    if (get(user, "is_god_admin") || get(user, "broadcasting_group_admin")) {
      return adminTabs.TAB_BROADCASTING_GROUP;
    }
    if (get(user, "is_admin")) {
      return adminTabs.TAB_STATIONS;
    }
    // for has a privilege to view or edit servers
    if (
      privileges.includes(PRIVILEGES.ADD_SERVERS) ||
      privileges.includes(PRIVILEGES.DELETE_SERVERS) ||
      privileges.includes(PRIVILEGES.VIEW_SERVERS) ||
      privileges.includes(PRIVILEGES.EDIT_SERVERS)
    ) {
      return adminTabs.TAB_BROADCASTING_GROUP;
    }

    // for has a privilege to view or edit stations
    if (
      privileges.includes(PRIVILEGES.ADD_STATIONS) ||
      privileges.includes(PRIVILEGES.VIEW_STATIONS) ||
      privileges.includes(PRIVILEGES.DELETE_STATIONS) ||
      privileges.includes(PRIVILEGES.EDIT_STATIONS)
    ) {
      return adminTabs.TAB_STATIONS;
    }
    // for has a privilege to view or edit users
    if (
      privileges.includes(PRIVILEGES.ADD_USERS) ||
      privileges.includes(PRIVILEGES.EDIT_USERS) ||
      // privileges.includes(PRIVILEGES.VIEW_USERS) ||
      privileges.includes(PRIVILEGES.DELETE_USERS) ||
      privileges.includes(PRIVILEGES.ASSIGN_PRIVILEGES)
    ) {
      return adminTabs.TAB_USERS;
    }

    if (
      privileges.includes(PRIVILEGES.ADD_TEAMS) ||
      privileges.includes(PRIVILEGES.VIEW_TEAMS) ||
      privileges.includes(PRIVILEGES.DELETE_TEAMS) ||
      privileges.includes(PRIVILEGES.EDIT_TEAMS)
    ) {
      return adminTabs.TAB_TEAMS;
    }

    return false;
  };

  firstVisibleTab = () => {
    const { isHasPrivilegeAccessTab } = this.props;

    if (isHasPrivilegeAccessTab(adminTabs.TAB_BROADCASTING_GROUP))
      return adminTabs.TAB_BROADCASTING_GROUP;
    if (isHasPrivilegeAccessTab(adminTabs.TAB_STATIONS))
      return adminTabs.TAB_STATIONS;
    if (isHasPrivilegeAccessTab(adminTabs.TAB_ROLES))
      return adminTabs.TAB_ROLES;
    if (isHasPrivilegeAccessTab(adminTabs.TAB_ADMINS))
      return adminTabs.TAB_ADMINS;
    if (isHasPrivilegeAccessTab(adminTabs.TAB_USERS))
      return adminTabs.TAB_USERS;
    if (isHasPrivilegeAccessTab(adminTabs.TAB_HUBS)) return adminTabs.TAB_HUBS;
    if (isHasPrivilegeAccessTab(adminTabs.TAB_ZETTA))
      return adminTabs.TAB_ZETTA;
    if (isHasPrivilegeAccessTab(adminTabs.TAB_OUT_OF_OFFICE))
      return adminTabs.TAB_OUT_OF_OFFICE;
  };
  renderBreadcrumbs = () => {
    const { intl, activeTab } = this.props;
    let breadcrumbs = [
      {
        name: intl.formatMessage({ id: "breadcrumbs > admin" }),
        to: URL.ADMINS({
          tab: this.isHasPrivilegesForAdmins(),
        }),
        active: false,
      },
    ];
    switch (activeTab) {
      case adminTabs.TAB_STATIONS:
        breadcrumbs = [
          ...breadcrumbs,
          {
            name: intl.formatMessage({ id: "breadcrumbs > stations" }),
            to: URL.ADMINS({
              tab: adminTabs.TAB_STATIONS,
            }),
            active: activeTab === adminTabs.TAB_STATIONS,
          },
        ];
        break;
      case adminTabs.TAB_ROLES:
        breadcrumbs = [
          ...breadcrumbs,
          {
            name: intl.formatMessage({ id: "breadcrumbs > roles" }),
            to: URL.ADMINS({
              tab: adminTabs.TAB_ROLES,
            }),
            active: activeTab === adminTabs.TAB_ROLES,
          },
        ];
        break;
      case adminTabs.TAB_ADMINS:
        breadcrumbs = [
          ...breadcrumbs,
          {
            name: intl.formatMessage({ id: "breadcrumbs > admins" }),
            to: URL.ADMINS({
              tab: adminTabs.TAB_ADMINS,
            }),
            active: activeTab === adminTabs.TAB_ADMINS,
          },
        ];
        break;
      case adminTabs.TAB_USERS:
        breadcrumbs = [
          ...breadcrumbs,
          {
            name: intl.formatMessage({ id: "breadcrumbs > users" }),
            to: URL.ADMINS({
              tab: adminTabs.TAB_USERS,
            }),
            active: activeTab === adminTabs.TAB_USERS,
          },
        ];
        break;
      case adminTabs.TAB_TEAMS:
        breadcrumbs = [
          ...breadcrumbs,
          {
            name: intl.formatMessage({ id: "breadcrumbs > teams" }),
            to: URL.ADMINS({
              tab: adminTabs.TAB_TEAMS,
            }),
            active: activeTab === adminTabs.TAB_TEAMS,
          },
        ];
        break;
      case adminTabs.TAB_CITIES:
        breadcrumbs = [
          ...breadcrumbs,
          {
            name: intl.formatMessage({ id: "breadcrumbs > cities" }),
            to: URL.ADMINS({
              tab: adminTabs.TAB_CITIES,
            }),
            active: activeTab === adminTabs.TAB_CITIES,
          },
        ];
        break;
      case adminTabs.TAB_GROUPS:
        breadcrumbs = [
          ...breadcrumbs,
          {
            name: intl.formatMessage({ id: "breadcrumbs > groups" }),
            to: URL.ADMINS({
              tab: adminTabs.TAB_GROUPS,
            }),
            active: activeTab === adminTabs.TAB_GROUPS,
          },
        ];
        break;
      case adminTabs.TAB_WIDEORBIT:
        breadcrumbs = [
          ...breadcrumbs,
          {
            name: intl.formatMessage({ id: "breadcrumbs > wideorbit" }),
            to: URL.ADMINS({
              tab: adminTabs.TAB_WIDEORBIT,
            }),
            active: activeTab === adminTabs.TAB_WIDEORBIT,
          },
        ];
        break;
      case adminTabs.TAB_HUBS:
        breadcrumbs = [
          ...breadcrumbs,
          {
            name: intl.formatMessage({ id: "breadcrumbs > hubs" }),
            to: URL.ADMINS({
              tab: adminTabs.TAB_HUBS,
            }),
            active:
              this.state.view_mode !== VIEW_MODE_HUB_DETAILS &&
              activeTab === adminTabs.TAB_HUBS,
          },
        ];
        if (this.state.view_mode === VIEW_MODE_HUB_DETAILS) {
          breadcrumbs = [
            ...breadcrumbs,
            {
              name: get(this.props, "hub.name") || "",
              active: true,
            },
          ];
        }
        break;
      case adminTabs.TAB_ZETTA:
        breadcrumbs = [
          ...breadcrumbs,
          {
            name: intl.formatMessage({ id: "breadcrumbs > zetta" }),
            to: URL.ADMINS({
              tab: adminTabs.TAB_ZETTA,
            }),
            active: activeTab === adminTabs.TAB_ZETTA,
          },
        ];
        break;
      case adminTabs.TAB_OUT_OF_OFFICE:
        breadcrumbs = [
          ...breadcrumbs,
          {
            name: intl.formatMessage({ id: "breadcrumbs > out of office" }),
            to: URL.ADMINS({
              tab: adminTabs.TAB_OUT_OF_OFFICE,
            }),
            active: activeTab === adminTabs.TAB_OUT_OF_OFFICE,
          },
        ];
        break;
      default:
        breadcrumbs = [
          ...breadcrumbs,
          {
            name: intl.formatMessage({ id: "breadcrumbs > broadcaster" }),
            to: URL.ADMINS({
              tab: adminTabs.TAB_BROADCASTING_GROUP,
            }),
            active: activeTab === adminTabs.TAB_BROADCASTING_GROUP,
          },
        ];
        break;
    }
    return breadcrumbs;
  };

  render() {
    const { activeTab, isHasPrivilegeAccessTab, intl, auth } = this.props;
    const authIsGodAdmin = get(auth, "user.is_god_admin", false);
    const authIsAdmin = get(auth, "user.is_admin", false);
    const authIsBroadcastingGroupAdmin = get(
      auth,
      "user.broadcasting_group_admin"
    );
    const showOnlyGlobalWOConfig =
      auth.info.app_flags && auth.info.app_flags.indexOf("SERVERS_LIST") > -1;
    const showWideOrbitTab =
      get(auth, "info.enable_wo_dubbing") ||
      get(auth, "info.enable_wo_traffic") ||
      showOnlyGlobalWOConfig;
    const showSmtpConfigTab =
      auth.info.app_flags && auth.info.app_flags.indexOf("SERVERS_LIST") > -1;

    return (
      <PrivilegedComponent
        requires={(privileges) => {
          if (authIsGodAdmin || authIsBroadcastingGroupAdmin || authIsAdmin)
            return true;
          return (
            privileges.indexOf(PRIVILEGES.VIEW_USERS) > -1 ||
            privileges.indexOf(PRIVILEGES.EDIT_USERS) > -1 ||
            privileges.indexOf(PRIVILEGES.ADD_USERS) > -1 ||
            privileges.indexOf(PRIVILEGES.DELETE_USERS) > -1 ||
            privileges.indexOf(PRIVILEGES.ASSIGN_PRIVILEGES) > -1 ||
            privileges.indexOf(PRIVILEGES.VIEW_STATIONS) > -1 ||
            privileges.indexOf(PRIVILEGES.EDIT_STATIONS) > -1 ||
            privileges.indexOf(PRIVILEGES.DELETE_STATIONS) > -1 ||
            privileges.indexOf(PRIVILEGES.ADD_STATIONS) > -1 ||
            privileges.indexOf(PRIVILEGES.ADD_SERVERS) > -1 ||
            privileges.indexOf(PRIVILEGES.EDIT_SERVERS) > -1 ||
            privileges.indexOf(PRIVILEGES.VIEW_SERVERS) > -1 ||
            privileges.indexOf(PRIVILEGES.DELETE_SERVERS) > -1 ||
            privileges.indexOf(PRIVILEGES.VIEW_TEAMS) > -1 ||
            privileges.indexOf(PRIVILEGES.EDIT_TEAMS) > -1 ||
            privileges.indexOf(PRIVILEGES.DELETE_TEAMS) > -1 ||
            privileges.indexOf(PRIVILEGES.ADD_TEAMS) > -1
          );
        }}
      >
        {({ isValid }) => {
          const { production_multimarket } = this.props.auth.info;
          if (isValid) {
            return (
              <PageAdmin
                title={intl.formatMessage(
                  { id: "pages > admin" },
                  { type: this.getAdminType() }
                )}
                breadcrumbs={this.renderBreadcrumbs()}
                className={classnames(bem.b(), bem.m(this.state.view_mode))}
              >
                <Row>
                  <Col>
                    <Nav tabs className={bem.e("nav")}>
                      {isHasPrivilegeAccessTab(
                        adminTabs.TAB_BROADCASTING_GROUP
                      ) && (
                        <NavItem>
                          <BSNavLink
                            className={classnames(bem.e("tab-link"))}
                            tag={NavLink}
                            to={{
                              pathname: URL.ADMINS({
                                tab: adminTabs.TAB_BROADCASTING_GROUP,
                              }),
                              isReminder: true,
                            }}
                          >
                            {auth.info.app_flags &&
                            auth.info.app_flags.indexOf("SERVERS_LIST") > -1 ? (
                              <FormattedMessage id="admins > tab broadcasting groups list" />
                            ) : (
                              <FormattedMessage id="admins > tab broadcaster" />
                            )}
                          </BSNavLink>
                        </NavItem>
                      )}

                      {isHasPrivilegeAccessTab(adminTabs.TAB_STATIONS) && (
                        <NavItem>
                          <BSNavLink
                            className={classnames(bem.e("tab-link"))}
                            tag={NavLink}
                            to={{
                              pathname: URL.ADMINS({
                                tab: adminTabs.TAB_STATIONS,
                              }),
                              isReminder: true,
                            }}
                          >
                            <FormattedMessage id="admins > tab stations" />
                          </BSNavLink>
                        </NavItem>
                      )}
                      {isHasPrivilegeAccessTab(adminTabs.TAB_ROLES) && (
                        <NavItem>
                          <BSNavLink
                            className={classnames(bem.e("tab-link"))}
                            tag={NavLink}
                            to={{
                              pathname: URL.ADMINS({
                                tab: adminTabs.TAB_ROLES,
                              }),
                              isReminder: true,
                            }}
                          >
                            <FormattedMessage id="admins > tab roles" />
                          </BSNavLink>
                        </NavItem>
                      )}
                      {isHasPrivilegeAccessTab(adminTabs.TAB_ADMINS) && (
                        <NavItem>
                          <BSNavLink
                            className={classnames(bem.e("tab-link"))}
                            tag={NavLink}
                            to={{
                              pathname: URL.ADMINS({
                                tab: adminTabs.TAB_ADMINS,
                              }),
                              isReminder: true,
                            }}
                          >
                            <FormattedMessage id="admins > tab admins" />
                          </BSNavLink>
                        </NavItem>
                      )}
                      {isHasPrivilegeAccessTab(adminTabs.TAB_USERS) && (
                        <NavItem>
                          <BSNavLink
                            className={classnames(bem.e("tab-link"))}
                            tag={NavLink}
                            to={{
                              pathname: URL.ADMINS({
                                tab: adminTabs.TAB_USERS,
                              }),
                              isReminder: true,
                            }}
                          >
                            {auth.info.app_flags &&
                            auth.info.app_flags.indexOf("SERVERS_LIST") > -1 ? (
                              <FormattedMessage id="admins > tab god admins" />
                            ) : (
                              <FormattedMessage id="admins > tab users" />
                            )}
                          </BSNavLink>
                        </NavItem>
                      )}
                      {showWideOrbitTab && authIsGodAdmin && (
                        <NavItem>
                          <BSNavLink
                            className={classnames(bem.e("tab-link"))}
                            tag={NavLink}
                            to={{
                              pathname: URL.ADMINS({
                                tab: adminTabs.TAB_WIDEORBIT,
                              }),
                              isReminder: true,
                            }}
                          >
                            <FormattedMessage id="admins > tab wideorbit" />
                          </BSNavLink>
                        </NavItem>
                      )}
                      {showSmtpConfigTab && authIsGodAdmin && (
                        <NavItem>
                          <BSNavLink
                            className={classnames(bem.e("tab-link"))}
                            tag={NavLink}
                            to={{
                              pathname: URL.ADMINS({
                                tab: adminTabs.TAB_SMTP_CONFIG,
                              }),
                              isReminder: true,
                            }}
                          >
                            <FormattedMessage id="admins > tab smtp config" />
                          </BSNavLink>
                        </NavItem>
                      )}
                      {/* {production_multimarket &&
                          isHasPrivilegeAccessTab(adminTabs.TAB_TEAMS) && (
                            <NavItem>
                              <BSNavLink
                                className={classnames(bem.e("tab-link"))}
                                tag={NavLink}
                                to={{
                                  pathname: URL.ADMINS({
                                    tab: adminTabs.TAB_TEAMS,
                                  }),
                                  isReminder: true,
                                }}
                              >
                                <FormattedMessage id="admins > tab teams" />
                              </BSNavLink>
                            </NavItem>
                          )} */}
                      {production_multimarket &&
                        isHasPrivilegeAccessTab(adminTabs.TAB_HUBS) && (
                          <NavItem>
                            <BSNavLink
                              className={classnames(bem.e("tab-link"))}
                              tag={NavLink}
                              to={{
                                pathname: URL.ADMINS({
                                  tab: adminTabs.TAB_HUBS,
                                }),
                                isReminder: true,
                              }}
                            >
                              <FormattedMessage id="admins > tab hubs" />
                            </BSNavLink>
                          </NavItem>
                        )}
                      {isHasPrivilegeAccessTab(adminTabs.TAB_ZETTA) && (
                        <NavItem>
                          <BSNavLink
                            className={classnames(bem.e("tab-link"))}
                            tag={NavLink}
                            to={{
                              pathname: URL.ADMINS({
                                tab: adminTabs.TAB_ZETTA,
                              }),
                              isReminder: true,
                            }}
                          >
                            <FormattedMessage id="admins > tab zetta" />
                          </BSNavLink>
                        </NavItem>
                      )}
                      {isHasPrivilegeAccessTab(adminTabs.TAB_OUT_OF_OFFICE) && (
                        <NavItem>
                          <BSNavLink
                            className={classnames(bem.e("tab-link"))}
                            tag={NavLink}
                            to={{
                              pathname: URL.ADMINS({
                                tab: adminTabs.TAB_OUT_OF_OFFICE,
                              }),
                              isReminder: true,
                            }}
                          >
                            <FormattedMessage id="admins > tab out of office" />
                          </BSNavLink>
                        </NavItem>
                      )}
                    </Nav>
                    <TabContent activeTab={activeTab}>
                      {this.state.view_mode ===
                        VIEW_MODE_BROADCASTING_DETAILS && (
                        <TabPane tabId={activeTab}>
                          <Row>
                            <Col>
                              <BroadcastingDetails
                                lists={
                                  get(this.state, "selectedInlineServer")
                                    ? get(this.state, "selectedInlineServer")
                                    : get(this.state, "selectedServers")
                                }
                                activeTab={activeTab}
                                isView={this.state.isViewModeServer}
                                back={this.onBackServer}
                              />
                            </Col>
                          </Row>
                        </TabPane>
                      )}
                      {this.state.view_mode === VIEW_MODE_ADMIN_DETAILS && (
                        <TabPane tabId={activeTab}>
                          <Row>
                            <Col>
                              <AdminsDetails
                                lists={
                                  get(this.state, "selectedInlineUser")
                                    ? get(this.state, "selectedInlineUser")
                                    : get(this.state, "selectedUsers")
                                }
                                aTab={activeTab}
                                activeTab={activeTab}
                                isView={this.state.isViewModeUser}
                                onSelectedEdit={(data) => {
                                  this.setState({
                                    view_mode: VIEW_MODE_ADMIN_DETAILS,
                                    selectedInlineUser: data,
                                    isViewModeUser: USER_FORM_MODE_EDIT,
                                  });
                                }}
                                back={() =>
                                  this.setState({
                                    view_mode: VIEW_MODE_LIST,
                                    selectedInlineUser: null,
                                    isViewModeUser: USER_FORM_MODE_EDIT,
                                  })
                                }
                              />
                            </Col>
                          </Row>
                        </TabPane>
                      )}
                      {this.state.view_mode === VIEW_MODE_STATIONS_DETAILS && (
                        <TabPane tabId={activeTab}>
                          <Row>
                            <Col>
                              <StationsDetails
                                lists={
                                  get(this.state, "selectedInlineStation")
                                    ? get(this.state, "selectedInlineStation")
                                    : get(this.state, "selectedStations")
                                }
                                activeTab={activeTab}
                                isView={this.state.isViewModeStation}
                                back={() => {
                                  if (
                                    document.location.href.indexOf(
                                      "/stations/add"
                                    ) > -1
                                  ) {
                                    history.push(
                                      URL.ADMINS({
                                        tab: adminTabs.TAB_STATIONS,
                                      })
                                    );
                                  } else {
                                    this.setState({
                                      view_mode: VIEW_MODE_LIST,
                                      selectedInlineStation: null,
                                      isViewModeStation: STATION_FORM_MODE_EDIT,
                                    });
                                  }
                                }}
                              />
                            </Col>
                          </Row>
                        </TabPane>
                      )}
                      {this.state.view_mode === VIEW_MODE_ROLE_DETAILS && (
                        <TabPane tabId={activeTab}>
                          <Row>
                            <Col>
                              <RoleDetails
                                lists={
                                  get(this.state, "selectedInlineRole")
                                    ? get(this.state, "selectedInlineRole")
                                    : get(this.state, "selectedServers")
                                }
                                activeTab={activeTab}
                                isView={this.state.isViewModeRole}
                                back={() =>
                                  this.setState({
                                    view_mode: VIEW_MODE_LIST,
                                    selectedInlineRole: null,
                                    isViewModeRole: false,
                                  })
                                }
                              />
                            </Col>
                          </Row>
                        </TabPane>
                      )}

                      {/* {this.state.view_mode === VIEW_MODE_ADD_TEAM && (
                            <TabPane tabId={activeTab}>
                              <Row>
                                <Col>
                                  <TeamForm
                                    selectedTeamId={this.state.selectedTeamId}
                                    back={() =>
                                      this.setState({
                                        view_mode: VIEW_MODE_LIST,
                                        selectedTeamId: null,
                                      })
                                    }
                                  />
                                </Col>
                              </Row>
                            </TabPane>
                          )} */}
                      {/* {this.state.view_mode === VIEW_MODE_VIEW_TEAM && (
                            <TabPane tabId={activeTab}>
                              <Row>
                                <Col>
                                  <TeamDetailsView
                                    selectedTeamId={this.state.selectedTeamId}
                                    back={() =>
                                      this.setState({
                                        view_mode: VIEW_MODE_LIST,
                                        selectedTeamId: null,
                                      })
                                    }
                                    onSelectedUserEdit={(data) => {
                                      this.setState({
                                        view_mode: VIEW_MODE_ADMIN_DETAILS,
                                        selectedInlineUser: data,
                                        isViewModeUser: USER_FORM_MODE_EDIT,
                                      });
                                    }}
                                    onSelectedUserView={(data) => {
                                      this.setState({
                                        view_mode: VIEW_MODE_ADMIN_DETAILS,
                                        selectedInlineUser: data,
                                        isViewModeUser: USER_FORM_MODE_VIEW,
                                      });
                                    }}
                                  />
                                </Col>
                              </Row>
                            </TabPane>
                          )} */}
                      {this.state.view_mode === VIEW_MODE_HUB_DETAILS && (
                        <TabPane tabId={activeTab}>
                          <Row>
                            <Col>
                              <HubDetailsView
                                selectedId={this.state.selectedHubId}
                                back={() =>
                                  this.setState({
                                    view_mode: VIEW_MODE_LIST,
                                    selectedHubId: null,
                                  })
                                }
                              />
                            </Col>
                          </Row>
                        </TabPane>
                      )}
                      {isHasPrivilegeAccessTab(
                        adminTabs.TAB_BROADCASTING_GROUP
                      ) && (
                        <TabPane tabId={adminTabs.TAB_BROADCASTING_GROUP}>
                          <Row
                            style={{
                              display:
                                this.state.view_mode !== VIEW_MODE_LIST &&
                                "none",
                            }}
                          >
                            <Col>
                              <div className={bem.e("tab-detail")}>
                                {get(this.props, "auth.user.is_god_admin") &&
                                  activeTab ===
                                    adminTabs.TAB_BROADCASTING_GROUP && (
                                    <ServersList
                                      onSelectedEdit={(data) => {
                                        this.setState({
                                          view_mode: VIEW_MODE_BROADCASTING_DETAILS,
                                          selectedInlineServer: data,
                                          isViewModeServer: false,
                                        });
                                      }}
                                      onSelectedView={(data) => {
                                        this.setState({
                                          view_mode: VIEW_MODE_BROADCASTING_DETAILS,
                                          selectedInlineServer: data,
                                          isViewModeServer: true,
                                        });
                                      }}
                                      onSelectedAdd={() => {
                                        this.setState({
                                          view_mode: VIEW_MODE_BROADCASTING_DETAILS,
                                          selectedInlineServer: null,
                                          isViewModeServer: false,
                                        });
                                      }}
                                      isFocused={
                                        activeTab ===
                                        adminTabs.TAB_BROADCASTING_GROUP
                                      }
                                    />
                                  )}
                              </div>
                            </Col>
                          </Row>
                        </TabPane>
                      )}
                      {isHasPrivilegeAccessTab(adminTabs.TAB_STATIONS) && (
                        <TabPane tabId={adminTabs.TAB_STATIONS}>
                          <Row
                            style={{
                              display:
                                this.state.view_mode !== VIEW_MODE_LIST &&
                                "none",
                            }}
                          >
                            <Col>
                              <div className={bem.e("tab-detail")}>
                                {activeTab === adminTabs.TAB_STATIONS ? (
                                  <StationsList
                                    key="admin_stations"
                                    onSelectedEdit={(data) => {
                                      this.setState({
                                        view_mode: VIEW_MODE_STATIONS_DETAILS,
                                        selectedInlineStation: data,
                                        isViewModeStation: STATION_FORM_MODE_EDIT,
                                      });
                                    }}
                                    onSelectedView={(data) => {
                                      this.setState({
                                        view_mode: VIEW_MODE_STATIONS_DETAILS,
                                        selectedInlineStation: data,
                                        isViewModeStation: STATION_FORM_MODE_VIEW,
                                      });
                                    }}
                                    onSelectedAdd={() => {
                                      this.setState({
                                        view_mode: VIEW_MODE_STATIONS_DETAILS,
                                        selectedInlineStation: null,
                                        isViewModeStation: STATION_FORM_MODE_ADD,
                                      });
                                    }}
                                    selectedStationsChanged={(
                                      stationList,
                                      selected
                                    ) => {
                                      const checked = stationList.filter(
                                        (u) =>
                                          Object.keys(selected).filter(
                                            (s) => selected[u.id]
                                          ).length > 0
                                      );
                                      const unchecked = stationList.filter(
                                        (u) =>
                                          Object.keys(selected).filter(
                                            (s) => selected[u.id] === false
                                          ).length > 0
                                      );
                                      var fullList = this.state
                                        .selectedStations;
                                      checked.map((c) => {
                                        if (!fullList[c.id])
                                          fullList[c.id] = checked.find(
                                            (u) => u.id === c.id
                                          );
                                        return true;
                                      });
                                      unchecked.map((c) => {
                                        if (fullList[c.id])
                                          delete fullList[c.id];
                                        return true;
                                      });
                                      this.setState({
                                        selectedStations: fullList,
                                        selectedInlineStation: null,
                                      });
                                    }}
                                    isFocused={
                                      activeTab === adminTabs.TAB_STATIONS
                                    }
                                  />
                                ) : null}

                                {Object.keys(this.state.selectedStations)
                                  .length !== 0 && (
                                  <Button
                                    color="blue"
                                    className="btn btn-radius"
                                    onClick={() =>
                                      this.setState({
                                        view_mode: VIEW_MODE_STATIONS_DETAILS,
                                        isViewModeUser: USER_FORM_MODE_EDIT,
                                      })
                                    }
                                  >
                                    <span>
                                      {intl.formatMessage(
                                        {
                                          id:
                                            Object.keys(
                                              this.state.selectedStations
                                            ).length === 1
                                              ? "admins > button edit selected station"
                                              : "admins > button edit selected stations",
                                        },
                                        {
                                          COUNT: Object.keys(
                                            this.state.selectedStations
                                          ).length,
                                        }
                                      )}
                                    </span>
                                  </Button>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </TabPane>
                      )}
                      {isHasPrivilegeAccessTab(adminTabs.TAB_ROLES) && (
                        <TabPane tabId={adminTabs.TAB_ROLES}>
                          <Row
                            style={{
                              display:
                                this.state.view_mode !== VIEW_MODE_LIST &&
                                "none",
                            }}
                          >
                            <Col>
                              {/* avoid warning duplicate block history */}
                              {activeTab === adminTabs.TAB_ROLES && (
                                <div className={bem.e("tab-detail")}>
                                  <RolesManager
                                    onSelectedEdit={(data) => {
                                      this.setState({
                                        view_mode: VIEW_MODE_ROLE_DETAILS,
                                        selectedInlineRole: data,
                                        isViewModeRole: false,
                                      });
                                    }}
                                    onSelectedView={(data) => {
                                      this.setState({
                                        view_mode: VIEW_MODE_ROLE_DETAILS,
                                        selectedInlineRole: data,
                                        isViewModeRole: true,
                                      });
                                    }}
                                    onSelectedAdd={() => {
                                      this.setState({
                                        view_mode: VIEW_MODE_ROLE_DETAILS,
                                        selectedInlineRole: null,
                                        isViewModeRole: false,
                                      });
                                    }}
                                    isFocused={
                                      activeTab === adminTabs.TAB_ROLES
                                    }
                                  />
                                </div>
                              )}
                            </Col>
                          </Row>
                        </TabPane>
                      )}
                      {isHasPrivilegeAccessTab(adminTabs.TAB_ADMINS) && (
                        <TabPane tabId={adminTabs.TAB_ADMINS}>
                          <Row
                            style={{
                              display:
                                this.state.view_mode !== VIEW_MODE_LIST &&
                                "none",
                            }}
                          >
                            <Col>
                              {activeTab === adminTabs.TAB_ADMINS ? (
                                <div className={bem.e("tab-detail")}>
                                  <AdminsList
                                    onSelectedEdit={(data) => {
                                      this.setState({
                                        view_mode: VIEW_MODE_ADMIN_DETAILS,
                                        selectedInlineUser: data,
                                        isViewModeUser: USER_FORM_MODE_EDIT,
                                      });
                                    }}
                                    onSelectedView={(data) => {
                                      this.setState({
                                        view_mode: VIEW_MODE_ADMIN_DETAILS,
                                        selectedInlineUser: data,
                                        isViewModeUser: USER_FORM_MODE_VIEW,
                                      });
                                    }}
                                    onSelectedAdd={(data) => {
                                      this.setState({
                                        view_mode: VIEW_MODE_ADMIN_DETAILS,
                                        selectedInlineUser: data,
                                        isViewModeUser: USER_FORM_MODE_ADD,
                                      });
                                      this.props.setNewUser({
                                        id: "new",
                                        data: {},
                                        isForce: true,
                                      });
                                    }}
                                    selectedUsersChanged={(
                                      userList,
                                      selected
                                    ) => {
                                      const checked = userList.filter(
                                        (u) =>
                                          Object.keys(selected).filter(
                                            (s) => selected[u.id]
                                          ).length > 0
                                      );
                                      const unchecked = userList.filter(
                                        (u) =>
                                          Object.keys(selected).filter(
                                            (s) => selected[u.id] === false
                                          ).length > 0
                                      );
                                      var fullList = this.state.selectedUsers;
                                      checked.map((c) => {
                                        if (!fullList[c.id])
                                          fullList[c.id] = checked.find(
                                            (u) => u.id === c.id
                                          );
                                        return true;
                                      });
                                      unchecked.map((c) => {
                                        if (fullList[c.id])
                                          delete fullList[c.id];
                                        return true;
                                      });
                                      this.setState({
                                        selectedUsers: fullList,
                                        selectedInlineUser: null,
                                      });
                                    }}
                                    isFocused={
                                      activeTab === adminTabs.TAB_ADMINS
                                    }
                                  />
                                </div>
                              ) : null}

                              {Object.keys(this.state.selectedUsers).length !==
                                0 && (
                                <Button
                                  color="blue"
                                  className="btn btn-radius"
                                  onClick={() =>
                                    this.setState({
                                      view_mode: VIEW_MODE_ADMIN_DETAILS,
                                    })
                                  }
                                >
                                  <span>
                                    {intl.formatMessage(
                                      {
                                        id:
                                          Object.keys(this.state.selectedUsers)
                                            .length === 1
                                            ? "admins > button edit selected user"
                                            : "admins > button edit selected users",
                                      },
                                      {
                                        COUNT: Object.keys(
                                          this.state.selectedUsers
                                        ).length,
                                      }
                                    )}
                                  </span>
                                </Button>
                              )}
                            </Col>
                          </Row>
                        </TabPane>
                      )}
                      {isHasPrivilegeAccessTab(adminTabs.TAB_USERS) && (
                        <TabPane tabId={adminTabs.TAB_USERS}>
                          <Row
                            style={{
                              display:
                                this.state.view_mode !== VIEW_MODE_LIST &&
                                "none",
                            }}
                          >
                            <Col>
                              {activeTab === adminTabs.TAB_USERS ? (
                                <div className={bem.e("tab-detail")}>
                                  <UsersList
                                    onSelectedEdit={(data) => {
                                      this.setState({
                                        view_mode: VIEW_MODE_ADMIN_DETAILS,
                                        selectedInlineUser: data,
                                        isViewModeUser: USER_FORM_MODE_EDIT,
                                      });
                                    }}
                                    onSelectedView={(data) => {
                                      this.setState({
                                        view_mode: VIEW_MODE_ADMIN_DETAILS,
                                        selectedInlineUser: data,
                                        isViewModeUser: USER_FORM_MODE_VIEW,
                                      });
                                    }}
                                    onSelectedAdd={(data) => {
                                      this.setState({
                                        view_mode: VIEW_MODE_ADMIN_DETAILS,
                                        selectedInlineUser: data,
                                        isViewModeUser: USER_FORM_MODE_ADD,
                                      });
                                      this.props.setNewUser({
                                        id: "new",
                                        data: {},
                                        isForce: true,
                                      });
                                    }}
                                    selectedUsersChanged={(
                                      userList,
                                      selected
                                    ) => {
                                      const checked = userList.filter(
                                        (u) =>
                                          Object.keys(selected).filter(
                                            (s) => selected[u.id]
                                          ).length > 0
                                      );
                                      const unchecked = userList.filter(
                                        (u) =>
                                          Object.keys(selected).filter(
                                            (s) => selected[u.id] === false
                                          ).length > 0
                                      );
                                      var fullList = this.state.selectedUsers;
                                      checked.map((c) => {
                                        if (!fullList[c.id])
                                          fullList[c.id] = checked.find(
                                            (u) => u.id === c.id
                                          );
                                        return true;
                                      });
                                      unchecked.map((c) => {
                                        if (fullList[c.id])
                                          delete fullList[c.id];
                                        return true;
                                      });
                                      this.setState({
                                        selectedUsers: fullList,
                                        selectedInlineUser: null,
                                      });
                                    }}
                                    isFocused={
                                      activeTab === adminTabs.TAB_USERS
                                    }
                                  />
                                </div>
                              ) : null}

                              {Object.keys(this.state.selectedUsers).length !==
                                0 && (
                                <Button
                                  color="blue"
                                  className="btn btn-radius"
                                  onClick={() =>
                                    this.setState({
                                      view_mode: VIEW_MODE_ADMIN_DETAILS,
                                    })
                                  }
                                >
                                  <span>
                                    {intl.formatMessage(
                                      {
                                        id:
                                          Object.keys(this.state.selectedUsers)
                                            .length === 1
                                            ? "admins > button edit selected user"
                                            : "admins > button edit selected users",
                                      },
                                      {
                                        COUNT: Object.keys(
                                          this.state.selectedUsers
                                        ).length,
                                      }
                                    )}
                                  </span>
                                </Button>
                              )}
                            </Col>
                          </Row>
                        </TabPane>
                      )}
                      {isHasPrivilegeAccessTab(adminTabs.TAB_HUBS) && (
                        <TabPane tabId={adminTabs.TAB_HUBS}>
                          <Row
                            style={{
                              display:
                                this.state.view_mode !== VIEW_MODE_LIST &&
                                "none",
                            }}
                          >
                            {activeTab === adminTabs.TAB_HUBS ? (
                              <Col>
                                <div className={bem.e("tab-detail")}>
                                  <HubsList
                                    key="admin_hubs"
                                    onSelectedEdit={(hubId) => {
                                      this.setState({
                                        view_mode: VIEW_MODE_HUB_DETAILS,
                                        selectedHubId: hubId,
                                      });
                                    }}
                                    isFocused={activeTab === adminTabs.TAB_HUBS}
                                  />
                                </div>
                              </Col>
                            ) : null}
                          </Row>
                        </TabPane>
                      )}
                      {isHasPrivilegeAccessTab(adminTabs.TAB_ZETTA) && (
                        <TabPane tabId={adminTabs.TAB_ZETTA}>
                          <Row
                            style={{
                              display:
                                this.state.view_mode !== VIEW_MODE_LIST &&
                                "none",
                            }}
                          >
                            {activeTab === adminTabs.TAB_ZETTA ? (
                              <Col>
                                <div className={bem.e("tab-detail")}>
                                  <Zetta
                                    key="admin_zetta"
                                    isFocused={
                                      activeTab === adminTabs.TAB_ZETTA
                                    }
                                  />
                                </div>
                              </Col>
                            ) : null}
                          </Row>
                        </TabPane>
                      )}
                      {isHasPrivilegeAccessTab(adminTabs.TAB_OUT_OF_OFFICE) && (
                        <TabPane tabId={adminTabs.TAB_OUT_OF_OFFICE}>
                          <Row
                            style={{
                              display:
                                this.state.view_mode !== VIEW_MODE_LIST &&
                                "none",
                            }}
                          >
                            <Col>
                              {activeTab === adminTabs.TAB_OUT_OF_OFFICE ? (
                                <div className={bem.e("tab-detail")}>
                                  <OutOfOfficeList
                                    onSelectedEdit={(data) => {}}
                                    isFocused={
                                      activeTab === adminTabs.TAB_OUT_OF_OFFICE
                                    }
                                  />
                                </div>
                              ) : null}
                            </Col>
                          </Row>
                        </TabPane>
                      )}
                      {/* {isHasPrivilegeAccessTab(adminTabs.TAB_TEAMS) && (
                            <TabPane tabId={adminTabs.TAB_TEAMS}>
                              <Row
                                style={{
                                  display:
                                    this.state.view_mode !== VIEW_MODE_LIST &&
                                    "none",
                                }}
                              >
                                {activeTab === adminTabs.TAB_TEAMS ? (
                                  <Col>
                                    <div className={bem.e("tab-detail")}>
                                      <TeamsList
                                        key="admin_teams"
                                        onSelectedView={(teamId) => {
                                          this.setState({
                                            view_mode: VIEW_MODE_VIEW_TEAM,
                                            selectedTeamId: teamId,
                                          });
                                        }}
                                        onClickAddTeam={() => {
                                          this.setState({
                                            view_mode: VIEW_MODE_ADD_TEAM,
                                            selectedTeamId: null,
                                          });
                                        }}
                                        isFocused={
                                          activeTab === adminTabs.TAB_TEAMS
                                        }
                                      />
                                    </div>
                                  </Col>
                                ) : null}
                              </Row>
                            </TabPane>
                          )} */}
                      {showWideOrbitTab && authIsGodAdmin && (
                        <TabPane tabId={adminTabs.TAB_WIDEORBIT}>
                          <Row
                            style={{
                              display:
                                this.state.view_mode !== VIEW_MODE_LIST &&
                                "none",
                            }}
                          >
                            <Col>
                              <div className={bem.e("tab-detail")}>
                                <WideOrbitConfigurations
                                  key="wideorbit"
                                  server={
                                    get(this.state, "selectedInlineServer") ||
                                    get(this.state, "selectedServers")
                                  }
                                  isFocused={
                                    activeTab === adminTabs.TAB_WIDEORBIT
                                  }
                                  showOnlyGlobalWOConfig={
                                    showOnlyGlobalWOConfig
                                  }
                                />
                              </div>
                            </Col>
                          </Row>
                        </TabPane>
                      )}
                      {showSmtpConfigTab && authIsGodAdmin && (
                        <TabPane tabId={adminTabs.TAB_SMTP_CONFIG}>
                          <Row
                            style={{
                              display:
                                this.state.view_mode !== VIEW_MODE_LIST &&
                                "none",
                            }}
                          >
                            <Col>
                              <div className={bem.e("tab-detail")}>
                                <SmtpConfigurations
                                  key="smtpconfig"
                                  server={
                                    get(this.state, "selectedInlineServer") ||
                                    get(this.state, "selectedServers")
                                  }
                                  isFocused={
                                    activeTab === adminTabs.TAB_SMTP_CONFIG
                                  }
                                />
                              </div>
                            </Col>
                          </Row>
                        </TabPane>
                      )}
                      {/* {isHasPrivilegeAccessTab(adminTabs.TAB_CITIES) && (
                            <TabPane tabId={adminTabs.TAB_CITIES}>
                              <Row
                                style={{
                                  display:
                                    this.state.view_mode !== VIEW_MODE_LIST &&
                                    "none"
                                }}
                              >
                                <Col>
                                  <div className={bem.e("tab-detail")}>
                                    <CitiesList
                                      isFocused={
                                        activeTab === adminTabs.TAB_CITIES
                                      }
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </TabPane>
                          )}

                          {isHasPrivilegeAccessTab(adminTabs.TAB_GROUPS) && (
                            <TabPane tabId={adminTabs.TAB_GROUPS}>
                              <Row
                                style={{
                                  display:
                                    this.state.view_mode !== VIEW_MODE_LIST &&
                                    "none"
                                }}
                              >
                                <Col>
                                  <div className={bem.e("tab-detail")}>
                                    <GroupsList
                                      isFocused={
                                        activeTab === adminTabs.TAB_GROUPS
                                      }
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </TabPane>
                          )} */}
                    </TabContent>
                  </Col>
                </Row>
              </PageAdmin>
            );
          } else {
            return <Redirect to="/" />;
          }
        }}
      </PrivilegedComponent>
    );
  }
}
export default Admins;
