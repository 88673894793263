import AddPrizePage from "./view";
import { connect } from "react-redux";
import { compose, lifecycle, withState } from "recompose";
import { getPrize, initNewPrize } from "store/actions/prizes";
import { getTemplate } from "store/actions/template";
import { injectIntl } from "react-intl";
import { find, get, isEqual } from "lodash";

const preparePage = (props) => {
  const { match } = props;
  let prize_id = get(match, "params.prizes_id");
  if (!prize_id) {
    props.initNewPrize({});
  }
};

export default injectIntl(
  compose(
    connect(
      (state, props) => {
        const { match } = props;
        let prize_id = get(match, "params.prizes_id");
        let prizeFromList = get(state, "prizes.prizes.filter_prizes_list")
          ? find(
              get(state, "prizes.prizes.filter_prizes_list.data"),
              (prize) => prize._id === prize_id
            )
          : null;
        let prize = prizeFromList
          ? { data: prizeFromList._source, error: null, loading: false }
          : state.prizes.prize;
        let template_id = get(match, "params.template_id");
        const isView =
          document.location.pathname.split("/")[2] === "view" ? true : false;
        return {
          match_prize_id: prize_id,
          match_template_id: template_id,
          prize,
          template_id,
          isView,
          template: state.templates.template.data,
          template_state: state.templates.template,
        };
      },
      {
        initNewPrize,
        getTemplate,
        getPrize,
      }
    ),
    withState("currentPrizeData", "setCurrentPrizeData", ({ prize }) =>
      get(prize, "data", {})
    ),
    lifecycle({
      componentDidMount() {
        const { match, prize } = this.props;
        const id = get(match, "params.prizes_id");
        if (id && (!prize.data || Object.keys(prize.data).length === 0)) {
          this.props.getPrize(id);
        }
        preparePage(this.props);
      },
      componentWillUpdate(nextProps) {
        if (this.props.match_prize_id !== nextProps.match_prize_id) {
          preparePage(nextProps, true);
        }
        if (
          !isEqual(
            get(this.props, "prize.data", {}),
            this.props.currentPrizeData
          ) &&
          get(this.props, "template_id") !== "new_prize"
        ) {
          this.props.setCurrentPrizeData(this.props.prize.data);
        }
      },
    })
  )(AddPrizePage)
);
