import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import bn from "utils/bemnames";
import { SearchMinusIcon } from "components/CustomIcons";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { map } from "lodash";
import FontSlider from "components/JockConsole/FontSlider";
const bem = bn.create("preview-script-modal");
const DEFAULT_SLIDER = 24;

const DisplayScript = (props) => {
  const { fontSize, script } = props;
  return (
    <div className={bem.e("script-item")}>
      <div
        className={bem.e("script")}
        style={{
          fontSize: `${fontSize}px`,
        }}
        dangerouslySetInnerHTML={{
          __html: script,
        }}
      />
    </div>
  );
};
const DisplayProductionScript = (props) => {
  const { fontSize, script, title, length } = props;
  return (
    <div className={bem.e("script-item")}>
      <div className={bem.e("script-head")}>
        {title ? <div className={bem.e("script-title")}>{title}</div> : null}
        {length ? (
          <div className={bem.e("length")}>
            {length} <FormattedMessage id="jock console > second spot" />
          </div>
        ) : null}
      </div>
      <div
        className={bem.e("script")}
        style={{
          fontSize: `${fontSize}px`,
        }}
        dangerouslySetInnerHTML={{
          __html: script,
        }}
      />
    </div>
  );
};
const PreviewScriptModal = (props) => {
  const { title, subtitle, script, scripts, productionData } = props;
  const [fontSize, setFontSize] = useState(DEFAULT_SLIDER);

  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.onToggle}
      className={classNames(bem.b(), bem.m(props.cardType))}
    >
      <ModalHeader className={classNames(bem.e(props.cardType))}>
        {title && <div className={bem.e("title")}>{title}</div>}
        {subtitle && <div className={bem.e("subtitle")}>{subtitle}</div>}
      </ModalHeader>
      <ModalBody>
        <div className={bem.e("section-top")}>
          {props.cardType === "production" && productionData ? (
            <div
              className={classNames(
                bem.e("production-type"),
                productionData.className
              )}
            >
              <div className={bem.e("type-icon")}>{productionData.type}</div>
              <div className={bem.e("type-text")}>
                {productionData.typeText}
              </div>
            </div>
          ) : null}
          <FontSlider
            fontSize={fontSize}
            setFontSize={(value) => setFontSize(value)}
          />
        </div>
        <div className={bem.e("content-script")}>
          {script ? (
            <DisplayScript script={script} fontSize={fontSize} />
          ) : null}
          {scripts && scripts.length > 0 ? (
            <div className={bem.e("scripts")}>
              {map(scripts, (item, index) => (
                <DisplayProductionScript
                  {...item}
                  key={index}
                  fontSize={fontSize}
                />
              ))}
            </div>
          ) : null}
          <div className={bem.e("search-icon")} onClick={props.onToggle}>
            <SearchMinusIcon />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

PreviewScriptModal.prototype = {
  onToggle: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  script: PropTypes.string,
  scripts: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.any,
      script: PropTypes.string,
    })
  ),
  productionData: PropTypes.any,
};

PreviewScriptModal.defaultProps = {
  onToggle: () => {},
  onCancel: () => {},
  title: "",
  subtitle: "",
  script: "",
  productionData: null,
};

export default PreviewScriptModal;
