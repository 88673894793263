import { connect } from "react-redux";
import { compose, lifecycle, withHandlers, withState } from "recompose";
import { get, uniq } from "lodash";
import { injectIntl } from "react-intl";
import SearchResults from "./view";
import { getPrizes, setPrizes } from "store/actions/prizes";
const pageSize = 5;
const Enhanced = compose(
  connect(
    (state, props) => {
      const filter = "prizes_list";
      const searchData = get(state, `prizes.prizes.filter_${filter}`, {});
      return {
        filter,
        searchData,
        isDirty: get(searchData, "is_dirty", false),
        hasMore:
          parseInt(get(searchData, "pages", 1)) >
          parseInt(get(searchData, "page", 1)),
        selectedStation: get(
          props.process,
          "data.fields.order_stations_array.value.0"
        ),
        ...props,
      };
    },
    {
      getPrizes,
      setPrizes,
    }
  ),
  withState("filtered", "setFiltered", []),
  withState("typeId", "setTypeId", null),
  withState("sorted", "setSorted", { id: "updated_ts", desc: true }),
  withState("selectedItems", "setSelectedItems", []),
  withState("searchText", "setSearchText", ""),
  withHandlers({
    getParams: ({
      sorted,
      filtered,
      selectedStation,
      typeId,
      searchText,
      filter,
      process,
    }) => (params = {}) => {
      const exclude_ids = get(process, "data.fields.prizes.value", []).map(
        (item) => item.id
      );
      const grand_prizes_ids = get(
        process,
        "data.fields.grand_prizes.value",
        []
      ).map((item) => item.id);
      const theme = get(process, "data.fields.theme.value", "");
      const is54321Theme = theme === "5_4_3_2_1";
      const finalParams = {
        filter,
        rpp: pageSize,
        page: 1,
        sort: `${sorted.id} ${sorted.desc ? "desc" : "asc"}`,
        filtered,
        station: get(selectedStation, "value", ""),
        category: typeId ? typeId : "",
        search: searchText,
        exclude_ids: uniq([...exclude_ids, ...grand_prizes_ids]),
        ...params,
      };
      if (is54321Theme) {
        finalParams.for_5_items_theme = true;
        delete finalParams.exclude_ids;
      }
      if (
        get(process, "data.fields.theme.value", "") === "rollover" &&
        !finalParams.category
      ) {
        finalParams.category = "cash_check";
      }
      return finalParams;
    },
  }),
  withHandlers({
    onSelectPrize: ({
      setPrizes,
      searchData,
      onSelectItem,
      filter,
      isView,
      process,
    }) => (item, itemPrize) => {
      if (isView) return;
      onSelectItem(item, itemPrize);
    },
    getItemsList: ({ getPrizes, getParams }) => (params = {}) => {
      getPrizes(getParams(params));
    },
    getMoreItems: ({ getPrizes, searchData, getParams }) => () => {
      const page = parseInt(get(searchData, "page", 1));
      getPrizes(getParams({ page: page + 1 }));
    },
  }),
  withHandlers({
    onSort: ({ setSorted, sorted, getItemsList }) => (field) => {
      let newSort = {
        id: field,
        desc: !sorted.desc,
      };
      let sortString = `${newSort.id} ${newSort.desc ? "desc" : "asc"}`;
      // detect third click
      if (sorted.id === field && sorted.desc && !newSort.desc) {
        sortString = `updated_ts desc`;
        newSort = {
          id: "updated_ts",
          desc: true,
        };
      }
      setSorted(newSort);
      getItemsList({
        sort: sortString,
      });
    },
    onSearch: ({ getItemsList }) => () => {
      getItemsList({
        page: 1,
      });
    },
  }),
  lifecycle({
    componentDidMount() {
      if (this.props.selectedStation) this.props.getItemsList();
    },
    componentDidUpdate(prevProps) {
      if (
        this.props.isDirty ||
        get(this.props, "selectedStation.key") !==
          get(prevProps, "selectedStation.key")
      ) {
        this.props.getItemsList();
      }
    },
  })
)(SearchResults);
export default injectIntl(Enhanced);
