import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, Button, ModalFooter } from "reactstrap";
import classnames from "classnames";
import bn from "utils/bemnames";
import { isFunction } from "lodash";
import { FormattedMessage } from "react-intl";
const bem = bn.create("reminder-modal");
const ReminderModal = (props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      className={classnames(props.className, bem.b())}
    >
      <ModalHeader toggle={props.onToggle} className={bem.e("header")}>
        {props.title}
        {props.subtitle ? (
          <div className={bem.e("subtitle")}>{props.subtitle}</div>
        ) : null}
      </ModalHeader>
      <ModalFooter className={bem.e("footer")}>
        {props.isShowConfirm && (
          <Button
            color="blue"
            className={classnames("btn-radius", bem.e("confirm-button"))}
            onClick={() => {
              if (isFunction(props.onConfirm)) {
                props.onConfirm();
              } else {
                props.onToggle();
              }
            }}
          >
            {props.confirmTitle}
          </Button>
        )}
        {props.isShowDiscard && (
          <Button
            color="blue"
            className={classnames("btn-radius", bem.e("confirm-button"))}
            onClick={() => {
              if (isFunction(props.onDiscard)) {
                props.onDiscard();
              } else {
                props.onToggle();
              }
            }}
          >
            {props.discardTitle}
          </Button>
        )}
        {props.isShowCancel && (
          <Button
            color="blue"
            outline
            className={classnames("btn-radius", bem.e("cancel-button"))}
            onClick={props.onCancel}
          >
            {props.cancelTitle}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

ReminderModal.propTypes = {
  onToggle: PropTypes.func,
  onDiscard: PropTypes.func,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  className: PropTypes.string,
  subtitle: PropTypes.any,
  isShowCancel: PropTypes.bool,
  isShowDiscard: PropTypes.bool,
};

ReminderModal.defaultProps = {
  onToggle: () => {},
  onDismiss: () => {},
  confirmTitle: (
    <FormattedMessage id="reminder modal > button return to form" />
  ),
  cancelTitle: <FormattedMessage id="reminder modal > button leave" />,
  title: (
    <FormattedMessage id="reminder modal > you have unsaved work. Do you want to return or leave" />
  ),
  discardTitle: <FormattedMessage id="reminder modal > button discard" />,
  subtitle: "",
  isShowCancel: true,
  isShowConfirm: true,
  isShowDiscard: false,
};

export default ReminderModal;
