import { filter, first, get, sortBy } from "lodash";
import { validatePrivileges } from "utils/helpers";
export const getRoleUsersByHubMembers = (item, users) => {
  const validUsers = filter(users, (user) => {
    const isValid = validatePrivileges({
      requires: get(item, "privileges", []),
      privileges: Object.values(user.user_privileges),
      user,
    });
    return isValid;
  }).map((user) => ({
    value: parseInt(user.id),
    label: user.name,
    role: first(user.roles) ? user.roles[0].id : null,
    info: {
      id: user.id,
      name: user.name,
      timezone: user.location,
      out_of_office_settings: user.out_of_office_settings,
      avatar_url: user.avatar_url,
      assigned_tasks_count: user.assigned_tasks_count,
      current_tasks_count: user.current_tasks_count,
      reel: user.reel,
      is_active: user.is_active,
      hubs: user.hubs,
    },
  }));
  return sortBy(validUsers, "label");
};
