import { FormattedMessage } from "react-intl";
import React from "react";

const eventRecap = {
  process_step_index: 5,
  key: "event_recap",
  caption_color: "#CC1EE6",
  title: <FormattedMessage id="process > title event event recap" />,
  description: null,
  step_roles: [1, 2, 3],
  step_viewers_roles: [1, 2, 3, 4, 7],
  step_editors_roles: [1, 2, 3, 4],
  step_viewers_privileges: ["select_promotion_event_recap"],
  step_editors_privileges: ["select_promotion_event_recap"],
  status: "initial",
  disabled_until_completed_steps: [],
  step_container: "none",
  no_edit_button: true,
  fields: [
    {
      component: "team_equipment",
    },
    {
      field: "event_recap",
      title: <FormattedMessage id="process > field prizes" />,
    },
    {
      key: "save_button",
      text: <FormattedMessage id="process > button save" />,
      on_click: {
        validate: true,
        save: true,
      },
      fields: [
        {
          key: "event_recap_status",
        },
        {
          key: "process_step",
          value: "done",
        },
      ],
      confirmation_dialog_props: {
        description: (
          <FormattedMessage id="process > description to submit prizes" />
        ),
        confirmTitle: <FormattedMessage id="process > button yes" />,
        cancelTitle: <FormattedMessage id="process > button no" />,
      },
      success_dialog_props: {
        title: <FormattedMessage id="process > title submitted" />,
        state: "success",
      },
    },
  ],
};
export default eventRecap;
