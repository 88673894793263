import React, { useEffect } from "react";
import { map, get, join } from "lodash";
import classnames from "classnames";
import { TabContent, TabPane, Nav, NavItem, NavLink, Button } from "reactstrap";
import { SectionTitle } from "components/Elements";
import { FormattedTaggedLog } from "../FormattedTaggedMessage";
import bn from "utils/bemnames";
import { FileReportIcon } from "components/CustomIcons";
import { FormattedMessage, injectIntl } from "react-intl";
import moment from "moment";
const bem = bn.create("activity-log");

const View = ({
  logs,
  activeTab,
  onToggleTab,
  onDownload,
  dateFormatByServer,
  ...rest
}) => {
  const { process } = rest;
  const processId = get(process, "data.fields.key.value");
  const stationId = get(
    process,
    "data.fields.order_stations_array.value.0.key"
  );
  const isShowScheduling = get(rest, "step.is_show_scheduling", false);
  const isShowItemsRun = get(rest, "step.is_show_items_run", false);
  const itemsRun = get(rest, "itemsRun", {});
  const scheduleLogs = get(rest, "scheduleLogs", {});
  useEffect(() => {
    document
      .getElementById("cr-app-main")
      .classList.add("cr-page-processes-log");
    return () => {
      document
        .getElementById("cr-app-main")
        .classList.remove("cr-page-processes-log");
    };
  }, []);
  return (
    <div className={bem.b()}>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ "nav-active": activeTab === "log" })}
            onClick={() => {
              onToggleTab("log");
            }}
          >
            <FormattedMessage id="log > activity" />
          </NavLink>
        </NavItem>
        {isShowScheduling ? (
          <NavItem>
            <NavLink
              className={classnames({
                "nav-active": activeTab === "scheduling",
              })}
              onClick={() => {
                onToggleTab("scheduling");
              }}
            >
              <FormattedMessage id="log > scheduling" />
            </NavLink>
          </NavItem>
        ) : null}
        {isShowItemsRun ? (
          <NavItem>
            <NavLink
              className={classnames({
                "nav-active": activeTab === "items",
              })}
              onClick={() => {
                onToggleTab("items");
              }}
            >
              <FormattedMessage id="log > items run" />
            </NavLink>
          </NavItem>
        ) : null}
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="log">
          <SectionTitle className={bem.e("tab-title")}>
            <FormattedMessage id="log > activity log" />
          </SectionTitle>

          <div className={bem.e("activity-log")}>
            {logs ? (
              <ul className={bem.e("list")}>
                {map(logs, (timers, date) => {
                  return (
                    <li key={date} className={bem.e("log-section")}>
                      <h6 className={bem.e("log-date")}>{date}</h6>
                      {map(timers, (value, index) => {
                        return (
                          <p key={index} className={bem.e("log-item")}>
                            <strong className={bem.e("log-time")}>
                              {get(value, "timer", "")}
                            </strong>
                            <span className={bem.e("log-text")}>
                              <FormattedTaggedLog
                                text={get(value, "text", "")}
                                isReturnElement
                              />
                            </span>
                          </p>
                        );
                      })}
                    </li>
                  );
                })}
              </ul>
            ) : null}
          </div>
        </TabPane>
        {isShowScheduling ? (
          <TabPane tabId="scheduling">
            <SectionTitle className={bem.e("tab-title")}>
              <FormattedMessage id="log > scheduling log" />
            </SectionTitle>

            <div className={bem.e("scheduling-log")}>
              <div className={bem.e("scheduling-log-content")}>
                {map(scheduleLogs, (items, date) => {
                  return (
                    <div className={bem.e("schedule-log-date")} key={`${date}`}>
                      <h6 className={bem.e("log-date")}>{date}</h6>
                      <div className={bem.e("schedule-log-items")}>
                        {map(items, (item, index) => {
                          if (item.restored_ts || item.deleted_ts) {
                            const slotDate = moment(
                              item.slot_date,
                              "YYYY-MM-DD"
                            ).format(dateFormatByServer);
                            const slotTime = item.slot_time
                              ? moment(item.slot_time, "HH:ss").format(
                                  "HH:ss a"
                                )
                              : "";
                            return (
                              <div
                                key={index}
                                className={bem.e("schedule-log-item")}
                              >
                                <strong className={bem.e("log-time")}>
                                  {item.timer}
                                </strong>
                                <div className={bem.e("log-text")}>
                                  {get(item, "user_name")}{" "}
                                  {item.deleted_ts
                                    ? rest.intl.formatMessage({
                                        id: "log > scheduling deleted",
                                      })
                                    : rest.intl.formatMessage({
                                        id: "log > scheduling restored",
                                      })}{" "}
                                  <strong>
                                    {slotDate} {slotTime}{" "}
                                    {item.slot_order_title}{" "}
                                  </strong>
                                </div>
                              </div>
                            );
                          }
                          const targettedSlotDate = moment(
                            item.targetted_slot_date,
                            "YYYY-MM-DD"
                          ).format(dateFormatByServer);
                          const targettedSlotTime = item.targetted_slot_time
                            ? moment(item.targetted_slot_time, "HH:ss").format(
                                "HH:ss a"
                              )
                            : "";

                          const targettedOrderTitle = get(
                            item,
                            "targetted_slot_order_title",
                            ""
                          );
                          const destinatedSlotDate = moment(
                            item.destinated_slot_date,
                            "YYYY-MM-DD"
                          ).format(dateFormatByServer);
                          const destinatedSlotTime = item.destinated_slot_time
                            ? moment(item.destinated_slot_time, "HH:ss").format(
                                "HH:ss a"
                              )
                            : "";
                          const destinatedOrderTitle = get(
                            item,
                            "destinated_slot_order_title",
                            ""
                          );
                          const rules = get(
                            item,
                            "overridden_rules",
                            []
                          ).map((rule) =>
                            rule.replace(new RegExp(/_/, "g"), " ")
                          );
                          return (
                            <div
                              key={index}
                              className={bem.e("schedule-log-item")}
                            >
                              <strong className={bem.e("log-time")}>
                                {item.timer}
                              </strong>
                              <div className={bem.e("log-text")}>
                                {get(item, "user_name")}{" "}
                                {rest.intl.formatMessage({
                                  id: "log > scheduling swapped",
                                })}{" "}
                                <strong>
                                  {targettedSlotDate} {targettedSlotTime}{" "}
                                  {targettedOrderTitle}{" "}
                                </strong>
                                {rest.intl.formatMessage({
                                  id: "log > scheduling width",
                                })}{" "}
                                <strong>
                                  {destinatedSlotDate} {destinatedSlotTime}{" "}
                                  {destinatedOrderTitle}
                                </strong>{" "}
                                {rules.length > 0 ? (
                                  <React.Fragment>
                                    {rules.length === 1 ? "overriding a" : ""}{" "}
                                    {join(rules, ", ")}
                                    {rules.length > 1 ? " were overridden" : ""}
                                    .
                                  </React.Fragment>
                                ) : null}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </TabPane>
        ) : null}
        {isShowItemsRun ? (
          <TabPane tabId="items">
            <SectionTitle className={bem.e("tab-title")}>
              <FormattedMessage id="log > scheduling log" />
            </SectionTitle>

            <div
              className={classnames(
                bem.e("scheduling-log"),
                bem.e("scheduling-items-run-log")
              )}
            >
              <div className={bem.e("scheduling-top")}>
                <table className={bem.e("allocated")}>
                  <thead>
                    <tr>
                      <th className={bem.e("allocated-text")}>
                        <FormattedMessage id="log > allocated" />
                      </th>
                      <th>
                        <FormattedMessage id="log > runs" />
                      </th>
                      <th>
                        <FormattedMessage id="log > overrides" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span className={bem.e("total")}>
                          {get(itemsRun, "count.totalAllocated", "")}
                        </span>
                      </td>
                      <td>
                        <span className={bem.e("total-highlight")}>
                          {get(itemsRun, "count.totalRuns", "")}
                        </span>
                      </td>
                      <td>
                        <span className={bem.e("total-highlight")}>
                          {get(itemsRun, "count.totalOverridden", "")}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Button
                  color="blue"
                  outline
                  disabled={!processId || !stationId}
                  className={"btn-radius btn-save-pdf"}
                  onClick={onDownload}
                >
                  <FileReportIcon />
                  <FormattedMessage id="log > save as pdf" />
                </Button>
              </div>
              <div className={bem.e("items-run-log-content")}>
                {map(itemsRun.items_runs, (items, date) => {
                  return (
                    <div className={bem.e("schedule-log-date")} key={`${date}`}>
                      <h6 className={bem.e("log-date")}>
                        {moment(date, "YYYY-MM-DD").format("MMMM DD, YYYY")}
                      </h6>
                      <p className={bem.e("log-item")}>
                        <strong className={bem.e("log-time")}>
                          {get(process, "data.fields.order_title.value", "")}{" "}
                          {rest.intl.formatMessage({
                            id: "log > ran on these scheduled times",
                          })}
                          :
                        </strong>
                        <span className={bem.e("log-text")}>
                          {join(items, ", ")}
                        </span>
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </TabPane>
        ) : null}
      </TabContent>
    </div>
  );
};
export default injectIntl(View);
