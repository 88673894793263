import View from "./view";
import { compose, withState, withHandlers } from "recompose";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { get, find, uniqBy } from "lodash";
import { saveFormValues } from "store/actions/processes";
const createMessage = (text, process, template, step, user) => {
	let message = {
		from: {
			id: user.id,
			name: user.name,
			avatar_url: user.avatar_url
		},
		tagged: null,
		order_stations: process.data.fields.order_stations
			? process.data.fields.order_stations.value
			: [],
		chat: text,
		template: template.key,
		process: process.key,
		order_title: process.data.fields.order_title
			? process.data.fields.order_title.value
			: "untitled",
		from_step: step ? step.key : "",
		team: process.data.fields.team ? process.data.fields.team.value : [],
		still_required_users: process.data.fields.still_required_users
			? process.data.fields.still_required_users.value
			: [],
		next_users: process.data.fields.next_users
			? process.data.fields.next_users.value
			: []
	};
	return message;
};

export default injectIntl(
	compose(
		connect((state, props) => ({ ...props }), {
			saveFormValues
		}),
		withState("isOpenComment", "setIsOpenComment", false),
		withState("isLoading", "setIsLoading", false),
		withHandlers({
			submitChanged: ({
				process,
				buttonClicked,
				step,
				template,
				user,
				setIsOpenComment
			}) => key => {
				const actionOrder = step.fields.find(f => f.field === key);
				buttonClicked(
					step,
					actionOrder,
					process,
					template,
					user,
					null,
					() => {
						setIsOpenComment(false);
					},
					false
				);
			},
			getApprovers: ({ process }) => () => {
				const marketingChannels = get(
					process,
					"data.fields.marketing_channels.value",
					[]
				);
				let approvals = [];
				marketingChannels.forEach(channel => {
					const { workflow } = channel;
					const workflowStepOrderDetail = workflow ? find(
						workflow.steps,
						step => step.id === "order_details"
					) : {};
					if (
						workflowStepOrderDetail &&
						workflowStepOrderDetail.approvals
					) {
						approvals = [
							...approvals,
							...workflowStepOrderDetail.approvals
						];
					}
				});
				let approvers = [];
				approvals.forEach(item => {
					approvers = [
						...approvers,
						...item.users.map(item => ({
							...item,
							id: Number(item.id)
						}))
					];
				});
				approvers = uniqBy(approvers, "id");
				return approvers;
			}
		}),
		withHandlers({
			onSubmitComment: ({
				process,
				template,
				step,
				user,
				saveFormValues,
				setIsLoading
			}) => (value, cb) => {
				setIsLoading(true);
				const chat_message = createMessage(
					value,
					process,
					template,
					step,
					user
				);
				if (process) {
					var success_callback = cb;
					saveFormValues({
						process,
						template,
						step,
						user,
						success_callback,
						valid: false,
						chat_message
					});
				}
			},

			onApprove: ({
				process,
				valueChanged,
				template,
				user,
				step,
				submitChanged,
				getApprovers
			}) => () => {
				const allApprovers = getApprovers();
				const allApprovalsField = step.fields.find(
					f => f.field === "all_approvals"
				);
				const completedApprovals = step.fields.find(
					f => f.field === "completed_approvals"
				);
				const rejectedApprovals = step.fields.find(
					f => f.field === "rejected_approvals"
				);
				const completedApprovalsValue = [...get(
					process,
					"data.fields.completed_approvals.value",
					[]
				)];				
				const rejectedApprovalsValue = [...get(
					process,
					"data.fields.rejected_approvals.value",
					[]
				)];
				if(!completedApprovalsValue.find(e=>e.id === user.id)) {
					completedApprovalsValue.push({name: user.name,id: user.id})
				}

				valueChanged(allApprovalsField, process, template, user, {
					value: allApprovers
				});
				valueChanged(completedApprovals, process, template, user, {
					value: completedApprovalsValue
				});
				valueChanged(rejectedApprovals, process, template, user, {
					value: rejectedApprovalsValue.filter(
						item => item.id !== user.id
					)
				});
				setTimeout(() => submitChanged("approve"), 100);
			},
			onReject: ({
				process,
				valueChanged,
				template,
				user,
				step,
				submitChanged
			}) => () => {
				const completedApprovals = step.fields.find(
					f => f.field === "completed_approvals"
				);
				const rejectedApprovals = step.fields.find(
					f => f.field === "rejected_approvals"
				);
				const completedApprovalsValue = [...get(
					process,
					"data.fields.completed_approvals.value",
					[]
				)];
				const rejectedApprovalsValue = [...get(
					process,
					"data.fields.rejected_approvals.value",
					[]
				)];

				if(!rejectedApprovalsValue.find(e=>e.id === user.id)) rejectedApprovalsValue.push({name: user.name,id: user.id})

				valueChanged(completedApprovals, process, template, user, {
					value: completedApprovalsValue.filter(
						item => item.id !== user.id
					)
				});
				valueChanged(rejectedApprovals, process, template, user, {
					value: rejectedApprovalsValue
				});
				setTimeout(() => submitChanged("reject_order"), 100);
			}
		})
	)(View)
);
