import queryString from "query-string";
import * as Const from "utils/constants";
import * as Types from "store/types/settings";
import Api from "utils/api";
import { isFunction, get } from "lodash";
import { userInfo } from "store/actions/auth";

export const getProductionSettings = (params = {}, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({
      type: Types.GET_PRODUCTION_SETTINGS_REQUEST,
    });
    let response = await Api.doCall(
      `${Const.GET_PRODUCTION_SETTINGS()}?${queryString.stringify(params)}`,
      "GET"
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.GET_PRODUCTION_SETTINGS_SUCCESS,
          payload: response.data,
        });
        if (cb) cb(response.data);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.GET_PRODUCTION_SETTINGS_ERROR,
    payload: { error: errorStr },
  });
};

export const updateProductionSettings = (params, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({
      type: Types.UPDATE_PRODUCTION_SETTINGS_REQUEST,
    });
    let response = await Api.doCall(
      Const.GET_PRODUCTION_SETTINGS(),
      "POST",
      params
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.UPDATE_PRODUCTION_SETTINGS_SUCCESS,
          payload: response.data,
        });
        dispatch(userInfo());
        if (isFunction(cb)) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.UPDATE_PRODUCTION_SETTINGS_ERROR,
    payload: { error: errorStr },
  });
};

export const getEnterpriseSettings = (params = {}, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({
      type: Types.GET_ENTERPRISE_SETTINGS_REQUEST,
    });
    let response = await Api.doCall(
      `${Const.GET_ENTERPRISE_SETTINGS()}?${queryString.stringify(params)}`,
      "GET"
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.GET_ENTERPRISE_SETTINGS_SUCCESS,
          payload: response.data,
        });
        if (cb) cb(response.data);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.GET_ENTERPRISE_SETTINGS_ERROR,
    payload: { error: errorStr },
  });
};

export const updateEnterpriseSettings = (params, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({
      type: Types.UPDATE_ENTERPRISE_SETTINGS_REQUEST,
    });
    let response = await Api.doCall(
      Const.GET_ENTERPRISE_SETTINGS(),
      "POST",
      params
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.UPDATE_ENTERPRISE_SETTINGS_SUCCESS,
          payload: get(response, "data.data", {}),
        });
        if (isFunction(cb)) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.UPDATE_ENTERPRISE_SETTINGS_ERROR,
    payload: { error: errorStr },
  });
};
