import StationsList from "./view";
import { get, debounce, map } from "lodash";
import { connect } from "react-redux";
import {
  compose,
  lifecycle,
  withState,
  withHandlers,
  withProps,
  withPropsOnChange,
} from "recompose";
import {
  getStations,
  getMoreStations,
  deleteStation,
} from "store/actions/stations";
import { injectIntl } from "react-intl";
import { pageSize } from "utils/config";
export default injectIntl(
  compose(
    connect(
      (state, ownProps) => ({
        isLazyLoading: get(state, "stations.lazyLoad", false),
        stations: get(state, "stations.stations"),
        privileges: get(state, "auth.user.privileges") || [],
        auth: state.auth,
      }),
      {
        getStations,
        getMoreStations,
        deleteStation,
      }
    ),
    withState("searchText", "setSearchText", ""),
    withState("filtered", "setFiltered", []),
    withState("searchTags", "setSearchTags", []),
    withState("filteredTags", "setFilteredTags", []),
    withState("sorted", "setSorted", [{ id: "market", desc: false }]),
    withState("isStationModalOpen", "setIsStationModalOpen", false),
    withState("isDeleteModalOpen", "setIsDeleteModalOpen", false),
    withState("deleteStationId", "setDeleteStationId", null),
    withState("selectedRowsId", "setSelectedRowsId", {}),
    withProps((props) => ({
      getStationDelayed: debounce(() => {
        const { pageSize = 10, page, sorted, filtered, filteredTags } = props;
        props.getStations({
          pageSize,
          page,
          sorted,
          filtered,
          filteredTags,
        });
      }, 200),
      selectedStationsChanged: props.selectedStationsChanged,
    })),
    withHandlers({
      openDeleteModal: ({ setIsDeleteModalOpen, setDeleteStationId }) => (
        id
      ) => {
        setIsDeleteModalOpen(true);
        setDeleteStationId(id);
      },
      closeDeleteModal: ({
        setIsDeleteModalOpen,
        setDeleteStationId,
      }) => () => {
        setIsDeleteModalOpen(false);
        setDeleteStationId(null);
      },
      onToggleStationModal: ({
        isStationModalOpen,
        setIsStationModalOpen,
      }) => () => {
        setIsStationModalOpen(!isStationModalOpen);
      },
      getStationList: ({
        getStations,
        sorted,
        filtered,
        filteredTags,
      }) => async () => {
        getStations({
          pageSize,
          page: 0,
          sorted,
          filtered,
          filteredTags,
        });
      },
      getMoreStationsList: ({
        getMoreStations,
        sorted,
        filtered,
        filteredTags,
      }) => async (page) => {
        getMoreStations({
          pageSize,
          page,
          sorted,
          filtered,
          filteredTags,
        });
      },
    }),
    withHandlers({
      onLoadMore: ({ getMoreStationsList, stations }) => () => {
        getMoreStationsList(get(stations, "data.current_page", 1));
      },
    }),
    withHandlers({
      onDelete: ({
        closeDeleteModal,
        deleteStationId,
        deleteStation,
      }) => () => {
        deleteStation(deleteStationId);
        closeDeleteModal();
      },
      onSearchKeyDown: ({ getStationList }) => (e) => {
        if (e.keyCode === 13) {
          getStationList();
        }
      },
      onSearchClicked: ({ getStationList }) => () => {
        getStationList();
      },
      onChangeInput: ({ filtered, setFiltered, setSearchText }) => ({
        target,
      }) => {
        const value = target.value;
        const name = target.name;
        const filterSearch = {
          id: name,
          value,
        };
        const index = filtered.findIndex((item) => item.id === name);
        if (index === -1) {
          filtered.push(filterSearch);
        } else {
          filtered[index] = filterSearch;
        }
        setSearchText(value);
        setFiltered(filtered);
      },
      onChangeTags: (props) => (target) => {
        let { filteredTags, setFilteredTags, setSearchTags } = props;
        filteredTags = target;
        setSearchTags(filteredTags);
        setFilteredTags(map(filteredTags, (item) => item.value));
      },
    }),
    withHandlers({
      onClearSearch: ({ onChangeInput, onSearchClicked }) => (e) => {
        e.preventDefault();
        onChangeInput({
          target: {
            name: "q",
            value: "",
          },
        });
        onSearchClicked();
      },
    }),
    withPropsOnChange(
      ["pageSize", "page", "sorted", "filtered", "filteredTags"],
      debounce(
        ({ getStationList, sorted, filtered, isFocused, filteredTags }) => {
          if (
            sorted.length > 0 ||
            filtered.length > 0 ||
            pageSize ||
            filteredTags.length > 0
          ) {
            if (isFocused) {
              getStationList();
            }
          }
        },
        500
      )
    ),
    lifecycle({
      // Disabled for duplicate call api. Because we called the get stations list api from line 163
      componentDidMount() {
        if (get(this.props, "isFocused")) {
          this.props.setSearchText("");
          this.props.setSorted([{ id: "market", desc: false }]);
          this.props.setFiltered([]);
          this.props.setFilteredTags([]);
          // this.props.getStationList({
          //   pageSize,
          //   page: 0,
          //   sorted: [],
          //   filtered: [],
          //   filteredTags: [],
          // });
        }
      },
      componentWillReceiveProps(nextProps, nextState) {
        // only call when focused to this tab
        if (
          get(this.props, "isFocused") !== get(nextProps, "isFocused") &&
          get(nextProps, "isFocused")
        ) {
          this.props.getStationList();
        }
        if (get(nextProps, "stations.is_dirty") && nextProps.isFocused) {
          this.props.getStationList();
        }
      },
    })
  )(StationsList)
);
