export const UPDATE_SYSTEM_TEMPLATES_REQUEST =
  "UPDATE_SYSTEM_TEMPLATES_REQUEST";
export const UPDATE_SYSTEM_TEMPLATES_SUCCESS =
  "UPDATE_SYSTEM_TEMPLATES_SUCCESS";
export const UPDATE_SYSTEM_TEMPLATES_ERROR = "UPDATE_SYSTEM_TEMPLATES_ERROR";

export const FETCH_SYSTEM_TEMPLATES_REQUEST = "FETCH_SYSTEM_TEMPLATES_REQUEST";
export const FETCH_SYSTEM_TEMPLATES_SUCCESS = "FETCH_SYSTEM_TEMPLATES_SUCCESS";
export const FETCH_SYSTEM_TEMPLATES_ERROR = "FETCH_SYSTEM_TEMPLATES_ERROR";

export const FETCH_WINNER_ANNOUNCEMENT_REQUEST =
  "FETCH_WINNER_ANNOUNCEMENT_REQUEST";
export const FETCH_WINNER_ANNOUNCEMENT_SUCCESS =
  "FETCH_WINNER_ANNOUNCEMENT_SUCCESS";
export const FETCH_WINNER_ANNOUNCEMENT_ERROR =
  "FETCH_WINNER_ANNOUNCEMENT_ERROR";

export const FETCH_FORM_CONFIRMATION_REQUEST =
  "FETCH_FORM_CONFIRMATION_REQUEST";
export const FETCH_FORM_CONFIRMATION_SUCCESS =
  "FETCH_FORM_CONFIRMATION_SUCCESS";
export const FETCH_FORM_CONFIRMATION_ERROR = "FETCH_FORM_CONFIRMATION_ERROR";

export const FETCH_PRIZE_RELEASE_TEMPLATE_REQUEST =
  "FETCH_PRIZE_RELEASE_TEMPLATE_REQUEST";
export const FETCH_PRIZE_RELEASE_TEMPLATE_SUCCESS =
  "FETCH_PRIZE_RELEASE_TEMPLATE_SUCCESS";
export const FETCH_PRIZE_RELEASE_TEMPLATE_ERROR =
  "FETCH_PRIZE_RELEASE_TEMPLATE_ERROR";

export const FETCH_PRIZE_FULFILLMENT_TEMPLATE_REQUEST =
  "FETCH_PRIZE_FULFILLMENT_TEMPLATE_REQUEST";
export const FETCH_PRIZE_FULFILLMENT_TEMPLATE_SUCCESS =
  "FETCH_PRIZE_FULFILLMENT_TEMPLATE_SUCCESS";
export const FETCH_PRIZE_FULFILLMENT_TEMPLATE_ERROR =
  "FETCH_PRIZE_FULFILLMENT_TEMPLATE_ERROR";

export const FORGET_SYSTEM_TEMPLATES_RELATED_FORMS =
  "FORGET_SYSTEM_TEMPLATES_RELATED_FORMS";

export const FETCH_EMAIL_TEMPLATES_REQUEST = "FETCH_EMAIL_TEMPLATES_REQUEST";
export const FETCH_EMAIL_TEMPLATES_SUCCESS = "FETCH_EMAIL_TEMPLATES_SUCCESS";
export const FETCH_EMAIL_TEMPLATES_ERROR = "FETCH_EMAIL_TEMPLATES_ERROR";

export const CHECK_DUPLICATE_TEMPLATE_REQUEST =
  "CHECK_DUPLICATE_TEMPLATE_REQUEST";
export const CHECK_DUPLICATE_TEMPLATE_SUCCESS =
  "CHECK_DUPLICATE_TEMPLATE_SUCCESS";
export const CHECK_DUPLICATE_TEMPLATE_ERROR = "CHECK_DUPLICATE_TEMPLATE_ERROR";
