import View from "./view";
import { injectIntl } from "react-intl";
import { compose, withState } from "recompose";
export default injectIntl(
	compose(
		withState(
			"isOpenPreviewAssetModal",
			"setIsOpenPreviewAssetModal",
			false
		),
		withState("currentAsset", "setCurrentAsset", null)
	)(View)
);
