import BulkUploadForm from "./view";
import { get, isFunction } from "lodash";
import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";
import { bulkUpload } from "store/actions/users";
import { injectIntl } from "react-intl";
export default injectIntl(
  compose(
    connect(
      (state, props) => {
        return {
          isLoading: get(state, "users.bulkUpload.loading", false)
        };
      },
      { bulkUpload }
    ),
    withHandlers({
      onFormSubmit: ({ onBack, bulkUpload }) => values => {
        bulkUpload(values, () => {
          if (isFunction(onBack)) onBack();
        });
      }
    })
  )(BulkUploadForm)
);
