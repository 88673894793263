import { compose, withState, withPropsOnChange, lifecycle } from "recompose";
import { get } from "lodash";
import Player from "./view";
export default compose(
    withState('playedSeconds', 'setPlayedSeconds', 0),
    withState('duration', 'setDuration', 0),
    withState('playing', 'setPlaying', false),
    withState('volume', 'setVolume', 1),
    withState('urlPlayer', 'setUrlPlayer', null),
    withPropsOnChange(['playing'], ({playing, setUrlPlayer, url}) => {
        if(playing){
            setUrlPlayer(url);
        }
    }),
    lifecycle({
        componentDidMount(){
            const parseUrl = get(this.props, "url", "").split("||");
            if(parseUrl.length === 3){
                this.props.setDuration(Number(parseUrl[1]));
            }else{
                this.props.setDuration(Number(get(this.props, "process.data.fields.order_length.value", 30)))
            }
        },
        componentWillUpdate(nextProps){
            if(this.props.url !== nextProps.url){
                const parseUrl = get(nextProps, "url", "").split("||");
                if(parseUrl.length === 3){
                    this.props.setDuration(Number(parseUrl[1]));
                }else{
                    this.props.setDuration(Number(get(nextProps, "process.data.fields.order_length.value", 30)))
                }
            }
        }
    })
)(Player);