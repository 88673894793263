import { connect } from "react-redux";
import { get } from "lodash";
import Winner from "./view";
import { injectIntl } from "react-intl";
import { getWinner } from "store/actions/winners";

const Enhanced = connect(
  (state, props) => {
    return {
      winner: get(state, "winners.winner.data", {}),
      ...props,
    };
  },
  { getWinner }
)(Winner);
export default injectIntl(Enhanced);
