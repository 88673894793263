import PrivilegesEffective from "./view";
import { injectIntl } from "react-intl";
import { get, filter, includes, findIndex } from "lodash";
import { connect } from "react-redux";
import { compose, withState, withHandlers } from "recompose";

export default injectIntl(
  compose(
    connect(
      state => {
        return {
          allPrivileges: get(state, "auth.info.privileges", [])
        };
      },
      {}
    ),
    withState(
      "privileges",
      "setPrivileges",
      ({ allPrivileges }) => allPrivileges
    ),
    withState("selectedPrivileges", "setSelectedPrivileges", []),
    withState("privilegesAdd", "setPrivilegesAdd", []),
    withState("privilegesRemove", "setPrivilegesRemove", []),
    withHandlers({
      onSelectItem: ({
        selectedPrivileges,
        setSelectedPrivileges
      }) => current => {
        const exists = findIndex(
          selectedPrivileges,
          privilege => privilege === current
        );
        // add
        if (exists === -1) {
          setSelectedPrivileges([current, ...selectedPrivileges]);
        } else {
          // remove
          selectedPrivileges.splice(exists, 1);
          setSelectedPrivileges(selectedPrivileges);
        }
      },
      onMoveToAddList: ({
        selectedPrivileges,
        privileges,
        setPrivileges,
        privilegesAdd,
        setPrivilegesAdd,
        setSelectedPrivileges
      }) => () => {
        // remove selected privileges from left options
        const newPrivileges = filter(
          privileges,
          privilege => !includes(selectedPrivileges, privilege)
        );
        setPrivileges(newPrivileges);
        setSelectedPrivileges([]);
        setPrivilegesAdd([...selectedPrivileges, ...privilegesAdd]);
      },
      onMoveToRemoveList: ({
        selectedPrivileges,
        privileges,
        setPrivileges,
        privilegesRemove,
        setPrivilegesRemove,
        setSelectedPrivileges
      }) => () => {
        // remove selected privileges from left options
        const newPrivileges = filter(
          privileges,
          privilege => !includes(selectedPrivileges, privilege)
        );
        setPrivileges(newPrivileges);
        setSelectedPrivileges([]);
        setPrivilegesRemove([...selectedPrivileges, ...privilegesRemove]);
      },
      onRemoveItemFromListAdd: ({
        privileges,
        setPrivileges,
        privilegesAdd,
        setPrivilegesAdd
      }) => item => {
        setPrivileges([item, ...privileges]);
        const newPrivilegesAdd = filter(
          privilegesAdd,
          privilege => privilege !== item
        );
        setPrivilegesAdd(newPrivilegesAdd);
      },
      onRemoveItemFromListRemove: ({
        privileges,
        setPrivileges,
        privilegesRemove,
        setPrivilegesRemove
      }) => item => {
        setPrivileges([item, ...privileges]);
        const newPrivilegesRemove = filter(
          privilegesRemove,
          privilege => privilege !== item
        );
        setPrivilegesRemove(newPrivilegesRemove);
      },
      onSave: ({ privilegesAdd, privilegesRemove, onSubmit }) => () => {
        onSubmit(privilegesAdd, privilegesRemove);
      }
    })
  )(PrivilegesEffective)
);
