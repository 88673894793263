import UserTableManual from "./view";
import { get } from "lodash";
import { connect } from "react-redux";
import { compose, lifecycle, withState, withHandlers } from "recompose";
import { injectIntl } from "react-intl";
import { pageSize } from "utils/config";
import {
  getHubAssignedUsers,
  updateOrderAssignments,
  getHubUsers,
} from "store/actions/hubs";
const defaultSorted = [
  {
    id: "name",
    desc: false,
  },
];
export default injectIntl(
  compose(
    connect(
      (state) => ({
        assignedUsers: get(state, "hubs.assignedUsers.data.members", []),
        assignmentsCount: get(
          state,
          "hubs.assignedUsers.data.assignments_count",
          {}
        ),
        isDirty: get(state, "hubs.assignedUsers.is_dirty"),
      }),
      {
        getHubAssignedUsers,
        updateOrderAssignments,
        getHubUsers,
      }
    ),
    withState("isOpenSuccessModal", "setIsOpenSuccessModal", false),
    withState("sidebarType", "setSidebarType", ""),
    withState("sorted", "setSorted", defaultSorted),
    withState("isOpenSidebar", "setIsOpenSidebar", false),
    withHandlers({
      onGetHubAssignedUsers: ({
        getHubAssignedUsers,
        sorted,
        selectedHub,
      }) => async (params = {}) => {
        getHubAssignedUsers({
          id: selectedHub.id,
          pageSize,
          page: 0,
          sorted,
          ...params,
        });
      },
      onUpdateOrderAssignments: ({
        updateOrderAssignments,
        setIsOpenSuccessModal,
      }) => async (values = {}) => {
        updateOrderAssignments(values, () => {
          setIsOpenSuccessModal(true);
        });
      },
    }),
    lifecycle({
      componentDidMount() {
        this.props.setSorted(defaultSorted);
        this.props.getHubAssignedUsers({
          id: this.props.selectedHub.id,
          pageSize,
          page: 0,
          sorted: defaultSorted,
        });
        this.props.getHubUsers({
          hub_id: this.props.selectedHub.id,
        });
      },
      componentDidUpdate(nextProps) {
        if (
          this.props.selectedHub.id !== nextProps.selectedHub.id ||
          this.props.isDirty
        ) {
          this.props.setSorted(defaultSorted);
          this.props.getHubAssignedUsers({
            id: this.props.selectedHub.id,
            pageSize,
            page: 0,
            sorted: defaultSorted,
          });
          this.props.getHubUsers({
            hub_id: this.props.selectedHub.id,
          });
        }
      },
    })
  )(UserTableManual)
);
