import React, { useState } from "react";
import classnames from "classnames";
import { MdStrikethroughS } from "react-icons/lib/md";
import { get, map, isFunction, toLower, last } from "lodash";
import Dropdown from "components/Dropdown";
import {
  BoldIcon,
  ItalicIcon,
  UnderlineIcon,
  ALargeIcon,
  ASmallIcon,
  DropletsIcon,
} from "components/CustomIcons";
import { CompositeDecorator, EditorState } from "draft-js";
import { styles } from "./index";
import { FormattedMessage } from "react-intl";
import EditorUpload from "./Upload";
import Hyperlink from "./Hyperlink";
import RemoveHyperlink from "./RemoveHyperlink";
import InsertButton from "./InsertButton";
import EditHyperlink from "./EditHyperlink";
export const DEFAULT_FONT_SIZE = 16;
export const DEFAULT_STEP_FONT_SIZE = 1;
export const fontFamilyOptions = [
  {
    label: "Serif",
    value: `serif`,
  },
  {
    label: "Sans Serif",
    value: `sans-serif`,
    is_default: true,
  },
];
const TAG_STYLE = {
  display: "inline-block",
  backgroundColor: "#829FB1",
  padding: "3px 5px",
  borderRadius: "4px",
  border: "1px solid #657894",
  fontSize: "11px",
  color: "rgb(255, 255, 255)",
};
const getButtonStyle = ({ color }) => {
  let style = {
    display: "inline-block",
    borderRadius: "24px",
    padding: "13px 30px",
    fontSize: "14px",
    color: color === "#FFFFFF" ? "#795afa" : "#fff",
    backgroundColor: color,
    textTransform: "uppercase",
    cursor: "pointer",
    textDecoration: "none",
  };
  if (color === "#FFFFFF") {
    style = {
      ...style,
      border: "1px solid #acc4d4",
    };
  }
  return style;
};
const StyleIcon = ({ style, active, onToggle }) => {
  const defaultFont = fontFamilyOptions.find((i) => i.is_default);
  const [selectedFontFamily, setSelectedFontFamily] = useState(defaultFont);
  let className = "RichEditor-styleButton";
  let defaultClass = "RichEditor-styleButton";
  if (active) {
    className += " RichEditor-activeButton";
  }
  const onMouseDown = (e) => {
    e.preventDefault();
    onToggle(style);
  };
  const size = 24;
  switch (style) {
    case "FONT_FAMILY":
      return (
        <Dropdown
          className={"RichEditor-styleDropdownFonts"}
          value={selectedFontFamily}
          options={fontFamilyOptions}
          placeholder="Font"
          defaultMenuIsOpen={false}
          onChange={(option) => {
            setSelectedFontFamily(option);
            onToggle("FONT_FAMILY", get(option, "value", defaultFont.value));
          }}
        />
      );
    case "BOLD":
      return (
        <span className={className} onMouseDown={onMouseDown}>
          <BoldIcon />
        </span>
      );
    case "ITALIC":
      return (
        <span className={className} onMouseDown={onMouseDown}>
          <ItalicIcon />
        </span>
      );
    case "UNDERLINE":
      return (
        <span className={className} onMouseDown={onMouseDown}>
          <UnderlineIcon />
        </span>
      );
    case "INCREMENT_FONTSIZE":
      return (
        <span
          className={classnames(defaultClass, "RichEditor-increment-icon")}
          onMouseDown={onMouseDown}
        >
          <ALargeIcon />
        </span>
      );
    case "DECREMENT_FONTSIZE":
      return (
        <span
          className={classnames(defaultClass, "RichEditor-decrement-icon")}
          onMouseDown={onMouseDown}
        >
          <ASmallIcon />
        </span>
      );
    case "red":
      return (
        <span className={className} onMouseDown={onMouseDown}>
          <DropletsIcon />
        </span>
      );
    default:
      break;
  }
  return (
    <MdStrikethroughS
      size={size}
      className={className}
      onMouseDown={onMouseDown}
    />
  );
};
// Custom overrides for "code" style.
export const styleMap = {
  CODE: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
  red: {
    color: "red",
  },
};

export const getBlockStyle = (block) => {
  switch (block.getType()) {
    case "blockquote":
      return "RichEditor-blockquote";
    default:
      return null;
  }
};

const INLINE_STYLES = [
  { style: "FONT_FAMILY" },
  { style: "BOLD" },
  { style: "ITALIC" },
  { style: "UNDERLINE" },
  // { style: "INCREMENT_FONTSIZE" },
  // { style: "DECREMENT_FONTSIZE" },
  { style: "red" },
];

export const InlineStyleControls = ({
  editorState,
  onToggle,
  tags,
  tagsIsModal,
  addTag,
  refTagModal,
  onSetAsDefault,
  isShowUpload,
  addImages,
  isShowHyperlink,
  addLink,
  removeLink,
  addButton,
  isShowInsertButton,
  // isOpenHyperlink,
  // setIsOpenHyperlink,
  // currentHyperlink,
  // setCurrentHyperlink,
  setCurrentEntityKey,
}) => {
  let currentStyle = editorState.getCurrentInlineStyle();
  return (
    <div className="RichEditor-controls RichEditor-inline-controls">
      {INLINE_STYLES.map((type, i) => (
        <StyleIcon
          key={i}
          active={
            currentStyle.has(type.style) ||
            styles.color.current(editorState) === type.style
          }
          onToggle={onToggle}
          style={type.style}
        />
      ))}
      {isShowHyperlink ? (
        <Hyperlink onSave={addLink} setCurrentEntityKey={setCurrentEntityKey} />
      ) : null}
      {isShowHyperlink ? <RemoveHyperlink onClick={removeLink} /> : null}
      {isShowUpload ? <EditorUpload onSave={addImages} /> : null}
      {isShowInsertButton ? <InsertButton onClick={addButton} /> : null}
      {!tagsIsModal && tags && tags.length > 0 && (
        <span className="RichEditor-tags">
          <span className="RichEditor-tags-label">
            <FormattedMessage id="textarea evaluator > merge tags" />
          </span>
          {map(tags, (item, index) => (
            <span
              key={index}
              onClick={() => addTag(item.label, item.replaceWith)}
              className="RichEditor-tags-text"
            >
              {item.label}
            </span>
          ))}
        </span>
      )}

      {tagsIsModal && (
        <span
          className="RichEditor-tags-modal"
          onClick={() => {
            refTagModal.current.toggle();
          }}
        >
          + <FormattedMessage id="textarea evaluator > merge tags" />
        </span>
      )}
      {onSetAsDefault && isFunction(onSetAsDefault) && (
        <span className="RichEditor-set-as-default" onClick={onSetAsDefault}>
          <FormattedMessage id="textarea evaluator > set as default" />
        </span>
      )}
    </div>
  );
};

export const findTagEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    if (entityKey === null) {
      return false;
    }
    return contentState.getEntity(entityKey).getType() === "TAG";
  }, callback);
};

export const Tag = (props) => {
  const { tag, replaceWith } = props.contentState
    .getEntity(props.entityKey)
    .getData();
  const attrs = {
    "data-tag-replace-with": replaceWith,
    "data-tag-label": tag,
    className: "tag",
    style: TAG_STYLE,
  };
  return <span {...attrs}>{props.children}</span>;
};

export const ImageComponent = (props) => {
  const { path, name, type } = props.contentState
    .getEntity(props.entityKey)
    .getData();
  const attrs = {
    "data-image-path": path,
    "data-image-name": name,
    "data-image-type": type,
    className: "image",
  };
  return <span {...attrs}>{props.children}</span>;
};

export const findImageEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    if (entityKey === null) {
      return false;
    }
    return contentState.getEntity(entityKey).getType() === "IMAGE";
  }, callback);
};
function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
}

const Link = (props) => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();

  return (
    <a
      href={url}
      style={styles.link}
      onClick={(e) => {
        if (props.onClick) {
          e.preventDefault();
          e.stopPropagation();
          const urlValue =
            url.indexOf("mailto") !== -1
              ? last(url.split("mailto:"))
              : url.indexOf("tel") !== -1
              ? last(url.split("tel:"))
              : url;
          props.onClick({ url: urlValue, entityKey: props.entityKey });
        }
      }}
    >
      {props.children}
    </a>
  );
};

export const findButtonEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    if (entityKey === null) {
      return false;
    }
    return contentState.getEntity(entityKey).getType() === "BUTTON";
  }, callback);
};

export const Button = (props) => {
  const { color, text, url, linkToForm, id } = props.contentState
    .getEntity(props.entityKey)
    .getData();
  const attrs = {
    "data-text": text,
    "data-color": color,
    "data-link-to-form": linkToForm,
    "data-id": id,
    href: url,
    className: "button",
    style: getButtonStyle({ color }),
  };
  return (
    <a
      {...attrs}
      onClick={(e) => {
        if (props.onClick) {
          e.preventDefault();
          e.stopPropagation();
          props.onClick({
            id,
            color,
            text,
            url,
            link_to_form: linkToForm,
          });
        }
      }}
    >
      {props.children}
    </a>
  );
};

export const decorator = (params = {}) => {
  return new CompositeDecorator([
    {
      strategy: findTagEntities, // essentially find entity.getType() === 'TAG'
      component: Tag,
    },
    {
      strategy: findImageEntities, // essentially find entity.getType() === 'IMAGE'
      component: ImageComponent,
    },
    {
      strategy: findLinkEntities, // essentially find entity.getType() === 'LINK'
      component: (props) => (
        <EditHyperlink
          draftProps={props}
          onSave={params.onAddOrEditLink}
          setCurrentEntityKey={params.setCurrentEntityKey}
          setIsEditorReadonly={params.setIsEditorReadonly}
        />
      ),
    },
    {
      strategy: findButtonEntities, // essentially find entity.getType() === 'BUTTON'
      component: (props) => (
        <Button
          {...props}
          onClick={(data) => {
            if (params && params.setCurrentInsertButton)
              params.setCurrentInsertButton(data);
          }}
        />
      ),
    },
  ]);
};

export const importHTMLOptions = {
  customInlineFn: (element, { Entity, Style }) => {
    if (
      element.style.color &&
      element.className !== "tag" &&
      element.className !== "image" &&
      element.className !== "button"
    ) {
      return Style("CUSTOM_COLOR_" + element.style.color);
    }
    if (element.className === "tag") {
      const tagLabel = element.getAttribute("data-tag-label");
      const replaceWith = element.getAttribute("data-tag-replace-with");
      return Entity("TAG", { tag: tagLabel, replaceWith }, "IMMUTABLE");
    }
    if (element.className === "image") {
      const imagePath = element.getAttribute("data-image-path");
      const imageName = element.getAttribute("data-image-name");
      const imageType = element.getAttribute("data-image-type");
      return Entity(
        "IMAGE",
        { path: imagePath, name: imageName, type: imageType },
        "IMMUTABLE"
      );
    }
    if (element.className === "button") {
      const text = element.getAttribute("data-text");
      const color = element.getAttribute("data-color");
      const url = element.getAttribute("href");
      const linkToForm = element.getAttribute("data-link-to-form");
      const id = element.getAttribute("data-id");
      return Entity(
        "BUTTON",
        { text, color, linkToForm, url, id },
        "IMMUTABLE"
      );
    }
    if (element.style.fontSize) {
      return Style("CUSTOM_FONT_SIZE_" + element.style.fontSize);
    }
    if (element.style.fontFamily) {
      return Style("CUSTOM_FONT_FAMILY_" + element.style.fontFamily);
    }
  },
};

export const exportHTMLOptions = {
  inlineStyleFn: (styles) => {
    let keyColor = "CUSTOM_COLOR_";
    let keyFontSize = "CUSTOM_FONT_SIZE_";
    let keyFontFamily = "CUSTOM_FONT_FAMILY_";
    let color = styles.filter((value) => value.startsWith(keyColor)).first();
    let fontSize = styles
      .filter((value) => value.startsWith(keyFontSize))
      .first();
    let fontFamily = styles
      .filter((value) => value.startsWith(keyFontFamily))
      .first();
    let finalStyles = {};
    if (fontFamily) {
      finalStyles = {
        ...finalStyles,
        fontFamily: fontFamily.replace(keyFontFamily, ""),
      };
    }
    if (fontSize) {
      finalStyles = {
        ...finalStyles,
        fontSize: fontSize.replace(keyFontSize, ""),
      };
    }
    if (color) {
      finalStyles = {
        ...finalStyles,
        color: color.replace(keyColor, ""),
      };
    }
    return {
      element: "span",
      style: finalStyles,
    };
  },
  entityStyleFn: (entity) => {
    const entityType = entity.get("type").toLowerCase();
    if (entityType === "tag") {
      const { tag, replaceWith } = entity.getData();
      return {
        element: "span",
        attributes: {
          className: "tag",
          "data-tag-label": tag,
          "data-tag-replace-with": replaceWith,
        },
        style: TAG_STYLE,
      };
    }
    if (entityType === "image") {
      const { path, name, type } = entity.getData();
      return {
        element: "span",
        attributes: {
          className: "image",
          "data-image-path": path,
          "data-image-name": name,
          "data-image-type": type,
        },
      };
    }

    if (entityType === "link") {
      const { url } = entity.getData();
      return {
        element: "a",
        attributes: {
          className: "link",
          href: url,
        },
      };
    }

    if (entityType === "button") {
      const { url, color, text, linkToForm, id } = entity.getData();
      const newColor = toLower(color);
      return {
        element: "a",
        attributes: {
          className: "button",
          href: url,
          "data-color": color,
          "data-text": text,
          "data-link-to-form": linkToForm,
          "data-id": id,
          style: `background-color: ${color}; display: inline-block; border-radius: 24px; padding: 13px 30px; font-size: 14px; color: ${
            newColor === "#ffffff" ? "#795afa" : "#fff"
          }; text-transform: uppercase; cursor: pointer; text-decoration: none; border: ${
            newColor === "#ffffff" ? "1px solid #acc4d4" : "none"
          }`,
        },
      };
    }
  },
};

// https://github.com/facebookarchive/draft-js/issues/1198#issuecomment-650004107
export function fixCursorBug(prevEditorState, nextEditorState) {
  const prevSelection = prevEditorState.getSelection();
  const nextSelection = nextEditorState.getSelection();
  if (
    prevSelection.getAnchorKey() === nextSelection.getAnchorKey() &&
    prevSelection.getAnchorOffset() === 0 &&
    nextSelection.getAnchorOffset() === 1 &&
    prevSelection.getFocusKey() === nextSelection.getFocusKey() &&
    prevSelection.getFocusOffset() === 0 &&
    nextSelection.getFocusOffset() === 1 &&
    prevSelection.getHasFocus() === false &&
    nextSelection.getHasFocus() === false
  ) {
    const fixedSelection = nextSelection.merge({ hasFocus: true });
    return EditorState.forceSelection(nextEditorState, fixedSelection);
  }
  return nextEditorState;
}
