import React from "react";
import {
  get,
  isEqual,
  flatten,
  map,
  pick,
  first,
  values,
  find,
  filter,
} from "lodash";
import TextInput from "components/TextInput";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import { FormattedMessage } from "react-intl";
import Spinner from "components/Spinner";
import UploadFile from "components/UploadFile";
import Dropdown from "components/Dropdown";
import ConfirmationModal from "components/ConfirmationModal";
import { PRIVILEGES } from "utils/constants";
import ToastManager from "components/ToastManager";
import classnames from "classnames";
import PrivilegedComponent from "components/PrivilegedComponent";
import DropdownColor from "components/DropdownColor";
import ToggleSwitch from "components/ToggleSwitch";
import FileView from "components/FileView";
import LivePreview from "components/LivePreview";
import { getFileName } from "utils/helpers";
import mimeTypes from "components/MimeTypes";
import { Form, FormGroup, Button, Row, Col } from "reactstrap";
import { Asterisk } from "components/Elements";
import bn from "utils/bemnames";
import { setTimeout } from "timers";
import NumberFormat from "react-number-format";
import TextInputFormatted from "components/TextInputFormatted";
import { PhoneNumberFormat, countryList } from "utils/config";
import BlockingComponent from "components/BlockingComponent";
import ServerConfiguration from "components/ServerForm/ServerConfiguration";
import ListenerDatabaseGeography from "components/ServerForm/ListenerDatabaseGeography";
import ProductionConfiguration from "components/ServerForm/ProductionConfiguration";
import MultiMarketConfiguration from "components/ServerForm/MultiMarketConfiguration";
import PromotionsConfiguration from "components/ServerForm/PromotionsConfiguration";
import DigitalConfiguration from "components/ServerForm/DigitalConfiguration";
import WideOrbitTrafficConfiguration from "components/ServerForm/WideOrbitTrafficConfiguration";
import WideOrbitAutomationConfiguration from "components/ServerForm/WideOrbitAutomationConfiguration";
import SecurityPolicyConfiguration from "components/ServerForm/SecurityPolicyConfiguration";

const bem = bn.create("server-form");
export const MODE_EDIT = "edit";
export const MODE_VIEW = "view";

function getNeighboringCountries(country) {
  if (country === "US") {
    return countryList.filter((item) => item.value !== country);
  }
  if (country === "CA")
    return countryList.filter((item) => item.value === "US");
  if (country === "MX")
    return countryList.filter((item) => item.value === "US");
  return [];
}
class ServerForm extends React.Component {
  formikRef = React.createRef();
  renderForm = () => {
    const {
      isLoading,
      intl,
      auth,
      shouldBlockNavigation,
      setShouldBlockNavigation,
      isSecurityPolicyModalOpen,
      toggleSecurityPolicyModalOpen,
      hasEntitiesToAssign,
      backButton,
    } = this.props;
    const serverDetails = get(this.props, "server", {});
    const authIsGodAdmin = get(auth, "user.is_god_admin", false);
    const isSupportAdmin = get(auth, "user.is_support_admin", false);
    const entities = get(serverDetails, "entities");
    const parseStationIds = (featureModule) => {
      const allStationsData = get(entities, featureModule, {});
      const allStations = flatten(values(allStationsData));
      return map(allStations, "id");
    };
    const initialValues = {
      name: serverDetails.name || "",
      logo: serverDetails.logo
        ? {
            path: serverDetails.logo,
            name: getFileName(serverDetails.logo),
            type: mimeTypes.lookup(serverDetails.logo),
          }
        : "",
      tag_line: serverDetails.tag_line || "",
      admins: serverDetails.admins || [],
      background_image: serverDetails.background_image
        ? {
            path: serverDetails.background_image,
            name: getFileName(serverDetails.background_image),
            type: mimeTypes.lookup(serverDetails.background_image),
          }
        : "",
      theme_background_color: serverDetails.theme_background_color || "#224B8B",
      button_color: serverDetails.button_color || "#334856",
      // server_type: serverDetails.is_shared ? "selected" : "",
      is_dedicated: get(serverDetails, "is_dedicated", false),
      is_shared: get(serverDetails, "is_shared", false),
      is_production: serverDetails.is_production || false,
      is_promotion: serverDetails.is_promotion || false,
      enable_digital: serverDetails.enable_digital || false,
      production_multimarket: serverDetails.production_multimarket || false,
      digital_multimarket: serverDetails.digital_multimarket || false,
      promotion_multimarket: serverDetails.promotion_multimarket || false,
      enable_wo_traffic: serverDetails.enable_wo_traffic || false,
      enable_wo_dubbing: serverDetails.enable_wo_dubbing || false,
      show_wo_cart_prefix: serverDetails.show_wo_cart_prefix || false,
      subdomain: serverDetails.subdomain || "",
      contract: serverDetails.contract || [],
      add1: serverDetails.add1 || "",
      add2: serverDetails.add2 || "",
      city: serverDetails.city || "",
      state: serverDetails.state || "",
      country: serverDetails.country || "",
      zip: serverDetails.zip || "",
      phone: serverDetails.phone || "",
      env: serverDetails.env || "",
      instructions: serverDetails.instructions || "",
      security_policy: {},
      entities: {
        production: parseStationIds("production"),
        promotion: parseStationIds("promotion"),
        digital: parseStationIds("digital"),
        enterprise_production: parseStationIds("enterprise_production"),
        wo_traffic: parseStationIds("wo_traffic"),
        wo_dubbing: parseStationIds("wo_dubbing"),
      },
      is_active: serverDetails.is_active || false,
      restrict_to_home_country: serverDetails.restrict_to_home_country || false,
      neighboring_countries: serverDetails.neighboring_countries
        ? serverDetails.neighboring_countries
        : [],
      password_complexity_pattern:
        serverDetails.password_complexity_pattern || "",
      replace_password_days: serverDetails.replace_password_days || "",
      reuse_password_days: serverDetails.reuse_password_days || "",
      domain_filter: serverDetails.domain_filter || "",
      system_idle_timeout: serverDetails.system_idle_timeout || "",
    };
    const form = (
      <div>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          ref={(ref) => (this.formikRef = ref)}
          validationSchema={Yup.object().shape({
            name: Yup.string().required(
              intl.formatMessage({
                id: "validate > name is required",
              })
            ),
            phone: Yup.string().required(
              intl.formatMessage({
                id: "validate > phone is required",
              })
            ),
            add1: Yup.string().required(
              intl.formatMessage({
                id: "validate > address 1 is required",
              })
            ),
            city: Yup.string().required(
              intl.formatMessage({
                id: "validate > city is required",
              })
            ),
            state: Yup.string().required(
              intl.formatMessage({
                id: "validate > state is required",
              })
            ),
            country: Yup.string().required(
              intl.formatMessage({
                id: "validate > country is required",
              })
            ),
            zip: Yup.string().required(
              intl.formatMessage({
                id: "validate > zip code is required",
              })
            ),
            is_dedicated: Yup.bool(),
            is_shared: Yup.bool(),
            // server_type: Yup.string().required(
            //   intl.formatMessage({
            //     id: "validate > server type is required",
            //   })
            // ),
            subdomain: Yup.string().required(
              intl.formatMessage({
                id: "validate > subdomain is required",
              })
            ),
            is_production: Yup.bool(),
            is_promotion: Yup.bool(),
            enable_digital: Yup.bool(),
            production_multimarket: Yup.bool(),
            digital_multimarket: Yup.bool(),
            promotion_multimarket: Yup.bool(),
            enable_wo_traffic: Yup.bool(),
            enable_wo_dubbing: Yup.bool(),
            show_wo_cart_prefix: Yup.bool(),
            is_active: Yup.bool(),
            entities: Yup.object().shape({
              production: Yup.array().of(Yup.string()),
              promotion: Yup.array().of(Yup.string()),
              digital: Yup.array().of(Yup.string()),
              enterprise_production: Yup.array().of(Yup.string()),
              wo_traffic: Yup.array().of(Yup.string()),
              wo_dubbing: Yup.array().of(Yup.string()),
            }),
            password_complexity_pattern: Yup.string(),
            replace_password_days: Yup.number()
              .integer()
              .positive()
              .typeError(
                this.props.intl.formatMessage({
                  id: "validate > replace password days must be number",
                })
              ),
            reuse_password_days: Yup.number()
              .integer()
              .positive()
              .typeError(
                this.props.intl.formatMessage({
                  id: "validate > reuse password days must be number",
                })
              ),
            domain_filter: Yup.string(),
            system_idle_timeout: Yup.number()
              .integer()
              .positive()
              .typeError(
                this.props.intl.formatMessage({
                  id: "validate > system idle timeout must be number",
                })
              ),
          })}
          onSubmit={this.props.onFormSubmit}
        >
          {(formProps) => {
            const errors = formProps.errors;
            const touched = formProps.touched;
            const values = formProps.values;
            const isChanged = !isEqual(initialValues, values);
            if (isChanged !== shouldBlockNavigation) {
              setTimeout(() => {
                setShouldBlockNavigation(isChanged);
              }, 20);
            }
            const moduleProps = {
              bem,
              edit: !isSupportAdmin,
              formProps,
              serverDetails,
              hasEntitiesToAssign,
            };
            const optionCountries = getNeighboringCountries(values.country);
            return (
              <Form
                id="server-form"
                onSubmit={formProps.handleSubmit}
                className={bem.e("server-edit-container")}
              >
                <div className={bem.e("content-form")}>
                  <div className={classnames(bem.e("title-section"), "d-flex")}>
                    <FormattedMessage id="server > title broadcast group details" />
                    {authIsGodAdmin ? (
                      <div
                        className={classnames(
                          "ml-auto mr-0",
                          bem.e("section-toggle-switch")
                        )}
                      >
                        <ToggleSwitch
                          leftComponent={
                            <FormattedMessage id="server > switch > off" />
                          }
                          rightComponent={
                            <FormattedMessage id="server > switch > on" />
                          }
                          switchProps={{
                            checked: values.is_active,
                            onChange: (checked) => {
                              formProps.setFieldValue("is_active", checked);
                              formProps.setFieldTouched("is_active", true);
                            },
                            disabled: false,
                            uncheckedIcon: false,
                            checkedIcon: false,
                            offColor: "#C2D4E0",
                            onColor: "#795AFA",
                            handleDiameter: 22,
                            width: 50,
                            height: 26,
                          }}
                        />
                      </div>
                    ) : null}
                  </div>
                  <Row className={bem.e("row-content")}>
                    <Col xs={12}>
                      <Row className={bem.e("row-logo-content")}>
                        <Col xs={6}>
                          {/* name */}
                          <FormGroup>
                            <TextInput
                              label={
                                <span>
                                  <FormattedMessage id="server > broadcaster name" />
                                  <Asterisk>*</Asterisk>
                                </span>
                              }
                              type="text"
                              name="name"
                              placeholder={intl.formatMessage({
                                id: "server > enter name",
                              })}
                              value={values.name}
                              error={touched.name && errors.name}
                              onChange={formProps.handleChange}
                              onBlur={formProps.handleBlur}
                            />
                          </FormGroup>
                        </Col>

                        <Col xs={6}>
                          {/* Logo */}
                          <FormGroup className="d-flex flex-column">
                            <label>
                              <FormattedMessage id="server > logo" />
                            </label>
                            <div className={bem.e("server-logo-edit")}>
                              <div className={bem.e("sever-logo-upload")}>
                                {get(values, "logo.path") ? (
                                  <FileView
                                    background
                                    file={values.logo}
                                    onDeleted={() => {
                                      setTimeout(() => {
                                        formProps.setFieldValue(
                                          "logo",
                                          "",
                                          true
                                        );
                                        formProps.setFieldTouched("logo", true);
                                      }, 20);
                                    }}
                                    onUploaded={(value) => {
                                      setTimeout(() => {
                                        formProps.setFieldValue("logo", value);
                                      }, 20);
                                    }}
                                    actions={["trash", "view"]}
                                  />
                                ) : (
                                  <UploadFile
                                    url={get(values, "logo.path")}
                                    mimes={["image/jpeg", "image/png"]}
                                    fieldName={"logo"}
                                    actions={[]}
                                    onDeleted={() => {
                                      setTimeout(() => {
                                        formProps.setFieldValue(
                                          "logo",
                                          "",
                                          true
                                        );
                                        formProps.setFieldTouched("logo", true);
                                      }, 20);
                                    }}
                                    onUploaded={(value) => {
                                      setTimeout(() => {
                                        formProps.setFieldValue("logo", value);
                                      }, 20);
                                    }}
                                    showRemoveIcon={false}
                                    maxSize={500000} //500kb
                                  />
                                )}
                              </div>
                              <span className={bem.e("upload-description")}>
                                <FormattedMessage id="server > upload logo format" />
                                <FormattedMessage id="server > upload logo orientation" />
                                <FormattedMessage id="server > upload logo size" />
                              </span>
                            </div>
                            {!!(touched.logo && errors.logo) && (
                              <span className="text-danger">{errors.logo}</span>
                            )}
                          </FormGroup>
                          {/* )} */}
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} className="mt-2">
                      <Row className={bem.e("row-logo-content")}>
                        <Col xs={6}>
                          {/* phone */}
                          <FormGroup className={bem.e("input-col")}>
                            <TextInputFormatted
                              label={
                                <span>
                                  <FormattedMessage id="server > phone" />
                                  <Asterisk>*</Asterisk>
                                </span>
                              }
                              format={PhoneNumberFormat}
                              name="phone"
                              placeholder={intl.formatMessage({
                                id: "server > enter phone",
                              })}
                              value={values.phone}
                              onChange={(phone) => {
                                formProps.setFieldValue("phone", phone);
                              }}
                              onBlur={formProps.handleBlur}
                              error={touched.phone && errors.phone}
                            />
                          </FormGroup>
                        </Col>

                        <Col xs={6}>
                          {/* background image */}
                          <FormGroup className="d-flex flex-column">
                            <label>
                              <FormattedMessage id="server > background image" />
                            </label>
                            <div className={bem.e("background-upload")}>
                              <div className={bem.e("background-upload-left")}>
                                {get(values, "background_image.path") ? (
                                  <FileView
                                    file={values.background_image}
                                    onDeleted={() => {
                                      setTimeout(() => {
                                        formProps.setFieldValue(
                                          "background_image",
                                          ""
                                        );
                                        formProps.setFieldTouched(
                                          "background_image",
                                          true
                                        );
                                      }, 20);
                                    }}
                                    onUploaded={(value) => {
                                      setTimeout(() => {
                                        formProps.setFieldValue(
                                          "background_image",
                                          first(value)
                                        );
                                      }, 20);
                                    }}
                                    actions={["trash", "view"]}
                                  />
                                ) : (
                                  <UploadFile
                                    url={null}
                                    isViewUiName={true}
                                    mimes={["image/jpeg", "image/png"]}
                                    fieldName={"background_image"}
                                    minDimensions={{
                                      width: 1200,
                                      height: 1000,
                                    }}
                                    onDeleted={() => {
                                      setTimeout(() => {
                                        formProps.setFieldValue(
                                          "background_image",
                                          "",
                                          true
                                        );
                                        formProps.setFieldTouched(
                                          "background_image",
                                          true
                                        );
                                      }, 20);
                                    }}
                                    onUploaded={(value) => {
                                      setTimeout(() => {
                                        formProps.setFieldValue(
                                          "background_image",
                                          value
                                        );
                                      }, 20);
                                    }}
                                    showRemoveIcon={false}
                                    maxSize={1024000} //1mb
                                  />
                                )}
                              </div>
                              <div className={bem.e("upload-description")}>
                                <FormattedMessage id="server > background image format" />
                                <FormattedMessage id="server > background image dimensions" />
                                <FormattedMessage id="server > background image size" />
                              </div>
                            </div>

                            {!!(
                              touched.background_image &&
                              errors.background_image
                            ) && (
                              <span className="text-danger">
                                {errors.background_image}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} className="mt-30">
                      <Row className={bem.e("row-logo-content")}>
                        <Col xs={6}>
                          {/* tag line */}
                          <FormGroup>
                            <TextInput
                              label={
                                <span>
                                  <FormattedMessage id="server > tag line" />
                                  {/* <Asterisk>*</Asterisk> */}
                                </span>
                              }
                              type="text"
                              name="tag_line"
                              placeholder={intl.formatMessage({
                                id: "server > enter tag line",
                              })}
                              value={values.tag_line}
                              onChange={formProps.handleChange}
                              onBlur={formProps.handleBlur}
                              error={touched.tag_line && errors.tag_line}
                            />
                          </FormGroup>
                        </Col>

                        <Col xs={6}>
                          {/* background image */}
                          <FormGroup className="d-flex flex-column">
                            {/* tab bar color, button color */}
                            <Row>
                              <Col>
                                <FormGroup className="d-flex flex-column">
                                  <DropdownColor
                                    label={
                                      <FormattedMessage id="server > top bar color" />
                                    }
                                    name="theme_background_color"
                                    value={values.theme_background_color}
                                    onChange={({ value }) => {
                                      formProps.setFieldValue(
                                        "theme_background_color",
                                        value
                                      );
                                    }}
                                    onBlur={formProps.handleBlur}
                                    error={
                                      touched.theme_background_color &&
                                      errors.theme_background_color
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup className="d-flex flex-column">
                                  <DropdownColor
                                    label={
                                      <FormattedMessage id="server > button color" />
                                    }
                                    except={["#FFFFFF"]}
                                    name="button_color"
                                    value={values.button_color}
                                    onChange={({ value }) => {
                                      formProps.setFieldValue(
                                        "button_color",
                                        value
                                      );
                                    }}
                                    onBlur={formProps.handleBlur}
                                    error={
                                      touched.button_color &&
                                      errors.button_color
                                    }
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={6}>
                      {/* address 1 */}
                      <FormGroup>
                        <TextInput
                          label={
                            <span>
                              <FormattedMessage id="server > address 1" />
                              <Asterisk>*</Asterisk>
                            </span>
                          }
                          type="text"
                          name="add1"
                          placeholder={intl.formatMessage({
                            id: "server > enter address",
                          })}
                          value={values.add1}
                          onChange={formProps.handleChange}
                          onBlur={formProps.handleBlur}
                          error={touched.add1 && errors.add1}
                        />
                      </FormGroup>
                      {/* address 2 */}
                      <FormGroup>
                        <TextInput
                          label={
                            <span>
                              <FormattedMessage id="server > address 2" />
                              {/* <Asterisk>*</Asterisk> */}
                            </span>
                          }
                          type="text"
                          name="add2"
                          placeholder={intl.formatMessage({
                            id: "server > enter address",
                          })}
                          value={values.add2}
                          onChange={formProps.handleChange}
                          onBlur={formProps.handleBlur}
                          error={touched.add2 && errors.add2}
                        />
                      </FormGroup>
                      {/* city */}
                      <FormGroup>
                        <TextInput
                          label={
                            <span>
                              <FormattedMessage id="server > city" />
                              <Asterisk>*</Asterisk>
                            </span>
                          }
                          type="text"
                          name="city"
                          placeholder={intl.formatMessage({
                            id: "server > enter city",
                          })}
                          value={values.city}
                          onChange={formProps.handleChange}
                          onBlur={formProps.handleBlur}
                          error={touched.city && errors.city}
                        />
                      </FormGroup>
                      {/* state, zip code */}
                      <Row>
                        <Col xs={6} className={bem.e("no-margin")}>
                          <FormGroup>
                            <TextInput
                              label={
                                <span>
                                  <FormattedMessage
                                    id={
                                      values.country === "CA"
                                        ? "server > province"
                                        : "server > state"
                                    }
                                  />
                                  <Asterisk>*</Asterisk>
                                </span>
                              }
                              type="text"
                              name="state"
                              placeholder={intl.formatMessage({
                                id:
                                  values.country === "CA"
                                    ? "server > enter province"
                                    : "server > enter state",
                              })}
                              value={values.state}
                              onChange={formProps.handleChange}
                              onBlur={formProps.handleBlur}
                              error={
                                touched.state && errors.state
                                  ? intl.formatMessage({
                                      id:
                                        values.country === "CA"
                                          ? "validate > province is required"
                                          : "validate > state is required",
                                    })
                                  : null
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={6} className={bem.e("no-margin")}>
                          <FormGroup>
                            <TextInput
                              label={
                                <span>
                                  <FormattedMessage
                                    id={
                                      values.country === "CA"
                                        ? "server > postal code"
                                        : "server > zip code"
                                    }
                                  />
                                  <Asterisk>*</Asterisk>
                                </span>
                              }
                              type="text"
                              name="zip"
                              placeholder={intl.formatMessage({
                                id:
                                  values.country === "CA"
                                    ? "server > enter postal code"
                                    : "server > enter zip code",
                              })}
                              value={values.zip}
                              onChange={formProps.handleChange}
                              onBlur={formProps.handleBlur}
                              error={
                                touched.zip && errors.zip
                                  ? intl.formatMessage({
                                      id:
                                        values.country === "CA"
                                          ? "validate > postal code is required"
                                          : "validate > zip code is required",
                                    })
                                  : null
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      {/* country */}
                      <Row>
                        <Col className={bem.e("no-margin")}>
                          <FormGroup className={bem.e("last-form-group")}>
                            <Dropdown
                              label={
                                <span>
                                  <FormattedMessage id="server > country" />
                                  <Asterisk>*</Asterisk>
                                </span>
                              }
                              value={
                                find(
                                  countryList,
                                  (item) => item.value === values.country
                                ) || null
                              }
                              placeholder={intl.formatMessage({
                                id: "server > please select",
                              })}
                              isClearable
                              onChange={(selectedOption) => {
                                formProps.setFieldValue(
                                  "country",
                                  get(selectedOption, "value", "")
                                );
                                const newOptionCountries = getNeighboringCountries(
                                  get(selectedOption, "value", "")
                                );
                                const newValue =
                                  newOptionCountries.length === 1
                                    ? [newOptionCountries[0].value]
                                    : filter(
                                        values.neighboring_countries,
                                        (item) =>
                                          find(
                                            newOptionCountries,
                                            (i) => i.value === item.value
                                          )
                                      ).map((item) => item.value);
                                formProps.setFieldValue(
                                  "neighboring_countries",
                                  newValue
                                );
                              }}
                              options={countryList}
                              name="country"
                              error={touched.country && errors.country}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={6}>
                      {/* live preview */}
                      <FormGroup className="d-flex flex-column">
                        <label>
                          <FormattedMessage id="server > live preview" />
                        </label>
                        <LivePreview
                          {...pick(values, [
                            "name",
                            "tag_line",
                            "background_image",
                            "logo",
                            "theme_background_color",
                            "button_color",
                          ])}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                {authIsGodAdmin && (
                  <ListenerDatabaseGeography
                    {...moduleProps}
                    optionCountries={optionCountries}
                  />
                )}
                {authIsGodAdmin && (
                  <>
                    <ServerConfiguration
                      {...moduleProps}
                      authIsGodAdmin={authIsGodAdmin}
                      isSecurityPolicyModalOpen={false}
                      toggleSecurityPolicyModalOpen={
                        toggleSecurityPolicyModalOpen
                      }
                    />
                    <SecurityPolicyConfiguration {...moduleProps} />
                    <ProductionConfiguration {...moduleProps} />
                    <MultiMarketConfiguration {...moduleProps} />
                    <PromotionsConfiguration {...moduleProps} />
                    <DigitalConfiguration {...moduleProps} />
                    <WideOrbitTrafficConfiguration {...moduleProps} />
                    <WideOrbitAutomationConfiguration {...moduleProps} />
                  </>
                )}
                <div className={bem.e("form-buttons")}>
                  <FormGroup>
                    <Button
                      color="blue"
                      type="submit"
                      className="btn btn-radius"
                      disabled={isLoading}
                      onClick={() => {
                        setTimeout(() => {
                          const formErrors = get(
                            this.formikRef,
                            "state.errors"
                          );
                          if (formErrors && Object.keys(formErrors).length) {
                            Object.keys(formErrors).forEach((inputName) => {
                              const inputNode = document.querySelector(
                                `#server-form .is-closed input[name=${inputName}]`
                              );
                              if (inputNode) {
                                const closedSection = inputNode.closest(
                                  ".is-closed"
                                );
                                const closedTitle = closedSection.querySelector(
                                  ".is-closed"
                                );
                                closedTitle && closedTitle.click();
                              }
                            });
                            ToastManager.show({
                              title: this.props.intl.formatMessage({
                                id: "toast > title not saved",
                              }),
                              message: this.props.intl.formatMessage({
                                id:
                                  "toast > message error please correct the hilighted fields",
                              }),
                              level: "error",
                            });
                            return;
                          }
                        }, 20);
                      }}
                    >
                      <FormattedMessage
                        id={`process > button ${
                          !isLoading ? "submit" : "submitting"
                        }`}
                      />
                    </Button>
                    {backButton ? (
                      backButton
                    ) : (
                      <Button
                        type="button"
                        outline
                        color="blue"
                        className={classnames(
                          bem.e("button-cancel"),
                          "btn-radius"
                        )}
                        onClick={() => {
                          this.props.setIsViewMode(MODE_VIEW);
                          if (this.props.back) {
                            this.props.back();
                          }
                        }}
                      >
                        <FormattedMessage id="server > button cancel" />
                      </Button>
                    )}
                  </FormGroup>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
    return (
      <React.Fragment>
        {form}
        <BlockingComponent
          isBlocked={shouldBlockNavigation}
          modalProps={{
            isShowConfirm: true,
            isShowDiscard: false,
            isCancelConfirmedNavigation: true,
            onCancel: () => {
              setShouldBlockNavigation(false); // reset
            },
            // onConfirm: () => {
            //   const buttonSubmit = document.getElementById(`btn-submit`);
            //   // trigger click to submit the form
            //   if (buttonSubmit) buttonSubmit.click();
            // },
          }}
        />
      </React.Fragment>
    );
    // return (
    //   <WarnAboutUnsavedChanges when={shouldBlockNavigation} children={form} />
    // );
  };

  renderView = () => {
    const { hasEntitiesToAssign, backButton } = this.props;
    const serverData = get(this.props, "server", {});
    const authIsGodAdmin = get(this.props, "auth.user.is_god_admin", false);
    const serverDetails = {
      name: serverData.name || "",
      logo: serverData.logo || "",
      tag_line: serverData.tag_line || "",
      admins: serverData.admins || [],
      background_image:
        {
          path: serverData.background_image,
          name: getFileName(serverData.background_image),
          type: mimeTypes.lookup(serverData.background_image),
        } || "",
      theme_background_color: serverData.theme_background_color || "#083070",
      button_color: serverData.button_color || "#334856",
      entities: serverData.entities || [],
      is_dedicated: serverData.is_dedicated || false,
      is_shared: serverData.is_shared || false,
      is_production: serverData.is_production || false,
      is_promotion: serverData.is_promotion || false,
      enable_digital: serverData.enable_digital || false,
      production_multimarket: serverData.production_multimarket || false,
      digital_multimarket: serverData.digital_multimarket || false,
      promotion_multimarket: serverData.promotion_multimarket || false,
      enable_wo_traffic: serverData.enable_wo_traffic || false,
      enable_wo_dubbing: serverData.enable_wo_dubbing || false,
      show_wo_cart_prefix: serverData.show_wo_cart_prefix || false,
      subdomain: serverData.subdomain || "",
      contract: serverData.contract || [],
      add1: serverData.add1 || "",
      add2: serverData.add2 || "",
      city: serverData.city || "",
      state: serverData.state || "",
      zip: serverData.zip || "",
      phone: serverData.phone || "",
      country:
        get(
          find(countryList, (i) => i.value === serverData.country),
          "label"
        ) || "",
      restrict_to_home_country: serverData.restrict_to_home_country || false,
      neighboring_countries: serverData.neighboring_countries || "",
      env: serverData.env || "",
      instructions: serverData.instructions || "",
      is_security:
        serverData.system_idle_timeout ||
        serverData.password_complexity_pattern ||
        serverData.replace_password_days ||
        serverData.reuse_password_days,
      is_active: serverData.is_active || false,
      password_complexity_pattern: serverData.password_complexity_pattern || "",
      replace_password_days: serverData.replace_password_days || "",
      reuse_password_days: serverData.reuse_password_days || "",
      domain_filter: serverData.domain_filter || "",
      system_idle_timeout: serverData.system_idle_timeout || "",
    };
    const moduleProps = {
      bem,
      hasEntitiesToAssign,
      serverDetails,
    };
    return (
      <>
        <div className={bem.e("server-view-container")}>
          <div className={bem.e("content-form")}>
            <div className={bem.e("title-section")}>
              <FormattedMessage id="server > title broadcast group details" />
            </div>
            <Row className={bem.e("row-content")}>
              <Col xs={6}>
                {/* name */}
                <FormGroup>
                  <label className={bem.e("server-view-label")}>
                    <FormattedMessage id="server > broadcaster name" />
                  </label>
                  <div className={bem.e("server-view-value")}>
                    {serverDetails.name ? (
                      serverDetails.name
                    ) : (
                      <FormattedMessage id="server > none" />
                    )}
                  </div>
                </FormGroup>
                {/* phone */}
                <FormGroup>
                  <label className={bem.e("server-view-label")}>
                    <FormattedMessage id="server > phone" />
                  </label>
                  <div className={bem.e("server-view-value")}>
                    {serverDetails.phone ? (
                      <NumberFormat
                        value={serverDetails.phone}
                        displayType={"text"}
                        format={PhoneNumberFormat}
                      />
                    ) : (
                      <FormattedMessage id="server > none" />
                    )}
                  </div>
                </FormGroup>
                {/* tag line */}
                <FormGroup>
                  <label className={bem.e("server-view-label")}>
                    <FormattedMessage id="server > tag line" />
                  </label>
                  <div className={bem.e("server-view-value")}>
                    {serverDetails.tag_line ? (
                      serverDetails.tag_line
                    ) : (
                      <FormattedMessage id="server > none" />
                    )}
                  </div>
                </FormGroup>
                {/* address 1 */}
                <FormGroup>
                  <label className={bem.e("server-view-label")}>
                    <FormattedMessage id="server > address 1" />
                  </label>
                  <div className={bem.e("server-view-value")}>
                    {serverDetails.add1 ? (
                      serverDetails.add1
                    ) : (
                      <FormattedMessage id="server > none" />
                    )}
                  </div>
                </FormGroup>
                {/* address 2 */}
                <FormGroup>
                  <label className={bem.e("server-view-label")}>
                    <FormattedMessage id="server > address 2" />
                  </label>
                  <div className={bem.e("server-view-value")}>
                    {serverDetails.add2 ? (
                      serverDetails.add2
                    ) : (
                      <FormattedMessage id="server > none" />
                    )}
                  </div>
                </FormGroup>
                {/* city */}
                <FormGroup>
                  <label className={bem.e("server-view-label")}>
                    <FormattedMessage id="server > city" />
                  </label>
                  <div className={bem.e("server-view-value")}>
                    {serverDetails.city ? (
                      serverDetails.city
                    ) : (
                      <FormattedMessage id="server > none" />
                    )}
                  </div>
                </FormGroup>
                {/* state, zip code */}
                <Row>
                  <Col xs={6} className={bem.e("no-margin")}>
                    <FormGroup>
                      <label className={bem.e("server-view-label")}>
                        {serverData.country === "CA" ? (
                          <FormattedMessage id="server > province" />
                        ) : (
                          <FormattedMessage id="server > state" />
                        )}
                      </label>
                      <div className={bem.e("server-view-value")}>
                        {serverDetails.state ? (
                          serverDetails.state
                        ) : (
                          <FormattedMessage id="server > none" />
                        )}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col xs={6} className={bem.e("no-margin")}>
                    <FormGroup>
                      <label className={bem.e("server-view-label")}>
                        {serverData.country === "CA" ? (
                          <FormattedMessage id="server > postal code" />
                        ) : (
                          <FormattedMessage id="server > zip code" />
                        )}
                      </label>
                      <div className={bem.e("server-view-value")}>
                        {serverDetails.zip ? (
                          serverDetails.zip
                        ) : (
                          <FormattedMessage id="server > none" />
                        )}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} className={bem.e("no-margin")}>
                    <FormGroup className={bem.e("last-form-group")}>
                      <label className={bem.e("server-view-label")}>
                        <FormattedMessage id="server > country" />
                      </label>
                      <div className={bem.e("server-view-value")}>
                        {serverDetails.country ? (
                          serverDetails.country
                        ) : (
                          <FormattedMessage id="server > none" />
                        )}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col xs={6}>
                {/* Logo */}
                <FormGroup>
                  <label className={bem.e("server-view-label")}>
                    <FormattedMessage id="server > logo" />
                  </label>
                  <div className={bem.e("view-logo")}>
                    <img
                      className={bem.e("logo-img")}
                      src={serverDetails.logo}
                      alt="logo"
                    />
                  </div>
                </FormGroup>
                {/* background image */}
                <FormGroup>
                  <label className={bem.e("server-view-label")}>
                    <FormattedMessage id="server > background image" />
                  </label>
                  {get(serverDetails, "background_image.path") ? (
                    <FileView
                      file={serverDetails.background_image}
                      onDeleted={() => {}}
                      onReplace={() => {}}
                      actions={["view"]}
                    />
                  ) : (
                    <div className={bem.e("server-view-value")}>
                      <FormattedMessage id="server > none" />
                    </div>
                  )}
                </FormGroup>
                {/* tab bar color, button color */}
                <Row>
                  <Col>
                    <FormGroup>
                      <DropdownColor
                        label={<FormattedMessage id="server > top bar color" />}
                        name="theme_background_color"
                        value={serverDetails.theme_background_color}
                        onChange={({ value }) => {}}
                        isView
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <DropdownColor
                        label={<FormattedMessage id="server > button color" />}
                        name="button_color"
                        value={serverDetails.button_color}
                        onChange={({ value }) => {}}
                        isView
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {/* live preview */}
              </Col>
            </Row>
          </div>
        </div>
        {authIsGodAdmin && <ListenerDatabaseGeography {...moduleProps} />}
        {authIsGodAdmin && (
          <>
            <ServerConfiguration
              authIsGodAdmin={authIsGodAdmin}
              {...moduleProps}
            />
            <SecurityPolicyConfiguration {...moduleProps} />
            <ProductionConfiguration {...moduleProps} />
            <MultiMarketConfiguration {...moduleProps} />
            <PromotionsConfiguration {...moduleProps} />
            <DigitalConfiguration {...moduleProps} />
            <WideOrbitTrafficConfiguration {...moduleProps} />
            <WideOrbitAutomationConfiguration {...moduleProps} />
          </>
        )}
        <div className={bem.e("form-buttons")}>
          <PrivilegedComponent requires={[PRIVILEGES.EDIT_SERVERS]}>
            <Button
              color="blue"
              type="button"
              className="btn btn-radius"
              onClick={() => {
                this.props.setIsViewMode(MODE_EDIT);
              }}
            >
              <FormattedMessage id="edit" />
            </Button>
          </PrivilegedComponent>
          {backButton ? (
            <Button
              type="button"
              outline
              color="blue"
              className={classnames(bem.e("button-cancel"), "btn-radius")}
              onClick={() => {
                if (this.props.back) this.props.back();
              }}
            >
              <FormattedMessage id="server > button cancel" />
            </Button>
          ) : null}
        </div>
        <ConfirmationModal
          isOpen={this.props.isDeleteModalOpen}
          title={this.props.intl.formatMessage({
            id: "server > are you sure you want to remove broadcasting group",
          })}
          onConfirm={this.props.onDelete}
          onToggle={this.props.closeDeleteModal}
          onCancel={this.props.closeDeleteModal}
          isCloseOutside={false}
        />
      </>
    );
  };
  render() {
    const { isViewMode, isLoading } = this.props;
    return (
      <Row className={bem.b()}>
        <Col>
          {isViewMode === MODE_EDIT ? this.renderForm() : this.renderView()}
          <Spinner isLoading={isLoading} />
        </Col>
      </Row>
    );
  }
}
ServerForm.propTypes = {
  isView: PropTypes.bool.isRequired,
  backButton: PropTypes.any,
  back: PropTypes.func.isRequired,
};
export default ServerForm;
