import PropTypes from "prop-types";
import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button, Form, FormGroup, Input, Label, Alert } from "reactstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import { EyeIcon } from "components/CustomIcons";
import classNames from "classnames";
class AuthForm extends React.Component {
  get isLogin() {
    return this.props.authState === STATE_LOGIN;
  }

  get isSignup() {
    return this.props.authState === STATE_SIGNUP;
  }

  render() {
    const {
      usernameInputProps,
      children,
      showPassword,
      setShowPassword,
      isMobile,
    } = this.props;
    return (
      <Formik
        initialValues={{
          email: "",
          password: "",
          c_password: "",
          isSignup: this.isSignup,
        }}
        validationSchema={Yup.object().shape({
          isSignup: Yup.boolean(),
          email: Yup.string()
            .email(
              this.props.intl.formatMessage({ id: "validate > invalid email" })
            )
            .required(
              this.props.intl.formatMessage({
                id: "validate > email is required",
              })
            ),
          password: Yup.string().required(
            this.props.intl.formatMessage({
              id: "validate > password is required",
            })
          ),
          c_password: Yup.mixed().when("isSignup", {
            is: true,
            then: Yup.string().required(
              this.props.intl.formatMessage({
                id: "validate > confirm password is required",
              })
            ),
            otherwise: Yup.mixed(),
          }),
          name: Yup.mixed().when("isSignup", {
            is: true,
            then: Yup.string().required(
              this.props.intl.formatMessage({
                id: "validate > name is required",
              })
            ),
            otherwise: Yup.mixed(),
          }),
        })}
        onSubmit={(values) =>
          this.props.handleSubmit(values, {
            url: this.props.redirectUrl,
            isMobile,
          })
        }
      >
        {(formProps) => {
          const emailError = formProps.touched.email && formProps.errors.email;
          const nameError = formProps.touched.name && formProps.errors.name;
          const passwordError =
            formProps.touched.password && formProps.errors.password;
          const cPasswordError =
            formProps.touched.c_password && formProps.errors.c_password;
          return (
            <Form onSubmit={formProps.handleSubmit}>
              <h2 className="form-title">
                {
                  <FormattedMessage
                    id={
                      this.isLogin
                        ? "auth > login title"
                        : "auth > signup title"
                    }
                  />
                }
              </h2>
              {this.isSignup && (
                <FormGroup>
                  <Label className="auth-label" for={"name"}>
                    <FormattedMessage id={"auth > name"} />
                  </Label>
                  <Input
                    id="name"
                    className="form-control"
                    onChange={formProps.handleChange}
                    onBlur={formProps.handleBlur}
                    value={formProps.values.name}
                    name="name"
                    invalid={!!nameError}
                    placeholder="Your name"
                  />
                  {nameError && (
                    <span className="text-danger">{nameError}</span>
                  )}
                </FormGroup>
              )}
              <FormGroup>
                <Label className="auth-label" for={"email"}>
                  <FormattedMessage id={"auth > email"} />
                </Label>
                <Input
                  {...usernameInputProps}
                  id="email"
                  className="form-control"
                  onChange={formProps.handleChange}
                  onBlur={formProps.handleBlur}
                  value={formProps.values.email}
                  name="email"
                  invalid={!!emailError}
                />
                {emailError && (
                  <span className="text-danger">{emailError}</span>
                )}
              </FormGroup>
              <FormGroup>
                <Label className="auth-label" for="password">
                  <FormattedMessage id={"auth > password"} />
                </Label>
                <div className="password-input">
                  <Input
                    type={showPassword.password ? "text" : "password"}
                    id="password"
                    className="form-control"
                    onChange={formProps.handleChange}
                    onBlur={formProps.handleBlur}
                    value={formProps.values.password}
                    name="password"
                    invalid={!!passwordError}
                  />
                  <span
                    className={classNames("btn-show-password", {
                      active: showPassword.password,
                    })}
                    onClick={() =>
                      setShowPassword({
                        ...showPassword,
                        password: !showPassword.password,
                      })
                    }
                  >
                    <EyeIcon color="#795AFA" width={24} height={16} />
                  </span>
                </div>
                {passwordError && (
                  <span className="text-danger">{passwordError}</span>
                )}
              </FormGroup>
              {this.isSignup && (
                <FormGroup>
                  <Label className="auth-label" for="c_password">
                    <FormattedMessage id={"auth > confirm password"} />
                  </Label>
                  <div className="password-input">
                    <Input
                      type={showPassword.c_password ? "text" : "password"}
                      id="c_password"
                      className="form-control"
                      onChange={formProps.handleChange}
                      onBlur={formProps.handleBlur}
                      value={formProps.values.c_password}
                      name="c_password"
                      invalid={!!cPasswordError}
                    />
                    <span
                      className={classNames("btn-show-password", {
                        active: showPassword.c_password,
                      })}
                      onClick={() =>
                        setShowPassword({
                          ...showPassword,
                          c_password: !showPassword.c_password,
                        })
                      }
                    >
                      <EyeIcon color="#795AFA" width={24} height={16} />
                    </span>
                  </div>
                  {cPasswordError && (
                    <span className="text-danger">{cPasswordError}</span>
                  )}
                </FormGroup>
              )}

              <Button
                color="blue"
                className={"btn-submit-auth"}
                block
                type="submit"
              >
                <FormattedMessage
                  id={this.isLogin ? "auth > login" : "auth > signup"}
                />
              </Button>
              <hr />
              <div className="auth-links">
                <a href="app/password/reset" className="link">
                  <FormattedMessage id={"auth > forgot password"} />
                </a>
                {this.isSignup ? (
                  <a
                    href="#login"
                    onClick={this.props.changeAuthState(STATE_LOGIN)}
                    className="link"
                  >
                    <FormattedMessage id={"auth > login"} />
                  </a>
                ) : null}
              </div>
              {this.props.errorMessage && (
                <Alert color="danger">{this.props.errorMessage}</Alert>
              )}
              {children}
            </Form>
          );
        }}
      </Formik>
    );
  }
}

export const STATE_LOGIN = "LOGIN";
export const STATE_SIGNUP = "SIGNUP";
AuthForm.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP]).isRequired,
  showLogo: PropTypes.bool,
  usernameInputProps: PropTypes.object,
  passwordInputProps: PropTypes.object,
  confirmPasswordInputProps: PropTypes.object,
  handleSubmit: PropTypes.func,
  errorMessage: PropTypes.string,
  isMobile: PropTypes.bool,
};

AuthForm.defaultProps = {
  authState: "LOGIN",
  showLogo: true,
  usernameInputProps: {
    type: "email",
    placeholder: "your@email.com",
  },
  handleSubmit: () => {},
  isMobile: false,
};

export default injectIntl(AuthForm);
