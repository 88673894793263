import HubsList from "./view";
import { get } from "lodash";
import { connect } from "react-redux";
import {
  compose,
  lifecycle,
  withState,
  withHandlers,
  withPropsOnChange,
} from "recompose";
import { getHubs, deleteHub, getMoreHubs } from "store/actions/hubs";
import { injectIntl } from "react-intl";
import { pageSize } from "utils/config";
export default injectIntl(
  compose(
    connect(
      (state) => {
        return {
          hubs: get(state, "hubs.hubs"),
          isLazyLoading: get(state, "hubs.lazyLoad", false),
          isLoading:
            get(state, "hubs.saveHub.loading", false) ||
            get(state, "hubs.deleteHub.loading", false),
        };
      },
      {
        getHubs,
        getMoreHubs,
        deleteHub,
      }
    ),
    withState("searchText", "setSearchText", ""),
    withState("filtered", "setFiltered", []),
    withState("searchTags", "setSearchTags", []),
    withState("sorted", "setSorted", []),
    withState("isDeleteModalOpen", "setIsDeleteModalOpen", false),
    withState("isWarningDeleteModalOpen", "setIsWarningDeleteModalOpen", false),
    withState("deleteId", "setDeleteId", null),
    withState("selectedId", "setSelectedId", null),
    withState("isCreateModalOpen", "setIsCreateModalOpen", false),
    withState("isTeamWarningModalOpen", "setIsTeamWarningModalOpen", false),
    withHandlers({
      openDeleteModal: ({ setIsDeleteModalOpen, setDeleteId }) => (id) => {
        setIsDeleteModalOpen(true);
        setDeleteId(id);
      },
      closeDeleteModal: ({ setIsDeleteModalOpen, setDeleteId }) => () => {
        setIsDeleteModalOpen(false);
        setDeleteId(null);
      },
      onToggleCreateModal: ({
        isCreateModalOpen,
        setIsCreateModalOpen,
      }) => () => {
        setIsCreateModalOpen(!isCreateModalOpen);
      },
      getHubList: ({ getHubs, sorted, filtered }) => async () => {
        getHubs({
          pageSize,
          page: 0,
          sorted,
          filtered,
        });
      },
      getMoreHubs: ({ getMoreHubs, sorted, filtered }) => async (page) => {
        getMoreHubs({
          pageSize,
          page,
          sorted,
          filtered,
        });
      },
    }),
    withHandlers({
      onLoadMore: ({ getMoreHubs, hubs }) => () => {
        getMoreHubs(get(hubs, "data.current_page", 1));
      },
    }),
    withHandlers({
      onDelete: ({ closeDeleteModal, deleteId, deleteHub }) => () => {
        deleteHub(deleteId);
        closeDeleteModal();
      },
      onSearchKeyDown: ({ getHubList }) => (e) => {
        if (e.keyCode === 13) {
          getHubList();
        }
      },
      onSearchClicked: ({ getHubList }) => () => {
        getHubList();
      },
      onChangeInput: ({ filtered, setFiltered, setSearchText }) => ({
        target,
      }) => {
        const value = target.value;
        const name = target.name;
        const filterSearch = {
          id: name,
          value,
        };
        const index = filtered.findIndex((item) => item.id === name);
        if (index === -1) {
          filtered.push(filterSearch);
        } else {
          filtered[index] = filterSearch;
        }
        setSearchText(value);
        setFiltered(filtered);
      },
    }),
    withHandlers({
      onClearSearch: ({ onChangeInput, onSearchClicked }) => (e) => {
        e.preventDefault();
        onChangeInput({
          target: {
            name: "q",
            value: "",
          },
        });
        onSearchClicked();
      },
      openViewModal: ({ setSelectedId, setIsViewModalOpen }) => (id) => {
        setSelectedId(id);
        setIsViewModalOpen(true);
      },
      openWarningModal: ({ setSelectedId, setIsWarningModalOpen }) => (id) => {
        setSelectedId(id);
        setIsWarningModalOpen(true);
      },
    }),
    withPropsOnChange(
      ["sorted", "filtered"],
      ({ getHubList, sorted, filtered, pageSize, isFocused }) => {
        if (sorted.length > 0 || filtered.length > 0 || pageSize) {
          if (isFocused) {
            getHubList();
          }
        }
      }
    ),
    lifecycle({
      componentDidMount() {
        if (get(this.props, "isFocused")) {
          this.props.setSorted([]);
          this.props.setFiltered([]);

          this.props.getHubs({
            pageSize,
            page: 0,
            sorted: [],
            filtered: [],
          });
        }
      },
      componentDidUpdate(prevProps) {
        if (
          get(this.props, "isFocused") !== get(prevProps, "isFocused") &&
          get(this.props, "isFocused")
        ) {
          this.props.getHubList();
        }
        if (get(this.props, "hubs.is_dirty") && get(this.props, "isFocused")) {
          this.props.getHubList();
        }
      },
    })
  )(HubsList)
);
