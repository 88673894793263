import React, { useState } from "react";
import { Row, Col, FormGroup, Label } from "reactstrap";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { find, get } from "lodash";
import { MenuIcon } from "components/CustomIcons";
import {
  ShadowBox,
  SectionTitle,
  SectionFields,
  Asterisk,
} from "components/Elements";
import { DownIcon, UpIcon } from "components/CustomIcons";
import Dropdown from "components/Dropdown";
import TextInputFormatted from "components/TextInputFormatted";
import TextInput from "components/TextInput";
import moment from "moment";
import ContestRulesModal from "../ContestRulesModal";
import ToggleSwitch from "components/ToggleSwitch";
const ContestRules = (props) => {
  const {
    validationState,
    process,
    bem,
    step,
    onValueChanged,
    intl,
    isView,
    openDetails,
    setOpenDetails,
    sectionKey,
    dateFormatByServer
  } = props;
  const [fieldRefs, setFieldRefs] = useState(null);
  const [isOpenContestRulesModal, setIsOpenContestRulesModal] = useState(false);

  const getFieldRefs = () => {
    if (fieldRefs) return fieldRefs;

    const age_restrictions = step.fields.find(
      (f) => f.field === "age_restrictions"
    );
    const rule_eligibility = step.fields.find(
      (f) => f.field === "rule_eligibility"
    );
    const rule_future_eligibility = step.fields.find(
      (f) => f.field === "rule_future_eligibility"
    );
    const contest_rules = step.fields.find((f) => f.field === "contest_rules");
    const rule_number_of_days = step.fields.find(
      (f) => f.field === "rule_number_of_days"
    );
    const rule_deadline = step.fields.find((f) => f.field === "rule_deadline");
    const enforce_forfeiture = step.fields.find(
      (f) => f.field === "enforce_forfeiture"
    );

    const payload = {
      age_restrictions,
      contest_rules,
      rule_eligibility,
      rule_future_eligibility,
      rule_number_of_days,
      rule_deadline,
      enforce_forfeiture,
    };

    setFieldRefs(payload);

    return payload;
  };
  const {
    age_restrictions,
    contest_rules,
    rule_eligibility,
    rule_future_eligibility,
    rule_number_of_days,
    rule_deadline,
    enforce_forfeiture,
  } = getFieldRefs();
  const selectedAge = get(process, "data.fields.age_restrictions.value", "");
  const selectedEligibility = get(
    process,
    "data.fields.rule_eligibility.value",
    ""
  );
  const selectedFutureEligibility = get(
    process,
    "data.fields.rule_future_eligibility.value",
    ""
  );
  const isOpen = get(openDetails, sectionKey, false);

  return (
    <ShadowBox className={bem.e("box-script-details")}>
      <SectionTitle
        className={bem.e("contest-script-section-title")}
        onClick={() =>
          setOpenDetails({
            ...openDetails,
            [sectionKey]: !get(openDetails, sectionKey, false),
          })
        }
      >
        <div className={bem.e("box-head-title")}>
          <span className={"menu-selection-icon"}>
            <MenuIcon />
          </span>
          <FormattedMessage id="process > promotion > contest > title contest rules" />
        </div>
        <span className={bem.e("box-action")}>
          {isOpen ? <UpIcon /> : <DownIcon />}
        </span>
      </SectionTitle>
      {isOpen && (
        <React.Fragment>
          <SectionFields
            className={classnames(
              bem.e("section-fields"),
              bem.e("contest-rules-fields")
            )}
          >
            {isView ? (
              <React.Fragment>
                <Row>
                  <Col xs={6} className={bem.e("reset-col")}>
                    <FormGroup>
                      <Label>{age_restrictions.title}</Label>
                      <span className={bem.e("order-view-value")}>
                        {selectedAge
                          ? find(
                              age_restrictions.props.options,
                              (item) => item.value === selectedAge
                            ).label
                          : intl.formatMessage({ id: "process > none" })}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col xs={6} className={bem.e("reset-col")}>
                    <FormGroup>
                      <Label>{contest_rules.title}</Label>
                      <div
                        className={bem.e("contest-rules")}
                        onClick={() => setIsOpenContestRulesModal(true)}
                      >
                        {
                          <FormattedMessage id="process > promotion > contest > contest rules" />
                        }
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} className={bem.e("reset-col")}>
                    <FormGroup>
                      <Label>{rule_eligibility.title}</Label>
                      <span className={bem.e("order-view-value")}>
                        {selectedEligibility
                          ? find(
                              rule_eligibility.props.options,
                              (item) => item.value === selectedEligibility
                            ).label
                          : intl.formatMessage({ id: "process > none" })}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col xs={6} className={bem.e("reset-col")}>
                    <FormGroup>
                      <Label>{rule_future_eligibility.title}</Label>
                      <span className={bem.e("order-view-value")}>
                        {selectedFutureEligibility
                          ? find(
                              rule_future_eligibility.props.options,
                              (item) => item.value === selectedFutureEligibility
                            ).label
                          : intl.formatMessage({ id: "process > none" })}
                      </span>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} className={bem.e("col-reset")}>
                    <Label>
                      <span>{rule_number_of_days.title}</span>
                    </Label>
                    <div className={bem.e("rule-days-group")}>
                      <FormGroup>
                        <span className={bem.e("order-view-value")}>
                          {get(process, "data.fields.rule_number_of_days.value")
                            ? process.data.fields.rule_number_of_days.value
                            : get(process, "data.fields.rule_deadline.value")
                            ? moment(
                                process.data.fields.rule_deadline.value
                              ).format(dateFormatByServer)
                            : intl.formatMessage({ id: "process > none" })}
                        </span>
                      </FormGroup>
                    </div>
                  </Col>
                  <Col xs={6} className={bem.e("col-reset")}>
                    <FormGroup className={bem.e("rule-switch")}>
                      <Label>{enforce_forfeiture.title}</Label>
                      <ToggleSwitch
                        leftComponent={intl.formatMessage({
                          id: "process > promotion > contest > yes",
                        })}
                        rightComponent={intl.formatMessage({
                          id: "process > promotion > contest > no",
                        })}
                        name="enforce_forfeiture"
                        disabled={true}
                        switchProps={{
                          checked: !get(
                            process,
                            "data.fields.enforce_forfeiture.value"
                          ),
                          onChange: () => {},
                          offColor: "#795AFA",
                          onColor: "#E3ECF1",
                          uncheckedIcon: false,
                          checkedIcon: false,
                          handleDiameter: 22,
                          width: 50,
                          height: 26,
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Row>
                  <Col xs={6} className={bem.e("reset-col")}>
                    <FormGroup>
                      <Dropdown
                        label={
                          <span>
                            {age_restrictions.title}
                            {age_restrictions.mandatory && (
                              <Asterisk>*</Asterisk>
                            )}
                          </span>
                        }
                        name="age_restrictions"
                        placeholder={intl.formatMessage({
                          id: "process > promotion > contest > select",
                        })}
                        onChange={(selectedOption) => {
                          onValueChanged(
                            age_restrictions,
                            selectedOption.value
                          );
                        }}
                        {...age_restrictions.props}
                        value={find(
                          age_restrictions.props.options,
                          (item) => item.value === selectedAge || null
                        )}
                        error={get(
                          validationState,
                          "age_restrictions.validation_error"
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6} className={bem.e("reset-col")}>
                    <FormGroup>
                      <Label>
                        {contest_rules.title}
                        {contest_rules.mandatory && <Asterisk>*</Asterisk>}
                      </Label>
                      <div
                        className={bem.e("contest-rules")}
                        onClick={() => setIsOpenContestRulesModal(true)}
                      >
                        {
                          <FormattedMessage id="process > promotion > contest > contest rules" />
                        }
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} className={bem.e("reset-col")}>
                    <FormGroup>
                      <Dropdown
                        label={
                          <span>
                            {rule_eligibility.title}
                            {rule_eligibility.mandatory && (
                              <Asterisk>*</Asterisk>
                            )}
                          </span>
                        }
                        name="rule_eligibility"
                        placeholder={intl.formatMessage({
                          id: "process > promotion > contest > select",
                        })}
                        onChange={(selectedOption) => {
                          onValueChanged(
                            rule_eligibility,
                            selectedOption.value
                          );
                        }}
                        {...rule_eligibility.props}
                        value={find(
                          rule_eligibility.props.options,
                          (item) => item.value === selectedEligibility || null
                        )}
                        error={get(
                          validationState,
                          "rule_eligibility.validation_error"
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6} className={bem.e("reset-col")}>
                    <FormGroup>
                      <Dropdown
                        label={
                          <span>
                            {rule_future_eligibility.title}
                            {rule_future_eligibility.mandatory && (
                              <Asterisk>*</Asterisk>
                            )}
                          </span>
                        }
                        name="rule_future_eligibility"
                        placeholder={intl.formatMessage({
                          id: "process > promotion > contest > select",
                        })}
                        onChange={(selectedOption) => {
                          onValueChanged(
                            rule_future_eligibility,
                            selectedOption.value
                          );
                        }}
                        {...rule_future_eligibility.props}
                        value={find(
                          rule_future_eligibility.props.options,
                          (item) =>
                            item.value === selectedFutureEligibility || null
                        )}
                        error={get(
                          rule_future_eligibility,
                          "eligibility.validation_error"
                        )}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} className={bem.e("col-reset")}>
                    <Label>
                      <span>
                        {rule_number_of_days.title}
                        {rule_number_of_days.mandatory && (
                          <Asterisk>*</Asterisk>
                        )}
                      </span>
                    </Label>
                    <div className={bem.e("rule-days-group")}>
                      <FormGroup>
                        <TextInputFormatted
                          name={`rule_number_of_days`}
                          value={
                            process.data.fields[rule_number_of_days.field]
                              ? process.data.fields[rule_number_of_days.field]
                                  .value
                              : ""
                          }
                          placeholder={intl.formatMessage({
                            id:
                              "process > promotion > contest > number of days",
                          })}
                          onChange={(value) => {
                            onValueChanged(rule_number_of_days, value);
                          }}
                          error={get(
                            validationState,
                            "rule_number_of_days.validation_error"
                          )}
                        />
                      </FormGroup>
                      <span className={bem.e("or-text")}>
                        <FormattedMessage id="process > promotion > contest > or" />
                      </span>
                      <FormGroup>
                        <TextInput
                          type="date"
                          name="rule_deadline"
                          placeholder={intl.formatMessage({
                            id: "process > promotion > contest > deadline",
                          })}
                          min={moment().format("YYYY-MM-DD")}
                          value={
                            process.data.fields[rule_deadline.field]
                              ? process.data.fields[rule_deadline.field].value
                              : ""
                          }
                          onChange={(value) => {
                            onValueChanged(rule_deadline, value);
                          }}
                          error={get(
                            validationState,
                            "rule_deadline.validation_error"
                          )}
                          allowShowIcon
                        />
                      </FormGroup>
                    </div>
                  </Col>
                  <Col xs={6} className={bem.e("col-reset")}>
                    <FormGroup className={bem.e("rule-switch")}>
                      <Label>
                        {enforce_forfeiture.title}
                        {enforce_forfeiture.mandatory && <Asterisk>*</Asterisk>}
                      </Label>
                      <ToggleSwitch
                        leftComponent={intl.formatMessage({
                          id: "process > promotion > contest > yes",
                        })}
                        rightComponent={intl.formatMessage({
                          id: "process > promotion > contest > no",
                        })}
                        name="is_required"
                        switchProps={{
                          checked: !get(
                            process,
                            "data.fields.enforce_forfeiture.value"
                          ),
                          onChange: (checked) => {
                            onValueChanged(enforce_forfeiture, !checked);
                          },
                          offColor: "#795AFA",
                          onColor: "#E3ECF1",
                          uncheckedIcon: false,
                          checkedIcon: false,
                          handleDiameter: 22,
                          width: 50,
                          height: 26,
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </SectionFields>
        </React.Fragment>
      )}
      <ContestRulesModal
        isOpen={isOpenContestRulesModal}
        onToggle={() => setIsOpenContestRulesModal(!isOpenContestRulesModal)}
        onSubmit={(value) => {
          onValueChanged(contest_rules, value);
          setIsOpenContestRulesModal(false);
        }}
        isAllowEdit={!isView}
        value={get(process, "data.fields.contest_rules.value", "")}
      />
    </ShadowBox>
  );
};

export default ContestRules;
