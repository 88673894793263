import * as React from "react";
import { compose, withHandlers, withState } from "recompose";
import { connect } from "react-redux";
import { get, filter, pick } from "lodash";
import View from "./view";
import { injectIntl } from "react-intl";
import ToastManager from "components/ToastManager";
import { MdImportantDevices } from "react-icons/lib/md";
import {
  updateFulfillmentSettings,
  setActivateFulfillmentSettings,
  copyFulfillmentSettings,
} from "store/actions/stations";
import { PRIVILEGES } from "utils/constants";
import { validatePrivileges } from "utils/helpers";
export default injectIntl(
  compose(
    connect(
      ({ auth, servers, ...rest }) => {
        return {
          auth,
          currentServer: get(servers, "currentServer.data", {}),
          loading:
            get(rest, "stations.fulfillmentSettings.loading", false) ||
            get(rest, "stations.copyFulfillmentSettings.loading", false),
          winnerAnnouncements: get(
            rest,
            "systemTemplates.winnerAnnouncements.data",
            null
          ),
          formConfirmations: get(
            rest,
            "systemTemplates.formConfirmations.data",
            null
          ),
          prizeReleases: get(rest, "systemTemplates.prizeReleases.data", null),
          prizeFulfillments: get(
            rest,
            "systemTemplates.prizeFulfillments.data",
            null
          ),
          fulfillmentForms: get(
            rest,
            "systemForms.fulfillmentForms.data",
            null
          ),
        };
      },
      {
        updateFulfillmentSettings,
        setActivateFulfillmentSettings,
        copyFulfillmentSettings,
      }
    ),
    withState("copyConfirmationModal", "setCopyConfirmationModal", false),
    withHandlers({
      getUsers: ({ auth, currentStation }) => () => {
        const users = get(auth, "info.users");
        const items = filter(users, (user) => {
          const { stations } = user;
          const filtered = filter(stations, (station) => {
            const userPrivileges = Object.values(station.user_privileges);
            const isValid = validatePrivileges({
              requires: {
                or: [PRIVILEGES.VERIFY_WINNER_ADDRESS],
              },
              privileges: userPrivileges,
              user,
            });
            return (
              isValid && get(currentStation, "key") === station.station_key
            );
          });
          return filtered.length > 0;
        });
        return items.map((user) => ({
          label: user.name,
          value: user.id,
          data: pick(user, ["id", "name", "avatar_url"]),
        }));
      },
      onConfirmCopy: ({
        copyFulfillmentSettings,
        onGetFulfillmentSettings,
        intl,
        setCopyConfirmationModal,
        toggleIsOpen,
      }) => (values, formikHelpers) => {
        setCopyConfirmationModal(false);
        copyFulfillmentSettings(values, () => {
          formikHelpers.resetForm();
          ToastManager.show({
            title: <MdImportantDevices />,
            message: intl.formatMessage({
              id: "station settings > copy successfully",
            }),
            level: "success",
          });
          onGetFulfillmentSettings();
          toggleIsOpen();
        });
      },
      onFormSubmit: ({
        updateFulfillmentSettings,
        intl,
        setShouldBlockNavigation,
        onGetFulfillmentSettings,
        toggleIsOpen,
        setCopyConfirmationModal,
      }) => (values, formikHelpers) => {
        updateFulfillmentSettings(values, (data) => {
          if (data && data.copy_to_station_failed) {
            setCopyConfirmationModal(true);
          } else {
            formikHelpers.resetForm();
            toggleIsOpen();
          }
          ToastManager.show({
            title: <MdImportantDevices />,
            message: intl.formatMessage({
              id: "form manager > update successfully",
            }),
            level: "success",
          });
          setShouldBlockNavigation(false);
          onGetFulfillmentSettings();
        });
      },
      onSetActive: ({
        setActivateFulfillmentSettings,
        intl,
        setShouldBlockNavigation,
        fulfillmentSetting,
      }) => (active) => {
        setActivateFulfillmentSettings(
          {
            id: fulfillmentSetting.id,
            active,
          },
          () => {
            ToastManager.show({
              title: <MdImportantDevices />,
              message: intl.formatMessage({
                id: "form manager > update successfully",
              }),
              level: "success",
            });
            setShouldBlockNavigation(false);
          }
        );
      },
    })
  )(View)
);
