import React from "react";
import UserProfileForm from "components/UserProfileForm";
import Page from "components/Page";
import { FormattedMessage } from "react-intl";

const View = (props) => {
  const {
    intl,
    match: {
      params: { user_id },
    },
  } = props;

  return (
    <Page
      applyPadding={false}
      breadcrumbs={[
        {
          name: intl.formatMessage({
            id: "breadcrumbs > home",
          }),
          active: false,
        },
        {
          name: intl.formatMessage({
            id: "breadcrumbs > my profile",
          }),
          active: true,
        },
      ]}
      title={<FormattedMessage id="my profile > title" />}
    >
      <UserProfileForm id={user_id} />
    </Page>
  );
};

export default View;
