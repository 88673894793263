import React, { useEffect } from "react";
import { first, get } from "lodash";
import { Label } from "reactstrap";
import Dropdown from "components/Dropdown";
import { Asterisk } from "components/Elements";

const SelectedManager = (props) => {
  const {
    bem,
    isEnableEnterprise,
    selectedTeamProductionManager,
    selectedProductionManager,
    team_production_manager_field,
    select_production_manager_field,
    valueOrEmpty,
    options,
    onSelectedManager,
    disabled,
    error,
    intl,
    loading,
  } = props;
  useEffect(() => {
    if (
      loading ||
      get(selectedTeamProductionManager, "value", "") ||
      get(selectedProductionManager, "value", "")
    )
      return;
    if (options && options.length > 0) {
      const option =
        isEnableEnterprise && team_production_manager_field
          ? options.find((item) => item.isTeam)
          : first(options);
      if (
        !option ||
        Number(get(selectedTeamProductionManager, "value", "")) ===
          Number(option.id) ||
        Number(get(selectedProductionManager, "value", "")) ===
          Number(option.id)
      ) {
        return;
      } else if (isEnableEnterprise || get(options, "length", 0) === 1) {
        onSelectedManager(option);
      }
    }
  }, [options, loading]);
  if (options && options.length === 1) {
    return (
      <>
        <Label>{select_production_manager_field.title}</Label>
        <span className={bem.e("order-view-value")}>
          {isEnableEnterprise
            ? selectedTeamProductionManager
              ? valueOrEmpty(team_production_manager_field)
              : valueOrEmpty(select_production_manager_field)
            : valueOrEmpty(select_production_manager_field)}
        </span>
      </>
    );
  }
  const value =
    isEnableEnterprise && selectedTeamProductionManager
      ? selectedTeamProductionManager
      : selectedProductionManager;
  return (
    <Dropdown
      name="select_production_manager"
      {...select_production_manager_field.props}
      options={options}
      placeholder={intl.formatMessage({
        id: "process > placeholder select",
      })}
      label={
        <span>
          {select_production_manager_field.title}
          {select_production_manager_field.mandatory && <Asterisk>*</Asterisk>}
        </span>
      }
      value={value || ""}
      onChange={onSelectedManager}
      disabled={disabled}
      error={error}
    />
  );
};
export default SelectedManager;
