import { useEffect } from "react";
function useScrollToTopElement(containerContent, effect) {
  useEffect(() => {
    if (containerContent.current) {
      containerContent.current.scrollTo(0, 0);
    }
  }, [effect]);
  return null;
}
export default useScrollToTopElement;
