import React, { useState } from "react";
import { get } from "lodash";
import { FormattedMessage } from "react-intl";
import { Col, Row, Label } from "reactstrap";
import bn from "utils/bemnames";
import {
  SectionTitle,
  ShadowBox,
  SectionFields,
  ChevronWrapper,
} from "components/Elements";
import { has, find } from "lodash";
import { DownIcon, UpIcon } from "components/CustomIcons";
import moment from "moment";
const bem = bn.create("order-details-view");

export default function OrderDetailsView(props) {
  const { item, process, intl, dateFormatByServer } = props;
  const [isOpenSection, setIsOpenSection] = useState(false);
  const spot = find(
    get(process, "data.fields.spot_info.value", []),
    (spot) => spot.key === process.key
  );
  const valueOrEmpty = (item) => {
    let value = null;
    if (has(process, `data.fields.${item.field}.value.name`)) {
      value = process.data.fields[item.field].value.name || "";
    } else if (get(process, `data.fields.${item.field}.value`)) {
      value = process.data.fields[item.field].value;
    } else {
      return intl.formatMessage({ id: "process > none" });
    }
    return value;
  };
  const orderStartDate = get(process, "data.fields.order_start_date.value")
    ? moment(get(process, "data.fields.order_start_date.value")).format(
        dateFormatByServer
      )
    : "";

  const orderEndDate = get(process, "data.fields.order_end_date.value")
    ? moment(get(process, "data.fields.order_end_date.value")).format(
        dateFormatByServer
      )
    : "";
  return (
    <ShadowBox className={bem.b()}>
      <SectionTitle
        className={bem.e("section-title")}
        onClick={() => {
          setIsOpenSection(!isOpenSection);
        }}
      >
        {item.title}
        <ChevronWrapper>
          {isOpenSection ? <UpIcon /> : <DownIcon />}
        </ChevronWrapper>
      </SectionTitle>
      {isOpenSection ? (
        <SectionFields className={bem.e("section-fields")}>
          <Row>
            <Col className={bem.e("col-view-field")}>
              <Label>
                <FormattedMessage id="process > title estimate" />
              </Label>
              <span className={bem.e("order-view-value")}>
                {valueOrEmpty({ field: "order_title" })}
              </span>
            </Col>
            <Col className={bem.e("col-view-field")}>
              <Label className={bem.e("order-view-label")}>
                <FormattedMessage id="process > field spot type" />
              </Label>
              <span className={bem.e("order-view-value")}>
                {get(spot, "spot_type", "") ? (
                  get(spot, "spot_type", "")
                ) : (
                  <FormattedMessage id="process > none" />
                )}
              </span>
            </Col>
          </Row>

          <Row>
            <Col xs={6} className={bem.e("col-view-field")}>
              <Label className={bem.e("order-view-label")}>
                <FormattedMessage id="process > field order start date" />
              </Label>
              <span className={bem.e("order-view-value")}>
                {orderStartDate || intl.formatMessage({ id: "process > none" })}
              </span>
            </Col>
            <Col xs={6} className={bem.e("col-view-field")}>
              <Label className={bem.e("order-view-label")}>
                <FormattedMessage id="process > field order end date" />
              </Label>
              <span className={bem.e("order-view-value")}>
                {orderEndDate || intl.formatMessage({ id: "process > none" })}
              </span>
            </Col>
          </Row>

          <Row>
            <Col xs={6} className={bem.e("col-view-field")}>
              <Label className={bem.e("order-view-label")}>
                <FormattedMessage id="process > field isci / ad id" />
              </Label>
              <span className={bem.e("order-view-value")}>
                {get(spot, "isci", "") ? (
                  get(spot, "isci", "")
                ) : (
                  <FormattedMessage id="process > none" />
                )}
              </span>
            </Col>
            <Col xs={6} className={bem.e("col-view-field")}>
              <Label className={bem.e("order-view-label")}>
                <FormattedMessage id="process > field cart number" />
              </Label>
              <span className={bem.e("order-view-value")}>
                {get(spot, "cart_number", "") ? (
                  get(spot, "cart_number", "")
                ) : (
                  <FormattedMessage id="process > none" />
                )}
              </span>
            </Col>
          </Row>
        </SectionFields>
      ) : null}
    </ShadowBox>
  );
}
