import React from "react";
import cx from "classnames";
import { FormattedMessage } from "react-intl";
import Checkbox from "components/Checkbox";
import { ShadowBox, SectionTitle } from "components/Elements";
import ToggleSwitch from "components/ToggleSwitch";

const StationProductSection = ({
  bem,
  checked,
  onSwitchToggle,
  title,
  sectionClass,
  intl,
  showMultiMarket,
  multiMarketChecked,
}) => {
  return (
    <ShadowBox className={cx(sectionClass, bem.e("box-section"))}>
      <SectionTitle className={cx(bem.e("section-title"))}>
        <span>{title}</span>
        {showMultiMarket && (
          <div
            className={bem.e("section-mm-checkbox")}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Checkbox
              checked={multiMarketChecked}
              text={intl.formatMessage({
                id: "server > multi market",
              })}
              checkStyle={{ color: "#fff", width: "15", height: "15" }}
              disabled
            />
          </div>
        )}
        <div
          className={cx("ml-auto", bem.e("section-toggle-switch"))}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <ToggleSwitch
            leftComponent={<FormattedMessage id="server > switch > off" />}
            rightComponent={<FormattedMessage id="server > switch > on" />}
            switchProps={{
              checked,
              onChange: onSwitchToggle,
              disabled: false,
              uncheckedIcon: false,
              checkedIcon: false,
              offColor: "#C2D4E0",
              onColor: "#795AFA",
              handleDiameter: 22,
              width: 50,
              height: 26,
            }}
          />
        </div>
      </SectionTitle>
    </ShadowBox>
  );
};

export default StationProductSection;
