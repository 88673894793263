import React, { useState } from "react";
import { Row, Col, Label } from "reactstrap";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { delay, get, find } from "lodash";
import SelectAudience from "components/SelectAudience";
import { ShadowBox, SectionTitle, SectionFields } from "components/Elements";
import ConfirmationModal from "components/ConfirmationModal";

export default function AudienceDetails(props) {
  const {
    validationState,
    process,
    bem,
    step,
    onValueTimezoneChanged,
    onValueChanged,
    intl,
    isView,
    onGetClocksData,
    template,
    onGetFulfillmentSettings,
  } = props;
  const [
    isOpenChangeStationConfirmation,
    setIsOpenChangeStationConfirmation,
  ] = useState(false);
  const [currentSelectedStation, setCurrentSelectedStation] = useState(null);
  const [fieldRefs, setFieldRefs] = useState(null);
  const getFieldRefs = () => {
    if (fieldRefs) return fieldRefs;

    const select_stations = step.fields.find(
      (f) => f.field === "order_stations"
    );
    const contract_stations = step.fields.find(
      (f) => f.field === "contract_stations"
    );
    const order_stations_array = step.fields.find(
      (f) => f.field === "order_stations_array"
    );
    const winner_fulfillment_intro_text = step.fields.find(
      (f) => f.field === "winner_fulfillment_intro_text"
    );
    const payload = {
      select_stations,
      contract_stations,
      order_stations_array,
      winner_fulfillment_intro_text,
    };

    setFieldRefs(payload);

    return payload;
  };

  const selectedStation = get(
    process,
    "data.fields.order_stations.value.0",
    ""
  );
  const selectedStationData = get(
    process,
    "data.fields.order_stations_array.value.0",
    ""
  );
  const {
    select_stations,
    contract_stations,
    order_stations_array,
    winner_fulfillment_intro_text,
  } = getFieldRefs();
  const onSelectStation = (selectedOptions) => {
    const stationData = find(
      props.auth.info.stations,
      (item) => item.station_key === selectedOptions.value
    );
    const newStations = [selectedOptions.value];
    onValueChanged(select_stations, newStations);
    onValueChanged(contract_stations, newStations);
    onValueChanged(order_stations_array, [selectedOptions]);
    onValueChanged(
      winner_fulfillment_intro_text,
      stationData.winner_fulfillment_intro_text || ""
    );
    onValueTimezoneChanged(newStations);
    onGetClocksData(selectedOptions.key);
    onGetFulfillmentSettings(selectedOptions.key);
  };
  return (
    <ShadowBox className={bem.e("box-audience-details")}>
      <SectionTitle className={bem.e("section-title")}>
        <FormattedMessage id="process > title audience details" />
      </SectionTitle>
      <SectionFields
        className={classnames(bem.e("section-fields"), {
          [bem.e("section-audience")]: !isView,
          [bem.e("section-audience-view")]: isView,
        })}
      >
        <Row>
          {!isView ? (
            <Col className={bem.e("col-reset")}>
              <SelectAudience
                validationState={validationState}
                item={select_stations}
                name="select_stations"
                {...select_stations.props}
                label={select_stations.title}
                required={select_stations.mandatory}
                placeholder={intl.formatMessage({
                  id:
                    "process > promotion > contest > placeholder select station",
                })}
                isMulti={false}
                value={selectedStation}
                isGroup={true}
                template={template}
                isFilterByClock={true}
                isFilterByServerConfig={true}
                allowSelectFullOption
                onChange={(selectedOptions) => {
                  if (props.shouldBlockNavigation && selectedStation) {
                    setCurrentSelectedStation(selectedOptions);
                    setIsOpenChangeStationConfirmation(true);
                  } else {
                    onSelectStation(selectedOptions);
                  }
                }}
              />
              {/* change station confirmation modal */}
              <ConfirmationModal
                isOpen={isOpenChangeStationConfirmation}
                onToggle={() => setIsOpenChangeStationConfirmation(false)}
                onCancel={() => setIsOpenChangeStationConfirmation(false)}
                confirmTitle={<FormattedMessage id="process > button yes" />}
                cancelTitle={<FormattedMessage id="process > button cancel" />}
                title={
                  <FormattedMessage id="process > promotion > contest > title change station confirmation" />
                }
                description={
                  <FormattedMessage id="process > promotion > contest > title change station description" />
                }
                onConfirm={() => {
                  onSelectStation(currentSelectedStation);
                  delay(() => props.onResetFormData(), 500);
                  setIsOpenChangeStationConfirmation(false);
                }}
                isCloseOutside={false}
              />
            </Col>
          ) : (
            <Col className={classnames(bem.e("col-reset"))}>
              <Label className={bem.e("order-view-label")}>
                <FormattedMessage id="process > advertiser name" />
              </Label>
              <span className={bem.e("order-view-value")}>
                {selectedStationData
                  ? selectedStationData.name
                  : intl.formatMessage({ id: "process > none" })}
              </span>
            </Col>
          )}
        </Row>
      </SectionFields>
    </ShadowBox>
  );
}
