import React, { useEffect, useState } from "react";
import bn from "utils/bemnames";
import { Button } from "reactstrap";
import { get, find, isEmpty, take } from "lodash";
import ToastManager from "components/ToastManager";
import Spinner from "components/Spinner";
import { firstUndoneStep } from "utils/helpers";
import FeedbackSuccessModal from "components/FeedbackSuccessModal";
import ConfirmationModal from "components/ConfirmationModal";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import UploadFile from "components/UploadFile";
import classnames from "classnames";
import Chat from "components/NotificationsPusher";
import { TimeStampHelpIcon } from "components/CustomIcons";
import OrderInfo from "../OrderInfo";
import ReviewLayout from "../ReviewLayout";
import { ApproveIcon, TrashIcon } from "components/CustomIcons";
import useWindowDimensions from "utils/useWindowDimensions";
import Checkbox from "components/Checkbox";

const bem = bn.create("client-review");
const ActionButtons = (props) => {
  const {
    actionStatus,
    isDisabled,
    setActionStatus,
    onClickApprove,
    process,
  } = props;
  const onClickReject = () => {
    setActionStatus("rejected");
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };
  return (
    <div className={bem.e("buttons-container")}>
      <Button
        color="blue"
        type="submit"
        className={classnames(bem.e("button-approve"), bem.e("button-review"), {
          [bem.e("button-approved")]: actionStatus === "approved",
        })}
        outline
        disabled={isDisabled}
        onClick={() => {
          if (isDisabled) return;
          setActionStatus("approved");
          onClickApprove(process);
        }}
        // style={buttonStyles}
      >
        <span className={bem.e("button-icon")}>
          <ApproveIcon color="#fff" />
        </span>
        <FormattedMessage id="process > button approve" />
      </Button>
      <Button
        type="button"
        color=""
        disabled={isDisabled}
        className={classnames(bem.e("button-reject"), bem.e("button-review"), {
          [bem.e("button-rejected")]: actionStatus === "rejected",
        })} // style={invertButtonStyles}
        onClick={() => {
          if (isDisabled) return;
          onClickReject();
          // onClickReject(process);
        }}
      >
        <span className={bem.e("button-icon")}>
          <TrashIcon />
        </span>
        <FormattedMessage id="process > button request changes" />
      </Button>
    </div>
  );
};
function SpotReview(props) {
  const {
    process,
    template,
    intl,
    extraReviewProcess,
    onGetReviewProcess,
    messages,
    dateFormatByServer,
  } = props;
  const { width: widthScreen } = useWindowDimensions();
  const isMobile = widthScreen <= 480;
  const [isCheckedDontShowModal, setIsCheckedDontShowModal] = useState(
    localStorage.getItem("is_disabled_confirm_feedback") === "true"
  );
  const [loading, setLoading] = useState(false);
  const [currentMessage, setCurrentMessage] = useState(null);
  const [isFeedbackSuccessModal, setIsFeedbackSuccessModal] = useState(false);
  const [feedbackAction, setFeedbackAction] = useState(null);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [isViewAllStations, setIsViewAllStations] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [commentTextB, setCommentTextB] = useState("");

  const [isClearInput, setIsClearInput] = useState(false);
  const [actionStatus, setActionStatus] = useState("");

  const clientName = get(process, "data.fields.client_name.value");
  const userSale = get(extraReviewProcess, "sale");
  const stationsData = get(extraReviewProcess, "stations", []);
  const stationsDataName = !isViewAllStations
    ? take(stationsData, 3).map((i) => i.name)
    : stationsData.map((i) => i.name);
  const processTitle = get(process, "data.fields.order_title.value");
  const orderStartDate = get(process, "data.fields.order_start_date.value")
    ? moment(get(process, "data.fields.order_start_date.value")).format(
        dateFormatByServer
      )
    : "";

  const orderEndDate = get(process, "data.fields.order_end_date.value")
    ? moment(get(process, "data.fields.order_end_date.value")).format(
        dateFormatByServer
      )
    : "";

  let recordingPath =
    get(process, `data.fields.recording_path.value`, "") &&
    !get(process, `data.fields.recording_path.is_dirty`, false)
      ? get(process, `data.fields.recording_path.value`, "")
      : get(process, "data.fields.recording_feedback.value.recording_path", "");

  let recordingPathB =
    get(process, `data.fields.recording_path_b.value`, "") &&
    !get(process, `data.fields.recording_path_b.is_dirty`, false)
      ? get(process, `data.fields.recording_path_b.value`, "")
      : get(
          process,
          "data.fields.recording_feedback.value.recording_path_b",
          ""
        );

  const user = {
    name: clientName,
    id: -1,
  };

  const spot = find(
    get(process, "data.fields.spot_info.value", []),
    (spot) => spot.key === process.key
  );

  const isBookend = get(spot, "spot_type", "Standard") === "Book-ends";

  const isRecordingFeedback =
    get(process, "data.fields.process_step.value", "") ===
      "approve_recording" ||
    get(process, "data.fields.approve_recording_status.value", "");
  const approveRecordingStatus = get(
    process,
    "data.fields.approve_recording_status.value",
    ""
  );
  const recordScriptStatus = get(
    process,
    "data.fields.record_script_status.value",
    ""
  );
  const step = find(template.steps, (item) => item.key === "approve_recording");

  let recordingFeedback = get(
    process,
    "data.fields.recording_feedback.value",
    {}
  );
  const isClientReviewed =
    (recordScriptStatus === "rejected" &&
      approveRecordingStatus === "rejected") ||
    (recordScriptStatus === "completed" &&
      approveRecordingStatus === "approved");
  useEffect(() => {
    if (approveRecordingStatus !== actionStatus && isClientReviewed) {
      setActionStatus(approveRecordingStatus);
    }
  }, [approveRecordingStatus]);

  const buttonClicked = (
    step,
    item,
    process,
    template,
    user,
    event,
    callback,
    isForce = false,
    noSuccessMessage = false
  ) => {
    if (!callback) setLoading(true);

    item.fields.map((field) => {
      if (typeof field.value === "undefined") {
        return false;
      }

      let value = field.value;

      if (typeof value === "function") {
        value = field.value(
          user,
          template,
          process,
          step,
          item,
          get(process, "data.fields.next_users.value", [])
        );
      }

      if (field.key === "process_step" && value === "first_undone") {
        value = firstUndoneStep(user, template, process, step, item);
      }

      props.formValueChanged({
        process,
        template,
        item: { field: field.key },
        user,
        value,
      });
      process.data.fields[field.key] = { value: value, is_dirty: true };
      return false;
    });

    let success_callback = () => {
      setIsFeedbackSuccessModal(true);
      setIsClearInput(true);
      setCommentText("");
      setCommentTextB("");
      onGetReviewProcess({ spotSwitching: true });
      setLoading(false);
    };

    if (callback) {
      success_callback = callback;
    }
    const recomputeFields = props.getRecomputeFields(
      step,
      item,
      process,
      template,
      user
    );
    process.data.fields.all_changed_users = {
      value: get(recomputeFields, "all_changed_users", []),
      is_dirty: true,
    };
    let saveFormValues = {
      process,
      template,
      step,
      user,
      success_callback,
      valid: true,
      isForce,
    };

    saveFormValues.meta = {
      ...get(saveFormValues, "meta", {}),
      is_client_review: true,
    };

    props.saveFormValues(saveFormValues);
  };

  let messagesToShow = messages.filter(({ message }) => {
    let process_key = process.key
      ? process.key
      : process.data.fields.key && process.data.fields.key.value
      ? process.data.fields.key.value
      : "-1";
    let message_process_key = message.process
      ? message.process
      : message.process_key;

    if (
      message.chat &&
      message.m_type === "recording_feedback" &&
      ((message_process_key && message_process_key === process_key) ||
        message_process_key === process_key ||
        message.process_key === "current") &&
      message.recording_path === recordingPath &&
      message.path === "a" &&
      isRecordingFeedback
    ) {
      return true;
    }
  });

  let messagesToShowB = messages.filter(({ message }) => {
    let process_key = process.key
      ? process.key
      : process.data.fields.key && process.data.fields.key.value
      ? process.data.fields.key.value
      : "-1";
    let message_process_key = message.process
      ? message.process
      : message.process_key;

    if (
      message.chat &&
      message.m_type === "recording_feedback" &&
      ((message_process_key && message_process_key === process_key) ||
        // message_process_key === process_key ||
        message.process_key === "current") &&
      message.recording_path === recordingPathB &&
      message.path === "b" &&
      isRecordingFeedback
    ) {
      return true;
    }
  });

  const warningUserEnterComments = () => {
    // commentText
    ToastManager.show({
      message: intl.formatMessage({
        id: "process > title warning submit the comment",
      }),
      autoDismiss: 1.5,
      level: "error",
    });
  };
  const onClickApprove = (process) => {
    const item = step.fields.find((f) => f.field === "approve");
    setFeedbackAction("approve");
    buttonClicked(step, item, process, template, user, null, null, true);
  };

  const onClickReject = (process) => {
    const item = step.fields.find((f) => f.field === "submit_feedback");
    setFeedbackAction("reject");
    if (isRecordingFeedback && !currentMessage) {
      if (isBookend) {
        if (isEmpty(messagesToShow) && isEmpty(messagesToShowB)) {
          setIsWarningModalOpen(true);
          return;
        }
      } else {
        if (isEmpty(messagesToShow)) {
          setIsWarningModalOpen(true);
          return;
        }
      }
    }

    // if (!isEmpty(commentText) || !isEmpty(commentTextB)) {
    //   warningUserEnterComments();
    //   return;
    // }
    // we need to set recording_feedback from config template
    const requestChangesItem = step.fields.find(
      (f) => f.field === "request_changes"
    );

    requestChangesItem.fields.map((field) => {
      if (typeof field.value === "undefined") {
        return false;
      }

      let value = field.value;

      if (typeof value === "function") {
        value = field.value(
          user,
          template,
          process,
          step,
          item,
          get(process, "data.fields.next_users.value", [])
        );
      }

      if (field.key === "process_step" && value === "first_undone") {
        value = firstUndoneStep(user, template, process, step, item);
      }

      props.formValueChanged({
        process,
        template,
        item: { field: field.key },
        user,
        value,
      });
      process.data.fields[field.key] = { value: value, is_dirty: true };
      return false;
    });
    const isValidSubmit = currentMessage
      ? true
      : !isEmpty(messagesToShow) || !isEmpty(messagesToShowB);
    if (isValidSubmit) {
      buttonClicked(
        step,
        item,
        process,
        template,
        user,
        null,
        null,
        true,
        false
      );
    }
  };

  const onClickSendMessage = (message, cb) => {
    setCurrentMessage(message);
    if (isCheckedDontShowModal) {
      onClickReject(process);
    } else {
      setIsConfirmModalOpen(true);
    }
  };

  const onSendMessage = (process, isSubmitFeedback) => {
    const { saveFormValues } = props;
    // clear input
    setIsClearInput(true);

    const success_callback = () => {
      setCurrentMessage(null);
      // clear input state for next comment
      setIsClearInput(false);
      if (isSubmitFeedback) {
        onClickReject(process);
      }
    };
    saveFormValues({
      process,
      template,
      step,
      user,
      success_callback,
      valid: true,
      chat_message: currentMessage,
      recordingFeedback: recordingFeedback,
      scriptFeedback: false,
      clientReview: true,
    });
  };
  // const onClickSubmitFeedback = () => {
  //   if (isCheckedDontShowModal) {
  //     onClickReject(process);
  //   } else {
  //     setIsConfirmModalOpen(true);
  //   }
  // };
  const isDisabledSubmitFeedbackButton = () => {
    if (isClientReviewed) return true; // client approved/rejected
    if (isBookend) {
      if (isEmpty(messagesToShow) && isEmpty(messagesToShowB)) {
        return true;
      }
    } else {
      if (isEmpty(messagesToShow)) {
        return true;
      }
    }
    return false;
  };
  const isDisabled =
    get(process, "data.fields.process_step.value", "") !== "approve_recording";

  const renderReviewContent = () => (
    <div
      className={classnames(
        bem.e("review-content"),
        bem.e("production-review"),
        bem.e("spot-production-review")
      )}
    >
      <div className={classnames(bem.e("p-review-left"), bem.e("review-left"))}>
        {!isMobile && (
          <ActionButtons
            actionStatus={actionStatus}
            isDisabled={isDisabled}
            process={process}
            setActionStatus={setActionStatus}
            onClickApprove={onClickApprove}
          />
        )}
        <div className={bem.e("final-script-recording")}>
          {recordingPath && (
            <div className={bem.e("recording")}>
              {!isBookend && (
                <div className={bem.e("script-recording-header")}>
                  <FormattedMessage id="process > field recording" />
                </div>
              )}
              <div className="final-recording">
                <UploadFile
                  positionActions="bottom"
                  mode={"view"}
                  showRemoveIcon={false}
                  process={process}
                  url={recordingPath}
                  isShowFileNameInEditMode={true}
                  isBookend={isBookend}
                  path={isBookend ? "a" : ""}
                  actions={["download"]}
                  isBubble={true}
                  hideSpotLength={true}
                  hideFileName={true}
                >
                  <FormattedMessage id="process > button upload" />
                </UploadFile>
              </div>
            </div>
          )}
        </div>
        {isBookend && (
          <div className={bem.e("final-script-recording")}>
            {recordingPathB && (
              <div className={bem.e("recording")}>
                {!isBookend && (
                  <div className={bem.e("script-recording-header")}>
                    <FormattedMessage id="process > field recording" />
                  </div>
                )}
                <div className="final-recording">
                  <UploadFile
                    positionActions="bottom"
                    mode={"view"}
                    showRemoveIcon={false}
                    process={process}
                    url={recordingPathB}
                    isShowFileNameInEditMode={true}
                    isBookend={isBookend}
                    path={isBookend ? "b" : ""}
                    actions={["download"]}
                    isBubble={true}
                    hideSpotLength={true}
                    hideFileName={true}
                  >
                    <FormattedMessage id="process > button upload" />
                  </UploadFile>
                </div>
              </div>
            )}
          </div>
        )}
        {isMobile && (
          <ActionButtons
            actionStatus={actionStatus}
            isDisabled={isDisabled}
            process={process}
            setActionStatus={setActionStatus}
            onClickApprove={onClickApprove}
          />
        )}
        {actionStatus === "rejected" ? (
          <React.Fragment>
            <div className={bem.e("comments-header")}>
              <FormattedMessage id="process > title feedback" />
            </div>
            <div className={bem.e("comments-help")}>
              <FormattedMessage id={"process > recording comments help text"} />
            </div>
            <div
              className={classnames(bem.e("comments-container"), {
                "no-message": isEmpty(messagesToShow),
              })}
            >
              {isBookend && (
                <div className={bem.e("comment-part-title")}>
                  <FormattedMessage id="process > part A" />
                </div>
              )}
              <Chat
                step={step}
                process={process}
                template={template}
                user={user}
                defaultExpand={true}
                onToggleCallback={() => {}}
                recordingFeedback={isRecordingFeedback}
                scriptFeedback={false}
                chatInputPlaceHolder={intl.formatMessage({
                  id: "process > client feedback input placeholder",
                })}
                chatInputDisabled={isClientReviewed}
                checkBoxIconStyle={{ width: "14px", height: "14px" }}
                showEmojiPicker={false}
                position="left"
                boxStyle={{ width: "100%" }}
                recordingUrl={isRecordingFeedback ? recordingPath : ""}
                path={isBookend ? "a" : ""}
                clientReview={true}
                onInputChange={(value) => {
                  setCommentText(value);

                  if (isRecordingFeedback) {
                    let newFeedback = {};

                    if (!get(process, "data.fields.recording_path.value", "")) {
                      newFeedback = { ...recordingFeedback, feedback: value };
                    } else {
                      newFeedback = {
                        ...recordingFeedback,
                        feedback: value,
                        recording_path: get(
                          process,
                          "data.fields.recording_path.value",
                          ""
                        ),
                        recording_audit: get(
                          process,
                          "data.fields.recording_audit.value",
                          null
                        ),
                      };
                    }

                    props.formValueChanged({
                      process,
                      template,
                      item: { field: "recording_feedback" },
                      user,
                      value: newFeedback,
                    });
                  }
                }}
                clearInput={isClearInput}
                onTimeStampSelect={(checked) => {
                  props.formValueChanged({
                    process,
                    template,
                    item: { field: "recording_feedback" },
                    user,
                    value: { ...recordingFeedback, include_timestamp: checked },
                  });
                }}
                onSubmit={isCheckedDontShowModal ? null : onClickSendMessage}
                successCallback={() => setCommentText("")}
                isKeepInputValueAfterClickSend={
                  isCheckedDontShowModal ? false : true
                }
              />
            </div>
            {isBookend && (
              <div
                className={classnames(bem.e("comments-container"), {
                  "no-message": isEmpty(messagesToShowB),
                })}
              >
                {isBookend && (
                  <div className={bem.e("comment-part-title")}>
                    <FormattedMessage id="process > part B" />
                  </div>
                )}
                <Chat
                  step={step}
                  process={process}
                  template={template}
                  user={user}
                  defaultExpand={true}
                  onToggleCallback={() => {}}
                  recordingFeedback={isRecordingFeedback}
                  scriptFeedback={false}
                  chatInputPlaceHolder={intl.formatMessage({
                    id: "process > client feedback input placeholder",
                  })}
                  chatInputDisabled={isClientReviewed}
                  checkBoxIconStyle={{ width: "14px", height: "14px" }}
                  showEmojiPicker={false}
                  position="left"
                  boxStyle={{ width: "100%" }}
                  recordingUrl={isRecordingFeedback ? recordingPathB : ""}
                  path={isBookend ? "b" : ""}
                  clientReview={true}
                  onInputChange={(value) => {
                    setCommentTextB(value);

                    if (isRecordingFeedback) {
                      let newFeedback = {};

                      if (
                        !get(process, "data.fields.recording_path_b.value", "")
                      ) {
                        newFeedback = {
                          ...recordingFeedback,
                          recording_path_b_feedback: value,
                        };
                      } else {
                        newFeedback = {
                          ...recordingFeedback,
                          recording_path_b_feedback: value,
                          recording_path_b: get(
                            process,
                            "data.fields.recording_path_b.value",
                            ""
                          ),
                          recording_path_b_audit: get(
                            process,
                            "data.fields.recording_path_b_audit.value",
                            null
                          ),
                        };
                      }

                      props.formValueChanged({
                        process,
                        template,
                        item: { field: "recording_feedback" },
                        user,
                        value: newFeedback,
                      });
                    }
                  }}
                  clearInput={isClearInput}
                  onTimeStampSelect={(checked) => {
                    props.formValueChanged({
                      process,
                      template,
                      item: { field: "recording_feedback" },
                      user,
                      value: {
                        ...recordingFeedback,
                        include_recording_path_b_timestamp: checked,
                      },
                    });
                  }}
                  onSubmit={isCheckedDontShowModal ? null : onClickSendMessage}
                  successCallback={() => setCommentTextB("")}
                  isKeepInputValueAfterClickSend={
                    isCheckedDontShowModal ? false : true
                  }
                />
              </div>
            )}
          </React.Fragment>
        ) : null}
        {actionStatus === "rejected" ? (
          <div className={bem.e("submit-all-feedback")}>
            <Button
              color="blue"
              type="submit"
              disabled={isDisabledSubmitFeedbackButton()}
              className="btn btn-radius btn-submit-script-review"
              onClick={() => onClickReject(process)}
            >
              <FormattedMessage id="process > button submit feedback" />
            </Button>
          </div>
        ) : null}
      </div>
      <div
        className={classnames(bem.e("review-right"), bem.e("p-review-right"))}
      >
        <React.Fragment>
          <OrderInfo
            isViewAllStations={isViewAllStations}
            bem={bem}
            clientName={clientName}
            processTitle={processTitle}
            stationsDataName={stationsDataName}
            stationsData={stationsData}
            setIsViewAllStations={setIsViewAllStations}
            orderStartDate={orderStartDate}
            orderEndDate={orderEndDate}
            helperText={intl.formatMessage({
              id:
                "process > please review recording, and approve or request changes",
            })}
          />
          <div className={bem.e("timestamp-helper")}>
            <TimeStampHelpIcon />
          </div>
        </React.Fragment>
      </div>
    </div>
  );
  return (
    <ReviewLayout
      extraReviewProcess={extraReviewProcess}
      renderReviewContent={renderReviewContent}
    >
      <Spinner isLoading={process.loading || loading} />
      <FeedbackSuccessModal
        isOpen={isFeedbackSuccessModal}
        user={userSale}
        title={
          feedbackAction === "reject" ? (
            <FormattedMessage id="process > client reject title" />
          ) : (
            <FormattedMessage id="process > client approval title" />
          )
        }
        description={
          feedbackAction === "reject" ? (
            <FormattedMessage id="process > client reject description" />
          ) : (
            <FormattedMessage id="process > client approval description" />
          )
        }
        isAutoClose
        onToggle={() => {
          setIsWarningModalOpen(false);
          setIsFeedbackSuccessModal(false);
        }}
        onCancel={() => {
          setIsWarningModalOpen(false);
          setIsFeedbackSuccessModal(false);
        }}
      />
      <ConfirmationModal
        isOpen={isWarningModalOpen}
        centered
        isCloseOutside={false}
        onToggle={() => {
          setIsClearInput(false);
          setIsWarningModalOpen(false);
        }}
        onConfirm={() => {
          setIsClearInput(false);
          setIsWarningModalOpen(false);
        }}
        title={
          <FormattedMessage id="process > leave feedback with your change request" />
        }
        className={"client-review-confirmation"}
        confirmTitle={<FormattedMessage id="process > button ok" />}
        description={null}
        cancelTitle={null}
      />
      <ConfirmationModal
        isOpen={isConfirmModalOpen}
        centered
        isCloseOutside={false}
        onToggle={() => {
          setIsConfirmModalOpen(false);
        }}
        onConfirm={() => {
          onSendMessage(process, true);
          setIsConfirmModalOpen(false);
        }}
        onCancel={() => {
          onSendMessage(process, false);
          setIsConfirmModalOpen(false);
        }}
        title={
          <FormattedMessage id="process > are you ready to submit your feedback" />
        }
        className={"client-review-confirmation-feedback"}
        confirmTitle={<FormattedMessage id="process > button submit" />}
        description={
          <div>
            <p>
              <FormattedMessage id="process > if you are done, click submit" />
            </p>
            <FormattedMessage id="process > if you have more comments, click close to return the feedback screen" />
          </div>
        }
        descriptionBottom={
          <Checkbox
            checked={isCheckedDontShowModal}
            text={intl.formatMessage({
              id: "process > dont show this to me again",
            })}
            onChange={(checked) => {
              setIsCheckedDontShowModal(checked);
              localStorage.setItem("is_disabled_confirm_feedback", checked);
            }}
          />
        }
        cancelTitle={<FormattedMessage id="process > button close" />}
      />
    </ReviewLayout>
  );
}
export default SpotReview;
