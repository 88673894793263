import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody } from "reactstrap";
import bn from "utils/bemnames";
import { CloseIcon, DownIcon } from "components/CustomIcons";

import { FormattedMessage } from "react-intl";
import formFields from "./fields.json";
import winnerFulfillmentFields from "./winnerFulfillment.json";
import masterListenerCreateFields from "./masterListenerCreate.json";
import masterListenerFields from "./masterListener.json";
import designElementFields from "./designElement.json";
import { get, map, filter, find } from "lodash";
import classNames from "classnames";
const bem = bn.create("fields-form-builder-modal");
function getFields({ list, validKeyFields }) {
  let fields = [];
  switch (list) {
    case "designElement":
      fields = designElementFields;
      break;
    case "masterListener":
      if (validKeyFields) {
        fields = map(masterListenerFields, (parentField) => {
          return {
            ...parentField,
            children: parentField.children.filter((item) => {
              return find(validKeyFields, (i) => i === item.key);
            }),
          };
        }).filter((parentField) => parentField.children.length > 0);
      } else {
        fields = masterListenerFields;
      }
      break;
    case "masterListenerCreate":
      fields = masterListenerCreateFields;
      break;
    case "winnerFulfillment":
      fields = winnerFulfillmentFields;
      break;
    default:
      fields = formFields;
  }
  return fields;
}
const FieldsFormBuilderModal = ({
  isOpen,
  onToggle,
  onSelect,
  list,
  disabledFields,
  validKeyFields,
}) => {
  const fields = getFields({
    list,
    validKeyFields,
  });
  const [isOpenGroup, setIsOpenGroup] = useState(null);
  const selectField = (field) => {
    if (field.all_group) {
      const childrenFields = isOpenGroup
        ? get(
            find(fields, (item) => item.key === isOpenGroup),
            "children",
            []
          )
        : fields;
      const selectGroup = field.all_group;
      const groupFields = filter(
        childrenFields,
        (item) => item.group === selectGroup
      );
      // Case: group home address, business
      if (field.multi_field) {
        onSelect({
          ...field,
          sub_fields: groupFields,
        });
      } else {
        // Case: demographics -> we just add all children fields and uniq with current selected fields
        const filteredFields = groupFields.filter((item) => {
          return !find(disabledFields, (i) => i === item.key);
        });
        onSelect(filteredFields);
      }
    } else {
      onSelect(field);
    }
  };
  const ItemElement = ({ item, disabled }) => {
    return (
      <div
        className={classNames(bem.e("field-name"), {
          [bem.e("field-name-disabled")]: disabled,
        })}
        onClick={() => {
          if (disabled) return;
          selectField(item);
        }}
      >
        <FormattedMessage id={`form builder > fields ${item.label}`} />
      </div>
    );
  };
  const filteredFields = fields.filter((field) => {
    if (list !== "masterListener") return true;
    if (field.children) {
      const childrenFields = field.children
        ? filter(field.children, (item) => {
            if (list !== "masterListener") return true;
            return !find(disabledFields, (i) => i === item.key);
          })
        : [];
      return childrenFields.length > 0;
    }
    return !find(disabledFields, (i) => i === field.key);
  });
  return (
    <>
      <Modal isOpen={isOpen} className={bem.b()} size="sm">
        <ModalBody className={bem.e("body")}>
          <div className={bem.e("title")}>
            <FormattedMessage id="form builder > modal title" />
            <div className={bem.e("close")} onClick={onToggle}>
              <CloseIcon />
            </div>
          </div>
          <div className={bem.e("fields")}>
            {filteredFields.length > 0 ? (
              filteredFields.map((field, index) => {
                if (field.section) {
                  return (
                    <div className={bem.e("section-head")}>
                      <FormattedMessage
                        id={`form builder > fields ${field.label}`}
                      />
                    </div>
                  );
                }
                if (field.isSection) {
                  return (
                    <div key={index} className={bem.e("field-section")}>
                      <ItemElement item={field} />
                    </div>
                  );
                }
                if (field.children) {
                  const childrenFields = field.children
                    ? filter(field.children, (item) => {
                        if (list !== "masterListener") return true;
                        return !find(disabledFields, (i) => i === item.key);
                      })
                    : [];
                  const isOpen = isOpenGroup === field.key;
                  return (
                    <div className={bem.e("group")} key={field.key}>
                      <div
                        className={bem.e("group-title")}
                        onClick={() => {
                          if (isOpen) {
                            setIsOpenGroup(null);
                          } else {
                            setIsOpenGroup(field.key);
                          }
                        }}
                      >
                        <div
                          className={classNames(bem.e("group-icon"), {
                            [bem.e("group-icon-active")]: isOpen,
                          })}
                        >
                          <DownIcon />
                        </div>
                        <FormattedMessage
                          id={`form builder > fields ${field.label}`}
                        />
                      </div>
                      {isOpen && (
                        <div className={bem.e("group-fields")}>
                          {childrenFields.map((item, index2) => {
                            return <ItemElement key={index2} item={item} />;
                          })}
                        </div>
                      )}
                    </div>
                  );
                }
                return <ItemElement key={index} item={field} />;
              })
            ) : (
              <span className={bem.e("empty-fields")}>
                <FormattedMessage id="form builder > empty fields" />
              </span>
            )}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

FieldsFormBuilderModal.propTypes = {
  onToggle: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  list: PropTypes.oneOf([
    "fields",
    "masterListener",
    "masterListenerCreate",
    "designElement",
  ]),
  validKeyFields: PropTypes.any,
};
FieldsFormBuilderModal.defaultProps = {
  isOpen: false,
  onToggle: () => {},
  onSelect: () => {},
  disabledFields: [],
  list: "fields",
  validKeyFields: null,
};
export default FieldsFormBuilderModal;
