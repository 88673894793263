import React from "react";
import { FormattedMessage } from "react-intl";
import { Label } from "reactstrap";
import classnames from "classnames";
import { map, get, concat } from "lodash";
import { Row, Col } from "reactstrap";
import HandleAssets from "components/HandleAssets";
import TextInput from "components/TextInput";
import UploadFiles from "components/UploadFiles";
import { IconsConfig } from "components/ChannelManager/IconSelector";
import { UpIcon, DownIcon } from "components/CustomIcons";
import bn from "utils/bemnames";
const bem = bn.create("digital-report");
function ChannelContentView(props) {
  const { channel } = props;
  const reportFiles = get(channel, "report_files", []);
  return (
    <div className={bem.e("channel-content-view")}>
      <Row>
        <Col xs={12} className={bem.e("col-view-field")}>
          <Label className={bem.e("view-field-label")}>
            <FormattedMessage id="process > report" />
          </Label>
          {reportFiles.length > 0 ? (
            <HandleAssets
              files={reportFiles}
              viewDisabled={false}
              renamingDisabled={true}
              renamingHidden={true}
              deleteDisabled={true}
              deleteHidden={true}
              downloadDisabled={false}
              isView={true}
            />
          ) : (
            <div className={classnames(bem.e("order-view-value"))}>
              <FormattedMessage id="form builder > none" />
            </div>
          )}
        </Col>
        <Col xs={12} className={bem.e("col-reset")}>
          <Label className={bem.e("view-field-label")}>
            <FormattedMessage id="process > optional notes" />
          </Label>
          <div className={classnames(bem.e("order-view-value"))}>
            {channel.optional_notes ? (
              channel.optional_notes
            ) : (
              <FormattedMessage id="form builder > none" />
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}
function ChannelContentForm(props) {
  const { channel, idx, onValueChanged } = props;
  const reportFiles = get(channel, "report_files", []);

  return (
    <div className={bem.e("channel-content-form")}>
      <Row>
        <Col xs={12} className={bem.e("col-form-field")}>
          {reportFiles.length > 0 && (
            <div className={bem.e("form-handle-assets")}>
              <Label className={bem.e("form-field-label")}>
                <FormattedMessage id="process > report" />
              </Label>
              <HandleAssets
                files={reportFiles}
                onUploadedFiles={(newValue) => {
                  onValueChanged(idx, {
                    field: "report_files",
                    value: newValue,
                  });
                }}
                onDeleted={(newValue) => {
                  onValueChanged(idx, {
                    field: "report_files",
                    value: newValue,
                  });
                }}
              />
            </div>
          )}

          <Label className={bem.e("form-field-label")}>
            <FormattedMessage id="process > report" />
          </Label>
          <UploadFiles
            // mimes
            // mimes={mimes}
            acceptMultiple={true}
            files={reportFiles || []}
            isHideFilesDragArea={true}
            onUploadedFiles={(updatedFiles) => {
              let newValue = updatedFiles;
              if (reportFiles) {
                newValue = concat(reportFiles, newValue);
              }
              onValueChanged(idx, {
                field: "report_files",
                value: newValue,
              });
            }}
            onDeleted={(newValue) => {
              onValueChanged(idx, {
                field: "report_files",
                value: newValue,
              });
            }}
            disabled={false}
            maxSize={10485760} // 10M
            className={bem.e("assets")}
            dropTextClass={bem.e("drop-text-class")}
          >
            <FormattedMessage id="process > button upload" />
          </UploadFiles>
        </Col>
        <Col xs={12} className={bem.e("col-reset")}>
          <TextInput
            label={<FormattedMessage id="process > optional notes" />}
            required
            type="textarea"
            name="description"
            placeholder=""
            aria-multiline="true"
            value={channel.optional_notes}
            onChange={({ target }) => {
              onValueChanged(idx, {
                field: "optional_notes",
                value: target.value,
              });
            }}
          />
        </Col>
      </Row>
    </div>
  );
}
function ChannelItem(props) {
  const { channel, isOpen, setIsOpen, isView } = props;
  const channelIcon = get(channel, "channel_icon");
  const ChannelIcon = IconsConfig[channelIcon]
    ? IconsConfig[channelIcon]
    : IconsConfig["default"];
  return (
    <div
      className={classnames(bem.e("channel"), {
        [bem.e("channel-open")]: isOpen,
      })}
    >
      <div className={bem.e("channel-head")}>
        <div className={bem.e("section-main-info")}>
          <span className={bem.e("channel-icon")}>
            <ChannelIcon />
          </span>
          <h3
            className={classnames(bem.e("channel-title"), {
              [bem.e("channel-title-inactive")]: get(
                channel,
                "is_hidden",
                false
              ),
            })}
            onClick={() => {
              setIsOpen();
            }}
          >
            {get(channel, "name")}
          </h3>
        </div>
        <div className={bem.e("channel-head-right")}>
          <div className={bem.e("status")}>
            <FormattedMessage id="process > status" />:
            <strong>
              {channel.report_status === "completed" ? (
                <FormattedMessage id="process > complete" />
              ) : (
                <FormattedMessage id="process > awaiting report" />
              )}
            </strong>
          </div>
          <button
            className={bem.e("button-down")}
            onClick={() => {
              setIsOpen();
            }}
          >
            {isOpen ? (
              <UpIcon
                className={bem.e("button-up-icon")}
                color="#795AFA"
                width={24}
                height={15.46}
              />
            ) : (
              <DownIcon
                className={bem.e("button-down-icon")}
                color="#795AFA"
                width={24}
                height={15.46}
              />
            )}
          </button>
        </div>
      </div>
      {isOpen && (
        <div className={bem.e("channel-content")}>
          {isView ? (
            <ChannelContentView channel={channel} />
          ) : (
            <ChannelContentForm
              onValueChanged={props.onValueChanged}
              idx={props.idx}
              channel={channel}
              intl={props.intl}
            />
          )}
        </div>
      )}
    </div>
  );
}
export default function DigitalReport(props) {
  const { process, isOpenForm, step, template, user, valueChanged } = props;
  const marketingChannels = get(
    process,
    "data.fields.marketing_channels.value",
    []
  );
  const isView = props.mode === "view";
  const onValueChanged = (index, { field, value }) => {
    const channelField = step.fields.find(
      (f) => f.field === "marketing_channels"
    );
    const channel = marketingChannels[index];
    if (channel) {
      marketingChannels[index] = {
        ...channel,
        [field]: value,
      };
      valueChanged(channelField, process, template, user, {
        value: marketingChannels,
      });
    }
  };
  return (
    <div className={bem.b()}>
      {map(marketingChannels, (channel, index) => {
        const isOpen = isOpenForm === channel.id;
        return (
          <ChannelItem
            channel={channel}
            intl={props.intl}
            isOpen={isOpen}
            setIsOpen={() => props.setIsOpenForm(isOpen ? null : channel.id)}
            key={index}
            isView={isView}
            idx={index}
            onValueChanged={onValueChanged}
          />
        );
      })}
    </div>
  );
}
