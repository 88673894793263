import React from "react";
import bn from "utils/bemnames";
import Spinner from "components/Spinner";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import { toLower, get, find } from "lodash";
import { default_templates } from "utils/defaults";
import { formatDisplayDate } from "utils/helpers";
import DeadlineTimer from "components/DeadlineTimer";

const bem = bn.create("team-list-orders");

const statusDisplay = (process) => {
  const processStep = get(process, "_source.process_step", "draft");
  let rowTemplate = default_templates.find(
    (t) => t._id === get(process, "_source.template_key", "basic_production")
  );
  let step = get(rowTemplate, `_source.steps.${processStep}`);
  let title =
    get(
      process,
      "_source.imported_from_wo",
      false
    ) && get(
      process,
      "_source.template_selected_for_wo",
      false
    ) == "" ? (
      <FormattedMessage id={"process > title wo draft"} />
    ) : (
      <FormattedMessage id={"process > title draft"} />
    );
  let color = "#9AA4CA";
  if (title !== "draft") {
    color = get(step, "caption_color", "#9AA4CA");
  }
  if (get(step, "title")) {
    title = get(step, "title");
    if (step.key === "approve_recording") {
      if (get(process, "_source.sent_to_client_for_spot_review")) {
        title = <FormattedMessage id={"process > title client review"} />;
      }
    }
    if (step.key === "approve_script") {
      if (get(process, "_source.sent_to_client_for_script_review")) {
        title = <FormattedMessage id={"process > title client review"} />;
      }
    }
    if (step.key === "order_details") {
      if (get(process, "_source.order_details_status") === "draft")
        title = "draft";
    }
    if (step.key === "record_script") {
      if (get(process, "_source.approve_recording_status") === "rejected") {
        title = <FormattedMessage id={"process > title spot change"} />;
        color = "#E40061";
      }
    }
    if (step.key === "write_script") {
      if (get(process, "_source.approve_script_status") === "rejected")
        title = <FormattedMessage id={"process > title script change"} />;
      color = "#E40061";
    }
    if (step.key === "assign_vo") {
      title = <FormattedMessage id={"process > title assign vo"} />;
      color = "#A82AC0";
    }
    if (step.key === "voice_over") {
      if (get(process, "_source.voice_over_status") === "rejected") {
        title = <FormattedMessage id={"process > title change request"} />;
        color = "#E40061";
      } else {
        title = <FormattedMessage id={"process > title voice over"} />;
      }
    }
    if (step.key === "continuity") {
      if (get(process, "_source.continuity_status") === "change_request") {
        title = <FormattedMessage id={"process > title change request"} />;
        color = "#E40061";
      }
    }
  }
  return (
    <span
      className={classnames(
        bem.e("order-status"),
        bem.e(
          `order-status-${
            title.replace ? toLower(title.replace(/ /g, "-")) : ""
          }`
        )
      )}
      style={{ color }}
    >
      {title}
    </span>
  );
};

const getIsAllowAssignTeamByProcess = (process) => {
  const isProductionProvided =
    get(process, "_source.template_key", "") === "production_provided";

  if (isProductionProvided) return false;
  return true;
};
const userRoles = [
  {
    sort_name: "P",
    field: "role_producer",
  },
  {
    sort_name: "V",
    field: "role_voice_talent",
  },
  {
    sort_name: "D",
    field: "role_dubber",
  },
  {
    sort_name: "W",
    field: "role_writer",
  },
];
const TeamListOrders = (props) => {
  const {
    isLoading,
    currentOrders,
    assignedOrders,
    user,
    dateFormatByServer,
  } = props;
  const timeToAirDisplay = (process) => {
    // 	Deadline should be none for Reports task
    if (get(process, "_source.process_step") === "report")
      return <FormattedMessage id="process > none" />;
    const spot = find(process._source.spot_info, item => item.key === process._id)
    const dueDate = get(spot, "due_date");
    const timezone = get(process, "_source.timezone", null);
    if (!dueDate) return null;
    return <DeadlineTimer date={dueDate} timezone={timezone} />;
  };
  const flightDatesDisplay = (process) => {
    const spot = find(process._source.spot_info, item => item.key === process._id)
    const dueDate = get(spot, "due_date");
    return (
      <span className={bem.e("flight-dates")}>
        {dueDate && formatDisplayDate(dueDate, dateFormatByServer)}
      </span>
    );
  };
  const rolesDisplay = (process) => {
    let roles = userRoles.filter((role) => {
      const roleData = process._source[role.field];
      const usersAssignedToRole = get(roleData, "users");
      // assign multiple
      if (Array.isArray(usersAssignedToRole)) {
        return usersAssignedToRole.find(
          (item) => Number(item.id) === Number(user.id)
        );
      }
      // single
      if (typeof roleData === "object" && roleData) {
        return Number(roleData.id) === Number(user.id);
      }
      return false;
    });
    if (!roles || roles.length === 0) return;

    return (
      <span className={bem.e("list-roles")}>
        {roles.map((role) => (
          <span className={bem.e("role-name")}>{role.sort_name}</span>
        ))}
      </span>
    );
  };
  return (
    <div className={bem.b()}>
      <table className={classnames(bem.e("table"), bem.e("current-table"))}>
        <thead>
          <tr>
            <th width="30%">
              <FormattedMessage id="hub manager > current" />
            </th>
            <th width="30%"></th>
            <th width="15%">
              <FormattedMessage id="hub manager > role" />
            </th>
            <th width="15%">
              <FormattedMessage id="hub manager > current status" />
            </th>
            <th width="10%">
              <FormattedMessage id="hub manager > due" />
            </th>
          </tr>
        </thead>
        <tbody>
          {currentOrders && currentOrders.length > 0 ? (
            currentOrders.map((process) => {
              const source = process._source;
              const isAllowAssignTeam = getIsAllowAssignTeamByProcess(process);
              return (
                <tr
                  key={process._id}
                  onClick={() => {
                    if (!isAllowAssignTeam) return;
                    props.onSelectProcess(process);
                  }}
                  className={classnames({
                    [bem.e("order-disabled")]: !isAllowAssignTeam,
                  })}
                >
                  <td className={bem.e("client-name")}>{source.client_name}</td>
                  <td className={bem.e("order-title")}>{source.order_title}</td>
                  <td>{rolesDisplay(process)}</td>
                  <td>{statusDisplay(process)}</td>
                  <td>{timeToAirDisplay(process)}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={5} className={bem.e("empty-orders")}>
                <FormattedMessage id="hub manager > no rows found" />
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <table className={classnames(bem.e("table"), bem.e("assigned-table"))}>
        <thead>
          <tr>
            <th width="30%">
              <FormattedMessage id="hub manager > assigned" />
            </th>
            <th width="30%"></th>
            <th width="15%"></th>
            <th width="15%"></th>
            <th width="10%"></th>
          </tr>
        </thead>
        <tbody>
          {assignedOrders && assignedOrders.length > 0 ? (
            assignedOrders.map((process) => {
              const source = process._source;
              return (
                <tr
                  key={process._id}
                  onClick={() => props.onSelectProcess(process)}
                >
                  <td className={bem.e("client-name")}>{source.client_name}</td>
                  <td className={bem.e("order-title")}>{source.order_title}</td>
                  <td>{rolesDisplay(process)}</td>
                  <td>{statusDisplay(process)}</td>
                  <td>{flightDatesDisplay(process)}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={5} className={bem.e("empty-orders")}>
                <FormattedMessage id="hub manager > no rows found" />
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Spinner isLoading={isLoading} />
    </div>
  );
};
export default TeamListOrders;
