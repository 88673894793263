import React from "react";
import Page from "components/Page";
import { FormattedMessage } from "react-intl";
import SettingsForm from "components/ProductionSettings/SettingsForm";
import WorkflowSettingsForm from "components/ProductionSettings/WorkflowSettingsForm";
import PrivilegedComponent from "components/PrivilegedComponent";
import { PRIVILEGES } from "utils/constants";
import { Redirect } from "react-router";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { adminTabs } from "utils/config";
import URL from "utils/urls";
import { Asterisk } from "components/Elements";
import { get } from "lodash";
import bn from "utils/bemnames";
import classnames from "classnames";
import SelectAudience from "components/SelectAudience";
import BlockingComponent from "components/BlockingComponent";
import ConfirmationModal from "components/ConfirmationModal";
const bem = bn.create("station-settings");
const ProductionSettings = ({
  stations,
  intl,
  currentStation,
  setCurrentStation,
  activeTab,
  setActiveTab,
  shouldBlockNavigation,
  setShouldBlockNavigation,
  isOpenConfirmationModal,
  setIsOpenConfirmationModal,
}) => {
  return (
    <PrivilegedComponent requires={[PRIVILEGES.EDIT_PRODUCTION_SETTINGS]}>
      {({ isValid }) => {
        if (isValid) {
          return (
            <Page
              className={bem.b()}
              applyPadding={false}
              title={
                <div>
                  <nav
                    className="cr-page__breadcrumb"
                    aria-label="breadcrumb"
                    style={{ fontSize: "initial" }}
                  >
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to={URL.ADMINS({ tab: adminTabs.TAB_UNKNOWN })}>
                          <FormattedMessage id="production settings > admin" />
                        </Link>
                      </li>
                      <li
                        className="active breadcrumb-item"
                        aria-current="page"
                      >
                        <strong>
                          <FormattedMessage id="production settings > production settings" />
                        </strong>
                      </li>
                    </ol>
                  </nav>
                  <span className="page-title">
                    <FormattedMessage id="production settings > page title" />
                  </span>
                </div>
              }
            >
              <div className={"page-container"}>
                {stations.length > 0 ? (
                  <SelectAudience
                    name="select_stations"
                    item={{ field: "stations" }}
                    label={
                      <span>
                        {intl.formatMessage({
                          id: "production settings > station",
                        })}
                        <Asterisk>*</Asterisk>
                      </span>
                    }
                    placeholder={intl.formatMessage({
                      id: "production settings > select station",
                    })}
                    template={{ key: "basic_production" }}
                    isFilterByServerConfig={true}
                    onChange={(station) => {
                      if (shouldBlockNavigation) {
                        setIsOpenConfirmationModal(true);
                      } else {
                        setCurrentStation(station);
                      }
                    }}
                    required={false}
                    isMulti={false}
                    value={get(currentStation, "value", "")}
                  />
                ) : null}
                {currentStation ? (
                  <React.Fragment>
                    <Nav tabs>
                      <PrivilegedComponent requires={[]}>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "settings",
                            })}
                            onClick={() => {
                              setActiveTab("settings");
                            }}
                          >
                            {intl.formatMessage({
                              id: "production settings > order settings",
                            })}
                          </NavLink>
                        </NavItem>
                      </PrivilegedComponent>
                      <PrivilegedComponent requires={[]}>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "workflow",
                            })}
                            onClick={() => {
                              setActiveTab("workflow");
                            }}
                          >
                            {intl.formatMessage({
                              id: "production settings > workflow settings",
                            })}
                          </NavLink>
                        </NavItem>
                      </PrivilegedComponent>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="settings">
                        {activeTab === "settings" ? (
                          <SettingsForm
                            currentStation={currentStation}
                            stations={stations}
                            shouldBlockNavigation={shouldBlockNavigation}
                            setShouldBlockNavigation={setShouldBlockNavigation}
                          />
                        ) : null}
                      </TabPane>
                      <TabPane tabId="workflow">
                        {activeTab === "workflow" ? (
                          <WorkflowSettingsForm
                            currentStation={currentStation}
                            stations={stations}
                            shouldBlockNavigation={shouldBlockNavigation}
                            setShouldBlockNavigation={setShouldBlockNavigation}
                          />
                        ) : null}
                      </TabPane>
                    </TabContent>
                    <BlockingComponent
                      isBlocked={shouldBlockNavigation}
                      modalProps={{
                        title: intl.formatMessage({
                          id:
                            "reminder modal > wait, you have unsaved changes in this form",
                        }),
                        confirmTitle: intl.formatMessage({
                          id: "reminder modal > button save",
                        }),
                        cancelTitle: intl.formatMessage({
                          id: "reminder modal > button dismiss",
                        }),
                        onConfirm: () => {
                          const buttonSubmit = document.getElementById(
                            `btn-submit-${activeTab}`
                          );
                          // trigger click to submit the form
                          if (buttonSubmit) buttonSubmit.click();
                        },
                      }}
                    />
                    <ConfirmationModal
                      isOpen={isOpenConfirmationModal}
                      title={intl.formatMessage({
                        id:
                          "reminder modal > wait, you have unsaved changes in this form",
                      })}
                      isCloseOutside={false}
                      className={bem.e("confirmation-modal")}
                      description={intl.formatMessage({
                        id:
                          "reminder modal > before you can perform this step, you must save or discard your changes",
                      })}
                      confirmTitle={intl.formatMessage({
                        id: "reminder modal > button save",
                      })}
                      discardTitle={intl.formatMessage({
                        id: "reminder modal > button discard",
                      })}
                      cancelTitle={intl.formatMessage({
                        id: "reminder modal > button cancel",
                      })}
                      onConfirm={() => {
                        const buttonSubmit = document.getElementById(
                          `btn-submit-${activeTab}`
                        );
                        // trigger click to submit the form
                        if (buttonSubmit) buttonSubmit.click();
                        setIsOpenConfirmationModal(false);
                      }}
                      onToggle={() =>
                        setIsOpenConfirmationModal(!isOpenConfirmationModal)
                      }
                      onDiscard={() => {
                        setShouldBlockNavigation(false);
                        setIsOpenConfirmationModal(false);
                      }}
                      onCancel={() => setIsOpenConfirmationModal(false)}
                    />
                  </React.Fragment>
                ) : null}
              </div>
            </Page>
          );
        } else {
          return <Redirect to="/" />;
        }
      }}
    </PrivilegedComponent>
  );
};

export default ProductionSettings;
