import React from "react";

import Table from "components/Table";
import { FormattedMessage } from 'react-intl';
import { values, forEach, has, get } from 'lodash';
import Checkbox from "components/Checkbox";

const StationsList = (props) => {
  const {
    stations,
    selectedStations,
    updateSelectedStations,
    addedStations
  } = props;

  let searchedStations = values(stations);
  const NoDataComponent = (<div className="no-results"><FormattedMessage id="picker > search no results" /></div>);

  const tableColumns = [
    {
      Header: (
        <div className="header">
          &nbsp;
        </div>
      ),
      id: 'station',
      width:40,
      Cell: ({ original : station }) => {
        return (
          <Checkbox
            checked={has(selectedStations, station.key)} 
            onChange={checked => updateSelectedStations(checked, station)}
            disableSelectOnTextClick={true}
            checkStyle={{color: "#fff", width: '15', height:'14'}}
          />
        );
      }
    },
    {
      Header: (
        <div className="header">
          <FormattedMessage id="picker > entity" />
        </div>
      ),
      id: 'station',
      accessor: "name",
      width: 90,
    },
    {
      Header: (
        <div className="header">
          <FormattedMessage id="picker > stations > market" />
        </div>
      ),
      id : 'market',
      accessor: d => {
        let markets = [];
        forEach(d.market, market => {
          markets.push(market.title);
        })
        return markets.join();
      },
    },
    {
      Header: (
        <div className="header">
          <FormattedMessage id="picker > stations > format" />
        </div>
      ),
      id: 'format',
      accessor: d => {
        let formats = [];
        forEach(d.format, format => {
          formats.push(format.title);
        })
        return formats.join();
      },
    },
  ];

  const results = searchedStations.filter(item => {
    const status = get(addedStations, item.key);
    if(status) return false;
    return true;
  })
  
  return (
    <Table
      className="stations-list"
      data={results}
      showPageSizeOptions={false}
      total={searchedStations.length}
      sortable={false}
      resizable={false}
      filterable={false}
      showPagination={false}
      NoDataComponent={NoDataComponent}
      columns={tableColumns}
    />
  );
};

export default StationsList;
