import React, { useCallback, useState, useRef } from "react";
import bn from "utils/bemnames";
import {
  ApproveIcon,
  TrashIcon,
  CloseIcon,
  MoreEllipsisIcon,
  SendIcon,
} from "components/CustomIcons";
import TextInput from "components/TextInput";
import { Button } from "reactstrap";
import { get, filter, trim } from "lodash";
import { FormattedMessage } from "react-intl";
import Tooltip from "rc-tooltip";
import useOutsideClick from "utils/useOutsideClick";
const bem = bn.create("client-review");
const ViewComment = ({
  comment,
  setIsEditComment,
  onSlickTo,
  onSelectDelete,
}) => {
  return (
    <div className={bem.e("view-comment-content")}>
      <div onClick={onSlickTo} className={bem.e("view-comment-left")}>
        <span className={bem.e("comment-number")}>{comment.number}</span>
        <div className={bem.e("view-comment-text")}>{comment.message}</div>
      </div>
      <span
        className={bem.e("view-comment-close")}
        onClick={() => onSelectDelete(comment)}
      >
        <CloseIcon color="#C2D4E0" />
      </span>
      <Tooltip
        trigger={["hover"]}
        placement="bottom"
        overlayClassName={bem.e("tooltip-comment-actions")}
        overlay={
          <div className={bem.e("comment-actions")}>
            <div
              className={bem.e("comment-button")}
              onClick={() => setIsEditComment(comment.id)}
            >
              <FormattedMessage id="process > title edit comment" />
            </div>
            <div
              className={bem.e("comment-button")}
              onClick={() => onSelectDelete(comment)}
            >
              <FormattedMessage id="process > title delete comment" />
            </div>
          </div>
        }
      >
        <span className={bem.e("comment-more-icon")}>
          <MoreEllipsisIcon />
        </span>
      </Tooltip>
    </div>
  );
};
const EditComment = ({
  comment,
  bem,
  onSubmit,
  intl,
  setIsEditComment,
  onSelectDelete,
}) => {
  const selectorRef = useRef(null);
  const [message, setMessage] = useState(comment.message);
  useOutsideClick(selectorRef, () => {
    setIsEditComment(null);
  });
  const submitMessage = useCallback(() => {
    let trimMessage = trim(message);
    if (trimMessage)
      onSubmit({
        ...comment,
        message: trimMessage,
      });
  }, [message]);
  return (
    <div className={bem.e("edit-comment-content")} ref={selectorRef}>
      <span className={bem.e("comment-number")}>{comment.number}</span>
      <div className={bem.e("edit-comment-text")}>
        <TextInput
          type="textarea"
          placeholder={intl.formatMessage({
            id: "process > give us your feedback",
          })}
          value={message}
          onChange={({ target }) => {
            setMessage(target.value);
          }}
          onKeyDown={(e) => {
            const { keyCode } = e;
            if (keyCode === 13) submitMessage();
          }}
        />
        <Button
          color=""
          className={bem.e("edit-submit")}
          disabled={!message}
          onClick={() => submitMessage()}
        >
          <SendIcon color="#fff" width={10} height={7} />
        </Button>
      </div>
      <span
        className={bem.e("edit-comment-close")}
        onClick={() => {
          setIsEditComment(null);
          onSelectDelete(comment);
        }}
      >
        <CloseIcon color="#C2D4E0" />
      </span>
    </div>
  );
};
function AssetReviewStatus(props) {
  const {
    field,
    isEditComment,
    setIsEditComment,
    onSlickTo,
    onDeleteComment,
    intl,
    onSubmitEditComment,
    onSelectDelete,
  } = props;
  const comments = get(field, "value.comments", []);
  if (!field.review_media_status) return null; // remove any field missing review
  return (
    <div className={bem.e("asset-status-item")}>
      <div
        className={bem.e("asset-status")}
        onClick={() => {
          onSlickTo();
        }}
      >
        {field.is_approved && <ApproveIcon />}
        {field.is_rejected && <TrashIcon color="#E40061" />}
        <span className={bem.e("asset-text")}>
          <span>{field.media_name}</span>
          {` `}
          {field.is_approved && (
            <FormattedMessage id="process > was approved" />
          )}
          {field.is_rejected && (
            <FormattedMessage id="process > was rejected" />
          )}
        </span>
      </div>
      <div className={bem.e("asset-comments")}>
        {filter(comments, (comment) => !get(comment, "is_deleted", false)).map(
          (comment, index) => {
            if (isEditComment === comment.id) {
              return (
                <EditComment
                  comment={comment}
                  bem={bem}
                  setIsEditComment={setIsEditComment}
                  intl={intl}
                  key={index}
                  onSubmit={onSubmitEditComment}
                  onSelectDelete={onSelectDelete}
                />
              );
            }
            return (
              <ViewComment
                comment={comment}
                onSlickTo={onSlickTo}
                key={index}
                onDeleteComment={onDeleteComment}
                setIsEditComment={setIsEditComment}
                onSelectDelete={onSelectDelete}
              />
            );
          }
        )}
      </div>
    </div>
  );
}
export default AssetReviewStatus;
