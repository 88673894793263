import React, { Component } from "react";
import ChatBox from "components/ChatBox";
import { reverse, get, isFunction, includes, uniqBy } from "lodash";
import Checkbox from "components/Checkbox";
import { injectIntl } from "react-intl";
class NotificationsPusher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      chats: [],
      include_timestamp: false,
    };
  }

  getTaggedUserIds = (text = "") => {
    let arr = (text || "").match(/(@\[.*?\]\(.*?\))/g);
    let idArr = [];
    if (arr) {
      for (let index = 0; index < arr.length; index++) {
        const item = arr[index]; // @[Sarju](1)
        const endSquareBracketPos = item.indexOf("]");
        const userId = item
          .substr(endSquareBracketPos + 1)
          .replace("(", "")
          .replace(")", "");
        const userIdInt = Number(userId);
        idArr.push(userIdInt);
      }
    }
    return idArr;
  };
  getTaggedUserData = (message) => {
    const text = message.chat || "";
    let arr = (text || "").match(/(@\[.*?\]\(.*?\))/g);
    const { user } = this.props;
    let userTagged;
    if (arr) {
      for (let index = 0; index < arr.length; index++) {
        const item = arr[index]; // @[Sarju](1)
        const endSquareBracketPos = item.indexOf("]");
        const userId = item
          .substr(endSquareBracketPos + 1)
          .replace("(", "")
          .replace(")", "");
        const userIdInt = Number(userId);
        if (userIdInt === Number(user.id)) {
          userTagged = message.from;
        }
      }
    }
    return userTagged;
  };
  create_message = (text, attachments = []) => {
    let {
      process,
      template,
      step,
      user,
      recordingFeedback,
      scriptFeedback,
      voiceFeedback,
      instance,
      path,
      voiceTrackValue,
    } = this.props;
    let mType = "chat";

    if (scriptFeedback) {
      mType = "script_feedback";
    } else if (recordingFeedback) {
      mType = "recording_feedback";
    } else if (voiceFeedback) {
      mType = "voice_feedback";
    }

    if (process) {
      let message = {
        from: {
          id: user.id,
          name: user.name,
          avatar_url: user.avatar_url,
        },
        tagged: this.getTaggedUserIds(text),
        order_stations: process.data.fields.order_stations
          ? process.data.fields.order_stations.value
          : [],
        chat: text,
        attachments,
        m_type: mType,
        template: template.key,
        process: process.key,
        order_title: process.data.fields.order_title
          ? process.data.fields.order_title.value
          : "untitled",
        from_step: step ? step.key : "",
        team: process.data.fields.team ? process.data.fields.team.value : [],
        still_required_users: process.data.fields.still_required_users
          ? process.data.fields.still_required_users.value
          : [],
        next_users: process.data.fields.next_users
          ? process.data.fields.next_users.value
          : [],
        path: path || "a",
      };

      if (user.impersonated_by) {
        message.from.impersonated_by = user.impersonated_by;
      }

      if (recordingFeedback) {
        let playerPlayedSeconds = 0;

        if (path === "b") {
          message.recording_path = get(
            process,
            `data.fields.recording_path_b.value`,
            ""
          )
            ? get(process, `data.fields.recording_path_b.value`, "")
            : get(
                process,
                "data.fields.recording_feedback.value.recording_path_b",
                ""
              );
          message.is_recording_path_b = true;

          if (this.state.include_timestamp) {
            message.include_timestamp = this.state.include_timestamp;

            playerPlayedSeconds = get(window, "playerBPlayedSeconds", 0);

            // message.player_played_seconds =
            // playerPlayedSeconds - 3 > 0 ? playerPlayedSeconds - 3 : 0;

            message.player_played_seconds = playerPlayedSeconds;
          }
        } else {
          message.recording_path = get(
            process,
            `data.fields.recording_path.value`,
            ""
          )
            ? get(process, `data.fields.recording_path.value`, "")
            : get(
                process,
                "data.fields.recording_feedback.value.recording_path",
                ""
              );

          if (this.state.include_timestamp) {
            message.include_timestamp = this.state.include_timestamp;

            playerPlayedSeconds = get(window, "playerPlayedSeconds", 0);

            // message.player_played_seconds =
            //   playerPlayedSeconds - 3 > 0 ? playerPlayedSeconds - 3 : 0;

            message.player_played_seconds = playerPlayedSeconds;
          }
        }
      }

      if (voiceFeedback) {
        let playerPlayedSeconds = 0;
        message.recording_path = get(voiceTrackValue, "path", "");

        if (this.state.include_timestamp) {
          message.include_timestamp = this.state.include_timestamp;

          playerPlayedSeconds = get(window, "playerPlayedSeconds", 0);

          // message.player_played_seconds =
          //   playerPlayedSeconds - 3 > 0 ? playerPlayedSeconds - 3 : 0;

          message.player_played_seconds = playerPlayedSeconds;
        }
      }

      if (scriptFeedback) {
        message.script_version = get(
          process,
          "data.fields.script_version.value",
          ""
        )
          ? get(process, "data.fields.script_version.value", "")
          : "";
      }

      return message;
    }

    if (instance) {
      const message = {
        from: {
          id: user.id,
          name: user.name,
          avatar_url: user.avatar_url,
        },
        tagged: this.getTaggedUserIds(text),
        chat: text,
        m_type: "chat",
        process_key: instance.id,
        process: instance.id,
      };
      return message;
    }
  };

  onSubmit = ({ value, attachments }) => {
    const chat_message = this.create_message(value, attachments);

    let {
      process,
      template,
      step,
      user,
      recordingFeedback,
      scriptFeedback,
      voiceFeedback,
      clientReview,
      sendMessage,
      isDirectSendMessage,
    } = this.props;
    const isValid =
      recordingFeedback || scriptFeedback || voiceFeedback ? true : false;
    // re-compute fields from callback
    if (this.props.recomputeFields) {
      process = this.props.recomputeFields(user, template, process, step);
    }
    if (this.props.process) {
      const success_callback = this.props.successCallback
        ? this.props.successCallback
        : false;
      const processKey = get(process, "data.fields.key.value", "");
      // We will call single to send message when editing an order
      if (processKey && isDirectSendMessage) {
        sendMessage({ message: chat_message, process_id: processKey, process });
      } else if (this.props.onSubmit) {
        this.props.onSubmit(chat_message);
      } else {
        this.props.saveFormValues({
          process,
          template,
          step,
          user,
          success_callback,
          valid: isValid,
          chat_message,
          recordingFeedback: recordingFeedback,
          scriptFeedback: scriptFeedback,
          clientReview: clientReview,
        });
      }
    }
    if (this.props.onSubmit) {
      this.props.onSubmit(chat_message);
    }
  };
  getMessagesTagged = (messages) => {
    const { user } = this.props;
    const messagesTagged = messages.filter(
      (message) => includes(message.tagged, Number(user.id)) && message.from
    );
    return uniqBy(messagesTagged, "from.id");
  };
  render() {
    const {
      user,
      process,
      messages,
      intl,
      isCollapse,
      setIsCollapse,
      recordingFeedback,
      scriptFeedback,
      clientReview,
      position,
      boxStyle,
      chatInputPlaceHolder,
      showEmojiPicker,
      checkBoxIconStyle,
      hideChatInput,
      onInputChange,
      onTimeStampSelect,
      recordingUrl,
      instance,
      path,
      clearInput,
      voiceFeedback,
      isShowMessageActions,
      chatInputDisabled,
      isKeepInputValueAfterClickSend,
    } = this.props;

    let _path = path || "a";
    let recording_path = 0;
    let show_timestamp = 0;
    if (
      get(process, "data.fields.recording_path") &&
      get(process, "data.fields.recording_path") !== undefined &&
      get(process, "data.fields.recording_path.value") !== ""
    ) {
      recording_path = 1;
    }
    if ((this.props.recordingFeedback && recording_path) || voiceFeedback) {
      show_timestamp = 1;
    }
    let messagesToShow = [];
    let allMessages = [];

    let scriptVersion = get(process, "data.fields.script_version.value", "")
      ? get(process, "data.fields.script_version.value", "")
      : "";

    if (process) {
      messagesToShow = messages
        .filter(({ message }) => {
          let process_key = process.key
            ? process.key
            : process.data.fields.key && process.data.fields.key.value
            ? process.data.fields.key.value
            : "-1";
          let message_process_key = message.process
            ? message.process
            : message.process_key;
          const isChat =
            message.m_type === "chat" &&
            !recordingFeedback &&
            !voiceFeedback &&
            !scriptFeedback;
          if (
            message.chat &&
            message.m_type === "chat" &&
            isChat &&
            ((message_process_key && message_process_key === process_key) ||
              message_process_key === process_key ||
              message.process_key === "current")
          ) {
            return true;
          } else if (
            message.chat &&
            message.m_type === "recording_feedback" &&
            recordingFeedback &&
            ((message_process_key && message_process_key === process_key) ||
              message_process_key === process_key ||
              message.process_key === "current") &&
            message.path === _path &&
            message.recording_path === recordingUrl
          ) {
            return true;
          } else if (
            message.chat &&
            message.m_type === "script_feedback" &&
            scriptFeedback &&
            ((message_process_key && message_process_key === process_key) ||
              message_process_key === process_key ||
              message.process_key === "current") &&
            message.path === _path &&
            message.script_version === scriptVersion
          ) {
            return true;
          } else if (
            message.chat &&
            message.m_type === "voice_feedback" &&
            voiceFeedback &&
            ((message_process_key && message_process_key === process_key) ||
              message_process_key === process_key ||
              message.process_key === "current") &&
            message.recording_path === recordingUrl
          ) {
            return true;
          }
          return false;
        })
        .map(({ message, m_id }) => {
          const isSender = get(message, "from.id", "") === user.id;

          let messagePosition;

          if (recordingFeedback || scriptFeedback || voiceFeedback) {
            messagePosition = "left";
          } else {
            messagePosition = isSender ? "right" : "left";
          }

          let data = {
            id: m_id,
            text: message.chat,
            data: message,
            from: message.from,
            timestamp: message.created_ts * 1000,
            status: message.status || "delivered",
            position: messagePosition,
          };
          allMessages.push({
            ...message,
            id: m_id,
          });
          return data;
        });
    } else if (instance) {
      messagesToShow = messages
        .filter(({ message }) => {
          const instance_key = instance.id ? instance.id : "-1";
          const message_process_key = message.process
            ? message.process
            : message.process_key;
          if (
            message.chat &&
            message.m_type === "chat" &&
            (message_process_key === instance_key ||
              message_process_key === "current")
          ) {
            return true;
          }

          return false;
        })
        .map(({ message, m_id }) => {
          const isSender = get(message, "from.id", "") === user.id;

          let messagePosition;

          if (recordingFeedback || scriptFeedback || voiceFeedback) {
            messagePosition = "left";
          } else {
            messagePosition = isSender ? "right" : "left";
          }

          let data = {
            id: m_id,
            text: message.chat,
            data: message,
            from: message.from,
            timestamp: message.created_ts * 1000,
            status: message.status || "delivered",
            position: messagePosition,
          };
          allMessages.push({
            ...message,
            id: m_id,
          });
          return data;
        });
    }

    const messagesTagged = this.getMessagesTagged(reverse(allMessages));
    return (
      <React.Fragment>
        <ChatBox
          onSubmit={this.onSubmit}
          isKeepInputValueAfterClickSend={isKeepInputValueAfterClickSend}
          submitOnEnter
          messages={reverse(messagesToShow)}
          messagesTagged={messagesTagged}
          intl={intl}
          isCollapse={isCollapse}
          setIsCollapse={setIsCollapse}
          recordingFeedback={recordingFeedback}
          scriptFeedback={scriptFeedback}
          voiceFeedback={voiceFeedback}
          isHiddenTitle={recordingFeedback || scriptFeedback || voiceFeedback}
          isShowMessageActions={isShowMessageActions}
          style={boxStyle}
          position={position}
          chatInputPlaceHolder={chatInputPlaceHolder}
          showEmojiPicker={showEmojiPicker}
          hideChatInput={hideChatInput}
          onInputChange={onInputChange}
          clientReview={clientReview}
          clearInput={clearInput}
          chatInputDisabled={chatInputDisabled}
        />
        {!hideChatInput && show_timestamp === 1 && (
          <div style={{ marginTop: "10px" }}>
            <Checkbox
              text={intl.formatMessage({ id: "process > include timestamp" })}
              checked={this.state.include_timestamp}
              onChange={() => {
                this.setState({
                  include_timestamp: !this.state.include_timestamp,
                });

                if (isFunction(onTimeStampSelect)) {
                  onTimeStampSelect(!this.state.include_timestamp);
                }
              }}
              checkStyle={checkBoxIconStyle}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}
NotificationsPusher.defaultProps = {
  isShowMessageActions: false,
  isDirectSendMessage: false,
  chatInputDisabled: false,
};
export default injectIntl(NotificationsPusher);
