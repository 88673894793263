import React from "react";
import PropTypes from "prop-types";
import {
  Modal,
  ModalBody,
  Button,
  Row,
  Col,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";
import classnames from "classnames";
import bn from "utils/bemnames";
import { FormattedMessage } from "react-intl";
import { Formik } from "formik";
import * as Yup from "yup";
import { get, includes, xor } from "lodash";
import Checkbox from "components/Checkbox";
import ToggleSwitch from "components/ToggleSwitch";
const bem = bn.create("export-modal");

const getFields = (configFields, key) => {
  return get(configFields, key, []);
};
function ExportForm(props) {
  const {
    onToggle,
    type,
    onSubmit,
    initialValues,
    intl,
    isDefaultSelectedAll,
    isShowTypeSwitch,
    serverCountry
  } = props;
  const configFields = {
    winners: [
      {
        label: <FormattedMessage id="export > full name" />,
        value: "Full Name",
      },
      {
        label: <FormattedMessage id="export > prize won" />,
        value: "Prize Won",
      },
      {
        label: <FormattedMessage id="export > email address" />,
        value: "Email Address",
      },
      {
        label: <FormattedMessage id="export > station" />,
        value: "Station",
      },
      {
        label: <FormattedMessage id="export > phone number" />,
        value: "Phone Number",
      },
      {
        label: <FormattedMessage id="export > prize expiration" />,
        value: "Prize Expiration",
      },
      {
        label: <FormattedMessage id="export > street address" />,
        value: "Street Address",
      },
      {
        label: <FormattedMessage id="export > date won" />,
        value: "Date Won",
      },
      {
        label: <FormattedMessage id="export > city" />,
        value: "City",
      },
      {
        label: <FormattedMessage id="export > birthdate" />,
        value: "Birthday",
      },
      {
        label: <FormattedMessage id={serverCountry === "CA" ? "export > province" : "export > state" }/>,
        value: "State",
      },
      {
        label: <FormattedMessage id="export > dl number" />,
        value: "Government Issued ID",
      },
      {
        label: <FormattedMessage id={serverCountry === "CA" ? "export > postal code" : "export > zipcode" }/>,
        value: "Zip Code",
      },
      {
        label: <FormattedMessage id="export > winner +1" />,
        value: "Winner + 1",
      },
    ],
    promotion_reports: [
      {
        label: <FormattedMessage id="export > station name" />,
        value: "Station",
      },
      {
        label: <FormattedMessage id="export > city" />,
        value: "City",
      },
      {
        label: <FormattedMessage id="export > first name" />,
        value: "First Name",
      },
      {
        label: <FormattedMessage id={serverCountry === "CA" ? "export > province" : "export > state" }/>,
        value: "State",
      },
      {
        label: <FormattedMessage id="export > last name" />,
        value: "Last Name",
      },
      {
        label: <FormattedMessage id={serverCountry === "CA" ? "export > postal code" : "export > zipcode" }/>,
        value: "Zip Code",
      },
      {
        label: <FormattedMessage id="export > social security #" />,
        value: "Social Security",
      },
      {
        label: <FormattedMessage id="export > country" />,
        value: "Country",
      },
      {
        label: <FormattedMessage id="export > street address" />,
        value: "Street Address",
      },
    ],
  };
  const fields = getFields(configFields, type);
  return (
    <Formik
      initialValues={{
        fields: isDefaultSelectedAll
          ? fields.map((i) => i.value)
          : get(initialValues, "fields", []),
        type: get(initialValues, "type", "summary"),
      }}
      validationSchema={Yup.object().shape({
        fields: Yup.array().min(
          1,
          intl.formatMessage({ id: "validate > at least one field" })
        ),
      })}
      onSubmit={onSubmit}
    >
      {(formProps) => {
        const { values, touched, errors } = formProps;
        const isDisabled = values.fields.length === 0;
        return (
          <Form onSubmit={formProps.handleSubmit}>
            <Row>
              {isShowTypeSwitch ? (
                <Col xs={12}>
                  <FormGroup>
                    <ToggleSwitch
                      leftComponent={intl.formatMessage({
                        id: "export > summary",
                      })}
                      rightComponent={intl.formatMessage({
                        id: "export > itemized",
                      })}
                      name="type"
                      switchProps={{
                        checked: values.type !== "summary",
                        onChange: (checked) => {
                          formProps.setFieldValue(
                            "type",
                            checked ? "itemized" : "summary"
                          );
                        },
                        offColor: "#795AFA",
                        onColor: "#795AFA",
                        uncheckedIcon: false,
                        checkedIcon: false,
                        handleDiameter: 22,
                        width: 50,
                        height: 26,
                      }}
                    />
                  </FormGroup>
                </Col>
              ) : null}

              <Col>
                <FormGroup>
                  <div className={bem.e("fields")}>
                    {fields.map((option, index) => (
                      <Checkbox
                        checked={!!includes(values.fields, option.value)}
                        onChange={() => {
                          const newValue = xor(values.fields, [option.value]);
                          formProps.setFieldValue("fields", newValue);
                        }}
                        text={option.label}
                        key={index}
                      />
                    ))}
                  </div>
                  {touched.fields && errors.fields && (
                    <div className={bem.e("error")}>
                      <span className="text-danger">{errors.fields}</span>
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className={bem.e("buttons")}>
                  <Button
                    color="primary"
                    className={classnames("btn-radius", bem.e("btn-search"))}
                    type="submit"
                    disabled={isDisabled}
                  >
                    <FormattedMessage id="export > button export list" />
                  </Button>
                  <Button
                    color="primary"
                    outline
                    onClick={onToggle}
                    className={classnames("btn-radius", bem.e("btn-cancel"))}
                  >
                    <FormattedMessage id="export > button cancel" />
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
}
const ExportModal = (props) => {
  const { isOpen, onToggle } = props;
  return (
    <Modal isOpen={isOpen} className={bem.b()} centered>
      <ModalHeader toggle={onToggle} className={bem.e("header")}>
        <FormattedMessage id="export > title modal export list" />
      </ModalHeader>
      <ModalBody className={bem.e("body")}>
        <ExportForm {...props} />
      </ModalBody>
    </Modal>
  );
};

ExportModal.propTypes = {
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
  onSubmit: PropTypes.func,
  type: PropTypes.string,
  isDefaultSelectedAll: PropTypes.bool,
  isShowTypeSwitch: PropTypes.bool,
};

ExportModal.defaultProps = {
  isOpen: false,
  onToggle: () => {},
  onSubmit: () => {},
  type: "winners",
  isDefaultSelectedAll: false,
  isShowTypeSwitch: false,
};

export default ExportModal;
