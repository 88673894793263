import { FormattedMessage } from "react-intl";
import React from "react";
import { get } from "lodash";
import fields from "../fields";

const Client = {
  process_step_index: 4,
  key: "winners",
  title: <FormattedMessage id="process > title winners" />,
  caption_color: "#CC1EE6",
  step_roles: [],
  step_viewers_roles: [],
  step_editors_roles: [],
  step_viewers_privileges: ["contest_view"],
  step_editors_privileges: {
    or: [
      "contest_save",
      "contest_edit",
      "contest_delete",
      "contest_publish",
      "contest_save_draft",
    ],
  },
  step_container: "none",
  no_edit_button: true,
  className: "contest-winners",
  is_hidden_chat: true,
  is_disabled: function(user, process, step) {
    const processKey = get(process, "data.fields.key.value", false);
    const selectedStations = get(
      process,
      "data.fields.order_stations_array.value",
      []
    );
    return selectedStations.length === 0 || !processKey;
  },
  fields: [
    {
      component: "promotion_contest_winners",
    },
    ...fields,
    {
      key: "save_button",
      text: <FormattedMessage id="process > button save" />,
      on_click: {
        validate: true,
        save: true,
      },
      fields: [
        {
          key: "winners_status",
          value: "completed",
        },
        {
          key: "process_step",
          value: "first_undone",
        },
      ],
      confirmation_dialog_props: {
        description: (
          <FormattedMessage id="process > description to submit production order" />
        ),
        confirmTitle: <FormattedMessage id="process > button yes" />,
        cancelTitle: <FormattedMessage id="process > button no" />,
      },
      success_dialog_props: {
        title: <FormattedMessage id="process > title submitted" />,
        state: "success",
      },
    },
  ],
};
export default Client;
