import * as React from "react";
import PropTypes from "prop-types";
import { findIndex } from "lodash";
import { FormGroup, Label } from "reactstrap";
import classnames from "classnames";
import bn from "utils/bemnames";
const bem = bn.create("radio-buttons");
class RadioButtons extends React.Component {
  static propType = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    error: PropTypes.string,
    buttons: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    selectedValue: PropTypes.string,
    onSelected: PropTypes.func,
    name: PropTypes.string,
    inline: PropTypes.bool,
    labelProps: PropTypes.object,
  };

  static defaultProps = {
    name: "radio",
    inline: true,
    onSelected: () => {},
  };

  _getSelectedIndex = () => {
    const { buttons } = this.props;
    const { selectedValue } = this.props;
    return findIndex(buttons, { value: selectedValue });
  };

  render() {
    const { buttons, name, label, labelProps, error, inline } = this.props;
    const selectedIndex = this._getSelectedIndex();
    return (
      <div className={bem.b()}>
        {!!label && (
          <Label for={name} {...labelProps}>
            {label}
          </Label>
        )}
        <div className={bem.e("radios")}>
          {buttons.map((button, index) => (
            <FormGroup check inline={inline} key={index}>
              <Label
                check
                onClick={() => {
                  this.props.onSelected(button.value);
                }}
              >
                <span
                  className={classnames(bem.e("radio-view"), {
                    [bem.e("radio-view-checked")]: index === selectedIndex,
                  })}
                />
                <span className={bem.e("label")}>{button.label}</span>
              </Label>
            </FormGroup>
          ))}
        </div>
        {!!error && <span className="text-danger">{error}</span>}
      </div>
    );
  }
}

export default RadioButtons;
