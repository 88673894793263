import React from "react";
// import { get } from "lodash";
import ContinuityBasic from "./ContinuityBasic";
// import ContinuityEnterprise from "./ContinuityEnterprise";
export default function ProductionTrafficDetails(props) {
  // Shijn + Chelsie confirmed:  Then it should be easy for you. No need to worry about the multi market flow we built in the past. Just keep the continuity  and dub tabs similar to normal workflow even if Enterprise is enabled.
  // const { info } = props;
  // const isEnableEnterprise = get(info, "production_multimarket", false);
  // return isEnableEnterprise ? (
  //   <ContinuityEnterprise {...props} />
  // ) : (
  //   <ContinuityBasic {...props} />
  // );
  return <ContinuityBasic {...props} />;
}
