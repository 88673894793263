import React from "react";
import { FormattedMessage } from "react-intl";
import TextInput from "components/TextInput";
import { HelpIcon } from "components/CustomIcons";
import Slider from "rc-slider";
import { Row, Col, FormGroup, Label } from "reactstrap";
import Tooltip from "rc-tooltip";
import {
  filter,
  find,
  get,
  uniq,
  forEach,
  forOwn,
  isEmpty,
  difference,
  map,
  toLower,
  upperCase,
} from "lodash";
import Dropdown from "components/Dropdown";
import PropTypes from "prop-types";
import Badge from "components/Badge";
import { Asterisk } from "components/Elements";
import SchedulingPreview, {
  renderFinalScheduleStatement,
} from "components/ScheduleComponents/SchedulingPreview";
import bn from "utils/bemnames";
import ExcludeHoursModal from "components/ExcludeHoursModal";
import moment from "moment";
import CustomMultiSelectDropdown from "components/CustomMultiSelectDropdown";
import classNames from "classnames";
const weekdaysMin = moment.weekdaysShort();

const bem = bn.create("schedule-preview");
function DesiredItems(props) {
  const { bem, total, onChange, value, intl } = props;
  return (
    <div className={bem.e("desired-items")}>
      <h2 className={bem.e("desired-items-title")}>
        <FormattedMessage id="process > total available items" />:
        <strong>
          {total > 0
            ? total
            : intl.formatMessage({ id: "process > undefined" })}
        </strong>
        {total > 0 ? (
          <Tooltip
            overlayClassName={bem.e("help-tooltip-overlay")}
            overlay={
              <div className={bem.e("help-tooltip")}>
                Lorem ipsum, or lipsum as it is sometimes known, is dummy text
                used in laying out print, graphic or web designs.
              </div>
            }
          >
            <span className={bem.e("help-icon")}>
              <HelpIcon />
            </span>
          </Tooltip>
        ) : null}
      </h2>
      <div className={bem.e("desired-slider")}>
        <span className={bem.e("desired-title")}>
          <FormattedMessage id="process > desired items" />:
        </span>
        <div className={bem.e("slider")}>
          <span className={bem.e("slider-value")}>0</span>
          <Slider
            onChange={onChange}
            value={value ? Number(value) : 0}
            reverse
            min={0}
            max={total}
          />
          <span className={bem.e("slider-value")}>{total}</span>
        </div>
        <TextInput
          value={value}
          placeholder="0"
          disabled
          onChange={(event) => {
            onChange(event.target.value);
          }}
        />
      </div>
    </div>
  );
}
export default function SchedulingSimple(props) {
  const {
    fields: { selected_items, schedule_by, excluded_hours, selected_by },
    step,
    intl,
    onValueChanged,
    process,
    checkIsInValidSchedulingCheck,
    validationState,
    isAllowShowDesiredItems,
    daypartsHours,
    showsHours,
    shows,
    dayparts,
    dateFormatByServer,
  } = props;
  const [isOpenExcludeHours, setIsOpenExcludeHours] = React.useState(false);
  const desired_items_scheduling = step.fields.find(
    (f) => f.field === "desired_items_scheduling"
  );
  // const isEdit = get(process, "data.fields.key.value");
  const schedulingType = get(process, "data.fields.scheduling_type.value");
  const selectedBy = get(process, "data.fields.selected_by.value", "");
  const scheduleBy = get(process, "data.fields.schedule_by.value", "");
  if (isAllowShowDesiredItems && checkIsInValidSchedulingCheck()) {
    return (
      <DesiredItems
        total={0}
        onChange={(value) => {
          onValueChanged(desired_items_scheduling, value);
        }}
        intl={intl}
        value={get(process, "data.fields.desired_items_scheduling.value", "")}
        bem={bem}
      />
    );
  }
  const renderSelectedItems = () => {
    let selectedItems = process.data.fields[selected_items.field]
      ? process.data.fields[selected_items.field].value
      : [];

    return selectedItems.map((item) => {
      return (
        <Badge
          key={item.value}
          text={item.label}
          onClick={() => {
            onValueChanged(selected_items, difference(selectedItems, [item]));
          }}
        />
      );
    });
  };
  const onSetActiveHoursBySelectedItems = () => {
    if (scheduleBy === "ROS") return [];

    const selectedItems = process.data.fields[selected_items.field]
      ? process.data.fields[selected_items.field].value
      : [];
    const data = selectedBy === "daypart" ? daypartsHours : showsHours;
    let hours = [];
    filter(data, (item) =>
      find(selectedItems, (i) => i.value === item.id)
    ).forEach((item) => {
      hours = uniq([...hours, ...item.hours]);
    });
    return hours;
  };
  const renderExcludedHours = () => {
    const excludedHours = get(
      process,
      "data.fields.excluded_hours_array.value"
    );
    if (!excludedHours && excludedHours.length === 0) return null;
    let items = [];
    const excludedHoursAll = get(
      find(excludedHours, (item) => item.key === "all"),
      "value",
      []
    ).map((item) => ({
      value: item.value,
      label: item.label,
      text: `${toLower(item.label)} - ALL`,
      key: "all",
    }));
    items = [...excludedHoursAll];
    forEach(excludedHours, (hourItem) => {
      const { key, value } = hourItem;
      if (key === "all") return;
      const itemsValue = value
        .filter((item) => {
          return !find(excludedHoursAll, (i) => i.value === item.value);
        })
        .map((item) => ({
          value: item.value,
          label: item.label,
          text: `${toLower(item.label)} - ${upperCase(
            weekdaysMin[parseInt(key) - 1]
          )}`,
          key,
        }));
      items = [...items, ...itemsValue];
    });
    return items.map((hour, index) => {
      return (
        <Badge
          key={`badge-excluded-hour-${index}`}
          text={hour.text}
          onClick={() => {
            let newValue = [];
            if (hour.key === "all") {
              newValue = excludedHours.map((hourItem) => {
                return {
                  ...hourItem,
                  value: filter(
                    get(hourItem, "value", []),
                    (i) => i.value !== hour.value
                  ),
                };
              });
            } else {
              newValue = excludedHours.map((hourItem) => {
                if (hourItem.key === hour.key) {
                  return {
                    ...hourItem,
                    value: filter(
                      hourItem.value,
                      (i) => i.value !== hour.value
                    ),
                  };
                }
                return hourItem;
              });
            }

            const newValueFormatted = filter(newValue, (hourItem) => {
              return get(hourItem, "value.length", 0) > 0;
            });
            onValueChanged(excluded_hours, newValueFormatted);
          }}
        />
      );
    });
  };
  const ExcludeHoursButton = () => {
    return (
      <div
        className="exclude-dates-btn"
        onClick={() => {
          setIsOpenExcludeHours(true);
        }}
      >
        <FormattedMessage id="process > promotion > liner > exclude hours" />
      </div>
    );
  };
  const selectedByOptions = schedule_by.props.options.filter((item) =>
    item.scheduling_type.includes(schedulingType)
  );
  return (
    <div className={bem.e("simple-scheduling-preview")}>
      <Row>
        <Col xs={6} className={bem.e("reset-col")}>
          <FormGroup>
            <Dropdown
              label={
                <span>
                  {schedule_by.title}
                  {schedule_by.mandatory && <Asterisk>*</Asterisk>}
                </span>
              }
              name="schedule_by"
              onChange={(selectedOption) => {
                if (["daypart", "show"].includes(selectedOption.value)) {
                  onValueChanged(selected_by, selectedOption.value);
                } else {
                  onValueChanged(selected_by, "show");
                }
                onValueChanged(schedule_by, selectedOption.value);
                onValueChanged(selected_items, []);
                onValueChanged(excluded_hours, []);
              }}
              value={find(
                selectedByOptions,
                (item) => item.value === scheduleBy
              )}
              error={get(validationState, "schedule_by.validation_error")}
              options={selectedByOptions}
            />
            <ExcludeHoursButton />
          </FormGroup>
        </Col>
        <Col xs={6} className={bem.e("reset-col")}>
          {scheduleBy === "daypart" && (
            <FormGroup>
              <Label>
                <FormattedMessage id="process > promotion > liner > scheduling > dayparts" />
                <Asterisk>*</Asterisk>
              </Label>
              <CustomMultiSelectDropdown
                name="selected_items"
                value={
                  get(process.data.fields[selected_items.field], "value", null)
                    ? get(
                        process.data.fields[selected_items.field],
                        "value",
                        []
                      )
                    : []
                }
                options={map(dayparts, (daypart) => ({
                  label: daypart.name,
                  value: daypart.id,
                }))}
                placeholder={intl.formatMessage({
                  id: "process > promotion > liner > select",
                })}
                isMulti={true}
                error={get(validationState, "selected_items.validation_error")}
                onChange={(value) => {
                  onValueChanged(selected_items, value);
                }}
              />
            </FormGroup>
          )}
          {scheduleBy === "show" && (
            <FormGroup>
              <Label>
                <FormattedMessage id="process > promotion > liner > scheduling > shows" />
                <Asterisk>*</Asterisk>
              </Label>
              <CustomMultiSelectDropdown
                name="selected_items"
                error={get(validationState, "selected_items.validation_error")}
                value={
                  get(process.data.fields[selected_items.field], "value", null)
                    ? get(
                        process.data.fields[selected_items.field],
                        "value",
                        []
                      )
                    : []
                }
                options={map(shows, (show) => ({
                  label: show.name,
                  value: show.id,
                }))}
                placeholder={intl.formatMessage({
                  id: "process > promotion > liner > select",
                })}
                isMulti={true}
                onChange={(value) => {
                  onValueChanged(selected_items, value);
                }}
              />
            </FormGroup>
          )}
        </Col>
      </Row>
      <Row className={bem.e("badges")}>
        {process.data.fields[selected_items.field] &&
          !isEmpty(process.data.fields[selected_items.field].value) && (
            <Col className={bem.e("reset-col")}>
              <FormGroup>
                <Label>
                  {selectedBy === "show" ? (
                    <FormattedMessage id="process > promotion > liner > scheduling > selected shows" />
                  ) : selectedBy === "daypart" ? (
                    <FormattedMessage id="process > promotion > liner > scheduling > selected dayparts" />
                  ) : (
                    <FormattedMessage id="process > promotion > liner > scheduling > select hours" />
                  )}
                </Label>
                <div
                  className={classNames(bem.e("badges-container"), {
                    [bem.e("badges-hours")]: selectedBy === "hour",
                  })}
                >
                  {renderSelectedItems()}
                </div>
              </FormGroup>
            </Col>
          )}
        {process.data.fields[excluded_hours.field] &&
          !isEmpty(process.data.fields[excluded_hours.field].value) && (
            <Col className={bem.e("reset-col")}>
              <FormGroup>
                <Label>
                  <FormattedMessage id="process > promotion > liner > scheduling > excluded hours" />
                </Label>
                <div
                  className={classNames(
                    bem.e("badges-container"),
                    bem.e("badges-excluded-hours")
                  )}
                >
                  {renderExcludedHours()}
                </div>
              </FormGroup>
            </Col>
          )}
      </Row>
      <Row>
        <Col xs={12}>
          <div className={bem.e("scheduling-statement-header")}>
            <FormattedMessage id="process > promotion > liner > scheduling > final statement" />
          </div>
          <div className={bem.e("scheduling-statement-text")}>
            {renderFinalScheduleStatement(process, dateFormatByServer)}
          </div>
        </Col>
      </Row>
      <SchedulingPreview
        intl={intl}
        bem={bem}
        process={process}
        schedulingType={schedulingType}
        onValueChanged={onValueChanged}
        validationState={validationState}
        step={step}
        isShowRule={true}
        template={props.template}
        user={props.user}
        buttonClicked={props.buttonClicked}
        isAllowShowDesiredItemsDescription={
          props.isAllowShowDesiredItemsDescription
        }
        isAllowManualDesiredItems={props.isAllowManualDesiredItems}
        schedulingPreviewHelpText={props.schedulingPreviewHelpText}
        isSchedulingPreviewRequired={props.isSchedulingPreviewRequired}
      />
      <ExcludeHoursModal
        isOpen={isOpenExcludeHours}
        onToggle={() => setIsOpenExcludeHours(!isOpenExcludeHours)}
        daysOfWeek={get(process, "data.fields.days_of_week.value", [])}
        onSubmit={(values) => {
          let valuesArray = [];
          forOwn(values, (value, key) => {
            valuesArray = [
              ...valuesArray,
              {
                key,
                value,
              },
            ];
          });
          onValueChanged(excluded_hours, valuesArray);
          setIsOpenExcludeHours(false);
        }}
        value={get(process, "data.fields.excluded_hours_array.value")}
        activeHours={onSetActiveHoursBySelectedItems()}
      />
    </div>
  );
}
SchedulingSimple.defaultProps = {
  isAllowShowDesiredItems: true,
  isSchedulingPreviewRequired: false,
};
SchedulingSimple.propTypes = {
  isAllowShowDesiredItems: PropTypes.bool,
  isSchedulingPreviewRequired: PropTypes.bool,
};
