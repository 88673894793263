import * as React from "react";
import { compose, withHandlers, withState, lifecycle } from "recompose";
import { connect } from "react-redux";
import { MdImportantDevices } from "react-icons/lib/md";
import ToastManager from "components/ToastManager";
import {
  updateSettings,
  setIsNotification,
  setLanguage,
} from "store/actions/auth";
import history, { hashHistory } from "components/History";
import { pick, get } from "lodash";
import URL from "utils/urls";
import View from "./view";
import { injectIntl } from "react-intl";

export default injectIntl(
  compose(
    connect(({ auth }) => ({ auth }), {
      updateSettings,
      setIsNotification,
      setLanguage,
    }),
    withState("isLoading", "setIsLoading", false),
    withState("isChangedLanguage", "setIsChangedLanguage", false),
    withState("shouldBlockNavigation", "setShouldBlockNavigation", false),
    withHandlers({
      onClickNotification: () => async (anchor) => {
        history.push(URL.HELP());
        hashHistory.push(anchor);
      },
    }),
    withHandlers({
      // onChangeIsNotification: ({ auth, setIsNotification }) => status => {
      //   let message;
      //   if(!auth.is_notification && status){
      //     Notification
      //     .requestPermission()
      //     .then((permission) => {
      //       if(permission === 'granted'){
      //         setIsNotification(true);
      //       }else{
      //         message = "Notifications blocked. Please enable them in your browser.";
      //         ToastManager.show({
      //           title: <MdImportantDevices />,
      //           message,
      //           autoDismiss: 1.5,
      //           level: 'info'
      //         });
      //       }
      //     });
      //   }else {
      //     setIsNotification(false);
      //   }
      // },
      onFormSubmit: ({
        updateSettings,
        setIsNotification,
        auth,
        setIsChangedLanguage,
      }) => async (data, cb) => {
        const response = await updateSettings(
          {
            ...data,
            language: get(data, "language.value"),
            ...pick(auth.user, ["email", "first_name", "last_name"]),
          },
          cb
        );
        if (response) {
          ToastManager.show({
            title: <MdImportantDevices />,
            message: response.message,
            autoDismiss: 1.5,
            level: response.status ? "success" : "error",
          });
          setIsNotification(data.notify);
          if (get(auth, "user.lang") !== get(data, "language.value")) {
            setIsChangedLanguage(true);
          }
        }
      },
      onNotify: ({ auth, onClickNotification }) => async ({ title }) => {
        if (auth.notify) {
          const options = {
            body: "Here is my description",
            icon:
              "https://promosuite-live.s3.amazonaws.com/9/c/d/e-8597-446b-860a-2c3ea47ca8013540f458-cfcb-4f07-b94f-3b7b173bf785||avatar_white_circle.png",
            vibrate: [50, 100, 150],
          };

          const notification = new Notification(title, options);
          setTimeout(() => {
            notification.close();
          }, 6000);
          // click on notification
          const anchor = "notification";
          notification.onclick = () => onClickNotification(anchor);
        } else {
          ToastManager.show({
            title: <MdImportantDevices />,
            message:
              "Notifications blocked. Please enable them in your browser.",
            autoDismiss: 1.5,
            level: "info",
          });
        }
      },
    }),
    lifecycle({
      componentWillUnmount() {
        if (!this.props.isChangedLanguage) {
          const userLangCode = get(this.props, "auth.user.lang", null);
          this.props.setLanguage(userLangCode);
        }
      },
    })
  )(View)
);
