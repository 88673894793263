import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import OrderDetailsDuplicate from "./view";
import { copyOrder, setActiveEditTab } from "store/actions/processes";
import { injectIntl } from "react-intl";
import { compose, withHandlers, withState } from "recompose";
import ToastManager from "components/ToastManager";
import { MdImportantDevices } from "react-icons/lib/md";
import { find, first, get } from "lodash";
import history from "components/History";
import URL from "utils/urls";

const Enhanced = compose(
  connect(
    (state, props) => ({
      user: state.auth.user,
      ...props,
    }),
    {
      copyOrder,
      setActiveEditTab,
    }
  ),
  withState("isLoading", "setIsLoading", false),
  withHandlers({
    onSubmit: ({
      copyOrder,
      close,
      process,
      extraData,
      intl,
      setIsLoading,
      setActiveEditTab,
    }) => (values) => {
      setIsLoading(true);
      const processKey = get(process, "data.fields.key.value", "");
      const spots = get(process, "data.fields.spot_info.value", []);
      var spot = false;
        // find(spots, (item) => item.key === processKey) || first(spots);
      var spotIndex = 0;
      spots.map(item => {
        spotIndex++;
        if(spot) return;
        if(item.key === processKey) {
          spot = item;
          if(!spot.uuid) spot.uuid = 'spot_' + spotIndex;
        }
       });
      if(!spot) spot = first(spots);
      if (!spot) {
        setIsLoading(false);
        ToastManager.show({
          title: <MdImportantDevices />,
          message: intl.formatMessage({
            id: "process > spot not found",
          }),
          level: "error",
        });
        return;
      }
      if(!spot.uuid) spot.uuid = 'spot_1';
      const copyFields = [];
      Object.entries(values).forEach(([key, value]) => {
        if (value) {
          copyFields.push(key);
        }
      });
      const params = {};
      params[spot.uuid] = {
        template: extraData.template_id,
        copy_fields: copyFields,
      };
      copyOrder(
        {
          id: processKey,
          copy_instructions: params,
        },
        (response) => {
          setIsLoading(false);
          ToastManager.show({
            title: <MdImportantDevices />,
            message: intl.formatMessage({
              id: "process > copy order successfully",
            }),
            level: "success",
          });
          if (response) {
            setActiveEditTab("order_details");
            history.push(
              URL.UPDATE_PROCESS({
                template_id: response.template_key,
                process_id: response.id,
              })
            );
            close(); // close current modal
          }
        }
      );
    },
  })
)(OrderDetailsDuplicate);

export default withRouter(injectIntl(Enhanced));
