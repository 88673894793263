import React from "react";
import { FormattedMessage } from "react-intl";
import { get } from "lodash";
import bn from "utils/bemnames";
import PropTypes from "prop-types";
import classNames from "classnames";
import StationImage from "components/JockConsole/Common/StationImage";
import FormContent from "./FormContent";
const bem = bn.create("preview-system-form");

const PreviewForm = (props) => {
  const {
    systemForm,
    intl,
    onClose,
    currentStation,
    currentServer,
    isConsoleForm,
    isCommonForm,
    isFulfillment,
    isShowMobile,
    isContestPreview,
    title,
    description,
  } = props;
  return (
    <div className={bem.b()}>
      {isConsoleForm && (
        <React.Fragment>
          <div className={bem.e("title-green")}>
            <FormattedMessage id="form manager > add new winner" />
          </div>
          <div className={bem.e("head-description")}>
            <FormattedMessage id="form manager > new winner helper text" />
          </div>
        </React.Fragment>
      )}
      {isCommonForm && (
        <div className={bem.e("form-name")}>{systemForm._source.name}</div>
      )}
      {isFulfillment && (
        <div className={bem.e("station-section")}>
          <div className={bem.e("station-image")}>
            <StationImage station={get(currentStation, "data", {})} />
          </div>
          {!isContestPreview ? (
            get(currentStation, "data.call_letters") && (
              <div className={bem.e("station-title")}>
                {currentStation.data.call_letters}
              </div>
            )
          ) : (
            <div className={bem.e("station-title")}>{title}</div>
          )}

          {!isContestPreview ? (
            get(currentStation, "data.slogan") && (
              <div className={bem.e("station-description")}>
                {currentStation.data.slogan}
              </div>
            )
          ) : (
            <div
              className={classNames(
                bem.e("station-description"),
                bem.e("script-description")
              )}
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
          )}
        </div>
      )}
      <FormContent
        systemForm={systemForm}
        intl={intl}
        onClose={onClose}
        currentStation={currentStation}
        currentServer={currentServer}
        isConsoleForm={isConsoleForm}
        isCommonForm={isCommonForm}
        isFulfillment={isFulfillment}
        isShowMobile={isShowMobile}
      />
    </div>
  );
};
PreviewForm.propTypes = {
  onClose: PropTypes.func,
  systemForm: PropTypes.object.isRequired,
  isShowMobile: PropTypes.bool,
  isContestPreview: PropTypes.bool,
};
PreviewForm.defaultProps = {
  isContestPreview: false,
  title: "",
  description: "",
};
export default PreviewForm;
