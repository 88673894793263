import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";
import { FormattedMessage } from "react-intl";
import Dropdown from "components/Dropdown";
import bn from "utils/bemnames";
const bem = bn.create("client-reassign-modal");

const ClientReassignModal = ({
  onToggle,
  isOpen,
  title,
  users,
  intl,
  onSubmit,
}) => {
  const [selected, setSelected] = useState(null);
  return (
    <Modal isOpen={isOpen} className={bem.b()} size="md">
      <ModalHeader toggle={onToggle} className={bem.e("header")}>
        {title}
      </ModalHeader>
      <ModalBody className={bem.e("body")}>
        <Dropdown
          label={null}
          value={selected}
          placeholder={intl.formatMessage({
            id: "client manager > select",
          })}
          onChange={(selectedOption) => {
            setSelected(selectedOption);
          }}
          options={[
            {
              label: "",
              value: "",
            },
            ...users,
          ]}
          name="user"
        />
      </ModalBody>
      <ModalFooter className={bem.e("footer")}>
        <Button
          disabled={!selected}
          onClick={() => {
            if (!selected) return;
            onSubmit(selected);
            setSelected(null);
          }}
          color="primary"
          className={"btn-blue btn-radius"}
        >
          <FormattedMessage id="client manager > button assign" />
        </Button>
        <Button
          onClick={() => {
            onToggle();
            setSelected(null);
          }}
          outline
          color="primary"
          className={"btn-blue btn-radius"}
        >
          <FormattedMessage id="client manager > button cancel" />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ClientReassignModal.defaultProps = {
  isOpen: false,
  onToggle: () => {},
  title: <FormattedMessage id="client manager > reassign modal title" />,
};
ClientReassignModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  title: PropTypes.any.isRequired,
};
export default ClientReassignModal;
