import React from "react";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import { get, isEmpty, forEach } from "lodash";
import { FaSortDesc, FaSortAsc } from "react-icons/lib/fa";
import DeadlineTimer from "components/DeadlineTimer";
import { Table } from "reactstrap";
import EmptyAnnouncement from "components/EmptyAnnouncement";
import useInfiniteScroll from "utils/useInfiniteScroll";
import DubButton from "./DubButton";
import { validatePrivileges } from "utils/helpers";
import { PRIVILEGES } from "utils/constants";
import { titleDisplay } from "utils/helpers";

const View = ({
  actionSort,
  sort,
  processList,
  bem,
  onClickRow,
  user,
  hasMoreItems,
  loadItems,
  isLoading,
}) => {
  let isWODubbingEnabled = get(user, "enable_wo_dubbing", false);

  const privileges = get(user, "privileges", []) || [];

  const isDubber = validatePrivileges({
    requires: {
      or: [
        PRIVILEGES.PERFORM_PRODUCTION_DUBBING,
        PRIVILEGES.EDIT_ALL_PRODUCTION_ORDERS,
      ],
    },
    privileges,
    user,
  });

  const isDisabledDub = (process) => {
    let audioUploaded = true;
    forEach(get(process, "_source.spot_info", []), (spot) => {
      if (isEmpty(get(spot, "recording_path", ""))) {
        audioUploaded = false;
        return false;
      }
    });

    return (
      isEmpty(get(process, "_source.continuity_cart")) ||
      !isDubber ||
      !audioUploaded
    );
  };

  const timeToAirDisplay = (process) => {
    const date = get(process, "_source.order_start_date");
    const timezone = get(process, "_source.timezone", null);
    if (!date) return null;
    return <DeadlineTimer date={date} timezone={timezone} />;
  };
  const spotDisplay = (process) => {
    if (get(process, "_source.contract_status") === "Production Needed")
      return null;
    return get(process, "_source.spot_info.length") > 1
      ? `(+${get(process, "_source.spot_info.length") - 1} spot)`
      : "";
  };
  const typeDisplay = (process) => {
    const orderSold = get(process, "_source.order_sold", true);
    return (
      <div
        className={classnames(bem.e("type"), {
          [bem.e("type-sold")]: orderSold,
          [bem.e("type-spec")]: !orderSold,
        })}
      >
        <FormattedMessage
          id={orderSold ? "process > order sold" : "process > order spec"}
        />
      </div>
    );
  };


  const renderEmpty = () => {
    return <EmptyAnnouncement />;
  };

  useInfiniteScroll({
    useWindow: false,
    elementId: "cr-app-main",
    onLoadMore: loadItems,
    shouldLoadMore: hasMoreItems,
  });

  return (
    <div className={bem.e("table-wrapper")}>
      <Table className={bem.e("table")}>
        <thead>
          <tr>
            <th
              onClick={() => actionSort("order_sold")}
              className={classnames(
                "can-click",
                "col-table-10",
                bem.e("table-first-head")
              )}
            >
              <div className={bem.e("item-sortable")}>
                <FormattedMessage id="process > Type" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_sold",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_sold desc",
                    })}
                  />
                </div>
              </div>
            </th>

            <th
              onClick={() => actionSort("client_name")}
              className="can-click col-table-15"
            >
              <div className={bem.e("item-sortable")}>
                <FormattedMessage id="process > Client" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "client_name",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "client_name desc",
                    })}
                  />
                </div>
              </div>
            </th>
            <th
              onClick={() => actionSort("order_title")}
              className="can-click col-table-20"
            >
              <div className={bem.e("item-sortable")}>
                <FormattedMessage id="process > Title" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_title",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_title desc",
                    })}
                  />
                </div>
              </div>
            </th>
            <th
              onClick={() => actionSort("order_length")}
              className="can-click col-table-10"
            >
              <div className={bem.e("item-sortable-center")}>
                <FormattedMessage id="process > length" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_length",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_length desc",
                    })}
                  />
                </div>
              </div>
            </th>

            <th className="text-center align-center col-table-20">
              <FormattedMessage id="process > spots" />
            </th>
            <th
              onClick={() => actionSort("order_start_date")}
              className="can-click col-table-10"
            >
              <div className={bem.e("item-sortable-center")}>
                <FormattedMessage id="process > Deadline" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_start_date",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        sort === "order_start_date desc",
                    })}
                  />
                </div>
              </div>
            </th>
            <th className="text-center align-center col-table-20">
              <FormattedMessage id="process > action" />
            </th>
          </tr>
        </thead>
        <tbody>
          {processList.length === 0 && !isLoading ? (
            <tr>
              <td colSpan="7">{renderEmpty()}</td>
            </tr>
          ) : (
            processList.map((process) => {
              if (!process._source.template_key) return null;
              let spot = spotDisplay(process);
              return (
                <tr key={process._id}>
                  {/* type */}
                  <td
                    className={classnames(
                      "align-middle col-table-10",
                      bem.e("first-col")
                    )}
                    onClick={() => onClickRow(process)}
                  >
                    <span className={bem.e("td-value")}>
                      {typeDisplay(process)}
                    </span>
                  </td>
                  {/* client */}
                  <td
                    className={classnames(
                      "align-middle col-table-15",
                      bem.e("client-name")
                    )}
                    onClick={() => onClickRow(process)}
                  >
                    <span className={bem.e("td-value")}>
                      {process._source.client_name}
                    </span>
                  </td>
                  {/* title */}
                  <td
                    className={classnames(
                      "align-middle col-table-20",
                      bem.e("order-title")
                    )}
                    onClick={() => onClickRow(process)}
                  >
                    <span className={bem.e("td-value")}>
                      {titleDisplay(process)}
                    </span>
                  </td>
                  {/* order_length */}
                  <td
                    className="align-middle col-table-10 text-center"
                    onClick={() => onClickRow(process)}
                  >
                    <span className={bem.e("td-value")}>
                      {process._source.order_length
                        ? `:${process._source.order_length}`
                        : ""}
                    </span>
                  </td>
                  {/* assignments */}
                  <td
                    className={classnames(
                      "align-middle col-table-20 text-center"
                    )}
                  >
                    <span className={bem.e("td-value")}>
                      {process._source.spot_info
                        ? process._source.spot_info.length
                        : ""}
                    </span>
                  </td>
                  {/* time to air */}
                  <td
                    className="align-middle col-table-10 text-center"
                    onClick={() => onClickRow(process)}
                  >
                    <span className={bem.e("td-value")}>
                      {timeToAirDisplay(process)}
                    </span>
                  </td>
                  {/* status */}
                  <td className="align-middle col-table-15 text-center">
                    <span className={bem.e("td-value")}>
                      <DubButton
                        processId={get(process, "_id")}
                        isWODubbingEnabled={isWODubbingEnabled}
                        bem={bem}
                        isDisabled={isDisabledDub(process)}
                        isDubbingCompleted={
                          get(process, "_source.dub_script_status", "") ===
                          "completed"
                        }
                        maxProgressBarLength={130}
                        buttonKey={`${get(process, "_id")}_all`}
                      />
                    </span>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </Table>
    </div>
  );
};
export default View;
