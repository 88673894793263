import View from "./view";
import moment from "moment";
import { get, forEach, has, findIndex } from "lodash";
import { compose, lifecycle, withState, withHandlers } from "recompose";
import { getSchedulesLog, saveAsPdf } from "store/actions/programmings";
import { connect } from "react-redux";
import ToastManager from "components/ToastManager";
import { labelToNameField } from "utils/helpers";
import { download } from "components/UploadFile/helper";
import { injectIntl } from "react-intl";
const Enhanced = compose(
  connect(
    (state) => {
      return {
        itemsRun: get(state, "programmings.schedulesLog.data", {}),
        dateFormatByServer: get(
          state,
          "servers.currentServer.data.date_format",
          "MM/DD/YYYY"
        ),
      };
    },
    { getSchedulesLog, saveAsPdf }
  ),
  withState("logs", "setLogs", null),
  withState("scheduleLogs", "setScheduleLogs", null),
  withState("activeTab", "setActiveTab", "log"),
  withHandlers({
    onDownload: ({ process, saveAsPdf, intl }) => () => {
      const processId = get(process, "data.fields.key.value");
      const stationId = get(
        process,
        "data.fields.order_stations_array.value.0.key"
      );
      saveAsPdf(
        {
          process_id: processId,
          station_id: stationId,
        },
        (responseBlob) => {
          if (responseBlob) {
            const title = get(process, "data.fields.order_title.value");
            const fileName = `${labelToNameField(title)}.pdf`;
            download(responseBlob, fileName);
          } else {
            ToastManager.show({
              title: intl.formatMessage({ id: "process > error" }),
              message: intl.formatMessage({
                id: "process > error save as pdf",
              }),
              level: "error",
            });
          }
        }
      );
    },
    onToggleTab: ({ setActiveTab, activeTab }) => (tab) => {
      if (activeTab !== tab) setActiveTab(tab);
    },
    generateLogs: ({ setLogs, setScheduleLogs }) => (messages) => {
      let logs = {};
      let scheduleLogs = {};
      let incomingMessages = [];
      forEach(messages, (message) => {
        if (!message._id) {
          const isDuplicate =
            findIndex(
              incomingMessages,
              (m) => m._source.created_ts === message._source.created_ts
            ) !== -1;
          if (isDuplicate) {
            return;
          }
          incomingMessages = [...incomingMessages, message];
        }
        const mType = get(message, "_source.m_type", null);
        const seconds = get(message, "_source.created_ts") * 1000;
        if (!moment(seconds).isValid()) {
          return;
        }
        const dateTime = moment(seconds).format("MMMM DD, YYYY");
        const timer = moment(seconds).format("hh:mm a");
        if (mType === "schedule_log") {
          const log = {
            timer,
            ...message._source,
          };
          if (has(scheduleLogs, dateTime)) {
            scheduleLogs[dateTime].push(log);
          } else {
            scheduleLogs[dateTime] = [];
            scheduleLogs[dateTime].push(log);
          }
          setScheduleLogs(scheduleLogs);
        } else {
          const dataLog = get(message, "_source");
          const deltaLog = get(message, "_source.delta");
          const user = get(dataLog, "from");
          let text = get(dataLog, "text", "");
          let stepName = get(dataLog, "from_step", "")
            .replace(/_/, " ", "gi")
            .replace("dub script", "dub spot", "gi");
          let toStep = (get(dataLog, "to_step", "") || "")
            .replace(/_/, " ", "gi")
            .replace("dub script", "dub spot", "gi");
          switch (mType) {
            case "chat":
              text = `${get(
                user,
                "name"
              )} wrote <strong class="chat-text">${get(
                dataLog,
                "chat"
              )}</strong>`;
              break;
            case "order_sent_back":
              text = `${get(
                user,
                "name"
              )} sent order back from <span class="step-name">${stepName}</span> to <span class="step-name">${toStep}</span>`;
              if (get(user, "impersonated_by")) {
                text += " - IMPERSONATED by " + user.impersonated_by.name + "";
              }
              break;
            case "sent_to_client":
              if (text !== "") {
                text = `${get(user, "name")} ${text}`;

                if (get(user, "impersonated_by")) {
                  text +=
                    " - IMPERSONATED by " + user.impersonated_by.name + "";
                }
              }
              break;
            default:
              if (text === "") {
                text = `${get(
                  user,
                  "name"
                )} saved step <span class="step-name">${stepName}</span>`;
                if (get(dataLog, "from_step") === "approve_script") {
                  text = `${get(user, "name")} ${get(
                    deltaLog,
                    "approve_script_status",
                    ""
                  )} script`;
                }
                if (dataLog.from_order_title)
                  text = `Order created from ${dataLog.from_order_title}`;
                if (mType === "review_media_feedback") {
                  text = `${get(user, "name")} added feedback(${get(
                    dataLog,
                    "number"
                  )}) for <span class="step-name"><a href="${get(
                    dataLog,
                    "file_path"
                  )}" target="_blank">${get(
                    dataLog,
                    "file_name"
                  )}</a></span> wrote ${get(dataLog, "message")}`;
                }
                if (
                  mType === "review_media_rejected" ||
                  mType === "review_media_approved"
                ) {
                  let review_status =
                    mType === "review_media_approved" ? "approved" : "rejected";
                  text = `${get(
                    user,
                    "name"
                  )} ${review_status} <span class="step-name"><a href="${get(
                    dataLog,
                    "file_path"
                  )}" target="_blank">${get(dataLog, "file_name")} (${get(
                    dataLog,
                    "field_label"
                  )})</a></span>`;
                }

                if (get(user, "impersonated_by"))
                  text +=
                    " - IMPERSONATED by " + user.impersonated_by.name + "";
              }
              break;
          }

          const log = {
            timer,
            text,
          };

          if (has(logs, dateTime)) {
            logs[dateTime].push(log);
          } else {
            logs[dateTime] = [];
            logs[dateTime].push(log);
          }
          setLogs(logs);
        }
      });
    },
  }),
  lifecycle({
    componentDidMount() {
      const messages = get(
        this.props,
        "process.data.fields.messages.value",
        []
      );
      this.props.generateLogs(messages);
      // get items run logs
      const isShowItemsRun = get(this.props, "step.is_show_items_run", false);
      if (isShowItemsRun) {
        const stationId = get(
          this.props,
          "process.data.fields.order_stations_array.value.0.key"
        );
        const processId = get(this.props, "process.data.fields.key.value");
        if (processId && stationId) {
          this.props.getSchedulesLog({
            data: {
              station_id: stationId,
              process_id: processId,
            },
          });
        }
      }
    },
    componentWillReceiveProps(nextProps) {
      const nextMessages = get(
        nextProps,
        "process.data.fields.messages.value",
        []
      );
      const prevMessages = get(
        this.props,
        "process.data.fields.messages.value",
        []
      );
      if (prevMessages.length !== nextMessages.length) {
        this.props.generateLogs(nextMessages);
      }
    },
  })
)(View);
export default injectIntl(Enhanced);
