import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { get, map, uniq, uniqBy } from "lodash";
import { Row, Col } from "reactstrap";
import { ShadowBox, SectionTitle, SectionFields } from "components/Elements";
import { DownIcon, UpIcon, MenuIcon, DeleteIcon } from "components/CustomIcons";
import AssociatedItems from "../AssociateItems";
import ToastManager from "components/ToastManager";
import {
  typeDisplay,
  spotDisplay,
  stationDisplay,
  flightDatesDisplay,
  titleDisplay,
} from "../AssociateItems";
import bn from "utils/bemnames";
const bem = bn.create("associated-items");

const SelectedItem = (props) => {
  const { item, onDelete, isView } = props;
  return (
    <Row
      className={classnames(
        bem.e("selected-associated-item"),
        "list-liner-item"
      )}
    >
      <Col xs={2} className={bem.e("col-reset")}>
        {typeDisplay(item)}
      </Col>
      <Col xs={4} className={bem.e("col-reset")}>
        {titleDisplay(item)}
        <span className={"sub-value"}>{spotDisplay(item)}</span>
      </Col>
      <Col xs={3} className={bem.e("col-reset")}>
        {titleDisplay(item)}
        <span className={"sub-value"}>{stationDisplay(item)}</span>
      </Col>
      <Col xs={3} className={bem.e("col-reset")}>
        {flightDatesDisplay(item)}
        {!isView ? (
          <span className={bem.e("trash")} onClick={() => onDelete(item)}>
            <DeleteIcon color="#795AFA" />
          </span>
        ) : null}
      </Col>
    </Row>
  );
};
const ContestAssociated = (props) => {
  const {
    process,
    step,
    onValueChanged,
    isView,
    openDetails,
    setOpenDetails,
    sectionKey,
    onRefreshAssociateElements,
    validationState,
  } = props;
  const [fieldRefs, setFieldRefs] = useState(null);
  const getFieldRefs = () => {
    if (fieldRefs) return fieldRefs;

    const associated_items_ids = step.fields.find(
      (f) => f.field === "associated_items_ids"
    );
    const associated_items = step.fields.find(
      (f) => f.field === "associated_items"
    );
    const payload = {
      associated_items_ids,
      associated_items,
    };

    setFieldRefs(payload);

    return payload;
  };
  const { associated_items_ids, associated_items } = getFieldRefs();
  const associatedItems = get(
    process,
    "data.fields.associated_items.value",
    []
  );
  const associatedItemsIds = get(
    process,
    "data.fields.associated_items_ids.value",
    []
  );
  const isOpen = get(openDetails, sectionKey, false);

  return (
    <ShadowBox className={bem.e("box-script-details")}>
      <SectionTitle
        className={bem.e("contest-script-section-title")}
        onClick={() =>
          setOpenDetails({
            ...openDetails,
            [sectionKey]: !isOpen,
          })
        }
      >
        <div className={bem.e("box-head-title")}>
          <span className={"menu-selection-icon"}>
            <MenuIcon />
          </span>
          <FormattedMessage id="process > promotion > contest > title contest associated" />
        </div>
        <span className={bem.e("box-action")}>
          {isOpen ? <UpIcon /> : <DownIcon />}
        </span>
      </SectionTitle>
      {isOpen && (
        <React.Fragment>
          <SectionFields
            className={classnames(
              bem.e("section-fields"),
              bem.e("contest-associated-fields")
            )}
          >
            {get(associatedItems, "length", 0) > 0 ? (
              <div className={bem.e("associated-items")}>
                {map(associatedItems, (item, index) => (
                  <SelectedItem
                    bem={bem}
                    item={item}
                    key={index}
                    isView={isView}
                    onDelete={(item) => {
                      onValueChanged(
                        associated_items_ids,
                        uniq(
                          associatedItemsIds.filter(
                            (itemId) => itemId !== item.key
                          )
                        )
                      );
                      onValueChanged(
                        associated_items,
                        uniqBy(
                          associatedItems.filter((i) => i.key !== item.key),
                          "key"
                        )
                      );
                      onRefreshAssociateElements();
                    }}
                  />
                ))}
              </div>
            ) : (
              <div className={bem.e("selected-associated-item")}>
                <div className={bem.e("associated-empty-text")}>
                  <FormattedMessage id="process > promotion > contest > title empty associated item" />
                </div>
              </div>
            )}
            {get(validationState, "associated_items_ids.validation_error") &&
              typeof validationState.associated_items_ids.validation_error ===
                "string" && (
                <span className="alert-danger">
                  {validationState.associated_items_ids.validation_error}
                </span>
              )}
            <AssociatedItems
              process={process}
              isView={isView}
              onSelectItem={(item) => {
                onValueChanged(
                  associated_items_ids,
                  uniq([...associatedItemsIds, item.key])
                );
                onValueChanged(
                  associated_items,
                  uniqBy([...associatedItems, item], "key")
                );
                ToastManager.show({
                  title: props.intl.formatMessage({
                    id: "toast > title added",
                  }),
                  level: "success",
                });
              }}
            />
          </SectionFields>
        </React.Fragment>
      )}
    </ShadowBox>
  );
};

export default ContestAssociated;
