import { compose, lifecycle } from "recompose";
import { logoutUser } from "store/actions/auth";
import { connect } from "react-redux";
import View from "./view";
export default compose(
  connect(
    () => {
      return {};
    },
    { logoutUser }
  ),
  lifecycle({
    componentDidMount() {
      this.props.logoutUser();
    },
  })
)(View);
