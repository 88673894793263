export const FETCH_CLIENTS_REQUEST = "FETCH_CLIENTS_REQUEST";
export const FETCH_CLIENTS_SUCCESS = "FETCH_CLIENTS_SUCCESS";
export const FETCH_CLIENTS_ERROR = "FETCH_CLIENTS_ERROR";

export const FETCH_CLIENT_REQUEST = "FETCH_CLIENT_REQUEST";
export const FETCH_CLIENT_SUCCESS = "FETCH_CLIENT_SUCCESS";
export const FETCH_CLIENT_ERROR = "FETCH_CLIENT_ERROR";

export const UPDATE_CLIENT_REQUEST = "UPDATE_CLIENT_REQUEST";
export const UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENT_SUCCESS";
export const UPDATE_CLIENT_ERROR = "UPDATE_CLIENT_ERROR";

export const CREATE_CLIENT_REQUEST = "CREATE_CLIENT_REQUEST";
export const CREATE_CLIENT_SUCCESS = "CREATE_CLIENT_SUCCESS";
export const CREATE_CLIENT_ERROR = "CREATE_CLIENT_ERROR";

export const SET_CLIENT = "SET_CLIENT";

export const REASSIGN_CLIENTS_REQUEST = "REASSIGN_CLIENTS_REQUEST";
export const REASSIGN_CLIENTS_SUCCESS = "REASSIGN_CLIENTS_SUCCESS";
export const REASSIGN_CLIENTS_ERROR = "REASSIGN_CLIENTS_ERROR";

export const SEND_MESSAGE_CLIENT_REQUEST = "SEND_MESSAGE_CLIENT_REQUEST";
export const SEND_MESSAGE_CLIENT_SUCCESS = "SEND_MESSAGE_CLIENT_SUCCESS";
export const SEND_MESSAGE_CLIENT_ERROR = "SEND_MESSAGE_CLIENT_ERROR";

export const DELETE_CLIENT_REQUEST = "DELETE_CLIENT_REQUEST";
export const DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS";
export const DELETE_CLIENT_ERROR = "DELETE_CLIENT_ERROR";
