import React from "react";
import moment from "moment";
import bn from "utils/bemnames";
const bem = bn.create("calendar-time");
export default function TooltipHourRange(props) {
  const { startTime, endTime, title } = props;
  const startHour = moment(startTime / 60, "HH").format("hA");
  const endHour = moment(endTime / 60, "HH").format("hA");
  return (
    <div className={bem.e("hour-range-content")}>
      <span className={bem.e("hour-tooltip-time")}>
        {startHour}-{endHour}
      </span>
      {title ? (
        <span className={bem.e("hour-tooltip-text")}>{title}</span>
      ) : null}
    </div>
  );
}
