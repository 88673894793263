import React from "react";
import bn from "utils/bemnames";
import { FormattedMessage } from "react-intl";
import { labelToNameField } from "utils/helpers";
import { FormGroup, Row, Col, Label } from "reactstrap";
import TextInput from "components/TextInput";
import { get } from "lodash";
import { Asterisk } from "components/Elements";
import classNames from "classnames";
import { getFieldName } from "../helper";
const bem = bn.create("form-builder-fields");

const FirstNameAndLastName = ({
  intl,
  isNew,
  field,
  value,
  values, // values of formik
  errors,
  touched,
  onChange,
  isFormBuilder,
  isView,
  disabled,
  isShowRequired,
  colNumber,
  isShowMobile,
  isRenderFieldNameFromLabel,
}) => {
  // UI for form builder
  if (isFormBuilder) {
    if (isNew) {
      return (
        <Row>
          <Col className="form-builder-field">
            <div className={bem.b()}>
              <div className={bem.e("first-last-name")}>
                <Row>
                  <Col xs={9} className={bem.e("col-reset")}>
                    <FormattedMessage id="form builder > first and last name" />
                  </Col>
                  {isShowRequired && (
                    <Col xs={3} className={bem.e("col-reset")}>
                      <span className={bem.e("required-text")}>
                        <FormattedMessage id="form builder > required" />
                      </span>
                    </Col>
                  )}
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    // ui for form
  } else {
    const firstName = get(field, "sub_fields", []).find(
      (item) => item.key === "first_name"
    );
    const lastName = get(field, "sub_fields", []).find(
      (item) => item.key === "last_name"
    );
    const fieldNameOfFirstName = isRenderFieldNameFromLabel
      ? labelToNameField(firstName ? firstName.label : "")
      : getFieldName(firstName);
    const fieldNameOfLastName = isRenderFieldNameFromLabel
      ? labelToNameField(lastName ? lastName.label : "")
      : getFieldName(lastName);
    if (isView) {
      return (
        <Row>
          <Col>
            <div className={bem.b()}>
              <div className={bem.e("form-field-group-saved")}>
                <Label>{get(field, "label", "")}</Label>
                <span className="order-view-value">
                  {value ? (
                    value
                  ) : (
                    <FormattedMessage id="form builder > none" />
                  )}
                </span>
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    return (
      <Col xs={colNumber}>
        <div className={classNames(bem.b(), "form-builder-edit-field")}>
          <Row>
            {fieldNameOfFirstName ? (
              <Col xs={isShowMobile ? 12 : 6}>
                <FormGroup className={bem.e("sub-form-group")}>
                  <TextInput
                    label={
                      <span>
                        <FormattedMessage id="form builder > first name" />{" "}
                        {get(firstName, "is_required", false) && (
                          <Asterisk>*</Asterisk>
                        )}
                      </span>
                    }
                    name={fieldNameOfFirstName}
                    maxCharacters={field.max_characters}
                    type="text"
                    placeholder={intl.formatMessage({
                      id: "form builder > placeholder first name",
                    })}
                    value={get(values, fieldNameOfFirstName, "")}
                    disabled={disabled}
                    onChange={({ target }) =>
                      onChange(target.value, { field: firstName })
                    }
                    error={
                      get(touched, fieldNameOfFirstName, false) &&
                      get(errors, fieldNameOfFirstName, "")
                        ? intl.formatMessage({
                            id: "validate > first name is required",
                          })
                        : ""
                    }
                  />
                </FormGroup>
              </Col>
            ) : null}
            {fieldNameOfLastName ? (
              <Col xs={isShowMobile ? 12 : 6}>
                <FormGroup className={bem.e("sub-form-group")}>
                  <TextInput
                    label={
                      <span>
                        <FormattedMessage id="form builder > last name" />{" "}
                        {get(lastName, "is_required", false) && (
                          <Asterisk>*</Asterisk>
                        )}
                      </span>
                    }
                    name={fieldNameOfLastName}
                    maxCharacters={lastName.max_characters}
                    type="text"
                    placeholder={intl.formatMessage({
                      id: "form builder > placeholder last name",
                    })}
                    value={get(values, fieldNameOfLastName, "")}
                    disabled={disabled}
                    onChange={({ target }) =>
                      onChange(target.value, { field: lastName })
                    }
                    error={
                      get(touched, fieldNameOfLastName, false) &&
                      get(errors, fieldNameOfLastName, "")
                        ? intl.formatMessage({
                            id: "validate > last name is required",
                          })
                        : ""
                    }
                  />
                </FormGroup>
              </Col>
            ) : null}
          </Row>
        </div>
      </Col>
    );
  }
};
FirstNameAndLastName.defaultProps = {
  isNew: false,
  isFormBuilder: true,
  errors: {},
  touched: {},
  onChange: () => {},
  onDelete: () => {},
  allowDelete: true,
  isView: false,
  disabled: false,
  isMasterListener: false,
  isShowRequired: false,
  colNumber: 12,
  isShowMobile: false,
  isShowOffText: true,
  isRenderFieldNameFromLabel: true,
};
export default FirstNameAndLastName;
