import moment from "moment";
import { get } from "lodash";
import momentDuration from "moment-duration-format"; // eslint-disable-line no-unused-vars
const formatSeconds = (seconds) => {
  const duration = moment.duration(seconds, "seconds");
  const formatted = duration.format("mm:ss");
  if (formatted < 60) return `0:${formatted}`;
  return formatted;
};
const getBubbleMessages = (process, isBubble, recordingUrl) => {
  const recordingPath = recordingUrl
    ? recordingUrl
    : get(process, `data.fields.recording_path.value`, "");

  const messages = get(process, "data.fields.messages.value", []).filter(
    (message) => {
      if (
        get(message, "_source.m_type") === "voice_feedback" &&
        get(message, "_source.recording_path", null) === recordingPath &&
        get(message, "_source.include_timestamp", false) &&
        isBubble
      ) {
        return true;
      }
      if (
        (get(message, "_source.m_type") === "chat" ||
          get(message, "_source.m_type") === "recording_feedback") &&
        get(message, "_source.recording_path", null) === recordingPath &&
        get(message, "_source.include_timestamp", false) &&
        isBubble
      ) {
        return message;
      }
      return false;
    }
  );
  return messages;
};

const getBubbleMessagesV2 = (allMessages, isBubble, recordingUrl) => {
  const recordingPath = recordingUrl
    ? recordingUrl
    : get(process, `data.fields.recording_path.value`, "");

  const messages = allMessages.filter((message) => {
    if (
      get(message, "message.m_type") === "voice_feedback" &&
      get(message, "message.recording_path", null) === recordingPath &&
      get(message, "message.include_timestamp", false) &&
      isBubble
    ) {
      return true;
    }
    if (
      (get(message, "message.m_type") === "chat" ||
        get(message, "message.m_type") === "recording_feedback") &&
      get(message, "message.recording_path", null) === recordingPath &&
      get(message, "message.include_timestamp", false) &&
      isBubble
    ) {
      return true;
    }
    return false;
  });
  return messages.map((item) => ({
    _id: item.m_id,
    _source: item.message,
  }));
};
export { formatSeconds, getBubbleMessages, getBubbleMessagesV2 };
