import * as Types from "store/types/tags";
import * as Const from "utils/constants";
import Api from "utils/api";
import { userInfoTags } from "store/actions/auth";
import { isFunction } from "lodash";
import { uniq } from "lodash";

export const getTagTypes = state => async dispatch => {
  let errorStr = "";
  try {
    dispatch({
      type: Types.FETCH_TAG_TYPES_SUCCESS,
      payload: state.info.tags.tag_types
    });
    return;
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.FETCH_TAG_TYPES_ERROR,
    payload: errorStr
  });
};

// cities
export const getCities = state => async dispatch => {
  let errorStr = "";
  try {
    // let s = state;
    dispatch(
      fetchCitiesSuccess(state.info.tags.tags.find(t => t.id === 1).children)
    );
    return;
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch(fetchCitiesError({ data: errorStr }));
};

export const updateCities = (data, cb) => async dispatch => {
  let errorStr = "";
  try {
    dispatch({ type: Types.UPDATE_CITIES_REQUEST });
    let response = await Api.doCall(Const.UPDATE_CITIES(), "POST", data);
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch(updateCitiesSuccess(response.data));
        if (isFunction(cb)) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch(updateCitiesError({ data: errorStr }));
};

export const ordersCities = (data, cb) => async dispatch => {
  let errorStr = "";
  try {
    dispatch({
      type: Types.ORDER_CITIES_REQUEST
    });
    let response = await Api.doCall(Const.UPDATE_CITIES(), "POST", {
      ...data,
      is_order: true
    });
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.ORDER_CITIES_SUCCESS,
          payload: data.treeData
        });
        if (isFunction(cb)) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.ORDER_CITIES_ERROR,
    payload: errorStr
  });
};

export const addCity = (data, cb) => async dispatch => {
  let errorStr = "";
  try {
    dispatch({ type: Types.ADD_CITY_REQUEST });
    let response = await Api.doCall(Const.ADD_CITY(), "POST", data);
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({ type: Types.ADD_CITY_SUCCESS, payload: response.data });
        if (isFunction(cb)) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.ADD_CITY_ERROR, payload: errorStr });
};

export const updateCity = (data, cb) => async dispatch => {
  let errorStr = "";
  try {
    dispatch({ type: Types.UPDATE_CITY_REQUEST });
    let response = await Api.doCall(Const.UPDATE_CITY(data.id), "PUT", data);
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({ type: Types.UPDATE_CITY_SUCCESS, payload: response.data });
        if (isFunction(cb)) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.UPDATE_CITY_ERROR, payload: errorStr });
};

export const deleteCity = (data, cb) => async dispatch => {
  let errorStr = "";
  try {
    dispatch({ type: Types.DELETE_CITY_REQUEST });
    let response = await Api.doCall(Const.DELETE_CITY(data.id), "DELETE", data);
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({ type: Types.DELETE_CITY_SUCCESS, payload: response.data });
        if (isFunction(cb)) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.DELETE_CITY_ERROR, payload: errorStr });
};

export function fetchCitiesSuccess(payload) {
  return {
    type: Types.FETCH_CITIES_SUCCESS,
    payload
  };
}

export function fetchCitiesError(payload) {
  return {
    type: Types.FETCH_CITIES_ERROR,
    payload
  };
}

export function updateCitiesSuccess(payload) {
  return {
    type: Types.UPDATE_CITIES_SUCCESS,
    payload
  };
}

export function updateCitiesError(payload) {
  return {
    type: Types.UPDATE_CITIES_ERROR,
    payload
  };
}

export const getStationsTree = state => async dispatch => {
  let errorStr = "";
  try {
    let tags = state.info.tags.tags; //.filter(t => t.id !== 1);

    tags.map(node => {
      node.children.map(node => {
        if (node.path.length === 2 && node.path[0] !== 1)
          // sub groups
          node.should_match = true;
        node.children.map(node => {
          if (node.path.length === 3 && node.path[0] === 1)
            // cities
            node.should_match = true;
        });
      });
    });

    let cloned_tags = JSON.parse(JSON.stringify(tags));
    function enhance_node(node) {
      if (node.tag_type_id < 0) return;
      let filters = [];
      cloned_tags.map(root => {
        if (node.path.indexOf(root.id) === -1) {
          root.tag_type_id = -3; // < 0 = not to enhance with stations
          let clone = JSON.parse(JSON.stringify(root));
          clone.path = uniq([...node.path, root.id]);
          clone.children &&
            clone.children.map(clone_child => {
              clone_child.path = uniq([...clone.path, ...clone_child.path]);
              clone_child.children &&
                clone_child.children.map(clone_grand_child => {
                  clone_grand_child.path &&
                    clone_grand_child.path.push(root.id);
                  clone_grand_child.path = uniq([
                    ...clone_child.path,
                    ...clone_grand_child.path
                  ]);
                  clone_grand_child.children &&
                    clone_grand_child.children.map(clone_grand_grand_child => {
                      clone_grand_grand_child.path = uniq([
                        ...clone_grand_child.path,
                        ...clone_grand_grand_child.path
                      ]);
                    });
                });
            });
          filters.push(clone);
        }
      });
    }
    tags.map(node => {
      node.tag_type_id = -3; // < 0 = not to enhance with stations
      node.children.map(node => {
        enhance_node(node);
        node.children.map(node => {
          enhance_node(node);
        });
      });
    });

    dispatch(fetchGroupsSuccess(tags));
    return;
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch(fetchGroupsError({ data: errorStr }));
};

// groups

export const getGroups = state => async dispatch => {
  let errorStr = "";
  try {
    dispatch(fetchGroupsSuccess(state.info.tags.tags.filter(t => t.id !== 1)));
    return;
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch(fetchGroupsError({ data: errorStr }));
};

export const updateGroups = (data, cb) => async dispatch => {
  let errorStr = "";
  try {
    dispatch({ type: Types.UPDATE_GROUPS_REQUEST });
    let response = await Api.doCall(Const.UPDATE_GROUPS(), "POST", data);
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch(updateGroupsSuccess(response.data));
        if (isFunction(cb)) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch(updateGroupsError({ data: errorStr }));
};

export const ordersGroups = (data, cb) => async dispatch => {
  let errorStr = "";
  try {
    dispatch({
      type: Types.ORDER_GROUPS_REQUEST
    });
    let response = await Api.doCall(Const.UPDATE_GROUPS(), "POST", {
      ...data,
      is_order: true
    });
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.ORDER_GROUPS_SUCCESS,
          payload: data.treeData
        });
        if (isFunction(cb)) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.ORDER_GROUPS_ERROR,
    payload: errorStr
  });
};

export const addGroup = (data, cb) => async dispatch => {
  let errorStr = "";
  try {
    dispatch({ type: Types.ADD_GROUP_REQUEST });
    let response = await Api.doCall(Const.ADD_GROUP(), "POST", data);
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({ type: Types.ADD_GROUP_SUCCESS, payload: response.data });
        if (isFunction(cb)) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.ADD_GROUP_ERROR, payload: errorStr });
};

export const updateGroup = (data, cb) => async dispatch => {
  let errorStr = "";
  try {
    dispatch({ type: Types.UPDATE_GROUP_REQUEST });
    let response = await Api.doCall(Const.UPDATE_GROUP(data.id), "PUT", data);
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({ type: Types.UPDATE_GROUP_SUCCESS, payload: response.data });
        if (isFunction(cb)) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.UPDATE_GROUP_ERROR, payload: errorStr });
};

export const deleteGroup = (data, cb) => async dispatch => {
  let errorStr = "";
  try {
    dispatch({ type: Types.DELETE_GROUP_REQUEST });
    let response = await Api.doCall(
      Const.DELETE_GROUP(data.id),
      "DELETE",
      data
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({ type: Types.DELETE_GROUP_SUCCESS, payload: response.data });
        if (isFunction(cb)) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.DELETE_CITY_ERROR, payload: errorStr });
};

export const addUnratedMarket = (data, cb) => async dispatch => {
  let errorStr = "";
  try {
    dispatch({ type: Types.ADD_UNRATED_MARKET_REQUEST });
    let response = await Api.doCall(Const.ADD_MARKET(), "POST", data);
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({ type: Types.ADD_UNRATED_MARKET_SUCCESS, payload: response.data });
        dispatch(userInfoTags());
        if (isFunction(cb)) cb(response.data);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.ADD_UNRATED_MARKET_ERROR, payload: errorStr });
};


export function fetchGroupsSuccess(payload) {
  return {
    type: Types.FETCH_GROUPS_SUCCESS,
    payload
  };
}

export function fetchGroupsError(payload) {
  return {
    type: Types.FETCH_GROUPS_ERROR,
    payload
  };
}

export function updateGroupsSuccess(payload) {
  return {
    type: Types.UPDATE_GROUPS_SUCCESS,
    payload
  };
}

export function updateGroupsError(payload) {
  return {
    type: Types.UPDATE_GROUPS_ERROR,
    payload
  };
}
