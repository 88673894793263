import View from "./view";
import { connect } from "react-redux";
import { compose, withState } from "recompose";
import { injectIntl } from "react-intl";
import { get } from "lodash";
const Enhanced = compose(
  connect((state, props) => {
    return {
      ...props,
      currentServer: get(state, "servers.currentServer.data", {}),
      winnersNotifications: get(state, "winners.winnersNotifications.data", {}),
    };
  }, {}),
  withState(
    "isOpenUnsavedConfirmationModal",
    "setIsOpenUnsavedConfirmationModal",
    false
  ),
  withState("shouldBlockNavigation", "setShouldBlockNavigation", false),
  withState("selectedEmailTemplate", "setSelectedEmailTemplate", null),
  withState("selectPreviewModal", "setSelectPreviewModal", null),
  withState("isOpenEmailTemplateModal", "setIsOpenEmailTemplateModal", false)
)(View);
export default injectIntl(Enhanced);
