import * as React from "react";
import StationForm, {
  MODE_ADD as STATION_FORM_MODE_ADD
} from "components/StationForm";
import Page from "components/Page";
// import { isFunction } from "lodash";
import { Redirect } from "react-router";
// import { Button } from "reactstrap";
// import { FormattedMessage } from "react-intl";
import PrivilegedComponent from "components/PrivilegedComponent";
import { PRIVILEGES } from "utils/constants";
import URL from "utils/urls";
// import { adminTabs } from "utils/config";

const AddUpdateStation = props => {
  const { isUpdate, id } = props;
  // let backButton = (
  //   <Button
  //     type="button"
  //     outline
  //     className={"btn btn-outline-blue btn-radius"}
  //     onClick={() => {
  //       if (isFunction(props.history.goBack)) {
  //         props.history.goBack();
  //       }
  //     }}
  //   >
  //     <FormattedMessage id="my profile > button cancel" />
  //   </Button>
  // );
  return (
    <PrivilegedComponent
      requires={privileges => {
        if (isUpdate) return privileges.indexOf(PRIVILEGES.EDIT_STATIONS) > -1;
        else return privileges.indexOf(PRIVILEGES.ADD_STATIONS) > -1;
      }}
    >
      {({ isValid }) => {
        if (isValid) {
          return (
            <Page
              applyPadding={false}
              title={
                <span>
                  <nav className="cr-page__breadcrumb" aria-label="breadcrumb" style={{ fontSize: 'initial' }}>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                      {
                        props.intl.formatMessage({
                          id: "breadcrumbs > stations"
                        })
                      }
                      </li>
                      <li className="active breadcrumb-item" aria-current="page">
                      {isUpdate
                        ? props.intl.formatMessage({ id: "breadcrumbs > update" })
                        : props.intl.formatMessage({ id: "breadcrumbs > add" })
                      }
                      </li>
                    </ol>
                  </nav>
                  {/* <span className="page-title">
                    <FormattedMessage id="channel manager > page title" />
                  </span> */}
                </span>
              }
              >
                <div className={"page-container"}>
                  <StationForm
                    id={id}
                    isView={STATION_FORM_MODE_ADD}
                    // onBack={() => back()}
                    // backButton={backButton}
                  />
                </div>
            </Page>
          );
        } else {
          return <Redirect to={URL.HOME()} />;
        }
      }}
    </PrivilegedComponent>
  );
};

export default AddUpdateStation;
