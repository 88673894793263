import PropTypes from 'prop-types'
import React from 'react'

const Header = ({ localizer, date }) => {
  // let label = localizer.format(date, 'dayFormat');
  const fullDateName = date.toLocaleDateString(localizer, { weekday: 'long' }); 
  const dateNumber = date.getDate();
  return <span className="ps-rbc-header-date">
    <span className="fullName">{fullDateName}</span>
    <span className="dateNumber">{dateNumber}</span>
  </span>
}

Header.propTypes = {
  label: PropTypes.node,
}

export default Header
