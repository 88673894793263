import { FormattedMessage } from "react-intl";
import moment from "moment";
import React from "react";
const promotionDetails = {
  process_step_index: 1,
  key: "promotion_details",
  caption_color: "#CC1EE6",
  title: <FormattedMessage id="process > title order" />,
  step_roles: [1, 2, 3],
  step_viewers_roles: [1, 2, 3, 4, 7],
  step_editors_roles: [1, 2, 3, 4],
  step_viewers_privileges: ["add_promotion_request"],
  step_editors_privileges: ["add_promotion_request"],
  description: null,
  status: "initial",
  disabled_until_completed_steps: [],
  step_container: "none",
  no_edit_button: true,
  fields: [
    {
      component: "promotion_details",
    },
    {
      field: "client_name",
      title: <FormattedMessage id="process > field client name" />,
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        if (
          !(
            (process.fields.contract_value &&
              process.fields.contract_value.value) ||
            (process.fields.contract_start_date &&
              process.fields.contract_start_date.value) ||
            (process.fields.contract_end_date &&
              process.fields.contract_end_date.value) ||
            (process.fields.contract_client_email &&
              process.fields.contract_client_email.value) ||
            (process.fields.contract_client_nickname &&
              process.fields.contract_client_nickname.value) ||
            (process.fields.contract_client_phone &&
              process.fields.contract_client_phone.value) ||
            (process.fields.contract_client_contact &&
              process.fields.contract_client_contact.value) ||
            (process.fields.contract_stations &&
              process.fields.contract_stations.value &&
              process.fields.contract_stations.value.length)
          )
        )
          return true;
        return value.length > 2
          ? true
          : "Customer name must be at least 2 letters long";
      },
    },
    {
      field: "client_id",
    },
    {
      field: "contract_id",
    },
    {
      field: "order_files",
      props: {
        width: "300",
        height: "55",
      },
    },
    {
      field: "order_title",
      title: <FormattedMessage id="process > field contest name" />,
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        return value.length > 2
          ? true
          : "Order title must be at least 3 letters long";
      },
    },
    {
      field: "contest_type",
      title: <FormattedMessage id="process > field contest type" />,
      props: {
        options: [
          {
            label: (
              <FormattedMessage id="process > option label call in contest" />
            ),
            value: "Call in Contest",
          },
          {
            label: <FormattedMessage id="process > option label ..." />,
            value: "...",
          },
        ],
      },
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        return value ? true : "Please enter a contest type";
      },
    },
    {
      field: "contest_theme",
      title: <FormattedMessage id="process > field contest theme" />,
      props: {
        options: [
          {
            label: <FormattedMessage id="process > option label theme" />,
            value: "Call theme",
          },
          {
            label: <FormattedMessage id="process > option label ..." />,
            value: "...",
          },
        ],
      },
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        return value ? true : "Please select a theme";
      },
    },
    {
      field: "contest_winner_selection",
      title: <FormattedMessage id="process > field contest winner selection" />,
      props: {
        options: [
          {
            label: (
              <FormattedMessage id="process > option label winner selection" />
            ),
            value: "winner selection",
          },
          {
            label: <FormattedMessage id="process > option label ..." />,
            value: "...",
          },
        ],
      },
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        return value ? true : "Please select a winner selection";
      },
    },
    {
      field: "contest_number_of_plays",
      title: <FormattedMessage id="process > field contest number of plays" />,
      mandatory: true,
      formatted: "number",
      validation: function(step, item, process, template, user, value) {
        return value ? true : "Please enter the number of plays";
      },
    },
    {
      field: "contest_internal_description",
      title: (
        <FormattedMessage id="process > field contest internal description" />
      ),
      validation: function(step, item, process, template, user, value) {
        return true;
      },
    },
    {
      field: "contest_approved_program_director",
      title: (
        <FormattedMessage id="process > field contest approved program director" />
      ),
    },
    {
      field: "order_stations",
      title: <FormattedMessage id="process > field stations" />,
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        if (
          !(
            (process.fields.client_name && process.fields.client_name.value) ||
            (process.fields.contract_value &&
              process.fields.contract_value.value) ||
            (process.fields.contract_start_date &&
              process.fields.contract_start_date.value) ||
            (process.fields.contract_end_date &&
              process.fields.contract_end_date.value) ||
            (process.fields.contract_client_email &&
              process.fields.contract_client_email.value) ||
            (process.fields.contract_client_nickname &&
              process.fields.contract_client_nickname.value) ||
            (process.fields.contract_client_phone &&
              process.fields.contract_client_phone.value) ||
            (process.fields.contract_client_contact &&
              process.fields.contract_client_contact.value) ||
            (process.fields.contract_stations &&
              process.fields.contract_stations.value &&
              process.fields.contract_stations.value.length)
          )
        )
          return true;
        return value.length > 0
          ? true
          : "At least one station has to be selected";
      },
    },
    {
      field: "order_day_parts",
      title: <FormattedMessage id="process > field day part" />,
      props: {
        allowNew: true,
        options: [
          {
            label: <FormattedMessage id="process > option label any time" />,
            value: "Any Time",
          },
          {
            label: <FormattedMessage id="process > option label am drive" />,
            value: "AM Drive",
          },
          {
            label: <FormattedMessage id="process > option label mid day" />,
            value: "Mid Day",
          },
          {
            label: <FormattedMessage id="process > option label pm drive" />,
            value: "PM Drive",
          },
          {
            label: <FormattedMessage id="process > option label ros anytime" />,
            value: "ROS - Anytime",
          },
          {
            label: <FormattedMessage id="process > option label evening" />,
            value: "Evening",
          },
          {
            label: <FormattedMessage id="process > option label overnight" />,
            value: "Overnight",
          },
        ],
      },
    },
    {
      field: "contract_stations",
      mandatory: true,
      title: <FormattedMessage id="process > field contract stations" />,
      validation: function(step, item, process, template, user, value) {
        if (
          !(
            (process.fields.client_name && process.fields.client_name.value) ||
            (process.fields.contract_value &&
              process.fields.contract_value.value) ||
            //(process.fields.contract_start_date &&
            //  process.fields.contract_start_date.value) ||
            // (process.fields.contract_client_contact &&
            //  process.fields.contract_client_contact.value) ||
            //(process.fields.contract_client_nickname &&
            //  process.fields.contract_client_nickname.value) ||
            // (process.fields.contract_client_email &&
            //   process.fields.contract_client_email.value) ||
            // (process.fields.contract_end_date &&
            //   process.fields.contract_end_date.value) ||
            // (process.fields.contract_client_phone &&
            //   process.fields.contract_client_phone.value) ||
            (process.fields.contract_stations &&
              process.fields.contract_stations.value &&
              process.fields.contract_stations.value.length)
          )
        )
          return true;
        return value.length > 0
          ? true
          : "At least one station has to be selected";
      },
    },
    {
      field: "contract_value",
      title: <FormattedMessage id="process > field salesperson" />,
      mandatory: true,
      formatted: "number",
      prefix: "$",
      validation: function(step, item, process, template, user, value) {
        if (
          !(
            (process.fields.client_name && process.fields.client_name.value) ||
            // (process.fields.contract_start_date &&
            //   process.fields.contract_start_date.value) ||
            // (process.fields.contract_end_date &&
            //   process.fields.contract_end_date.value) ||
            // (process.fields.contract_client_email &&
            //   process.fields.contract_client_email.value) ||
            // (process.fields.contract_client_nickname &&
            //   process.fields.contract_client_nickname.value) ||
            // (process.fields.contract_client_phone &&
            //   process.fields.contract_client_phone.value) ||
            // (process.fields.contract_client_contact &&
            //   process.fields.contract_client_contact.value) ||
            (process.fields.contract_stations &&
              process.fields.contract_stations.value &&
              process.fields.contract_stations.value.length)
          )
        )
          return true;
        if (value < 0) return "Contract value must be greater than 0.";
        return value ||
          !process.fields.order_sold ||
          !process.fields.order_sold.value
          ? true
          : "Please enter a value";
      },
    },

    {
      field: "recording_path",
      mandatory: false,
      validation: function(step, item, process, template, user, value) {
        return !(
          process.fields.contract_status &&
          process.fields.contract_status.value &&
          process.fields.contract_status.value !== "Production Needed"
        ) || value !== ""
          ? true
          : "Please upload the produced spot";
      },
    },
    {
      field: "contract_number",
      title: <FormattedMessage id="process > field contract number" />,
      mandatory: false,
      // "formatted": "number",
      validation: function(step, item, process, template, user, value) {
        return value === "" ||
          value.length > 2 ||
          !process.fields.order_sold ||
          !process.fields.order_sold.value
          ? true
          : "Contract number must be at least 3 letters long";
      },
    },
    // {
    //   field: "contract_start_date",
    //   title: (
    //     <FormattedMessage id="process > field contract start date" />
    //   ),
    //   mandatory: true,
    //   validation: function(step, item, process, template, user, value) {
    //     if (
    //       !(
    //         (process.fields.client_name &&
    //           process.fields.client_name.value) ||
    //         (process.fields.contract_value &&
    //           process.fields.contract_value.value) ||
    //         (process.fields.contract_end_date &&
    //           process.fields.contract_end_date.value) ||
    //         (process.fields.contract_client_email &&
    //           process.fields.contract_client_email.value) ||
    //         (process.fields.contract_client_nickname &&
    //           process.fields.contract_client_nickname.value) ||
    //         (process.fields.contract_client_phone &&
    //           process.fields.contract_client_phone.value) ||
    //         (process.fields.contract_client_contact &&
    //           process.fields.contract_client_contact.value) ||
    //         (process.fields.contract_stations &&
    //           process.fields.contract_stations.value &&
    //           process.fields.contract_stations.value.length)
    //       )
    //     )
    //       return true;
    //     if (
    //       process.fields.order_sold &&
    //       !process.fields.order_sold.value
    //     )
    //       return true;
    //     if (value.length === 0) return "Please enter a date";
    //     if (isNaN(Date.parse(value)))
    //       return "Please enter a valid date";
    //     // if (moment(value).isBefore(moment())) return 'Date cannot be in the past';
    //     return true;
    //   }
    // },
    // {
    //   field: "contract_end_date",
    //   title: (
    //     <FormattedMessage id="process > field contract end date" />
    //   ),
    //   mandatory: true,
    //   validation: function(step, item, process, template, user, value) {
    //     if (
    //       !(
    //         (process.fields.client_name &&
    //           process.fields.client_name.value) ||
    //         (process.fields.contract_value &&
    //           process.fields.contract_value.value) ||
    //         (process.fields.contract_start_date &&
    //           process.fields.contract_start_date.value) ||
    //         (process.fields.contract_client_email &&
    //           process.fields.contract_client_email.value) ||
    //         (process.fields.contract_client_nickname &&
    //           process.fields.contract_client_nickname.value) ||
    //         (process.fields.contract_client_phone &&
    //           process.fields.contract_client_phone.value) ||
    //         (process.fields.contract_client_contact &&
    //           process.fields.contract_client_contact.value) ||
    //         (process.fields.contract_stations &&
    //           process.fields.contract_stations.value &&
    //           process.fields.contract_stations.value.length)
    //       )
    //     )
    //       return true;
    //     if (
    //       process.fields.order_sold &&
    //       !process.fields.order_sold.value
    //     )
    //       return true;
    //     if (value.length === 0) return "Please enter a date";
    //     if (isNaN(Date.parse(value)))
    //       return "Please enter a valid date";
    //     if (
    //       process.fields.contract_start_date &&
    //       !isNaN(
    //         Date.parse(process.fields.contract_start_date.value)
    //       ) &&
    //       Date.parse(value) <
    //         Date.parse(process.fields.contract_start_date.value)
    //     )
    //       return "Please enter a date later than the start date";
    //     if (moment(value).isBefore(moment()))
    //       return "Date cannot be in the past";
    //     return true;
    //   }
    // },
    // {
    //   field: "contract_client_contact",
    //   mandatory: true,
    //   title: <FormattedMessage id="process > field client contact" />,
    //   validation: function(step, item, process, template, user, value) {
    //     if (
    //       !(
    //         (process.fields.client_name &&
    //           process.fields.client_name.value) ||
    //         (process.fields.contract_value &&
    //           process.fields.contract_value.value) ||
    //         (process.fields.contract_start_date &&
    //           process.fields.contract_start_date.value) ||
    //         (process.fields.contract_client_email &&
    //           process.fields.contract_client_email.value) ||
    //         (process.fields.contract_client_nickname &&
    //           process.fields.contract_client_nickname.value) ||
    //         (process.fields.contract_client_phone &&
    //           process.fields.contract_client_phone.value) ||
    //         (process.fields.contract_end_date &&
    //           process.fields.contract_end_date.value) ||
    //         (process.fields.contract_stations &&
    //           process.fields.contract_stations.value &&
    //           process.fields.contract_stations.value.length)
    //       )
    //     )
    //       return true;
    //     return value ? true : "Please enter a client contact";
    //   }
    // },
    // {
    //   field: "contract_client_email",
    //   title: <FormattedMessage id="process > field email" />,
    //   mandatory: true,
    //   validation: function(step, item, process, template, user, value) {
    //     if (
    //       !(
    //         (process.fields.client_name &&
    //           process.fields.client_name.value) ||
    //         (process.fields.contract_value &&
    //           process.fields.contract_value.value) ||
    //         (process.fields.contract_start_date &&
    //           process.fields.contract_start_date.value) ||
    //         (process.fields.contract_client_contact &&
    //           process.fields.contract_client_contact.value) ||
    //         (process.fields.contract_client_nickname &&
    //           process.fields.contract_client_nickname.value) ||
    //         (process.fields.contract_client_phone &&
    //           process.fields.contract_client_phone.value) ||
    //         (process.fields.contract_end_date &&
    //           process.fields.contract_end_date.value) ||
    //         (process.fields.contract_stations &&
    //           process.fields.contract_stations.value &&
    //           process.fields.contract_stations.value.length)
    //       )
    //     )
    //       return true;
    //     return value.length > 0 &&
    //       value.match(new RegExp("[^@]+@[^\\.]+\\..+", "gi"))
    //       ? true
    //       : "Please enter a valid client email";
    //   }
    // },
    // {
    //   field: "contract_client_nickname",
    //   title: <FormattedMessage id="process > field nickname" />
    // },
    // {
    //   field: "contract_client_phone",
    //   title: <FormattedMessage id="process > field phone" />,
    //   mandatory: true,
    //   formatted: "phone",
    //   validation: function(step, item, process, template, user, value) {
    //     if (
    //       !(
    //         (process.fields.client_name &&
    //           process.fields.client_name.value) ||
    //         (process.fields.contract_value &&
    //           process.fields.contract_value.value) ||
    //         (process.fields.contract_start_date &&
    //           process.fields.contract_start_date.value) ||
    //         (process.fields.contract_client_contact &&
    //           process.fields.contract_client_contact.value) ||
    //         (process.fields.contract_client_nickname &&
    //           process.fields.contract_client_nickname.value) ||
    //         (process.fields.contract_client_email &&
    //           process.fields.contract_client_email.value) ||
    //         (process.fields.contract_end_date &&
    //           process.fields.contract_end_date.value) ||
    //         (process.fields.contract_stations &&
    //           process.fields.contract_stations.value &&
    //           process.fields.contract_stations.value.length)
    //       )
    //     )
    //       return true;
    //     return (process.fields.order_sold &&
    //       !process.fields.order_sold.value) ||
    //       (value.length > 0 &&
    //         value.match(new RegExp("^[0-9()-]{5,}$", "gi")))
    //       ? true
    //       : "Please enter a valid client phone number";
    //   }
    // },
    {
      field: "contract_category",
      title: <FormattedMessage id="process > field contract category" />,
      props: {
        options: [
          {
            label: <FormattedMessage id="process > option label healthcare" />,
            value: "Healthcare",
          },
          {
            label: <FormattedMessage id="process > option label telecom" />,
            value: "Telecom",
          },
          {
            label: (
              <FormattedMessage id="process > option label food and beverage" />
            ),
            value: "Food & Beverage",
          },
          {
            label: <FormattedMessage id="process > option label technology" />,
            value: "Technology",
          },
          {
            label: <FormattedMessage id="process > option label automotive" />,
            value: "Automotive",
          },
          {
            label: (
              <FormattedMessage id="process > option label entertainment" />
            ),
            value: "Entertainment",
          },
          {
            label: <FormattedMessage id="process > option label travel" />,
            value: "Travel",
          },
        ],
      },
      // "mandatory": true,
      // "validation": function (step, item, process, template, user, value) { return (value.length > 0 || !process.fields.order_sold || !process.fields.order_sold.value) ? true : 'Please select a category' }
    },
    {
      field: "contest_before_winner_selection",
      title: <FormattedMessage id="process > field before winner selection" />,
    },
    {
      field: "contest_after_winner_selection",
      title: <FormattedMessage id="process > field after winner selection" />,
    },
    {
      field: "contest_notes",
      title: <FormattedMessage id="process > field notes" />,
    },
    {
      field: "contest_additional_questions",
      title: <FormattedMessage id="process > additional questions" />,
    },
    {
      field: "contest_additional_questions_textfields",
      title: "",
      validation: function(step, item, process, template, user, value) {
        if (
          process.fields.contest_additional_questions &&
          process.fields.contest_additional_questions.value
        ) {
          if (value && value.length && value[0]) {
            return true;
          } else {
            return "At least one additional question is required.";
          }
        }
        return true;
      },
    },
    {
      field: "contest_trivia_questions",
      title: <FormattedMessage id="process > trivia questions" />,
    },
    {
      field: "contest_trivia_question_order",
      title: <FormattedMessage id="process > field trivia question order" />,
      props: {
        options: [
          {
            label: <FormattedMessage id="process > option label random" />,
            value: "Random",
          },
          {
            label: (
              <FormattedMessage id="process > option label jocks choice" />
            ),
            value: "Jocks choice",
          },
        ],
      },
      mandatory: false,
      // "validation": function (step, item, process, template, user, value) { return value ? true : 'Please enter a contest type' }
    },
    {
      field: "contest_trivia_unanswered_questions",
      title: (
        <FormattedMessage id="process > field reuse unanswered questions" />
      ),
      props: {
        options: [
          {
            label: <FormattedMessage id="process > option label yes" />,
            value: "Yes",
          },
          {
            label: <FormattedMessage id="process > option label no" />,
            value: "No",
          },
        ],
      },
      mandatory: false,
      // "validation": function (step, item, process, template, user, value) { return value ? true : 'Please enter a contest type' }
    },
    {
      field: "contest_trivia_rollover_unclaimed_prizes",
      title: (
        <FormattedMessage id="process > field rollover unclaimed prizes" />
      ),
      props: {
        options: [
          {
            label: <FormattedMessage id="process > option label yes" />,
            value: "Yes",
          },
          {
            label: <FormattedMessage id="process > option label no" />,
            value: "No",
          },
        ],
      },
      mandatory: false,
      // "validation": function (step, item, process, template, user, value) { return value ? true : 'Please enter a contest type' }
    },
    {
      field: "contest_trivia_number_of_chances",
      title: <FormattedMessage id="process > field number of chances" />,
      props: {
        options: [
          {
            label: "1",
            value: "1",
          },
          {
            label: "2",
            value: "2",
          },
          {
            label: "3",
            value: "3",
          },
        ],
      },
      mandatory: false,
      // "validation": function (step, item, process, template, user, value) { return value ? true : 'Please enter a contest type' }
    },
    {
      field: "contest_trivia_questions_textfields",
      title: <FormattedMessage id="process > field questions" />,
      validation: function(step, item, process, template, user, value) {
        if (
          process.fields.contest_trivia_questions &&
          process.fields.contest_trivia_questions.value
        ) {
          if (value && value.length) {
            if (Object.values(value[0]).indexOf("") > -1) {
              return "At least one trivia question is required.";
            } else {
              return true;
            }
          } else {
            return "At least one trivia question is required.";
          }
        }
        return true;
      },
    },
    {
      field: "plays_per_day",
      title: <FormattedMessage id="process > field plays per day" />,
      props: {
        options: [
          {
            label: "1",
            value: "1",
          },
          {
            label: "2",
            value: "2",
          },
          {
            label: "3",
            value: "3",
          },
          {
            label: "4",
            value: "4",
          },
          {
            label: "5",
            value: "5",
          },
        ],
      },
      mandatory: true,
      // validation: function(step, item, process, template, user, value) {
      //   if (Object.keys(process.fields.liners.value[0]).length > 1) {
      //     return process.fields.liners.value[0][
      //       "liner_mentions_per_day"
      //     ] &&
      //       process.fields.liners.value[0]["liner_mentions_per_day"]
      //         .value &&
      //       parseInt(
      //         process.fields.liners.value[0]["liner_mentions_per_day"]
      //           .value
      //       ) > 0
      //       ? true
      //       : "Please select mention";
      //   } else {
      //     if (process.fields.liners.value[0]["script"]) {
      //       value = JSON.parse(
      //         process.fields.liners.value[0]["script"]
      //       );
      //       return get(value, "blocks.0.text")
      //         ? "Please select mention"
      //         : true;
      //     }
      //   }
      // }
    },
    {
      field: "order_start_date",
      title: <FormattedMessage id="process > field contest start date" />,
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        if (
          !(
            (process.fields.order_day_parts &&
              process.fields.order_day_parts.value &&
              process.fields.order_day_parts.value.length) ||
            (process.fields.order_end_date &&
              process.fields.order_end_date.value) ||
            (process.fields.contest_days_of_week &&
              process.fields.contest_days_of_week.value &&
              process.fields.contest_days_of_week.value.length)
          )
        )
          return true;
        if (process.fields.order_sold && !process.fields.order_sold.value)
          return true;
        if (value.length === 0) return "Please enter a date";
        if (isNaN(Date.parse(value))) return "Please enter a valid date";
        if (
          process.fields.contract_start_date &&
          !isNaN(Date.parse(process.fields.contract_start_date.value)) &&
          process.fields.contract_end_date &&
          !isNaN(Date.parse(process.fields.contract_end_date.value)) &&
          (Date.parse(value) <
            Date.parse(process.fields.contract_start_date.value) ||
            Date.parse(value) >
              Date.parse(process.fields.contract_end_date.value))
        )
          return "Please enter a date in the contract dates range";
        if (moment(value).isBefore(moment()))
          return "Date cannot be in the past";
        return true;
      },
    },
    {
      field: "order_end_date",
      title: <FormattedMessage id="process > field contest end date" />,
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        if (
          !(
            (process.fields.order_day_parts &&
              process.fields.order_day_parts.value &&
              process.fields.order_day_parts.value.length) ||
            (process.fields.order_start_date &&
              process.fields.order_start_date.value) ||
            (process.fields.contest_days_of_week &&
              process.fields.contest_days_of_week.value &&
              process.fields.contest_days_of_week.value.length)
          )
        )
          return true;
        if (process.fields.order_sold && !process.fields.order_sold.value)
          return true;
        if (value.length === 0) return "Please enter a date";
        if (isNaN(Date.parse(value))) return "Please enter a valid date";
        if (
          process.fields.order_start_date &&
          !isNaN(Date.parse(process.fields.order_start_date.value)) &&
          Date.parse(value) < Date.parse(process.fields.order_start_date.value)
        )
          return "Please enter a date later than the start date";
        if (
          process.fields.contract_start_date &&
          !isNaN(Date.parse(process.fields.contract_start_date.value)) &&
          process.fields.contract_end_date &&
          !isNaN(Date.parse(process.fields.contract_end_date.value)) &&
          (Date.parse(value) <
            Date.parse(process.fields.contract_start_date.value) ||
            Date.parse(value) >
              Date.parse(process.fields.contract_end_date.value))
        )
          return "Please enter a date in the contract dates range";
        if (moment(value).isBefore(moment()))
          return "Date cannot be in the past";
        return true;
      },
    },
    {
      field: "contest_days_of_week",
      title: <FormattedMessage id="process > days of week" />,
    },
    {
      field: "copy_points",
      title: <FormattedMessage id="process > field copy points" />,
    },
    {
      field: "script",
      title: <FormattedMessage id="process > field script" />,
    },
    {
      field: "liners",
      title: <FormattedMessage id="process > liners" />,
    },
    {
      field: "active_liner",
      title: "",
    },
    // {
    //   field: "liner_mentions_per_day",
    //   title: <FormattedMessage id="process > liner mentions" />,
    //   props: {
    //     options: [
    //       {
    //         label: "1",
    //         value: "1"
    //       },
    //       {
    //         label: "2",
    //         value: "2"
    //       },
    //       {
    //         label: "3",
    //         value: "3"
    //       },
    //       {
    //         label: "4",
    //         value: "4"
    //       },
    //       {
    //         label: "5",
    //         value: "5"
    //       }
    //     ]
    //   },
    //   mandatory: true,
    //   validation: function(step, item, process, template, user, value) {
    //     if (Object.keys(process.fields.liners.value[0]).length > 1) {
    //       return process.fields.liners.value[0][
    //         "liner_mentions_per_day"
    //       ] &&
    //         process.fields.liners.value[0]["liner_mentions_per_day"]
    //           .value &&
    //         parseInt(
    //           process.fields.liners.value[0]["liner_mentions_per_day"]
    //             .value
    //         ) > 0
    //         ? true
    //         : "Please select mention";
    //     } else {
    //       if (process.fields.liners.value[0]["script"]) {
    //         value = JSON.parse(
    //           process.fields.liners.value[0]["script"]
    //         );
    //         return get(value, "blocks.0.text")
    //           ? "Please select mention"
    //           : true;
    //       }
    //     }
    //   }
    // },
    // {
    //   field: "liner_start_date",
    //   title: <FormattedMessage id="process > liner start date" />,
    //   mandatory: true,
    //   validation: function(step, item, process, template, user, value) {
    //     if (Object.keys(process.fields.liners.value[0]).length > 1) {
    //       value = process.fields.liners.value[0]["liner_start_date"];
    //       if (!value || value.length === 0)
    //         return "Please enter a date";
    //       if (isNaN(Date.parse(value)))
    //         return "Please enter a valid date";
    //       if (moment(value).isBefore(moment()))
    //         return "Date cannot be in the past";
    //       return true;
    //     } else {
    //       if (process.fields.liners.value[0]["script"]) {
    //         value = JSON.parse(
    //           process.fields.liners.value[0]["script"]
    //         );
    //         return get(value, "blocks.0.text")
    //           ? "Please enter a date"
    //           : true;
    //       }
    //     }
    //   }
    // },
    // {
    //   field: "liner_end_date",
    //   title: <FormattedMessage id="process > liner end date" />,
    //   mandatory: true,
    //   validation: function(step, item, process, template, user, value) {
    //     if (Object.keys(process.fields.liners.value[0]).length > 1) {
    //       value = process.fields.liners.value[0]["liner_start_date"];
    //       if (!value || value.length === 0)
    //         return "Please enter a date";
    //       if (isNaN(Date.parse(value)))
    //         return "Please enter a valid date";
    //       if (moment(value).isBefore(moment()))
    //         return "Date cannot be in the past";
    //       return true;
    //     } else {
    //       if (process.fields.liners.value[0]["script"]) {
    //         value = JSON.parse(
    //           process.fields.liners.value[0]["script"]
    //         );
    //         return get(value, "blocks.0.text")
    //           ? "Please enter a date"
    //           : true;
    //       }
    //     }
    //   }
    // },
    // {
    //   field: "liner_days_of_week",
    //   title: <FormattedMessage id="process > liner days of week" />
    // },
    // {
    //   field: "liner_length",
    //   title: <FormattedMessage id="process > liner length" />,
    //   props: {
    //     options: [
    //       {
    //         label: "15 secs",
    //         value: "15 secs"
    //       },
    //       {
    //         label: "30 secs",
    //         value: "30 secs"
    //       }
    //     ]
    //   },
    //   mandatory: false
    // },
    // {
    //   field: "liner_day_part",
    //   title: <FormattedMessage id="process > liner day part" />,
    //   props: {
    //     allowNew: true,
    //     options: [
    //       {
    //         label: (
    //           <FormattedMessage id="process > option label any time" />
    //         ),
    //         value: "Any Time"
    //       },
    //       {
    //         label: (
    //           <FormattedMessage id="process > option label am drive" />
    //         ),
    //         value: "AM Drive"
    //       },
    //       {
    //         label: (
    //           <FormattedMessage id="process > option label mid day" />
    //         ),
    //         value: "Mid Day"
    //       },
    //       {
    //         label: (
    //           <FormattedMessage id="process > option label pm drive" />
    //         ),
    //         value: "PM Drive"
    //       },
    //       {
    //         label: (
    //           <FormattedMessage id="process > option label ros anytime" />
    //         ),
    //         value: "ROS - Anytime"
    //       },
    //       {
    //         label: (
    //           <FormattedMessage id="process > option label evening" />
    //         ),
    //         value: "Evening"
    //       },
    //       {
    //         label: (
    //           <FormattedMessage id="process > option label overnight" />
    //         ),
    //         value: "Overnight"
    //       }
    //     ]
    //   }
    // },
    // {
    //   field: "liner_recorded_promos",
    //   title: <FormattedMessage id="process > recorded promos" />
    // },
    {
      key: "save_button",
      text: <FormattedMessage id="process > button save" />,
      // "component": "button",
      on_click: {
        validate: true,
        save: true,
      },
      fields: [
        {
          key: "promotion_details_status",
          // "value": "submitted"
        },
        {
          key: "process_step",
          value: "done",
        },
      ],
      confirmation_dialog_props: {
        description: (
          <FormattedMessage id="process > description to submit promotions" />
        ),
        confirmTitle: <FormattedMessage id="process > button yes" />,
        cancelTitle: <FormattedMessage id="process > button no" />,
      },
      success_dialog_props: {
        title: <FormattedMessage id="process > title submitted" />,
        state: "success",
      },
    },
  ],
};
export default promotionDetails;
