import React, { Component } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import { filter, get, isFunction, forEach, map, find } from "lodash";
import SimpleLineText from "components/FormBuilder/SimpleLineText";
import TextArea from "components/FormBuilder/TextArea";
import Dropdown from "components/FormBuilder/Dropdown";
import Checkbox from "components/FormBuilder/Checkbox";
import DateSelector from "components/FormBuilder/DateSelector";
import EmailAddress from "components/FormBuilder/EmailAddress";
import PhoneNumber from "components/FormBuilder/PhoneNumber";
import BasicFileUpload from "components/FormBuilder/BasicFileUpload";
import StreetAddress from "components/FormBuilder/StreetAddress";
import NumberOfImpression from "components/FormBuilder/NumberOfImpression";
import Demographics from "components/FormBuilder/Demographics";
import GeoTargeting from "components/FormBuilder/GeoTargeting";
import Media from "components/FormBuilder/Media";
import URLField from "components/FormBuilder/URL";
import Radio from "components/FormBuilder/Radio";
import { uuidv4 } from "utils/helpers";

const SortableItem = SortableElement(({ children }) => <div>{children}</div>);

const renderCustomFields = (fields, formProps) => {
  let newFields = fields;
  if (fields.length === 0) return null;
  const fieldsEle = fields.map((field, index) => {
    const { key } = field;
    const fieldProps = {
      key: index,
      value: field,
      formFields: filter(
        fields,
        (formFieldItem, formFieldIndex) => formFieldIndex !== index
      ),
      disabled: formProps.disabled,
      allowDelete: !formProps.isView,
      isNew: true, // we no longer show view mode, only edite //   field.is_new || false,
      errors:
        get(formProps, "touched.fields") &&
        get(formProps, `errors.fields.${index}`, {}),
      onChange: (value) => {
        newFields[index] = value;
        const changeFields = newFields.map(item => {
          // update the field to condition logic object
          if (
            item.is_condition_logic &&
            item.options &&
            item.options.length > 0
          ) {
            return {
              ...item,
              options: map(item.options, (op) => {
                if (get(op, "rule.options.length", 0) > 0){
                  return {
                    ...op,
                    rule: {
                      ...op.rule,
                      options: get(op, "rule.options", []).map(o => {
                        const oFieldData = newFields.find(i => i.uuid === o.uuid);
                        if(!oFieldData) return null
                        return {
                          ...oFieldData,
                          is_controlled: true
                        }
                      }).filter(o => o)
                    }
                  }
                }else{
                  return op
                }
              }),
            };
          }
          return item
        })
        // update is_controlled to each field if this field still controlled by other field
        let controlledFields = [];
        forEach(changeFields, (item) => {
          if (
            item.is_condition_logic &&
            item.options &&
            item.options.length > 0
          ) {
            item.options.forEach((op) => {
              if (get(op, "rule.options.length", 0) > 0) {
                controlledFields = [...controlledFields, ...op.rule.options];
              }
            });
          }
        });

        const fieldsUpdate = map(changeFields, (item) => {
          const isControlled = find(controlledFields, (i) => {
            return (item.uuid && get(i, 'uuid', '') === get(item, 'uuid', ''));
          });
          // clear all rule of each option was added condition logic
          if (
            !item.is_condition_logic &&
            item.options &&
            item.options.length > 0
          ) {
            return {
              ...item,
              options: map(item.options, (o) => ({
                ...o,
                rule: null,
              })),
              is_controlled: !!isControlled,
              addedBy: isControlled ? item.addedBy : null
            };
          }
          return {
            ...item,
            is_controlled: !!isControlled,
            addedBy: isControlled ? item.addedBy : null
          };
        });
        formProps.setFieldValue("fields", fieldsUpdate);
        formProps.setFieldTouched("fields");
        formProps.setShouldBlockNavigation(true);
      },
      onChangeOtherFields: (data) => {
        // controlled
        const conditionFields = newFields.filter(
          (newFieldItem) => newFieldItem.is_condition_logic
        );
        newFields = newFields.map((newFieldItem) => {
          const indexControlled = data.findIndex(
            (dataItem) => dataItem.uuid && get(dataItem, 'uuid', '') === get(newFieldItem, 'uuid', '')
          );
          let isControlled = false;
          if (indexControlled !== -1) {
            isControlled = true;
          } else {
            let conditionFieldRuleOptions = [];
            conditionFields.forEach((conditionFieldItem) => {
              conditionFieldItem.options.forEach((conditionFieldRuleOption) => {
                conditionFieldRuleOptions = [
                  ...conditionFieldRuleOptions,
                  ...get(conditionFieldRuleOption, "rule.options", []),
                ];
              });
            });
            const otherIndexControlled = conditionFieldRuleOptions.findIndex(
              (dataItem) => dataItem.uuid && get(dataItem, 'uuid') === get(newFieldItem, 'uuid')
            );
            if (otherIndexControlled !== -1) {
              isControlled = true;
            }
          }
          return {
            ...newFieldItem,
            is_controlled: isControlled,
            addedBy: isControlled ? newFieldItem.addedBy : null
          };
        });
        formProps.setFieldValue("fields", newFields);
        formProps.setFieldTouched("fields");
        formProps.setShouldBlockNavigation(true);
      },
      highlightSelection: formProps.highlightSelection,
      setHighlightSelection: formProps.setHighlightSelection,
      highlightFields: formProps.highlightFields,
      onToggleSetHighlighFormFields: (data) => {
        formProps.setHighlightFields(data);
      },
      onDelete: () => {
        if (isFunction(formProps.setFieldValue)) {
          const fieldRemoved = newFields[index];
          newFields.splice(index, 1);
          // update is_controlled to each field if this field still controlled by other field
          let controlledFields = [];
          forEach(newFields, (item) => {
            if (
              item.is_condition_logic &&
              item.options &&
              item.options.length > 0
            ) {
              item.options.forEach((op) => {
                if (get(op, "rule.options.length", 0) > 0) {
                  controlledFields = [...controlledFields, ...op.rule.options];
                }
              });
            }
          });
          newFields = newFields.map((item) => {
            if (item.is_condition_logic) {
              const options = get(item, "options", []).map((option) => {
                return {
                  ...option,
                  rule: option.rule
                    ? {
                        ...option.rule,
                        options: get(option, "rule.options", []).filter(
                          (i) =>
                          i.uuid !== fieldRemoved.uuid
                        ),
                      }
                    : null,
                };
              });
              return {
                ...item,
                options,
              };
            }
            const isControlled = find(controlledFields, (i) => {
              return (item.uuid && get(i, 'uuid', '') === get(item, 'uuid', ''));
            });
            // clear all rule of each option was added condition logic
            if (
              !item.is_condition_logic &&
              item.options &&
              item.options.length > 0
            ) {
              return {
                ...item,
                options: map(item.options, (o) => ({
                  ...o,
                  rule: null,
                })),
                is_controlled: !!isControlled,
                addedBy: isControlled ? item.addedBy : null
              };
            }
            return {
              ...item,
              is_controlled: !!isControlled,
              addedBy: isControlled ? item.addedBy : null
            };
          });
          formProps.setFieldValue("fields", newFields);
          formProps.setShouldBlockNavigation(true);
        }
      },
      isDirty: formProps.dirty,
    };
    let fieldElement;
    switch (key) {
      case "single_line_text":
        fieldElement = <SimpleLineText {...fieldProps} />;
        break;
      case "textarea":
        fieldElement = <TextArea {...fieldProps} />;
        break;
      case "dropdown":
        // default 2 options
        if (field.is_new && get(fieldProps, "value.options.length", 0) < 2) {
          fieldProps.value = {
            ...field,
            options: [
              {
                value: "",
                label: "",
                rule: null,
                key: uuidv4(),
              },
              {
                value: "",
                label: "",
                rule: null,
                key: uuidv4(),
              },
            ],
          };
          // set default options to field
          setTimeout(() => {
            newFields[index] = fieldProps.value;
            formProps.setFieldValue("fields", newFields);
          }, 100);
        }
        fieldElement = (
          <Dropdown
            {...fieldProps}
            setIsOpenConfirmationActionsUnsavedModal={
              formProps.setIsOpenConfirmationActionsUnsavedModal
            }
            setIsForceSubmitForm={formProps.setIsForceSubmitForm}
            isShowSectionHeadingOfList
          />
        );
        break;
      case "checkbox":
        // default 2 options
        if (field.is_new && get(fieldProps, "value.options.length", 0) < 1) {
          fieldProps.value = {
            ...field,
            options: [
              {
                value: "",
                label: "",
                is_default: false,
                rule: null,
                key: uuidv4(),
              },
              {
                value: "",
                label: "",
                is_default: false,
                rule: null,
                key: uuidv4(),
              },
            ],
          };
          // set default options to field
          setTimeout(() => {
            newFields[index] = fieldProps.value;
            formProps.setFieldValue("fields", newFields);
          }, 100);
        }
        fieldElement = (
          <Checkbox
            {...fieldProps}
            setIsOpenConfirmationActionsUnsavedModal={
              formProps.setIsOpenConfirmationActionsUnsavedModal
            }
            setIsForceSubmitForm={formProps.setIsForceSubmitForm}
            isShowSectionHeadingOfList
          />
        );
        break;
      case "radio":
        // default 2 options
        if (field.is_new && get(fieldProps, "value.options.length", 0) < 1) {
          fieldProps.value = {
            ...field,
            options: [
              {
                value: "",
                label: "",
                is_default: false,
                rule: null,
                key: uuidv4(),
              },
              {
                value: "",
                label: "",
                is_default: false,
                rule: null,
                key: uuidv4(),
              },
            ],
          };
          // set default options to field
          setTimeout(() => {
            newFields[index] = fieldProps.value;
            formProps.setFieldValue("fields", newFields);
          }, 100);
        }
        fieldElement = (
          <Radio
            {...fieldProps}
            setIsOpenConfirmationActionsUnsavedModal={
              formProps.setIsOpenConfirmationActionsUnsavedModal
            }
            setIsForceSubmitForm={formProps.setIsForceSubmitForm}
            isShowSectionHeadingOfList
          />
        );
        break;
      case "date_selector":
        fieldElement = <DateSelector {...fieldProps} />;
        break;
      case "email_address":
        fieldElement = <EmailAddress {...fieldProps} />;
        break;
      case "phone_number":
        fieldElement = <PhoneNumber {...fieldProps} />;
        break;
      case "url":
        fieldElement = <URLField {...fieldProps} />;
        break;
      case "media_for_review_approval":
        if (field.is_new && get(fieldProps, "value.mimes.length", 0) === 0) {
          fieldProps.value = {
            ...field,
            mimes: [],
          };
        }

        fieldElement = <Media {...fieldProps} />;
        break;
      case "basic_file_upload":
        if (field.is_new && get(fieldProps, "value.mimes.length", 0) === 0) {
          fieldProps.value = {
            ...field,
            mimes: [],
          };
        }
        fieldElement = <BasicFileUpload {...fieldProps} />;
        break;
      case "number_of_impression":
        fieldElement = <NumberOfImpression {...fieldProps} />;
        break;
      case "demographics":
        if (field.is_new && get(fieldProps, "value.includes.length", 0) === 0) {
          fieldProps.value = {
            ...field,
            includes: [],
          };
        }
        fieldElement = <Demographics {...fieldProps} />;
        break;
      case "street_address":
        fieldElement = <StreetAddress {...fieldProps} />;
        break;
      case "geotargeting":
        if (field.is_new && get(fieldProps, "value.length", 0) === 0) {
          fieldProps.value = {
            ...field,
            data: [],
          };
        }
        fieldElement = <GeoTargeting {...fieldProps} />;
        break;
      default:
        break;
    }

    return (
      <SortableItem
        index={index}
        key={`field-item-${index}`}
        disabled={formProps.disabled}
      >
        {fieldElement}
      </SortableItem>
    );
  });
  return fieldsEle;
};
const SortableList = SortableContainer(({ items, formProps }) => {
  return <div>{renderCustomFields(items, formProps)}</div>;
});
class SortableComponent extends Component {
  onSortEnd = ({ oldIndex, newIndex }) => {
    let newItems = arrayMove(this.props.items, oldIndex, newIndex);
    this.props.setItems(newItems);
    this.props.onSorted(newItems);
  };
  render() {
    return (
      <SortableList
        useWindowAsScrollContainer={true}
        formProps={this.props.formProps}
        items={this.props.items}
        renderItem={this.props.renderItem}
        onSortEnd={this.onSortEnd}
        shouldCancelStart={(event) => {
          let isCanStart = false;
          const { path, composedPath } = event;
          const paths = path || (composedPath && event.composedPath()) || [];
          paths.forEach((item) => {
            if (
              item.classList &&
              item.classList.contains("cr-form-builder-fields__menu-icon")
            ) {
              isCanStart = true;
            }
          });
          return !isCanStart;
        }}
      />
    );
  }
}
export default SortableComponent;
