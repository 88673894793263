import React from "react";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
  Label,
  Button,
} from "reactstrap";
import classnames from "classnames";
import bn from "utils/bemnames";
import { FormattedMessage } from "react-intl";
import * as Yup from "yup";
import { Formik } from "formik";
import { get, forEach, includes } from "lodash";
import moment from "moment";
import ConfirmationModal from "components/ConfirmationModal";
import TextInput from "components/TextInput";
import { Asterisk } from "components/Elements";
import InputTime from "components/InputTime";
import ColorPicker from "components/CustomColorPicker";
import DaysOfWeek from "components/DaysOfWeek";
import { getRandomColor } from "utils/colors";
import ToggleSwitch from "components/ToggleSwitch";

const bem = bn.create("clock-calendar-modal");
const DefineShowModal = (props) => {
  const {
    intl,
    isOpen,
    toggle,
    selectedShow,
    onSaveShow,
    isLoading,
    selectedSlot,
    onDeleteShow,
    isOpenDeleteConfirmationModal,
    setIsOpenDeleteConfirmationModal,
    currentShow,
    setCurrentShow,
  } = props;

  let startTime = get(
    selectedShow,
    "start_time",
    get(selectedSlot, "hour") * 60
  );
  let endTime = get(
    selectedShow,
    "end_time",
    (get(selectedSlot, "hour") + 1) * 60
  );

  let startDate = moment().set({
    hours: 0,
    minutes: parseInt(startTime),
    seconds: 0,
    millisecond: 0,
  });
  let endDate = moment().set({
    hours: 0,
    minutes: parseInt(endTime),
    seconds: 0,
    millisecond: 0,
  });

  let showDays = selectedSlot
    ? [get(selectedSlot, "day")]
    : get(selectedShow, "relatedShows", []).map((show) => parseInt(show.day));

  const dateTimeFormat = "YYYY-MM-DD HH:mm:ss";
  const colorData = getRandomColor(props.allShows);
  let initialValues = {
    name: get(selectedShow, "name"),
    color: get(selectedShow, "color")
      ? selectedShow.color
      : get(colorData, "color"),
    start_time: startTime,
    end_time: endTime,
    start_date: startDate.format(dateTimeFormat),
    end_date: endDate.format(dateTimeFormat),
    day: get(selectedShow, "day") || get(selectedSlot, "day"),
    days: showDays,
    related_shows: get(selectedShow, "relatedShows", []),
    order_color: get(selectedShow, "show")
      ? get(selectedShow, "order_color", 0)
      : get(colorData, "order_color"),
    show_type: get(selectedShow, "show_type", "live"),
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(
        intl.formatMessage({
          id: "clock creator > show name is required",
        })
      )
      .max(
        150,
        intl.formatMessage(
          {
            id: "validate > max characters",
          },
          {
            FIELD_NAME: "Name",
            MAX: 150,
          }
        )
      ),
    color: Yup.string()
      .trim()
      .required(
        intl.formatMessage({
          id: "clock creator > show color is required",
        })
      ),
    start_time: Yup.string()
      .trim()
      .required(
        intl.formatMessage({
          id: "clock creator > show start_time is required",
        })
      ),
    end_time: Yup.string()
      .trim()
      .required(
        intl.formatMessage({
          id: "clock creator > show end_time is required",
        })
      ),
    start_date: Yup.date(),
    // disable for span multiple days
    // end_date: Yup
    //     .date()
    //     .when(
    //       "start_date", (start_date, schema) => start_date && schema.min(start_date, intl.formatMessage({
    //       id: "clock creator > show end_time must be greater than start time",
    //     }))
    //   ),
    days: Yup.array().required(
      intl.formatMessage({
        id: "clock creator > show days are required",
      })
    ),
    show_type: Yup.string().required(
      intl.formatMessage({
        id: "clock creator > type is required",
      })
    ),
  });

  return (
    <Modal
      backdrop="static"
      isOpen={isOpen}
      toggle={toggle}
      className={classnames(bem.b(), "modal-dialog-centered")}
    >
      <ModalHeader toggle={toggle}>
        <div className="title">
          <FormattedMessage
            id={`${
              selectedShow
                ? "clock creator > edit show"
                : "clock creator > define show"
            }`}
          />
        </div>
        <div className="sub-title">
          <FormattedMessage id="clock creator > define show helper" />
        </div>
      </ModalHeader>
      <ModalBody>
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          validateOnMount={false}
          validationSchema={validationSchema}
          // enableReinitialize
          initialValues={initialValues}
          isInitialValid={validationSchema.isValidSync(initialValues)}
          onSubmit={onSaveShow}
        >
          {(formProps) => {
            const {
              handleSubmit,
              values,
              errors,
              handleBlur,
              handleChange,
              setFieldValue,
            } = formProps;

            return (
              <React.Fragment>
                <div>
                  <Form onSubmit={handleSubmit} autoComplete="off">
                    <Row className="field-container m-0">
                      <Col
                        xs={11}
                        className={classnames(bem.e("name-container"), "p-0")}
                      >
                        <FormGroup>
                          <TextInput
                            label={
                              <span>
                                <FormattedMessage id="clock creator > show name" />
                                <Asterisk>*</Asterisk>
                              </span>
                            }
                            type="text"
                            name="name"
                            placeholder={intl.formatMessage({
                              id: "clock creator > enter show name",
                            })}
                            value={values.name}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            autoComplete="off"
                            onBlur={handleBlur}
                            error={errors && (errors.name || errors.color)}
                          />
                        </FormGroup>
                      </Col>
                      <Col
                        xs={1}
                        className={classnames(
                          bem.e("color-picker-container"),
                          "p-0"
                        )}
                      >
                        <FormGroup>
                          <Label>
                            <FormattedMessage id="clock creator > show color" />
                          </Label>
                          <ColorPicker
                            selectedColor={values.color}
                            onChange={(color) => {
                              setFieldValue("color", color);
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="field-container m-0">
                      <Col className="p-0">
                        <ToggleSwitch
                          leftComponent={intl.formatMessage({
                            id: "clock creator > live",
                          })}
                          rightComponent={intl.formatMessage({
                            id: "clock creator > voice track",
                          })}
                          className={bem.e("show-type")}
                          name="show_type"
                          switchProps={{
                            checked: values.show_type !== "live",
                            onChange: (checked) => {
                              setFieldValue(
                                "show_type",
                                !checked ? "live" : "voice_track"
                              );
                            },
                            disabled: false,
                            offColor: "#795AFA",
                            onColor: "#795AFA",
                            uncheckedIcon: false,
                            checkedIcon: false,
                            boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.282)",
                            height: 26.44,
                            width: 48.48,
                            handleDiameter: 22.92,
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="field-container m-0">
                      <Col
                        xs={6}
                        className={classnames(
                          bem.e("time-combo-container"),
                          "p-0 start-time"
                        )}
                      >
                        <Label>
                          <FormattedMessage id="clock creator > show start time" />
                          <Asterisk>*</Asterisk>
                        </Label>
                        <InputTime
                          id="startTimerInput"
                          time={values.start_date}
                          onChange={(startDateTime) => {
                            if (startDateTime) {
                              setFieldValue(
                                "start_date",
                                startDateTime.format(dateTimeFormat)
                              );
                              setFieldValue(
                                "start_time",
                                parseInt(startDateTime.get("hours")) * 60 +
                                  parseInt(startDateTime.get("minutes"))
                              );
                            } else {
                              setFieldValue("start_date", undefined);
                              setFieldValue("start_time", undefined);
                            }
                          }}
                          error={
                            errors && (errors.start_time || errors.start_date)
                          }
                        />
                      </Col>
                      <span className="start-end-time-separator">TO</span>
                      <Col
                        xs={6}
                        className={classnames(
                          bem.e("time-combo-container"),
                          "p-0 end-time"
                        )}
                      >
                        <Label>
                          <FormattedMessage id="clock creator > show end time" />
                          <Asterisk>*</Asterisk>
                        </Label>
                        <InputTime
                          id="endTimerInput"
                          time={values.end_date}
                          onChange={(endDateTime) => {
                            if (endDateTime) {
                              setFieldValue(
                                "end_date",
                                endDateTime.format(dateTimeFormat)
                              );
                              setFieldValue(
                                "end_time",
                                parseInt(endDateTime.get("hours")) * 60 +
                                  parseInt(endDateTime.get("minutes"))
                              );
                            } else {
                              setFieldValue("end_date", undefined);
                              setFieldValue("end_time", undefined);
                            }
                          }}
                          error={errors && (errors.end_time || errors.end_date)}
                        />
                      </Col>
                    </Row>
                    <Row className={bem.e("separator")}></Row>
                    <Row className="field-container">
                      <Col>
                        <div className="title">
                          <FormattedMessage id="clock creator > copy show" />
                        </div>
                        <div className="sub-title">
                          <FormattedMessage id="clock creator > copy show helper" />
                        </div>
                      </Col>
                    </Row>
                    <Row className="field-container">
                      <Col>
                        <DaysOfWeek
                          value={values.days}
                          label={intl.formatMessage({
                            id: "clock creator > show days",
                          })}
                          onChange={(days) => {
                            let relatedShows = [];

                            forEach(values.related_shows, (relatedShow) => {
                              relatedShows.push({
                                ...relatedShow,
                                deleted: !includes(
                                  days,
                                  parseInt(relatedShow.day)
                                ),
                              });
                            });

                            setFieldValue("related_shows", relatedShows);
                            setFieldValue("days", days);
                          }}
                          enableWeekendWeekdayCheckboxes={true}
                          error={errors && errors.days}
                          isRequired={true}
                        />
                      </Col>
                    </Row>
                    <Row className="field-container">
                      <Col xs={12}>
                        <div className={bem.e("form-buttons")}>
                          <Button
                            color="primary"
                            type="submit"
                            className="btn btn-blue btn-radius"
                            disabled={isLoading}
                            onClick={handleSubmit}
                          >
                            <FormattedMessage
                              id={`clock creator > button ${
                                !isLoading ? "save" : "submitting"
                              }`}
                            />
                          </Button>
                          <Button
                            type="button"
                            onClick={() => {
                              toggle(false);
                            }}
                            outline
                            className="btn-outline-blue btn-radius button-cancel"
                          >
                            <FormattedMessage id="clock creator > button cancel" />
                          </Button>
                          {selectedShow && (
                            <Button
                              type="button"
                              onClick={() => {
                                setIsOpenDeleteConfirmationModal(true);
                                setCurrentShow(values);
                              }}
                              outline
                              className="btn-outline-blue btn-radius button-delete"
                            >
                              <FormattedMessage id="clock creator > button delete" />
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </React.Fragment>
            );
          }}
        </Formik>
        {/* confirmation delete modal */}
        <ConfirmationModal
          isOpen={isOpenDeleteConfirmationModal}
          className={bem.e("confirmation-modal")}
          title={
            <FormattedMessage id="clock creator > are you sure you want to delete" />
          }
          description={null}
          cancelTitle={<FormattedMessage id="clock creator > button no" />}
          confirmTitle={<FormattedMessage id="clock creator > button yes" />}
          onCancel={() => {
            setIsOpenDeleteConfirmationModal(false);
            setCurrentShow(null);
          }}
          onConfirm={() => {
            onDeleteShow(currentShow);
            setCurrentShow(null);
          }}
          onToggle={() =>
            setIsOpenDeleteConfirmationModal(!isOpenDeleteConfirmationModal)
          }
          isCloseOutside={false}
        />
      </ModalBody>
    </Modal>
  );
};

export default DefineShowModal;
