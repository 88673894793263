import React, { Component } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import { filter, get, isFunction } from "lodash";
import TextArea from "components/FormBuilder/TextArea";
import Dropdown from "components/FormBuilder/Dropdown";
import Checkbox from "components/FormBuilder/Checkbox";
import DateSelector from "components/FormBuilder/DateSelector";
import BasicFileUpload from "components/FormBuilder/BasicFileUpload";
import Radio from "components/FormBuilder/Radio";
import HelperText from "components/FormBuilder/HelperText";
import SimpleLineText from "components/FormBuilder/SimpleLineText";
import CurrentJob from "components/FormBuilder/CurrentJob";
import PerPreference from "components/FormBuilder/PerPreference";
import CollegeAlmaMater from "components/FormBuilder/CollegeAlmaMater";
const SortableItem = SortableElement(({ children }) => <div>{children}</div>);

const renderCustomFields = (fields, formProps) => {
  const validFields = fields;
  let newFields = validFields;

  if (fields.length === 0) return null;
  const fieldsEle = validFields.map((field, index) => {
    const { key } = field;
    let fieldProps = {
      key: index,
      value: field,
      formFields: filter(
        fields,
        (formFieldItem, formFieldIndex) => formFieldIndex !== index
      ),
      highlightFields: [],
      disabled: formProps.isView,
      allowDelete: !formProps.isView,
      isNew: true,
      isShowRequiredToggle: true,
      errors:
        get(formProps, "touched.fields") &&
        get(formProps, `errors.fields.${index}`, {}),
      onChange: (value) => {
        newFields[index] = value;
        formProps.setFieldValue(newFields);
      },
      onDelete: () => {
        if (isFunction(formProps.onDeleteField)) {
          formProps.onDeleteField(index);
        }
      },
      onDuplicate: () => {
        formProps.setFieldValue([...newFields, field]);
      },
    };
    let fieldElement;
    let isSortableDisabled = formProps.disabled;
    switch (key) {
      case "helper_text":
        fieldElement = <HelperText {...fieldProps} isTextarea={false} />;
        break;
      case "single_line_text":
        fieldElement = <SimpleLineText {...fieldProps} />;
        break;
      case "textarea":
        fieldElement = <TextArea {...fieldProps} />;
        break;
      case "dropdown":
        // default 2 options
        if (field.is_new && get(fieldProps, "value.options.length", 0) < 2) {
          fieldProps.value = {
            ...field,
            options: [
              {
                value: "",
                label: "",
                rule: null,
              },
              {
                value: "",
                label: "",
                rule: null,
              },
            ],
          };
          // set default options to field
          setTimeout(() => {
            newFields[index] = fieldProps.value;
            formProps.setFieldValue(newFields);
          }, 100);
        }
        fieldElement = (
          <Dropdown {...fieldProps} isAllowConditionLogic={false} />
        );
        break;
      case "checkbox":
        // default 2 options
        if (field.is_new && get(fieldProps, "value.options.length", 0) < 1) {
          fieldProps.value = {
            ...field,
            options: [
              {
                value: "",
                label: "",
                is_default: false,
                rule: null,
              },
              {
                value: "",
                label: "",
                is_default: false,
                rule: null,
              },
            ],
          };
          // set default options to field
          setTimeout(() => {
            newFields[index] = fieldProps.value;
            formProps.setFieldValue(newFields);
          }, 100);
        }
        fieldElement = (
          <Checkbox {...fieldProps} isAllowConditionLogic={false} />
        );
        break;
      case "radio":
        // default 2 options
        if (field.is_new && get(fieldProps, "value.options.length", 0) < 1) {
          fieldProps.value = {
            ...field,
            options: [
              {
                value: "",
                label: "",
                is_default: false,
                rule: null,
              },
              {
                value: "",
                label: "",
                is_default: false,
                rule: null,
              },
            ],
          };
          // set default options to field
          setTimeout(() => {
            newFields[index] = fieldProps.value;
            formProps.setFieldValue(newFields);
          }, 100);
        }
        fieldElement = <Radio {...fieldProps} isAllowConditionLogic={false} />;
        break;
      case "date_selector":
        fieldElement = <DateSelector {...fieldProps} />;
        break;
      case "basic_file_upload":
        if (field.is_new && get(fieldProps, "value.mimes.length", 0) === 0) {
          fieldProps.value = {
            ...field,
            mimes: [],
          };
        }
        fieldElement = (
          <BasicFileUpload {...fieldProps} isAllowDuplicate={false} />
        );
        break;
      case "current_job":
        fieldElement = <CurrentJob {...fieldProps} />;
        break;
      case "college_alma_mater":
        fieldElement = <CollegeAlmaMater {...fieldProps} />;
        break;
      case "per_preference":
        fieldElement = <PerPreference {...fieldProps} />;
        break;
      default:
        break;
    }

    return (
      <SortableItem
        index={index}
        key={`field-item-${index}`}
        disabled={isSortableDisabled}
      >
        {fieldElement}
      </SortableItem>
    );
  });
  return fieldsEle;
};
const SortableList = SortableContainer(({ items, formProps }) => {
  return <div>{renderCustomFields(items, formProps)}</div>;
});
class SortableComponent extends Component {
  onSortEnd = ({ oldIndex, newIndex }) => {
    let newItems = arrayMove(this.props.items, oldIndex, newIndex);
    this.props.setItems(newItems);
    this.props.onSorted(newItems);
  };
  render() {
    return (
      <SortableList
        // useWindowAsScrollContainer={true}
        formProps={this.props.formProps}
        items={this.props.items}
        renderItem={this.props.renderItem}
        onSortEnd={this.onSortEnd}
        shouldCancelStart={(event) => {
          let isCanStart = false;
          const { path, composedPath } = event;
          const paths = path || (composedPath && event.composedPath()) || [];
          paths.forEach((item) => {
            if (
              item.classList &&
              item.classList.contains("cr-form-builder-fields__menu-icon")
            ) {
              isCanStart = true;
            }
          });
          return !isCanStart;
        }}
      />
    );
  }
}
export default SortableComponent;
