export const GET_JOCK_SCHEDULES_REQUEST = "GET_JOCK_SCHEDULES_REQUEST";
export const GET_JOCK_SCHEDULES_SUCCESS = "GET_JOCK_SCHEDULES_SUCCESS";
export const GET_JOCK_SCHEDULES_ERROR = "GET_JOCK_SCHEDULES_ERROR";

export const GET_SCHEDULE_REQUEST = "GET_SCHEDULE_REQUEST";
export const GET_SCHEDULE_SUCCESS = "GET_SCHEDULE_SUCCESS";
export const GET_SCHEDULE_ERROR = "GET_SCHEDULE_ERROR";

export const UPDATE_SCHEDULE_REQUEST = "UPDATE_SCHEDULE_REQUEST";
export const UPDATE_SCHEDULE_SUCCESS = "UPDATE_SCHEDULE_SUCCESS";
export const UPDATE_SCHEDULE_ERROR = "UPDATE_SCHEDULE_ERROR";

export const SET_SCHEDULE_SUCCESS = "SET_SCHEDULE_SUCCESS";

export const FETCH_JOCK_CONSOLE_WIDGETS_REQUEST =
  "FETCH_JOCK_CONSOLE_WIDGETS_REQUEST";
export const FETCH_JOCK_CONSOLE_WIDGETS_SUCCESS =
  "FETCH_JOCK_CONSOLE_WIDGETS_SUCCESS";
export const FETCH_JOCK_CONSOLE_WIDGETS_ERROR =
  "FETCH_JOCK_CONSOLE_WIDGETS_ERROR";
export const SET_JOCK_CONSOLE_WIDGETS_SUCCESS =
  "SET_JOCK_CONSOLE_WIDGETS_SUCCESS";

export const UPDATE_ACTIVE_JOCK_CONSOLE_WIDGET_REQUEST =
  "UPDATE_ACTIVE_JOCK_CONSOLE_WIDGET_REQUEST";
export const UPDATE_ACTIVE_JOCK_CONSOLE_WIDGET_SUCCESS =
  "UPDATE_ACTIVE_JOCK_CONSOLE_WIDGET_SUCCESS";
export const UPDATE_ACTIVE_JOCK_CONSOLE_WIDGET_ERROR =
  "UPDATE_ACTIVE_JOCK_CONSOLE_WIDGET_ERROR";

export const UPDATE_ORDER_WIDGETS_REQUEST = "UPDATE_ORDER_WIDGETS_REQUEST";
export const UPDATE_ORDER_WIDGETS_SUCCESS = "UPDATE_ORDER_WIDGETS_SUCCESS";
export const UPDATE_ORDER_WIDGETS_ERROR = "UPDATE_ORDER_WIDGETS_ERROR";

export const UPDATE_JOCK_CONSOLE_WIDGET_REQUEST =
  "UPDATE_JOCK_CONSOLE_WIDGET_REQUEST";
export const UPDATE_JOCK_CONSOLE_WIDGET_SUCCESS =
  "UPDATE_JOCK_CONSOLE_WIDGET_SUCCESS";
export const UPDATE_JOCK_CONSOLE_WIDGET_ERROR =
  "UPDATE_JOCK_CONSOLE_WIDGET_ERROR";

export const FORGET_JOCK_CONSOLE_WIDGETS = "FORGET_JOCK_CONSOLE_WIDGETS";

export const ADD_JOCK_CONSOLE_WIDGET_LOCAL_SUCCESS =
  "ADD_JOCK_CONSOLE_WIDGET_LOCAL_SUCCESS";
export const DELETE_JOCK_CONSOLE_WIDGET_LOCAL_SUCCESS =
  "DELETE_JOCK_CONSOLE_WIDGET_LOCAL_SUCCESS";

export const ADD_JOCK_CONSOLE_WIDGET_REQUEST =
  "ADD_JOCK_CONSOLE_WIDGET_REQUEST";
export const ADD_JOCK_CONSOLE_WIDGET_SUCCESS =
  "ADD_JOCK_CONSOLE_WIDGET_SUCCESS";
export const ADD_JOCK_CONSOLE_WIDGET_ERROR = "ADD_JOCK_CONSOLE_WIDGET_ERROR";

export const DELETE_JOCK_CONSOLE_WIDGET_REQUEST =
  "DELETE_JOCK_CONSOLE_WIDGET_REQUEST";
export const DELETE_JOCK_CONSOLE_WIDGET_SUCCESS =
  "DELETE_JOCK_CONSOLE_WIDGET_SUCCESS";
export const DELETE_JOCK_CONSOLE_WIDGET_ERROR =
  "DELETE_JOCK_CONSOLE_WIDGET_ERROR";

export const FETCH_DISPLAY_SETTINGS_REQUEST = "FETCH_DISPLAY_SETTINGS_REQUEST";
export const FETCH_DISPLAY_SETTINGS_SUCCESS = "FETCH_DISPLAY_SETTINGS_SUCCESS";
export const FETCH_DISPLAY_SETTINGS_ERROR = "FETCH_DISPLAY_SETTINGS_ERROR";

export const UPDATE_DISPLAY_SETTINGS_REQUEST =
  "UPDATE_DISPLAY_SETTINGS_REQUEST";
export const UPDATE_DISPLAY_SETTINGS_SUCCESS =
  "UPDATE_DISPLAY_SETTINGS_SUCCESS";
export const UPDATE_DISPLAY_SETTINGS_ERROR = "UPDATE_DISPLAY_SETTINGS_ERROR";

export const HIDE_DISPLAY_SETTINGS_MODAL = "HIDE_DISPLAY_SETTINGS_MODAL";
export const SHOW_DISPLAY_SETTINGS_MODAL = "SHOW_DISPLAY_SETTINGS_MODAL";

export const JOCK_CONSOLE_WIDGET_FETCH_FEED_REQUEST =
  "JOCK_CONSOLE_WIDGET_FETCH_FEED_REQUEST";
export const JOCK_CONSOLE_WIDGET_FETCH_FEED_SUCCESS =
  "JOCK_CONSOLE_WIDGET_FETCH_FEED_SUCCESS";
export const JOCK_CONSOLE_WIDGET_FETCH_FEED_ERROR =
  "JOCK_CONSOLE_WIDGET_FETCH_FEED_ERROR";

export const SET_STATION_DISPLAY_SETTINGS_MODAL =
  "SET_STATION_DISPLAY_SETTINGS_MODAL";

export const GET_JOCK_SCHEDULES_FLOATING_CONTEST_REQUEST =
  "GET_JOCK_SCHEDULES_FLOATING_CONTEST_REQUEST";
export const GET_JOCK_SCHEDULES_FLOATING_CONTEST_SUCCESS =
  "GET_JOCK_SCHEDULES_FLOATING_CONTEST_SUCCESS";
export const GET_JOCK_SCHEDULES_FLOATING_CONTEST_ERROR =
  "GET_JOCK_SCHEDULES_FLOATING_CONTEST_ERROR";
