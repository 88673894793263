import { connect } from "react-redux";
import ItemScheduling from "./view";
import { injectIntl } from "react-intl";
import { setSchedulingAvailability } from "store/actions/programmings";
import { get } from "lodash";
const Enhanced = connect(
  (state) => {
    return {
      auth: state.auth,
      defaultSchedulingType: "simple",
      dateFormatByServer: get(
        state,
        "servers.currentServer.data.date_format",
        "MM/DD/YYYY"
      ),
    };
  },
  { setSchedulingAvailability }
)(ItemScheduling);

export default injectIntl(Enhanced);
