export const FETCH_CHANNELS_REQUEST = "FETCH_CHANNELS_REQUEST";
export const FETCH_CHANNELS_SUCCESS = "FETCH_CHANNELS_SUCCESS";
export const FETCH_CHANNELS_ERROR = "FETCH_CHANNELS_ERROR";

export const UPDATE_CHANNEL_REQUEST = "UPDATE_CHANNEL_REQUEST";
export const UPDATE_CHANNEL_SUCCESS = "UPDATE_CHANNEL_SUCCESS";
export const UPDATE_CHANNEL_ERROR = "UPDATE_CHANNEL_ERROR";

export const UPDATE_CHANNELS_REQUEST = "UPDATE_CHANNELS_REQUEST";
export const UPDATE_CHANNELS_SUCCESS = "UPDATE_CHANNELS_SUCCESS";
export const UPDATE_CHANNELS_ERROR = "UPDATE_CHANNELS_ERROR";

export const ADD_CHANNEL_REQUEST = "ADD_CHANNEL_REQUEST";
export const ADD_CHANNEL_SUCCESS = "ADD_CHANNEL_SUCCESS";
export const ADD_CHANNEL_ERROR = "ADD_CHANNEL_ERROR";

export const DELETE_CHANNEL_REQUEST = "DELETE_CHANNEL_REQUEST";
export const DELETE_CHANNEL_SUCCESS = "DELETE_CHANNEL_SUCCESS";
export const DELETE_CHANNEL_ERROR = "DELETE_CHANNEL_ERROR";

export const CLONE_CHANNEL_REQUEST = "CLONE_CHANNEL_REQUEST";
export const CLONE_CHANNEL_SUCCESS = "CLONE_CHANNEL_SUCCESS";
export const CLONE_CHANNEL_ERROR = "CLONE_CHANNEL_ERROR";
export const CANCEL_CHANNEL_REQUEST = "CANCEL_CHANNEL_REQUEST";
export const CANCEL_CHANNEL_SUCCESS = "CANCEL_CHANNEL_SUCCESS";
export const CANCEL_CHANNEL_ERROR = "CANCEL_CHANNEL_ERROR";

export const UPDATE_ORDER_CHANNELS_REQUEST = "UPDATE_ORDER_CHANNELS_REQUEST";
export const UPDATE_ORDER_CHANNELS_SUCCESS = "UPDATE_ORDER_CHANNELS_SUCCESS";
export const UPDATE_ORDER_CHANNELS_ERROR = "UPDATE_ORDER_CHANNELS_ERROR";

export const FORGET_CHANNELS = "FORGET_CHANNELS";

export const SET_ORDER_CHANNELS = "SET_ORDER_CHANNELS";
