import React, { Component, Fragment } from "react";
import { Button, Input } from "reactstrap";
import moment from 'moment';
import OrderDetails from "components/OrderDetails";
import PromotionDetails from "components/PromotionDetails";
import PromotionRequestDetails from "components/PromotionRequestDetails";
import PromotionLinerDetails from "components/PromotionLinerDetails";
import PromotionLinerScripts from 'components/PromotionLinerScripts';
import PromotionEventDetails from "components/PromotionEventDetails";
import PromotionSchedule from 'components/PromotionSchedule';
import PromotionLinerClient from 'components/PromotionLinerClient';
// promotion contest
import PromotionContestWinners from 'components/PromotionContest/Winners';
import PromotionContestPrizes from 'components/PromotionContest/Prizes';
import PromotionContestDetails from 'components/PromotionContest/Overview';
import PrizesDetails from "components/PrizesDetails";
import TrafficDetails from "components/TrafficDetails";
import DigitalReport from "components/DigitalReport";
import DigitalOrderDetails from "components/DigitalOrderDetails";
import DigitalApproval from "components/DigitalApproval";
import ReviewMedia from "components/ReviewMedia";
import Traffic from "components/Traffic";
import Design from "components/Design";
import Media from "components/Media";

import TeamEquipment from "components/TeamEquipment";
import SendMailDialog from "components/SendMailDialog";
import OrderApprovals from "components/OrderApprovals";
import OrderScript from 'components/OrderScript';
import OrderScriptReview from 'components/OrderScriptReview';
import OrderScriptReviewFeedback from 'components/OrderScriptReview/OrderScriptReviewFeedback';
import OrderScriptReviewHistory from 'components/OrderScriptReview/OrderScriptReviewHistory';
import VoiceTracks from "components/VoiceTracks";
import OrderRecordingReview from "components/OrderRecordingReview";
import OrderRecordingHistory from "components/OrderRecordingReview/OrderRecordingHistory";
import ProductionAssignments from 'components/ProductionAssignments';
import ProductionDetails from "components/OrderDetails/ProductionDetails";
import ProductionReschedule from "components/ProductionReschedule";
import ProductionContinuityTrafficDetails from "components/ProductionTrafficDetails/Continuity";
import ProductionDubbingTrafficDetails from "components/ProductionTrafficDetails/Dubbing";
import ProductionRecording from "components/ProductionRecording"
import OrderTextTimer from "components/OrderTextTimer";
import VoiceOverDetails from 'components/VoiceOverDetails';
import SelectTeamVo from 'components/SelectTeamVo';
import OrderDetailsView from 'components/OrderDetailsView';
// import axios from "axios";
import {
  setScriptField,
  formatDisplayDateTime,
  getUserName,
  getFileName,
  uuidv4,
  firstUndoneStep,
  stepAssignedByRoles
} from "utils/helpers";
import { concat, find, pick, filter, uniqBy, uniq, isFunction, omit, includes, findIndex, forEach, sortBy, map } from "lodash";
import SelectTeam from "components/SelectTeam";
import DropdownCustomOption from "components/DropdownCustomOption";
import { FormattedMessage } from "react-intl";
import UploadFile from "components/UploadFile";
import UploadFiles from "components/UploadFiles";
import SelectStations from "../SelectStations";
import ToastManager from "components/ToastManager";
import TextareaEvaluator from "components/TextareaEvaluator";
import ConfirmationModal from "components/ConfirmationModal";
import Player from "components/Player";
import Image from "components/Image";
import ActivityLog from "components/ActivityLog";
import TextInput from "components/TextInput";
import Checkbox from "components/Checkbox";
import FormFactory from "./formFactory";
import { differenceWith, get, isEqual } from "lodash";
import { SectionTitle, Asterisk } from "components/Elements";
import { injectIntl } from "react-intl";
// import { convertUrlHttps, download } from "../UploadFile/helper";
import FileView from "components/FileView";
import history from "components/History";
// import DigitalApproval from "components/DigitalApproval";
// import Design from "components/Design";
// import Traffic from "components/Traffic";
import PrivilegedComponent from "components/PrivilegedComponent";
import HandleAssets from "components/HandleAssets";
import { validatePrivileges } from "utils/helpers";
import ProductionDeadlines from "components/ProductionDeadlines";


class ComponentFactory extends Component {
  state = {
    // contentState: null,
    stationsWithoutSpots: null,
    warningDialogProps: null,
    editScript: false,
    editRecording: false,
    editRecordingPathB: false,
    newRecording: "",
    newRecordingPathB: "",
    prevRecording: null,
    refreshField: uuidv4(),
    buttonClicking: null,
    originalScript: false,
    originalScriptPartB: false,
  };
  componentDidMount(){
    // assign buttonClicked function to parent component to handle. 
    this.props.submitRefFunc.current = this.buttonClicked;
  }
  render() {
    let factoryDom = null;
    switch (this.props.ui_hint) {
      case "form":
        factoryDom = (
          <FormFactory
            {...this.props}
            valueChanged={this.valueChanged}
            buttonClicked={this.buttonClicked}
            validationState={this.props.validationState}
          />
        );
        break;
      default:
        factoryDom = this.factory(
          this.props.step,
          this.props.item,
          this.props.process,
          this.props.template,
          this.props.user,
          this.props.info
        );
    } //(this.props.component_index === 0 ?
    return (
      <React.Fragment>
        {factoryDom}
        

        <ConfirmationModal
          {...this.state.warningDialogProps}
          isOpen={!!this.state.warningDialogProps}
        />
      </React.Fragment>
    );
    // : factoryDom
  }

  getRoleCandidates(item, process, template, user) {
    if (
      !process ||
      !process.data ||
      !process.data.fields ||
      !process.data.fields.order_stations ||
      !item
    )
      return;
    // debugger
    let info = this.props.info;
    let stations = process.data.fields.order_stations
      ? process.data.fields.order_stations.value
      : [];
    let roles = item.roles;
    // let privileges = item.privileges || [];
    // only stations targeted in this campaign
    // let stations_info = info.stations.filter(
    //   e => stations.indexOf(e.station_key) > -1
    // );
    // only users linked to all those stations
    let users_info = [];
    let calc_station_info = {};
    let calc_user_info = {};
    const totalUsers = get(info, 'users.length', 0);
    for (let i1 = 0; i1 < totalUsers; i1++) {
      let station_user = info.users[i1];

      for (let i3 = 0; i3 < station_user.stations.length; i3++) {
        let user_station = station_user.stations[i3];
        let station_key = user_station.station_key;
        if (stations.indexOf(station_key) === -1 || user_station.implicit) continue;
        // find by privileges
        const isValid = validatePrivileges({
          requires: get(item, 'privileges', []),
          privileges: Object.values(user_station.user_privileges),
          user: user_station
        });
        if(isValid){
          if (!calc_station_info[station_key])
            calc_station_info[station_key] = [];
          if (calc_station_info[station_key].indexOf(station_user.id) === -1)
            calc_station_info[station_key].push(station_user.id);
          if (!calc_user_info[station_user.id])
            calc_user_info[station_user.id] = [];
          if (
            !calc_user_info[station_user.id].find(
              si => si.station_key === station_key
            )
          )
            calc_user_info[station_user.id].push({
              station_key,
              user_role_id: roles[0] // user picked by privilege, fulfiles the first role
            });
        }
        // for (
        //   let i4 = 0;
        //   i4 < Object.keys(user_station.user_privileges).length;
        //   i4++
        // ) {
        //   let user_privilege_id =
        //     user_station.user_privileges[
        //     Object.keys(user_station.user_privileges)[i4]
        //     ];
        //   if (privileges.indexOf(user_privilege_id) > -1) {
        //     if (!calc_station_info[station_key])
        //       calc_station_info[station_key] = [];
        //     if (calc_station_info[station_key].indexOf(station_user.id) === -1)
        //       calc_station_info[station_key].push(station_user.id);
        //     if (!calc_user_info[station_user.id])
        //       calc_user_info[station_user.id] = [];
        //     if (
        //       !calc_user_info[station_user.id].find(
        //         si => si.station_key === station_key
        //       )
        //     )
        //       calc_user_info[station_user.id].push({
        //         station_key,
        //         user_role_id: roles[0] // user picked by privilege, fulfiles the first role
        //       });
        //   }
        // }
      }
    }

    let valid_users = [];
    for (let i = 0; i < Object.keys(calc_user_info).length; i++) {
      let user_id = Object.keys(calc_user_info)[i];
      let user_stations = calc_user_info[user_id];
      if (user_stations.length === stations.length) {
        if (valid_users.indexOf(user_id) === -1) {
          valid_users.push(user_id);
        }
      }
    }
    for (let i = 0; i < valid_users.length; i++) {
      let user_id = valid_users[i];
      let user = info.users.find(u => u.id === parseInt(user_id));
      if(user.is_active){
        users_info.push({
          value: parseInt(user_id),
          label: user.name,
          role: parseInt(calc_user_info[user_id][0].user_role_id),
          info: info.users.find(u => u.id === parseInt(user_id))
        });
      }
    }

    return sortBy(users_info, item => item.label.toLowerCase());//.map(u=>{return {value: u.id, label: u.name, role: u.role}})
  }

  valueChanged = (item, process, template, user, event) => {
    try {
      this.props.clearValidationError(item);
      this.props.formValueChanged({
        process,
        template,
        item,
        user,
        value: !event
          ? ""
          : Array.isArray(event)
            ? event
            : event.value !== undefined
              ? event.value
              : event.target
                ? event.target.value
                : event
      });
    } catch (e) {
      debugger;
    }
  };

  // downloadFile = async path => {
  //   axios({
  //     url: convertUrlHttps(path),
  //     method: "GET",
  //     responseType: "blob"
  //   })
  //     .then(response => {
  //       const { data } = response;
  //       if (data) {
  //         const fileName = path.replace(/^.*[\\/]/, "");
  //         download(data, fileName);
  //       } else {
  //         alert(`Can't download`);
  //       }
  //     })
  //     .catch(e => {
  //       const { message } = e;
  //       alert(message);
  //     });
  // };

  valueEvaluatorChanged = (
    item,
    process,
    template,
    user,
    value
  ) => {
    this.props.clearValidationError(item);
    this.props.formValueChanged({
      process,
      template,
      item,
      user,
      value
    });
  };
  onValueChangedSpot = (index, { field, value }, process, step, template, user) => {
    const { dateFormatByServer } = this.props;
		const spotInfoField = step.fields.find((f) => f.field === "spot_info");
    const scriptAuditField = step.fields.find((f) => f.field === "script_audit");

    const scriptBAuditField = step.fields.find((f) => f.field === "script_part_b_audit");

    const scriptHistoryField = step.fields.find((f) => f.field === "script_history");
		const spotInfo = get(process, "data.fields.spot_info.value", []);

    const spot = spotInfo[index];

		if (spot) {

      if(field === 'script' && (step.key === 'record_script' || step.key === 'voice_over') && spot.script !== value){

        this.valueChanged(scriptAuditField, process, template, user, {
          value: {
            modified_at : `${moment().format(dateFormatByServer).toString()} at ${moment().format("h:mm A").toString()}`,
            modified_by: user.name,
          },
        });

        this.valueChanged(scriptBAuditField, process, template, user, {
          value: {
            modified_at : `${moment().format(dateFormatByServer).toString()} at ${moment().format("h:mm A").toString()}`,
            modified_by: user.name,
          },
        });

        let currentScriptHistory = get(process,"data.fields.script_history.value", []);
        let newScriptHistory = [];

        if(!get(currentScriptHistory[0], "new_version_by", false) || (get(currentScriptHistory[0], "new_version_by", false) !== user.id)){
          // let sriptApproveStatus = get(process,"data.fields.approve_script_status.value", "");

          newScriptHistory.push({
            script: spot.script,
            script_part_b: spot.script_part_b,
            script_audit: get(process,"data.fields.script_audit.value"),
            script_part_b_audit: get(process,"data.fields.script_part_b_audit.value"),
            feedback: "",
            script_part_b_feedback: "",
            script_feedback_audit: "",
            script_part_b_feedback_audit: "",
            new_version_by: user.id,
          });

          forEach(currentScriptHistory, scriptHistory => {
            newScriptHistory.push(scriptHistory);
          });

          this.valueChanged(scriptHistoryField, process, template, user, {
            value: newScriptHistory,
          });
        }
      }
      
			spotInfo[index] = {
				...spot,
				[field]: value,
			};
			this.valueChanged({
        ...spotInfoField,
        changing: spotInfo.map((item, i) => {
          if(index === i) return [field]
          return []
        })
      }, process, template, user, {
				value: spotInfo,
			});
		}
	};
  spotsChanged = (item, process, template, user, event) => {
    this.props.clearValidationError(item);
    if (!process.data.fields.key) {
      ToastManager.show({
        title: this.props.intl.formatMessage({ id: "toast > title not saved" }),
        message: this.props.intl.formatMessage({
          id: "toast > message please save the order before adding new spots"
        }),
        level: "error"
      });
      return;
    }
    let spots = process.data.fields.spots
      ? process.data.fields.spots.value
      : [process.data.fields.key.value];
    if (event === "+1") event = { target: { value: spots.length + 1 } };
    if (
      spots.length > parseInt(event.target.value) &&
      spots[spots.length - 1] !== "new"
    ) {
      ToastManager.show({
        title: this.props.intl.formatMessage({
          id: "toast > title cant delete a saved spot"
        }),
        message: this.props.intl.formatMessage({
          id:
            "toast > message spots cannot be reduced here, you can delete a spot from the processes screen"
        })
      });
      return;
    }
    while (spots.length < parseInt(event.target.value)) {
      spots.push("new");
    }
    spots = spots.slice(0, parseInt(event.target.value));
    this.props.formValueChanged({
      process,
      template,
      item: { field: "spots" },
      user,
      value: spots
    });
    this.props.formValueChanged({
      process,
      template,
      item,
      user,
      value: event.value || event.target.value
    });
  };
  checkboxChanged = (item, process, template, user, event) => {
    this.props.clearValidationError(item);
    this.props.formValueChanged({
      process,
      template,
      item,
      user,
      value: event.target.checked
    });
  };

  checkboxGroupChanged = (item, process, template, user, event) => {
    this.props.clearValidationError(item);
    var value = process.data.fields[item.field]
      ? [...process.data.fields[item.field].value]
      : [];
    if (value.indexOf(event.target.value) > -1) {
      let ind = value.indexOf(event.target.value);
      value = [...value.slice(0, ind), ...value.slice(ind + 1)];
    } else {
      value.push(event.target.value);
    }
    // if (value.length === 0)
    //   ToastManager.show({
    //     title: this.props.intl.formatMessage({id: "toast > title can't uncheck"}),
    //     message: this.props.intl.formatMessage({id: "toast > message at least one station must be selected"}),
    //     level: "warning"
    //   });
    // else
    this.props.formValueChanged({ process, template, item, user, value });
  };

  multipleStationSelectionChanged = (item, process, template, user, value) => {
    // if (value.length === 0)
    //   ToastManager.show({
    //     title: this.props.intl.formatMessage({id: "toast > title can't unselect"}),
    //     message: this.props.intl.formatMessage({id: "toast > message at least one station must be selected"}),
    //     level: "warning"
    //   });
    // else
    this.props.clearValidationError(item);
    this.props.formValueChanged({ process, template, item, user, value });
  };

  recomputeRoles = (step, item, process, template, user) => {
    const extraData = pick(this.props.info, ['enable_digital', 'production_multimarket', 'enable_production', 'enable_promotion', 'enable_wo', 'roles_string'])
    const currentStep = step;
    let still_required_users = [];
    let next_users = [];
    // let still_required_roles =
    //   item.required_roles_func &&
    //   safeEval(item.required_roles_func)(user, template, process, step, item);
    // let next_roles =
    //   item.next_role_func &&
    //   safeEval(item.next_role_func)(user, template, process, step, item);

    let next_roles = template.steps[process.data.fields.process_step.value]
      ? [...template.steps[process.data.fields.process_step.value].step_roles]
      : [];
    // asynchronous steps executed asap
    let still_required_roles_async = [];
    Object.keys(template.steps).map((key, ind) => {
      let step = template.steps[key];
      if (!step.asynchronous) return true;
      /* if the step have config step_asynchronous and asynchronous. That mean only executed if current step is step_asynchronous
      * We only check step_asynchronous when the step step_asynchronous is not completed. If step_asynchronous completed. That mean user is updating the order.
      ex: Sales or Continuity is updating the order title. Issue: https://tasks.getventive.com/projects/EC097-B41
      */ 
      const stepAsynchronousStatus = step.step_asynchronous ? get(
        process,
        `data.fields.${step.step_asynchronous}_status.value`,
        ""
      ) : null;
      if(step.step_asynchronous && currentStep.key !== step.step_asynchronous && !['completed', 'approved'].includes(stepAsynchronousStatus)) return true;
      let is_hidden = step.is_hidden && step.is_hidden(user, process, step);
      let state = "undone";
      if (get(process, `data.fields.${step.key + "_status"}.value`)) {
        if (process.data.fields[step.key + "_status"].value === "draft")
          state = "draft";
        else if (process.data.fields[step.key + "_status"].value === "rejected")
          state = "rejected";
        else state = "done";
      }
      if (!is_hidden && state !== "done") {
        still_required_roles_async = concat(
          still_required_roles_async,
          get(step, "step_roles", [])
        );
      }
      return key;
    });
    still_required_roles_async.map(e => {
      if (next_roles.indexOf(e) === -1) next_roles.push(e);
      return true;
    });

    let still_required_roles = this.getStillRequiredRoles(
      user,
      template,
      process,
      step,
      item
    );
    // debugger
    let team = [];
    const equal = (arr1, arr2) => {
      if (!arr1) arr1 = [];
      if (!arr2) arr2 = [];
      arr1.length === arr2.length &&
        arr1.every(i => arr2.includes(i)) &&
        arr2.every(i => arr1.includes(i));
    };
    Object.keys(process.data.fields).map(f => {
      if (f.indexOf("role_") === 0) {
        let users = process.data.fields[f].value && process.data.fields[f].value.users;
        if (!users) {
          users = [];
          if (process.data.fields[f].value && process.data.fields[f].value.id) {
            users.push(process.data.fields[f].value)
          }
        }
        users.map(assignee => {
          let user_id = parseInt(assignee.id);
          let user_role_id = parseInt(assignee.role);

          if (team.indexOf(user_id) === -1) team.push(user_id);
          if (
            still_required_roles &&
            still_required_roles.indexOf(user_id) > -1 &&
            still_required_users.indexOf(user_id) === -1
          )
            still_required_users.push(user_id);
          if (
            next_roles &&
            next_roles.indexOf(user_role_id) > -1 &&
            next_users.indexOf(user_id) === -1
          )
            next_users.push(user_id);
          return true;
        });
      }
      return false;
    });

    if(step.recomputeRoles) {
      
      let assignments = step.recomputeRoles(user,template,process,step,item, team,still_required_users,next_users);
      if(assignments){
        team = assignments.team;
        still_required_users = assignments.still_required_users;
        next_users = assignments.next_users;
      }
    }
    const nextUsersFromConfig = item.fields && find(item.fields, (field) => field.key === 'next_users');
    if(nextUsersFromConfig){
      if(typeof nextUsersFromConfig.value === 'function'){
        next_users = nextUsersFromConfig.value(user, template, process, step, item, next_users, extraData)
      }else{
        next_users = nextUsersFromConfig.value
      }
    }
    var changed_still_required_users = [];
    if (
      still_required_roles &&
      (!process.data.fields.still_required_users ||
        process.data.fields.still_required_users.value ||
        !equal(
          still_required_users,
          process.data.fields.still_required_users.value
        ))
    ) {
      if (!process.data.loaded_fields.still_required_users)
        changed_still_required_users = still_required_users;
      else
        changed_still_required_users = [
          ...differenceWith(
            still_required_users,
            get(process, 'data.loaded_fields.still_required_users.value', []),
            isEqual
          ),
          ...differenceWith(
            get(process, 'data.loaded_fields.still_required_users.value', []),
            still_required_users,
            isEqual
          )
        ];
      this.props.formValueChanged({
        process,
        template,
        item: { field: "still_required_users" },
        user,
        still_required_users
      });
      process.data.fields.still_required_users = {
        value: still_required_users,
        is_dirty: true
      };
    }
  var changed_next_users = [];
  // set next users for only digital order from workflows approvers
  if (get(process, "data.fields.template_key.value") === "digital"
  && (!process.data.fields.approve_order_status || (process.data.fields.approve_order_status.value !== 'approved' && process.data.fields.approve_order_status.value !== 'in progress'))
  ) {
    const approvers = this.getApprovers(process);
    next_users = [...next_users, ...approvers.map(item => item.id)];
    next_users = uniq(next_users);
  }
    if (
      next_roles &&
      (!process.data.fields.next_users ||
        !equal(next_users, process.data.fields.next_users.value))
    ) {
      if (!process.data.loaded_fields.next_users)
        changed_next_users = next_users;
      else
        changed_next_users = [
          ...differenceWith(
            next_users,
            process.data.loaded_fields.next_users.value,
            isEqual
          ),
          ...differenceWith(
            process.data.loaded_fields.next_users.value,
            next_users,
            isEqual
          )
        ];
      this.props.formValueChanged({
        process,
        template,
        item: { field: "next_users" },
        user,
        next_users
      });
      process.data.fields.next_users = { value: next_users, is_dirty: true };
    }
    var changed_team = [];
    if (
      team &&
      (!process.data.fields.team ||
        !equal(team, process.data.fields.team.value))
    ) {
      if (!process.data.loaded_fields.team) changed_team = team;
      else
        changed_team = [
          ...differenceWith(
            team,
            process.data.loaded_fields.team.value,
            isEqual
          ),
          ...differenceWith(
            process.data.loaded_fields.team.value,
            team,
            isEqual
          )
        ];
      // Contest or Programming element => We don't implement the next users by role. We need to always keep
      if(['contest', 'liner'].indexOf(get(process, "data.fields.template_key.value")) !== -1 && team.indexOf(user.id) === -1){
        team.push(user.id);
      }
      this.props.formValueChanged({
        process,
        template,
        item: { field: "team" },
        user,
        team
      });
      process.data.fields.team = { value: team, is_dirty: true };
    }

    var all_changed_users = [
      ...changed_still_required_users,
      ...changed_next_users,
      ...changed_team
    ].filter(function (item, i, ar) {
      return ar.indexOf(item) === i;
    });
    this.props.formValueChanged({
      process,
      template,
      item: { field: "all_changed_users" },
      user,
      all_changed_users
    });
    process.data.fields.all_changed_users = {
      value: all_changed_users,
      is_dirty: true
    };
  };

  getStillRequiredRoles = (user, template, process, step, item) => {
    let still_required_roles = [];
    Object.keys(template.steps).map((key, ind) => {
      let step = template.steps[key];
      let is_hidden = step.is_hidden && step.is_hidden(user, process, step);
      let state = "undone";
      if (get(process, `data.fields.${step.key + "_status"}.value`)) {
        if (process.data.fields[step.key + "_status"].value === "draft")
          state = "draft";
        else if (process.data.fields[step.key + "_status"].value === "rejected")
          state = "rejected";
        else state = "done";
      }
      if (!is_hidden && state !== "done") {
        const stepAssignedByRolesValue = get(stepAssignedByRoles, `${template.key}.${get(step, "key")}`);
        if(stepAssignedByRolesValue){
          still_required_roles = [...still_required_roles, ...stepAssignedByRolesValue];
        }
      }
      return key;
    });
    still_required_roles = uniq(still_required_roles);
    let still_required = [];
    forEach(still_required_roles, roleKey => {
      let users = process.data.fields[roleKey] && process.data.fields[roleKey].value && process.data.fields[roleKey].value.users;
      if (!users) {
        users = [];
        if (process.data.fields[roleKey] && process.data.fields[roleKey].value && process.data.fields[roleKey].value.id) {
          users.push(process.data.fields[roleKey].value)
        }
      }
      users = map(users, user => Number(user.id));
      still_required = [...still_required, ...users];
    });
    return uniq(still_required);
  };

  // firstUndoneStep = (user, template, process, step, item) => {
  //   let firstUndone = "done";
  //   Object.keys(template.steps).map((key, ind) => {
  //     let step = template.steps[key];
  //     // let is_disabled =
  //     //   step.is_disabled &&
  //     //   safeEval(step.is_disabled)(user, process, step);
  //     let is_hidden = step.is_hidden && step.is_hidden(user, process, step);
  //     let state = "undone";
  //     if (get(process, `data.fields.${step.key + "_status"}.value`)) {
  //       if (process.data.fields[step.key + "_status"].value === "draft")
  //         state = "draft";
  //       else if (process.data.fields[step.key + "_status"].value === "rejected")
  //         state = "rejected";
  //       else state = "done";
  //     }
  //     if (
  //       !is_hidden &&
  //       firstUndone === "done" &&
  //       state !== "done" &&
  //       step.key !== "log"
  //     )
  //       firstUndone = step.key;
  //     return key;
  //   });
  //   return firstUndone;
  // };

  /**
   * Helps to decide if the confirmation dialog is to be shown or skipped on a step submit
   */
  skipConfirmation = (step, process) => {
    if (step.key === "voice_over") {
      const voiceTracks = get(process, "data.fields.voice_tracks.value");
      const recordingWithProducer = find(voiceTracks, (item) => item.is_recording_with_producer);
      return recordingWithProducer;
    }
    return false;
  };

  buttonClicked = (
    step,
    item,
    process,
    template,
    user,
    event,
    callback,
    isForce = false,
    noSuccessMessage = false,
    actionAfterValidValidate = null,
  ) => {
    // clear old errors
    this.props.clearAllValidationError();
    const extraData = pick(this.props.info, ['enable_digital', 'production_multimarket', 'enable_production', 'enable_promotion', 'enable_wo', 'roles_string'])
    if (item.on_click) {
      let valid = true;
      // ignore validate fields
      const isIgnoreValidate = item.is_ignore_validate || false;
      if (item.on_click.validate && !isIgnoreValidate) {
        try {
          valid = this.props.validate(step, item, process, template, user);
        } catch (e) {
          if (!e.item) debugger;
          // When user is added to rotation => Need to prevent validate and save the order as draft
          if (item.draft && !get(item, 'add_to_rotation', false)) {
            valid = true;
          } else {
            ToastManager.show({
              title: this.props.intl.formatMessage({
                id: "toast > title not saved"
              }),
              message: e.message,
              level: "error"
            });
            if (document.location.href.indexOf("#testing") === -1) return;
            valid = true;
          }
        }
      }

      if (valid) {

        if (actionAfterValidValidate) {
          if (actionAfterValidValidate()) return;
        }

        let showWarning = get(item, "warning_dialog_props.show");

         if (
          item.warning_dialog_props &&
          !this.state.warningDialogProps &&
          !item.draft &&
          showWarning(process)
        ) {
          this.setState({
            warningDialogProps: {
              ...item.warning_dialog_props,
              onConfirm: () => {
                this.setState({ warningDialogProps: null });
              },
            }
          });
          return;
         }
        if (
          item.confirmation_dialog_props &&
          !this.props.confirmationDialogProps &&
          !item.draft &&
          !this.skipConfirmation(step, process)
        ) {
          if (!isForce) {
            this.props.setConfirmationDialogProps({
              ...item.confirmation_dialog_props,
              onConfirm: () => {
                if(get(item, 'confirmation_dialog_props.onConfirm')){
                  item.confirmation_dialog_props.onConfirm(this, step, item, process, template, user);
                }else{
                  this.buttonClicked(step, item, process, template, user, {});
                }
              },
              onCancel: () => {
                if(get(item, 'confirmation_dialog_props.onCancel')){
                  item.confirmation_dialog_props.onCancel(this, step, item, process, template, user);
                }else{
                  this.props.setConfirmationDialogProps(null)
                }
              }
            });
            return;
          }
        }
      }

      if (item.on_click.go_to_step && valid) {
        this.props.tab_toggle(item.on_click.go_to_step);
      }

      if(item.on_click.updateFormValue){

          get(item, "fields", []).map(field => {

            let value = get(field, "value", null);
            
            if(isFunction(value)){
              value = value(user, template, process, step, item);
            }

            this.props.formValueChanged({
              process,
              template,
              item: { field: field.key },
              user,
              value
            });

            process.data.fields[field.key] = { value: value, is_dirty: true };

            return false;
          });
      }

      if (item.on_click.save) {
        // add the step status
        // if (!process.data.fields[step.key + "_status"]) {
        this.props.formValueChanged({
          process,
          template,
          item: { field: step.key + "_status" },
          user,
          value: valid && !item.draft ? "completed" : "draft"
        });
        process.data.fields[step.key + "_status"] = {
          value: valid && !item.draft ? "completed" : "draft",
          is_dirty: true
        };
        // }
        this.setState({
          buttonClicking: valid && !item.draft ? "completed" : "draft"
        });
        if (
          item.fields &&
          !item.draft // && valid
        ) {
          item.fields.map(field => {
            if (typeof field.value === 'undefined') {
              return false;
            }
            let value = field.value;
            // if (value.indexOf && typeof value === "function")
            if(typeof value === "function")
              value = field.value(user, template, process, step, item, get(process, 'data.fields.next_users.value', []), extraData);
            if (field.key === "process_step" && value === "first_undone")
              value = firstUndoneStep(user, template, process, step, item);
            this.props.formValueChanged({
              process,
              template,
              item: { field: field.key },
              user,
              value
            });
            process.data.fields[field.key] = { value: value, is_dirty: true };
            return false;
          });
        }
        // assign process step to markets
        if(item.recomputeFields){
          process = item.recomputeFields(user, template, process, step, {
            ...extraData,
            formValueChanged: this.props.formValueChanged
          })
        }

        if (
          !process.data.fields.next_users ||
          !process.data.fields.next_users.value
        )
          process.data.fields.next_users = { value: [] };

        if (valid && !item.draft) {
          this.recomputeRoles(step, item, process, template, user);
        } else if (
          !process.data.fields.next_users ||
          !process.data.fields.next_users.value.includes(user.id)
        ) {
          // draft saved, current user must be in the next users list
          let next_users = process.data.fields.next_users
            ? [...process.data.fields.next_users.value, user.id]
            : [user.id];
          this.props.formValueChanged({
            process,
            template,
            item: { field: "next_users" },
            user,
            next_users
          });
          process.data.fields.next_users = {
            value: next_users,
            is_dirty: true
          };
        }
        let success_message =
          valid && !item.draft
            ? ToastManager.show.bind(this, {
              title: this.props.intl.formatMessage({
                id: "toast > title saved"
              }),
              message: this.props.intl.formatMessage({
                id: "toast > message all good"
              }),
              level: "info"
            })
            : ToastManager.show.bind(this, {
              title: this.props.intl.formatMessage({
                id: "toast > title draft saved"
              }),
              message: valid
                ? ""
                : this.props.intl.formatMessage({
                  id: "toast > message please make the required corrections"
                }),
              level: "warning"
            });
        if (item.success_dialog_props) {

          let _successDialogProps = item.success_dialog_props;
          let successDialogProps = isFunction(_successDialogProps) ? _successDialogProps(user, template, process, step, item) : _successDialogProps;

          success_message = () => {
            this.props.setConfirmationDialogProps({
              ...(!valid
                ? {
                  title: this.props.intl.formatMessage({
                    id: "toast > title saved as draft"
                  }),
                  state: "success",
                  centered: true
                }
                : item.draft
                  ? {
                    title: this.props.intl.formatMessage({
                      id: "toast > title saved"
                    }),
                    state: "success",
                    centered: true
                  }
                  : successDialogProps),
              onCancel: () => {
                this.props.setConfirmationDialogProps(null);
              },
              onToggle: () => {
                this.props.setConfirmationDialogProps(null);
              }
            })
          };
        }
        if(noSuccessMessage) success_message = () => {}
        let success_callback = response => {
          this.setState({
            buttonClicking: null,
            originalScript: false,
            originalScriptPartB: false,
          });
          if (response.data.alert_contract_missing_spots)
            this.alertContractMissingSpots(response.data);
          success_message();
          this.props.clearAllValidationError();
          const stepKeysKeepEditMode = ['dub_script', 'traffic'];
          const stepKeysKeepViewMode = ['order_details'];

          let processKey = response.data.key;
          // check for current process exists in the spot info for production needed remove spot
          if(response.data.spots){
            let existsProcessKey = findIndex(response.data.spots, item => item === response.data.key);
            if(existsProcessKey === -1){
              processKey = response.data.spots[0]; // redirect to first spot
            }
          }
          if(item.on_click.go_to && valid && !item.draft){
            item.on_click.go_to(this, step, item, process, template, user, extraData);
          }else{
            const privileges = get(user, "privileges", []);
            let isForceEdit = false;
            // Make the order open up in EDIT mode when clicked on, if the User has the EDIT privileges . https://tasks.getventive.com/projects/D6CB3-068
            if (["liner", "contest"].includes(template.key) /*|| (step.key === 'order_details' && ["production_provided", "basic_production"].includes(template.key))*/) {
              const isUserHasEditPrivileges = validatePrivileges({
                requires: step.step_editors_privileges,
                privileges,
                user
              });
              if (isUserHasEditPrivileges) {
                isForceEdit = true;
              }
            }
            if(isForceEdit){
              history.replace(`/processes/edit/${template.key}/${processKey}`);
              return;
            }
            /**
             * 1. Old code
             * 2. saved as a draft, all orders should open in View mode on the Order Entry tab
             */
           const isSwitchToView =
            (valid &&
              !item.draft &&
              document.location.href.indexOf("/processes/view/") === -1 &&
              !includes(stepKeysKeepEditMode, get(step, "key"))) ||
            (includes(stepKeysKeepViewMode, get(step, "key")) &&
              item.draft &&
              document.location.href.indexOf("/processes/view/") === -1);

            if (isSwitchToView) {
              history.replace(`/processes/view/${template.key}/${processKey}`);
            } else if (
              item.draft &&
              document.location.href.indexOf("/processes/edit/") === -1
            ) {
              history.replace(`/processes/edit/${template.key}/${processKey}`);
            }
          }
        }; 
        if (callback) success_callback = callback;
        //this.props.formValueChanged.bind(this, { process, template, item, user, value: "saved" })
        let saveFormValues = {
          process,
          template,
          step,
          user,
          success_callback,
          valid: valid && !item.draft,
          isForce
        }

        if(item.on_click.includeChatMessage){

          const spot = find(
            get(process, "data.fields.spot_info.value", []),
            (spot) => spot.key === process.key
          );

          let isBookend = get(spot, "spot_type", "Standard") === "Book-ends";

          let recordingPath = get(process, `data.fields.recording_path.value`, "") ? get(process, `data.fields.recording_path.value`, "") : get(process, "data.fields.recording_feedback.value.recording_path", "");

          let lastFeedback = find(get(process,"data.fields.messages.value", []), message => {
            return get(message, "_source.recording_path") === recordingPath && !get(message, "_source.is_recording_path_b", false);
          })

          if(get(lastFeedback,"_source.chat", "") !== get(process,"data.fields.recording_feedback.value.feedback", "")){
              const chat_message = this.create_message(
              get(process,"data.fields.recording_feedback.value.feedback", ""),
              get(process,"data.fields.recording_feedback.value.include_timestamp", false),
              true,
              process,
              template,
              step,
              user
            );

            saveFormValues.chat_message = chat_message;
            saveFormValues.recordingFeedback = true;
          }

          if(isBookend){
            let recordingPathB = get(process, `data.fields.recording_path_b.value`, "") ? get(process, `data.fields.recording_path_b.value`, "") : get(process, "data.fields.recording_feedback.value.recording_path_b", "");

            let lastFeedbackB = find(get(process,"data.fields.messages.value", []), message => {
              return get(message, "_source.recording_path") === recordingPathB && get(message, "_source.is_recording_path_b", false);
            })

            if(get(lastFeedbackB,"_source.chat", "") !== get(process,"data.fields.recording_feedback.value.recording_path_b_feedback", "")){
                const chat_message_2 = this.create_message(
                get(process,"data.fields.recording_feedback.value.recording_path_b_feedback", ""),
                get(process,"data.fields.recording_feedback.value.include_recording_path_b_timestamp", false),
                true,
                process,
                template,
                step,
                user,
                true
              );

              saveFormValues.chat_message_2 = chat_message_2;
              saveFormValues.recordingFeedback = true;
            }
          }
        }



        // Add variable for server check change this order to draft
        if(item.add_rotation){
          saveFormValues.meta = {
            add_rotation: true
          }
        }
        // add params for server check send email + notification in app to sales. When CM adds Placement information.
        if(item.notification){
          saveFormValues.meta = {
            ...get(saveFormValues, 'meta', {}),
            notification: item.notification // {users: [1], type: 'cm_placement_information', is_send_mail: true/false, is_send_notification: true/false}
          }
        }
        // add params for server to send email + next task to users
        if(item.notify_all_spots){
          saveFormValues.meta = {
            ...get(saveFormValues, 'meta', {}),
            notify_all_spots: true
          }
        }

        if(item.move_next_task){
          saveFormValues.meta = {
            ...get(saveFormValues, 'meta', {}),
            move_next_task: true
          }
        }

        this.props.saveFormValues(saveFormValues);

        if (valid){
          this.forceUpdate();
        } 

        this.setState({
          editScript: false,
          refreshField: uuidv4(),
          editRecording: false,
          editRecordingPartB: false,
        });
        this.props.setConfirmationDialogProps(null);
      }
    }
  };

  getTaggedUserIds (text = '') {
    let arr = text && text.match(/(@\[.*?\]\(.*?\))/g);
    let idArr = [];
    if (arr) {
      for (let index = 0; index < arr.length; index++) {
        const item = arr[index]; // @[Sarju](1)
        const endSquareBracketPos = item.indexOf("]");
        const userId = item
          .substr(endSquareBracketPos + 1)
          .replace("(", "")
          .replace(")", "");
        const userIdInt = Number(userId);
        idArr.push(userIdInt);
      }
    }
    return idArr;
  };
  create_message(text, includeTimeStamp, recordingFeedback,  process, template, step, user, isPathB = false){
    
    if (process) {
      let message = {
        from: {
          id: user.id,
          name: user.name,
          avatar_url: user.avatar_url
        },
        tagged: this.getTaggedUserIds(text),
        order_stations: process.data.fields.order_stations ? process.data.fields.order_stations.value : [],
        chat: text,
        m_type: recordingFeedback ? 'recording_feedback' : 'chat',
        template: template.key,
        process: process.key,
        order_title: process.data.fields.order_title
          ? process.data.fields.order_title.value
          : "untitled",
        from_step: step ? step.key : "",
        team: process.data.fields.team ? process.data.fields.team.value : [],
        still_required_users: process.data.fields.still_required_users
          ? process.data.fields.still_required_users.value
          : [],
        next_users: process.data.fields.next_users
          ? process.data.fields.next_users.value
          : []
      };
      if (user.impersonated_by){
        message.from.impersonated_by = user.impersonated_by;
      }

      if(recordingFeedback){
        message.recording_path = get(process, `data.fields.recording_path.value`, "") ? get(process, `data.fields.recording_path.value`, "") : get(process, "data.fields.recording_feedback.value.recording_path", "");

        if (includeTimeStamp) {
          message.include_timestamp = includeTimeStamp;
          
          let playerPlayedSeconds = get(window, "playerPlayedSeconds", 0);

          // message.player_played_seconds = (playerPlayedSeconds - 3) > 0 ? (playerPlayedSeconds - 3) : 0;
          message.player_played_seconds = playerPlayedSeconds;
        }
      }

      if(recordingFeedback && isPathB){
        message.recording_path = get(process, `data.fields.recording_path_b.value`, "") ? get(process, `data.fields.recording_path_b.value`, "") : get(process, "data.fields.recording_feedback.value.recording_path_b", "");
        message.is_recording_path_b = true;

        if (includeTimeStamp) {
          message.include_timestamp = includeTimeStamp;
          
          let playerPlayedSeconds = get(window, "playerBPlayedSeconds", 0);

          // message.player_played_seconds = (playerPlayedSeconds - 3) > 0 ? (playerPlayedSeconds - 3) : 0;
          message.player_played_seconds = playerPlayedSeconds
        }
      }

      return message;
    }
  };

  alertContractMissingSpots(response_data) {
    let stations = response_data.alert_contract_missing_spots;
    this.setState({ stationsWithoutSpots: stations });
  }

  filesUploaded = (item, process, template, user, event) => {
    let value = [
      ...(process.data.fields[item.field]
        ? process.data.fields[item.field].value
        : []),
      ...event
    ];
    this.props.clearValidationError(item);
    this.props.formValueChanged({ process, template, item, user, value });
  };

  fileUploaded = (item, process, template, user, event) => {
    this.props.clearValidationError(item);
    this.props.formValueChanged({
      process,
      template,
      item,
      user,
      value: event.path || event
    });
  };

  fileDeleted = (item, process, template, user, event) => {
    this.props.clearValidationError(item);
    this.props.formValueChanged({ process, template, item, user, value: "" });
    this.props.setShouldBlockNavigation(true);
  };

  fileNewDeleted = (item, process, template, user, event) => {

    if(item.field === 'recording_path_b'){
      this.setState({
        editRecordingPathB: true,
        newRecordingPathB: ""
      });
    }else{
      this.setState({
        editRecording: true,
        newRecording: ""
      });
    }
    
    this.props.clearValidationError(item);
    this.props.formValueChanged({
      process,
      template,
      item,
      user,
      value: this.state.prevRecording
    });
  };
  filesDeleted = (item, process, template, user, event) => {
    this.props.clearValidationError(item);
    this.props.formValueChanged({
      process,
      template,
      item,
      user,
      value: event
    });
  };

  getApprovers = (process) => {
  let approvals = [];
  const marketingChannels = get(process, 'data.fields.marketing_channels.value', []);
  marketingChannels.forEach(channel => {
    const { workflow } = channel;
    if (workflow && workflow.steps)
      workflow.steps.filter(item => item.approvals).map(item => item.approvals).forEach(item => {
        approvals = [
          ...approvals,
          ...item
        ];
      });
  });
  let approvers = [];
  approvals.forEach(item => {
    approvers = [...approvers, ...item.users];
  });
  approvers = uniqBy(approvers, "id");
  return approvers;
  }
  factory = (step, item, process, template, user, info) => {
    const { editScript, refreshField } = this.state;
    const isView = document.location.pathname.split("/")[2] === "view";
    switch (item.component) {
      case "promotion_request_details":
        return (
          <PromotionRequestDetails
            valueChanged={this.valueChanged}
            step={step}
            process={process}
            template={template}
            user={user}
            validationState={this.props.validationState}
            buttonClicked={this.buttonClicked}
            fileUploaded={this.fileUploaded}
            filesUploaded={this.filesUploaded}
            filesDeleted={this.filesDeleted}
            fileDeleted={this.fileDeleted}
            checkboxGroupChanged={this.checkboxGroupChanged}
            checkboxChanged={this.checkboxChanged}
            spotsChanged={this.spotsChanged}
            valueEvaluatorChanged={this.valueEvaluatorChanged}
            getRoleCandidates={this.getRoleCandidates.bind(this)}
            multipleStationSelectionChanged={
              this.multipleStationSelectionChanged
            }
            stationsWithoutSpots={this.state.stationsWithoutSpots}
            shouldBlockNavigation={this.props.shouldBlockNavigation}
            setShouldBlockNavigation={this.props.setShouldBlockNavigation}
            onPreparePage={this.props.onPreparePage}
            info={this.props.info}
            {...(isView && { mode: "view" })}
            {...item.props}
          />
        );
      case "promotion_details":
        return (
          <PromotionDetails
            valueChanged={this.valueChanged}
            step={step}
            process={process}
            template={template}
            user={user}
            validationState={this.props.validationState}
            buttonClicked={this.buttonClicked}
            fileUploaded={this.fileUploaded}
            filesUploaded={this.filesUploaded}
            filesDeleted={this.filesDeleted}
            fileDeleted={this.fileDeleted}
            checkboxGroupChanged={this.checkboxGroupChanged}
            checkboxChanged={this.checkboxChanged}
            spotsChanged={this.spotsChanged}
            valueEvaluatorChanged={this.valueEvaluatorChanged}
            getRoleCandidates={this.getRoleCandidates.bind(this)}
            multipleStationSelectionChanged={
              this.multipleStationSelectionChanged
            }
            stationsWithoutSpots={this.state.stationsWithoutSpots}
            shouldBlockNavigation={this.props.shouldBlockNavigation}
            setShouldBlockNavigation={this.props.setShouldBlockNavigation}
            onPreparePage={this.props.onPreparePage}
            info={this.props.info}
            {...(isView && { mode: "view" })}
            {...item.props}
          />
        );
      case "promotion_event_details":
        return (
          <PromotionEventDetails
            valueChanged={this.valueChanged}
            step={step}
            process={process}
            template={template}
            user={user}
            validationState={this.props.validationState}
            buttonClicked={this.buttonClicked}
            checkboxGroupChanged={this.checkboxGroupChanged}
            checkboxChanged={this.checkboxChanged}
            spotsChanged={this.spotsChanged}
            valueEvaluatorChanged={this.valueEvaluatorChanged}
            getRoleCandidates={this.getRoleCandidates.bind(this)}
            multipleStationSelectionChanged={
              this.multipleStationSelectionChanged
            }
            stationsWithoutSpots={this.state.stationsWithoutSpots}
            shouldBlockNavigation={this.props.shouldBlockNavigation}
            setShouldBlockNavigation={this.props.setShouldBlockNavigation}
            onPreparePage={this.props.onPreparePage}
            info={this.props.info}
            {...(isView && { mode: "view" })}
            {...item.props}
          />
        );
      case "promotion_schedule":
        return (
          <PromotionSchedule
            valueChanged={this.valueChanged}
            step={step}
            process={process}
            template={template}
            user={user}
            validationState={this.props.validationState}
            buttonClicked={this.buttonClicked}
            info={this.props.info}
            validate={this.props.validate}
            isShowClearForm={this.props.isShowClearForm}
            setIsShowClearForm={this.props.setIsShowClearForm}
            shouldBlockNavigation={this.props.shouldBlockNavigation}
            setShouldBlockNavigation={this.props.setShouldBlockNavigation}
            onPreparePage={this.props.onPreparePage}
            {...(isView && { mode: "view" })}
            {...item.props}
          />
        );
      case "promotion_liner_client":
        return (
          <PromotionLinerClient
            valueChanged={this.valueChanged}
            step={step}
            process={process}
            template={template}
            user={user}
            validationState={this.props.validationState}
            buttonClicked={this.buttonClicked}
            // fileUploaded={this.fileUploaded}
            // filesUploaded={this.filesUploaded}
            // filesDeleted={this.filesDeleted}
            // fileDeleted={this.fileDeleted}
            // checkboxGroupChanged={this.checkboxGroupChanged}
            // checkboxChanged={this.checkboxChanged}
            // spotsChanged={this.spotsChanged}
            // valueEvaluatorChanged={this.valueEvaluatorChanged}
            // getRoleCandidates={this.getRoleCandidates.bind(this)}
            // multipleStationSelectionChanged={
            //   this.multipleStationSelectionChanged
            // }
            // stationsWithoutSpots={this.state.stationsWithoutSpots}
            info={this.props.info}
            validate={this.props.validate}
            isShowClearForm={this.props.isShowClearForm}
            setIsShowClearForm={this.props.setIsShowClearForm}
            shouldBlockNavigation={this.props.shouldBlockNavigation}
            setShouldBlockNavigation={this.props.setShouldBlockNavigation}
            onPreparePage={this.props.onPreparePage}
            {...(isView && { mode: "view" })}
            {...item.props}
          />
        );
      case "promotion_liner_details":
        return (
          <PromotionLinerDetails
            valueChanged={this.valueChanged}
            step={step}
            process={process}
            template={template}
            user={user}
            validationState={this.props.validationState}
            buttonClicked={this.buttonClicked}
            fileUploaded={this.fileUploaded}
            filesUploaded={this.filesUploaded}
            filesDeleted={this.filesDeleted}
            fileDeleted={this.fileDeleted}
            checkboxGroupChanged={this.checkboxGroupChanged}
            checkboxChanged={this.checkboxChanged}
            spotsChanged={this.spotsChanged}
            valueEvaluatorChanged={this.valueEvaluatorChanged}
            getRoleCandidates={this.getRoleCandidates.bind(this)}
            multipleStationSelectionChanged={
              this.multipleStationSelectionChanged
            }
            stationsWithoutSpots={this.state.stationsWithoutSpots}
            info={this.props.info}
            validate={this.props.validate}
            isShowClearForm={this.props.isShowClearForm}
            setIsShowClearForm={this.props.setIsShowClearForm}
            shouldBlockNavigation={this.props.shouldBlockNavigation}
            setShouldBlockNavigation={this.props.setShouldBlockNavigation}
            onPreparePage={this.props.onPreparePage}
            setValidationError={this.props.setValidationError}
            {...(isView && { mode: "view" })}
            {...item.props}
          />
        );
      case "promotion_liner_scripts": 
        return (
          <PromotionLinerScripts
            valueChanged={this.valueChanged}
            step={step}
            process={process}
            template={template}
            user={user}
            validationState={this.props.validationState}
            buttonClicked={this.buttonClicked}
            setValidationError={this.props.setValidationError}
            info={this.props.info}
            validate={this.props.validate}
            isShowClearForm={this.props.isShowClearForm}
            setIsShowClearForm={this.props.setIsShowClearForm}
            shouldBlockNavigation={this.props.shouldBlockNavigation}
            setShouldBlockNavigation={this.props.setShouldBlockNavigation}
            onPreparePage={this.props.onPreparePage}
            {...(isView && { mode: "view" })}
            {...item.props}
          />
        );
      case "promotion_contest_details":
        return (
          <PromotionContestDetails
            valueChanged={this.valueChanged}
            step={step}
            process={process}
            template={template}
            user={user}
            validationState={this.props.validationState}
            buttonClicked={this.buttonClicked}
            fileUploaded={this.fileUploaded}
            filesUploaded={this.filesUploaded}
            filesDeleted={this.filesDeleted}
            fileDeleted={this.fileDeleted}
            checkboxGroupChanged={this.checkboxGroupChanged}
            checkboxChanged={this.checkboxChanged}
            spotsChanged={this.spotsChanged}
            valueEvaluatorChanged={this.valueEvaluatorChanged}
            getRoleCandidates={this.getRoleCandidates.bind(this)}
            multipleStationSelectionChanged={
              this.multipleStationSelectionChanged
            }
            stationsWithoutSpots={this.state.stationsWithoutSpots}
            info={this.props.info}
            validate={this.props.validate}
            isShowClearForm={this.props.isShowClearForm}
            setIsShowClearForm={this.props.setIsShowClearForm}
            shouldBlockNavigation={this.props.shouldBlockNavigation}
            setShouldBlockNavigation={this.props.setShouldBlockNavigation}
            onPreparePage={this.props.onPreparePage}
            setValidationError={this.props.setValidationError}
            {...(isView && { mode: "view" })}
            {...item.props}
          />
        );
      case "promotion_contest_prizes":
        return (
          <PromotionContestPrizes
            valueChanged={this.valueChanged}
            step={step}
            process={process}
            template={template}
            user={user}
            validationState={this.props.validationState}
            buttonClicked={this.buttonClicked}
            fileUploaded={this.fileUploaded}
            filesUploaded={this.filesUploaded}
            filesDeleted={this.filesDeleted}
            fileDeleted={this.fileDeleted}
            checkboxGroupChanged={this.checkboxGroupChanged}
            checkboxChanged={this.checkboxChanged}
            spotsChanged={this.spotsChanged}
            valueEvaluatorChanged={this.valueEvaluatorChanged}
            getRoleCandidates={this.getRoleCandidates.bind(this)}
            multipleStationSelectionChanged={
              this.multipleStationSelectionChanged
            }
            stationsWithoutSpots={this.state.stationsWithoutSpots}
            info={this.props.info}
            validate={this.props.validate}
            isShowClearForm={this.props.isShowClearForm}
            setIsShowClearForm={this.props.setIsShowClearForm}
            shouldBlockNavigation={this.props.shouldBlockNavigation}
            setShouldBlockNavigation={this.props.setShouldBlockNavigation}
            onPreparePage={this.props.onPreparePage}
            setValidationError={this.props.setValidationError}
            {...(isView && { mode: "view" })}
            {...item.props}
          />
        );
      case "promotion_contest_winners":
        return (
          <PromotionContestWinners
            valueChanged={this.valueChanged}
            step={step}
            process={process}
            template={template}
            user={user}
            validationState={this.props.validationState}
            buttonClicked={this.buttonClicked}
            fileUploaded={this.fileUploaded}
            filesUploaded={this.filesUploaded}
            filesDeleted={this.filesDeleted}
            fileDeleted={this.fileDeleted}
            checkboxGroupChanged={this.checkboxGroupChanged}
            checkboxChanged={this.checkboxChanged}
            spotsChanged={this.spotsChanged}
            valueEvaluatorChanged={this.valueEvaluatorChanged}
            getRoleCandidates={this.getRoleCandidates.bind(this)}
            multipleStationSelectionChanged={
              this.multipleStationSelectionChanged
            }
            stationsWithoutSpots={this.state.stationsWithoutSpots}
            info={this.props.info}
            validate={this.props.validate}
            isShowClearForm={this.props.isShowClearForm}
            setIsShowClearForm={this.props.setIsShowClearForm}
            shouldBlockNavigation={this.props.shouldBlockNavigation}
            setShouldBlockNavigation={this.props.setShouldBlockNavigation}
            onPreparePage={this.props.onPreparePage}
            {...(isView && { mode: "view" })}
            {...item.props}
          />
        );
      case "prizes":
        return (
          <PrizesDetails
            valueChanged={this.valueChanged}
            step={step}
            process={process}
            template={template}
            user={user}
            validationState={this.props.validationState}
            buttonClicked={this.buttonClicked}
            fileUploaded={this.fileUploaded}
            filesUploaded={this.filesUploaded}
            filesDeleted={this.filesDeleted}
            fileDeleted={this.fileDeleted}
            checkboxGroupChanged={this.checkboxGroupChanged}
            checkboxChanged={this.checkboxChanged}
            spotsChanged={this.spotsChanged}
            valueEvaluatorChanged={this.valueEvaluatorChanged}
            getRoleCandidates={this.getRoleCandidates.bind(this)}
            multipleStationSelectionChanged={
              this.multipleStationSelectionChanged
            }
            stationsWithoutSpots={this.state.stationsWithoutSpots}
            info={this.props.info}
            {...(isView && { mode: "view" })}
            {...item.props}
          />
        );
      case "order_details":
        return (
          <OrderDetails
            valueChanged={this.valueChanged}
            step={step}
            process={process}
            template={template}
            user={user}
            validationState={this.props.validationState}
            buttonClicked={this.buttonClicked}
            fileUploaded={this.fileUploaded}
            filesUploaded={this.filesUploaded}
            filesDeleted={this.filesDeleted}
            fileDeleted={this.fileDeleted}
            checkboxGroupChanged={this.checkboxGroupChanged}
            checkboxChanged={this.checkboxChanged}
            spotsChanged={this.spotsChanged}
            valueEvaluatorChanged={this.valueEvaluatorChanged}
            getRoleCandidates={this.getRoleCandidates.bind(this)}
            multipleStationSelectionChanged={
              this.multipleStationSelectionChanged
            }
            stationsWithoutSpots={this.state.stationsWithoutSpots}
            info={this.props.info}
            validate={this.props.validate}
            isShowClearForm={this.props.isShowClearForm}
            setIsShowClearForm={this.props.setIsShowClearForm}
            shouldBlockNavigation={this.props.shouldBlockNavigation}
            setShouldBlockNavigation={this.props.setShouldBlockNavigation}
            onPreparePage={this.props.onPreparePage}
            {...(isView && { mode: "view" })}
            {...item.props}
          />
    );
    case "traffic_details":
      return (
      <TrafficDetails
        valueChanged={this.valueChanged}
        step={step}
        process={process}
        template={template}
        user={user}
        validationState={this.props.validationState}
        buttonClicked={this.buttonClicked}
        fileUploaded={this.fileUploaded}
        filesUploaded={this.filesUploaded}
        filesDeleted={this.filesDeleted}
        fileDeleted={this.fileDeleted}
        checkboxGroupChanged={this.checkboxGroupChanged}
        checkboxChanged={this.checkboxChanged}
        spotsChanged={this.spotsChanged}
        valueEvaluatorChanged={this.valueEvaluatorChanged}
        getRoleCandidates={this.getRoleCandidates.bind(this)}
        multipleStationSelectionChanged={
          this.multipleStationSelectionChanged
        }
        stationsWithoutSpots={this.state.stationsWithoutSpots}
        info={this.props.info}
        validate={this.props.validate}
        {...(isView && { mode: "view" })}
        {...item.props}
      />
    );
    case "digital_report":
      return (
        <DigitalReport
          valueChanged={this.valueChanged}
          step={step}
          process={process}
          template={template}
          user={user}
          validationState={this.props.validationState}
          buttonClicked={this.buttonClicked}
          info={this.props.info}
          validate={this.props.validate}
          {...(isView && { mode: "view" })}
          {...item.props}
        />
      );
    case "digital_order_details":
      return (
        <DigitalOrderDetails
        valueChanged={this.valueChanged}
        step={step}
        process={process}
        template={template}
        user={user}
        validationState={this.props.validationState}
        buttonClicked={this.buttonClicked}
        fileUploaded={this.fileUploaded}
        filesUploaded={this.filesUploaded}
        filesDeleted={this.filesDeleted}
        fileDeleted={this.fileDeleted}
        checkboxGroupChanged={this.checkboxGroupChanged}
        checkboxChanged={this.checkboxChanged}
        spotsChanged={this.spotsChanged}
        valueEvaluatorChanged={this.valueEvaluatorChanged}
        getRoleCandidates={this.getRoleCandidates.bind(this)}
        multipleStationSelectionChanged={
          this.multipleStationSelectionChanged
        }
        stationsWithoutSpots={this.state.stationsWithoutSpots}
        info={this.props.info}
        validate={this.props.validate}
        onPreparePage={this.props.onPreparePage}
        setShouldBlockNavigation={this.props.setShouldBlockNavigation}
        {...(isView && { mode: "view" })}
        {...item.props}
      />
      );
    case "digital_approval":
      return (
        <DigitalApproval
          step={step}
          process={process}
          template={template}
          isView={false}
          user={user}
          buttonClicked={this.buttonClicked}
          valueChanged={this.valueChanged}
      />);    
    case "review_media":
      return (
        <ReviewMedia
          step={step}
          process={process}
          template={template}
          user={user}
          buttonClicked={this.buttonClicked}
          isView={isView}
          valueChanged={this.valueChanged}
        />
      )
    case "traffic": 
      return <Traffic
      valueChanged={this.valueChanged}
      step={step}
      process={process}
      template={template}
      user={user}
      info={this.props.info}
      validate={this.props.validate}
      buttonClicked={this.buttonClicked}
      validationState={this.props.validationState}
      setShouldBlockNavigation={this.props.setShouldBlockNavigation}
      setBlockNavigationAction={this.props.setBlockNavigationAction}
      {...(isView && { mode: "view" })}
      {...item.props}
      />
    case "design":
      return (
        <Design
          valueChanged={this.valueChanged}
          step={step}
          process={process}
          template={template}
          user={user}
          validationState={this.props.validationState}
          buttonClicked={this.buttonClicked}
          info={this.props.info}
          validate={this.props.validate}
          isView={isView}
          setShouldBlockNavigation={this.props.setShouldBlockNavigation}
          {...item.props}
        />
      );
    case "media":
      return (
        <Media
          valueChanged={this.valueChanged}
          step={step}
          process={process}
          template={template}
          user={user}
          validationState={this.props.validationState}
          buttonClicked={this.buttonClicked}
          info={this.props.info}
          validate={this.props.validate}
          isView={isView}
          {...item.props}
        />
      );

      case "team_equipment":
        return (
          <TeamEquipment
            valueChanged={this.valueChanged}
            step={step}
            process={process}
            template={template}
            user={user}
            validationState={this.props.validationState}
            buttonClicked={this.buttonClicked}
            checkboxGroupChanged={this.checkboxGroupChanged}
            checkboxChanged={this.checkboxChanged}
            spotsChanged={this.spotsChanged}
            valueEvaluatorChanged={this.valueEvaluatorChanged}
            getRoleCandidates={this.getRoleCandidates.bind(this)}
            stationsWithoutSpots={this.state.stationsWithoutSpots}
            info={this.props.info}
            {...(isView && { mode: "view" })}
            {...item.props}
          />
        );
      case "send_mail":
        return (
          !item.is_hidden(user, process, step, isView) && (
            <SendMailDialog
              {...item.props}
              step={step}
              process={process}
              template={template}
              user={user}
              buttonClicked={this.buttonClicked}
              validate={this.props.validate}
            />
          )
        );
      case "select_team":
        return (
          <SelectTeam
            {...item.props}
            valueChanged={this.valueChanged}
            step={step}
            process={process}
            template={template}
            user={user}
            validationState={this.props.validationState}
            buttonClicked={this.buttonClicked}
            getRoleCandidates={this.getRoleCandidates.bind(this)}
            info={this.props.info}
            setShouldBlockNavigation={this.props.setShouldBlockNavigation}
            {...(isView && { mode: "view" })}
          />
        );
      case "select_team_vo":
        return (
          <SelectTeamVo
            {...item.props}
            valueChanged={this.valueChanged}
            step={step}
            process={process}
            template={template}
            user={user}
            validationState={this.props.validationState}
            buttonClicked={this.buttonClicked}
            getRoleCandidates={this.getRoleCandidates.bind(this)}
            info={this.props.info}
            setShouldBlockNavigation={this.props.setShouldBlockNavigation}
            {...(isView && { mode: "view" })}
          />
        );
      case "label":
        return (
          <div {...item.props}>
            {process.data.fields[item.field]
              ? process.data.fields[item.field].value &&
                process.data.fields[item.field].value.split
                ? process.data.fields[item.field].value
                  .split("\n")
                  .map((l, i) => <div key={i}>{l}</div>)
                : item.value
              : item.value
                ? item.value
                : ""}
          </div>
        );
      case "div":
        return (
          <div {...item.props}>
            {process.data.fields[item.field]
              ? process.data.fields[item.field].value &&
                process.data.fields[item.field].value.split
                ? process.data.fields[item.field].value
                  .split("\n")
                  .map((l, i) => <div key={i}>{l}</div>)
                : `${get(
                  process,
                  `data.fields.${item.field}.value`,
                  item.value
                )} ${get(item, "unit", "")}`
              : item.value
                ? item.value
                : ""}
          </div>
        );
      case "checkbox":
        return (
          <Checkbox
            {...item.props}
            checked={
              process.data.fields[item.field] &&
                process.data.fields[item.field].value
                ? true
                : false
            }
            onChange={checked => {
              const event = { target: { checked } };
              this.checkboxChanged(item, process, template, user, event);
            }}
            {...(isView && { disabled: true })}
          />
        );
      case "title":
        return <SectionTitle style={{padding: '15px 0'}}>{item.value}</SectionTitle>;
      case "text":
        // if (process.fields[step.key + '_status'] && process.fields[step.key + '_status'].value === 'saved')
        return isView || get(item, "isView", false) ? (
          <div className={item.class}>
            {process.data.fields[item.field]
              ? process.data.fields[item.field].value
              : ""}
          </div>
        ) : (
            <span>
              <TextInput
                label={
                  <span>
                    {item.title}
                    {item.mandatory && <Asterisk>*</Asterisk>}
                  </span>
                }
                {...item.props}
                value={
                  process.data.fields[item.field]
                    ? process.data.fields[item.field].value
                    : ""
                }
                onChange={this.valueChanged.bind(
                  this,
                  item,
                  process,
                  template,
                  user
                )}
              />
              <div className="alert-danger">{this.props.validation_error}</div>
            </span>
          );
      case "textarea":
        return isView ? (
          <div className={item.class}>
            {process.data.fields[item.field]
              ? process.data.fields[item.field].value
              : ""}
          </div>
        ) : (
            <span>
              <TextInput
                {...item.props}
                type="textarea"
                className={"form-control " + item.class}
                value={
                  process.data.fields[item.field]
                    ? process.data.fields[item.field].value
                    : ""
                }
                onChange={this.valueChanged.bind(
                  this,
                  item,
                  process,
                  template,
                  user
                )}
              />
              <div className="alert-danger">{this.props.validation_error}</div>
            </span>
          );
      case "order_details_view":
        return <OrderDetailsView
          item={item}
          process={process}
          intl={this.props.intl}
        />
      case "text_timer":
        return <OrderTextTimer 
          isView={isView}
          item={item}
          process={process}
          refreshField={refreshField}
          step={step}
          template={template}
          user={user}
          editScript={editScript}
          onEditScript={this.editScript}
          valueEvaluatorChanged={this.valueEvaluatorChanged}
          onValueChangedSpot={this.onValueChangedSpot}
          validation_error={this.props.validation_error}
          buttonClicked={this.buttonClicked}
          intl={this.props.intl}
          setShouldBlockNavigation={this.props.setShouldBlockNavigation}
        />
      // case "text_timer":
      //   let textTimerValue = get(process, `data.fields.${item.field}.value`, "");
      //   /* get field value from spot linked */
      //   let spotInfo;
      //   let spotInfoLoaded;
      //   let spotInfoIndex;
      //   let isBookend;
      //   let textTimerValuePartB = "";
      //   let isUploadScriptFile = false;
      //   // process.data.loaded_fields.script.value
      //   if(item.isSpot){
      //     spotInfo = get(process, 'data.fields.spot_info.value', []).find(spot => spot.key === process.key);
      //     spotInfoIndex = get(process, 'data.fields.spot_info.value', []).findIndex(spot => spot.key === process.key);
      //     spotInfoLoaded = get(process, 'data.loaded_fields.spot_info.value', []).find(spot => spot.key === process.key);
      //     textTimerValue = get(spotInfo, item.field, "");

      //     isBookend = get(spotInfo, "spot_type", "Standard") === "Book-ends";

      //     if(isBookend){
      //       textTimerValuePartB = get(spotInfo, "script_part_b", "");
      //     }
      //   }

      //   return (
      //     <span>
      //       {!editScript && (!isBookend ? (<TextareaEvaluator
      //         readOnly={isView}
      //         showScriptLength={true}
      //         {...item.props}
      //         text={setScriptField(textTimerValue)}
      //         process={process}
      //         key={refreshField}
      //         onEvaluatorCallback={(value) => {
      //           if(item.isSpot){
      //             this.onValueChangedSpot(spotInfoIndex, { field: item.field, value }, process, step, template, user)
      //           }else{
      //             this.valueEvaluatorChanged(
      //               item,
      //               process,
      //               template,
      //               user,
      //               value
      //             )
      //           }
      //         }}
      //       />) : (
      //         <React.Fragment>
      //           <TextareaEvaluator
      //             readOnly={isView}
      //             showScriptLength={true}
      //             {...item.props}
      //             text={setScriptField(textTimerValue)}
      //             process={process}
      //             spot={spotInfo}
      //             key={refreshField}
      //             onEvaluatorCallback={(value) => {
      //               if(item.isSpot){
      //                 this.onValueChangedSpot(spotInfoIndex, { field: item.field, value }, process, step, template, user)
      //               }else{
      //                 this.valueEvaluatorChanged(
      //                   item,
      //                   process,
      //                   template,
      //                   user,
      //                   value
      //                 )
      //               }
      //             }}
      //             title={<FormattedMessage id="process > part A" />}
      //             isBookend={isBookend}
      //             part="a"
      //             showInnerTitle={true}
      //           />
      //           <TextareaEvaluator
      //             readOnly={isView}
      //             showScriptLength={true}
      //             {...item.props}
      //             text={setScriptField(textTimerValuePartB)}
      //             process={process}
      //             spot={spotInfo}
      //             key={`${refreshField}_1`}
      //             onEvaluatorCallback={(value) => {
      //               if(item.isSpot){
      //                 this.onValueChangedSpot(spotInfoIndex, { field: "script_part_b", value }, process, step, template, user)
      //               }else{
      //                 this.valueEvaluatorChanged(
      //                   item,
      //                   process,
      //                   template,
      //                   user,
      //                   value
      //                 )
      //               }
      //             }}
      //             title={<FormattedMessage id="process > part B" />}
      //             isBookend={isBookend}
      //             part="b"
      //             showInnerTitle={true}
      //           />
      //         </React.Fragment>
      //       )
      //       )}
      //       {editScript && (
      //         <Fragment>
      //           {!isBookend ? (<TextareaEvaluator
      //             readOnly={false}
      //             className="form-div"
      //             editScript={true}
      //             key={refreshField}
      //             text={setScriptField(textTimerValue)}
      //             process={process}
      //             hideHelperText={get(item, "props.hideHelperText", false)}
      //             onEvaluatorCallback={(value) => {
      //               if(item.isSpot){
      //                 this.onValueChangedSpot(spotInfoIndex, { field: item.field, value }, process, step, template, user)
      //               }else{
      //                 this.valueEvaluatorChanged(
      //                   item,
      //                   process,
      //                   template,
      //                   user,
      //                   value
      //                 )
      //               }
      //             }}
      //           />) : (
      //             <React.Fragment>
      //                 <TextareaEvaluator
      //                   readOnly={false}
      //                   className="form-div"
      //                   editScript={true}
      //                   key={refreshField}
      //                   text={setScriptField(textTimerValue)}
      //                   process={process}
      //                   spot={spotInfo}
      //                   hideHelperText={get(item, "props.hideHelperText", false)}
      //                   onEvaluatorCallback={(value) => {
      //                     if(item.isSpot){
      //                       this.onValueChangedSpot(spotInfoIndex, { field: item.field, value }, process, step, template, user)
      //                     }else{
      //                       this.valueEvaluatorChanged(
      //                         item,
      //                         process,
      //                         template,
      //                         user,
      //                         value
      //                       )
      //                     }
      //                   }}
      //                   title={<FormattedMessage id="process > part A" />}
      //                   isBookend={isBookend}
      //                   part="a"
      //                   showInnerTitle={true}
      //                 />
      //                 <TextareaEvaluator
      //                   readOnly={false}
      //                   className="form-div"
      //                   editScript={true}
      //                   key={`${refreshField}_1`}
      //                   text={setScriptField(textTimerValuePartB)}
      //                   process={process}
      //                   spot={spotInfo}
      //                   hideHelperText={get(item, "props.hideHelperText", false)}
      //                   onEvaluatorCallback={(value) => {
      //                     if(item.isSpot){
      //                       this.onValueChangedSpot(spotInfoIndex, { field: "script_part_b", value }, process, step, template, user)
      //                     }else{
      //                       this.valueEvaluatorChanged(
      //                         item,
      //                         process,
      //                         template,
      //                         user,
      //                         value
      //                       )
      //                     }
      //                   }}
      //                   title={<FormattedMessage id="process > part B" />}
      //                   isBookend={isBookend}
      //                   part="b"
      //                   showInnerTitle={true}
      //                 />
      //             </React.Fragment>
      //           )}
      //         </Fragment>
      //       )}
      //       <div className="alert-danger">{this.props.validation_error}</div>
      //       {!item.hide_open_in_new_window && (
      //         <div
      //           className="script-buttons"
      //         >
      //           <Button
      //             className={"btn-radius"}
      //             color="blue"
      //             onClick={() => {
      //               const processId = get(process, "data.fields.key.value");
      //               if(!processId) return;
      //               if (textTimerValue || textTimerValuePartB) {
      //                 const htmlContent = encodeURIComponent(textTimerValue);
      //                 const htmlContentB = encodeURIComponent(textTimerValuePartB);
      //                 const orderSold = get(
      //                   process,
      //                   "data.fields.order_sold.value",
      //                   true
      //                 );
      //                 let adType;
      //                 let adTypeSub;

      //                 if (!orderSold) {
      //                   adType = "SPEC";
      //                   adTypeSub = this.props.intl.formatMessage({
      //                     id: "process > order spec"
      //                   });
      //                 } else {
      //                   adType = "SOLD";
      //                   adTypeSub = this.props.intl.formatMessage({
      //                     id: "process > order spot"
      //                   });
      //                 }
      //                 const userId = get(this.props, "user.id");
      //                 window.open(
      //                   `${API_URL()}/app/script_viewer?script=${htmlContent}&scriptB=${htmlContentB}&process_id=${processId}&ad_type=${adType}&ad_type_sub=${adTypeSub}&user_id=${userId}`,
      //                   "script",
      //                   "width=1000,height=650,resizable=1"
      //                 );
      //               }
      //             }}
      //           >
      //             <FormattedMessage id="process > button open in new window" />
      //           </Button>
      //           { get(spotInfo, 'script') && 
      //             (!isBookend || (isBookend && get(spotInfo, 'script_part_b'))) && 
      //             htmlToText(get(spotInfoLoaded, 'script')) &&
      //             (!isBookend || (isBookend && htmlToText(get(spotInfoLoaded, 'script_part_b')))) && 
      //             (!isView || item.isShowEditButtonViewMode) &&
      //             item.editButton ? (
      //               editScript ? (
      //                 <Fragment>
      //                   <PrivilegedComponent requires={{or: [PRIVILEGES.EDIT_PRODUCTION_SCRIPT, PRIVILEGES.EDIT_ALL_PRODUCTION_ORDERS]}}>
      //                     <Button
      //                       className={"btn btn-pull-left"}
      //                       outline
      //                       color="blue"
      //                       onClick={this.buttonClicked.bind(
      //                         this,
      //                         step,
      //                         {
      //                           ...step.fields.find(f => f.component === "button"),
      //                           on_click: {
      //                             ...step.fields.find(f => f.component === "button")
      //                               .on_click,
      //                             validate: false
      //                           },
      //                           draft: true,
      //                         },
      //                         process,
      //                         template,
      //                         user
      //                       )}
      //                     >
      //                       {process.loading ? (
      //                         <FormattedMessage id="process > button saving" />
      //                       ) : (
      //                           <FormattedMessage id="process > button save" />
      //                         )}
      //                     </Button>
      //                   </PrivilegedComponent>
      //                   <Button
      //                     outline
      //                     color="blue"
      //                     onClick={this.editScript}
      //                     className={"btn btn-pull-left"}
      //                   >
      //                     <FormattedMessage id="process > button cancel" />
      //                   </Button>
      //                 </Fragment>
      //               ) : (
      //                   <PrivilegedComponent requires={{
      //                     or: [PRIVILEGES.EDIT_PRODUCTION_SCRIPT, PRIVILEGES.EDIT_ALL_PRODUCTION_ORDERS]
      //                   }}>
      //                     <Button
      //                       outline
      //                       color="blue"
      //                       onClick={this.editScript}
      //                       className={"btn btn-pull-left"}
      //                     >
      //                       <FormattedMessage id="process > button edit script" />
      //                     </Button>
      //                   </PrivilegedComponent>
      //                 )
      //             ) : null}
      //         </div>
      //       )}
      //     </span>
      //   );
      case "date":
        return isView ? (
          <div className={"input-group-text w-100 " + item.class}>
            {process.data.fields[item.field]
              ? process.data.fields[item.field].value
              : ""}
          </div>
        ) : (
            <span>
              <Input
                {...item.props}
                type="date"
                className={item.class}
                value={
                  process.data.fields[item.field]
                    ? process.data.fields[item.field].value
                    : ""
                }
                onChange={this.valueChanged.bind(
                  this,
                  item,
                  process,
                  template,
                  user
                )}
              />
              <div className="alert-danger">{this.props.validation_error}</div>
            </span>
          );
      case "numeric":
        return isView ? (
          <div className={"input-group-text w-100 " + item.class}>
            {process.data.fields[item.field]
              ? process.data.fields[item.field].value
              : ""}
          </div>
        ) : (
            <span>
              <Input
                {...item.props}
                type="number"
                step="1"
                className={item.class}
                value={
                  process.data.fields[item.field]
                    ? process.data.fields[item.field].value
                    : ""
                }
                onChange={this.valueChanged.bind(
                  this,
                  item,
                  process,
                  template,
                  user
                )}
              />
              <div className="alert-danger">{this.props.validation_error}</div>
            </span>
          );
      case "file_upload":
        let showRemoveIcon = true;
        const actionPrivileges = get(item, 'props.privileges.delete');

        if(actionPrivileges){
          showRemoveIcon = actionPrivileges.every(
            privilege => get(user, 'privileges', []).indexOf(privilege) > -1
          );
        }

        const spot = find(
          get(process, "data.fields.spot_info.value", []),
          (spot) => spot.key === process.key
        );

        const isBookend = get(spot, "spot_type", "Standard") === "Book-ends";

        return (
          <React.Fragment>
            <div className="final-recording">
              <UploadFile
                mode={isView ? "view" : ""}
                {...item.props}
                showRemoveIcon={showRemoveIcon}
                process={process}
                spot={spot}
                onUploaded={value => {
                  this.props.setShouldBlockNavigation(true);
                  this.fileUploaded(item, process, template, user, value);
                  if (item.uploadNew && !isView)
                    this.setState({ editRecording: true }); // keep buttons save and cancel
                }}
                onDeleted={this.fileDeleted.bind(
                  this,
                  item,
                  process,
                  template,
                  user
                )}
                url={get(process, `data.fields.${item.field}.value`, "")}
                className={item.class}
                auditInfo={get(process, "data.fields.recording_audit.value", "")}
                isBookend={isBookend}
                path={isBookend ? "a" : ""}
              >
                <FormattedMessage id="process > button upload" />
              </UploadFile>
              {this.props.validation_error && <div className="alert-danger">{this.props.validation_error}</div>}
            </div>
            {isBookend && (
              <div className="final-recording">
              <UploadFile
                mode={isView ? "view" : ""}
                {...item.props}
                showRemoveIcon={showRemoveIcon}
                process={process}
                spot={spot}
                onUploaded={value => {
                  this.props.setShouldBlockNavigation(true);
                  this.fileUploaded({ field: "recording_path_b"}, process, template, user, value);
                  if (item.uploadNew && !isView)
                    this.setState({ editRecordingPathB: true }); // keep buttons save and cancel
                }}
                onDeleted={this.fileDeleted.bind(
                  this,
                  { field: "recording_path_b"},
                  process,
                  template,
                  user
                )}
                url={get(process, `data.fields.recording_path_b.value`, "")}
                className={item.class}
                auditInfo={get(process, "data.fields.recording_path_b_audit.value", "")}
                isBookend={isBookend}
                path="b"
              >
                <FormattedMessage id="process > button upload" />
              </UploadFile>
              {get(this.props, 'validationState.recording_path_b.validation_error') && <div className="alert-danger">{get(this.props, 'validationState.recording_path_b.validation_error')}</div>}
            </div>)}
          </React.Fragment>
        );
      case "files_upload":
        if(isView) return null;
        return (
          <UploadFiles
            attachText={<FormattedMessage id="process > upload assets" />}
            {...item.props}
            // mimes
            acceptMultiple={true}
            files={
              process.data.fields[item.field]
                ? process.data.fields[item.field].value || []
                : []
            }
            isHideFilesDragArea={true}
            onUploadedFiles={value => {
              if(item.isUploadedByUser){
                value = value.map(fileValue => ({
                  ...fileValue,
                  user: pick(user, ['id', 'name', 'avatar'])
                }))
              }
              
              this.filesUploaded(
                item,
                process,
                template,
                user,
                value
              );
            }}
            onDeleted={filesDeleted => {}}
          >
            <FormattedMessage id="process > button upload" />
          </UploadFiles>
        );
      case "select_stations":
        return (
          <SelectStations
            {...item.props}
            {...(isView && { disabled: "disabled" })}
            validationState={this.props.validationState}
            checked={process.data.fields[item.field]}
            template={template}
            isFilterByServerConfig={true}
            onChange={this.checkboxGroupChanged.bind(
              this,
              item,
              process,
              template,
              user
            )}
            className={item.class}
          />
        );
      case "select_user":
        let options = this.getRoleCandidates(item, process, template, user);
        if (!options)
          return (
            <div className="text-black-50">
              <FormattedMessage id="process > title stations still not selected" />
            </div>
          );
        let value;
        if (process.data.fields[item.field])
          value = {
            value: process.data.fields[item.field].value.id,
            label: process.data.fields[item.field].value.name,
            role: process.data.fields[item.field].value.role
          };
        else if (item.default && options && options.length > 0) {
          value = options.reduce(e => e.value === user.id);
          //value = {value:user.id,label:user.name, role:item.default}
          if (value)
            this.valueChanged(item, process, template, user, {
              value: { id: user.id, name: user.name, role: item.default }
            });
        }
        return isView ? (
          <div className={item.class}>
            {process.data.fields[item.field]
              ? process.data.fields[item.field].value.name
              : ""}
          </div>
        ) : (
            <span>
              <DropdownCustomOption
                allowSelectAll={false}
                isMulti={false}
                {...item.props}
                value={value}
                onChange={selectedOption => {
                  this.valueChanged(item, process, template, user, {
                    value: {
                      id: selectedOption.value,
                      name: selectedOption.label,
                      role: selectedOption.role
                    }
                  });
                }}
                options={options}
                name={"select_" + step.key + "_" + item.key}
              />
              <div className="alert-danger">{this.props.validation_error}</div>
            </span>
          );

      // <span><Dropdown allowSelectAll={false} isMulti={false} {...item.props}
      // value={value}
      // onChange={selectedOption => {
      //     this.valueChanged(item, process, template, user,{value:{id:selectedOption.value, name:selectedOption.label, role:selectedOption.role}})
      //   }}
      // options={options}
      // name={"select_" + step.key + "_" + item.key}
      // />
      // <div className="alert-danger">{this.props.validation_error}</div>
      // </span>
      case "player":
        return !item.field ? (
          <FormattedMessage id="process > title not uploaded yet" />
        ) : item.field.indexOf(".mp3") > 0 ? (
          <Player {...item.props} url={process.data.fields[item.field]} />
        ) : (
              <Image {...item.props} url={process.data.fields[item.field]} />
            );
      case "spots":
        return isView ? (
          <div className={"input-group-text w-100 " + item.class}>
            {process.data.fields[item.field]
              ? process.data.fields[item.field].value
              : ""}
          </div>
        ) : (
            <span>
              <Input
                {...item.props}
                type="number"
                step="1"
                className={item.class}
                value={
                  process.data.fields[item.field]
                    ? process.data.fields[item.field].value
                    : ""
                }
                onChange={this.spotsChanged.bind(
                  this,
                  item,
                  process,
                  template,
                  user
                )}
              />
              <div className="alert-danger">{this.props.validation_error}</div>
            </span>
          );
      case "button":
        let disabled = get(item, 'props.disabled', false) || false;
        if(isFunction(get(item, 'props.is_disabled'))){
          disabled = item.props.is_disabled(user, process, step)
        }
        const isHidden = item.is_hidden ? item.is_hidden(user, process, step, isView) : false;
        return (isView || isHidden) ? null : (
          <PrivilegedComponent requires={item.privileges ? item.privileges : []}>
            <Button 
              className={"btn btn-pull-left"}
              {...omit(item.props, ['is_disabled', 'isOwnSegment', 'classNameSegment'])}
              color="blue"
              disabled={disabled}
              onClick={item.onClick ? () => item.onClick(this, step, item, process, template, user) : this.buttonClicked.bind(
                this,
                step,
                item,
                process,
                template,
                user
              )}
            >
              {(this.state.buttonClicking === "draft" && item.draft) ||
              (this.state.buttonClicking === "completed" && !item.draft) ? (
                <FormattedMessage id="process > button submitting" />
              ) : typeof item.getText === 'function' ? (
                item.getText(step, item, process, template, user)
              ) : (
                item.text
              )}
            </Button>
          </PrivilegedComponent>
        );
      case "view_files":
        if (get(process, `data.fields.${item.field}.value.length`, 0) === 0) {
          return <div className="form-div" />;
        }
        if(!item.isHandleAssets || isView){
          return <div className="view-files-component">
            <div className="view-only">
              {
                get(process, `data.fields.${item.field}.value`, []).map((file, index) => (
                  <FileView key={index} file={file} actions={item.props.actions} />
                ))
              }
            </div>
          </div>
        }
        const fileViews = filter(get(process, `data.fields.${item.field}.value`, []), (file) => !file.user || file.user.id !== user.id);
        const fileHandleAssets = filter(get(process, `data.fields.${item.field}.value`, []), (file) => file.user && file.user.id === user.id);
        let fileHandleAssetsEle;
        if(fileHandleAssets.length > 0){
          fileHandleAssetsEle = <HandleAssets
          files={fileHandleAssets}
          onUploadedFiles={value => {
            if(item.isUploadedByUser){
              value = value.map(fileValue => ({
                ...fileValue,
                user: pick(user, ['id', 'name', 'avatar'])
              }));
            }
            value = [...fileViews, ...value];
            this.fileUploaded(
              item,
              process,
              template,
              user,
              value
            );
          }}
          onDeleted={filesDeleted => {
            let value = get(process, `data.fields.${item.field}.value`, []);
            value = [...fileViews, ...filesDeleted];
            this.filesDeleted(
              item,
              process,
              template,
              user,
              value
            );
          }}
        />
        }
        const fileViewEle = fileViews.map((file, index) => (
          <FileView key={index} file={file} actions={item.props.actions} />
        ));
        return (
          <div className="view-files-component">
            <div className="view-only">{fileViewEle}</div>
            <div className="handle-assets">{fileHandleAssetsEle}</div>
          </div>
        );
      case "log":
        return <ActivityLog process={process} step={step} />;
      case "order_approvals":
        return (
          <OrderApprovals
            valueChanged={this.valueChanged}
            process={process}
            step={step}
            template={template}
            user={user}
            isView={isView}
            item={item}
            validationState={this.props.validationState}
            setShouldBlockNavigation={this.props.setShouldBlockNavigation}
          />
        );
      case "order_script":

        return (
          <OrderScript
            valueChanged={this.valueChanged}
            process={process}
            step={step}
            template={template}
            user={user}
            isView={isView}
            item={item}
            validationState={this.props.validationState}
            setShouldBlockNavigation={this.props.setShouldBlockNavigation}
          />
        );
      case "order_script_review":
        return (
          <OrderScriptReview
            valueChanged={this.valueChanged}
            process={process}
            step={step}
            template={template}
            user={user}
            isView={isView}
            item={item}
            valueEvaluatorChanged={this.valueEvaluatorChanged}
            validationState={this.props.validationState}
          />
        );
      case "order_script_review_feedback":
        return (
          <OrderScriptReviewFeedback
            valueChanged={this.valueChanged}
            process={process}
            step={step}
            template={template}
            user={user}
            isView={isView}
            item={item}
            valueEvaluatorChanged={this.valueEvaluatorChanged}
            validationState={this.props.validationState}
          />
        );
      case "order_script_review_history":
        return (
          <OrderScriptReviewHistory
            valueChanged={this.valueChanged}
            process={process}
            step={step}
            template={template}
            user={user}
            isView={isView}
            item={item}
            valueEvaluatorChanged={this.valueEvaluatorChanged}
            validationState={this.props.validationState}
          />
        );
      case "production_assignments":
        return (
          <ProductionAssignments
            valueChanged={this.valueChanged}
            process={process}
            step={step}
            template={template}
            user={user}
            isView={isView}
            item={item}
            valueEvaluatorChanged={this.valueEvaluatorChanged}
            validationState={this.props.validationState}
            getRoleCandidates={this.getRoleCandidates.bind(this)}
          />
        );
      case 'order_recording_review':
        return <OrderRecordingReview
          valueChanged={this.valueChanged}
          process={process}
          step={step}
          template={template}
          user={user}
          isView={isView}
          item={item}
          shouldBlockNavigation={this.props.setShouldBlockNavigation}
          setShouldBlockNavigation={this.props.setShouldBlockNavigation}
          formValueChanged={this.props.formValueChanged}
        />
      case 'order_recording_history':
        return <OrderRecordingHistory
          process={process}
          step={step}
          template={template}
          user={user}
          isView={isView}
          item={item}
        />
      case "voice_tracks": 
        return <VoiceTracks
          valueChanged={this.valueChanged}
          step={step}
          process={process}
          template={template}
          user={user}
          validationError={this.props.validation_error}
          getRoleCandidates={this.getRoleCandidates.bind(this)}
          {...(isView && { mode: "view" })}
          item={item}
          formValueChanged={this.props.formValueChanged}
          shouldBlockNavigation={this.props.shouldBlockNavigation}
          setShouldBlockNavigation={this.props.setShouldBlockNavigation}
        />
      case "voice_over_details": 
        return <VoiceOverDetails
          valueChanged={this.valueChanged}
          step={step}
          process={process}
          template={template}
          user={user}
          validationError={this.props.validation_error}
          {...(isView && { mode: "view" })}
          item={item}
          formValueChanged={this.props.formValueChanged}
          shouldBlockNavigation={this.props.shouldBlockNavigation}
          setShouldBlockNavigation={this.props.setShouldBlockNavigation}
        />
      case "production_reschedule":
        return <ProductionReschedule
          valueChanged={this.valueChanged}
          step={step}
          process={process}
          template={template}
          user={user}
          validationState={this.props.validationState}
          buttonClicked={this.buttonClicked}
          {...(isView && { mode: "view" })}
          {...item.props}
        />
      case "production_details":
        return <ProductionDetails
          valueChanged={this.valueChanged}
          step={step}
          process={process}
          template={template}
          user={user}
          getRoleCandidates={this.getRoleCandidates.bind(this)}
          validationState={this.props.validationState}
          setShouldBlockNavigation={this.props.setShouldBlockNavigation}
          {...(isView && { mode: "view" })}
          {...item.props}
        />
      case "production_continuity_traffic_details":
        return <ProductionContinuityTrafficDetails
          valueChanged={this.valueChanged}
          step={step}
          process={process}
          template={template}
          user={user}
          isView={isView}
          buttonClicked={this.buttonClicked}
          validationState={this.props.validationState}
          getRoleCandidates={this.getRoleCandidates.bind(this)}
          setShouldBlockNavigation={this.props.setShouldBlockNavigation}
          setActiveEditTab={this.props.setActiveEditTab}
          {...item.props}
        />
      case "production_dubbing_traffic_details":
        return <ProductionDubbingTrafficDetails
          valueChanged={this.valueChanged}
          step={step}
          process={process}
          template={template}
          user={user}
          isView={isView}
          buttonClicked={this.buttonClicked}
          validationState={this.props.validationState}
          setShouldBlockNavigation={this.props.setShouldBlockNavigation}
          {...item.props}
        />
      case "production_recording":
        return <ProductionRecording
          valueChanged={this.valueChanged}
          step={step}
          process={process}
          template={template}
          user={user}
          isView={isView}
          buttonClicked={this.buttonClicked}
          validationState={this.props.validationState}
          shouldBlockNavigation={this.props.shouldBlockNavigation}
          setShouldBlockNavigation={this.props.setShouldBlockNavigation}
          {...item.props}
        />
      case "history":
        return (
          process.data.fields[item.field].value &&
          process.data.fields[item.field].value.length &&
          process.data.fields[item.field].value.map(script => {
            return (
              <div key={script.time} className="process-history-row">
                <div className="process-history-item">
                  <span>
                    {item.field === "recording_history" &&
                      getFileName(script.value)}
                  </span>
                  <span>
                    By {getUserName(script.user, info.users)} On{" "}
                    {formatDisplayDateTime(script.time)}
                  </span>
                </div>

                {item.field === "script_history" ? (
                  <TextareaEvaluator
                    readOnly={isView}
                    {...item.props}
                    text={setScriptField(script.value)}
                    process={process}
                    onEvaluatorCallback={() => { }}
                  />
                ) : (
                    <Fragment>
                      <UploadFile
                        mode={"view"}
                        {...item.props}
                        showRemoveIcon={false}
                        url={script.value}
                      >
                        <FormattedMessage id="process > button upload" />
                      </UploadFile>
                    </Fragment>
                  )}
              </div>
            );
          })
        );
      case "production_deadlines": 
        return <ProductionDeadlines
          valueChanged={this.valueChanged}
          step={step}
          process={process}
          template={template}
          user={user}
          validationError={this.props.validation_error}
          isView={isView}
          item={item}
          shouldBlockNavigation={this.props.shouldBlockNavigation}
          setShouldBlockNavigation={this.props.setShouldBlockNavigation}
        />
      default:
        return (
          <div className={item.class}>
            <b>{item.component}</b> component: NOT IMPLEMENTED YET:
            <br />
            {process.data.fields[item.field]
              ? process.data.fields[item.field].value
              : ""}
          </div>
        );
    }
  };

  editScript = () => {
    this.setState(prevState => ({
      editScript: !prevState.editScript,
      refreshField: uuidv4()
    }));

    let spotInfo = get(this.props.process, 'data.fields.spot_info.value', []).find(spot => spot.key === this.props.process.key);
    let spotInfoIndex = get(this.props.process, 'data.fields.spot_info.value', []).findIndex(spot => spot.key === this.props.process.key);

    if(get(this.state, "originalScript", false) !== false){
    
      this.onValueChangedSpot(spotInfoIndex, { field: 'script', value: get(this.state, "originalScript") }, this.props.process, this.props.step, this.props.template, this.props.user)
    }else{
      this.setState({originalScript: spotInfo.script});
    }

    if(get(this.state, "originalScriptPartB", false) !== false){
    
      this.onValueChangedSpot(spotInfoIndex, { field: 'script_part_b', value: get(this.state, "originalScriptPartB") }, this.props.process, this.props.step, this.props.template, this.props.user)
    }else{
      this.setState({originalScriptPartB: spotInfo.script_part_b});
    }
  }
    
  onUploadNewVersionRecording = item => {
    if (item.field === "recording_path") {
      const { process, template, user } = this.props;
      this.props.setConfirmationDialogProps({
        title: this.props.intl.formatMessage({
          id: "confirm modal > title upload new version"
        }),
        onConfirm: () => {
          this.setState({
            editRecording: true
          });
          this.props.setConfirmationDialogProps(null)
          this.valueChanged(item, process, template, user, {
            value: ""
          });
        },
        onCancel: () => {
          this.setState({
            editRecording: false
          });
          this.props.setConfirmationDialogProps(null)
          return;
        }
      })
      return true;
    }
    else if (item.field === "recording_path_b") {
      const { process, template, user } = this.props;
      this.props.setConfirmationDialogProps({
        title: this.props.intl.formatMessage({
          id: "confirm modal > title upload new version"
        }),
        onConfirm: () => {
          this.setState({
            editRecordingPathB: true
          });
          this.props.setConfirmationDialogProps(null)
          this.valueChanged(item, process, template, user, {
            value: ""
          });
        },
        onCancel: () => {
          this.setState({
            editRecordingPathB: false
          });
          this.props.setConfirmationDialogProps(null)
          return;
        }
      });
      return true;
    }
     else {
      this.setState({
        editRecording: true
      });
    }
  };
  cancelUploadRecording = item => {
    const { process, template, user } = this.props;
    this.valueChanged(item, process, template, user, {
      value: null
    });

    if(item.field === 'recording_path_b'){
      this.setState({ editRecordingPathB: true });
    }else{
      this.setState({ editRecording: true });
    }
    
  };
}

export default injectIntl(ComponentFactory);