import { injectIntl } from 'react-intl';
import { omit, forOwn, forEach, keys, includes, has, get, map, isEqual, concat, intersection, isEmpty, filter } from "lodash";
import { compose, withState, withHandlers, lifecycle } from "recompose";
import { connect } from "react-redux";

import ModuleEntitiesAssignmentModal from './view';
import { getAllStationsAndTeams } from 'store/actions/teams';

export default injectIntl(
  compose(
    connect((state, ownProps) => {
      const {validEntities} = ownProps
      const assignedStations = get(state, "stations.stations.data.data", []);
      const assignedStationIds = map(assignedStations, "id");
      const allStationsAndTeams = get(state, "teams.allStationsAndTeams.data.data", []);
      
      const allAssignedStations = {};
      forOwn(allStationsAndTeams, (value) => {
        // filter entities by props
        if(validEntities){
          if (includes(validEntities, value.id) && includes(assignedStationIds, value.id)) {
            allAssignedStations[value.id] = value;
          }
        }else{
          if (includes(assignedStationIds, value.id)) {
            allAssignedStations[value.id] = value;
          }
        }
      });
      return {
        ...ownProps,
        assignedStations: assignedStations,
        allStationsAndTeams: allAssignedStations,
        userId: get(state, "auth.user.id")
      };
    }, {
      getAllStationsAndTeams,
    }),
    withState("selectedTags", "setSelectedTags", {}),
    withState("selectedStations", "setSelectedStations", {}),
    withState("addedStations", "setAddedStations", {}),
    withState("isLoading", "setIsLoading", true),
    withState("isEntitiesSaved", "setIsEntitiesSaved", false),
    withState("isUpdateModalOpen", "setIsUpdateModalOpen", false),
    withHandlers({
      updateSelectedTags: ({
        setSelectedTags,
        selectedStations,
        setSelectedStations,
      }) => (checked, tag, tagType, updateStations = true) => {

        const { id, label, stations } = tag;

        if (checked) {

          let tagStations = {};

          forEach(stations, station => {

            station = has(selectedStations, station.key) ? selectedStations[station.key] : station;

            tagStations[station.key] = { ...station, [tagType]: { ...station[tagType], [id]: { ...station[tagType][id], selected: true } } };

          });

          setSelectedTags((previousSelectedTags) => {

            if (updateStations) {

              //if the selected tag is a team, we will just add the team to the selected stations, otherwise we will add the stations of the selected tag to the selected stations list

              setSelectedStations({ ...selectedStations, ...tagStations });
            }

            return { ...previousSelectedTags, [id]: { id, label, type: tagType, stations: tagStations } };

          });
        } else {

          setSelectedTags((previousSelectedTags) => {

            let newSelectedTags = omit(previousSelectedTags, id);

            if (updateStations) {

              /*
              * if the stations of the removed tag are not part of any other tag we need to remove those stations from selected stations list
              * If they were selected using another tag, we will unset the selected property for the deleted tag
              */

              let selectedTagStations = {};

              forEach(newSelectedTags, (tag) => {
                selectedTagStations = concat(selectedTagStations, keys(tag.stations));
              });


              let newSelectedStations = {};

              //all stations other than the removed tag's
              forEach(selectedStations, station => {
                if (!includes(keys(stations), station.key)) {
                  newSelectedStations[station.key] = station;
                }
              });

              //now loop through removed tag's stations and remove  their selected attribute for the removed tag if they are part of other tag's stations
              forEach(stations, (station) => {

                station = has(selectedStations, station.key) ? selectedStations[station.key] : station;

                if (includes(selectedTagStations, station.key)) {
                  newSelectedStations[station.key] = { ...station, [tagType]: { ...station[tagType], [id]: omit(station[tagType][id], 'selected') } };
                }
              });

              setSelectedStations(newSelectedStations);
            }

            return newSelectedTags;

          });
        }
      },
      closeModal: ({ setSelectedStations, setSelectedTags, setAddedStations, onToggle }) => () => {
        setSelectedTags({});
        setSelectedStations({});
        setAddedStations({});
        onToggle();
      }
    }),
    withHandlers({
      updateSelectedStations: ({
        selectedStations,
        setSelectedStations,
        selectedTags,
        updateSelectedTags
      }) => (checked, station) => {
        if (checked) {
          setSelectedStations({ ...selectedStations, [station.key]: station });
        } else {

          let newSelectedStations = omit(selectedStations, station.key);
          setSelectedStations(newSelectedStations);

          forEach(selectedTags, (tag) => {
            if (isEmpty(intersection(keys(tag.stations), keys(newSelectedStations)))) {
              updateSelectedTags(false, tag, tag.type, false);
            }
          });
        }
      },
      onSaveEntities: ({ assignedStations, addedStations, closeModal, onSubmit }) => () => {
        if (onSubmit) onSubmit(addedStations, assignedStations);
        closeModal();
      },
    }),
    lifecycle({
      componentDidMount() {
        // ensure we got the stations and teams from api before render the form content
        this.props.setIsLoading(true);
        this.props.getAllStationsAndTeams(() => {
          this.props.setIsLoading(false);
        });
      },
      componentDidUpdate(prevProps) {
        if (!isEqual(this.props.allStationsAndTeams, prevProps.allStationsAndTeams) || !isEqual(this.props.selectedEntitiesAndTeams, prevProps.selectedEntitiesAndTeams) || (this.props.isOpen && !isEqual(this.props.isOpen, prevProps.isOpen))) {

          let selectedEntitiesAndTeams = {};

          filter(this.props.allStationsAndTeams, station => includes(keys(this.props.selectedEntitiesAndTeams), station.key))
            .forEach(({ key, station_key, market, format, team, name, call_letters }) => {
              selectedEntitiesAndTeams[key] = {
                key,
                station_key,
                market,
                format,
                team,
                name,
                call_letters
              };
            });
          this.props.setAddedStations(selectedEntitiesAndTeams);
        }
      }
    }),
  )(ModuleEntitiesAssignmentModal)
);
