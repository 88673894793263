import Admins from "./view";
import { get, isEmpty, findIndex, forEach, filter, includes } from "lodash";
import { connect } from "react-redux";
import { adminTabs } from "utils/config";
import { compose, withState, lifecycle, withHandlers } from "recompose";
import { injectIntl } from "react-intl";
import { PRIVILEGES } from "utils/constants";
import {
  TAG_TYPE_LOCATION,
  TAG_TYPE_FORMAT,
  TAG_TYPE_EXCEPT_LOCATION_FORMAT,
} from "components/SelectTags";
import { setNewUser } from "store/actions/users";
function getTagOptionsByType(type, auth) {
  let tagOptions = [];
  let tags = get(auth, "info.tags.tags", []);
  switch (type) {
    case TAG_TYPE_LOCATION:
      tags = filter(tags, (tag) => includes(tag, "Location"));
      break;
    case TAG_TYPE_FORMAT:
      tags = filter(tags, (tag) => includes(tag, "Format"));
      break;
    case TAG_TYPE_EXCEPT_LOCATION_FORMAT:
      tags = filter(
        tags,
        (tag) => !includes(tag, "Format") && !includes(tag, "Location")
      );
      break;
    default:
      break;
  }
  let map_tags = (tag, path) => {
    tagOptions.push({
      value: tag.id,
      label: path,
    });
    tag.children &&
      tag.children.map((child) => {
        map_tags(child, path + " / " + child.title);
      });
  };
  tags.map((tag) => {
    map_tags(tag, tag.title);
  });
  return tagOptions;
}

export default injectIntl(
  compose(
    connect(
      (state, props) => {
        const auth = get(state, "auth");
        const serverId = get(auth, "info.server_id");
        const server = get(state, `servers.server.${serverId}.data`, {});
        const woAutomation = get(server, "entities.wo_dubbing", {});
        const enableIntegrations =
          get(server, "enable_wo_dubbing") && !isEmpty(woAutomation);
        return {
          auth,
          enableIntegrations,
          tab: get(props, "match.params.tab", adminTabs.TAB_BROADCASTING_GROUP),
          hub: get(state, "hubs.hub.data"),
        };
      },
      { setNewUser }
    ),
    withState("activeTab", "setActiveTab", ({ tab }) => tab),
    withHandlers({
      getSelectedMarketsByUser: ({ auth }) => () => {
        let selectedMarketOptions = [];
        const { user } = auth;
        const locationOptions = getTagOptionsByType(TAG_TYPE_LOCATION, auth);
        forEach(get(user, "tags"), (item) => {
          let existsIndex = findIndex(
            locationOptions,
            (selected) => Number(selected.value) === Number(item)
          );

          if (existsIndex !== -1) {
            selectedMarketOptions.push({
              value: get(locationOptions, `${existsIndex}.value`),
              label: get(locationOptions, `${existsIndex}.label`),
            });
          }
        });
        return selectedMarketOptions;
      },
      isHasPrivilegeAccessTab: ({ auth, enableIntegrations }) => (tabKey) => {
        if (
          auth.info.app_flags &&
          auth.info.app_flags.indexOf("SERVERS_LIST") > -1 &&
          tabKey !== adminTabs.TAB_BROADCASTING_GROUP &&
          tabKey !== adminTabs.TAB_USERS
        ) {
          return false;
        }

        if (
          get(auth, "user.is_support_admin", false) &&
          tabKey === adminTabs.TAB_USERS &&
          auth.info.app_flags &&
          auth.info.app_flags.indexOf("SERVERS_LIST") > -1
        ) {
          return false;
        }

        if (
          get(auth, "user.is_god_admin") ||
          (get(auth, "user.broadcasting_group_admin") &&
            tabKey !== adminTabs.TAB_ROLES)
        ) {
          return true;
        }

        let status = false;
        let privileges = get(auth, "user.privileges") || [];
        switch (tabKey) {
          case adminTabs.TAB_BROADCASTING_GROUP:
            if (
              privileges.includes(PRIVILEGES.ADD_SERVERS) ||
              privileges.includes(PRIVILEGES.DELETE_SERVERS) ||
              privileges.includes(PRIVILEGES.VIEW_SERVERS) ||
              privileges.includes(PRIVILEGES.EDIT_SERVERS)
            ) {
              status = true;
            }
            break;
          case adminTabs.TAB_STATIONS:
            if (get(auth, "user.is_admin")) {
              status = true;
            } else if (
              privileges.includes(PRIVILEGES.ADD_STATIONS) ||
              privileges.includes(PRIVILEGES.VIEW_STATIONS) ||
              privileges.includes(PRIVILEGES.DELETE_STATIONS) ||
              privileges.includes(PRIVILEGES.EDIT_STATIONS)
            ) {
              status = true;
            }
            break;
          case adminTabs.TAB_ROLES:
            status = get(auth, "user.is_god_admin") ? true : false;
            break;
          case adminTabs.TAB_ADMINS:
            status = get(auth, "user.is_god_admin") ? true : false;
            break;
          case adminTabs.TAB_USERS:
            if (
              privileges.includes(PRIVILEGES.ADD_USERS) ||
              privileges.includes(PRIVILEGES.EDIT_USERS) ||
              privileges.includes(PRIVILEGES.VIEW_USERS) ||
              privileges.includes(PRIVILEGES.DELETE_USERS) ||
              privileges.includes(PRIVILEGES.ASSIGN_PRIVILEGES)
            ) {
              status = true;
            }
            break;
          case adminTabs.TAB_TEAMS:
            if (get(auth, "user.is_admin")) {
              status = true;
            } else if (
              privileges.includes(PRIVILEGES.ADD_TEAMS) ||
              privileges.includes(PRIVILEGES.VIEW_TEAMS) ||
              privileges.includes(PRIVILEGES.DELETE_TEAMS) ||
              privileges.includes(PRIVILEGES.EDIT_TEAMS)
            ) {
              status = true;
            }
            break;
          case adminTabs.TAB_WIDEORBIT:
            status = false;
            if (get(auth, "user.is_god_admin")) {
              status = enableIntegrations;
            }
            break;
          case adminTabs.TAB_HUBS:
            status =
              get(auth, "user.is_god_admin") || get(auth, "user.is_admin")
                ? true
                : false;
            break;
          case adminTabs.TAB_ZETTA:
            status =
              get(auth, "user.is_god_admin") || get(auth, "user.is_admin")
                ? true
                : false;
            break;
          case adminTabs.TAB_OUT_OF_OFFICE:
            if (
              get(auth, "user.is_god_admin") ||
              privileges.includes(PRIVILEGES.USER_SCHEDULING)
            ) {
              status = true;
            }
            break;
          default:
            status = false;
            break;
        }
        return status;
      },
    }),
    lifecycle({
      componentWillReceiveProps(nextProps, nextState) {
        if (get(nextProps, "tab") !== get(this.props, "tab")) {
          this.props.setActiveTab(get(nextProps, "tab"));
        }
      },
    })
  )(Admins)
);
