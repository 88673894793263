import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  FaUserSecret,
  FaSortDesc,
  FaSortAsc,
  FaPlus,
  FaSearch,
} from "react-icons/lib/fa";
import { TrashListIcon, LockClosedIcon } from "components/CustomIcons";
import { MdClose } from "react-icons/lib/md";
import classnames from "classnames";
import Checkbox from "components/Checkbox";
import Spinner from "components/Spinner";
import { get, find, includes, map, sortBy } from "lodash";
import { ButtonGroup, Button, Row, Col } from "reactstrap";
import { FormattedMessage } from "react-intl";
import TextInput from "components/TextInput";
import ConfirmationModal from "components/ConfirmationModal";
import Table from "components/Table";
import PrivilegedComponent from "components/PrivilegedComponent";
import BulkUploadUserModal from "components/BulkUploadUser";
import { PRIVILEGES } from "utils/constants";
import useInfiniteScroll from "utils/useInfiniteScroll";
import ToastManager from "components/ToastManager";
import bn from "utils/bemnames";
import { validatePrivileges } from "utils/helpers";
import Tooltip from "rc-tooltip";
const bem = bn.create("list-users");

const UserTableManual = (props) => {
  const {
    selectedRowsId,
    setSelectedRowsId,
    filtered,
    sorted,
    setSorted,
    users,
    searchText,
    onChangeInput,
    auth,
    onSearchKeyDown,
    onSearchClicked,
    onClearSearch,
    isLazyLoading,
    onLoadMore,
    isFocused,
  } = props;
  const privileges = get(auth, "user.privileges", []);
  const isGroupAdmin = get(auth, "user.broadcasting_group_admin", false);
  const selectedNewUsers = Object.values(selectedRowsId).filter((item) => item);
  const userList = get(users, "data.data") || [];
  const pages = get(users, "data.last_page") || 1;
  const total = get(users, "data.total") || 1;
  const isServerList =
    auth.info.app_flags && auth.info.app_flags.indexOf("SERVERS_LIST") > -1;
  const isAllowActive =
    validatePrivileges({
      requires: [PRIVILEGES.EDIT_USERS],
      privileges,
      user: auth.user,
    }) || isGroupAdmin;
  let columns = [];
  const onClickItem = (item) => {
    const isEditable = validatePrivileges({
      requires: {
        or: [PRIVILEGES.EDIT_USERS, PRIVILEGES.ASSIGN_PRIVILEGES],
      },
      privileges,
      user: auth.user,
    });
    if (isEditable) {
      props.onSelectedEdit({ [item.id]: item });
      return;
    }
    const isViewable = validatePrivileges({
      requires: [PRIVILEGES.VIEW_USERS],
      privileges,
      user: auth.user,
    });
    if (isViewable) {
      props.onSelectedView({
        [item.id]: item,
      });
    }
  };

  useInfiniteScroll({
    useWindow: false,
    elementId: "cr-app-main",
    onLoadMore: onLoadMore,
    shouldLoadMore:
      isFocused &&
      !isLazyLoading &&
      get(users, "data.last_page") !== get(users, "data.current_page"),
  });
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const userId = urlParams.get("user");
    if (userId) {
      onClickItem({
        id: userId,
      });
    }
  }, []);
  const sortedName = find(sorted, (item) => item.id === "name");
  columns.push({
    Header: (
      <div className={classnames(bem.e("th-name"), bem.e("th-sort"))}>
        <FormattedMessage id="user > name" />
        <div className={bem.e("sortable")}>
          <FaSortAsc
            className={classnames(bem.e("sortable-icon"), {
              [bem.e("sortable-active")]: sortedName && !sortedName.desc,
            })}
          />
          <FaSortDesc
            className={classnames(bem.e("sortable-icon"), {
              [bem.e("sortable-active")]: sortedName && sortedName.desc,
            })}
          />
        </div>
      </div>
    ),
    accessor: "name",
    Cell: ({ original: user }) => {
      const { is_active } = user;
      return (
        <div
          className={classnames(bem.e("user-name-column"), bem.e("td-value"), {
            [bem.e("text-inactive")]: !is_active,
          })}
        >
          {user.name}
        </div>
      );
    },
  });
  if (!isServerList) {
    const sortedRoles = find(sorted, (item) => item.id === "roles_string");
    columns.push({
      Header: (
        <div className={bem.e("th-sort")}>
          <FormattedMessage id="user > roles" />
          <div className={bem.e("sortable")}>
            <FaSortAsc
              className={classnames(bem.e("sortable-icon"), {
                [bem.e("sortable-active")]: sortedRoles && !sortedRoles.desc,
              })}
            />
            <FaSortDesc
              className={classnames(bem.e("sortable-icon"), {
                [bem.e("sortable-active")]: sortedRoles && sortedRoles.desc,
              })}
            />
          </div>
        </div>
      ),
      accessor: "roles_string",
      Cell: ({ original: user }) => {
        const { is_active } = user;
        let rolesString = user.roles_string;
        let isAdmin = false;
        if (user.broadcasting_group_admin) {
          rolesString = props.intl.formatMessage({
            id: "user > broadcasting group admin",
          });
          isAdmin = true;
        } else if (user.admin_type === "market_admin") {
          rolesString = props.intl.formatMessage({ id: "user > market admin" });
          isAdmin = true;
        }
        return (
          <span
            className={classnames({
              [bem.e("td-value")]: !isAdmin,
              [bem.e("text-inactive")]: !is_active,
            })}
          >
            {rolesString ? (
              <Tooltip
                placement="topLeft"
                trigger={["click", "hover"]}
                overlayClassName={bem.e("tooltip")}
                overlay={<div className={"market-string"}>{rolesString}</div>}
              >
                <div className={"market-string"}>{rolesString}</div>
              </Tooltip>
            ) : (
              <FormattedMessage id="user > none" />
            )}
          </span>
        );
      },
    });
    columns.push({
      Header: <FormattedMessage id="user > teams and stations" />,
      sortable: false,
      accessor: "stations_string",
      Cell: ({ original: user }) => {
        const { is_active } = user;
        const stationsArr = map(user.stations, (item) => {
          if (item.call_letters && item.name) {
            return `${item.call_letters} - ${item.name}`;
          }
          return item.name;
        })
          .concat(map(user.team_groups, (team) => team.name))
          .filter((string) => string)
          .map((string) => string.toLowerCase());

        const stationsString = sortBy(stationsArr).join(", ");
        return (
          <span
            className={classnames(bem.e("td-value"), {
              [bem.e("text-inactive")]: !is_active,
            })}
          >
            {stationsArr.length > 0 ? (
              <Tooltip
                placement="topLeft"
                trigger={["click", "hover"]}
                overlayClassName={bem.e("tooltip")}
                overlay={
                  <div className={"market-string text-caps"}>
                    {stationsString}
                  </div>
                }
              >
                <div className={"market-string text-caps"}>
                  {stationsString}
                </div>
              </Tooltip>
            ) : (
              <FormattedMessage id="user > none" />
            )}
          </span>
        );
      },
    });
  }
  columns.push({
    Header: <FormattedMessage id="user > Actions" />,
    sortable: false,
    resizable: false,
    filterable: false,
    id: "actions",
    style: { justifyContent: "center", textAlign: "center" },
    Cell: ({ original: user }) => {
      if (
        !auth.user.broadcasting_group_admin &&
        !auth.user.is_god_admin &&
        (user.is_god_admin || user.broadcasting_group_admin)
      )
        return null;

      if (auth.user.is_support_admin && user.is_god_admin) return null;
      const isShowImpersonateButton = auth.user.is_god_admin && !isServerList;
      return (
        <ButtonGroup>
          {/* delete button */}
          <PrivilegedComponent requires={[PRIVILEGES.DELETE_USERS]}>
            <Button
              color="link"
              onClick={() => {
                props.openDeleteModal(user.id);
              }}
              className={bem.e("action-button")}
            >
              <TrashListIcon />
            </Button>
          </PrivilegedComponent>
          {/* impesonate button */}
          {isShowImpersonateButton ? (
            <Button
              color="blue"
              onClick={() => {
                if (!user.is_active) {
                  ToastManager.show({
                    title: props.intl.formatMessage({
                      id: "toast > title error",
                    }),
                    message: props.intl.formatMessage({
                      id: "toast > message error please verify the account",
                    }),
                    level: "error",
                  });
                } else {
                  props.impersonateUser(user.id);
                }
              }}
              className={bem.e("action-button")}
            >
              <FaUserSecret color="#795AFA" size={20} />
            </Button>
          ) : null}
        </ButtonGroup>
      );
    },
  });
  const sortedActive = find(sorted, (item) => item.id === "order");
  columns.push({
    Header: (
      <div className={classnames(bem.e("head-active"), bem.e("th-sort"))}>
        <FormattedMessage id="user > active" />
        <div className={bem.e("sortable")}>
          <FaSortAsc
            className={classnames(bem.e("sortable-icon"), {
              [bem.e("sortable-active")]: sortedActive && !sortedActive.desc,
            })}
          />
          <FaSortDesc
            className={classnames(bem.e("sortable-icon"), {
              [bem.e("sortable-active")]: sortedActive && sortedActive.desc,
            })}
          />
        </div>
      </div>
    ),
    accessor: "order",
    Cell: ({ original: user }) => {
      const { email_invited_at, is_active, is_locked } = user;

      let isChecked = false;
      if (email_invited_at) {
        isChecked = !!is_active;
      } else {
        isChecked = selectedRowsId[user.id] || false;
      }
      return (
        <div className={bem.e("checkbox")}>
          <Checkbox
            checked={isChecked}
            disabled={!isAllowActive || is_locked}
            color={email_invited_at ? "blue" : "green"}
            onChange={(checked) => {
              if (!email_invited_at) {
                const selected = {
                  ...selectedRowsId,
                  [user.id]: checked,
                };
                setSelectedRowsId(selected);
              } else {
                props.onActiveUsers([user.id]);
              }
            }}
          />
          {!email_invited_at && (
            <span className={bem.e("new-label")}>
              <FormattedMessage id="user > new" />
            </span>
          )}
          {is_locked ? (
            <div
              className={bem.e("locked_icon")}
              onClick={() => {
                props.setUnlockUserId(user.id);
                props.setIsUnlockConifrmModalOpen(true);
              }}
            >
              <LockClosedIcon width={20} height={28} color="#FF006C" />
            </div>
          ) : (
            ``
          )}
        </div>
      );
    },
    minWidth: 50,
    style: {
      justifyContent: "flex-start",
      textAlign: "center",
    },
  });
  const renderInviteUserButton = () => {
    if (isServerList) {
      if (!auth.user.is_support_admin) {
        return (
          <div className={bem.e("user-list-buttons")}>
            <Button
              color=""
              onClick={() => props.onSelectedAdd({ new: {} })}
              className={bem.e("btn-new-user")}
            >
              <FaPlus color="#795AFA" size={20} />
              <FormattedMessage id="user > invite god admin" />
            </Button>
            {selectedNewUsers.length > 0 && isAllowActive && (
              <Button
                color="blue"
                onClick={() => {
                  props.setIsInviteModalOpen(true);
                }}
                className={classnames(bem.e("btn-invite-user"), "btn-radius")}
              >
                {props.intl.formatMessage(
                  {
                    id: "user > invite new users",
                  },
                  {
                    total: selectedNewUsers.length,
                  }
                )}
              </Button>
            )}
          </div>
        );
      }

      return null;
    }

    return (
      <div className={bem.e("user-list-buttons")}>
        <Button
          color=""
          onClick={() => props.onSelectedAdd({ new: {} })}
          className={bem.e("btn-new-user")}
        >
          <FaPlus color="#795AFA" size={20} />
          <FormattedMessage id="user > invite new user" />
        </Button>
        {selectedNewUsers.length > 0 && isAllowActive && (
          <Button
            color="blue"
            onClick={() => {
              props.setIsInviteModalOpen(true);
            }}
            className={classnames(bem.e("btn-invite-user"), "btn-radius")}
          >
            {props.intl.formatMessage(
              {
                id: "user > invite new users",
              },
              {
                total: selectedNewUsers.length,
              }
            )}
          </Button>
        )}
      </div>
    );
  };
  return (
    <>
      <Row>
        <Col xs="12">
          <div className={bem.e("head-admin-list-wrapper")}>
            <PrivilegedComponent requires={[PRIVILEGES.ADD_USERS]}>
              {renderInviteUserButton()}
            </PrivilegedComponent>
            <TextInput
              type="text"
              name="q"
              value={searchText}
              onChange={onChangeInput}
              onKeyDown={onSearchKeyDown}
              placeholder={props.intl.formatMessage({
                id: "user > placeholder search",
              })}
              rightComponent={
                <div className="search-buttons">
                  {searchText && (
                    <div
                      onClick={onClearSearch}
                      className={"button-search-close"}
                    >
                      <MdClose />
                    </div>
                  )}

                  <Button
                    color=""
                    className="btn-search"
                    onClick={onSearchClicked}
                  >
                    <FaSearch size={17} />
                  </Button>
                </div>
              }
            />
          </div>
        </Col>
      </Row>
      <Table
        className={bem.e("table")}
        manual
        data={userList}
        pages={pages}
        loading={users.loading}
        showPagination={false}
        total={total}
        // Controlled props
        filtered={filtered}
        sorted={sorted}
        // Callbacks
        onSortedChange={(sorted) => {
          setSorted(sorted);
        }}
        columns={columns}
        getTdProps={(state, rowInfo, column, instance) => {
          return {
            onClick: (e, handleOriginal) => {
              const { original } = rowInfo;
              const columnId = column.id;
              if (!includes(["actions", "order"], columnId)) {
                onClickItem(original);
              } else if (handleOriginal) {
                handleOriginal();
              }
            },
          };
        }}
      />
      <ConfirmationModal
        isOpen={props.isDeleteModalOpen}
        title={props.intl.formatMessage({
          id: "user > are you sure you want to remove user",
        })}
        onConfirm={props.onDelete}
        onToggle={props.closeDeleteModal}
        onCancel={props.closeDeleteModal}
        isCloseOutside={false}
      />
      <ConfirmationModal
        isOpen={props.isInviteModalOpen}
        title={props.intl.formatMessage(
          {
            id: "user > are you ready to invite {total} new users",
          },
          {
            total: selectedNewUsers.length,
          }
        )}
        onConfirm={() => props.onInviteUsers()}
        onToggle={() => props.setIsInviteModalOpen(!props.isInviteModalOpen)}
        onCancel={() => props.setIsInviteModalOpen(!props.isInviteModalOpen)}
        cancelTitle={props.intl.formatMessage({
          id: "user > button cancel",
        })}
        isCloseOutside={false}
      />
      <ConfirmationModal
        isOpen={props.isSuccessModalOpen}
        title={props.intl.formatMessage({
          id: "user > invite sent",
        })}
        state="success"
        onToggle={() => props.setIsSuccessModalOpen(!props.isSuccessModalOpen)}
        onCancel={() => props.setIsSuccessModalOpen(false)}
      />
      <ConfirmationModal
        isOpen={props.isUnlockConifrmModalOpen}
        title=""
        description={
          <div>
            <div className={bem.e("unlcok_confirm_text")}>
              Please confirm the user's password has been changed, then confirm
              your password to unlock their account.
            </div>
            <div>
              <TextInput
                type="password"
                name="q"
                value={props.currentUserPassword}
                onChange={(e) => {
                  props.setCurrentUserPassword(e.currentTarget.value);
                }}
                placeholder={props.intl.formatMessage({
                  id: "user > password",
                })}
              />
            </div>
          </div>
        }
        onConfirm={() => {
          props.onClickUnlock();
        }}
        confirmTitle={props.intl.formatMessage({
          id: "user > unlock user",
        })}
        isConfirmButtonDisabled={!props.currentUserPassword}
        cancelTitle={props.intl.formatMessage({
          id: "user > unlock user cancel",
        })}
        onCancel={() => {
          props.setIsUnlockConifrmModalOpen(false);
          props.setCurrentUserPassword("");
        }}
        isCloseOutside={false}
      />
      <BulkUploadUserModal
        isOpen={props.isBulkUploadUserModalOpen}
        onToggle={props.toggleBulkUploadUserModal}
        onCancel={() => props.setIsBulkUploadUserModalOpen(false)}
      />
      <Spinner isLoading={props.isLoading} />
    </>
  );
};
UserTableManual.propsType = {
  onSelectedEdit: PropTypes.func,
  onSelectedView: PropTypes.func,
  onSelectedAdd: PropTypes.func,
};
export default UserTableManual;
