import React from "react";
import { get } from "lodash";
import { FormattedMessage } from "react-intl";
import URL from "utils/urls";
import { isFunction } from "lodash";
import PropTypes from "prop-types";
import classnames from "classnames";
import history from "components/History";
import Pagination from "components/Pagination";
import { CardBody } from "reactstrap";
import Spinner from "components/Spinner";
import ButtonAdd from "components/ButtonAdd";
import SearchInput from "components/SearchInput";
import bn from "utils/bemnames";
import ResourcesTable from "./ResourcesTable";
import { pageSize } from "utils/config";
import Avatar from "../Avatar";
import { resourceCategoryList } from "utils/config";

const bem = bn.create("process-list");
export const MODE_IN_TAB = "mode_in_tab";
export const MODE_IN_SIDEBAR = "mode_in_sidebar";
class ResourceList extends React.Component {
  componentDidMount() {
    const filter = get(this.props, "filter");
    const sort = get(
      this.props.resources["filter_" + filter],
      "sort",
      "created_ts"
    );
    const page = get(this.props.resources["filter_" + filter], "page", "1");
    const rpp = get(this.props.resources["filter_" + filter], "rpp", pageSize);
    const search = get(this.props.resources["filter_" + filter], "search", "");
    const category = get(
      this.props.resources["filter_" + filter],
      "category",
      ""
    );

    let stations;
    if (
      filter === "grand_resources_list" ||
      filter === "qualifying_resources_list"
    ) {
      stations = get(this.props, "process.data.fields.order_stations.value", [])
        .map(station => station)
        .join(",");
    }

    if (filter) {
      if (
        !this.props.resources["filter_" + filter] ||
        this.props.resources["filter_" + filter].is_dirty
      ) {
        this.props.getResources({
          filter,
          sort,
          page,
          rpp,
          search,
          category,
          stations
        });
      }
    }
  }
  componentDidUpdate(prevProps) {
    const filter = get(this.props, "filter");
    let sort = get(
      this.props.resources["filter_" + filter],
      "sort",
      "created_ts desc"
    );
    let page = get(this.props.resources["filter_" + filter], "page", "1");
    let rpp = get(this.props.resources["filter_" + filter], "rpp", pageSize);
    let search = get(this.props.resources["filter_" + filter], "search", "");
    const category = get(
      this.props.resources["filter_" + filter],
      "category",
      ""
    );

    let stations;
    if (
      filter === "grand_resources_list" ||
      filter === "qualifying_resources_list"
    ) {
      stations = get(this.props, "process.data.fields.order_stations.value", [])
        .map(station => station)
        .join(",");
    }
    if (
      !this.props.resources["filter_" + filter] ||
      this.props.resources["filter_" + filter].is_dirty
    ) {
      this.props.getResources({
        filter,
        sort,
        page,
        rpp,
        search,
        category,
        stations
      });
    }
  }
  sort = field => {
    const filter = get(this.props, "filter");
    let sort = get(
      this.props.resources["filter_" + filter],
      "sort",
      "created_ts desc"
    );
    let page = get(this.props.resources["filter_" + filter], "page", "1");
    let rpp = get(this.props.resources["filter_" + filter], "rpp", pageSize);
    let search = get(this.props.resources["filter_" + filter], "search", "");
    const category = get(
      this.props.resources["filter_" + filter],
      "category",
      ""
    );
    let stations;
    if (
      filter === "grand_resources_list" ||
      filter === "qualifying_resources_list"
    ) {
      stations = get(this.props, "process.data.fields.order_stations.value", [])
        .map(station => station)
        .join(",");
    }
    // detect on third click
    if (sort === field + " desc") {
      sort = "created_ts desc";
    } else {
      sort = sort === field ? sort + " desc" : field;
    }
    this.props.getResources({
      filter,
      sort,
      page,
      rpp,
      search,
      category,
      stations
    });
  };
  search(event) {
    event.preventDefault();
    const filter = get(this.props, "filter");
    let sort = "search"; //get(this.props.resources['filter_' + filter], "sort", "created_ts desc");
    let page = get(this.props.resources["filter_" + filter], "page", "1");
    let rpp = get(this.props.resources["filter_" + filter], "rpp", pageSize);
    const category = get(
      this.props.resources["filter_" + filter],
      "category",
      ""
    );
    let search = event.target.elements[0].value;
    let stations;
    if (
      filter === "grand_resources_list" ||
      filter === "qualifying_resources_list"
    ) {
      stations = get(
        this.props,
        "process.data.fields.contract_stations.value",
        []
      )
        .map(station => station)
        .join(",");
    }
    this.props.getResources({
      filter,
      sort,
      page,
      rpp,
      search,
      category,
      stations
    });
  }
  onClearSearch = () => {
    const filter = get(this.props, "filter");
    let sort = get(
      this.props.resources["filter_" + filter],
      "sort",
      "created_ts desc"
    );
    let page = get(this.props.resources["filter_" + filter], "page", "1");
    let rpp = get(this.props.resources["filter_" + filter], "rpp", pageSize);
    const category = get(
      this.props.resources["filter_" + filter],
      "category",
      ""
    );
    let stations;
    if (
      filter === "grand_resources_list" ||
      filter === "qualifying_resources_list"
    ) {
      stations = get(
        this.props,
        "process.data.fields.contract_stations.value",
        []
      )
        .map(station => station)
        .join(",");
    }
    let search = "";
    this.props.getResources({
      filter,
      sort,
      page,
      rpp,
      search,
      category,
      stations
    });
  };
  usersDisplay(user_ids) {
    let users = user_ids.map(user_id =>
      this.props.auth.info.users.find(user => user.id === user_id)
    );
    return (
      <span>
        {users.map((user, index) => (
          <div key={index}>
            <Avatar src={user.avatar_url} size={20} className="mb-2" />
            {` `}
            {user.name}
          </div>
        ))}
      </span>
    );
  }

  actionDisplay(resource) {
    if (resource._source.last_action) {
      let payload = { message: resource._source.last_action };
      if (payload.message.chat) {
        return (
          <span>
            <Avatar src={get(payload, "message.from.avatar_url", "")} size={20} />
            {get(payload, "message.from.name", "")}
            <span>
              {" "}
              <FormattedMessage id="resource > wrote" />{" "}
            </span>
            <span>
              {` `}
              {payload.message.chat}
            </span>
          </span>
        );
      } else {
        return (
          <span>
            <Avatar src={get(payload, "message.from.avatar_url", "")} />
            {get(payload, "message.from.name", "")}
            <span>
              {" "}
              <FormattedMessage id="resource > submitted" />{" "}
            </span>
            <span>
              {` `}
              {payload.message.from_step &&
                payload.message.from_step.replace(/_/, " ", "gi") + "'"}
            </span>
          </span>
        );
      }
    }
    return null;
  }
  onClickRow = resource => {
    const { onSelectItem } = this.props;
    if (isFunction(onSelectItem)) {
      onSelectItem(resource);
    } else {
      const params = {
        template_id: resource._source.template_key,
        resources_id: resource._id
      };
      history.push(URL.VIEW_RESOURCES(params));
    }
  };

  onPageChange = currentPage => {
    const page = currentPage + 1;
    const filter = get(this.props, "filter");
    let sort = get(
      this.props.resources["filter_" + filter],
      "sort",
      "created_ts desc"
    );
    let rpp = get(this.props.resources["filter_" + filter], "rpp", pageSize);
    let search = get(this.props.resources["filter_" + filter], "search", "");
    const category = get(
      this.props.resources["filter_" + filter],
      "category",
      ""
    );
    let stations;
    if (
      filter === "grand_resources_list" ||
      filter === "qualifying_resources_list"
    ) {
      stations = get(this.props, "process.data.fields.order_stations.value", [])
        .map(station => station)
        .join(",");
    }
    this.props.getResources({
      filter,
      sort,
      page,
      rpp,
      search,
      category,
      stations
    });
  };

  onPageSizeChange = rpp => {
    const filter = get(this.props, "filter");
    let page = get(this.props.resources["filter_" + filter], "page", "1");
    let sort = get(
      this.props.resources["filter_" + filter],
      "sort",
      "created_ts desc"
    );
    let search = get(this.props.resources["filter_" + filter], "search", "");
    const category = get(
      this.props.resources["filter_" + filter],
      "category",
      ""
    );
    let stations;
    if (
      filter === "grand_resources_list" ||
      filter === "qualifying_resources_list"
    ) {
      stations = get(this.props, "process.data.fields.order_stations.value", [])
        .map(station => station)
        .join(",");
    }
    this.props.getResources({
      filter,
      sort,
      page,
      rpp,
      search,
      category,
      stations
    });
  };

  onTypeFilter = type => {
    const filter = get(this.props, "filter");
    let page = get(this.props.resources["filter_" + filter], "page", "1");
    let sort = get(
      this.props.resources["filter_" + filter],
      "sort",
      "created_ts desc"
    );
    let rpp = get(this.props.resources["filter_" + filter], "rpp", pageSize);
    let search = get(this.props.resources["filter_" + filter], "search", "");
    const category = get(
      this.props.resources["filter_" + filter],
      "category",
      ""
    );
    let stations;
    if (
      filter === "grand_resources_list" ||
      filter === "qualifying_resources_list"
    ) {
      stations = get(this.props, "process.data.fields.order_stations.value", [])
        .map(station => station)
        .join(",");
    }
    this.props.getResources({
      filter,
      sort,
      page,
      rpp,
      search,
      category: category === type ? "" : type, // toggle un check
      stations
    });
  };
  renderHeader = () => {
    const { filter, mode } = this.props;
    const category = get(
      this.props.resources["filter_" + filter],
      "category",
      ""
    );
    if (mode === MODE_IN_TAB) {
      return (
        <div className={bem.e("header-tab-resource-closet")}>
          <SearchInput
            placeholder={this.props.intl.formatMessage({
              id: "resources > placeholder search input"
            })}
            onClear={this.onClearSearch}
            onSubmit={this.search.bind(this)}
            value={get(this.props.resources["filter_" + filter], "search", "")}
          />
          <ul className={bem.e("prize-categories")}>
            {resourceCategoryList.map((item, index) => (
              <li
                key={index}
                className={classnames({
                  [bem.e("category-active")]: category === item.value
                })}
                onClick={() => this.onTypeFilter(item.value)}
              >
                {item.label}
              </li>
            ))}
          </ul>
        </div>
      );
    }
    return (
      <div className={bem.e("header")}>
        <div className={bem.e("title")}>
          <FormattedMessage id={`resources > title ${filter}`} />
        </div>
        <div className={bem.e("header-actions")}>
          {/* <PrivilegedComponent
              requires={[PRIVILEGES.ADD_resources]}> */}
          <ButtonAdd
            onClick={() => {
              history.push(URL.ADD_RESOURCES({
                template_id: "new_resource"
              }))
            }}
            children={<FormattedMessage id="resources > add new resources" />}
          />
          <SearchInput
            placeholder={this.props.intl.formatMessage({
              id: "resources > placeholder search input"
            })}
            onClear={this.onClearSearch}
            onSubmit={this.search.bind(this)}
            value={get(this.props.resources["filter_" + filter], "search", "")}
          />
        </div>
      </div>
    );
  };
  render() {
    const { resources, filter, user, users } = this.props;
    let sort = get(
      this.props.resources["filter_" + filter],
      "sort",
      "created_ts desc"
    );
    let pages = get(this.props.resources["filter_" + filter], "pages");
    let page = get(this.props.resources["filter_" + filter], "page", 1);
    let rpp = get(this.props.resources["filter_" + filter], "rpp", pageSize);
    const resourceList =
      get(resources, `filter_${filter}.data`, []) !== null
        ? get(resources, `filter_${filter}.data`, [])
        : [];
    if (resourceList.length === 0 && this.props.empty_component)
      return this.props.empty_component;
    return (
      <div className={classnames("fluid-container", bem.b())}>
        {this.renderHeader()}
        <div
          className={classnames(bem.e("process-list-container"), {
            [bem.e("no-padding")]: this.props.mode === MODE_IN_TAB
          })}
        >
          {this.props.full_message && resourceList.length > 0 && (
            <CardBody>{this.props.full_message}</CardBody>
          )}
          {this.props.empty_message && resourceList.length === 0 && (
            <CardBody>{this.props.empty_message}</CardBody>
          )}
          <ResourcesTable
            resourceList={resourceList}
            bem={bem}
            sort={sort}
            actionSort={this.sort}
            countdowns={this.props.countdowns}
            onClickRow={this.onClickRow}
            template={this.props.template}
            user={user}
            filter={filter}
            users={users}
            mode={this.props.mode}
            isLoading={get(resources, `filter_${filter}.loading`, false)}
          />
          {pages ? (
            <Pagination
              pages={Number(pages)}
              page={Number(page) - 1}
              pageSize={Number(rpp)}
              onPageSizeChange={this.onPageSizeChange}
              onPageChange={this.onPageChange}
            />
          ) : null}
        </div>
        <Spinner
          isLoading={
            !resourceList || get(resources, `filter_${filter}.loading`)
          }
        />
      </div>
    );
  }
}
ResourceList.propTypes = {
  mode: PropTypes.oneOf([MODE_IN_TAB, MODE_IN_SIDEBAR])
};
ResourceList.defaultProps = {
  mode: MODE_IN_SIDEBAR
};
export default ResourceList;
