import steps from "./steps";

const promotionContest = {
  _index: "templates",
  _type: "template",
  _id: "contest",
  _score: 1,
  _source: {
    key: "contest",
    templates_name: "Promotion Contest",
    templates_description: "Create a promotion contest",
    privileges: {},
    steps: steps,
    active: true,
    numberOfPlaysFor54321Theme: 5,
  },
};
export default promotionContest;
