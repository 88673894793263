import { FormattedMessage } from "react-intl";
import React from "react";
import {
  get,
  map,
  omit,
  sum,
  uniq,
  delay,
  find,
  // forEach,
  // uniqBy,
  isEmpty,
  includes,
  filter,
} from "lodash";
import history from "components/History";
import moment from "moment";
import { orderLengthOptions } from "utils/config";

const continuity = {
  process_step_index: 2,
  key: "continuity",
  title: <FormattedMessage id="process > title continuity" />,
  caption_color: "#0F99A2",
  description: "",
  asynchronous: true,
  isCollapsible: true,
  step_roles: [8, 7],
  step_viewers_roles: [8, 7, 4, 2, 9],
  step_editors_roles: [8, 7, 4],
  step_viewers_privileges: {
    or: ["perform_production_continuity", "edit_all_production_orders"],
  },
  step_editors_privileges: {
    or: ["perform_production_continuity", "edit_all_production_orders"],
  },
  status: "initial",
  className: "continuity-container",
  is_hidden: function(user, process, step) {
    return (
      process.data.fields.order_sold && !process.data.fields.order_sold.value
    );
  },
  workflow: {
    title: "continuity",
    icon: "ContinuityIcon",
  },
  is_approval_step: true,
  fields: [
    {
      component: "production_reschedule",
    },
    {
      component: "production_details",
      props: {
        isCollapse: true,
      },
    },
    /*{
      component: "production_assignments",
    },*/
    {
      component: "production_continuity_traffic_details",
    },
    {
      field: "order_title",
    },
    {
      field: "recording_path",
    },
    {
      field: "approve_recording_status",
    },
    {
      field: "record_script_status",
    },
    {
      field: "contract_status",
      title: <FormattedMessage id="process > field production type" />,
      props: {
        options: [
          {
            label: (
              <FormattedMessage id="process > option label production complete" />
            ),
            value: "Production Complete",
          },
          {
            label: (
              <FormattedMessage id="process > option label production provided needed tags" />
            ),
            value: "Production Provided - Needs tags",
          },
        ],
      },
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        return get(value, "length") > 0
          ? true
          : "Please select an order status";
      },
    },
    {
      field: "order_length_custom",
    },
    {
      field: "order_length",
      title: <FormattedMessage id="process > field length" />,
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        return value ? true : "Please enter a length";
      },
      props: {
        options: orderLengthOptions,
      },
    },
    {
      field: "role_dubber",
      title: <FormattedMessage id="process > field dubber" />,
      roles: [9],
      privileges: {
        or: ["perform_production_dubbing", "edit_all_production_orders"],
      },
      default: 9,
      // validation: function(
      //   step,
      //   item,
      //   process,
      //   template,
      //   user,
      //   value,
      //   process_key,
      //   extraData
      // ) {
      //   const contractStatus = get(
      //     process,
      //     "data.fields.contract_status.value",
      //     ""
      //   );
      //   // const spotInfo = get(process, "fields.spot_info.value", []).find(
      //   //   (spot) => spot.key === process_key
      //   // );
      //   const isProductionNeeded = contractStatus === "Production Needed";

      //   // const isEnableEnterprise = get(
      //   //   extraData,
      //   //   "production_multimarket",
      //   //   false
      //   // );
      //   if (isProductionNeeded) return true;
      //   if (get(value, "users.length") > 0) return true;
      //   return "Please select dubber";
      // },
      // mandatory: true,
    },
    {
      field: "role_producer",
      title: <FormattedMessage id="process > field producer" />,
      roles: [5],
      privileges: {
        or: ["produce_production", "edit_all_production_orders"],
      },
      // mandatory: true,
      default: 5,
      // validation: function(
      //   step,
      //   item,
      //   process,
      //   template,
      //   user,
      //   value,
      //   process_key,
      //   extraData
      // ) {
      //   // const isEnableEnterprise = get(
      //   //   extraData,
      //   //   "production_multimarket",
      //   //   false
      //   // );
      //   const contractStatus = get(process, "fields.contract_status.value", "");
      //   // const spotInfo = get(process, "fields.spot_info.value", []).find(
      //   //   (spot) => spot.key === process_key
      //   // );
      //   const isProductionComplete = contractStatus === "Production Complete";
      //   const isProductionNeeded = contractStatus === "Production Needed";
      //   if (isProductionComplete || isProductionNeeded) return true;
      //   return get(value, "users.length") > 0
      //     ? true
      //     : "Please select a producer";
      // },
    },
    {
      field: "spot_info",
      validation: function(
        step,
        item,
        process,
        template,
        user,
        value,
        process_key,
        extraData
      ) {
        // const isEnableEnterprise = get(
        //   extraData,
        //   "production_multimarket",
        //   false
        // );
        // if (isEnableEnterprise) return true;

        const availableLineNumbers = get(
          process,
          "fields.available_line_numbers.value",
          []
        );
        const showLineNumber = !isEmpty(availableLineNumbers);

        let isValid = true;

        let validProductionProvidedSpots = [];
        let validProductionNeededSpots = [];
        let isDirtyProductionProvided = false;

        const totalProductionProvidedSpots = filter(
          value,
          (spot) => spot.template_key === "production_provided"
        ).length;

        const spotInfo = map(value, (spot, index) => {
          let error = {};
          if (!spot["title"]) {
            isValid = false;
            error.title = "Order title is required";
          }
          if (get(user, "enable_wo_traffic", false) && !spot["isci"]) {
            isValid = false;
            error.isci = "You must enter an ISCI/AD id";
          }

          let spotInfoDirtyFields = get(
            process,
            "fields.spot_info_fields_dirty.value",
            []
          );
          const cartNumberEdited =
            spotInfoDirtyFields &&
            includes(spotInfoDirtyFields[index], "cart_number");

          if (
            (!get(user, "enable_wo_traffic", false) || cartNumberEdited) &&
            !spot["cart_number"]
          ) {
            isValid = false;
            error.cart_number = "You must enter a cart number";
          }

          if (
            !get(user, "enable_wo_traffic", false) &&
            get(user, "enable_wo_dubbing", false) &&
            !spot["cart_prefix"]
          ) {
            isValid = false;
            error.cart_prefix = "You must select an aduio source prefix";
          }
          if (!spot["spot_type"]) {
            isValid = false;
            error.spot_type = "Please select a spot type";
          }
          if (
            !spot["recording_path"] &&
            spot["contract_status"] !== "Production Needed"
          ) {
            isValid = false;
            error.recording_path = "Please upload the recording";
          }

          if (
            !spot["role_producer"] &&
            spot["contract_status"] === "Production Provided - Needs tags"
          ) {
            isValid = false;
            error.role_producer = "Please select a producer";
          }

          if (
            !get(spot, "role_dubber.users.length", 0) &&
            [
              "Production Provided - Needs tags",
              "Production Complete",
            ].includes(spot["contract_status"])
          ) {
            isValid = false;
            error.role_dubber = "Please select a dubber";
          }

          if (spot["spot_type"] === "Book-ends" && !spot["recording_path_b"]) {
            isValid = false;
            error.recording_path_b = "Please upload the recording";
          }

          if (
            get(user, "enable_wo_traffic", false) &&
            !spot["material_group_name"]
          ) {
            isValid = false;
            error.material_group_name = "You must select a material group";
          }

          if (showLineNumber && isEmpty(spot["line_hashes"])) {
            isValid = false;
            error.line_hashes = "Please select a line #";
          }
          if (
            spot.template_key === "production_provided" &&
            Object.keys(error).length === 0
          ) {
            validProductionProvidedSpots.push(spot);
          }
          if (
            spot.template_key === "basic_production" &&
            Object.keys(error).length === 0
          ) {
            validProductionNeededSpots.push(spot);
          }
          if (
            spot.template_key === "production_provided" &&
            !isDirtyProductionProvided
          ) {
            isDirtyProductionProvided = !!get(spot, "cart_number", "");
          }
          return { ...error, template_key: spot.template_key };
        });
        if (isValid) return true;
        //  the concept here is to treat each spot independently in the continuity page, so you can move spots forward independently of the other spots being ready.
        // ALL Prod Provided spots need to be validated at the same time
        if (
          isDirtyProductionProvided &&
          validProductionProvidedSpots.length !== totalProductionProvidedSpots
        ) {
          return spotInfo.map((error) => {
            // don't need to show validation error for production needed
            if (
              error.template_key === "basic_production" &&
              Object.keys(error).length > 0
            ) {
              return {};
            }
            const { template_key, ...rest } = Object.assign({}, error);
            return rest;
          });
        }
        if (
          totalProductionProvidedSpots > 0 &&
          validProductionProvidedSpots.length === totalProductionProvidedSpots
        ) {
          return true;
        }
        if (validProductionNeededSpots.length > 0) return true;
        const spotErrors = spotInfo.map((error) => {
          const { template_key, ...rest } = Object.assign({}, error);
          return rest;
        });
        return spotErrors;
      },
      mandatory: true,
    },
    {
      field: "rotation_chart",
      validation: function(
        step,
        item,
        process,
        template,
        user,
        value,
        process_key,
        extraData
      ) {
        let isValid = true;
        const rotationChart = map(value, (rotation) => {
          const rotationSpot = Object.values(omit(rotation, ["station"]));
          const total = sum(rotationSpot);
          if (total < 99 || total > 100) {
            isValid = false;
            return "Spot rotation percentages must add up to 100%";
          }
          return "";
        });
        if (isValid) return true;
        return rotationChart;
      },
      mandatory: true,
    },
    {
      field: "continuity_cart",
    },
    {
      field: "production_asset_audit",
    },
    {
      field: "production_asset_b_audit",
    },
    {
      field: "markets",
      // validation: function(
      //   step,
      //   item,
      //   process,
      //   template,
      //   user,
      //   value,
      //   process_key,
      //   extraData
      // ) {
      //   // const isEnableEnterprise = get(
      //   //   extraData,
      //   //   "production_multimarket",
      //   //   false
      //   // );
      //   // if (!isEnableEnterprise) return true;
      //   let isValid = true;
      //   const markets = map(value, (market) => {
      //     let marketError = {};
      //     const spotInfo = map(market.spot_info, (spot, index) => {
      //       let error = {};
      //       const availableLineNumbers = get(
      //         process,
      //         "fields.available_line_numbers.value",
      //         []
      //       );
      //       const showLineNumber = !isEmpty(availableLineNumbers);

      //       if (get(user, "enable_wo_traffic", false) && !spot["isci"]) {
      //         isValid = false;
      //         error.isci = "You must enter an ISCI/AD id";
      //       }
      //       if (!spot["spot_type"]) {
      //         isValid = false;
      //         error.spot_type = "Please select a spot type";
      //       }

      //       let spotInfoDirtyFields = get(
      //         process,
      //         "fields.spot_info_fields_dirty.value",
      //         []
      //       );
      //       const cartNumberEdited =
      //         spotInfoDirtyFields &&
      //         includes(spotInfoDirtyFields[index], "cart_number");

      //       if (
      //         (!get(user, "enable_wo_traffic", false) || cartNumberEdited) &&
      //         !spot["cart_number"]
      //       ) {
      //         isValid = false;
      //         error.cart_number = "You must enter a cart number";
      //       }

      //       if (
      //         !get(user, "enable_wo_traffic", false) &&
      //         get(user, "enable_wo_dubbing", false) &&
      //         !spot["cart_prefix"]
      //       ) {
      //         isValid = false;
      //         error.cart_prefix = "You must select an aduio source prefix";
      //       }
      //       if (!spot["spot_type"]) {
      //         isValid = false;
      //         error.spot_type = "Please select a spot type";
      //       }
      //       if (showLineNumber && isEmpty(spot["line_hashes"])) {
      //         isValid = false;
      //         error.line_hashes = "Please select a line #";
      //       }
      //       if (
      //         get(user, "enable_wo_traffic", false) &&
      //         !spot["material_group_name"]
      //       ) {
      //         isValid = false;
      //         error.material_group_name = "You must select a material group";
      //       }
      //       if (
      //         get(spot, "contract_status", "") === "Production Complete" &&
      //         !spot["recording_path"]
      //       ) {
      //         isValid = false;
      //         error.recording_path = "Please upload the recording";
      //       }
      //       if (
      //         spot["spot_type"] === "Book-ends" &&
      //         !spot["recording_path_b"]
      //       ) {
      //         isValid = false;
      //         error.recording_path_b = "Please upload the recording";
      //       }
      //       return error;
      //     });
      //     marketError = {
      //       spot_info: spotInfo,
      //     };
      //     if (market["rotation_chart"]) {
      //       const rotationChart = map(market["rotation_chart"], (rotation) => {
      //         const rotationSpot = Object.values(omit(rotation, ["station"]));
      //         if (sum(rotationSpot) < 99 || sum(rotationSpot) > 100) {
      //           isValid = false;
      //           return "Spot rotation percentages must add up to 100%";
      //         }
      //         return "";
      //       });
      //       if (rotationChart) {
      //         marketError = {
      //           ...marketError,
      //           rotation_chart: rotationChart,
      //         };
      //       }
      //     }
      //     return marketError;
      //   });

      //   if (isValid) return true;
      //   return markets;
      // },
      // mandatory: true,
    },
    {
      text: <FormattedMessage id="process > button submit" />,
      component: "button",
      props: { color: "blue" },
      key: "save_button",
      container: "bottom_buttons",
      fields: [
        {
          key: "continuity_status",
          // "value": "submitted"
        },
        {
          key: "process_step",
          value: "first_undone",
        },
        {
          key: "spot_info",
          value: function(user, template, process, step, item) {
            // marked continuity_status to each spot info
            let spotInfo = map(
              get(process, "data.fields.spot_info.value", []),
              (spot) => ({
                ...spot,
                continuity_status: "completed",
              })
            );
            return spotInfo;
          },
        },
        {
          key: "markets",
          value: function(
            user,
            template,
            process,
            step,
            item,
            next_users,
            extraData
          ) {
            const enableEnterprise = get(
              extraData,
              "production_multimarket",
              false
            );
            if (!enableEnterprise)
              return get(process, "data.fields.markets.value", []);
            // marked continuity_completed_at to market
            const markets = map(
              get(process, "data.fields.markets.value", []),
              (market) => {
                const isContinuityAssigned = find(
                  get(market, "role_continuity.users", []),
                  (item) => Number(item.id) === Number(user.id)
                );
                if (isContinuityAssigned) {
                  return {
                    ...market,
                    continuity_completed_at: moment().unix(),
                    continuity_status: "completed",
                  };
                }
                return market;
              }
            );
            return markets;
          },
        },
        {
          key: "continuity_cart",
          value: function(user, template, process, step, item) {
            // map continuity cart
            let continuityCart = map(
              get(process, "data.fields.spot_info.value", []),
              (spot) => spot.cart_number
            );
            continuityCart = uniq(continuityCart).join(",");
            return continuityCart;
          },
        },
      ],
      // recomputeFields: function(user, template, process, step, extraData) {
      //   const formValueChanged = get(extraData, "formValueChanged");
      //   if (!get(extraData, "production_multimarket") || !formValueChanged) {
      //     return process;
      //   }
      //   const spotInfo = get(process, "data.fields.spot_info.value", []).find(
      //     (spot) => spot.key === process.key
      //   );
      //   const isProductionProvidedNeededTags =
      //     get(spotInfo, "contract_status", "") ===
      //     "Production Provided - Needs tags";
      //   // sync all producers from all market to order
      //   let producers = [];
      //   let dubbers = [];
      //   forEach(get(process, "data.fields.markets.value", []), (market) => {
      //     producers = [...producers, ...get(market, "role_producer.users", [])];
      //     dubbers = [...dubbers, ...get(market, "role_dubber.users", [])];
      //   });
      //   producers = uniqBy(producers, "id");
      //   dubbers = uniqBy(dubbers, "id");
      //   if (isProductionProvidedNeededTags) {
      //     process.data.fields.role_producer = {
      //       value: {
      //         users: producers,
      //       },
      //       is_dirty: true,
      //     };
      //   }
      //   process.data.fields.role_dubber = {
      //     value: {
      //       users: dubbers,
      //     },
      //     is_dirty: true,
      //   };
      //   return process;
      // },
      on_click: {
        validate: true,
        save: true,
        go_to: function(_this, step, item, process, template, user) {
          if (!user.return_to_current_tasks || item.is_keep_on) return;
          // set delay when after success message
          delay(() => {
            if (_this.props.setSidebarActive) {
              _this.props.setSidebarActive(`/processes/my_action_items`);
            }
            history.replace(`/processes/my_action_items`);
          }, 4000);
        },
      },
      success_dialog_props: function(user, template, process, step, item) {
        return {
          title: <FormattedMessage id="process > title submitted" />,
          state: "success",
        };
      },
    },
  ],
};
export default continuity;
