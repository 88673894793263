import React from "react";
import Select, { components } from "react-select";
import moment from "moment-timezone";
import classnames from "classnames";
import bn from "utils/bemnames";
import {
  CaretIcon,
  CloseTagDropdownIcon,
  CloseSmallIcon,
} from "components/CustomIcons";
const bem = bn.create("dropdown");

const currentZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const currentCountry = currentZone && currentZone.split("/")[0];

const TimezonePicker = ({ ...rest }) => {
  const listTimezones = moment.tz.names();
  const options = listTimezones
    .sort((a, b) => {
      if (currentZone && currentZone === a) {
        return -1;
      }
      if (
        currentCountry &&
        a.includes(currentCountry) &&
        !b.includes(currentCountry)
      ) {
        return -1;
      }
      return 0;
    })
    .map((item) => ({
      value: item,
      label: item,
    }));
  let selected;
  if (!rest.value) {
    // selected = currentZone;
  } else {
    selected = rest.value;
  }
  let configComponents = {};
  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <CaretIcon />
        </components.DropdownIndicator>
      )
    );
  };
  configComponents.DropdownIndicator = DropdownIndicator;
  const ClearIndicator = (props) => {
    return (
      components.ClearIndicator && (
        <components.ClearIndicator {...props}>
          <CloseSmallIcon />
        </components.ClearIndicator>
      )
    );
  };
  configComponents.ClearIndicator = ClearIndicator;
  const MultiValueRemove = (props) => {
    return (
      components.MultiValueRemove && (
        <components.MultiValueRemove {...props}>
          <CloseTagDropdownIcon />
        </components.MultiValueRemove>
      )
    );
  };
  configComponents.MultiValueRemove = MultiValueRemove;
  return (
    <Select
      {...rest}
      value={
        selected
          ? {
              label: selected,
              value: selected,
            }
          : null
      }
      options={options}
      className={classnames(
        "react-select-container",
        bem.b(),
        rest.className && rest.className.toString(),
        {
          "react-select-menu-open": rest.isMenuOpen,
        }
      )}
      classNamePrefix="react-select"
      components={configComponents}
      onMenuOpen={() => rest.setIsMenuOpen(true)}
      onMenuClose={() => rest.setIsMenuOpen(false)}
      styles={{
        // Fixes the overlapping problem of the component
        menu: (provided) => ({ ...provided, zIndex: 9999 }),
      }}
    />
  );
};

export default TimezonePicker;
