import React from "react";
import bn from "utils/bemnames";
import { FormattedMessage } from "react-intl";
import { ReviewGuide } from "components/CustomIcons";
import { Button } from "reactstrap";
import { get } from "lodash";
import classNames from "classnames";
const bem = bn.create("client-review");
function GuideReview(props) {
  const { process, setIsShowGuide } = props;
  const clientName = get(process, "data.fields.client_name.value");
  return (
    <div className={bem.e("guide-container")}>
      <h2 className={bem.e("guide-title")}>
        <FormattedMessage id="process > you're about to review artwork for" />
      </h2>
      <p className={bem.e("guide-client-name")}>{clientName}</p>
      <p className={bem.e("guide-description")}>
        <strong>Approve</strong> or <strong>reject</strong> all artwork to
        submit your feedback. <br/>Feel free to pin comments directly to each piece
        of artwork by clicking the <br/>pin icon then dropping it where you’d like to
        leave your comment.
      </p>
      <div className={bem.e("guide-image")}>
        <ReviewGuide />
      </div>
      <Button
        outline
        color="blue"
        onClick={() => setIsShowGuide(false)}
        className={classNames("btn btn-radius", bem.e("guide-button"))}
      >
        <FormattedMessage id="process > i got it.Let's go!" />
      </Button>
    </div>
  );
}
export default GuideReview;
