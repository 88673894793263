import HandleAssets from "./view";
import axios from "axios";
import { compose, withHandlers, withState } from "recompose";
import { convertUrlHttps, download } from "components/UploadFile/helper";
import { injectIntl } from "react-intl";
export default injectIntl(
  compose(
    withState("isOpenPreviewModal", "setIsOpenPreviewModal", false),
    withState("currentPreviewFile", "setCurrentPreviewFile", null),
    withState("isRename", "setIsRename", false),
    withState("error", "setError", null),
    withHandlers({
      onRenameFile: ({
        setCurrentPreviewFile,
        setIsOpenPreviewModal,
        setIsRename,
      }) => (file) => {
        setCurrentPreviewFile(file);
        setIsOpenPreviewModal(true);
        setIsRename(true);
      },
      onViewFile: ({
        setCurrentPreviewFile,
        setIsOpenPreviewModal,
        setIsRename,
      }) => (file) => {
        setCurrentPreviewFile(file);
        setIsOpenPreviewModal(true);
        setIsRename(false);
      },
      onTogglePreviewModal: ({
        isOpenPreviewModal,
        setIsOpenPreviewModal,
      }) => () => {
        setIsOpenPreviewModal(!isOpenPreviewModal);
      },
      onCancelPreviewModal: ({ setIsOpenPreviewModal }) => () => {
        setIsOpenPreviewModal(false);
      },
      onDeleteFile: ({ files, onDeleted }) => async (index) => {
        const newFiles = [...files];
        newFiles.splice(index, 1);
        if (onDeleted) {
          onDeleted(newFiles);
        }
      },
      onDownloadFile: ({ setError }) => async (file) => {
        const { path } = file;
        axios({
          url: convertUrlHttps(path) + "?ts=" + (new Date() - 0),
          method: "GET",
          responseType: "blob",
        })
          .then((response) => {
            const { data } = response;
            if (data) {
              const fileName = path.replace(/^.*[\\/]/, "");
              download(data, fileName);
            } else {
              setError(`Can't download`);
            }
          })
          .catch((e) => {
            const { message } = e;
            setError(message);
          });
      },
    })
  )(HandleAssets)
);
