import { connect } from "react-redux";
import {
  compose,
  withHandlers,
  withState,
  lifecycle,
  withPropsOnChange,
} from "recompose";
import {
  isFunction,
  find,
  findIndex,
  flatten,
  get,
  first,
  values,
} from "lodash";
import moment from "moment";
import {
  getJockSchedule,
  getJockSchedules,
  updateJockSchedule,
  showDisplaySettingsModal,
  hideDisplaySettingsModal,
  getJockConsoleDisplaySettings,
  setStationDisplaySettingsModal,
  setJockSchedule,
  getJockSchedulesFloatingContest,
} from "store/actions/jocks";

import { toggleRightPanel } from "store/actions/auth";

import {
  addValueForStandardPrize,
  markWinOrLoss,
  pickPrizeForWinner,
  pickGrandPrizeWinner,
  createFloatingContestPlay,
} from "store/actions/winners";

import View from "./view";
import { injectIntl } from "react-intl";

const Enhanced = compose(
  connect(
    (state, props) => {
      const user = get(state, "auth.user");
      const schedules = get(state, "jocks.schedules");
      const update = get(state, "jocks.update");
      const schedule = get(state, "jocks.schedule");
      const displaySettings = get(state, "jocks.displaySettings.data", {});
      const markWinOrLoss = get(state, "winners.markWinLoss", {});
      const pickPrize = get(state, "winners.pickPrizeForWinner", {});
      const addUnitPrize = get(state, "winners.addUnitPrizeForWinner", {});
      const pickGrandPrizeWinner = get(
        state,
        "winners.pickGrandPrizeWinner",
        {}
      );
      const openRightPanel = get(state, "auth.openRightPanel");

      const loadingFlags = [
        schedules.loading,
        update.loading,
        schedule.loading,
        markWinOrLoss.loading,
        pickPrize.loading,
        addUnitPrize.loading,
        pickGrandPrizeWinner.loading,
      ];
      const loading = loadingFlags.filter(Boolean).length > 0;
      return {
        user,
        loading,
        schedules,
        stations: get(state, "auth.info.stations", []),
        selectedSchedule: schedule.data,
        isDisplaySettingsModalOpen: get(
          state,
          "jocks.displaySettings.is_visible",
          false
        ),
        displaySettings,
        openRightPanel,
        ...props,
      };
    },
    {
      getJockSchedule,
      getJockSchedules,
      getJockSchedulesFloatingContest,
      updateJockSchedule,
      showDisplaySettingsModal,
      hideDisplaySettingsModal,
      getJockConsoleDisplaySettings,
      setStationDisplaySettingsModal,
      setJockSchedule,
      markWinOrLoss,
      pickPrizeForWinner,
      addValueForStandardPrize,
      pickGrandPrizeWinner,
      toggleRightPanel,
      createFloatingContestPlay,
    }
  ),
  withState("isOpenMultiViewModal", "setIsOpenMultiViewModal", false),
  withState("selectedStation", "setSelectedStation", ""),
  withState("selectedType", "setSelectedType", "hour"),
  withState("selectedDate", "setSelectedDate", new Date()),
  withState("currentWidget", "setCurrentWidget", null),
  withState("isOpenConfirmationModal", "setIsOpenConfirmationModal", false),
  withState("selectedDoneSchedule", "setSelectedDoneSchedule", null),
  withState("currentAction", "setCurrentAction", ""),
  withPropsOnChange(
    ["selectedSchedule"],
    ({
      setStationDisplaySettingsModal,
      setSelectedStation,
      setCurrentWidget,
      selectedSchedule,
      stations,
    }) => {
      const station = find(
        stations,
        (item) => item.key === get(selectedSchedule, "schedule.station_id")
      );
      const stationId = get(station, "id", "");
      setSelectedStation(stationId);
      setStationDisplaySettingsModal(stationId);
      setCurrentWidget(null);
    }
  ),
  withHandlers({
    onSelectSchedule: ({ setJockSchedule }) => (item) => {
      setJockSchedule(item);
    },
  }),
  withHandlers({
    onCreateFloatingPlay: ({
      createFloatingContestPlay,
      selectedDate,
      selectedSchedule,
      onSelectSchedule,
    }) => (cb) => {
      const schedule = selectedSchedule ? selectedSchedule.schedule : null;
      if (!schedule) return;
      createFloatingContestPlay(
        {
          contest_id: schedule.process_id,
          tz: moment.tz.guess(),
          datetime: moment(selectedDate).format("YYYY-MM-DD HH:mm:ss"),
        },
        (response) => {
          onSelectSchedule({
            ...selectedSchedule,
            schedule: response,
          });
          if (cb) cb(response);
        }
      );
    },
  }),
  withHandlers({
    fetchJockSchedules: ({
      getJockSchedules,
      getJockSchedulesFloatingContest,
      selectedDate,
      selectedType,
    }) => () => {
      const formattedSelectedDate = moment(selectedDate).format("YYYY-MM-DD");
      const params = { date: formattedSelectedDate, type: selectedType };
      getJockSchedules(params);
      getJockSchedulesFloatingContest(params);
    },
    toggleDisplaySettingsModal: ({
      isDisplaySettingsModalOpen,
      showDisplaySettingsModal,
      hideDisplaySettingsModal,
    }) => () => {
      isDisplaySettingsModalOpen
        ? hideDisplaySettingsModal()
        : showDisplaySettingsModal();
    },
    onMarkWinOrLoss: ({ getJockSchedule, markWinOrLoss }) => (payload) => {
      markWinOrLoss(payload, () => {
        getJockSchedule({ id: payload.event_id });
      });
    },
    onAaddValueForStandardPrize: ({ addValueForStandardPrize }) => (
      payload,
      cb
    ) => {
      addValueForStandardPrize(payload, () => {
        if (isFunction(cb)) cb();
      });
    },
    onPickPrize: ({
      getJockSchedule,
      pickPrizeForWinner,
      selectedDoneSchedule,
      setJockSchedule,
      setSelectedDoneSchedule,
    }) => (payload) => {
      pickPrizeForWinner(payload, () => {
        getJockSchedule({ id: payload.event_id });
        if (selectedDoneSchedule) {
          setJockSchedule(selectedDoneSchedule);
          setSelectedDoneSchedule(null);
        }
      });
    },
    onUpdateJockSchedule: ({ getJockSchedule, updateJockSchedule }) => (
      payload,
      cb
    ) => {
      updateJockSchedule(payload, () => {
        getJockSchedule({ id: payload.id }, (data) => {
          if (isFunction(cb)) cb(data);
        });
      });
    },
    onPickGrandPrizeWinner: ({ getJockSchedule, pickGrandPrizeWinner }) => (
      payload
    ) => {
      pickGrandPrizeWinner(payload, () => {
        getJockSchedule({ id: payload.event_id });
      });
    },
  }),
  lifecycle({
    componentDidMount() {
      const {
        fetchJockSchedules,
        getJockConsoleDisplaySettings,
        stations,
        selectedStation,
        setSelectedStation,
      } = this.props;
      fetchJockSchedules();
      getJockConsoleDisplaySettings();
      if (!selectedStation) {
        setSelectedStation(get(first(stations), "id", ""));
      }
    },
    componentDidUpdate(prevProps) {
      const {
        fetchJockSchedules,
        selectedDate,
        selectedSchedule,
        setJockSchedule,
        stations,
        setCurrentWidget,
        displaySettings,
        currentWidget,
        loading,
        schedules,
        selectedStation,
        selectedType,
      } = this.props;
      if (
        prevProps.selectedDate !== selectedDate ||
        prevProps.selectedType !== selectedType
      ) {
        fetchJockSchedules();
      }
      if (prevProps.loading && !loading && selectedSchedule) {
        const allSchedules = flatten(values(schedules.data));
        const index = findIndex(
          allSchedules,
          ({ id }) => id === selectedSchedule.schedule.id
        );
        if (index === -1) {
          setJockSchedule(null);
        }
      }
      if (!currentWidget && (selectedSchedule || selectedStation)) {
        const station = find(stations, (item) =>
          item.key === selectedSchedule
            ? get(selectedSchedule, "schedule.station_id")
            : selectedStation
        );
        if (station) {
          const widgetSettings = get(
            displaySettings,
            `widget_settings.${station.id}`
          );
          if (widgetSettings) {
            const widget = find(
              widgetSettings.widgets,
              (item) => item.id === widgetSettings.active_widgets[0]
            );
            if (widget) {
              setCurrentWidget(widget);
            }
          }
        }
      }
    },
  })
)(View);

export default injectIntl(Enhanced);
