import React from "react";
import PropTypes from "prop-types";
import bn from "utils/bemnames";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import { IconsConfig } from "components/ChannelManager/IconSelector";
import { UpIcon, DownIcon, ApproveIcon } from "components/CustomIcons";
import CustomFormTraffic from "components/CustomFormTraffic";
import { find, get, includes } from "lodash";
import PreviewMediaModal from "components/PreviewMediaModal";
import Spinner from "components/Spinner";
import moment from "moment";
import { Button, Col, Row } from "reactstrap";
import ConfirmationModal from "components/ConfirmationModal";
const bem = bn.create("media-channel");

function generateStatus(channel, dateFormatByServer) {
  if (get(channel, "completed_ts")) {
    return (
      <div className={bem.e("head-status")}>
        <span className={bem.e("head-status-label")}>
          <FormattedMessage id="channel manager > status" />:
        </span>
        <span className={bem.e("head-status-value")}>
          <FormattedMessage id="process > completed" />{" "}
          {moment
            .unix(get(channel, "completed_ts"))
            .format(dateFormatByServer)
            .toString()}
        </span>
      </div>
    );
  }
  let title = get(channel, "channel_step");
  let color = "#6C08CE";
  switch (get(channel, "channel_step")) {
    case "design":
      title = "PRODUCTION";
      break;
    case "review_media":
      title = "IN REVIEW";
      break;
    case "traffic":
      title = "DIGITAL TRAFFIC";
      color = "#129AA3";
      break;
    case "report":
      title = "REPORT";
      color = "#2B85FF";
      break;
    default:
      break;
  }

  if (title) {
    return (
      <div className={bem.e("head-status")}>
        <span className={bem.e("head-review")} style={{ color }}>
          {title}
        </span>
      </div>
    );
  }

  return null;
}

function MediaChannel(props) {
  const {
    channel,
    isCollapse,
    setIsCollapse,
    currentPreviewMediaModalOpen,
    setCurrentPreviewMediaModalOpen,
    downloadAllMedia,
    isLoading,
    user,
    onSubmit,
    onValueChanged,
    idx,
    validationState,
    onSave,
    setShouldBlockNavigation,
    dateFormatByServer
  } = props;
  const isView = document.location.href.indexOf("/view/") !== -1;
  const channelIcon = get(channel, "channel_icon");
  let ChannelIcon = IconsConfig[channelIcon]
    ? IconsConfig[channelIcon]
    : IconsConfig["default"];

  const hasChannelManagementAccess = !!find(channel.channel_managers, {
    value: user.id,
  });

  const isShowInstructions =
    channel.channel_step === "traffic" ||
    channel.channel_step === "report" ||
    channel.channel_step === "online";

  function generateDoneButton({ channel, onSubmit, isView }) {
    if (
      !includes(["traffic", "online", "report", "done"], channel.channel_step)
    )
      return null;

    if (!channel.completed_ts) {
      if (!hasChannelManagementAccess) {
        return null;
      }

      return (
        <Button
          color="blue"
          outline
          className="btn btn-radius"
          onClick={() => onSubmit("done")}
          disabled={isView}
        >
          <FormattedMessage id="process > button traffic" />
        </Button>
      );
    }
    return (
      <div className={bem.e("trafficked-content")}>
        <span className={bem.e("trafficked-at")}>
          {moment
            .unix(get(channel, "completed_ts"))
            .format(`${dateFormatByServer} HH:ss a`)
            .toString()}
        </span>
        <Button
          color=""
          className={classnames("btn btn-radius", bem.e("btn-trafficked"))}
          onClick={() => {}}
          disabled={isView}
        >
          <span className={bem.e("trafficked-icon")}>
            <ApproveIcon color="#fff" />
          </span>
          <FormattedMessage id="process > button trafficked" />
        </Button>
        {hasChannelManagementAccess && (
          <div
            onClick={() => !isView && onSubmit("undo")}
            className={bem.e("undo-link")}
          >
            <FormattedMessage id="process > button undo" />
          </div>
        )}
      </div>
    );
  }

  const renderView = () => (
    <div className={bem.e("media-view")}>
      {isShowInstructions && (
        <Row>
          <Col xs={8}>
            <div className={bem.e("instructions")}>
              <span className={bem.e("instructions-text")}>
                <FormattedMessage id="process > instructions" />
              </span>
              <div className={bem.e("instructions-actions")}>
                <span className={bem.e("instructions-description")}>
                  <FormattedMessage id="process > traffic instructions helper text" />
                </span>
              </div>
            </div>
          </Col>
          <Col xs={4}>
            <div className={bem.e("manager-actions")}>
              {generateDoneButton({
                channel,
                onSubmit,
                process,
                isView,
              })}
            </div>
          </Col>
        </Row>
      )}

      <div className={bem.e("custom-form-view")}>
        <CustomFormTraffic
          channel={channel}
          downloadAllMedia={downloadAllMedia}
          setIsOpenPreviewMediaModal={setCurrentPreviewMediaModalOpen}
          onValueChanged={onValueChanged}
          idx={idx}
          validationState={validationState}
          isShowPlacementConfirmation={hasChannelManagementAccess}
          isView={isView}
          onSave={onSave}
          setShouldBlockNavigation={setShouldBlockNavigation}
        />
      </div>
    </div>
  );
  return (
    <div className={bem.b()}>
      <div className={bem.e("wrapper")}>
        <div
          className={bem.e("form-header")}
          onClick={() => {
            setIsCollapse(!isCollapse);
          }}
        >
          <span className={bem.e("channel-icon")}>
            <ChannelIcon />
          </span>
          <h4 className={bem.e("title")}>{get(channel, "name", "")}</h4>
          <div className={bem.e("head-right")}>
            {generateStatus(channel, dateFormatByServer)}

            {isCollapse ? (
              <span className={bem.e("button-up-icon")}>
                <UpIcon color="#795AFA" />
              </span>
            ) : (
              <span className={bem.e("button-down-icon")}>
                <DownIcon color="#795AFA" />
              </span>
            )}
          </div>
        </div>
        <div
          className={classnames(bem.e("form-content"), {
            [bem.e("show")]: isCollapse,
            [bem.e("hide")]: !isCollapse,
          })}
        >
          {renderView()}
          <PreviewMediaModal
            isOpen={!!currentPreviewMediaModalOpen}
            channel={channel}
            currentMedia={currentPreviewMediaModalOpen}
            onToggle={() =>
              setCurrentPreviewMediaModalOpen(
                currentPreviewMediaModalOpen
                  ? null
                  : currentPreviewMediaModalOpen
              )
            }
          />
        </div>
      </div>
      <ConfirmationModal
        isOpen={props.isOpenConfirmationModal}
        title={props.intl.formatMessage({
          id: "process > placement confirmation required",
        })}
        isCloseOutside={false}
        className={bem.e("confirmation-modal")}
        description={props.intl.formatMessage({
          id: "process > order will remain in your current tasks",
        })}
        confirmTitle={props.intl.formatMessage({
          id: "process > button okay",
        })}
        onConfirm={() => {
          props.setIsOpenConfirmationModal(false);
        }}
      />
      <Spinner isLoading={isLoading} />
    </div>
  );
}
MediaChannel.defaultProps = {
  channel: {},
  isView: false,
};
MediaChannel.propTypes = {
  channel: PropTypes.any,
  isView: PropTypes.bool,
};
export default MediaChannel;
