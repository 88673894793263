import { FormattedMessage } from "react-intl";
import React from "react";
import { get, find, uniqBy, delay } from "lodash";
import history from "components/History";

const ApproveOrder = {
  process_step_index: 2,
  key: "approve_order",
  title: <FormattedMessage id="process > title approve order" />,
  caption_color: "#73A703",
  description: "Approve order",
  isCollapsible: true,
  step_roles: [],
  step_viewers_roles: [],
  step_editors_roles: [],
  step_viewers_privileges: {
    or: ["approve_digital_order", "edit_all_digital_orders"],
  },
  step_editors_privileges: {
    or: ["approve_digital_order", "edit_all_digital_orders"],
  },
  is_check_hidden_tab: true,
  status: "initial",
  form_class_name: "approve-tab-container",
  is_hidden: function(user, process, step) {
    const marketingChannels = get(
      process,
      "data.fields.marketing_channels.value",
      []
    );
    let approvals = [];
    marketingChannels.forEach((channel) => {
      const { workflow } = channel;
      if (!workflow) return true;
      const workflowStepOrderDetail = find(
        workflow.steps,
        (step) => step.id === "order_details"
      );
      if (workflowStepOrderDetail && workflowStepOrderDetail.approvals) {
        approvals = [...approvals, ...workflowStepOrderDetail.approvals];
      }
    });
    let approvers = [];
    approvals.forEach((item) => {
      approvers = [...approvers, ...item.users];
    });
    approvers = uniqBy(approvers, "id");
    return !approvers.find((item) => item.id === user.id);
  },
  is_approval_step: true,
  fields: [
    {
      component: "digital_approval",
    },
    {
      field: "completed_approvals",
    },
    {
      field: "rejected_approvals",
    },
    {
      field: "all_approvals",
    },
    {
      field: "approve",
      text: <FormattedMessage id="process > button approve" />,
      props: { color: "blue" },
      on_click: {
        save: true,
      },
      fields: [
        {
          key: "approve_order_status",
          value: function(user, template, process, step, item) {
            if (
              process.data.fields.all_approvals &&
              process.data.fields.completed_approvals &&
              process.data.fields.all_approvals.value.length ===
                process.data.fields.completed_approvals.value.length
            )
              return "approved";
            return "in progress";
          },
        },
        {
          key: "process_step",
          value: function(user, template, process, step, item) {
            if (
              process.data.fields.all_approvals &&
              process.data.fields.completed_approvals &&
              process.data.fields.all_approvals.value.length ===
                process.data.fields.completed_approvals.value.length
            )
              return "first_undone";
            return "approve_order";
          },
        },
      ],
      confirmation_dialog_props: {
        description: (
          <FormattedMessage id="process > description to approve the order" />
        ),
        confirmTitle: <FormattedMessage id="process > button yes" />,
        cancelTitle: <FormattedMessage id="process > button no" />,
      },
      success_dialog_props: {
        title: <FormattedMessage id="process > title approved" />,
        state: "success",
      },
    },
    {
      field: "reject_order",
      text: <FormattedMessage id="process > button reject" />,
      props: { outline: true, color: "blue" },
      fields: [
        {
          key: "approve_order_status",
          value: "rejected",
        },
        {
          key: "order_details_status",
          value: "rejected",
        },
        {
          key: "process_step",
          value: "order_details",
        },
      ],
      on_click: {
        validate: true,
        save: true,
        go_to: function(_this, step, item, process, template, user) {
          if (!user.return_to_current_tasks) return;
          // set delay when after success message
          delay(() => {
            if (_this.props.setSidebarActive) {
              _this.props.setSidebarActive(`/processes/my_action_items`);
            }
            history.replace(`/processes/my_action_items`);
          }, 4000);
        },
      },
      confirmation_dialog_props: {
        description: (
          <FormattedMessage id="process > description request changes to the order" />
        ),
        confirmTitle: <FormattedMessage id="process > button yes" />,
        cancelTitle: <FormattedMessage id="process > button no" />,
      },
      success_dialog_props: {
        title: <FormattedMessage id="process > title feedback sent" />,
        state: "success",
      },
    },
  ],
};
export default ApproveOrder;
