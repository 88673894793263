import React from 'react';
import { Row, Col, Label} from "reactstrap";
import bn from "utils/bemnames";
import moment from 'moment'
import { 
	isEmpty, 
	get, 
	sortBy, 
	forEach, 
	has, 
	filter, 
	includes,
	difference,
	map,
	uniq,
	forOwn
} from 'lodash';
import classnames from 'classnames';
import { FormattedMessage } from "react-intl";

import Slot from './Slot';
import HourBlock from './HourBlock';
import DefineHourModal from './DefineHourModal';
import FilterDropdown from './FilterDropdown';
import { getHourLabel } from 'utils/helpers';
import { DownIcon, UpIcon } from 'components/CustomIcons';

const bem = bn.create("programming-clock-calendar");

const Calendar = (props) => {

	const {
	 	isDefineHourModalOpen,
		setIsDefineHourModalOpen,
		clock,
		selectedBlock,
		setSelectedBlock,
		selectedSlotTypes,
		setSelectedSlotTypes,
		selectedTimeSlots,
		setSelectedTimeSlots,
		collapsedHours,
		setCollapsedHours,
	} = props;

	const weekStart = get(clock, "data.start_week_on", "sunday");
	const clockId = get(clock, "data.id");
	const slotDefinitions = get(clock, "data.slot_definitions", {});
	const slots = sortBy(get(clock, "data.slots", []), ['day', 'start_time']);
	let hoursWithSlots = [];

	forEach(slots, slot => {
		let slotMinute = parseInt(slot.start_time);
		let slotHour = parseInt(slotMinute / 60);
		hoursWithSlots.push(slotHour);
	});

	const renderExpandCollapsAllButton = () => {

		if(isEmpty(difference(hoursWithSlots, collapsedHours))){
			return (
				<div 
					className={bem.e('collaps-all')}
					onClick={() => {
						setCollapsedHours([]);
					}}
				>
		  			<span><FormattedMessage id="clock creator > programming calendar expand" /></span>
					<DownIcon color="#795AFA" height="7" width="11"/>
		  		</div>
			);
		}

		return (
			<div 
				className={bem.e('collaps-all')}
				onClick={() => {
					setCollapsedHours(hoursWithSlots);	
				}}
			>
	  			<span><FormattedMessage id="clock creator > programming calendar collapse" /></span>
				<UpIcon color="#795AFA" height="7" width="11"/>
	  		</div>
		);
		
	}


	const renderWeekDays = () => {
		return [''].concat(moment.weekdays(weekStart === 'monday')).map(day => {
		   return (
		     <Col xs={1} key={day} className="week-day p-0 m-0">
		      	{day === '' ? 
		      		renderExpandCollapsAllButton()
		      		: day
		  		}
		     </Col>
		   );
		});
	}

	const renderSlots = () => {

		let totalSlots = [];
		
		let timeSlots = {};
		forEach(slots, slot => {

			let slotDay = parseInt(slot.day);
			let slotMinute = parseInt(slot.start_time);
			let slotHour = parseInt(slotMinute / 60);
			if(!has(timeSlots, slotDay)){
				timeSlots[slotDay] = {};
			}

			if(!has(timeSlots[slotDay], slotHour)){
				timeSlots[slotDay][slotHour] = {};
			}
			
			if(!has(timeSlots[slotDay][slotHour], slotMinute)){
				timeSlots[slotDay][slotHour][slotMinute] = [{...slot, colors: uniq(map(slot.slot_labels, i => i.color))}];
			}else{
				timeSlots[slotDay][slotHour][slotMinute] = [
					...timeSlots[slotDay][slotHour][slotMinute],
					{...slot, colors: uniq(map(slot.slot_labels, i => i.color))}
				]
			}
		});
		for (let hour = 0; hour <= 23; hour++) {
			for(let day = 1; day <= 7; day++){

				let newDay = (weekStart === 'monday') ? ((day === 7) ? 1 : day + 1) : day;

				if(!has(timeSlots[newDay], hour)){
					totalSlots.push(
						<Slot 
							key={`${hour}${newDay}`}
							bem={bem}
							onClick={ () => {
								setSelectedBlock({hour, day: newDay});
								setIsDefineHourModalOpen(true);
							}}
						/>
					);
				}else{
					let sortedSlots = [];
					forOwn(timeSlots[newDay][hour], (value) => {
						sortedSlots = [...sortedSlots, ...value];
					});
					sortedSlots = sortBy(sortedSlots, ['start_time']);
					totalSlots.push(
						<HourBlock
							key={`${hour}${newDay}`}
							bem={bem}
							slots={sortedSlots}
							selectedTimeSlots={selectedSlotTypes}
							onClick={ () => {
								setSelectedTimeSlots(sortBy(filter(slots, slot => slot.day === newDay && parseInt(slot.start_time / 60) === hour), ['start_time']));
								setSelectedBlock({hour, day: newDay});
								setIsDefineHourModalOpen(true);
							}}
							isCollapsed={includes(collapsedHours, hour)}
						/>
					);
				}
			}
		}

		let rows = [];
    	let cells = [];

		totalSlots.forEach((row, i) => {

	      if (i % 7 !== 0) {
	        cells.push(row); // if index not equal 7 that means not go to next hour
	      } else {
	      	
	      	if(!isEmpty(cells)){
	      		rows.push(cells); // when reach next hour we contain all td in last hour to rows 
	      	}
	        
	        cells = []; // empty container 
	        cells.push(row); // in current loop we still push current row to new container
	      }
	      if (i === totalSlots.length - 1) { // when end loop we add remain slots
	        rows.push(cells);
	      }
	    });

	    return rows.map((slot, i) => {
	      return (
	      	<Row className={classnames(bem.e('hour'), 'p-0 m-0')} key={i}>
	      		<Col 
	      			xs={1} 
	      			className={classnames(bem.e('time'), 'p-0 m-0', {filled: includes(hoursWithSlots, i)})}
	      			onClick={() => {
	      				if(includes(hoursWithSlots, i)){
	      					if(includes(collapsedHours, i)){
	      						setCollapsedHours(difference(collapsedHours, [i]));
	      					}else{
	      						setCollapsedHours([...collapsedHours, i]);
	      					}
	      				}
	      			}}
	      		>
	      			{getHourLabel(i)}
	      			{includes(hoursWithSlots, i) && (<div className={bem.e('time-chevron-icon')}>
	      				{includes(collapsedHours, i) ? 
	      					<DownIcon color="#C2D4E0" height="7" width="12"/> : 
	      					<UpIcon color="#C2D4E0" height="7" width="12"/>
	      				}
	      			</div>)}
	      		</Col>
	      		{slot}
	      	</Row>
	      )
	    });
	}

	return (
		<React.Fragment>
			<Row className={classnames(bem.e('filter-container'), 'm-0 p-0')}>
				<Col xs={2} className="p-0 m-0 label-container">
					<Label>
						<FormattedMessage id="clock creator > programming calendar filter view by" />
					</Label>
				</Col>
				<Col xs={3} className="p-0 dropdown-container">
		            <FilterDropdown
		                name="filter"
		                onFilter={(selectedFilters) => {
		                  setSelectedSlotTypes(selectedFilters);
		                }}
		                options={map(slotDefinitions, (slotDefinition) => ({
			              label: slotDefinition.name,
			              value: slotDefinition.id,
			              color: slotDefinition.color,
			              slot_count: filter(slots, slot => get(slot, "slot_definition.id") === slotDefinition.id).length
			            }))}
			            isMulti={true}

		            />
		         </Col>
            </Row>
			<div className={bem.e('calendar')}>
				<Row className="p-0 m-0">
					{renderWeekDays()}
				</Row>
				{renderSlots()}
			</div>
			{isDefineHourModalOpen && (<DefineHourModal 
	    		isOpen={isDefineHourModalOpen} 
	    		clockId={clockId}
	    		selectedBlock={selectedBlock}
	    		slotDefinitions={slotDefinitions}
	    		selectedTimeSlots={selectedTimeSlots}
	    		toggle={ () => {
	    			setIsDefineHourModalOpen(!isDefineHourModalOpen);
	    			setSelectedBlock(null);
	    			setSelectedTimeSlots([]);
	    		}} 
	    	/>)}
	    </React.Fragment>
	);
}

export default Calendar;