import React from "react";
import bn from "utils/bemnames";
const bem = bn.create("alert-not-allowed");

export default function View(props) {
  return (
    <div className={bem.b()}>
      <h2 className={bem.e("title")}>{props.title}</h2>
    </div>
  );
}
View.defaultProps = {
  title: "You are not allowed",
};
