import React from "react";
import bn from "utils/bemnames";
import { get } from "lodash";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import Spinner from "components/Spinner";
import ParticipantView from "../Common/ParticipantView";
import ParticipantEdit from "../Common/ParticipantForm";
import ContactActivity from "../Common/ContactActivity";
import ParticipationActivity from "../Common/ParticipationActivity";
import Contest from "../Common/Contest";

import { map } from "lodash";
const bem = bn.create("winner-details");

export default function WinnerDetails(props) {
  const {
    activeTab,
    setActiveTab,
    isView,
    isLoading,
    winner,
    onUpdateFulfilled,
    auth,
    systemForm,
  } = props;
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const activeContests = get(winner, "contests", []);
  const expiredContests = get(winner, "expired_contests", []);
  const stations = get(auth, "info.stations", []);
  const isShowContests = activeTab === "1";
  const isShowParticipationActivity = activeTab === "2";
  const isShowContactActivity = activeTab === "3";

  return (
    <div className={bem.b()}>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => {
              toggle("1");
            }}
          >
            <FormattedMessage id="winners > tab participant" />
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => {
              toggle("2");
            }}
          >
            <FormattedMessage id="winners > tab activity" />
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "3" })}
            onClick={() => {
              toggle("3");
            }}
          >
            <FormattedMessage id="winners > tab contact log" />
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent
        activeTab={activeTab}
        className={classnames({
          [bem.e("active-first-tab-content")]: activeTab === "1",
          [bem.e("tab-content-view")]: isView,
          [bem.e("tab-content-edit")]: !isView,
        })}
      >
        <TabPane tabId="1">
          {systemForm && (
            <Row>
              <Col>
                {isView ? (
                  <ParticipantView winner={winner || {}} />
                ) : (
                  <ParticipantEdit winner={winner || {}} />
                )}
              </Col>
            </Row>
          )}
        </TabPane>
        <TabPane tabId="2">
          {systemForm && (
            <Row>
              <Col>
                <ParticipantView isShowEditIcon={false} winner={winner || {}} />
              </Col>
            </Row>
          )}
        </TabPane>
        <TabPane tabId="3">
          {systemForm && (
            <Row>
              <Col>
                <ParticipantView isShowEditIcon={false} winner={winner || {}} />
              </Col>
            </Row>
          )}
        </TabPane>
      </TabContent>
      {isShowContests && (
        <React.Fragment>
          <div className={bem.e("contests")}>
            <div className={bem.e("contest-title")}>
              <FormattedMessage id="winners > active contests" />
            </div>
            <div className={bem.e("contests-list")}>
              {map(activeContests, (item, index) => (
                <Contest
                  item={item}
                  winner={winner}
                  key={index}
                  onUpdateFulfilled={onUpdateFulfilled}
                  stations={stations}
                />
              ))}
            </div>
          </div>
          <div className={bem.e("contests")}>
            <div className={bem.e("contest-title")}>
              <FormattedMessage id="winners > past contests" />
            </div>
            <div className={bem.e("contests-list")}>
              {map(expiredContests, (item, index) => (
                <Contest
                  isPast={true}
                  item={item}
                  winner={winner}
                  key={index}
                  onUpdateFulfilled={onUpdateFulfilled}
                  stations={stations}
                />
              ))}
            </div>
          </div>
        </React.Fragment>
      )}
      {isShowContactActivity && <ContactActivity />}
      {isShowParticipationActivity && <ParticipationActivity />}
      <Spinner isLoading={isLoading} />
    </div>
  );
}
