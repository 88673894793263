import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { get } from "lodash";
import ServerFormSection from "./ServerFormSection";
import ModuleEntitiesAssignment from "./ModuleEntitiesAssignment";

class PromotionsConfiguration extends Component {

  onSwitchToggle = (checked) => {
    const { formProps } = this.props;
    formProps.setFieldValue(
      "is_promotion",
      checked
    );
    formProps.setFieldTouched(
      "is_promotion",
      true
    );
  };

  render() {
    const { bem, edit, formProps, intl } = this.props;
    const moduleEnabled = get(formProps, "values.is_promotion", false);
     const multiMarketEnabled = get(formProps, "values.promotion_multimarket", false);
    return (
      <ServerFormSection
        bem={bem}
        checked={moduleEnabled}
        titleId="server > promotions"
        sectionClass={bem.e("box-promotion-details")}
        sectionFieldsClass={bem.e("section-view-promotion-configuration-fields")}
        hasToggle={edit}
        onSwitchToggle={this.onSwitchToggle}
        showMultiMarket={false} //edit ? true : false
        onChangeMultiMarket={(checked) => {
          formProps.setFieldValue(
            "promotion_multimarket",
            checked
          );
          formProps.setFieldTouched(
            "promotion_multimarket",
            true
          );
        }}
        intl={intl}
        multiMarketEnabled={multiMarketEnabled}
      >
        <ModuleEntitiesAssignment {...this.props} moduleEnabled={moduleEnabled} featureModule={"promotion"} />
      </ServerFormSection>
    )
  }
}

export default injectIntl(PromotionsConfiguration);
