import { connect } from "react-redux";
import View from "./view";
import { get } from "lodash";

import { injectIntl } from "react-intl";
const Enhanced = connect((state) => {
  return {
    dateFormatByServer: get(
      state,
      "servers.currentServer.data.date_format",
      "MM/DD/YYYY"
    ),
  };
}, {})(View);

export default injectIntl(Enhanced);
