import React, { useEffect, useState } from "react";
import bn from "utils/bemnames";
import { Views } from "react-big-calendar";
import PropTypes from "prop-types";
import {
  filter,
  find,
  findIndex,
  first,
  forEach,
  get,
  map,
  orderBy,
} from "lodash";
import { arrayContainsArray } from "utils/helpers";
import CalendarTime from "../CalendarTime";
import { FormattedMessage } from "react-intl";
const bem = bn.create("schedule-calendar");
const calendarHoursColor = [
  // loop 1
  "#CFE2B3",
  "#CAEFE9",
  "#ADE2F6",
  "#ADCFFD",
  "#D4CEFB",
  // loop 2
  "#CFE2B3",
  "#CAEFE9",
  "#ADE2F6",
  "#ADCFFD",
  "#D4CEFB",
];
function getCalendarData({ filterType, clocksData }) {
  let calendarData = [];
  let clockDataKey = "dayparts";
  let clockDataHoursKey = "dayparts_hours";
  if (filterType !== "daypart") {
    clockDataKey = "shows";
    clockDataHoursKey = "shows_hours";
  }
  calendarData = map(clocksData[clockDataKey], (daypart) => {
    const isWeekday = arrayContainsArray(daypart.days, [2, 3, 4, 5, 6]); // only get weekday
    const isSaturday = arrayContainsArray(daypart.days, [7]); // only get saturday
    const isSunday = arrayContainsArray(daypart.days, [1]); // only get sunday
    const dataHours = find(
      get(clocksData, clockDataHoursKey, []),
      (i) => i.id === daypart.id
    );
    let hours = dataHours.hours;
    // push 24 hour to hours array
    if (daypart.end_time === 0 && findIndex(hours, (i) => i === 24) === -1) {
      hours = [...hours, 24];
    }
    return {
      ...daypart,
      ...dataHours,
      hours,
      order: first(hours),
      isWeekday,
      isSaturday,
      isSunday,
    };
  });
  calendarData = orderBy(calendarData, "order");
  return calendarData;
}
export default function CalendarContent(props) {
  const {
    rangeDates,
    scheduleCalendarFilter,
    intl,
    selectedItems,
    selectedItem,
    setSelectedItem,
    items,
    onSwapItem,
    onViewItem,
    onDeleteItem,
    setStatementType,
    isScheduleTab,
    onSelectItemSwap,
    selectedItemSwap,
    onSelectItemTargetSwap,
    swappableSlots,
    selectedItemTargetSwap,
    clocksData,
    sameTypeSlots,
  } = props;
  const filterType = get(scheduleCalendarFilter, "type_id", "daypart");
  const [isOpenHours, setIsOpenHours] = useState({});
  const [isAllOpen, setIsAllOpen] = useState(true);
  const [calendarData, setCalendarData] = useState(
    getCalendarData({
      filterType,
      clocksData,
    })
  );
  const weekdaysData = filter(calendarData, (item) => item.isWeekday);
  const saturdaysData = filter(calendarData, (item) => item.isSaturday).map(
    (item, index) => ({
      ...item,
      range_color: get(calendarHoursColor, index),
    })
  );
  const sundaysData = filter(calendarData, (item) => item.isSunday).map(
    (item, index) => ({
      ...item,
      range_color: get(calendarHoursColor, index),
    })
  );
  useEffect(() => {
    setCalendarData(
      getCalendarData({
        filterType,
        clocksData,
      })
    );
  }, [filterType, clocksData]);
  useEffect(() => {
    let newIsOpenHours = {};
    forEach(weekdaysData, (item, index) => {
      newIsOpenHours = {
        ...newIsOpenHours,
        [index]: true, // open = true
      };
    });
    setIsOpenHours(newIsOpenHours);
  }, [calendarData]);
  return (
    <div className={bem.e("calendar-content")}>
      {/* render calendar view */}
      <div className={bem.e("calendar")}>
        <div
          className={bem.e("close-all")}
          onClick={() => {
            let newIsOpenHours = {};
            forEach(weekdaysData, (item, index) => {
              newIsOpenHours = {
                ...newIsOpenHours,
                [index]: !isAllOpen,
              };
            });
            setIsAllOpen(!isAllOpen);
            setIsOpenHours(newIsOpenHours);
          }}
        >
          {isAllOpen ? (
            <FormattedMessage
              id={`process > promotion > liner > schedule > collapse all ${
                filterType === "daypart" ? "dayparts" : "shows"
              }`}
            />
          ) : (
            <FormattedMessage
              id={`process > promotion > liner > schedule > expand all ${
                filterType === "daypart" ? "dayparts" : "shows"
              }`}
            />
          )}
        </div>

        {map(weekdaysData, (weekdayClockData, index) => {
          return (
            <CalendarTime
              isOpen={get(isOpenHours, index, false)}
              setIsOpen={() => {
                const newIsOpen = !get(isOpenHours, index, false);
                setIsAllOpen(newIsOpen);
                setIsOpenHours({
                  ...isOpenHours,
                  [index]: newIsOpen,
                });
              }}
              items={items}
              idx={index}
              weekdayClockData={weekdayClockData}
              hours={filter(weekdayClockData.hours, (i) => i <= 24)}
              rangeColor={get(calendarHoursColor, index)}
              rangeDates={rangeDates}
              saturdaysData={saturdaysData}
              sundaysData={sundaysData}
              key={index}
              scheduleCalendarFilter={scheduleCalendarFilter}
              selectedItem={selectedItem}
              selectedItemSwap={selectedItemSwap}
              selectedItemTargetSwap={selectedItemTargetSwap}
              selectedItems={selectedItems}
              intl={intl}
              onSwapItem={onSwapItem}
              onSelectItemSwap={onSelectItemSwap}
              onViewItem={onViewItem}
              onDeleteItem={onDeleteItem}
              isScheduleTab={isScheduleTab}
              onSelectItem={(item) => {
                if (!isScheduleTab) {
                  setSelectedItem(item);
                  setStatementType("select");
                }
              }}
              onSelectItemTargetSwap={onSelectItemTargetSwap}
              swappableSlots={swappableSlots}
              sameTypeSlots={sameTypeSlots}
              clocksData={clocksData}
            />
          );
        })}
      </div>
    </div>
  );
}
CalendarContent.defaultProps = {
  view: Views.WORK_WEEK,
  rangeDates: [],
  items: {},
  isScheduleTab: false,
  onSelectItemSwap: () => {},
  onSelectItemTargetSwap: () => {},
  onUndoSwappedItem: () => {},
  swappableSlots: [],
  onDeleteItem: () => {},
};
CalendarContent.propTypes = {
  view: PropTypes.oneOf(Object.values(Views)),
  items: PropTypes.object,
  isScheduleTab: PropTypes.bool,
  onSelectItemSwap: PropTypes.func,
  onSelectItemTargetSwap: PropTypes.func,
  onUndoSwappedItem: PropTypes.func,
  swappableSlots: PropTypes.array,
  onDeleteItem: PropTypes.func,
};
