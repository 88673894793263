import React from "react";
import { get } from "lodash";
import TextInput from "components/TextInput";
import { Formik } from "formik";
import * as Yup from "yup";
import { Redirect } from "react-router";
import Spinner from "components/Spinner";
import { FormattedMessage } from "react-intl";
import PrivilegedComponent from "components/PrivilegedComponent";
import { Form, FormGroup, Button, Row, Col, Label } from "reactstrap";
import { PRIVILEGES } from "utils/constants";
import URL from "utils/urls";
import bn from "utils/bemnames";
const bem = bn.create("security-policy");
class SecurityPolicy extends React.Component {
  renderForm() {
    const { user, isLoading, auth } = this.props;
    const userDetails = get(user, "data", {});
    const authIsGodAdmin = get(auth, "user.is_god_admin", false);
    const authIsBroadcastingGroupAdmin = get(
      auth,
      "user.broadcasting_group_admin",
      false
    );
    const isDisableSubmit = !get(userDetails, "id");
    return (
      <PrivilegedComponent
        requires={(privileges) => {
          if (authIsGodAdmin || authIsBroadcastingGroupAdmin) return true;
          return (
            privileges.indexOf(PRIVILEGES.EDIT_USERS) > -1 ||
            privileges.indexOf(PRIVILEGES.VIEW_USERS) > -1 ||
            privileges.indexOf(PRIVILEGES.ASSIGN_PRIVILEGES) > -1
          );
        }}
      >
        {({ isValid }) => {
          if (isValid) {
            return (
              <div className={bem.b()}>
                <Formik
                  enableReinitialize
                  initialValues={{
                    password_complexity_pattern:
                      userDetails.password_complexity_pattern || "",
                    replace_password_days:
                      userDetails.replace_password_days || "",
                    reuse_password_days: userDetails.reuse_password_days || "",
                    domain_filter: userDetails.domain_filter || "",
                    system_idle_timeout: userDetails.system_idle_timeout || "",
                  }}
                  validationSchema={Yup.object().shape({
                    password_complexity_pattern: Yup.string(),
                    replace_password_days: Yup.number().typeError(
                      this.props.intl.formatMessage({
                        id: "validate > replace password days must be number",
                      })
                    ),
                    reuse_password_days: Yup.number().typeError(
                      this.props.intl.formatMessage({
                        id: "validate > reuse password days must be number",
                      })
                    ),
                    domain_filter: Yup.string(),
                    system_idle_timeout: Yup.number().typeError(
                      this.props.intl.formatMessage({
                        id: "validate > system idle timeout must be number",
                      })
                    ),
                  })}
                  onSubmit={this.props.onFormSubmit}
                >
                  {(formProps) => {
                    const errors = formProps.errors;
                    const touched = formProps.touched;
                    const values = formProps.values;
                    return (
                      <Form
                        onSubmit={formProps.handleSubmit}
                        className="form-add-update-user"
                      >
                        <div className={bem.e("content-form")}>
                          <div className={bem.e("title")}>
                            <FormattedMessage id="user > security policy" />
                          </div>
                          <FormGroup>
                            <TextInput
                              label={
                                <FormattedMessage id="user > password complexity pattern" />
                              }
                              type="text"
                              name="password_complexity_pattern"
                              value={values.password_complexity_pattern}
                              error={
                                touched.password_complexity_pattern &&
                                errors.password_complexity_pattern
                              }
                              onChange={formProps.handleChange}
                              onBlur={formProps.handleBlur}
                            />
                          </FormGroup>
                          <FormGroup>
                            <TextInput
                              label={
                                <FormattedMessage id="user > replace password days" />
                              }
                              type="text"
                              name="replace_password_days"
                              value={values.replace_password_days}
                              error={
                                touched.replace_password_days &&
                                errors.replace_password_days
                              }
                              onChange={formProps.handleChange}
                              onBlur={formProps.handleBlur}
                            />
                          </FormGroup>
                          <FormGroup>
                            <TextInput
                              label={
                                <FormattedMessage id="user > reuse password days" />
                              }
                              type="text"
                              name="reuse_password_days"
                              value={values.reuse_password_days}
                              error={
                                touched.reuse_password_days &&
                                errors.reuse_password_days
                              }
                              onChange={formProps.handleChange}
                              onBlur={formProps.handleBlur}
                            />
                          </FormGroup>
                          <FormGroup>
                            <TextInput
                              label={
                                <FormattedMessage id="user > domain filter" />
                              }
                              name="domain_filter"
                              value={values.domain_filter}
                              error={
                                touched.domain_filter && errors.domain_filter
                              }
                              onChange={formProps.handleChange}
                              onBlur={formProps.handleBlur}
                            />
                          </FormGroup>
                          <FormGroup>
                            <TextInput
                              label={
                                <FormattedMessage id="user > system idle timeout" />
                              }
                              name="system_idle_timeout"
                              value={values.system_idle_timeout}
                              error={
                                touched.system_idle_timeout &&
                                errors.system_idle_timeout
                              }
                              onChange={formProps.handleChange}
                              onBlur={formProps.handleBlur}
                            />
                          </FormGroup>
                        </div>

                        <div className={bem.e("form-buttons")}>
                          <FormGroup>
                            <Button
                              color="blue"
                              type="submit"
                              className="btn btn-radius"
                              disabled={isLoading || isDisableSubmit}
                            >
                              <FormattedMessage
                                id={`user > button ${
                                  !isLoading ? "submit" : "submitting"
                                }`}
                              />
                            </Button>
                            {this.props.backButton}
                          </FormGroup>
                        </div>
                        <Spinner isLoading={isLoading} />
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            );
          } else {
            return <Redirect to={URL.DASHBOARD()} />;
          }
        }}
      </PrivilegedComponent>
    );
  }
  renderView() {
    const { user } = this.props;
    const userDetails = get(user, "data", {});
    return (
      <div className={bem.e("view-wrapper")}>
        <div className={bem.e("content-form")}>
          <div className={bem.e("title")}>
            <FormattedMessage id="user > security policy" />
          </div>
          <Row>
            <Col xs={12} className={bem.e("col-reset")}>
              <FormGroup className={bem.e("form-group-view")}>
                <Label className={bem.e("label")}>
                  <FormattedMessage id="user > password complexity pattern" />
                </Label>
                <div className={bem.e("field-view-value")}>
                  {userDetails.password_complexity_pattern ? (
                    userDetails.password_complexity_pattern
                  ) : (
                    <FormattedMessage id="user > none" />
                  )}
                </div>
              </FormGroup>
            </Col>
            <Col xs={12} className={bem.e("col-reset")}>
              <FormGroup className={bem.e("form-group-view")}>
                <Label className={bem.e("label")}>
                  <FormattedMessage id="user > replace password days" />
                </Label>
                <div className={bem.e("field-view-value")}>
                  {userDetails.replace_password_days ? (
                    userDetails.replace_password_days
                  ) : (
                    <FormattedMessage id="user > none" />
                  )}
                </div>
              </FormGroup>
            </Col>
            <Col xs={12} className={bem.e("col-reset")}>
              <FormGroup className={bem.e("form-group-view")}>
                <Label className={bem.e("label")}>
                  <FormattedMessage id="user > domain filter" />
                </Label>
                <div className={bem.e("field-view-value")}>
                  {userDetails.domain_filter ? (
                    userDetails.domain_filter
                  ) : (
                    <FormattedMessage id="user > none" />
                  )}
                </div>
              </FormGroup>
            </Col>
            <Col xs={12} className={bem.e("col-reset")}>
              <FormGroup className={bem.e("form-group-view")}>
                <Label className={bem.e("label")}>
                  <FormattedMessage id="user > system idle timeout" />
                </Label>
                <div className={bem.e("field-view-value")}>
                  {userDetails.system_idle_timeout ? (
                    userDetails.system_idle_timeout
                  ) : (
                    <FormattedMessage id="user > none" />
                  )}
                </div>
              </FormGroup>
            </Col>
          </Row>
        </div>

        <div className={bem.e("form-buttons")}>
          <FormGroup>
            <Button
              color="blue"
              onClick={() => this.props.setIsView(false)}
              className="btn btn-radius"
            >
              <FormattedMessage id={`user > button edit`} />
            </Button>
            {this.props.backButton}
          </FormGroup>
        </div>
      </div>
    );
  }
  render() {
    const { isView } = this.props;
    if (isView) {
      return this.renderView();
    }
    return this.renderForm();
  }
}

export default SecurityPolicy;
