import * as React from "react";
import { Row, Col, Button } from "reactstrap";
import { get, map, debounce, filter, toLower, find, orderBy } from "lodash";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import { ShadowBox, SectionTitle, SectionFields } from "components/Elements";
import bn from "utils/bemnames";
import SearchInput from "components/SearchInput";
import { PlusIcon, PalmTreeIcon } from "components/CustomIcons";
import { FaEllipsisH } from "react-icons/lib/fa";
import Avatar from "components/Avatar";
import Player from "components/Player";
import { CloseIcon } from "components/CustomIcons";
import {
  checkOutOfOfficeOfUserByDates,
  formatDisplayDateWithTimezone,
  getRoleIdByName,
} from "utils/helpers";
import { getRoleUsersByHubMembers } from "components/MultipleMarketComponents/helper";
import Spinner from "components/Spinner";
import VoLabel from "./VoLabel";

const bem = bn.create("select-team");

const userInfo = (user) => {
  const currentTasksCount = user.info.current_tasks_count
    ? user.info.current_tasks_count
    : user.info.availability
    ? user.info.availability.action_items
    : 0;
  const assignedTasksCount = user.info.assigned_tasks_count
    ? user.info.assigned_tasks_count
    : user.info.availability
    ? user.info.availability.upcoming_items
    : 0;
  return (
    <div className={bem.e("user-tasks")}>
      <div
        className={classnames(bem.e("user-task"), bem.e("user-task-current"))}
      >
        <span className={bem.e("user-task-label")}>
          <FormattedMessage
            className={bem.e("user-task-label")}
            id="select team > current tasks"
          />
        </span>
        :<span className={bem.e("user-task-value")}>{currentTasksCount}</span>
      </div>
      <div
        className={classnames(bem.e("user-task"), bem.e("user-task-assigned"))}
      >
        <span className={bem.e("user-task-label")}>
          <FormattedMessage id="select team > assigned tasks" />:
        </span>
        <span className={bem.e("user-task-value")}>{assignedTasksCount}</span>
      </div>
    </div>
  );
};
const SelectTeamVo = (props) => {
  const {
    process,
    getRoleCandidates,
    template,
    user,
    step,
    intl,
    mode,
    dateFormatByServer,
    members,
    info,
    isLoading,
  } = props;
  const isView = mode === "view";
  const [selectedItem, setSelectedItem] = React.useState(0);
  const [currentTotalMembers, setCurrentTotalMembers] = React.useState(4);
  const [searchText, setSearchText] = React.useState("");
  const roleVoiceTalent = step.fields.find(
    (f) => f.field === "role_voice_talent"
  );
  const buttonField = step.fields.find((f) => f.key === "save_button");
  const teamProductionManager = get(
    process,
    "data.fields.team_production_manager.value",
    ""
  );
  const isHubManager = getRoleIdByName("Hub Manager", info.enterprise_roles);
  const [currentUsers, setCurrentUsers] = React.useState([]);
  const allVoUsers = React.useMemo(() => {
    let allVoUsers = [];
    if (teamProductionManager) {
      const hubMembers = getRoleUsersByHubMembers(roleVoiceTalent, members);
      allVoUsers = filter(
        hubMembers,
        (user) => get(user, "info.hubs.length", 0) > 0
      );
    } else {
      allVoUsers = getRoleCandidates(roleVoiceTalent, process, template, user);
    }
    setCurrentUsers(allVoUsers);
    return allVoUsers;
  }, [members, teamProductionManager]);
  React.useEffect(() => {
    if (!teamProductionManager || isLoading) return;
    if (!isHubManager) {
      const stations = get(process, "data.fields.order_stations.value", []);
      props.onGetTeamMembers(stations);
    } else {
      props.onGetTeamMembers([]);
    }
  }, [isHubManager]);
  React.useEffect(() => {
    if (currentUsers.length === 0 && allVoUsers.length > 0) {
      setCurrentUsers(allVoUsers);
    }
  }, [allVoUsers]);
  const voiceTalentUsers = get(
    process,
    "data.fields.role_voice_talent.value.users",
    []
  );
  React.useEffect(() => {
    const maxIndex = Math.max(...map(voiceTalentUsers, (u) => u.index));
    if (maxIndex > 4 && currentTotalMembers !== 8) {
      setCurrentTotalMembers(8);
    }
    // const totalSelected = voiceTalentUsers.length;
    // if (totalSelected < currentTotalMembers) {
    //   const index = findIndex(
    //     [...Array(currentTotalMembers).keys()],
    //     (number) => !find(voiceTalentUsers, (u) => u.index === number)
    //   );
    //   setSelectedItem(index !== -1 ? index : 0);
    // } else {
    //   setSelectedItem(0);
    // }
  }, [voiceTalentUsers.length]);
  const onChangeInput = debounce((searchQuery) => {
    const newUsers = filter(allVoUsers, (item) =>
      toLower(item.label).includes(toLower(searchQuery))
    );
    setCurrentUsers(newUsers);
  }, 20);
  const toggleUserRole = (user, role) => {
    let { step, template, process } = props;
    let item = step.fields.find((f) => role && f.field === role.field);

    let candidate_for = [];
    let role_id = 0;
    user.candidate_for.map((c) => {
      c.roles.map((r) => {
        if (candidate_for.indexOf(r) === -1 && role.roles.indexOf(r) > -1)
          candidate_for.push(r);
        return true;
      });
      return true;
    });
    if (candidate_for.length === 0) {
      debugger;
    } else role_id = candidate_for[0];

    let role_users =
      process.data.fields[role.field] &&
      process.data.fields[role.field].value.users
        ? process.data.fields[role.field].value.users
        : [];
    if (
      role &&
      !(
        process.data.fields[role.field] &&
        process.data.fields[role.field].value.users
      ) &&
      process.data.fields[role.field] &&
      process.data.fields[role.field].value.id
    ) {
      role_users = [process.data.fields[role.field].value];
    }
    if (role_users.length > 0) {
      let user_exists = false;
      role_users.map((existing_user) => {
        if (
          parseInt(existing_user.id) === parseInt(user.info.id) &&
          parseInt(existing_user.role) === parseInt(role_id)
        )
          user_exists = true;
      });
      if (user_exists) {
        role_users = role_users.filter(
          (existing_user) =>
            parseInt(existing_user.id) !== parseInt(user.info.id) ||
            parseInt(existing_user.role) !== parseInt(role_id)
        );
      } else {
        role_users.push({
          id: user.info.id,
          name: user.info.name,
          role: role_id,
          avatar_url: user.info.avatar_url,
          index: selectedItem,
          label: `VO ${selectedItem + 1}`,
        });
      }
    } else {
      role_users = [
        {
          id: user.info.id,
          name: user.info.name,
          role: role_id,
          avatar_url: user.info.avatar_url,
          index: selectedItem,
          label: `VO ${selectedItem + 1}`,
        },
      ];
    }

    props.valueChanged(item, process, template, user, {
      value: { users: role_users },
    });
    if (props.setShouldBlockNavigation) {
      props.setShouldBlockNavigation(true);
    }
  };
  const onDeleteUser = (user) => {
    const users = voiceTalentUsers.filter(
      (u) => parseInt(u.id) !== parseInt(user.id)
    );
    props.valueChanged(roleVoiceTalent, process, template, user, {
      value: { users },
    });
    if (props.setShouldBlockNavigation) {
      props.setShouldBlockNavigation(true);
    }
  };
  const onUpdateUser = (index, params) => {
    const users = voiceTalentUsers.map((u) => {
      if (u.index === index) {
        return { ...u, ...params };
      }
      return u;
    });
    props.valueChanged(roleVoiceTalent, process, template, user, {
      value: { users },
    });
    if (props.setShouldBlockNavigation) {
      props.setShouldBlockNavigation(true);
    }
  };
  const onClickPlusMembers = () => {
    if (currentTotalMembers < 8) {
      setCurrentTotalMembers(8);
    } else {
      setCurrentTotalMembers(4);
      if (selectedItem >= 4) {
        setSelectedItem(0);
      }
      const users = voiceTalentUsers.filter((u) => parseInt(u.index) < 4);
      props.valueChanged(roleVoiceTalent, process, template, user, {
        value: { users },
      });
      if (props.setShouldBlockNavigation) {
        props.setShouldBlockNavigation(true);
      }
    }
  };
  // generate data to display users. (it's same with select team component)
  const roles = [roleVoiceTalent];
  let all_candidates = [];
  let role_candidates = {};
  roles.forEach((role) => {
    role_candidates[role.field] = currentUsers;
  });
  Object.keys(role_candidates).map((role_key) => {
    let candidates = role_candidates[role_key];

    candidates &&
      candidates.map((candidate) => {
        if (!all_candidates.find((c) => c.info.id === candidate.info.id))
          all_candidates.push({ ...candidate, candidate_for: [] });

        all_candidates
          .find((c) => c.info.id === candidate.info.id)
          .candidate_for.push(roles.find((r) => r && r.field === role_key));
        return true;
      });
    return true;
  });
  all_candidates = all_candidates.map((user) => {
    const isSelected = user.candidate_for.find((role) => {
      return (
        get(process.data.fields[role.field], "value.users", []).filter(
          (u) => parseInt(u.id) === user.info.id
        ).length > 0
      );
    });
    return {
      ...user,
      order: isSelected ? 1 : 0,
    };
  });
  all_candidates = orderBy(all_candidates, ["order", "label"], "desc");
  if (isView) {
    return (
      <div className={bem.b()}>
        <ShadowBox>
          <SectionTitle className={bem.e("team-members-title")}>
            <FormattedMessage id="process > team members" />
          </SectionTitle>
          <SectionFields
            className={classnames(
              bem.e("assign-vo-members"),
              bem.e("view-assign-vo-members")
            )}
          >
            <div className={bem.e("members-items")}>
              {map([...Array(currentTotalMembers).keys()], (number) => {
                const userVo = find(
                  voiceTalentUsers,
                  (u) => u.index === number
                );
                const status = userVo
                  ? "assigned"
                  : selectedItem === number
                  ? "selected"
                  : "inactive";
                return (
                  <div
                    className={classnames(
                      bem.e("member-item"),
                      bem.e(`member-${status}`)
                    )}
                    key={number}
                  >
                    <span className={bem.e("vo-text")}>
                      {get(userVo, "label", "") ? (
                        userVo.label
                      ) : (
                        <>
                          <FormattedMessage id="process > vo" />
                          {` `}
                          {number + 1}
                        </>
                      )}
                    </span>
                    <div className={bem.e("member-box")}>
                      {status === "selected" ? <PlusIcon color="#fff" /> : null}
                      {status === "assigned" ? (
                        <span className={bem.e("member-name")}>
                          {userVo.name}
                        </span>
                      ) : null}
                    </div>
                  </div>
                );
              })}
            </div>
          </SectionFields>
        </ShadowBox>
      </div>
    );
  }
  return (
    <div className={bem.b()}>
      <ShadowBox className={bem.e("assign-vo")}>
        <SectionTitle className={bem.e("team-members-title")}>
          <FormattedMessage id="process > team members" />
        </SectionTitle>
        <SectionFields className={bem.e("assign-vo-members")}>
          <div className={bem.e("vo-members-items-container")}>
            <div className={bem.e("members-items")}>
              {map([...Array(currentTotalMembers).keys()], (number) => {
                const userVo = find(
                  voiceTalentUsers,
                  (u) => u.index === number
                );
                const status = userVo
                  ? "assigned"
                  : selectedItem === number
                  ? "selected"
                  : "inactive";
                return (
                  <div
                    className={classnames(
                      bem.e("member-item"),
                      bem.e(`member-${status}`)
                    )}
                    key={number}
                  >
                    <VoLabel
                      bem={bem}
                      intl={intl}
                      number={number}
                      userVo={userVo}
                      onUpdateUser={onUpdateUser}
                      status={status}
                    />
                    <div
                      className={bem.e("member-box")}
                      onClick={() => {
                        if (status === "inactive") {
                          setSelectedItem(number);
                        }
                      }}
                    >
                      {status === "selected" ? <PlusIcon color="#fff" /> : null}
                      {status === "assigned" ? (
                        <span className={bem.e("member-name")}>
                          {userVo.name}
                          <span
                            className={bem.e("delete-icon")}
                            onClick={() => {
                              onDeleteUser(userVo);
                              setSelectedItem(number);
                            }}
                          >
                            <CloseIcon color={"#C2D4E0"} />
                          </span>
                        </span>
                      ) : null}
                    </div>
                  </div>
                );
              })}
            </div>
            <div
              className={bem.e("add-member-btn")}
              onClick={onClickPlusMembers}
            >
              {currentTotalMembers >= 8 ? (
                <span className={bem.e("minus-icon")} />
              ) : (
                <PlusIcon color="#fff" />
              )}
            </div>
          </div>
          <div className={bem.e("members-search")}>
            <SearchInput
              placeholder={intl.formatMessage({
                id: "process > search for team member",
              })}
              onClear={() => {
                setSearchText("");
                setCurrentUsers(allVoUsers);
              }}
              onSubmit={() => onChangeInput(searchText)}
              onChange={(value) => {
                setSearchText(value);
                onChangeInput(value);
              }}
              value={searchText}
            />
            <div className="bottom-actions-sticky">
              <div className="buttons">
                <Button
                  color="blue"
                  className={classnames(
                    "btn-radius btn-blue",
                    bem.e("btn-assign-team")
                  )}
                  disabled={voiceTalentUsers.length === 0}
                  onClick={(event) => {
                    props.buttonClicked(
                      step,
                      buttonField,
                      process,
                      template,
                      user,
                      event,
                      null,
                      false
                    );
                  }}
                >
                  {buttonField.text}
                </Button>
              </div>
            </div>
          </div>
        </SectionFields>
        <div className={bem.e("select-teams-member")}>
          <div className={bem.e("container-users")}>
            {all_candidates.map((user, ind) => {
              const userTimeZone = get(user, "info.timezone");
              const datesOutOfOffice = checkOutOfOfficeOfUserByDates(
                get(user, "info.out_of_office_settings", []),
                userTimeZone
              );

              const isHasOOOPast = find(
                datesOutOfOffice,
                (date) => date.diffDays <= 0
              );
              return (
                <div
                  key={"user_" + ind}
                  className={classnames(bem.e("user-row"), {
                    [bem.e("user-ooo")]: isHasOOOPast,
                  })}
                >
                  <Row>
                    <Col xs={6} className={bem.e("col-user")}>
                      <div className={bem.e("user-info")}>
                        <Avatar
                          src={user.info.avatar_url}
                          size={40}
                          className={bem.e("user-avatar")}
                          name={user.info.name}
                        />
                        <div className={bem.e("user-info-right")}>
                          <span className={bem.e("user-name")}>
                            {user.info.name}
                            {isHasOOOPast ? (
                              <span className={bem.e("vacation-icon")}>
                                <PalmTreeIcon />
                              </span>
                            ) : null}
                            <div className={bem.e("user-action")}>
                              <FaEllipsisH color={`#798186`} size={20} />
                            </div>
                          </span>
                          {userInfo(user)}
                          {datesOutOfOffice && datesOutOfOffice.length > 0
                            ? datesOutOfOffice.map((date) => (
                                <div className={bem.e("out-of-office")}>
                                  {props.intl.formatMessage({
                                    id: "process > out as of",
                                  })}{" "}
                                  {formatDisplayDateWithTimezone(
                                    date.from_date,
                                    dateFormatByServer,
                                    userTimeZone
                                  )}
                                  {" - "}
                                  {props.intl.formatMessage({
                                    id: "process > returns",
                                  })}{" "}
                                  {formatDisplayDateWithTimezone(
                                    date.to_date,
                                    dateFormatByServer,
                                    userTimeZone
                                  )}
                                </div>
                              ))
                            : null}
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} className={bem.e("col-user")}>
                      <div
                        className={classnames(
                          bem.e("user-roles-reel"),
                          bem.e("user-roles-reel-column")
                        )}
                      >
                        {user.info.reel && <Player url={user.info.reel} />}

                        <div className={bem.e("user-roles")}>
                          {user.candidate_for.map((role) => {
                            let selected_for_role =
                              role &&
                              process.data.fields[role.field] &&
                              process.data.fields[role.field].value &&
                              process.data.fields[role.field].value.id &&
                              parseInt(
                                process.data.fields[role.field].value.id
                              ) === user.info.id;
                            if (
                              role &&
                              process.data.fields[role.field] &&
                              process.data.fields[role.field].value &&
                              process.data.fields[role.field].value.users
                            )
                              selected_for_role =
                                process.data.fields[
                                  role.field
                                ].value.users.filter(
                                  (u) => parseInt(u.id) === user.info.id
                                ).length > 0;
                            const userVo = voiceTalentUsers.find(
                              (u) => parseInt(u.id) === user.info.id
                            );
                            const selectedUserForCurrentSlot = voiceTalentUsers.find(
                              (u) => parseInt(u.index) === selectedItem
                            );
                            const isDisabled =
                              role.is_disabled ||
                              voiceTalentUsers.length === currentTotalMembers ||
                              selectedUserForCurrentSlot;
                            return (
                              <div
                                className={classnames(
                                  bem.e("user-role-button"),
                                  {
                                    [bem.e(
                                      "user-role-assigned"
                                    )]: selected_for_role,
                                    [bem.e("user-role-disabled")]: isDisabled, //add condition for show disabled style
                                  }
                                )}
                                key={
                                  "user_role_" + user.info.id + "_" + role.field
                                }
                                onClick={() => {
                                  if (isDisabled) return;
                                  toggleUserRole(user, role);
                                }}
                              >
                                <span className={bem.e("user-role-title")}>
                                  {!userVo ? (
                                    role.title
                                  ) : (
                                    <span>
                                      {userVo.label ? (
                                        userVo.label
                                      ) : (
                                        <>
                                          {
                                            <FormattedMessage id="process > vo" />
                                          }
                                          {` `}
                                          {parseInt(userVo.index) + 1}
                                        </>
                                      )}
                                    </span>
                                  )}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              );
            })}
          </div>
        </div>
      </ShadowBox>
      <Spinner isLoading={isLoading} />
    </div>
  );
};

export default SelectTeamVo;
