import { debounce } from "lodash";
import React from "react";
export default function AutoSave({ debounceMs, submitForm, formik }) {
    const onSubmit = submitForm ? submitForm : formik.submitForm;
    const debouncedSubmit = React.useCallback(
        debounce(() => onSubmit(), debounceMs),
        [debounceMs, onSubmit]
    );
    React.useEffect(() => {
        debouncedSubmit();
    }, [formik.values]);
    return null;
}
