import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import bn from "utils/bemnames";
import Tooltip from "rc-tooltip";

const bem = bn.create("title-tooltip");
function checkIsTruncated(width, e) {
  if (!e) return false;
  return width < e.scrollWidth;
}
export default function TitleTooltip(props) {
  const { title } = props;
  const [isTruncated, setIsTruncated] = useState(false);
  const [width, setWidth] = useState(props.width);
  const ref = useRef();
  const refContent = useRef();

  useEffect(() => {
    const newWidth = ref.current.offsetWidth;
    setWidth(newWidth);
    window.addEventListener("resize", function() {
      setIsTruncated(checkIsTruncated(newWidth, refContent.current));
    });
    return () => {
      window.removeEventListener("resize", function() {
        setIsTruncated(checkIsTruncated(newWidth, refContent.current));
      });
    };
  }, []);
  useEffect(() => {
    // default is 132
    if (width !== 132) {
      setIsTruncated(checkIsTruncated(width, refContent.current));
    }
  }, [width]);
  return (
    <div ref={ref} className={bem.e("title-wrapper")}>
      <div ref={refContent} style={{ width }} className={bem.e("title")}>
        <Tooltip
          placement="topLeft"
          trigger={isTruncated ? ["click", "hover"] : []}
          overlayClassName={bem.e("tooltip")}
          overlay={<div className={bem.e("tooltip-content")}>{title}</div>}
        >
          <span>{title}</span>
        </Tooltip>
      </div>
    </div>
  );
}
TitleTooltip.defaultProps = {
  title: "",
  width: 132,
};
TitleTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  width: PropTypes.number,
};
