import * as React from "react";
import bn from "utils/bemnames";
import MasterListener from "./MasterListener";
import { FaInfoCircle } from "react-icons/lib/fa";
import Spinner from "components/Spinner";
import { filter, find, forEach, get, map } from "lodash";
import SystemItem from "./SystemItem";
import { FormattedMessage } from "react-intl";
import Tooltip from "rc-tooltip";
import classNames from "classnames";
import PreviewModal from "./PreviewModal";
import { validatePrivileges } from "utils/helpers";
import { PRIVILEGES } from "utils/constants";
const bem = bn.create("system-forms");
function getMissingFields(masterListenerForm, forms) {
  if (!masterListenerForm || forms.length === 0) return [];
  let fields = [];
  forEach(masterListenerForm._source.fields, (field) => {
    const fieldIsUsedInForms = filter(forms, (form) => {
      return find(form._source.fields, (f) => f.key === field.key);
    });
    if (fieldIsUsedInForms.length === 0) fields.push(field);
  });
  return fields;
}

const SystemForms = (props) => {
  const {
    isOpenForm,
    systemForms,
    loading,
    setIsOpenForm,
    onSaveHidden,
    onDuplicate,
    isOpenPreviewModal,
    setIsOpenPreviewModal,
    selectedSystemForm,
    setSelectedSystemForm,
    onSaveDefault,
    auth,
    user,
  } = props;
  const privileges = Object.values(get(auth, "info.privileges", [])) || [];

  const forms = filter(
    systemForms,
    (form) => form._source.form_type !== "master_listener_database_list"
  );
  const masterListenerForm = find(
    systemForms,
    (item) => item._source.form_type === "master_listener_database_list"
  );
  const totalForms = get(forms, "length", 0);
  const missingFields = getMissingFields(masterListenerForm, forms);
  const isMissingFields = missingFields.length > 0;
  const isEditable = validatePrivileges({
    requires: [PRIVILEGES.EDIT_FORMS],
    privileges,
    user,
  });
  return (
    <div className={bem.b()} id="system-forms">
      <MasterListener
        isOpen={isOpenForm === get(masterListenerForm, "_id")}
        setIsOpen={setIsOpenForm}
        masterListenerForm={masterListenerForm}
        currentStation={props.currentStation}
        isEditable={isEditable}
        onPreview={(data) => {
          setSelectedSystemForm(data);
          setIsOpenPreviewModal(true);
        }}
        currentServer={props.currentServer}
        getSystemForms={props.getSystemForms}
        getSystemForm={props.getSystemForm}
      />
      <div className={bem.e("forms-container")}>
        {forms.length > 0 ? (
          <div className={bem.e("forms-head")}>
            <span className={bem.e("views-text")}>
              <FormattedMessage id="form manager > views" />
            </span>
            <div className={bem.e("status-right")}>
              <span className={bem.e("fields-status")}>
                <span className={bem.e("published-text")}>
                  <FormattedMessage id="form manager > published views" />:
                </span>
                <div className={bem.e("selection-condition")}>
                  <strong
                    className={classNames(bem.e("status-text"), {
                      [bem.e("status-text-missing")]: isMissingFields,
                    })}
                  >
                    <FormattedMessage
                      id={
                        isMissingFields
                          ? "form builder > missing fields"
                          : "form manager > all fields used"
                      }
                    />
                  </strong>
                  <Tooltip
                    placement="top"
                    trigger={["click", "hover"]}
                    overlayClassName={bem.e("tooltip")}
                    getTooltipContainer={() => {
                      let container = document.querySelector("#system-forms");
                      if (container) return container;
                      return document.querySelector(".cr-app");
                    }}
                    overlay={
                      <div className={bem.e("tooltip-content")}>
                        <p className={bem.e("tooltip-text")}>
                          <FormattedMessage
                            id={
                              isMissingFields
                                ? "form builder > missing fields helper text"
                                : "form builder > all fields used helper text"
                            }
                          />
                        </p>
                        {isMissingFields && (
                          <ul className={bem.e("missing-fields")}>
                            {map(missingFields, (field, index) => (
                              <li key={index}>{field.label}</li>
                            ))}
                          </ul>
                        )}
                      </div>
                    }
                  >
                    <FaInfoCircle
                      size={18}
                      color={isMissingFields ? "#F2994A" : "#73A703"}
                    />
                  </Tooltip>
                </div>
              </span>
              <span className={bem.e("active-text")}>
                <FormattedMessage id="form manager > active" />
              </span>
            </div>
          </div>
        ) : null}

        {map(forms, (form, index) => {
          const isShowDefault = find(
            forms,
            (item) =>
              item._source.form_type === form._source.form_type &&
              form._id !== item._id
          );
          return (
            <SystemItem
              key={index}
              systemForm={form}
              isShowDefault={!!isShowDefault}
              isOpen={isOpenForm === form._id}
              setIsOpen={setIsOpenForm}
              onSaveHidden={onSaveHidden}
              onSaveDefault={onSaveDefault}
              onDuplicate={onDuplicate}
              currentStation={props.currentStation}
              isLast={totalForms - 1 === index}
              isEditable={isEditable}
              masterListenerForm={masterListenerForm}
              currentServer={props.currentServer}
              getSystemForm={props.getSystemForm}
              onPreview={(data) => {
                setSelectedSystemForm(data);
                setIsOpenPreviewModal(true);
              }}
            />
          );
        })}
      </div>
      {selectedSystemForm && (
        <PreviewModal
          isOpen={isOpenPreviewModal}
          systemForm={selectedSystemForm}
          currentStation={props.currentStation}
          currentServer={props.currentServer}
          onToggle={() => {
            setSelectedSystemForm(null);
            setIsOpenPreviewModal(!isOpenPreviewModal);
          }}
        />
      )}

      <Spinner isLoading={loading} />
    </div>
  );
};
export default SystemForms;
