import React from "react";
import { Row, Col, FormGroup, Button, Label } from "reactstrap";
import { FieldArray } from "formik";
import bn from "utils/bemnames";
import { FormattedMessage } from "react-intl";
import TextInput from "components/TextInput";
import {
  PlusIconSmaller,
  FacebookSocialIcon,
  InstagramSocialIcon,
  TwitterSocialIcon,
  YoutubeSocialIcon,
  SnapchatSocialIcon,
  TiktokSocialIcon,
  LinkedinSocialIcon,
  PinterestSocialIcon,
  DefaultSocialIcon,
  CloseIcon,
} from "components/CustomIcons";
import PropTypes from "prop-types";
import { get } from "lodash";
import classNames from "classnames";
const bem = bn.create("jock-console-item");
const configIcons = {
  facebook: FacebookSocialIcon,
  instagram: InstagramSocialIcon,
  twitter: TwitterSocialIcon,
  youtube: YoutubeSocialIcon,
  snapchat: SnapchatSocialIcon,
  tiktok: TiktokSocialIcon,
  linkedin: LinkedinSocialIcon,
  pinterest: PinterestSocialIcon,
  default: DefaultSocialIcon,
};
export default function SocialMedia(props) {
  const { isEdit, value } = props;
  if (!isEdit)
    return (
      <div className={bem.e("social-media")}>
        {value && value.length > 0
          ? value.map((item, index) => {
              const Icon = configIcons[item.label];
              return (
                <Row key={index}>
                  <Col>
                    <div
                      className={classNames(bem.e("social-row"), {
                        [bem.e("social-row-first")]: index === 0,
                      })}
                    >
                      <div className={bem.e("social-icon")}>
                        <Icon />
                      </div>
                      <FormGroup>
                        <div className={bem.e("jock-console-view-field")}>
                          {index === 0 && (
                            <Label>
                              <FormattedMessage id="jock console manager > url link" />
                            </Label>
                          )}
                          <div
                            className={bem.e("jock-console-view-field-value")}
                          >
                            <div
                              className={bem.e("jock-console-view-field-value")}
                            >
                              {item.url ? (
                                item.url
                              ) : (
                                <FormattedMessage id="jock console manager > none" />
                              )}
                            </div>
                          </div>
                        </div>
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
              );
            })
          : null}
      </div>
    );
  const { touched, errors, intl } = props;

  return (
    <div className={bem.e("social-media")}>
      <FieldArray
        name="fields.links"
        render={(arrayHelpers) => (
          <div>
            {value && value.length > 0
              ? value.map((item, index) => {
                  const Icon = configIcons[item.label];
                  return (
                    <Row key={index}>
                      <Col>
                        <div
                          className={classNames(bem.e("social-row"), {
                            [bem.e("social-row-first")]: index === 0,
                          })}
                        >
                          <div className={bem.e("social-icon")}>
                            <Icon />
                          </div>
                          <FormGroup>
                            <TextInput
                              label={
                                index === 0 ? (
                                  <span>
                                    <FormattedMessage id="jock console manager > url link" />
                                  </span>
                                ) : null
                              }
                              type="text"
                              name={`fields.links.${index}.url`}
                              placeholder={intl.formatMessage({
                                id: "jock console manager > paste url link",
                              })}
                              value={item.url}
                              error={
                                get(touched, `fields.links.${index}.url`) &&
                                get(errors, `fields.links.${index}.url`)
                              }
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                            />
                            {!!item.url && (
                              <span
                                className={bem.e("remove-link-icon")}
                                onClick={() => {
                                  if (item.label !== "default") {
                                    arrayHelpers.replace(index, {
                                      ...item,
                                      url: "",
                                    });
                                  } else {
                                    arrayHelpers.remove(index);
                                  }
                                }}
                              >
                                <CloseIcon />
                              </span>
                            )}
                          </FormGroup>
                        </div>
                      </Col>
                    </Row>
                  );
                })
              : null}
            {get(touched, "fields.links") &&
              typeof get(errors, "fields.links") === "string" && (
                <span className="text-danger">{errors.fields.links}</span>
              )}
            <div className={bem.e("add-row-button")}>
              <Button
                onClick={() =>
                  arrayHelpers.push({
                    url: "",
                    label: "default",
                  })
                }
                color="blue"
                outline
                className="btn-add-row btn-radius"
                type="button"
              >
                <span className={bem.e("plus-icon")}>
                  <PlusIconSmaller />
                </span>
                <FormattedMessage id="jock console manager > add a row" />
              </Button>
            </div>
          </div>
        )}
      />
    </div>
  );
}
SocialMedia.defaultProps = {
  isEdit: false,
};
SocialMedia.propTypes = {
  isEdit: PropTypes.bool,
};
