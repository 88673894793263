import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { get } from "lodash";
import bn from "utils/bemnames";
import { CloseIcon } from "components/CustomIcons";
import MediaImage from "./MediaImage";
import MediaPdf from "./MediaPdf";

import { isPdf } from "utils/helpers";
import classNames from "classnames";

const bem = bn.create("preview-asset-modal");

const PreviewMediaModal = ({ onToggle, isOpen, comments, ...rest }) => {
  if (!rest.asset || !isOpen) return null;
  const isPdfFile = isPdf(get(rest, "asset.path", ""));

  return (
    <Modal isOpen={isOpen} className={bem.b()} size="lg">
      <ModalHeader toggle={onToggle} className={bem.e("header")}>
        <div className={bem.e("file-title")}>{get(rest, "asset.name", "")}</div>
        <div className={bem.e("close")} onClick={onToggle}>
          <CloseIcon />
        </div>
      </ModalHeader>
      <ModalBody className={bem.e("body")}>
        <div
          className={classNames(bem.e("content"), {
            [bem.e("content-pdf")]: isPdfFile,
          })}
        >
          <h5 className={bem.e("field-title")}>
            {get(rest, "media.label", "")}
          </h5>
          {isPdfFile ? (
            <MediaPdf bem={bem} asset={rest.asset} onClose={onToggle} />
          ) : (
            <MediaImage
              bem={bem}
              asset={rest.asset}
              comments={comments}
              setIsVisibleComment={rest.setIsVisibleComment}
              isVisibleComment={rest.isVisibleComment}
            />
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

PreviewMediaModal.defaultProps = {
  isOpen: false,
  isView: false,
  onToggle: () => {},
};
PreviewMediaModal.propTypes = {
  isView: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};
export default PreviewMediaModal;
