import AddResourcePage from "./view";
import { connect } from "react-redux";
import { compose, lifecycle, withHandlers } from "recompose";
import {
  getResource,
  initNewResource,
  saveFormValues,
  updateResources,
  deleteResource,
  getResources
} from "store/actions/resources";
import { getTemplate } from "store/actions/template";
import { injectIntl } from "react-intl";
import { find, get } from "lodash";
import { pageSize } from "utils/config";

const preparePage = props => {
  const { match } = props;
  let resource_id = get(match, "params.resources_id");
  if (!resource_id) {
    props.initNewResource({});
  }
};

export default injectIntl(
  compose(
    connect(
      (state, props) => {
        const { match } = props;
        let resource_id = get(match, "params.resources_id");
        let resourceFromList = get(
          state,
          "resources.resources.filter_resources_list"
        )
          ? find(
              get(state, "resources.resources.filter_resources_list.data"),
              resource => resource._id === resource_id
            )
          : null;
        let resource = resourceFromList
          ? { data: resourceFromList._source, error: null, loading: false }
          : state.resources.resource;
        let template_id = get(match, "params.template_id");
        let ui_hint = (template_id + ".").split(".")[1];
        return {
          match_resource_id: resource_id,
          match_template_id: template_id,
          ui_hint,
          resource,
          isUpdate: !!resource_id,
          isView: !!resource_id,
          template: state.templates.template.data,
          template_state: state.templates.template,
          auth: state.auth,
          resources: state.resources,
          isLoading:
            state.templates.template.loading ||
            state.resources.resource.loading ||
            state.resources.addResources.loading ||
            state.resources.updateResources.loading
        };
      },
      {
        initNewResource,
        getTemplate,
        saveFormValues,
        updateResources,
        getResource,
        deleteResource,
        getResources
      }
    ),
    withHandlers({
      getResources: ({ resources, getResources }) => () => {
        const sort = get(resources, "filter_resources_list.sort", "created_ts");
        const page = get(resources, "filter_resources_list.page", "1");
        const rpp = get(resources, "filter_resources_list.rpp", pageSize);
        const search = get(resources, "filter_resources_list.search", "");
        getResources({ filter: "resources_list", sort, page, rpp, search });
      }
    }),
    withHandlers({
      onFormSubmit: ({ saveFormValues, updateResources, getResources }) => (
        values,
        cb
      ) => {
        let dataToSend = {
          id: values.id,
          title: values.title,
          subtitle: values.subtitle,
          description: values.description,
          quantity: values.quantity,
          unit_value: values.unit_value,
          supplier: values.supplier,
          category: values.category.value,
          station_id: values.station_id
        };
        if (values.isUpdate) {
          updateResources(values.id, dataToSend, cb);
        } else {
          saveFormValues(dataToSend, cb);
        }
      }
    }),
    lifecycle({
      componentDidMount() {
        const { match, resource } = this.props;
        const id = get(match, "params.resources_id");
        if (id && (!resource.data || Object.keys(resource.data).length === 0)) {
          this.props.getResource(id);
        } else {
          // this.props.getResources();
        }
        preparePage(this.props);
      },
      componentWillUpdate(nextProps) {
        if (this.props.match_resource_id !== nextProps.match_resource_id) {
          preparePage(nextProps, true);
        }
      }
    })
  )(AddResourcePage)
);
