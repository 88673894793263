import React from "react";
import bn from "utils/bemnames";
import { MenuIcon } from "components/CustomIcons";
import classnames from "classnames";
import { FaClose } from "react-icons/lib/fa";
import { labelToNameField } from "utils/helpers";
import { FormGroup, Row, Col, Label } from "reactstrap";
import ToggleSwitch from "components/ToggleSwitch";
import TextInput from "components/TextInput";
import { default as CheckboxDefault } from "components/Checkbox";
import { default as RadioButtonDefault } from "components/Radio";
import { get, xor, findIndex, filter, isString } from "lodash";
import { Asterisk } from "components/Elements";
import { FormattedMessage } from "react-intl";
const bem = bn.create("form-builder-fields");
const demographics = [
  {
    label: "gender",
    value: "gender",
  },
  {
    label: "age range",
    value: "age_range",
  },
  {
    label: "education level",
    value: "education_level",
  },
  {
    label: "household income",
    value: "household_income",
  },
];
const genders = [
  {
    label: "adult",
    value: "adult",
  },
  {
    label: "male",
    value: "male",
  },
  {
    label: "female",
    value: "female",
  },
];
const educations = [
  {
    label: "some high school",
    value: "some high school",
  },
  {
    label: "high school degree",
    value: "high school degree",
  },
  {
    label: "some college",
    value: "some college",
  },
  {
    label: "associate's degree",
    value: "associate's degree",
  },
  {
    label: "bachelor's degree",
    value: "bachelor's degree",
  },
  {
    label: "master's degree or higher",
    value: "master's degree or higher",
  },
];
const households = [
  {
    label: "< $50,000",
    value: "< $50,000",
  },
  {
    label: "$50,000 - $75,000",
    value: "$50,000 - $75,000",
  },
  {
    label: "$75,000 - $100,000",
    value: "$75,000 - $100,000",
  },
  {
    label: "$100,000 - $200,000",
    value: "$100,000 - $200,000",
  },
  {
    label: "$200,000 - $300,000",
    value: "$200,000 - $400,000",
  },
  {
    label: "> $300,000",
    value: "> $300,000",
  },
];
const Demographics = ({
  intl,
  isView,
  isNew,
  field,
  value,
  errors,
  touched,
  onChange,
  onDelete,
  allowDelete,
  isFormBuilder,
  highlightFields,
  disabled,
  isShowOffText,
}) => {
  // UI for form builder
  if (isFormBuilder) {
    if (isNew) {
      const isHighlightFormField =
        filter(
          highlightFields,
          (highlightFieldItem) =>
            highlightFieldItem.uuid === value.uuid &&
            highlightFieldItem.count_highlight > 0
        ).length > 0;
      return (
        <Row>
          <Col className="form-builder-field">
            <div className={bem.b()}>
              <div
                className={classnames(bem.e("form-field-group"), {
                  [bem.e("form-field-group-highlight")]: isHighlightFormField,
                  [bem.e("form-field-group-disable")]:
                    highlightFields.length > 0 &&
                    !isHighlightFormField &&
                    value.is_controlled,
                })}
              >
                <div className={bem.e("form-field-group-head")}>
                  <div className={bem.e("form-field-group-head-single-left")}>
                    <span className={bem.e("menu-icon")}>
                      <MenuIcon />
                    </span>
                    <Row>
                      <Col xs={9}>
                        <div className={bem.e("input-label")}>
                          <TextInput
                            label={null}
                            name="label"
                            type="text"
                            placeholder={intl.formatMessage({
                              id: "form builder > label demographics",
                            })}
                            disabled={disabled}
                            value={value.label}
                            onChange={({ target }) => {
                              onChange({
                                ...value,
                                label: target.value,
                              });
                            }}
                            error={get(errors, "label", "")}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                  {allowDelete && (
                    <div
                      className={bem.e("form-field-group-head-right")}
                      onClick={onDelete}
                    >
                      <FaClose size="23" color="#C2D4E0" />
                    </div>
                  )}
                </div>
                <Row className={bem.e("form-field-group-bottom")}>
                  <Col xs={9}>
                    <div className={bem.e("include-subtitle")}>
                      <FormattedMessage id="form builder > include the following" />
                    </div>
                    <div className={bem.e("demographics-group")}>
                      <React.Fragment>
                        {demographics.map((demographic, index) => (
                          <FormGroup key={index}>
                            <CheckboxDefault
                              text={
                                <span className={bem.e("demographic-label")}>
                                  <FormattedMessage
                                    id={`form builder > ${demographic.label}`}
                                  />
                                </span>
                              }
                              checked={
                                findIndex(
                                  value.includes,
                                  (include) => include === demographic.value
                                ) > -1
                              }
                              disabled={disabled}
                              onChange={() => {
                                let includes = xor(value.includes, [
                                  demographic.value,
                                ]);
                                onChange({
                                  ...value,
                                  includes,
                                });
                              }}
                            />
                          </FormGroup>
                        ))}
                        {!!get(errors, "includes", "") && (
                          <span className="text-danger">
                            {get(errors, "includes", "")}
                          </span>
                        )}
                      </React.Fragment>
                    </div>
                  </Col>
                  <Col xs={3}>
                    <FormGroup>
                      <ToggleSwitch
                        leftComponent={
                          <span
                            className={classnames({
                              [bem.e("switch-active")]: !get(
                                value,
                                "is_required",
                                false
                              ),
                            })}
                          >
                            {isShowOffText
                              ? intl.formatMessage({
                                  id: "form builder > off",
                                })
                              : true}
                          </span>
                        }
                        rightComponent={
                          <span
                            className={classnames({
                              [bem.e("switch-active")]: get(
                                value,
                                "is_required",
                                false
                              ),
                            })}
                          >
                            {intl.formatMessage({
                              id: "form builder > required",
                            })}
                          </span>
                        }
                        name="is_required"
                        switchProps={{
                          checked: !get(value, "is_required", false),
                          onChange: (checked) => {
                            onChange({
                              ...value,
                              is_required: !checked,
                            });
                          },
                          disabled: disabled,
                          offColor: "#795AFA",
                          onColor: "#E3ECF1",
                          uncheckedIcon: false,
                          checkedIcon: false,
                          handleDiameter: 22,
                          width: 50,
                          height: 26,
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row>
          <Col>
            <div className={bem.b()}>
              <div className={bem.e("form-field-group-saved")}>
                <Label className={bem.e("form-field-label")}>
                  <span>
                    {get(value, "label", "")}{" "}
                    {get(value, "is_required", false) && <Asterisk>*</Asterisk>}
                  </span>
                </Label>
                {/* gender */}
                {get(value, "includes", []).includes("gender") && (
                  <div className={bem.e("demographic-row-gender")}>
                    <div className={bem.e("demographic-row-label")}>
                      <FormattedMessage id="form builder > gender" />
                    </div>
                    {genders.map((item, index) => (
                      <FormGroup key={index}>
                        <RadioButtonDefault
                          checked={false}
                          text={
                            <span className={bem.e("demographic-label")}>
                              <FormattedMessage
                                id={`form builder > gender ${item.label}`}
                              />
                            </span>
                          }
                          onChange={(checked) => {}}
                        />
                      </FormGroup>
                    ))}
                  </div>
                )}

                {/* age range */}
                {get(field, "includes", []).includes("age_range") && (
                  <div
                    className={classnames(
                      bem.e("demographic-row-common"),
                      bem.e("demographic-row-age-range")
                    )}
                  >
                    <div className={bem.e("demographic-row-label")}>
                      <FormattedMessage id="form builder > age range" />
                    </div>
                    <Row>
                      <Col xs={3}>
                        <FormGroup>
                          <TextInput
                            name=""
                            type="text"
                            placeholder="25"
                            onChange={() => {}}
                          />
                        </FormGroup>
                      </Col>
                      <span className={bem.e("demographic-age-range-divider")}>
                        TO
                      </span>
                      <Col xs={3}>
                        <FormGroup>
                          <TextInput
                            name=""
                            type="text"
                            placeholder="54"
                            onChange={() => {}}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                )}

                {/* education level */}
                {get(value, "includes", []).includes("education_level") && (
                  <div className={bem.e("demographic-row-common")}>
                    <div className={bem.e("demographic-row-label")}>
                      <FormattedMessage id="form builder > education level" />
                    </div>
                    {educations.map((item, index) => (
                      <FormGroup key={index}>
                        <RadioButtonDefault
                          checked={false}
                          text={
                            <span className={bem.e("demographic-label")}>
                              <FormattedMessage
                                id={`form builder > education ${item.label}`}
                              />
                            </span>
                          }
                          onChange={(checked) => {}}
                        />
                      </FormGroup>
                    ))}
                  </div>
                )}

                {/* households */}
                {get(value, "includes", []).includes("household_income") && (
                  <div className={bem.e("demographic-row-common")}>
                    <div className={bem.e("demographic-row-label")}>
                      <FormattedMessage id="form builder > household income" />
                    </div>
                    {households.map((item, index) => (
                      <FormGroup key={index}>
                        <RadioButtonDefault
                          checked={false}
                          text={
                            <span className={bem.e("demographic-label")}>
                              {item.label}
                            </span>
                          }
                          onChange={(checked) => {}}
                        />
                      </FormGroup>
                    ))}
                  </div>
                )}

                {allowDelete && (
                  <div
                    className={bem.e("form-field-group-saved-button-close")}
                    onClick={onDelete}
                  >
                    <FaClose size="23" color="#C2D4E0" />
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    // ui for form
  } else {
    const fieldName = labelToNameField(get(field, "label", ""));
    if (isView) {
      return (
        <Row>
          <Col>
            <div className={bem.b()}>
              <div className={bem.e("form-field-group-saved")}>
                <Label className={bem.e("form-field-label")}>
                  <span>{get(field, "label", "")} </span>
                </Label>
                {/* gender */}
                {get(field, "includes", []).includes("gender") && (
                  <div className={bem.e("demographic-row-gender")}>
                    <div className={bem.e("demographic-row-label")}>
                      <FormattedMessage id="form builder > gender" />
                    </div>
                    <span className="order-view-value">
                      {get(value, "gender") ? (
                        get(value, "gender")
                      ) : (
                        <FormattedMessage id="form builder > none" />
                      )}
                    </span>
                  </div>
                )}
                {/* Age range */}
                {get(field, "includes", []).includes("age_range") && (
                  <div className={bem.e("demographic-row-age-range")}>
                    <div className={bem.e("demographic-row-label")}>
                      <FormattedMessage id="form builder > age range" />
                    </div>
                    <span className="order-view-value">
                      {get(value, "age_range.from") ? (
                        `${get(value, "age_range.from", 0)} - ${get(
                          value,
                          "age_range.to",
                          0
                        )}`
                      ) : (
                        <FormattedMessage id="form builder > none" />
                      )}
                    </span>
                  </div>
                )}
                {/* education level */}
                {get(field, "includes", []).includes("education_level") && (
                  <div className={bem.e("demographic-row-common")}>
                    <div className={bem.e("demographic-row-label")}>
                      <FormattedMessage id="form builder > education level" />
                    </div>
                    <span className="order-view-value">
                      {get(value, "education") ? (
                        get(value, "education")
                      ) : (
                        <FormattedMessage id="form builder > none" />
                      )}
                    </span>
                  </div>
                )}

                {/* households */}
                {get(field, "includes", []).includes("household_income") && (
                  <div className={bem.e("demographic-row-common")}>
                    <div className={bem.e("demographic-row-label")}>
                      <FormattedMessage id="form builder > household income" />
                    </div>
                    <span className="order-view-value">
                      {get(value, "household_income") ? (
                        get(value, "household_income")
                      ) : (
                        <FormattedMessage id="form builder > none" />
                      )}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    return (
      <Row>
        <Col>
          <div className={bem.b()}>
            <div className={bem.e("form-field-group-saved")}>
              <Label className={bem.e("form-field-label")}>
                <span>
                  {get(field, "label", "")}{" "}
                  {get(field, "is_required", false) && <Asterisk>*</Asterisk>}
                </span>
              </Label>
              {/* gender */}
              {get(field, "includes", []).includes("gender") && (
                <div className={bem.e("demographic-row-gender")}>
                  <div className={bem.e("demographic-row-label")}>
                    <FormattedMessage id="form builder > gender" />
                  </div>
                  {genders.map((item, index) => (
                    <FormGroup key={index}>
                      <RadioButtonDefault
                        checked={get(value, "gender") === item.value}
                        text={
                          <span className={bem.e("demographic-label")}>
                            <FormattedMessage
                              id={`form builder > gender ${item.label}`}
                            />
                          </span>
                        }
                        disabled={disabled}
                        onChange={(checked) => {
                          onChange({
                            ...value,
                            gender: item.value,
                          });
                        }}
                      />
                    </FormGroup>
                  ))}
                  {!!get(errors, fieldName, "") &&
                    get(touched, fieldName, false) && (
                      <span className="text-danger">
                        {get(errors, `${fieldName}.gender`, "") && (
                          <span className="text-danger-line">
                            {get(errors, `${fieldName}.gender`, "")}
                          </span>
                        )}
                      </span>
                    )}
                </div>
              )}

              {/* age gender */}
              {get(field, "includes", []).includes("age_range") && (
                <div
                  className={classnames(
                    bem.e("demographic-row-common"),
                    bem.e("demographic-row-age-range")
                  )}
                >
                  <div className={bem.e("demographic-row-label")}>
                    <FormattedMessage id="form builder > age range" />
                  </div>
                  <Row>
                    <Col xs={3}>
                      <FormGroup>
                        <TextInput
                          name=""
                          type="number"
                          placeholder="25"
                          value={get(value, "age_range.from", "")}
                          disabled={disabled}
                          onChange={({ target }) => {
                            const value1 = target.value;
                            let value2 = get(value, "age_range.to", "");
                            if (
                              !isNaN(parseFloat(value1)) &&
                              isFinite(value1)
                            ) {
                              if (
                                !isNaN(parseFloat(value2)) &&
                                isFinite(value2) &&
                                parseInt(value1) >= parseInt(value2)
                              )
                                value2 = parseInt(value1) + 1;
                              if (!value2) value2 = parseInt(value1) + 1;
                            }
                            onChange({
                              ...value,
                              age_range: {
                                from: value1,
                                to: value2,
                              },
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <span className={bem.e("demographic-age-range-divider")}>
                      TO
                    </span>
                    <Col xs={3}>
                      <FormGroup>
                        <TextInput
                          name=""
                          type="number"
                          placeholder="54"
                          value={get(value, "age_range.to", "")}
                          disabled={disabled}
                          onChange={({ target }) => {
                            const value1 = get(value, "age_range.from", "");
                            const value2 = target.value;
                            onChange({
                              ...value,
                              age_range: {
                                from: value1,
                                to: value2,
                              },
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {!!get(errors, fieldName, "") &&
                    get(touched, fieldName, false) && (
                      <span className="text-danger">
                        {get(errors, `${fieldName}.age_range.from`, "") && (
                          <span className="text-danger-line">
                            {get(errors, `${fieldName}.age_range.from`, "")}
                          </span>
                        )}
                        {get(errors, `${fieldName}.age_range.to`, "") && (
                          <span className="text-danger-line">
                            {get(errors, `${fieldName}.age_range.to`, "")}
                          </span>
                        )}
                      </span>
                    )}
                </div>
              )}

              {/* education level */}
              {get(field, "includes", []).includes("education_level") && (
                <div className={bem.e("demographic-row-common")}>
                  <div className={bem.e("demographic-row-label")}>
                    <FormattedMessage id="form builder > education level" />
                  </div>
                  {educations.map((item, index) => (
                    <FormGroup key={index}>
                      <RadioButtonDefault
                        checked={get(value, "education") === item.value}
                        text={
                          <span className={bem.e("demographic-label")}>
                            <FormattedMessage
                              id={`form builder > education ${item.label}`}
                            />
                          </span>
                        }
                        disabled={disabled}
                        onChange={() => {
                          onChange({
                            ...value,
                            education: item.value,
                          });
                        }}
                      />
                    </FormGroup>
                  ))}
                  {!!get(errors, fieldName, "") &&
                    get(touched, fieldName, false) && (
                      <span className="text-danger">
                        {get(errors, `${fieldName}.education`, "") && (
                          <span className="text-danger-line">
                            {get(errors, `${fieldName}.education`, "")}
                          </span>
                        )}
                      </span>
                    )}
                </div>
              )}

              {/* households */}
              {get(field, "includes", []).includes("household_income") && (
                <div className={bem.e("demographic-row-common")}>
                  <div className={bem.e("demographic-row-label")}>
                    <FormattedMessage id="form builder > household income" />
                  </div>
                  {households.map((item, index) => (
                    <FormGroup key={index}>
                      <RadioButtonDefault
                        checked={get(value, "household_income") === item.value}
                        text={
                          <span className={bem.e("demographic-label")}>
                            {item.label}
                          </span>
                        }
                        disabled={disabled}
                        onChange={() => {
                          onChange({
                            ...value,
                            household_income: item.value,
                          });
                        }}
                      />
                    </FormGroup>
                  ))}
                  {!!get(errors, fieldName, "") &&
                    get(touched, fieldName, false) && (
                      <span className="text-danger">
                        {get(errors, `${fieldName}.household_income`, "") && (
                          <span className="text-danger-line">
                            {get(errors, `${fieldName}.household_income`, "")}
                          </span>
                        )}
                      </span>
                    )}
                </div>
              )}

              {!!get(errors, fieldName, "") &&
                get(touched, fieldName, false) &&
                isString(get(errors, fieldName, "")) && (
                  <span className="text-danger">
                    {get(errors, fieldName, "")}
                  </span>
                )}
            </div>
          </div>
        </Col>
      </Row>
    );
  }
};
Demographics.defaultProps = {
  isNew: false,
  isFormBuilder: true,
  errors: {},
  touched: {},
  onChange: () => {},
  onDelete: () => {},
  allowDelete: true,
  disabled: false,
  isShowOffText: true,
};
export default Demographics;
