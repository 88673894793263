import React, { useEffect, useRef } from "react";
import { Form, Button, Label } from "reactstrap";
import bn from "utils/bemnames";
import { Asterisk } from "components/Elements";
import { FormattedMessage } from "react-intl";
import ToastManager from "components/ToastManager";
import { FaPlus } from "react-icons/lib/fa";
import * as Yup from "yup";
import { Formik, yupToFormErrors, validateYupSchema } from "formik";
import classNames from "classnames";
import {
  findIndex,
  get,
  isArray,
  map,
  intersection,
  split,
  filter,
  forEach,
  uniq,
} from "lodash";
import FieldsFormBuilderModal from "components/FormBuilder/FieldsFormBuilderModal";
import SortableComponent from "../SortableComponent";
import ConfirmationModal from "components/ConfirmationModal";
import { generateField } from "../helper";

const bem = bn.create("master-listener");
const useOutsideAlerter = (ref, props) => {
  const {
    isOpenChannelPreviewModal,
    isOpenFieldsFormBuilderModal,
    isDirty,
  } = props;
  /**
   * Alert if clicked on outside of element
   */
  function handleClickOutside(event) {
    // detect click on scrollbar
    if (event.clientX + 20 >= window.innerWidth) {
      return;
    }

    const classNamesDisabled = [
      "react-select__clear-indicator",
      "disable-unsaved",
      "rc-tooltip-inner",
    ];
    const eventClasses = split(
      get(event, "target.parentElement.parentElement.className", ""),
      " "
    );
    let isDisabled =
      intersection(eventClasses, classNamesDisabled).length > 0 ||
      event.target.classList.contains("disable-unsaved");
    if (
      ref.current &&
      !ref.current.contains(event.target) &&
      !isDisabled &&
      get(event, "target.parentElement.parentElement.className.animVal", "") !==
        "disable-unsaved" // this is edit channel button
    ) {
      if (!isOpenChannelPreviewModal && !isOpenFieldsFormBuilderModal) {
        if (isDirty) {
          if (!document.body.classList.contains("modal-open")) {
            props.setIsOpenConfirmationActionsUnsavedModal(true);
          }
        } else {
          // if (event.clientX + 7 >= event.target.clientWidth) {
          //   return;
          // } else {
          //   if (event.target.classList.contains("modal")) return;
          //   if (!document.body.classList.contains("modal-open")) {
          //     onClose();
          //   }
          // }
        }
      }
    }
  }
  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
};
const MasterListenerForm = (props) => {
  const {
    onSubmitForm,
    setIsOpenFieldsFormBuilderModal,
    isOpenFieldsFormBuilderModal,
    intl,
    listFields,
    setListFields,
    masterListenerForm,
    currentStation,
    isOpenChannelPreviewModal,
    isEditable,
    currentServer,
  } = props;
  const formikRef = useRef(null);
  const wrapperRef = useRef(null);
  const validateBeforeSubmit = (values, formikObject) => {
    const { fields } = values;
    let errors;
    fields.forEach((field, index) => {
      const { key } = field;
      if (key === "dropdown" || "radio" || "checkbox") {
        const { options } = field;
        let indexError = findIndex(
          options,
          ({ label, value }) => !label || !value
        );
        if (indexError > -1) {
          let fieldsErrors = errors ? errors.fields : [];
          fieldsErrors[index] = {
            ...fieldsErrors[index],
            options: intl.formatMessage({
              id: "validate > option is required",
            }),
          };
          errors = {
            ...errors,
            fields: fieldsErrors,
          };
        }
      }
      if (key === "home_address" || "business_address") {
        const { sub_fields } = field;
        let indexError = findIndex(sub_fields, ({ label }) => !label);
        if (indexError > -1) {
          let fieldsErrors = errors ? errors.fields : [];
          fieldsErrors[index] = {
            ...fieldsErrors[index],
            sub_fields: intl.formatMessage({
              id: "validate > label is required",
            }),
          };
          errors = {
            ...errors,
            fields: fieldsErrors,
          };
        }
      }
    });
    if (errors) {
      formikObject.setErrors(errors);
    } else {
      onSubmitForm(values, formikObject);
    }
  };
  const formSchema = Yup.object().shape({
    fields: Yup.array().of(
      Yup.object().shape({
        label: Yup.string()
          .required(intl.formatMessage({ id: "validate > label is required" }))
          .test({
            name: "duplicate_label",
            message: intl.formatMessage({
              id: "validate > label name already exists",
            }),
            test: function(value) {
              let count = 0;
              this.options.context.customFields.forEach((label) => {
                if (
                  value.trim() !== "" &&
                  label.trim().toLowerCase() === value.trim().toLowerCase()
                ) {
                  count++;
                }
              });
              return !(count > 1);
              // return true;
            },
          }),
      })
    ),
  });

  useOutsideAlerter(wrapperRef, {
    isOpenChannelPreviewModal,
    isOpenFieldsFormBuilderModal,
    setIsOpenConfirmationActionsUnsavedModal:
      props.setIsOpenConfirmationActionsUnsavedModal,
    isDirty: props.isDirty,
  });
  const serverCountries = [
    currentServer.country,
    ...currentServer.neighboring_countries,
  ];
  return (
    <div ref={wrapperRef}>
      <Formik
        initialValues={{
          id: masterListenerForm._id,
          name: get(masterListenerForm, "_source.name"),
          form_type: get(masterListenerForm, "_source.form_type"),
          station_id: currentStation.key,
          fields: get(masterListenerForm, "_source.fields", []),
        }}
        validate={(values) => {
          if (!isEditable) return;
          try {
            validateYupSchema(values, formSchema, true, {
              customFields: map(values.fields, (field) => field.label),
            });
          } catch (err) {
            return yupToFormErrors(err);
          }
          return {};
        }}
        onSubmit={validateBeforeSubmit}
        ref={formikRef}
      >
        {(formProps) => {
          const { values } = formProps;
          let keyFields = [];
          forEach(values.fields, (field) => {
            keyFields = [...keyFields, field.key];
            if (field.sub_fields && field.sub_fields.length > 0) {
              keyFields = [
                ...keyFields,
                ...map(field.sub_fields, (item) => item.key),
              ];
            }
          });
          const onSelectedField = (value) => {
            setIsOpenFieldsFormBuilderModal(false);
            // eslint-disable-next-line no-lone-blocks
            let fields = [];
            if (isArray(value)) {
              fields = [
                ...values.fields,
                ...map(value, (i) => generateField(i, serverCountries)),
              ];
              formProps.setFieldValue("fields", fields);
              formProps.setFieldTouched("fields", false);
              props.setIsDirty(true);
              return;
            }
            const newField = generateField(value, serverCountries);
            if (newField.multi_field) {
              const exists = filter(
                values.fields,
                (item) => item.key === newField.key
              ).length;
              // Case: When user added a group -> add unique label
              if (exists > 0) {
                fields = [
                  ...values.fields,
                  {
                    ...newField,
                    label: `${newField.label} ${exists}`,
                    sub_fields: newField.sub_fields.map((i) =>
                      generateField(i, serverCountries)
                    ),
                  },
                ];
                // Case: when user add new group or add new sub field -> we will add this group field to values
              } else {
                fields = [
                  ...values.fields,
                  {
                    ...newField,
                    sub_fields: newField.sub_fields.map((i) =>
                      generateField(i, serverCountries)
                    ),
                  },
                ];
              }
            } else {
              fields = [...values.fields, newField];
            }

            formProps.setFieldValue("fields", fields);
            formProps.setFieldTouched("fields", false);
            props.setIsDirty(true);
          };
          return (
            <Form
              className={bem.e("form")}
              onSubmit={formProps.handleSubmit}
              noValidate
            >
              <div className={bem.e("form-name")}>
                <Label>
                  <FormattedMessage id="form manager > form name" />
                  <Asterisk>*</Asterisk>
                </Label>
                <div className={bem.e("form-name-title")}>
                  <FormattedMessage id="form manager > master listener database list" />
                </div>
              </div>
              <div className={bem.e("form-fields")}>
                <div className={bem.e("first-last-name")}>
                  <FormattedMessage id="form builder > first and last name" />
                </div>
                <div className={bem.e("custom-fields-sort")}>
                  <SortableComponent
                    data={values.fields}
                    onSorted={(sortItems) => {
                      // always keep first name last name field on the top
                      const nameField = sortItems.find(
                        (item) => item.key === "first_last_name"
                      );
                      const finalSortItems = [
                        nameField,
                        ...filter(
                          sortItems,
                          (item) => item.key !== "first_last_name"
                        ),
                      ];
                      props.setIsDirty(true);
                      formProps.setFieldValue("fields", finalSortItems);
                    }}
                    formProps={{
                      ...formProps,
                      isView: !isEditable,
                      disabled: false,
                      currentStation: props.currentStation,
                      setIsDirty: props.setIsDirty,
                      isSystemForm: false,
                      isMasterListener: true,
                      currentServer,
                      onDeleteField: (field) => {
                        if (props.countConfirmationMasterList < 3) {
                          props.setDeletingField(field);
                          props.setIsOpenConfirmationDeleteModal(true);
                          props.setCountConfirmationMasterList(
                            props.countConfirmationMasterList + 1
                          );
                        } else {
                          const newFields = values.fields;
                          newFields.splice(field, 1);
                          formProps.setFieldValue("fields", newFields);
                          props.setIsOpenConfirmationDeleteModal(false);
                          props.setDeletingField(null);
                        }
                      },
                    }}
                  />
                </div>
                <div className={bem.e("field-buttons")}>
                  <Button
                    className={classNames(bem.e("button-create"), "btn-radius")}
                    color="blue"
                    outline
                    disabled={!isEditable}
                    onClick={() => {
                      if (!isEditable) return;
                      setIsOpenFieldsFormBuilderModal(
                        !isOpenFieldsFormBuilderModal
                      );
                      setListFields("masterListener");
                    }}
                  >
                    <i className={bem.e("button-icon")}>
                      <FaPlus />
                    </i>
                    <FormattedMessage id="form manager > add a field" />
                  </Button>
                  <Button
                    className={classNames(bem.e("button-create"), "btn-radius")}
                    color="blue"
                    outline
                    disabled={!isEditable}
                    onClick={() => {
                      if (!isEditable) return;
                      setIsOpenFieldsFormBuilderModal(
                        !isOpenFieldsFormBuilderModal
                      );
                      setListFields("masterListenerCreate");
                    }}
                  >
                    <i className={bem.e("button-icon")}>
                      <FaPlus />
                    </i>
                    <FormattedMessage id="form manager > create new" />
                  </Button>
                </div>
              </div>
              <div className={bem.e("form-buttons")}>
                <Button
                  color="blue"
                  type="submit"
                  className="btn btn-radius btn-submit"
                  disabled={!isEditable}
                  onClick={() => {
                    if (!isEditable) return;
                    setTimeout(() => {
                      const formErrors = get(formikRef, "current.state.errors");
                      if (formErrors && Object.keys(formErrors).length) {
                        ToastManager.show({
                          title: intl.formatMessage({
                            id: "toast > title not saved",
                          }),
                          message: intl.formatMessage({
                            id:
                              "toast > message error please correct the hilighted fields",
                          }),
                          level: "error",
                        });
                        return;
                      }
                    }, 20);
                  }}
                >
                  <FormattedMessage id={`form manager > button submit`} />
                </Button>
                <Button
                  color="blue"
                  outline
                  className="btn btn-radius"
                  onClick={props.onCancel}
                >
                  <FormattedMessage id={`form manager > button cancel`} />
                </Button>
              </div>
              <FieldsFormBuilderModal
                isOpen={isOpenFieldsFormBuilderModal}
                onSelect={onSelectedField}
                list={listFields}
                disabledFields={uniq(keyFields)}
                onToggle={() =>
                  setIsOpenFieldsFormBuilderModal(!isOpenFieldsFormBuilderModal)
                }
              />

              {/* actions confirmation delete field*/}
              <ConfirmationModal
                isOpen={props.isOpenConfirmationDeleteModal}
                title={intl.formatMessage({
                  id: "form manager > title delete master field confirmation",
                })}
                isCloseOutside={false}
                className={bem.e("confirmation-modal")}
                confirmTitle={intl.formatMessage({
                  id: "form manager > button delete",
                })}
                cancelTitle={intl.formatMessage({
                  id: "form manager > button cancel",
                })}
                onToggle={() =>
                  props.setIsOpenConfirmationDeleteModal(
                    !props.isOpenConfirmationDeleteModal
                  )
                }
                onConfirm={() => {
                  const newFields = values.fields;
                  newFields.splice(props.deletingField, 1);
                  formProps.setFieldValue("fields", newFields);
                  props.setIsOpenConfirmationDeleteModal(false);
                  props.setDeletingField(null);
                }}
                onCancel={() => {
                  props.setIsOpenConfirmationDeleteModal(false);
                }}
              />
            </Form>
          );
        }}
      </Formik>
      <ConfirmationModal
        isOpen={props.isOpenConfirmationActionsUnsavedModal}
        title={
          <span>
            {intl.formatMessage({
              id: "form manager > save changes to",
            })}
            {` `}
            {masterListenerForm._source.name}
          </span>
        }
        isCloseOutside={false}
        className={bem.e("confirmation-modal")}
        description={""}
        confirmTitle={intl.formatMessage({
          id: "form manager > button save",
        })}
        cancelTitle={intl.formatMessage({
          id: "form manager > button cancel",
        })}
        onConfirm={() => {
          props.setIsOpenConfirmationActionsUnsavedModal(false);
          if (formikRef.current) {
            formikRef.current.submitForm();
          }
        }}
        onToggle={() =>
          props.setIsOpenConfirmationActionsUnsavedModal(
            !props.isOpenConfirmationActionsUnsavedModal
          )
        }
        onCancel={() => {
          props.onCancel();
          props.getSystemForms({
            station_id: currentStation.key,
          });
          props.setIsOpenConfirmationActionsUnsavedModal(false);
        }}
      />
    </div>
  );
};
MasterListenerForm.defaultProps = {};
export default MasterListenerForm;
