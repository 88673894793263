import * as React from "react";
import PropTypes from "prop-types";
import { FaClose, FaChevronCircleRight } from "react-icons/lib/fa";
import Spinner from "components/Spinner";
import bn from "utils/bemnames";
import { map, includes } from "lodash";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import { Row, Col, Label, Button } from "reactstrap";
const bem = bn.create("select-multiple-effective");

class PrivilegesEffective extends React.Component {
  render() {
    const {
      privilegesRemove,
      privileges,
      privilegesAdd,
      onRemoveItemFromListAdd,
      onRemoveItemFromListRemove,
      onSelectItem,
      onMoveToAddList,
      onMoveToRemoveList,
      isLoading,
      selectedPrivileges,
      onSave
    } = this.props;
    return (
      <div className={bem.b()}>
        <Row className={bem.e("content")}>
          <Col xs={6}>
            <div className={bem.e("left-vertical")}>
              <div className={bem.e("left-box")}>
                <Label className={bem.e("title")}>
                  <FormattedMessage id="privileges > left list options" />
                </Label>
                <div className={bem.e("list-items")}>
                  <ul>
                    {map(privileges, (privilege, index) => (
                      <li
                        className={classnames(bem.e("item"), {
                          [bem.e("item-selected")]: includes(
                            selectedPrivileges,
                            privilege
                          )
                        })}
                        onClick={() => onSelectItem(privilege)}
                        key={index}
                      >
                        <span>{privilege}</span>
                        {includes(selectedPrivileges, privilege) ? (
                          <span className={bem.e("remove-icon")}>
                            <FaClose />
                          </span>
                        ) : null}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className={bem.e("buttons")}>
                  <Button
                    disabled={selectedPrivileges.length === 0}
                    color="link"
                    className={bem.e("button")}
                    onClick={onMoveToAddList}
                  >
                    <FaChevronCircleRight />
                  </Button>
                  <Button
                    disabled={selectedPrivileges.length === 0}
                    color="link"
                    className={bem.e("button")}
                    onClick={onMoveToRemoveList}
                  >
                    <FaChevronCircleRight />
                  </Button>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={6}>
            <div className={bem.e("right-box")}>
              <Label className={bem.e("title")}>
                <FormattedMessage id="privileges > list options to add" />
              </Label>
              <div className={bem.e("list-selected-items")}>
                <ul>
                  {map(privilegesAdd, (item, index) => (
                    <li
                      key={index}
                      onClick={() => onRemoveItemFromListAdd(item)}
                      className={bem.e("selected-item")}
                    >
                      <span>{item}</span>
                      <span className={bem.e("remove-icon")}>
                        <FaClose />
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className={bem.e("right-box")}>
              <Label className={bem.e("title")}>
                <FormattedMessage id="privileges > list options to remove" />
              </Label>
              <div className={bem.e("list-selected-items")}>
                <ul>
                  {map(privilegesRemove, (item, index) => (
                    <li
                      key={index}
                      onClick={() => onRemoveItemFromListRemove(item)}
                      className={bem.e("selected-item")}
                    >
                      <span>{item}</span>
                      <span className={bem.e("remove-icon")}>
                        <FaClose />
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Button
              color="blue"
              type="button"
              onClick={onSave}
              className="btn btn-radius"
              disabled={
                isLoading ||
                (privilegesRemove.length === 0 && privilegesAdd.length === 0)
              }
            >
              <FormattedMessage
                id={`user > button ${!isLoading ? "submit" : "submitting"}`}
              />
            </Button>
            {` `}
            {this.props.backButton}
            <Spinner isLoading={isLoading} />
          </Col>
        </Row>
      </div>
    );
  }
}
PrivilegesEffective.defaultProps = {
  onSubmit: () => {},
  isLoading: false
};
PrivilegesEffective.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired
};
export default PrivilegesEffective;
