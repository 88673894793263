import React from "react";
import bn from "utils/bemnames";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import { get, map } from "lodash";
import { FaPlus } from "react-icons/lib/fa";
import { FaSortDesc, FaSortAsc } from "react-icons/lib/fa";
import { Button, Table } from "reactstrap";
import moment from "moment";
import history from "components/History";
import URL from "utils/urls";
import useInfiniteScroll from "utils/useInfiniteScroll";
const bem = bn.create("promotion-contest-winners");
const getWinnerType = (item) => {
  let sortName;
  let name;
  let className;
  if (item.type === "call_in_contest") {
    sortName = "CALL";
    name = "Call-in";
    className = "call-in";
  }
  return (
    <div className={classnames(bem.e("winner-type"), className)}>
      <span className={bem.e("winner-sort-name")}>{sortName}</span>
      <span className={bem.e("winner-name")}>{name}</span>
    </div>
  );
};
export const titleDisplay = (process) => {
  if (get(process, "title")) {
    return process.title;
  }
  return <FormattedMessage id="process > title of spot" />;
};
const getWinnerName = ({ item, intl }) => {
  let name = "";
  if (item.first_name && item.last_name) {
    name = item.first_name + " " + item.last_name;
  } else if (item.name) {
    name = item.name;
  }

  if (!name) {
    name = intl.formatMessage({ id: "process > none" });
  }

  return name;
};
function EmptyTable(props) {
  const { process, onSelectWinner } = props;
  const isOffAir =
    get(process, "data.fields.prize_execution.value", "") === "off_air";
  return (
    <div
      className={classnames(
        bem.e("winners-table"),
        bem.e("grand-winners-empty-table"),
        "cr-list-table"
      )}
    >
      <Table>
        <thead>
          <tr>
            <th className={classnames("table-first-head")}>
              <div className={bem.e("no-winner-text")}>
                <FormattedMessage id="process > promotion > contest > no winner selected" />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="align-middle">
              {isOffAir ? (
                <div className={bem.e("empty-winner-help")}>
                  <Button
                    color="blue"
                    outline
                    className={"btn-radius"}
                    onClick={onSelectWinner}
                  >
                    <FaPlus className={bem.e("icon-add")} />
                    <FormattedMessage id="process > promotion > contest > select a winner" />
                  </Button>
                </div>
              ) : (
                <div className={bem.e("empty-winner-help")}>
                  <FormattedMessage id="process > promotion > contest > grand prize selection empty on air text" />
                </div>
              )}
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}
const View = ({
  onSort,
  sorted,
  setSidebarActive,
  process,
  searchData,
  hasMore,
  getMoreItems,
  onPickWinnerForOffAir,
  intl,
}) => {
  const isLoading = get(searchData, "loading", false);
  const items = get(searchData, "data", []);
  const onView = (item) => {
    setSidebarActive("/winners");
    history.push({
      pathname: URL.WINNER({
        action: "view",
        winner_id: item._id,
      }),
    });
  };
  useInfiniteScroll({
    useWindow: false,
    elementId: "grand-prize-winners-scroll",
    onLoadMore: getMoreItems,
    shouldLoadMore: hasMore,
  });
  const isShowEmpty = !isLoading && items.length === 0;
  const isShowList = !isLoading && items.length > 0;
  return (
    <div
      className={classnames(
        bem.e("winners-table"),
        bem.e("grand-winners-table"),
        "cr-list-table"
      )}
    >
      <div className={bem.e("winners-head")}>
        <h2 className={bem.e("title")}>
          <FormattedMessage id="process > promotion > contest > title grand prize winner" />
        </h2>
      </div>
      {isShowList ? (
        <div className={classnames(bem.e("contest-winners-fields"))}>
          <Table className={"table-scroll"}>
            <thead>
              <tr>
                <th
                  onClick={() => onSort("type")}
                  className={classnames("can-click", "table-first-head")}
                >
                  <div className={"item-sortable"}>
                    <FormattedMessage id="process > Type" />
                    <div className={"sortable"}>
                      <FaSortAsc
                        className={classnames("sortable-icon", {
                          "sortable-active":
                            get(sorted, "id") === "type" && get(sorted, "desc"),
                        })}
                      />
                      <FaSortDesc
                        className={classnames("sortable-icon", {
                          "sortable-active":
                            get(sorted, "id") === "type" &&
                            !get(sorted, "desc"),
                        })}
                      />
                    </div>
                  </div>
                </th>

                <th onClick={() => onSort("name")} className="can-click title">
                  <div className={"item-sortable"}>
                    <FormattedMessage id="process > promotion > contest > winner" />
                    <div className={"sortable"}>
                      <FaSortAsc
                        className={classnames("sortable-icon", {
                          "sortable-active":
                            get(sorted, "id") === "name" &&
                            !get(sorted, "desc"),
                        })}
                      />
                      <FaSortDesc
                        className={classnames("sortable-icon", {
                          "sortable-active":
                            get(sorted, "id") === "name" && get(sorted, "desc"),
                        })}
                      />
                    </div>
                  </div>
                </th>
                <th>
                  <FormattedMessage id="process > promotion > contest > prize" />
                </th>
                <th
                  onClick={() => onSort("contacted_at_ts")}
                  className="can-click"
                >
                  <div className={"item-sortable"}>
                    <FormattedMessage id="process > promotion > contest > date/time" />
                    <div className={"sortable"}>
                      <FaSortAsc
                        className={classnames("sortable-icon", {
                          "sortable-active":
                            get(sorted, "id") === "contacted_at_ts" &&
                            !get(sorted, "desc"),
                        })}
                      />
                      <FaSortDesc
                        className={classnames("sortable-icon", {
                          "sortable-active":
                            get(sorted, "id") === "contacted_at_ts" &&
                            get(sorted, "desc"),
                        })}
                      />
                    </div>
                  </div>
                </th>
                <th
                  onClick={() => onSort("status")}
                  className="can-click text-center"
                >
                  <div className={"item-sortable"}>
                    <FormattedMessage id="process > promotion > contest > status" />
                    <div className={"sortable"}>
                      <FaSortAsc
                        className={classnames("sortable-icon", {
                          "sortable-active":
                            get(sorted, "id") === "status" &&
                            !get(sorted, "desc"),
                        })}
                      />
                      <FaSortDesc
                        className={classnames("sortable-icon", {
                          "sortable-active":
                            get(sorted, "id") === "status" &&
                            get(sorted, "desc"),
                        })}
                      />
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="scroll-bar-style" id="grand-prize-winners-scroll">
              {map(items, (item, index) => {
                const processData = {
                  ...item._source,
                  key: item._id,
                };
                const prizes = get(processData, "contests.prizes", []);
                return (
                  <tr
                    key={index}
                    className="list-liner-item"
                    onClick={() => onView(item)}
                  >
                    {/* type */}
                    <td
                      className={classnames(
                        "align-middle align-middle first-col"
                      )}
                    >
                      <span className={"td-value"}>
                        {getWinnerType(processData.contests)}
                      </span>
                    </td>
                    {/* title */}
                    <td className={classnames("align-middle title")}>
                      {getWinnerName({ item: processData, intl })}
                    </td>
                    {/* prize */}
                    <td className={"align-middle"}>
                      {map(prizes, (prize, index2) => (
                        <div key={index2}>
                          <span className={bem.e("prize-name")}>
                            {prize.title}
                          </span>
                          <span className={bem.e("small-text")}>
                            {prize.subtitle}
                          </span>
                        </div>
                      ))}
                    </td>
                    {/* date time */}
                    <td className="align-middle">
                      {processData.contests.contacted_at_ts ? (
                        <React.Fragment>
                          <span className={bem.e("date-time")}>
                            {moment(
                              processData.contests.contacted_at_ts * 1000
                            ).format("L hh:mm a")}
                          </span>
                          <span className={bem.e("small-text")}>
                            {processData.contests.contacted_by}
                          </span>
                        </React.Fragment>
                      ) : (
                        <div className="list-none-text">
                          <FormattedMessage id="process > none" />
                        </div>
                      )}
                    </td>
                    {/* status */}
                    <td className="align-middle text-center">
                      <span
                        className={classnames(
                          bem.e("winner-status"),
                          bem.e(`winner-status-selected`)
                        )}
                      >
                        <FormattedMessage id="process > promotion > contest > selected" />
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      ) : null}
      {isShowEmpty && (
        <EmptyTable process={process} onSelectWinner={onPickWinnerForOffAir} />
      )}
    </div>
  );
};
export default View;
