import React from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { compose, lifecycle, withHandlers, withState } from "recompose";
import ToastManager from "components/ToastManager";
import { MdImportantDevices } from "react-icons/lib/md";
import { get, isFunction } from "lodash";
import { getJockSchedule } from "store/actions/jocks";
import AddWinnerModal from "./view";
import {
  addWinner,
  getWinners,
  updateWinner,
  assignWinnerToContest,
  updateWinnerToContest,
  createFloatingContestPlay,
} from "store/actions/winners";
import moment from "moment";
import { pageSize } from "utils/config";
import { getSystemFormDefault } from "store/actions/systemForms";
const Enhanced = compose(
  connect(
    (state, props) => {
      return {
        currentServer: get(state, "servers.currentServer.data", {}),
        isLoading:
          get(state, "winners.winners.loading", false) ||
          get(state, "winners.assignWinner.loading", false) ||
          get(state, "winners.addWinner.loading", false) ||
          get(state, "winners.updateWinner.loading", false) ||
          get(state, "winners.createFloatingContestPlay.loading", false) ||
          get(state, "systemForms.systemForm.loading", false),
        winners: get(state, "winners.winners", {}),
        systemForm: get(state, "systemForms.systemForm.data", null),
        ...props,
      };
    },
    {
      addWinner,
      getWinners,
      updateWinner,
      assignWinnerToContest,
      getJockSchedule,
      getSystemFormDefault,
      updateWinnerToContest,
      createFloatingContestPlay,
    }
  ),
  withState("keyword", "setKeyword", ""),
  withState("filtered", "setFiltered", []),
  withState("selectedWinner", "setSelectedWinner", null),
  withState("hasSearched", "setHasSearched", false),
  withState("isShowForm", "setIsShowForm", false),
  withState("isEdit", "setIsEdit", false),
  withState("sorted", "setSorted", [{ id: "created_ts", desc: true }]),
  withHandlers({
    getWinnersList: ({ getWinners, sorted, filtered }) => async (
      params = {},
      cb = null
    ) => {
      getWinners(
        {
          pageSize,
          page: 1,
          sorted,
          filtered,
          isSearch: true,
          ...params, //override
        },
        cb
      );
    },
    getMoreWinners: ({ getWinners, sorted, filtered }) => (page) => {
      getWinners({
        pageSize,
        page: page + 1,
        sorted,
        filtered,
        isSearch: true,
      });
    },
  }),
  withHandlers({
    onAddWinner: ({
      addWinner,
      intl,
      schedule,
      getJockSchedule,
      onSubmitted,
      isContest,
      initialValues,
    }) => (values, cb) => {
      let params = {};
      if (isContest) {
        params = {
          ...initialValues,
          ...values,
          social_security:
            values.social_security && values.social_security.number1
              ? `${values.social_security.number1}${values.social_security.number2}${values.social_security.number3}`
              : "",
          source: "call-in",
        };
      } else {
        params = {
          ...values,
          social_security:
            values.social_security && values.social_security.number1
              ? `${values.social_security.number1}${values.social_security.number2}${values.social_security.number3}`
              : "",
          event_id: schedule.id,
          source: "call-in",
        };
      }
      addWinner(params, (data) => {
        if (isFunction(onSubmitted)) {
          onSubmitted();
        }
        if (schedule) {
          getJockSchedule(schedule);
        }
        ToastManager.show({
          title: <MdImportantDevices />,
          message: intl.formatMessage({
            id: "jock console > create successfully",
          }),
          level: "success",
        });
        if (isFunction(cb)) {
          cb(data);
        }
      });
    },
    onEdit: ({
      updateWinner,
      intl,
      selectedWinner,
      assignWinnerToContest,
      schedule,
      getJockSchedule,
      onSubmitted,
      updateWinnerToContest,
      isContest,
      initialValues,
    }) => (values, cb) => {
      const payload = {
        ...values,
        social_security:
          values.social_security && values.social_security.number1
            ? `${values.social_security.number1}${values.social_security.number2}${values.social_security.number3}`
            : "",
        source: "call-in",
        id: get(selectedWinner, "_id"),
      };
      if (isContest) {
        updateWinnerToContest(
          {
            winner_id: get(selectedWinner, "_id"),
            contest_id: initialValues.contest_id,
          },
          () => {
            updateWinner(payload, (data) => {
              ToastManager.show({
                title: <MdImportantDevices />,
                message: intl.formatMessage({
                  id: "jock console > update successfully",
                }),
                level: "success",
              });
              if (isFunction(onSubmitted)) {
                onSubmitted();
              }
              if (isFunction(cb)) {
                cb(data);
              }
            });
          }
        );
      } else {
        assignWinnerToContest(
          {
            winner_id: get(selectedWinner, "_id"),
            event_id: schedule.id,
          },
          () => {
            getJockSchedule(schedule);
            if (isFunction(onSubmitted)) {
              onSubmitted();
            }
            updateWinner(payload, (data) => {
              ToastManager.show({
                title: <MdImportantDevices />,
                message: intl.formatMessage({
                  id: "jock console > update successfully",
                }),
                level: "success",
              });
              if (isFunction(cb)) {
                cb(data);
              }
            });
          }
        );
      }
    },
  }),
  withHandlers({
    onCreateFloatingPlay: ({
      createFloatingContestPlay,
      schedule,
      selectedDate,
      selectedSchedule,
      onSelectSchedule,
    }) => (cb) => {
      createFloatingContestPlay(
        {
          contest_id: schedule.process_id,
          tz: moment.tz.guess(),
          datetime: moment(selectedDate).format("YYYY-MM-DD HH:mm:ss"),
        },
        (response) => {
          onSelectSchedule({
            ...selectedSchedule,
            schedule: response,
          });

          if (cb) cb(response);
        }
      );
    },
  }),
  withHandlers({
    onSearch: ({
      setHasSearched,
      setFiltered,
      keyword,
      getWinnersList,
      setIsShowForm,
    }) => () => {
      const filtered = [
        {
          key: "keyword",
          value: keyword,
        },
      ];
      setFiltered(filtered);
      setHasSearched(true);
      getWinnersList(
        {
          filtered,
        },
        (response) => {
          if (response && response.total === 0) {
            setIsShowForm(true);
          }
        }
      );
    },
    onSubmit: ({ schedule, onAddWinner, onCreateFloatingPlay }) => (
      values,
      cb
    ) => {
      if (!schedule || !schedule.floating_contest) {
        onAddWinner(values, cb);
      } else {
        onCreateFloatingPlay(() => {
          onAddWinner(values, cb);
        });
      }
    },
    onSubmitEdit: ({ schedule, onEdit, onCreateFloatingPlay }) => (
      values,
      cb
    ) => {
      if (!schedule || !schedule.floating_contest) {
        onEdit(values, cb);
      } else {
        onCreateFloatingPlay(() => {
          onEdit(values, cb);
        });
      }
    },
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const { stationId } = this.props;
      if (
        !stationId ||
        (stationId === prevProps.stationId && this.props.systemForm)
      )
        return;
      this.props.getSystemFormDefault({
        station_id: stationId,
        form_type: "individual_studio_console_winner_form",
      });
    },
  })
)(AddWinnerModal);

export default injectIntl(Enhanced);
