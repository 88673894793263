import React from "react";
import bn from "utils/bemnames";
import { MenuIcon } from "components/CustomIcons";
import classnames from "classnames";
import { FaClose } from "react-icons/lib/fa";
import { FormattedMessage } from "react-intl";
import { labelToNameField } from "utils/helpers";
import { FormGroup, Row, Col, Label } from "reactstrap";
import ToggleSwitch from "components/ToggleSwitch";
import TextInput from "components/TextInput";
import { find, get } from "lodash";
import Dropdown from "components/Dropdown";
import { Asterisk } from "components/Elements";
import { getFieldName } from "../helper";
const bem = bn.create("form-builder-fields");
const configOptions = [
  { label: "Some high school", value: "some high school" },
  { label: "High school degree", value: "high school degree" },
  { label: "Some college", value: "some college" },
  { label: "Associate's degree", value: "associate's degree" },
  { label: "Bachelor's degree", value: "bachelor's degree" },
  {
    label: "Master's degree or higher",
    value: "Master's degree or higher",
  },
];
const Education = ({
  intl,
  isNew,
  isView,
  field,
  value,
  errors,
  onChange,
  touched,
  onDelete,
  allowDelete,
  isFormBuilder,
  disabled,
  isShowRequiredToggle,
  isMasterListener,
  isRenderRow,
  colNumber,
  isShowOffText,
  isRenderFieldNameFromLabel,
}) => {
  // UI for form builder
  if (isFormBuilder) {
    if (isNew) {
      return (
        <Row>
          <Col className="form-builder-field">
            <div className={bem.b()}>
              <div
                className={classnames(
                  bem.e("form-field-group"),
                  bem.e("form-field-group-line")
                )}
              >
                <Row>
                  <Col xs={isShowRequiredToggle ? 9 : 12}>
                    <div className={bem.e("form-field-group-head")}>
                      <div
                        className={bem.e("form-field-group-head-single-left")}
                      >
                        <span className={bem.e("menu-icon")}>
                          <MenuIcon />
                        </span>
                        <div
                          className={classnames(
                            bem.e("input-label"),
                            {
                              [bem.e(
                                "full-input-label"
                              )]: !isShowRequiredToggle,
                            },
                            {
                              [bem.e("master-input-field")]: isMasterListener,
                            }
                          )}
                        >
                          <TextInput
                            label={null}
                            name="label"
                            type="text"
                            placeholder={intl.formatMessage({
                              id: "form builder > label education",
                            })}
                            value={value.label}
                            onChange={({ target }) => {
                              onChange({
                                ...value,
                                label: target.value,
                              });
                            }}
                            disabled={disabled}
                            error={get(errors, "label", "")}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  {isShowRequiredToggle && (
                    <Col xs={3}>
                      <ToggleSwitch
                        leftComponent={
                          <span
                            className={classnames({
                              [bem.e("switch-active")]: !get(
                                value,
                                "is_required",
                                false
                              ),
                            })}
                          >
                            {isShowOffText
                              ? intl.formatMessage({
                                  id: "form builder > off",
                                })
                              : ""}
                          </span>
                        }
                        rightComponent={
                          <span
                            className={classnames({
                              [bem.e("switch-active")]: get(
                                value,
                                "is_required",
                                false
                              ),
                            })}
                          >
                            {intl.formatMessage({
                              id: "form builder > required",
                            })}
                          </span>
                        }
                        name="is_required"
                        switchProps={{
                          checked: !get(value, "is_required", false),
                          onChange: (checked) => {
                            onChange({
                              ...value,
                              is_required: !checked,
                            });
                          },
                          disabled: disabled,
                          offColor: "#795AFA",
                          onColor: "#E3ECF1",
                          uncheckedIcon: false,
                          checkedIcon: false,
                          handleDiameter: 22,
                          width: 50,
                          height: 26,
                        }}
                      />
                    </Col>
                  )}
                </Row>
                {allowDelete && (
                  <div
                    className={classnames(
                      bem.e("form-field-group-head-right"),
                      bem.e("form-field-group-delete-icon")
                    )}
                    onClick={onDelete}
                  >
                    <FaClose size="23" color="#C2D4E0" />
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row>
          <Col>
            <div className={bem.b()}>
              <div className={bem.e("form-field-group-saved")}>
                <FormGroup>
                  <TextInput
                    label={
                      <span>
                        {get(value, "label", "")}{" "}
                        {get(value, "is_required", false) && (
                          <Asterisk>*</Asterisk>
                        )}
                      </span>
                    }
                    name=""
                    type="text"
                    placeholder={intl.formatMessage({
                      id: "form builder > placeholder education",
                    })}
                    onChange={() => {}}
                  />
                </FormGroup>
                {allowDelete && (
                  <div
                    className={bem.e("form-field-group-saved-button-close")}
                    onClick={onDelete}
                  >
                    <FaClose size="23" color="#C2D4E0" />
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    // ui for form
  } else {
    const fieldName = isRenderFieldNameFromLabel
      ? labelToNameField(get(field, "label", ""))
      : getFieldName(field);
    const selectedOption =
      find(configOptions, (item) => item.value === value) || null;

    if (isView) {
      return (
        <Row>
          <Col>
            <div className={bem.b()}>
              <div className={bem.e("form-field-group-saved")}>
                <Label>{get(field, "label", "")}</Label>
                <span className="order-view-value">
                  {value ? (
                    value
                  ) : (
                    <FormattedMessage id="form builder > none" />
                  )}
                </span>
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    const elementField = (
      <div className={bem.b()}>
        <div
          className={classnames(
            bem.e("form-field-group-saved"),
            "form-builder-edit-field-2"
          )}
        >
          <FormGroup className={bem.e("form-group")}>
            <Dropdown
              label={
                <span>
                  {get(field, "label", "")}{" "}
                  {get(field, "is_required", false) && <Asterisk>*</Asterisk>}
                </span>
              }
              value={selectedOption}
              placeholder={intl.formatMessage({
                id: "form builder > placeholder select",
              })}
              onChange={(selectedOption) => {
                onChange(get(selectedOption, "value", ""));
              }}
              // defaultMenuIsOpen
              options={configOptions}
              name={fieldName}
              error={
                get(touched, fieldName, false) && get(errors, fieldName, "")
                  ? get(errors, fieldName, "")
                  : ""
              }
            />
          </FormGroup>
        </div>
      </div>
    );
    if (isRenderRow)
      return (
        <Row>
          <Col>{elementField}</Col>
        </Row>
      );
    return <Col xs={colNumber}>{elementField}</Col>;
  }
};
Education.defaultProps = {
  isNew: false,
  isFormBuilder: true,
  errors: {},
  touched: {},
  onChange: () => {},
  onDelete: () => {},
  allowDelete: true,
  disabled: false,
  isShowRequiredToggle: true,
  isMasterListener: false,
  isRenderRow: true,
  colNumber: 6,
  isShowOffText: true,
  isRenderFieldNameFromLabel: true,
};
export default Education;
