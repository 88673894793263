import React, { useState } from "react";
import bn from "utils/bemnames";
import classnames from "classnames";
import ToggleSwitch from "components/ToggleSwitch";
import { FormattedMessage } from "react-intl";
import TextInput from "components/TextInput";
import { Asterisk } from "components/Elements";
import DaysOfWeek from "components/DaysOfWeek";
import InputTime from "components/InputTime";
import { setScriptField } from "utils/helpers";
import TextareaEvaluator from "components/TextareaEvaluator";
import {
  UpIcon,
  DownIcon,
  DeleteIcon,
  PlusSmallIcon,
  ScriptHistoryIcon,
} from "components/CustomIcons";
import { uuidv4 } from "utils/helpers";
import { difference, filter, get, map } from "lodash";
import { Row, Col, FormGroup, Label, Button } from "reactstrap";
import moment from "moment";
import RotationChart from "components/RotationChart";
import ScriptHistoryModal from "../ScriptHistoryModal";
const bem = bn.create("copy-group");
const dateTimeFormat = "HH:mm:ss";
function CopyGroupForm(props) {
  const {
    intl,
    group,
    idx,
    validationState,
    process,
    setIsOpenScriptHistoryModal,
    setScriptsHistory,
  } = props;
  const disabledDays = difference(
    [1, 2, 3, 4, 5, 6, 7],
    get(process, "data.fields.days_of_week.value", [])
  );
  const isShowScriptNumber = get(group, "list_scripts.length", 0) > 1;
  return (
    <div className={bem.e("content-form")}>
      <div
        className={classnames(
          bem.e("form-section-fields"),
          bem.e("form-padding")
        )}
      >
        <Row>
          <Col xs={6} className={bem.e("reset-col")}>
            <FormGroup>
              <TextInput
                label={
                  <span>
                    <FormattedMessage id="process > title" />
                    <Asterisk>*</Asterisk>
                  </span>
                }
                required
                name="title"
                placeholder={intl.formatMessage({
                  id: "process > enter title",
                })}
                value={get(group, "title", "")}
                onChange={({ target }) => {
                  const { value } = target;
                  props.onChange({
                    idx,
                    key: "title",
                    value,
                  });
                }}
                error={get(validationState, "title", "")}
              />
            </FormGroup>
          </Col>
          <Col xs={6} className={bem.e("reset-col")}>
            <FormGroup>
              <TextInput
                label={<FormattedMessage id="process > copy id" />}
                name="copy_id"
                placeholder={intl.formatMessage({
                  id: "process > enter ID",
                })}
                value={get(group, "copy_id", "")}
                onChange={({ target }) => {
                  const { value } = target;
                  props.onChange({
                    idx,
                    key: "copy_id",
                    value,
                  });
                }}
                error={get(validationState, "copy_id", "")}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={4} className={bem.e("reset-col")}>
            <FormGroup>
              <TextInput
                label={
                  <span>
                    <FormattedMessage id="process > start date" />
                    <Asterisk>*</Asterisk>
                  </span>
                }
                type="date"
                name="start_date"
                placeholder={intl.formatMessage({
                  id: "process > enter start date",
                })}
                value={get(group, "start_date", "")}
                error={get(validationState, "start_date", "")}
                onChange={(value) => {
                  props.onChange({
                    idx,
                    key: "start_date",
                    value,
                  });
                }}
                allowShowIcon
              />
            </FormGroup>
          </Col>
          <Col xs={4} className={bem.e("reset-col")}>
            <FormGroup>
              <TextInput
                label={
                  <span>
                    <FormattedMessage id="process > end date" />
                    <Asterisk>*</Asterisk>
                  </span>
                }
                type="date"
                name="end_date"
                placeholder={intl.formatMessage({
                  id: "process > enter end date",
                })}
                value={get(group, "end_date", "")}
                min={
                  get(group, "start_date", "")
                    ? moment(group.start_date).format("YYYY-MM-DD")
                    : ""
                }
                error={get(validationState, "end_date", "")}
                onChange={(value) => {
                  props.onChange({
                    idx,
                    key: "end_date",
                    value,
                  });
                }}
                allowShowIcon
              />
            </FormGroup>
          </Col>
          <Col xs={4} className={bem.e("reset-col")}>
            <FormGroup>
              <DaysOfWeek
                value={get(group, "days_of_week", "")}
                label={
                  <span>
                    <FormattedMessage id="process > days of the week" />{" "}
                    <Asterisk>*</Asterisk>
                  </span>
                }
                disabledDays={disabledDays}
                onChange={(value) => {
                  props.onChange({
                    idx,
                    key: "days_of_week",
                    value: value,
                  });
                }}
                checkStyle={{ color: "#fff", width: "11", height: "11" }}
                error={get(validationState, "days_of_week", "")}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col
            xs={8}
            className={classnames(bem.e("col-reset"), bem.e("time-inputs"))}
          >
            <div className={bem.e("time-col")}>
              <Label>
                <FormattedMessage id="process > start time" />
                <Asterisk>*</Asterisk>
              </Label>
              <InputTime
                id="startTimerInput"
                time={
                  get(group, "start_date")
                    ? `${get(group, "start_date")} ${get(
                        group,
                        "start_date_time",
                        ""
                      )}`
                    : ""
                }
                onChange={(startDateTime) => {
                  if (startDateTime) {
                    props.onChange({
                      idx,
                      key: "start_date_time",
                      value: startDateTime.format(dateTimeFormat),
                    });
                  } else {
                    props.onChange({
                      idx,
                      key: "start_date_time",
                      value: "",
                    });
                  }
                }}
                error={get(validationState, "start_date_time", "")}
              />
            </div>
            <div className={bem.e("time-line")}>-</div>
            <div className={bem.e("time-col")}>
              <Label>
                <FormattedMessage id="process > end time" />
                <Asterisk>*</Asterisk>
              </Label>
              <InputTime
                id="endTimerInput"
                time={
                  get(group, "end_date")
                    ? `${get(group, "end_date")} ${get(
                        group,
                        "end_date_time",
                        ""
                      )}`
                    : ""
                }
                onChange={(startDateTime) => {
                  if (startDateTime) {
                    props.onChange({
                      idx,
                      key: "end_date_time",
                      value: startDateTime.format(dateTimeFormat),
                    });
                  } else {
                    props.onChange({
                      idx,
                      key: "end_date_time",
                      value: "",
                    });
                  }
                }}
                error={get(validationState, "end_date_time", "")}
              />
            </div>
          </Col>
          <Col xs={4} className={bem.e("col-reset")}>
            <FormGroup className={bem.e("switch-group")}>
              <ToggleSwitch
                leftComponent={
                  <span className={classnames(bem.e("switch-label"))}>
                    <FormattedMessage id="process > per flight" />
                  </span>
                }
                rightComponent={
                  <span className={classnames(bem.e("switch-label"))}>
                    <FormattedMessage id="process > per day" />
                  </span>
                }
                switchProps={{
                  onChange: (checked) => {
                    props.onChange({
                      idx,
                      key: "per_day",
                      value: checked,
                    });
                  },
                  checked: get(group, "per_day", false),
                  disabled: false,
                  uncheckedIcon: false,
                  checkedIcon: false,
                  offColor: "#795AFA",
                  onColor: "#795AFA",
                  handleDiameter: 22,
                  width: 50,
                  height: 26,
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
      <div className={classnames(bem.e("form-section-script"))}>
        <Row>
          <Col xs={12} className={bem.e("col-reset")}>
            {map(group.list_scripts, (script, index) => {
              const scriptHistory =
                filter(
                  get(
                    props,
                    `process.data.fields.copy_groups.value.${idx}.scripts_history_array`,
                    []
                  ),
                  (item) => {
                    return item.uuid === script.uuid;
                  }
                ) || [];
              return (
                <TextareaEvaluator
                  key={index}
                  text={setScriptField(get(script, "text", ""))}
                  title={
                    <span>
                      <FormattedMessage id="process > script" />{" "}
                      {isShowScriptNumber ? index + 1 : null}
                      {scriptHistory.length > 1 ? (
                        <span
                          className={bem.e("script-history")}
                          onClick={() => {
                            setIsOpenScriptHistoryModal(true);

                            setScriptsHistory(scriptHistory);
                          }}
                        >
                          <ScriptHistoryIcon width={17} height={16} />
                          <FormattedMessage id="process > script history" />
                        </span>
                      ) : null}
                    </span>
                  }
                  showScriptLength={true}
                  showDelete={isShowScriptNumber}
                  tags={[
                    {
                      label: "STATION",
                      replaceWith: "STATION",
                    },
                    {
                      label: "CITY",
                      replaceWith: "CITY",
                    },
                  ]}
                  onDelete={() => {
                    let newGroupScripts = filter(
                      group.list_scripts,
                      (item) => item.uuid !== script.uuid
                    );
                    props.onChange({
                      idx,
                      key: "list_scripts",
                      value: newGroupScripts,
                    });
                  }}
                  deleteText={<FormattedMessage id="process > delete" />}
                  onEvaluatorCallback={(value) => {
                    let newGroupScripts = group.list_scripts;
                    newGroupScripts[index] = {
                      script: index + 1,
                      text: value,
                      uuid: get(newGroupScripts, `${index}.uuid`)
                        ? get(newGroupScripts, `${index}.uuid`)
                        : uuidv4(),
                    };
                    props.onChange({
                      idx,
                      key: "list_scripts",
                      value: newGroupScripts,
                    });
                  }}
                  helperText={intl.formatMessage({
                    id: "process > script helper text",
                  })}
                />
              );
            })}

            <RotationChart
              onChange={(value) => {
                props.onChange({
                  idx,
                  key: "rotation_chart",
                  value,
                });
              }}
              title={
                <FormattedMessage id="process > promotion > liner > script rotation chart" />
              }
              rotationChartValue={get(group, "rotation_chart")}
              totalSpots={get(group, "list_scripts.length")} // key for get spots length
              error={
                get(validationState, "rotation_chart", "")
                  ? get(validationState, "rotation_chart", "")
                  : null
              }
              isView={false}
            />
          </Col>
        </Row>
      </div>
      <div className={classnames(bem.e("form-section-jock"))}>
        <Row>
          <Col xs={12} className={bem.e("col-reset")}>
            <TextareaEvaluator
              text={setScriptField(get(group, "jock_instructions", ""))}
              title={<FormattedMessage id="process > jock instructions" />}
              placeholder={intl.formatMessage({
                id: "process > enter instructions for the jock",
              })}
              showScriptLength={false}
              hideHelperText={true}
              onEvaluatorCallback={(value) => {
                props.onChange({
                  idx,
                  key: "jock_instructions",
                  value,
                });
              }}
            />
            <div className={bem.e("add-rotation")}>
              <Button
                className={classnames(bem.e("add-button"), "btn-radius")}
                outline
                color="blue"
                onClick={() => props.onAddToRotation({ idx })}
              >
                <span className={bem.e("add-icon")}>
                  <PlusSmallIcon />
                </span>
                <FormattedMessage id="process > button add to rotation" />
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
function CopyGroupView(props) {
  const {
    intl,
    group,
    validationState,
    idx,
    setIsOpenScriptHistoryModal,
    setScriptsHistory,
    dateFormatByServer
  } = props;
  const isShowScriptNumber = get(group, "list_scripts.length", 0) > 1;
  let startEndTime = [];
  if (get(group, "start_date_time")) {
    startEndTime.push(
      moment(get(group, "start_date_time"), dateTimeFormat).format("h:mm A")
    );
  }
  if (get(group, "end_date_time")) {
    startEndTime.push(
      moment(get(group, "end_date_time"), dateTimeFormat).format("h:mm A")
    );
  }
  return (
    <div className={bem.e("content-view")}>
      <div
        className={classnames(
          bem.e("form-section-fields"),
          bem.e("view-padding")
        )}
      >
        <Row>
          <Col xs={6} className={bem.e("reset-col")}>
            <FormGroup>
              <Label className={bem.e("order-view-label")}>
                <FormattedMessage id="process > title" />
              </Label>
              <span className={bem.e("order-view-value")}>
                {get(group, "title", "") ? (
                  get(group, "title", "")
                ) : (
                  <FormattedMessage id="process > none" />
                )}
              </span>
            </FormGroup>
          </Col>
          <Col xs={6} className={bem.e("reset-col")}>
            <FormGroup>
              <Label className={bem.e("order-view-label")}>
                <FormattedMessage id="process > copy id" />
              </Label>
              <span className={bem.e("order-view-value")}>
                {get(group, "copy_id", "") ? (
                  get(group, "copy_id", "")
                ) : (
                  <FormattedMessage id="process > none" />
                )}
              </span>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={6} className={bem.e("reset-col")}>
            <FormGroup>
              <Label className={bem.e("order-view-label")}>
                <FormattedMessage id="process > start date" />
              </Label>
              <span className={bem.e("order-view-value")}>
                {get(group, "start_date", "") ? (
                  moment(get(group, "start_date", "")).format(dateFormatByServer)
                ) : (
                  <FormattedMessage id="process > none" />
                )}
              </span>
            </FormGroup>
          </Col>
          <Col xs={6} className={bem.e("reset-col")}>
            <FormGroup>
              <Label className={bem.e("order-view-label")}>
                <FormattedMessage id="process > end date" />
              </Label>
              <span className={bem.e("order-view-value")}>
                {get(group, "end_date", "") ? (
                  moment(get(group, "end_date", "")).format(dateFormatByServer)
                ) : (
                  <FormattedMessage id="process > none" />
                )}
              </span>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={6} className={bem.e("col-reset")}>
            <FormGroup>
              <Label>
                <FormattedMessage id="process > days of the week" />
              </Label>
              <span className={bem.e("order-view-value")}>
                {get(group, "days_of_week", []).length > 0 ? (
                  <DaysOfWeek
                    isView={true}
                    value={get(group, "days_of_week", [])}
                  />
                ) : (
                  <FormattedMessage id="process > none" />
                )}
              </span>
            </FormGroup>
          </Col>
          <Col xs={6} className={bem.e("col-reset")}>
            <FormGroup>
              <Label>
                {get(group, "per_day", "") ? (
                  <FormattedMessage id="process > start/end per day" />
                ) : (
                  <FormattedMessage id="process > start/end per flight" />
                )}
              </Label>
              <span className={bem.e("order-view-value")}>
                {startEndTime.length > 0 ? (
                  startEndTime.join(" - ")
                ) : (
                  <FormattedMessage id="process > none" />
                )}
              </span>
            </FormGroup>
          </Col>
        </Row>
      </div>
      <div className={classnames(bem.e("view-section"), bem.e("view-scripts"))}>
        <Row>
          <Col xs={12} className={bem.e("col-reset")}>
            {map(group.list_scripts, (script, index) => {
              const scriptHistory =
                filter(
                  get(
                    props,
                    `process.data.fields.copy_groups.value.${idx}.scripts_history_array`,
                    []
                  ),
                  (item) => {
                    return item.uuid === script.uuid;
                  }
                ) || [];
              return (
                <React.Fragment key={index}>
                  <h2 className={bem.e("section-title")}>
                    <span>
                      <FormattedMessage id="process > script" />{" "}
                      {isShowScriptNumber ? index + 1 : null}
                    </span>
                    {scriptHistory.length > 1 ? (
                      <span
                        className={bem.e("script-history")}
                        onClick={() => {
                          setIsOpenScriptHistoryModal(true);

                          setScriptsHistory(scriptHistory);
                        }}
                      >
                        <ScriptHistoryIcon width={17} height={16} />
                        <FormattedMessage id="process > script history" />
                      </span>
                    ) : null}
                  </h2>
                  <div className={bem.e("order-view-value")}>
                    <TextareaEvaluator
                      readOnly={true}
                      text={setScriptField(get(script, "text", ""))}
                      showScriptLength={true}
                      onEvaluatorCallback={() => {}}
                      hideHelperText={true}
                    />
                  </div>
                </React.Fragment>
              );
            })}

            <RotationChart
              onChange={() => {}}
              rotationChartValue={get(group, "rotation_chart")}
              totalSpots={get(group, "list_scripts.length")}
              validationState={validationState}
              title={
                <FormattedMessage id="process > promotion > liner > script rotation chart" />
              }
              isView={true}
            />
          </Col>
        </Row>
      </div>
      <div className={classnames(bem.e("view-section"), bem.e("view-jock"))}>
        <Row>
          <Col xs={12} className={bem.e("col-reset")}>
            <h2 className={bem.e("section-title")}>
              <FormattedMessage id="process > jock instructions" />
            </h2>
            <div className={bem.e("order-view-value")}>
              <TextareaEvaluator
                readOnly={true}
                text={setScriptField(get(group, "jock_instructions", ""))}
                placeholder={intl.formatMessage({
                  id: "process > enter instructions for the jock",
                })}
                hideOrderLength={true}
                hideHelperText={true}
                onEvaluatorCallback={() => {}}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
export default function CopyGroup(props) {
  const {
    isView,
    isOpen,
    toggle,
    group,
    intl,
    template,
    user,
    step,
    onAddToRotation,
    isShowDelete,
    process,
    validationState,
  } = props;
  const [isOpenScriptHistoryModal, setIsOpenScriptHistoryModal] = useState(
    false
  );
  const [scriptsHistory, setScriptsHistory] = useState(null);

  return (
    <div className={bem.b()}>
      <div
        className={classnames(bem.e("header"), {
          [bem.e("header-open")]: isOpen,
        })}
      >
        <div className={bem.e("header-left")}>
          <span>
            {get(group, "title") ? (
              group.title
            ) : (
              <FormattedMessage id="process > title" />
            )}
          </span>
        </div>
        <div className={bem.e("header-actions")}>
          {(group.status || group.is_expired) && (
            <span
              className={classnames(bem.e("status-text"), {
                [bem.e("baseline-text")]: group.is_baseline,
                [bem.e("superseding-text")]: group.is_superseding,
                [bem.e("expired-text")]: group.is_expired,
              })}
            >
              <FormattedMessage
                id="process > promotion > liner > copy group status"
                values={{
                  status: group.is_expired ? "expired" : group.status,
                }}
              />
            </span>
          )}

          {isShowDelete && (
            <span
              onClick={() => props.onDelete()}
              className={bem.e("delete-button")}
            >
              <DeleteIcon />
            </span>
          )}

          <span onClick={() => toggle()} className={bem.e("toggle-button")}>
            {isOpen ? <UpIcon /> : <DownIcon />}
          </span>
        </div>
      </div>
      {isOpen && (
        <div className={bem.e("content")}>
          {!isView ? (
            <CopyGroupForm
              idx={props.idx}
              onChange={props.onChange}
              group={group}
              intl={intl}
              step={step}
              process={process}
              template={template}
              user={user}
              validationState={validationState}
              onAddToRotation={onAddToRotation}
              setIsOpenScriptHistoryModal={setIsOpenScriptHistoryModal}
              setScriptsHistory={setScriptsHistory}
            />
          ) : (
            <CopyGroupView
              idx={props.idx}
              group={group}
              intl={intl}
              step={step}
              process={process}
              template={template}
              user={user}
              setIsOpenScriptHistoryModal={setIsOpenScriptHistoryModal}
              setScriptsHistory={setScriptsHistory}
            />
          )}
        </div>
      )}
      <ScriptHistoryModal
        isOpen={isOpenScriptHistoryModal}
        onToggle={() => setIsOpenScriptHistoryModal(!isOpenScriptHistoryModal)}
        scripts={scriptsHistory}
      />
    </div>
  );
}
