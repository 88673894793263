import React from "react";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import SelectionDropdown from "components/SelectionDropdown";
import { FulfillmentArrow } from "components/CustomIcons";
import SelectionBox from "../SelectionBox";
import SelectionBoxText from "../SelectionBoxText";
import { get } from "lodash";
export default function EmailAutomationNoVerification(props) {
  const {
    bem,
    intl,
    formProps,
    setShouldBlockNavigation,
    winnerAnnouncementOptions,
    fulfillmentFormOptions,
    prizeReleaseOptions,
    prizeFulfillmentOptions,
    reminderDays,
  } = props;
  const { errors, values, touched } = formProps;

  const selectedAnnouncementOption = winnerAnnouncementOptions.find(
    (item) => item.value === get(values, "settings.winner_announcement", "")
  );
  const selectedFulfillmentFormOption = fulfillmentFormOptions.find(
    (item) =>
      item.value === get(values, "settings.fulfillment_form_template", "")
  );
  const selectedPrizeReleaseOption = prizeReleaseOptions.find(
    (item) => item.value === get(values, "settings.prize_release_template", "")
  );
  const selectedPrizeFulfillmentOption = prizeFulfillmentOptions.find(
    (item) =>
      item.value === get(values, "settings.prize_fulfillment_template", "")
  );
  return (
    <>
      <div className={classNames(bem.e("step"), bem.e("step-start"))}>
        <span className={bem.e("start-text")}>
          <FormattedMessage id="station settings > start" />
        </span>
        <SelectionBox bem={bem} intl={intl}>
          <span
            dangerouslySetInnerHTML={{
              __html: `${intl.formatMessage({
                id:
                  "station settings > upon selection of a winner in the studio",
              })}.`,
            }}
          ></span>
          <SelectionDropdown
            placeholder={intl.formatMessage({
              id: "station settings > select winner announcement",
            })}
            type="notification"
            isAllowAdd
            name="winner_announcement"
            value={selectedAnnouncementOption}
            options={winnerAnnouncementOptions}
            onChange={(option) => {
              formProps.setFieldValue(
                "settings.winner_announcement",
                option ? option.value : ""
              );
              setShouldBlockNavigation(true);
            }}
            onClickNew={() => {
              const { location } = window;
              const url = `${location.origin}/system-templates?tab=winner_notification&station=${values.station_id}`;
              window.open(url, "_blank").focus();
            }}
            error={
              get(touched, "settings.winner_announcement") &&
              get(errors, "settings.winner_announcement")
            }
          />
          <span
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: "station settings > include a link to",
              }),
            }}
          ></span>
          <SelectionDropdown
            placeholder={intl.formatMessage({
              id: "station settings > select fulfillment form template",
            })}
            type="form"
            isAllowAdd
            value={selectedFulfillmentFormOption}
            options={fulfillmentFormOptions}
            onChange={(option) => {
              formProps.setFieldValue(
                "settings.fulfillment_form_template",
                option ? option.value : ""
              );
              setShouldBlockNavigation(true);
            }}
            onClickNew={() => {
              const { location } = window;
              const url = `${location.origin}/form-manager?tab=promotion&station=${values.station_id}`;
              window.open(url, "_blank").focus();
            }}
            error={
              get(touched, "settings.fulfillment_form_template") &&
              get(errors, "settings.fulfillment_form_template")
            }
          />{" "}
          ,{" "}
          <SelectionDropdown
            placeholder={intl.formatMessage({
              id: "station settings > select prize release template",
            })}
            type="form"
            isAllowAdd
            value={selectedPrizeReleaseOption}
            options={prizeReleaseOptions}
            onChange={(option) => {
              formProps.setFieldValue(
                "settings.prize_release_template",
                option ? option.value : ""
              );
              setShouldBlockNavigation(true);
            }}
            onClickNew={() => {
              const { location } = window;
              const url = `${location.origin}/system-templates?tab=release_form&station=${values.station_id}`;
              window.open(url, "_blank").focus();
            }}
            error={
              get(touched, "settings.prize_release_template") &&
              get(errors, "settings.prize_release_template")
            }
          />
          <span
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: "station settings > and a W-9 form, if required.",
              }),
            }}
          ></span>
        </SelectionBox>
      </div>
      <div className={classNames(bem.e("step"), bem.e("step-middle"))}>
        <SelectionBoxText
          bem={bem}
          intl={intl}
          text={`completed form is not received within ${reminderDays} days, send reminder email.`}
        />
      </div>
      <div className={bem.e("step")}>
        <SelectionBox bem={bem} intl={intl}>
          <span
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id:
                  "station settings > upon receiving a completed winner release form",
              }),
            }}
          ></span>
          <SelectionDropdown
            placeholder={intl.formatMessage({
              id: "station settings > select prize fulfillment template",
            })}
            type="form"
            isAllowAdd
            value={selectedPrizeFulfillmentOption}
            options={prizeFulfillmentOptions}
            onChange={(option) => {
              formProps.setFieldValue(
                "settings.prize_fulfillment_template",
                option ? option.value : ""
              );
              setShouldBlockNavigation(true);
            }}
            onClickNew={() => {
              const { location } = window;
              const url = `${location.origin}/system-templates?tab=winner_notification&station=${values.station_id}`;
              window.open(url, "_blank").focus();
            }}
            error={
              get(touched, "settings.prize_fulfillment_template") &&
              get(errors, "settings.prize_fulfillment_template")
            }
          />
          .
        </SelectionBox>
        <span className={bem.e("arrow")}>
          <FulfillmentArrow />
        </span>
      </div>
    </>
  );
}
