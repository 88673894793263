import { FormattedMessage } from "react-intl";
import React from "react";
import { get } from "lodash";

const airCheck = {
  process_step_index: 9,
  key: "air_check",
  title: <FormattedMessage id="process > title aircheck" />,
  caption_color: "#CC1EE6",
  description: (
    <FormattedMessage id="process > description upload aircheck recording" />
  ),
  isCollapsible: true,
  step_roles: [1, 2, 3],
  step_viewers_roles: [1, 2, 3, 4, 7],
  step_editors_roles: [1, 2, 3, 4],
  step_viewers_privileges: {
    or: ["submit_production_air_check", "edit_all_production_orders"],
  },
  step_editors_privileges: {
    or: ["submit_production_air_check", "edit_all_production_orders"],
  },
  is_check_hidden_tab: true,
  status: "initial",
  className: "air-check air-check-provided",
  is_hidden: function(user, process, step) {
    return !get(process, "data.fields.aircheck_requires_approval.value", false);
  },
  workflow: {
    title: "air check",
    icon: "ApprovalIcon",
  },
  is_approval_step: true,
  fields: [
    {
      field: "aircheck_path",
      component: "file_upload",
      mandatory: true,
      props: {
        extensions: ["mp3", "wav", "aiff", "flac", "aac", "alac", "m4a", "mp4"],
      },
      validation: function(step, item, process, template, user, value) {
        return value !== "" ? true : "Please upload the aircheck recording";
      },
    },
    {
      text: <FormattedMessage id="process > button submit" />,
      component: "button",
      props: { color: "blue" },
      key: "save_button",
      container: "bottom_buttons",
      class: null,
      on_click: {
        validate: true,
        save: true,
      },
      fields: [
        {
          key: "air_check_status",
          // "value": "submitted"
        },
        {
          key: "process_step",
          value: "done",
        },
      ],
      confirmation_dialog_props: {
        title: (
          <FormattedMessage id="process > description submit the aircheck" />
        ),
        confirmTitle: <FormattedMessage id="process > button yes" />,
        cancelTitle: <FormattedMessage id="process > button no" />,
      },
      success_dialog_props: {
        title: <FormattedMessage id="process > title aircheck submitted" />,
        state: "success",
      },
    },
  ],
};
export default airCheck;
