import React from "react";
import bn from "utils/bemnames";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import EmptyAnnouncementImage from "../../assets/img/bg/empty-announcement.png";
const bem = bn.create("empty-announcement");

const EmptyAnnouncement = ({ title, description }) => (
  <div className={bem.b()}>
    <div className={bem.e("content")}>
      <div className={bem.e("image")}>
        <img src={EmptyAnnouncementImage} alt="empty-announcement" />
      </div>
      <h3 className={bem.e("title")}>{title}</h3>
      <p className={bem.e("description")}>{description} </p>
    </div>
  </div>
);
EmptyAnnouncement.propTypes = {
  title: PropTypes.any,
  description: PropTypes.any
};
EmptyAnnouncement.defaultProps = {
  title: <FormattedMessage id="empty announcement > title" />,
  description: <FormattedMessage id="empty announcement > description" />
};
export default EmptyAnnouncement;
