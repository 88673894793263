import React from "react";
import bn from "utils/bemnames";
import { FormattedMessage } from "react-intl";
import SearchInput from "components/SearchAutocompleteInput";
import { Button } from "reactstrap";
import { get } from "lodash";
import CustomMultiSelectDropdown from "components/CustomMultiSelectDropdown";
import { Link } from "react-router-dom";
import { adminTabs } from "utils/config";
import URL from "utils/urls";

const bem = bn.create("process-list");
function WinnersListHeader(props) {
  const {
    exportWinners,
    saveSearch,
    intl,
    optionStations,
    selectedStations,
    setSelectedStations,
    total,
  } = props;
  const savedSearchesItems = get(props, "savedSearches", []);
  return (
    <div className={bem.e("winners-header")}>
      <div className={bem.e("header")}>
        <div className={bem.e("title")}>
          <Link to={URL.ADMINS({ tab: adminTabs.TAB_UNKNOWN })}>
            <FormattedMessage id="breadcrumbs > home" /> /{` `}
          </Link>
          <strong>
            <FormattedMessage id={`breadcrumbs > winners list`} />
          </strong>
        </div>
        {/* stations */}
        <div className={bem.e("winners-stations")}>
          <span className={bem.e("viewing-text")}>
            <FormattedMessage id="winners > viewing" />
          </span>
          <CustomMultiSelectDropdown
            name="stations"
            value={selectedStations}
            options={optionStations}
            placeholder={intl.formatMessage({
              id: "winners > please select",
            })}
            isAllowAllOption={true}
            allOption={{
              label: intl.formatMessage({
                id: "winners > all stations",
              }),
              value: "*",
            }}
            isMulti={true}
            isStation
            isAllowSelectGroup
            error={null}
            onChange={(value) => {
              setSelectedStations(value);
            }}
            multiText={`${selectedStations.length} ${intl.formatMessage({
              id: "winners > stations",
            })}`}
          />
        </div>
      </div>
      <div className={bem.e("winners-actions")}>
        <div className={bem.e("export-winners")}>
          <div className={bem.e("total")}>
            <strong>{total}</strong>
            <FormattedMessage id="winners > winners" />
          </div>
          {total > 0 ? (
            <Button
              color="link"
              className={bem.e("btn-export")}
              onClick={exportWinners}
            >
              <FormattedMessage id="winners > export list" />
            </Button>
          ) : null}

          {props.isShowSaveSearch && (
            <Button
              color="link"
              className={bem.e("btn-save-search")}
              onClick={saveSearch}
            >
              <FormattedMessage id="winners > save search" />
            </Button>
          )}
        </div>
        {props.isShowSearch && (
          <div className={bem.e("search-wrapper")}>
            <span
              onClick={() => {
                props.setIsShowAdvancedSearchModal(true);
              }}
              className={bem.e("advanced-search-text-relative")}
            >
              <FormattedMessage id="process > advanced search" />
            </span>
            <SearchInput
              searchCallback={(params) => {
                // response fake data to display the menu items of auto-complete lib
                params.cb([
                  {
                    title: "",
                  },
                ]);
              }}
              onChange={(value) => {
                props.setSearchText(value);
              }}
              onClearSearch={props.onClearSearch}
              value={props.searchText}
              placeholder={""}
              isShowSavedSearches
              savedSearches={savedSearchesItems}
              isShowMenuItems={false}
              onSelectSavedSearch={props.onSelectedSavedSearch}
              onSearchClicked={props.onSearchClicked}
            />
          </div>
        )}
      </div>
    </div>
  );
}
WinnersListHeader.defaultProps = {
  isShowSearch: true,
  isShowSaveSearch: false,
  exportWinners: () => {},
  saveSearch: () => {},
};
export default WinnersListHeader;
