
export const FETCH_GROUP_REQUEST = "FETCH_GROUP_REQUEST";
export const FETCH_GROUP_SUCCESS = "FETCH_GROUP_SUCCESS";
export const FETCH_GROUP_ERROR = "FETCH_GROUP_ERROR";

export const SAVE_GROUP_REQUEST = "SAVE_GROUP_REQUEST";
export const SAVE_GROUP_SUCCESS = "SAVE_GROUP_SUCCESS";
export const SAVE_GROUP_ERROR = "SAVE_GROUP_ERROR";

export const UPDATE_GROUP_REQUEST = "UPDATE_GROUP_REQUEST";
export const UPDATE_GROUP_SUCCESS = "UPDATE_GROUP_SUCCESS";
export const UPDATE_GROUP_ERROR = "UPDATE_GROUP_ERROR";


