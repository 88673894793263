import * as React from "react";

const useElementScroll = ({ useWindow = true, elementId, handler }) => {
  // observe scroll
  React.useEffect(() => {
    let element = window;

    if (!useWindow) {
      element = document.getElementById(elementId);
    }

    if (element) {
      element.addEventListener("scroll", handler);
      return () => element.removeEventListener("scroll", handler);
    }
  }, [useWindow, elementId]);
};

export default useElementScroll;
