import * as React from "react";
import bn from "utils/bemnames";
import { get, map, filter, find, split, trim, indexOf } from "lodash";
import { Row, Col, FormGroup, Button, Label } from "reactstrap";
import { FormattedMessage } from "react-intl";
import TextInput from "components/TextInput";
import { Asterisk } from "components/Elements";
import CustomMultiSelectDropdown from "components/CustomMultiSelectDropdown";
import { CheckboxIcon } from "components/CustomIcons";
import ToggleSwitch from "components/ToggleSwitch";
import classnames from "classnames";
import TextInputFormatted from "components/TextInputFormatted";
import { countryList } from "utils/config";
import { convertArrayToString } from "utils/helpers";
const bem = bn.create("station-settings-form");
function getSelectedCountries(values, optionCounties) {
  const countries = map(values.listener_countries, (item) => {
    const countryData = find(optionCounties, (i) => i.value === item);
    return countryData;
  });
  return countries;
}
function getListenerStatement({ data, station, intl }) {
  const countries = countryList.filter(
    (item) => indexOf(data.listener_countries, item.value) !== -1
  );
  if (data.selected_by === "zip_code" && countries.length > 0) {
    if (data.postal_codes) {
      return (
        <FormattedMessage
          id="station settings > listener statement for zip codes"
          values={{
            COUNTRIES: convertArrayToString(
              countries.map((i) => i.label),
              ",",
              "or"
            ),
            STATION: station.label,
          }}
        />
      );
    }
    return (
      <FormattedMessage
        id="station settings > listener statement for zip codes default"
        values={{
          COUNTRIES: convertArrayToString(
            countries.map((i) => i.label),
            ",",
            "or"
          ),
          STATION: station.label,
        }}
      />
    );
  }
  if (data.selected_by === "state_county" && countries.length > 0) {
    let textArr = [
      intl.formatMessage(
        {
          id: "station settings > listener statement countries",
        },
        {
          COUNTRIES: convertArrayToString(
            countries.map((i) => i.label),
            ",",
            "or"
          ),
          STATION: station.label,
        }
      ),
    ];
    if (data.provinces && data.provinces.length > 0) {
      textArr.push(
        intl.formatMessage(
          {
            id: "station settings > listener statement provinces",
          },
          {
            PROVINCES: convertArrayToString(data.provinces, ",", "and"),
          }
        )
      );
    }
    if (data.states && data.states.length > 0) {
      textArr.push(
        intl.formatMessage(
          {
            id: "station settings > listener statement states",
          },
          {
            STATES: convertArrayToString(data.states, ",", "and"),
            COUNTIES: convertArrayToString(data.counties, ",", "and"),
          }
        )
      );
    }
    return `${textArr.join(", ")}.`;
  }
  return (
    <FormattedMessage id="station settings > listener geography statement default" />
  );
}
const ListenerGeography = (props) => {
  const {
    intl,
    formProps,
    optionCountries,
    optionStates,
    restrictToHomeCountry,
    optionCounties,
  } = props;
  const { values, errors, touched } = formProps;
  const [rangeStart, setRangeStart] = React.useState("");
  const [rangeEnd, setRangeEnd] = React.useState("");
  const isSelectedCA = find(values.listener_countries, (item) => item === "CA");
  const isSelectedUS = find(values.listener_countries, (item) => item === "US");
  const isSelectedMX = find(values.listener_countries, (item) => item === "MX");
  const isShowStates =
    isSelectedUS || isSelectedMX || values.listener_countries.length === 0;
  const isShowCounties = isSelectedUS || isSelectedMX;
  const isShowUnits = isSelectedCA;
  const isShowProvinces = isSelectedCA;
  const selectedCountries = getSelectedCountries(values, optionCountries);
  React.useEffect(() => {
    if (values.listener_countries && values.listener_countries.length > 0) {
      props.onGetStatesByCountries(values.listener_countries);
    }

    if (values.states && values.states.length > 0) {
      props.onGetCounties(values.states);
    }
  }, []);
  return (
    <div className={bem.e("listener-geography")}>
      <div className={bem.e("form-padding")}>
        <h2 className={bem.e("title")}>
          <FormattedMessage id={"station settings > listener geography"} />
        </h2>
      </div>

      <div className={bem.e("form-padding")}>
        <span className={bem.e("field-helper-text")}>
          <FormattedMessage id="station settings > limit winner eligibility by country" />
        </span>
        <Row>
          <Col xs={3} className={bem.e("col-reset")}>
            <FormGroup>
              <Label>
                <FormattedMessage id="station settings > listener country" />
                <Asterisk>*</Asterisk>
              </Label>
              {!restrictToHomeCountry ? (
                <CustomMultiSelectDropdown
                  name="listener_countries"
                  value={selectedCountries}
                  options={optionCountries}
                  placeholder={intl.formatMessage({
                    id: "station settings > please select",
                  })}
                  isAllowAllOption={false}
                  isMulti={true}
                  error={get(errors, "listener_countries")}
                  onChange={(value) => {
                    const newValue = filter(value, (item) =>
                      find(optionCountries, (i) => i.value === item.value)
                    ).map((item) => item.value);
                    formProps.setFieldValue("listener_countries", newValue);
                    props.onGetStatesByCountries(newValue);
                    formProps.setFieldValue("states", []);
                    formProps.setFieldValue("provinces", []);
                    formProps.setFieldValue("counties", []);
                    formProps.setFieldValue("units", []);
                    // props.onGetStatesByCountries(newValue, (states) => {
                    //   const newStates = map(states, (item) => item.value);
                    //   formProps.setFieldValue("states", newStates);
                    //   formProps.setFieldValue("provinces", newStates);
                    //   formProps.setFieldValue("units", newStates);
                    //   props.onGetCounties(newStates, (counties) => {
                    //     let newCounties = [];
                    //     counties.forEach((element) => {
                    //       newCounties = [
                    //         ...newCounties,
                    //         ...map(element.options, (option) => option.value),
                    //       ];
                    //     });
                    //     formProps.setFieldValue("counties", newCounties);
                    //   });
                    // });
                  }}
                  multiText={intl.formatMessage({
                    id: "station settings > multiple",
                  })}
                />
              ) : (
                <div className={bem.e("selected-input-checkbox")}>
                  <span className={bem.e("selected-checkbox")}>
                    <CheckboxIcon />
                  </span>
                  <span className={bem.e("selected-value")}>
                    {selectedCountries && selectedCountries.length > 0
                      ? selectedCountries.map((i) => i.label).join(", ")
                      : ""}
                  </span>
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={6} className={bem.e("col-reset")}>
            <FormGroup>
              <ToggleSwitch
                leftComponent={
                  <span>
                    {intl.formatMessage({
                      id: "station settings > state county",
                    })}
                  </span>
                }
                rightComponent={
                  <span>
                    {intl.formatMessage({
                      id: "station settings > zip code",
                    })}
                  </span>
                }
                name="selected_by"
                switchProps={{
                  checked: get(values, "selected_by", "") !== "state_county",
                  onChange: (checked) => {
                    formProps.setFieldValue(
                      "selected_by",
                      checked ? "zip_code" : "state_county"
                    );
                    formProps.setFieldValue("states", []);
                    formProps.setFieldValue("provinces", []);
                    formProps.setFieldValue("counties", []);
                    formProps.setFieldValue("units", []);
                    formProps.setFieldValue("postal_codes", "");
                  },
                  offColor: "#795AFA",
                  onColor: "#795AFA",
                  uncheckedIcon: false,
                  checkedIcon: false,
                  handleDiameter: 22,
                  width: 50,
                  height: 26,
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <div className={bem.e("limit-text")}>
          {values.selected_by === "zip_code" ? (
            <FormattedMessage id="station settings > limit winner eligibility by zip code" />
          ) : (
            <FormattedMessage id="station settings > limit winner eligibility by state" />
          )}
        </div>
        {values.selected_by === "zip_code" ? (
          <React.Fragment>
            <Row>
              <Col xs={4} className={bem.e("col-reset")}>
                <FormGroup>
                  <TextInput
                    label={
                      <FormattedMessage id="station settings > enter zip postal codes" />
                    }
                    type="textarea"
                    name="postal_codes"
                    placeholder={intl.formatMessage({
                      id: "station settings > postal codes placeholder",
                    })}
                    value={values.postal_codes}
                    error={touched.postal_codes && errors.postal_codes}
                    onChange={formProps.handleChange}
                    onBlur={formProps.handleBlur}
                  />
                </FormGroup>
              </Col>
              <Col xs={3} className={bem.e("col-reset")}>
                <FormGroup>
                  <Label>
                    <FormattedMessage id="station settings > enter range for zip / postal codes" />
                  </Label>
                  <div className={bem.e("range-inputs")}>
                    <TextInputFormatted
                      placeholder={intl.formatMessage({
                        id: "station settings > start",
                      })}
                      value={rangeStart}
                      onChange={setRangeStart}
                    />
                    <span className={bem.e("range-divider")}>-</span>
                    <TextInputFormatted
                      placeholder={intl.formatMessage({
                        id: "station settings > end",
                      })}
                      value={rangeEnd}
                      onChange={setRangeEnd}
                    />
                  </div>
                  <Button
                    outline
                    className="btn-radius btn-range-submit"
                    color="blue"
                    disabled={!rangeStart || !rangeEnd}
                    onClick={() => {
                      const postalCodesArr = split(values.postal_codes, ",")
                        .map((item) => trim(item))
                        .filter((item) => item);
                      formProps.setFieldValue(
                        "postal_codes",
                        [...postalCodesArr, rangeStart, rangeEnd].join(", ")
                      );
                      setRangeEnd("");
                      setRangeStart("");
                    }}
                  >
                    <FormattedMessage id="station settings > submit" />
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Row>
              {isShowStates && (
                <Col xs={3} className={bem.e("col-reset")}>
                  <FormGroup>
                    <Label>
                      <FormattedMessage id="station settings > states" />
                    </Label>
                    <CustomMultiSelectDropdown
                      name="states"
                      value={map(values.states, (item) => ({
                        label: item,
                        value: item,
                      }))}
                      options={optionStates}
                      placeholder={intl.formatMessage({
                        id: "station settings > please select",
                      })}
                      isAllowAllOption={false}
                      isMulti={true}
                      error={get(errors, "states")}
                      onChange={(value) => {
                        const newValue = map(value, (item) => item.value);
                        formProps.setFieldValue("states", newValue);
                        formProps.setFieldValue("provinces", []);
                        formProps.setFieldValue("counties", []);
                        formProps.setFieldValue("units", []);
                        props.onGetCounties(newValue);
                      }}
                      multiText={intl.formatMessage({
                        id: "station settings > multiple",
                      })}
                    />
                  </FormGroup>
                </Col>
              )}

              {isShowProvinces && (
                <Col xs={3} className={bem.e("col-reset")}>
                  <FormGroup>
                    <Label>
                      <FormattedMessage id="station settings > provinces" />
                    </Label>
                    <CustomMultiSelectDropdown
                      name="provinces"
                      value={map(values.provinces, (item) => ({
                        label: item,
                        value: item,
                      }))}
                      options={optionStates}
                      placeholder={intl.formatMessage({
                        id: "station settings > please select",
                      })}
                      isAllowAllOption={false}
                      isMulti={true}
                      error={get(errors, "provinces")}
                      onChange={(value) => {
                        const newValue = map(value, (item) => item.value);
                        formProps.setFieldValue("provinces", newValue);
                      }}
                      multiText={intl.formatMessage({
                        id: "station settings > multiple",
                      })}
                    />
                  </FormGroup>
                </Col>
              )}
            </Row>
            <Row>
              {isShowCounties && (
                <Col xs={3} className={bem.e("col-reset")}>
                  <FormGroup>
                    <Label>
                      {values.listener_countries.length > 1 ? (
                        <FormattedMessage id="station settings > counties parishes" />
                      ) : (
                        <FormattedMessage id="station settings > counties" />
                      )}
                    </Label>
                    <CustomMultiSelectDropdown
                      name="counties"
                      value={map(values.counties, (item) => ({
                        label: item,
                        value: item,
                      }))}
                      options={optionCounties}
                      placeholder={intl.formatMessage({
                        id: "station settings > please select",
                      })}
                      isAllowAllOption={false}
                      isMulti={true}
                      isAllowSelectGroup
                      error={get(errors, "counties")}
                      onChange={(value) => {
                        const newValue = map(value, (item) => item.value);
                        formProps.setFieldValue("counties", newValue);
                      }}
                      multiText={intl.formatMessage({
                        id: "station settings > multiple",
                      })}
                    />
                  </FormGroup>
                </Col>
              )}

              {isShowUnits && (
                <Col xs={3} className={bem.e("col-reset")}>
                  <FormGroup>
                    <Label>
                      <FormattedMessage id="station settings > census geographic units" />
                    </Label>
                    <CustomMultiSelectDropdown
                      name="units"
                      value={map(values.units, (item) => ({
                        label: item,
                        value: item,
                      }))}
                      options={optionStates}
                      placeholder={intl.formatMessage({
                        id: "station settings > please select",
                      })}
                      isAllowAllOption={false}
                      isMulti={true}
                      error={get(errors, "units")}
                      onChange={(value) => {
                        const newValue = map(value, (item) => item.value);
                        formProps.setFieldValue("units", newValue);
                      }}
                      multiText={intl.formatMessage({
                        id: "station settings > multiple",
                      })}
                    />
                  </FormGroup>
                </Col>
              )}
            </Row>
          </React.Fragment>
        )}
        <div className={bem.e("listener-statement")}>
          <Label>
            <FormattedMessage id="station settings > listener geography statement" />
          </Label>
          <div className={bem.e("listener-statement-text")}>
            {getListenerStatement({
              data: values,
              station: props.currentStation,
              intl,
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ListenerGeography;
