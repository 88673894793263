import React from "react";
import JockConsole from "components/JockConsole";
import bn from "utils/bemnames";
import PrivilegedComponent from "components/PrivilegedComponent";
import { PRIVILEGES } from "utils/constants";
import { Redirect } from "react-router";

const bem = bn.create("jock-console-page");

const JockConsolePage = () => {
  return (
    <PrivilegedComponent
      requires={{
        or: [PRIVILEGES.VIEW_TASKS, PRIVILEGES.PERFORM_TASKS],
      }}
    >
      {({ isValid }) => {
        if (isValid) {
          return (
            <div className={bem.b()}>
              <JockConsole />
            </div>
          );
        } else {
          return <Redirect to="/" />;
        }
      }}
    </PrivilegedComponent>
  );
};

export default JockConsolePage;
