import { connect } from "react-redux";
import { get } from "lodash";
import moment from "moment";
import { compose, withState, withHandlers } from "recompose";
import { injectIntl } from "react-intl";

import { getItemsAvailability } from "store/actions/programmings";
import ItemAvailability from "./view";

const Enhanced = compose(
  connect(
    (state) => {
      const itemsAvailability = get(
        state,
        "programmings.itemsAvailability.data",
        {}
      );
      return {
        isLoading: get(state, "programmings.itemsAvailability.loading", false),
        slotDefinitions: get(itemsAvailability, "slot_definitions", []),
        dayparts: get(itemsAvailability, "dayparts", []),
        shows: get(itemsAvailability, "shows", []),
        availability: get(itemsAvailability, "availability", []),
        numberOfWeeks: 12,
      };
    },
    { getItemsAvailability }
  ),
  withState("availabilityFromDate", "setAvailabilityFromDate", new Date()),
  withState("availabilityFilter", "setAvailabilityFilter", {}),
  withHandlers({
    onCheckItemsAvailability: ({ setAvailabilityFilter, setAvailabilityFromDate, getItemsAvailability }) => (data = {}, cb) => {
      if (!data.from_date) {
        setAvailabilityFromDate(new Date());
      } else {
        setAvailabilityFromDate(data.from_date);
        data.from_date = moment(data.from_date).format("YYYY-MM-DD");
      }
      const { from_date, ...filterData } = data;
      setAvailabilityFilter(filterData);
      getItemsAvailability({ data, cb });
    },
  }),
  withHandlers({
    onLoadMore: ({ availabilityFilter, availabilityFromDate, onCheckItemsAvailability, numberOfWeeks }) => () => {
      const from_date = moment(availabilityFromDate).add(numberOfWeeks, 'weeks');
      onCheckItemsAvailability({ ...availabilityFilter, from_date });
    },
  }),
)(ItemAvailability);

export default injectIntl(Enhanced);
