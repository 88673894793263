import * as React from "react";
import { FormattedMessage } from "react-intl";
import bn from "utils/bemnames";
import {
  MasterListenerIcon,
  EyeIcon,
  DownIcon,
  UpIcon,
} from "components/CustomIcons";
import MasterListenerForm from "../MasterListenerForm";
const bem = bn.create("master-listener");
function getTotalFields(masterListenerForm) {
  return masterListenerForm._source.fields.length;
}
const MasterListener = ({
  intl,
  isOpen,
  setIsOpen,
  currentStation,
  masterListenerForm,
  onPreview,
  isEditable,
  getSystemForms,
  currentServer,
  serverCountries,
  getSystemForm,
}) => {
  if (!masterListenerForm) return null;
  const totalFields = getTotalFields(masterListenerForm);
  return (
    <div className={bem.b()}>
      <div className={bem.e("head-container")}>
        <div className={bem.e("head-left")}>
          <MasterListenerIcon />
          <span className={bem.e("head-title")}>
            {masterListenerForm._source.name || (
              <FormattedMessage id="form manager > master listener database list" />
            )}
          </span>
          <span className={bem.e("total-fields")}>
            ({totalFields} {intl.formatMessage({ id: "form manager > fields" })}
            )
          </span>
        </div>
        <div className={bem.e("head-right")}>
          <div
            className={bem.e("btn-preview")}
            onClick={() => onPreview(masterListenerForm)}
          >
            <EyeIcon color="#795AFA" width={24} height={16} />
            <FormattedMessage id="form manager > preview" />
          </div>
          <button
            className={bem.e("button-down")}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setIsOpen(isOpen ? null : masterListenerForm._id);
            }}
          >
            {isOpen ? (
              <UpIcon
                className={bem.e("button-up-icon")}
                color="#795AFA"
                width={24}
                height={15.46}
              />
            ) : (
              <DownIcon
                className={bem.e("button-down-icon")}
                color="#795AFA"
                width={24}
                height={15.46}
              />
            )}
          </button>
        </div>
      </div>
      {isOpen && (
        <MasterListenerForm
          masterListenerForm={masterListenerForm}
          isEditable={isEditable}
          onCancel={() => {
            setIsOpen(false);
            getSystemForm({ id: masterListenerForm._id });
          }}
          currentStation={currentStation}
          getSystemForms={getSystemForms}
          currentServer={currentServer}
          serverCountries={serverCountries}
        />
      )}
    </div>
  );
};
export default MasterListener;
