import React from "react";
import classNames from "classnames";
import { map, get } from "lodash";
import { FormattedMessage } from "react-intl";
import UploadFile from "components/UploadFile";

export default function VoiceTrackValue(props) {
  const {
    voiceTrackValue,
    isView,
    item,
    bem,
    process,
    isShowInProduction,
  } = props;
  if (isShowInProduction) {
    return (
      <div className={bem.e("vo-track-empty")}>
        <FormattedMessage id="process > title in production" />
      </div>
    );
  }
  return (
    <div
      className={classNames(
        bem.e("viewing-assets"),
        bem.e("other-viewing-assets")
      )}
    >
      {map(voiceTrackValue, (file, index) => (
        <UploadFile
          {...item.props}
          mode={"view"}
          key={`file-${index}`}
          process={process}
          onUploaded={() => {}}
          disabled={isView}
          onDeleted={() => {}}
          url={file.path}
          actions={["download"]}
          positionActions="bottom"
          isShowFileNameInEditMode={true}
          auditInfo={get(item, "props.isShowAudit") && file.vo_audit}
        >
          <FormattedMessage id="process > button upload" />
        </UploadFile>
      ))}
    </div>
  );
}
