import React from "react";
import ServersList from "./view";
import { get } from "lodash";
import { connect } from "react-redux";
import {
  compose,
  lifecycle,
  withState,
  withHandlers,
  withPropsOnChange,
} from "recompose";
import { pageSize } from "utils/config";
import {
  getServers,
  getMoreServers,
  deleteServer,
  requestLoginTargetServer,
} from "store/actions/servers";
import { injectIntl } from "react-intl";
import { MdImportantDevices } from "react-icons/lib/md";
import ToastManager from "components/ToastManager";

export default injectIntl(
  compose(
    connect(
      (state) => ({
        servers: get(state, "servers.servers"),
        isLazyLoading: get(state, "servers.lazyLoad", false),
        auth: get(state, "auth"),
      }),
      {
        getServers,
        getMoreServers,
        deleteServer,
        requestLoginTargetServer,
      }
    ),
    withState("isLoading", "setIsLoading", false),
    withState("searchText", "setSearchText", ""),
    withState("filtered", "setFiltered", []),
    withState("sorted", "setSorted", []),
    withState("isBroadcastModalOpen", "setIsBroadcastModalOpen", false),
    withState("isDeleteModalOpen", "setIsDeleteModalOpen", false),
    withState("deleteServerId", "setDeleteServerId", null),
    withHandlers({
      openDeleteModal: ({ setIsDeleteModalOpen, setDeleteServerId }) => (
        id
      ) => {
        setIsDeleteModalOpen(true);
        setDeleteServerId(id);
      },
      closeDeleteModal: ({ setIsDeleteModalOpen, setDeleteServerId }) => () => {
        setIsDeleteModalOpen(false);
        setDeleteServerId(null);
      },
      onBroadcastModalToggle: ({
        isBroadcastModalOpen,
        setIsBroadcastModalOpen,
      }) => () => {
        setIsBroadcastModalOpen(!isBroadcastModalOpen);
      },
      getServerList: ({ getServers, sorted, filtered }) => async () => {
        getServers({
          pageSize,
          page: 0,
          sorted,
          filtered,
        });
      },
      getMoreServersList: ({ getMoreServers, sorted, filtered }) => async (
        page
      ) => {
        getMoreServers({
          pageSize,
          page,
          sorted,
          filtered,
        });
      },
    }),
    withHandlers({
      onLoadMore: ({ getMoreServersList, servers }) => () => {
        getMoreServersList(get(servers, "data.current_page", 1));
      },
    }),
    withHandlers({
      onDelete: ({ closeDeleteModal, deleteServerId, deleteServer }) => () => {
        deleteServer(deleteServerId);
        closeDeleteModal();
      },
      onSearchKeyDown: ({ getServerList }) => (e) => {
        if (e.keyCode === 13) {
          getServerList();
        }
      },
      onSearchClicked: ({ getServerList }) => () => {
        getServerList();
      },
      onChangeInput: ({ filtered, setFiltered, setSearchText }) => ({
        target,
      }) => {
        const value = target.value;
        const name = target.name;
        const filterSearch = {
          id: name,
          value,
        };
        const index = filtered.findIndex((item) => item.id === name);
        if (index === -1) {
          filtered.push(filterSearch);
        } else {
          filtered[index] = filterSearch;
        }
        setSearchText(value);
        setFiltered(filtered);
      },
      onRequestLoginTargetServer: ({
        requestLoginTargetServer,
        intl,
        auth,
        setIsLoading,
      }) => (server) => {
        setIsLoading(true);
        requestLoginTargetServer(server, (res) => {
          setIsLoading(false);
          if (res.status && res.guid) {
            const { guid } = res;
            const { url } = server;
            window.open(
              `${url}/admin/login/${auth.user.email}/${guid}`,
              "_blank"
            );
          } else {
            ToastManager.show({
              title: <MdImportantDevices />,
              message: res.message
                ? res.message
                : intl.formatMessage({
                    id: "toast > message error admin login other server",
                  }),
              autoDismiss: 1.5,
              level: "error",
            });
          }
        });
      },
    }),
    withPropsOnChange(
      ["sorted", "filtered"],
      ({ getServerList, sorted, filtered, pageSize, isFocused, auth }) => {
        if (sorted.length > 0 || filtered.length > 0 || pageSize) {
          if (isFocused && get(auth, "user.is_god_admin")) {
            getServerList();
          }
        }
      }
    ),
    lifecycle({
      componentDidMount() {
        if (get(this.props, "isFocused")) {
          this.props.setFiltered([]);
          this.props.setSorted([]);
          this.props.getServers({
            pageSize,
            page: 0,
            sorted: [],
            filtered: [],
          });
        }
      },
      componentWillReceiveProps(nextProps) {
        // only call when focused to this tab
        if (get(nextProps, "auth.user.is_god_admin")) {
          if (
            get(this.props, "isFocused") !== get(nextProps, "isFocused") &&
            get(nextProps, "isFocused") &&
            !get(nextProps, "servers.data.data")
          ) {
            this.props.getServerList();
          }
          if (get(nextProps, "servers.is_dirty")) {
            this.props.getServerList();
          }
        }
      },
    })
  )(ServersList)
);
