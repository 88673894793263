import * as React from "react";
import { xor } from "lodash";
import {
  Row
} from "reactstrap";
import { FormattedMessage } from "react-intl";
import {
  ShadowBox,
  SectionTitle,
  SectionFields
} from "components/Elements";
import CheckAvailabilityForm from './CheckAvailabilityForm'

class StaffingAvailability extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedUsers: [],
      availabilityForms: [],
    }
  }
  onAddNew = () => {
    this.setState(prevstate => ({ availabilityForms: [...prevstate.availabilityForms, true ]}))
  }
  onSelect(user) {
    const { value, onChange } = this.props;
    onChange(xor(value, [user.id]));
  }
  render() {
    const { users, value, isOpen } = this.props;
    const { availabilityForms } = this.state;
    return(
      <ShadowBox>
        <SectionTitle
        onClick={() => this.props.click(!isOpen)}
          className="promotion-section-title">
            <FormattedMessage id="staff > section title" />
        </SectionTitle>
        <SectionFields className={!isOpen && "hidden"}>
        <CheckAvailabilityForm isDefault={true} onFormSubmit={ v => console.log(v) } />
        {availabilityForms.map((v, i) =>(<CheckAvailabilityForm key={i} onFormSubmit={ v => console.log(v) } />))}
        <Row>
        <div className={"cr-textinput__add-new-link"} onClick={this.onAddNew}>
            + <FormattedMessage id="staff > add shifts" />
          </div>
        </Row>
        <Row><div style={{ marginTop: '21px' }} className="sa_headline">Team Members</div></Row>
        <Row style={{marginTop: '12px'}}>
          {users.map(user => (
          <div className="sh_user_bdage" onClick={() => this.onSelect(user)} key={user.id} style={{height: '61px', paddingRight: '20px', display: 'flex', flexDirection: 'row'}}>
              <img
                height="46"
                width="46"
                alt=""
                style={{borderRadius: '50%',  border: (value && value.includes(user.id)) ? '1px solid #003BAF' : ''}}
                src={user.avatar_url}
                />
            <div style={{ padding: '8px 0 0 5px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <div style={{ fontSize: '13px', lineHeight: '15px', color: (value && value.includes(user.id)) ? '#003BAF' : '#333333' }}>
                {user.name}
              </div>
              <div style={{ fontSize: '12px', lineHeight: '14px',  color: '#333333'}}>
                10 hrs available
              </div>
            </div>
          </div>
          ))}
        </Row>
        </SectionFields>
      </ShadowBox>
    )
  }
}

export default StaffingAvailability
