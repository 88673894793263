import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  ModalBody,
  Form,
  ModalHeader,
  FormGroup,
  Button,
} from "reactstrap";
import classnames from "classnames";
import bn from "utils/bemnames";
import { FormattedMessage } from "react-intl";
import * as Yup from "yup";
import { Formik } from "formik";
import Dropdown from "components/Dropdown";
import TextInput from "components/TextInput";
import { hubTypes } from "utils/config";
import { find } from "lodash";

const bem = bn.create("hub-modal");

const CreateHubModal = ({ isOpen, onToggle, onSubmit, intl }) => {
  const initialValues = {
    name: "",
    type: "",
  };
  const hubTypesOptions = useMemo(() => {
    return hubTypes.map((option) => ({
      label: intl.formatMessage({ id: `hubs > type ${option.label}` }),
      value: option.value,
    }));
  }, []);
  return (
    <Modal isOpen={isOpen} className={classnames(bem.b())}>
      <ModalHeader toggle={onToggle}>
        <FormattedMessage id="hubs > create your hub" />
      </ModalHeader>
      <ModalBody className={bem.e("body")}>
        <Formik
          enableReinitialize
          initialValues={{
            name: initialValues.title || "",
            type: initialValues.type || "",
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required(
              intl.formatMessage({
                id: "validate > name is required",
              })
            ),
            type: Yup.string().required(
              intl.formatMessage({
                id: "validate > type is required",
              })
            ),
          })}
          onSubmit={onSubmit}
        >
          {(formProps) => {
            const errors = formProps.errors;
            const touched = formProps.touched;
            const values = formProps.values;

            return (
              <Form onSubmit={formProps.handleSubmit} noValidate>
                <FormGroup>
                  <TextInput
                    label={null}
                    type="text"
                    name="name"
                    placeholder={intl.formatMessage({
                      id: "hubs > enter hub name",
                    })}
                    value={values.name}
                    error={touched.name && errors.name}
                    onChange={formProps.handleChange}
                    onBlur={formProps.handleBlur}
                  />
                </FormGroup>
                <FormGroup>
                  <Dropdown
                    placeholder={intl.formatMessage({
                      id: "hubs > hub type",
                    })}
                    name="type"
                    error={touched.type && errors.type}
                    onChange={(selectedOption) => {
                      formProps.setFieldValue("type", selectedOption.value);
                    }}
                    value={find(
                      hubTypesOptions,
                      (item) => item.value === values.type
                    )}
                    options={hubTypesOptions}
                    closeMenuOnSelect={true}
                  />
                </FormGroup>
                <div className={bem.e("buttons")}>
                  <Button type="submit" color="blue" className={"btn-radius"}>
                    <FormattedMessage id="hubs > button submit" />
                  </Button>
                  <Button
                    color="blue"
                    outline
                    className={"btn-radius"}
                    onClick={onToggle}
                  >
                    <FormattedMessage id="hubs > button cancel" />
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

CreateHubModal.propTypes = {
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
};

CreateHubModal.defaultProps = {
  onToggle: () => {},
};

export default CreateHubModal;
