import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import View from "./view";
import { deleteProcess, setActiveEditTab } from "store/actions/processes";
import { forgetPrizes } from "store/actions/prizes";
import { injectIntl } from "react-intl";
const Enhanced = connect(
  () => ({}),
  {
    deleteProcess,
    setActiveEditTab,
    forgetPrizes
  }
)(View);

export default withRouter(injectIntl(Enhanced));
