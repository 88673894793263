import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import bn from "utils/bemnames";
import LocationForm from "components/LocationForm";
import { FormattedMessage } from "react-intl";
import { get } from "lodash";
const bem = bn.create("location-modal");

export default function LocationModal(props) {
  const { isOpen, onToggle, location, optionalFields } = props;
  return (
    <Modal isOpen={isOpen} toggle={onToggle} className={bem.b()} size="lg">
      <ModalHeader toggle={onToggle} className={bem.e("header")}>
        {get(location, "id") ? (
          <FormattedMessage id="location > edit title" />
        ) : (
          <FormattedMessage id="location > add title" />
        )}
      </ModalHeader>
      <ModalBody className={bem.e("body")}>
        <LocationForm
          onCancel={onToggle}
          onSubmitted={props.onSubmitted}
          location={location}
          optionalFields={optionalFields}
        />
      </ModalBody>
    </Modal>
  );
}
