import React from "react";
import bn from "utils/bemnames";
import { Row, Col, FormGroup, Button, Label } from "reactstrap";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import { PlusSmallIcon, DeleteIcon } from "components/CustomIcons";
import TextInput from "components/TextInput";
import { FieldArray } from "formik";
import { get, map } from "lodash";
import moment from "moment";

const bem = bn.create("profile");
const OutOfOffice = (props) => {
  const { isView, user, dateFormatByServer } = props;
  if (isView) {
    return (
      <div
        className={classnames(
          bem.e("out-of-office-section"),
          bem.e("box-section")
        )}
      >
        <h4 className={bem.e("section-title")}>
          <FormattedMessage id="my profile > title out of office" />
        </h4>
        <Row className={bem.e("list-dates")}>
          {/*<PrivilegedComponent
            requires={{
              or: [
                PRIVILEGES.ADD_PRODUCTION,
                PRIVILEGES.EDIT_ALL_PRODUCTION_ORDERS,
              ],
            }}
          >
            <Col xs={12} className={bem.e("reset-col")}>
              <div className={bem.e("workflow-help-text")}>
                <FormattedMessage id="my profile > ooo helper text" />
              </div>
            </Col>
          </PrivilegedComponent>*/}
          <Col className={bem.e("reset-col")}>
            <Label className={bem.e("view-label")}>
              <FormattedMessage id="my profile > start date" />
            </Label>
            {map(user.out_of_office_settings, (item, index) => (
              <div key={index} className={bem.e("view-value")}>
                {moment(item.from_date).format(dateFormatByServer)}
              </div>
            ))}
          </Col>
          <Col className={bem.e("reset-col")}>
            <Label className={bem.e("view-label")}>
              <FormattedMessage id="my profile > return date" />
            </Label>
            {map(user.out_of_office_settings, (item, index) => (
              <div key={index} className={bem.e("view-value")}>
                {moment(item.to_date).format(dateFormatByServer)}
              </div>
            ))}
          </Col>
        </Row>
      </div>
    );
  }
  const { formProps, intl } = props;
  const { values, touched, errors } = formProps;
  const outOfOfficeValues = values.out_of_office_settings;
  const isSingleDateValue =
    outOfOfficeValues.length === 1 &&
    (outOfOfficeValues[0].from_date || outOfOfficeValues[0].to_date);
  const isShowTrash = outOfOfficeValues.length > 1 || isSingleDateValue;

  return (
    <div
      className={classnames(
        bem.e("out-of-office-section"),
        bem.e("box-section")
      )}
    >
      <h4 className={bem.e("section-title")}>
        <FormattedMessage id="my profile > title out of office" />
      </h4>
      <div className={bem.e("list-dates")}>
        {/*<PrivilegedComponent
            requires={{
              or: [
                PRIVILEGES.ADD_PRODUCTION,
                PRIVILEGES.EDIT_ALL_PRODUCTION_ORDERS,
              ],
            }}
          >
          <div className={bem.e("workflow-help-text")}>
            <FormattedMessage id="my profile > ooo helper text" />
          </div>
        </PrivilegedComponent>*/}
        <FieldArray
          name="out_of_office_settings"
          render={(arrayHelper) => (
            <React.Fragment>
              {map(outOfOfficeValues, (item, index) => {
                return (
                  <Row key={index}>
                    <Col xs={4}>
                      <FormGroup>
                        <TextInput
                          label={
                            <FormattedMessage id="my profile > start date" />
                          }
                          type="date"
                          name={`out_of_office.${index}.from_date`}
                          placeholder={intl.formatMessage({
                            id: "my profile > enter date",
                          })}
                          value={item.from_date}
                          onChange={(value) => {
                            formProps.setFieldValue(
                              `out_of_office_settings.${index}.from_date`,
                              value
                            );
                            formProps.setFieldValue(
                              `out_of_office_settings.${index}.to_date`,
                              ""
                            );
                          }}
                          min={moment().format("YYYY-MM-DD")}
                          max={item.to_date}
                          error={
                            get(
                              touched,
                              `out_of_office_settings.${index}.from_date`
                            ) &&
                            get(
                              errors,
                              `out_of_office_settings.${index}.from_date`
                            )
                          }
                          allowShowIcon
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={4}>
                      <FormGroup>
                        <TextInput
                          label={
                            <FormattedMessage id="my profile > return date" />
                          }
                          type="date"
                          name={`out_of_office_settings.${index}.to_date`}
                          placeholder={intl.formatMessage({
                            id: "my profile > enter date",
                          })}
                          value={item.to_date}
                          min={item.from_date || undefined}
                          onChange={(value) =>
                            formProps.setFieldValue(
                              `out_of_office_settings.${index}.to_date`,
                              value
                            )
                          }
                          error={
                            get(
                              touched,
                              `out_of_office_settings.${index}.to_date`
                            ) &&
                            get(
                              errors,
                              `out_of_office_settings.${index}.to_date`
                            )
                          }
                          allowShowIcon
                        />
                      </FormGroup>
                    </Col>
                    {isShowTrash && (
                      <Col xs={2}>
                        <div
                          className={bem.e("trash-date")}
                          onClick={() => {
                            if (isSingleDateValue) {
                              formProps.setFieldValue(
                                `out_of_office_settings.${index}`,
                                {
                                  from_date: "",
                                  to_date: "",
                                }
                              );
                            } else {
                              arrayHelper.remove(index);
                            }
                          }}
                        >
                          <DeleteIcon color="#795AFA" />
                        </div>
                      </Col>
                    )}
                  </Row>
                );
              })}
              <Button
                className={classnames(bem.e("add-button"), "btn-radius")}
                outline
                color="blue"
                onClick={() => {
                  arrayHelper.push({
                    start_date: "",
                    return_date: "",
                  });
                }}
              >
                <span className={bem.e("add-icon")}>
                  <PlusSmallIcon />
                </span>
                <FormattedMessage id="my profile > add dates" />
              </Button>
            </React.Fragment>
          )}
        />
      </div>
    </div>
  );
};
export default OutOfOffice;
