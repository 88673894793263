import React from "react";
import EditProfileForm from "components/EditProfileForm";
import Page from "components/Page";
import { FormattedMessage } from "react-intl";
const View = (props) => {
  const { intl } = props;
  return (
    <Page
      applyPadding={false}
      breadcrumbs={[
        {
          name: intl.formatMessage({
            id: "breadcrumbs > home",
          }),
          active: false,
        },
        {
          name: intl.formatMessage({
            id: "breadcrumbs > my profile",
          }),
          active: true,
        },
      ]}
      title={
        <div>
          <FormattedMessage id="my profile > title" />
        </div>
      }
    >
      <EditProfileForm />
    </Page>
  );
};

export default View;
