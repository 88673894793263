import React from "react";
import { Button, Row, Col } from "reactstrap";
import { get } from "lodash";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import bn from "utils/bemnames";
import RoleForm from "../RoleForm";

export const TAB_INFO = "info";

const bem = bn.create("admin-tab-detail");

const RoleDetails = ({ back, lists, isView }) => {
  let backButton = (
    <Button
      type="button"
      color="blue"
      outline
      className={classnames(bem.e("button-cancel"), "btn-radius")}
      onClick={() => {
        if (back) back();
      }}
    >
      <FormattedMessage id="role > button cancel" />
    </Button>
  );
  let role;
  if (lists) {
    if (Object.keys(lists).length === 1) {
      role = lists[Object.keys(lists)[0]];
    }
  }
  return (
    <div className={bem.b()}>
      <Row>
        <Col>
          <div className={classnames(bem.e("tab-detail"), bem.e("tab-role-detail"))}>
            <RoleForm
              id={get(role, "id")}
              backButton={backButton}
              isView={isView}
              onBack={() => back()}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};
RoleDetails.defaultProps = {
  isView: true
};
RoleDetails.propTypes = {
  isView: PropTypes.bool.isRequired
};
export default RoleDetails;
