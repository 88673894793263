import React from "react";
import { get } from "lodash";
import { FormattedMessage } from "react-intl";
import URL from "utils/urls";
import { isFunction } from "lodash";
import PropTypes from "prop-types";
import classnames from "classnames";
import history from "components/History";
import { CardBody } from "reactstrap";
import Spinner from "components/Spinner";
import ButtonAdd from "components/ButtonAdd";
import SearchInput from "components/SearchInput";
import bn from "utils/bemnames";
import PrizesTable from "./PrizesTable";
import { pageSize } from "utils/config";
import Avatar from "../Avatar";
import { categoryList } from "utils/config";
import { validatePrivileges } from "utils/helpers";
import { PRIVILEGES } from "utils/constants";
import { Link } from "react-router-dom";
import { adminTabs } from "utils/config";
const bem = bn.create("process-list");
export const MODE_IN_TAB = "mode_in_tab";
export const MODE_IN_SIDEBAR = "mode_in_sidebar";
class PrizeList extends React.Component {
  componentDidMount() {
    const filter = get(this.props, "filter");
    const reqQuantity = get(this.props, "reqQuantity");
    const sort = get(
      this.props.prizes["filter_" + filter],
      "sort",
      "created_ts"
    );
    const rpp = get(this.props.prizes["filter_" + filter], "rpp", pageSize);
    const search = get(this.props.prizes["filter_" + filter], "search", "");
    const category = get(this.props.prizes["filter_" + filter], "category", "");

    let stations;
    if (filter === "grand_prizes_list" || filter === "qualifying_prizes_list") {
      stations = get(this.props, "process.data.fields.order_stations.value", [])
        .map((station) => station)
        .join(",");
    }

    if (filter) {
      if (
        !this.props.prizes["filter_" + filter] ||
        this.props.prizes["filter_" + filter].is_dirty
      ) {
        this.props.getPrizes({
          filter,
          sort,
          page: 1,
          rpp,
          search,
          category,
          stations,
          reqQuantity,
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    const filter = get(this.props, "filter");
    let sort = get(
      this.props.prizes["filter_" + filter],
      "sort",
      "created_ts desc"
    );
    let rpp = get(this.props.prizes["filter_" + filter], "rpp", pageSize);
    let search = get(this.props.prizes["filter_" + filter], "search", "");
    const category = get(this.props.prizes["filter_" + filter], "category", "");

    let stations;
    if (filter === "grand_prizes_list" || filter === "qualifying_prizes_list") {
      stations = get(this.props, "process.data.fields.order_stations.value", [])
        .map((station) => station)
        .join(",");
    }
    if (
      !this.props.prizes["filter_" + filter] ||
      this.props.prizes["filter_" + filter].is_dirty
    ) {
      this.props.getPrizes({
        filter,
        sort,
        page: 1,
        rpp,
        search,
        category,
        stations,
      });
    }
  }

  sort = (field) => {
    const filter = get(this.props, "filter");
    const reqQuantity = get(this.props, "reqQuantity");
    let prevSort = get(
      this.props.prizes["filter_" + filter],
      "sort",
      "created_ts desc"
    );
    let page = get(this.props.prizes["filter_" + filter], "page", "1");
    let rpp = get(this.props.prizes["filter_" + filter], "rpp", pageSize);
    let search = get(this.props.prizes["filter_" + filter], "search", "");
    const category = get(this.props.prizes["filter_" + filter], "category", "");
    let stations;
    if (filter === "grand_prizes_list" || filter === "qualifying_prizes_list") {
      stations = get(this.props, "process.data.fields.order_stations.value", [])
        .map((station) => station)
        .join(",");
    }
    // detect on third click
    let sort = prevSort;
    if (sort === field + " desc") {
      sort = "created_ts desc";
    } else {
      sort = sort === field ? sort + " desc" : field;
    }
    if (sort !== prevSort) {
      page = 1;
    }
    this.props.getPrizes({
      filter,
      sort,
      page,
      rpp,
      search,
      category,
      stations,
      reqQuantity,
    });
  };

  search(event) {
    event.preventDefault();
    const filter = get(this.props, "filter");
    const reqQuantity = get(this.props, "reqQuantity");
    let sort = "search"; //get(this.props.prizes['filter_' + filter], "sort", "created_ts desc");
    let page = get(this.props.prizes["filter_" + filter], "page", "1");
    let rpp = get(this.props.prizes["filter_" + filter], "rpp", pageSize);
    const category = get(this.props.prizes["filter_" + filter], "category", "");
    let search = event.target.elements[0].value;
    let stations;
    if (filter === "grand_prizes_list" || filter === "qualifying_prizes_list") {
      stations = get(
        this.props,
        "process.data.fields.contract_stations.value",
        []
      )
        .map((station) => station)
        .join(",");
    }
    this.props.getPrizes({
      filter,
      sort,
      page,
      rpp,
      search,
      category,
      stations,
      reqQuantity,
    });
  }

  onClearSearch = () => {
    const filter = get(this.props, "filter");
    const reqQuantity = get(this.props, "reqQuantity");
    let sort = get(
      this.props.prizes["filter_" + filter],
      "sort",
      "created_ts desc"
    );
    let page = get(this.props.prizes["filter_" + filter], "page", "1");
    let rpp = get(this.props.prizes["filter_" + filter], "rpp", pageSize);
    const category = get(this.props.prizes["filter_" + filter], "category", "");
    let stations;
    if (filter === "grand_prizes_list" || filter === "qualifying_prizes_list") {
      stations = get(
        this.props,
        "process.data.fields.contract_stations.value",
        []
      )
        .map((station) => station)
        .join(",");
    }
    let search = "";
    this.props.getPrizes({
      filter,
      sort,
      page,
      rpp,
      search,
      category,
      stations,
      reqQuantity,
    });
  };

  usersDisplay(user_ids) {
    let users = user_ids.map((user_id) =>
      this.props.auth.info.users.find((user) => user.id === user_id)
    );
    return (
      <span>
        {users.map((user, index) => (
          <div key={index}>
            <Avatar src={user.avatar_url} size={20} className="mb-2" />
            {` `}
            {user.name}
          </div>
        ))}
      </span>
    );
  }

  actionDisplay(prize) {
    if (prize._source.last_action) {
      let payload = { message: prize._source.last_action };
      if (payload.message.chat) {
        return (
          <span>
            <Avatar src={get(payload, "message.from.avatar_url", "")} size={20} />
            {get(payload, "message.from.name", "")}
            <span>
              {" "}
              <FormattedMessage id="prize > wrote" />{" "}
            </span>
            <span>
              {` `}
              {payload.message.chat}
            </span>
          </span>
        );
      } else {
        return (
          <span>
            <Avatar src={get(payload, "message.from.avatar_url", "")} />
            {get(payload, "message.from.name", "")}
            <span>
              {" "}
              <FormattedMessage id="prize > submitted" />{" "}
            </span>
            <span>
              {` `}
              {payload.message.from_step &&
                payload.message.from_step.replace(/_/, " ", "gi") + "'"}
            </span>
          </span>
        );
      }
    }
    return null;
  }

  onClickRow = (prize) => {
    const { onSelectItem, auth } = this.props;
    const privileges = get(auth, "user.privileges", []) || [];

    if (isFunction(onSelectItem)) {
      onSelectItem(prize);
    } else {
      const params = {
        template_id: prize._source.template_key,
        prizes_id: prize._id,
      };
      const isUserHasEditPrivileges = validatePrivileges({
        requires: {
          or: [
            PRIVILEGES.PRIZE_CLOSET_SAVE,
            PRIVILEGES.PRIZE_CLOSET_EDIT,
            PRIVILEGES.PRIZE_CLOSET_DELETE,
          ],
        },
        privileges,
        user: auth.user,
      });
      if (isUserHasEditPrivileges) {
        history.push(URL.UPDATE_PRIZES(params));
      } else {
        history.push(URL.VIEW_PRIZES(params));
      }
    }
  };

  handleLoadMore = () => {
    const filter = get(this.props, "filter");
    let page =
      parseInt(get(this.props.prizes["filter_" + filter], "page", 1)) + 1;
    const reqQuantity = get(this.props, "reqQuantity");
    let sort = get(
      this.props.prizes["filter_" + filter],
      "sort",
      "created_ts desc"
    );
    let rpp = get(this.props.prizes["filter_" + filter], "rpp", pageSize);
    let search = get(this.props.prizes["filter_" + filter], "search", "");
    const category = get(this.props.prizes["filter_" + filter], "category", "");
    let stations;
    if (filter === "grand_prizes_list" || filter === "qualifying_prizes_list") {
      stations = get(this.props, "process.data.fields.order_stations.value", [])
        .map((station) => station)
        .join(",");
    }
    this.props.getPrizes({
      filter,
      sort,
      page,
      rpp,
      search,
      category,
      stations,
      reqQuantity,
    });
  };

  onTypeFilter = (type) => {
    const filter = get(this.props, "filter");
    const reqQuantity = get(this.props, "reqQuantity");
    let page = get(this.props.prizes["filter_" + filter], "page", "1");
    let sort = get(
      this.props.prizes["filter_" + filter],
      "sort",
      "created_ts desc"
    );
    let rpp = get(this.props.prizes["filter_" + filter], "rpp", pageSize);
    let search = get(this.props.prizes["filter_" + filter], "search", "");
    const category = get(this.props.prizes["filter_" + filter], "category", "");
    let stations;
    if (filter === "grand_prizes_list" || filter === "qualifying_prizes_list") {
      stations = get(this.props, "process.data.fields.order_stations.value", [])
        .map((station) => station)
        .join(",");
    }
    this.props.getPrizes({
      filter,
      sort,
      page,
      rpp,
      search,
      category: category === type ? "" : type, // toggle un check
      stations,
      reqQuantity,
    });
  };

  renderHeader = () => {
    const { filter, mode } = this.props;
    const category = get(this.props.prizes["filter_" + filter], "category", "");
    if (mode === MODE_IN_TAB) {
      return (
        <div className={bem.e("header-tab-prize-closet")}>
          <SearchInput
            placeholder={this.props.intl.formatMessage({
              id: "prizes > placeholder search input",
            })}
            onClear={this.onClearSearch}
            onSubmit={this.search.bind(this)}
            value={get(this.props.prizes["filter_" + filter], "search", "")}
          />
          <ul className={bem.e("prize-categories")}>
            {categoryList.map((item, index) => (
              <li
                key={index}
                className={classnames({
                  [bem.e("category-active")]: category === item.value,
                })}
                onClick={() => this.onTypeFilter(item.value)}
              >
                {item.label}
              </li>
            ))}
          </ul>
        </div>
      );
    }
    return (
      <div className={bem.e("header")}>
        <div className={classnames(bem.e("title"), bem.e("prize-title"))}>
          <Link to={URL.ADMINS({ tab: adminTabs.TAB_UNKNOWN })}>
            <FormattedMessage id="breadcrumbs > home" /> /{` `}
          </Link>

          <strong>
            <FormattedMessage id={`prizes > title ${filter}`} />
          </strong>
        </div>
        <div className={bem.e("header-actions")}>
          <ButtonAdd
            onClick={() => {
              history.push(
                URL.ADD_PRIZES({
                  template_id: "new_prize",
                })
              );
            }}
            children={<FormattedMessage id="prizes > add new prizes" />}
          />
          <SearchInput
            placeholder={this.props.intl.formatMessage({
              id: "prizes > placeholder search input",
            })}
            onClear={this.onClearSearch}
            onSubmit={this.search.bind(this)}
            value={get(this.props.prizes["filter_" + filter], "search", "")}
          />
        </div>
      </div>
    );
  };

  render() {
    const { prizes, filter, user, users, dateFormatByServer } = this.props;
    let sort = get(
      this.props.prizes["filter_" + filter],
      "sort",
      "created_ts desc"
    );
    let pages = parseInt(get(this.props.prizes["filter_" + filter], "pages"));
    let page = parseInt(get(this.props.prizes["filter_" + filter], "page", 1));
    const isLoading = get(prizes, `filter_${filter}.loading`, false);
    const prizeList =
      get(prizes, `filter_${filter}.data`, []) !== null
        ? get(prizes, `filter_${filter}.data`, [])
        : [];
    if (prizeList.length === 0 && this.props.empty_component)
      return this.props.empty_component;
    return (
      <div className={classnames("fluid-container", bem.b())}>
        {this.renderHeader()}
        <div
          className={classnames(bem.e("process-list-container"), {
            [bem.e("no-padding")]: this.props.mode === MODE_IN_TAB,
          })}
        >
          {this.props.full_message && prizeList.length > 0 && (
            <CardBody>{this.props.full_message}</CardBody>
          )}
          {this.props.empty_message && prizeList.length === 0 && (
            <CardBody>{this.props.empty_message}</CardBody>
          )}
          <PrizesTable
            prizeList={prizeList}
            bem={bem}
            sort={sort}
            actionSort={this.sort}
            countdowns={this.props.countdowns}
            onClickRow={this.onClickRow}
            template={this.props.template}
            user={user}
            filter={filter}
            users={users}
            mode={this.props.mode}
            hasMoreItems={pages > page && !isLoading}
            handleLoadMore={this.handleLoadMore}
            isLoading={isLoading}
            dateFormatByServer={dateFormatByServer}
          />
        </div>
        <Spinner
          isLoading={!prizeList || get(prizes, `filter_${filter}.loading`)}
        />
      </div>
    );
  }
}
PrizeList.propTypes = {
  mode: PropTypes.oneOf([MODE_IN_TAB, MODE_IN_SIDEBAR]),
};
PrizeList.defaultProps = {
  mode: MODE_IN_SIDEBAR,
};
export default PrizeList;
