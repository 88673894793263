import Dashboard from "./view";
import {compose, withState} from 'recompose'
import { get } from 'lodash';
import { connect } from 'react-redux';

export default compose(
  connect((state, ownProps) => {
  	return {
  		serverInfo : get(state, "auth.info", {}),
  	};
  }),
  withState('isOpenProductionPromptModal', 'setIsOpenProductionPromptModal', false)
)(Dashboard);
