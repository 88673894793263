export const INIT_NEW_AUDIT = "INIT_NEW_AUDIT"
export const AUDIT_VALUE_CHANGED = "AUDIT_VALUE_CHANGED"
export const SAVE_FORM_VALUES_REQUEST = "SAVE_FORM_VALUES_REQUEST"
export const SAVE_FORM_VALUES_SUCCESS = "SAVE_FORM_VALUES_SUCCESS"
export const SAVE_FORM_VALUES_ERROR = "SAVE_FORM_VALUES_ERROR"

export const FETCH_AUDIT_REQUEST = "FETCH_AUDIT_REQUEST";
export const FETCH_AUDIT_SUCCESS = "FETCH_AUDIT_SUCCESS"
export const FETCH_AUDIT_ERROR = "FETCH_AUDIT_ERROR"

export const DELETE_AUDIT_REQUEST = "DELETE_AUDIT_REQUEST";
export const DELETE_AUDIT_SUCCESS = "DELETE_AUDIT_SUCCESS"
export const DELETE_AUDIT_ERROR = "DELETE_AUDIT_ERROR"

export const UPDATE_AUDIT_REQUEST = "UPDATE_AUDIT_REQUEST";
export const UPDATE_AUDIT_SUCCESS = "UPDATE_AUDIT_SUCCESS"
export const UPDATE_AUDIT_ERROR = "UPDATE_AUDIT_ERROR"

export const FETCH_AUDITS_REQUEST = "FETCH_AUDITS_REQUEST";
export const FETCH_AUDITS_SUCCESS = "FETCH_AUDITS_SUCCESS"
export const FETCH_AUDITS_ERROR = "FETCH_AUDITS_ERROR"

export const ADD_AUDIT_REQUEST = "ADD_AUDIT_REQUEST";
export const ADD_AUDIT_SUCCESS = "ADD_AUDIT_SUCCESS"
export const ADD_AUDIT_ERROR = "ADD_AUDIT_ERROR"

export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB"
export const SET_TAB_TO_EDIT = "SET_TAB_TO_EDIT"
export const RESET_TAB = "RESET_TAB";

export const FORGET_AUDITS = "FORGET_AUDITS"
export const SET_AUDITS_AUTO_REFRESH = "SET_AUDITS_AUTO_REFRESH"
