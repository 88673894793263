import React from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { first, get, pick, map, minBy, maxBy, truncate } from "lodash";
import { Label } from "reactstrap";
import arrayMove from "array-move";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import Tooltip from "rc-tooltip";
import Dropdown from "components/Dropdown";
import {
  Asterisk,
  ShadowBox,
  SectionTitle,
  SectionFields,
} from "components/Elements";
import { DownIcon, MenuIcon, UpIcon, DeleteIcon } from "components/CustomIcons";
import { fulfillmentList } from "utils/config";

const SortableItem = SortableElement(({ children }) => children);
const SortableList = SortableContainer(({ children }) => children);

const SelectedItem = (props) => {
  const {
    bem,
    item,
    onDelete,
    intl,
    playsNumber,
    onUpdate,
    isView,
    validationState,
    minPrizeItem,
    maxPrizeItem,
    isMinAndMaxSame,
  } = props;
  const errors = pick(validationState, [
    "total_allotment",
    "total_value",
    "per_play",
    "fulfillment",
    "selected_items",
  ]);
  const perOptions = [...Array(playsNumber || 1).keys()].map((item) => ({
    value: item + 1,
    label: item + 1,
  }));
  const fulfillment = fulfillmentList.find((i) => i.value === item.fulfillment);
  const maxDescriptionLength = 120;
  const originalDescription = get(item, "description", "") || "";
  const description = truncate(originalDescription, {
    length: maxDescriptionLength,
  });
  const isTruncated = originalDescription.length > maxDescriptionLength;

  let formattedTotalValue = "0";
  let formattedValue = "0";
  const perPlay = get(item, "per_play", 1);
  if (perPlay) {
    const minPrizeValue = get(minPrizeItem, "value", 0);
    const maxPrizeValue = get(maxPrizeItem, "value", 0);
    const minWithPerPlay = perPlay * parseInt(minPrizeValue);
    const maxWithPerPlay = perPlay * parseInt(maxPrizeValue);
    formattedTotalValue = isMinAndMaxSame
      ? `${minWithPerPlay}`
      : `${minWithPerPlay}-${maxWithPerPlay}`;
    formattedValue =
      minPrizeValue === maxPrizeValue
        ? `${minPrizeValue}`
        : `${minPrizeValue}-${maxPrizeValue}`;
  }
  return (
    <div className={bem.e("selected-prize-package")}>
      <div className={bem.e("selected-prize-padding")}>
        {!isView && (
          <span className={bem.e("trash")} onClick={() => onDelete(item)}>
            <DeleteIcon color="#795AFA" />
          </span>
        )}

        <h2 className={bem.e("selected-prize-title")}>{item.title}</h2>
        <span className={bem.e("selected-prize-subtitle")}>
          {item.subtitle}
        </span>
        <div className={bem.e("selected-prize-value")}>
          <FormattedMessage id="process > promotion > contest > item value" />$
          {formattedValue}
        </div>
        <div className={bem.e("selected-prize-description")}>
          {isTruncated ? (
            <Tooltip
              placement="top"
              trigger={["click", "hover"]}
              overlayClassName={bem.e("tooltip")}
              overlay={
                <div className={bem.e("tooltip-content")}>
                  {originalDescription}
                </div>
              }
            >
              <div className={bem.e("tooltip-content")}>{description}</div>
            </Tooltip>
          ) : (
            description
          )}
        </div>
        <div className={bem.e("selected-prize-row")}>
          {isView ? (
            <div className={bem.e("selected-prize-item")}>
              <Label>
                <FormattedMessage id="process > promotion > contest > per play" />
              </Label>
              <span className={"order-view-value"}>{item.per_play}</span>
            </div>
          ) : (
            <Dropdown
              name="per_play"
              label={
                <>
                  <FormattedMessage id="process > promotion > contest > per play" />
                  <Asterisk>*</Asterisk>
                </>
              }
              className={bem.e("per-play-dropdown")}
              onChange={(selectedOption) => {
                const perPlay = get(selectedOption, "value", 1);
                onUpdate({
                  ...item,
                  per_play: perPlay,
                  total_allotment: perPlay * playsNumber,
                  total_value: perPlay * item.unit_value,
                });
              }}
              value={perOptions.find((i) => i.value === item.per_play)}
              options={perOptions}
            />
          )}
          {isView ? (
            <div className={classnames(bem.e("selected-prize-item"), "px-2")}>
              <Label>
                <FormattedMessage id="process > promotion > contest > fulfillment" />
              </Label>
              <span className={"order-view-value"}>
                {get(fulfillment, "label", "")}
              </span>
            </div>
          ) : (
            <Dropdown
              name="fulfillment"
              label={
                <>
                  <FormattedMessage id="process > promotion > contest > fulfillment" />
                  {!isView && <Asterisk>*</Asterisk>}
                </>
              }
              className={bem.e("fulfillment-dropdown")}
              placeholder={intl.formatMessage({
                id: "process > promotion > contest > select",
              })}
              onChange={(selectedOption) => {
                const fulfillment = get(selectedOption, "value", "");
                onUpdate({
                  ...item,
                  fulfillment,
                });
              }}
              value={fulfillment}
              options={fulfillmentList}
            />
          )}

          <div className={bem.e("total-value")}>
            <Label>
              <FormattedMessage id="process > promotion > contest > total value" />
            </Label>
            <strong>${formattedTotalValue}</strong>
          </div>
        </div>
        {map(errors, (value, key) => (
          <span className="alert-danger" key={key}>
            {value}
          </span>
        ))}
      </div>
      <div className={bem.e("selected-prize-footer")}>
        <div>
          <FormattedMessage id="process > promotion > contest > plays" />:
          <strong>{playsNumber}</strong>
        </div>
        <div>
          <FormattedMessage id="process > promotion > contest > total allotment" />
          :<strong>{item.total_allotment}</strong>
        </div>
      </div>
    </div>
  );
};

const Five4321ThemePrizePackage = (props) => {
  const {
    validationState,
    process,
    bem,
    onValueChanged,
    intl,
    isView,
    isOpenDetails,
    setIsOpenDetails,
    sectionKey,
    renderPrizeCloset,
    fieldRefs,
    template,
  } = props;

  const { prizes } = fieldRefs;
  const theme = get(process, "data.fields.theme.value", "");
  const is54321Theme = theme === "5_4_3_2_1";
  const isPickYourPrizeTheme = theme === "pick_your_prize";
  const isRolloverTheme = theme === "rollover";
  const selectedPrizes = get(process, "data.fields.prizes.value", "");
  const playsNumber = get(process, "data.fields.plays_number.value")
    ? parseInt(get(process, "data.fields.plays_number.value"))
    : 0;
  const numberOfPlaysFor54321Theme = get(
    template,
    "numberOfPlaysFor54321Theme"
  );

  const defaultSectionOpen = isOpenDetails === sectionKey;
  const firstPrize = first(selectedPrizes);
  const selectedPrizeItems = Array.from({
    length: numberOfPlaysFor54321Theme,
  }).map((_, index) => {
    const currentItem = get(
      firstPrize,
      `selected_itemized_prices.${index}`,
      {}
    );
    return { order: index + 1, ...currentItem };
  });
  const minPrizeItem = minBy(selectedPrizeItems, "value");
  const maxPrizeItem = maxBy(selectedPrizeItems, "value");
  const isMinAndMaxSame =
    get(minPrizeItem, "value") === get(maxPrizeItem, "value");
  const mapItemizedPrices = (items) =>
    items.map((item, index) => ({
      ...item,
      order: index + 1,
    }));

  const onUpdateItemizedItems = (items) => {
    const newSelectedPrizes = [...selectedPrizes];
    newSelectedPrizes[0].selected_itemized_prices = mapItemizedPrices(items);
    onValueChanged(prizes, newSelectedPrizes);
  };
  const onDeleteItemizedPrize = (item) => {
    const newItems = selectedPrizes[0].selected_itemized_prices.filter(
      (i) => i.id !== item.id
    );
    onUpdateItemizedItems(newItems);
  };
  const onSortEnd = ({ oldIndex, newIndex }) => {
    onUpdateItemizedItems(
      arrayMove(selectedPrizes[0].selected_itemized_prices, oldIndex, newIndex)
    );
  };

  const shouldCancelStart = (e) => {
    var targetEle = e;
    if (!targetEle.id) {
      targetEle = e.target;
    }
    const actionIcon = targetEle.closest(".action-icon");
    if (actionIcon) {
      return true;
    }
  };
  return (
    <ShadowBox className={bem.e("box-prize-details")}>
      <SectionTitle
        className={bem.e("contest-prize-section-title")}
        onClick={() => setIsOpenDetails(defaultSectionOpen ? null : sectionKey)}
      >
        <div className={bem.e("box-head-title")}>
          <FormattedMessage id="process > promotion > contest > title prize package 54321" />
        </div>
        <div className={classnames("d-flex", bem.e("right-section"))}>
          <span className={bem.e("box-action")}>
            {defaultSectionOpen ? <UpIcon /> : <DownIcon />}
          </span>
        </div>
      </SectionTitle>
      {defaultSectionOpen && (
        <React.Fragment>
          <SectionFields
            className={classnames(
              bem.e("section-fields"),
              bem.e("contest-prize-fields")
            )}
          >
            <div className="row">
              <div className="col">
                {get(selectedPrizes, "length", 0) > 0 ? (
                  <div className={bem.e("prize-items")}>
                    {map(selectedPrizes, (item, index) => (
                      <SelectedItem
                        bem={bem}
                        item={item}
                        key={index}
                        isView={isView}
                        validationState={get(
                          validationState,
                          `prizes.validation_error.${index}`,
                          null
                        )}
                        onUpdate={(item) => {
                          onValueChanged(
                            prizes,
                            selectedPrizes.map((i) => {
                              if (i.key === item.key) {
                                return item;
                              }
                              return i;
                            })
                          );
                        }}
                        onDelete={(item) => {
                          onValueChanged(
                            prizes,
                            selectedPrizes.filter((i) => i.key !== item.key)
                          );
                          props.onRefreshPrizes();
                        }}
                        is54321Theme={is54321Theme}
                        isPickYourPrizeTheme={isPickYourPrizeTheme}
                        isRolloverTheme={isRolloverTheme}
                        intl={intl}
                        playsNumber={playsNumber}
                        minPrizeItem={minPrizeItem}
                        maxPrizeItem={maxPrizeItem}
                        isMinAndMaxSame={isMinAndMaxSame}
                      />
                    ))}
                  </div>
                ) : (
                  <div className={bem.e("selected-prize-item")}>
                    <div className={bem.e("prize-empty-text")}>
                      <FormattedMessage id="process > promotion > contest > title select itemized prize item" />
                    </div>
                  </div>
                )}
              </div>
              <div className="col d-flex">
                <div
                  className={classnames(
                    bem.e("only-number"),
                    "col d-flex flex-column"
                  )}
                >
                  {map(selectedPrizeItems, (_, index) => {
                    return (
                      <div
                        key={`item-number-${index}`}
                        className={classnames(bem.e("itemed_prize"))}
                      >
                        <div className={bem.e("itemed_prize_number")}>
                          {numberOfPlaysFor54321Theme - index}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <SortableList
                  onSortEnd={onSortEnd}
                  shouldCancelStart={shouldCancelStart}
                >
                  <div className="col d-flex flex-column">
                    {map(selectedPrizeItems, (item, index) => {
                      const canModify = !isView && item.description;
                      return (
                        <SortableItem
                          key={`item-${index}`}
                          index={index}
                          disabled={!canModify}
                        >
                          <div className={bem.e("itemed_prize")}>
                            {canModify && (
                              <span className="menu-selection-icon mr-3">
                                <MenuIcon />
                              </span>
                            )}
                            <div className={bem.e("itemed_prize_item")}>
                              {item.description}
                            </div>
                            {canModify && (
                              <span
                                className={classnames(
                                  bem.e("itemed_prize_item_delete"),
                                  "action-icon"
                                )}
                                onClick={() => onDeleteItemizedPrize(item)}
                              >
                                <DeleteIcon color="#795AFA" />
                              </span>
                            )}
                          </div>
                        </SortableItem>
                      );
                    })}
                  </div>
                </SortableList>
              </div>
            </div>
          </SectionFields>
          {renderPrizeCloset()}
        </React.Fragment>
      )}
    </ShadowBox>
  );
};

export default Five4321ThemePrizePackage;
