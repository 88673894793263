import { connect } from "react-redux";
import { get } from "lodash";
import FormattedTaggedMessage, { FormattedTaggedLog } from "./view";
export { FormattedTaggedLog };

export default connect(
  (state, props) => ({
    users: get(state, "auth.info.users", []).filter(
      (user) => !!user.name.trim()
    ),
    ...props,
  }),
  {}
)(FormattedTaggedMessage);
