import { injectIntl } from "react-intl";
import { get } from "lodash";
import { connect } from "react-redux";
import ContentAssignUsers from "./view";

export default injectIntl(
  connect((state, props) => {
    return {
      ...props,
      assignedHubs: get(state, "hubs.assignedHubs.data.data", []),
      roles: get(state, "roles.rolesProductionTeam.data", []),
    };
  }, {})(ContentAssignUsers)
);
