import React, { useRef, forwardRef } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Form,
  Col,
  Row,
  FormGroup,
} from "reactstrap";
import { Asterisk } from "components/Elements";
import TextInput from "components/TextInput";
import bn from "utils/bemnames";
import { Formik } from "formik";
import moment from "moment";
import * as Yup from "yup";
import DatePicker from "components/DatePicker";
import Spinner from "components/Spinner";
import { FormattedMessage } from "react-intl";
import {
  get,
  find,
  difference,
  includes,
  isEmpty,
  forEach,
  intersection,
  isEqual,
  map,
} from "lodash";
import classnames from "classnames";
import Dropdown from "components/Dropdown";
import DaysOfWeek from "components/DaysOfWeek";
import ToastManager from "components/ToastManager";
import Badge from "components/Badge";

const bem = bn.create("add-item-modal");
function AddItemModal(props) {
  const { intl, onFormSubmit, slotDefinitions } = props;
  const slotDefinitionsOptions = slotDefinitions.map((slotDefinition) => ({
    label: `${slotDefinition.type} - ${slotDefinition.name}`,
    value: slotDefinition.id,
  }));
  const initialValues = {
    order_title: "",
    type_id: "",
    order_type: "",
    order_start_date: "",
    order_end_date: "",
    days_of_week: [2, 3, 4, 5, 6],
    excluded_dates: [],
  };
  const formikRef = useRef();
  const ExcludeDatesButton = forwardRef(({ value, onClick }, ref) => (
    <div className="exclude-dates-btn" onClick={onClick} ref={ref}>
      <FormattedMessage id="process > promotion > liner > exclude dates" />
    </div>
  ));

  return (
    <Modal isOpen={props.isOpen} className={bem.b()}>
      <ModalHeader>
        <FormattedMessage id="process > promotion > contest > add a new item" />
      </ModalHeader>
      <ModalBody className={bem.e("body")}>
        <Formik
          ref={formikRef}
          enableReinitialize
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            order_title: Yup.string().required(
              intl.formatMessage({ id: "validate > title is required" })
            ),
            type_id: Yup.string().required(
              intl.formatMessage({ id: "validate > type is required" })
            ),
            order_type: Yup.string().required(
              intl.formatMessage({ id: "validate > order type is required" })
            ),
            order_start_date: Yup.date().required(
              intl.formatMessage({ id: "validate > start date is required" })
            ),
            order_end_date: Yup.date()
              .when(
                "order_start_date",
                (startDate, schema) =>
                  startDate &&
                  schema.min(
                    startDate,
                    intl.formatMessage({
                      id: `validate > end date should be later than start date`,
                    })
                  )
              )
              .required(
                intl.formatMessage({ id: "validate > end date is required" })
              ),
            days_of_week: Yup.array().required(
              intl.formatMessage({
                id: "validate > days of week is required",
              })
            ),
          })}
          onSubmit={onFormSubmit}
        >
          {(formProps) => {
            const errors = formProps.errors;
            const touched = formProps.touched;
            const values = formProps.values;
            const isSelectedDay = (date) => {
              let selectedDays = values.days_of_week;
              const day = moment(date).day() + 1;
              return includes(selectedDays, day);
            };
            const renderExcludedDates = () => {
              const excludedDates = values.excluded_dates || [];
              return map(excludedDates, (date) => {
                return (
                  <Badge
                    key={date}
                    text={moment(date).format("MMM D")}
                    onClick={() => {
                      formProps.setFieldValue(
                        "excluded_dates",
                        difference(excludedDates, [date])
                      );
                    }}
                  />
                );
              });
            };
            const getAvailableDates = () => {
              if (!values.order_start_date || !values.order_end_date) {
                return [];
              }

              const startDate = moment(values.order_start_date);
              const endDate = moment(values.order_end_date);

              let dates = [];
              let currentDate = startDate.subtract(1, "days");
              let addDays = function(days) {
                let date = new Date(this.valueOf());
                date.setDate(date.getDate() + days);
                return date;
              };
              while (currentDate <= endDate) {
                if (isSelectedDay(currentDate)) {
                  dates.push(currentDate);
                }

                currentDate = addDays.call(currentDate, 1);
              }

              return dates;
            };
            const getSelectedDates = () => {
              let selectedDates = [];

              let excludedDates = values.excluded_dates;

              excludedDates = map(excludedDates, (date) =>
                moment(date).format("YYYY-MM-DD")
              );

              let availableDates = getAvailableDates();

              forEach(availableDates, (date) => {
                if (
                  !includes(excludedDates, moment(date).format("YYYY-MM-DD"))
                ) {
                  selectedDates.push(date);
                }
              });

              const _excludedDates = intersection(
                excludedDates,
                availableDates.map((date) => moment(date).format("YYYY-MM-DD"))
              );
              if (!isEqual(excludedDates, _excludedDates)) {
                formProps.setFieldValue("excluded_dates", _excludedDates);
              }
              return selectedDates;
            };
            const selectedDates = getSelectedDates();
            return (
              <Form onSubmit={formProps.handleSubmit}>
                <div className={bem.e("form-content")}>
                  <Row>
                    <Col xs={12} className={bem.e("reset-col")}>
                      <FormGroup>
                        <TextInput
                          label={
                            <span>
                              <FormattedMessage id="process > promotion > contest > title" />
                              <Asterisk>*</Asterisk>
                            </span>
                          }
                          type="text"
                          name="order_title"
                          placeholder={intl.formatMessage({
                            id: "process > promotion > contest > enter title",
                          })}
                          value={values.order_title}
                          error={touched.order_title && errors.order_title}
                          onChange={formProps.handleChange}
                          onBlur={formProps.handleBlur}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={4} className={bem.e("reset-col")}>
                      <FormGroup>
                        <TextInput
                          label={
                            <span>
                              <FormattedMessage id="process > promotion > contest > start date" />
                              <Asterisk>*</Asterisk>
                            </span>
                          }
                          type="date"
                          min={moment().format("YYYY-MM-DD")}
                          placeholder={intl.formatMessage({
                            id: "process > promotion > contest > enter date",
                          })}
                          value={values.order_start_date}
                          onChange={(value) => {
                            formProps.setFieldValue("order_start_date", value);
                          }}
                          error={
                            touched.order_start_date && errors.order_start_date
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={4} className={bem.e("reset-col")}>
                      <FormGroup>
                        <TextInput
                          label={
                            <span>
                              <FormattedMessage id="process > promotion > contest > end date" />
                              <Asterisk>*</Asterisk>
                            </span>
                          }
                          placeholder={intl.formatMessage({
                            id: "process > promotion > contest > enter date",
                          })}
                          type="date"
                          min={moment().format("YYYY-MM-DD")}
                          value={values.order_end_date}
                          onChange={(value) => {
                            formProps.setFieldValue("order_end_date", value);
                          }}
                          error={
                            touched.order_end_date && errors.order_end_date
                          }
                        />
                        {values.order_end_date && values.order_start_date ? (
                          <div className="cr-textinput exclude-date-picker">
                            <DatePicker
                              field={"order_start_date"}
                              onChange={(date) => {
                                let excludedDates = values.excluded_dates;

                                if (!includes(excludedDates, date)) {
                                  excludedDates.push(date);
                                } else {
                                  excludedDates = difference(excludedDates, [
                                    date,
                                  ]);
                                }
                                formProps.setFieldValue(
                                  "excluded_dates",
                                  excludedDates
                                );
                              }}
                              name="selected_dates"
                              minDate={moment(values.order_start_date).toDate()}
                              maxDate={moment(values.order_end_date).toDate()}
                              filterDate={isSelectedDay}
                              highlightDates={selectedDates}
                              selected={null}
                              customInput={<ExcludeDatesButton />}
                              shouldCloseOnSelect={false}
                            />
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col xs={4} className={bem.e("reset-col")}>
                      <FormGroup>
                        <DaysOfWeek
                          value={values.days_of_week}
                          label={
                            <span>
                              <FormattedMessage id="process > promotion > contest > days of the week" />
                              <Asterisk>*</Asterisk>
                            </span>
                          }
                          error={
                            get(touched, "days_of_week") &&
                            get(errors, "days_of_week")
                          }
                          onChange={(days) => {
                            formProps.setFieldValue("days_of_week", days);
                          }}
                          checkStyle={{
                            color: "#fff",
                            width: "11",
                            height: "11",
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {!isEmpty(values.excluded_dates) && (
                    <Row className={bem.e("badges")}>
                      <Col className={bem.e("reset-col")}>
                        <FormGroup>
                          <div className={bem.e("excluded-days-text")}>
                            <FormattedMessage id="process > promotion > contest > excluded dates" />
                          </div>
                          <div className={bem.e("badges-container")}>
                            {renderExcludedDates()}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col xs={12}>
                      <FormGroup>
                        <Dropdown
                          label={
                            <span>
                              <FormattedMessage id="process > promotion > contest > type" />
                              <Asterisk>*</Asterisk>
                            </span>
                          }
                          name="type_id"
                          placeholder={intl.formatMessage({
                            id: "process > promotion > contest > select",
                          })}
                          onChange={(selectedOption) => {
                            formProps.setFieldValue(
                              "order_type",
                              selectedOption.label
                            );
                            formProps.setFieldValue(
                              "type_id",
                              selectedOption.value
                            );
                          }}
                          value={
                            find(
                              slotDefinitionsOptions,
                              (item) => item.value === values.type_id
                            ) || ""
                          }
                          options={slotDefinitionsOptions}
                          error={
                            get(touched, "type_id") && get(errors, "type_id")
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className={bem.e("buttons")}>
                  <Button
                    color="blue"
                    type="submit"
                    className={classnames("btn-radius", bem.e("button-submit"))}
                    onClick={() => {
                      setTimeout(() => {
                        const formErrors = get(
                          formikRef,
                          "current.state.errors"
                        );
                        if (formErrors && Object.keys(formErrors).length) {
                          ToastManager.show({
                            title: props.intl.formatMessage({
                              id: "toast > title not saved",
                            }),
                            message: props.intl.formatMessage({
                              id:
                                "toast > message error please correct the hilighted fields",
                            }),
                            level: "error",
                          });
                        }
                      }, 20);
                    }}
                  >
                    <FormattedMessage id={"process > button save draft"} />
                  </Button>
                  {` `}
                  <Button
                    type="button"
                    outline
                    color="blue"
                    className={classnames("btn-radius", bem.e("button-cancel"))}
                    onClick={() => {
                      formProps.resetForm({});
                      props.onToggle();
                    }}
                  >
                    <FormattedMessage id="process > button cancel" />
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </ModalBody>
      <Spinner isLoading={props.isLoading} />
    </Modal>
  );
}

AddItemModal.defaultProps = {
  isOpen: false,
  onSubmit: () => {},
};

export default AddItemModal;
