import Calendar from './view';
import { injectIntl } from 'react-intl';
import { compose, withState } from "recompose";


export default injectIntl(compose(
    withState("isDefineHourModalOpen", "setIsDefineHourModalOpen", false),
    withState("selectedBlock", "setSelectedBlock", null),
    withState("selectedSlotTypes", "setSelectedSlotTypes", []),
    withState("selectedTimeSlots", "setSelectedTimeSlots", []),
    withState("collapsedHours", "setCollapsedHours", []),
  )(Calendar)
);