import React from "react";
import bn from "utils/bemnames";
import { Button } from "reactstrap";
import { FaPlus } from "react-icons/lib/fa";
import { FormattedMessage } from "react-intl";
import Spinner from "components/Spinner";
import ChannelPreviewModal from "components/ChannelPreviewModal";
import ConfirmationModal from "components/ConfirmationModal";
import SortableContainer from "./SortableContainer";
import { get } from "lodash";
import classNames from "classnames";
import BlockingComponent from "components/BlockingComponent";
const bem = bn.create("channel-manager");
const ChannelManager = ({
  channels,
  loading,
  onDeleteChannel,
  setIsOpenConfirmationModal,
  isOpenConfirmationModal,
  setCurrentChannelDelete,
  currentOpenForm,
  setCurrentOpenForm,
  onCloneChannel,
  onHandledChannelForm,
  isViewModeForms,
  setIsViewModeForms,
  setIsOpenConfirmationUnsavedModal,
  onSaveHiddenChannel,
  isOpenChannelPreviewModal,
  setIsOpenChannelPreviewModal,
  setCurrentPreviewChannel,
  currentPreviewChannel,
  addChannelLocal,
  workflows,
  roles,
  onUpdateOrderChannels,
  currentStation,
  shouldBlockNavigation,
  setShouldBlockNavigation,
  isOpenConfirmationActionsUnsavedModalNewChannel,
  setIsOpenConfirmationActionsUnsavedModalNewChannel,
  intl,
  deleteChannelLocal,
  currentPublishChannel,
  setCurrentPublishChannel,
  onGetChannels,
  setOrderChannels,
}) => {
  const hasUnsavedNew =
    channels.filter((c) => get(c, "_source.id", "").indexOf("_new_") === 0)
      .length > 0;
  const newChannel = channels.find(
    (c) => get(c, "_source.id", "").indexOf("_new_") === 0
  );
  const onScrollToBottom = () => {
    setTimeout(() => {
      const eleItems = document.getElementById("cr-app-main");
      eleItems.scrollTop = eleItems.scrollHeight;
    }, 200);
  };
  const children = (
    <div
      className={classNames(bem.b(), {
        [bem.m("channel-new")]: hasUnsavedNew,
      })}
      id="channels"
    >
      {!hasUnsavedNew && (
        <Button
          className={bem.e("button-create")}
          color="blue"
          onClick={() => {
            let new_id = "_new_" + new Date();
            addChannelLocal({
              name: "New Marketing Channel",
              id: new_id,
            });
            setCurrentOpenForm(new_id);
            onScrollToBottom();
            setShouldBlockNavigation(true);
          }}
        >
          <FaPlus className={bem.e("icon-add")} />
          <FormattedMessage id="channel manager > create new channel" />
        </Button>
      )}
      <SortableContainer
        channels={channels}
        workflows={workflows}
        roles={roles}
        bem={bem}
        currentOpenForm={currentOpenForm}
        isViewModeForms={isViewModeForms}
        setIsViewModeForms={setIsViewModeForms}
        setCurrentOpenForm={setCurrentOpenForm}
        setIsOpenConfirmationModal={setIsOpenConfirmationModal}
        onCloneChannel={onCloneChannel}
        setCurrentChannelDelete={setCurrentChannelDelete}
        onHandledChannelForm={onHandledChannelForm}
        onSaveHiddenChannel={onSaveHiddenChannel}
        setIsOpenConfirmationUnsavedModal={setIsOpenConfirmationUnsavedModal}
        setCurrentPreviewChannel={setCurrentPreviewChannel}
        isOpenChannelPreviewModal={isOpenChannelPreviewModal}
        setIsOpenChannelPreviewModal={setIsOpenChannelPreviewModal}
        onUpdateOrderChannels={onUpdateOrderChannels}
        currentStation={currentStation}
        setShouldBlockNavigation={setShouldBlockNavigation}
        shouldBlockNavigation={shouldBlockNavigation}
        setIsOpenConfirmationActionsUnsavedModalNewChannel={
          setIsOpenConfirmationActionsUnsavedModalNewChannel
        }
        setCurrentPublishChannel={setCurrentPublishChannel}
        hasUnsavedNew={hasUnsavedNew}
        onGetChannels={onGetChannels}
        setOrderChannels={setOrderChannels}
      />
      <Spinner isLoading={loading} />
      {/* confirmation delete modal */}
      <ConfirmationModal
        isOpen={isOpenConfirmationModal}
        className={bem.e("confirmation-modal")}
        title={
          <FormattedMessage id="channel manager > Are you sure you want to delete?" />
        }
        cancelTitle={<FormattedMessage id="channel manager > button cancel" />}
        confirmTitle={
          <FormattedMessage id="channel manager > button yes delete" />
        }
        isCloseOutside={false}
        onCancel={() => {
          setIsOpenConfirmationModal(false);
          setCurrentChannelDelete(null);
        }}
        onConfirm={onDeleteChannel}
        onToggle={() => setIsOpenConfirmationModal(!isOpenConfirmationModal)}
      />
      <ChannelPreviewModal
        channel={currentPreviewChannel}
        isOpen={isOpenChannelPreviewModal}
        onToggle={() =>
          setIsOpenChannelPreviewModal(!isOpenChannelPreviewModal)
        }
      />
      {/* actions confirmation unsaved new channel*/}
      <ConfirmationModal
        isOpen={isOpenConfirmationActionsUnsavedModalNewChannel}
        title={intl.formatMessage({
          id: "channel manager > wait, you have unsaved changes in this form",
        })}
        isCloseOutside={false}
        className={bem.e("confirmation-modal")}
        description={intl.formatMessage({
          id:
            "channel manager > before you can perform this step, you must save or discard your changes",
        })}
        confirmTitle={intl.formatMessage({
          id: "channel manager > button save",
        })}
        discardTitle={intl.formatMessage({
          id: "channel manager > button discard",
        })}
        cancelTitle={intl.formatMessage({
          id: "channel manager > button cancel",
        })}
        onConfirm={() => {
          const buttonSubmit = document.getElementById("btn-submit-add");
          // trigger click to submit the form
          if (buttonSubmit) buttonSubmit.click();
          setIsOpenConfirmationActionsUnsavedModalNewChannel(false);
        }}
        onToggle={() =>
          setIsOpenConfirmationActionsUnsavedModalNewChannel(
            !isOpenConfirmationActionsUnsavedModalNewChannel
          )
        }
        onDiscard={() => {
          if (newChannel) {
            deleteChannelLocal(newChannel._id);
          }
          const eleItems = document.getElementById("cr-app-main");
          if (eleItems) eleItems.scrollTop = 0;
          setShouldBlockNavigation(false);
          setIsOpenConfirmationActionsUnsavedModalNewChannel(false);
        }}
        onCancel={() =>
          setIsOpenConfirmationActionsUnsavedModalNewChannel(false)
        }
      />
      {/* Publish confirmation modal */}
      <ConfirmationModal
        isOpen={!!currentPublishChannel}
        title={intl.formatMessage({
          id: "channel manager > would you like to publish this Channel",
        })}
        isCloseOutside={false}
        className={bem.e("confirmation-modal")}
        description={intl.formatMessage({
          id:
            "channel manager > a channel will not appear in the Digital form until it is published",
        })}
        confirmTitle={intl.formatMessage({
          id: "channel manager > button yes",
        })}
        cancelTitle={intl.formatMessage({
          id: "channel manager > button no",
        })}
        onConfirm={() => {
          onSaveHiddenChannel(currentPublishChannel, true);
          setCurrentPublishChannel(null);
        }}
        onToggle={() => setCurrentPublishChannel(null)}
        onCancel={() => {
          setCurrentPublishChannel(null);
        }}
      />
    </div>
  );
  return (
    <div>
      {children}
      <BlockingComponent
        isBlocked={shouldBlockNavigation}
        modalProps={{
          title: intl.formatMessage({
            id: "reminder modal > wait, you have unsaved changes in this form",
          }),
          subtitle: intl.formatMessage({
            id:
              "reminder modal > before you can perform this step, you must save or discard your changes",
          }),
          confirmTitle: intl.formatMessage({
            id: "reminder modal > button save",
          }),
          cancelTitle: intl.formatMessage({
            id: "reminder modal > button cancel",
          }),
          discardTitle: intl.formatMessage({
            id: "reminder modal > button discard",
          }),
          isShowDiscard: true,
          onDiscard: () => {
            onGetChannels();
          },
          onConfirm: () => {
            const buttonSubmit = document.getElementById(
              `btn-submit-${currentOpenForm}`
            );
            // trigger click to submit the form
            if (buttonSubmit) buttonSubmit.click();
          },
        }}
      />
    </div>
  );
};
export default ChannelManager;
