import React from "react";
import Tooltip from "rc-tooltip";
import { SaveIcon, CloseSmallIcon } from "components/CustomIcons";
import { injectIntl } from "react-intl";
import ToastManager from "components/ToastManager";
import ConfirmationModal from "components/ConfirmationModal";
import * as Yup from "yup";
import { last } from "lodash";
import { styles } from "./index";
import ContentEditable from "react-contenteditable";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const EditHyperlink = ({
  intl,
  onSave,
  draftProps,
  setCurrentEntityKey,
  setIsEditorReadonly,
}) => {
  const { url } = draftProps.contentState
    .getEntity(draftProps.entityKey)
    .getData();
  const urlValue =
    url.indexOf("mailto") !== -1
      ? last(url.split("mailto:"))
      : url.indexOf("tel") !== -1
      ? last(url.split("tel:"))
      : url;
  const [isOpen, setIsOpen] = React.useState(false);
  const [value, setValue] = React.useState(urlValue);
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = React.useState(
    false
  );
  const [type, setType] = React.useState(null);
  return (
    <React.Fragment>
      <Tooltip
        trigger={["click"]}
        placement="top"
        visible={isOpen}
        onVisibleChange={(visible) => {
          setIsOpen(visible);
        }}
        afterVisibleChange={(visible) => {
          // set enable or disable editor. If user is editing the hyperlink -> we need to disable the editor
          setIsEditorReadonly(visible);
        }}
        overlayClassName={"RichEditor-edit-hyperlink"}
        overlay={
          <div className={"edit-hyperlink-form component-form"}>
            <span
              className={"edit-hyperlink-close"}
              onClick={() => {
                setIsOpen(false);
                setValue(urlValue);
              }}
            >
              <CloseSmallIcon />
            </span>
            <ContentEditable
              className="content-editable"
              html={value}
              onChange={(event) => {
                setValue(event.target.value);
              }}
              placeholder={
                value
                  ? ""
                  : intl.formatMessage({
                      id: "textarea evaluator > placeholder enter url",
                    })
              }
            />
            <span
              className="upload-save"
              onClick={async () => {
                let isValid = !!value;
                const isUrl = await Yup.string()
                  .url()
                  .validate(value)
                  .catch(() => {
                    isValid = false;
                  });
                const isEmail = await Yup.string()
                  .email()
                  .validate(value)
                  .catch(() => {
                    if (isValid) isValid = false;
                  });
                const isPhone = await Yup.string()
                  .matches(phoneRegExp)
                  .validate(value)
                  .catch(() => {
                    if (isValid) isValid = false;
                  });
                if (isUrl) {
                  isValid = true;
                  setType("url");
                }
                if (isEmail) {
                  isValid = true;
                  setType("email");
                }
                if (isPhone) {
                  isValid = true;
                  setType("phone");
                }
                if (!isValid) {
                  ToastManager.show({
                    message: intl.formatMessage({
                      id: "textarea evaluator > error save hyperlink",
                    }),
                    level: "error",
                  });
                } else {
                  if (isUrl) {
                    setIsOpen(false);
                    onSave(value);
                  } else {
                    setIsOpenConfirmationModal(true);
                  }
                }
              }}
            >
              <SaveIcon />
            </span>
          </div>
        }
        // defaultVisible
      >
        <a
          href={url}
          style={styles.link}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setCurrentEntityKey(draftProps.entityKey);
          }}
        >
          {draftProps.children}
        </a>
      </Tooltip>
      <ConfirmationModal
        isOpen={isOpenConfirmationModal}
        title={intl.formatMessage(
          {
            id:
              "textarea evaluator > the URL you entered seems to be an {type}. Do you want to add the required {prefix}: prefix?",
          },
          {
            type,
            prefix: type === "email" ? "mailto" : type === "phone" ? "tel" : "",
          }
        )}
        isCloseOutside={false}
        onConfirm={() => {
          setIsOpenConfirmationModal(false);
          const prefix =
            type === "email" ? "mailto" : type === "phone" ? "tel" : "";
          onSave(`${prefix}:${value}`);
        }}
        onToggle={() => setIsOpenConfirmationModal(!isOpenConfirmationModal)}
        onCancel={() => {
          setIsOpenConfirmationModal(false);
          onSave(value);
        }}
      />
    </React.Fragment>
  );
};
const Enhanced = injectIntl(EditHyperlink);
export default React.memo(Enhanced, (props, nextProps) => {
  const dataEntityProps = props.draftProps.contentState
    .getEntity(props.draftProps.entityKey)
    .getData();
  const dataEntityNextProps = nextProps.draftProps.contentState
    .getEntity(nextProps.draftProps.entityKey)
    .getData();
  if (dataEntityProps.url === dataEntityNextProps.url) {
    // don't re-render/update
    return true;
  }
});
