import React from "react";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import { FaSortDesc, FaSortAsc } from "react-icons/lib/fa";
import { Table } from "reactstrap";
import NoItemsFound from "components/NoItemsFound";
import { MODE_IN_TAB } from "../view";
import Avatar from "../../Avatar";
import moment from "moment";
import history from "components/History";
import { isArray, isObject } from "lodash";
import { FilterIcon } from "components/CustomIcons";



const View = ({
  actionSort,
  sort,
  auditList,
  bem,
  onClickRow,
  mode,
  isLoading,
  filter,
  dateFormatByServer
}) => {
  const userDisplay = audit => {
    var user = audit.user_info || {};
    var impersonator = audit.impersonated_by;
    return (
      <table className="plain">
          <tbody >
            <tr>
              <td style={{ borderTop: 'none', height: '40px', padding: '0 10px' }}>
              <Avatar 
                src={user.avatar_url} 
                name={user.name} 
                size={40} 
                className="avatar-small" 
                style={{marginRight:'10px'}}/>
              {impersonator && <Avatar 
                src={impersonator.avatar_url} 
                name={impersonator.name} 
                size={40} 
                className="avatar-small avatar-overlay" 
                />}
              {` `}
            </td>
            <td style={{ borderTop: 'none' ,padding: 0, height: '40px'}}>
              <span>{user.name ? user.name : user.email}</span>
              <br/>
              <code style={{color:'#777', fontSize: '10px', whiteSpace: 'nowrap'}}>{user.email}</code>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  const auditAction = (audit) => {
    var zoomButton = <span className="zoominlink"
    style={{ right: '10px' }}
      onClick={ e =>
      {
        history.push('/audit/item_'+audit._source.item_key)
        e.stopPropagation();
        return false;
      }}
    ><FilterIcon color="#525252" /></span>
    var path = audit._source.path;
    if(audit._source.form_values && audit._source.form_values.data && audit._source.form_values.data.template_key) 
    path = <a href={'/processes/view/'+audit._source.form_values.data.template_key+'/'+path.split('/')[1]} onClick={e=>{e.stopPropagation();
      return false;}}>{path}</a>
    var fields = !audit._source.delta ? [] : {...audit._source.delta};
    if(audit._source.delta){
      Object.keys(audit._source.delta).map(k=>{
        var d=audit._source.delta[k];
        if(isArray(d)){
          d.map((s,i)=>{
            var delimiter = '(' + i + '), ';
            if(d.length<2) delimiter =', ';
            Object.keys(s).map(n=>fields[k + delimiter + n] = s[n])
            
          });
        }
        if(isObject(d)){
          Object.keys(d).map(dn=>{
            if(isArray(audit._source.delta[dn])){
              audit._source.delta[dn].map((s,i)=>{
                var delimiter = '(' + i + '), ';
                if(d.length<2) delimiter =', ';
                Object.keys(s).map(n=>fields[dn + delimiter + n] = s[n])
              });
            }
          });
        }
      });
      Object.keys(fields).map(k=>{
        var d=fields[k];
        if(isArray(d) || isObject(d) || (!k.endsWith('.new') && !k.endsWith('.old'))){
          delete fields[k];
        }
      });
    }
    var action = <span>

    {(audit._source.item_key && audit._source.item_key !== filter.replace('item_','').replace('user_','')) && zoomButton}
    {audit._source.description 
    ? audit._source.description.replace(new RegExp('_', 'gi'),' ')
    : audit._source.path.replace(new RegExp('/', 'gi'),' ')}
    
    {(audit._source.level !== 'audit') && <span> {audit._source.level}</span>}

    <span>
    <br/>
    <code style={{color:'#777', fontSize: '10px'}}>{audit._source.action}, {audit._source.method}, {path}</code>
    {audit._source.delta && <div>
      {Object.keys(fields).map((key, ind) => {
        if(ind>6) return null;
        return key.indexOf('.new')>-1 ? <div key={ind} style={{ fontSize: '12px'}}>
          <b>{key.replace('.new','').replace(/_/gi,' ')}</b>
          <br/> 
          <span style={{ color: 'gray' }}>
            <div dangerouslySetInnerHTML={{ __html: (''+fields[key.replace('.new','.old')]).replace('<p>','').replace('</p>','') + ` ➜ ` + (''+fields[key]).replace('<p>','').replace('</p>','') }} />
            </span>
        </div> 
        : null
        }
      )}
      {Object.keys(fields).length > 6 && <div>...{ (Object.keys(fields).length - 6)/2 } more</div>}
    </div>}
    </span>
    </span>
    return action;
  }



  return (
    <div
      className={classnames(bem.e("table-wrapper"), {
        [bem.e("table-wrapper-mode-in-tab")]: mode === MODE_IN_TAB
      })}
    >
      <Table className={bem.e("table")}>
        <thead>
          <tr>
            <th onClick={() => actionSort("broadcasting_group")} className={classnames("can-click", bem.e('table-first-head'))}>
              <div className={bem.e("item-sortable")}>
                <FormattedMessage id="audits > group" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "broadcasting_group"
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "broadcasting_group desc"
                    })}
                  />
                </div>
              </div>
            </th>

            <th onClick={() => actionSort("user_info.name")} className="can-click">
              <div className={bem.e("item-sortable")}>
                <FormattedMessage id="audits > user" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "user_info.name"
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "user_info.name desc"
                    })}
                  />
                </div>
              </div>
            </th>
            <th onClick={() => actionSort("created_at")} className="can-click">
              <div className={bem.e("item-sortable")}>
                <FormattedMessage id="audits > created at" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "created_at"
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "created_at desc"
                    })}
                  />
                </div>
              </div>
            </th>
            <th onClick={() => actionSort("end_point")} className="can-click">
              <div className={bem.e("item-sortable")}>
                <FormattedMessage id="audits > section" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "end_point"
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "end_point desc"
                    })}
                  />
                </div>
              </div>
            </th>
            <th onClick={() => actionSort("path")} className="can-click">
              <div className={bem.e("item-sortable")}>
                <FormattedMessage id="audits > action" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "path"
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "path desc"
                    })}
                  />
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {auditList.length === 0 && !isLoading ? (
            <tr>
              <td colSpan={8}>
                <NoItemsFound />
              </td>
            </tr>
          ) : (
            auditList.map(audit => {
              var user = audit._source.user_info || {};
              return (
                <tr key={audit._id} onClick={() => onClickRow(audit)}
                      style={{"background": audit._source.level === 'failure' ? "#fff1d9" : audit._source.level === 'error' ? "#ffeded" : ""}}
                >
                  {/* broadcasting_group */}
                  <td className={classnames("align-middle", bem.e("first-col"))}>
                    <span style={{position: '', marginTop: '0px',color: 'green',fontWeight: 'bold'}}>
                      {audit._source.level === 'audit' && <span>ⓘ </span>}
                      {audit._source.level === 'failure' && <span style={{color:'orange'}}>⚠ </span>}
                      {audit._source.level === 'error' && <span style={{color:'red'}}>⚠ </span>}
                    </span><span>
                    
                    {audit._source.broadcasting_group}
                    {audit._source.stations && audit._source.stations.map((station, ind) => 
                    { 
                      return (ind === 3 && audit._source.stations.length > 4) ?
                        <span key={ind}>... {audit._source.stations.length - ind} more</span> 
                        :
                        (ind > 3 && !(ind === 3 && audit._source.stations.length <= 4)) ? null
                        :
                        <span key={ind}
                        style={{ position: 'relative', display: 'block' }}
                        >
                        <code className="stationcode"
                        onClick={ e =>
                          {
                            history.push('/audit/station_'+station)
                            e.stopPropagation();
                            return false;
                          }}>
                          <span className="zoominlinkstation" style={{marginLeft:'20px'}}><FilterIcon color="#525252" /></span>{station}</code></span>
                    })}
                    </span>
                  </td>
                  {/* user */}
                  <td
                    className={classnames("align-middle", bem.e("client-name"))}
                    style={{position: 'relative'}}
                    
                  >
                    {user.id && 
                    <span className="zoominlink"
                    style={{ left: '0px' }}
                      onClick={ e =>
                        {
                          history.push('/audit/user_'+user.id)
                          e.stopPropagation();
                          return false;
                        }}
                    ><FilterIcon color="#525252" /></span>}
                    {userDisplay(audit._source)}
                  </td>
                  {/* created_at */}
                  <td
                    className={classnames("align-middle", bem.e("client-name"))}
                    style={{whiteSpace:'nowrap'}}
                  >
                    {moment.unix(audit._source.created_at).fromNow()}<br/>
                    <code style={{color:'#777', fontSize: '10px'}}>
                    {moment.unix(audit._source.created_at).format(dateFormatByServer).toString()} - {moment.unix(audit._source.created_at).format("h:mm A").toString()}<br/>
                    </code>
                    
                    
                  </td>
                  {/* section */}
                  <td
                    className={classnames("align-middle", bem.e("client-name"))}
                    style={{position: 'relative', textTransform: 'capitalize'}}
                  >
                    <span className="zoominlink"
                    style={{ left: '-18px', zIndex: 98 }}
                      onClick={ e =>
                        {
                          history.push('/audit/section_' + audit._source.end_point)
                          e.stopPropagation();
                          return false;
                        }}
                    ><FilterIcon color="#525252" /></span>
                    {audit._source.end_point}
                  </td>
                  {/* summery */}
                  <td
                    className={classnames("align-middle", bem.e("order-title"))}
                    style={{position:'relative'}}
                  >
                    {auditAction(audit)}
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </Table>
    </div>
  );
};
export default View;
