import * as Types from "store/types/servers";
import { map, get } from "lodash";
import Api from "utils/api";
import * as Const from "utils/constants";
import queryString from "query-string";
import { isFunction } from "lodash";
import { userInfo } from "store/actions/auth";

export const getServers = (state, updateServer) => async dispatch => {
  let errorStr = "";
  try {
    dispatch({ type: Types.FETCH_SERVERS_REQUEST });
    let params = {};
    if (state) {
      const { pageSize, page, sorted, filtered } = state;
      params = {
        page: page + 1,
        rpp: pageSize
      };
      if (sorted && sorted.length > 0) {
        const sortedColumns = map(sorted, "id").join(",");
        params.sort = `${sortedColumns} ${
          get(sorted, `${sorted.length - 1}.desc`) ? "desc" : "asc"
        }`;
      }
      const index = filtered ? filtered.findIndex(item => item.id === "q") : -1;
      if (index !== -1) {
        params.q = filtered[index].value;
      }
    }
    const response = await Api.doCall(
      `${Const.GET_SERVERS()}?${queryString.stringify(params)}`,
      "GET"
    );
    if (response.status === 200) {
      if (!response.data.error) {
        dispatch(fetchServersSuccess(response.data));
        if (updateServer && response.data.length) {
          response.data.forEach(server => dispatch(fetchServerSuccess({ id: server.id, data: server })));
        }
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch(fetchServersError({ data: errorStr }));
};

export const getMoreServers = state => async dispatch => {
  
  let errorStr = "";
  
  try {
    
    dispatch({ type: Types.FETCH_MORE_SERVERS_REQUEST });
    
    let response;
    let params = {};
    
    if (state) {

      const { pageSize, page, sorted, filtered } = state;

      params = {
        page: page + 1,
        rpp: pageSize
      };

      if (sorted.length > 0) {
        const sortedColumns = map(sorted, "id").join(",");
        params.sort = `${sortedColumns} ${
          get(sorted, `${sorted.length - 1}.desc`) ? "desc" : "asc"
        }`;
      }

      const index = filtered.findIndex(item => item.id === "q");
      if (index !== -1) {
        params.q = filtered[index].value;
      }
    }

    response = await Api.doCall(
      `${Const.GET_SERVERS()}?${queryString.stringify(params)}`,
      "GET"
    );

    if (response.status === 200) {
      
      if (!response.data.error) {

        dispatch(fetchMoreServersSucces(response.data));
        return;
      }
      errorStr = response.data.error;
    }

  } catch (error) {
    errorStr = error.toString();
  }

  dispatch(fetchMoreServersError({ data: errorStr }));
};

export const getAllServers = () => async dispatch => {
  let errorStr = "";
  try {
    dispatch({ type: Types.FETCH_ALL_SERVERS_REQUEST });
    const response = await Api.doCall(Const.GET_SERVERS(), "GET");

    if (response.status === 200) {
      if (!response.data.error) {
        dispatch({
          type: Types.FETCH_ALL_SERVERS_SUCCESS,
          payload: response.data
        });
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
    dispatch({
      type: Types.FETCH_ALL_SERVERS_ERROR,
      payload: errorStr
    });
  }
};
export const getCurrentServer = (cb) => async dispatch => {
  let errorStr = "";
  try {
    dispatch({ type: Types.FETCH_CURRENT_SERVER_REQUEST });
    let response = await Api.doCall(Const.GET_CURRENT_SERVER(), "GET");
    if (response.status === 200) {
      if (!response.data.error) {
        dispatch({ type: Types.FETCH_CURRENT_SERVER_SUCCESS, payload: response.data });
        if (isFunction(cb)) cb(response.data);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.FETCH_CURRENT_SERVER_ERROR,
    payload: errorStr
  });
};

export const getServer = (id, cb) => async dispatch => {
  let errorStr = "";
  try {
    dispatch({ type: Types.FETCH_SERVER_REQUEST, payload: { id } });
    let response = await Api.doCall(Const.GET_SERVER(id), "GET");
    if (response.status === 200) {
      if (!response.data.error) {
        dispatch(fetchServerSuccess({ id, data: response.data }));
        if (isFunction(cb)) cb(response.data);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch(fetchServerError({ id, error: errorStr }));
};

export const addServer = (data, cb) => async dispatch => {
  let errorStr = "";
  try {
    dispatch({ type: Types.ADD_SERVER_REQUEST });
    let response = await Api.doCall(Const.GET_SERVERS(), "POST", data);
    if (response.status === 200) {
      if (!response.data.error) {
        dispatch({ type: Types.ADD_SERVER_SUCCESS, payload: data });
        if (isFunction(cb)) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.ADD_SERVER_ERROR, payload: errorStr });
};

export const updateServer = (id, data, cb) => async dispatch => {
  let errorStr = "";
  try {
    dispatch({ type: Types.UPDATE_SERVER_REQUEST });
    let response = await Api.doCall(Const.GET_SERVER(id), "PUT", data);
    if (response.status === 200) {
      if (!response.data.error) {
        dispatch({ type: Types.UPDATE_SERVER_SUCCESS });
        dispatch(getServer(id));
        // dispatch(userInfo());
        dispatch(getCurrentServer());
        if (isFunction(cb)) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.UPDATE_SERVER_ERROR, payload: errorStr });
};

export const deleteServer = (id, cb) => async dispatch => {
  let errorStr = "";
  try {
    dispatch({ type: Types.DELETE_SERVER_REQUEST });
    let response = await Api.doCall(Const.GET_SERVER(id), "DELETE");
    if (response.status === 200) {
      if (!response.data.error) {
        dispatch({ type: Types.DELETE_SERVER_SUCCESS });
        if (isFunction(cb)) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.UPDATE_SERVER_ERROR, payload: errorStr });
};

export const requestLoginTargetServer = (server, cb) => async dispatch => {
  let errorStr = "";
  try {
    let response = await Api.doCall(
      Const.REQUEST_LOGIN_TARGET_SERVER(),
      "POST",
      {
        id: server.id
      }
    );
    if (response.status === 200) {
      if (isFunction(cb)) {
        cb({
          status: response.data.status,
          guid: response.data.guid
        });
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  if (isFunction(cb));
  cb({
    status: false,
    errorStr
  });
};

export const getWideOrbitConfigurations = id => async dispatch => {
  let errorStr = "";
  try {
    dispatch({ type: Types.FETCH_PRODUCTS_SETTINGS_REQUEST, payload: { id } });
    let response = await Api.doCall(Const.PRODUCTS_SETTINGS(id), "GET");
    if (response.status === 200) {
      if (!response.data.error) {
        dispatch(fetchWideObitConfigurationsSuccess({ id, data: response.data }));
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch(fetchWideObitConfigurationsError({ id, error: errorStr }));
};

export const updateWideOrbitConfigurations = (id, data, cb) => async dispatch => {
  let errorStr = "";
  try {
    dispatch({ type: Types.UPDATE_PRODUCTS_SETTINGS_REQUEST,  payload: id});
    let response = await Api.doCall(Const.PRODUCTS_SETTINGS(id), "PUT", data);
    if (response.status === 200) {
      if (!response.data.error) {
        dispatch({ type: Types.UPDATE_PRODUCTS_SETTINGS_SUCCESS, payload: { id, data } });
        if (isFunction(cb)) cb(false);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  if (isFunction(cb)) cb(true);
  dispatch({ type: Types.UPDATE_PRODUCTS_SETTINGS_ERROR, payload: {id: id, error: errorStr} });
};

export const getMaterialGroups = id => async dispatch => {
  let errorStr = "";
  try {
    dispatch({ type: Types.FETCH_MATERIAL_GROUPS_REQUEST, payload: { id } });
    let response = await Api.doCall(Const.MATERIAL_GROUPS(id), "GET");
    if (response.status === 200) {
      if (!response.data.error) {
        dispatch(fetchMaterialGroupsSuccess({ id, data: response.data }));
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch(fetchMaterialGroupsError({ id, error: errorStr }));
};

export const updateMaterialGroups = (id, data, cb) => async dispatch => {
  let errorStr = "";
  try {
    dispatch({ type: Types.UPDATE_MATERIAL_GROUPS_REQUEST,  payload: id});
    let response = await Api.doCall(Const.MATERIAL_GROUPS(id), "PUT", data);
    if (response.status === 200) {
      if (!response.data.error) {
        dispatch({ type: Types.UPDATE_MATERIAL_GROUPS_SUCCESS, payload: { id, data } });
        if (isFunction(cb)) cb(false);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  if (isFunction(cb)) cb(true);
  dispatch({ type: Types.UPDATE_MATERIAL_GROUPS_ERROR, payload: {id: id, error: errorStr} });
}

export function saveServerError(payload) {
  return {
    type: Types.FETCH_SERVERS_ERROR,
    payload
  };
}

export function deleteServerError(payload) {
  return {
    type: Types.DELETE_SERVER_ERROR,
    payload
  };
}

export function updateServerError(payload) {
  return {
    type: Types.UPDATE_SERVER_ERROR,
    payload
  };
}

export function fetchServerSuccess(payload) {
  return {
    type: Types.FETCH_SERVER_SUCCESS,
    payload
  };
}

export function fetchServersSuccess(payload) {
  return {
    type: Types.FETCH_SERVERS_SUCCESS,
    payload
  };
}

export function fetchServerError(payload) {
  return {
    type: Types.FETCH_SERVERS_ERROR,
    payload
  };
}

export function fetchMoreServersSucces(payload) {
  return {
    type: Types.FETCH_MORE_SERVERS_SUCCESS,
    payload
  };
}

export function fetchMoreServersError(payload) {
  return {
    type: Types.FETCH_MORE_SERVERS_ERROR,
    payload
  };
}


export function fetchServersError(payload) {
  return {
    type: Types.FETCH_SERVERS_ERROR,
    payload
  };
}


export function fetchWideObitConfigurationsSuccess(payload) {
  return {
    type: Types.FETCH_PRODUCTS_SETTINGS_SUCCESS,
    payload
  };
}

export function fetchWideObitConfigurationsError(payload) {
  return {
    type: Types.FETCH_PRODUCTS_SETTINGS_ERROR,
    payload
  };
}

export function fetchMaterialGroupsSuccess(payload) {
  return {
    type: Types.FETCH_MATERIAL_GROUPS_SUCCESS,
    payload
  };
}

export function fetchMaterialGroupsError(payload) {
  return {
    type: Types.FETCH_MATERIAL_GROUPS_ERROR,
    payload
  };
}


export const getSecurityPolicyServer = id => async dispatch => {
    let errorStr = "";
    try {
        dispatch({
            type: Types.FETCH_SECURITY_POLICY_SERVER_REQUEST,
            payload: { id }
        });
        let response = await Api.doCall(Const.GET_SECURITY_POLICY_SERVER(id), "GET");
        if (response.status === 200 || response.status === 201) {
            if (!response.data.error) {
                dispatch({
                    type: Types.FETCH_SECURITY_POLICY_SERVER_SUCCESS,
                    payload: { id, data: response.data }
                });
                return;
            }
            errorStr = response.data.error;
        }
    } catch (error) {
        errorStr = error.toString();
    }
    dispatch({
        type: Types.FETCH_SECURITY_POLICY_SERVER_ERROR,
        payload: { id, error: errorStr }
    });
};


export const updateSecurityPolicyServer = (id, data, cb) => async dispatch => {
    let errorStr = "";
    try {
        dispatch({
            type: Types.UPDATE_SECURITY_POLICY_SERVER_REQUEST
        });
        let response = await Api.doCall(
            Const.UPDATE_SECURITY_POLICY_SERVER(id),
            "POST",
            data
        );
        if (response.status === 200 || response.status === 201) {
            if (!response.data.error) {
                dispatch({
                    type: Types.UPDATE_SECURITY_POLICY_SERVER_SUCCESS,
                    payload: { id, data: response.data }
                });
                if (isFunction(cb)) cb();
                return;
            }
            errorStr = response.data.error;
        }
    } catch (error) {
        errorStr = error.toString();
    }
    dispatch({
        type: Types.UPDATE_SECURITY_POLICY_SERVER_ERROR,
        payload: { error: errorStr }
    });
};
