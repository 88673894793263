import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  ModalBody,
  Button,
  Row,
  Col,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";
import classnames from "classnames";
import TextInput from "components/TextInput";
import bn from "utils/bemnames";
import { CloseIcon } from "components/CustomIcons";
import { FormattedMessage } from "react-intl";
import Checkbox from "components/Checkbox";
import SearchResults from "./SearchResults";
import { Formik } from "formik";
import * as Yup from "yup";
import { map, xor, includes, get, find } from "lodash";
import classNames from "classnames";
import Dropdown from "components/Dropdown";

const bem = bn.create("advanced-search-modal");
const statusConfig = [
  {
    label: <FormattedMessage id="process > active" />,
    value: "active",
  },
  {
    label: <FormattedMessage id="process > expired" />,
    value: "expired",
  },
  {
    label: <FormattedMessage id="process > archived" />,
    value: "archived",
  },
];
function SearchForm(props) {
  const {
    onToggle,
    intl,
    onSubmit,
    initialValues,
    setIsShowResults,
    isSupportSearchResults,
    isSupportType,
    types,
  } = props;
  return (
    <Formik
      initialValues={{
        ...initialValues,
        status: get(initialValues, "status", ["active", "expired", "archived"]),
      }}
      validationSchema={Yup.object().shape({
        start_date: Yup.date().nullable(),
        end_date: Yup.date()
          .when(
            "start_date",
            (startDate, schema) =>
              startDate &&
              schema.min(
                startDate,
                intl.formatMessage({
                  id: `validate > end date should be later than start date`,
                })
              )
          )
          .nullable(),
        keyword: Yup.string().required(
          intl.formatMessage({ id: "validate > keyword is required" })
        ),
      })}
      onSubmit={(values) => {
        onSubmit(values, (response) => {
          if (isSupportSearchResults) {
            setIsShowResults(true);
          }
        });
      }}
    >
      {(formProps) => {
        const { values, touched, errors } = formProps;
        return (
          <Form onSubmit={formProps.handleSubmit}>
            <Row>
              <Col xs={12} className={bem.e("reset-col")}>
                <FormGroup className={bem.e("group-checkboxes")}>
                  {map(statusConfig, (item, index) => (
                    <Checkbox
                      key={index}
                      checked={includes(values.status, item.value)}
                      onChange={() => {
                        formProps.setFieldValue(
                          "status",
                          xor(values.status, [item.value])
                        );
                      }}
                      text={item.label}
                    />
                  ))}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <TextInput
                    label={<FormattedMessage id="process > keyword" />}
                    type="text"
                    name="keyword"
                    placeholder={intl.formatMessage({
                      id: "process > placeholder keyword",
                    })}
                    value={values.keyword}
                    onChange={formProps.handleChange}
                    onBlur={formProps.handleBlur}
                    error={errors.keyword}
                  />
                </FormGroup>
              </Col>
              {isSupportType && (
                <Col xs={6}>
                  <FormGroup>
                    <Dropdown
                      label={<FormattedMessage id="process > field type" />}
                      placeholder={intl.formatMessage({
                        id:
                          "process > promotion > liner > schedule > placeholder select",
                      })}
                      name="type_id"
                      onChange={(option) => {
                        formProps.setFieldValue("type_id", option.value);
                      }}
                      value={find(
                        types,
                        (item) => item.value === values.type_id
                      )}
                      options={types}
                      closeMenuOnSelect={true}
                    />
                  </FormGroup>
                </Col>
              )}
            </Row>
            <Row>
              <Col xs={6}>
                <FormGroup className={bem.e("datepicker")}>
                  <TextInput
                    label={<FormattedMessage id="process > start date" />}
                    type="date"
                    name="start_date"
                    placeholder={intl.formatMessage({
                      id:
                        "process > promotion > liner > schedule > placeholder select",
                    })}
                    value={values.start_date}
                    onChange={(value) =>
                      formProps.setFieldValue("start_date", value)
                    }
                    error={touched.start_date && errors.start_date}
                    allowShowIcon
                  />
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup className={bem.e("datepicker")}>
                  <TextInput
                    label={<FormattedMessage id="process > end date" />}
                    type="date"
                    name="end_date"
                    placeholder={intl.formatMessage({
                      id:
                        "process > promotion > liner > schedule > placeholder select",
                    })}
                    value={values.end_date}
                    onChange={(value) =>
                      formProps.setFieldValue("end_date", value)
                    }
                    error={touched.end_date && errors.end_date}
                    allowShowIcon
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className={bem.e("buttons")}>
                  <Button
                    color="primary"
                    className={classnames("btn-radius", bem.e("btn-search"))}
                    type="submit"
                  >
                    <FormattedMessage id="process > button search" />
                  </Button>
                  <Button
                    color="primary"
                    outline
                    onClick={onToggle}
                    className={classnames("btn-radius", bem.e("btn-cancel"))}
                  >
                    <FormattedMessage id="process > button cancel" />
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
}
const AdvancedSearchModal = (props) => {
  const { isOpen, onToggle, onSelectedAdvancedSearch } = props;
  const [isShowResults, setIsShowResults] = useState(false);
  return (
    <Modal
      isOpen={isOpen}
      className={classnames(bem.b(), {
        [bem.m("results")]: isShowResults,
      })}
      centered
    >
      <ModalHeader toggle={onToggle} className={bem.e("header")}>
        <FormattedMessage id="process > title modal advanced search" />
        <div
          className={bem.e("close")}
          onClick={() => {
            onToggle();
            setIsShowResults(false);
          }}
        >
          <CloseIcon color="#657894" />
        </div>
      </ModalHeader>
      <ModalBody
        className={classNames(bem.e("body"), {
          [bem.e("search-results-body")]: isShowResults,
        })}
      >
        {isShowResults ? (
          <SearchResults
            {...props}
            bem={bem}
            setIsShowResults={setIsShowResults}
            onCancel={() => {
              onToggle();
              setIsShowResults(false);
            }}
            onSearch={onSelectedAdvancedSearch}
          />
        ) : (
          <SearchForm {...props} setIsShowResults={setIsShowResults} />
        )}
      </ModalBody>
    </Modal>
  );
};

AdvancedSearchModal.propTypes = {
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
  initialValues: PropTypes.object,
  isSupportSearchResults: PropTypes.bool,
};

AdvancedSearchModal.defaultProps = {
  isOpen: false,
  onToggle: () => {},
  initialValues: {},
  isSupportSearchResults: false,
};

export default AdvancedSearchModal;
