import React, { useEffect, useRef } from "react";
import { Form, Button } from "reactstrap";
import bn from "utils/bemnames";
import { Asterisk } from "components/Elements";
import { FormattedMessage } from "react-intl";
import ToastManager from "components/ToastManager";
import { FaPlus } from "react-icons/lib/fa";
import * as Yup from "yup";
import { Formik, yupToFormErrors, validateYupSchema } from "formik";
import classNames from "classnames";
import TextInput from "components/TextInput";
import {
  findIndex,
  get,
  isArray,
  map,
  intersection,
  split,
  clone,
  filter,
  find,
  orderBy,
  forEach,
  uniq,
} from "lodash";
import FieldsFormBuilderModal from "components/FormBuilder/FieldsFormBuilderModal";
import SortableComponent from "../SortableComponent";
import ConfirmationModal from "components/ConfirmationModal";
import { generateField } from "../helper";
const bem = bn.create("system-form");

const classNamesEnabled = [
  "cr-sidebar__sections",
  "cr-sidebar__sidebar-section",
  "cr-system-form__btn-preview",
  "cr-master-listener__btn-preview",
  "cr-master-listener__head-right",
  "cr-system-form__head-right",
  "cr-system-form__btn-clone",
  "cr-system-form__button-down",
  "cr-sidebar__admin-card",
  "cr-sidebar-new",
  "cr-header__site-logo",
  "breadcrumb-item",
];
const useOutsideAlerter = (ref, props) => {
  const {
    isOpenChannelPreviewModal,
    isOpenFieldsFormBuilderModal,
    isDirty,
  } = props;
  /**
   * Alert if clicked on outside of element
   */
  function handleClickOutside(event) {
    // detect click on scrollbar
    if (
      event.clientX + 20 >= window.innerWidth ||
      document.body.classList.contains("modal-open")
    ) {
      return;
    }

    const eventClasses = split(
      get(event, "target.parentElement.parentElement.className", ""),
      " "
    );
    const isEnabled = intersection(eventClasses, classNamesEnabled).length > 0;
    if (
      ref.current &&
      !ref.current.contains(event.target) &&
      isEnabled &&
      isDirty &&
      !isOpenChannelPreviewModal &&
      !isOpenFieldsFormBuilderModal
    ) {
      props.setIsOpenConfirmationActionsUnsavedModal(true);
    }
  }
  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
};
const getMasterListFields = (masterListenerForm) => {
  let validKeyFields = [];
  forEach(get(masterListenerForm, "_source.fields", []), (field) => {
    validKeyFields = [...validKeyFields, field.key];
    if (field.sub_fields && field.sub_fields.length > 0) {
      validKeyFields = [
        ...validKeyFields,
        ...map(field.sub_fields, (item) => item.key),
      ];
    }
  });
  return validKeyFields;
};
const SystemForm = (props) => {
  const {
    onSubmitForm,
    setIsOpenFieldsFormBuilderModal,
    isOpenFieldsFormBuilderModal,
    intl,
    listFields,
    setListFields,
    systemForm,
    currentStation,
    isOpenChannelPreviewModal,
    formName,
    setFormName,
    isEditable,
    masterListenerForm,
    currentServer,
  } = props;
  const serverCountries = [
    currentServer.country,
    ...currentServer.neighboring_countries,
  ];

  const formikRef = useRef(null);
  const wrapperRef = useRef(null);
  const formType = get(systemForm, "_source.form_type", "");
  const isAllowDesignElement = formType.indexOf("console") === -1;

  const validateBeforeSubmit = (values, formikObject) => {
    const { fields } = values;
    let errors;
    fields.forEach((field, index) => {
      const { key } = field;
      if (key === "dropdown" || "radio" || "checkbox") {
        const { options } = field;
        let indexError = findIndex(
          options,
          ({ label, value }) => !label || !value
        );
        if (indexError > -1) {
          let fieldsErrors = errors ? errors.fields : [];
          fieldsErrors[index] = {
            ...fieldsErrors[index],
            options: intl.formatMessage({
              id: "validate > option is required",
            }),
          };
          errors = {
            ...errors,
            fields: fieldsErrors,
          };
        }
      }
      if (key === "home_address" || "business_address") {
        const { sub_fields } = field;
        let indexError = findIndex(sub_fields, ({ label }) => !label);
        if (indexError > -1) {
          let fieldsErrors = errors ? errors.fields : [];
          fieldsErrors[index] = {
            ...fieldsErrors[index],
            sub_fields: intl.formatMessage({
              id: "validate > label is required",
            }),
          };
          errors = {
            ...errors,
            fields: fieldsErrors,
          };
        }
      }
    });
    if (errors) {
      formikObject.setErrors(errors);
    } else {
      onSubmitForm(values, formikObject);
    }
  };
  const formSchema = Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage({ id: "validate > name is required" })
    ),
    fields: Yup.array().of(
      Yup.object().shape({
        label: Yup.string()
          .required(intl.formatMessage({ id: "validate > label is required" }))
          .test({
            name: "duplicate_label",
            message: intl.formatMessage({
              id: "validate > label name already exists",
            }),
            test: function(value) {
              let count = 0;
              if (["separator", "helper_text"].indexOf(this.parent.key) !== -1)
                return true;
              this.options.context.customFields.forEach((label) => {
                if (
                  value.trim() !== "" &&
                  label.trim().toLowerCase() === value.trim().toLowerCase()
                ) {
                  count++;
                }
              });
              return !(count > 1);
            },
          }),
        value: Yup.string()
          .test({
            name: "helper_text_required",
            message: intl.formatMessage({
              id: "validate > label is required",
            }),
            test: function(value) {
              if (this.parent.key === "helper_text" && !value) return false;
              return true;
            },
          })
          .nullable(),
      })
    ),
  });

  useOutsideAlerter(wrapperRef, {
    isOpenChannelPreviewModal,
    isOpenFieldsFormBuilderModal,
    setIsOpenConfirmationActionsUnsavedModal:
      props.setIsOpenConfirmationActionsUnsavedModal,
    isDirty: props.isDirty,
  });
  const validKeyFields = getMasterListFields(masterListenerForm);
  return (
    <div ref={wrapperRef}>
      <Formik
        initialValues={{
          id: systemForm._id,
          name: get(systemForm, "_source.name"),
          form_type: get(systemForm, "_source.form_type"),
          station_id: currentStation.key,
          fields: get(systemForm, "_source.fields", []),
        }}
        validate={(values) => {
          if (!isEditable) return;
          try {
            validateYupSchema(values, formSchema, true, {
              customFields: map(values.fields, (field) => field.label),
            });
          } catch (err) {
            return yupToFormErrors(err);
          }
          return {};
        }}
        onSubmit={validateBeforeSubmit}
        ref={formikRef}
      >
        {(formProps) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
          } = formProps;
          let keyFields = [];
          forEach(values.fields, (field) => {
            keyFields = [...keyFields, field.key];
            if (field.sub_fields && field.sub_fields.length > 0) {
              keyFields = [
                ...keyFields,
                ...map(field.sub_fields, (item) => item.key),
              ];
            }
          });
          const onSelectedField = (value) => {
            setIsOpenFieldsFormBuilderModal(false);
            // eslint-disable-next-line no-lone-blocks
            let fields = [];
            if (isArray(value)) {
              fields = [
                ...values.fields,
                ...map(value, (i) =>
                  generateField(i, serverCountries, {
                    isSystemForm: true,
                    masterListenerForm,
                  })
                ),
              ];
              formProps.setFieldValue("fields", fields);
              formProps.setFieldTouched("fields", false);
              props.setIsDirty(true);
              return;
            }
            const newField = generateField(value, serverCountries, {
              isSystemForm: true,
              masterListenerForm,
            });
            if (newField.multi_field) {
              const exists = filter(
                values.fields,
                (item) => item.key === newField.key
              ).length;
              // Case: When user added a group -> add unique label
              if (exists > 0) {
                fields = [
                  ...values.fields,
                  {
                    ...newField,
                    label: `${newField.label} ${exists}`,
                    sub_fields: newField.sub_fields.map((i) =>
                      generateField(i, serverCountries, {
                        isSystemForm: true,
                        masterListenerForm,
                      })
                    ),
                  },
                ];
                // Case: when user add new group or add new sub field -> we will add this group field to values
              } else {
                const masterListenerGroup = get(
                  masterListenerForm,
                  "_source.fields",
                  []
                ).find((i) => i.key === newField.key);
                let isAddedAddress1 = true;
                let newSubFields = newField.sub_fields
                  .map((i) =>
                    generateField(i, serverCountries, {
                      isSystemForm: true,
                      masterListenerForm,
                    })
                  )
                  .filter((i) => {
                    const isEnabled = masterListenerGroup
                      ? find(
                          masterListenerGroup.sub_fields,
                          (k) => k.key === i.key && !k.is_hidden
                        )
                      : true;
                    if (
                      i.key === "business_address_1" ||
                      i.key === "home_address_1"
                    ) {
                      isAddedAddress1 = isEnabled;
                    }
                    return isEnabled;
                  });
                // update address 2 when missing address 1
                if (!isAddedAddress1) {
                  newSubFields = map(newSubFields, (i) => {
                    if (i.key === "business_address_2") {
                      return {
                        ...i,
                        label: "business address line 1",
                      };
                    }
                    if (i.key === "home_address_2") {
                      return {
                        ...i,
                        label: "home address line 1",
                      };
                    }
                    return i;
                  });
                }
                fields = [
                  ...values.fields,
                  {
                    ...newField,
                    sub_fields: newSubFields,
                  },
                ];
              }
            } else if (newField.group) {
              // When user add a subfield of a group field -> check this field is missing in the group -> add this field to group
              const currentGroup = find(
                values.fields,
                (i) => i.key === newField.group
              );
              const isAddedInGroup = currentGroup
                ? !!find(currentGroup.sub_fields, (i) => i.key === newField.key)
                : false;
              const masterListenerGroup = get(
                masterListenerForm,
                "_source.fields",
                []
              ).find((i) => i.key === newField.group);
              const isEnabledForMasterListener = masterListenerGroup
                ? !!find(
                    masterListenerGroup.sub_fields,
                    (i) => i.key === newField.key && !i.is_hidden
                  )
                : false;
              if (!isAddedInGroup && isEnabledForMasterListener) {
                fields = map(values.fields, (i) => {
                  if (i.key === newField.group) {
                    return {
                      ...i,
                      sub_fields: orderBy(
                        [...i.sub_fields, newField],
                        ["order"],
                        ["asc"]
                      ),
                    };
                  }
                  return i;
                });
              } else {
                fields = [...values.fields, newField];
              }
            } else {
              fields = [...values.fields, newField];
            }

            formProps.setFieldValue("fields", fields);
            formProps.setFieldTouched("fields", false);
            props.setIsDirty(true);
          };
          return (
            <Form
              className={bem.e("form")}
              onSubmit={formProps.handleSubmit}
              noValidate
            >
              <div className={bem.e("form-name")}>
                <TextInput
                  label={
                    <span>
                      <FormattedMessage id="form manager > form name" />
                      <Asterisk>*</Asterisk>
                    </span>
                  }
                  type="text"
                  name="name"
                  disabled={!isEditable}
                  placeholder={intl.formatMessage({
                    id: "form manager > enter name",
                  })}
                  value={values.name}
                  error={touched.name && errors.name}
                  onChange={(e) => {
                    handleChange(e);
                    setFormName(e.target.value);
                    props.setIsDirty(true);
                  }}
                  onBlur={handleBlur}
                />
              </div>
              <div className={bem.e("form-fields")}>
                <div className={bem.e("first-last-name")}>
                  <FormattedMessage id="form builder > first and last name" />
                  <span className={bem.e("required-text")}>
                    <FormattedMessage id="form builder > required" />
                  </span>
                </div>
                <div className={bem.e("custom-fields-sort")}>
                  <SortableComponent
                    data={values.fields}
                    onSorted={(sortItems) => {
                      // always keep first name last name field on the top
                      const nameField = sortItems.find(
                        (item) => item.key === "first_last_name"
                      );
                      const finalSortItems = [
                        nameField,
                        ...filter(
                          sortItems,
                          (item) => item.key !== "first_last_name"
                        ),
                      ];
                      props.setIsDirty(true);
                      formProps.setFieldValue("fields", finalSortItems);
                    }}
                    formProps={{
                      ...formProps,
                      isView: !isEditable,
                      disabled: false,
                      currentStation: props.currentStation,
                      setIsDirty: props.setIsDirty,
                      onDeleteField: (field) => {
                        props.setDeletingField(field);
                        props.setIsOpenConfirmationDeleteModal(true);
                      },
                      isSystemForm: true,
                      isMasterListener: false,
                      currentServer,
                      masterListenerFields: get(
                        masterListenerForm,
                        "_source.fields",
                        []
                      ),
                    }}
                  />
                </div>
                <div className={bem.e("field-buttons")}>
                  <Button
                    className={classNames(bem.e("button-create"), "btn-radius")}
                    color="blue"
                    outline
                    disabled={!isEditable}
                    onClick={() => {
                      if (!isEditable) return;
                      setIsOpenFieldsFormBuilderModal(
                        !isOpenFieldsFormBuilderModal
                      );
                      setListFields("masterListener");
                    }}
                  >
                    <i className={bem.e("button-icon")}>
                      <FaPlus />
                    </i>
                    <FormattedMessage id="form manager > add a field" />
                  </Button>
                  {isAllowDesignElement && (
                    <Button
                      className={classNames(
                        bem.e("button-create"),
                        "btn-radius"
                      )}
                      color="blue"
                      outline
                      disabled={!isEditable}
                      onClick={() => {
                        if (!isEditable) return;
                        setIsOpenFieldsFormBuilderModal(
                          !isOpenFieldsFormBuilderModal
                        );
                        setListFields("designElement");
                      }}
                    >
                      <i className={bem.e("button-icon")}>
                        <FaPlus />
                      </i>
                      <FormattedMessage id="form manager > add design element" />
                    </Button>
                  )}
                </div>
              </div>
              <div className={bem.e("form-buttons")}>
                <Button
                  color="blue"
                  type="submit"
                  className="btn btn-radius btn-submit"
                  disabled={!isEditable}
                  onClick={() => {
                    if (!isEditable) return;
                    setTimeout(() => {
                      const formErrors = get(formikRef, "current.state.errors");
                      if (formErrors && Object.keys(formErrors).length) {
                        ToastManager.show({
                          title: intl.formatMessage({
                            id: "toast > title not saved",
                          }),
                          message: intl.formatMessage({
                            id:
                              "toast > message error please correct the hilighted fields",
                          }),
                          level: "error",
                        });
                        return;
                      }
                    }, 20);
                  }}
                >
                  <FormattedMessage id={`form manager > button submit`} />
                </Button>
                <Button
                  color="blue"
                  outline
                  className="btn btn-radius"
                  onClick={props.onCancel}
                >
                  <FormattedMessage id={`form manager > button cancel`} />
                </Button>
              </div>
              <FieldsFormBuilderModal
                isOpen={isOpenFieldsFormBuilderModal}
                onSelect={onSelectedField}
                list={listFields}
                disabledFields={uniq(keyFields)}
                validKeyFields={uniq(validKeyFields)}
                onToggle={() =>
                  setIsOpenFieldsFormBuilderModal(!isOpenFieldsFormBuilderModal)
                }
              />

              {/* actions confirmation delete field*/}
              <ConfirmationModal
                isOpen={props.isOpenConfirmationDeleteModal}
                title={intl.formatMessage({
                  id: "form manager > title delete system field confirmation",
                })}
                isCloseOutside={false}
                className={bem.e("confirmation-modal")}
                confirmTitle={intl.formatMessage({
                  id: "form manager > button delete",
                })}
                cancelTitle={intl.formatMessage({
                  id: "form manager > button cancel",
                })}
                onToggle={() =>
                  props.setIsOpenConfirmationDeleteModal(
                    !props.isOpenConfirmationDeleteModal
                  )
                }
                onConfirm={() => {
                  let newFields = clone(values.fields);
                  if (typeof props.deletingField === "object") {
                    newFields = map(newFields, (item, index) => {
                      if (index === props.deletingField.deletingParentIndex) {
                        return {
                          ...item,
                          sub_fields: filter(
                            item.sub_fields,
                            (i) =>
                              i.key !== props.deletingField.subfieldDeleting.key
                          ),
                        };
                      }
                      return item;
                    });
                  } else {
                    newFields.splice(props.deletingField, 1);
                  }
                  formProps.setFieldValue("fields", newFields);
                  props.setIsOpenConfirmationDeleteModal(false);
                  props.setDeletingField(null);
                }}
                onCancel={() => {
                  props.setIsOpenConfirmationDeleteModal(false);
                }}
              />
            </Form>
          );
        }}
      </Formik>
      <ConfirmationModal
        isOpen={props.isOpenConfirmationActionsUnsavedModal}
        title={
          <span>
            {intl.formatMessage({
              id: "form manager > save changes to",
            })}
            {` `}
            {formName}?
          </span>
        }
        isCloseOutside={false}
        className={bem.e("confirmation-modal")}
        description={""}
        confirmTitle={intl.formatMessage({
          id: "form manager > button save",
        })}
        cancelTitle={intl.formatMessage({
          id: "form manager > button cancel",
        })}
        onConfirm={() => {
          props.setIsOpenConfirmationActionsUnsavedModal(false);
          if (formikRef.current) {
            formikRef.current.submitForm();
            setTimeout(() => {
              const formErrors = get(formikRef, "current.state.errors");
              if (formErrors && Object.keys(formErrors).length) {
                ToastManager.show({
                  title: intl.formatMessage({
                    id: "toast > title not saved",
                  }),
                  message: intl.formatMessage({
                    id:
                      "toast > message error please correct the hilighted fields",
                  }),
                  level: "error",
                });
                return;
              }
            }, 20);
          }
        }}
        onToggle={() =>
          props.setIsOpenConfirmationActionsUnsavedModal(
            !props.isOpenConfirmationActionsUnsavedModal
          )
        }
        onCancel={() => {
          props.onCancel();
          props.setFormName(systemForm._source.name);
          props.setIsOpenConfirmationActionsUnsavedModal(false);
        }}
      />
    </div>
  );
};
SystemForm.defaultProps = {};
export default SystemForm;
