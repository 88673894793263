import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import ContestSchedule from "./view";
import { setSchedulingAvailability } from "store/actions/programmings";
import { get } from "lodash";
const Enhanced = connect(
  (state, props) => {
    return {
      defaultSchedulingType: state.auth.scheduling_type || "simple",
      dateFormatByServer: get(
        state,
        "servers.currentServer.data.date_format",
        "MM/DD/YYYY"
      ),
      ...props,
    };
  },
  { setSchedulingAvailability }
)(ContestSchedule);

export default injectIntl(Enhanced);
