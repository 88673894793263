import React, { useMemo } from "react";
import PropTypes from "utils/propTypes";
import { get } from "lodash";
import { withRouter } from "react-router-dom";
import bn from "utils/bemnames";

import { Breadcrumb, BreadcrumbItem } from "reactstrap";

import Typography from "./Typography";

const bem = bn.create("page");

const Page = ({
  title,
  breadcrumbs,
  tag: Tag,
  className,
  applyPadding,
  children,
  history,
  headerChildren,
  ...restProps
}) => {
  const classes = bem.b(applyPadding ? "p-5" : "", className);
  const onGoToBreadCrumb = (breadcrumb) => {
    if (get(breadcrumb, "to")) {
      history.push(breadcrumb.to);
    }
  };
  const safeProps = useMemo(() => {
    const localProps = { ...restProps };
    Object.keys(localProps).map((p) => {
      if (p === "staticContext") delete localProps[p];
    });
  }, [restProps]);
  const isShowHeader = title || headerChildren;
  return (
    <Tag className={classes} {...safeProps}>
      <div className={bem.e("page-top")}>
        {breadcrumbs && (
          <Breadcrumb className={bem.e("breadcrumb")}>
            {breadcrumbs.length &&
              breadcrumbs.map((breadcrumb, index) => (
                <BreadcrumbItem
                  key={index}
                  active={breadcrumb.active}
                  onClick={() => onGoToBreadCrumb(breadcrumb)}
                >
                  {breadcrumb.name}
                </BreadcrumbItem>
              ))}
          </Breadcrumb>
        )}
        {isShowHeader && (
          <div className={bem.e("header")}>
            {title && (
              <Typography type="h1" className={bem.e("title")}>
                {title}
              </Typography>
            )}
            {headerChildren ? headerChildren : null}
          </div>
        )}
      </div>
      {children}
    </Tag>
  );
};

Page.propTypes = {
  tag: PropTypes.component,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
  children: PropTypes.node,
  breadcrumbs: PropTypes.any,
  applyPadding: PropTypes.bool,
};

Page.defaultProps = {
  tag: "div",
  title: "",
  applyPadding: true,
};

export default withRouter(Page);
