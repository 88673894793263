import React from "react";
import { FormGroup } from "reactstrap";
import bn from "utils/bemnames";
import { setScriptField } from "utils/helpers";
import TextareaEvaluator from "components/TextareaEvaluator";
import PropTypes from "prop-types";
const bem = bn.create("jock-console-item");
export default function TextEditor(props) {
  const { isEdit, value, intl } = props;
  if (!isEdit) {
    return (
      <div className={bem.e("text-editor")}>
        <FormGroup>
          <TextareaEvaluator
            text={setScriptField(value)}
            hideOrderLength={true}
            onEvaluatorCallback={() => {}}
            placeholder={intl.formatMessage({
              id: "jock console manager > enter text",
            })}
            readOnly={true}
          />
        </FormGroup>
      </div>
    );
  }
  return (
    <div className={bem.e("text-editor")}>
      <FormGroup>
        <TextareaEvaluator
          text={setScriptField(value)}
          hideOrderLength={true}
          onEvaluatorCallback={(value) => {
            props.setFieldValue("fields.text", value);
          }}
          placeholder={intl.formatMessage({
            id: "jock console manager > enter text",
          })}
          isEditorOnly
        />
      </FormGroup>
    </div>
  );
}
TextEditor.defaultProps = {
  isEdit: false,
};
TextEditor.propTypes = {
  isEdit: PropTypes.bool,
};
