import { connect } from "react-redux";
import { compose } from "recompose";
import { get } from "lodash";
import { injectIntl } from "react-intl";

import View from './view';

const Enhanced = compose(
  connect(
    (state, props) => {
      const allStationsAndTeams = get(state, "teams.allStationsAndTeams.data.data", []);
      const loading = get(state, "teams.allStationsAndTeams.loading", false);
      return {
        allStationsAndTeams,
        loading,
        ...props,
      };
    }
  )
)(View);

export default injectIntl(Enhanced);
