import React from "react";
import { Row, Col } from "reactstrap";
import Avatar from "components/Avatar";
import { get, find, forEach, filter, isEmpty } from "lodash";
import { FaEllipsisH } from "react-icons/lib/fa";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import Player from "components/Player";
import { PalmTreeIcon } from "components/CustomIcons";
import bn from "utils/bemnames";
import {
  checkOutOfOfficeOfUserByDates,
  formatDisplayDateWithTimezone,
  getNextOutOfOfficeOfUserByDates,
} from "utils/helpers";
const bem = bn.create("assign-team-sidebar");
const userInfo = (user) => {
  const currentTasksCount = user.info.current_tasks_count
    ? user.info.current_tasks_count
    : 0;
  const assignedTasksCount = user.info.assigned_tasks_count
    ? user.info.assigned_tasks_count
    : 0;
  return (
    <div className={bem.e("user-tasks")}>
      <div
        className={classnames(bem.e("user-task"), bem.e("user-task-current"))}
      >
        <span className={bem.e("user-task-label")}>
          <FormattedMessage
            className={bem.e("user-task-label")}
            id="select team > current tasks"
          />
        </span>
        :<span className={bem.e("user-task-value")}>{currentTasksCount}</span>
      </div>
      <div
        className={classnames(bem.e("user-task"), bem.e("user-task-assigned"))}
      >
        <span className={bem.e("user-task-label")}>
          <FormattedMessage id="select team > assigned tasks" />:
        </span>
        <span className={bem.e("user-task-value")}>{assignedTasksCount}</span>
      </div>
    </div>
  );
};

const getUserStatus = (user, enterpriseSettings, values) => {
  if (!enterpriseSettings) return "";
  let currentLoad =
    get(user, "info.current_tasks_count", 0) +
    get(user, "info.assigned_tasks_count", 0);

  let isOverLoaded = false;

  let selectedForRoles = filter(get(user, "candidate_for", []), (role) => {
    return (
      role &&
      values[role.field] &&
      values[role.field].filter((u) => parseInt(u.id) === user.info.id).length >
        0
    );
  });

  if (!isEmpty(selectedForRoles)) {
    forEach(selectedForRoles, (role) => {
      let capacity = getCapacityForRole(role, enterpriseSettings);

      if (capacity > 0 && currentLoad >= capacity) {
        isOverLoaded = true;
        return;
      }
    });
  } else {
    forEach(get(user, "candidate_for", []), (role) => {
      let capacity = getCapacityForRole(role, enterpriseSettings);

      if (capacity > 0 && currentLoad >= capacity) {
        isOverLoaded = true;
        return;
      }
    });
  }

  if (!user.info.is_active) return "inactive";
  if (isOverLoaded) return "overload";
  return "available";
};

const getCapacityForRole = (role, enterpriseSettings) => {
  let capacity = 0;

  if (get(role, "field") === "role_writer") {
    capacity = get(enterpriseSettings, "writer_capacity", 0);
  } else if (get(role, "field") === "role_voice_talent") {
    capacity = get(enterpriseSettings, "voice_over_capacity", 0);
  } else if (get(role, "field") === "role_producer") {
    capacity = get(enterpriseSettings, "producer_capacity", 0);
  } else if (get(role, "field") === "role_dubber") {
    capacity = get(enterpriseSettings, "dubber_capacity", 0);
  }

  return capacity;
};

function UserRows({
  candidates,
  intl,
  isAudition,
  values,
  toggleUserRole,
  enterpriseSettings,
}) {
  return (
    <div className={bem.e("container-users")}>
      {candidates.map((user, ind) => {
        const userStatus = getUserStatus(user, enterpriseSettings, values);
        const userTimeZone = get(user, "info.timezone");
        const datesOutOfOffice = checkOutOfOfficeOfUserByDates(
          get(user, "info.out_of_office_settings", []),
          userTimeZone
        );
        const nextDatesOutOfOffline = getNextOutOfOfficeOfUserByDates(
          get(user, "info.out_of_office_settings", []),
          userTimeZone
        );

        const isHasOOOPast = find(
          datesOutOfOffice,
          (date) => date.diffDays <= 0
        );
        const datesOOO = isHasOOOPast
          ? datesOutOfOffice
          : nextDatesOutOfOffline;
        return (
          <div
            key={"user_" + ind}
            className={classnames(bem.e("user-row"), {
              [bem.e("user-ooo")]: isHasOOOPast,
            })}
          >
            <Row>
              <Col xs={6} className={bem.e("col-user")}>
                <div className={bem.e("user-info")}>
                  <Avatar
                    src={user.info.avatar_url}
                    size={40}
                    className={bem.e("user-avatar")}
                    name={user.info.name}
                  />
                  <div className={bem.e("user-info-right")}>
                    <span className={bem.e("user-name")}>
                      <span>
                        <i
                          className={classnames(
                            bem.e("user-status"),
                            bem.e(`status-${userStatus}`)
                          )}
                        />
                        {user.info.name}
                      </span>
                      {isHasOOOPast ? (
                        <span className={bem.e("vacation-icon")}>
                          <PalmTreeIcon />
                        </span>
                      ) : null}
                      <div className={bem.e("user-action")}>
                        <FaEllipsisH color={`#798186`} size={20} />
                      </div>
                    </span>
                    {userInfo(user)}
                    {datesOOO && datesOOO.length > 0
                      ? datesOOO.map((date, index) => (
                          <div className={bem.e("out-of-office")} key={index}>
                            {intl.formatMessage({
                              id: "process > out as of",
                            })}{" "}
                            {formatDisplayDateWithTimezone(
                              date.from_date,
                              "MM/DD/YYYY",
                              userTimeZone
                            )}
                            {" - "}
                            {intl.formatMessage({
                              id: "process > returns",
                            })}{" "}
                            {formatDisplayDateWithTimezone(
                              date.to_date,
                              "MM/DD/YYYY",
                              userTimeZone
                            )}
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              </Col>
              <Col xs={6} className={bem.e("col-user")}>
                <div
                  className={classnames(bem.e("user-roles-reel"), {
                    [bem.e("user-roles-reel-column")]:
                      user.candidate_for.length > 1,
                  })}
                >
                  {user.info.reel && isAudition && (
                    <Player url={user.info.reel} />
                  )}

                  <div className={bem.e("user-roles")}>
                    {user.candidate_for.map((role) => {
                      const selected_for_role =
                        role &&
                        values[role.field] &&
                        values[role.field].filter(
                          (u) => parseInt(u.id) === user.info.id
                        ).length > 0;
                      return (
                        <div
                          className={classnames(bem.e("user-role-button"), {
                            [bem.e("user-role-assigned")]: selected_for_role,
                            [bem.e("user-role-disabled")]: role.is_disabled, //add condition for show disabled style
                          })}
                          key={"user_role_" + user.info.id + "_" + role.field}
                          onClick={() => {
                            if (role.is_disabled) return;
                            toggleUserRole(user, role);
                          }}
                        >
                          <span className={bem.e("user-role-title")}>
                            {role.title}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        );
      })}
    </div>
  );
}
export default UserRows;
