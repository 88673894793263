import * as React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Tooltip from "rc-tooltip";
import { FormattedMessage } from "react-intl";
import { MoreEllipsisIcon } from "components/CustomIcons";
import bn from "utils/bemnames";
const bem = bn.create("chatbox");
export default function MessageActions(props) {
  const { onEdit, onDelete, id } = props;
  return (
    <Tooltip
      trigger={["hover"]}
      placement="bottom"
      overlayClassName={cx(
        bem.e("tooltip-comment-actions"),
        "ignore-click-outside"
      )}
      overlay={
        <div className={bem.e("comment-actions")}>
          <div
            className={bem.e("comment-action-button")}
            onClick={(e) => {
              e.stopPropagation();
              onEdit(id);
            }}
          >
            <FormattedMessage id="process > title edit comment" />
          </div>
          <div
            className={bem.e("comment-action-button")}
            onClick={(e) => {
              e.stopPropagation();
              onDelete(id);
            }}
          >
            <FormattedMessage id="process > title delete comment" />
          </div>
        </div>
      }
    >
      <span className={bem.e("comment-more-icon")}>
        <MoreEllipsisIcon />
      </span>
    </Tooltip>
  );
}
MessageActions.propTypes = {
  onDelete: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
};
