import React from "react";
import bn from "utils/bemnames";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import { Row, Col, FormGroup } from "reactstrap";
import ToggleSwitch from "components/ToggleSwitch";
import { get } from "lodash";
const bem = bn.create("profile");
const ShareAccess = (props) => {
  const { isView, intl, user } = props;
  if (isView) {
    return (
      <div
        className={classnames(
          bem.e("workflow-section"),
          bem.e("view-workflow-section"),
          bem.e("box-section")
        )}
      >
        <h4 className={bem.e("section-title")}>
          <FormattedMessage id="my profile > title workflow" />
        </h4>
        <div className={bem.e("workflow-content")}>
          <Row>
            <Col className={bem.e("col-reset")}>
              <FormGroup>
                <div className={bem.e("workflow-help-text")}>
                  <FormattedMessage id="my profile > when i submit a task, i want to do the following" />
                </div>
                <ToggleSwitch
                  leftComponent={intl.formatMessage({
                    id: "my profile > return to current tasks",
                  })}
                  rightComponent={intl.formatMessage({
                    id: "my profile > stay in order",
                  })}
                  name="return_to_current_tasks"
                  switchProps={{
                    checked: !get(user, "return_to_current_tasks", true),
                    onChange: () => {},
                    offColor: "#795AFA",
                    onColor: "#C2D4E0",
                    uncheckedIcon: false,
                    checkedIcon: false,
                    boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.282)",
                    height: 26.44,
                    width: 48.48,
                    handleDiameter: 22.92,
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
  const { formProps } = props;
  const { values, setFieldValue } = formProps;

  return (
    <div
      className={classnames(
        bem.e("share-access-section"),
        bem.e("box-section")
      )}
    >
      <h4 className={bem.e("section-title")}>
        <FormattedMessage id="my profile > title workflow" />
      </h4>
      <div className={bem.e("workflow-content")}>
        <Row>
          <Col className={bem.e("col-reset")}>
            <FormGroup>
              <div className={bem.e("workflow-help-text")}>
                <FormattedMessage id="my profile > when i submit a task, i want to do the following" />
              </div>
              <ToggleSwitch
                leftComponent={intl.formatMessage({
                  id: "my profile > return to current tasks",
                })}
                rightComponent={intl.formatMessage({
                  id: "my profile > stay in order",
                })}
                name="return_to_current_tasks"
                switchProps={{
                  checked: !values.return_to_current_tasks,
                  onChange: (checked) => {
                    props.setShouldBlockNavigation(true);
                    setFieldValue("return_to_current_tasks", !checked);
                  },
                  offColor: "#795AFA",
                  onColor: "#795AFA",
                  uncheckedIcon: false,
                  checkedIcon: false,
                  boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.282)",
                  height: 26.44,
                  width: 48.48,
                  handleDiameter: 22.92,
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default ShareAccess;
