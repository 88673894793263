import React from "react";
import {
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col
} from "reactstrap";
import { FaCogs } from "react-icons/lib/fa";
import CitiesGroups from "components/CitiesGroups";
import MarketForm from "components/MarketForm";
import StationForm, {
  MODE_ADD as STATION_FORM_MODE_ADD
} from "components/StationForm";
import PrivilegesEffective from "components/PrivilegesEffective";
import { get } from "lodash";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import bn from "utils/bemnames";

export const TAB_INFO = "profile";
export const TAB_PRIVILEGES = "privileges";
export const TAB_CITIES_GROUPS = "cities_groups";

const bem = bn.create("station-tab-detail");

const StationsDetails = ({
  auth,
  activeTab,
  setActiveTab,
  back,
  lists,
  onSubmitPrivileges,
  isLoadingPrivileges,
  isView,
  intl
}) => {
  let backButton = (
    <Button
      type="button"
      color="blue"
      outline
      className={classnames(bem.e("button-cancel"), "btn-radius")}
      onClick={() => {
        if (back) back();
      }}
    >
      <FormattedMessage id="my profile > button cancel" />
    </Button>
  );
  let selectedStation = false;
  let ids = [];
  let single = false;
  if (lists) {
    ids = Object.keys(lists);
    if (Object.keys(lists).length === 1) {
      selectedStation = lists[Object.keys(lists)[0]];
      single = true;
    }
  }
  const isVisibleTabPrivileges = !single && isView !== STATION_FORM_MODE_ADD;
  const isVisibleTabCities =
    !single &&
    (get(auth, "user.is_god_admin") ||
      get(auth, "user.broadcasting_group_admin")) &&
    isView !== STATION_FORM_MODE_ADD;
  const isVisibleTabDetails =
    (single || isView === STATION_FORM_MODE_ADD) &&
    isVisibleTabPrivileges &&
    isVisibleTabCities;
  const isMultipleStations = !single && isView !== STATION_FORM_MODE_ADD;
  return (
    <div>
      <div className={bem.b()}>
        {isMultipleStations && (
          <div className={bem.e("info-profile")}>
            <div className={bem.e("user-avatar")}>
              <div>
                <FaCogs className={bem.e("avatar")} size={100} />
              </div>
              <div className={bem.e("user-name")}>
                <span className={bem.e("text-head")}>
                  Editing {Object.keys(lists).length} stations
                </span>
              </div>
            </div>
            <div className={bem.e("user-position")}>
              <span className={bem.e("text-head")} />
            </div>
          </div>
        )}
        <div>
          <Nav
            tabs
            className={classnames(bem.e("nav"), {
              [bem.e("has-tabs")]:
                isVisibleTabDetails ||
                isVisibleTabPrivileges ||
                isVisibleTabCities
            })}
          >
            {isVisibleTabDetails && (
              <NavItem>
                <NavLink
                  className={classnames(bem.e("tab-link"), {
                    [bem.e("active")]: activeTab === TAB_INFO
                  })}
                  onClick={() => {
                    setActiveTab(TAB_INFO);
                  }}
                >
                  <FormattedMessage
                    id={"station > tab info"}
                  />
                </NavLink>
              </NavItem>
            )}
            {isVisibleTabPrivileges ? (
              <NavItem>
                <NavLink
                  className={classnames(bem.e("tab-link"), {
                    [bem.e("active")]: activeTab === TAB_PRIVILEGES
                  })}
                  onClick={() => {
                    setActiveTab(TAB_PRIVILEGES);
                  }}
                >
                  <FormattedMessage id="station > tab privileges" />
                </NavLink>
              </NavItem>
            ) : null}
            {isVisibleTabCities ? (
              <NavItem>
                <NavLink
                  className={classnames(bem.e("tab-link"), {
                    [bem.e("active")]: activeTab === TAB_CITIES_GROUPS
                  })}
                  onClick={() => {
                    setActiveTab(TAB_CITIES_GROUPS);
                  }}
                >
                  <FormattedMessage id="station > tab cities groups" />
                </NavLink>
              </NavItem>
            ) : null}
          </Nav>
          <TabContent activeTab={activeTab}>
            {(single || isView === STATION_FORM_MODE_ADD) && (
              <TabPane tabId={TAB_INFO}>
                <Row>
                  <Col>
                    <div className={bem.e("tab-detail")}>
                      <StationForm
                        id={
                          isView === STATION_FORM_MODE_ADD && selectedStation
                            ? null
                            : selectedStation.id
                        }
                        isView={isView}
                        onBack={() => back()}
                        backButton={backButton}
                      />
                    </div>
                  </Col>
                </Row>
              </TabPane>
            )}
            {isVisibleTabPrivileges ? (
              <TabPane tabId={TAB_PRIVILEGES}>
                <Row>
                  <Col>
                    <div className={bem.e("tab-detail")}>
                      <PrivilegesEffective
                        onSubmit={onSubmitPrivileges}
                        backButton={backButton}
                        isLoading={isLoadingPrivileges}
                      />
                    </div>
                  </Col>
                </Row>
              </TabPane>
            ) : null}

            {isVisibleTabCities ? (
              <TabPane tabId={TAB_CITIES_GROUPS}>
                <Row>
                  <Col>
                    <div className={bem.e("tab-detail")}>
                      <CitiesGroups
                        isFocused={activeTab === TAB_CITIES_GROUPS}
                        ids={ids}
                        backButton={backButton}
                      />
                    </div>
                  </Col>
                </Row>
              </TabPane>
            ) : null}
          </TabContent>
        </div>
      </div>
    </div>
  );
};

export default StationsDetails;
