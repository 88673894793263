import React from "react";
import bn from "utils/bemnames";
import { Row, Col, Label, Table, Button } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { ApproveIcon, TrashIcon } from "components/CustomIcons";
import classNames from "classnames";
import { map, find, includes } from "lodash";
import moment from "moment";
import { PhoneNumberFormat } from "utils/config";
import NumberFormat from "react-number-format";
import { categoryList, fulfillmentList } from "utils/config";
const bem = bn.create("winner-details");
function getLocationText(prize) {
  let arr = [];
  if (prize.add1) {
    arr.push(prize.add1);
  }
  if (prize.add2) {
    arr.push(prize.add2);
  }
  if (prize.city) {
    arr.push(prize.city);
  }
  if (prize.state) {
    arr.push(prize.state);
  }
  if (prize.zip) {
    arr.push(prize.zip);
  }
  return arr.join(", ");
}
function getDescription(prize, intl) {
  if (!prize.time_open || !prize.time_close) return null;
  return intl.formatMessage(
    {
      id: "winners > fulfillment details description",
    },
    {
      TIME_CLOSE: prize.time_close,
      TIME_OPEN: prize.time_open,
      CONTACT: prize.contact,
    }
  );
}
const Prize = ({
  prize,
  intl,
  onUpdateFulfilled,
  isDisabledFulfilled,
  isDeadlineNegative,
  dateFormatByServer,
}) => {
  const type = find(categoryList, (item) => item.value === prize.category);
  const fulfillment = find(
    fulfillmentList,
    (item) => item.value === prize.fulfillment
  );
  const isShowFulfilled = prize.status === "fulfilled";
  const isShowFulfillmentDetails = includes(
    ["pickup_station", "pickup_client_provider", "will_call"],
    prize.fulfillment
  );
  return (
    <div className={bem.e("contest-section-box")}>
      <Row>
        <Col xs={6} className={bem.e("col-reset")}>
          <div className={bem.e("contest-group")}>
            <span className={bem.e("prize-title")}>{prize.title}</span>
            <div className={bem.e("prize-title-value")}>{prize.subtitle}</div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={3} className={bem.e("col-reset")}>
          <div className={bem.e("contest-group")}>
            <Label>
              <FormattedMessage id="winners > category" />
            </Label>
            <div className={bem.e("contest-group-value")}>
              {type
                ? `${type.label}`
                : intl.formatMessage({ id: "winners > none" })}
            </div>
          </div>
        </Col>
        <Col xs={3} className={bem.e("col-reset")}>
          <div className={bem.e("contest-group")}>
            <Label>
              <FormattedMessage id="winners > unit value" />
            </Label>
            <div className={bem.e("contest-group-value")}>
              {prize.unit_value
                ? `$${prize.unit_value}`
                : intl.formatMessage({ id: "winners > none" })}
            </div>
          </div>
        </Col>
        <Col xs={3} className={bem.e("col-reset")}>
          <div className={bem.e("contest-group")}>
            <Label>
              <FormattedMessage id="winners > quantity" />
            </Label>
            <div className={bem.e("contest-group-value")}>
              {prize.per_play
                ? `${prize.per_play}`
                : intl.formatMessage({ id: "winners > none" })}
            </div>
          </div>
        </Col>
        <Col xs={3} className={bem.e("col-reset")}>
          <div
            className={classNames(bem.e("contest-group"), bem.e("text-center"))}
          >
            <Label>
              <FormattedMessage id="winners > prize value" />
            </Label>
            <div className={bem.e("prize-value")}>
              {prize.per_play && prize.unit_value
                ? `$${prize.per_play * prize.unit_value}`
                : intl.formatMessage({ id: "winners > none" })}
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={3} className={bem.e("col-reset")}>
          <div className={bem.e("contest-group")}>
            <Label>
              <FormattedMessage id="winners > expiration" />
            </Label>
            <div className={bem.e("contest-group-value")}>
              {prize.expiration_date
                ? moment(prize.expiration_date).format("L")
                : intl.formatMessage({ id: "winners > none" })}
            </div>
          </div>
        </Col>
        <Col xs={3} className={bem.e("col-reset")}>
          <div className={bem.e("contest-group")}>
            <Label>
              <FormattedMessage id="winners > fulfillment" />
            </Label>
            <div className={bem.e("contest-group-value")}>
              {fulfillment
                ? fulfillment.label
                : intl.formatMessage({ id: "winners > none" })}
            </div>
          </div>
        </Col>
        <Col xs={3} className={bem.e("col-reset")}>
          <div className={bem.e("contest-group")}>
            <Label>
              <FormattedMessage id="winners > supplier" />
            </Label>
            <div className={bem.e("contest-group-value")}>
              {prize.supplier
                ? prize.supplier
                : intl.formatMessage({ id: "winners > none" })}
            </div>
          </div>
        </Col>
        <Col xs={3} className={bem.e("col-reset")}>
          <div className={bem.e("contest-button-action")}>
            {prize.fulfilled_at_ts ? (
              <span className={bem.e("time-text-at")}>
                {moment(prize.fulfilled_at_ts * 1000).format(
                  `${dateFormatByServer} hh:mma`
                )}
              </span>
            ) : null}

            <div className={bem.e("contest-button")}>
              {isShowFulfilled ? (
                <Button
                  className="btn btn-radius btn-fulfilled"
                  disabled={isDisabledFulfilled}
                  onClick={() => {}}
                >
                  <ApproveIcon color="#fff" />
                  <FormattedMessage id="winners > fulfilled" />
                </Button>
              ) : (
                <Button
                  outline
                  color="blue"
                  disabled={isDisabledFulfilled}
                  onClick={() => {
                    if (isDisabledFulfilled) return;
                    onUpdateFulfilled(
                      prize,
                      isDeadlineNegative ? "unfulfilled" : "fulfilled"
                    );
                  }}
                  className={classNames("btn btn-radius btn-unfulfilled", {
                    "btn-unfulfilled-danger": isDeadlineNegative,
                  })}
                >
                  {isDeadlineNegative && <TrashIcon color="#E40061" />}
                  {isDeadlineNegative || prize.status === null ? (
                    <FormattedMessage id="winners > unfulfilled" />
                  ) : (
                    <FormattedMessage id="winners > fulfilled" />
                  )}
                </Button>
              )}

              {isShowFulfilled ? (
                <span
                  className={bem.e("btn-undo")}
                  onClick={() => {
                    if (isDisabledFulfilled) return;
                    onUpdateFulfilled(prize, "undo");
                  }}
                >
                  <FormattedMessage id="winners > undo" />
                </span>
              ) : null}
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className={bem.e("col-reset")}>
          <div
            className={classNames(
              bem.e("contest-group-last"),
              bem.e("contest-group")
            )}
          >
            <Label>
              <FormattedMessage id="winners > prize details" />
            </Label>
            <div className={bem.e("contest-prize-text")}>
              {prize.description
                ? prize.description
                : intl.formatMessage({ id: "winners > none" })}
            </div>
          </div>
        </Col>
      </Row>
      {isShowFulfillmentDetails && (
        <Row>
          <Col xs={12} className={bem.e("col-reset")}>
            <Table className={bem.e("fullfilment-table")}>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="winners > fulfillment details" />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Row>
                      <Col xl={3} className={bem.e("col-reset")}>
                        <div className={bem.e("fullfilment-group")}>
                          <Label>
                            <FormattedMessage id="winners > location" />
                          </Label>
                          <span
                            className={classNames(
                              bem.e("fullfilment-value"),
                              bem.e("text-location")
                            )}
                          >
                            {getLocationText(prize)}
                          </span>
                        </div>
                      </Col>
                      <Col xl={3} className={bem.e("col-reset")}>
                        <div className={bem.e("fullfilment-group")}>
                          <Label>
                            <FormattedMessage id="winners > phone" />
                          </Label>
                          <span className={bem.e("fullfilment-value")}>
                            {prize.phone ? (
                              <NumberFormat
                                value={prize.phone}
                                displayType={"text"}
                                format={PhoneNumberFormat}
                              />
                            ) : (
                              <FormattedMessage id="process > none" />
                            )}
                          </span>
                        </div>
                      </Col>
                      <Col xl={3} className={bem.e("col-reset")}>
                        <div className={bem.e("fullfilment-group")}>
                          <Label>
                            <FormattedMessage id="winners > contact" />
                          </Label>
                          <span className={bem.e("fullfilment-value")}>
                            {prize.contact}
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className={bem.e("col-reset")}>
                        <div className={bem.e("fullfilment-group")}>
                          <Label>
                            <FormattedMessage id="winners > notes" />
                          </Label>
                          <span className={bem.e("fullfilment-value")}>
                            {getDescription(prize, intl)}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      )}
    </div>
  );
};
export default function PrizeDetails(props) {
  const { item, intl, onUpdateFulfilled, dateFormatByServer } = props;
  if (item.prizes.length === 0) return null;
  return (
    <div className={bem.e("contest-section")}>
      <div className={bem.e("contest-section-title")}>
        <FormattedMessage id="winners > prize details" />
      </div>
      {map(item.prizes, (prize) => (
        <Prize
          prize={prize || {}}
          intl={intl}
          key={prize.id}
          isDeadlineNegative={props.isDeadlineNegative}
          dateFormatByServer={dateFormatByServer}
          onUpdateFulfilled={(prize, action) => {
            onUpdateFulfilled(
              {
                prize_id: prize.id,
                event_id: item.event_id,
              },
              action
            );
          }}
          isDisabledFulfilled={props.isPast || props.isDeadlineNegative}
        />
      ))}
    </div>
  );
}
