import React, { Component } from "react";
import ComponentFactory from "./componentFactory";
import {
  get,
  pick,
  forOwn,
  has,
  isEmpty,
  includes,
  isEqual,
  isFunction,
  forEach,
  uniq,
} from "lodash";
import { Row, Col, Button } from "reactstrap";
import PrivilegedComponent from "components/PrivilegedComponent";
import history from "components/History";
import URL from "utils/urls";
import classnames from "classnames";
import { UncontrolledTooltip, FormGroup } from "reactstrap";
import { PRIVILEGES } from "utils/constants";
import ConfirmationModal from "components/ConfirmationModal";
import {
  Form,
  SectionTitle,
  SectionFields,
  ChevronWrapper,
} from "components/Elements";
import {
  getEditableTabsByStage,
  getStepForTabEdit,
  getEditableTabByUser,
} from "utils/helpers";
import { DownIcon, UpIcon } from "components/CustomIcons";
import { validatePrivileges, checkAllowSendBack } from "utils/helpers";
import ToastManager from "components/ToastManager";

import { FormattedMessage } from "react-intl";
import SendBackOrderStatusModal from "components/SendBackOrderStatusModal";
import BlockingComponent from "components/BlockingComponent";
class DynamicForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenDialogDelete: false,
      isOpenDialogArchive: false,
      isOpenConfirmUnarchive: false,
      isView:
        document.location.pathname.split("/")[2] === "view" ? true : false,
      isAdd: document.location.pathname.split("/")[2] === "add" ? true : false,
      buttons: true,
      isOpenSections: {
        [props.step.key]: isFunction(props.step.isOpen)
          ? props.step.isOpen(props)
          : true,
      },
      isOpenSendBackOrder: false,
    };
  }

  componentDidMount() {
    this.setState({ buttons: true });
    const { step, process, template, user } = this.props;
    const isTabUndefined = process.lastEditTab in window;
    const lastEditTab = get(process, "lastEditTab", []);
    const privileges = get(user, "privileges", []);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const fromQueue = urlParams.get("source");

    if (
      document.location.href.indexOf("processes/add") === -1 &&
      process.data.fields.key
    ) {
      // Make the order in the List open up in EDIT mode when clicked on, if the User has the EDIT privileges . https://tasks.getventive.com/projects/D6CB3-068
      if (["liner", "contest"].includes(template.key)) {
        const isUserHasEditPrivileges = validatePrivileges({
          requires: step.step_editors_privileges,
          privileges,
          user,
        });
        if (
          document.location.href.indexOf("processes/edit") !== -1 &&
          !isUserHasEditPrivileges
        ) {
          this.switchToView(step, process, template, user);
        }
        return;
      }
      // make the order in the Assignment queue open up in Edit mode when clicked from Assignment queue
      if (fromQueue === "assignments_queue") return;

      if (!isTabUndefined && !includes(lastEditTab, process.activeTab)) {
        // this.switchToView(step, process, template, user);
        if (get(step, "is_open_edit") || this.checkEditStayInOrder()) {
          this.switchToEdit(step, process, template, user);
        } else {
          this.switchToView(step, process, template, user);
        }
      } else if (!isTabUndefined && includes(lastEditTab, process.activeTab)) {
        // switch to view when user completed
        const isUserCompletedStep =
          get(process, `data.fields.${process.activeTab}_status.value`) ===
          "completed";

        if (
          !isUserCompletedStep &&
          get(process, `data.fields.process_step.value`) ===
            process.activeTab &&
          !["order_details", "draft"].includes(process.activeTab)
        ) {
          this.switchToEdit(step, process, template, user);
        } else {
          // this.switchToView(step, process, template, user);
        }
      } else if (!isTabUndefined && includes(lastEditTab, step.key)) {
        this.switchToEdit(step, process, template, user);
      } else if (lastEditTab.length > 0) {
        this.switchToView(step, process, template, user);
      }
    }
  }
  componentDidUpdate(nextProps) {
    this.isChangedFields(this.props, nextProps);
  }
  setIsOpenSections = (section) => {
    this.setState({
      isOpenSections: {
        ...this.state.isOpenSections,
        [section]: !this.state.isOpenSections[section],
      },
    });
  };
  validate = (step, item, process, template, user) => {
    let valid = true;
    let ex_messages = [];
    const { intl } = this.props;
    step.fields.map((item) => {
      let validation_result = true;
      if (
        includes(["liner", "contest"], template.key) &&
        !includes(step.key, item.step)
      )
        return false;
      if (item.validation) {
        let validation = item.validation;
        let value = "";
        if (
          process.data.fields[item.field] &&
          process.data.fields[item.field].value
        )
          value = process.data.fields[item.field].value;

        try {
          const extraData = {
            ...pick(this.props.info, [
              "enable_digital",
              "production_multimarket",
              "enable_production",
              "enable_promotion",
              "enable_wo",
              "stations",
            ]),
          };
          validation_result = validation(
            step,
            item,
            process.data,
            template,
            user,
            value,
            process.key,
            extraData
          );
        } catch (e) {
          console.log(e);
          debugger;
        }
        if (validation_result !== true) {
          valid = false;
          this.setState({
            [item.field]: { validation_error: validation_result },
          });
          if (item.mandatory && !valid) ex_messages.push(item); // throw { item }; // eslint-disable-line
        }
      }
      return false;
    });
    let keyTranslate;
    if (ex_messages.length > 0) {
      let message = "";
      const isShowMessageAsTab = includes(["liner", "contest"], template.key);
      if (
        ex_messages.length === 1 &&
        (ex_messages[0].label ? ex_messages[0].label : ex_messages[0].field)
      ) {
        keyTranslate =
          "process > field " +
          get(ex_messages, "0.field", "").replace(/_/g, " ");
        if (template.key === "liner") {
          keyTranslate = `process > ${template.key} field ${get(
            ex_messages,
            "0.field",
            ""
          ).replace(/_/g, " ")}`;
        }
        message = `${intl.formatMessage({
          id: "process > error please correct",
        })} ${intl.formatMessage({ id: keyTranslate }).toLowerCase()}`;
      } else {
        message = intl.formatMessage({
          id: "process > error please correct the hilighted fields",
        });
      }
      if (isShowMessageAsTab && ex_messages.length > 0) {
        let errorSteps = [];
        forEach(ex_messages, (field) => {
          errorSteps = [...errorSteps, ...field.step];
        });
        const errorTabs = uniq(errorSteps)
          .map((stepKey) => {
            return intl.formatMessage({
              id: `process > title ${template.key} step ${stepKey}`,
            });
          })
          .join(", ");
        message = `${errorTabs} ${intl.formatMessage({
          id: "process > has errors",
        })}`;
      }
      let error = { message };
      throw error;
    }
    return valid;
  };

  clearValidationError = (item) => {
    return this.setState({ [item.field]: { validation_error: "" } });
  };

  setValidationError = (item, error) => {
    return this.setState({ [item.field]: { validation_error: error } });
  };

  clearAllValidationError = () => {
    let validationState = {};
    forOwn(this.state, (value, key) => {
      if (has(value, "validation_error") && value.validation_error) {
        validationState = {
          ...validationState,
          [key]: { validation_error: "" },
        };
      }
    });
    if (!isEmpty(validationState)) {
      this.setState({
        ...validationState,
      });
    }
  };

  saveFormValues = (payload) => {
    const { process } = this.props;
    return this.props.saveFormValues(payload, () => {
      // only reset block navigation when action is unsaved. Other if action is Trafficked (Traffic Tab on Digital Order) we have to keep the should block
      if (this.props.blockNavigationAction === "unsaved") {
        this.props.setShouldBlockNavigation(false);
      }
      this.props.setIsShowClearForm(false);
      if (
        ["liners", "contest"].indexOf(
          get(process, "data.fields.template_key.value")
        )
      ) {
        this.props.setSchedulingType(
          get(process, "data.fields.scheduling_type.value", "")
        );
      }
      if (
        ["liners", "contest"].indexOf(
          get(process, "data.fields.template_key.value")
        ) &&
        this.props.isScheduleAvailabilityChecked
      ) {
        this.props.resetCheckSchedulingAvailability();
      }
    });
  };

  switchToEdit = (step, process, template, user) => {
    const { match_process_id, info } = this.props; // need to user process id from params. Cant use from process store redux. Because the data until update for new process id.
    let stepKey = getStepForTabEdit(step, process, user, info);
    this.props.setActiveEditTab(stepKey);
    this.setState({ isView: false });
    if (
      document.location.href.indexOf("/processes/edit/") === -1 &&
      match_process_id
    ) {
      history.replace(
        URL.UPDATE_PROCESS({
          template_id: process.data.fields.template_key.value,
          process_id: match_process_id,
        })
      );
    }
  };
  switchToView = (step, process, template, user) => {
    const { match_process_id } = this.props; // need to user process id from params. Cant use from process store redux. Because the data until update for new process id.
    this.setState({ isView: true });
    if (
      document.location.href.indexOf("/processes/view/") === -1 &&
      match_process_id
    )
      history.replace(
        URL.VIEW_PROCESS({
          template_id: process.data.fields.template_key.value,
          process_id: match_process_id,
        })
      );
  };

  isChangedFields = (props, nextProps) => {
    let isChanged = false;
    let isChangedProcess =
      get(props, "match_process_id") !== get(nextProps, "match_process_id");
    if (isChangedProcess) {
      return isChanged;
    }
    const ignoreTemplates = ["liner", "contest"]; // disable to check unsaved by fields. We will detect the changes if you select or type the input
    const template = get(nextProps, "process.data.fields.template_key.value");
    if (ignoreTemplates.indexOf(template) !== -1) return;
    forOwn(nextProps.process.data.fields, (field, key) => {
      const currentValue = get(props, `process.data.fields.${key}.value`);
      const nextValue = field.value;
      const nextIsDirty = get(field, `is_dirty`, false);
      switch (key) {
        case "contract_stations":
          isChanged =
            typeof currentValue === "undefined" &&
            get(nextValue, "length") === 0
              ? false
              : !isEqual(currentValue, nextValue) && nextIsDirty;
          break;
        case "order_stations":
          isChanged =
            typeof currentValue === "undefined" &&
            get(nextValue, "length") === 0
              ? false
              : !isEqual(currentValue, nextValue) && nextIsDirty;
          break;
        case "order_rotation":
          isChanged =
            typeof currentValue === "undefined" && nextValue === 100
              ? false
              : !isEqual(currentValue, nextValue) && nextIsDirty;
          break;
        case "script":
          // isChanged = false;
          isChanged =
            typeof currentValue === "undefined" && nextValue === "<p><br></p>"
              ? false
              : !isEqual(currentValue, nextValue) && nextIsDirty;
          break;
        case "spot_info_fields_dirty":
        case "template_key":
          isChanged = false;
          break;
        case "recording_feedback":
          const recordingFieldKeys = [
            "feedback",
            "recording_audit",
            "recording_path",
          ];
          isChanged =
            !isEqual(
              pick(currentValue, recordingFieldKeys),
              pick(nextValue, recordingFieldKeys)
            ) && nextIsDirty;
          break;
        case "spot_info":
          isChanged = nextIsDirty;
          break;
        default:
          isChanged = !isEqual(currentValue, nextValue) && nextIsDirty;
          break;
      }
      const isView =
        document.location.pathname.split("/")[2] === "view" ? true : false;
      if (isChanged && !isView) {
        if (key !== "order_sold" && !this.props.isShowClearForm) {
          this.props.setIsShowClearForm(true);
        }
        return false;
      }
    });
    return;
  };
  onFormValueChanged = (values) => {
    this.props.formValueChanged(values);
  };

  checkEditStayInOrder = () => {
    const { user, step, process, template, info } = this.props;
    // As always, after an order is submitted, it should always open in read mode, forcing the user to click edit to make changes.
    if (step.key === "order_details" && get(process, "data.fields.key.value")) {
      return false;
    }
    const isTabCanEditByStage = getEditableTabsByStage(step, process, user);
    const {
      isAssigned,
      isEditor,
      // isUserCompletedStep,
      // isUserAssignedToStepRoles,
    } = getEditableTabByUser({
      step,
      process,
      user,
      template: template.key,
      userInfo: info,
    });
    const isTabCanEditByUser = isAssigned && isEditor;
    let found = false;
    if (
      isTabCanEditByStage &&
      isTabCanEditByUser // case when user go to the tab from current tasks
    ) {
      // case when user assigned to step by role and completed this step
      found = true;
    }
    // When a user has 'STAY IN ORDER' turned on, edit mode should stay on by default so the user doesn't have to hit the 'Edit' button to make changes
    return found && !user.return_to_current_tasks;
  };

  checkIsShowEditButton = () => {
    const { user, step, process, template, info } = this.props;
    const isMultiMarket = get(
      process,
      "data.fields.is_multi_market.value",
      false
    );
    const assignTeamStatus = get(
      process,
      "data.fields.assign_team_status.value"
    );
    let { isView, isAdd } = this.state;
    isView = document.location.pathname.split("/")[2] === "view" ? true : false;

    const isViewEffect =
      document.location.pathname.split("/")[2] === "view" ? true : false;

    if (isViewEffect && !isView) {
      this.switchToView(step, process, template, user);
      isView = true;
    }
    let isContinuityMultipleMarket = false;
    // Continuity should be able to perform its task as soon as Assignments is done
    if (
      isMultiMarket &&
      assignTeamStatus === "completed" &&
      step.key === "continuity"
    ) {
      isContinuityMultipleMarket = true;
    }

    let no_edit_button = step.no_edit_button;
    const isTabCanEditByStage = getEditableTabsByStage(step, process, user);

    if (!no_edit_button && step.key !== "log") {
      let found = false;
      // Flag for check current use can edit step he submitted
      const {
        isAssigned,
        isEditor,
        isUserCompletedStep,
        isUserAssignedToStepRoles,
      } = getEditableTabByUser({
        step,
        process,
        user,
        template: template.key,
        userInfo: info,
      });

      const isTabCanEditByUser = isAssigned && isEditor;
      if (
        (isTabCanEditByStage && isTabCanEditByUser) || // case when user go to the tab from current tasks
        (isEditor && isUserAssignedToStepRoles && isContinuityMultipleMarket) || // case continuity should be able to perform its task as soon as assignments is done
        (isEditor && isUserCompletedStep)
      ) {
        // case when user assigned to step by role and completed this step
        found = true;
      }
      if (!found) {
        no_edit_button = true;
        if (
          !isViewEffect &&
          !isView &&
          process.data.fields &&
          process.data.fields.key &&
          process.data.fields.template_key
        ) {
          this.switchToView(step, process, template, user);
          isView = true;
        }
      }
    }

    const processStep = get(
      process,
      "data.fields.process_step.value",
      "order_details"
    );

    if (
      get(template, "key", "basic_production") === "digital" &&
      processStep !== "report" &&
      processStep !== "online" &&
      step.key === "report"
    ) {
      no_edit_button = true;
    }

    let isShowEditButton = isView && !isAdd && !no_edit_button;
    return isShowEditButton;
  };

  onUnArchive = () => {
    const processKey = get(this.props, "process.data.fields.key.value", "");
    this.props.unarchiveProcess(processKey, () => {
      ToastManager.show({
        message: this.props.intl.formatMessage({
          id: "toast > title saved",
        }),
        level: "success",
      });
    });
  };

  /*disableEditButton(){

    const { step, process, template } = this.props;

    // exclude digital orders from this logic

    if(get(template, "key", "basic_production") === 'digital') return false;

    const processStep = get(process, 'data.fields.process_step.value', "order_details");

    if(processStep === 'online' || processStep === 'done' || !get(process, 'data.fields.active.value', 1)){
      return true;
    }

    if(get(template, "key", "basic_production") === 'digital' && processStep === 'traffic' && step.key === 'design'){
      return true;
    }
    
    const activeTab = find(get(template, "steps", []), {'key' : processStep});
    const activeTabIndex = get(activeTab, "process_step_index", 1);

    return get(step, "process_step_index") > activeTabIndex && !(get(template, "key", "basic_production") !== 'digital' && step.key === 'continuity' && activeTabIndex >= 3);
  }*/

  renderEditButton() {
    const { step, process, template, user } = this.props;
    let no_edit_button = step.no_edit_button;
    const isShowEditButton = this.checkIsShowEditButton();
    const isEditEffect =
      document.location.pathname.split("/")[2] === "edit" ? true : false;
    if (isShowEditButton && step.is_hidden_edit_button) {
      no_edit_button = step.is_hidden_edit_button(user, process, step);
    }
    if (no_edit_button || isEditEffect) return null;
    const isDisabled = !isShowEditButton;
    const isShowDeleteButton =
      ["order_details"].indexOf(step.key) !== -1 &&
      template.key === "basic_production";

    return (
      <React.Fragment>
        <Button
          disabled={isDisabled}
          id="edit-button-row"
          color="blue"
          className="button-edit button-edit-auto"
          onClick={() => {
            this.switchToEdit(step, process, template, user);
          }}
        >
          <FormattedMessage id="process > button edit" />
        </Button>
        {isDisabled && (
          <UncontrolledTooltip
            placement="top-start"
            delay={0}
            target="edit-button-row"
          >
            <FormattedMessage id="process > button edit disabled" />
          </UncontrolledTooltip>
        )}
        {isShowDeleteButton && (
          <PrivilegedComponent
            requires={{
              or: [
                PRIVILEGES.DELETE_PRODUCTION,
                PRIVILEGES.EDIT_ALL_PRODUCTION_ORDERS,
              ],
            }}
          >
            <Button
              color="link"
              outline
              className="btn-link"
              onClick={() =>
                this.setState({
                  isOpenDialogDelete: true,
                })
              }
            >
              <FormattedMessage id={`process > button delete`} />
            </Button>
          </PrivilegedComponent>
        )}
      </React.Fragment>
    );
  }
  renderArchiveButton() {
    const { step, process, template } = this.props;

    const isShowUnarchive = get(
      process,
      "data.fields.is_archived.value",
      false
    );

    const showArchiveButton = !!(
      ["basic_production", "production_provided"].indexOf(template.key) !==
        -1 &&
      !isShowUnarchive &&
      step.key === "continuity" &&
      get(process, "data.fields.key.value")
    );

    if (showArchiveButton) {
      return (
        <PrivilegedComponent requires={[PRIVILEGES.ARCHIVE_PRODUCTION]}>
          <Button
            color="link"
            outline
            className="btn-link"
            onClick={() =>
              this.setState({
                isOpenDialogArchive: true,
              })
            }
          >
            <FormattedMessage id={`process > button archive`} />
          </Button>
        </PrivilegedComponent>
      );
    }
    if (isShowUnarchive) {
      return (
        <PrivilegedComponent requires={[PRIVILEGES.ARCHIVE_PRODUCTION]}>
          <Button
            color="link"
            outline
            className="btn-link"
            onClick={() =>
              this.setState({
                isOpenConfirmUnarchive: true,
              })
            }
          >
            <FormattedMessage id={`process > button unarchive`} />
          </Button>
        </PrivilegedComponent>
      );
    }
    return null;
  }
  renderSendBackButton() {
    const { step, template, user, process } = this.props;
    if (step.key === "order_details") return null;
    let isAdd =
      document.location.pathname.split("/")[2] === "add" ? true : false;
    const showButton =
      ["basic_production", "production_provided"].indexOf(template.key) !==
        -1 && !isAdd;
    const isValidPrivileges = checkAllowSendBack({ process, user });
    if (showButton && isValidPrivileges) {
      return (
        <Button
          color="link"
          outline
          className="btn-radius btn-send-back"
          onClick={() =>
            this.setState({
              isOpenSendBackOrder: true,
            })
          }
        >
          <FormattedMessage id={`process > button send back`} />
        </Button>
      );
    }
    return null;
  }
  render() {
    let step = this.props.step;
    let process = this.props.process;
    let template = this.props.template;
    // let formValueChanged = this.props.formValueChanged;
    let isAdd =
      document.location.pathname.split("/")[2] === "add" ? true : false;
    let isView =
      document.location.pathname.split("/")[2] === "view" ? true : false;
    let user = this.props.user;
    let info = this.props.info;
    let ui_hint = this.props.ui_hint;
    let tab_toggle = this.props.tab_toggle;
    let validate = this.validate.bind(this);
    let clearValidationError = this.clearValidationError.bind(this);
    let clearAllValidationError = this.clearAllValidationError.bind(this);
    let saveFormValues = this.saveFormValues.bind(this);
    if (ui_hint) {
      return (
        <ComponentFactory
          ui_hint={ui_hint}
          formValueChanged={this.onFormValueChanged}
          user={user}
          info={info}
          process={process}
          template={template}
          tab_toggle={tab_toggle}
          validate={validate}
          clearValidationError={clearValidationError}
          clearAllValidationError={clearAllValidationError}
          saveFormValues={saveFormValues}
          validationState={this.state}
          isShowClearForm={this.props.isShowClearForm}
          setIsShowClearForm={this.props.setIsShowClearForm}
          shouldBlockNavigation={this.props.shouldBlockNavigation}
          setShouldBlockNavigation={this.props.setShouldBlockNavigation}
          onPreparePage={this.props.onPreparePage}
          setSidebarActive={this.props.setSidebarActive}
          setActiveEditTab={this.props.setActiveEditTab}
          setActiveTab={this.props.setActiveTab}
          submitRefFunc={this.props.submitRefFunc}
          setValidationError={this.setValidationError}
          deleteProcess={this.props.deleteProcess}
          archiveProcess={this.props.archiveProcess}
          blockNavigationAction={this.props.blockNavigationAction}
          setBlockNavigationAction={this.props.setBlockNavigationAction}
          dateFormatByServer={this.props.dateFormatByServer}
          confirmationDialogProps={this.props.confirmationDialogProps}
          setConfirmationDialogProps={this.props.setConfirmationDialogProps}
        />
      );
    }
    const ignoreSteps = ["record_script"];
    const ignoreTemplates = ["liner", "contest"]; // disable validate when user click on check availability
    if (
      this.props.match_process_id &&
      !this.state.validated &&
      this.props.process.data.fields[step.key + "_status"] &&
      this.props.process.data.fields[step.key + "_status"].value === "draft" &&
      ignoreSteps.indexOf(step.key) === -1 &&
      ignoreTemplates.indexOf(template.key) === -1
    ) {
      this.setState({ validated: true });
      let valid = false;
      try {
        valid = this.validate(step, null, process, template, user);
      } catch (e) {}
      if (!valid) {
        let tab_index = 0;
        Object.keys(template.steps).map((key, ind) => {
          if (key === step.key) tab_index = ind;
          return true;
        });
        tab_toggle(tab_index);
      }
    }
    let stepContainer = get(step, "step_container");
    let all_fields = {};
    let component_index = 0;
    Object.keys(step.fields).map((key, ind) => {
      let item = step.fields[key];
      let item_container = "main";
      if (item.container) item_container = item.container;
      if (!all_fields[item_container]) all_fields[item_container] = [];

      if (!item.component) return null;
      if (item.is_hidden && item.is_hidden(user, process, step, isView))
        return null;
      let component = item.component && (
        <ComponentFactory
          component_index={component_index}
          formValueChanged={this.onFormValueChanged}
          user={user}
          info={info}
          process={process}
          template={template}
          step={step}
          item={item}
          tab_toggle={tab_toggle}
          validate={validate}
          clearValidationError={clearValidationError}
          clearAllValidationError={clearAllValidationError}
          validation_error={
            this.state[item.field] && this.state[item.field].validation_error
          }
          saveFormValues={saveFormValues}
          validationState={this.state}
          isFieldsChanged={this.props.isFieldsChanged}
          isOpenSections={this.state.isOpenSections}
          setIsOpenSections={this.setIsOpenSections}
          isShowClearForm={this.props.isShowClearForm}
          setIsShowClearForm={this.props.setIsShowClearForm}
          shouldBlockNavigation={this.props.shouldBlockNavigation}
          setShouldBlockNavigation={this.props.setShouldBlockNavigation}
          onPreparePage={this.props.onPreparePage}
          setSidebarActive={this.props.setSidebarActive}
          setActiveEditTab={this.props.setActiveEditTab}
          setActiveTab={this.props.setActiveTab}
          submitRefFunc={this.props.submitRefFunc}
          setValidationError={this.setValidationError}
          deleteProcess={this.props.deleteProcess}
          archiveProcess={this.props.archiveProcess}
          blockNavigationAction={this.props.blockNavigationAction}
          setBlockNavigationAction={this.props.setBlockNavigationAction}
          dateFormatByServer={this.props.dateFormatByServer}
          confirmationDialogProps={this.props.confirmationDialogProps}
          setConfirmationDialogProps={this.props.setConfirmationDialogProps}
        />
      );
      component_index++;
      let row;
      if (item.label && item.component) {
        row = (
          <Row key={"key_" + ind}>
            <Col xs={4} className={"m-0 text-truncate"}>
              {item.label}
            </Col>
            <Col className={"w-50"}>{component}</Col>
          </Row>
        );
      } else if (item.label) {
        row = (
          <Row key={"key_" + ind}>
            <Col colSpan={2} className={"w-50"}>
              {item.label}
            </Col>
          </Row>
        );
      } else {
        row = (
          <Row key={"key_" + ind}>
            <Col colSpan={2}>{component}</Col>
          </Row>
        );
      }
      if (item_container === "main") all_fields[item_container].push(row);
      else all_fields[item_container].push(component);
      return true;
    });
    let buttons;
    if (this.state.buttons) {
      buttons = all_fields.bottom_buttons;
    }
    let form = (
      <div
        className={classnames(
          {
            "form-edit-mode": !isView,
            "form-view-mode": isView,
          },
          `form-tab-${step.key}`
        )}
      >
        {/* top */}
        {all_fields.top_container && all_fields.top_container}
        {/* middle */}
        {stepContainer !== "none" ? (
          <Form {...pick(step, ["className"])}>
            {step.description && (
              <SectionTitle
                onClick={() => {
                  if (step.isCollapsible) {
                    this.setIsOpenSections(step.key);
                  }
                }}
              >
                {step.description}
                {step.isCollapsible ? (
                  <ChevronWrapper>
                    {get(this.state, `isOpenSections.${step.key}`, false) ? (
                      <UpIcon />
                    ) : (
                      <DownIcon />
                    )}
                  </ChevronWrapper>
                ) : null}
              </SectionTitle>
            )}
            <SectionFields
              className={
                !get(this.state, `isOpenSections.${step.key}`, false) &&
                "hidden"
              }
            >
              {all_fields.main}
            </SectionFields>
          </Form>
        ) : (
          <div>
            {step.description && <h3 className="mb-5">{step.description}</h3>}
            <div className="panel panel-default p50 uth-panel">
              {all_fields.main}
            </div>
          </div>
        )}
        {all_fields.segment &&
          all_fields.segment.map((field, index) => {
            let fieldValue =
              field.props.process.data.fields[field.props.item.field];
            const isSpot = get(field, "props.item.isSpot", false);
            /* get field value from spot linked */
            if (isSpot) {
              const spotInfo = get(
                process,
                "data.fields.spot_info.value",
                []
              ).find((spot) => spot.key === process.key);
              fieldValue = get(spotInfo, field.props.item.field);
            }

            let sectionHeader = field.props.item.title;

            let isOpen = get(this.state, `${field.props.item.field}`, true);

            if (
              isFunction(field.props.item.isOpen) &&
              get(this.state, `${field.props.item.field}`, null) === null
            ) {
              isOpen = field.props.item.isOpen(process, isView);
            }
            const isShowSection =
              (fieldValue ||
                field.props.item.field === "recording_path" ||
                field.props.item.field === "script") &&
              !get(field, "props.item.props.isOwnSegment", false);
            return isShowSection ? (
              <PrivilegedComponent
                requires={get(field, "props.item.segment_privileges", [])}
                key={index}
              >
                <div
                  className={classnames(
                    "segment-container",
                    get(field, "props.item.props.classNameSegment")
                  )}
                >
                  {get(field, "props.item.props.separateSegment", true) && (
                    <div className="separate-segment" />
                  )}
                  <Form>
                    <SectionTitle
                      className="promotion-section-title"
                      onClick={() => {
                        if (field.props.item.isCollapsible === false) return;
                        this.setState({
                          [field.props.item.field]: !isOpen,
                        });
                      }}
                    >
                      {isFunction(sectionHeader)
                        ? sectionHeader(process)
                        : sectionHeader}
                      {field.props.item.isCollapsible !== false && (
                        <ChevronWrapper>
                          {isOpen ? <UpIcon /> : <DownIcon />}
                        </ChevronWrapper>
                      )}
                      {field.props.item.segmentRightSectionTitleComponent && (
                        <div className="segment-right-section-title">
                          {field.props.item.segmentRightSectionTitleComponent(
                            user,
                            process,
                            step
                          )}
                        </div>
                      )}
                    </SectionTitle>
                    <SectionFields
                      className={classnames(
                        {
                          hidden:
                            !isOpen && field.props.item.isCollapsible !== false,
                        },
                        "section-fields"
                      )}
                    >
                      {field}
                    </SectionFields>
                  </Form>
                </div>
              </PrivilegedComponent>
            ) : get(field, "props.item.props.isOwnSegment", false) ? (
              <div
                key={`${index}-own-container`}
                className={classnames(
                  "segment-own-container",
                  get(field, "props.item.props.classNameSegment")
                )}
              >
                {field}
              </div>
            ) : null;
          })}
        {/* bottom */}
        {all_fields.bottom_container && (
          <div>
            <br />
            <Form>
              <SectionFields>{all_fields.bottom_container}</SectionFields>
            </Form>
          </div>
        )}
        <div
          className={classnames(
            "process-bottom-buttons",
            `${step.className}-bottom-buttons`
          )}
        >
          <FormGroup>
            <div className="process-buttons-left">
              {this.renderEditButton()}
              {!isAdd && buttons}
              {this.renderArchiveButton()}
              {this.renderSendBackButton()}
            </div>
          </FormGroup>
        </div>
        {/* delete modal */}
        <ConfirmationModal
          isOpen={this.state.isOpenDialogDelete}
          onToggle={() =>
            this.setState({
              isOpenDialogDelete: !this.state.isOpenDialogDelete,
            })
          }
          centered
          onCancel={() => this.setState({ isOpenDialogDelete: false })}
          title={<FormattedMessage id="process > confirm delete this spot" />}
          className="modal-delete"
          onConfirm={() => {
            this.setState({ isOpenDialogDelete: false });
            this.props.deleteProcess(
              get(process, "data.fields.key.value"),
              () => {
                const currentTasks = URL.PROCESSES({
                  filter: "my_action_items",
                });
                const nextRoute = this.props.sidebarActive
                  ? this.props.sidebarActive
                  : currentTasks;
                history.push(nextRoute);
              }
            );
          }}
          isCloseOutside={false}
        />
        {/* archive modal */}
        <ConfirmationModal
          centered
          isOpen={this.state.isOpenDialogArchive}
          onToggle={() =>
            this.setState({
              isOpenDialogArchive: !this.state.isOpenDialogArchive,
            })
          }
          onCancel={() => this.setState({ isOpenDialogArchive: false })}
          title={<FormattedMessage id="process > confirm archive this order" />}
          description={
            <FormattedMessage id="process > confirm archive this order description" />
          }
          onConfirm={() => {
            this.setState({ isOpenDialogArchive: false });
            this.props.archiveProcess(
              get(process, "data.fields.key.value"),
              () => {
                ToastManager.show({
                  message: this.props.intl.formatMessage({
                    id: "toast > title saved",
                  }),
                  level: "success",
                });
              }
            );
          }}
          isCloseOutside={false}
          confirmTitle={<FormattedMessage id="process > button yes" />}
          cancelTitle={<FormattedMessage id="process > button cancel" />}
        />
        {/* send back modal */}
        <SendBackOrderStatusModal
          isOpen={this.state.isOpenSendBackOrder}
          process={process}
          onToggle={() =>
            this.setState({
              isOpenSendBackOrder: !this.state.isOpenSendBackOrder,
            })
          }
          step={step}
          onSuccessCallback={() => {
            this.setState({ isOpenSendBackOrder: false });
            this.props.setConfirmationDialogProps({
              title: <FormattedMessage id="process > title submitted" />,
              state: "success",
              onCancel: () => this.props.setConfirmationDialogProps(null),
              onToggle: () => {
                this.setState({ confirmationDialogProps: null });
              },
            });
          }}
        />
        <ConfirmationModal
          centered
          isOpen={this.state.isOpenConfirmUnarchive}
          title={
            <FormattedMessage id="process > title ready to unarchive this order" />
          }
          description={
            <FormattedMessage id="process > description unarchive this order" />
          }
          confirmTitle={<FormattedMessage id="process > button yes" />}
          cancelTitle={<FormattedMessage id="process > button cancel" />}
          onConfirm={() => {
            this.setState({
              isOpenConfirmUnarchive: false,
            });
            this.onUnArchive();
          }}
          onCancel={() => {
            this.setState({
              isOpenConfirmUnarchive: false,
            });
          }}
          onToggle={() =>
            this.setState({
              isOpenConfirmUnarchive: false,
            })
          }
          isCloseOutside={false}
        />
      </div>
    );
    // For the traffic tab of Digital order -> we will show other blocking modal
    const reminderModalProps =
      this.props.blockNavigationAction === "trafficked"
        ? {
            className: "placement-confirmation-modal",
            title: this.props.intl.formatMessage({
              id: "process > placement confirmation required",
            }),
            subtitle: this.props.intl.formatMessage({
              id: "process > order will remain in your current tasks",
            }),
            discardTitle: this.props.intl.formatMessage({
              id: "process > button okay",
            }),
            isShowConfirm: false,
            isShowDiscard: true,
            isShowCancel: false,
          }
        : {
            isShowConfirm: true,
            isShowDiscard: false,
            isCancelConfirmedNavigation: true,
            onCancel: () => {
              this.props.setShouldBlockNavigation(false); // reset
            },
          };
    return (
      <React.Fragment>
        {form}
        <BlockingComponent
          isBlocked={this.props.shouldBlockNavigation}
          modalProps={reminderModalProps}
          callBackDismissBlockNavigation={
            this.props.callBackDismissBlockNavigation
          }
        />
      </React.Fragment>
    );
    // return (
    //   <WarnAboutUnsavedChanges
    //     when={this.props.shouldBlockNavigation}
    //     children={form}
    //     callBackDismiss={this.props.callBackDismissBlockNavigation}
    //     isDisableSwitchToTargetLocation={get(this.props, 'location.state.origin_process_key')}
    //   />
    // );
  }
}

export default DynamicForm;
