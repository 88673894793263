import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { get, find, map, sum, pick, truncate, uniqBy } from "lodash";
import { Label } from "reactstrap";
import Tooltip from "rc-tooltip";
import Dropdown from "components/Dropdown";
import NumberFormat from "react-number-format";
import {
  ShadowBox,
  Asterisk,
  SectionTitle,
  SectionFields,
} from "components/Elements";
import { DownIcon, UpIcon, DeleteIcon } from "components/CustomIcons";
import { fulfillmentList } from "utils/config";
import ToastManager from "components/ToastManager";
import PrizeCloset from "../PrizeCloset";

const validKeysPrize = [
  "active",
  "add1",
  "add2",
  "allocation",
  "available",
  "category",
  "city",
  "contact",
  "description",
  "digital_link",
  "expiration_date_ts",
  "expiration_date",
  "fulfillment",
  "id",
  "key",
  "max_per_winner",
  "name",
  "office_days",
  "on_hold_quantity",
  "phone",
  "phone",
  "quantity",
  "state",
  "subtitle",
  "supplier",
  "time_close",
  "time_open",
  "title",
  "total_cash",
  "unit_value",
  "winner_instructions",
  "zip",
];
const SelectedItem = (props) => {
  const {
    bem,
    item,
    onDelete,
    intl,
    playsNumber,
    onUpdate,
    isView,
    validationState,
  } = props;
  const perOptions = [...Array(playsNumber || 1).keys()].map((item) => ({
    value: item + 1,
    label: item + 1,
  }));
  const fulfillment = fulfillmentList.find((i) => i.value === item.fulfillment);
  const maxDescriptionLength = 120;
  const originalDescription = get(item, "description", "") || "";
  const description = truncate(originalDescription, {
    length: maxDescriptionLength,
  });
  const isTruncated = originalDescription.length > maxDescriptionLength;
  return (
    <div className={bem.e("selected-prize-package")}>
      <div className={bem.e("selected-prize-padding")}>
        {!isView && (
          <span className={bem.e("trash")} onClick={() => onDelete(item)}>
            <DeleteIcon color="#795AFA" />
          </span>
        )}

        <h2 className={bem.e("selected-prize-title")}>{item.title}</h2>
        <span className={bem.e("selected-prize-subtitle")}>
          {item.subtitle}
        </span>
        <div className={bem.e("selected-prize-value")}>
          <FormattedMessage id={"process > promotion > contest > value"} />$
          {item.unit_value}
        </div>
        <div className={bem.e("selected-prize-description")}>
          {isTruncated ? (
            <Tooltip
              placement="top"
              trigger={["click", "hover"]}
              overlayClassName={bem.e("tooltip")}
              overlay={
                <div className={bem.e("tooltip-content")}>
                  {originalDescription}
                </div>
              }
            >
              <div className={bem.e("tooltip-content")}>{description}</div>
            </Tooltip>
          ) : (
            description
          )}
        </div>
        <div className={bem.e("selected-prize-row")}>
          {isView ? (
            <div className={bem.e("selected-prize-item")}>
              <Label>
                <FormattedMessage id="process > promotion > contest > per play" />
              </Label>
              <span className={"order-view-value"}>{item.per_play}</span>
            </div>
          ) : (
            <Dropdown
              name="per_play"
              label={
                <>
                  <FormattedMessage id="process > promotion > contest > per play" />
                  <Asterisk>*</Asterisk>
                </>
              }
              className={bem.e("per-play-dropdown")}
              onChange={(selectedOption) => {
                const perPlay = get(selectedOption, "value", 1);
                onUpdate({
                  ...item,
                  per_play: perPlay,
                  total_allotment: perPlay * playsNumber,
                  total_value: perPlay * item.unit_value,
                });
              }}
              value={perOptions.find((i) => i.value === item.per_play)}
              options={perOptions}
            />
          )}
          {isView ? (
            <div className={classnames(bem.e("selected-prize-item"), "px-2")}>
              <Label>
                <FormattedMessage id="process > promotion > contest > fulfillment" />
              </Label>
              <span className={"order-view-value"}>
                {get(fulfillment, "label", "")}
              </span>
            </div>
          ) : (
            <Dropdown
              name="fulfillment"
              label={
                <>
                  <FormattedMessage id="process > promotion > contest > fulfillment" />
                  <Asterisk>*</Asterisk>
                </>
              }
              className={bem.e("fulfillment-dropdown")}
              placeholder={intl.formatMessage({
                id: "process > promotion > contest > select",
              })}
              onChange={(selectedOption) => {
                const fulfillment = get(selectedOption, "value", "");
                onUpdate({
                  ...item,
                  fulfillment,
                });
              }}
              value={fulfillment}
              options={fulfillmentList}
            />
          )}

          <div className={bem.e("total-value")}>
            <Label>
              <FormattedMessage
                id={"process > promotion > contest > total value"}
              />
            </Label>
            <strong>${item.total_value}</strong>
          </div>
        </div>
        {get(validationState, "total_allotment") ? (
          <span className="alert-danger">
            {validationState.total_allotment}
          </span>
        ) : null}
        {get(validationState, "total_value") ? (
          <span className="alert-danger">{validationState.total_value}</span>
        ) : null}
      </div>
      <div className={bem.e("selected-prize-footer")}>
        <div>
          <FormattedMessage id="process > promotion > contest > plays" />:
          <strong>{playsNumber}</strong>
        </div>
        <div>
          <FormattedMessage id="process > promotion > contest > total allotment" />
          :<strong>{item.total_allotment}</strong>
        </div>
      </div>
    </div>
  );
};
const PrizePackage = (props) => {
  const {
    validationState,
    process,
    bem,
    step,
    onValueChanged,
    intl,
    isView,
    isOpenDetails,
    setIsOpenDetails,
    sectionKey,
  } = props;
  const [fieldRefs, setFieldRefs] = useState(null);
  const getFieldRefs = () => {
    if (fieldRefs) return fieldRefs;

    const prizes = step.fields.find((f) => f.field === "grand_prizes");
    const payload = {
      prizes,
    };

    setFieldRefs(payload);

    return payload;
  };
  const { prizes } = getFieldRefs();

  const theme = get(process, "data.fields.theme.value", "");
  const isQualifyingGrandPrizeTheme = theme === "qualifying_with_grand_prize";
  const selectedPrizes = get(process, "data.fields.grand_prizes.value", "");
  const playsNumber = get(process, "data.fields.plays_number.value")
    ? parseInt(get(process, "data.fields.plays_number.value"))
    : 0;

  const onSelectItem = (toReplace) => (item) => {
    if (isView) return;
    const hasNewPrize = find(selectedPrizes, { key: "_new" });
    let newSelectedPrizes = [];
    if (hasNewPrize || toReplace) {
      newSelectedPrizes = map(selectedPrizes, (prize) => {
        if (prize.key === "_new" || prize.key === get(toReplace, "key")) {
          return item;
        }
        return prize;
      });
    } else {
      newSelectedPrizes = uniqBy(
        [
          ...selectedPrizes,
          {
            ...pick(item, validKeysPrize),
            per_play: 1,
            total_allotment: playsNumber,
            total_value: item.unit_value,
          },
        ],
        "key"
      );
    }
    onValueChanged(prizes, newSelectedPrizes);
    ToastManager.show({
      title: props.intl.formatMessage({
        id: "toast > title added",
      }),
      level: "success",
    });
  };

  const getPrizePackageTitle = () => {
    return "process > promotion > contest > title grand prize package";
  };

  const renderPrizeCloset = (prize) => {
    if (isView) return null;
    return (
      <PrizeCloset
        process={process}
        isView={isView}
        onSelectItem={onSelectItem(prize)}
      />
    );
  };

  const renderRegularPrizePackage = () => {
    const showPrizeStats = [isQualifyingGrandPrizeTheme].filter(Boolean).length;
    const totalValue =
      get(selectedPrizes, "length", 0) > 0
        ? sum(map(selectedPrizes, "total_value"))
        : 0;
    return (
      <>
        <ShadowBox className={bem.e("box-prize-details")}>
          <SectionTitle
            className={bem.e("contest-prize-section-title")}
            onClick={() =>
              setIsOpenDetails(isOpenDetails === sectionKey ? null : sectionKey)
            }
          >
            <div className={bem.e("box-head-title")}>
              <FormattedMessage id={getPrizePackageTitle()} />
            </div>
            <div className={classnames("d-flex", bem.e("right-section"))}>
              {!!showPrizeStats && (
                <>
                  <div className={bem.e("price-total")}>
                    <FormattedMessage id="prizes > total" />
                  </div>
                  <div className={bem.e("price-value")}>
                    <NumberFormat
                      prefix="$"
                      value={totalValue || 0}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </div>
                </>
              )}
              <span className={bem.e("box-action")}>
                {isOpenDetails === sectionKey ? <UpIcon /> : <DownIcon />}
              </span>
            </div>
          </SectionTitle>
          {isOpenDetails === sectionKey && (
            <React.Fragment>
              <SectionFields
                className={classnames(
                  bem.e("section-fields"),
                  bem.e("contest-prize-fields")
                )}
              >
                {get(selectedPrizes, "length", 0) > 0 ? (
                  <div className={bem.e("prize-items")}>
                    {map(selectedPrizes, (item, index) => (
                      <SelectedItem
                        bem={bem}
                        item={item}
                        key={`grand-prize-selected-item-${item._id}-${index}`}
                        isView={isView}
                        validationState={get(
                          validationState,
                          `prizes.validation_error.${index}`,
                          null
                        )}
                        onUpdate={(item) => {
                          onValueChanged(
                            prizes,
                            selectedPrizes.map((i) => {
                              if (i.key === item.key) {
                                return item;
                              }
                              return i;
                            })
                          );
                        }}
                        onDelete={(item) => {
                          onValueChanged(
                            prizes,
                            selectedPrizes.filter((i) => i.key !== item.key)
                          );
                          props.onRefreshPrizes();
                        }}
                        intl={intl}
                        playsNumber={playsNumber}
                      />
                    ))}
                  </div>
                ) : (
                  <div className={bem.e("selected-prize-item")}>
                    <div className={bem.e("prize-empty-text")}>
                      <FormattedMessage
                        id={
                          "process > promotion > contest > title empty prize item"
                        }
                      />
                    </div>
                  </div>
                )}
                {get(validationState, "prizes.validation_error") &&
                  typeof validationState.prizes.validation_error ===
                    "string" && (
                    <span className="alert-danger">
                      {validationState.prizes.validation_error}
                    </span>
                  )}
              </SectionFields>
              {renderPrizeCloset()}
            </React.Fragment>
          )}
        </ShadowBox>
      </>
    );
  };
  return renderRegularPrizePackage();
};

export default PrizePackage;
