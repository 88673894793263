import React, { useState } from "react";
import bn from "utils/bemnames";
import classNames from "classnames";
import LoadMore from "../LoadMore";
import { FormattedMessage } from "react-intl";
import { get, map } from "lodash";
import PropTypes from "prop-types";
const bem = bn.create("jock-console");
function TagItem(props) {
  const { item } = props;
  return (
    <div className={bem.e("tag-item")}>
      <div className={bem.e("tag-category")}>{item.category}</div>
      <div className={bem.e("tag-title")}>{item.title}</div>
      <div className={bem.e("tag-subtitle")}>{item.subtitle}</div>
    </div>
  );
}
const hashtags = [
  {
    name: "National",
    key: "National",
  },
  {
    name: "Local",
    key: "Local",
  },
  {
    name: "News",
    key: "News",
  },
  {
    name: "Sports",
    key: "Sports",
  },
  {
    name: "Entertainment",
    key: "Entertainment",
  },
];
const tags = [
  {
    category: "1 - Soul Music - Trending",
    title: "#TreySongz",
    subtitle: "30.8K Tweets - Trending with KekePalmer",
  },
  {
    category: "1 - Soul Music - Trending",
    title: "#TreySongz",
    subtitle: "30.8K Tweets - Trending with KekePalmer",
  },
  {
    category: "1 - Soul Music - Trending",
    title: "#TreySongz",
    subtitle: "30.8K Tweets - Trending with KekePalmer",
  },
  {
    category: "1 - Soul Music - Trending",
    title: "#TreySongz",
    subtitle: "30.8K Tweets - Trending with KekePalmer",
  },
];
export default function TrendingHashtags(props) {
  const { isMultiView, widget } = props;
  const [currentActive, setCurrentActive] = useState(hashtags[0].key);

  return (
    <div
      className={classNames(
        bem.e("scroll-container"),
        bem.e("trending-hashtags"),
        bem.e("widget-detail-padding")
      )}
    >
      <div
        className={classNames(bem.e("trending-hashtags-head-title"), {
          [bem.e("head-title-multi-view")]: isMultiView,
        })}
        style={{
          backgroundColor: isMultiView ? widget.color : "",
        }}
      >
        {get(widget, "name", "")}
      </div>
      <div className={bem.e("hashtags")}>
        {map(hashtags, (item, index) => (
          <span
            key={index}
            className={classNames(bem.e("hashtag-item"), {
              [bem.e("hashtag-item-active")]: currentActive === item.key,
            })}
            onClick={() => setCurrentActive(item.key)}
          >
            {item.name}
          </span>
        ))}
      </div>
      <div className={bem.e("tags-list")}>
        {map(tags, (item, index) => (
          <TagItem key={index} item={item} />
        ))}
      </div>
      <LoadMore onClick={() => {}} />
    </div>
  );
}
TrendingHashtags.defaultProps = {
  isMultiView: false,
  widget: {},
};
TrendingHashtags.propsTypes = {
  isMultiView: PropTypes.bool,
  widget: PropTypes.object.isRequired,
};
