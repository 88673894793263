import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody } from "reactstrap";
import bn from "utils/bemnames";
import { get } from "lodash";
import Avatar from "components/Avatar";
const bem = bn.create("feedback-success-modal");
function FeedbackSuccessModal(props) {
  const {
    onToggle,
    isOpen,
    user,
    isAutoClose,
    onCancel,
    title,
    description,
  } = props;
  const avatarUrl = get(user, "avatar_url")
    ? user.avatar_url
    : "https://promosuite-live.s3.amazonaws.com/9/c/d/e-8597-446b-860a-2c3ea47ca8013540f458-cfcb-4f07-b94f-3b7b173bf785||avatar_white_circle.png";
  return (
    <Modal
      isOpen={isOpen}
      toggle={onToggle}
      className={bem.b()}
      onOpened={() => {
        if (isOpen && isAutoClose) {
          setTimeout(() => {
            onCancel();
          }, 3000);
        }
      }}
    >
      <ModalBody className={bem.e("body")}>
        <div className={bem.e("title")}>
          <div className={bem.e("feedback-avatar")}>
            <Avatar src={avatarUrl} name={user.name} size={130} />
          </div>
          {title}
        </div>
        <p className={bem.e("description")}>{description}</p>
        <div className={bem.e("user")}>
          <span className={bem.e("user-name")}>{get(user, "name", "")}</span>
          <span className={bem.e("user-role")}>{get(user, "role", "")}</span>
        </div>
      </ModalBody>
    </Modal>
  );
}
FeedbackSuccessModal.defaultProps = {
  isOpen: false,
  onToggle: () => {},
  onCancel: () => {},
  user: {},
  isAutoClose: false,
  description: "",
  title: "",
};
FeedbackSuccessModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  isAutoClose: PropTypes.bool,
  title: PropTypes.any.isRequired,
  description: PropTypes.any.isRequired,
};
export default FeedbackSuccessModal;
