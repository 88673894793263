import TemplatesPage from "./view";
import get from "lodash/get";
import { connect } from "react-redux";
import { compose, lifecycle, withState, withHandlers } from "recompose";
import { getTemplates } from "store/actions/templates";
import { deleteTemplate } from "store/actions/template";
import { injectIntl } from "react-intl";
export default injectIntl(compose( 
  connect(
    state => ({ templates: get(state, "templates.templates") }),
    {
      getTemplates, 
      deleteTemplate
    }
  ),
  withState("isDeleteModalOpen", "setIsDeleteModalOpen", false),
  withState("deleteTemplateId", "setDeleteTemplateId", null),
  withHandlers({
    openDeleteModal: ({ setIsDeleteModalOpen, setDeleteTemplateId }) => id => {
      setIsDeleteModalOpen(true);
      setDeleteTemplateId(id);
    },
    closeDeleteModal: ({ setIsDeleteModalOpen, setDeleteTemplateId }) => () => {
      setIsDeleteModalOpen(false);
      setDeleteTemplateId(null);
    }
  }),
  withHandlers({
    onDelete: ({ closeDeleteModal, deleteTemplateId, deleteTemplate }) => () => {
      deleteTemplate(deleteTemplateId);
      closeDeleteModal();
    }
  }),
  lifecycle({
    componentDidMount() {
      this.props.getTemplates();
    }
  })
)(TemplatesPage));
