import React, { useRef } from "react";
import { replace, get, map } from "lodash";
import PlacedIcon from "assets/img/icons/placed-icon.png";
import classnames from "classnames";
import { CloseIcon } from "components/CustomIcons";
import Avatar from "components/Avatar";

function Comment({ comment, visible, onClose, bem }) {
  const src = get(comment, "from.avatar_url");
  const name = get(comment, "from.name");

  return (
    <div
      className={classnames(bem.e("tooltip"), {
        [bem.e("visible")]: visible,
      })}
    >
      <div className={bem.e("tooltip-content")}>
        <div className={bem.e("tooltip-message")}>
          <Avatar size={41} src={src} name={name} />
          <div className={bem.e("tooltip-message-content")}>
            {comment.message}
          </div>
        </div>
        <span className={bem.e("close-icon")} onClick={onClose}>
          <CloseIcon />
        </span>
      </div>
    </div>
  );
}
const MediaImage = (props) => {
  const { bem, asset, comments, setIsVisibleComment, isVisibleComment } = props;
  const containerMedia = useRef(null);

  return (
    <div
      className={bem.e("asset-view")}
      style={{
        backgroundImage: `url("${replace(
          get(asset, "path", ""),
          "https://",
          "http://"
        )}")`,
      }}
      ref={containerMedia}
    >
      {/* render placed for comment */}
      {map(
        comments.filter((item) => item.number && !item.is_deleted),
        (comment, index2) => {
          return (
            <div
              key={index2}
              className={bem.e("placed-icon")}
              style={{
                top: comment.y,
                left: comment.x,
              }}
            >
              <div
                className={bem.e("placed-wrapper")}
                onClick={() => {
                  setIsVisibleComment(comment.id);
                }}
              >
                <img src={PlacedIcon} alt="" />
                <span className={bem.e("placed-number")}>{comment.number}</span>
              </div>
              {isVisibleComment === comment.id && (
                <Comment
                  comment={comment}
                  visible
                  onClose={() => setIsVisibleComment(null)}
                  bem={bem}
                />
              )}
            </div>
          );
        }
      )}
    </div>
  );
};
export default MediaImage;
