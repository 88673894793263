import * as React from "react";
import { Col, Row } from "reactstrap";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { setScriptField, getFileNameFromUrl } from "utils/helpers";
import TextareaEvaluator, {
  DEFAULT_WORDS_PER_MIN,
} from "components/TextareaEvaluator";
import OrderScriptReviewFeedback from "./OrderScriptReviewFeedback";
import { get, find } from "lodash";
import bn from "utils/bemnames";
import FileView from "components/FileView";
import moment from "moment";
const bem = bn.create("order-script");
function RenderScriptEditor(props) {
  const { isBookend, spot, showFeedbackEditor, process } = props;
  const refreshTextEditor = get(process, 'data.fields.refreshed_at.value', 0)

  return (
    <React.Fragment>
      <Row>
        <Col className={classnames(bem.e("col-field"))}>
          <div className={bem.e("form-div")}>
            {!isBookend ? (
              <TextareaEvaluator
                readOnly={true}
                isOrderLength={true}
                showScriptLength={true}
                text={setScriptField(get(spot, "script", ""))}
                process={process}
                wordsPerMin={get(
                  spot,
                  "default_words_per_min",
                  DEFAULT_WORDS_PER_MIN
                )}
                onEvaluatorCallback={() => {}}
                key={refreshTextEditor}
              />
            ) : (
              <TextareaEvaluator
                readOnly={true}
                isOrderLength={true}
                showScriptLength={true}
                text={setScriptField(get(spot, "script", ""))}
                process={process}
                spot={spot}
                wordsPerMin={get(
                  spot,
                  "default_words_per_min",
                  DEFAULT_WORDS_PER_MIN
                )}
                onEvaluatorCallback={() => {}}
                title={<FormattedMessage id="process > field script part A" />}
                isBookend={isBookend}
                part="a"
                showInnerTitle={true}
                key={refreshTextEditor}
              />
            )}
          </div>
        </Col>
      </Row>
      {showFeedbackEditor &&
        (!isBookend ? (
          <React.Fragment>
            <Row className={bem.e("horizontal-line")}></Row>
            <Row>
              <OrderScriptReviewFeedback
                {...props}
                spot={spot}
                bem={bem}
                feedbackField="script_feedback"
              />
            </Row>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Row className={bem.e("horizontal-line")}></Row>
            <Row>
              <OrderScriptReviewFeedback
                {...props}
                spot={spot}
                isBookend={isBookend}
                scriptPart="a"
                bem={bem}
                feedbackField="script_feedback"
              />
            </Row>
          </React.Fragment>
        ))}
      {isBookend && (
        <React.Fragment>
          {showFeedbackEditor && (
            <Row className={bem.e("horizontal-line")}></Row>
          )}
          <Row>
            <Col className={bem.e("col-field")}>
              <div className={bem.e("form-div")}>
                <TextareaEvaluator
                  readOnly={true}
                  isOrderLength={true}
                  showScriptLength={true}
                  text={setScriptField(get(spot, "script_part_b", ""))}
                  process={process}
                  spot={spot}
                  wordsPerMin={get(
                    spot,
                    "default_words_per_min_b",
                    DEFAULT_WORDS_PER_MIN
                  )}
                  onEvaluatorCallback={() => {}}
                  title={
                    <FormattedMessage id="process > field script part B" />
                  }
                  isBookend={isBookend}
                  part="b"
                  showInnerTitle={true}
                  key={refreshTextEditor}
                />
              </div>
            </Col>
          </Row>
          {showFeedbackEditor && (
            <React.Fragment>
              <Row className={bem.e("horizontal-line")}></Row>
              <Row>
                <OrderScriptReviewFeedback
                  {...props}
                  spot={spot}
                  isBookend={isBookend}
                  scriptPart="b"
                  bem={bem}
                  feedbackField="script_part_b_feedback"
                />
              </Row>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
function RenderUploadFile(props) {
  const { isBookend, spot, showFeedbackEditor, item } = props;
  const isShowOrderLength = item.props.isShowOrderLength;
  const orderLengthCustom =
    get(process, "data.fields.order_length_custom.value") ||
    get(process, "_source.order_length_custom");
  let orderLength =
    get(process, "data.fields.order_length.value") ||
    get(process, "_source.order_length") ||
    "00";
  if (orderLengthCustom) {
    const formatted = moment.utc(parseInt(orderLength) * 1000).format("mm:ss");
    orderLength = formatted;
  }
  const length1 = get(spot, "length1", "00") || "";
  const length2 = get(spot, "length2", "00") || "";
  return (
    <React.Fragment>
      <Row>
        <Col
          className={classnames(bem.e("col-field"), {
            [bem.e("col-field-bookend")]: isBookend,
          })}
        >
          <div className={bem.e("form-div")}>
            <React.Fragment>
              <div className={bem.e("script-upload-item-top")}>
                {isBookend && (
                  <div className={bem.e("part-upload-title")}>
                    <FormattedMessage id="process > part A" />
                  </div>
                )}
                {isShowOrderLength && (
                  <div className={bem.e("spot-length")}>
                    <FormattedMessage id="process > length" />:{" "}
                    <strong>{length1}</strong>
                  </div>
                )}
              </div>
              {spot.script ? (
                <FileView
                  file={{
                    path: spot.script,
                    name: getFileNameFromUrl(spot.script),
                  }}
                  actions={["view", "download"]}
                />
              ) : (
                <div className={bem.e("script-file-empty")}>
                  <FormattedMessage id="process > title in production" />
                </div>
              )}
            </React.Fragment>
          </div>
        </Col>
      </Row>
      {showFeedbackEditor && (
        <React.Fragment>
          <Row>
            <OrderScriptReviewFeedback
              {...props}
              spot={spot}
              isBookend={isBookend}
              scriptPart="a"
              bem={bem}
              feedbackField="script_feedback"
              isUploadFile={true}
            />
          </Row>
        </React.Fragment>
      )}
      {isBookend && (
        <React.Fragment>
          <Row className={bem.e("horizontal-line")}></Row>
          <Row>
            <Col
              className={classnames(bem.e("col-field"), {
                [bem.e("col-field-bookend")]: isBookend,
              })}
            >
              <div className={bem.e("form-div")}>
                <React.Fragment>
                  <div className={bem.e("script-upload-item-top")}>
                    <div className={bem.e("part-upload-title")}>
                      <FormattedMessage id="process > part B" />
                    </div>
                    {isShowOrderLength && (
                      <div className={bem.e("spot-length")}>
                        <FormattedMessage id="process > length" />:{" "}
                        <strong>{length2}</strong>
                      </div>
                    )}
                  </div>
                  {spot.script_part_b ? (
                    <FileView
                      file={{
                        path: spot.script_part_b,
                        name: getFileNameFromUrl(spot.script_part_b),
                      }}
                      actions={["view", "download"]}
                    />
                  ) : (
                    <div className={bem.e("script-file-empty")}>
                      <FormattedMessage id="process > title in production" />
                    </div>
                  )}
                </React.Fragment>
              </div>
            </Col>
          </Row>
          {showFeedbackEditor && (
            <React.Fragment>
              <Row>
                <OrderScriptReviewFeedback
                  {...props}
                  spot={spot}
                  isBookend={isBookend}
                  scriptPart="b"
                  bem={bem}
                  feedbackField="script_part_b_feedback"
                  isUploadFile={true}
                />
              </Row>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
const OrderScriptReview = (props) => {
  const { process, isView } = props;
  const spot = find(
    get(process, "data.fields.spot_info.value", []),
    (spot) => spot.key === process.key
  );

  const isBookend = get(spot, "spot_type", "Standard") === "Book-ends";
  const showFeedbackEditor =
    !isView && get(process, "data.fields.script_feedback.value", null) !== null;
  const isUploadScriptFile = get(spot, "is_upload_script_file", false);

  return (
    <div className={bem.b()}>
      {!isUploadScriptFile ? (
        <RenderScriptEditor
          {...props}
          isBookend={isBookend}
          spot={spot}
          showFeedbackEditor={showFeedbackEditor}
        />
      ) : (
        <RenderUploadFile
          {...props}
          isBookend={isBookend}
          spot={spot}
          showFeedbackEditor={showFeedbackEditor}
        />
      )}
    </div>
  );
};
export default OrderScriptReview;
