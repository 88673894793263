import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import { StarIcon } from "components/CustomIcons";
import bn from "utils/bemnames";
const bem = bn.create("jock-console");

export default function JockWidget(props) {
  const { isMultiView, widget, isActive, onClick } = props;
  const { color = "#DFECF5", empty } = widget;
  return (
    <div
      className={classnames(bem.e("jock-tag"), {
        [bem.e("jock-tag-active")]: isActive,
        "is-empty": empty,
      })}
      style={{
        backgroundColor: color,
      }}
      onClick={onClick}
    >
      <div className={bem.e("jock-tag-title")}>{widget.name}</div>
      <span className={bem.e("star-icon")}>{isMultiView && <StarIcon />}</span>
    </div>
  );
}
JockWidget.propTypes = {
  isShowSearchIcon: PropTypes.bool,
  widget: PropTypes.any,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  isMultiView: PropTypes.bool,
};
JockWidget.defaultProps = {
  isActive: false,
  isMultiView: false,
  onClick: () => {},
};
