import React from "react";
import { get, isEqual, debounce } from "lodash";
import { Row, Col, Card, CardBody } from "reactstrap";
import classnames from "classnames";
import bn from "utils/bemnames";
import Checkbox from "components/Checkbox";
import ToastManager from "components/ToastManager";
import { MdImportantDevices } from "react-icons/lib/md";
import SortableTree from "react-sortable-tree";
import "react-sortable-tree/style.css";
import Spinner from "components/Spinner";
import Select from "react-select";
import { STATION } from 'utils/constants';

const bem = bn.create("cities-list");
class StationsTree extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenDialogSave: false,
      isOpenDialogDelete: false,
      isOpenDialogUpdateOrNew: false,
      searchFocusIndex: 0,
      searchFoundCount: null,
      searchQuery: "",
      shouldShowToast: "",
      nodeDeleting: null,
      initialValues: {},
      isUpdate: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      !isEqual(
        get(this.props, "tags.data", []),
        get(nextProps, "tags.data", [])
      )
    ) {
      this.setState({ searchQuery: null });
    }
  }

  onChangeInput = debounce(searchQuery => {
    this.setState({ searchQuery });
    if (!searchQuery) {
      this.setState({ searchQuery: null });
    }
  }, 20);

  selectPrevMatch = () => {
    const { searchFocusIndex, searchFoundCount } = this.state;

    this.setState({
      searchFocusIndex:
        searchFocusIndex !== null
          ? (searchFoundCount + searchFocusIndex - 1) % searchFoundCount
          : searchFoundCount - 1
    });
  };

  selectNextMatch = () => {
    const { searchFocusIndex, searchFoundCount } = this.state;

    this.setState({
      searchFocusIndex:
        searchFocusIndex !== null
          ? (searchFocusIndex + 1) % searchFoundCount
          : 0
    });
  };

  render() {
    const {
      setTreeData,
      treeData,
      setSelected,
      value,
      auth
    } = this.props;
    let selected = {}//value
    value.map(v=>{ selected[v]=true});
    const {
      searchQuery,
      searchFoundCount,
      searchFocusIndex,
      shouldShowToast
    } = this.state;
    if (shouldShowToast === "error") {
      ToastManager.show({
        title: <MdImportantDevices />,
        message: this.props.intl.formatMessage({
          id: "groups > move exists"
        }),
        autoDismiss: 0.5,
        level: "warning",
        onRemove: () => {
          this.setState({ shouldShowToast: false });
        }
      });
    }
    return (
      <Row className={classnames(bem.b())}>
        <Col>
          <Card>
            <CardBody className={bem.e("tree")}>
              <Row>
                <Col className="col-sm-9">
              <div className={bem.e("tree-wrapper")} style={{ height: '100%',minHeight: '50vh'}}>
                <SortableTree
                  isVirtualized
                  canDrag={false}
                  rowHeight={55}
                  treeData={treeData}
                  onChange={treeData => {
                    setTreeData(treeData);
                    this.setState({ shouldShowToast: "moved" });
                  }}
                  ref={ref => (this.sortableTree = ref)}
                  onVisibilityToggle={({treeData, node, expanded, path})=> {
                    if(expanded){
                      function enhance(node){
                        if (!node.enhanced && node.tag_type_id > 0){//} && (!node.children || node.children.length === 0 || (node.children.length === 1 && node.children[0].is_filters) )){
                          let candidate_stations = get(auth, 'info.stations', []).filter(s => s.type === STATION);
                          
                          newNodeInd = 0;
                          while(newNodeInd < node.path.length){

                            // which tags should not be evaluated

                            candidate_stations=candidate_stations.filter(station=>station.tags.indexOf(node.path[newNodeInd])>-1);
                            newNodeInd++
                          }
                          if(candidate_stations.length===0) {

                            // flag empty for removal

                            node.hidden = true;
                          }
                          else{
                            let station_nodes = candidate_stations.map(station => ({tag_type_id:-1, title:station.station_key, id:'station_'+station.user_station_id}))

                            station_nodes.map(n=>node.children.push(n));
                            // console.log('ADDED TO child: '+ node.title)
                            node.has_content = true;
                            node.enhanced = true;
                          }
                        }
                      }
                      let newTreeData = treeData;
                      let newNode = {children: newTreeData};
                      let newNodeInd = 0;
                      let ancesters = [];
                      if(!newNode.enhanced){

                        while(newNodeInd < node.path.length){
                          ancesters.push(newNode);
                          let foundNode = newNode.children.find(child=>child.id === node.path[newNodeInd]);
                          if(!foundNode){
                            foundNode = newNode.children.find(child=>child.tag_type_id === -2);
                            if(foundNode){
                              foundNode=foundNode.children.find(child=>child.id === node.path[newNodeInd]);
                            }
                            if(!foundNode){
                              debugger
                            }
                          } 
                          newNode = foundNode;
                          if(!newNode){
                            debugger
                          }
                        newNodeInd++
                        }

                        if (newNode){

                          // enhance chidlren

                          newNode.children.map(node=>{
                            enhance(node)
                          });
                          
                          // enhance grand chidlren

                          newNode.children.map(grandnode=>{
                            grandnode.enhanced = true;
                            grandnode.children && grandnode.children.map(node=>{
                              enhance(node)
                            })
                          });

                          // remove empty nodes

                          newNode.children = newNode.children.filter(node=>!node.hidden);
                          newNode.children = newNode.children.filter(node=> (node.children && node.children.length>0) || node.tag_type_id === -1);
                          newNode.children = newNode.children.filter(nodeb=> 
                            nodeb.tag_type_id < 0  
                            || (nodeb.children && nodeb.children.filter(nodeg=>{
                              return nodeg.tag_type_id === -1 
                              || (nodeg.children && nodeg.children.filter(noded=>{
                                return noded.tag_type_id === -1
                                || (noded.children && noded.children.length>1 && noded.has_content)
                                }).length > 0)
                              }).length > 0)
                            );

                          newNode.enhanced = true;
                          setTreeData(newTreeData);
                        }
                        else
                          debugger
                      }
                    }
                  }}
                  generateNodeProps={rowInfo => {
                    const { node } = rowInfo;
                    // if(node.hidden) return null;
                    return {
                      buttons: [
                        <div>
                        </div>
                      ],
                      title: (
                        <div className={bem.e("row-item-container")}>
                          {node.tag_type_id >= -1 && <div className={bem.e("row-item-checkbox")}>
                            <Checkbox
                              checked={get(selected, `${node.id}`, false)}
                              onChange={checked => {
                                let newSelected = {
                                  ...selected,
                                  [node.id]: checked
                                };
                                if(node.tag_type_id > 0){
                                  node.children && node.children.map(child=>{
                                    if(child.tag_type_id === -1)
                                    newSelected = {
                                      ...newSelected,
                                      [child.id]: checked
                                    };
                                    child.children && child.children.map(grand_child=>{
                                      if(grand_child.tag_type_id === -1)
                                      newSelected = {
                                        ...newSelected,
                                        [grand_child.id]: checked
                                      };
                                    })
                                  })
                                }
                                newSelected=(newSelected);
                                setSelected(newSelected);
                                let sel = Object.keys(newSelected)
                                   .filter(s=>newSelected[s])
                                  //.map(key=>key.replace('station_',''))
                                this.props.onChange(sel);
                              }}
                            />
                          </div>}
                          <h2 className={bem.e("row-item-title")}>
                            {get(rowInfo, "node.title", "")}
                          </h2>
                          {get(rowInfo, "node.icon") &&
                          get(rowInfo, "node.icon") !== "NULL" ? (
                            <div className={bem.e("row-item-icon")}>
                              <img
                                src={get(rowInfo, "node.url_icon")}
                                alt={get(rowInfo, "node.icon", "")}
                              />
                            </div>
                          ) : null}
                        </div>
                      )
                    };
                  }}
                />
              </div>
                </Col>
                <Col className="col-sm-3" style={{'borderLeft':'1px solid #e1e7e8'}}>
                <Select
                  ref={ref => this.selectRef = ref }
                  options={get(auth, "info.stations", []).filter(s => s.type === STATION).map(station=>({tag_type_id:-1, title:station.station_key, label:station.station_key, id:'station_'+station.user_station_id, value:'station_'+station.user_station_id}))}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  isMulti
                  value={(selected) ? Object.keys(selected).filter(k=>k.indexOf('station_')===0)
                    .map(key=>{
                    let station=selected[key]; 
                    try{
                    if (station){
                      station = auth.info.stations.find(s=>s.user_station_id+'' == key.replace('station_',''))
                      return {tag_type_id:-1, label:station.station_key, id:'station_'+station.user_station_id, value:'station_'+station.user_station_id}
                    
                    }
                  }catch(e) {
                    debugger
                  }

                    }
                    
                    ):[]}
                  closeMenuOnSelect={false}
                  onChange={sel => {
                    let newSelected = {}
                    sel.map(child=>{
                      newSelected = {
                        ...newSelected,
                        [child.id]: true
                      }
                    });
                    setSelected(newSelected);
                    let selected = sel
                     .map(s=>s.id) //.replace('station_',''))
                   this.props.onChange(selected);
                  }}
                />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Spinner isLoading={get(this.props, "isLoading", false)} />
      </Row>
    );
  }
}

export default StationsTree;
