import * as React from "react";
import bn from "utils/bemnames";
import Spinner from "components/Spinner";
import { get, indexOf, split, trim, find } from "lodash";
import * as Yup from "yup";
import { Formik } from "formik";
import ToastManager from "components/ToastManager";
import { Form, Button } from "reactstrap";
import { FormattedMessage } from "react-intl";
import ListenerGeography from "./ListenerGeography";
import CopyStations from "components/StationSettings/CopyStations";
import ContestEligibilityRules from "./ContestEligibilityRules";
import { countryList } from "utils/config";
const bem = bn.create("station-settings-form");

const StationSettingsForm = (props) => {
  const {
    loading,
    onFormSubmit,
    intl,
    currentServer,
    stationSettingsData,
    history,
  } = props;
  const stationSettings = get(stationSettingsData, "_source", {});
  let restrictToHomeCountry = get(
    currentServer,
    "restrict_to_home_country",
    false
  );
  const formikRef = React.useRef(null);
  const optionCountries = countryList
    .filter((item) => {
      return (
        item.value === currentServer.country ||
        indexOf(currentServer.neighboring_countries, item.value) !== -1
      );
    })
    .map((country) => {
      return {
        label:
          country.value === "US"
            ? "US citizens"
            : country.value === "CA"
            ? "Canadian citizens"
            : `${country.label} citizens`,
        value: country.value,
      };
    });
  if (optionCountries.length === 1) {
    restrictToHomeCountry = true;
  }

  const listener_countries = get(
    stationSettings,
    "listener_countries",
    []
  ).filter((item) => find(optionCountries, (i) => i.value === item));

  const initialValues = {
    station_id: props.currentStation.key,
    listener_countries:
      restrictToHomeCountry && optionCountries.length > 0
        ? [optionCountries[0].value]
        : listener_countries,
    selected_by: get(stationSettings, "selected_by", "state_county"),
    states: get(stationSettings, "states", []),
    counties: get(stationSettings, "counties", []),
    provinces: get(stationSettings, "provinces", []),
    units: get(stationSettings, "units", []),
    minimum_age: get(stationSettings, "minimum_age", 18),
    postal_codes: get(stationSettings, "postal_codes", ""),
    max_wins_in_specific_contest: get(
      stationSettings,
      "max_wins_in_specific_contest",
      1
    ),
    max_wins_in_30_days: get(stationSettings, "max_wins_in_30_days", 1),
    max_wins_apply_to: get(stationSettings, "max_wins_apply_to", "individual"),
    general_prize_min_days_wait: get(
      stationSettings,
      "general_prize_min_days_wait",
      1
    ),
    general_prize_min_days_wait_apply_to: get(
      stationSettings,
      "general_prize_min_days_wait_apply_to",
      "individual"
    ),
    cash_prize_min_days_wait: get(
      stationSettings,
      "cash_prize_min_days_wait",
      1
    ),
    cash_prize_min_days_wait_apply_to: get(
      stationSettings,
      "cash_prize_min_days_wait_apply_to",
      "individual"
    ),
    high_value_min_amount: get(stationSettings, "high_value_min_amount", 1000),
    high_value_min_days_wait: get(
      stationSettings,
      "high_value_min_days_wait",
      30
    ),
    high_value_min_apply_to: get(
      stationSettings,
      "high_value_min_apply_to",
      "individual"
    ),
    high_value_prize_restricted: get(
      stationSettings,
      "high_value_prize_restricted",
      ""
    ),
    market_cluster: get(stationSettings, "market_cluster", ""),
    settings_copy_to_stations: get(
      stationSettings,
      "settings_copy_to_stations",
      ""
    ),
  };
  const form = (
    <div className={bem.b()}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        ref={formikRef}
        validationSchema={Yup.object().shape({
          listener_countries: Yup.array().required(
            intl.formatMessage({
              id: "validate > listener country is required",
            })
          ),
          postal_codes: Yup.string()
            .test(
              "postal_codes_commas",
              intl.formatMessage({
                id: "validate > postal codes is invalid",
              }),
              (value) => {
                if (!value) return true;
                const postalCodesArr = split(value, ",").map((item) =>
                  trim(item)
                );
                const isValid =
                  postalCodesArr.filter((item) => !item).length === 0;
                return isValid;
              }
            )
            .nullable(),
          market_cluster: Yup.string().required(
            intl.formatMessage({ id: "validate > market cluster is required" })
          ),
        })}
        onSubmit={onFormSubmit}
      >
        {(formProps) => {
          return (
            <React.Fragment>
              <Form
                onSubmit={formProps.handleSubmit}
                onChange={() => {
                  props.setShouldBlockNavigation(true);
                }}
              >
                <div className={bem.e("station-wrapper")}>
                  <ListenerGeography
                    formProps={formProps}
                    optionCountries={optionCountries}
                    intl={intl}
                    restrictToHomeCountry={restrictToHomeCountry}
                    optionStates={props.optionStates}
                    optionCounties={props.optionCounties}
                    onGetStatesByCountries={props.onGetStatesByCountries}
                    onGetCounties={props.onGetCounties}
                    currentStation={props.currentStation}
                  />
                  <ContestEligibilityRules formProps={formProps} intl={intl} />
                  <CopyStations
                    formProps={formProps}
                    intl={intl}
                    stations={props.stations}
                    currentServer={currentServer}
                    currentStation={props.currentStation}
                    setShouldBlockNavigation={props.setShouldBlockNavigation}
                  />
                </div>

                <div className="bottom-actions-sticky">
                  <div className={"buttons"}>
                    <Button
                      color="blue"
                      type="submit"
                      className="btn btn-radius"
                      disabled={loading}
                      id="btn-submit-eligibility"
                      onClick={() => {
                        setTimeout(() => {
                          const formErrors = get(
                            formikRef,
                            "current.state.errors"
                          );
                          if (formErrors && Object.keys(formErrors).length) {
                            ToastManager.show({
                              title: intl.formatMessage({
                                id: "toast > title not saved",
                              }),
                              message: intl.formatMessage({
                                id:
                                  "toast > message error please correct the hilighted fields",
                              }),
                              level: "error",
                            });
                          }
                        }, 40);
                      }}
                    >
                      <FormattedMessage id={`station settings > button save`} />
                    </Button>
                    <Button
                      color="blue"
                      outline
                      className="btn btn-radius"
                      disabled={loading}
                      onClick={() => {
                        formProps.resetForm(initialValues);
                      }}
                    >
                      <FormattedMessage
                        id={`station settings > button cancel`}
                      />
                    </Button>
                  </div>
                </div>
              </Form>
              <Spinner isLoading={loading} />
            </React.Fragment>
          );
        }}
      </Formik>
    </div>
  );
  return form;
};
export default StationSettingsForm;
