import { FormattedMessage } from "react-intl";
import React from "react";
import { get, filter, includes, uniq, find, values, delay, map } from "lodash";
import history from "components/History";
import { checkUserRedirectToCurrentTasks } from "utils/helpers";

const voiceOver = {
  process_step_index: 4.2,
  key: "voice_over",
  title: <FormattedMessage id="process > title vo" />,
  caption_color: "#3118CA",
  description: <FormattedMessage id="process > voice over instructions" />,
  step_roles: [6],
  step_viewers_roles: [6, 5, 4, 7, 2],
  step_editors_roles: [6, 5, 4, 7],
  step_viewers_privileges: {
    or: ["edit_vo", "edit_all_production_orders"],
  },
  step_editors_privileges: {
    or: ["edit_vo", "edit_all_production_orders"],
  },
  className: "voice-over",
  isCollapsible: true,
  is_check_hidden_tab: true,
  is_check_hidden_tab_ui: function(user, process, step, is_hidden_tab) {
    const producerAssignsVo = get(
      process,
      "data.fields.producer_assigns_vo.value",
      false
    );
    const roleProducers = get(
      process,
      "data.fields.role_producer.value.users",
      []
    ).map((producer) => producer.id);
    const userAssignedToProducers = roleProducers.includes(Number(user.id));
    const roleVoiceTalens = get(
      process,
      "data.fields.role_voice_talent.value.users",
      []
    ).map((voice) => voice.id);
    const userAssignedToVoiceTalens = roleVoiceTalens.includes(Number(user.id));

    // Vo + producer is the same and enabled producerAssignsVo
    if (
      producerAssignsVo &&
      userAssignedToVoiceTalens &&
      userAssignedToProducers
    ) {
      return true;
    }
    // return current value
    return is_hidden_tab;
  },
  isOpen: function({ step, process, template, user }) {
    return true;
  },
  is_hidden_edit_button: function(user, process, step) {
    const roleProducers = get(
      process,
      "data.fields.role_producer.value.users",
      []
    ).map((producer) => producer.id);
    const roleVoiceTalens = get(
      process,
      "data.fields.role_voice_talent.value.users",
      []
    ).map((voice) => voice.id);
    const roleVosIsNotProducers = filter(
      roleVoiceTalens,
      (userId) => !includes(roleProducers, userId)
    );
    // empty VOs
    if (roleVoiceTalens.length === 0 || roleVosIsNotProducers.length === 0)
      return true;
    // only VOs assigned that also Producers
    if (
      roleVoiceTalens.length === 1 &&
      includes(roleProducers, roleVoiceTalens[0])
    )
      return true;
  },
  is_hidden: function(user, process, step) {
    const roleProducers = get(
      process,
      "data.fields.role_producer.value.users",
      []
    ).map((producer) => producer.id);
    const roleVoiceTalens = get(
      process,
      "data.fields.role_voice_talent.value.users",
      []
    ).map((voice) => voice.id);
    const roleVosIsNotProducers = filter(
      roleVoiceTalens,
      (userId) => !includes(roleProducers, userId)
    );
    // empty VOs
    if (roleVoiceTalens.length === 0 || roleVosIsNotProducers.length === 0)
      return true;
    // only VOs assigned that also Producers
    if (
      roleVoiceTalens.length === 1 &&
      includes(roleProducers, roleVoiceTalens[0])
    )
      return true;
    // current user is VOs assigned that also producers
    // if(includes(roleProducers, user.id) && includes(roleVoiceTalens, user.id)) return true;

    // return !process.data.fields.write_script_status || process.data.fields.write_script_status &&
    // process.data.fields.write_script_status.value !==
    // "completed";
  },
  status: "initial",
  fields: [
    {
      field: "voice_tracks",
      component: "voice_tracks",
      mandatory: true,
      props: {
        attachText: <FormattedMessage id="process > field upload audio" />,
        actions: [],
        isCheckMimesByExt: true,
        extensions: ["mp3", "wav", "aiff", "flac", "aac", "alac", "m4a", "mp4"],
        isShowAudit: false,
        isBubble: true,
        isCanFeedback: true, // flag to show the review ui
        isShowFeedbackOtherVo: false, // flag to show the list review comments view mode when other VO viewing.
      },
      validation: function(step, item, process, template, user, value) {
        const roleProducers = get(
          process,
          "fields.role_producer.value.users",
          []
        ).map((producer) => producer.id);
        const voiceTracksArr = Object.keys(
          get(process, "fields.voice_tracks.value", {})
        );
        const voiceTalentUsers = get(
          process,
          "fields.role_voice_talent.value.users",
          []
        ).filter((voice) => !includes(roleProducers, voice.id));
        if (
          voiceTalentUsers.length === voiceTracksArr.length &&
          !find(voiceTalentUsers, (voiceUser) => voiceUser.id === user.id)
        ) {
          return true;
        }
        const voTrackValue = filter(value, (item) => item.user_id === user.id);
        const recordingWithProducer = find(voTrackValue, (item) =>
          item.hasOwnProperty("is_recording_with_producer")
        );
        if (
          (voTrackValue.length > 0 && !recordingWithProducer) ||
          (recordingWithProducer &&
            recordingWithProducer.is_recording_with_producer)
        )
          return true;
        return "Please upload voice over";
      },
    },
    {
      field: "script",
      container: "segment",
      component: "text_timer",
      isCollapsible: false,
      isSpot: true,
      editButton: true,
      isShowEditButtonViewMode: false,
      props: {
        classNameSegment: "segment-script voice-script",
        className: "form-div-script",
        readOnly: true,
        hideHelperText: true,
      },
      title: <FormattedMessage id="process > title final script" />,
    },
    {
      field: "script_part_b",
    },
    {
      field: "script_history",
    },
    {
      field: "script_audit",
    },
    {
      field: "script_part_b_audit",
    },
    { field: "spot_info" },
    {
      text: <FormattedMessage id="process > button submit" />,
      component: "button",
      key: "save_button",
      props: { color: "blue" },
      container: "bottom_buttons",
      on_click: {
        validate: true,
        save: true,
        go_to: function(_this, step, item, process, template, user, extraData) {
          const return_to_current_tasks = checkUserRedirectToCurrentTasks({
            ...user,
            roles_string: extraData.roles_string,
          });
          if (!return_to_current_tasks) return;
          // set delay when after success message
          delay(() => {
            if (_this.props.setSidebarActive) {
              _this.props.setSidebarActive(`/processes/my_action_items`);
            }
            history.replace(`/processes/my_action_items`);
          }, 4000);
        },
      },
      is_disabled: function(user, process, step) {
        const voiceTracks = get(process, "data.fields.voice_tracks.value", []);
        const roleProducers = get(
          process,
          "data.fields.role_producer.value.users",
          []
        ).map((producer) => producer.id);
        const voiceTalentUsers = get(
          process,
          "data.fields.role_voice_talent.value.users",
          []
        )
          .filter((voice) => !includes(roleProducers, voice.id))
          .map((voice) => voice.id);
        const currentUploadedVoiceTracks = filter(
          voiceTracks,
          (item) => item.user_id === user.id
        );
        return (
          includes(voiceTalentUsers, user.id) &&
          currentUploadedVoiceTracks.length === 0
        );
      },
      fields: [
        {
          key: "voice_tracks",
          value: (user, template, process, step, item, next_users) => {
            const voiceTracks = get(
              process,
              "data.fields.voice_tracks.value",
              []
            ).map((item) => {
              if (item.user_id === user.id) {
                return {
                  ...item,
                  is_submitted: true,
                };
              }
              return item;
            });
            return voiceTracks;
          },
        },
        {
          key: "voice_over_status",
          value: (user, template, process, step, item, next_users) => {
            const spotInfo = get(
              process,
              "data.fields.spot_info.value",
              []
            ).find((spot) => spot.key === process.key);
            const roleProducers = get(
              process,
              "data.fields.role_producer.value.users",
              []
            ).map((producer) => producer.id);
            const voiceTracksArr = uniq(
              values(get(process, "data.fields.voice_tracks.value", []))
                .filter((item) => item.is_submitted)
                .map((item) => item.user_id)
                .map(Number)
            );
            const voiceTalentUsers = get(
              process,
              "data.fields.role_voice_talent.value.users",
              []
            ).filter((voice) => !includes(roleProducers, voice.id));
            // only set completed status of vo tab when all VO is uploaded the tracks
            if (voiceTalentUsers.length === voiceTracksArr.length) {
              return "completed";
            }
            if (get(spotInfo, "voice_over_status", "") === "rejected")
              return "rejected"; // keep rejected state to other VO can continue upload record
            return "";
          },
        },
        {
          key: "next_users",
          value: (user, template, process, step, item, next_users) => {
            let continuityStatus = get(
              process,
              "data.fields.continuity_status.value",
              ""
            );

            const roleContinuity = get(
              process,
              "data.fields.role_continuity.value.users",
              []
            ).map((continuity) => continuity.id);

            let nextUsers = next_users;

            if (continuityStatus !== "completed") {
              nextUsers = [...nextUsers, ...roleContinuity];
            }

            const voiceTracksArr = uniq(
              values(get(process, "data.fields.voice_tracks.value", []))
                .filter((item) => item.is_submitted)
                .map((item) => item.user_id)
                .map(Number)
            );

            nextUsers = filter(nextUsers, (nexUserId) => {
              return !includes(voiceTracksArr, nexUserId);
            });

            return map(nextUsers, Number);
          },
        },
        {
          key: "process_step",
          value: (user, template, process, step, item) => {
            const roleProducers = get(
              process,
              "data.fields.role_producer.value.users",
              []
            ).map((producer) => producer.id);
            const voiceTracksArr = uniq(
              values(get(process, "data.fields.voice_tracks.value", []))
                .filter((item) => item.is_submitted)
                .map((item) => item.user_id)
                .map(Number)
            );
            const voiceTalentUsers = get(
              process,
              "data.fields.role_voice_talent.value.users",
              []
            ).filter((voice) => !includes(roleProducers, voice.id));
            if (voiceTalentUsers.length === voiceTracksArr.length) {
              return "first_undone";
            }
            return get(process, "data.fields.process_step.value");
          },
        },
      ],
      confirmation_dialog_props: {
        title: (
          <FormattedMessage id="process > description confirm submit recording" />
        ),
        confirmTitle: <FormattedMessage id="process > button yes" />,
        cancelTitle: <FormattedMessage id="process > button no" />,
      },
      success_dialog_props: {
        title: <FormattedMessage id="process > submitted" />,
        state: "success",
      },
    },
  ],
};
export default voiceOver;
