import React from "react";
import { get, includes } from "lodash";
import { FormattedMessage } from "react-intl";
import URL from "utils/urls";
import classnames from "classnames";
import history from "components/History";
import { CardBody } from "reactstrap";
import Spinner from "components/Spinner";
import ButtonAdd from "components/ButtonAdd";
import SearchInput from "components/SearchInput";
import bn from "utils/bemnames";
import ContinuityAssignments from "./ContinuityAssignments";
import DubbingQueue from "./DubbingQueue";
import ProcessesTable from "./ProcessesTable";
import ProducedSpotsTable from "./ProducedSpotsTable";
import DigitalQueueTable from "./DigitalQueueTable";
import ExpiringSpotsTable from "./ExpiringSpotsTable";
import ProducedDigitalsTable from "./ProducedDigitalsTable";
import PromotionLinersTable from "./PromotionLinersTable";
import PromotionContestsTable from "./PromotionContestsTable";
import MultiMarketQueueTable from "./MultiMarketQueue";
import ProductionArchiveTable from "./ProductionArchive";

import ContinuityQueue from "./ContinuityQueue";
import AssignmentsQueue from "./AssignmentsQueue";
import queryString from "query-string";
import { pageSize, filterAccountsOptions } from "utils/config";
import PrivilegedComponent from "components/PrivilegedComponent";
import { PRIVILEGES } from "utils/constants";
import Avatar from "../Avatar";
import AdvancedSearchModal from "components/AdvancedSearchModal";
import { RefineIcon, CloseSmallIcon } from "components/CustomIcons";
import RightSidebar from "components/ProcessesComponents/RightSidebar";
import ContentFilterView from "components/ProcessesComponents/ContentFilterView";
import ContentFilterProducedSpotsView from "components/ProcessesComponents/ContentFilterProducedSpotsView";
import ContentProductionArchiveView from "components/ProcessesComponents/ContentProductionArchiveView";
import FilteredProducedSpotTags from "components/ProcessesComponents/FilteredProducedSpotTags";
import FilteredProductionArchiveTags from "components/ProcessesComponents/FilteredProductionArchiveTags";
import ContentProductionQueueView from "components/ProcessesComponents/ContentProductionQueueView";
import FilteredProductionQueueTags from "components/ProcessesComponents/FilteredProductionQueueTags";
import { validatePrivileges } from "utils/helpers";

const bem = bn.create("process-list");
const defaultGlobalSort = "updated_ts desc";
const defaultSortByFilter = {
  my_action_items: "order_start_date",
  production_queue: "order_start_date",
  my_upcoming_items: "order_start_date",
  continuity_queue: "order_start_date",
  continuity_assignment: "order_start_date",
  my_continuity_assignment: "order_start_date",
  dubbing_queue: "order_start_date",
  produced_spots: "order_end_date desc",
  expiring_spots: "order_end_date desc",
  liners: "order_title asc",
  produced_digitals: "order_end_date desc",
  digital_queue: "order_start_date",
  assignments_queue: "order_start_date",
  multi_market_production_queue: "due_date",
  multi_market_list: "due_date",
};
class ProcessesList extends React.Component {
  componentDidMount() {
    const filter = get(this.props, "filter");
    let defaultSort = defaultGlobalSort;
    if (defaultSortByFilter[filter]) {
      defaultSort = defaultSortByFilter[filter];
    }
    if (get(this.props, `sortProcessesQueue.${filter}.sort`)) {
      defaultSort = get(this.props, `sortProcessesQueue.${filter}.sort`);
    }
    const sort =
      get(this.props.processes["filter_" + filter], "sort", "") || defaultSort;
    const page = get(this.props.processes["filter_" + filter], "page", "1");
    const rpp = get(this.props.processes["filter_" + filter], "rpp", pageSize);
    const search = get(this.props.processes["filter_" + filter], "search", "");

    if (filter) {
      this.props.setAdvancedSearch({});
      this.props.setFilterViewValues({
        accounts: "all",
        account_executive: "",
        search: "",
      });
      this.props.setFilterViewProducedSpotsValues({
        stations: [],
        client_id: "",
        expiring_in_days: "",
        statuses: [],
        spot_types: [],
        start_date: "",
        end_date: "",
      });
      this.props.setFilterViewProductionArchiveValues({
        stations: [],
        client_id: "",
        client_name: "",
        status: "",
        spot_types: [],
        start_date: "",
        end_date: "",
      });
      this.getProcesses({ filter, sort, page, rpp, search });
    }
  }

  componentWillUnmount() {
    const filter = get(this.props, "filter");
    // When user goes to some other page and come back or click the navbar link again we can load the first set of records
    this.props.forgetProcessesFilter({
      filter,
    });
  }

  componentDidUpdate(prevProps) {
    const filter = get(this.props, "filter");
    const prevFilter = get(prevProps, "filter");
    if (prevFilter !== filter) {
      this.props.forgetProcessesFilter({
        filter: prevFilter,
      });
      this.props.setAdvancedSearch({});
      this.props.setFilterViewValues({
        accounts: "all",
        account_executive: "",
        search: "",
      });
      this.props.setFilterViewProducedSpotsValues({
        stations: [],
        client_id: "",
        expiring_in_days: "",
        statuses: [],
        spot_types: [],
        start_date: "",
        end_date: "",
      });
      this.props.setFilterViewProductionArchiveValues({
        stations: [],
        client_id: "",
        client_name: "",
        status: "",
        spot_types: [],
        start_date: "",
        end_date: "",
      });
      this.props.setFilterViewProductionQueueValues({
        stations: [],
        client_id: "",
        client_name: "",
        status: "",
        spot_types: [],
        deadline: [],
      });
    }

    let defaultSort = defaultGlobalSort;

    if (defaultSortByFilter[filter]) {
      defaultSort = defaultSortByFilter[filter];
    }
    if (get(this.props, `sortProcessesQueue.${filter}.sort`)) {
      defaultSort = get(this.props, `sortProcessesQueue.${filter}.sort`);
    }
    const isDirty = get(this.props, `processes.filter_${filter}.is_dirty`);

    const sort = get(
      this.props.processes["filter_" + filter],
      "sort",
      defaultSort
    );
    let page = get(this.props.processes["filter_" + filter], "page", "1");
    let rpp = get(this.props.processes["filter_" + filter], "rpp", pageSize);
    let search = get(this.props.processes["filter_" + filter], "search", "");
    // const isSortingOrSearching = sort !== defaultSort || !!search
    // get processes list when new filter list or incoming filter is dirty and user is sorting or searching
    const isFetch =
      !this.props.processes["filter_" + filter] ||
      isDirty ||
      prevFilter !== filter;

    if (isFetch) {
      this.props.setAdvancedSearch({});
      this.props.setFilterViewValues({
        accounts: "all",
        account_executive: "",
        search: "",
      });
      this.props.setFilterViewProducedSpotsValues({
        stations: [],
        client_id: "",
        expiring_in_days: "",
        statuses: [],
        spot_types: [],
        start_date: "",
        end_date: "",
      });
      this.props.setFilterViewProductionArchiveValues({
        stations: [],
        client_id: "",
        client_name: "",
        status: "",
        spot_types: [],
        start_date: "",
        end_date: "",
      });
      this.props.setFilterViewProductionQueueValues({
        stations: [],
        client_id: "",
        client_name: "",
        status: "",
        spot_types: [],
        deadline: [],
      });
      this.getProcesses({ filter, sort, page, rpp, search });
    }
    const currentDirty = get(
      this.props,
      `processes.filter_${filter}.current_dirty`
    );
    if (currentDirty) {
      this.props.getProcessesInComing({
        filter,
        sort,
        page: 1,
        rpp,
        search,
        process_id: currentDirty,
      });
    }
  }

  getProcesses = (payload) => {
    const filter = get(this.props, "filter");
    // if (filter === "produced_spots") payload.template_key = "basic_production";
    if (filter === "produced_digitals" || filter === "digital_queue")
      payload.template_key = "digital";
    if (filter === "promotion_requests")
      payload.template_key = "promotion_request";
    if (filter === "promotion_contests") payload.template_key = "contest";
    if (
      ["multi_market_production_queue", "multi_market_list"].includes(filter)
    ) {
      this.props.setSortProcessesQueue({
        [filter]: {
          sort: get(payload, "sort"),
        },
      });
    }

    this.props.getProcesses(payload);
  };

  sort = (field) => {
    if (field === "type") {
      field = "order_sold";
    }

    const filter = get(this.props, "filter");

    // let defaultSort = defaultGlobalSort;
    // if (defaultSortByFilter[filter]) {
    //   defaultSort = defaultSortByFilter[filter];
    // }

    let prevSort = get(this.props.processes["filter_" + filter], "sort", "");

    let page = get(this.props.processes["filter_" + filter], "page", "1");
    let rpp = get(this.props.processes["filter_" + filter], "rpp", pageSize);
    let search = get(this.props.processes["filter_" + filter], "search", "");
    // detect on third click

    let sort = field;

    let sortTotally;

    if (sort !== prevSort) {
      sortTotally = true;
      page = 1;
    } else {
      sort = sort + " desc";
    }
    const filterParams = this.generateFilterPrams();
    this.getProcesses({
      ...filterParams,
      filter,
      sort,
      page,
      rpp,
      search,
      sortTotally,
    });
  };

  search(event) {
    event.preventDefault();
    const filter = get(this.props, "filter");
    let defaultSort = defaultGlobalSort;
    if (defaultSortByFilter[filter]) {
      defaultSort = defaultSortByFilter[filter];
    }
    let sort = get(
      this.props.processes["filter_" + filter],
      "sort",
      defaultSort
    );
    let page = get(this.props.processes["filter_" + filter], "page", "1");
    let rpp = get(this.props.processes["filter_" + filter], "rpp", pageSize);
    let search = event.target.elements[0].value;
    let prevSearch = get(
      this.props.processes["filter_" + filter],
      "search",
      ""
    );
    if (search !== prevSearch) {
      page = 1;
      const filterParams = this.generateFilterPrams();

      this.getProcesses({ ...filterParams, filter, sort, page, rpp, search });
    }
  }

  generateFilterPrams = () => {
    const filter = get(this.props, "filter");
    let filterValues = {};
    if (filter === "produced_spots") {
      filterValues = this.props.filterViewProducedSpotsValues;
      const stations = get(filterValues, "stations", []).map(
        (item) => item.value
      );
      const client_id = filterValues.client_id;
      const expiring_in_days = filterValues.expiring_in_days;
      const statuses = filterValues.statuses;
      const spot_status = filterValues.spot_status;
      const spot_types = filterValues.spot_types;
      const start_date = filterValues.start_date;
      const end_date = filterValues.end_date;
      return {
        stations,
        client_id,
        expiring_in_days,
        statuses,
        spot_status,
        spot_types,
        start_date,
        end_date,
      };
    } else if (filter === "archived_spots") {
      filterValues = this.props.filterViewProductionArchiveValues;
      const stations = get(filterValues, "stations", []).map(
        (item) => item.value
      );
      const client_name = filterValues.client_name;
      const status = filterValues.status;
      const spot_status = filterValues.spot_status;
      const spot_types = filterValues.spot_types;
      const start_date = filterValues.start_date;
      const end_date = filterValues.end_date;
      return {
        stations,
        client_name,
        status,
        spot_status,
        spot_types,
        start_date,
        end_date,
      };
    } else if (filter === "expiring_spots") {
      filterValues = this.props.filterViewValues;
      const search = filterValues.search;
      const accounts = filterValues.accounts;
      const account_executive = filterValues.account_executive
        ? [filterValues.account_executive]
        : "";
      return {
        search,
        accounts,
        account_executive,
      };
    } else if (
      ["production_queue", "multi_market_production_queue"].includes(filter)
    ) {
      filterValues = this.props.filterViewProductionQueueValues;
      const stations = get(filterValues, "stations", []).map(
        (item) => item.value
      );
      const client_name = filterValues.client_name;
      const status = filterValues.status;
      const spot_status = filterValues.spot_status;
      const spot_types = filterValues.spot_types;
      const deadline = filterValues.deadline;
      return {
        stations_ids: stations,
        client_name,
        status,
        spot_status,
        spot_types,
        deadline,
      };
    }
  };
  onAdvancedSearch = (values) => {
    const filter = get(this.props, "filter");
    this.props.setIsShowAdvancedSearchModal(false);
    const sort = "search";
    let page = 1;
    const rpp = get(this.props.processes["filter_" + filter], "rpp", pageSize);
    const search = values.keyword;
    const statuses = values.status;
    this.props.setAdvancedSearch(values);

    const start_date = values.start_date;
    const end_date = values.end_date;
    this.getProcesses({
      filter,
      sort,
      page,
      rpp,
      search,
      statuses,
      start_date,
      end_date,
    });
  };
  onClearSearch = () => {
    const filter = get(this.props, "filter");

    let defaultSort = defaultGlobalSort;
    if (defaultSortByFilter[filter]) {
      defaultSort = defaultSortByFilter[filter];
    }

    let sort = get(
      this.props.processes["filter_" + filter],
      "sort",
      defaultSort
    );

    let page = get(this.props.processes["filter_" + filter], "page", "1");
    let rpp = get(this.props.processes["filter_" + filter], "rpp", pageSize);
    let search = "";
    this.getProcesses({ filter, sort, page, rpp, search });
    this.props.setAdvancedSearch({});
    this.props.setFilterViewValues({
      accounts: "all",
      account_executive: "",
      search: "",
    });
    this.props.setFilterViewProducedSpotsValues({
      stations: [],
      client_id: "",
      expiring_in_days: "",
      statuses: [],
      spot_types: [],
      start_date: "",
      end_date: "",
    });
    this.props.setFilterViewProductionArchiveValues({
      stations: [],
      client_id: "",
      client_name: "",
      status: "",
      spot_types: [],
      start_date: "",
      end_date: "",
    });
    this.props.setFilterViewProductionQueueValues({
      stations: [],
      client_id: "",
      client_name: "",
      status: "",
      spot_types: [],
      deadline: [],
    });
  };

  onSubmitFilter = (filterValues) => {
    this.props.setFilterViewValues(filterValues);
    this.props.setIsOpenSidebar(false);
    const filter = get(this.props, "filter");
    let defaultSort = defaultGlobalSort;
    if (defaultSortByFilter[filter]) {
      defaultSort = defaultSortByFilter[filter];
    }
    const sort = get(
      this.props.processes["filter_" + filter],
      "sort",
      defaultSort
    );
    let page = 1;
    const rpp = get(this.props.processes["filter_" + filter], "rpp", pageSize);
    const search = filterValues.search;
    const accounts = filterValues.accounts;
    const account_executive = filterValues.account_executive
      ? [filterValues.account_executive]
      : "";

    this.getProcesses({
      filter,
      sort,
      page,
      rpp,
      search,
      accounts,
      account_executive,
    });
  };

  onSubmitProducedSpotsFilter = (filterValues) => {
    this.props.setFilterViewProducedSpotsValues(filterValues);
    this.props.setIsOpenSidebar(false);
    const filter = get(this.props, "filter");
    let defaultSort = defaultGlobalSort;
    if (defaultSortByFilter[filter]) {
      defaultSort = defaultSortByFilter[filter];
    }
    const sort = get(
      this.props.processes["filter_" + filter],
      "sort",
      defaultSort
    );
    let page = 1;
    const rpp = get(this.props.processes["filter_" + filter], "rpp", pageSize);
    const search = get(this.props.processes["filter_" + filter], "search", "");
    const stations = get(filterValues, "stations", []).map(
      (item) => item.value
    );
    const client_id = filterValues.client_id;
    const expiring_in_days = filterValues.expiring_in_days;
    const statuses = filterValues.statuses;
    const spot_status = filterValues.spot_status;
    const spot_types = filterValues.spot_types;
    const start_date = filterValues.start_date;
    const end_date = filterValues.end_date;
    this.getProcesses({
      filter,
      sort,
      page,
      rpp,
      search,
      stations,
      client_id,
      expiring_in_days,
      statuses,
      spot_status,
      spot_types,
      start_date,
      end_date,
    });
  };

  onSubmitProductionArchiveFilter = (filterValues) => {
    this.props.setFilterViewProductionArchiveValues(filterValues);
    this.props.setIsOpenSidebar(false);
    const filter = get(this.props, "filter");
    let defaultSort = defaultGlobalSort;
    if (defaultSortByFilter[filter]) {
      defaultSort = defaultSortByFilter[filter];
    }
    const sort = get(
      this.props.processes["filter_" + filter],
      "sort",
      defaultSort
    );
    let page = 1;
    const rpp = get(this.props.processes["filter_" + filter], "rpp", pageSize);
    const search = get(this.props.processes["filter_" + filter], "search", "");
    const stations = get(filterValues, "stations", []).map(
      (item) => item.value
    );
    const status = filterValues.status;
    const spot_status = filterValues.spot_status;
    const spot_types = filterValues.spot_types;
    const start_date = filterValues.start_date;
    const end_date = filterValues.end_date;
    const client_name = filterValues.client_name;
    this.getProcesses({
      filter,
      sort,
      page,
      rpp,
      search,
      stations,
      client_name,
      status,
      spot_status,
      spot_types,
      start_date,
      end_date,
    });
  };

  onSubmitProductionQueueFilter = (filterValues) => {
    this.props.setFilterViewProductionQueueValues(filterValues);
    this.props.setIsOpenSidebar(false);
    const filter = get(this.props, "filter");
    let defaultSort = defaultGlobalSort;
    if (defaultSortByFilter[filter]) {
      defaultSort = defaultSortByFilter[filter];
    }
    const sort = get(
      this.props.processes["filter_" + filter],
      "sort",
      defaultSort
    );
    let page = 1;
    const rpp = get(this.props.processes["filter_" + filter], "rpp", pageSize);
    const search = get(this.props.processes["filter_" + filter], "search", "");
    const stations = get(filterValues, "stations", []).map(
      (item) => item.value
    );
    const status = filterValues.status;
    const spot_types = filterValues.spot_types;
    const spot_status = filterValues.spot_status;
    const deadline = filterValues.deadline;
    const client_name = filterValues.client_name;
    this.getProcesses({
      filter,
      sort,
      page,
      rpp,
      search,
      stations_ids: stations,
      client_name,
      status,
      spot_status,
      spot_types,
      deadline,
    });
  };

  usersDisplay(user_ids) {
    let users = user_ids.map((user_id) =>
      this.props.auth.info.users.find((user) => user.id === user_id)
    );
    return (
      <span>
        {users.map((user, index) => (
          <div key={index}>
            <Avatar src={user.avatar_url} size={20} className="mb-2" />
            {` `}
            {user.name}
          </div>
        ))}
      </span>
    );
  }

  actionDisplay(process) {
    if (process._source.last_action) {
      let payload = { message: process._source.last_action };
      if (payload.message.chat) {
        return (
          <span>
            <Avatar
              src={get(payload, "message.from.avatar_url", "")}
              size={20}
            />
            <strong>{get(payload, "message.from.name", "")}</strong>
            <span>
              {" "}
              <FormattedMessage id="process > wrote" />{" "}
            </span>
            <span>
              {` `}
              {payload.message.chat}
            </span>
          </span>
        );
      } else {
        return (
          <span>
            <Avatar src={get(payload, "message.from.avatar_url", "")} />
            <strong>{get(payload, "message.from.name", "")}</strong>
            <span>
              {" "}
              <FormattedMessage id="process > submitted" />{" "}
            </span>
            <span>
              {` `}
              {payload.message.from_step &&
                payload.message.from_step.replace(/_/, " ", "gi") + "'"}
            </span>
          </span>
        );
      }
    }
    return null;
  }

  onClickRow = (process) => {
    const { filter } = this.props;
    const params = {
      template_id: process._source.template_key,
      process_id: process._id,
    };
    // this.props.getTemplate(params.template_id, this.props.auth.info);
    this.props.resetProcess();
    this.props.resetTab();
    let url = URL.VIEW_PROCESS(params);
    if (["liner", "contest"].includes(process._source.template_key)) {
      // Make the order in the List open up in EDIT mode when clicked on, if the User has the EDIT privileges . https://tasks.getventive.com/projects/D6CB3-068
      url = URL.UPDATE_PROCESS(params);
    }
    if (filter === "my_action_items") {
      //  Once submitted, or saved as a draft, the Order tab should open in View mode, regardless of who is opening it
      if (
        !["draft", "order_details"].includes(
          get(process, "_source.process_step")
        )
      ) {
        url = URL.UPDATE_PROCESS(params);
        this.props.setActiveEditTab(get(process, "_source.process_step"));
      }
    } else if (filter === "dubbing_queue") {
      this.props.setActiveEditTab(get(process, "_source.process_step"));
    } else if (
      filter === "continuity_assignment" ||
      filter === "continuity_queue"
    ) {
      url = URL.UPDATE_PROCESS(params);
      // When order is status other than continuity and if user opens it from continuity assignments queue then it should open in Continuity tab.
      this.props.setActiveEditTab("continuity");
    } else if (
      [
        "multi_market_list",
        "multi_market_production_queue",
        "production_queue",
      ].includes(filter)
    ) {
      // if order enabled writer sends script to client or producer sends spot to client. We don't have approve_script or approve_recording step
      const spotInfo = get(process, "_source.spot_info", []).find(
        (spot) => spot.key === process._id
      );
      const writerSendsScriptToClient = get(
        spotInfo,
        "writer_sends_script_to_client",
        false
      );
      const producerSendsSpotToClient = get(
        spotInfo,
        "producer_sends_spot_to_client",
        false
      );
      if (
        (writerSendsScriptToClient &&
          get(process, "_source.process_step") === "approve_script") ||
        (producerSendsSpotToClient &&
          get(process, "_source.process_step") === "approve_recording")
      ) {
        this.props.setActiveTab(null);
      } else {
        this.props.setActiveTab(get(process, "_source.process_step"));
      }
    } else if (filter === "assignments_queue") {
      url = URL.UPDATE_PROCESS(params);
      // When order is status other than assign_team and if user opens it from assignments queue then it should open in Assignments tab.
      this.props.setActiveEditTab("assign_team");
    } else {
      this.props.setActiveTab(null);
    }
    let hidden_tabs = [];
    if (filter === "produced_spots") {
      hidden_tabs = ["approve_recording"];
    }
    history.push(
      `${url}?${queryString.stringify({
        source: filter,
      })}`,
      {
        hidden_tabs,
      }
    );
  };

  handleLoadMore = () => {
    const filter = get(this.props, "filter");
    const currentPage = get(
      this.props.processes["filter_" + filter],
      "page",
      "1"
    );

    let defaultSort = defaultGlobalSort;
    if (defaultSortByFilter[filter]) {
      defaultSort = defaultSortByFilter[filter];
    }

    const sort = get(
      this.props.processes["filter_" + filter],
      "sort",
      defaultSort
    );

    const rpp = get(this.props.processes["filter_" + filter], "rpp", pageSize);
    const pages = get(this.props.processes["filter_" + filter], "pages");
    const search = get(this.props.processes["filter_" + filter], "search", "");
    const page = Number(currentPage) + 1;
    if (
      !get(this.props.processes, `filter_${filter}.loading`) &&
      parseInt(pages) > Number(currentPage)
    ) {
      const filterParams = this.generateFilterPrams();
      this.getProcesses({ ...filterParams, filter, sort, page, rpp, search });
    }
  };

  getPlaceholderSearchInput = () => {
    const filter = get(this.props, "filter");
    if (filter === "produced_spots") {
      return this.props.intl.formatMessage({
        id: "process > placeholder search for a client, title, cart or order",
      });
    }
    if (filter === "promotion_contests") {
      return this.props.intl.formatMessage({
        id: "process > placeholder search",
      });
    }
    if (
      [
        "multi_market_production_queue",
        "multi_market_list",
        "my_action_items",
        "production_queue",
      ].includes(filter)
    ) {
      return this.props.intl.formatMessage({
        id: "process > placeholder search for client, title or cart",
      });
    }
    return this.props.intl.formatMessage({
      id: "process > placeholder search for a client, title or order",
    });
  };

  renderTable() {
    const { processes, filter, user, users } = this.props;

    let defaultSort = defaultGlobalSort;
    if (defaultSortByFilter[filter]) {
      defaultSort = defaultSortByFilter[filter];
    }

    const sort = get(
      this.props.processes["filter_" + filter],
      "sort",
      defaultSort
    );

    let pages = get(this.props.processes["filter_" + filter], "pages");
    let page = get(this.props.processes["filter_" + filter], "page", 1);
    let processList =
      get(processes, `filter_${filter}.data`, []) !== null
        ? get(processes, `filter_${filter}.data`, [])
        : [];

    if (filter === "my_action_items") {
      processList = processList.filter(
        (process) => !get(process, "_source.hide", false)
      );
    }

    if (filter === "produced_spots") {
      return (
        <ProducedSpotsTable
          processList={processList}
          bem={bem}
          user={user}
          sort={sort}
          actionSort={this.sort}
          onClickRow={this.onClickRow}
          template={this.props.template}
          loadItems={this.handleLoadMore}
          hasMoreItems={parseInt(pages) > parseInt(page)}
          isLoading={get(processes, `filter_${filter}.loading`)}
          dateFormatByServer={this.props.dateFormatByServer}
        />
      );
    } else if (filter === "archived_spots") {
      return (
        <ProductionArchiveTable
          processList={processList}
          bem={bem}
          user={user}
          sort={sort}
          actionSort={this.sort}
          onClickRow={this.onClickRow}
          template={this.props.template}
          loadItems={this.handleLoadMore}
          hasMoreItems={parseInt(pages) > parseInt(page)}
          isLoading={get(processes, `filter_${filter}.loading`)}
          dateFormatByServer={this.props.dateFormatByServer}
        />
      );
    } else if (filter === "produced_digitals") {
      return (
        <ProducedDigitalsTable
          processList={processList}
          bem={bem}
          sort={sort}
          actionSort={this.sort}
          // countdowns={this.props.countdowns}
          onClickRow={this.onClickRow}
          template={this.props.template}
          user={user}
          filter={filter}
          users={users}
          loadItems={this.handleLoadMore}
          isLoading={get(processes, `filter_${filter}.loading`)}
          hasMoreItems={parseInt(pages) > parseInt(page)}
          search={get(this.props.processes["filter_" + filter], "search", "")}
          dateFormatByServer={this.props.dateFormatByServer}
        />
      );
    } else if (filter === "digital_queue") {
      return (
        <DigitalQueueTable
          processList={processList}
          bem={bem}
          sort={sort}
          actionSort={this.sort}
          onClickRow={this.onClickRow}
          template={this.props.template}
          user={user}
          filter={filter}
          users={users}
          loadItems={this.handleLoadMore}
          isLoading={get(processes, `filter_${filter}.loading`)}
          hasMoreItems={parseInt(pages) > parseInt(page)}
          search={get(this.props.processes["filter_" + filter], "search", "")}
          dateFormatByServer={this.props.dateFormatByServer}
        />
      );
    } else if (filter === "liners") {
      return (
        <PromotionLinersTable
          processList={processList}
          bem={bem}
          sort={sort}
          actionSort={this.sort}
          // countdowns={this.props.countdowns}
          onClickRow={this.onClickRow}
          template={this.props.template}
          user={user}
          filter={filter}
          users={users}
          loadItems={this.handleLoadMore}
          isLoading={get(processes, `filter_${filter}.loading`)}
          hasMoreItems={parseInt(pages) > parseInt(page)}
          search={get(this.props.processes["filter_" + filter], "search", "")}
          stations={get(this.props, "serverInfo.stations", [])}
          dateFormatByServer={this.props.dateFormatByServer}
        />
      );
    } else if (filter === "continuity_assignment") {
      return (
        <ContinuityAssignments
          processList={processList}
          bem={bem}
          sort={sort}
          actionSort={this.sort}
          // countdowns={this.props.countdowns}
          onClickRow={this.onClickRow}
          template={this.props.template}
          user={user}
          filter={filter}
          users={users}
          loadItems={this.handleLoadMore}
          isLoading={get(processes, `filter_${filter}.loading`)}
          hasMoreItems={parseInt(pages) > parseInt(page)}
          search={get(this.props.processes["filter_" + filter], "search", "")}
          dateFormatByServer={this.props.dateFormatByServer}
        />
      );
    } else if (filter === "continuity_queue") {
      return (
        <ContinuityQueue
          processList={processList}
          bem={bem}
          sort={sort}
          actionSort={this.sort}
          // countdowns={this.props.countdowns}
          onClickRow={this.onClickRow}
          template={this.props.template}
          user={user}
          filter={filter}
          users={users}
          loadItems={this.handleLoadMore}
          isLoading={get(processes, `filter_${filter}.loading`)}
          hasMoreItems={parseInt(pages) > parseInt(page)}
          search={get(this.props.processes["filter_" + filter], "search", "")}
          intl={this.props.intl}
          dateFormatByServer={this.props.dateFormatByServer}
        />
      );
    } else if (filter === "assignments_queue") {
      return (
        <AssignmentsQueue
          processList={processList}
          bem={bem}
          sort={sort}
          actionSort={this.sort}
          onClickRow={this.onClickRow}
          template={this.props.template}
          user={user}
          filter={filter}
          users={users}
          loadItems={this.handleLoadMore}
          isLoading={get(processes, `filter_${filter}.loading`)}
          hasMoreItems={parseInt(pages) > parseInt(page)}
          search={get(this.props.processes["filter_" + filter], "search", "")}
          intl={this.props.intl}
          dateFormatByServer={this.props.dateFormatByServer}
        />
      );
    } else if (filter === "dubbing_queue") {
      return (
        <DubbingQueue
          processList={processList}
          bem={bem}
          sort={sort}
          actionSort={this.sort}
          // countdowns={this.props.countdowns}
          onClickRow={this.onClickRow}
          template={this.props.template}
          user={user}
          filter={filter}
          users={users}
          loadItems={this.handleLoadMore}
          isLoading={get(processes, `filter_${filter}.loading`)}
          hasMoreItems={parseInt(pages) > parseInt(page)}
          search={get(this.props.processes["filter_" + filter], "search", "")}
          dateFormatByServer={this.props.dateFormatByServer}
        />
      );
    } else if (filter === "promotion_contests") {
      return (
        <PromotionContestsTable
          processList={processList}
          bem={bem}
          sort={sort}
          actionSort={this.sort}
          // countdowns={this.props.countdowns}
          onClickRow={this.onClickRow}
          template={this.props.template}
          user={user}
          info={this.props.auth.info}
          filter={filter}
          users={users}
          loadItems={this.handleLoadMore}
          isLoading={get(processes, `filter_${filter}.loading`)}
          hasMoreItems={parseInt(pages) > parseInt(page)}
          search={get(this.props.processes["filter_" + filter], "search", "")}
          dateFormatByServer={this.props.dateFormatByServer}
        />
      );
    } else if (filter === "expiring_spots") {
      return (
        <ExpiringSpotsTable
          processList={processList}
          bem={bem}
          sort={sort}
          actionSort={this.sort}
          onClickRow={this.onClickRow}
          template={this.props.template}
          loadItems={this.handleLoadMore}
          hasMoreItems={parseInt(pages) > parseInt(page)}
          isLoading={get(processes, `filter_${filter}.loading`)}
          // countdowns={this.props.countdowns}
          intl={this.props.intl}
          dateFormatByServer={this.props.dateFormatByServer}
        />
      );
    } else if (filter === "multi_market_list") {
      return (
        <MultiMarketQueueTable
          processList={processList}
          bem={bem}
          sort={sort}
          actionSort={this.sort}
          // countdowns={this.props.countdowns}
          onClickRow={this.onClickRow}
          template={this.props.template}
          user={user}
          filter={filter}
          users={users}
          loadItems={this.handleLoadMore}
          isLoading={get(processes, `filter_${filter}.loading`)}
          hasMoreItems={parseInt(pages) > parseInt(page)}
          search={get(this.props.processes["filter_" + filter], "search", "")}
          intl={this.props.intl}
          info={this.props.auth.info}
          dateFormatByServer={this.props.dateFormatByServer}
        />
      );
    } else if (filter === "multi_market_production_queue") {
      return (
        <MultiMarketQueueTable
          processList={processList}
          bem={bem}
          sort={sort}
          actionSort={this.sort}
          onClickRow={this.onClickRow}
          template={this.props.template}
          user={user}
          filter={filter}
          users={users}
          loadItems={this.handleLoadMore}
          isLoading={get(processes, `filter_${filter}.loading`)}
          hasMoreItems={parseInt(pages) > parseInt(page)}
          search={get(this.props.processes["filter_" + filter], "search", "")}
          intl={this.props.intl}
          info={this.props.auth.info}
          dateFormatByServer={this.props.dateFormatByServer}
        />
      );
    } else {
      return (
        <ProcessesTable
          processList={processList}
          bem={bem}
          sort={sort}
          actionSort={this.sort}
          // countdowns={this.props.countdowns}
          onClickRow={this.onClickRow}
          template={this.props.template}
          user={user}
          filter={filter}
          users={users}
          loadItems={this.handleLoadMore}
          isLoading={get(processes, `filter_${filter}.loading`)}
          hasMoreItems={parseInt(pages) > parseInt(page)}
          search={get(this.props.processes["filter_" + filter], "search", "")}
          intl={this.props.intl}
          dateFormatByServer={this.props.dateFormatByServer}
        />
      );
    }
  }
  render() {
    const { processes, filter, user, auth } = this.props;
    const privileges = get(auth, "user.privileges", []);
    const showTotalResults = includes(
      [
        "expiring_spots",
        "produced_spots",
        "archived_spots",
        "production_queue",
        "multi_market_production_queue",
      ],
      filter
    );
    // let showProductionOrderButton = includes(['my_action_items', 'production_queue', 'produced_spots'], filter);
    // let showDigitalOrderButton = includes(['my_action_items', 'produced_digitals', 'digital_queue'], filter);
    let showPromotionLinerButton = includes(["liners"], filter);
    let showPromotionContestButton = includes(["promotion_contests"], filter);

    const { disabled_contesting, enable_promotion } = this.props.serverInfo;
    const enable_contesting = !disabled_contesting;
    let processList =
      get(processes, `filter_${filter}.data`, []) !== null
        ? get(processes, `filter_${filter}.data`, [])
        : [];
    const totalNumber = get(processes, `filter_${filter}.total`);
    const isValidPrivilegesShowFilterView =
      includes(
        [
          "produced_spots",
          "archived_spots",
          "production_queue",
          "multi_market_production_queue",
        ],
        filter
      ) ||
      (filter === "expiring_spots" &&
        validatePrivileges({
          requires: [
            PRIVILEGES.VIEW_ALL_PRODUCTION_ORDERS,
            PRIVILEGES.VIEW_EXPIRING_SPOTS,
          ],
          privileges,
          user,
        }));
    return (
      <div className={classnames("fluid-container", bem.b())}>
        <div className={bem.e("header")} id="processes-header">
          <div className={bem.e("title")}>
            <span>
              <FormattedMessage id="process > home" /> /{" "}
            </span>
            <strong>
              <FormattedMessage id={`process > title ${filter}`} />
            </strong>
          </div>
          <div className={bem.e("header-actions")}>
            {enable_promotion && showPromotionLinerButton && (
              <PrivilegedComponent
                requires={[PRIVILEGES.PROGRAMMING_ELEMENT_SAVE]}
              >
                {({ isValid }) => {
                  if (isValid || user.is_god_admin) {
                    return (
                      <div style={{ marginRight: "10px" }}>
                        <ButtonAdd
                          onClick={() => {
                            this.props.setSchedulingAvailability({});
                            history.push(
                              URL.ADD_PROCESS({
                                template_id: "liner",
                              })
                            );
                          }}
                          children={
                            <FormattedMessage id="dashboard > add new liner item" />
                          }
                        />
                      </div>
                    );
                  } else {
                    return null;
                  }
                }}
              </PrivilegedComponent>
            )}
            {enable_contesting && showPromotionContestButton && (
              <PrivilegedComponent requires={[PRIVILEGES.CONTEST_SAVE]}>
                {({ isValid }) => {
                  if (isValid || user.is_god_admin) {
                    return (
                      <div style={{ marginRight: "10px" }}>
                        <ButtonAdd
                          onClick={() => {
                            this.props.resetProcess();
                            this.props.setSchedulingAvailability({});
                            this.props.setFetchFulfillmentSettings([]);
                            history.push(
                              URL.ADD_PROCESS({
                                template_id: "contest",
                              })
                            );
                          }}
                          children={
                            <FormattedMessage id="dashboard > add new contest item" />
                          }
                        />
                      </div>
                    );
                  } else {
                    return null;
                  }
                }}
              </PrivilegedComponent>
            )}
            {includes(
              [
                "my_upcoming_items",
                "my_action_items",
                "promotion_requests",
                "continuity_assignment",
                "continuity_queue",
                "expiring_spots",
                "assignments_queue",
                "produced_spots",
                "archived_spots",
                "production_queue",
                "multi_market_production_queue",
              ],
              filter
            ) &&
              this.props.isShowPageTitle && (
                <div className="process-title">
                  <FormattedMessage id={`process > title ${filter}`} />
                </div>
              )}
            <div className={bem.e("search-input")}>
              {isValidPrivilegesShowFilterView && (
                <div
                  className={bem.e("filter-view")}
                  onClick={() => {
                    let type = "filter-view";
                    if (filter === "produced_spots") {
                      type = "filter-view-produced-spots";
                    } else if (filter === "archived_spots") {
                      type = "filter-view-production-archive";
                    } else if (
                      [
                        "production_queue",
                        "multi_market_production_queue",
                      ].includes(filter)
                    ) {
                      type = "filter-view-production-queue";
                    }
                    this.props.setSidebarType(type);
                    this.props.setIsOpenSidebar(true);
                  }}
                >
                  <FormattedMessage id="process > filter view" />
                  <RefineIcon />
                </div>
              )}
              <div className={bem.e("search-wrapper")}>
                {includes(["liners", "promotion_contests"], filter) && (
                  <span
                    onClick={() =>
                      this.props.setIsShowAdvancedSearchModal(true)
                    }
                    className={bem.e("advanced-search-text")}
                  >
                    <FormattedMessage id="process > advanced search" />
                  </span>
                )}
                <SearchInput
                  placeholder={this.getPlaceholderSearchInput()}
                  onClear={this.onClearSearch}
                  onSubmit={this.search.bind(this)}
                  onChange={(value) => {
                    this.props.setKeyword(value);
                    this.props.setAdvancedSearch({ keyword: value });
                    this.props.setFilterViewValues({
                      ...this.props.filterViewValues,
                      search: value,
                    });
                  }}
                  value={get(
                    this.props.processes["filter_" + filter],
                    "search",
                    ""
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className={classnames(bem.e("process-list-container"), {
            [bem.e("process-list-results")]: showTotalResults,
          })}
        >
          {this.props.full_message && processList.length > 0 && (
            <CardBody>{this.props.full_message}</CardBody>
          )}
          {this.props.empty_message && processList.length === 0 && (
            <CardBody>{this.props.empty_message}</CardBody>
          )}
          {showTotalResults ? (
            <div className={bem.e("header-results")}>
              <div className={bem.e("total-results")}>
                <FormattedMessage id="process > viewing" />
                <strong>{totalNumber}</strong>
                <FormattedMessage id="process > results" />:
              </div>
              <div className={bem.e("filter-tags")}>
                {/* expiring spots filtered */}
                {filter === "expiring_spots" ? (
                  <>
                    {this.props.filterViewValues.accounts &&
                    this.props.filterViewValues.accounts !== "all" ? (
                      <div className={bem.e("filter-tag")}>
                        {
                          filterAccountsOptions.find(
                            (i) =>
                              i.value === this.props.filterViewValues.accounts
                          ).label
                        }
                        <span
                          className={bem.e("remove-tag")}
                          onClick={() => {
                            this.onSubmitFilter({
                              ...this.props.filterViewValues,
                              accounts: "all",
                            });
                          }}
                        >
                          <CloseSmallIcon />
                        </span>
                      </div>
                    ) : null}
                    {this.props.filterViewValues.account_executive ? (
                      <div className={bem.e("filter-tag")}>
                        <span>
                          {get(
                            this.props.users.find(
                              (i) =>
                                i.id ===
                                Number(
                                  this.props.filterViewValues.account_executive
                                )
                            ),
                            "name",
                            ""
                          )}
                        </span>
                        <span
                          className={bem.e("remove-tag")}
                          onClick={() => {
                            this.onSubmitFilter({
                              ...this.props.filterViewValues,
                              account_executive: "",
                            });
                          }}
                        >
                          <CloseSmallIcon />
                        </span>
                      </div>
                    ) : null}
                  </>
                ) : null}
                {/* produced spots filtered */}
                {filter === "produced_spots" ? (
                  <FilteredProducedSpotTags
                    values={this.props.filterViewProducedSpotsValues}
                    onSubmit={this.onSubmitProducedSpotsFilter}
                    bem={bem}
                    dateFormatByServer={this.props.dateFormatByServer}
                  />
                ) : filter === "archived_spots" ? (
                  <FilteredProductionArchiveTags
                    values={this.props.filterViewProductionArchiveValues}
                    onSubmit={this.onSubmitProductionArchiveFilter}
                    bem={bem}
                    dateFormatByServer={this.props.dateFormatByServer}
                  />
                ) : [
                    "production_queue",
                    "multi_market_production_queue",
                  ].includes(filter) ? (
                  <FilteredProductionQueueTags
                    values={this.props.filterViewProductionQueueValues}
                    onSubmit={this.onSubmitProductionQueueFilter}
                    bem={bem}
                    dateFormatByServer={this.props.dateFormatByServer}
                  />
                ) : null}
              </div>
            </div>
          ) : null}
          {this.renderTable()}
        </div>
        <Spinner
          isLoading={!processList || get(processes, `filter_${filter}.loading`)}
        />
        <AdvancedSearchModal
          isOpen={this.props.isShowAdvancedSearchModal}
          onToggle={() =>
            this.props.setIsShowAdvancedSearchModal(
              !this.props.isShowAdvancedSearchModal
            )
          }
          onSubmit={this.onAdvancedSearch}
          initialValues={this.props.advancedSearch}
        />
        <RightSidebar
          isOpen={this.props.isOpenSidebar}
          onCancel={() => this.props.setIsOpenSidebar(false)}
          isAllowCloseAnywhere={false}
          type={this.props.sidebarType}
          title={
            [
              "filter-view",
              "filter-view-produced-spots",
              "filter-view-production-archive",
              "filter-view-production-queue",
            ].includes(this.props.sidebarType)
              ? this.props.intl.formatMessage({
                  id: "process > filter your view",
                })
              : null
          }
          children={
            this.props.sidebarType === "filter-view" ? (
              <ContentFilterView
                initialValues={this.props.filterViewValues}
                onSubmit={this.onSubmitFilter}
              />
            ) : this.props.sidebarType === "filter-view-produced-spots" ? (
              <ContentFilterProducedSpotsView
                initialValues={this.props.filterViewProducedSpotsValues}
                onSubmit={this.onSubmitProducedSpotsFilter}
              />
            ) : this.props.sidebarType === "filter-view-production-archive" ? (
              <ContentProductionArchiveView
                initialValues={this.props.filterViewProductionArchiveValues}
                onSubmit={this.onSubmitProductionArchiveFilter}
              />
            ) : this.props.sidebarType === "filter-view-production-queue" ? (
              <ContentProductionQueueView
                initialValues={this.props.filterViewProductionQueueValues}
                onSubmit={this.onSubmitProductionQueueFilter}
              />
            ) : null
          }
        />
      </div>
    );
  }
}

export default ProcessesList;
