import React, { useState, useRef, useEffect, useMemo } from "react";
import { Button } from "reactstrap";
import { get, filter, includes, find, toLower } from "lodash";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import Dropdown from "components/Dropdown";
import PropTypes from "prop-types";
import ToggleSwitch from "components/ToggleSwitch";
import { FaCheckCircle } from "react-icons/lib/fa";
import bn from "utils/bemnames";
import SearchInput from "components/SearchInput";
import { isHtml } from "utils/helpers";
import { getRoleUsersByHubMembers } from "../helper";
import stepAssignTeam from "utils/DefaultTemplates/BaseProduction/steps/assignTeam";
import UserRows from "./UserRows";
import ConfirmationModal from "components/ConfirmationModal";
import Spinner from "components/Spinner";
import TextareaAutosize from "react-textarea-autosize";
import {
  CheckDoneIcon,
  ArrowUpIcon,
  ArrowDownIcon,
} from "components/CustomIcons";
import TextareaEvaluator from "components/TextareaEvaluator";
import { setScriptField } from "utils/helpers";
import { Row, Col } from "reactstrap";
import TextInput from "components/TextInput";
import moment from "moment";

const HEADER_HEIGHT = 95;
const FOOTER_HEIGHT = 95;
const bem = bn.create("assign-team-sidebar");
function getAssignmentTeamFromProcess(process) {
  const writerUsers = get(process, "_source.role_writer.users", []);
  const voiceUsers = get(process, "_source.role_voice_talent.users", []);
  const producerUsers = get(process, "_source.role_producer.users", []);
  const dubberUsers = get(process, "_source.role_dubber.users", []);
  const assignmentDeadline = get(process, "_source.assignment_deadline", "");
  const scriptDeadline = get(process, "_source.script_deadline", "");
  const voiceDeadline = get(process, "_source.voice_deadline", "");
  const recordDeadline = get(process, "_source.record_deadline", "");

  return {
    role_writer: writerUsers,
    role_voice_talent: voiceUsers,
    role_producer: producerUsers,
    role_dubber: dubberUsers,
    assignment_deadline: assignmentDeadline,
    script_deadline: scriptDeadline,
    voice_deadline: voiceDeadline,
    record_deadline: recordDeadline,
  };
}

function SectionContent(props) {
  const { item } = props;
  if (item.key === "copy_points") {
    const isCopyPointsHtml = isHtml(get(item, "data"));
    return (
      <div className={bem.e("section-content-item")}>
        <div className={bem.e("content-details")}>
          <p className={bem.e("content-text")}>
            {get(item, "data", "") ? (
              isCopyPointsHtml ? (
                <TextareaEvaluator
                  text={setScriptField(get(item, "data", ""))}
                  isEditorOnly={true}
                  hideOrderLength={true}
                  readOnly={true}
                  process={process}
                  onEvaluatorCallback={() => {}}
                />
              ) : (
                <TextareaAutosize
                  value={get(item, "data", "")}
                  className="text-area-view"
                  disabled
                />
              )
            ) : (
              <FormattedMessage id="process > none" />
            )}
          </p>
        </div>
      </div>
    );
  }
  return <div></div>;
}
const AssignTeam = (props) => {
  const {
    process,
    onClose,
    members,
    intl,
    onSubmit,
    setIsShowSuccessModal,
    isShowSuccessModal,
    enterpriseSettings,
    isEdit,
    setIsEdit,
    getTeamMembersForAssignment,
    isLoading,
    isShowAssignedVo,
  } = props;
  const contentHeight = useRef(0);
  const [searchText, setSearchText] = React.useState("");
  const [selectedRole, setSelectedRole] = useState(null);
  const [isAudition, setIsAudition] = useState(false);
  const [values, setValues] = useState({});

  const [producerAssignsVo, setProducerAssignsVo] = useState(false);
  const [assignmentDeadlineModified, setAssignmentDeadlineModified] = useState(false);
  const [voiceDeadlineModified, setVoiceDeadlineModified] = useState(false);
  const [scriptDeadlineModified, setScriptDeadlineModified] = useState(false);
  const [recordDeadlineModified, setRecordDeadlineModified] = useState(false);
  const [isOpenDetails, setIsOpenDetails] = useState({
    copy_points: true,
  });
  const isShowRoleVoiceTalent = useMemo(() => {
    if (!isShowAssignedVo) return !producerAssignsVo;

    if (isShowAssignedVo && !producerAssignsVo) return true;
    const voiceUsers = get(process, "_source.role_voice_talent.users", []);
    // In Team Management, the interface should include all of the roles for the order that have been assigned, including VO, including when Producer Assigns VO is on
    return isShowAssignedVo && get(voiceUsers, "length", 0) > 0;
  }, [process._id, isShowAssignedVo, producerAssignsVo]);

  const assignmentDeadline = get(values, "assignment_deadline", "");
  const voiceDeadline = get(values, "voice_deadline", "");
  const scriptDeadline = get(values, "script_deadline", "");
  const recordDeadline = get(values, "record_deadline", "");

  useEffect(() => {
    const newValues = getAssignmentTeamFromProcess(process);
    setValues(newValues);
    setProducerAssignsVo(get(process, "_source.producer_assigns_vo", false));
    getTeamMembersForAssignment({
      sorted: [{ id: "name", desc: false }],
    });
    // set active model
    // const assignStatus = get(process, "_source.assign_team_status", "");
    // if (assignStatus === "completed") {
    //   setIsEdit(false);
    // } else {
    //   setIsEdit(true);
    // }
  }, [process._id]);
  useEffect(() => {
    // only remove VO if on the team management view, leave existing
    // VO when reassigning from processes lists, isShowAssignedVo indicates 
    // parent is tem management
    if (!isShowRoleVoiceTalent && isShowAssignedVo) {
      setValues({
        ...values,
        role_voice_talent: [],
      });
    }
  }, [isShowRoleVoiceTalent, isShowAssignedVo]);

  const {
    assignmentDeadlineField,
    voiceDeadlineField,
    scriptDeadlineField,
    recordDeadlineField,
  } = React.useMemo(() => {
    return {
      assignmentDeadlineField: stepAssignTeam.fields.find(
        (f) => f.field === "assignment_deadline"
      ),
      voiceDeadlineField: stepAssignTeam.fields.find(
        (f) => f.field === "voice_deadline"
      ),
      scriptDeadlineField: stepAssignTeam.fields.find(
        (f) => f.field === "script_deadline"
      ),
      recordDeadlineField: stepAssignTeam.fields.find(
        (f) => f.field === "record_deadline"
      ),
    };
  }, [stepAssignTeam]);
  const assignmentDeadlineValue = React.useMemo(() => {
    if (!assignmentDeadline) {
      return {
        date: "",
        time: "",
      };
    }
    const momentDate = moment(assignmentDeadline, "YYYY-MM-DD HH:mm");
    return {
      date: momentDate.format("YYYY-MM-DD"),
      time: momentDate.format("HH:mm"),
    };
  }, [assignmentDeadline]);

  const voiceDeadlineValue = React.useMemo(() => {
    if (!voiceDeadline) {
      return {
        date: "",
        time: "",
      };
    }
    const momentDate = moment(voiceDeadline, "YYYY-MM-DD HH:mm");
    return {
      date: momentDate.format("YYYY-MM-DD"),
      time: momentDate.format("HH:mm"),
    };
  }, [voiceDeadline]);

  const scriptDeadlineValue = React.useMemo(() => {
    if (!scriptDeadline) {
      return {
        date: "",
        time: "",
      };
    }
    const momentDate = moment(scriptDeadline, "YYYY-MM-DD HH:mm");
    return {
      date: momentDate.format("YYYY-MM-DD"),
      time: momentDate.format("HH:mm"),
    };
  }, [scriptDeadline]);

  const recordDeadlineValue = React.useMemo(() => {
    if (!recordDeadline) {
      return {
        date: "",
        time: "",
      };
    }
    const momentDate = moment(recordDeadline, "YYYY-MM-DD HH:mm");
    return {
      date: momentDate.format("YYYY-MM-DD"),
      time: momentDate.format("HH:mm"),
    };
  }, [recordDeadline]);

  const hourOptions = React.useMemo(() => {
    return Array.from({ length: 24 }, (_, i) => i).map((item) => {
      return {
        label: moment(item, "HH").format("h a"),
        value: moment(item, "HH").format("HH:mm"),
      };
    });
  }, []);

  const items = React.useMemo(() => {
    if (!process) return [];
    const spotInfo = get(process, "_source.spot_info", []).find(
      (spot) => spot.key === process._id
    );

    return [
      {
        key: "copy_points",
        title: intl.formatMessage({
          id: "process > field copy points",
        }),
        data: get(spotInfo, "copy_points", ""),
        isCompleted: !!get(spotInfo, "copy_points", ""),
      },
    ];
  }, [process]);

  const roles = React.useMemo(() => {
    const role_writer = find(
      stepAssignTeam.fields,
      (item) => item.field === "role_writer"
    );
    const role_voice_talent = find(
      stepAssignTeam.fields,
      (item) => item.field === "role_voice_talent"
    );
    const role_producer = find(
      stepAssignTeam.fields,
      (item) => item.field === "role_producer"
    );
    const role_dubber = find(
      stepAssignTeam.fields,
      (item) => item.field === "role_dubber"
    );
    const spotInfo = get(process, "_source.spot_info", []).find(
      (spot) => spot.key === process._id
    );
    const isProductionComplete =
      get(process, "_source.contract_status", "") === "Production Complete";
    const isProductionProvidedTags =
      get(process, "_source.contract_status", "") ===
      "Production Provided - Needs tags";
    if (isProductionComplete) {
      return [role_dubber];
    }
    if (isProductionProvidedTags) {
      return [role_producer, role_dubber];
    }
    //  If AE has provided the script and selected it as final script under Creative Details section, we don't need to show the Writer selection option.
    const isAEProvidedFinalScript =
      get(spotInfo, "script_type", "") === "final" &&
      get(spotInfo, "script", "");

    let newRoles = [];
    if (!isAEProvidedFinalScript) {
      newRoles = [role_writer];
    }
    newRoles = !isShowRoleVoiceTalent
      ? [...newRoles, role_producer, role_dubber]
      : [...newRoles, role_voice_talent, role_producer, role_dubber];
    return newRoles;
  }, [stepAssignTeam, process, isShowRoleVoiceTalent]);
  // get candidate by role
  let roleCandidates = {};
  roles.forEach((role) => {
    if (role && (!selectedRole || get(selectedRole, "field") === role.field)) {
      roleCandidates[role.field] = getRoleUsersByHubMembers(role, members);
      // const testUsers = getRoleCandidates(role, process, info);
    }
  });
  const roleClicked = (role) => {
    if (!role) setIsAudition(false);
    if (selectedRole === role) setSelectedRole(null);
    else setSelectedRole(role);
  };
  const toggleUserRole = (user, role) => {
    if (!isEdit) return;
    const roleUsers = get(values, role.field, []);
    const isExists =
      roleUsers.findIndex((item) => item.id === user.info.id) !== -1;
    if (isExists) {
      setValues({
        ...values,
        [role.field]: roleUsers.filter((item) => item.id !== user.info.id),
      });
    } else {
      setValues({
        ...values,
        [role.field]: roleUsers.concat([
          {
            id: Number(user.info.id),
            name: user.info.name,
            role: Number(role.roles[0]),
            avatar_url: user.info.avatar_url,
          },
        ]),
      });
    }
  };
  const isShowAudition =
    includes(
      ["role_producer", "role_voice_talent"],
      get(selectedRole, "field")
    ) || !selectedRole;

  let allCandidates = [];

  Object.keys(roleCandidates).map((role_key) => {
    let candidates = roleCandidates[role_key];

    candidates &&
      candidates.map((candidate) => {
        if (!allCandidates.find((c) => c.info.id === candidate.info.id))
          allCandidates.push({ ...candidate, candidate_for: [] });

        allCandidates
          .find((c) => c.info.id === candidate.info.id)
          .candidate_for.push(roles.find((r) => r && r.field === role_key));
        return true;
      });
    return true;
  });
  const selectedCandidates = allCandidates.filter((user) => {
    return user.candidate_for.find(
      (role) =>
        values[role.field] &&
        values[role.field].filter((u) => parseInt(u.id) === user.info.id)
          .length > 0
    );
  });
  const remainCandidates = (searchText
    ? allCandidates.filter((user) => {
        return toLower(user.label).includes(toLower(searchText));
      })
    : allCandidates
  ).filter(
    (user) =>
      !selectedCandidates.find(
        (item) => Number(item.value) === Number(user.value)
      )
  );
  const rolesOptionFilter = [
    {
      label: props.intl.formatMessage({
        id: "select team > all",
      }),
      value: "",
    },
    ...roles.map((r) => {
      return {
        label: get(r, `title.props.id`)
          ? props.intl.formatMessage({
              id: `${get(r, `title.props.id`)}`,
            })
          : get(r, "title", "").toString(),
        value: r,
      };
    }),
  ];
  useEffect(() => {
    // set dynamic height
    const sidebar = document.getElementById("right-sidebar");
    contentHeight.current =
      sidebar.offsetHeight - HEADER_HEIGHT - FOOTER_HEIGHT;
  }, []);
  const requiredRoles = filter(
    roles,
    (role) => role.field !== "role_voice_talent"
  );
  const isActiveButton =
    filter(
      requiredRoles,
      (role) => values[role.field] && values[role.field].length > 0
    ).length === requiredRoles.length;

  const onChangeDeadlines = (item, value, type = "date") => {
    let formatValue = "";
    if (item.field === "assignment_deadline") {
      setAssignmentDeadlineModified(true);
      const assignmentDeadlineArr = [
        assignmentDeadlineValue.date || moment().format("YYYY-MM-DD"),
        assignmentDeadlineValue.time || "00:00",
      ];
      if (type === "date") {
        assignmentDeadlineArr[0] = value;
      } else if (type === "time") {
        assignmentDeadlineArr[1] = value;
      }
      formatValue = assignmentDeadlineArr.filter(Boolean).join(" ");
    } else if (item.field === "voice_deadline") {
      setVoiceDeadlineModified(true);
      const voiceDeadlineArr = [
        voiceDeadlineValue.date || moment().format("YYYY-MM-DD"),
        voiceDeadlineValue.time || "00:00",
      ];
      if (type === "date") {
        voiceDeadlineArr[0] = value;
      } else if (type === "time") {
        voiceDeadlineArr[1] = value;
      }
      formatValue = voiceDeadlineArr.filter(Boolean).join(" ");
    } else if (item.field === "record_deadline") {
      setRecordDeadlineModified(true);
      const recordDeadlineArr = [
        recordDeadlineValue.date || moment().format("YYYY-MM-DD"),
        recordDeadlineValue.time || "00:00",
      ];
      if (type === "date") {
        recordDeadlineArr[0] = value;
      } else if (type === "time") {
        recordDeadlineArr[1] = value;
      }
      formatValue = recordDeadlineArr.filter(Boolean).join(" ");
    } else if (item.field === "script_deadline") {
      setScriptDeadlineModified(true);
      const scriptDeadlineArr = [
        scriptDeadlineValue.date || moment().format("YYYY-MM-DD"),
        scriptDeadlineValue.time || "00:00",
      ];
      if (type === "date") {
        scriptDeadlineArr[0] = value;
      } else if (type === "time") {
        scriptDeadlineArr[1] = value;
      }
      formatValue = scriptDeadlineArr.filter(Boolean).join(" ");
    }
    setValues({
      ...values,
      [item.field]: formatValue,
    });
  };

  const maxDate = useMemo(() => {
    const orderStartDate = get(process, "_source.order_start_date", "");
    if (orderStartDate) {
      return moment(orderStartDate)
        .subtract(1, "days")
        .toDate();
    }
    return null;
  }, [process]);

  return (
    <div className={bem.b()}>
      <div
        className={classnames(bem.e("scroll-container"), "scroll-bar-style")}
        style={{
          height: contentHeight.current,
        }}
      >
        {items.map((item) => (
          <div key={item.key} className={bem.e("instruction-item")}>
            <div
              className={bem.e("instruction-header")}
              onClick={() => {
                if (isOpenDetails[item.key]) {
                  setIsOpenDetails({
                    ...isOpenDetails,
                    [item.key]: false,
                  });
                } else {
                  setIsOpenDetails({
                    ...isOpenDetails,
                    [item.key]: true,
                  });
                }
              }}
            >
              <span className={bem.e("section-title")}>
                {item.isCompleted ? (
                  <CheckDoneIcon color="#73A703" />
                ) : (
                  <span className={bem.e("fake-icon")} />
                )}
                {item.title}
              </span>
              <div className={bem.e("section-action")}>
                {isOpenDetails[item.key] ? <ArrowDownIcon /> : <ArrowUpIcon />}
              </div>
            </div>
            {isOpenDetails[item.key] && (
              <SectionContent item={item} intl={intl} />
            )}
          </div>
        ))}
        <div
          className={classnames(bem.e("production-deadlines"), {
            [bem.e(
              "production-deadlines-open"
            )]: isOpenDetails.production_deadlines,
          })}
        >
          <div
            className={bem.e("instruction-header")}
            onClick={() => {
              if (isOpenDetails["production_deadlines"]) {
                setIsOpenDetails({
                  ...isOpenDetails,
                  production_deadlines: false,
                });
              } else {
                setIsOpenDetails({
                  ...isOpenDetails,
                  production_deadlines: true,
                });
              }
            }}
          >
            <span className={bem.e("section-title")}>
              <span className={bem.e("fake-icon")} />
              <FormattedMessage id="process > title production deadlines" />
            </span>
            <div className={bem.e("section-action")}>
              {isOpenDetails.production_deadlines ? (
                <ArrowDownIcon />
              ) : (
                <ArrowUpIcon />
              )}
            </div>
          </div>
          {isOpenDetails.production_deadlines && (
            <Row>
              {assignmentDeadlineField && (
                <Col xs={6}>
                  <div className={bem.e("deadline-group")}>
                    <TextInput
                      label={assignmentDeadlineField.title}
                      type="date"
                      name="assignment_deadline"
                      placeholder={intl.formatMessage({
                        id:
                          "process > promotion > liner > schedule > placeholder select",
                      })}
                      value={assignmentDeadlineValue.date || ""}
                      onChange={(value) =>
                        onChangeDeadlines(
                          assignmentDeadlineField,
                          value,
                          "date"
                        )
                      }
                      minDate={moment().toDate()}
                      maxDate={maxDate}
                      allowShowIcon
                    />
                    <Dropdown
                      options={hourOptions}
                      value={
                        hourOptions.find(
                          (item) => item.value === assignmentDeadlineValue.time
                        ) || ""
                      }
                      placeholder={intl.formatMessage({
                        id:
                          "process > promotion > liner > schedule > placeholder select",
                      })}
                      onChange={(option) => {
                        onChangeDeadlines(
                          assignmentDeadlineField,
                          option.value,
                          "time"
                        );
                      }}
                    />
                  </div>
                </Col>
              )}
              {voiceDeadlineField && (
                <Col xs={6}>
                  <div className={bem.e("deadline-group")}>
                    <TextInput
                      label={voiceDeadlineField.title}
                      type="date"
                      name="voice_deadline"
                      placeholder={intl.formatMessage({
                        id:
                          "process > promotion > liner > schedule > placeholder select",
                      })}
                      value={voiceDeadlineValue.date || ""}
                      onChange={(value) =>
                        onChangeDeadlines(voiceDeadlineField, value, "date")
                      }
                      minDate={moment().toDate()}
                      maxDate={maxDate}
                      allowShowIcon
                    />
                    <Dropdown
                      options={hourOptions}
                      value={
                        hourOptions.find(
                          (item) => item.value === voiceDeadlineValue.time
                        ) || ""
                      }
                      placeholder={intl.formatMessage({
                        id:
                          "process > promotion > liner > schedule > placeholder select",
                      })}
                      onChange={(option) => {
                        onChangeDeadlines(
                          voiceDeadlineField,
                          option.value,
                          "time"
                        );
                      }}
                    />
                  </div>
                </Col>
              )}
              {scriptDeadlineField && (
                <Col xs={6}>
                  <div className={bem.e("deadline-group")}>
                    <TextInput
                      label={scriptDeadlineField.title}
                      type="date"
                      name="script_deadline"
                      placeholder={intl.formatMessage({
                        id:
                          "process > promotion > liner > schedule > placeholder select",
                      })}
                      value={scriptDeadlineValue.date || ""}
                      onChange={(value) =>
                        onChangeDeadlines(scriptDeadlineField, value, "date")
                      }
                      minDate={moment().toDate()}
                      maxDate={maxDate}
                      allowShowIcon
                    />
                    <Dropdown
                      options={hourOptions}
                      value={
                        hourOptions.find(
                          (item) => item.value === scriptDeadlineValue.time
                        ) || ""
                      }
                      placeholder={intl.formatMessage({
                        id:
                          "process > promotion > liner > schedule > placeholder select",
                      })}
                      onChange={(option) => {
                        onChangeDeadlines(
                          scriptDeadlineField,
                          option.value,
                          "time"
                        );
                      }}
                    />
                  </div>
                </Col>
              )}
              {recordDeadlineField && (
                <Col xs={6}>
                  <div className={bem.e("deadline-group")}>
                    <TextInput
                      label={recordDeadlineField.title}
                      type="date"
                      name="record_deadline"
                      placeholder={intl.formatMessage({
                        id:
                          "process > promotion > liner > schedule > placeholder select",
                      })}
                      value={recordDeadlineValue.date || ""}
                      onChange={(value) =>
                        onChangeDeadlines(recordDeadlineField, value, "date")
                      }
                      minDate={moment().toDate()}
                      maxDate={maxDate}
                      allowShowIcon
                    />
                    <Dropdown
                      options={hourOptions}
                      value={
                        hourOptions.find(
                          (item) => item.value === recordDeadlineValue.time
                        ) || ""
                      }
                      placeholder={intl.formatMessage({
                        id:
                          "process > promotion > liner > schedule > placeholder select",
                      })}
                      onChange={(option) => {
                        onChangeDeadlines(
                          recordDeadlineField,
                          option.value,
                          "time"
                        );
                      }}
                    />
                  </div>
                </Col>
              )}
            </Row>
          )}
        </div>
        <div className={bem.e("assigned-header")}>
          <span className={bem.e("assignments-text")}>
            <FormattedMessage id="process > assignments" />:
          </span>
          <div className={bem.e("roles")}>
            {filter(roles, (role) => !role.is_disabled).map((role, ind) => {
              const assigned =
                values[role.field] && values[role.field].length > 0;
              return assigned ? (
                <div key={ind} className={bem.e("role-item")}>
                  <span className={classnames(bem.e("role-title"))}>
                    {get(role, "title")}:
                  </span>
                  <div className={bem.e("checked")}>
                    <FaCheckCircle color="#10BA37" size={40} />
                  </div>
                </div>
              ) : (
                <div key={ind} className={bem.e("role-item")}>
                  <span className={bem.e("role-title")}>
                    {get(role, "title")}:
                  </span>
                </div>
              );
            })}
          </div>
          <ToggleSwitch
            leftComponent={intl.formatMessage({
              id: "production settings > producer assigns vo",
            })}
            rightComponent={null}
            name="producer_assigns_vo"
            switchProps={{
              checked: !producerAssignsVo,
              onChange: (checked) => {
                setProducerAssignsVo(!checked);
              },
              offColor: "#795AFA",
              onColor: "#C2D4E0",
              uncheckedIcon: false,
              checkedIcon: false,
              boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.282)",
              height: 26.44,
              width: 48.48,
              handleDiameter: 22.92,
            }}
          />
        </div>
        <div className={classnames(bem.e("section-content"))}>
          {/* selected */}
          {selectedCandidates.length > 0 ? (
            <div
              className={classnames(bem.e("user-rows"), bem.e("user-selected"))}
            >
              <div className={bem.e("selected-title")}>
                <FormattedMessage id="process > assignments selected" />
              </div>
              <UserRows
                candidates={selectedCandidates}
                intl={intl}
                isAudition={isAudition}
                values={values}
                toggleUserRole={toggleUserRole}
                enterpriseSettings={enterpriseSettings}
              />
            </div>
          ) : null}

          {/* remain */}
          <div className={classnames(bem.e("user-rows"), bem.e("user-remain"))}>
            <div className={bem.e("filter-row")}>
              <div className={bem.e("filter-row-left")}>
                <div className={bem.e("team-member-dropdown")}>
                  <span className={bem.e("filter-dropdown-label")}>
                    <FormattedMessage id="select team > filter by role" />
                  </span>
                  <Dropdown
                    label={null}
                    placeholder={props.intl.formatMessage({
                      id: "select team > show all",
                    })}
                    name="role"
                    isMulti={false}
                    allowSelectAll={false}
                    onChange={(selectedOption) => {
                      roleClicked(selectedOption.value);
                    }}
                    value={rolesOptionFilter.find(
                      (item) =>
                        get(item, "value.field") === get(selectedRole, "field")
                    )}
                    options={rolesOptionFilter}
                    closeMenuOnSelect={true}
                  />
                </div>
                <div className={bem.e("members-search")}>
                  <SearchInput
                    placeholder={intl.formatMessage({
                      id: "process > search for a team member",
                    })}
                    onClear={() => {
                      setSearchText("");
                    }}
                    onChange={(value) => {
                      setSearchText(value);
                    }}
                    value={searchText}
                  />
                </div>
              </div>
              {isShowAudition && (
                <ToggleSwitch
                  leftComponent={
                    <FormattedMessage id="select team > audition" />
                  }
                  rightComponent={null}
                  name="is_hidden"
                  containerClassName={bem.e("filter-audition")}
                  switchProps={{
                    checked: isAudition,
                    onChange: (checked) => {
                      setIsAudition(checked);
                    },
                    offColor: "#C2D4E0",
                    onColor: "#795AFA",
                    uncheckedIcon: false,
                    checkedIcon: false,
                    boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.282)",
                    height: 26.44,
                    width: 49.76,
                    handleDiameter: 22.92,
                  }}
                />
              )}
            </div>
            <UserRows
              candidates={remainCandidates}
              intl={intl}
              isAudition={isAudition}
              values={values}
              toggleUserRole={toggleUserRole}
              enterpriseSettings={enterpriseSettings}
            />
          </div>
        </div>
      </div>
      <div className={bem.e("assigned-footer")}>
        {isEdit ? (
          <>
            <Button
              color="blue"
              type="submit"
              className={"btn btn-radius"}
              disabled={!isActiveButton}
              onClick={() =>
                onSubmit({
                  ...values,
                  producer_assigns_vo: producerAssignsVo,
                  assignment_deadline_modified: assignmentDeadlineModified,
                  voice_deadline_modified: voiceDeadlineModified,
                  script_deadline_modified: scriptDeadlineModified,
                  record_deadline_modified: recordDeadlineModified,
                })
              }
            >
              {props.buttonSubmitText}
            </Button>
            <Button
              color="blue"
              outline
              className={"btn btn-radius"}
              onClick={onClose}
            >
              <FormattedMessage id={`process > button cancel`} />
            </Button>
          </>
        ) : (
          <Button
            color="blue"
            type="button"
            className={"btn btn-radius"}
            onClick={() => setIsEdit(true)}
          >
            <FormattedMessage id={`process > button edit`} />
          </Button>
        )}
      </div>
      <ConfirmationModal
        isOpen={isShowSuccessModal}
        state="success"
        title={<FormattedMessage id="select team > team assigned" />}
        onToggle={() => setIsShowSuccessModal(!isShowSuccessModal)}
        onCancel={() => setIsShowSuccessModal(!isShowSuccessModal)}
      />
      <Spinner isLoading={isLoading} />
    </div>
  );
};
AssignTeam.propTypes = {
  buttonSubmitText: PropTypes.any,
};
AssignTeam.defaultProps = {
  buttonSubmitText: <FormattedMessage id={`process > button submit`} />,
};
export default AssignTeam;
