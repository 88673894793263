import * as Types from "../types/events";
import { LOGOUT_USER } from "../types/auth";
import {get} from "lodash";

const initialState = {
  events: {
    // This object contains all Events list
    // this is mainly required in Events listing page
    // where we show all events list and loading icon if events are loading
    data: [],
    error: null,
    loading: false
  },

  addEvent: {
    // This object will be useful to show loading icon and status when user want to create new event
    is_added: false,
    data: null,
    error: null,
    loading: false
  },
  updateEvent: {
    // This object will be useful to show loading icon and status when user want to update event
    data: null,
    error: null,
    loading: false
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    // events Actions

    case Types.FETCH_EVENTS_REQUEST: {
      return {
        ...state,
        events: { data: [], error: null, loading: true }
      };
    }
    case Types.FETCH_EVENTS_SUCCESS: {
      return {
        ...state,
        events: {
          data: action.payload,
          error: null,
          loading: false
        }
      };
    }
    case Types.FETCH_EVENTS_ERROR: {
      return {
        ...state,
        events: {
          data: [],
          error: action.payload,
          loading: false
        }
      };
    }

    // Particular Event Actions
    case Types.FETCH_EVENT_REQUEST: {
      const existingEvents = get(state, "event");
      const { id } = action.payload;
      return {
        ...state,
        event: {
          ...existingEvents,
          [id]: {
            ...(existingEvents[id] || {}),
            loading: true,
            error: null
          }
        }
      };
    }
    case Types.FETCH_EVENT_SUCCESS: {
      const existingEvents = get(state, "event");
      const { id, data } = action.payload;
      return {
        ...state,
        event: {
          ...existingEvents,
          [id]: {
            loading: false,
            error: null,
            data
          }
        }
      };
    }
    case Types.FETCH_EVENT_ERROR: {
      const existingEvents = get(state, "event");
      const { id, error } = action.payload;
      return {
        ...state,
        event: {
          ...existingEvents,
          [id]: {
            loading: false,
            error
          }
        }
      };
    }

    // Add Event Actions
    case Types.ADD_EVENT_REQUEST: {
      return {
        ...state,
        addEvent: {
          is_added: false,
          loading: true,
          error: null
        }
      };
    }
    case Types.ADD_EVENT_SUCCESS: {
      return {
        ...state,
        event: {
          ...get(state, "event"),
          data: {
            ...get(state, "event.data"),
            data: get(state, "event.data.data"),
          }
        },
        addEvent: {
          is_added: true,
          data: action.payload,
          loading: false,
          error: null
        }
      };
    }
    case Types.ADD_EVENT_ERROR: {
      return {
        ...state,
        addEvent: {
          is_added: false,
          loading: false,
          error: action.payload
        }
      };
    }

    // Update Event Actions
    case Types.UPDATE_EVENT_REQUEST: {
      return {
        ...state,
        updateEvent: {
          is_updated: false,
          loading: true,
          error: null
        }
      };
    }
    case Types.UPDATE_EVENT_SUCCESS: {
      return {
        ...state,
        updateEvent: {
          is_updated: true,
          data: action.payload,
          loading: false,
          error: null
        }
      };
    }
    case Types.UPDATE_EVENT_ERROR: {
      return {
        ...state,
        updateEvent: {
          is_updated: false,
          loading: false,
          error: action.payload
        }
      };
    }

  // delete Event Actions
  case Types.DELETE_EVENT_REQUEST: {
      return {
        ...state,
        updateEvent: {
          is_updated: false,
          loading: true,
          error: null
        }
      };
    }
    case Types.DELETE_EVENT_SUCCESS: {
      return {
        ...state,
        updateEvent: {
          is_updated: true,
          data: action.payload,
          loading: false,
          error: null
        }
      };
    }
    case Types.DELETE_EVENT_ERROR: {
      return {
        ...state,
        updateEvent: {
          is_updated: false,
          loading: false,
          error: action.payload
        }
      };
    }
    case LOGOUT_USER:
    return initialState;
    default:
      return state;
  }
}
