import * as React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { get } from "lodash";
import TextInput from "components/TextInput";
import { FormattedMessage } from "react-intl";
import { FaCheck } from "react-icons/lib/fa";
import {
  Row,
  Col,
  Form,
  Button,
  Label,
  Modal,
  ModalBody,
  ButtonGroup,
} from "reactstrap";
import NumberFormat from "react-number-format";
import history from "components/History";
import ToastManager from "components/ToastManager";
import { autoSuggestClients } from "store/actions/process";

import {
  ShadowBox,
  Asterisk,
  SectionTitle,
  SectionFields,
  DivView,
} from "components/Elements";
import OrderDetailsDuplicate from "components/OrderDetailsDuplicate";
import OrderDetailsIncompleteContract from "components/OrderDetailsIncompleteContract";
import { PhoneNumberFormat, RotationFormatSuffix } from "utils/config";
// import UploadFile from "components/UploadFile/index";
import ConfirmationModal from "../ConfirmationModal/view";

class TeamEquipment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      contracts: [],
      isOpenDialog: false,
      isOpenDialogDelete: false,
      isOpenDialogSubmitted: false,
      isOpenContestDescription: true,
      isOpenJockInstructions: false,
      isOpenContractDetails: false,
      isOpenContestSchedule: false,
      isOpenLinerSchedule: false,
      isOpenDocuments: false,
    };
  }

  static propTypes = {
    mode: PropTypes.string,
  };

  static defaultProps = {
    mode: "edit",
  };

  componentDidMount() {
    this._isMounted = true;
    autoSuggestClients({
      q: "",
      cb: (items) => {
        if (this._isMounted) {
          this.setState({ customers: items, fieldRefs: false });
        }
      },
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  onCheckBoxChange = (item, process, template, user, checked) => {
    this.props.checkboxChanged(item, process, template, user, {
      target: { checked },
    });
  };

  switchToSpot(key) {
    const { intl } = this.props;
    if (key === "new") {
      ToastManager.show({
        title: intl.formatMessage("toast > title not saved"),
        message: intl.formatMessage(
          "toast > message please save the order before switching to another spot"
        ),
        level: "error",
      });
      return;
    }
    // let process = this.props.process;
    let template = this.props.template;
    // if(this.props.process.data.fields.key===key) return
    history.push("/processes/edit/" + template.key + "/" + key);
  }

  getFieldRefs() {
    if (this.state.fieldRefs) return this.state.fieldRefs;
    let {
      process,
      template,
      // user,
      step,
      // valueChanged,
      // validationState,
      // fileUploaded,
      // fileDeleted,
      // filesUploaded,
      // filesDeleted,
      // checkboxGroupChanged,
      // checkboxChanged,
      // getRoleCandidates
      // spotsChanged,
      // multipleStationSelectionChanged
    } = this.props;

    step = template.steps.promotion_event_details;
    let start_date = step.fields.find((f) => f.field === "start_date");
    let end_date = step.fields.find((f) => f.field === "end_date");
    let event_name = step.fields.find((f) => f.field === "event_name");
    let event_type = step.fields.find((f) => f.field === "event_type");
    let event_description = step.fields.find(
      (f) => f.field === "event_description"
    );

    // must have order stations
    if (!this.props.process.data.fields.contract_stations) {
      // let init_stations = [this.props.info.stations[0].station_key]
      let init_stations = [];
      this.props.valueChanged(
        { field: "contract_stations" },
        this.props.process,
        this.props.template,
        this.props.user,
        { value: init_stations }
      );
    }

    // must have order stations
    if (!this.props.process.data.fields.order_stations) {
      // let init_stations = this.props.process.data.fields.contract_stations ? [...this.props.process.data.fields.contract_stations.value] : [this.props.info.stations[0].station_key]
      let init_stations = [];
      this.props.valueChanged(
        { field: "order_stations" },
        this.props.process,
        this.props.template,
        this.props.user,
        { value: init_stations }
      );
    }

    let select_stations_checked = [];
    if (get(this.props, "process.data.fields.order_stations.value")) {
      select_stations_checked = this.props.process.data.fields.order_stations.value.map(
        (s) => ({ station_key: s })
      );
    }

    let spots = [];
    if (
      process.data.fields.key &&
      this.props.process.data.fields.spots &&
      this.props.process.data.fields.spots.value.length > 1
    ) {
      this.props.process.data.fields.spots.value.map((spot, ind) =>
        spots.push({
          key: spot,
          component: <span key={ind}> Spot {ind + 1} </span>,
        })
      );
    }

    let contractSaved = process.data.fields.contract_id !== undefined;
    let payload = {
      start_date,
      end_date,
      event_name,
      event_type,
      event_description,
    };
    this.setState({ fieldRefs: payload });
    //debugger
    console.log("recomputed");
    return payload;
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.stationsWithoutSpots &&
      nextProps.stationsWithoutSpots !== this.props.stationsWithoutSpots
    ) {
      this.setState({
        isOpenDialog: true,
        stationsWithoutSpots: nextProps.stationsWithoutSpots,
      });
    }
    if (JSON.stringify(nextProps.info) !== JSON.stringify(this.props.info)) {
      this.setState({ fieldRefs: false });
      return;
    }
    for (
      let i = 0;
      i < Object.keys(nextProps.process.data.fields).length;
      i++
    ) {
      let key = Object.keys(nextProps.process.data.fields)[i];
      let changed = false;
      if (
        !nextProps.process.data.fields[key] &&
        !this.props.process.data.fields[key]
      )
        continue;
      else if (
        !nextProps.process.data.fields[key] &&
        this.props.process.data.fields[key]
      )
        changed = true;
      else if (
        nextProps.process.data.fields[key] &&
        !this.props.process.data.fields[key]
      )
        changed = true;
      else if (
        !nextProps.process.data.fields[key].value &&
        !this.props.process.data.fields[key].value
      )
        continue;
      else if (
        !nextProps.process.data.fields[key].value &&
        this.props.process.data.fields[key].value
      )
        changed = true;
      else if (
        nextProps.process.data.fields[key].value &&
        !this.props.process.data.fields[key].value
      )
        changed = true;
      else if (
        nextProps.process.data.fields[key].value.toString() !==
        this.props.process.data.fields[key].value.toString()
      )
        changed = true;
      if (changed) {
        console.log("changed: " + key);
        if (
          [
            "client_id",
            "contract_stations",
            "order_stations",
            "order_sold",
            "spots",
            "key",
          ].indexOf(key) > -1
        ) {
          this.setState({ fieldRefs: false });
          // refresh prizes list when changing contest stations.
          break;
        }
      }
    }
    // this.setState({fieldRefs:false})

    // open section when having any validate
    if (this.props.validationState !== nextProps.validationState) {
      let sections = [
        "contest_description",
        "jock_instructions",
        "contract_details",
        "contest_schedule",
        "liner_schedule",
        "documents",
      ];
      sections.forEach((key, index) => {
        let fields = [];
        let errors = [];
        fields = this.renderFieldsSection(key);
        errors = fields.filter((item) =>
          get(nextProps.validationState, `${item}.validation_error`)
        );
        if (errors.length > 0) {
          if (
            key === "contest_description" &&
            !this.state.isOpenContestDescription
          ) {
            this.setState({
              isOpenContestDescription: true,
            });
          } else if (
            key === "jock_instructions" &&
            !this.state.isOpenJockInstructions
          ) {
            this.setState({
              isOpenJockInstructions: true,
            });
          } else if (
            key === "contract_details" &&
            !this.state.isOpenContractDetails
          ) {
            this.setState({
              isOpenContractDetails: true,
            });
          } else if (
            key === "contest_schedule" &&
            !this.state.isOpenContestSchedule
          ) {
            this.setState({
              isOpenContestSchedule: true,
            });
          } else if (
            key === "liner_schedule" &&
            !this.state.isOpenLinerSchedule
          ) {
            this.setState({
              isOpenLinerSchedule: true,
            });
          } else if (key === "documents" && !this.state.isOpenDocuments) {
            this.setState({
              isOpenDocuments: true,
            });
          }
        }
      });
    }
  }

  renderFieldsSection = (key) => {
    let fields = [];
    switch (key) {
      case "contest_description":
        fields = [
          "order_title",
          "order_stations",
          "contest_type",
          "contest_theme",
          "contest_winner_selection",
          "contest_number_of_plays",
          "contest_internal_description",
          "contest_approved_program_director",
        ];
        break;
      case "jock_instructions":
        fields = [
          "contest_before_winner_selection",
          "contest_after_winner_selection",
          "contest_notes",
          "contest_trivia_questions",
          "contest_trivia_questions_textfields",
          "contest_additional_questions",
          "contest_additional_questions_textfields",
        ];
        break;
      case "contract_details":
        fields = [
          "client_name",
          "contract_number",
          "contract_value",
          "contract_start_date",
          "contract_end_date",
          "contract_client_contact",
          "contract_client_email",
          "contract_client_nickname",
          "contract_client_phone",
          "contract_stations",
        ];
        break;
      case "contest_schedule":
        fields = [
          "order_start_date",
          "order_end_date",
          "contest_days_of_week",
          "order_day_parts",
        ];
        break;
      case "liner_schedule":
        fields = [
          "liner_mentions_per_day",
          "liner_start_date",
          "liner_end_date",
          "liner_days_of_week",
          "liner_length",
          "liner_day_part",
          "script",
          "liner_recorded_promos",
        ];
        break;
      case "documents":
        fields = ["order_files"];
        break;
      default:
        break;
    }
    return fields;
  };

  isCheckFieldRequired = (key, fields) => {
    const { process } = this.props;

    let required = [];
    if (key === "liner_schedule") {
      const { liners, active_liner } = this.getFieldRefs();
      const linersArray = process.data.fields[liners.field]
        ? process.data.fields[liners.field].value
        : [{}];
      const activelinerIndex = process.data.fields[active_liner.field]
        ? process.data.fields[active_liner.field].value
        : 0;
      const activeliner = linersArray[activelinerIndex];
      required = fields.filter((key, index) => {
        if (key === "liner_days_of_week" || key === "order_stations") {
          return get(activeliner, `${key}.length`) === 0;
        } else {
          return !get(activeliner, `${key}`);
        }
      });
    } else {
      required = fields.filter((key, index) => {
        if (key === "contract_stations" || key === "order_stations") {
          return get(process, `data.fields.${key}.value.length`) === 0;
        } else if (key === "order_files") {
          return (
            !get(process, `data.fields.${key}.value`) ||
            get(process, `data.fields.${key}.value.length`) === 0
          );
        } else {
          return !get(process, `data.fields.${key}.value`);
        }
      });
    }
    return required;
  };

  isCheckAnyFieldHaveValue = (key, fields) => {
    const { process } = this.props;
    let status = false;
    if (key === "liner_schedule") {
      const { liners, active_liner } = this.getFieldRefs();
      const linersArray = process.data.fields[liners.field]
        ? process.data.fields[liners.field].value
        : [{}];
      const activelinerIndex = process.data.fields[active_liner.field]
        ? process.data.fields[active_liner.field].value
        : 0;
      const activeliner = linersArray[activelinerIndex];
      fields.forEach((key, index) => {
        let value = null;
        if (key === "liner_days_of_week" || key === "order_stations") {
          value = get(activeliner, `${key}.length`);
        } else {
          value = get(activeliner, `${key}`);
        }
        if (value) {
          status = true;
        }
      });
    } else {
      fields.forEach((key) => {
        let value = null;
        if (key === "contract_stations" || key === "order_stations") {
          value = get(process, `data.fields.${key}.value.length`);
        } else if (key === "order_files") {
          value = get(process, `data.fields.${key}.value.length`);
        } else {
          value = get(process, `data.fields.${key}.value`);
        }
        if (value) {
          status = true;
        }
      });
    }
    return status;
  };

  isCompletedSection = (key) => {
    const { step } = this.props;
    let fields = this.renderFieldsSection(key);
    let fieldsRequired = fields.filter((key, index) => {
      const fieldRef = step.fields.find((f) => f.field === key);
      return get(fieldRef, "mandatory");
    });
    // section don't have any fields required
    if (fieldsRequired.length === 0) {
      return this.isCheckAnyFieldHaveValue(key, fields);
    } else {
      // check section with fields require
      const required = this.isCheckFieldRequired(key, fieldsRequired);
      return required.length === 0;
    }
  };

  renderForm() {
    let {
      process,
      template,
      user,
      step,
      valueChanged,
      validationState,
      // fileUploaded,
      // fileDeleted,
      filesUploaded,
      filesDeleted,
      // checkboxGroupChanged,
      // checkboxChanged,
      // getRoleCandidates,
      // spotsChanged,
      multipleStationSelectionChanged,
    } = this.props;

    let {
      start_date,
      end_date,
      event_name,
      event_type,
      event_description,
    } = this.getFieldRefs();
    console.log(this.getFieldRefs());
    // const additionTextFieldsValue = process.data.fields[
    //   contest_additional_questions_textfields.field
    // ]
    //   ? process.data.fields[contest_additional_questions_textfields.field].value
    //   : [""];
    // const triviaQuestionValue = process.data.fields[
    //   contest_trivia_questions_textfields.field
    // ]
    //   ? process.data.fields[contest_trivia_questions_textfields.field].value
    //   : [{ q: "", a: "" }];
    // const linersArray = process.data.fields[liners.field]
    //   ? process.data.fields[liners.field].value
    //   : [{}];
    // const activelinerIndex = process.data.fields[active_liner.field]
    //   ? process.data.fields[active_liner.field].value
    //   : 0;
    // const activeliner = linersArray[activelinerIndex];

    let bindedValueChanged = valueChanged.bind(this);

    return (
      <div className="text-left">
        <Form>
          <ShadowBox className="promotion-shadow-box">
            <SectionTitle
              className="promotion-section-title"
              onClick={() =>
                this.setState({
                  isOpenContestDescription: !this.state
                    .isOpenContestDescription,
                })
              }
            >
              <FormattedMessage id="process > title promotion event schedule" />
              {this.isCompletedSection("contest_description") && (
                <FaCheck color="#6F96D1" size={30} />
              )}
            </SectionTitle>
            <SectionFields
              className={!this.state.isOpenContestDescription && "hidden"}
            >
              <Row>
                <Col>
                  <TextInput
                    label={
                      <span>
                        {start_date.title}
                        {start_date.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    type="date"
                    min={moment().format("YYYY-MM-DD")}
                    value={
                      process.data.fields[start_date.field]
                        ? process.data.fields[start_date.field].value
                        : ""
                    }
                    onChange={valueChanged.bind(
                      this,
                      start_date,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[start_date.field] &&
                      validationState[start_date.field].validation_error}
                  </div>
                </Col>
                <Col>
                  <TextInput
                    label={
                      <span>
                        {end_date.title}
                        {end_date.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    type="date"
                    min={moment().format("YYYY-MM-DD")}
                    value={
                      process.data.fields[end_date.field]
                        ? process.data.fields[end_date.field].value
                        : ""
                    }
                    onChange={valueChanged.bind(
                      this,
                      end_date,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[end_date.field] &&
                      validationState[end_date.field].validation_error}
                  </div>
                </Col>
              </Row>
            </SectionFields>
          </ShadowBox>
          <ShadowBox className="promotion-shadow-box">
            <SectionTitle
              className="promotion-section-title"
              onClick={() =>
                this.setState({
                  isOpenJockInstructions: !this.state.isOpenJockInstructions,
                })
              }
            >
              <FormattedMessage id="process > title promotion event details" />
              {this.isCompletedSection("jock_instructions") && (
                <FaCheck color="#6F96D1" size={30} />
              )}
            </SectionTitle>
            <SectionFields
              className={!this.state.isOpenJockInstructions && "hidden"}
            >
              <Row>
                <Col>
                  <TextInput
                    label={
                      <span>
                        {event_name.title}
                        {event_name.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    value={
                      get(process, `data.fields.${event_name.field}.value`)
                        ? get(process, `data.fields.${event_name.field}.value`)
                        : ""
                    }
                    onChange={valueChanged.bind(
                      this,
                      event_name,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[event_name.field] &&
                      validationState[event_name.field].validation_error}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextInput
                    label={
                      <span>
                        {event_description.title}
                        {event_description.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    value={
                      process.data.fields[event_description.field]
                        ? process.data.fields[event_description.field].value
                        : ""
                    }
                    type="textarea"
                    onChange={valueChanged.bind(
                      this,
                      event_description,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[event_description.field] &&
                      validationState[event_description.field].validation_error}
                  </div>
                </Col>
              </Row>
            </SectionFields>
          </ShadowBox>
          <Row>
            <Col>
              <ButtonGroup>
                <Button
                  block
                  color="blue"
                  style={{
                    borderRadius: 30,
                    minWidth: "95px",
                    marginRight: "10px",
                    paddingRight: " 20px",
                    paddingLeft: "20px",
                  }}
                  onClick={this.props.buttonClicked.bind(
                    this,
                    step,
                    step.fields.find((f) => f.key === "save_button"),
                    process,
                    template,
                    user
                  )}
                >
                  <FormattedMessage id="process > button submit" />
                </Button>

                <Button
                  block
                  color="blue"
                  outline
                  style={{
                    borderRadius: 30,
                    paddingRight: " 20px",
                    paddingLeft: "20px",
                  }}
                  onClick={this.props.buttonClicked.bind(
                    this,
                    step,
                    {
                      ...step.fields.find((f) => f.key === "save_button"),
                      draft: true,
                    },
                    process,
                    template,
                    user
                  )}
                >
                  <FormattedMessage id="process > button save draft" />
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </Form>
        <Modal
          isOpen={this.state.isOpenDialog}
          toggle={() =>
            this.setState({ isOpenDialog: !this.state.isOpenDialog })
          }
        >
          <ModalBody>
            {this.state.stationsWithoutSpots ? (
              <OrderDetailsIncompleteContract
                stationsWithoutSpots={this.state.stationsWithoutSpots}
                close={() => this.setState({ isOpenDialog: false })}
              />
            ) : (
              <OrderDetailsDuplicate
                process={process}
                template={template}
                close={() => this.setState({ isOpenDialog: false })}
              />
            )}
          </ModalBody>
        </Modal>
      </div>
    );
  }

  formattedValueOrEmpty(value, item) {
    if (get(item, "formatted")) {
      const formatted = get(item, "formatted");
      switch (formatted) {
        case "number":
          return (
            <NumberFormat
              prefix={get(item, "prefix", null)}
              value={value}
              displayType={"text"}
              thousandSeparator={true}
            />
          );
        case "phone":
          return (
            <NumberFormat
              value={value}
              displayType={"text"}
              format={PhoneNumberFormat}
            />
          );
        case "percentage":
          return (
            <NumberFormat
              value={value}
              displayType={"text"}
              suffix={get(item, "suffix", RotationFormatSuffix)}
            />
          );
        default:
          break;
      }
    }
    return value;
  }

  valueOrEmpty(item) {
    let { process } = this.props;
    let value =
      process.data.fields[item.field] && process.data.fields[item.field].value
        ? process.data.fields[item.field].value.name !== undefined
          ? process.data.fields[item.field].value.name
          : process.data.fields[item.field].value
        : "";
    return this.formattedValueOrEmpty(value, item);
  }

  renderView() {
    let { process, template, user, step, valueChanged } = this.props;

    let {
      start_date,
      end_date,
      event_name,
      event_type,
      event_description,
    } = this.getFieldRefs();

    let spots = [];
    let prevOrNextSpot = false;
    let passedCurrent = false;
    let valueOrEmpty = this.valueOrEmpty.bind(this);
    if (
      process.data.fields.key &&
      this.props.process.data.fields.spots &&
      this.props.process.data.fields.spots.value.length > 1
    ) {
      this.props.process.data.fields.spots.value.map((spot, ind) => {
        if (
          spot.key !== process.data.fields.key.value &&
          (!passedCurrent || !prevOrNextSpot)
        )
          prevOrNextSpot = spot;
        else passedCurrent = true;
        spots.push({
          key: spot,
          component: (
            <span>
              {" "}
              <FormattedMessage id="process > title spot" /> {ind + 1}{" "}
            </span>
          ),
        });
        return true;
      });
    }

    return (
      <div className="text-left">
        <Form>
          <ShadowBox className="promotion-shadow-box">
            <SectionTitle
              className="promotion-section-title"
              onClick={() =>
                this.setState({
                  isOpenContestDescription: !this.state
                    .isOpenContestDescription,
                })
              }
            >
              <FormattedMessage id="process > title promotion event schedule" />
              {this.isCompletedSection("contest_description") && (
                <FaCheck color="#6F96D1" size={30} />
              )}
            </SectionTitle>
            <SectionFields
              className={!this.state.isOpenContestDescription && "hidden"}
            >
              <Row>
                <Col>
                  <Label>{start_date.title}</Label>
                  <DivView>{valueOrEmpty(start_date)}</DivView>
                </Col>
                <Col>
                  <Label>{end_date.title}</Label>
                  <DivView>{valueOrEmpty(end_date)}</DivView>
                </Col>
              </Row>
            </SectionFields>
          </ShadowBox>

          <ShadowBox className="promotion-shadow-box">
            <SectionTitle
              className="promotion-section-title"
              onClick={() =>
                this.setState({
                  isOpenJockInstructions: !this.state.isOpenJockInstructions,
                })
              }
            >
              <FormattedMessage id="process > title promotion event details" />
              {this.isCompletedSection("jock_instructions") && (
                <FaCheck color="#6F96D1" size={30} />
              )}
            </SectionTitle>
            <SectionFields
              className={!this.state.isOpenJockInstructions && "hidden"}
            >
              <Row>
                <Col>
                  <Label>{event_name.title}</Label>
                  <DivView>{valueOrEmpty(event_name)}</DivView>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>{event_description.title}</Label>
                  <DivView>{valueOrEmpty(event_description)}</DivView>
                </Col>
              </Row>
            </SectionFields>
          </ShadowBox>
        </Form>
        {this.props.mode === "view" && (
          <Row>
            <Col>
              <Button
                color="blue"
                style={{
                  borderRadius: 30,
                  maxWidth: "180px",
                  minWidth: "95px",
                }}
                onClick={() => {
                  history.push(
                    "/processes/edit/" + template.key + "/" + process.key
                  );
                }}
              >
                <FormattedMessage id="process > button edit" />
              </Button>
            </Col>
          </Row>
        )}
        {/* submitted modal */}
        <ConfirmationModal
          isOpen={this.state.isOpenDialogSubmitted}
          onToggle={() =>
            this.setState({
              isOpenDialogSubmitted: !this.state.isOpenDialogSubmitted,
            })
          }
          onCancel={() => this.setState({ isOpenDialogSubmitted: false })}
          state="success"
          title={<FormattedMessage id={`process > title deleted`} />}
        />
      </div>
    );
  }

  render() {
    let { process } = this.props;

    if (!process.data.fields || Object.keys(process.data.fields).length === 0)
      return null;

    let form = null;
    if (this.props.mode === "edit") {
      form = this.renderForm();
    } else {
      form = this.renderView();
    }
    return <span className="orderForm">{form}</span>;
  }
}

export default TeamEquipment;
