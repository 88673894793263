import React, { useEffect } from "react";
import Page from "components/Page";
import { FormattedMessage } from "react-intl";
import SettingsForm from "components/EnterpriseSettings/SettingsForm";
import PrivilegedComponent from "components/PrivilegedComponent";
import { PRIVILEGES } from "utils/constants";
import history from "components/History";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { adminTabs } from "utils/config";
import URL from "utils/urls";
import bn from "utils/bemnames";
import classnames from "classnames";
import BlockingComponent from "components/BlockingComponent";
import ConfirmationModal from "components/ConfirmationModal";
import get from "lodash/get";
import { getRoleIdByName } from "utils/helpers";
import { validatePrivileges } from "utils/helpers";

const bem = bn.create("station-settings");
const EnterpriseSettings = ({
  intl,
  activeTab,
  setActiveTab,
  shouldBlockNavigation,
  setShouldBlockNavigation,
  isOpenConfirmationModal,
  setIsOpenConfirmationModal,
  auth,
}) => {
  const { production_multimarket } = get(auth, "info", {});
  const privileges = get(auth, "user.privileges", []);
  const user = get(auth, "user", {});
  const authIsGodAdmin = get(auth, "user.is_god_admin");
  const authIsBroadcastingGroupAdmin = get(
    auth,
    "user.broadcasting_group_admin"
  );

  useEffect(() => {
    const isShowEnterpriseSettings =
      validatePrivileges({
        requires: {
          or: [
            PRIVILEGES.ADD_SERVERS,
            PRIVILEGES.DELETE_SERVERS,
            PRIVILEGES.VIEW_SERVERS,
            PRIVILEGES.EDIT_SERVERS,
          ],
        },
        privileges,
        user,
      }) || getRoleIdByName("Hub Manager", auth.info.enterprise_roles);
    if (!production_multimarket) history.push("/");
    if (
      !isShowEnterpriseSettings &&
      !authIsBroadcastingGroupAdmin &&
      !authIsGodAdmin
    ) {
      history.push("/");
    }
  }, []);
  return (
    <Page
      className={bem.b()}
      applyPadding={false}
      title={
        <div>
          <nav
            className="cr-page__breadcrumb"
            aria-label="breadcrumb"
            style={{ fontSize: "initial" }}
          >
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={URL.ADMINS({ tab: adminTabs.TAB_UNKNOWN })}>
                  <FormattedMessage id="enterprise settings > admin" />
                </Link>
              </li>
              <li className="active breadcrumb-item" aria-current="page">
                <strong>
                  <FormattedMessage id="enterprise settings > form builder" />
                </strong>
              </li>
            </ol>
          </nav>
          <span className="page-title">
            <FormattedMessage id="enterprise settings > page title" />
          </span>
        </div>
      }
    >
      <div className={"page-container"}>
        <React.Fragment>
          <Nav tabs>
            <PrivilegedComponent requires={[]}>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "settings",
                  })}
                  onClick={() => {
                    setActiveTab("settings");
                  }}
                >
                  {intl.formatMessage({
                    id: "enterprise settings > enterprise settings",
                  })}
                </NavLink>
              </NavItem>
            </PrivilegedComponent>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="settings">
              {activeTab === "settings" ? (
                <SettingsForm
                  shouldBlockNavigation={shouldBlockNavigation}
                  setShouldBlockNavigation={setShouldBlockNavigation}
                />
              ) : null}
            </TabPane>
          </TabContent>
          <BlockingComponent
            isBlocked={shouldBlockNavigation}
            modalProps={{
              title: intl.formatMessage({
                id:
                  "reminder modal > wait, you have unsaved changes in this form",
              }),
              confirmTitle: intl.formatMessage({
                id: "reminder modal > button save",
              }),
              cancelTitle: intl.formatMessage({
                id: "reminder modal > button dismiss",
              }),
              onConfirm: () => {
                const buttonSubmit = document.getElementById(
                  `btn-submit-${activeTab}`
                );
                // trigger click to submit the form
                if (buttonSubmit) buttonSubmit.click();
              },
            }}
          />
          <ConfirmationModal
            isOpen={isOpenConfirmationModal}
            title={intl.formatMessage({
              id:
                "reminder modal > wait, you have unsaved changes in this form",
            })}
            isCloseOutside={false}
            className={bem.e("confirmation-modal")}
            description={intl.formatMessage({
              id:
                "reminder modal > before you can perform this step, you must save or discard your changes",
            })}
            confirmTitle={intl.formatMessage({
              id: "reminder modal > button save",
            })}
            discardTitle={intl.formatMessage({
              id: "reminder modal > button discard",
            })}
            cancelTitle={intl.formatMessage({
              id: "reminder modal > button cancel",
            })}
            onConfirm={() => {
              const buttonSubmit = document.getElementById(
                `btn-submit-${activeTab}`
              );
              // trigger click to submit the form
              if (buttonSubmit) buttonSubmit.click();
              setIsOpenConfirmationModal(false);
            }}
            onToggle={() =>
              setIsOpenConfirmationModal(!isOpenConfirmationModal)
            }
            onDiscard={() => {
              setShouldBlockNavigation(false);
              setIsOpenConfirmationModal(false);
            }}
            onCancel={() => setIsOpenConfirmationModal(false)}
          />
        </React.Fragment>
      </div>
    </Page>
  );
};

export default EnterpriseSettings;
