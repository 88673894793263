import React, { useMemo } from "react";
import PropTypes from "prop-types";
import bn from "utils/bemnames";
import classnames from "classnames";
import { Row, Col, FormGroup, Label, Button } from "reactstrap";
import { FaClose } from "react-icons/lib/fa";
import { FormattedMessage } from "react-intl";
import FlightScheduleModal, {
  generateDescription,
} from "components/FlightScheduleModal";
import Dropdown from "components/Dropdown";
import {
  get,
  map,
  isEqual,
  filter,
  includes,
  intersection,
  find,
} from "lodash";
import moment from "moment";
import { Asterisk } from "components/Elements";
import CustomForm from "components/CustomForm";
import MediaChannel from "components/MediaChannel";
import { RecurrenceIcon } from "components/CustomIcons";
import ConfirmationModal from "components/ConfirmationModal";
import { PRIVILEGES } from "utils/constants";
import { validatePrivileges } from "utils/helpers";

const bem = bn.create("traffic-details");
function getChannelsOptions({ channels, selectedStations, stationsData }) {
  const channelOptions = channels
    .filter((channel) => {
      if (channel.is_hidden) return false;
      return (
        includes(selectedStations, channel.station_id) ||
        intersection(selectedStations, channel.stations).length > 0
      );
    })
    .map((channel) => {
      const isShared =
        intersection(selectedStations, channel.stations).length > 0;
      const channelStation = find(
        stationsData,
        (station) => station.station_key === channel.station_id
      );
      return {
        label: channel.name,
        value: channel.id,
        data: channel,
        isShared,
        channelStation,
      };
    });
  return channelOptions;
}
const TrafficDetails = ({
  intl,
  channels,
  step,
  valueChanged,
  template,
  user,
  validationState,
  process,
  isOpenFlightSchedule,
  setIsOpenFlightSchedule,
  isView,
  isTrafficView,
  buttonClicked,
  auth,
  shouldBlockNavigation,
  setShouldBlockNavigation,
  setBlockNavigationAction,
  dateFormatByServer,
  ...rest
}) => {
  const stationsData = get(auth, "info.stations", []);
  const selectedStations = get(
    process,
    "data.fields.contract_stations.value",
    []
  );

  const channelOptions = getChannelsOptions({
    channels,
    selectedStations: selectedStations,
    stationsData,
  });
  const marketing_channels = step.fields.find(
    (f) => f.field === "marketing_channels"
  );
  const campaign_schedule = step.fields.find(
    (f) => f.field === "campaign_schedule"
  );
  const selectedChannels = get(
    process,
    `data.fields.${marketing_channels.field}.value`,
    []
  );
  const selectedCampaignSchedule = get(
    process,
    `data.fields.${campaign_schedule.field}.value`,
    null
  );
  const selectedChannelsOption = map(selectedChannels, (channel) => ({
    label: channel.name,
    value: channel.id,
    data: channel,
  }));
  const channelCampaignSchedule = filter(
    selectedChannels,
    (channel) => channel.schedule
  );
  const hasOrderStations =
    get(process, "data.fields.contract_stations.value.length", 0) > 0;
  const isAdd =
    document.location.pathname.split("/")[2] === "add" ? true : false;
  const processStep = get(process, "data.fields.process_step.value");
  const isDraft = processStep === "draft";
  const getIsViewMode = (channel) => {
    if (isView) {
      return isView;
    }
    const digitalAccountExecutive = get(
      process,
      "data.fields.role_digital_account_executive.value.id"
    );
    const privileges = get(user, "privileges", []);
    // check user can edit by privileges
    const found = validatePrivileges({
      requires: PRIVILEGES.EDIT_ALL_DIGITAL_ORDERS,
      privileges,
      user,
    });
    return (
      !isAdd &&
      !isDraft &&
      ![digitalAccountExecutive, channel.channel_manager].includes(user.id) &&
      !found
    );
  };
  const scheduleDatesText = useMemo(() => {
    if (!selectedCampaignSchedule) return "";
    let arr = [];
    if (selectedCampaignSchedule.start_date) {
      arr.push(
        moment(selectedCampaignSchedule.start_date, "YYYY-MM-DD").format(
          dateFormatByServer
        )
      );
    }
    if (selectedCampaignSchedule.end_date) {
      arr.push(
        moment(selectedCampaignSchedule.end_date, "YYYY-MM-DD").format(
          dateFormatByServer
        )
      );
    }
    return arr.join(" - ");
  }, [selectedCampaignSchedule]);
  const renderView = () => (
    <Row className={bem.e("view-container")}>
      <Col xs={6}>
        <FormGroup>
          <Label
            className={classnames(
              bem.e("view-field-label"),
              bem.e("view-field-dates-label")
            )}
          >
            <FormattedMessage id="traffic details > field campaign flight dates" />
          </Label>

          {!selectedCampaignSchedule ? (
            <div className={bem.e("text-description")}>
              <FormattedMessage id="traffic details > no flight dates selected" />
            </div>
          ) : (
            <div
              className={classnames(
                bem.e("schedule-view-value"),
                bem.e("schedule-dates-view-value")
              )}
            >
              {scheduleDatesText ||
                intl.formatMessage({ id: "process > none" })}
            </div>
          )}
        </FormGroup>
        <FormGroup className={bem.e("recurrence-statement")}>
          <Label className={bem.e("view-field-label")}>
            <FormattedMessage id="traffic details > recurrence" />
          </Label>
          {!selectedCampaignSchedule ? (
            <div className={bem.e("text-description-empty")}>
              <FormattedMessage id="traffic details > you have not set your flight dates for this campaign" />
            </div>
          ) : (
            <div className={bem.e("schedule-view-value")}>
              {generateDescription(intl, selectedCampaignSchedule)}
            </div>
          )}
        </FormGroup>
      </Col>
      <Col xs={6}>
        {channelCampaignSchedule
          .filter((item) => {
            return !isEqual(selectedCampaignSchedule, item.schedule);
          })
          .map((item, index) => {
            return (
              <FormGroup key={index}>
                <Label className={bem.e("special-schedule")}>
                  <RecurrenceIcon color="#FF006C" />
                  <FormattedMessage id="traffic details > special schedule" />:{" "}
                  {item.name}
                </Label>
                {item.schedule ? (
                  <div className={bem.e("text-description")}>
                    {generateDescription(intl, item.schedule)}
                  </div>
                ) : null}
              </FormGroup>
            );
          })}
      </Col>
    </Row>
  );
  const renderForm = () => (
    <Row className={bem.e("traffic-detail-edit")}>
      <Col xs={6}>
        <FormGroup className={bem.e("marketing-channels")}>
          <Dropdown
            label={
              <span>
                <FormattedMessage id="traffic details > select marketing channels" />
                {marketing_channels.mandatory && <Asterisk>*</Asterisk>}
              </span>
            }
            className="select-stations-hide-value"
            value={selectedChannelsOption}
            placeholder={intl.formatMessage({
              id: "traffic details > select your channels",
            })}
            isMulti
            allowSelectAll
            disabled={!hasOrderStations}
            onChange={(selectedOption) => {
              const campaignSchedule = get(
                process,
                "data.fields.campaign_schedule.value",
                {}
              );
              const newSelectedChannels = map(selectedOption, (item) => {
                return {
                  ...item.data,
                  schedule: get(item, "data.schedule")
                    ? get(item, "data.schedule")
                    : campaignSchedule
                    ? {
                        ...campaignSchedule,
                        due_date: get(campaignSchedule, "start_date", ""),
                      }
                    : null,
                };
              });
              valueChanged(marketing_channels, process, template, user, {
                value: newSelectedChannels,
              });
              setShouldBlockNavigation(true);
            }}
            formatOptionLabel={(option) => {
              const { label, isShared, channelStation, value } = option;
              if (value === "*") return label;
              const isShowLabel = get(channelStation, "name", "") || isShared;
              return (
                <div className="value-container">
                  <span>{label}</span>
                  {isShowLabel && (
                    <React.Fragment>
                      {`  `}-{`  `}
                      <strong>
                        {isShared
                          ? intl.formatMessage({
                              id: "channel manager > shared",
                            })
                          : get(channelStation, "name", "")}
                      </strong>
                    </React.Fragment>
                  )}
                </div>
              );
            }}
            options={channelOptions}
            name="channels"
          />
          <div className="alert-danger">
            {validationState[marketing_channels.field] &&
              typeof validationState[marketing_channels.field]
                .validation_error === "string" &&
              validationState[marketing_channels.field].validation_error}
          </div>
        </FormGroup>
        <FormGroup>
          <Label className={bem.e("text-label")}>
            <FormattedMessage id="traffic details > selected channels" />
          </Label>
          <div className={bem.e("selected-list")}>
            {selectedChannels.map((channel, index) => (
              <div className={bem.e("selected-item")} key={index}>
                <FaClose
                  color="#C2D4E0"
                  className={bem.e("trash-icon")}
                  onClick={() => {
                    rest.setIsOpenConfirmationModal(true);
                    rest.setCurrentChannelDelete(channel);
                  }}
                />
                <span className={bem.e("selected-name")}>{channel.name}</span>
              </div>
            ))}
            {selectedChannels.length === 0 && (
              <div className={bem.e("empty-text")}>
                <FormattedMessage id="traffic details > no channels selected" />
              </div>
            )}
          </div>
        </FormGroup>
      </Col>
      <Col xs={6}>
        <FormGroup>
          <Label className={bem.e("text-label")}>
            {campaign_schedule.title}
            {campaign_schedule.mandatory && <Asterisk>*</Asterisk>}
          </Label>

          {!selectedCampaignSchedule ? (
            <div className={bem.e("text-campaign-description")}>
              <FormattedMessage id="traffic details > no flight dates selected" />
            </div>
          ) : (
            <div className={bem.e("text-value")}>
              {scheduleDatesText ||
                intl.formatMessage({ id: "process > none" })}
            </div>
          )}

          <div className="alert-danger">
            {validationState[campaign_schedule.field] &&
              validationState[campaign_schedule.field].validation_error}
          </div>
        </FormGroup>
        <FormGroup className={bem.e("recurrence-statement")}>
          <Label className={bem.e("text-label")}>
            <FormattedMessage id="traffic details > recurrence statement" />
          </Label>
          <div
            className={classnames(
              bem.e("text-description", {
                [bem.e("text-description-empty")]: !selectedCampaignSchedule,
              })
            )}
          >
            {!selectedCampaignSchedule ? (
              <FormattedMessage id="traffic details > you have not set your flight dates for this campaign" />
            ) : (
              generateDescription(intl, selectedCampaignSchedule)
            )}
          </div>
        </FormGroup>
        <div className={bem.e("button-campaign")}>
          <Button
            color="blue"
            outline
            type="button"
            className={classnames("btn btn-radius", bem.e("btn-recurrence"), {
              [bem.e("btn-has-campaign")]: selectedCampaignSchedule,
            })}
            disabled={!hasOrderStations}
            onClick={() => setIsOpenFlightSchedule(true)}
          >
            {!selectedCampaignSchedule ? (
              <FormattedMessage id="traffic details > create campaign schedule" />
            ) : (
              <>
                <span className={bem.e("recurrence-icon")}>
                  <RecurrenceIcon />
                </span>
                <FormattedMessage
                  id={`traffic details > adjust campaign schedule`}
                />
              </>
            )}
          </Button>
        </div>
      </Col>
      <FlightScheduleModal
        isOpen={isOpenFlightSchedule}
        onToggle={() => setIsOpenFlightSchedule(!isOpenFlightSchedule)}
        value={selectedCampaignSchedule}
        isChannel={false}
        onSubmitForm={(values) => {
          valueChanged(campaign_schedule, process, template, user, {
            value: values,
          });
          setShouldBlockNavigation(true);
          setIsOpenFlightSchedule(false);
        }}
      />
    </Row>
  );
  const renderChannels = () => {
    if (isTrafficView) {
      // If a channel requires production
      const marketingChannels = get(
        process,
        "data.fields.marketing_channels.value",
        []
      );
      if (marketingChannels.length === 0) return null;
      return (
        <div className={bem.e("channels-list")}>
          {marketingChannels.map((channel, index) => {
            const isViewMode = getIsViewMode(channel);
            return (
              <div className={bem.e("channel-form")} key={index}>
                <MediaChannel
                  channel={channel}
                  isView={isViewMode}
                  process={process}
                  template={template}
                  user={user}
                  step={step}
                  idx={index}
                  buttonClicked={buttonClicked}
                  validationState={validationState}
                  valueChanged={valueChanged}
                  setShouldBlockNavigation={setShouldBlockNavigation}
                  setBlockNavigationAction={setBlockNavigationAction}
                />
              </div>
            );
          })}
        </div>
      );
    } else {
      if (selectedChannels.length === 0) return null;
      return (
        <div className={bem.e("channels-list")}>
          {selectedChannels.map((channel, index) => {
            const isViewMode = getIsViewMode(channel);
            return (
              <div className={bem.e("channel-form")} key={channel.id}>
                <CustomForm
                  channel={channel}
                  process={process}
                  onSubmitForm={(values) => {
                    selectedChannels[index] = values;
                    valueChanged(marketing_channels, process, template, user, {
                      value: selectedChannels,
                    });
                    setShouldBlockNavigation(true);
                  }}
                  onDeleteChannel={() => {
                    rest.onDeleteChannel(channel, selectedChannels);
                  }}
                  isShowStatus={true}
                  isView={isViewMode}
                  isShowRequiresPlacement={true}
                  isShowAdjustChannelSchedule={!isViewMode}
                  isShowCollapse={true}
                  isDefaultCollapse={true}
                  isDigitalOrder={true}
                  isShowDeleteChannel={!isViewMode}
                  validationState={get(
                    validationState,
                    `marketing_channels.validation_error.${index}`
                  )}
                />
              </div>
            );
          })}
          {/* confirmation delete modal */}
          <ConfirmationModal
            isOpen={rest.isOpenConfirmationModal}
            className={bem.e("confirmation-modal")}
            title={<FormattedMessage id="process > title deleted channel" />}
            confirmTitle={<FormattedMessage id="process > button yes" />}
            cancelTitle={<FormattedMessage id="process > button no" />}
            onCancel={() => {
              rest.setIsOpenConfirmationModal(false);
              rest.setCurrentChannelDelete(null);
            }}
            onConfirm={() => {
              rest.onDeleteChannel(rest.currentChannelDelete, selectedChannels);
              rest.setIsOpenConfirmationModal(false);
              setShouldBlockNavigation(true);
            }}
            onToggle={() =>
              rest.setIsOpenConfirmationModal(!rest.isOpenConfirmationModal)
            }
            isCloseOutside={false}
          />
        </div>
      );
    }
  };
  return (
    <div className={bem.b()}>
      <div className={bem.e("main-section")}>
        <div className={bem.e("main-title")}>
          <h3 className={bem.e("title")}>
            <FormattedMessage id="traffic details > title" />
          </h3>
        </div>
        <div className={bem.e("main-content")}>
          {isView ? renderView() : renderForm()}
        </div>
      </div>
      {renderChannels()}
    </div>
  );
};
TrafficDetails.propTypes = {
  channels: PropTypes.array,
  isView: PropTypes.bool,
  isTrafficView: PropTypes.bool,
};
export default TrafficDetails;
