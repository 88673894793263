import { connect } from "react-redux";
import View from "./view";
import { get } from "lodash";
import { injectIntl } from "react-intl";
export default injectIntl(
  connect((state, props) => {
    const authPrivileges = get(state, "auth.info.privileges", []);
    const privileges = Object.keys(authPrivileges).map((k) => ({
      id: k,
      name: authPrivileges[k],
    }));
    return {
      // auth: state.auth,
      enable_production: get(state, "auth.info.enable_production", false),
      enable_promotion: get(state, "auth.info.enable_promotion", false),
      enable_digital: get(state, "auth.info.enable_digital", false),
      production_multimarket: get(
        state,
        "auth.info.production_multimarket",
        false
      ),
      digital_multimarket: get(state, "auth.info.digital_multimarket", false),
      promotion_multimarket: get(
        state,
        "auth.info.promotion_multimarket",
        false
      ),
      stations: get(state, "auth.info.stations", []),
      privileges,
    };
  })(View)
);
