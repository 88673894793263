import React from "react";
import { FormattedMessage } from "react-intl";
import { Button, Row, Col } from "reactstrap";
import classnames from "classnames";
import TextInput from "components/TextInput";
import Spinner from "components/Spinner";
import {
	CheckedIcon,
	ApproveIcon,
	TrashIcon,
	SendMailIcon
} from "components/CustomIcons";
import { get } from "lodash";
import { Formik } from "formik";
import * as Yup from "yup";
import bn from "utils/bemnames";
import PrivilegedComponent from "components/PrivilegedComponent";
import { PRIVILEGES } from "utils/constants";
const bem = bn.create("digital-approval");

let formikRef = React.createRef();
const DigitalApproval = ({
	step,
	template,
	user,
	process,
	isOpenComment,
	setIsOpenComment,
	comment,
	setComment,
	intl,
	isLoading,
	setIsLoading,
	getApprovers,
	valueChanged,
	onApprove,
	onReject,
	isView,
	...rest
}) => {
	if (step.is_hidden && step.is_hidden(user, process, step)) return null;
	const approveOrder = step.fields.find(f => f.field === "approve");
	const rejectOrder = step.fields.find(f => f.field === "reject_order");
	const completedApprovalsValue = get(
		process,
		"data.fields.completed_approvals.value",
		[]
	);
	const rejectedApprovalsValue = get(
		process,
		"data.fields.rejected_approvals.value",
		[]
	);
	const isApproved =
		get(process, "data.fields.approve_order_status.value") ===
			"in progress" &&
    completedApprovalsValue.find(item => item.id === user.id);
	const isRejected =
		get(process, "data.fields.approve_order_status.value") === "rejected" &&
		rejectedApprovalsValue.find(item => item.id === user.id);
  const approvers = getApprovers();
	return (
		<div className={bem.b()}>
			<div className={bem.e("wrapper")}>
				<Row>
					<Col xs={6}>
						<h3 className={bem.e("title")}>
							<FormattedMessage id="process > title please review this order" />
						</h3>
						<div className={bem.e("buttons")}>
              <PrivilegedComponent requires={{ or: [PRIVILEGES.APPROVE_ORDER, PRIVILEGES.EDIT_ALL_DIGITAL_ORDERS]}}>
                <Button
                  color=""
                  className={classnames(bem.e("button-approve"), {
                    [bem.e("button-approved")]: isApproved
                  })}
                  disabled={isView}
                  onClick={() => {
                    onApprove();
                  }}
                >
                  <span className={bem.e("button-icon")}>
                    <ApproveIcon color="#fff" />
                  </span>
                  {approveOrder.text}
                </Button>
                <Button
                  color=""
                  className={classnames(bem.e("button-reject"), {
                    [bem.e("button-rejected")]: isRejected
                  })}
                  onClick={event => {
                    setIsOpenComment(true);
                    // set focus submit form for display message
                    if(formikRef.current){
                      formikRef.current.handleSubmit(event)
                    }
                  }}
                  disabled={isView}
                >
                  <span className={bem.e("button-icon")}>
                    <TrashIcon />
                  </span>
                  {rejectOrder.text}
                </Button>
              </PrivilegedComponent>
							
						</div>
					</Col>
					<Col xs={6}>
						<div>
							<h3 className={bem.e("completed-title")}>
								<FormattedMessage id="process > title completed approvals" />
							</h3>
							<ul className={bem.e("list-approvals")}>
								{approvers.map((approver, index) => {
									const itemIsApproved = completedApprovalsValue.find(
										item => item.id === approver.id
									);
									return (
										<li key={index}>
											{!itemIsApproved ? (
												<span
													className={bem.e(
														"uncheck-icon"
													)}
												/>
											) : (
												<span
													className={bem.e(
														"checked-icon"
													)}
												>
													<CheckedIcon />
												</span>
											)}

											<span>{approver.name}</span>
										</li>
									);
								})}
							</ul>
						</div>
					</Col>
					<Col
						xs={12}
						className={classnames({
							[bem.e("show")]: isOpenComment,
							[bem.e("hide")]: !isOpenComment
						})}
					>
						<div className={bem.e("comment-wrapper")}>
							<Formik
								enableReinitialize
								ref={formikRef}
								initialValues={{
									comment: ""
								}}
								validationSchema={Yup.object().shape({
									comment: Yup.string().required(
										intl.formatMessage({
											id: "validate > please submit feedback"
										})
									)
								})}
								onSubmit={values => {
									rest.onSubmitComment(values.comment, () => {
										setIsLoading(false);
										if (formikRef.current) {
											formikRef.current.resetForm();
											onReject();
										}
									});
								}}
							>
								{formProps => {
									const errors = formProps.errors;
									const touched = formProps.touched;
									const values = formProps.values;
									return (
										<div
											onSubmit={formProps.handleSubmit}
											className={bem.e("form-comment")}
										>
											<TextInput
												type="textarea"
												value={values.comment}
												name="comment"
												onChange={
													formProps.handleChange
												}
												onBlur={formProps.handleBlur}
												placeholder={intl.formatMessage(
													{
														id:
															"process > placeholder send your feedback"
													}
												)}
												error={
													touched.comment &&
													errors.comment
												}
											/>
											<button
												className={bem.e("send-button")}
												type="submit"
												onClick={e => {
													e.preventDefault();
													formikRef.current.handleSubmit(
														e
													);
												}}
											>
												<SendMailIcon />
											</button>
										</div>
									);
								}}
							</Formik>
						</div>
					</Col>
				</Row>
			</div>
			<Spinner isLoading={isLoading} />
		</div>
	);
};
DigitalApproval.defaultProps = {
	buttonClicked: () => {},
	valueChanged: () => {},
	isView: false
};
export default DigitalApproval;
