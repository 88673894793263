import EditClock from "./view";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { get, map, omit, values as lodashValues } from "lodash";
import { compose, withState, withHandlers, lifecycle } from "recompose";
import history from "components/History";
import URL from "utils/urls";
import { SHOWCLOCK, PROGCLOCK } from "utils/constants";
import ToastManager from "components/ToastManager";
import {
  getClock,
  updateClock,
  updateClockRelations,
} from "store/actions/clocks";

export default injectIntl(
  compose(
    connect(
      (state, ownProps) => {
        return {
          selectedClock: get(
            state,
            `clocks.viewedClocks.${ownProps.clockId}`,
            {}
          ),
          updateClockError: get(state, "clocks.updateClock.error", false),
        };
      },
      {
        getClock,
        updateClock,
        updateClockRelations,
      }
    ),
    withState("isLoading", "setIsLoading", false),
    withState("isEditClicked", "setIsEditClicked", false),
    withState("isWeekStartModalOpen", "setIsWeekStartModalOpen", false),
    withState("isCancelWarningModalOpen", "setIsCancelWarningModalOpen", false),
    withState("isReleasePanelOpen", "setIsReleasePanelOpen", true),
    withState("isRulesPanelOpen", "setIsRulesPanelOpen", false),
    withState("isSaveConfirmed", "setIsSaveConfirmed", false),
    withState("isSaveConfirmModalOpen", "setIsSaveConfirmModalOpen", false),
    withState("deleteSuccessModalTitle", "setDeleteSuccessModalTitle", false),
    withState("slotBeingModified", "setSlotBeingModified", {}),
    withState("deleteStep", "setDeleteStep", 0),
    withState("shouldBlockNavigation", "setShouldBlockNavigation", false),
    withHandlers({
      onSaveClock: ({
        setShouldBlockNavigation,
        updateClock,
        setIsLoading,
        clockId,
        setIsEditClicked,
        selectedClock,
        updateClockRelations,
        setIsWeekStartModalOpen,
        intl,
        setIsSaveConfirmed,
        setIsSaveConfirmModalOpen,
      }) => (values) => {
        setIsLoading(true);
        if (get(selectedClock, "data.type") === SHOWCLOCK) {
          let data = {
            title: values.title,
            station_id: get(values, "station.value", ""),
            type: get(values, "type.value", ""),
          };

          updateClock(clockId, data, (clock) => {
            setIsLoading(false);
            setIsEditClicked(false);
            setShouldBlockNavigation(false);
            if (clock.type !== get(selectedClock, "type", "")) {
              history.push(
                URL.CLOCK_EDITOR({
                  type: clock.type,
                  clock_id: clock.id,
                })
              );
            }
          });
        } else {
          if (!values.start_week_on) {
            setIsWeekStartModalOpen(true);
            return false;
          }
          const slot_definitions = lodashValues(values.slot_definitions).filter(
            (item) => !item.deleted
          );
          let data = {
            clock_id: clockId,
            clock_title: values.title,
            station_id: get(values, "station.value", ""),
            type: get(values, "type.value", ""),
            start_week_on: values.start_week_on,
            slot_definitions: map(
              slot_definitions,
              (
                {
                  id,
                  guid,
                  national,
                  category,
                  type,
                  color,
                  label,
                  release_to_local,
                  assign_to,
                  days_before_date,
                  deleted,
                  reassigned_items_to,
                  remove_scheduled_items,
                  ...rest
                },
                index
              ) => {
                let _slot = {
                  id,
                  guid: guid.toString(),
                  category_id: parseInt(get(category, "value", "")),
                  type_id: parseInt(get(type, "value", "")),
                  name: label,
                  color,
                  order_color: get(rest, "order_color", index),
                  national,
                  assign_to: release_to_local
                    ? get(assign_to, "guid", "").toString()
                    : null,
                  days_before_date: release_to_local ? days_before_date : null,
                };

                if (typeof id === "string" && id.includes("_new")) {
                  _slot = omit(_slot, ["id"]);
                }
                // Add delete always if the slot definition is deleted
                if (deleted) {
                  _slot = { ..._slot, deleted };
                }

                if (reassigned_items_to) {
                  _slot = {
                    ..._slot,
                    reassigned_items_to: get(reassigned_items_to, "guid"),
                  };
                }

                if (remove_scheduled_items) {
                  _slot = { ..._slot, remove_scheduled_items };
                }

                return _slot;
              }
            ),
          };
          if (get(values, "type.value", "") === PROGCLOCK) {
            data.separation_rules = get(values, "separation_rules", {});
          }
          updateClockRelations("define_slots", data, (clock) => {
            setIsLoading(false);
            setIsEditClicked(false);
            setIsSaveConfirmModalOpen(false);
            setIsSaveConfirmed(false);
            setShouldBlockNavigation(false);
            ToastManager.show({
              title: intl.formatMessage({ id: "toast > title saved" }),
              level: "success",
            });
            if (clock.type !== get(selectedClock, "type", "")) {
              history.push(
                URL.CLOCK_EDITOR({
                  type: clock.type,
                  clock_id: clock.id,
                })
              );
            }
          });
        }
      },
      addDefaultSeparationRules: () => ({
        values,
        setFieldValue,
        setFieldTouched,
      }) => {
        if (!values.separation_rules) {
          const defaultRules = {
            consecutive_days_rule: true,
            label_rule: true,
            linked_items_rule: true,
            linked_items_min_separation_time: true,
            linked_items_priority: [],
            time_rule: true,
            time_rule_min_separation_time: [],
          };
          setFieldValue("separation_rules", defaultRules);
          setFieldTouched("separation_rules", true);
        }
      },
      addEmptySlot: (props) => ({ values, setFieldValue, setFieldTouched }) => {
        let id = `${Date.now()}_new`;
        let guid = Date.now();

        let slot = {
          id,
          national: false,
          type: null,
          category: null,
          color: "",
          label: "",
          deleted: false,
          guid: guid,
          assign_to: null,
          days_before_date: null,
          release_to_local: false,
        };

        let slots = { ...values.slot_definitions, [id]: slot };

        setFieldValue("slot_definitions", slots);
        setFieldTouched("slot_definitions", true);
      },
    }),
    lifecycle({
      componentDidMount() {
        if (this.props.clockId) {
          this.props.getClock(this.props.clockId);
          window.scrollTo(0, 0);
        }
      },
      componentDidUpdate(prevProps) {
        if (
          this.props.isEditClicked === false &&
          prevProps.isEditClicked === true
        ) {
          this.props.getClock(this.props.clockId);
        }

        if (
          (this.props.updateClockError || this.props.updateClockError) &&
          this.props.isLoading
        ) {
          this.props.setIsLoading(false);
        }
      },
    })
  )(EditClock)
);
