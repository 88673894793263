import { compose, withState, lifecycle } from "recompose";
import { connect } from "react-redux";
import { getCurrentServer } from "store/actions/servers";
import { first, includes, get, uniqBy, filter, forOwn, orderBy } from "lodash";
import View from "./view";
import { injectIntl } from "react-intl";
export default injectIntl(
  compose(
    connect(
      ({ auth, servers }) => {
        let stations = [];
        const entities = get(
          servers,
          `currentServer.data.entities.promotion`,
          {}
        );
        forOwn(entities, (entityStations) => {
          stations = [...stations, ...entityStations];
        });
        const stationKeys = uniqBy(stations, "id").map((item) => item.key);
        const promotionStations = filter(auth.info.stations, (item) =>
          includes(stationKeys, item.key)
        );
        const orderedStations = orderBy(
          promotionStations,
          ["call_letters"],
          ["asc"]
        );

        return {
          auth,
          user: auth.user,
          stations: orderedStations,
          currentServer: get(servers, "currentServer.data", {}),
        };
      },
      {
        getCurrentServer,
      }
    ),
    withState(
      "selectedFulfillmentAutomation",
      "setSelectedFulfillmentAutomation",
      null
    ),
    withState("isOpenConfirmationModal", "setIsOpenConfirmationModal", false),
    withState("shouldBlockNavigation", "setShouldBlockNavigation", false),
    withState("activeTab", "setActiveTab", "eligibility"),
    withState(
      "currentStation",
      "setCurrentStation",
      ({ stations }) => first(stations) || null
    ),
    lifecycle({
      componentDidMount() {
        this.props.getCurrentServer(() => {
          const firstStation = first(this.props.stations);
          const currentStation = firstStation
            ? {
                value: firstStation.key,
                label: firstStation.name,
                key: firstStation.key,
                name: firstStation.name,
              }
            : null;
          this.props.setCurrentStation(currentStation);
        });
      },
    })
  )(View)
);
