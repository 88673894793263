import { connect } from "react-redux";
import ItemAvailability from "./view";

import { injectIntl } from "react-intl";
import {
  checkSchedulingAvailability,
  findInventory,
  releaseInventory,
  saveInventory,
  resetCheckSchedulingAvailability,
} from "store/actions/programmings";
import { compose, withHandlers } from "recompose";
import { find, forEach, get, includes, map, filter } from "lodash";
import history from "components/History";
import withState from "recompose/withState";
import { renderFinalScheduleStatement } from "./view";
const Enhanced = compose(
  connect(
    (state) => {
      const scheduleAvailability = get(
        state,
        "programmings.scheduleAvailability.data"
      );
      const inventory = get(state, "programmings.inventory.data", []);
      return {
        isLoading:
          get(state, "programmings.scheduleAvailability.loading", false) ||
          get(state, "programmings.inventory.loading", false),
        scheduleAvailability,
        dayparts: get(scheduleAvailability, "dayparts", []),
        shows: get(scheduleAvailability, "shows", []),
        restrictions: get(
          state,
          "programmings.clocksData.data.restrictions",
          {}
        ),
        availability: get(scheduleAvailability, "availability", []),
        inventory,
      };
    },
    {
      checkSchedulingAvailability,
      findInventory,
      releaseInventory,
      saveInventory,
      resetCheckSchedulingAvailability,
    }
  ),
  withState(
    "isOpenSupplementItemsModal",
    "setIsOpenSupplementItemsModal",
    false
  ),
  withHandlers({
    generateParams: ({ process }) => (data = {}) => {
      const stationKey = get(
        process,
        "data.fields.order_stations_array.value.0.key"
      );
      const templateKey = get(process, "data.fields.template_key.value", "");
      const processKey = get(process, "data.fields.key.value");
      const typeId = get(process, "data.fields.type_id.value");
      const startDate = get(process, "data.fields.order_start_date.value");
      const endDate = get(process, "data.fields.order_end_date.value");
      const daysOfWeek = get(process, "data.fields.days_of_week.value");
      const schedulingType = get(process, "data.fields.scheduling_type.value");
      const excludedDates = get(process, "data.fields.excluded_dates.value");
      const excludedHours = get(
        process,
        "data.fields.excluded_hours_array.value"
      );
      const per = get(process, "data.fields.per.value");
      const mentions = get(process, "data.fields.mentions.value");
      const selectedBy = get(process, "data.fields.selected_by.value", "show");
      const scheduleBy = get(process, "data.fields.schedule_by.value", "");
      const desiredItemsScheduling = get(
        process,
        "data.fields.desired_items_scheduling.value"
      );
      const relaxRestrictionsFor = get(
        process,
        "data.fields.relax_restrictions_for.value"
      );
      const relaxRestrictionsForWeeks = get(
        process,
        "data.fields.relax_restrictions_for_weeks.value",
        []
      );
      const selectedItems = get(
        process,
        "data.fields.selected_items.value",
        []
      ).map((item) => {
        if (selectedBy === "daypart" || selectedBy === "show") {
          return item.label;
        }
        return item.value;
      });
      if (schedulingType === "targeted" || schedulingType === "simple") {
        data = {
          per,
          mentions,
          selected_by: selectedBy,
          selected_items: selectedItems,
          relax_restrictions_for: relaxRestrictionsFor,
          relax_restrictions_for_weeks: relaxRestrictionsForWeeks,
          ...data,
        };
      } else {
        data = {
          ...data,
          selected_by: selectedBy,
        };
      }
      if (templateKey === "contest") {
        data = {
          ...data,
          contests_scheduling: true,
        };
      }
      if (desiredItemsScheduling) {
        data = {
          ...data,
          desired_items: desiredItemsScheduling,
        };
      }

      if (excludedHours && excludedHours.length > 0) {
        let excludedHoursData = [];
        const hourAll = find(excludedHours, (i) => i.key === "all");
        const excludedHoursAll = get(hourAll, "value", []).map(
          (item) => item.value
        );
        forEach(excludedHours, (hourItem) => {
          let hours = map(hourItem.value, (i) => i.value);
          if (hourItem.key !== "all") {
            // remove hours selected from all for days of week hours
            hours = hours.filter((i) => !includes(excludedHoursAll, i));
          }
          if (hours.length > 0) {
            excludedHoursData = [
              ...excludedHoursData,
              {
                ...hourItem,
                value: hours,
              },
            ];
          }
        });
        data.excluded_hours_array = excludedHoursData;
      }
      return {
        station_id: stationKey,
        process_key: processKey,
        type_id: typeId,
        start_date: startDate,
        end_date: endDate,
        days_of_week: daysOfWeek,
        excluded_dates: excludedDates,
        scheduling_type: schedulingType,
        schedule_by: scheduleBy,
        ...data,
      };
    },
    onCheckSaveDraft: ({ buttonClicked, step, template, user }) => (
      process,
      cb
    ) => {
      const isNew = window.location.href.indexOf("add") !== -1;
      const templateKey = get(process, "data.fields.template_key.value", "");
      // re-check for automatic save as draft when details filled
      let fields = [
        "order_title",
        "days_of_week",
        "order_end_date",
        "order_start_date",
      ];
      if (templateKey !== "contest") {
        fields = [...fields, "type_id"];
      }
      const isInValidFieldValue =
        filter(fields, (field) => {
          if (field === "days_of_week") {
            return get(process, `data.fields.${field}.value.length`, 0) === 0;
          }
          return !get(process, `data.fields.${field}.value`);
        }).length > 0;
      const isCanSaveDraft = !isInValidFieldValue && isNew;
      if (isCanSaveDraft) {
        buttonClicked(
          step,
          {
            ...step.fields.find((f) => f.key === "save_button"),
            draft: true,
            is_ignore_validate: true,
          },
          process,
          template,
          user,
          null,
          (response) => {
            let processKey = response.data.key;
            history.replace(`/processes/edit/${template.key}/${processKey}`);
            setTimeout(() => {
              cb(response);
            }, 1000);
          },
          true
        );
      }
      return isCanSaveDraft;
    },
  }),
  withHandlers({
    checkAvailability: ({
      checkSchedulingAvailability,
      generateParams,
      onValueChanged,
      step,
      process,
      resetCheckSchedulingAvailability,
    }) => (data = {}, isNew = false) => {
      const desired_items_scheduling = step.fields.find(
        (f) => f.field === "desired_items_scheduling"
      );
      const total_available_items = step.fields.find(
        (f) => f.field === "total_available_items"
      );
      const schedulingType = get(process, "data.fields.scheduling_type.value");
      const template = get(process, "data.fields.template_key.value");
      checkSchedulingAvailability({
        data: generateParams(data),
        cb: (response) => {
          const responseData = get(response, "data");
          if (isNew) resetCheckSchedulingAvailability();
          if (responseData) {
            if (schedulingType === "targeted") {
              onValueChanged(
                total_available_items,
                responseData.total_available_items
              );
              // Note: Do not update total desired items if contest
              if (!["contest"].includes(template)) {
                onValueChanged(
                  desired_items_scheduling,
                  responseData.total_desired_items
                );
              }
            }
            if (schedulingType === "simple") {
              const newTotal =
                get(data, "selected_by") === "show"
                  ? get(responseData, "total_count_show_availabilty", "")
                  : get(data, "selected_by") === "daypart"
                  ? get(responseData, "total_count_dayparts_availabilty", "")
                  : ""; // add default daypart
              onValueChanged(total_available_items, newTotal);
            }
          }
        },
      });
    },
  }),
  withHandlers({
    checkIsInValidFindInventoryCheck: ({ process }) => () => {
      const stationKey = get(
        process,
        "data.fields.order_stations_array.value.0.key"
      );
      const orderTitle = get(process, "data.fields.order_title.value", "");
      const templateKey = get(process, "data.fields.template_key.value", "");
      const selectedBy = get(process, "data.fields.selected_by.value");
      let typeId = get(process, "data.fields.type_id.value");
      const startDate = get(process, "data.fields.order_start_date.value");
      const endDate = get(process, "data.fields.order_end_date.value");
      const daysOfWeek = get(process, "data.fields.days_of_week.value");
      const schedulingType = get(process, "data.fields.scheduling_type.value");
      if (templateKey === "contest") typeId = true; // ignore typeId for contest
      if (schedulingType === "targeted") {
        const per = get(process, "data.fields.per.value");
        const mentions = get(process, "data.fields.mentions.value");
        const selectedItems = get(
          process,
          "data.fields.selected_items.value",
          []
        ).map((item) => item.value);
        return (
          !orderTitle ||
          !selectedBy ||
          !stationKey ||
          !typeId ||
          !startDate ||
          !endDate ||
          !daysOfWeek ||
          !per ||
          !mentions ||
          selectedItems.length === 0
        );
      }
      return false;
    },
    onCheckSchedulingAvailability: ({
      process,
      onCheckSaveDraft,
      checkAvailability,
    }) => (data = {}) => {
      // save draft the order
      const isNew = window.location.href.indexOf("add") !== -1;
      if (isNew) {
        onCheckSaveDraft(process, () => {
          checkAvailability(data, isNew);
        });
      } else {
        checkAvailability(data);
      }
    },
    onFindInventory: ({
      setIsOpenSupplementItemsModal,
      generateParams,
      findInventory,
    }) => () => {
      const data = generateParams();
      findInventory({
        data,
        cb: () => {
          setIsOpenSupplementItemsModal(true);
        },
      });
    },
    onSaveInventory: ({
      generateParams,
      saveInventory,
      setIsOpenSupplementItemsModal,
    }) => (values) => {
      const selected_inventory = map(
        get(values, "selected_inventory", []),
        (value) => {
          value.number = parseInt(value.number || 0);
          return value;
        }
      );
      const data = generateParams({ selected_inventory });
      saveInventory({
        data,
        cb: () => {
          setIsOpenSupplementItemsModal(false);
        },
      });
    },
    onReleaseInventory: ({
      process,
      releaseInventory,
      setIsOpenSupplementItemsModal,
    }) => () => {
      const station_id = get(
        process,
        "data.fields.order_stations_array.value.0.key"
      );
      const process_key = get(process, "data.fields.key.value");
      const data = { station_id, process_key };
      releaseInventory({
        data,
        cb: () => {
          setIsOpenSupplementItemsModal(false);
        },
      });
    },
    checkIsInValidSchedulingCheck: ({ process }) => () => {
      const templateKey = get(process, "data.fields.template_key.value", "");
      const stationKey = get(
        process,
        "data.fields.order_stations_array.value.0.key"
      );
      const orderTitle = get(process, "data.fields.order_title.value");
      // const processKey = get(process, "data.fields.key.value");
      const selectedBy = get(process, "data.fields.selected_by.value");
      let typeId = get(process, "data.fields.type_id.value");
      const startDate = get(process, "data.fields.order_start_date.value");
      const endDate = get(process, "data.fields.order_end_date.value");
      const daysOfWeek = get(process, "data.fields.days_of_week.value");
      const schedulingType = get(process, "data.fields.scheduling_type.value");
      if (templateKey === "contest") typeId = true; // ignore typeId for contest
      if (schedulingType === "targeted") {
        const per = get(process, "data.fields.per.value");
        const mentions = get(process, "data.fields.mentions.value");
        const selectedItems = get(
          process,
          "data.fields.selected_items.value",
          []
        ).map((item) => item.value);
        return (
          !orderTitle ||
          !selectedBy ||
          !stationKey ||
          !typeId ||
          !startDate ||
          !endDate ||
          !daysOfWeek ||
          !schedulingType ||
          !per ||
          !mentions ||
          selectedItems.length === 0
        );
      }
      return (
        !orderTitle ||
        !stationKey ||
        !typeId ||
        !startDate ||
        !endDate ||
        !daysOfWeek ||
        !schedulingType
      );
    },
  })
  // Commented: https://tasks.getventive.com/projects/7DDA9-672
  // lifecycle({
  //   componentDidMount() {
  //     const isNew = window.location.href.indexOf("add") !== -1;
  //     const processKey = get(this.props, "process.data.fields.key.value");
  //     // Ensure dont call check schedule when new order
  //     if (!this.props.checkIsInValidSchedulingCheck() && processKey && !isNew)
  //       this.props.onCheckSchedulingAvailability();
  //   },
  // })
)(ItemAvailability);

export default injectIntl(Enhanced);
export { renderFinalScheduleStatement };
