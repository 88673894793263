import React, { useState, useEffect, forwardRef } from "react";
import { Row, Col, Label, FormGroup } from "reactstrap";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import {
  get,
  includes,
  first,
  difference,
  isEmpty,
  forEach,
  intersection,
  isEqual,
  find,
  map,
  lowerCase,
} from "lodash";
import moment from "moment";
import TextInputFormatted from "components/TextInputFormatted";
import {
  ShadowBox,
  SectionTitle,
  SectionFields,
  Asterisk,
} from "components/Elements";
import Dropdown from "components/Dropdown";
import TextInput from "components/TextInput";
import DaysOfWeek from "components/DaysOfWeek";
import DatePicker from "components/DatePicker";
import Badge from "components/Badge";
import UploadFile from "components/UploadFile";
import FileView from "components/FileView";

function getAutomationWorkFlowOptions({ intl, fulfillmentSettings }) {
  const options = map(fulfillmentSettings, (item) => ({
    label: intl.formatMessage({
      id: `process > promotion > contest > ${lowerCase(item.title)}`,
    }),
    value: item.id,
    data: {
      id: item.id,
      type: item.type,
      title: item.title,
      verification_required: item.verification_required,
    },
  }));
  return [
    {
      label: intl.formatMessage({
        id: `process > promotion > contest > no automation - manual fulfillment`,
      }),
      value: 0,
      data: {
        id: 0,
        type: "no_automation_manual_fulfillment",
        title: "No automation - Manual fulfillment",
      },
    },
    ...options,
  ];
}
const ContestDescription = (props) => {
  const {
    validationState,
    process,
    bem,
    step,
    onValueChanged,
    intl,
    isView,
    contestTypes,
    template,
    fulfillmentSettings,
    dateFormatByServer
  } = props;
  const [fieldRefs, setFieldRefs] = useState(null);
  const [selectedDates, setSelectedDates] = useState([]);
  const automationWorkflowOptions = getAutomationWorkFlowOptions({
    intl,
    fulfillmentSettings,
  });
  const getFieldRefs = () => {
    if (fieldRefs) return fieldRefs;
    const desired_items_scheduling = step.fields.find(
      (f) => f.field === "desired_items_scheduling"
    );
    const order_title = step.fields.find((f) => f.field === "order_title");
    const order_type = step.fields.find((f) => f.field === "order_type");
    const theme = step.fields.find((f) => f.field === "theme");
    const winner_selection = step.fields.find(
      (f) => f.field === "winner_selection"
    );
    const plays_number = step.fields.find((f) => f.field === "plays_number");
    const description = step.fields.find((f) => f.field === "description");

    const order_start_date = step.fields.find(
      (f) => f.field === "order_start_date"
    );
    const order_end_date = step.fields.find(
      (f) => f.field === "order_end_date"
    );
    const days_of_week = step.fields.find((f) => f.field === "days_of_week");
    const excluded_dates = step.fields.find(
      (f) => f.field === "excluded_dates"
    );
    // grand prize fields
    const qualifying_prize = step.fields.find(
      (f) => f.field === "qualifying_prize"
    );
    const prize_execution = step.fields.find(
      (f) => f.field === "prize_execution"
    );
    const grand_prize_execution_date = step.fields.find(
      (f) => f.field === "grand_prize_execution_date"
    );
    const show_daypart = step.fields.find((f) => f.field === "show_daypart");
    const grand_prize_script_before = step.fields.find(
      (f) => f.field === "grand_prize_script_before"
    );
    const grand_prize_script_after = step.fields.find(
      (f) => f.field === "grand_prize_script_after"
    );
    const grand_prize_jock_instructions = step.fields.find(
      (f) => f.field === "grand_prize_jock_instructions"
    );
    const contest_image = step.fields.find((f) => f.field === "contest_image");
    const automation_workflow = step.fields.find(
      (f) => f.field === "automation_workflow_setting"
    );

    if (!process.data.fields.days_of_week) {
      onValueChanged(days_of_week, [2, 3, 4, 5, 6], {
        shouldBlockNavigation: false,
      });
    }
    if (!process.data.fields.theme) {
      onValueChanged(theme, "standard_giveaway", {
        shouldBlockNavigation: false,
      });
    }

    const payload = {
      qualifying_prize,
      prize_execution,
      grand_prize_execution_date,
      show_daypart,
      grand_prize_script_before,
      grand_prize_script_after,
      grand_prize_jock_instructions,

      desired_items_scheduling,
      order_title,
      order_type,
      theme,
      winner_selection,
      description,
      order_start_date,
      order_end_date,
      days_of_week,
      excluded_dates,
      plays_number,
      contest_image,
      automation_workflow,
    };

    setFieldRefs(payload);

    return payload;
  };

  const {
    desired_items_scheduling,
    order_title,
    order_type,
    theme,
    winner_selection,
    description,
    order_start_date,
    order_end_date,
    days_of_week,
    excluded_dates,
    plays_number,

    qualifying_prize,
    prize_execution,
    grand_prize_execution_date,
    show_daypart,
    grand_prize_script_before,
    grand_prize_script_after,
    grand_prize_jock_instructions,
    automation_workflow,
    contest_image,
  } = getFieldRefs();
  const isSelectedDay = (date) => {
    let selectedDays = process.data.fields[days_of_week.field]
      ? process.data.fields[days_of_week.field].value
      : [];
    const day = moment(date).day() + 1;
    return includes(selectedDays, day);
  };
  const resetGrandPrizeFields = () => {
    onValueChanged(qualifying_prize, null);
    onValueChanged(prize_execution, "");
    onValueChanged(grand_prize_execution_date, "");
    onValueChanged(show_daypart, "");
    onValueChanged(grand_prize_script_before, "");
    onValueChanged(grand_prize_script_after, "");
    onValueChanged(grand_prize_jock_instructions, "");
  };
  const getAvailableDates = () => {
    if (
      !process.data.fields[order_start_date.field] ||
      !process.data.fields[order_end_date.field]
    ) {
      return [];
    }

    let startDate = moment(process.data.fields[order_start_date.field].value);
    let endDate = moment(process.data.fields[order_end_date.field].value);

    let dates = [];
    let currentDate = startDate.subtract(1, "days");
    let addDays = function(days) {
      let date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };
    while (currentDate <= endDate) {
      if (isSelectedDay(currentDate)) {
        dates.push(currentDate);
      }

      currentDate = addDays.call(currentDate, 1);
    }

    return dates;
  };

  const _setSelectedDates = () => {
    let selectedDates = [];

    let excludedDates = process.data.fields[excluded_dates.field]
      ? process.data.fields[excluded_dates.field].value
      : [];

    excludedDates = excludedDates.map((date) =>
      moment(date).format("YYYY-MM-DD")
    );

    let availableDates = getAvailableDates();

    forEach(availableDates, (date) => {
      if (!includes(excludedDates, moment(date).format("YYYY-MM-DD"))) {
        selectedDates.push(date);
      }
    });

    setSelectedDates(selectedDates);

    let _excludedDates = intersection(
      excludedDates,
      availableDates.map((date) => moment(date).format("YYYY-MM-DD"))
    );

    if (!isEqual(excludedDates, _excludedDates)) {
      onValueChanged(excluded_dates, _excludedDates);
    }
  };

  useEffect(() => {
    _setSelectedDates();
  }, [process]);

  const ExcludeDatesButton = forwardRef(({ value, onClick }, ref) => (
    <div className="exclude-dates-btn" onClick={onClick} ref={ref}>
      <FormattedMessage id="process > promotion > liner > exclude dates" />
    </div>
  ));

  const renderExcludedDates = () => {
    const excludedDates = process.data.fields[excluded_dates.field]
      ? process.data.fields[excluded_dates.field].value
      : [];

    return excludedDates.map((date) => {
      return (
        <Badge
          key={date}
          text={moment(date).format("MMM D")}
          onClick={() => {
            onValueChanged(excluded_dates, difference(excludedDates, [date]));
          }}
        />
      );
    });
  };
  const numberOfPlaysFor54321Theme = get(
    template,
    "numberOfPlaysFor54321Theme"
  );
  const orderType = get(process, `data.fields.${order_type.field}.value`, "");
  const selectedTheme =
    find(
      theme.props.options,
      (item) =>
        item.value === get(process, `data.fields.${theme.field}.value`, "")
    ) || "";
  const winnerSelection = get(
    process,
    `data.fields.${winner_selection.field}.value`,
    ""
  );

  const selectedOrderType =
    find(order_type.props.options, (i) => i.value === orderType) || "";
  const selectedWinnerSelection =
    find(winner_selection.props.options, (i) => i.value === winnerSelection) ||
    "";
  const isEnableWinnerSelection = includes(
    ["online_registration", "text_to_win"],
    orderType
  );
  const type_id = step.fields.find((f) => f.field === "type_id");
  const contestTypesOptions = contestTypes.map((contestType) => ({
    label: `${contestType.type} - ${contestType.name}`,
    value: contestType.id,
  }));
  const isShowOtherFields =
    selectedTheme && selectedTheme.value !== "qualifying_with_grand_prize";
  useEffect(() => {
    const typeId = get(process, "data.fields.type_id.value");
    const contestType = first(contestTypesOptions);
    // If there is only one item type defined. It should automatically be selected in the dropdown
    if (
      contestTypesOptions &&
      contestTypesOptions.length === 1 &&
      typeId !== get(contestType, "value")
    ) {
      onValueChanged(type_id, contestType.value, {
        shouldBlockNavigation: false,
      });
    }
  }, [contestTypesOptions]);
  useEffect(() => {
    // While creating a new contest, there is should have default type for contest type
    if (!selectedOrderType) {
      onValueChanged(order_type, "call_in_contest", {
        shouldBlockNavigation: false,
      });
      onValueChanged(winner_selection, "selected_on_air", {
        shouldBlockNavigation: false,
      });
    }
  }, []);

  const categoryTypeValue = get(process, "data.fields.type_id.value");
  const categoryType = find(
    contestTypesOptions,
    (item) => item.value === categoryTypeValue
  );
  const automationWorkflow = get(
    process,
    "data.fields.automation_workflow_setting.value"
  );
  const selectedAutomationWorkflow = find(
    automationWorkflowOptions,
    (item) => item.value === get(automationWorkflow, "id")
  );
  const is54321Theme = selectedTheme && selectedTheme.value === "5_4_3_2_1";
  const contestImage = get(process, "data.fields.contest_image.value", "");
  return (
    <ShadowBox className={bem.e("box-contest-details")}>
      <SectionTitle className={bem.e("contest-details-section-title")}>
        <FormattedMessage id="process > promotion > contest > title contest description" />
      </SectionTitle>
      <SectionFields
        className={classnames(bem.e("section-fields"), {
          [bem.e("contest-details-section-fields-view")]: isView,
          [bem.e("contest-details-section-fields")]: !isView,
        })}
      >
        {/* render view */}
        {isView ? (
          <React.Fragment>
            <Row>
              <Col xs={6} className={bem.e("reset-col")}>
                <FormGroup>
                  <Label>{order_title.title}</Label>
                  <span className={bem.e("order-view-value")}>
                    {process.data.fields[order_title.field]
                      ? process.data.fields[order_title.field].value
                      : intl.formatMessage({ id: "process > none" })}
                  </span>
                </FormGroup>
              </Col>
              <Col xs={6} className={bem.e("reset-col")}>
                <FormGroup>
                  <Label>{type_id.title}</Label>
                  <span className={bem.e("order-view-value")}>
                    {categoryType
                      ? categoryType.label
                      : intl.formatMessage({ id: "process > none" })}
                  </span>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={6} className={bem.e("reset-col")}>
                <FormGroup>
                  <Label>{order_type.title}</Label>
                  <span className={bem.e("order-view-value")}>
                    {selectedOrderType
                      ? get(selectedOrderType, "label", "")
                      : intl.formatMessage({ id: "process > none" })}
                  </span>
                </FormGroup>
              </Col>
              <Col xs={6} className={bem.e("reset-col")}>
                <FormGroup>
                  <Label>{theme.title}</Label>
                  <span className={bem.e("order-view-value")}>
                    {selectedTheme
                      ? get(selectedTheme, "label", "")
                      : intl.formatMessage({ id: "process > none" })}
                  </span>
                </FormGroup>
              </Col>
            </Row>
            {isShowOtherFields && (
              <React.Fragment>
                <Row>
                  <Col xs={6} className={bem.e("reset-col")}>
                    <FormGroup>
                      <Label>{winner_selection.title}</Label>
                      <span className={bem.e("order-view-value")}>
                        {winnerSelection
                          ? winnerSelection === "selected_on_air"
                            ? intl.formatMessage({
                                id:
                                  "process > promotion > contest > selected on air",
                              })
                            : get(selectedWinnerSelection, "label", "")
                          : intl.formatMessage({ id: "process > none" })}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col xs={6} className={bem.e("reset-col")}>
                    <Row>
                      <Col xs={12} className={bem.e("reset-col")}>
                        <FormGroup>
                          <Label>{plays_number.title}</Label>
                          <span className={bem.e("order-view-value")}>
                            {process.data.fields[plays_number.field]
                              ? process.data.fields[plays_number.field].value
                              : intl.formatMessage({ id: "process > none" })}
                          </span>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </React.Fragment>
            )}
            <Row>
              <Col xs={12} className={bem.e("reset-col")}>
                <FormGroup>
                  <Label>{description.title}</Label>
                  <span className={bem.e("order-view-value")}>
                    {process.data.fields[description.field]
                      ? process.data.fields[description.field].value
                      : intl.formatMessage({ id: "process > none" })}
                  </span>
                </FormGroup>
              </Col>
            </Row>
            {isShowOtherFields && (
              <React.Fragment>
                <Row>
                  <Col xs={6} className={bem.e("reset-col")}>
                    <FormGroup>
                      <Label>{order_start_date.title}</Label>
                      <span className={bem.e("order-view-value")}>
                        {process.data.fields[order_start_date.field]
                          ? moment(
                              process.data.fields[order_start_date.field].value
                            ).format(dateFormatByServer)
                          : intl.formatMessage({ id: "process > none" })}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col xs={6} className={bem.e("reset-col")}>
                    <FormGroup>
                      <Label>{order_end_date.title}</Label>
                      <span className={bem.e("order-view-value")}>
                        {process.data.fields[order_end_date.field]
                          ? moment(
                              process.data.fields[order_end_date.field].value
                            ).format(dateFormatByServer)
                          : intl.formatMessage({ id: "process > none" })}
                      </span>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} className={bem.e("reset-col")}>
                    <FormGroup>
                      <Label>
                        <FormattedMessage id="process > days of the week" />
                      </Label>
                      <span className={bem.e("order-view-value")}>
                        {process.data.fields[days_of_week.field] ? (
                          <DaysOfWeek
                            isView={true}
                            value={get(
                              process,
                              "data.fields.days_of_week.value",
                              []
                            )}
                          />
                        ) : (
                          intl.formatMessage({ id: "process > none" })
                        )}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col xs={6} className={bem.e("reset-col")}>
                    <FormGroup>
                      <Label>{excluded_dates.title}</Label>
                      <span
                        className={classnames(
                          bem.e("order-view-value"),
                          bem.e("order-view-value-excluded-dates")
                        )}
                      >
                        {process.data.fields[excluded_dates.field]
                          ? process.data.fields[excluded_dates.field].value
                              .map((date) => moment(date).format("MMM D"))
                              .join(", ")
                          : intl.formatMessage({ id: "process > none" })}
                      </span>
                    </FormGroup>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Row>
              <Col xs={6} className={bem.e("reset-col")}>
                <FormGroup>
                  <TextInput
                    label={
                      <span>
                        {order_title.title}
                        {order_title.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    required
                    name="order_title"
                    placeholder={intl.formatMessage({
                      id: "process > promotion > contest > enter contest name",
                    })}
                    value={
                      process.data.fields[order_title.field]
                        ? process.data.fields[order_title.field].value
                        : ""
                    }
                    onChange={(event) => {
                      onValueChanged(
                        order_title,
                        get(event, "target.value", "")
                      );
                    }}
                    error={get(validationState, "order_title.validation_error")}
                  />
                </FormGroup>
              </Col>
              <Col xs={6} className={bem.e("reset-col")}>
                <FormGroup className={bem.e("reset-form-group")}>
                  <Dropdown
                    label={
                      <span>
                        {type_id.title}
                        {type_id.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    name="type_id"
                    placeholder={intl.formatMessage({
                      id: "process > promotion > liner > select item type",
                    })}
                    onChange={(selectedOption) => {
                      onValueChanged(type_id, selectedOption.value);
                    }}
                    value={categoryType || ""}
                    options={contestTypesOptions}
                    error={get(validationState, "type_id.validation_error")}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={6} className={bem.e("reset-col")}>
                <FormGroup>
                  <Dropdown
                    label={
                      <span>
                        {order_type.title}
                        {order_type.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    name="order_type"
                    placeholder={intl.formatMessage({
                      id: "process > promotion > contest > select",
                    })}
                    onChange={(selectedOption) => {
                      onValueChanged(order_type, selectedOption.value);
                      const winnerSelection =
                        selectedOption.value === "call_in_contest"
                          ? "selected_on_air"
                          : "";
                      onValueChanged(winner_selection, winnerSelection);
                    }}
                    value={selectedOrderType}
                    {...order_type.props}
                    error={get(validationState, "order_type.validation_error")}
                  />
                </FormGroup>
              </Col>
              <Col xs={6} className={bem.e("reset-col")}>
                <FormGroup>
                  <Dropdown
                    label={
                      <span>
                        {theme.title}
                        {theme.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    name="theme"
                    placeholder={intl.formatMessage({
                      id: "process > promotion > contest > select",
                    })}
                    onChange={(selectedOption) => {
                      if (selectedOption.value === "5_4_3_2_1") {
                        onValueChanged(
                          desired_items_scheduling,
                          numberOfPlaysFor54321Theme
                        );
                        onValueChanged(
                          plays_number,
                          numberOfPlaysFor54321Theme
                        );
                      }
                      if (
                        selectedTheme.value === "qualifying_with_grand_prize"
                      ) {
                        const winnerSelection = "selected_on_air";
                        onValueChanged(winner_selection, winnerSelection);
                      } else {
                        resetGrandPrizeFields();
                      }
                      onValueChanged(theme, selectedOption.value);
                    }}
                    value={selectedTheme}
                    {...theme.props}
                    error={get(validationState, "theme.validation_error")}
                  />
                </FormGroup>
              </Col>
            </Row>
            {isShowOtherFields && (
              <Row>
                <Col xs={6} className={bem.e("reset-col")}>
                  <FormGroup>
                    {winnerSelection === "selected_on_air" ? (
                      <div className={bem.e("winner-on-air")}>
                        <Label>{winner_selection.title}</Label>
                        <span className={bem.e("order-view-value")}>
                          {intl.formatMessage({
                            id:
                              "process > promotion > contest > selected on air",
                          })}
                        </span>
                      </div>
                    ) : (
                      <Dropdown
                        label={
                          <span>
                            {winner_selection.title}
                            {winner_selection.mandatory &&
                              isEnableWinnerSelection && <Asterisk>*</Asterisk>}
                          </span>
                        }
                        name="winner_selection"
                        placeholder={intl.formatMessage({
                          id: "process > promotion > contest > select",
                        })}
                        onChange={(selectedOption) => {
                          onValueChanged(
                            winner_selection,
                            selectedOption.value
                          );
                        }}
                        value={selectedWinnerSelection}
                        {...winner_selection.props}
                        error={get(
                          validationState,
                          "winner_selection.validation_error"
                        )}
                      />
                    )}
                  </FormGroup>
                </Col>
                <Col xs={6} className={bem.e("reset-col")}>
                  <Row>
                    <Col xs={12} className={bem.e("reset-col")}>
                      <FormGroup>
                        <TextInputFormatted
                          label={
                            <span>
                              {plays_number.title}
                              {plays_number.mandatory && !is54321Theme && (
                                <Asterisk>*</Asterisk>
                              )}
                            </span>
                          }
                          className={classnames({
                            [bem.e("static-input")]: is54321Theme,
                          })}
                          name={`plays_number`}
                          value={
                            process.data.fields[plays_number.field]
                              ? process.data.fields[plays_number.field].value
                              : ""
                          }
                          onChange={(value) => {
                            onValueChanged(desired_items_scheduling, value);
                            onValueChanged(plays_number, value);
                          }}
                          error={get(
                            validationState,
                            "plays_number.validation_error"
                          )}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
            {isShowOtherFields && (
              <React.Fragment>
                <Row>
                  <Col xs={4} className={bem.e("reset-col")}>
                    <FormGroup>
                      <TextInput
                        label={
                          <span>
                            {order_start_date.title}
                            {order_start_date.mandatory && (
                              <Asterisk>*</Asterisk>
                            )}
                          </span>
                        }
                        type="date"
                        name="order_start_date"
                        placeholder={intl.formatMessage({
                          id: "process > promotion > contest > select date",
                        })}
                        value={
                          process.data.fields[order_start_date.field]
                            ? process.data.fields[order_start_date.field].value
                            : ""
                        }
                        onChange={(value) => {
                          onValueChanged(order_start_date, value);
                          // clear order end date if order start date if after
                          if (
                            value &&
                            get(
                              process,
                              `data.fields.${order_end_date.field}.value`
                            )
                          ) {
                            const isAfterEndDate = moment(value).isAfter(
                              process.data.fields[order_end_date.field].value
                            );
                            if (isAfterEndDate) {
                              onValueChanged(order_end_date, "");
                            }
                          }
                        }}
                        error={get(
                          validationState,
                          "order_start_date.validation_error"
                        )}
                        allowShowIcon
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={4} className={bem.e("reset-col")}>
                    <FormGroup>
                      <TextInput
                        label={
                          <span>
                            {order_end_date.title}
                            {order_end_date.mandatory && <Asterisk>*</Asterisk>}
                          </span>
                        }
                        type="date"
                        name="order_end_date"
                        placeholder={intl.formatMessage({
                          id: "process > promotion > contest > select date",
                        })}
                        min={get(
                          process,
                          "data.fields.order_start_date.value",
                          null
                        )}
                        value={
                          process.data.fields[order_end_date.field]
                            ? process.data.fields[order_end_date.field].value
                            : ""
                        }
                        onChange={(value) => {
                          onValueChanged(order_end_date, value);
                        }}
                        error={get(
                          validationState,
                          "order_end_date.validation_error"
                        )}
                        allowShowIcon
                      />
                      {process.data.fields[order_start_date.field] &&
                        process.data.fields[order_start_date.field].value &&
                        process.data.fields[order_end_date.field] &&
                        process.data.fields[order_end_date.field].value && (
                          <div className="cr-textinput exclude-date-picker">
                            <DatePicker
                              field={
                                process.data.fields[order_start_date.field]
                                  ? process.data.fields[order_start_date.field]
                                  : null
                              }
                              onChange={(date) => {
                                let excludedDates = process.data.fields[
                                  excluded_dates.field
                                ]
                                  ? process.data.fields[excluded_dates.field]
                                      .value
                                  : [];

                                if (!includes(excludedDates, date)) {
                                  excludedDates.push(date);
                                } else {
                                  excludedDates = difference(excludedDates, [
                                    date,
                                  ]);
                                }

                                onValueChanged(excluded_dates, excludedDates);
                              }}
                              name="selected_dates"
                              minDate={moment(
                                process.data.fields[order_start_date.field]
                                  .value
                              ).toDate()}
                              maxDate={moment(
                                process.data.fields[order_end_date.field].value
                              ).toDate()}
                              filterDate={isSelectedDay}
                              highlightDates={selectedDates}
                              selected={null}
                              customInput={<ExcludeDatesButton />}
                              shouldCloseOnSelect={false}
                            />
                          </div>
                        )}
                    </FormGroup>
                  </Col>
                  <Col xs={4} className={bem.e("reset-col")}>
                    <FormGroup>
                      <DaysOfWeek
                        value={get(
                          process,
                          "data.fields.days_of_week.value",
                          []
                        )}
                        label={
                          <span>
                            {days_of_week.title}
                            {days_of_week.mandatory && <Asterisk>*</Asterisk>}
                          </span>
                        }
                        error={get(
                          validationState,
                          "days_of_week.validation_error"
                        )}
                        onChange={(days) => {
                          onValueChanged(days_of_week, days);
                        }}
                        checkStyle={{
                          color: "#fff",
                          width: "11",
                          height: "11",
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {process.data.fields[excluded_dates.field] &&
                  !isEmpty(process.data.fields[excluded_dates.field].value) && (
                    <Row className={bem.e("badges")}>
                      <Col className={bem.e("reset-col")}>
                        <FormGroup>
                          <span className={bem.e("badge-title")}>
                            {excluded_dates.title}
                            {excluded_dates.mandatory && <Asterisk>*</Asterisk>}
                          </span>
                          <div className={bem.e("badges-container")}>
                            {renderExcludedDates()}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  )}
              </React.Fragment>
            )}
            <Row>
              <Col className={bem.e("reset-col")}>
                <FormGroup>
                  <TextInput
                    label={
                      <span>
                        {description.title}
                        {description.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    type="textarea"
                    placeholder={intl.formatMessage({
                      id: "process > promotion > contest > enter description",
                    })}
                    value={
                      process.data.fields[description.field]
                        ? process.data.fields[description.field].value
                        : ""
                    }
                    onChange={({ target }) => {
                      onValueChanged(description, target.value);
                    }}
                    error={get(validationState, "description.validation_error")}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={6} className={bem.e("reset-col")}>
                <FormGroup
                  className={classnames(
                    bem.e("reset-form-group"),
                    bem.e("contest-image")
                  )}
                >
                  <Label>{contest_image.title}</Label>
                  {contestImage && (
                    <FileView
                      background
                      file={contestImage}
                      onDeleted={() => {
                        onValueChanged(contest_image, "");
                      }}
                      onUploaded={(value) => {
                        onValueChanged(
                          contest_image,
                          value ? first(value) : ""
                        );
                      }}
                      onRenameFile={(value) => {
                        onValueChanged(contest_image, value);
                      }}
                      actions={["trash", "view", "download", "rename"]}
                    />
                  )}
                  {!contestImage && (
                    <React.Fragment>
                      <UploadFile
                        url={""}
                        attachText={props.intl.formatMessage({
                          id: "process > attach file",
                        })}
                        mimes={["image/jpeg", "image/png"]}
                        fieldName={"contest_image"}
                        actions={[]}
                        onDeleted={() => {
                          onValueChanged(contest_image, "");
                        }}
                        onUploaded={(value) => {
                          onValueChanged(contest_image, value);
                        }}
                        isHideFilesDragArea={true}
                        showRemoveIcon={false}
                        maxSize={300000} //300kb
                      />
                      {validationState[contest_image.field] && (
                        <div className="alert-danger">
                          {
                            validationState[contest_image.field]
                              .validation_error
                          }
                        </div>
                      )}
                    </React.Fragment>
                  )}
                </FormGroup>
              </Col>
              <Col xs={6} className={bem.e("reset-col")}>
                <div className={bem.e("contest-image-help-text")}>
                  <FormattedMessage id="process > promotion > contest > contest image max size" />
                  <FormattedMessage id="process > promotion > contest > contest image suggest" />
                </div>
              </Col>
            </Row>
          </React.Fragment>
        )}
      </SectionFields>
      <div className={bem.e("section-fulfillment")}>
        <Row>
          <Col xs={8} className={bem.e("reset-col")}>
            <FormGroup className={bem.e("reset-form-group")}>
              <div className={bem.e("fulfillment-automation-title")}>
                <FormattedMessage id="process > promotion > contest > winner fulfillment automation" />
              </div>
              <Dropdown
                label={
                  <span>
                    {automation_workflow.title}
                    {automation_workflow.mandatory && <Asterisk>*</Asterisk>}
                  </span>
                }
                name="automation_workflow_setting"
                placeholder={intl.formatMessage({
                  id: "process > promotion > liner > select",
                })}
                onChange={(selectedOption) => {
                  onValueChanged(
                    automation_workflow,
                    selectedOption ? selectedOption.data : ""
                  );
                }}
                value={selectedAutomationWorkflow || ""}
                options={automationWorkflowOptions}
                error={get(
                  validationState,
                  "automation_workflow_setting.validation_error"
                )}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
    </ShadowBox>
  );
};

export default ContestDescription;
