import * as React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { get, lowerCase, find } from "lodash";
import TextInput from "components/TextInput";
import Dropdown from "components/Dropdown";
import StaffingAvailability from "components/StaffingAvailability";
import { FormattedMessage } from "react-intl";
import { FaCheck } from "react-icons/lib/fa";
import {
  Row,
  Col,
  Form,
  Button,
  Label,
  Modal,
  ModalBody,
  ButtonGroup,
} from "reactstrap";
import NumberFormat from "react-number-format";
import SelectStations from "../SelectStations";
import history from "components/History";
import ToastManager from "components/ToastManager";
import { autoSuggestClients } from "store/actions/process";
import {
  ShadowBox,
  Asterisk,
  SectionTitle,
  SectionFields,
  DivView,
} from "components/Elements";
import OrderDetailsDuplicate from "components/OrderDetailsDuplicate";
import OrderDetailsIncompleteContract from "components/OrderDetailsIncompleteContract";
import PrivilegedComponent from "components/PrivilegedComponent";
import { PRIVILEGES } from "utils/constants";
import { PhoneNumberFormat, RotationFormatSuffix } from "utils/config";
// import UploadFile from "components/UploadFile/index";
import ConfirmationModal from "../ConfirmationModal/view";
import timeOptions from "assets/data/time-options.json";
import Checkbox from "components/Checkbox";
import TextInputFormatted from "components/TextInputFormatted";

class PromotionDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      contracts: [],
      isOpenDialog: false,
      isOpenDialogDelete: false,
      isOpenDialogSubmitted: false,
      isOpenEventSchedule: true,
      isOpenEventDetails: false,
      isOpenEventLocation: false,
      isOpenContestSchedule: false,
      isOpenLinerSchedule: false,
    };
  }

  static propTypes = {
    mode: PropTypes.string,
  };

  static defaultProps = {
    mode: "edit",
  };

  componentDidMount() {
    this._isMounted = true;
    autoSuggestClients({
      q: "",
      cb: (items) => {
        if (this._isMounted) {
          this.setState({ customers: items, fieldRefs: false });
        }
      },
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  onCheckBoxChange = (item, process, template, user, checked) => {
    this.props.checkboxChanged(item, process, template, user, {
      target: { checked },
    });
  };

  switchToSpot(key) {
    const { intl } = this.props;
    if (key === "new") {
      ToastManager.show({
        title: intl.formatMessage("toast > title not saved"),
        message: intl.formatMessage(
          "toast > message please save the order before switching to another spot"
        ),
        level: "error",
      });
      return;
    }
    let template = this.props.template;
    history.push("/processes/edit/" + template.key + "/" + key);
  }

  getFieldRefs() {
    if (this.state.fieldRefs) return this.state.fieldRefs;
    let { process, template, user, step } = this.props;

    step = template.steps.promotion_event_details;
    let start_date = step.fields.find((f) => f.field === "start_date");
    let recurring = step.fields.find((f) => f.field === "recurring");
    let repeats = step.fields.find((f) => f.field === "repeats");
    // let end_date = step.fields.find(f => f.field === "end_date");
    let start_time = step.fields.find((f) => f.field === "start_time");
    let end_time = step.fields.find((f) => f.field === "end_time");
    let order_stations = step.fields.find((f) => f.field === "order_stations");
    let event_name = step.fields.find((f) => f.field === "event_name");
    let event_type = step.fields.find((f) => f.field === "event_type");
    let event_description = step.fields.find(
      (f) => f.field === "event_description"
    );
    let jock = step.fields.find((f) => f.field === "jock");
    let jock_instructions = step.fields.find(
      (f) => f.field === "jock_instructions"
    );

    let on_premise_copy = step.fields.find(
      (f) => f.field === "on_premise_copy"
    );
    let jock_notes = step.fields.find((f) => f.field === "notes");
    let venue_name = step.fields.find((f) => f.field === "venue_name");
    let location_information = step.fields.find(
      (f) => f.field === "location_information"
    );
    let contact = step.fields.find((f) => f.field === "contact");
    let phone = step.fields.find((f) => f.field === "phone");
    let staff_availability = step.fields.find(
      (f) => f.field === "staff_availability"
    );
    let payload = {
      start_date,
      recurring,
      repeats,
      // end_date,
      start_time,
      end_time,
      order_stations,
      event_name,
      event_type,
      event_description,
      jock,
      jock_instructions,
      jock_notes,
      on_premise_copy,
      venue_name,
      location_information,
      contact,
      phone,
      staff_availability,
    };
    this.setState({ fieldRefs: payload });
    //debugger
    console.log("recomputed");
    return payload;
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.stationsWithoutSpots &&
      nextProps.stationsWithoutSpots !== this.props.stationsWithoutSpots
    ) {
      this.setState({
        isOpenDialog: true,
        stationsWithoutSpots: nextProps.stationsWithoutSpots,
      });
    }
    if (JSON.stringify(nextProps.info) !== JSON.stringify(this.props.info)) {
      this.setState({ fieldRefs: false });
      return;
    }
    for (
      let i = 0;
      i < Object.keys(nextProps.process.data.fields).length;
      i++
    ) {
      let key = Object.keys(nextProps.process.data.fields)[i];
      let changed = false;
      if (
        !nextProps.process.data.fields[key] &&
        !this.props.process.data.fields[key]
      )
        continue;
      else if (
        !nextProps.process.data.fields[key] &&
        this.props.process.data.fields[key]
      )
        changed = true;
      else if (
        nextProps.process.data.fields[key] &&
        !this.props.process.data.fields[key]
      )
        changed = true;
      else if (
        !nextProps.process.data.fields[key].value &&
        !this.props.process.data.fields[key].value
      )
        continue;
      else if (
        !nextProps.process.data.fields[key].value &&
        this.props.process.data.fields[key].value
      )
        changed = true;
      else if (
        nextProps.process.data.fields[key].value &&
        !this.props.process.data.fields[key].value
      )
        changed = true;
      else if (
        nextProps.process.data.fields[key].value.toString() !==
        this.props.process.data.fields[key].value.toString()
      )
        changed = true;
      if (changed) {
        console.log("changed: " + key);
        if (
          [
            "client_id",
            "contract_stations",
            "order_stations",
            "order_sold",
            "spots",
            "key",
          ].indexOf(key) > -1
        ) {
          this.setState({ fieldRefs: false });
          // refresh prizes list when changing contest stations.
          if (key === "order_stations") {
            this.props.forgetPrizes({
              keys: [
                "filter_grand_prizes_list",
                "filter_qualifying_prizes_list",
              ],
            });
          }
          break;
        }
      }
    }
    // this.setState({fieldRefs:false})

    // open section when having any validate
    if (this.props.validationState !== nextProps.validationState) {
      let sections = [
        "event_schedule",
        "event_details",
        "event_location",
        "staff",
      ];
      sections.forEach((key, index) => {
        let fields = [];
        let errors = [];
        fields = this.renderFieldsSection(key);
        errors = fields.filter((item) =>
          get(nextProps.validationState, `${item}.validation_error`)
        );
        if (errors.length > 0) {
          if (key === "event_schedule" && !this.state.isOpenEventSchedule) {
            this.setState({
              isOpenEventSchedule: true,
            });
          } else if (
            key === "event_details" &&
            !this.state.isOpenEventDetails
          ) {
            this.setState({
              isOpenEventDetails: true,
            });
          } else if (
            key === "event_location" &&
            !this.state.isOpenEventLocation
          ) {
            this.setState({
              isOpenEventLocation: true,
            });
          } else if (key === "staff" && !this.state.isOpenStaff) {
            this.setState({
              isOpenStaff: true,
            });
          }
        }
      });
    }
  }

  renderFieldsSection = (key) => {
    let fields = [];
    switch (key) {
      case "event_schedule":
        fields = [
          "start_date",
          "recurring",
          "repeats",
          // "end_date",
          "start_time",
          "end_time",
          "order_stations",
        ];
        break;
      case "event_details":
        fields = [
          "event_name",
          "event_type",
          "event_description",
          "jock",
          "jock_instructions",
          "jock_notes",
          "on_premise_copy",
        ];
        break;
      case "event_location":
        fields = ["venue_name", "location_information", "contact", "phone"];
        break;
      case "staff":
        fields = ["staff_availability"];
        break;
      default:
        break;
    }
    return fields;
  };

  isCheckFieldRequired = (key, fields) => {
    const { process } = this.props;

    let required = [];
    if (key === "liner_schedule") {
      const { liners, active_liner } = this.getFieldRefs();
      const linersArray = process.data.fields[liners.field]
        ? process.data.fields[liners.field].value
        : [{}];
      const activelinerIndex = process.data.fields[active_liner.field]
        ? process.data.fields[active_liner.field].value
        : 0;
      const activeliner = linersArray[activelinerIndex];
      required = fields.filter((key, index) => {
        if (key === "liner_days_of_week" || key === "order_stations") {
          return get(activeliner, `${key}.length`) === 0;
        } else {
          return !get(activeliner, `${key}`);
        }
      });
    } else {
      required = fields.filter((key, index) => {
        if (key === "contract_stations" || key === "order_stations") {
          return get(process, `data.fields.${key}.value.length`) === 0;
        } else {
          return !get(process, `data.fields.${key}.value`);
        }
      });
    }
    return required;
  };

  isCheckAnyFieldHaveValue = (key, fields) => {
    const { process } = this.props;
    let status = false;
    if (key === "liner_schedule") {
      const { liners, active_liner } = this.getFieldRefs();
      const linersArray = process.data.fields[liners.field]
        ? process.data.fields[liners.field].value
        : [{}];
      const activelinerIndex = process.data.fields[active_liner.field]
        ? process.data.fields[active_liner.field].value
        : 0;
      const activeliner = linersArray[activelinerIndex];
      fields.forEach((key, index) => {
        let value = null;
        if (key === "liner_days_of_week" || key === "order_stations") {
          value = get(activeliner, `${key}.length`);
        } else {
          value = get(activeliner, `${key}`);
        }
        if (value) {
          status = true;
        }
      });
    } else {
      fields.forEach((key) => {
        let value = null;
        if (key === "contract_stations" || key === "order_stations") {
          value = get(process, `data.fields.${key}.value.length`);
        } else {
          value = get(process, `data.fields.${key}.value`);
        }
        if (value) {
          status = true;
        }
      });
    }
    return status;
  };

  isCompletedSection = (key) => {
    const { step } = this.props;
    let fields = this.renderFieldsSection(key);
    let fieldsRequired = fields.filter((key, index) => {
      const fieldRef = step.fields.find((f) => f.field === key);
      return get(fieldRef, "mandatory");
    });
    // section don't have any fields required
    if (fieldsRequired.length === 0) {
      return this.isCheckAnyFieldHaveValue(key, fields);
    } else {
      // check section with fields require
      const required = this.isCheckFieldRequired(key, fieldsRequired);
      return required.length === 0;
    }
  };

  renderForm() {
    let {
      process,
      template,
      user,
      step,
      valueChanged,
      validationState,
      multipleStationSelectionChanged,
    } = this.props;

    let {
      start_date,
      recurring,
      repeats,
      end_date,
      start_time,
      end_time,
      order_stations,
      event_name,
      event_type,
      event_description,
      jock,
      jock_instructions,
      jock_notes,
      on_premise_copy,
      venue_name,
      location_information,
      contact,
      phone,
      staff_availability,
    } = this.getFieldRefs();
    let bindedValueChanged = valueChanged.bind(this);

    return (
      <div className="text-left">
        <Form>
          <ShadowBox className="promotion-shadow-box">
            <SectionTitle
              className="promotion-section-title"
              onClick={() =>
                this.setState({
                  isOpenEventSchedule: !this.state.isOpenEventSchedule,
                })
              }
            >
              <FormattedMessage id="process > title promotion event schedule" />
              {this.isCompletedSection("event_schedule") && (
                <FaCheck color="#6F96D1" size={30} />
              )}
            </SectionTitle>
            <SectionFields
              className={!this.state.isOpenEventSchedule && "hidden"}
            >
              <Row>
                <Col>
                  <TextInput
                    label={
                      <span>
                        {start_date.title}
                        {start_date.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    type="date"
                    min={moment().format("YYYY-MM-DD")}
                    value={
                      process.data.fields[start_date.field]
                        ? process.data.fields[start_date.field].value
                        : ""
                    }
                    onChange={valueChanged.bind(
                      this,
                      start_date,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[start_date.field] &&
                      validationState[start_date.field].validation_error}
                  </div>
                </Col>
                <Col>
                  <Checkbox
                    checked={
                      process.data.fields[recurring.field] &&
                      process.data.fields[recurring.field].value
                        ? true
                        : false
                    }
                    onChange={(checked) => {
                      this.onCheckBoxChange(
                        recurring,
                        process,
                        template,
                        user,
                        checked
                      );
                    }}
                    text={recurring.title}
                    style={{ marginTop: 45 }}
                  />
                </Col>
                {process.data.fields[recurring.field] &&
                  process.data.fields[recurring.field].value && (
                    <Col>
                      <TextInputFormatted
                        thousandSeparator={true}
                        label={
                          <span>
                            {repeats.title}
                            {repeats.mandatory && <Asterisk>*</Asterisk>}
                          </span>
                        }
                        type="number"
                        value={
                          process.data.fields[repeats.field]
                            ? process.data.fields[repeats.field].value
                            : ""
                        }
                        onChange={(value) => {
                          valueChanged(repeats, process, template, user, {
                            value,
                          });
                        }}
                      />
                    </Col>
                  )}
                {/* <TextInput
                    label={
                      <span>
                        {end_date.title}
                        {end_date.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    type="date"
                    min={moment().format("YYYY-MM-DD")}
                    value={
                      process.data.fields[end_date.field]
                        ? process.data.fields[end_date.field].value
                        : ""
                    }
                    onChange={valueChanged.bind(
                      this,
                      end_date,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[end_date.field] &&
                      validationState[end_date.field].validation_error}
                  </div>                   */}
              </Row>
              <Row>
                <Col>
                  <Dropdown
                    isClearable={true}
                    name="start_time"
                    {...start_time.props}
                    label={
                      <span>
                        {start_time.title}
                        {start_time.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    value={
                      process.data.fields[start_time.field]
                        ? {
                            label: get(
                              find(
                                timeOptions,
                                (t) =>
                                  t.value ===
                                  process.data.fields[start_time.field].value
                              ),
                              "label",
                              ""
                            ),
                            value: process.data.fields[start_time.field].value,
                          }
                        : ""
                    }
                    onChange={valueChanged.bind(
                      this,
                      start_time,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[start_time.field] &&
                      validationState[start_time.field].validation_error}
                  </div>
                </Col>
                <Col>
                  <Dropdown
                    isClearable={true}
                    name="end_time"
                    {...end_time.props}
                    label={
                      <span>
                        {end_time.title}
                        {end_time.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    value={
                      process.data.fields[end_time.field]
                        ? {
                            label: get(
                              find(
                                timeOptions,
                                (t) =>
                                  t.value ===
                                  process.data.fields[end_time.field].value
                              ),
                              "label",
                              ""
                            ),
                            value: process.data.fields[end_time.field].value,
                          }
                        : ""
                    }
                    onChange={valueChanged.bind(
                      this,
                      end_time,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[end_time.field] &&
                      validationState[end_time.field].validation_error}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs="12" lg="12">
                  <SelectStations
                    contract={true}
                    item={order_stations}
                    // disabled={contractSaved}
                    checkboxes={true}
                    {...order_stations.props}
                    validationState={validationState}
                    checked={process.data.fields[order_stations.field]}
                    template={template}
                    isFilterByServerConfig={true}
                    onChange={(value) => {
                      multipleStationSelectionChanged(
                        order_stations,
                        process,
                        template,
                        user,
                        value
                      );
                    }}
                  />
                </Col>
              </Row>
            </SectionFields>
          </ShadowBox>
          <ShadowBox className="promotion-shadow-box">
            <SectionTitle
              className="promotion-section-title"
              onClick={() =>
                this.setState({
                  isOpenEventDetails: !this.state.isOpenEventDetails,
                })
              }
            >
              <FormattedMessage id="process > title promotion event details" />
              {this.isCompletedSection("event_details") && (
                <FaCheck color="#6F96D1" size={30} />
              )}
            </SectionTitle>
            <SectionFields
              className={!this.state.isOpenEventDetails && "hidden"}
            >
              <Row>
                <Col>
                  <TextInput
                    label={
                      <span>
                        {event_name.title}
                        {event_name.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    value={
                      get(process, `data.fields.${event_name.field}.value`)
                        ? get(process, `data.fields.${event_name.field}.value`)
                        : ""
                    }
                    onChange={valueChanged.bind(
                      this,
                      event_name,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[event_name.field] &&
                      validationState[event_name.field].validation_error}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Dropdown
                    isClearable={true}
                    name="event_type"
                    {...event_type.props}
                    label={
                      <span>
                        {event_type.title}
                        {event_type.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    value={
                      process.data.fields[event_type.field]
                        ? {
                            label: (
                              <FormattedMessage
                                id={`process > option label ${lowerCase(
                                  process.data.fields[event_type.field].value
                                )}`}
                              />
                            ),
                            value: process.data.fields[event_type.field].value,
                          }
                        : ""
                    }
                    onChange={valueChanged.bind(
                      this,
                      event_type,
                      process,
                      template,
                      user
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextInput
                    label={
                      <span>
                        {event_description.title}
                        {event_description.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    value={
                      process.data.fields[event_description.field]
                        ? process.data.fields[event_description.field].value
                        : ""
                    }
                    type="textarea"
                    onChange={valueChanged.bind(
                      this,
                      event_description,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[event_description.field] &&
                      validationState[event_description.field].validation_error}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Dropdown
                    isClearable={true}
                    name="jock"
                    {...jock.props}
                    label={
                      <span>
                        {jock.title}
                        {jock.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    value={
                      process.data.fields[jock.field]
                        ? {
                            label: (
                              <FormattedMessage
                                id={`process > option label ${lowerCase(
                                  process.data.fields[jock.field].value
                                )}`}
                              />
                            ),
                            value: process.data.fields[jock.field].value,
                          }
                        : ""
                    }
                    onChange={valueChanged.bind(
                      this,
                      jock,
                      process,
                      template,
                      user
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextInput
                    label={
                      <span>
                        {jock_instructions.title}
                        {jock_instructions.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    value={
                      process.data.fields[jock_instructions.field]
                        ? process.data.fields[jock_instructions.field].value
                        : ""
                    }
                    type="textarea"
                    onChange={valueChanged.bind(
                      this,
                      jock_instructions,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[jock_instructions.field] &&
                      validationState[jock_instructions.field].validation_error}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextInput
                    label={
                      <span>
                        {on_premise_copy.title}
                        {on_premise_copy.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    value={
                      get(process, `data.fields.${on_premise_copy.field}.value`)
                        ? get(
                            process,
                            `data.fields.${on_premise_copy.field}.value`
                          )
                        : ""
                    }
                    onChange={valueChanged.bind(
                      this,
                      on_premise_copy,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[on_premise_copy.field] &&
                      validationState[on_premise_copy.field].validation_error}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextInput
                    label={
                      <span>
                        {jock_notes.title}
                        {jock_notes.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    value={
                      process.data.fields[jock_notes.field]
                        ? process.data.fields[jock_notes.field].value
                        : ""
                    }
                    type="textarea"
                    onChange={valueChanged.bind(
                      this,
                      jock_notes,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[jock_notes.field] &&
                      validationState[jock_notes.field].validation_error}
                  </div>
                </Col>
              </Row>
            </SectionFields>
          </ShadowBox>
          <ShadowBox className="promotion-shadow-box">
            <SectionTitle
              className="promotion-section-title"
              onClick={() =>
                this.setState({
                  isOpenEventLocation: !this.state.isOpenEventLocation,
                })
              }
            >
              <FormattedMessage id="process > title promotion event location" />
              {this.isCompletedSection("event_location") && (
                <FaCheck color="#6F96D1" size={30} />
              )}
            </SectionTitle>
            <SectionFields
              className={!this.state.isOpenEventLocation && "hidden"}
            >
              <Row>
                <Col>
                  <TextInput
                    label={
                      <span>
                        {venue_name.title}
                        {venue_name.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    value={
                      process.data.fields[venue_name.field]
                        ? process.data.fields[venue_name.field].value
                        : ""
                    }
                    onChange={valueChanged.bind(
                      this,
                      venue_name,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[venue_name.field] &&
                      validationState[venue_name.field].validation_error}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextInput
                    label={
                      <span>
                        {location_information.title}
                        {location_information.mandatory && (
                          <Asterisk>*</Asterisk>
                        )}
                      </span>
                    }
                    value={
                      process.data.fields[location_information.field]
                        ? process.data.fields[location_information.field].value
                        : ""
                    }
                    type="textarea"
                    onChange={valueChanged.bind(
                      this,
                      location_information,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[location_information.field] &&
                      validationState[location_information.field]
                        .validation_error}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <TextInput
                    label={
                      <span>
                        {contact.title}
                        {contact.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    value={
                      process.data.fields[contact.field]
                        ? process.data.fields[contact.field].value
                        : ""
                    }
                    onChange={valueChanged.bind(
                      this,
                      contact,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[contact.field] &&
                      validationState[contact.field].validation_error}
                  </div>
                </Col>
                <Col>
                  <TextInput
                    label={
                      <span>
                        {phone.title}
                        {phone.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    value={
                      process.data.fields[phone.field]
                        ? process.data.fields[phone.field].value
                        : ""
                    }
                    onChange={valueChanged.bind(
                      this,
                      phone,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[phone.field] &&
                      validationState[phone.field].validation_error}
                  </div>
                </Col>
              </Row>
            </SectionFields>
          </ShadowBox>
          <StaffingAvailability
            isOpen={this.state.isOpenStaff}
            click={() =>
              this.setState({
                isOpenStaff: !this.state.isOpenStaff,
              })
            }
            onChange={valueChanged.bind(
              this,
              staff_availability,
              process,
              template,
              user
            )}
            value={
              process.data.fields[staff_availability.field]
                ? process.data.fields[staff_availability.field].value
                : []
            }
          />
          <Row>
            <Col>
              <ButtonGroup>
                <Button
                  block
                  color="blue"
                  className="button-submit"
                  onClick={this.props.buttonClicked.bind(
                    this,
                    step,
                    step.fields.find((f) => f.key === "save_button"),
                    process,
                    template,
                    user
                  )}
                >
                  <FormattedMessage id="process > button submit" />
                </Button>

                <Button
                  block
                  color="blue"
                  outline
                  className="button-save-draft"
                  onClick={this.props.buttonClicked.bind(
                    this,
                    step,
                    {
                      ...step.fields.find((f) => f.key === "save_button"),
                      draft: true,
                    },
                    process,
                    template,
                    user
                  )}
                >
                  <FormattedMessage id="process > button save draft" />
                </Button>
                {this.props.shouldBlockNavigation && (
                  <Button
                    color=""
                    className="process-link-clear-form"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.setShouldBlockNavigation(false);
                      this.props.onPreparePage();
                    }}
                  >
                    <FormattedMessage id="process > button clear form" />
                  </Button>
                )}
              </ButtonGroup>
            </Col>
          </Row>
        </Form>
        <Modal
          isOpen={this.state.isOpenDialog}
          toggle={() =>
            this.setState({ isOpenDialog: !this.state.isOpenDialog })
          }
        >
          <ModalBody>
            {this.state.stationsWithoutSpots ? (
              <OrderDetailsIncompleteContract
                stationsWithoutSpots={this.state.stationsWithoutSpots}
                close={() => this.setState({ isOpenDialog: false })}
              />
            ) : (
              <OrderDetailsDuplicate
                process={process}
                template={template}
                close={() => this.setState({ isOpenDialog: false })}
              />
            )}
          </ModalBody>
        </Modal>
      </div>
    );
  }

  formattedValueOrEmpty(value, item) {
    if (get(item, "formatted")) {
      const formatted = get(item, "formatted");
      switch (formatted) {
        case "number":
          return (
            <NumberFormat
              prefix={get(item, "prefix", null)}
              value={value}
              displayType={"text"}
              thousandSeparator={true}
            />
          );
        case "phone":
          return (
            <NumberFormat
              value={value}
              displayType={"text"}
              format={PhoneNumberFormat}
            />
          );
        case "percentage":
          return (
            <NumberFormat
              value={value}
              displayType={"text"}
              suffix={get(item, "suffix", RotationFormatSuffix)}
            />
          );
        case "time":
          return get(
            find(timeOptions, (t) => t.value === value),
            "label",
            ""
          );
        default:
          break;
      }
    }
    return value;
  }

  valueOrEmpty(item) {
    let { process } = this.props;
    let value =
      process.data.fields[item.field] && process.data.fields[item.field].value
        ? process.data.fields[item.field].value.name !== undefined
          ? process.data.fields[item.field].value.name
          : process.data.fields[item.field].value
        : "";
    return this.formattedValueOrEmpty(value, item);
  }

  renderView() {
    let { process, template, user, step, valueChanged } = this.props;

    let {
      start_date,
      recurring,
      repeats,
      // end_date,
      start_time,
      end_time,
      order_stations,
      event_name,
      event_type,
      event_description,
      jock,
      jock_instructions,
      jock_notes,
      on_premise_copy,
      venue_name,
      location_information,
      contact,
      phone,
      staff_availability,
    } = this.getFieldRefs();

    let spots = [];
    let prevOrNextSpot = false;
    let passedCurrent = false;
    if (
      process.data.fields.key &&
      this.props.process.data.fields.spots &&
      this.props.process.data.fields.spots.value.length > 1
    ) {
      this.props.process.data.fields.spots.value.map((spot, ind) => {
        if (
          spot.key !== process.data.fields.key.value &&
          (!passedCurrent || !prevOrNextSpot)
        )
          prevOrNextSpot = spot;
        else passedCurrent = true;
        spots.push({
          key: spot,
          component: (
            <span>
              {" "}
              <FormattedMessage id="process > title spot" /> {ind + 1}{" "}
            </span>
          ),
        });
        return true;
      });
    }
    let valueOrEmpty = this.valueOrEmpty.bind(this);

    return (
      <div className="text-left">
        <Form>
          <ShadowBox className="promotion-shadow-box">
            <SectionTitle
              className="promotion-section-title"
              onClick={() =>
                this.setState({
                  isOpenEventSchedule: !this.state.isOpenEventSchedule,
                })
              }
            >
              <FormattedMessage id="process > title promotion event schedule" />
              {this.isCompletedSection("event_schedule") && (
                <FaCheck color="#6F96D1" size={30} />
              )}
            </SectionTitle>
            <SectionFields
              className={!this.state.isOpenEventSchedule && "hidden"}
            >
              <Row>
                <Col>
                  <Label>{start_date.title}</Label>
                  <DivView>{valueOrEmpty(start_date)}</DivView>
                </Col>
                <Col>
                  <Label>{recurring.title}</Label>
                  <DivView>{valueOrEmpty(recurring)}</DivView>
                </Col>
                <Col>
                  <Label>{repeats.title}</Label>
                  <DivView>{valueOrEmpty(repeats)}</DivView>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>{start_time.title}</Label>
                  <DivView>{valueOrEmpty(start_time)}</DivView>
                </Col>
                <Col>
                  <Label>{end_time.title}</Label>
                  <DivView>{valueOrEmpty(end_time)}</DivView>
                </Col>
              </Row>
              <Row>
                <Col xs="12" lg="12">
                  <Label>{order_stations.title}</Label>
                  <DivView>
                    {process.data.fields[order_stations.field] &&
                      process.data.fields[order_stations.field].value.map(
                        (s, i) => (
                          <span
                            style={{
                              paddingRight: "20px",
                              display: "inline-block",
                            }}
                            key={i}
                          >
                            {s}
                          </span>
                        )
                      )}
                  </DivView>
                </Col>
              </Row>
            </SectionFields>
          </ShadowBox>
          <ShadowBox className="promotion-shadow-box">
            <SectionTitle
              className="promotion-section-title"
              onClick={() =>
                this.setState({
                  isOpenEventDetails: !this.state.isOpenEventDetails,
                })
              }
            >
              <FormattedMessage id="process > title promotion event details" />
              {this.isCompletedSection("event_details") && (
                <FaCheck color="#6F96D1" size={30} />
              )}
            </SectionTitle>
            <SectionFields
              className={!this.state.isOpenEventDetails && "hidden"}
            >
              <Row>
                <Col>
                  <Label>{event_name.title}</Label>
                  <DivView>{valueOrEmpty(event_name)}</DivView>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>{event_type.title}</Label>
                  <DivView>{valueOrEmpty(event_type)}</DivView>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>{event_description.title}</Label>
                  <DivView>{valueOrEmpty(event_description)}</DivView>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>{jock.title}</Label>
                  <DivView>{valueOrEmpty(jock)}</DivView>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>{jock_instructions.title}</Label>
                  <DivView>{valueOrEmpty(jock_instructions)}</DivView>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>{on_premise_copy.title}</Label>
                  <DivView>{valueOrEmpty(on_premise_copy)}</DivView>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>{jock_notes.title}</Label>
                  <DivView>{valueOrEmpty(jock_notes)}</DivView>
                </Col>
              </Row>
            </SectionFields>
          </ShadowBox>
          <ShadowBox className="promotion-shadow-box">
            <SectionTitle
              className="promotion-section-title"
              onClick={() =>
                this.setState({
                  isOpenEventLocation: !this.state.isOpenEventLocation,
                })
              }
            >
              <FormattedMessage id="process > title promotion event location" />
              {this.isCompletedSection("event_location") && (
                <FaCheck color="#6F96D1" size={30} />
              )}
            </SectionTitle>
            <SectionFields
              className={!this.state.isOpenEventLocation && "hidden"}
            >
              <Row>
                <Col>
                  <Label>{venue_name.title}</Label>
                  <DivView>{valueOrEmpty(venue_name)}</DivView>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>{location_information.title}</Label>
                  <DivView>{valueOrEmpty(location_information)}</DivView>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Label>{contact.title}</Label>
                  <DivView>{valueOrEmpty(contact)}</DivView>
                </Col>
                <Col>
                  <Label>{phone.title}</Label>
                  <DivView>{valueOrEmpty(phone)}</DivView>
                </Col>
              </Row>
            </SectionFields>
          </ShadowBox>
          <StaffingAvailability
            isOpen={this.state.isOpenStaff}
            click={() =>
              this.setState({
                isOpenStaff: !this.state.isOpenStaff,
              })
            }
            onChange={() => null}
            value={
              process.data.fields[staff_availability.field]
                ? process.data.fields[staff_availability.field].value
                : []
            }
          />
        </Form>
        {this.props.mode === "view" && (
          <Row>
            <Col>
              <Button
                color="blue"
                style={{
                  borderRadius: 30,
                  maxWidth: "180px",
                  minWidth: "95px",
                }}
                onClick={() => {
                  history.push(
                    "/processes/edit/" + template.key + "/" + process.key
                  );
                  this.props.setActiveEditTab(step.key);
                }}
              >
                <FormattedMessage id="process > button edit" />
              </Button>
              <PrivilegedComponent requires={[PRIVILEGES.DELETE_PROCESSES]}>
                <Button
                  color="blue"
                  outline
                  style={{
                    borderRadius: 30,
                    maxWidth: "180px",
                    marginLeft: 10,
                    minWidth: "95px",
                  }}
                  onClick={() => this.setState({ isOpenDialogDelete: true })}
                >
                  <FormattedMessage id="process > button delete" />
                </Button>
              </PrivilegedComponent>
            </Col>
          </Row>
        )}
        {/* delete modal */}
        <ConfirmationModal
          isOpen={this.state.isOpenDialogDelete}
          onToggle={() =>
            this.setState({
              isOpenDialogDelete: !this.state.isOpenDialogDelete,
            })
          }
          onCancel={() => this.setState({ isOpenDialogDelete: false })}
          description={
            <FormattedMessage id="process > description to delete this spot" />
          }
          className="modal-delete"
          onConfirm={() => {
            this.props.deleteProcess(process.key, (response) => {
              this.setState({ isOpenDialogDelete: false });
              this.setState({ isOpenDialogSubmitted: true });
              setTimeout(() => {
                this.setState({ isOpenDialogSubmitted: false });
                if (prevOrNextSpot)
                  history.push(
                    "/processes/view/" + template.key + "/" + prevOrNextSpot
                  );
                else history.push("/");
              }, 2000);
            });
          }}
          isCloseOutside={false}
        />
        {/* submitted modal */}
        <ConfirmationModal
          isOpen={this.state.isOpenDialogSubmitted}
          onToggle={() =>
            this.setState({
              isOpenDialogSubmitted: !this.state.isOpenDialogSubmitted,
            })
          }
          onCancel={() => this.setState({ isOpenDialogSubmitted: false })}
          state="success"
          title={<FormattedMessage id={`process > title deleted`} />}
        />
      </div>
    );
  }

  render() {
    let { process } = this.props;

    if (!process.data.fields || Object.keys(process.data.fields).length === 0)
      return null;

    let form = null;
    if (this.props.mode === "edit") {
      form = this.renderForm();
    } else {
      form = this.renderView();
    }
    return <span className="orderForm">{form}</span>;
  }
}

export default PromotionDetails;
