import React, { useEffect, useRef } from "react";
import { Button, Row, Col, Form, ButtonGroup } from "reactstrap";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  get,
  values as getValues,
  isEqual,
  map,
  first,
  isEmpty,
  filter,
  forEach,
  mapValues,
  find,
} from "lodash";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import {
  EditIcon,
  PlusIconSmaller,
  UpIcon,
  DownIcon,
} from "components/CustomIcons";
import PrivilegedComponent from "components/PrivilegedComponent";
import ClockFields from "../ClockFields";
import ShowClockCalendar from "../Calendar/ShowClock";
import ProgrammingClockCalendar from "../Calendar/ProgrammingClock";
import { SHOWCLOCK, PROGCLOCK } from "utils/constants";
import ProgClockSlots from "./ProgClockSlots";
import ReleaseNationalSlots from "./ReleaseNationalSlots";
import SeparationRules from "./SeparationRules";
import ButtonAdd from "components/ButtonAdd";
import Dropdown from "components/Dropdown";
import ConfirmationModal from "components/ConfirmationModal";
import BlockingComponent from "components/BlockingComponent";
import ToastManager from "components/ToastManager";
import { PRIVILEGES } from "utils/constants";

const EditClock = (props) => {
  const {
    intl,
    stations,
    selectedClock,
    onSaveClock,
    isLoading,
    isEditClicked,
    setIsEditClicked,
    bem,
    clockId,
    addEmptySlot,
    isCancelWarningModalOpen,
    setIsCancelWarningModalOpen,
    getClock,
    setIsWeekStartModalOpen,
    isWeekStartModalOpen,
    isReleasePanelOpen,
    setIsReleasePanelOpen,
    isSaveConfirmModalOpen,
    setIsSaveConfirmModalOpen,
    setIsSaveConfirmed,
    slotBeingModified,
    setSlotBeingModified,
    deleteSuccessModalTitle,
    setDeleteSuccessModalTitle,
    deleteStep,
    setDeleteStep,
    shouldBlockNavigation,
    setShouldBlockNavigation,
    isRulesPanelOpen,
    setIsRulesPanelOpen,
    addDefaultSeparationRules,
  } = props;

  let station = get(selectedClock, "data.station", {});
  const stationData = find(stations, (item) => item.key === station.key);
  const slotCategories = get(stationData, "clock_slot_categories", {});
  let slotDefinitions = {};

  forEach(
    get(selectedClock, "data.slot_definitions", []),
    ({
      id,
      national,
      category_name,
      category_tag_id,
      type_name,
      type_tag_id,
      color,
      name,
      assign_to,
      days_before_date,
      has_clock_slots,
      items_scheduled,
    }) => {
      slotDefinitions[id] = {
        id,
        national: national ? true : false,
        type: {
          label: type_name ? type_name.toString() : "",
          value: type_tag_id ? type_tag_id.toString() : "",
        },
        category: get(slotCategories, category_tag_id)
          ? {
              label: category_name ? category_name.toString() : "",
              value: category_tag_id ? category_tag_id.toString() : "",
            }
          : null,
        color,
        label: name,
        deleted: false,
        guid: `${Date.now()}${id}`,
        assign_to: assign_to,
        days_before_date: days_before_date,
        release_to_local: national && !isEmpty(assign_to) ? true : false,
        has_clock_slots,
        items_scheduled,
        slots: filter(get(selectedClock, "data.slots", []), (slot) => {
          return get(slot, "slot_labels", []).find((i) => i.id === id);
        }),
      };
    }
  );
  //set assignTO
  forEach(slotDefinitions, (slot) => {
    let assignTo = slotDefinitions[get(slot, "assign_to.id")];

    slotDefinitions[slot.id] = { ...slot, assign_to: assignTo || null };
  });
  let initialSlotDefinitions = {};
  if (
    isEmpty(slotDefinitions) &&
    get(selectedClock, "data.type", "") === PROGCLOCK
  ) {
    initialSlotDefinitions = {
      _new_slot: {
        id: "_new_slot",
        national: false,
        type: null,
        category: null,
        color: "",
        label: "",
        deleted: false,
        guid: Date.now(),
        assign_to: null,
        days_before_date: null,
        release_to_local: false,
      },
    };
  } else if (
    !isEmpty(slotDefinitions) &&
    get(selectedClock, "data.type", "") === PROGCLOCK
  ) {
    initialSlotDefinitions = slotDefinitions;
  }

  let initialValues = {
    type: {
      label: get(selectedClock, "data.type", ""),
      value: get(selectedClock, "data.type", ""),
    },
    title: get(selectedClock, "data.title"),
    station: {
      label: station.name,
      value: station.key,
    },
    start_week_on: get(selectedClock, "data.start_week_on", ""),
    slot_definitions: initialSlotDefinitions,
  };
  if (get(selectedClock, "data.type", "") === PROGCLOCK) {
    const separation_rules = get(selectedClock, "data.separation_rules", {});
    initialValues.separation_rules = {
      consecutive_days_rule_types: get(
        separation_rules,
        "consecutive_days_rule_types",
        []
      ),
      consecutive_days_rule: get(
        separation_rules,
        "consecutive_days_rule",
        true
      ),
      label_rule: get(separation_rules, "label_rule", true),
      linked_items_rule: get(separation_rules, "linked_items_rule", true),
      linked_items_min_separation_time: get(
        separation_rules,
        "linked_items_min_separation_time",
        30
      ),
      linked_items_priority: get(separation_rules, "linked_items_priority", []),
      time_rule: get(separation_rules, "time_rule", true),
      time_rule_min_separation_time: get(
        separation_rules,
        "time_rule_min_separation_time",
        []
      ),
    };
  }
  const formikRef = useRef();

  useEffect(() => {
    formikRef.current.setValues(initialValues);
    formikRef.current.setTouched({}, false);

    if (
      get(selectedClock, "data.type") === PROGCLOCK &&
      isEmpty(get(selectedClock, "data.slot_definitions"))
    ) {
      setIsEditClicked(true);
    }
  }, [selectedClock]);
  useEffect(() => {
    const bemRootElement = document.querySelector(".cr-clock-creator");
    if (!bemRootElement) return;
    if (isEditClicked) bemRootElement.classList.add("cr-clock-creator--edit");
    if (!isEditClicked)
      bemRootElement.classList.remove("cr-clock-creator--edit");
  }, [isEditClicked]);
  const validationSchema = Yup.object().shape({
    type: Yup.mixed().required(
      intl.formatMessage({
        id: "clock creator > type is required",
      })
    ),
    title: Yup.mixed().required(
      intl.formatMessage({
        id: "clock creator > title is required",
      })
    ),
    station: Yup.mixed().required(
      intl.formatMessage({
        id: "clock creator > station is required",
      })
    ),
    separation_rules: Yup.object({
      consecutive_days_rule: Yup.boolean(),
      label_rule: Yup.boolean(),
      linked_items_rule: Yup.boolean(),
      linked_items_min_separation_time: Yup.string().nullable(),
      linked_items_priority: Yup.array(),
      time_rule: Yup.boolean(),
      time_rule_min_separation_time: Yup.array(),
    }),
    slot_definitions: Yup.lazy((obj) =>
      Yup.object(
        mapValues(obj, () =>
          Yup.object({
            deleted: Yup.boolean(),
            category: Yup.mixed()
              .nullable()
              .when("deleted", {
                is: false,
                then: Yup.mixed()
                  .required(
                    intl.formatMessage({
                      id:
                        "clock creator > define slot definitions > category is required",
                    })
                  )
                  .test(
                    "validCategory",
                    intl.formatMessage({
                      id:
                        "clock creator > define slot definitions > category is required",
                    }),
                    function(val) {
                      if (!val) return false;
                      // validate selected category is exists from categories of station. Because user can delete the custom category.
                      const selectedStation = get(
                        formikRef,
                        "current.state.values.station",
                        {}
                      );
                      const stationData = find(
                        stations,
                        (item) => item.key === selectedStation.value
                      );
                      const slotCategories = get(
                        stationData,
                        "clock_slot_categories",
                        {}
                      );
                      return get(slotCategories, val.value);
                    }
                  ),
              }),
            label: Yup.string()
              .nullable()
              .when("deleted", {
                is: false,
                then: Yup.string().required(
                  intl.formatMessage({
                    id:
                      "clock creator > define slot definitions > label is required",
                  })
                ),
              }),
            type: Yup.object()
              .nullable()
              .when("deleted", {
                is: false,
                then: Yup.object().required(
                  intl.formatMessage({
                    id:
                      "clock creator > define slot definitions > type is required",
                  })
                ),
              }),
            release_to_local: Yup.boolean(),
            national: Yup.boolean(),
            assign_to: Yup.object()
              .nullable()
              .when(["national", "deleted", "release_to_local"], {
                is: (national, deleted, release_to_local) =>
                  national && !deleted && release_to_local,
                then: Yup.object().test(
                  "assign_to",
                  intl.formatMessage({
                    id:
                      "clock creator > define slot definitions > assign to is required",
                  }),
                  (value) => {
                    if (isEmpty(value)) {
                      return false;
                    }
                    return true;
                  }
                ),
              }),
            days_before_date: Yup.string()
              .nullable()
              .when(["national", "deleted", "release_to_local"], {
                is: (national, deleted, release_to_local) =>
                  national && !deleted && release_to_local,
                then: Yup.string().required(
                  intl.formatMessage({
                    id:
                      "clock creator > define slot definitions > days before date is required",
                  })
                ),
              }),
          })
        )
      )
    ),
  });

  let isEdit = selectedClock ? true : false;
  let clockType = get(selectedClock, "data.type", "");

  const getDeleteModalTitle = () => {
    let title = "";

    switch (deleteStep) {
      case 1:
        title = intl.formatMessage({
          id: "clock creator > confirm delete slot type",
        });
        break;
      case 2:
        title = intl.formatMessage({
          id: "clock creator > confirm re-assign schedules",
        });
        break;
      case 3:
        title = intl.formatMessage({
          id: "clock creator > assign these items to",
        });
        break;
      case 4:
        title = intl.formatMessage({ id: "clock creator > remove schedules" });
        break;
      case 5:
        title = intl.formatMessage({
          id: "clock creator > remove associated schedules",
        });
        break;
      case 6:
        title = intl.formatMessage({
          id: "clock creator > confirm re-assign empty schedules",
        });
        break;
      case 7:
        title = intl.formatMessage({
          id: "clock creator > confirm reset slot type",
        });
        break;
      default:
        break;
    }

    return title;
  };

  const getDeleteSuccessMessage = (onCancel) => {
    let message = "";

    switch (deleteStep) {
      case 1:
        message = intl.formatMessage({
          id: "clock creator > slot type deleted",
        });
        break;
      case 3:
        message = intl.formatMessage({
          id: "clock creator > slot type deleted and re-assigned",
        });
        break;
      case 4:
      case 5:
        message = onCancel
          ? intl.formatMessage({ id: "clock creator > schedules left alone" })
          : intl.formatMessage({ id: "clock creator > removed schedules" });
        break;
      case 7:
        message = intl.formatMessage({ id: "clock creator > slot type reset" });
        break;
      default:
        break;
    }

    return message;
  };

  const renderReassignSchedulesModalContent = (
    slot,
    { values, setFieldValue, setFieldTouched }
  ) => {
    let slotDefinitions = get(values, "slot_definitions", {});

    return (
      <Row className="m-0">
        <Col
          className={bem.e("reassign-dropdown-container")}
          style={{ width: "279px", marginTop: "20px", textAlign: "left" }}
        >
          <Dropdown
            name="reassigned_items_to"
            placeholder={intl.formatMessage({
              id: "clock creator > select slot type",
            })}
            onChange={(selectedOption) => {
              setFieldValue("slot_definitions", {
                ...slotDefinitions,
                [slot.id]: {
                  ...slot,
                  reassigned_items_to: find(
                    slotDefinitions,
                    (slotDefinition) =>
                      slotDefinition.guid === selectedOption.value
                  ),
                },
              });
              setFieldTouched("slot_definitions", true);
            }}
            value={
              slot.reassigned_items_to
                ? {
                    label: get(slot, "reassigned_items_to.label"),
                    value: get(slot, "reassigned_items_to.guid"),
                  }
                : null
            }
            options={filter(
              slotDefinitions,
              ({ id, deleted }) => !deleted && id !== slot.id
            ).map((slotDefinition) => ({
              label: slotDefinition.label,
              value: slotDefinition.guid,
            }))}
          />
        </Col>
      </Row>
    );
  };

  const onDeleteSlotDefinition = (
    slot,
    { values, setFieldValue, setFieldTouched },
    deleteStep,
    onCancel = false
  ) => {
    let slotDefinitions = get(values, "slot_definitions", {});
    let updatedSlot = {};

    let isTheOnlyDefinition =
      filter(slotDefinitions, (slotDefinition) => !slotDefinition.deleted)
        .length === 1;

    if (deleteStep === 4 && !onCancel) {
      updatedSlot = isTheOnlyDefinition
        ? {
            ...slot,
            national: false,
            type: null,
            category: null,
            color: "",
            label: "",
            assign_to: null,
            days_before_date: null,
            remove_scheduled_items: true,
            cleared: true,
          }
        : { ...slot, deleted: true, remove_scheduled_items: true };
    } else {
      updatedSlot = isTheOnlyDefinition
        ? {
            ...slot,
            national: false,
            type: null,
            category: null,
            color: "",
            label: "",
            assign_to: null,
            days_before_date: null,
            cleared: true,
          }
        : { ...slot, deleted: true };
    }

    let relatedSlots = {};

    let _relatedSlots = filter(
      slotDefinitions,
      (_relatedSlot) =>
        parseInt(get(_relatedSlot, "assign_to.guid")) === parseInt(slot.guid)
    );

    forEach(_relatedSlots, (relatedSlot) => {
      relatedSlots[relatedSlot.id] = { ...relatedSlot, assign_to: null };
    });

    //we need to update reassigned_items_to for other slots which are having reassigned_items_to set to this slot
    let otherSlotsToModify = {};

    let _otherSlotsToModify = filter(
      slotDefinitions,
      (_otherSlot) =>
        parseInt(get(_otherSlot, "reassigned_items_to.guid")) ===
        parseInt(slot.guid)
    );

    forEach(_otherSlotsToModify, (otherSlot) => {
      otherSlotsToModify[otherSlot.id] = {
        ...otherSlot,
        reassigned_items_to: get(slot, "reassigned_items_to"),
      };
    });

    setFieldValue("slot_definitions", {
      ...slotDefinitions,
      ...relatedSlots,
      ...otherSlotsToModify,
      [slot.id]: updatedSlot,
    });
    setFieldTouched("slot_definitions", true);

    let successMessage = getDeleteSuccessMessage(onCancel);

    setDeleteStep(0, (prevState) => {
      setSlotBeingModified({});
      setDeleteSuccessModalTitle(successMessage, () => {
        setTimeout(() => {
          setDeleteSuccessModalTitle(false);
        }, 2000);
      });
    });
  };

  const renderCalendar = () => {
    if (clockType === SHOWCLOCK) {
      return (
        <div className={bem.e("calendar-container")}>
          <div className={bem.e("calendar-header")}>
            <h2>
              <FormattedMessage id="clock creator > set times" />
            </h2>
            <div className="sub-text">
              <FormattedMessage id="clock creator > show calendar helper text" />
            </div>
          </div>
          <div>
            <ShowClockCalendar bem={bem} clock={selectedClock} />
          </div>
        </div>
      );
    }

    if (clockType === PROGCLOCK && !isEditClicked) {
      return (
        <div className={bem.e("calendar-container")}>
          <div className={bem.e("calendar-header")}>
            <h2>
              <FormattedMessage id="clock creator > set times" />
            </h2>
            <div className="sub-text">
              <FormattedMessage id="clock creator > programming calendar helper text" />
            </div>
          </div>
          <div>
            <ProgrammingClockCalendar bem={bem} clock={selectedClock} />
          </div>
        </div>
      );
    }

    return null;
  };

  const renderProgClockSlotDefinitions = (formProps) => {
    const { values, setFieldValue, setFieldTouched, errors } = formProps;

    let progClockSlots = get(values, "slot_definitions", {});
    const isShowTrash =
      get(
        Object.values(progClockSlots).filter((slot) => !slot.deleted),
        "length",
        0
      ) > 1;
    let progClockSlotsIndex = 0;
    return map(progClockSlots, (slot, key) => {
      let slotDefinitionErrors = errors.slot_definitions
        ? errors.slot_definitions[key]
        : {};
      // let errorKey = first(keys(slotDefinitionErrors));
      if (!slot.deleted) {
        progClockSlotsIndex += 1;
        return (
          <React.Fragment key={key}>
            <ProgClockSlots
              firstRow={progClockSlotsIndex === 1}
              id={key}
              slots={progClockSlots}
              isShowTrash={isShowTrash}
              onChange={(slots) => {
                setFieldValue("slot_definitions", slots);
                setFieldTouched("slot_definitions", true);

                if (!isEmpty(get(slot, "slots")) && !get(slot, "cleared")) {
                  setDeleteStep(5);
                  setSlotBeingModified(slot);
                }
              }}
              stations={stations}
              selectedStation={values.station}
              clockId={clockId}
              onDelete={(slot) => {
                setDeleteStep(isShowTrash ? 1 : 7);
                setSlotBeingModified(slot);
              }}
              errors={slotDefinitionErrors}
            />
          </React.Fragment>
        );
      }
    });
  };

  const renderReleaseNationalSlotsPanel = ({
    values,
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
  }) => {
    return (
      <ReleaseNationalSlots
        bem={bem}
        slots={values.slot_definitions}
        onChange={(slots) => {
          setFieldValue("slot_definitions", slots);
          setFieldTouched("slot_definitions", true);
        }}
        touched={touched}
        errors={errors}
      />
    );
  };

  const renderSeparationRules = ({
    values,
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
  }) => {
    return (
      <SeparationRules
        bem={bem}
        separation_rules={values.separation_rules}
        slots={values.slot_definitions}
        onChange={(data, isUpdateTouched = true) => {
          setFieldValue("separation_rules", data);
          if (isUpdateTouched) {
            setFieldTouched("separation_rules", true);
          }
        }}
        intl={intl}
        touched={touched}
        errors={errors}
      />
    );
  };

  const renderClockDetailsPanel = (formProps, initialValues, formikRef) => {
    const { values, setFieldValue, touched } = formProps;

    let nationalSlots = getValues(
      filter(
        values.slot_definitions,
        (slot) => slot.national && slot.label && !slot.deleted
      )
    );
    const slots = getValues(
      filter(values.slot_definitions, (slot) => slot.label && !slot.deleted)
    );
    if (!isEditClicked) {
      return (
        <div className={bem.e("clock-info")}>
          <Row>
            <Col xs={10} md={10} className="col">
              <span className="clock-name">
                {get(values, "title", "")} - {get(values, "station.label", "")}
              </span>
            </Col>
            <Col xs={2} md={2} className="col">
              <ButtonGroup>
                <PrivilegedComponent
                  requires={{
                    or: [
                      PRIVILEGES.EDIT_PROGRAMMING_CLOCK,
                      PRIVILEGES.EDIT_SHOW_CLOCK,
                    ],
                  }}
                >
                  <Button color="link" onClick={() => setIsEditClicked(true)}>
                    <EditIcon width="24" height="22.35" />
                  </Button>
                </PrivilegedComponent>
              </ButtonGroup>
            </Col>
          </Row>
        </div>
      );
    }
    let progClockSlots = get(values, "slot_definitions", {});
    const isShowTrash =
      get(
        Object.values(progClockSlots).filter((slot) => !slot.deleted),
        "length",
        0
      ) > 1;
    return (
      <React.Fragment>
        <div className={bem.e("form-panel")}>
          {clockType === PROGCLOCK && (
            <div className={classnames(bem.e("header-wrapper"))}>
              <h2 className={classnames(bem.e("header"))}>
                <FormattedMessage id="clock creator > clock details" />
              </h2>
            </div>
          )}
          <Row className={classnames(bem.e("form-container"), "m-0")}>
            <ClockFields
              fieldSpan={clockType === PROGCLOCK ? 4 : 3}
              bem={bem}
              stations={stations}
              formProps={formProps}
              isEdit={isEdit}
              onStationChange={() => {
                let slotDefinitions = {};

                forEach(values.slot_definitions, (slot) => {
                  slotDefinitions[slot.id] = {
                    ...slot,
                    category: null,
                    type: null,
                  };
                });

                setFieldValue("slot_definitions", slotDefinitions);
              }}
            />
            {clockType === SHOWCLOCK && (
              <div className="bottom-actions-sticky">
                <div className={"buttons"}>
                  <PrivilegedComponent requires={[PRIVILEGES.EDIT_SHOW_CLOCK]}>
                    <Button
                      color="primary"
                      type="submit"
                      className="btn btn-blue btn-radius"
                      disabled={isLoading}
                      onClick={() => {
                        setTimeout(() => {
                          const formErrors = get(
                            formikRef.current,
                            "state.errors"
                          );
                          if (formErrors && Object.keys(formErrors).length) {
                            ToastManager.show({
                              title: intl.formatMessage({
                                id: "toast > title not saved",
                              }),
                              message: intl.formatMessage({
                                id:
                                  "toast > message error please correct the hilighted fields",
                              }),
                              level: "error",
                            });
                            return;
                          }
                        }, 20);
                      }}
                      // onClick={handleSubmit}
                    >
                      <FormattedMessage
                        id={`clock creator > button ${
                          !isLoading ? "save" : "saving"
                        }`}
                      />
                    </Button>
                  </PrivilegedComponent>
                  <Button
                    type="button"
                    onClick={() => {
                      setIsEditClicked(false);
                      formProps.resetForm(initialValues);
                    }}
                    outline
                    className={classnames(
                      bem.e("button-cancel"),
                      "btn-outline-blue btn-radius"
                    )}
                  >
                    <FormattedMessage id="clock creator > button cancel" />
                  </Button>
                </div>
              </div>
            )}
          </Row>
        </div>
        {clockType === PROGCLOCK && (
          <React.Fragment>
            <div className={classnames(bem.e("form-panel"), "sub-panel")}>
              <div className={classnames(bem.e("header-wrapper"))}>
                <h2 className={classnames(bem.e("header"))}>
                  <FormattedMessage id="clock creator > define slots" />
                </h2>
              </div>
              <Row className={classnames(bem.e("form-container"), "m-0")}>
                <div className={bem.e("helper-text-content")}>
                  <FormattedMessage id="clock creator > define slots details" />
                </div>
              </Row>
              <Row
                className={classnames(
                  bem.e("progclock-slots-container"),
                  "m-0"
                )}
              >
                <Col className="p-0 m-0" xs={12}>
                  {renderProgClockSlotDefinitions(formProps)}
                </Col>
              </Row>
              <Row
                className={classnames(
                  bem.e("add-row-button-container"),
                  "m-0 p-0"
                )}
              >
                <Col xs={12} className="m-0">
                  <ButtonAdd
                    onClick={() => {
                      addEmptySlot(formProps);
                      addDefaultSeparationRules(formProps);
                    }}
                    showDefaultIcon={false}
                  >
                    <div>
                      <PlusIconSmaller />
                      <FormattedMessage id="clock creator > add another progclock slot" />
                    </div>
                  </ButtonAdd>
                </Col>
              </Row>
            </div>
            {!isEmpty(nationalSlots) && (
              <div className={classnames(bem.e("form-panel"), "sub-panel")}>
                <div className={classnames(bem.e("header-wrapper"))}>
                  <h2 className={classnames(bem.e("header"))}>
                    <FormattedMessage id="clock creator > release slots" />
                    <span className="sub-text">
                      &nbsp;
                      <FormattedMessage id="clock creator > optional" />
                    </span>
                    <span
                      className={bem.e("button-panel-toggle-icon")}
                      onClick={() => setIsReleasePanelOpen(!isReleasePanelOpen)}
                    >
                      {isReleasePanelOpen ? (
                        <UpIcon color="#657894" />
                      ) : (
                        <DownIcon color="#657894" />
                      )}
                    </span>
                  </h2>
                </div>
                {isReleasePanelOpen && (
                  <React.Fragment>
                    <Row className={classnames(bem.e("form-container"), "m-0")}>
                      <div className={bem.e("helper-text-content")}>
                        <FormattedMessage id="clock creator > release slots details" />
                      </div>
                    </Row>
                    <Row
                      className={classnames(
                        bem.e("form-container"),
                        "m-0",
                        bem.e("national-slots")
                      )}
                    >
                      <Col className="p-0 m-0" xs={12}>
                        {renderReleaseNationalSlotsPanel(formProps)}
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </div>
            )}
            {!isEmpty(slots) && (
              <div className={classnames(bem.e("form-panel"), "sub-panel")}>
                <div className={classnames(bem.e("header-wrapper"))}>
                  <h2 className={classnames(bem.e("header"))}>
                    <FormattedMessage id="clock creator > separation rules" />
                    <span
                      className={bem.e("button-panel-toggle-icon")}
                      onClick={() => setIsRulesPanelOpen(!isRulesPanelOpen)}
                    >
                      {isRulesPanelOpen ? (
                        <UpIcon color="#657894" />
                      ) : (
                        <DownIcon color="#657894" />
                      )}
                    </span>
                  </h2>
                </div>
                <div
                  className={classnames(bem.e("separation-rules-details"), {
                    [bem.e(
                      "separation-rules-details-opened"
                    )]: isRulesPanelOpen,
                  })}
                >
                  <Row
                    className={classnames(
                      bem.e("form-container"),
                      bem.e("rules-helper-text"),
                      "m-0"
                    )}
                  >
                    <div className={bem.e("helper-text-content")}>
                      <FormattedMessage id="clock creator > separation rules details" />
                    </div>
                  </Row>
                  {renderSeparationRules(formProps)}
                </div>
              </div>
            )}
            <div className={"bottom-actions-sticky"}>
              <div className={"buttons"}>
                <PrivilegedComponent
                  requires={{
                    or: [
                      PRIVILEGES.EDIT_PROGRAMMING_CLOCK,
                      PRIVILEGES.CREATE_PROGRAMMING_CLOCK,
                    ],
                  }}
                >
                  <Button
                    color="primary"
                    type="submit"
                    className="btn btn-blue btn-radius"
                    disabled={isLoading}
                    onClick={() => {
                      setTimeout(() => {
                        const formErrors = get(
                          formikRef.current,
                          "state.errors"
                        );
                        if (formErrors && Object.keys(formErrors).length) {
                          ToastManager.show({
                            title: intl.formatMessage({
                              id: "toast > title not saved",
                            }),
                            message: intl.formatMessage({
                              id:
                                "toast > message error please correct the hilighted fields",
                            }),
                            level: "error",
                          });
                          return;
                        }
                      }, 20);
                    }}
                  >
                    <FormattedMessage
                      id={`clock creator > button ${
                        !isLoading ? "save" : "saving"
                      }`}
                    />
                  </Button>
                </PrivilegedComponent>
                <Button
                  type="button"
                  onClick={() => {
                    if (!isEmpty(touched)) {
                      setIsCancelWarningModalOpen(true);
                    } else {
                      setIsEditClicked(false);
                    }
                  }}
                  outline
                  className="btn-outline-blue btn-radius button-cancel"
                >
                  <FormattedMessage id="clock creator > button cancel" />
                </Button>
              </div>
            </div>
            <ConfirmationModal
              isOpen={isWeekStartModalOpen}
              title={intl.formatMessage({
                id: "clock creator > week start on",
              })}
              onConfirm={() => {
                setFieldValue("start_week_on", "sunday");
                setIsWeekStartModalOpen(false);
                onSaveClock({ ...values, start_week_on: "sunday" });
              }}
              confirmTitle={intl.formatMessage({
                id: "clock creator > sunday",
              })}
              onCancel={() => {
                setFieldValue("start_week_on", "monday");
                setIsWeekStartModalOpen(false);
                onSaveClock({ ...values, start_week_on: "monday" });
              }}
              cancelTitle={intl.formatMessage({ id: "clock creator > monday" })}
              cancelBlueButton={true}
              isCloseOutside={false}
            />
            <ConfirmationModal
              isOpen={isSaveConfirmModalOpen}
              title={intl.formatMessage({
                id: "clock creator > confirm programming clock save",
              })}
              onConfirm={() => {
                setIsSaveConfirmed(true, (prevState) => {
                  setIsSaveConfirmModalOpen(false);
                  onSaveClock(values);
                });
              }}
              onCancel={() => setIsSaveConfirmModalOpen(false)}
              isCloseOutside={false}
            />
            <ConfirmationModal
              isOpen={!isEmpty(slotBeingModified)}
              title={getDeleteModalTitle()}
              description={
                deleteStep === 3 &&
                renderReassignSchedulesModalContent(
                  find(
                    values.slot_definitions,
                    (slotDefinition) =>
                      slotDefinition.id === slotBeingModified.id
                  ),
                  formProps
                )
              }
              isCloseOutside={false}
              onConfirm={() => {
                let _slotBeingModified = find(
                  values.slot_definitions,
                  (slotDefinition) => slotDefinition.id === slotBeingModified.id
                );
                if (deleteStep === 1 || deleteStep === 7) {
                  // We need a new modal for this occasion. This new modal should only show when it has been added to the clock but nothing has been scheduled in it. If it has been added to the clock, and has items scheduled, then the current modal would be correct.
                  if (
                    _slotBeingModified.has_clock_slots &&
                    !_slotBeingModified.items_scheduled
                  ) {
                    setDeleteStep(6);
                  } else if (
                    _slotBeingModified.has_clock_slots &&
                    _slotBeingModified.items_scheduled
                  ) {
                    setDeleteStep(2);
                  } else {
                    onDeleteSlotDefinition(_slotBeingModified, formProps, 1);
                  }
                } else if (deleteStep === 2 || deleteStep === 6) {
                  setDeleteStep(3);
                } else if (deleteStep === 3) {
                  if (get(_slotBeingModified, "reassigned_items_to")) {
                    onDeleteSlotDefinition(_slotBeingModified, formProps, 3);
                  }
                } else if (deleteStep === 4) {
                  onDeleteSlotDefinition(_slotBeingModified, formProps, 4);
                } else if (deleteStep === 5) {
                  setFieldValue("slot_definitions", {
                    ...values.slot_definitions,
                    [slotBeingModified.id]: {
                      ..._slotBeingModified,
                      remove_scheduled_items: true,
                    },
                  });

                  let successMessage = getDeleteSuccessMessage();

                  setDeleteStep(0, (prevState) => {
                    setSlotBeingModified({});
                    setDeleteSuccessModalTitle(successMessage, () => {
                      setTimeout(() => {
                        setDeleteSuccessModalTitle(false);
                      }, 2000);
                    });
                  });
                }
              }}
              onCancel={() => {
                let _slotBeingModified = find(
                  values.slot_definitions,
                  (slotDefinition) => slotDefinition.id === slotBeingModified.id
                );
                if (deleteStep === 2 || deleteStep === 6) {
                  setDeleteStep(4);
                } else if (deleteStep === 4) {
                  onDeleteSlotDefinition(
                    _slotBeingModified,
                    formProps,
                    4,
                    true
                  );
                } else if (deleteStep === 5) {
                  let successMessage = getDeleteSuccessMessage(true);

                  setDeleteStep(0, (prevState) => {
                    setSlotBeingModified({});
                    setDeleteSuccessModalTitle(successMessage, () => {
                      setTimeout(() => {
                        setDeleteSuccessModalTitle(false);
                      }, 2000);
                    });
                  });
                } else if (deleteStep === 7) {
                  setSlotBeingModified(null);
                } else {
                  setFieldValue("slot_definitions", {
                    ...values.slot_definitions,
                    [slotBeingModified.id]: slotBeingModified,
                  });
                  setDeleteStep(0, (prevState) => {
                    setSlotBeingModified(null);
                  });
                }
              }}
              confirmTitle={
                deleteStep === 3
                  ? intl.formatMessage({ id: "clock creator > button assign" })
                  : intl.formatMessage({ id: "clock creator > button yes" })
              }
              cancelTitle={
                deleteStep === 3
                  ? intl.formatMessage({ id: "clock creator > button cancel" })
                  : intl.formatMessage({ id: "clock creator > button no" })
              }
            />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  const form = (
    <div className={bem.e("clock-details")}>
      <Row>
        <Col className="m-0 p-0">
          <div>
            <Formik
              validationSchema={validationSchema}
              isInitialValid={validationSchema.isValidSync(initialValues)}
              validateOnChange={false}
              validateOnBlur={false}
              validateOnMount={false}
              onSubmit={(values) => {
                if (clockType === PROGCLOCK) {
                  setIsWeekStartModalOpen(true);
                } else {
                  onSaveClock(values);
                }
              }}
              ref={formikRef}
            >
              {(formProps) => {
                const { handleSubmit, values, touched } = formProps;
                const isChanged = !isEqual(initialValues, values);
                // only check when user touched any field.
                if (
                  isChanged !== shouldBlockNavigation &&
                  Object.keys(touched).length > 0
                ) {
                  setTimeout(() => setShouldBlockNavigation(isChanged), 20);
                }
                // console.log(formProps.errors)
                return (
                  <React.Fragment>
                    <div>
                      <Form onSubmit={handleSubmit}>
                        {renderClockDetailsPanel(
                          formProps,
                          initialValues,
                          formikRef
                        )}
                      </Form>
                    </div>
                  </React.Fragment>
                );
              }}
            </Formik>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="m-0 p-0">{renderCalendar()}</Col>
      </Row>
      <ConfirmationModal
        isOpen={isCancelWarningModalOpen}
        title={intl.formatMessage({ id: "clock creator > confirm cancel" })}
        onConfirm={() => {
          setIsCancelWarningModalOpen(false);
          setIsEditClicked(false);
          getClock(clockId);
        }}
        onCancel={() => setIsCancelWarningModalOpen(false)}
        isCloseOutside={false}
      />
      <ConfirmationModal
        isOpen={deleteSuccessModalTitle ? true : false}
        title={deleteSuccessModalTitle}
        state="success"
      />
    </div>
  );

  return (
    <React.Fragment>
      {form}
      <BlockingComponent
        isBlocked={shouldBlockNavigation}
        modalProps={{
          isShowConfirm: true,
          isShowDiscard: false,
          isCancelConfirmedNavigation: true,
          onCancel: () => {
            setShouldBlockNavigation(false); // reset
          },
          // onConfirm: () => {
          //   const buttonSubmit = document.getElementById(`btn-submit`);
          //   // trigger click to submit the form
          //   if (buttonSubmit) buttonSubmit.click();
          // },
        }}
      />
    </React.Fragment>
  );
  // return (
  //   <WarnAboutUnsavedChanges when={shouldBlockNavigation} children={form} />
  // );
};

export default EditClock;
