import React, { useState, useRef } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { Row, Col, Label, FormGroup } from "reactstrap";
import AutocompleteInput from "components/AutocompleteInput";
import { autoSuggestClients } from "store/actions/process";
import TextInput from "components/TextInput";
import { get } from "lodash";
import {
  ShadowBox,
  SectionTitle,
  SectionFields,
  Asterisk,
} from "components/Elements";
export default function ClientDetails(props) {
  const {
    bem,
    isView,
    template,
    onValueChanged,
    validationState,
    process,
    intl,
  } = props;
  const requestTimer = useRef();
  const client_name = get(props, "fieldRefs.client_name", {});
  const client_id = get(props, "fieldRefs.client_id", {});
  const contract_number = get(props, "fieldRefs.contract_number", {});
  const salesperson = get(props, "fieldRefs.salesperson", {});
  const [customers, setCustomers] = useState([]);
  // useEffect(() => {
  //   if (!isView) {
  //     autoSuggestClients({
  //       q: "",
  //       template_key: template.key,
  //       cb: (items) => {
  //         setCustomers(items);
  //       },
  //     });
  //   }
  // }, []);
  return (
    <ShadowBox className={bem.e("box-client-details")}>
      <SectionTitle className={bem.e("section-title")}>
        <FormattedMessage id="process > title client details" />
      </SectionTitle>
      <SectionFields
        className={classnames(bem.e("section-fields"), {
          [bem.e("section-client-details")]: !isView,
          [bem.e("section-client-details-view")]: isView,
        })}
      >
        <Row>
          <Col xs={6} className={bem.e("reset")}>
            {isView ? (
              <FormGroup>
                <Label>{client_name.title}</Label>
                <span className={bem.e("order-view-value")}>
                  {process.data.fields[client_name.field]
                    ? process.data.fields[client_name.field].value
                    : intl.formatMessage({ id: "process > none" })}
                </span>
              </FormGroup>
            ) : (
              <FormGroup>
                <div className={bem.e("auto-complete")}>
                  <AutocompleteInput
                    label={client_name.title}
                    isRequired={client_name.mandatory}
                    value={
                      process.data.fields[client_name.field]
                        ? process.data.fields[client_name.field].value
                        : ""
                    }
                    inputProps={{
                      placeholder: intl.formatMessage({
                        id: "process > promotion > liner > enter client",
                      }),
                    }}
                    wrapperStyle={{
                      display: "block",
                    }}
                    items={customers}
                    getItemValue={(item) => item.client_name}
                    renderMenu={(children) => {
                      return (
                        <div className={children.length > 0 ? "menu" : ""}>
                          {children}
                        </div>
                      );
                    }}
                    renderItem={(item, isHighlighted) => (
                      <div
                        className={`item ${
                          isHighlighted ? "item-highlighted" : ""
                        }`}
                        key={item.id}
                      >
                        {item.client_name}
                      </div>
                    )}
                    onSelect={(value, item) => {
                      onValueChanged(client_name, value);
                      onValueChanged(client_id, item.id);
                    }}
                    onChange={(e, value) => {
                      clearTimeout(requestTimer.current);
                      requestTimer.current = setTimeout(() => {
                        autoSuggestClients({
                          q: value,
                          template_key: template.key,
                          cb: (items) => {
                            setCustomers(items);
                          },
                        });
                      }, 200);
                      onValueChanged(client_name, value);
                    }}
                    error={validationState[client_name.field] && validationState[client_name.field].validation_error}
                  />
                </div>
              </FormGroup>
            )}
          </Col>
          <Col xs={6} className={bem.e("reset")}>
            {isView ? (
              <FormGroup>
                <Label>{contract_number.title}</Label>
                <span className={bem.e("order-view-value")}>
                  {process.data.fields[contract_number.field]
                    ? process.data.fields[contract_number.field].value
                    : intl.formatMessage({ id: "process > none" })}
                </span>
              </FormGroup>
            ) : (
              <FormGroup>
                <TextInput
                  label={
                    <span>
                      {contract_number.title}
                      {contract_number.mandatory && <Asterisk>*</Asterisk>}
                    </span>
                  }
                  value={
                    process.data.fields[contract_number.field]
                      ? process.data.fields[contract_number.field].value
                      : ""
                  }
                  placeholder={intl.formatMessage({
                    id: "process > promotion > liner > enter number",
                  })}
                  onChange={(event) => {
                    onValueChanged(
                      contract_number,
                      get(event, "target.value", "")
                    );
                  }}
                />
                {validationState[contract_number.field] && (
                  <div className="alert-danger">
                    {validationState[contract_number.field].validation_error}
                  </div>
                )}
              </FormGroup>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={6} className={bem.e("reset")}>
            {isView ? (
              <FormGroup>
                <Label>{salesperson.title}</Label>
                <span className={bem.e("order-view-value")}>
                  {process.data.fields[salesperson.field]
                    ? process.data.fields[salesperson.field].value
                    : intl.formatMessage({ id: "process > none" })}
                </span>
              </FormGroup>
            ) : (
              <FormGroup>
                <TextInput
                  label={
                    <span>
                      {salesperson.title}
                      {salesperson.mandatory && <Asterisk>*</Asterisk>}
                    </span>
                  }
                  value={
                    process.data.fields[salesperson.field]
                      ? process.data.fields[salesperson.field].value
                      : ""
                  }
                  placeholder={intl.formatMessage({
                    id: "process > promotion > liner > enter name",
                  })}
                  onChange={(event) => {
                    onValueChanged(salesperson, get(event, "target.value", ""));
                  }}
                />
                {validationState[salesperson.field] && (
                  <div className="alert-danger">
                    {validationState[salesperson.field].validation_error}
                  </div>
                )}
              </FormGroup>
            )}
          </Col>
        </Row>
      </SectionFields>
    </ShadowBox>
  );
}
