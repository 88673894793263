import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { get } from "lodash";
import moment from "moment-timezone";
import { CalendarPickerIcon } from "components/CustomIcons";
import ToggleSwitch from "components/ToggleSwitch";
import FilterDropdown from "components/ClockManager/ClockCreator/Calendar/ProgrammingClock/FilterDropdown";
import QuickViewCalendar from "../QuickViewCalendar";

const ContestsQuickView = (props) => {
  const {
    bem,
    loading,
    intl,
    isView,
    isOneMonth,
    setIsOneMonth,
    showQuickView,
    setShowQuickView,
    fetchQuickViewDetails,
    startDate,
    endDate,
    data,
    onBackClick,
    onNextClick,
  } = props;
  if (isView) return null;
  const { contests = {}, dayparts = [] } = data || {};

  const selectedStation = get(
    props,
    "process.data.fields.order_stations_array.value.0"
  );
  const handleOnToggle = () => {
    if (selectedStation) {
      setShowQuickView(!showQuickView);
    }
  };
  const onSwitchToggle = () => setIsOneMonth(!isOneMonth);

  useEffect(() => {
    if (!selectedStation) {
      setShowQuickView(false);
    } else {
      // make api call to update the data of quick view
      const overrideData = {
        station_id: selectedStation.key,
        start_date: startDate,
        end_date: endDate,
      };
      fetchQuickViewDetails(overrideData);
    }
  }, [selectedStation]);
  return (
    <div className={bem.e("contests-quick-view-container")}>
      <div
        className={classnames("button-row", {
          [bem.e("quick-view-disabled")]: !selectedStation,
        })}
        onClick={handleOnToggle}
      >
        {!showQuickView ? (
          <FormattedMessage id="process > promotion > contest > quick view" />
        ) : (
          <FormattedMessage id="process > promotion > contest > close" />
        )}

        <div className="icon">
          <CalendarPickerIcon color={selectedStation ? "#795AFA" : "#829FB1"} />
        </div>
      </div>
      {showQuickView && (
        <div className={bem.e("contests-quick-view-content")}>
          <div className={bem.e("content-header")}>
            <ToggleSwitch
              leftComponent={
                <FormattedMessage id="process > promotion > contest > three month" />
              }
              rightComponent={
                <FormattedMessage id="process > promotion > contest > one month" />
              }
              switchProps={{
                checked: isOneMonth,
                onChange: onSwitchToggle,
                disabled: false,
                uncheckedIcon: false,
                checkedIcon: false,
                offColor: "#795AFA",
                onColor: "#C2D4E0",
                handleDiameter: 22,
                width: 50,
                height: 26,
              }}
            />
            <div className="year-title">{moment().format("YYYY")}</div>
            {/* TODO: hidden as the filters are not working */}
            <div style={{ visibility: "hidden" }}>
              <FilterDropdown
                name="slot_definition"
                className={bem.e("dropdown-slot")}
                placeholder={intl.formatMessage({
                  id: "process > promotion > contest > display",
                })}
                onFilter={(selected) => {
                }}
                isClearable
                // TODO: add options and values here
                value={[]}
                isShowPlaceholder
                options={[]}
              />
            </div>
          </div>
          <div className={bem.e("content-container")}>
            <QuickViewCalendar
              contests={contests}
              dayparts={dayparts}
              isOneMonth={isOneMonth}
              onNextClick={onNextClick}
              onBackClick={onBackClick}
              isLoading={loading}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ContestsQuickView;
