import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Dropzone from "react-dropzone";
import FileBlock from "./FileBlock";
import { map, size, get } from "lodash";
import { FaSpinner } from "react-icons/lib/fa";
import { FormattedMessage } from "react-intl";
import ToastManager from "components/ToastManager";
import { MdImportantDevices } from "react-icons/lib/md";
import { FileIcon } from "components/CustomIcons";
import classnames from "classnames";
import bn from "utils/bemnames";
import { isRejectedUploadFiles } from "utils/helpers";
const bem = bn.create("upload-files");

const getColor = (props) => {
  if (props.isDragReject) {
    return "#c66";
  }
  if (props.isDragActive) {
    return "#6c6";
  }
  return "#829FB1";
};

const DropArea = styled.div`
  border-color: ${(props) => getColor(props)};
  border-style: ${(props) =>
    props.isDragReject || props.isDragActive || props.mode === "view"
      ? "solid"
      : "dashed"};
`;

class UploadFiles extends React.Component {
  state = {};
  renderDropAreaText = ({ isDragActive, isDragReject }) => {
    const { mode, attachText } = this.props;
    if (mode === "view") {
      return null;
    }
    let dropDom = (
      <div className={bem.e("drop-text-wrapper")}>
        <FileIcon />
        <div className={bem.e("drop-text")}>{attachText}</div>
      </div>
    );
    if (isDragActive) {
      dropDom = (
        <div className={bem.e("drop-text")}>
          <FormattedMessage id="upload > drag file here..." />
        </div>
      );
    }
    if (isDragReject) {
      dropDom = (
        <div className={bem.e("drop-text")}>
          <FormattedMessage id="upload > one of the file is not valid" />
        </div>
      );
    }
    return dropDom;
  };

  renderFiles = () => {
    const {
      droppedFiles,
      mode,
      files,
      width,
      height,
      isRenderFilesName,
    } = this.props;
    if (isRenderFilesName) {
      if (size(files) === 0) return null;
      return (
        <div className={bem.e("files-name-wrapper")}>
          {files.map((file, index) => {
            return <span key={index}>{file.name}</span>;
          })}
        </div>
      );
    }
    if (size(files) === 0 && droppedFiles.length === 0) {
      return (
        <div className={classnames("no-files", bem.e("drop-text"))}>
          <FormattedMessage id="upload > no files" />
        </div>
      );
    }
    const allFiles = files;
    return (
      <div className={bem.e("files-wrapper")}>
        {allFiles.map((file, index) => (
          <FileBlock
            file={file}
            key={index}
            width={width}
            height={height}
            showRemoveIcon={mode !== "view"}
            onDeleteClick={() => this.props.onDeleteFile(allFiles, index)}
            onDownloadClick={() =>
              this.props.onDownloadFile(file.preview || file.path)
            }
          />
        ))}
      </div>
    );
  };

  render() {
    const {
      onDropFiles,
      mode,
      disabled,
      isHideFilesDragArea,
      files,
      intl,
      ...rest
    } = this.props;
    const maxSizeMB = rest.maxSize
      ? parseInt((rest.maxSize / (1024 * 1024)).toFixed(2))
      : null;

    return (
      <Dropzone
        disabled={mode === "view" || disabled}
        className={bem.b()}
        onDropRejected={(rejectedFiles) => {
          if (rejectedFiles.length > 0) {
            const message = map(rejectedFiles, (file) => {
              if (maxSizeMB && file.size > rest.maxSize) {
                return `${file.name}: ${intl.formatMessage(
                  {
                    id: `upload > please upload file size less than :maximum MB`,
                  },
                  {
                    maximum: maxSizeMB,
                  }
                )}`;
              }
              return `${file.name}: ${intl.formatMessage({
                id: `upload > File is not valid`,
              })}`;
            });
            ToastManager.show({
              title: <MdImportantDevices />,
              message: message.join(", "),
              autoDismiss: 1.5,
              level: "error",
            });
          }
        }}
        onDrop={(acceptedFiles) => {
          if (acceptedFiles.length === 0) {
            return;
          }
          const rejectedFiles = acceptedFiles.filter((file) => {
            return isRejectedUploadFiles(file.name);
          });
          if (rejectedFiles.length > 0) {
            // const message = map(rejectedFiles, (file) => {
            //   return file.name;
            // });
            ToastManager.show({
              title: <MdImportantDevices />,
              message: "This file format is not supported",
              autoDismiss: 1.5,
              level: "error",
            });
            return;
          }
          let newFiles = acceptedFiles;
          if (this.props.maxFiles) {
            if (acceptedFiles.length + files.length > this.props.maxFiles) {
              ToastManager.show({
                title: <MdImportantDevices />,
                message:
                  "A maximum of " + this.props.maxFiles + " files is allowed",
                autoDismiss: 1.5,
                level: "error",
              });

              return;
            }
            // newFiles = newFiles.slice(0, this.props.maxFiles);
          }
          if (newFiles.length > 0) {
            onDropFiles(newFiles);
          }
        }}
        accept={
          get(this.props, "mimes", []).length > 0 ? this.props.mimes : null
        }
        multiple={this.props.multiple}
        {...rest}
      >
        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
          return (
            <DropArea
              mode={mode}
              isDragReject={isDragReject}
              isDragActive={isDragActive}
              className={classnames(rest.className, bem.e("drop-area"))}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {!isHideFilesDragArea && this.renderFiles()}
                {this.props.isLoading && (
                  <div className={"cr-icon-spin-container"}>
                    <FaSpinner size="40" className={"cr-icon-spin"} />
                  </div>
                )}
              </div>
              {this.renderDropAreaText({ isDragActive, isDragReject })}
            </DropArea>
          );
        }}
      </Dropzone>
    );
  }
}

UploadFiles.propTypes = {
  attachText: PropTypes.any,
  droppedFiles: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.instanceOf(File)),
    PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        type: PropTypes.string,
        path: PropTypes.string,
      })
    ),
  ]),
  mode: PropTypes.oneOf(["view", "edit"]),
  disabled: PropTypes.bool,
};

UploadFiles.defaultProps = {
  attachText: "Attach Files",
  droppedFiles: [],
  disabled: false,
  isHideFilesDragArea: false,
  maxFiles: null,
  isRenderFilesName: false,
};

export default UploadFiles;
