import { get, omit, isFunction } from "lodash";

import history from "../../components/History";
import * as Types from "../types/resources";
import * as Const from "../../utils/constants";
import Api from "../../utils/api";
import URL from "../../utils/urls";

export function formValueChanged(payload) {
  return {
    type: Types.RESOURCE_VALUE_CHANGED,
    payload
  };
}

export const initNewResource = payload => dispatch => {
  dispatch({
    type: Types.INIT_NEW_RESOURCE,
    payload
  });
};

export const setActiveTab = payload => dispatch => {
  dispatch({
    type: Types.SET_ACTIVE_TAB,
    payload
  });
};

export const setActiveEditTab = payload => dispatch => {
  dispatch({
    type: Types.SET_TAB_TO_EDIT,
    payload
  });
};
//get all resources
export const getResource = id => async dispatch => {
  let errorStr = "";
  try {
    dispatch({ type: Types.FETCH_RESOURCE_REQUEST, payload: { id } });
    let response = await Api.doCall(Const.GET_RESOURCE(id), "GET");
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch(fetchResourcesSucces({ id, data: response.data }));
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch(fetchResourcesError({ id, error: errorStr }));
};

//save resources values to ES
export const saveFormValues = (data, success_callback) => async dispatch => {
  let errorStr = "";
  try {
    dispatch({ type: Types.ADD_RESOURCE_REQUEST });
    //api call for store data
    let response = await Api.doCall(Const.ADD_RESOURCES(), "POST", data);
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        //response data
        let responseData = JSON.parse(JSON.stringify(response.data));
        dispatch({ type: Types.ADD_RESOURCE_SUCCESS, payload: responseData });
        // refresh resources when adding new
        dispatch(forgetResources());

        if (isFunction(success_callback)) {
          success_callback();
        } else {
          //redirect to list page
          history.push(URL.RESOURCES({ filter: "resources_list" }));
        }
        return;
      }
      //error data
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  //return error
  dispatch({ type: Types.ADD_RESOURCE_ERROR, payload: errorStr });
};

//update resources values to ES
export const updateResources = (id, data, cb) => async dispatch => {
  let errorStr = "";
  try {
    dispatch({ type: Types.UPDATE_RESOURCE_REQUEST });
    //api call for store data
    let response = await Api.doCall(Const.GET_RESOURCE(id), "PUT", data);
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        //response data
        let responseData = JSON.parse(JSON.stringify(response.data));
        dispatch({
          type: Types.UPDATE_RESOURCE_SUCCESS,
          payload: responseData
        });
        // refresh resources when adding new
        dispatch(forgetResources());
        history.push(URL.RESOURCES({ filter: "resources_list" }));
        if (isFunction(cb)) cb();
        return;
      }
      //error data
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  //return error
  dispatch({ type: Types.UPDATE_RESOURCE_ERROR, payload: errorStr });
};

export function saveFormValuesSuccess(payload) {
  return {
    type: Types.SAVE_FORM_VALUES_SUCCESS,
    payload
  };
}

export function forgetResources(payload) {
  return {
    type: Types.FORGET_RESOURCES,
    payload
  };
}

export function saveFormValuesError(payload) {
  return {
    type: Types.SAVE_FORM_VALUES_ERROR,
    payload
  };
}

export function fetchResourceSuccess(payload) {
  return {
    type: Types.FETCH_RESOURCE_SUCCESS,
    payload
  };
}

export function fetchResourceError(payload) {
  return {
    type: Types.FETCH_RESOURCE_ERROR,
    payload
  };
}

export const deleteResource = id => async dispatch => {
  let errorStr = "";
  try {
    dispatch({ type: Types.DELETE_RESOURCE_REQUEST });
    let response = await Api.doCall(Const.GET_RESOURCE(id), "DELETE");
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({ type: Types.DELETE_RESOURCE_SUCCESS, payload: id });
        history.push(URL.RESOURCES({ filter: "resources_list" }));
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.DELETE_RESOURCE_ERROR, payload: errorStr });
};

export const getResourcesAll = state => async dispatch => {
  let errorStr = "";
  try {
    dispatch({ type: Types.FETCH_RESOURCE_REQUEST });
    let response = await Api.doCall(`${Const.GET_RESOURCES_ALL()}`, "GET");
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch(fetchResourcesSucces(response.data));
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch(fetchResourcesError({ data: errorStr }));
};

export const getResources = payload => async dispatch => {
  let errorStr = "";
  try {
    dispatch({ type: Types.FETCH_RESOURCES_REQUEST, payload });
    const url =
      Const.GET_RESOURCES(payload.filter) +
      "?sort=" +
      payload.sort +
      "&page=" +
      payload.page +
      "&rpp=" +
      payload.rpp +
      "&search=" +
      payload.search +
      "&category=" +
      get(payload, "category", "") +
      "&stations=" +
      get(payload, "stations", "");
    let response = await Api.doCall(url, "GET");
    if (response.status === 200) {
      if (!response.data.error) {
        dispatch(
          fetchResourcesSucces({
            ...omit(payload, ["page"]),
            page: get(response, "data.page", 1),
            pages: get(response, "data.pages", 1),
            data: get(response, "data.items", [])
          })
        );
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch(fetchResourcesError({ filter: payload.filter, error: errorStr }));
};

export function fetchResourcesSucces(payload) {
  return {
    type: Types.FETCH_RESOURCES_SUCCESS,
    payload
  };
}

export function fetchResourcesError(payload) {
  return {
    type: Types.FETCH_RESOURCES_ERROR,
    payload
  };
}

export function resetTab() {
  return {
    type: Types.RESET_TAB
  };
}
