import * as React from "react";
import { Button, Form, FormGroup, Row, Col, Label } from "reactstrap";
import { FormattedMessage } from "react-intl";
import * as Yup from "yup";
import { Formik } from "formik";
import TextInput from "components/TextInput";
import classnames from "classnames";
import bn from "utils/bemnames";
import { get, map, filter, find } from "lodash";
import { Asterisk } from "components/Elements";
import { formatDisplayDate } from "utils/helpers";
import moment from "moment";
import ConfirmationModal from "components/ConfirmationModal";
import PropTypes from "prop-types";
const bem = bn.create("order-details-schedule");
class OrderDetailsSchedule extends React.Component {
  formikRef = React.createRef();
  constructor(props) {
    super(props);
    this.random_name_suffix = "_rnd_" + Math.random();
    this.state = {
      isOpenSuccessModal: false,
    };
  }

  onFormSubmit = (values, formikHelpers) => {
    if (!this.isValid(values)) {
      formikHelpers.setSubmitting(false);
      return false;
    }
    const { process, auth } = this.props;
    const stationsData = get(auth, "info.stations", []);
    const orderStationsData = get(process, "_source.order_stations", [])
      .map((item) => {
        const station = find(stationsData, (i) => i.station_key === item);
        return station;
      })
      .filter((station) => station);
    const isAllStationsEnableContinuityQueue =
      filter(orderStationsData, (station) =>
        get(station, "production_settings.continuity_queue", false)
      ).length === orderStationsData.length;
    this.props.reschedule(
      {
        process_key: process._id,
        success_callback: (key) => {
          if (this.props.isShowSuccessModal) {
            this.setState({
              isOpenSuccessModal: true,
            });
            setTimeout(() => {
              this.props.close();
              this.props.history.push(
                `/processes/edit/${get(process, "_source.template_key")}/${key}`
              );
            }, 1500);
          } else {
            this.props.close();
            this.props.history.push(
              `/processes/edit/${get(process, "_source.template_key")}/${key}`
            );
          }
        },
        post_data: {
          order_start_date: values.start_date,
          order_end_date: values.end_date,
          original_order_start_date: process._source.order_start_date,
          original_order_end_date: process._source.order_end_date,
          continuity_status: "rejected",
          process_step: "continuity",
          next_users: !isAllStationsEnableContinuityQueue
            ? map(get(process, "_source.role_continuity.users", []), (user) =>
                parseInt(user.id)
              )
            : [],
        },
      },
      () => {
        formikHelpers.setSubmitting(false);
      }
    );
  };
  isValid = (values) => {
    const { process } = this.props;
    const { start_date, end_date } = values;
    const contract_start_date = get(process, "_source.contract_start_date", "");
    const contract_end_date = get(process, "_source.contract_end_date", "");
    if (
      (contract_start_date &&
        Date.parse(start_date) < Date.parse(contract_start_date)) ||
      (contract_end_date &&
        Date.parse(start_date) > Date.parse(contract_end_date))
    ) {
      this.formikRef.setFieldError(
        "start_date",
        this.props.intl.formatMessage({
          id: "process > please enter a date in the contract dates range",
        })
      );
      return false;
    }

    if (Date.parse(end_date) < Date.parse(start_date)) {
      this.formikRef.setFieldError(
        "end_date",
        this.props.intl.formatMessage({
          id: "process > please enter a date later than the start date",
        })
      );
      return false;
    }

    if (
      (contract_start_date &&
        Date.parse(end_date) < Date.parse(contract_start_date)) ||
      (contract_end_date &&
        Date.parse(end_date) > Date.parse(contract_end_date))
    ) {
      this.formikRef.setFieldError(
        "end_date",
        this.props.intl.formatMessage({
          id: "process > please enter a date in the contract dates range",
        })
      );
      return false;
    }
    return true;
  };
  render() {
    const { process } = this.props;
    const contract_end_date = get(process, "_source.contract_end_date");
    return (
      <div className={bem.b()}>
        <Formik
          ref={(ref) => (this.formikRef = ref)}
          enableReinitialize
          initialValues={{
            start_date: get(
              process,
              "_source.order_start_date",
              moment().format("YYYY-MM-DD")
            ),
            end_date: get(
              process,
              "_source.order_end_date",
              moment().format("YYYY-MM-DD")
            ),
            s_button: "schedule_again",
          }}
          validationSchema={Yup.object().shape({
            start_date: Yup.string().required(
              this.props.intl.formatMessage({
                id: "validate > start date is required",
              })
            ),
            end_date: Yup.string().required(
              this.props.intl.formatMessage({
                id: "validate > end date is required",
              })
            ),
          })}
          onSubmit={this.onFormSubmit}
        >
          {(formProps) => {
            const errors = formProps.errors;
            const touched = formProps.touched;
            const values = formProps.values;
            const isSubmitting = formProps.isSubmitting;
            return (
              <Form onSubmit={formProps.handleSubmit} noValidate>
                <input type="hidden" value="prayer" />
                <Row>
                  <Col xs={12}>
                    <FormGroup className={bem.e("form-group-view")}>
                      <Label>
                        <FormattedMessage id="process > field client name" />
                      </Label>
                      <span className={bem.e("order-view-value")}>
                        {process._source.client_name ? (
                          process._source.client_name
                        ) : (
                          <FormattedMessage id="process > none" />
                        )}
                      </span>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <FormGroup className={bem.e("form-group-view")}>
                      <Label>
                        <FormattedMessage id="process > field contract start date" />
                      </Label>
                      <span className={bem.e("order-view-value")}>
                        {process._source.contract_start_date ? (
                          formatDisplayDate(process._source.contract_start_date)
                        ) : (
                          <FormattedMessage id="process > none" />
                        )}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup className={bem.e("form-group-view")}>
                      <Label>
                        <FormattedMessage id="process > field contract end date" />
                      </Label>
                      <span className={bem.e("order-view-value")}>
                        {process._source.contract_end_date ? (
                          formatDisplayDate(process._source.contract_end_date)
                        ) : (
                          <FormattedMessage id="process > none" />
                        )}
                      </span>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <FormGroup>
                      <TextInput
                        label={
                          <span>
                            <FormattedMessage id="process > field order start date" />
                            <Asterisk>*</Asterisk>
                          </span>
                        }
                        type="date"
                        name={"start_date" + this.random_name_suffix}
                        min={moment().format("YYYY-MM-DD")}
                        max={
                          contract_end_date
                            ? moment(contract_end_date).format("YYYY-MM-DD")
                            : undefined
                        }
                        value={values.start_date}
                        error={touched.start_date && errors.start_date}
                        onChange={(e) =>
                          formProps.setFieldValue("start_date", e)
                        }
                        autoComplete="new-password"
                        allowShowIcon
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <TextInput
                        label={
                          <span>
                            <FormattedMessage id="process > field order end date" />
                            <Asterisk>*</Asterisk>
                          </span>
                        }
                        type="date"
                        name={"end_date" + this.random_name_suffix}
                        min={get(
                          values,
                          "start_date",
                          moment().format("YYYY-MM-DD")
                        )}
                        max={
                          contract_end_date
                            ? moment(contract_end_date).format("YYYY-MM-DD")
                            : undefined
                        }
                        openToDate={
                          get(values, "end_date")
                            ? new Date(
                                moment(
                                  get(
                                    values,
                                    "end_date" // open to entered end date if available
                                  )
                                ).format("YYYY/MM/DD")
                              )
                            : get(values, "start_date")
                            ? new Date(
                                moment(
                                  get(
                                    values,
                                    "start_date" // open to entered start date if available
                                  )
                                ).format("YYYY/MM/DD")
                              )
                            : new Date() // open to today
                        }
                        value={values.end_date}
                        error={touched.end_date && errors.end_date}
                        onChange={(e) => formProps.setFieldValue("end_date", e)}
                        autoComplete="new-password"
                        allowShowIcon
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <div className={bem.e("buttons")}>
                  <Button
                    type="submit"
                    color="blue"
                    className={classnames("btn-radius", bem.e("btn-submit"))}
                  >
                    {isSubmitting ? (
                      <FormattedMessage id="process > button submitting" />
                    ) : (
                      <FormattedMessage id="process > button submit" />
                    )}
                  </Button>
                  <Button
                    color="blue"
                    outline
                    className={classnames("btn-radius", bem.e("btn-cancel"))}
                    onClick={() => this.props.close()}
                  >
                    <FormattedMessage id="process > button cancel" />
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
        <ConfirmationModal
          isOpen={this.state.isOpenSuccessModal}
          onToggle={() => this.setState({ isOpenSuccessModal: false })}
          onCancel={() => this.setState({ isOpenSuccessModal: false })}
          state="success"
          title={<FormattedMessage id={`process > title submitted`} />}
        />
      </div>
    );
  }
}
OrderDetailsSchedule.propTypes = {
  isShowSuccessModal: PropTypes.bool,
};
OrderDetailsSchedule.defaultProps = {
  isShowSuccessModal: false,
};
export default OrderDetailsSchedule;
