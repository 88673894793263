import * as Types from "../types/imports";
import { LOGOUT_USER } from "../types/auth";
const initialState = {
  import:{
    is_imported: false,
    data: {},
    error: null,
    loading: false
  },
  purge:{
    is_purged: false,
    data: {},
    error: null,
    loading: false
  },
  indexes:{
    is_deleted: false,
    data: {},
    error: null,
    loading: false
  },
  index:{
    is_deleted: false,
    data: {},
    error: null,
    loading: false
  }
};

export default function(state = initialState, action) {
  switch (action.type) {

    // Start Import
    case Types.IMPORT_ACTION_REQUEST: {
      return {
        ...state,
        import:{
          is_imported: false,
          data: {},
          error: null,
          loading: true
        }
      };
    }
    case Types.IMPORT_ACTION_SUCCESS: {
      return {
        ...state,
        import:{
           data: {},
           error: null,
           loading: false,
           is_imported:true
        }
      };
    }
    case Types.IMPORT_ACTION_ERROR: {
      return {
        ...state,
        import:{
          is_imported: false,
          loading: false,
          error: action.payload
        }
      };
    }
    // purge all records
    case Types.PURGE_ACTION_REQUEST: {
      return {
        ...state,
        purge:{
          is_purged: false,
          data: {},
          error: null,
          loading: true
        }
      };
    }
    case Types.PURGE_ACTION_SUCCESS: {
      return {
        ...state,
        purge:{
           data: {},
           error: null,
           loading: false,
           is_purged:true
        }
      };
    }
    case Types.PURGE_ACTION_ERROR: {
      return {
        ...state,
        purge:{
          is_purged: false,
          loading: false,
          error: action.payload
        }
      };
    }
    // Delete indexes
    case Types.DELETE_INDEXES_REQUEST: {
      return {
        ...state,
        indexes:{
          is_deleted: false,
          data: {},
          error: null,
          loading: true
        }
      };
    }
    case Types.DELETE_INDEXES_SUCCESS: {
      return {
        ...state,
        indexes:{
           data: {},
           error: null,
           loading: false,
           is_deleted:true
        }
      };
    }
    case Types.DELETE_INDEXES_ERROR: {
      return {
        ...state,
        indexes:{
          is_deleted: false,
          loading: false,
          error: action.payload
        }
      };
    }


    // Delete index
    case Types.DELETE_INDEX_REQUEST: {
      return {
        ...state,
        index:{
          is_deleted: false,
          data: {},
          error: null,
          loading: true
        }
      };
    }
    case Types.DELETE_INDEX_SUCCESS: {
      return {
        ...state,
        index:{
           data: {},
           error: null,
           loading: false,
           is_deleted:true
        }
      };
    }
    case Types.DELETE_INDEX_ERROR: {
      return {
        ...state,
        index:{
          is_deleted: false,
          loading: false,
          error: action.payload
        }
      };
    }
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
}
