import React from "react";
import { Formik } from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { isEqual } from "lodash";
import { injectIntl } from "react-intl";
import { Row, Col, Button, Form, FormGroup } from "reactstrap";
import { FormattedMessage } from "react-intl";
import BlockingComponent from "components/BlockingComponent";

import TextInput from "components/TextInput";
import { Asterisk } from "components/Elements";
import Dropdown from "components/Dropdown";
import TextInputFormatted from "components/TextInputFormatted";
import SelectStations from "components/SelectStations";
import { resourceCategoryList } from "utils/config";
import bn from "utils/bemnames";
const bem = bn.create("ps-form");
const ResourceForm = ({
  updateValues,
  isUpdate,
  isView,
  isLoading,
  intl,
  onFormSubmit,
  onCancel,
  removeResource,
  setShouldBlockNavigation,
  shouldBlockNavigation
}) => {
  const customButtonMargin = {
    marginRight: "10px"
  };
  const customButtonFloat = {
    float: "right"
  };
  const initialValues = {
    id: updateValues.id || "",
    title: updateValues.title || "",
    subtitle: updateValues.subtitle || "",
    description: updateValues.description || "",
    quantity: updateValues.quantity || "",
    unit_value: updateValues.unit_value || "",
    supplier: updateValues.supplier || "",
    category: updateValues.category
      ? resourceCategoryList.find(
          category => category.value === updateValues.category
        )
      : "",
    station_id: updateValues.station_id || [],
    isUpdate
  };
  const form = (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        isUpdate: Yup.bool(),
        title: Yup.string().required(
          intl.formatMessage({ id: "resources validate > title is required" })
        ),
        subtitle: Yup.string().required(
          intl.formatMessage({
            id: "resources validate > subtitle is required"
          })
        ),
        description: Yup.string().required(
          intl.formatMessage({
            id: "resources validate > description is required"
          })
        ),
        id: Yup.string(),
        quantity: Yup.string().required(
          intl.formatMessage({
            id: "resources validate > quantity is required"
          })
        ),
        category: Yup.string().required(
          intl.formatMessage({
            id: "resources validate > categories is required"
          })
        ),
        station_id: Yup.array().required(
          intl.formatMessage({ id: "resources validate > station is required" })
        )
      })}
      onSubmit={onFormSubmit}
    >
      {formProps => {
        const errors = formProps.errors;
        const touched = formProps.touched;
        const values = formProps.values;
        const isChanged = !isEqual(initialValues, values);
        if (isChanged !== shouldBlockNavigation) {
          setTimeout(() => setShouldBlockNavigation(isChanged), 20);
        }
        return (
          <Form
            onSubmit={formProps.handleSubmit}
            noValidate
            className={bem.b()}
          >
            <div className={bem.e("content-form")}>
              <Row>
                <Col>
                  <input
                    type="hidden"
                    name="id"
                    value={values.id}
                    error={touched.id && errors.id}
                    onChange={formProps.handleChange}
                    onBlur={formProps.handleBlur}
                    disabled={isView}
                  />
                  <FormGroup>
                    <TextInput
                      label={
                        <label>
                          <FormattedMessage id="resources > form title" />
                          <Asterisk>*</Asterisk>
                        </label>
                      }
                      type="text"
                      name="title"
                      placeholder={intl.formatMessage({
                        id: "resources > enter resource title"
                      })}
                      value={values.title}
                      error={touched.title && errors.title}
                      onChange={formProps.handleChange}
                      onBlur={formProps.handleBlur}
                      disabled={isView}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <TextInput
                      label={
                        <label>
                          <FormattedMessage id="resources > form subtitle" />
                          <Asterisk>*</Asterisk>
                        </label>
                      }
                      type="text"
                      name="subtitle"
                      placeholder={intl.formatMessage({
                        id: "resources > enter resource subtitle"
                      })}
                      value={values.subtitle}
                      error={touched.subtitle && errors.subtitle}
                      onChange={formProps.handleChange}
                      onBlur={formProps.handleBlur}
                      disabled={isView}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <TextInput
                  label={
                    <label>
                      <FormattedMessage id="resources > form description" />
                      <Asterisk>*</Asterisk>
                    </label>
                  }
                  required
                  type="textarea"
                  name="description"
                  placeholder=""
                  aria-multiline="true"
                  value={values.description}
                  onChange={formProps.handleChange}
                  onBlur={formProps.handleBlur}
                  disabled={isView}
                  error={touched.description && errors.description}
                />
              </FormGroup>
              <FormGroup>
                <Dropdown
                  label={
                    <label>
                      <FormattedMessage id="resources > form category" />
                      <Asterisk>*</Asterisk>
                    </label>
                  }
                  placeholder={intl.formatMessage({
                    id: "resources > select category"
                  })}
                  name="category"
                  error={touched.category && errors.category}
                  onChange={selectedOption => {
                    formProps.setFieldValue("category", selectedOption);
                  }}
                  value={values.category}
                  options={resourceCategoryList}
                  closeMenuOnSelect
                  disabled={isView}
                />
              </FormGroup>
              <FormGroup>
                <SelectStations
                  name="station_id"
                  contract={true}
                  item={{ field: "station_id" }}
                  onChange={value => {
                    formProps.setFieldValue("station_id", value);
                  }}
                  checked={{value: values.station_id}}
                  validationState={errors}
                />
              </FormGroup>
              <Row>
                <Col>
                  <FormGroup>
                    <TextInput
                      label={
                        <span>
                          <FormattedMessage id="resources > form quantity" />
                          <Asterisk>*</Asterisk>
                        </span>
                      }
                      type="number"
                      name="quantity"
                      placeholder={intl.formatMessage({
                        id: "resources > enter resource quantity"
                      })}
                      value={values.quantity}
                      error={touched.quantity && errors.quantity}
                      onChange={formProps.handleChange}
                      onBlur={formProps.handleBlur}
                      disabled={isView}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <TextInputFormatted
                      label={
                        <FormattedMessage id="resources > form unit_value" />
                      }
                      prefix={"$"}
                      allowNegative={false}
                      thousandSeparator={true}
                      type="number"
                      name="unit_value"
                      placeholder={intl.formatMessage({
                        id: "resources > enter resource unit value"
                      })}
                      value={values.unit_value}
                      onChange={value =>
                        formProps.setFieldValue("unit_value", value)
                      }
                      onBlur={formProps.handleBlur}
                      disabled={isView}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <TextInput
                      label={
                        <label>
                          <FormattedMessage id="resources > form supplier" />
                        </label>
                      }
                      type="text"
                      name="supplier"
                      placeholder={intl.formatMessage({
                        id: "resources > enter resource supplier"
                      })}
                      value={values.supplier}
                      error={touched.supplier && errors.supplier}
                      onChange={formProps.handleChange}
                      onBlur={formProps.handleBlur}
                      disabled={isView}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <div className={bem.e("form-buttons")}>
              {/* {isView && (
                <div
                  className="position-relative form-group"
                  style={{ display: "inline-block" }}
                >
                  <Button
                    color="blue"
                    disabled={isLoading}
                    style={customButtonMargin}
                    onClick={e => {
                      this.editClick(e);
                    }}
                  >
                    <FormattedMessage id={`resources > button edit`} />
                  </Button>
                </div>
              )} */}

              <FormGroup>
                {!isView && (
                  <Button
                    color="blue"
                    type="submit"
                    className="btn btn-radius"
                    disabled={isLoading}
                    style={customButtonMargin}
                  >
                    <FormattedMessage
                      id={`process > button ${
                        !isLoading ? "submit" : "submitting"
                      }`}
                    />
                  </Button>
                )}
                <Button
                  color="blue"
                  outline
                  className="btn btn-radius"
                  onClick={onCancel}
                  style={customButtonMargin}
                >
                  <FormattedMessage id={`resources > button cancel`} />
                </Button>
                {isUpdate && !isView && (
                  <Button
                    color="blue"
                    outline
                    className="btn btn-radius"
                    onClick={() => {
                      removeResource(values.id);
                    }}
                    style={customButtonFloat}
                  >
                    <FormattedMessage id={`resources > button delete`} />
                  </Button>
                )}
              </FormGroup>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
  return (
    <React.Fragment>
      {form}
      <BlockingComponent
        isBlocked={shouldBlockNavigation}
        modalProps={{
          isShowConfirm: true,
          isShowDiscard: false,
          isCancelConfirmedNavigation: true,
          onCancel: () => {
            setShouldBlockNavigation(false); // reset
          },
          // onConfirm: () => {
          //   const buttonSubmit = document.getElementById(`btn-submit`);
          //   // trigger click to submit the form
          //   if (buttonSubmit) buttonSubmit.click();
          // },
        }}
      />
    </React.Fragment>
  )
  // return (
  //   <WarnAboutUnsavedChanges when={shouldBlockNavigation} children={form} />
  // );
};
ResourceForm.propTypes = {
  updateValues: PropTypes.any.isRequired,
  isUpdate: PropTypes.bool.isRequired,
  isView: PropTypes.bool,
  stationsList: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  removeResource: PropTypes.func.isRequired,
  isModal: PropTypes.bool
};
ResourceForm.defaultProps = {
  updateValues: {},
  isUpdate: false,
  isView: false,
  stationsList: [],
  isLoading: false,
  onFormSubmit: () => {},
  onCancel: () => {},
  removeResource: () => {},
  isModal: false
};
export default injectIntl(ResourceForm);
