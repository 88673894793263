import * as React from "react";
import { Asterisk } from "components/Elements";
import * as Yup from "yup";
import { Formik } from "formik";
import TextInput from "components/TextInput";
import { Form, Button, Col, Row, FormGroup, Label } from "reactstrap";
import CustomMultiSelectDropdown from "components/CustomMultiSelectDropdown";
import TextareaEvaluator from "components/TextareaEvaluator";
import { setScriptField } from "utils/helpers";
import { get, find, includes, map } from "lodash";
import { EyeIcon } from "components/CustomIcons";
import TemplatePreviewModal from "../TemplatePreviewModal";
import { templateTags, prizeReleaseTemplateTags } from "utils/config";
import ConfirmationModal from "components/ConfirmationModal";
import { FormattedMessage } from "react-intl";
import MergeTags from "../MergeTags";
import bn from "utils/bemnames";
import Dropdown from "components/Dropdown";
import ToastManager from "components/ToastManager";
import { CloseSmallIcon } from "components/CustomIcons";
import OverrideStationsModal from "../OverrideStationsModal";
const bem = bn.create("system-template-form");
export const getCategories = ({ intl }) => {
  return [
    {
      value: "winner_announcement",
      label: intl.formatMessage({
        id: "system templates > winner announcement",
      }),
    },
    {
      value: "received_form_confirmation",
      label: intl.formatMessage({
        id: "system templates > received form confirmation",
      }),
    },
    {
      value: "prize_release",
      label: intl.formatMessage({ id: "system templates > prize release" }),
    },
    {
      value: "prize_fulfillment",
      label: intl.formatMessage({ id: "system templates > prize fulfillment" }),
    },
    {
      value: "other",
      label: intl.formatMessage({ id: "system templates > other" }),
      data: {
        className: "option-other",
      },
    },
  ];
};
const getRecipients = ({ intl }) => {
  return [
    {
      value: "all_winners",
      label: intl.formatMessage({ id: "system templates > all winners" }),
    },
    {
      value: "winners_without_completed_fulfillment_form",
      label: intl.formatMessage({
        id: "system templates > winners without completed fulfillment form",
      }),
    },
    {
      value: "winner_without_signed_prize_release",
      label: intl.formatMessage({
        id: "system templates > winners without signed prize release",
      }),
    },
    {
      value: "winners_without_signed_w9s",
      label: intl.formatMessage({
        id: "system templates > winners without signed W-9s",
      }),
    },
    {
      value: "winners_with_unfulfilled_prizes",
      label: intl.formatMessage({
        id: "system templates > winners with unfulfilled prizes",
      }),
    },
  ];
};
function getInitialValues(props) {
  const { type, currentStation, systemTemplate } = props;
  const isWinnerNotification = type === "winner_notification";
  const isRejectionNotification = type === "rejection_notification";
  let values = {
    name: get(systemTemplate, "name", ""),
    type,
    content: get(systemTemplate, "content", "") || "<p><br></p>",
    copy_to_stations: get(systemTemplate, "copy_to_stations", []),
    station_id: get(currentStation, "key", null),
  };
  if (isWinnerNotification) {
    values = {
      ...values,
      name: get(systemTemplate, "name", ""),
      subject_line: get(systemTemplate, "subject_line", ""),
      recipients: get(systemTemplate, "recipients", ""),
      category: get(systemTemplate, "category", ""),
    };
  }
  if (isRejectionNotification) {
    values = {
      ...values,
      subject_line: get(systemTemplate, "subject_line", ""),
    };
  }

  if (systemTemplate) {
    values = {
      ...values,
      id: systemTemplate.id,
    };
  }
  return values;
}
const TemplateForm = (props) => {
  const { type, intl } = props;
  const isWinnerNotification = type === "winner_notification";
  const isRejectionNotification = type === "rejection_notification";
  const templateTagsConfig =
    type === "release_form" ? prizeReleaseTemplateTags : templateTags;
  const isNormalForm = ![
    "winner_notification",
    "rejection_notification",
  ].includes(type);
  const refPreviewModal = React.useRef(null);
  const formikRef = React.useRef(null);
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = React.useState(
    false
  );
  const [isOpenOverrideModal, setIsOpenOverrideModal] = React.useState(false);
  const { action, stations } = props;
  const formSchema = Yup.object().shape({
    type: Yup.string().nullable(),
    name: Yup.string().required(
      intl.formatMessage({ id: "validate > name is required" })
    ),
    subject_line: Yup.string().when("type", {
      is: (value) =>
        value === "winner_notification" || value === "rejection_notification",
      then: Yup.string().required(
        intl.formatMessage({ id: "validate > subject is required" })
      ),
      otherwise: Yup.string().nullable(),
    }),
    category: Yup.string().when("type", {
      is: (value) => value === "winner_notification",
      then: Yup.string().required(
        intl.formatMessage({ id: "validate > category is required" })
      ),
      otherwise: Yup.string().nullable(),
    }),
    content: Yup.string().nullable(),
    copy_to_stations: Yup.mixed().nullable(),
    duplicate_stations: Yup.mixed().nullable(),
  });

  const initialValues = getInitialValues(props);

  const categoryOptions = getCategories({ intl });
  const recipientOptions = getRecipients({ intl });
  const stationOptions = stations
    .filter((s) => s.key !== initialValues.station_id)
    .map((station) => {
      return {
        label: station.call_letters,
        value: station.key,
        data: {
          firstLabel: station.call_letters,
          lastLabel: station.name,
        },
      };
    });
  const onSaveTemplate = async (values, { resetForm }) => {
    await props.onSubmit(values);
    resetForm();
    if (action === "create") {
      props.onClose();
    }
  };
  const onSubmitForm = async (values, { resetForm, setFieldValue }) => {
    const duplicateParams = {
      template_name: values.name,
      type,
      stations: [...values.copy_to_stations, props.currentStation.key],
    };
    if (values.id) {
      duplicateParams.update_id = values.id;
    }
    await props.onCheckDuplicateTemplate(
      duplicateParams,
      async (response) => {
        if (response && response.length > 0) {
          const duplicateStations = stations
            .filter((item) => {
              return includes(response, item.key);
            })
            .map((item) => ({
              label: item.call_letters,
              value: item.key,
              data: item,
            }));
          setFieldValue("duplicate_stations", duplicateStations);
          setIsOpenOverrideModal(true);
        } else {
          onSaveTemplate(values, { resetForm });
        }
      }
    );
  };
  return (
    <div className={bem.b()}>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={formSchema}
        ref={formikRef}
        onSubmit={onSubmitForm}
      >
        {(formProps) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
          } = formProps;
          const selectedStations = stations
            .filter((item) => {
              return includes(values.copy_to_stations, item.key);
            })
            .map((item) => ({
              label: item.call_letters,
              value: item.key,
              data: item,
            }));
          return (
            <Form
              onSubmit={formProps.handleSubmit}
              noValidate
              onChange={() => {
                props.setShouldBlockNavigation(true);
              }}
            >
              <div className="system-template-box">
                <div className={bem.e("head-preview")}>
                  <div
                    className={bem.e("preview")}
                    onClick={() => {
                      refPreviewModal.current.toggle({
                        ...values,
                        currentStation: props.currentStation,
                      });
                    }}
                  >
                    <EyeIcon color="#795AFA" width={24} height={16} />
                    <FormattedMessage id="system templates > preview" />
                  </div>
                </div>
                {isWinnerNotification && (
                  <Row>
                    <Col xs={6}>
                      <FormGroup>
                        <TextInput
                          label={
                            <span>
                              <FormattedMessage id="system templates > template name" />
                              <Asterisk> *</Asterisk>
                            </span>
                          }
                          type="text"
                          name="name"
                          placeholder={intl.formatMessage({
                            id: "system templates > placeholder template name",
                          })}
                          value={values.name}
                          error={touched.name && errors.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={6}>
                      <FormGroup>
                        <Dropdown
                          label={
                            <span>
                              <FormattedMessage id="system templates > category" />
                              <Asterisk> *</Asterisk>
                            </span>
                          }
                          placeholder={intl.formatMessage({
                            id: "system templates > placeholder category",
                          })}
                          name="category"
                          onChange={(option) => {
                            formProps.setFieldValue("category", option.value);
                            props.setShouldBlockNavigation(true);
                          }}
                          // defaultMenuIsOpen
                          value={
                            find(
                              categoryOptions,
                              (item) => item.value === values.category
                            ) || ""
                          }
                          options={categoryOptions}
                          closeMenuOnSelect={true}
                          error={touched.category && errors.category}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                )}

                {isWinnerNotification && (
                  <Row>
                    <Col xs={6}>
                      <FormGroup>
                        <TextInput
                          label={
                            <span>
                              <FormattedMessage id="system templates > email subject" />
                              <Asterisk> *</Asterisk>
                              <MergeTags
                                onChange={(tag) => {
                                  const { subject_line } = values;
                                  formProps.setFieldValue(
                                    "subject_line",
                                    `${subject_line} {${tag}}`
                                  );
                                  props.setShouldBlockNavigation(true);
                                }}
                                bem={bem}
                              />
                            </span>
                          }
                          type="text"
                          name="subject_line"
                          placeholder={intl.formatMessage({
                            id: "system templates > placeholder email subject",
                          })}
                          value={values.subject_line}
                          error={touched.subject_line && errors.subject_line}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={6}>
                      <FormGroup>
                        <Dropdown
                          label={
                            <FormattedMessage id="system templates > recipients" />
                          }
                          placeholder={intl.formatMessage({
                            id: "system templates > placeholder recipients",
                          })}
                          name="recipients"
                          onChange={(option) => {
                            formProps.setFieldValue(
                              "recipients",
                              option ? option.value : ""
                            );
                            props.setShouldBlockNavigation(true);
                          }}
                          isClearable
                          value={
                            find(
                              recipientOptions,
                              (item) => item.value === values.recipients
                            ) || ""
                          }
                          options={recipientOptions}
                          closeMenuOnSelect={true}
                          error={touched.recipients && errors.recipients}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                )}
                {isRejectionNotification && (
                  <Row>
                    <Col xs={6}>
                      <FormGroup>
                        <TextInput
                          label={
                            <span>
                              <FormattedMessage id="system templates > email subject" />
                              <Asterisk> *</Asterisk>
                              <MergeTags
                                onChange={(tag) => {
                                  const { subject_line } = values;
                                  formProps.setFieldValue(
                                    "subject_line",
                                    `${subject_line} {${tag}}`
                                  );
                                  props.setShouldBlockNavigation(true);
                                }}
                                bem={bem}
                              />
                            </span>
                          }
                          type="text"
                          name="subject_line"
                          placeholder={intl.formatMessage({
                            id: "system templates > placeholder email subject",
                          })}
                          value={values.subject_line}
                          error={touched.subject_line && errors.subject_line}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={6}>
                      <FormGroup>
                        <TextInput
                          label={
                            <span>
                              <FormattedMessage id="system templates > template name" />
                              <Asterisk> *</Asterisk>
                            </span>
                          }
                          type="text"
                          name="name"
                          placeholder={intl.formatMessage({
                            id: "system templates > placeholder template name",
                          })}
                          value={values.name}
                          error={touched.name && errors.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                )}

                {isNormalForm && (
                  <Row>
                    <Col xs={6}>
                      <FormGroup>
                        <TextInput
                          label={
                            <span>
                              <FormattedMessage id="system templates > template name" />
                              <Asterisk> *</Asterisk>
                            </span>
                          }
                          type="text"
                          name="name"
                          placeholder={intl.formatMessage({
                            id: "system templates > placeholder template name",
                          })}
                          value={values.name}
                          error={touched.name && errors.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col>
                    <FormGroup>
                      <TextareaEvaluator
                        text={setScriptField(get(values, "content", ""))}
                        placeholder={
                          <FormattedMessage id="system templates > placeholder content" />
                        }
                        onEvaluatorCallback={(value) => {
                          formProps.setFieldValue("content", value);
                          if (props.setFormValues)
                            props.setFormValues({
                              ...values,
                              content: value,
                            });
                        }}
                        showScriptLength={false}
                        hideHelperText={true}
                        showInnerTitle={false}
                        isShowSlider={false}
                        hideOrderLength={true}
                        tagsInModal={templateTagsConfig}
                        tagsIsModal={true}
                        isShowUpload={true}
                        isShowHyperlink={true}
                        isShowInsertButton={true}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col xs={4}>
                    <div className={bem.e("guide-text")}>
                      <FormattedMessage id="system templates > stations to copy this template to" />
                    </div>
                    <FormGroup className="form-group">
                      <Label>
                        <FormattedMessage id="system templates > station" />
                      </Label>
                      <CustomMultiSelectDropdown
                        name="stations"
                        placeholder={intl.formatMessage({
                          id: "system templates > select",
                        })}
                        value={selectedStations}
                        onChange={(selectedValues) => {
                          formProps.setFieldValue(
                            "copy_to_stations",
                            map(selectedValues, (i) => i.value)
                          );
                          props.setShouldBlockNavigation(true);
                        }}
                        isMulti={true}
                        isStation
                        options={stationOptions}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {selectedStations.length > 0 ? (
                  <div className={bem.e("selected-stations")}>
                    {map(selectedStations, (item, index) => (
                      <span className={bem.e("station")} key={index}>
                        <span
                          className={bem.e("remove-tag")}
                          onClick={() => {
                            selectedStations.splice(index, 1);
                            formProps.setFieldValue(
                              "copy_to_stations",
                              selectedStations.map((item) => item.value)
                            );
                          }}
                        >
                          <CloseSmallIcon />
                        </span>
                        {item.label}
                      </span>
                    ))}
                  </div>
                ) : null}
              </div>
              <Row>
                <Col>
                  <div className={"system-templates-buttons"}>
                    <div className={bem.e("left-buttons")}>
                      <Button
                        color="blue"
                        type="submit"
                        className="btn btn-radius btn-submit"
                        id={`btn-submit-${
                          action === "create" ? "new" : props.systemTemplate.id
                        }`}
                        onClick={() => {
                          setTimeout(() => {
                            const formErrors = get(
                              formikRef,
                              "current.state.errors"
                            );
                            if (formErrors && Object.keys(formErrors).length) {
                              ToastManager.show({
                                title: intl.formatMessage({
                                  id: "toast > title not saved",
                                }),
                                message: intl.formatMessage({
                                  id:
                                    "toast > message error please correct the hilighted fields",
                                }),
                                level: "error",
                              });
                            }
                          }, 40);
                        }}
                      >
                        <FormattedMessage id="system templates > save template" />
                      </Button>
                      <Button
                        color="blue"
                        outline
                        className="btn btn-radius"
                        onClick={() => {
                          formProps.resetForm();
                          props.setShouldBlockNavigation(false);
                          props.onClose();
                        }}
                      >
                        <FormattedMessage id="system templates > cancel" />
                      </Button>
                    </div>

                    {action === "update" && (
                      <Button
                        color="blue"
                        outline
                        className="btn btn-radius"
                        onClick={() => setIsOpenConfirmationModal(true)}
                      >
                        <FormattedMessage id="system templates > delete" />
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>
              <ConfirmationModal
                isOpen={isOpenConfirmationModal}
                className={bem.e("confirmation-modal")}
                title={intl.formatMessage({
                  id: "system templates > are you sure you want to delete",
                })}
                confirmTitle={intl.formatMessage({
                  id: "system templates > yes delete",
                })}
                onCancel={() => {
                  setIsOpenConfirmationModal(false);
                }}
                onConfirm={async () => {
                  await props.onDelete(props.systemTemplate);
                  props.onClose();
                }}
                onToggle={() =>
                  setIsOpenConfirmationModal(!isOpenConfirmationModal)
                }
                isCloseOutside={false}
              />
              <OverrideStationsModal
                isOpen={isOpenOverrideModal}
                setIsOpen={(value) => {
                  setIsOpenOverrideModal(value);
                }}
                stations={values.duplicate_stations}
                onSubmit={(data) => {
                  setIsOpenOverrideModal(false);
                  onSaveTemplate(
                    {
                      ...values,
                      overwrite: data,
                    },
                    formProps
                  );
                }}
              />
            </Form>
          );
        }}
      </Formik>

      <TemplatePreviewModal ref={refPreviewModal} />
    </div>
  );
};
export default TemplateForm;
