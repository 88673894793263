import * as React from "react";
import PropTypes from "prop-types";
import { Row, Col, Label } from "reactstrap";
import Avatar from "components/Avatar";
import { get, filter, includes, find } from "lodash";
import { FaEllipsisH } from "react-icons/lib/fa";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import Dropdown from "components/Dropdown";
import {
  ShadowBox,
  SectionTitle,
  SectionFields,
  ChevronWrapper,
} from "components/Elements";
import Player from "components/Player";
import ToggleSwitch from "components/ToggleSwitch";
import SelectTeamGuide from "components/SelectTeamGuide";
import { UpIcon, DownIcon, PalmTreeIcon } from "components/CustomIcons";
import bn from "utils/bemnames";
import TextareaEvaluator from "components/TextareaEvaluator";
import {
  setScriptField,
  checkOutOfOfficeOfUserByDates,
  formatDisplayDateWithTimezone,
  isHtml,
} from "utils/helpers";
import TextareaAutosize from "react-textarea-autosize";
import ProductionDeadlines from "components/ProductionDeadlines";

const bem = bn.create("select-team");
class SelectTeam extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRole: "",
      isOpenSections: {
        creativeInstructions: true,
      },
      isAudition: false,
    };
  }

  static propTypes = {
    mode: PropTypes.string,
  };

  static defaultProps = {
    mode: "edit",
  };

  setIsOpenSections = (section) => {
    this.setState({
      isOpenSections: {
        ...this.state.isOpenSections,
        [section]: !this.state.isOpenSections[section],
      },
    });
  };

  roleClicked = (role) => {
    if (!role) this.setState({ isAudition: false });
    if (this.state.selectedRole === role) this.setState({ selectedRole: null });
    else this.setState({ selectedRole: role });
  };

  userInfo = (user) => {
    const action_items = user.info.availability
      ? user.info.availability.action_items
      : "";
    const upcoming_items = user.info.availability
      ? user.info.availability.upcoming_items
      : "";
    return (
      <div className={bem.e("user-tasks")}>
        <div
          className={classnames(bem.e("user-task"), bem.e("user-task-current"))}
        >
          <span className={bem.e("user-task-label")}>
            <FormattedMessage
              className={bem.e("user-task-label")}
              id="select team > current tasks"
            />
          </span>
          :<span className={bem.e("user-task-value")}>{action_items}</span>
        </div>
        <div
          className={classnames(
            bem.e("user-task"),
            bem.e("user-task-assigned")
          )}
        >
          <span className={bem.e("user-task-label")}>
            <FormattedMessage id="select team > assigned tasks" />:
          </span>
          <span className={bem.e("user-task-value")}>{upcoming_items}</span>
        </div>
      </div>
    );
  };
  toggleProducerAssignsVO = (checked) => {
    const { step, template, user, process } = this.props;
    const producer_assigns_vo = step.fields.find(
      (f) => f.field === "producer_assigns_vo"
    );
    const role_voice_talent = step.fields.find(
      (f) => f.field === "role_voice_talent"
    );
    this.props.valueChanged(producer_assigns_vo, process, template, user, {
      value: checked,
    });
    // clean role_voice_talent
    this.props.valueChanged(role_voice_talent, process, template, user, {
      value: { users: [] },
    });
    if (this.props.setShouldBlockNavigation) {
      this.props.setShouldBlockNavigation(true);
    }
  };
  toggleUserRole = (user, role) => {
    let { step, template, process } = this.props;
    let item = step.fields.find((f) => role && f.field === role.field);

    let candidate_for = [];
    let role_id = 0;
    user.candidate_for.map((c) => {
      c.roles.map((r) => {
        if (candidate_for.indexOf(r) === -1 && role.roles.indexOf(r) > -1)
          candidate_for.push(r);
        return true;
      });
      return true;
    });
    if (candidate_for.length === 0) {
      debugger;
    } else role_id = candidate_for[0];

    let role_users =
      process.data.fields[role.field] &&
      process.data.fields[role.field].value.users
        ? process.data.fields[role.field].value.users
        : [];
    if (
      role &&
      !(
        process.data.fields[role.field] &&
        process.data.fields[role.field].value.users
      ) &&
      process.data.fields[role.field] &&
      process.data.fields[role.field].value.id
    ) {
      role_users = [process.data.fields[role.field].value];
    }
    if (role_users.length > 0) {
      let user_exists = false;
      role_users.map((existing_user) => {
        if (
          parseInt(existing_user.id) === parseInt(user.info.id) &&
          parseInt(existing_user.role) === parseInt(role_id)
        )
          user_exists = true;
      });
      if (user_exists) {
        role_users = role_users.filter(
          (existing_user) =>
            parseInt(existing_user.id) !== parseInt(user.info.id) ||
            parseInt(existing_user.role) !== parseInt(role_id)
        );
      } else {
        role_users.push({
          id: user.info.id,
          name: user.info.name,
          role: role_id,
          avatar_url: user.info.avatar_url,
        });
      }
    } else {
      role_users = [
        {
          id: user.info.id,
          name: user.info.name,
          role: role_id,
          avatar_url: user.info.avatar_url,
        },
      ];
    }

    this.props.valueChanged(item, process, template, user, {
      value: { users: role_users },
    });
    if (this.props.setShouldBlockNavigation) {
      this.props.setShouldBlockNavigation(true);
    }
  };
  valueOrEmpty(item) {
    let { process } = this.props;
    let value =
      process.data.fields[item.field] && process.data.fields[item.field].value
        ? process.data.fields[item.field].value.name !== undefined
          ? process.data.fields[item.field].value.name
          : process.data.fields[item.field].value
        : "";
    return value;
  }
  renderForm() {
    let {
      process,
      template,
      user,
      step,
      validationState,
      dateFormatByServer,
    } = this.props;
    let role_dubber = step.fields.find((f) => f.field === "role_dubber");
    let role_voice_talent = step.fields.find(
      (f) => f.field === "role_voice_talent"
    );
    let role_continuity = step.fields.find(
      (f) => f.field === "role_continuity"
    );
    let role_producer = step.fields.find((f) => f.field === "role_producer");
    let role_writer = step.fields.find((f) => f.field === "role_writer");
    let copy_points = step.fields.find((f) => f.field === "copy_points");
    let userInfo = this.userInfo.bind(this);
    let toggleUserRole = this.toggleUserRole.bind(this);

    let spotInfo = get(process, "data.fields.spot_info.value", []).find(
      (spot) => spot.key === process.key
    );
    const isFinalScript = get(spotInfo, "script_type") === "final";

    let isSold =
      !process.data.fields.order_sold || process.data.fields.order_sold.value;

    const isProductionProvided =
      get(process, "data.fields.contract_status.value") ===
      "Production Provided";
    const producerAssignsVO = get(
      process,
      "data.fields.producer_assigns_vo.value",
      false
    );
    const needsProduction = !(
      process.data.fields.contract_status &&
      process.data.fields.contract_status.value &&
      process.data.fields.contract_status.value !== "Production Needed"
    );

    let roles = isSold
      ? needsProduction
        ? [role_writer, role_producer, role_voice_talent, role_dubber]
        : [role_continuity, role_dubber]
      : needsProduction
      ? [role_writer, role_producer, role_voice_talent]
      : [role_dubber];
    if (isFinalScript || isProductionProvided) {
      // remove role_writer
      roles = filter(roles, (role) => get(role, "field") !== "role_writer");
    }
    if (producerAssignsVO) {
      // remove role_voice_talent : https://tasks.getventive.com/projects/3D7FF-B9A
      roles = roles.map((role) => {
        if (get(role, "field") === "role_voice_talent") {
          return {
            ...role,
            is_disabled: true,
          };
        }
        return role;
      });
    }
    let role_candidates = {};
    roles.map((role) => {
      if (
        role &&
        (!this.state.selectedRole ||
          get(this.state.selectedRole, "field") === role.field)
      ) {
        role_candidates[role.field] = this.props.getRoleCandidates(
          role,
          process,
          template,
          user
        );
      }
      return true;
    });
    const isShowAudition = includes(
      ["role_producer", "role_voice_talent"],
      get(this.state.selectedRole, "field")
    );

    let all_candidates = [];

    Object.keys(role_candidates).map((role_key) => {
      let candidates = role_candidates[role_key];

      candidates &&
        candidates.map((candidate) => {
          if (!all_candidates.find((c) => c.info.id === candidate.info.id))
            all_candidates.push({ ...candidate, candidate_for: [] });

          all_candidates
            .find((c) => c.info.id === candidate.info.id)
            .candidate_for.push(roles.find((r) => r && r.field === role_key));
          return true;
        });
      return true;
    });

    Object.keys(role_candidates).map((role_key) => {
      let candidates = role_candidates[role_key];
      if (
        candidates &&
        candidates.length === 1 &&
        !(
          process.data.fields[role_key] &&
          process.data.fields[role_key].value &&
          process.data.fields[role_key].value.id !== 0
        ) &&
        !(
          process.data.fields[role_key] &&
          process.data.fields[role_key].value &&
          process.data.fields[role_key].value.users &&
          process.data.fields[role_key].value.users.length > 0
        )
      ) {
        // role was not assigned and there is just one candidate
        let user = all_candidates.find(
          (c) => c.info.id === candidates[0].info.id
        );
        if (!user || !user.candidate_for) debugger;
        let role = user.candidate_for.find(
          (role) => role && role.field === role_key
        );
        toggleUserRole(user, role);
        process.data.fields[role_key] = {
          is_dirty: true,
          value: {
            id: user.info.id,
            name: user.info.name,
            role: user.info.role,
          },
        };
      }
      return true;
    });
    const users_row = (
      <div className={bem.e("container-users")}>
        {all_candidates.map((user, ind) => {
          if (
            this.state.selectedRole &&
            !user.candidate_for.find(
              (r) => r && r.field === this.state.selectedRole.field
            )
          ) {
            return null;
          }
          const userTimeZone = get(user, "info.timezone");
          const datesOutOfOffice = checkOutOfOfficeOfUserByDates(
            get(user, "info.out_of_office_settings", []),
            userTimeZone
          );

          const isHasOOOPast = find(
            datesOutOfOffice,
            (date) => date.diffDays <= 0
          );
          return (
            <div
              key={"user_" + ind}
              className={classnames(bem.e("user-row"), {
                [bem.e("user-ooo")]: isHasOOOPast,
              })}
            >
              <Row>
                <Col xs={6} className={bem.e("col-user")}>
                  <div className={bem.e("user-info")}>
                    <Avatar
                      src={user.info.avatar_url}
                      size={40}
                      className={bem.e("user-avatar")}
                      name={user.info.name}
                    />
                    <div className={bem.e("user-info-right")}>
                      <span className={bem.e("user-name")}>
                        {user.info.name}
                        {isHasOOOPast ? (
                          <span className={bem.e("vacation-icon")}>
                            <PalmTreeIcon />
                          </span>
                        ) : null}
                        <div className={bem.e("user-action")}>
                          <FaEllipsisH color={`#798186`} size={20} />
                        </div>
                      </span>
                      {userInfo(user)}
                      {datesOutOfOffice && datesOutOfOffice.length > 0
                        ? datesOutOfOffice.map((date, ind) => (
                            <div
                              className={bem.e("out-of-office")}
                              key={`date-${ind}`}
                            >
                              {this.props.intl.formatMessage({
                                id: "process > out as of",
                              })}{" "}
                              {formatDisplayDateWithTimezone(
                                date.from_date,
                                dateFormatByServer,
                                userTimeZone
                              )}
                              {" - "}
                              {this.props.intl.formatMessage({
                                id: "process > returns",
                              })}{" "}
                              {formatDisplayDateWithTimezone(
                                date.to_date,
                                dateFormatByServer,
                                userTimeZone
                              )}
                            </div>
                          ))
                        : null}
                    </div>
                  </div>
                </Col>
                <Col xs={6} className={bem.e("col-user")}>
                  <div
                    className={classnames(bem.e("user-roles-reel"), {
                      [bem.e("user-roles-reel-column")]:
                        user.candidate_for.length > 1,
                    })}
                  >
                    {user.info.reel && this.state.isAudition && (
                      <Player url={user.info.reel} />
                    )}

                    <div className={bem.e("user-roles")}>
                      {user.candidate_for.map((role) => {
                        let selected_for_role =
                          role &&
                          process.data.fields[role.field] &&
                          process.data.fields[role.field].value &&
                          process.data.fields[role.field].value.id &&
                          parseInt(process.data.fields[role.field].value.id) ===
                            user.info.id;
                        if (
                          role &&
                          process.data.fields[role.field] &&
                          process.data.fields[role.field].value &&
                          process.data.fields[role.field].value.users
                        )
                          selected_for_role =
                            process.data.fields[role.field].value.users.filter(
                              (u) => parseInt(u.id) === user.info.id
                            ).length > 0;
                        return (
                          <div
                            className={classnames(bem.e("user-role-button"), {
                              [bem.e("user-role-assigned")]: selected_for_role,
                              [bem.e("user-role-disabled")]: role.is_disabled, //add condition for show disabled style
                            })}
                            key={"user_role_" + user.info.id + "_" + role.field}
                            onClick={() => {
                              if (role.is_disabled) return;
                              toggleUserRole(user, role);
                            }}
                          >
                            <span className={bem.e("user-role-title")}>
                              {role.title}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          );
        })}
      </div>
    );
    let rolesOptionFilter = [];
    if (this.props.mode === "edit") {
      rolesOptionFilter = [
        {
          label: this.props.intl.formatMessage({
            id: "select team > show all",
          }),
          value: "",
        },
        ...roles.map((r) => {
          return {
            label: get(r, `title.props.id`)
              ? this.props.intl.formatMessage({
                  id: `${get(r, `title.props.id`)}`,
                })
              : get(r, "title", "").toString(),
            value: r,
          };
        }),
      ];
    }
    return (
      <div className={bem.b()}>
        {this.props.mode === "edit" && (
          <ShadowBox>
            <SectionTitle
              onClick={() => this.setIsOpenSections("creativeInstructions")}
            >
              <FormattedMessage id="process > title creative instructions" />
              <ChevronWrapper>
                {get(
                  this.state,
                  `isOpenSections.creativeInstructions`,
                  false
                ) ? (
                  <DownIcon />
                ) : (
                  <UpIcon />
                )}
              </ChevronWrapper>
            </SectionTitle>
            <SectionFields
              className={
                !get(
                  this.state,
                  `isOpenSections.creativeInstructions`,
                  false
                ) && "hidden"
              }
            >
              <Row>
                <Col>
                  <Label>{copy_points.title}</Label>
                  {get(spotInfo, "copy_points", "") ? (
                    isHtml(get(spotInfo, "copy_points", "")) ? (
                      <TextareaEvaluator
                        text={setScriptField(get(spotInfo, "copy_points", ""))}
                        isEditorOnly={true}
                        hideOrderLength={true}
                        readOnly={true}
                        process={process}
                        onEvaluatorCallback={() => {}}
                      />
                    ) : (
                      <TextareaAutosize
                        value={get(spotInfo, "copy_points", "")}
                        className="text-area-view"
                        disabled
                      />
                    )
                  ) : (
                    <FormattedMessage id="process > none" />
                  )}
                </Col>
              </Row>
            </SectionFields>
          </ShadowBox>
        )}
        {this.props.mode === "edit" && (
          <ProductionDeadlines
            process={process}
            step={step}
            template={template}
            user={user}
            valueChanged={this.props.valueChanged}
            validationState={validationState}
          />
        )}
        <SelectTeamGuide
          roles={roles}
          process={process}
          selectedRole={this.state.selectedRole}
          validationState={validationState}
          roleClicked={this.roleClicked}
          allCandidates={all_candidates}
          toggleUserRole={this.toggleUserRole}
          toggleProducerAssignsVO={this.toggleProducerAssignsVO}
          isView={this.props.mode !== "edit"}
        />
        {this.props.mode === "edit" && (
          <ShadowBox className={bem.e("team-members")}>
            <SectionTitle className={bem.e("team-members-title")}>
              <FormattedMessage id="select team > team members" />
              {isShowAudition && (
                <ToggleSwitch
                  leftComponent={
                    <FormattedMessage id="select team > audition" />
                  }
                  rightComponent={null}
                  name="is_hidden"
                  switchProps={{
                    checked: this.state.isAudition,
                    onChange: (checked) => {
                      this.setState({ isAudition: checked });
                    },
                    offColor: "#C2D4E0",
                    onColor: "#795AFA",
                    uncheckedIcon: false,
                    checkedIcon: false,
                    boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.282)",
                    height: 26.44,
                    width: 49.76,
                    handleDiameter: 22.92,
                  }}
                />
              )}
            </SectionTitle>
            <div className={bem.e("filter-row")}>
              <div className={bem.e("team-member-dropdown")}>
                <span className={bem.e("filter-dropdown-label")}>
                  <FormattedMessage id="select team > filter by role" />:
                </span>
                <Dropdown
                  label={null}
                  placeholder={this.props.intl.formatMessage({
                    id: "select team > show all",
                  })}
                  name="role"
                  isMulti={false}
                  allowSelectAll={false}
                  onChange={(selectedOption) => {
                    this.roleClicked(selectedOption.value);
                  }}
                  value={rolesOptionFilter.find(
                    (item) =>
                      get(item, "value.field") ===
                      get(this.state, "selectedRole.field")
                  )}
                  options={rolesOptionFilter}
                  closeMenuOnSelect={true}
                />
              </div>
            </div>
            <div className={bem.e("select-teams-member")}>{users_row}</div>
          </ShadowBox>
        )}
      </div>
    );
  }

  render() {
    return this.renderForm();
  }
}

export default SelectTeam;
