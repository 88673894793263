import React from "react";
import { get, map, isEmpty, first, includes } from "lodash";
import { FaSortDesc, FaSortAsc } from "react-icons/lib/fa";
import { Row, Col, ButtonGroup, Button, Card, CardBody } from "reactstrap";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import moment from "moment";

import bn from "utils/bemnames";
import Table from "components/Table";
import PrivilegedComponent from "components/PrivilegedComponent";
import { DAYPART } from "utils/constants";
import ButtonAdd from "components/ButtonAdd";
import SearchBox from "components/SearchBox";
import Checkbox from "components/Checkbox";
import ClockType from "../ClockType";
import URL from "utils/urls";
import history from "components/History";
import { PRIVILEGES } from "utils/constants";
import { validatePrivileges } from "utils/helpers";
import TitleTooltip from "components/TitleTooltip";
import useInfiniteScroll from "utils/useInfiniteScroll";
import Tooltip from "rc-tooltip";
import {
  CopyIcon,
  TrashListIcon,
  InfoArrowIconUp,
} from "components/CustomIcons";
import ConfirmationModal from "components/ConfirmationModal";
import CopySelectStationsModal from "components/CopySelectStationsModal";
const bem = bn.create("clock-manager");

const ClocksList = (props) => {
  const {
    intl,
    searchText,
    setSearchText,
    onSearchClicked,
    filtered,
    sorted,
    setSorted,
    clocks,
    onLoadMore,
    isLazyLoading,
    updateStatus,
    deleteClockId,
    onDelete,
    openDeleteModal,
    closeDeleteModal,
    duplicateClock,
    activeConflictClocks,
    setActiveConflictClocks,
    currentClockId,
    setCurrentClockId,
    isOpenDeActiveConfirmationModal,
    setIsOpenDeActiveConfirmationModal,
    user,
    isOpenCopySelectStationsModal,
    setIsOpenCopySelectStationsModal,
    copyDaypartValue,
    setCopyDaypartValue,
    onCopyDaypart,
    auth,
    dateFormatByServer
  } = props;
  const privileges = get(user, "privileges", []);

  let clocksList = get(clocks, "data.data") || [];
  let pages = get(clocks, "data.last_page") || 1;
  let total = get(clocks, "data.total") || 1;

  useInfiniteScroll({
    useWindow: false,
    elementId: "cr-app-main",
    onLoadMore: onLoadMore,
    shouldLoadMore:
      !isLazyLoading &&
      get(clocks, "data.last_page") !== get(clocks, "data.current_page"),
  });
  const onClickItem = (clock) => {
    const isClickable = validatePrivileges({
      requires: {
        or: [
          PRIVILEGES.EDIT_SHOW_CLOCK,
          PRIVILEGES.EDIT_PROGRAMMING_CLOCK,
          PRIVILEGES.EDIT_DAYPART_CLOCK,
          PRIVILEGES.VIEW_SHOW_CLOCK,
          PRIVILEGES.VIEW_PROGRAMMING_CLOCK,
        ],
      },
      privileges,
      user,
    });
    if (isClickable)
      history.push(
        URL.CLOCK_EDITOR({
          type: clock.type,
          clock_id: clock.id,
        })
      );
  };

  let sortedColumn = first(sorted);
  const clockMessage = map(activeConflictClocks, "title").join(", ");
  const stationsData = get(auth, "info.stations", []);
  return (
    <Row className={bem.b()}>
      <Col>
        <Card className={classnames({ "no-data": isEmpty(clocksList) })}>
          <CardBody>
            <Row>
              <Col xs="12">
                <div className={bem.e("head-wrapper")}>
                  <PrivilegedComponent
                    requires={{
                      or: [
                        PRIVILEGES.CREATE_SHOW_CLOCK,
                        PRIVILEGES.CREATE_PROGRAMMING_CLOCK,
                      ],
                    }}
                  >
                    <ButtonAdd
                      onClick={() => {
                        history.push(URL.CLOCK_CREATOR());
                      }}
                      color="primary"
                      className={classnames(
                        "btn-radius btn-outline-blue",
                        "create-clock"
                      )}
                      iconProps={{ color: "#795AFA", width: 19, height: 19 }}
                    >
                      <FormattedMessage id="clock manager > create new clock" />
                    </ButtonAdd>
                  </PrivilegedComponent>
                  <SearchBox
                    placeholder={intl.formatMessage({
                      id: "clock manager > placeholder search input",
                    })}
                    onSearchClicked={onSearchClicked}
                    searchText={searchText}
                    setSearchText={setSearchText}
                  />
                </div>
              </Col>
            </Row>
            <Table
              className="table-hidden-checkbox admin-table clocks-table"
              manual
              resizable={false}
              data={clocksList}
              pages={pages}
              loading={clocks.loading}
              showPagination={false}
              total={total}
              filtered={filtered}
              sorted={sorted}
              onSortedChange={(sorted, column) => {
                setSorted(sorted);
              }}
              getTdProps={(state, rowInfo, column, instance) => {
                return {
                  onClick: (e, handleOriginal) => {
                    const { original } = rowInfo;
                    const columnId = column.id;
                    if (!includes(["actions", "is_active"], columnId)) {
                      onClickItem(original);
                    } else if (handleOriginal) {
                      handleOriginal();
                    }
                  },
                };
              }}
              columns={[
                {
                  Header: (
                    <div className={classnames("th-sort", "th-center")}>
                      <FormattedMessage id="clock manager > type" />
                      <div className="sortable">
                        <FaSortAsc
                          className={classnames("sortable-icon", {
                            "sortable-active":
                              sortedColumn.id === "type" && !sortedColumn.desc,
                          })}
                        />
                        <FaSortDesc
                          className={classnames("sortable-icon", {
                            "sortable-active":
                              sortedColumn.id === "type" && sortedColumn.desc,
                          })}
                        />
                      </div>
                    </div>
                  ),
                  Cell: ({ original: clock }) => {
                    return <ClockType clock={clock} />;
                  },
                  accessor: "type",
                  style: { justifyContent: "center" },
                  maxWidth: 100,
                },
                {
                  Header: (
                    <div
                      className={classnames(
                        "th-sort",
                        "th-left",
                        "th-pad-left"
                      )}
                    >
                      <FormattedMessage id="clock manager > name" />
                      <div className="sortable">
                        <FaSortAsc
                          className={classnames("sortable-icon", {
                            "sortable-active":
                              sortedColumn.id === "title" && !sortedColumn.desc,
                          })}
                        />
                        <FaSortDesc
                          className={classnames("sortable-icon", {
                            "sortable-active":
                              sortedColumn.id === "title" && sortedColumn.desc,
                          })}
                        />
                      </div>
                    </div>
                  ),
                  accessor: "title",
                  Cell: ({ original: clock }) => {
                    return (
                      <div
                        className={classnames(bem.e("clock-name"), {
                          inactive: !clock.is_active,
                        })}
                      >
                        <TitleTooltip title={clock.title} width={360} />
                      </div>
                    );
                  },
                  width: 400,
                },
                {
                  Header: (
                    <div className={classnames("th-sort", "th-center")}>
                      <FormattedMessage id="clock manager > last updated" />
                      <div className="sortable">
                        <FaSortAsc
                          className={classnames("sortable-icon", {
                            "sortable-active":
                              sortedColumn.id === "updated_at" &&
                              !sortedColumn.desc,
                          })}
                        />
                        <FaSortDesc
                          className={classnames("sortable-icon", {
                            "sortable-active":
                              sortedColumn.id === "updated_at" &&
                              sortedColumn.desc,
                          })}
                        />
                      </div>
                    </div>
                  ),
                  Cell: ({ original: clock }) => {
                    return (
                      <span
                        className={classnames(bem.e("clock-creation-date"), {
                          inactive: !clock.is_active,
                        })}
                      >
                        {moment(clock.updated_at).format(dateFormatByServer)}
                      </span>
                    );
                  },
                  accessor: "updated_at",
                  width: 170,
                  style: { justifyContent: "center" },
                },
                {
                  Header: (
                    <div className={classnames("th-center", "th-sort")}>
                      <FormattedMessage id="clock manager > station" />
                      <div className="sortable">
                        <FaSortAsc
                          className={classnames("sortable-icon", {
                            "sortable-active":
                              sortedColumn.id === "station_name" &&
                              !sortedColumn.desc,
                          })}
                        />
                        <FaSortDesc
                          className={classnames("sortable-icon", {
                            "sortable-active":
                              sortedColumn.id === "station_name" &&
                              sortedColumn.desc,
                          })}
                        />
                      </div>
                    </div>
                  ),
                  Cell: ({ original: clock }) => {
                    const stationData = stationsData.find(
                      (i) => i.id === clock.station_id
                    );
                    let stationArr = [];
                    if (get(stationData, "call_letters", ""))
                      stationArr.push(stationData.call_letters);
                    if (get(stationData, "name", ""))
                      stationArr.push(stationData.name);
                    return (
                      <span
                        className={classnames(bem.e("clock-station"), {
                          inactive: !clock.is_active,
                        })}
                      >
                        {stationArr.length > 0 ? (
                          <Tooltip
                            placement="topLeft"
                            trigger={["click", "hover"]}
                            overlayClassName={bem.e("tooltip")}
                            overlay={
                              <span className={bem.e("tooltip-station-text")}>
                                {stationArr.join(" - ")}
                              </span>
                            }
                          >
                            <span>{stationArr.join(" - ")}</span>
                          </Tooltip>
                        ) : (
                          <FormattedMessage id="clock manager > none" />
                        )}
                      </span>
                    );
                  },
                  accessor: "station_name",
                  style: { justifyContent: "center", textAlign: "center" },
                },
                {
                  Header: (
                    <div
                      className={classnames(
                        "table-head-title",
                        "table-head-title-last"
                      )}
                    >
                      <FormattedMessage id="clock manager > actions" />
                    </div>
                  ),
                  sortable: false,
                  resizable: false,
                  filterable: false,
                  minWidth: 150,
                  style: { justifyContent: "center", textAlign: "center" },
                  id: "actions",
                  Cell: ({ original: clock }) => {
                    return (
                      <ButtonGroup>
                        <PrivilegedComponent
                          requires={{
                            or: [
                              PRIVILEGES.CREATE_SHOW_CLOCK,
                              PRIVILEGES.CREATE_PROGRAMMING_CLOCK,
                            ],
                          }}
                        >
                          <Button
                            color="link"
                            onClick={() => {
                              if (clock.type === DAYPART) {
                                setCopyDaypartValue({
                                  id: clock.id,
                                  stations: [],
                                });
                                setIsOpenCopySelectStationsModal(true);
                                return;
                              }
                              duplicateClock({
                                id: clock.id,
                              });
                            }}
                          >
                            <CopyIcon width="20.55" height="20" />
                          </Button>
                        </PrivilegedComponent>
                        {clock.type !== DAYPART && (
                          <PrivilegedComponent
                            requires={{
                              or: [
                                PRIVILEGES.DELETE_SHOW_CLOCK,
                                PRIVILEGES.DELETE_PROGRAMMING_CLOCK,
                              ],
                            }}
                          >
                            <Button
                              color="link"
                              onClick={() => openDeleteModal(clock.id)}
                            >
                              <TrashListIcon />
                            </Button>
                          </PrivilegedComponent>
                        )}
                      </ButtonGroup>
                    );
                  },
                },
                {
                  Header: (
                    <div className={classnames("th-sort", "th-center")}>
                      <FormattedMessage id="clock manager > active" />
                      <div className="sortable">
                        <FaSortAsc
                          className={classnames("sortable-icon", {
                            "sortable-active":
                              sortedColumn.id === "is_active" &&
                              !sortedColumn.desc,
                          })}
                        />
                        <FaSortDesc
                          className={classnames("sortable-icon", {
                            "sortable-active":
                              sortedColumn.id === "is_active" &&
                              sortedColumn.desc,
                          })}
                        />
                      </div>
                    </div>
                  ),
                  accessor: "is_active",
                  style: { justifyContent: "center" },
                  Cell: ({ original: clock }) => {
                    return (
                      <PrivilegedComponent
                        requires={{
                          or: [
                            PRIVILEGES.CREATE_SHOW_CLOCK,
                            PRIVILEGES.EDIT_SHOW_CLOCK,
                            PRIVILEGES.CREATE_PROGRAMMING_CLOCK,
                            PRIVILEGES.EDIT_PROGRAMMING_CLOCK,
                          ],
                        }}
                      >
                        <div
                          className={classnames(bem.e("is-active"), {
                            inactive: !clock.is_active,
                          })}
                        >
                          <Checkbox
                            checked={clock.is_active ? true : false}
                            disabled={clock.type === DAYPART}
                            onChange={(checked) => {
                              updateStatus(clock.id, checked);
                            }}
                            checkStyle={{
                              color: "#fff",
                              width: "13.37",
                              height: "12.29",
                            }}
                          />
                        </div>
                      </PrivilegedComponent>
                    );
                  },
                },
              ]}
            />
          </CardBody>
          <ConfirmationModal
            className="modal-with-no-title"
            isOpen={deleteClockId ? true : false}
            title=""
            description={intl.formatMessage({
              id: "clock manager > are you sure you want to delete clock",
            })}
            onConfirm={onDelete}
            onToggle={closeDeleteModal}
            onCancel={closeDeleteModal}
            cancelTitle={intl.formatMessage({
              id: "clock manager > button no",
            })}
            isCloseOutside={false}
          />
        </Card>
        {/* only show when have 1 record */}
        <div>
          {total === 1 && (
            <div className={bem.e("helper-box")}>
              <InfoArrowIconUp />
              <div className={bem.e("helper-text")}>
                <FormattedMessage id="clock manager > helper text" />
              </div>
            </div>
          )}
        </div>
      </Col>
      <CopySelectStationsModal
        isOpen={isOpenCopySelectStationsModal}
        title={<FormattedMessage id="clock manager > copy daypart title" />}
        onCancel={() => {
          setIsOpenCopySelectStationsModal(false);
        }}
        onSubmit={(values) => {
          setIsOpenCopySelectStationsModal(false);
          if (values.length > 0) {
            setCopyDaypartValue({
              ...copyDaypartValue,
              stations: map(values, (item) => item.value),
            });
            onCopyDaypart({
              ...copyDaypartValue,
              stations: map(values, (item) => item.value),
            });
          }
        }}
        onToggle={() =>
          setIsOpenCopySelectStationsModal(!isOpenCopySelectStationsModal)
        }
      />
      {/* confirmation delete modal */}
      <ConfirmationModal
        isOpen={isOpenDeActiveConfirmationModal}
        className={bem.e("confirmation-modal")}
        title={<FormattedMessage id="clock manager > active confirm" />}
        description={
          <FormattedMessage
            id="clock manager > de-active clocks"
            values={{ clockMessage }}
          />
        }
        cancelTitle={<FormattedMessage id="clock manager > button no" />}
        confirmTitle={<FormattedMessage id="clock manager > button yes" />}
        onCancel={() => {
          setIsOpenDeActiveConfirmationModal(false);
          setCurrentClockId(null);
          setActiveConflictClocks([]);
        }}
        onConfirm={() => {
          updateStatus(currentClockId, true, true);
        }}
        onToggle={() =>
          setIsOpenDeActiveConfirmationModal(!isOpenDeActiveConfirmationModal)
        }
        isCloseOutside={false}
      />
    </Row>
  );
};

export default ClocksList;
