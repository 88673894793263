import { connect } from "react-redux";
import { compose, withState, withHandlers } from "recompose";
import {
  markAsSeenNotifications,
  replyMessage,
  getNotifications,
} from "store/actions/messages";
import { get } from "lodash";
import View from "./view";
import { pageSize } from "utils/config";

export default compose(
  connect(
    (state) => ({
      user: state.auth.user,
      notificationsData: get(state, "messages.notifications"),
    }),
    {
      markAsSeenNotifications,
      replyMessage,
      getNotifications,
    }
  ),
  withHandlers({
    getMoreItems: ({ getNotifications, notificationsData }) => () => {
      const page = parseInt(get(notificationsData, "data.page", 1));
      getNotifications({
        rpp: pageSize,
        page: page + 1,
      });
    },
  }),
  withState("message", "setMessage", ""),
  withState("viewing", "setViewing", null),
  withState("loading", "setLoading", false)
)(View);
