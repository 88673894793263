import fp from "lodash/fp";
import { syllable } from "syllable";

export const pad = (num) => {
  const time = parseInt(num);
  if (time < 10) {
    return "0" + parseInt(num);
  }
  return parseInt(num);
};

const toTenths = (num) => {
  return Math.round(10 * num) / 10;
};
export const formatTimer = (secs) => {
  secs = Number(secs);
  if (secs < 10) {
    return `:0${toTenths(secs)}`;
  }
  const hours = Math.floor(secs / 3600);
  secs %= 3600;
  const minutes = Math.floor(secs / 60);
  const seconds = secs % 60;
  let results = [];
  if (hours > 0) {
    results.push(pad(hours));
  }
  if (minutes > 0) {
    results.push(pad(minutes));
  } else if (hours > 0 && minutes <= 0) {
    results.push(pad(0));
  }
  if (seconds >= 10) {
    results.push(toTenths(seconds));
  } else if (seconds === 0) {
    results.push(pad(0));
  } else {
    results.push(`0${toTenths(seconds)}`);
  }
  if (results.length === 1) return `:${results[0]}`;
  return results.join(":");
};
/*
When entering multiple spaces or breaks (Enter key), the array will come
with empty elements. This function removes those elements. 
i.e. ["a dog", "", "a cat"]
*/
const specialWords = ["a", "the", "", ","];

export const removeSpecialElements = (arr) => {
  let pos = -1;
  arr.forEach((element, index) => {
    if (specialWords.includes(element)) {
      pos = index;
    }
  });

  if (pos === -1) return arr;

  arr.splice(pos, 1);

  return removeSpecialElements(arr);
};

export const counter = (value) => {
  let trimmedValue = value.trim();
  trimmedValue = trimmedValue.replace(/\[[^\]]+\]/g, "");
  const wordsArr = trimmedValue.split(/[" "-]+/);
  const words = fp.compose(removeSpecialElements)(wordsArr);
  const dots = (trimmedValue.match(RegExp("\\.", "g")) || []).length;
  const breaks = (trimmedValue.match(/[^\n]*\n[^\n]*/gi) || []).length;
  return parseInt(words.length) + parseInt(dots) + parseInt(breaks);
};

export function countSyllables(word) {
  if (!word) return 0;
  let trimmedValue = word.trim();
  // remove brackets: Use brackets [ ] to leave directions. The text within [brackets] will not be counted in the script.
  trimmedValue = trimmedValue.replace(/\[[^\]]+\]/g, "");
  return syllable(trimmedValue);
}

export function convertCountSyllablesToWord(counter) {
  return counter * 0.6;
}
