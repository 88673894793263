import React from "react";
import { FormattedMessage } from "react-intl";
import bn from "utils/bemnames";
const bem = bn.create("no-items-found");
export default () => (
  <div className={bem.b()}>
    <h4 className={bem.e("title")}>
      <FormattedMessage id="no items found > title" />
    </h4>
  </div>
);
