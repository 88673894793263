import React, { useMemo } from "react";
import bn from "utils/bemnames";
import { Row, Col, Label, Button } from "reactstrap";
import TextInput from "components/TextInput";
import { FormattedMessage } from "react-intl";
import UploadFile from "components/UploadFile";
import classnames from "classnames";
import { get, isEmpty, includes, uniqBy, map, first, find } from "lodash";
import { Asterisk } from "components/Elements";
import { RecurranceSmallIcon, EditIcon } from "components/CustomIcons";
import AsyncDropdown from "components/AsyncDropdown";
import { fetchWideOrbitMaterialGroups } from "store/actions/process";
import Badge from "components/Badge";
import moment from "moment";
import Dropdown from "components/Dropdown";

const bem = bn.create("production-continuity-traffic-details");

function SpotTrafficProductionNeeded(props) {
  const {
    spotIndex,
    spot,
    onValueChangedSpot,
    isView,
    process,
    validationState,
    step,
    valueChanged,
    template,
    user,
    isCartNumberEditable,
    setIsCartNumberEditable,
    isShowDelete,
    onRemoveSpot,
    getRoleCandidates,
  } = props;

  let recording;
  let recordingB;

  const orderTitleField = step.fields.find((f) => f.field === "order_title");

  const recordingPathField = step.fields.find(
    (f) => f.field === "recording_path"
  );

  const recordingPathFieldB = step.fields.find(
    (f) => f.field === "recording_path_b"
  );

  const selectDubberField = step.fields.find((f) => f.field === "role_dubber");

  if (
    !get(spot, "recording_requires_approval") ||
    (get(spot, "recording_requires_approval") &&
      get(spot, "approve_recording_status") === "approved")
  ) {
    recording = spot.recording_path;
  }

  if (
    !get(spot, "recording_requires_approval") ||
    (get(spot, "recording_requires_approval") &&
      get(spot, "approve_recording_status") === "approved")
  ) {
    recordingB = spot.recording_path_b;
  }

  let recordingAudit = get(process, "data.fields.recording_audit.value", "");

  if (!recordingAudit) {
    recordingAudit = get(
      process,
      "data.fields.production_asset_audit.value",
      ""
    );
  }

  if (!recordingAudit) {
    recordingAudit = get(process, "data.fields.provided_audio_audit.value", "");
  }

  let recordingAuditB = get(
    process,
    "data.fields.recording_path_b_audit.value",
    ""
  );
  const spotInfo = get(process, "data.fields.spot_info.value", []);
  const cartNumberEdited = includes(isCartNumberEditable, spotIndex);
  const isWOEnabled = get(user, "enable_wo", false);
  const isWOTrafficEnabled = get(user, "enable_wo_traffic", false);
  const showCartNumber =
    !isWOEnabled || !isWOTrafficEnabled || isView || cartNumberEdited;
  const isWODubbingEnabled = get(user, "enable_wo_dubbing", false);
  const showWOCartPrefix = !isWOTrafficEnabled && isWODubbingEnabled;
  const availableLineNumbers = get(
    process,
    "data.fields.available_line_numbers.value",
    []
  );
  const convertedSpecToSold = get(
    process,
    "data.fields.converted_from_spec_to_sold.value",
    false
  );
  const showLineNumber = !isEmpty(availableLineNumbers);
  const lineHashes = get(spot, "line_hashes", []);
  const isBookend = get(spot, "spot_type", "Standard") === "Book-ends";
  const canSyncCartNumber = !isEmpty(get(spot, "material_group_id", ""));

  let materialGroup = !isEmpty(get(spot, "material_group_id", ""))
    ? {
        label: get(spot, "material_group_name", ""),
        value: get(spot, "material_group_id", ""),
      }
    : null;
  const orderLengthCustom = get(spot, "order_length_custom", false);
  const orderLength = get(spot, "order_length", "");
  const isUpdateProcessData =
    (!get(process, "data.fields.order_title.value") && spotIndex === 0) ||
    get(process, "data.fields.key.value") === spot.key;

  const fetchMaterialGroups = async (q) => {
    let materialGroups = await fetchWideOrbitMaterialGroups({
      q: q,
      template_key: template.key,
      stations: get(process, "data.fields.order_stations.value"),
    });

    let materialGroupOptions = materialGroups.map((group) => {
      return {
        label: `${group.material_group_name} (${group.prefix})`,
        value: group.id,
        stations: group.stations,
      };
    });

    return materialGroupOptions;
  };

  const fetchAudioSources = async (q) => {
    let materialGroups = await fetchWideOrbitMaterialGroups({
      q: q,
      template_key: template.key,
      stations: [],
    });

    let audioSourceOptions = materialGroups.map((group) => {
      return {
        label: group.prefix,
        value: group.prefix,
        stations: group.stations,
      };
    });

    return audioSourceOptions;
  };

  const onChangeDubbersOrderData = (dubbers) => {
    let assignedDubbersOtherSpots = [];
    spotInfo.forEach((spot, index) => {
      if (index !== spotIndex && get(spot, "role_dubber.users.length", 0) > 0) {
        assignedDubbersOtherSpots = [
          ...assignedDubbersOtherSpots,
          ...spot.role_dubber.users,
        ];
      }
    });
    assignedDubbersOtherSpots = assignedDubbersOtherSpots.map((user) => ({
      ...user,
      id: Number(user.id),
    }));
    valueChanged(selectDubberField, process, template, user, {
      value: {
        users: dubbers
          ? uniqBy([...assignedDubbersOtherSpots, ...dubbers], "id")
          : uniqBy(assignedDubbersOtherSpots, "id"),
      },
    });
  };

  const {
    selectProducerValue,
    selectProducerOptions,
    selectDubberValue,
    selectDubberOptions,
  } = useMemo(() => {
    let selectDubberOptions = getRoleCandidates(
      selectDubberField,
      process,
      template,
      user
    );
    let selectDubberValue = map(get(spot, `role_dubber.users`, []), (item) => ({
      label: item.name,
      value: item.id,
      role: item.role,
    }));
    // only option
    const dubberOption = first(selectDubberOptions);
    const isSelectedDefaultDubberOption =
      dubberOption &&
      find(
        selectDubberValue,
        (item) => Number(item.value) === Number(dubberOption.value)
      );
    if (
      selectDubberField.default &&
      selectDubberOptions.length === 1 &&
      dubberOption &&
      !isSelectedDefaultDubberOption &&
      !isView
    ) {
      selectDubberValue = [
        {
          id: dubberOption.value,
          name: dubberOption.label,
          role: dubberOption.role,
        },
      ];
      onChangeDubbersOrderData(selectDubberValue);

      onValueChangedSpot(spotIndex, {
        field: "role_dubber",
        value: {
          users: selectDubberValue,
        },
        isSelectedDefault: true,
      });
    }
    return {
      selectProducerValue,
      selectProducerOptions,
      selectDubberValue,
      selectDubberOptions,
    };
  }, [process]);

  let audoSourcePrefix = !isEmpty(get(spot, "cart_prefix", ""))
    ? {
        label: get(spot, "cart_prefix", ""),
        value: get(spot, "cart_prefix", ""),
      }
    : null;

  const renderSelectedLineHashes = (lineHashes, props) => {
    return lineHashes.map((item) => {
      return (
        <Badge
          key={item.value}
          text={item.label}
          onClick={() => {}}
          readOnly={true}
        />
      );
    });
  };
  const orderLengthDisplay = () => {
    if (orderLengthCustom && orderLength) {
      const formatted = moment
        .utc(parseInt(orderLength) * 1000)
        .format("mm:ss");
      return formatted;
    }
    if (orderLength) {
      return `${orderLength} ${props.intl.formatMessage({
        id: "process > title seconds",
      })}`;
    }
    return props.intl.formatMessage({ id: "process > none" });
  };
  const isCOOPOrder = get(process, "data.fields.is_coop_order.value", false);

  return (
    <div
      className={classnames(
        {
          [bem.e("spot-production-needed-view")]: isView,
          [bem.e("spot-production-needed-edit")]: !isView,
        },
        bem.e("spot-section")
      )}
    >
      <Row>
        <Col xs={12} className={bem.e("col-title")}>
          <h2 className={bem.e("spot-title")}>
            <FormattedMessage id="process > title spot" />
            {` `}
            {spotIndex + 1}
            {isShowDelete && (
              <span
                onClick={() => onRemoveSpot(spotIndex)}
                className={bem.e("close-button")}
              >
                <FormattedMessage id="process > button delete" />
              </span>
            )}
          </h2>
        </Col>
      </Row>
      <Row>
        <Col className={bem.e("col-field")}>
          {isView ? (
            <>
              <Label className={bem.e("title-label")}>
                <FormattedMessage id="process > field title" />
              </Label>
              <div className={bem.e("field-value")}>
                {get(spot, "title", "") ? (
                  get(spot, "title", "")
                ) : (
                  <FormattedMessage id="process > none" />
                )}
              </div>
            </>
          ) : (
            <React.Fragment>
              <TextInput
                label={
                  <span>
                    <FormattedMessage id="process > field spot title" />
                    <Asterisk>*</Asterisk>
                  </span>
                }
                placeholder={props.intl.formatMessage({
                  id: "process > placeholder enter title",
                })}
                value={get(spot, "title", "")}
                onChange={({ target }) => {
                  onValueChangedSpot(spotIndex, {
                    field: "title",
                    value: target.value,
                  });
                  if (isUpdateProcessData) {
                    valueChanged(orderTitleField, process, template, user, {
                      value: target.value,
                    });
                  }
                }}
                disabled={isView}
              />
              {get(validationState, "title", "") && (
                <div className="alert-danger">
                  {get(validationState, "title", "")}
                </div>
              )}
            </React.Fragment>
          )}
        </Col>
        <Col className={bem.e("col-field")}>
          <Label className={bem.e("title-label")}>
            <FormattedMessage id="process > field spot type" />
          </Label>
          <div className={bem.e("field-value")}>
            {get(spot, "spot_type", "") ? (
              get(spot, "spot_type", "")
            ) : (
              <FormattedMessage id="process > none" />
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col className={bem.e("col-field")}>
          <Label className={bem.e("title-label")}>
            <FormattedMessage id="process > field production type" />
          </Label>
          <div className={bem.e("field-value")}>
            {get(spot, "contract_status", "") ? (
              get(spot, "contract_status", "")
            ) : (
              <FormattedMessage id="process > none" />
            )}
          </div>
        </Col>
        <Col className={bem.e("col-field")}>
          <Label className={bem.e("title-label")}>
            <FormattedMessage id="process > field order length" />
          </Label>
          <div className={bem.e("field-value")}>{orderLengthDisplay()}</div>
        </Col>
      </Row>
      {isWOEnabled && showLineNumber && !isEmpty(lineHashes) && (
        <Row>
          <Col className="selected-line-hashes">
            <Label>
              <FormattedMessage id="process > field selected line #" />
            </Label>
            <div className="line-hashes-badges-container">
              {renderSelectedLineHashes(lineHashes, props)}
            </div>
          </Col>
        </Row>
      )}
      {isWOTrafficEnabled && (
        <Row>
          {isView ? (
            <Col
              xs={12}
              className={classnames(
                bem.e("col-field"),
                "selected-line-numbers"
              )}
            >
              <Label className={bem.e("title-label")}>
                <FormattedMessage id="process > field material group name" />
              </Label>
              <div className={bem.e("field-value")}>
                {get(spot, "material_group_name", "") ? (
                  get(spot, "material_group_name", "")
                ) : (
                  <FormattedMessage id="process > none" />
                )}
              </div>
            </Col>
          ) : (
            <Col xs={6} className={bem.e("col-field")}>
              <AsyncDropdown
                name="material_group_name"
                loadOptions={fetchMaterialGroups}
                label={
                  <span>
                    <FormattedMessage id="process > field material group name" />
                    <Asterisk>*</Asterisk>
                  </span>
                }
                value={materialGroup}
                onChange={(selectedOption) => {
                  onValueChangedSpot(spotIndex, {
                    field: "material_group_name",
                    value: get(selectedOption, "label", ""),
                  });
                  onValueChangedSpot(spotIndex, {
                    field: "material_group_id",
                    value: get(selectedOption, "value", ""),
                  });
                }}
                defaultOptions={true}
                error={get(validationState, "material_group_name", "")}
              />
            </Col>
          )}
        </Row>
      )}
      {convertedSpecToSold && (
        <Row>
          <Col xs={6} className={bem.e("col-field")}>
            {isView ? (
              <React.Fragment>
                <Label className={bem.e("title-label")}>
                  <FormattedMessage id="process > field dubber" />
                </Label>
                <div className={bem.e("field-value")}>
                  {selectDubberValue && selectDubberValue.length > 0
                    ? map(selectDubberValue, (item) => item.label).join(",")
                    : props.intl.formatMessage({ id: "process > none" })}
                </div>
              </React.Fragment>
            ) : (
              <Dropdown
                isClearable={true}
                name="select_dubber"
                {...get(selectDubberField, "props", {})}
                placeholder={props.intl.formatMessage({
                  id: "process > placeholder select",
                })}
                isMulti
                options={selectDubberOptions}
                label={
                  <span>
                    {get(selectDubberField, "title")}
                    {selectDubberField && selectDubberField.mandatory && (
                      <Asterisk>*</Asterisk>
                    )}
                  </span>
                }
                value={selectDubberValue ? selectDubberValue : ""}
                onChange={(selectedOption) => {
                  let selectValue = "";
                  let newDubbers = "";
                  if (selectedOption) {
                    newDubbers = map(selectedOption, (option) => ({
                      id: option.value,
                      name: option.label,
                      role: option.role,
                    }));
                    selectValue = {
                      users: newDubbers,
                    };
                  }
                  onValueChangedSpot(spotIndex, {
                    field: "role_dubber",
                    value: selectValue,
                  });
                  // update role_dubber to root data if current order is production provided. Because the hybrid order allow production complete and production needed tags on an order data
                  onChangeDubbersOrderData(newDubbers);
                }}
              />
            )}
            {get(validationState, "role_dubber", "") && (
              <div className="alert-danger">
                {get(validationState, "role_dubber", "")}
              </div>
            )}
          </Col>
        </Row>
      )}
      <Row>
        <Col
          className={classnames(bem.e("col-field"))}
          xs={showCartNumber && showWOCartPrefix ? 4 : 6}
        >
          <Label className={bem.e("title-label")}>
            <FormattedMessage id="process > field isci / ad id" />
            {!isView && isWOTrafficEnabled && <Asterisk>*</Asterisk>}
          </Label>
          {isView ? (
            <span className={bem.e("table-value")}>
              {get(spot, "isci", "") ? (
                get(spot, "isci", "")
              ) : (
                <FormattedMessage id="process > none" />
              )}
            </span>
          ) : (
            <React.Fragment>
              <TextInput
                placeholder={props.intl.formatMessage({
                  id: "process > placeholder enter number",
                })}
                value={get(spot, "isci", "")}
                error={
                  get(validationState, "isci", "") &&
                  get(validationState, "isci", "")
                }
                disabled={isView}
                onChange={({ target }) => {
                  onValueChangedSpot(spotIndex, {
                    field: "isci",
                    value: target.value,
                  });
                }}
              />
            </React.Fragment>
          )}
        </Col>
        {showWOCartPrefix && (
          <Col className={bem.e("col-field")} xs={showCartNumber ? 4 : 6}>
            <Label className={bem.e("title-label")}>
              <FormattedMessage id="process > field cart prefix" />
              {!isView && <Asterisk>*</Asterisk>}
            </Label>
            {isView ? (
              <span className={bem.e("table-value")}>
                {get(spot, "cart_prefix", "") ? (
                  get(spot, "cart_prefix", "")
                ) : (
                  <FormattedMessage id="process > none" />
                )}
              </span>
            ) : (
              <React.Fragment>
                <AsyncDropdown
                  name="cart_prefix"
                  loadOptions={fetchAudioSources}
                  placeholder={props.intl.formatMessage({
                    id: "process > field select placeholder",
                  })}
                  value={audoSourcePrefix}
                  onChange={(selectedOption) => {
                    onValueChangedSpot(spotIndex, {
                      field: "cart_prefix",
                      value: get(selectedOption, "label", ""),
                    });
                    onValueChangedSpot(spotIndex, {
                      field: "cart_prefix",
                      value: get(selectedOption, "value", ""),
                    });
                  }}
                  defaultOptions={true}
                  error={get(validationState, "cart_prefix", "")}
                />
              </React.Fragment>
            )}
          </Col>
        )}
        <Col
          className={classnames(bem.e("col-field"))}
          xs={showWOCartPrefix ? 4 : 6}
        >
          <React.Fragment>
            <Label className={bem.e("table-label")}>
              <FormattedMessage id="process > field cart number" />
              {!isView && showCartNumber && <Asterisk>*</Asterisk>}
            </Label>
            {isView || !showCartNumber ? (
              get(spot, "cart_number", "") ? (
                <React.Fragment>
                  <span
                    className={classnames(bem.e("spot-value"), "cart-number")}
                  >
                    {get(spot, "cart_number", "")}
                  </span>
                  {!isView && !cartNumberEdited && (
                    <span
                      className={bem.e("cart-edit-pencil")}
                      onClick={() => {
                        isCartNumberEditable.push(spotIndex);
                        setIsCartNumberEditable(isCartNumberEditable);
                      }}
                    >
                      {!cartNumberEdited && (
                        <React.Fragment>
                          <EditIcon width="16" height="15" />
                          <span className={bem.e("cart-edit-text")}>
                            <FormattedMessage id="process > button edit" />
                          </span>
                        </React.Fragment>
                      )}
                    </span>
                  )}
                </React.Fragment>
              ) : isWOTrafficEnabled ? (
                <React.Fragment>
                  <span
                    className={classnames(bem.e("spot-pending"), {
                      "sync-cart": canSyncCartNumber,
                    })}
                    onClick={() => {
                      if (!canSyncCartNumber) {
                        return false;
                      }
                      props.syncWOCartNumbers({
                        cb: () => {
                          props.setIsSyncCartNumberProgressModalOpen(true);
                        },
                      });
                    }}
                  >
                    <RecurranceSmallIcon />
                    <FormattedMessage id="process > title pending" />
                  </span>
                  {!isView && !cartNumberEdited && (
                    <span
                      className={bem.e("cart-edit-pencil")}
                      onClick={() => {
                        isCartNumberEditable.push(spotIndex);
                        setIsCartNumberEditable(isCartNumberEditable);
                      }}
                    >
                      {!cartNumberEdited && (
                        <React.Fragment>
                          <EditIcon width="16" height="15" />
                          <span className={bem.e("cart-edit-text")}>
                            <FormattedMessage id="process > button edit" />
                          </span>
                        </React.Fragment>
                      )}
                    </span>
                  )}
                </React.Fragment>
              ) : (
                <FormattedMessage id="process > none" />
              )
            ) : (
              <TextInput
                placeholder={props.intl.formatMessage({
                  id: "process > placeholder enter number",
                })}
                value={get(spot, "cart_number", "")}
                disabled={isView}
                onChange={({ target }) => {
                  onValueChangedSpot(spotIndex, {
                    field: "cart_number",
                    value: target.value,
                  });
                }}
                error={
                  get(validationState, "cart_number", "") &&
                  get(validationState, "cart_number", "")
                }
              />
            )}
          </React.Fragment>
        </Col>
      </Row>
      {isBookend && <div className={bem.e("horizontal-line")}></div>}
      <Row>
        <Col xs={12} className={bem.e("col-field")}>
          <div className={bem.e("spot-right")}>
            {!isBookend && (
              <Label className={bem.e("label")}>
                <FormattedMessage id="process > field produced spot" />
              </Label>
            )}
            <div className={bem.e("produced-spot")}>
              {recording ? (
                <UploadFile
                  mode={"edit"}
                  process={process}
                  onUploaded={(response) => {
                    onValueChangedSpot(spotIndex, {
                      field: "recording_path",
                      value: get(response, "path"),
                    });
                    // update current process
                    if (spot.key === process.key) {
                      valueChanged(
                        recordingPathField,
                        process,
                        template,
                        user,
                        {
                          value: get(response, "path"),
                        }
                      );
                    }
                  }}
                  disabled={isView}
                  showRemoveIcon={false}
                  url={recording}
                  actions={["download"]}
                  positionActions="bottom"
                  auditInfo={recordingAudit}
                  isShowFileNameInEditMode={true}
                  isBookend={isBookend}
                  path={isBookend ? "a" : ""}
                  spot={spot}
                >
                  <FormattedMessage id="process > button upload" />
                </UploadFile>
              ) : (
                <div className={bem.e("spot-production")}>
                  <FormattedMessage id="process > title spot in production" />
                </div>
              )}
              {isCOOPOrder && (
                <Button
                  onClick={() =>
                    props.setShowFinalScriptModal({
                      title: spot.title,
                      uuid: spot.uuid,
                      spotIndex,
                      script: spot.script,
                      orderLength: isBookend
                        ? spot.length1
                        : get(process, "data.fields.order_length.value", ""),
                    })
                  }
                  className={classnames(
                    "btn btn-radius",
                    bem.e("btn-coop-script")
                  )}
                  color="blue"
                >
                  <FormattedMessage id="process > co-op script" />
                </Button>
              )}
            </div>
          </div>
        </Col>
      </Row>
      {isBookend && (
        <React.Fragment>
          <div className={bem.e("horizontal-line")}></div>
          <Row>
            <Col xs={12} className={bem.e("col-field")}>
              <div
                className={classnames(
                  bem.e("spot-right"),
                  bem.e("produced-spot")
                )}
              >
                {recordingB ? (
                  <UploadFile
                    mode={"edit"}
                    process={process}
                    onUploaded={(response) => {
                      onValueChangedSpot(spotIndex, {
                        field: "recording_path_b",
                        value: get(response, "path"),
                      });
                      // update current process
                      if (spot.key === process.key) {
                        valueChanged(
                          recordingPathFieldB,
                          process,
                          template,
                          user,
                          {
                            value: get(response, "path"),
                          }
                        );
                      }
                    }}
                    disabled={isView}
                    showRemoveIcon={false}
                    url={recordingB}
                    actions={["download"]}
                    positionActions="bottom"
                    auditInfo={recordingAuditB}
                    isShowFileNameInEditMode={true}
                    isBookend={isBookend}
                    path={isBookend ? "b" : ""}
                    spot={spot}
                  >
                    <FormattedMessage id="process > button upload" />
                  </UploadFile>
                ) : (
                  <div className={bem.e("spot-production")}>
                    <FormattedMessage id="process > title spot in production" />
                  </div>
                )}
                {isCOOPOrder && (
                  <Button
                    onClick={() =>
                      props.setShowFinalScriptModal({
                        title: spot.title,
                        uuid: spot.uuid,
                        spotIndex,
                        script: isBookend ? spot.script_part_b : "",
                        orderLength: isBookend
                          ? spot.length2
                          : get(process, "data.fields.order_length.value", ""),
                      })
                    }
                    className={classnames(
                      "btn btn-radius",
                      bem.e("btn-coop-script")
                    )}
                    color="blue"
                  >
                    <FormattedMessage id="process > co-op script" />
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </React.Fragment>
      )}
    </div>
  );
}
export default SpotTrafficProductionNeeded;
