import React from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { map, filter } from 'lodash';

import MarketsList from './TagsList/MarketsList';
import FormatsList from './TagsList/FormatsList';
import TeamsList from './TagsList/TeamsList';
import GroupsList from './TagsList/GroupsList';
import Search from './Search';

const StationSelectors = (props) => {
  
  const {
    bem,
    tabs,
    stations,
    teams,
    groups,
    activeTab,
    toggleTab,
    selectedTags,
    updateSelectedTags,
    selectedStations,
    setSelectedStations,
    updateSelectedStations,
    selectedRecord,
    addedStations,
    productionMultimarket,
    digitalMultimarket,
    promotionMultimarket,
  } = props;

  let enabledTabs = filter(tabs, ({ tabId }) => tabId !== 'team' || (tabId === 'team' && (productionMultimarket || digitalMultimarket || promotionMultimarket)));

  const renderTabNavItems = () => {
    return map(enabledTabs,  ({ tabId, allowStationsSelect }) => {
      return (
        <NavItem className={bem.e('nav-item')} key={tabId}>
          <NavLink
            className={classnames({ active: activeTab === tabId}, bem.e('nav-link'))}
            onClick={() => { toggleTab(tabId); }}
          >
            <FormattedMessage id={`picker > tabs > ${tabId}`}/>
          </NavLink>
        </NavItem>
      );
    })
  }

  const renderTabContent = () => {
    return map(enabledTabs,  ({ tabId, allowStationsSelect }) => {
      return (
        <TabPane tabId={tabId} key={tabId}>
            {renderTab(tabId, allowStationsSelect)}
        </TabPane>
      );
    })
  }


  const renderTab = (tabId, allowStationsSelect) => {
      switch (tabId){
        case 'market' :
        return (
            <div className={bem.e('tags-container')}>
              <MarketsList 
                tagType={tabId}
                allowStationsSelect={allowStationsSelect}
                availableStations={stations}
                selectedTags={selectedTags}
                updateSelectedTags={updateSelectedTags}
                selectedStations={selectedStations}
                setSelectedStations={setSelectedStations}
                updateSelectedStations={updateSelectedStations}
                selectedRecord={selectedRecord}
              />
            </div>
        );

        case 'format' :
        return (
            <div className={bem.e('tags-container')}>
              <FormatsList 
                tagType={tabId}
                allowStationsSelect={allowStationsSelect}
                availableStations={stations}
                selectedTags={selectedTags}
                updateSelectedTags={updateSelectedTags}
                selectedStations={selectedStations}
                setSelectedStations={setSelectedStations}
                updateSelectedStations={updateSelectedStations}
                selectedRecord={selectedRecord}
              />
            </div>
        );

        case 'team' :
        return (
            <div className={bem.e('tags-container')}>
              <TeamsList 
                tagType={tabId}
                allowStationsSelect={allowStationsSelect}
                availableStations={stations}
                availableTeams={teams}
                selectedTags={selectedTags}
                updateSelectedTags={updateSelectedTags}
                selectedStations={selectedStations}
                setSelectedStations={setSelectedStations}
                updateSelectedStations={updateSelectedStations}
                selectedRecord={selectedRecord}
              />
            </div>
        );

        case 'audience' :
        return (
            <div className={bem.e('tags-container')}>
              <GroupsList 
                tagType={tabId}
                allowStationsSelect={allowStationsSelect}
                availableStations={stations}
                availableGroups={groups}
                selectedTags={selectedTags}
                updateSelectedTags={updateSelectedTags}
                selectedStations={selectedStations}
                setSelectedStations={setSelectedStations}
                updateSelectedStations={updateSelectedStations}
                selectedRecord={selectedRecord}
              />
            </div>
        );

        case 'search' :
        return (
            <div className={bem.e('search-container')}>
              <Search
                availableStations={stations}
                selectedTags={selectedTags}
                selectedStations={selectedStations}
                updateSelectedStations={updateSelectedStations}
                selectedRecord={selectedRecord}
                addedStations={addedStations}
              />
            </div>
        );
        default:
      }
  }


  return (
    <div>
      <Nav tabs>
        {renderTabNavItems()}
      </Nav>
      <div className="tab-pane-separator"></div>
      <TabContent activeTab={activeTab} className={bem.e('tab-content')}>
        {renderTabContent()}
      </TabContent>
    </div>
  );
}

export default StationSelectors;