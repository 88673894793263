export const FETCH_LOCATIONS_REQUEST = "FETCH_LOCATIONS_REQUEST";
export const FETCH_LOCATIONS_SUCCESS = "FETCH_LOCATIONS_SUCCESS";
export const FETCH_LOCATIONS_ERROR = "FETCH_LOCATIONS_ERROR";

// store location
export const CREATE_LOCATION_REQUEST = "CREATE_LOCATION_REQUEST";
export const CREATE_LOCATION_SUCCESS = "CREATE_LOCATION_SUCCESS";
export const CREATE_LOCATION_ERROR = "CREATE_LOCATION_ERROR";

// update location
export const UPDATE_LOCATION_REQUEST = "UPDATE_LOCATION_REQUEST";
export const UPDATE_LOCATION_SUCCESS = "UPDATE_LOCATION_SUCCESS";
export const UPDATE_LOCATION_ERROR = "UPDATE_LOCATION_ERROR";
