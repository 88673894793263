import React from "react";
import bn from "utils/bemnames";
import { FormattedMessage } from "react-intl";
import Spinner from "components/Spinner";
import ConfirmationModal from "components/ConfirmationModal";
import SortableComponent from "components/SortableComponent";
import BlockingComponent from "components/BlockingComponent";
import RoleItem from "./RoleItem";
import SelectTags, { TAG_TYPE_DMAs } from "components/SelectTags";
import {
  Row,
  Col,
  Label,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import SearchBox from "components/SearchBox";
import { map, isEmpty, get, filter, includes, first } from "lodash";
import classnames from "classnames";

const bem = bn.create("roles-manager");

const RoleManager = ({
  roles,
  onDelete,
  setIsOpenConfirmationModal,
  isOpenConfirmationModal,
  shouldBlockNavigation,
  setShouldBlockNavigation,
  intl,
  currentOpenForm,
  onCloneRole,
  setCurrentOpenForm,
  onUpdateOrderRoles,
  onFormSubmit,
  setIsOpenConfirmationUnsavedModal,
  isOpenConfirmationUnsavedModal,
  isLoading,
  successConfirmationModal,
  setSuccessConfirmationModal,
  isOpenDeactivateConfirmationModal,
  setIsOpenDeactivateConfirmationModal,
  onToggleActivate,
  currentFilter,
  setCurrentFilter,
  onSearchClicked,
  onSearchKeyDown,
  onGetRoles,
  toggleTab,
  activeTab,
  authInfo,
  setActiveTab,
}) => {
  function getTabs(authInfo, currentFilter) {
    let tabs = [];
    const { enable_production, enable_promotion, enable_digital } = authInfo;
    let stations = get(authInfo, "stations", []);
    if (get(currentFilter, "market.value", "")) {
      stations = filter(stations, (station) => {
        return (
          get(station, `market.${get(currentFilter, "market.value")}.id`) ===
          get(currentFilter, "market.value")
        );
      });
    }
    if (
      enable_production &&
      !isEmpty(
        filter(stations, (station) => {
          return includes(station.products, "production");
        })
      )
    ) {
      tabs.push("production");
    }
    if (
      enable_digital &&
      !isEmpty(
        filter(stations, (station) => {
          return includes(station.products, "digital");
        })
      )
    ) {
      tabs.push("digital");
    }
    if (
      enable_promotion &&
      !isEmpty(
        filter(stations, (station) => {
          return includes(station.products, "promotion");
        })
      )
    ) {
      tabs.push("promotion");
    }
    return tabs;
  }

  const tabs = getTabs(authInfo, currentFilter);
  let selectedTab = includes(tabs, activeTab) ? activeTab : first(tabs);

  const renderTabNavItems = () => {
    return map(tabs, (tabId) => {
      return (
        <NavItem
          className={classnames(bem.e("nav-item"), {
            active: selectedTab === tabId,
          })}
          key={tabId}
        >
          <NavLink
            className={classnames(
              { active: selectedTab === tabId },
              bem.e("nav-link")
            )}
            onClick={() => {
              toggleTab(tabId);
            }}
          >
            <FormattedMessage id={`role manager > tabs > ${tabId}`} />
          </NavLink>
        </NavItem>
      );
    });
  };

  const renderTabContent = () => {
    return map(tabs, (tabId) => {
      return (
        <TabPane tabId={tabId} key={tabId}>
          {!isEmpty(roles) ? (
            <React.Fragment>
              <SortableComponent
                data={roles}
                bem={bem}
                onSorted={onUpdateOrderRoles}
                lockToContainerEdges={false}
                renderItem={(item, i) => (
                  <RoleItem
                    currentOpenForm={currentOpenForm}
                    setCurrentOpenForm={setCurrentOpenForm}
                    shouldBlockNavigation={shouldBlockNavigation}
                    setShouldBlockNavigation={setShouldBlockNavigation}
                    setIsOpenConfirmationUnsavedModal={
                      setIsOpenConfirmationUnsavedModal
                    }
                    onCloneRole={onCloneRole}
                    setIsOpenConfirmationModal={setIsOpenConfirmationModal}
                    role={item}
                    onFormSubmit={onFormSubmit}
                    isLoading={isLoading}
                    onToggleActivate={onToggleActivate}
                    key={`role-${i}`}
                  />
                )}
              />
              <Spinner isLoading={isLoading} />
              {/* success */}
              <ConfirmationModal
                isOpen={!!successConfirmationModal.isOpen}
                state="success"
                title={successConfirmationModal.message}
                onToggle={() =>
                  setSuccessConfirmationModal({ isOpen: false, message: "" })
                }
                onCancel={() =>
                  setSuccessConfirmationModal({ isOpen: false, message: "" })
                }
                isCloseOutside={true}
              />
              {/* confirm deactivate */}
              <ConfirmationModal
                isOpen={isOpenDeactivateConfirmationModal}
                title={intl.formatMessage({
                  id:
                    "role > you must remove all assigned users before you can de-active",
                })}
                isCloseOutside={false}
                className={bem.e("confirmation-modal")}
                confirmTitle={intl.formatMessage({
                  id: "role > button ok",
                })}
                cancelTitle={intl.formatMessage({
                  id: "role > button cancel",
                })}
                onConfirm={() => {
                  setIsOpenDeactivateConfirmationModal(false);
                }}
                onToggle={() =>
                  setIsOpenDeactivateConfirmationModal(
                    !isOpenDeactivateConfirmationModal
                  )
                }
                onCancel={() => setIsOpenDeactivateConfirmationModal(false)}
              />
              {/* actions confirmation unsaved new channel*/}
              <ConfirmationModal
                isOpen={isOpenConfirmationUnsavedModal}
                title={intl.formatMessage({
                  id: "role > wait, you have unsaved changes in this form",
                })}
                isCloseOutside={false}
                className={bem.e("confirmation-modal")}
                description={intl.formatMessage({
                  id:
                    "role > before you can perform this step, you must save or discard your changes",
                })}
                confirmTitle={intl.formatMessage({
                  id: "role > button save",
                })}
                discardTitle={intl.formatMessage({
                  id: "role > button discard",
                })}
                cancelTitle={intl.formatMessage({
                  id: "role > button cancel",
                })}
                onConfirm={() => {
                  const buttonSubmit = document.getElementById(
                    `btn-submit-${currentOpenForm}`
                  );
                  // trigger click to submit the form
                  if (buttonSubmit) buttonSubmit.click();
                  setIsOpenConfirmationUnsavedModal(false);
                }}
                onToggle={() =>
                  setIsOpenConfirmationUnsavedModal(
                    !setIsOpenConfirmationUnsavedModal
                  )
                }
                onDiscard={() => {
                  const eleItems = document.getElementById("cr-app-main");
                  if (eleItems) eleItems.scrollTop = 0;
                  setShouldBlockNavigation(false);
                  setIsOpenConfirmationUnsavedModal(false);
                }}
                onCancel={() => setIsOpenConfirmationUnsavedModal(false)}
              />
              {/* confirmation delete modal */}
              <ConfirmationModal
                isOpen={isOpenConfirmationModal}
                className={bem.e("confirmation-modal")}
                title={
                  <FormattedMessage id="role > are you sure you want to delete" />
                }
                cancelTitle={<FormattedMessage id="role > button cancel" />}
                confirmTitle={
                  <FormattedMessage id="role > button yes delete" />
                }
                isCloseOutside={false}
                onCancel={() => {
                  setIsOpenConfirmationModal(false);
                }}
                onConfirm={onDelete}
                onToggle={() =>
                  setIsOpenConfirmationModal(!isOpenConfirmationModal)
                }
              />
            </React.Fragment>
          ) : (
            <div className={bem.e("no-roles-found")}>
              <FormattedMessage id="role > no roles found" />
            </div>
          )}
        </TabPane>
      );
    });
  };

  const children = (
    <div id="roles-manager">
      <div className={bem.e("section-head")}>
        <div className={bem.e("page-title")}>
          <FormattedMessage id="role > roles manager" />
        </div>
        <Row>
          <Col xs={6}>
            <Label>
              <FormattedMessage id="role > market" />
            </Label>
            <SelectTags
              isMulti={false}
              allowSelectAll={true}
              checked={
                currentFilter.market || {
                  label: intl.formatMessage({
                    id: "role > all markets",
                  }),
                  value: "*",
                }
              }
              placeholder={intl.formatMessage({
                id: "role > placeholder select",
              })}
              allOption={{
                label: intl.formatMessage({
                  id: "role > all markets",
                }),
                value: "*",
              }}
              name="market"
              onChange={(data) => {
                const newFilter = {
                  ...currentFilter,
                  market: get(data, "value") === "*" ? "" : data,
                };
                const tabs = getTabs(authInfo, newFilter);
                let selectedTab = includes(tabs, activeTab)
                  ? activeTab
                  : first(tabs);
                setActiveTab(selectedTab);
                setCurrentFilter({
                  ...newFilter,
                  group: selectedTab,
                });
                onGetRoles({
                  group: selectedTab,
                  market_id:
                    get(data, "value") === "*" ? "" : get(data, "value"),
                });
              }}
              type={TAG_TYPE_DMAs}
              isRemovePrefixNumber={true}
              isFilterBroadcaster={true}
            />
          </Col>
          <Col>
            <SearchBox
              placeholder={intl.formatMessage({
                id: "role > search roles",
              })}
              onSearchClicked={onSearchClicked}
              searchText={currentFilter.search}
              setSearchText={(text) =>
                setCurrentFilter({
                  ...currentFilter,
                  search: text,
                })
              }
              onKeyDown={onSearchKeyDown}
            />
          </Col>
        </Row>
      </div>
      {!isEmpty(tabs) ? (
        <div>
          <Row className={bem.e("tab-container")}>
            <Col xs={11} className={bem.e("column")}>
              <Nav tabs>{renderTabNavItems()}</Nav>
            </Col>
            <Col xs={1} className={bem.e("column")}>
              <div className={bem.e("active-text")}>
                <FormattedMessage id="role > active" />
              </div>
            </Col>
          </Row>
          <div className={bem.e("tab-pane-separator")}></div>
          <TabContent activeTab={selectedTab} className={bem.e("tab-content")}>
            {renderTabContent()}
          </TabContent>
        </div>
      ) : (
        <div className={bem.e("no-roles-found")}>
          <FormattedMessage id="role > no roles found" />
        </div>
      )}
    </div>
  );
  return (
    <div className={bem.b()}>
      {children}
      <BlockingComponent
        isBlocked={shouldBlockNavigation}
        modalProps={{
          title: intl.formatMessage({
            id: "role > wait, you have unsaved changes in this form",
          }),
          confirmTitle: intl.formatMessage({
            id: "role > button save",
          }),
          cancelTitle: intl.formatMessage({
            id: "role > button dismiss",
          }),
          isCancelConfirmedNavigation: true,
          onConfirm: () => {
            const buttonSubmit = document.getElementById(
              `btn-submit-${currentOpenForm}`
            );
            // trigger click to submit the form
            if (buttonSubmit) {
              buttonSubmit.click();
            }
          },
        }}
      />
      <Spinner isLoading={isLoading} />
    </div>
  );
};
export default RoleManager;
