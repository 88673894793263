import * as React from "react";
import { FormattedMessage } from "react-intl";
import { get, find } from "lodash";
import bn from "utils/bemnames";
import UploadFile from "components/UploadFile";
import Chat from "../NotificationsPusher";

const bem = bn.create("order-recording");

const OrderRecordingReview = (props) => {
	const [currentFeedback, setCurrentFeedback] = React.useState({});
	const {
		process,
		template,
		user,
		isView,
		step,
		item,
		intl,
		formValueChanged
	} = props;

	const spot = find(
      get(process, "data.fields.spot_info.value", []),
      (spot) => spot.key === process.key
    );

    let isBookend = get(spot, "spot_type", "Standard") === "Book-ends";

	let hideFeedbackOption = isView || !get(process, "data.fields.recording_feedback.value.is_feedback", false);

	let recordingPath = get(process, `data.fields.recording_path.value`, "") && !get(process, `data.fields.recording_path.is_dirty`, false) ? get(process, `data.fields.recording_path.value`, "") : get(process, "data.fields.recording_feedback.value.recording_path", "");

	let recordingPathB = get(process, `data.fields.recording_path_b.value`, "") && !get(process, `data.fields.recording_path_b.is_dirty`, false) ? get(process, `data.fields.recording_path_b.value`, "") : get(process, "data.fields.recording_feedback.value.recording_path_b", "");

	let recordingAudit = get(process, `data.fields.recording_audit.value`, "") ? get(process, `data.fields.recording_audit.value`, "") : get(process, "data.fields.recording_feedback.value.recording_audit", "");

	let recordingAuditB = get(process, `data.fields.recording_path_b_audit.value`, "") ? get(process, `data.fields.recording_path_b_audit.value`, "") : get(process, "data.fields.recording_feedback.value.recording_path_b_audit", "");


	let recordingFeedback = get(process, "data.fields.recording_feedback.value", {});

	if(step.key === "approve_recording"){
	    return (
	    	<div>
		      <div className="final-recording">
		        <UploadFile
		          actions={['download']}
		          positionActions="bottom"
		          mode={isView ? "view" : ""}
		          {...item.props}
		          showRemoveIcon={false}
		          process={process}
		          spot={spot}
		          url={recordingPath}
		          className={item.class}
		          isShowFileNameInEditMode={true}
		          auditInfo={recordingAudit}
		          isBookend={isBookend}
                  path={isBookend ? "a" : ""}
		        >
		          <FormattedMessage id="process > button upload" />
		        </UploadFile>
		      </div>
		      <div className="recording-feedback">
		      	{!hideFeedbackOption && (<div className="feedback-note"><FormattedMessage id="process > recording feedback note" /></div>)}
			    <Chat
	              step={step}
	              process={process}
	              template={template}
	              user={user}
	              defaultExpand={true}
	              onToggleCallback={() => {}}
	              recordingFeedback={true}
	              chatInputPlaceHolder={intl.formatMessage({
	                id: "process > recording feedback input placeholder"
	              })}
	              checkBoxIconStyle={{width: "14px", height: "14px"}}
	              showEmojiPicker={false}
	              position="left"
	              boxStyle={{width: "100%"}}
	              hideChatInput={hideFeedbackOption}
	              recordingUrl={recordingPath}
	              path={isBookend ? "a" : ""}
								isShowMessageActions={!isView}
								recomputeFields={() => {
									formValueChanged({
										process,
										template,
										item: { field: 'recording_feedback' },
										user,
										value : currentFeedback
									});
									process.data.fields.recording_feedback = {
										value: currentFeedback,
										is_dirty: true
									};
									return process;
								}}
	              onInputChange={ (value) => {	
	              	const newFeedback = {
										...recordingFeedback,
										feedback : value,
									}; 
									setCurrentFeedback(newFeedback);
									props.setShouldBlockNavigation(value ? true : false);// set unsaved modal open
	              }}
	              onTimeStampSelect={ (checked) => {
									setCurrentFeedback({...recordingFeedback, ...currentFeedback, include_timestamp : checked});
									props.setShouldBlockNavigation(checked ? true : false);// set unsaved modal open
	              }}
	            />
			  </div>
			  {isBookend && (
			  	<React.Fragment>
			  		{!hideFeedbackOption && (<div className={bem.e('horizontal-line')}></div>)}
			      	<div className="final-recording">
				        <UploadFile
				          actions={['download']}
				          positionActions="bottom"
				          mode={isView ? "view" : ""}
				          {...item.props}
				          showRemoveIcon={false}
				          process={process}
				          spot={spot}
				          url={recordingPathB}
				          className={item.class}
				          isShowFileNameInEditMode={true}
				          auditInfo={recordingAuditB}
				          isBookend={isBookend}
		                  path="b"
				        >
				          <FormattedMessage id="process > button upload" />
				        </UploadFile>
				    </div>
				    <div className="recording-feedback">
			      	{!hideFeedbackOption && (<div className="feedback-note"><FormattedMessage id="process > recording feedback note" /></div>)}
				    <Chat
		              step={step}
		              process={process}
		              template={template}
		              user={user}
		              defaultExpand={true}
		              onToggleCallback={() => {}}
		              recordingFeedback={true}
		              chatInputPlaceHolder={intl.formatMessage({
		                id: "process > recording feedback input placeholder"
		              })}
		              checkBoxIconStyle={{width: "14px", height: "14px"}}
		              showEmojiPicker={false}
		              position="left"
		              boxStyle={{width: "100%"}}
		              hideChatInput={hideFeedbackOption}
		              recordingUrl={recordingPathB}
		              path="b"
									isShowMessageActions={!isView}
									recomputeFields={() => {
										formValueChanged({
		              		process,
		              		template,
											item: { field: 'recording_feedback' },
											user,
											value : currentFeedback
		              	});
										process.data.fields.recording_feedback = {
											value: currentFeedback,
											is_dirty: true
										};
										return process;
									}}
		              onInputChange={ (value) => {	
		              	const newFeedback = {
											...recordingFeedback,
											recording_path_b_feedback : value,
										};
										setCurrentFeedback(newFeedback);
										props.setShouldBlockNavigation(value ? true : false);// set unsaved modal open
		              }}
		              onTimeStampSelect={ (checked) => {
										setCurrentFeedback({...recordingFeedback, ...currentFeedback, include_recording_path_b_timestamp : checked});
										props.setShouldBlockNavigation(checked ? true : false);// set unsaved modal open
		              }}
		            />
				  </div>
				</React.Fragment>
		      )}
			</div>
	    );
	}else{
		return (
	    	<div>
		      <div className="recording-feedback">
			    <Chat
	              step={step}
	              process={process}
	              template={template}
	              user={user}
	              defaultExpand={true}
	              onToggleCallback={() => {}}
	              recordingFeedback={true}
	              showEmojiPicker={false}
	              position="left"
	              boxStyle={{width: "100%"}}
	              hideChatInput={true}
	              recordingUrl={recordingPath}
	            />

	            <div className="final-recording">
	            	<div className="version">{isBookend && (<><FormattedMessage id="process > part A" /><span>&nbsp;:&nbsp;</span></>)}<FormattedMessage id="process > field version" />&nbsp;{get(process, "data.fields.recording_version.value", 1)}</div>
			        <UploadFile
			          actions={['download']}
			          positionActions="bottom"
			          mode={isView ? "view" : ""}
			          {...item.props}
			          showRemoveIcon={false}
			          process={process}
			          spot={spot}
			          url={recordingPath}
			          className={item.class}
			          isShowFileNameInEditMode={true}
			          auditInfo={recordingAudit}
			          isBookend={isBookend}
			          path="a"
			        >
			          <FormattedMessage id="process > button upload" />
			        </UploadFile>
		      	</div>
			  </div>
			  {isBookend && (
			  	<React.Fragment>
				  	<div className={bem.e('horizontal-line')}></div>
				  	<div className="recording-feedback">
					    <Chat
			              step={step}
			              process={process}
			              template={template}
			              user={user}
			              defaultExpand={true}
			              onToggleCallback={() => {}}
			              recordingFeedback={true}
			              showEmojiPicker={false}
			              position="left"
			              boxStyle={{width: "100%"}}
			              hideChatInput={true}
			              recordingUrl={recordingPathB}
			              path="b"
			            />

			            <div className="final-recording">
			            	<div className="version">{isBookend && (<><FormattedMessage id="process > part B" /><span>&nbsp;:&nbsp;</span></>)}<FormattedMessage id="process > field version" />&nbsp;{get(process, "data.fields.recording_version.value", 1)}</div>
					        <UploadFile
					          actions={['download']}
					          positionActions="bottom"
					          mode={isView ? "view" : ""}
					          {...item.props}
					          showRemoveIcon={false}
					          process={process}
					          spot={spot}
					          url={recordingPathB}
					          className={item.class}
					          isShowFileNameInEditMode={true}
					          auditInfo={recordingAuditB}
					          isBookend={isBookend}
			                  path="b"
					        >
					          <FormattedMessage id="process > button upload" />
					        </UploadFile>
				      	</div>
					 </div>
				</React.Fragment>
			  )}
			</div>
	    );
	}
};

export default OrderRecordingReview;
