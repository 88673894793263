import React from "react";
import { get } from "lodash";
import { SHOWCLOCK, PROGCLOCK } from "utils/constants";
import bn from "utils/bemnames";
import CreateClock from "./CreateClock";
import EditClock from "./EditClock";
import EditDaypart from "./EditDaypart";
const bem = bn.create("clock-creator");

const ClockCreator = (props) => {
  const { stations, match } = props;

  let clockId = get(match, "params.clock_id", null);
  let clockType = get(match, "params.type", null);

  if (!clockId) {
    return <CreateClock bem={bem} stations={stations} />;
  }

  if (clockType === SHOWCLOCK || clockType === PROGCLOCK) {
    return (
      <EditClock
        bem={bem}
        stations={stations}
        clockId={clockId}
        clockType={clockType}
      />
    );
  }

  return (
    <EditDaypart
      bem={bem}
      stations={stations}
      clockId={clockId}
      clockType={clockType}
    />
  );
};

export default ClockCreator;
