import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";
import { injectIntl } from "react-intl";
import GrandPrizePackage from "./view";
import { forgetPrizes } from "store/actions/prizes";
const Enhanced = compose(
  connect((state, props) => ({ ...props }), {
    forgetPrizes,
  }),
  withHandlers({
    onRefreshPrizes: ({ forgetPrizes }) => () => {
      forgetPrizes();
    },
  })
)(GrandPrizePackage);
export default injectIntl(Enhanced);
