import React from "react";
import bn from "utils/bemnames";
import classnames from "classnames";
import { FaClose } from "react-icons/lib/fa";
import { MenuIcon } from "components/CustomIcons";
import { FormattedMessage } from "react-intl";
import { labelToNameField } from "utils/helpers";
import { FormGroup, Row, Col, Label } from "reactstrap";
import ToggleSwitch from "components/ToggleSwitch";
import TextInput from "components/TextInput";
import { get, filter } from "lodash";
import { Asterisk } from "components/Elements";
const bem = bn.create("form-builder-fields");

const URL = ({
  intl,
  isNew,
  isView,
  field,
  value,
  errors,
  touched,
  onChange,
  onDelete,
  allowDelete,
  isFormBuilder,
  highlightFields,
  disabled,
  isShowOffText,
}) => {
  // UI for form builder
  if (isFormBuilder) {
    if (isNew) {
      const isHighlightFormField =
        filter(
          highlightFields,
          (highlightFieldItem) =>
            highlightFieldItem.uuid === value.uuid &&
            highlightFieldItem.count_highlight > 0
        ).length > 0;
      return (
        <Row>
          <Col className="form-builder-field">
            <div className={bem.b()}>
              <div
                className={classnames(
                  bem.e("form-field-group"),
                  bem.e("form-field-group-line"),
                  {
                    [bem.e("form-field-group-highlight")]: isHighlightFormField,
                    [bem.e("form-field-group-disable")]:
                      highlightFields.length > 0 &&
                      !isHighlightFormField &&
                      value.is_controlled,
                  }
                )}
              >
                <Row>
                  <Col xs={9}>
                    <div className={bem.e("form-field-group-head")}>
                      <div
                        className={bem.e("form-field-group-head-single-left")}
                      >
                        <span className={bem.e("menu-icon")}>
                          <MenuIcon />
                        </span>
                        <div className={bem.e("input-label")}>
                          <TextInput
                            label={null}
                            name="label"
                            type="text"
                            placeholder={intl.formatMessage({
                              id: "form builder > label url",
                            })}
                            value={value.label}
                            onChange={({ target }) => {
                              onChange({
                                ...value,
                                label: target.value,
                              });
                            }}
                            disabled={disabled}
                            error={get(errors, "label", "")}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xs={3}>
                    <FormGroup>
                      <ToggleSwitch
                        leftComponent={
                          <span
                            className={classnames({
                              [bem.e("switch-active")]: !get(
                                value,
                                "is_required",
                                false
                              ),
                            })}
                          >
                            {isShowOffText
                              ? intl.formatMessage({
                                  id: "form builder > off",
                                })
                              : ""}
                          </span>
                        }
                        rightComponent={
                          <span
                            className={classnames({
                              [bem.e("switch-active")]: get(
                                value,
                                "is_required",
                                false
                              ),
                            })}
                          >
                            {intl.formatMessage({
                              id: "form builder > required",
                            })}
                          </span>
                        }
                        name="is_required"
                        switchProps={{
                          checked: !get(value, "is_required", false),
                          onChange: (checked) => {
                            onChange({
                              ...value,
                              is_required: !checked,
                            });
                          },
                          disabled: disabled,
                          offColor: "#795AFA",
                          onColor: "#E3ECF1",
                          uncheckedIcon: false,
                          checkedIcon: false,
                          handleDiameter: 22,
                          width: 50,
                          height: 26,
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {allowDelete && (
                  <div
                    className={classnames(
                      bem.e("form-field-group-head-right"),
                      bem.e("form-field-group-delete-icon")
                    )}
                    onClick={onDelete}
                  >
                    <FaClose size="23" color="#C2D4E0" />
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row>
          <Col>
            <div className={bem.b()}>
              <div className={bem.e("form-field-group-saved")}>
                <FormGroup>
                  <TextInput
                    label={
                      <span>
                        {get(value, "label", "")}{" "}
                        {get(value, "is_required", false) && (
                          <Asterisk>*</Asterisk>
                        )}
                      </span>
                    }
                    name=""
                    type="text"
                    placeholder={intl.formatMessage({
                      id: "form builder > placeholder url",
                    })}
                    onChange={() => {}}
                  />
                </FormGroup>
                {allowDelete && (
                  <div
                    className={bem.e("form-field-group-saved-button-close")}
                    onClick={onDelete}
                  >
                    <FaClose size="23" color="#C2D4E0" />
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    // ui for form
  } else {
    const fieldName = labelToNameField(get(field, "label", ""));
    if (isView) {
      return (
        <Row>
          <Col>
            <div className={bem.b()}>
              <div
                className={classnames(
                  bem.e("form-field-group-saved"),
                  bem.e("form-field-url")
                )}
              >
                <Label>{get(field, "label", "")}</Label>
                <span className="order-view-value">
                  {value ? (
                    value
                  ) : (
                    <FormattedMessage id="form builder > none" />
                  )}
                </span>
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    return (
      <Row>
        <Col>
          <div className={bem.b()}>
            <div className={bem.e("form-field-group-saved")}>
              <FormGroup className={bem.e("form-group")}>
                <TextInput
                  label={
                    <span>
                      {get(field, "label", "")}{" "}
                      {get(field, "is_required", false) && (
                        <Asterisk>*</Asterisk>
                      )}
                    </span>
                  }
                  name={fieldName}
                  type="text"
                  placeholder={intl.formatMessage({
                    id: "form builder > placeholder url",
                  })}
                  disabled={disabled}
                  value={value || ""}
                  onChange={({ target }) => onChange(target.value)}
                  error={
                    get(touched, fieldName, false) && get(errors, fieldName, "")
                      ? get(errors, fieldName, "")
                      : ""
                  }
                />
              </FormGroup>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
};
URL.defaultProps = {
  isNew: false,
  isFormBuilder: true,
  errors: {},
  touched: {},
  onChange: () => {},
  onDelete: () => {},
  allowDelete: true,
  disabled: false,
  isShowOffText: true,
};
export default URL;
