import * as Types from "store/types/locations";
import { get } from "lodash";

const initialState = {
  locations: {
    data: [],
    error: null,
    loading: false,
    is_dirty: false,
  },
  createLocation: {
    data: null,
    error: null,
    loading: false,
  },
  updateLocation: {
    data: null,
    error: null,
    loading: false,
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_LOCATIONS_REQUEST: {
      return {
        ...state,
        locations: {
          data: get(state, "locations.data"),
          error: null,
          loading: true,
          is_dirty: false,
        },
      };
    }

    case Types.FETCH_LOCATIONS_SUCCESS: {
      return {
        ...state,
        locations: {
          data: action.payload,
          error: null,
          loading: false,
          is_dirty: false,
        },
      };
    }

    case Types.FETCH_LOCATIONS_ERROR: {
      return {
        ...state,
        locations: {
          data: null,
          error: action.payload,
          loading: false,
          is_dirty: false,
        },
      };
    }

    case Types.CREATE_LOCATION_REQUEST: {
      return {
        ...state,
        createLocation: {
          loading: true,
          error: null,
        },
      };
    }

    case Types.CREATE_LOCATION_SUCCESS: {
      return {
        locations: {
          ...get(state, "locations"),
          is_dirty: true,
        },
        createLocation: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    }

    case Types.CREATE_LOCATION_ERROR: {
      return {
        ...state,
        createLocation: {
          loading: false,
          error: action.payload,
        },
      };
    }

    case Types.UPDATE_LOCATION_REQUEST: {
      return {
        ...state,
        updateLocation: {
          loading: true,
          error: null,
        },
      };
    }

    case Types.UPDATE_LOCATION_SUCCESS: {
      return {
        locations: {
          ...get(state, "locations"),
          is_dirty: true,
        },
        updateLocation: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    }

    case Types.UPDATE_LOCATION_ERROR: {
      return {
        ...state,
        createLocation: {
          loading: false,
          error: action.payload,
        },
      };
    }

    default:
      return state;
  }
}
