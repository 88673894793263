import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { FaSortDesc, FaSortAsc, FaSearch } from "react-icons/lib/fa";
import { EditIcon } from "components/CustomIcons";
import { MdClose } from "react-icons/lib/md";
import classnames from "classnames";
import Spinner from "components/Spinner";
import { get, find, includes, map, sortBy } from "lodash";
import { ButtonGroup, Button, Row, Col, Card, CardBody } from "reactstrap";
import { FormattedMessage } from "react-intl";
import TextInput from "components/TextInput";
import Table from "components/Table";
import PrivilegedComponent from "components/PrivilegedComponent";
import { PRIVILEGES } from "utils/constants";
import useInfiniteScroll from "utils/useInfiniteScroll";
import bn from "utils/bemnames";
import Tooltip from "rc-tooltip";
import moment from "moment";
import history from "components/History";
import URL from "utils/urls";
import classNames from "classnames";

const bem = bn.create("list-users");

const OutOfOfficeList = (props) => {
  const {
    filtered,
    sorted,
    setSorted,
    users,
    searchText,
    onChangeInput,
    onSearchKeyDown,
    onSearchClicked,
    onClearSearch,
    isLazyLoading,
    onLoadMore,
    isFocused,
  } = props;

  const userList = get(users, "data.data") || [];
  const pages = get(users, "data.last_page") || 1;
  const total = get(users, "data.total") || 1;

  const onClickItem = (item) => {
    props.onSelectedEdit({ [item.id]: item });
  };

  useInfiniteScroll({
    useWindow: false,
    elementId: "cr-app-main",
    onLoadMore: onLoadMore,
    shouldLoadMore:
      isFocused &&
      !isLazyLoading &&
      get(users, "data.last_page") !== get(users, "data.current_page"),
  });

  const columns = useMemo(() => {
    let items = [];
    const sortedName = find(sorted, (item) => item.id === "name");
    items.push({
      Header: (
        <div className={classnames(bem.e("th-name"), bem.e("th-sort"))}>
          <FormattedMessage id="user > name" />
          <div className={bem.e("sortable")}>
            <FaSortAsc
              className={classnames(bem.e("sortable-icon"), {
                [bem.e("sortable-active")]: sortedName && !sortedName.desc,
              })}
            />
            <FaSortDesc
              className={classnames(bem.e("sortable-icon"), {
                [bem.e("sortable-active")]: sortedName && sortedName.desc,
              })}
            />
          </div>
        </div>
      ),
      accessor: "name",
      Cell: ({ original: user }) => {
        return (
          <div
            className={classnames(bem.e("user-name-column"), bem.e("td-value"))}
          >
            {user.name}
          </div>
        );
      },
    });
    const sortedRoles = find(sorted, (item) => item.id === "roles_string");
    items.push({
      Header: (
        <div className={bem.e("th-sort")}>
          <FormattedMessage id="user > roles" />
          <div className={bem.e("sortable")}>
            <FaSortAsc
              className={classnames(bem.e("sortable-icon"), {
                [bem.e("sortable-active")]: sortedRoles && !sortedRoles.desc,
              })}
            />
            <FaSortDesc
              className={classnames(bem.e("sortable-icon"), {
                [bem.e("sortable-active")]: sortedRoles && sortedRoles.desc,
              })}
            />
          </div>
        </div>
      ),
      accessor: "roles_string",
      Cell: ({ original: user }) => {
        const { is_active } = user;
        let rolesString = user.roles_string;
        let isAdmin = false;
        if (user.broadcasting_group_admin) {
          rolesString = props.intl.formatMessage({
            id: "user > broadcasting group admin",
          });
          isAdmin = true;
        } else if (user.admin_type === "market_admin") {
          rolesString = props.intl.formatMessage({ id: "user > market admin" });
          isAdmin = true;
        }
        return (
          <span
            className={classnames({
              [bem.e("td-value")]: !isAdmin,
              [bem.e("text-inactive")]: !is_active,
            })}
          >
            {rolesString ? (
              <Tooltip
                placement="topLeft"
                trigger={["click", "hover"]}
                overlayClassName={bem.e("tooltip")}
                overlay={<div className={"market-string"}>{rolesString}</div>}
              >
                <div className={"market-string"}>{rolesString}</div>
              </Tooltip>
            ) : (
              <FormattedMessage id="user > none" />
            )}
          </span>
        );
      },
    });
    items.push({
      Header: <FormattedMessage id="user > teams and stations" />,
      sortable: false,
      accessor: "stations_string",
      Cell: ({ original: user }) => {
        const { is_active } = user;
        const stationsArr = map(user.stations, (item) => {
          if (item.call_letters && item.name) {
            return `${item.call_letters} - ${item.name}`;
          }
          return item.name;
        })
          .concat(map(user.team_groups, (team) => team.name))
          .filter((string) => string)
          .map((string) => string.toLowerCase());
        const stationsString = sortBy(stationsArr).join(", ");
        return (
          <span
            className={classnames(bem.e("td-value"), {
              [bem.e("text-inactive")]: !is_active,
            })}
          >
            {stationsArr.length > 0 ? (
              <Tooltip
                placement="topLeft"
                trigger={["click", "hover"]}
                overlayClassName={bem.e("tooltip")}
                overlay={
                  <div className={"market-string text-caps"}>
                    {stationsString}
                  </div>
                }
              >
                <div className={"market-string text-caps"}>
                  {stationsString}
                </div>
              </Tooltip>
            ) : (
              <FormattedMessage id="user > none" />
            )}
          </span>
        );
      },
    });
    const sortedOutOfOffice = find(
      sorted,
      (item) => item.id === "out_of_office"
    );
    items.push({
      Header: (
        <div className={bem.e("th-sort")}>
          <FormattedMessage id="user > out of office" />
          <div className={bem.e("sortable")}>
            <FaSortAsc
              className={classnames(bem.e("sortable-icon"), {
                [bem.e("sortable-active")]:
                  sortedOutOfOffice && !sortedOutOfOffice.desc,
              })}
            />
            <FaSortDesc
              className={classnames(bem.e("sortable-icon"), {
                [bem.e("sortable-active")]:
                  sortedOutOfOffice && sortedOutOfOffice.desc,
              })}
            />
          </div>
        </div>
      ),
      accessor: "out_of_office",
      Cell: ({ original: user }) => {
        const outOfOfficeSettings = map(user.out_of_office_settings, (item) => {
          const arr = [];
          if (item.from_date) {
            arr.push(moment(item.from_date, "YYYY-MM-DD").format("MM/DD"));
          }
          if (item.to_date) {
            arr.push(moment(item.to_date, "YYYY-MM-DD").format("MM/DD"));
          }
          return arr.join(" - ");
        }).join(", ");
        return (
          <span className={bem.e("td-value")}>
            <Tooltip
              placement="topLeft"
              trigger={["click", "hover"]}
              overlayClassName={bem.e("tooltip")}
              overlay={
                <div className={"market-string"}>{outOfOfficeSettings}</div>
              }
            >
              <div className={"market-string"}>{outOfOfficeSettings}</div>
            </Tooltip>
          </span>
        );
      },
    });
    items.push({
      Header: <FormattedMessage id="user > Actions" />,
      sortable: false,
      resizable: false,
      filterable: false,
      id: "actions",
      style: { justifyContent: "center", textAlign: "center" },
      Cell: ({ original: user }) => {
        return (
          <ButtonGroup>
            <PrivilegedComponent requires={[PRIVILEGES.USER_SCHEDULING]}>
              <Button
                color="link"
                onClick={() => {
                  history.push(
                    URL.USER({
                      user_id: user.id,
                    })
                  );
                }}
                className={bem.e("action-button")}
              >
                <EditIcon />
              </Button>
            </PrivilegedComponent>
          </ButtonGroup>
        );
      },
    });
    return items;
  }, [sorted, users, filtered]);

  return (
    <Row className={bem.b()}>
      <Col>
        <Card>
          <CardBody>
            <Row>
              <Col xs="12">
                <div className={bem.e("head-admin-list-wrapper")}>
                  <TextInput
                    type="text"
                    name="q"
                    value={searchText}
                    onChange={onChangeInput}
                    onKeyDown={onSearchKeyDown}
                    placeholder={props.intl.formatMessage({
                      id: "user > placeholder search",
                    })}
                    rightComponent={
                      <div className="search-buttons">
                        {searchText && (
                          <div
                            onClick={onClearSearch}
                            className={"button-search-close"}
                          >
                            <MdClose />
                          </div>
                        )}

                        <Button
                          color=""
                          className="btn-search"
                          onClick={onSearchClicked}
                        >
                          <FaSearch size={17} />
                        </Button>
                      </div>
                    }
                  />
                </div>
              </Col>
            </Row>
            <Table
              className={classNames(
                bem.e("out-of-office-table"),
                bem.e("table")
              )}
              manual
              data={userList}
              pages={pages}
              loading={users.loading}
              showPagination={false}
              total={total}
              // Controlled props
              filtered={filtered}
              sorted={sorted}
              // Callbacks
              onSortedChange={(sorted) => setSorted(sorted)}
              columns={columns}
              getTdProps={(state, rowInfo, column, instance) => {
                return {
                  onClick: (e, handleOriginal) => {
                    const { original } = rowInfo;
                    const columnId = column.id;
                    if (!includes(["actions"], columnId)) {
                      onClickItem(original);
                    } else if (handleOriginal) {
                      handleOriginal();
                    }
                  },
                };
              }}
            />
            <Spinner isLoading={props.isLoading} />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
OutOfOfficeList.propsType = {
  onSelectedEdit: PropTypes.func,
};
export default OutOfOfficeList;
