import TagsList from "../view";

import { injectIntl } from "react-intl";
import { compose, withState, withHandlers, lifecycle } from "recompose";
import { connect } from "react-redux";
import { get, filter, includes, isEmpty, forEach, pick, find } from "lodash";
import { STATION } from 'utils/constants';


export const TAG_TYPE_FORMAT = "format";
export const TAG_TYPE_DMAs = "market";

export default injectIntl(
  compose(
    connect((state, props) => {
      return {
        availableTags: get(state, "auth.info.tags.tags", []),
      };
    }),
    withState("tags", "setTags", {}),
    withState("expandedRow", "setExpandedRow", null),
    withHandlers({
      expandRow: ({expandedRow, setExpandedRow}) => (id) => {
        if(expandedRow !== id){
          setExpandedRow(id);
        }else{
          setExpandedRow(null);
        }
      },
    }),
    lifecycle({
      componentDidMount (){

        let { availableStations, availableTags, tagType } = this.props;

        let stations = filter(availableStations, station => station.type === STATION);

        let tagOptions = [];

        if (tagType === TAG_TYPE_FORMAT) {
          availableTags = filter(availableTags, (tag) => includes(tag, "Format"));
        }

        let mapTags = (tag, path, root) => {
          if (!root) root = tag;
          if (path) {
            if (
              root.title === "Location" &&
              tag.children &&
              tag.children.length > 0
            ) {
            } else {

              let tagStations = {};

              stations.filter(station => includes(station.tags, tag.id)).forEach(station => {
                  tagStations[station.key] = pick(station, ['key', 'station_key', 'market', 'format', 'team', 'audience', 'name', 'call_letters'])
              });

              if(!isEmpty(tagStations)){
                tagOptions[tag.id] = {
                  id: tag.id,
                  label: path,
                  type: tagType,
                  stations : tagStations,
                };
              }
            }
          }
          tag.children &&
            tag.children.map((child, index) => {
              let childPath = path ? path + " / " + child.title : child.title;
              if (
                this.props.isHideRootPath &&
                includes(["Location", "Format"], path)
              ) {
                childPath = path ? child.title : child.title;
              }
              mapTags(child, childPath, root);
            });
        };

        availableTags.map((tag) => {
          if (tag) {
            mapTags(tag, false);
          }
        });

        //set tags
        this.props.setTags((previousTags) => {

            let newTags = {...previousTags, ...tagOptions};

            //get saved tags and set them against selected tags
            let savedTags = get(this.props.selectedRecord, "data.selected_tags", {});

            forEach(savedTags, savedTag => {
              let tag = find(newTags, {id : savedTag}); 

              if(tag){
                this.props.updateSelectedTags(true, tag, tag.type, false);
              }
            });

            return newTags;
        });
      }
    })
  )(TagsList)
);