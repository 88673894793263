import React, { useState } from "react";
import View, { isSameSelectedStations } from "./view";
export { isSameSelectedStations };
export default function FormAssignments(props) {
  const [isOpenAssignEntitiesModal, setIsOpenAssignEntitiesModal] = useState(
    false
  );
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
  return (
    <View
      {...props}
      {...Object.assign(
        {},
        {
          isOpenAssignEntitiesModal,
          setIsOpenAssignEntitiesModal,
          shouldBlockNavigation,
          setShouldBlockNavigation,
        }
      )}
    />
  );
}
