import * as Types from "store/types/settings";
const initialState = {
  production_settings: {},
  production_workflow_settings: {},
  enterprise_settings: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case Types.GET_PRODUCTION_SETTINGS_REQUEST:
      return {
        ...state,
        production_settings: {
          data: null,
          loading: true,
          error: false,
        },
      };
    case Types.GET_PRODUCTION_SETTINGS_SUCCESS:
      return {
        ...state,
        production_settings: {
          data: action.payload,
          loading: false,
          error: false,
        },
      };
    case Types.GET_PRODUCTION_SETTINGS_ERROR:
      return {
        ...state,
        production_settings: {
          data: null,
          loading: false,
          error: action.payload,
        },
      };

    case Types.UPDATE_PRODUCTION_SETTINGS_REQUEST:
      return {
        ...state,
        production_settings: {
          ...state.production_settings,
          loading: true,
          error: false,
        },
      };
    case Types.UPDATE_PRODUCTION_SETTINGS_SUCCESS:
      return {
        ...state,
        production_settings: {
          data: action.payload,
          loading: false,
          error: false,
        },
      };
    case Types.UPDATE_PRODUCTION_SETTINGS_ERROR:
      return {
        ...state,
        production_settings: {
          ...state.production_settings,
          loading: false,
          error: action.payload,
        },
      };
    // enterprise settings
    case Types.GET_ENTERPRISE_SETTINGS_REQUEST:
      return {
        ...state,
        enterprise_settings: {
          data: null,
          loading: true,
          error: false,
        },
      };
    case Types.GET_ENTERPRISE_SETTINGS_SUCCESS:
      return {
        ...state,
        enterprise_settings: {
          data: action.payload,
          loading: false,
          error: false,
        },
      };
    case Types.GET_ENTERPRISE_SETTINGS_ERROR:
      return {
        ...state,
        enterprise_settings: {
          data: null,
          loading: false,
          error: action.payload,
        },
      };

    case Types.UPDATE_ENTERPRISE_SETTINGS_REQUEST:
      return {
        ...state,
        enterprise_settings: {
          ...state.enterprise_settings,
          loading: true,
          error: false,
        },
      };
    case Types.UPDATE_ENTERPRISE_SETTINGS_SUCCESS:
      return {
        ...state,
        enterprise_settings: {
          data: action.payload,
          loading: false,
          error: false,
        },
      };
    case Types.UPDATE_ENTERPRISE_SETTINGS_ERROR:
      return {
        ...state,
        enterprise_settings: {
          ...state.enterprise_settings,
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
}
