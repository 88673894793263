import * as Types from "store/types/zetta";
import { LOGOUT_USER } from "store/types/auth";

const initialState = {
  items: {
    data: null,
    error: null,
    loading: false,
  },
  updateSettings: {
    error: null,
    loading: false,
  },
  toggleEnable: {
    error: null,
    loading: false,
  },
  deleteSettings: {
    error: null,
    loading: false,
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_ZETTA_SETTINGS_REQUEST: {
      return {
        ...state,
        items: { ...state.items, error: null, loading: true },
      };
    }
    case Types.FETCH_ZETTA_SETTINGS_SUCCESS: {
      return {
        ...state,
        items: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    }
    case Types.FETCH_ZETTA_SETTINGS_ERROR: {
      return {
        ...state,
        items: {
          data: null,
          error: action.payload,
          loading: false,
        },
      };
    }
    case Types.UPDATE_ZETTA_SETTINGS_REQUEST: {
      return {
        ...state,
        updateSettings: {
          loading: true,
          error: null,
        },
      };
    }
    case Types.UPDATE_ZETTA_SETTINGS_SUCCESS: {
      return {
        ...state,
        updateSettings: {
          loading: false,
          error: null,
        },
      };
    }
    case Types.UPDATE_ZETTA_SETTINGS_ERROR: {
      return {
        ...state,
        updateSettings: {
          loading: false,
          error: null,
        },
      };
    }

    case Types.TOGGLE_ZETTA_ENABLE_REQUEST: {
      return {
        ...state,
        toggleEnable: {
          loading: true,
          error: null,
        },
      };
    }
    case Types.TOGGLE_ZETTA_ENABLE_SUCCESS: {
      return {
        ...state,
        toggleEnable: {
          loading: false,
          error: null,
        },
      };
    }
    case Types.TOGGLE_ZETTA_ENABLE_ERROR: {
      return {
        ...state,
        toggleEnable: {
          loading: false,
          error: null,
        },
      };
    }

    case Types.DELETE_ZETTA_SETTINGS_REQUEST: {
      return {
        ...state,
        deleteSettings: {
          loading: true,
          error: null,
        },
      };
    }
    case Types.DELETE_ZETTA_SETTINGS_SUCCESS: {
      return {
        ...state,
        deleteSettings: {
          loading: false,
          error: null,
        },
      };
    }
    case Types.DELETE_ZETTA_SETTINGS_ERROR: {
      return {
        ...state,
        deleteSettings: {
          loading: false,
          error: null,
        },
      };
    }
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
}
