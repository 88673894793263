import React, { useState } from "react";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import {
  get,
  find,
  toLower,
  map,
  upperCase,
  includes,
  isEmpty,
  isArray,
  isObject,
} from "lodash";
import { FaSortDesc, FaSortAsc } from "react-icons/lib/fa";
import DeadlineTimer from "components/DeadlineTimer";
import { Button, Table } from "reactstrap";
import EmptyAnnouncement from "components/EmptyAnnouncement";
import NoItemsFound from "components/NoItemsFound";
import FileInstructions from "components/MultipleMarketComponents/FileInstructions";
import AssignTeam from "components/MultipleMarketComponents/AssignTeam";
import AssignVoTeam from "components/MultipleMarketComponents/AssignVoTeam";
import CommentsPanel from "components/MultipleMarketComponents/CommentsPanel";
import useInfiniteScroll from "utils/useInfiniteScroll";
import { default_templates } from "utils/defaults";
import bn from "utils/bemnames";
import { formatDisplayDate } from "utils/helpers";
import moment from "moment";
import {
  EditIcon,
  NewWindowIcon,
  CheckDoneIcon,
  CommentsIcon,
} from "components/CustomIcons";
import RightSidebar from "components/MultipleMarketComponents/RightSidebar";
import { getFileInstructions } from "components/MultipleMarketComponents/FileInstructions/helper";
import { getTaggedMessages } from "./helper";
import ConfirmationModal from "components/ConfirmationModal";
import Spinner from "components/Spinner";
import { getRoleIdByName } from "utils/helpers";

const bem = bn.create("production-manager-queue");

const View = ({
  actionSort,
  sort,
  processList,
  filter,
  hasMoreItems,
  loadItems,
  isLoading,
  search,
  intl,
  dateFormatByServer,
  info,
  user,
  enterpriseSettings,
  setIsShowSuccessModal,
  isShowSuccessModal,
  isAssigning,
  onAssignTeam,
  onSendMessage,
  members,
  onClickRow,
}) => {
  const stationsData = get(info, "stations", []);
  const isDisplayOrderTemplate =
    document.location.href.indexOf("multi_market_production_queue") !== -1;
  const [isOpenRightSidebar, setIsOpenRightSidebar] = useState(false);
  const [isOpenCommentsPanel, setIsOpenCommentsPanel] = useState(false);
  const [selectedProcess, setSelectedProcess] = useState(null);
  const [sidebarType, setSidebarType] = useState("");
  const orderLengthDisplay = (process) => {
    const orderLengthCustom = get(process, "_source.order_length_custom");
    let orderLength = get(process, "_source.order_length") || "00";
    if (orderLengthCustom) {
      const momentTime = moment.utc(parseInt(orderLength) * 1000);
      const formatted = momentTime.format(
        momentTime.minutes() > 0 ? "mm:ss" : ":ss"
      );
      return formatted;
    }
    return process._source.order_length
      ? `${process._source.order_length} sec`
      : "";
  };
  const timeToAirDisplay = (process) => {
    // 	Deadline should be none for Reports task
    if (get(process, "_source.process_step") === "report")
      return <FormattedMessage id="process > none" />;
    const timezone = get(process, "_source.timezone", null);
    const stepDeadline = get(process, "_source.step_deadline");
    if (stepDeadline && filter === "multi_market_list") {
      return <DeadlineTimer date={stepDeadline} timezone={timezone} />;
    }
    const orderStartDate = get(process, "_source.order_start_date");
    const dueDate = get(process, "_source.due_date");
    const date = dueDate || orderStartDate;
    if (!date) return null;
    return <DeadlineTimer date={date} timezone={timezone} />;
  };
  const getVoicePreference = (process) => {
    const spotInfo = get(process, "_source.spot_info", []).find(
      (spot) => spot.key === process._id
    );
    const voicePreference = get(spotInfo, "voice_preference", "");
    if (!voicePreference) return ""; //no_preference
    return intl.formatMessage({ id: `process > ${voicePreference}` });
  };
  const statusDisplay = (process) => {
    const processStep = get(process, "_source.process_step", "draft");
    let row_template = default_templates.find(
      (t) => t._id === get(process, "_source.template_key", "basic_production")
    );
    let step = get(row_template, `_source.steps.${processStep}`);
    let title =
      get(process, "_source.imported_from_wo", false) &&
      get(process, "_source.template_selected_for_wo", false) == "" ? (
        <FormattedMessage id={"process > title wo draft"} />
      ) : (
        <FormattedMessage id={"process > title draft"} />
      );
    let color = "#9AA4CA";
    if (title !== "draft") {
      color = get(step, "caption_color", "#9AA4CA");
    }
    if (get(step, "title")) {
      title = get(step, "title");
      if (step.key === "approve_recording") {
        if (get(process, "_source.sent_to_client_for_spot_review")) {
          title = <FormattedMessage id={"process > title client review"} />;
        }
      }
      if (step.key === "approve_script") {
        if (get(process, "_source.sent_to_client_for_script_review")) {
          title = <FormattedMessage id={"process > title client review"} />;
        }
      }
      if (step.key === "order_details") {
        if (get(process, "_source.order_details_status") === "draft")
          title = "draft";
      }
      if (step.key === "record_script") {
        if (get(process, "_source.approve_recording_status") === "rejected") {
          title = <FormattedMessage id={"process > title spot change"} />;
          color = "#E40061";
        }
      }
      if (step.key === "write_script") {
        if (get(process, "_source.approve_script_status") === "rejected")
          title = <FormattedMessage id={"process > title script change"} />;
        color = "#E40061";
      }
      if (step.key === "assign_vo") {
        title = <FormattedMessage id={"process > title assign vo"} />;
        color = "#A82AC0";
      }
      if (step.key === "voice_over") {
        if (get(process, "_source.voice_over_status") === "rejected") {
          title = <FormattedMessage id={"process > title change request"} />;
          color = "#E40061";
        } else {
          title = <FormattedMessage id={"process > title voice over"} />;
        }
      }
      if (step.key === "continuity") {
        if (get(process, "_source.continuity_status") === "change_request") {
          title = <FormattedMessage id={"process > title change request"} />;
          color = "#E40061";
        }
      }
    }
    return (
      <span
        className={classnames(
          bem.e("order-status"),
          bem.e(
            `order-status-${
              title.replace ? toLower(title.replace(/ /g, "-")) : ""
            }`
          )
        )}
        style={{ color }}
      >
        {title}
      </span>
    );
  };
  const flightDatesDisplay = (process) => {
    const startDate = get(process, "_source.order_start_date");
    const stepDeadline = get(process, "_source.step_deadline");

    const spot = find(
      process._source.spot_info,
      (item) => item.key === process._id
    );
    let dueDate = get(spot, "due_date") ? get(spot, "due_date") : startDate;
    if (stepDeadline && filter === "multi_market_list") {
      dueDate = stepDeadline;
    }
    return (
      <div className={bem.e("flight-dates")}>
        {dueDate && (
          <>
            <label>{intl.formatMessage({ id: "process > due date" })}</label>
            <span>
              {dueDate ? formatDisplayDate(dueDate, dateFormatByServer) : ""}
            </span>
          </>
        )}

        {startDate && (
          <>
            <label>{intl.formatMessage({ id: "process > start date" })}</label>
            <span>
              {startDate
                ? formatDisplayDate(startDate, dateFormatByServer)
                : ""}
            </span>
          </>
        )}
      </div>
    );
  };
  const getAssignData = (process) => {
    const producerAssignsVo = get(
      process,
      "_source.producer_assigns_vo",
      false
    );
    const processStep = get(process, "_source.process_step", "");
    const isProductionComplete =
      get(process, "_source.contract_status", "") === "Production Complete";
    const isProductionProvidedTags =
      get(process, "_source.contract_status", "") ===
      "Production Provided - Needs tags";

    const spotInfo = get(process, "_source.spot_info", []).find(
      (spot) => spot.key === process._id
    );
    //  If AE has provided the script and selected it as final script under Creative Details section, we don't need to show the Writer selection option.
    const isAEProvidedFinalScript =
      get(spotInfo, "script_type", "") === "final" &&
      get(spotInfo, "script", "");
    const writerUsers = get(process, "_source.role_writer.users", []).map(
      (user) => {
        const userInfo = find(
          members,
          (item) => Number(item.id) === Number(user.id)
        );
        let status = "";
        if (
          userInfo &&
          userInfo.current_tasks_count + userInfo.assigned_tasks_count >=
            get(enterpriseSettings, "writer_capacity", 0)
        ) {
          status = "overload";
        } else if (
          userInfo &&
          userInfo.current_tasks_count + userInfo.assigned_tasks_count <
            get(enterpriseSettings, "writer_capacity", 0)
        ) {
          status = "available";
        }
        if (userInfo && !userInfo.is_active) status = "inactive";

        return {
          ...user,
          status,
        };
      }
    );
    const voiceUsers = get(process, "_source.role_voice_talent.users", []).map(
      (user) => {
        const userInfo = find(
          members,
          (item) => Number(item.id) === Number(user.id)
        );
        let status = "";
        if (
          userInfo &&
          userInfo.current_tasks_count + userInfo.assigned_tasks_count >=
            get(enterpriseSettings, "voice_over_capacity", 0)
        ) {
          status = "overload";
        } else if (
          userInfo &&
          userInfo.current_tasks_count + userInfo.assigned_tasks_count <
            get(enterpriseSettings, "voice_over_capacity", 0)
        ) {
          status = "available";
        }
        if (userInfo && !userInfo.is_active) status = "inactive";

        return {
          ...user,
          status,
        };
      }
    );
    const producerUsers = get(process, "_source.role_producer.users", []).map(
      (user) => {
        const userInfo = find(
          members,
          (item) => Number(item.id) === Number(user.id)
        );
        let status = "";
        if (
          userInfo &&
          userInfo.current_tasks_count + userInfo.assigned_tasks_count >=
            get(enterpriseSettings, "producer_capacity", 0)
        ) {
          status = "overload";
        } else if (
          userInfo &&
          userInfo.current_tasks_count + userInfo.assigned_tasks_count <
            get(enterpriseSettings, "producer_capacity", 0)
        ) {
          status = "available";
        }
        if (userInfo && !userInfo.is_active) status = "inactive";

        return {
          ...user,
          status,
        };
      }
    );
    const dubberUsers = get(process, "_source.role_dubber.users", []).map(
      (user) => {
        const userInfo = find(
          members,
          (item) => Number(item.id) === Number(user.id)
        );
        let status = "";
        if (
          userInfo &&
          userInfo.current_tasks_count + userInfo.assigned_tasks_count >=
            get(enterpriseSettings, "dubber_capacity", 0)
        ) {
          status = "overload";
        } else if (
          userInfo &&
          userInfo.current_tasks_count + userInfo.assigned_tasks_count <
            get(enterpriseSettings, "dubber_capacity", 0)
        ) {
          status = "available";
        }
        if (userInfo && !userInfo.is_active) status = "inactive";

        return {
          ...user,
          status,
        };
      }
    );
    let rolesAndUsers = {};
    // Production complete have only Dubber
    if (isProductionComplete) {
      rolesAndUsers = {
        D: dubberUsers,
      };
    } else if (isProductionProvidedTags) {
      rolesAndUsers = {
        P: producerUsers,
        D: dubberUsers,
      };
    } else {
      if (!isAEProvidedFinalScript) {
        rolesAndUsers = {
          W: writerUsers,
        };
      }
      if (processStep === "assign_team") {
        if (producerAssignsVo) {
          rolesAndUsers = {
            ...rolesAndUsers,
            P: producerUsers,
            D: dubberUsers,
          };
        } else {
          rolesAndUsers = {
            ...rolesAndUsers,
            V: voiceUsers,
            P: producerUsers,
            D: dubberUsers,
          };
        }
      } else {
        rolesAndUsers = {
          ...rolesAndUsers,
          V: voiceUsers,
          P: producerUsers,
          D: dubberUsers,
        };
      }
    }

    const isValidTeam =
      Object.values(rolesAndUsers).filter((roleData) => {
        return (
          roleData.filter(
            (user) => user.status === "available" || user.status === "overload"
          ).length === 0
        );
      }).length === 0;
    return { rolesAndUsers, isValidTeam };
  };
  const getIsAllowAssignTeamByProcess = (process) => {
    const isProductionProvided =
      get(process, "_source.template_key", "") === "production_provided";

    if (isProductionProvided) return false;

    const isHubOrder = !isEmpty(
      get(process, "_source.team_production_manager", "")
    );

    if (!isHubOrder) return false;

    const isHubManager = getRoleIdByName("Hub Manager", info.enterprise_roles);

    if (isHubManager) return true;

    const producers = map(
      get(process, "_source.role_producer.users", []),
      (user) => {
        return Number(user.id);
      }
    );
    const isAssignedToProducer = includes(producers, Number(user.id));

    const recordScriptStatus = get(process, "_source.record_script_status", "");
    const processStep = process._source.process_step;
    const producerAssignsVo = get(
      process,
      "_source.producer_assigns_vo",
      false
    );

    if (
      isAssignedToProducer &&
      ["assign_vo", "voice_over", "record_script"].includes(processStep) &&
      producerAssignsVo &&
      !["approved", "completed"].includes(recordScriptStatus)
    ) {
      return true;
    }
    const isCheckedOrderAssignments = !!info.show_hub_assignment;

    return isCheckedOrderAssignments;
  };
  const assignDisplay = (rolesAndUsers, isAllowAssignTeam) => {
    if (!rolesAndUsers) return "";
    const text = Object.entries(rolesAndUsers).map(([role, users], index) => {
      return (
        <div className={bem.e("assign-role")} key={index}>
          <span className={bem.e("role-name")}>{role}</span>
          {users.length > 0 ? (
            <div className={bem.e("assign-users")}>
              {users.map((item, _index) => (
                <span key={_index}>
                  <i
                    className={classnames(
                      bem.e("user-status"),
                      bem.e(`status-${item.status}`)
                    )}
                  ></i>
                  {item.name}
                </span>
              ))}
            </div>
          ) : (
            <span
              className={classnames(bem.e("assign-select"), {
                [bem.e("assign-unassigned")]: isAllowAssignTeam,
              })}
            >
              {intl.formatMessage({
                id: isAllowAssignTeam
                  ? "process > select"
                  : "process > unassigned",
              })}
            </span>
          )}
        </div>
      );
    });
    return text;
  };
  const fileInstructionsDisplay = (process) => {
    const items = getFileInstructions(process, intl);
    const isSpecOrder = get(process, "_source.order_sold") === false;

    return (
      <div className={classnames(bem.e("instructions"))}>
        {isSpecOrder && (
          <div className={bem.e("instructions-spec-text")}>
            <FormattedMessage id="process > spec spot" />
          </div>
        )}
        {map(items, (item, index) => (
          <div key={index} className={bem.e("instructions-item")}>
            {item.isCompleted ? (
              <CheckDoneIcon color={"#73A703"} />
            ) : (
              <span className={bem.e("fake-check")} />
            )}
            <span>{item.title}</span>
          </div>
        ))}
      </div>
    );
  };
  const renderEmpty = () => {
    if (filter === "my_action_items" && !search) {
      return <EmptyAnnouncement />;
    } else {
      return <NoItemsFound />;
    }
  };
  const stationsDisplay = (process) => {
    const orderStations = get(process, "_source.order_stations", [])
      .map((item) => find(stationsData, (i) => i.station_key === item))
      .filter((i) => i);
    return (
      <div className={bem.e("stations")}>
        {orderStations.map((item, key) => (
          <span key={key}>
            {upperCase(item.call_letters)} {item.name}
          </span>
        ))}
      </div>
    );
  };
  const onClickOpenSidebar = (process) => {
    const producers = map(
      get(process, "_source.role_producer.users", []),
      (user) => {
        return Number(user.id);
      }
    );
    const recordScriptStatus = get(process, "_source.record_script_status", "");
    const processStep = process._source.process_step;
    const producerAssignsVo = get(
      process,
      "_source.producer_assigns_vo",
      false
    );
    const isAssignedToProducer = includes(producers, Number(user.id));
    setSelectedProcess(process);
    setIsOpenRightSidebar(true);
    // NOTE: Producer can edit the VO assignments after they complete this step UNTIL they finish the RECORDING step
    if (
      isAssignedToProducer &&
      ["assign_vo", "voice_over", "record_script"].includes(processStep) &&
      producerAssignsVo &&
      !["approved", "completed"].includes(recordScriptStatus)
    ) {
      setSidebarType("assign-vo");
    } else {
      setSidebarType("assign");
    }
  };
  const renderRightSidebar = () => {
    if (sidebarType === "assign")
      return (
        <AssignTeam
          process={selectedProcess}
          onClose={() => setIsOpenRightSidebar(false)}
        />
      );
    if (sidebarType === "assign-vo")
      return (
        <AssignVoTeam
          process={selectedProcess}
          onClose={() => setIsOpenRightSidebar(false)}
        />
      );
    return <FileInstructions process={selectedProcess} />;
  };

  useInfiniteScroll({
    useWindow: false,
    elementId: "cr-app-main",
    onLoadMore: loadItems,
    shouldLoadMore: hasMoreItems,
  });
  return (
    <div className={bem.e("table-wrapper")}>
      <Table className={bem.e("table")}>
        <thead>
          <tr>
            <th
              className={classnames(
                "col-table-checkbox",
                bem.e("table-first-head")
              )}
            >
              {/* checkbox */}
              {/* <Checkbox
                disabled={processList.length === 0}
                checked={isSelectedAll}
                onChange={() => {
                  if (isSelectedAll) {
                    setSelectedItems({});
                  } else {
                    const newSelectedItems = {};
                    processList.forEach((item) => {
                      newSelectedItems[item._id] = true;
                    });
                    setSelectedItems(newSelectedItems);
                  }
                }}
              /> */}
            </th>
            <th
              onClick={() => actionSort("order_title")}
              className={classnames("can-click", "col-table-20")}
            >
              <div className={bem.e("item-sortable")}>
                <FormattedMessage id="process > client/title" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_title",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_title desc",
                    })}
                  />
                </div>
              </div>
            </th>
            <th className="col-table-10">
              <FormattedMessage id="process > entity" />
            </th>
            <th className="col-table-10">
              <FormattedMessage id="process > order" />
            </th>
            <th className="col-table-10">
              <FormattedMessage id="process > cart" />
            </th>
            <th
              onClick={() => actionSort("due_date")}
              className="col-table-10 can-click"
            >
              <div className={bem.e("item-sortable-center")}>
                <FormattedMessage id="process > Deadline" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "due_date",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "due_date desc",
                    })}
                  />
                </div>
              </div>
            </th>
            <th className="col-table-15 text-center">
              <FormattedMessage id="process > assignments" />
            </th>
            <th
              onClick={() => actionSort("process_step")}
              className="col-table-10 can-click"
            >
              <div className={bem.e("item-sortable-center")}>
                <FormattedMessage id="process > Status" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "process_step",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "process_step desc",
                    })}
                  />
                </div>
              </div>
            </th>
            <th className="col-table-15">
              <FormattedMessage id="process > file instructions" />
            </th>
          </tr>
        </thead>
        <tbody>
          {processList.length === 0 && !isLoading ? (
            <tr>
              <td colSpan="9">{renderEmpty()}</td>
            </tr>
          ) : (
            processList.map((process) => {
              if (!process._source.template_key) return null;
              const clientName = get(process, "_source.client_name", "");
              const orderTitle = get(process, "_source.order_title", "");
              const spotInfo = get(process, "_source.spot_info", []).find(
                (spot) => spot.key === process._id
              );
              const salesName = get(
                process,
                "_source.role_sales_person.name",
                ""
              );
              const taggedMessages = getTaggedMessages(
                get(process, "_source.messages", []),
                user
              );
              const messages = get(process, "_source.messages", []);
              const totalMessages = isArray(messages)
                ? messages.length
                : isObject(messages)
                ? Object.keys(messages).length
                : 0;

              const { rolesAndUsers, isValidTeam } = getAssignData(process);
              const processStep = get(process, "_source.process_step", "");
              const isShowAssignButton = processStep === "assign_team";
              const isAllowAssignTeam = getIsAllowAssignTeamByProcess(process);
              return (
                <tr key={process._id}>
                  {/* checkbox */}
                  <td
                    className={classnames(
                      "col-table-checkbox",
                      bem.e("first-col")
                    )}
                  >
                    {/* <Checkbox
                      checked={!!selectedItems[process._id]}
                      onChange={() => {
                        setSelectedItems({
                          ...selectedItems,
                          [process._id]: selectedItems[process._id]
                            ? false
                            : true,
                        });
                      }}
                    /> */}
                    <div className={"text-center"}>
                      <div
                        className={bem.e("comments")}
                        onClick={() => {
                          setSelectedProcess(process);
                          setIsOpenCommentsPanel(true);
                        }}
                      >
                        <CommentsIcon />
                        {taggedMessages > 0 ? (
                          <span className={bem.e("comment-red")}>
                            {taggedMessages}
                          </span>
                        ) : totalMessages > 0 ? (
                          <span className={bem.e("comment-white")}>
                            {totalMessages}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </td>
                  {/* title */}
                  <td
                    className={classnames("col-table-20")}
                    onClick={() => onClickRow(process)}
                  >
                    <strong className={bem.e("client-name")}>
                      {clientName}
                    </strong>
                    <span className={bem.e("order-title")}>{orderTitle}</span>
                    <div className={bem.e("order-info-item")}>
                      <label>
                        {intl.formatMessage({ id: "process > length" })}:
                      </label>
                      <span>{orderLengthDisplay(process)}</span>
                    </div>
                    {get(spotInfo, "isci", "") ? (
                      <div className={bem.e("order-info-item")}>
                        <label>
                          {intl.formatMessage({ id: "process > isci" })}:
                        </label>
                        <span>{get(spotInfo, "isci", "")}</span>
                      </div>
                    ) : null}
                    <div className={bem.e("order-info-item")}>
                      <label>
                        {intl.formatMessage({ id: "process > sales" })}:
                      </label>
                      <span>{salesName}</span>
                    </div>
                  </td>
                  {/* entity */}
                  <td
                    className={classnames("col-table-10")}
                    onClick={() => onClickRow(process)}
                  >
                    {stationsDisplay(process)}
                  </td>
                  {/* order */}
                  <td
                    className={classnames("col-table-10")}
                    onClick={() => onClickRow(process)}
                  >
                    <div className={bem.e("order")}>
                      {get(
                        process,
                        "_source.contract_stations_order_number_array",
                        []
                      ).map((item, i) => (
                        <span key={i}>{item.number}</span>
                      ))}
                    </div>
                  </td>
                  {/* cart */}
                  <td
                    className="col-table-10"
                    onClick={() => onClickRow(process)}
                  >
                    <div className={bem.e("cart")}>
                      {get(spotInfo, "cart_number") ? (
                        <span>{get(spotInfo, "cart_number", "")}</span>
                      ) : (
                        <span className={bem.e("cart-pending")}>
                          {intl.formatMessage({
                            id: "process > title pending",
                          })}
                        </span>
                      )}
                    </div>
                  </td>
                  {/* deadline */}
                  <td
                    className="col-table-10"
                    onClick={() => onClickRow(process)}
                  >
                    {timeToAirDisplay(process)}
                    {flightDatesDisplay(process)}
                  </td>
                  <td className="col-table-15">
                    {/* assignments */}
                    {isDisplayOrderTemplate &&
                      process._source.contract_status !==
                        "Production Needed" && (
                        <span className={bem.e("order-template-text")}>
                          {process._source.contract_status ===
                          "Production Complete"
                            ? intl.formatMessage({
                                id: `process > production provided`,
                              })
                            : process._source.contract_status ===
                              "Production Provided - Needs tags"
                            ? intl.formatMessage({
                                id: `process > production provided needed tags`,
                              })
                            : null}
                        </span>
                      )}
                    <div
                      className={classnames(
                        bem.e("box"),
                        bem.e("box-assign-team"),
                        {
                          [bem.e("box-assign-team-view")]: !isAllowAssignTeam,
                        }
                      )}
                    >
                      <div
                        className={bem.e("box-content")}
                        onClick={() => {
                          if (!isAllowAssignTeam) return;
                          onClickOpenSidebar(process);
                        }}
                      >
                        {assignDisplay(rolesAndUsers, isAllowAssignTeam)}
                      </div>
                      {isAllowAssignTeam ? (
                        <div className={bem.e("box-actions")}>
                          {isShowAssignButton && isValidTeam ? (
                            <Button
                              color="blue"
                              type="button"
                              className="btn btn-radius"
                              disabled={!isValidTeam}
                              onClick={(e) => {
                                e.preventDefault();
                                onAssignTeam(rolesAndUsers, process);
                              }}
                            >
                              {intl.formatMessage({ id: "process > assign" })}
                            </Button>
                          ) : (
                            <span />
                          )}

                          <div
                            className={bem.e("btn-edit")}
                            onClick={() => {
                              onClickOpenSidebar(process);
                            }}
                          >
                            <EditIcon />
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </td>
                  {/* status */}
                  <td
                    className="col-table-10 text-center"
                    onClick={() => onClickRow(process)}
                  >
                    <span className={bem.e("td-value")}>
                      {statusDisplay(process)}
                    </span>
                  </td>
                  <td className="col-table-15">
                    {/* files instructions */}
                    <div
                      className={bem.e("box")}
                      onClick={() => {
                        setSelectedProcess(process);
                        setIsOpenRightSidebar(true);
                        setSidebarType("instructions");
                      }}
                    >
                      <div
                        className={classnames(
                          bem.e("box-content"),
                          "scroll-bar-style"
                        )}
                      >
                        {fileInstructionsDisplay(process)}
                      </div>
                      <div className={bem.e("box-actions")}>
                        <div className={bem.e("voice-text")}>
                          <span>
                            {intl.formatMessage({ id: "process > voice" })}
                          </span>
                          <strong>{getVoicePreference(process)}</strong>
                        </div>
                        <div className={bem.e("btn-edit")}>
                          <NewWindowIcon width={16} height={15} />
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </Table>
      <RightSidebar
        isOpen={isOpenRightSidebar}
        onCancel={() => setIsOpenRightSidebar(false)}
        type={sidebarType}
        process={selectedProcess}
        children={renderRightSidebar()}
      />
      <CommentsPanel
        isOpen={isOpenCommentsPanel}
        onCancel={() => {
          setIsOpenCommentsPanel(false);
        }}
        processKey={get(selectedProcess, "_id", "")}
        templateKey={get(selectedProcess, "_source.template_key", "")}
        conversationData={
          selectedProcess
            ? {
                role_account_executive: get(
                  selectedProcess,
                  "_source.role_account_executive"
                ),
                role_production_manager: get(
                  selectedProcess,
                  "_source.role_production_manager"
                ),
                role_writer: get(selectedProcess, "_source.role_writer"),
                role_voice_talent: get(
                  selectedProcess,
                  "_source.role_voice_talent"
                ),
                role_producer: get(selectedProcess, "_source.role_producer"),
                role_continuity: get(
                  selectedProcess,
                  "_source.role_continuity"
                ),
                role_dubber: get(selectedProcess, "_source.role_dubber"),
              }
            : null
        }
        onSubmit={(chatValue) => onSendMessage(chatValue, selectedProcess)}
      />
      <ConfirmationModal
        isOpen={isShowSuccessModal}
        state="success"
        title={<FormattedMessage id="select team > team assigned" />}
        onToggle={() => setIsShowSuccessModal(!isShowSuccessModal)}
        onCancel={() => setIsShowSuccessModal(!isShowSuccessModal)}
      />
      <Spinner isLoading={isAssigning} />
    </div>
  );
};
export default View;
