import React, { useEffect, useState } from "react";
import bn from "utils/bemnames";
import Spinner from "components/Spinner";
import classnames from "classnames";
import URL from "utils/urls";
import { PRIVILEGES } from "utils/constants";
import { first, get, map, orderBy, find } from "lodash";
import history from "components/History";
import ExportModal from "components/ExportModal";
import SaveSearchModal from "components/SaveSearchModal";
import ReportsTable from "../Common/ReportsTable";
import ReportsListHeader from "../Common/ReportsListHeader";
import AdvancedSearchModal from "../Common/AdvancedSearchModal";
import { validatePrivileges } from "utils/helpers";
import AudiencePickerModal from "components/AudiencePickerModal";
import ConfirmationModal from "components/ConfirmationModal";
const bem = bn.create("process-list");

export default function PromotionReportsList(props) {
  const {
    winners,
    isLoading,
    onSort,
    sorted,
    getMoreWinners,
    onAdvancedSearch,
    advancedSearch,
    user,
    isShowSaveSearch,
    setIsShowSaveSearch,
    filtered,
    onChangeFiltered,
    setIsSubmittedAdvancedSearch,
    setAdvancedSearch,
    setFiltered,
    promotionMultiMarket,
    intl,
    setSelectedItems,
    info,
  } = props;
  const [optionYears, setOptionYears] = useState([]);
  const [optionStations, setOptionStations] = useState([]);
  const [allStations, setAllStations] = useState([]);
  const isShowAudience = promotionMultiMarket;
  const d = new Date();
  const minYear = 2015,
    maxYear = d.getFullYear();
  useEffect(() => {
    if (optionStations.length > 0) return;

    const years = Array.apply(null, { length: maxYear + 1 })
      .map(Number.call, Number)
      .slice(minYear)
      .map((number) => ({
        label:
          number === maxYear
            ? intl.formatMessage({ id: "winners > current year" })
            : number,
        value: number,
      }));
    setOptionYears(orderBy(years, ["value"], ["desc"]));
    const newAllStations = map(props.stations, (item) => {
      return {
        value: item.station_key,
        label: item.call_letters,
        data: {
          firstLabel: item.call_letters,
          lastLabel: item.name,
        },
      };
    });
    let options = orderBy(newAllStations, ["label"], ["asc"]);
    setOptionStations(options);
    setAllStations(options);
    const newStations = options.map((i) => i.value);
    const newFiltered = filtered.map((item) => {
      if (item.key === "stations" && !isShowAudience) {
        return {
          ...item,
          value: newStations,
        };
      }
      if (item.key === "year") {
        return {
          ...item,
          // The default value will be last year. e.g, for now, it is 2021
          value: maxYear - 1,
        };
      }
      return item;
    });
    setFiltered(newFiltered);
    setAdvancedSearch({
      ...advancedSearch,
      stations: isShowAudience ? newStations : [],
    });
    props.getWinnersList({ filtered: newFiltered });
  }, []);
  const hasMoreItemsSavedSearch =
    get(props, "savedSearches.page", 1) < get(props, "savedSearches.pages", 1);
  const items = get(winners, "data.items", []);
  const pages = get(winners, "data.pages", 1);
  const page = get(winners, "data.page", 1);
  const total = get(winners, "data.total", 0);
  const privileges = get(user, "privileges", []);
  const filteredStationKeys = find(filtered, (i) => i.key === "stations");
  const selectedStations = get(filteredStationKeys, "value", []).map(
    (stationKey) => {
      return find(allStations, (item) => item.value === stationKey);
    }
  );
  const filteredYear = find(filtered, (i) => i.key === "year");

  const searchText = find(filtered, (i) => i.key === "keyword");
  const isSelectedAllStations =
    get(selectedStations, "length", 0) === get(allStations, "length", 0) &&
    selectedStations.length > 0;
  const onView = (item) => {
    const isUserHasEditPrivileges = validatePrivileges({
      requires: {
        or: [PRIVILEGES.EDIT_WINNER, PRIVILEGES.FULFILL_WINNER_PRIZES],
      },
      privileges,
      user,
    });
    history.push({
      pathname: URL.WINNER({
        action: isUserHasEditPrivileges ? "edit" : "view",
        winner_id: item._id,
      }),
    });
  };
  const setSelectedStations = (newStationsId) => {
    const newFiltered = onChangeFiltered("stations", newStationsId);
    setAdvancedSearch({
      ...advancedSearch,
      stations: newStationsId,
    });
    const newIsSelectedAllStations =
      get(newStationsId, "length", 0) === get(allStations, "length", 0);
    if (newIsSelectedAllStations && !get(searchText, "value", "")) {
      setIsShowSaveSearch(false);
    } else {
      setIsShowSaveSearch(true);
    }
    if (get(newStationsId, "length", 0) === 1) {
      props.onGetClocksData(newStationsId[0]);
    }
    props.getWinnersList({ filtered: newFiltered });
    setSelectedItems({});
  };
  const onSelectedAudience = (audienceGroup, addedStations, selectedTags) => {
    const stations = audienceGroup
      ? get(audienceGroup, "selected_stations", [])
      : addedStations;
    const newSelectedStations = map(stations, ({ station_key }) => station_key);
    setSelectedStations(newSelectedStations);
  };
  const isShowGuide = isShowAudience && selectedStations.length === 0;
  return (
    <div className={classnames("fluid-container", bem.b())}>
      <ReportsListHeader
        onClearSearch={() => {
          const newStations = allStations.map((i) => i.value);
          const newFiltered = filtered.map((item) => {
            if (item.key === "stations" && !isShowAudience) {
              return {
                ...item,
                value: newStations,
              };
            }
            if (item.key === "year") {
              return {
                ...item,
                // The default value will be last year. e.g, for now, it is 2021
                value: maxYear - 1,
              };
            }
            if (item.key === "keyword") {
              return {
                ...item,
                value: "",
              };
            }
            return item;
          });
          setFiltered(newFiltered);
          props.getWinnersList({ filtered: newFiltered });
          setIsSubmittedAdvancedSearch(false);
          if (isSelectedAllStations) setIsShowSaveSearch(false);
          setAdvancedSearch({
            prize_types: ["cash", "non_cash"],
            keyword: "",
            w9_status: ["signed", "unsigned"],
            min_value: "",
            start_date: "",
            end_date: "",
            prize_date_range: "distributed",
          });
          setSelectedItems({});
        }}
        setIsAudiencePickerModalOpen={props.setIsAudiencePickerModalOpen}
        isShowAudience={isShowAudience}
        onSearchClicked={props.onSearch}
        searchText={get(searchText, "value", "")}
        setSearchText={(newSearchText) => {
          onChangeFiltered("keyword", newSearchText);
          setAdvancedSearch({
            ...advancedSearch,
            keyword: newSearchText,
          });
          if (isSelectedAllStations && !newSearchText) {
            setIsShowSaveSearch(false);
          } else {
            setIsShowSaveSearch(true);
          }
        }}
        isShowSaveSearch={isShowSaveSearch}
        setIsShowAdvancedSearchModal={props.setIsShowAdvancedSearchModal}
        saveSearch={() => {
          props.getSavedSearchList();
          props.setIsShowSaveSearchModal(true);
        }}
        exportWinners={() => props.setIsShowExportModal(true)}
        optionStations={optionStations}
        selectedStations={selectedStations}
        setSelectedStations={(newStations) => {
          const newStationsId = map(newStations, (i) => i.value);
          setSelectedStations(newStationsId);
        }}
        optionYears={optionYears}
        setSelectedYear={(newYear) => {
          const newFiltered = onChangeFiltered(
            "year",
            get(newYear, "value", "")
          );
          if (maxYear === get(newYear, "value", "")) {
            setIsShowSaveSearch(false);
          } else {
            setIsShowSaveSearch(true);
          }
          props.getWinnersList({ filtered: newFiltered });
          setSelectedItems({});
        }}
        selectedYear={get(filteredYear, "value", "")}
        total={total}
        selectedItems={props.selectedItems}
        savedSearches={get(props, "allSavedSearches.items", [])}
        getSavedSearchList={props.getSavedSearchList}
        onSelectedSavedSearch={props.onSelectedSavedSearch}
        isShowGuide={isShowGuide}
        isShowSearch={!isShowGuide}
      />
      <div className={bem.e("process-list-container")}>
        <ReportsTable
          items={items}
          bem={bem}
          intl={props.intl}
          sort={first(sorted)}
          actionSort={onSort}
          onView={onView}
          hasMoreItems={pages > page && !isLoading}
          handleLoadMore={() => getMoreWinners(page)}
          isLoading={isLoading}
          selectedItems={props.selectedItems}
          setSelectedItems={props.setSelectedItems}
          selectedItem={props.selectedItem}
          setSelectedItem={props.setSelectedItem}
          isShowGuide={isShowGuide}
          selectedYear={get(filteredYear, "value", "")}
          info={info}
        />
      </div>
      <AdvancedSearchModal
        isOpen={props.isShowAdvancedSearchModal}
        onToggle={() =>
          props.setIsShowAdvancedSearchModal(!props.isShowAdvancedSearchModal)
        }
        onSubmit={onAdvancedSearch}
        initialValues={advancedSearch}
      />
      <ExportModal
        isOpen={props.isShowExportModal}
        onToggle={() => props.setIsShowExportModal(!props.isShowExportModal)}
        onSubmit={props.onExportList}
        isDefaultSelectedAll
        type="promotion_reports"
        initialValues={{
          type: "summary",
        }}
        isShowTypeSwitch={true}
      />
      <SaveSearchModal
        isOpen={props.isShowSaveSearchModal}
        onToggle={() =>
          props.setIsShowSaveSearchModal(!props.isShowSaveSearchModal)
        }
        is1099Report
        onSubmit={props.onSaveSearch}
        savedSearches={get(props, "savedSearches.items", [])}
        hasMoreItems={hasMoreItemsSavedSearch}
        onDelete={props.onDeleteSavedSearch}
        handleLoadMore={() => {
          props.getMoreSavedSearchList(get(props, "savedSearches.page", 1));
        }}
        isSelectableItem
        onSelectItem={(item) => {
          props.onSelectedSavedSearch(item);
          props.setIsShowSaveSearchModal(false);
        }}
      />
      <AudiencePickerModal
        isOpen={props.isAudiencePickerModalOpen}
        onToggle={() =>
          props.setIsAudiencePickerModalOpen(!props.isAudiencePickerModalOpen)
        }
        template={{
          key: "promotion",
        }}
        isFilterByServerConfig={true}
        isForceUpdateSelect={true}
        onClose={onSelectedAudience}
        selectedGroupId={null}
        selectedAudience={selectedStations
          .filter((item) => item)
          .map((item) => item.value)}
      />
      <ConfirmationModal
        isOpen={props.isShowExportSuccessModal}
        onToggle={() => props.setIsShowExportSuccessModal(false)}
        onCancel={() => props.setIsShowExportSuccessModal(false)}
        state="success"
        title={intl.formatMessage({ id: "winners > list exported" })}
      />
      <Spinner isLoading={isLoading} />
    </div>
  );
}
