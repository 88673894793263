import { connect } from "react-redux";
import { compose } from "recompose";
import { get, find, forOwn } from "lodash";
import WinnersPage from "./view";
const Enhanced = compose(
  connect((state, props) => {
    let stations = [];
    const entities = get(
      state.servers,
      `currentServer.data.entities.promotion`,
      {}
    );
    forOwn(entities, (entityStations) => {
      stations = [...stations, ...entityStations];
    });
    const filteredStations = get(state, "auth.info.stations", []).filter(
      (station) => {
        return (
          station.station_key &&
          find(stations, (i) => i.key === station.station_key)
        );
      }
    );
    return {
      stations: filteredStations,
    };
  }, {})
)(WinnersPage);
export default Enhanced;
