import React, { useMemo } from "react";
import bn from "utils/bemnames";
import { Row, Col, Label, Button } from "reactstrap";
import TextInput from "components/TextInput";
import { FormattedMessage } from "react-intl";
import UploadFile from "components/UploadFile";
import classnames from "classnames";
import { Asterisk } from "components/Elements";
import {
  get,
  isEmpty,
  difference,
  uniqBy,
  map,
  includes,
  first,
  find,
} from "lodash";
import { RecurranceSmallIcon, EditIcon } from "components/CustomIcons";
import { spotTypes } from "utils/constants";
import Dropdown from "components/Dropdown";
import AsyncDropdown from "components/AsyncDropdown";
import CustomMultiSelectDropdown from "components/CustomMultiSelectDropdown";
import Badge from "components/Badge";
import { fetchWideOrbitMaterialGroups } from "store/actions/process";
import { productionTypeOptions } from "utils/config";
import moment from "moment";

const bem = bn.create("production-continuity-traffic-details");

function SpotTrafficProductionComplete(props) {
  const {
    spotIndex,
    spot,
    onValueChangedSpot,
    isView,
    process,
    validationState,
    onRemoveSpot,
    user,
    template,
    isShowDelete,
    valueChanged,
    isCartNumberEditable,
    setIsCartNumberEditable,
    step,
    getRoleCandidates,
  } = props;
  const recording = spot.recording_path;
  const recordingB = spot.recording_path_b;
  const cartNumberEdited = includes(isCartNumberEditable, spotIndex);
  const contractStatusField = step.fields.find(
    (f) => f.field === "contract_status"
  );
  const orderTitleField = step.fields.find((f) => f.field === "order_title");
  const selectDubberField = step.fields.find((f) => f.field === "role_dubber");

  const isWoEnabled = get(user, "enable_wo", false);
  const isWOTrafficEnabled = get(user, "enable_wo_traffic", false);
  const isWODubbingEnabled = get(user, "enable_wo_dubbing", false);
  const showWOCartPrefix = !isWOTrafficEnabled && isWODubbingEnabled;
  const showCartNumber =
    !isWoEnabled || !isWOTrafficEnabled || isView || cartNumberEdited;
  const isBookend = get(spot, "spot_type", "Standard") === "Book-ends";
  const availableLineNumbers = get(
    process,
    "data.fields.available_line_numbers.value",
    []
  );
  const contractStatus = get(spot, "contract_status", "");
  const orderLengthCustom = get(spot, "order_length_custom", false);
  const orderLength = get(spot, "order_length", "");

  const showLineNumber = !isEmpty(availableLineNumbers);
  const lineHashes = get(spot, "line_hashes", []);
  const isCOOPOrder = get(process, "data.fields.is_coop_order.value", false);
  const canSyncCartNumber = !isEmpty(get(spot, "material_group_id", ""));
  const rolContinuity = get(
    process,
    "data.fields.role_continuity.value.users",
    []
  );
  let recordingAudit = get(
    process,
    "data.fields.production_asset_audit.value",
    ""
  )
    ? get(process, "data.fields.production_asset_audit.value", "")
    : get(process, "data.fields.provided_audio_audit.value", "");

  let recordingAuditB = get(
    process,
    "data.fields.production_asset_b_audit.value",
    ""
  )
    ? get(process, "data.fields.production_asset_b_audit.value", "")
    : get(process, "data.fields.provided_audio_b_audit.value", "");
  const rootTemplateKey = get(process, "data.fields.template_key.value", "");
  const spotInfo = get(process, "data.fields.spot_info.value", []);

  // only update root data for first provided spot
  const spotIndexForProductionProvided = get(
    process,
    "data.fields.spot_info.value",
    []
  ).findIndex(
    (item) =>
      item.template_key === "production_provided" &&
      get(process, "data.fields.key.value") === spot.key
  );

  const isUpdateTitleProcessData =
    (!get(process, "data.fields.order_title.value") && spotIndex === 0) ||
    spotIndexForProductionProvided === spotIndex;
  const isUpdateDubberOrProducerProcessData =
    rootTemplateKey === "production_provided";

  const orderLengthDisplay = () => {
    if (orderLengthCustom && orderLength) {
      const formatted = moment
        .utc(parseInt(orderLength) * 1000)
        .format("mm:ss");
      return formatted;
    }
    if (orderLength) {
      return `${orderLength} ${props.intl.formatMessage({
        id: "process > title seconds",
      })}`;
    }
    return props.intl.formatMessage({ id: "process > none" });
  };

  const fetchMaterialGroups = async (q) => {
    let materialGroups = await fetchWideOrbitMaterialGroups({
      q: q,
      template_key: template.key,
      stations: get(process, "data.fields.order_stations.value"),
    });

    let materialGroupOptions = materialGroups.map((group) => {
      return {
        label: `${group.material_group_name} (${group.prefix})`,
        value: group.id,
        stations: group.stations,
      };
    });

    return materialGroupOptions;
  };

  let materialGroup = !isEmpty(get(spot, "material_group_id", ""))
    ? {
        label: get(spot, "material_group_name", ""),
        value: get(spot, "material_group_id", ""),
      }
    : null;

  const fetchAudioSources = async (q) => {
    let materialGroups = await fetchWideOrbitMaterialGroups({
      q: q,
      template_key: template.key,
      stations: [],
    });

    let audioSourceOptions = materialGroups.map((group) => {
      return {
        label: group.prefix,
        value: group.prefix,
        stations: group.stations,
      };
    });

    return audioSourceOptions;
  };

  let audoSourcePrefix = !isEmpty(get(spot, "cart_prefix", ""))
    ? {
        label: get(spot, "cart_prefix", ""),
        value: get(spot, "cart_prefix", ""),
      }
    : null;

  const renderSelectedLineHashes = (lineHashes, props) => {
    const { isView, onValueChangedSpot, spotIndex } = props;

    return lineHashes.map((item) => {
      return (
        <Badge
          key={item.value}
          text={item.label}
          onClick={() => {
            onValueChangedSpot(spotIndex, {
              field: "line_hashes",
              value: difference(lineHashes, [item]),
            });
          }}
          readOnly={isView}
        />
      );
    });
  };
  const onChangeDubbersOrderData = (dubbers) => {
    let assignedDubbersOtherSpots = [];
    spotInfo.forEach((spot, index) => {
      if (
        index !== spotIndex &&
        get(spot, "role_dubber.users.length", 0) > 0 &&
        spot.template_key === "production_provided"
      ) {
        assignedDubbersOtherSpots = [
          ...assignedDubbersOtherSpots,
          ...spot.role_dubber.users,
        ];
      }
    });
    assignedDubbersOtherSpots = assignedDubbersOtherSpots.map((user) => ({
      ...user,
      id: Number(user.id),
    }));
    valueChanged(selectDubberField, process, template, user, {
      value: {
        users: dubbers
          ? uniqBy([...assignedDubbersOtherSpots, ...dubbers], "id")
          : uniqBy(assignedDubbersOtherSpots, "id"),
      },
    });
  };

  const {
    selectProducerValue,
    selectProducerOptions,
    selectDubberValue,
    selectDubberOptions,
  } = useMemo(() => {
    let selectDubberOptions = getRoleCandidates(
      selectDubberField,
      process,
      template,
      user
    );
    let selectDubberValue = map(get(spot, `role_dubber.users`, []), (item) => ({
      label: item.name,
      value: item.id,
      role: item.role,
    }));
    // only option
    const dubberOption = first(selectDubberOptions);
    const isSelectedDefaultDubberOption =
      dubberOption &&
      find(
        selectDubberValue,
        (item) => Number(item.value) === Number(dubberOption.value)
      );
    if (
      selectDubberField.default &&
      selectDubberOptions.length === 1 &&
      dubberOption &&
      !isSelectedDefaultDubberOption &&
      !isView
    ) {
      selectDubberValue = [
        {
          id: dubberOption.value,
          name: dubberOption.label,
          role: dubberOption.role,
        },
      ];
      if (isUpdateDubberOrProducerProcessData) {
        onChangeDubbersOrderData(selectDubberValue);
      }
      onValueChangedSpot(spotIndex, {
        field: "role_dubber",
        value: {
          users: selectDubberValue,
        },
        isSelectedDefault: true,
      });
    }
    return {
      selectProducerValue,
      selectProducerOptions,
      selectDubberValue,
      selectDubberOptions,
    };
  }, [process]);

  return (
    <div
      className={classnames(
        {
          [bem.e("spot-production-complete-view")]: isView,
          [bem.e("spot-production-complete-edit")]: !isView,
          [bem.e("spot-row-view")]: isView,
        },
        bem.e("spot-section")
      )}
    >
      <Row>
        <Col xs={12} className={bem.e("col-title")}>
          <h2 className={bem.e("spot-title")}>
            <FormattedMessage id="process > title spot" />
            {` `}
            {spotIndex + 1}
            {isShowDelete && (
              <span
                onClick={() => onRemoveSpot(spotIndex)}
                className={bem.e("close-button")}
                // onMouseOver={() => setIsRollover(true)}
                // onMouseOut={() => setIsRollover(false)}
              >
                <FormattedMessage id="process > button delete" />
              </span>
            )}
          </h2>
        </Col>
      </Row>
      <Row>
        <Col xs={6} className={bem.e("col-field")}>
          {isView ? (
            <React.Fragment>
              <Label className={bem.e("title-label")}>
                <FormattedMessage id="process > field title" />
              </Label>
              <div className={bem.e("field-value")}>
                {get(spot, "title", "") ? (
                  get(spot, "title", "")
                ) : (
                  <FormattedMessage id="process > none" />
                )}
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <TextInput
                label={
                  <span>
                    <FormattedMessage id="process > field spot title" />
                    <Asterisk>*</Asterisk>
                  </span>
                }
                placeholder={props.intl.formatMessage({
                  id: "process > placeholder enter title",
                })}
                value={get(spot, "title", "")}
                onChange={({ target }) => {
                  onValueChangedSpot(spotIndex, {
                    field: "title",
                    value: target.value,
                  });
                  if (isUpdateTitleProcessData) {
                    valueChanged(orderTitleField, process, template, user, {
                      value: target.value,
                    });
                  }
                }}
                disabled={isView}
              />
              {get(validationState, "title", "") && (
                <div className="alert-danger">
                  {get(validationState, "title", "")}
                </div>
              )}
            </React.Fragment>
          )}
        </Col>
        {isView ? (
          <Col className={bem.e("col-field")}>
            <Label className={bem.e("title-label")}>
              <FormattedMessage id="process > field spot type" />
            </Label>
            <div className={bem.e("field-value")}>
              {get(spot, "spot_type", "") ? (
                get(spot, "spot_type", "")
              ) : (
                <FormattedMessage id="process > none" />
              )}
            </div>
          </Col>
        ) : (
          <Col className={bem.e("col-field")}>
            <Dropdown
              name="spot_type"
              label={
                <span>
                  <FormattedMessage id="process > field spot type" />
                  <Asterisk>*</Asterisk>
                </span>
              }
              placeholder={props.intl.formatMessage({
                id: "process > placeholder select spot type",
              })}
              value={{
                label: get(spot, "spot_type", ""),
                value: get(spot, "spot_type", ""),
              }}
              onChange={(event) => {
                onValueChangedSpot(spotIndex, {
                  field: "spot_type",
                  value: get(event, "value", ""),
                });
              }}
              options={spotTypes}
            />
            <div className="alert-danger">
              {get(validationState, "spot_type", "")}
            </div>
          </Col>
        )}
      </Row>
      {!isView && isWoEnabled && showLineNumber && (
        <Row>
          <Col xs={6} className={bem.e("col-field")}>
            <Label>
              <FormattedMessage id="process > field line #" />
            </Label>
            <CustomMultiSelectDropdown
              name="line_hashes"
              placeholder={props.intl.formatMessage({
                id: "process > placeholder select line #",
              })}
              value={lineHashes}
              onChange={(selectedValues) => {
                onValueChangedSpot(spotIndex, {
                  field: "line_hashes",
                  value: selectedValues,
                });
              }}
              isMulti={true}
              disabled={isView}
              options={availableLineNumbers.map((lineHash) => {
                return {
                  label: lineHash,
                  value: lineHash,
                };
              })}
            />
            <div className="alert-danger">
              {get(validationState, "line_hashes", "")}
            </div>
          </Col>
        </Row>
      )}
      {isWoEnabled && showLineNumber && !isEmpty(lineHashes) && (
        <Row>
          <Col className="selected-line-hashes">
            <Label className="label">
              <FormattedMessage id="process > field selected line #" />
            </Label>
            <div className="line-hashes-badges-container">
              {renderSelectedLineHashes(lineHashes, props)}
            </div>
          </Col>
        </Row>
      )}
      {isWOTrafficEnabled && (
        <React.Fragment>
          <Row>
            {isView ? (
              <Col
                xs={12}
                className={classnames(
                  bem.e("col-field"),
                  "selected-line-numbers"
                )}
              >
                <Label className={bem.e("title-label")}>
                  <FormattedMessage id="process > field material group name" />
                </Label>
                <div className={bem.e("field-value")}>
                  {get(spot, "material_group_name", "") ? (
                    get(spot, "material_group_name", "")
                  ) : (
                    <FormattedMessage id="process > none" />
                  )}
                </div>
              </Col>
            ) : (
              <Col xs={6}>
                <span>
                  <div>
                    <AsyncDropdown
                      name="material_group_name"
                      loadOptions={fetchMaterialGroups}
                      label={
                        <span>
                          <FormattedMessage id="process > field material group name" />
                          <Asterisk>*</Asterisk>
                        </span>
                      }
                      placeholder={props.intl.formatMessage({
                        id: "process > field select placeholder",
                      })}
                      value={materialGroup}
                      onChange={(selectedOption) => {
                        onValueChangedSpot(spotIndex, {
                          field: "material_group_name",
                          value: get(selectedOption, "label", ""),
                        });
                        onValueChangedSpot(spotIndex, {
                          field: "material_group_id",
                          value: get(selectedOption, "value", ""),
                        });
                      }}
                      defaultOptions={true}
                      error={get(validationState, "material_group_name", "")}
                    />
                  </div>
                </span>
              </Col>
            )}
          </Row>
        </React.Fragment>
      )}
      <Row>
        <Col xs={6} className={bem.e("col-field")}>
          {isView ? (
            <React.Fragment>
              <Label className={bem.e("title-label")}>
                <FormattedMessage id="process > field production type" />
              </Label>
              <div className={bem.e("field-value")}>
                {get(spot, "contract_status", "") ? (
                  get(spot, "contract_status", "")
                ) : (
                  <FormattedMessage id="process > none" />
                )}
              </div>
            </React.Fragment>
          ) : (
            <Dropdown
              name="contract_status"
              options={productionTypeOptions}
              label={
                <span>
                  {contractStatusField.title}
                  {contractStatusField.mandatory && <Asterisk>*</Asterisk>}
                </span>
              }
              placeholder={props.intl.formatMessage({
                id: "process > placeholder select",
              })}
              value={
                contractStatus
                  ? {
                      label: contractStatus,
                      value: contractStatus,
                    }
                  : ""
              }
              onChange={(event) => {
                if (rootTemplateKey === "production_provided") {
                  // any spot change to need tags. Update root level data to show producer tab
                  if (
                    get(event, "value", "") ===
                    "Production Provided - Needs tags"
                  ) {
                    valueChanged(contractStatusField, process, template, user, {
                      value: get(event, "value", ""),
                    });
                  }
                }
                onValueChangedSpot(spotIndex, {
                  field: "contract_status",
                  value: get(event, "value", ""),
                });
              }}
              error={get(validationState, "contract_status", "")}
            />
          )}
        </Col>
        <Col className={bem.e("col-field")}>
          <Label className={bem.e("title-label")}>
            <FormattedMessage id="process > field length" />
          </Label>
          <div className={bem.e("field-value")}>{orderLengthDisplay()}</div>
        </Col>
      </Row>
      {/* dubber */}
      <Row>
        <Col xs={6} className={bem.e("col-field")}>
          {isView ? (
            <React.Fragment>
              <Label className={bem.e("title-label")}>
                <FormattedMessage id="process > field dubber" />
              </Label>
              <div className={bem.e("field-value")}>
                {selectDubberValue && selectDubberValue.length > 0
                  ? map(selectDubberValue, (item) => item.label).join(",")
                  : props.intl.formatMessage({ id: "process > none" })}
              </div>
            </React.Fragment>
          ) : (
            <Dropdown
              isClearable={true}
              name="select_dubber"
              {...get(selectDubberField, "props", {})}
              placeholder={props.intl.formatMessage({
                id: "process > placeholder select",
              })}
              isMulti
              options={selectDubberOptions}
              label={
                <span>
                  {get(selectDubberField, "title")}
                  <Asterisk>*</Asterisk>
                </span>
              }
              value={selectDubberValue ? selectDubberValue : ""}
              onChange={(selectedOption) => {
                let selectValue = "";
                let newDubbers = "";
                if (selectedOption) {
                  newDubbers = map(selectedOption, (option) => ({
                    id: option.value,
                    name: option.label,
                    role: option.role,
                  }));
                  selectValue = {
                    users: newDubbers,
                  };
                }
                onValueChangedSpot(spotIndex, {
                  field: "role_dubber",
                  value: selectValue,
                });
                // update role_dubber to root data if current order is production provided. Because the hybrid order allow production complete and production needed tags on an order data
                if (isUpdateDubberOrProducerProcessData) {
                  onChangeDubbersOrderData(newDubbers);
                }
              }}
            />
          )}
          {get(validationState, "role_dubber", "") && (
            <div className="alert-danger">
              {get(validationState, "role_dubber", "")}
            </div>
          )}
        </Col>
        <Col xs={6} className={bem.e("col-field")}>
          <Label className={bem.e("title-label")}>
            <FormattedMessage id="process > order continuity" />
          </Label>
          <div className={bem.e("field-value")}>
            {rolContinuity && rolContinuity.length > 0
              ? map(rolContinuity, (item) => item.name).join(",")
              : props.intl.formatMessage({ id: "process > none" })}
          </div>
        </Col>
      </Row>
      <Row>
        <Col
          className={bem.e("col-field")}
          xs={showCartNumber && showWOCartPrefix ? 4 : 6}
        >
          <Label className={bem.e("title-label")}>
            <FormattedMessage id="process > field isci / ad id" />
            {!isView && isWOTrafficEnabled && <Asterisk>*</Asterisk>}
          </Label>
          {isView ? (
            <span className={bem.e("table-value")}>
              {get(spot, "isci", "") ? (
                get(spot, "isci", "")
              ) : (
                <FormattedMessage id="process > none" />
              )}
            </span>
          ) : (
            <React.Fragment>
              <TextInput
                placeholder={props.intl.formatMessage({
                  id: "process > placeholder enter number",
                })}
                value={get(spot, "isci", "")}
                error={
                  get(validationState, "isci", "") &&
                  get(validationState, "isci", "")
                }
                disabled={isView}
                onChange={({ target }) => {
                  onValueChangedSpot(spotIndex, {
                    field: "isci",
                    value: target.value,
                  });
                }}
              />
            </React.Fragment>
          )}
        </Col>
        {showWOCartPrefix && (
          <Col className={bem.e("col-field")} xs={showCartNumber ? 4 : 6}>
            <Label className={bem.e("title-label")}>
              <FormattedMessage id="process > field cart prefix" />
              {!isView && <Asterisk>*</Asterisk>}
            </Label>
            {isView ? (
              <span className={bem.e("table-value")}>
                {get(spot, "cart_prefix", "") ? (
                  get(spot, "cart_prefix", "")
                ) : (
                  <FormattedMessage id="process > none" />
                )}
              </span>
            ) : (
              <React.Fragment>
                <AsyncDropdown
                  name="cart_prefix"
                  loadOptions={fetchAudioSources}
                  placeholder={props.intl.formatMessage({
                    id: "process > field select placeholder",
                  })}
                  value={audoSourcePrefix}
                  onChange={(selectedOption) => {
                    onValueChangedSpot(spotIndex, {
                      field: "cart_prefix",
                      value: get(selectedOption, "label", ""),
                    });
                    onValueChangedSpot(spotIndex, {
                      field: "cart_prefix",
                      value: get(selectedOption, "value", ""),
                    });
                  }}
                  defaultOptions={true}
                  error={get(validationState, "cart_prefix", "")}
                />
              </React.Fragment>
            )}
          </Col>
        )}

        <Col className={bem.e("col-field")} xs={showWOCartPrefix ? 4 : 6}>
          <React.Fragment>
            <Label className={bem.e("table-label")}>
              <FormattedMessage id="process > field cart number" />
              {!isView && showCartNumber && <Asterisk>*</Asterisk>}
            </Label>
            {isView || !showCartNumber ? (
              get(spot, "cart_number", "") ? (
                <React.Fragment>
                  <span
                    className={classnames(bem.e("spot-value"), "cart-number")}
                  >
                    {get(spot, "cart_number", "")}
                  </span>
                  {!isView && !cartNumberEdited && (
                    <span
                      className={bem.e("cart-edit-pencil")}
                      onClick={() => {
                        isCartNumberEditable.push(spotIndex);
                        setIsCartNumberEditable(isCartNumberEditable);
                      }}
                    >
                      {!cartNumberEdited && (
                        <React.Fragment>
                          <EditIcon width="16" height="15" />
                          <span className={bem.e("cart-edit-text")}>
                            <FormattedMessage id="process > button edit" />
                          </span>
                        </React.Fragment>
                      )}
                    </span>
                  )}
                </React.Fragment>
              ) : isWOTrafficEnabled ? (
                <React.Fragment>
                  <span
                    className={classnames(bem.e("spot-pending"), {
                      "sync-cart": canSyncCartNumber,
                    })}
                    onClick={() => {
                      if (!canSyncCartNumber) {
                        return false;
                      }
                      props.syncWOCartNumbers({
                        cb: () => {
                          props.setIsSyncCartNumberProgressModalOpen(true);
                        },
                      });
                    }}
                  >
                    <RecurranceSmallIcon />
                    <FormattedMessage id="process > title pending" />
                  </span>
                  {!isView && !cartNumberEdited && (
                    <span
                      className={bem.e("cart-edit-pencil")}
                      onClick={() => {
                        isCartNumberEditable.push(spotIndex);
                        setIsCartNumberEditable(isCartNumberEditable);
                      }}
                    >
                      {!cartNumberEdited && (
                        <React.Fragment>
                          <EditIcon width="16" height="15" />
                          <span className={bem.e("cart-edit-text")}>
                            <FormattedMessage id="process > button edit" />
                          </span>
                        </React.Fragment>
                      )}
                    </span>
                  )}
                </React.Fragment>
              ) : (
                <FormattedMessage id="process > none" />
              )
            ) : (
              <TextInput
                placeholder={props.intl.formatMessage({
                  id: "process > placeholder enter number",
                })}
                value={get(spot, "cart_number", "")}
                disabled={isView}
                onChange={({ target }) => {
                  onValueChangedSpot(spotIndex, {
                    field: "cart_number",
                    value: target.value,
                  });
                }}
                error={
                  get(validationState, "cart_number", "") &&
                  get(validationState, "cart_number", "")
                }
              />
            )}
          </React.Fragment>
        </Col>
      </Row>
      <Row>
        <Col className={classnames(bem.e("recording-info"))}>
          <div className={bem.e("spot-right")}>
            {!isBookend && (
              <Label className={bem.e("label")}>
                <FormattedMessage id="process > field produced spot" />
                {!isView && <Asterisk>*</Asterisk>}
              </Label>
            )}
            <div className={bem.e("produced-spot")}>
              <UploadFile
                mode={isView ? "view" : "edit"}
                process={process}
                onUploaded={(response) => {
                  onValueChangedSpot(spotIndex, {
                    field: "recording_path",
                    value: get(response, "path"),
                  });
                }}
                extensions={[
                  "mp3",
                  "wav",
                  "aiff",
                  "flac",
                  "aac",
                  "alac",
                  "m4a",
                  "mp4",
                ]}
                isCheckMimesByExt
                disabled={isView}
                isDisabledDelete={isView}
                onDeleted={() => {
                  onValueChangedSpot(spotIndex, {
                    field: "recording_path",
                    value: "",
                  });
                }}
                url={recording}
                actions={["download"]}
                positionActions="bottom"
                attachText={props.intl.formatMessage({
                  id: "process > field upload audio",
                })}
                auditInfo={recordingAudit}
                isShowFileNameInEditMode={true}
                isBookend={isBookend}
                path={isBookend ? "a" : ""}
                hideSpotLength={
                  !get(process, "data.fields.imported_from_wo.value", false)
                }
                spot={spot}
              >
                <FormattedMessage id="process > button upload" />
              </UploadFile>
              {isCOOPOrder && (
                <Button
                  onClick={() =>
                    props.setShowFinalScriptModal({
                      title: spot.title,
                      uuid: spot.uuid,
                      spotIndex,
                      script: spot.provided_coop_script,
                      orderLength: isBookend
                        ? spot.length1
                        : get(process, "data.fields.order_length.value", ""),
                    })
                  }
                  className={classnames(
                    "btn btn-radius",
                    bem.e("btn-coop-script")
                  )}
                  color="blue"
                >
                  <FormattedMessage id="process > co-op script" />
                </Button>
              )}
            </div>
            {get(validationState, "recording_path", "") && (
              <div className="alert-danger">
                {get(validationState, "recording_path", "")}
              </div>
            )}
          </div>
        </Col>
      </Row>
      {isBookend && (
        <React.Fragment>
          <Row>
            <Col className={classnames(bem.e("recording-info"))}>
              <div className={bem.e("spot-right")}>
                <div className={bem.e("produced-spot")}>
                  <UploadFile
                    mode={isView ? "view" : "edit"}
                    process={process}
                    onUploaded={(response) => {
                      onValueChangedSpot(spotIndex, {
                        field: "recording_path_b",
                        value: get(response, "path"),
                      });
                    }}
                    extensions={[
                      "mp3",
                      "wav",
                      "aiff",
                      "flac",
                      "aac",
                      "alac",
                      "m4a",
                      "mp4",
                    ]}
                    isCheckMimesByExt
                    disabled={isView}
                    isDisabledDelete={isView}
                    onDeleted={() => {
                      onValueChangedSpot(spotIndex, {
                        field: "recording_path_b",
                        value: "",
                      });
                    }}
                    url={recordingB}
                    actions={["download"]}
                    positionActions="bottom"
                    attachText={props.intl.formatMessage({
                      id: "process > field upload audio",
                    })}
                    auditInfo={recordingAuditB}
                    isShowFileNameInEditMode={true}
                    isBookend={isBookend}
                    path={isBookend ? "b" : ""}
                    hideSpotLength={
                      !get(process, "data.fields.imported_from_wo.value", false)
                    }
                    spot={spot}
                  >
                    <FormattedMessage id="process > button upload" />
                  </UploadFile>
                  {isCOOPOrder && (
                    <Button
                      onClick={() =>
                        props.setShowFinalScriptModal({
                          title: spot.title,
                          uuid: spot.uuid,
                          spotIndex,
                          script: spot.provided_coop_script,
                          orderLength: isBookend
                            ? spot.length2
                            : get(
                                process,
                                "data.fields.order_length.value",
                                ""
                              ),
                        })
                      }
                      className={classnames(
                        "btn btn-radius",
                        bem.e("btn-coop-script")
                      )}
                      color="blue"
                    >
                      <FormattedMessage id="process > co-op script" />
                    </Button>
                  )}
                </div>
                {get(validationState, "recording_path_b", "") && (
                  <div className="alert-danger">
                    {get(validationState, "recording_path_b", "")}
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </React.Fragment>
      )}
    </div>
  );
}
export default SpotTrafficProductionComplete;
