import React, { useState } from "react";
import { Row, Col, Label, FormGroup } from "reactstrap";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { get, find, map } from "lodash";
import moment from "moment";
import {
  ShadowBox,
  SectionTitle,
  SectionFields,
  Asterisk,
} from "components/Elements";
import { DownIcon, UpIcon } from "components/CustomIcons";
import Dropdown from "components/Dropdown";
import InputTime from "components/InputTime";
import TextInput from "components/TextInput";
import { setScriptField } from "utils/helpers";
import TextareaEvaluator from "components/TextareaEvaluator";
import { grandScriptTags, grandScriptAfterTags } from "utils/config";
import GroupSectionDropdown from "components/GroupSectionDropdown";
const dateTimeFormat = "YYYY-MM-DD HH:mm:ss";

function getShowDaypartOptions({ clocksData, intl, value }) {
  if (!clocksData)
    return {
      options: [],
      value: "",
    };
  const { dayparts, shows } = clocksData;
  let selected = null;
  const getOptions = (options) => {
    return map(options, (option) => {
      const optionFormatted = {
        label: option.name,
        value: option.id,
        data: option,
      };
      if (Number(option.id) === Number(value)) {
        selected = optionFormatted;
      }
      return optionFormatted;
    });
  };
  const options = [
    {
      label: intl.formatMessage({
        id: "process > promotion > contest > select by daypart",
      }),
      options: getOptions(dayparts),
      value: "daypart",
    },
    {
      label: intl.formatMessage({
        id: "process > promotion > contest > select by show",
      }),
      options: getOptions(shows),
      value: "show",
    },
  ];
  return { options, value: selected };
}
const GrandPrizeGiveaway = (props) => {
  const {
    validationState,
    process,
    bem,
    step,
    onValueChanged,
    intl,
    isView,
    setOpenDetails,
    openDetails,
    clocksData,
    dateFormatByServer
  } = props;
  const [fieldRefs, setFieldRefs] = useState(null);
  const getFieldRefs = () => {
    if (fieldRefs) return fieldRefs;
    const qualifying_prize = step.fields.find(
      (f) => f.field === "qualifying_prize"
    );
    const prize_execution = step.fields.find(
      (f) => f.field === "prize_execution"
    );
    const grand_prize_execution_date = step.fields.find(
      (f) => f.field === "grand_prize_execution_date"
    );
    const show_daypart = step.fields.find((f) => f.field === "show_daypart");
    const grand_prize_script_before = step.fields.find(
      (f) => f.field === "grand_prize_script_before"
    );
    const grand_prize_script_after = step.fields.find(
      (f) => f.field === "grand_prize_script_after"
    );
    const grand_prize_jock_instructions = step.fields.find(
      (f) => f.field === "grand_prize_jock_instructions"
    );

    const grand_prize_execution_time = step.fields.find(
      (f) => f.field === "grand_prize_execution_time"
    );
    const payload = {
      qualifying_prize,
      prize_execution,
      grand_prize_execution_date,
      grand_prize_script_before,
      grand_prize_script_after,
      show_daypart,
      grand_prize_jock_instructions,
      grand_prize_execution_time,
    };

    setFieldRefs(payload);

    return payload;
  };

  const {
    prize_execution,
    grand_prize_execution_date,
    grand_prize_script_before,
    grand_prize_script_after,
    show_daypart,
    grand_prize_jock_instructions,
    grand_prize_execution_time,
  } = getFieldRefs();
  const selectedPrizeExecution =
    find(
      prize_execution.props.options,
      (item) =>
        item.value ===
        get(process, `data.fields.${prize_execution.field}.value`, "")
    ) || "";
  const isOffAir = get(selectedPrizeExecution, "value") === "off_air";
  const resetGrandPrizeFields = () => {
    onValueChanged(show_daypart, "");
    onValueChanged(grand_prize_execution_time, "");
    onValueChanged(grand_prize_script_before, "");
    onValueChanged(grand_prize_script_after, "");
    onValueChanged(grand_prize_jock_instructions, "");
  };
  const executionTime = get(
    process,
    `data.fields.${grand_prize_execution_time.field}.value`,
    ""
  );

  const formattedDateTime = executionTime
    ? moment.utc(executionTime * 60 * 1000).format(dateTimeFormat)
    : "";
  const showDaypartValue = get(
    process,
    `data.fields.${show_daypart.field}.value`,
    ""
  );
  const showDaypartData = getShowDaypartOptions({
    clocksData,
    intl,
    value: showDaypartValue,
  });
  const isOpen = get(openDetails, "grand_prize", false);
  return (
    <ShadowBox
      className={bem.e("box-grand-prize-details")}
      onClick={() =>
        setOpenDetails({
          ...openDetails,
          grand_prize: !isOpen,
        })
      }
    >
      <SectionTitle className={bem.e("contest-details-section-title")}>
        <FormattedMessage id="process > promotion > contest > title grand prize giveaway" />
        <span className={bem.e("box-action")}>
          {isOpen ? <UpIcon /> : <DownIcon />}
        </span>
      </SectionTitle>
      {isOpen && (
        <React.Fragment>
          <SectionFields
            className={classnames(bem.e("section-fields"), {
              [bem.e("contest-details-section-fields-view")]: isView,
              [bem.e("contest-details-section-fields")]: !isView,
            })}
          >
            {/* render view */}
            {isView ? (
              <React.Fragment>
                <Row>
                  <Col xs={6} className={bem.e("reset-col")}>
                    <FormGroup>
                      <Label>{prize_execution.title}</Label>
                      <span className={bem.e("order-view-value")}>
                        {selectedPrizeExecution
                          ? get(selectedPrizeExecution, "label", "")
                          : intl.formatMessage({ id: "process > none" })}
                      </span>
                    </FormGroup>
                  </Col>
                  {isOffAir ? (
                    <Col xs={6} className={bem.e("reset-col")}>
                      <FormGroup>
                        <Label>{grand_prize_execution_date.title}</Label>
                        <span className={bem.e("order-view-value")}>
                          {process.data.fields[grand_prize_execution_date.field]
                            ? moment(
                                process.data.fields[
                                  grand_prize_execution_date.field
                                ].value
                              ).format(dateFormatByServer)
                            : intl.formatMessage({ id: "process > none" })}
                        </span>
                      </FormGroup>
                    </Col>
                  ) : (
                    <Col xs={6} className={bem.e("reset-col")}>
                      <FormGroup>
                        <Label>{show_daypart.title}</Label>
                        <span className={bem.e("order-view-value")}>
                          {showDaypartData.value
                            ? get(showDaypartData, "value.label", "")
                            : intl.formatMessage({ id: "process > none" })}
                        </span>
                      </FormGroup>
                    </Col>
                  )}
                </Row>
                {!isOffAir && (
                  <Row>
                    <Col xs={6} className={bem.e("reset-col")}>
                      <FormGroup>
                        <Label>{grand_prize_execution_date.title}</Label>
                        <span className={bem.e("order-view-value")}>
                          {process.data.fields[grand_prize_execution_date.field]
                            ? moment(
                                process.data.fields[
                                  grand_prize_execution_date.field
                                ].value
                              ).format(dateFormatByServer)
                            : intl.formatMessage({ id: "process > none" })}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col xs={6} className={bem.e("reset-col")}>
                      <FormGroup>
                        <Label>{grand_prize_execution_time.title}</Label>
                        <span className={bem.e("order-view-value")}>
                          {executionTime
                            ? moment
                                .utc(executionTime * 60 * 1000)
                                .format("h:mm A")
                            : intl.formatMessage({ id: "process > none" })}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Row>
                  <Col xs={6} className={bem.e("reset-col")}>
                    <FormGroup className={bem.e("reset-form-group")}>
                      <Dropdown
                        {...prize_execution.props}
                        label={
                          <span>
                            {prize_execution.title}
                            {prize_execution.mandatory && (
                              <Asterisk>*</Asterisk>
                            )}
                          </span>
                        }
                        name="prize_execution"
                        placeholder={intl.formatMessage({
                          id: "process > promotion > liner > select",
                        })}
                        onChange={(selectedOption) => {
                          if (selectedOption.value === "off_air")
                            resetGrandPrizeFields();
                          onValueChanged(prize_execution, selectedOption.value);
                        }}
                        value={selectedPrizeExecution}
                        error={get(
                          validationState,
                          "prize_execution.validation_error"
                        )}
                      />
                    </FormGroup>
                  </Col>
                  {isOffAir ? (
                    <Col xs={6} className={bem.e("reset-col")}>
                      <FormGroup>
                        <TextInput
                          label={
                            <span>{grand_prize_execution_date.title}</span>
                          }
                          type="date"
                          name="grand_prize_execution_date"
                          placeholder={intl.formatMessage({
                            id: "process > promotion > contest > select date",
                          })}
                          min={moment().format("YYYY-MM-DD")}
                          value={
                            process.data.fields[
                              grand_prize_execution_date.field
                            ]
                              ? process.data.fields[
                                  grand_prize_execution_date.field
                                ].value
                              : ""
                          }
                          onChange={(value) => {
                            onValueChanged(grand_prize_execution_date, value);
                          }}
                          error={get(
                            validationState,
                            "grand_prize_execution_date.validation_error"
                          )}
                          allowShowIcon
                        />
                      </FormGroup>
                    </Col>
                  ) : (
                    <Col xs={6} className={bem.e("reset-col")}>
                      <FormGroup>
                        <GroupSectionDropdown
                          label={
                            <span>
                              {show_daypart.title}
                              {show_daypart.mandatory && <Asterisk>*</Asterisk>}
                            </span>
                          }
                          name="show_daypart"
                          placeholder={intl.formatMessage({
                            id: "process > promotion > liner > select",
                          })}
                          onChange={(selectedOption) => {
                            onValueChanged(show_daypart, selectedOption.value);
                          }}
                          value={
                            process.data.fields[show_daypart.field]
                              ? process.data.fields[show_daypart.field].value
                              : ""
                          }
                          options={showDaypartData.options}
                          error={get(
                            validationState,
                            "show_daypart.validation_error"
                          )}
                        />
                      </FormGroup>
                    </Col>
                  )}
                </Row>
                {!isOffAir && (
                  <Row>
                    <Col xs={6} className={bem.e("reset-col")}>
                      <FormGroup className={bem.e("reset-form-group")}>
                        <TextInput
                          label={
                            <span>
                              {grand_prize_execution_date.title}
                              {grand_prize_execution_date.mandatory && (
                                <Asterisk>*</Asterisk>
                              )}
                            </span>
                          }
                          type="date"
                          name="grand_prize_execution_date"
                          placeholder={intl.formatMessage({
                            id: "process > promotion > contest > select date",
                          })}
                          min={moment().format("YYYY-MM-DD")}
                          value={
                            process.data.fields[
                              grand_prize_execution_date.field
                            ]
                              ? process.data.fields[
                                  grand_prize_execution_date.field
                                ].value
                              : ""
                          }
                          onChange={(value) => {
                            onValueChanged(grand_prize_execution_date, value);
                          }}
                          error={get(
                            validationState,
                            "grand_prize_execution_date.validation_error"
                          )}
                          allowShowIcon
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={6} className={bem.e("reset-col")}>
                      <FormGroup className={bem.e("reset-form-group")}>
                        <div className={bem.e("time-col")}>
                          <Label>
                            {grand_prize_execution_time.title}
                            {grand_prize_execution_time.mandatory && (
                              <Asterisk>*</Asterisk>
                            )}
                          </Label>
                          <InputTime
                            time={formattedDateTime}
                            onChange={(startDateTime) => {
                              const minutes =
                                parseInt(startDateTime.get("hours")) * 60 +
                                parseInt(startDateTime.get("minutes"));
                              onValueChanged(
                                grand_prize_execution_time,
                                minutes
                              );
                            }}
                            error={get(
                              validationState,
                              "grand_prize_execution_time.validation_error"
                            )}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                )}
              </React.Fragment>
            )}
          </SectionFields>
          {!isOffAir && (
            <React.Fragment>
              <div className={bem.e("line")} />
              <SectionFields
                className={classnames(bem.e("section-fields"), {
                  [bem.e("contest-details-section-fields-view")]: isView,
                  [bem.e("contest-details-section-fields")]: !isView,
                })}
              >
                {/* render view */}
                {isView ? (
                  <React.Fragment>
                    <Row>
                      <Col xs={12} className={bem.e("col-reset")}>
                        <FormGroup>
                          <span className={bem.e("script-subtitle")}>
                            <FormattedMessage id="process > script" />
                          </span>
                          <div
                            className={classnames(
                              bem.e("order-view-value"),
                              bem.e("script-view-value")
                            )}
                          >
                            <TextareaEvaluator
                              readOnly={true}
                              title={
                                <FormattedMessage id="process > promotion > contest > before winner selection" />
                              }
                              text={setScriptField(
                                get(
                                  process,
                                  "data.fields.grand_prize_script_before.value",
                                  ""
                                )
                              )}
                              orderLengthText={
                                <FormattedMessage id="process > promotion > contest > length" />
                              }
                              showScriptLength={true}
                              onEvaluatorCallback={() => {}}
                              hideHelperText={true}
                              showInnerTitle
                              isShowWordCounting
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col xs={12} className={bem.e("col-reset")}>
                        <FormGroup>
                          <span className={bem.e("script-subtitle")}>
                            <FormattedMessage id="process > script" />
                          </span>
                          <div
                            className={classnames(
                              bem.e("order-view-value"),
                              bem.e("script-view-value")
                            )}
                          >
                            <TextareaEvaluator
                              readOnly={true}
                              title={
                                <FormattedMessage id="process > promotion > contest > after winner selection" />
                              }
                              text={setScriptField(
                                get(
                                  process,
                                  "data.fields.grand_prize_script_after.value",
                                  ""
                                )
                              )}
                              orderLengthText={
                                <FormattedMessage id="process > promotion > contest > length" />
                              }
                              showScriptLength={true}
                              onEvaluatorCallback={() => {}}
                              hideHelperText={true}
                              showInnerTitle
                              isShowWordCounting
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col xs={12} className={bem.e("col-reset")}>
                        <FormGroup>
                          <Label>{grand_prize_jock_instructions.title}</Label>
                          <div className={bem.e("order-view-value")}>
                            <TextareaEvaluator
                              readOnly={true}
                              text={setScriptField(
                                get(
                                  process,
                                  "data.fields.grand_prize_jock_instructions.value",
                                  ""
                                )
                              )}
                              placeholder={intl.formatMessage({
                                id: "process > enter here",
                              })}
                              hideOrderLength={true}
                              hideHelperText={true}
                              onEvaluatorCallback={() => {}}
                              showInnerTitle
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Row>
                      <Col xs={12} className={bem.e("reset-col")}>
                        <FormGroup>
                          <span className={bem.e("script-subtitle")}>
                            <FormattedMessage id="process > script" />
                          </span>
                          <TextareaEvaluator
                            text={setScriptField(
                              get(
                                process,
                                "data.fields.grand_prize_script_before.value",
                                ""
                              )
                            )}
                            title={
                              <FormattedMessage id="process > promotion > contest > before winner selection" />
                            }
                            orderLengthText={
                              <FormattedMessage id="process > promotion > contest > length" />
                            }
                            placeholder={intl.formatMessage({
                              id: "process > enter script",
                            })}
                            showScriptLength={true}
                            tags={grandScriptTags}
                            onEvaluatorCallback={(value) => {
                              onValueChanged(grand_prize_script_before, value);
                            }}
                            showInnerTitle
                            hideHelperText={true}
                            error={get(
                              validationState,
                              "grand_prize_script_before.validation_error"
                            )}
                            isShowWordCounting
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} className={bem.e("reset-col")}>
                        <FormGroup>
                          <span className={bem.e("script-subtitle")}>
                            <FormattedMessage id="process > script" />
                          </span>
                          <TextareaEvaluator
                            text={setScriptField(
                              get(
                                process,
                                "data.fields.grand_prize_script_after.value",
                                ""
                              )
                            )}
                            title={
                              <FormattedMessage id="process > promotion > contest > after winner selection" />
                            }
                            orderLengthText={
                              <FormattedMessage id="process > promotion > contest > length" />
                            }
                            showScriptLength={true}
                            placeholder={intl.formatMessage({
                              id: "process > enter script",
                            })}
                            tags={grandScriptAfterTags}
                            onEvaluatorCallback={(value) => {
                              onValueChanged(grand_prize_script_after, value);
                            }}
                            showInnerTitle
                            hideHelperText={true}
                            error={get(
                              validationState,
                              "grand_prize_script_after.validation_error"
                            )}
                            isShowWordCounting
                          />
                        </FormGroup>
                      </Col>

                      <Col xs={12} className={bem.e("reset-col")}>
                        <FormGroup className={bem.e("group-jock-instructions")}>
                          <TextareaEvaluator
                            text={setScriptField(
                              get(
                                process,
                                "data.fields.grand_prize_jock_instructions.value",
                                ""
                              )
                            )}
                            title={grand_prize_jock_instructions.title}
                            placeholder={intl.formatMessage({
                              id: "process > enter here",
                            })}
                            onEvaluatorCallback={(value) => {
                              onValueChanged(
                                grand_prize_jock_instructions,
                                value
                              );
                            }}
                            showScriptLength={false}
                            hideHelperText={true}
                            showInnerTitle
                            error={get(
                              validationState,
                              "grand_prize_jock_instructions.validation_error",
                              ""
                            )}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </SectionFields>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </ShadowBox>
  );
};

export default GrandPrizeGiveaway;
