import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import {
  get,
  find,
  first,
  map,
  sum,
  pick,
  truncate,
  uniqBy,
  forEach,
  filter,
} from "lodash";
import { Label } from "reactstrap";
import Tooltip from "rc-tooltip";
import Dropdown from "components/Dropdown";
import NumberFormat from "react-number-format";
import {
  ShadowBox,
  Asterisk,
  SectionTitle,
  SectionFields,
} from "components/Elements";
import { DownIcon, UpIcon, DeleteIcon } from "components/CustomIcons";
import { fulfillmentList } from "utils/config";
import ToastManager from "components/ToastManager";
import PrizeCloset from "../PrizeCloset";
import PickYourThemePrizePackage from "../PickYourThemePrizePackage";
import Five4321ThemePrizePackage from "../Five4321ThemePrizePackage";

const validKeysPrize = [
  "active",
  "add1",
  "add2",
  "allocation",
  "available",
  "category",
  "city",
  "contact",
  "description",
  "digital_link",
  "expiration_date_ts",
  "expiration_date",
  "fulfillment",
  "id",
  "key",
  "max_per_winner",
  "name",
  "office_days",
  "on_hold_quantity",
  "phone",
  "phone",
  "quantity",
  "state",
  "subtitle",
  "supplier",
  "time_close",
  "time_open",
  "title",
  "total_cash",
  "unit_value",
  "winner_instructions",
  "zip",
  "itemize",
  "allocation",
  "stations_quantity",
];
const SelectedItem = (props) => {
  const {
    bem,
    item,
    onDelete,
    intl,
    playsNumber,
    onUpdate,
    isView,
    validationState,
    isRolloverTheme,
    isCashVariablePrize,
    selectedStation,
  } = props;
  const isShowItemizedPrize =
    get(item, "itemize") &&
    (item.allocation === "assigned" || item.allocation === "shared")
      ? true
      : false;
  const isShowNoneItemized =
    !get(item, "itemize") && item.allocation === "assigned" ? true : false;
  const itemizedPrizeOptions = get(item, "itemized_prices", []).map((i) => ({
    label: i.description,
    value: i.id,
    data: i,
  }));
  let quantityPrize = get(item, "quantity", 0);
  if (isShowItemizedPrize) {
    forEach(item.stations_itemized_quantity, (i) => {
      if (i.station === selectedStation) {
        let allocation = {};
        if (i.allotment) {
          allocation = i.allotment.find(
            (o) => o.item_id === get(item, "selected_itemized_price.0.id", "")
          );
        } else {
          allocation = i.allocation.find(
            (o) => o.item_id === get(item, "selected_itemized_price.0.id", "")
          );
        }
        quantityPrize = get(allocation, "available", 0);
      }
    });
  } else if (isShowNoneItemized) {
    const stationQuantity = find(
      item.stations_quantity,
      (i) => i.station === selectedStation
    );
    quantityPrize = get(stationQuantity, "quantity", 0);
  }
  const maximumNumberOfPer =
    quantityPrize !== null && playsNumber
      ? Math.floor(Number(quantityPrize) / Number(playsNumber))
      : null;
  const perOptions = maximumNumberOfPer
    ? [...Array(maximumNumberOfPer || 1).keys()].map((item) => ({
        value: item + 1,
        label: item + 1,
      }))
    : [
        {
          value: 1,
          label: 1,
        },
      ];
  const fulfillment = fulfillmentList.find((i) => i.value === item.fulfillment);
  const maxDescriptionLength = 120;
  const originalDescription = get(item, "description", "") || "";
  const description = truncate(originalDescription, {
    length: maxDescriptionLength,
  });
  const isTruncated = originalDescription.length > maxDescriptionLength;
  return (
    <div className={bem.e("selected-prize-package")}>
      <div className={bem.e("selected-prize-padding")}>
        {!isView && (
          <span className={bem.e("trash")} onClick={() => onDelete(item)}>
            <DeleteIcon color="#795AFA" />
          </span>
        )}

        <h2 className={bem.e("selected-prize-title")}>{item.title}</h2>
        <span className={bem.e("selected-prize-subtitle")}>
          {item.subtitle}
        </span>
        <div className={bem.e("selected-prize-value")}>
          <FormattedMessage
            id={
              isCashVariablePrize
                ? "process > promotion > contest > max value"
                : "process > promotion > contest > value"
            }
          />
          ${isCashVariablePrize ? item.max_per_winner : item.unit_value}
        </div>
        <div className={bem.e("selected-prize-description")}>
          {isTruncated ? (
            <Tooltip
              placement="top"
              trigger={["click", "hover"]}
              overlayClassName={bem.e("tooltip")}
              overlay={
                <div className={bem.e("tooltip-content")}>
                  {originalDescription}
                </div>
              }
            >
              <div className={bem.e("tooltip-content")}>{description}</div>
            </Tooltip>
          ) : (
            description
          )}
        </div>
        {isShowItemizedPrize ? (
          <div className={bem.e("selected-prize-type")}>
            {isView ? (
              <div className={bem.e("selected-prize-item")}>
                <Label>
                  <FormattedMessage id="process > promotion > contest > per play" />
                </Label>
                <span className={"order-view-value"}>{item.per_play}</span>
              </div>
            ) : (
              <Dropdown
                name="selected_itemized_price"
                label={
                  <FormattedMessage id="process > promotion > contest > itemized prize" />
                }
                className={bem.e("type-dropdown")}
                onChange={(selectedOption) => {
                  onUpdate({
                    ...item,
                    selected_itemized_price: selectedOption
                      ? [selectedOption.data]
                      : [],
                    per_play: 1,
                    total_value: get(selectedOption, "data.value")
                      ? Number(selectedOption.data.value)
                      : 0,
                    unit_value: get(selectedOption, "data.value")
                      ? Number(selectedOption.data.value)
                      : 0,
                  });
                }}
                value={itemizedPrizeOptions.find(
                  (i) =>
                    i.value === get(item, "selected_itemized_price.0.id", "")
                )}
                options={itemizedPrizeOptions}
              />
            )}
          </div>
        ) : null}
        <div className={bem.e("selected-prize-row")}>
          {isView ? (
            <div className={bem.e("selected-prize-item")}>
              <Label>
                <FormattedMessage id="process > promotion > contest > per play" />
              </Label>
              <span className={"order-view-value"}>{item.per_play}</span>
            </div>
          ) : (
            <Dropdown
              name="per_play"
              label={
                <>
                  <FormattedMessage id="process > promotion > contest > per play" />
                  {isRolloverTheme && <Asterisk>*</Asterisk>}
                </>
              }
              className={bem.e("per-play-dropdown")}
              onChange={(selectedOption) => {
                const perPlay = get(selectedOption, "value", 1);
                onUpdate({
                  ...item,
                  per_play: perPlay,
                  total_allotment: perPlay * playsNumber,
                  total_value: perPlay * item.unit_value,
                });
              }}
              value={perOptions.find((i) => i.value === item.per_play)}
              options={perOptions}
            />
          )}
          {isView ? (
            <div className={classnames(bem.e("selected-prize-item"), "px-2")}>
              <Label>
                <FormattedMessage id="process > promotion > contest > fulfillment" />
              </Label>
              <span className={"order-view-value"}>
                {get(fulfillment, "label", "")}
              </span>
            </div>
          ) : (
            <Dropdown
              name="fulfillment"
              label={
                <>
                  <FormattedMessage id="process > promotion > contest > fulfillment" />
                  {isRolloverTheme && <Asterisk>*</Asterisk>}
                </>
              }
              className={bem.e("fulfillment-dropdown")}
              placeholder={intl.formatMessage({
                id: "process > promotion > contest > select",
              })}
              onChange={(selectedOption) => {
                const fulfillment = get(selectedOption, "value", "");
                onUpdate({
                  ...item,
                  fulfillment,
                });
              }}
              value={fulfillment}
              options={fulfillmentList}
            />
          )}

          <div className={bem.e("total-value")}>
            <Label>
              <FormattedMessage
                id={
                  isCashVariablePrize
                    ? "process > promotion > contest > max value"
                    : "process > promotion > contest > total value"
                }
              />
            </Label>
            <strong>
              ${isCashVariablePrize ? item.max_per_winner : item.total_value}
            </strong>
          </div>
        </div>
        {get(validationState, "total_allotment") ? (
          <span className="alert-danger">
            {validationState.total_allotment}
          </span>
        ) : null}
        {get(validationState, "total_value") ? (
          <span className="alert-danger">{validationState.total_value}</span>
        ) : null}
      </div>
      <div className={bem.e("selected-prize-footer")}>
        <div>
          <FormattedMessage id="process > promotion > contest > plays" />:
          <strong>{playsNumber}</strong>
        </div>
        <div>
          <FormattedMessage id="process > promotion > contest > total allotment" />
          :<strong>{item.total_allotment}</strong>
        </div>
      </div>
    </div>
  );
};
const PrizePackage = (props) => {
  const {
    validationState,
    process,
    bem,
    step,
    onValueChanged,
    intl,
    isView,
    isOpenDetails,
    setIsOpenDetails,
    sectionKey,
    template,
    prizesList,
    setPrizes,
  } = props;
  const [fieldRefs, setFieldRefs] = useState(null);
  const getFieldRefs = () => {
    if (fieldRefs) return fieldRefs;

    const prizes = step.fields.find((f) => f.field === "prizes");
    const payload = {
      prizes,
    };

    setFieldRefs(payload);

    return payload;
  };
  const { prizes } = getFieldRefs();
  const numberOfPlaysFor54321Theme = get(
    template,
    "numberOfPlaysFor54321Theme"
  );
  const theme = get(process, "data.fields.theme.value", "");
  const is54321Theme = theme === "5_4_3_2_1";
  const isPickYourPrizeTheme = theme === "pick_your_prize";
  const isRolloverTheme = theme === "rollover";
  const isStandardGiveawayTheme = theme === "standard_giveaway";
  const isQualifyingGrandPrizeTheme = theme === "qualifying_with_grand_prize";
  const selectedPrizes = get(process, "data.fields.prizes.value", "");
  const playsNumber = get(process, "data.fields.plays_number.value")
    ? parseInt(get(process, "data.fields.plays_number.value"))
    : 0;
  const prizeCategory = get(selectedPrizes, "0.category");
  const isCashVariablePrize = prizeCategory === "variable_cash";
  const selectedStation = get(process, "data.fields.order_stations.value.0");
  const onSelectItem = (toReplace) => (item, prize) => {
    if (isView) return;
    if (is54321Theme) {
      if (prize && selectedPrizes.length <= 1) {
        const newSelectedPrizes = [...selectedPrizes];
        if (!newSelectedPrizes.length) {
          newSelectedPrizes.push(item);
        } else if (
          first(selectedPrizes) &&
          first(selectedPrizes).key !== item.key
        ) {
          ToastManager.show({
            title: props.intl.formatMessage({
              id: "toast > message error please select items from same prize",
            }),
            level: "error",
          });
          return;
        }
        let newItemizedPrices = get(
          newSelectedPrizes,
          "0.selected_itemized_prices",
          []
        );
        if (get(newItemizedPrices, "length", 0) >= 5) {
          ToastManager.show({
            title: props.intl.formatMessage(
              {
                id: "toast > message error max items for prize",
              },
              {
                count: numberOfPlaysFor54321Theme,
              }
            ),
            level: "error",
          });
          return;
        }
        newItemizedPrices.push(prize);
        newSelectedPrizes[0].selected_itemized_prices = uniqBy(
          newItemizedPrices,
          "id"
        );
        onValueChanged(prizes, newSelectedPrizes);
      } else {
        ToastManager.show({
          title: props.intl.formatMessage({
            id: "toast > message error please select items from only one prize",
          }),
          level: "error",
        });
        return;
      }
    } else if (!is54321Theme) {
      // const canSelectSinglePrize = isStandardGiveawayTheme;
      const hasNewPrize = find(selectedPrizes, { key: "_new" });
      let newSelectedPrizes = [];
      if (hasNewPrize || toReplace) {
        newSelectedPrizes = map(selectedPrizes, (prize) => {
          if (prize.key === "_new" || prize.key === get(toReplace, "key")) {
            return item;
          }
          return prize;
        });
      } else {
        let quantityPrize = get(item, "quantity", 0);
        let perPlay = 1;
        let totalValue = item.unit_value;
        let unitValue = item.unit_value;
        let itemizedPrizeOptionFirst;
        let stationsItemizedQuantity = [];
        const isShowItemizedPrize =
          get(item, "itemize") &&
          (item.allocation === "assigned" || item.allocation === "shared")
            ? true
            : false;
        const isShowNoneItemized =
          !get(item, "itemize") && item.allocation === "assigned"
            ? true
            : false;
        if (isShowItemizedPrize) {
          itemizedPrizeOptionFirst = first(get(item, "itemized_prices", []));
          stationsItemizedQuantity = get(
            item,
            "stations_itemized_quantity",
            []
          );
          quantityPrize = Number(get(itemizedPrizeOptionFirst, "quantity", 0));
          totalValue = itemizedPrizeOptionFirst.value
            ? Number(itemizedPrizeOptionFirst.value) * perPlay
            : 0;
          unitValue = itemizedPrizeOptionFirst.value
            ? Number(itemizedPrizeOptionFirst.value)
            : 0;
        } else if (isShowNoneItemized) {
          const stationQuantity = find(
            item.stations_quantity,
            (i) => i.station === selectedStation
          );
          quantityPrize = get(stationQuantity, "quantity", 0);
        }
        newSelectedPrizes = uniqBy(
          [
            ...selectedPrizes,
            {
              ...pick(item, validKeysPrize),
              itemized_prices: filter(item.itemized_prices, (i) => i.id),
              per_play: perPlay,
              // if quantity < plays number -> assigned quantityPrize
              total_allotment:
                Number(quantityPrize) >= Number(playsNumber)
                  ? perPlay * playsNumber
                  : quantityPrize,
              total_value: totalValue,
              unit_value: unitValue,
              selected_itemized_price: itemizedPrizeOptionFirst
                ? [itemizedPrizeOptionFirst]
                : [],
              stations_itemized_quantity: stationsItemizedQuantity,
              key: item.id,
            },
          ],
          "key"
        );
      }
      // if (canSelectSinglePrize && get(newSelectedPrizes, "length") > 1) {
      //   ToastManager.show({
      //     title: props.intl.formatMessage(
      //       {
      //         id: "toast > message error max prizes",
      //       },
      //       { count: 1 }
      //     ),
      //     level: "error",
      //   });
      //   return;
      // }
      if (isPickYourPrizeTheme && item.key) {
        setIsOpenDetails(item.key);
      }
      onValueChanged(prizes, newSelectedPrizes);
    }
    // remove selected items from list
    const items = is54321Theme
      ? prizesList
      : prizesList.filter((i) => i._id !== item.id);
    setPrizes({
      filter: "prizes_list",
      data: items,
    });
    ToastManager.show({
      title: props.intl.formatMessage({
        id: "toast > title added",
      }),
      level: "success",
    });
  };

  const getPrizePackageTitle = () => {
    if (is54321Theme) {
      return "process > promotion > contest > title prize package 54321";
    }
    if (isRolloverTheme) {
      return "process > promotion > contest > title prize package rollover";
    }
    if (isQualifyingGrandPrizeTheme) {
      return "process > promotion > contest > title prize package qualifying";
    }
    return "process > promotion > contest > title prize package";
  };

  const renderPrizeCloset = (prize) => {
    if (isView) return null;
    return (
      <PrizeCloset
        process={process}
        isView={isView}
        is54321Theme={is54321Theme}
        isRolloverTheme={isRolloverTheme}
        isPickYourPrizeTheme={isPickYourPrizeTheme}
        onSelectItem={onSelectItem(prize)}
      />
    );
  };

  const renderRegularPrizePackage = () => {
    const showPrizeStats = [
      isRolloverTheme,
      isStandardGiveawayTheme && !isCashVariablePrize,
      isQualifyingGrandPrizeTheme,
    ].filter(Boolean).length;
    const totalValue =
      get(selectedPrizes, "length", 0) > 0
        ? sum(map(selectedPrizes, "total_value"))
        : 0;
    return (
      <>
        <ShadowBox className={bem.e("box-prize-details")}>
          <SectionTitle
            className={bem.e("contest-prize-section-title")}
            onClick={() =>
              setIsOpenDetails(isOpenDetails === sectionKey ? null : sectionKey)
            }
          >
            <div className={bem.e("box-head-title")}>
              <FormattedMessage id={getPrizePackageTitle()} />
            </div>
            <div className={classnames("d-flex", bem.e("right-section"))}>
              {!!showPrizeStats && (
                <>
                  <div className={bem.e("price-total")}>
                    <FormattedMessage id="prizes > total" />
                  </div>
                  <div className={bem.e("price-value")}>
                    <NumberFormat
                      prefix="$"
                      value={totalValue || 0}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </div>
                </>
              )}
              <span className={bem.e("box-action")}>
                {isOpenDetails === sectionKey ? <UpIcon /> : <DownIcon />}
              </span>
            </div>
          </SectionTitle>
          {isOpenDetails === sectionKey && (
            <React.Fragment>
              <SectionFields
                className={classnames(
                  bem.e("section-fields"),
                  bem.e("contest-prize-fields")
                )}
              >
                {get(selectedPrizes, "length", 0) > 0 ? (
                  <div className={bem.e("prize-items")}>
                    {map(selectedPrizes, (item, index) => (
                      <SelectedItem
                        bem={bem}
                        item={item}
                        key={`selected-prize-${index}`}
                        isView={isView}
                        validationState={get(
                          validationState,
                          `prizes.validation_error.${index}`,
                          null
                        )}
                        selectedStation={selectedStation}
                        onUpdate={(item) => {
                          onValueChanged(
                            prizes,
                            selectedPrizes.map((i) => {
                              if (i.key === item.key) {
                                return item;
                              }
                              return i;
                            })
                          );
                        }}
                        onDelete={(item) => {
                          onValueChanged(
                            prizes,
                            selectedPrizes.filter((i) => i.key !== item.key)
                          );
                          props.onRefreshPrizes();
                        }}
                        isCashVariablePrize={isCashVariablePrize}
                        isStandardGiveawayTheme={isStandardGiveawayTheme}
                        is54321Theme={is54321Theme}
                        isPickYourPrizeTheme={isPickYourPrizeTheme}
                        isRolloverTheme={isRolloverTheme}
                        intl={intl}
                        playsNumber={playsNumber}
                      />
                    ))}
                  </div>
                ) : (
                  <div className={bem.e("selected-prize-item")}>
                    <div className={bem.e("prize-empty-text")}>
                      <FormattedMessage
                        id={
                          is54321Theme
                            ? "process > promotion > contest > title select itemized prize item"
                            : "process > promotion > contest > title empty prize item"
                        }
                      />
                    </div>
                  </div>
                )}
                {get(validationState, "prizes.validation_error") &&
                  typeof validationState.prizes.validation_error ===
                    "string" && (
                    <span className="alert-danger">
                      {validationState.prizes.validation_error}
                    </span>
                  )}
              </SectionFields>
              {renderPrizeCloset()}
            </React.Fragment>
          )}
        </ShadowBox>
      </>
    );
  };

  const propsToPass = {
    ...props,
    fieldRefs,
    renderPrizeCloset,
  };

  if (isPickYourPrizeTheme) {
    return <PickYourThemePrizePackage {...propsToPass} />;
  }
  if (is54321Theme) {
    return <Five4321ThemePrizePackage {...propsToPass} />;
  }
  return renderRegularPrizePackage();
};

export default PrizePackage;
