import { connect } from "react-redux";
import {
  compose,
  lifecycle,
  withHandlers,
  withState,
  withPropsOnChange,
} from "recompose";
import { withRouter } from "react-router-dom";
import { get } from "lodash";
import ResourceList, { MODE_IN_TAB, MODE_IN_SIDEBAR } from "./view";
import {
  getResources,
  setActiveTab,
  setActiveEditTab,
  resetTab,
} from "store/actions/resources";
import { countdown } from "utils/helpers";
import { getTemplate } from "store/actions/template";
import { injectIntl } from "react-intl";
export { MODE_IN_TAB, MODE_IN_SIDEBAR };
const Enhanced = compose(
  connect(
    (state, props) => {
      return {
        user: state.auth.user,
        users: get(state, "auth.info.users", []),
        resources: state.resources.resources,
        auth: state.auth,
        template: state.templates.template.data,
        ...props,
      };
    },
    {
      getResources,
      setActiveTab,
      setActiveEditTab,
      getTemplate,
      resetTab,
    }
  ),
  withState("intervalData", "setIntervalData", null),
  withState("listDays", "setListDays", []),
  withState("countdowns", "setCountdowns", []),
  withHandlers({
    calculateCountdown: () => (endDate) => countdown(endDate),

    stop: ({ intervalData }) => async () => {
      clearInterval(intervalData);
    },
  }),
  withPropsOnChange(
    ["listDays"],
    ({
      listDays,
      setCountdowns,
      setIntervalData,
      calculateCountdown,
      intervalData,
      stop,
    }) => {
      if (intervalData) {
        stop();
      }
      const interval = setInterval(async () => {
        const countdowns = (listDays || []).map((item) => {
          if (!item) return {};
          const date = calculateCountdown(item.date);
          if (date) {
            return {
              id: item.id,
              ...date,
            };
          }
          return {};
        });
        setCountdowns(countdowns);
      }, 1000);
      setIntervalData(interval);
    }
  ),
  lifecycle({
    componentWillReceiveProps(nextProps) {
      const filter = get(this.props, "filter");
      // clear interval on Produced Spots
      if (filter === "active") {
        this.props.stop();
      } else {
        // render list days for countdown
        /* eslint-disable */
        if (
          (get(this.props, "resources") !== get(nextProps, "resources") &&
            get(nextProps, `resources.filter_${filter}.data`)) ||
          (!get(this.props, "intervalData") &&
            get(nextProps, `resources.filter_${filter}.data`))
        ) {
          const listDays = get(
            nextProps,
            `resources.filter_${filter}.data`,
            []
          ).map((resource, index) => {
            const date = get(resource, "_source.quantity", null);
            const id = get(resource, "_id", null);
            if (date) {
              return {
                id,
                date,
              };
            }
          });
          this.props.setListDays(listDays);
        }
      }
    },
    componentWillUnmount() {
      this.props.stop();
    },
  })
)(ResourceList);
export default withRouter(injectIntl(Enhanced));
