import { connect } from "react-redux";
import { compose } from "recompose";
import { get } from "lodash";

import View from "./view";

const Enhanced = compose(
  connect((state, props) => {
    const schedules = get(state, "jocks.schedules.data");
    const loading = get(state, "jocks.schedules.loading");
    const floatingContestSchedules = get(
      state,
      "jocks.floatingContestSchedules.data"
    );
    return {
      schedules,
      floatingContestSchedules,
      loading,
      ...props,
    };
  })
)(View);

export default Enhanced;
