import * as React from "react";
import { compose, lifecycle, withHandlers } from "recompose";
import { connect } from "react-redux";
import { get } from "lodash";
import View from "./view";
import { injectIntl } from "react-intl";
import ToastManager from "components/ToastManager";
import { MdImportantDevices } from "react-icons/lib/md";
import {
  getProductionSettings,
  updateProductionSettings,
} from "store/actions/settings";
export default injectIntl(
  compose(
    connect(
      ({ servers, ...rest }) => {
        return {
          currentServer: get(servers, "currentServer.data", {}),
          loading: get(rest, "settings.production_settings.loading", false),
          productionSettings: get(
            rest,
            "settings.production_settings.data",
            {}
          ),
        };
      },
      { getProductionSettings, updateProductionSettings }
    ),
    withHandlers({
      onFormSubmit: ({
        updateProductionSettings,
        intl,
        setShouldBlockNavigation,
      }) => (values) => {
        updateProductionSettings(values, () => {
          ToastManager.show({
            title: <MdImportantDevices />,
            message: intl.formatMessage({
              id: "production settings > update successfully",
            }),
            level: "success",
          });
          setShouldBlockNavigation(false);
        });
      },
    }),
    withHandlers({
      onGetProductionSettings: ({
        currentStation,
        getProductionSettings,
      }) => () => {
        getProductionSettings({
          station_id: currentStation.key,
        });
      },
    }),
    lifecycle({
      componentDidMount() {
        const { onGetProductionSettings } = this.props;
        onGetProductionSettings();
      },
      componentDidUpdate(prevProps) {
        const isStationChanged =
          this.props.currentStation &&
          this.props.currentStation.key !== prevProps.currentStation.key;
        if (isStationChanged) {
          this.props.onGetProductionSettings();
        }
      },
    })
  )(View)
);
