import * as React from "react";
import { map, isFunction } from "lodash";
import cn from "classnames";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

class FilterDay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: props.currentDay
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.currentDay !== nextProps.currentDay) {
      this.setState({
        active: nextProps.currentDay
      });
    }
  }
  onClick = day => {
    const { onChange } = this.props;
    let active = day;
    if (this.state.active === day) {
      active = "";
    }
    this.setState({ active }, () => {
      if (isFunction(this.props.onChange)) onChange(active);
    });
  };
  render() {
    const { days } = this.props;
    return (
      <div className="ps-rbc-filter-day">
        {map(days, (day, index) => {
          return (
            <span
              key={index}
              className={cn("filter-day-item", {
                "filter-day-item-selected": this.state.active === day
              })}
              onClick={() => this.onClick(day)}
            >
              <span className="ps-rbc-day">{day}</span>
              <FormattedMessage
                id={`calendar > ${day === 1 ? "day" : "days"}`}
              />
            </span>
          );
        })}
      </div>
    );
  }
}
FilterDay.defaultProps = {
  days: [1, 2, 3, 4, 5, 6, 7],
  currentDay: 7
};
FilterDay.propTypes = {
  days: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  currentDay: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
export default FilterDay;
