import * as React from "react";
import { compose, withHandlers, withState } from "recompose";
import { connect } from "react-redux";
import { MdImportantDevices } from "react-icons/lib/md";
import { forOwn, first, get, pick, map } from "lodash";
import ToastManager from "components/ToastManager";
import ProfileForm from "./view";
import { injectIntl } from "react-intl";
import {
  updateUser,
  getUser,
  updateUserOOOSettings,
} from "store/actions/users";

export default injectIntl(
  compose(
    connect(
      (state) => {
        return {
          privileges: get(state.auth, "user.privileges", []) || [],
          auth: state.auth,
        };
      },
      {
        updateUser,
        getUser,
        updateUserOOOSettings,
      }
    ),
    withState("isLoading", "setIsLoading", false),
    withHandlers({
      onFormSubmit: ({
        updateUser,
        setIsLoading,
        intl,
        getUser,
        user,
        isSpecialUser,
        updateUserOOOSettings,
        setShouldBlockNavigation,
      }) => async (values, formikProps) => {
        setIsLoading(true);
        const id = user.id;
        let dataToSend = pick(values, [
          "first_name",
          "last_name",
          "email",
          "language",
          "phone",
          "location",
          "title",
          "timezone",
          "location_text",
        ]);
        if (isSpecialUser) {
          dataToSend = {
            ...dataToSend,
            summary: values.summary,
            tags_string: map(
              get(values, "tags", []),
              (item) => item.data.title
            ).join("#"),
            reel: get(values, "reel"),
            tags: map(get(values, "tags", []), (item) => item.value),
          };
        }
        const promise1 = values.is_edit_info
          ? new Promise((resolve, reject) => {
              updateUser(id, dataToSend, (response) => {
                setIsLoading(false);
                const newUserData = get(response, "data.user");
                if (newUserData) {
                  resolve(newUserData);
                } else {
                  reject();
                  forOwn(response, (value, key) => {
                    formikProps.setFieldError(key, first(value));
                  });
                }
              });
            })
          : dataToSend;
        const promise2 = new Promise((resolve, reject) => {
          updateUserOOOSettings(
            id,
            {
              out_of_office_settings: get(
                values,
                "out_of_office_settings",
                []
              ).filter((i) => i.from_date && i.to_date),
            },
            (response) => {
              const newUserData = get(response, "data.user");
              if (newUserData) {
                resolve(newUserData);
              } else {
                reject();
                forOwn(response, (value, key) => {
                  formikProps.setFieldError(key, first(value));
                });
              }
            }
          );
        });
        Promise.all([promise1, promise2]).then((values) => {
          getUser(id);
          setIsLoading(false);
          setShouldBlockNavigation(false);
          ToastManager.show({
            title: <MdImportantDevices />,
            message: intl.formatMessage({
              id: "user > update successfully",
            }),
            level: "success",
          });
        });
      },
    })
  )(ProfileForm)
);
