import steps from "./steps";
const BaseProduction = {
  _index: "templates",
  _type: "template",
  _id: "basic_production",
  _score: 1,
  _source: {
    key: "basic_production",
    templates_name: "Basic Production",
    templates_description: "Track the process of a basic commercial production",
    priviliges: {
      initiate: ["initiate_commercial_production"],
      delete: ["delete_commercial_production"],
      view: ["view_commercial_production", "station_manager"],
      edit: ["edit_commercial_production"],
    },
    workflow: {
      title: "Production workflow",
    },
    steps: steps,
    active: true,
    is_allow_create_custom_workflow: true,
  },
};
export default BaseProduction;
