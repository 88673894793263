import React from "react";
import SettingsForm from "components/SettingsForm";
import Page from "components/Page";
import { FormattedMessage } from "react-intl";
import Spinner from "components/Spinner";

const View = ({ isLoading }) => {
  return (
    <Page applyPadding={false} title={<FormattedMessage id="pages > settings" />}>
      {isLoading ? (
        <Spinner isLoading={isLoading} />
      ) : (
        <div className={"page-container"}>
          <SettingsForm />
        </div>
      )}
    </Page>
  );
};

export default View;
