import * as React from "react";
import bn from "utils/bemnames";
import { find } from "lodash";
import { Row, Col, FormGroup } from "reactstrap";
import { FormattedMessage } from "react-intl";
import RadioButtons from "components/RadioButtons";
import Dropdown from "components/Dropdown";
import classnames from "classnames";
import { Asterisk } from "components/Elements";
import TextInputFormatted from "components/TextInputFormatted";
const bem = bn.create("station-settings-form");
const applyToOptions = [
  {
    label: <FormattedMessage id="station settings > individual" />,
    value: "individual",
  },
  {
    label: <FormattedMessage id="station settings > household" />,
    value: "household",
  },
];

const ContestEligibilityRules = (props) => {
  const { intl, formProps } = props;
  const { values, errors, touched } = formProps;
  const prizeRestrictedOptions = [
    {
      label: intl.formatMessage({ id: "station settings > high-value prize" }),
      value: "high_value_prize",
    },
    {
      label: intl.formatMessage({ id: "station settings > any prize" }),
      value: "any_prize",
    },
  ];
  const marketClusterOptions = [
    {
      label: intl.formatMessage({ id: "station settings > station" }),
      value: "station",
    },
    {
      label: intl.formatMessage({ id: "station settings > market" }),
      value: "market",
    },
    {
      label: intl.formatMessage({ id: "station settings > broadcaster" }),
      value: "broadcaster",
    },
  ];

  return (
    <div className={bem.e("contest-eligibility-rules")}>
      <div className={classnames(bem.e("form-padding"), bem.e("contest-head"))}>
        <h2 className={bem.e("title")}>
          <FormattedMessage
            id={"station settings > contest eligibility rules"}
          />
        </h2>
        <span className={bem.e("contest-helper-text")}>
          <FormattedMessage id="station settings > contest eligibility rules helper" />
        </span>
      </div>

      <div className={bem.e("form-padding")}>
        <span className={bem.e("field-helper-text")}>
          <FormattedMessage id="station settings > minimum age helper" />
        </span>
        <Row>
          <Col xs={3} className={bem.e("col-reset")}>
            <FormGroup>
              <TextInputFormatted
                label={intl.formatMessage({
                  id: "station settings > minimum age",
                })}
                placeholder={intl.formatMessage({
                  id: "station settings > enter age",
                })}
                value={values.minimum_age}
                onChange={(value) =>
                  formProps.setFieldValue("minimum_age", value)
                }
              />
            </FormGroup>
          </Col>
        </Row>
        <span className={bem.e("field-helper-text")}>
          <FormattedMessage id="station settings > max wins specific contest helper" />
        </span>
        <Row>
          <Col xs={3} className={bem.e("col-reset")}>
            <FormGroup>
              <TextInputFormatted
                label={intl.formatMessage({
                  id: "station settings > max wins",
                })}
                placeholder={intl.formatMessage({
                  id: "station settings > enter number",
                })}
                value={values.max_wins_in_specific_contest}
                onChange={(value) =>
                  formProps.setFieldValue("max_wins_in_specific_contest", value)
                }
              />
            </FormGroup>
          </Col>
        </Row>
        <span className={bem.e("field-helper-text")}>
          <FormattedMessage id="station settings > max wins any contest helper" />
        </span>
        <Row>
          <Col xs={3} className={bem.e("col-reset")}>
            <FormGroup>
              <TextInputFormatted
                label={intl.formatMessage({
                  id: "station settings > max wins",
                })}
                placeholder={intl.formatMessage({
                  id: "station settings > enter number",
                })}
                value={values.max_wins_in_30_days}
                onChange={(value) =>
                  formProps.setFieldValue("max_wins_in_30_days", value)
                }
              />
            </FormGroup>
          </Col>
          <Col xs={9} className={bem.e("col-reset")}>
            <FormGroup>
              <RadioButtons
                label={<FormattedMessage id="station settings > apply to" />}
                onSelected={(value) => {
                  formProps.setFieldValue("max_wins_apply_to", value);
                }}
                selectedValue={values.max_wins_apply_to}
                buttons={applyToOptions}
              />
            </FormGroup>
          </Col>
        </Row>
        <span className={bem.e("field-helper-text")}>
          <FormattedMessage id="station settings > minimum wait time following a general prize win" />
        </span>
        <Row>
          <Col xs={3} className={bem.e("col-reset")}>
            <FormGroup>
              <TextInputFormatted
                label={intl.formatMessage({
                  id: "station settings > days to wait",
                })}
                placeholder={intl.formatMessage({
                  id: "station settings > enter number",
                })}
                value={values.general_prize_min_days_wait}
                onChange={(value) =>
                  formProps.setFieldValue("general_prize_min_days_wait", value)
                }
              />
            </FormGroup>
          </Col>
          <Col xs={9} className={bem.e("col-reset")}>
            <FormGroup>
              <RadioButtons
                label={<FormattedMessage id="station settings > apply to" />}
                onSelected={(value) => {
                  formProps.setFieldValue(
                    "general_prize_min_days_wait_apply_to",
                    value
                  );
                }}
                selectedValue={values.general_prize_min_days_wait_apply_to}
                buttons={applyToOptions}
              />
            </FormGroup>
          </Col>
        </Row>
        <span className={bem.e("field-helper-text")}>
          <FormattedMessage id="station settings > minimum wait time following a cash prize win" />
        </span>
        <Row>
          <Col xs={3} className={bem.e("col-reset")}>
            <FormGroup>
              <TextInputFormatted
                label={intl.formatMessage({
                  id: "station settings > days to wait",
                })}
                placeholder={intl.formatMessage({
                  id: "station settings > enter number",
                })}
                value={values.cash_prize_min_days_wait}
                onChange={(value) =>
                  formProps.setFieldValue("cash_prize_min_days_wait", value)
                }
              />
            </FormGroup>
          </Col>
          <Col xs={9} className={bem.e("col-reset")}>
            <FormGroup>
              <RadioButtons
                label={<FormattedMessage id="station settings > apply to" />}
                onSelected={(value) => {
                  formProps.setFieldValue(
                    "cash_prize_min_days_wait_apply_to",
                    value
                  );
                }}
                selectedValue={values.cash_prize_min_days_wait_apply_to}
                buttons={applyToOptions}
              />
            </FormGroup>
          </Col>
        </Row>
        <span className={bem.e("field-helper-text")}>
          <FormattedMessage id="station settings > minimum wait time following a high value prize win" />
        </span>
        <Row>
          <Col xs={3} className={bem.e("col-reset")}>
            <FormGroup>
              <TextInputFormatted
                label={intl.formatMessage({
                  id: "station settings > high value minimum",
                })}
                placeholder={intl.formatMessage({
                  id: "station settings > enter amount",
                })}
                value={values.high_value_min_amount}
                onChange={(value) =>
                  formProps.setFieldValue("high_value_min_amount", value)
                }
              />
            </FormGroup>
          </Col>
          <Col xs={3} className={bem.e("col-reset")}>
            <FormGroup>
              <TextInputFormatted
                label={intl.formatMessage({
                  id: "station settings > days to wait",
                })}
                placeholder={intl.formatMessage({
                  id: "station settings > enter amount",
                })}
                value={values.high_value_min_days_wait}
                onChange={(value) =>
                  formProps.setFieldValue("high_value_min_days_wait", value)
                }
              />
            </FormGroup>
          </Col>
          <Col xs={3} className={bem.e("col-reset")}>
            <FormGroup>
              <Dropdown
                label={
                  <FormattedMessage id="station settings > prize restricted" />
                }
                placeholder={intl.formatMessage({
                  id: "station settings > placeholder select",
                })}
                name="high_value_prize_restricted"
                onChange={(option) => {
                  formProps.setFieldValue(
                    "high_value_prize_restricted",
                    option.value
                  );
                }}
                value={
                  find(
                    prizeRestrictedOptions,
                    (item) => item.value === values.high_value_prize_restricted
                  ) || ""
                }
                options={prizeRestrictedOptions}
                closeMenuOnSelect={true}
              />
            </FormGroup>
          </Col>
          <Col xs={3} className={bem.e("col-reset")}>
            <FormGroup className={bem.e("high-radios")}>
              <RadioButtons
                label={<FormattedMessage id="station settings > apply to" />}
                onSelected={(value) => {
                  formProps.setFieldValue("high_value_min_apply_to", value);
                }}
                selectedValue={values.high_value_min_apply_to}
                buttons={applyToOptions}
              />
            </FormGroup>
          </Col>
        </Row>
        <span className={bem.e("field-helper-text")}>
          <FormattedMessage id="station settings > check winner eligibility against" />
        </span>
        <Row>
          <Col xs={3} className={bem.e("col-reset")}>
            <Dropdown
              label={
                <span>
                  <FormattedMessage id="station settings > market cluster" />
                  <Asterisk>*</Asterisk>
                </span>
              }
              placeholder={intl.formatMessage({
                id: "station settings > placeholder select",
              })}
              name="market_cluster"
              onChange={(option) => {
                formProps.setFieldValue("market_cluster", option.value);
              }}
              error={touched.market_cluster && errors.market_cluster}
              value={
                find(
                  marketClusterOptions,
                  (item) => item.value === values.market_cluster
                ) || ""
              }
              options={marketClusterOptions}
              closeMenuOnSelect={true}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default ContestEligibilityRules;
