import React from "react";
import { get, includes, map } from "lodash";
import bn from "utils/bemnames";
import { Button } from "reactstrap";
import classnames from "classnames";
import CopyGroup from "./CopyGroup";
import Buttons from "components/PromotionLinerDetails/Buttons";
import { FormattedMessage } from "react-intl";
import ConfirmationModal from "components/ConfirmationModal";
import { PlusSmallIcon } from "components/CustomIcons";
const bem = bn.create("promotion-liner-scripts");

export default function PromotionLinerScript(props) {
  const {
    validationState,
    onValueChanged,
    onAddNewGroup,
    step,
    template,
    user,
    process,
    onValueChangedField,
    copyGroups,
  } = props;
  const isView = props.mode === "view";

  const totalCopyGroups = copyGroups.length;
  const totalCopyGroupsSuperseding = copyGroups.filter(
    (item) => item.status === "superseding"
  );
  return (
    <div className={bem.b()}>
      {map(copyGroups, (group, index) => {
        const isOpen = props.isOpenGroupIndex === index;
        let showDelete = !isView && index === 0 && totalCopyGroups > 1;
        if (
          includes(["baseline", "superseding", null], group.status) &&
          totalCopyGroupsSuperseding.length > 0 &&
          !isView
        ) {
          showDelete = true;
        }
        return (
          <CopyGroup
            group={group}
            idx={index}
            key={index}
            isOpen={isOpen}
            step={step}
            process={process}
            template={template}
            user={user}
            validationState={get(
              validationState,
              `copy_groups.validation_error.${index}`
            )}
            onDelete={() => {
              props.setCurrentGroupIndex(index);
              props.setIsOpenDeleteConfirmationModal(true);
            }}
            isView={isView}
            isShowDelete={showDelete}
            onChange={onValueChanged}
            onAddToRotation={props.onAddToRotation}
            toggle={() => props.setIsOpenGroupIndex(!isOpen ? index : null)}
          />
        );
      })}
      {!isView && (
        <div className={bem.e("section-add-group")}>
          <div className={bem.e("add-group")}>
            <Button
              className={classnames(bem.e("add-button"), "btn-radius")}
              outline
              color="blue"
              onClick={() => onAddNewGroup()}
            >
              <span className={bem.e("add-icon")}>
                <PlusSmallIcon />
              </span>
              <FormattedMessage id="process > button add a copy group" />
            </Button>
          </div>
        </div>
      )}

      <Buttons
        buttonClicked={props.buttonClicked}
        step={step}
        process={process}
        template={template}
        user={user}
        isView={isView}
        bem={bem}
        onValueChanged={onValueChangedField}
        setValidationError={props.setValidationError}
      />
      {/* confirmation delete modal */}
      <ConfirmationModal
        isOpen={props.isOpenDeleteConfirmationModal}
        className={bem.e("confirmation-modal")}
        title={
          <FormattedMessage id="process > are you sure you want to delete" />
        }
        description={null}
        cancelTitle={<FormattedMessage id="process > button cancel" />}
        confirmTitle={<FormattedMessage id="process > button yes" />}
        onCancel={() => {
          props.setIsOpenDeleteConfirmationModal(false);
          props.setCurrentGroupIndex(null);
        }}
        onConfirm={props.onDeleteGroup}
        onToggle={() =>
          props.setIsOpenDeleteConfirmationModal(
            !props.isOpenDeleteConfirmationModal
          )
        }
        isCloseOutside={false}
      />
    </div>
  );
}
