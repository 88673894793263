import View from "./view";
import { connect } from "react-redux";
import { compose, withHandlers, withState } from "recompose";
import { deleteMessages } from "store/actions/messages";
import { get } from "lodash";
const Enhancer = compose(
  connect(
    (state) => ({
      auth: state.auth,
      dateFormatByServer: get(
        state,
        "servers.currentServer.data.date_format",
        "MM/DD/YYYY"
      ),
    }),
    { deleteMessages }
  ),
  withState("isLoading", "setIsLoading", false),
  withHandlers({
    onDeleteMessages: ({ deleteMessages, setIsLoading }) => (messages) => {
      setIsLoading(true);
      deleteMessages(messages, () => {
        setIsLoading(false);
      });
    },
  })
)(View);

export default Enhancer;
