import React, { useRef, useState } from "react";
import bn from "utils/bemnames";
import { FormattedMessage } from "react-intl";
import { get, map, find, findIndex } from "lodash";
import moment from "moment";
import AssetReviewStatus from "../AssetReviewStatus";
import { labelToNameField } from "utils/helpers";
import ConfirmationModal from "components/ConfirmationModal";
import Chat from "components/NotificationsPusher";

const bem = bn.create("client-review");
function CommentSidebar(props) {
  const [isEditComment, setIsEditComment] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentDeleteComment, setCurrentDeleteComment] = useState(null);
  const containerContent = useRef();
  const { template, user, intl } = props;
  const step = find(
    get(template, "steps", []),
    (item) => item.key === "review_media"
  );
  const {
    mainSlickRef,
    isShowGuide,
    valueChanged,
    channel,
    isClientReviewed,
    stations,
    dateFormatByServer
  } = props;
  const onSlickTo = (index) => {
    if (mainSlickRef.current) mainSlickRef.current.slickGoTo(index);
    containerContent.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    }); // scroll top to
  };
  const onDeleteComment = (values, field) => {
    const currentMedia = field;
    const currentAsset = field.value;

    const marketingChannelsField = step.fields.find(
      (f) => f.field === "marketing_channels"
    );
    let channels = get(process, "data.fields.marketing_channels.value", []);
    channels = channels
      .map((item) => {
        if (channel.id === item.id) {
          const fieldName = labelToNameField(currentMedia.label);
          let fieldValue = channel[fieldName];
          if (fieldValue) {
            fieldValue = fieldValue.map((fieldValueItem) => {
              // assign new comment to current asset
              if (fieldValueItem.path === currentAsset.path) {
                let number = 0;
                let comments = get(fieldValueItem, "comments", [])
                  .map((commentItem) => {
                    if (commentItem.id === values.id) {
                      return {
                        ...commentItem,
                        is_deleted: true,
                      };
                    }
                    return commentItem;
                  })
                  .map((i) => {
                    if (i.is_deleted) return i;
                    number += 1;
                    return {
                      ...i,
                      number,
                    };
                  });
                return {
                  ...fieldValueItem,
                  comments,
                };
              }
              return fieldValueItem;
            });
            return {
              ...item,
              [fieldName]: fieldValue,
            };
          }
        }
        return item;
      })
      .map((item) => {
        if (channel.id === item.id) {
          const fieldName = labelToNameField(currentMedia.label);
          let fieldValue = item[fieldName];
          const fields = item.fields.map((field) => {
            if (
              currentMedia.label === field.label &&
              currentMedia.key === field.key &&
              field.value_arr
            ) {
              let newValueHistory = [];
              const valueHistory = get(field, "value_history", []);
              fieldValue.forEach((valueItem) => {
                const valueHistoryIndex = findIndex(
                  get(field, "value_history", []),
                  (valueHistoryItem) => valueHistoryItem.path === valueItem.path
                );
                if (valueHistoryIndex === -1) {
                  newValueHistory.push({
                    ...valueItem,
                    time: new Date(),
                  });
                } else {
                  newValueHistory.push({
                    ...valueHistory[valueHistoryIndex],
                    comments: valueItem.comments || [],
                    time: new Date(),
                  });
                }
              });
              return {
                ...field,
                value_history: newValueHistory,
              };
            }
            return field;
          });
          return {
            ...item,
            fields,
          };
        }
        return item;
      });
    process.data.fields.marketing_channels = {
      value: channels,
      is_dirty: true,
    };
    valueChanged(marketingChannelsField, process, template, user, {
      value: channels,
    });
    props.onAutoSubmit(process);
  };
  const onSubmitEditComment = (values, field) => {
    const currentMedia = field;
    const currentAsset = field.value;
    const marketingChannelsField = step.fields.find(
      (f) => f.field === "marketing_channels"
    );
    let channels = get(process, "data.fields.marketing_channels.value", []);
    channels = channels
      .map((item) => {
        if (channel.id === item.id) {
          const fieldName = labelToNameField(currentMedia.label);
          let fieldValue = channel[fieldName];
          if (fieldValue) {
            fieldValue = fieldValue.map((fieldValueItem) => {
              // assign new comment to current asset
              if (fieldValueItem.path === currentAsset.path) {
                let comments = get(fieldValueItem, "comments", []);
                const commentIndex = findIndex(
                  comments,
                  (commentItem) => commentItem.id === values.id
                );
                if (commentIndex !== -1) {
                  comments[commentIndex] = values;
                }
                return {
                  ...fieldValueItem,
                  comments: [...comments],
                };
              }
              return fieldValueItem;
            });
            return {
              ...item,
              [fieldName]: fieldValue,
            };
          }
        }
        return item;
      })
      .map((item) => {
        if (channel.id === item.id) {
          const fieldName = labelToNameField(currentMedia.label);
          let fieldValue = item[fieldName];
          const fields = item.fields.map((field) => {
            if (
              currentMedia.label === field.label &&
              currentMedia.key === field.key &&
              field.value_arr
            ) {
              let newValueHistory = [];
              const valueHistory = get(field, "value_history", []);
              fieldValue.forEach((valueItem) => {
                const valueHistoryIndex = findIndex(
                  get(field, "value_history", []),
                  (valueHistoryItem) => valueHistoryItem.path === valueItem.path
                );
                if (valueHistoryIndex === -1) {
                  newValueHistory.push({
                    ...valueItem,
                    time: new Date(),
                  });
                } else {
                  newValueHistory.push({
                    ...valueHistory[valueHistoryIndex],
                    comments: valueItem.comments || [],
                    time: new Date(),
                  });
                }
              });
              return {
                ...field,
                value_history: newValueHistory,
              };
            }
            return field;
          });
          return {
            ...item,
            fields,
          };
        }
        return item;
      });
    process.data.fields.marketing_channels = {
      value: channels,
      is_dirty: true,
    };
    valueChanged(marketingChannelsField, process, template, user, {
      value: channels,
    });
    setIsEditComment(false);
    props.onAutoSubmit(process);
  };
  const { process, mediaFields } = props;
  const clientName = get(process, "data.fields.client_name.value");
  const processTitle = get(process, "data.fields.order_title.value");
  const orderStartDate = get(process, "data.fields.order_start_date.value")
    ? moment(get(process, "data.fields.order_start_date.value")).format(
        dateFormatByServer
      )
    : "";
  const orderEndDate = get(process, "data.fields.order_end_date.value")
    ? moment(get(process, "data.fields.order_end_date.value")).format(
        dateFormatByServer
      )
    : "";
  return (
    <div className={bem.e("comment-sidebar")}>
      <h2 className={bem.e("comment-client-name")} ref={containerContent}>
        {clientName}
      </h2>
      <h2 className={bem.e("process-title")}>{processTitle}</h2>
      <div className={bem.e("process-campaign")}>
        <strong>
          <FormattedMessage id="process > campaign" />:
        </strong>
        <span className={bem.e("campaign")}>
          <FormattedMessage id="process > introducing" /> {processTitle}
        </span>
      </div>
      <div className={bem.e("process-stations")}>
        <strong>
          <FormattedMessage id="process > stations" />:
        </strong>
        <span className={bem.e("stations")}>
          {map(stations, (item) => item.name).join(", ")}
        </span>
      </div>
      <div className={bem.e("process-flight-dates")}>
        <strong>
          <FormattedMessage id="process > flight dates" />:
        </strong>
        <span
          className={bem.e("date")}
        >{`${orderStartDate} - ${orderEndDate}`}</span>
      </div>
      <div className={bem.e("chat-comments")}>
        <Chat
          step={step}
          process={process}
          template={template}
          chatInputPlaceHolder={intl.formatMessage({
            id: "process > client feedback input placeholder",
          })}
          user={user}
          isCollapse={false}
          setIsCollapse={() => {}}
          // successCallback={() => {
          //   props.onGetReviewProcess();
          // }}
        />
      </div>
      {!isShowGuide && !isClientReviewed && (
        <div className={bem.e("media-status-container")} key={props.refreshKey}>
          {map(mediaFields, (field, index) => (
            <AssetReviewStatus
              field={field}
              key={index}
              intl={intl}
              isEditComment={isEditComment}
              setIsEditComment={setIsEditComment}
              onSubmitEditComment={(comment) => {
                onSubmitEditComment(comment, field);
              }}
              onSlickTo={() => {
                onSlickTo(index);
              }}
              onDeleteComment={(comment) => {
                onDeleteComment(comment, field);
              }}
              onSelectDelete={(comment) => {
                setIsDeleteModalOpen(true);
                setCurrentDeleteComment({
                  comment,
                  field,
                });
              }}
            />
          ))}
        </div>
      )}
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        title={intl.formatMessage({
          id: "process > are you sure you want to delete this comment",
        })}
        onConfirm={() => {
          if (currentDeleteComment) {
            setIsDeleteModalOpen(false);
            onDeleteComment(
              currentDeleteComment.comment,
              currentDeleteComment.field
            );
          }
        }}
        onToggle={() => {
          setIsDeleteModalOpen(false);
          setCurrentDeleteComment(null);
        }}
        onCancel={() => {
          setCurrentDeleteComment(null);
          setIsDeleteModalOpen(false);
        }}
        isCloseOutside={false}
      />
    </div>
  );
}
export default CommentSidebar;
