import React, { useState } from "react";
import bn from "utils/bemnames";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import { debounce, find, get, toLower } from "lodash";
import { FaSortDesc, FaSortAsc, FaPlus } from "react-icons/lib/fa";
import { Table, Button, Row, Col } from "reactstrap";
import { formatDisplayDate } from "utils/helpers";
import { EyeIcon } from "components/CustomIcons";
import NoItemsFound from "components/NoItemsFound";
import useInfiniteScroll from "utils/useInfiniteScroll";
import Dropdown from "components/Dropdown";
import SearchBox from "components/SearchBox";
import PreviewItemModal from "../PreviewItemModal";
import AddItemModal from "../AddItemModal";
import PrivilegedComponent from "components/PrivilegedComponent";
import { PRIVILEGES } from "utils/constants";
const bem = bn.create("associate-items");
export const spotDisplay = (process) => {
  return get(process, "order_type.label", "");
};
export const stationDisplay = (process) => {
  return get(process, "order_stations_array", []).map(i => i.name).join(", ");
};
export const typeDisplay = (process) => {
  const schedulingMethod = get(process, "scheduling_type");
  return (
    <div
      className={classnames(
        "type",
        "liner-type",
        `liner-${toLower(schedulingMethod)}`
      )}
    >
      <strong>{schedulingMethod ? schedulingMethod.charAt(0) : ""}</strong>
      <span>{schedulingMethod}</span>
    </div>
  );
};
export const flightDatesDisplay = (process, dateFormatByServer) => {
  const startDate = get(process, "order_start_date");
  const endDate = get(process, "order_end_date");
  return (
    <div className={"flight-dates"}>
      <span>{startDate ? formatDisplayDate(startDate, dateFormatByServer) : ""}</span>
      <span>
        {endDate ? ` - ${formatDisplayDate(endDate, dateFormatByServer)}` : ""}
      </span>
    </div>
  );
};

export const titleDisplay = (process) => {
  if (get(process, "order_title")) {
    return process.order_title;
  }
  return <FormattedMessage id="process > title of spot" />;
};
const View = ({
  onSort,
  sorted,
  searchData,
  isLoading,
  getMoreItems,
  hasMore,
  onSelect,
  intl,
  searchText,
  setSearchText,
  onSearch,
  process,
  selectedStation,
  definitions,
  typeId,
  setTypeId,
  getItemsList,
  isView,
  dateFormatByServer
}) => {
  const [previewItem, setPreviewItem] = useState(null);
  const [isOpenAddItemModal, setIsOpenAddItemModal] = useState(false);
  const items = get(searchData, "items", []);
  const definitionsOptions = definitions.map((slotDefinition) => ({
    label: `${slotDefinition.type} - ${slotDefinition.name}`,
    value: slotDefinition.id,
  }));
  const onDelaySearch = debounce(onSearch, 200);

  const renderEmpty = () => {
    return <NoItemsFound />;
  };
  useInfiniteScroll({
    useWindow: false,
    elementId: "associate-items-scroll",
    onLoadMore: getMoreItems,
    shouldLoadMore: hasMore,
  });
  return (
    <div className={classnames(bem.b(), "cr-list-table")}>
      <h2 className={bem.e("title")}>
        <FormattedMessage id="process > promotion > contest > title associate item list" />
      </h2>
      <Row>
        <Col xs={6} className={bem.e("reset-col")}>
          <Dropdown
            name="type_id"
            placeholder={intl.formatMessage({
              id: "process > promotion > contest > select",
            })}
            isClearable
            onChange={(selectedOption) => {
              const typeId = get(selectedOption, "value", null);
              setTypeId(typeId);
              getItemsList({
                type_id: typeId,
              });
            }}
            value={
              find(definitionsOptions, (item) => item.value === typeId) || ""
            }
            options={definitionsOptions}
          />
        </Col>
        <Col xs={6} className={bem.e("reset-col")}>
          <SearchBox
            placeholder={intl.formatMessage({
              id:
                "process > promotion > contest > placeholder search associate input",
            })}
            onSearchClicked={onSearch}
            searchText={searchText}
            setSearchText={(text) => {
              setSearchText(text);
              onDelaySearch(text);
            }}
          />
        </Col>
      </Row>
      <Table className={"table-scroll"}>
        <thead>
          <tr>
            <th
              onClick={() => onSort("order_type")}
              className={classnames("can-click", "table-first-head")}
            >
              <div className={"item-sortable"}>
                <FormattedMessage id="process > Type" />
                <div className={"sortable"}>
                  <FaSortAsc
                    className={classnames("sortable-icon", {
                      "sortable-active":
                        get(sorted, "id") === "order_type" &&
                        get(sorted, "desc"),
                    })}
                  />
                  <FaSortDesc
                    className={classnames("sortable-icon", {
                      "sortable-active":
                        get(sorted, "id") === "order_type" &&
                        !get(sorted, "desc"),
                    })}
                  />
                </div>
              </div>
            </th>

            <th onClick={() => onSort("order_title")} className="can-click">
              <div className={"item-sortable"}>
                <FormattedMessage id="process > Title" />
                <div className={"sortable"}>
                  <FaSortAsc
                    className={classnames("sortable-icon", {
                      "sortable-active":
                        get(sorted, "id") === "order_title" &&
                        !get(sorted, "desc"),
                    })}
                  />
                  <FaSortDesc
                    className={classnames("sortable-icon", {
                      "sortable-active":
                        get(sorted, "id") === "order_title" &&
                        get(sorted, "desc"),
                    })}
                  />
                </div>
              </div>
            </th>
            <th onClick={() => onSort("station")} className="can-click">
              <div className={"item-sortable"}>
                <FormattedMessage id="process > entity" />
                <div className={"sortable"}>
                  <FaSortAsc
                    className={classnames("sortable-icon", {
                      "sortable-active":
                        get(sorted, "id") === "station" && !get(sorted, "desc"),
                    })}
                  />
                  <FaSortDesc
                    className={classnames("sortable-icon", {
                      "sortable-active":
                        get(sorted, "id") === "station" && get(sorted, "desc"),
                    })}
                  />
                </div>
              </div>
            </th>
            <th className="text-center">
              <FormattedMessage id="process > Preview" />
            </th>
            <th
              onClick={() => onSort("order_start_date")}
              className="can-click"
            >
              <div className={"item-sortable"}>
                <FormattedMessage id="process > flight dates" />
                <div className={"sortable"}>
                  <FaSortAsc
                    className={classnames("sortable-icon", {
                      "sortable-active":
                        get(sorted, "id") === "order_start_date" &&
                        !get(sorted, "desc"),
                    })}
                  />
                  <FaSortDesc
                    className={classnames("sortable-icon", {
                      "sortable-active":
                        get(sorted, "id") === "order_start_date" &&
                        get(sorted, "desc"),
                    })}
                  />
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="scroll-bar-style" id="associate-items-scroll">
          {items.length === 0 && !isLoading ? (
            <tr>
              <td colSpan="6">{renderEmpty()}</td>
            </tr>
          ) : (
            items.map((process) => {
              if (!process._source.template_key) return null;
              const spot = spotDisplay(process);
              const station = get(
                process,
                "_source.order_stations_array.0",
                {}
              );
              const processData = {
                ...process._source,
                key: process._id,
                _id: process._id,
              };
              return (
                <tr key={process._id} className="list-liner-item">
                  {/* type */}
                  <td
                    className={classnames(
                      "align-middle align-middle first-col"
                    )}
                    onClick={() => onSelect(processData)}
                  >
                    <span className={"td-value"}>
                      {typeDisplay(processData)}
                    </span>
                  </td>
                  {/* title */}
                  <td
                    className={classnames("align-middle title")}
                    onClick={() => onSelect(processData)}
                  >
                    <span className={"td-value"}>
                      {titleDisplay(processData)}
                    </span>
                    <span className={"sub-value"}>{spot}</span>
                  </td>
                  {/* entity */}
                  <td
                    className={classnames("align-middle")}
                    onClick={() => onSelect(processData)}
                  >
                    <span className={"td-value"}>
                      {stationDisplay(processData)}
                    </span>
                    {station.letters ? (
                      <span className={"sub-value"}>{station.letters}</span>
                    ) : null}
                  </td>
                  {/* duplicate */}
                  <td className="align-middle text-center">
                    <span
                      className={"td-value"}
                      onClick={(e) => {
                        e.preventDefault();
                        setPreviewItem(process);
                      }}
                    >
                      <EyeIcon />
                    </span>
                  </td>
                  {/* flight dates */}
                  <td
                    className="align-middle"
                    onClick={() => onSelect(processData)}
                  >
                    <span className={"td-value"}>
                      {flightDatesDisplay(processData, dateFormatByServer)}
                    </span>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </Table>
      {!isView && (
        <PrivilegedComponent requires={[PRIVILEGES.PROGRAMMING_ELEMENT_SAVE]}>
          <div className={bem.e("buttons")}>
            <Button
              onClick={() => {
                if (!selectedStation || isView) return;
                setIsOpenAddItemModal(true);
              }}
              color="blue"
              outline
              disabled={!selectedStation}
              className={"btn-radius btn btn-add"}
            >
              <FaPlus />
              <FormattedMessage id="process > promotion > contest > add a new item" />
            </Button>
          </div>
        </PrivilegedComponent>
      )}

      <AddItemModal
        isOpen={isOpenAddItemModal}
        process={process}
        onToggle={() => setIsOpenAddItemModal(!isOpenAddItemModal)}
        onSelectItem={onSelect}
      />
      <PreviewItemModal
        isOpen={!!previewItem}
        onToggle={() => setPreviewItem(null)}
        item={previewItem}
      />
    </div>
  );
};
export default View;
