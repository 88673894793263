import React from "react";
import classnames from "classnames";
import { Button } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { DubbingSentButton } from "components/CustomIcons";
const DubButton = (props) => {
  const {
    dubbingProgress,
    processId,
    bem,
    callAutomateDubbing,
    setDubbingProgress,
    buttonTimeout,
    setDubbingButtonTimeout,
    isDisabled,
    isDubbingCompleted,
  } = props;
  const onClickMarkAsDubbed = () => {
    if (isDubbingCompleted || isDisabled) {
      return false;
    }

    if (dubbingProgress === 0) {
      setDubbingProgress(30);
      callAutomateDubbing(processId, 30);
    } else if (dubbingProgress > 0 && dubbingProgress <= 30) {
      clearTimeout(buttonTimeout);

      setDubbingButtonTimeout(null);
      setDubbingProgress(0);
    }
  };

  const getDubbingButtonText = () => {
    if (isDubbingCompleted) {
      return "process > button dub sent";
    }

    if (dubbingProgress === 0) {
      return "process > button dub";
    }

    if (dubbingProgress === 30) {
      return "process > button dub undo";
    }
  };

  return (
    <React.Fragment>
      {dubbingProgress > 0 && (
        <div
          className={classnames(bem.e("dubbing-progress"), {
            complete: isDubbingCompleted,
          })}
          style={{ width: `${dubbingProgress}px` }}
        ></div>
      )}
      <Button
        className={classnames("btn-radius", bem.e("dub-order"), {
          complete: isDubbingCompleted,
        })}
        color="primary"
        onClick={() => onClickMarkAsDubbed()}
        disabled={isDisabled}
      >
        {isDubbingCompleted && <DubbingSentButton />}
        {(dubbingProgress <= 30 || isDubbingCompleted) && (
          <span className="sent-text">
            <FormattedMessage id={getDubbingButtonText()} />
          </span>
        )}
      </Button>
    </React.Fragment>
  );
};

export default DubButton;
