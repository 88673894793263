import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { validateSubdomain } from 'store/actions/auth';
import { first, includes } from 'lodash';

const prod_envs=[
'promosuite.ventive.app',
'promosuitedevelop.ventive.app',
'promosuitedev.ventive.app',
'promosuitefeartures.ventive.app',
'promosuitestage.ventive.app',
'promosuitedemo.ventive.app',
'ps-live.ventive.app',
'promosuitenext.app',
'promosuitenext.net',
'promosuiteplus.app',
'promosuiteplus.net',
'ps.com',
];
let redirected = false;
prod_envs.map(env=>{
    if (document.location.href.toLowerCase().indexOf('://'+env)>-1 && !redirected){
        redirected = true;
        document.location.href = 'http://promosuite.com/';
    }
})

//validate subdomain and redirect to admin url if not valid
let subdomain = first(window.location.host.toLowerCase().split('.'));
let validSubdomains = JSON.parse(window.localStorage.getItem('valid_subdomains')) || [];

if(subdomain && subdomain !== 'admin' && subdomain !== 'app' && !includes(validSubdomains, subdomain)){
	validateSubdomain(subdomain, () => {
		if(!redirected) ReactDOM.render(<App />, document.getElementById('root'));
	});
}else{
	if(!redirected) ReactDOM.render(<App />, document.getElementById('root'));
}
