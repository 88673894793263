import { connect } from "react-redux";
import React from "react";
import { compose, lifecycle, withHandlers } from "recompose";
import { get } from "lodash";
import Winner from "./view";
import { injectIntl } from "react-intl";
import { withState } from "recompose";
import { withRouter } from "react-router";
import { getWinner, updateWinnerFulfilled } from "store/actions/winners";
import ToastManager from "components/ToastManager";
import { MdImportantDevices } from "react-icons/lib/md";
import { getSystemFormDefault } from "store/actions/systemForms";

const Enhanced = compose(
  connect(
    (state, props) => {
      const {
        match: { params },
      } = props;
      const isView = get(params, "action") === "view";
      const winnerId = get(params, "winner_id");
      return {
        auth: get(state, "auth", {}),
        winner: get(state, "winners.winner.data", {}),
        isView,
        winnerId,
        isLoading:
          get(state, "winners.updateWinner.loading", false) ||
          get(state, "winners.fulfilledWinner.loading", false) ||
          get(state, "winners.winner.loading", false) ||
          get(state, "systemForms.systemForm.loading", false),
        systemForm: get(state, "systemForms.systemForm.data", null),

        ...props,
      };
    },
    { getWinner, updateWinnerFulfilled, getSystemFormDefault }
  ),
  withState("activeTab", "setActiveTab", "1"),
  withHandlers({
    onUpdateFulfilled: ({ winnerId, updateWinnerFulfilled, intl }) => (
      data,
      action
    ) => {
      let params = {
        ...data,
        winner_id: winnerId,
      };
      if (action === "undo" || action === "unfulfilled") {
        params.status = "undo";
      }
      // Unfulfilled successfully
      updateWinnerFulfilled(params, () => {
        ToastManager.show({
          title: <MdImportantDevices />,
          message: intl.formatMessage({
            id: `winners > ${action} successfully`,
          }),
          level: "success",
        });
      });
    },
  }),
  lifecycle({
    componentDidMount() {
      const { getSystemFormDefault } = this.props;
      this.props.getWinner(this.props.winnerId, (response) => {
        const stationId = get(response, `contests.0.station_key`);
        if (stationId) {
          getSystemFormDefault({
            station_id: stationId,
            form_type: "individual_studio_console_winner_form",
          });
        }
      });
    },
  })
)(Winner);
export default withRouter(injectIntl(Enhanced));
