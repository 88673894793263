import AdminRights from "./view";
import * as React from "react";
import { get, first } from "lodash";
import { connect } from "react-redux";
// import { getUserStations } from "store/actions/auth";
import { getAdminRightsUser, updateAdminRightsUser } from "store/actions/users";
// import { getAllServers } from "store/actions/servers";
import { compose, withHandlers, withState, lifecycle } from "recompose";
import { MdImportantDevices } from "react-icons/lib/md";
import ToastManager from "components/ToastManager";
import { injectIntl } from "react-intl";

export default injectIntl(
  compose(
    connect(
      (state, props) => {
        const ids = get(props, "ids", []);
        const id = first(ids);
        if (ids.length === 1) {
          return {
            // isMultiple: false,
            id,
            user: get(state, `users.user.${id}`, {}),
            auth: state.auth,
            // servers: get(state, "servers.allServers.data", []),
            isLoading:
              get(state, `users.user.${id}.loading`, false) ||
              get(state, `users.updateUser.loading`, false),
          };
        } else {
          return {
            id,
            // ids,
            // isMultiple: true,
            isLoading: get(state, `users.updateUsers.loading`, false),
            auth: state.auth,
            back_button: props.back_button,
            // servers: get(state, "servers.allServers.data", []),
          };
        }
      },
      {
        // getUserStations,
        getAdminRightsUser,
        updateAdminRightsUser,
        // getAllServers,
      }
    ),
    withState("isView", "setIsView", (props) =>
      props.mode === "edit" || props.mode === "add" ? false : true
    ),
    // withState("stations", "setStations", ({ auth, user }) => {
    //   // update station options. Except selected stations of this user
    //   const selectedStations = map(
    //     get(user, "data.admin_stations"),
    //     (item) => item.user_station_id
    //   );
    //   return map(
    //     filter(
    //       get(auth, "user_stations"),
    //       (item) => !includes(selectedStations, item.user_station_id)
    //     ),
    //     (item) => {
    //       return {
    //         id: item.user_station_id,
    //         label: item.name,
    //       };
    //     }
    //   );
    // }),
    // withState("cities", "setCities", []),
    // withState("groups", "setGroups", []),
    withHandlers({
      onFormSubmit: ({
        updateAdminRightsUser,
        id,
        // isMultiple,
        // ids,
        intl,
        // updateAdminRightsUsers,
        setIsView,
      }) => (values) => {
        let dataToSend;
        dataToSend = {
          admin_type: values.admin_type,
          password: values.password,
          broadcasting_group_admin:
            values.admin_type === "broadcasting_group_admin",
        };
        updateAdminRightsUser(id, dataToSend, () => {
          ToastManager.show({
            title: <MdImportantDevices />,
            message: intl.formatMessage({
              id: "user > update successfully",
            }),
            level: "success",
          });
          setIsView(true);
        });
      },
    }),
    lifecycle({
      componentDidMount() {
        const { isFocused, id } = this.props;
        // only call when focused to this tab
        // if (isFocused) {
        //   this.props.getUserStations();
        // }
        if (isFocused && id) {
          this.props.getAdminRightsUser(id);
        }
        // get servers list for broadcasting group admin
        // if (isFocused && get(auth, "user.is_god_admin")) {
        //   this.props.getAllServers();
        // }
      },
      componentWillReceiveProps(nextProps) {
        // only call when focused to this tab
        // if (
        //   get(this.props, "isFocused") !== get(nextProps, "isFocused") &&
        //   get(nextProps, "isFocused")
        // ) {
        //   this.props.getUserStations();
        //   // get servers list for broadcasting group admin
        //   if (get(nextProps, "auth.user.is_god_admin")) {
        //     this.props.getAllServers();
        //   }
        // }
        // if (
        //   get(this.props, "isFocused") !== get(nextProps, "isFocused") &&
        //   get(nextProps, "isFocused") &&
        //   get(nextProps, "id")
        // ) {
        //   this.props.getAdminRightsUser(nextProps.id);
        // }
        // update station options. Except selected stations of this user
        // if (
        //   get(this.props, "user") !== get(nextProps, "user") &&
        //   get(nextProps, "id")
        // ) {
        //   const { user, auth } = nextProps;
        //   const selectedStations = map(
        //     get(user, "data.admin_stations"),
        //     (item) => item.user_station_id
        //   );
        //   const stations = map(
        //     filter(
        //       get(auth, "user_stations"),
        //       (item) => !includes(selectedStations, item.user_station_id)
        //     ),
        //     (item) => {
        //       return {
        //         id: item.user_station_id,
        //         label: item.name,
        //       };
        //     }
        //   );
        //   this.props.setStations(stations);
        // }
      },
    })
  )(AdminRights)
);
