import React from "react";
import { get, isEqual, map, first, has, find } from "lodash";
import Dropdown from "components/Dropdown";
import { FormattedMessage } from "react-intl";
import { Row, Col, Label } from "reactstrap";
import NumberFormat from "react-number-format";
import { Asterisk } from "components/Elements";
import { PhoneNumberFormat, RotationFormatSuffix } from "utils/config";
import bn from "utils/bemnames";
const bem = bn.create("production-assignments");
class ProductionAssignments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldRefs: null,
    };
  }
  componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps.info) !== JSON.stringify(this.props.info)) {
      this.setState({ fieldRefs: false });
      return;
    }
    for (
      let i = 0;
      i < Object.keys(nextProps.process.data.fields).length;
      i++
    ) {
      let key = Object.keys(nextProps.process.data.fields)[i];
      let changed = false;
      if (
        !nextProps.process.data.fields[key] &&
        !this.props.process.data.fields[key]
      )
        continue;
      else if (
        !nextProps.process.data.fields[key] &&
        this.props.process.data.fields[key]
      )
        changed = true;
      else if (
        nextProps.process.data.fields[key] &&
        !this.props.process.data.fields[key]
      )
        changed = true;
      else if (
        !nextProps.process.data.fields[key].value &&
        !this.props.process.data.fields[key].value
      )
        continue;
      else if (
        !nextProps.process.data.fields[key].value &&
        this.props.process.data.fields[key].value
      )
        changed = true;
      else if (
        nextProps.process.data.fields[key].value &&
        !this.props.process.data.fields[key].value
      )
        changed = true;
      else if (
        nextProps.process.data.fields[key].value.toString() !==
        this.props.process.data.fields[key].value.toString()
      )
        changed = true;
      else if (
        !isEqual(
          get(nextProps, `process.data.fields.${key}.value`),
          get(this.props, `process.data.fields.${key}.value`)
        )
      ) {
        changed = true;
      }
      if (changed) {
        if (["role_dubber", "role_producer"].indexOf(key) > -1) {
          this.setState({ fieldRefs: false });
          break;
        }
      }
    }
  }

  getFieldRefs() {
    if (this.state.fieldRefs) return this.state.fieldRefs;
    let {
      process,
      template,
      user,
      step,
      valueChanged,
      getRoleCandidates,
    } = this.props;
    let select_dubber = step.fields.find((f) => f.field === "role_dubber");
    let select_dubber_options = getRoleCandidates(
      select_dubber,
      process,
      template,
      user
    );
    let select_dubber_value;
    if (
      select_dubber &&
      select_dubber.field &&
      process.data.fields[select_dubber.field]
    ) {
      select_dubber_value = get(
        process,
        `data.fields.${select_dubber.field}.value.users`,
        []
      ).map((item) => ({
        label: item.name,
        value: item.id,
        role: item.role,
      }));
    } else if (
      select_dubber &&
      select_dubber.default &&
      select_dubber_options &&
      select_dubber_options.length === 1
    ) {
      select_dubber_value = select_dubber_options[0];
      map(select_dubber_options, (e) => {
        if (
          get(e, "info.availability.upcoming_items") <
          get(select_dubber_value, "info.availability.upcoming_items")
        )
          select_dubber_value = e;
        return false;
      });
      if (select_dubber_value)
        valueChanged(select_dubber, process, template, user, {
          value: {
            users: [
              {
                id: select_dubber_value.value,
                name: select_dubber_value.label,
                role: select_dubber_value.role,
              },
            ],
          },
        });
    }
    // producer
    let select_producer = step.fields.find((f) => f.field === "role_producer");
    let select_producer_options = getRoleCandidates(
      select_producer,
      process,
      template,
      user
    );
    let select_producer_value;
    if (select_producer && process.data.fields[select_producer.field]) {
      select_producer_value = get(
        process,
        `data.fields.${select_producer.field}.value.users`,
        []
      ).map((item) => ({
        label: item.name,
        value: item.id,
        role: item.role,
      }));
    } else if (
      select_producer &&
      select_producer.default &&
      select_producer_options &&
      select_producer_options.length > 0
    ) {
      select_producer_value = select_producer_options[0];
      map(select_producer_options, (e) => {
        if (
          get(e, "info.availability.upcoming_items") <
          get(select_producer_value, "info.availability.upcoming_items")
        )
          select_producer_value = e;
        return false;
      });
      if (select_producer_value)
        valueChanged(select_producer, process, template, user, {
          value: {
            users: [
              {
                id: select_producer_value.value,
                name: select_producer_value.label,
                role: select_producer_value.role,
              },
            ],
          },
        });
    }
    let payload = {
      select_dubber,
      select_dubber_options,
      select_dubber_value,
      select_producer,
      select_producer_options,
      select_producer_value,
    };
    this.setState({ fieldRefs: payload });
    return payload;
  }

  onValueChanged = (field, value) => {
    const { valueChanged, template, user } = this.props;
    valueChanged(field, process, template, user, { value });
  };

  renderEdit() {
    let { validationState, process } = this.props;
    let {
      select_dubber,
      select_dubber_options,
      select_dubber_value,
      select_producer,
      select_producer_options,
      select_producer_value,
    } = this.getFieldRefs();
    const spotInfo = get(process, "data.fields.spot_info.value", []);
    const spot = find(spotInfo, (spot) => spot.key === process.key);
    const isProductionProvidedNeededTags =
      get(spot, "contract_status") === "Production Provided - Needs tags";

    const rolContinuity = get(
      process,
      "data.fields.role_continuity.value.users",
      []
    );

    return (
      <React.Fragment>
        <Row>
          {isProductionProvidedNeededTags && (
            <Col xs={6} className={bem.e("col-field")}>
              <Dropdown
                isClearable={true}
                name="select_producer"
                {...get(select_producer, "props", {})}
                placeholder={this.props.intl.formatMessage({
                  id: "process > placeholder select",
                })}
                options={select_producer_options}
                label={
                  <span>
                    {get(select_producer, "title")}
                    {get(select_producer, "mandatory") && (
                      <Asterisk>*</Asterisk>
                    )}
                  </span>
                }
                value={
                  select_producer_value ? first(select_producer_value) : ""
                }
                onChange={(selectedOption) => {
                  let selectValue = "";
                  if (selectedOption) {
                    selectValue = {
                      users: [
                        {
                          id: selectedOption.value,
                          name: selectedOption.label,
                          role: selectedOption.role,
                        },
                      ],
                    };
                  }
                  this.onValueChanged(select_producer, selectValue);
                }}
              />
              <div className="alert-danger">
                {select_producer &&
                  validationState[select_producer.field] &&
                  validationState[select_producer.field].validation_error}
              </div>
            </Col>
          )}
          <Col xs={6} className={bem.e("col-field")}>
            <Dropdown
              isClearable={true}
              name="select_dubber"
              {...get(select_dubber, "props", {})}
              placeholder={this.props.intl.formatMessage({
                id: "process > placeholder select",
              })}
              options={select_dubber_options}
              label={
                <span>
                  {get(select_dubber, "title")}
                  {select_dubber && select_dubber.mandatory && (
                    <Asterisk>*</Asterisk>
                  )}
                </span>
              }
              value={select_dubber_value ? first(select_dubber_value) : ""}
              onChange={(selectedOption) => {
                let selectValue = "";
                if (selectedOption) {
                  selectValue = {
                    users: [
                      {
                        id: selectedOption.value,
                        name: selectedOption.label,
                        role: selectedOption.role,
                      },
                    ],
                  };
                }
                this.onValueChanged(select_dubber, selectValue);
              }}
            />
            <div className="alert-danger">
              {select_dubber &&
                validationState[select_dubber.field] &&
                validationState[select_dubber.field].validation_error}
            </div>
          </Col>
          {!isProductionProvidedNeededTags && (
            <Col className={bem.e("col-view-field")}>
              <Label className={bem.e("order-view-label")}>
                <FormattedMessage id="process > order continuity" />
              </Label>
              <span className={bem.e("order-view-value")}>
                {rolContinuity && rolContinuity.length > 0
                  ? map(rolContinuity, (item) => item.name).join(",")
                  : this.props.intl.formatMessage({ id: "process > none" })}
              </span>
            </Col>
          )}
        </Row>
        {isProductionProvidedNeededTags && (
          <Row>
            <Col
              className={bem.e("col-field")}
              style={{ paddingBottom: "30px" }}
            >
              <Label className={bem.e("order-view-label")}>
                <FormattedMessage id="process > order continuity" />
              </Label>
              <span className={bem.e("order-view-value")}>
                {rolContinuity && rolContinuity.length > 0
                  ? map(rolContinuity, (item) => item.name).join(",")
                  : this.props.intl.formatMessage({ id: "process > none" })}
              </span>
            </Col>
          </Row>
        )}
      </React.Fragment>
    );
  }

  formattedValueOrEmpty(value, item) {
    if (get(item, "formatted")) {
      const formatted = get(item, "formatted");
      switch (formatted) {
        case "number":
          return (
            <NumberFormat
              prefix={get(item, "prefix", null)}
              value={value}
              displayType={"text"}
              thousandSeparator={true}
            />
          );
        case "phone":
          return (
            <NumberFormat
              value={value}
              displayType={"text"}
              format={PhoneNumberFormat}
            />
          );
        case "percentage":
          return (
            <NumberFormat
              value={value}
              displayType={"text"}
              suffix={get(item, "suffix", RotationFormatSuffix)}
            />
          );
        default:
          break;
      }
    }
    return value;
  }

  valueOrEmpty(item) {
    let { process, intl } = this.props;
    let value = null;
    if (has(process, `data.fields.${item.field}.value.name`)) {
      value = process.data.fields[item.field].value.name || "";
    } else if (get(process, `data.fields.${item.field}.value`)) {
      value = process.data.fields[item.field].value;
    } else {
      return intl.formatMessage({ id: "process > none" });
    }
    return this.formattedValueOrEmpty(value, item);
  }

  renderView() {
    let { process } = this.props;
    let { select_dubber, select_producer } = this.getFieldRefs();
    const spotInfo = get(process, "data.fields.spot_info.value", []);
    const spot = find(spotInfo, (spot) => spot.key === process.key);
    const isProductionProvidedNeededTags =
      get(spot, "contract_status") === "Production Provided - Needs tags";
    const roleProducers = get(
      process,
      "data.fields.role_producer.value.users",
      []
    );
    const roleDubbers = get(process, "data.fields.role_dubber.value.users", []);
    const rolContinuity = get(
      process,
      "data.fields.role_continuity.value.users",
      []
    );

    return (
      <React.Fragment>
        <Row style={{ margin: 0 }}>
          {isProductionProvidedNeededTags && (
            <Col className={bem.e("col-view-field")}>
              <Label className={bem.e("order-view-label")}>
                {get(select_producer, "title")}
              </Label>
              <span className={bem.e("order-view-value")}>
                {roleProducers && roleProducers.length > 0
                  ? map(roleProducers, (item) => item.name).join(",")
                  : this.props.intl.formatMessage({ id: "process > none" })}
              </span>
            </Col>
          )}

          <Col className={bem.e("col-view-field")}>
            <Label className={bem.e("order-view-label")}>
              {get(select_dubber, "title")}
            </Label>
            <span className={bem.e("order-view-value")}>
              {roleDubbers && roleDubbers.length > 0
                ? map(roleDubbers, (item) => item.name).join(",")
                : this.props.intl.formatMessage({ id: "process > none" })}
            </span>
          </Col>
          {!isProductionProvidedNeededTags && (
            <Col className={bem.e("col-view-field")}>
              <Label className={bem.e("order-view-label")}>
                <FormattedMessage id="process > order continuity" />
              </Label>
              <span className={bem.e("order-view-value")}>
                {rolContinuity && rolContinuity.length > 0
                  ? map(rolContinuity, (item) => item.name).join(",")
                  : this.props.intl.formatMessage({ id: "process > none" })}
              </span>
            </Col>
          )}
        </Row>
        {isProductionProvidedNeededTags && (
          <Row style={{ margin: 0, paddingBottom: "30px" }}>
            <Col className={bem.e("col-field")}>
              <Label className={bem.e("order-view-label")}>
                <FormattedMessage id="process > order continuity" />
              </Label>
              <span className={bem.e("order-view-value")}>
                {rolContinuity && rolContinuity.length > 0
                  ? map(rolContinuity, (item) => item.name).join(",")
                  : this.props.intl.formatMessage({ id: "process > none" })}
              </span>
            </Col>
          </Row>
        )}
      </React.Fragment>
    );
  }

  render() {
    let { isView } = this.props;
    return (
      <div className={bem.b()}>
        {isView ? this.renderView() : this.renderEdit()}
      </div>
    );
  }
}

ProductionAssignments.defaultProps = {
  mode: "edit",
};
export default ProductionAssignments;
