import { injectIntl } from "react-intl";
import View from "./view";
import { compose, withState, withHandlers } from "recompose";

const Enhanced = compose(
  withState("isOpenPreviewModal", "setIsOpenPreviewModal", false),
  withState("currentProcess", "setCurrentProcess", null),
  withHandlers({
    onTogglePreviewModal: ({
      isOpenPreviewModal,
      setIsOpenPreviewModal,
    }) => () => setIsOpenPreviewModal(!isOpenPreviewModal),
  })
)(View);
export default injectIntl(Enhanced);
