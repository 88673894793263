import React from "react";
import bn from "utils/bemnames";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
const bem = bn.create("jock-console");
export default function NoWidgets(props) {
  return (
    <div className={classNames(bem.e("no-widgets"))}>
      <span className={bem.e("no-widgets-title")}>
        <FormattedMessage id="jock console > no widgets title" />
      </span>
      <span className={bem.e("no-widgets-description")}>
        <FormattedMessage id="jock console > no widgets description" />
      </span>
    </div>
  );
}
