import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { get } from "lodash";
import ServerFormSection from "./ServerFormSection";
import ModuleEntitiesAssignment from "./ModuleEntitiesAssignment";

class DigitalConfiguration extends Component {

  onSwitchToggle = (checked) => {
    const { formProps } = this.props;
    formProps.setFieldValue(
      "enable_digital",
      checked
    );
    formProps.setFieldTouched(
      "enable_digital",
      true
    );
  };

  render() {
    const { bem, edit, formProps, intl } = this.props;
    const moduleEnabled = get(formProps, "values.enable_digital", false);
    const multiMarketEnabled = get(formProps, "values.digital_multimarket", false);
    
    return (
      <ServerFormSection
        bem={bem}
        checked={moduleEnabled}
        titleId="server > digital"
        sectionClass={bem.e("box-digital-details")}
        sectionFieldsClass={bem.e("section-view-digital-configuration-fields")}
        hasToggle={edit}
        onSwitchToggle={this.onSwitchToggle}
        showMultiMarket={false} //edit ? true : false
        onChangeMultiMarket={(checked) => {
          formProps.setFieldValue(
            "digital_multimarket",
            checked
          );
          formProps.setFieldTouched(
            "digital_multimarket",
            true
          );
        }}
        intl={intl}
        multiMarketEnabled={multiMarketEnabled}
      >
        <ModuleEntitiesAssignment {...this.props} moduleEnabled={moduleEnabled} featureModule={"digital"} />
      </ServerFormSection>
    )
  }
}

export default injectIntl(DigitalConfiguration);
