import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, Button } from "reactstrap";
import classnames from "classnames";
import bn from "utils/bemnames";
import { FormattedMessage } from "react-intl";
import UserForm from "components/UserForm";
import { MODE_ADD as USER_FORM_MODE_ADD } from "components/UserForm";
const bem = bn.create("invite-modal");

const InviteUser = ({ isOpen, onToggle, auth }) => {
  let backButton = (
    <Button
      type="button"
      outline
      color="blue"
      className={classnames(bem.e("button-cancel"), "btn-radius")}
      onClick={onToggle}
    >
      <FormattedMessage id="user > button cancel" />
    </Button>
  );
  return (
    <Modal
      isOpen={isOpen}
      className={classnames(bem.b())}
    >
      <ModalBody className={bem.e("body")}>
        <UserForm
          isView={USER_FORM_MODE_ADD}
          title={
            auth.info.app_flags &&
            auth.info.app_flags.indexOf("SERVERS_LIST") > -1 ? (
              <FormattedMessage id="user > invite god admin" />
            ) : (
              <FormattedMessage id="user > invite new user" />
            )
          }
          backButton={backButton}
          onBack={onToggle}
          isShowAssignmentsModal
        />
      </ModalBody>
    </Modal>
  );
};

InviteUser.propTypes = {
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
};

InviteUser.defaultProps = {
  onToggle: () => {},
};

export default InviteUser;
