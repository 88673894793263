import React from "react";
import { findIndex, isEmpty, first, isFunction, get } from "lodash";
import classnames from "classnames";
import Select, { components } from "react-select";
import bn from "utils/bemnames";
import Checkbox from "components/Checkbox";
import { injectIntl } from "react-intl";
import { CaretIcon, CloseSmallIcon } from "components/CustomIcons";
import { Button } from "reactstrap";
import { FormattedMessage } from "react-intl";

const bem = bn.create("prog-slots-filter-dropdown");

const CustomOption = ({ data, ...props }) => {
  const value = props.getValue();

  const optionSelectedIndex = findIndex(
    value,
    (item) => item.value === props.value
  );

  const isOptionSelected = optionSelectedIndex > -1;

  return (
    <div className={classnames(bem.e("option-container"))}>
      <components.Option {...props}>
        <div className={bem.e("option")}>
          <Checkbox
            checked={isOptionSelected}
            text={`${data.label}${
              data.slot_count ? `(${data.slot_count})` : ""
            }`}
            color={data.color ? data.color.substring(1) : "blue"}
          />
        </div>
      </components.Option>
    </div>
  );
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretIcon />
    </components.DropdownIndicator>
  );
};

const MenuList = (props) => {
  const { onMenuClose, onFilter } = props.selectProps.extraProps;

  return (
    <components.MenuList {...props}>
      {props.children}
      <div className={bem.e("filter-button-container")}>
        <Button
          color="primary"
          type="button"
          className="btn btn-blue btn-radius"
          onClick={() => {
            if (isFunction(onFilter)) {
              onFilter(props.getValue());
            }
            onMenuClose();
          }}
        >
          <FormattedMessage id="clock creator > prog clock filter button" />
        </Button>
      </div>
    </components.MenuList>
  );
};

const ValueContainer = ({ children, ...rest }) => {
  const renderValue = () => {
    let value = rest.getValue();

    if (isEmpty(value)) {
      return get(rest, "selectProps.isShowPlaceholder") ? (
        <span>{get(rest, "selectProps.placeholder")}</span>
      ) : (
        <FormattedMessage id="clock creator > prog clock filter select slot type" />
      );
    }

    if (value.length === 1) {
      value = first(value);

      return (
        <Checkbox
          checked={true}
          text={value.label}
          color={value.color ? value.color.substring(1) : "blue"}
        />
      );
    }

    return (
      <FormattedMessage id="clock creator > prog clock filter multiple selected" />
    );
  };

  return (
    <components.ValueContainer {...rest}>
      <div className={bem.e("value-container")}>{renderValue()}</div>
    </components.ValueContainer>
  );
};

const ControlComponent = (controlProps) => {
  const {
    isMenuOpen,
    onMenuClose,
    onMenuOpen,
  } = controlProps.selectProps.extraProps;
  return (
    <div onClick={isMenuOpen ? onMenuClose : onMenuOpen}>
      <components.Control {...controlProps} />
    </div>
  );
};
const ClearIndicator = (props) => {
  return (
    components.ClearIndicator && (
      <components.ClearIndicator {...props}>
        <CloseSmallIcon />
      </components.ClearIndicator>
    )
  );
};

const FilterDropdown = (props) => {
  const {
    intl,
    allOption,
    name,
    disabled,
    className,
    isMenuOpen,
    setIsMenuOpen,
    options,
    selectedFilters,
    setSelectedFilters,
    onFilter,
    isClearable,
    ...rest
  } = props;

  const onMenuOpen = () => {
    setIsMenuOpen(true);
  };

  const onMenuClose = () => {
    setIsMenuOpen(false);
  };

  let filterOptions =
    selectedFilters.length === options.length
      ? options
      : options.concat([allOption]);

  return (
    <div className={bem.b()}>
      <Select
        {...rest}
        value={selectedFilters}
        options={filterOptions}
        className={classnames(
          "react-select-container",
          className && className.toString(),
          {
            "react-select-menu-open": isMenuOpen,
          }
        )}
        classNamePrefix="react-select"
        onChange={(selected, { action }) => {
          let isAllSelected = selected.find((value) => value.value === "*");
          if (action === "clear" && isClearable && isFunction(onFilter)) {
            onFilter([]);
          }
          if (isAllSelected) {
            return setSelectedFilters(options);
          } else {
            return setSelectedFilters(selected);
          }
        }}
        components={{
          Option: CustomOption,
          DropdownIndicator: DropdownIndicator,
          MenuList: MenuList,
          ValueContainer: ValueContainer,
          Control: ControlComponent,
          ClearIndicator: ClearIndicator,
        }}
        closeMenuOnSelect={false}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        menuIsOpen={isMenuOpen}
        styles={{
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
        }}
        isMulti={true}
        hideSelectedOptions={false}
        isClearable={isClearable}
        extraProps={{
          ...props,
          selectedFilters,
          onFilter,
          onMenuOpen,
          onMenuClose,
          isMenuOpen,
        }}
      />
      {isMenuOpen ? (
        <div
          className="position-fixed top-0 left-0 w-100 h-100"
          onClick={onMenuClose}
        />
      ) : null}
    </div>
  );
};

FilterDropdown.defaultProps = {
  allOption: {
    label: "Show all",
    value: "*",
  },
  onChange: () => {},
  name: "",
  isClearable: false,
};

export default injectIntl(FilterDropdown);
