import React from "react";
import bn from "utils/bemnames";
import classnames from "classnames";
import { MdSearch } from "react-icons/lib/md";
import { CloseIcon } from "components/CustomIcons";
const bem = bn.create("search-box");

const SearchBox = (props) => {
  const {
    onSearchClicked,
    placeholder,
    searchText,
    onChangeInput,
    onClearSearch,
    onSearchKeyDown,
  } = props;

  return (
    <div className={bem.b()}>
      <input
        value={searchText}
        onChange={onChangeInput}
        onKeyDown={onSearchKeyDown}
        type="search"
        className={classnames(bem.e("input"), "form-control")}
        placeholder={placeholder}
      />
      {searchText && (
        <span className={bem.e("icon-close")} onClick={onClearSearch}>
          <CloseIcon color="#C2D4E0" />
        </span>
      )}
      <button
        type="button"
        className={bem.e("icon-search")}
        onClick={onSearchClicked}
      >
        <MdSearch size={24} />
      </button>
    </div>
  );
};

export default SearchBox;
