import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, Button, FormGroup } from "reactstrap";
import classnames from "classnames";
import bn from "utils/bemnames";
import { Formik } from "formik";
import * as Yup from "yup";
import TextInput from "components/TextInput";
import { isImage, isMp3, isPdf, checkIsDocViewer, isMp4 } from "utils/helpers";
import { FormattedMessage } from "react-intl";
import Spinner from "components/Spinner";
import { getExt } from "utils/helpers";
import Player from "components/Player";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { Document, Page } from "react-pdf";
import { FaSpinner } from "react-icons/lib/fa";

const bem = bn.create("preview-file-modal");

const renderImage = (file, background) => {
  return (
    <div
      className={classnames(bem.e("image"), {
        [bem.e("image-background")]: background,
      })}
    >
      {/* `file.preview` when we drop the file, `file.path` when we are showing already uploaded file */}
      <img src={file.preview || file.path} alt="" />
    </div>
  );
};

const renderMp3 = (file) => {
  return (
    <div>
      {/* `file.preview` when we drop the file, `file.path` when we are showing already uploaded file */}
      <Player url={file.preview || file.path} />
    </div>
  );
};

const renderDocViewer = (file) => {
  const ext = getExt(file.name);
  return (
    <DocViewer
      pluginRenderers={DocViewerRenderers}
      documents={[{ uri: file.path, fileType: ext }]}
      config={{
        header: {
          disableHeader: true,
          disableFileName: true,
          retainURLParams: false,
        },
      }}
      style={{ minHeight: 600 }}
    />
  );
};
const renderPdf = (file, { onCancel, intl }) => {
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [isLoaded, setIsLoaded] = React.useState(false);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  if (!file) return null;
  const pdfWidth = window.innerWidth * 0.7;
  return (
    <div className={bem.e("pdf-container")}>
      <Document
        file={file.path}
        loading={
          <span className="loading">
            <FaSpinner size={20} className={"cr-icon-spin"} />
          </span>
        }
        onPassword={(callback, reason) => {
          function callbackProxy(password) {
            // Cancel button handler
            if (password === null) {
              setIsLoaded(false);
              onCancel();
            }

            callback(password);
          }
          switch (reason) {
            case 1: {
              const password = prompt(
                intl.formatMessage({ id: "preview file > prompt password" })
              );
              callbackProxy(password);
              break;
            }
            case 2: {
              const password = prompt(
                intl.formatMessage({
                  id: "preview file > prompt invalid password",
                })
              );
              callbackProxy(password);
              break;
            }
            default:
          }
        }}
        onSourceSuccess={() => {
          setIsLoaded(true);
        }}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page
          pageNumber={pageNumber}
          width={pdfWidth}
          renderAnnotationLayer={false}
        />
      </Document>
      {isLoaded && numPages && (
        <div className="page-controls">
          <button
            type="button"
            disabled={pageNumber === 1}
            onClick={() => {
              if (pageNumber > 1) setPageNumber(pageNumber - 1);
            }}
          >
            ‹
          </button>
          <span>
            {pageNumber} of {numPages}
          </span>
          <button
            type="button"
            disabled={pageNumber === numPages}
            onClick={() => {
              if (pageNumber < numPages) setPageNumber(pageNumber + 1);
            }}
          >
            ›
          </button>
        </div>
      )}
    </div>
  );
};
const PreviewFileModal = (props) => {
  if (!props.file) return null;
  let file = props.file;
  if (file.push) file = file[0];
  if (!file.path) file.path = file.name;

  let fileType = "default";
  if (isImage(props.file.type)) {
    fileType = "image";
  } else if (
    isMp3(file.type, file.path) &&
    (!file.path ||
      (file.path.substr(-4) !== ".ini" && file.path.substr(-4) !== ".m4a"))
  ) {
    fileType = "mp3";
  } else if (isPdf(props.file.type)) {
    fileType = "pdf";
  } else if (checkIsDocViewer(props.file)) {
    fileType = "doc";
  } else if (isMp4(props.file.type)) {
    fileType = "mp4";
  }
  const ext = getExt(file.name);
  return (
    <Modal
      isOpen={props.isOpen}
      className={classnames(bem.b(), {
        [bem.m("large")]: fileType === "doc" || props.isLarge,
        [bem.m("pdf")]: fileType === "pdf",
      })}
      centered
    >
      <ModalBody className={bem.e("body")}>
        <div>
          {fileType.toLowerCase() === "image" &&
            renderImage(props.file, props.background)}
          {["mp3", "mp4"].includes(fileType.toLowerCase()) &&
            renderMp3(props.file)}
          {fileType.toLowerCase() === "pdf" &&
            props.isOpen &&
            renderPdf(props.file, {
              onCancel: props.onCancel,
              intl: props.intl,
            })}
          {fileType.toLowerCase() === "doc" && renderDocViewer(props.file)}
          {fileType.toLowerCase() === "default" && (
            <div className={bem.e("cannot-preview")}>
              <FormattedMessage id="preview file > can not preview this file" />
            </div>
          )}
        </div>
        {!props.isEdit ? (
          <div>
            <div className={bem.e("file-name")}>{props.file.name}</div>
            <div className={bem.e("view-buttons")}>
              <Button
                color="blue"
                className={classnames(
                  "btn-block btn-radius",
                  bem.e("close-button")
                )}
                onClick={props.onCancel}
              >
                {props.cancelTitle}
              </Button>
            </div>
          </div>
        ) : (
          <Formik
            enableReinitialize
            initialValues={{
              file_name: file.name.replace(`.${ext}`, ""),
            }}
            validationSchema={Yup.object().shape({
              file_name: Yup.string().required(
                props.intl.formatMessage({
                  id: "validate > file name is required",
                })
              ),
            })}
            onSubmit={props.onRename}
          >
            {(formProps) => {
              const errors = formProps.errors;
              const touched = formProps.touched;
              const values = formProps.values;
              return (
                <div>
                  <FormGroup>
                    <TextInput
                      value={values.file_name}
                      error={touched.file_name && errors.file_name}
                      type="text"
                      name="file_name"
                      onChange={formProps.handleChange}
                      onBlur={formProps.handleBlur}
                    />
                  </FormGroup>
                  <div className={bem.e("buttons")}>
                    <div className={bem.e("center-buttons")}>
                      <Button
                        color="blue"
                        className={classnames(
                          "btn-block btn-radius",
                          bem.e("submit-button")
                        )}
                        onClick={formProps.handleSubmit}
                        disabled={
                          file.name.replace(`.${ext}`, "") === values.file_name
                        }
                      >
                        {props.submitTitle}
                      </Button>
                      <Button
                        className={classnames(
                          "btn-outline-blue btn-block btn-radius",
                          bem.e("cancel-button")
                        )}
                        onClick={props.onCancel}
                      >
                        {props.cancelTitle}
                      </Button>
                    </div>
                  </div>
                </div>
              );
            }}
          </Formik>
        )}
      </ModalBody>
      <Spinner isLoading={props.isLoading} />
    </Modal>
  );
};

PreviewFileModal.prototype = {
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
  onCancel: PropTypes.func,
  cancelTitle: PropTypes.string,
  submitTitle: PropTypes.string,
  className: PropTypes.string,
  file: PropTypes.object.isRequired,
  isEdit: PropTypes.bool,
};

PreviewFileModal.defaultProps = {
  isOpen: false,
  isEdit: false,
  onToggle: () => {},
  onCancel: () => {},
  submitTitle: <FormattedMessage id="confirm modal > button save" />,
  cancelTitle: <FormattedMessage id="confirm modal > button close" />,
};

export default PreviewFileModal;
