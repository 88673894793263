import { filter, isArray, isObject } from "lodash";
export const getTaggedMessages = (messages, user) => {
  if (isArray(messages)) {
    // If messages is an array
    return filter(messages, (message) => {
      return (message._source.tagged || []).indexOf(user.id) > -1;
    }).length;
  } else if (isObject(messages)) {
    // If messages is an object
    return filter(Object.values(messages), (message) => {
      return (message._source.tagged || []).indexOf(user.id) > -1;
    }).length;
  } else {
    // Handle other cases or throw an error based on your requirements
    return 0;
  }
};
