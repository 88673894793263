import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, Button } from "reactstrap";
import classnames from "classnames";
import bn from "utils/bemnames";
import { FormattedMessage } from "react-intl";
import BulkUploadForm from "components/BulkUploadForm";
const bem = bn.create("bulk-upload-user-modal");

const BulkUploadUser = ({ isOpen, onToggle }) => {
  let backButton = (
    <Button
      type="button"
      color="blue"
      outline
      className={classnames(bem.e("button-cancel"), "btn-radius")}
      onClick={onToggle}
    >
      <FormattedMessage id="user > button cancel" />
    </Button>
  );
  return (
    <Modal isOpen={isOpen} className={classnames(bem.b())}>
      <ModalBody className={bem.e("body")}>
        <BulkUploadForm
          title={<FormattedMessage id="user > invite new user title" />}
          backButton={backButton}
          onBack={onToggle}
        />
      </ModalBody>
    </Modal>
  );
};

BulkUploadUser.propTypes = {
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
};

BulkUploadUser.defaultProps = {
  onToggle: () => {},
};

export default BulkUploadUser;
