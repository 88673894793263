import ClocksList from "./view";
import { connect } from "react-redux";
import {
  compose,
  withState,
  withHandlers,
  lifecycle,
  withPropsOnChange,
} from "recompose";
import { injectIntl } from "react-intl";
import { get } from "lodash";
import ToastManager from "components/ToastManager";
import {
  getClocks,
  getMoreClocks,
  updateClockStatus,
  deleteClock,
  duplicateClock,
} from "store/actions/clocks";

export default injectIntl(
  compose(
    connect(
      (state) => {
        return {
          user: state.auth.user,
          auth: state.auth,
          clocks: get(state, "clocks.clocks"),
          isLazyLoading: get(state, "clocks.lazyLoad", false),
          dateFormatByServer: get(state, "servers.currentServer.data.date_format", "MM/DD/YYYY")
        };
      },
      {
        getClocks,
        getMoreClocks,
        updateClockStatus,
        deleteClock,
        duplicateClock,
      }
    ),
    withState(
      "isOpenCopySelectStationsModal",
      "setIsOpenCopySelectStationsModal",
      false
    ),
    withState("copyDaypartValue", "setCopyDaypartValue", {}),
    withState("searchText", "setSearchText", ""),
    withState("filtered", "setFiltered", []),
    withState("searchTags", "setSearchTags", []),
    withState("sorted", "setSorted", [{ id: "created_at", desc: true }]),
    withState("deleteClockId", "setDeleteClockId", null),
    withState(
      "isOpenDeActiveConfirmationModal",
      "setIsOpenDeActiveConfirmationModal",
      false
    ),
    withState("activeConflictClocks", "setActiveConflictClocks", []),
    withState("currentClockId", "setCurrentClockId", null),
    withHandlers({
      getClocksList: ({ getClocks, sorted, filtered }) => async () => {
        getClocks({
          pageSize: 10,
          page: 0,
          sorted,
          filtered,
        });
      },
      getMoreClocks: ({ getMoreClocks, sorted, filtered }) => async (page) => {
        getMoreClocks({
          pageSize: 10,
          page,
          sorted,
          filtered,
        });
      },
    }),
    withHandlers({
      onSearchClicked: ({ searchText, setFiltered }) => () => {
        setFiltered([
          {
            id: "q",
            value: searchText,
          },
        ]);
      },
      openDeleteModal: ({ setDeleteClockId }) => (id) => {
        setDeleteClockId(id);
      },
      closeDeleteModal: ({ setDeleteClockId }) => () => {
        setDeleteClockId(null);
      },
    }),
    withHandlers({
      onLoadMore: ({ getMoreClocks, clocks }) => () => {
        getMoreClocks(get(clocks, "data.current_page", 1));
      },
      onCopyDaypart: ({ duplicateClock, setCopyDaypartValue, intl }) => (
        values
      ) => {
        duplicateClock(
          {
            id: values.id,
            station_keys: values.stations,
          },
          () => {
            setCopyDaypartValue({});
            ToastManager.show({
              title: intl.formatMessage({
                id: "toast > title copied",
              }),
              level: "success",
            });
          }
        );
      },
      updateStatus: ({
        updateClockStatus,
        setActiveConflictClocks,
        setIsOpenDeActiveConfirmationModal,
        setCurrentClockId,
      }) => (id, status, override = false) => {
        updateClockStatus(id, { active: status, override }, (responseData) => {
          if (responseData.conflict) {
            setCurrentClockId(id);
            setIsOpenDeActiveConfirmationModal(true);
            setActiveConflictClocks(responseData.active_clocks);
          } else {
            setCurrentClockId(null);
            setIsOpenDeActiveConfirmationModal(false);
            setActiveConflictClocks([]);
          }
        });
      },
      onDelete: ({ closeDeleteModal, deleteClockId, deleteClock }) => () => {
        deleteClock(deleteClockId);
        closeDeleteModal();
      },
    }),
    withPropsOnChange(
      ["sorted", "filtered"],
      ({ getClocksList, sorted, filtered, pageSize }) => {
        if (sorted.length > 0 || filtered.length > 0 || pageSize) {
          getClocksList();
        }
      }
    ),
    lifecycle({
      componentDidMount() {
        this.props.getClocksList();
      },
      componentDidUpdate() {
        if (get(this.props, "clocks.is_dirty")) {
          this.props.getClocksList();
        }
      },
    })
  )(ClocksList)
);
