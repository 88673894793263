import AuthForm, { STATE_LOGIN, STATE_SIGNUP } from "./view";
import { compose, withHandlers, withState } from "recompose";

export { STATE_LOGIN, STATE_SIGNUP };

export default compose(
  withState("showPassword", "setShowPassword", {
    password: false,
    c_password: false,
  }),
  withHandlers({
    changeAuthState: ({ onChangeAuthState }) => (authState) => (event) => {
      event.preventDefault();
      onChangeAuthState(authState);
    },
  })
)(AuthForm);
