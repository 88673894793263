import * as React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { get, lowerCase } from "lodash";
import TextInput from "components/TextInput";
import TextInputGroup from "components/TextInputGroup";
import TextInputFormatted from "components/TextInputFormatted";
import Dropdown from "components/Dropdown";
import UploadFiles from "components/UploadFiles";
import ToggleSwitch from "components/ToggleSwitch";
import DaysOfWeek from "components/DaysOfWeek";
import { FormattedMessage } from "react-intl";
import AutocompleteInput from "components/AutocompleteInput";
import { FaCheck } from "react-icons/lib/fa";
import {
  Row,
  Col,
  Form,
  Button,
  Label,
  Modal,
  ModalBody,
  ButtonGroup
} from "reactstrap";
import NumberFormat from "react-number-format";
import SelectStations from "../SelectStations";
import history from "components/History";
import ToastManager from "components/ToastManager";
import Checkbox from "components/Checkbox";
import {
  autoSuggestClients,
  autoSuggestContracts
} from "store/actions/process";

import {
  ShadowBox,
  Asterisk,
  SectionTitle,
  SectionFields,
  DivView
} from "components/Elements";
import OrderDetailsDuplicate from "components/OrderDetailsDuplicate";
import OrderDetailsIncompleteContract from "components/OrderDetailsIncompleteContract";
import PrivilegedComponent from "components/PrivilegedComponent";
import { PRIVILEGES } from "utils/constants";
// import TextareaEvaluator from "components/TextareaEvaluator";
import { PhoneNumberFormat, RotationFormatSuffix } from "utils/config";
// import UploadFile from "components/UploadFile/index";
import ConfirmationModal from "../ConfirmationModal/view";

class PromotionDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      contracts: [],
      isOpenDialog: false,
      isOpenDialogDelete: false,
      isOpenDialogSubmitted: false,
      isOpenContestDescription: true,
      isOpenJockInstructions: false,
      isOpenContractDetails: false,
      isOpenContestSchedule: false,
      isOpenLinerSchedule: false,
      isOpenDocuments: false,
      fieldRefs: false
    };
  }

  static propTypes = {
    mode: PropTypes.string
  };

  static defaultProps = {
    mode: "edit"
  };

  // componentDidMount() {
  //   this._isMounted = true;
  //   autoSuggestClients({
  //     q: "",
  //     cb: items => {
  //       if (this._isMounted) {
  //         this.setState({ customers: items, fieldRefs: false });
  //       }
  //     }
  //   });
  // }
  // componentWillUnmount() {
  //   this._isMounted = false;
  // }

  onCheckBoxChange = (item, process, template, user, checked) => {
    this.props.checkboxChanged(item, process, template, user, {
      target: { checked }
    });
  };

  switchToSpot(key) {
    const { intl } = this.props;
    if (key === "new") {
      ToastManager.show({
        title: intl.formatMessage("toast > title not saved"),
        message: intl.formatMessage(
          "toast > message please save the order before switching to another spot"
        ),
        level: "error"
      });
      return;
    }
    // let process = this.props.process;
    let template = this.props.template;
    // if(this.props.process.data.fields.key===key) return
    history.push("/processes/edit/" + template.key + "/" + key);
  }

  getFieldRefs() {
    if (this.state.fieldRefs) return this.state.fieldRefs;
    let {
      process,
      template,
      user,
      step
      // valueChanged,
      // validationState,
      // fileUploaded,
      // fileDeleted,
      // filesUploaded,
      // filesDeleted,
      // checkboxGroupChanged,
      // checkboxChanged,
      // getRoleCandidates
      // spotsChanged,
      // multipleStationSelectionChanged
    } = this.props;

    step = template.steps.promotion_details;
    let client_name = step.fields.find(f => f.field === "client_name");
    let client_id = step.fields.find(f => f.field === "client_id");
    let contract_number = step.fields.find(f => f.field === "contract_number");
    let contract_id = step.fields.find(f => f.field === "contract_id");
    // let contract_start_date = step.fields.find(
    //   f => f.field === "contract_start_date"
    // );
    // let contract_end_date = step.fields.find(
    //   f => f.field === "contract_end_date"
    // );
    let contract_value = step.fields.find(f => f.field === "contract_value");

    // let contract_client_contact = step.fields.find(
    //   f => f.field === "contract_client_contact"
    // );
    // let contract_client_email = step.fields.find(
    //   f => f.field === "contract_client_email"
    // );
    // let contract_client_nickname = step.fields.find(
    //   f => f.field === "contract_client_nickname"
    // );
    // let contract_client_phone = step.fields.find(
    //   f => f.field === "contract_client_phone"
    // );

    let contract_stations = step.fields.find(
      f => f.field === "contract_stations"
    );
    let contract_category = step.fields.find(
      f => f.field === "contract_category"
    );
    let order_title = step.fields.find(f => f.field === "order_title");
    let order_start_date = step.fields.find(
      f => f.field === "order_start_date"
    );
    let order_end_date = step.fields.find(f => f.field === "order_end_date");
    let order_live_read = step.fields.find(f => f.field === "order_live_read");
    let order_day_parts = step.fields.find(f => f.field === "order_day_parts");
    let copy_points = step.fields.find(f => f.field === "copy_points");
    let script = step.fields.find(f => f.field === "script");
    let contest_type = step.fields.find(f => f.field === "contest_type");
    let contest_theme = step.fields.find(f => f.field === "contest_theme");
    let contest_winner_selection = step.fields.find(
      f => f.field === "contest_winner_selection"
    );
    let contest_number_of_plays = step.fields.find(
      f => f.field === "contest_number_of_plays"
    );
    let contest_internal_description = step.fields.find(
      f => f.field === "contest_internal_description"
    );
    let contest_approved_program_director = step.fields.find(
      f => f.field === "contest_approved_program_director"
    );
    let contest_before_winner_selection = step.fields.find(
      f => f.field === "contest_before_winner_selection"
    );
    let contest_after_winner_selection = step.fields.find(
      f => f.field === "contest_after_winner_selection"
    );
    let contest_notes = step.fields.find(f => f.field === "contest_notes");
    let contest_additional_questions = step.fields.find(
      f => f.field === "contest_additional_questions"
    );

    let contest_additional_questions_textfields = step.fields.find(
      f => f.field === "contest_additional_questions_textfields"
    );
    let contest_trivia_questions = step.fields.find(
      f => f.field === "contest_trivia_questions"
    );
    let contest_trivia_questions_textfields = step.fields.find(
      f => f.field === "contest_trivia_questions_textfields"
    );
    let contest_trivia_question_order = step.fields.find(
      f => f.field === "contest_trivia_question_order"
    );
    let contest_trivia_unanswered_questions = step.fields.find(
      f => f.field === "contest_trivia_unanswered_questions"
    );
    let contest_trivia_rollover_unclaimed_prizes = step.fields.find(
      f => f.field === "contest_trivia_rollover_unclaimed_prizes"
    );
    let contest_trivia_number_of_chances = step.fields.find(
      f => f.field === "contest_trivia_number_of_chances"
    );
    let contest_days_of_week = step.fields.find(
      f => f.field === "contest_days_of_week"
    );
    // let liners = step.fields.find(f => f.field === "liners");
    let active_liner = step.fields.find(f => f.field === "active_liner");
    // let liner_mentions_per_day = step.fields.find(
    //   f => f.field === "liner_mentions_per_day"
    // );
    let plays_per_day = step.fields.find(f => f.field === "plays_per_day");
    // let liner_start_date = step.fields.find(
    //   f => f.field === "liner_start_date"
    // );
    // let liner_end_date = step.fields.find(f => f.field === "liner_end_date");
    // let liner_days_of_week = step.fields.find(
    //   f => f.field === "liner_days_of_week"
    // );
    // let liner_length = step.fields.find(f => f.field === "liner_length");
    // let liner_day_part = step.fields.find(f => f.field === "liner_day_part");
    // let liner_recorded_promos = step.fields.find(
    //   f => f.field === "liner_recorded_promos"
    // );

    let order_files = step.fields.find(f => f.field === "order_files");
    let select_stations = step.fields.find(f => f.field === "order_stations");

    let contract_stations_checked = [];
    if (get(this.props, "process.data.fields.contract_stations.value")) {
      contract_stations_checked = this.props.process.data.fields.contract_stations.value.map(
        s => ({ station_key: s })
      );
    }

    let stations_value = user.stations;
    if (get(this.props, "process.data.fields.contract_stations.value")) {
      stations_value = this.props.process.data.fields.contract_stations.value.map(
        s => ({ station_key: s })
      );
    }

    // must have order stations
    if (!this.props.process.data.fields.contract_stations) {
      // let init_stations = [this.props.info.stations[0].station_key]
      let init_stations = [];
      this.props.valueChanged(
        { field: "contract_stations" },
        this.props.process,
        this.props.template,
        this.props.user,
        { value: init_stations }
      );
    }

    // must have order stations
    if (!this.props.process.data.fields.order_stations) {
      // let init_stations = this.props.process.data.fields.contract_stations ? [...this.props.process.data.fields.contract_stations.value] : [this.props.info.stations[0].station_key]
      let init_stations = [];
      this.props.valueChanged(
        { field: "order_stations" },
        this.props.process,
        this.props.template,
        this.props.user,
        { value: init_stations }
      );
    }

    let select_stations_checked = [];
    if (get(this.props, "process.data.fields.order_stations.value")) {
      select_stations_checked = this.props.process.data.fields.order_stations.value.map(
        s => ({ station_key: s })
      );
    }

    let spots = [];
    if (
      process.data.fields.key &&
      this.props.process.data.fields.spots &&
      this.props.process.data.fields.spots.value.length > 1
    ) {
      this.props.process.data.fields.spots.value.map((spot, ind) =>
        spots.push({
          key: spot,
          component: <span key={ind}> Spot {ind + 1} </span>
        })
      );
    }

    let contractSaved = process.data.fields.contract_id !== undefined;
    let payload = {
      client_name,
      client_id,
      contract_number,
      contract_id,
      // contract_start_date,
      // contract_end_date,
      contract_value,
      // contract_client_contact,
      // contract_client_email,
      // contract_client_nickname,
      // contract_client_phone,
      contract_stations,
      contract_category,
      order_title,
      order_start_date,
      order_end_date,
      order_live_read,
      order_day_parts,
      copy_points,
      order_files,
      select_stations,
      spots,
      contract_stations_checked,
      stations_value,
      select_stations_checked,
      contractSaved,
      script,
      contest_type,
      contest_theme,
      contest_winner_selection,
      contest_number_of_plays,
      contest_internal_description,
      contest_approved_program_director,
      contest_before_winner_selection,
      contest_after_winner_selection,
      contest_notes,
      contest_additional_questions,
      contest_additional_questions_textfields,
      contest_days_of_week,
      // liners,
      active_liner,
      // liner_mentions_per_day,
      plays_per_day,
      // liner_start_date,
      // liner_end_date,
      // liner_recorded_promos,
      // liner_length,
      // liner_day_part,
      // liner_days_of_week,
      contest_trivia_questions,
      contest_trivia_questions_textfields,
      contest_trivia_question_order,
      contest_trivia_unanswered_questions,
      contest_trivia_rollover_unclaimed_prizes,
      contest_trivia_number_of_chances
    };
    this.setState({ fieldRefs: payload });
    //debugger
    console.log("recomputed");
    return payload;
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.stationsWithoutSpots &&
      nextProps.stationsWithoutSpots !== this.props.stationsWithoutSpots
    ) {
      this.setState({
        isOpenDialog: true,
        stationsWithoutSpots: nextProps.stationsWithoutSpots
      });
    }
    if (JSON.stringify(nextProps.info) !== JSON.stringify(this.props.info)) {
      this.setState({ fieldRefs: false });
      return;
    }
    for (
      let i = 0;
      i < Object.keys(nextProps.process.data.fields).length;
      i++
    ) {
      let key = Object.keys(nextProps.process.data.fields)[i];
      let changed = false;
      if (
        !nextProps.process.data.fields[key] &&
        !this.props.process.data.fields[key]
      )
        continue;
      else if (
        !nextProps.process.data.fields[key] &&
        this.props.process.data.fields[key]
      )
        changed = true;
      else if (
        nextProps.process.data.fields[key] &&
        !this.props.process.data.fields[key]
      )
        changed = true;
      else if (
        !nextProps.process.data.fields[key].value &&
        !this.props.process.data.fields[key].value
      )
        continue;
      else if (
        !nextProps.process.data.fields[key].value &&
        this.props.process.data.fields[key].value
      )
        changed = true;
      else if (
        nextProps.process.data.fields[key].value &&
        !this.props.process.data.fields[key].value
      )
        changed = true;
      else if (
        nextProps.process.data.fields[key].value.toString() !==
        this.props.process.data.fields[key].value.toString()
      )
        changed = true;
      if (changed) {
        console.log("changed: " + key);
        if (
          [
            "client_id",
            "contract_stations",
            "order_stations",
            "order_sold",
            "spots",
            "key"
          ].indexOf(key) > -1
        ) {
          this.setState({ fieldRefs: false });
          // refresh prizes list when changing contest stations.
          if (key === "order_stations") {
            this.props.forgetPrizes({
              keys: [
                "filter_grand_prizes_list",
                "filter_qualifying_prizes_list"
              ]
            });
          }
          break;
        }
      }
    }
    // this.setState({fieldRefs:false})

    // open section when having any validate
    if (this.props.validationState !== nextProps.validationState) {
      let sections = [
        "contest_description",
        "jock_instructions",
        "contract_details",
        "contest_schedule",
        "liner_schedule",
        "documents"
      ];
      sections.forEach((key, index) => {
        let fields = [];
        let errors = [];
        fields = this.renderFieldsSection(key);
        errors = fields.filter(item =>
          get(nextProps.validationState, `${item}.validation_error`)
        );
        if (errors.length > 0) {
          if (
            key === "contest_description" &&
            !this.state.isOpenContestDescription
          ) {
            this.setState({
              isOpenContestDescription: true
            });
          } else if (
            key === "jock_instructions" &&
            !this.state.isOpenJockInstructions
          ) {
            this.setState({
              isOpenJockInstructions: true
            });
          } else if (
            key === "contract_details" &&
            !this.state.isOpenContractDetails
          ) {
            this.setState({
              isOpenContractDetails: true
            });
          } else if (
            key === "contest_schedule" &&
            !this.state.isOpenContestSchedule
          ) {
            this.setState({
              isOpenContestSchedule: true
            });
          } else if (
            key === "liner_schedule" &&
            !this.state.isOpenLinerSchedule
          ) {
            this.setState({
              isOpenLinerSchedule: true
            });
          } else if (key === "documents" && !this.state.isOpenDocuments) {
            this.setState({
              isOpenDocuments: true
            });
          }
        }
      });
    }
  }

  renderFieldsSection = key => {
    let fields = [];
    switch (key) {
      case "contest_description":
        fields = [
          "order_title",
          "order_stations",
          "contest_type",
          "contest_theme",
          "contest_winner_selection",
          "contest_number_of_plays",
          "contest_internal_description",
          "contest_approved_program_director"
        ];
        break;
      case "jock_instructions":
        fields = [
          "contest_before_winner_selection",
          "contest_after_winner_selection",
          "contest_notes",
          "contest_trivia_questions",
          "contest_trivia_questions_textfields",
          "contest_additional_questions",
          "contest_additional_questions_textfields"
        ];
        break;
      case "contract_details":
        fields = [
          "client_name",
          "contract_number",
          "contract_value",
          // "contract_start_date",
          // "contract_end_date",
          // "contract_client_contact",
          // "contract_client_email",
          // "contract_client_nickname",
          // "contract_client_phone",
          "contract_stations"
        ];
        break;
      case "contest_schedule":
        fields = [
          "plays_per_day",
          "order_start_date",
          "order_end_date",
          "contest_days_of_week",
          "order_day_parts"
        ];
        break;
      case "liner_schedule":
        fields = [
          // "liner_mentions_per_day",
          // "liner_start_date",
          //  "liner_end_date",
          // "liner_days_of_week",
          // "liner_length",
          // "liner_day_part",
          "script"
          // "liner_recorded_promos"
        ];
        break;
      case "documents":
        fields = ["order_files"];
        break;
      default:
        break;
    }
    return fields;
  };

  isCheckFieldRequired = (key, fields) => {
    const { process } = this.props;

    let required = [];
    if (key === "liner_schedule") {
      // const { liners, active_liner } = this.getFieldRefs();
      // const linersArray = process.data.fields[liners.field]
      //   ? process.data.fields[liners.field].value
      //   : [{}];
      // const activelinerIndex = process.data.fields[active_liner.field]
      //   ? process.data.fields[active_liner.field].value
      //   : 0;
      // const activeliner = linersArray[activelinerIndex];
      // required = fields.filter((key, index) => {
      //   if (key === "liner_days_of_week" || key === "order_stations") {
      //     return get(activeliner, `${key}.length`) === 0;
      //   } else {
      //     return !get(activeliner, `${key}`);
      //   }
      // });
    } else {
      required = fields.filter((key, index) => {
        if (key === "contract_stations" || key === "order_stations") {
          return get(process, `data.fields.${key}.value.length`) === 0;
        } else if (key === "order_files") {
          return (
            !get(process, `data.fields.${key}.value`) ||
            get(process, `data.fields.${key}.value.length`) === 0
          );
        } else {
          return !get(process, `data.fields.${key}.value`);
        }
      });
    }
    return required;
  };

  isCheckAnyFieldHaveValue = (key, fields) => {
    const { process } = this.props;
    let status = false;
    if (key === "liner_schedule") {
      // const { liners, active_liner } = this.getFieldRefs();
      // const linersArray = process.data.fields[liners.field]
      //   ? process.data.fields[liners.field].value
      //   : [{}];
      // const activelinerIndex = process.data.fields[active_liner.field]
      //   ? process.data.fields[active_liner.field].value
      //   : 0;
      // const activeliner = linersArray[activelinerIndex];
      // fields.forEach((key, index) => {
      //   let value = null;
      //   if (key === "liner_days_of_week" || key === "order_stations") {
      //     value = get(activeliner, `${key}.length`);
      //   } else {
      //     value = get(activeliner, `${key}`);
      //   }
      //   if (value) {
      //     status = true;
      //   }
      // });
    } else {
      fields.forEach(key => {
        let value = null;
        if (key === "contract_stations" || key === "order_stations") {
          value = get(process, `data.fields.${key}.value.length`);
        } else if (key === "order_files") {
          value = get(process, `data.fields.${key}.value.length`);
        } else {
          value = get(process, `data.fields.${key}.value`);
        }
        if (value) {
          status = true;
        }
      });
    }
    return status;
  };

  isCompletedSection = key => {
    const { step } = this.props;
    let fields = this.renderFieldsSection(key);
    let fieldsRequired = fields.filter((key, index) => {
      const fieldRef = step.fields.find(f => f.field === key);
      return get(fieldRef, "mandatory");
    });
    // section don't have any fields required
    if (fieldsRequired.length === 0) {
      return this.isCheckAnyFieldHaveValue(key, fields);
    } else {
      // check section with fields require
      const required = this.isCheckFieldRequired(key, fieldsRequired);
      return required.length === 0;
    }
  };

  renderForm() {
    let {
      process,
      template,
      user,
      step,
      valueChanged,
      validationState,
      // fileUploaded,
      // fileDeleted,
      filesUploaded,
      filesDeleted,
      // checkboxGroupChanged,
      // checkboxChanged,
      // getRoleCandidates,
      // spotsChanged,
      multipleStationSelectionChanged
    } = this.props;

    let {
      client_name,
      client_id,
      contract_number,
      contract_id,
      // contract_start_date,
      // contract_end_date,
      contract_value,
      // contract_client_contact,
      // contract_client_email,
      // contract_client_nickname,
      // contract_client_phone,
      // contract_status,
      contract_stations,
      contract_category,
      order_title,
      plays_per_day,
      order_start_date,
      order_end_date,
      // order_length,
      order_day_parts,
      // copy_points,
      order_files,
      select_stations,
      // spots,
      // contract_stations_checked,
      // stations_value,
      // select_stations_checked,
      contractSaved,
      script,
      contest_type,
      contest_theme,
      contest_winner_selection,
      contest_number_of_plays,
      contest_internal_description,
      contest_approved_program_director,
      contest_before_winner_selection,
      contest_after_winner_selection,
      contest_notes,
      contest_additional_questions,
      contest_additional_questions_textfields,
      contest_days_of_week,
      // liners,
      // active_liner,
      // liner_mentions_per_day,
      // liner_recorded_promos,
      // liner_start_date,
      // liner_end_date,
      // liner_length,
      // liner_day_part,
      // liner_days_of_week,
      contest_trivia_questions_textfields,
      contest_trivia_questions,
      contest_trivia_question_order,
      contest_trivia_unanswered_questions,
      contest_trivia_rollover_unclaimed_prizes,
      contest_trivia_number_of_chances
    } = this.getFieldRefs();

    const additionTextFieldsValue = process.data.fields[
      contest_additional_questions_textfields.field
    ]
      ? process.data.fields[contest_additional_questions_textfields.field].value
      : [""];
    const triviaQuestionValue = process.data.fields[
      contest_trivia_questions_textfields.field
    ]
      ? process.data.fields[contest_trivia_questions_textfields.field].value
      : [{ q: "", a: "" }];
    // const linersArray = process.data.fields[liners.field]
    //   ? process.data.fields[liners.field].value
    //   : [{}];
    // const activelinerIndex = process.data.fields[active_liner.field]
    //   ? process.data.fields[active_liner.field].value
    //   : 0;
    // const activeliner = linersArray[activelinerIndex];

    let bindedValueChanged = valueChanged.bind(this);

    // const contestName =
    //   process.data.fields[order_title.field] &&
    //   process.data.fields[order_title.field].value;
    // const selectedStationsStr =
    //   process.data.fields[contract_stations.field] &&
    //   process.data.fields[contract_stations.field].value.join(", ");
    // const selectedDayPartStr =
    //   process.data.fields[order_day_parts.field] &&
    //   process.data.fields[order_day_parts.field].value.join(", ");

    return (
      <div className="text-left">
        <Form>
          <ShadowBox className="promotion-shadow-box">
            <SectionTitle
              className="promotion-section-title"
              onClick={() =>
                this.setState({
                  isOpenContestDescription: !this.state.isOpenContestDescription
                })
              }
            >
              <FormattedMessage id="process > title promotion contest details" />
              {this.isCompletedSection("contest_description") && (
                <FaCheck color="#6F96D1" size={30} />
              )}
            </SectionTitle>
            <SectionFields
              className={!this.state.isOpenContestDescription && "hidden"}
            >
              <Row>
                <Col>
                  <TextInput
                    label={
                      <span>
                        {order_title.title}
                        {order_title.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    value={
                      process.data.fields[order_title.field]
                        ? process.data.fields[order_title.field].value
                        : ""
                    }
                    onChange={valueChanged.bind(
                      this,
                      order_title,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[order_title.field] &&
                      validationState[order_title.field].validation_error}
                  </div>
                </Col>
              </Row>
              {/* <Row>
                <Col xs="12" lg="12">
                  <SelectStations
                    contract={true}
                    item={select_stations}
                    disabled={contractSaved}
                    {...select_stations.props}
                    validationState={validationState}
                    checked={process.data.fields[select_stations.field]}
                    onChange={value => {
                      multipleStationSelectionChanged(
                        select_stations,
                        process,
                        template,
                        user,
                        value
                      );
                    }}
                  />
                </Col>
              </Row> */}
              <Row>
                <Col>
                  <Dropdown
                    isClearable={true}
                    name="contest_type"
                    {...contest_type.props}
                    label={
                      <span>
                        {contest_type.title}
                        {contest_type.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    value={
                      process.data.fields[contest_type.field]
                        ? {
                            label: (
                              <FormattedMessage
                                id={`process > option label ${lowerCase(
                                  process.data.fields[contest_type.field].value
                                )}`}
                              />
                            ),
                            value: process.data.fields[contest_type.field].value
                          }
                        : ""
                    }
                    onChange={valueChanged.bind(
                      this,
                      contest_type,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[contest_type.field] &&
                      validationState[contest_type.field].validation_error}
                  </div>
                </Col>
                <Col>
                  <Dropdown
                    isClearable={true}
                    name="contest_theme"
                    {...contest_theme.props}
                    label={
                      <span>
                        {contest_theme.title}
                        {contest_theme.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    isMulti={false}
                    value={
                      process.data.fields[contest_theme.field]
                        ? {
                            label: (
                              <FormattedMessage
                                id={`process > option label ${lowerCase(
                                  process.data.fields[contest_theme.field].value
                                )} secs`}
                              />
                            ),
                            value:
                              process.data.fields[contest_theme.field].value
                          }
                        : ""
                    }
                    onChange={valueChanged.bind(
                      this,
                      contest_theme,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[contest_theme.field] &&
                      validationState[contest_theme.field].validation_error}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Dropdown
                    isClearable={true}
                    name="contest_winner_selection"
                    {...contest_winner_selection.props}
                    label={
                      <span>
                        {contest_winner_selection.title}
                        {contest_winner_selection.mandatory && (
                          <Asterisk>*</Asterisk>
                        )}
                      </span>
                    }
                    value={
                      process.data.fields[contest_winner_selection.field]
                        ? {
                            label: (
                              <FormattedMessage
                                id={`process > option label ${lowerCase(
                                  process.data.fields[
                                    contest_winner_selection.field
                                  ].value
                                )}`}
                              />
                            ),
                            value:
                              process.data.fields[
                                contest_winner_selection.field
                              ].value
                          }
                        : ""
                    }
                    onChange={valueChanged.bind(
                      this,
                      contest_winner_selection,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[contest_winner_selection.field] &&
                      validationState[contest_winner_selection.field]
                        .validation_error}
                  </div>
                </Col>
                <Col>
                  <TextInputFormatted
                    thousandSeparator={true}
                    label={
                      <span>
                        {contest_number_of_plays.title}
                        {contest_number_of_plays.mandatory && (
                          <Asterisk>*</Asterisk>
                        )}
                      </span>
                    }
                    type="number"
                    value={
                      process.data.fields[contest_number_of_plays.field]
                        ? process.data.fields[contest_number_of_plays.field]
                            .value
                        : ""
                    }
                    onChange={value => {
                      valueChanged(
                        contest_number_of_plays,
                        process,
                        template,
                        user,
                        {
                          value
                        }
                      );
                    }}
                  />
                  <div className="alert-danger">
                    {validationState[contest_number_of_plays.field] &&
                      validationState[contest_number_of_plays.field]
                        .validation_error}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextInput
                    label={
                      <span>
                        {contest_internal_description.title}
                        {contest_internal_description.mandatory && (
                          <Asterisk>*</Asterisk>
                        )}
                      </span>
                    }
                    type="textarea"
                    placeholder="Type Here..."
                    value={
                      process.data.fields[contest_internal_description.field]
                        ? process.data.fields[
                            contest_internal_description.field
                          ].value
                        : ""
                    }
                    onChange={valueChanged.bind(
                      this,
                      contest_internal_description,
                      process,
                      template,
                      user
                    )}
                    name="contest_internal_description"
                  />
                  <div className="alert-danger">
                    {validationState[contest_internal_description.field] &&
                      validationState[contest_internal_description.field]
                        .validation_error}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>
                    <FormattedMessage id="process > title approvals" />{" "}
                  </Label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ marginRight: 15 }}>
                      <Checkbox
                        checked={
                          process.data.fields[
                            contest_approved_program_director.field
                          ] &&
                          process.data.fields[
                            contest_approved_program_director.field
                          ].value
                            ? true
                            : false
                        }
                        onChange={checked => {
                          this.onCheckBoxChange(
                            contest_approved_program_director,
                            process,
                            template,
                            user,
                            checked
                          );
                        }}
                        text={contest_approved_program_director.title}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </SectionFields>
          </ShadowBox>

          <ShadowBox className="promotion-shadow-box">
            <SectionTitle
              className="promotion-section-title"
              onClick={() =>
                this.setState({
                  isOpenJockInstructions: !this.state.isOpenJockInstructions
                })
              }
            >
              <FormattedMessage id="process > title promotion jock instructions" />
              {this.isCompletedSection("jock_instructions") && (
                <FaCheck color="#6F96D1" size={30} />
              )}
            </SectionTitle>
            <SectionFields
              className={!this.state.isOpenJockInstructions && "hidden"}
            >
              <Row>
                <Col>
                  <TextInput
                    label={
                      <span>
                        {contest_before_winner_selection.title}
                        {contest_before_winner_selection.mandatory && (
                          <Asterisk>*</Asterisk>
                        )}
                      </span>
                    }
                    value={
                      get(
                        process,
                        `data.fields.${contest_before_winner_selection.field}.value`
                      )
                        ? get(
                            process,
                            `data.fields.${contest_before_winner_selection.field}.value`
                          )
                        : ""
                    }
                    onChange={valueChanged.bind(
                      this,
                      contest_before_winner_selection,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[contest_before_winner_selection.field] &&
                      validationState[contest_before_winner_selection.field]
                        .validation_error}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextInput
                    label={
                      <span>
                        {contest_after_winner_selection.title}
                        {contest_after_winner_selection.mandatory && (
                          <Asterisk>*</Asterisk>
                        )}
                      </span>
                    }
                    value={
                      process.data.fields[contest_after_winner_selection.field]
                        ? process.data.fields[
                            contest_after_winner_selection.field
                          ].value
                        : ""
                    }
                    onChange={valueChanged.bind(
                      this,
                      contest_after_winner_selection,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[contest_after_winner_selection.field] &&
                      validationState[contest_after_winner_selection.field]
                        .validation_error}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextInput
                    label={
                      <span>
                        {contest_notes.title}
                        {contest_notes.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    value={
                      process.data.fields[contest_notes.field]
                        ? process.data.fields[contest_notes.field].value
                        : ""
                    }
                    type="textarea"
                    onChange={valueChanged.bind(
                      this,
                      contest_notes,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[contest_notes.field] &&
                      validationState[contest_notes.field].validation_error}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <ToggleSwitch
                    label={contest_trivia_questions.title}
                    leftComponent={"Off"}
                    rightComponent={"On"}
                    switchProps={{
                      onChange: checked => {
                        this.onCheckBoxChange(
                          contest_trivia_questions,
                          process,
                          template,
                          user,
                          checked
                        );
                      },
                      disabled: false,
                      checked: process.data.fields[
                        contest_trivia_questions.field
                      ]
                        ? process.data.fields[contest_trivia_questions.field]
                            .value
                        : false,
                      uncheckedIcon: false,
                      checkedIcon: false
                    }}
                  />
                </Col>
              </Row>
              {process.data.fields[contest_trivia_questions.field] &&
                process.data.fields[contest_trivia_questions.field].value && (
                  <React.Fragment>
                    <Row>
                      <Col>
                        <Dropdown
                          isClearable={true}
                          name="contest_trivia_unanswered_questions"
                          {...contest_trivia_unanswered_questions.props}
                          label={
                            <span>
                              {contest_trivia_unanswered_questions.title}
                              {contest_trivia_unanswered_questions.mandatory && (
                                <Asterisk>*</Asterisk>
                              )}
                            </span>
                          }
                          value={
                            process.data.fields[
                              contest_trivia_unanswered_questions.field
                            ]
                              ? {
                                  label: (
                                    <FormattedMessage
                                      id={`process > option label ${lowerCase(
                                        process.data.fields[
                                          contest_trivia_unanswered_questions
                                            .field
                                        ].value
                                      )}`}
                                    />
                                  ),
                                  value:
                                    process.data.fields[
                                      contest_trivia_unanswered_questions.field
                                    ].value
                                }
                              : ""
                          }
                          onChange={valueChanged.bind(
                            this,
                            contest_trivia_unanswered_questions,
                            process,
                            template,
                            user
                          )}
                        />
                      </Col>
                      <Col>
                        <Dropdown
                          isClearable={true}
                          name="contest_trivia_rollover_unclaimed_prizes"
                          {...contest_trivia_rollover_unclaimed_prizes.props}
                          label={
                            <span>
                              {contest_trivia_rollover_unclaimed_prizes.title}
                              {contest_trivia_rollover_unclaimed_prizes.mandatory && (
                                <Asterisk>*</Asterisk>
                              )}
                            </span>
                          }
                          isMulti={false}
                          value={
                            process.data.fields[
                              contest_trivia_rollover_unclaimed_prizes.field
                            ]
                              ? {
                                  label: (
                                    <FormattedMessage
                                      id={`process > option label ${lowerCase(
                                        process.data.fields[
                                          contest_trivia_rollover_unclaimed_prizes
                                            .field
                                        ].value
                                      )}`}
                                    />
                                  ),
                                  value:
                                    process.data.fields[
                                      contest_trivia_rollover_unclaimed_prizes
                                        .field
                                    ].value
                                }
                              : ""
                          }
                          onChange={valueChanged.bind(
                            this,
                            contest_trivia_rollover_unclaimed_prizes,
                            process,
                            template,
                            user
                          )}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Dropdown
                          isClearable={true}
                          name="contest_trivia_question_order"
                          {...contest_trivia_question_order.props}
                          label={
                            <span>
                              {contest_trivia_question_order.title}
                              {contest_trivia_question_order.mandatory && (
                                <Asterisk>*</Asterisk>
                              )}
                            </span>
                          }
                          value={
                            process.data.fields[
                              contest_trivia_question_order.field
                            ]
                              ? {
                                  label: (
                                    <FormattedMessage
                                      id={`process > option label ${lowerCase(
                                        process.data.fields[
                                          contest_trivia_question_order.field
                                        ].value
                                      )}`}
                                    />
                                  ),
                                  value:
                                    process.data.fields[
                                      contest_trivia_question_order.field
                                    ].value
                                }
                              : ""
                          }
                          onChange={valueChanged.bind(
                            this,
                            contest_trivia_question_order,
                            process,
                            template,
                            user
                          )}
                        />
                      </Col>
                      <Col>
                        <Dropdown
                          isClearable={true}
                          name="contest_trivia_number_of_chances"
                          {...contest_trivia_number_of_chances.props}
                          label={
                            <span>
                              {contest_trivia_number_of_chances.title}
                              {contest_trivia_number_of_chances.mandatory && (
                                <Asterisk>*</Asterisk>
                              )}
                            </span>
                          }
                          isMulti={false}
                          value={
                            process.data.fields[
                              contest_trivia_number_of_chances.field
                            ]
                              ? {
                                  label:
                                    process.data.fields[
                                      contest_trivia_number_of_chances.field
                                    ].value,
                                  value:
                                    process.data.fields[
                                      contest_trivia_number_of_chances.field
                                    ].value
                                }
                              : ""
                          }
                          onChange={valueChanged.bind(
                            this,
                            contest_trivia_number_of_chances,
                            process,
                            template,
                            user
                          )}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <TextInputGroup
                          leftComponent="Questions"
                          rightComponent="delete"
                          onChange={(e, index, key) => {
                            const v = e.target.value;
                            const newValues = triviaQuestionValue;
                            newValues[index][key] = v;
                            valueChanged(
                              contest_trivia_questions_textfields,
                              process,
                              template,
                              user,
                              { value: newValues }
                            );
                          }}
                          placeholder="Type Here"
                          ableToAddNew
                          onAddNew={() => {
                            valueChanged(
                              contest_trivia_questions_textfields,
                              process,
                              template,
                              user,
                              {
                                value: [
                                  ...triviaQuestionValue,
                                  { q: "", a: "" }
                                ]
                              }
                            );
                          }}
                          onDelete={index => {
                            const newValues = [...triviaQuestionValue];
                            newValues.splice(index, 1);
                            valueChanged(
                              contest_trivia_questions_textfields,
                              process,
                              template,
                              user,
                              { value: newValues }
                            );
                          }}
                          fields={triviaQuestionValue}
                        />
                        <div className="alert-danger">
                          {validationState[
                            contest_trivia_questions_textfields.field
                          ] &&
                            validationState[
                              contest_trivia_questions_textfields.field
                            ].validation_error}
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              <Row>
                <Col>
                  <ToggleSwitch
                    label={contest_additional_questions.title}
                    leftComponent={"Off"}
                    rightComponent={"On"}
                    switchProps={{
                      onChange: checked => {
                        this.onCheckBoxChange(
                          contest_additional_questions,
                          process,
                          template,
                          user,
                          checked
                        );
                      },
                      disabled: false,
                      checked: process.data.fields[
                        contest_additional_questions.field
                      ]
                        ? process.data.fields[
                            contest_additional_questions.field
                          ].value
                        : false,
                      uncheckedIcon: false,
                      checkedIcon: false
                    }}
                  />
                </Col>
              </Row>
              {process.data.fields[contest_additional_questions.field] &&
                process.data.fields[contest_additional_questions.field]
                  .value && (
                  <Row>
                    <Col>
                      <TextInput
                        label={
                          <span>
                            {contest_additional_questions_textfields.title}
                            {contest_additional_questions_textfields.mandatory && (
                              <Asterisk>*</Asterisk>
                            )}
                          </span>
                        }
                        onChange={(e, index) => {
                          const v = e.target.value;
                          const newValues = additionTextFieldsValue;
                          newValues[index] = v;
                          valueChanged(
                            contest_additional_questions_textfields,
                            process,
                            template,
                            user,
                            { value: newValues }
                          );
                        }}
                        placeholder="Type Here"
                        ableToAddNew
                        onAddNew={() => {
                          valueChanged(
                            contest_additional_questions_textfields,
                            process,
                            template,
                            user,
                            { value: [...additionTextFieldsValue, ""] }
                          );
                        }}
                        fields={additionTextFieldsValue.map(value => ({
                          value
                        }))}
                      />
                      <div className="alert-danger">
                        {validationState[
                          contest_additional_questions_textfields.field
                        ] &&
                          validationState[
                            contest_additional_questions_textfields.field
                          ].validation_error}
                      </div>
                    </Col>
                  </Row>
                )}
            </SectionFields>
          </ShadowBox>

          <ShadowBox className="promotion-shadow-box">
            <SectionTitle
              className="promotion-section-title"
              onClick={() =>
                this.setState({
                  isOpenContractDetails: !this.state.isOpenContractDetails
                })
              }
            >
              <FormattedMessage id="process > title client and contract details" />
              {this.isCompletedSection("contract_details") && (
                <FaCheck color="#6F96D1" size={30} />
              )}
            </SectionTitle>
            <SectionFields
              className={!this.state.isOpenContractDetails && "hidden"}
            >
              <Row>
                <Col>
                  {!contractSaved ? (
                    <div className="autosuggest-container">
                      <AutocompleteInput
                        label={client_name.title}
                        isRequired={client_name.mandatory}
                        value={
                          process.data.fields[client_name.field]
                            ? process.data.fields[client_name.field].value
                            : ""
                        }
                        items={this.state.customers}
                        getItemValue={item => item.client_name}
                        renderMenu={children => {
                          return (
                            <div
                              className={children.length > 0 ? "menu" : ""}
                            >
                              {children}
                            </div>
                          );
                        }}
                        renderItem={(item, isHighlighted) => (
                          <div
                            className={`item ${
                              isHighlighted ? "item-highlighted" : ""
                            }`}
                            key={item.id}
                          >
                            {item.client_name}
                          </div>
                        )}
                        onSelect={(value, item) => {
                          bindedValueChanged(
                            client_name,
                            process,
                            template,
                            user,
                            { value }
                          );
                          bindedValueChanged(
                            client_id,
                            process,
                            template,
                            user,
                            { value: item.id }
                          );
                          autoSuggestContracts({
                            cliant_id: item.id,
                            q: "",
                            cb: items => {
                              this.setState({ contracts: items });
                            }
                          });
                        }}
                        onChange={(e, value) => {
                          clearTimeout(this.requestTimer);
                          this.requestTimer = setTimeout(() => {
                            autoSuggestClients({
                              q: value,
                              cb: items => {
                                this.setState({ customers: items });
                              }
                            });
                          }, 200);
                          bindedValueChanged(
                            client_name,
                            process,
                            template,
                            user,
                            e
                          );
                        }}
                        error={validationState[client_name.field] &&
                          validationState[client_name.field].validation_error}
                      />
                    </div>
                  ) : (
                    <div>
                      <Label>{client_name.title}</Label>
                      {process.data.fields[client_name.field]
                        ? process.data.fields[client_name.field].value
                        : ""}
                    </div>
                  )}
                </Col>
                <Col>
                  {((process.data.fields[client_name.field] &&
                    process.data.fields[client_name.field].value) ||
                    (process.data.fields[client_id.field] &&
                      process.data.fields[client_id.field].value)) &&
                  !contractSaved ? (
                    <div className="autosuggest-container">
                      <AutocompleteInput
                        label={contract_number.title}
                        isRequired={contract_number.mandatory}
                        error={validationState[contract_number.field] &&
                          validationState[contract_number.field].validation_error}
                        value={
                          process.data.fields[contract_number.field]
                            ? process.data.fields[contract_number.field].value
                            : ""
                        }
                        items={this.state.contracts}
                        getItemValue={item => item.contract_number}
                        renderMenu={children => {
                          return (
                            <div
                              className={children.length > 0 ? "menu" : ""}
                            >
                              {children}
                            </div>
                          );
                        }}
                        renderItem={(item, isHighlighted) => (
                          <div
                            className={`item ${
                              isHighlighted ? "item-highlighted" : ""
                            }`}
                            key={item.id}
                          >
                            {item.contract_number}
                          </div>
                        )}
                        onSelect={(value, item) => {
                          bindedValueChanged(
                            contract_number,
                            process,
                            template,
                            user,
                            { value }
                          );
                          bindedValueChanged(
                            contract_id,
                            process,
                            template,
                            user,
                            { value: item.id }
                          );
                          // populate all contract fields
                          bindedValueChanged(
                            contract_category,
                            process,
                            template,
                            user,
                            {
                              value: item.contract_category
                                ? item.contract_category
                                : ""
                            }
                          );
                          bindedValueChanged(
                            contract_stations,
                            process,
                            template,
                            user,
                            {
                              value: item.contract_stations
                                ? item.contract_stations
                                : []
                            }
                          );
                          bindedValueChanged(
                            contract_value,
                            process,
                            template,
                            user,
                            {
                              value: item.contract_value
                                ? item.contract_value
                                : 0
                            }
                          );
                        }}
                        onChange={(e, value) => {
                          bindedValueChanged(
                            contract_number,
                            process,
                            template,
                            user,
                            e
                          );
                          if (process.data.fields[client_id.field]) {
                            clearTimeout(this.requestTimer);
                            this.requestTimer = setTimeout(() => {
                              autoSuggestContracts({
                                cliant_id:
                                  process.data.fields[client_id.field].value,
                                q: value,
                                cb: items => {
                                  this.setState({ contracts: items });
                                }
                              });
                            }, 500);
                          }
                          // invalidate contract fields
                          bindedValueChanged(
                            client_name,
                            process,
                            template,
                            user,
                            {
                              value: process.data.fields.client_name
                                ? process.data.fields.client_name.value
                                : ""
                            }
                          );
                          bindedValueChanged(
                            contract_category,
                            process,
                            template,
                            user,
                            {
                              value: process.data.fields.contract_category
                                ? process.data.fields.contract_category.value
                                : ""
                            }
                          );
                          bindedValueChanged(
                            contract_stations,
                            process,
                            template,
                            user,
                            {
                              value: process.data.fields.contract_stations
                                ? process.data.fields.contract_stations.value
                                : ""
                            }
                          );
                        }}
                      />
                    </div>
                  ) : (
                    <div>
                      <Label>
                        {contract_number.title}
                        {contract_number.mandatory && <Asterisk>*</Asterisk>}
                      </Label>
                      <TextInputFormatted
                        disabled={true}
                        value={
                          process.data.fields[contract_number.field]
                            ? process.data.fields[contract_number.field].value
                            : ""
                        }
                      />
                      <div className="alert-danger">
                        {validationState[contract_number.field] &&
                          validationState[contract_number.field].validation_error}
                      </div>
                    </div>
                  )}
                </Col>
              </Row>

              <Row>
                <Col>
                  {!contractSaved ? (
                    <span>
                      {/* <span className="" style={{
                        position: 'absolute',
                        padding: '13px 3px 13.5px',
                        background: '#ddd',
                        borderRadius: '4px 0 0 4px',
                        top: 31
                      }}>$</span> */}
                      <div
                      // style={{paddingLeft:'10px'}}
                      >
                        <TextInputFormatted
                          disabled={contractSaved}
                          prefix={"$"}
                          allowNegative={false}
                          thousandSeparator={true}
                          label={
                            <span
                            // style={{marginLeft:'-10px'}}
                            >
                              {contract_value.title}
                              {contract_value.mandatory && (
                                <Asterisk>*</Asterisk>
                              )}
                            </span>
                          }
                          type="number"
                          value={
                            process.data.fields[contract_value.field]
                              ? process.data.fields[contract_value.field].value
                              : ""
                          }
                          onChange={value => {
                            valueChanged(
                              contract_value,
                              process,
                              template,
                              user,
                              {
                                value
                              }
                            );
                          }}
                        />
                      </div>
                    </span>
                  ) : (
                    <div>
                      <Label>{contract_value.title}</Label>
                      {this.formattedValueOrEmpty(
                        process.data.fields[contract_value.field]
                          ? process.data.fields[contract_value.field].value
                          : "",
                        contract_value
                      )}
                    </div>
                  )}
                  <div className="alert-danger">
                    {validationState[contract_value.field] &&
                      validationState[contract_value.field].validation_error}
                  </div>
                </Col>
                {/* <Col xs="12" md="6" xl="4">
                  {!contractSaved ? (
                    <TextInput
                      disabled={contractSaved}
                      label={
                        <span>
                          {contract_start_date.title}
                          {contract_start_date.mandatory && (
                            <Asterisk>*</Asterisk>
                          )}
                        </span>
                      }
                      type="date"
                      min={moment().format("YYYY-MM-DD")}
                      value={
                        process.data.fields[contract_start_date.field]
                          ? process.data.fields[contract_start_date.field].value
                          : ""
                      }
                      onChange={valueChanged.bind(
                        this,
                        contract_start_date,
                        process,
                        template,
                        user
                      )}
                    />
                  ) : (
                    <div>
                      <Label>{contract_start_date.title}</Label>
                      {process.data.fields[contract_start_date.field]
                        ? formatDisplayDate(
                            process.data.fields[contract_start_date.field].value
                          )
                        : ""}
                    </div>
                  )}
                  <div className="alert-danger">
                    {validationState[contract_start_date.field] &&
                      validationState[contract_start_date.field]
                        .validation_error}
                  </div>
                </Col>
                <Col xs="12" md="12" xl="4">
                  {!contractSaved ? (
                    <TextInput
                      disabled={contractSaved}
                      label={
                        <span>
                          {contract_end_date.title}
                          {contract_end_date.mandatory && (
                            <Asterisk>*</Asterisk>
                          )}
                        </span>
                      }
                      type="date"
                      min={moment().format("YYYY-MM-DD")}
                      value={
                        process.data.fields[contract_end_date.field]
                          ? process.data.fields[contract_end_date.field].value
                          : ""
                      }
                      onChange={valueChanged.bind(
                        this,
                        contract_end_date,
                        process,
                        template,
                        user
                      )}
                    />
                  ) : (
                    <div>
                      <Label>{contract_end_date.title}</Label>
                      {process.data.fields[contract_end_date.field]
                        ? formatDisplayDate(
                            process.data.fields[contract_end_date.field].value
                          )
                        : ""}
                    </div>
                  )}
                  <div className="alert-danger">
                    {validationState[contract_end_date.field] &&
                      validationState[contract_end_date.field].validation_error}
                  </div>
                </Col> */}
              </Row>
              {/* <Row>
                <Col>
                  <TextInput
                    label={
                      <span>
                        {contract_client_contact.title}
                        {contract_client_contact.mandatory && (
                          <Asterisk>*</Asterisk>
                        )}
                      </span>
                    }
                    value={
                      process.data.fields[contract_client_contact.field]
                        ? process.data.fields[contract_client_contact.field]
                            .value
                        : ""
                    }
                    onChange={valueChanged.bind(
                      this,
                      contract_client_contact,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[contract_client_contact.field] &&
                      validationState[contract_client_contact.field]
                        .validation_error}
                  </div>
                </Col>
                <Col>
                  <TextInput
                    label={
                      <span>
                        {contract_client_email.title}
                        {contract_client_email.mandatory && (
                          <Asterisk>*</Asterisk>
                        )}
                      </span>
                    }
                    value={
                      process.data.fields[contract_client_email.field]
                        ? process.data.fields[contract_client_email.field].value
                        : ""
                    }
                    onChange={valueChanged.bind(
                      this,
                      contract_client_email,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[contract_client_email.field] &&
                      validationState[contract_client_email.field]
                        .validation_error}
                  </div>
                </Col>
              </Row> */}
              {/* <Row>
                <Col>
                  <TextInput
                    label={
                      <span>
                        {contract_client_nickname.title}
                        {contract_client_nickname.mandatory && (
                          <Asterisk>*</Asterisk>
                        )}
                      </span>
                    }
                    value={
                      process.data.fields[contract_client_nickname.field]
                        ? process.data.fields[contract_client_nickname.field]
                            .value
                        : ""
                    }
                    onChange={valueChanged.bind(
                      this,
                      contract_client_nickname,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[contract_client_nickname.field] &&
                      validationState[contract_client_nickname.field]
                        .validation_error}
                  </div>
                </Col>
                <Col>
                  <TextInputFormatted
                    label={
                      <span>
                        {contract_client_phone.title}
                        {contract_client_phone.mandatory && (
                          <Asterisk>*</Asterisk>
                        )}
                      </span>
                    }
                    value={
                      process.data.fields[contract_client_phone.field]
                        ? process.data.fields[contract_client_phone.field].value
                        : ""
                    }
                    format={PhoneNumberFormat}
                    onChange={value => {
                      valueChanged(
                        contract_client_phone,
                        process,
                        template,
                        user,
                        {
                          value
                        }
                      );
                    }}
                  />
                  <div className="alert-danger">
                    {validationState[contract_client_phone.field] &&
                      validationState[contract_client_phone.field]
                        .validation_error}
                  </div>
                </Col>
              </Row> */}
              <Row>
                <Col xs="12" lg="12">
                  <SelectStations
                    contract={true}
                    item={contract_stations}
                    disabled={contractSaved}
                    {...contract_stations.props}
                    validationState={validationState}
                    checked={process.data.fields[contract_stations.field]}
                    template={template}
                    isFilterByServerConfig={true}
                    onChange={value => {
                      multipleStationSelectionChanged(
                        contract_stations,
                        process,
                        template,
                        user,
                        value
                      );
                    }}
                  />
                </Col>
              </Row>
            </SectionFields>
          </ShadowBox>

          <ShadowBox className="promotion-shadow-box">
            <SectionTitle
              className="promotion-section-title"
              onClick={() =>
                this.setState({
                  isOpenContestSchedule: !this.state.isOpenContestSchedule
                })
              }
            >
              <FormattedMessage id="process > title promotion contest schedule" />
              {this.isCompletedSection("contest_schedule") && (
                <FaCheck color="#6F96D1" size={30} />
              )}
            </SectionTitle>
            <SectionFields
              className={!this.state.isOpenContestSchedule && "hidden"}
            >
              <Row>
                <Col xs="12" lg="12">
                  <SelectStations
                    contract={true}
                    item={select_stations}
                    disabled={contractSaved}
                    {...select_stations.props}
                    validationState={validationState}
                    checked={process.data.fields[select_stations.field]}
                    template={template}
                    isFilterByServerConfig={true}
                    onChange={value => {
                      multipleStationSelectionChanged(
                        select_stations,
                        process,
                        template,
                        user,
                        value
                      );
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Dropdown
                    isClearable={true}
                    name="plays_per_day"
                    {...plays_per_day.props}
                    label={
                      <span>
                        {plays_per_day.title}
                        {plays_per_day.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    isMulti={false}
                    value={
                      process.data.fields[plays_per_day.field]
                        ? {
                            label:
                              process.data.fields[plays_per_day.field].value,
                            value:
                              process.data.fields[plays_per_day.field].value
                          }
                        : ""
                    }
                    onChange={valueChanged.bind(
                      this,
                      plays_per_day,
                      process,
                      template,
                      user
                    )}
                  />
                </Col>
                <Col>
                  <TextInput
                    label={
                      <span>
                        {order_start_date.title}
                        {order_start_date.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    type="date"
                    min={moment().format("YYYY-MM-DD")}
                    value={
                      process.data.fields[order_start_date.field]
                        ? process.data.fields[order_start_date.field].value
                        : ""
                    }
                    onChange={valueChanged.bind(
                      this,
                      order_start_date,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[order_start_date.field] &&
                      validationState[order_start_date.field].validation_error}
                  </div>
                </Col>
                <Col>
                  <TextInput
                    label={
                      <span>
                        {order_end_date.title}
                        {order_end_date.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    type="date"
                    min={moment().format("YYYY-MM-DD")}
                    value={
                      process.data.fields[order_end_date.field]
                        ? process.data.fields[order_end_date.field].value
                        : ""
                    }
                    onChange={valueChanged.bind(
                      this,
                      order_end_date,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[order_end_date.field] &&
                      validationState[order_end_date.field].validation_error}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <DaysOfWeek
                    value={
                      process.data.fields[contest_days_of_week.field]
                        ? process.data.fields[contest_days_of_week.field].value
                        : []
                    }
                    onChange={days => {
                      valueChanged(
                        contest_days_of_week,
                        process,
                        template,
                        user,
                        { value: days }
                      );
                    }}
                  />
                </Col>
                <Col>
                  <Dropdown
                    isClearable={true}
                    name="day_parts"
                    {...order_day_parts.props}
                    label={
                      <span>
                        {order_day_parts.title}
                        {order_day_parts.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    isMulti={true}
                    value={
                      process.data.fields[order_day_parts.field]
                        ? process.data.fields[order_day_parts.field].value.map(
                            v => {
                              return {
                                label: (
                                  <FormattedMessage
                                    id={`process > option label ${lowerCase(
                                      v
                                    )}`}
                                  />
                                ),
                                value: v
                              };
                            }
                          )
                        : []
                    }
                    onChange={e =>
                      bindedValueChanged(
                        order_day_parts,
                        process,
                        template,
                        user,
                        e.map(v => v.value)
                      )
                    }
                  />
                </Col>
              </Row>
            </SectionFields>
          </ShadowBox>
          <ShadowBox className="promotion-shadow-box">
            <SectionTitle
              className="promotion-section-title"
              onClick={() =>
                this.setState({ isOpenDocuments: !this.state.isOpenDocuments })
              }
            >
              <FormattedMessage id="process > title promotion documents" />
              {this.isCompletedSection("documents") && (
                <FaCheck color="#6F96D1" size={30} />
              )}
            </SectionTitle>
            <SectionFields className={!this.state.isOpenDocuments && "hidden"}>
              <Row>
                <Col>
                  <UploadFiles
                    attachText="Upload Documents"
                    mimes={["application/pdf", "application/msword"]}
                    {...order_files.props}
                    acceptMultiple={true}
                    files={
                      process.data.fields[order_files.field]
                        ? process.data.fields[order_files.field].value || []
                        : []
                    }
                    onUploadedFiles={filesUploaded.bind(
                      this,
                      order_files,
                      process,
                      template,
                      user
                    )}
                    onDeleted={filesDeleted.bind(
                      this,
                      order_files,
                      process,
                      template,
                      user
                    )}
                    className={order_files.class}
                  >
                    <FormattedMessage id="process > button upload" />
                  </UploadFiles>
                </Col>
              </Row>
            </SectionFields>
          </ShadowBox>

          <Row>
            <Col>
              <ButtonGroup>
                <Button
                  block
                  color="blue"
                  className="button-edit"
                  onClick={this.props.buttonClicked.bind(
                    this,
                    step,
                    step.fields.find(f => f.key === "save_button"),
                    process,
                    template,
                    user
                  )}
                >
                  <FormattedMessage id="process > button submit" />
                </Button>

                <Button
                  block
                  color="blue"
                  outline
                  className="button-edit"
                  onClick={this.props.buttonClicked.bind(
                    this,
                    step,
                    {
                      ...step.fields.find(f => f.key === "save_button"),
                      draft: true
                    },
                    process,
                    template,
                    user
                  )}
                >
                  <FormattedMessage id="process > button save draft" />
                </Button>
                {
                  this.props.shouldBlockNavigation && (
                    <Button color="" className="process-link-clear-form" onClick={(e) => {
                      e.preventDefault();
                      this.props.setShouldBlockNavigation(false);
                      this.props.onPreparePage();
                    }}>
                      <FormattedMessage id="process > button clear form" />
                    </Button>
                  )
                }
              </ButtonGroup>
            </Col>
          </Row>
        </Form>
        <Modal
          isOpen={this.state.isOpenDialog}
          toggle={() =>
            this.setState({ isOpenDialog: !this.state.isOpenDialog })
          }
        >
          <ModalBody>
            {this.state.stationsWithoutSpots ? (
              <OrderDetailsIncompleteContract
                stationsWithoutSpots={this.state.stationsWithoutSpots}
                close={() => this.setState({ isOpenDialog: false })}
              />
            ) : (
              <OrderDetailsDuplicate
                process={process}
                template={template}
                close={() => this.setState({ isOpenDialog: false })}
              />
            )}
          </ModalBody>
        </Modal>
      </div>
    );
  }

  formattedValueOrEmpty(value, item) {
    if (get(item, "formatted")) {
      const formatted = get(item, "formatted");
      switch (formatted) {
        case "number":
          return (
            <NumberFormat
              prefix={get(item, "prefix", null)}
              value={value}
              displayType={"text"}
              thousandSeparator={true}
            />
          );
        case "phone":
          return (
            <NumberFormat
              value={value}
              displayType={"text"}
              format={PhoneNumberFormat}
            />
          );
        case "percentage":
          return (
            <NumberFormat
              value={value}
              displayType={"text"}
              suffix={get(item, "suffix", RotationFormatSuffix)}
            />
          );
        default:
          break;
      }
    }
    return value;
  }

  valueOrEmpty(item) {
    let { process } = this.props;
    let value =
      process.data.fields[item.field] && process.data.fields[item.field].value
        ? process.data.fields[item.field].value.name !== undefined
          ? process.data.fields[item.field].value.name
          : process.data.fields[item.field].value
        : "";
    return this.formattedValueOrEmpty(value, item);
  }

  renderView() {
    let {
      process,
      template,
      user,
      step,
      valueChanged
      // validationState,
      // fileUploaded,
      // fileDeleted,
      // filesUploaded,
      // filesDeleted,
      // checkboxGroupChanged,
      // checkboxChanged,
      // getRoleCandidates,
      // spotsChanged,
      // multipleStationSelectionChanged
    } = this.props;

    let {
      client_name,
      // client_id,
      contract_number,
      // contract_id,
      // contract_start_date,
      // contract_end_date,
      contract_value,
      // contract_client_contact,
      // contract_client_email,
      // contract_client_nickname,
      // contract_client_phone,
      // contract_status,
      contract_stations,
      // contract_category,
      order_title,
      order_start_date,
      order_end_date,
      // order_length,
      order_day_parts,
      // copy_points,
      order_files,
      select_stations,
      // spots,
      // contract_stations_checked,
      // stations_value,
      // select_stations_checked,
      // contractSaved,
      // script,
      contest_type,
      contest_theme,
      contest_winner_selection,
      contest_number_of_plays,
      contest_internal_description,
      contest_approved_program_director,
      contest_before_winner_selection,
      contest_after_winner_selection,
      contest_notes,
      contest_additional_questions,
      contest_additional_questions_textfields,
      contest_days_of_week,
      // liners,
      // active_liner,
      // liner_mentions_per_day,
      // liner_recorded_promos,
      // liner_start_date,
      // liner_end_date,
      // liner_length,
      // liner_day_part,
      // liner_days_of_week,
      contest_trivia_questions_textfields,
      contest_trivia_questions,
      contest_trivia_question_order,
      contest_trivia_unanswered_questions,
      contest_trivia_rollover_unclaimed_prizes,
      contest_trivia_number_of_chances
    } = this.getFieldRefs();

    const additionTextFieldsValue = process.data.fields[
      contest_additional_questions_textfields.field
    ]
      ? process.data.fields[contest_additional_questions_textfields.field].value
      : [""];
    const triviaQuestionValue = process.data.fields[
      contest_trivia_questions_textfields.field
    ]
      ? process.data.fields[contest_trivia_questions_textfields.field].value
      : [{ q: "", a: "" }];
    // const linersArray = process.data.fields[liners.field]
    //   ? process.data.fields[liners.field].value
    //   : [{}];
    // const activelinerIndex = process.data.fields[active_liner.field]
    //   ? process.data.fields[active_liner.field].value
    //   : 0;
    // const activeliner = linersArray[activelinerIndex];

    // const draftScript = activeliner[script.field]
    //   ? activeliner[script.field]
    //   : "";
    // let parsedDraftScript = "";
    // const domParser = new DOMParser();
    // if (draftScript) {
    //   const parsed = domParser.parseFromString(draftScript, "text/html");
    //   const tags = parsed.getElementsByClassName("tag") || [];
    //   for (let index = 0; index < tags.length; index++) {
    //     const tag = tags[index];
    //     const replaceWith = tag.getAttribute("data-tag-replace-with");
    //     if (replaceWith) {
    //       const field = process.data.fields[replaceWith]
    //         ? process.data.fields[replaceWith].value
    //         : "";
    //       tag.innerText = [].concat(field).join(", ");
    //     } else {
    //       tag.innerText = "";
    //     }
    //   }
    //   parsedDraftScript = parsed.documentElement.outerHTML;
    // }

    let spots = [];
    let prevOrNextSpot = false;
    let passedCurrent = false;
    if (
      process.data.fields.key &&
      this.props.process.data.fields.spots &&
      this.props.process.data.fields.spots.value.length > 1
    ) {
      this.props.process.data.fields.spots.value.map((spot, ind) => {
        if (
          spot.key !== process.data.fields.key.value &&
          (!passedCurrent || !prevOrNextSpot)
        )
          prevOrNextSpot = spot;
        else passedCurrent = true;
        spots.push({
          key: spot,
          component: (
            <span>
              {" "}
              <FormattedMessage id="process > title spot" /> {ind + 1}{" "}
            </span>
          )
        });
        return true;
      });
    }
    let valueOrEmpty = this.valueOrEmpty.bind(this);

    return (
      <div className="text-left">
        <Form>
          <ShadowBox className="promotion-shadow-box">
            <SectionTitle
              className="promotion-section-title"
              onClick={() =>
                this.setState({
                  isOpenContestDescription: !this.state.isOpenContestDescription
                })
              }
            >
              <FormattedMessage id="process > title promotion contest details" />
              {this.isCompletedSection("contest_description") && (
                <FaCheck color="#6F96D1" size={30} />
              )}
            </SectionTitle>
            <SectionFields
              className={!this.state.isOpenContestDescription && "hidden"}
            >
              <Row>
                <Col>
                  <Label>{order_title.title}</Label>
                  <div className="order-view-value">
                    {valueOrEmpty(order_title)}
                  </div>
                </Col>
              </Row>
              {/* <Row>
                <Col xs="12" lg="6">
                  <Label>{select_stations.title}</Label>
                  <DivView>
                    {process.data.fields[select_stations.field] &&
                      process.data.fields[select_stations.field].value.map(
                        (s, i) => (
                          <span
                            style={{
                              paddingRight: "20px",
                              display: "inline-block"
                            }}
                            key={i}
                          >
                            {s}
                          </span>
                        )
                      )}
                  </DivView>
                </Col>
              </Row> */}
              <Row>
                <Col>
                  <Label>{contest_type.title}</Label>
                  <div className="order-view-value">
                    {valueOrEmpty(contest_type)}
                  </div>
                </Col>
                <Col>
                  <Label>{contest_theme.title}</Label>
                  <div className="order-view-value">
                    {valueOrEmpty(contest_theme)}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>{contest_winner_selection.title}</Label>
                  <div className="order-view-value">
                    {valueOrEmpty(contest_winner_selection)}
                  </div>
                </Col>
                <Col>
                  <Label>{contest_number_of_plays.title}</Label>
                  <div className="order-view-value">
                    {valueOrEmpty(contest_number_of_plays)}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>{contest_internal_description.title}</Label>
                  <div className="order-view-value">
                    {valueOrEmpty(contest_internal_description)}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>
                    <FormattedMessage id="process > title approvals" />{" "}
                  </Label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ marginRight: 15 }}>
                      <Checkbox
                        checked={
                          process.data.fields[
                            contest_approved_program_director.field
                          ] &&
                          process.data.fields[
                            contest_approved_program_director.field
                          ].value
                            ? true
                            : false
                        }
                        onChange={checked => {
                          this.onCheckBoxChange(
                            contest_approved_program_director,
                            process,
                            template,
                            user,
                            checked
                          );
                        }}
                        disabled
                        text={contest_approved_program_director.title}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </SectionFields>
          </ShadowBox>

          <ShadowBox className="promotion-shadow-box">
            <SectionTitle
              className="promotion-section-title"
              onClick={() =>
                this.setState({
                  isOpenJockInstructions: !this.state.isOpenJockInstructions
                })
              }
            >
              <FormattedMessage id="process > title promotion jock instructions" />
              {this.isCompletedSection("jock_instructions") && (
                <FaCheck color="#6F96D1" size={30} />
              )}
            </SectionTitle>
            <SectionFields
              className={!this.state.isOpenJockInstructions && "hidden"}
            >
              <Row>
                <Col>
                  <Label>{contest_before_winner_selection.title}</Label>
                  <div className="order-view-value">
                    {valueOrEmpty(contest_before_winner_selection)}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>{contest_after_winner_selection.title}</Label>
                  <div className="order-view-value">
                    {valueOrEmpty(contest_after_winner_selection)}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>{contest_notes.title}</Label>
                  <div className="order-view-value">
                    {valueOrEmpty(contest_notes)}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <ToggleSwitch
                    label={contest_trivia_questions.title}
                    leftComponent={"Off"}
                    rightComponent={"On"}
                    switchProps={{
                      onChange: () => {},
                      disabled: true,
                      checked: process.data.fields[
                        contest_trivia_questions.field
                      ]
                        ? process.data.fields[contest_trivia_questions.field]
                            .value
                        : false,
                      uncheckedIcon: false,
                      checkedIcon: false
                    }}
                  />
                </Col>
              </Row>
              {process.data.fields[contest_trivia_questions.field] &&
                process.data.fields[contest_trivia_questions.field].value && (
                  <React.Fragment>
                    <Row>
                      <Col>
                        <Label>
                          {contest_trivia_unanswered_questions.title}
                        </Label>
                        <div className="order-view-value">
                          {valueOrEmpty(contest_trivia_unanswered_questions)}
                        </div>
                      </Col>
                      <Col>
                        <Label>
                          {contest_trivia_rollover_unclaimed_prizes.title}
                        </Label>
                        <div className="order-view-value">
                          {valueOrEmpty(
                            contest_trivia_rollover_unclaimed_prizes
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label>{contest_trivia_question_order.title}</Label>
                        <div className="order-view-value">
                          {valueOrEmpty(contest_trivia_question_order)}
                        </div>
                      </Col>
                      <Col>
                        <Label>{contest_trivia_number_of_chances.title}</Label>
                        <div className="order-view-value">
                          {valueOrEmpty(contest_trivia_number_of_chances)}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <TextInputGroup
                          leftComponent="Questions"
                          disabled
                          fields={triviaQuestionValue}
                        />
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              <Row>
                <Col>
                  <ToggleSwitch
                    label={contest_additional_questions.title}
                    leftComponent={"Off"}
                    rightComponent={"On"}
                    switchProps={{
                      onChange: () => {},
                      disabled: true,
                      checked: process.data.fields[
                        contest_additional_questions.field
                      ]
                        ? process.data.fields[
                            contest_additional_questions.field
                          ].value
                        : false,
                      uncheckedIcon: false,
                      checkedIcon: false
                    }}
                  />
                </Col>
              </Row>
              {process.data.fields[contest_additional_questions.field] &&
                process.data.fields[contest_additional_questions.field]
                  .value && (
                  <Row>
                    <Col>
                      <TextInput
                        label={contest_additional_questions_textfields.title}
                        disabled
                        fields={additionTextFieldsValue.map(value => ({
                          value
                        }))}
                      />
                    </Col>
                  </Row>
                )}
            </SectionFields>
          </ShadowBox>

          <ShadowBox className="promotion-shadow-box">
            <SectionTitle
              className="promotion-section-title"
              onClick={() =>
                this.setState({
                  isOpenContractDetails: !this.state.isOpenContractDetails
                })
              }
            >
              <FormattedMessage id="process > title client and contract details" />
              {this.isCompletedSection("contract_details") && (
                <FaCheck color="#6F96D1" size={30} />
              )}
            </SectionTitle>
            <SectionFields
              className={!this.state.isOpenContractDetails && "hidden"}
            >
              <Row>
                <Col>
                  <Label>{client_name.title}</Label>
                  <div className="order-view-value">
                    {valueOrEmpty(client_name)}
                  </div>
                </Col>
                <Col>
                  <Label>{contract_number.title}</Label>
                  <div className="order-view-value">
                    {valueOrEmpty(contract_number)}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xs="12" md="6" xl="4">
                  <Label>{contract_value.title}</Label>
                  <div className="order-view-value">
                    {valueOrEmpty(contract_value)}
                  </div>
                </Col>
                {/* <Col xs="12" md="6" xl="4">
                  <Label>{contract_start_date.title}</Label>
                  <DivView>{valueOrEmpty(contract_start_date)}</DivView>
                </Col>
                <Col xs="12" md="12" xl="4">
                  <Label>{contract_end_date.title}</Label>
                  <DivView>{valueOrEmpty(contract_end_date)}</DivView>
                </Col> */}
              </Row>
              {/* <Row>
                <Col>
                  <Label>{contract_client_contact.title}</Label>
                  <DivView>{valueOrEmpty(contract_client_contact)}</DivView>
                </Col>
                <Col>
                  <Label>{contract_client_email.title}</Label>
                  <DivView>{valueOrEmpty(contract_client_email)}</DivView>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>{contract_client_nickname.title}</Label>
                  <DivView>{valueOrEmpty(contract_client_nickname)}</DivView>
                </Col>
                <Col>
                  <Label>{contract_client_phone.title}</Label>
                  <DivView>{valueOrEmpty(contract_client_phone)}</DivView>
                </Col>
              </Row> */}
              <Row>
                <Col xs="12" lg="6">
                  <Label>{contract_stations.title}</Label>
                  <DivView>
                    {process.data.fields[contract_stations.field] &&
                      process.data.fields[contract_stations.field].value.map(
                        (s, i) => (
                          <span className="order-view-list-item" key={i}>
                            {s}
                          </span>
                        )
                      )}
                  </DivView>
                </Col>
              </Row>
            </SectionFields>
          </ShadowBox>

          <ShadowBox className="promotion-shadow-box">
            <SectionTitle
              className="promotion-section-title"
              onClick={() =>
                this.setState({
                  isOpenContestSchedule: !this.state.isOpenContestSchedule
                })
              }
            >
              <FormattedMessage id="process > title promotion contest schedule" />
              {this.isCompletedSection("contest_schedule") && (
                <FaCheck color="#6F96D1" size={30} />
              )}
            </SectionTitle>
            <SectionFields
              className={!this.state.isOpenContestSchedule && "hidden"}
            >
              <Row>
                <Col xs="12" lg="6">
                  <Label>{select_stations.title}</Label>
                  <DivView>
                    {process.data.fields[select_stations.field] &&
                      process.data.fields[select_stations.field].value.map(
                        (s, i) => (
                          <span className="order-view-list-item" key={i}>
                            {s}
                          </span>
                        )
                      )}
                  </DivView>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>{order_start_date.title}</Label>
                  <div className="order-view-value">
                    {valueOrEmpty(order_start_date)}
                  </div>
                </Col>
                <Col>
                  <Label>{order_end_date.title}</Label>
                  <div className="order-view-value">
                    {valueOrEmpty(order_end_date)}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <DaysOfWeek
                    value={
                      process.data.fields[contest_days_of_week.field]
                        ? process.data.fields[contest_days_of_week.field].value
                        : []
                    }
                    disabled
                    onChange={days => {
                      valueChanged(
                        contest_days_of_week,
                        process,
                        template,
                        user,
                        { value: days }
                      );
                    }}
                  />
                </Col>
                <Col>
                  <Label>{order_day_parts.title}</Label>
                  <div className="order-view-value">
                    {valueOrEmpty(order_day_parts)}
                  </div>
                </Col>
              </Row>
            </SectionFields>
          </ShadowBox>
          <ShadowBox className="promotion-shadow-box">
            <SectionTitle
              className="promotion-section-title"
              onClick={() =>
                this.setState({ isOpenDocuments: !this.state.isOpenDocuments })
              }
            >
              <FormattedMessage id="process > title promotion documents" />
              {this.isCompletedSection("documents") && (
                <FaCheck color="#6F96D1" size={30} />
              )}
            </SectionTitle>
            <SectionFields className={!this.state.isOpenDocuments && "hidden"}>
              <Row>
                <Col>
                  <UploadFiles
                    attachText="Upload Documents"
                    mimes={["application/pdf", "application/msword"]}
                    {...order_files.props}
                    acceptMultiple={true}
                    files={
                      process.data.fields[order_files.field]
                        ? process.data.fields[order_files.field].value || []
                        : []
                    }
                    disabled
                    className={order_files.class}
                  >
                    <FormattedMessage id="process > button upload" />
                  </UploadFiles>
                </Col>
              </Row>
            </SectionFields>
          </ShadowBox>
        </Form>
        {this.props.mode === "view" && (
          <Row>
            <Col>
              <Button
                color="blue"
                className="button-edit button-edit-auto"
                onClick={() => {
                  history.push(
                    "/processes/edit/" + template.key + "/" + process.key
                  );
                  this.props.setActiveEditTab(step.key);
                }}
              >
                <FormattedMessage id="process > button edit" />
              </Button>
              <PrivilegedComponent requires={[PRIVILEGES.DELETE_PROCESSES]}>
                <Button
                  color="blue"
                  outline
                  className="button-edit button-edit-auto"
                  onClick={() => this.setState({ isOpenDialogDelete: true })}
                >
                  <FormattedMessage id="process > button delete" />
                </Button>
              </PrivilegedComponent>
            </Col>
          </Row>
        )}
        {/* delete modal */}
        <ConfirmationModal
          isOpen={this.state.isOpenDialogDelete}
          onToggle={() =>
            this.setState({
              isOpenDialogDelete: !this.state.isOpenDialogDelete
            })
          }
          onCancel={() => this.setState({ isOpenDialogDelete: false })}
          description={
            <FormattedMessage id="process > description to delete this spot" />
          }
          className="modal-delete"
          onConfirm={() => {
            this.props.deleteProcess(process.key, response => {
              this.setState({ isOpenDialogDelete: false });
              this.setState({ isOpenDialogSubmitted: true });
              setTimeout(() => {
                this.setState({ isOpenDialogSubmitted: false });
                if (prevOrNextSpot)
                  history.push(
                    "/processes/view/" + template.key + "/" + prevOrNextSpot
                  );
                else history.push("/");
              }, 2000);
            });
          }}
          isCloseOutside={false}
        />
        {/* submitted modal */}
        <ConfirmationModal
          isOpen={this.state.isOpenDialogSubmitted}
          onToggle={() =>
            this.setState({
              isOpenDialogSubmitted: !this.state.isOpenDialogSubmitted
            })
          }
          onCancel={() => this.setState({ isOpenDialogSubmitted: false })}
          state="success"
          title={<FormattedMessage id={`process > title deleted`} />}
        />
      </div>
    );
  }

  render() {
    let {
      process
      // template,
      // user,
      // step
      // valueChanged,
      // validationState,
      // fileUploaded,
      // fileDeleted,
      // filesUploaded,
      // filesDeleted,
      // checkboxGroupChanged,
      // checkboxChanged,
      // getRoleCandidates,
      // spotsChanged
    } = this.props;

    if (!process.data.fields || Object.keys(process.data.fields).length === 0)
      return null;

    let form = null;
    if (this.props.mode === "edit") {
      form = this.renderForm();
    } else {
      form = this.renderView();
    }
    return <span className="orderForm">{form}</span>;
  }
}

export default PromotionDetails;
