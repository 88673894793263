import React from "react";
import PropTypes from "prop-types";
import { Label } from "reactstrap";
import { get } from "lodash";
import classnames from "classnames";
import { components } from "react-select";
import Async from 'react-select/lib/Async';
import bn from "utils/bemnames";
import TextInput from "components/TextInput";
import { injectIntl } from "react-intl";
import { CaretIcon, CloseSmallIcon } from "components/CustomIcons";
const bem = bn.create("dropdown");

class AsyncDropdown extends React.Component {

  static propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    error: PropTypes.string,
    name: PropTypes.string.isRequired,
    labelProps: PropTypes.object,
  }; 

static defaultProps = {
    labelProps: {},
    onChange: () => {},
    loadOptions: () => {},
    name: "",
    cacheOptions : true,
  };

  renderSelectComponent() {
    const {
      name,
      loadOptions,
      ...rest
    } = this.props;
    let configComponents = {}
    const DropdownIndicator = props => {
      return (
        components.DropdownIndicator && (
          <components.DropdownIndicator {...props}>
            <CaretIcon />
          </components.DropdownIndicator>
        )
      );
    };
    configComponents.DropdownIndicator = DropdownIndicator;
    const ClearIndicator = props => {
      return (
        components.ClearIndicator && (
          <components.ClearIndicator {...props}>
            <CloseSmallIcon />
          </components.ClearIndicator>
        )
      )
    }
    configComponents.ClearIndicator = ClearIndicator;
    const MultiValueRemove = props => {
      return (
        components.MultiValueRemove && (
          <components.MultiValueRemove {...props}>
            <CloseSmallIcon />
          </components.MultiValueRemove>
        )
      )
    }
    configComponents.MultiValueRemove = MultiValueRemove;
    return (
      <Async
        cacheOptions
        name={name}
        loadOptions={loadOptions}
        {...rest}
        components={configComponents}
        classNamePrefix="react-select"
        className={classnames("react-select-container", this.props.className && this.props.className.toString(), {
          'react-select-menu-open': rest.isMenuOpen
        })}
        onMenuOpen={() => rest.setIsMenuOpen(true)}
        onMenuClose={() => rest.setIsMenuOpen(false)}
      />
    );
  }

  render() {
    const {
      label,
      error,
      name,
      labelProps,
      disabled,
      value,
      intl
    } = this.props;

    if (disabled) {

      let valueInput = "";

      if (value.label) {
        valueInput =
          typeof value.label === "string"
            ? value.label
            : intl.formatMessage({
                id: `${get(value, "label.props.id")}`
              });
      }

      return <TextInput disabled={true} label={label} value={valueInput} />;
    }
    return (
      <div className={this.props.containerClass || ""}>
        {!!label && (
          <Label for={name} {...labelProps}>
            {label}
          </Label>
        )}
        {this.renderSelectComponent()}
        {!!error && <span className="text-danger">{error}</span>}
      </div>
    );
  }
}

export default injectIntl(AsyncDropdown);
