import React from "react";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import {
  get,
  toLower,
  indexOf,
  includes,
  map,
  isEmpty,
  intersection,
} from "lodash";
import { FaSortDesc, FaSortAsc } from "react-icons/lib/fa";
import { Table } from "reactstrap";
import NoItemsFound from "components/NoItemsFound";
import useInfiniteScroll from "utils/useInfiniteScroll";
import { countdownWithTimezone } from "utils/helpers";
import { default_templates } from "utils/defaults";
import { getSortUserName } from "utils/helpers";
import moment from "moment";
const CommentAvatar = (props) => {
  const { bem, user, isShowAvatar } = props;
  return (
    <div className={bem.e("comment-display")}>
      <div
        className={classnames(bem.e("comment-avatar"), {
          "avatar-sort-name": !isShowAvatar,
        })}
      >
        {isShowAvatar ? (
          <img src={user.avatar_url} alt={user.name} />
        ) : (
          <span>{getSortUserName(get(user, "name", ""))}</span>
        )}
      </div>
    </div>
  );
};
const View = ({
  actionSort,
  sort,
  processList,
  bem,
  onClickRow,
  hasMoreItems,
  loadItems,
  isLoading,
  user,
  dateFormatByServer,
}) => {
  const commentDisplay = (process) => {
    const lastAction = get(process, "_source.last_action");
    if (!lastAction) return null;
    if (get(lastAction, "m_type") === "chat") {
      const commenter = get(lastAction, "from", {});
      const isShowAvatar =
        commenter.avatar_url &&
        indexOf(commenter.avatar_url, "avatar_white_circle") === -1;
      return (
        commenter.id !== user.id &&
        (includes(get(lastAction, "tagged", []), user.id) ||
          !isEmpty(
            intersection(
              get(lastAction, "tagged", []),
              map(user.has_access_from_users, (user) => user.id)
            )
          )) && (
          <CommentAvatar
            isShowAvatar={isShowAvatar}
            bem={bem}
            user={commenter}
          />
        )
      );
    }
    return null;
  };
  const titleDisplay = (process) => {
    return (
      <React.Fragment>
        {process._source.order_title}
        <span className={"small"}>
          {get(process, "_source.for_marketing_channel.name", "-")}
        </span>
      </React.Fragment>
    );
  };

  const dueDateDisplay = (process) => {
    const dueDate = get(process, `_source.due_date`, null);
    const timezone = get(process, "_source.timezone", null);

    if (!dueDate) return "";
    const timeData = countdownWithTimezone(dueDate, timezone);
    if (!timeData) return null;
    const { isNegative, days, hours } = timeData;
    const isHighlight = days === 0 && !isNegative && hours <= 24;
    return (
      <span
        className={classnames({
          [bem.e("highlight-deadline-text")]: isHighlight,
        })}
      >
        {moment(dueDate, "YYYY-MM-DD").format(dateFormatByServer)}
      </span>
    );
  };

  const statusDisplay = (process) => {
    const processStep = get(process, "_source.process_step", "draft");
    let row_template = default_templates.find(
      (t) => t._id === get(process, "_source.template_key", "digital")
    );
    let step = row_template._source.steps[processStep];
    let title = <FormattedMessage id={"process > title draft"} />;
    if (get(step, "title")) {
      title = get(step, "title");
      if (step.key === "order_details") {
        if (get(process, "_source.order_details_status") === "draft")
          title = "draft";
      }
    }
    let color = "#9AA4CA";
    if (title !== "draft") {
      color = get(step, "caption_color", "#9AA4CA");
    }
    switch (get(process, "_source.process_step")) {
      case "draft":
        break;
      case "order_details":
        if (get(process, "_source.order_details_status") === "rejected")
          title = "CHANGE REQUEST";
        break;
      case "approve_order":
        title = "APPROVAL";
        break;
      case "design":
        title = "PRODUCTION";
        if (get(process, "_source.design_status") === "rejected") {
          title = "CHANGE REQUEST";
          color = "#E40061";
        }
        break;
      case "review_media":
        title = "REVIEW";
        break;
      case "traffic":
        title = "TRAFFIC";
        break;
      case "report":
        title = "REPORT";
        break;
      default:
        title = "ONLINE";
        color = "#0A53C0";
        break;
    }
    return (
      <span
        className={classnames(
          bem.e("order-status"),
          bem.e(
            `order-status-${
              title.replace ? toLower(title.replace(/ /g, "-")) : ""
            }`
          )
        )}
        style={{ color }}
      >
        {title}
      </span>
    );
  };

  const renderEmpty = () => {
    return <NoItemsFound />;
  };
  useInfiniteScroll({
    useWindow: false,
    elementId: "cr-app-main",
    onLoadMore: loadItems,
    shouldLoadMore: hasMoreItems,
  });
  return (
    <div className={bem.e("table-wrapper")}>
      <Table className={classnames(bem.e("table"), "produced_digitals")}>
        <thead>
          <tr>
            <th
              onClick={() => actionSort("client_name")}
              className={classnames(
                "can-click client_name",
                "col-table-15",
                bem.e("table-first-head")
              )}
            >
              <div className={bem.e("item-sortable")}>
                <FormattedMessage id="process > Client" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "client_name",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "client_name desc",
                    })}
                  />
                </div>
              </div>
            </th>
            <th
              onClick={() => actionSort("order_title")}
              className="can-click col-table-25"
            >
              <div className={bem.e("item-sortable")}>
                <FormattedMessage id="process > Title" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_title",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_title desc",
                    })}
                  />
                </div>
              </div>
            </th>
            <th className="col-table-15">
              <FormattedMessage id="process > comments" />
            </th>
            <th
              onClick={() => actionSort("order_due_date")}
              className="can-click align-middle text-center col-table-10"
            >
              <div className={bem.e("item-sortable")}>
                <FormattedMessage id="process > field due date" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_due_date",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "due_date desc",
                    })}
                  />
                </div>
              </div>
            </th>
            <th
              onClick={() => actionSort("order_start_date")}
              className="can-click align-middle text-center col-table-10"
            >
              <div className={bem.e("item-sortable")}>
                <FormattedMessage id="process > Deadline" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_start_date",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        sort === "order_start_date desc",
                    })}
                  />
                </div>
              </div>
            </th>
            <th
              onClick={() => actionSort("process_step")}
              className="can-click align-middle text-center col-table-15 align-bottom"
            >
              <div className={bem.e("item-sortable")}>
                <FormattedMessage id="process > Status" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "process_step",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "process_step desc",
                    })}
                  />
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {processList.length === 0 && !isLoading ? (
            <tr>
              <td colSpan="7">{renderEmpty()}</td>
            </tr>
          ) : (
            processList.map((process) => {
              if (!process._source.template_key) return null;
              let title = titleDisplay(process);
              return (
                <tr key={process._id} onClick={() => onClickRow(process)}>
                  {/* client */}
                  <td
                    className={classnames(
                      "align-left col-table-15 align-middle",
                      bem.e("client-name"),
                      bem.e("first-col")
                    )}
                  >
                    <span className={bem.e("td-value")}>
                      {process._source.client_name}
                    </span>
                  </td>
                  {/* title */}
                  <td
                    className={classnames(
                      "align-middle col-table-25",
                      bem.e("order-title")
                    )}
                  >
                    <span className={bem.e("td-value")}>{title}</span>
                  </td>
                  {/* comment */}
                  <td className={classnames("align-middle col-table-15")}>
                    <div
                      className={classnames("align-middle", bem.e("comment"))}
                    >
                      <span className={bem.e("mentions")}>
                        {commentDisplay(process)}
                      </span>
                    </div>
                  </td>
                  {/* due date */}
                  <td className="align-middle text-center col-table-10">
                    <span className={bem.e("td-value")}>
                      {dueDateDisplay(process)}
                    </span>
                  </td>
                  {/* start date */}
                  <td className="align-middle text-center col-table-10">
                    <span className={bem.e("td-value")}>
                      {process._source.order_start_date
                        ? moment(process._source.order_start_date).format(
                            dateFormatByServer
                          )
                        : ""}
                    </span>
                  </td>
                  {/* status */}
                  <td className="align-middle text-center col-table-15">
                    <span className={bem.e("td-value")}>
                      {statusDisplay(process)}
                    </span>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </Table>
    </div>
  );
};
export default View;
