import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import bn from "utils/bemnames";
import {
  get,
  find,
  xorBy,
  forEach,
  includes,
  findIndex,
  filter,
  map,
  mapValues,
  replace,
  values,
  isEmpty,
} from "lodash";
import { Row, Col, FormGroup, Label, ListGroup, Button } from "reactstrap";
import * as Yup from "yup";
import { Formik } from "formik";
import { PRIVILEGES } from "utils/constants";
import { validatePrivileges } from "utils/helpers";
import classnames from "classnames";
import UploadFiles from "components/UploadFiles";
import Dropdown from "components/Dropdown";
import HandleAssets from "components/HandleAssets";
import SimpleLineText from "components/FormBuilder/SimpleLineText";
import EmailAddress from "components/FormBuilder/EmailAddress";
import PhoneNumber from "components/FormBuilder/PhoneNumber";
import URLField from "components/FormBuilder/URL";
import Media from "components/FormBuilder/Media";
import BasicFileUpload from "components/FormBuilder/BasicFileUpload";
import NumberOfImpression from "components/FormBuilder/NumberOfImpression";
import StreetAddress from "components/FormBuilder/StreetAddress";
import GeoTargeting from "components/FormBuilder/GeoTargeting";
import { default as DropdownFormBuilder } from "components/FormBuilder/Dropdown";
import { default as CheckboxFormBuilder } from "components/FormBuilder/Checkbox";
import { default as RadioFormBuilder } from "components/FormBuilder/Radio";
import Demographics from "components/FormBuilder/Demographics";
import { first, uniqBy } from "lodash";
import TextArea from "components/FormBuilder/TextArea";
import TextareaAutoSize from "components/TextareaAutoSize";
import { createYupSchema } from "components/ChannelForm/yupSchemaCreator";
import ChannelManagerDropdown from "components/ChannelManagerDropdown";
import { FormattedMessage } from "react-intl";
import { Asterisk } from "components/Elements";
import TextInput from "components/TextInput";
import { labelToNameField } from "utils/helpers";
import { IconsConfig } from "components/ChannelManager/IconSelector";
import DateSelector from "components/FormBuilder/DateSelector";
import Checkbox from "components/Checkbox";
import FlightScheduleModal, {
  generateDescription,
} from "components/FlightScheduleModal";
import {
  RecurrenceIcon,
  UpIcon,
  DownIcon,
  DeleteIcon,
} from "components/CustomIcons";
import { getFormFieldsWithConditionLogic } from "utils/helpers";
import AutoSave from "./formikAutoSave";
import ConfirmationModal from "components/ConfirmationModal";
const bem = bn.create("custom-form");

const getFieldsBySelectedOtherControlFields = (formFields, values) => {
  let fields = [];
  forEach(formFields, (field) => {
    const fieldName = labelToNameField(field.label);
    let val = values[fieldName];
    const fieldValue = val && val.value ? val.value : val;
    if (fieldValue) {
      forEach(field.options, (fieldOptionItem) => {
        const rule = fieldOptionItem.rule;
        if (rule) {
          switch (rule.is) {
            case "selected":
              // selected -> push to fields for show
              if (Array.isArray(fieldValue)) {
                let indexValue = findIndex(
                  fieldValue,
                  (itemFieldValue) =>
                    itemFieldValue.value === fieldOptionItem.value
                );
                if (indexValue !== -1) {
                  fields = [...fields, ...rule.options];
                }
              } else {
                if (fieldValue === fieldOptionItem.value) {
                  fields = [...fields, ...rule.options];
                }
              }

              break;
            case "not selected":
              // not selected -> push to fields for show
              if (Array.isArray(fieldValue)) {
                let indexValue = findIndex(
                  fieldValue,
                  (itemFieldValue) =>
                    itemFieldValue.value === fieldOptionItem.value
                );
                if (indexValue === -1) {
                  fields = [...fields, ...rule.options];
                } else {
                  const valueRuleOptions = map(
                    rule.options,
                    (itemOption) => itemOption.value
                  );
                  fields = filter(
                    fields,
                    (itemField) => !includes(valueRuleOptions, itemField.value)
                  );
                }
              } else {
                if (fieldValue !== fieldOptionItem.value) {
                  if (fieldValue === fieldOptionItem.value) {
                    fields = [...fields, ...rule.options];
                  }
                }
              }

              break;
            default:
              break;
          }
        }
      });
    }
  });
  return fields;
};
const renderCustomFields = (
  formikRef,
  onSubmitForm,
  fields,
  formProps,
  allFields,
  initialValues,
  props,
  whiteList = [],
  assignedList = []
) => {
  const values = formProps.values;
  const { formFields, setFormFields } = props;

  if (!fields || fields.length === 0) return null;
  if (get(props, "onlyDesign")) {
    const whiteListKeys = whiteList.map((item) => item.key);
    fields = fields.filter((fieldItem) =>
      includes(whiteListKeys, fieldItem.key)
    );
  }

  let labels = [];
  fields = filter(fields, (fieldItem) => {
    if (!includes(labels, fieldItem.label.trim().toLowerCase())) {
      labels.push(fieldItem.label.trim().toLowerCase());
      return fieldItem;
    }
  });

  if (assignedList.length > 0) {
    fields = [...fields, ...assignedList];
  }
  fields = uniqBy(fields, "uuid");
  const fieldsOrdered = allFields.filter((itemField) => {
    return find(fields, (f) => f.uuid === itemField.uuid);
  });
  const fieldsEle = fieldsOrdered.map((field, index) => {
    const { key } = field;
    const fieldName = labelToNameField(field.label);

    const errors = mapValues(get(formProps, `errors`, {}), (value) => {
      if (key === "street_address" || "demographics") {
        return value;
      } else {
        return replace(value, new RegExp("_", "g"), " ");
      }
    });
    const fieldProps = {
      key: index,
      field,
      value: values[fieldName],
      errors,
      touched: get(formProps, `touched`, {}),
      onChange: (value) => {
        const originalSelectedValue = value;
        // if (value && value.value) value = value.value;
        if (field.key === "checkbox") {
          let currentValue = filter(
            values[fieldName],
            (item) => item && item.value
          );
          value = xorBy(currentValue, [value], "value");
        }
        if (field.key === "dropdown" || field.key === "radio") {
          value = get(value, "value", "");
        }
        // check conditional logic
        if (field.is_condition_logic) {
          const fieldOptions = get(field, "options", []);
          let appendIndex = findIndex(
            formFields,
            (formFieldItem) => formFieldItem.label === field.label
          );
          let newFormFields = formFields;
          let removedFields = [];
          let addedFields = [];
          forEach(fieldOptions, (fieldOptionItem) => {
            let isOptionSelected = includes(value, fieldOptionItem.value);
            if (field.key === "radio" || field.key === "dropdown") {
              // priority to check key first
              isOptionSelected = originalSelectedValue.key
                ? originalSelectedValue.key === fieldOptionItem.key
                : value === fieldOptionItem.value;
            }
            if (field.key === "checkbox") {
              isOptionSelected =
                findIndex(value, (itemValue) =>
                  // priority to check key first
                  itemValue.key
                    ? itemValue.key === fieldOptionItem.key
                    : itemValue.value === fieldOptionItem.value
                ) !== -1;
            }
            const ruleForOption = get(fieldOptionItem, "rule");

            if (!ruleForOption) return;
            switch (ruleForOption.is) {
              case "selected":
                if (isOptionSelected) {
                  // append
                  forEach(ruleForOption.options, (ruleOption) => {
                    const isExistsRuleOptionInFormFields = findIndex(
                      newFormFields,
                      (formFieldItem) =>
                        formFieldItem.label === ruleOption.label
                    );
                    let fieldToAdd = allFields.find(
                      (f) => f.label === ruleOption.label
                    );
                    if (fieldToAdd) {
                      if (!fieldToAdd.addedBy) fieldToAdd.addedBy = [];
                      if (
                        fieldToAdd.addedBy.findIndex(
                          (addedByItem) => addedByItem.label === field.label
                        ) === -1
                      ) {
                        fieldToAdd.addedBy.push({
                          label: field.label,
                        });
                      }

                      if (isExistsRuleOptionInFormFields === -1) {
                        appendIndex++;
                        newFormFields.splice(appendIndex, 0, fieldToAdd);
                        addedFields.push(fieldToAdd);
                      }
                    } else {
                      debugger;
                    }
                  });
                } else {
                  // remove
                  forEach(ruleForOption.options, (ruleOption) => {
                    const isExistsRuleOptionInFormFields = findIndex(
                      newFormFields,
                      (formFieldItem) =>
                        formFieldItem.label === ruleOption.label
                    );
                    if (isExistsRuleOptionInFormFields !== -1) {
                      let fieldToRemove = allFields.find(
                        (f) => f.label === ruleOption.label
                      );
                      const isControlledCurrentSelected = find(
                        get(value, "rule.options", []),
                        (item) => item.label === fieldToRemove.label
                      );
                      // we are ensure this field do not controlled by current option
                      if (!isControlledCurrentSelected) {
                        removedFields.push(fieldToRemove);
                        newFormFields.splice(isExistsRuleOptionInFormFields, 1);
                      }
                    }
                  });
                }
                break;
              case "not selected":
                if (!isOptionSelected && value) {
                  // append
                  forEach(ruleForOption.options, (ruleOption) => {
                    const isExistsRuleOptionInFormFields = findIndex(
                      newFormFields,
                      (formFieldItem) =>
                        formFieldItem.label === ruleOption.label
                    );
                    let fieldToAdd = allFields.find(
                      (f) => f.label === ruleOption.label
                    );
                    if (fieldToAdd) {
                      if (!fieldToAdd.addedBy) fieldToAdd.addedBy = [];
                      if (fieldToAdd.addedBy.indexOf(field) === -1)
                        fieldToAdd.addedBy.push({
                          label: field.label,
                        });
                      if (isExistsRuleOptionInFormFields === -1) {
                        appendIndex++;
                        newFormFields.splice(appendIndex, 0, fieldToAdd);
                        addedFields.push(fieldToAdd);
                      }
                    } else {
                      debugger;
                    }
                  });
                } else {
                  // remove
                  forEach(ruleForOption.options, (ruleOption) => {
                    const isExistsRuleOptionInFormFields = findIndex(
                      newFormFields,
                      (formFieldItem) =>
                        formFieldItem.label === ruleOption.label
                    );
                    if (isExistsRuleOptionInFormFields !== -1) {
                      let fieldToRemove = allFields.find(
                        (f) => f.label === ruleOption.label
                      );
                      removedFields.push(fieldToRemove);
                      newFormFields.splice(isExistsRuleOptionInFormFields, 1);
                    }
                  });
                }
                break;
              default:
                break;
            }
          });

          // reset removed fields to default values
          let removedFieldsLabels = [];
          removedFields.map((f) => {
            removedFieldsLabels.push(f.label);
            const addedFieldName = labelToNameField(f.label);
            formProps.setFieldValue(
              addedFieldName,
              null //initialValues[addedFieldName]
            );
            formProps.setFieldTouched(addedFieldName, false);
          });

          // remove fields that were added by fields
          // that are now removed

          let subFieldsToKeep = newFormFields.filter(
            (f) =>
              !f.addedBy ||
              f.addedBy.filter((a) => removedFieldsLabels.indexOf(a.label) > -1)
                .length === 0
          );
          let subFieldsToRemove = newFormFields.filter(
            (f) => subFieldsToKeep.indexOf(f) === -1
          );

          // reset removed sub fields to default values
          subFieldsToRemove.map((f) => {
            const addedFieldName = labelToNameField(f.label);
            formProps.setFieldValue(
              addedFieldName,
              initialValues[addedFieldName]
            );
            formProps.setFieldTouched(addedFieldName, false);
          });

          newFormFields = newFormFields.filter(
            (f) => subFieldsToKeep.indexOf(f) > -1
          );
          // keep order fields
          const newFormFieldsOrdered = allFields.filter((itemField) => {
            return find(newFormFields, (f) => f.uuid === itemField.uuid);
          });
          setFormFields(newFormFieldsOrdered);
        }

        formProps.setFieldValue(fieldName, value);
        formProps.setFieldTouched(fieldName);
        let newValues = values;
        // we need to remove old rejected status. When digital producer uploaded new file
        newValues.fields = map(values.fields, (field) => {
          const fieldName = labelToNameField(field.label);
          if (
            field.review_media_status &&
            field.review_media_status === "rejected" &&
            get(formikRef, `current.state.touched.${fieldName}`)
          ) {
            return {
              ...field,
              review_media_status: null,
            };
          }
          return field;
        });
        newValues[fieldName] = value;
        onSubmitForm(newValues);
      },
      isFormBuilder: false,
      disabled: props.disabled,
    };
    switch (key) {
      case "single_line_text":
        return <SimpleLineText {...fieldProps} />;
      case "textarea":
        return <TextArea {...fieldProps} />;
      case "dropdown":
        return <DropdownFormBuilder {...fieldProps} />;
      case "checkbox":
        return <CheckboxFormBuilder {...fieldProps} />;
      case "radio":
        return <RadioFormBuilder {...fieldProps} />;
      case "date_selector":
        return <DateSelector {...fieldProps} />;
      case "email_address":
        return <EmailAddress {...fieldProps} />;
      case "phone_number":
        return <PhoneNumber {...fieldProps} />;
      case "url":
        return <URLField {...fieldProps} />;
      case "media_for_review_approval":
        return (
          <Media
            {...fieldProps}
            setIsDirty={props.setIsDirty}
            isShowFileViewStatus={get(props, "onlyDesign")}
          />
        );
      case "basic_file_upload":
        return (
          <BasicFileUpload {...fieldProps} setIsDirty={props.setIsDirty} />
        );
      case "number_of_impression":
        return <NumberOfImpression {...fieldProps} />;
      case "demographics":
        return <Demographics {...fieldProps} />;
      case "street_address":
        return <StreetAddress {...fieldProps} />;
      case "geotargeting":
        return <GeoTargeting {...fieldProps} />;
      default:
        break;
    }
  });
  return fieldsEle;
};

const renderCustomFieldsView = (
  channel,
  blackList = [],
  whiteList = [],
  config = {}
) => {
  let newFields = getFormFieldsWithConditionLogic(channel);
  if (!newFields.length) return null;
  if (blackList.length > 0) {
    const blackListKeys = blackList.map((item) => item.key);
    newFields = newFields.filter(
      (field) => !includes(blackListKeys, field.key)
    );
  }
  if (whiteList.length > 0) {
    const whiteListKeys = whiteList.map((item) => item.key);
    newFields = newFields.filter((field) => includes(whiteListKeys, field.key));
  }
  return newFields.map((field, index) => {
    const { key } = field;
    const fieldName = labelToNameField(field.label);
    const fieldProps = {
      disabled: true,
      key: index,
      field,
      value: get(channel, fieldName, ""),
      isFormBuilder: false,
      isView: true,
    };
    switch (key) {
      case "single_line_text":
        return <SimpleLineText {...fieldProps} />;
      case "textarea":
        return <TextArea {...fieldProps} />;
      case "dropdown":
        return <DropdownFormBuilder {...fieldProps} />;
      case "checkbox":
        return <CheckboxFormBuilder {...fieldProps} />;
      case "radio":
        return <RadioFormBuilder {...fieldProps} />;
      case "date_selector":
        return <DateSelector {...fieldProps} />;
      case "email_address":
        return <EmailAddress {...fieldProps} />;
      case "phone_number":
        return <PhoneNumber {...fieldProps} />;
      case "url":
        return <URLField {...fieldProps} />;
      case "media_for_review_approval":
        return (
          <Media {...fieldProps} isShowFileViewStatus={config.onlyDesign} />
        );
      case "basic_file_upload":
        return <BasicFileUpload {...fieldProps} />;
      case "number_of_impression":
        return <NumberOfImpression {...fieldProps} />;
      case "demographics":
        return <Demographics {...fieldProps} />;
      case "street_address":
        return <StreetAddress {...fieldProps} />;
      case "geotargeting":
        return <GeoTargeting {...fieldProps} />;
      default:
        break;
    }
  });
};
const getProductionOptions = (intl, options) => {
  const { isShowRequiresProduction } = options;
  let productionOptions = [
    {
      label: intl.formatMessage({
        id: "channel manager > none",
      }),
      value: "None",
    },
    {
      label: intl.formatMessage({
        id: "channel manager > production provided",
      }),
      value: "Production Provided",
    },
  ];
  if (isShowRequiresProduction) {
    productionOptions = [
      {
        label: intl.formatMessage({
          id: "channel manager > production needed",
        }),
        value: "Production Needed",
      },
      ...productionOptions,
    ];
  }
  return productionOptions;
};

const getUsersByPrivilege = (users, privilege, stations) => {
  if (isEmpty(stations)) {
    return [];
  }

  let newUsers = filter(users, (user) => {
    let userHasPrivilege = true;

    forEach(stations, (station) => {
      let selectedUserStation = find(user.stations, (userStation) => {
        return station === userStation.station_key;
      });

      if (
        !selectedUserStation ||
        !includes(values(selectedUserStation.user_privileges), privilege)
      ) {
        userHasPrivilege = false;
      }
    });

    return userHasPrivilege;
  });

  return newUsers;
};

const CustomForm = ({
  channel,
  isView,
  onSubmitForm,
  intl,
  formFields,
  setFormFields,
  isOpenFlightSchedule,
  setIsOpenFlightSchedule,
  isShowAdjustChannelSchedule,
  isShowRequiresPlacement,
  isCollapse,
  setIsCollapse,
  isShowCollapse,
  isShowStatus,
  privileges,
  users,
  onlyDesign,
  isPreview,
  process,
  isDigitalOrder,
  setIsDirty,
  isDirty,
  auth,
  validationState,
  ...rest
}) => {
  const channelIcon = get(channel, "channel_icon");
  let ChannelIcon = IconsConfig[channelIcon]
    ? IconsConfig[channelIcon]
    : IconsConfig["default"];
  const isChannelHasMediaForReviewField =
    filter(
      channel.fields || [],
      (field) => field.key === "media_for_review_approval"
    ).length > 0;
  const productionOptions = getProductionOptions(intl, {
    isShowRequiresProduction: isChannelHasMediaForReviewField,
  });
  // Sales/Account Executive, digital producer should appear
  const digitalProducerRoles = validatePrivileges({
    requires: {
      or: [
        PRIVILEGES.ADD_DIGITAL,
        PRIVILEGES.EDIT_DIGITAL,
        PRIVILEGES.EDIT_ALL_DIGITAL_ORDERS,
      ],
    },
    privileges,
    user: auth.user,
  });
  const isShowDigitalProducer =
    digitalProducerRoles && isChannelHasMediaForReviewField;

  formFields = getFormFieldsWithConditionLogic(channel);

  let _allFields = [];

  forEach(formFields, (field) => {
    if (isEmpty(field.addedBy)) {
      _allFields.push(field);

      forEach(formFields, (_field) => {
        forEach(_field.addedBy, (_addedBy) => {
          if (_addedBy.label === field.label) {
            _allFields.push(_field);
          }
        });
      });
    }
  });

  formFields = _allFields;

  const renderForm = () => {
    const userDigitalProducers = getUsersByPrivilege(
      users,
      "add_design",
      get(process, "data.fields.contract_stations.value", [])
    );
    const digitalProducerOptions = userDigitalProducers.map((user) => ({
      label: user.name,
      value: user.id,
    }));
    const primaryChannelManager = find(
      channel.channel_managers,
      (manager) => manager.primary
    );
    let initialValues = {
      channel_managers: get(channel, "channel_managers", []),
      id: get(channel, "id", null),
      name: get(channel, "name", ""),
      notes: "",
      assets: [],
      production: "",
      schedule: "",
      channel_manager:
        isDigitalOrder && !channel.channel_manager
          ? get(primaryChannelManager, "value", "")
          : "",
      channel_manager_name:
        isDigitalOrder && !channel.channel_manager
          ? get(primaryChannelManager, "label", "")
          : "",
      is_required_placement: false,
      assets_link: "",
      digital_producer:
        isShowDigitalProducer &&
        digitalProducerOptions.length > 0 &&
        !channel.digital_producer
          ? first(digitalProducerOptions).value
          : "",
      is_show_digital_producer: isShowDigitalProducer,
      is_digital_order: isDigitalOrder,
    };
    let defaultValidate = {
      is_digital_order: Yup.bool().nullable(),
      is_show_digital_producer: Yup.bool().nullable(),
      channel_managers: Yup.array().required(
        intl.formatMessage({
          id: "validate > channel manager is required",
        })
      ),
      production: Yup.string()
        .required(
          intl.formatMessage({
            id: "validate > production is required",
          })
        )
        .nullable(),
      assets_link: Yup.string()
        .url(
          intl.formatMessage({
            id: "validate > url invalid",
          })
        )
        .nullable(),
      digital_producer: Yup.string()
        .test(
          "validateDigitalProducer",
          intl.formatMessage({
            id: "validate > digital producer is required",
          }),
          function(value) {
            const productionValue = this.parent.production;
            const isShow =
              this.parent.is_show_digital_producer &&
              (productionValue === "Requires Production" ||
                productionValue === "Production Needed");
            if (isShow && !value) return false;
            return true;
          }
        )
        .nullable(),
      channel_manager: Yup.string().when("is_digital_order", {
        is: true,
        then: Yup.string()
          .required(
            intl.formatMessage({
              id: "validate > channel manager is required",
            })
          )
          .nullable(),
        otherwise: Yup.string().nullable(),
      }),
    };
    if (channel) {
      initialValues = {
        ...initialValues,
        ...channel,
      };
    }
    //init all fields for first load
    get(channel, "fields", []).forEach((field) => {
      const { key } = field;
      const fieldName = labelToNameField(field.label);
      let defaultValue = get(channel, fieldName, "");
      switch (key) {
        case "checkbox":
          if (!defaultValue) {
            defaultValue = [];
            const defaultOption = find(
              field.options,
              (option) => option.is_default
            );
            if (defaultOption) {
              defaultValue = [
                {
                  value: defaultOption.value,
                  label: defaultOption.label,
                },
              ];
            }
          }
          break;
        case "dropdown":
        case "radio":
          if (!defaultValue) {
            const defaultOption = find(
              field.options,
              (option) => option.is_default
            );
            if (defaultOption) {
              defaultValue = defaultOption.value;
            }
          }
          break;
        case "street_address":
          if (!defaultValue) {
            defaultValue = {
              address_1: get(defaultValue, "address_1", ""),
              address_2: get(defaultValue, "address_2", ""),
              city: get(defaultValue, "city", ""),
              state_abb: get(defaultValue, "state_abb", ""),
              zipcode: get(defaultValue, "zipcode", ""),
              // country: get(defaultValue, "country", ""),
            };
          }

          break;
        case "demographics":
          if (!defaultValue) {
            defaultValue = {
              age_range: {
                from: get(defaultValue, "age_range.from", ""),
                to: get(defaultValue, "age_range.to", ""),
              },
              education: get(defaultValue, "education", ""),
              household_income: get(defaultValue, "household_income", ""),
              gender: get(defaultValue, "gender", ""),
            };
          }

          break;
        default:
          // if(isNull(defaultValue)) defaultValue = "";
          break;
      }
      initialValues[labelToNameField(field.label)] = defaultValue;
    });

    //const channelManagersOptions = rest.getChannelManagers();
    const channelManagersOptions = get(channel, "channel_managers", []);

    let fieldsSaved = getFieldsBySelectedOtherControlFields(
      formFields,
      initialValues
    );

    const yepSchema = [...formFields, ...fieldsSaved].reduce(
      (schema, config) => createYupSchema(schema, config, intl),
      {}
    );

    const formikRef = useRef(null);
    useEffect(() => {
      // reset the formik for reload the channel fields
      if (formikRef.current) {
        // formikRef.current.handleReset();
      }
    }, [channel.id]);
    if (onlyDesign) {
      const digitalProducer = find(
        users,
        (item) => item.id === get(channel, "digital_producer", "")
      );
      const fieldsForDesign = get(channel, "fields", []).filter((field) => {
        return includes(["media_for_review_approval"], field.key);
      });
      const channelManager = find(
        channelManagersOptions,
        (item) => item.value === get(channel, "channel_manager", "")
      );
      let isRejected =
        filter(channel.fields, (field) => {
          if (field.key === "media_for_review_approval") {
            return find(
              field.value_history,
              (item) => item.review_media_status === "rejected"
            );
          }
          return false;
        }).length > 0;
      return (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            ...yepSchema,
            ...defaultValidate,
          })}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={() => {}}
          ref={formikRef}
        >
          {(formProps) => {
            const values = formProps.values;
            return (
              <div className={bem.e("form")}>
                <Row>
                  {/* left column */}
                  <Col xs={6}>
                    <div className={bem.e("custom-fields")}>
                      <div className={bem.e("custom-fields-container")}>
                        <div className={bem.e("design-view-fields")}>
                          {renderCustomFieldsView(channel, fieldsForDesign)}
                        </div>
                        <h2 className={bem.e("creative-title")}>
                          <FormattedMessage id="channel manager > creative for approval" />
                          :
                          {isRejected && (
                            <span className={bem.e("changes-requested")}>
                              <FormattedMessage id="channel manager > changes requested" />
                            </span>
                          )}
                        </h2>
                        {renderCustomFields(
                          formikRef,
                          onSubmitForm,
                          values.form_fields,
                          formProps,
                          get(channel, "fields", []),
                          initialValues,
                          {
                            formFields: values.form_fields,
                            setFormFields: (data) =>
                              formProps.setFieldValue("form_fields", data),
                            onlyDesign,
                            setIsDirty,
                          },
                          fieldsForDesign
                        )}
                      </div>
                    </div>
                  </Col>
                  {/* right column */}
                  <Col xs={6} className={bem.e("right-column")}>
                    <FormGroup>
                      <Label className={bem.e("view-label")}>
                        <FormattedMessage id="channel manager > channel manager" />
                      </Label>
                      <div className={"order-view-value"}>
                        {get(channelManager, "label", "") ? (
                          get(channelManager, "label", "")
                        ) : (
                          <FormattedMessage id="process > none" />
                        )}
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <Label className={bem.e("view-label")}>
                        <FormattedMessage id="channel manager > production" />
                      </Label>
                      <div className={"order-view-value"}>
                        {get(channel, "production", "") ? (
                          get(channel, "production", "")
                        ) : (
                          <FormattedMessage id="process > none" />
                        )}
                      </div>
                    </FormGroup>
                    {isShowDigitalProducer &&
                      (channel.production === "Requires Production" ||
                        channel.production === "Production Needed") && (
                        <FormGroup>
                          <Label className={bem.e("view-label")}>
                            <FormattedMessage id="channel manager > digital producer" />
                          </Label>
                          <div className={"order-view-value"}>
                            {get(digitalProducer, "name", "")}
                          </div>
                        </FormGroup>
                      )}

                    <FormGroup>
                      <Label className={bem.e("view-label")}>
                        <FormattedMessage id="channel manager > optional notes" />
                      </Label>
                      <div className={"order-view-value"}>
                        {get(channel, "notes", "") ? (
                          get(channel, "notes", "")
                        ) : (
                          <FormattedMessage id="process > none" />
                        )}
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <Label className={bem.e("view-optional-assets-label")}>
                        <FormattedMessage id="channel manager > optional assets" />
                      </Label>
                      {get(channel, "assets_link", "") ? (
                        <a
                          href={get(channel, "assets_link", "")}
                          alt=""
                          className={bem.e("view-assets-link")}
                        >
                          {get(channel, "assets_link", "")}
                        </a>
                      ) : null}
                      {get(channel, "assets.length") > 0 ? (
                        <HandleAssets
                          files={channel.assets}
                          viewDisabled={false}
                          renamingDisabled={true}
                          renamingHidden={true}
                          deleteDisabled={true}
                          deleteHidden={true}
                          downloadDisabled={false}
                          isView={true}
                        />
                      ) : (
                        <FormattedMessage id="process > none" />
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <AutoSave
                  onSave={(saveData) => {
                    let newValues = saveData;
                    // we need to remove old rejected status. When digital producer uploaded new file
                    newValues.fields = map(saveData.fields, (field) => {
                      const fieldName = labelToNameField(field.label);
                      if (
                        field.review_media_status &&
                        field.review_media_status === "rejected" &&
                        get(formikRef, `current.state.touched.${fieldName}`)
                      ) {
                        return {
                          ...field,
                          review_media_status: null,
                        };
                      }
                      return field;
                    });
                    onSubmitForm(newValues);
                  }}
                  debounce={500}
                  values={values}
                  isDirty={isDirty}
                  setIsDirty={setIsDirty}
                  render={({ isSaving, lastSaved, saveError }) => {
                    return null;
                  }}
                />
              </div>
            );
          }}
        </Formik>
      );
    }
    const isDisabled = false;
    return (
      <Formik
        // enableReinitialize={false} //
        enableReinitialize={!isPreview}
        initialValues={{
          ...initialValues,
          form_fields: formFields, // for preview or render custom form on digital. We will set form_fields to formik. Can't set from state. Because the formik not render if state change
        }}
        ref={formikRef}
        validationSchema={Yup.object().shape({
          ...yepSchema,
          ...defaultValidate,
        })}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={() => {}}
      >
        {(formProps) => {
          const errors = formProps.errors;
          const touched = formProps.touched;
          const values = formProps.values;
          if (isPreview) {
            fieldsSaved = getFieldsBySelectedOtherControlFields(
              values.form_fields,
              values
            );
          }
          return (
            <div className={bem.e("form")}>
              <Row>
                {/* left column */}
                <Col xs={6}>
                  {/* only show for digital order */}
                  {isShowRequiresPlacement && (
                    <div className={bem.e("requires-placement")}>
                      <Checkbox
                        checked={values.is_required_placement}
                        onChange={(value) => {
                          formProps.setFieldValue(
                            "is_required_placement",
                            value
                          );
                          onSubmitForm({
                            ...values,
                            is_required_placement: value,
                          });
                        }}
                        text={intl.formatMessage({
                          id:
                            "channel manager > requires placement confirmation",
                        })}
                      />
                    </div>
                  )}
                  <div className={bem.e("custom-fields")}>
                    <div className={bem.e("custom-fields-container")}>
                      {renderCustomFields(
                        formikRef,
                        onSubmitForm,
                        values.form_fields,
                        formProps,
                        get(channel, "fields", []),
                        initialValues,
                        {
                          formFields: values.form_fields,
                          setFormFields: (data) =>
                            formProps.setFieldValue("form_fields", data),
                          onlyDesign,
                          setIsDirty,
                          disabled: isDisabled,
                        },
                        [],
                        fieldsSaved
                      )}
                    </div>
                  </div>
                </Col>
                {/* right column */}
                <Col xs={6} className={bem.e("right-column")}>
                  <FormGroup>
                    {/* render dropdown select manager for only digital order */}
                    {isDigitalOrder ? (
                      <Dropdown
                        label={
                          <span>
                            <FormattedMessage id="channel manager > channel manager" />
                            <Asterisk>*</Asterisk>
                          </span>
                        }
                        value={
                          find(
                            channelManagersOptions || [],
                            (manager) =>
                              manager.value === values.channel_manager
                          ) || ""
                        }
                        options={channelManagersOptions}
                        onChange={(selectedOption) => {
                          formProps.setFieldValue(
                            "channel_manager",
                            get(selectedOption, "value", "")
                          );
                          formProps.setFieldValue(
                            "channel_manager_name",
                            get(selectedOption, "label", "")
                          );
                          onSubmitForm({
                            ...values,
                            channel_manager: get(selectedOption, "value", ""),
                            channel_manager_name: get(
                              selectedOption,
                              "label",
                              ""
                            ),
                          });
                        }}
                        error={
                          touched.channel_manager && errors.channel_manager
                        }
                      />
                    ) : (
                      <ChannelManagerDropdown
                        label={
                          <span>
                            <FormattedMessage id="channel manager > channel manager" />
                            <Asterisk>*</Asterisk>
                          </span>
                        }
                        isDisabled={isDisabled}
                        value={values.channel_managers}
                        onChange={(value) => {
                          formProps.setFieldValue("channel_managers", value);
                          onSubmitForm({ ...values, channel_managers: value });
                        }}
                        error={
                          touched.channel_managers && errors.channel_managers
                        }
                      />
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Dropdown
                      label={
                        <span>
                          <FormattedMessage id="channel manager > production" />
                          <Asterisk>*</Asterisk>
                        </span>
                      }
                      placeholder={intl.formatMessage({
                        id: "channel manager > select",
                      })}
                      name="production"
                      error={
                        formProps.touched.production &&
                        formProps.errors.production
                      }
                      isDisabled={isDisabled}
                      onChange={(selectedOption) => {
                        const production = selectedOption.value;
                        let digitalProducerValue = "";
                        let digitalProducerName = "";
                        if (
                          production === "Requires Production" ||
                          production === "Production Needed"
                        ) {
                          if (
                            digitalProducerOptions.length > 0 &&
                            !values.digital_producer
                          ) {
                            const digitalProducer = first(
                              digitalProducerOptions
                            );
                            digitalProducerValue = digitalProducer.value || "";
                            digitalProducerName = digitalProducer.label || "";
                          }
                        }
                        formProps.setFieldValue("production", production);
                        formProps.setFieldValue(
                          "digital_producer",
                          digitalProducerValue
                        );

                        formProps.setFieldValue(
                          "digital_producer_name",
                          digitalProducerName
                        );
                        onSubmitForm({
                          ...values,
                          production,
                          digital_producer: digitalProducerValue,
                          digital_producer_name: digitalProducerName,
                        });
                      }}
                      value={find(
                        productionOptions,
                        (item) => item.value === values.production
                      )}
                      options={productionOptions}
                    />
                  </FormGroup>
                  {values.is_show_digital_producer &&
                    (values.production === "Requires Production" ||
                      values.production === "Production Needed") && (
                      <FormGroup>
                        <Dropdown
                          label={
                            <span>
                              <FormattedMessage id="channel manager > digital producer" />
                              <Asterisk>*</Asterisk>
                            </span>
                          }
                          placeholder={intl.formatMessage({
                            id: "channel manager > select",
                          })}
                          name="digital_producer"
                          error={
                            formProps.touched.digital_producer &&
                            formProps.errors.digital_producer
                          }
                          onChange={(selectedOption) => {
                            formProps.setFieldValue(
                              "digital_producer",
                              selectedOption.value
                            );
                            formProps.setFieldValue(
                              "digital_producer_name",
                              selectedOption.label
                            );

                            onSubmitForm({
                              ...values,
                              digital_producer: selectedOption.value,
                              digital_producer_name: selectedOption.label,
                            });
                          }}
                          value={
                            find(
                              digitalProducerOptions,
                              (item) => item.value === values.digital_producer
                            ) || ""
                          }
                          options={digitalProducerOptions}
                        />
                      </FormGroup>
                    )}
                  <FormGroup>
                    <TextareaAutoSize
                      label={
                        <FormattedMessage id="channel manager > optional notes" />
                      }
                      type="textarea"
                      name="notes"
                      placeholder={intl.formatMessage({
                        id: "channel manager > placeholder option notes",
                      })}
                      disabled={isDisabled}
                      value={values.notes || ""}
                      error={touched.notes && errors.notes}
                      onChange={(event) => {
                        const value = get(event, "target.value", "");
                        formProps.setFieldValue("notes", value);
                        onSubmitForm({
                          ...values,
                          notes: value,
                        });
                      }}
                      onBlur={formProps.handleBlur}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>
                      <FormattedMessage id="channel manager > optional assets" />
                    </Label>
                    <TextInput
                      type="text"
                      name="assets_link"
                      placeholder={intl.formatMessage({
                        id: "channel manager > placeholder add link",
                      })}
                      value={values.assets_link}
                      error={touched.assets_link && errors.assets_link}
                      onChange={(event) => {
                        const value = get(event, "target.value", "");
                        formProps.setFieldValue("assets_link", value);
                        onSubmitForm({
                          ...values,
                          assets_link: value,
                        });
                      }}
                      onBlur={formProps.handleBlur}
                    />
                    <HandleAssets
                      files={values.assets}
                      onUploadedFiles={(value) => {
                        formProps.setFieldValue("assets", value);

                        onSubmitForm({ ...values, assets: value });
                      }}
                      onDeleted={(value) => {
                        formProps.setFieldValue("assets", value);

                        onSubmitForm({ ...values, assets: value });
                      }}
                    />
                    <UploadFiles
                      attachText={intl.formatMessage({
                        id: "channel manager > upload assets",
                      })}
                      // mimes
                      disabled={isDisabled}
                      acceptMultiple={true}
                      files={values.assets}
                      isHideFilesDragArea={true}
                      onUploadedFiles={(value) => {
                        formProps.setFieldValue("assets", [
                          ...values.assets,
                          ...value,
                        ]);

                        onSubmitForm({
                          ...values,
                          assets: [...values.assets, ...value],
                        });
                      }}
                      maxFiles={10}
                      onDeleted={(value) => {
                        formProps.setFieldValue("assets", value);

                        onSubmitForm({ ...values, assets: value });
                      }}
                      className={bem.e("assets")}
                      dropTextClass={bem.e("drop-text-class")}
                    >
                      <FormattedMessage id="channel manager > button upload" />
                    </UploadFiles>
                  </FormGroup>
                  {/* Only show for digital order */}
                  {isShowAdjustChannelSchedule && !isView && (
                    <>
                      <FormGroup>
                        <Label className={bem.e("text-label")}>
                          <FormattedMessage id="traffic details > recurrence statement" />
                        </Label>
                        {values.schedule ? (
                          <div className={bem.e("text-description")}>
                            {generateDescription(intl, values.schedule)}
                          </div>
                        ) : null}
                      </FormGroup>
                      <FormGroup>
                        <div className={bem.e("recurrence-container")}>
                          <Button
                            color="blue"
                            outline
                            type="button"
                            disabled={isDisabled}
                            className={classnames(
                              "btn btn-radius",
                              bem.e("btn-recurrence")
                            )}
                            onClick={() => setIsOpenFlightSchedule(true)}
                          >
                            <span className={bem.e("recurrence-icon")}>
                              <RecurrenceIcon />
                            </span>
                            <FormattedMessage
                              id={`channel manager > adjust channel schedule`}
                            />
                          </Button>
                        </div>
                        {validationState &&
                          get(validationState, "schedule") && (
                            <div className="alert-danger">
                              {get(validationState, "schedule")}
                            </div>
                          )}
                      </FormGroup>
                    </>
                  )}
                </Col>
              </Row>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  formProps.submitForm();
                }}
                id={`custom-form-${channel.id}`}
                className={classnames(bem.e("submit-button-fake"), {
                  "is-valid":
                    formikRef &&
                    Object.keys(get(formikRef, "current.state.errors", {}))
                      .length === 0,
                })}
              >
                Submit
              </button>
              {/* Only show for digital order */}
              {isShowAdjustChannelSchedule && (
                <FlightScheduleModal
                  isOpen={isOpenFlightSchedule}
                  onToggle={() =>
                    setIsOpenFlightSchedule(!isOpenFlightSchedule)
                  }
                  value={values.schedule || null}
                  initialValues={get(values, "schedule", {})}
                  isChannel={true}
                  channel={channel}
                  onSubmitForm={(data) => {
                    formProps.setFieldValue("schedule", data);
                    setIsOpenFlightSchedule(false);

                    onSubmitForm({ ...values, schedule: data });
                  }}
                />
              )}
              {/* 
              Disabled auto save. Because we set the value to order forms. When user is selecting or entering any text to any field.
              <AutoSave
                onSave={onSubmitForm}
                debounce={500}
                values={values}
                isDirty={isDirty}
                setIsDirty={setIsDirty}
                render={({ isSaving, lastSaved, saveError }) => {
                  return null;
                }}
              /> */}
            </div>
          );
        }}
      </Formik>
    );
  };
  const renderView = () => {
    //const channelManagersOptions = rest.getChannelManagers();
    const channelManagersOptions = get(channel, "channel_managers", []);
    const digitalProducer = find(
      users,
      (item) => item.id === get(channel, "digital_producer", "")
    );
    const channelManager = find(
      channelManagersOptions,
      (item) => item.value === get(channel, "channel_manager", "")
    );
    if (onlyDesign) {
      const fieldsForDesign = get(channel, "fields", []).filter((field) => {
        return includes(["media_for_review_approval"], field.key);
      });
      let isRejected =
        filter(channel.fields, (field) => {
          if (field.key === "media_for_review_approval") {
            return find(
              field.value_history,
              (item) => item.review_media_status === "rejected"
            );
          }
          return false;
        }).length > 0;
      return (
        <div className={bem.e("container-view")}>
          <Row>
            <Col xs={6}>
              {/* only show for digital order */}
              {isShowRequiresPlacement && (
                <div className={bem.e("requires-placement")}>
                  <Checkbox
                    checked={get(channel, "is_required_placement", false)}
                    onChange={() => {}}
                    text={intl.formatMessage({
                      id: "channel manager > requires placement confirmation",
                    })}
                  />
                </div>
              )}
              <ListGroup flush className={bem.e("fields-view")}>
                {renderCustomFieldsView(channel, fieldsForDesign)}
                <h2 className={bem.e("creative-title")}>
                  <FormattedMessage id="channel manager > creative for approval" />
                  :
                  {isRejected && (
                    <span className={bem.e("changes-requested")}>
                      <FormattedMessage id="channel manager > changes requested" />
                    </span>
                  )}
                </h2>
                <div className={bem.e("fields-design-view")}>
                  {get(channel, "fields.length") > 0
                    ? renderCustomFieldsView(channel, [], fieldsForDesign, {
                        onlyDesign: true,
                      })
                    : intl.formatMessage({ id: "process > none" })}
                </div>
              </ListGroup>
            </Col>
            <Col xs={6}>
              <FormGroup>
                <Label className={bem.e("view-label")}>
                  <FormattedMessage id="channel manager > channel manager" />
                </Label>
                <div className={"order-view-value"}>
                  {get(channel, "channel_manager_name", "")
                    ? get(channel, "channel_manager_name", "")
                    : intl.formatMessage({ id: "process > none" })}
                </div>
              </FormGroup>
              <FormGroup>
                <Label className={bem.e("view-label")}>
                  <FormattedMessage id="channel manager > production" />
                </Label>
                <div className={"order-view-value"}>
                  {get(channel, "production", "")
                    ? get(channel, "production", "")
                    : intl.formatMessage({ id: "process > none" })}
                </div>
              </FormGroup>
              {isShowDigitalProducer &&
                (channel.production === "Requires Production" ||
                  channel.production === "Production Needed") && (
                  <FormGroup>
                    <Label className={bem.e("view-label")}>
                      <FormattedMessage id="channel manager > digital producer" />
                    </Label>
                    <div className={"order-view-value"}>
                      {get(digitalProducer, "name", "")}
                    </div>
                  </FormGroup>
                )}

              <FormGroup>
                <Label className={bem.e("view-label")}>
                  <FormattedMessage id="channel manager > optional notes" />
                </Label>
                <div className={"order-view-value"}>
                  {get(channel, "notes", "") ? (
                    get(channel, "notes", "")
                  ) : (
                    <FormattedMessage id="process > none" />
                  )}
                </div>
              </FormGroup>
              <FormGroup>
                <Label className={bem.e("view-optional-assets-label")}>
                  <FormattedMessage id="channel manager > optional assets" />
                </Label>
                {get(channel, "assets_link", "") ? (
                  <a
                    href={get(channel, "assets_link", "")}
                    alt=""
                    className={bem.e("view-assets-link")}
                  >
                    {get(channel, "assets_link", "")}
                  </a>
                ) : null}
                {get(channel, "assets.length") > 0 ? (
                  <HandleAssets
                    files={channel.assets}
                    viewDisabled={false}
                    renamingDisabled={true}
                    renamingHidden={true}
                    deleteDisabled={true}
                    deleteHidden={true}
                    downloadDisabled={false}
                    isView={isView}
                  />
                ) : (
                  <FormattedMessage id="process > none" />
                )}
              </FormGroup>
            </Col>
          </Row>
        </div>
      );
    }
    return (
      <div className={bem.e("container-view")}>
        <Row>
          <Col xs={6}>
            {/* only show for digital order */}
            {isShowRequiresPlacement && (
              <div className={bem.e("requires-placement")}>
                <Checkbox
                  checked={get(channel, "is_required_placement", false)}
                  onChange={() => {}}
                  text={intl.formatMessage({
                    id: "channel manager > requires placement confirmation",
                  })}
                />
              </div>
            )}
            <ListGroup flush className={bem.e("fields-view")}>
              {renderCustomFieldsView(channel)}
            </ListGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label className={bem.e("view-label")}>
                <FormattedMessage id="channel manager > channel manager" />
              </Label>
              <div className={"order-view-value"}>
                {get(channelManager, "label", "") ? (
                  get(channelManager, "label", "")
                ) : (
                  <FormattedMessage id="process > none" />
                )}
              </div>
            </FormGroup>
            <FormGroup>
              <Label className={bem.e("view-label")}>
                <FormattedMessage id="channel manager > production" />
              </Label>
              <div className={"order-view-value"}>
                {get(channel, "production", "") ? (
                  get(channel, "production", "")
                ) : (
                  <FormattedMessage id="process > none" />
                )}
              </div>
            </FormGroup>
            {isShowDigitalProducer &&
              (channel.production === "Requires Production" ||
                channel.production === "Production Needed") && (
                <FormGroup>
                  <Label className={bem.e("view-label")}>
                    <FormattedMessage id="channel manager > digital producer" />
                  </Label>
                  <div className={"order-view-value"}>
                    {get(digitalProducer, "name", "") ? (
                      get(digitalProducer, "name", "")
                    ) : (
                      <FormattedMessage id="process > none" />
                    )}
                  </div>
                </FormGroup>
              )}

            <FormGroup>
              <Label className={bem.e("view-label")}>
                <FormattedMessage id="channel manager > optional notes" />
              </Label>
              <div className={"order-view-value"}>
                {get(channel, "notes", "") ? (
                  get(channel, "notes", "")
                ) : (
                  <FormattedMessage id="process > none" />
                )}
              </div>
            </FormGroup>
            <FormGroup>
              <Label className={bem.e("view-label")}>
                <FormattedMessage id="channel manager > optional assets" />
              </Label>
              {get(channel, "assets_link", "") ? (
                <a
                  href={get(channel, "assets_link", "")}
                  alt=""
                  className={bem.e("view-assets-link")}
                >
                  {get(channel, "assets_link", "")}
                </a>
              ) : null}
              {get(channel, "assets.length") > 0 ? (
                <HandleAssets
                  files={channel.assets}
                  viewDisabled={false}
                  renamingDisabled={true}
                  renamingHidden={true}
                  deleteDisabled={true}
                  deleteHidden={true}
                  downloadDisabled={false}
                  isView={isView}
                />
              ) : (
                <FormattedMessage id="process > none" />
              )}
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  };
  return (
    <div className={bem.e("wrapper")}>
      <div className={bem.e("form-header")}>
        <span className={bem.e("channel-icon")}>
          <ChannelIcon />
        </span>
        <h4 className={bem.e("title")}>{get(channel, "name", "")}</h4>
        <div className={bem.e("head-right")}>
          {/* {isShowStatus &&
						get(channel, "production") === "Production Coming" && (
							<div className={bem.e("head-status")}>
								<span className={bem.e("head-status-label")}>
									<FormattedMessage id="channel manager > status" />
									:
								</span>
								<span className={bem.e("head-status-value")}>
									<FormattedMessage id="channel manager > awaiting assets" />
								</span>
							</div>
						)} */}
          {rest.isShowDeleteChannel && (
            <span
              className={bem.e("button-delete-icon")}
              onClick={() => {
                if (isView) return;
                rest.setIsOpenConfirmationDeleteChannel(true);
              }}
            >
              <DeleteIcon color="#795AFA" />
            </span>
          )}
          {isShowCollapse && (
            <span
              className={bem.e("button-collapse-icon")}
              onClick={() => {
                if (isShowCollapse) setIsCollapse(!isCollapse);
                // toggle class into root for handle css
                const elementRoot = document.getElementById("root");
                elementRoot.classList.toggle("opened-channel");
              }}
            >
              {isCollapse ? (
                <UpIcon color="#795AFA" />
              ) : (
                <DownIcon color="#795AFA" />
              )}
            </span>
          )}
        </div>
      </div>
      <div
        className={classnames(bem.e("form-content"), {
          [bem.e("show")]: isCollapse,
          [bem.e("hide")]: !isCollapse && isShowCollapse,
        })}
      >
        {isView ? (
          renderView()
        ) : (
          <React.Fragment>
            {renderForm()}
            {rest.isShowDeleteChannel && (
              <ConfirmationModal
                isOpen={rest.isOpenConfirmationDeleteChannel}
                onToggle={() =>
                  rest.setIsOpenConfirmationDeleteChannel(
                    !rest.isOpenConfirmationDeleteChannel
                  )
                }
                onCancel={() => rest.setIsOpenConfirmationDeleteChannel(false)}
                onConfirm={() => {
                  rest.onDeleteChannel();
                  rest.setIsOpenConfirmationDeleteChannel(false);
                }}
                confirmTitle={intl.formatMessage({
                  id: "process > button yes",
                })}
                cancelTitle={intl.formatMessage({
                  id: "process > button no",
                })}
                title={
                  <FormattedMessage id={`process > title deleted channel`} />
                }
                isCloseOutside={false}
              />
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};
CustomForm.defaultProps = {
  channel: {},
  onSubmitForm: () => {},
  isView: false,
  isShowAdjustChannelSchedule: false,
  isShowCollapse: false,
  isShowStatus: false,
  onlyDesign: false,
  isPreview: false,
  isDigitalOrder: false,
  isShowDeleteChannel: false,
};

CustomForm.propTypes = {
  isView: PropTypes.bool,
  channel: PropTypes.any,
  onSubmitForm: PropTypes.func,
  isShowAdjustChannelSchedule: PropTypes.bool,
  isShowCollapse: PropTypes.bool,
  isShowStatus: PropTypes.bool,
  onlyDesign: PropTypes.bool,
  isPreview: PropTypes.bool,
  isDigitalOrder: PropTypes.bool,
  isShowDeleteChannel: PropTypes.bool,
};
export default CustomForm;
