import React from "react";
import { FormattedMessage } from "react-intl";
import steps from "./steps";

const Digital = {
  _index: "templates",
  _type: "template",
  _id: "digital",
  _score: 1,
  _source: {
    key: "digital",
    templates_name: "Digital",
    templates_description: "Track the process of a digital production",
    breadcrumbs_title: (values) => <FormattedMessage id={`process > breadcrumb > digital ${values.type} title`} />,
    breadcrumbs_subtitle: (values) => <FormattedMessage id="process > breadcrumb > digital subtitle" values={values} />,
    workflow: {
      title: "Digital workflow",
    },
    steps: steps,
    active: true,
    is_allow_create_custom_workflow: true,
  },
};
export default Digital;
