import React from "react";
import PropTypes from "prop-types";
import bn from "utils/bemnames";
import { get } from "lodash";
import MediaChannel from "components/MediaChannel";
import { labelToNameField } from "utils/helpers";
const bem = bn.create("media");

const Media = ({ process, isView }) => {
  // If a channel requires production
  const marketingChannels = get(
    process,
    "data.fields.marketing_channels.value",
    []
  ).filter((channel) => {
    if (
      channel.production === "Requires Production" ||
      channel.production === "Production Needed"
    ) {
      return (
        channel.id ===
          get(process, "data.fields.for_marketing_channel.value.id") &&
        channel.fields.filter((item) => {
          if (item.key === "media_for_review_approval") {
            const value = get(channel, labelToNameField(item.label));
            return value && item.key === "media_for_review_approval";
          }
        }).length > 0
      );
    }
  });
  const renderView = () => (
    <div className={bem.e("media-view")}>
      {marketingChannels.map((channel, index) => {
        return (
          <div className={bem.e("channel-form")} key={index}>
            <MediaChannel channel={channel} isView={isView} />
          </div>
        );
      })}
    </div>
  );
  return (
    <div className={bem.b()}>
      <div className={bem.e("main-section")}>
        <div className={bem.e("main-content")}>{renderView()}</div>
      </div>
    </div>
  );
};
Media.defaultProps = {
  channels: [],
  isView: false,
};
Media.propTypes = {
  channels: PropTypes.array,
  isView: PropTypes.bool,
};
export default Media;
