import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import bn from "utils/bemnames";
import { CloseIcon } from "components/CustomIcons";
import { FormattedMessage } from "react-intl";
import PreviewView from "components/SystemTemplates/PreviewView";
import StationImage from "components/JockConsole/Common/StationImage";

const bem = bn.create("preview-email-template-modal");
function PreviewEmailTemplateModal(props) {
  const { station, emailTemplate, contestImage } = props;
  const stationArr = [];
  if (station.call_letters) stationArr.push(station.call_letters);
  if (station.name) stationArr.push(station.name);

  return (
    <Modal isOpen={props.isOpen} className={bem.b()}>
      <ModalHeader>
        <FormattedMessage id="process > promotion > contest > preview" />
        <div className={bem.e("close-icon")} onClick={props.onToggle}>
          <CloseIcon color="#C2D4E0" />
        </div>
      </ModalHeader>
      <ModalBody>
        <div className={bem.e("section-info")}>
          <div className={bem.e("info-text")}>
            <strong>
              <FormattedMessage id="process > promotion > contest > to" />:
            </strong>
            <span>
              <FormattedMessage id="process > promotion > contest > winner name" />{" "}
              {"<"}
              <FormattedMessage id="process > promotion > contest > winner email" />
              {">"}
            </span>
          </div>
          <div className={bem.e("info-text")}>
            <strong>
              <FormattedMessage id="process > promotion > contest > from" />:
            </strong>
            <span>
              <FormattedMessage id="process > promotion > contest > station name" />{" "}
              {"<"}
              <FormattedMessage id="process > promotion > contest > station email" />
              {">"}
            </span>
          </div>
          <div className={bem.e("info-text")}>
            <strong>
              <FormattedMessage id="process > promotion > contest > cc" />:
            </strong>
          </div>
          <div className={bem.e("info-text")}>
            <strong>
              <FormattedMessage id="process > promotion > contest > subject" />:
            </strong>
            <span>{emailTemplate ? emailTemplate.subject : ""}</span>
          </div>
        </div>
        <div className={bem.e("section-content")}>
          <div className={bem.e("station-image")}>
            <StationImage station={station} />
          </div>
          {contestImage ? (
            <div className={bem.e("image")}>
              <img src={contestImage.path} alt="contest" />
            </div>
          ) : null}
          {emailTemplate && (
            <PreviewView
              bem={bem}
              data={{
                content: emailTemplate.content,
              }}
            />
          )}
        </div>
        <div className={bem.e("section-bottom")}>
          <div className={bem.e("official-text")}>
            <FormattedMessage id="process > promotion > contest > click here for official rules" />
          </div>
          <div className={bem.e("line")} />
          <div className={bem.e("copyright")}>
            <span className={bem.e("station-text")}>
              {stationArr.join(" - ")}
            </span>
            <FormattedMessage
              id="process > promotion > contest > copyright year all rights reserved"
              values={{ year: new Date().getFullYear() }}
            />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

PreviewEmailTemplateModal.defaultProps = {
  isOpen: false,
  station: {},
  subject: "",
  emailTemplate: null,
};

export default PreviewEmailTemplateModal;
