import steps from "./steps";

const PromotionEvent = {
  _index: "templates",
  _type: "template",
  _id: "promotion_event",
  _score: 1,
  _source: {
    key: "promotion_event",
    templates_name: "PromotionEvent",
    templates_description: "Create a promotion event",
    priviliges: {},
    steps: steps,
    active: true,
  },
};
export default PromotionEvent;
