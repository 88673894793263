import React, { Component } from "react";
import classnames from "classnames";
import { get } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  FormGroup,
  Row,
  Button,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { EditIcon, ViewIcon } from "components/CustomIcons";
import { Asterisk } from "components/Elements";
import HandleAssets from "components/HandleAssets";
import SecurityPolicyModal from "components/SecurityPolicyModal";
import TextInput from "components/TextInput";
import ToggleSwitch from "components/ToggleSwitch";
import UploadFiles from "components/UploadFiles";
import ServerFormSection from "./ServerFormSection";

class ServerConfiguration extends Component {
  state = {
    edit: false,
    envDetailsModal: false,
  };

  onView = () => {
    this.setState({ envDetailsModal: true });
  };

  onEdit = () => {
    this.setState({ edit: true });
  };

  onToggleModal = () => {
    this.setState({ envDetailsModal: !this.state.envDetailsModal });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.edit !== this.props.edit) {
      this.setState({ edit: false });
    }
  }

  renderEnvField = (isEdit) => {
    const { authIsGodAdmin, bem, formProps = {}, intl } = this.props;
    if (!authIsGodAdmin) {
      return null;
    }
    const { values, touched, errors } = formProps;
    if (this.state.edit) {
      return (
        <TextInput
          label={<FormattedMessage id="server > env" />}
          type="textarea"
          name="env"
          placeholder={intl.formatMessage({
            id: "server > enter env",
          })}
          value={values.env}
          onChange={formProps.handleChange}
          onBlur={formProps.handleBlur}
          error={touched.env && errors.env}
        />
      );
    }
    return (
      <FormGroup>
        <label>
          <FormattedMessage id="server > env" />
        </label>
        <div className={bem.e("env-field-wrapper")}>
          <div className={bem.e("file-name")}>.env</div>
          <div className={bem.e("file-actions")}>
            {isEdit && (
              <div onClick={this.onEdit}>
                <EditIcon />
              </div>
            )}
            <div onClick={this.onView}>
              <ViewIcon />
            </div>
          </div>
        </div>
      </FormGroup>
    );
  };

  renderEnvDetails = () => {
    const { authIsGodAdmin, bem, serverDetails } = this.props;
    if (!authIsGodAdmin) {
      return null;
    }
    return (
      <FormGroup className="mb-2">
        <div>
          <h3>Make sure to set keys:</h3>
          <b>APP_URL:</b> including http / https protocol, specific subdomain,
          or a dedicated domain
          <br />
          <b>APP_TITLE:</b> will be shown on the top left of the page
          <br />
          <b>DB_HOST:</b> can be a local or remote instance, shared or dedicated
          <br />
          <b>DB_DATABASE:</b> must be a unique dedicated name, same as subdomain
          is a good option
          <br />
          <b>DB_*</b> <br />
          <b>MASTER_ELASTICSEARCH_HOST</b>
          <br />
          <br />
          Optionaly set dedicated:
          <br />
          <br />
          <b>MEMCACHED_*</b>
          <br />
          <b>AWS_*</b>
          <br />
          <br />
          A DNS record should be created to point the URL to a server with:
          <br />
          &middot; The deployed app (and a configured deploymnt)
          <br />
          &middot; MySQL (if configured to run local)
          <br />
          &middot; MemCached (if configured to run local)
          <br />
          <br />
          <h3>Server setup requirements from client:</h3>
          {serverDetails.instructions
            ? serverDetails.instructions
                .split("\n")
                .map((item, index) => <div key={index}>{item}</div>)
            : "None"}
          <br />
          <br />
        </div>
        <FormGroup>
          <label className={bem.e("server-view-label")}>
            <FormattedMessage id="server > env" />
          </label>
          <div className={bem.e("server-view-value")}>{serverDetails.env}</div>
        </FormGroup>
        <div className="d-flex">
          <Button
            type="button"
            outline
            className={classnames(
              bem.e("button-cancel"),
              "btn-outline-blue btn-radius",
              "ml-auto"
            )}
            onClick={this.onToggleModal}
          >
            <FormattedMessage id="confirm modal > button close" />
          </Button>
        </div>
      </FormGroup>
    );
  };

  renderEdit = () => {
    const {
      bem,
      formProps,
      intl,
      isSecurityPolicyModalOpen,
      serverDetails,
      toggleSecurityPolicyModalOpen,
    } = this.props;
    const { values, touched, errors } = formProps;
    return (
      <Row>
        <Col xs={6}>
          <FormGroup>
            <label>
              <FormattedMessage id="server > server type" />
              <Asterisk>*</Asterisk>
            </label>
            <ToggleSwitch
              leftComponent={<FormattedMessage id="server > dedicated" />}
              rightComponent={<FormattedMessage id="server > shared" />}
              switchProps={{
                onChange: (checked) => {
                  if (!checked) {
                    formProps.setFieldValue("is_dedicated", true);
                    formProps.setFieldValue("is_shared", false);
                  } else {
                    formProps.setFieldValue("is_dedicated", false);
                    formProps.setFieldValue("is_shared", true);
                  }
                },
                disabled: false,
                checked: values.is_shared,
                uncheckedIcon: false,
                checkedIcon: false,
                offColor: "#795AFA",
                onColor: "#795AFA",
                handleDiameter: 22,
                width: 50,
                height: 26,
              }}
            />
          </FormGroup>
          {/* BROADCAST GROUP SUBDOMAIN */}
          {
            <div className={bem.e("subdomain")}>
              <FormGroup>
                <TextInput
                  label={null}
                  type="text"
                  name="subdomain"
                  placeholder={intl.formatMessage({
                    id: "server > enter broadcast group subdomain",
                  })}
                  value={values.subdomain}
                  error={touched.subdomain && errors.subdomain}
                  onChange={formProps.handleChange}
                  onBlur={formProps.handleBlur}
                />
              </FormGroup>
              {values.is_shared && (
                <span className={bem.e("domain-text")}>
                  .Promosuiteplus.net
                </span>
              )}
            </div>
          }
          {/*{serverDetails && serverDetails.id ? (
            <FormGroup>
              <div className={bem.e("security-policy")}>
                <label>
                  <FormattedMessage id="server > server security policy" />
                </label>
                <div
                  className={bem.e("security-link")}
                  onClick={toggleSecurityPolicyModalOpen}
                >
                  <FormattedMessage id={"server > click to view and update"} />
                </div>
                <SecurityPolicyModal
                  isOpen={isSecurityPolicyModalOpen}
                  onToggle={toggleSecurityPolicyModalOpen}
                  ids={
                    serverDetails && serverDetails.id ? [serverDetails.id] : ""
                  }
                  onFormSubmit={(data) => {
                    formProps.setFieldValue("security_policy", data);
                    toggleSecurityPolicyModalOpen();
                  }}
                />
              </div>
            </FormGroup>
          ) : (
            ""
          )}*/}

          {this.renderEnvField(true)}
        </Col>
        <Col xs={6}>
          {/* contract server requirements */}
          <FormGroup>
            <label>
              <FormattedMessage id="server > contract server requirements" />
            </label>
            <HandleAssets
              files={values.contract}
              onUploadedFiles={(value) => {
                setTimeout(() => {
                  formProps.setFieldValue("contract", value);
                }, 20);
              }}
              onDeleted={(value) => {
                setTimeout(() => {
                  formProps.setFieldValue("contract", value);
                }, 20);
              }}
            />
            <UploadFiles
              attachText={intl.formatMessage({
                id: "server > attach files",
              })}
              acceptMultiple={true}
              files={values.contract}
              isHideFilesDragArea={true}
              onUploadedFiles={(value) => {
                setTimeout(() => {
                  formProps.setFieldValue("contract", [
                    ...values.contract,
                    ...value,
                  ]);
                }, 20);
              }}
              // onDeleted={value => {
              //   setTimeout(() => {
              //     formProps.setFieldValue("contract", value);
              //   }, 20);
              // }}
            >
              <FormattedMessage id="process > button upload" />
            </UploadFiles>
            {!!(touched.contract && errors.contract) && (
              <span className="text-danger">{errors.contract}</span>
            )}
          </FormGroup>
          <FormGroup>
            <TextInput
              label={<FormattedMessage id="server > notes" />}
              type="textarea"
              name="instructions"
              value={values.instructions}
              onChange={formProps.handleChange}
              onBlur={formProps.handleBlur}
              error={touched.instructions && errors.instructions}
            />
          </FormGroup>
        </Col>
      </Row>
    );
  };

  renderView = () => {
    const { bem, serverDetails } = this.props;
    return (
      <Row>
        <Col xs={6}>
          <FormGroup>
            <label className={bem.e("server-view-label")}>
              <FormattedMessage id="server > server type" />
            </label>
            <div
              className={classnames(
                bem.e("server-view-value"),
                bem.e("view-value-bold")
              )}
            >
              {serverDetails.is_shared ? (
                <FormattedMessage id="server > shared" />
              ) : null}
              {serverDetails.is_dedicated ? (
                <FormattedMessage id="server > dedicated" />
              ) : null}
            </div>
          </FormGroup>
          {/* BROADCAST GROUP SUBDOMAIN */}
          {/* {
                      <div className={bem.e("subdomain")}>
                        <FormGroup>
                          <div className={bem.e("server-view-value")}>
                            {serverDetails.subdomain}.Promosuiteplus.net
                          </div>
                        </FormGroup>
                      </div>
                    } */}
          {/*<FormGroup>
            <div className={bem.e("security-policy")}>
              <label>
                <FormattedMessage id="server > server security policy" />
              </label>
              <div
                className={classnames(
                  bem.e("server-view-value"),
                  bem.e("view-value-bold")
                )}
              >
                {serverDetails.is_security ? (
                  <FormattedMessage id="server > customized" />
                ) : (
                  <FormattedMessage id="server > standard" />
                )}
              </div>
            </div>
          </FormGroup>*/}
          {this.renderEnvField()}
        </Col>
        <Col xs={6}>
          {/* contract server requirements */}
          <FormGroup>
            <label className={bem.e("server-view-label")}>
              <FormattedMessage id="server > contract server requirements" />
            </label>
            {get(serverDetails, "contract.length", 0) > 0 ? (
              <HandleAssets
                files={serverDetails.contract}
                onUploadedFiles={(value) => {}}
                onDeleted={(value) => {}}
                downloadDisabled={false}
                isView
                deleteHidden
                renamingHidden
                viewHidden
              />
            ) : (
              <div className={bem.e("server-view-value")}>
                <FormattedMessage id="server > none" />
              </div>
            )}
          </FormGroup>
          {/* notes */}
          <FormGroup>
            <label className={bem.e("server-view-label")}>
              <FormattedMessage id="server > notes" />
            </label>
            <div className={bem.e("server-view-value")}>
              {serverDetails.instructions}
            </div>
          </FormGroup>
        </Col>
      </Row>
    );
  };

  renderEnvModal = () => {
    const { bem } = this.props;
    return (
      <Modal
        isOpen={this.state.envDetailsModal}
        size="lg"
        className={classnames(bem.b())}
      >
        <ModalHeader toggle={this.onToggleModal}>
          <FormattedMessage id="server > env" />
        </ModalHeader>
        <ModalBody className={bem.e("body")}>
          {this.renderEnvDetails()}
        </ModalBody>
      </Modal>
    );
  };

  render() {
    const { bem, edit } = this.props;
    return (
      <ServerFormSection
        bem={bem}
        titleId="server > title server configuration"
        sectionClass={bem.e("box-server-details")}
        sectionFieldsClass={bem.e("section-view-server-configuration-fields")}
      >
        {edit ? this.renderEdit() : this.renderView()}
        {this.renderEnvModal()}
      </ServerFormSection>
    );
  }
}

export default injectIntl(ServerConfiguration);
