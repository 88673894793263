import View from "./view";
import { get } from "lodash";
import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";
import { injectIntl } from "react-intl";
import { sendBackProcess, getProcess } from "store/actions/processes";
export default injectIntl(
  compose(
    connect(
      (state, props) => {
        return {
          isLoading: get(state, "processes.sendBackProgress.loading", false),
        };
      },
      {
        sendBackProcess,
        getProcess,
      }
    ),
    withHandlers({
      onSubmit: ({
        sendBackProcess,
        process,
        onSuccessCallback,
        getProcess,
      }) => (values) => {
        const processKey = get(process, "data.fields.key.value", "");
        if (!processKey) return;
        sendBackProcess(
          {
            process_id: processKey,
            step: values.status,
            instructions: values.instructions,
          },
          () => {
            onSuccessCallback();
            getProcess({ process_id: processKey });
          }
        );
      },
    })
  )(View)
);
