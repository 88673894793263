import React from "react";
import Tooltip from "rc-tooltip";
import { FormattedMessage } from "react-intl";
import { InsertButtonIcon } from "components/CustomIcons";
const InsertButton = ({ onClick }) => {
  return (
    <Tooltip
      trigger={["hover"]}
      placement="top"
      overlayClassName={"RichEditor-tooltip"}
      overlay={<FormattedMessage id="textarea evaluator > insert a button" />}
    >
      <span className={"RichEditor-styleButton"} onClick={onClick}>
        <InsertButtonIcon />
      </span>
    </Tooltip>
  );
};
export default InsertButton;
