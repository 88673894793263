import { get, values } from "lodash";
import * as Types from "store/types/jocks";
import { LOGOUT_USER } from "store/types/auth";
const initialState = {
  schedules: {
    data: [],
    error: null,
    loading: false,
  },
  floatingContestSchedules: {
    data: [],
    error: null,
    loading: false,
  },
  schedule: {
    data: null,
    error: null,
    loading: false,
  },
  update: {
    id: null,
    error: null,
    loading: false,
  },
  widgets: {
    data: {},
    error: null,
    loading: false,
  },
  widget: {
    data: {},
    error: null,
    loading: null,
  },
  activeWidget: {
    error: null,
    loading: null,
  },
  orderWidget: {
    error: null,
    loading: null,
  },
  updateWidget: {
    error: null,
    loading: null,
  },
  addWidget: {
    error: null,
    loading: null,
  },
  deleteWidget: {
    error: null,
    loading: null,
  },
  displaySettings: {
    data: {},
    is_visible: false,
    is_dirty: false,
    error: null,
    loading: false,
  },
  widgetsDisplay: {
    data: {},
    is_dirty: false,
    error: null,
    loading: false,
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case Types.GET_JOCK_SCHEDULES_REQUEST: {
      return {
        ...state,
        schedules: {
          data: {},
          error: null,
          loading: true,
        },
      };
    }

    case Types.GET_JOCK_SCHEDULES_SUCCESS: {
      return {
        ...state,
        schedules: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    }

    case Types.GET_JOCK_SCHEDULES_ERROR: {
      return {
        ...state,
        schedules: {
          data: null,
          error: action.payload,
          loading: false,
        },
      };
    }

    case Types.GET_JOCK_SCHEDULES_FLOATING_CONTEST_REQUEST: {
      return {
        ...state,
        floatingContestSchedules: {
          data: get(state, "floatingContestSchedules.data"),
          error: null,
          loading: true,
        },
      };
    }

    case Types.GET_JOCK_SCHEDULES_FLOATING_CONTEST_SUCCESS: {
      return {
        ...state,
        floatingContestSchedules: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    }

    case Types.GET_JOCK_SCHEDULES_FLOATING_CONTEST_ERROR: {
      return {
        ...state,
        floatingContestSchedules: {
          data: null,
          error: action.payload,
          loading: false,
        },
      };
    }

    case Types.UPDATE_SCHEDULE_REQUEST: {
      return {
        ...state,
        update: {
          id: get(state, "update.id"),
          error: null,
          loading: true,
        },
      };
    }

    case Types.UPDATE_SCHEDULE_SUCCESS: {
      return {
        ...state,
        update: {
          id: get(state, "update.id"),
          error: null,
          loading: false,
        },
      };
    }

    case Types.UPDATE_SCHEDULE_ERROR: {
      return {
        ...state,
        update: {
          id: null,
          error: action.payload,
          loading: false,
        },
      };
    }
    case Types.FETCH_JOCK_CONSOLE_WIDGETS_REQUEST: {
      return {
        ...state,
        widgets: {
          ...state.widgets,
          is_dirty: false,
          loading: true,
          error: null,
        },
      };
    }
    case Types.FETCH_JOCK_CONSOLE_WIDGETS_SUCCESS: {
      return {
        ...state,
        widgets: {
          data: action.payload,
          is_dirty: false,
          loading: false,
          error: null,
        },
      };
    }
    case Types.FETCH_JOCK_CONSOLE_WIDGETS_ERROR: {
      return {
        ...state,
        widgets: {
          ...state.widgets,
          is_dirty: false,
          loading: false,
          error: action.payload.error,
        },
      };
    }
    case Types.SET_JOCK_CONSOLE_WIDGETS_SUCCESS: {
      return {
        ...state,
        widgets: {
          data: action.payload,
          is_dirty: false,
          loading: false,
          error: null,
        },
      };
    }
    case Types.FETCH_DISPLAY_SETTINGS_REQUEST: {
      return {
        ...state,
        displaySettings: {
          ...state.displaySettings,
          is_dirty: false,
          loading: true,
          error: null,
        },
      };
    }
    case Types.FETCH_DISPLAY_SETTINGS_SUCCESS: {
      return {
        ...state,
        displaySettings: {
          ...state.displaySettings,
          data: {
            ...get(state, "displaySettings.data", {}),
            ...action.payload,
          },
          is_dirty: false,
          loading: false,
          error: null,
        },
      };
    }
    case Types.FETCH_DISPLAY_SETTINGS_ERROR: {
      return {
        ...state,
        displaySettings: {
          ...state.displaySettings,
          is_dirty: false,
          loading: false,
          error: action.payload.error,
        },
      };
    }
    case Types.UPDATE_DISPLAY_SETTINGS_REQUEST: {
      return {
        ...state,
        displaySettingsUpdate: {
          loading: true,
          error: null,
        },
      };
    }
    case Types.UPDATE_DISPLAY_SETTINGS_SUCCESS: {
      return {
        ...state,
        displaySettingsUpdate: {
          loading: false,
          error: null,
        },
      };
    }
    case Types.UPDATE_DISPLAY_SETTINGS_ERROR: {
      return {
        ...state,
        displaySettingsUpdate: {
          loading: false,
          error: null,
        },
      };
    }
    case Types.SET_STATION_DISPLAY_SETTINGS_MODAL: {
      return {
        ...state,
        displaySettings: {
          ...state.displaySettings,
          selected_station: action.payload || null,
        },
      };
    }
    case Types.SHOW_DISPLAY_SETTINGS_MODAL: {
      return {
        ...state,
        displaySettings: {
          ...state.displaySettings,
          is_visible: true,
        },
      };
    }
    case Types.HIDE_DISPLAY_SETTINGS_MODAL: {
      return {
        ...state,
        displaySettings: {
          ...state.displaySettings,
          is_visible: false,
        },
      };
    }
    // active
    case Types.UPDATE_ACTIVE_JOCK_CONSOLE_WIDGET_REQUEST: {
      return {
        ...state,
        activeWidget: {
          ...state.activeWidget,
          loading: true,
          error: null,
        },
      };
    }
    case Types.UPDATE_ACTIVE_JOCK_CONSOLE_WIDGET_SUCCESS: {
      return {
        ...state,
        widgets: {
          ...state.widgets,
          data: {
            ...state.widgets.data,
            items: get(state, "widgets.data.items", []).map((item) => {
              if (item.id === action.payload.id) return action.payload;
              return item;
            }),
          },
        },
        activeWidget: {
          ...state.activeWidget,
          loading: false,
          error: null,
        },
      };
    }
    case Types.UPDATE_ACTIVE_JOCK_CONSOLE_WIDGET_ERROR: {
      return {
        ...state,
        activeWidget: {
          ...state.activeWidget,
          loading: false,
          error: action.payload.error,
        },
      };
    }
    // order
    case Types.UPDATE_ORDER_WIDGETS_REQUEST: {
      return {
        ...state,
        // update state widgets when ordering
        widgets: {
          ...state.widgets,
          data: {
            ...state.widgets.data,
            items: action.payload,
          },
        },
        orderWidget: {
          ...state.orderWidget,
          loading: true,
          error: null,
        },
      };
    }
    case Types.UPDATE_ORDER_WIDGETS_SUCCESS: {
      return {
        ...state,
        orderWidget: {
          ...state.orderWidget,
          loading: false,
          error: null,
        },
      };
    }
    case Types.UPDATE_ORDER_WIDGETS_ERROR: {
      return {
        ...state,
        orderWidget: {
          ...state.orderWidget,
          loading: false,
          error: action.payload.error,
        },
      };
    }
    // add new
    case Types.ADD_JOCK_CONSOLE_WIDGET_REQUEST: {
      return {
        ...state,
        addWidget: {
          ...state.addWidget,
          loading: true,
          error: null,
        },
      };
    }
    case Types.ADD_JOCK_CONSOLE_WIDGET_SUCCESS: {
      return {
        ...state,
        widgets: {
          ...state.widgets,
          is_dirty: true,
        },
        addWidget: {
          ...state.addWidget,
          loading: false,
          error: null,
        },
      };
    }
    case Types.ADD_JOCK_CONSOLE_WIDGET_ERROR: {
      return {
        ...state,
        addWidget: {
          ...state.addWidget,
          loading: false,
          error: action.payload.error,
        },
      };
    }
    // update
    case Types.UPDATE_JOCK_CONSOLE_WIDGET_REQUEST: {
      return {
        ...state,
        updateWidget: {
          ...state.updateWidget,
          loading: true,
          error: null,
        },
      };
    }
    case Types.UPDATE_JOCK_CONSOLE_WIDGET_SUCCESS: {
      return {
        ...state,
        widgets: {
          ...state.widgets,
          data: {
            ...state.widgets.data,
            items: get(state, "widgets.data.items", []).map((item) => {
              if (item.id === action.payload.id) return action.payload;
              return item;
            }),
          },
        },
        updateWidget: {
          ...state.updateWidget,
          loading: false,
          error: null,
        },
      };
    }
    case Types.UPDATE_JOCK_CONSOLE_WIDGET_ERROR: {
      return {
        ...state,
        updateWidget: {
          ...state.updateWidget,
          loading: false,
          error: action.payload.error,
        },
      };
    }
    case Types.FORGET_JOCK_CONSOLE_WIDGETS: {
      return {
        ...state,
        widgets: {
          ...state.widgets,
          is_dirty: true,
        },
      };
    }
    case Types.ADD_JOCK_CONSOLE_WIDGET_LOCAL_SUCCESS: {
      return {
        ...state,
        widgets: {
          ...state.widgets,
          data: {
            ...state.widgets.data,
            items: [
              ...get(state, "widgets.data.items", []),
              {
                ...action.payload,
              },
            ].map((item, index) => {
              return {
                ...item,
                order: item.order ? item.order : index + 1,
              };
            }),
          },
        },
      };
    }
    case Types.DELETE_JOCK_CONSOLE_WIDGET_LOCAL_SUCCESS: {
      return {
        ...state,
        widgets: {
          ...state.widgets,
          data: {
            ...state.widgets.data,
            items: get(state, "widgets.data.items", []).filter(
              (item) => item.id.indexOf("_new") !== 0
            ),
          },
        },
      };
    }
    // delete
    case Types.DELETE_JOCK_CONSOLE_WIDGET_REQUEST: {
      return {
        ...state,
        deleteWidget: {
          ...state.deleteWidget,
          loading: true,
          error: null,
        },
      };
    }
    case Types.DELETE_JOCK_CONSOLE_WIDGET_SUCCESS: {
      return {
        ...state,
        widgets: {
          ...state.widgets,
          is_dirty: true,
        },
        deleteWidget: {
          ...state.deleteWidget,
          loading: false,
          error: null,
        },
      };
    }
    case Types.DELETE_JOCK_CONSOLE_WIDGET_ERROR: {
      return {
        ...state,
        deleteWidget: {
          ...state.deleteWidget,
          loading: false,
          error: action.payload.error,
        },
      };
    }
    // set select schedule
    case Types.SET_SCHEDULE_SUCCESS: {
      return {
        ...state,
        schedule: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    }
    // schedule
    case Types.GET_SCHEDULE_REQUEST: {
      return {
        ...state,
        schedule: {
          ...state.schedule,
          loading: true,
          error: null,
        },
      };
    }
    case Types.GET_SCHEDULE_SUCCESS: {
      return {
        ...state,
        schedule: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    }
    case Types.GET_SCHEDULE_ERROR: {
      return {
        ...state,
        schedule: {
          ...state.schedule,
          loading: false,
          error: action.payload.error,
        },
      };
    }
    // widgets display
    case Types.JOCK_CONSOLE_WIDGET_FETCH_FEED_REQUEST: {
      return {
        ...state,
        widgetsDisplay: {
          ...state.widgetsDisplay,
          loading: true,
          error: null,
        },
      };
    }
    case Types.JOCK_CONSOLE_WIDGET_FETCH_FEED_SUCCESS: {
      return {
        ...state,
        widgetsDisplay: {
          ...state.widgetsDisplay,
          data: {
            ...get(state.widgetsDisplay, "data", {}),
            [action.payload.id]: {
              ...get(action, "payload.data", {}),
              data:
                get(action, "payload.data.current_page", 1) > 1
                  ? [
                      ...get(
                        state,
                        `widgetsDisplay.data.${action.payload.id}.data`,
                        []
                      ),
                      // convert to array
                      ...values(get(action, "payload.data.data", [])),
                    ]
                  : get(action, "payload.data.data", []),
            },
          },
          loading: false,
          error: null,
        },
      };
    }
    case Types.JOCK_CONSOLE_WIDGET_FETCH_FEED_ERROR: {
      return {
        ...state,
        widgetsDisplay: {
          ...state.widgetsDisplay,
          loading: false,
          error: action.payload.error,
        },
      };
    }
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
}
