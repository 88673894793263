import * as Types from "store/types/hubs";
import { get, uniqBy } from "lodash";
import { LOGOUT_USER } from "../types/auth";

const initialState = {
  hubs: {
    data: [],
    error: null,
    loading: false,
    is_dirty: false,
  },
  saveHub: {
    data: null,
    error: null,
    loading: false,
  },
  updateHub: {
    data: null,
    error: null,
    loading: false,
  },
  hub: {
    data: null,
    error: null,
    loading: false,
  },
  deleteHub: {
    data: null,
    error: null,
    loading: false,
  },
  assignedHubs: {
    data: null,
    error: null,
    loading: false,
  },
  assignedStations: {
    data: null,
    error: null,
    loading: false,
    is_dirty: false,
  },
  assignedUsers: {
    data: null,
    error: null,
    loading: false,
    is_dirty: false,
  },
  orderAssignments: {
    loading: false,
    error: null,
  },
  hubUsers: {
    loading: false,
    error: null,
    data: null,
  },
  teams: {
    data: [],
    error: null,
    loading: false,
  },
  currentOrders: {
    data: [],
    error: null,
    loading: false,
  },
  assignedOrders: {
    data: [],
    error: null,
    loading: false,
  },
  members: {
    data: [],
    error: null,
    loading: false,
  },
  membersAssignment: {
    data: [],
    error: null,
    loading: false,
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_HUBS_REQUEST: {
      return {
        ...state,
        hubs: {
          data: get(state, "hubs.data"),
          error: null,
          loading: true,
          is_dirty: false,
        },
      };
    }

    case Types.FETCH_HUBS_SUCCESS: {
      return {
        ...state,
        hubs: {
          data: action.payload,
          error: null,
          loading: false,
          is_dirty: false,
        },
      };
    }

    case Types.FETCH_HUBS_ERROR: {
      return {
        ...state,
        hubs: {
          data: null,
          error: action.payload,
          loading: false,
          is_dirty: false,
        },
      };
    }

    case Types.FETCH_MORE_HUBS_REQUEST: {
      return {
        ...state,
        hubs: {
          ...state.hubs,
          lazyLoad: true,
        },
      };
    }
    case Types.FETCH_MORE_HUBS_SUCCESS: {
      const { data: newData, ...rest } = action.payload;

      return {
        ...state,

        hubs: {
          ...state.hubs,
          data: {
            data: uniqBy(
              [...get(state, "hubs.data.data", []), ...newData],
              "id"
            ),
            ...rest,
          },
          lazyLoad: false,
        },
      };
    }
    case Types.FETCH_MORE_HUBS_ERROR: {
      return {
        ...state,
        hubs: {
          ...state.hubs,
          lazyLoad: false,
        },
      };
    }

    case Types.SAVE_HUB_REQUEST: {
      return {
        ...state,
        saveHub: {
          loading: true,
          error: null,
        },
      };
    }

    case Types.SAVE_HUB_SUCCESS: {
      return {
        ...state,
        hubs: {
          ...get(state, "hubs"),
          is_dirty: true,
        },
        saveHub: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    }

    case Types.SAVE_HUB_ERROR: {
      return {
        ...state,
        saveHUb: {
          is_added: false,
          loading: false,
          error: action.payload,
        },
      };
    }

    case Types.FETCH_HUB_REQUEST: {
      return {
        ...state,
        hub: {
          data: null,
          error: null,
          loading: true,
        },
      };
    }

    case Types.FETCH_HUB_SUCCESS: {
      return {
        ...state,
        hub: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    }

    case Types.FETCH_HUB_ERROR: {
      return {
        ...state,
        hub: {
          data: null,
          loading: false,
          error: action.payload,
        },
      };
    }

    case Types.UPDATE_HUB_REQUEST: {
      return {
        ...state,
        updateHub: {
          loading: true,
          error: null,
        },
      };
    }

    case Types.UPDATE_HUB_SUCCESS: {
      return {
        ...state,
        hubs: {
          ...get(state, "hubs"),
          is_dirty: true,
        },
        updateHub: {
          loading: false,
          error: null,
        },
      };
    }

    case Types.UPDATE_HUB_ERROR: {
      return {
        ...state,
        updateHub: {
          loading: false,
          error: action.payload,
        },
      };
    }

    case Types.DELETE_HUB_REQUEST: {
      return {
        ...state,
        deleteHub: {
          loading: true,
          error: null,
        },
      };
    }

    case Types.DELETE_HUB_SUCCESS: {
      return {
        ...state,
        hubs: {
          ...get(state, "hubs"),
          is_dirty: true,
        },
        deleteHub: {
          loading: false,
          error: null,
        },
      };
    }
    case Types.DELETE_HUB_ERROR: {
      return {
        ...state,
        deleteHub: {
          loading: false,
          error: action.payload,
        },
      };
    }
    // assigned hubs
    case Types.FETCH_ASSIGNED_HUBS_REQUEST: {
      return {
        ...state,
        assignedHubs: {
          ...state.assignedHubs.data,
          loading: true,
          error: null,
        },
      };
    }

    case Types.FETCH_ASSIGNED_HUBS_SUCCESS: {
      return {
        ...state,
        assignedHubs: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    }
    case Types.FETCH_ASSIGNED_HUBS_ERROR: {
      return {
        ...state,
        assignedHubs: {
          data: null,
          loading: false,
          error: action.payload,
        },
      };
    }
    // assigned hub stations
    case Types.FETCH_HUB_ASSIGNED_STATIONS_REQUEST: {
      return {
        ...state,
        assignedStations: {
          data: null,
          loading: true,
          error: null,
          is_dirty: false,
        },
      };
    }

    case Types.FETCH_HUB_ASSIGNED_STATIONS_SUCCESS: {
      return {
        ...state,
        assignedStations: {
          data: action.payload,
          loading: false,
          error: null,
          is_dirty: false,
        },
      };
    }

    case Types.FETCH_HUB_ASSIGNED_STATIONS_ERROR: {
      return {
        ...state,
        assignedStations: {
          data: null,
          loading: false,
          error: action.payload,
          is_dirty: false,
        },
      };
    }

    case Types.UPDATE_HUB_ASSIGNED_STATIONS_REQUEST: {
      return {
        ...state,
        assignedStations: {
          ...state.assignedStations,
          loading: true,
          error: null,
          is_dirty: false,
        },
      };
    }

    case Types.UPDATE_HUB_ASSIGNED_STATIONS_SUCCESS: {
      return {
        ...state,
        assignedStations: {
          ...state.assignedStations,
          loading: false,
          error: null,
          is_dirty: true,
        },
      };
    }

    case Types.UPDATE_HUB_ASSIGNED_STATIONS_ERROR: {
      return {
        ...state,
        assignedStations: {
          data: null,
          loading: false,
          error: action.payload,
          is_dirty: false,
        },
      };
    }

    // assigned hub users
    case Types.FETCH_HUB_ASSIGNED_USERS_REQUEST: {
      return {
        ...state,
        assignedUsers: {
          ...state.assignedUsers,
          loading: true,
          error: null,
          is_dirty: false,
        },
      };
    }

    case Types.FETCH_HUB_ASSIGNED_USERS_SUCCESS: {
      return {
        ...state,
        assignedUsers: {
          data: action.payload,
          loading: false,
          error: null,
          is_dirty: false,
        },
      };
    }

    case Types.FETCH_HUB_ASSIGNED_USERS_ERROR: {
      return {
        ...state,
        assignedUsers: {
          data: null,
          loading: false,
          error: action.payload,
          is_dirty: false,
        },
      };
    }

    case Types.UPDATE_HUB_ASSIGNED_USERS_REQUEST: {
      return {
        ...state,
        assignedUsers: {
          ...state.assignedUsers,
          loading: true,
          error: null,
          is_dirty: false,
        },
      };
    }

    case Types.UPDATE_HUB_ASSIGNED_USERS_SUCCESS: {
      return {
        ...state,
        assignedUsers: {
          ...state.assignedUsers,
          loading: false,
          error: null,
          is_dirty: true,
        },
      };
    }

    case Types.UPDATE_HUB_ASSIGNED_USERS_ERROR: {
      return {
        ...state,
        assignedUsers: {
          data: null,
          loading: false,
          error: action.payload,
          is_dirty: false,
        },
      };
    }
    // order assignments
    case Types.UPDATE_HUB_ORDER_ASSIGNMENTS_REQUEST: {
      return {
        ...state,
        orderAssignments: {
          loading: true,
          error: null,
        },
      };
    }

    case Types.UPDATE_HUB_ORDER_ASSIGNMENTS_SUCCESS: {
      return {
        ...state,
        assignedUsers: {
          ...state.assignedUsers,
          is_dirty: true,
        },
        orderAssignments: {
          loading: false,
          error: null,
        },
      };
    }

    case Types.UPDATE_HUB_ORDER_ASSIGNMENTS_ERROR: {
      return {
        ...state,
        orderAssignments: {
          loading: false,
          error: action.payload,
        },
      };
    }
    // hub users
    case Types.FETCH_HUB_USERS_REQUEST: {
      return {
        ...state,
        hubUsers: {
          ...state.hubUsers,
          loading: true,
          error: null,
        },
      };
    }

    case Types.FETCH_HUB_USERS_SUCCESS: {
      return {
        ...state,
        hubUsers: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    }

    case Types.FETCH_HUB_USERS_ERROR: {
      return {
        ...state,
        hubUsers: {
          data: null,
          loading: false,
          error: action.payload,
        },
      };
    }
    // team management
    case Types.FETCH_TEAM_MANAGEMENT_REQUEST: {
      return {
        ...state,
        teams: {
          ...state.teams,
          loading: true,
          error: null,
        },
      };
    }

    case Types.FETCH_TEAM_MANAGEMENT_SUCCESS: {
      return {
        ...state,
        teams: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    }

    case Types.FETCH_TEAM_MANAGEMENT_ERROR: {
      return {
        ...state,
        teams: {
          data: null,
          loading: false,
          error: action.payload,
        },
      };
    }
    // current orders
    case Types.FETCH_CURRENT_ORDERS_BY_TEAM_REQUEST: {
      return {
        ...state,
        currentOrders: {
          data: get(state, "currentOrders.data"),
          error: null,
          loading: true,
        },
      };
    }

    case Types.FETCH_CURRENT_ORDERS_BY_TEAM_SUCCESS: {
      return {
        ...state,
        currentOrders: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    }

    case Types.FETCH_CURRENT_ORDERS_BY_TEAM_ERROR: {
      return {
        ...state,
        currentOrders: {
          data: [],
          error: action.payload,
          loading: false,
        },
      };
    }
    // assigned orders
    case Types.FETCH_ASSIGNED_ORDERS_BY_TEAM_REQUEST: {
      return {
        ...state,
        assignedOrders: {
          data: get(state, "assignedOrders.data"),
          error: null,
          loading: true,
        },
      };
    }

    case Types.FETCH_ASSIGNED_ORDERS_BY_TEAM_SUCCESS: {
      return {
        ...state,
        assignedOrders: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    }

    case Types.FETCH_ASSIGNED_ORDERS_BY_TEAM_ERROR: {
      return {
        ...state,
        assignedOrders: {
          data: [],
          error: action.payload,
          loading: false,
        },
      };
    }
    // team members
    case Types.FETCH_HUB_MEMBERS_REQUEST: {
      return {
        ...state,
        members: {
          data: [],
          loading: true,
          error: null,
        },
      };
    }

    case Types.FETCH_HUB_MEMBERS_SUCCESS: {
      return {
        ...state,
        members: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    }

    case Types.FETCH_HUB_MEMBERS_ERROR: {
      return {
        ...state,
        members: {
          data: null,
          loading: false,
          error: action.payload,
        },
      };
    }
    // team members for assignment
    case Types.FETCH_HUB_MEMBERS_ASSIGNMENT_REQUEST: {
      return {
        ...state,
        membersAssignment: {
          data: [],
          loading: true,
          error: null,
        },
      };
    }

    case Types.FETCH_HUB_MEMBERS_ASSIGNMENT_SUCCESS: {
      return {
        ...state,
        membersAssignment: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    }

    case Types.FETCH_HUB_MEMBERS_ASSIGNMENT_ERROR: {
      return {
        ...state,
        membersAssignment: {
          data: null,
          loading: false,
          error: action.payload,
        },
      };
    }
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
}
