import React from "react";
import bn from "utils/bemnames";
import { CloseSmallIcon } from "components/CustomIcons";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import Dropdown from "components/Dropdown";
import { Row, Col, FormGroup, Label } from "reactstrap";
import { map } from "lodash";
import get from "lodash/get";
const bem = bn.create("profile");
const ShareAccess = (props) => {
  const { isView, intl, options, user } = props;
  if (isView) {
    return (
      <div
        className={classnames(
          bem.e("share-access-section"),
          bem.e("view-share-access-section"),
          bem.e("box-section")
        )}
      >
        <h4 className={bem.e("section-title")}>
          <FormattedMessage id="my profile > title share access" />
        </h4>
        <div className={bem.e("share-access-content")}>
          <Row>
            <Col>
              <Label className={bem.e("view-label")}>
                <FormattedMessage id="my profile > grant access to my account" />
              </Label>
              {get(user, "access_granted_to_users", []).length > 0 ? (
                <div className={bem.e("selected-users")}>
                  {get(user, "access_granted_to_users", []).map(
                    (user, index) => (
                      <div className={bem.e("selected-user")} key={index}>
                        <span>
                          {user.first_name} {user.last_name}
                        </span>
                      </div>
                    )
                  )}
                </div>
              ) : (
                <div className={bem.e("view-value")}>
                  <FormattedMessage id="user > none" />
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
  const { formProps } = props;
  const { values } = formProps;
  const selectedUsersValue = values.grant_access_to_users || [];
  const selectedUsersData = map(selectedUsersValue, (id) =>
    options.find((i) => i.value === id)
  ).filter((i) => i);
  return (
    <div
      className={classnames(
        bem.e("share-access-section"),
        bem.e("box-section")
      )}
    >
      <h4 className={bem.e("section-title")}>
        <FormattedMessage id="my profile > title share access" />
      </h4>
      <div className={bem.e("share-access-content")}>
        <Row>
          {get(options, "length", 0) > 0 ? (
            <Col xs={4}>
              <FormGroup>
                <Dropdown
                  label={
                    <FormattedMessage id="my profile > grant access to my account" />
                  }
                  placeholder={intl.formatMessage({
                    id: "my profile > select",
                  })}
                  name="user_id"
                  onChange={(option) => {
                    const value = option ? [option.value] : [];
                    props.setShouldBlockNavigation(true);
                    formProps.setFieldValue("grant_access_to_users", value);
                  }}
                  value={""}
                  options={options}
                  closeMenuOnSelect={true}
                />
              </FormGroup>
            </Col>
          ) : null}
          <Col>
            <div className={bem.e("selected-users")}>
              {selectedUsersData.map((option, index) => (
                <div className={bem.e("selected-user")} key={index}>
                  <span
                    className={bem.e("delete-user")}
                    onClick={() => {
                      selectedUsersValue.splice(index, 1);
                      formProps.setFieldValue(
                        "grant_access_to_users",
                        selectedUsersValue
                      );
                    }}
                  >
                    <CloseSmallIcon />
                  </span>
                  <span>{option.label}</span>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default ShareAccess;
