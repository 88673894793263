import {
  INIT_NEW_PRIZE,
  FETCH_PRIZES_REQUEST,
  FETCH_PRIZES_SUCCESS,
  FETCH_PRIZES_ERROR,
  FORGET_PRIZES,
  PRIZE_SET_PRIZES_SUCCESS,
} from "store/types/prizes";
import { get, includes, uniqBy } from "lodash";
import { LOGOUT_USER } from "store/types/auth";
import { pageSize } from "utils/config";
import * as Types from "store/types/prizes";

const initialState = {
  prizes: {
    data: [],
    error: null,
    loading: false,
  },
  prize: {
    data: {},
    error: null,
    loading: null,
  },
  addPrizes: {
    is_added: false,
    data: null,
    error: null,
    loading: false,
  },
  updatePrizes: {
    data: null,
    error: null,
    loading: false,
  },
  deletePrize: {
    data: {},
    error: null,
    loading: false,
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_PRIZES_REQUEST: {
      let data = [];
      if (
        // case: first search will reset data or change search text
        (get(action, "payload.search") &&
          get(state, `prizes.filter_${action.payload.filter}.search`) !==
            get(action, "payload.search")) ||
        // case: empty search (not first load)
        (get(action, "payload.search") === "" &&
          get(state, `prizes.filter_${action.payload.filter}.search`)) ||
        // case: first load
        parseInt(get(action, "payload.page")) === 1
      ) {
        data = [];
        // case: other
      } else {
        data = get(state, `prizes.filter_${action.payload.filter}.data`, []);
      }
      return {
        ...state,
        prizes: {
          ...state.prizes,
          ["filter_" + action.payload.filter]: {
            data,
            pages: state.prizes["filter_" + action.payload.filter]
              ? state.prizes["filter_" + action.payload.filter].pages
              : null,
            page: state.prizes["filter_" + action.payload.filter]
              ? state.prizes["filter_" + action.payload.filter].page
              : 1,
            rpp: state.prizes["filter_" + action.payload.filter]
              ? state.prizes["filter_" + action.payload.filter].rpp
              : pageSize,
            category: state.prizes["filter_" + action.payload.filter]
              ? state.prizes["filter_" + action.payload.filter].category
              : "",
            error: null,
            loading: true,
          },
        },
      };
    }
    case FETCH_PRIZES_SUCCESS: {
      return {
        ...state,
        prize: {
          ...get(state, "prize"),
          lastEditTab: [],
          activeTab: null,
        },
        prizes: {
          ...state.prizes,
          ["filter_" + action.payload.filter]: {
            ...action.payload,
            data: uniqBy(
              [
                ...state.prizes["filter_" + action.payload.filter].data,
                ...action.payload.data,
              ],
              "_id"
            ),
            error: null,
            loading: false,
          },
        },
      };
    }
    case FETCH_PRIZES_ERROR: {
      return {
        ...state,
        prizes: {
          ...state.prizes,
          ["filter_" + action.payload.filter]: {
            data: [],
            error: action.error,
            loading: false,
          },
        },
      };
    }
    case PRIZE_SET_PRIZES_SUCCESS: {
      return {
        ...state,
        prizes: {
          ...state.prizes,
          ["filter_" + action.payload.filter]: {
            data: get(action, "payload.data", []),
            error: null,
            loading: false,
          },
        },
      };
    }
    case FORGET_PRIZES: {
      const keys = get(action, "payload.keys");
      let new_prizes = { ...state.prizes };
      Object.keys(new_prizes).map((k) => {
        if (keys) {
          // only dirty with keys from payload. Avoid affect other list
          if (k.indexOf("filter_") === 0 && includes(keys, k)) {
            new_prizes[k].is_dirty = true;
          }
        } else {
          if (k.indexOf("filter_") === 0) new_prizes[k].is_dirty = true;
        }
        return true;
      });
      return {
        ...state,
        prizes: new_prizes,
      };
    }

    case INIT_NEW_PRIZE:
      return {
        ...state,
        prize: action.payload,
      };
    // Add Prize Actions
    case Types.ADD_PRIZE_REQUEST: {
      return {
        ...state,
        addPrizes: {
          is_added: false,
          loading: true,
          error: null,
        },
      };
    }
    case Types.ADD_PRIZE_SUCCESS: {
      return {
        ...state,
        prize: {
          ...get(state, "prize"),
          data: {
            ...get(state, "prize.data"),
            data: get(state, "prize.data.data"),
          },
        },
        addPrizes: {
          is_added: true,
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    }
    case Types.ADD_PRIZE_ERROR: {
      return {
        ...state,
        addPrizes: {
          is_added: false,
          loading: false,
          error: action.payload,
        },
      };
    }

    // Update Prizes Actions
    case Types.UPDATE_PRIZE_REQUEST: {
      return {
        ...state,
        updatePrizes: {
          is_updated: false,
          loading: true,
          error: null,
        },
      };
    }
    case Types.UPDATE_PRIZE_SUCCESS: {
      return {
        ...state,
        updatePrizes: {
          is_updated: true,
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    }
    case Types.UPDATE_PRIZE_ERROR: {
      return {
        ...state,
        updatePrizes: {
          is_updated: false,
          loading: false,
          error: action.payload,
        },
      };
    }
    // Particular Prize Actions
    case Types.FETCH_PRIZE_REQUEST: {
      const existingPrize = get(state, "prize");
      const { id } = action.payload;
      return {
        ...state,
        prize: {
          ...existingPrize,
          ...(existingPrize[id] || {}),
          loading: true,
          error: null,
        },
      };
    }
    case Types.FETCH_PRIZE_SUCCESS: {
      const existingPrize = get(state, "prize");
      const { data } = action.payload;
      return {
        ...state,
        prize: {
          ...existingPrize,
          loading: false,
          error: null,
          data,
        },
      };
    }
    case Types.FETCH_PRIZE_ERROR: {
      const existingPrize = get(state, "prize");
      const { error } = action.payload;
      return {
        ...state,
        prize: {
          ...existingPrize,
          loading: false,
          error,
        },
      };
    }

    // delete prizes Actions
    case Types.DELETE_PRIZE_REQUEST: {
      return {
        ...state,
      };
    }
    case Types.DELETE_PRIZE_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.DELETE_PRIZE_ERROR: {
      return {
        ...state,
      };
    }
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
}
