import React from "react";
import bn from "utils/bemnames";
import { FormattedMessage } from "react-intl";
import { ShadowBox, SectionTitle, SectionFields } from "components/Elements";
import { map, get } from "lodash";
import classnames from "classnames";
import { Table } from "reactstrap";
import { FaSortDesc, FaSortAsc } from "react-icons/lib/fa";
const bem = bn.create("winner-details");

const items = [
  {
    name: "Coachella 2019 Fly Away ",
    type: "Text to Win",
    date: "1/15/2019",
  },
  {
    name: "Roadie for a day!",
    type: "Online Registration",
    date: "1/15/2019",
  },
  {
    name: "Win a 4-pack of tickets to the Bristol Renaissance Fair",
    type: "Text to Win",
    date: "1/15/2019",
  },
  {
    name: "La Festival Presale",
    type: "Text to Win",
    date: "1/15/2019",
  },
  {
    name: "Amp Energy Tour Summerfest",
    type: "Online Registration",
    date: "1/15/2019",
  },
  {
    name: "Budweiser Black and Blue Battle",
    type: "Online Registration",
    date: "1/15/2019",
  },
  {
    name: "Pepsi Max Showdown Sweepstakes",
    type: "Text to Win",
    date: "1/15/2019",
  },
  {
    name: "Cabo Wabo Summit",
    type: "Text to Win",
    date: "1/15/2019",
  },
  {
    name: "Six Flag Ultimate Season Pass Giveaway",
    type: "Online Registration",
    date: "1/15/2019",
  },
];
export default function ParticipationActivity(props) {
  const { sorted, onSort } = props;
  return (
    <ShadowBox className={bem.e("participation-activity-box")}>
      <SectionTitle className={bem.e("section-title")}>
        <FormattedMessage id="winners > title participation activity" />
      </SectionTitle>
      <SectionFields className={bem.e("participation-activity-fields")}>
        <div className={"cr-list-table"}>
          <Table>
            <thead>
              <tr>
                <th className={"table-first-head"}>
                  <FormattedMessage id="winners > contest name" />
                </th>
                <th
                  onClick={() => onSort("type")}
                  className={classnames("can-click")}
                >
                  <div className={"item-sortable"}>
                    <FormattedMessage id="winners > type" />
                    <div className={"sortable"}>
                      <FaSortAsc
                        className={classnames("sortable-icon", {
                          "sortable-active":
                            get(sorted, "id") === "type" && get(sorted, "desc"),
                        })}
                      />
                      <FaSortDesc
                        className={classnames("sortable-icon", {
                          "sortable-active":
                            get(sorted, "id") === "type" &&
                            !get(sorted, "desc"),
                        })}
                      />
                    </div>
                  </div>
                </th>
                <th
                  onClick={() => onSort("date")}
                  className="can-click table-last-head"
                >
                  <div className={"item-sortable"}>
                    <FormattedMessage id="winners > date" />
                    <div className={"sortable"}>
                      <FaSortAsc
                        className={classnames("sortable-icon", {
                          "sortable-active":
                            get(sorted, "id") === "date" &&
                            !get(sorted, "desc"),
                        })}
                      />
                      <FaSortDesc
                        className={classnames("sortable-icon", {
                          "sortable-active":
                            get(sorted, "id") === "date" && get(sorted, "desc"),
                        })}
                      />
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {map(items, (item, index) => {
                return (
                  <tr key={index} className="participation-item">
                    {/* title */}
                    <td className={"align-middle title"}>
                      <span>{item.name}</span>
                    </td>
                    {/* type */}
                    <td className={"align-middle text-type"}>
                      <span>{item.type}</span>
                    </td>
                    {/* date */}
                    <td className="align-middle text-date">
                      <span>{item.date}</span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </SectionFields>
    </ShadowBox>
  );
}
