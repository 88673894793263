import React, { useState } from "react";
import TextareaEvaluator, {
  DEFAULT_WORDS_PER_MIN,
} from "components/TextareaEvaluator";
import { get } from "lodash";
import { FormattedMessage } from "react-intl";
import { setScriptField, htmlToText, getFileNameFromUrl } from "utils/helpers";
import { Button } from "reactstrap";
import PreviewScriptModal from "components/PreviewScriptModal";
import PrivilegedComponent from "components/PrivilegedComponent";
import { PRIVILEGES } from "utils/constants";
import FileView from "components/FileView";
import bn from "utils/bemnames";
import moment from "moment";
import classNames from "classnames";
const bem = bn.create("order-text-timer");
export default function OrderTextTimer(props) {
  const {
    isView,
    item,
    process,
    refreshField,
    step,
    template,
    user,
    editScript,
    onEditScript,
    valueEvaluatorChanged,
    onValueChangedSpot,
    validation_error,
    buttonClicked,
    setShouldBlockNavigation,
  } = props;
  const [selectedScript, setSelectedScript] = useState(null);
  let textTimerValue = get(process, `data.fields.${item.field}.value`, "");
  /* get field value from spot linked */
  let spotInfo;
  let spotInfoLoaded;
  let spotInfoIndex;
  let isBookend;
  let textTimerValuePartB = "";
  let isUploadScriptFile = false;
  const processId = get(process, "data.fields.key.value");
  if (item.isSpot) {
    spotInfo = get(process, "data.fields.spot_info.value", []).find(
      (spot) => spot.key === process.key
    );
    spotInfoIndex = get(process, "data.fields.spot_info.value", []).findIndex(
      (spot) => spot.key === process.key
    );
    spotInfoLoaded = get(
      process,
      "data.loaded_fields.spot_info.value",
      []
    ).find((spot) => spot.key === process.key);
    textTimerValue = get(spotInfo, item.field, "");

    isBookend = get(spotInfo, "spot_type", "Standard") === "Book-ends";

    if (isBookend) {
      textTimerValuePartB = get(spotInfo, "script_part_b", "");
    }
    isUploadScriptFile = get(spotInfo, "is_upload_script_file", false);
  }
  if (isUploadScriptFile) {
    const fileViewActions = ["view", "download"];
    const orderLengthCustom =
      get(process, "data.fields.order_length_custom.value") ||
      get(process, "_source.order_length_custom");
    let orderLength =
      get(process, "data.fields.order_length.value") ||
      get(process, "_source.order_length") ||
      "00";
    if (orderLengthCustom) {
      const formatted = moment
        .utc(parseInt(orderLength) * 1000)
        .format("mm:ss");
      orderLength = formatted;
    }
    const length1 = get(spotInfo, "length1", "00") || "";
    const length2 = get(spotInfo, "length2", "00") || "";
    return (
      <div
        className={classNames(bem.b(), bem.m("upload-file"), {
          [bem.m("bookend")]: isBookend,
        })}
      >
        <React.Fragment>
          <div className={bem.e("script-upload-item")}>
            {textTimerValue ? (
              <React.Fragment>
                <div className={bem.e("script-upload-item-top")}>
                  {isBookend && (
                    <div className={bem.e("part-upload-title")}>
                      <FormattedMessage id="process > part A" />
                    </div>
                  )}
                  <div className={bem.e("spot-length")}>
                    <FormattedMessage id="process > length" />:{" "}
                    <strong>{length1}</strong>
                  </div>
                </div>
                <FileView
                  file={{
                    path: textTimerValue,
                    name: getFileNameFromUrl(textTimerValue),
                  }}
                  actions={fileViewActions}
                  onDeleted={() => {
                    onValueChangedSpot(
                      spotInfoIndex,
                      { field: "script", value: "" },
                      process,
                      step,
                      template,
                      user
                    );
                    if (setShouldBlockNavigation)
                      setShouldBlockNavigation(true);
                  }}
                />
              </React.Fragment>
            ) : null}
          </div>
          {isBookend && textTimerValuePartB ? (
            <div className={bem.e("script-upload-item")}>
              <div className={bem.e("script-upload-item-top")}>
                <div className={bem.e("part-upload-title")}>
                  <FormattedMessage id="process > part B" />
                </div>
                <div className={bem.e("spot-length")}>
                  <FormattedMessage id="process > length" />:{" "}
                  <strong>{length2}</strong>
                </div>
              </div>
              <FileView
                file={{
                  path: textTimerValuePartB,
                  name: getFileNameFromUrl(textTimerValuePartB),
                }}
                actions={fileViewActions}
                onDeleted={() => {}}
              />
            </div>
          ) : null}
        </React.Fragment>
      </div>
    );
  }
  return (
    <div className={bem.b()}>
      {!editScript &&
        (!isBookend ? (
          <TextareaEvaluator
            readOnly={isView}
            showScriptLength={true}
            {...item.props}
            text={setScriptField(textTimerValue)}
            process={process}
            key={refreshField}
            wordsPerMin={get(
              spotInfo,
              "default_words_per_min",
              DEFAULT_WORDS_PER_MIN
            )}
            onEvaluatorCallback={(value) => {
              if (setShouldBlockNavigation) setShouldBlockNavigation(true);
              if (item.isSpot) {
                onValueChangedSpot(
                  spotInfoIndex,
                  { field: item.field, value },
                  process,
                  step,
                  template,
                  user
                );
              } else {
                valueEvaluatorChanged(item, process, template, user, value);
              }
            }}
            onWordsPerMinChangeCallback={(value) => {
              if (item.isSpot) {
                if (setShouldBlockNavigation) setShouldBlockNavigation(true);
                onValueChangedSpot(
                  spotInfoIndex,
                  { field: "default_words_per_min", value },
                  process,
                  step,
                  template,
                  user
                );
              }
            }}
          />
        ) : (
          <React.Fragment>
            <TextareaEvaluator
              readOnly={isView}
              showScriptLength={true}
              {...item.props}
              text={setScriptField(textTimerValue)}
              process={process}
              wordsPerMin={get(
                spotInfo,
                "default_words_per_min",
                DEFAULT_WORDS_PER_MIN
              )}
              spot={spotInfo}
              key={refreshField}
              onEvaluatorCallback={(value) => {
                if (setShouldBlockNavigation) setShouldBlockNavigation(true);
                if (item.isSpot) {
                  onValueChangedSpot(
                    spotInfoIndex,
                    { field: item.field, value },
                    process,
                    step,
                    template,
                    user
                  );
                } else {
                  valueEvaluatorChanged(item, process, template, user, value);
                }
              }}
              onWordsPerMinChangeCallback={(value) => {
                if (item.isSpot) {
                  if (setShouldBlockNavigation) setShouldBlockNavigation(true);
                  onValueChangedSpot(
                    spotInfoIndex,
                    { field: "default_words_per_min", value },
                    process,
                    step,
                    template,
                    user
                  );
                }
              }}
              title={<FormattedMessage id="process > part A" />}
              isBookend={isBookend}
              part="a"
              showInnerTitle={true}
            />
            <TextareaEvaluator
              readOnly={isView}
              showScriptLength={true}
              {...item.props}
              text={setScriptField(textTimerValuePartB)}
              process={process}
              spot={spotInfo}
              key={`${refreshField}_1`}
              onEvaluatorCallback={(value) => {
                if (setShouldBlockNavigation) setShouldBlockNavigation(true);
                if (item.isSpot) {
                  onValueChangedSpot(
                    spotInfoIndex,
                    { field: "script_part_b", value },
                    process,
                    step,
                    template,
                    user
                  );
                } else {
                  valueEvaluatorChanged(item, process, template, user, value);
                }
              }}
              onWordsPerMinChangeCallback={(value) => {
                if (item.isSpot) {
                  if (setShouldBlockNavigation) setShouldBlockNavigation(true);
                  onValueChangedSpot(
                    spotInfoIndex,
                    { field: "default_words_per_min_b", value },
                    process,
                    step,
                    template,
                    user
                  );
                }
              }}
              wordsPerMin={get(
                spotInfo,
                "default_words_per_min_b",
                DEFAULT_WORDS_PER_MIN
              )}
              title={<FormattedMessage id="process > part B" />}
              isBookend={isBookend}
              part="b"
              showInnerTitle={true}
            />
          </React.Fragment>
        ))}
      {editScript && (
        <React.Fragment>
          {!isBookend ? (
            <TextareaEvaluator
              readOnly={false}
              className="form-div"
              editScript={true}
              key={refreshField}
              text={setScriptField(textTimerValue)}
              process={process}
              wordsPerMin={get(
                spotInfo,
                "default_words_per_min",
                DEFAULT_WORDS_PER_MIN
              )}
              hideHelperText={get(item, "props.hideHelperText", false)}
              onEvaluatorCallback={(value) => {
                if (setShouldBlockNavigation) setShouldBlockNavigation(true);
                if (item.isSpot) {
                  onValueChangedSpot(
                    spotInfoIndex,
                    { field: item.field, value },
                    process,
                    step,
                    template,
                    user
                  );
                } else {
                  valueEvaluatorChanged(item, process, template, user, value);
                }
              }}
              onWordsPerMinChangeCallback={(value) => {
                if (item.isSpot) {
                  if (setShouldBlockNavigation) setShouldBlockNavigation(true);
                  onValueChangedSpot(
                    spotInfoIndex,
                    { field: "default_words_per_min", value },
                    process,
                    step,
                    template,
                    user
                  );
                }
              }}
            />
          ) : (
            <React.Fragment>
              <TextareaEvaluator
                readOnly={false}
                className="form-div"
                editScript={true}
                key={refreshField}
                text={setScriptField(textTimerValue)}
                process={process}
                spot={spotInfo}
                wordsPerMin={get(
                  spotInfo,
                  "default_words_per_min",
                  DEFAULT_WORDS_PER_MIN
                )}
                hideHelperText={get(item, "props.hideHelperText", false)}
                onEvaluatorCallback={(value) => {
                  if (setShouldBlockNavigation) setShouldBlockNavigation(true);
                  if (item.isSpot) {
                    onValueChangedSpot(
                      spotInfoIndex,
                      { field: item.field, value },
                      process,
                      step,
                      template,
                      user
                    );
                  } else {
                    valueEvaluatorChanged(item, process, template, user, value);
                  }
                }}
                onWordsPerMinChangeCallback={(value) => {
                  if (item.isSpot) {
                    if (setShouldBlockNavigation)
                      setShouldBlockNavigation(true);
                    onValueChangedSpot(
                      spotInfoIndex,
                      { field: "default_words_per_min", value },
                      process,
                      step,
                      template,
                      user
                    );
                  }
                }}
                title={<FormattedMessage id="process > part A" />}
                isBookend={isBookend}
                part="a"
                showInnerTitle={true}
              />
              <TextareaEvaluator
                readOnly={false}
                className="form-div"
                editScript={true}
                key={`${refreshField}_1`}
                text={setScriptField(textTimerValuePartB)}
                process={process}
                spot={spotInfo}
                wordsPerMin={get(
                  spotInfo,
                  "default_words_per_min_b",
                  DEFAULT_WORDS_PER_MIN
                )}
                hideHelperText={get(item, "props.hideHelperText", false)}
                onEvaluatorCallback={(value) => {
                  if (setShouldBlockNavigation) setShouldBlockNavigation(true);
                  if (item.isSpot) {
                    onValueChangedSpot(
                      spotInfoIndex,
                      { field: "script_part_b", value },
                      process,
                      step,
                      template,
                      user
                    );
                  } else {
                    valueEvaluatorChanged(item, process, template, user, value);
                  }
                }}
                onWordsPerMinChangeCallback={(value) => {
                  if (item.isSpot) {
                    if (setShouldBlockNavigation)
                      setShouldBlockNavigation(true);
                    onValueChangedSpot(
                      spotInfoIndex,
                      { field: "default_words_per_min_b", value },
                      process,
                      step,
                      template,
                      user
                    );
                  }
                }}
                title={<FormattedMessage id="process > part B" />}
                isBookend={isBookend}
                part="b"
                showInnerTitle={true}
              />
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      {validation_error && (
        <div className="alert-danger">{validation_error}</div>
      )}
      <div className="script-buttons">
        {!item.hide_open_in_new_window && (
          <Button
            className={"btn-radius"}
            color="blue"
            disabled={(!textTimerValue && !textTimerValuePartB) || !processId}
            onClick={() => {
              const clientName = get(
                process,
                "data.fields.client_name.value",
                true
              );
              const title = get(process, "data.fields.order_title.value", true);
              let scripts = [
                {
                  title: isBookend ? (
                    <FormattedMessage id="process > part A" />
                  ) : (
                    " "
                  ),
                  script: setScriptField(textTimerValue),
                  length: isBookend
                    ? get(spotInfo, "length1", null)
                    : get(process, "data.fields.order_length.value"),
                },
              ];
              if (isBookend) {
                scripts.push({
                  title: <FormattedMessage id="process > part B" />,
                  script: setScriptField(textTimerValuePartB),
                  length: get(spotInfo, "length2", null),
                });
              }
              const orderSold = get(
                process,
                "data.fields.order_sold.value",
                true
              );
              let adType;
              let adTypeSub;
              let adClass;
              if (!orderSold) {
                adType = "SPEC";
                adTypeSub = props.intl.formatMessage({
                  id: "process > order spec",
                });
                adClass = "ad-types-title-spec";
              } else {
                adType = "SOLD";
                adTypeSub = props.intl.formatMessage({
                  id: "process > order spot",
                });
                adClass = "ad-types-title-sold";
              }
              setSelectedScript({
                cardType: "production",
                title: clientName,
                subtitle: title,
                scripts,
                productionData: {
                  type: adType,
                  typeText: adTypeSub,
                  className: adClass,
                },
              });
            }}
          >
            <FormattedMessage id="process > button open in new window" />
          </Button>
        )}
        {get(spotInfo, "script") &&
        (!isBookend || (isBookend && get(spotInfo, "script_part_b"))) &&
        htmlToText(get(spotInfoLoaded, "script")) &&
        (!isBookend ||
          (isBookend && htmlToText(get(spotInfoLoaded, "script_part_b")))) &&
        (!isView || item.isShowEditButtonViewMode) &&
        item.editButton ? (
          editScript ? (
            <React.Fragment>
              <PrivilegedComponent
                requires={{
                  or: [
                    PRIVILEGES.EDIT_PRODUCTION_SCRIPT,
                    PRIVILEGES.EDIT_ALL_PRODUCTION_ORDERS,
                  ],
                }}
              >
                <Button
                  className={"btn btn-radius"}
                  color="blue"
                  onClick={() => {
                    buttonClicked(
                      step,
                      {
                        ...step.fields.find((f) => f.key === "save_button"),
                        on_click: {
                          ...step.fields.find((f) => f.key === "save_button")
                            .on_click,
                          validate: false,
                        },
                        draft: true,
                      },
                      process,
                      template,
                      user
                    );
                  }}
                >
                  {process.loading ? (
                    <FormattedMessage id="process > button saving" />
                  ) : (
                    <FormattedMessage id="process > button save" />
                  )}
                </Button>
              </PrivilegedComponent>
              <Button
                outline
                color="blue"
                onClick={onEditScript}
                className={"btn btn-radius"}
              >
                <FormattedMessage id="process > button cancel" />
              </Button>
            </React.Fragment>
          ) : (
            <PrivilegedComponent
              requires={{
                or: [
                  PRIVILEGES.EDIT_PRODUCTION_SCRIPT,
                  PRIVILEGES.EDIT_ALL_PRODUCTION_ORDERS,
                ],
              }}
            >
              <Button
                outline
                color="blue"
                onClick={onEditScript}
                className={"btn btn-pull-left"}
              >
                <FormattedMessage id="process > button edit script" />
              </Button>
            </PrivilegedComponent>
          )
        ) : null}
        <PreviewScriptModal
          isOpen={!!selectedScript}
          onToggle={() => setSelectedScript(null)}
          onCancel={() => setSelectedScript(null)}
          {...selectedScript}
        />
      </div>
    </div>
  );
}
