import SearchBox from "./view";
import { injectIntl } from "react-intl";
import { compose, withState, withHandlers } from 'recompose';

export default injectIntl(
	compose(
		withHandlers({
			onChangeInput: ({ setSearchText }) => ({ target }) => {
		        const value = target.value;
		        const name = target.name;
		        setSearchText(value);
		    },
			onClearSearch: ({ setSearchText, onSearchClicked }) => () => {
		        setSearchText("");
		        setTimeout(function (){
		        	onSearchClicked();
		        }, 500);
		        
		    },
		    onSearchKeyDown: ({ onSearchClicked }) => (e) => {
		        if (e.keyCode === 13) {
		          onSearchClicked();
		        }
	      	},
		})
	)(SearchBox)
);