import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "reactstrap";
import { get, find, map } from "lodash";
import classnames from "classnames";
import ConfirmationModal from "components/ConfirmationModal";
import history from "components/History";
import bn from "utils/bemnames";
import PropTypes from "prop-types";
import URL from "utils/urls";
import PrivilegedComponent from "components/PrivilegedComponent";
import { PRIVILEGES } from "utils/constants";
import { isBetweenDates, validatePrivileges } from "utils/helpers";
import ToastManager from "components/ToastManager";

const bem = bn.create("promotion-liner-buttons");
export default function View(props) {
  const [isOpenDialogDelete, setIsOpenDialogDelete] = useState(false);
  const [isOpenDialogPublish, setIsOpenDialogPublish] = useState(false);
  const [isOpenDialogSupersede, setIsOpenDialogSupersede] = useState(false);
  const [
    isOpenDialogValidateCopyGroups,
    setIsOpenDialogValidateCopyGroups,
  ] = useState(null);
  const [currentCopyGroupsFormatted, setCurrentCopyGroupsFormatted] = useState(
    null
  );
  const {
    step,
    template,
    user,
    process,
    isView,
    onValueChanged,
    isShowSaveDraftButton,
    intl,
    setValidationError,
    auth,
  } = props;
  const privileges = get(auth, "user.privileges") || [];
  const isAllowSave = validatePrivileges({
    requires: [PRIVILEGES.PROGRAMMING_ELEMENT_SAVE],
    privileges,
    user,
  });
  const isPublishField = step.fields.find((f) => f.field === "is_publish");
  const copyGroupsField = step.fields.find((f) => f.field === "copy_groups");
  const desiredItemsSchedulingField = step.fields.find(
    (f) => f.field === "desired_items_scheduling"
  );

  const isDisabledSubmit = false;
  // They should only be able to do this after they have completed both Details and Script sections.
  const isDisabledPublish =
    get(process, "data.fields.process_step_index.value") < 3 ||
    !get(process, "data.fields.key.value");
  const isPublished = get(process, "data.fields.is_publish.value", false);
  const timezone = get(process, "data.fields.timezone.value");
  const totalDesiredItems = get(
    process,
    "data.fields.desired_items_scheduling.value"
  );
  const validateCopyGroupStatus = () => {
    setCurrentCopyGroupsFormatted(null); // reset first
    const stepKey = get(step, "key");
    if (stepKey !== "write_script") return false;
    const copyGroups = get(process, "data.fields.copy_groups.value", []);
    if (copyGroups.length === 1) return false;
    const copyGroupBaseline = find(
      copyGroups,
      (item) => item.status === "baseline"
    );
    const baselineStartDate = `${get(copyGroupBaseline, "start_date")} ${get(
      copyGroupBaseline,
      "start_date_time"
    )}`;
    const baselineEndDate = `${get(copyGroupBaseline, "end_date")} ${get(
      copyGroupBaseline,
      "end_date_time"
    )}`;
    let isShowSupersede = false;
    const copyGroupsFormatted = copyGroups.map((group) => {
      if (group.status === "baseline") return group;
      const startDate = `${get(group, "start_date")} ${get(
        group,
        "start_date_time"
      )}`;
      const endDate = `${get(group, "end_date")} ${get(
        group,
        "end_date_time"
      )}`;
      let isOverlap = false;
      if (
        isBetweenDates({
          date: startDate,
          startDate: baselineStartDate,
          endDate: baselineEndDate,
          timezone,
        }) &&
        isBetweenDates({
          date: endDate,
          startDate: baselineStartDate,
          endDate: baselineEndDate,
          timezone,
        })
      ) {
        isOverlap = true;
        isShowSupersede = true;
      }
      return {
        ...group,
        status: isOverlap ? "superseding" : "",
      };
    });
    if (isShowSupersede) {
      setCurrentCopyGroupsFormatted(copyGroupsFormatted);
    }
    return isShowSupersede;
  };
  const onSubmit = (event) => {
    props.buttonClicked(
      step,
      step.fields.find((f) => f.key === "save_button"),
      process,
      template,
      user,
      event,
      null,
      true
    );
  };
  const onValidateCopyGroups = (cb) => {
    const copyGroups = get(process, "data.fields.copy_groups.value", []);
    const data = {
      order_start_date: get(process, "data.fields.order_start_date.value", ""),
      order_end_date: get(process, "data.fields.order_end_date.value", ""),
      copy_groups: map(copyGroups, (group) => ({
        start_date: group.start_date,
        end_date: group.end_date,
      })),
    };
    props.validateCopyGroups({
      data,
      cb: (response) => {
        if (response.message) {
          setIsOpenDialogValidateCopyGroups(response.message);
        } else if (cb) {
          cb();
        }
      },
    });
  };
  return (
    <div
      className={classnames(
        bem.e("buttons-container"),
        "bottom-actions-sticky"
      )}
    >
      <div className={classnames(bem.e("buttons"), "buttons")}>
        <div className={bem.e("buttons-left")}>
          <PrivilegedComponent requires={[PRIVILEGES.PROGRAMMING_ELEMENT_SAVE]}>
            <Button
              color="blue"
              className={classnames(bem.e("btn-submit"), "btn-radius")}
              disabled={isDisabledSubmit}
              onClick={(event) => {
                if (isView) {
                  history.push(
                    "/processes/edit/" +
                      template.key +
                      "/" +
                      get(process, "data.fields.key.value")
                  );
                  props.setActiveEditTab(step.key);
                } else {
                  //Modal need to show when user save scripts/copy groups on second step. We will validate if user cover all dates or not
                  if (step.key === "write_script") {
                    onValidateCopyGroups(() => onSubmit(event));
                  } else {
                    onSubmit(event);
                  }
                }
              }}
            >
              {isView ? (
                <FormattedMessage id="process > button edit" />
              ) : (
                <FormattedMessage id="process > liner button submit" />
              )}
            </Button>
          </PrivilegedComponent>
          {/* button edit */}
          {isView && !isAllowSave && (
            <PrivilegedComponent
              requires={[PRIVILEGES.PROGRAMMING_ELEMENT_EDIT]}
            >
              <Button
                color="blue"
                className={classnames(bem.e("btn-submit"), "btn-radius")}
                disabled={isDisabledSubmit}
                onClick={(event) => {
                  history.push(
                    "/processes/edit/" +
                      template.key +
                      "/" +
                      get(process, "data.fields.key.value")
                  );
                  props.setActiveEditTab(step.key);
                }}
              >
                <FormattedMessage id="process > button edit" />
              </Button>
            </PrivilegedComponent>
          )}
          {/* button save draft */}
          {!isView && isShowSaveDraftButton && (
            <PrivilegedComponent
              requires={{
                or: [
                  PRIVILEGES.PROGRAMMING_ELEMENT_SAVE_DRAFT,
                  PRIVILEGES.PROGRAMMING_ELEMENT_EDIT,
                ],
              }}
            >
              <Button
                color="blue"
                outline
                className={classnames(bem.e("btn-draft"), "btn-radius")}
                onClick={(event) => {
                  const validate = desiredItemsSchedulingField.validation(
                    step,
                    desiredItemsSchedulingField,
                    {
                      fields: process.data.fields,
                    },
                    template,
                    user,
                    totalDesiredItems
                  );
                  if (validate !== true) {
                    setValidationError(desiredItemsSchedulingField, validate);
                    const message = `${intl.formatMessage({
                      id: `process > title ${template.key} step order_details`,
                    })}: ${validate}`;
                    ToastManager.show({
                      title: intl.formatMessage({
                        id: "toast > title not saved",
                      }),
                      message,
                      level: "error",
                    });
                    return;
                  }
                  props.buttonClicked(
                    step,
                    {
                      ...step.fields.find((f) => f.key === "save_button"),
                      draft: true,
                    },
                    process,
                    template,
                    user,
                    event,
                    null,
                    true
                  );
                }}
              >
                <FormattedMessage id="process > button save draft" />
              </Button>
            </PrivilegedComponent>
          )}

          {get(process, "data.fields.process_step.value", "") !== "done" && (
            <PrivilegedComponent
              requires={[PRIVILEGES.PROGRAMMING_ELEMENT_DELETE]}
            >
              <Button
                color="blue"
                outline
                className={classnames(bem.e("btn-delete"), "btn-radius")}
                onClick={() => setIsOpenDialogDelete(true)}
              >
                <FormattedMessage id={`process > button delete`} />
              </Button>
            </PrivilegedComponent>
          )}
        </div>
        <PrivilegedComponent
          requires={[PRIVILEGES.PROGRAMMING_ELEMENT_PUBLISH]}
        >
          <Button
            color="blue"
            className={classnames(bem.e("btn-publish"), "btn-radius")}
            onClick={() => {
              if (isPublished) {
                setIsOpenDialogPublish(true);
                return;
              }
              //Modal need to show when user save scripts/copy groups on second step. We will validate if user cover all dates or not
              onValidateCopyGroups(() => {
                if (validateCopyGroupStatus()) {
                  setIsOpenDialogSupersede(true);
                } else {
                  setIsOpenDialogPublish(true);
                }
              });
            }}
            disabled={isDisabledPublish}
          >
            {isPublished ? (
              <FormattedMessage id="process > button unpublish" />
            ) : (
              <FormattedMessage id="process > button publish" />
            )}
          </Button>
        </PrivilegedComponent>
      </div>
      {/* validate copy groups modal */}
      <ConfirmationModal
        isOpen={!!isOpenDialogValidateCopyGroups}
        onToggle={() => setIsOpenDialogValidateCopyGroups(null)}
        onCancel={() => setIsOpenDialogValidateCopyGroups(null)}
        confirmTitle={<FormattedMessage id="process > button confirm" />}
        cancelTitle={<FormattedMessage id="process > button cancel" />}
        title={isOpenDialogValidateCopyGroups}
        onConfirm={() => {
          onSubmit(null);
          setIsOpenDialogValidateCopyGroups(null);
        }}
        isCloseOutside={false}
      />
      {/* delete modal */}
      <ConfirmationModal
        isOpen={isOpenDialogDelete}
        onToggle={() => setIsOpenDialogDelete(!isOpenDialogDelete)}
        onCancel={() => setIsOpenDialogDelete(false)}
        title={
          <FormattedMessage id="process > confirm delete programming element" />
        }
        className="modal-delete"
        onConfirm={() => {
          props.deleteProcess(
            "@andspots@" + get(process, "data.fields.key.value"),
            () => {
              setIsOpenDialogDelete(false);
              const nextRoute = URL.PROCESSES({
                filter: "liners",
              });
              history.push(nextRoute);
            }
          );
        }}
        isCloseOutside={false}
      />
      {/* publish */}
      <ConfirmationModal
        isOpen={isOpenDialogPublish}
        onToggle={() => setIsOpenDialogPublish(!isOpenDialogPublish)}
        onCancel={() => setIsOpenDialogPublish(false)}
        className={bem.e("confirmation-publish-modal")}
        title={
          isPublished ? (
            <span>
              <FormattedMessage id="process > confirm unpublish item" />
              <FormattedMessage id="process > confirm unpublish item description" />
            </span>
          ) : (
            <span>
              <FormattedMessage id="process > confirm publish item" />
              <FormattedMessage id="process > confirm publish item description" />
            </span>
          )
        }
        cancelTitle={<FormattedMessage id="process > button cancel" />}
        description={null}
        onConfirm={(event) => {
          setIsOpenDialogPublish(false);
          onValueChanged(isPublishField, !isPublished);
          process.data.fields.is_publish = {
            value: !isPublished,
            is_dirty: true,
          };
          if (currentCopyGroupsFormatted && copyGroupsField) {
            onValueChanged(copyGroupsField, currentCopyGroupsFormatted);
            process.data.fields.copy_groups = {
              value: currentCopyGroupsFormatted,
              is_dirty: true,
            };
          }
          props.buttonClicked(
            step,
            step.fields.find((f) => f.key === "save_button"),
            process,
            template,
            user,
            event,
            null,
            true
          );
        }}
        isCloseOutside={false}
      />
      {/* Supersede modal */}
      <ConfirmationModal
        isOpen={isOpenDialogSupersede}
        onToggle={() => setIsOpenDialogSupersede(!isOpenDialogSupersede)}
        onCancel={() => setIsOpenDialogSupersede(false)}
        title={<FormattedMessage id="process > confirm supersede title" />}
        description={null}
        onConfirm={(event) => {
          setIsOpenDialogSupersede(false);
          setIsOpenDialogPublish(true);
        }}
        isCloseOutside={false}
      />
    </div>
  );
}
View.propTypes = {
  isShowSaveDraftButton: PropTypes.bool,
};
View.defaultProps = {
  isShowSaveDraftButton: true,
};
