import ServerSecurityView from "./view";
import { injectIntl } from "react-intl";
import {
    getSecurityPolicyServer,
    updateSecurityPolicyServer
} from "../../store/actions/servers";
import {compose, lifecycle, withHandlers} from "recompose";
import connect from "react-redux/es/connect/connect";
import ToastManager from "components/ToastManager";
import {first, get} from "lodash";
import {MdImportantDevices} from "react-icons/lib/md";
import * as React from "react";

export default injectIntl(
    compose(
        connect(
            (state, props) => {
                const ids = get(props, "ids", []);
                if (ids.length === 1) {
                    let id = first(ids);
                    return {
                        id,
                        isMultiple: false,
                        server: get(state, `servers.server.${id}.data`, {}),
                        isLoading:
                            get(state, `users.user.${id}.loading`, false) ||
                            get(state, `users.updateUser.loading`, false),
                        auth: state.auth,
                        back_button: props.back_button
                    };
                } else {
                    return {
                        ids,
                        isMultiple: true,
                        isLoading: get(state, `users.updateUsers.loading`, false),
                        auth: state.auth,
                        back_button: props.back_button
                    };
                }
            },
            {
                getSecurityPolicyServer,
                updateSecurityPolicyServer
            }
        ),
        withHandlers({
            onFormSubmit: ({
                               id,
                               updateSecurityPolicyServer,
                               isMultiple,
                               onToggle,
                               ids,
                               intl
                           }) => values => {
                                updateSecurityPolicyServer(id, values, () => {
                                    onToggle();
                                    ToastManager.show({
                                        title: <MdImportantDevices />,
                                        message: intl.formatMessage({
                                            id: "user > update successfully"
                                        }),
                                        level: "success"
                                    });
                                });
                            }
        }),
        lifecycle({
            componentDidMount() {
                const { ids } = this.props;
                // only call when focused to this tab
                if (ids) {
                   // this.props.getSecurityPolicyServer(ids);
                }
            },
            componentWillReceiveProps(nextProps) {

                if (
                    get(this.props, "ids") !== get(nextProps, "ids") &&
                    get(nextProps, "ids")
                ) {
                   // this.props.getSecurityPolicyServer(nextProps.id);
                }
            }
        })
    )(ServerSecurityView));
