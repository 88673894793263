import * as React from "react";
import bn from "utils/bemnames";
import Spinner from "components/Spinner";
import { get, find } from "lodash";
import * as Yup from "yup";
import { Formik } from "formik";
import ToastManager from "components/ToastManager";
import { Form, Button, Row, Col, FormGroup } from "reactstrap";
import { FormattedMessage } from "react-intl";
import TextInputFormatted from "components/TextInputFormatted";
import Checkbox from "components/Checkbox";
import CopyStations from "components/StationSettings/CopyStations";
import { adminTabs } from "utils/config";
import URL from "utils/urls";
import classNames from "classnames";
import Dropdown from "components/Dropdown";
import { Asterisk } from "components/Elements";

const bem = bn.create("station-settings-form");

const W9Form = (props) => {
  const {
    loading,
    onFormSubmit,
    intl,
    currentServer,
    stationSettingsData,
    history,
  } = props;
  const formikRef = React.useRef(null);
  const initialValues = {
    station_id: props.currentStation.key,
    total_winning: get(stationSettingsData, "total_winning", ""),
    request_for_all_cash_prizes: Boolean(
      get(stationSettingsData, "request_for_all_cash_prizes", false)
    ),
    market_cluster: get(stationSettingsData, "market_cluster", "broadcast"),
    request_every_year: Boolean(
      get(stationSettingsData, "request_every_year", false)
    ),
    request_if_name_or_ssn_changed: Boolean(
      get(stationSettingsData, "request_if_name_or_ssn_changed")
    ),
    settings_copy_to_stations: get(
      stationSettingsData,
      "settings_copy_to_stations",
      []
    ),
  };
  const marketClusterOptions = [
    {
      label: intl.formatMessage({ id: "station settings > station" }),
      value: "station",
    },
    {
      label: intl.formatMessage({ id: "station settings > market" }),
      value: "market",
    },
    {
      label: intl.formatMessage({ id: "station settings > broadcaster" }),
      value: "broadcast",
    },
  ];
  const form = (
    <div className={bem.b()}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        ref={formikRef}
        validationSchema={Yup.object().shape({
          total_winning: Yup.string().nullable(),
          request_for_all_cash_prizes: Yup.boolean().nullable(),
          request_every_year: Yup.boolean().nullable(),
          request_if_name_or_ssn_changed: Yup.boolean().nullable(),
          market_cluster: Yup.string().required(
            intl.formatMessage({ id: "validate > market cluster is required" })
          ),
        })}
        onSubmit={onFormSubmit}
      >
        {(formProps) => {
          const { values, touched, errors } = formProps;
          return (
            <React.Fragment>
              <Form
                onSubmit={formProps.handleSubmit}
                onChange={() => {
                  props.setShouldBlockNavigation(true);
                }}
                className={bem.e("station-w9-form")}
              >
                <div className={bem.e("station-wrapper")}>
                  <div
                    className={classNames(
                      bem.e("form-title"),
                      bem.e("form-padding")
                    )}
                  >
                    <FormattedMessage id="station settings > signed W-9 form requirements" />
                  </div>
                  <div className={bem.e("form-padding")}>
                    <div className={bem.e("session-title")}>
                      <FormattedMessage id="station settings > year to date winnings that require a signed W-9" />
                    </div>
                    <Row>
                      <Col xs={2} className={bem.e("col-reset")}>
                        <FormGroup>
                          <TextInputFormatted
                            label={intl.formatMessage({
                              id: "station settings > total winnings",
                            })}
                            placeholder={intl.formatMessage({
                              id: "station settings > enter number",
                            })}
                            prefix="$"
                            value={values.total_winning}
                            onChange={(value) =>
                              formProps.setFieldValue("total_winning", value)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col className={bem.e("col-reset")}>
                        <div className={bem.e("winning-help-text")}>
                          <FormattedMessage id="station settings > total winnings help text" />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className={bem.e("col-reset")}>
                        <Checkbox
                          checked={values.request_for_all_cash_prizes}
                          onChange={(value) => {
                            formProps.setFieldValue(
                              "request_for_all_cash_prizes",
                              value
                            );
                            props.setShouldBlockNavigation(true);
                          }}
                          text={props.intl.formatMessage({
                            id:
                              "station settings > request a signed W-9 for all cash prizes",
                          })}
                        />
                      </Col>
                    </Row>
                    <div className={bem.e("session-title")}>
                      <FormattedMessage id="station settings > check year to date winnings against" />
                    </div>
                    <Row>
                      <Col xs={3} className={bem.e("col-reset")}>
                        <FormGroup>
                          <Dropdown
                            label={
                              <span>
                                <FormattedMessage id="station settings > market cluster" />
                                <Asterisk>*</Asterisk>
                              </span>
                            }
                            placeholder={intl.formatMessage({
                              id: "station settings > placeholder select",
                            })}
                            name="market_cluster"
                            onChange={(option) => {
                              formProps.setFieldValue(
                                "market_cluster",
                                option.value
                              );
                              props.setShouldBlockNavigation(true);
                            }}
                            error={
                              touched.market_cluster && errors.market_cluster
                            }
                            value={
                              find(
                                marketClusterOptions,
                                (item) => item.value === values.market_cluster
                              ) || ""
                            }
                            options={marketClusterOptions}
                            closeMenuOnSelect={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} className={bem.e("col-reset")}>
                        <FormGroup className={bem.e("checkboxes")}>
                          <Checkbox
                            checked={values.request_every_year}
                            onChange={(value) => {
                              formProps.setFieldValue(
                                "request_every_year",
                                value
                              );
                              props.setShouldBlockNavigation(true);
                            }}
                            text={props.intl.formatMessage({
                              id:
                                "station settings > request a signed W-9 every year",
                            })}
                          />
                          <Checkbox
                            checked={values.request_if_name_or_ssn_changed}
                            onChange={(value) => {
                              formProps.setFieldValue(
                                "request_if_name_or_ssn_changed",
                                value
                              );
                              props.setShouldBlockNavigation(true);
                            }}
                            text={props.intl.formatMessage({
                              id:
                                "station settings > request a signed W-9 if winner changes or social security number",
                            })}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <CopyStations
                    formProps={formProps}
                    intl={intl}
                    stations={props.stations}
                    currentServer={currentServer}
                    currentStation={props.currentStation}
                    setShouldBlockNavigation={props.setShouldBlockNavigation}
                  />
                </div>

                <div className="bottom-actions-sticky">
                  <div className={"buttons"}>
                    <Button
                      color="blue"
                      type="submit"
                      className="btn btn-radius"
                      disabled={loading}
                      id="btn-submit-w9-form"
                      onClick={() => {
                        setTimeout(() => {
                          const formErrors = get(
                            formikRef,
                            "current.state.errors"
                          );
                          if (formErrors && Object.keys(formErrors).length) {
                            ToastManager.show({
                              title: intl.formatMessage({
                                id: "toast > title not saved",
                              }),
                              message: intl.formatMessage({
                                id:
                                  "toast > message error please correct the hilighted fields",
                              }),
                              level: "error",
                            });
                          }
                        }, 40);
                      }}
                    >
                      <FormattedMessage id={`station settings > button save`} />
                    </Button>
                    <Button
                      color="blue"
                      outline
                      className="btn btn-radius"
                      disabled={loading}
                      onClick={() => {
                        history.push(
                          URL.ADMINS({ tab: adminTabs.TAB_UNKNOWN })
                        );
                        props.setSidebarActive(
                          URL.ADMINS({ tab: adminTabs.TAB_UNKNOWN })
                        );
                      }}
                    >
                      <FormattedMessage
                        id={`station settings > button cancel`}
                      />
                    </Button>
                  </div>
                </div>
              </Form>
              <Spinner isLoading={loading} />
            </React.Fragment>
          );
        }}
      </Formik>
    </div>
  );
  return form;
};
export default W9Form;
