import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { isEmpty, find, get } from "lodash";
import moment from "moment";

import { CalendarPickerIcon } from "components/CustomIcons";
import ToggleSwitch from "components/ToggleSwitch";
import Dropdown from "components/Dropdown";
import Spinner from "components/Spinner";
import AvailabilityTable from "components/ScheduleComponents/AvailabilityTable";
import { ShadowBox, SectionTitle, SectionFields } from "components/Elements";
import TitleTooltip from "components/TitleTooltip";

const ItemAvailability = (props) => {
  const {
    bem,
    intl,
    slotDefinitions,
    dayparts,
    shows,
    availability,
    isView,
    onLoadMore,
    availabilityFromDate,
    showItemAvailability,
    setShowItemAvailability,
  } = props;

  const [showSelected, setShowSelected] = useState("daypart");
  const [selectedLinerType, setSelectedLinerType] = useState(null);

  if (isView) return null;

  const slotDefinitionsOptions = slotDefinitions.map((slotDefinition) => ({
    label: slotDefinition.name,
    value: slotDefinition.id,
    type: slotDefinition.type,
  }));
  const selectedStation = get(
    props,
    "process.data.fields.order_stations_array.value.0"
  );

  const selectedCategory = get(props, "process.data.fields.type_id.value", 0);

  const foundType = find(slotDefinitionsOptions, { value: selectedCategory });

  const isDisabled = !selectedStation || !selectedCategory;

  const shouldLoadMore =
    moment(availabilityFromDate).diff(moment(), "months") <= 12;

  return (
    <div className={bem.e("quick-availability-container")}>
      <div
        className={classnames("button-row", {
          "disable-availability": isDisabled,
        })}
      >
        <div>
          <FormattedMessage id="process > promotion > liner quick view" />:
        </div>
        <div
          className="button-quick-view"
          onClick={() => {
            if (isDisabled) return;
            if (!showItemAvailability) {
              props.onCheckItemsAvailability(
                {
                  station_id: selectedStation.key,
                  definition_id: selectedCategory,
                },
                () => {
                  setShowItemAvailability(!showItemAvailability);
                }
              );
            } else {
              setShowItemAvailability(!showItemAvailability);
            }
          }}
        >
          <div className="btn-expand">
            {showItemAvailability ? (
              <FormattedMessage id="process > promotion > liner item close" />
            ) : (
              <FormattedMessage id="process > promotion > liner item availability" />
            )}
          </div>
          <div className="icon">
            <CalendarPickerIcon />
          </div>
        </div>
      </div>
      {showItemAvailability && (
        <ShadowBox className={bem.e("box-quick-availability")}>
          <SectionTitle className={bem.e("quick-view-section-title")}>
            <FormattedMessage id="process > promotion > liner > title available items" />
            <div className={bem.e("quick-view-section-toolbar")}>
              {!isEmpty(shows) && (
                <ToggleSwitch
                  className={bem.e("switch-toolbar")}
                  leftComponent={
                    <span
                      className={classnames("switch-text", {
                        active: showSelected === "daypart",
                      })}
                    >
                      <FormattedMessage id="process > promotion > liner item availability > daypart" />
                    </span>
                  }
                  rightComponent={
                    <span
                      className={classnames("switch-text", {
                        active: showSelected === "show",
                      })}
                    >
                      <FormattedMessage id="process > promotion > liner item availability > show" />
                    </span>
                  }
                  name="is_show"
                  switchProps={{
                    checked: showSelected === "show",
                    onChange: (checked) => {
                      setShowSelected(checked ? "show" : "daypart");
                    },
                    disabled: false,
                    offColor: "#C2D4E0",
                    onColor: "#795AFA",
                    uncheckedIcon: false,
                    checkedIcon: false,
                    boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.282)",
                    height: 26.44,
                    width: 48.48,
                    handleDiameter: 22.92,
                  }}
                />
              )}
              <Dropdown
                name="liner_type"
                placeholder={intl.formatMessage({
                  id:
                    "process > promotion > liner item availability > filter placeholder",
                })}
                onChange={(selectedOption) => {
                  setSelectedLinerType(selectedOption);
                  if (!selectedStation) return;
                  let definitionId = null;
                  // select all
                  if (Array.isArray(selectedOption)) {
                    definitionId = null;
                  } else {
                    // select single or remove
                    definitionId = selectedOption ? selectedOption.value : null;
                  }
                  props.onCheckItemsAvailability({
                    station_id: selectedStation.key,
                    definition_id: definitionId,
                  });
                }}
                allowSelectAll={true}
                allOption={{
                  label: intl.formatMessage({
                    id:
                      "process > promotion > liner item availability > all items",
                  }),
                  value: "*",
                }}
                value={selectedLinerType || foundType}
                options={slotDefinitionsOptions}
                isClearable
                formatOptionLabel={(option) => {
                  const { label, type, value } = option;
                  if (
                    value === "*" ||
                    get(selectedLinerType, "length") ===
                      get(slotDefinitions, "length", 0)
                  ) {
                    return (
                      <div className="value-container">
                        <div className="type">
                          {intl.formatMessage({
                            id:
                              "process > promotion > liner item availability > all items",
                          })}
                        </div>
                      </div>
                    );
                  }
                  return (
                    <div className="value-container">
                      <div className="label">
                        <TitleTooltip title={type} />
                      </div>
                      <div className="type">
                        <TitleTooltip title={label} />
                      </div>
                    </div>
                  );
                }}
              />
            </div>
          </SectionTitle>
          <SectionFields
            className={classnames(bem.e("quick-view-section-fields"))}
          >
            <AvailabilityTable
              availability={availability}
              showSelected={showSelected}
              shows={shows}
              onLoadMore={onLoadMore}
              shouldLoadMore={shouldLoadMore}
              dayparts={dayparts}
              bem={bem}
              intl={intl}
            />
          </SectionFields>
        </ShadowBox>
      )}
      <Spinner isLoading={props.isLoading} />
    </div>
  );
};

export default ItemAvailability;
