import * as React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import moment from "moment";
import { get, last, split } from "lodash";
import Avatar from "../Avatar";
import { getSortUserName } from "utils/helpers";
import { toHHMMSS } from "utils/helpers";
import { DateTimeFormat } from "utils/config";
import FormattedTaggedMessage from "../FormattedTaggedMessage";
import MessageActions from "./MessageActions";
import ChatInput from "components/ChatInput";
import { CloseIcon } from "components/CustomIcons";
import { delay } from "utils/helpers";
class Message extends React.Component {
  onClickSeek = async () => {
    const { data, recordingFeedback, voiceFeedback } = this.props;
    const currentRecordingName = last(split(data.recording_path, "/"));

    let playSecond = parseFloat(get(data, "player_played_seconds", 0));
    if (playSecond - 3 > 0) {
      playSecond = playSecond - 3;
    } else {
      playSecond = 0;
    }

    if (voiceFeedback) {
      const voiceFeedbackValue = get(
        this.props,
        "process.data.fields.voice_feedback.value",
        []
      ).find((item) => item.path === data.recording_path);
      if (!voiceFeedbackValue || get(window, "players.length", 0) === 0) {
        return false;
      }
      const player = window.players.find(
        (item) =>
          get(item, "props.url", "").indexOf(currentRecordingName) !== -1
      );
      if (!player) return false;

      if (!get(player, "player.isPlaying")) {
        // we toggle play button with id. The id encode with base64 by recording url
        const id = btoa(encodeURI(data.recording_path));
        const playButton = document.getElementById(id);
        if (playButton) playButton.click();
        // set delay for init player on first time
        await delay(500);
        player.seekTo(playSecond);
      } else {
        player.seekTo(playSecond);
      }
      return;
    }
    /**
     * Disabled click to seek when changing recording path
     */
    if (
      recordingFeedback &&
      get(this.props, "process.data.fields.recording_path.value") !==
        get(data, "recording_path")
    ) {
      return false;
    }
    const player = window.players.find(
      (item) => get(item, "props.url", "").indexOf(currentRecordingName) !== -1
    );
    if (!player) return false;
    if (!get(player, "player.isPlaying")) {
      // we toggle play button with id. The id encode with base64 by recording url
      const id = btoa(encodeURI(data.recording_path));
      const playButton = document.getElementById(id);
      if (playButton) playButton.click();
      // set delay for init player on first time
      await delay(500);
      player.seekTo(playSecond);
    } else {
      player.seekTo(playSecond);
    }
  };

  getPrettyTimeForPlayed = () => {
    const { data } = this.props;
    if (!get(data, "include_timestamp")) return null;
    return (
      <span className={cx("message-played")} onClick={() => this.onClickSeek()}>
        {toHHMMSS(get(data, "player_played_seconds", 0))}
      </span>
    );
  };
  getPrettyTime = () => {
    const { timestamp } = this.props;
    const currentTime = new Date().getTime();
    const diffInSec = (currentTime - timestamp) / 1000;
    if (diffInSec <= 3600 * 24 * 2) {
      return moment(timestamp).fromNow();
    } else {
      return moment(timestamp).format(DateTimeFormat);
    }
  };

  render() {
    const {
      position,
      from,
      isLastInGroup,
      isFirstInGroup,
      status,
      id,
      text,
      isShowMessageActions,
      setDeletingMessage,
      setEditingMessage,
      isEditing,
      chatInputPlaceHolder,
      bem,
      onEditMessage,
      user,
    } = this.props;
    const isOwnerMessage = user.id === from.id;
    const time = this.getPrettyTime();
    const timePlayed = this.getPrettyTimeForPlayed();
    return (
      <div
        className={cx("message", `${id}`, {
          mine: position === "right",
          yours: position === "left",
        })}
        id={id}
      >
        {position === "left" && (
          <div className={"from"}>
            {get(from, "avatar_url") ? (
              <Avatar
                src={from.avatar_url}
                size={40}
                className={bem.e("message-avatar")}
                alt={get(from, "name")}
              />
            ) : (
              <div className={bem.e("message-avatar-text")}>
                {getSortUserName(get(from, "name", ""))}
              </div>
            )}
          </div>
        )}
        <div className={cx("message-content")}>
          <div
            className={cx("text", {
              last: isLastInGroup,
              first: isFirstInGroup,
            })}
          >
            {isEditing && (
              <span
                className={bem.e("view-comment-close")}
                onClick={(e) => {
                  e.stopPropagation();
                  setEditingMessage(null);
                }}
              >
                <CloseIcon color="#C2D4E0" />
              </span>
            )}
            {isEditing ? (
              <ChatInput
                onSubmit={(data) =>
                  onEditMessage({
                    message: data.value,
                    id,
                  })
                }
                submitOnEnter={true}
                mentionPosition={"top"}
                chatInputPlaceHolder={chatInputPlaceHolder}
                showEmojiPicker={false}
                isFilterUsers={true}
                isAllowMentions={false}
                initialValues={{
                  plainValue: "",
                  value: text,
                  mentions: [],
                  attachments: [],
                }}
                template={get(process, "data.fields.template_key.value")}
              />
            ) : (
              <span>
                {/* {status === "waiting" && (
                  <span
                    className={"message-loading"}
                    style={{ marginLeft: "-14px", paddingRight: "4px" }}
                  >
                    <FaSpinner size="10" className={"cr-icon-spin"} />
                  </span>
                )} */}
                {timePlayed}
                <FormattedTaggedMessage text={text} />
              </span>
            )}
            {isShowMessageActions && !isEditing && isOwnerMessage && (
              <MessageActions
                id={id}
                onDelete={setDeletingMessage}
                onEdit={setEditingMessage}
              />
            )}
          </div>
          {status !== "waiting" && (
            <div className="time">
              {position === "left" && get(from, "name") + ", "}
              {time}
            </div>
          )}
        </div>
        {position === "right" && (
          <div className={"from"}>
            {from.avatar_url ? (
              <Avatar
                src={from.avatar_url}
                size={40}
                className={cx("message-avatar")}
                alt={get(from, "name")}
              />
            ) : (
              <div className={bem.e("message-avatar-text")}>
                {getSortUserName(get(from, "name", ""))}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

Message.propTypes = {
  id: PropTypes.string.isRequired,
  position: PropTypes.oneOf(["left", "right"]),
  text: PropTypes.string.isRequired,
  data: PropTypes.object,
  timestamp: PropTypes.any.isRequired,
  status: PropTypes.oneOf(["waiting", "delivered"]),
  from: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    avatar: PropTypes.string,
  }),
  isLastInGroup: PropTypes.bool,
  isFirstInGroup: PropTypes.bool,
};

export default Message;
