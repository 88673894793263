import CreateClock from "./view";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { get } from "lodash";
import history from "components/History";
import URL from "utils/urls";

import { compose, withState, withHandlers, lifecycle } from "recompose";
import { createClock as _createClock } from "store/actions/clocks";

export default injectIntl(
  compose(
    connect(
      (state, ownProps) => {
        return {
          ...ownProps,
          stations: get(state, "auth.info.stations", []),
          saveClockError: get(state, "clocks.createClock.error", false),
        };
      },
      {
        _createClock,
      }
    ),
    withState("shouldBlockNavigation", "setShouldBlockNavigation", false),
    withState("isClockSaved", "setIsClockSaved", false),
    withState("isLoading", "setIsLoading", false),
    withHandlers({
      onSaveClock: ({ setIsLoading, _createClock }) => (values) => {
        setIsLoading(true);
        let data = {
          title: values.title,
          station_id: get(values, "station.value", ""),
          type: get(values, "type.value", ""),
        };

        _createClock(data, (clock) => {
          setIsLoading(false);
          history.push(
            URL.CLOCK_EDITOR({
              type: clock.type,
              clock_id: clock.id,
            })
          );
        });
      },
    }),
    lifecycle({
      componentDidUpdate(prevProps) {
        if (
          (this.props.saveClockError || this.props.saveClockError) &&
          this.props.isLoading
        ) {
          this.props.setIsLoading(false);
        }
      },
    })
  )(CreateClock)
);
