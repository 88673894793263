import React, { useRef } from "react";
import {
  get,
  includes,
  reduce,
  find,
  map,
  trim,
  split,
  intersection,
  uniqBy,
  filter,
  toLower,
  uniq,
} from "lodash";
import TextInput from "components/TextInput";
import { Formik } from "formik";
import * as Yup from "yup";
import Checkbox from "components/Checkbox";
import Spinner from "components/Spinner";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { Asterisk } from "components/Elements";
import NumberFormat from "react-number-format";
import UploadFile from "components/UploadFile";
import ToastManager from "components/ToastManager";
import BlockingComponent from "components/BlockingComponent";
import TextInputFormatted from "components/TextInputFormatted";
import { Row, Col, Form, FormGroup, Button, Label } from "reactstrap";
import ConfirmationModal from "components/ConfirmationModal";
import PrivilegedComponent from "components/PrivilegedComponent";
import { PRIVILEGES } from "utils/constants";
import Dropdown from "components/Dropdown";
import ChangePasswordModal from "components/ChangePasswordModal";
import AutoSave from "components/AutoSave";
import { UpIcon, DownIcon } from "components/CustomIcons";
import TimezonePicker from "components/TimezonePicker";
import { convertArrayToString } from "utils/helpers";
import ManagePrivilegesModal from "components/ManagePrivilegesModal";
import { CloseSmallIcon } from "components/CustomIcons";
import SelectTags, {
  TAG_TYPE_EXCEPT_LOCATION_FORMAT,
} from "components/SelectTags";
import {
  languageList,
  PhoneNumberFormat,
  language as defaultLangCode,
} from "utils/config";
import bn from "utils/bemnames";
import UserAssignmentsModal from "components/UserAssignmentsModal";

const bem = bn.create("user-form");
export const MODE_EDIT = "edit";
export const MODE_VIEW = "view";
export const MODE_ADD = "add";
const enterpriseRoles = [
  "hub manager",
  // "production assistant",
  "producer",
  "dubber",
  "continuity",
  "writer",
  "vo talent",
  // "jock",
];
function UserForm(props) {
  const { auth, isShowEnterpriseRoles, roles } = props;
  const rolesOptions = filter(roles, (role) =>
    enterpriseRoles.includes(toLower(role.name))
  ).map((item) => {
    return {
      value: parseInt(item.id),
      label: item.name,
      privileges: map(item.privileges, (privilege) => parseInt(privilege.id)),
    };
  });
  const renderForm = () => {
    const {
      isLoading,
      isUpdate,
      user,
      intl,
      isViewMode,
      shouldBlockNavigation,
      setShouldBlockNavigation,
      title,
      tagsOptions,
      selectedTagOptions,
      isSpecialUser,
      id,
      isAdmin,
      hasMultimarket,
      currentServer,
    } = props;
    const formikRef = useRef();
    const isShowCreativeDetails =
      !isAdmin && (isSpecialUser || props.isView === "add");
    const isServerList =
      auth.info.app_flags && auth.info.app_flags.indexOf("SERVERS_LIST") > -1;
    const authUser = get(auth, "user");
    const userLangCode = get(user, "lang", null);
    const defaultLanguage = find(
      languageList,
      (item) => item.value === defaultLangCode
    );
    const initialValues = {
      first_name: user.first_name || "",
      last_name: user.last_name || "",
      email: user.email || "",
      title: user.title || "",
      phone: user.phone || "",
      language: userLangCode
        ? {
            value: userLangCode,
            label: get(
              find(languageList, (item) => item.value === userLangCode),
              "label",
              "English"
            ),
          }
        : {
            value: defaultLangCode,
            label: get(defaultLanguage, "label", "English"),
          },
      location: user.location || "",
      location_text: user.location_text || "",
      summary: user.summary || "",
      reel: user.reel || "",
      is_group_admin: !!user.broadcasting_group_admin,
      is_support_admin: !!user.is_support_admin,
      enterprise_roles: map(user.enterprise_roles, (item) => {
        return find(
          rolesOptions,
          (role) => Number(role.value) === Number(item.id)
        );
      }).filter((item) => item),
      enterprise_privileges: map(user.enterprise_privileges, (item) => item.id),
      isUpdate,
      tags: selectedTagOptions,
      is_set_password: false,
    };
    const validEmailDomains =
      currentServer && currentServer.domain_filter
        ? split(currentServer.domain_filter, ",").map((email) => trim(email))
        : [];
    const authIsGodAdmin = get(auth, "user.is_god_admin", false);
    const authIsSupportAdmin = get(auth, "user.is_support_admin", false);
    const form = (
      <div>
        <Formik
          ref={formikRef}
          enableReinitialize={id !== "new"}
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            isUpdate: Yup.bool(),
            first_name: Yup.string()
              .required(
                props.intl.formatMessage({
                  id: "validate > first name is required",
                })
              )
              .min(
                2,
                props.intl.formatMessage({
                  id:
                    "validate > the first name must be at least 2 characters.",
                })
              ),
            last_name: Yup.string()
              .required(
                props.intl.formatMessage({
                  id: "validate > last name is required",
                })
              )
              .min(
                2,
                props.intl.formatMessage({
                  id: "validate > the last name must be at least 2 characters.",
                })
              ),
            email: Yup.string()
              .email(
                props.intl.formatMessage({ id: "validate > invalid email" })
              )
              .required(
                props.intl.formatMessage({
                  id: "validate > email is required",
                })
              )
              .test(
                "email",
                props.intl.formatMessage(
                  {
                    id: "validate > email should be in domain",
                  },
                  {
                    domains: convertArrayToString(validEmailDomains),
                  }
                ),
                (value) => {
                  if (!value) return false;
                  if (validEmailDomains.length === 0) return true;
                  const domain = value.replace(/.*@/, "");
                  return includes(validEmailDomains, domain);
                }
              ),
            language: Yup.string().required(
              intl.formatMessage({ id: "validate > language is required" })
            ),
            phone: Yup.string()
              .nullable()
              .test(
                "len",
                intl.formatMessage({
                  id: "validate > phone number must be 10 digits",
                }),
                (val) => !val || val.length === 10
              ),
            title: Yup.string().nullable(),
            location: hasMultimarket
              ? Yup.string().required(
                  props.intl.formatMessage({
                    id: "validate > timezone is required",
                  })
                )
              : Yup.string().nullable(),
            location_text: Yup.string().nullable(),
            summary: Yup.string().max(
              150,
              intl.formatMessage(
                { id: "validate > max characters" },
                {
                  FIELD_NAME: "Summary",
                  MAX: 150,
                }
              )
            ),
            is_set_password: Yup.bool(),
            password: Yup.string().nullable(),
            enterprise_roles: Yup.array().nullable(),
            enterprise_privileges: Yup.array().nullable(),
            // enterprise_privileges: Yup.array().when("enterprise_roles", {
            //   is: (value) => value && value.length > 0,
            //   then: Yup.array().required(
            //     intl.formatMessage({
            //       id: "validate > please select privileges",
            //     })
            //   ),
            //   otherwise: Yup.array().nullable(),
            // }),
          })}
          onSubmit={props.onFormSubmit}
        >
          {(formProps) => {
            const errors = formProps.errors;
            const touched = formProps.touched;
            const values = formProps.values;
            const privilegeOptions = reduce(
              values.enterprise_roles,
              (accumulator, currentValue) => {
                return [...accumulator, ...get(currentValue, "privileges", [])];
              },
              []
            );
            const onDeleteRole = (role) => {
              const selectedOption = values.enterprise_roles.filter(
                (item) => item.value !== role.value
              );
              // get valid privileges for new roles
              const validPrivileges = reduce(
                selectedOption,
                (accumulator, currentValue) => {
                  return [
                    ...accumulator,
                    ...get(currentValue, "privileges", []),
                  ];
                },
                []
              );
              // only get valid privileges from new roles
              const privileges = intersection(
                validPrivileges,
                values.enterprise_privileges
              );
              formProps.setFieldValue("enterprise_privileges", privileges);
              formProps.setFieldValue("enterprise_roles", selectedOption);
            };
            return (
              <Form
                onSubmit={formProps.handleSubmit}
                className="form-add-update-user"
                onChange={() => {
                  if (!shouldBlockNavigation) setShouldBlockNavigation(true);
                }}
              >
                <div className={bem.e("content-form")}>
                  <h2 className={bem.e("title")}>
                    {title ? (
                      title
                    ) : (
                      <FormattedMessage id={"user > detail title"} />
                    )}
                  </h2>
                  <div className={bem.e("form-fields")}>
                    <Row>
                      <Col xs={6} className={bem.e("col-reset")}>
                        <FormGroup className={bem.e("form-group-edit")}>
                          <TextInput
                            label={
                              <span>
                                <FormattedMessage id="user > first name" />
                                <Asterisk>*</Asterisk>
                              </span>
                            }
                            type="text"
                            name="first_name"
                            placeholder={props.intl.formatMessage({
                              id: "user > enter first name",
                            })}
                            value={values.first_name}
                            error={touched.first_name && errors.first_name}
                            onChange={formProps.handleChange}
                            onBlur={formProps.handleBlur}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={6} className={bem.e("col-reset")}>
                        <FormGroup className={bem.e("form-group-edit")}>
                          <TextInput
                            label={
                              <span>
                                <FormattedMessage id="user > last name" />
                                <Asterisk>*</Asterisk>
                              </span>
                            }
                            type="text"
                            name="last_name"
                            placeholder={props.intl.formatMessage({
                              id: "user > enter last name",
                            })}
                            value={values.last_name}
                            error={touched.last_name && errors.last_name}
                            onChange={formProps.handleChange}
                            onBlur={formProps.handleBlur}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={6} className={bem.e("col-reset")}>
                        <FormGroup className={bem.e("form-group-edit")}>
                          <TextInput
                            label={
                              <span>
                                <FormattedMessage id="user > job title" />
                              </span>
                            }
                            type="text"
                            name="title"
                            placeholder={props.intl.formatMessage({
                              id: "user > enter job title",
                            })}
                            value={values.title}
                            error={touched.title && errors.title}
                            onChange={formProps.handleChange}
                            onBlur={formProps.handleBlur}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={6} className={bem.e("col-reset")}>
                        <FormGroup className={bem.e("form-group-edit")}>
                          <TextInput
                            label={
                              <span>
                                <FormattedMessage id="user > email" />
                                <Asterisk>*</Asterisk>
                              </span>
                            }
                            type="email"
                            name="email"
                            placeholder={props.intl.formatMessage({
                              id: "user > enter email",
                            })}
                            value={values.email}
                            error={touched.email && errors.email}
                            onChange={formProps.handleChange}
                            onBlur={formProps.handleBlur}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} className={bem.e("col-reset")}>
                        <FormGroup className={bem.e("form-group-edit")}>
                          <TextInputFormatted
                            format={PhoneNumberFormat}
                            label={
                              <span>
                                <FormattedMessage id="user > phone" />
                              </span>
                            }
                            type="text"
                            name="phone"
                            placeholder={intl.formatMessage({
                              id: "user > enter phone",
                            })}
                            value={values.phone}
                            error={touched.phone && errors.phone}
                            onChange={(value) => {
                              formProps.setFieldValue("phone", value);
                              setShouldBlockNavigation(true);
                            }}
                            onBlur={formProps.handleBlur}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={6} className={bem.e("col-reset")}>
                        <FormGroup className={bem.e("form-group-edit")}>
                          <Dropdown
                            label={
                              <span>
                                <FormattedMessage id="my profile > language" />
                                <Asterisk>*</Asterisk>
                              </span>
                            }
                            value={values.language}
                            placeholder={intl.formatMessage({
                              id: "my profile > language placeholder",
                            })}
                            onChange={(selectedOption) => {
                              formProps.setFieldValue(
                                "language",
                                selectedOption
                              );
                              setShouldBlockNavigation(true);
                            }}
                            options={languageList}
                            name="language"
                            error={touched.language && errors.language}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} className={bem.e("col-reset")}>
                        <FormGroup className={bem.e("form-group-edit")}>
                          <TextInput
                            label={<FormattedMessage id="user > location" />}
                            type="text"
                            name="location_text"
                            placeholder={props.intl.formatMessage({
                              id: "user > enter location",
                            })}
                            value={values.location_text}
                            error={
                              touched.location_text && errors.location_text
                            }
                            onChange={formProps.handleChange}
                            onBlur={formProps.handleBlur}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={6} className={bem.e("col-reset")}>
                        <FormGroup className={bem.e("form-group-edit")}>
                          <Label>
                            <FormattedMessage id="station > timezone" />
                            {hasMultimarket && <Asterisk>*</Asterisk>}
                          </Label>
                          <TimezonePicker
                            value={values.location}
                            onChange={({ value }) => {
                              formProps.setFieldValue("location", value);
                              setShouldBlockNavigation(true);
                            }}
                            placeholder={props.intl.formatMessage({
                              id: "user > enter timezone",
                            })}
                          />
                          {touched.location && errors.location && (
                            <div className="text-danger">{errors.location}</div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} className={bem.e("col-reset")}>
                        <FormGroup
                          className={bem.e("form-group-edit-password")}
                        >
                          {values.isUpdate && (
                            <Button
                              color="blue"
                              className={classnames(
                                "btn btn-radius",
                                bem.e("btn-reset-password")
                              )}
                              onClick={() => {
                                props.setIsChangePasswordModalOpen(true);
                              }}
                            >
                              <FormattedMessage
                                id={`user > button reset password`}
                              />
                            </Button>
                          )}

                          {isServerList && authUser.is_god_admin && (
                            <Checkbox
                              disabled={!authUser.is_god_admin}
                              text={
                                <FormattedMessage id="user > is support admin" />
                              }
                              checked={!!values["is_support_admin"]}
                              onChange={(value) => {
                                formProps.setFieldValue(
                                  "is_support_admin",
                                  value
                                );
                                setShouldBlockNavigation(true);
                              }}
                            />
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <div className={bem.e("line")} />
                  {isShowCreativeDetails && (
                    <div className={bem.e("creative-edit")}>
                      <h2 className={bem.e("creative-title")}>
                        <FormattedMessage
                          id={"user > title creative details"}
                        />
                        {props.isCreativeDetailsCollapsed ? (
                          <span
                            className={bem.e("button-down-icon")}
                            onClick={() =>
                              props.setIsCreativeDetailsCollapsed(false)
                            }
                          >
                            <DownIcon color="#657894" />
                          </span>
                        ) : (
                          <span
                            className={bem.e("button-up-icon")}
                            onClick={() =>
                              props.setIsCreativeDetailsCollapsed(true)
                            }
                          >
                            <UpIcon color="#657894" />
                          </span>
                        )}
                      </h2>
                      {!props.isCreativeDetailsCollapsed && (
                        <Row className={bem.e("creative-content")}>
                          <Col xs={6} className={bem.e("col-reset")}>
                            <FormGroup
                              className={bem.e("form-group-creative-edit")}
                            >
                              <TextInput
                                label={
                                  <FormattedMessage id="user > personal bio" />
                                }
                                type="textarea"
                                name="summary"
                                placeholder={intl.formatMessage({
                                  id: "user > summary placeholder",
                                })}
                                value={values.summary}
                                error={touched.summary && errors.summary}
                                onChange={formProps.handleChange}
                                onBlur={formProps.handleBlur}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label className={bem.e("label")}>
                                <FormattedMessage id="user > sample reel" />
                              </Label>
                              <UploadFile
                                attachText={intl.formatMessage({
                                  id: "my profile > attach files",
                                })}
                                onUploaded={(file) => {
                                  formProps.setFieldValue("reel", file.path);
                                  setShouldBlockNavigation(true);
                                }}
                                showRemoveIcon={true}
                                onDeleted={() => {
                                  formProps.setFieldValue("reel", null);
                                  setShouldBlockNavigation(true);
                                }}
                                isShowFileNameInEditMode={true}
                                actions={[]}
                                url={get(values, "reel")}
                                isCheckMimesByExt={true}
                                extensions={[
                                  "mp3",
                                  "wav",
                                  "aiff",
                                  "flac",
                                  "aac",
                                  "alac",
                                  "m4a",
                                  "mp4",
                                ]}
                              >
                                <FormattedMessage id="process > button upload" />
                              </UploadFile>
                            </FormGroup>
                          </Col>
                          <Col xs={6} className={bem.e("col-reset")}>
                            <Label>
                              <FormattedMessage id="user > style tags" />
                            </Label>
                            <SelectTags
                              checked={values.tags}
                              isInputSearch={true}
                              name="tags"
                              onChange={(data) => {
                                const newSelectedTags = tagsOptions.filter(
                                  (item) =>
                                    includes(
                                      map(
                                        data,
                                        (selectedTagItem) =>
                                          selectedTagItem.value
                                      ),
                                      item.value
                                    )
                                );
                                formProps.setFieldValue(
                                  "tags",
                                  newSelectedTags
                                );
                                setShouldBlockNavigation(true);
                              }}
                              isShowPopularTags={false}
                              type={TAG_TYPE_EXCEPT_LOCATION_FORMAT}
                            />
                            <div className="text-danger">
                              {touched.tags && errors.tags}
                            </div>
                          </Col>
                        </Row>
                      )}
                    </div>
                  )}
                </div>
                {/* enterprise roles */}
                {isShowEnterpriseRoles ? (
                  <div className={bem.e("content-box")}>
                    <div className={bem.e("content-box-head")}>
                      <h2 className={bem.e("enterprise-roles-title")}>
                        <FormattedMessage
                          id={"user > title enterprise roles"}
                        />
                        {!props.isOpenEnterpriseRoles ? (
                          <span
                            className={bem.e("button-down-icon")}
                            onClick={() =>
                              props.setIsOpenEnterpriseRoles(false)
                            }
                          >
                            <DownIcon color="#657894" />
                          </span>
                        ) : (
                          <span
                            className={bem.e("button-up-icon")}
                            onClick={() => props.setIsOpenEnterpriseRoles(true)}
                          >
                            <UpIcon color="#657894" />
                          </span>
                        )}
                      </h2>
                    </div>
                    {props.isOpenEnterpriseRoles && (
                      <div className={bem.e("content-box-content")}>
                        <Row>
                          <Col xs={6} className={bem.e("col-reset")}>
                            <div className={bem.e("selected-roles")}>
                              {map(values.enterprise_roles, (role) => (
                                <div
                                  className={bem.e("role-item")}
                                  key={role.id}
                                >
                                  <span
                                    className={bem.e("remove-role")}
                                    onClick={() => onDeleteRole(role)}
                                  >
                                    <CloseSmallIcon />
                                  </span>
                                  <span>{role.label}</span>
                                </div>
                              ))}
                            </div>
                            <Dropdown
                              placeholder={props.intl.formatMessage({
                                id: "user > select a role",
                              })}
                              label={intl.formatMessage({ id: "user > roles" })}
                              isMulti
                              allowSelectAll
                              onChange={(selectedOption) => {
                                let selectedRoles = selectedOption
                                  ? [
                                      ...values.enterprise_roles,
                                      ...selectedOption,
                                    ]
                                  : values.enterprise_roles;
                                selectedRoles = uniqBy(
                                  selectedRoles,
                                  (option) => option.value
                                );
                                // get valid privileges for new roles
                                const validPrivileges = reduce(
                                  selectedRoles,
                                  (accumulator, currentValue) => {
                                    return [
                                      ...accumulator,
                                      ...get(currentValue, "privileges", []),
                                    ];
                                  },
                                  []
                                );
                                // only get valid privileges from new roles
                                const privileges = uniq(validPrivileges);
                                formProps.setFieldValue(
                                  "enterprise_privileges",
                                  privileges
                                );
                                formProps.setFieldValue(
                                  "enterprise_roles",
                                  selectedRoles
                                );
                                setShouldBlockNavigation(true);
                              }}
                              value={[]} // no value to hidden on dropdown
                              options={rolesOptions}
                              closeMenuOnSelect={false}
                              error={
                                get(formProps, `touched.enterprise_roles`) &&
                                get(formProps, `errors.enterprise_roles`)
                              }
                            />
                            {(authIsGodAdmin || authIsSupportAdmin) && (
                              <div
                                className={bem.e("manage-privileges")}
                                onClick={() => {
                                  props.setIsOpenManagePrivilegesModal(true);
                                }}
                              >
                                <FormattedMessage id="user > manage privileges" />
                              </div>
                            )}
                            <div className="text-danger">
                              {get(
                                formProps,
                                "touched.enterprise_privileges"
                              ) &&
                                get(formProps, "errors.enterprise_privileges")}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </div>
                ) : null}

                <div className={bem.e("form-buttons")}>
                  <FormGroup>
                    {!auth.user.broadcasting_group_admin &&
                    !auth.user.is_god_admin &&
                    (user.is_god_admin ||
                      user.broadcasting_group_admin) ? null : (
                      <Button
                        color="blue"
                        type="submit"
                        className="btn btn-radius"
                        disabled={isLoading}
                        onClick={() => {
                          setTimeout(() => {
                            const formErrors = get(
                              formikRef,
                              "current.state.errors"
                            );
                            if (formErrors && Object.keys(formErrors).length) {
                              ToastManager.show({
                                title: props.intl.formatMessage({
                                  id: "toast > title not saved",
                                }),
                                message: props.intl.formatMessage({
                                  id:
                                    "toast > message error please correct the hilighted fields",
                                }),
                                level: "error",
                              });
                            }
                          }, 40);
                        }}
                      >
                        <FormattedMessage id={`user > button submit`} />
                      </Button>
                    )}
                    {props.isShowAssignmentsModal && (
                      <Button
                        color="blue"
                        type="button"
                        className="btn btn-radius"
                        onClick={() => {
                          props.setIsOpenAssignmentsModal(true);
                        }}
                      >
                        <FormattedMessage id={`user > button assignments`} />
                      </Button>
                    )}
                    {props.backButton}
                    {!auth.user.broadcasting_group_admin &&
                    !auth.user.is_god_admin &&
                    (user.is_god_admin ||
                      user.broadcasting_group_admin) ? null : isViewMode ===
                      MODE_EDIT ? (
                      <Button
                        color="blue"
                        outline
                        onClick={props.onResendInvitationUser}
                        className="btn btn-radius"
                      >
                        <FormattedMessage
                          id={`user > button resend invitation`}
                        />
                      </Button>
                    ) : null}
                  </FormGroup>
                </div>
                <Spinner isLoading={isLoading} />
                <AutoSave
                  submitForm={() => {
                    props.onSetNewUser({
                      ...formProps.values,
                      name: trim(
                        `${formProps.values.first_name} ${formProps.values.last_name}`
                      ),
                    });
                  }}
                  formik={formProps}
                  debounceMs={300}
                />
                <ManagePrivilegesModal
                  title={intl.formatMessage({ id: "user > manage privileges" })}
                  validPrivileges={privilegeOptions}
                  isOpen={props.isOpenManagePrivilegesModal}
                  onToggle={() =>
                    props.setIsOpenManagePrivilegesModal(
                      !props.isOpenManagePrivilegesModal
                    )
                  }
                  // init values to match prev code
                  station={{
                    privileges: values.enterprise_privileges.map((item) => ({
                      value: item,
                    })),
                  }}
                  onCancel={() => {
                    props.setIsOpenManagePrivilegesModal(false);
                  }}
                  onConfirm={(newPrivileges) => {
                    formProps.setFieldValue(
                      "enterprise_privileges",
                      map(newPrivileges, (item) => item.value)
                    );
                    props.setIsOpenManagePrivilegesModal(false);
                    setShouldBlockNavigation(true);
                  }}
                />
              </Form>
            );
          }}
        </Formik>
        <ChangePasswordModal
          isOpen={props.isChangePasswordModalOpen}
          user={user}
          onToggle={() =>
            props.setIsChangePasswordModalOpen(!props.isChangePasswordModalOpen)
          }
        />
        <UserAssignmentsModal
          isOpen={props.isOpenAssignmentsModal}
          user={user}
          onSubmit={(assignments) => {
            props.setAssignmentsData(assignments);
            props.setIsOpenAssignmentsModal(false);
          }}
          onToggle={() =>
            props.setIsOpenAssignmentsModal(!props.isOpenAssignmentsModal)
          }
        />
      </div>
    );
    return (
      <React.Fragment>
        {form}
        <BlockingComponent
          isBlocked={props.shouldBlockNavigation}
          modalProps={{
            isShowConfirm: true,
            isShowDiscard: false,
            isCancelConfirmedNavigation: true,
            onCancel: () => {
              props.setShouldBlockNavigation(false); // reset
            },
          }}
        />
      </React.Fragment>
    );
  };

  const renderView = () => {
    const {
      user,
      title,
      intl,
      auth,
      selectedTagOptions,
      isSpecialUser,
    } = props;
    let languageCode = user.lang ? user.lang : defaultLangCode;
    const lang = find(languageList, (item) => item.value === languageCode);
    const isNoShowResend =
      !auth.user.broadcasting_group_admin &&
      !auth.user.is_god_admin &&
      (user.is_god_admin || user.broadcasting_group_admin);
    return (
      <>
        <div className={bem.e("content-form")}>
          <h2 className={bem.e("title")}>
            {title ? title : <FormattedMessage id={"user > detail title"} />}
          </h2>
          <div className={bem.e("fields-view")}>
            <Row>
              <Col xs={6} className={bem.e("col-reset")}>
                <FormGroup className={bem.e("form-group-view")}>
                  <Label className={bem.e("label")}>
                    <FormattedMessage id="user > first name" />
                  </Label>
                  <div className={bem.e("field-view-value")}>
                    {user.first_name}
                  </div>
                </FormGroup>
              </Col>
              <Col xs={6} className={bem.e("col-reset")}>
                <FormGroup className={bem.e("form-group-view")}>
                  <Label className={bem.e("label")}>
                    <FormattedMessage id="user > last name" />
                  </Label>
                  <div className={bem.e("field-view-value")}>
                    {user.last_name}
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={6} className={bem.e("col-reset")}>
                <FormGroup className={bem.e("form-group-view")}>
                  <Label className={bem.e("label")}>
                    <FormattedMessage id="user > job title" />
                  </Label>
                  <div className={bem.e("field-view-value")}>
                    {user.title
                      ? user.title
                      : intl.formatMessage({ id: "user > none" })}
                  </div>
                </FormGroup>
              </Col>
              <Col xs={6} className={bem.e("col-reset")}>
                <FormGroup className={bem.e("form-group-view")}>
                  <Label className={bem.e("label")}>
                    <FormattedMessage id="user > email" />
                  </Label>
                  <div
                    className={classnames(
                      bem.e("field-view-value"),
                      bem.e("field-view-value-email")
                    )}
                  >
                    {user.email}
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={6} className={bem.e("col-reset")}>
                <FormGroup className={bem.e("form-group-view")}>
                  <Label className={bem.e("label")}>
                    <FormattedMessage id="user > phone" />
                  </Label>
                  {user.phone ? (
                    <NumberFormat
                      className={bem.e("field-view-value")}
                      format={PhoneNumberFormat}
                      displayType={"text"}
                      value={user.phone}
                    />
                  ) : (
                    intl.formatMessage({ id: "user > none" })
                  )}
                </FormGroup>
              </Col>
              <Col xs={6} className={bem.e("col-reset")}>
                <FormGroup className={bem.e("form-group-view")}>
                  <Label className={bem.e("label")}>
                    <FormattedMessage id="user > language" />
                  </Label>
                  <div className={bem.e("field-view-value")}>
                    {get(lang, "label", "")}
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={6} className={bem.e("col-reset")}>
                <FormGroup className={bem.e("form-group-view")}>
                  <Label className={bem.e("label")}>
                    <FormattedMessage id="user > location" />
                  </Label>
                  <div className={bem.e("field-view-value")}>
                    {user.location_text
                      ? user.location_text
                      : intl.formatMessage({ id: "user > none" })}
                  </div>
                </FormGroup>
              </Col>
              <Col xs={6} className={bem.e("col-reset")}>
                <FormGroup className={bem.e("form-group-view")}>
                  <Label className={bem.e("label")}>
                    <FormattedMessage id="user > timezone" />
                  </Label>
                  <div className={bem.e("field-view-value")}>
                    {user.location
                      ? user.location
                      : intl.formatMessage({ id: "user > none" })}
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </div>
          {isSpecialUser && (
            <React.Fragment>
              <div className={bem.e("line")} />
              <div className={bem.e("creative-details-view")}>
                <h2 className={bem.e("creative-title")}>
                  <FormattedMessage id={"user > title creative details"} />
                  {props.isCreativeDetailsCollapsed ? (
                    <span
                      className={bem.e("button-up-icon")}
                      onClick={() => props.setIsCreativeDetailsCollapsed(false)}
                    >
                      <UpIcon color="#657894" />
                    </span>
                  ) : (
                    <span
                      className={bem.e("button-down-icon")}
                      onClick={() => props.setIsCreativeDetailsCollapsed(true)}
                    >
                      <DownIcon color="#657894" />
                    </span>
                  )}
                </h2>
                {!props.isCreativeDetailsCollapsed && (
                  <React.Fragment>
                    <Row className={bem.e("creative-content")}>
                      <Col xs={6} className={bem.e("col-reset")}>
                        <FormGroup
                          className={bem.e("form-group-creative-view")}
                        >
                          <Label className={bem.e("label")}>
                            <FormattedMessage id="user > personal bio" />
                          </Label>
                          <div className={bem.e("field-view-value")}>
                            {user.summary}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col xs={6} className={bem.e("col-reset")}>
                        <FormGroup className={bem.e("form-group-reset")}>
                          <Label className={bem.e("label")}>
                            <FormattedMessage id="user > style tags" />
                          </Label>
                          <div className={bem.e("field-view-tags")}>
                            {map(selectedTagOptions, (item, index) => (
                              <span key={index} className={bem.e("tag-view")}>
                                {item.label}
                              </span>
                            ))}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} className={bem.e("col-reset")}>
                        <FormGroup className={bem.e("form-group-reset")}>
                          <Label className={bem.e("label")}>
                            <FormattedMessage id="user > sample reel" />
                          </Label>
                          <div className={bem.e("field-view-value")}>
                            <UploadFile
                              attachText={intl.formatMessage({
                                id: "my profile > attach files",
                              })}
                              mode="view"
                              showRemoveIcon={false}
                              actions={[]}
                              url={get(user, "reel")}
                            >
                              <FormattedMessage id="process > button upload" />
                            </UploadFile>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </div>
            </React.Fragment>
          )}
        </div>
        {/* enterprise roles */}
        {isShowEnterpriseRoles ? (
          <div className={bem.e("content-box")}>
            <div className={bem.e("content-box-head")}>
              <h2 className={bem.e("enterprise-roles-title")}>
                <FormattedMessage id={"user > title enterprise roles"} />
                {!props.isOpenEnterpriseRoles ? (
                  <span
                    className={bem.e("button-down-icon")}
                    onClick={() => props.setIsOpenEnterpriseRoles(false)}
                  >
                    <DownIcon color="#657894" />
                  </span>
                ) : (
                  <span
                    className={bem.e("button-up-icon")}
                    onClick={() => props.setIsOpenEnterpriseRoles(true)}
                  >
                    <UpIcon color="#657894" />
                  </span>
                )}
              </h2>
            </div>
            {props.isOpenEnterpriseRoles && (
              <div className={bem.e("content-box-content")}>
                <Row>
                  <Col xs={6} className={bem.e("col-reset")}>
                    <Label className={bem.e("label")}>
                      <FormattedMessage id="user > roles" />
                    </Label>
                    <div className={bem.e("field-view-tags")}>
                      {map(user.enterprise_roles, (item, index) => (
                        <span key={index} className={bem.e("tag-view")}>
                          {item.name}
                        </span>
                      ))}
                    </div>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        ) : null}
        <PrivilegedComponent
          requires={[PRIVILEGES.EDIT_USERS, PRIVILEGES.DELETE_USERS]}
        >
          <div className={bem.e("form-buttons")}>
            <PrivilegedComponent requires={[PRIVILEGES.EDIT_USERS]}>
              <Button
                color="blue"
                type="button"
                className="btn btn-radius"
                onClick={() => {
                  props.setIsViewMode(MODE_EDIT);
                }}
              >
                <FormattedMessage id="edit" />
              </Button>
            </PrivilegedComponent>
            {/* <PrivilegedComponent requires={[PRIVILEGES.DELETE_USERS]}>
              <Button
                color="blue"
                type="button"
                className="btn btn-radius"
                onClick={() => {
                  props.openDeleteModal(user.id);
                }}
              >
                <FormattedMessage id="delete" />
              </Button>
            </PrivilegedComponent> */}
            {props.backButton}
            {isNoShowResend ? null : (
              <Button
                color="blue"
                outline
                onClick={props.onResendInvitationUser}
                className="btn btn-radius"
              >
                <FormattedMessage id={`user > button resend invitation`} />
              </Button>
            )}
          </div>
        </PrivilegedComponent>
        <ConfirmationModal
          isOpen={props.isDeleteModalOpen}
          title={props.intl.formatMessage({
            id: "user > are you sure you want to remove user",
          })}
          onConfirm={props.onDelete}
          onToggle={props.closeDeleteModal}
          onCancel={props.closeDeleteModal}
          isCloseOutside={false}
        />
      </>
    );
  };

  const { isViewMode, isLoading } = props;
  return (
    <Row className={bem.b()}>
      <Col>
        <div className={bem.e("user-wrapper")}>
          {isViewMode === MODE_EDIT || isViewMode === MODE_ADD
            ? renderForm()
            : renderView()}
        </div>
        <Spinner isLoading={isLoading} />
      </Col>
    </Row>
  );
}
export default UserForm;
