import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { get } from "lodash";
import PrizeList, { MODE_IN_TAB, MODE_IN_SIDEBAR } from "./view";
import {
  getPrizes,
  setActiveTab,
  setActiveEditTab,
  resetTab,
} from "store/actions/prizes";
import { getTemplate } from "store/actions/template";
import { injectIntl } from "react-intl";
export { MODE_IN_TAB, MODE_IN_SIDEBAR };
const Enhanced = compose(
  connect(
    (state, props) => {
      return {
        user: state.auth.user,
        users: get(state, "auth.info.users", []),
        prizes: state.prizes.prizes,
        auth: state.auth,
        template: state.templates.template.data,
        dateFormatByServer: get(
          state,
          "servers.currentServer.data.date_format",
          "MM/DD/YYYY"
        ),
        ...props,
      };
    },
    {
      getPrizes,
      setActiveTab,
      setActiveEditTab,
      getTemplate,
      resetTab,
    }
  )
)(PrizeList);
export default withRouter(injectIntl(Enhanced));
