import PropTypes from "prop-types";
import cn from "classnames";
import raf from "dom-helpers/util/requestAnimationFrame";
import React, { Component } from "react";
import { findDOMNode } from "react-dom";
import memoize from "memoize-one";
import { round } from "lodash";
import * as dates from "./utils/dates";
import DayColumn from "./DayColumn";
import TimeGutter from "./TimeGutter";
import getWidth from "dom-helpers/query/width";
import TimeGridHeader from "./TimeGridHeader";
import { notify } from "./utils/helpers";
import { inRange, sortEvents } from "./utils/eventLevels";
import Resources from "./utils/Resources";

export default class TimeGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gutterWidth: undefined,
      isOverflowing: null,
      scrollIndex: 0,
      contentWidth: null
    };

    this.scrollRef = React.createRef();
    this.contentRef = React.createRef();
  }

  componentWillMount() {
    this.calculateScroll();
  }

  componentDidMount() {
    this.checkOverflow();

    if (this.props.width == null) {
      this.measureGutter();
    }

    this.applyScroll();

    window.addEventListener("resize", this.handleResize);
    if (this.contentRef.current) {
      const contentWidth = this.contentRef.current.clientWidth;
      this.setState({
        contentWidth
      });
    }
  }

  handleScroll = e => {
    if (this.scrollRef.current) {
      const { currentModeDay } = this.props;
      this.scrollRef.current.scrollLeft = e.target.scrollLeft;
      const scrollPosition = e.target.scrollLeft;
      let widthElement;
      if (currentModeDay && this.state.contentWidth) {
        widthElement = round(this.state.contentWidth / currentModeDay);
      }
      const width = widthElement || this.props.width || this.state.gutterWidth;
      const scrollIndex = round(scrollPosition / width);
      this.setState({ scrollIndex });
    }
  };

  handleResize = () => {
    raf.cancel(this.rafHandle);
    this.rafHandle = raf(this.checkOverflow);
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);

    raf.cancel(this.rafHandle);

    if (this.measureGutterAnimationFrameRequest) {
      window.cancelAnimationFrame(this.measureGutterAnimationFrameRequest);
    }
  }

  componentDidUpdate() {
    if (this.props.width == null) {
      this.measureGutter();
    }

    this.applyScroll();
    //this.checkOverflow()
  }

  componentWillReceiveProps(nextProps) {
    const { range, scrollToTime } = this.props;
    // When paginating, reset scroll
    if (
      !dates.eq(nextProps.range[0], range[0], "minute") ||
      !dates.eq(nextProps.scrollToTime, scrollToTime, "minute")
    ) {
      this.calculateScroll(nextProps);
    }
  }

  gutterRef = ref => {
    this.gutter = ref && findDOMNode(ref);
  };

  handleSelectAlldayEvent = (...args) => {
    //cancel any pending selections so only the event click goes through.
    this.clearSelection();
    notify(this.props.onSelectEvent, args);
  };

  handleSelectAllDaySlot = (slots, slotInfo) => {
    const { onSelectSlot } = this.props;
    notify(onSelectSlot, {
      slots,
      start: slots[0],
      end: slots[slots.length - 1],
      action: slotInfo.action
    });
  };

  renderEvents(range, events, now, widthElement) {
    let { min, max, components, accessors, localizer } = this.props;
    const { scrollIndex } = this.state;
    const resources = this.memoizedResources(this.props.resources, accessors);
    const groupedEvents = resources.groupEvents(events);

    return resources.map(([id, resource], i) =>
      range.map((date, jj) => {
        let daysEvents = (groupedEvents.get(id) || []).filter(event =>
          dates.inRange(
            date,
            accessors.start(event),
            accessors.end(event),
            "day"
          )
        );
        return (
          <DayColumn
            {...this.props}
            localizer={localizer}
            min={dates.merge(date, min)}
            max={dates.merge(date, max)}
            resource={resource && id}
            components={components}
            isNow={dates.eq(date, now, "day")}
            key={i + "-" + jj}
            date={date}
            events={daysEvents}
            className={scrollIndex === jj ? `ps-rbc-slot-current-scroll` : ""}
            widthElement={widthElement}
          />
        );
      })
    );
  }

  render() {
    let {
      events,
      range,
      width,
      rtl,
      selected,
      getNow,
      resources,
      components,
      accessors,
      getters,
      localizer,
      min,
      max,
      showMultiDayTimes,
      longPressThreshold,
      currentModeDay
    } = this.props;
    let widthElement;
    if (currentModeDay && this.state.contentWidth) {
      widthElement = round(this.state.contentWidth / currentModeDay);
    }
    width = width || this.state.gutterWidth;

    let start = range[0],
      end = range[range.length - 1];

    this.slots = range.length;

    let allDayEvents = [],
      rangeEvents = [];

    events.forEach(event => {
      if (inRange(event, start, end, accessors)) {
        let eStart = accessors.start(event),
          eEnd = accessors.end(event);

        if (
          accessors.allDay(event) ||
          (dates.isJustDate(eStart) && dates.isJustDate(eEnd)) ||
          (!showMultiDayTimes && !dates.eq(eStart, eEnd, "day"))
        ) {
          allDayEvents.push(event);
        } else {
          rangeEvents.push(event);
        }
      }
    });

    allDayEvents.sort((a, b) => sortEvents(a, b, accessors));
    return (
      <div
        className={cn("rbc-time-view", resources && "rbc-time-view-resources")}
      >
        <TimeGridHeader
          range={range}
          events={allDayEvents}
          width={width}
          rtl={rtl}
          getNow={getNow}
          localizer={localizer}
          selected={selected}
          resources={this.memoizedResources(resources, accessors)}
          selectable={this.props.selectable}
          accessors={accessors}
          getters={getters}
          components={components}
          scrollRef={this.scrollRef}
          isOverflowing={this.state.isOverflowing}
          longPressThreshold={longPressThreshold}
          onSelectSlot={this.handleSelectAllDaySlot}
          onSelectEvent={this.handleSelectAlldayEvent}
          onDoubleClickEvent={this.props.onDoubleClickEvent}
          onDrillDown={this.props.onDrillDown}
          getDrilldownView={this.props.getDrilldownView}
          // scrollIndex={this.state.scrollIndex}
          widthElement={widthElement}
        />
        <div className="ps-rbc-time-content-wrapper">
          <TimeGutter
            date={start}
            ref={this.gutterRef}
            localizer={localizer}
            min={dates.merge(start, min)}
            max={dates.merge(start, max)}
            step={this.props.step}
            getNow={this.props.getNow}
            timeslots={this.props.timeslots}
            components={components}
            className="rbc-time-gutter"
            width={width}
          />
          <div
            ref={this.contentRef}
            className="rbc-time-content"
            onScroll={this.handleScroll}
          >
            {this.renderEvents(range, rangeEvents, getNow(), widthElement)}
          </div>
        </div>
      </div>
    );
  }

  clearSelection() {
    clearTimeout(this._selectTimer);
    this._pendingSelection = [];
  }

  measureGutter() {
    if (this.measureGutterAnimationFrameRequest) {
      window.cancelAnimationFrame(this.measureGutterAnimationFrameRequest);
    }
    this.measureGutterAnimationFrameRequest = window.requestAnimationFrame(
      () => {
        const width = getWidth(this.gutter);

        if (width && this.state.gutterWidth !== width) {
          this.setState({ gutterWidth: width });
        }
      }
    );
  }

  applyScroll() {
    if (this._scrollRatio) {
      const content = this.contentRef.current;
      content.scrollTop = content.scrollHeight * this._scrollRatio;
      // Only do this once
      this._scrollRatio = null;
    }
  }

  calculateScroll(props = this.props) {
    const { min, max, scrollToTime } = props;

    const diffMillis = scrollToTime - dates.startOf(scrollToTime, "day");
    const totalMillis = dates.diff(max, min);

    this._scrollRatio = diffMillis / totalMillis;
  }

  checkOverflow = () => {
    if (this._updatingOverflow) return;

    const content = this.contentRef.current;
    let isOverflowing = content.scrollHeight > content.clientHeight;

    if (this.state.isOverflowing !== isOverflowing) {
      this._updatingOverflow = true;
      this.setState({ isOverflowing }, () => {
        this._updatingOverflow = false;
      });
    }
  };

  memoizedResources = memoize((resources, accessors) =>
    Resources(resources, accessors)
  );
}

TimeGrid.propTypes = {
  events: PropTypes.array.isRequired,
  resources: PropTypes.array,

  step: PropTypes.number,
  timeslots: PropTypes.number,
  range: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  min: PropTypes.instanceOf(Date),
  max: PropTypes.instanceOf(Date),
  getNow: PropTypes.func.isRequired,

  scrollToTime: PropTypes.instanceOf(Date),
  showMultiDayTimes: PropTypes.bool,

  rtl: PropTypes.bool,
  width: PropTypes.number,

  accessors: PropTypes.object.isRequired,
  components: PropTypes.object.isRequired,
  getters: PropTypes.object.isRequired,
  localizer: PropTypes.object.isRequired,

  selected: PropTypes.object,
  selectable: PropTypes.oneOf([true, false, "ignoreEvents"]),
  longPressThreshold: PropTypes.number,

  onNavigate: PropTypes.func,
  onSelectSlot: PropTypes.func,
  onSelectEnd: PropTypes.func,
  onSelectStart: PropTypes.func,
  onSelectEvent: PropTypes.func,
  onDoubleClickEvent: PropTypes.func,
  onDrillDown: PropTypes.func,
  getDrilldownView: PropTypes.func.isRequired
};

TimeGrid.defaultProps = {
  step: 30,
  timeslots: 2,
  min: dates.startOf(new Date(), "day"),
  max: dates.endOf(new Date(), "day"),
  scrollToTime: dates.startOf(new Date(), "day")
};
