import React from "react";
import { Modal, ModalBody } from "reactstrap";
import bn from "utils/bemnames";
import { CloseIcon } from "components/CustomIcons";
import { FormattedMessage } from "react-intl";
import { setScriptField } from "utils/helpers";
import { get, map } from "lodash";
import TextareaEvaluator from "components/TextareaEvaluator";
const bem = bn.create("preview-item-modal");
function PreviewItemModal(props) {
  const { item } = props;
  const length = get(item, "_source.order_length", "");
  const copyGroups = get(item, "_source.copy_groups", []);
  return (
    <Modal isOpen={props.isOpen} className={bem.b()}>
      <ModalBody className={bem.e("body")}>
        <div className={bem.e("close-icon")} onClick={props.onToggle}>
          <CloseIcon color="#657894" />
        </div>
        <div className={bem.e("item-subtitle")}>
          {get(item, "_source.order_stations_array", [])
            .map((i) => i.name)
            .join(", ")}
        </div>
        <div className={bem.e("item-head")}>
          <div className={bem.e("item-title")}>
            {get(item, "_source.order_title", "")}
          </div>
          {length ? (
            <div className={bem.e("item-length")}>
              <FormattedMessage id="process > length" />:
              <strong>{get(item, "_source.order_length", "")}</strong>
            </div>
          ) : null}
        </div>
        {map(copyGroups, (group, index) => (
          <div key={index} className={bem.e("item-group")}>
            <h4>{group.title}</h4>
            {map(group.list_scripts, (script) => (
              <div className={bem.e("item-description")} key={script.uuid}>
                <TextareaEvaluator
                  readOnly={true}
                  text={setScriptField(script.text)}
                  hideOrderLength={true}
                  hideHelperText={true}
                  onEvaluatorCallback={() => {}}
                  showInnerTitle
                />
              </div>
            ))}
          </div>
        ))}
      </ModalBody>
    </Modal>
  );
}

PreviewItemModal.defaultProps = {
  isOpen: false,
  item: {},
};

export default PreviewItemModal;
