import React, { useRef } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Table,
} from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import TextInputFormatted from "components/TextInputFormatted";
import ToastManager from "components/ToastManager";
import bn from "utils/bemnames";
import { get, map, sumBy } from "lodash";
import { CloseIcon } from "components/CustomIcons";
import { CheckedIcon, TrashIcon } from "components/CustomIcons";
const bem = bn.create("supplement-items-modal");

function SupplementItemsModal(props) {
  const { onToggle, isOpen, items, totalNeeded, intl } = props;
  const formikRef = useRef();
  const initialValues = {
    selected_inventory: map(items, (item) => ({
      id: item.id,
      items: item.items,
      number: "",
    })),
  };

  const getTotalSupplement = (values) => {
    return sumBy(values, (value) => parseInt(get(value, "number", 0) || 0));
  };

  const renderModalContent = (formProps) => {
    const errors = formProps.errors;
    const touched = formProps.touched;
    const values = formProps.values;

    const selectedInventoryValues = get(values, "selected_inventory", []);
    const getSelectedInventoryError = (index, field) => {
      const inventoryErrors =
        touched.selected_inventory && errors.selected_inventory;
      const error = inventoryErrors && inventoryErrors[index];
      if (error && typeof error === "string") {
        return error;
      }
      return error && error[field];
    };
    const getSelectedInventoryValue = (index, field) => {
      return get(selectedInventoryValues, `${index}.${field}`, "");
    };
    const setSelectedInventoryValue = (index, field, value) => {
      const newInventoryValues = [...selectedInventoryValues];
      newInventoryValues[index][field] = value;
      formProps.setFieldValue("selected_inventory", newInventoryValues);
    };
    const total = getTotalSupplement(selectedInventoryValues);
    const isValid = total === totalNeeded;
    return (
      <>
        <ModalHeader>
          <p className={bem.e("title")}>
            <FormattedMessage id="process > supplement items > supplement items" />
          </p>
          <div className={bem.e("close")} onClick={onToggle}>
            <CloseIcon color={"#657894"} />
          </div>
        </ModalHeader>
        <ModalBody className={bem.e("body")}>
          <p className={bem.e("description")}>
            <FormattedMessage id="process > supplement items > allocate other available items in order to meet the needed number" />
          </p>
          <div className={bem.e("head-text")}>
            <FormattedMessage id="process > supplement items > items needed" />{" "}
            : <strong>{totalNeeded}</strong>
          </div>
          <div className={bem.e("head-text")}>
            <FormattedMessage id="process > supplement items > items selected" />{" "}
            : <strong>{total}</strong>
            {isValid ? <CheckedIcon /> : <TrashIcon color="#e40061" />}
          </div>
          <Table bordered>
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="process > supplement items > type" />
                </th>
                <th className="text-center">
                  <FormattedMessage id="process > supplement items > available" />
                </th>
                <th className="text-center">
                  <FormattedMessage id="process > supplement items > allocate" />
                </th>
              </tr>
            </thead>
            <tbody>
              {map(items, (item, index) => {
                return (
                  <tr key={`intervention-${item.id}`}>
                    <td>
                      <span className={bem.e("type-text")}>{item.name}</span>
                    </td>
                    <td className="text-center">
                      <strong className={bem.e("available-text")}>
                        {item.items}
                      </strong>
                    </td>
                    <td className="text-center">
                      <TextInputFormatted
                        label={null}
                        max={item.items}
                        placeholder={""}
                        value={getSelectedInventoryValue(index, "number")}
                        onChange={(value) => {
                          setSelectedInventoryValue(index, "number", value);
                        }}
                        onBlur={formProps.handleBlur}
                        error={getSelectedInventoryError(index, "number")}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button
            color="blue"
            className="btn btn-radius"
            type="submit"
            onClick={() => {
              setTimeout(() => {
                const formErrors = get(formikRef, "current.state.errors");
                if (formErrors && Object.keys(formErrors).length) {
                  ToastManager.show({
                    title: intl.formatMessage({
                      id: "toast > title not saved",
                    }),
                    message: intl.formatMessage({
                      id:
                        "toast > message error please correct the hilighted fields",
                    }),
                    level: "error",
                  });
                }
              }, 50);
              formProps.handleSubmit(values);
            }}
          >
            <FormattedMessage id={`process > button save`} />
          </Button>
          <Button
            color="blue"
            outline
            className="btn btn-radius btn-cancel"
            onClick={onToggle}
          >
            <FormattedMessage id={`process > button cancel`} />
          </Button>
        </ModalFooter>
      </>
    );
  };
  return (
    <Modal isOpen={isOpen} toggle={onToggle} className={bem.b()}>
      <Formik
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={initialValues}
        ref={formikRef}
        validationSchema={Yup.object().shape({
          selected_inventory: Yup.lazy(function(value, item) {
            switch (typeof value) {
              case "object":
                return Yup.array().of(
                  Yup.object()
                    .shape({
                      number: Yup.string()
                        .test(
                          "max",
                          intl.formatMessage({
                            id:
                              "validate > allocation should be less than or equal total needed",
                          }),
                          function(val) {
                            if (!val) return true;
                            return parseInt(val) <= parseInt(totalNeeded);
                          }
                        )
                        .test(
                          "maxAssigned",
                          intl.formatMessage({
                            id:
                              "validate > total allocation should be equal to total needed",
                          }),
                          function(val) {
                            if (!val) return true;
                            const selectedTotal = getTotalSupplement(value);
                            return !!(
                              val &&
                              parseFloat(totalNeeded) ===
                                parseFloat(selectedTotal)
                            );
                          }
                        ),
                    })
                    .test(
                      "maxItems",
                      intl.formatMessage({
                        id:
                          "validate > allocation should not exceed total available",
                      }),
                      function(val) {
                        return parseInt(val.number || 0) < val.items;
                      }
                    )
                );
              default:
                return Yup.mixed();
            }
          }),
        })}
        onSubmit={(values) => {
          const selectedInventoryValues = get(values, "selected_inventory", []);
          const total = getTotalSupplement(selectedInventoryValues);
          props.onSubmit(total, values);
        }}
      >
        {renderModalContent}
      </Formik>
    </Modal>
  );
}
SupplementItemsModal.defaultProps = {
  isOpen: false,
  onToggle: () => {},
  onCancel: () => {},
  items: [],
  totalNeeded: 0,
  onSubmit: () => {},
};
SupplementItemsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  items: PropTypes.array,
  totalNeeded: PropTypes.number,
  onSubmit: PropTypes.func,
};
export default SupplementItemsModal;
