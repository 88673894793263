import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";
import { get } from "lodash";
import {
  getAssignedOrdersByTeam,
  getCurrentOrdersByTeam,
} from "store/actions/hubs";
import { injectIntl } from "react-intl";
import TeamManagement from "./view";

const Enhanced = compose(
  connect(
    (state, props) => {
      return {
        auth: get(state, "auth"),
        isLoading:
          get(state, "hubs.currentOrders.loading", false) ||
          get(state, "hubs.assignedOrders.loading", false),
        currentOrders: get(state, "hubs.currentOrders.data", []),
        assignedOrders: get(state, "hubs.assignedOrders.data", []),
        dateFormatByServer: get(
          state,
          "servers.currentServer.data.date_format",
          "MM/DD/YYYY"
        ),
        ...props,
      };
    },
    {
      getCurrentOrdersByTeam,
      getAssignedOrdersByTeam,
    }
  ),
  lifecycle({
    componentDidMount() {
      const { user } = this.props;
      if (user) {
        this.props.getCurrentOrdersByTeam({ id: user.id, rpp: 1000 });
        this.props.getAssignedOrdersByTeam({ id: user.id, rpp: 1000 });
      }
    },
  })
)(TeamManagement);
export default injectIntl(Enhanced);
