import React from "react";
import PropTypes from "prop-types";
import bn from "utils/bemnames";
import { Button, ButtonGroup } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { get, find, findIndex } from "lodash";
import CustomForm from "components/CustomForm";
import PrivilegedComponent from "components/PrivilegedComponent";
import { PRIVILEGES } from "utils/constants";
import ReviewHistory from "components/ReviewHistory";
import classNames from "classnames";
const bem = bn.create("design");

const Design = ({
  intl,
  step,
  valueChanged,
  template,
  user,
  validationState,
  process,
  isView,
  setShouldBlockNavigation,
  ...rest
}) => {
  let isAdd = document.location.pathname.split("/")[2] === "add" ? true : false;
  const marketing_channels = step.fields.find(
    (f) => f.field === "marketing_channels"
  );
  const buttonField = step.fields.find((f) => f.key === "save_button");
  // If a channel requires production
  const marketingChannels = get(
    process,
    "data.fields.marketing_channels.value",
    []
  );
  let isDisabledButtonSubmit = false;
  if (buttonField.is_disabled) {
    isDisabledButtonSubmit =
      buttonField.is_disabled && buttonField.is_disabled(user, process, step);
  }
  const channelMediaIndex = findIndex(marketingChannels, (channel) => {
    return (
      channel.id ===
        get(process, "data.fields.for_marketing_channel.value.id") &&
      (channel.production === "Requires Production" ||
        channel.production === "Production Needed") &&
      find(channel.fields, (field) => field.key === "media_for_review_approval")
    );
  });
  const channelMedia = marketingChannels[channelMediaIndex];
  const renderView = () => (
    <div className={bem.e("design-view")}>
      <div className={bem.e("channel-form")}>
        <CustomForm
          channel={channelMedia}
          onSubmitForm={() => {}}
          isShowStatus={false}
          isView={true}
          onlyDesign={true}
          isShowAdjustChannelSchedule={false}
          isShowRequiresPlacement={false}
          isShowCollapse={false}
        />
      </div>
      <ReviewHistory process={process} />
    </div>
  );
  const renderForm = () => (
    <div className={bem.e("design-form")}>
      <div className={bem.e("channel-form")}>
        <CustomForm
          channel={channelMedia}
          onSubmitForm={(values) => {
            marketingChannels[channelMediaIndex] = values;
            valueChanged(marketing_channels, process, template, user, {
              value: marketingChannels,
            });
            setShouldBlockNavigation(true);
          }}
          isShowStatus={false}
          isView={false}
          onlyDesign={true}
          isShowAdjustChannelSchedule={false}
          isShowRequiresPlacement={false}
          isShowCollapse={false}
        />
      </div>
      <ReviewHistory process={process} />
      {!isAdd && (
        <div className="bottom-actions-sticky">
          <ButtonGroup className={classNames(bem.e("buttons"), "buttons")}>
            {/* button submit  */}
            <PrivilegedComponent
              requires={{
                or: [
                  PRIVILEGES.ADD_DESIGN,
                  PRIVILEGES.DELETE_DESIGN,
                  PRIVILEGES.EDIT_ALL_DIGITAL_ORDERS,
                ],
              }}
            >
              <Button
                block
                color="blue"
                className="button-submit"
                disabled={isDisabledButtonSubmit}
                onClick={(event) => {
                  rest.buttonClicked(
                    step,
                    buttonField,
                    process,
                    template,
                    user,
                    event,
                    null,
                    true
                  );
                }}
              >
                <FormattedMessage id="process > button submit" />
              </Button>
            </PrivilegedComponent>
          </ButtonGroup>
        </div>
      )}
    </div>
  );
  return (
    <div className={bem.b()}>
      <div className={bem.e("main-section")}>
        <div className={bem.e("main-content")}>
          {isView ? renderView() : renderForm()}
        </div>
      </div>
    </div>
  );
};
Design.defaultProps = {
  channels: [],
  isView: false,
};
Design.propTypes = {
  channels: PropTypes.array,
  isView: PropTypes.bool,
};
export default Design;
