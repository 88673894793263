import UserTableStatic from "./view";
import {
  filter,
  includes,
  debounce,
  get,
  isEqual,
  toLower,
  forEach,
  findIndex,
  forOwn,
} from "lodash";
import { connect } from "react-redux";
import {
  compose,
  withProps,
  withState,
  withHandlers,
  lifecycle,
} from "recompose";
import { deleteUser, invitationUsers, activeUsers } from "store/actions/users";
import { userInfo } from "store/actions/auth";
import { injectIntl } from "react-intl";
import { pageSize } from "utils/config";
import ToastManager from "components/ToastManager";

export default injectIntl(
  compose(
    connect(
      (state, props) => ({
        auth: get(state, "auth", {}),
        privileges: get(state, "auth.user.privileges") || [],
        users: get(state, "auth.info.users", []),
        locationOptions: get(props, "locationOptions", []),
      }),
      {
        deleteUser,
        invitationUsers,
        activeUsers,
        userInfo,
      }
    ),
    withState(
      "isBulkUploadUserModalOpen",
      "setIsBulkUploadUserModalOpen",
      false
    ),
    withState("isInviteUserModalOpen", "setIsInviteUserModalOpen", false),
    withState("userList", "setUserList", ({ users }) => users),
    withState("searchText", "setSearchText", ""),
    withState("isDeleteModalOpen", "setIsDeleteModalOpen", false),
    withState("isInviteModalOpen", "setIsInviteModalOpen", false),
    withState("isSuccessModalOpen", "setIsSuccessModalOpen", false),
    withState("deleteUserId", "setDeleteUserId", null),
    withState("selectedRowsId", "setSelectedRowsId", {}),
    withState("filtered", "setFiltered", []),
    withState("sorted", "setSorted", []),
    withState("page", "setPage", 0),
    withState("pageSize", "setPageSize", ({ users }) => users.length),
    withProps((props) => ({
      getUsersDelayed: debounce((userList) => props.setUserList(userList), 200),
      selectedUsersChanged: props.selectedUsersChanged,
    })),
    withHandlers({
      openDeleteModal: ({ setIsDeleteModalOpen, setDeleteUserId }) => (id) => {
        setIsDeleteModalOpen(true);
        setDeleteUserId(id);
      },
      closeDeleteModal: ({ setIsDeleteModalOpen, setDeleteUserId }) => () => {
        setIsDeleteModalOpen(false);
        setDeleteUserId(null);
      },
      toggleInviteUserModal: ({
        isInviteUserModalOpen,
        setIsInviteUserModalOpen,
      }) => () => setIsInviteUserModalOpen(!isInviteUserModalOpen),
      toggleBulkUploadUserModal: ({
        isBulkUploadUserModalOpen,
        setIsBulkUploadUserModalOpen,
      }) => () => setIsBulkUploadUserModalOpen(!isBulkUploadUserModalOpen),
      getSelectedMarketsByUser: ({ locationOptions }) => (user) => {
        if (!get(user, "tags_string", "")) return [];
        let selectedMarketOptions = [];
        forEach(get(user, "tags_string", "").split("#"), (item) => {
          let existsIndex = findIndex(locationOptions, (selected) =>
            includes(selected.label, item)
          );
          if (existsIndex !== -1) {
            selectedMarketOptions.push({
              value: get(locationOptions, `${existsIndex}.value`),
              label: get(locationOptions, `${existsIndex}.label`),
            });
          }
        });
        return selectedMarketOptions;
      },
    }),
    withHandlers({
      onActiveUsers: ({ activeUsers, userInfo }) => (userIds = []) => {
        activeUsers({ ids: userIds }, () => userInfo());
      },
      onInviteUsers: ({
        selectedRowsId,
        setSelectedRowsId,
        invitationUsers,
        setIsSuccessModalOpen,
        setIsInviteModalOpen,
        userInfo,
      }) => () => {
        setIsInviteModalOpen(false);
        let userIds = [];
        forOwn(selectedRowsId, (value, key) => {
          if (value) {
            userIds.push(Number(key));
          }
        });
        invitationUsers({ ids: userIds }, () => {
          setIsSuccessModalOpen(true);
          setSelectedRowsId({});
          userInfo();
        });
      },
      onDelete: ({ closeDeleteModal, deleteUserId, deleteUser, intl }) => () => {
        deleteUser(deleteUserId, () => {
          ToastManager.show({
            title: intl.formatMessage({ id: "user > success" }),
            message: intl.formatMessage({
              id: `user > delete success`,
            }),
            autoDismiss: 1.5,
            level: "success",
          });
        });
        closeDeleteModal();
      },
      onSearchKeyDown: ({ searchText, users, getUsersDelayed }) => (e) => {
        if (e.keyCode === 13) {
          let userList = users;
          if (searchText) {
            userList = filter(users, (user) =>
              includes(toLower(user.name), toLower(searchText))
            );
          }
          getUsersDelayed(userList);
        }
      },
      onSearchClicked: ({ getUsersDelayed, searchText, users }) => () => {
        let userList = users;
        if (searchText) {
          userList = filter(users, (user) =>
            includes(toLower(user.name), toLower(searchText))
          );
        }
        getUsersDelayed(userList);
      },
      onChangeInput: ({ setSearchText }) => ({ target }) => {
        const value = target.value;
        setSearchText(value);
      },
    }),
    withHandlers({
      onClearSearch: ({ onChangeInput, onSearchClicked }) => (e) => {
        e.preventDefault();
        onChangeInput({
          target: {
            value: "",
          },
        });
        setTimeout(() => {
          onSearchClicked();
        }, 20);
      },
    }),
    lifecycle({
      componentWillUpdate(nextProps) {
        if (
          !isEqual(get(this.props, "users", []), get(nextProps, "users", []))
        ) {
          this.props.setUserList(get(nextProps, "users", []));
          this.props.setPageSize(get(nextProps, "users.length", pageSize));
        }
      },
    })
  )(UserTableStatic)
);
