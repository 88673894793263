import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import bn from "utils/bemnames";
import { FormattedMessage } from "react-intl";
import { map, xor, includes } from "lodash";
import Checkbox from "components/Checkbox";
import classNames from "classnames";
const bem = bn.create("override-stations-modal");

const OverrideStationsModal = (props) => {
  const { isOpen, setIsOpen, stations, onSubmit } = props;
  const [values, setValues] = useState([]);
  useEffect(() => {
    if(stations && stations.length === 1) setValues(stations[0].value)
  }, [stations])
  return (
    <Modal isOpen={isOpen} className={bem.b()} size="lg">
      <ModalHeader>
        <div className={bem.e("override-title")}>
          <FormattedMessage id="system templates > override stations modal title" />
        </div>
        <div className={bem.e("override-desc")}>
          <FormattedMessage id="system templates > override stations modal description" />
        </div>
      </ModalHeader>

      <ModalBody>
        <div className={classNames(bem.e("list-stations"), {
          [bem.e("list-stations-center")]: stations && stations.length === 1
        })}>
          {map(stations, (item, index) => (
            <Checkbox
              key={index}
              checked={includes(values, item.value)}
              onChange={() => {
                setValues(xor(values, [item.value]));
              }}
              text={item.label}
            />
          ))}
          {stations && stations.length > 1 && (
            <Checkbox
            checked={stations.length === values.length}
            onChange={() => {
              if(stations.length === values.length){
                setValues([]);
              }else{
                setValues(stations.map(item => item.value))
              }
            }}
            text={<FormattedMessage id="system templates > all" />}
          />
          )}
        </div>
      </ModalBody>

      <ModalFooter>
        <Button
          color="blue"
          className="btn-radius"
          onClick={() => onSubmit(values)}
        >
          <FormattedMessage id="system templates > button override" />
        </Button>
        <Button
          color="blue"
          className="btn-radius"
          outline
          onClick={() => setIsOpen(false)}
        >
          <FormattedMessage id="system templates > button cancel" />
        </Button>
      </ModalFooter>
    </Modal>
  );
};
export default OverrideStationsModal;
