export const FETCH_USERS_REQUEST = "FETCH_USERS_REQUEST";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_ERROR = "FETCH_USERS_ERROR";

export const FETCH_MORE_USERS_REQUEST = "FETCH_MORE_USERS_REQUEST";
export const FETCH_MORE_USERS_SUCCESS = "FETCH_MORE_USERS_SUCCESS";
export const FETCH_MORE_USERS_ERROR = "FETCH_MORE_USERS_ERROR";

export const FETCH_ADMINS_REQUEST = "FETCH_ADMINS_REQUEST";
export const FETCH_ADMINS_SUCCESS = "FETCH_ADMINS_SUCCESS";
export const FETCH_ADMINS_ERROR = "FETCH_ADMINS_ERROR";

export const FETCH_MORE_ADMINS_REQUEST = "FETCH_MORE_ADMINS_REQUEST";
export const FETCH_MORE_ADMINS_SUCCESS = "FETCH_MORE_ADMINS_SUCCESS";
export const FETCH_MORE_ADMINS_ERROR = "FETCH_MORE_ADMINS_ERROR";

export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_ERROR = "FETCH_USER_ERROR";

export const ADD_USER_REQUEST = "ADD_USER_REQUEST";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_ERROR = "ADD_USER_ERROR";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";

export const FETCH_SECURITY_POLICY_USER_REQUEST =
  "FETCH_SECURITY_POLICY_USER_REQUEST";
export const FETCH_SECURITY_POLICY_USER_SUCCESS =
  "FETCH_SECURITY_POLICY_USER_SUCCESS";
export const FETCH_SECURITY_POLICY_USER_ERROR =
  "FETCH_SECURITY_POLICY_USER_ERROR";

export const UPDATE_SECURITY_POLICY_USER_REQUEST =
  "UPDATE_SECURITY_POLICY_USER_REQUEST";
export const UPDATE_SECURITY_POLICY_USER_SUCCESS =
  "UPDATE_SECURITY_POLICY_USER_SUCCESS";
export const UPDATE_SECURITY_POLICY_USER_ERROR =
  "UPDATE_SECURITY_POLICY_USER_ERROR";

export const UPDATE_SECURITY_POLICY_USERS_REQUEST =
  "UPDATE_SECURITY_POLICY_USERS_REQUEST";
export const UPDATE_SECURITY_POLICY_USERS_SUCCESS =
  "UPDATE_SECURITY_POLICY_USERS_SUCCESS";
export const UPDATE_SECURITY_POLICY_USERS_ERROR =
  "UPDATE_SECURITY_POLICY_USERS_ERROR";

export const FETCH_ADMIN_RIGHTS_USER_REQUEST =
  "FETCH_ADMIN_RIGHTS_USER_REQUEST";
export const FETCH_ADMIN_RIGHTS_USER_SUCCESS =
  "FETCH_ADMIN_RIGHTS_USER_SUCCESS";
export const FETCH_ADMIN_RIGHTS_USER_ERROR = "FETCH_ADMIN_RIGHTS_USER_ERROR";

export const UPDATE_ADMIN_RIGHTS_USER_REQUEST =
  "UPDATE_ADMIN_RIGHTS_USER_REQUEST";
export const UPDATE_ADMIN_RIGHTS_USER_SUCCESS =
  "UPDATE_ADMIN_RIGHTS_USER_SUCCESS";
export const UPDATE_ADMIN_RIGHTS_USER_ERROR = "UPDATE_ADMIN_RIGHTS_USER_ERROR";

// export const UPDATE_ADMIN_RIGHTS_USERS_REQUEST =
//   "UPDATE_ADMIN_RIGHTS_USERS_REQUEST";
// export const UPDATE_ADMIN_RIGHTS_USERS_SUCCESS =
//   "UPDATE_ADMIN_RIGHTS_USERS_SUCCESS";
// export const UPDATE_ADMIN_RIGHTS_USERS_ERROR =
//   "UPDATE_ADMIN_RIGHTS_USERS_ERROR";

export const UPDATE_PRIVILEGES_USERS_REQUEST =
  "UPDATE_PRIVILEGES_USERS_REQUEST";
export const UPDATE_PRIVILEGES_USERS_SUCCESS =
  "UPDATE_PRIVILEGES_USERS_SUCCESS";
export const UPDATE_PRIVILEGES_USERS_ERROR = "UPDATE_PRIVILEGES_USERS_ERROR";

export const INVITATION_USER_REQUEST = "INVITATION_USER_REQUEST";
export const INVITATION_USER_SUCCESS = "INVITATION_USER_SUCCESS";
export const INVITATION_USER_ERROR = "INVITATION_USER_ERROR";

export const BULK_UPLOAD_USERS_REQUEST = "BULK_UPLOAD_USERS_REQUEST";
export const BULK_UPLOAD_USERS_SUCCESS = "BULK_UPLOAD_USERS_SUCCESS";
export const BULK_UPLOAD_USERS_ERROR = "BULK_UPLOAD_USERS_ERROR";

export const UPDATE_ASSIGNMENT_USER_REQUEST = "UPDATE_ASSIGNMENT_USER_REQUEST";
export const UPDATE_ASSIGNMENT_USER_SUCCESS = "UPDATE_ASSIGNMENT_USER_SUCCESS";
export const UPDATE_ASSIGNMENT_USER_ERROR = "UPDATE_ASSIGNMENT_USER_ERROR";

export const SET_NEW_USER_REQUEST = "SET_NEW_USER_REQUEST";
export const SET_NEW_USER_SUCCESS = "SET_NEW_USER_SUCCESS";
export const SET_NEW_USER_ERROR = "SET_NEW_USER_ERROR";

export const INVITATION_USERS_REQUEST = "INVITATION_USERS_REQUEST";
export const INVITATION_USERS_SUCCESS = "INVITATION_USERS_SUCCESS";
export const INVITATION_USERS_ERROR = "INVITATION_USERS_ERROR";

export const ACTIVE_USERS_REQUEST = "ACTIVE_USERS_REQUEST";
export const ACTIVE_USERS_SUCCESS = "ACTIVE_USERS_SUCCESS";
export const ACTIVE_USERS_ERROR = "ACTIVE_USERS_ERROR";

export const INVITATION_ADMINS_REQUEST = "INVITATION_ADMINS_REQUEST";
export const INVITATION_ADMINS_SUCCESS = "INVITATION_ADMINS_SUCCESS";
export const INVITATION_ADMINS_ERROR = "INVITATION_ADMINS_ERROR";

export const ACTIVE_ADMINS_REQUEST = "ACTIVE_ADMINS_REQUEST";
export const ACTIVE_ADMINS_SUCCESS = "ACTIVE_ADMINS_SUCCESS";
export const ACTIVE_ADMINS_ERROR = "ACTIVE_ADMINS_ERROR";

export const SET_IS_NEW_USER = "SET_IS_NEW_USER";

export const UNLOCK_USER_REQUEST = "UNLOCK_USER_REQUEST";
export const UNLOCK_USER_SUCCESS = "UNLOCK_USER_SUCCESS";
export const UNLOCK_USER_ERROR = "UNLOCK_USER_ERROR";

export const UPDATE_USER_OOO_REQUEST = "UPDATE_USER_OOO_REQUEST";
export const UPDATE_USER_OOO_SUCCESS = "UPDATE_USER_OOO_SUCCESS";
export const UPDATE_USER_OOO_ERROR = "UPDATE_USER_OOO_ERROR";
