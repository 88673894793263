import React from "react";
import HubsList from "./view";
import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";
import { saveHub } from "store/actions/hubs";
import { injectIntl } from "react-intl";
import ToastManager from "components/ToastManager";
import { MdImportantDevices } from "react-icons/lib/md";

export default injectIntl(
  compose(
    connect(
      () => {
        return {};
      },
      {
        saveHub,
      }
    ),
    withHandlers({
      onSubmit: ({ saveHub, intl, onToggle }) => (values) => {
        saveHub(values, () => {
          ToastManager.show({
            title: <MdImportantDevices />,
            message: intl.formatMessage({
              id: "hubs > create successfully",
            }),
            level: "success",
          });
          onToggle();
        });
      },
    })
  )(HubsList)
);
