import React, { useState } from "react";
import View from "./view";
import { get, values, find } from "lodash";
import { PRIVILEGES } from "utils/constants";
import URL from "utils/urls";
import { FormattedMessage, injectIntl } from "react-intl";
import { adminTabs } from "utils/config";
import { validatePrivileges, checkUserIsAEOrAEDigital } from "utils/helpers";
import { withRouter } from "react-router";
import {
  QueueIcon,
  TasksIcon,
  CloudIcon,
  ProfileIcon,
  StartAddIcon,
  GridIcon,
  UserAddIcon,
  ClockIcon,
  InventoryClosetIcon,
  FileReportsIcon,
  ClientIcon,
  LinerManagementIcon,
  ScheduleIcon,
  JockConsoleManagerIcon,
  JockConsoleIcon,
  ContestPrizesIcon,
  SettingIcon,
  SystemTemplatesIcon,
  HubAssignmentsIcon,
  HubManagerIcon,
} from "components/CustomIcons";
import { getRoleIdByName } from "utils/helpers";
const isHasPrivilegesForAdmins = ({ auth }) => {
  const privileges = get(auth, "user.privileges", []) || [];
  const user = get(auth, "user");

  // for god admin or broadcasting admin always true
  if (get(user, "is_god_admin") || get(user, "broadcasting_group_admin")) {
    return adminTabs.TAB_BROADCASTING_GROUP;
  }
  if (get(user, "is_admin")) {
    return adminTabs.TAB_STATIONS;
  }

  // for has a privilege to view or edit servers
  if (
    privileges.includes(PRIVILEGES.ADD_SERVERS) ||
    privileges.includes(PRIVILEGES.DELETE_SERVERS) ||
    privileges.includes(PRIVILEGES.VIEW_SERVERS) ||
    privileges.includes(PRIVILEGES.EDIT_SERVERS)
  ) {
    return adminTabs.TAB_BROADCASTING_GROUP;
  }

  // for has a privilege to view or edit stations
  if (
    !get(user, "is_support_admin") &&
    (privileges.includes(PRIVILEGES.ADD_STATIONS) ||
      privileges.includes(PRIVILEGES.VIEW_STATIONS) ||
      privileges.includes(PRIVILEGES.DELETE_STATIONS) ||
      privileges.includes(PRIVILEGES.EDIT_STATIONS))
  ) {
    return adminTabs.TAB_STATIONS;
  }
  // for has a privilege to view or edit users
  if (
    privileges.includes(PRIVILEGES.ADD_USERS) ||
    privileges.includes(PRIVILEGES.EDIT_USERS) ||
    // privileges.includes(PRIVILEGES.VIEW_USERS) ||
    privileges.includes(PRIVILEGES.DELETE_USERS) ||
    privileges.includes(PRIVILEGES.ASSIGN_PRIVILEGES)
  ) {
    return adminTabs.TAB_USERS;
  }

  if (
    privileges.includes(PRIVILEGES.ADD_TEAMS) ||
    privileges.includes(PRIVILEGES.VIEW_TEAMS) ||
    privileges.includes(PRIVILEGES.EDIT_TEAMS) ||
    privileges.includes(PRIVILEGES.DELETE_TEAMS)
  ) {
    return adminTabs.TAB_TEAMS;
  }
  return false;
};

function Sidebar(props) {
  const { auth, intl } = props;
  const isSidebarOpen = get(auth, "is_sidebar_open", false);
  const [scrollSectionHeight, setScrollSectionHeight] = useState(null);
  const {
    enable_production,
    enable_promotion,
    enable_digital,
    production_multimarket,
    stations,
  } = get(auth, "info", {});

  const privileges = values(get(auth, "info.privileges")) || [];
  let sections = [];

  const isShowForAdmin =
    get(auth, "info.app_flags", "") &&
    auth.info.app_flags.indexOf("SERVERS_LIST") > -1;
  const user = auth.user;
  const authIsGodAdmin = get(props, "auth.user.is_god_admin", false);
  const authIsSupportAdmin = get(props, "auth.user.is_support_admin", false);
  const authIsBroadcastingGroupAdmin = get(
    auth,
    "user.broadcasting_group_admin"
  );
  const isEnableProductionNeeded = !get(
    props,
    "auth.info.disabled_production_needed",
    false
  );
  const isEnableProductionProvided = !get(
    props,
    "auth.info.disabled_production_provided",
    false
  );
  const isEnableClientManagement = !get(
    props,
    "auth.info.disabled_client_management",
    false
  );
  const isEnableJockConsole = !get(
    props,
    "auth.info.disabled_jock_console",
    false
  );
  const isEnableClocks = !get(props, "auth.info.disabled_clocks", false);
  const isEnableProgrammingElements = !get(
    props,
    "auth.info.disabled_programming_elements",
    false
  );
  const isEnablePrizeCloset = !get(
    props,
    "auth.info.disabled_prize_closet",
    false
  );
  const isEnableContesting = !get(
    props,
    "auth.info.disabled_contesting",
    false
  );
  const isEnableProduction =
    isEnableProductionNeeded || isEnableProductionProvided;
  const showProductionNavItems =
    isEnableProduction &&
    validatePrivileges({
      requires: {
        or: [
          PRIVILEGES.ADD_PRODUCTION,
          PRIVILEGES.EDIT_PRODUCTION,
          PRIVILEGES.DRAFT_PRODUCTION,
          PRIVILEGES.DELETE_PRODUCTION,
          PRIVILEGES.DRAFT_PRODUCTION_SCRIPT,
          PRIVILEGES.EDIT_PRODUCTION_SCRIPT,
          PRIVILEGES.SUBMIT_PRODUCTION_SCRIPT,
          PRIVILEGES.APPROVE_PRODUCTION_SCRIPT,
          PRIVILEGES.SUBMIT_PRODUCTION_RECORDING,
          PRIVILEGES.DELETE_PRODUCTION_RECORDING,
          PRIVILEGES.APPROVE_PRODUCTION_RECORDING,
          PRIVILEGES.VIEW_ALL_PRODUCTION_ORDERS,
          PRIVILEGES.EDIT_VO,
          PRIVILEGES.ASSIGN_PRODUCTION_TEAM,
          PRIVILEGES.PERFORM_PRODUCTION_CONTINUITY,
          PRIVILEGES.PRODUCE_PRODUCTION,
          PRIVILEGES.SUBMIT_PRODUCTION_AIRCHECK,
          PRIVILEGES.PERFORM_PRODUCTION_DUBBING,
          PRIVILEGES.EDIT_ALL_PRODUCTION_ORDERS,
          PRIVILEGES.EDIT_PRODUCTION_SETTINGS,
        ],
      },
      privileges,
      user,
    });

  const showDigitalNavItems = validatePrivileges({
    requires: {
      or: [
        PRIVILEGES.DRAFT_DIGITAL,
        PRIVILEGES.ADD_DIGITAL,
        PRIVILEGES.EDIT_DIGITAL,
        PRIVILEGES.VIEW_DIGITAL,
        PRIVILEGES.VIEW_ALL_DIGITAL_ORDERS,
        PRIVILEGES.DELETE_DIGITAL,
        PRIVILEGES.EDIT_CHANNELS,
        PRIVILEGES.PUBLISH_CHANNELS,
        PRIVILEGES.ADD_DESIGN,
        PRIVILEGES.DELETE_DESIGN,
        PRIVILEGES.APPROVE_ORDER,
        PRIVILEGES.APPROVE_DESIGN,
        PRIVILEGES.PERFORM_DIGITAL_TRAFFIC,
        PRIVILEGES.EDIT_ALL_DIGITAL_ORDERS,
      ],
    },
    privileges,
    user,
  });

  const isShowClientManagement =
    isEnableClientManagement &&
    validatePrivileges({
      requires: {
        or: [
          PRIVILEGES.MANAGE_CLIENTS,
          PRIVILEGES.EDIT_CLIENT,
          PRIVILEGES.ADD_CLIENT,
          PRIVILEGES.REASSIGN_CLIENTS,
        ],
      },
      privileges,
      user,
    });

  const isShowJockConsoleManager =
    isEnableJockConsole &&
    enable_promotion &&
    validatePrivileges({
      requires: {
        or: [PRIVILEGES.CONFIGURE_CONSOLE],
      },
      privileges,
      user,
    });
  const isWoEnabled = get(auth, "user.enable_wo", false);
  const isWODubbingEnabled = get(auth, "user.enable_wo_dubbing", false);

  const showDubbingQueue = validatePrivileges({
    requires: {
      or: [
        PRIVILEGES.PERFORM_PRODUCTION_DUBBING,
        PRIVILEGES.EDIT_ALL_PRODUCTION_ORDERS,
      ],
    },
    privileges,
    user,
  });

  const getTaskNavItems = () => {
    if (isShowForAdmin) return;
    const isShowMyAssignments = checkUserIsAEOrAEDigital(privileges);
    const isHasStationEnableContinuityQueue = !!find(stations, (station) =>
      get(station, "production_settings.continuity_queue", false)
    );
    const isHasStationEnableAssignmentsQueue = !!find(stations, (station) =>
      get(station, "production_settings.assignments_queue", false)
    );
    const isHasPrivilegesForContinuityQueue = validatePrivileges({
      requires: {
        or: [
          PRIVILEGES.PERFORM_PRODUCTION_CONTINUITY,
          PRIVILEGES.EDIT_ALL_PRODUCTION_ORDERS,
        ],
      },
      privileges,
      user,
    });
    const isShowContinuityQueue = !production_multimarket
      ? isHasStationEnableContinuityQueue && isHasPrivilegesForContinuityQueue
      : isHasPrivilegesForContinuityQueue;
    const isShowHubManager =
      getRoleIdByName("Hub Manager", auth.info.enterprise_roles) &&
      production_multimarket;

    const isShowAssignmentsQueue = validatePrivileges({
      requires: {
        or: [
          PRIVILEGES.ASSIGN_PRODUCTION_TEAM,
          PRIVILEGES.EDIT_ALL_PRODUCTION_ORDERS,
        ],
      },
      privileges,
      user,
    });

    let items = [];
    let section = {
      title: null,
      items,
    };
    if (!authIsGodAdmin) {
      items.push({
        to: {
          pathname: URL.PROCESSES({ filter: "my_action_items" }),
          isReminder: true,
        },
        name: intl.formatMessage({ id: "menu > my tasks" }),
        exact: true,
        Icon: TasksIcon,
        counter: get(auth, "info.total_action_items", 0),
      });
    }

    if (
      isWoEnabled &&
      isWODubbingEnabled &&
      showDubbingQueue &&
      !authIsGodAdmin
    ) {
      items.push({
        to: {
          pathname: URL.PROCESSES({ filter: "dubbing_queue" }),
          isReminder: true,
        },
        className: "nav-link-small",
        name: intl.formatMessage({ id: "menu > dubbing queue" }),
        exact: true,
        Icon: QueueIcon,
        counter: get(auth, "info.total_dubbing_queue", 0),
      });
    }

    if (isShowMyAssignments) {
      items.push({
        to: {
          pathname: URL.PROCESSES({ filter: "my_upcoming_items" }),
          isReminder: true,
        },
        name: intl.formatMessage({ id: "process > title my upcoming items" }),
        exact: true,
        Icon: QueueIcon,
      });
    }
    if (isShowHubManager) {
      items.push({
        to: {
          pathname: URL.TEAM_MANAGEMENT(),
          isReminder: true,
        },
        name: intl.formatMessage({ id: "menu > team management" }),
        exact: true,
        Icon: QueueIcon,
      });
    }
    if (isShowContinuityQueue) {
      items.push({
        to: {
          pathname: URL.PROCESSES({ filter: "continuity_queue" }),
          isReminder: true,
        },
        className: "nav-link-small",
        name: intl.formatMessage({ id: "menu > continuity queue" }),
        exact: true,
        Icon: QueueIcon,
        counter: get(auth, "info.total_continuity_queue", 0),
      });
    }
    if (isShowAssignmentsQueue && isHasStationEnableAssignmentsQueue) {
      items.push({
        to: {
          pathname: URL.PROCESSES({ filter: "assignments_queue" }),
          isReminder: true,
        },
        className: "nav-link-small",
        name: intl.formatMessage({ id: "menu > assignments queue" }),
        exact: true,
        Icon: QueueIcon,
        counter: get(auth, "info.total_assignments_queue_count", 0),
      });
    }

    if (isShowClientManagement) {
      items.push({
        to: {
          pathname: URL.CLIENT_MANAGER(),
          isReminder: true,
        },
        name: intl.formatMessage({ id: "menu > client management" }),
        exact: true,
        Icon: ClientIcon,
      });
    }
    return items.length > 0 ? section : null;
  };
  const getProductionNavItems = () => {
    if (isShowForAdmin) return;
    if (enable_production && showProductionNavItems) {
      let items = [];
      let section = {
        title: isSidebarOpen ? (
          <FormattedMessage id="menu > production" />
        ) : (
          <FormattedMessage id="menu > sort production" />
        ),
        items,
      };
      if (!authIsGodAdmin) {
        const isShowHuAssignments = auth.info.show_hub_assignment;
        // Hub manager
        if (isShowHuAssignments) {
          items.push({
            to: {
              pathname: URL.PROCESSES({
                filter: "multi_market_list",
              }),
              isReminder: true,
            },
            name: intl.formatMessage({ id: "menu > hub assignments" }),
            exact: true,
            Icon: HubAssignmentsIcon,
            counter: get(auth, "info.total_hub_assignments", 0),
          });
        }
        const isShowMMProductionQueue = production_multimarket;
        items.push({
          to: {
            pathname: URL.PROCESSES({
              filter: isShowMMProductionQueue
                ? "multi_market_production_queue"
                : "production_queue",
            }),
            isReminder: true,
          },
          name: intl.formatMessage({ id: "menu > production queue" }),
          exact: true,
          Icon: QueueIcon,
        });

        // if (isShowContinuityAssignments && production_multimarket) {
        //   items.push({
        //     to: {
        //       pathname: URL.PROCESSES({ filter: "continuity_assignment" }),
        //       isReminder: true,
        //     },
        //     className: "nav-link-small",
        //     name: intl.formatMessage({ id: "menu > continuity assignments" }),
        //     exact: true,
        //     Icon: QueueIcon,
        //     counter: get(auth, "info.total_continuity_assignment", 0),
        //   });
        // }

        items.push({
          to: {
            pathname: URL.PROCESSES({ filter: "produced_spots" }),
            isReminder: true,
          },
          name: intl.formatMessage({ id: "menu > produced spots" }),
          exact: true,
          Icon: CloudIcon,
        });
        const isShowExpiringSpots = validatePrivileges({
          requires: [PRIVILEGES.VIEW_EXPIRING_SPOTS],
          privileges,
          user,
        });
        if (isShowExpiringSpots) {
          items.push({
            to: {
              pathname: URL.PROCESSES({ filter: "expiring_spots" }),
              isReminder: true,
            },
            name: intl.formatMessage({ id: "menu > expiring spots" }),
            exact: true,
            Icon: JockConsoleIcon,
            counter: get(auth, "info.total_expiring_spots", 0),
          });
        }
        const isShowProductionArchive = validatePrivileges({
          requires: [PRIVILEGES.ARCHIVE_PRODUCTION],
          privileges,
          user,
        });
        if (isShowProductionArchive) {
          items.push({
            to: {
              pathname: URL.PROCESSES({ filter: "archived_spots" }),
              isReminder: true,
            },
            name: intl.formatMessage({ id: "menu > production archive" }),
            exact: true,
            Icon: QueueIcon,
            // counter: get(auth, "info.total_expiring_spots", 0),
          });
        }
      }

      const isShowProductionSettings = validatePrivileges({
        requires: {
          or: [PRIVILEGES.EDIT_PRODUCTION_SETTINGS],
        },
        privileges,
        user,
      });
      if (isShowProductionSettings) {
        items.push({
          to: {
            pathname: URL.PRODUCTION_SETTINGS(),
            isReminder: true,
          },
          name: intl.formatMessage({ id: "menu > production settings" }),
          exact: true,
          Icon: SettingIcon,
        });
      }
      return section;
    }
    return;
  };
  const getDigitalNavItems = () => {
    if (isShowForAdmin) return;

    if (!authIsGodAdmin && enable_digital && showDigitalNavItems) {
      let items = [];
      let section = {
        title: isSidebarOpen ? (
          <FormattedMessage id="menu > digital" />
        ) : (
          <FormattedMessage id="menu > sort digital" />
        ),
        items,
      };
      items.push({
        to: {
          pathname: URL.PROCESSES({ filter: "digital_queue" }),
          isReminder: true,
        },
        name: intl.formatMessage({ id: "menu > digital queue" }),
        exact: true,
        Icon: QueueIcon,
      });
      items.push({
        to: {
          pathname: URL.PROCESSES({ filter: "produced_digitals" }),
          isReminder: true,
        },
        name: intl.formatMessage({ id: "menu > produced digital" }),
        exact: true,
        Icon: CloudIcon,
      });
      return section;
    }
    return;
  };
  const getPromotionNavItems = () => {
    if (isShowForAdmin) return;
    if (enable_promotion) {
      let items = [];
      let section = {
        title: isSidebarOpen ? (
          <FormattedMessage id="menu > promotions" />
        ) : (
          <FormattedMessage id="menu > sort promotions" />
        ),
        items,
      };
      const isShowContest = validatePrivileges({
        requires: {
          or: [
            PRIVILEGES.CONTEST_VIEW,
            PRIVILEGES.CONTEST_SAVE_DRAFT,
            PRIVILEGES.CONTEST_SAVE,
            PRIVILEGES.CONTEST_PUBLISH,
            PRIVILEGES.CONTEST_EDIT,
            PRIVILEGES.CONTEST_DELETE,
          ],
        },
        privileges,
        user,
      });

      const isShowPrizeCloset = validatePrivileges({
        requires: {
          or: [
            PRIVILEGES.PRIZE_CLOSET_SAVE,
            PRIVILEGES.PRIZE_CLOSET_EDIT,
            PRIVILEGES.PRIZE_CLOSET_VIEW,
            PRIVILEGES.PRIZE_CLOSET_DELETE,
          ],
        },
        privileges,
        user,
      });
      const isShowWinners = validatePrivileges({
        requires: {
          or: [
            PRIVILEGES.VIEW_WINNERS_LIST,
            PRIVILEGES.EDIT_WINNER,
            PRIVILEGES.FULFILL_WINNER_PRIZES,
            PRIVILEGES.VIEW_WINNER,
          ],
        },
        privileges,
        user,
      });
      const isShow1099Reports = validatePrivileges({
        requires: {
          or: [
            PRIVILEGES.VIEW_WINNERS_LIST,
            PRIVILEGES.EDIT_WINNER,
            PRIVILEGES.FULFILL_WINNER_PRIZES,
            PRIVILEGES.VIEW_WINNER,
          ],
        },
        privileges,
        user,
      });
      const isShowProgrammingElements = validatePrivileges({
        requires: {
          or: [
            PRIVILEGES.PROGRAMMING_ELEMENT_SAVE_DRAFT,
            PRIVILEGES.PROGRAMMING_ELEMENT_SAVE,
            PRIVILEGES.PROGRAMMING_ELEMENT_PUBLISH,
            PRIVILEGES.PROGRAMMING_ELEMENT_EDIT,
            PRIVILEGES.PROGRAMMING_ELEMENT_VIEW,
            PRIVILEGES.PROGRAMMING_ELEMENT_DELETE,
          ],
        },
        privileges,
        user,
      });
      if (isEnableContesting && isShowContest) {
        items.push({
          to: {
            pathname: URL.PROCESSES({ filter: "promotion_contests" }),
            isReminder: true,
          },
          name: intl.formatMessage({ id: "menu > contests queue" }),
          exact: true,
          Icon: QueueIcon,
        });
      }

      if (isEnablePrizeCloset && isShowPrizeCloset) {
        items.push({
          to: {
            pathname: URL.PRIZES({ filter: "prizes_list" }),
            isReminder: true,
          },
          name: intl.formatMessage({ id: "menu > prizes" }),
          exact: true,
          Icon: InventoryClosetIcon,
        });
      }
      if (isEnableContesting && isShowWinners) {
        items.push({
          to: {
            pathname: URL.WINNERS(),
            isReminder: true,
          },
          name: intl.formatMessage({ id: "menu > winners" }),
          exact: true,
          Icon: ContestPrizesIcon,
        });
      }
      if (isEnableContesting && isShow1099Reports) {
        items.push({
          to: {
            pathname: URL.PROMOTION_REPORTS(),
            isReminder: true,
          },
          name: intl.formatMessage({ id: "menu > 1099 report" }),
          exact: true,
          Icon: FileReportsIcon,
        });
      }

      if (isEnableProgrammingElements && isShowProgrammingElements) {
        items.push({
          to: {
            pathname: URL.PROCESSES({ filter: "liners" }),
            isReminder: true,
          },
          name: intl.formatMessage({ id: "menu > programming elements" }),
          exact: true,
          Icon: LinerManagementIcon,
        });
      }
      if (
        (isEnableProgrammingElements && isShowProgrammingElements) ||
        (isEnableContesting && isShowContest)
      ) {
        items.push({
          to: {
            pathname: URL.SCHEDULE(),
            isReminder: true,
          },
          name: intl.formatMessage({ id: "menu > schedule" }),
          exact: true,
          Icon: ScheduleIcon,
        });
      }

      if (isShowJockConsoleManager) {
        items.push({
          to: {
            pathname: URL.JOCK_CONSOLE(),
            isReminder: true,
          },
          name: intl.formatMessage({ id: "menu > jock console" }),
          exact: true,
          Icon: JockConsoleIcon,
        });
      }
      return section;
    }
    return;
  };
  const getAccountNavItems = () => {
    let items = [];
    let section = {
      title: null,
      sortTitle: null,
      items,
    };
    if (authIsGodAdmin) {
      items.push({
        to: {
          pathname: URL.AUDIT({ filter: "all" }),
          isReminder: true,
        },
        name: intl.formatMessage({ id: "audit > audit" }),
        exact: true,
        Icon: QueueIcon,
      });
    }

    return items.length > 0 ? section : null;
  };
  const getQuickLinksNavItems = () => {
    let items = [];
    let section = {
      title: isSidebarOpen ? (
        <FormattedMessage id="menu > quick links" />
      ) : (
        <FormattedMessage id="menu > sort quick links" />
      ),
      items,
    };
    // quick links for admin
    if (authIsGodAdmin && !authIsSupportAdmin) {
      items.push({
        to: {
          pathname: URL.ADMINS_TAB_ADD({ tab: adminTabs.TAB_STATIONS }),
          isReminder: true,
        },
        name: intl.formatMessage({ id: "menu > quick link add a station" }),
        exact: true,
        Icon: StartAddIcon,
      });
      items.push({
        to: {
          pathname: URL.ADMINS_TAB_ADD({ tab: adminTabs.TAB_USERS }),
          isReminder: true,
        },
        name: intl.formatMessage({ id: "menu > quick link add a user" }),
        exact: true,
        Icon: UserAddIcon,
      });
    }
    return items.length > 0 ? section : null;
  };
  const getConfigureNavItems = () => {
    let items = [];
    let section = {
      title: isSidebarOpen ? (
        <FormattedMessage id="menu > configure" />
      ) : (
        <FormattedMessage id="menu > sort configure" />
      ),
      items,
    };

    const isShowEnterpriseSettings =
      validatePrivileges({
        requires: {
          or: [
            PRIVILEGES.ADD_SERVERS,
            PRIVILEGES.DELETE_SERVERS,
            PRIVILEGES.VIEW_SERVERS,
            PRIVILEGES.EDIT_SERVERS,
          ],
        },
        privileges,
        user,
      }) || getRoleIdByName("Hub Manager", auth.info.enterprise_roles);
    const isShowFormManager = validatePrivileges({
      requires: {
        or: [
          PRIVILEGES.EDIT_CHANNELS,
          PRIVILEGES.PUBLISH_CHANNELS,
          PRIVILEGES.EDIT_FORMS,
          PRIVILEGES.VIEW_FORMS,
        ],
      },
      privileges,
      user,
    });
    const isShowSystemTemplate = validatePrivileges({
      requires: {
        or: [PRIVILEGES.EDIT_FORMS, PRIVILEGES.VIEW_FORMS],
      },
      privileges,
      user,
    });
    const isShowStationSettings = validatePrivileges({
      requires: {
        or: [
          PRIVILEGES.VIEW_STATION_SETTINGS_FORMS,
          PRIVILEGES.EDIT_STATION_SETTINGS_FORMS,
        ],
      },
      privileges,
      user,
    });
    const isShowClocks = validatePrivileges({
      requires: {
        or: [
          PRIVILEGES.EDIT_DAYPART_CLOCK,
          PRIVILEGES.CREATE_SHOW_CLOCK,
          PRIVILEGES.EDIT_SHOW_CLOCK,
          PRIVILEGES.VIEW_SHOW_CLOCK,
          PRIVILEGES.DELETE_SHOW_CLOCK,
          PRIVILEGES.CREATE_PROGRAMMING_CLOCK,
          PRIVILEGES.EDIT_PROGRAMMING_CLOCK,
          PRIVILEGES.VIEW_PROGRAMMING_CLOCK,
          PRIVILEGES.DELETE_PROGRAMMING_CLOCK,
        ],
      },
      privileges,
      user,
    });
    const isShowHubManager =
      (getRoleIdByName("Hub Manager", auth.info.enterprise_roles) ||
        authIsGodAdmin ||
        authIsSupportAdmin) &&
      production_multimarket;
    if ((enable_digital || enable_promotion) && isShowFormManager) {
      items.push({
        to: {
          pathname: URL.FORM_MANAGER(),
          isReminder: true,
        },
        name: intl.formatMessage({ id: "menu > form manager" }),
        exact: true,
        Icon: QueueIcon,
      });
    }
    if (enable_promotion && isShowSystemTemplate) {
      items.push({
        to: {
          pathname: URL.SYSTEM_TEMPLATES(),
          isReminder: true,
        },
        name: intl.formatMessage({ id: "menu > system templates" }),
        exact: true,
        Icon: SystemTemplatesIcon,
      });
    }
    if (enable_promotion && isShowStationSettings) {
      items.push({
        to: {
          pathname: URL.STATION_SETTINGS(),
          isReminder: true,
        },
        name: intl.formatMessage({ id: "menu > station settings" }),
        exact: true,
        Icon: SettingIcon,
      });
    }
    if ((enable_digital || enable_production) && authIsGodAdmin) {
      items.push({
        to: {
          pathname: URL.WORKFLOW_MANAGER(),
          isReminder: true,
        },
        name: intl.formatMessage({ id: "menu > workflow manager" }),
        exact: true,
        Icon: GridIcon,
      });
    }
    if (
      production_multimarket &&
      (isShowEnterpriseSettings ||
        authIsBroadcastingGroupAdmin ||
        authIsGodAdmin)
    ) {
      items.push({
        to: {
          pathname: URL.ENTERPRISE_SETTINGS(),
          isReminder: true,
        },
        name: intl.formatMessage({ id: "menu > enterprise settings" }),
        exact: true,
        Icon: SettingIcon,
      });
    }
    if (isEnableClocks && isShowClocks) {
      items.push({
        to: {
          pathname: URL.CLOCK_MANAGER(),
          isReminder: true,
        },

        name: intl.formatMessage({ id: "menu > clock manager" }),
        exact: true,
        Icon: ClockIcon,
      });
    }
    if (isShowJockConsoleManager) {
      items.push({
        to: {
          pathname: URL.JOCK_CONSOLE_MANAGER(),
          isReminder: true,
        },
        name: intl.formatMessage({ id: "menu > station widgets" }),
        exact: true,
        Icon: JockConsoleManagerIcon,
      });
    }

    if (isShowHubManager) {
      items.push({
        to: {
          pathname: URL.HUB_MANAGER(),
          isReminder: true,
        },
        name: intl.formatMessage({ id: "menu > hub manager" }),
        exact: true,
        Icon: HubManagerIcon,
      });
    }

    if (isHasPrivilegesForAdmins({ auth })) {
      items.push({
        to: {
          pathname: URL.ADMINS({ tab: adminTabs.TAB_UNKNOWN }),
          isReminder: true,
        },
        name: intl.formatMessage({ id: "menu > admins" }),
        exact: true,
        Icon: ProfileIcon,
      });
    }
    const isShowUserScheduling = validatePrivileges({
      requires: [PRIVILEGES.USER_SCHEDULING],
      privileges,
      user,
    });
    if (isShowUserScheduling) {
      items.push({
        to: {
          pathname: URL.ADMINS({ tab: adminTabs.TAB_OUT_OF_OFFICE }),
          isReminder: true,
        },
        name: intl.formatMessage({ id: "menu > user scheduling" }),
        exact: true,
        Icon: ProfileIcon,
      });
    }
    return items.length > 0 ? section : null;
  };
  let navItemsTask = getTaskNavItems();
  let navItemsProduction = getProductionNavItems();
  let navItemsDigital = getDigitalNavItems();
  let navItemsPromotion = getPromotionNavItems();
  let navItemsAccount = getAccountNavItems();
  let navItemsConfigure = getConfigureNavItems();
  let navItemsQuickLinks = getQuickLinksNavItems();
  if (navItemsTask && get(navItemsTask, "items.length", 0) > 0) {
    sections.push(navItemsTask);
  }
  if (navItemsProduction && get(navItemsProduction, "items.length", 0) > 0) {
    sections.push(navItemsProduction);
  }
  if (navItemsDigital && get(navItemsDigital, "items.length", 0) > 0) {
    sections.push(navItemsDigital);
  }
  if (navItemsPromotion && get(navItemsPromotion, "items.length", 0) > 0) {
    sections.push(navItemsPromotion);
  }
  if (navItemsConfigure && get(navItemsConfigure, "items.length", 0) > 0) {
    sections.push(navItemsConfigure);
  }
  if (navItemsQuickLinks && get(navItemsQuickLinks, "items.length", 0) > 0) {
    sections.push(navItemsQuickLinks);
  }
  if (navItemsAccount && get(navItemsAccount, "items.length", 0) > 0) {
    sections.push(navItemsAccount);
  }
  return (
    <View
      {...props}
      {...Object.assign(
        {},
        {
          authIsGodAdmin,
          sections,
          isSidebarOpen,
          scrollSectionHeight,
          setScrollSectionHeight,
        }
      )}
    />
  );
}
export default injectIntl(withRouter(Sidebar));
