import React from "react";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import { FaSortDesc, FaSortAsc } from "react-icons/lib/fa";
import { Table } from "reactstrap";
import NoItemsFound from "components/NoItemsFound";
import { MODE_IN_TAB } from "../view";
import { resourceCategoryValues } from "utils/config";

const View = ({
  actionSort,
  sort,
  resourceList,
  bem,
  onClickRow,
  mode,
  isLoading
}) => {
  return (
    <div
      className={classnames(bem.e("table-wrapper"), {
        [bem.e("table-wrapper-mode-in-tab")]: mode === MODE_IN_TAB
      })}
    >
      <Table className={bem.e("table")}>
        <thead>
          <tr>
            <th onClick={() => actionSort("category")} className={classnames("can-click", bem.e('table-first-head'))}>
              <div className={bem.e("item-sortable")}>
                <FormattedMessage id="resources > category" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "category"
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "category desc"
                    })}
                  />
                </div>
              </div>
            </th>

            <th onClick={() => actionSort("title")} className="can-click">
              <div className={bem.e("item-sortable")}>
                <FormattedMessage id="resources > title" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "title"
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "title desc"
                    })}
                  />
                </div>
              </div>
            </th>
            <th onClick={() => actionSort("subtitle")} className="can-click">
              <div className={bem.e("item-sortable")}>
                <FormattedMessage id="resources > subtitle" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "subtitle"
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "subtitle desc"
                    })}
                  />
                </div>
              </div>
            </th>
            <th onClick={() => actionSort("quantity")} className="can-click">
              <div className={bem.e("item-sortable")}>
                <FormattedMessage id="resources > quantity" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "quantity"
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "quantity desc"
                    })}
                  />
                </div>
              </div>
            </th>
            <th onClick={() => actionSort("unit_value")} className="can-click">
              <div className={bem.e("item-sortable")}>
                <FormattedMessage id="resources > unit_value" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "unit_value"
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "unit_value desc"
                    })}
                  />
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {resourceList.length === 0 && !isLoading ? (
            <tr>
              <td colSpan={8}>
                <NoItemsFound />
              </td>
            </tr>
          ) : (
            resourceList.map(resource => {
              return (
                <tr key={resource._id} onClick={() => onClickRow(resource)}>
                  {/* type */}
                  <td className={classnames("align-middle", bem.e("first-col"))}>
                    {resourceCategoryValues[resource._source.category]}
                  </td>
                  {/* title */}
                  <td
                    className={classnames("align-middle", bem.e("client-name"))}
                  >
                    {resource._source.title}
                  </td>
                  {/* subtitle */}
                  <td
                    className={classnames("align-middle", bem.e("client-name"))}
                  >
                    {resource._source.subtitle}
                  </td>
                  {/* quantity */}
                  <td
                    className={classnames("align-middle", bem.e("client-name"))}
                  >
                    {resource._source.quantity}
                  </td>
                  {/* on_hold_quantity */}
                  <td
                    className={classnames("align-middle", bem.e("order-title"))}
                  >
                    {resource._source.unit_value}
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </Table>
    </div>
  );
};
export default View;
