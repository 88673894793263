import View from "./view";
import { injectIntl } from "react-intl";
import { withState, compose, withHandlers, lifecycle } from "recompose";
import { connect } from "react-redux";
import { getTeamsAndLeads } from "store/actions/teams";
import { get, isEmpty } from "lodash";
import { getProductionSettings } from "store/actions/settings";

export default injectIntl(
  compose(
    connect(() => ({}), {
      getTeamsAndLeads,
      getProductionSettings,
    }),
    withState(
      "isAudiencePickerModalOpen",
      "setIsAudiencePickerModalOpen",
      false
    ),
    withHandlers({
      onGetTeamsAndLeads: ({ getTeamsAndLeads, process }) => (stationKeys) => {
        const isProductionNeeded =
          get(process, "data.fields.contract_status.value") ===
          "Production Needed";
        if (!isProductionNeeded) return;
        getTeamsAndLeads({
          station_ids: stationKeys,
        }); // call api get teams and leads
      },
    }),
    lifecycle({
      componentDidMount() {
        const stationKeys = get(
          this.props.process,
          "data.fields.contract_stations.value"
        );
        const isEnableEnterprise = get(
          this.props.auth,
          "info.production_multimarket",
          false
        );
        if (isEnableEnterprise) {
          if (!isEmpty(stationKeys)) {
            this.props.onGetTeamsAndLeads(stationKeys);
          }
        }
      },
    })
  )(View)
);
