import React from "react";
import bn from "utils/bemnames";
import { map, get } from "lodash";
import { Button } from "reactstrap";
// import { FaEye, FaPencil, FaTrash, FaDownload } from "react-icons/lib/fa";
import PropTypes from "prop-types";
import PreviewFileModal from "components/PreviewFileModal";
import {
  DownloadFileIcon,
  ViewIcon,
  EditIcon,
  TrashSmallIcon,
} from "components/CustomIcons";
import classnames from "classnames";
import { checkIsSupportViewer } from "utils/helpers";

const bem = bn.create("handle-assets");

const HandleAssets = ({
  files,
  onDeleteFile,
  onDownloadFile,
  error,
  onTogglePreviewModal,
  onCancelPreviewModal,
  isOpenPreviewModal,
  currentPreviewFile,
  onViewFile,
  onRenameFile,
  isRename,
  onUploadedFiles,
  viewDisabled,
  renamingDisabled,
  deleteDisabled,
  downloadDisabled,
  renamingHidden,
  viewHidden,
  deleteHidden,
  downloadHidden,
  isView,
  isOverrideViewAction,
  onClickViewAction,
  intl,
}) => {
  return (
    <div className={bem.b()}>
      {map(files, (file, key) => {
        const isSupportViewer =
          isOverrideViewAction && onClickViewAction
            ? true
            : checkIsSupportViewer(file);
        return (
          <div key={`file-${key}`}>
            {file.audit && (
              <span className={bem.e("asset-audit")}>
                {intl.formatMessage({ id: "process > file" })} -{" "}
                {intl.formatMessage({ id: "process > by" })}{" "}
                {file.audit.modified_by}{" "}
                {intl.formatMessage({ id: "process > on" })}{" "}
                {file.audit.modified_at}
              </span>
            )}
            <div className={bem.e("file-item")}>
              <span
                className={classnames(bem.e("file-name"), {
                  [bem.e("file-name-view")]: isView,
                })}
              >
                {get(file, "name")}
              </span>
              <div className={bem.e("file-buttons")}>
                {!viewHidden && isSupportViewer && (
                  <Button
                    className={bem.e("file-view")}
                    onClick={() => {
                      if (isOverrideViewAction && onClickViewAction) {
                        onClickViewAction(file);
                      } else {
                        onViewFile(file);
                      }
                    }}
                    disabled={viewDisabled}
                  >
                    <ViewIcon />
                  </Button>
                )}

                {!renamingHidden && (
                  <Button
                    className={bem.e("file-edit")}
                    onClick={(e) => onRenameFile(file)}
                    disabled={renamingDisabled}
                  >
                    <EditIcon />
                  </Button>
                )}
                {!deleteHidden && (
                  <Button
                    className={bem.e("file-trash")}
                    onClick={() => onDeleteFile(key)}
                    disabled={deleteDisabled}
                  >
                    <TrashSmallIcon />
                  </Button>
                )}
                {!downloadHidden && (
                  <Button
                    className={bem.e("file-download")}
                    onClick={() => onDownloadFile(file)}
                    disabled={downloadDisabled}
                  >
                    <DownloadFileIcon />
                  </Button>
                )}
              </div>
            </div>
          </div>
        );
      })}
      {error && <div className="alert-danger">{error}</div>}
      <PreviewFileModal
        isOpen={isOpenPreviewModal}
        onToggle={onTogglePreviewModal}
        onCancel={onCancelPreviewModal}
        file={currentPreviewFile}
        files={files}
        isEdit={isRename}
        onUploadedFiles={onUploadedFiles}
      />
    </div>
  );
};
HandleAssets.defaultProps = {
  files: [],
  onDeleted: () => {},
  viewDisabled: false,
  renamingDisabled: false,
  deleteDisabled: false,
  downloadDisabled: false,
  renamingHidden: false,
  viewHidden: false,
  deleteHidden: false,
  downloadHidden: false,
  isView: false,
  isOverrideViewAction: false,
};
HandleAssets.propTypes = {
  files: PropTypes.any,
  onDeleted: PropTypes.func,
  viewDisabled: PropTypes.bool,
  renamingDisabled: PropTypes.bool,
  deleteDisabled: PropTypes.bool,
  downloadDisabled: PropTypes.bool,
  renamingHidden: PropTypes.bool,
  viewHidden: PropTypes.bool,
  deleteHidden: PropTypes.bool,
  downloadHidden: PropTypes.bool,
  isView: PropTypes.bool,
  isOverrideViewAction: PropTypes.bool,
  onClickViewAction: PropTypes.func,
};
export default HandleAssets;
