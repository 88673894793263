import React from "react";
import bn from "utils/bemnames";
import { Row, Col, Label, Button } from "reactstrap";
import { ShadowBox, SectionTitle, SectionFields } from "components/Elements";
import { FormattedMessage } from "react-intl";
import UploadFile from "components/UploadFile";
import { RecurranceSmallIcon, ApproveIcon } from "components/CustomIcons";
import ConfirmationModal from "components/ConfirmationModal";
import classnames from "classnames";
import { map, get, isEmpty, difference, find } from "lodash";
import moment from "moment";
import { getEditableTabByUser, getEditableTabsByStage } from "utils/helpers";
import RotationChart from "components/RotationChart";
import Badge from "components/Badge";
import { validatePrivileges } from "utils/helpers";
import { PRIVILEGES } from "utils/constants";
import DubButton from "components/ProcessesList/DubbingQueue/DubButton";
import history from "components/History";
import URL from "utils/urls";

const bem = bn.create("production-dubbing-traffic-details");

function SpotTraffic(props) {
  const {
    spotIndex,
    spot,
    onValueChangedSpot,
    isView,
    process,
    step,
    valueChanged,
    template,
    user,
    setIsOpenConfirmationModal,
    setCurrentSpotIndex,
    dateFormatByServer,
    isWODubbingEnabled,
    autoDubbingEnabled,
  } = props;
  let recording;
  let recordingB;

  const isProductionComplete =
    get(spot, "contract_status") === "Production Complete";

  const isProductionProvidedNeededTags =
    get(spot, "contract_status") === "Production Provided - Needs tags";

  const isProductionNeeded =
    get(spot, "contract_status") === "Production Needed";

  const recordingPathField = step.fields.find(
    (f) => f.field === "recording_path"
  );

  const recordingPathFieldB = step.fields.find(
    (f) => f.field === "recording_path_b"
  );

  let processStepIndex;
  if (get(process, "data.fields.related_spots.value", []).length > 0) {
    // check for multiple spots
    let processRelated = get(
      process,
      "data.fields.related_spots.value",
      []
    ).find((item) => item.id === spot.key);
    processStepIndex = get(processRelated, "process_step_index");
  } else {
    // check for only spot
    processStepIndex = get(process, "data.fields.process_step_index.value");
  }
  if (
    !get(spot, "recording_requires_approval") ||
    (get(spot, "recording_requires_approval") &&
      get(spot, "approve_recording_status") === "approved") ||
    isProductionComplete
  ) {
    recording = spot.recording_path;
  }
  if (isProductionProvidedNeededTags) {
    recording = spot.final_recording_path;
  }

  if (
    !get(spot, "recording_requires_approval") ||
    (get(spot, "recording_requires_approval") &&
      get(spot, "approve_recording_status") === "approved") ||
    isProductionComplete
  ) {
    recordingB = spot.recording_path_b;
  }
  if (isProductionProvidedNeededTags) {
    recordingB = spot.final_recording_path_b;
  }
  const isTabCanEditByStage = getEditableTabsByStage(step, process, user);
  const { isEditor } = getEditableTabByUser({
    step,
    process,
    user,
    template: template.key,
  });
  const roleDubbers = get(spot, "role_dubber.users", []);
  const isAssigned = find(
    roleDubbers,
    (item) => Number(item.id) === Number(user.id)
  );
  const privileges = get(user, "privileges", []) || [];

  const isDubber = validatePrivileges({
    requires: {
      or: [
        PRIVILEGES.PERFORM_PRODUCTION_DUBBING,
        PRIVILEGES.EDIT_ALL_PRODUCTION_ORDERS,
      ],
    },
    privileges,
    user,
  });

  const isTabCanEditByUser = (isAssigned || isDubber) && isEditor;
  const isWOEnabled = get(user, "enable_wo", false);
  const isWOTrafficEnabled = get(user, "enable_wo_traffic", false);
  const isDubbingCompleted = get(spot, "is_dubbed", false);
  // 8 is dubbing step. We will disable if this spot in previous steps or cart number is not available

  let isDisabledButton =
    processStepIndex < 8 ||
    isEmpty(get(spot, "cart_number", "")) ||
    isView ||
    !(isTabCanEditByStage && isTabCanEditByUser);

  let disableDubButton =
    processStepIndex < 8 ||
    isEmpty(get(spot, "cart_number", "")) ||
    isView ||
    !(isTabCanEditByStage && isTabCanEditByUser) ||
    isDubbingCompleted;

  // const isWODubbingEnabled = get(user, "enable_wo_dubbing", false); // comment this line. Because need to get from user info api to get latest

  const showWOCartPrefix = !isWOTrafficEnabled && isWODubbingEnabled;

  const availableLineNumbers = get(
    process,
    "data.fields.available_line_numbers.value",
    []
  );
  const showLineNumber = !isEmpty(availableLineNumbers);
  const lineHashes = get(spot, "line_hashes", []);
  const isBookend = get(spot, "spot_type", "Standard") === "Book-ends";

  let recordingAudit;
  let recordingAuditB;

  if (isProductionNeeded) {
    recordingAudit = get(process, "data.fields.recording_audit.value", "");
  } else {
    recordingAudit = get(
      process,
      "data.fields.production_asset_audit.value",
      ""
    )
      ? get(process, "data.fields.production_asset_audit.value", "")
      : get(process, "data.fields.provided_audio_audit.value", "");
  }

  if (isProductionNeeded) {
    recordingAuditB = get(
      process,
      "data.fields.recording_path_b_audit.value",
      ""
    );
  } else {
    recordingAuditB = get(
      process,
      "data.fields.production_asset_b_audit.value",
      ""
    )
      ? get(process, "data.fields.production_asset_b_audit.value", "")
      : get(process, "data.fields.provided_audio_b_audit.value", "");
  }

  const renderSelectedLineHashes = (lineHashes, props) => {
    const { isEdit, onValueChangedSpot, spotIndex } = props;

    return lineHashes.map((item) => {
      return (
        <Badge
          key={item.value}
          text={item.label}
          onClick={() => {
            onValueChangedSpot(spotIndex, {
              field: "line_hashes",
              value: difference(lineHashes, [item]),
            });
          }}
          readOnly={!isEdit}
        />
      );
    });
  };

  return (
    <div
      className={classnames(bem.e("spot-row"), {
        [bem.e("spot-row-view")]: isView,
      })}
    >
      <Row
        className={classnames(
          bem.e("spot-row"),
          bem.e("spot-production-needed-row"),
          {
            [bem.e("spot-row-view")]: isView,
          }
        )}
      >
        <Col xs={12} className={bem.e("col-title")}>
          <h2 className={bem.e("spot-title")}>
            <FormattedMessage id="process > title spot" />
            {` `}
            {spotIndex + 1}
          </h2>
        </Col>
      </Row>
      <Row
        className={classnames(
          bem.e("spot-row"),
          bem.e("spot-production-needed-row"),
          bem.e("spot-row-view")
        )}
      >
        <Col className={bem.e("col-field")}>
          <Label className={bem.e("title-label")}>
            <FormattedMessage
              id={
                !isWOEnabled ? "process > field title" : "process > field title"
              }
            />
          </Label>
          <div className={bem.e("field-value")}>
            {get(spot, "title", "") ? (
              get(spot, "title", "")
            ) : (
              <FormattedMessage id="process > none" />
            )}
          </div>
        </Col>
        <Col className={bem.e("col-field")}>
          <Label className={bem.e("title-label")}>
            <FormattedMessage id="process > field spot type" />
          </Label>
          <div className={bem.e("field-value")}>
            {get(spot, "spot_type", "") ? (
              get(spot, "spot_type", "")
            ) : (
              <FormattedMessage id="process > none" />
            )}
          </div>
        </Col>
      </Row>
      {isWOTrafficEnabled && showLineNumber && !isEmpty(lineHashes) && (
        <Row
          className={classnames(
            bem.e("spot-row"),
            bem.e("spot-production-needed-row"),
            bem.e("spot-row-view")
          )}
        >
          <Col className="selected-line-hashes">
            <Label>
              <FormattedMessage id="process > field selected line #" />
            </Label>
            <div className="line-hashes-badges-container">
              {renderSelectedLineHashes(lineHashes, props)}
            </div>
          </Col>
        </Row>
      )}
      {isBookend && <div className={bem.e("horizontal-line")}></div>}
      <Row
        className={classnames(
          {
            [bem.e("not-dubbed")]: !spot.is_dubbed,
          },
          bem.e("spot-row"),
          bem.e("spot-production-needed-row"),
          bem.e("spot-row-view")
        )}
      >
        {showWOCartPrefix && (
          <Col xs={3}>
            <div className={bem.e("spot-center")}>
              <Label className={bem.e("label")}>
                <FormattedMessage id="process > field cart prefix" />
              </Label>
              <span className={bem.e("spot-value")}>
                {get(spot, "cart_prefix", "") ? (
                  get(spot, "cart_prefix", "")
                ) : (
                  <FormattedMessage id="process > none" />
                )}
              </span>
            </div>
          </Col>
        )}
        <Col xs={showWOCartPrefix ? 3 : 4}>
          <div className={bem.e("spot-center")}>
            <Label className={bem.e("label")}>
              <FormattedMessage id="process > field cart number" />
            </Label>
            {get(spot, "cart_number", "") ? (
              <span className={bem.e("spot-value")}>
                {get(spot, "cart_number", "")}
              </span>
            ) : isWOTrafficEnabled ? (
              <span className={bem.e("spot-pending")}>
                <RecurranceSmallIcon />
                <FormattedMessage id="process > title pending" />
              </span>
            ) : (
              <FormattedMessage id="process > none" />
            )}
          </div>
        </Col>
        <Col xs={showWOCartPrefix ? 3 : 4}>
          <div className={bem.e("spot-center")}>
            <Label className={bem.e("label")}>
              <FormattedMessage id="process > field isci / ad id" />
            </Label>
            <span className={bem.e("spot-value")}>
              {get(spot, "isci", "") ? (
                get(spot, "isci", "")
              ) : (
                <FormattedMessage id="process > none" />
              )}
            </span>
          </div>
        </Col>
        <Col xs={showWOCartPrefix ? 3 : 4}>
          <div className={bem.e("spot-right")}>
            {spot.is_dubbed ? (
              <div className={bem.e("dubbed")}>
                <span className={bem.e("dubbed-time")}>
                  {moment(spot.dubbed_at * 1000).format(
                    `${dateFormatByServer} h:mm a`
                  )}
                </span>
                <Button color="" className={bem.e("button-dubbed")}>
                  <span className={bem.e("button-dubbed-icon")}>
                    <ApproveIcon color="#fff" />
                  </span>
                  <FormattedMessage
                    id={`${
                      isWODubbingEnabled
                        ? "process > button dub sent"
                        : "process > button dubbed"
                    }`}
                  />
                </Button>
                <span
                  disabled={isDisabledButton}
                  className={classnames(bem.e("dubbed-undo"), {
                    [bem.e("dubbed-undo-disabled")]: isDisabledButton,
                  })}
                  onClick={() => {
                    if (isDisabledButton) {
                      return false;
                    }
                    setIsOpenConfirmationModal(true);
                    setCurrentSpotIndex(spotIndex);
                  }}
                >
                  <FormattedMessage id="process > button undo" />
                </span>
              </div>
            ) : (
              <div
                className={classnames(bem.e("not-dubbed"), {
                  automated: autoDubbingEnabled,
                })}
              >
                {autoDubbingEnabled ? (
                  <DubButton
                    processId={get(process, "key")}
                    isWODubbingEnabled={isWODubbingEnabled}
                    bem={bem}
                    isDisabled={disableDubButton}
                    spotIndex={spotIndex}
                    isDubbingCompleted={isDubbingCompleted}
                    maxProgressBarLength={146}
                    buttonKey={`${get(process, "key")}_${spotIndex}`}
                    dubCallback={(process) => {
                      if (get(process, "dub_script_status") === "completed") {
                        history.push(
                          URL.PROCESSES({ filter: "dubbing_queue" })
                        );
                      }
                    }}
                  />
                ) : (
                  <Button
                    color=""
                    className={bem.e("button-mark-as-dubbed")}
                    disabled={isDisabledButton}
                    onClick={() => {
                      onValueChangedSpot(spotIndex, {
                        field: "is_dubbed",
                        value: true,
                      });
                      onValueChangedSpot(
                        spotIndex,
                        {
                          field: "dubbed_at",
                          value: moment().unix(),
                        },
                        true // flag for auto save
                      );
                    }}
                  >
                    <FormattedMessage id="process > button mark as dubbed" />
                  </Button>
                )}
              </div>
            )}
          </div>
        </Col>
      </Row>
      <Row
        className={classnames(
          bem.e("spot-row"),
          bem.e("spot-production-needed-row"),
          {
            [bem.e("spot-row-view")]: isView,
          }
        )}
      >
        <Col xs={12}>
          <div className={bem.e("spot-center")}>
            {recording ? (
              <UploadFile
                mode={"view"}
                process={process}
                onUploaded={(value) => {
                  valueChanged(recordingPathField, process, template, user, {
                    value,
                  });
                }}
                disabled={isView}
                onDeleted={() => {
                  valueChanged(recordingPathField, process, template, user, {
                    value: "",
                  });
                }}
                url={recording}
                actions={["download"]}
                positionActions="bottom"
                auditInfo={recordingAudit}
                isShowFileNameInEditMode={true}
                isBookend={isBookend}
                path={isBookend ? "a" : ""}
                hideSpotLength={
                  !(isProductionNeeded || isProductionComplete) ||
                  !get(process, "data.fields.imported_from_wo.value", false)
                }
                spot={spot}
              >
                <FormattedMessage id="process > button upload" />
              </UploadFile>
            ) : (
              <div className={bem.e("spot-production")}>
                <FormattedMessage id="process > title spot in production" />
              </div>
            )}
          </div>
        </Col>
      </Row>
      {isBookend && (
        <React.Fragment>
          <Row
            className={classnames(
              bem.e("spot-row"),
              bem.e("spot-production-needed-row"),
              {
                [bem.e("spot-row-view")]: isView,
              }
            )}
          >
            <Col xs={12}>
              <div className={bem.e("spot-center")}>
                {recordingB ? (
                  <UploadFile
                    mode={"view"}
                    process={process}
                    onUploaded={(value) => {
                      valueChanged(
                        recordingPathFieldB,
                        process,
                        template,
                        user,
                        {
                          value,
                        }
                      );
                    }}
                    disabled={isView}
                    onDeleted={() => {
                      valueChanged(
                        recordingPathFieldB,
                        process,
                        template,
                        user,
                        {
                          value: "",
                        }
                      );
                    }}
                    url={recordingB}
                    actions={["download"]}
                    positionActions="bottom"
                    auditInfo={recordingAuditB}
                    isShowFileNameInEditMode={true}
                    isBookend={isBookend}
                    path={isBookend ? "b" : ""}
                    hideSpotLength={
                      !(isProductionNeeded || isProductionComplete) ||
                      !get(process, "data.fields.imported_from_wo.value", false)
                    }
                    spot={spot}
                  >
                    <FormattedMessage id="process > button upload" />
                  </UploadFile>
                ) : (
                  <div className={bem.e("spot-production")}>
                    <FormattedMessage id="process > title spot in production" />
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </React.Fragment>
      )}
    </div>
  );
}
export default function ProductionTrafficDetails(props) {
  const {
    process,
    step,
    template,
    user,
    valueChanged,
    isView,
    validationState,
    onAutoSubmit,
    isWODubbingEnabled,
    autoDubbingEnabled,
    dateFormatByServer,
  } = props;
  const spotInfo = get(process, "data.fields.spot_info.value", []);
  const spotInfoField = step.fields.find((f) => f.field === "spot_info");
  if (spotInfo.length === 0) return null;
  const onValueChangedSpot = (index, { field, value }, autoSave = false) => {
    const spot = spotInfo[index];
    if (spot) {
      spotInfo[index] = {
        ...spot,
        [field]: value,
      };
      valueChanged(
        {
          ...spotInfoField,
          changing: spotInfo.map((item, i) => {
            if (index === i) return [field];
            return [];
          }),
        },
        process,
        template,
        user,
        {
          value: spotInfo,
        }
      );
      if (autoSave) {
        process.data.fields.spot_info = {
          value: spotInfo,
          is_dirty: true,
        };
        onAutoSubmit(process);
      }
    }
  };
  return (
    <div className={bem.b()}>
      <ShadowBox className={bem.e("box-traffic-container")}>
        <SectionTitle className={bem.e("section-title")}>
          <FormattedMessage id="process > title traffic details" />
        </SectionTitle>
        <SectionFields className={bem.e("section-fields")}>
          {map(spotInfo, (spot, index) => (
            <SpotTraffic
              key={index}
              spot={spot}
              spotIndex={index}
              intl={props.intl}
              isView={isView}
              valueChanged={valueChanged}
              onValueChangedSpot={onValueChangedSpot}
              process={process}
              step={step}
              template={template}
              user={user}
              setIsOpenConfirmationModal={props.setIsOpenConfirmationModal}
              setCurrentSpotIndex={props.setCurrentSpotIndex}
              validationState={get(
                validationState,
                `spot_info.validation_error.${index}`
              )}
              isWODubbingEnabled={isWODubbingEnabled}
              autoDubbingEnabled={autoDubbingEnabled}
              dateFormatByServer={dateFormatByServer}
            />
          ))}
          <ConfirmationModal
            centered
            isOpen={props.isOpenConfirmationModal}
            className={bem.e("confirmation-modal")}
            title={<FormattedMessage id="process > confirm undo dubbed" />}
            confirmTitle={<FormattedMessage id="process > button yes undo" />}
            cancelTitle={<FormattedMessage id="process > button cancel" />}
            onConfirm={() => {
              onValueChangedSpot(props.currentSpotIndex, {
                field: "is_dubbed",
                value: false,
              });
              onValueChangedSpot(
                props.currentSpotIndex,
                {
                  field: "dubbed_at",
                  value: null,
                },
                true // flag for auto save
              );
              props.setIsOpenConfirmationModal(false);
            }}
            onCancel={() => {
              props.setIsOpenConfirmationModal(false);
              props.setCurrentSpotIndex(null);
            }}
            onToggle={() =>
              props.setIsOpenConfirmationModal(!props.isOpenConfirmationModal)
            }
            isCloseOutside={false}
          />
        </SectionFields>
        <Row
          style={{ paddingBottom: "25px", paddingLeft: "55px" }}
          className={classnames(bem.e("spot-row"), bem.e("spot-row-rotation"), {
            [bem.e("spot-row-view")]: isView,
          })}
        >
          <Col>
            <RotationChart
              valueChanged={props.valueChanged}
              step={step}
              process={process}
              template={template}
              user={user}
              validationState={validationState}
              isView={true}
              isShowAdjust={false}
              isContinuity={false}
            />
          </Col>
        </Row>
      </ShadowBox>
    </div>
  );
}
