import { compose } from "recompose";
import { get, values } from "lodash";
import { connect } from "react-redux";
import PrivilegedComponent from "./view";

export default compose(
  connect((state, props) => {
    return {
      privileges: props.privileges ? props.privileges : values(get(state, "auth.info.privileges")) || [],
      user: get(state, "auth.user"),
    };
  })
)(PrivilegedComponent);
