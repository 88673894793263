import View from "./view";
import { connect } from "react-redux";
import { compose, withHandlers, withState, lifecycle } from "recompose";
import { injectIntl } from "react-intl";
import { get } from "lodash";
import { getWinnersNotifications } from "store/actions/winners";
import { pageSize } from "utils/config";

const Enhanced = compose(
  connect(
    (state, props) => {
      return {
        ...props,
        isLoading: get(state, "winners.winnersNotifications.loading", false),
        isDirty: get(state, "winners.winnersNotifications.is_dirty", false),
        dateFormatByServer: get(
          state,
          "servers.currentServer.data.date_format",
          "MM/DD/YYYY"
        ),
      };
    },
    { getWinnersNotifications }
  ),

  withState("filtered", "setFiltered", []),
  withState("sorted", "setSorted", { id: "created_at", desc: true }),
  withHandlers({
    getItemsList: ({ getWinnersNotifications, process }) => (params = {}) => {
      const processKey = get(process, "data.fields.key.value");
      getWinnersNotifications({
        contestId: processKey,
        pageSize,
        ...params,
      });
    },
    getMoreItems: ({ getWinnersNotifications, process, notificationsData }) => (
      params = {}
    ) => {
      const processKey = get(process, "data.fields.key.value");
      const page = parseInt(get(notificationsData, "current_page", 1));
      getWinnersNotifications({
        contestId: processKey,
        pageSize,
        page: page + 1,
        ...params,
      });
    },
  }),
  withHandlers({
    onSort: ({ setSorted, sorted, getItemsList }) => (field) => {
      let newSort = {
        id: field,
        desc: !sorted.desc,
      };
      // detect third click
      if (sorted.id === field && sorted.desc && !newSort.desc) {
        newSort = {
          id: "created_at",
          desc: true,
        };
      }
      setSorted(newSort);
      getItemsList({
        sorted: newSort,
        page: 1,
      });
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.getItemsList({
        page: 1,
      });
    },
    componentDidUpdate(prevProps) {
      if (this.props.isDirty !== prevProps.isDirty && this.props.isDirty) {
        this.props.getItemsList({
          page: 1,
        });
      }
    },
  })
)(View);
export default injectIntl(Enhanced);
