export const SET_MESSAGES = "SET_MESSAGES";
export const REPLACE_MESSAGES = "REPLACE_MESSAGES";

export const FETCH_NOTIFICATIONS_REQUEST = "FETCH_NOTIFICATIONS_REQUEST";
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATIONS_ERROR = "FETCH_NOTIFICATIONS_ERROR";

export const MARK_MESSAGES_AS_READ_REQUEST = "MARK_MESSAGES_AS_READ_REQUEST";
export const MARK_MESSAGES_AS_READ_SUCCESS = "MARK_MESSAGES_AS_READ_SUCCESS";
export const MARK_MESSAGES_AS_READ_ERROR = "MARK_MESSAGES_AS_READ_ERROR";

export const EDIT_MESSAGE_REQUEST = "EDIT_MESSAGE_REQUEST";
export const EDIT_MESSAGE_SUCCESS = "EDIT_MESSAGE_SUCCESS";
export const EDIT_MESSAGE_ERROR = "EDIT_MESSAGE_ERROR";

export const DELETE_MESSAGE_REQUEST = "DELETE_MESSAGE_REQUEST";
export const DELETE_MESSAGE_SUCCESS = "DELETE_MESSAGE_SUCCESS";
export const DELETE_MESSAGE_ERROR = "DELETE_MESSAGE_ERROR";

export const DELETE_MESSAGES_REQUEST = "DELETE_MESSAGES_REQUEST";
export const DELETE_MESSAGES_SUCCESS = "DELETE_MESSAGES_SUCCESS";
export const DELETE_MESSAGES_ERROR = "DELETE_MESSAGES_ERROR";

export const MARK_PROCESS_MESSAGES_AS_READ_REQUEST =
  "MARK_PROCESS_MESSAGES_AS_READ_REQUEST";
export const MARK_PROCESS_MESSAGES_AS_READ_SUCCESS =
  "MARK_PROCESS_MESSAGES_AS_READ_SUCCESS";
export const MARK_PROCESS_MESSAGES_AS_READ_ERROR =
  "MARK_PROCESS_MESSAGES_AS_READ_ERROR";
export const UPDATE_MESSAGES_INCOMING = "UPDATE_MESSAGES_INCOMING";

export const GET_MESSAGES_BY_PROCESS_REQUEST =
  "GET_MESSAGES_BY_PROCESS_REQUEST";
export const GET_MESSAGES_BY_PROCESS_SUCCESS =
  "GET_MESSAGES_BY_PROCESS_SUCCESS";
export const GET_MESSAGES_BY_PROCESS_ERROR = "GET_MESSAGES_BY_PROCESS_ERROR";
