import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import classnames from "classnames";
import { setScriptField } from "utils/helpers";
import TextareaEvaluator from "components/TextareaEvaluator";
import bn from "utils/bemnames";
import { CloseIcon } from "components/CustomIcons";
import { FormattedMessage } from "react-intl";
import { get, map } from "lodash";
import moment from "moment";
const bem = bn.create("script-history-modal");
export default function ScriptHistoryModal(props) {
  const { scripts, dateFormatByServer } = props;
  return (
    <Modal
      isOpen={props.isOpen}
      className={classnames(props.className, bem.b())}
    >
      <ModalHeader toggle={props.onToggle} className={bem.e("header")}>
        {props.title}
        <div className={bem.e("close-icon")} onClick={props.onToggle}>
          <CloseIcon color="#657894" />
        </div>
      </ModalHeader>
      <ModalBody>
        {map(scripts, (item, index) => {
          return (
            <div className={bem.e("script-item")} key={index}>
              <div className={bem.e("script-header")}>
                <h2 className={bem.e("section-title")}>
                  {item.is_current ? (
                    <FormattedMessage id="process > current script" />
                  ) : (
                    <FormattedMessage id="process > script" />
                  )}
                </h2>
                <div className={bem.e("script-header-right")}>
                  <span className={bem.e("flight-text")}>
                    RAN:{" "}
                    {moment(item.start).format(
                      `${dateFormatByServer} - hh:mm A`
                    )}{" "}
                    -{" "}
                    {moment(item.end).format(`${dateFormatByServer} - hh:mm A`)}
                  </span>
                  <span className={bem.e("auth-text")}>
                    By {get(item, "created_by.name")} on{" "}
                    {moment(item.created_at).format(
                      `${dateFormatByServer} - hh:mm A`
                    )}
                  </span>
                </div>
              </div>
              <div className={bem.e("script-content")}>
                <TextareaEvaluator
                  readOnly={true}
                  text={setScriptField(get(item, "text", ""))}
                  showScriptLength={false}
                  hideOrderLength={true}
                  onEvaluatorCallback={() => {}}
                  hideHelperText={true}
                />
              </div>
            </div>
          );
        })}
      </ModalBody>
    </Modal>
  );
}

ScriptHistoryModal.propTypes = {
  onToggle: PropTypes.func,
  isOpen: PropTypes.bool,
};

ScriptHistoryModal.defaultProps = {
  isOpen: false,
  onToggle: () => {},
  title: <FormattedMessage id="process > script history" />,
};
