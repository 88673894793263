import * as React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { FormattedMessage } from "react-intl";
import TextInput from "components/TextInput";
import { Row, Col } from "reactstrap";

import bn from "utils/bemnames";
import classNames from "classnames";

const bem = bn.create("order-details");
const CommentToChat = (props) => {
  const {
    step,
    process,
    valueChanged,
    template,
    user,
    validationState,
    setIsFieldsChanged,
  } = props;
  const commentField = step.fields.find((f) => f.field === "comment");
  const onValueChanged = (field, value) => {
    valueChanged(field, process, template, user, { value });
    setIsFieldsChanged(true);
  };

  return (
    <Row>
      <Col
        className={classNames(bem.e("col-field"), bem.e("col-field-comment"))}
      >
        <TextInput
          label={<FormattedMessage id="process > post a comment to chat" />}
          placeholder={props.intl.formatMessage({
            id: "process > start typing",
          })}
          type="textarea"
          name="comment"
          aria-multiline="true"
          value={get(
            process.data.fields[commentField.field],
            "value.message",
            ""
          )}
          onChange={(event) => {
            onValueChanged(commentField, {
              message: get(event, "target.value", ""),
              tagged: [],
            });
          }}
          error={
            validationState[commentField.field] &&
            validationState[commentField.field].validation_error
          }
        />
      </Col>
    </Row>
  );
};
CommentToChat.defaultProps = {
  isView: false,
  validationState: {},
  valueChanged: () => {},
  setIsFieldsChanged: () => {},
};
CommentToChat.propTypes = {
  isView: PropTypes.bool,
  validationState: PropTypes.any,
  valueChanged: PropTypes.func,
  setIsFieldsChanged: PropTypes.func,
};
export default CommentToChat;
