import React, { memo } from "react";
import PropTypes from "prop-types";
import bn from "utils/bemnames";
import { Form, Row, Col, FormGroup, Button } from "reactstrap";
import { map, isFunction } from "lodash";
import { Formik } from "formik";
import * as Yup from "yup";
import classnames from "classnames";
import { FaPlus } from "react-icons/lib/fa";
import ToggleSwitch from "components/ToggleSwitch";
import WorkflowRole from "../WorkflowRole";
import Sortable from "../Sortable";
import Approval from "../Approval";
import { get, filter, forEach } from "lodash";
import TextInput from "components/TextInput";
import { Asterisk } from "components/Elements";
import { FormattedMessage } from "react-intl";
import {UpIcon, DownIcon} from 'components/CustomIcons';
import ToastManager from "components/ToastManager";
import PrivilegedComponent from "components/PrivilegedComponent";
import { PRIVILEGES } from "utils/constants";
const bem = bn.create("workflow-manager-item");
const WorkflowManagerItem = props => {
  const renderHeader = () => (
    <div
      className={bem.e("head-section")}
      onClick={() => {
        props.setIsOpenWorkflow({
          [props.id]: !props.isOpenWorkflow
        });
      }}
    >
      <h3
        className={classnames(
          bem.e("name", {
            [bem.e("name-disabled")]: !props.is_published
          })
        )}
      >
        {props.workflowName}
      </h3>

      <div className={bem.e("head-right")}>
        <span className={bem.e("head-type-text")}>
          {props.type ? (
            <FormattedMessage id={`workflow manager > ${props.type}`} />
          ) : null}
        </span>
        {props.isOpenWorkflow ? (
          <UpIcon className={bem.e("button-up-icon")} />
        ) : (
          <DownIcon className={bem.e("button-down-icon")} />
        )}
      </div>
    </div>
  );
  const renderView = () => {
    return (
      <React.Fragment>
        {renderHeader()}
        <div
          className={classnames({
            [bem.e("workflow-show")]: props.isOpenWorkflow,
            [bem.e("workflow-hide")]: !props.isOpenWorkflow
          })}
        >
          <div className={bem.e("workflow-view")}>
            <div className={bem.e("list")}>
              {map(props.steps, (item, index) => (
                <div key={index}>
                  <WorkflowRole {...item} />
                  <div className={bem.e("approval-list")}>
                    {map(item.approvals, (approval, approvalIndex) => (
                      <Approval
                        key={approvalIndex}
                        {...approval}
                        isView={true}
                      />
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <PrivilegedComponent requires={[PRIVILEGES.EDIT_WORKFLOWS]}>
              {!props.is_default && (
                <div className={bem.e("button-edit-workflow")}>
                  <Button
                    color="blue"
                    type="button"
                    className="btn btn-radius"
                    onClick={() => props.setIsRenderView(false)}
                  >
                    <FormattedMessage id={`workflow manager > edit workflow`} />
                  </Button>
                </div>
              )}
            </PrivilegedComponent>
          </div>
        </div>
        {!props.is_default && (
          <FormGroup className={bem.e("checkbox-wrapper")}>
            <PrivilegedComponent requires={[PRIVILEGES.PUBLISH_WORKFLOWS]}>
              {({isValid}) => {
                if(isValid){
                  return (
                    <ToggleSwitch
                      leftComponent={null}
                      rightComponent={null}
                      disabled={true}
                      name="is_published"
                      switchProps={{
                        checked: props.publishState && Object.keys(props.publishState).indexOf(props.id)>-1 ? props.publishState[props.id] : get(props, "is_published", false),
                        onChange: checked => {
                          props.setPublishState({[props.id]: checked});
                          props.onSubmitForm({...props.itemValues, is_published: checked}, () => {
                            props.setIsRenderView(true);
                          });
                        },
                        disabled: false,
                        offColor: "#E3ECF1",
                        onColor: "#8165f3",
                        uncheckedIcon: false,
                        checkedIcon: false
                      }}
                    />
                  )
                }else{
                  return (
                    <ToggleSwitch
                      leftComponent={null}
                      rightComponent={null}
                      disabled={true}
                      name="is_published"
                      switchProps={{
                        checked: props.publishState && Object.keys(props.publishState).indexOf(props.id)>-1 ? props.publishState[props.id] : get(props, "is_published", false),
                        onChange: checked => {},
                        disabled: false,
                        offColor: "#E3ECF1",
                        onColor: "#8165f3",
                        uncheckedIcon: false,
                        checkedIcon: false
                      }}
                    />
                  )
                }
              }}
            </PrivilegedComponent>
          </FormGroup>
        )}
      </React.Fragment>
    );
  };

  const renderForm = () => {
    let refForm = React.createRef();
    return (
      <React.Fragment>
        {renderHeader()}
        <Formik
          enableReinitialize
          ref={refForm}
          initialValues={{
            id: props.id,
            name: props.name || "",
            steps: props.steps,
            is_published: props.is_published,
            type: props.type
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required(
              props.intl.formatMessage({
                id: "validate > name is required"
              })
            ),
            // steps: Yup.array()
            //   .of(
            //     Yup.object().shape({
            //       id: Yup.mixed().required(),
            //       name: Yup.mixed().required(),
            //       approvals: Yup.mixed().required()
            //     })
            //   )
            //   .min(1),
            is_published: Yup.boolean()
          })}
          onSubmit={values => {
            //
            let isStepsInvalid = [];
            forEach(values.steps || [], step => {
              if (step.is_approval_step) {
                const isSelectedRolesUsers = filter(
                  step.approvals,
                  approval => {
                    return (
                      approval.roles.length === 0 || approval.users.length === 0
                    );
                  }
                );
                if (isSelectedRolesUsers !== 0)
                  isStepsInvalid = [...isStepsInvalid, ...isSelectedRolesUsers];
              }
            });
            if (isStepsInvalid.length !== 0) {
              ToastManager.show({
                title: props.intl.formatMessage({
                  id: "toast > title not saved"
                }),
                message: props.intl.formatMessage({
                  id: "toast > message error please correct approval step"
                }),
                level: "error"
              });
            } else {
              props.onSubmitForm(values, () => {
                props.setIsRenderView(true)
              });
            }
          }}
        >
          {formProps => {
            const errors = formProps.errors;
            const touched = formProps.touched;
            const values = formProps.values;
            return (
              <Form
                onSubmit={formProps.handleSubmit}
                className={classnames(bem.e("workflow-form"), {
                  [bem.e("workflow-form-show")]: props.isOpenWorkflow,
                  [bem.e("workflow-form-hide")]: !props.isOpenWorkflow
                })}
              >
                <div
                  className={classnames({
                    [bem.e("workflow-show")]: props.isOpenWorkflow,
                    [bem.e("workflow-hide")]: !props.isOpenWorkflow
                  })}
                >
                  <Row>
                    <Col>
                      <FormGroup>
                        <TextInput
                          label={
                            <span>
                              <FormattedMessage id="workflow manager > channel name" />
                              <Asterisk>*</Asterisk>
                            </span>
                          }
                          type="text"
                          name="name"
                          placeholder={props.intl.formatMessage({
                            id: "workflow manager > enter workflow name"
                          })}
                          value={values.name}
                          onChange={({ target }) => {
                            formProps.setFieldValue("name", target.value);
                            props.setWorkflowName(target.value);
                          }}
                          onBlur={formProps.handleBlur}
                          error={touched.name && errors.name}
                        />
                      </FormGroup>
                      <FormGroup>
                        <div className={bem.e("list")}>
                          {map(values.steps, (step, indexStep) => (
                            <div key={indexStep}>
                              <WorkflowRole {...step} />
                              {step.is_approval_step && (
                                <>
                                  <div className={bem.e("approval-list")}>
                                    <Sortable
                                      items={get(step, "approvals", [])}
                                      renderItem={(approval, indexApproval) => {
                                        return (
                                          <Approval
                                            key={indexApproval}
                                            value={approval}
                                            users={props.users}
                                            roles={props.roles}
                                            onChange={value => {
                                              let approvals = get(
                                                step,
                                                "approvals",
                                                []
                                              );
                                              approvals[indexApproval] = value;

                                              const steps = values.steps || [];
                                              steps[indexStep] = {
                                                ...step,
                                                approvals
                                              };
                                              formProps.setFieldValue(
                                                "steps",
                                                steps
                                              );
                                            }}
                                            onRemove={() => {
                                              let approvals = get(
                                                step,
                                                "approvals",
                                                []
                                              );
                                              approvals.splice(
                                                indexApproval,
                                                1
                                              );

                                              const steps = values.steps || [];
                                              steps[indexStep] = {
                                                ...step,
                                                approvals
                                              };

                                              formProps.setFieldValue(
                                                "steps",
                                                steps
                                              );
                                            }}
                                          />
                                        );
                                      }}
                                    />
                                  </div>
                                  {get(step, "approvals", []).length === 0 && <div className={bem.e("button-wrapper")}>
                                    <Button
                                      className={bem.e("button-create")}
                                      color=""
                                      onClick={() => {
                                        let steps = values.steps;
                                        if (steps[indexStep]) {
                                          steps[indexStep] = {
                                            ...steps[indexStep],
                                            approvals: [
                                              ...get(
                                                steps,
                                                `${indexStep}.approvals`,
                                                []
                                              ),
                                              {
                                                roles: [],
                                                users: []
                                              }
                                            ]
                                          };
                                          formProps.setFieldValue(
                                            "steps",
                                            steps
                                          );
                                        }
                                      }}
                                    >
                                      <i className={bem.e("button-icon")}>
                                        <FaPlus />
                                      </i>
                                      <FormattedMessage id="workflow manager > add approval step" />
                                    </Button>
                                  </div>}
                                </>
                              )}
                            </div>
                          ))}
                        </div>
                      </FormGroup>

                      <FormGroup>
                        <div className={bem.e("form-buttons")}>
                          <PrivilegedComponent requires={[PRIVILEGES.EDIT_WORKFLOWS]}>
                            <Button
                              color="blue"
                              type="submit"
                              className="btn btn-radius"
                            >
                              <FormattedMessage
                                id={`workflow manager > save form`}
                              />
                            </Button>
                          </PrivilegedComponent>
                          <Button
                            color="blue"
                            outline
                            className="btn btn-radius"
                            onClick={() => {
                              if (isFunction(props.onCancel)) {
                                props.onCancel();
                              }
                              props.setIsRenderView(true);
                            }}
                          >
                            <FormattedMessage
                              id={`workflow manager > cancel`}
                            />
                          </Button>
                          <PrivilegedComponent requires={[PRIVILEGES.EDIT_WORKFLOWS]}>
                            {
                              isFunction(props.onDelete) && (
                                <Button
                                  className={classnames("btn btn-radius", bem.e('btn-right'))}
                                  color="blue"
                                  outline
                                  onClick={() => props.onDelete(props.id)}
                                >
                                  <FormattedMessage
                                    id={`workflow manager > delete`}
                                  />
                                </Button>
                              )
                            }
                          </PrivilegedComponent>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <FormGroup className={bem.e("checkbox-wrapper")}>
                  <PrivilegedComponent requires={[PRIVILEGES.PUBLISH_WORKFLOWS]}>
                    {({isValid}) => {
                      if(isValid){
                        return (
                          <ToggleSwitch
                            leftComponent={null}
                            rightComponent={null}
                            name="is_published"
                            switchProps={{
                              checked: get(values, "is_published", false),
                              onChange: checked => {
                                formProps.setFieldValue("is_published", checked);
                              },
                              disabled: false,
                              offColor: "#E3ECF1",
                              onColor: "#8165f3",
                              uncheckedIcon: false,
                              checkedIcon: false
                            }}
                          />
                        )
                      }else{
                        return (
                          <ToggleSwitch
                            leftComponent={null}
                            rightComponent={null}
                            name="is_published"
                            switchProps={{
                              checked: get(values, "is_published", false),
                              onChange: checked => {},
                              disabled: false,
                              offColor: "#E3ECF1",
                              onColor: "#8165f3",
                              uncheckedIcon: false,
                              checkedIcon: false
                            }}
                          />
                        )
                      }
                    }}
                  </PrivilegedComponent>
                </FormGroup>
              </Form>
            );
          }}
        </Formik>
      </React.Fragment>
    );
  };
  return (
    <div className={bem.b()}>
      {props.isRenderView ? renderView() : renderForm()}
    </div>
  );
};
WorkflowManagerItem.defaultProps = {
  isRenderView: true,
  onCancel: () => {},
  onSubmitForm: () => {},
  users: [],
  roles: [],
  isView: true
};
WorkflowManagerItem.propTypes = {
  isRenderView: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmitForm: PropTypes.func,
  users: PropTypes.any,
  roles: PropTypes.any,
  isView: PropTypes.bool
};
export default memo(WorkflowManagerItem);
