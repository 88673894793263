import View from "./view";
import { withState, compose, lifecycle } from "recompose";
import { injectIntl } from "react-intl";
export default injectIntl(
  compose(
    withState("isRenderView", "setIsRenderView", true),
    withState("workflowName", "setWorkflowName", ({ name }) => name),
    withState("publishState", "setPublishState", {}),
    lifecycle({
      componentDidUpdate() {
        if (
          this.props.isView !== this.props.isRenderView &&
          this.props.isRenderView
        ) {
          this.props.setIsRenderView(this.props.isView);
        }
        if (
          this.props.workflowName !== this.props.name &&
          !this.props.workflowName
        ) {
          this.props.setWorkflowName(this.props.name);
        }
      }
    })
  )(View)
);
