import * as Types from "store/types/locations";
import { isFunction } from "lodash";
import Api from "utils/api";
import * as Const from "utils/constants";
import queryString from "query-string";

export const getLocations = (params) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.FETCH_LOCATIONS_REQUEST });

    let response = await Api.doCall(
      `${Const.GET_LOCATIONS()}?${queryString.stringify(params)}`,
      "GET"
    );

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.FETCH_LOCATIONS_SUCCESS,
          payload: response.data,
        });
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({
    type: Types.FETCH_LOCATIONS_ERROR,
    payload: { data: errorStr },
  });
};

export const createLocation = (data, cb) => async (dispatch) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.CREATE_LOCATION_REQUEST });

    let response = await Api.doCall(Const.GET_LOCATIONS(), "POST", data);

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.CREATE_LOCATION_SUCCESS,
          payload: response.data,
        });

        if (isFunction(cb)) {
          cb(response.data);
        }

        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({ type: Types.CREATE_LOCATION_ERROR, payload: errorStr });
};

//update location values to ES
export const updateLocation = (id, data, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.UPDATE_LOCATION_REQUEST });
    //api call for store data
    let response = await Api.doCall(Const.GET_LOCATION(id), "PUT", data);
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        //response data
        let responseData = JSON.parse(JSON.stringify(response.data));
        dispatch({
          type: Types.UPDATE_LOCATION_SUCCESS,
          payload: responseData,
        });
        // refresh location when adding new
        if (isFunction(cb)) cb();
        return;
      }
      //error data
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  //return error
  dispatch({ type: Types.UPDATE_LOCATION_ERROR, payload: errorStr });
};
