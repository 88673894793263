import { compose, withState, lifecycle } from "recompose";
import { get, isEqual } from "lodash";
import View from "./view";
export default compose(
  withState("channelName", "setChannelName", ({ item }) =>
    get(item, "_source.name")
  ),
  withState("channelIcon", "setChannelIcon", ({ item }) =>
    get(item, "_source.channel_icon")
  ),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (
        get(this.props, "item._source.name") !==
        get(prevProps, "item._source.name")
      ) {
        this.props.setChannelName(get(this.props, "item._source.name"));
      }
      if (
        get(this.props, "item._source.channel_icon") !==
        get(prevProps, "item._source.channel_icon")
      ) {
        this.props.setChannelIcon(get(this.props, "item._source.channel_icon"));
      }

      if (!isEqual(this.props.isOpenEditForms, prevProps.isOpenEditForms)) {
        this.props.setChannelName(get(this.props, "item._source.name"));
      }
    },
  })
)(View);
