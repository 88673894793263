import React from "react";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import { FaSortDesc, FaSortAsc } from "react-icons/lib/fa";
import { Table } from "reactstrap";
import useInfiniteScroll from "utils/useInfiniteScroll";
import NoItemsFound from "components/NoItemsFound";
import { find, get, map } from "lodash";
import { categoryList, PhoneNumberFormat } from "utils/config";
import NumberFormat from "react-number-format";
import moment from "moment";
import Tooltip from "rc-tooltip";

const getStatus = (item) => {
  const contest = get(item, "_source.contests", {});
  if (contest.status === "fulfilled") return "fulfilled";
  const expirationDate = get(contest, "prizes.0.expiration_date", "");
  if (expirationDate) {
    const isSelectedDatePast = moment(expirationDate, "YYYY-MM-DD").diff(
      moment(),
      "days"
    );
    if (isSelectedDatePast < 0) return "expired";
  }
  return contest.status;
};
const getStationName = (contest, intl, stations) => {
  if (!contest.station_key) return intl.formatMessage({ id: "process > none" });
  const stationData = find(
    stations,
    (item) => item.station_key === contest.station_key
  );
  if (!stationData) return intl.formatMessage({ id: "process > none" });
  if (!stationData.call_letters && !stationData.name)
    return intl.formatMessage({ id: "process > none" });
  return (
    <span>
      <span>{stationData.call_letters}</span>
      <span className="station-name">{stationData.name}</span>
    </span>
  );
};

const View = ({
  actionSort,
  sort,
  items,
  bem,
  onView,
  isLoading,
  handleLoadMore,
  hasMoreItems,
  intl,
  info,
}) => {
  const authStations = get(info, "stations", []);
  const getWinnerType = (item) => {
    let sortName;
    let name;
    let className;
    if (item.type === "call_in_contest") {
      sortName = "CALL";
      name = "Call-in";
      className = "call-in";
    }
    return (
      <div className={classnames(bem.e("winner-type"), className)}>
        <span className={bem.e("winner-sort-name")}>{sortName}</span>
        <span className={bem.e("winner-name")}>{name}</span>
      </div>
    );
  };

  const getWinnerName = (item) => {
    let name = "";
    if (item._source.first_name && item._source.last_name) {
      name = item._source.first_name + " " + item._source.last_name;
    } else if (item._source.name) {
      name = item._source.name;
    }

    if (!name) {
      name = intl.formatMessage({ id: "process > none" });
    }

    return name;
  };
  const getWinnerPhone = (item) => {
    let phoneFieldPrimary = get(item, "_source.primary_contact", "phone_number");
    if (phoneFieldPrimary === "email_address" || !phoneFieldPrimary)
      phoneFieldPrimary = "phone_number";
    return (
      item._source[phoneFieldPrimary] && (
        <span className={bem.e("winner-phone-text")}>
          <FormattedMessage id={`winners > ${phoneFieldPrimary} sort name`} />:
          {` `}
          <NumberFormat
            value={item._source[phoneFieldPrimary]}
            displayType={"text"}
            format={PhoneNumberFormat}
          />
        </span>
      )
    );
  };
  useInfiniteScroll({
    useWindow: false,
    elementId: "cr-app-main",
    onLoadMore: handleLoadMore,
    shouldLoadMore: hasMoreItems,
  });
  return (
    <div className={classnames(bem.e("table-wrapper"), bem.e("table-prizes"))}>
      <Table className={bem.e("table")}>
        <thead>
          <tr>
            <th
              onClick={() => actionSort("type")}
              className={classnames("can-click", bem.e("table-first-head"))}
            >
              <div className={bem.e("item-sortable")}>
                <FormattedMessage id="winners > type" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        get(sort, "id") === "type" && !get(sort, "desc"),
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        get(sort, "id") === "type" && get(sort, "desc"),
                    })}
                  />
                </div>
              </div>
            </th>
            <th onClick={() => actionSort("title")} className="can-click">
              <div className={bem.e("item-sortable")}>
                <FormattedMessage id="winners > contest" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        get(sort, "id") === "title" && !get(sort, "desc"),
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        get(sort, "id") === "title" && get(sort, "desc"),
                    })}
                  />
                </div>
              </div>
            </th>
            <th onClick={() => actionSort("prize")} className="can-click">
              <div className={classnames(bem.e("item-sortable"))}>
                <FormattedMessage id="winners > prize" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        get(sort, "id") === "prize" && !get(sort, "desc"),
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        get(sort, "id") === "prize" && get(sort, "desc"),
                    })}
                  />
                </div>
              </div>
            </th>
            <th onClick={() => actionSort("station")} className="can-click">
              <div className={classnames(bem.e("item-sortable"))}>
                <FormattedMessage id="winners > station" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        get(sort, "id") === "station" && !get(sort, "desc"),
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        get(sort, "id") === "station" && get(sort, "desc"),
                    })}
                  />
                </div>
              </div>
            </th>
            <th onClick={() => actionSort("name")} className="can-click">
              <div className={classnames(bem.e("item-sortable"))}>
                <FormattedMessage id="winners > winner" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        get(sort, "id") === "name" && !get(sort, "desc"),
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        get(sort, "id") === "name" && get(sort, "desc"),
                    })}
                  />
                </div>
              </div>
            </th>
            <th onClick={() => actionSort("won_at_ts")} className="can-click">
              <div
                className={classnames(
                  bem.e("item-sortable"),
                  bem.e("item-center")
                )}
              >
                <FormattedMessage id="winners > date won" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        get(sort, "id") === "won_at_ts" && !get(sort, "desc"),
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        get(sort, "id") === "won_at_ts" && get(sort, "desc"),
                    })}
                  />
                </div>
              </div>
            </th>
            <th onClick={() => actionSort("status")} className="can-click">
              <div
                className={classnames(
                  bem.e("item-sortable"),
                  bem.e("item-center")
                )}
              >
                <FormattedMessage id="winners > status" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        get(sort, "id") === "status" && !get(sort, "desc"),
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        get(sort, "id") === "status" && get(sort, "desc"),
                    })}
                  />
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {items.length === 0 && !isLoading ? (
            <tr>
              <td colSpan={8}>
                <NoItemsFound />
              </td>
            </tr>
          ) : (
            map(items, (item) => {
              const contest = get(item, "_source.contests", {});
              const status = getStatus(item);
              return (
                <tr key={item._id} onClick={() => onView(item)}>
                  {/* type */}
                  <td
                    className={classnames(bem.e("first-col"), "align-middle")}
                  >
                    {getWinnerType(contest)}
                  </td>
                  {/* contest */}
                  <td
                    className={classnames("align-middle", bem.e("winner-text"))}
                  >
                    <strong>{contest.title}</strong>
                    <span className={bem.e("small-text")}>
                      {contest.type === "call_in_contest"
                        ? intl.formatMessage({
                            id: "process > promotion > contest > call in",
                          })
                        : ""}
                    </span>
                  </td>
                  {/* prize */}
                  <td className={classnames("align-middle")}>
                    {map(contest.prizes, (prize) => {
                      const prizeCategory = find(
                        categoryList,
                        (i) => i.value === prize.category
                      );
                      return (
                        <div key={prize.id}>
                          <span className={bem.e("winner-prize-text")}>
                            {prize.title}
                          </span>
                          <span className={bem.e("small-text")}>
                            {prizeCategory ? prizeCategory.label : ""}
                          </span>
                        </div>
                      );
                    })}
                  </td>
                  {/* station */}
                  <td
                    className={classnames(
                      "align-middle",
                      bem.e("winner-station-text")
                    )}
                  >
                    {getStationName(contest, intl, authStations)}
                  </td>
                  {/* winner */}
                  <td
                    className={classnames(
                      "align-middle",
                      bem.e("winner-list-name")
                    )}
                  >
                    {getWinnerName(item)}
                    {getWinnerPhone(item)}
                    {item._source.email_address && (
                      <Tooltip
                        placement="topLeft"
                        trigger={["click", "hover"]}
                        overlayClassName={classnames(
                          bem.e("tooltip"),
                          bem.e("email-tooltip")
                        )}
                        overlay={
                          <div className={bem.e("winner-email-text")}>
                            {item._source.email_address}
                          </div>
                        }
                      >
                        <div className={bem.e("winner-email-text")}>
                          {item._source.email_address}
                        </div>
                      </Tooltip>
                    )}
                  </td>
                  {/* date won */}
                  <td
                    className={classnames(
                      "align-middle",
                      bem.e("date-won"),
                      bem.e("text-center")
                    )}
                  >
                    {moment.utc(contest.won_at_ts * 1000).format("L")}
                  </td>
                  {/* status */}
                  <td
                    className={classnames("align-middle", bem.e("text-center"), bem.e('last-col'))}
                  >
                    {status ? (
                      <span
                        className={classnames(
                          bem.e("winner-status"),
                          bem.e(`winner-status-${status}`)
                        )}
                      >
                        {status}
                      </span>
                    ) : null}
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </Table>
    </div>
  );
};
export default View;
