import FilterDropdown from "./view";
import { compose, lifecycle, withState } from "recompose";
import { get, isEqual } from "lodash";
export default compose(
  withState("isMenuOpen", "setIsMenuOpen", false),
  withState("selectedFilters", "setSelectedFilters", (props) =>
    get(props, "value", [])
  ),
  lifecycle({
    componentWillReceiveProps(nextProps) {
      if (!isEqual(nextProps.value, this.props.value)) {
        this.props.setSelectedFilters(nextProps.value);
      }
    },
  })
)(FilterDropdown);
