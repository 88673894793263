import React, { useRef } from "react";
import { join, slice } from "lodash";
import { FormattedMessage } from "react-intl";
import useWindowDimensions from "utils/useWindowDimensions";
import useOutsideClick from "utils/useOutsideClick";

const OrderStations = (props) => {
  const {
    stationsData,
    bem,
    stationsDataName,
    isViewAllStations,
    setIsViewAllStations,
  } = props;
  const { width: widthScreen } = useWindowDimensions();
  const ref = useRef(null);
  useOutsideClick(ref, () => {
    setIsViewAllStations(false);
  });
  if (widthScreen <= 480) {
    return (
      <span className={bem.e("stations")} ref={ref}>
        {slice(stationsData, 0, 2)
          .map((item) => item.name)
          .join(", ")}
        {stationsData.length > 2 ? (
          <span
            className={bem.e("view-all")}
            onClick={() => setIsViewAllStations(!isViewAllStations)}
          >
            {!isViewAllStations && (
              <React.Fragment>
                <FormattedMessage id="process > view all" />
              </React.Fragment>
            )}
          </span>
        ) : null}
        {isViewAllStations && (
          <div className={bem.e("tooltip-stations")}>
            <strong>
              <FormattedMessage id="process > stations" />:
            </strong>
            <span>{stationsData.map((item) => item.name).join(", ")}</span>
          </div>
        )}
      </span>
    );
  }
  return (
    <span className={bem.e("stations")}>
      {join(stationsDataName, ", ")}
      {stationsData.length > 3 ? (
        <span
          className={bem.e("view-all")}
          onClick={() => setIsViewAllStations(!isViewAllStations)}
        >
          {isViewAllStations ? (
            <React.Fragment>
              {` `}(<FormattedMessage id="process > close" />)
            </React.Fragment>
          ) : (
            <React.Fragment>
              ...(
              <FormattedMessage id="process > view all" />)
            </React.Fragment>
          )}
        </span>
      ) : null}
    </span>
  );
};
const OrderInfo = ({
  isViewAllStations,
  bem,
  clientName,
  processTitle,
  stationsDataName,
  stationsData,
  setIsViewAllStations,
  orderStartDate,
  orderEndDate,
  helperText,
}) => {
  return (
    <React.Fragment>
      <div className={bem.e("p-client-name")}>{clientName}</div>
      <div className={bem.e("p-order-title")}>{processTitle}</div>
      <div className={bem.e("process-stations")}>
        <strong>
          <FormattedMessage id="process > stations" />:
        </strong>
        <OrderStations
          stationsData={stationsData}
          bem={bem}
          stationsDataName={stationsDataName}
          isViewAllStations={isViewAllStations}
          setIsViewAllStations={setIsViewAllStations}
        />
      </div>
      <div className={bem.e("process-flight-dates")}>
        <strong>
          <FormattedMessage id="process > flight dates" />:
        </strong>
        <span
          className={bem.e("date")}
        >{`${orderStartDate} - ${orderEndDate}`}</span>
      </div>
      {helperText ? (
        <div className={bem.e("helper-text")}>{helperText}</div>
      ) : null}
    </React.Fragment>
  );
};
export default OrderInfo;
