import { FormattedMessage } from "react-intl";
import React from "react";
import { get, map, orderBy } from "lodash";
import { countdownWithTimezone } from "utils/helpers";
import fields  from "../fields";
const WriteScript = {
  process_step_index: 2,
  key: "write_script",
  title: <FormattedMessage id="process > title scripts" />,
  caption_color: "#CC1EE6",
  step_roles: [],
  step_viewers_roles: [],
  step_editors_roles: [],
  step_viewers_privileges: ["programming_element_view"],
  step_editors_privileges: {
    or: ["programming_element_edit", "programming_element_delete"],
  },
  step_container: "none",
  no_edit_button: true,
  is_hidden: function(user, process, step) {
    return false;
  },
  isOpen: function({ step, process, template, user }) {
    return !get(process, "data.fields.write_script_status.value", false);
  },
  className: "write-script",
  fields: [
    {
      component: "promotion_liner_scripts",
    },
    ...fields,
    {
      key: "save_button",
      text: <FormattedMessage id="process > button save" />,
      on_click: {
        validate: true,
        save: true,
      },
      fields: [
        {
          key: "write_script_status",
          value: "completed",
        },
        {
          key: "process_step",
          value: "first_undone",
        },
        {
          key: "copy_groups",
          value: (user, template, process, step, item) => {
            // re-order copy groups
            let newCopyGroupsValue = get(
              process,
              "data.fields.copy_groups.value",
              []
            );
            let copyGroups = map(newCopyGroupsValue, (group) => {
              const endDate = get(group, "end_date");
              const endDateTime = get(group, "end_date_time");

              const timezone = get(process, "data.fields.timezone.value", null);
              const timer = countdownWithTimezone(
                endDate,
                timezone,
                endDateTime
              );
              const isBaseline = get(group, "status") === "baseline";
              const isSuperseding = get(group, "status") === "superseding";
              const isExpired = get(timer, "isNegative");
              let order = isExpired ? 3 : isBaseline ? 1 : 2;
              return {
                ...group,
                is_baseline: isBaseline,
                is_superseding: isSuperseding,
                is_expired: isExpired,
                order,
              };
            });
            copyGroups = orderBy(copyGroups, ["order"], ["asc"]);
            return copyGroups;
          },
        },
      ],
      confirmation_dialog_props: {
        description: (
          <FormattedMessage id="process > description to submit production order" />
        ),
        confirmTitle: <FormattedMessage id="process > button yes" />,
        cancelTitle: <FormattedMessage id="process > button no" />,
      },
      success_dialog_props: {
        title: <FormattedMessage id="process > title submitted" />,
        state: "success",
      },
    },
  ],
};
export default WriteScript;
