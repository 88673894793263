import TextareaEvaluator, { DEFAULT_WORDS_PER_MIN } from "./view";
import { connect } from "react-redux";
import { compose, withState, withHandlers, lifecycle } from "recompose";
import {
  formatTimer,
  countSyllables,
  convertCountSyllablesToWord,
} from "./helper";
export { DEFAULT_WORDS_PER_MIN };
const DEFAULT_TIMER = ":00";

export default compose(
  connect(
    (state, props) => ({
      process: props.process ? props.process : state.processes.process,
    }),
    {}
  ),
  withState("isInitWordsCount", "setIsInitWordsCount", false),
  withState("timer", "setTimer", DEFAULT_TIMER),
  withState("wordCounting", "setWordCounting", 0),
  withState("seconds", "setSeconds", 0),
  withState("slider", "setSlider", null),
  withHandlers({
    onUpdateSeconds: ({ setSeconds, setTimer }) => (wordCounting, slider) => {
      const seconds = (wordCounting / slider) * 60;
      setSeconds(seconds);
      const timer = formatTimer(seconds);

      setTimer(timer);
    },
  }),
  withHandlers({
    onChangeSlider: ({
      wordCounting,
      setSlider,
      onUpdateSeconds,
      onWordsPerMinChangeCallback,
    }) => (slider) => {
      setSlider(slider);
      onUpdateSeconds(wordCounting, slider);
      if (onWordsPerMinChangeCallback) {
        onWordsPerMinChangeCallback(slider);
      }
    },
    onChangeEditorCallback: ({
      slider,
      setWordCounting,
      onUpdateSeconds,
      onEvaluatorCallback,
      text,
    }) => (html, editorState) => {
      const contentState = editorState.getCurrentContent();
      const plainText = contentState.getPlainText();
      const wordCounting = convertCountSyllablesToWord(
        countSyllables(plainText)
      );
      setWordCounting(wordCounting);
      onUpdateSeconds(wordCounting, slider);
      //callback
      if ((html === "<p><br></p>" && text === "") || html === text) return; // It is same. We don't need to re-update
      onEvaluatorCallback(html, contentState);
    },
  }),
  lifecycle({
    componentDidMount() {
      const wordsPerMin = this.props.wordsPerMin || DEFAULT_WORDS_PER_MIN;
      this.props.setSlider(wordsPerMin);
      this.props.onUpdateSeconds(this.props.wordCounting, wordsPerMin);
    },
    componentDidUpdate() {
      if (
        this.props.wordsPerMin &&
        !this.props.isInitWordsCount &&
        this.props.wordsPerMin !== DEFAULT_WORDS_PER_MIN
      ) {
        this.props.setIsInitWordsCount(true);
        this.props.setSlider(this.props.wordsPerMin);
        this.props.onUpdateSeconds(
          this.props.wordCounting,
          this.props.wordsPerMin
        );
      } else if (this.props.slider === null) {
        this.props.setSlider(DEFAULT_WORDS_PER_MIN);
        this.props.onUpdateSeconds(
          this.props.wordCounting,
          DEFAULT_WORDS_PER_MIN
        );
      }
    },
  })
)(TextareaEvaluator);
