import React from "react";
import PropTypes from "../utils/propTypes";
import bn from "utils/bemnames";
import classNames from "classnames";
import { getSortUserName } from "utils/helpers";
const bem = bn.create("avatar");

const Avatar = ({
  rounded,
  circle,
  src,
  size,
  tag: Tag,
  className,
  name,
  style,
  ...restProps
}) => {
  const classes = classNames(
    { "rounded-circle": circle, rounded },
    className,
    { "avatar-sort-name": !src },
    bem.b()
  );
  return (
    <div
      style={{
        width: size,
        height: size,
        ...style,
        backgroundImage: `url(${src})`,
      }}
      className={classes}
      {...restProps}
    >
      {!src && name && (
        <span className="avatar-sort-name-text">{getSortUserName(name)}</span>
      )}
    </div>
  );
};

Avatar.propTypes = {
  tag: PropTypes.component,
  rounded: PropTypes.bool,
  circle: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  src: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};

Avatar.defaultProps = {
  tag: "img",
  rounded: false,
  circle: true,
  size: 40,
  src: null,
  style: {},
  onClick: () => {},
};

export default Avatar;
