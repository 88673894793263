import { FormattedMessage } from "react-intl";
import React from "react";
import {
  get,
  map,
  omit,
  sum,
  uniq,
  delay,
  find,
  includes,
  isEmpty,
  filter,
} from "lodash";
import history from "components/History";
import moment from "moment";
import { checkUserRedirectToCurrentTasks } from "utils/helpers";
import { orderLengthOptions } from "utils/config";

const continuity = {
  process_step_index: 7,
  key: "continuity",
  title: <FormattedMessage id="process > title continuity" />,
  caption_color: "#0F99A2",
  description: "",
  asynchronous: true,
  step_asynchronous: "order_details",
  isCollapsible: true,
  step_roles: [8, 7],
  step_viewers_roles: [8, 7, 4, 2, 9],
  step_editors_roles: [8, 7, 4],
  step_viewers_privileges: {
    or: ["perform_production_continuity", "edit_all_production_orders"],
  },
  step_editors_privileges: {
    or: ["perform_production_continuity", "edit_all_production_orders"],
  },
  status: "initial",
  className: "continuity-container",
  is_check_hidden_tab: function(user, process, step) {
    return (
      process.data.fields.order_sold && !process.data.fields.order_sold.value
    );
  },
  is_hidden: function(user, process, step) {
    return (
      process.data.fields.order_sold && !process.data.fields.order_sold.value
    );
  },
  workflow: {
    title: "continuity",
    icon: "ContinuityIcon",
  },
  is_approval_step: true,
  fields: [
    {
      component: "production_reschedule",
    },
    {
      component: "production_details",
      props: {
        isCollapse: true,
      },
    },
    {
      component: "production_continuity_traffic_details",
    },
    {
      field: "recording_path",
    },
    {
      field: "recording_path_b",
    },
    {
      field: "order_title",
      title: <FormattedMessage id="process > field title" />,
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        return value.length > 2
          ? true
          : "Order title must be at least 3 letters long";
      },
    },
    {
      field: "contract_status",
      title: <FormattedMessage id="process > field production type" />,
      props: {
        options: [
          {
            label: (
              <FormattedMessage id="process > option label production needed" />
            ),
            value: "Production Needed",
          },
          {
            label: (
              <FormattedMessage id="process > option label production provided" />
            ),
            value: "Production Provided",
          },
        ],
      },
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        return get(value, "length") > 0 ||
          (get(process, "fields.order_sold") &&
            !get(process, "fields.order_sold.value"))
          ? true
          : "Please select an order status";
      },
    },
    {
      field: "order_length_custom",
    },
    {
      field: "order_length",
      title: <FormattedMessage id="process > field length" />,
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        return value ? true : "Please enter a length";
      },
      props: {
        options: orderLengthOptions,
      },
    },
    {
      field: "role_dubber",
      title: <FormattedMessage id="process > field dubber" />,
      roles: [9],
      privileges: {
        or: ["perform_production_dubbing", "edit_all_production_orders"],
      },
      default: 9,
      validation: function(
        step,
        item,
        process,
        template,
        user,
        value,
        process_key,
        extraData
      ) {
        const spotInfo = get(process, "fields.spot_info.value", []).find(
          (spot) => spot.key === process_key
        );

        const isProductionNeeded =
          get(spotInfo, "contract_status") === "Production Needed";

        const isEnableEnterprise = get(
          extraData,
          "production_multimarket",
          false
        );
        if (isEnableEnterprise || isProductionNeeded) return true;
        if (get(value, "users.length") > 0) return true;
        return "Please select dubber";
      },
      mandatory: true,
    },
    {
      field: "role_producer",
      title: <FormattedMessage id="process > field producer" />,
      roles: [5],
      privileges: {
        or: ["produce_production", "edit_all_production_orders"],
      },
      mandatory: true,
      default: 5,
      validation: function(
        step,
        item,
        process,
        template,
        user,
        value,
        process_key,
        extraData
      ) {
        const spotInfo = get(process, "fields.spot_info.value", []).find(
          (spot) => spot.key === process_key
        );
        const isEnableEnterprise = get(
          extraData,
          "production_multimarket",
          false
        );
        const isProductionComplete =
          get(spotInfo, "contract_status") === "Production Complete";
        const isProductionNeeded =
          get(spotInfo, "contract_status") === "Production Needed";

        if (isEnableEnterprise || isProductionComplete || isProductionNeeded)
          return true;
        return get(value, "users.length") > 0
          ? true
          : "Please select a producer";
      },
    },
    {
      field: "rotation_chart",
      validation: function(step, item, process, template, user, value) {
        let isValid = true;
        const rotationChart = map(value, (rotation) => {
          const rotationSpot = Object.values(omit(rotation, ["station"]));
          const total = sum(rotationSpot);
          if (total < 99 || total > 100) {
            isValid = false;
            return "Spot rotation percentages must add up to 100%";
          }
          return "";
        });
        if (isValid) return true;
        return rotationChart;
      },
      mandatory: true,
    },
    {
      field: "role_dubber",
      title: <FormattedMessage id="process > field dubber" />,
      roles: [9],
      privileges: {
        or: ["perform_production_dubbing", "edit_all_production_orders"],
      },
      default: 9,
      validation: function(
        step,
        item,
        process,
        template,
        user,
        value,
        process_key,
        extraData
      ) {
        const convertedSpecToSold = get(
          process,
          "fields.converted_from_spec_to_sold.value",
          ""
        );
        if (!convertedSpecToSold) return true;
        if (get(value, "users.length") > 0) return true;
        return "Please select dubber";
      },
      mandatory: true,
    },
    {
      field: "spot_info",
      validation: function(
        step,
        item,
        process,
        template,
        user,
        value,
        process_key,
        extraData
      ) {
        const isEnableEnterprise = get(
          extraData,
          "production_multimarket",
          false
        );
        const convertedSpecToSold = get(
          process,
          "fields.converted_from_spec_to_sold.value",
          ""
        );
        if (isEnableEnterprise) return true;

        const availableLineNumbers = get(
          process,
          "fields.available_line_numbers.value",
          []
        );
        const showLineNumber = !isEmpty(availableLineNumbers);

        let isValid = true;
        let validProductionNeededSpots = [];
        let validProductionProvidedSpots = [];
        let isDirtyProductionProvided = false;
        const totalProductionProvidedSpots = filter(
          value,
          (spot) => spot.template_key === "production_provided"
        ).length;
        const spotInfo = map(value, (spot, index) => {
          let error = {};
          if (!spot["title"]) {
            isValid = false;
            error.title = "Order title is required";
          }
          if (get(user, "enable_wo_traffic", false) && !spot["isci"]) {
            isValid = false;
            error.isci = "You must enter an ISCI/AD id";
          }

          let spotInfoDirtyFields = get(
            process,
            "fields.spot_info_fields_dirty.value",
            []
          );
          const cartNumberEdited =
            spotInfoDirtyFields &&
            includes(spotInfoDirtyFields[index], "cart_number");

          if (
            (!get(user, "enable_wo_traffic", false) || cartNumberEdited) &&
            !spot["cart_number"]
          ) {
            isValid = false;
            error.cart_number = "You must enter a cart number";
          }

          if (
            !get(user, "enable_wo_traffic", false) &&
            get(user, "enable_wo_dubbing", false) &&
            !spot["cart_prefix"]
          ) {
            isValid = false;
            error.cart_prefix = "You must select an aduio source prefix";
          }
          if (!spot["spot_type"]) {
            isValid = false;
            error.spot_type = "Please select a spot type";
          }
          if (
            !spot["recording_path"] &&
            spot["contract_status"] !== "Production Needed"
          ) {
            isValid = false;
            error.recording_path = "Please upload the recording";
          }

          if (
            !spot["role_producer"] &&
            spot["contract_status"] === "Production Provided - Needs tags"
          ) {
            isValid = false;
            error.role_producer = "Please select a producer";
          }

          if (
            !get(spot, "role_dubber.users.length", 0) &&
            ([
              "Production Provided - Needs tags",
              "Production Complete",
            ].includes(spot["contract_status"]) ||
              convertedSpecToSold)
          ) {
            isValid = false;
            error.role_dubber = "Please select a dubber";
          }

          if (spot["spot_type"] === "Book-ends" && !spot["recording_path_b"]) {
            isValid = false;
            error.recording_path_b = "Please upload the recording";
          }

          if (
            get(user, "enable_wo_traffic", false) &&
            !spot["material_group_name"]
          ) {
            isValid = false;
            error.material_group_name = "You must select a material group";
          }

          if (showLineNumber && isEmpty(spot["line_hashes"])) {
            isValid = false;
            error.line_hashes = "Please select a line #";
          }
          if (
            spot.template_key === "production_provided" &&
            Object.keys(error).length === 0
          ) {
            validProductionProvidedSpots.push(spot);
          }
          if (
            spot.template_key === "basic_production" &&
            Object.keys(error).length === 0
          ) {
            validProductionNeededSpots.push(spot);
          }
          if (
            spot.template_key === "production_provided" &&
            !isDirtyProductionProvided
          ) {
            isDirtyProductionProvided = !!get(spot, "cart_number", "");
          }
          return { ...error, template_key: spot.template_key };
        });
        if (isValid) return true;
        //  the concept here is to treat each spot independently in the continuity page, so you can move spots forward independently of the other spots being ready.
        // ALL Prod Provided spots need to be validated at the same time
        if (
          isDirtyProductionProvided &&
          validProductionProvidedSpots.length !== totalProductionProvidedSpots
        ) {
          return spotInfo.map((error) => {
            // don't need to show validation error for production needed
            if (
              error.template_key === "basic_production" &&
              Object.keys(error).length > 0
            ) {
              return {};
            }
            const { template_key, ...rest } = Object.assign({}, error);
            return rest;
          });
        }
        if (
          totalProductionProvidedSpots > 0 &&
          validProductionProvidedSpots.length === totalProductionProvidedSpots
        ) {
          return true;
        }
        if (validProductionNeededSpots.length > 0) return true;
        const spotErrors = spotInfo.map((error) => {
          const { template_key, ...rest } = Object.assign({}, error);
          return rest;
        });
        return spotErrors;
      },
      mandatory: true,
    },
    {
      field: "continuity_cart",
    },
    {
      field: "production_asset_audit",
    },
    {
      field: "production_asset_b_audit",
    },
    {
      field: "markets",
    },
    {
      text: <FormattedMessage id="process > button submit" />,
      key: "save_button",
      component: "button",
      props: { color: "blue" },
      container: "bottom_buttons",
      fields: [
        {
          key: "continuity_status",
          // "value": "submitted"
        },
        {
          key: "process_step",
          value: function(user, template, process, step, item) {
            let processStep = "first_undone";
            const currentProcessStep = get(
              process,
              "data.fields.process_step.value",
              ""
            );
            const spotInfo = get(
              process,
              "data.fields.spot_info.value",
              []
            ).find((spot) => spot.key === process.key);
            const writerSendsScriptToClient = get(
              spotInfo,
              "writer_sends_script_to_client",
              false
            );
            const producerSendsSpotToClient = get(
              spotInfo,
              "producer_sends_spot_to_client",
              false
            );
            // Writer sent to client review. But the client still not review. Should keep current process step
            if (
              writerSendsScriptToClient &&
              currentProcessStep === "approve_script"
            ) {
              return currentProcessStep;
            }

            // Producer sent to client review. But the client still not review. Should keep current process step
            if (
              producerSendsSpotToClient &&
              currentProcessStep === "approve_recording"
            ) {
              return currentProcessStep;
            }
            return processStep;
          },
        },
        {
          key: "next_users",
          value: (user, template, process, step, item, next_users) => {
            return next_users;
          },
        },
        {
          key: "spot_info",
          value: function(user, template, process, step, item) {
            // marked continuity_status to each spot info
            let spotInfo = map(
              get(process, "data.fields.spot_info.value", []),
              (spot) => ({
                ...spot,
                continuity_status: "completed",
              })
            );
            return spotInfo;
          },
        },
        {
          key: "continuity_cart",
          value: function(user, template, process, step, item) {
            // map continuity cart
            let continuityCart = map(
              get(process, "data.fields.spot_info.value", []),
              (spot) => spot.cart_number
            );
            continuityCart = uniq(continuityCart).join(",");
            return continuityCart;
          },
        },
        {
          key: "markets",
          value: function(user, template, process, step, item) {
            // marked continuity_completed_at to market
            const markets = map(
              get(process, "data.fields.markets.value", []),
              (market) => {
                const isContinuityAssigned = find(
                  get(market, "role_continuity.users", []),
                  (item) => Number(item.id) === Number(user.id)
                );
                if (isContinuityAssigned) {
                  return {
                    ...market,
                    continuity_completed_at: moment().unix(),
                  };
                }
                return market;
              }
            );
            return markets;
          },
        },
      ],
      class: null,
      on_click: {
        validate: true,
        save: true,
        go_to: function(_this, step, item, process, template, user, extraData) {
          const return_to_current_tasks = checkUserRedirectToCurrentTasks({
            ...user,
            roles_string: extraData.roles_string,
          });
          if (!return_to_current_tasks) return;
          // set delay when after success message
          delay(() => {
            if (_this.props.setSidebarActive) {
              _this.props.setSidebarActive(`/processes/my_action_items`);
            }
            history.replace(`/processes/my_action_items`);
          }, 4000);
        },
      },
      success_dialog_props: function(user, template, process, step, item) {
        return {
          title: <FormattedMessage id="process > title submitted" />,
          state: "success",
        };
      },
    },
    // {
    //   text: <FormattedMessage id="process > button delete" />,
    //   component: "button",
    //   props: { color: "blue", outline: true },
    //   container: "bottom_buttons",
    //   class: null,
    //   privileges: { or: ["delete_production", "edit_all_production_order"] },
    //   confirmation_dialog_props: {
    //     title: <FormattedMessage id="process > confirm delete this spot" />,
    //     confirmTitle: <FormattedMessage id="process > button yes" />,
    //     cancelTitle: <FormattedMessage id="process > button no" />,
    //     onConfirm: (_this, step, item, process, template, user) => {
    //       _this.setState({ confirmationDialogProps: false });
    //       _this.props.deleteProcess(
    //         get(process, "data.fields.key.value"),
    //         () => {
    //           const currentTasks = URL.PROCESSES({
    //             filter: "my_action_items",
    //           });
    //           const nextRoute = _this.props.sidebarActive
    //             ? _this.props.sidebarActive
    //             : currentTasks;
    //           history.push(nextRoute);
    //         }
    //       );
    //     },
    //   },
    //   on_click: {},
    // },
  ],
};
export default continuity;
