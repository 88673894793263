import { injectIntl } from "react-intl";
import View from "./view";
import { get } from "lodash";
import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";
import { getTeamMembersForAssignment } from "store/actions/hubs";

const Enhanced = compose(
  connect(
    (state) => ({
      info: state.auth.info,
      members: get(state, "hubs.membersAssignment.data", []),
      isLoading: get(state, "hubs.membersAssignment.loading", false),
    }),
    { getTeamMembersForAssignment }
  ),
  withHandlers({
    onGetTeamMembers: ({ getTeamMembersForAssignment }) => (stations) => {
      getTeamMembersForAssignment({
        sorted: [{ id: "name", desc: false }],
        stations,
      });
    },
  })
)(View);

export default injectIntl(Enhanced);
