import React from "react";
import classNames from "classnames";
import bn from "utils/bemnames";
import Tooltip from "rc-tooltip";
import { get, includes } from "lodash";
import TooltipHourRange from "../TooltipHourRange";
const bem = bn.create("calendar-time");
export default function TooltipWeekend(props) {
  const {
    clockWeekendData,
    index,
    tooltipTitle,
    total,
    clockWeekendDataHourIndex,
    hour,
  } = props;
  return (
    <Tooltip
      trigger={["hover"]}
      placement="top"
      overlay={
        <TooltipHourRange
          startTime={get(clockWeekendData, "start_time", 0)}
          endTime={get(clockWeekendData, "end_time", 0)}
          title={tooltipTitle}
        />
      }
      destroyTooltipOnHide
      overlayClassName={"calendar-tooltip hour-range-tooltip"}
    >
      <div
        className={classNames(bem.e("hour-range-tooltip"), {
          [bem.e("hour-range-tooltip-boundary")]: total - 1 === index,

          [bem.e("hour-range-tooltip-middle")]:
            clockWeekendDataHourIndex > 0 &&
            clockWeekendDataHourIndex <
              get(clockWeekendData, "hours.length", 0) - 1 &&
            total - 1 !== index,

          [bem.e("hour-range-tooltip-first")]: clockWeekendDataHourIndex === 0,
          [bem.e("hour-range-tooltip-first-inside-hours-range")]:
            clockWeekendDataHourIndex === 0 &&
            includes(get(clockWeekendData, "hours", []), hour),
          [bem.e("hour-range-tooltip-last")]:
            clockWeekendDataHourIndex ===
            get(clockWeekendData, "hours.length", 0) - 1,
          [bem.e("hour-range-tooltip-last-inside-hours-rang")]:
            clockWeekendDataHourIndex ===
              get(clockWeekendData, "hours.length", 0) - 1 &&
            includes(get(clockWeekendData, "hours", []), hour),
          [bem.e("hour-range-tooltip-first-left-hours-range")]: index === 0,
        })}
        style={{
          backgroundColor: get(clockWeekendData, "range_color"),
        }}
      />
    </Tooltip>
  );
}
