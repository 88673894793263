import { connect } from "react-redux";
import View from "./view";
import { copyOrder, setActiveEditTab } from "store/actions/processes";
import { injectIntl } from "react-intl";
import { compose, withHandlers, withState } from "recompose";
import get from "lodash/get";
import ToastManager from "components/ToastManager";
import history from "components/History";
import URL from "utils/urls";
import { MdImportantDevices } from "react-icons/lib/md";
import React from "react";

const Enhanced = compose(
  connect(
    (state) => {
      return {
        loading: get(state, "processes.copyOrder.loading", false),
      };
    },
    {
      copyOrder,
      setActiveEditTab,
    }
  ),
  withState("values", "setValues", []),
  withState("currentSlide", "setCurrentSlide", 0),
  withHandlers({
    onSubmit: ({ copyOrder, values, intl, onToggle, setActiveEditTab }) => (
      processId
    ) => {
      const params = {};
      var spotIndex = 0;
      values
        //.filter((spot) => spot.selected)
        .forEach((spot) => {
          spotIndex++;
          if(spot.selected){
            const spotIdentifyer = spot.uuid ? spot.uuid : 'spot_' + spotIndex;
            params[spotIdentifyer] = {
              template: spot.template_key,
              copy_fields: get(spot, `fields.${spot.template_key}`, []),
            };
          }
        });
      copyOrder(
        {
          id: processId,
          copy_instructions: params,
        },
        (response) => {
          ToastManager.show({
            title: <MdImportantDevices />,
            message: intl.formatMessage({
              id: "process > copy order successfully",
            }),
            level: "success",
          });
          if (response) {
            setActiveEditTab("order_details");
            history.push(
              URL.UPDATE_PROCESS({
                template_id: response.template_key,
                process_id: response.id,
              })
            );
          }
          onToggle();
        }
      );
    },
  })
)(View);

export default injectIntl(Enhanced);
