export const FETCH_CLOCKS_REQUEST = "FETCH_CLOCKS_REQUEST";
export const FETCH_CLOCKS_SUCCESS = "FETCH_CLOCKS_SUCCESS";
export const FETCH_CLOCKS_ERROR = "FETCH_CLOCKS_ERROR";

export const FETCH_MORE_CLOCKS_REQUEST = "FETCH_MORE_CLOCKS_REQUEST";
export const FETCH_MORE_CLOCKS_SUCCESS = "FETCH_MORE_CLOCKS_SUCCESS";
export const FETCH_MORE_CLOCKS_ERROR = "FETCH_MORE_CLOCKS_ERROR";

export const FETCH_CLOCK_REQUEST = "FETCH_CLOCK_REQUEST";
export const FETCH_CLOCK_SUCCESS = "FETCH_CLOCK_SUCCESS";
export const FETCH_CLOCK_ERROR = "FETCH_CLOCK_ERROR";

export const CREATE_CLOCK_REQUEST = "CREATE_CLOCK_REQUEST";
export const CREATE_CLOCK_SUCCESS = "CREATE_CLOCK_SUCCESS";
export const CREATE_CLOCK_ERROR = "CREATE_CLOCK_ERROR";

export const UPDATE_CLOCK_REQUEST = "UPDATE_CLOCK_REQUEST";
export const UPDATE_CLOCK_SUCCESS = "UPDATE_CLOCK_SUCCESS";
export const UPDATE_CLOCK_ERROR = "UPDATE_CLOCK_ERROR";

export const DELETE_CLOCK_REQUEST = "DELETE_CLOCK_REQUEST";
export const DELETE_CLOCK_SUCCESS = "DELETE_CLOCK_SUCCESS";
export const DELETE_CLOCK_ERROR = "DELETE_CLOCK_ERROR";

export const DELETE_CLOCK_SLOT_CATEGORY_REQUEST =
  "DELETE_CLOCK_SLOT_CATEGORY_REQUEST";
export const DELETE_CLOCK_SLOT_CATEGORY_SUCCESS =
  "DELETE_CLOCK_SLOT_CATEGORY_SUCCESS";
export const DELETE_CLOCK_SLOT_CATEGORY_ERROR =
  "DELETE_CLOCK_SLOT_CATEGORY_ERROR";
