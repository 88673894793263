import View from "./view";
import { xorBy, get, filter, pick, findIndex } from "lodash";
import { connect } from "react-redux";
import { compose, withHandlers, withState } from "recompose";
import { injectIntl } from "react-intl";
import { PRIVILEGES } from "utils/constants";
import { validatePrivileges } from "utils/helpers";
export default injectIntl(
	compose(
		connect(({ auth }) => ({ auth }), {}),
		withState("isMenuOpen", "setIsMenuOpen", false),
		withHandlers({
			autoSetPrimary: () => (selected) => {
				// auto set first option primary
				if (
					selected.length &&
					findIndex(selected, (value) => value.primary) === -1
				) {
					selected[0] = {
						...selected[0],
						primary: true,
					};
				}
				return selected;
			},
		}),
		withHandlers({
			onToggleCheckbox: ({ value, onChange, autoSetPrimary }) => (
				option
			) => {
				let newValue = xorBy(value, [option.data], "value");
				newValue = autoSetPrimary(newValue);
				onChange(newValue);
			},
			onSetPrimary: ({ value, onChange }) => (option) => {
				const newValue = value.map((item) => {
					if (item.value === option.data.value) {
						return {
							...item,
							primary: true,
						};
					}
					return {
						...item,
						primary: false,
					};
				});
				onChange(newValue);
			},
			getChannelManagers: ({ auth, filterStation }) => () => {
				const users = get(auth, "info.users");
				const managers = filter(users, (user) => {
					const { stations } = user;
					const stationsWithEditChannels = filter(
						stations,
						(station) => {
							const userPrivileges = Object.values(
								station.user_privileges
							);
							const isValid = validatePrivileges({
								requires: {
									or: [
										PRIVILEGES.PERFORM_DIGITAL_TRAFFIC,
										PRIVILEGES.EDIT_ALL_DIGITAL_ORDERS
									],
								},
								privileges: userPrivileges,
								user
							});
							return isValid && get(filterStation, 'value') === station.station_key;
						}
					);
					return stationsWithEditChannels.length > 0;
				});
				return managers.map((user) => ({
					label: user.name,
					value: user.id,
					primary: false,
					data: pick(user, ["id", "name", "avatar_url"]),
				}));
			},
		})
	)(View)
);
