import { connect } from "react-redux";
import ProcessesList from "./view";
import { compose, lifecycle } from "recompose";
import { get, find } from "lodash";
import { validatePrivileges } from "utils/helpers";
import { PRIVILEGES } from "utils/constants";
import history from "components/History";

const Enhanced = compose(
  connect((state, props) => {
    return {
      ...props,
      auth: state.auth,
      assignmentProcessLoading: get(
        state,
        "processes.assignmentProcess.loading",
        false
      ),
    };
  }, {}),
  lifecycle({
    componentDidMount() {
      const { auth } = this.props;
      const { stations } = get(auth, "info", {});
      const privileges = get(auth, "user.privileges", []) || [];
      const user = get(auth, "user");
      const isHasStationEnableAssignmentsQueue = !!find(stations, (station) =>
        get(station, "production_settings.assignments_queue", false)
      );
      const isShowContinuityAssignments = validatePrivileges({
        requires: {
          or: [
            PRIVILEGES.ASSIGN_PRODUCTION_TEAM,
            PRIVILEGES.EDIT_ALL_PRODUCTION_ORDERS,
          ],
        },
        privileges,
        user,
      });
      if (!isShowContinuityAssignments || !isHasStationEnableAssignmentsQueue)
        history.push("/");
    },
  })
)(ProcessesList);
export default Enhanced;
