import React from "react";
import Page from "components/Page";
import AddUpdateProcess from "components/AddUpdateProcess";
import useLeaves from "utils/useLeaves";
const AddProcessPage = (props) => {
  useLeaves(props.onUnlockProcess);
  return (
    <Page title="" applyPadding={false}>
      <AddUpdateProcess {...props} />
    </Page>
  );
};

export default AddProcessPage;
