import * as React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { get, map } from "lodash";
import bn from "utils/bemnames";
import { FormattedMessage } from "react-intl";
const bem = bn.create("popover-event-detail");
class PopoverEventDetail extends React.Component {
  render() {
    const { event, active, localizer } = this.props;
    const time = `${localizer.format(
      get(event, "start"),
      "HH:ss A"
    )} ${localizer.format(get(event, "end"), "HH:ss A")}`;
    return (
      <div
        className={cn(bem.b(), "ps-rbc-event-inside", {
          [bem.e("active")]: active === get(event, "id")
        })}
      >
        <h4 className={bem.e("title ps-rbc-event-inside")}>
          Killers @ The Aragon
        </h4>
        <span className={bem.e("time ps-rbc-event-inside")}>{time}</span>
        <span className={bem.e("address ps-rbc-event-inside")}>
          {get(event, "address")}
        </span>
        <div className={bem.e("users ps-rbc-event-inside")}>
          {get(event, "leads.length") ? (
            <div className={bem.e("role ps-rbc-event-inside")}>
              <span className={bem.e("role-title")}>
                <FormattedMessage id="calendar > role lead" />
              </span>
              {map(event.leads, (item, index) => (
                <img
                  key={index}
                  src={item.avatar}
                  alt={item.name}
                  className={bem.e("user-avatar")}
                />
              ))}
            </div>
          ) : null}
          {get(event, "leads.length") ? (
            <div className={bem.e("role ps-rbc-event-inside")}>
              <span className={bem.e("role-title")}>
                <FormattedMessage id="calendar > role team" />
              </span>
              {map(event.teams, (item, index) => (
                <img
                  key={index}
                  src={item.avatar}
                  alt={item.name}
                  className={bem.e("user-avatar")}
                />
              ))}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
PopoverEventDetail.propTypes = {
  localizer: PropTypes.any.isRequired,
  event: PropTypes.any.isRequired,
  active: PropTypes.any
};
export default PopoverEventDetail;
