import React, { useEffect } from "react";
import { get } from "lodash";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import Dropdown from "components/Dropdown";

import ToggleSwitch from "components/ToggleSwitch";
import SearchInput from "components/SearchInput";
import UserRows from "./UserRows";
const HEADER_HEIGHT = 179;
const FOOTER_HEIGHT = 95;
const TAB_BAR_HEIGHT = 47;
const AssignTeam = (props) => {
  const {
    intl,
    candidates,
    bem,
    values,
    isAudition,
    toggleUserRole,
    roleClicked,
    rolesOptionFilter,
    setSearchText,
    searchText,
    isShowAudition,
    setIsAudition,
    selectedRole,
    setContentHeight,
    contentHeight,
  } = props;
  console.log("rolesOptionFilter", rolesOptionFilter);
  const totalCandidates = get(candidates, "length", 0);
  useEffect(() => {
    // set dynamic height
    const sidebar = document.getElementById("right-sidebar-hub-members");
    if (sidebar) {
      setContentHeight(
        sidebar.offsetHeight - HEADER_HEIGHT - FOOTER_HEIGHT - TAB_BAR_HEIGHT
      );
    }
  }, [totalCandidates]);
  return (
    <div
      className={classnames(
        bem.e("user-rows"),
        "scroll-bar-style",
        bem.e("hub-assign-members-scroll")
      )}
      style={{
        height: contentHeight,
      }}
    >
      <div className={bem.e("filter-row")}>
        <div className={bem.e("filter-row-left")}>
          <div className={bem.e("team-member-dropdown")}>
            <span className={bem.e("filter-dropdown-label")}>
              <FormattedMessage id="select team > filter by role" />
            </span>
            <Dropdown
              placeholder={props.intl.formatMessage({
                id: "select team > show all",
              })}
              name="role"
              isMulti={false}
              allowSelectAll={false}
              onChange={(selectedOption) => {
                roleClicked(selectedOption.value);
              }}
              value={rolesOptionFilter.find(
                (item) => get(item, "value") === get(selectedRole, "id")
              )}
              options={rolesOptionFilter}
              closeMenuOnSelect={true}
            />
          </div>
          <div className={bem.e("members-search")}>
            <SearchInput
              placeholder={intl.formatMessage({
                id: "process > search for a team member",
              })}
              onClear={() => {
                setSearchText("");
              }}
              onChange={(value) => {
                setSearchText(value);
              }}
              value={searchText}
            />
          </div>
        </div>
        {isShowAudition && (
          <ToggleSwitch
            leftComponent={<FormattedMessage id="select team > audition" />}
            rightComponent={null}
            name="is_hidden"
            containerClassName={bem.e("filter-audition")}
            switchProps={{
              checked: !!isAudition,
              onChange: (checked) => {
                setIsAudition(checked);
              },
              offColor: "#C2D4E0",
              onColor: "#795AFA",
              uncheckedIcon: false,
              checkedIcon: false,
              boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.282)",
              height: 26.44,
              width: 49.76,
              handleDiameter: 22.92,
            }}
          />
        )}
      </div>
      <UserRows
        candidates={candidates}
        intl={intl}
        isAudition={isAudition}
        values={values}
        toggleUserRole={toggleUserRole}
      />
    </div>
  );
};

export default AssignTeam;
