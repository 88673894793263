import React from "react";
import { Redirect } from "react-router";
import ClientManagerList from "components/ClientManagerList";
import Page from "components/Page";
import PrivilegedComponent from "components/PrivilegedComponent";
import { PRIVILEGES } from "utils/constants";

const ClientManagerPage = (props) => {
  return (
    <PrivilegedComponent requires={{ or: [PRIVILEGES.MANAGE_CLIENTS, PRIVILEGES.EDIT_CLIENT, PRIVILEGES.ADD_CLIENT, PRIVILEGES.REASSIGN_CLIENTS] }}>
      {({ isValid }) => {
        if (isValid) {
          return (
            <Page title="" applyPadding={false}>
              <ClientManagerList {...props} />
            </Page>
          );
        } else {
          return <Redirect to="/" />;
        }
      }}
    </PrivilegedComponent>
  );
};

export default ClientManagerPage;
