import { connect } from "react-redux";
import View from "./view";
import {
  getClocksData,
  setClocksData,
  getOrdersList,
  setOrdersList,
} from "store/actions/programmings";
import { injectIntl } from "react-intl";
import { get, filter, map, find } from "lodash";
import { compose, lifecycle, withHandlers, withState } from "recompose";
const Enhanced = compose(
  connect(
    (state) => ({
      auth: state.auth,
    }),
    { getClocksData, setClocksData, getOrdersList, setOrdersList }
  ),
  withState("showItemAvailability", "setShowItemAvailability", false),
  withState("openDetails", "setOpenDetails", {}),
  withHandlers({
    onGetClocksData: ({ getClocksData }) => (stationKey) => {
      getClocksData({
        data: {
          station_id: stationKey,
        },
      });
    },
    onGetOrdersList: ({
      getOrdersList,
      process,
      valueChanged,
      user,
      step,
      setOrdersList,
    }) => (params = {}) => {
      const fillItemRotationField = step.fields.find(
        (f) => f.field === "fill_item_rotation"
      );
      const selectedStationKey = get(
        process,
        "data.fields.order_stations_array.value.0.key"
      );
      const startDate = get(process, "data.fields.order_start_date.value");
      const endDate = get(process, "data.fields.order_end_date.value");
      const typeId = get(process, "data.fields.type_id.value");
      const template = get(process, "data.fields.template_key.value");
      const data = {
        station_id: selectedStationKey,
        start_date: startDate,
        end_date: endDate,
        type_id: typeId,
        template_type: template,
        ...params,
      };
      if (
        !data.station_id ||
        !data.start_date ||
        !data.type_id ||
        !data.template_type
      ) {
        setOrdersList([]);
      } else {
        const fillItemRotationValue = get(
          process,
          "data.fields.fill_item_rotation.value"
        );
        getOrdersList({
          data,
          cb: ({ data }) => {
            const newFillItemRotationValue = map(data, (item) => {
              const dataItem = find(
                fillItemRotationValue,
                (i) => i.order_key === item.id
              );
              return {
                order_key: item.id,
                order_title: item.order_title,
                percentage: dataItem ? dataItem.percentage : 0,
              };
            });
            valueChanged(fillItemRotationField, process, template, user, {
              value: newFillItemRotationValue,
            });
          },
        });
      }
    },
    onCheckSaveDraft: ({ buttonClicked, step, template, user }) => (
      process,
      cb
    ) => {
      const isNew = window.location.href.indexOf("add") !== -1;
      // re-check for automatic save as draft when details filled
      const fields = [
        "order_title",
        "days_of_week",
        "order_end_date",
        "order_start_date",
        "type_id",
      ];
      const isInValidFieldValue =
        filter(fields, (field) => {
          if (field === "days_of_week") {
            return get(process, `data.fields.${field}.value.length`, 0) === 0;
          }
          return !get(process, `data.fields.${field}.value`);
        }).length > 0;
      const isCanSaveDraft = !isInValidFieldValue && isNew;
      if (isCanSaveDraft) {
        buttonClicked(
          step,
          {
            ...step.fields.find((f) => f.key === "save_button"),
            draft: true,
          },
          process,
          template,
          user,
          null,
          (response) => {
            if (cb) cb(response);
          },
          true
        );
      }
      return isCanSaveDraft;
    },
  }),
  lifecycle({
    componentDidMount() {
      const selectedStationKey = get(
        this.props,
        "process.data.fields.order_stations_array.value.0.key"
      );
      // const schedulingType = get(
      //   this.props,
      //   "process.data.fields.scheduling_type.value"
      // );
      if (selectedStationKey) {
        this.props.onGetClocksData(selectedStationKey);
      } else {
        this.props.setClocksData({});
      }
      // Commented for https://tasks.getventive.com/projects/07425-153
      // if (schedulingType === "fill") {
      //   this.props.onGetOrdersList();
      // } else {
      //   this.props.setOrdersList();
      // }
    },
  })
)(View);

export default injectIntl(Enhanced);
