import React from "react";
import Textarea from "react-textarea-autosize";
import bn from "utils/bemnames";
import { Label } from "reactstrap";
import PropTypes from "prop-types";
const bem = bn.create("textarea");

const TextareaAutoSize = (props) => {
  const { label, error, ...rest } = props;
  return (
    <div className={bem.b()}>
      {label ? <Label>{label}</Label> : null}
      <Textarea {...rest} />
      {!!error && <span className="text-danger">{error}</span>}
    </div>
  );
};
TextareaAutoSize.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  name: PropTypes.string,
};
export default TextareaAutoSize;
