import React, { useState } from "react";
import Autocomplete from "react-autocomplete";
import { Label } from "reactstrap";
import { Asterisk } from "components/Elements";
import bn from "utils/bemnames";
import classNames from "classnames";
import { get } from "lodash";
import { CaretIcon } from "components/CustomIcons";
const bem = bn.create("auto-complete-input");
export default function View(props) {
  const [isDropdownOpen, setIsDropdownIsOpen] = useState(false);
  const { error, label, isRequired, isShowDropdown, ...rest } = props;
  const inputRef = React.useRef(null);

  const isOpen = props.isOpen || isDropdownOpen;
  const setIsOpen = props.setIsOpen || setIsDropdownIsOpen;

  return (
    <div className={bem.b()}>
      {label && (
        <Label>
          {label}
          {isRequired && <Asterisk>*</Asterisk>}
        </Label>
      )}

      <div
        className={classNames("auto-complete", {
          "auto-complete-open": isOpen && get(rest, "items.length", 0) > 0,
          "auto-complete-error": !!error,
        })}
      >
        <div className="auto-complete-wrapper">
          <Autocomplete
            {...rest}
            open={isOpen}
            onMenuVisibilityChange={setIsOpen}
            ref={inputRef}
          />
          {isShowDropdown && (
            <span
              className="dropdown-icon"
              onClick={() => inputRef.current.focus()}
            >
              <CaretIcon className="disable-unsaved" />
            </span>
          )}
        </div>
        {error && <div className="alert-danger">{error}</div>}
      </div>
    </div>
  );
}
