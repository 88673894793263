import { injectIntl } from "react-intl";
import { get, uniq } from "lodash";
import { compose, withState, withHandlers } from "recompose";
import { connect } from "react-redux";
import ContentAssignUsers from "./view";
import { updateHubAssignedUsers } from "store/actions/hubs";

export default injectIntl(
  compose(
    connect(
      (state, props) => {
        return {
          ...props,
          info: state.auth.info,
          hubUsers: get(state, "hubs.hubUsers.data", []),
          assignedUsers: get(state, "hubs.assignedUsers.data.members", []),
          assignmentsCount: get(
            state,
            "hubs.assignedUsers.data.assignments_count",
            {}
          ),
        };
      },
      { updateHubAssignedUsers }
    ),
    withState("activeTab", "setActiveTab", "assign"),
    withState("contentHeight", "setContentHeight", 0),
    withState("searchText", "setSearchText", ""),
    withState("selectedRole", "setSelectedRole", null),
    withState("isAudition", "setIsAudition", ""),
    withState("values", "setValues", {}),
    withState("isOpenSuccessModal", "setIsOpenSuccessModal", false),
    withState("isLoading", "setIsLoading", false),
    withHandlers({
      onSubmit: ({
        updateHubAssignedUsers,
        selectedHub,
        setIsOpenSuccessModal,
        onCancel,
        assignedUsers,
      }) => (values = {}) => {
        const users = [];
        for (const [key, value] of Object.entries(values)) {
          const userIds = value.map(Number);
          const roleId = Number(key);
          const userData = userIds.map((id) => ({
            id,
            roles: [roleId],
            privileges: [],
          }));
          userData.forEach((item) => {
            const existIndex = users.findIndex((u) => u.id === item.id);
            if (existIndex !== -1) {
              users.splice(existIndex, 1, {
                ...users[existIndex],
                roles: uniq([...users[existIndex].roles, ...item.roles]),
              });
            } else {
              users.push(item);
            }
          });
        }
        const unassignedUsers = assignedUsers
          .filter((user) => !users.find((i) => i.id === user.id))
          .map((user) => ({
            id: user.id,
            roles: [],
            privileges: [],
          }));

        updateHubAssignedUsers(
          {
            id: selectedHub.id,
            users: [...users, ...unassignedUsers],
          },
          () => {
            setIsOpenSuccessModal(true);
            setTimeout(() => onCancel(), 2000);
          }
        );
      },
    })
  )(ContentAssignUsers)
);
