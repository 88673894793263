import React, { useEffect, useRef, useState } from "react";
import bn from "utils/bemnames";
import { find, findIndex, first, get, map, take } from "lodash";
import Spinner from "components/Spinner";
import FeedbackSuccessModal from "components/FeedbackSuccessModal";
import ConfirmationModal from "components/ConfirmationModal";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import ReviewLayout from "../ReviewLayout";
import OrderInfo from "../OrderInfo";
import moment from "moment";
import { ArrowLeftIcon, ArrowRightIcon } from "components/CustomIcons";
import { Button } from "reactstrap";
import Slider from "react-slick";
import { ApproveIcon, TrashIcon } from "components/CustomIcons";
import ScriptFileItem from "./ScriptFileItem";
import ScriptItem from "./ScriptItem";
import { firstUndoneStep } from "utils/helpers";
import useWindowDimensions from "utils/useWindowDimensions";

const bem = bn.create("client-review");

const NextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowRightIcon color="#829FB1" />
    </div>
  );
};

const PrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowLeftIcon color="#829FB1" />
    </div>
  );
};

const SliderButtons = (props) => {
  const {
    reviewStatus,
    isDisabled,
    setOpenFeedback,
    index,
    onChangeFeedback,
    item,
    onSubmitApproved,
    totalReviewItems,
  } = props;
  return (
    <div className={bem.e("slider-review-buttons")}>
      <Button
        color=""
        className={classnames(bem.e("button-approve"), bem.e("button-review"), {
          [bem.e("button-approved")]:
            reviewStatus && reviewStatus.review_status === "approved",
        })}
        disabled={isDisabled}
        onClick={() => {
          if (isDisabled) return;
          setOpenFeedback((prev) => ({
            ...prev,
            [index]: false,
          }));
          if (totalReviewItems === 1 && onSubmitApproved) {
            onSubmitApproved(item.key, "review_status", "approved");
          } else {
            onChangeFeedback(item.key, "review_status", "approved");
          }
        }}
      >
        <span className={bem.e("button-icon")}>
          <ApproveIcon color="#fff" />
        </span>
        <FormattedMessage id="process > button approve" />
      </Button>
      <Button
        color=""
        className={classnames(bem.e("button-reject"), bem.e("button-review"), {
          [bem.e("button-rejected")]:
            reviewStatus && reviewStatus.review_status === "rejected",
        })}
        disabled={isDisabled}
        onClick={() => {
          if (isDisabled) return;
          setOpenFeedback((prev) => ({
            ...prev,
            [index]: true,
          }));
          onChangeFeedback(item.key, "review_status", "rejected");
        }}
      >
        <span className={bem.e("button-icon")}>
          <TrashIcon />
        </span>
        <FormattedMessage id="process > button request change" />
      </Button>
    </div>
  );
};

const ReviewSummary = (props) => {
  const { totalReviewedItems, reviewItems } = props;
  return (
    <div className={bem.e("assets-approvals")}>
      <span className={bem.e("assets-approvals-title")}>
        <FormattedMessage id="process > script reviewed" />:
      </span>
      <span className={bem.e("assets-approvals-value")}>
        {totalReviewedItems}/{reviewItems.length}
      </span>
    </div>
  );
};
const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipe: false,
  adaptiveHeight: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};
function getReviewItems(process) {
  const spot = find(
    get(process, "data.fields.spot_info.value", []),
    (spot) => spot.key === process.key
  );
  const script = get(spot, "script", "")
    ? get(spot, "script", "")
    : get(
        first(get(process, "data.fields.script_history.value", "")),
        "script",
        ""
      );

  const scriptB = get(spot, "script_part_b", "")
    ? get(spot, "script_part_b", "")
    : get(
        first(get(process, "data.fields.script_history.value", "")),
        "script_part_b",
        ""
      );

  const isBookend = get(spot, "spot_type", "Standard") === "Book-ends";
  const approveScriptStatus = get(
    process,
    "data.fields.approve_script_status.value",
    ""
  );
  const writerScriptStatus = get(
    process,
    "data.fields.write_script_status.value",
    ""
  );
  const isClientReviewed =
    (writerScriptStatus === "rejected" && approveScriptStatus === "rejected") ||
    (writerScriptStatus === "completed" && approveScriptStatus === "approved");
  if (isBookend) {
    return [
      {
        script,
        key: "script",
        review_status: isClientReviewed
          ? get(spot, "script_review_status", "")
          : "",
      },
      {
        script: scriptB,
        key: "script_part_b",
        review_status: isClientReviewed
          ? get(spot, "script_part_b_review_status", "")
          : "",
      },
    ].filter((item) => item.script);
  } else {
    return [
      {
        script,
        key: "script",
        review_status: isClientReviewed
          ? get(spot, "script_review_status", "")
          : "",
      },
    ].filter((item) => item.script);
  }
}

function ScriptReview(props) {
  const {
    process,
    template,
    extraReviewProcess,
    onGetReviewProcess,
    intl,
    dateFormatByServer
  } = props;
  const { width: widthScreen } = useWindowDimensions();
  const mainSlick = useRef();
  const reviewItems = getReviewItems(process);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [isFeedbackSuccessModal, setIsFeedbackSuccessModal] = useState(false);
  const [feedbackAction, setFeedbackAction] = useState(null);
  const [isViewAllStations, setIsViewAllStations] = useState(false);
  const [openFeedback, setOpenFeedback] = useState({});
  const [reviewValues, setReviewValues] = useState(
    reviewItems.map((item) => ({
      review_status: "",
      feedback: "",
      key: item.key,
    }))
  );
  const isMobile = widthScreen <= 480;
  const step = find(template.steps, (item) => item.key === "approve_script");
  const userSale = get(extraReviewProcess, "sale");
  const clientName = get(process, "data.fields.client_name.value");
  const stationsData = get(extraReviewProcess, "stations", []);
  const numberStationNameLimit = isMobile ? 2 : 3;
  const stationsDataName = !isViewAllStations
    ? take(stationsData, numberStationNameLimit).map((i) => i.name)
    : stationsData.map((i) => i.name);
  const processTitle = get(process, "data.fields.order_title.value");
  const orderStartDate = get(process, "data.fields.order_start_date.value")
    ? moment(get(process, "data.fields.order_start_date.value")).format(
        dateFormatByServer
      )
    : "";

  const orderEndDate = get(process, "data.fields.order_end_date.value")
    ? moment(get(process, "data.fields.order_end_date.value")).format(
        dateFormatByServer
      )
    : "";
  const spot = find(
    get(process, "data.fields.spot_info.value", []),
    (spot) => spot.key === process.key
  );
  const isUploadScriptFile = get(spot, "is_upload_script_file", false);
  const isBookend = get(spot, "spot_type", "Standard") === "Book-ends";
  const totalReviewedItems = reviewValues.filter((item) => item.review_status)
    .length;
  const user = {
    name: clientName,
    id: -1,
  };
  const spotIndex = findIndex(
    get(process, "data.fields.spot_info.value", []),
    (spot) => spot.key === process.key
  );
  const isShowSubmitButton =
    get(process, "data.fields.process_step.value", "") === "approve_script";
  const isDisabled =
    get(process, "data.fields.process_step.value", "") !== "approve_script";
  const isReviewedAll = totalReviewedItems === reviewItems.length;
  const isMissingFeedback = find(reviewValues, (item) => {
    if (
      item.review_status === "rejected" &&
      (item.feedback === "<p><br></p>" || !item.feedback)
    ) {
      return item;
    }
  });
  useEffect(() => {
    if (reviewItems.length !== reviewValues.length) {
      setReviewValues(
        reviewItems.map((item) => ({
          review_status: item.review_status || "",
          feedback: "",
          key: item.key,
        }))
      );
    }
    if (mainSlick.current) {
      mainSlick.current.slickGoTo(0);
    }
  }, [reviewItems.length]);
  const onChangeFeedback = (key, field, value) => {
    const data = map(reviewValues, (item) => {
      if (item.key === key) {
        return {
          ...item,
          [field]: value,
        };
      }
      return item;
    });
    setReviewValues(data);
    // move slide
    const nextIndex = data.findIndex((i) => !i.review_status);
    if (
      mainSlick.current &&
      nextIndex !== -1 &&
      field === "review_status" &&
      value === "approved"
    ) {
      mainSlick.current.slickGoTo(nextIndex);
    }
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };
  const buttonClicked = (
    step,
    item,
    process,
    template,
    user,
    event,
    callback,
    isForce = false,
    noSuccessMessage = false
  ) => {
    item.fields.map((field) => {
      if (typeof field.value === "undefined") {
        return false;
      }

      let value = field.value;

      if (typeof value === "function") {
        value = field.value(
          user,
          template,
          process,
          step,
          item,
          get(process, "data.fields.next_users.value", [])
        );
      }

      if (field.key === "process_step" && value === "first_undone") {
        value = firstUndoneStep(user, template, process, step, item);
      }

      props.formValueChanged({
        process,
        template,
        item: { field: field.key },
        user,
        value,
      });
      process.data.fields[field.key] = { value: value, is_dirty: true };
      return false;
    });

    let success_callback = () => {
      setIsFeedbackSuccessModal(true);
      onGetReviewProcess({ spotSwitching: true });
      setOpenFeedback({});
    };

    if (callback) {
      success_callback = callback;
    }
    const recomputeFields = props.getRecomputeFields(
      step,
      item,
      process,
      template,
      user
    );
    process.data.fields.all_changed_users = {
      value: get(recomputeFields, "all_changed_users", []),
      is_dirty: true,
    };
    let saveFormValues = {
      process,
      template,
      step,
      user,
      success_callback,
      valid: true,
      isForce,
    };

    saveFormValues.meta = {
      ...get(saveFormValues, "meta", {}),
      is_client_review: true,
    };

    props.saveFormValues(saveFormValues);
  };
  const valueChanged = (item, process, template, user, event) => {
    props.formValueChanged({
      process,
      template,
      item,
      user,
      value: !event
        ? ""
        : Array.isArray(event)
        ? event
        : event.value !== undefined
        ? event.value
        : event.target
        ? event.target.value
        : event,
    });
  };
  const updateFeedbackToProcessObject = () => {
    const spotInfoField = step.fields.find((f) => f.field === "spot_info");
    const scriptFeedbackField = step.fields.find(
      (f) => f.field === "script_feedback"
    );
    const scriptFeedbackPartBField = step.fields.find(
      (f) => f.field === "script_part_b_feedback"
    );

    const spotInfo = get(process, "data.fields.spot_info.value", []);
    const scriptFeedback = find(reviewValues, (item) => item.key === "script");
    const scriptPartBFeedback = find(
      reviewValues,
      (item) => item.key === "script_part_b"
    );
    let params = {
      ...spot,
      script_feedback: get(scriptFeedback, "feedback", ""),
      script_review_status: get(scriptFeedback, "review_status", ""),
    };
    if (isBookend) {
      params = {
        ...params,
        script_part_b_feedback: get(scriptPartBFeedback, "feedback", ""),
        script_part_b_review_status: get(
          scriptPartBFeedback,
          "review_status",
          ""
        ),
      };
    }
    spotInfo[spotIndex] = params;
    valueChanged(spotInfoField, process, template, user, {
      value: spotInfo,
    });
    process.data.fields[spotInfoField.field] = {
      value: spotInfo,
      is_dirty: true,
    };
    valueChanged(scriptFeedbackField, process, template, user, {
      value: get(scriptFeedback, "feedback", ""),
    });
    process.data.fields[scriptFeedbackField.field] = {
      value: get(scriptFeedback, "feedback", ""),
      is_dirty: true,
    };
    if (isBookend) {
      valueChanged(scriptFeedbackPartBField, process, template, user, {
        value: get(scriptPartBFeedback, "feedback", ""),
      });
      process.data.fields[scriptFeedbackPartBField.field] = {
        value: get(scriptPartBFeedback, "feedback", ""),
        is_dirty: true,
      };
    }
    return process;
  };
  const onApprove = () => {
    const item = step.fields.find((f) => f.field === "approve");
    const processData = updateFeedbackToProcessObject();
    buttonClicked(step, item, processData, template, user, null, null, true);
  };

  const onReject = () => {
    const item = step.fields.find((f) => f.field === "submit_feedback");
    const processData = updateFeedbackToProcessObject();
    buttonClicked(
      step,
      item,
      processData,
      template,
      user,
      null,
      null,
      true,
      false
    );
  };
  const onSubmit = () => {
    if (isDisabled) return;

    if (isMissingFeedback || !isReviewedAll) {
      setIsWarningModalOpen(true);
    } else {
      const feedbackAction = find(
        reviewValues,
        (item) => item.review_status === "rejected"
      )
        ? "reject"
        : "approve";
      setFeedbackAction(feedbackAction);
      if (feedbackAction === "approve") {
        onApprove();
      } else {
        onReject();
      }
    }
  };
  const onSubmitApproved = (key, field, value) => {
    const data = map(reviewValues, (item) => {
      if (item.key === key) {
        return {
          ...item,
          [field]: value,
        };
      }
      return item;
    });
    setReviewValues(data);
    // only submit if has only script or file to review
    if (data.length === 1) onApprove();
  };

  const renderReviewContent = () => (
    <div
      className={classnames(
        bem.e("review-content"),
        bem.e("production-review"),
        bem.e("script-production-review")
      )}
    >
      <div className={classnames(bem.e("p-review-left"), bem.e("review-left"))}>
        {!isMobile ? (
          <ReviewSummary
            totalReviewedItems={totalReviewedItems}
            reviewItems={reviewItems}
          />
        ) : null}
        <Slider
          ref={(ref) => {
            mainSlick.current = ref;
          }}
          {...sliderSettings}
          arrows={reviewItems.length > 1}
        >
          {map(reviewItems, (item, index) => {
            const reviewStatus = find(reviewValues, (i) => i.key === item.key);
            return (
              <div
                className={bem.e("slide-review")}
                key={`client-script-review-${index}`}
              >
                {!isMobile && (
                  <SliderButtons
                    reviewStatus={reviewStatus}
                    isDisabled={isDisabled}
                    setOpenFeedback={setOpenFeedback}
                    index={index}
                    isMobile={isMobile}
                    onChangeFeedback={onChangeFeedback}
                    item={item}
                    onSubmitApproved={onSubmitApproved}
                    totalReviewItems={reviewItems.length}
                  />
                )}

                {/* content slider */}
                {isUploadScriptFile ? (
                  <ScriptFileItem
                    item={item}
                    isBookend={isBookend}
                    spot={spot}
                    isDisabled={isDisabled}
                    process={process}
                    intl={intl}
                    isOpenFeedback={openFeedback[index]}
                    onChangeFeedback={onChangeFeedback}
                    isMobile={isMobile}
                    renderSummary={
                      isMobile ? (
                        <ReviewSummary
                          totalReviewedItems={totalReviewedItems}
                          reviewItems={reviewItems}
                        />
                      ) : null
                    }
                    renderButtons={
                      isMobile ? (
                        <SliderButtons
                          reviewStatus={reviewStatus}
                          isDisabled={isDisabled}
                          setOpenFeedback={setOpenFeedback}
                          index={index}
                          onChangeFeedback={onChangeFeedback}
                          item={item}
                          isMobile={isMobile}
                          onSubmitApproved={onSubmitApproved}
                          totalReviewItems={reviewItems.length}
                        />
                      ) : null
                    }
                  />
                ) : (
                  <ScriptItem
                    item={item}
                    isBookend={isBookend}
                    spot={spot}
                    isDisabled={isDisabled}
                    process={process}
                    isOpenFeedback={openFeedback[index]}
                    onChangeFeedback={onChangeFeedback}
                    isMobile={isMobile}
                    renderSummary={
                      isMobile ? (
                        <ReviewSummary
                          totalReviewedItems={totalReviewedItems}
                          reviewItems={reviewItems}
                        />
                      ) : null
                    }
                    renderButtons={
                      isMobile ? (
                        <SliderButtons
                          reviewStatus={reviewStatus}
                          isDisabled={isDisabled}
                          setOpenFeedback={setOpenFeedback}
                          index={index}
                          onChangeFeedback={onChangeFeedback}
                          item={item}
                          isMobile={isMobile}
                          onSubmitApproved={onSubmitApproved}
                          totalReviewItems={reviewItems.length}
                        />
                      ) : null
                    }
                  />
                )}
              </div>
            );
          })}
        </Slider>
        {isShowSubmitButton && totalReviewedItems > 0 ? (
          <div className={bem.e("submit-all-feedback")}>
            <Button
              color="blue"
              type="submit"
              disabled={!!(isDisabled || isMissingFeedback || !isReviewedAll)}
              className="btn btn-radius btn-submit-script-review"
              onClick={onSubmit}
            >
              <FormattedMessage id="process > button submit feedback" />
            </Button>
          </div>
        ) : null}
      </div>
      <div
        className={classnames(bem.e("review-right"), bem.e("p-review-right"))}
      >
        <OrderInfo
          isViewAllStations={isViewAllStations}
          bem={bem}
          clientName={clientName}
          processTitle={processTitle}
          stationsDataName={stationsDataName}
          stationsData={stationsData}
          setIsViewAllStations={setIsViewAllStations}
          orderStartDate={orderStartDate}
          orderEndDate={orderEndDate}
          helperText={intl.formatMessage({
            id:
              "process > please review script, and approve or request changes",
          })}
        />
        {/* review status ui */}
        <div className={bem.e("review-status-content")}>
          {map(reviewValues, (item, index) => {
            if (item.review_status === "rejected")
              return (
                <div className={bem.e("review-status-item")} key={index}>
                  <TrashIcon color="#E40061" />
                  <FormattedMessage id="process > change is requested for final script" />
                  {isBookend ? (
                    item.key === "script" ? (
                      <span className={bem.e("part-text")}>
                        (<FormattedMessage id="process > part A" />)
                      </span>
                    ) : (
                      <span className={bem.e("part-text")}>
                        (<FormattedMessage id="process > part B" />)
                      </span>
                    )
                  ) : null}
                  .
                </div>
              );
            if (item.review_status === "approved")
              return (
                <div className={bem.e("review-status-item")} key={index}>
                  <ApproveIcon color="#73A703" />
                  <FormattedMessage id="process > final script was approved" />
                  {isBookend ? (
                    item.key === "script" ? (
                      <span className={bem.e("part-text")}>
                        (<FormattedMessage id="process > part A" />)
                      </span>
                    ) : (
                      <span className={bem.e("part-text")}>
                        (<FormattedMessage id="process > part B" />)
                      </span>
                    )
                  ) : null}
                  .
                </div>
              );
            return null;
          })}
        </div>
      </div>
    </div>
  );
  return (
    <ReviewLayout
      extraReviewProcess={extraReviewProcess}
      renderReviewContent={renderReviewContent}
    >
      <Spinner isLoading={process.loading} />
      <FeedbackSuccessModal
        isOpen={isFeedbackSuccessModal}
        user={userSale}
        title={
          feedbackAction === "reject" ? (
            <FormattedMessage id="process > client reject title" />
          ) : (
            <FormattedMessage id="process > client approval title" />
          )
        }
        description={
          feedbackAction === "reject" ? (
            <FormattedMessage id="process > client reject description" />
          ) : (
            <FormattedMessage id="process > client approval description" />
          )
        }
        isAutoClose
        onToggle={() => {
          setIsWarningModalOpen(false);
          setIsFeedbackSuccessModal(false);
        }}
        onCancel={() => {
          setIsWarningModalOpen(false);
          setIsFeedbackSuccessModal(false);
        }}
      />
      <ConfirmationModal
        isOpen={isWarningModalOpen}
        centered
        isCloseOutside={false}
        onToggle={() => {
          setIsWarningModalOpen(false);
        }}
        onConfirm={() => {
          setIsWarningModalOpen(false);
        }}
        title={
          <FormattedMessage id="process > leave feedback with your change request" />
        }
        className={"client-review-confirmation"}
        confirmTitle={<FormattedMessage id="process > button ok" />}
        description={null}
        cancelTitle={null}
      />
    </ReviewLayout>
  );
}
export default ScriptReview;
