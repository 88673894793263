import React from "react";
import PrizeForm from "components/PrizeForm";
import Page from "components/Page";
import bn from "utils/bemnames";
import { get } from "lodash";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
const bem = bn.create("prize-page");

const View = (props) => {
  const { intl } = props;
  const prizeCategory = get(props, "currentPrizeData.category");

  const getPrizeType = () => {
    let sortName;
    let name;
    let className;
    if (prizeCategory === "cash_check") {
      sortName = "$";
      name = "Cash";
      className = bem.e("cash");
    } else if (prizeCategory === "tickets") {
      sortName = "TX";
      name = "Tickets";
      className = bem.e("tickets");
    } else if (prizeCategory === "prize") {
      sortName = "P";
      name = "Prize";
      className = bem.e("prize");
    } else if (prizeCategory === "station_merchandise") {
      sortName = "M";
      name = "Merch";
      className = bem.e("merch");
    }
    return (
      <div className={classnames(bem.e("type"), className)}>
        <span className={bem.e("sort-name")}>{sortName}</span>
        <span className={bem.e("name")}>{name}</span>
      </div>
    );
  };
  return (
    <Page applyPadding={false} className={bem.b()}>
      <div className={bem.e("prize-header")}>
        <div className={bem.e("page-padding")}>
          <div className={bem.e("breadcrumb")}>
            {intl.formatMessage({
              id: "breadcrumbs > prizes data",
            })}
            <strong>
              /{` `}
              {get(props, "currentPrizeData.id")
                ? intl.formatMessage({
                    id: "breadcrumbs > update a prize",
                  })
                : intl.formatMessage({
                    id: "breadcrumbs > add a prize",
                  })}
            </strong>
          </div>
          <div className={bem.e("header-content")}>
            <div className={bem.e("type")}>
              {prizeCategory && <span></span>}
              {getPrizeType()}
            </div>
            <div className={bem.e("header-title")}>
              <h2 className={bem.e("title")}>
                {get(props, "currentPrizeData.title") ? (
                  get(props, "currentPrizeData.title")
                ) : (
                  <FormattedMessage id="prizes > title" />
                )}
              </h2>
              <span className={bem.e("subtitle")}>
                {get(props, "currentPrizeData.subtitle") ? (
                  get(props, "currentPrizeData.subtitle")
                ) : (
                  <FormattedMessage id="prizes > subtitle" />
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={bem.e("page-padding")}>
        <PrizeForm
          currentPrizeData={props.currentPrizeData}
          setCurrentPrizeData={props.setCurrentPrizeData}
          prize={get(props, "prize.data")}
          isView={props.isView}
        />
      </div>
    </Page>
  );
};

export default View;
