import steps from "./steps";
const promotion = {
  _index: "templates",
  _type: "template",
  _id: "promotion",
  _score: 1,
  _source: {
    key: "promotion",
    templates_name: "Promotion",
    templates_description: "Create a promotion",
    priviliges: {},
    steps: steps,
    active: true,
  },
};
export default promotion;
