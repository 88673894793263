export const download = async (data, filename) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  let orginalFileName = getOriginalFilename(filename) || filename;
  link.setAttribute("download", orginalFileName);
  document.body.appendChild(link);
  link.click();
};

export const convertUrlHttps = (url) => url.replace(/^http:\/\//i, "https://");

export const getExt = (path) => {
  let pos = path.lastIndexOf(".");
  return path.substring(pos + 1);
};

export const getOriginalFilename = (filename) => {
  let pos = filename.lastIndexOf("||");

  if (pos !== -1) {
    return filename.substring(pos + 2);
  }

  pos = filename.lastIndexOf("___");
  if (pos !== -1) {
    return filename.substring(pos + 3);
  }

  return filename;
};
