import React from "react";
import bn from "utils/bemnames";
import WinnersList from "../Common/WinnersList";
import GrandPrizeWinnersList from "../Common/GrandPrizeWinnersList";
import classnames from "classnames";
import Spinner from "components/Spinner";
import { get, find } from "lodash";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import WinnerFulfillment from "../Common/WinnerFulfillment";
import WinnerNotification from "../Common/WinnerNotification";

const bem = bn.create("promotion-contest-winners");

export default function PromotionContestWinners(props) {
  const isView = props.mode === "view";
  const {
    process,
    template,
    step,
    activeTab,
    setActiveTab,
    intl,
    validationState,
    valueChanged,
    user,
    auth,
  } = props;
  const selectedTheme = get(process, "data.fields.theme.value", "");
  const isQualifyingGrandPrizeTheme =
    selectedTheme === "qualifying_with_grand_prize";
  const contest_image = step.fields.find((f) => f.field === "contest_image");
  const onValueChanged = (
    field,
    value,
    restProps = {
      shouldBlockNavigation: true,
    }
  ) => {
    valueChanged(field, process, template, user, { value });
    props.setShouldBlockNavigation(restProps.shouldBlockNavigation);
  };
  const selectedStationKey = get(process, "data.fields.order_stations.value.0");
  const stationData = find(
    auth.info.stations,
    (item) => item.station_key === selectedStationKey
  );
  return (
    <div
      className={classnames(bem.b(), {
        [bem.m("edit")]: !isView,
        [bem.m("view")]: isView,
      })}
    >
      <React.Fragment>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === "1",
              })}
              onClick={() => {
                setActiveTab("1");
              }}
            >
              {intl.formatMessage({
                id: "process > promotion > contest > winner notification",
              })}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === "2",
              })}
              onClick={() => {
                setActiveTab("2");
              }}
            >
              {intl.formatMessage({
                id: "process > promotion > contest > winner fulfillment",
              })}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === "3",
              })}
              onClick={() => {
                setActiveTab("3");
              }}
            >
              {intl.formatMessage({
                id: "process > promotion > contest > winners list",
              })}
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <WinnerNotification
              process={process}
              template={template}
              step={step}
              user={user}
              validationState={validationState}
              onValueChanged={onValueChanged}
              isView={isView}
              station={stationData}
              buttonClicked={props.buttonClicked}
              onUploadedContestImage={(contestImage) =>
                onValueChanged(contest_image, contestImage)
              }
            />
          </TabPane>
          <TabPane tabId="2">
            <WinnerFulfillment
              process={process}
              template={template}
              step={step}
              user={user}
              validationState={validationState}
              onValueChanged={onValueChanged}
              isView={isView}
              station={stationData}
              buttonClicked={props.buttonClicked}
            />
          </TabPane>
          <TabPane tabId="3">
            {isQualifyingGrandPrizeTheme && (
              <GrandPrizeWinnersList
                process={process}
                template={template}
                step={step}
              />
            )}
            <WinnersList process={process} template={template} />
          </TabPane>
        </TabContent>
      </React.Fragment>
      <Spinner isLoading={props.isLoading} />
    </div>
  );
}
