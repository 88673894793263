import { toUpper } from "lodash";
export const getDateFormatByCountry = (country) => {
  let date = "MM/DD/YYYY";
  if (toUpper(country) === "MX") date = "DD/MM/YYYY";
  localStorage.setItem("dateFormatByServer", date);
  return date;
};

export const getLocalStorageDateFormat = () =>
  localStorage.getItem("dateFormatByServer") || " MM/DD/YYYY";
