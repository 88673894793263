import * as React from "react";
import { Col, Row, Label } from "reactstrap";
import classnames from "classnames";
import { setScriptField, getFileNameFromUrl } from "utils/helpers";
import TextareaEvaluator, {
  DEFAULT_WORDS_PER_MIN,
} from "components/TextareaEvaluator";
import { Asterisk } from "components/Elements";
import { get, find, findIndex, first, has } from "lodash";
import { FormattedMessage } from "react-intl";
import ToggleSwitch from "components/ToggleSwitch";
import UploadFile from "components/UploadFile";
import FileView from "components/FileView";
import TextInput from "components/TextInput";
import bn from "utils/bemnames";
import TextareaAutosize from "react-textarea-autosize";

const bem = bn.create("order-script");
function RenderDraftOrFinalSwitch(props) {
  const { onValueChangedSpot, spotIndex, spot, disabled, isBookend } = props;
  const isChecked = get(spot, "is_upload_script_file", false);
  return (
    <div className={bem.e("switch-final-script")}>
      <ToggleSwitch
        leftComponent={<FormattedMessage id="process > add script" />}
        rightComponent={<FormattedMessage id="process > upload script file" />}
        switchProps={{
          onChange: (checked) => {
            onValueChangedSpot(spotIndex, {
              field: "is_upload_script_file",
              value: checked,
            });
            onValueChangedSpot(spotIndex, {
              field: "script",
              value: "",
            });
            if (isBookend) {
              onValueChangedSpot(spotIndex, {
                field: "script_part_b",
                value: "",
              });
            }
          },
          disabled,
          checked: isChecked,
          offColor: "#8165f3",
          onColor: "#8165f3",
          uncheckedIcon: false,
          checkedIcon: false,
        }}
      />
    </div>
  );
}

function RenderScriptEditor(props) {
  const {
    isBookend,
    isView,
    isEditButtonDisabled,
    spot,
    onValueChangedSpot,
    spotIndex,
    intl,
    validationState,
    process,
  } = props;
  const refreshTextEditor = get(process, "data.fields.refreshed_at.value", 0);
  return (
    <React.Fragment>
      <Row>
        <Col className={bem.e("col-field")}>
          <div
            className={classnames(
              bem.e("form-div"),
              bem.e("form-div-draft-script")
            )}
          >
            {!isBookend ? (
              <TextareaEvaluator
                readOnly={isView || isEditButtonDisabled}
                isOrderLength={true}
                showScriptLength={true}
                hideHelperText={true}
                can_edit_view_mode={true}
                text={setScriptField(get(spot, "script", ""))}
                key={refreshTextEditor}
                onEvaluatorCallback={(value) => {
                  onValueChangedSpot(spotIndex, {
                    field: "script",
                    value,
                  });
                }}
                onWordsPerMinChangeCallback={(value) => {
                  onValueChangedSpot(spotIndex, {
                    field: "default_words_per_min",
                    value,
                  });
                }}
                wordsPerMin={get(
                  spot,
                  "default_words_per_min",
                  DEFAULT_WORDS_PER_MIN
                )}
                placeholder={intl.formatMessage({
                  id: "process > enter script",
                })}
                process={process}
              />
            ) : (
              <TextareaEvaluator
                readOnly={isView || isEditButtonDisabled}
                isOrderLength={true}
                showScriptLength={true}
                hideHelperText={true}
                can_edit_view_mode={true}
                text={setScriptField(get(spot, "script", ""))}
                wordsPerMin={get(
                  spot,
                  "default_words_per_min",
                  DEFAULT_WORDS_PER_MIN
                )}
                onEvaluatorCallback={(value) => {
                  onValueChangedSpot(spotIndex, {
                    field: "script",
                    value,
                  });
                }}
                onWordsPerMinChangeCallback={(value) => {
                  onValueChangedSpot(spotIndex, {
                    field: "default_words_per_min",
                    value,
                  });
                }}
                placeholder={intl.formatMessage({
                  id: "process > enter script",
                })}
                key={refreshTextEditor}
                process={process}
                spot={spot}
                title={<FormattedMessage id="process > field script part A" />}
                isBookend={isBookend}
                part="a"
                showInnerTitle={true}
              />
            )}
            {get(validationState, "script", "") && (
              <div className="alert-danger">
                {get(validationState, "script", "")}
              </div>
            )}
          </div>
        </Col>
      </Row>
      {isBookend && (
        <Row>
          <Col className={bem.e("col-field")}>
            <div
              className={classnames(
                bem.e("form-div"),
                bem.e("form-div-draft-script")
              )}
            >
              <TextareaEvaluator
                readOnly={isView || isEditButtonDisabled}
                isOrderLength={true}
                showScriptLength={true}
                hideHelperText={true}
                can_edit_view_mode={true}
                text={setScriptField(get(spot, "script_part_b", ""))}
                key={refreshTextEditor}
                onEvaluatorCallback={(value) => {
                  onValueChangedSpot(spotIndex, {
                    field: "script_part_b",
                    value,
                  });
                }}
                onWordsPerMinChangeCallback={(value) => {
                  onValueChangedSpot(spotIndex, {
                    field: "default_words_per_min_b",
                    value,
                  });
                }}
                placeholder={intl.formatMessage({
                  id: "process > enter script",
                })}
                wordsPerMin={get(
                  spot,
                  "default_words_per_min_b",
                  DEFAULT_WORDS_PER_MIN
                )}
                process={process}
                spot={spot}
                title={<FormattedMessage id="process > field script part B" />}
                isBookend={isBookend}
                part="b"
                showInnerTitle={true}
              />
              {get(validationState, "script_part_b", "") && (
                <div className="alert-danger">
                  {get(validationState, "script_part_b", "")}
                </div>
              )}
            </div>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
}

function RenderUploadFile(props) {
  const {
    spot,
    process,
    intl,
    onValueChangedSpot,
    spotIndex,
    isBookend,
    validationState,
    isView,
  } = props;
  const fileViewActions = isView
    ? ["view", "download"]
    : ["view", "rename", "trash", "download"];
  const isScriptApproved =
    get(spot, "script_review_status", "") === "approved" && spot.script;
  const isScriptPartBApproved =
    get(spot, "script_part_b_review_status", "") === "approved" &&
    spot.script_part_b;

  return (
    <div className={bem.e("script-upload")}>
      <div className={bem.e("script-upload-item")}>
        {spot.script || isScriptApproved ? (
          <React.Fragment>
            {isBookend && (
              <div className={bem.e("part-upload-title")}>
                <FormattedMessage id="process > part A" />
              </div>
            )}
            <FileView
              file={{
                path: spot.script,
                name: getFileNameFromUrl(spot.script),
              }}
              actions={fileViewActions}
              onDeleted={() => {
                onValueChangedSpot(spotIndex, {
                  field: "script",
                  value: "",
                });
              }}
              onUploaded={(files) => {
                const file = first(files);
                onValueChangedSpot(spotIndex, {
                  field: "script",
                  value: get(file, "path", ""),
                });
              }}
            />
          </React.Fragment>
        ) : (
          <UploadFile
            mode={"edit"}
            process={process}
            onUploaded={(value) => {
              onValueChangedSpot(spotIndex, {
                field: "script",
                value: value.path,
              });
            }}
            attachText={intl.formatMessage({
              id: "process > placeholder script file",
            })}
            isDisabledDelete={true}
            onDeleted={() => {}}
            isCheckMimesByExt={true}
            extensions={["doc", "docx", "pdf"]}
            url={get(spot, "script", "")}
            actions={["download"]}
            positionActions="bottom"
            isShowFileNameInEditMode={true}
            hideSpotLength={true}
            path="a"
            isBookend={isBookend}
          >
            <FormattedMessage id="process > button upload" />
          </UploadFile>
        )}

        {get(validationState, "script", "") && (
          <div className="alert-danger">
            {get(validationState, "script", "")}
          </div>
        )}
      </div>

      {isBookend && (
        <div className={bem.e("script-upload-item")}>
          {spot.script_part_b || isScriptPartBApproved ? (
            <React.Fragment>
              {isBookend && (
                <div className={bem.e("part-upload-title")}>
                  <FormattedMessage id="process > part B" />
                </div>
              )}
              <FileView
                file={{
                  path: spot.script_part_b,
                  name: getFileNameFromUrl(spot.script_part_b),
                }}
                actions={fileViewActions}
                onDeleted={() => {
                  onValueChangedSpot(spotIndex, {
                    field: "script_part_b",
                    value: "",
                  });
                }}
              />
            </React.Fragment>
          ) : (
            <UploadFile
              mode={"edit"}
              process={process}
              onUploaded={(value) => {
                onValueChangedSpot(spotIndex, {
                  field: "script_part_b",
                  value: value.path,
                });
              }}
              attachText={intl.formatMessage({
                id: "process > placeholder script file",
              })}
              isDisabledDelete={true}
              onDeleted={() => {}}
              isCheckMimesByExt={true}
              extensions={["doc", "docx", "pdf"]}
              url={get(spot, "script_part_b", "")}
              actions={["download"]}
              positionActions="bottom"
              isShowFileNameInEditMode={true}
              hideSpotLength={true}
              path="b"
              isBookend={isBookend}
            >
              <FormattedMessage id="process > button upload" />
            </UploadFile>
          )}

          {get(validationState, "script_part_b", "") && (
            <div className="alert-danger">
              {get(validationState, "script_part_b", "")}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

const RenderAssignsVO = (props) => {
  const {
    validationState,
    setShouldBlockNavigation,
    intl,
    isView,
    spot,
    onValueChangedSpot,
    spotIndex,
  } = props;
  if (isView) {
    return (
      <Row>
        <Col className={bem.e("col-field")} xs={12}>
          <Label className={bem.e("order-view-label")}>
            <FormattedMessage id="process > field voice over instructions" />
          </Label>
          <span className={bem.e("order-view-value")}>
            {get(spot, "voice_over_instructions", "") ? (
              <TextareaAutosize
                value={get(spot, "voice_over_instructions", "")}
                className="text-area-view"
                disabled
              />
            ) : (
              <FormattedMessage id="process > none" />
            )}
          </span>
        </Col>
      </Row>
    );
  }
  return (
    <div className={bem.e("assigns-vo")}>
      <Row>
        <Col className={bem.e("col-field")} xs={12}>
          <TextInput
            label={
              <span>
                <FormattedMessage id="process > field voice over instructions" />
                <Asterisk>*</Asterisk>
              </span>
            }
            type="textarea"
            placeholder={intl.formatMessage({
              id: "process > placeholder type here",
            })}
            value={get(spot, "voice_over_instructions", "")}
            onChange={({ target }) => {
              if (setShouldBlockNavigation) setShouldBlockNavigation(true);
              onValueChangedSpot(spotIndex, {
                field: "voice_over_instructions",
                value: target.value,
              });
            }}
            error={get(validationState, "voice_over_instructions", "")}
          />
        </Col>
      </Row>
    </div>
  );
};
const OrderScript = ({
  step,
  process,
  valueChanged,
  template,
  user,
  intl,
  isView,
  setShouldBlockNavigation,
  ...rest
}) => {
  const isEditButtonDisabled = document.getElementById("edit-button-row")
    ? document.getElementById("edit-button-row").classList.contains("disabled")
    : false;
  const spot = find(
    get(process, "data.fields.spot_info.value", []),
    (spot) => spot.key === process.key
  );
  const spotIndex = findIndex(
    get(process, "data.fields.spot_info.value", []),
    (spot) => spot.key === process.key
  );

  const isBookend = get(spot, "spot_type", "Standard") === "Book-ends";
  const isUploadScriptFile = get(spot, "is_upload_script_file", false);

  const onValueChangedSpot = (index, { field, value }) => {
    const spotInfoField = step.fields.find((f) => f.field === "spot_info");
    const spotInfo = get(process, "data.fields.spot_info.value", []);
    const spot = spotInfo[index];
    if (spot) {
      spotInfo[index] = {
        ...spot,
        [field]: value,
      };
      valueChanged(
        {
          ...spotInfoField,
          changing: spotInfo.map((item, i) => {
            if (index === i) return [field];
            return [];
          }),
        },
        process,
        template,
        user,
        {
          value: spotInfo,
        }
      );
      if (setShouldBlockNavigation) setShouldBlockNavigation(true);
    }
  };

  const valueOrEmpty = (item) => {
    let value = null;
    if (has(process, `data.fields.${item.field}.value.name`)) {
      value = process.data.fields[item.field].value.name || "";
    } else if (get(process, `data.fields.${item.field}.value`)) {
      value = process.data.fields[item.field].value;
    } else {
      return intl.formatMessage({ id: "process > none" });
    }
    return value;
  };

  const isScriptHistory = get(
    process,
    "data.fields.script_history.value.length",
    0
  );
  const producerAssignsVo = get(
    process,
    "data.fields.producer_assigns_vo.value",
    false
  );
  const validationState = get(
    rest.validationState,
    `spot_info.validation_error.${spotIndex}`
  );
  const isShowAssignsVo = producerAssignsVo && step.key === "write_script";
  return (
    <div className={bem.b()}>
      {!isScriptHistory && (
        <RenderDraftOrFinalSwitch
          onValueChangedSpot={onValueChangedSpot}
          spotIndex={spotIndex}
          spot={spot}
          isBookend={isBookend}
        />
      )}

      {isUploadScriptFile ? (
        <RenderUploadFile
          spot={spot}
          process={process}
          intl={intl}
          onValueChangedSpot={onValueChangedSpot}
          spotIndex={spotIndex}
          isView={isView}
          isBookend={isBookend}
          validationState={validationState}
        />
      ) : (
        <RenderScriptEditor
          isBookend={isBookend}
          isView={isView}
          isEditButtonDisabled={isEditButtonDisabled}
          spot={spot}
          onValueChangedSpot={onValueChangedSpot}
          spotIndex={spotIndex}
          process={process}
          intl={intl}
          validationState={validationState}
        />
      )}
      {isShowAssignsVo && (
        <RenderAssignsVO
          isView={isView}
          step={step}
          process={process}
          intl={intl}
          spot={spot}
          onValueChangedSpot={onValueChangedSpot}
          spotIndex={spotIndex}
          validationState={validationState}
          setShouldBlockNavigation={setShouldBlockNavigation}
          valueChanged={valueChanged}
          template={template}
          user={user}
          valueOrEmpty={valueOrEmpty}
        />
      )}
    </div>
  );
};
export default OrderScript;
