import * as React from "react";
import { compose, lifecycle, withHandlers } from "recompose";
import { connect } from "react-redux";
import { get } from "lodash";
import View from "./view";
import { injectIntl } from "react-intl";
import ToastManager from "components/ToastManager";
import { MdImportantDevices } from "react-icons/lib/md";
import {
  getEnterpriseSettings,
  updateEnterpriseSettings,
} from "store/actions/settings";
export default injectIntl(
  compose(
    connect(
      ({ servers, ...rest }) => {
        return {
          loading: get(rest, "settings.enterprise_settings.loading", false),
          enterpriseSettings: get(
            rest,
            "settings.enterprise_settings.data",
            {}
          ),
        };
      },
      { getEnterpriseSettings, updateEnterpriseSettings }
    ),
    withHandlers({
      onFormSubmit: ({
        updateEnterpriseSettings,
        intl,
        setShouldBlockNavigation,
      }) => (values) => {
        updateEnterpriseSettings(values, () => {
          ToastManager.show({
            title: <MdImportantDevices />,
            message: intl.formatMessage({
              id: "enterprise settings > update successfully",
            }),
            level: "success",
          });
          setShouldBlockNavigation(false);
        });
      },
    }),
    withHandlers({
      onGetEnterpriseSettings: ({ getEnterpriseSettings }) => () => {
        getEnterpriseSettings();
      },
    }),
    lifecycle({
      componentDidMount() {
        const { onGetEnterpriseSettings } = this.props;
        onGetEnterpriseSettings();
      },
    })
  )(View)
);
