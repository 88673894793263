import * as React from "react";
import PropsType from "prop-types";
import styled from "styled-components";

const Img = styled.div`
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  &:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
  }
  background-image: url(blob:http://localhost:3000/024bd8f1-f6d7-4df5-8ea0-186ddc4b5924);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

const Image = ({ url, alt, width, height }) => (
  <Img className={"image"} style={{ backgroundImage: `url(${url})`, width, height }} />
);
Image.propsTypes = {
  url: PropsType.string.isRequired,
  alt: PropsType.string,
  width: PropsType.number,
  height: PropsType.number
};

Image.defaultProps = {
  width: 150,
  height: 100
};
export default Image;
