import * as Types from "../types/events";
import history from "components/History";
import Api from "utils/api";
import * as Const from "utils/constants";
import URL from "utils/urls";
import queryString from "query-string";

export const getEvents = params => async dispatch => {
  let errorStr = "";
  try {
    dispatch({ type: Types.FETCH_EVENTS_REQUEST });
    let response = await Api.doCall(
      `${Const.GET_EVENTS()}?${queryString.stringify(params)}`,
      "GET"
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        //loop through all the data and make start_date and end_date an object
        response.data.map(responseEvents => {
          if (!responseEvents._source.allDay) {
            responseEvents._source.start_date = new Date(
              `${responseEvents._source.start_date} ${
                responseEvents._source.start_time
              }`
            );
            responseEvents._source.end_date = new Date(
              `${responseEvents._source.end_date} ${
                responseEvents._source.end_time
              }`
            );
          } else {
            responseEvents._source.start_date = new Date(
              `${responseEvents._source.start_date} 00:00`
            );
            responseEvents._source.end_date = new Date(
              `${responseEvents._source.end_date} 23:59`
            );
            responseEvents._source.start_time = "00:00";
            responseEvents._source.end_time = "23:59";
          }
          return responseEvents;
        });
        dispatch(fetchEventsSuccess(response.data));
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch(fetchEventsError({ data: errorStr }));
};

export const getEvent = id => async dispatch => {
  let errorStr = "";
  try {
    dispatch({ type: Types.FETCH_EVENT_REQUEST, payload: { id } });
    let response = await Api.doCall(Const.GET_EVENT(id), "GET");
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch(fetchEventDataSuccess({ id, data: response.data }));
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch(fetchEventDataError({ id, error: errorStr }));
};

export const addEvent = data => async dispatch => {
  let errorStr = "";
  try {
    dispatch({ type: Types.ADD_EVENT_REQUEST });
    let response = await Api.doCall(Const.GET_EVENTS(), "POST", data);
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        let responseData = JSON.parse(JSON.stringify(response.data));
        if (!responseData._source.allDay) {
          responseData._source.start_date = new Date(
            `${responseData._source.start_date} ${
              responseData._source.start_time
            }`
          );
          responseData._source.end_date = new Date(
            `${responseData._source.end_date} ${responseData._source.end_time}`
          );
        } else {
          responseData._source.start_date = new Date(
            `${responseData._source.start_date} 00:00`
          );
          responseData._source.end_date = new Date(
            `${responseData._source.end_date} 23:59`
          );
          responseData._source.start_time = "00:00";
          responseData._source.end_time = "23:59";
        }
        dispatch({ type: Types.ADD_EVENT_SUCCESS, payload: responseData });
        history.push(URL.CALENDAR());
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.ADD_EVENT_ERROR, payload: errorStr });
};

export const updateEvent = (id, data) => async dispatch => {
  let errorStr = "";
  try {
    dispatch({ type: Types.UPDATE_EVENT_REQUEST });
    let response = await Api.doCall(Const.GET_EVENT(id), "PUT", data);
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        let responseData = JSON.parse(JSON.stringify(response.data));
        if (!responseData._source.allDay) {
          responseData._source.start_date = new Date(
            `${responseData._source.start_date} ${
              responseData._source.start_time
            }`
          );
          responseData._source.end_date = new Date(
            `${responseData._source.end_date} ${responseData._source.end_time}`
          );
        } else {
          responseData._source.start_date = new Date(
            `${responseData._source.start_date} 00:00`
          );
          responseData._source.end_date = new Date(
            `${responseData._source.end_date} 23:59`
          );
          responseData._source.start_time = "00:00";
          responseData._source.end_time = "23:59";
        }
        dispatch({ type: Types.UPDATE_EVENT_SUCCESS, payload: responseData });
        history.push(URL.CALENDAR());
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.UPDATE_EVENT_ERROR, payload: errorStr });
};

export const deleteEvent = id => async dispatch => {
  let errorStr = "";
  try {
    dispatch({ type: Types.DELETE_EVENT_REQUEST });
    let response = await Api.doCall(Const.GET_EVENT(id), "DELETE");
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({ type: Types.DELETE_EVENT_SUCCESS });
        history.push(URL.CALENDAR());
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.DELETE_EVENT_ERROR, payload: errorStr });
};

export function fetchEventsSuccess(payload) {
  return {
    type: Types.FETCH_EVENTS_SUCCESS,
    payload
  };
}

export function fetchEventsError(payload) {
  return {
    type: Types.FETCH_EVENTS_ERROR,
    payload
  };
}

export function fetchEventDataSuccess(payload) {
  return {
    type: Types.FETCH_EVENT_SUCCESS,
    payload
  };
}

export function fetchEventDataError(payload) {
  return {
    type: Types.FETCH_EVENT_ERROR,
    payload
  };
}
