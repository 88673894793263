import PrizesPage from "./view";
import { get } from "lodash";
import { connect } from "react-redux";
import { compose } from "recompose";
export default compose(
  connect((state, props) => {
    return {
      filter:
        get(props, "match.params.filter") + get(props, "location.search", ""),
      ...props,
    };
  }, {})
)(PrizesPage);
