import React from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { get, find, truncate } from "lodash";
import { Label } from "reactstrap";
import Tooltip from "rc-tooltip";
import NumberFormat from "react-number-format";
import Dropdown from "components/Dropdown";
import TextInput from "components/TextInput";
import {
  Asterisk,
  ShadowBox,
  SectionTitle,
  SectionFields,
} from "components/Elements";
import { DownIcon, UpIcon, DeleteIcon } from "components/CustomIcons";
import { fulfillmentList } from "utils/config";

const SelectedItem = (props) => {
  const {
    bem,
    item,
    onDelete,
    intl,
    playsNumber,
    onUpdate,
    isView,
    validationState,
  } = props;
  const perOptions = [...Array(playsNumber || 1).keys()].map((item) => ({
    value: item + 1,
    label: item + 1,
  }));
  const fulfillment = fulfillmentList.find((i) => i.value === item.fulfillment);
  const maxDescriptionLength = 120;
  const originalDescription = get(item, "description", "") || "";
  const description = truncate(originalDescription, {
    length: maxDescriptionLength,
  });
  const isTruncated = originalDescription.length > maxDescriptionLength;
  return (
    <div className={bem.e("selected-prize-package")}>
      <div className={bem.e("selected-prize-padding")}>
        {!isView && (
          <span className={bem.e("trash")} onClick={() => onDelete(item)}>
            <DeleteIcon color="#795AFA" />
          </span>
        )}

        <h2 className={bem.e("selected-prize-title")}>{item.title}</h2>
        <span className={bem.e("selected-prize-subtitle")}>
          {item.subtitle}
        </span>
        <div className={bem.e("selected-prize-value")}>
          <FormattedMessage id="process > promotion > contest > value" />$
          {item.unit_value}
        </div>
        <div className={bem.e("selected-prize-description")}>
          {isTruncated ? (
            <Tooltip
              placement="top"
              trigger={["click", "hover"]}
              overlayClassName={bem.e("tooltip")}
              overlay={
                <div className={bem.e("tooltip-content")}>
                  {originalDescription}
                </div>
              }
            >
              <div className={bem.e("tooltip-content")}>{description}</div>
            </Tooltip>
          ) : (
            description
          )}
        </div>
        <div className={bem.e("selected-prize-row")}>
          {isView ? (
            <div className={bem.e("selected-prize-item")}>
              <Label>
                <FormattedMessage id="process > promotion > contest > per play" />
              </Label>
              <span className={"order-view-value"}>{item.per_play}</span>
            </div>
          ) : (
            <Dropdown
              name="per_play"
              label={
                <FormattedMessage id="process > promotion > contest > per play" />
              }
              className={bem.e("per-play-dropdown")}
              onChange={(selectedOption) => {
                const perPlay = get(selectedOption, "value", 1);
                onUpdate({
                  ...item,
                  per_play: perPlay,
                  total_allotment: perPlay * playsNumber,
                  total_value: perPlay * item.unit_value,
                });
              }}
              value={perOptions.find((i) => i.value === item.per_play)}
              options={perOptions}
            />
          )}
          {isView ? (
            <div className={classnames(bem.e("selected-prize-item"), "px-2")}>
              <Label>
                <FormattedMessage id="process > promotion > contest > fulfillment" />
              </Label>
              <span className={"order-view-value"}>
                {get(fulfillment, "label", "")}
              </span>
            </div>
          ) : (
            <Dropdown
              name="fulfillment"
              label={
                <>
                  <FormattedMessage id="process > promotion > contest > fulfillment" />
                  {!isView && <Asterisk>*</Asterisk>}
                </>
              }
              className={bem.e("fulfillment-dropdown")}
              placeholder={intl.formatMessage({
                id: "process > promotion > contest > select",
              })}
              onChange={(selectedOption) => {
                const fulfillment = get(selectedOption, "value", "");
                onUpdate({
                  ...item,
                  fulfillment,
                });
              }}
              value={fulfillment}
              options={fulfillmentList}
            />
          )}

          <div className={bem.e("total-value")}>
            <Label>
              <FormattedMessage id="process > promotion > contest > total value" />
            </Label>
            <strong>${item.total_value}</strong>
          </div>
        </div>
        {get(validationState, "total_allotment") ? (
          <span className="alert-danger">
            {validationState.total_allotment}
          </span>
        ) : null}
        {get(validationState, "total_value") ? (
          <span className="alert-danger">{validationState.total_value}</span>
        ) : null}
      </div>
      <div className={bem.e("selected-prize-footer")}>
        <div className="d-flex align-items-center justify-content-end w-100">
          <FormattedMessage id="process > promotion > contest > total allotment" />
          : {!isView && <Asterisk>*</Asterisk>}
          {isView ? (
            <strong>{item.total_allotment}</strong>
          ) : (
            <TextInput
              value={item.total_allotment || ""}
              type="number"
              className={bem.e("number-input")}
              max={item.available}
              onChange={(e) => {
                onUpdate({
                  ...item,
                  total_allotment: e.target.value,
                });
              }}
              // error={"error message"}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const PickYourThemePrizePackage = (props) => {
  const {
    validationState,
    process,
    bem,
    onValueChanged,
    intl,
    isView,
    isOpenDetails,
    setIsOpenDetails,
    sectionKey,
    renderPrizeCloset,
    fieldRefs,
  } = props;

  const { prizes } = fieldRefs;
  const selectedPrizes = get(process, "data.fields.prizes.value", "");
  const playsNumber = get(process, "data.fields.plays_number.value")
    ? parseInt(get(process, "data.fields.plays_number.value"))
    : 0;

  const prizeLength = get(selectedPrizes, "length", 0);

  const renderDefaultSection = () => {
    const hasNewPrize = find(selectedPrizes, { key: "_new" });
    if (prizeLength && !hasNewPrize) {
      return null;
    }
    const defaultSectionOpen = isOpenDetails === sectionKey;
    return (
      <ShadowBox className={bem.e("box-prize-details")}>
        <SectionTitle
          className={bem.e("contest-prize-section-title")}
          onClick={() =>
            setIsOpenDetails(defaultSectionOpen ? null : sectionKey)
          }
        >
          <div className={bem.e("box-head-title")}>
            <FormattedMessage
              id="process > promotion > contest > prize index"
              values={{ index: hasNewPrize ? prizeLength : prizeLength + 1 }}
            />
          </div>
          <div className={classnames("d-flex", bem.e("right-section"))}>
            <div className={bem.e("price-total")}>
              <FormattedMessage id="prizes > total" />
            </div>
            <div className={bem.e("price-value")}>
              <NumberFormat
                prefix="$"
                value={0}
                displayType={"text"}
                thousandSeparator={true}
              />
            </div>
            <span className={bem.e("box-action")}>
              {defaultSectionOpen ? <UpIcon /> : <DownIcon />}
            </span>
          </div>
        </SectionTitle>
        {defaultSectionOpen && (
          <React.Fragment>
            <SectionFields
              className={classnames(
                bem.e("section-fields"),
                bem.e("contest-prize-fields")
              )}
            >
              <div className={bem.e("selected-prize-item")}>
                <div className={bem.e("prize-empty-text")}>
                  <FormattedMessage id="process > promotion > contest > title empty prize item" />
                </div>
              </div>
              {get(validationState, "prizes.validation_error") &&
                typeof validationState.prizes.validation_error === "string" && (
                  <span className="alert-danger">
                    {validationState.prizes.validation_error}
                  </span>
                )}
            </SectionFields>
            {renderPrizeCloset()}
          </React.Fragment>
        )}
      </ShadowBox>
    );
  };

  const renderSelectedPrizeItems = () => {
    if (!prizeLength) {
      return null;
    }
    const addedPrizes = selectedPrizes.filter((prize) => prize.key !== "_new");
    if (!addedPrizes.length) {
      return null;
    }
    return addedPrizes.map((prize, index) => {
      const isOpen = prize.key === isOpenDetails;
      return (
        <ShadowBox
          key={`${prize.key}-${index}`}
          className={bem.e("box-prize-details")}
        >
          <SectionTitle className={bem.e("contest-prize-section-title")}>
            <div className={bem.e("box-head-title")}>{prize.title}</div>
            <div className={classnames("d-flex", bem.e("right-section"))}>
              <div className={bem.e("price-total")}>
                <FormattedMessage id="prizes > total" />
              </div>
              <div className={bem.e("price-value")}>
                <NumberFormat
                  prefix="$"
                  value={prize.total_value || 0}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </div>
              <span
                className={bem.e("box-action")}
                onClick={() => setIsOpenDetails(isOpen ? null : prize.key)}
              >
                {isOpen ? <UpIcon /> : <DownIcon />}
              </span>
            </div>
          </SectionTitle>
          {isOpen && (
            <React.Fragment>
              <SectionFields
                className={classnames(
                  bem.e("section-fields"),
                  bem.e("contest-prize-fields")
                )}
              >
                <SelectedItem
                  bem={bem}
                  item={prize}
                  isView={isView}
                  validationState={get(
                    validationState,
                    `prizes.validation_error.${index}`,
                    null
                  )}
                  onUpdate={(item) => {
                    onValueChanged(
                      prizes,
                      selectedPrizes.map((i) => {
                        if (i.key === item.key) {
                          return item;
                        }
                        return i;
                      })
                    );
                  }}
                  onDelete={(item) => {
                    onValueChanged(
                      prizes,
                      selectedPrizes.filter((i) => i.key !== item.key)
                    );
                    props.onRefreshPrizes();
                  }}
                  intl={intl}
                  playsNumber={playsNumber}
                />
              </SectionFields>
              {renderPrizeCloset(prize)}
            </React.Fragment>
          )}
        </ShadowBox>
      );
    });
  };

  return (
    <>
      {renderSelectedPrizeItems()}
      {renderDefaultSection()}
    </>
  );
};

export default PickYourThemePrizePackage;
