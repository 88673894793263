import React from 'react';
import ProgClockSlots from "./view";
import { injectIntl } from "react-intl";
import { compose, withHandlers, withState } from "recompose";
import { connect } from "react-redux";
import { addNewTag, deleteClockSlotCategory } from "store/actions/clocks";
import ToastManager from "components/ToastManager";
import { MdImportantDevices } from "react-icons/lib/md";

export default injectIntl(
  compose(
    connect(
      (state, ownProps) => {
        return {
          ...ownProps,
        };
      },
      {
        addNewTag,
        deleteClockSlotCategory,
      }
    ),
    withState("currentDeleteCategory", "setCurrentDeleteCategory", null),
    withHandlers({
      addNewOption: ({ addNewTag }) => (data, tagType, onAddOption) => {
        addNewTag(data, tagType, (tag) => {
          onAddOption(tag);
        });
      },
      onDeleteClockSlotCategory: ({
        deleteClockSlotCategory,
        currentDeleteCategory,
        setCurrentDeleteCategory,
        selectedStation,
        intl
      }) => (cb) => {
        const category = currentDeleteCategory;
        setCurrentDeleteCategory(null);
        deleteClockSlotCategory(
          {
            station_id: selectedStation.value,
            category_id: category.value,
          },
          () => {
            if(cb) cb(category);
            ToastManager.show({
              title: <MdImportantDevices />,
              message: intl.formatMessage({
                id: "clock creator > delete category successfully"
              }),
              level: "success"
            });
          }
        );
      },
    })
  )(ProgClockSlots)
);
