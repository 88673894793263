import * as React from "react";
import { compose, lifecycle, withHandlers } from "recompose";
import { connect } from "react-redux";
import { get } from "lodash";
import View from "./view";
import { injectIntl } from "react-intl";
import ToastManager from "components/ToastManager";
import { MdImportantDevices } from "react-icons/lib/md";
import {
  getStationAddressVerification,
  updateStationAddressVerification,
} from "store/actions/stations";

export default injectIntl(
  compose(
    connect(
      ({ auth, servers, ...rest }) => {
        return {
          auth,
          currentServer: get(servers, "currentServer.data", {}),
          loading: get(
            rest,
            "stations.stationAddressVerification.loading",
            false
          ),
          stationSettingsData: get(
            rest,
            "stations.stationAddressVerification.data",
            null
          ),
        };
      },
      {
        getStationAddressVerification,
        updateStationAddressVerification,
      }
    ),
    withHandlers({
      onFormSubmit: ({
        updateStationAddressVerification,
        intl,
        setShouldBlockNavigation,
      }) => (values) => {
        updateStationAddressVerification(values, () => {
          ToastManager.show({
            title: <MdImportantDevices />,
            message: intl.formatMessage({
              id: "form manager > update successfully",
            }),
            level: "success",
          });
          setShouldBlockNavigation(false);
        });
      },
    }),
    withHandlers({
      onGetStationSettings: ({
        currentStation,
        getStationAddressVerification,
      }) => () => {
        getStationAddressVerification({
          station_id: currentStation.key,
        });
      },
    }),
    lifecycle({
      componentDidMount() {
        const { onGetStationSettings } = this.props;
        onGetStationSettings();
      },
      componentDidUpdate(prevProps) {
        const isStationChanged =
          this.props.currentStation &&
          this.props.currentStation.key !== prevProps.currentStation.key;
        if (isStationChanged) {
          this.props.onGetStationSettings();
        }
      },
    })
  )(View)
);
