import { connect } from "react-redux";
import View from "./view";
import { injectIntl } from "react-intl";
import { compose, withHandlers, withState } from "recompose";
import { assignTeamProcess } from "store/actions/processes";
import { get } from "lodash";
import {
  getTeamMembers,
  getTeamMembersForAssignment,
} from "store/actions/hubs";

const Enhanced = compose(
  connect(
    (state) => ({
      // info: state.auth.info,
      enterpriseSettings: get(state, "settings.enterprise_settings.data", {}),
      members: get(state, "hubs.membersAssignment.data", []),
      isLoading: get(state, "hubs.membersAssignment.loading", false),
    }),
    {
      assignTeamProcess,
      getTeamMembers,
      getTeamMembersForAssignment,
    }
  ),
  withState("isEdit", "setIsEdit", true),
  withState("isShowSuccessModal", "setIsShowSuccessModal", false),
  withHandlers({
    onSubmit: ({
      setIsShowSuccessModal,
      onClose,
      assignTeamProcess,
      process,
      onSubmittedCallback,
      getTeamMembers,
    }) => (values) => {
      assignTeamProcess(
        {
          ...values,
          process_id: process._id,
        },
        () => {
          setIsShowSuccessModal(true);
          // recall api to get user capacity on Production queue list
          getTeamMembers({
            sorted: [{ id: "name", desc: false }],
            all_members: true,
          });
          if (onSubmittedCallback) {
            onSubmittedCallback();
          }
          setTimeout(() => {
            onClose();
          }, 1200);
        }
      );
    },
  })
)(View);
export default injectIntl(Enhanced);
