import React, { useEffect, useState } from "react";
import bn from "utils/bemnames";
import { FormattedMessage } from "react-intl";
import { Button, Label } from "reactstrap";
import classnames from "classnames";
import Dropdown from "components/Dropdown";
import PropTypes from "prop-types";
import classNames from "classnames";
import Checkbox from "components/Checkbox";
import { get, includes, map, orderBy, uniqBy, xor } from "lodash";
import TextInput from "components/TextInput";
import { Row, Col } from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import CustomMultiSelectDropdown from "components/CustomMultiSelectDropdown";
import AsyncDropdown from "components/AsyncDropdown";
import { fetchClients } from "store/actions/process";
import moment from "moment";

const bem = bn.create("processes-filter-view");
const HEADER_HEIGHT = 95;
const FOOTER_HEIGHT = 92;
export const statusConfig = [
  {
    label: <FormattedMessage id="process > on air" />,
    value: "on_air",
  },
  {
    label: <FormattedMessage id="process > archived" />,
    value: "archived",
  },
];
export const spotTypeConfig = [
  {
    label: <FormattedMessage id="process > sold spot" />,
    value: "sold",
  },
  {
    label: <FormattedMessage id="process > bookend" />,
    value: "bookend",
  },
  {
    label: <FormattedMessage id="process > spec spot" />,
    value: "spec",
  },

  {
    label: <FormattedMessage id="process > coop" />,
    value: "coop",
  },
];
export const spotStatusConfig = [
  {
    label: <FormattedMessage id="process > on air" />,
    value: "on_air",
  },
  {
    label: <FormattedMessage id="process > aired" />,
    value: "aired",
  },
  {
    label: <FormattedMessage id="process > upcoming" />,
    value: "upcoming",
  },
];

function ContentFilterProducedSpotsView(props) {
  const { initialValues, intl, onSubmit } = props;
  const [contentHeight, setContentHeight] = useState(0);
  const [optionStations, setOptionStations] = useState([]);
  useEffect(() => {
    const sidebar = document.getElementById("right-sidebar");
    if (sidebar) {
      setContentHeight(sidebar.offsetHeight - HEADER_HEIGHT - FOOTER_HEIGHT);
    }
  }, []);
  useEffect(() => {
    const newAllStations = map(props.stations, (item) => {
      return {
        value: item.station_key,
        label: item.call_letters,
        data: {
          firstLabel: item.call_letters,
          lastLabel: item.name,
        },
      };
    });
    const options = orderBy(newAllStations, ["label"], ["asc"]);
    setOptionStations(options);
  }, []);
  const fetchClientsOptions = async (q) => {
    const clients = await fetchClients({
      q,
      sort: "client_name asc",
      produced_spots_client_list: true,
      rpp: 1000,
    });
    const options = get(clients, "items", []).map((client) => {
      return {
        label: client._source.client_name,
        value: client._source.client_id,
      };
    });
    return uniqBy(options, "label");
  };

  const expiringOptions = [7, 15, 30].map((item) => ({
    label: intl.formatMessage(
      { id: "process > within days" },
      { number: item }
    ),
    value: item,
  }));
  return (
    <div className={bem.b()}>
      <Formik
        enableReinitialize
        initialValues={{
          stations:
            initialValues.stations && initialValues.stations.length === 0
              ? optionStations
              : initialValues.stations,
          client_id: initialValues.client_id || "",
          client_name: initialValues.client_name || "",
          expiring_in_days: initialValues.expiring_in_days || "",
          statuses: initialValues.statuses || [],
          spot_types: initialValues.spot_types || [],
          spot_status: initialValues.spot_status || [],
          start_date: initialValues.start_date || "",
          end_date: initialValues.end_date || "",
        }}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={Yup.object().shape({
          stations: Yup.mixed().nullable(),
          client_id: Yup.string().nullable(),
          client_name: Yup.string().nullable(),
          expiring_in_days: Yup.string().nullable(),
          spot_types: Yup.mixed().nullable(),
          spot_status: Yup.mixed().nullable(),
          start_date: Yup.date().nullable(),
          end_date: Yup.date()
            .when(
              "start_date",
              (startDate, schema) =>
                startDate &&
                schema
                  .min(
                    startDate,
                    intl.formatMessage({
                      id: `validate > end date should be later than start date`,
                    })
                  )
                  .required(
                    intl.formatMessage({
                      id:
                        "validate > you must select both a start and end date",
                    })
                  )
            )
            .nullable(),
        })}
        onSubmit={(values) => {
          onSubmit({
            ...values,
            stations:
              values.stations.length === optionStations.length
                ? []
                : values.stations,
          });
        }}
      >
        {(formProps) => {
          const { touched, errors, values } = formProps;
          return (
            <>
              <div
                className={classnames(
                  bem.e("filter-view-content-2"),
                  "scroll-bar-style"
                )}
                style={{
                  height: contentHeight,
                }}
              >
                <div>
                  {/* stations */}
                  <div className={bem.e("dropdown")}>
                    <Label>
                      <FormattedMessage id="process > entity" />
                    </Label>
                    <CustomMultiSelectDropdown
                      name="stations"
                      value={values.stations}
                      options={optionStations}
                      placeholder={intl.formatMessage({
                        id: "process > please select",
                      })}
                      isAllowAllOption={true}
                      allOption={{
                        label: intl.formatMessage({
                          id: "process > all stations",
                        }),
                        value: "*",
                      }}
                      isStation
                      isMulti={true}
                      error={null}
                      onChange={(value) => {
                        formProps.setFieldValue("stations", value);
                      }}
                      multiText={`${
                        values.stations.length
                      } ${intl.formatMessage({
                        id: "process > stations",
                      })}`}
                    />
                  </div>
                  <AsyncDropdown
                    name="client_id"
                    loadOptions={fetchClientsOptions}
                    label={<FormattedMessage id="process > client" />}
                    placeholder={intl.formatMessage({
                      id: "process > please select",
                    })}
                    value={
                      values.client_id
                        ? {
                            label: values.client_name,
                            value: values.client_id,
                          }
                        : ""
                    }
                    onChange={(selectedOption) => {
                      formProps.setFieldValue(
                        "client_id",
                        get(selectedOption, "value", "")
                      );
                      formProps.setFieldValue(
                        "client_name",
                        get(selectedOption, "label", "")
                      );
                    }}
                    containerClass={bem.e("dropdown")}
                    defaultOptions={true}
                    error={touched.client_id && errors.client_id}
                  />
                  <Dropdown
                    label={<FormattedMessage id="process > expiring" />}
                    containerClass={bem.e("dropdown")}
                    placeholder={intl.formatMessage({
                      id: "process > please select",
                    })}
                    name="expiring_in_days"
                    onChange={(selectedOption) => {
                      formProps.setFieldValue(
                        "expiring_in_days",
                        selectedOption ? selectedOption.value : ""
                      );
                    }}
                    value={
                      expiringOptions.find(
                        (item) => item.value === values.expiring_in_days
                      ) || ""
                    }
                    options={expiringOptions}
                  />
                  {/* status */}
                  {/* <div className={bem.e("statuses")}>
                    <Label>
                      <FormattedMessage id="process > status" />
                    </Label>
                    <Row>
                      {map(statusConfig, (item, index) => (
                        <Col key={index} xs={6}>
                          <Checkbox
                            checked={includes(values.statuses, item.value)}
                            onChange={() => {
                              formProps.setFieldValue(
                                "statuses",
                                xor(values.statuses, [item.value])
                              );
                            }}
                            text={item.label}
                          />
                        </Col>
                      ))}
                    </Row>
                  </div> */}
                  {/* spot status */}
                  <div className={bem.e("spot-status")}>
                    <Label>
                      <FormattedMessage id="process > status" />
                    </Label>
                    <Row>
                      {map(spotStatusConfig, (item, index) => (
                        <Col key={index} xs={6}>
                          <Checkbox
                            checked={includes(values.spot_status, item.value)}
                            onChange={() => {
                              formProps.setFieldValue(
                                "spot_status",
                                xor(values.spot_status, [item.value])
                              );
                            }}
                            text={item.label}
                          />
                        </Col>
                      ))}
                      <Col key={4} xs={6}>
                      </Col>
                    </Row>
                  </div>
                  {/* spot types */}
                  <div className={bem.e("spot-types")}>
                    <Label>
                      <FormattedMessage id="process > spot type" />
                    </Label>
                    <Row>
                      {map(spotTypeConfig, (item, index) => (
                        <Col key={index} xs={6}>
                          <Checkbox
                            checked={includes(values.spot_types, item.value)}
                            onChange={() => {
                              formProps.setFieldValue(
                                "spot_types",
                                xor(values.spot_types, [item.value])
                              );
                            }}
                            text={item.label}
                          />
                        </Col>
                      ))}
                    </Row>
                  </div>
                  <Row>
                    <Col xs={6} className={bem.e("col-reset")}>
                      <TextInput
                        label={<FormattedMessage id="process > start date" />}
                        type="date"
                        name="start_date"
                        placeholder={intl.formatMessage({
                          id: "process > placeholder select",
                        })}
                        value={values.start_date}
                        onChange={(value) => {
                          formProps.setFieldValue("start_date", value);
                          if (
                            values.end_date &&
                            moment(value, "YYYY-MM-DD").isAfter(
                              moment(values.start_date, "YYYY-MM-DD")
                            )
                          ) {
                            formProps.setFieldValue("end_date", "");
                          }
                        }}
                        allowShowIcon
                      />
                    </Col>
                    <Col xs={6} className={bem.e("col-reset")}>
                      <TextInput
                        label={<FormattedMessage id="process > end date" />}
                        type="date"
                        name="end_date"
                        placeholder={intl.formatMessage({
                          id: "process > placeholder select",
                        })}
                        disabled={!values.start_date}
                        value={values.end_date}
                        onChange={(value) =>
                          formProps.setFieldValue("end_date", value)
                        }
                        min={values.start_date || undefined}
                        openToDate={
                          values.start_date &&
                          moment().isBefore(
                            moment(values.start_date, "YYYY-MM-DD")
                          )
                            ? moment(values.start_date, "YYYY-MM-DD").toDate()
                            : new Date()
                        } // open to today
                        allowShowIcon
                      />
                    </Col>
                    {(touched.start_date && errors.start_date) ||
                    (touched.end_date && errors.end_date) ? (
                      <Col xs={12} className={bem.e("col-reset")}>
                        {touched.start_date && errors.start_date ? (
                          <span className="text-danger">
                            {errors.start_date}
                          </span>
                        ) : null}
                        {touched.end_date && errors.end_date ? (
                          <span className="text-danger">{errors.end_date}</span>
                        ) : null}
                      </Col>
                    ) : null}
                  </Row>
                </div>
              </div>
              <div className={bem.e("production-buttons")}>
                <Button
                  color="blue"
                  className={classNames("btn-radius", bem.e("btn-apply"))}
                  type="submit"
                  onClick={formProps.handleSubmit}
                >
                  <FormattedMessage id="process > apply" />
                </Button>
                <Button
                  color="link"
                  className={classNames("btn-radius", bem.e("btn-clear"))}
                  onClick={() => {
                    formProps.resetForm({
                      stations: [],
                      client_id: "",
                      expiring_in_days: "",
                      statuses: [],
                      spot_types: [],
                      spot_status: [],
                      start_date: "",
                      end_date: "",
                    });
                  }}
                >
                  <FormattedMessage id="process > clear" />
                </Button>
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
}
ContentFilterProducedSpotsView.defaultProps = {
  initialValues: {
    stations: [],
    client_id: "",
    expiring_in_days: "",
    statuses: [],
    spot_types: [],
    spot_status: [],
    start_date: "",
    end_date: "",
  },
};
ContentFilterProducedSpotsView.propTypes = {
  initialValues: PropTypes.object,
};

export default ContentFilterProducedSpotsView;
