import React from "react";
import PropTypes from "prop-types";
import bn from "utils/bemnames";
import classNames from "classnames";
import { get } from "lodash";
import { ArrowRightIcon } from "components/CustomIcons";
import useOutsideClick from "utils/useOutsideClick";
import { useRef } from "react";
import { hubTypes } from "utils/config";

const bem = bn.create("right-sidebar");
function HubSidebar(props) {
  const {
    onCancel,
    isOpen,
    children,
    type,
    selectedHub,
    isShowSubtitle,
  } = props;
  const hubName = get(selectedHub, "name", "");
  const containerRef = useRef();
  const hubType = hubTypes.find((item) => item.value === selectedHub.team_type);
  useOutsideClick(containerRef, (event) => {
    const openModal = document
      .querySelector("body")
      .classList.contains("modal-open");
    const isClickDropdownIcon = event.target
      ? event.target.classList.contains("disable-unsaved")
      : false;
    if (!openModal && !isClickDropdownIcon) {
      onCancel();
    }
  });
  return (
    <div ref={containerRef}>
      <div className={bem.b()}>
        {isOpen && (
          <div className={bem.e("background")} onClick={() => onCancel()}></div>
        )}
        <div
          className={classNames(bem.e("wrapper"), bem.e(type), {
            [bem.e("open")]: isOpen,
            [bem.e("close")]: !isOpen,
          })}
          id={`right-sidebar-${type}`}
        >
          <div className={bem.e("header")}>
            <div className={bem.e("btn-close")} onClick={() => onCancel()}>
              <ArrowRightIcon color="#8165F3" />
            </div>
            <div className={bem.e("title")}>
              <h3>{hubName}</h3>
              {isShowSubtitle ? <span>{get(hubType, "label", "")}</span> : null}
            </div>
          </div>
          {isOpen && children}
        </div>
      </div>
    </div>
  );
}
HubSidebar.defaultProps = {
  isOpen: false,
  onCancel: () => {},
  children: null,
  type: "",
  isShowSubtitle: true,
};
HubSidebar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  children: PropTypes.element,
  type: PropTypes.string,
  isShowSubtitle: PropTypes.bool,
};
export default HubSidebar;
