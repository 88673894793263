import React, { useEffect } from "react";
import bn from "utils/bemnames";
import moment from "moment";
import PropsTypes from "prop-types";
import StatementSticky from "../Statement";
import { pick } from "lodash";
import HeaderCalendar from "./HeaderCalendar.js";

const bem = bn.create("calendar-week-sticky");
export default function CalendarWeekSticky(props) {
  const {
    currentDate,
    view,
    // onView,
    weekStart,
    lang,
    onAddFillItems,
    fillSettings,
    weekdays,
  } = props;
  useEffect(() => {
    moment.locale(lang, {
      week: {
        dow: weekStart, //Monday = 1 or Sunday = 0 is the first day of the week.
      },
    });
  }, [weekStart, lang]);
  useEffect(() => {
    const element = document.querySelector("#calendar-sticky");
    // To check when element get's position sticky
    const observer = new IntersectionObserver(
      function(entries) {
        // No intersection
        if (entries[0].intersectionRatio === 0 && element && element.classList)
          element.classList.add("calendar-sticky");
        // Fully intersects
        else if (
          entries[0].intersectionRatio === 1 &&
          element &&
          element.classList
        )
          element.classList.remove("calendar-sticky");
      },
      {
        threshold: [0, 1],
      }
    );

    observer.observe(document.querySelector("#calendar-container-bottom"));
  });
  return (
    <>
      <div className={bem.b()} id="calendar-sticky">
        <div className={"calendar-sticky-content"}>
          <HeaderCalendar
            view={view}
            date={currentDate}
            weekdays={weekdays}
            onRangeChange={(data) => {
              props.onRangeChange(data);
            }}
            onAddFillItems={onAddFillItems}
            fillSettings={fillSettings}
          />

          <StatementSticky
            {...pick(props, [
              "intl",
              "statementType",
              "selectedItemSwap",
              "onSelectItemSwap",
              "onUndoSwappedItem",
              "selectedItem",
              "setStatementType",
              "setSelectedItem",
              "itemSwapped",
              "onUndoDeletedItem",
              "onUndoAddFillItems",
            ])}
          />
        </div>
      </div>
      <div id="calendar-container-bottom"></div>
    </>
  );
}
CalendarWeekSticky.propTypes = {
  weekStart: PropsTypes.number,
  lang: PropsTypes.string,
};
CalendarWeekSticky.defaultProps = {
  currentDate: new Date(),
  view: "work_week",
  onRangeChange: () => {},
  onView: () => {},
  setCurrentDate: () => {},
  onAddFillItems: () => {},
  weekStart: 1,
  lang: "en-us",
};
