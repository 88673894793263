import React from "react";
import bn from "utils/bemnames";
import { Row, Col } from "reactstrap";
import { get, lowerCase } from "lodash";
const bem = bn.create("client-review");

function ReviewLayout(props) {
  const { extraReviewProcess, children, renderReviewContent } = props;
  const server = get(extraReviewProcess, "server");
  const themeBackgroundColor = get(server, "theme_background_color", "");
  const isBackgroundWhite = lowerCase(themeBackgroundColor) === "#ffffff";
  const buttonColor = get(server, "button_color", "#ffffff");
  const isButtonColorWhite = lowerCase(buttonColor) === "#ffffff";
  const buttonStyles = {
    background: buttonColor,
    color: isButtonColorWhite ? "#334856" : "#ffffff",
    borderColor: isButtonColorWhite ? "#334856" : buttonColor,
  };
  const invertButtonStyles = {
    color: buttonColor,
    borderColor: buttonColor,
  };

  const childrenDom =
    typeof children === "function"
      ? children({ buttonStyles, invertButtonStyles })
      : children;
  return (
    <div className={bem.b()}>
      <div className={bem.e("server-image")}>
        <img src={get(server, "background_image")} alt="" />
      </div>
      <div className="container-fluid">
        <Row>
          <Col className="col-reset">
            <div className={bem.e("review-container")}>
              <div
                className={bem.e("header")}
                style={{
                  backgroundColor: themeBackgroundColor,
                  boxShadow: isBackgroundWhite ? `0px 0px 5px 0 #aaa` : "none",
                }}
              >
                {!!get(server, "logo") && (
                  <img
                    src={get(server, "logo")}
                    alt="logo"
                    className={bem.e("logo")}
                  />
                )}
                <div className={bem.e("branding-wrapper")}>
                  {!!get(server, "name") && !get(server, "logo") && (
                    <h2 className={bem.e("server-name")}>
                      {get(server, "name")}
                    </h2>
                  )}
                  {get(server, "tag_line") && (
                    <p
                      className={bem.e("server-description")}
                      style={{
                        color: isBackgroundWhite ? "#334856" : "#ffffff",
                      }}
                    >
                      {get(server, "tag_line")}
                    </p>
                  )}
                </div>
              </div>
              {renderReviewContent &&
                renderReviewContent(buttonStyles, invertButtonStyles)}
            </div>
          </Col>
        </Row>
        {childrenDom}
      </div>
    </div>
  );
}
export default ReviewLayout;
