import React from "react";
import { compose, withState, lifecycle, withHandlers } from "recompose";
import { connect } from "react-redux";
import { getCurrentServer } from "store/actions/servers";
import { first, includes, get, uniqBy, filter, forOwn, find } from "lodash";
import {
  createSystemTemplate,
  updateSystemTemplate,
  getSystemTemplates,
  setPrimarySystemTemplate,
  duplicateSystemTemplate,
  deleteSystemTemplate,
  checkDuplicateTemplate,
} from "store/actions/systemTemplates";
import { MdImportantDevices } from "react-icons/lib/md";
import ToastManager from "components/ToastManager";
import View from "./view";
import { injectIntl } from "react-intl";
export default injectIntl(
  compose(
    connect(
      ({ auth, servers, systemTemplates }) => {
        let stations = [];

        const entities = get(
          servers,
          `currentServer.data.entities.promotion`,
          {}
        );
        forOwn(entities, (entityStations) => {
          stations = [...stations, ...entityStations];
        });
        const stationKeys = uniqBy(stations, "id").map((item) => item.key);
        const promotionStations = filter(auth.info.stations, (item) =>
          includes(stationKeys, item.key)
        );

        const listTemplates = get(systemTemplates, "systemTemplates.data.data");
        const page = get(systemTemplates, "systemTemplates.data.page");
        const pages = get(systemTemplates, "systemTemplates.data.pages");

        return {
          auth,
          user: auth.user,
          stations: promotionStations,
          listTemplates,
          page,
          hasMore: pages > page,
          isLoading:
            get(systemTemplates, "systemTemplates.loading", false) ||
            get(systemTemplates, "updateSystemTemplate.loading", false) ||
            get(systemTemplates, "checkDuplicateTemplate.loading", false),
        };
      },
      {
        getCurrentServer,
        createSystemTemplate,
        updateSystemTemplate,
        getSystemTemplates,
        setPrimarySystemTemplate,
        duplicateSystemTemplate,
        deleteSystemTemplate,
        checkDuplicateTemplate,
      }
    ),
    withState("activeTab", "setActiveTab", () => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const activeTab = urlParams.get("tab");
      return activeTab || "release_form";
    }),
    withState("shouldBlockNavigation", "setShouldBlockNavigation", false),
    withState("openSystemTemplate", "setOpenSystemTemplate", null),
    withState("isOpenConfirmationModal", "setIsOpenConfirmationModal", false),
    withState("currentStation", "setCurrentStation", ({ stations }) => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const station = urlParams.get("station");
      const stationUrl = find(stations, (i) => i.key === station);
      return stationUrl || first(stations) || null;
    }),
    withHandlers({
      getMoreItems: ({
        activeTab,
        currentStation,
        getSystemTemplates,
        page,
      }) => () => {
        getSystemTemplates({
          type: activeTab,
          station_id: get(currentStation, "key"),
          page: page + 1,
        });
      },
      onSetPrimary: ({
        setPrimarySystemTemplate,
        activeTab,
        currentStation,
        getSystemTemplates,
        setShouldBlockNavigation,
        intl,
      }) => async (data) => {
        const id = get(data, "id", null);
        await setPrimarySystemTemplate(id);
        ToastManager.show({
          title: <MdImportantDevices />,
          message: intl.formatMessage({
            id: "system templates > successfully",
          }),
          level: "success",
        });
        setShouldBlockNavigation(false);
        getSystemTemplates({
          type: activeTab,
          station_id: get(currentStation, "key"),
        });
      },
      onDelete: ({
        deleteSystemTemplate,
        activeTab,
        currentStation,
        getSystemTemplates,
        setShouldBlockNavigation,
        intl,
      }) => async (data) => {
        const id = get(data, "id", null);
        await deleteSystemTemplate(id);
        ToastManager.show({
          title: <MdImportantDevices />,
          message: intl.formatMessage({
            id: "system templates > delete successfully",
          }),
          level: "success",
        });
        setShouldBlockNavigation(false);
        getSystemTemplates({
          type: activeTab,
          station_id: get(currentStation, "key"),
        });
      },
      onPublish: ({
        updateSystemTemplate,
        activeTab,
        currentStation,
        getSystemTemplates,
        setShouldBlockNavigation,
        intl,
      }) => async (data) => {
        const id = get(data, "id", null);
        const published = get(data, "published", 0);
        await updateSystemTemplate(id, {
          ...data,
          published: published === 0 ? true : false,
        });
        ToastManager.show({
          title: <MdImportantDevices />,
          message: intl.formatMessage({
            id: "system templates > update successfully",
          }),
          level: "success",
        });
        setShouldBlockNavigation(false);
        getSystemTemplates({
          type: activeTab,
          station_id: get(currentStation, "key"),
        });
      },
      onDuplicate: ({
        duplicateSystemTemplate,
        activeTab,
        currentStation,
        getSystemTemplates,
        setShouldBlockNavigation,
        intl,
      }) => async (data) => {
        const id = get(data, "id", null);

        await duplicateSystemTemplate(id);
        ToastManager.show({
          title: <MdImportantDevices />,
          message: intl.formatMessage({
            id: "system templates > duplicate successfully",
          }),
          level: "success",
        });
        setShouldBlockNavigation(false);
        getSystemTemplates({
          type: activeTab,
          station_id: get(currentStation, "key"),
        });
      },
      onSubmit: ({
        createSystemTemplate,
        updateSystemTemplate,
        activeTab,
        currentStation,
        getSystemTemplates,
        setShouldBlockNavigation,
        setIsOpenConfirmationModal,
        intl,
      }) => async (data) => {
        const id = get(data, "id", null);
        const post = data;
        if (id) {
          await updateSystemTemplate(id, post);
          ToastManager.show({
            title: <MdImportantDevices />,
            message: intl.formatMessage({
              id: "system templates > update successfully",
            }),
            level: "success",
          });
        } else {
          await createSystemTemplate(post);
          ToastManager.show({
            title: <MdImportantDevices />,
            message: intl.formatMessage({
              id: "system templates > create successfully",
            }),
            level: "success",
          });
        }
        setShouldBlockNavigation(false);
        setIsOpenConfirmationModal(false);
        getSystemTemplates({
          type: activeTab,
          station_id: get(currentStation, "key"),
        });
      },
      onCheckDuplicateTemplate: ({ checkDuplicateTemplate }) => (
        params = {},
        cb
      ) => {
        checkDuplicateTemplate(params, cb);
      },
    }),
    lifecycle({
      componentDidMount() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const station = urlParams.get("station");
        this.props.getCurrentServer(() => {
          const firstStation = first(this.props.stations);
          const selectedStationUrl = find(
            this.props.stations,
            (i) => i.key === station
          );
          const currentStation = selectedStationUrl
            ? {
                value: selectedStationUrl.key,
                label: selectedStationUrl.name,
                key: selectedStationUrl.key,
                name: selectedStationUrl.name,
                data: {
                  logo: selectedStationUrl.logo,
                  name: selectedStationUrl.name,
                  contact_email: selectedStationUrl.contact_email,
                },
              }
            : firstStation
            ? {
                value: firstStation.key,
                label: firstStation.name,
                key: firstStation.key,
                name: firstStation.name,
                data: {
                  logo: firstStation.logo,
                  name: firstStation.name,
                  contact_email: firstStation.contact_email,
                },
              }
            : null;
          this.props.setCurrentStation(currentStation);
        });
      },
    })
  )(View)
);
