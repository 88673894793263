import * as React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Link } from "react-router-dom";
import { get, lowerCase, find } from "lodash";
import TextInput from "components/TextInput";
import TextInputFormatted from "components/TextInputFormatted";
import Dropdown from "components/Dropdown";
import { FormattedMessage } from "react-intl";
import { FaCheck } from "react-icons/lib/fa";
import {
  MdDashboard,
  MdSchedule,
} from "react-icons/lib/md";
import {
  Row,
  Col,
  Form,
  Button,
  Label,
  Modal,
  ModalBody,
  ButtonGroup
} from "reactstrap";
import NumberFormat from "react-number-format";
import SelectStations from "../SelectStations";
import history from "components/History";
import ToastManager from "components/ToastManager";
import Checkbox from "components/Checkbox";
import {
  autoSuggestClients,
  // autoSuggestContracts
} from "store/actions/process";

import {
  ShadowBox,
  Asterisk,
  SectionTitle,
  SectionFields,
  DivView
} from "components/Elements";
import OrderDetailsDuplicate from "components/OrderDetailsDuplicate";
import OrderDetailsIncompleteContract from "components/OrderDetailsIncompleteContract";
import PrivilegedComponent from "components/PrivilegedComponent";
import { PRIVILEGES } from "utils/constants";
import TextareaEvaluator from "components/TextareaEvaluator";
import { PhoneNumberFormat, RotationFormatSuffix } from "utils/config";
// import UploadFile from "components/UploadFile/index";
import ConfirmationModal from "../ConfirmationModal/view";
import CalendarEventsModal from "components/CalendarEventsModal";
import URL from "utils/urls";
import timeOptions from 'assets/data/time-options.json';

class PromotionRequestDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      contracts: [],
      isOpenDialog: false,
      isOpenDialogDelete: false,
      isOpenDialogSubmitted: false,
      isOpenPromotionRequest: true,
      isOpenAppearanceEvent: false,
      isOpenContractDetails: false,
      isOpenContestGiveaway: false,
      isOpenCalendarEvents: false,
    };
  }

  static propTypes = {
    mode: PropTypes.string
  };

  static defaultProps = {
    mode: "edit"
  };

  componentDidMount() {
    this._isMounted = true;
    autoSuggestClients({
      q: "",
      cb: items => {
        if (this._isMounted) {
          this.setState({ customers: items, fieldRefs: false });
        }
      }
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  onCheckBoxChange = (item, process, template, user, checked) => {
    this.props.checkboxChanged(item, process, template, user, {
      target: { checked }
    });
  };

  switchToSpot(key) {
    const { intl } = this.props;
    if (key === "new") {
      ToastManager.show({
        title: intl.formatMessage("toast > title not saved"),
        message: intl.formatMessage(
          "toast > message please save the order before switching to another spot"
        ),
        level: "error"
      });
      return;
    }
    // let process = this.props.process;
    let template = this.props.template;
    // if(this.props.process.data.fields.key===key) return
    history.push("/processes/edit/" + template.key + "/" + key);
  }

  getFieldRefs() {
    if (this.state.fieldRefs) return this.state.fieldRefs;
    let {
      process,
      template,
      user,
      step,
      valueChanged,
      // validationState,
      // fileUploaded,
      // fileDeleted,
      // filesUploaded,
      // filesDeleted,
      // checkboxGroupChanged,
      // checkboxChanged,
      getRoleCandidates
      // spotsChanged,
      // multipleStationSelectionChanged
    } = this.props;

    step = template.steps.promotion_request_details;
    let client_name = step.fields.find(f => f.field === "client_name");
    let agency_name = step.fields.find(f => f.field === "agency_name");
    let budget_amount = step.fields.find(f => f.field === "budget_amount");
    // let account_executive = step.fields.find(f => f.field === "account_executive");
    let account_exec_phone = step.fields.find(
      f => f.field === "account_exec_phone"
    );
    let account_exec_email = step.fields.find(
      f => f.field === "account_exec_email"
    );
    let appearance_event = step.fields.find(f => f.field === "appearance_event");
    let contest_giveaway = step.fields.find(f => f.field === "contest_giveaway");
    let digital = step.fields.find(f => f.field === "digital");
    let other = step.fields.find(f => f.field === "other");
    let event_type = step.fields.find(f => f.field === "event_type");
    let event_show_requested = step.fields.find(f => f.field === "event_show_requested");
    let event_pay_jock_directly = step.fields.find(f => f.field === "event_pay_jock_directly");
    let event_marketing_objective = step.fields.find(f => f.field === "event_marketing_objective");
    let event_occurrence = step.fields.find(f => f.field === "event_occurrence");
    let notes = step.fields.find(f => f.field === "notes");
    let event_date = step.fields.find(f => f.field === "event_date");
    let start_time = step.fields.find(f => f.field === "start_time");
    let venue_name = step.fields.find(f => f.field === "venue_name");
    let location_information = step.fields.find(f => f.field === "location_information");
    let event_venue_capacity = step.fields.find(f => f.field === "event_venue_capacity");
    let event_held_indoors = step.fields.find(f => f.field === "event_held_indoors");
    let order_title = step.fields.find(f => f.field === "order_title");
    let contest_type = step.fields.find(f => f.field === "contest_type");
    let contest_marketing_objective = step.fields.find(f => f.field === "contest_marketing_objective");
    let contest_prize = step.fields.find(f => f.field === "contest_prize");
    let contest_prize_description = step.fields.find(f => f.field === "contest_prize_description");
    let contest_categories = step.fields.find(f => f.field === "contest_categories");
    let contest_quantity = step.fields.find(f => f.field === "contest_quantity");
    let contest_unit_value = step.fields.find(f => f.field === "contest_unit_value");
    let select_stations = step.fields.find(f => f.field === "order_stations");

    let select_production_manager = step.fields.find(
      f => f.field === "account_executive"
    );
    let select_production_manager_options = getRoleCandidates(
      select_production_manager,
      process,
      template,
      user
    );
    let select_production_manager_value;
    if (process.data.fields[select_production_manager.field]) {
      select_production_manager_value = {
        value: process.data.fields[select_production_manager.field].value.id,
        label: process.data.fields[select_production_manager.field].value.name,
        role: process.data.fields[select_production_manager.field].value.role
      };
    } else if (
      select_production_manager.default &&
      select_production_manager_options &&
      select_production_manager_options.length > 0
    ) {
      select_production_manager_value = select_production_manager_options.reduce(
        e => e.value === user.id
      );
      if (select_production_manager_value)
        valueChanged(select_production_manager, process, template, user, {
          value: {
            id: user.id,
            name: user.name,
            role: select_production_manager.default
          }
        });
    }

    let payload = {
      client_name,
      agency_name,
      budget_amount,
      select_production_manager,
      select_production_manager_options,
      select_production_manager_value,
      account_exec_phone,
      account_exec_email,
      appearance_event,
      contest_giveaway,
      digital,
      other,
      event_type,
      event_show_requested,
      event_pay_jock_directly,
      event_marketing_objective,
      event_occurrence,
      notes,
      event_date,
      start_time,
      venue_name,
      location_information,
      event_venue_capacity,
      event_held_indoors,
      order_title,
      contest_type,
      contest_marketing_objective,
      contest_prize,
      contest_prize_description,
      contest_categories,
      contest_quantity,
      contest_unit_value,
      select_stations
    };
    this.setState({ fieldRefs: payload });
    //debugger
    console.log("recomputed");
    return payload;
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.stationsWithoutSpots &&
      nextProps.stationsWithoutSpots !== this.props.stationsWithoutSpots
    ) {
      this.setState({
        isOpenDialog: true,
        stationsWithoutSpots: nextProps.stationsWithoutSpots
      });
    }
    if(JSON.stringify(nextProps.info) !== JSON.stringify(this.props.info)){
      this.setState({ fieldRefs: false });
      return
    }
    for (
      let i = 0;
      i < Object.keys(nextProps.process.data.fields).length;
      i++
    ) {
      let key = Object.keys(nextProps.process.data.fields)[i];
      let changed = false;
      if (
        !nextProps.process.data.fields[key] &&
        !this.props.process.data.fields[key]
      )
        continue;
      else if (
        !nextProps.process.data.fields[key] &&
        this.props.process.data.fields[key]
      )
        changed = true;
      else if (
        nextProps.process.data.fields[key] &&
        !this.props.process.data.fields[key]
      )
        changed = true;
      else if (
        !nextProps.process.data.fields[key].value &&
        !this.props.process.data.fields[key].value
      )
        continue;
      else if (
        !nextProps.process.data.fields[key].value &&
        this.props.process.data.fields[key].value
      )
        changed = true;
      else if (
        nextProps.process.data.fields[key].value &&
        !this.props.process.data.fields[key].value
      )
        changed = true;
      else if (
        nextProps.process.data.fields[key].value.toString() !==
        this.props.process.data.fields[key].value.toString()
      )
        changed = true;
      if (changed) {
        console.log("changed: " + key);
        if (
          [
            "client_id",
            "contract_stations",
            "order_stations",
            "order_sold",
            "spots",
            "key"
          ].indexOf(key) > -1
        ) {
          this.setState({ fieldRefs: false });
          // refresh prizes list when changing contest stations.
          if (key === "order_stations") {
            this.props.forgetPrizes({
              keys: [
                "filter_grand_prizes_list",
                "filter_qualifying_prizes_list"
              ]
            });
          }
          break;
        }
      }
    }
    // this.setState({fieldRefs:false})

    // open section when having any validate
    if (this.props.validationState !== nextProps.validationState) {
      let sections = [
        "promotion_request",
        "appearance_event",
        "contest_giveaway",
      ];
      sections.forEach((key, index) => {
        let fields = [];
        let errors = [];
        fields = this.renderFieldsSection(key);
        errors = fields.filter(item =>
          get(nextProps.validationState, `${item}.validation_error`)
        );
        if (errors.length > 0) {
          if (
            key === "promotion_request" &&
            !this.state.isOpenPromotionRequest
          ) {
            this.setState({
              isOpenPromotionRequest: true
            });
          } else if (
            key === "appearance_event" &&
            !this.state.isOpenAppearanceEvent
          ) {
            this.setState({
              isOpenAppearanceEvent: true
            });
          } else if (
            key === "contest_giveaway" &&
            !this.state.isOpenContestGiveaway
          ) {
            this.setState({
              isOpenContestGiveaway: true
            });
          }
        }
      });
    }
  }

  renderFieldsSection = key => {
    let fields = [];
    switch (key) {
      case "promotion_request":
        fields = [
          "order_stations",
          "client_name",
          "agency_name",
          "budget_amount",
          "account_executive",
          "account_exec_phone",
          "account_exec_email",
          "appearance_event",
          "contest_giveaway",
          "digital",
          "other",
        ];
        break;
      case "appearance_event":
        fields = [
          "event_type",
          "event_show_requested",
          "event_pay_jock_directly",
          "event_marketing_objective",
          "event_occurrence",
          "notes",
          "event_date",
          "start_time",
          "venue_name",
          "location_information",
          "event_venue_capacity",
          "event_held_indoors",
        ];
        break;
      case "contest_giveaway":
        fields = [
          "order_title",
          "contest_type",
          "contest_marketing_objective",
          "contest_prize",
          "contest_prize_description",
          "contest_categories",
          "contest_quantity",
          "contest_unit_value",
        ];
        break;
      default:
        break;
    }
    return fields;
  };

  isCheckFieldRequired = (key, fields) => {
    const { process } = this.props;

    let required = [];
    if (key === "liner_schedule") {
      const { liners, active_liner } = this.getFieldRefs();
      const linersArray = process.data.fields[liners.field]
        ? process.data.fields[liners.field].value
        : [{}];
      const activelinerIndex = process.data.fields[active_liner.field]
        ? process.data.fields[active_liner.field].value
        : 0;
      const activeliner = linersArray[activelinerIndex];
      required = fields.filter((key, index) => {
        if (key === "liner_days_of_week" || key === "order_stations") {
          return get(activeliner, `${key}.length`) === 0;
        } else {
          return !get(activeliner, `${key}`);
        }
      });
    } else {
      required = fields.filter((key, index) => {
        if (key === "contract_stations" || key === "order_stations") {
          return get(process, `data.fields.${key}.value.length`) === 0;
        } else if (key === "order_files") {
          return (
            !get(process, `data.fields.${key}.value`) ||
            get(process, `data.fields.${key}.value.length`) === 0
          );
        } else {
          return !get(process, `data.fields.${key}.value`);
        }
      });
    }
    return required;
  };

  isCheckAnyFieldHaveValue = (key, fields) => {
    const { process } = this.props;
    let status = false;
    if (key === "liner_schedule") {
      const { liners, active_liner } = this.getFieldRefs();
      const linersArray = process.data.fields[liners.field]
        ? process.data.fields[liners.field].value
        : [{}];
      const activelinerIndex = process.data.fields[active_liner.field]
        ? process.data.fields[active_liner.field].value
        : 0;
      const activeliner = linersArray[activelinerIndex];
      fields.forEach((key, index) => {
        let value = null;
        if (key === "liner_days_of_week" || key === "order_stations") {
          value = get(activeliner, `${key}.length`);
        } else {
          value = get(activeliner, `${key}`);
        }
        if (value) {
          status = true;
        }
      });
    } else {
      fields.forEach(key => {
        let value = null;
        if (key === "contract_stations" || key === "order_stations") {
          value = get(process, `data.fields.${key}.value.length`);
        } else if (key === "order_files") {
          value = get(process, `data.fields.${key}.value.length`);
        } else {
          value = get(process, `data.fields.${key}.value`);
        }
        if (value) {
          status = true;
        }
      });
    }
    return status;
  };

  isCompletedSection = key => {
    const { step } = this.props;
    let fields = this.renderFieldsSection(key);
    let fieldsRequired = fields.filter((key, index) => {
      const fieldRef = step.fields.find(f => f.field === key);
      return get(fieldRef, "mandatory");
    });
    // section don't have any fields required
    if (fieldsRequired.length === 0) {
      return this.isCheckAnyFieldHaveValue(key, fields);
    } else {
      // check section with fields require
      const required = this.isCheckFieldRequired(key, fieldsRequired);
      return required.length === 0;
    }
  };

  renderForm() {
    let {
      process,
      template,
      user,
      step,
      valueChanged,
      validationState,
      // fileUploaded,
      // fileDeleted,
      // checkboxGroupChanged,
      // checkboxChanged,
      // getRoleCandidates,
      // spotsChanged,
      multipleStationSelectionChanged
    } = this.props;

    let {
      client_name,
      agency_name,
      budget_amount,
      select_production_manager,
      select_production_manager_options,
      select_production_manager_value,
      account_exec_phone,
      account_exec_email,
      appearance_event,
      contest_giveaway,
      digital,
      other,
      event_type,
      event_show_requested,
      event_pay_jock_directly,
      event_marketing_objective,
      event_occurrence,
      notes,
      event_date,
      start_time,
      venue_name,
      location_information,
      event_venue_capacity,
      event_held_indoors,
      order_title,
      contest_type,
      contest_marketing_objective,
      contest_prize,
      contest_prize_description,
      contest_categories,
      contest_quantity,
      contest_unit_value,
      select_stations,
    } = this.getFieldRefs();

      const appearanceEvent = process.data.fields[
          appearance_event.field
        ]
        ? process.data.fields[appearance_event.field].value
        : false;
      const contestGiveaway = process.data.fields[
        contest_giveaway.field
        ]
        ? process.data.fields[contest_giveaway.field].value
        : false;

    return (
      <div className="text-left">
        
        <Row>
            <Col>
              <Label><FormattedMessage id="process > title quick view" /></Label>
              <ButtonGroup>
                <Button
                  block
                  color="blue"
                  outline
                  style={{
                    borderRadius: 30,
                    minWidth: "95px",
                    marginRight: "10px",
                    paddingRight: " 20px",
                    paddingLeft: "20px",
                    marginLeft: "20px"
                  }}
                  onClick={() => this.setState({isOpenCalendarEvents : true})}>
                  <MdSchedule /> <FormattedMessage id="process > title events" />
                </Button>

                <Button
                  block
                  color="blue"
                  outline
                  style={{
                    borderRadius: 30,
                    paddingRight: " 20px",
                    paddingLeft: "20px"
                  }}
                  onClick={() => this.setState({isOpenCalendarEvents : true})}>
                  <MdDashboard /> <FormattedMessage id="process > title contests" />
                </Button>
              </ButtonGroup>
              <CalendarEventsModal
                isOpen={this.state.isOpenCalendarEvents}
                onToggle={() => this.setState(prevState => ({isOpenCalendarEvents : !prevState.isOpenCalendarEvents}))}
              />
            </Col>
          </Row>
        <Form>
          <ShadowBox className="promotion-shadow-box">
            <SectionTitle
              className="promotion-section-title"
              onClick={() =>
                this.setState({
                  isOpenPromotionRequest: !this.state.isOpenPromotionRequest
                })
              }>
              <FormattedMessage id="process > title promotion request form" />
              {this.isCompletedSection("promotion_request") && (
                <FaCheck color="#6F96D1" size={30} />
              )}
            </SectionTitle>
            <SectionFields
              className={!this.state.isOpenPromotionRequest && "hidden"}>
              <Row>
                <Col>
                  <TextInput
                    label={
                      <span>
                        {client_name.title}
                        {client_name.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    value={
                      process.data.fields[client_name.field]
                        ? process.data.fields[client_name.field].value
                        : ""
                    }
                    onChange={valueChanged.bind(
                      this,
                      client_name,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[client_name.field] &&
                      validationState[client_name.field].validation_error}
                  </div>
                </Col>
                <Col>
                  <TextInput
                    label={
                      <span>
                        {agency_name.title}
                        {agency_name.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    value={
                      process.data.fields[agency_name.field]
                        ? process.data.fields[agency_name.field].value
                        : ""
                    }
                    onChange={valueChanged.bind(
                      this,
                      agency_name,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[agency_name.field] &&
                      validationState[agency_name.field].validation_error}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                <TextInputFormatted
                    thousandSeparator={true}
                    label={
                      <span>
                        {budget_amount.title}
                        {budget_amount.mandatory && (
                          <Asterisk>*</Asterisk>
                        )}
                      </span>
                    }
                    type="number"
                    value={
                      process.data.fields[budget_amount.field]
                        ? process.data.fields[budget_amount.field]
                            .value
                        : ""
                    }
                    onChange={value => {
                      valueChanged(
                        budget_amount,
                        process,
                        template,
                        user,
                        {
                          value
                        }
                      );
                    }}
                  />
                  <div className="alert-danger">
                    {validationState[budget_amount.field] &&
                      validationState[budget_amount.field].validation_error}
                  </div>
                </Col>
                <Col>
                    <Dropdown
                      isClearable={true}
                      name="select_production_manager"
                      {...select_production_manager.props}
                      options={select_production_manager_options}
                      label={
                        <span>
                          {select_production_manager.title}
                          {select_production_manager.mandatory && (
                            <Asterisk>*</Asterisk>
                          )}
                        </span>
                      }
                      value={
                        process.data.fields[select_production_manager.field]
                          ? select_production_manager_value
                          : ""
                      }
                      onChange={selectedOption => {
                        valueChanged(
                          select_production_manager,
                          process,
                          template,
                          user,
                          {
                            value: selectedOption
                              ? {
                                  id: selectedOption.value,
                                  name: selectedOption.label,
                                  role: selectedOption.role
                                }
                              : null
                          }
                        );
                      }}
                    />
                    <div className="alert-danger">
                      {validationState[select_production_manager.field] &&
                        validationState[select_production_manager.field]
                          .validation_error}
                    </div>
                  </Col>
                {/* <Col>
                  <TextInput
                    label={
                      <span>
                        {account_executive.title}
                        {account_executive.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    value={
                      process.data.fields[account_executive.field]
                        ? process.data.fields[account_executive.field].value
                        : ""
                    }
                    onChange={valueChanged.bind(
                      this,
                      account_executive,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[account_executive.field] &&
                      validationState[account_executive.field].validation_error}
                  </div>
                </Col> */}
              </Row>
              <Row>
                <Col>
                  <TextInputFormatted
                    label={
                      <span>
                        {account_exec_phone.title}
                        {account_exec_phone.mandatory && (
                          <Asterisk>*</Asterisk>
                        )}
                      </span>
                    }
                    value={
                      process.data.fields[account_exec_phone.field]
                        ? process.data.fields[account_exec_phone.field].value
                        : ""
                    }
                    format={PhoneNumberFormat}
                    onChange={value => {
                      valueChanged(
                        account_exec_phone,
                        process,
                        template,
                        user,
                        {
                          value
                        }
                      );
                    }}
                  />
                  <div className="alert-danger">
                    {validationState[account_exec_phone.field] &&
                      validationState[account_exec_phone.field]
                        .validation_error}
                  </div>
                </Col>
                <Col>
                  <TextInput
                    label={
                      <span>
                        {account_exec_email.title}
                        {account_exec_email.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    value={
                      process.data.fields[account_exec_email.field]
                        ? process.data.fields[account_exec_email.field].value
                        : ""
                    }
                    onChange={valueChanged.bind(
                      this,
                      account_exec_email,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[account_exec_email.field] &&
                      validationState[account_exec_email.field].validation_error}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>
                    <FormattedMessage id="process > title promotion type" />
                  </Label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Checkbox
                    checked={
                      process.data.fields[
                        appearance_event.field
                      ] &&
                      process.data.fields[
                        appearance_event.field
                      ].value
                        ? true
                        : false
                    }
                    onChange={checked => {
                      this.onCheckBoxChange(
                        appearance_event,
                        process,
                        template,
                        user,
                        checked
                      );
                    }}
                    text={appearance_event.title}
                  />
                </Col>
                <Col>
                  <Checkbox
                    checked={
                      process.data.fields[
                        contest_giveaway.field
                      ] &&
                      process.data.fields[
                        contest_giveaway.field
                      ].value
                        ? true
                        : false
                    }
                    onChange={checked => {
                      this.onCheckBoxChange(
                        contest_giveaway,
                        process,
                        template,
                        user,
                        checked
                      );
                    }}
                    text={contest_giveaway.title}
                  />
                </Col>
                <Col>
                  <Checkbox
                    checked={
                      process.data.fields[
                        digital.field
                      ] &&
                      process.data.fields[
                        digital.field
                      ].value
                        ? true
                        : false
                    }
                    onChange={checked => {
                      this.onCheckBoxChange(
                        digital,
                        process,
                        template,
                        user,
                        checked
                      );
                    }}
                    text={digital.title}
                  />
                </Col>
                <Col>
                  <Checkbox
                    checked={
                      process.data.fields[
                        other.field
                      ] &&
                      process.data.fields[
                        other.field
                      ].value
                        ? true
                        : false
                    }
                    onChange={checked => {
                      this.onCheckBoxChange(
                        other,
                        process,
                        template,
                        user,
                        checked
                      );
                    }}
                    text={other.title}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs="12" lg="12">
                  <SelectStations
                    contract={true}
                    checkboxes={true}
                    item={select_stations}
                    {...select_stations.props}
                    validationState={validationState}
                    checked={process.data.fields[select_stations.field]}
                    template={template}
                    isFilterByServerConfig={true}
                    onChange={value => {
                      multipleStationSelectionChanged(
                        select_stations,
                        process,
                        template,
                        user,
                        value
                      );
                    }}
                  />
                </Col>
              </Row>
            </SectionFields>
          </ShadowBox>

          {appearanceEvent &&(     
           <ShadowBox className="promotion-shadow-box">
            <SectionTitle
              className="promotion-section-title"
              onClick={() =>
                this.setState({
                  isOpenAppearanceEvent: !this.state.isOpenAppearanceEvent
                })
              }>
              <FormattedMessage id="process > title promotion appearance event" />
              {this.isCompletedSection("appearance_event") && (
                <FaCheck color="#6F96D1" size={30} />
              )}
            </SectionTitle>
            <SectionFields
              className={!this.state.isOpenAppearanceEvent && "hidden"}>
              <Row>
                <Col>
                  <Dropdown
                    isClearable={true}
                    name="event_type"
                    {...event_type.props}
                    label={
                      <span>
                        {event_type.title}
                        {event_type.mandatory && (
                          <Asterisk>*</Asterisk>
                        )}
                      </span>
                    }
                    value={
                      process.data.fields[
                        event_type.field
                      ]
                        ? {
                            label: (
                              <FormattedMessage
                                id={`process > option label ${lowerCase(
                                  process.data.fields[
                                    event_type.field
                                  ].value
                                )}`}
                              />
                            ),
                            value:
                              process.data.fields[
                                event_type.field
                              ].value
                          }
                        : ""
                    }
                    onChange={valueChanged.bind(
                      this,
                      event_type,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[event_type.field] &&
                      validationState[event_type.field]
                        .validation_error}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Dropdown
                    isClearable={true}
                    name="event_show_requested"
                    {...event_show_requested.props}
                    label={
                      <span>
                        {event_show_requested.title}
                        {event_show_requested.mandatory && (
                          <Asterisk>*</Asterisk>
                        )}
                      </span>
                    }
                    value={
                      process.data.fields[
                        event_show_requested.field
                      ]
                        ? {
                            label: (
                              <FormattedMessage
                                id={`process > option label ${lowerCase(
                                  process.data.fields[
                                    event_show_requested.field
                                  ].value
                                )}`}
                              />
                            ),
                            value:
                              process.data.fields[
                                event_show_requested.field
                              ].value
                          }
                        : ""
                    }
                    onChange={valueChanged.bind(
                      this,
                      event_show_requested,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[event_show_requested.field] &&
                      validationState[event_show_requested.field]
                        .validation_error}
                  </div>
                </Col>
                <Col>
                  <Label>&nbsp;</Label>
                  <Checkbox
                    checked={
                      process.data.fields[
                        event_pay_jock_directly.field
                      ] &&
                      process.data.fields[
                        event_pay_jock_directly.field
                      ].value
                        ? true
                        : false
                    }
                    onChange={checked => {
                      this.onCheckBoxChange(
                        event_pay_jock_directly,
                        process,
                        template,
                        user,
                        checked
                      );
                    }}
                    text={event_pay_jock_directly.title}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextInput
                    label={
                      <span>
                        {event_marketing_objective.title}
                        {event_marketing_objective.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    value={
                      process.data.fields[event_marketing_objective.field]
                        ? process.data.fields[event_marketing_objective.field].value
                        : ""
                    }
                    type="textarea"
                    onChange={valueChanged.bind(
                      this,
                      event_marketing_objective,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[event_marketing_objective.field] &&
                      validationState[event_marketing_objective.field].validation_error}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Dropdown
                    isClearable={true}
                    name="event_occurrence"
                    {...event_occurrence.props}
                    label={
                      <span>
                        {event_occurrence.title}
                        {event_occurrence.mandatory && (
                          <Asterisk>*</Asterisk>
                        )}
                      </span>
                    }
                    value={
                      process.data.fields[
                        event_occurrence.field
                      ]
                        ? {
                            label: (
                              <FormattedMessage
                                id={`process > option label ${lowerCase(
                                  process.data.fields[
                                    event_occurrence.field
                                  ].value
                                )}`}
                              />
                            ),
                            value:
                              process.data.fields[
                                event_occurrence.field
                              ].value
                          }
                        : ""
                    }
                    onChange={valueChanged.bind(
                      this,
                      event_occurrence,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[event_occurrence.field] &&
                      validationState[event_occurrence.field]
                        .validation_error}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextInput
                    label={
                      <span>
                        {notes.title}
                        {notes.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    value={
                      process.data.fields[notes.field]
                        ? process.data.fields[notes.field].value
                        : ""
                    }
                    type="textarea"
                    onChange={valueChanged.bind(
                      this,
                      notes,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[notes.field] &&
                      validationState[notes.field].validation_error}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextInput
                    label={
                      <span>
                        {event_date.title}
                        {event_date.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    type="date"
                    min={moment().format("YYYY-MM-DD")}
                    value={
                      process.data.fields[event_date.field]
                        ? process.data.fields[event_date.field].value
                        : ""
                    }
                    onChange={valueChanged.bind(
                      this,
                      event_date,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[event_date.field] &&
                      validationState[event_date.field].validation_error}
                  </div>                  
                </Col>
                <Col>
                <Dropdown
                    isClearable={true}
                    name="end_time"
                    {...start_time.props}
                    label={
                      <span>
                        {start_time.title}
                        {start_time.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    value={
                      process.data.fields[start_time.field]
                        ? {
                          label : get(find(timeOptions, t => t.value === process.data.fields[start_time.field].value), 'label', ""),
                          value : process.data.fields[start_time.field].value
                        }
                        : ""
                    }
                    onChange={valueChanged.bind(
                      this,
                      start_time,
                      process,
                      template,
                      user
                    )}
                  />
                  {/* <TextInput
                    label={
                      <span>
                        {start_time.title}
                        {start_time.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    type="text"
                    value={
                      process.data.fields[start_time.field]
                        ? process.data.fields[start_time.field].value
                        : ""
                    }
                    onChange={valueChanged.bind(
                      this,
                      start_time,
                      process,
                      template,
                      user
                    )}
                  /> */}
                  <div className="alert-danger">
                    {validationState[start_time.field] &&
                      validationState[start_time.field].validation_error}
                  </div>                  
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextInput
                    label={
                      <span>
                        {venue_name.title}
                        {venue_name.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    type="text"
                    value={
                      process.data.fields[venue_name.field]
                        ? process.data.fields[venue_name.field].value
                        : ""
                    }
                    onChange={valueChanged.bind(
                      this,
                      venue_name,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[venue_name.field] &&
                      validationState[venue_name.field].validation_error}
                  </div>                  
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextInput
                    label={
                      <span>
                        {location_information.title}
                        {location_information.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    value={
                      process.data.fields[location_information.field]
                        ? process.data.fields[location_information.field].value
                        : ""
                    }
                    type="textarea"
                    onChange={valueChanged.bind(
                      this,
                      location_information,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[location_information.field] &&
                      validationState[location_information.field].validation_error}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                <TextInputFormatted
                    thousandSeparator={true}
                    label={
                      <span>
                        {event_venue_capacity.title}
                        {event_venue_capacity.mandatory && (
                          <Asterisk>*</Asterisk>
                        )}
                      </span>
                    }
                    type="number"
                    value={
                      process.data.fields[event_venue_capacity.field]
                        ? process.data.fields[event_venue_capacity.field]
                            .value
                        : ""
                    }
                    onChange={value => {
                      valueChanged(
                        event_venue_capacity,
                        process,
                        template,
                        user,
                        {
                          value
                        }
                      );
                    }}
                  />
                  <div className="alert-danger">
                    {validationState[event_venue_capacity.field] &&
                      validationState[event_venue_capacity.field].validation_error}
                  </div>                  
                </Col>
                <Col>
                  <Label>&nbsp;</Label>
                  <Checkbox
                    checked={
                      process.data.fields[
                        event_held_indoors.field
                      ] &&
                      process.data.fields[
                        event_held_indoors.field
                      ].value
                        ? true
                        : false
                    }
                    onChange={checked => {
                      this.onCheckBoxChange(
                        event_held_indoors,
                        process,
                        template,
                        user,
                        checked
                      );
                    }}
                    text={event_held_indoors.title}
                  />
                </Col>
              </Row>
              {!process.key && (<Row>
                <Col>
                  <Button
                    color="blue"
                    className="button-edit-link button-edit"
                    tag={Link}
                    to={URL.ADD_PROCESS({
                      template_id: "promotion_event"
                    })}
                    onClick={() => {
                      this.props.getTemplate("promotion_event");
                    }}
                  >
                    <FormattedMessage id="event > add new event" />
                  </Button>
                </Col>
              </Row>)}
            </SectionFields>
          </ShadowBox>
          )}
          {contestGiveaway &&(  
          <ShadowBox className="promotion-shadow-box">
            <SectionTitle
              className="promotion-section-title"
              onClick={() =>
                this.setState({
                  isOpenContestGiveaway: !this.state.isOpenContestGiveaway
                })
              }>
              <FormattedMessage id="process > title promotion contest giveaway" />
              {this.isCompletedSection("contest_giveaway") && (
                <FaCheck color="#6F96D1" size={30} />
              )}
            </SectionTitle>
            <SectionFields
              className={!this.state.isOpenContestGiveaway && "hidden"}>
              <Row>
                <Col>
                  <TextInput
                    label={
                      <span>
                        {order_title.title}
                        {order_title.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    value={
                      process.data.fields[order_title.field]
                        ? process.data.fields[order_title.field].value
                        : ""
                    }
                    onChange={valueChanged.bind(
                      this,
                      order_title,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[order_title.field] &&
                      validationState[order_title.field].validation_error}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Dropdown
                    isClearable={true}
                    name="contest_type"
                    {...contest_type.props}
                    label={
                      <span>
                        {contest_type.title}
                        {contest_type.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    value={
                      process.data.fields[contest_type.field]
                        ? {
                            label: (
                              <FormattedMessage
                                id={`process > option label ${lowerCase(
                                  process.data.fields[contest_type.field].value
                                )}`}
                              />
                            ),
                            value: process.data.fields[contest_type.field].value
                          }
                        : ""
                    }
                    onChange={valueChanged.bind(
                      this,
                      contest_type,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[contest_type.field] &&
                      validationState[contest_type.field].validation_error}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextInput
                    label={
                      <span>
                        {contest_marketing_objective.title}
                        {contest_marketing_objective.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    value={
                      process.data.fields[contest_marketing_objective.field]
                        ? process.data.fields[contest_marketing_objective.field].value
                        : ""
                    }
                    type="textarea"
                    onChange={valueChanged.bind(
                      this,
                      contest_marketing_objective,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[contest_marketing_objective.field] &&
                      validationState[contest_marketing_objective.field].validation_error}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextInput
                    label={
                      <span>
                        {contest_prize.title}
                        {contest_prize.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    value={
                      process.data.fields[contest_prize.field]
                        ? process.data.fields[contest_prize.field].value
                        : ""
                    }
                    onChange={valueChanged.bind(
                      this,
                      contest_prize,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[contest_prize.field] &&
                      validationState[contest_prize.field].validation_error}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextInput
                    label={
                      <span>
                        {contest_prize_description.title}
                        {contest_prize_description.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    value={
                      process.data.fields[contest_prize_description.field]
                        ? process.data.fields[contest_prize_description.field].value
                        : ""
                    }
                    type="textarea"
                    onChange={valueChanged.bind(
                      this,
                      contest_prize_description,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[contest_prize_description.field] &&
                      validationState[contest_prize_description.field].validation_error}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Dropdown
                    isClearable={true}
                    name="contest_categories"
                    {...contest_categories.props}
                    label={
                      <span>
                        {contest_categories.title}
                        {contest_categories.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    value={
                      process.data.fields[contest_categories.field]
                        ? {
                            label: process.data.fields[contest_categories.field].value,
                            value: process.data.fields[contest_categories.field].value
                          }
                        : ""
                    }
                    onChange={valueChanged.bind(
                      this,
                      contest_categories,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[contest_categories.field] &&
                      validationState[contest_categories.field].validation_error}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Dropdown
                    isClearable={true}
                    name="contest_quantity"
                    {...contest_quantity.props}
                    label={
                      <span>
                        {contest_quantity.title}
                        {contest_quantity.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    value={
                      process.data.fields[contest_quantity.field]
                        ? {
                            label: process.data.fields[contest_quantity.field].value,
                            value: process.data.fields[contest_quantity.field].value
                          }
                        : ""
                    }
                    onChange={valueChanged.bind(
                      this,
                      contest_quantity,
                      process,
                      template,
                      user
                    )}
                  />
                  <div className="alert-danger">
                    {validationState[contest_quantity.field] &&
                      validationState[contest_quantity.field].validation_error}
                  </div>
                </Col>
                <Col>
                  <TextInputFormatted
                    prefix={"$"}
                    thousandSeparator={true}
                    label={
                      <span>
                        {contest_unit_value.title}
                        {contest_unit_value.mandatory && (
                          <Asterisk>*</Asterisk>
                        )}
                      </span>
                    }
                    type="number"
                    value={
                      process.data.fields[contest_unit_value.field]
                        ? process.data.fields[contest_unit_value.field].value
                        : ""
                    }
                    onChange={value => {
                      valueChanged(
                        contest_unit_value,
                        process,
                        template,
                        user,
                        {
                          value
                        }
                      );
                    }}
                  />
                   <div className="alert-danger">
                    {validationState[contest_unit_value.field] &&
                      validationState[contest_unit_value.field].validation_error}
                  </div>
                </Col>
              </Row>
              {!process.key && (<Row>
                <Col>
                <Button
                    color="blue"
                    className="button-edit-link button-edit"
                    tag={Link}
                    to={URL.ADD_PROCESS({
                      template_id: "promotion"
                    })}
                    onClick={() => {
                      this.props.getTemplate("promotion");
                    }}
                  >
                    <FormattedMessage id="process > button add contest" />
                  </Button>
                </Col>
              </Row>)}
            </SectionFields>
          </ShadowBox>
          )}
          <Row>
            <Col>
              <ButtonGroup>
                <Button
                  block
                  color="blue"
                  className="button-submit"
                  onClick={this.props.buttonClicked.bind(
                    this,
                    step,
                    step.fields.find(f => f.key === "save_button"),
                    process,
                    template,
                    user
                  )}>
                  <FormattedMessage id="process > button submit" />
                </Button>

                <Button
                  block
                  color="blue"
                  outline
                  className="button-save-draft"
                  onClick={this.props.buttonClicked.bind(
                    this,
                    step,
                    {
                      ...step.fields.find(f => f.key === "save_button"),
                      draft: true
                    },
                    process,
                    template,
                    user
                  )}>
                  <FormattedMessage id="process > button save draft" />
                </Button>
                {
                  this.props.shouldBlockNavigation && (
                    <Button color="" className="process-link-clear-form" onClick={(e) => {
                      e.preventDefault();
                      this.props.setShouldBlockNavigation(false);
                      this.props.onPreparePage();
                    }}>
                      <FormattedMessage id="process > button clear form" />
                    </Button>
                  )
                }
              </ButtonGroup>
            </Col>
          </Row>
        </Form>
        <Modal
          isOpen={this.state.isOpenDialog}
          toggle={() =>
            this.setState({ isOpenDialog: !this.state.isOpenDialog })
          }>
          <ModalBody>
            {this.state.stationsWithoutSpots ? (
              <OrderDetailsIncompleteContract
                stationsWithoutSpots={this.state.stationsWithoutSpots}
                close={() => this.setState({ isOpenDialog: false })}
              />
            ) : (
              <OrderDetailsDuplicate
                process={process}
                template={template}
                close={() => this.setState({ isOpenDialog: false })}
              />
            )}
          </ModalBody>
        </Modal>
      </div>
    );
  }

  formattedValueOrEmpty(value, item) {
    if (get(item, "formatted")) {
      const formatted = get(item, "formatted");
      switch (formatted) {
        case "number":
          return (
            <NumberFormat
              prefix={get(item, "prefix", null)}
              value={value}
              displayType={"text"}
              thousandSeparator={true}
            />
          );
        case "phone":
          return (
            <NumberFormat
              value={value}
              displayType={"text"}
              format={PhoneNumberFormat}
            />
          );
        case "percentage":
          return (
            <NumberFormat
              value={value}
              displayType={"text"}
              suffix={get(item, "suffix", RotationFormatSuffix)}
            />
          );
        default:
          break;
      }
    }
    return value;
  }

  valueOrEmpty(item) {
    let { process } = this.props;
    let value =
      process.data.fields[item.field] && process.data.fields[item.field].value
        ? process.data.fields[item.field].value.name !== undefined
          ? process.data.fields[item.field].value.name
          : process.data.fields[item.field].value
        : "";
    return this.formattedValueOrEmpty(value, item);
  }

  renderView() {
    let {
      process,
      template,
      user,
      step,
      valueChanged
      // validationState,
      // fileUploaded,
      // fileDeleted,
      // filesUploaded,
      // filesDeleted,
      // checkboxGroupChanged,
      // checkboxChanged,
      // getRoleCandidates,
      // spotsChanged,
      // multipleStationSelectionChanged
    } = this.props;

    let {
      client_name,
      agency_name,
      budget_amount,
      //account_executive,
      select_production_manager,
      account_exec_phone,
      account_exec_email,
      appearance_event,
      contest_giveaway,
      digital,
      other,
      event_type,
      event_show_requested,
      event_pay_jock_directly,
      event_marketing_objective,
      event_occurrence,
      notes,
      event_date,
      start_time,
      venue_name,
      location_information,
      event_venue_capacity,
      event_held_indoors,
      order_title,
      contest_type,
      contest_marketing_objective,
      contest_prize,
      contest_prize_description,
      contest_categories,
      contest_quantity,
      contest_unit_value,
      select_stations,
    } = this.getFieldRefs();

    const appearanceEvent = process.data.fields[
      appearance_event.field
      ]
      ? process.data.fields[appearance_event.field].value
      : false;
    const contestGiveaway = process.data.fields[
      contest_giveaway.field
      ]
      ? process.data.fields[contest_giveaway.field].value
      : false;

    // const additionTextFieldsValue = process.data.fields[
    //   contest_additional_questions_textfields.field
    // ]
    //   ? process.data.fields[contest_additional_questions_textfields.field].value
    //   : [""];
    // const triviaQuestionValue = process.data.fields[
    //   contest_trivia_questions_textfields.field
    // ]
    //   ? process.data.fields[contest_trivia_questions_textfields.field].value
    //   : [{ q: "", a: "" }];
    // const linersArray = process.data.fields[liners.field]
    //   ? process.data.fields[liners.field].value
    //   : [{}];
    // const activelinerIndex = process.data.fields[active_liner.field]
    //   ? process.data.fields[active_liner.field].value
    //   : 0;
    // const activeliner = linersArray[activelinerIndex];

    // const draftScript = activeliner[script.field]
    //   ? activeliner[script.field]
    //   : "";
    let parsedDraftScript = "";
    const domParser = new DOMParser();
    // if (draftScript) {
    //   const parsed = domParser.parseFromString(draftScript, "text/html");
    //   const tags = parsed.getElementsByClassName("tag") || [];
    //   for (let index = 0; index < tags.length; index++) {
    //     const tag = tags[index];
    //     const replaceWith = tag.getAttribute("data-tag-replace-with");
    //     if (replaceWith) {
    //       const field = process.data.fields[replaceWith]
    //         ? process.data.fields[replaceWith].value
    //         : "";
    //       tag.innerText = [].concat(field).join(", ");
    //     } else {
    //       tag.innerText = "";
    //     }
    //   }
    //   parsedDraftScript = parsed.documentElement.outerHTML;
    // }

    let spots = [];
    let prevOrNextSpot = false;
    let passedCurrent = false;
    if (
      process.data.fields.key &&
      this.props.process.data.fields.spots &&
      this.props.process.data.fields.spots.value.length > 1
    ) {
      this.props.process.data.fields.spots.value.map((spot, ind) => {
        if (
          spot.key !== process.data.fields.key.value &&
          (!passedCurrent || !prevOrNextSpot)
        )
          prevOrNextSpot = spot;
        else passedCurrent = true;
        spots.push({
          key: spot,
          component: (
            <span>
              {" "}
              <FormattedMessage id="process > title spot" /> {ind + 1}{" "}
            </span>
          )
        });
        return true;
      });
    }
    let valueOrEmpty = this.valueOrEmpty.bind(this);

    return (
      <div className="text-left">
        <Form>
          <ShadowBox className="promotion-shadow-box">
            <SectionTitle
              className="promotion-section-title"
              onClick={() =>
                this.setState({
                  isOpenPromotionRequest: !this.state.isOpenPromotionRequest
                })
              }>
              <FormattedMessage id="process > title promotion request form" />
              {this.isCompletedSection("promotion_request") && (
                <FaCheck color="#6F96D1" size={30} />
              )}
            </SectionTitle>
            <SectionFields
              className={!this.state.isOpenPromotionRequest && "hidden"}>
              <Row>
                <Col>
                  <Label>{client_name.title}</Label>
                  <DivView>{valueOrEmpty(client_name)}</DivView>
                </Col>
                <Col>
                  <Label>{agency_name.title}</Label>
                  <DivView>{valueOrEmpty(agency_name)}</DivView>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>{budget_amount.title}</Label>
                  <DivView>{valueOrEmpty(budget_amount)}</DivView>
                </Col>
                <Col>
                  <Label>{select_production_manager.title}</Label>
                  <DivView>{valueOrEmpty(select_production_manager)}</DivView>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>{account_exec_phone.title}</Label>
                  <DivView>{valueOrEmpty(account_exec_phone)}</DivView>
                </Col>
                <Col>
                  <Label>{account_exec_email.title}</Label>
                  <DivView>
                    {valueOrEmpty(account_exec_email)}
                  </DivView>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label><FormattedMessage id="process > title promotion type" /></Label>
                </Col>
              </Row>
              <Row>
                  <Col>
                  <Checkbox
                    checked={
                      process.data.fields[
                        appearance_event.field
                      ] &&
                      process.data.fields[
                        appearance_event.field
                      ].value
                        ? true
                        : false
                    }
                    onChange={() => null}
                    text={appearance_event.title}
                  />
                </Col>
                <Col>
                  <Checkbox
                    checked={
                      process.data.fields[
                        contest_giveaway.field
                      ] &&
                      process.data.fields[
                        contest_giveaway.field
                      ].value
                        ? true
                        : false
                    }
                    onChange={() => null}
                    text={contest_giveaway.title}
                  />
                </Col>
                <Col>
                  <Checkbox
                    checked={
                      process.data.fields[
                        digital.field
                      ] &&
                      process.data.fields[
                        digital.field
                      ].value
                        ? true
                        : false
                    }
                    onChange={() => null}
                    text={digital.title}
                  />
                </Col>
                <Col>
                  <Checkbox
                    checked={
                      process.data.fields[
                        other.field
                      ] &&
                      process.data.fields[
                        other.field
                      ].value
                        ? true
                        : false
                    }
                    onChange={() => null}
                    text={other.title}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs="12" lg="6">
                  <Label>{select_stations.title}</Label>
                  <DivView>
                    {process.data.fields[select_stations.field] &&
                      process.data.fields[select_stations.field].value.map(
                        (s, i) => (
                          <span
                            style={{
                              paddingRight: "20px",
                              display: "inline-block"
                            }}
                            key={i}>
                            {s}
                          </span>
                        )
                      )}
                  </DivView>
                </Col>
              </Row>
            </SectionFields>
          </ShadowBox>

          {appearanceEvent && (<ShadowBox className="promotion-shadow-box">
            <SectionTitle
              className="promotion-section-title"
              onClick={() =>
                this.setState({
                  isOpenAppearanceEvent: !this.state.isOpenAppearanceEvent
                })
              }>
              <FormattedMessage id="process > title promotion appearance event" />
              {this.isCompletedSection("appearance_event") && (
                <FaCheck color="#6F96D1" size={30} />
              )}
            </SectionTitle>
            <SectionFields
              className={!this.state.isOpenAppearanceEvent && "hidden"}>
              <Row>
                <Col>
                  <Label>{event_type.title}</Label>
                  <DivView>
                    {valueOrEmpty(event_type)}
                  </DivView>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>{event_show_requested.title}</Label>
                  <DivView>
                    {valueOrEmpty(event_show_requested)}
                  </DivView>
                </Col>
                <Col>
                  <Label>&nbsp;</Label>
                  <Checkbox
                    checked={
                      process.data.fields[
                        event_pay_jock_directly.field
                      ] &&
                      process.data.fields[
                        event_pay_jock_directly.field
                      ].value
                        ? true
                        : false
                    }
                    onChange={() => null}
                    text={event_pay_jock_directly.title}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>{event_marketing_objective.title}</Label>
                  <DivView>{valueOrEmpty(event_marketing_objective)}</DivView>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>{event_occurrence.title}</Label>
                  <DivView>{valueOrEmpty(event_occurrence)}</DivView>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>{notes.title}</Label>
                  <DivView>{valueOrEmpty(notes)}</DivView>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>{event_date.title}</Label>
                  <DivView>{valueOrEmpty(event_date)}</DivView>
                </Col>
                <Col>
                  <Label>{start_time.title}</Label>
                  <DivView>{valueOrEmpty(start_time)}</DivView>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>{venue_name.title}</Label>
                  <DivView>{valueOrEmpty(venue_name)}</DivView>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>{location_information.title}</Label>
                  <DivView>{valueOrEmpty(location_information)}</DivView>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>{event_venue_capacity.title}</Label>
                  <DivView>{valueOrEmpty(event_venue_capacity)}</DivView>
                </Col>
                <Col>
                  <Label>&nbsp;</Label>
                  <Checkbox
                    checked={
                      process.data.fields[
                        event_held_indoors.field
                      ] &&
                      process.data.fields[
                        event_held_indoors.field
                      ].value
                        ? true
                        : false
                    }
                    onChange={() => null}
                    text={event_held_indoors.title}
                  />
                </Col>
              </Row>
            </SectionFields>
          </ShadowBox>
          ) }
          {contestGiveaway && (
          <ShadowBox className="promotion-shadow-box">
            <SectionTitle
              className="promotion-section-title"
              onClick={() =>
                this.setState({
                  isOpenContestGiveaway: !this.state.isOpenContestGiveaway
                })
              }>
              <FormattedMessage id="process > title promotion contest giveaway" />
              {this.isCompletedSection("contest_giveaway") && (
                <FaCheck color="#6F96D1" size={30} />
              )}
            </SectionTitle>
            <SectionFields
              className={!this.state.isOpenContestGiveaway && "hidden"}>
              <Row>
                <Col>
                  <Label>{order_title.title}</Label>
                  <DivView>
                    {valueOrEmpty(order_title)}
                  </DivView>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>{contest_type.title}</Label>
                  <DivView>
                    {valueOrEmpty(contest_type)}
                  </DivView>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>{contest_marketing_objective.title}</Label>
                  <DivView>{valueOrEmpty(contest_marketing_objective)}</DivView>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>{contest_prize.title}</Label>
                  <DivView>{valueOrEmpty(contest_prize)}</DivView>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>{contest_prize_description.title}</Label>
                  <DivView>{valueOrEmpty(contest_prize_description)}</DivView>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>{contest_categories.title}</Label>
                  <DivView>{valueOrEmpty(contest_categories)}</DivView>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>{contest_quantity.title}</Label>
                  <DivView>{valueOrEmpty(contest_quantity)}</DivView>
                </Col>
                <Col>
                  <Label>{contest_unit_value.title}</Label>
                  <DivView>{valueOrEmpty(contest_unit_value)}</DivView>
                </Col>
              </Row>
            </SectionFields>
          </ShadowBox>
          )}
        </Form>
        {this.props.mode === "view" && (
          <Row>
            <Col>
              <Button
                color="blue"
                style={{
                  borderRadius: 30,
                  maxWidth: "180px",
                  minWidth: "95px"
                }}
                onClick={() => {
                  history.push(
                    "/processes/edit/" + template.key + "/" + process.key
                  );
                  this.props.setActiveEditTab(step.key);
                }}>
                <FormattedMessage id="process > button edit" />
              </Button>
              <PrivilegedComponent requires={[PRIVILEGES.DELETE_PROCESSES]}>
                <Button
                  color="blue"
                  outline
                  style={{
                    borderRadius: 30,
                    maxWidth: "180px",
                    marginLeft: 10,
                    minWidth: "95px"
                  }}
                  onClick={() => this.setState({ isOpenDialogDelete: true })}>
                  <FormattedMessage id="process > button delete" />
                </Button>
              </PrivilegedComponent>
            </Col>
          </Row>
        )}
        {/* delete modal */}
        <ConfirmationModal
          isOpen={this.state.isOpenDialogDelete}
          onToggle={() =>
            this.setState({
              isOpenDialogDelete: !this.state.isOpenDialogDelete
            })
          }
          onCancel={() => this.setState({ isOpenDialogDelete: false })}
          description={
            <FormattedMessage id="process > description to delete this spot" />
          }
          className="modal-delete"
          onConfirm={() => {
            this.props.deleteProcess(process.key, response => {
              this.setState({ isOpenDialogDelete: false });
              this.setState({ isOpenDialogSubmitted: true });
              setTimeout(() => {
                this.setState({ isOpenDialogSubmitted: false });
                if (prevOrNextSpot)
                  history.push(
                    "/processes/view/" + template.key + "/" + prevOrNextSpot
                  );
                else history.push("/");
              }, 2000);
            });
          }}
          isCloseOutside={false}
        />
        {/* submitted modal */}
        <ConfirmationModal
          isOpen={this.state.isOpenDialogSubmitted}
          onToggle={() =>
            this.setState({
              isOpenDialogSubmitted: !this.state.isOpenDialogSubmitted
            })
          }
          onCancel={() => this.setState({ isOpenDialogSubmitted: false })}
          state="success"
          title={<FormattedMessage id={`process > title deleted`} />}
        />
      </div>
    );
  }

  render() {
    let {
      process
      // template,
      // user,
      // step
      // valueChanged,
      // validationState,
      // fileUploaded,
      // fileDeleted,
      // filesUploaded,
      // filesDeleted,
      // checkboxGroupChanged,
      // checkboxChanged,
      // getRoleCandidates,
      // spotsChanged
    } = this.props;

    if (!process.data.fields || Object.keys(process.data.fields).length === 0)
      return null;

    let form = null;
    if (this.props.mode === "edit") {
      form = this.renderForm();
    } else {
      form = this.renderView();
    }
    return <span className="orderForm">{form}</span>;
  }
}

export default PromotionRequestDetails;
