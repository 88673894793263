import * as Types from "../types/audience";
import { get, uniqBy } from "lodash";

const initialState = {
  saveGroup: {
    is_added: false,
    data: null,
    error: null,
    loading: false
  },
  viewedGroups: {
    data: [],
    error: null,
    loading: false,
    is_dirty: false
  },
};

export default function(state = initialState, action) {

  switch (action.type) {

    case Types.SAVE_GROUP_REQUEST: {
      return {
        ...state,
        saveGroup: {
          is_added: false,
          loading: true,
          error: null
        }
      };
    }

    case Types.SAVE_GROUP_SUCCESS: {
      return {
        saveGroup: {
          is_added: true,
          data: action.payload,
          loading: false,
          error: null
        }
      };
    }

    case Types.SAVE_GROUP_ERROR: {
      return {
        ...state,
        saveGroup: {
          is_added: false,
          loading: false,
          error: action.payload
        }
      };
    }
    
    case Types.FETCH_GROUP_REQUEST: {

      const existingGroups = get(state, "viewedGroups", {});
      const { id, data } = action.payload;

      return {
        ...state,
        viewedGroups : {
          ...existingGroups, 
          [id]: {
            data: data,
            error: null,
            loading: true,
          }
        }
      };
    }

    case Types.FETCH_GROUP_SUCCESS: {

      const existingGroups = get(state, "viewedGroups", {});
      const { id, data } = action.payload;

      return {
        ...state,
        viewedGroups : {
          ...existingGroups, 
          [id]: {
            data: data,
            error: null,
            loading: false,
          }
        }
      };
    }

    case Types.FETCH_GROUP_ERROR: {

      const existingGroups = get(state, "viewedGroups", {});
      const { id, error } = action.payload;

      return {
        ...state,
        viewedGroups : {
          ...existingGroups, 
          [id]: {
            error: error,
            loading: false,
          }
        }
      };
    }

    case Types.UPDATE_GROUP_REQUEST: {
      return {
        ...state,
        updateGroup: {
          loading: true,
          error: null
        }
      };
    }

    case Types.UPDATE_GROUP_SUCCESS: {

      const existingGroups = get(state, "updateGroup", {});

      const { id, data } = action.payload;

      return {
        ...state,
        audience: {
          ...get(state, "teams"),
          is_dirty: true
        },
        updateGroup: {
          data: action.payload,
          loading: false,
          error: null,
        },
        viewedGroups : {
          ...existingGroups, 
          [id]: {
            data: data,
            error: null,
            loading: false,
          }
        }
      };
    }

    case Types.UPDATE_GROUP_ERROR: {
      return {
        ...state,
        updateGroup: {
          loading: false,
          error: action.payload
        }
      };
    }

    default:
      return state;
  }
}
