import ServerForm, { MODE_VIEW, MODE_EDIT } from "./view";
import { get, isFunction } from "lodash";
import React from "react";
import { MdImportantDevices } from "react-icons/lib/md";
import ToastManager from "components/ToastManager";
import { connect } from "react-redux";
import { compose, lifecycle, withHandlers, withState } from "recompose";
import {
  addServer,
  getServer,
  updateServer,
  deleteServer,
} from "store/actions/servers";
import { getAllStations } from "store/actions/stations";
import { deleteUser } from "store/actions/users";
import { injectIntl } from "react-intl";
export { MODE_EDIT, MODE_VIEW };
export default injectIntl(
  compose(
    connect(
      (state, props) => {
        const { id } = props;
        return {
          id,
          isUpdate: id ? true : false,
          server: get(state, `servers.server.${id}.data`, {}),
          auth: get(state, "auth"),
          hasEntitiesToAssign: get(state, "stations.stations.data.data", [])
            .length,
          isLoading:
            get(state, `servers.server.${id}.loading`, false) ||
            get(state, "servers.addServer.loading", false) ||
            get(state, "servers.updateServer.loading", false),
        };
      },
      {
        addServer,
        updateServer,
        getServer,
        deleteServer,
        deleteUser,
        getAllStations,
      }
    ),
    withState("shouldBlockNavigation", "setShouldBlockNavigation", false),
    withState("isViewMode", "setIsViewMode", (props) =>
      get(props, "isView") ? MODE_VIEW : MODE_EDIT
    ),
    withState("deleteServerId", "setDeleteServerId", null),
    withState("deleteAdminId", "setDeleteAdminId", null),
    withState("isDeleteModalOpen", "setIsDeleteModalOpen", false),
    withState("isOpenGroupAdminModal", "setIsOpenGroupAdminModal", false),
    withState("currentAdminEditing", "setCurrentAdminEditing", {}),
    withState("isDeleteAdminModalOpen", "setIsDeleteAdminModalOpen", false),
    withState(
      "isSecurityPolicyModalOpen",
      "setIsSecurityPolicyModalOpen",
      false
    ),
    withState("isOpenPreviewFileModal", "setIsOpenPreviewFileModal", false),
    withState("isOpenEditLogoModal", "setIsOpenEditLogoModal", false),
    withHandlers({
      onFormSubmit: ({
        addServer,
        updateServer,
        isUpdate,
        id,
        onBack,
        intl,
        setIsViewMode,
      }) => (values) => {
        const payload = {
          ...values,
          logo: get(values, "logo.path", ""),
          background_image: get(values, "background_image.path", ""),
        };
        if (isUpdate) {
          updateServer(id, payload, () => {
            ToastManager.show({
              title: <MdImportantDevices />,
              message: intl.formatMessage({
                id: "server > update successfully",
              }),
              autoDismiss: 1.5,
              level: "success",
            });
            setIsViewMode(MODE_VIEW);
          });
        } else {
          addServer(payload, () => {
            if (isFunction(onBack)) onBack();
            ToastManager.show({
              title: <MdImportantDevices />,
              message: intl.formatMessage({
                id: "server > create successfully",
              }),
              autoDismiss: 1.5,
              level: "success",
            });
          });
        }
      },
      openDeleteModal: ({ setIsDeleteModalOpen, setDeleteServerId }) => (
        id
      ) => {
        setIsDeleteModalOpen(true);
        setDeleteServerId(id);
      },
      closeDeleteModal: ({ setIsDeleteModalOpen, setDeleteServerId }) => () => {
        setIsDeleteModalOpen(false);
        setDeleteServerId(null);
      },
      toggleSecurityPolicyModalOpen: ({
        isSecurityPolicyModalOpen,
        setIsSecurityPolicyModalOpen,
      }) => () => {
        setIsSecurityPolicyModalOpen(!isSecurityPolicyModalOpen);
      },
      toggleOpenGroupAdminModal: ({
        isOpenGroupAdminModal,
        setIsOpenGroupAdminModal,
      }) => () => {
        setIsOpenGroupAdminModal(!isOpenGroupAdminModal);
      },
      closeDeleteAdminModal: ({
        setIsDeleteAdminModalOpen,
        setDeleteAdminId,
      }) => () => {
        setIsDeleteAdminModalOpen(false);
        setDeleteAdminId(null);
      },
    }),
    withHandlers({
      onDelete: ({
        closeDeleteModal,
        deleteServerId,
        deleteServer,
        back,
      }) => () => {
        deleteServer(deleteServerId, () => {
          if (isFunction(back)) back();
        });
        closeDeleteModal();
      },
      onDeleteAdmin: ({
        deleteUser,
        deleteAdminId,
        getServer,
        id,
        setIsDeleteAdminModalOpen,
      }) => () => {
        deleteUser(deleteAdminId, () => {
          getServer(id);
          setIsDeleteAdminModalOpen(false);
        });
      },
    }),
    lifecycle({
      componentDidMount() {
        const { id } = this.props;
        if (id) {
          this.props.getServer(id);
        }
        this.props.getAllStations();
      },
      componentWillReceiveProps(nextProps) {
        if (get(this.props, "id") !== get(nextProps, "id")) {
          this.props.getServer(get(nextProps, "id"));
        }
        if (get(this.props, "isView") !== get(nextProps, "isView")) {
          const isViewMode = get(nextProps, "isView") ? MODE_VIEW : MODE_EDIT;
          this.props.setIsViewMode(isViewMode);
        }
      },
    })
  )(ServerForm)
);
