import React from "react";
import Select, { components } from "react-select";
import { FormattedMessage } from "react-intl";
import { colors } from "utils/config";
import { Label } from "reactstrap";
import classnames from "classnames";
import {filter, find, get } from 'lodash';
import { CaretIcon } from "components/CustomIcons";
import bn from "utils/bemnames";
const bem = bn.create("dropdown-color");

const Option = props => {
  return (
    <components.Option {...props}>
      <div className={bem.e("color-option")}>
        <span
          className={classnames(bem.e("dot-color"), {
            [bem.e("dot-color-border")]: props.data.label === "white"
          })}
          style={{ backgroundColor: props.data.value }}
        />
        <FormattedMessage id={`dropdown color > ${props.data.label}`} />
      </div>
    </components.Option>
  );
};

const SingleValue = ({ children, ...props }) => {
  return (
    <components.SingleValue {...props}>
      <div className={bem.e("color-option")}>
        <span
          className={classnames(bem.e("dot-color"), {
            [bem.e("dot-color-border")]: props.data.label === "white"
          })}
          style={{ backgroundColor: props.data.value }}
        />
        <span className={bem.e('color-name')}>
          <FormattedMessage id={`dropdown color > ${props.data.label}`} />
        </span>
      </div>
    </components.SingleValue>
  );
};
const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <CaretIcon />
      </components.DropdownIndicator>
    )
  );
};
const DropdownColorLabel = (props) => {
  return !!props.label && (
    <Label for={props.name} {...props.labelProps}>
      {props.label}
    </Label>
  )
}
const DropdownCustomOption = props => {
  let colorOptions = props.except ? filter(colors, color => !find(props.except, item => item === color.value)) : colors;
  const {isView} = props;
  const value = colorOptions.find(
    item => item.value && item.value.toLowerCase() === props.value.toLowerCase()
  );
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  if(isView) return (
    <div className={bem.b()}>
      <DropdownColorLabel {...props} />
      <div className={bem.e("color-option")}>
        <span
          className={classnames(bem.e("dot-color"), {
            [bem.e("dot-color-border")]: get(value, "label") === "white"
          })}
          style={{ backgroundColor: get(value, "value") }}
        />
        <span className={bem.e('color-name')}>
          <FormattedMessage id={`dropdown color > ${get(value, "label")}`} />
        </span>
      </div>
    </div>
  )
  return (
    <>
      <DropdownColorLabel {...props} />
      <Select
        {...props}
        className={classnames(bem.b(), "react-select-container", {
          'react-select-menu-open': isMenuOpen
        })}
        classNamePrefix="react-select"
        value={value ? value : null}
        options={colorOptions}
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => setIsMenuOpen(false)}
        styles={{
          menu: provided => ({
            ...provided,
            width: "100%",
            zIndex: 9999
          })
        }}
        components={{
          Option,
          SingleValue,
          DropdownIndicator
        }}
      />
      {!!props.error && <span className="text-danger">{props.error}</span>}
    </>
  );
};
export default DropdownCustomOption;
