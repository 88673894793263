import CopySelectStationsModal from "./view";
import { injectIntl } from "react-intl";
import { get } from "lodash";
import { connect } from "react-redux";

export default injectIntl(
  connect((state) => {
    return {
      stations: get(state, "auth.info.stations", []),
    };
  })(CopySelectStationsModal)
);
