import ChatBoxView from "./view";
import { get, forOwn } from "lodash";
import { connect } from "react-redux";
import { compose, withState, withHandlers } from "recompose";
import { filterUsersByStations } from "utils/helpers";
import { uploadFiles } from "store/actions/uploads";

const Enhancer = compose(
  connect(
    (state, props) => {
      const { isFilterUsers, template } = props;
      let keyPickEntities = "";
      if (
        template === "basic_production" ||
        template === "production_provided"
      ) {
        keyPickEntities = "production";
      } else if (template === "contest" || template === "liner") {
        keyPickEntities = "promotion";
      } else if (template === "digital") {
        keyPickEntities = "digital";
      }
      const entities = get(
        state.servers,
        `currentServer.data.entities.${keyPickEntities}`,
        {}
      );
      let stations = [];
      forOwn(entities, (entityStations) => {
        stations = [...stations, ...entityStations];
      });
      const users = get(state, "auth.info.users", []).filter(
        (user) => !!user.name.trim() && user.is_active
      );
      return {
        users: isFilterUsers ? filterUsersByStations(users, stations) : users,
      };
    },
    { uploadFiles }
  ),
  withState("inputValue", "setInputValue", (props) => {
    if (props.initialValues) {
      return props.initialValues;
    }
    return {
      plainValue: "",
      value: "",
      mentions: [],
      attachments: [],
    };
  }),
  withState("isUploading", "setIsUploading", false),
  withState("isEmojiPickerOpen", "setIsEmojiPickerOpen", false),
  withHandlers({
    toggleEmojiPicker: ({ isEmojiPickerOpen, setIsEmojiPickerOpen }) => (e) => {
      setIsEmojiPickerOpen(!isEmojiPickerOpen);
    },
    onUploadAttachment: ({
      uploadFiles,
      setInputValue,
      inputValue,
      setIsUploading,
    }) => async (event) => {
      setIsUploading(true);
      const files = event.target.files;
      const response = await uploadFiles(files);
      setIsUploading(false);
      if (response && Array.isArray(response)) {
        setInputValue({
          ...inputValue,
          attachments: [...get(inputValue, "attachments", []), ...response],
        });
      }
    },
    onSend: ({
      inputValue,
      setInputValue,
      onSubmit,
      isKeepInputValueAfterClickSend,
    }) => () => {
      if (inputValue.value || get(inputValue, "attachments", []).length > 0) {
        onSubmit(inputValue);
        if (!isKeepInputValueAfterClickSend) {
          setInputValue({
            plainValue: "",
            value: "",
            mentions: [],
            attachments: [],
          });
        }
      }
    },
  })
)(ChatBoxView);

export default Enhancer;
