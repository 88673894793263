import React from "react";
import { FormattedMessage } from "react-intl";
import TextInput from "components/TextInput";

import classnames from "classnames";
import { Row, Col, FormGroup } from "reactstrap";
import { get } from "lodash";
import {
  ShadowBox,
  SectionTitle,
  SectionFields,
  Asterisk,
} from "components/Elements";
export default function Notes(props) {
  const { bem, isView, onValueChanged, validationState, process, intl } = props;
  const client_notes = get(props, "fieldRefs.client_notes", {});
  return (
    <ShadowBox className={bem.e("box-notes-details")}>
      <SectionTitle className={bem.e("section-title")}>
        <FormattedMessage id="process > title notes" />
      </SectionTitle>
      <SectionFields
        className={classnames(bem.e("section-fields"), {
          [bem.e("section-notes-details")]: !isView,
          [bem.e("section-notes-details-view")]: isView,
        })}
      >
        <Row>
          <Col xs={12} className={bem.e("reset")}>
            {isView ? (
              <React.Fragment>
                <span className={bem.e("order-view-value")}>
                  {process.data.fields[client_notes.field]
                    ? process.data.fields[client_notes.field].value
                    : intl.formatMessage({ id: "process > none" })}
                </span>
              </React.Fragment>
            ) : (
              <FormGroup>
                <TextInput
                  type="textarea"
                  label={
                    <span>
                      {client_notes.title}
                      {client_notes.mandatory && <Asterisk>*</Asterisk>}
                    </span>
                  }
                  value={
                    process.data.fields[client_notes.field]
                      ? process.data.fields[client_notes.field].value
                      : ""
                  }
                  placeholder={intl.formatMessage({
                    id: "process > promotion > liner > enter note",
                  })}
                  onChange={(event) => {
                    onValueChanged(
                      client_notes,
                      get(event, "target.value", "")
                    );
                  }}
                />
                {validationState[client_notes.field] && (
                  <div className="alert-danger">
                    {validationState[client_notes.field].validation_error}
                  </div>
                )}
              </FormGroup>
            )}
          </Col>
        </Row>
      </SectionFields>
    </ShadowBox>
  );
}
