import React from "react";
import { Col, Row, Label } from "reactstrap";
import Checkbox from "components/Checkbox";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import HandleAssets from "components/HandleAssets";
import FileView from "components/FileView";
import { setScriptField, isHtml } from "utils/helpers";
import UploadFiles from "components/UploadFiles";
import TextareaEvaluator from "components/TextareaEvaluator";
import { get, find, findIndex, isEmpty, includes } from "lodash";
import bn from "utils/bemnames";
import TextareaAutosize from "react-textarea-autosize";

const bem = bn.create("order-approvals");
const renderAssets = ({
  isViewAssets,
  isView,
  spot,
  onValueChangedSpot,
  intl,
  spotIndex,
}) => {
  if (isViewAssets || isView) {
    if (isEmpty(get(spot, `files`, []))) {
      return (
        <span className="none-placeholder">
          <FormattedMessage id="process > none" />
        </span>
      );
    }
    const fileActions = isView
      ? ["view", "download"]
      : ["view", "download", "trash"];

    return (
      <div className="view-files-component">
        <div className="view-only">
          {get(spot, `files`, []).map((file, index) => (
            <FileView
              key={index}
              file={file}
              actions={fileActions}
              onDeleted={() => {
                const newFiles = get(spot, `files`, []).filter(
                  (i, id2) => id2 !== index
                );
                onValueChangedSpot(spotIndex, {
                  field: "files",
                  value: newFiles,
                });
              }}
            />
          ))}
        </div>
      </div>
    );
  }
  return (
    <React.Fragment>
      <HandleAssets
        files={get(spot, "files", [])}
        onUploadedFiles={(value) => {
          onValueChangedSpot(spotIndex, {
            field: "files",
            value,
          });
        }}
        onDeleted={(value) => {
          onValueChangedSpot(spotIndex, {
            field: "files",
            value,
          });
        }}
      />
      <div className={bem.e("creative-upload-files")}>
        <UploadFiles
          attachText={intl.formatMessage({
            id: "process > upload assets",
          })}
          actions={["view", "download"]}
          acceptMultiple={true}
          files={get(spot, "files", [])}
          isHideFilesDragArea={true}
          onUploadedFiles={(value) => {
            onValueChangedSpot(spotIndex, {
              field: "files",
              value: [...value, ...get(spot, "files", [])],
            });
          }}
          onDeleted={(value) => {
            onValueChangedSpot(spotIndex, {
              field: "files",
              value,
            });
          }}
          className={bem.e("upload-files")}
        >
          <FormattedMessage id="process > button upload" />
        </UploadFiles>
      </div>
    </React.Fragment>
  );
};
const OrderApprovals = ({
  step,
  process,
  valueChanged,
  template,
  user,
  intl,
  isView,
  setShouldBlockNavigation,
}) => {
  const refreshTextEditor = get(process, "data.fields.refreshed_at.value", 0);
  const spotInfoField = step.fields.find((f) => f.field === "spot_info");
  const spot = find(
    get(process, "data.fields.spot_info.value", []),
    (spot) => spot.key === process.key
  );
  const spotIndex = findIndex(
    get(process, "data.fields.spot_info.value", []),
    (spot) => spot.key === process.key
  );
  const isViewAssets = get(spotInfoField, "props.isViewAssets", false);
  const onValueChangedSpot = (index, { field, value }) => {
    const spotInfoField = step.fields.find((f) => f.field === "spot_info");
    const spotInfo = get(process, "data.fields.spot_info.value", []);
    const spot = spotInfo[index];
    if (spot) {
      spotInfo[index] = {
        ...spot,
        [field]: value,
      };
      valueChanged(
        {
          ...spotInfoField,
          changing: spotInfo.map((item, i) => {
            if (i === index) return [field];
            return [];
          }),
        },
        process,
        template,
        user,
        {
          value: spotInfo,
        }
      );
      if (setShouldBlockNavigation) setShouldBlockNavigation(true);
    }
  };
  const isBookend = get(spot, "spot_type", "Standard") === "Book-ends";
  const isShowCopyPoints = !includes(["record_script"], step.key);
  const isShowDraftScript = !includes(["record_script"], step.key);
  const isCopyPointsHtml = isHtml(get(spot, "copy_points"));

  return (
    <div className={bem.b()}>
      <Row className={classnames(bem.e("approvals"), "order-approvals1")}>
        <Col>
          <Label className="form-label">
            <FormattedMessage id="process > title approvals" />
          </Label>
          <div className={bem.e("checkboxes")}>
            {get(process, "data.fields.contract_status.value") !==
              "Production Provided" &&
              get(spot, "script_type") !== "final" && (
                <div className="order-approval-checkbox">
                  <Checkbox
                    checked={get(spot, "script_requires_approval", false)}
                    text={<FormattedMessage id="process > field script" />}
                  />
                </div>
              )}
            {get(process, "data.fields.contract_status.value") !==
              "Production Provided" && (
              <div className="order-approval-checkbox">
                <Checkbox
                  checked={get(spot, "recording_requires_approval", false)}
                  text={<FormattedMessage id="process > field recording" />}
                />
              </div>
            )}
            {/*<div className="order-approval-checkbox">
							<Checkbox
								checked={get(
									spot,
									"aircheck_requires_approval",
									false
								)}
								disabled
								text={
									<FormattedMessage id="process > field aircheck" />
								}
							/>
						</div>*/}
          </div>
        </Col>
      </Row>
      {isShowCopyPoints && (
        <Row>
          <Col className={bem.e("col-field")}>
            <Label className="form-label">
              <FormattedMessage id="process > field copy points/tag instructions" />
            </Label>
            <div
              className={classnames(
                bem.e("form-div"),
                bem.e("form-div-copy-points")
              )}
            >
              {get(spot, "copy_points", "") ? (
                isCopyPointsHtml ? (
                  <TextareaEvaluator
                    text={setScriptField(get(spot, "copy_points"))}
                    isEditorOnly={true}
                    hideOrderLength={true}
                    readOnly={true}
                    process={process}
                    onEvaluatorCallback={() => {}}
                    key={refreshTextEditor}
                  />
                ) : (
                  <TextareaAutosize
                    value={setScriptField(get(spot, "copy_points"))}
                    className="text-area-view"
                    disabled
                  />
                )
              ) : (
                <span className="none-placeholder">
                  <FormattedMessage id="process > none" />
                </span>
              )}
            </div>
          </Col>
        </Row>
      )}

      {isShowDraftScript && (
        <React.Fragment>
          <Row>
            <Col className={bem.e("col-field")}>
              <Label className="form-label">
                <FormattedMessage
                  id={
                    isBookend
                      ? "process > title draft script part A"
                      : "process > title draft script"
                  }
                />
              </Label>
              <div
                className={classnames(
                  bem.e("form-div"),
                  bem.e("form-div-draft-script")
                )}
              >
                {get(spot, "draft_script") ? (
                  <TextareaEvaluator
                    readOnly={true}
                    isOrderLength={false}
                    text={setScriptField(get(spot, "draft_script"))}
                    process={process}
                    onEvaluatorCallback={() => {}}
                    key={refreshTextEditor}
                  />
                ) : (
                  <span className="none-placeholder">
                    <FormattedMessage id="process > none" />
                  </span>
                )}
              </div>
            </Col>
          </Row>
          {isBookend && (
            <Row>
              <Col className={bem.e("col-field")}>
                <Label className="form-label">
                  <FormattedMessage id="process > title draft script part B" />
                </Label>
                <div
                  className={classnames(
                    bem.e("form-div"),
                    bem.e("form-div-draft-script")
                  )}
                >
                  {get(spot, "draft_script_part_b") ? (
                    <TextareaEvaluator
                      readOnly={true}
                      isOrderLength={false}
                      key={refreshTextEditor}
                      text={setScriptField(get(spot, "draft_script_part_b"))}
                      process={process}
                      onEvaluatorCallback={() => {}}
                    />
                  ) : (
                    <span className="none-placeholder">
                      <FormattedMessage id="process > none" />
                    </span>
                  )}
                </div>
              </Col>
            </Row>
          )}
        </React.Fragment>
      )}
      <Row>
        <Col className={bem.e("col-field")}>
          <Label className="form-label">
            <FormattedMessage id="process > field assets" />
          </Label>
          {renderAssets({
            isViewAssets,
            isView,
            spot,
            onValueChangedSpot,
            intl,
            spotIndex,
          })}
        </Col>
      </Row>
    </div>
  );
};
export default OrderApprovals;
