import * as React from "react";
import TemplateForm from "./TemplateForm";
import { Button } from "reactstrap";
import { FaPlus } from "react-icons/lib/fa";
import bn from "utils/bemnames";
import { FormattedMessage } from "react-intl";
const bem = bn.create("system-templates");
const CreateTemplate = (props) => {
  const isOpen = props.openSystemTemplate === "new";
  if (isOpen === false)
    return (
      <div className="d-flex align-items-center system-templates-box-create">
        <span className="guide-text-create mr-auto">
          <FormattedMessage
            id="system templates > view, edit or copy templates below or create a new template by
          clicking here"
          />
        </span>
        <Button
          className={bem.e("button-create")}
          color="blue"
          onClick={() => {
            props.setOpenSystemTemplate("new");
          }}
        >
          <FaPlus className={bem.e("icon-add")} />
          <span>
            <FormattedMessage id="system templates > create new template" />
          </span>
        </Button>
      </div>
    );
  return (
    <TemplateForm
      action="create"
      currentStation={props.currentStation}
      stations={props.stations}
      type={props.type}
      onSubmit={props.onSubmit}
      onCheckDuplicateTemplate={props.onCheckDuplicateTemplate}
      onClose={() => {
        props.setOpenSystemTemplate(null);
      }}
      shouldBlockNavigation={props.shouldBlockNavigation}
      setShouldBlockNavigation={props.setShouldBlockNavigation}
    />
  );
};
export default CreateTemplate;
