import React, { useEffect, useState } from "react";
import bn from "utils/bemnames";
import ClientDetails from "./ClientDetails";
import Notes from "./Notes";
import Documents from "./Documents";
import Buttons from "components/PromotionLinerDetails/Buttons";

const bem = bn.create("promotion-liner-client");

export default function PromotionLinerClient(props) {
  const [fieldRefs, setFieldRefs] = useState({});
  const {
    step,
    process,
    template,
    user,
    validationState,
    valueChanged,
    intl,
  } = props;
  const isView = props.mode === "view";

  const getFieldRefs = () => {
    if (Object.keys(fieldRefs).length > 0) return fieldRefs;
    const client_name = step.fields.find((f) => f.field === "client_name");
    const timezone = step.fields.find((f) => f.field === "timezone");
    const client_id = step.fields.find((f) => f.field === "client_id");
    const contract_number = step.fields.find(
      (f) => f.field === "contract_number"
    );
    const salesperson = step.fields.find((f) => f.field === "salesperson");
    const client_documents = step.fields.find(
      (f) => f.field === "client_documents"
    );
    const client_note = step.fields.find((f) => f.field === "client_note");

    let payload = {
      client_name,
      timezone,
      client_id,
      contract_number,
      salesperson,
      client_documents,
      client_note,
    };

    setFieldRefs(payload);

    return payload;
  };
  useEffect(() => {
    getFieldRefs();
  }, []);

  const onValueChanged = (field, value) => {
    valueChanged(field, process, template, user, { value });
  };

  const [
    isOpenDeleteConfirmationModal,
    setIsOpenDeleteConfirmationModal,
  ] = useState(false);

  return (
    <div className={bem.b()}>
      <ClientDetails
        bem={bem}
        process={process}
        template={template}
        step={step}
        intl={intl}
        isView={isView}
        validationState={validationState}
        onValueChanged={onValueChanged}
        fieldRefs={fieldRefs}
      />
      <Documents
        bem={bem}
        process={process}
        intl={intl}
        isView={isView}
        validationState={validationState}
        onValueChanged={onValueChanged}
        fieldRefs={fieldRefs}
      />
      <Notes
        bem={bem}
        process={process}
        intl={intl}
        isView={isView}
        validationState={validationState}
        onValueChanged={onValueChanged}
        fieldRefs={fieldRefs}
      />

      <Buttons
        buttonClicked={props.buttonClicked}
        step={step}
        process={process}
        template={template}
        user={user}
        isView={isView}
        bem={bem}
        isOpenDeleteConfirmationModal={isOpenDeleteConfirmationModal}
        onDeleteCancel={() => setIsOpenDeleteConfirmationModal(false)}
        onDeleteConfirm={() => {}}
        onValueChanged={onValueChanged}
        isShowSaveDraftButton={false}
      />
    </div>
  );
}
