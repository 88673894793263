import * as React from "react";
import PropTypes from "prop-types";
import { get, findIndex, remove, sumBy, pick } from "lodash";
import { FormattedMessage } from "react-intl";
import EmptyPrizes from "./emptyPrizes";
import PrizeCloset from "components/PrizeCloset";
import classnames from "classnames";
import { Row, Col, Button, ButtonGroup } from "reactstrap";
import AddPrizeModal from "components/AddPrizeModal";
import history from "components/History";
import { ShadowBox, SectionTitle, SectionFields } from "components/Elements";
import bn from "utils/bemnames";
import PrizeItem from "./PrizeItem/view";
const bem = bn.create("prizes-details");

class PrizeDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenQualifyingPrizes: true,
      isOpenGrandPrizes: false,
      isOpenAddModal: false,
    };
  }

  static propTypes = {
    mode: PropTypes.string,
  };

  static defaultProps = {
    mode: "edit",
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.validationState !== nextProps.validationState) {
      if (
        get(nextProps, "validationState.qualifying_prizes.validation_error")
      ) {
        this.setState({ isOpenQualifyingPrizes: true });
      }
      if (get(nextProps, "validationState.grand_prizes.validation_error")) {
        this.setState({ isOpenGrandPrizes: true });
      }
    }
  }
  onToggleAddModal = () => {
    this.setState((state) => ({ isOpenAddModal: !state.isOpenAddModal }));
  };

  onSelectPrize = (prize, section) => {
    let currentPrizes = [];
    const { process, template, user, valueChanged } = this.props;
    const { grand_prizes, qualifying_prizes } = this.getFieldRefs();
    let field;
    switch (section) {
      case "qualifying_prizes":
        currentPrizes = get(process, "data.fields.qualifying_prizes.value", []);
        field = qualifying_prizes;
        break;
      case "grand_prizes":
        currentPrizes = get(process, "data.fields.grand_prizes.value", []);
        field = grand_prizes;
        break;
      default:
        break;
    }
    const index = findIndex(
      currentPrizes,
      (item) => item.id === get(prize, "_source.id")
    );
    if (index === -1) {
      let prizes = [
        ...currentPrizes,
        {
          ...pick(prize._source, [
            "id",
            "description",
            "on_hold_quantity",
            "subtitle",
            "title",
            "fulfillment",
            "unit_value",
          ]),
          per_play: 1,
          total: get(prize, "_source.unit_value", 0),
          total_allotment: get(
            process,
            "data.fields.contest_number_of_plays.value",
            0
          ),
        },
      ];
      valueChanged(field, process, template, user, {
        value: prizes,
      });
      this.calculateTotal(prizes, section);
    }
  };

  calculateTotal = (prizes, section) => {
    const { process, template, user, valueChanged } = this.props;
    const {
      grand_prizes_total,
      qualifying_prizes_total,
      contest_prize_list,
    } = this.getFieldRefs();
    let fieldTotal;
    switch (section) {
      case "qualifying_prizes":
        fieldTotal = qualifying_prizes_total;
        break;
      case "grand_prizes":
        fieldTotal = grand_prizes_total;
        break;
      default:
        break;
    }
    // calculate section total
    let total = sumBy(prizes, (item) => Number(item.total));
    valueChanged(fieldTotal, process, template, user, {
      value: total,
    });
    // calculate grand total
    let otherTotal;
    switch (section) {
      case "qualifying_prizes":
        otherTotal = get(process, "data.fields.grand_prizes_total.value", 0);
        break;
      case "grand_prizes":
        otherTotal = get(
          process,
          "data.fields.qualifying_prizes_total.value",
          0
        );
        break;
      default:
        otherTotal = 0;
        break;
    }
    // calculate section total
    valueChanged(contest_prize_list, process, template, user, {
      value: Number(total) + Number(otherTotal),
    });
  };

  onCalculateSectionTotal = (prize, payload, section) => {
    let currentPrizes = [];
    const { process, template, user, valueChanged } = this.props;
    const { grand_prizes, qualifying_prizes } = this.getFieldRefs();
    let field;
    switch (section) {
      case "qualifying_prizes":
        currentPrizes = get(process, "data.fields.qualifying_prizes.value", []);
        field = qualifying_prizes;
        break;
      case "grand_prizes":
        currentPrizes = get(process, "data.fields.grand_prizes.value", []);
        field = grand_prizes;
        break;
      default:
        break;
    }
    const index = findIndex(currentPrizes, (item) => item.id === prize.id);

    if (index !== -1) {
      currentPrizes.splice(index, 1, {
        ...prize,
        ...payload,
        total: Number(prize.unit_value) * Number(payload.per_play),
        total_allotment:
          get(process, "data.fields.contest_number_of_plays.value", 0) *
          Number(payload.per_play),
      });
      valueChanged(field, process, template, user, {
        value: currentPrizes,
      });

      this.calculateTotal(currentPrizes, section);
    }
  };

  onDeleteItemSection = (prize, section) => {
    let currentPrizes = [];
    const { process, template, user, valueChanged } = this.props;
    const { grand_prizes, qualifying_prizes } = this.getFieldRefs();
    let field;
    switch (section) {
      case "qualifying_prizes":
        currentPrizes = get(process, "data.fields.qualifying_prizes.value", []);
        field = qualifying_prizes;
        break;
      case "grand_prizes":
        currentPrizes = get(process, "data.fields.grand_prizes.value", []);
        field = grand_prizes;
        break;
      default:
        break;
    }
    remove(currentPrizes, (item) => item.id === prize.id);
    valueChanged(field, process, template, user, {
      value: currentPrizes,
    });
    this.calculateTotal(currentPrizes, section);
  };

  getFieldRefs() {
    if (this.state.fieldRefs) return this.state.fieldRefs;
    let { template, step } = this.props;
    step = template.steps.prizes;
    let contest_prize_list = step.fields.find(
      (f) => f.field === "contest_prize_list"
    );
    let qualifying_prizes_total = step.fields.find(
      (f) => f.field === "qualifying_prizes_total"
    );
    let grand_prizes_total = step.fields.find(
      (f) => f.field === "grand_prizes_total"
    );
    let qualifying_prizes = step.fields.find(
      (f) => f.field === "qualifying_prizes"
    );
    let grand_prizes = step.fields.find((f) => f.field === "grand_prizes");
    let payload = {
      contest_prize_list,
      qualifying_prizes_total,
      grand_prizes_total,
      qualifying_prizes,
      grand_prizes,
    };
    this.setState({ fieldRefs: payload });
    return payload;
  }

  renderForm() {
    let { process, template, user, step } = this.props;

    let {
      contest_prize_list,
      qualifying_prizes_total,
      grand_prizes_total,
    } = this.getFieldRefs();

    let grandPrizes = get(process, "data.fields.grand_prizes.value", []);
    let qualifyingPrizes = get(
      process,
      "data.fields.qualifying_prizes.value",
      []
    );
    let qualifyingPrizesTotal = get(
      process,
      "data.fields.qualifying_prizes_total.value"
    );
    let grandPrizesTotal = get(process, "data.fields.grand_prizes_total.value");
    let contestPrizeList = get(process, "data.fields.contest_prize_list.value");
    const reqQuantity = get(
      process,
      "data.fields.contest_number_of_plays.value",
      0
    );
    return (
      <div className="text-left">
        <div>
          <ShadowBox className="promotion-shadow-box promotion-section-disabled">
            <SectionTitle className="promotion-section-title">
              <FormattedMessage id="process > title contest prize list" />
              <div className={bem.e("total-wrapper")}>
                {contest_prize_list.title}
                <span className={bem.e("total-value")}>
                  ${get(process, "data.fields.contest_prize_list.value", 0)}
                </span>
              </div>
            </SectionTitle>
          </ShadowBox>

          <ShadowBox className="promotion-shadow-box">
            <SectionTitle
              className="promotion-section-title"
              onClick={() =>
                this.setState({
                  isOpenQualifyingPrizes: !this.state.isOpenQualifyingPrizes,
                })
              }
            >
              <FormattedMessage id="process > title qualifying prizes" />
              <div className={bem.e("total-wrapper")}>
                {qualifying_prizes_total.title}
                <span className={bem.e("total-value")}>
                  $
                  {get(process, "data.fields.qualifying_prizes_total.value", 0)}
                </span>
              </div>
            </SectionTitle>
            <SectionFields
              className={!this.state.isOpenQualifyingPrizes && "hidden"}
            >
              <Row>
                <Col>
                  {qualifyingPrizes.length === 0 ? (
                    <EmptyPrizes />
                  ) : (
                    <div className={bem.e("prizes-list")}>
                      {qualifyingPrizes.map((prize, index) => (
                        <PrizeItem
                          key={index}
                          prize={prize}
                          process={process}
                          onDelete={(item) =>
                            this.onDeleteItemSection(item, "qualifying_prizes")
                          }
                          onChange={(prize, payload) =>
                            this.onCalculateSectionTotal(
                              prize,
                              payload,
                              "qualifying_prizes"
                            )
                          }
                          mode={this.props.mode}
                        />
                      ))}
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <PrizeCloset
                    onSelectItem={(item) =>
                      this.onSelectPrize(item, "qualifying_prizes")
                    }
                    process={process}
                    filter={"qualifying_prizes_list"}
                    onToggleAddModal={this.onToggleAddModal}
                    mode={this.props.mode}
                    reqQuantity={reqQuantity}
                  />
                </Col>
              </Row>
            </SectionFields>
          </ShadowBox>

          <ShadowBox className="promotion-shadow-box">
            <SectionTitle
              className="promotion-section-title"
              onClick={() =>
                this.setState({
                  isOpenGrandPrizes: !this.state.isOpenGrandPrizes,
                })
              }
            >
              <FormattedMessage id="process > title grand prizes" />
              <div className={bem.e("total-wrapper")}>
                {grand_prizes_total.title}
                <span className={bem.e("total-value")}>
                  ${get(process, "data.fields.grand_prizes_total.value", 0)}
                </span>
              </div>
            </SectionTitle>
            <SectionFields
              className={!this.state.isOpenGrandPrizes && "hidden"}
            >
              <Row>
                <Col>
                  {grandPrizes.length === 0 ? (
                    <EmptyPrizes />
                  ) : (
                    <div className={bem.e("prizes-list")}>
                      {grandPrizes.map((prize, index) => (
                        <PrizeItem
                          key={index}
                          prize={prize}
                          process={process}
                          mode={this.props.mode}
                          onDelete={(item) =>
                            this.onDeleteItemSection(item, "grand_prizes")
                          }
                          onChange={(prize, payload) =>
                            this.onCalculateSectionTotal(
                              prize,
                              payload,
                              "grand_prizes"
                            )
                          }
                        />
                      ))}
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <PrizeCloset
                    onSelectItem={(item) =>
                      this.onSelectPrize(item, "grand_prizes")
                    }
                    mode={this.props.mode}
                    process={process}
                    filter={"grand_prizes_list"}
                    onToggleAddModal={this.onToggleAddModal}
                    reqQuantity={reqQuantity}
                  />
                </Col>
              </Row>
            </SectionFields>
          </ShadowBox>
          <Row>
            <Col>
              <ButtonGroup>
                <Button
                  block
                  color="blue"
                  disabled={
                    (grandPrizes.length === 0 &&
                      qualifyingPrizes.length === 0) ||
                    (!qualifyingPrizesTotal && !grandPrizesTotal) ||
                    !contestPrizeList
                  }
                  className={classnames(bem.e("button"), {
                    [bem.e("button-gray")]:
                      grandPrizes.length === 0 && qualifyingPrizes.length === 0,
                  })}
                  onClick={this.props.buttonClicked.bind(
                    this,
                    step,
                    step.fields.find((f) => f.key === "save_button"),
                    process,
                    template,
                    user
                  )}
                >
                  <FormattedMessage id="process > button publish" />
                </Button>
                <Button
                  block
                  color="blue"
                  outline
                  className={bem.e("button")}
                  onClick={this.props.buttonClicked.bind(
                    this,
                    step,
                    {
                      ...step.fields.find((f) => f.key === "save_button"),
                      draft: true,
                    },
                    process,
                    template,
                    user
                  )}
                >
                  <FormattedMessage id="process > button save draft" />
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  renderView() {
    let { process, template, step } = this.props;

    let {
      contest_prize_list,
      qualifying_prizes_total,
      grand_prizes_total,
    } = this.getFieldRefs();

    let grandPrizes = get(process, "data.fields.grand_prizes.value", []);
    let qualifyingPrizes = get(
      process,
      "data.fields.qualifying_prizes.value",
      []
    );
    return (
      <div className="text-left">
        <div>
          <ShadowBox className="promotion-shadow-box promotion-section-disabled">
            <SectionTitle className="promotion-section-title">
              <FormattedMessage id="process > title contest prize list" />
              <div className={bem.e("total-wrapper")}>
                {contest_prize_list.title}
                <span className={bem.e("total-value")}>
                  ${get(process, "data.fields.contest_prize_list.value", 0)}
                </span>
              </div>
            </SectionTitle>
          </ShadowBox>

          <ShadowBox className="promotion-shadow-box">
            <SectionTitle
              className="promotion-section-title"
              onClick={() =>
                this.setState({
                  isOpenQualifyingPrizes: !this.state.isOpenQualifyingPrizes,
                })
              }
            >
              <FormattedMessage id="process > title qualifying prizes" />
              <div className={bem.e("total-wrapper")}>
                {qualifying_prizes_total.title}
                <span className={bem.e("total-value")}>
                  $
                  {get(process, "data.fields.qualifying_prizes_total.value", 0)}
                </span>
              </div>
            </SectionTitle>
            <SectionFields
              className={!this.state.isOpenQualifyingPrizes && "hidden"}
            >
              <Row>
                <Col>
                  {qualifyingPrizes.length === 0 ? (
                    <EmptyPrizes />
                  ) : (
                    <div className={bem.e("prizes-list")}>
                      {qualifyingPrizes.map((prize, index) => (
                        <PrizeItem
                          key={index}
                          prize={prize}
                          process={process}
                          mode={this.props.mode}
                        />
                      ))}
                    </div>
                  )}
                </Col>
              </Row>
            </SectionFields>
          </ShadowBox>

          <ShadowBox className="promotion-shadow-box">
            <SectionTitle
              className="promotion-section-title"
              onClick={() =>
                this.setState({
                  isOpenGrandPrizes: !this.state.isOpenGrandPrizes,
                })
              }
            >
              <FormattedMessage id="process > title grand prizes" />
              <div className={bem.e("total-wrapper")}>
                {grand_prizes_total.title}
                <span className={bem.e("total-value")}>
                  ${get(process, "data.fields.grand_prizes_total.value", 0)}
                </span>
              </div>
            </SectionTitle>
            <SectionFields
              className={!this.state.isOpenGrandPrizes && "hidden"}
            >
              <Row>
                <Col>
                  {grandPrizes.length === 0 ? (
                    <EmptyPrizes />
                  ) : (
                    <div className={bem.e("prizes-list")}>
                      {grandPrizes.map((prize, index) => (
                        <PrizeItem
                          key={index}
                          prize={prize}
                          process={process}
                          mode={this.props.mode}
                        />
                      ))}
                    </div>
                  )}
                </Col>
              </Row>
            </SectionFields>
          </ShadowBox>
          <Row>
            <Col>
              <ButtonGroup>
                <Button
                  block
                  color="blue"
                  style={{
                    borderRadius: 30,
                    maxWidth: "180px",
                    minWidth: "95px",
                  }}
                  disabled={this.props.mode === "edit"}
                  onClick={() => {
                    this.props.forgetPrizes({
                      keys: [
                        "filter_grand_prizes_list",
                        "filter_qualifying_prizes_list",
                      ],
                    });
                    history.push(
                      "/processes/edit/" + template.key + "/" + process.key
                    );
                    this.props.setActiveEditTab(step.key);
                  }}
                >
                  <FormattedMessage id="process > button edit" />
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  render() {
    let { process } = this.props;
    const { isOpenAddModal } = this.state;
    if (!process.data.fields || Object.keys(process.data.fields).length === 0)
      return null;

    let form = null;
    if (this.props.mode === "edit") {
      form = this.renderForm();
    } else {
      form = this.renderView();
    }
    return (
      <>
        <div className={bem.b()}>{form}</div>
        <AddPrizeModal
          isOpen={isOpenAddModal}
          onToggle={this.onToggleAddModal}
        />
      </>
    );
  }
}

export default PrizeDetails;
