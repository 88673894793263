import * as React from "react";
import PropTypes from "prop-types";
import { default as BaseCheckbox } from "rc-checkbox";
import bn from "utils/bemnames";
import { CheckboxIcon } from "components/CustomIcons";
import { get, isString } from "lodash";

const bem = bn.create("checkbox");

class Checkbox extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    text: PropTypes.any,
    value: PropTypes.any,
  };

  static defaultProps = {
    onChange: () => {},
    checked: false,
    disabled: false,
    text: "",
    color: "blue",
  };
  render() {
    const {
      onChange,
      text,
      checked,
      value,
      disabled,
      checkStyle,
      disableSelectOnTextClick,
    } = this.props;
    return (
      <div className={bem.b()} style={this.props.style}>
        <div className={bem.e("checkbox")}>
          <div className={bem.e("base-checkbox")}>
            <BaseCheckbox
              checked={checked}
              className={bem.e(`checkbox-${this.props.color}`)}
              onChange={(e) => {
                onChange(e.target.checked);
              }}
              disabled={disabled}
              value={value}
            />
            {checked ? (
              <span className={bem.e("checked-icon")}>
                <CheckboxIcon
                  color={get(checkStyle, "color", "#fff")}
                  width={get(checkStyle, "width", "17")}
                  height={get(checkStyle, "height", "16")}
                />
              </span>
            ) : null}
          </div>

          {!!text ? (
            <div
              className={bem.e("text")}
              onClick={() =>
                disabled || disableSelectOnTextClick ? "" : onChange(!checked)
              }
            >
              {isString(text) ? (
                <span dangerouslySetInnerHTML={{ __html: text }}></span>
              ) : (
                text
              )}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default Checkbox;
