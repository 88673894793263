import React from "react";
import { get } from "lodash";
import { FaSearch, FaPlus } from "react-icons/lib/fa";
import { Row, Col, ButtonGroup, Button, Card, CardBody } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { MdClose } from "react-icons/lib/md";
import bn from "utils/bemnames";
import classnames from "classnames";

import TextInput from "components/TextInput";
import Table from "components/Table";
import ConfirmationModal from "components/ConfirmationModal";
import PrivilegedComponent from "components/PrivilegedComponent";
import { PRIVILEGES } from "utils/constants";
import Spinner from "components/Spinner";
import { EditIcon, TrashListIcon } from "components/CustomIcons";
// import ViewTeamModal from "./ViewTeamModal";
// import TeamWarningModal from "./TeamWarningModal";
import useInfiniteScroll from "utils/useInfiniteScroll";
import CreateHubModal from "./CreateHubModal";
import { hubTypes } from "utils/config";

const bem = bn.create("market-list");

const HubsList = (props) => {
  const {
    intl,
    filtered,
    sorted,
    setSorted,
    hubs,
    searchText,
    onChangeInput,
    onSearchKeyDown,
    onSearchClicked,
    onClearSearch,
    isCreateModalOpen,
    onToggleCreateModal,
    isDeleteModalOpen,
    onDelete,
    openDeleteModal,
    closeDeleteModal,
    // onSelectedView,
    isLazyLoading,
    onLoadMore,
    isFocused,
    isLoading,
    onSelectedEdit, // from Admins component
    isWarningDeleteModalOpen,
    setIsWarningDeleteModalOpen,
  } = props;

  const hubList = get(hubs, "data.data") || [];
  const total = get(hubs, "data.total") || 1;

  useInfiniteScroll({
    useWindow: false,
    elementId: "cr-app-main",
    onLoadMore: onLoadMore,
    shouldLoadMore:
      isFocused &&
      !isLazyLoading &&
      get(hubs, "data.last_page") !== get(hubs, "data.current_page"),
  });

  return (
    <Row className={bem.b()}>
      <Col>
        <Card>
          <CardBody>
            <Row>
              <Col xs="12">
                <div className={bem.e("head-wrapper")}>
                  <PrivilegedComponent requires={[PRIVILEGES.ADD_TEAMS]}>
                    <Button
                      color="blue"
                      outline
                      onClick={() => onToggleCreateModal(true)}
                      className={classnames(
                        "btn-radius",
                        bem.e("button-create")
                      )}
                    >
                      <FaPlus color="#795AFA" size={20} />
                      <FormattedMessage id="hubs > create new hub" />
                    </Button>
                  </PrivilegedComponent>
                  <TextInput
                    type="text"
                    name="q"
                    value={searchText}
                    onChange={onChangeInput}
                    onKeyDown={onSearchKeyDown}
                    placeholder={props.intl.formatMessage({
                      id: "hubs > placeholder search",
                    })}
                    rightComponent={
                      <div className="search-buttons">
                        {searchText && (
                          <div
                            onClick={onClearSearch}
                            className={"button-search-close"}
                          >
                            <MdClose />
                          </div>
                        )}
                        <Button color="link" className="btn-search">
                          <FaSearch size={17} onClick={onSearchClicked} />
                        </Button>
                      </div>
                    }
                  />
                </div>
              </Col>
            </Row>
            <Table
              className="table-hidden-checkbox"
              manual
              resizable={false}
              data={hubList}
              loading={hubs.loading}
              showPagination={false}
              total={total}
              filtered={filtered}
              sorted={sorted}
              onSortedChange={(sorted) => {
                setSorted(sorted);
              }}
              columns={[
                {
                  Header: (
                    <div
                      className={classnames(
                        bem.e("table-head-title"),
                        "sortable"
                      )}
                    >
                      <FormattedMessage id="hubs > hub name" />
                    </div>
                  ),
                  accessor: "name",
                  Cell: ({ original: hub }) => {
                    const hubType = hubTypes.find(
                      (item) => item.value === hub.team_type
                    );
                    return (
                      <div>
                        <span className={bem.e("hub-name")}>{hub.name}</span>
                        {hubType ? (
                          <span className={bem.e("hub-type")}>
                            {intl.formatMessage({
                              id: `hubs > type ${hubType.label}`,
                            })}
                          </span>
                        ) : null}
                      </div>
                    );
                  },
                },
                {
                  sortable: false,
                  resizable: false,
                  filterable: false,
                  style: { justifyContent: "center" },
                  Header: (
                    <div className={bem.e("table-head-center")}>
                      <FormattedMessage id="hubs > total entities" />
                    </div>
                  ),
                  accessor: "name",
                  Cell: ({ original: hub }) => {
                    return <span>{hub.total_entities || 0}</span>;
                  },
                },
                {
                  sortable: false,
                  resizable: false,
                  filterable: false,
                  style: { justifyContent: "center" },
                  Header: (
                    <div className={bem.e("table-head-center")}>
                      <FormattedMessage id="hubs > team members" />
                    </div>
                  ),
                  accessor: "name",
                  Cell: ({ original: hub }) => {
                    return <span>{hub.team_members_count || 0}</span>;
                  },
                },
                {
                  Header: (
                    <div
                      className={classnames(
                        bem.e("table-head-title"),
                        bem.e("table-head-title-last")
                      )}
                    >
                      <FormattedMessage id="hubs > actions" />
                    </div>
                  ),
                  sortable: false,
                  resizable: false,
                  filterable: false,
                  style: { justifyContent: "center" },
                  Cell: ({ original: hub }) => {
                    const isDisabledDelete =
                      hub.active_orders && hub.active_orders > 0 ? true : false;
                    return (
                      <ButtonGroup>
                        <PrivilegedComponent requires={[PRIVILEGES.EDIT_TEAMS]}>
                          <Button
                            color="link"
                            onClick={() => onSelectedEdit(hub.id)}
                          >
                            <EditIcon />
                          </Button>
                        </PrivilegedComponent>
                        <PrivilegedComponent
                          requires={[PRIVILEGES.DELETE_TEAMS]}
                        >
                          <Button
                            color="link"
                            onClick={() => {
                              if (isDisabledDelete) {
                                setIsWarningDeleteModalOpen(true);
                              } else {
                                openDeleteModal(hub.id);
                              }
                            }}
                          >
                            <TrashListIcon
                              color={isDisabledDelete ? "#829FB1" : "#795AFA"}
                            />
                          </Button>
                        </PrivilegedComponent>
                      </ButtonGroup>
                    );
                  },
                },
              ]}
            />
          </CardBody>
        </Card>
        <ConfirmationModal
          className={"delete-hub-modal"}
          isOpen={isDeleteModalOpen}
          title=""
          description={intl.formatMessage({
            id: "hubs > are you sure you want to delete this hub",
          })}
          onConfirm={onDelete}
          onToggle={closeDeleteModal}
          onCancel={closeDeleteModal}
          cancelTitle={intl.formatMessage({ id: "hubs > button cancel" })}
          isCloseOutside={false}
        />
        <ConfirmationModal
          className={"warning-delete-hub-modal"}
          isOpen={isWarningDeleteModalOpen}
          title={intl.formatMessage({
            id: "hubs > you cannot delete this hub",
          })}
          description={intl.formatMessage({
            id: "hubs > description warning delete hub",
          })}
          onToggle={() =>
            setIsWarningDeleteModalOpen(!isWarningDeleteModalOpen)
          }
          onCancel={() => setIsWarningDeleteModalOpen(false)}
          cancelTitle={intl.formatMessage({ id: "hubs > button cancel" })}
          isCloseOutside={false}
        />
        <CreateHubModal
          isOpen={isCreateModalOpen}
          onToggle={onToggleCreateModal}
        />
        <Spinner isLoading={isLoading} />
      </Col>
    </Row>
  );
};

export default HubsList;
