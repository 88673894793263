import * as React from "react";
import { compose, withState, lifecycle, withHandlers } from "recompose";
import { connect } from "react-redux";
import { get, forOwn, map } from "lodash";
import View from "./view";
import { injectIntl } from "react-intl";
import ToastManager from "components/ToastManager";
import { MdImportantDevices } from "react-icons/lib/md";
import {
  getStatesForCountries,
  getCounties,
  getStationSettings,
  updateStationSettings,
} from "store/actions/stations";

export default injectIntl(
  compose(
    connect(
      ({ auth, servers, ...rest }) => {
        return {
          auth,
          currentServer: get(servers, "currentServer.data", {}),
          loading: get(rest, "stations.stationSettings.loading", false),
          stationSettingsData: get(rest, "stations.stationSettings.data", null),
        };
      },
      {
        getStatesForCountries,
        getCounties,
        getStationSettings,
        updateStationSettings,
      }
    ),
    withState("optionStates", "setOptionStates", []),
    withState("optionCounties", "setOptionCounties", []),
    withHandlers({
      onGetStatesByCountries: ({
        getStatesForCountries,
        setOptionStates,
      }) => async (countries, cb) => {
        if (countries.length === 0) {
          setOptionStates([]);
          return;
        }
        const params = {
          country_codes: countries,
        };
        getStatesForCountries(params, (response) => {
          const optionStates = map(response || [], (item) => ({
            label: item,
            value: item,
          }));
          setOptionStates(optionStates);
          if (cb) cb(optionStates);
        });
      },
      onGetCounties: ({ getCounties, setOptionCounties }) => async (
        states,
        cb
      ) => {
        if (states.length === 0) {
          setOptionCounties([]);
          return;
        }
        const params = {
          states,
        };
        getCounties(params, (response) => {
          let optionCounties = [];
          forOwn(response, (group, key) => {
            const groupOption = {
              label: key,
              value: key,
              options: map(group, (item) => ({
                label: item.county,
                value: item.county,
              })),
            };
            optionCounties.push(groupOption);
          });
          setOptionCounties(optionCounties);
          if (cb) cb(optionCounties);
        });
      },
      onFormSubmit: ({
        updateStationSettings,
        intl,
        setShouldBlockNavigation,
      }) => (values) => {
        updateStationSettings(values, () => {
          ToastManager.show({
            title: <MdImportantDevices />,
            message: intl.formatMessage({
              id: "form manager > update successfully",
            }),
            level: "success",
          });
          setShouldBlockNavigation(false);
        });
      },
    }),
    withHandlers({
      onGetStationSettings: ({
        currentStation,
        getStationSettings,
        currentServer,
        onGetStatesByCountries,
        onGetCounties,
      }) => () => {
        getStationSettings(
          {
            station_id: currentStation.key,
          },
          (data) => {
            const countries = get(data, "_source.listener_countries", []);
            const states = get(data, "_source.states", []);

            const restrictToHomeCountry = get(
              currentServer,
              "restrict_to_home_country",
              false
            );
            if (restrictToHomeCountry) {
              onGetStatesByCountries([currentServer.country]);
            } else {
              onGetStatesByCountries(countries);
            }
            onGetCounties(states);
          }
        );
      },
    }),
    lifecycle({
      componentDidMount() {
        const { onGetStationSettings } = this.props;
        onGetStationSettings();
      },
      componentDidUpdate(prevProps) {
        const isStationChanged =
          this.props.currentStation &&
          this.props.currentStation.key !== prevProps.currentStation.key;
        if (isStationChanged) {
          this.props.onGetStationSettings();
        }
      },
    })
  )(View)
);
