import { compose } from "recompose";
import View from "./view";
import { injectIntl } from "react-intl";
import { withState, withHandlers, withPropsOnChange } from "recompose";

const CalendarInline = compose(
  withState("clockData", "setClockData", null),
  withState("intervalData", "setIntervalData", null),
  withHandlers({
    stop: ({ intervalData, setIntervalData }) => async () => {
      clearInterval(intervalData);
      setIntervalData(null);
    },
  }),
  withPropsOnChange(
    ["selectedDate"],
    ({ setClockData, intervalData, stop, setIntervalData }) => {
      if (intervalData) {
        stop();
      }
      const interval = setInterval(() => {
        setClockData(new Date());
      }, 1000);
      setIntervalData(interval);
    }
  )
)(View);
export default injectIntl(CalendarInline);
