import React, { useEffect, useState } from "react";
import bn from "utils/bemnames";
import { Views } from "react-big-calendar";
import moment from "moment";
import PropTypes from "prop-types";
import CalendarWeekSticky from "../CalendarWeekSticky";
import CalendarContent from "../CalendarContent";
import ScheduleFilter from "../ScheduleFilter";
import ScheduleFilterBottom from "../ScheduleFilterBottom";
import { get, first, last } from "lodash";
import Spinner from "components/Spinner";
import { countdownWithTimezone } from "utils/helpers";
import ConfirmationModal from "components/ConfirmationModal";
import { FormattedMessage } from "react-intl";
const bem = bn.create("schedule-calendar");
const weekdaysConfig = {
  "Sunday-Saturday": Views.WEEK,
  "Monday-Sunday": Views.WEEK,
  "Monday-Friday": Views.WORK_WEEK,
};
const formatDate = "YYYY-MM-DD";
export default function Calendar(props) {
  const {
    intl,
    setScheduleCalendarFilter,
    scheduleCalendarFilter,
    setIsSidebarOpen,
    onGetDataByRangeDates,
    onSelectItemTargetSwap,
    onChangeSelectedStation,
    clocksData,
    selectedItems,
    lang,
    schedules,
    loading,
    onSwapItem,
    onViewItem,
    onDeleteItem,
    isScheduleTab,
    onSelectItemSwap,
    onUndoSwappedItem,
    swappableSlots,
    sameTypeSlots,
    isOnAirSchedule,
    fillSettings,
  } = props;
  if (!clocksData) return <Spinner isLoading={loading} />;
  const definitions = isOnAirSchedule
    ? [
        ...get(clocksData, "definitions", []),
        ...get(clocksData, "contest_types", []),
      ]
    : get(clocksData, "definitions", []);
  const [view, setView] = useState(
    get(weekdaysConfig, scheduleCalendarFilter.weekdays, Views.WORK_WEEK)
  );
  const [rangeDates, setRangeDates] = useState([]);
  const currentDate = get(
    scheduleCalendarFilter,
    "current_date",
    moment().format(formatDate)
  );
  const selectedItem = get(scheduleCalendarFilter, "selectedItem", {});
  const selectedItemSwap = get(scheduleCalendarFilter, "selectedItemSwap", {});
  const selectedItemTargetSwap = get(
    scheduleCalendarFilter,
    "selectedItemTargetSwap",
    {}
  );
  const weekStart =
    get(scheduleCalendarFilter, "weekdays") === "Sunday-Saturday" ? 0 : 1;
  const calendarWeekdays = get(scheduleCalendarFilter, "weekdays");
  useEffect(() => {
    // auto fetch items when current date change.
    moment.locale(lang, {
      week: {
        dow: weekStart, //Monday = 1 or Sunday = 0 is the first day of the week.
      },
    });

    let startDate = moment(currentDate).startOf("week");
    let endDate;
    if (view === Views.WORK_WEEK) {
      endDate = moment(currentDate)
        .endOf("week")
        .subtract(2, "days");
    } else {
      endDate = moment(currentDate).endOf("week");
    }
    const startDateFormatted = startDate.format(formatDate);
    const endDateFormatted = endDate.format(formatDate);
    let newRangeDates = [];
    while (startDate.isSameOrBefore(endDate, "day")) {
      if (view === Views.WORK_WEEK) {
        if ([6, 0].indexOf(parseInt(startDate.format("d"))) === -1) {
          newRangeDates.push(startDate.toDate());
        }
      } else {
        newRangeDates.push(startDate.toDate());
      }
      startDate.add(1, "day");
    }

    setRangeDates(newRangeDates);
    //to call apis first time.
    onGetDataByRangeDates({
      start_date: startDateFormatted,
      end_date: endDateFormatted,
    });
  }, [view]);
  useEffect(() => {
    const newView = get(
      weekdaysConfig,
      scheduleCalendarFilter.weekdays,
      Views.WORK_WEEK
    );
    setView(newView);
    // Whenever 7-day view is displayed, left side nav collapses or Click on Elements Schedule , left navigation bar should be collapsed.
    if (newView === Views.WEEK || !isScheduleTab) setIsSidebarOpen(false);
  }, [scheduleCalendarFilter.weekdays, scheduleCalendarFilter.station_id]);
  const setSelectedItem = (item) => {
    setScheduleCalendarFilter({
      ...scheduleCalendarFilter,
      selectedItem: item
        ? {
            ...item,
            type: `${item.type_name} - ${item.type_label}`,
          }
        : {},
    });
  };
  // After clicking enter, elements containing the keyword are highlighted in the schedule. Schedule moves to the first week the element appears if it’s in the future.
  const onSearchSelectItem = (value, item) => {
    let calendarDate = scheduleCalendarFilter.current_date;
    const orderStartDate = get(item, "order_start_date");
    const timeData = countdownWithTimezone(orderStartDate); // default get current timezone of user browser
    if (!get(timeData, "isNegative", false) && item) {
      calendarDate = orderStartDate;
    }
    let weekdays = scheduleCalendarFilter.weekdays
      ? scheduleCalendarFilter.weekdays
      : "Monday-Friday";
    const calendarDateObj = moment(calendarDate);
    const dayOfWeek = calendarDateObj.day();

    if (weekdays === "Monday-Friday") {
      const isWeekend = dayOfWeek === 6 || dayOfWeek === 0; // 6 = Saturday, 0 = Sunday
      if (isWeekend) {
        // + 1 or 2 day to move next week for calendar view
        const newCalendarDateObj = calendarDateObj.add(
          dayOfWeek === 6 ? 2 : 1,
          "days"
        );
        calendarDate = moment(newCalendarDateObj).format(formatDate);
      }
    }
    setScheduleCalendarFilter({
      ...scheduleCalendarFilter,
      current_date: calendarDate,
      search: value,
      selectedItem: item,
    });
  };
  return (
    <div className={bem.b()}>
      <ScheduleFilter
        intl={intl}
        values={scheduleCalendarFilter}
        definitions={definitions}
        onChange={setScheduleCalendarFilter}
        onChangeSelectedStation={onChangeSelectedStation}
        onSearchSelectItem={onSearchSelectItem}
        startWeekOn={get(clocksData, "start_week_on", "monday")}
        isScheduleSettingsModalOpen={props.isScheduleSettingsModalOpen}
        setIsScheduleSettingsModalOpen={props.setIsScheduleSettingsModalOpen}
        onSubmitScheduleSettings={props.onSubmitScheduleSettings}
        {...get(props, "filterProps", {})}
      />
      <CalendarWeekSticky
        onRangeChange={(dates) => {
          const newCurrentDate = moment(first(dates)).format(formatDate);
          const startDate = moment(first(dates)).format(formatDate);
          const endDate = moment(last(dates)).format(formatDate);
          const filter = {
            ...scheduleCalendarFilter,
            current_date: moment(newCurrentDate).format(formatDate),
            start_date: startDate,
            end_date: endDate,
          };
          setRangeDates(dates);
          setScheduleCalendarFilter(filter);
          onGetDataByRangeDates({
            start_date: startDate,
            end_date: endDate,
          });
        }}
        currentDate={moment(currentDate, formatDate).toDate()}
        view={view}
        weekdays={calendarWeekdays}
        onView={setView}
        lang={lang}
        weekStart={weekStart}
        intl={intl}
        onAddFillItems={props.onAddFillItems}
        fillSettings={fillSettings}
        // props for statement
        statementType={props.statementType}
        selectedItemSwap={selectedItemSwap}
        onSelectItemSwap={onSelectItemSwap}
        onUndoSwappedItem={onUndoSwappedItem}
        selectedItem={selectedItem}
        setStatementType={props.setStatementType}
        setSelectedItem={setSelectedItem}
        itemSwapped={props.itemSwapped}
        onUndoDeletedItem={props.onUndoDeletedItem}
        onUndoAddFillItems={props.onUndoAddFillItems}
      />
      <CalendarContent
        scheduleCalendarFilter={scheduleCalendarFilter}
        rangeDates={rangeDates}
        selectedItems={selectedItems}
        selectedItem={selectedItem}
        selectedItemSwap={selectedItemSwap}
        selectedItemTargetSwap={selectedItemTargetSwap}
        setSelectedItem={setSelectedItem}
        view={view}
        chunkNumber={5}
        items={schedules}
        onSwapItem={onSwapItem}
        onViewItem={onViewItem}
        onDeleteItem={onDeleteItem}
        setStatementType={props.setStatementType}
        statementType={props.statementType}
        isScheduleTab={isScheduleTab}
        onSelectItemSwap={onSelectItemSwap}
        onSelectItemTargetSwap={onSelectItemTargetSwap}
        onUndoSwappedItem={onUndoSwappedItem}
        clocksData={clocksData}
        swappableSlots={swappableSlots}
        sameTypeSlots={sameTypeSlots}
      />

      <ScheduleFilterBottom
        values={scheduleCalendarFilter}
        definitions={definitions}
        onChange={setScheduleCalendarFilter}
        isScheduleSettingsModalOpen={props.isScheduleSettingsModalOpen}
        setIsScheduleSettingsModalOpen={props.setIsScheduleSettingsModalOpen}
        onSubmitScheduleSettings={props.onSubmitScheduleSettings}
        {...get(props, "filterProps", {})}
      />
      <ConfirmationModal
        isOpen={props.isSuccessModalOpen}
        title={props.successMessage}
        state="success"
        onToggle={() => props.setIsSuccessModalOpen(!props.isSuccessModalOpen)}
        onCancel={() => props.setIsSuccessModalOpen(false)}
      />
      <ConfirmationModal
        isCloseOutside={false}
        isOpen={props.isOpenConfirmDeleteModal}
        title={
          <FormattedMessage id="process > are you sure you want to delete" />
        }
        onToggle={() =>
          props.setIsOpenConfirmDeleteModal(!props.isOpenConfirmDeleteModal)
        }
        onConfirm={props.onConfirmDeleteItem}
        onCancel={() => props.setIsOpenConfirmDeleteModal(false)}
      />
      <Spinner isLoading={loading} />
    </div>
  );
}
Calendar.defaultProps = {
  selectedItems: [],
  isScheduleTab: false,
  filterProps: {},
  isOnAirSchedule: false,
};
Calendar.propTypes = {
  selectedItems: PropTypes.array,
  isScheduleTab: PropTypes.bool,
  filterProps: PropTypes.object,
  isOnAirSchedule: PropTypes.bool,
};
