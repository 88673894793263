import React from "react";
import PropTypes from "prop-types";
import bn from "utils/bemnames";
import { get, find, includes, concat } from "lodash";
import { Row, Col, FormGroup, Label, Button } from "reactstrap";
import SimpleLineText from "components/FormBuilder/SimpleLineText";
import EmailAddress from "components/FormBuilder/EmailAddress";
import PhoneNumber from "components/FormBuilder/PhoneNumber";
import HandleAssets from "components/HandleAssets";
import TextInput from "components/TextInput";
import ToastManager from "components/ToastManager";
import UploadFiles from "components/UploadFiles";
import URLField from "components/FormBuilder/URL";
import Media from "components/FormBuilder/Media";
import { Asterisk } from "components/Elements";
import BasicFileUpload from "components/FormBuilder/BasicFileUpload";
import NumberOfImpression from "components/FormBuilder/NumberOfImpression";
import StreetAddress from "components/FormBuilder/StreetAddress";
import GeoTargeting from "components/FormBuilder/GeoTargeting";
import { default as DropdownFormBuilder } from "components/FormBuilder/Dropdown";
import { default as CheckboxFormBuilder } from "components/FormBuilder/Checkbox";
import { default as RadioFormBuilder } from "components/FormBuilder/Radio";
import Demographics from "components/FormBuilder/Demographics";
import TextArea from "components/FormBuilder/TextArea";
import { FormattedMessage } from "react-intl";
import {
  labelToNameField,
  getFormFieldsWithConditionLogic,
} from "utils/helpers";
import classnames from "classnames";
import { DownloadFileIcon, CopyIcon } from "components/CustomIcons";
import DateSelector from "components/FormBuilder/DateSelector";
const bem = bn.create("custom-form-traffic");

const renderCustomFieldsView = (
  fields,
  channel,
  blackList = [],
  whiteList = [],
  props = {}
) => {
  let newFields = fields;
  if (!newFields.length) return null;
  if (blackList.length > 0) {
    const blackListKeys = blackList.map((item) => item.key);
    newFields = newFields.filter(
      (field) => !includes(blackListKeys, field.key)
    );
  }
  if (whiteList.length > 0) {
    const whiteListKeys = whiteList.map((item) => item.key);
    newFields = newFields.filter((field) => includes(whiteListKeys, field.key));
  }
  return newFields.map((field, index) => {
    const { key } = field;
    const fieldName = labelToNameField(field.label);
    const fieldProps = {
      disabled: true,
      key: index,
      field,
      value: get(channel, fieldName, ""),
      isFormBuilder: false,
      isView: true,
    };
    switch (key) {
      case "single_line_text":
        return <SimpleLineText {...fieldProps} />;
      case "textarea":
        return <TextArea {...fieldProps} />;
      case "dropdown":
        return <DropdownFormBuilder {...fieldProps} />;
      case "checkbox":
        return <CheckboxFormBuilder {...fieldProps} />;
      case "radio":
        return <RadioFormBuilder {...fieldProps} />;
      case "date_selector":
        return <DateSelector {...fieldProps} />;
      case "email_address":
        return <EmailAddress {...fieldProps} />;
      case "phone_number":
        return <PhoneNumber {...fieldProps} />;
      case "url":
        return <URLField {...fieldProps} />;
      case "media_for_review_approval":
        return (
          <Media
            {...fieldProps}
            isShowFileViewStatus={false}
            setIsOpenPreviewMediaModal={props.setIsOpenPreviewMediaModal}
          />
        );
      case "basic_file_upload":
        return <BasicFileUpload {...fieldProps} />;
      case "number_of_impression":
        return <NumberOfImpression {...fieldProps} />;
      case "demographics":
        return <Demographics {...fieldProps} />;
      case "street_address":
        return <StreetAddress {...fieldProps} />;
      case "geotargeting":
        return <GeoTargeting {...fieldProps} />;
      default:
        break;
    }
  });
};

function CustomFormTraffic({
  channel,
  downloadAllMedia,
  setIsOpenPreviewMediaModal,
  users,
  onValueChanged,
  idx,
  isShowPlacementConfirmation,
  isView,
  validationState,
  onSave,
  intl,
  setShouldBlockNavigation,
}) {
  const renderView = () => {
    const digitalProducer = find(
      users,
      (item) => item.id === get(channel, "digital_producer", "")
    );
    const fieldsForDesign = get(channel, "fields", []).filter((field) => {
      return includes(["media_for_review_approval"], field.key);
    });
    const fieldsForOther = get(channel, "fields", []).filter((field) => {
      return !includes(["media_for_review_approval"], field.key);
    });
    let newFields = getFormFieldsWithConditionLogic(channel);
    const placementFiles = get(channel, "placement_files", []);
    const placementUrlLink = get(channel, "placement_url_link", []);
    const copyClipBoard = () => {
      navigator.clipboard.writeText(placementUrlLink);
      ToastManager.show({
        title: intl.formatMessage({
          id: "toast > title copied",
        }),
        level: "success",
      });
    };
    const isRequiredPlacement = get(channel, "is_required_placement", false);
    return (
      <div className={bem.b()}>
        <div className={bem.e("section-form-fields")}>
          <Row>
            <Col xs={6}>
              {renderCustomFieldsView(newFields, channel, fieldsForDesign)}
            </Col>
            <Col xs={6}>
              <FormGroup>
                <Label className={bem.e("view-label")}>
                  <FormattedMessage id="channel manager > channel manager" />
                </Label>
                <div className={"order-view-value"}>
                  {get(channel, "channel_manager_name", "")}
                </div>
              </FormGroup>
              {digitalProducer && (
                <FormGroup>
                  <Label className={bem.e("view-label")}>
                    <FormattedMessage id="channel manager > digital producer" />
                  </Label>
                  <div className={"order-view-value"}>
                    {get(digitalProducer, "name", "")}
                  </div>
                </FormGroup>
              )}
              <FormGroup>
                <Label className={bem.e("view-label")}>
                  <FormattedMessage id="channel manager > optional notes" />
                </Label>
                <div className={"order-view-value"}>
                  {get(channel, "notes", "") ? (
                    get(channel, "notes", "")
                  ) : (
                    <FormattedMessage id="process > none" />
                  )}
                </div>
              </FormGroup>
              <FormGroup>
                <Label className={bem.e("view-optional-assets-label")}>
                  <FormattedMessage id="channel manager > optional assets" />
                </Label>
                {get(channel, "assets_link", "") ? (
                  <a
                    href={get(channel, "assets_link", "")}
                    alt=""
                    className={bem.e("view-assets-link")}
                  >
                    {get(channel, "assets_link", "")}
                  </a>
                ) : null}
                {get(channel, "assets.length") > 0 ? (
                  <HandleAssets
                    files={channel.assets}
                    viewDisabled={false}
                    renamingDisabled={true}
                    renamingHidden={true}
                    deleteDisabled={true}
                    deleteHidden={true}
                    downloadDisabled={false}
                    isView={isView}
                  />
                ) : (
                  <FormattedMessage id="process > none" />
                )}
              </FormGroup>
            </Col>
          </Row>
        </div>
        {fieldsForDesign.length > 0 ? (
          <div className={bem.e("section-media-approve")}>
            <Row>
              <Col xs={6}>
                <div className={bem.e("creative-head")}>
                  <h2 className={bem.e("creative-title")}>
                    <FormattedMessage id="channel manager > approved creative" />
                  </h2>
                  <Button
                    color="blue"
                    outline
                    className="btn btn-radius"
                    onClick={downloadAllMedia}
                  >
                    <span className={bem.e("download-icon")}>
                      <DownloadFileIcon width={24} height={16} />
                    </span>
                    <FormattedMessage id="process > download all media" />
                  </Button>
                </div>
                <div className={bem.e("fields-design-view")}>
                  {renderCustomFieldsView(
                    newFields,
                    channel,
                    fieldsForOther,
                    fieldsForDesign,
                    {
                      setIsOpenPreviewMediaModal,
                    }
                  )}
                </div>
              </Col>
            </Row>
          </div>
        ) : null}
        {isRequiredPlacement ? (
          <div
            className={classnames(bem.e("section-placement-confirmation"), {
              [bem.e("section-placement-view")]: isView,
            })}
          >
            <Row
              className={classnames(bem.e("section-placement-content"), {
                [bem.e("section-placement-content-view")]: isView,
                [bem.e("section-placement-content-edit")]: !isView,
              })}
            >
              <Col xs={12} className={bem.e("reset")}>
                <div className={bem.e("placement-title")}>
                  <FormattedMessage id="process > placement confirmation" />
                  <Asterisk>*</Asterisk>
                </div>
                {/* show view mode for other user */}
                {isView || !isShowPlacementConfirmation ? (
                  <FormGroup className={bem.e("reset")}>
                    <Label className={bem.e("view-label")}>
                      <FormattedMessage id="process > url link" />
                    </Label>
                    <div className={"order-view-value placement-link"}>
                      {get(channel, "placement_url_link", "") ? (
                        <div className={bem.e("copy-link")}>
                          <span className={bem.e("copy-link-truncate")}>
                            {get(channel, "placement_url_link", "")}
                          </span>
                          <span
                            className={bem.e("copy-icon")}
                            onClick={() => copyClipBoard()}
                          >
                            <CopyIcon width={20.55} height={20} />
                          </span>
                        </div>
                      ) : (
                        <FormattedMessage id="process > none" />
                      )}
                    </div>
                  </FormGroup>
                ) : (
                  <div className={bem.e("placement-url")}>
                    <div className={bem.e("placement-input")}>
                      <TextInput
                        label={<FormattedMessage id="process > url link" />}
                        required
                        name="placement_url_link"
                        placeholder=""
                        aria-multiline="true"
                        value={placementUrlLink}
                        onChange={({ target }) => {
                          onValueChanged(idx, {
                            field: "placement_url_link",
                            value: target.value,
                          });
                          if (setShouldBlockNavigation)
                            setShouldBlockNavigation(true);
                        }}
                      />
                      <div className="alert-danger">
                        {get(
                          validationState,
                          `marketing_channels.validation_error.${idx}.placement_url_link`
                        ) &&
                          get(
                            validationState,
                            `marketing_channels.validation_error.${idx}.placement_url_link`
                          )}
                      </div>
                    </div>
                    {/* <Button
                      color="blue"
                      className={classnames(
                        "btn btn-radius",
                        bem.e("btn-save")
                      )}
                      onClick={() => onSave()}
                    >
                      <FormattedMessage id="process > button add" />
                    </Button> */}
                  </div>
                )}
                {/* show view mode for other user */}
                {isView || !isShowPlacementConfirmation ? (
                  <FormGroup className={bem.e("reset")}>
                    <Label className={bem.e("view-label")}>
                      <FormattedMessage id="process > screenshot/file" />
                    </Label>
                    {placementFiles.length > 0 ? (
                      <HandleAssets
                        files={placementFiles}
                        viewDisabled={false}
                        renamingDisabled={true}
                        renamingHidden={true}
                        deleteDisabled={true}
                        deleteHidden={true}
                        downloadDisabled={false}
                        isView={true}
                      />
                    ) : (
                      <div className={classnames(bem.e("order-view-value"))}>
                        <FormattedMessage id="form builder > none" />
                      </div>
                    )}
                  </FormGroup>
                ) : (
                  <div className={bem.e("placement-files")}>
                    {placementFiles.length > 0 && (
                      <div className={bem.e("form-handle-assets")}>
                        <Label className={bem.e("form-field-label")}>
                          <FormattedMessage id="process > screenshot/file" />
                        </Label>
                        <HandleAssets
                          files={placementFiles}
                          onUploadedFiles={(newValue) => {
                            onValueChanged(idx, {
                              field: "placement_files",
                              value: newValue,
                            });
                            if (setShouldBlockNavigation)
                              setShouldBlockNavigation(true);
                          }}
                          onDeleted={(newValue) => {
                            onValueChanged(idx, {
                              field: "placement_files",
                              value: newValue,
                            });
                            if (setShouldBlockNavigation)
                              setShouldBlockNavigation(true);
                          }}
                        />
                      </div>
                    )}
                    <Label className={bem.e("form-field-label")}>
                      <FormattedMessage id="process > screenshot/file" />
                    </Label>
                    <UploadFiles
                      acceptMultiple={true}
                      files={placementFiles || []}
                      isHideFilesDragArea={true}
                      onUploadedFiles={(updatedFiles) => {
                        let newValue = updatedFiles;
                        if (placementFiles) {
                          newValue = concat(placementFiles, newValue);
                        }
                        onValueChanged(idx, {
                          field: "placement_files",
                          value: newValue,
                        });
                        if (setShouldBlockNavigation)
                          setShouldBlockNavigation(true);
                      }}
                      onDeleted={(newValue) => {
                        onValueChanged(idx, {
                          field: "placement_files",
                          value: newValue,
                        });
                        if (setShouldBlockNavigation)
                          setShouldBlockNavigation(true);
                      }}
                      disabled={false}
                      maxSize={10485760} // 10M
                      className={bem.e("assets")}
                      dropTextClass={bem.e("drop-text-class")}
                    >
                      <FormattedMessage id="process > button upload" />
                    </UploadFiles>
                    {get(
                      validationState,
                      `marketing_channels.validation_error.${idx}.placement_files`
                    ) && (
                      <div className="alert-danger">
                        {get(
                          validationState,
                          `marketing_channels.validation_error.${idx}.placement_files`
                        )}
                      </div>
                    )}
                  </div>
                )}
                {get(
                  validationState,
                  `marketing_channels.validation_error.${idx}.placement`
                ) ? (
                  <div className="alert-danger">
                    {get(
                      validationState,
                      `marketing_channels.validation_error.${idx}.placement`
                    )}
                  </div>
                ) : null}
                {!isView && isShowPlacementConfirmation ? (
                  <Button
                    color="blue"
                    className={classnames("btn btn-radius", bem.e("btn-save"))}
                    onClick={() => onSave()}
                  >
                    <FormattedMessage id="process > button save" />
                  </Button>
                ) : null}
              </Col>
            </Row>
          </div>
        ) : null}
      </div>
    );
  };
  return <div className={bem.e("wrapper")}>{renderView()}</div>;
}
CustomFormTraffic.defaultProps = {
  channel: {},
  downloadAllMedia: () => {},
  setIsOpenPreviewMediaModal: () => {},
};
CustomFormTraffic.propTypes = {
  channel: PropTypes.any,
  downloadAllMedia: PropTypes.func,
  setIsOpenPreviewMediaModal: PropTypes.func,
};
export default CustomFormTraffic;
