import * as React from "react";
import { compose, withState, lifecycle, withHandlers } from "recompose";
import { connect } from "react-redux";
import AdminsDetails, { TAB_ASSIGNMENTS, TAB_PROFILE } from "./view";
import { injectIntl } from "react-intl";
import { get, isEqual } from "lodash";
import { MdImportantDevices } from "react-icons/lib/md";
import { MODE_VIEW as USER_FORM_MODE_VIEW, MODE_ADD as USER_FORM_MODE_ADD } from "components/UserForm";
import { PRIVILEGES } from "utils/constants";
import ToastManager from "components/ToastManager";
import {
  updatePrivilegesUsers,
  updateUser,
  getUser,
  setNewUser
} from "store/actions/users";
import { validatePrivileges } from "utils/helpers";

export default injectIntl(
  compose(
    connect(
      (state, props) => {
        let user = null;
        let id = null;
        if (Object.keys(props.lists).length === 1) {
          id = Object.keys(props.lists)[0];
          user = get(state, `users.user.${id}.data`, {});
        }
        const privileges = get(state.auth, "user.privileges", []) || [];
        return {
          auth: state.auth,
          user: user,
          privileges,
          id: id,
          isLoadingPrivileges: get(state, "users.updateUsers.loading", false),
        };
      },
      { updatePrivilegesUsers, updateUser, getUser, setNewUser }
    ),
    withState("isOpenEditAvatarModal", "setIsOpenEditAvatarModal", false),
    withState("activeTab", "setActiveTab", (props) => {
      if (props.isView === USER_FORM_MODE_VIEW || props.isView === USER_FORM_MODE_ADD || (props.auth.info.app_flags && props.auth.info.app_flags.indexOf("SERVERS_LIST") > -1)) return TAB_PROFILE;
      // const isShowAssignments = validatePrivileges({
      //   requires: [PRIVILEGES.REASSIGN_CLIENTS],
      //   privileges: props.privileges,
      // });
      // return isShowAssignments ? TAB_ASSIGNMENTS : TAB_PROFILE;
      return TAB_PROFILE
    }),
    withHandlers({
      onUploadAvatar: ({
        updateUser,
        intl,
        getUser,
        setIsOpenEditAvatarModal,
        setNewUser
      }) => (image, user) => {
        setIsOpenEditAvatarModal(false);
        if(user.id){
          const initialValues = {
            avatar: image.path,
            email: user.email || "",
            first_name: user.first_name || "",
            last_name: user.last_name || "",
          };
          updateUser(user.id, initialValues, () => {
            getUser(user.id);
            ToastManager.show({
              title: <MdImportantDevices />,
              message: intl.formatMessage({
                id: "user > update successfully",
              }),
              level: "success",
            });
          });
        }else{
          setNewUser({
            id: 'new',
            data: {
              avatar: image.path,
              avatar_url: image.path,
            }
          });
        }
      },
      onSubmitPrivileges: ({ lists, intl, updatePrivilegesUsers }) => (
        privilegesAdd,
        privilegesRemove
      ) => {
        updatePrivilegesUsers(
          {
            ids: Object.keys(lists),
            list_add: privilegesAdd,
            list_remove: privilegesRemove,
          },
          () => {
            ToastManager.show({
              title: <MdImportantDevices />,
              message: intl.formatMessage({
                id: "user > update successfully",
              }),
              level: "success",
            });
          }
        );
      },
    }),
    lifecycle({
      componentDidMount() {
        let id = this.props.id;
        if (id && id !== 'new') {
          this.props.getUser(id);
        }
      },
      componentWillReceiveProps(nextProps) {
        if (
          !isEqual(get(this.props, "lists", []), get(nextProps, "lists", []))
        ) {
          let activeTab = TAB_ASSIGNMENTS
          if (nextProps.isView === USER_FORM_MODE_VIEW || nextProps.isView === USER_FORM_MODE_ADD) {
            activeTab = TAB_PROFILE;
          }
          this.props.setActiveTab(activeTab);
        }
      },
    })
  )(AdminsDetails)
);
