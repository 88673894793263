import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import View from "./view";
import { deleteProcess, setActiveEditTab } from "store/actions/processes";
import { getTemplate } from "store/actions/template";
import { injectIntl } from "react-intl";
const Enhanced = connect(
	state => ({
		auth: state.auth
	}),
	{
		deleteProcess,
		setActiveEditTab,
		getTemplate
	}
)(View);

export default withRouter(injectIntl(Enhanced));
