export const AUTH_USER = "AUTH_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const AUTH_ERROR = "AUTH_ERROR";
export const USER_INFO = "USER_INFO";
export const USER_INFO_SUCCESS = "USER_INFO_SUCCESS";
export const USER_INFO_ERROR = "USER_INFO_ERROR";

export const SET_IS_SIDEBAR_OPEN = "SET_IS_SIDEBAR_OPEN";
export const SET_SIDEBAR_ACTIVE = "SET_SIDEBAR_ACTIVE";
export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const AUTH_REQUEST = "AUTH_REQUEST";
export const IMPERSONATE_REQUEST = "IMPERSONATE_REQUEST";
export const IMPERSONATE_SUCCESS = "IMPERSONATE_SUCCESS";
export const IMPERSONATE_STOP_REQUEST = "IMPERSONATE_STOP_REQUEST";

export const GET_USER_STATIONS_REQUEST = "GET_USER_STATIONS_REQUEST";
export const GET_USER_STATIONS_SUCCESS = "GET_USER_STATIONS_SUCCESS";
export const GET_USER_STATIONS_ERROR = "GET_USER_STATIONS_ERROR";

export const SET_IS_REMINDER_OPEN = "SET_IS_REMINDER_OPEN";

export const SET_IS_TEAM_LEAD = "SET_IS_TEAM_LEAD";

export const SET_SCHEDULE_CALENDAR_FILTER = "SET_SCHEDULE_CALENDAR_FILTER";
export const SET_ORDER_SECTIONS_CONTEST = "SET_ORDER_SECTIONS_CONTEST";
export const SET_SCHEDULING_TYPE = "SET_SCHEDULING_TYPE";

export const TOGGLE_RIGHT_PANEL = "TOGGLE_RIGHT_PANEL";

export const SET_COUNT_CONFIRMATION_MASTER_LIST =
  "SET_COUNT_CONFIRMATION_MASTER_LIST";

export const GET_USER_INFO_TAGS_REQUEST = "GET_USER_INFO_TAGS_REQUEST";
export const GET_USER_INFO_TAGS_SUCCESS = "GET_USER_INFO_TAGS_SUCCESS";
export const GET_USER_INFO_TAGS_ERROR = "GET_USER_INFO_TAGS_ERROR";

export const GET_USER_INFO_USERS_REQUEST = "GET_USER_INFO_USERS_REQUEST";
export const GET_USER_INFO_USERS_SUCCESS = "GET_USER_INFO_USERS_SUCCESS";
export const GET_USER_INFO_USERS_ERROR = "GET_USER_INFO_USERS_ERROR";

export const GET_USER_INFO_MESSAGES_REQUEST = "GET_USER_INFO_MESSAGES_REQUEST";
export const GET_USER_INFO_MESSAGES_SUCCESS = "GET_USER_INFO_MESSAGES_SUCCESS";
export const GET_USER_INFO_MESSAGES_ERROR = "GET_USER_INFO_MESSAGES_ERROR";

export const SET_PROMOTIONS_CONTEST_JOCK_INTRODUCTIONS_TEXT =
  "SET_PROMOTIONS_CONTEST_JOCK_INTRODUCTIONS_TEXT";

export const REFRESH_ACCESS_TOKEN_SUCCESS = "REFRESH_ACCESS_TOKEN_SUCCESS";

export const SET_SORT_PROCESSES_QUEUE_SUCCESS =
  "SET_SORT_PROCESSES_QUEUE_SUCCESS";
