import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { get } from "lodash";
import ServerFormSection from "./ServerFormSection";
import ModuleEntitiesAssignment from "./ModuleEntitiesAssignment";

class MultiMarketConfiguration extends Component {
  onSwitchToggle = (checked) => {
    const { formProps } = this.props;
    formProps.setFieldValue("production_multimarket", checked);
    formProps.setFieldTouched("production_multimarket", true);
  };

  render() {
    const { bem, edit, formProps } = this.props;
    const moduleEnabled = get(
      formProps,
      "values.production_multimarket",
      false
    );
    const entitiesProduction = get(formProps, "values.entities.production");
    const switchDisabled = !get(
      formProps,
      "values.is_production",
      false
    );
    return (
      <ServerFormSection
        bem={bem}
        checked={moduleEnabled}
        titleId="server > multi market"
        sectionClass={bem.e("box-multi-market-details")}
        sectionFieldsClass={bem.e(
          "section-view-multi-market-configuration-fields"
        )}
        hasToggle={edit}
        onSwitchToggle={this.onSwitchToggle}
        switchDisabled={switchDisabled}
      >
        <ModuleEntitiesAssignment
          {...this.props}
          moduleEnabled={moduleEnabled}
          featureModule={"enterprise_production"}
          validEntities={entitiesProduction}
        />
      </ServerFormSection>
    );
  }
}

export default injectIntl(MultiMarketConfiguration);
