import React from "react";
import Page from "components/Page";
import PrivilegedComponent from "components/PrivilegedComponent";
import Winner from "components/Winners/Winner";
import { Redirect } from "react-router";
import { FormattedMessage } from "react-intl";
import bn from "utils/bemnames";
import { PRIVILEGES } from "utils/constants";
import { get } from "lodash";

const bem = bn.create("page");
const WinnerPage = (props) => {
  const { intl, winner } = props;
  return (
    <PrivilegedComponent
      requires={{ or: [PRIVILEGES.VIEW_WINNER, PRIVILEGES.EDIT_WINNER] }}
    >
      {({ isValid }) => {
        if (isValid) {
          return (
            <Page applyPadding={false} className={bem.b()}>
              <div className={bem.e("page-header")}>
                <div className={bem.e("page-padding")}>
                  <div className={bem.e("breadcrumb")}>
                    {intl.formatMessage({
                      id: "breadcrumbs > home",
                    })}
                    {` `}/{` `}
                    {intl.formatMessage({
                      id: "breadcrumbs > winners",
                    })}
                    {` `}/{` `}
                    {get(winner, "name", "")}
                  </div>
                  <div className={bem.e("header-content")}>
                    <div className={bem.e("header-title")}>
                      <h2 className={bem.e("title")}>
                        <FormattedMessage id="winners > title winners list" />
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className={bem.e("page-padding")}>
                <Winner />
              </div>
            </Page>
          );
        } else {
          return <Redirect to="/" />;
        }
      }}
    </PrivilegedComponent>
  );
};

export default WinnerPage;
