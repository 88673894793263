import View from "./view";
import { injectIntl } from "react-intl";
import { get } from "lodash";
import { connect } from "react-redux";
import { sendMessage, saveFormValues } from "store/actions/processes";
export default injectIntl(
  connect(
    (state) => {
      return {
        messages: get(state, "messages.messages", []),
      };
    },
    { sendMessage, saveFormValues }
  )(View)
);
