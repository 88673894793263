import React from "react";
import bn from "utils/bemnames";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import { get, map, sumBy } from "lodash";
import { FaSortDesc, FaSortAsc } from "react-icons/lib/fa";
import { Button, Table } from "reactstrap";
import useInfiniteScroll from "utils/useInfiniteScroll";
import moment from "moment";
import history from "components/History";
import URL from "utils/urls";
import AddWinnerModal from "components/JockConsole/AddWinnerModal";
import PrivilegedComponent from "components/PrivilegedComponent";
import { PRIVILEGES } from "utils/constants";

const bem = bn.create("promotion-contest-winners");
const getWinnerType = (item) => {
  let sortName;
  let name;
  let className;
  if (item.type === "call_in_contest") {
    sortName = "CALL";
    name = "Call-in";
    className = "call-in";
  }
  return (
    <div className={classnames(bem.e("winner-type"), className)}>
      <span className={bem.e("winner-sort-name")}>{sortName}</span>
      <span className={bem.e("winner-name")}>{name}</span>
    </div>
  );
};
export const titleDisplay = (process) => {
  if (get(process, "title")) {
    return process.title;
  }
  return <FormattedMessage id="process > title of spot" />;
};

const getWinnerName = ({ item, intl }) => {
  let name = "";
  if (item.first_name && item.last_name) {
    name = item.first_name + " " + item.last_name;
  } else if (item.name) {
    name = item.name;
  }

  if (!name) {
    name = intl.formatMessage({ id: "process > none" });
  }

  return name;
};

const View = ({
  onSort,
  sorted,
  getMoreItems,
  hasMore,
  searchData,
  setSidebarActive,
  process,
  showEndContest,
  onEndContest,
  intl,
  setIsOpenWinnerModal,
  isOpenWinnerModal,
  getItemsList,
}) => {
  const processKey = get(process, "data.fields.key.value", "");
  const scheduleTheme = get(process, "data.fields.theme.value", "");
  const isRollOverTheme = scheduleTheme === "rollover";
  const scheduleFirstPrize = get(process, "data.fields.prizes.0", {});
  const isStandardGiveawayTheme = scheduleTheme === "standard_giveaway";
  const prizeCategory = get(scheduleFirstPrize, "category");
  const isCashVariablePrize = prizeCategory === "variable_cash";
  const isStandardCash = isStandardGiveawayTheme && isCashVariablePrize;
  const stationId = get(process, "data.fields.order_stations.value.0");

  const items = get(searchData, "data", []);
  useInfiniteScroll({
    useWindow: false,
    elementId: "winners-scroll",
    onLoadMore: getMoreItems,
    shouldLoadMore: hasMore,
  });
  const totalPrizes = sumBy(
    get(process, "data.fields.prizes.value", []),
    "total_allotment"
  );
  const selectedTheme = get(process, "data.fields.theme.value", "");
  const isQualifyingGrandPrizeTheme =
    selectedTheme === "qualifying_with_grand_prize";
  const totalAllocated = get(searchData, "total_allocated", 0);
  const totalRemaining = parseInt(totalPrizes) - parseInt(totalAllocated);
  const onView = (item) => {
    setSidebarActive("/winners");
    history.push({
      pathname: URL.WINNER({
        action: "view",
        winner_id: item._id,
      }),
    });
  };
  const getTitle = () => {
    if (isQualifyingGrandPrizeTheme) {
      return (
        <FormattedMessage id="process > promotion > contest > title qualifying winners" />
      );
    }
    return (
      <FormattedMessage id="process > promotion > contest > title winners list" />
    );
  };
  return (
    <div className={classnames(bem.e("winners-table"), "cr-list-table")}>
      <div className={bem.e("winners-head")}>
        <h2 className={bem.e("title")}>{getTitle()}</h2>
        <div className="d-flex">
          {totalPrizes ? (
            <div className={bem.e("information")}>
              <div>
                <FormattedMessage id="process > promotion > contest > total prizes" />
                :<strong>{totalPrizes}</strong>
              </div>
              <div>
                <FormattedMessage id="process > promotion > contest > given away" />
                :<strong>{totalAllocated}</strong>
              </div>
              <div>
                <FormattedMessage id="process > promotion > contest > remaining" />
                :<strong>{totalRemaining}</strong>
              </div>
            </div>
          ) : null}
          <div className="bottom-actions-sticky">
            <div className="buttons">
              <PrivilegedComponent requires={[PRIVILEGES.EDIT_WINNER]}>
                <Button
                  type="button"
                  color="blue"
                  className={classnames(
                    bem.e("button-add-winner"),
                    "btn-radius ml-4"
                  )}
                  onClick={() => setIsOpenWinnerModal(true)}
                >
                  <FormattedMessage id="process > promotion > contest > add winner" />
                </Button>
              </PrivilegedComponent>
              {showEndContest && (
                <Button
                  type="button"
                  outline
                  className={classnames(
                    bem.e("button-end-contest"),
                    "btn-outline-blue btn-radius ml-4"
                  )}
                  onClick={onEndContest}
                >
                  <FormattedMessage id="process > promotion > contest > end contest" />
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={classnames(bem.e("contest-winners-fields"))}>
        <Table className={"table-scroll"}>
          <thead>
            <tr>
              <th
                onClick={() => onSort("type")}
                className={classnames("can-click", "table-first-head")}
              >
                <div className={"item-sortable"}>
                  <FormattedMessage id="process > Type" />
                  <div className={"sortable"}>
                    <FaSortAsc
                      className={classnames("sortable-icon", {
                        "sortable-active":
                          get(sorted, "id") === "type" && get(sorted, "desc"),
                      })}
                    />
                    <FaSortDesc
                      className={classnames("sortable-icon", {
                        "sortable-active":
                          get(sorted, "id") === "type" && !get(sorted, "desc"),
                      })}
                    />
                  </div>
                </div>
              </th>

              <th onClick={() => onSort("name")} className="can-click title">
                <div className={"item-sortable"}>
                  <FormattedMessage id="process > promotion > contest > winner" />
                  <div className={"sortable"}>
                    <FaSortAsc
                      className={classnames("sortable-icon", {
                        "sortable-active":
                          get(sorted, "id") === "name" && !get(sorted, "desc"),
                      })}
                    />
                    <FaSortDesc
                      className={classnames("sortable-icon", {
                        "sortable-active":
                          get(sorted, "id") === "name" && get(sorted, "desc"),
                      })}
                    />
                  </div>
                </div>
              </th>
              <th>
                <FormattedMessage id="process > promotion > contest > prize" />
              </th>
              <th
                onClick={() => onSort("contacted_at_ts")}
                className="can-click"
              >
                <div className={"item-sortable"}>
                  <FormattedMessage id="process > promotion > contest > date/time" />
                  <div className={"sortable"}>
                    <FaSortAsc
                      className={classnames("sortable-icon", {
                        "sortable-active":
                          get(sorted, "id") === "contacted_at_ts" &&
                          !get(sorted, "desc"),
                      })}
                    />
                    <FaSortDesc
                      className={classnames("sortable-icon", {
                        "sortable-active":
                          get(sorted, "id") === "contacted_at_ts" &&
                          get(sorted, "desc"),
                      })}
                    />
                  </div>
                </div>
              </th>
              <th
                onClick={() => onSort("status")}
                className="can-click text-center"
              >
                <div className={"item-sortable"}>
                  <FormattedMessage id="process > promotion > contest > status" />
                  <div className={"sortable"}>
                    <FaSortAsc
                      className={classnames("sortable-icon", {
                        "sortable-active":
                          get(sorted, "id") === "status" &&
                          !get(sorted, "desc"),
                      })}
                    />
                    <FaSortDesc
                      className={classnames("sortable-icon", {
                        "sortable-active":
                          get(sorted, "id") === "status" && get(sorted, "desc"),
                      })}
                    />
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="scroll-bar-style" id="winners-scroll">
            {map(items, (item, index) => {
              const processData = {
                ...item._source,
                key: item._id,
              };
              const prizes = get(processData, "contests.prizes", []);
              return (
                <tr
                  key={index}
                  className="list-liner-item"
                  onClick={() => onView(item)}
                >
                  {/* type */}
                  <td
                    className={classnames(
                      "align-middle align-middle first-col"
                    )}
                  >
                    <span className={"td-value"}>
                      {getWinnerType(processData.contests)}
                    </span>
                  </td>
                  {/* title */}
                  <td className={classnames("align-middle title")}>
                    {getWinnerName({ item: processData, intl })}
                  </td>
                  {/* prize */}
                  <td className={"align-middle"}>
                    {map(prizes, (prize) => (
                      <div key={prize.id}>
                        <span className={bem.e("prize-name")}>
                          {prize.title}
                        </span>
                        <span className={bem.e("small-text")}>
                          {prize.subtitle}
                        </span>
                      </div>
                    ))}
                  </td>
                  {/* date time */}
                  <td className="align-middle">
                    {processData.contests.won_at_ts && (
                      <span className={bem.e("date-time")}>
                        {moment(processData.contests.won_at_ts * 1000).format(
                          "L hh:mm a"
                        )}
                      </span>
                    )}
                    {processData.contests.added_by ? (
                      <span className={bem.e("small-text")}>
                        {processData.contests.added_by.name}
                      </span>
                    ) : (
                      <div className="list-none-text">
                        <FormattedMessage id="process > none" />
                      </div>
                    )}
                  </td>
                  {/* status */}
                  <td className="align-middle text-center">
                    {processData.contests.status ? (
                      <span
                        className={classnames(
                          bem.e("winner-status"),
                          bem.e(`winner-status-${processData.contests.status}`)
                        )}
                      >
                        {processData.contests.status}
                      </span>
                    ) : null}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <AddWinnerModal
        isOpen={isOpenWinnerModal}
        isRollOverTheme={isRollOverTheme}
        isStandardCash={isStandardCash}
        onToggle={() => setIsOpenWinnerModal(!isOpenWinnerModal)}
        onCancel={() => {
          setIsOpenWinnerModal(false);
        }}
        initialValues={{
          contest_id: processKey,
        }}
        stationId={stationId}
        process={process}
        isContest={true}
        onSubmitted={() => getItemsList()}
      />
    </div>
  );
};
export default View;
