import React from "react";
import { Row, Col, FormGroup, Button, Label } from "reactstrap";
import { FieldArray } from "formik";
import bn from "utils/bemnames";
import { FormattedMessage } from "react-intl";
import TextInput from "components/TextInput";
import PropTypes from "prop-types";
import { Asterisk } from "components/Elements";
import { PlusIconSmaller, TrashLagerIcon } from "components/CustomIcons";
import { get } from "lodash";
import classNames from "classnames";
const bem = bn.create("jock-console-item");
export default function OtherLinks(props) {
  const { isEdit, value, intl } = props;
  if (!isEdit) {
    return (
      <div className={bem.e("view-rows")}>
        {value && value.length > 0
          ? value.map((item, index) => (
              <Row key={index}>
                <Col>
                  <FormGroup>
                    <div className={bem.e("jock-console-view-field")}>
                      {index === 0 && (
                        <Label>
                          <FormattedMessage id="jock console manager > label" />
                        </Label>
                      )}

                      <div className={bem.e("jock-console-view-field-value")}>
                        {item.label ? (
                          item.label
                        ) : (
                          <FormattedMessage id="jock console manager > none" />
                        )}
                      </div>
                    </div>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <div className={bem.e("jock-console-view-field")}>
                      {index === 0 && (
                        <Label>
                          <FormattedMessage id="jock console manager > url link" />
                        </Label>
                      )}

                      <div className={bem.e("jock-console-view-field-value")}>
                        {item.url ? (
                          item.url
                        ) : (
                          <FormattedMessage id="jock console manager > none" />
                        )}
                      </div>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            ))
          : null}
      </div>
    );
  }
  const { touched, errors } = props;
  const isShowDelete = get(value, "length", 0) > 1;
  return (
    <div
      className={classNames(bem.e("other-links"), {
        [bem.e("view-rows")]: isShowDelete,
      })}
    >
      <FieldArray
        name="fields.links"
        render={(arrayHelpers) => (
          <div>
            {value && value.length > 0
              ? value.map((item, index) => (
                  <Row key={index}>
                    <Col>
                      <FormGroup>
                        <TextInput
                          label={
                            index === 0 ? (
                              <span>
                                <FormattedMessage id="jock console manager > label" />
                                <Asterisk>*</Asterisk>
                              </span>
                            ) : null
                          }
                          type="text"
                          name={`fields.links.${index}.label`}
                          placeholder={intl.formatMessage({
                            id: "jock console manager > name",
                          })}
                          value={item.label}
                          error={
                            get(touched, `fields.links.${index}.label`) &&
                            get(errors, `fields.links.${index}.label`)
                          }
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup className={bem.e("col-row-delete")}>
                        <TextInput
                          label={
                            index === 0 ? (
                              <span>
                                <FormattedMessage id="jock console manager > url link" />
                                <Asterisk>*</Asterisk>
                              </span>
                            ) : null
                          }
                          type="text"
                          name={`fields.links.${index}.url`}
                          placeholder={intl.formatMessage({
                            id: "jock console manager > enter url link",
                          })}
                          value={item.url}
                          error={
                            get(touched, `fields.links.${index}.url`) &&
                            get(errors, `fields.links.${index}.url`)
                          }
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                        />
                        {isShowDelete ? (
                          <span
                            className={classNames(bem.e("remove-icon"), {
                              [bem.e("remove-icon-first")]: index === 0,
                            })}
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            <TrashLagerIcon />
                          </span>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                ))
              : null}
            {get(touched, "fields.links") &&
              typeof get(errors, "fields.links") === "string" && (
                <span className="text-danger">{errors.fields.links}</span>
              )}
            <div className={bem.e("add-row-button")}>
              <Button
                onClick={() =>
                  arrayHelpers.push({
                    url: "",
                    label: "",
                  })
                }
                color="blue"
                outline
                className="btn-add-row btn-radius"
                type="button"
              >
                <span className={bem.e("plus-icon")}>
                  <PlusIconSmaller />
                </span>
                <FormattedMessage id="jock console manager > add a row" />
              </Button>
            </div>
          </div>
        )}
      />
    </div>
  );
}
OtherLinks.defaultProps = {
  isEdit: false,
};
OtherLinks.propTypes = {
  isEdit: PropTypes.bool,
};
