import React from "react";
import { Button, Row, Col, Form } from "reactstrap";
import * as Yup from "yup";
import { Formik } from "formik";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { Link } from "react-router-dom";

import URL from "utils/urls";
import { InfoArrowIconUp } from "components/CustomIcons";
import ClockFields from "../ClockFields";
import BlockingComponent from "components/BlockingComponent";
import { isEqual } from "lodash";

const CreateClock = (props) => {
  const {
    intl,
    stations,
    onSaveClock,
    isLoading,
    bem,
    shouldBlockNavigation,
    setShouldBlockNavigation,
  } = props;

  const validationSchema = Yup.object().shape({
    type: Yup.string()
      .trim()
      .required(
        intl.formatMessage({
          id: "clock creator > type is required",
        })
      ),
    title: Yup.string()
      .trim()
      .required(
        intl.formatMessage({
          id: "clock creator > title is required",
        })
      ),
    station: Yup.string()
      .trim()
      .required(
        intl.formatMessage({
          id: "clock creator > station is required",
        })
      ),
  });

  const form = (
    <div className={bem.e("clock-details")}>
      <Row>
        <Col className="m-0">
          <div>
            <Formik
              validationSchema={validationSchema}
              enableReinitialize
              isInitialValid={false}
              validateOnChange={false}
              validateOnBlur={false}
              validateOnMount={false}
              onSubmit={onSaveClock}
            >
              {(formProps) => {
                const {
                  isValid,
                  handleSubmit,
                  initialValues,
                  values,
                } = formProps;
                const isChanged = !isEqual(initialValues, values);
                if (isChanged !== shouldBlockNavigation) {
                  setTimeout(() => setShouldBlockNavigation(isChanged), 20);
                }
                return (
                  <React.Fragment>
                    <div>
                      <Form onSubmit={handleSubmit}>
                        <div className={bem.e("form-panel")}>
                          <div className={classnames(bem.e("header-wrapper"))}>
                            <h2 className={classnames(bem.e("header"))}>
                              <FormattedMessage id="clock creator > clock details" />
                            </h2>
                          </div>
                          <Row
                            className={classnames(
                              bem.e("form-container"),
                              "m-0"
                            )}
                          >
                            <ClockFields
                              fieldSpan={4}
                              bem={bem}
                              stations={stations}
                              formProps={formProps}
                            />
                          </Row>
                        </div>
                        <div className={bem.e("buttons-container")}>
                          <Row>
                            <Col xs={5} md={5}>
                              <div
                                className={classnames(
                                  bem.e("form-buttons"),
                                  bem.e("form-create-buttons")
                                )}
                              >
                                <Button
                                  color="primary"
                                  type="submit"
                                  onSubmit={handleSubmit}
                                  className="btn btn-blue btn-radius"
                                  disabled={isLoading}
                                >
                                  <FormattedMessage
                                    id={`clock creator > button ${
                                      !isLoading ? "submit" : "submitting"
                                    }`}
                                  />
                                </Button>
                                <Button
                                  type="button"
                                  to={URL.CLOCK_MANAGER()}
                                  tag={Link}
                                  outline
                                  className="btn-outline-blue btn-radius button-cancel"
                                >
                                  <FormattedMessage id="clock creator > button cancel" />
                                </Button>
                              </div>
                            </Col>
                            {!isValid ? (
                              <Col xs={7} md={7}>
                                <div className={bem.e("helper-box")}>
                                  <InfoArrowIconUp width="154" height="82" />
                                  <div className={bem.e("helper-text")}>
                                    <FormattedMessage id="clock creator > helper text" />
                                  </div>
                                </div>
                              </Col>
                            ) : null}
                          </Row>
                        </div>
                      </Form>
                    </div>
                  </React.Fragment>
                );
              }}
            </Formik>
          </div>
        </Col>
      </Row>
    </div>
  );
  return (
    <React.Fragment>
      {form}
      <BlockingComponent
        isBlocked={shouldBlockNavigation}
        modalProps={{
          isShowConfirm: true,
          isShowDiscard: false,
          isCancelConfirmedNavigation: true,
          onCancel: () => {
            props.setShouldBlockNavigation(false); // reset
          },
          // onConfirm: () => {
          //   const buttonSubmit = document.getElementById(`btn-submit`);
          //   // trigger click to submit the form
          //   if (buttonSubmit) buttonSubmit.click();
          // },
        }}
      />
    </React.Fragment>
  );
  // return (
  //   <WarnAboutUnsavedChanges when={shouldBlockNavigation} children={form} />
  // );
};

export default CreateClock;
