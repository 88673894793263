import { get, map, last, trim, isFunction } from "lodash";
import { connect } from "react-redux";
import * as React from "react";
import { compose, lifecycle, withHandlers, withState } from "recompose";
import { addStation, getStation, updateStation } from "store/actions/stations";
import { getAllServers } from "store/actions/servers";
import { injectIntl } from "react-intl";
import ToastManager from "components/ToastManager";
import { MdImportantDevices } from "react-icons/lib/md";
import FormatFrom, { MODE_EDIT, MODE_VIEW, MODE_ADD } from "./view";
export { MODE_EDIT, MODE_VIEW, MODE_ADD };
export default injectIntl(
  compose(
    connect(
      (state, props) => {
        const { match } = props;
        let id = get(match, "params.id");
        if (props.id) id = props.id;
        return {
          id,
          isLoading:
            get(state, "stations.addStation.loading") ||
            get(state, "stations.updateStation.loading") ||
            get(state, `stations.station.${id}.loading`),
          isUpdate: id ? true : false,
          station: get(state, `stations.station.${id}`, {}),
          servers: get(state, "servers.allServers.data", []),
          auth: get(state, "auth")
        };
      },
      {
        addStation,
        updateStation,
        getStation,
        getAllServers
      }
    ),
    withState("isViewMode", "setIsViewMode", props => get(props, "isView")),
    withHandlers({
      onFormSubmit: ({
        addStation,
        updateStation,
        isUpdate,
        id,
        onBack,
        intl
      }) => values => {
        const { server, ...rest } = values;
        // const stationPrivileges = get(values, "station_privileges", [])
        //   .map(item => {
        //     if (item) return item.value;
        //     return false;
        //   })
        //   .join(",");
        const dataToSend = {
          ...rest,
          market: true,
          server_id: get(server, "value"),
          city: trim(last(get(values, "city.label", "").split("/"))),
          city_id: get(values, "city.value"),
          server_id: get(server, "value"),
          categories: map(get(values, "categories", []), item => item.value),
          format: map(get(values, "format", []), item => item.value),
          time_open: get(values, "time_open.value"),
          time_close: get(values, "time_close.value"),
          office_days: get(values, "office_days", []).join(",")
        };
        if (isUpdate) {
          updateStation(id, dataToSend, () => {
            ToastManager.show({
              title: <MdImportantDevices />,
              message: intl.formatMessage({
                id: "markets > update successfully"
              }),
              level: "success"
            });
          });
        } else {
          addStation(dataToSend, () => {
            ToastManager.show({
              title: <MdImportantDevices />,
              message: intl.formatMessage({
                id: "markets > create successfully"
              }),
              level: "success"
            });
            if (isFunction(onBack)) onBack();
          });
        }
      }
    }),
    lifecycle({
      componentDidMount() {
        const { match, auth } = this.props;
        let id = get(match, "params.id");
        if (this.props.id) id = this.props.id;
        if (id) {
          this.props.getStation(id);
        }
        if (get(auth, "user.is_god_admin")) {
          this.props.getAllServers();
        }
      },
      componentWillReceiveProps(nextProps) {
        if (get(this.props, "isView") !== get(nextProps, "isView")) {
          this.props.setIsViewMode(get(nextProps, "isView"));
        }
      }
    })
  )(FormatFrom)
);
