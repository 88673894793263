export const UPDATE_PRODUCTION_SETTINGS_REQUEST =
  "UPDATE_PRODUCTION_SETTINGS_REQUEST";
export const UPDATE_PRODUCTION_SETTINGS_SUCCESS =
  "UPDATE_PRODUCTION_SETTINGS_SUCCESS";
export const UPDATE_PRODUCTION_SETTINGS_ERROR =
  "UPDATE_PRODUCTION_SETTINGS_ERROR";

export const GET_PRODUCTION_SETTINGS_REQUEST =
  "GET_PRODUCTION_SETTINGS_REQUEST";
export const GET_PRODUCTION_SETTINGS_SUCCESS =
  "GET_PRODUCTION_SETTINGS_SUCCESS";
export const GET_PRODUCTION_SETTINGS_ERROR = "GET_PRODUCTION_SETTINGS_ERROR";

export const UPDATE_ENTERPRISE_SETTINGS_REQUEST =
  "UPDATE_ENTERPRISE_SETTINGS_REQUEST";
export const UPDATE_ENTERPRISE_SETTINGS_SUCCESS =
  "UPDATE_ENTERPRISE_SETTINGS_SUCCESS";
export const UPDATE_ENTERPRISE_SETTINGS_ERROR =
  "UPDATE_ENTERPRISE_SETTINGS_ERROR";

export const GET_ENTERPRISE_SETTINGS_REQUEST =
  "GET_ENTERPRISE_SETTINGS_REQUEST";
export const GET_ENTERPRISE_SETTINGS_SUCCESS =
  "GET_ENTERPRISE_SETTINGS_SUCCESS";
export const GET_ENTERPRISE_SETTINGS_ERROR = "GET_ENTERPRISE_SETTINGS_ERROR";
