import PromotionEventDetails from "./promotionEventDetails";
import prizes from "./prizes";
import LinerSchedule from "./linerSchedule";
import TeamEquipment from "./teamEquipment";
import EventRecap from "./eventRecap";
const steps = {
  promotion_event_details: PromotionEventDetails,
  prizes: prizes,
  liner_schedule: LinerSchedule,
  team_equipment: TeamEquipment,
  event_recap: EventRecap,
};
export default steps;
