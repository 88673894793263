import { compose, lifecycle, withHandlers, withState } from "recompose";
import { connect } from "react-redux";
import { get } from "lodash";
import { getEnterpriseSettings } from "store/actions/settings";
import { assignTeamProcess, sendMessage } from "store/actions/processes";
import { getTeamMembers } from "store/actions/hubs";
import View from "./view";
import { createChatMessageForQueue } from "components/MultipleMarketComponents/CommentsPanel/helpers";
import history from "components/History";
export default compose(
  connect(
    (state) => {
      return {
        auth: state.auth,
        isAssigning: get(state, "processes.assignTeamProcess.loading", true),
        enterpriseSettings: get(state, "settings.enterprise_settings.data", {}),
        members: get(state, "hubs.members.data", []),
      };
    },
    { getEnterpriseSettings, assignTeamProcess, sendMessage, getTeamMembers }
  ),
  withState("isShowSuccessModal", "setIsShowSuccessModal", false),
  withHandlers({
    onAssignTeam: ({ setIsShowSuccessModal, assignTeamProcess }) => (
      values,
      process
    ) => {
      const producerAssignsVo = get(
        process,
        "_source.producer_assigns_vo",
        false
      );
      let data = {
        producer_assigns_vo: producerAssignsVo,
      };
      Object.keys(values).forEach((key) => {
        let roleKey;
        if (key === "W") {
          roleKey = "role_writer";
        } else if (key === "V") {
          roleKey = "role_voice_talent";
        } else if (key === "P") {
          roleKey = "role_producer";
        } else if (key === "D") {
          roleKey = "role_dubber";
        }
        if (roleKey) {
          data[roleKey] = values[key];
        }
      });
      assignTeamProcess(
        {
          ...data,
          process_id: process._id,
        },
        () => {
          setIsShowSuccessModal(true);
        }
      );
    },
    onGetEnterpriseSettings: ({ getEnterpriseSettings }) => () => {
      getEnterpriseSettings();
    },
    onGetTeamMembers: ({ getTeamMembers }) => () => {
      getTeamMembers({
        sorted: [{ id: "name", desc: false }],
        all_members: true,
      });
    },
    onSendMessage: ({ sendMessage, user }) => (chatValue, selectedProcess) => {
      const text = get(chatValue, "value", "");
      const attachments = get(chatValue, "attachments", []);
      const processKey = get(selectedProcess, "_id", "");
      if (!text && attachments.length === 0) return;
      // convert the process data to match with process details
      let processFields = {
        key: {
          value: processKey,
        },
      };
      Object.keys(selectedProcess._source).forEach((key) => {
        processFields[key] = { value: selectedProcess._source[key] };
      });
      const processData = {
        data: {
          fields: processFields,
        },
      };
      const chat_message = createChatMessageForQueue(
        {
          chatText: text,
          attachments,
        },
        {
          user,
          process: processData,
        }
      );
      sendMessage({
        message: chat_message,
        process_id: processKey,
        process: processData,
      });
    },
  }),
  lifecycle({
    componentDidMount() {
      const {
        onGetEnterpriseSettings,
        auth,
        onGetTeamMembers,
        filter,
      } = this.props;
      const isShowMMProductionQueue = auth.info.show_hub_assignment;
      if (
        (filter === "multi_market_production_queue" &&
          !auth.info.production_multimarket) ||
        (filter === "multi_market_list" && !isShowMMProductionQueue)
      ) {
        history.push("/");
      } else {
        onGetEnterpriseSettings();
        onGetTeamMembers();
      }
    },
  })
)(View);
