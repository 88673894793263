import AuthForm, { STATE_LOGIN } from "components/AuthForm";
import React, { useMemo } from "react";
import { Redirect } from "react-router";
import { Card, Col, Row } from "reactstrap";
import URL from "utils/urls";
import Spinner from "components/Spinner";
import backgroundLogo from "assets/img/background_logo.jpg";
import backgroundImage from "assets/img/background_image.jpg";
import PSLogo from "assets/img/logo/ps+logo.png";
import useWindowDimensions from "utils/useWindowDimensions";
import HeaderMobile from "components/HeaderMobile";
import Footer from "components/Footer";

const AuthPage = (props) => {
  const { authenticated, loading } = props;
  const { width: widthScreen } = useWindowDimensions();
  const isMobile = useMemo(() => {
    return widthScreen < 480;
  }, [widthScreen]);

  const handleAuthState = (authState) => {
    if (authState === STATE_LOGIN) {
      props.history.push(URL.LOGIN());
    } else {
      props.history.push(isMobile ? URL.HOME_MOBILE() : URL.HOME());
    }
  };
  if (authenticated) {
    return <Redirect to={isMobile ? URL.HOME_MOBILE() : URL.HOME()} />;
  }
  return (
    <Row className="auth-content-page">
      {isMobile ? (
        <HeaderMobile />
      ) : (
        <div
          style={{
            position: "absolute",
            backgroundImage: `url(${backgroundImage})`,
            backgroundPosition: "top",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            height: "100%",
            width: "100%",
            opacity: 0.5,
            left: 0,
            right: 0,
          }}
        />
      )}
      <Col sm={12}>
        {loading && <Spinner isLoading={loading} />}
        <Row className="auth-form">
          <Col sm={6} className="reset">
            <Card body>
              <AuthForm
                showLogo={false}
                authState={props.authState}
                onChangeAuthState={handleAuthState}
                handleSubmit={props.handleSubmit}
                errorMessage={props.errorMessage}
                isMobile={isMobile}
                redirectUrl={
                  props.location !== undefined &&
                  props.location.state !== undefined &&
                  props.location.state.requestFrom !== undefined
                    ? props.location.state.requestFrom
                    : null
                }
              />
            </Card>
          </Col>
          <Col sm={6} className="reset section-logo hidden-xs">
            <div
              className="logo"
              style={{
                height: "100%",
                backgroundImage: `url(${backgroundLogo})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            >
              <img src={PSLogo} alt="logo" />
            </div>
          </Col>
        </Row>
      </Col>
      <Col sm={12}>
        <Footer />
      </Col>
    </Row>
  );
};

export default AuthPage;
