import * as React from "react";
import { compose, withHandlers, withState } from "recompose";
import { connect } from "react-redux";
import { MdImportantDevices } from "react-icons/lib/md";
import ToastManager from "components/ToastManager";
import { addProgrammingElement } from "store/actions/programmings";
import View from "./view";
import { get } from "lodash";
import { injectIntl } from "react-intl";

export default injectIntl(
  compose(
    connect(
      (state) => {
        const clocksData = get(state, "programmings.clocksData.data", {});
        return {
          slotDefinitions: get(clocksData, "definitions", []),
        };
      },
      {
        addProgrammingElement,
      }
    ),
    withState("isLoading", "setIsLoading", false),
    withHandlers({
      onFormSubmit: ({
        addProgrammingElement,
        process,
        intl,
        setIsLoading,
        onToggle,
        onSelectItem,
      }) => (values, formProps) => {
        setIsLoading(true);
        addProgrammingElement({
          data: {
            ...values,
            active: true,
            process_step_index: 0,
            process_step: "draft",
            last_saved_step: "order_details",
            order_details_status: "draft",
            template_key: "liner",
            scheduling_type: "simple",
            timezone: get(process, "data.fields.timezone.value"),
            order_stations: get(process, "data.fields.order_stations.value"),
            order_stations_array: get(
              process,
              "data.fields.order_stations_array.value"
            ),
          },
          cb: (response) => {
            setIsLoading(false);
            onSelectItem(response);
            formProps.resetForm({});
            ToastManager.show({
              title: <MdImportantDevices />,
              message: intl.formatMessage({
                id: "process > promotion > contest > create successfully",
              }),
              level: "success",
            });
            onToggle();
          },
        });
      },
    })
  )(View)
);
