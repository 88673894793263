import { connect } from "react-redux";
import { compose, lifecycle, withHandlers, withState } from "recompose";
import View from "./view";
import { injectIntl } from "react-intl";
import { get, forOwn, find, orderBy } from "lodash";
import { searchElements } from "store/actions/programmings";
import { setScheduleCalendarFilter } from "store/actions/auth";
import moment from "moment";
export default injectIntl(
  compose(
    connect(
      (state) => {
        const entities = get(
          state.servers,
          `currentServer.data.entities.promotion`,
          {}
        );
        let stations = [];
        forOwn(entities, (entityStations) => {
          stations = [...stations, ...entityStations];
        });
        const filteredStations = get(state, "auth.info.stations", []).filter(
          (station) => {
            return (
              station.has_active_prog_clock &&
              station.has_active_show_clock &&
              station.station_key &&
              find(stations, (i) => i.key === station.station_key)
            );
          }
        );
        const orderedStations = orderBy(
          filteredStations,
          ["call_letters"],
          ["asc"]
        );
        const selectedStationId = get(
          state,
          "auth.schedule_calendar_filter.selected_station_id"
        );
        const scheduleCalendarFilter = get(
          state,
          `auth.schedule_calendar_filter.${selectedStationId}`,
          {}
        );
        return {
          stations: orderedStations,
          scheduleCalendarFilter,
          loading: get(state, "programmings.search.loading", false),
          clocksData: get(state, "programmings.clocksData.data", {}),
        };
      },
      { setScheduleCalendarFilter, searchElements }
    ),
    withState("isRendered", "setIsRendered", false),
    withState("advancedSearch", "setAdvancedSearch", {}),
    withHandlers({
      onAdvancedSearch: (props) => (values, cb) => {
        const { scheduleCalendarFilter } = props;
        props.setAdvancedSearch(values);
        props.searchElements({
          data: {
            station_id: get(scheduleCalendarFilter, "station_id"),
            statuses: get(values, "status", []),
            keyword: get(values, "keyword", ""),
            type_id: get(values, "type_id", ""),
            page: 1,
          },
          cb,
        });
      },
    }),
    lifecycle({
      componentDidMount() {
        let calendarDate = moment().format("YYYY-MM-DD");
        let weekdays = this.props.scheduleCalendarFilter.weekdays
          ? this.props.scheduleCalendarFilter.weekdays
          : "Monday-Friday";
        const type_id = this.props.scheduleCalendarFilter.type_id
          ? this.props.scheduleCalendarFilter.type_id
          : "daypart";
        this.props.setScheduleCalendarFilter({
          ...this.props.scheduleCalendarFilter,
          weekdays,
          type_id,
          search: "",
          selectedItem: {},
          selectedItemSwap: {},
          selectedItemTargetSwap: {},
          current_date: calendarDate,
        });
        this.props.setIsRendered(true);
      },
    })
  )(View)
);
