import React from "react";
import { Modal, ModalBody, ModalHeader, ListGroupItem, ListGroup } from "reactstrap";
import { get, isEmpty, map } from "lodash";
import { FaInfoCircle } from 'react-icons/lib/fa';

import Spinner from "components/Spinner";
import TeamStationsList from "components/TeamForm/TeamStationsList";
import bn from "utils/bemnames";

const TeamWarningModal = ({ isOpen, toggle, selectedTeam }) => {
  
  const bem = bn.create("stations-view-modal");
  const teamStations = get(selectedTeam, "data.selected_stations", {});

  const warningList = map(teamStations, ({formatChange, key}) => {
  	if(!isEmpty(formatChange)){
  		return (
  			<ListGroupItem key={key}>
          <div className="warning-container">
  	  			<FaInfoCircle size={17} color="#E40061" className="warning-ico"/>
            <span>{formatChange}</span>
          </div>
	  		</ListGroupItem>
	  	);
  	}
  });

  return (
    <>
      <Modal isOpen={isOpen} className={bem.b()}>
        {get(selectedTeam, "loading", true) ? (
          <Spinner isLoading={true} />
        ) : (
          <>
            <ModalHeader toggle={toggle}>
              {get(selectedTeam, "data.name", "")}
            </ModalHeader>
            <ModalBody className="stations-list">
             	<ListGroup>{warningList}</ListGroup>
            </ModalBody>
          </>
        )}
      </Modal>
    </>
  );
};

export default TeamWarningModal;
