import * as React from "react";
import bn from "utils/bemnames";
import Spinner from "components/Spinner";
import * as Yup from "yup";
import { Formik } from "formik";
import ToastManager from "components/ToastManager";
import {
  AssignTeamActiveExample,
  AssignTeamInActiveExample,
} from "components/CustomIcons";
import TextInput from "components/TextInput";
import { Form, Button, Row, Col, FormGroup, Label } from "reactstrap";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import ToggleSwitch from "components/ToggleSwitch";
import { get } from "lodash";
const bem = bn.create("enterprise-settings-form");

const SettingsForm = (props) => {
  const { loading, onFormSubmit, intl, enterpriseSettings } = props;
  const formikRef = React.useRef(null);

  const initialValues = {
    writer_capacity: get(enterpriseSettings, "writer_capacity", ""),
    voice_over_capacity: get(enterpriseSettings, "voice_over_capacity", ""),
    producer_capacity: get(enterpriseSettings, "producer_capacity", ""),
    dubber_capacity: get(enterpriseSettings, "dubber_capacity", ""),
    recurring_team_recommendations: Boolean(
      get(enterpriseSettings, "recurring_team_recommendations", false)
    ),
    auto_assign_recommended_team: Boolean(
      get(enterpriseSettings, "auto_assign_recommended_team", false)
    ),
  };
  const form = (
    <div className={bem.b()}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        ref={formikRef}
        validationSchema={Yup.object().shape({
          writer_capacity: Yup.number()
            .typeError(
              intl.formatMessage({ id: "validate > field is required" })
            )
            .required(
              intl.formatMessage({ id: "validate > field is required" })
            )
            .min(
              1,
              intl.formatMessage({
                id: "validate > field is required",
              })
            ),
          voice_over_capacity: Yup.number()
            .typeError(
              intl.formatMessage({ id: "validate > field is required" })
            )
            .required(
              intl.formatMessage({ id: "validate > field is required" })
            )
            .min(
              1,
              intl.formatMessage({
                id: "validate > field is required",
              })
            ),
          producer_capacity: Yup.number()
            .typeError(
              intl.formatMessage({ id: "validate > field is required" })
            )
            .required(
              intl.formatMessage({ id: "validate > field is required" })
            )
            .min(
              1,
              intl.formatMessage({
                id: "validate > field is required",
              })
            ),
          dubber_capacity: Yup.number()
            .typeError(
              intl.formatMessage({ id: "validate > field is required" })
            )
            .required(
              intl.formatMessage({ id: "validate > field is required" })
            )
            .min(
              1,
              intl.formatMessage({
                id: "validate > field is required",
              })
            ),
          recurring_team_recommendations: Yup.bool(),
          auto_assign_recommended_team: Yup.bool(),
        })}
        onSubmit={onFormSubmit}
      >
        {(formProps) => {
          const { values, errors } = formProps;
          return (
            <React.Fragment>
              <Form onSubmit={formProps.handleSubmit}>
                <div
                  className={classNames(
                    bem.e("content-wrapper"),
                    bem.e("content-settings-wrapper")
                  )}
                >
                  <div className={bem.e("section-title")}>
                    <FormattedMessage id="enterprise settings > team member capacity" />
                  </div>

                  <div
                    className={classNames(
                      bem.e("form-padding"),
                      bem.e("team-member-wrapper")
                    )}
                  >
                    <div className={bem.e("helper-text")}>
                      <FormattedMessage id="enterprise settings > team member help text" />
                    </div>
                    <Row>
                      <Col className={bem.e("col-reset")} xs={6}>
                        <FormGroup className={bem.e("form-group-capacity")}>
                          <Label className={bem.e("label-bold")}>
                            <FormattedMessage id="enterprise settings > writer" />
                          </Label>
                          <TextInput
                            label={intl.formatMessage({
                              id: "enterprise settings > capacity",
                            })}
                            type="number"
                            name="writer_capacity"
                            placeholder={intl.formatMessage({
                              id: "enterprise settings > select",
                            })}
                            min="0"
                            value={values.writer_capacity}
                            onChange={(event) => {
                              formProps.setFieldValue(
                                "writer_capacity",
                                event.target.value
                              );

                              props.setShouldBlockNavigation(true);
                            }}
                            onBlur={formProps.handleBlur}
                            error={errors.writer_capacity}
                          />
                        </FormGroup>
                      </Col>
                      <Col className={bem.e("col-reset")} xs={6}>
                        <FormGroup className={bem.e("form-group-capacity")}>
                          <Label className={bem.e("label-bold")}>
                            <FormattedMessage id="enterprise settings > voice over" />
                          </Label>
                          <TextInput
                            label={intl.formatMessage({
                              id: "enterprise settings > capacity",
                            })}
                            type="number"
                            name="voice_over_capacity"
                            placeholder={intl.formatMessage({
                              id: "enterprise settings > select",
                            })}
                            value={values.voice_over_capacity}
                            onChange={(event) => {
                              formProps.setFieldValue(
                                "voice_over_capacity",
                                event.target.value
                              );

                              props.setShouldBlockNavigation(true);
                            }}
                            min="0"
                            onBlur={formProps.handleBlur}
                            error={errors.voice_over_capacity}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className={bem.e("col-reset")} xs={6}>
                        <FormGroup className={bem.e("form-group-capacity")}>
                          <Label className={bem.e("label-bold")}>
                            <FormattedMessage id="enterprise settings > producer" />
                          </Label>
                          <TextInput
                            label={intl.formatMessage({
                              id: "enterprise settings > capacity",
                            })}
                            type="number"
                            name="producer_capacity"
                            placeholder={intl.formatMessage({
                              id: "enterprise settings > select",
                            })}
                            value={values.producer_capacity}
                            onChange={(event) => {
                              formProps.setFieldValue(
                                "producer_capacity",
                                event.target.value
                              );

                              props.setShouldBlockNavigation(true);
                            }}
                            min="0"
                            onBlur={formProps.handleBlur}
                            error={errors.producer_capacity}
                          />
                        </FormGroup>
                      </Col>
                      <Col className={bem.e("col-reset")} xs={6}>
                        <FormGroup className={bem.e("form-group-capacity")}>
                          <Label className={bem.e("label-bold")}>
                            <FormattedMessage id="enterprise settings > dubber" />
                          </Label>
                          <TextInput
                            label={intl.formatMessage({
                              id: "enterprise settings > capacity",
                            })}
                            type="number"
                            name="dubber_capacity"
                            placeholder={intl.formatMessage({
                              id: "enterprise settings > select",
                            })}
                            value={values.dubber_capacity}
                            onChange={(event) => {
                              formProps.setFieldValue(
                                "dubber_capacity",
                                event.target.value
                              );

                              props.setShouldBlockNavigation(true);
                            }}
                            min="0"
                            onBlur={formProps.handleBlur}
                            error={errors.dubber_capacity}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <div className={bem.e("section-title")}>
                    <FormattedMessage id="enterprise settings > team recommendations" />
                  </div>
                  <div
                    className={classNames(
                      bem.e("form-padding"),
                      bem.e("team-recommendations-wrapper")
                    )}
                  >
                    <Row>
                      <Col className={bem.e("col-reset")} xs={6}>
                        <div className={bem.e("helper-text")}>
                          <FormattedMessage id="enterprise settings > recurring team recommendations help text" />
                        </div>
                        <ToggleSwitch
                          leftComponent={intl.formatMessage({
                            id:
                              "enterprise settings > recurring team recommendations",
                          })}
                          rightComponent={intl.formatMessage({
                            id: "enterprise settings > off",
                          })}
                          name="recurring_team_recommendations"
                          switchProps={{
                            checked: !values.recurring_team_recommendations,
                            onChange: (checked) => {
                              formProps.setFieldValue(
                                "recurring_team_recommendations",
                                !checked
                              );
                              // Under enterprise settings, we need to disable the auto assignment switch if the recurring suggestion switch is not turned on.
                              if (!checked === false) {
                                formProps.setFieldValue(
                                  "auto_assign_recommended_team",
                                  false
                                );
                              }
                              props.setShouldBlockNavigation(true);
                            },
                            offColor: "#795AFA",
                            onColor: "#C2D4E0",
                            uncheckedIcon: false,
                            checkedIcon: false,
                            boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.282)",
                            height: 26.44,
                            width: 48.48,
                            handleDiameter: 22.92,
                          }}
                        />

                        <div className={bem.e("helper-text")}>
                          <FormattedMessage id="enterprise settings > auto-assign recommended team help text" />
                        </div>
                        <ToggleSwitch
                          leftComponent={intl.formatMessage({
                            id:
                              "enterprise settings > auto-assign recommended team",
                          })}
                          rightComponent={intl.formatMessage({
                            id: "enterprise settings > off",
                          })}
                          name="recurring_team_recommendations"
                          switchProps={{
                            checked: !values.auto_assign_recommended_team,
                            // Under enterprise settings, we need to disable the auto assignment switch if the recurring suggestion switch is not turned on.
                            disabled: !values.recurring_team_recommendations,
                            onChange: (checked) => {
                              formProps.setFieldValue(
                                "auto_assign_recommended_team",
                                !checked
                              );
                              props.setShouldBlockNavigation(true);
                            },
                            offColor: "#795AFA",
                            onColor: "#C2D4E0",
                            uncheckedIcon: false,
                            checkedIcon: false,
                            boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.282)",
                            height: 26.44,
                            width: 48.48,
                            handleDiameter: 22.92,
                          }}
                        />
                      </Col>
                      <Col className={bem.e("col-reset")} xs={6}>
                        <div className={bem.e("example-text")}>
                          <FormattedMessage id="enterprise settings > example" />
                        </div>
                        <div className={bem.e("example-boxes")}>
                          <div
                            className={classNames(
                              bem.e("example-box"),
                              bem.e("example-active-box")
                            )}
                          >
                            <AssignTeamActiveExample />
                            <FormattedMessage id="enterprise settings > assign active help text" />
                          </div>
                          <div className={bem.e("example-box")}>
                            <AssignTeamInActiveExample />
                            <FormattedMessage id="enterprise settings > assign warning help text" />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>

                <div
                  className={classNames(
                    bem.e("form-buttons"),
                    bem.e("form-padding")
                  )}
                >
                  <Button
                    color="blue"
                    type="submit"
                    className="btn btn-radius"
                    disabled={loading}
                    id="btn-submit-settings"
                    onClick={() => {
                      formProps.validateForm(values).then((onfulfilled) => {
                        if (onfulfilled && onfulfilled.isCancelled) return;
                        if (onfulfilled && Object.keys(onfulfilled).length) {
                          ToastManager.show({
                            title: intl.formatMessage({
                              id: "toast > title not saved",
                            }),
                            message: intl.formatMessage({
                              id:
                                "toast > message error please correct the hilighted fields",
                            }),
                            level: "error",
                          });
                        }
                      });
                    }}
                  >
                    <FormattedMessage
                      id={`enterprise settings > button save`}
                    />
                  </Button>
                  <Button
                    color="blue"
                    outline
                    className="btn btn-radius"
                    disabled={loading}
                    onClick={() => {
                      formProps.resetForm(initialValues);
                    }}
                  >
                    <FormattedMessage
                      id={`enterprise settings > button cancel`}
                    />
                  </Button>
                </div>
              </Form>
              <Spinner isLoading={loading} />
            </React.Fragment>
          );
        }}
      </Formik>
    </div>
  );
  return form;
};
export default SettingsForm;
