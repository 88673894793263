import React, { useEffect, useState } from "react";
import TextareaEvaluator, {
  DEFAULT_WORDS_PER_MIN,
} from "components/TextareaEvaluator";
import { setScriptField, uuidv4 } from "utils/helpers";
import { FormattedMessage } from "react-intl";
import FileView from "components/FileView";
import { getFileNameFromUrl } from "utils/helpers";
import { get, first } from "lodash";
import { Button } from "reactstrap";
import PrivilegedComponent from "components/PrivilegedComponent";
import { PRIVILEGES } from "utils/constants";
import { useMemo } from "react";
import UploadFile from "components/UploadFile";
import { MdImportantDevices } from "react-icons/lib/md";
import ToastManager from "components/ToastManager";
import classNames from "classnames";

const FinalScriptContent = (props) => {
  const { bem, intl, item, process, updateFinalScript } = props;
  const [isLoading, setIsLoading] = useState(false);
  const spotInfo = get(process, "_source.spot_info", []).find(
    (spot) => spot.key === process._id
  );
  const isBookend = item.isBookend;
  const [isEdit, setIsEdit] = useState(false);
  const [refreshField, setRefreshField] = useState(uuidv4());
  const processFormatted = useMemo(() => {
    let fields = {
      key: process._id,
    };
    Object.keys(process._source).forEach((key) => {
      fields[key] = { value: process._source[key] };
    });
    return {
      key: process._id,
      data: { fields },
    };
  }, [process]);
  const [values, setValues] = useState({
    script: get(item, "data.script", ""),
    script_part_b: get(item, "data.script_part_b", ""),
    default_words_per_min: get(
      spotInfo,
      "default_words_per_min",
      DEFAULT_WORDS_PER_MIN
    ),
    default_words_per_min_b: get(
      spotInfo,
      "default_words_per_min_b",
      DEFAULT_WORDS_PER_MIN
    ),
  });
  useEffect(() => {
    setValues({
      script: get(item, "data.script", ""),
      script_part_b: get(item, "data.script_part_b", ""),
      default_words_per_min: get(
        spotInfo,
        "default_words_per_min",
        DEFAULT_WORDS_PER_MIN
      ),
      default_words_per_min_b: get(
        spotInfo,
        "default_words_per_min_b",
        DEFAULT_WORDS_PER_MIN
      ),
    });
  }, [item]);
  const onSubmit = () => {
    setIsLoading(true);
    updateFinalScript(
      {
        ...values,
        id: process._id,
      },
      () => {
        setIsEdit(false);
        ToastManager.show({
          title: <MdImportantDevices />,
          message: intl.formatMessage({
            id: "toast > title saved",
          }),
          level: "success",
        });
        setIsLoading(false);
      }
    );
  };
  let orderLength = get(process, "_source.order_length") || "00";
  const length1 = get(spotInfo, "length1", "00") || "";
  const length2 = get(spotInfo, "length2", "00") || "";
  const isShowUploadFile = spotInfo.is_upload_script_file;
  if (!item.data)
    return (
      <div
        className={classNames(bem.e("section-content"), props.containerClass)}
      >
        <div className={bem.e("empty-content-details")}>
          <label>
            <FormattedMessage id="process > approved script" />
          </label>
          <span className={bem.e("empty-text")}>
            [{intl.formatMessage({ id: "process > in production" })}]
          </span>
        </div>
      </div>
    );
  if (isShowUploadFile) {
    return (
      <div
        className={classNames(bem.e("section-content"), props.containerClass)}
      >
        <div className={bem.e("content-details")}>
          {!isEdit ? (
            isBookend ? (
              <>
                <div className={bem.e("content-item")}>
                  <div className={bem.e("script-file-label")}>
                    <label className={bem.e("approved-label")}>
                      {intl.formatMessage({
                        id: "process > approved script part A",
                      })}
                    </label>
                    <div className={bem.e("spot-length")}>
                      <FormattedMessage id="process > length" />:{" "}
                      <strong>{length1}</strong>
                    </div>
                  </div>
                  <FileView
                    file={{
                      path: values.script,
                      name: getFileNameFromUrl(values.script),
                    }}
                    actions={["view", "download"]}
                  />
                </div>
                <div className={bem.e("content-item")}>
                  <div className={bem.e("script-file-label")}>
                    <label className={bem.e("approved-label")}>
                      {intl.formatMessage({
                        id: "process > approved script part B",
                      })}
                    </label>
                    <div className={bem.e("spot-length")}>
                      <FormattedMessage id="process > length" />:{" "}
                      <strong>{length2}</strong>
                    </div>
                  </div>
                  <FileView
                    file={{
                      path: values.script_part_b,
                      name: getFileNameFromUrl(values.script_part_b),
                    }}
                    actions={["view", "download"]}
                  />
                </div>
              </>
            ) : (
              <div className={bem.e("content-item")}>
                <div className={bem.e("script-file-label")}>
                  <label className={bem.e("approved-label")}>
                    {intl.formatMessage({ id: "process > approved script" })}
                  </label>
                  <div className={bem.e("spot-length")}>
                    <FormattedMessage id="process > length" />:{" "}
                    <strong>{orderLength}</strong>
                  </div>
                </div>
                <FileView
                  file={{
                    path: values.script,
                    name: getFileNameFromUrl(values.script),
                  }}
                  actions={["view", "download"]}
                />
              </div>
            )
          ) : isBookend ? (
            <>
              <div className={bem.e("content-item")}>
                <div className={bem.e("script-file-label")}>
                  <label className={bem.e("approved-label")}>
                    {intl.formatMessage({
                      id: "process > approved script part A",
                    })}
                  </label>
                  <div className={bem.e("spot-length")}>
                    <FormattedMessage id="process > length" />:{" "}
                    <strong>{length1}</strong>
                  </div>
                </div>
                {values.script ? (
                  <FileView
                    file={{
                      path: values.script,
                      name: getFileNameFromUrl(values.script),
                    }}
                    actions={["view", "download", "trash", "rename"]}
                    onDeleted={() => {
                      setValues({
                        ...values,
                        script: "",
                      });
                    }}
                    onUploaded={(files) => {
                      const file = first(files);
                      setValues({
                        ...values,
                        script: get(file, "path", ""),
                      });
                    }}
                  />
                ) : (
                  <UploadFile
                    mode={"edit"}
                    process={process}
                    onUploaded={(value) => {
                      setValues({
                        ...values,
                        script: value.path,
                      });
                    }}
                    attachText={intl.formatMessage({
                      id: "process > placeholder script file",
                    })}
                    isDisabledDelete={true}
                    onDeleted={() => {}}
                    isCheckMimesByExt={true}
                    extensions={["doc", "docx", "pdf"]}
                    url={values.script || ""}
                    actions={["download"]}
                    positionActions="bottom"
                    isShowFileNameInEditMode={true}
                    hideSpotLength={true}
                    path="a"
                    isBookend={isBookend}
                  >
                    <FormattedMessage id="process > button upload" />
                  </UploadFile>
                )}
              </div>
              <div className={bem.e("content-item")}>
                <div className={bem.e("script-file-label")}>
                  <label className={bem.e("approved-label")}>
                    {intl.formatMessage({
                      id: "process > approved script part B",
                    })}
                  </label>
                  <div className={bem.e("spot-length")}>
                    <FormattedMessage id="process > length" />:{" "}
                    <strong>{length2}</strong>
                  </div>
                </div>
                {values.script_part_b ? (
                  <FileView
                    file={{
                      path: values.script_part_b,
                      name: getFileNameFromUrl(values.script_part_b),
                    }}
                    actions={["view", "download", "trash", "rename"]}
                    onDeleted={() => {
                      setValues({
                        ...values,
                        script_part_b: "",
                      });
                    }}
                    onUploaded={(files) => {
                      const file = first(files);
                      setValues({
                        ...values,
                        script_part_b: get(file, "path", ""),
                      });
                    }}
                  />
                ) : (
                  <UploadFile
                    mode={"edit"}
                    process={process}
                    onUploaded={(value) => {
                      setValues({
                        ...values,
                        script_part_b: value.path,
                      });
                    }}
                    attachText={intl.formatMessage({
                      id: "process > placeholder script file",
                    })}
                    isDisabledDelete={true}
                    onDeleted={() => {}}
                    isCheckMimesByExt={true}
                    extensions={["doc", "docx", "pdf"]}
                    url={values.script_part_b || ""}
                    actions={["download"]}
                    positionActions="bottom"
                    isShowFileNameInEditMode={true}
                    hideSpotLength={true}
                    path="a"
                    isBookend={isBookend}
                  >
                    <FormattedMessage id="process > button upload" />
                  </UploadFile>
                )}
              </div>
            </>
          ) : (
            <div className={bem.e("content-item")}>
              <div className={bem.e("script-file-label")}>
                <label className={bem.e("approved-label")}>
                  {intl.formatMessage({ id: "process > approved script" })}
                </label>
                <div className={bem.e("spot-length")}>
                  <FormattedMessage id="process > length" />:{" "}
                  <strong>{orderLength}</strong>
                </div>
              </div>
              {values.script ? (
                <FileView
                  file={{
                    path: values.script,
                    name: getFileNameFromUrl(values.script),
                  }}
                  actions={["view", "download", "trash", "rename"]}
                  onDeleted={() => {
                    setValues({
                      ...values,
                      script: "",
                    });
                  }}
                  onUploaded={(files) => {
                    const file = first(files);
                    setValues({
                      ...values,
                      script: get(file, "path", ""),
                    });
                  }}
                />
              ) : (
                <UploadFile
                  mode={"edit"}
                  process={process}
                  onUploaded={(value) => {
                    setValues({
                      ...values,
                      script: value.path,
                    });
                  }}
                  attachText={intl.formatMessage({
                    id: "process > placeholder script file",
                  })}
                  isDisabledDelete={true}
                  onDeleted={() => {}}
                  isCheckMimesByExt={true}
                  extensions={["doc", "docx", "pdf"]}
                  url={values.script || ""}
                  actions={["download"]}
                  positionActions="bottom"
                  isShowFileNameInEditMode={true}
                  hideSpotLength={true}
                  path="a"
                  isBookend={isBookend}
                >
                  <FormattedMessage id="process > button upload" />
                </UploadFile>
              )}
            </div>
          )}
          <div className={bem.e("content-buttons")}>
            {isEdit ? (
              <>
                <PrivilegedComponent
                  requires={{
                    or: [
                      PRIVILEGES.EDIT_PRODUCTION_SCRIPT,
                      PRIVILEGES.EDIT_ALL_PRODUCTION_ORDERS,
                    ],
                  }}
                >
                  <Button
                    color="blue"
                    className={"btn-radius"}
                    onClick={onSubmit}
                  >
                    {isLoading
                      ? intl.formatMessage({ id: "process > button saving" })
                      : intl.formatMessage({ id: "process > button save" })}
                  </Button>
                </PrivilegedComponent>

                <Button
                  outline
                  color="blue"
                  className={"btn-radius btn-cancel"}
                  onClick={() => setIsEdit(false)}
                >
                  {intl.formatMessage({ id: "process > button cancel" })}
                </Button>
              </>
            ) : (
              <PrivilegedComponent
                requires={{
                  or: [
                    PRIVILEGES.EDIT_PRODUCTION_SCRIPT,
                    PRIVILEGES.EDIT_ALL_PRODUCTION_ORDERS,
                  ],
                }}
              >
                <Button
                  outline
                  color="blue"
                  className={"btn-radius"}
                  onClick={() => setIsEdit(true)}
                >
                  {intl.formatMessage({ id: "process > button edit script" })}
                </Button>
              </PrivilegedComponent>
            )}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={classNames(bem.e("section-content"), props.containerClass)}>
      <div className={bem.e("content-details")}>
        {!isEdit ? (
          isBookend ? (
            <>
              <div className={bem.e("content-item")}>
                <TextareaEvaluator
                  text={setScriptField(values.script)}
                  isOrderLength={true}
                  onEvaluatorCallback={() => {}}
                  process={processFormatted}
                  spot={spotInfo}
                  title={intl.formatMessage({
                    id: "process > approved script part A",
                  })}
                  disabled={true}
                  isBookend={true}
                  part="a"
                  readOnly={true}
                  showInnerTitle={true}
                  showScriptLength={true}
                  orderLengthText={<FormattedMessage id="process > length" />}
                />
              </div>
              <div className={bem.e("content-item")}>
                <TextareaEvaluator
                  text={setScriptField(values.script_part_b)}
                  isOrderLength={true}
                  onEvaluatorCallback={() => {}}
                  process={processFormatted}
                  spot={spotInfo}
                  title={intl.formatMessage({
                    id: "process > approved script part B",
                  })}
                  disabled={true}
                  isBookend={true}
                  part="b"
                  readOnly={true}
                  showInnerTitle={true}
                  showScriptLength={true}
                  orderLengthText={<FormattedMessage id="process > length" />}
                />
              </div>
            </>
          ) : (
            <TextareaEvaluator
              text={setScriptField(values.script)}
              isOrderLength={false}
              onEvaluatorCallback={() => {}}
              process={processFormatted}
              spot={spotInfo}
              disabled={true}
              readOnly={true}
              showInnerTitle={false}
              showScriptLength={false}
              hideOrderLength={true}
            />
          )
        ) : (
          <React.Fragment>
            {!isBookend ? (
              <TextareaEvaluator
                key={refreshField}
                text={setScriptField(values.script)}
                process={processFormatted}
                wordsPerMin={values.default_words_per_min}
                spot={spotInfo}
                hideHelperText={true}
                showScriptLength={true}
                onEvaluatorCallback={(value) => {
                  setValues({
                    ...values,
                    script: value,
                  });
                }}
                onWordsPerMinChangeCallback={(value) => {
                  setValues({
                    ...values,
                    default_words_per_min: value,
                  });
                }}
              />
            ) : (
              <React.Fragment>
                <TextareaEvaluator
                  key={refreshField}
                  text={setScriptField(values.script)}
                  process={processFormatted}
                  spot={spotInfo}
                  wordsPerMin={values.default_words_per_min}
                  hideHelperText={true}
                  showScriptLength={true}
                  onEvaluatorCallback={(value) => {
                    setValues({
                      ...values,
                      script: value,
                    });
                  }}
                  onWordsPerMinChangeCallback={(value) => {
                    setValues({
                      ...values,
                      default_words_per_min: value,
                    });
                  }}
                  title={<FormattedMessage id="process > part A" />}
                  isBookend={isBookend}
                  part="a"
                  showInnerTitle={true}
                />
                <TextareaEvaluator
                  key={`${refreshField}_1`}
                  text={setScriptField(values.script_part_b)}
                  process={processFormatted}
                  spot={spotInfo}
                  wordsPerMin={values.default_words_per_min_b}
                  hideHelperText={true}
                  showScriptLength={true}
                  onEvaluatorCallback={(value) => {
                    setValues({
                      ...values,
                      script_part_b: value,
                    });
                  }}
                  onWordsPerMinChangeCallback={(value) => {
                    setValues({
                      ...values,
                      default_words_per_min_b: value,
                    });
                  }}
                  title={<FormattedMessage id="process > part B" />}
                  isBookend={isBookend}
                  part="b"
                  showInnerTitle={true}
                />
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        <div className={bem.e("content-buttons")}>
          {isEdit ? (
            <>
              <PrivilegedComponent
                requires={{
                  or: [
                    PRIVILEGES.EDIT_PRODUCTION_SCRIPT,
                    PRIVILEGES.EDIT_ALL_PRODUCTION_ORDERS,
                  ],
                }}
              >
                <Button
                  color="blue"
                  className={"btn-radius"}
                  onClick={onSubmit}
                >
                  {isLoading
                    ? intl.formatMessage({ id: "process > button saving" })
                    : intl.formatMessage({ id: "process > button save" })}
                </Button>
              </PrivilegedComponent>

              <Button
                outline
                color="blue"
                className={"btn-radius btn-cancel"}
                onClick={() => setIsEdit(false)}
              >
                {intl.formatMessage({ id: "process > button cancel" })}
              </Button>
            </>
          ) : (
            <PrivilegedComponent
              requires={{
                or: [
                  PRIVILEGES.EDIT_PRODUCTION_SCRIPT,
                  PRIVILEGES.EDIT_ALL_PRODUCTION_ORDERS,
                ],
              }}
            >
              <Button
                outline
                color="blue"
                className={"btn-radius"}
                onClick={() => setIsEdit(true)}
              >
                {intl.formatMessage({ id: "process > button edit script" })}
              </Button>
            </PrivilegedComponent>
          )}
        </div>
      </div>
    </div>
  );
};
export default FinalScriptContent;
