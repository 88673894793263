import React, { useState } from "react";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import { FaSortDesc, FaSortAsc } from "react-icons/lib/fa";
import { Table, Button } from "reactstrap";
import moment from "moment";
import Tooltip from "rc-tooltip";
import { get } from "lodash";
import Checkbox from "components/Checkbox";
import { TrashListIcon } from "components/CustomIcons";
import Dropdown from "components/Dropdown";
import NoItemsFound from "components/NoItemsFound";
import PrivilegedComponent from "components/PrivilegedComponent";
import useInfiniteScroll from "utils/useInfiniteScroll";
import { PRIVILEGES } from "utils/constants";

const View = ({
  actionSort,
  sort,
  items,
  bem,
  onEdit,
  onView,
  onClickItem,
  isLoading,
  handleLoadMore,
  hasMoreItems,
  intl,
  selectedClients,
  totalSelectedClients,
  dateFormatByServer,
  ...rest
}) => {
  const [selected, setSelected] = useState(null);
  useInfiniteScroll({
    useWindow: false,
    elementId: "cr-app-main",
    onLoadMore: handleLoadMore,
    shouldLoadMore: hasMoreItems,
  });
  return (
    <div
      className={classnames(
        bem.e("table-wrapper"),
        bem.e("table-client-manager")
      )}
    >
      <Table className={bem.e("table")}>
        <thead>
          <tr>
            <PrivilegedComponent requires={[PRIVILEGES.REASSIGN_CLIENTS]}>
              <th className="text-select">
                <FormattedMessage id="client manager > select" />
              </th>
            </PrivilegedComponent>

            <th
              onClick={() => actionSort("client_name")}
              className="can-click client-name"
            >
              <div className={bem.e("item-sortable")}>
                <FormattedMessage id="client manager > client" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        get(sort, "id") === "client_name" && !get(sort, "desc"),
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        get(sort, "id") === "client_name" && get(sort, "desc"),
                    })}
                  />
                </div>
              </div>
            </th>
            <th onClick={() => actionSort("user_name")} className="can-click">
              <div className={classnames(bem.e("item-sortable"))}>
                <FormattedMessage id="client manager > account exec" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        get(sort, "id") === "user_name" && !get(sort, "desc"),
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        get(sort, "id") === "user_name" && get(sort, "desc"),
                    })}
                  />
                </div>
              </div>
            </th>
            <th onClick={() => actionSort("created_ts")} className="can-click">
              <div
                className={classnames(
                  bem.e("item-sortable"),
                  bem.e("item-center")
                )}
              >
                <FormattedMessage id="client manager > created" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        get(sort, "id") === "created_ts" && !get(sort, "desc"),
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        get(sort, "id") === "created_ts" && get(sort, "desc"),
                    })}
                  />
                </div>
              </div>
            </th>
            <th>
              <div
                className={classnames(
                  bem.e("item-sortable"),
                  bem.e("item-center")
                )}
              >
                <FormattedMessage id="client manager > actions" />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {items.length === 0 && !isLoading ? (
            <tr>
              <td colSpan={5}>
                <NoItemsFound />
              </td>
            </tr>
          ) : (
            <React.Fragment>
              <PrivilegedComponent requires={[PRIVILEGES.REASSIGN_CLIENTS]}>
                {totalSelectedClients > 0 && (
                  <tr>
                    <td colSpan={5} className={bem.e("reassign-bulk-td")}>
                      <div className={bem.e("reassign-bulk-row")}>
                        <span className={bem.e("reassign-bulk-row-label")}>
                          <FormattedMessage id="client manager > reassign selected clients to" />
                        </span>
                        :
                        <Dropdown
                          label={null}
                          value={selected}
                          placeholder={intl.formatMessage({
                            id: "client manager > select",
                          })}
                          onChange={(selectedOption) => {
                            setSelected(selectedOption);
                          }}
                          options={[
                            {
                              label: "",
                              value: "",
                            },
                            ...rest.users,
                          ]}
                          name="user"
                        />
                        {totalSelectedClients > 0 ? (
                          <Button
                            disabled={!selected}
                            onClick={() => {
                              if (!selected) return;
                              rest.onReassignBulk(selected.data);
                              setSelected(null);
                            }}
                            color="primary"
                            className={"btn-blue btn-radius"}
                          >
                            {intl.formatMessage(
                              {
                                id: "client manager > reassign clients",
                              },
                              {
                                TOTAL: totalSelectedClients,
                              }
                            )}
                          </Button>
                        ) : null}
                      </div>
                    </td>
                  </tr>
                )}
              </PrivilegedComponent>

              {items.map((item, index) => {
                const client = get(item, "_source");
                return (
                  <tr key={index}>
                    <PrivilegedComponent
                      requires={[PRIVILEGES.REASSIGN_CLIENTS]}
                    >
                      {/* checkbox */}
                      <td className={classnames("align-middle")}>
                        <Checkbox
                          checked={selectedClients && selectedClients[item._id]}
                          onChange={() => {
                            rest.setSelectedClients({
                              ...selectedClients,
                              [item._id]:
                                selectedClients && selectedClients[item._id]
                                  ? false
                                  : true,
                            });
                          }}
                          checkStyle={{
                            color: "#fff",
                            width: "14",
                            height: "13",
                          }}
                        />
                      </td>
                    </PrivilegedComponent>
                    {/* client */}
                    <td
                      className={classnames(
                        "align-middle",
                        bem.e("client-name")
                      )}
                      onClick={() => onClickItem(item)}
                    >
                      <span>{client.client_name}</span>
                    </td>
                    {/* account exec */}
                    <td
                      className={classnames(
                        "align-middle",
                        bem.e("account-name")
                      )}
                      onClick={() => onClickItem(item)}
                    >
                      {client.user_name ? (
                        client.user_name
                      ) : (
                        <FormattedMessage id="client manager > none" />
                      )}
                    </td>
                    {/* created */}
                    <td
                      className={classnames(
                        "align-middle",
                        bem.e("text-center"),
                        bem.e("created-text")
                      )}
                      onClick={() => onClickItem(item)}
                    >
                      {client.created_ts ? (
                        moment(client.created_ts * 1000).format(dateFormatByServer)
                      ) : (
                        <FormattedMessage id="client manager > none" />
                      )}
                    </td>
                    {/* actions */}
                    <td
                      className={classnames(
                        "align-middle",
                        bem.e("text-center")
                      )}
                    >
                      <div className={bem.e("client-actions")}>
                        {/* <Tooltip
                          placement="top"
                          overlayClassName={bem.e("tooltip-action")}
                          overlay={
                            <FormattedMessage id="client manager > view client details" />
                          }
                        >
                          <span
                            className={bem.e("view-action")}
                            onClick={() => onView(item)}
                          >
                            <EyeIcon />
                          </span>
                        </Tooltip>
                        <PrivilegedComponent
                          requires={[PRIVILEGES.EDIT_CLIENT]}
                        >
                          <Tooltip
                            placement="top"
                            overlayClassName={bem.e("tooltip-action")}
                            overlay={
                              <FormattedMessage id="client manager > edit client details" />
                            }
                          >
                            <span
                              className={bem.e("edit-action")}
                              onClick={() => onEdit(item)}
                            >
                              <EditIcon />
                            </span>
                          </Tooltip>
                        </PrivilegedComponent> */}
                        <PrivilegedComponent
                          requires={[PRIVILEGES.DELETE_CLIENT]}
                        >
                          <Tooltip
                            placement="top"
                            overlayClassName={bem.e("tooltip-action")}
                            overlay={
                              <FormattedMessage id="client manager > delete" />
                            }
                          >
                            <span
                              className={bem.e("reassign-action")}
                              onClick={() => rest.onDelete(item)}
                            >
                              <TrashListIcon />
                            </span>
                          </Tooltip>
                        </PrivilegedComponent>
                        {/* Note: Hiding it as we are handling it only with checkbox and reassign dropdown  */}
                        {/* <PrivilegedComponent
                          requires={[PRIVILEGES.REASSIGN_CLIENTS]}
                        >
                          <Tooltip
                            placement="top"
                            overlayClassName={bem.e("tooltip-action")}
                            overlay={
                              <FormattedMessage id="client manager > reassign" />
                            }
                          >
                            <span
                              className={bem.e("reassign-action")}
                              onClick={() => rest.onReassign(item)}
                            >
                              <ClientIcon color="#795AFA" />
                            </span>
                          </Tooltip>
                        </PrivilegedComponent> */}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </React.Fragment>
          )}
        </tbody>
      </Table>
    </div>
  );
};
export default View;
