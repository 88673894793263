import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { FaSearch, FaPlus, FaSortDesc, FaSortAsc } from "react-icons/lib/fa";
import { MdClose } from "react-icons/lib/md";
import { TrashListIcon, LockClosedIcon } from "components/CustomIcons";
import Checkbox from "components/Checkbox";
import classnames from "classnames";
import { get, find, includes, map, sortBy } from "lodash";
import { ButtonGroup, Button, Row, Col } from "reactstrap";
import { FormattedMessage } from "react-intl";
import Table from "components/Table";
import ConfirmationModal from "components/ConfirmationModal";
import PrivilegedComponent from "components/PrivilegedComponent";
import { PRIVILEGES } from "utils/constants";
import TextInput from "components/TextInput";
import InviteUserModal from "components/InviteUser";
import BulkUploadUserModal from "components/BulkUploadUser";
import { validatePrivileges } from "utils/helpers";
import bn from "utils/bemnames";
import Tooltip from "rc-tooltip";

const bem = bn.create("list-users");
const UserTableStatic = (props) => {
  const {
    selectedRowsId,
    setSelectedRowsId,
    filtered,
    auth,
    page,
    pageSize,
    sorted,
    setSorted,
    setPage,
    setPageSize,
    setFiltered,
    users,
    onSearchClicked,
    searchText,
    onChangeInput,
    onSearchKeyDown,
    userList,
    onClearSearch,
  } = props;
  const privileges = get(auth, "user.privileges", []);
  const selectedNewUsers = Object.values(selectedRowsId).filter((item) => item);
  const pages = get(users, "data.last_page") || 1;
  const total = get(users, "data.total") || 1;
  const sortedName = find(sorted, (item) => item.id === "name");
  const sortedRoles = find(sorted, (item) => item.id === "roles_string");
  const sortedActive = find(sorted, (item) => item.id === "order");
  const isGroupAdmin = get(auth, "user.broadcasting_group_admin", false);
  const isAllowActive =
    validatePrivileges({
      requires: [PRIVILEGES.EDIT_USERS],
      privileges,
      user: auth.user,
    }) || isGroupAdmin;
  const onClickItem = (item) => {
    const isEditable = validatePrivileges({
      requires: {
        or: [PRIVILEGES.EDIT_USERS, PRIVILEGES.ASSIGN_PRIVILEGES],
      },
      privileges,
      user: auth.user,
    });
    if (isEditable) {
      props.onSelectedEdit({ [item.id]: item });
      return;
    }
    const isViewable = validatePrivileges({
      requires: [PRIVILEGES.VIEW_USERS],
      privileges,
      user: auth.user,
    });
    if (isViewable) {
      props.onSelectedView({
        [item.id]: item,
      });
    }
  };
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const userId = urlParams.get("user");
    if (userId) {
      onClickItem({
        id: userId,
      });
    }
  }, []);
  const renderInviteUserButton = () => {
    return (
      <div className={bem.e("user-list-buttons")}>
        <Button
          color=""
          onClick={() => props.onSelectedAdd({ new: {} })}
          className={bem.e("btn-new-user")}
        >
          <FaPlus color="#795AFA" size={20} />
          <FormattedMessage id="user > invite new user" />
        </Button>
        {selectedNewUsers.length > 0 && isAllowActive && (
          <Button
            color="blue"
            onClick={() => {
              props.setIsInviteModalOpen(true);
            }}
            className={classnames(bem.e("btn-invite-user"), "btn-radius")}
          >
            {props.intl.formatMessage(
              {
                id: "user > invite new users",
              },
              {
                total: selectedNewUsers.length,
              }
            )}
          </Button>
        )}
      </div>
    );
  };
  return (
    <>
      <Row>
        <Col sm="12">
          <div className={bem.e("head-admin-list-wrapper")}>
            <PrivilegedComponent requires={[PRIVILEGES.ADD_USERS]}>
              {renderInviteUserButton()}
            </PrivilegedComponent>
            <TextInput
              type="text"
              name="q"
              value={searchText}
              onChange={onChangeInput}
              onKeyDown={onSearchKeyDown}
              placeholder={props.intl.formatMessage({
                id: "user > placeholder search",
              })}
              rightComponent={
                <div className="search-buttons">
                  {searchText && (
                    <div
                      onClick={onClearSearch}
                      className={"button-search-close"}
                    >
                      <MdClose />
                    </div>
                  )}

                  <Button
                    color=""
                    className="btn-search"
                    onClick={onSearchClicked}
                  >
                    <FaSearch size={17} />
                  </Button>
                </div>
              }
            />
          </div>
        </Col>
      </Row>
      <Table
        className={classnames(bem.e("table"), {
          "empty-list": !userList.length,
        })}
        data={userList}
        pages={pages}
        loading={users.loading}
        showPageSizeOptions={false}
        total={total}
        // Controlled props
        filtered={filtered}
        page={page}
        pageSize={pageSize}
        sorted={sorted}
        // Callbacks
        onSortedChange={(sorted) => setSorted(sorted)}
        onPageChange={(page) => setPage(page)}
        onPageSizeChange={(pageSize, page) => {
          setPageSize(pageSize);
          setPage(page);
        }}
        getTdProps={(state, rowInfo, column, instance) => {
          return {
            onClick: (e, handleOriginal) => {
              const { original } = rowInfo;
              const columnId = column.id;
              if (!includes(["actions", "order"], columnId)) {
                onClickItem(original);
              } else if (handleOriginal) {
                handleOriginal();
              }
            },
          };
        }}
        onFilteredChange={(filtered) => setFiltered(filtered)}
        columns={[
          {
            Header: (
              <div className={classnames(bem.e("th-name"), bem.e("th-sort"))}>
                <FormattedMessage id="user > name" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        sortedName && !sortedName.desc,
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sortedName && sortedName.desc,
                    })}
                  />
                </div>
              </div>
            ),
            accessor: "name",
            Cell: ({ original: user }) => {
              const { is_active } = user;
              return (
                <div
                  className={classnames(
                    bem.e("user-name-column"),
                    bem.e("td-value"),
                    { [bem.e("text-inactive")]: !is_active }
                  )}
                >
                  {user.name}
                </div>
              );
            },
          },
          {
            Header: (
              <div className={bem.e("th-sort")}>
                <FormattedMessage id="user > roles" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        sortedRoles && !sortedRoles.desc,
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        sortedRoles && sortedRoles.desc,
                    })}
                  />
                </div>
              </div>
            ),
            accessor: "roles_string",
            Cell: ({ original: user }) => {
              const { is_active } = user;
              let rolesString = user.roles_string;
              let isAdmin = false;
              if (user.broadcasting_group_admin) {
                rolesString = props.intl.formatMessage({
                  id: "user > broadcasting group admin",
                });
                isAdmin = true;
              } else if (user.admin_type === "market_admin") {
                rolesString = props.intl.formatMessage({
                  id: "user > market admin",
                });
                isAdmin = true;
              }
              return (
                <span
                  className={classnames({
                    [bem.e("td-value")]: !isAdmin,
                    [bem.e("text-inactive")]: !is_active,
                  })}
                >
                  {rolesString ? (
                    <Tooltip
                      placement="topLeft"
                      trigger={["click", "hover"]}
                      overlayClassName={bem.e("tooltip")}
                      overlay={
                        <div className={"market-string"}>{rolesString}</div>
                      }
                    >
                      <div className={"market-string"}>{rolesString}</div>
                    </Tooltip>
                  ) : (
                    <FormattedMessage id="user > none" />
                  )}
                </span>
              );
            },
          },
          {
            Header: <FormattedMessage id="user > teams and stations" />,
            sortable: false,
            accessor: "stations_string",
            Cell: ({ original: user }) => {
              const { is_active } = user;
              const stationsArr = map(user.stations, (item) => {
                let stationArr = [];
                if (get(item, "call_letters", ""))
                  stationArr.push(item.call_letters);
                if (get(item, "name", ""))
                  stationArr.push(item.name);
                return stationArr.join(' - ');
              })
                .filter((string) => string)
                .map((string) => string.toLowerCase());
              const stationsString = sortBy(stationsArr).join(", ");
              return (
                <span
                  className={classnames(bem.e("td-value"), {
                    [bem.e("text-inactive")]: !is_active,
                  })}
                >
                  {stationsArr.length > 0 ? (
                    <Tooltip
                      placement="topLeft"
                      trigger={["click", "hover"]}
                      overlayClassName={bem.e("tooltip")}
                      overlay={
                        <div className={"market-string text-caps"}>
                          {stationsString}
                        </div>
                      }
                    >
                      <div className={"market-string text-caps"}>
                        {stationsString}
                      </div>
                    </Tooltip>
                  ) : (
                    <FormattedMessage id="user > none" />
                  )}
                </span>
              );
            },
          },
          {
            Header: <FormattedMessage id="user > Actions" />,
            sortable: false,
            resizable: false,
            filterable: false,
            style: { justifyContent: "center", textAlign: "center" },
            Cell: ({ original: user }) => {
              if (
                !auth.user.broadcasting_group_admin &&
                !auth.user.is_god_admin &&
                (user.is_god_admin || user.broadcasting_group_admin)
              )
                return null;

              if (auth.user.is_support_admin && user.is_god_admin) return null;

              return (
                <ButtonGroup>
                  <PrivilegedComponent requires={[PRIVILEGES.DELETE_USERS]}>
                    <Button
                      color="blue"
                      className={bem.e("action-button")}
                      onClick={() => {
                        props.openDeleteModal(user.id);
                      }}
                    >
                      <TrashListIcon />
                    </Button>
                  </PrivilegedComponent>
                </ButtonGroup>
              );
            },
          },
          {
            Header: (
              <div
                className={classnames(bem.e("head-active"), bem.e("th-sort"))}
              >
                <FormattedMessage id="user > active" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        sortedActive && !sortedActive.desc,
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        sortedActive && sortedActive.desc,
                    })}
                  />
                </div>
              </div>
            ),
            accessor: "order",
            Cell: ({ original: user }) => {
              const { email_invited_at, is_active, is_locked } = user;

              let isChecked = false;
              if (email_invited_at) {
                isChecked = !!is_active;
              } else {
                isChecked = selectedRowsId[user.id] || false;
              }
              return (
                <div className={bem.e("checkbox")}>
                  <Checkbox
                    checked={isChecked}
                    disabled={!isAllowActive || is_locked}
                    color={email_invited_at ? "blue" : "green"}
                    onChange={(checked) => {
                      if (!email_invited_at) {
                        const selected = {
                          ...selectedRowsId,
                          [user.id]: checked,
                        };
                        setSelectedRowsId(selected);
                      } else {
                        props.onActiveUsers([user.id]);
                      }
                    }}
                  />
                  {!email_invited_at && (
                    <span className={bem.e("new-label")}>
                      <FormattedMessage id="user > new" />
                    </span>
                  )}
                  {is_locked ? (
                    <div
                      className={bem.e("locked_icon")}
                      onClick={() => {
                        props.setUnlockUserId(user.id);
                        props.setIsUnlockConifrmModalOpen(true);
                      }}
                    >
                      <LockClosedIcon width={20} height={28} color="#FF006C" />
                    </div>
                  ) : (
                    ``
                  )}
                </div>
              );
            },
            minWidth: 50,
            style: {
              justifyContent: "flex-start",
              textAlign: "center",
            },
          },
        ]}
      />
      <ConfirmationModal
        isOpen={props.isInviteModalOpen}
        title={props.intl.formatMessage(
          {
            id: "user > are you ready to invite {total} new users",
          },
          {
            total: selectedNewUsers.length,
          }
        )}
        onConfirm={() => props.onInviteUsers()}
        onToggle={() => props.setIsInviteModalOpen(!props.isInviteModalOpen)}
        onCancel={() => props.setIsInviteModalOpen(!props.isInviteModalOpen)}
        cancelTitle={props.intl.formatMessage({
          id: "user > button cancel",
        })}
        isCloseOutside={false}
      />
      <ConfirmationModal
        isOpen={props.isSuccessModalOpen}
        title={props.intl.formatMessage({
          id: "user > invite sent",
        })}
        state="success"
        onToggle={() => props.setIsSuccessModalOpen(!props.isSuccessModalOpen)}
        onCancel={() => props.setIsSuccessModalOpen(false)}
      />
      <ConfirmationModal
        isOpen={props.isDeleteModalOpen}
        title={props.intl.formatMessage({
          id: "user > are you sure you want to remove user",
        })}
        onConfirm={props.onDelete}
        onToggle={props.closeDeleteModal}
        onCancel={props.closeDeleteModal}
        isCloseOutside={false}
      />
      <InviteUserModal
        auth={auth}
        isOpen={props.isInviteUserModalOpen}
        onToggle={props.toggleInviteUserModal}
        onCancel={() => {
          props.setIsInviteUserModalOpen(false);
        }}
      />
      <BulkUploadUserModal
        isOpen={props.isBulkUploadUserModalOpen}
        onToggle={props.toggleBulkUploadUserModal}
        onCancel={() => props.setIsBulkUploadUserModalOpen(false)}
      />
    </>
  );
};
UserTableStatic.defaultProps = {
  onSelectedEdit: () => {},
  onSelectedView: () => {},
  onSelectedAdd: () => {},
};
UserTableStatic.propsType = {
  onSelectedEdit: PropTypes.func,
  onSelectedView: PropTypes.func,
  onSelectedAdd: PropTypes.func,
};
export default UserTableStatic;
