import * as React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import {
  get,
  isEqual,
  includes,
  orderBy,
  map,
  find,
  filter,
  trim,
  isEmpty,
} from "lodash";
import moment from "moment-timezone";
import ToggleSwitch from "components/ToggleSwitch";
import { FormattedMessage } from "react-intl";
import ProductionDetails from "./ProductionDetails";
import ClientDetails from "./ClientDetails";
import AudienceDetails from "./AudienceDetails";
import SpotDetails from "./SpotDetails";
import {
  Form,
  Button,
  ButtonGroup,
  UncontrolledTooltip,
  Modal,
  ModalBody,
} from "reactstrap";
import OrderDetailsDuplicate from "components/OrderDetailsDuplicate";
import ToastManager from "components/ToastManager";
import PrivilegedComponent from "components/PrivilegedComponent";
import { PRIVILEGES } from "utils/constants";
import ConfirmationModal from "components/ConfirmationModal";
import ReminderModal from "components/ReminderModal";
import SendBackOrderStatusModal from "components/SendBackOrderStatusModal";
import {
  generateRotationChart,
  getEditableTabByUser,
  getEditableTabsByStage,
  uuidv4,
} from "utils/helpers";
import URL from "utils/urls";
import bn from "utils/bemnames";
import CopyOrderModal from "./CopyOrderModal";
import { checkAllowSendBack, validatePrivileges } from "utils/helpers";
import history from "components/History";
import { AddIcon } from "components/CustomIcons";
import { getWindowDimensions } from "utils/useWindowDimensions";

const bem = bn.create("order-details");
class OrderDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenDialogDelete: false,
      isOpenDialogArchive: false,
      isOpenDialogDeleteSpot: false,
      isOpenSendBackOrder: false,
      isOpenReminderMissingSelectStations: false,
      missingSelectStations: [],
      currentSpotDeleteIndex: null,
      isOpenTemplateSelectionModal: false,
      anotherSpotData: {},
      isOpenSuccessDialog: false,
      isOpenDueDateModal: false,
      isOpenRotationPromptModal: false,
      isOpenCopyOrderModal: false,
      isOpenConfirmUnarchive: false,
      isShowSoldSpecSwitch: false,
    };
  }

  static propTypes = {
    mode: PropTypes.string,
  };

  static defaultProps = {
    mode: "edit",
  };

  componentDidMount() {
    this.props.getTemplate(
      get(this.props.template, "key", "basic_production"),
      this.props.auth.info
    );
    const { process } = this.props;
    if (
      process &&
      !process.activeTab &&
      process.activeTab !== "order_details"
    ) {
      this.props.setActiveTab("order_details");
    }
  }

  componentDidUpdate(prevProps) {
    if (
      get(this.props.template, "key", "basic_production") !==
      get(prevProps.template, "key", "basic_production")
    ) {
      this.props.getTemplate(
        get(this.props.template, "key", "basic_production"),
        this.props.auth.info
      );
    }

    if (
      !get(this.props, "shouldBlockNavigation") &&
      get(this.props, "location.state.origin_process_key")
    ) {
      this.props.setShouldBlockNavigation(true);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps.info) !== JSON.stringify(this.props.info)) {
      this.setState({ fieldRefs: false });
      return;
    }
    for (
      let i = 0;
      i < Object.keys(nextProps.process.data.fields).length;
      i++
    ) {
      let key = Object.keys(nextProps.process.data.fields)[i];
      let changed = false;
      if (
        !nextProps.process.data.fields[key] &&
        !this.props.process.data.fields[key]
      )
        continue;
      else if (
        !nextProps.process.data.fields[key] &&
        this.props.process.data.fields[key]
      )
        changed = true;
      else if (
        nextProps.process.data.fields[key] &&
        !this.props.process.data.fields[key]
      )
        changed = true;
      else if (
        !nextProps.process.data.fields[key].value &&
        !this.props.process.data.fields[key].value
      )
        continue;
      else if (
        !nextProps.process.data.fields[key].value &&
        this.props.process.data.fields[key].value
      )
        changed = true;
      else if (
        nextProps.process.data.fields[key].value &&
        !this.props.process.data.fields[key].value
      )
        changed = true;
      else if (
        nextProps.process.data.fields[key].value.toString() !==
        this.props.process.data.fields[key].value.toString()
      )
        changed = true;
      else if (
        !isEqual(
          get(nextProps, `process.data.fields.${key}.value`),
          get(this.props, `process.data.fields.${key}.value`)
        )
      ) {
        changed = true;
      }
      if (changed) {
        if (["order_stations", "order_sold", "key"].indexOf(key) > -1) {
          this.setState({ fieldRefs: false });
          break;
        }
      }
    }
  }

  setIsFieldsChanged = (isFieldsChanged) => {
    if (this.state.isFieldsChanged !== isFieldsChanged) {
      this.setState({ isFieldsChanged });
    }
  };
  onToggleReminderMissingSelectStationsModal = () => {
    this.setState((prevState) => ({
      isOpenReminderMissingSelectStations: !prevState.isOpenReminderMissingSelectStations,
    }));
  };

  onToggleTemplateSelectionModal = () => {
    this.setState((prevState) => ({
      isOpenTemplateSelectionModal: !prevState.isOpenTemplateSelectionModal,
    }));
  };

  onToggleRotationPromptModal = () => {
    this.setState((prevState) => ({
      isOpenRotationPromptModal: !prevState.isOpenRotationPromptModal,
    }));
  };

  setIsOpenSuccessDialog = (isOpenSuccessDialog) => {
    this.setState({
      isOpenSuccessDialog,
    });
  };

  onCheckBoxChange = (item, process, template, user, checked) => {
    this.props.checkboxChanged(item, process, template, user, {
      target: { checked },
    });
  };
  checkMissingSelectStationsForSpot = () => {
    const { auth } = this.props;
    let selectedOtherSpotsOrderStations = [];
    const stationsData = get(auth, "info.stations", []);
    get(this.props, "process.data.fields.related_spots.value", []).forEach(
      (spot) => {
        if (spot.id !== get(this.props, "process.data.fields.key.value")) {
          const intersectionOrderStations = spot.order_stations.filter(
            (item) => !selectedOtherSpotsOrderStations.includes(item)
          );
          selectedOtherSpotsOrderStations = [
            ...selectedOtherSpotsOrderStations,
            ...intersectionOrderStations,
          ];
        }
      }
    );
    const contractStations = get(
      this.props,
      "process.data.fields.contract_stations.value",
      []
    );
    const contractStationsHaveToCheck = contractStations.filter(
      (item) => !selectedOtherSpotsOrderStations.includes(item)
    );
    const selectedStations = get(
      this.props,
      "process.data.fields.order_stations.value",
      []
    );
    const intersectionCurrentSpot = contractStationsHaveToCheck
      .filter((item) => !selectedStations.includes(item))
      .map((item) => {
        return get(
          find(stationsData, (i) => i.station_key === item),
          "name",
          item
        );
      });
    this.setState({
      missingSelectStations: intersectionCurrentSpot,
    });
    return intersectionCurrentSpot.length > 0;
  };

  switchToSpot(key) {
    const { intl } = this.props;
    if (key === "new") {
      ToastManager.show({
        title: intl.formatMessage("toast > title not saved"),
        message: intl.formatMessage(
          "toast > message please save the order before switching to another spot"
        ),
        level: "error",
      });
      return;
    }
    let template = this.props.template;
    this.props.history.push("/processes/edit/" + template.key + "/" + key);
  }

  renderSoldSpecSwitch() {
    const { process, step, template, user } = this.props;
    let order_sold = step.fields.find((f) => f.field === "order_sold");
    const isChecked = get(process, "data.fields.order_sold.value", false);
    return (
      <div className={bem.e("order-switch-type")}>
        <ToggleSwitch
          leftComponent={
            <span className={"order-switch-text"}>
              <FormattedMessage id="process > title spec" />
            </span>
          }
          rightComponent={
            <span className={"order-switch-text"}>
              <FormattedMessage id="process > title sold" />
            </span>
          }
          switchProps={{
            onChange: (checked) => {
              this.onCheckBoxChange(
                order_sold,
                process,
                template,
                user,
                checked
              );
              this.setIsFieldsChanged(true);
            },
            checked: isChecked,
            disabled: false,
            uncheckedIcon: false,
            checkedIcon: false,
            offColor: "#795AFA",
            onColor: "#795AFA",
            handleDiameter: 22,
            width: 50,
            height: 26,
          }}
        />
      </div>
    );
  }
  renderModalSpot = () => {
    const { template, process, item, step } = this.props;
    return (
      <Modal
        isOpen={this.state.isOpenDialog}
        className={"production-duplicate-modal"}
        centered
        toggle={() => this.setState({ isOpenDialog: !this.state.isOpenDialog })}
      >
        <ModalBody>
          <OrderDetailsDuplicate
            process={process}
            template={template}
            item={item}
            step={step}
            extraData={this.state.anotherSpotData}
            close={() => this.setState({ isOpenDialog: false })}
            setIsOpenSuccessDialog={this.setIsOpenSuccessDialog}
          />
        </ModalBody>
      </Modal>
    );
  };
  renderTemplateSelectionModal = () => {
    const { process, template, step, user, saveFormValues } = this.props;

    return (
      <ConfirmationModal
        centered
        isOpen={this.state.isOpenTemplateSelectionModal}
        className={"production-prompt-modal production-prompt-details-modal"}
        title={
          <FormattedMessage id="process > title who will produce the spots for this order" />
        }
        confirmTitle={
          <div className="btn-content">
            <AddIcon color="#fff" />
            <FormattedMessage id="process > button production needed" />
          </div>
        }
        cancelTitle={
          <div className="btn-content">
            <AddIcon color="#fff" />
            <FormattedMessage id="process > button production provided" />
          </div>
        }
        descriptionBottom={
          <div className={"description-spots-button"}>
            <div className={"description-left"}>
              <FormattedMessage id="process > description the spots need to be produced" />
            </div>
            <div className={"description-right"}>
              <FormattedMessage id="process > description the client or agency is producing the spots" />
            </div>
          </div>
        }
        onConfirm={() => {
          const {
            draft_template,
            template_selected_for_wo,
          } = this.getFieldRefs();
          this.setState({
            isOpenTemplateSelectionModal: false,
          });

          this.onValueChanged(draft_template, "basic_production");
          this.onValueChanged(template_selected_for_wo, "basic_production");

          process.data.fields.draft_template = {
            value: "basic_production",
            is_dirty: true,
          };

          process.data.fields.template_selected_for_wo = {
            value: "basic_production",
            is_dirty: true,
          };

          saveFormValues(
            {
              process,
              template,
              step,
              user,
              success_callback: false,
              valid: true,
              isForce: true,
            },
            () => {
              this.props.history.push(
                "/processes/edit/basic_production/" + process.key
              );
            }
          );
        }}
        onCancel={() => {
          const {
            draft_template,
            template_selected_for_wo,
          } = this.getFieldRefs();
          this.setState({
            isOpenTemplateSelectionModal: false,
          });
          this.onValueChanged(draft_template, "production_provided");
          this.onValueChanged(template_selected_for_wo, "production_provided");

          process.data.fields.draft_template = {
            value: "production_provided",
            is_dirty: true,
          };

          process.data.fields.template_selected_for_wo = {
            value: "production_provided",
            is_dirty: true,
          };

          saveFormValues(
            {
              process,
              template,
              step,
              user,
              success_callback: false,
              valid: true,
              isForce: true,
            },
            () => {
              this.props.history.push(
                "/processes/edit/production_provided/" + process.key
              );
            }
          );
        }}
        onToggle={this.onToggleTemplateSelectionModal}
      />
    );
  };

  renderRotationPromptModal = () => {
    return (
      <ConfirmationModal
        centered
        isOpen={this.state.isOpenRotationPromptModal}
        className={"production-prompt-modal production-prompt-details-modal"}
        title={
          <FormattedMessage id="process > title select a production option for this spot" />
        }
        confirmTitle={
          <div className="btn-content">
            <AddIcon color="#fff" />
            <FormattedMessage id="process > button production needed" />
          </div>
        }
        cancelTitle={
          <div className="btn-content">
            <AddIcon color="#fff" />
            <FormattedMessage id="process > button production provided" />
          </div>
        }
        descriptionBottom={
          <div className={"description-spots-button"}>
            <div className={"description-left"}>
              <FormattedMessage id="process > description the spots need to be produced" />
            </div>
            <div className={"description-right"}>
              <FormattedMessage id="process > description the client or agency is producing the spots" />
            </div>
          </div>
        }
        onConfirm={() =>
          this.handleSelectRotation({ template_key: "basic_production" })
        }
        onCancel={() => {
          this.handleSelectRotation({ template_key: "production_provided" });
        }}
        onToggle={this.onToggleRotationPromptModal}
      />
    );
  };

  getFieldRefs() {
    if (this.state.fieldRefs) return this.state.fieldRefs;
    let { template, step } = this.props;

    step = template.steps.order_details;

    let order_title = step.fields.find((f) => f.field === "order_title");

    let select_stations = step.fields.find((f) => f.field === "order_stations");
    let order_sold = step.fields.find((f) => f.field === "order_sold");
    let spot_info = step.fields.find((f) => f.field === "spot_info");
    let spots_field = step.fields.find((f) => f.field === "spots");
    let rotation_chart = step.fields.find((f) => f.field === "rotation_chart");
    let timezone = step.fields.find((f) => f.field === "timezone");
    let draft_template = step.fields.find((f) => f.field === "draft_template");
    let template_selected_for_wo = step.fields.find(
      (f) => f.field === "template_selected_for_wo"
    );
    let markets = step.fields.find((f) => f.field === "markets");
    let contract_status = step.fields.find(
      (f) => f.field === "contract_status"
    );

    let payload = {
      order_title,
      select_stations,
      order_sold,
      timezone,
      spot_info,
      spots_field,
      rotation_chart,
      draft_template,
      template_selected_for_wo,
      markets,
      contract_status,
    };
    this.setState({ fieldRefs: payload });
    return payload;
  }

  onValueChanged = (field, value) => {
    const { valueChanged, template, user } = this.props;
    valueChanged(field, process, template, user, { value });
    this.setIsFieldsChanged(true);
  };

  onValueTimezoneChanged = (orderStations) => {
    const { auth } = this.props;
    const { timezone } = this.getFieldRefs();
    let timezoneValue;
    let stations = get(auth, "info.stations", []).filter(
      (station) => station.station_key
    );

    if (orderStations.length === 1) {
      const selectStation = orderStations[0];
      const stationWithData = stations.find(
        (item) => item.station_key === selectStation
      );
      if (stationWithData) {
        timezoneValue = stationWithData.timezone;
      }
    } else {
      let listTimezone = [];
      stations.forEach((item) => {
        if (
          includes(orderStations, item.station_key) &&
          item.timezone &&
          !includes(listTimezone, item.timezone)
        ) {
          const gmt = moment.tz(item.timezone).format("Z");
          listTimezone.push({
            key: parseInt(gmt),
            gmt: gmt,
            timezone: item.timezone,
          });
        }
      });
      listTimezone = orderBy(listTimezone, ["key"], ["desc"]);
      timezoneValue = get(listTimezone, `0.timezone`);
    }
    if (!timezoneValue) {
      timezoneValue = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    this.onValueChanged(timezone, timezoneValue);
  };

  handleSelectRotation = (params = {}) => {
    this.setState({
      isOpenRotationPromptModal: false,
    });
    this.addRotationSpot(params);
  };
  /*
  onClickAddSpot = (postData = {}) => {
    const { rotation_chart } = this.getFieldRefs();
    const { process, template, step, user } = this.props;
    const isProductionNeeded =
      get(process, "data.fields.contract_status.value") === "Production Needed";
    const isProductionComplete =
      get(process, "data.fields.contract_status.value") ===
      "Production Complete";
    const isProductionProvidedNeededTags =
      get(process, "data.fields.contract_status.value") ===
      "Production Provided - Needs tags";
    let paramButton = {
      ...step.fields.find((f) => f.key === "save_button"),
      // Commented this line. Because we don't need update the process_step to draft and next_users to current user for origin order (All spots in rotation should have independent workflows.)
      // https://tasks.getventive.com/projects/53802-FFA
      // add_rotation: true,
    };
    this.props.buttonClicked(
      step,
      paramButton,
      process,
      template,
      user,
      null,
      ({ data }) => {
        if (data) {
          this.props.setShouldBlockNavigation(true);
          // call duplicate with new spot
          if (isProductionNeeded) {
            const spotInfo = get(process, "data.fields.spot_info.value", []);

            const spot = find(spotInfo, (spot) => spot.key === process.key);

            let newSpotInfo = {
              title: "",
              spot_type: get(spot, "spot_type", "Standard"),
              line_hashes: get(spot, "line_hashes", []),
              isci: "",
              voice_preference: "",
              script_requires_approval: false,
              recording_requires_approval: true,
              aircheck_requires_approval: false,
              writer_sends_script_to_client: false,
              producer_sends_spot_to_client: false,
              script: "",
              draft_script: "",
              script_type: "draft",
              is_upload_script_file: false,
              files: [],
              uuid: uuidv4(),
            };

            const spots = [...spotInfo, newSpotInfo];

            const rotationChart = generateRotationChart(
              process,
              get(process, "data.fields.order_stations.value", []),
              spots
            );
            const newMarkets = this.onSyncSpotInfoMarkets({
              spot_info: spots,
              rotation_chart: rotationChart,
            });
            this.props.duplicate({
              process_key: data.key,
              success_callback: (key) => {
                this.props.history.push({
                  pathname: `/processes/edit/${template.key}/${key}`,
                  state: {
                    isKeepPosition: true,
                  },
                });
              },
              post_data: {
                ...postData,
                new_order: false,
                button: "production_queue",
                rotation_chart: rotationChart,
                markets: newMarkets,
              },
            });
          }
          // redirect to edit the order
          if (isProductionComplete || isProductionProvidedNeededTags) {
            this.props.history.push(
              "/processes/edit/" + template.key + "/" + data.key
            );
            setTimeout(() => {
              const spotInfoField = step.fields.find(
                (f) => f.field === "spot_info"
              );
              // Add new spot container
              const spotInfo = get(process, "data.fields.spot_info.value", []);

              const spot = find(spotInfo, (spot) => spot.key === data.key);

              let newSpotInfo = {
                title: "",
                spot_type: get(spot, "spot_type", "Standard"),
                line_hashes: get(spot, "line_hashes", []),
                isci: "",
                recording_path: "",
                recording_path_b: "",
                recording_audit: "",
                recording_path_b_audit: "",
                cart_number: "",
                uuid: uuidv4(),
              };

              const spotWithUseAudioForAll = find(
                get(process, "data.fields.spot_info.value", []),
                (item) => item.is_audio_for_all
              );
              // assign audio when have an spot selected use this audio for all spots
              if (isProductionProvidedNeededTags && spotWithUseAudioForAll) {
                newSpotInfo.recording_path =
                  spotWithUseAudioForAll.recording_path;
                newSpotInfo.recording_path_b =
                  spotWithUseAudioForAll.recording_path_b;
              }
              const spots = [...spotInfo, newSpotInfo];
              const rotationChart = generateRotationChart(
                process,
                get(process, "data.fields.order_stations.value", []),
                spots
              );
              this.props.valueChanged(rotation_chart, process, template, user, {
                value: rotationChart,
              });
              this.props.valueChanged(spotInfoField, process, template, user, {
                value: spots,
              });
              this.onSyncSpotInfoMarkets({
                spot_info: spots,
              });
            }, 1000);
          }
        }
      },
      true
    );
  };
  */
  // onCreateProductionNeedSpot = (processKey, postData = {}, spotData = {}) => {
  //   const { process, template } = this.props;
  //   const spotInfo = get(process, "data.fields.spot_info.value", []);

  //   const spot = find(spotInfo, (spot) => spot.key === process.key);
  //   const newSpotInfo = {
  //     title: "",
  //     spot_type: get(spot, "spot_type", "Standard"),
  //     line_hashes: get(spot, "line_hashes", []),
  //     isci: "",
  //     voice_preference: "",
  //     script_requires_approval: false,
  //     recording_requires_approval: true,
  //     aircheck_requires_approval: false,
  //     writer_sends_script_to_client: false,
  //     producer_sends_spot_to_client: false,
  //     script: "",
  //     draft_script: "",
  //     script_type: "draft",
  //     is_upload_script_file: false,
  //     files: [],
  //     uuid: uuidv4(),
  //     contact_status: "",
  //     order_length_custom: "",
  //     order_length: "",
  //     length1: "",
  //     length2: "",
  //     due_date: "",
  //     ...spotData,
  //   };

  //   const spots = [...spotInfo, newSpotInfo];
  //   const rotationChart = generateRotationChart(
  //     process,
  //     get(process, "data.fields.order_stations.value", []),
  //     spots
  //   );
  //   const newMarkets = this.onSyncSpotInfoMarkets({
  //     spot_info: spots,
  //     rotation_chart: rotationChart,
  //   });

  //   this.props.duplicate({
  //     process_key: processKey,
  //     success_callback: (key) => {
  //       this.props.history.push({
  //         pathname: `/processes/edit/${template.key}/${key}`,
  //         state: {
  //           isKeepPosition: true,
  //         },
  //       });
  //     },
  //     post_data: {
  //       ...postData,
  //       new_order: false,
  //       button: "production_queue",
  //       rotation_chart: rotationChart,
  //       markets: newMarkets,
  //       new_spot_data: newSpotInfo,
  //     },
  //   });
  // };

  // onCreateProductionProvidedSpot = (processKey) => {
  //   const { process, template, step, user } = this.props;
  //   const { rotation_chart } = this.getFieldRefs();
  //   const isProductionProvidedNeededTags =
  //     get(process, "data.fields.contract_status.value") ===
  //     "Production Provided - Needs tags";
  //   this.props.history.push(
  //     "/processes/edit/" + template.key + "/" + processKey
  //   );
  //   setTimeout(() => {
  //     const spotInfoField = step.fields.find((f) => f.field === "spot_info");
  //     // Add new spot container
  //     const spotInfo = get(process, "data.fields.spot_info.value", []);

  //     const spot = find(spotInfo, (spot) => spot.key === processKey);

  //     let newSpotInfo = {
  //       title: "",
  //       spot_type: get(spot, "spot_type", "Standard"),
  //       line_hashes: get(spot, "line_hashes", []),
  //       isci: "",
  //       recording_path: "",
  //       recording_path_b: "",
  //       recording_audit: "",
  //       recording_path_b_audit: "",
  //       cart_number: "",
  //       uuid: uuidv4(),
  //     };

  //     const spotWithUseAudioForAll = find(
  //       get(process, "data.fields.spot_info.value", []),
  //       (item) => item.is_audio_for_all
  //     );
  //     // assign audio when have an spot selected use this audio for all spots
  //     if (isProductionProvidedNeededTags && spotWithUseAudioForAll) {
  //       newSpotInfo.recording_path = spotWithUseAudioForAll.recording_path;
  //       newSpotInfo.recording_path_b = spotWithUseAudioForAll.recording_path_b;
  //     }
  //     const spots = [...spotInfo, newSpotInfo];
  //     const rotationChart = generateRotationChart(
  //       process,
  //       get(process, "data.fields.order_stations.value", []),
  //       spots
  //     );
  //     this.props.valueChanged(rotation_chart, process, template, user, {
  //       value: rotationChart,
  //     });
  //     this.props.valueChanged(spotInfoField, process, template, user, {
  //       value: spots,
  //     });
  //     this.onSyncSpotInfoMarkets({
  //       spot_info: spots,
  //     });
  //   }, 1000);
  // };

  // onCreateNewSpot = (postData = {}, spotData = {}) => {
  //   const { process, template, step, user } = this.props;
  //   const isProductionNeeded =
  //     get(process, "data.fields.contract_status.value") === "Production Needed";
  //   const isProductionComplete =
  //     get(process, "data.fields.contract_status.value") ===
  //     "Production Complete";
  //   const isProductionProvidedNeededTags =
  //     get(process, "data.fields.contract_status.value") ===
  //     "Production Provided - Needs tags";
  //   let paramButton = {
  //     ...step.fields.find((f) => f.key === "save_button"),
  //     // Commented this line. Because we don't need update the process_step to draft and next_users to current user for origin order (All spots in rotation should have independent workflows.)
  //     // https://tasks.getventive.com/projects/53802-FFA
  //     // add_rotation: true,
  //   };
  //   this.props.buttonClicked(
  //     step,
  //     paramButton,
  //     process,
  //     template,
  //     user,
  //     null,
  //     ({ data }) => {
  //       if (data) {
  //         this.props.setShouldBlockNavigation(true);
  //         // call duplicate with new spot
  //         if (isProductionNeeded) {
  //           this.onCreateProductionNeedSpot(data.key, postData, spotData);
  //         }
  //         // redirect to edit the order
  //         if (isProductionComplete || isProductionProvidedNeededTags) {
  //           this.onCreateProductionProvidedSpot(data.key);
  //         }
  //       }
  //     },
  //     true
  //   );
  // };

  addRotationSpot = (params = {}) => {
    const { process, template, step, user } = this.props;
    const processKey = get(this.props, "process.data.fields.key.value", "");
    if (!processKey) return;
    const { rotation_chart } = this.getFieldRefs();
    const productionSettings = get(this.props, "productionSettings", {});
    const newTemplateKey = params.template_key;
    const currentTemplateKey = get(
      this.props,
      "process.data.fields.template_key.value",
      ""
    );
    /**
     * Case1: when a production provided spot is added to a production provided order. We just add to spot data info of the order. Don't need to create new order url
     */
    if (
      newTemplateKey === "production_provided" &&
      currentTemplateKey === "production_provided"
    ) {
      const commentField = step.fields.find((f) => f.field === "comment");
      const spotInfoField = step.fields.find((f) => f.field === "spot_info");
      const isProductionProvidedNeededTagsOfOrder =
        get(process, "data.fields.contract_status.value") ===
        "Production Provided - Needs tags";
      // Add new spot container
      const spotInfo = get(process, "data.fields.spot_info.value", []);

      const spot = find(spotInfo, (spot) => spot.key === processKey);

      let newSpotInfo = {
        title: "",
        spot_type: get(spot, "spot_type", "Standard"),
        line_hashes: get(spot, "line_hashes", []),
        isci: "",
        recording_path: "",
        recording_path_b: "",
        recording_audit: "",
        recording_path_b_audit: "",
        cart_number: "",
        role_producer: "",
        role_dubber: "",
        length1: "00",
        length2: "00",
        order_length:
          productionSettings.default_spot_length ||
          get(spot, "order_length", ""),
        order_length_custom: Boolean(productionSettings.custom_spot_length),
        due_date: get(spot, "due_date", ""),
        contract_status: "Production Complete",
        template_key: "production_provided",
        uuid: uuidv4(),
        key: processKey, // linked to current order
      };

      const spotWithUseAudioForAll = find(
        get(process, "data.fields.spot_info.value", []),
        (item) => item.is_audio_for_all
      );
      // assign audio when have an spot selected use this audio for all spots
      if (isProductionProvidedNeededTagsOfOrder && spotWithUseAudioForAll) {
        newSpotInfo.recording_path = spotWithUseAudioForAll.recording_path;
        newSpotInfo.recording_path_b = spotWithUseAudioForAll.recording_path_b;
      }
      const spots = [...spotInfo, newSpotInfo];
      const rotationChart = generateRotationChart(
        process,
        get(process, "data.fields.order_stations.value", []),
        spots
      );
      this.props.valueChanged(rotation_chart, process, template, user, {
        value: rotationChart,
      });
      this.props.valueChanged(spotInfoField, process, template, user, {
        value: spots,
      });
      // reset comment
      this.props.valueChanged(commentField, process, template, user, {
        value: {
          message: "",
          tagged: [],
        },
      });
      this.onSyncSpotInfoMarkets({
        spot_info: spots,
      });
      return;
    }
    let isOpenNewOrderUrl = true;
    /**
     * Case1: when a production provided spot is added to a production needed order, we need to create a new order
     * Case2: When a new production needed spot is added to a production needed order
     * Case3: When a new production needed spot is added to a production provided order
     */
    if (
      (newTemplateKey === "production_provided" &&
        currentTemplateKey === "basic_production") ||
      (newTemplateKey === "basic_production" &&
        currentTemplateKey === "basic_production") ||
      (newTemplateKey === "basic_production" &&
        currentTemplateKey === "production_provided")
    ) {
      isOpenNewOrderUrl = true;
    }

    this.props.addRotation(
      {
        ...params,
        id: processKey,
      },
      (newProcessKey) => {
        if (isOpenNewOrderUrl) {
          this.props.history.push({
            pathname: `/processes/edit/${newTemplateKey}/${newProcessKey}`,
            state: {
              isKeepPosition: true,
              spotSwitching: true,
            },
          });
        } else {
          // call api to reload process data
          this.props.getProcess({
            template_id: newTemplateKey,
            process_id: newProcessKey,
          });
        }
      }
    );
  };
  onClickAddSpot = (postData = {}) => {
    const { process, template, step, user } = this.props;
    const isAdd = document.location.href.indexOf("processes/add") > -1;
    const spotInfo = get(process, "data.fields.spot_info.value", []);
    const totalSpots = spotInfo.length;
    if (totalSpots >= 8) {
      ToastManager.show({
        message: this.props.intl.formatMessage({
          id: "toast > message no more than 8 spots can be added to rotation",
        }),
        level: "error",
      });
      return;
    }
    const buttonParams = {
      ...step.fields.find((f) => f.key === "save_button"),
      draft: true,
      add_to_rotation: true,
    };
    this.props.buttonClicked(
      step,
      buttonParams,
      process,
      template,
      user,
      null,
      ({ data }) => {
        if (!data) return;
        if (isAdd) {
          this.props.history.push({
            pathname: `/processes/edit/${data.template_key}/${data.key}`,
            state: {
              isKeepPosition: true,
              spotSwitching: true,
            },
          });
        }
        // PRODUCTION (BELL) - Hybrid Order with Prod Needed and Provided => https://tasks.getventive.com/projects/43CA8-12E
        this.setState({
          isOpenRotationPromptModal: true,
        });
        this.props.setShouldBlockNavigation(false);
      },
      true
    );
  };
  onDeleteSpotInfoMarkets = (spot) => {
    const { markets } = this.getFieldRefs();
    const { process, template, user, auth } = this.props;
    const isEnableEnterprise = get(auth, "info.production_multimarket", false);

    if (isEnableEnterprise) {
      let selectedMarkets = get(process, "data.fields.markets.value", []);
      selectedMarkets = map(selectedMarkets, (market) => {
        const spot_info = filter(
          market.spot_info,
          (spotInfoItem) => spotInfoItem.uuid !== spot.uuid
        );
        const rotationChart = generateRotationChart(
          {
            data: {
              fields: {
                rotation_chart: {
                  value: get(market, "rotation_chart", []),
                },
              },
            },
          },
          get(market, "order_stations", []),
          spot_info
        );
        return {
          ...market,
          spot_info,
          rotation_chart: rotationChart,
        };
      });
      this.props.valueChanged(markets, process, template, user, {
        value: selectedMarkets,
      });
      return selectedMarkets;
    }
    return [];
  };
  onSyncSpotInfoMarkets = ({ spot_info }) => {
    const { markets } = this.getFieldRefs();
    const { process, template, user, auth } = this.props;
    const isEnableEnterprise = get(auth, "info.production_multimarket", false);

    if (isEnableEnterprise) {
      let selectedMarkets = get(process, "data.fields.markets.value", []);
      selectedMarkets = map(selectedMarkets, (market) => {
        const rotationChart = generateRotationChart(
          {
            data: {
              fields: {
                rotation_chart: {
                  value: get(market, "rotation_chart", []),
                },
              },
            },
          },
          get(market, "order_stations", []),
          spot_info
        );
        return {
          ...market,
          spot_info,
          rotation_chart: rotationChart,
        };
      });
      this.props.valueChanged(markets, process, template, user, {
        value: selectedMarkets,
      });
      return selectedMarkets;
    }
    return [];
  };
  checkNoStationsRepresented = () => {
    // If no stations are represented in any of the spots will toast error
    const selectedStations = get(
      this.props,
      "process.data.fields.order_stations.value",
      []
    );
    if (selectedStations.length === 0) {
      ToastManager.show({
        title: this.props.intl.formatMessage({
          id: "toast > title not saved",
        }),
        message: this.props.intl.formatMessage({
          id:
            "toast > message please correct at least one station has to be selected",
        }),
        level: "error",
      });
      return true;
    }
    return false;
  };

  checkNoAudienceSelected = () => {
    const { auth } = this.props;
    const isEnableEnterprise = get(auth, "info.production_multimarket", false);
    if (!isEnableEnterprise) return false;
    // If no stations are represented in any of the spots will toast error
    const selectedAudience = get(
      this.props,
      "process.data.fields.audience.value",
      []
    );
    if (
      selectedAudience.length === 0 &&
      get(this.props.user, "production_multimarket", false)
    ) {
      ToastManager.show({
        title: this.props.intl.formatMessage({
          id: "toast > title not saved",
        }),
        message: this.props.intl.formatMessage({
          id: "process > please select audience",
        }),
        level: "error",
      });
      return true;
    }
    return false;
  };

  onClickDeleteSpot = (spotIndex) => {
    this.setState({
      currentSpotDeleteIndex: spotIndex,
      isOpenDialogDeleteSpot: true,
    });
  };
  onDeleteSpotInSpotInfo = () => {
    let { process, template, user, valueChanged, auth } = this.props;
    const isEnableEnterprise = get(auth, "info.production_multimarket", false);
    let { spot_info, rotation_chart } = this.getFieldRefs();
    let currentSpotInfo = get(process, "data.fields.spot_info.value", []);
    const spotIndex = this.state.currentSpotDeleteIndex;
    const spotRemove = currentSpotInfo[spotIndex];
    if (!spotRemove) {
      return;
    }
    currentSpotInfo.splice(spotIndex, 1);
    const rotationChart = generateRotationChart(
      process,
      get(process, "data.fields.order_stations.value", []),
      currentSpotInfo
    );
    // update rotation chart
    valueChanged(rotation_chart, process, template, user, {
      value: rotationChart,
    });

    valueChanged(spot_info, process, template, user, {
      value: currentSpotInfo,
    });
    if (isEnableEnterprise) {
      this.onDeleteSpotInfoMarkets(spotRemove);
    }
    this.setState({ isOpenDialogDeleteSpot: false });
  };

  onConfirmDeleteSpot = () => {
    let { process, template, user, valueChanged, auth, step } = this.props;
    const isEnableEnterprise = get(auth, "info.production_multimarket", false);
    const { rotation_chart, spot_info, contract_status } = this.getFieldRefs();
    const currentSpotInfo = get(process, "data.fields.spot_info.value", []);
    const spotIndex = this.state.currentSpotDeleteIndex;
    const spotRemove = currentSpotInfo[spotIndex];
    this.setState({ isOpenDialogDeleteSpot: false });

    if (!spotRemove) {
      return;
    }

    let isCallApiDeleteSpot = false;
    if (spotRemove.template_key === "production_provided") {
      isCallApiDeleteSpot =
        currentSpotInfo.filter(
          (item) =>
            item.uuid !== spotRemove.uuid &&
            item.template_key === "production_provided"
        ).length === 0;
    } else {
      isCallApiDeleteSpot = true;
    }
    if (isCallApiDeleteSpot) {
      this.props.deleteSpotProcess(spotRemove["key"], (response) => {
        const nextOrderId = trim(response.data);
        const nextSpot = nextOrderId
          ? currentSpotInfo.find((item) => item.key === nextOrderId)
          : null;
        if (nextSpot) {
          this.props.history.push(
            "/processes/edit/" + nextSpot["template_key"] + "/" + nextOrderId
          );
          this.props.getProcess({
            template_id: nextSpot["template_key"],
            process_id: nextOrderId,
          });
        } else {
          this.props.getProcess({
            template_id: spotRemove["template_key"],
            process_id: spotRemove["key"],
          });
        }
      });
    } else {
      // Otherwise just call the process update api by removing the deleted spot from the spot_info
      currentSpotInfo.splice(spotIndex, 1);
      const rotationChart = generateRotationChart(
        process,
        get(process, "data.fields.order_stations.value", []),
        currentSpotInfo
      );
      // update rotation chart
      valueChanged(rotation_chart, process, template, user, {
        value: rotationChart,
      });

      valueChanged(spot_info, process, template, user, {
        value: currentSpotInfo,
      });
      const contractStatus = get(
        process,
        "data.fields.contract_status.value",
        ""
      );
      // recheck contract status for production provided
      if (
        ["Production Provided - Needs tags", "Production Complete"].includes(
          contractStatus
        )
      ) {
        let newContactStatus = "Production Complete";
        if (
          find(
            currentSpotInfo,
            (item) =>
              item.contract_status === "Production Provided - Needs tags"
          )
        ) {
          newContactStatus = "Production Provided - Needs tags";
        }
        valueChanged(contract_status, process, template, user, {
          value: newContactStatus,
        });
        process.data.fields.contract_status = {
          value: newContactStatus,
          is_dirty: true,
        };
      }
      if (isEnableEnterprise) {
        this.onDeleteSpotInfoMarkets(spotRemove);
      }
      this.setState({ isOpenDialogDeleteSpot: false });
      process.data.fields.rotation_chart = {
        value: rotationChart,
        is_dirty: true,
      };
      process.data.fields.spot_info = {
        value: currentSpotInfo,
        is_dirty: true,
      };
      // call api update
      this.props.buttonClicked(
        step,
        step.fields.find((f) => f.key === "save_button"),
        process,
        template,
        user,
        null,
        null,
        true
      );
    }
  };

  onConvertToSold = () => {
    const { process } = this.props;
    const processKey = get(process, "data.fields.key.value", "");
    this.props.convertSpecToSold(
      {
        id: processKey,
      },
      (data) => {
        this.props.getProcess({
          template_id: process.data.fields.template_key.value,
          process_id: processKey,
          spotSwitching: true,
        });
        history.replace(
          URL.UPDATE_PROCESS({
            template_id: process.data.fields.template_key.value,
            process_id: processKey,
          })
        );
        ToastManager.show({
          message: this.props.intl.formatMessage({
            id: "toast > title convert spec to sold success",
          }),
          level: "success",
        });
      }
    );
  };

  onUnArchive = () => {
    const processKey = get(this.props, "process.data.fields.key.value", "");
    this.props.unarchiveProcess(processKey, () => {
      ToastManager.show({
        message: this.props.intl.formatMessage({
          id: "toast > title saved",
        }),
        level: "success",
      });
    });
  };

  renderEditButton = () => {
    const { template, step, process, user, auth } = this.props;
    const isTabCanEditByStage = getEditableTabsByStage(step, process, user);
    const {
      isAssigned,
      isEditor,
      isUserCompletedStep,
      isUserAssignedToStepRoles,
      isStepCanEditAnyTime,
    } = getEditableTabByUser({
      step,
      process,
      user,
      template: template.key,
      userInfo: auth.info,
    });
    let isShowEditButton = false;
    const isTabCanEditByUser = isAssigned && isEditor;
    if (
      (isTabCanEditByStage && isTabCanEditByUser) || // case when user go to the tab from current tasks
      (isEditor && isUserAssignedToStepRoles && isStepCanEditAnyTime) || // case order details can edit any time by config
      (isEditor && isUserCompletedStep)
    ) {
      // case when user assigned to step by role and completed this step
      isShowEditButton = true;
    }
    const isDisabled = !isShowEditButton;
    return (
      <>
        <Button
          id="edit-button-row"
          disabled={isDisabled}
          color="blue"
          className="button-edit button-edit-auto"
          onClick={() => {
            this.props.history.push(
              "/processes/edit/" +
                template.key +
                "/" +
                get(process, "data.fields.key.value")
            );
            this.props.setActiveEditTab(step.key);
          }}
        >
          <FormattedMessage id="process > button edit" />
        </Button>
        {isDisabled && (
          <UncontrolledTooltip
            placement="top-start"
            delay={0}
            target="edit-button-row"
          >
            <FormattedMessage id="process > button edit disabled" />
          </UncontrolledTooltip>
        )}
      </>
    );
  };

  renderAnotherSpot = ({ has_order_stations }) => {
    const { process, auth } = this.props;
    const privileges = get(auth, "user.privileges", []);
    const user = get(auth, "user", {});
    // const spotInfo = get(process, "data.fields.spot_info.value", []);
    // const isSpecOrder = get(process, "data.fields.order_sold.value") === false;
    const aeUser = get(process, "data.fields.role_account_executive.value");
    const isShowAnotherButton = !!get(process, "key");
    if (!isShowAnotherButton) return null;
    const isValidPrivileges = validatePrivileges({
      requires: [
        PRIVILEGES.ADD_PRODUCTION,
        PRIVILEGES.VIEW_ALL_PRODUCTION_ORDERS,
      ],
      privileges,
      user,
    });
    const isAeUser = aeUser && Number(aeUser.id) === Number(user.id);

    if (!isValidPrivileges && !isAeUser) return null;
    return (
      <Button
        block
        disabled={!has_order_stations}
        color={!process.data.fields.key ? "grey" : "blue"}
        outline
        className="button-another-spot"
        onClick={() => {
          this.setState({
            isOpenCopyOrderModal: true,
          });
        }}
      >
        <FormattedMessage id="process > button another spot" />
      </Button>
    );
  };

  renderSendBackButton() {
    const { template, user, process } = this.props;
    let isAdd =
      document.location.pathname.split("/")[2] === "add" ? true : false;
    const showButton =
      ["basic_production", "production_provided"].indexOf(template.key) !==
        -1 && !isAdd;
    const isValidPrivileges = checkAllowSendBack({ process, user });

    if (showButton && isValidPrivileges) {
      return (
        <Button
          color="link"
          outline
          className="btn-radius btn-send-back"
          onClick={() =>
            this.setState({
              isOpenSendBackOrder: true,
            })
          }
        >
          <FormattedMessage id={`process > button send back`} />
        </Button>
      );
    }
    return null;
  }

  renderView() {
    let {
      process,
      template,
      user,
      step,
      valueChanged,
      validationState,
      multipleStationSelectionChanged,
      auth,
      dateFormatByServer,
    } = this.props;
    let { select_stations } = this.getFieldRefs();
    let has_order_stations =
      !!process.data.fields[select_stations.field] &&
      process.data.fields[select_stations.field].value.length > 0;

    const spotInfo = get(process, "data.fields.spot_info.value", []);

    const isProductionNeeded =
      get(process, "data.fields.contract_status.value") === "Production Needed";
    const isSpecOrder = get(process, "data.fields.order_sold.value") === false;
    if (
      isEmpty(get(process, "data.fields.template_selected_for_wo.value", "")) &&
      get(process, "data.fields.imported_from_wo", false) &&
      !get(this.state, "isOpenTemplateSelectionModal", false)
    ) {
      this.setState({
        isOpenTemplateSelectionModal: true,
      });
    }
    const isShowDeleteButton =
      get(this.props, "process.data.fields.process_step.value", "") !==
        "done" && get(this.props, "process.data.fields.key.value");

    const showArchiveButton =
      !get(this.props, "process.data.fields.is_archived.value", false) &&
      get(this.props, "process.data.fields.key.value");

    const isShowDraftButton =
      get(this.props, "process.data.fields.order_details_status.value", "") !==
      "completed";

    const isShowUnarchive = get(
      this.props,
      "process.data.fields.is_archived.value",
      false
    );
    const isShowConvertToSold =
      isSpecOrder && get(this.props, "process.data.fields.key.value", "");
    const showSoldSpecSwitch =
      this.props.mode !== "view" &&
      isProductionNeeded &&
      (this.state.isShowSoldSpecSwitch ||
        document.location.href.indexOf("processes/add") > -1);
    return (
      <div className={bem.e("order-form")}>
        {showSoldSpecSwitch && this.renderSoldSpecSwitch()}
        <Form className={bem.e("order-detail-form")}>
          <ClientDetails
            isView={this.props.mode === "view"}
            setIsFieldsChanged={this.setIsFieldsChanged}
            multipleStationSelectionChanged={multipleStationSelectionChanged}
            process={process}
            template={template}
            user={user}
            auth={auth}
            step={step}
            validationState={validationState}
            valueChanged={valueChanged}
            getRoleCandidates={this.props.getRoleCandidates}
            setShouldBlockNavigation={this.props.setShouldBlockNavigation}
          />
          <AudienceDetails
            isView={this.props.mode === "view"}
            setIsFieldsChanged={this.setIsFieldsChanged}
            multipleStationSelectionChanged={multipleStationSelectionChanged}
            process={process}
            template={template}
            user={user}
            auth={auth}
            step={step}
            validationState={validationState}
            valueChanged={valueChanged}
            onValueTimezoneChanged={this.onValueTimezoneChanged}
            setTeamLeadMembers={this.props.setTeamLeadMembers}
            setShouldBlockNavigation={this.props.setShouldBlockNavigation}
          />
          <ProductionDetails
            mode={this.props.mode}
            process={process}
            user={user}
            step={step}
            template={template}
            validationState={validationState}
            getRoleCandidates={this.props.getRoleCandidates}
            valueChanged={this.props.valueChanged}
            setIsFieldsChanged={this.setIsFieldsChanged}
            onValueTimezoneChanged={this.onValueTimezoneChanged}
            multipleStationSelectionChanged={
              this.props.multipleStationSelectionChanged
            }
            setShouldBlockNavigation={this.props.setShouldBlockNavigation}
          />
          {/* Spots list */}
          {map(spotInfo, (spot, index) => {
            return (
              <SpotDetails
                spot={spot}
                key={index}
                spotIndex={index}
                isShowAddButton={index === spotInfo.length - 1 && !isSpecOrder}
                isShowRotationChar={
                  index === spotInfo.length - 1 && !isSpecOrder
                }
                valueChanged={this.props.valueChanged}
                template={template}
                user={user}
                auth={auth}
                validationState={get(
                  validationState,
                  `spot_info.validation_error.${index}`
                )}
                allValidationState={validationState}
                step={step}
                process={process}
                setIsFieldsChanged={this.setIsFieldsChanged}
                disabled={!has_order_stations}
                isEdit={this.props.mode === "edit"}
                onClickAddSpot={this.onClickAddSpot}
                isShowDeleteSpotButton={
                  (index === 0 && spotInfo.length > 1) || index > 0
                }
                onClickDeleteSpot={this.onClickDeleteSpot}
                totalSpots={spotInfo.length}
                setShouldBlockNavigation={this.props.setShouldBlockNavigation}
                dateFormatByServer={dateFormatByServer}
              />
            );
          })}

          <div className={"bottom-actions-sticky"}>
            {this.props.mode === "view" ? (
              <React.Fragment>
                <ButtonGroup
                  className={classnames(bem.e("buttons"), "buttons")}
                >
                  <div className="process-buttons-left">
                    {this.renderEditButton()}
                    {this.renderAnotherSpot({
                      has_order_stations,
                    })}
                    {isShowConvertToSold && (
                      <PrivilegedComponent
                        requires={{
                          or: [
                            PRIVILEGES.EDIT_PRODUCTION,
                            PRIVILEGES.ADD_PRODUCTION,
                            PRIVILEGES.EDIT_ALL_PRODUCTION_ORDERS,
                          ],
                        }}
                      >
                        <Button
                          color="blue"
                          outline
                          className="btn-radius btn-to-sold"
                          onClick={() => {
                            this.setState({
                              isOpenConvertSpotConfirmationModal: true,
                            });
                          }}
                        >
                          <FormattedMessage
                            id={"process > button convert to sold"}
                          />
                        </Button>
                      </PrivilegedComponent>
                    )}

                    {isShowDeleteButton && (
                      <PrivilegedComponent
                        requires={{
                          or: [
                            PRIVILEGES.DELETE_PRODUCTION,
                            PRIVILEGES.EDIT_ALL_PRODUCTION_ORDERS,
                          ],
                        }}
                      >
                        <Button
                          color="link"
                          outline
                          className="btn-link"
                          onClick={() =>
                            this.setState({
                              isOpenDialogDelete: true,
                            })
                          }
                        >
                          <FormattedMessage
                            id={`process > button delete order`}
                          />
                        </Button>
                      </PrivilegedComponent>
                    )}
                    {this.renderSendBackButton()}
                    {isShowUnarchive && (
                      <PrivilegedComponent
                        requires={[PRIVILEGES.ARCHIVE_PRODUCTION]}
                      >
                        <Button
                          color="link"
                          outline
                          className="btn-link"
                          onClick={() =>
                            this.setState({
                              isOpenConfirmUnarchive: true,
                            })
                          }
                        >
                          <FormattedMessage id={`process > button unarchive`} />
                        </Button>
                      </PrivilegedComponent>
                    )}
                    {showArchiveButton && (
                      <PrivilegedComponent
                        requires={[PRIVILEGES.ARCHIVE_PRODUCTION]}
                      >
                        <Button
                          color="link"
                          outline
                          className="btn-link"
                          onClick={() =>
                            this.setState({
                              isOpenDialogArchive: true,
                            })
                          }
                        >
                          <FormattedMessage id={`process > button archive`} />
                        </Button>
                      </PrivilegedComponent>
                    )}
                  </div>
                </ButtonGroup>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <ButtonGroup
                  className={classnames(bem.e("buttons"), "buttons")}
                >
                  <div className="process-buttons-left">
                    <Button
                      block
                      color="blue"
                      className="button-submit"
                      onClick={(event) => {
                        const isNoStationsRepresented = this.checkNoStationsRepresented();
                        if (isNoStationsRepresented) {
                          return;
                        }
                        const isMissingSelectStations = this.checkMissingSelectStationsForSpot();
                        if (isMissingSelectStations) {
                          this.onToggleReminderMissingSelectStationsModal();
                          return;
                        }

                        // submit form but only validate data
                        this.props.buttonClicked(
                          step,
                          {
                            ...step.fields.find((f) => f.key === "save_button"),
                            notify_all_spots: true,
                          },
                          process,
                          template,
                          user,
                          event,
                          null,
                          true,
                          false,
                          () => {
                            const spotInfo = get(
                              process,
                              "data.fields.spot_info.value",
                              []
                            );
                            const isDueDateWarning = filter(
                              spotInfo,
                              (spot) => spot.key === process.key
                            ).find((spot) => {
                              const dueDate = spot ? spot.due_date : null;
                              if (
                                this.props.process.data.fields
                                  .order_start_date &&
                                dueDate &&
                                Date.parse(dueDate) >
                                  Date.parse(
                                    this.props.process.data.fields
                                      .order_start_date.value
                                  )
                              ) {
                                return true;
                              }
                              return false;
                            });

                            if (isDueDateWarning) {
                              this.setState({ isOpenDueDateModal: true });
                              return true; // true === prevent send request submit data
                            }
                          }
                        );
                      }}
                    >
                      <FormattedMessage id="process > button submit" />
                    </Button>
                    {/* button save draft */}
                    {isShowDraftButton ? (
                      <Button
                        block
                        color="blue"
                        outline
                        disabled={!has_order_stations}
                        className="button-save-draft"
                        onClick={(event) => {
                          const isNoStationsRepresented = this.checkNoStationsRepresented();
                          if (isNoStationsRepresented) return;
                          const isMissingSelectStations = this.checkMissingSelectStationsForSpot();
                          if (isMissingSelectStations) {
                            this.onToggleReminderMissingSelectStationsModal();
                          } else {
                            this.props.buttonClicked(
                              step,
                              {
                                ...step.fields.find(
                                  (f) => f.key === "save_button"
                                ),
                                draft: true,
                              },
                              process,
                              template,
                              user,
                              event,
                              null,
                              true
                            );
                          }
                        }}
                      >
                        <FormattedMessage id="process > button save draft" />
                      </Button>
                    ) : null}
                    {document.location.href.indexOf("/processes/edit/") ===
                      -1 &&
                      this.props.isShowClearForm && (
                        <Button
                          color=""
                          className="process-link-clear-form"
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.setIsShowClearForm(false);
                            this.props.setShouldBlockNavigation(false);
                            this.props.onPreparePage();
                          }}
                        >
                          <FormattedMessage id="process > button clear form" />
                        </Button>
                      )}
                    {isShowDeleteButton && (
                      <PrivilegedComponent
                        requires={{
                          or: [
                            PRIVILEGES.DELETE_PRODUCTION,
                            PRIVILEGES.EDIT_ALL_PRODUCTION_ORDERS,
                          ],
                        }}
                      >
                        <Button
                          color="link"
                          outline
                          className="btn-link"
                          onClick={() =>
                            this.setState({
                              isOpenDialogDelete: true,
                            })
                          }
                        >
                          <FormattedMessage id={`process > button delete`} />
                        </Button>
                      </PrivilegedComponent>
                    )}
                    {this.renderSendBackButton()}
                  </div>
                </ButtonGroup>
              </React.Fragment>
            )}
          </div>
        </Form>
        {/* delete modal */}
        <ConfirmationModal
          isOpen={this.state.isOpenDialogDelete}
          onToggle={() =>
            this.setState({
              isOpenDialogDelete: !this.state.isOpenDialogDelete,
            })
          }
          centered
          onCancel={() => this.setState({ isOpenDialogDelete: false })}
          title={<FormattedMessage id="process > confirm delete order" />}
          className="modal-delete"
          onConfirm={() => {
            this.setState({ isOpenDialogDelete: false });
            const { width: widthScreen } = getWindowDimensions();
            this.props.deleteProcess(
              "@andspots@" + get(process, "data.fields.key.value"),
              () => {
                const currentTasks = URL.PROCESSES({
                  filter: "my_action_items",
                });
                const nextRoute = widthScreen
                  ? URL.HOME_MOBILE()
                  : this.props.sidebarActive
                  ? this.props.sidebarActive
                  : currentTasks;
                this.props.history.push(nextRoute);
              }
            );
          }}
          isCloseOutside={false}
        />
        {/* archive modal */}
        <ConfirmationModal
          centered
          isOpen={this.state.isOpenDialogArchive}
          onToggle={() =>
            this.setState({
              isOpenDialogArchive: !this.state.isOpenDialogArchive,
            })
          }
          onCancel={() => this.setState({ isOpenDialogArchive: false })}
          title={<FormattedMessage id="process > confirm archive this order" />}
          description={
            <FormattedMessage id="process > confirm archive this order description" />
          }
          onConfirm={() => {
            this.setState({ isOpenDialogArchive: false });
            this.props.archiveProcess(
              get(process, "data.fields.key.value"),
              () => {
                ToastManager.show({
                  message: this.props.intl.formatMessage({
                    id: "toast > title saved",
                  }),
                  level: "success",
                });
                // const currentTasks = URL.PROCESSES({
                //   filter: "my_action_items",
                // });
                // const nextRoute = this.props.sidebarActive
                //   ? this.props.sidebarActive
                //   : currentTasks;
                // this.props.history.push(nextRoute);
              }
            );
          }}
          isCloseOutside={false}
          confirmTitle={<FormattedMessage id="process > button yes" />}
          cancelTitle={<FormattedMessage id="process > button cancel" />}
        />
        {/* delete spot modal confirmation */}
        <ConfirmationModal
          isOpen={this.state.isOpenDialogDeleteSpot}
          centered
          onToggle={() =>
            this.setState({
              isOpenDialogDeleteSpot: !this.state.isOpenDialogDeleteSpot,
            })
          }
          onCancel={() => this.setState({ isOpenDialogDeleteSpot: false })}
          title={<FormattedMessage id="process > confirm delete this spot" />}
          cancelTitle={<FormattedMessage id="confirm modal > button cancel" />}
          description={null}
          className={bem.e("confirmation-modal-delete-spot")}
          onConfirm={this.onConfirmDeleteSpot}
          isCloseOutside={false}
        />
        {/* reminder modal missing select stations */}
        <ReminderModal
          isOpen={this.state.isOpenReminderMissingSelectStations}
          onCancel={() => {
            this.onToggleReminderMissingSelectStationsModal();
          }}
          onConfirm={(event) => {
            this.onToggleReminderMissingSelectStationsModal();
            this.props.buttonClicked(
              step,
              step.fields.find((f) => f.key === "save_button"),
              process,
              template,
              user,
              event,
              null,
              true
            );
          }}
          title={
            <FormattedMessage id="reminder modal > these stations have no spots assigned" />
          }
          subtitle={get(this.state, "missingSelectStations", []).map(
            (station, index) => (
              <span key={index}>{station}</span>
            )
          )}
          confirmTitle={
            <FormattedMessage id="reminder modal > button continue" />
          }
          cancelTitle={<FormattedMessage id="reminder modal > button cancel" />}
          onToggle={this.onToggleReminderMissingSelectStationsModal}
        />
        <ConfirmationModal
          isOpen={this.state.isOpenSuccessDialog}
          state={"success"}
          title={<FormattedMessage id="process > title submitted" />}
          onCancel={() => this.setIsOpenSuccessDialog(false)}
        />
        {/* send back modal */}
        <SendBackOrderStatusModal
          isOpen={this.state.isOpenSendBackOrder}
          process={process}
          onToggle={() =>
            this.setState({
              isOpenSendBackOrder: !this.state.isOpenSendBackOrder,
            })
          }
          step={step}
          onSuccessCallback={() => {
            this.setState({
              isOpenSendBackOrder: false,
              isOpenSuccessDialog: true,
            });
          }}
        />
        {/* production prompt modal */}
        {this.renderTemplateSelectionModal()}
        {/* rotation prompt modal */}
        {this.renderRotationPromptModal()}
        {/* warning due date greater than spot start date */}
        <ConfirmationModal
          isOpen={this.state.isOpenDueDateModal}
          onToggle={() =>
            this.setState({
              isOpenDueDateModal: !this.state.isOpenDueDateModal,
            })
          }
          cancelTitle=""
          confirmTitle="OK"
          title={
            <FormattedMessage id="process > field due date vs sport start date" />
          }
          className="modal-delete"
          onConfirm={(event) => {
            this.props.buttonClicked(
              step,
              step.fields.find((f) => f.key === "save_button"),
              process,
              template,
              user,
              event,
              null,
              true
            );
            this.setState({ isOpenDueDateModal: false });
          }}
          isCloseOutside={true}
        />
        {/* copy order modal */}
        <CopyOrderModal
          isOpen={this.state.isOpenCopyOrderModal}
          onToggle={() =>
            this.setState({
              isOpenCopyOrderModal: !this.state.isOpenCopyOrderModal,
            })
          }
          centered
          process={process}
        />
        <ConfirmationModal
          centered
          isOpen={this.state.isOpenConvertSpotConfirmationModal}
          title={
            <FormattedMessage id="process > title are you sure you want to convert this order to sold" />
          }
          confirmTitle={<FormattedMessage id="process > button yes" />}
          cancelTitle={<FormattedMessage id="process > button no" />}
          onConfirm={() => {
            this.setState({
              isOpenConvertSpotConfirmationModal: false,
            });
            this.onConvertToSold();
          }}
          onCancel={() => {
            this.setState({
              isOpenConvertSpotConfirmationModal: false,
            });
          }}
          onToggle={() => {
            this.setState({
              isOpenConvertSpotConfirmationModal: false,
            });
          }}
          isCloseOutside={false}
        />
        <ConfirmationModal
          centered
          isOpen={this.state.isOpenConfirmUnarchive}
          title={
            <FormattedMessage id="process > title ready to unarchive this order" />
          }
          description={
            <FormattedMessage id="process > description unarchive this order" />
          }
          confirmTitle={<FormattedMessage id="process > button yes" />}
          cancelTitle={<FormattedMessage id="process > button cancel" />}
          onConfirm={() => {
            this.setState({
              isOpenConfirmUnarchive: false,
            });
            this.onUnArchive();
          }}
          onCancel={() => {
            this.setState({
              isOpenConfirmUnarchive: false,
            });
          }}
          onToggle={() =>
            this.setState({
              isOpenConfirmUnarchive: false,
            })
          }
          isCloseOutside={false}
        />
      </div>
    );
  }
  render() {
    let { process } = this.props;
    if (!process.data.fields || Object.keys(process.data.fields).length === 0)
      return null;
    return (
      <div className={classnames("orderForm", bem.b())}>
        {this.renderView()}
        {this.renderModalSpot()}
      </div>
    );
  }
}

export default OrderDetails;
