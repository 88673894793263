import TableManual from "./view";
import { get, forEach, findIndex, includes, forOwn } from "lodash";
import { connect } from "react-redux";
import {
  compose,
  lifecycle,
  withState,
  withHandlers,
  withPropsOnChange,
} from "recompose";
import {
  getAdmins,
  getMoreAdmins,
  deleteUser,
  invitationAdmins,
  activeAdmins,
} from "store/actions/users";
import { injectIntl } from "react-intl";
import { pageSize } from "utils/config";

export default injectIntl(
  compose(
    connect(
      (state, props) => ({
        isLazyLoading: get(state, "users.lazyLoad", false),
        isLoading: get(state, "users.inviteAdmins.loading", false),
        users: get(state, "users.admins"),
        auth: get(state, "auth", {}),
        privileges: get(state, "auth.user.privileges") || [],
        locationOptions: get(props, "locationOptions", []),
      }),
      {
        getAdmins,
        getMoreAdmins,
        deleteUser,
        invitationAdmins,
        activeAdmins,
      }
    ),
    withState(
      "isBulkUploadUserModalOpen",
      "setIsBulkUploadUserModalOpen",
      false
    ),
    withState("searchText", "setSearchText", ""),
    withState("filtered", "setFiltered", []),
    withState("searchTags", "setSearchTags", []),
    withState("sorted", "setSorted", []),
    withState("isDeleteModalOpen", "setIsDeleteModalOpen", false),
    withState("deleteUserId", "setDeleteUserId", null),
    withState("selectedRowsId", "setSelectedRowsId", {}),
    withState("isInviteModalOpen", "setIsInviteModalOpen", false),
    withState("isSuccessModalOpen", "setIsSuccessModalOpen", false),
    withHandlers({
      openDeleteModal: ({ setIsDeleteModalOpen, setDeleteUserId }) => (id) => {
        setIsDeleteModalOpen(true);
        setDeleteUserId(id);
      },
      closeDeleteModal: ({ setIsDeleteModalOpen, setDeleteUserId }) => () => {
        setIsDeleteModalOpen(false);
        setDeleteUserId(null);
      },
      toggleBulkUploadUserModal: ({
        isBulkUploadUserModalOpen,
        setIsBulkUploadUserModalOpen,
      }) => () => setIsBulkUploadUserModalOpen(!isBulkUploadUserModalOpen),
      getSelectedMarketsByUser: ({ locationOptions }) => (user) => {
        if (!get(user, "tags_string", "")) return [];
        let selectedMarketOptions = [];
        forEach(get(user, "tags_string", "").split("#"), (item) => {
          let existsIndex = findIndex(locationOptions, (selected) =>
            includes(selected.label, item)
          );
          if (existsIndex !== -1) {
            selectedMarketOptions.push({
              value: get(locationOptions, `${existsIndex}.value`),
              label: get(locationOptions, `${existsIndex}.label`),
            });
          }
        });
        return selectedMarketOptions;
      },
    }),
    withHandlers({
      getAdminsList: ({ getAdmins, sorted, filtered, setPage }) => async () => {
        getAdmins({
          pageSize,
          page: 0,
          sorted,
          filtered,
        });
      },
      getMoreAdmins: ({ getMoreAdmins, sorted, filtered }) => async (page) => {
        getMoreAdmins({
          pageSize,
          page,
          sorted,
          filtered,
        });
      },
    }),
    withHandlers({
      onLoadMore: ({ getMoreAdmins, users }) => () => {
        getMoreAdmins(get(users, "data.current_page", 1));
      },
    }),
    withHandlers({
      onActiveAdmins: ({ activeAdmins }) => (userIds = []) => {
        activeAdmins({ ids: userIds });
      },
      onInviteAdmins: ({
        selectedRowsId,
        setSelectedRowsId,
        invitationAdmins,
        setIsSuccessModalOpen,
        setIsInviteModalOpen,
      }) => () => {
        setIsInviteModalOpen(false);
        let userIds = [];
        forOwn(selectedRowsId, (value, key) => {
          if (value) {
            userIds.push(Number(key));
          }
        });
        invitationAdmins({ ids: userIds }, () => {
          setIsSuccessModalOpen(true);
          setSelectedRowsId({});
        });
      },
      onDelete: ({
        closeDeleteModal,
        deleteUserId,
        deleteUser,
        getAdminsList,
      }) => () => {
        deleteUser(deleteUserId, getAdminsList);
        closeDeleteModal();
      },
      onSearchKeyDown: ({ getAdminsList }) => (e) => {
        if (e.keyCode === 13) {
          getAdminsList();
        }
      },
      onSearchClicked: ({ getAdminsList }) => () => {
        getAdminsList();
      },
      onChangeInput: ({ filtered, setFiltered, setSearchText }) => ({
        target,
      }) => {
        const value = target.value;
        const name = target.name;
        const filterSearch = {
          id: name,
          value,
        };
        const index = filtered.findIndex((item) => item.id === name);
        if (index === -1) {
          filtered.push(filterSearch);
        } else {
          filtered[index] = filterSearch;
        }
        setSearchText(value);
        setFiltered(filtered);
      },
    }),
    withHandlers({
      onClearSearch: ({ onChangeInput, onSearchClicked }) => (e) => {
        e.preventDefault();
        onChangeInput({
          target: {
            name: "q",
            value: "",
          },
        });
        onSearchClicked();
      },
    }),
    withPropsOnChange(
      ["sorted", "filtered"],
      ({ getAdminsList, sorted, filtered, pageSize, isFocused }) => {
        if (sorted.length > 0 || filtered.length > 0 || pageSize) {
          if (isFocused) {
            getAdminsList();
          }
        }
      }
    ),
    lifecycle({
      componentDidMount() {
        if (get(this.props, "isFocused")) {
          this.props.setSearchText("");
          this.props.setSorted([]);
          this.props.setFiltered([]);
          this.props.getAdmins({
            pageSize,
            page: 0,
            sorted: [],
            filtered: [],
          });
        }
      },
      componentWillUpdate(nextProps) {
        // only call when focused to this tab
        if (
          get(this.props, "isFocused") !== get(nextProps, "isFocused") &&
          get(nextProps, "isFocused") &&
          !get(nextProps, "admins.data.data")
        ) {
          this.props.getAdminsList();
        } else if (
          get(nextProps, "users.is_dirty") &&
          get(nextProps, "isFocused")
        ) {
          this.props.getAdminsList();
        }
      },
    })
  )(TableManual)
);
