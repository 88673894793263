import React, { useMemo } from "react";
import Countdown from "components/Countdown";
import useCountdown from "./useCountdown";
import { first, last, split } from "lodash";

const DeadlineTimer = (props) => {
  const { timezone } = props;
  const { time, date } = useMemo(() => {
    if (!props.date) {
      return {
        date: null,
        time: null,
      };
    }
    const dateArray = split(props.date, " ");
    if (dateArray.length === 2) {
      return {
        date: first(dateArray),
        time: last(dateArray),
      };
    }
    return {
      date: first(dateArray),
      time: null,
    };
  }, [props.date]);

  const countDown = useCountdown(date, timezone, time);
  if (!countDown) return null;
  const { years, days, hours, min, sec, millisec, isNegative } = countDown;
  return (
    <Countdown
      years={years}
      days={days}
      hours={hours}
      min={min}
      sec={sec}
      millisec={millisec}
      isNegative={isNegative}
    />
  );
};
export default DeadlineTimer;
