import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { EditIcon } from "components/CustomIcons";
import classNames from "classnames";
import * as Yup from "yup";
import { Formik } from "formik";
import TextInput from "components/TextInput";
import { Button, Form } from "reactstrap";
import { ApproveIcon, TrashIcon, DeleteIcon } from "components/CustomIcons";
import { delay } from "lodash";

const StationItem = (props) => {
  const { bem, station, intl, isEdit, setIsEdit, onDelete } = props;
  const [progress, setProgress] = React.useState(0);
  const settings = useMemo(() => {
    return station.zetta_dubbing_settings;
  }, [station.zetta_dubbing_settings]);

  if (isEdit) {
    return (
      <div className={bem.e("station-item")}>
        <div className={bem.e("station-name")}>{station.call_letters}</div>
        <Formik
          enableReinitialize
          initialValues={{
            station_id: station.id,
            ftp_path: settings ? settings.ftp_path : "",
            port: settings ? settings.port : "",
            subfolder: settings ? settings.subfolder : "",
            username: settings ? settings.username : "",
            password: settings ? settings.password : "",
          }}
          validationSchema={Yup.object().shape({
            ftp_path: Yup.string().required(
              intl.formatMessage({
                id: "validate > fpt path is required",
              })
            ),
            port: Yup.string().required(
              intl.formatMessage({
                id: "validate > port is required",
              })
            ),
            subfolder: Yup.string().nullable(),
            username: Yup.string().required(
              intl.formatMessage({
                id: "validate > username is required",
              })
            ),
            password: Yup.string().required(
              intl.formatMessage({
                id: "validate > password is required",
              })
            ),
          })}
          onSubmit={(values) => {
            setProgress(90);
            props.onSubmit(values, (response) => {
              if (response) {
                setProgress(100);
              } else {
                setProgress(-1);
              }
              delay(() => {
                setProgress(0);
              }, 5000);
            });
          }}
        >
          {(formProps) => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
            } = formProps;
            return (
              <Form
                onSubmit={formProps.handleSubmit}
                className={bem.e("edit-settings")}
              >
                <div className={classNames(bem.e("ftp-path"), bem.e("input"))}>
                  <TextInput
                    label={null}
                    type="text"
                    name="ftp_path"
                    placeholder={intl.formatMessage({
                      id: "zetta > ftp path",
                    })}
                    value={values.ftp_path}
                    error={touched.ftp_path && errors.ftp_path}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className={classNames(bem.e("port"), bem.e("input"))}>
                  <TextInput
                    label={null}
                    type="text"
                    name="port"
                    placeholder={intl.formatMessage({
                      id: "zetta > port",
                    })}
                    value={values.port}
                    error={touched.port && errors.port}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className={classNames(bem.e("subfolder"), bem.e("input"))}>
                  <TextInput
                    label={null}
                    type="text"
                    name="subfolder"
                    placeholder={intl.formatMessage({
                      id: "zetta > subfolder",
                    })}
                    value={values.subfolder}
                    error={touched.subfolder && errors.subfolder}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className={classNames(bem.e("username"), bem.e("input"))}>
                  <TextInput
                    label={null}
                    type="text"
                    name="username"
                    placeholder={intl.formatMessage({
                      id: "zetta > username",
                    })}
                    value={values.username}
                    error={touched.username && errors.username}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className={classNames(bem.e("password"), bem.e("input"))}>
                  <TextInput
                    label={null}
                    type="text"
                    name="password"
                    placeholder={intl.formatMessage({
                      id: "zetta > password",
                    })}
                    value={values.password}
                    error={touched.password && errors.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <Button
                  className={classNames("btn-radius btn-save", {
                    [bem.e("btn-progress-success")]: progress === 100,
                    [bem.e("btn-progress-error")]: progress === -1,
                    [bem.e("btn-progress")]: progress === 90,
                  })}
                  type="submit"
                  color="blue"
                >
                  {progress === 0 ? (
                    <FormattedMessage id="zetta > save" />
                  ) : progress === 100 ? (
                    <>
                      <ApproveIcon color="#ffffff" />
                      <FormattedMessage id="zetta > ftp" />
                    </>
                  ) : progress === -1 ? (
                    <>
                      <TrashIcon color="#ffffff" />
                      <FormattedMessage id="zetta > error" />
                    </>
                  ) : null}
                </Button>
                <Button
                  className="btn-radius btn-cancel"
                  color="blue"
                  outline
                  onClick={setIsEdit}
                >
                  <FormattedMessage id="zetta > cancel" />
                </Button>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
  return (
    <div className={bem.e("station-item")}>
      <div className={bem.e("station-name")}>{station.call_letters}</div>
      {settings ? (
        <div className={bem.e("view-settings")}>
          <div className={classNames(bem.e("ftp-path"), bem.e("text"))}>
            {settings.ftp_path}
          </div>
          <div className={classNames(bem.e("port"), bem.e("text"))}>
            {settings.port}
          </div>
          <div className={classNames(bem.e("subfolder"), bem.e("text"))}>
            {settings.subfolder}
          </div>
          <div className={classNames(bem.e("username"), bem.e("text"))}>
            {settings.username}
          </div>
          <div className={classNames(bem.e("password"), bem.e("text"))}>
            •••••••••••
          </div>
          <span onClick={setIsEdit} className={bem.e("btn-edit")}>
            <EditIcon />
          </span>
          <span onClick={onDelete} className={bem.e("btn-delete")}>
            <DeleteIcon />
          </span>
        </div>
      ) : (
        <div className={bem.e("missed-info")}>
          <FormattedMessage id="zetta > information required" />
          <span onClick={setIsEdit}>
            <EditIcon />
          </span>
        </div>
      )}
    </div>
  );
};

export default StationItem;
