import HubsList from "./view";
import { get } from "lodash";
import { connect } from "react-redux";
import { compose, lifecycle, withState } from "recompose";
import { getHub } from "store/actions/hubs";
import { injectIntl } from "react-intl";

export default injectIntl(
  compose(
    connect(
      (state) => {
        return {
          hub: get(state, "hubs.hub.data"),
          isLoading:
            get(state, "hubs.assignedStations.loading", false) ||
            get(state, "hubs.orderAssignments.loading", false) ||
            get(state, "hubs.hub.loading", false),
        };
      },
      {
        getHub,
      }
    ),
    withState("activeTab", "setActiveTab", "stations"),
    lifecycle({
      componentDidMount() {
        const selectedId = this.props.selectedId;
        if (selectedId) {
          this.props.getHub(selectedId);
        }
      },
      componentDidUpdate(prevProps) {
        if (
          get(this.props, "selectedId") !== get(prevProps, "selectedId") &&
          get(this.props, "selectedId")
        ) {
          this.props.getHub();
        }
      },
    })
  )(HubsList)
);
