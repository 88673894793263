import { compose, lifecycle, withHandlers, withState } from "recompose";
import { connect } from "react-redux";
import { get } from "lodash";
import View from "./view";
import { injectIntl } from "react-intl";
import {
  updateReminderDays,
  fetchReminderDays,
  fetchFulfillmentSettings,
  fetchFulfillmentSetting,
} from "store/actions/stations";
import {
  fetchWinnerAnnouncement,
  fetchFormConfirmation,
  fetchPrizeReleaseTemplate,
  fetchPrizeFulfillmentTemplate,
} from "store/actions/systemTemplates";
import { fetchFulfillmentFormTemplate } from "store/actions/systemForms";

export default injectIntl(
  compose(
    connect(
      ({ auth, servers, ...rest }) => {
        return {
          auth,
          currentServer: get(servers, "currentServer.data", {}),
          loading:
            get(rest, "stations.fulfillmentSettings.loading", false) ||
            get(rest, "stations.fulfillmentSetting.loading", false),
          stationSettingsData: get(
            rest,
            "stations.fulfillmentSettings.data",
            null
          ),
          fulfillmentSetting: get(
            rest,
            "stations.fulfillmentSetting.data",
            null
          ),
          reminderDays: get(
            rest,
            "stations.reminderDays.data.fulfillment_reminder_days_before",
            7
          ),
          isDirtySystemTemplateForms:
            get(rest, "systemTemplates.winnerAnnouncements.is_dirty", false) ||
            get(rest, "systemTemplates.formConfirmations.is_dirty", false) ||
            get(rest, "systemTemplates.prizeReleases.is_dirty", false) ||
            get(rest, "systemTemplates.prizeFulfillments.is_dirty", false),
          isDirtySystemForms: get(
            rest,
            "systemForms.fulfillmentForms.is_dirty",
            false
          ),
        };
      },
      {
        updateReminderDays,
        fetchReminderDays,
        fetchFulfillmentSettings,
        fetchWinnerAnnouncement,
        fetchFulfillmentFormTemplate,
        fetchFormConfirmation,
        fetchPrizeReleaseTemplate,
        fetchPrizeFulfillmentTemplate,
        fetchFulfillmentSetting,
      }
    ),
    withState("isOpenPreview", "setIsOpenPreview", null),
    withHandlers({
      onUpdateReminderDays: ({ currentStation, updateReminderDays }) => (
        day = 7
      ) => {
        updateReminderDays({
          station_id: currentStation.key,
          reminder_days_before: day,
        });
      },
      onGetFulfillmentSettings: ({
        currentStation,
        fetchFulfillmentSettings,
      }) => () => {
        fetchFulfillmentSettings({
          station_id: currentStation.key,
        });
      },
      onGetAllSystemTemplateForms: ({
        currentStation,
        fetchWinnerAnnouncement,
        fetchPrizeFulfillmentTemplate,
        fetchFormConfirmation,
        fetchPrizeReleaseTemplate,
      }) => () => {
        fetchWinnerAnnouncement({
          station_id: currentStation.key,
        });
        fetchFormConfirmation({
          station_id: currentStation.key,
        });
        fetchPrizeReleaseTemplate({
          station_id: currentStation.key,
        });
        fetchPrizeFulfillmentTemplate({
          station_id: currentStation.key,
        });
      },
      onGetAllSystemForms: ({
        currentStation,
        fetchFulfillmentFormTemplate,
      }) => () => {
        fetchFulfillmentFormTemplate({
          station_id: currentStation.key,
        });
      },
    }),
    lifecycle({
      componentDidMount() {
        this.props.fetchReminderDays({
          station_id: this.props.currentStation.key,
        });
        this.props.onGetFulfillmentSettings();
        this.props.onGetAllSystemTemplateForms();
        this.props.onGetAllSystemForms();
      },
      componentDidUpdate(prevProps) {
        const isStationChanged =
          this.props.currentStation &&
          this.props.currentStation.key !== prevProps.currentStation.key;
        if (isStationChanged) {
          this.props.fetchReminderDays({
            station_id: this.props.currentStation.key,
          });
          this.props.onGetFulfillmentSettings();
          this.props.onGetAllSystemTemplateForms();
          this.props.onGetAllSystemForms();
        }
        if (
          this.props.isDirtySystemTemplateForms &&
          this.props.currentStation
        ) {
          this.props.onGetAllSystemTemplateForms();
        }
        if (this.props.isDirtySystemForms && this.props.currentStation) {
          this.props.onGetAllSystemForms();
        }
      },
    })
  )(View)
);
