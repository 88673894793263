import React, { useRef, useCallback } from "react";
import { Modal, ModalBody } from "reactstrap";
import bn from "utils/bemnames";
import {
  map,
  get,
  replace,
  filter,
  debounce,
  findIndex,
  find,
  forEach,
  split,
  first,
} from "lodash";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CloseIcon,
} from "components/CustomIcons";
import Slider from "react-slick";
import { getFormFieldsWithConditionLogic } from "utils/helpers";
const bem = bn.create("preview-media-modal");
const NextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowRightIcon color="#829FB1" />
    </div>
  );
};

const PrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowLeftIcon color="#829FB1" />
    </div>
  );
};
const mediaSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};
function PreviewMediaModal(props) {
  const mainSlick = useRef(null);
  const { channel, currentMedia, isShowFileName } = props;
  // we will map fields by condition logic
  let newFields = getFormFieldsWithConditionLogic(channel);
  let assets = [];
  filter(
    newFields,
    (field) => field.key === "media_for_review_approval"
  ).forEach((field) => {
    let value = find(
      channel.fields,
      (item) => item.label === field.label && item.key === field.key
    );
    forEach(value.value_arr, (item) => {
      assets.push({
        key: field.key,
        label: field.label,
        image: item,
      });
    });
  });
  const debounceActiveSlide = useCallback(
    debounce(() => {
      let index = findIndex(
        assets,
        (item) => get(item, "image.path") === get(currentMedia, "asset.path")
      );
      if (index === -1) index = 0;
      mainSlick.current.slickGoTo(index);
    }, 200),
    [currentMedia]
  );
  return (
    <Modal isOpen={props.isOpen} className={bem.b()}>
      <ModalBody className={bem.e("body")}>
        <div className={bem.e("close-icon")} onClick={props.onToggle}>
          <CloseIcon color="#657894" />
        </div>
        <div>
          <Slider
            ref={mainSlick}
            {...mediaSettings}
            onInit={() => {
              debounceActiveSlide();
            }}
          >
            {map(assets, (item, index) => (
              <div key={index} className={bem.e("asset-item-container")}>
                <div className={bem.e("field-name")}>
                  {isShowFileName
                    ? first(split(get(item, "image.name", ""), "."))
                    : get(item, "label", "")}
                </div>

                <div className={bem.e("asset-item")}>
                  <img
                    src={replace(
                      get(item, "image.path", ""),
                      "https://",
                      "http://"
                    )}
                    alt={""}
                  />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </ModalBody>
    </Modal>
  );
}

PreviewMediaModal.defaultProps = {
  isOpen: false,
  isEdit: false,
  isShowFileName: false,
};

export default PreviewMediaModal;
