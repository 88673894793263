import React, { useEffect } from "react";
import bn from "utils/bemnames";
import SortableComponent from "components/SortableComponent";
import JockConsoleItem from "./JockConsoleItem";
import { FormattedMessage } from "react-intl";
import Spinner from "components/Spinner";
import ConfirmationModal from "components/ConfirmationModal";
import { Button } from "reactstrap";
import { FaPlus } from "react-icons/lib/fa";
import classNames from "classnames";
import SelectStations from "components/SelectStations";
import { filter, get } from "lodash";
const bem = bn.create("jock-console-manager");
export default function JockConsoleManager(props) {
  const {
    isOpenDetails,
    setIsOpenDetails,
    widgets,
    onSwitchActive,
    isOrdering,
    onAddNew,
    intl,
    currentActiveConfirmation,
    setCurrentActiveConfirmation,
    currentStation,
    setCurrentStation,
    stations,
    getJockConsoleWidgets,
    user,
    dateFormatByServer
  } = props;
  const replacementWidgets = filter(
    widgets,
    (item) => item.type === "bulletin_board_replacement"
  );
  const privileges = get(user, "privileges", []);

  useEffect(() => {
    if (stations.length === 1) {
      const station = get(stations, 0);
      setCurrentStation(station);
      getJockConsoleWidgets({
        station_id: station.key,
      });
    }
  }, []);

  return (
    <div className={bem.b()}>
      <div className={bem.e("jock-manager-head")}>
        <div className={bem.e("jock-manager-description")}>
          <FormattedMessage id="jock console manager > description page" />
        </div>
        <div className={bem.e("right-option")}>
          {stations.length > 1 ? (
            <SelectStations
              name="select_stations"
              item={{ field: "stations" }}
              placeholder={intl.formatMessage({
                id: "channel manager > select stations",
              })}
              onChange={(station) => {
                setCurrentStation(station);
                getJockConsoleWidgets({
                  station_id: station.key,
                });
              }}
              isMulti={false}
              value={get(currentStation, "value", "")}
            />
          ) : null}
          <div className={bem.e("activated-text")}>
            <FormattedMessage id="jock console manager > activated" />
          </div>
        </div>
      </div>
      <SortableComponent
        data={widgets}
        onSorted={props.onUpdateOrder}
        lockToContainerEdges={false}
        disabled={isOrdering}
        renderItem={(item, index) => {
          const isOpen = isOpenDetails === item.id;
          const newWidget = widgets.filter((w) => {
            return get(w, "id", "").indexOf("_new") === 0;
          });

          return (
            <JockConsoleItem
              key={index}
              item={item}
              isOpen={isOpen}
              disabledDragDrop={newWidget.length > 0 ? true : false}
              isHaveReplacementWidgets={replacementWidgets.length > 0}
              setCurrentActiveConfirmation={setCurrentActiveConfirmation}
              toggleOpen={() => setIsOpenDetails(isOpen ? null : item.id)}
              onSwitchActive={onSwitchActive}
              station={currentStation}
              privileges={privileges}
              user={user}
              dateFormatByServer={dateFormatByServer}
            />
          );
        }}
      />
      <ConfirmationModal
        isOpen={!!currentActiveConfirmation}
        title={
          <div className={bem.e("modal-title")}>
            <span>
              {intl.formatMessage({
                id: "jock console manager > active widget modal title",
              })}
            </span>
          </div>
        }
        description={intl.formatMessage({
          id: "jock console manager > active widget modal description",
        })}
        className={bem.e("confirmation-modal")}
        confirmTitle={intl.formatMessage({
          id: "jock console manager > button activate",
        })}
        cancelTitle={intl.formatMessage({
          id: "jock console manager > button not now",
        })}
        onToggle={() => setCurrentActiveConfirmation(null)}
        onConfirm={() => {
          onSwitchActive(currentActiveConfirmation, true);
        }}
        onCancel={() => {
          setCurrentActiveConfirmation(null);
          setIsOpenDetails(null);
        }}
        isCloseOutside={false}
      />
      <Button
        className={classNames(bem.e("button-create"), "btn-radius")}
        color="blue"
        outline
        onClick={onAddNew}
      >
        <FaPlus className={bem.e("icon-add")} />
        <FormattedMessage id="jock console manager > create new widget" />
      </Button>
      <Spinner isLoading={props.isLoading} />
    </div>
  );
}
