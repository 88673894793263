import history from "components/History";
import * as Const from "utils/constants";
import { labelToNameField, createMessage } from "utils/helpers";
import Api from "utils/api";
import URL from "utils/urls";
import { getTeamsAndLeads } from "store/actions/teams";
import {
  get,
  omit,
  isFunction,
  map,
  forEach,
  find,
  pick,
  filter,
  includes,
  trim,
} from "lodash";
import moment from "moment";
import * as ProcessesTypes from "store/types/processes";
import { REPLACE_MESSAGES, SET_MESSAGES } from "store/types/messages";
import { dismissNotifications } from "store/actions/messages";
import queryString from "query-string";

export function formValueChanged(payload) {
  return {
    type: ProcessesTypes.PROCESS_VALUE_CHANGED,
    payload,
  };
}

export const getProcess = (payload) => async (dispatch) => {
  let err = "";
  let { process_id, ...rest } = payload;
  try {
    const params = {
      ...rest,
    };
    dispatch({ type: ProcessesTypes.FETCH_PROCESS_REQUEST, payload });
    const processId = trim(process_id);
    if (!process_id) {
      dispatch(fetchProcessSuccess({ id: undefined, data: {} }));
      return;
    }
    let response = await Api.doCall(
      Const.GET_PROCESS(processId) + `?${queryString.stringify(params)}`,
      "GET"
    );
    if (response.status === 200) {
      if (!response.data.error) {
        // we're getting the messages in the same request
        // add them to the known messages store
        if (response.data.messages) {
          const messages = response.data.messages.map((m) => ({
            message: m._source,
            m_id: m._id,
          }));
          dispatch({
            type: SET_MESSAGES,
            payload: { messages },
          });
          dispatch(dismissNotifications(payload));
        }

        // spread channel field values as sub objects
        if (
          response.data.template_key === "digital" &&
          response.data.marketing_channels
        ) {
          response.data.marketing_channels.map((channel) => {
            if (channel.fields) {
              channel.fields.map((channel_field) => {
                let field_name = labelToNameField(channel_field.label);
                if (!channel[field_name]) {
                  if (channel_field.value_arr)
                    channel[field_name] = channel_field.value_arr;
                  else if (channel_field.value_obj)
                    channel[field_name] = channel_field.value_obj;
                  else if (channel_field.value_bool)
                    channel[field_name] = channel_field.value_bool;
                  else if (channel_field.value_num)
                    channel[field_name] = channel_field.value_num;
                  else if (channel_field.value_str)
                    channel[field_name] = channel_field.value_str;
                  else channel[field_name] = channel_field.value; // backward compability
                }
              });
            }
          });
        }

        let fields = {};
        let forgetProcesses = get(response.data, "order_viewed", false);

        Object.keys(omit(response.data, "order_viewed")).forEach((key) => {
          fields[key] = { value: response.data[key] };
        });
        // add spot_info_fields_dirty to process data. To implement auto update data on process incoming from pusher. We only update the fields is not dirty
        if (
          ["basic_production", "production_provided"].includes(
            fields.template_key.value
          )
        ) {
          fields["spot_info_fields_dirty"] = {
            value: [],
            is_dirty: true,
          };
        }
        dispatch(
          fetchProcessSuccess({
            id: processId,
            data: fields,
            forgetProcesses: forgetProcesses,
          })
        );
        const isMultiMarket = get(fields, "is_multi_market.value", false);
        const isProductionNeeded =
          get(fields, "contract_status.value") === "Production Needed";
        if (isMultiMarket && isProductionNeeded) {
          const stationKeys = get(fields, "contract_stations.value", []).filter(
            Boolean
          );
          dispatch(
            getTeamsAndLeads({
              station_ids: stationKeys,
            })
          ); // call api get teams and leads
        }
        return;
      }
      err = response.data.error;
    }
  } catch (error) {
    err = error.toString();
  }
  dispatch(fetchProcessError({ data: err }));
};

export const getReviewProcess = (payload, cb) => async (dispatch) => {
  let err = "";
  let { process_email_key } = payload;
  try {
    dispatch({ type: ProcessesTypes.FETCH_REVIEW_PROCESS_REQUEST, payload });
    dispatch({
      type: ProcessesTypes.SET_EXTRA_DATA_REVIEW_PROCESS,
      payload: null,
    });
    let response = await Api.doCall(
      Const.GET_PUBLIC_PROCESS(process_email_key),
      "GET"
    );
    if (response.status === 200) {
      // debugger;
      if (!response.data.error) {
        // spread channel field values as sub objects
        const {
          process,
          sale,
          server,
          stations,
          messages,
          isTrashed,
        } = response.data;
        if (process.template_key === "digital" && process.marketing_channels) {
          process.marketing_channels.map((channel) => {
            if (channel.fields) {
              channel.fields.map((channel_field) => {
                let field_name = labelToNameField(channel_field.label);
                if (!channel[field_name]) {
                  if (channel_field.value_arr)
                    channel[field_name] = channel_field.value_arr;
                  else if (channel_field.value_obj)
                    channel[field_name] = channel_field.value_obj;
                  else if (channel_field.value_bool)
                    channel[field_name] = channel_field.value_bool;
                  else if (channel_field.value_num)
                    channel[field_name] = channel_field.value_num;
                  else if (channel_field.value_str)
                    channel[field_name] = channel_field.value_str;
                  else channel[field_name] = channel_field.value; // backward compability
                }
                return channel_field;
              });
            }
            return channel;
          });
        }

        let fields = {};
        Object.keys(process).map(
          (key) => (fields[key] = { value: process[key] })
        );
        dispatch({
          type: ProcessesTypes.FETCH_REVIEW_PROCESS_SUCCESS,
          payload: {
            id: process.key,
            data: fields,
          },
        });
        dispatch({
          type: ProcessesTypes.SET_EXTRA_DATA_REVIEW_PROCESS,
          payload: {
            server,
            sale,
            is_reviewed: isTrashed,
            stations,
          },
        });
        const formattedMessages = messages.map((m) => ({
          message: m._source,
          m_id: m._id,
          dismissable:
            m._source.needs_confirmation &&
            m._source.needs_confirmation.indexOf(process.id) > -1,
        }));
        dispatch({
          type: SET_MESSAGES,
          payload: { messages: formattedMessages, is_dirty: true },
        });
        if (cb) cb();
        return;
      }
      err = response.data.error;
    }
  } catch (error) {
    err = error.toString();
  }
  dispatch({
    type: ProcessesTypes.FETCH_REVIEW_PROCESS_ERROR,
    payload: err,
  });
};

export const initNewProcess = (payload) => (dispatch) => {
  dispatch({
    type: ProcessesTypes.INIT_NEW_PROCESS,
    payload,
  });
};
export const resetProcess = () => (dispatch) => {
  dispatch({
    type: ProcessesTypes.RESET_PROCESS,
  });
};

export const setActiveTab = (payload) => (dispatch) => {
  dispatch({
    type: ProcessesTypes.SET_ACTIVE_TAB,
    payload,
  });
};

export const setActiveEditTab = (payload) => (dispatch) => {
  dispatch({
    type: ProcessesTypes.SET_TAB_TO_EDIT,
    payload,
  });
};

export const saveFormValues = (payload, cb) => async (dispatch) => {
  try {
    dispatch({
      type: ProcessesTypes.SAVE_FORM_VALUES_REQUEST,
      payload,
    });

    let {
      process,
      template,
      step,
      valid,
      save_all_dirty,
      user,
      recordingFeedback,
      scriptFeedback,
    } = payload;
    let mId;
    let now;

    if (payload.chat_message) {
      // generate local message to show until the
      // sent message is bounced back from the server
      mId = ("" + Math.random()).replace("0.", "");
      now = new Date();
      dispatch({
        type: SET_MESSAGES,
        payload: {
          messages: [
            {
              m_id: mId,
              message: {
                ...payload.chat_message,
                process: "current",
                m_type: payload.chat_message.m_type || "chat",
                script_version: payload.chat_message.script_version || "",
                process_key: "current",
                process_id: "current",
                created_ts: now,
              },
            },
          ],
        },
      });
    }

    if (payload.chat_message_2) {
      // generate local message to show until the
      // sent message is bounced back from the server
      mId = ("" + Math.random()).replace("0.", "");
      now = new Date();
      dispatch({
        type: SET_MESSAGES,
        payload: {
          messages: [
            {
              m_id: mId,
              message: {
                ...payload.chat_message_2,
                process: "current",
                m_type: payload.chat_message_2.m_type || "chat",
                process_key: "current",
                process_id: "current",
                created_ts: now,
              },
            },
          ],
        },
      });
    }
    let changed_step_data = {};

    if (recordingFeedback) {
      changed_step_data.recordingFeedback = true;

      if (process.data.fields.recording_feedback.is_dirty) {
        changed_step_data.recording_feedback =
          process.data.fields.recording_feedback.value;
      }
    }
    if (scriptFeedback) {
      changed_step_data.scriptFeedback = true;
    }
    // add the process step
    if (process.data.fields.process_step) {
      changed_step_data.process_step = valid
        ? process.data.fields.process_step.value
        : step.key;

      let step_value = changed_step_data.process_step;

      let processStepIndex = 0;
      // eslint-disable-next-line
      Object.keys(template.steps).map((key, ind) => {
        if (step_value === template.steps[key].key) {
          processStepIndex = template.steps[key].process_step_index;
        }
      });
      if (
        (step_value === "done" || step_value === "online") &&
        processStepIndex === 0
      ) {
        processStepIndex = 100;
      }
      if (
        process.data.fields.order_details_status &&
        process.data.fields.order_details_status.value === "draft"
      ) {
        changed_step_data.process_step = "draft";
        processStepIndex = 0;
      }
      //add process_step_index
      changed_step_data.process_step_index = processStepIndex; //step.process_step_index
    } else {
      debugger;
      changed_step_data.process_step_index = 0;
      changed_step_data.process_step = "draft";
    }
    // add last saved step
    changed_step_data.last_saved_step = step.key;

    changed_step_data.template_key = template.key;

    // add the template key on doc creation
    if (
      !process.data.fields.template_key ||
      process.data.fields.template_key.is_dirty
    )
      changed_step_data.template_key = template.key;

    //update template key
    if (
      process.data.fields.template_key &&
      process.data.fields.draft_template &&
      process.data.fields.template_key.value !==
        process.data.fields.draft_template.value
    ) {
      changed_step_data.draft_template =
        process.data.fields.draft_template.value;
      changed_step_data.template_key = process.data.fields.draft_template.value;
      changed_step_data.contract_status =
        process.data.fields.draft_template.value === "basic_production"
          ? "Production Needed"
          : "Production Complete";
    }

    // add the process next users
    if (
      process.data.fields.next_users &&
      process.data.fields.next_users.is_dirty
    )
      changed_step_data.next_users = process.data.fields.next_users.value;

    // add the process still required users
    if (
      process.data.fields.still_required_users &&
      process.data.fields.still_required_users.is_dirty
    )
      changed_step_data.still_required_users =
        process.data.fields.still_required_users.value;

    // add the process team
    if (process.data.fields.team && process.data.fields.team.is_dirty)
      changed_step_data.team = process.data.fields.team.value;

    // get changed team
    let all_changed_users = process.data.fields.all_changed_users
      ? process.data.fields.all_changed_users.value
      : [];

    // add the process spots
    if (process.data.fields.spots && process.data.fields.spots.is_dirty) {
      changed_step_data.spots = process.data.fields.spots.value;
    }
    // add the recording history
    /*if (
      process.data.fields.recording_path &&
      process.data.fields.recording_path.is_dirty &&
      process.data.fields.recording_path.value &&
      process.data.loaded_fields.recording_path &&
      process.data.loaded_fields.recording_path.value &&
      process.data.loaded_fields.recording_path.value !==
        process.data.fields.recording_path.value
    )
      changed_step_data.recording_history = concat(
        [
          {
            value: get(process, "data.loaded_fields[recording_path].value"),
            time: new Date(),
            user: user.id
          }
        ],
        get(process, "data.fields[recording_history].value", [])
      );*/
    // add the recording to spot linked
    if (
      (process.data.fields.recording_path &&
        process.data.fields.recording_path.is_dirty) ||
      (process.data.fields.recording_path_b &&
        process.data.fields.recording_path_b.is_dirty)
      // && process.data.fields.recording_path.value
    ) {
      changed_step_data.spot_info = map(
        get(process, "data.fields.spot_info.value", []),
        (spot) => ({
          ...spot,
          recording_path:
            spot.key === process.key
              ? process.data.fields.recording_path
                ? process.data.fields.recording_path.value
                : ""
              : spot.recording_path,
          recording_path_b:
            spot.key === process.key
              ? process.data.fields.recording_path_b
                ? process.data.fields.recording_path_b.value
                : ""
              : spot.recording_path_b,
        })
      );
      // set is_dirty to false for force update
      process.data.fields.spot_info = {
        ...process.data.fields.spot_info,
        is_dirty: false,
      };
    }
    // add the script history
    /*if (
      process.data.fields &&
      process.data.fields.script &&
      process.data.fields.script.is_dirty &&
      htmlToText(process.data.fields.script.value) &&
      process.data.loaded_fields &&
      process.data.loaded_fields.script &&
      process.data.loaded_fields.script.value &&
      htmlToText(process.data.loaded_fields.script.value) &&
      process.data.loaded_fields.script.value !==
        process.data.fields.script.value
    )
      changed_step_data.script_history = concat(
        [
          {
            value: get(process, "data.loaded_fields[script].value"),
            time: new Date(),
            user: user.id
          }
        ],
        get(process, "data.fields[script_history].value", [])
      );*/

    // add the process order type(sold.spec)
    // add script history to group for liner template
    if (
      template.key === "liner" &&
      get(process, "data.fields.copy_groups.value.length") > 0
    ) {
      const copyGroups = map(
        get(process, "data.fields.copy_groups.value", []),
        (group, groupIndex) => {
          let scriptsHistoryGroup = [];
          const scriptsHistoryLoaded = get(
            process,
            `data.loaded_fields.copy_groups.value.${groupIndex}.scripts_history_array`,
            []
          );
          if (group.list_scripts && group.list_scripts.length > 0) {
            forEach(group.list_scripts, (script) => {
              let scriptHistory =
                filter(
                  scriptsHistoryLoaded,
                  (item) => item.uuid === get(script, "uuid")
                ) || [];
              if (!scriptHistory || scriptHistory.length === 0) {
                scriptHistory.push({
                  is_current: true,
                  script: get(script, "script"),
                  text: get(script, "text"),
                  uuid: get(script, "uuid"),
                  created_at: new Date(),
                  created_by: pick(user, ["id", "name", "avatar_url"]),
                  start: `${group.start_date} ${group.start_date_time}`,
                  end: `${group.end_date} ${group.end_date_time}`,
                });
              } else {
                const currentScriptHistory = find(
                  scriptHistory,
                  (i) => i.is_current
                );
                if (currentScriptHistory.text !== get(script, "text")) {
                  scriptHistory = [
                    {
                      is_current: true,
                      script: get(script, "script"),
                      text: get(script, "text"),
                      uuid: get(script, "uuid"),
                      created_at: new Date(),
                      created_by: pick(user, ["id", "name", "avatar_url"]),
                      start: `${group.start_date} ${group.start_date_time}`,
                      end: `${group.end_date} ${group.end_date_time}`,
                    },
                    ...map(scriptHistory, (i) => ({
                      ...i,
                      is_current: false,
                    })),
                  ];
                }
              }
              scriptsHistoryGroup = [...scriptsHistoryGroup, ...scriptHistory];
            });
          }
          if (scriptsHistoryGroup && scriptsHistoryGroup.length > 0) {
            return {
              ...group,
              scripts_history_array: scriptsHistoryGroup,
            };
          }
          return group;
        }
      );
      process.data.fields.copy_groups = {
        value: copyGroups,
        is_dirty: true,
      };
    }
    //const type = process.data.fields;
    if (process.data.fields.order_sold) {
      if (process.data.fields.order_sold.value)
        changed_step_data.order_sold = true;
      else changed_step_data.order_sold = false;
    } else {
      changed_step_data.order_sold = true;
    }

    changed_step_data.active = true;

    // add all the dirty step fields
    if (step && !save_all_dirty)
      step.fields.map((field) => {
        if (
          field.field &&
          process.data.fields[field.field] &&
          process.data.fields[field.field].is_dirty
        ) {
          changed_step_data[field.field] =
            process.data.fields[field.field].value;
        }
        // button fields that change other steps values
        if (field.fields) {
          field.fields.map((field) => {
            if (
              field.key &&
              process.data.fields[field.key] &&
              process.data.fields[field.key].is_dirty
            ) {
              changed_step_data[field.key] =
                process.data.fields[field.key].value;
            }
            return false;
          });
        }
        return false;
      });
    else {
      // add all dirty fields regardless of steps
      Object.keys(process.data.fields).map((field_key) => {
        let field = process.data.fields[field_key];
        if (field.is_dirty) changed_step_data[field_key] = field.value;
        return true;
      });
    }

    if (!process.data.fields.key) changed_step_data.active = true;
    let process_key = process.data.fields.key
      ? trim(process.data.fields.key.value)
      : "__new";

    let message = payload.chat_message
      ? payload.chat_message
      : createMessage(payload, changed_step_data);

    let message_2 = payload.chat_message_2
      ? payload.chat_message_2
      : createMessage(payload, changed_step_data);

    // move channel field objects values to fields array
    // this is done so we don't keep adding fields indexes to ES
    // as more custom fields are added
    if (
      changed_step_data.template_key === "digital" &&
      changed_step_data.marketing_channels
    ) {
      if (message.delta && message.delta.marketing_channels) {
        message.delta.marketing_channels = [];
      }

      if (message_2.delta && message_2.delta.marketing_channels) {
        message_2.delta.marketing_channels = [];
      }
      changed_step_data.marketing_channels.map((channel) => {
        if (channel.fields) {
          channel.fields.map((channel_field) => {
            let field_name = labelToNameField(channel_field.label);
            if (channel[field_name]) {
              if (Array.isArray(channel[field_name])) {
                channel_field.value_arr = channel[field_name];
              } else if (typeof channel[field_name] === "object") {
                channel_field.value_obj = channel[field_name];
              } else if (typeof channel[field_name] === "boolean") {
                channel_field.value_bool = channel[field_name];
              } else if (typeof channel[field_name] === "number") {
                channel_field.value_num = channel[field_name];
              } else channel_field.value_str = channel[field_name];
              if (changed_step_data.process_step_index !== 0)
                delete channel[field_name];
            } else if (channel[field_name] === null) {
              channel_field.value_arr = null;
              channel_field.value_obj = null;
              channel_field.value_bool = null;
              channel_field.value_num = null;
              channel_field.value_str = null;
              if (changed_step_data.process_step_index !== 0)
                delete channel[field_name];
            }
          });
        }
      });
    }

    let post_data = {
      meta: {
        process_key,
        step_key: step.key,
        message,
        message_2,
        all_changed_users,
        ...(payload.meta ? payload.meta : {}),
      },
      data: { ...changed_step_data },
    };
    const processKey = trim(process_key);
    let response = await Api.doCall(
      get(user, "id") && get(user, "id") !== -1
        ? Const.GET_PROCESS(processKey)
        : Const.GET_PUBLIC_PROCESS(processKey),
      "PUT",
      post_data
    );
    if (response.status === 200) {
      const processId = trim(response.data.key);
      if (payload.success_callback) payload.success_callback(response);
      if (isFunction(cb)) cb();
      // don't change to view mode when ...
      if (
        !get(payload, "chat_message") &&
        !get(payload, "chat_message_2") &&
        !get(payload, "isReminder") &&
        !get(payload, "isForce", false)
      ) {
        const stepKeysKeepEditMode = [
          "assign_team",
          "write_script",
          "approve_script",
          "assign_vo",
          "voice_over",
          "record_script",
          "approve_recording",
          "continuity",
          "dub_script",
        ];
        // switch (get(payload, "step.key")) {
        //   case "record_script":
        //     const recordScriptStatus = get(
        //       process,
        //       "data.fields.record_script_status.value"
        //     );
        //     if (recordScriptStatus === "completed") {
        //       history.replace(
        //         URL.VIEW_PROCESS({
        //           template_id: process.data.fields.template_key.value,
        //           process_id: response.data.key,
        //         })
        //       );
        //     }
        //     break;
        //   case "voice_over":
        //     const voiceOverStatus = get(
        //       process,
        //       "data.fields.voice_over_status.value"
        //     );
        //     if (voiceOverStatus === "completed") {
        //       history.replace(
        //         URL.VIEW_PROCESS({
        //           template_id: process.data.fields.template_key.value,
        //           process_id: response.data.key,
        //         })
        //       );
        //     }
        //     break;
        //   default:
        //     history.replace(
        //       URL.VIEW_PROCESS({
        //         template_id: process.data.fields.template_key.value,
        //         process_id: response.data.key,
        //       })
        //     );
        //     break;
        // }
        if (!includes(stepKeysKeepEditMode, get(payload, "step.key"))) {
          history.replace(
            URL.VIEW_PROCESS({
              template_id: process.data.fields.template_key.value,
              process_id: processId, //process.data.fields.key.value
            })
          );
        }
        // else {
        //   history.replace(
        //     URL.UPDATE_PROCESS({
        //       template_id: process.data.fields.template_key.value,
        //       process_id: response.data.key //process.data.fields.key.value
        //     })
        //   );
        // }
      }
      if (payload.chat_message) {
        // set message is bounced back from the server
        get(response, "data.messages", []).unshift({
          _source: {
            ...message,
            m_type: "chat",
          },
        });
        // refresh processes when adding new message
        dispatch({
          type: ProcessesTypes.FORGET_PRCESSES,
        });

        // redirect to edit when adding new message into empty order
        if (
          // get(response, "data.contract_stations.length") === 0 &&
          get(response, "data.order_details_status") !== "draft"
        ) {
          const isAdd = document.location.href.indexOf("processes/add") > -1;
          if (isAdd) {
            history.replace(
              URL.UPDATE_PROCESS({
                template_id: process.data.fields.template_key.value,
                process_id: processId, //process.data.fields.key.value
              })
            );
            // update messages when process don't have order_stations for push notification
          } else if (get(response, "data.contract_stations.length") === 0) {
            dispatch({
              type: REPLACE_MESSAGES,
              payload: {
                messages: [
                  {
                    m_id: mId,
                    message: {
                      ...payload.chat_message,
                      process: processId,
                      m_type: "chat",
                      process_key: processId,
                      process_id: processId,
                      created_ts: now.getTime() / 1000,
                    },
                  },
                ],
              },
            });
          }
        }
        const isClientReview = document.location.href.indexOf("review") > -1;
        // update messages process when client review. We dont have the auth for push notification
        if (isClientReview) {
          dispatch({
            type: REPLACE_MESSAGES,
            payload: {
              messages: [
                {
                  m_id: mId,
                  message: {
                    ...payload.chat_message,
                    process: processId,
                    process_key: processId,
                    process_id: processId,
                    created_ts: now.getTime() / 1000,
                  },
                },
              ],
            },
          });
        }
      }

      if (payload.chat_message_2) {
        // set message is bounced back from the server
        get(response, "data.messages", []).unshift({
          _source: {
            ...message_2,
            m_type: "chat",
          },
        });
        // refresh processes when adding new message
        dispatch({
          type: ProcessesTypes.FORGET_PRCESSES,
        });

        // redirect to edit when adding new message into empty order
        if (
          get(response, "data.contract_stations.length") === 0 &&
          get(response, "data.order_details_status") !== "draft"
        ) {
          const isAdd = document.location.href.indexOf("processes/add") > -1;
          if (isAdd) {
            history.replace(
              URL.UPDATE_PROCESS({
                template_id: process.data.fields.template_key.value,
                process_id: processId, //process.data.fields.key.value
              })
            );
            // update messages when process don't have order_stations for push notification
          } else {
            dispatch({
              type: REPLACE_MESSAGES,
              payload: {
                messages: [
                  {
                    m_id: mId,
                    message: {
                      ...payload.chat_message_2,
                      process: processId,
                      m_type: "chat",
                      process_key: processId,
                      process_id: processId,
                      created_ts: now.getTime() / 1000,
                    },
                  },
                ],
              },
            });
          }
        }
      }
      // spread channel field values as sub objects
      if (
        response.data.template_key === "digital" &&
        response.data.marketing_channels
      ) {
        response.data.marketing_channels.map((channel) => {
          if (channel.fields) {
            channel.fields.map((channel_field) => {
              let field_name = labelToNameField(channel_field.label);
              if (!channel[field_name]) {
                if (channel_field.value_arr)
                  channel[field_name] = channel_field.value_arr;
                else if (channel_field.value_obj)
                  channel[field_name] = channel_field.value_obj;
                else if (channel_field.value_bool)
                  channel[field_name] = channel_field.value_bool;
                else if (channel_field.value_num)
                  channel[field_name] = channel_field.value_num;
                else if (channel_field.value_str)
                  channel[field_name] = channel_field.value_str;
                else channel[field_name] = channel_field.value; // backward compability
              }
            });
          }
        });
      }

      if (payload.recordingFeedback && !payload.clientReview) {
        dispatch(
          saveFormValuesSuccess({
            ...changed_step_data,
            ...response.data,
            recordingFeedback: payload.recordingFeedback,
          })
        );
      } else {
        dispatch(
          saveFormValuesSuccess({
            ...changed_step_data,
            ...response.data,
            chat_message: payload.chat_message,
          })
        );
      }
    } else {
      dispatch(saveFormValuesError(response.error.toString()));
    }
  } catch (error) {
    dispatch(saveFormValuesError(error.toString()));
  }
};

export const duplicate = (payload, cb) => async (dispatch) => {
  try {
    dispatch({
      type: ProcessesTypes.SAVE_FORM_VALUES_REQUEST,
      payload,
    });
    let url = window.location.href;
    let { post_data, process_key, success_callback } = payload;
    if (url.includes("view") || url.includes("edit")) {
      post_data.button = post_data.button
        ? post_data.button
        : "production_queue";
    } else {
      if (!post_data.s_button && !post_data.button) {
        post_data.button = "produced_spots";
      }
    }
    const isContest = post_data.template_key === "contest";
    const processKey = trim(process_key);
    const apiUrl = isContest
      ? Const.DUPLICATE_CONTEST_PROCESS(processKey)
      : Const.DUPLICATE_PROCESS(processKey);
    let response = await Api.doCall(apiUrl, "PUT", post_data);
    if (response.status === 200) {
      if (success_callback) payload.success_callback(trim(response.data));
      dispatch(saveFormValuesSuccess({}));
      if (cb) cb();
    } else {
      dispatch(saveFormValuesError(response.error.toString()));
      if (cb) cb();
    }
  } catch (error) {
    //debugger
    dispatch(saveFormValuesError(error.toString()));
    if (cb) cb();
  }
};

export const reschedule = (payload, cb) => async (dispatch) => {
  try {
    dispatch({
      type: ProcessesTypes.SAVE_FORM_VALUES_REQUEST,
      payload,
    });
    let { post_data, process_key, success_callback } = payload;
    const processKey = trim(process_key);
    const apiUrl = Const.RESCHEDULE_PROCESS(processKey);
    let response = await Api.doCall(apiUrl, "PUT", post_data);
    if (response.status === 200) {
      if (success_callback) payload.success_callback(trim(response.data));
      dispatch(saveFormValuesSuccess({}));
      if (cb) cb();
    } else {
      dispatch(saveFormValuesError(response.error.toString()));
      if (cb) cb();
    }
  } catch (error) {
    //debugger
    dispatch(saveFormValuesError(error.toString()));
    if (cb) cb();
  }
};

export function saveFormValuesSuccess(payload) {
  return {
    type: ProcessesTypes.SAVE_FORM_VALUES_SUCCESS,
    payload,
  };
}

export function saveFormValuesError(payload) {
  return {
    type: ProcessesTypes.SAVE_FORM_VALUES_ERROR,
    payload,
  };
}

export function fetchProcessSuccess(payload) {
  return {
    type: ProcessesTypes.FETCH_PROCESS_SUCCESS,
    payload,
  };
}

export function fetchProcessError(payload) {
  return {
    type: ProcessesTypes.FETCH_PROCESS_ERROR,
    payload,
  };
}

export const deleteProcess = (id, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: ProcessesTypes.DELETE_PROCESS_REQUEST });
    let response = await Api.doCall(Const.GET_PROCESS(trim(id)), "DELETE");
    if (response.status === 200) {
      if (!response.error) {
        dispatch({ type: ProcessesTypes.DELETE_PROCESS_SUCCESS, payload: id });
        if (cb) cb(response);
        else history.push(URL.PROCESSES());
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: ProcessesTypes.DELETE_PROCESS_ERROR, payload: errorStr });
};

export const deleteSpotProcess = (id, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: ProcessesTypes.DELETE_PROCESS_REQUEST });
    let response = await Api.doCall(
      `${Const.GET_PROCESS(trim(id))}?spot_only=true`,
      "DELETE"
    );
    if (response.status === 200) {
      if (!response.error) {
        dispatch({
          type: ProcessesTypes.DELETE_PROCESS_SUCCESS,
          payload: {
            id,
            spot_only: true,
            nextProcessId: trim(response.data),
          },
        });
        if (cb) cb(response);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: ProcessesTypes.DELETE_PROCESS_ERROR, payload: errorStr });
};

export const archiveProcess = (id, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: ProcessesTypes.ARCHIVE_PROCESS_REQUEST });
    let response = await Api.doCall(
      `${Const.GET_PROCESS(trim(id))}/archive`,
      "POST"
    );
    if (response.status === 200) {
      if (!response.error) {
        dispatch({
          type: ProcessesTypes.ARCHIVE_PROCESS_SUCCESS,
          payload: id,
        });
        if (cb) cb(response);
        else history.push(URL.PROCESSES());
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: ProcessesTypes.ARCHIVE_PROCESS_ERROR, payload: errorStr });
};

export const getProcesses = (payload) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: ProcessesTypes.FETCH_PROCESSES_REQUEST, payload });
    let params = {
      sort: payload.sort,
      page: payload.page,
      search: payload.search,
    };
    if (payload.sortTotally) {
      params.sortTotally = payload.sortTotally;
    }
    if (payload.template_key) {
      params.template_key = payload.template_key;
    }

    if (payload.accounts) {
      params.accounts = payload.accounts;
    }

    if (payload.account_executive) {
      params.account_executive = payload.account_executive;
    }

    const statuses = get(payload, "statuses", []);
    if (statuses && statuses.length > 0) {
      params.statuses = statuses;
    }

    if (payload.status) {
      params.status = payload.status;
    }

    if (payload.stations && payload.stations.length > 0) {
      params.stations = payload.stations;
    }

    if (payload.stations_ids && payload.stations_ids.length > 0) {
      params.stations_ids = payload.stations_ids;
    }

    if (payload.spot_types && payload.spot_types.length > 0) {
      params.spot_types = payload.spot_types;
    }

    if (payload.spot_status && payload.spot_status.length > 0) {
      params.spot_status = payload.spot_status;
    }

    if (payload.deadline && payload.deadline.length > 0) {
      params.deadline = payload.deadline;
    }

    if (payload.client_id) {
      params.client_id = payload.client_id;
    }

    if (payload.client_name) {
      params.client_name = payload.client_name;
    }

    if (payload.expiring_in_days) {
      params.expiring_in_days = payload.expiring_in_days;
    }

    let start_date = get(payload, "start_date");
    if (start_date) {
      start_date = ["produced_spots", "archived_spots"].includes(payload.filter)
        ? payload.start_date // keep format
        : moment(start_date).format("MM/DD/YYYY");
      params.start_date = start_date;
    }

    let end_date = get(payload, "end_date");
    if (end_date) {
      end_date = ["produced_spots", "archived_spots"].includes(payload.filter)
        ? payload.end_date // keep format
        : moment(end_date).format("MM/DD/YYYY");
      params.end_date = end_date;
    }

    let url;
    let response;
    if (payload.filter === "expiring_spots") {
      url = `${Const.GET_EXPIRING_SPOTS()}?${queryString.stringify(params, {
        arrayFormat: "bracket",
      })}`;
      response = await Api.doCall(url, "GET");
    } else if (payload.filter === "produced_spots") {
      url = `${Const.GET_PRODUCED_SPOTS()}?${queryString.stringify(params, {
        arrayFormat: "bracket",
      })}`;
      response = await Api.doCall(url, "POST", params);
    } else if (payload.filter === "archived_spots") {
      url = `${Const.GET_ARCHIVED_SPOTS()}?${queryString.stringify(params, {
        arrayFormat: "bracket",
      })}`;
      response = await Api.doCall(url, "POST", params);
    } else {
      url = `${Const.GET_PROCESSES(
        payload.filter
      )}?${queryString.stringify(params, { arrayFormat: "bracket" })}`;
      response = await Api.doCall(url, "GET");
    }

    if (response.status === 200) {
      if (!response.data.error) {
        dispatch(
          fetchProcessesSucces({
            ...omit(payload, ["page"]),
            page: get(response, "data.page", 1),
            pages: get(response, "data.pages", 1),
            data: get(response, "data.items", []),
            total: get(response, "data.total"),
          })
        );
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch(fetchProcessesError({ filter: payload.filter, error: errorStr }));
};

export function fetchProcessesSucces(payload) {
  return {
    type: ProcessesTypes.FETCH_PROCESSES_SUCCESS,
    payload,
  };
}

export function fetchProcessesError(payload) {
  return {
    type: ProcessesTypes.FETCH_PROCESSES_ERROR,
    payload,
  };
}

export function resetTab() {
  return {
    type: ProcessesTypes.RESET_TAB,
  };
}

export const getProcessesInComing = (payload) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({
      type: ProcessesTypes.FETCH_PROCESSES_INCOMING_REQUEST,
      payload,
    });
    const processId = trim(payload.process_id);
    const params = {
      sort: payload.sort,
      page: payload.page,
      rpp: payload.rpp,
      search: payload.search,
      process_id: processId,
    };
    const url = `${Const.GET_PROCESSES(payload.filter)}?${queryString.stringify(
      params
    )}`;
    let response = await Api.doCall(url, "GET");
    if (response.status === 200) {
      if (!response.data.error) {
        dispatch({
          type: ProcessesTypes.FETCH_PROCESSES_INCOMING_SUCCESS,
          payload: {
            filter: payload.filter,
            data: get(response, "data.items", []),
            process_id: processId,
          },
        });
        return;
        //}
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch(fetchProcessesError({ filter: payload.filter, error: errorStr }));
};

export const updateAssignmentProcess = ({ data, cb }) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: ProcessesTypes.ASSIGNMENT_PROCESS_REQUEST, data });
    const processKey = trim(data.process_id);
    const url =
      data.action === "take"
        ? Const.CONTINUITY_TAKEN_PROCESS(processKey)
        : Const.CONTINUITY_RELEASE_PROCESS(processKey);
    let response = await Api.doCall(url, "POST");
    if (response.status === 200) {
      if (!response.data.error) {
        dispatch({
          type: ProcessesTypes.ASSIGNMENT_PROCESS_SUCCESS,
          payload: response.data,
        });
        if (isFunction(cb)) cb(response);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: ProcessesTypes.ASSIGNMENT_PROCESS_ERROR,
    error: errorStr,
  });
};

export const assignTeamProcess = (data, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: ProcessesTypes.ASSIGN_TEAM_PROCESS_REQUEST, data });
    const processKey = trim(data.process_id);
    const url = Const.ASSIGN_TEAM_PROCESS(processKey);
    let response = await Api.doCall(url, "POST", data);
    if (response.status === 200) {
      if (!response.data.error) {
        dispatch({
          type: ProcessesTypes.ASSIGN_TEAM_PROCESS_SUCCESS,
          payload: response.data,
        });
        if (isFunction(cb)) cb(response);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: ProcessesTypes.ASSIGN_TEAM_PROCESS_ERROR,
    error: errorStr,
  });
};

export const assignVoTeamProcess = (data, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: ProcessesTypes.ASSIGN_VO_TEAM_PROCESS_REQUEST, data });
    const processKey = trim(data.process_id);
    const url = Const.ASSIGN_VO_TEAM_PROCESS(processKey);
    let response = await Api.doCall(url, "POST", data);
    if (response.status === 200) {
      if (!response.data.error) {
        dispatch({
          type: ProcessesTypes.ASSIGN_VO_TEAM_PROCESS_SUCCESS,
          payload: response.data,
        });
        if (isFunction(cb)) cb(response);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: ProcessesTypes.ASSIGN_VO_TEAM_PROCESS_ERROR,
    error: errorStr,
  });
};

export const forgetProcess = (payload) => (dispatch) => {
  dispatch({
    type: ProcessesTypes.FORGET_PROCESS,
  });
};

export const syncWOOrders = ({ cb }) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: ProcessesTypes.SYNC_WO_ORDERS_REQUEST });

    const url = Const.WIDE_ORBIT_SYNC();

    let response = await Api.doCall(url, "POST");

    if (response.status === 200) {
      if (!response.data.error) {
        dispatch({
          type: ProcessesTypes.SYNC_WO_ORDERS_SUCCESS,
          payload: {},
        });

        if (isFunction(cb)) cb();

        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: ProcessesTypes.SYNC_WO_ORDERS_ERROR,
    error: errorStr,
  });
};

export const syncWOCartNumbers = ({ cb }) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: ProcessesTypes.SYNC_WO_CART_NUMBERS_REQUEST });

    const url = Const.WIDE_ORBIT_CART_NUMBER_SYNC();

    let response = await Api.doCall(url, "POST");

    if (response.status === 200) {
      if (!response.data.error) {
        dispatch({
          type: ProcessesTypes.SYNC_WO_CART_NUMBERS_SUCCESS,
          payload: {},
        });

        if (isFunction(cb)) cb();

        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: ProcessesTypes.SYNC_WO_CART_NUMBERS_ERROR,
    error: errorStr,
  });
};

export const automateDubbing = (id, { callback, spotIndex }) => async (
  dispatch
) => {
  let errorStr = "";
  let buttonKey = spotIndex ? `${id}_${spotIndex}` : id;

  try {
    dispatch({
      type: ProcessesTypes.AUTOMATE_DUBBING_REQUEST,
      payload: {
        [buttonKey]: {
          error: null,
          progress: 30,
          timeout: null,
        },
      },
    });

    const url = Const.PROCESS_AUTOMATE_DUBBING(id);

    let response = await Api.doCall(url, "POST", { spotIndex });

    if (response.status === 200) {
      if (!response.data.error) {
        dispatch({
          type: ProcessesTypes.AUTOMATE_DUBBING_SUCCESS,
          payload: {
            process: response.data,
            dubbingProgress: {
              [buttonKey]: {
                error: null,
                progress: 100,
                timeout: null,
              },
            },
          },
        });

        if (isFunction(callback)) callback(response.data);

        return;
      }
    }
  } catch (error) {
    if (isFunction(callback)) callback();
    errorStr = error.toString();
  }

  dispatch({
    type: ProcessesTypes.AUTOMATE_DUBBING_ERROR,
    payload: {
      [buttonKey]: {
        error: errorStr,
        progress: 0,
        timeout: null,
      },
    },
  });
};

export const updateDubbingProgress = (buttonKey, progress) => async (
  dispatch
) => {
  dispatch({
    type: ProcessesTypes.AUTOMATE_DUBBING_PROGRESS,
    payload: {
      [buttonKey]: {
        error: null,
        progress: progress,
        timeout: null,
      },
    },
  });
};

export const updateDubbingButtonTimeout = (buttonKey, timeout) => async (
  dispatch
) => {
  dispatch({
    type: ProcessesTypes.AUTOMATE_DUBBING_PROGRESS,
    payload: {
      [buttonKey]: {
        error: null,
        timeout: timeout,
        progress: timeout ? 30 : 0,
      },
    },
  });
};

export const sendMessage = (payload = {}, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: ProcessesTypes.SEND_MESSAGE_REQUEST });
    const mId = ("" + Math.random()).replace("0.", "");
    const now = new Date();
    const processId = trim(payload.process_id);
    // if the order assigned the stations -> use key = current -> it will auto remove when has new message from pusher
    // if the order still not assigned the stations -> use key = process key
    const isContractStations =
      get(payload, "process.data.fields.contract_stations.value.length", 0) > 0;
    dispatch({
      type: SET_MESSAGES,
      payload: {
        messages: [
          {
            m_id: mId,
            message: {
              ...payload.message,
              process: isContractStations ? "current" : processId,
              m_type: payload.message.m_type || "chat",
              script_version: payload.message.script_version || "",
              process_key: isContractStations ? "current" : processId,
              process_id: isContractStations ? "current" : processId,
              created_ts: now.getTime() / 1000,
            },
          },
        ],
      },
    });
    const url = Const.SEND_MESSAGE_PROCESS(processId);

    let response = await Api.doCall(url, "POST", {
      message: payload.message,
      meta: {
        process_key: processId,
      },
    });
    if (response.status === 200) {
      if (!response.data.error) {
        dispatch({
          type: ProcessesTypes.SEND_MESSAGE_SUCCESS,
          payload: {},
        });
        if (isFunction(cb)) cb();

        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: ProcessesTypes.SEND_MESSAGE_ERROR,
    error: errorStr,
  });
};

export const notifyEditingProcess = (data, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: ProcessesTypes.NOTIFY_EDITING_PROCESS_REQUEST });
    let response = await Api.doCall(
      `${Const.GET_PROCESS(trim(data.id))}/notify_editing`,
      "POST",
      data
    );
    if (response.status === 200) {
      if (!response.error) {
        dispatch({
          type: ProcessesTypes.NOTIFY_EDITING_PROCESS_SUCCESS,
          payload: data,
        });
        if (cb) cb(response);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: ProcessesTypes.NOTIFY_EDITING_PROCESS_ERROR,
    payload: errorStr,
  });
};

export const unlockProcess = (params, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    const { id, source } = params;
    dispatch({ type: ProcessesTypes.UNLOCK_PROCESS_REQUEST });
    let response = await Api.doCall(
      `${Const.GET_PROCESS(trim(id))}/unlock?source=${source}`,
      "POST"
    );
    if (response.status === 200) {
      if (!response.error) {
        dispatch({ type: ProcessesTypes.UNLOCK_PROCESS_SUCCESS, payload: id });
        if (cb) cb(response);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: ProcessesTypes.UNLOCK_PROCESS_ERROR, payload: errorStr });
};

export const sendBackProcess = (params, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: ProcessesTypes.SEND_BACK_PROCESS_REQUEST });
    let response = await Api.doCall(Const.SEND_BACK_PROCESS(), "POST", params);
    if (response.status === 200) {
      if (!response.error) {
        dispatch({
          type: ProcessesTypes.SEND_BACK_PROCESS_SUCCESS,
          payload: params,
        });
        if (cb) cb(response);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: ProcessesTypes.SEND_BACK_PROCESS_ERROR, payload: errorStr });
};

export const addRotation = (params = {}, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: ProcessesTypes.ADD_ROTATION_REQUEST });
    let response = await Api.doCall(
      Const.ADD_ROTATION_PROCESS(trim(params.id)),
      "POST",
      params
    );
    if (response.status === 200) {
      if (!response.error) {
        dispatch({ type: ProcessesTypes.ADD_ROTATION_SUCCESS, payload: {} });
        if (cb) cb(trim(response.data));
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: ProcessesTypes.ADD_ROTATION_ERROR, payload: errorStr });
};
export const setActiveFilterProcesses = (payload) => (dispatch) =>
  dispatch({
    type: ProcessesTypes.SET_ACTIVE_FILTER_PROCESSES,
    payload,
  });

export const getProcessesByIds = (ids = [], cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: ProcessesTypes.FETCH_PROCESSES_BY_IDS_REQUEST });
    let response = await Api.doCall(Const.GET_PROCESSES_BY_IDS(), "POST", {
      ids,
    });
    if (response.status === 200) {
      if (!response.error) {
        dispatch({
          type: ProcessesTypes.FETCH_PROCESSES_BY_IDS_SUCCESS,
          payload: response.data,
        });
        if (cb) cb(response);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: ProcessesTypes.FETCH_PROCESSES_BY_IDS_ERROR,
    payload: errorStr,
  });
};

export const forgetProcessesFilter = (payload) => (dispatch) =>
  dispatch({
    type: ProcessesTypes.FORGET_PROCESSES_FILTER_SUCCESS,
    payload,
  });

export const copyOrder = (params = {}, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: ProcessesTypes.COPY_ORDER_REQUEST });
    let response = await Api.doCall(
      Const.COPY_ORDER(trim(params.id)),
      "POST",
      params
    );
    if (response.status === 200) {
      if (!response.error) {
        dispatch({ type: ProcessesTypes.COPY_ORDER_SUCCESS, payload: {} });
        if (cb) cb(response.data);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: ProcessesTypes.COPY_ORDER_ERROR, payload: errorStr });
};

export const updateFinalScript = (params = {}, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: ProcessesTypes.UPDATE_FINAL_SCRIPT_REQUEST });
    let response = await Api.doCall(
      Const.UPDATE_FINAL_SCRIPT(trim(params.id)),
      "POST",
      params
    );
    if (response.status === 200) {
      if (!response.error) {
        dispatch({
          type: ProcessesTypes.UPDATE_FINAL_SCRIPT_SUCCESS,
          payload: {},
        });
        dispatch(getProcessesByIds([params.id]));
        if (cb) cb(response.data);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: ProcessesTypes.UPDATE_FINAL_SCRIPT_ERROR,
    payload: errorStr,
  });
};

export const unarchiveProcess = (id, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: ProcessesTypes.UNARCHIVE_PROCESS_REQUEST });
    let response = await Api.doCall(
      `${Const.GET_PROCESS(id)}/unarchive`,
      "POST"
    );
    if (response.status === 200) {
      if (!response.error) {
        dispatch({
          type: ProcessesTypes.UNARCHIVE_PROCESS_SUCCESS,
          payload: id,
        });
        if (cb) cb(response);
        else history.push(URL.PROCESSES());
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: ProcessesTypes.UNARCHIVE_PROCESS_ERROR, payload: errorStr });
};

export const convertSpecToSold = (params = {}, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: ProcessesTypes.CONVERT_SPEC_TO_SOLD_REQUEST });
    const response = await Api.doCall(
      Const.CONVERT_SPEC_TO_SOLD(trim(params.id)),
      "POST",
      params
    );
    if (response.status === 200) {
      if (!response.error) {
        dispatch({
          type: ProcessesTypes.CONVERT_SPEC_TO_SOLD_SUCCESS,
          payload: {},
        });
        if (cb) cb(response.data);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
    if (cb) cb();
  }
  dispatch({
    type: ProcessesTypes.CONVERT_SPEC_TO_SOLD_ERROR,
    payload: errorStr,
  });
};

export const checkAutoDubbing = (params = {}) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: ProcessesTypes.CHECK_AUTO_DUBBING_REQUEST });
    const response = await Api.doCall(
      Const.CHECK_AUTO_DUBBING(params.id),
      "GET"
    );
    if (response.status === 200) {
      if (!response.error) {
        dispatch({
          type: ProcessesTypes.CHECK_AUTO_DUBBING_SUCCESS,
          payload: get(response, "data.auto_dubbing_enabled", false),
        });
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: ProcessesTypes.CHECK_AUTO_DUBBING_ERROR,
    payload: errorStr,
  });
};
