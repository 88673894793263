import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Form, FormGroup, Button, Card, CardBody, Col, Row } from "reactstrap";
import cx from "classnames";
import { get, find, map, times } from "lodash";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";

import Dropdown from "components/Dropdown";
import Spinner from "components/Spinner";
import { textCases, textFonts, textSizes, widgetLengths } from "utils/config";
import JockWidget from "../JockWidget";
import JockPreviewCard from "../JockPreviewCard";
import bn from "utils/bemnames";
import SettingFontSize from "./components/SettingFontSize";

const bem = bn.create("display-settings-form");

const SortableItem = SortableElement(({ isPreview, ...rest }) => {
  if (isPreview) {
    return <JockPreviewCard {...rest} />;
  }
  return <JockWidget {...rest} />;
});

const SortableList = SortableContainer(
  ({
    sidebarWidgets = [],
    widgets = [],
    mutiViewWidgets = [],
    unusedWidgets = [],
    isActiveDragging,
  }) => (
    <div>
      <Row className="mt-2">
        <Col md={4}>
          <FormGroup>
            <label>
              <FormattedMessage id="jock console > config sidebar view" />
            </label>
            <div className={bem.e("jock-tags")}>
              {map(sidebarWidgets, (widget, index) => (
                <SortableItem
                  widget={widget}
                  index={index}
                  key={widget.id || index}
                  isActive
                  collection="all"
                />
              ))}
            </div>
          </FormGroup>
        </Col>
        <Col md={8}>
          <FormGroup>
            <label>
              <FormattedMessage id="jock console > multiple view" />
            </label>
            <div className="d-flex flex-row">
              {map(mutiViewWidgets, (widget, index) => (
                <SortableItem
                  isPreview
                  index={sidebarWidgets.length + index}
                  widget={widget}
                  key={widget.id || index}
                  collection={isActiveDragging ? "all" : "multi"}
                />
              ))}
            </div>
          </FormGroup>
        </Col>
        <Col sm={12}>
          <FormGroup>
            <label>
              <FormattedMessage id="jock console > unused widgets" />
            </label>
            <div
              className={cx(bem.e("unused-widgets-wrapper"), {
                "has-data": !!unusedWidgets.length,
                "empty-widgets": !widgets.length,
              })}
            >
              {unusedWidgets.length ? (
                map(unusedWidgets, (widget, index) => (
                  <SortableItem
                    index={
                      sidebarWidgets.length + mutiViewWidgets.length + index
                    }
                    widget={widget}
                    key={widget.id || index}
                    isActive
                    collection="all"
                  />
                ))
              ) : (
                <FormattedMessage
                  id={
                    widgets.length
                      ? "jock console > unused widgets drag desc"
                      : "jock console > unused widgets drag desc empty"
                  }
                />
              )}
            </div>
          </FormGroup>
        </Col>
      </Row>
    </div>
  )
);

class DisplaySettingsForm extends React.Component {
  getMappedWidgets = (ids, padCount) => {
    const { settings: { widgets } = {} } = this.props;
    const widgetMapper = (id) => find(widgets, { id }) || {};
    const mapped = !widgets || !widgets.length ? [] : map(ids, widgetMapper);
    if (padCount) {
      times(padCount - mapped.length, () => {
        mapped.push({ empty: true });
      });
    }
    return mapped;
  };

  onSortStart = (item, b) => {
    const { index } = item;
    if (
      index >= widgetLengths.sidebar &&
      index < widgetLengths.sidebar + widgetLengths.multiView
    ) {
      this.props.setIsMultiDragging(true);
    } else if (index < widgetLengths.sidebar) {
      this.props.setIsActiveDragging(true);
    }
  };

  onSortEnd = (formProps) => ({ oldIndex, newIndex, collection }, e) => {
    const { values } = formProps;
    this.props.setIsMultiDragging(false);
    this.props.setIsActiveDragging(false);

    const sidebarPlusMulti = widgetLengths.sidebar + widgetLengths.multiView;
    const isBetween = (num, a, b) => {
      const min = Math.min.apply(Math, [a, b]);
      const max = Math.max.apply(Math, [a, b]);
      return num >= min && num < max;
    };

    // const sidebarWidgets = this.getMappedWidgets(values.active_widgets, widgetLengths.sidebar);
    // const mutiViewWidgets = this.getMappedWidgets(values.multi_view_widgets, widgetLengths.multiView);
    // const unusedWidgets = this.getMappedWidgets(values.unused_widgets);

    // Internal to sidebar widgets
    if (
      isBetween(oldIndex, 0, widgetLengths.sidebar) &&
      isBetween(newIndex, 0, widgetLengths.sidebar)
    ) {
      formProps.setFieldValue(
        "active_widgets",
        arrayMove(values.active_widgets, oldIndex, newIndex)
      );
      return;
    }
    // Internal to multi view
    if (
      isBetween(oldIndex, widgetLengths.sidebar, sidebarPlusMulti) &&
      isBetween(newIndex, widgetLengths.sidebar, sidebarPlusMulti)
    ) {
      // Removing the added index of the sidebar widgets
      const finalOldIndex = oldIndex - widgetLengths.sidebar;
      const finalNewIndex = newIndex - widgetLengths.sidebar;
      formProps.setFieldValue(
        "multi_view_widgets",
        arrayMove(values.multi_view_widgets, finalOldIndex, finalNewIndex)
      );
      return;
    }
    // If active sidebar item is dragged to multi view
    if (
      isBetween(oldIndex, 0, widgetLengths.sidebar) &&
      isBetween(newIndex, widgetLengths.sidebar, sidebarPlusMulti)
    ) {
      const selectedActive = values.active_widgets[oldIndex];
      if (values.multi_view_widgets.includes(selectedActive)) {
        // We do not need to change anything as the selected widget is already preset in multi view
        return;
      }
      const newMultiViews = [...values.multi_view_widgets];
      const finalNewIndex = newIndex - widgetLengths.sidebar;
      newMultiViews[finalNewIndex] = selectedActive;
      formProps.setFieldValue("multi_view_widgets", newMultiViews);
    }
    // If unused widget is dropped on active sidebar
    if (
      isBetween(oldIndex, sidebarPlusMulti, Number.MAX_SAFE_INTEGER) &&
      isBetween(newIndex, 0, widgetLengths.sidebar)
    ) {
      const allWidgets = [...values.active_widgets, ...values.unused_widgets];
      // Need to remove only multi view as we are adding all the active and unused together
      const finalOldIndex = oldIndex - widgetLengths.multiView;
      const updatedWidgets = arrayMove(allWidgets, finalOldIndex, newIndex);
      const newActive = updatedWidgets.slice(0, widgetLengths.sidebar);
      const newUnused = updatedWidgets.slice(widgetLengths.sidebar);
      formProps.setFieldValue("active_widgets", newActive);
      formProps.setFieldValue("unused_widgets", newUnused);
    }
  };

  renderForm = () => {
    const {
      isLoading,
      settings,
      isActiveDragging,
      isMultiDragging,
      displaySettings,
    } = this.props;
    const {
      widgets,
      active_widgets,
      multi_view_widgets,
      unused_widgets,
      station_id,
    } = settings;
    const { font, font_case, font_size } = displaySettings;
    const selectedFont = find(textFonts, { value: font });
    const selectedCase = find(textCases, { value: font_case });
    const settingFontSize = font_size - 16 > 0 ? font_size - 16 : 0;
    return (
      <Formik
        enableReinitialize
        initialValues={{
          font_size: font_size || textSizes[0],
          font: selectedFont,
          font_case: selectedCase,
          active_widgets,
          multi_view_widgets,
          unused_widgets,
          station_id,
        }}
        validationSchema={Yup.object().shape({
          font: Yup.string(),
          font_size: Yup.string(),
          font_case: Yup.string(),
          active_widgets: Yup.array().of(Yup.string()),
          multi_view_widgets: Yup.array().of(Yup.string()),
          unused_widgets: Yup.array().of(Yup.string()),
        })}
        onSubmit={this.props.onFormSubmit}
      >
        {(formProps) => {
          const errors = formProps.errors;
          const touched = formProps.touched;
          const values = formProps.values;
          const sidebarWidgets = this.getMappedWidgets(
            values.active_widgets,
            widgetLengths.sidebar
          );
          const mutiViewWidgets = this.getMappedWidgets(
            values.multi_view_widgets,
            widgetLengths.multiView
          );
          const unusedWidgets = this.getMappedWidgets(values.unused_widgets);
          const fontValue = get(values, "font.value", "");
          const fontLabel = get(values, "font.label", "");
          const caseValue = get(values, "font_case.value", "");
          const caseLabel = get(values, "font_case.label", "");
          return (
            <Form onSubmit={formProps.handleSubmit}>
              <div className={bem.e("title")}>
                <FormattedMessage id="jock console > set fonts" />
              </div>
              <div className={bem.e("hr")} />
              <div className={bem.e("subtitle")}>
                <FormattedMessage id="jock console > fonts desc" />
              </div>
              <Row>
                <Col md={6}>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Dropdown
                          name="font"
                          options={textFonts}
                          placeholder={this.props.intl.formatMessage({
                            id: "jock console > select",
                          })}
                          label={<FormattedMessage id="jock console > font" />}
                          value={values.font}
                          onChange={(selectedOption) => {
                            formProps.setFieldValue("font", selectedOption);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Dropdown
                          name="font_case"
                          options={textCases}
                          placeholder={this.props.intl.formatMessage({
                            id: "jock console > select",
                          })}
                          label={<FormattedMessage id="jock console > case" />}
                          value={values.font_case}
                          onChange={(selectedOption) => {
                            formProps.setFieldValue(
                              "font_case",
                              selectedOption
                            );
                          }}
                          error={
                            touched.font_case &&
                            errors.font_case &&
                            errors.font_case.validation_error
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} className="setupTextStyle">
                      <FormGroup>
                        <label>
                          <FormattedMessage id="jock console > size" />
                        </label>
                      </FormGroup>
                      <SettingFontSize
                        value={settingFontSize}
                        onChange={(value) => {
                          formProps.setFieldValue("font_size", value + 16);
                        }}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <label>
                      <FormattedMessage id="jock console > preview" />
                    </label>
                    <div
                      className={`dynamic-text font-${fontValue} fs-${values.font_size} case-${caseValue}`}
                    >
                      Here’s a preview of {values.font_size}px{" "}
                      {fontLabel.toLowerCase()} text displayed in{" "}
                      {caseLabel.toLowerCase()}
                      {caseLabel.toLowerCase() === "sentence" ? " case" : ""}.
                    </div>
                  </FormGroup>
                </Col>
              </Row>

              <div className={cx(bem.e("title"), "mt-4")}>
                <FormattedMessage id="jock console > config widgets" />
              </div>
              <div className={bem.e("hr")} />
              <div className={bem.e("subtitle")}>
                <FormattedMessage id="jock console > config widgets desc" />
              </div>
              <SortableList
                axis="xy"
                updateBeforeSortStart={this.onSortStart}
                isActiveDragging={isActiveDragging}
                isMultiDragging={isMultiDragging}
                onSortEnd={this.onSortEnd(formProps)}
                mutiViewWidgets={mutiViewWidgets}
                sidebarWidgets={sidebarWidgets}
                unusedWidgets={unusedWidgets}
                widgets={widgets}
                formProps={formProps}
              />
              <div className={cx(bem.e("hr"), "padded")} />
              <FormGroup>
                <Button
                  color="blue"
                  type="submit"
                  className="btn btn-radius"
                  disabled={isLoading}
                >
                  <FormattedMessage
                    id={`jock console > settings ${
                      !isLoading ? "submit" : "submitting"
                    }`}
                  />
                </Button>
                {` `}
                {this.props.backButton}
              </FormGroup>
            </Form>
          );
        }}
      </Formik>
    );
  };

  render() {
    const { isLoading } = this.props;
    return (
      <Card className={bem.b()}>
        <CardBody>{this.renderForm()}</CardBody>
        <Spinner isLoading={isLoading} />
      </Card>
    );
  }
}

DisplaySettingsForm.defaultProps = {
  backButton: null,
  onBack: () => {},
};

DisplaySettingsForm.propTypes = {
  backButton: PropTypes.any,
  onBack: PropTypes.func.isRequired,
};

export default DisplaySettingsForm;
