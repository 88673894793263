import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import bn from "utils/bemnames";
import classNames from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import { get, uniqBy, includes } from "lodash";
import { ArrowRightIcon } from "components/CustomIcons";
import ChatInput from "components/ChatInput";
import Message from "./MessageItem";
import Avatar from "components/Avatar";
import BoxConversation from "./BoxConversation";
import Spinner from "components/Spinner";

import { getSortUserName } from "utils/helpers";
const HEADER_HEIGHT = 95;
const bem = bn.create("comments-panel");
function CommentsPanel(props) {
  const contentHeight = useRef(0);
  const {
    onCancel,
    onMarkAsSeenMessages,
    isOpen,
    intl,
    user,
    onSubmit,
    processKey,
    templateKey,
    conversationData,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const chatInputRef = useRef();
  useEffect(() => {
    const sidebar = document.getElementById("comments-panel");
    contentHeight.current = sidebar.offsetHeight - HEADER_HEIGHT;
    const body = document.querySelector("body");
    const appEle = document.getElementById("cr-app-main");
    if (isOpen) {
      body.classList.add("body-right-sidebar-open");
      appEle.classList.add("scroll-bar-style");
    } else {
      body.classList.remove("body-right-sidebar-open");
      appEle.classList.remove("scroll-bar-style");
    }
    return () => {
      body.classList.remove("body-right-sidebar-open");
      appEle.classList.remove("scroll-bar-style");
    };
  }, [isOpen]);
  useEffect(() => {
    if (!processKey || !isOpen) return;
    setIsLoading(true);
    props.getMessagesByProcess(processKey, () => {
      setIsLoading(false);
    });
  }, [processKey, isOpen]);
  const handleOnRef = useCallback((callback) => {
    chatInputRef.current = callback;
  });

  const allMessages = useMemo(() => {
    if (!processKey) return [];
    const process_key = processKey || "-1";
    const messagesToShow = props.messages
      .filter((item) => {
        const message = get(item, "message");
        const message_process_key = message.process_key;
        const isChat = message.m_type === "chat";
        if (
          // message.chat &&
          isChat &&
          ((message_process_key && message_process_key === process_key) ||
            message_process_key === "current")
        ) {
          return true;
        }
        return false;
      })
      .map((item) => {
        const message = get(item, "message");
        const isSender = get(message, "from.id", "") === user.id;

        let messagePosition;

        messagePosition = isSender ? "right" : "left";

        const data = {
          id: item.m_id,
          text: message.chat,
          data: message,
          from: message.from,
          timestamp: message.created_ts * 1000,
          status: message.status || "delivered",
          position: messagePosition,
          highlight: includes(message.tagged, Number(user.id)),
          attachments: message.attachments || [],
        };
        return data;
      });
    return messagesToShow;
  }, [processKey, props.messages, isOpen]);
  const conversationUsers = useMemo(() => {
    if (!processKey) return [];
    const process_key = processKey || "-1";
    const messagesTagged = get(props, "messages", []).filter((item) => {
      const message = get(item, "message");
      const message_process_key = message.process_key;
      const isMatchCurrentProcess =
        (message_process_key && message_process_key === process_key) ||
        message_process_key === "current";
      return (
        isMatchCurrentProcess &&
        includes(item.message.tagged, Number(user.id)) &&
        item.message.from
      );
    });
    return uniqBy(messagesTagged, "message.from.id").map((item) =>
      get(item, "message.from")
    );
  }, [processKey, props.messages, isOpen]);
  const onSelectUser = (user) => {
    if (!chatInputRef.current) return;
    const inputValue = chatInputRef.current.props.inputValue;
    const mentions = [
      ...get(inputValue, "mentions", []),
      {
        id: user.id,
        display: user.name,
      },
    ];
    chatInputRef.current.props.setInputValue({
      attachments: get(inputValue, "attachments", []),
      mentions: mentions,
      plainValue: get(inputValue, "plainValue", "")
        ? `${inputValue.plainValue}${user.name} `
        : `${user.name} `,
      value: get(inputValue, "value", "")
        ? `${inputValue.value}@[${user.name}](${user.id}) `
        : `@[${user.name}](${user.id}) `,
    });
    chatInputRef.current.inputRef.focus();
  };
  return (
    <OutsideClickHandler onOutsideClick={() => {}}>
      <div className={bem.b()}>
        <div
          className={classNames(bem.e("wrapper"), {
            [bem.e("open")]: isOpen,
            [bem.e("close")]: !isOpen,
            [bem.e("wrapper-comments")]: processKey,
          })}
          id="comments-panel"
        >
          <div className={bem.e("header")}>
            <div
              className={bem.e("btn-close")}
              onClick={() => {
                onCancel();
                onMarkAsSeenMessages();
              }}
            >
              <ArrowRightIcon color="#8165F3" />
            </div>
            <div className={bem.e("members")}>
              {conversationUsers.map((u) => {
                return (
                  <div className={bem.e("member-item")} key={u.id}>
                    {u.avatar_url ? (
                      <Avatar
                        src={u.avatar_url}
                        size={36}
                        alt={get(u, "name")}
                      />
                    ) : (
                      <div className={bem.e("message-avatar-text")}>
                        {getSortUserName(get(u, "name", ""))}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <BoxConversation
              conversationData={conversationData}
              onSelectUser={onSelectUser}
              bem={bem}
            />
          </div>
          <div className={classNames(bem.e("section-content"))}>
            <div
              className={classNames(
                bem.e("section-messages"),
                "scroll-bar-style"
              )}
              style={{
                height: contentHeight.current,
              }}
            >
              <div className={bem.e("chat-input")}>
                <ChatInput
                  onSubmit={onSubmit}
                  submitOnEnter={true}
                  mentionPosition={"top"}
                  chatInputPlaceHolder={intl.formatMessage({
                    id: "process > enter your comment...",
                  })}
                  showEmojiPicker={true}
                  clearInput={false}
                  isFilterUsers={true}
                  template={templateKey}
                  onRef={handleOnRef}
                />
              </div>
              <div className={bem.e("messages-list")}>
                {allMessages.map((message) => (
                  <Message bem={bem} message={message} key={message.id} />
                ))}
              </div>
            </div>
          </div>
        </div>
        <Spinner isLoading={isLoading} />
      </div>
    </OutsideClickHandler>
  );
}
CommentsPanel.defaultProps = {
  isOpen: false,
  onCancel: () => {},
  processKey: "",
  templateKey: "",
  messages: [],
};
CommentsPanel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  processKey: PropTypes.string,
  templateKey: PropTypes.string.isRequired,
  messages: PropTypes.array,
};
export default CommentsPanel;
