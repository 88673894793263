import React from "react";
import { FormattedMessage } from "react-intl";
import bn from "utils/bemnames";
import classnames from "classnames";
import { Button } from "reactstrap";
import moment from "moment";
import ChangePasswordModal from "components/ChangePasswordModal";
const bem = bn.create("profile");
export default function View(props) {
  const {
    isView,
    isLoading,
    isOpenChangePasswordModal,
    setIsOpenChangePasswordModal,
    user,
  } = props;
  if (isView) {
    const passwordLastChanged = user.password_updated_at ? moment(user.password_updated_at)
    .format("MMMM DD, YYYY HH:ss")
    .toString() : '';
    return (
      <div className={bem.e("security-section")}>
        <h4 className={bem.e("section-title")}>
          <FormattedMessage id="my profile > title security" />
        </h4>
        <div className={bem.e("description-password-changed")}>
          <FormattedMessage id="my profile > password last changed" />:{" "}
          {passwordLastChanged}
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className={bem.e("security-section")}>
        <h4 className={bem.e("section-title")}>
          <FormattedMessage id="my profile > title security" />
        </h4>
        <div className={bem.e("password-buttons")}>
          <Button
            color="blue"
            type="button"
            disabled={isLoading}
            className={classnames(
              "btn-radius",
              bem.e("button-change-password")
            )}
            onClick={(e) => {
              setIsOpenChangePasswordModal(true);
            }}
          >
            <FormattedMessage id={"my profile > button change password"} />
          </Button>
          <ChangePasswordModal
            isOpen={isOpenChangePasswordModal}
            isAuthChangePassword={true}
            user={user}
            onToggle={() =>
              setIsOpenChangePasswordModal(!isOpenChangePasswordModal)
            }
          />
        </div>
      </div>
    </React.Fragment>
  );
}
