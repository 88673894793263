import ScheduleSettingModal from "./view";
import { injectIntl } from "react-intl";
import {
  getFillSettings,
  updateFillSettings,
} from "store/actions/programmings";
import { compose, lifecycle, withHandlers } from "recompose";
import connect from "react-redux/es/connect/connect";
import ToastManager from "components/ToastManager";
import { MdImportantDevices } from "react-icons/lib/md";
import * as React from "react";
import { get } from "lodash";
export default injectIntl(
  compose(
    connect(
      (state) => {
        return {
          fillSettings: get(state, "programmings.fillSettings.data", {}),
        };
      },
      {
        getFillSettings,
        updateFillSettings,
      }
    ),
    withHandlers({
      onFormSubmit: ({ updateFillSettings, getFillSettings,onToggle, intl }) => (values) => {
        onToggle();
        updateFillSettings({
          data: values,
          cb: () => {
            getFillSettings(values)
            ToastManager.show({
              title: <MdImportantDevices />,
              message: intl.formatMessage({
                id: "user > update successfully",
              }),
              level: "success",
            });
          },
        });
      },
    }),
    lifecycle({
      componentDidMount() {
        if (this.props.stationId)
          this.props.getFillSettings({
            station_id: this.props.stationId,
          });
      },
    })
  )(ScheduleSettingModal)
);
