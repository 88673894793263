import React from "react";
import { get } from "lodash";
import { FormattedMessage } from "react-intl";
import Player from "components/Player";
import { setScriptField } from "utils/helpers";
import UploadFile from "components/UploadFile";
import TextareaEvaluator from "components/TextareaEvaluator";
import bn from "utils/bemnames";
const bem = bn.create("process-dialog");

function ContentProductionNeeded(props) {
  const { process } = props;
  const spotInfo = get(process, "_source.spot_info", []).find(
    (spot) => spot.key === get(process, "_id")
  );
  const isBookend = get(spotInfo, "spot_type", "Standard") === "Book-ends";

  const recordingUrl = get(process, "_source.recording_path", false)
    ? get(process, "_source.recording_path", false)
    : get(spotInfo, "recording_path");
  const recordingAudit = get(process, "_source.recording_audit", "")
    ? get(process, "_source.recording_audit", false)
    : get(spotInfo, "recording_audit");
  const recordingUrlB = get(process, "_source.recording_path_b", false)
    ? get(process, "_source.recording_path_b", false)
    : get(spotInfo, "recording_path_b");
  const recordingBAudit = get(process, "_source.recording_path_b_audit", false)
    ? get(process, "_source.recording_path_b_audit", false)
    : get(spotInfo, "recording_path_b_audit");

  return (
    <React.Fragment>
      {isBookend ? (
        <React.Fragment>
          <div className={bem.e("container-timer")}>
            <TextareaEvaluator
              readOnly={true}
              isOrderLength={true}
              showScriptLength={true}
              text={setScriptField(get(spotInfo, "script", ""))}
              process={process}
              spot={spotInfo}
              onEvaluatorCallback={() => {}}
              title={<FormattedMessage id="process > field script part A" />}
              isBookend={isBookend}
              part="a"
              showInnerTitle={true}
            />
            <UploadFile
              actions={[]}
              positionActions="bottom"
              mode={"view"}
              showRemoveIcon={false}
              process={process}
              spot={spotInfo}
              url={recordingUrl}
              isShowFileNameInEditMode={true}
              isBookend={isBookend}
              path="a"
              auditInfo={recordingAudit}
            >
              <FormattedMessage id="process > button upload" />
            </UploadFile>
            {(recordingUrlB || get(spotInfo, "script_part_b", "")) && (
              <div className={bem.e("line")}></div>
            )}

            <TextareaEvaluator
              readOnly={true}
              isOrderLength={true}
              showScriptLength={true}
              text={setScriptField(get(spotInfo, "script_part_b", ""))}
              process={process}
              spot={spotInfo}
              onEvaluatorCallback={() => {}}
              title={<FormattedMessage id="process > field script part B" />}
              isBookend={isBookend}
              part="b"
              showInnerTitle={true}
            />
            {recordingUrlB && (
              <UploadFile
                actions={[]}
                positionActions="bottom"
                mode={"view"}
                showRemoveIcon={false}
                process={process}
                spot={spotInfo}
                url={recordingUrlB}
                isShowFileNameInEditMode={true}
                isBookend={isBookend}
                path="b"
                auditInfo={recordingBAudit}
              >
                <FormattedMessage id="process > button upload" />
              </UploadFile>
            )}
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className={bem.e("container-timer")}>
            <TextareaEvaluator
              readOnly={true}
              isOrderLength={true}
              text={get(spotInfo, "script")}
              process={process}
              onEvaluatorCallback={() => {}}
            />
          </div>
          {recordingUrl && (
            <div className={bem.e("player")}>
              <Player url={recordingUrl} />
            </div>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default ContentProductionNeeded;
