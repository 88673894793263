import * as React from "react";
import { compose, withHandlers, withState, lifecycle } from "recompose";
import { connect } from "react-redux";
import { MdImportantDevices } from "react-icons/lib/md";
import { omit, map, get, filter, pick, split, trim, toLower } from "lodash";
import ToastManager from "components/ToastManager";
import { updateProfile, setLanguage } from "store/actions/auth";
import MyProfile, { MODE_VIEW, TAB_PROFILE } from "./view";
import {
  TAG_TYPE_LOCATION,
  TAG_TYPE_EXCEPT_LOCATION_FORMAT,
} from "components/SelectTags";
import {
  getTagOptionsByType,
  getSelectedTagOptions,
  getSelectedMarketOptions,
} from "utils/helpers";
import { isUserShowStyleTags } from "utils/helpers";
import { injectIntl } from "react-intl";
import { getCurrentServer } from "store/actions/servers";
import { validatePrivileges } from "utils/helpers";
import { PRIVILEGES } from "utils/constants";
import { checkIsShowWorkflow } from "utils/helpers";
export default injectIntl(
  compose(
    connect(
      ({ auth, servers }) => {
        const locationOptions = getTagOptionsByType(TAG_TYPE_LOCATION, auth);
        const tagsOptions = getTagOptionsByType(
          TAG_TYPE_EXCEPT_LOCATION_FORMAT,
          auth
        );
        const roles_objects = get(auth, "user.roles", {});
        const userPrivileges = get(auth, "user.privileges");
        const authIsGodAdmin = get(auth, "user.is_god_admin", false);
        const limitAccess =
          get(userPrivileges, "length", 0) === 0 && !authIsGodAdmin;

        const roles = Object.keys(roles_objects).map((key) => {
          return { name: roles_objects[key], id: parseInt(key) };
        });

        let hasMultimarket = false;
        const server = get(servers, `currentServer.data`, {});
        const digitalMultimarket = get(server, "digital_multimarket", false);
        const productionMultimarket = get(
          server,
          "production_multimarket",
          false
        );
        const promotionMultimarket = get(
          server,
          "promotion_multimarket",
          false
        );

        if (
          digitalMultimarket ||
          productionMultimarket ||
          promotionMultimarket
        ) {
          hasMultimarket = true;
        }
        const roleStrings = split(auth.info.roles_string, ",").map((str) =>
          toLower(trim(str))
        );
        // For users with role Producer, VO, Writer, Continuity, Dubber show the setting in their profile (if they don't have AE role). Then if user has turned on keep me in order
        const isShowWorkflow = checkIsShowWorkflow(roleStrings);
        return {
          auth,
          locationOptions,
          tagsOptions,
          isSpecialUser: isUserShowStyleTags(auth.user),
          roles,
          limitAccess,
          hasMultimarket,
          dateFormatByServer: get(
            servers,
            "currentServer.data.date_format",
            "MM/DD/YYYY"
          ),
          isShowWorkflow,
        };
      },
      {
        updateProfile,
        setLanguage,
        getCurrentServer,
      }
    ),
    withState("shouldBlockNavigation", "setShouldBlockNavigation", false),
    withState("currentMode", "setCurrentMode", MODE_VIEW),
    withState("isLoading", "setIsLoading", false),
    withState("activeTab", "setActiveTab", TAB_PROFILE),
    withState(
      "isOpenChangePasswordModal",
      "setIsOpenChangePasswordModal",
      false
    ),
    withState("isChangedLanguage", "setIsChangedLanguage", false),
    withState("isOpenEditAvatarModal", "setIsOpenEditAvatarModal", false),
    withState(
      "islimitAccessModalOpen",
      "setIslimitAccessModalOpen",
      (props) => props.limitAccess
    ),
    withState("selectedMarketOptions", "setSelectedMarketOptions", (props) =>
      getSelectedMarketOptions(props.locationOptions, props.auth.user)
    ),
    withState("selectedTagOptions", "setSelectedTagsOptions", (props) =>
      getSelectedTagOptions(props.tagsOptions, props.auth.user)
    ),
    withHandlers({
      onToggleOpenChangePasswordModal: ({
        setIsOpenChangePasswordModal,
        isOpenChangePasswordModal,
      }) => () => {
        setIsOpenChangePasswordModal(!isOpenChangePasswordModal);
      },
      onToggleOpenEditAvatarModal: ({
        setIsOpenEditAvatarModal,
        isOpenEditAvatarModal,
      }) => () => {
        setIsOpenEditAvatarModal(!isOpenEditAvatarModal);
      },
      onUploadAvatar: ({
        updateProfile,
        auth,
        setIsLoading,
        intl,
        setIsOpenEditAvatarModal,
      }) => (image) => {
        const { user } = auth;
        setIsLoading(true);
        setIsOpenEditAvatarModal(false);
        const initialValues = {
          avatar: image.path,
          email: user.email || "",
          first_name: user.first_name || "",
          last_name: user.last_name || "",
        };
        updateProfile(initialValues, (status) => {
          setIsLoading(false);
          ToastManager.show({
            title: <MdImportantDevices />,
            message: intl.formatMessage({
              id: `my profile > ${
                status ? "updated successfully" : "updated error"
              }`,
            }),
            autoDismiss: 1.5,
            level: status ? "success" : "error",
          });
        });
      },
      onFormSubmit: ({
        updateProfile,
        setIsLoading,
        setIsChangedLanguage,
        intl,
        setCurrentMode,
      }) => async (data) => {
        setIsLoading(true);
        updateProfile(
          {
            ...omit(data, [
              "password",
              "markets",
              "tags",
              "language",
              "out_of_office_settings",
            ]),
            reel: get(data, "reel"),
            markets: map(get(data, "markets", []), (item) => item.value),
            tags: map(get(data, "tags", []), (item) => item.value),
            tags_string: map(
              get(data, "tags", []),
              (item) => item.data.title
            ).join("#"),
            language: get(data, "language.value", "en-US"),
            out_of_office_settings: get(
              data,
              "out_of_office_settings",
              []
            ).filter((i) => i.from_date && i.to_date),
          },
          (status) => {
            setIsLoading(false);
            setCurrentMode(MODE_VIEW);
            ToastManager.show({
              title: <MdImportantDevices />,
              message: intl.formatMessage({
                id: `my profile > ${
                  status ? "updated successfully" : "updated error"
                }`,
              }),
              autoDismiss: 1.5,
              level: status ? "success" : "error",
            });
            if (status) {
              setIsChangedLanguage(true);
            }
          }
        );
      },
      changePassword: ({
        setIsLoading,
        updateProfile,
        intl,
        setCurrentMode,
      }) => async (data) => {
        setIsLoading(true);
        updateProfile(
          {
            ...omit(data, [
              "avatar",
              "name",
              "language",
              "gender",
              "summary",
              "age",
              "gender",
              "reel",
              "phone",
              "location",
              "markets",
              "tags",
            ]),
          },
          (status) => {
            setIsLoading(false);
            setCurrentMode(MODE_VIEW);
            ToastManager.show({
              title: <MdImportantDevices />,
              message: intl.formatMessage({
                id: `my profile > ${
                  status ? "updated successfully" : "updated error"
                }`,
              }),
              autoDismiss: 1.5,
              level: status ? "success" : "error",
            });
          }
        );
      },
      getUsersForGrantAccess: ({ auth }) => () => {
        const users = get(auth, "info.users");
        const items = filter(users, (user) => {
          const { stations } = user;
          const stationsWithProductionPrivilege = filter(
            stations,
            (station) => {
              const userPrivileges = Object.values(station.user_privileges);
              const isValid = validatePrivileges({
                requires: {
                  or: [
                    PRIVILEGES.ADD_PRODUCTION,
                    PRIVILEGES.EDIT_ALL_PRODUCTION_ORDERS,
                  ],
                },
                privileges: userPrivileges,
                user,
              });
              return isValid;
            }
          );
          return (
            stationsWithProductionPrivilege.length > 0 &&
            user.id !== auth.user.id &&
            Boolean(user.is_active)
          );
        });
        return items.map((user) => ({
          label: user.name,
          value: user.id,
          data: pick(user, ["id", "name", "avatar_url"]),
        }));
      },
    }),
    lifecycle({
      componentDidUpdate(prevProps) {
        if (
          get(this.props, "auth.user.tags") !== get(prevProps, "auth.user.tags")
        ) {
          this.props.setSelectedMarketOptions(
            getSelectedMarketOptions(
              this.props.locationOptions,
              this.props.auth.user
            )
          );
          this.props.setSelectedTagsOptions(
            getSelectedTagOptions(this.props.tagsOptions, this.props.auth.user)
          );
        }
      },
      componentWillUnmount() {
        if (!this.props.isChangedLanguage) {
          const userLangCode = get(this.props, "auth.user.lang", null);
          this.props.setLanguage(userLangCode);
        }
      },
    })
  )(MyProfile)
);
