import React, { useEffect, useMemo, useState } from "react";
import bn from "utils/bemnames";
import { FormattedMessage } from "react-intl";
import { Button } from "reactstrap";
import classnames from "classnames";
import Dropdown from "components/Dropdown";
import PropTypes from "prop-types";
import classNames from "classnames";
import TextInput from "components/TextInput";
import { filterAccountsOptions } from "utils/config";
import { getRoleIdByName } from "utils/helpers";
import { filter, map } from "lodash";

const bem = bn.create("processes-filter-view");
const HEADER_HEIGHT = 95;
const FOOTER_HEIGHT = 92;
function ContentFilterView(props) {
  const { initialValues, intl, onSubmit, users } = props;
  const [contentHeight, setContentHeight] = useState(0);

  const [values, setValues] = useState({
    accounts: "all",
    account_executive: "",
    search: "",
  });
  useEffect(() => {
    setValues(initialValues);
  }, [initialValues]);

  useEffect(() => {
    const sidebar = document.getElementById("right-sidebar");
    if (sidebar) {
      setContentHeight(sidebar.offsetHeight - HEADER_HEIGHT - FOOTER_HEIGHT);
    }
  }, []);

  const aeOptions = useMemo(() => {
    const aeUsers = filter(users, (user) =>
      getRoleIdByName("Account Executive", user.roles)
    );
    return map(aeUsers, (user) => ({
      value: user.id,
      label: user.name,
    }));
  }, [users]);
  return (
    <div className={bem.b()}>
      <div
        className={classnames(bem.e("filter-view-content"), "scroll-bar-style")}
        style={{
          height: contentHeight,
        }}
      >
        <div>
          <Dropdown
            label={<FormattedMessage id="process > accounts" />}
            placeholder={intl.formatMessage({
              id: "process > please select",
            })}
            containerClass={bem.e("dropdown")}
            name="accounts"
            onChange={(selectedOption) => {
              setValues({
                ...values,
                accounts: selectedOption ? selectedOption.value : "",
              });
            }}
            value={
              filterAccountsOptions.find(
                (item) => item.value === values.accounts
              ) || ""
            }
            options={filterAccountsOptions}
          />
          <Dropdown
            label={<FormattedMessage id="process > account executive" />}
            containerClass={bem.e("dropdown")}
            placeholder={intl.formatMessage({
              id: "process > please select",
            })}
            name="account_executive"
            onChange={(selectedOption) => {
              setValues({
                ...values,
                account_executive: selectedOption ? selectedOption.value : "",
              });
            }}
            value={
              aeOptions.find(
                (item) => item.value === values.account_executive
              ) || ""
            }
            options={aeOptions}
          />
          <TextInput
            label={<FormattedMessage id="process > keyword" />}
            type="text"
            name="search"
            placeholder={intl.formatMessage({
              id: "process > enter client or title",
            })}
            value={values.search}
            onChange={({ target }) => {
              setValues({
                ...values,
                search: target.value || "",
              });
            }}
          />
        </div>
      </div>
      <div className={bem.e("production-buttons")}>
        <Button
          color="blue"
          className={classNames("btn-radius", bem.e("btn-apply"))}
          onClick={() => {
            onSubmit(values);
          }}
        >
          <FormattedMessage id="process > apply" />
        </Button>
        <Button
          color="link"
          className={classNames("btn-radius", bem.e("btn-clear"))}
          onClick={() => {
            setValues({
              accounts: "all",
              account_executive: "",
              search: "",
            });
          }}
        >
          <FormattedMessage id="process > clear" />
        </Button>
      </div>
    </div>
  );
}
ContentFilterView.defaultProps = {
  initialValues: {
    accounts: "all",
    account_executive: "",
    search: "",
  },
};
ContentFilterView.propTypes = {
  initialValues: PropTypes.object,
};

export default ContentFilterView;
