import * as TypesMessages from "store/types/messages";
import { LOGOUT_USER } from "store/types/auth";
import { find, get, includes, orderBy, uniqBy } from "lodash";

const initialState = {
  messages: [],
  notifications: {
    data: null,
    loading: false,
    error: null,
  },
  editMessage: {
    loading: false,
    error: null,
  },
  deleteMessage: {
    loading: false,
    error: null,
  },
  messagesData: {
    loading: false,
    error: null,
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case TypesMessages.SET_MESSAGES:
      let isDirty = get(action, "payload.is_dirty", false);
      const currentMessages = state.messages;
      let allMessages = [];
      if (isDirty) {
        allMessages = action.payload.messages;
      } else {
        allMessages = uniqBy(
          [...action.payload.messages, ...currentMessages],
          "m_id"
        );
      }
      // allMessages = allMessages.sort((b, a) =>
      //   a.message.created_ts > b.message.created_ts
      //     ? 1
      //     : b.message.created_ts > a.message.created_ts
      //     ? -1
      //     : 0
      // );
      if (
        action.payload.messages.length === 1 &&
        action.payload.messages[0].message.process_key === "current"
      ) {
        // own chat message
      } else {
        allMessages = allMessages.filter(
          (m) => m.message.process_key !== "current"
        );
      }
      return {
        ...state,
        messages: orderBy(allMessages, "message.created_ts", "desc"),
      };
    case TypesMessages.REPLACE_MESSAGES:
      let replaceMessages = state.messages;
      if (action.payload.messages.length) {
        replaceMessages = replaceMessages.map((message) => {
          const newMessage = action.payload.messages.find(
            (newItem) => message.m_id === newItem.m_id
          );
          if (newMessage) return newMessage;
          return message;
        });
      }

      return {
        ...state,
        messages: replaceMessages,
      };
    case TypesMessages.FETCH_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          loading: true,
        },
      };
    case TypesMessages.FETCH_NOTIFICATIONS_SUCCESS:
      let notificationsData = action.payload;
      const isMore = get(action, "payload.page") > 1;
      if (isMore) {
        notificationsData.items = [
          ...get(state, "notifications.data.items"),
          ...get(action, "payload.items", []),
        ];
      }
      return {
        ...state,
        notifications: {
          data: notificationsData,
          loading: false,
          error: null,
        },
      };
    case TypesMessages.FETCH_NOTIFICATIONS_ERROR: {
      return {
        ...state,
        notifications: {
          ...state.notifications,
          loading: false,
          error: action.payload,
        },
      };
    }
    case TypesMessages.EDIT_MESSAGE_REQUEST:
      return {
        ...state,
        editMessage: {
          loading: true,
          error: null,
        },
      };
    case TypesMessages.EDIT_MESSAGE_SUCCESS:
      const editMessages = get(state, "messages", []).map((item) => {
        if (item.m_id === action.payload.id) {
          return {
            ...item,
            message: {
              ...item.message,
              chat: action.payload.message,
            },
          };
        }
        return item;
      });
      return {
        ...state,
        messages: editMessages,
        editMessage: {
          loading: false,
          error: null,
        },
      };
    case TypesMessages.EDIT_MESSAGE_ERROR: {
      return {
        ...state,
        editMessage: {
          loading: false,
          error: action.payload,
        },
      };
    }
    case TypesMessages.DELETE_MESSAGE_REQUEST:
      return {
        ...state,
        deleteMessage: {
          loading: true,
          error: null,
        },
      };
    case TypesMessages.DELETE_MESSAGE_SUCCESS:
      const deleteMessages = get(state, "messages", []).filter(
        (item) => item.m_id !== action.payload.id
      );
      return {
        ...state,
        messages: deleteMessages,
        deleteMessage: {
          loading: false,
          error: null,
        },
      };
    case TypesMessages.DELETE_MESSAGE_ERROR: {
      return {
        ...state,
        deleteMessage: {
          loading: false,
          error: action.payload,
        },
      };
    }
    case TypesMessages.DELETE_MESSAGES_REQUEST:
      return {
        ...state,
        deleteMessages: {
          loading: true,
          error: null,
        },
      };
    case TypesMessages.DELETE_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: get(state, "messages", []).filter(
          (item) => !includes(action.payload.ids, item.m_id)
        ),
        deleteMessages: {
          loading: false,
          error: null,
        },
      };
    case TypesMessages.DELETE_MESSAGES_ERROR: {
      return {
        ...state,
        deleteMessages: {
          loading: false,
          error: action.payload,
        },
      };
    }
    case TypesMessages.UPDATE_MESSAGES_INCOMING:
      const messagesIncomingUpdated = get(state, "messages", []).map((item) => {
        const messageData = find(
          action.payload,
          (message) => message.m_id === item.m_id
        );
        if (messageData) return messageData;
        return item;
      });
      return {
        ...state,
        messages: orderBy(
          messagesIncomingUpdated,
          "message.created_ts",
          "desc"
        ),
      };
    case TypesMessages.GET_MESSAGES_BY_PROCESS_REQUEST:
      return {
        ...state,
        messagesData: {
          loading: true,
          error: null,
        },
      };
    case TypesMessages.GET_MESSAGES_BY_PROCESS_SUCCESS:
      return {
        ...state,
        messages: orderBy(action.payload, "message.created_ts", "desc"),
        messagesData: {
          loading: false,
          error: null,
        },
      };
    case TypesMessages.GET_MESSAGES_BY_PROCESS_ERROR:
      return {
        ...state,
        messagesData: {
          loading: false,
          error: action.payload,
        },
      };
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
}
