import AdminImport from "./view";
import {get} from "lodash";
import { connect } from "react-redux";
import { compose, lifecycle, withHandlers } from "recompose";
import { getServers } from "store/actions/servers";
import { importRecord, purgeRecords, deleteAllIndexes,deleteIndex } from "store/actions/imports";
import { injectIntl } from "react-intl";
export default injectIntl(compose(
  connect(
    (state, props) => {
      return {
        auth: get(state, "auth"),
        servers: get(state, "servers.servers"),
        import_loading:get(state, "imports.import.loading"),
        purge_loading:get(state, "imports.purge.loading"),
        index_loading:get(state, "imports.indexes.loading"),
        single_loading:get(state, "imports.index.loading"),
      };
    },
    {
      getServers,
      importRecord,
      purgeRecords,
      deleteAllIndexes,
      deleteIndex
    }
  ),
  withHandlers({
    onFormSubmit: (data) => values => {
      let dataToSend = new FormData();
      for ( var key in values ) {
          dataToSend.append(key, values[key]);
      }
      data.importRecord(dataToSend)
    }
  }),
  lifecycle({
    componentDidMount() {
      let {auth} = this.props
      if (auth.user.id === 1) {
        this.props.getServers();
      }else{
        this.props.history.push('/');
      }
    },
  })
)(AdminImport));
