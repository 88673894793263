import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import bn from "utils/bemnames";
import { FormattedMessage } from "react-intl";
import { Formik } from "formik";
import TextInput from "components/TextInput";
import CheckboxDefault from "components/Checkbox";
import { Form, FormGroup, Button, Label } from "reactstrap";
import * as Yup from "yup";
import { findIndex, find, get, filter } from "lodash";
import Dropdown from "components/Dropdown";
const bem = bn.create("rule-modal");

const RuleModal = ({
  isOpen,
  condition,
  onToggle,
  formFields,
  intl,
  onSubmit,
}) => {
  const initialValues = {
    if: get(condition, "value", ""),
    is: get(condition, "rule.is", "selected"),
    options: get(condition, "rule.options", []),
  };
  const isOptions = [
    {
      value: "selected",
      label: intl.formatMessage({ id: "rule > selected" }),
    },
    {
      value: "not selected",
      label: intl.formatMessage({
        id: "rule > not selected",
      }),
    },
  ];
  const options = formFields.map((item) => ({
    ...item,
    value: item.uuid,
  }));
  return (
    <>
      <Modal isOpen={isOpen} className={bem.b()} size="lg">
        <ModalHeader toggle={onToggle} className={bem.e("header")}>
          <div className={bem.e("title")}>
            <FormattedMessage
              id={
                condition.rule
                  ? `rule > edit a rule title`
                  : `rule > add a rule title`
              }
            />
          </div>
        </ModalHeader>
        <ModalBody className={bem.e("body")}>
          <div className={bem.e("content")}>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={Yup.object().shape({
                if: Yup.string(),
                is: Yup.string(),
                options: Yup.array(),
              })}
              onSubmit={(value) => {
                onSubmit({
                  ...condition,
                  rule: {
                    ...value,
                    options: value.options.map((option) => ({
                      ...option,
                      is_controlled: true,
                    })),
                  },
                });
              }}
            >
              {(formProps) => {
                const errors = formProps.errors;
                const touched = formProps.touched;
                const values = formProps.values;
                const isOptionsEmpty = options && options.length === 0;
                return (
                  <Form onSubmit={formProps.handleSubmit}>
                    <FormGroup className={bem.e("group-input")}>
                      <Label className={bem.e("label-group")}>
                        <FormattedMessage id="rule > if" />
                      </Label>
                      <TextInput
                        label={null}
                        type="text"
                        name="if"
                        placeholder={intl.formatMessage({
                          id: "rule > if placeholder",
                        })}
                        value={values.if}
                        error={touched.if && errors.if}
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                        disabled
                      />
                    </FormGroup>
                    <FormGroup className={bem.e("group-input")}>
                      <Label className={bem.e("label-group")}>
                        <FormattedMessage id="rule > is" />
                      </Label>
                      <Dropdown
                        label={null}
                        placeholder={intl.formatMessage({
                          id: "rule > is",
                        })}
                        name="is"
                        isMulti={false}
                        allowSelectAll={false}
                        onChange={(selectedOption) => {
                          formProps.setFieldValue("is", selectedOption.value);
                        }}
                        value={find(
                          isOptions,
                          (item) => item.value === values.is
                        )}
                        options={isOptions}
                        closeMenuOnSelect={true}
                      />
                      <Label className={bem.e("then-show")}>
                        <FormattedMessage id="rule > then show" />
                      </Label>
                    </FormGroup>
                    <FormGroup>
                      <Label className={bem.e("subtitle")}>
                        <FormattedMessage
                          id={
                            isOptionsEmpty
                              ? "rule > select empty available fields to show"
                              : "rule > select one or more available fields to show"
                          }
                        />
                      </Label>
                      {!isOptionsEmpty ? (
                        <div className={bem.e("list-options")}>
                          {options.map((option, index) => {
                            const checkedIndex = findIndex(
                              values.options,
                              (item) => item.uuid === option.value
                            );
                            return (
                              <CheckboxDefault
                                text={option.label}
                                key={index}
                                checked={checkedIndex !== -1}
                                onChange={(checked) => {
                                  if (checked) {
                                    const options = [...values.options, option];
                                    formProps.setFieldValue("options", options);
                                  } else {
                                    const options = filter(
                                      values.options,
                                      (item) => item.uuid !== option.value
                                    );
                                    formProps.setFieldValue("options", options);
                                  }
                                }}
                              />
                            );
                          })}
                        </div>
                      ) : null}
                    </FormGroup>
                    <FormGroup>
                      <div className={bem.e("buttons")}>
                        <Button
                          color="blue"
                          className="btn btn-radius"
                          disabled={isOptionsEmpty}
                          onClick={() => formProps.submitForm()}
                        >
                          <FormattedMessage id={"rule > button submit"} />
                        </Button>
                        <Button
                          color="blue"
                          outline
                          className="btn btn-radius"
                          onClick={onToggle}
                        >
                          <FormattedMessage id={`rule > button cancel`} />
                        </Button>
                        {condition.rule && (
                          <Button
                            color="blue"
                            outline
                            className="btn btn-radius"
                            onClick={() =>
                              onSubmit({
                                ...condition,
                                rule: null,
                              })
                            }
                          >
                            <FormattedMessage id={`rule > button delete`} />
                          </Button>
                        )}
                      </div>
                    </FormGroup>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

RuleModal.propTypes = {
  onToggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  formFields: PropTypes.array,
};
RuleModal.defaultProps = {
  formFields: [],
  isOpen: false,
  onToggle: () => {},
};
export default RuleModal;
