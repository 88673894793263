import React, { useState } from "react";
import bn from "utils/bemnames";
import { default as DropdownSelect } from "components/Dropdown";
import TextInput from "components/TextInput";
import { Button, FormGroup, Row, Col, Label } from "reactstrap";
import { Formik } from "formik";
import { get, filter } from "lodash";
import ToggleSwitch from "components/ToggleSwitch";
import classnames from "classnames";
import { FaPlus, FaClose } from "react-icons/lib/fa";
import { MdClose } from "react-icons/lib/md";
import { MenuIcon } from "components/CustomIcons";
import * as Yup from "yup";
import { Asterisk } from "components/Elements";
import { FormattedMessage } from "react-intl";
import { labelToNameField } from "utils/helpers";
const bem = bn.create("form-builder-fields");
const bemGeo = bn.create("geo-targeting");

const GeoTargeting = ({
  intl,
  field,
  isView,
  onChange,
  allowDelete,
  value,
  errors,
  onDelete,
  touched,
  isFormBuilder,
  isNew,
  highlightFields,
  disabled,
  isShowOffText,
}) => {
  const [data, setData] = useState(value || []);
  const onSubmit = (values, { resetForm, setSubmitting }) => {
    const { type, location, radius } = values;
    let array = [...data];
    array.push({ type: type.value, location, radius });
    setData(array);
    onChange(array);
    resetForm();
    setSubmitting(false);
  };

  if (isFormBuilder) {
    if (isNew) {
      const isHighlightFormField =
        filter(
          highlightFields,
          (highlightFieldItem) =>
            highlightFieldItem.uuid === value.uuid &&
            highlightFieldItem.count_highlight > 0
        ).length > 0;
      return (
        <Row>
          <Col className="form-builder-field">
            <div className={classnames(bem.b(), bemGeo.b())}>
              <div
                className={classnames(
                  bem.e("form-field-group"),
                  bem.e("form-field-group-line"),
                  {
                    [bem.e("form-field-group-highlight")]: isHighlightFormField,
                    [bem.e("form-field-group-disable")]:
                      highlightFields.length > 0 &&
                      !isHighlightFormField &&
                      value.is_controlled,
                  }
                )}
              >
                <Row>
                  <Col xs={9}>
                    <div className={bem.e("form-field-group-head")}>
                      <div
                        className={bem.e("form-field-group-head-single-left")}
                      >
                        <span className={bem.e("menu-icon")}>
                          <MenuIcon />
                        </span>
                        <div className={bem.e("input-label")}>
                          <TextInput
                            label={null}
                            name="label"
                            type="text"
                            placeholder={intl.formatMessage({
                              id: "form builder > label geotargeting",
                            })}
                            disabled={disabled}
                            value={value.label}
                            onChange={({ target }) => {
                              onChange({
                                ...value,
                                label: target.value,
                              });
                            }}
                            error={get(errors, "label", "")}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xs={3}>
                    <ToggleSwitch
                      leftComponent={
                        <span
                          className={classnames({
                            [bem.e("switch-active")]: !get(
                              value,
                              "is_required",
                              false
                            ),
                          })}
                        >
                          {isShowOffText
                            ? intl.formatMessage({
                                id: "form builder > off",
                              })
                            : ""}
                        </span>
                      }
                      rightComponent={
                        <span
                          className={classnames({
                            [bem.e("switch-active")]: get(
                              value,
                              "is_required",
                              false
                            ),
                          })}
                        >
                          {intl.formatMessage({
                            id: "form builder > required",
                          })}
                        </span>
                      }
                      name="is_required"
                      switchProps={{
                        checked: !get(value, "is_required", false),
                        onChange: (checked) => {
                          onChange({
                            ...value,
                            is_required: !checked,
                          });
                        },
                        disabled: disabled,
                        offColor: "#795AFA",
                        onColor: "#E3ECF1",
                        uncheckedIcon: false,
                        checkedIcon: false,
                        handleDiameter: 22,
                        width: 50,
                        height: 26,
                      }}
                    />
                  </Col>
                </Row>
                {allowDelete && (
                  <div
                    className={classnames(
                      bem.e("form-field-group-head-right"),
                      bem.e("form-field-group-delete-icon")
                    )}
                    onClick={onDelete}
                  >
                    <FaClose size="23" color="#C2D4E0" />
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      );
    } else {
      return value && value.data && value.data.length
        ? value.data.map((item, index) => (
            <div className="full_wrap">
              {index === 0 ? (
                <div className="targets">
                  {intl.formatMessage({
                    id: "form builder > targets geotargeting",
                  })}
                </div>
              ) : null}
              <div className="FlexRow">
                <div className="location">{item.type}</div>
                <div className="notification_text">{item.location}</div>
                <div className="location">
                  {item.radius ? `${item.radius} mi` : "---"}
                </div>
                <button className="cross" disabled>
                  <MdClose />
                </button>
              </div>
            </div>
          ))
        : null;
    }
    // ui for form
  } else {
    const fieldName = labelToNameField(get(field, "label", ""));
    if (isView) {
      return (
        <Row>
          <Col xs={12}>
            <div className={classnames(bem.b(), bemGeo.b())}>
              <div className={bem.e("form-field-group-saved")}>
                <Label className={bem.e("form-field-label")}>
                  <span>{get(field, "label", "")} </span>
                </Label>
                <Row>
                  <Col xs={12}>
                    {Array.isArray(value) && value.length ? (
                      value.map((item, index) => (
                        <div className={"full_wrap"} key={"item_" + index}>
                          <div className="FlexRow">
                            <div className="location">{item.type}</div>
                            <div className="notification_text">
                              {item.location}
                            </div>
                            <div className="location">
                              {item.radius ? `${item.radius} mi` : "---"}
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <FormattedMessage id="form builder > none" />
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    return (
      <Row>
        <Col xs={12}>
          <div className={classnames(bem.b(), bemGeo.b())}>
            <div className={bem.e("form-field-group-saved")}>
              <Label className={bem.e("form-field-label")}>
                <span>
                  {get(field, "label", "")}{" "}
                  {get(field, "is_required", false) && <Asterisk>*</Asterisk>}
                </span>
              </Label>
              <Row>
                <Col xs={12}>
                  <div className={bem.e("geotargeting-form")}>
                    <p className="heading_text">
                      {intl.formatMessage({
                        id: "form builder > target geotargeting",
                      })}
                    </p>

                    <Formik
                      // enableReinitialize
                      initialValues={{
                        type: "",
                        location: "",
                        radius: "",
                      }}
                      validationSchema={Yup.object().shape({
                        type: Yup.string().required("Type is required"),
                        location: Yup.string().required("Location is required"),
                        radius: Yup.string(),
                      })}
                      onSubmit={onSubmit}
                    >
                      {(formProps) => {
                        const errors = formProps.errors;
                        const touched = formProps.touched;
                        const values = formProps.values;
                        return (
                          <div>
                            <FormGroup className="rowWrap">
                              <div className="dropDown_input">
                                <DropdownSelect
                                  label={null}
                                  name="type"
                                  onChange={(selectedOption) => {
                                    formProps.setFieldValue(
                                      "type",
                                      selectedOption
                                    );
                                  }}
                                  placeholder={intl.formatMessage({
                                    id: "process > Type",
                                  })}
                                  isDisabled={disabled}
                                  options={[
                                    {
                                      value: "Address",
                                      label: "Address",
                                    },
                                    {
                                      value: "City",
                                      label: "City",
                                    },
                                    {
                                      value: "State",
                                      label: "State",
                                    },
                                    {
                                      value: "Zip Code",
                                      label: "Zip Code",
                                    },
                                    {
                                      value: "County",
                                      label: "County",
                                    },
                                  ]}
                                  value={values.type}
                                  error={
                                    errors.type && touched.type && errors.type
                                  }
                                />
                              </div>

                              <TextInput
                                type="text"
                                name="location"
                                placeholder={intl.formatMessage({
                                  id: "form builder > placeholder location",
                                })}
                                // style={{ maxHeight: "53px" }}
                                value={values.location}
                                error={
                                  errors.location &&
                                  touched.location &&
                                  errors.location
                                }
                                disabled={disabled}
                                onChange={(e) => {
                                  if (values.type) {
                                    formProps.handleChange(e);
                                  } else {
                                    formProps.handleChange({});
                                  }
                                }}
                                // onBlur={formProps.handleBlur}
                                className="textInput"
                              />

                              <TextInput
                                type="text"
                                name="radius"
                                placeholder={intl.formatMessage({
                                  id: "form builder > placeholder radius",
                                })}
                                className="textInput_last"
                                // style={{ maxHeight: "53px" }}
                                value={values.radius}
                                error={
                                  errors.radius &&
                                  touched.radius &&
                                  errors.radius
                                }
                                disabled={disabled}
                                onChange={(e) => {
                                  formProps.handleChange(e);
                                }}
                                // onBlur={formProps.handleBlur}
                              />

                              <Button
                                color="white"
                                // type="submit"
                                className="plus_button"
                                disabled={disabled}
                                // disabled={isLoading}
                                onClick={formProps.handleSubmit}
                              >
                                <FaPlus />
                              </Button>
                            </FormGroup>
                          </div>
                        );
                      }}
                    </Formik>

                    {data.length
                      ? data.map((item, index) => (
                          <div className={"full_wrap"} key={"item_" + index}>
                            {index === 0 ? (
                              <div className="targets">
                                {intl.formatMessage({
                                  id: "form builder > targets geotargeting",
                                })}
                              </div>
                            ) : null}
                            <div className="FlexRow">
                              <div className="location">{item.type}</div>
                              <div className="notification_text">
                                {item.location}
                              </div>
                              <div className="location">
                                {item.radius ? `${item.radius} mi` : "---"}
                              </div>
                              <button
                                className="cross"
                                disabled={disabled}
                                onClick={(e) => {
                                  e.preventDefault();
                                  let arr = [...data];
                                  arr.splice(index, 1);
                                  setData(arr);
                                  onChange(arr);
                                }}
                              >
                                <MdClose />
                              </button>
                            </div>
                          </div>
                        ))
                      : null}
                    {!!get(errors, fieldName, "") &&
                      get(touched, fieldName, false) && (
                        <span className="text-danger">
                          {get(errors, fieldName, "")}
                        </span>
                      )}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
};

GeoTargeting.defaultProps = {
  isNew: false,
  isFormBuilder: true,
  errors: {},
  touched: {},
  onChange: () => {},
  onDelete: () => {},
  allowDelete: true,
  disabled: false,
  isShowOffText: true,
};

export default GeoTargeting;
