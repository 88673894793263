import UserList from "./view";
import { get, filter, includes } from "lodash";
import { connect } from "react-redux";
import {
  TAG_TYPE_LOCATION,
  TAG_TYPE_FORMAT,
  TAG_TYPE_EXCEPT_LOCATION_FORMAT
} from "components/SelectTags";
function getTagOptionsByType(type, auth) {
  let tagOptions = [];
  let tags = get(auth, "info.tags.tags", []);
  switch (type) {
    case TAG_TYPE_LOCATION:
      tags = filter(tags, tag => includes(tag, "Location"));
      break;
    case TAG_TYPE_FORMAT:
      tags = filter(tags, tag => includes(tag, "Format"));
      break;
    case TAG_TYPE_EXCEPT_LOCATION_FORMAT:
      tags = filter(
        tags,
        tag => !includes(tag, "Format") && !includes(tag, "Location")
      );
      break;
    default:
      break;
  }
  let map_tags = (tag, path) => {
    tagOptions.push({
      value: tag.id,
      label: path
    });
    tag.children &&
      tag.children.map(child => {
        map_tags(child, path + " / " + child.title);
      });
  };
  tags.map(tag => {
    map_tags(tag, tag.title);
  });
  return tagOptions;
}

export default connect(state => {
  const auth = get(state, "auth", {});
  const locationOptions = getTagOptionsByType(TAG_TYPE_LOCATION, auth);
  return {
    auth,
    locationOptions
  };
}, {})(UserList);
