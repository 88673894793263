import * as React from "react";
import { Row, Col } from "reactstrap";
import { get, map, debounce, filter, toLower, find, orderBy } from "lodash";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import bn from "utils/bemnames";
import SearchInput from "components/SearchInput";
import { PlusIcon, PalmTreeIcon } from "components/CustomIcons";
import { FaEllipsisH } from "react-icons/lib/fa";
import Avatar from "components/Avatar";
import Player from "components/Player";
import { CloseIcon } from "components/CustomIcons";
import ToggleSwitch from "components/ToggleSwitch";
import {
  checkOutOfOfficeOfUserByDates,
  formatDisplayDateWithTimezone,
  // getRoleIdByName,
} from "utils/helpers";
import { getRoleUsersByHubMembers } from "../helper";
import { useMemo } from "react";
import VoLabel from "./VoLabel";

const bem = bn.create("select-team");
const userInfo = (user) => {
  const currentTasksCount = user.info.current_tasks_count
    ? user.info.current_tasks_count
    : 0;
  const assignedTasksCount = user.info.assigned_tasks_count
    ? user.info.assigned_tasks_count
    : 0;
  return (
    <div className={bem.e("user-tasks")}>
      <div
        className={classnames(bem.e("user-task"), bem.e("user-task-current"))}
      >
        <span className={bem.e("user-task-label")}>
          <FormattedMessage
            className={bem.e("user-task-label")}
            id="select team > current tasks"
          />
        </span>
        :<span className={bem.e("user-task-value")}>{currentTasksCount}</span>
      </div>
      <div
        className={classnames(bem.e("user-task"), bem.e("user-task-assigned"))}
      >
        <span className={bem.e("user-task-label")}>
          <FormattedMessage id="select team > assigned tasks" />:
        </span>
        <span className={bem.e("user-task-value")}>{assignedTasksCount}</span>
      </div>
    </div>
  );
};
const SelectTeamVo = (props) => {
  const {
    process,
    step,
    intl,
    selectedUsers,
    setSelectedUsers,
    onGetTeamMembers,
    members,
    // info,
    isLoading,
  } = props;
  const [isAudition, setIsAudition] = React.useState(true);
  const [selectedItem, setSelectedItem] = React.useState(0);
  const [currentTotalMembers, setCurrentTotalMembers] = React.useState(4);
  const [searchText, setSearchText] = React.useState("");
  const [currentUsers, setCurrentUsers] = React.useState([]);
  const roleVoiceTalent = step.fields.find(
    (f) => f.field === "role_voice_talent"
  );
  const processKey = get(process, "_id", "");
  // const isHubManager = getRoleIdByName("Hub Manager", info.enterprise_roles);
  const allVoUsers = useMemo(() => {
    const users = getRoleUsersByHubMembers(roleVoiceTalent, members);
    const filteredUsers = filter(
      users,
      (user) => get(user, "info.hubs.length", 0) > 0
    );
    setCurrentUsers(filteredUsers);
    return users;
  }, [members]);

  React.useEffect(() => {
    const voiceTalentUsers = get(
      process,
      "_source.role_voice_talent.users",
      []
    );
    const formattedUsers = map(voiceTalentUsers, (user, i) => {
      const userIndex = user.index !== undefined ? user.index : i;
      return {
        id: user.id,
        name: user.name,
        role: user.role,
        avatar_url: user.avatar_url,
        index: userIndex,
        label: user.label ? user.label : `VO ${userIndex + 1}`,
      };
    });
    setSelectedUsers(formattedUsers);
  }, [process]);
  React.useEffect(() => {
    if (isLoading) return;
    const stations = get(process, "_source.order_stations", []);
    onGetTeamMembers(stations);
  }, [processKey]);

  React.useEffect(() => {
    const maxIndex = Math.max(...map(selectedUsers, (u) => u.index));
    if (maxIndex > 4 && currentTotalMembers !== 8) {
      setCurrentTotalMembers(8);
    }
  }, [selectedUsers.length]);
  const onChangeInput = debounce((searchQuery) => {
    const newUsers = filter(allVoUsers, (item) =>
      toLower(item.label).includes(toLower(searchQuery))
    );
    setCurrentUsers(newUsers);
  }, 20);
  const toggleUserRole = (user, role) => {
    const isSelected = selectedUsers.find(
      (item) => Number(item.id) === Number(user.info.id)
    );
    if (isSelected) {
      setSelectedUsers(
        selectedUsers.filter((item) => Number(item.id) !== Number(user.info.id))
      );
    } else {
      setSelectedUsers([
        ...selectedUsers,
        {
          id: user.info.id,
          name: user.info.name,
          role: user.role,
          avatar_url: user.info.avatar_url,
          index: selectedItem,
          label: `VO ${selectedItem + 1}`,
        },
      ]);
    }
  };
  const onDeleteUser = (user) => {
    const users = selectedUsers.filter(
      (u) => parseInt(u.id) !== parseInt(user.id)
    );
    setSelectedUsers(users);
  };
  const onUpdateUser = (index, params) => {
    const users = selectedUsers.map((u) => {
      if (u.index === index) {
        return { ...u, ...params };
      }
      return u;
    });
    setSelectedUsers(users);
  };
  const onClickPlusMembers = () => {
    if (currentTotalMembers < 8) {
      setCurrentTotalMembers(8);
    } else {
      setCurrentTotalMembers(4);
      if (selectedItem >= 4) {
        setSelectedItem(0);
      }
      const users = selectedUsers.filter((u) => parseInt(u.index) < 4);
      setSelectedUsers(users);
    }
  };
  // generate data to display users. (it's same with select team component)
  const roles = [roleVoiceTalent];
  let all_candidates = [];
  let role_candidates = {};
  roles.forEach((role) => {
    role_candidates[role.field] = currentUsers;
  });
  Object.keys(role_candidates).map((role_key) => {
    let candidates = role_candidates[role_key];

    candidates &&
      candidates.map((candidate) => {
        if (!all_candidates.find((c) => c.info.id === candidate.info.id))
          all_candidates.push({ ...candidate, candidate_for: [] });

        all_candidates
          .find((c) => c.info.id === candidate.info.id)
          .candidate_for.push(roles.find((r) => r && r.field === role_key));
        return true;
      });
    return true;
  });
  all_candidates = all_candidates.map((user) => {
    const isSelected = user.candidate_for.find((role) => {
      return (
        get(process._source[role.field], "users", []).filter(
          (u) => parseInt(u.id) === user.info.id
        ).length > 0
      );
    });
    return {
      ...user,
      order: isSelected ? 1 : 0,
    };
  });
  all_candidates = orderBy(all_candidates, ["order", "label"], "desc");

  return (
    <div className={bem.b()}>
      <div className={bem.e("assign-vo")}>
        <div className={bem.e("assign-vo-sidebar-members")}>
          <div className={bem.e("vo-members-items-container")}>
            <div className={bem.e("members-items")}>
              {map([...Array(currentTotalMembers).keys()], (number) => {
                const userVo = find(selectedUsers, (u) => u.index === number);
                const status = userVo
                  ? "assigned"
                  : selectedItem === number
                  ? "selected"
                  : "inactive";
                return (
                  <div
                    className={classnames(
                      bem.e("member-item"),
                      bem.e(`member-${status}`)
                    )}
                    key={number}
                  >
                    <VoLabel
                      bem={bem}
                      intl={intl}
                      number={number}
                      userVo={userVo}
                      onUpdateUser={onUpdateUser}
                      status={status}
                    />

                    <div
                      className={bem.e("member-box")}
                      onClick={() => {
                        if (status === "inactive") {
                          setSelectedItem(number);
                        }
                      }}
                    >
                      {status === "selected" ? <PlusIcon color="#fff" /> : null}
                      {status === "assigned" ? (
                        <span className={bem.e("member-name")}>
                          {userVo.name}
                          <span
                            className={bem.e("delete-icon")}
                            onClick={() => {
                              onDeleteUser(userVo);
                              setSelectedItem(number);
                            }}
                          >
                            <CloseIcon color={"#C2D4E0"} />
                          </span>
                        </span>
                      ) : null}
                    </div>
                  </div>
                );
              })}
            </div>
            <div
              className={bem.e("add-member-btn")}
              onClick={onClickPlusMembers}
            >
              {currentTotalMembers >= 8 ? (
                <span className={bem.e("minus-icon")} />
              ) : (
                <PlusIcon color="#fff" />
              )}
            </div>
          </div>
          <div className={bem.e("members-search")}>
            <SearchInput
              placeholder={intl.formatMessage({
                id: "process > search for team member",
              })}
              onClear={() => {
                setSearchText("");
                setCurrentUsers(allVoUsers);
              }}
              onSubmit={() => onChangeInput(searchText)}
              onChange={(value) => {
                setSearchText(value);
                onChangeInput(value);
              }}
              value={searchText}
            />
            <ToggleSwitch
              leftComponent={<FormattedMessage id="select team > audition" />}
              rightComponent={null}
              name="is_hidden"
              containerClassName={bem.e("filter-audition")}
              switchProps={{
                checked: isAudition,
                onChange: (checked) => {
                  setIsAudition(checked);
                },
                offColor: "#C2D4E0",
                onColor: "#795AFA",
                uncheckedIcon: false,
                checkedIcon: false,
                boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.282)",
                height: 26.44,
                width: 49.76,
                handleDiameter: 22.92,
              }}
            />
          </div>
        </div>
        <div className={bem.e("select-teams-member")}>
          <div className={bem.e("container-users")}>
            {all_candidates.map((user, ind) => {
              const userTimeZone = get(user, "info.timezone");
              const datesOutOfOffice = checkOutOfOfficeOfUserByDates(
                get(user, "info.out_of_office_settings", []),
                userTimeZone
              );

              const isHasOOOPast = find(
                datesOutOfOffice,
                (date) => date.diffDays <= 0
              );
              return (
                <div
                  key={"user_" + ind}
                  className={classnames(bem.e("user-row"), {
                    [bem.e("user-ooo")]: isHasOOOPast,
                  })}
                >
                  <Row>
                    <Col xs={6} className={bem.e("col-user")}>
                      <div className={bem.e("user-info")}>
                        <Avatar
                          src={user.info.avatar_url}
                          size={40}
                          className={bem.e("user-avatar")}
                          name={user.info.name}
                        />
                        <div className={bem.e("user-info-right")}>
                          <span className={bem.e("user-name")}>
                            {user.info.name}
                            {isHasOOOPast ? (
                              <span className={bem.e("vacation-icon")}>
                                <PalmTreeIcon />
                              </span>
                            ) : null}
                            <div className={bem.e("user-action")}>
                              <FaEllipsisH color={`#798186`} size={20} />
                            </div>
                          </span>
                          {userInfo(user)}
                          {datesOutOfOffice && datesOutOfOffice.length > 0
                            ? datesOutOfOffice.map((date) => (
                                <div className={bem.e("out-of-office")}>
                                  {props.intl.formatMessage({
                                    id: "process > out as of",
                                  })}{" "}
                                  {formatDisplayDateWithTimezone(
                                    date.from_date,
                                    "MM/DD/YYYY",
                                    userTimeZone
                                  )}
                                  {" - "}
                                  {props.intl.formatMessage({
                                    id: "process > returns",
                                  })}{" "}
                                  {formatDisplayDateWithTimezone(
                                    date.to_date,
                                    "MM/DD/YYYY",
                                    userTimeZone
                                  )}
                                </div>
                              ))
                            : null}
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} className={bem.e("col-user")}>
                      <div
                        className={classnames(
                          bem.e("user-roles-reel"),
                          bem.e("user-roles-reel-column")
                        )}
                      >
                        {user.info.reel && isAudition && (
                          <Player url={user.info.reel} />
                        )}
                        <div className={bem.e("user-roles")}>
                          {user.candidate_for.map((role) => {
                            const userVo = selectedUsers.find(
                              (u) => parseInt(u.id) === user.info.id
                            );
                            const selectedUserForCurrentSlot = selectedUsers.find(
                              (u) => parseInt(u.index) === selectedItem
                            );
                            const isDisabled =
                              role.is_disabled ||
                              selectedUsers.length === currentTotalMembers ||
                              selectedUserForCurrentSlot;
                            return (
                              <div
                                className={classnames(
                                  bem.e("user-role-button"),
                                  {
                                    [bem.e("user-role-assigned")]: userVo,
                                    [bem.e("user-role-disabled")]: isDisabled, //add condition for show disabled style
                                  }
                                )}
                                key={
                                  "user_role_" + user.info.id + "_" + role.field
                                }
                                onClick={() => {
                                  if (isDisabled) return;
                                  toggleUserRole(user, role);
                                }}
                              >
                                <span className={bem.e("user-role-title")}>
                                  {!userVo ? (
                                    role.title
                                  ) : (
                                    <span>
                                      {userVo.label ? (
                                        userVo.label
                                      ) : (
                                        <>
                                          {
                                            <FormattedMessage id="process > vo" />
                                          }
                                          {` `}
                                          {parseInt(userVo.index) + 1}
                                        </>
                                      )}
                                    </span>
                                  )}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectTeamVo;
