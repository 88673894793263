import { useEffect, useState } from "react";
import { countdownWithTimezone } from "utils/helpers";

const useCountdown = (date, timezone, time) => {
  const [countDown, setCountDown] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      const data = countdownWithTimezone(date, timezone, time);
      setCountDown(data);
    }, 1000);

    return () => clearInterval(interval);
  }, [date]);

  return countDown;
};
export default useCountdown;
