import * as Const from "utils/constants";
import Api from "utils/api";
import queryString from "query-string";
import * as Types from "store/types/programmings";
import { get, isFunction, values } from "lodash";
export const searchElements = ({ data, cb }) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.PROGRAMMINGS_SEARCH_REQUEST, payload: data });
    const response = await Api.doCall(
      `${Const.SEARCH_ELEMENTS()}?${queryString.stringify(data, {
        arrayFormat: "bracket",
      })}`,
      "GET"
    );
    if (response.status === 200) {
      if (!response.error) {
        dispatch({
          type: Types.PROGRAMMINGS_SEARCH_SUCCESS,
          payload: response.data,
        });
        if (cb) cb(response);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.PROGRAMMINGS_SEARCH_ERROR, payload: errorStr });
};

export const getSchedules = ({ data, cb }) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.PROGRAMMINGS_GET_SCHEDULES_REQUEST });
    const response = await Api.doCall(
      `${Const.GET_SCHEDULES()}?${queryString.stringify(data)}`,
      "GET"
    );
    if (response.status === 200) {
      if (!response.error) {
        dispatch({
          type: Types.PROGRAMMINGS_GET_SCHEDULES_SUCCESS,
          payload: response.data,
        });
        if (cb) cb(response);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.PROGRAMMINGS_GET_SCHEDULES_ERROR, payload: errorStr });
};

export const getClocksData = ({ data, cb }) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.FETCH_CLOCKS_DATA_REQUEST });
    const response = await Api.doCall(
      `${Const.GET_CLOCKS_DATA()}?${queryString.stringify(data)}`,
      "GET"
    );
    if (response.status === 200) {
      if (!response.error) {
        dispatch({
          type: Types.FETCH_CLOCKS_DATA_SUCCESS,
          payload: response.data,
        });
        if (cb) cb(response);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.FETCH_CLOCKS_DATA_ERROR, payload: errorStr });
};

export const getItemsAvailability = ({ data, cb }) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.FETCH_ITEMS_AVAILABILITY_REQUEST });
    const response = await Api.doCall(
      Const.GET_ITEMS_AVAILABILITY(),
      "POST",
      data
    );
    if (response.status === 200) {
      if (!response.error) {
        dispatch({
          type: Types.FETCH_ITEMS_AVAILABILITY_SUCCESS,
          payload: response.data,
          loadMore: !!data.from_date,
        });
        if (cb) cb(response);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.FETCH_ITEMS_AVAILABILITY_ERROR, payload: errorStr });
};

export const checkSchedulingAvailability = ({ data, cb }) => async (
  dispatch
) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.CHECK_SCHEDULING_AVAILABILITY_REQUEST });
    const response = await Api.doCall(
      `${Const.CHECK_SCHEDULING_AVAILABILITY()}`,
      "POST",
      data
    );
    if (response.status === 200) {
      if (!response.error) {
        dispatch({
          type: Types.CHECK_SCHEDULING_AVAILABILITY_SUCCESS,
          payload: response.data,
        });
        if (cb) cb(response);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.CHECK_SCHEDULING_AVAILABILITY_ERROR,
    payload: errorStr,
  });
};
export const findInventory = ({ data, cb }) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.PROGRAMMINGS_FIND_INVENTORY_REQUEST });
    const response = await Api.doCall(
      `${Const.FIND_INVENTORY()}`,
      "POST",
      data
    );
    if (response.status === 200) {
      if (!response.error) {
        dispatch({
          type: Types.PROGRAMMINGS_FIND_INVENTORY_SUCCESS,
          payload: response.data,
        });
        if (cb) cb(response);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.PROGRAMMINGS_FIND_INVENTORY_ERROR,
    payload: errorStr,
  });
};

export const saveInventory = ({ data, cb }) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.PROGRAMMINGS_SAVE_INVENTORY_REQUEST });
    const response = await Api.doCall(Const.SAVE_INVENTORY(), "POST", data);
    if (response.status === 200) {
      if (!response.error) {
        dispatch({
          type: Types.PROGRAMMINGS_SAVE_INVENTORY_SUCCESS,
          payload: response.data,
        });
        if (cb) cb(response);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.PROGRAMMINGS_SAVE_INVENTORY_ERROR,
    payload: errorStr,
  });
};

export const releaseInventory = ({ data, cb }) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.PROGRAMMINGS_RELEASE_INVENTORY_REQUEST });
    const response = await Api.doCall(Const.RELEASE_INVENTORY(), "POST", data);
    if (response.status === 200) {
      if (!response.error) {
        dispatch({
          type: Types.PROGRAMMINGS_RELEASE_INVENTORY_SUCCESS,
          payload: response.data,
        });
        if (cb) cb(response);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.PROGRAMMINGS_RELEASE_INVENTORY_ERROR,
    payload: errorStr,
  });
};

export const releaseReservedSlots = ({ data, cb }) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.PROGRAMMINGS_RELEASE_RESERVED_SLOTS_REQUEST });
    dispatch(resetCheckSchedulingAvailability());
    const response = await Api.doCall(
      Const.RELEASE_RESERVED_INVENTORY(),
      "POST",
      data
    );
    if (response.status === 200) {
      if (!response.error) {
        dispatch({
          type: Types.PROGRAMMINGS_RELEASE_RESERVED_SLOTS_SUCCESS,
          payload: response.data,
        });
        if (cb) cb(response);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.PROGRAMMINGS_RELEASE_RESERVED_SLOTS_ERROR,
    payload: errorStr,
  });
};

export const setSchedulingAvailability = ({ data = {} }) => (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.PROGRAMMINGS_SET_SCHEDULING_AVAILABILITY_REQUEST });
    dispatch({
      type: Types.PROGRAMMINGS_SET_SCHEDULING_AVAILABILITY_SUCCESS,
      payload: data,
    });
    return;
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.PROGRAMMINGS_SET_SCHEDULING_AVAILABILITY_ERROR,
    payload: errorStr,
  });
};

export const setClocksData = (payload = {}) => async (dispatch) => {
  dispatch({
    type: Types.SET_CLOCKS_DATA,
    payload,
  });
};

export const resetCheckSchedulingAvailability = (payload = {}) => async (
  dispatch
) => {
  dispatch({
    type: Types.CHECK_SCHEDULING_AVAILABILITY_RESET,
  });
};

export const swapOverrideCheck = ({ data, cb }) => async () => {
  try {
    const response = await Api.doCall(
      `${Const.SWAP_OVERRIDE_CHECK()}`,
      "POST",
      data
    );
    if (response.status === 200) {
      if (!response.error) {
        if (cb) cb(response.data);
        return;
      }
    }
  } catch (error) {}
};
export const swapSchedule = ({ data, cb }) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.PROGRAMMINGS_SWAP_SCHEDULE_REQUEST });
    const response = await Api.doCall(`${Const.SWAP_SCHEDULE()}`, "POST", data);
    if (response.status === 200) {
      if (!response.error) {
        dispatch({
          type: Types.PROGRAMMINGS_SWAP_SCHEDULE_SUCCESS,
          payload: data,
        });
        if (cb) cb(response.data);
        return;
      }
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.PROGRAMMINGS_SWAP_SCHEDULE_ERROR, payload: errorStr });
};

export const getSwappableSlots = ({ data, cb }) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.PROGRAMMINGS_GET_SWAPPABLE_SLOTS_REQUEST });
    const response = await Api.doCall(
      `${Const.GET_SWAPPABLE_SLOTS()}`,
      "POST",
      data
    );
    if (response.status === 200) {
      if (!response.error) {
        const dataResponse = get(response, "data", {});
        const swappableSlots = {
          same_type_slots: values(dataResponse.same_type_slots),
          swappable_slots: values(dataResponse.swappable_slots),
        };
        dispatch({
          type: Types.PROGRAMMINGS_GET_SWAPPABLE_SLOTS_SUCCESS,
          payload: swappableSlots,
        });
        if (cb) cb(swappableSlots);
        return;
      }
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.PROGRAMMINGS_GET_SWAPPABLE_SLOTS_ERROR,
    payload: errorStr,
  });
};

export const getSchedulesLog = ({ data, cb }) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.PROGRAMMINGS_GET_SCHEDULES_ITEMS_LOG_REQUEST });
    const response = await Api.doCall(
      `${Const.GET_SCHEDULES_LOG()}?${queryString.stringify(data)}`,
      "GET"
    );
    if (response.status === 200) {
      if (!response.error) {
        dispatch({
          type: Types.PROGRAMMINGS_GET_SCHEDULES_ITEMS_LOG_SUCCESS,
          payload: get(response, "data", {}),
        });
        if (cb) cb(get(response, "data", {}));
        return;
      }
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.PROGRAMMINGS_GET_SCHEDULES_ITEMS_LOG_ERROR,
    payload: errorStr,
  });
};

export const validateCopyGroups = ({ data, cb }) => async () => {
  let errorStr = "";
  try {
    const response = await Api.doCall(
      Const.PROGRAMMING_VALIDATE_COPY_GROUPS(),
      "POST",
      data
    );
    if (response.status === 200) {
      if (!response.error) {
        if (cb) cb(get(response, "data", {}));
        return;
      }
    }
  } catch (error) {
    errorStr = error.toString();
  }
};

export const addProgrammingElement = ({ data, cb }) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.ADD_PROGRAMMING_ELEMENT_REQUEST });
    const postData = {
      meta: {
        process_key: "__new",
        step_key: "order_details",
        message: {
          delta: data,
          order_stations: data.order_stations,
          contract_stations: data.contract_stations,
          order_stations_array: data.order_stations_array,
          template: data.template,
          order_title: data.order_title,
          from_step: "order_details",
          to_step: "",
        },
      },
      data,
    };
    let response = await Api.doCall(
      Const.GET_PROCESS("__new"),
      "PUT",
      postData
    );
    if (response.status === 200) {
      if (!response.error) {
        dispatch({
          type: Types.ADD_PROGRAMMING_ELEMENT_SUCCESS,
          payload: get(response, "data", {}),
        });
        // dispatch({ type: Types.FORGET_ASSOCIATE_ELEMENTS });

        if (cb) cb(get(response, "data", {}));
        return;
      }
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.ADD_PROGRAMMING_ELEMENT_ERROR,
    payload: errorStr,
  });
};

export const saveAsPdf = (params, cb) => async () => {
  try {
    Api.doCall(
      `${Const.PROGRAMMING_ITEMS_LOGS_PDF()}?${queryString.stringify(params)}`,
      "GET",
      null,
      (response) => {
        if (response.status === 200 && isFunction(cb)) {
          cb(response.data);
        }
      },
      true,
      {
        responseType: "arraybuffer",
      }
    );
  } catch (error) {
    if (isFunction(cb)) cb(false);
    return false;
  }
};

export const getAssociateElements = ({ data, cb }) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({
      type: Types.PROGRAMMINGS_GET_ASSOCIATE_ELEMENTS_REQUEST,
      payload: data,
    });
    const response = await Api.doCall(
      `${Const.SEARCH_ELEMENTS()}?${queryString.stringify(data, {
        arrayFormat: "bracket",
      })}`,
      "GET"
    );
    if (response.status === 200) {
      if (!response.error) {
        dispatch({
          type: Types.PROGRAMMINGS_GET_ASSOCIATE_ELEMENTS_SUCCESS,
          payload: response.data,
        });
        if (cb) cb(response);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.PROGRAMMINGS_GET_ASSOCIATE_ELEMENTS_ERROR,
    payload: errorStr,
  });
};

export const setAssociateElements = (payload = {}) => async (dispatch) => {
  dispatch({
    type: Types.PROGRAMMINGS_SET_ASSOCIATE_ELEMENTS_SUCCESS,
    payload,
  });
};
export const forgetAssociateElements = (payload = {}) => async (dispatch) => {
  dispatch({
    type: Types.FORGET_ASSOCIATE_ELEMENTS,
    payload,
  });
};

export const getQuickViewDetails = ({ data, cb }) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({
      type: Types.GET_QUICK_VIEW_REQUEST,
      payload: data,
    });
    const response = await Api.doCall(
      `${Const.CONTESTS_QUICK_VIEW()}?${queryString.stringify(data)}`,
      "GET"
    );
    if (response.status === 200) {
      if (!response.error) {
        dispatch({
          type: Types.GET_QUICK_VIEW_REQUEST_SUCCESS,
          payload: response.data,
        });
        if (cb) cb(response);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.GET_QUICK_VIEW_REQUEST_ERROR,
    payload: errorStr,
  });
};

export const endContestEarly = ({ contestId, cb }) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.END_CONTEST_EARLY_REQUEST });
    const response = await Api.doCall(
      `${Const.CONTESTS_END_EARLY()}/${contestId}`,
      "POST"
    );
    if (response.status === 200) {
      if (!response.error) {
        dispatch({
          type: Types.END_CONTEST_EARLY_SUCCESS,
          payload: response.data,
        });
        if (cb) cb(response);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.END_CONTEST_EARLY_ERROR,
    payload: errorStr,
  });
};

export const deleteSlot = ({ data, cb }) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.PROGRAMMINGS_DELETE_SLOT_REQUEST });
    const response = await Api.doCall(
      Const.PROGRAMMING_DELETE_SLOT(),
      "DELETE",
      data
    );
    if (response.status === 200) {
      if (!response.error) {
        dispatch({
          type: Types.PROGRAMMINGS_DELETE_SLOT_SUCCESS,
          payload: response.data,
        });
        if (cb) cb(response.data);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.PROGRAMMINGS_DELETE_SLOT_ERROR, payload: errorStr });
};

export const undoDeleteSlot = ({ data, cb }) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.PROGRAMMINGS_UNDO_DELETE_SLOT_REQUEST });
    const response = await Api.doCall(
      Const.PROGRAMMING_UNDO_DELETE_SLOT(),
      "POST",
      data
    );
    if (response.status === 200) {
      if (!response.error) {
        dispatch({
          type: Types.PROGRAMMINGS_UNDO_DELETE_SLOT_SUCCESS,
          payload: response.data,
        });
        if (cb) cb(response);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.PROGRAMMINGS_UNDO_DELETE_SLOT_ERROR,
    payload: errorStr,
  });
};

export const getOrdersList = ({ data, cb }) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({
      type: Types.PROGRAMMINGS_ORDERS_LIST_REQUEST,
      payload: data,
    });
    const response = await Api.doCall(
      `${Const.GET_ORDERS_LIST()}`,
      "POST",
      data
    );
    if (response.status === 200) {
      if (!response.error) {
        dispatch({
          type: Types.PROGRAMMINGS_ORDERS_LIST_SUCCESS,
          payload: response.data,
        });
        if (cb) cb(response);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.PROGRAMMINGS_ORDERS_LIST_ERROR,
    payload: errorStr,
  });
};
export const setOrdersList = (payload = []) => async (dispatch) => {
  dispatch({
    type: Types.SET_ORDERS_LIST,
    payload,
  });
};

export const getFillSettings = (data) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({
      type: Types.PROGRAMMINGS_GET_FILL_SETTINGS_REQUEST,
      payload: data,
    });
    const response = await Api.doCall(
      `${Const.GET_FILL_SETTINGS()}?${queryString.stringify(data)}`,
      "GET"
    );
    if (response.status === 200) {
      if (!response.error) {
        dispatch({
          type: Types.PROGRAMMINGS_GET_FILL_SETTINGS_SUCCESS,
          payload: response.data,
        });
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.PROGRAMMINGS_GET_FILL_SETTINGS_ERROR,
    payload: errorStr,
  });
};
export const updateFillSettings = ({ data, cb }) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({
      type: Types.PROGRAMMINGS_UPDATE_FILL_SETTINGS_REQUEST,
      payload: data,
    });
    const response = await Api.doCall(
      `${Const.SAVE_FILL_SETTINGS()}`,
      "POST",
      data
    );
    if (response.status === 200) {
      if (!response.error) {
        dispatch({
          type: Types.PROGRAMMINGS_UPDATE_FILL_SETTINGS_SUCCESS,
          payload: response.data,
        });
        if (cb) cb(response);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.PROGRAMMINGS_UPDATE_FILL_SETTINGS_ERROR,
    payload: errorStr,
  });
};

export const fillSchedulingManually = ({ data, cb }) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({
      type: Types.PROGRAMMINGS_FILL_SCHEDULING_MANUALLY_REQUEST,
      payload: data,
    });
    const response = await Api.doCall(
      `${Const.FILL_SCHEDULING_MANUALLY()}`,
      "POST",
      data
    );
    if (response.status === 200) {
      if (!response.error) {
        dispatch({
          type: Types.PROGRAMMINGS_FILL_SCHEDULING_MANUALLY_SUCCESS,
          payload: response.data,
        });
        if (cb) cb(response);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.PROGRAMMINGS_FILL_SCHEDULING_MANUALLY_ERROR,
    payload: errorStr,
  });
};

export const fillSchedulingUndo = ({ data, cb }) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({
      type: Types.PROGRAMMINGS_FILL_SCHEDULING_UNDO_REQUEST,
      payload: data,
    });
    const response = await Api.doCall(
      `${Const.FILL_SCHEDULING_UNDO()}`,
      "POST",
      data
    );
    if (response.status === 200) {
      if (!response.error) {
        dispatch({
          type: Types.PROGRAMMINGS_FILL_SCHEDULING_UNDO_SUCCESS,
          payload: response.data,
        });
        if (cb) cb(response);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.PROGRAMMINGS_FILL_SCHEDULING_UNDO_ERROR,
    payload: errorStr,
  });
};
