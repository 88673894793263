import React from "react";
import bn from "utils/bemnames";

const bem = bn.create("footer");

const currentDate = new Date();
const currentYear = currentDate.getFullYear();

const Footer = (props) => {
  const { intl } = props;

  return (
    <div className={bem.b()}>
      <p>
        {intl.formatMessage({ id: "footer > support" })} |
        <a
          href="https://help.promosuite.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          {intl.formatMessage({ id: "footer > help center" })}
        </a>
        {intl.formatMessage({ id: "footer > support email" })}
      </p>
      <p>
        {intl.formatMessage(
          { id: "footer > copyright text 1" },
          {
            year: currentYear,
          }
        )}
      </p>
      <p>{intl.formatMessage({ id: "footer > copyright text 2" })}</p>
    </div>
  );
};

export default Footer;
