import ClientReview from "./view";
import { connect } from "react-redux";
import { compose, lifecycle, withState } from "recompose";
import { injectIntl } from "react-intl";
import { getTemplate } from "store/actions/template";
import { logoutUser } from "store/actions/auth";
import {
  getReviewProcess,
  formValueChanged,
  saveFormValues,
} from "store/actions/processes";
import { get } from "lodash";
import withHandlers from "recompose/withHandlers";
import { getDateFormatByCountry } from 'utils/dates';
export default injectIntl(
  compose(
    connect(
      (state, props) => {
        const { match } = props;
        let process_email_key = get(match, "params.process_email_key");
        let process = state.processes.process;
        let template_id = get(match, "params.template_id");
        const authenticated = get(state, "auth.authenticated");
        const extraReviewProcess = get(state, "processes.extraReviewProcess.data");
        const server = get(extraReviewProcess, "server", {});
        const dateFormatByServer = getDateFormatByCountry(server.country);
        return {
          authenticated,
          process,
          template_id,
          process_email_key,
          template: state.templates.template.data,
          extraReviewProcess: get(state, "processes.extraReviewProcess.data"),
          messages: get(state, "messages.messages"),
          dateFormatByServer,
        };
      },
      {
        getReviewProcess,
        formValueChanged,
        saveFormValues,
        getTemplate,
        logoutUser,
      }
    ),
    withState("refreshKey", "setRefreshKey", new Date().getTime()),
    withHandlers({
      onGetReviewProcess: ({
        process_email_key,
        template_id,
        getReviewProcess,
      }) => (payload = {}) => {
        getReviewProcess({
          ...payload,
          process_email_key,
          template_id,
        });
      },
      onGetInit: ({
        getReviewProcess,
        process_email_key,
        template_id,
        getTemplate,
      }) => () => {
        getReviewProcess({
          process_email_key: process_email_key,
          template_id,
        });
        getTemplate(template_id, null);
      },
    }),
    lifecycle({
      componentDidMount() {
        if (this.props.authenticated) {
          this.props.logoutUser(false, () => {
            this.props.onGetInit();
          });
        } else {
          this.props.onGetInit();
        }
      },
    })
  )(ClientReview)
);
