import Toast from "./view";

class Manager {
  currentToast;

  registerToast = ref => {
    this.currentToast = ref;
  };

  unregisterToast = () => {
    this.currentToast = null;
  };

  show = options => {
    if (this.currentToast) {
      this.currentToast.show(options);
    }
  };

  hide = (notif) => {
    if (this.currentToast) {
      this.currentToast.hide(notif);
    }
  };
}
const ToastManager = new Manager();

export default ToastManager;
export { Toast };
