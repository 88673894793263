import React, { useEffect, useState } from "react";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  RecurranceSmallIcon,
} from "components/CustomIcons";
import { FormattedMessage } from "react-intl";
import * as dates from "date-arithmetic";
import moment from "moment";
import bn from "utils/bemnames";
import get from "lodash/get";
const bem = bn.create("calendar-week-sticky");

const HeaderCalendar = (props) => {
  const {
    view,
    date,
    onRangeChange,
    onAddFillItems,
    fillSettings,
    weekdays,
  } = props;

  const firstOfWeek = weekdays === "Sunday-Saturday" ? 0 : 1;

  const [range, setRange] = useState([]);

  const workWeekRange = (r) => {
    return r.filter(
      (d) => [6, 0].indexOf(parseInt(moment(d).format("d"))) === -1
    );
  };

  const rangeWeek = (start, end, unit = "day") => {
    let current = start,
      days = [];

    while (dates.lte(current, end, unit)) {
      days.push(current);
      current = dates.add(current, 1, unit);
    }

    return days;
  };

  const saveRange = (r) => {
    if (view === "week") setRange(r);
    else setRange(workWeekRange(r));
  };

  useEffect(() => {
    const start = dates.startOf(date, "week", firstOfWeek);
    const end = dates.endOf(date, "week", firstOfWeek);

    const r = rangeWeek(start, end);
    saveRange(r);
  }, [view, weekdays]);

  const goNextWeek = () => {
    const firstDay = get(range, 0);
    let start = dates.add(firstDay, +7, "day");
    start = dates.startOf(start, "week", firstOfWeek);
    let end =
      view === "week" ? dates.add(start, 6, "day") : dates.add(start, 4, "day");

    const r = rangeWeek(start, end);
    saveRange(r);
    onRangeChange(r);
  };

  const goPrevWeek = () => {
    const firstDay = get(range, 0);
    let start = dates.add(firstDay, -7, "day");
    start = dates.startOf(start, "week", firstOfWeek);
    let end =
      view === "week" ? dates.add(start, 6, "day") : dates.add(start, 4, "day");

    const r = rangeWeek(start, end);
    saveRange(r);
    onRangeChange(r);
  };

  const goNextOneDay = () => {
    const firstDay = get(range, 0);
    let start = dates.add(firstDay, +1, "day");

    if (view === "work_week") {
      if (parseInt(moment(start).format("d")) === 0) {
        start = dates.add(start, +1, "day");
      }
      if (parseInt(moment(start).format("d")) === 6) {
        start = dates.add(start, +2, "day");
      }
    }

    let end = dates.add(start, 6, "day");

    const r = rangeWeek(start, end);

    saveRange(r);
    onRangeChange(r);
  };

  const goBackOneDay = () => {
    const firstDay = get(range, 0);
    let start = dates.add(firstDay, -1, "day");

    if (view === "work_week") {
      if (parseInt(moment(start).format("d")) === 0) {
        start = dates.add(start, -2, "day");
      }
      if (parseInt(moment(start).format("d")) === 6) {
        start = dates.add(start, -1, "day");
      }
    }

    let end = dates.add(start, 6, "day");

    const r = rangeWeek(start, end);

    saveRange(r);
    onRangeChange(r);
  };

  const mDate = moment(date);

  return (
    <div className="rbc-calendar">
      <div className={bem.e("toolbar-container")}>
        <label className={bem.e("label-date")}>{mDate.format("YYYY")}</label>
        <div className={bem.e("back-next-buttons")}>
          <button className={bem.e("btn-back")} onClick={() => goPrevWeek()}>
            <ArrowLeftIcon color="#795AFA" />
            <ArrowLeftIcon color="#795AFA" />
          </button>

          <button
            className={bem.e("btn-back-2")}
            onClick={() => goBackOneDay()}
          >
            <ArrowLeftIcon color="#795AFA" />
          </button>

          <button className={bem.e("btn-next")} onClick={() => goNextOneDay()}>
            <ArrowRightIcon color="#795AFA" />
          </button>

          <button className={bem.e("btn-next-2")} onClick={() => goNextWeek()}>
            <ArrowRightIcon color="#795AFA" />
            <ArrowRightIcon color="#795AFA" />
          </button>
        </div>
      </div>

      <div className="rbc-time-view">
        <div className="rbc-time-header">
          <div className="rbc-label rbc-time-header-gutter"></div>
          <div className="rbc-time-header-content">
            <div className="rbc-row rbc-time-header-cell">
              {range.map((day) => {
                const mDay = moment(day);
                const isShowAddFillItems =
                  moment(day).diff(new Date(), "days") >= 10;
                const settingDate = moment()
                  .startOf("days")
                  .add(fillSettings.days_before, "days");
                const isShowAddFillItemsBySetting =
                  moment(day).diff(settingDate, "days") === 0;

                return (
                  <div className="rbc-header" key={mDay.format("YYYYMMDD")}>
                    <div className={bem.e("weekday-item")}>
                      <span className={bem.e("weekday")}>
                        {mDay.format("dddd")}
                      </span>
                      <strong className={bem.e("header-date")}>
                        {mDay.format("MMM D")}
                      </strong>
                      {(isShowAddFillItemsBySetting || isShowAddFillItems) && (
                        <span
                          className={bem.e("header-add-fill")}
                          onClick={() => onAddFillItems(day)}
                        >
                          <RecurranceSmallIcon />
                          <FormattedMessage id="process > promotion > liner > schedule > add fill items" />
                        </span>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

HeaderCalendar.defaultProps = {};

export default HeaderCalendar;
