import React, { useEffect, useState } from "react";
import classnames from "classnames";
import {
  map,
  find,
  includes,
  xor,
  upperFirst,
  get,
  forEach,
  sumBy,
  filter,
} from "lodash";
import { Row, Col } from "reactstrap";
import { FormattedMessage } from "react-intl";
import Checkbox from "components/Checkbox";
import { ReCalculateIcon } from "components/CustomIcons";
import useInfiniteScroll from "utils/useInfiniteScroll";
import bn from "utils/bemnames";
const bem = bn.create("schedule-preview");
const rulesConfig = [
  {
    value: "consecutive_days_rule",
    label: (
      <FormattedMessage id="process > promotion > liner item availability > consecutive days" />
    ),
  },
  {
    value: "time_rule",
    label: (
      <FormattedMessage id="process > promotion > liner item availability > separation" />
    ),
  },
  {
    value: "label_rule",
    label: (
      <FormattedMessage id="process > promotion > liner item availability > type" />
    ),
  },
  {
    value: "linked_items_rule",
    label: (
      <FormattedMessage id="process > promotion > liner item availability > linked items" />
    ),
  },
];
function getAllWeeksForSelect(availability) {
  const allWeeksSelect = [];
  forEach(availability, (weeks) => {
    forEach(weeks, (week) => {
      allWeeksSelect.push({
        start_date: week.start_date,
        end_date: week.end_date,
      });
    });
  });
  return allWeeksSelect;
}
const AvailabilityTable = (props) => {
  const {
    isShowTotal,
    isShowRule,
    isVerticalScroll,
    isDistribution,
    dayparts,
    shows,
    availability,
    showSelected,
    distributionText,
    rules,
    hours,
    selectedRestrictionWeeks,
    onLoadMore,
    shouldLoadMore,
    restrictions,
  } = props;
  const isShowSelectedWeeks = get(rules, "length") > 0;
  const allWeeksSelect = getAllWeeksForSelect(availability);
  const [selectedWeeks, setSelectedWeeks] = useState(
    selectedRestrictionWeeks || allWeeksSelect
  );
  let elementId = isShowTotal
    ? `availability-table-total`
    : `availability-table`;
  useInfiniteScroll({
    useWindow: false,
    isHorizontal: true,
    elementId,
    onLoadMore,
    shouldLoadMore,
  });
  useEffect(() => {
    if (props.onRelaxRestrictionWeeks)
      props.onRelaxRestrictionWeeks(selectedWeeks);
  }, [selectedWeeks]);
  const configItemNames = {
    show: "shows",
    daypart: "dayparts",
    hour: "hours",
  };
  const configColumns = {
    show: shows,
    daypart: dayparts,
    hour: hours || [],
  };
  const renderDaypartOrShows = () => {
    let columns = configColumns[showSelected] || [];
    if (isShowTotal) {
      columns = [
        ...columns,
        {
          id: -1,
          name: "Total",
        },
      ];
    }
    return map(columns, ({ name, id }) => {
      return (
        <div className="left-column" key={id}>
          {name}
        </div>
      );
    });
  };
  const renderRules = () => {
    return filter(rulesConfig, ({ value }) => get(restrictions, value)).map(
      ({ value, label }, index) => {
        return (
          <div className="week week-checkbox" key={`rule-checkbox-${index}`}>
            <Checkbox
              checked={includes(rules, value)}
              onChange={() => {
                props.onRelaxRestriction(xor(rules, [value]));
                props.onRelaxRestrictionWeeks(selectedWeeks);
              }}
              text={label}
            />
          </div>
        );
      }
    );
  };
  const renderWeekAll = () => {
    const isSelectedWeeksAll = selectedWeeks.length === allWeeksSelect.length;

    return (
      <div className={classnames("week", "week-all")} key={`week_all`}>
        <div className="week-checkbox">
          <Checkbox
            checked={isSelectedWeeksAll}
            onChange={(checked) => {
              let newValue = [];
              if (checked) {
                newValue = allWeeksSelect;
              } else {
                newValue = [];
              }
              setSelectedWeeks(newValue);
            }}
            text={null}
          />
        </div>
        <div>
          <FormattedMessage id="process > promotion > liner item availability > all weeks" />
        </div>
      </div>
    );
  };
  const renderWeeks = () => {
    const rootElement = document.getElementById(elementId);
    let totalWeeks = 0;
    const element = map(availability, (weeks, month) => {
      return map(weeks, (week, index) => {
        totalWeeks += 1;
        return (
          <div
            className={classnames("week", {
              bordered: index === weeks.length - 1,
            })}
            key={`${month}_${index}`}
          >
            {isShowSelectedWeeks && (
              <div className="week-checkbox">
                <Checkbox
                  checked={
                    !!find(
                      selectedWeeks,
                      (i) =>
                        i.start_date === week.start_date &&
                        i.end_date === week.end_date
                    )
                  }
                  onChange={(checked) => {
                    let newValue = [];
                    if (checked) {
                      newValue = [
                        ...selectedWeeks,
                        {
                          start_date: week.start_date,
                          end_date: week.end_date,
                        },
                      ];
                    } else {
                      newValue = selectedWeeks.filter(
                        (i) =>
                          !(
                            i.start_date === week.start_date &&
                            i.end_date === week.end_date
                          )
                      );
                    }
                    setSelectedWeeks(newValue);
                  }}
                  text={null}
                />
              </div>
            )}

            <div>{month}</div>
            <div>{`${week.start_day} - ${week.end_day}`}</div>
          </div>
        );
      });
    });
    if (
      totalWeeks > 7 &&
      rootElement &&
      !rootElement.classList.contains("weeks-multiple")
    ) {
      rootElement.classList.add("weeks-multiple");
    }
    return element;
  };
  const renderAvailability = () => {
    let columns = configColumns[showSelected] || [];
    const itemName = configItemNames[showSelected];
    if (isShowTotal) {
      columns = [
        ...columns,
        {
          id: -1,
          name: "Total",
        },
      ];
    }
    return map(columns, ({ id }) => {
      return (
        <div key={id} className={"d-flex week-row"}>
          {renderItemCounts(id, itemName)}
        </div>
      );
    });
  };

  const renderItemCounts = (id, itemName) => {
    let isRenderAll = false;
    return map(availability, (weeks, month) => {
      let weeksElement = map(weeks, (week, index) => {
        let item = find(week[itemName], (item) => item.id === id);
        if (id === -1 && week[itemName]) {
          item = {
            items: sumBy(week[itemName], "items"),
            all_items: sumBy(week[itemName], "all_items"),
          };
        }
        return (
          <div
            key={`${month}_${index}`}
            className={classnames("week item-count", {
              bordered: index === weeks.length - 1,
            })}
          >
            <div>
              {item && item.items ? item.items : "-"}
              {item && item.all_items ? (
                <span className="all-items-count">({item.all_items})</span>
              ) : null}
            </div>
          </div>
        );
      });
      if (isShowSelectedWeeks && !isRenderAll) {
        weeksElement = [
          <div key={`${month}_all}`} className={"week item-count"}>
            <div></div>
          </div>,
          ...weeksElement,
        ];
        isRenderAll = true;
      }
      return weeksElement;
    });
  };

  return (
    <div
      className={classnames(bem.e("availability-table"), {
        [bem.e("availability-table-total")]: isShowTotal,
      })}
    >
      {isShowRule && (
        <Row className={classnames("m-0 p-0 rules")}>
          <Col xs={3} className="fixed-column p-0">
            <div className="left-column header">
              <FormattedMessage id="process > promotion > liner item availability > relax restrictions for" />
            </div>
          </Col>
          <Col xs={9} className="p-0">
            <div className="weeks p-0 scroll-bar-style">
              <div className={"d-flex"}>
                {renderRules()}
                <div className="week">
                  <div
                    className={"re-calculate"}
                    onClick={() => {
                      if (props.onRecalculate) props.onRecalculate();
                    }}
                  >
                    <ReCalculateIcon />
                    <FormattedMessage id="process > promotion > liner item availability > re-calculate" />
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      )}

      <Row
        className={classnames("quick-view-box m-0 p-0", {
          "vertical-scroll scroll-bar-style": isVerticalScroll,
        })}
      >
        <Col xs={3} className="fixed-column p-0">
          <div
            className={classnames("left-column header", {
              "header-checkbox": get(rules, "length", 0) > 0,
            })}
          >
            {isDistribution ? (
              <FormattedMessage
                id="process > promotion > liner item availability > distribution by"
                values={{
                  selected: upperFirst(distributionText),
                }}
              />
            ) : showSelected === "daypart" ? (
              <FormattedMessage id="process > promotion > liner item availability > daypart" />
            ) : (
              <FormattedMessage id="process > promotion > liner item availability > show" />
            )}
          </div>
          {renderDaypartOrShows()}
        </Col>
        <Col xs={9} className="p-0">
          <div id={elementId} className="weeks p-0 scroll-bar-style">
            <div className={"d-flex week-row"}>
              {isShowSelectedWeeks && renderWeekAll()}
              {renderWeeks()}
            </div>
            {renderAvailability()}
          </div>
        </Col>
      </Row>
    </div>
  );
};
AvailabilityTable.defaultProps = {
  showSelected: "daypart",
};
export default AvailabilityTable;
