import * as Types from "../types/stations";
import { get, uniqBy, map } from "lodash";

const initialState = {
  stations: {
    // This object contains all stations list
    // this is mainly required in stations listing page
    // where we show all stations list and loading icon if stations are loading
    data: [],
    error: null,
    loading: false,
    is_dirty: false,
  },
  station: {
    // This object contains particular station object with id mapping
    // this is mainly required in station details page
    // where we show particular station detail and loading icon if station details is loading
    // below is example object
    // "<id>": {
    //   data: null,
    //   error: null,
    //   loading: null
    // }
  },
  addStation: {
    // This object will be useful to show loading icon and status when user want to create new station
    is_added: false,
    data: null,
    error: null,
    loading: false,
  },
  updateStation: {
    // This object will be useful to show loading icon and status when user want to update station
    data: null,
    error: null,
    loading: false,
  },
  updateStations: {
    error: null,
    loading: false,
  },
  deleteStation: {
    // This object will be useful to show loading icon and status when user want to delete station
    is_deleted: false,
    data: null,
    error: null,
    loading: false,
  },
  stationSettings: {
    loading: false,
    error: null,
    data: null,
  },
  stationW9Forms: {
    loading: false,
    error: null,
    data: null,
  },
  stationAddressVerification: {
    loading: false,
    error: null,
    data: null,
  },
  reminderDays: {
    loading: false,
    error: null,
    data: null,
  },
  fulfillmentSettings: {
    loading: false,
    error: null,
    data: null,
  },
  fulfillmentSetting: {
    loading: false,
    error: null,
    data: null,
  },
  copyFulfillmentSettings: {
    loading: false,
    error: null,
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    // stations Actions
    case Types.FETCH_USER_STATION_REQUEST: {
      return {
        ...state,
        userStations: { data: [], error: null, loading: true },
      };
    }
    case Types.FETCH_USER_STATION_SUCCESS: {
      return {
        ...state,
        userStations: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    }
    case Types.FETCH_USER_STATIONS_ERROR: {
      return {
        ...state,
        userStations: {
          data: null,
          error: action.payload,
          loading: false,
        },
      };
    }

    case Types.FETCH_STATIONS_REQUEST: {
      return {
        ...state,
        stations: {
          data: get(state, "stations.data"),
          error: null,
          loading: true,
          is_dirty: false,
        },
      };
    }
    case Types.FETCH_STATIONS_SUCCESS: {
      return {
        ...state,
        stations: {
          data: action.payload,
          error: null,
          loading: false,
          is_dirty: false,
        },
      };
    }
    case Types.FETCH_STATIONS_ERROR: {
      return {
        ...state,
        stations: {
          data: null,
          error: action.payload,
          loading: false,
          is_dirty: false,
        },
      };
    }

    case Types.FETCH_MORE_STATIONS_REQUEST: {
      return {
        ...state,
        stations: {
          ...state.stations,
          lazyLoad: true,
        },
      };
    }
    case Types.FETCH_MORE_STATIONS_SUCCESS: {
      const { data: newData, ...rest } = action.payload;

      return {
        ...state,

        stations: {
          ...state.stations,
          data: {
            data: uniqBy(
              [...get(state, "stations.data.data", []), ...newData],
              "id"
            ),
            ...rest,
          },
          lazyLoad: false,
        },
      };
    }
    case Types.FETCH_MORE_STATIONS_ERROR: {
      return {
        ...state,
        stations: {
          ...state.stations,
          lazyLoad: false,
        },
      };
    }

    // Particular Station Actions
    case Types.FETCH_STATION_REQUEST: {
      const existingStations = get(state, "station");
      const { id } = action.payload;
      return {
        ...state,
        station: {
          ...existingStations,
          [id]: {
            ...(existingStations[id] || {}),
            loading: true,
            error: null,
          },
        },
      };
    }
    case Types.FETCH_STATION_SUCCESS: {
      const existingStations = get(state, "station");
      const { id, data } = action.payload;
      return {
        ...state,
        station: {
          ...existingStations,
          [id]: {
            loading: false,
            error: null,
            data,
          },
        },
      };
    }
    case Types.FETCH_STATION_ERROR: {
      const existingStations = get(state, "station");
      const { id, error } = action.payload;
      return {
        ...state,
        station: {
          ...existingStations,
          [id]: {
            loading: false,
            error,
          },
        },
      };
    }
    // Update cities groups stations
    case Types.UPDATE_CITIES_GROUPS_STATIONS_REQUEST: {
      return {
        ...state,
        updateStations: {
          loading: true,
          error: null,
        },
      };
    }
    case Types.UPDATE_CITIES_GROUPS_STATIONS_SUCCESS: {
      return {
        ...state,
        updateStations: {
          loading: false,
          error: null,
        },
      };
    }
    case Types.UPDATE_CITIES_GROUPS_STATIONS_ERROR: {
      return {
        ...state,
        updateStations: {
          loading: false,
          error: action.payload,
        },
      };
    }

    // Update privileges stations
    case Types.UPDATE_PRIVILEGES_STATIONS_REQUEST: {
      return {
        ...state,
        updateStations: {
          loading: true,
          error: null,
        },
      };
    }
    case Types.UPDATE_PRIVILEGES_STATIONS_SUCCESS: {
      return {
        ...state,
        updateStations: {
          loading: false,
          error: null,
        },
      };
    }
    case Types.UPDATE_PRIVILEGES_STATIONS_ERROR: {
      return {
        ...state,
        updateStations: {
          loading: false,
          error: action.payload,
        },
      };
    }

    // Add Station Actions
    case Types.ADD_STATION_REQUEST: {
      return {
        ...state,
        addStation: {
          is_added: false,
          loading: true,
          error: null,
        },
      };
    }
    case Types.ADD_STATION_SUCCESS: {
      return {
        ...state,
        stations: {
          ...get(state, "stations"),
          is_dirty: true,
        },
        addStation: {
          is_added: true,
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    }
    case Types.ADD_STATION_ERROR: {
      return {
        ...state,
        addStation: {
          is_added: false,
          loading: false,
          error: action.payload,
        },
      };
    }

    // Update Station Actions
    case Types.UPDATE_STATION_REQUEST: {
      return {
        ...state,
        updateStation: {
          loading: true,
          error: null,
        },
      };
    }
    case Types.UPDATE_STATION_SUCCESS: {
      return {
        ...state,
        stations: {
          ...get(state, "stations"),
          is_dirty: true,
        },
        updateStation: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    }
    case Types.UPDATE_STATION_ERROR: {
      return {
        ...state,
        updateStation: {
          loading: false,
          error: action.payload,
        },
      };
    }

    // DELETE Station Actions
    case Types.DELETE_STATION_REQUEST: {
      return {
        ...state,
        deleteStation: {
          is_deleted: false,
          loading: true,
          error: null,
        },
      };
    }

    case Types.DELETE_STATION_SUCCESS: {
      return {
        ...state,
        stations: {
          ...get(state, "stations"),
          is_dirty: true,
        },
        deleteStation: {
          is_deleted: true,
          loading: false,
          error: null,
        },
      };
    }
    case Types.DELETE_STATION_ERROR: {
      return {
        ...state,
        deleteStation: {
          is_deleted: false,
          loading: false,
          error: action.payload,
        },
      };
    }

    case Types.FETCH_STATION_SETTINGS_REQUEST: {
      return {
        ...state,
        stationSettings: {
          ...state.stationSettings,
          loading: true,
        },
      };
    }
    case Types.FETCH_STATION_SETTINGS_SUCCESS: {
      return {
        ...state,
        stationSettings: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    }
    case Types.FETCH_STATION_SETTINGS_ERROR: {
      return {
        ...state,
        stationSettings: {
          data: null,
          error: action.payload,
          loading: false,
        },
      };
    }
    // Station settings
    case Types.UPDATE_STATION_SETTINGS_REQUEST: {
      return {
        ...state,
        stationSettings: {
          ...state.stationSettings,
          loading: true,
        },
      };
    }
    case Types.UPDATE_STATION_SETTINGS_SUCCESS: {
      return {
        ...state,
        stationSettings: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    }
    case Types.UPDATE_STATION_SETTINGS_ERROR: {
      return {
        ...state,
        stationSettings: {
          data: null,
          error: action.payload,
          loading: false,
        },
      };
    }
    // W9 forms
    case Types.FETCH_STATION_W9_FORMS_REQUEST: {
      return {
        ...state,
        stationW9Forms: {
          ...state.stationW9Forms,
          loading: true,
        },
      };
    }
    case Types.FETCH_STATION_W9_FORMS_SUCCESS: {
      return {
        ...state,
        stationW9Forms: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    }
    case Types.FETCH_STATION_W9_FORMS_ERROR: {
      return {
        ...state,
        stationW9Forms: {
          data: null,
          error: action.payload,
          loading: false,
        },
      };
    }

    case Types.UPDATE_STATION_W9_FORMS_REQUEST: {
      return {
        ...state,
        stationW9Forms: {
          ...state.stationW9Forms,
          loading: true,
        },
      };
    }
    case Types.UPDATE_STATION_W9_FORMS_SUCCESS: {
      return {
        ...state,
        stationW9Forms: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    }
    case Types.UPDATE_STATION_W9_FORMS_ERROR: {
      return {
        ...state,
        stationW9Forms: {
          data: null,
          error: action.payload,
          loading: false,
        },
      };
    }

    // Address verification
    case Types.FETCH_STATION_ADDRESS_VERIFICATION_REQUEST: {
      return {
        ...state,
        stationAddressVerification: {
          ...state.stationAddressVerification,
          loading: true,
        },
      };
    }
    case Types.FETCH_STATION_ADDRESS_VERIFICATION_SUCCESS: {
      return {
        ...state,
        stationAddressVerification: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    }
    case Types.FETCH_STATION_ADDRESS_VERIFICATION_ERROR: {
      return {
        ...state,
        stationAddressVerification: {
          data: null,
          error: action.payload,
          loading: false,
        },
      };
    }

    case Types.UPDATE_STATION_ADDRESS_VERIFICATION_REQUEST: {
      return {
        ...state,
        stationAddressVerification: {
          ...state.stationAddressVerification,
          loading: true,
        },
      };
    }
    case Types.UPDATE_STATION_ADDRESS_VERIFICATION_SUCCESS: {
      return {
        ...state,
        stationAddressVerification: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    }
    case Types.UPDATE_STATION_ADDRESS_VERIFICATION_ERROR: {
      return {
        ...state,
        stationAddressVerification: {
          data: null,
          error: action.payload,
          loading: false,
        },
      };
    }
    // Reminders days
    case Types.FETCH_REMINDER_DAYS_REQUEST: {
      return {
        ...state,
        reminderDays: {
          ...state.reminderDays,
          loading: true,
        },
      };
    }
    case Types.FETCH_REMINDER_DAYS_SUCCESS: {
      return {
        ...state,
        reminderDays: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    }
    case Types.FETCH_REMINDER_DAYS_ERROR: {
      return {
        ...state,
        reminderDays: {
          data: null,
          error: action.payload,
          loading: false,
        },
      };
    }

    case Types.UPDATE_REMINDER_DAYS_REQUEST: {
      return {
        ...state,
        reminderDays: {
          ...state.reminderDays,
          loading: true,
        },
      };
    }
    case Types.UPDATE_REMINDER_DAYS_SUCCESS: {
      return {
        ...state,
        reminderDays: {
          data: {
            ...state.reminderDays,
            ...action.payload,
          },
          error: null,
          loading: false,
        },
      };
    }
    case Types.UPDATE_REMINDER_DAYS_ERROR: {
      return {
        ...state,
        reminderDays: {
          data: null,
          error: action.payload,
          loading: false,
        },
      };
    }

    // Fulfillment settings
    case Types.FETCH_FULFILLMENT_SETTINGS_REQUEST: {
      return {
        ...state,
        fulfillmentSettings: {
          ...state.fulfillmentSettings,
          loading: true,
        },
      };
    }
    case Types.FETCH_FULFILLMENT_SETTINGS_SUCCESS: {
      return {
        ...state,
        fulfillmentSettings: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    }
    case Types.FETCH_FULFILLMENT_SETTINGS_ERROR: {
      return {
        ...state,
        fulfillmentSettings: {
          data: null,
          error: action.payload,
          loading: false,
        },
      };
    }
    case Types.SET_FULFILLMENT_SETTINGS_SUCCESS: {
      return {
        ...state,
        fulfillmentSettings: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    }

    // Fulfillment setting details
    case Types.FETCH_FULFILLMENT_SETTING_REQUEST: {
      return {
        ...state,
        fulfillmentSetting: {
          ...state.fulfillmentSetting,
          loading: true,
        },
      };
    }
    case Types.FETCH_FULFILLMENT_SETTING_SUCCESS: {
      return {
        ...state,
        fulfillmentSetting: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    }
    case Types.FETCH_FULFILLMENT_SETTING_ERROR: {
      return {
        ...state,
        fulfillmentSetting: {
          data: null,
          error: action.payload,
          loading: false,
        },
      };
    }

    case Types.UPDATE_FULFILLMENT_SETTINGS_REQUEST: {
      return {
        ...state,
        fulfillmentSettings: {
          ...state.fulfillmentSettings,
          loading: true,
        },
      };
    }
    case Types.UPDATE_FULFILLMENT_SETTINGS_SUCCESS: {
      return {
        ...state,
        fulfillmentSettings: {
          ...state.fulfillmentSettings,
          error: null,
          loading: false,
        },
      };
    }
    case Types.UPDATE_FULFILLMENT_SETTINGS_ERROR: {
      return {
        ...state,
        fulfillmentSettings: {
          data: null,
          error: action.payload,
          loading: false,
        },
      };
    }
    // activate
    case Types.SET_FULFILLMENT_SETTINGS_ACTIVATE_REQUEST: {
      return {
        ...state,
        fulfillmentSettings: {
          ...state.fulfillmentSettings,
          loading: true,
        },
      };
    }
    case Types.SET_FULFILLMENT_SETTINGS_ACTIVATE_SUCCESS: {
      return {
        ...state,
        fulfillmentSettings: {
          data: map(state.fulfillmentSettings.data, (item) => {
            return {
              ...item,
              active:
                item.id === action.payload.id
                  ? action.payload.active
                  : item.active,
            };
          }),
          error: null,
          loading: false,
        },
      };
    }
    case Types.SET_FULFILLMENT_SETTINGS_ACTIVATE_ERROR: {
      return {
        ...state,
        fulfillmentSettings: {
          ...state.fulfillmentSettings,
          error: action.payload,
          loading: false,
        },
      };
    }
    case Types.COPY_FULFILLMENT_SETTINGS_REQUEST: {
      return {
        ...state,
        copyFulfillmentSettings: {
          ...state.copyFulfillmentSettings,
          loading: true,
        },
      };
    }
    case Types.COPY_FULFILLMENT_SETTINGS_SUCCESS: {
      return {
        ...state,
        copyFulfillmentSettings: {
          error: null,
          loading: false,
        },
      };
    }
    case Types.COPY_FULFILLMENT_SETTINGS_ERROR: {
      return {
        ...state,
        copyFulfillmentSettings: {
          error: action.payload,
          loading: false,
        },
      };
    }
    default:
      return state;
  }
}
