import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import OrderDetailsSchedule from "./view";
import { copyOrder, reschedule } from "store/actions/processes";
import { injectIntl } from "react-intl";
export default injectIntl(
  withRouter(
    connect(
      (state, props) => {
        return {
          auth: state.auth,
          ...props,
        };
      },
      {
        copyOrder,
        reschedule,
      }
    )(OrderDetailsSchedule)
  )
);
