import React from "react";
import { FaSpinner } from "react-icons/lib/fa";
import PropTypes from "prop-types";
import classnames from "classnames";
const View = ({ isLoading, className, size }) =>
  isLoading ? (
    <div className={classnames(className, "cr-spinner-loading")}>
      <FaSpinner size={size} className={"cr-icon-spin"} />
    </div>
  ) : null;
View.propTypes = {
  isLoading: PropTypes.bool,
  size: PropTypes.number,
};
View.defaultProps = {
  isLoading: false,
  size: 40,
};
export default View;
