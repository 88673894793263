import React from "react";
import Table from "components/Table";
import { FormattedMessage } from "react-intl";
import { get, filter, values, find } from "lodash";
import Checkbox from "components/Checkbox";
import { Asterisk } from "components/Elements";
import Dropdown from "components/Dropdown";
import TextInput from "components/TextInput";

const ReleaseNationalSlots = (props) => {
  
  const {
  	intl,
    bem,
  	slots,
  	onChange,
    errors,
  } = props;

  const NoDataComponent = <div className="no-results"></div>;

  let nationalSlots = values(filter(slots, slot => slot.national && slot.label && !slot.deleted));
  let localSlots = values(filter(slots, slot => !slot.national  && slot.label && !slot.deleted));

  const tableColumns = [
    {
      Header: (
      	<div className="header">
      		<FormattedMessage id="clock creator > release slots > slot type" />
      	</div>
      ),
      id: "slot_type",
      Cell: ({ original: slot }) => {
        return (
          <React.Fragment>
            <Checkbox
              checked={slot.release_to_local}
              text={slot.label}
              onChange={(checked) => {
              	onChange({...slots, [slot.id]: {...slot, release_to_local: checked, assign_to: !checked ? null : slot.assign_to, days_before_date: !checked ? null : slot.days_before_date}})
              }}
              disableSelectOnTextClick={true}
              checkStyle={{ color: "#fff", width: "15", height: "14" }}
            />
            
          </React.Fragment>
        );
      },
    },
    {
      Header: (
        <div className="header">
          <FormattedMessage id="clock creator > release slots > assign to" />
          <span className="asterisk"><Asterisk>*</Asterisk></span>
        </div>
      ),
      id: "assign_to",
      Cell: ({ original: slot }) => {
      	if(slot.release_to_local){

          let slotDefinitionErrors = errors.slot_definitions ? errors.slot_definitions[slot.id] : {};

      		return (
	          <Dropdown
	            name="assign_to"
	            placeholder={intl.formatMessage({
	              id: "clock creator > none",
	            })}
	            onChange={(selectedOption) => {
	              onChange({...slots, [slot.id]: {...slot, assign_to: find(localSlots, localSlot => localSlot.guid === selectedOption.value && !localSlot.national)}})
	            }}
	            value={slot.assign_to ? {
                label: get(slot, "assign_to.label"),
                value: get(slot, "assign_to.guid"),
              } : null}
	            options={localSlots.map((localSlot) => ({
	              label: localSlot.label,
	              value: localSlot.guid,
	            }))}
              error={slotDefinitionErrors && slotDefinitionErrors.assign_to}
	            menuPortalTarget={document.body}
              menuShouldBlockScroll={true}
	          />
	        );
	    }
        
        return <div className={bem.e('empty-dash')}>-</div>;
      },
    },
    {
      Header: (
        <div className="header">
          <FormattedMessage id="clock creator > release slots > release inventory" />
          <span className="asterisk"><Asterisk>*</Asterisk></span>
        </div>
      ),
      id: "days_before_date",
      Cell: ({ original: slot }) => {
      	if(slot.release_to_local){

          let slotDefinitionErrors = errors.slot_definitions ? errors.slot_definitions[slot.id] : {};

      		return (
            <React.Fragment>
  	      		<TextInput
  		            type="text"
  		            name="days_before_date"
  		            placeholder="#"
  		            value={get(slot, "days_before_date")}
  		            onChange={(e) => {
                    onChange({...slots, [slot.id]: {...slot, days_before_date: /^\d*$/.test(e.target.value) ? e.target.value : null}})
  		            }}
                  error={slotDefinitionErrors && slotDefinitionErrors.days_before_date}
  		         />
               <div className="days-before-text"><FormattedMessage id="clock creator > release slots > days before date" /></div>
            </React.Fragment>
	      	);
      	}

      	return <div className={bem.e('empty-dash')}>-</div>;
      }
    },
  ];

  return (
  	<div className="table-container">
	    <Table
	      className="slots-list"
	      data={nationalSlots}
	      showPageSizeOptions={false}
	      total={nationalSlots.length}
	      sortable={false}
	      resizable={false}
	      filterable={false}
	      showPagination={false}
	      NoDataComponent={NoDataComponent}
	      columns={tableColumns}
	    />
	</div>
  );
};

export default ReleaseNationalSlots;
