import React from "react";
import { get } from "lodash";
import { FormattedMessage } from "react-intl";
import Checkbox from "components/Checkbox";

export default function VoiceTrackProductionValue(props) {
  const { recordingWithProducer, bem } = props;
  return (
    <div className={bem.e("checkbox-producer")}>
      <Checkbox
        checked={get(
          recordingWithProducer,
          "is_recording_with_producer",
          false
        )}
        text={<FormattedMessage id="process > title recording with producer" />}
        onChange={() => {}}
      />
    </div>
  );
}
