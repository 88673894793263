import React from "react";
import PropTypes from "prop-types";
import { get, first, filter, map, includes, split, sortBy } from "lodash";
import { FaSortAsc, FaSortDesc } from "react-icons/lib/fa";
import { Row, Col, ButtonGroup, Button, Card, CardBody } from "reactstrap";
import { FormattedMessage } from "react-intl";
import Tooltip from "rc-tooltip";
import cx from "classnames";
import Table from "components/Table";
import bn from "utils/bemnames";
import ConfirmationModal from "components/ConfirmationModal";
import StationModal from "components/StationModal";
import PrivilegedComponent from "components/PrivilegedComponent";
import { EyeIcon, EditIcon, TrashListIcon } from "components/CustomIcons";
import ButtonAdd from "components/ButtonAdd";
import SearchBox from "components/SearchBox";
import { PRIVILEGES } from "utils/constants";
import useInfiniteScroll from "utils/useInfiniteScroll";
import { validatePrivileges } from "utils/helpers";
const bem = bn.create("stations-list");

const StationsPage = (props) => {
  const {
    filtered,
    sorted,
    setSorted,
    stations,
    searchText,
    onChangeInput,
    onSearchKeyDown,
    onSearchClicked,
    isLazyLoading,
    onLoadMore,
    isFocused,
    auth,
    intl,
  } = props;
  const privileges = get(auth, "user.privileges", []);
  const filterTagsByType = (tags, type) =>
    filter(tags, (item) => item.type === type)
      .map((item) => item.title)
      .join(", ");
  const stationList = map(get(stations, "data.data") || [], (station) => {
    const tags = get(station, "tags_json", []);
    return {
      ...station,
      market: filterTagsByType(tags, "dmas"),
      format: filterTagsByType(tags, "format"),
    };
  });
  const pages = get(stations, "data.last_page") || 1;
  const total = get(stations, "data.total") || 1;
  // only show delete button and add new button for got admin === 1
  const authIsGodAdmin =
    get(auth, "user.is_god_admin", false) &&
    !get(auth, "user.is_support_admin", false);
  useInfiniteScroll({
    useWindow: false,
    elementId: "cr-app-main",
    onLoadMore: onLoadMore,
    shouldLoadMore:
      isFocused &&
      !isLazyLoading &&
      get(stations, "data.last_page") >= get(stations, "data.current_page"),
  });

  const sortedColumn = first(sorted);
  const onViewClick = (station) => {
    props.onSelectedView({
      [station.id]: station,
    });
  };

  const onEditClick = (station) => {
    props.onSelectedEdit({ [station.id]: station });
    return;
  };

  const onClickItem = (station) => {
    const isEditable = validatePrivileges({
      requires: [PRIVILEGES.EDIT_STATIONS],
      privileges,
      user: auth.user,
    });
    if (isEditable) {
      onEditClick(station);
    }
    const isViewable = validatePrivileges({
      requires: [PRIVILEGES.VIEW_STATIONS],
      privileges,
      user: auth.user,
    });
    if (isViewable) {
      onViewClick(station);
    }
  };
  return (
    <>
      <Card className={bem.b()}>
        <CardBody className="px-0">
          <Row>
            <Col xs="12">
              <div
                className={cx("head-admin-list-wrapper", bem.e("list-padding"))}
              >
                {authIsGodAdmin && (
                  <PrivilegedComponent requires={[PRIVILEGES.ADD_STATIONS]}>
                    <ButtonAdd
                      onClick={props.onToggleStationModal}
                      color="primary"
                      className="btn-radius btn-outline-blue create-station"
                      iconProps={{ color: "#795AFA", width: 19, height: 19 }}
                    >
                      <FormattedMessage id="station > add new station" />
                    </ButtonAdd>
                  </PrivilegedComponent>
                )}
                <SearchBox
                  placeholder={props.intl.formatMessage({
                    id: "station > placeholder search",
                  })}
                  onSearchClicked={onSearchClicked}
                  searchText={searchText}
                  setSearchText={(text) =>
                    onChangeInput({
                      target: {
                        name: "q",
                        value: text,
                      },
                    })
                  }
                  onKeyDown={onSearchKeyDown}
                />
              </div>
            </Col>
          </Row>
          <Table
            className="table-hidden-checkbox admin-table clocks-table"
            manual
            data={stationList}
            pages={pages}
            loading={stations.loading}
            total={total}
            showPagination={false}
            // Controlled props
            filtered={filtered}
            sorted={sorted}
            // Callbacks
            onSortedChange={(sorted) => {
              setSorted(sorted);
            }}
            getTdProps={(state, rowInfo, column, instance) => {
              return {
                onClick: (e, handleOriginal) => {
                  const { original } = rowInfo;
                  const columnId = column.id;
                  if (!includes(["actions"], columnId)) {
                    onClickItem(original);
                  } else if (handleOriginal) {
                    handleOriginal();
                  }
                },
              };
            }}
            columns={[
              {
                Header: (
                  <div className={cx("th-sort", bem.e("list-left-padding"))}>
                    <FormattedMessage id="station > market" />
                    <div className="sortable">
                      <FaSortAsc
                        className={cx("sortable-icon", {
                          "sortable-active":
                            sortedColumn.id === "market" && !sortedColumn.desc,
                        })}
                      />
                      <FaSortDesc
                        className={cx("sortable-icon", {
                          "sortable-active":
                            sortedColumn.id === "market" && sortedColumn.desc,
                        })}
                      />
                    </div>
                  </div>
                ),
                Cell: ({ value }) => {
                  return (
                    <Tooltip
                      placement="top"
                      trigger={["click", "hover"]}
                      overlayClassName={bem.e("tooltip")}
                      overlay={<div className={"market-string"}>{value}</div>}
                    >
                      <div className={"market-string"}>{value}</div>
                    </Tooltip>
                  );
                },
                accessor: "market",
              },
              {
                Header: (
                  <div className={cx("th-sort")}>
                    <FormattedMessage id="station > format" />
                    <div className="sortable">
                      <FaSortAsc
                        className={cx("sortable-icon", {
                          "sortable-active":
                            sortedColumn.id === "format_tag" &&
                            !sortedColumn.desc,
                        })}
                      />
                      <FaSortDesc
                        className={cx("sortable-icon", {
                          "sortable-active":
                            sortedColumn.id === "format_tag" &&
                            sortedColumn.desc,
                        })}
                      />
                    </div>
                  </div>
                ),
                Cell: ({ value }) => {
                  return (
                    <Tooltip
                      placement="top"
                      trigger={["click", "hover"]}
                      overlayClassName={bem.e("tooltip")}
                      overlay={<div className={"market-string"}>{value}</div>}
                    >
                      <div className={"market-string"}>{value}</div>
                    </Tooltip>
                  );
                },
                accessor: "format_tag",
              },
              {
                Header: (
                  <div className={cx("th-sort")}>
                    <FormattedMessage id="station > stations" />
                    <div className="sortable">
                      <FaSortAsc
                        className={cx("sortable-icon", {
                          "sortable-active":
                            sortedColumn.id === "name" && !sortedColumn.desc,
                        })}
                      />
                      <FaSortDesc
                        className={cx("sortable-icon", {
                          "sortable-active":
                            sortedColumn.id === "name" && sortedColumn.desc,
                        })}
                      />
                    </div>
                  </div>
                ),
                Cell: ({ original: station }) => {
                  const array = [];
                  if (station.call_letters) {
                    array.push(station.call_letters);
                  }
                  if (station.name) {
                    array.push(station.name);
                  }
                  const name = array.join(" - ");
                  return (
                    <Tooltip
                      placement="topLeft"
                      trigger={["click", "hover"]}
                      overlayClassName={bem.e("tooltip")}
                      overlay={
                        <div className={"market-string text-caps"}>{name}</div>
                      }
                    >
                      <div className={"market-string text-caps"}>{name}</div>
                    </Tooltip>
                  );
                },
                accessor: "name",
              },
              {
                Header: <FormattedMessage id="station > products" />,
                sortable: false,
                Cell: ({ original: station }) => {
                  const productsArr = split(station.products_string, ", ")
                    .filter((text) => text)
                    .map((text) =>
                      intl
                        .formatMessage({ id: `station > title ${text}` })
                        .toLowerCase()
                    );
                  const productsString = sortBy(productsArr).join(", ");
                  return (
                    <Tooltip
                      placement="top"
                      trigger={["click", "hover"]}
                      overlayClassName={bem.e("tooltip")}
                      overlay={
                        <div className={cx("product-string")}>
                          {productsString}
                        </div>
                      }
                    >
                      <div className={cx("product-string")}>
                        {productsString}
                      </div>
                    </Tooltip>
                  );
                },
              },
              {
                Header: <FormattedMessage id="station > Actions" />,
                sortable: false,
                resizable: false,
                filterable: false,
                id: "actions",
                style: { justifyContent: "center", textAlign: "center" },
                Cell: ({ original: station }) => {
                  return (
                    <ButtonGroup>
                      {/* View Button */}
                      <PrivilegedComponent
                        requires={[PRIVILEGES.VIEW_STATIONS]}
                      >
                        <Button
                          color="link"
                          onClick={() => {
                            onViewClick(station);
                          }}
                        >
                          <EyeIcon />
                        </Button>
                      </PrivilegedComponent>
                      {/* Edit Button */}
                      <PrivilegedComponent
                        requires={[PRIVILEGES.EDIT_STATIONS]}
                      >
                        <Button
                          color="link"
                          onClick={() => {
                            onEditClick(station);
                          }}
                        >
                          <EditIcon />
                        </Button>
                      </PrivilegedComponent>
                      {/* delete button */}
                      {authIsGodAdmin && (
                        <PrivilegedComponent
                          requires={[PRIVILEGES.DELETE_STATIONS]}
                        >
                          <Button
                            color="link"
                            onClick={() => {
                              props.openDeleteModal(station.id);
                            }}
                          >
                            <TrashListIcon />
                          </Button>
                        </PrivilegedComponent>
                      )}
                    </ButtonGroup>
                  );
                },
              },
            ]}
          />
        </CardBody>
      </Card>
      <ConfirmationModal
        isOpen={props.isDeleteModalOpen}
        title={props.intl.formatMessage({
          id: "station > are you sure you want to remove station",
        })}
        onConfirm={props.onDelete}
        onToggle={props.closeDeleteModal}
        onCancel={props.closeDeleteModal}
        isCloseOutside={false}
      />
      <StationModal
        isOpen={props.isStationModalOpen}
        onToggle={props.onToggleStationModal}
      />
    </>
  );
};

StationsPage.defaultProps = {
  onSelectedEdit: () => {},
  onSelectedView: () => {},
  onSelectedAdd: () => {},
};
StationsPage.propsType = {
  onSelectedEdit: PropTypes.func,
  onSelectedView: PropTypes.func,
  onSelectedAdd: PropTypes.func,
};
export default StationsPage;
