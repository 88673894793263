import React from "react";
import { mapValues, get, replace, filter, xorBy, forEach, find } from "lodash";
import SimpleLineText from "components/FormBuilder/SimpleLineText";
import TextArea from "components/FormBuilder/TextArea";
import Dropdown from "components/FormBuilder/Dropdown";
import Checkbox from "components/FormBuilder/Checkbox";
import Gender from "components/FormBuilder/Gender";
import DateSelector from "components/FormBuilder/DateSelector";
import EmailAddress from "components/FormBuilder/EmailAddress";
import BasicFileUpload from "components/FormBuilder/BasicFileUpload";
import SectionHeader from "components/FormBuilder/SectionHeader";
import HomePhone from "components/FormBuilder/HomePhone";
import Radio from "components/FormBuilder/Radio";
import PhoneNumber from "components/FormBuilder/PhoneNumber";
import DateOfBirth from "components/FormBuilder/DateOfBirth";
import SocialSecurity from "components/FormBuilder/SocialSecurity";
import DriversLicense from "components/FormBuilder/DriversLicense";
import InstagramLink from "components/FormBuilder/InstagramLink";
import TikTokHandle from "components/FormBuilder/TikTokHandle";
import TwitterHandle from "components/FormBuilder/TwitterHandle";
import WinnerNote from "components/FormBuilder/WinnerNote";
import GroupHomeAddress from "components/FormBuilder/GroupHomeAddress";
import Ethnicity from "components/FormBuilder/Ethnicity";
import MaritalStatus from "components/FormBuilder/MaritalStatus";
import Education from "components/FormBuilder/Education";
import HouseholdIncome from "components/FormBuilder/HouseholdIncome";
import HomeOwnership from "components/FormBuilder/HomeOwnership";
import NumberOfChildren from "components/FormBuilder/NumberOfChildren";
import BusinessName from "components/FormBuilder/BusinessName";
import TaxId from "components/FormBuilder/TaxId";
import BusinessPhoneAndExtension from "components/FormBuilder/BusinessPhoneAndExtension";
import GroupBusinessAddress from "components/FormBuilder/GroupBusinessAddress";
import Separator from "components/FormBuilder/Separator";
import HelperText from "components/FormBuilder/HelperText";
import FirstNameAndLastName from "components/FormBuilder/FirstNameAndLastName";
import SingleAddress from "components/FormBuilder/SingleAddress";
import City from "components/FormBuilder/City";
import PostalCode from "components/FormBuilder/PostalCode";
import StateProvince from "components/FormBuilder/StateProvince";
import County from "components/FormBuilder/County";
import Country from "components/FormBuilder/Country";
import DriversLicensePhoto from "components/FormBuilder/DriversLicensePhoto";
import CurrentJob from "components/FormBuilder/CurrentJob";
import PerPreference from "components/FormBuilder/PerPreference";
import CollegeAlmaMater from "components/FormBuilder/CollegeAlmaMater";
import { getFieldName } from "components/FormBuilder/helper";
function renderCustomFields(
  systemForm,
  { formProps, currentServer, isRenderForm, isFulfillment, isConsoleForm }
) {
  let formFields = [];
  // Filter remove the fields with is_hidden = true to display on preview form
  if (isRenderForm) {
    forEach(get(systemForm, "_source.fields", []), (field) => {
      if (field.sub_fields && field.sub_fields.length > 0) {
        let validSubFields = filter(
          field.sub_fields,
          (item) => !item.is_hidden
        );
        if (validSubFields.length > 0) {
          formFields.push({
            ...field,
            sub_fields: validSubFields,
          });
        }
      } else if (!field.is_hidden) {
        formFields.push(field);
      }
    });
  } else {
    formFields = get(systemForm, "_source.fields", []).filter(
      (field) => !field.is_hidden
    );
  }
  let totalPhoneField = 0;
  forEach(formFields, (field) => {
    if (!field.sub_fields || field.sub_fields.length === 0) {
      if (field.key.indexOf("phone") !== -1) {
        totalPhoneField += 1;
      }
    }
  });
  const isShowPrimaryPhone = totalPhoneField > 1 ? true : false;

  const values = formProps.values;
  const serverCountries = [
    currentServer.country,
    ...currentServer.neighboring_countries,
  ];
  const fieldsEle = formFields.map((field, index) => {
    const { key } = field;
    const fieldName = getFieldName(field);

    const errors = mapValues(get(formProps, `errors`, {}), (value) => {
      return replace(value, new RegExp("_", "g"), " ");
    });
    let fieldProps = {
      key: index,
      field,
      value: values[fieldName],
      values,
      errors,
      touched: get(formProps, `touched`, {}),
      onChange: (value, changeProps = null) => {
        let fieldChanging = field;
        // for case when user is changing a group
        if (changeProps && changeProps.field) fieldChanging = changeProps.field;
        const fieldName = getFieldName(fieldChanging);

        if (fieldChanging.key === "checkbox") {
          let currentValue = filter(
            values[fieldName],
            (item) => item && item.value
          );
          value = xorBy(currentValue, [value], "value");
        }
        if (fieldChanging.key === "dropdown" || fieldChanging.key === "radio") {
          value = get(value, "value", "");
        }

        formProps.setFieldValue(fieldName, value);
        formProps.setFieldTouched(fieldName);
      },
      isFormBuilder: false,
      disabled: false,
      isRenderRow: false,
      isRenderFieldNameFromLabel: false,
    };
    let fieldElement;
    switch (key) {
      case "first_last_name":
        fieldProps = {
          ...fieldProps,
          field: {
            ...fieldProps.field,
            sub_fields: fieldProps.field.sub_fields.map((i) => ({
              ...i,
              label: `${field.label} ${i.label}`,
            })),
          },
        };
        fieldElement = <FirstNameAndLastName {...fieldProps} />;
        break;
      case "separator":
        fieldElement = <Separator {...fieldProps} />;
        break;
      case "helper_text":
        fieldElement = <HelperText {...fieldProps} />;
        break;
      case "single_line_text":
        fieldElement = <SimpleLineText {...fieldProps} />;
        break;
      case "textarea":
        fieldElement = <TextArea {...fieldProps} />;
        break;
      case "dropdown":
        fieldElement = (
          <Dropdown {...fieldProps} isAllowConditionLogic={false} />
        );
        break;
      case "checkbox":
        fieldElement = (
          <Checkbox {...fieldProps} isAllowConditionLogic={false} />
        );
        break;
      case "radio":
        fieldElement = <Radio {...fieldProps} isAllowConditionLogic={false} />;
        break;
      case "date_selector":
        fieldElement = <DateSelector {...fieldProps} />;
        break;
      case "email_address":
        fieldElement = (
          <EmailAddress
            {...fieldProps}
            allowDelete={false}
            isShowPrimary
            isPrimaryValueAsFieldName={true}
          />
        );
        break;
      case "phone_number":
        fieldElement = (
          <PhoneNumber
            {...fieldProps}
            allowDelete={false}
            isShowPrimary={isConsoleForm || isShowPrimaryPhone}
            isPrimaryValueAsFieldName={true}
          />
        );
        break;
      case "basic_file_upload":
        fieldElement = <BasicFileUpload {...fieldProps} />;
        break;
      case "section_header":
        fieldElement = <SectionHeader {...fieldProps} />;
        break;
      case "home_phone":
        fieldElement = (
          <HomePhone
            {...fieldProps}
            isShowPrimary={isShowPrimaryPhone}
            isPrimaryValueAsFieldName={true}
          />
        );
        break;
      case "gender":
        fieldElement = <Gender {...fieldProps} />;
        break;
      case "date_of_birth":
        fieldElement = <DateOfBirth {...fieldProps} allowDelete={false} />;
        break;
      case "social_security":
        fieldElement = <SocialSecurity {...fieldProps} allowDelete={false} />;
        break;
      case "drivers_license_number":
        fieldElement = <DriversLicense {...fieldProps} allowDelete={false} />;
        break;
      case "drivers_license_photo":
        fieldElement = <DriversLicensePhoto {...fieldProps} />;
        break;
      case "instagram_link":
        fieldElement = <InstagramLink {...fieldProps} />;
        break;
      case "tiktok_handle":
        fieldElement = <TikTokHandle {...fieldProps} />;
        break;
      case "twitter_handle":
        fieldElement = <TwitterHandle {...fieldProps} />;
        break;
      case "winner_note":
        fieldElement = <WinnerNote {...fieldProps} />;
        break;
      case "home_address_1":
        fieldElement = <SingleAddress {...fieldProps} />;
        break;
      case "home_address_2":
        fieldElement = <SingleAddress {...fieldProps} />;
        break;
      case "business_address_1":
        fieldElement = <SingleAddress {...fieldProps} />;
        break;
      case "business_address_2":
        fieldElement = <SingleAddress {...fieldProps} />;
        break;
      case "business_city":
        fieldElement = <City {...fieldProps} />;
        break;
      case "home_city":
        fieldElement = <City {...fieldProps} />;
        break;
      case "business_zip_postal_code":
        fieldElement = <PostalCode {...fieldProps} />;
        break;
      case "home_zip_postal_code":
        fieldElement = <PostalCode {...fieldProps} />;
        break;
      case "business_state_province":
        fieldElement = (
          <StateProvince
            {...fieldProps}
            serverCountries={serverCountries || []}
          />
        );
        break;
      case "home_state_province":
        fieldElement = (
          <StateProvince
            {...fieldProps}
            serverCountries={serverCountries || []}
          />
        );
        break;
      case "home_county":
        fieldElement = <County {...fieldProps} />;
        break;
      case "business_county":
        fieldElement = <County {...fieldProps} />;
        break;
      case "home_country":
        fieldElement = (
          <Country {...fieldProps} serverCountries={serverCountries || []} />
        );
        break;
      case "business_country":
        fieldElement = (
          <Country {...fieldProps} serverCountries={serverCountries || []} />
        );
        break;
      case "home_address":
        fieldProps = {
          ...fieldProps,
          field: {
            ...fieldProps.field,
            sub_fields: fieldProps.field.sub_fields
              .filter((i) => !i.is_hidden)
              .map((i) => ({
                ...i,
                label: `${field.label} ${i.label}`,
                placeholder: i.placeholder,
              })),
          },
        };
        fieldElement = (
          <GroupHomeAddress
            {...fieldProps}
            serverCountries={serverCountries || []}
            isFulfillment={isFulfillment}
          />
        );
        break;
      case "business_address":
        fieldProps = {
          ...fieldProps,
          field: {
            ...fieldProps.field,
            sub_fields: fieldProps.field.sub_fields
              .filter((i) => !i.is_hidden)
              .map((i) => ({
                ...i,
                label: `${field.label} ${i.label}`,
                placeholder: i.placeholder, // add placeholder as label to show on preview form
              })),
          },
        };
        fieldElement = (
          <GroupBusinessAddress
            {...fieldProps}
            serverCountries={serverCountries || []}
            isFulfillment={isFulfillment}
          />
        );
        break;
      case "ethnicity":
        fieldElement = <Ethnicity {...fieldProps} />;
        break;
      case "marital_status":
        fieldElement = <MaritalStatus {...fieldProps} />;
        break;
      case "number_of_children":
        fieldElement = <NumberOfChildren {...fieldProps} />;
        break;
      case "education_level":
        fieldElement = <Education {...fieldProps} />;
        break;
      case "household_income":
        fieldElement = <HouseholdIncome {...fieldProps} />;
        break;
      case "home_ownership":
        fieldElement = <HomeOwnership {...fieldProps} />;
        break;
      case "business_name":
        fieldElement = <BusinessName {...fieldProps} />;
        break;
      case "business_phone_and_extension":
        fieldElement = (
          <BusinessPhoneAndExtension
            {...fieldProps}
            isShowPrimary={isShowPrimaryPhone}
            isPrimaryValueAsFieldName={true}
          />
        );
        break;
      case "tax_id_number":
        fieldElement = <TaxId {...fieldProps} />;
        break;
      case "current_job":
        fieldElement = <CurrentJob {...fieldProps} />;
        break;
      case "college_alma_mater":
        fieldElement = <CollegeAlmaMater {...fieldProps} />;
        break;
      case "per_preference":
        fieldElement = <PerPreference {...fieldProps} />;
        break;
      default:
        break;
    }
    return fieldElement;
  });
  return fieldsEle;
}
export default renderCustomFields;

export function getFormFields(systemForm) {
  let formFields = [];
  const fields = get(systemForm, "_source.fields", []);
  forEach(fields, (field) => {
    if (field.multi_field) {
      formFields = [
        ...formFields,
        ...field.sub_fields.map((i) => {
          return {
            ...i,
            label: `${field.label} ${i.label}`, // add parent label to each subfield to ensure unique label
          };
        }),
      ];
    } else if (
      [
        "email_address",
        "mobile_phone",
        "home_phone",
        "business_phone_and_extension",
      ].indexOf(field.key) !== -1
    ) {
      // add sub field for checkbox is primary
      formFields = [
        ...formFields,
        field,
        {
          value: false,
          label: `${field.label} is primary`,
          placeholder: null,
          key: "is_primary",
          multi_field: false,
          sub_fields: [],
        },
      ];
      if (field.key === "business_phone_and_extension") {
        formFields = [
          ...formFields,
          field,
          {
            value: "",
            label: `${field.label} extension`,
            placeholder: null,
            key: "extension",
            multi_field: false,
            sub_fields: [],
          },
        ];
      }
    } else {
      formFields.push(field);
    }
  });
  let finalFormFields = filter(formFields, (field) => !field.is_hidden);
  return finalFormFields;
}
export function getInitialValues(fields, systemFormSaved = {}, extraData = {}) {
  let initialValues = {};
  fields.forEach((field) => {
    const { key } = field;
    const fieldName = getFieldName(field);
    let defaultValue = get(systemFormSaved, fieldName, "");
    switch (key) {
      case "home_country":
      case "business_country":
        defaultValue = get(extraData, "countries.0", "");
        break;
      case "social_security":
        defaultValue = {
          number1: "",
          number2: "",
          number3: "",
        };
        break;
      case "checkbox":
        if (!defaultValue) {
          defaultValue = [];
          const defaultOption = find(
            field.options,
            (option) => option.is_default
          );
          if (defaultOption) {
            defaultValue = [
              {
                value: defaultOption.value,
                label: defaultOption.label,
              },
            ];
          }
        }
        break;
      case "dropdown":
      case "radio":
        if (!defaultValue) {
          const defaultOption = find(
            field.options,
            (option) => option.is_default
          );
          if (defaultOption) {
            defaultValue = defaultOption.value;
          }
        }
        break;
      default:
        break;
    }
    initialValues[fieldName] = defaultValue;
  });
  return initialValues;
}
