import * as React from "react";
import {
  Row,
  Col,
  Button,
  Form
} from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { get, find } from 'lodash';
import { FormattedMessage } from "react-intl";
import { injectIntl } from "react-intl";
import {
  Asterisk,
  DivView
} from "components/Elements";
import Dropdown from "components/Dropdown";
import TextInput from "components/TextInput";
import timeOptions from 'assets/data/time-options.json';

function greaterThan(ref, msg) {
  return Yup.mixed().test({
    name: 'equalTo',
    exclusive: false,
    message: msg || '${path} must be greater than ${reference}',
    params: {
      reference: ref.path,
    },
    test: function(value) {
      console.log(value, 'vaule', this.resolve(ref))
      return parseInt(value) > parseInt(this.resolve(ref));
    },
  });
}
Yup.addMethod(Yup.string, 'greaterThan', greaterThan);


const CheckAvailabilityForm = ({onFormSubmit, isDefault, intl}) => (
  <Formik
          enableReinitialize
          validationSchema={Yup.object().shape({
            shift_name: Yup.string()
            .required(intl.formatMessage({id: "validate > title is required"})),
            start_time: Yup.string()
            .required(intl.formatMessage({id: "validate > start time is required"})),
            end_time: Yup.string()
            .required(intl.formatMessage({id: "validate > end time is required"}))
            .greaterThan(Yup.ref('start_time'), intl.formatMessage({id: "validate > end time is not valid"})),
          })}
          onSubmit={onFormSubmit}>
          {formProps => {
              const errors = formProps.errors;
              // const touched = formProps.touched;
              const values = formProps.values
              return (
              // <Form onSubmit={formProps.handleSubmit}>
        <Row>
        <Col>
        { isDefault ? <DivView>Open Mic Night</DivView>:
        <div>
          <TextInput
          label={<span><FormattedMessage id="staff > shift" /><Asterisk>*</Asterisk></span>}
          name="shift_name"
          onChange={formProps.handleChange}
          onBlur={formProps.handleBlur}
        /> 
          {errors.shift_name && <div className="alert-danger">{errors.shift_name}</div>}</div>
        }
        </Col>
        <Col>
            <Dropdown 
              label={<span><FormattedMessage id="staff > shift start time" /><Asterisk>*</Asterisk></span>}
              name="start_time"
              options={timeOptions}
              onChange={selectedOption => {
                formProps.setFieldValue(
                    "start_time",
                    selectedOption.value
                );
              }}
            />
            {!!errors.start_time && <div className="alert-danger">{errors.start_time}</div>}
        </Col>
        <Col>
            <Dropdown 
              label={<span><FormattedMessage id="staff > shift end time" /><Asterisk>*</Asterisk></span>}
              name="end_time"
              options={timeOptions}
              onChange={selectedOption => {
                formProps.setFieldValue(
                    "end_time",
                    selectedOption.value
                );
              }}
            />
            {!!errors.end_time && <div className="alert-danger">{errors.end_time}</div>}
        </Col>
        <Col>
        <DivView
        >
        <Button
          type="submit"
          outline
          style={{
            marginTop: 35,
          }}
        > <FormattedMessage id="staff > check availability" /> </Button>
        </DivView>
        </Col>
        </Row>
        // </Form>
              )}}
          </Formik>
)

export default injectIntl(CheckAvailabilityForm)