import React from "react";
import Select, { components } from "react-select";
import { FormattedMessage } from "react-intl";
import Avatar from "../Avatar";

const gaugeBgStyle = {
  position: "absolute",
  backgroundColor: "#eee",
  border: "1px solid #999",
  width: "200px",
  height: "10px",
  marginTop: "-2px",
  borderRadius: "4px",
  textAlign: "center"
};
const gaugeColorStyle = {
  height: "10px",
  position: "absolute",
  marginTop: "-2px",
  borderRadius: "4px",
  transition: "all .2s ease-out"
};
const gaugeDotStyle = {
  position: "absolute",
  marginTop: "-10px",
  color: "#222",
  display: "block",
  textAlign: "center",
  backgroundColor: "white",
  borderRadius: "50%",
  height: "25px",
  width: "25px",
  border: "1px solid #999"
};
const Option = props => {
  const action_items = props.data.info.availability.action_items;
  const upcoming_items = props.data.info.availability.upcoming_items * 5;
  return (
    <div style={{ width: "390px" }}>
      <components.Option {...props}>
        <Avatar src={props.data.info.avatar_url} size={40} className="mb-2" />
        {` `}
        {props.data.label}
        <br />
        <table>
          <tbody>
            <tr>
              <td style={{ width: "160px", height: "30px" }}>
                <FormattedMessage id="dropdown > action items" />:
              </td>
              <td>
                <div style={gaugeBgStyle} />
                <div
                  style={{
                    ...gaugeColorStyle,
                    backgroundColor:
                      action_items > 5
                        ? "#ff2e00"
                        : action_items > 2
                        ? "#ffbf00"
                        : "#85cc00",
                    width: `${Math.min(200, action_items * 20)}px`
                  }}
                />
                <span
                  style={{
                    ...gaugeDotStyle,
                    marginLeft: `${Math.min(180, action_items * 20 - 10)}px`
                  }}
                >
                  {action_items}
                </span>
              </td>
            </tr>
            <tr>
              <td style={{ width: "160px", height: "30px" }}>
                <FormattedMessage id="dropdown > upcoming items" />:
              </td>
              <td>
                <div style={gaugeBgStyle} />
                <div
                  style={{
                    ...gaugeColorStyle,
                    backgroundColor:
                      upcoming_items > 5
                        ? "#ff2e00"
                        : upcoming_items > 2
                        ? "#ffbf00"
                        : "#85cc00",
                    width: `${Math.min(200, upcoming_items * 20)}px`
                  }}
                />
                <span
                  style={{
                    ...gaugeDotStyle,
                    marginLeft: `${Math.min(180, upcoming_items * 20 - 10)}px`
                  }}
                >
                  {upcoming_items}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </components.Option>
    </div>
  );
};

const Control = props => (
  <div>
    <components.Control {...props} />
  </div>
);

const DropdownCustomOption = props => {
  if (props.disabled) {
    return (
      <span>
        <br />
        <span>{props.value && props.value.label}</span>
      </span>
    );
  }
  return (
    <Select
      {...props}
      className="react-select-container"
      classNamePrefix="react-select"
      value={props.value ? props.value : null}
      options={props.options ? props.options : []}
      styles={{
        menu: provided => ({
          ...provided,
          width: "auto"
        })
      }}
      components={{
        Option,
        Control
      }}
    />
  );
};
export default DropdownCustomOption;
