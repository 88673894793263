import React, { Component } from "react";
import SortableList from "../SortableList";
import { omit } from "lodash";
export default class SortableComponent extends Component {
  render() {
    const { onSortEnd, channels } = this.props;
    return (
      <SortableList
        items={channels}
        onSortEnd={onSortEnd}
        useWindowAsScrollContainer={true}
        distance={30}
        shouldCancelStart={(event) => {
          let isCanStart = false;
          const { path, composedPath } = event;
          const paths = path || (composedPath && event.composedPath()) || [];
          paths.forEach((item) => {
            if (
              item.classList &&
              item.classList.contains("cr-channel-manager__channel") &&
              !item.classList.contains("cr-channel-manager__channel-open-form")
            ) {
              isCanStart = true;
            }
          });
          return !isCanStart;
        }}
        {...omit(this.props, ["onSortEnd", "list"])}
      />
    );
  }
}
