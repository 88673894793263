import * as Types from "store/types/programmings";
import { LOGOUT_USER } from "store/types/auth";
import { get, map } from "lodash";
const initialState = {
  itemsAvailability: {
    data: null,
    error: null,
    loading: false,
  },
  scheduleAvailability: {
    data: null,
    error: null,
    loading: false,
  },
  clocksData: {
    data: null,
    error: null,
    loading: false,
  },
  inventory: {
    data: null,
    error: null,
    loading: false,
  },
  schedules: {
    data: {},
    error: null,
    loading: false,
  },
  search: {
    data: null,
    error: null,
    loading: false,
  },
  swap: {
    error: null,
    loading: false,
  },
  swappableSlots: {
    data: {},
    error: null,
    loading: false,
  },
  schedulesLog: {
    data: [],
    error: null,
    loading: false,
  },
  ordersList: {
    data: [],
    error: null,
    loading: false,
  },
  fillSettings: {
    data: {},
    error: null,
    loading: false,
  },
  fillScheduling: {
    data: null,
    error: null,
    loading: false,
  },
  associateElements: {
    data: null,
    error: null,
    loading: false,
    is_dirty: false,
  },
  quickView: {
    data: null,
    error: null,
    loading: false,
    is_dirty: false,
  },
  contestEndEarly: {
    error: null,
    loading: false,
  },
  deleteSlot: {
    error: null,
    loading: false,
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_ITEMS_AVAILABILITY_REQUEST:
      return {
        ...state,
        itemsAvailability: {
          ...state.itemsAvailability,
          error: null,
          loading: true,
        },
      };
    case Types.FETCH_ITEMS_AVAILABILITY_SUCCESS:
      if (action.loadMore) {
        const data = { ...state.itemsAvailability.data };
        const payloadData = action.payload;
        data.total_count_dayparts_availabilty +=
          payloadData.total_count_dayparts_availabilty;
        data.total_count_show_availabilty +=
          payloadData.total_count_show_availabilty;
        map(payloadData.availability, (__, month) => {
          if (data.availability[month]) {
            payloadData.availability[month] = [
              ...data.availability[month],
              ...payloadData.availability[month],
            ];
          }
        });
        data.availability = {
          ...data.availability,
          ...payloadData.availability,
        };
        return {
          ...state,
          itemsAvailability: {
            data,
            error: null,
            loading: false,
          },
        };
      }
      return {
        ...state,
        itemsAvailability: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    case Types.FETCH_ITEMS_AVAILABILITY_ERROR:
      return {
        ...state,
        itemsAvailability: {
          ...state.itemsAvailability,
          error: action.payload,
          loading: false,
        },
      };
    case Types.CHECK_SCHEDULING_AVAILABILITY_REQUEST:
      return {
        ...state,
        scheduleAvailability: {
          ...state.scheduleAvailability,
          isChecked: false,
          error: null,
          loading: true,
        },
      };
    case Types.CHECK_SCHEDULING_AVAILABILITY_SUCCESS:
      return {
        ...state,
        scheduleAvailability: {
          data: action.payload,
          isChecked: true,
          error: null,
          loading: false,
        },
      };
    case Types.CHECK_SCHEDULING_AVAILABILITY_ERROR:
      return {
        ...state,
        scheduleAvailability: {
          ...state.scheduleAvailability,
          error: action.payload,
          loading: false,
          isChecked: false,
        },
      };
    case Types.CHECK_SCHEDULING_AVAILABILITY_RESET:
      return {
        ...state,
        scheduleAvailability: {
          ...state.scheduleAvailability,
          isChecked: false,
        },
      };
    case Types.FETCH_CLOCKS_DATA_REQUEST:
      return {
        ...state,
        clocksData: {
          ...state.clocksData,
          error: null,
          loading: true,
        },
      };
    case Types.FETCH_CLOCKS_DATA_SUCCESS:
      return {
        ...state,
        clocksData: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    case Types.FETCH_CLOCKS_DATA_ERROR:
      return {
        ...state,
        clocksData: {
          ...state.clocksData,
          error: action.payload,
          loading: false,
        },
      };
    case Types.PROGRAMMINGS_FIND_INVENTORY_REQUEST:
      return {
        ...state,
        inventory: {
          ...state.inventory,
          error: null,
          loading: true,
        },
      };
    case Types.PROGRAMMINGS_FIND_INVENTORY_SUCCESS:
      return {
        ...state,
        inventory: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    case Types.PROGRAMMINGS_FIND_INVENTORY_ERROR:
      return {
        ...state,
        inventory: {
          ...state.clocksData,
          error: action.payload,
          loading: false,
        },
      };
    case Types.PROGRAMMINGS_SAVE_INVENTORY_REQUEST:
      return {
        ...state,
        inventory: {
          ...state.inventory,
          error: null,
          loading: true,
        },
      };
    case Types.PROGRAMMINGS_SAVE_INVENTORY_SUCCESS:
      return {
        ...state,
        inventory: {
          ...state.inventory,
          error: null,
          loading: false,
        },
      };
    case Types.PROGRAMMINGS_SAVE_INVENTORY_ERROR:
      return {
        ...state,
        inventory: {
          ...state.inventory,
          error: action.payload,
          loading: false,
        },
      };
    case Types.PROGRAMMINGS_RELEASE_INVENTORY_REQUEST:
      return {
        ...state,
        inventory: {
          ...state.inventory,
          error: null,
          loading: true,
        },
      };
    case Types.PROGRAMMINGS_RELEASE_INVENTORY_SUCCESS:
      return {
        ...state,
        inventory: {
          ...state.inventory,
          error: null,
          loading: false,
        },
      };
    case Types.PROGRAMMINGS_RELEASE_INVENTORY_ERROR:
      return {
        ...state,
        inventory: {
          ...state.inventory,
          error: action.payload,
          loading: false,
        },
      };
    case Types.PROGRAMMINGS_SET_SCHEDULING_AVAILABILITY_REQUEST:
      return {
        ...state,
        scheduleAvailability: {
          ...state.scheduleAvailability,
          error: null,
          loading: true,
        },
      };
    case Types.PROGRAMMINGS_SET_SCHEDULING_AVAILABILITY_SUCCESS:
      return {
        ...state,
        scheduleAvailability: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    case Types.PROGRAMMINGS_SET_SCHEDULING_AVAILABILITY_ERROR:
      return {
        ...state,
        scheduleAvailability: {
          ...state.scheduleAvailability,
          error: action.payload,
          loading: false,
        },
      };
    case Types.SET_CLOCKS_DATA:
      return {
        ...state,
        clocksData: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    case Types.PROGRAMMINGS_GET_SCHEDULES_REQUEST:
      return {
        ...state,
        schedules: {
          ...state.schedules,
          error: null,
          loading: true,
        },
      };
    case Types.PROGRAMMINGS_GET_SCHEDULES_SUCCESS:
      return {
        ...state,
        schedules: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    case Types.PROGRAMMINGS_GET_SCHEDULES_ERROR:
      return {
        ...state,
        schedules: {
          ...state.schedules,
          error: action.payload,
          loading: false,
        },
      };
    case Types.PROGRAMMINGS_SEARCH_REQUEST:
      return {
        ...state,
        search: {
          ...state.search,
          params: action.payload,
          error: null,
          loading: true,
        },
      };
    case Types.PROGRAMMINGS_SEARCH_SUCCESS:
      let searchData = action.payload;
      const isMore = get(action, "payload.page") > 1;
      if (isMore) {
        searchData.items = [
          ...get(state, "search.data.items"),
          ...get(action, "payload.items", []),
        ];
      }
      return {
        ...state,
        search: {
          ...state.search,
          data: searchData,
          error: null,
          loading: false,
        },
      };
    case Types.PROGRAMMINGS_SEARCH_ERROR:
      return {
        ...state,
        search: {
          ...state.search,
          error: action.payload,
          loading: false,
        },
      };
    case Types.PROGRAMMINGS_SWAP_SCHEDULE_REQUEST:
      return {
        ...state,
        swap: {
          error: null,
          loading: true,
        },
      };
    case Types.PROGRAMMINGS_SWAP_SCHEDULE_SUCCESS:
      return {
        ...state,
        swap: {
          error: null,
          loading: false,
        },
      };
    case Types.PROGRAMMINGS_SWAP_SCHEDULE_ERROR:
      return {
        ...state,
        swap: {
          ...state.swap,
          error: action.payload,
          loading: false,
        },
      };
    case Types.PROGRAMMINGS_GET_SWAPPABLE_SLOTS_REQUEST:
      return {
        ...state,
        swappableSlots: {
          data: {},
          error: null,
          loading: true,
        },
      };
    case Types.PROGRAMMINGS_GET_SWAPPABLE_SLOTS_SUCCESS:
      return {
        ...state,
        swappableSlots: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    case Types.PROGRAMMINGS_GET_SWAPPABLE_SLOTS_ERROR:
      return {
        ...state,
        swappableSlots: {
          data: {},
          error: action.payload,
          loading: false,
        },
      };
    case Types.PROGRAMMINGS_GET_SCHEDULES_ITEMS_LOG_REQUEST:
      return {
        ...state,
        schedulesLog: {
          data: [],
          error: null,
          loading: true,
        },
      };
    case Types.PROGRAMMINGS_GET_SCHEDULES_ITEMS_LOG_SUCCESS:
      return {
        ...state,
        schedulesLog: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    case Types.PROGRAMMINGS_GET_SCHEDULES_ITEMS_LOG_ERROR:
      return {
        ...state,
        schedulesLog: {
          data: [],
          error: action.payload,
          loading: false,
        },
      };
    case Types.PROGRAMMINGS_GET_ASSOCIATE_ELEMENTS_REQUEST:
      return {
        ...state,
        associateElements: {
          ...state.associateElements,
          params: action.payload,
          error: null,
          loading: true,
          is_dirty: false,
        },
      };
    case Types.PROGRAMMINGS_GET_ASSOCIATE_ELEMENTS_SUCCESS:
      let associateElementsData = action.payload;
      if (get(action, "payload.page") > 1) {
        associateElementsData.items = [
          ...get(state, "associateElements.data.items"),
          ...get(action, "payload.items", []),
        ];
      }
      return {
        ...state,
        associateElements: {
          ...state.associateElements,
          data: associateElementsData,
          error: null,
          loading: false,
        },
      };
    case Types.PROGRAMMINGS_GET_ASSOCIATE_ELEMENTS_ERROR:
      return {
        ...state,
        associateElements: {
          ...state.associateElements,
          error: action.payload,
          is_dirty: false,
        },
      };
    case Types.PROGRAMMINGS_SET_ASSOCIATE_ELEMENTS_SUCCESS:
      return {
        ...state,
        associateElements: {
          ...state.associateElements,
          data: {
            ...state.associateElements.data,
            items: action.payload,
          },
        },
      };

    case Types.FORGET_ASSOCIATE_ELEMENTS: {
      return {
        ...state,
        associateElements: {
          ...state.associateElements,
          is_dirty: true,
        },
      };
    }
    case Types.GET_QUICK_VIEW_REQUEST:
      return {
        ...state,
        quickView: {
          ...state.quickView,
          params: action.payload,
          error: null,
          loading: true,
          is_dirty: false,
        },
      };
    case Types.GET_QUICK_VIEW_REQUEST_SUCCESS:
      return {
        ...state,
        quickView: {
          ...state.quickView,
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    case Types.GET_QUICK_VIEW_REQUEST_ERROR:
      return {
        ...state,
        quickView: {
          ...state.quickView,
          error: action.payload,
          is_dirty: false,
        },
      };
    case Types.END_CONTEST_EARLY_REQUEST:
      return {
        ...state,
        contestEndEarly: {
          error: null,
          loading: true,
        },
      };
    case Types.END_CONTEST_EARLY_SUCCESS:
      return {
        ...state,
        contestEndEarly: {
          error: null,
          loading: false,
        },
      };
    case Types.END_CONTEST_EARLY_ERROR:
      return {
        ...state,
        contestEndEarly: {
          error: action.payload,
          is_dirty: false,
        },
      };
    case Types.PROGRAMMINGS_DELETE_SLOT_REQUEST:
      return {
        ...state,
        deleteSlot: {
          error: null,
          loading: true,
        },
      };
    case Types.PROGRAMMINGS_DELETE_SLOT_SUCCESS:
      return {
        ...state,
        deleteSlot: {
          error: null,
          loading: false,
        },
      };
    case Types.PROGRAMMINGS_DELETE_SLOT_ERROR:
      return {
        ...state,
        deleteSlot: {
          error: action.payload,
          is_dirty: false,
        },
      };
    case Types.PROGRAMMINGS_UNDO_DELETE_SLOT_REQUEST:
      return {
        ...state,
        deleteSlot: {
          error: null,
          loading: true,
        },
      };
    case Types.PROGRAMMINGS_UNDO_DELETE_SLOT_SUCCESS:
      return {
        ...state,
        deleteSlot: {
          error: null,
          loading: false,
        },
      };
    case Types.PROGRAMMINGS_UNDO_DELETE_SLOT_ERROR:
      return {
        ...state,
        deleteSlot: {
          error: action.payload,
          is_dirty: false,
        },
      };
    case Types.PROGRAMMINGS_ORDERS_LIST_REQUEST:
      return {
        ...state,
        ordersList: {
          error: null,
          loading: true,
        },
      };
    case Types.PROGRAMMINGS_ORDERS_LIST_SUCCESS:
      return {
        ...state,
        ordersList: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    case Types.PROGRAMMINGS_ORDERS_LIST_ERROR:
      return {
        ...state,
        ordersList: {
          error: action.payload,
          is_dirty: false,
        },
      };
    case Types.SET_ORDERS_LIST:
      return {
        ...state,
        ordersList: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    case Types.PROGRAMMINGS_GET_FILL_SETTINGS_REQUEST:
      return {
        ...state,
        fillSettings: {
          error: null,
          loading: true,
        },
      };
    case Types.PROGRAMMINGS_GET_FILL_SETTINGS_SUCCESS:
      return {
        ...state,
        fillSettings: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    case Types.PROGRAMMINGS_GET_FILL_SETTINGS_ERROR:
      return {
        ...state,
        fillSettings: {
          error: action.payload,
          is_dirty: false,
        },
      };
    case Types.PROGRAMMINGS_UPDATE_FILL_SETTINGS_REQUEST:
      return {
        ...state,
        fillSettings: {
          ...state.fillSettings,
          error: null,
          loading: true,
        },
      };
    case Types.PROGRAMMINGS_UPDATE_FILL_SETTINGS_SUCCESS:
      return {
        ...state,
        fillSettings: {
          ...state.fillSettings,
          error: null,
          loading: false,
        },
      };
    case Types.PROGRAMMINGS_UPDATE_FILL_SETTINGS_ERROR:
      return {
        ...state,
        fillSettings: {
          data: {},
          error: action.payload,
          is_dirty: false,
        },
      };
    case Types.PROGRAMMINGS_FILL_SCHEDULING_MANUALLY_REQUEST:
      return {
        ...state,
        fillScheduling: {
          ...state.fillScheduling,
          error: null,
          loading: true,
        },
      };
    case Types.PROGRAMMINGS_FILL_SCHEDULING_MANUALLY_SUCCESS:
      return {
        ...state,
        fillScheduling: {
          ...state.fillScheduling,
          error: null,
          loading: false,
        },
      };
    case Types.PROGRAMMINGS_FILL_SCHEDULING_MANUALLY_ERROR:
      return {
        ...state,
        fillScheduling: {
          data: {},
          error: action.payload,
          is_dirty: false,
        },
      };
    case Types.PROGRAMMINGS_FILL_SCHEDULING_UNDO_REQUEST:
      return {
        ...state,
        fillScheduling: {
          ...state.fillScheduling,
          error: null,
          loading: true,
        },
      };
    case Types.PROGRAMMINGS_FILL_SCHEDULING_UNDO_SUCCESS:
      return {
        ...state,
        fillScheduling: {
          ...state.fillScheduling,
          error: null,
          loading: false,
        },
      };
    case Types.PROGRAMMINGS_FILL_SCHEDULING_UNDO_ERROR:
      return {
        ...state,
        fillScheduling: {
          data: {},
          error: action.payload,
          is_dirty: false,
        },
      };
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
}
