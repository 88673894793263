import { connect } from "react-redux";
import SelectStations from "./view";
import { compose } from "recompose";
import { get, forOwn, find, orderBy } from "lodash";
export default compose(
  connect((state, props) => {
    const { auth } = state;
    const template = get(props, "template.key");
    //
    let keyPickEntities = "";
    if (template === "basic_production" || template === "production_provided") {
      keyPickEntities = "production";
    } else if (template === "contest" || template === "liner") {
      keyPickEntities = "promotion";
    } else if (template === "digital") {
      keyPickEntities = "digital";
    }
    const {
      isFilterByServerConfig,
      isFilterByClock,
      isAllowUseStationsProps,
    } = props;
    // get the stations from props
    if (isAllowUseStationsProps) {
      return {
        ...props,
        filteredStations: orderBy(props.stations, ["call_letters"], ["asc"]),
      };
    }
    const entities = get(
      state.servers,
      `currentServer.data.entities.${keyPickEntities}`,
      {}
    );
    let stations = [];
    forOwn(entities, (entityStations) => {
      stations = [...stations, ...entityStations];
    });

    // filter stations from stations assigned to user and stations assigned to config broadcast
    const filteredStations =
      keyPickEntities && isFilterByServerConfig
        ? get(auth, "info.stations", []).filter((station) => {
            if (isFilterByClock) {
              return (
                station.has_active_prog_clock &&
                station.has_active_show_clock &&
                station.station_key &&
                find(stations, (i) => i.key === station.station_key)
              );
            }
            return (
              station.station_key &&
              find(stations, (i) => i.key === station.station_key)
            );
          })
        : get(auth, "info.stations", []).filter((station) => {
            if (isFilterByClock) {
              return (
                station.has_active_prog_clock &&
                station.has_active_show_clock &&
                station.station_key
              );
            }
            return station.station_key;
          });
    const sortedKey = get(props, "optionKeys.first", "call_letters");

    return {
      ...props,
      filteredStations: orderBy(filteredStations, [sortedKey], ["asc"]),
    };
  }, {})
)(SelectStations);
