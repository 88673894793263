import { connect } from "react-redux";
import View from "./view";
import history from "components/History";
import {
  duplicate,
  setActiveEditTab,
  unarchiveProcess,
  archiveProcess,
  convertSpecToSold,
} from "store/actions/processes";
import { compose, withHandlers, withState } from "recompose";
import { injectIntl } from "react-intl";
const Enhanced = compose(
  connect(
    (state, props) => ({
      ...props,
      user: state.auth.user,
    }),
    {
      duplicate,
      setActiveEditTab,
      archiveProcess,
      convertSpecToSold,
      unarchiveProcess,
    }
  ),
  withState("isLoading", "setIsLoading", false),
  withHandlers({
    onDuplicate: (props) => (values) => {
      let { process, setActiveEditTab, setIsLoading } = props;
      let postData = {
        new_order: true,
        template_key: values.template_id,
        button: props.button ? props.button : "another_spot",
      };
      setIsLoading(true);
      props.duplicate(
        {
          process_key: process._id,
          success_callback: (key) => {
            const templateKey = values.template_id;
            setActiveEditTab("order_details");
            history.push("/processes/edit/" + templateKey + "/" + key);
          },
          post_data: postData,
        },
        () => setIsLoading(false)
      );
    },
  })
)(View);

export default injectIntl(Enhanced);
