import React from "react";
import Spinner from "components/Spinner";
import Tabs from "components/Process/tabsIndex";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const AddUpdateProcess = (props) => {
  const { ui_hint, isLoading, onPreparePage } = props;
  return (
    <>
      {props.process && props.template && (
        <Tabs
          process={props.process}
          template={props.template}
          match_process_id={props.match_process_id}
          ui_hint={ui_hint}
          onPreparePage={onPreparePage}
        />
      )}
      <Spinner isLoading={isLoading} />
    </>
  );
};

export default AddUpdateProcess;
