import React, { useState, useEffect } from "react";
import { Row, Col, Label, FormGroup } from "reactstrap";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { get } from "lodash";
import { ShadowBox, SectionTitle, SectionFields } from "components/Elements";
import RadioButtons from "components/RadioButtons";
import SchedulingFill from "components/ScheduleComponents/SchedulingFill";
import SchedulingSimple from "components/ScheduleComponents/SchedulingSimple";
import SchedulingTargeted from "components/ScheduleComponents/SchedulingTargeted";
import { renderFinalScheduleStatement } from "components/ScheduleComponents/SchedulingPreview";

const ItemScheduling = (props) => {
  const {
    validationState,
    process,
    bem,
    step,
    onValueChanged,
    intl,
    isView,
    defaultSchedulingType,
    dateFormatByServer,
  } = props;
  const [fieldRefs, setFieldRefs] = useState(null);
  const getFieldRefs = () => {
    if (fieldRefs) return fieldRefs;

    const scheduling_type = step.fields.find(
      (f) => f.field === "scheduling_type"
    );
    const selected_by = step.fields.find((f) => f.field === "selected_by");
    const schedule_by = step.fields.find((f) => f.field === "schedule_by");
    const selected_items = step.fields.find(
      (f) => f.field === "selected_items"
    );
    const excluded_hours = step.fields.find(
      (f) => f.field === "excluded_hours_array"
    );
    const mentions = step.fields.find((f) => f.field === "mentions");

    const per = step.fields.find((f) => f.field === "per");
    if (!get(process, "data.fields.scheduling_type.value")) {
      onValueChanged(scheduling_type, defaultSchedulingType, {
        shouldBlockNavigation: false,
      }); // default value
    }
    if (!process.data.fields.schedule_by) {
      onValueChanged(schedule_by, "ROS", { shouldBlockNavigation: false });
    }
    if (!process.data.fields.selected_by) {
      onValueChanged(selected_by, "show", { shouldBlockNavigation: false });
    }
    const payload = {
      scheduling_type,
      selected_by,
      selected_items,
      desired_items_scheduling,
      mentions,
      per,
      excluded_hours,
      schedule_by,
    };

    setFieldRefs(payload);

    return payload;
  };
  useEffect(() => {
    // reset fields
    if (fieldRefs !== null) setFieldRefs(null);
  }, [get(process, "data.fields.key.value", "")]);
  const {
    scheduling_type,
    selected_by,
    desired_items_scheduling,
    mentions,
    per,
    excluded_hours,
    selected_items,
    schedule_by,
  } = getFieldRefs();

  const scheduleMethod = get(process, "data.fields.scheduling_type.value", "");
  const desiredItemsScheduling = get(
    process,
    "data.fields.desired_items_scheduling.value",
    ""
  );

  return (
    <ShadowBox className={bem.e("box-item-scheduling")}>
      <SectionTitle className={bem.e("item-scheduling-section-title")}>
        <FormattedMessage id="process > promotion liner > scheduling" />
      </SectionTitle>
      <SectionFields
        className={classnames(bem.e("section-fields"), {
          [bem.e("item-scheduling-section-fields-view")]: isView,
          [bem.e("item-scheduling-section-fields")]: !isView,
        })}
      >
        {isView ? (
          <React.Fragment>
            <Row>
              <Col xs={6} className={bem.e("reset")}>
                <FormGroup>
                  <Label>
                    <FormattedMessage id="process > schedule type" />
                  </Label>
                  <span
                    className={classnames(
                      bem.e("order-view-value"),
                      bem.e("text-cap")
                    )}
                  >
                    {scheduleMethod
                      ? scheduleMethod
                      : intl.formatMessage({ id: "process > none" })}
                  </span>
                </FormGroup>
              </Col>
              <Col xs={6} className={bem.e("reset")}>
                <FormGroup>
                  <Label>
                    <FormattedMessage id="process > promotion liner > scheduling > number" />
                  </Label>
                  <span
                    className={classnames(
                      bem.e("order-view-value"),
                      bem.e("text-cap")
                    )}
                  >
                    {desiredItemsScheduling
                      ? desiredItemsScheduling
                      : intl.formatMessage({ id: "process > none" })}
                  </span>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className={bem.e("reset")}>
                <FormGroup>
                  <Label>
                    <FormattedMessage id="process > schedule statement" />
                  </Label>
                  <strong className={bem.e("order-view-statement")}>
                    {renderFinalScheduleStatement(process, dateFormatByServer)}
                  </strong>
                </FormGroup>
              </Col>
            </Row>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Row>
              <Col xs={12}>
                <div className={bem.e("item-shcedule-helper-text")}>
                  <FormattedMessage id="process > promotion > liner > scheduling > helper" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className={bem.e("reset-col")}>
                <FormGroup className={bem.e("group-radios")}>
                  <RadioButtons
                    onSelected={(selected) => {
                      onValueChanged(scheduling_type, selected);
                      onValueChanged(selected_items, []);
                      onValueChanged(excluded_hours, []);
                      if (selected === "fill" || selected === "simple") {
                        onValueChanged(schedule_by, "ROS");
                        onValueChanged(
                          selected_by,
                          selected === "simple" ? "show" : ""
                        );
                        onValueChanged(per, "");
                        onValueChanged(mentions, "");
                      } else {
                        onValueChanged(selected_by, "show");
                        onValueChanged(per, "");
                        onValueChanged(mentions, "");
                      }
                      props.setSchedulingAvailability({}); // reset availability data
                    }}
                    selectedValue={scheduleMethod}
                    buttons={scheduling_type.props.options}
                    error={get(
                      validationState,
                      "scheduling_type.validation_error"
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
            {scheduleMethod === "simple" && (
              <SchedulingSimple
                intl={intl}
                bem={bem}
                fields={{
                  schedule_by,
                  selected_by,
                  selected_items,
                  excluded_hours,
                }}
                isView={isView}
                step={step}
                process={process}
                validationState={validationState}
                onValueChanged={onValueChanged}
                user={props.user}
                template={props.template}
                buttonClicked={props.buttonClicked}
              />
            )}
            {scheduleMethod === "targeted" && (
              <SchedulingTargeted
                bem={bem}
                onValueChanged={onValueChanged}
                fields={{
                  mentions,
                  per,
                  selected_by,
                  selected_items,
                  excluded_hours,
                }}
                intl={intl}
                step={step}
                process={process}
                isView={isView}
                validationState={validationState}
                user={props.user}
                template={props.template}
                buttonClicked={props.buttonClicked}
              />
            )}
            {scheduleMethod === "fill" && (
              <SchedulingFill
                bem={bem}
                onValueChanged={onValueChanged}
                fields={{
                  schedule_by,
                  selected_by,
                  selected_items,
                  excluded_hours,
                }}
                intl={intl}
                step={step}
                process={process}
                isView={isView}
                validationState={validationState}
                user={props.user}
                template={props.template}
                buttonClicked={props.buttonClicked}
              />
            )}
          </React.Fragment>
        )}
      </SectionFields>
    </ShadowBox>
  );
};

export default ItemScheduling;
