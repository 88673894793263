import React from "react";
import { compose, lifecycle, withState, withHandlers } from "recompose";
import { connect } from "react-redux";
import StationsTree from "./view";
import ToastManager from "components/ToastManager";
import { injectIntl } from "react-intl";
import { get, isEqual } from "lodash";
import { MdImportantDevices } from "react-icons/lib/md";
import {
  getStationsTree
} from "store/actions/tags";

export default injectIntl(
  compose(
    connect(
      (state, props) => ({
        tags: get(state, "tags.groups"),
        auth: get(state, "auth"),
        onChange: props.onChange,
        selected: props.selected,
        isLoading:
          get(state, "tags.groups.loading") ||
          get(state, "tags.deleteGroup.loading")
      }),
      { getStationsTree,
         }
    ),
    withState("selected", "setSelected", []),
    withState("treeData", "setTreeData", ({ tags }) => get(tags, "data", [])),
    withHandlers({
      // onChange: (selected) => data => {
      //   debugger
      //   onChange(data);
      // }
    }),
    lifecycle({
      componentDidMount() {
        const { isFocused } = this.props;
        // only call when focused to this tab
        if (isFocused) {
          this.props.getStationsTree(this.props.auth);
        }
      },
      componentWillReceiveProps(nextProps, nextState) {
        // only call when focused to this tab
        if (
          get(this.props, "isFocused") !== get(nextProps, "isFocused") &&
          get(nextProps, "isFocused") &&
          get(this.props, "tags.data", []).length === 0
        ) {
          this.props.getStationsTree(this.props.auth);
        }

        // update treeData when having any change
        if (
          !isEqual(
            get(this.props, "tags.data", []),
            get(nextProps, "tags.data", [])
          )
        ) {
          this.props.setTreeData(get(nextProps, "tags.data", []));
        }
      }
    })
  )(StationsTree)
);
