import React from "react";
import { FormattedMessage } from "react-intl";
import { CloseSmallIcon } from "components/CustomIcons";
import {
  spotTypeConfig,
  statusConfig,
} from "../ContentFilterProducedSpotsView/view";
import moment from "moment";

const FilteredProducedSpotTags = ({
  bem,
  values,
  onSubmit,
  dateFormatByServer,
}) => {
  if (!values) return null;
  return (
    <>
      {values.stations
        ? values.stations.map((station) => {
            let stationName = [];
            if (station.data.firstLabel) {
              stationName.push(station.data.firstLabel);
            }
            if (station.data.lastLabel) {
              stationName.push(station.data.lastLabel);
            }
            return (
              <div className={bem.e("filter-tag")}>
                <span>{stationName.join(" - ")}</span>
                <span
                  className={bem.e("remove-tag")}
                  onClick={() => {
                    onSubmit({
                      ...values,
                      stations: values.stations.filter(
                        (item) => item.value !== station.value
                      ),
                    });
                  }}
                >
                  <CloseSmallIcon />
                </span>
              </div>
            );
          })
        : null}
      {values.client_id ? (
        <div className={bem.e("filter-tag")}>
          <span>{values.client_name}</span>
          <span
            className={bem.e("remove-tag")}
            onClick={() => {
              onSubmit({
                ...values,
                client_id: "",
                client_name: "",
              });
            }}
          >
            <CloseSmallIcon />
          </span>
        </div>
      ) : null}
      {values.expiring_in_days ? (
        <div className={bem.e("filter-tag")}>
          <FormattedMessage
            id={`process > within days`}
            values={{
              number: values.expiring_in_days,
            }}
          />
          <span
            className={bem.e("remove-tag")}
            onClick={() => {
              onSubmit({
                ...values,
                expiring_in_days: "",
              });
            }}
          >
            <CloseSmallIcon />
          </span>
        </div>
      ) : null}
      {values.start_date && values.end_date ? (
        <div className={bem.e("filter-tag")}>
          <span>{`${moment(values.start_date, "YYYY-MM-DD").format(
            dateFormatByServer
          )} - ${moment(values.end_date, "YYYY-MM-DD").format(
            dateFormatByServer
          )}`}</span>
          <span
            className={bem.e("remove-tag")}
            onClick={() => {
              onSubmit({
                ...values,
                start_date: "",
                end_date: "",
              });
            }}
          >
            <CloseSmallIcon />
          </span>
        </div>
      ) : null}
      {values.spot_types
        ? values.spot_types.map((type) => (
            <div className={bem.e("filter-tag")}>
              {spotTypeConfig.find((item) => item.value === type).label}
              <span
                className={bem.e("remove-tag")}
                onClick={() => {
                  onSubmit({
                    ...values,
                    spot_types: values.spot_types.filter(
                      (item) => item !== type
                    ),
                  });
                }}
              >
                <CloseSmallIcon />
              </span>
            </div>
          ))
        : null}
      {values.statuses
        ? values.statuses.map((status) => (
            <div className={bem.e("filter-tag")}>
              {statusConfig.find((item) => item.value === status).label}
              <span
                className={bem.e("remove-tag")}
                onClick={() => {
                  onSubmit({
                    ...values,
                    statuses: values.statuses.filter((item) => item !== status),
                  });
                }}
              >
                <CloseSmallIcon />
              </span>
            </div>
          ))
        : null}
    </>
  );
};

export default FilteredProducedSpotTags;
