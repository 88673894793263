import * as React from "react";
import { FormattedMessage } from "react-intl";
import bn from "utils/bemnames";
import {
  EyeIcon,
  DownIcon,
  UpIcon,
  SystemFormArrow,
  CopyIcon,
} from "components/CustomIcons";
import SystemForm from "../SystemForm";
import { get } from "lodash";
import classNames from "classnames";
import ToggleSwitch from "components/ToggleSwitch";
import Checkbox from "components/Checkbox";

const bem = bn.create("system-form");
function getFormType(systemForm) {
  if (!systemForm) return "";

  if (
    systemForm._source.form_type === "individual_studio_console_winner_form" ||
    systemForm._source.form_type === "business_studio_console_winner_form"
  ) {
    return (
      <span className={bem.e("head-form-type")}>
        - <FormattedMessage id="form manager > studio console" />
      </span>
    );
  }
  if (
    systemForm._source.form_type === "business_winner_fulfillment_form" ||
    systemForm._source.form_type === "individual_online_registration_form" ||
    systemForm._source.form_type === "individual_winner_fulfillment_form" ||
    systemForm._source.form_type === "business_online_registration_form"
  ) {
    return (
      <span className={bem.e("head-form-type")}>
        - <FormattedMessage id="form manager > fulfillment form" />
      </span>
    );
  }
}
const SystemItem = ({
  isOpen,
  setIsOpen,
  systemForm,
  currentStation,
  onSaveHidden,
  onDuplicate,
  isLast,
  onPreview,
  intl,
  onSaveDefault,
  isShowDefault,
  isEditable,
  masterListenerForm,
  currentServer,
  getSystemForm,
}) => {
  const [formName, setFormName] = React.useState("");
  React.useEffect(() => {
    setFormName(systemForm._source.name);
  }, [systemForm._source.name]);
  return (
    <div
      className={classNames(bem.b(), {
        [bem.m("open")]: isOpen,
        [bem.m("last")]: isLast,
      })}
    >
      <div
        className={classNames(bem.e("head-container"), {
          [bem.e("system-disabled")]: systemForm._source.is_hidden,
        })}
      >
        <div className={bem.e("head-left")}>
          <span className={bem.e("head-title")}>{formName}</span>
          {getFormType(systemForm)}
        </div>
        <div className={bem.e("head-right")}>
          {isShowDefault && (
            <Checkbox
              checked={systemForm._source.is_default}
              disabled={!isEditable}
              onChange={(checked) => {
                if (!isEditable) return;
                onSaveDefault(systemForm, checked);
              }}
              text={intl.formatMessage({
                id: `form manager > default`,
              })}
            />
          )}

          <div
            className={bem.e("btn-preview")}
            onClick={() => {
              onPreview(systemForm);
            }}
          >
            <EyeIcon color="#795AFA" width={24} height={16} />
          </div>
          <div
            className={classNames(bem.e("btn-clone"), {
              "btn-disabled": !isEditable,
            })}
            onClick={() => {
              if (!isEditable) return;
              onDuplicate(systemForm);
            }}
          >
            <CopyIcon color={"#795AFA"} width={20.55} height={20} />
          </div>
          <button
            className={bem.e("button-down")}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setIsOpen(isOpen ? null : systemForm._id);
            }}
          >
            {isOpen ? (
              <UpIcon
                className={bem.e("button-up-icon")}
                color="#795AFA"
                width={24}
                height={15.46}
              />
            ) : (
              <DownIcon
                className={bem.e("button-down-icon")}
                color="#795AFA"
                width={24}
                height={15.46}
              />
            )}
          </button>
        </div>
      </div>

      <div className={bem.e("system-form-switch")}>
        <ToggleSwitch
          leftComponent={null}
          rightComponent={null}
          name="is_hidden"
          switchProps={{
            checked: !get(systemForm, "_source.is_hidden", false),
            onChange: (checked) => {
              if (!isEditable) return;
              onSaveHidden(systemForm, !checked);
            },
            disabled: !isEditable,
            offColor: "#C2D4E0",
            onColor: "#795AFA",
            uncheckedIcon: false,
            checkedIcon: false,
            boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.282)",
            height: 26.44,
            width: 48.48,
            handleDiameter: 22.92,
          }}
        />
      </div>
      {isOpen && (
        <SystemForm
          systemForm={systemForm}
          formName={formName}
          setFormName={setFormName}
          isEditable={isEditable}
          onCancel={() => {
            setIsOpen(false);
            getSystemForm({ id: systemForm._id });
          }}
          currentStation={currentStation}
          masterListenerForm={masterListenerForm}
          currentServer={currentServer}
        />
      )}
      <span className={bem.e("arrow-icon")}>
        <SystemFormArrow />
      </span>
    </div>
  );
};
export default SystemItem;
