import React, { useRef, useState } from "react";
import { Button, Row, Col, Form } from "reactstrap";
import * as Yup from "yup";
import { Formik } from "formik";
import Spinner from "components/Spinner";
import {
  get,
  map,
  isEqual,
  first,
  values as getValues,
  forEach,
  isEmpty,
  find,
  filter,
  omit,
  values,
  includes,
  orderBy,
  difference,
  forOwn,
  toLower,
} from "lodash";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { Link } from "react-router-dom";
import moment from "moment";

import URL from "utils/urls";
import { PlusIconSmaller } from "components/CustomIcons";
import ButtonAdd from "components/ButtonAdd";
import BlockingComponent from "components/BlockingComponent";
import ToastManager from "components/ToastManager";
import ConfirmationModal from "components/ConfirmationModal";
import { ReCalculateIcon } from "components/CustomIcons";

import ClockFields from "../ClockFields";
import DaypartSchedule from "./DaypartSchedule";
import {
  isBetweenDates,
  convertArrayToString,
  isBorderRangeDates,
} from "utils/helpers";
function generateClockValidationsMessage(clocksValidationsError, intl) {
  let errorArray = [];
  const weekdays = moment.weekdays();
  forOwn(clocksValidationsError, (value, key) => {
    if (Array.isArray(value) && value.length > 0) {
      // need to: item - 1. Because format from function clocksValidations is + 1
      const hours = map(value, (item) => moment(item - 1, "HH").format("hA"));
      errorArray.push(
        `${weekdays[key - 1]} ${intl.formatMessage({
          id: "clock creator > is missing hours",
        })} ${convertArrayToString(hours)}`
      );
    } else if (value === false) {
      errorArray.push(
        `${intl.formatMessage({ id: "clock creator > please select" })} ${
          weekdays[key - 1]
        }`
      );
    }
  });
  return errorArray;
}
function getInitialValues(selectedClock) {
  let schedules = [];

  forEach(
    get(selectedClock, "slots", []),
    ({ id, name, start_time, end_time, days }) => {
      let startDate = moment().set({
        hours: 0,
        minutes: parseInt(start_time),
        seconds: 0,
        millisecond: 0,
      });
      let endDate = moment().set({
        hours: 0,
        minutes: parseInt(end_time),
        seconds: 0,
        millisecond: 0,
      });

      const dateTimeFormat = "YYYY-MM-DD HH:mm:ss";

      schedules = [
        ...schedules,
        {
          id,
          label: name,
          start_time,
          start_date: startDate.format(dateTimeFormat),
          end_time,
          end_date: endDate.format(dateTimeFormat),
          days_of_week: days,
          deleted: false,
        },
      ];
    }
  );

  const initialValues = {
    type: {
      label: get(selectedClock, "type", ""),
      value: get(selectedClock, "type", ""),
    },
    title: get(selectedClock, "title"),
    station: get(selectedClock, "station"),
    schedules: schedules,
  };
  return initialValues;
}
function clocksValidations(schedules) {
  const weekArray = Array.from({ length: 7 }, (_, i) => i + 1);
  const hoursArray = Array.from({ length: 24 }, (_, i) => i + 1);
  const validations = {};
  function generateHoursFromStartToEnd(start, end) {
    let hours = [];
    for (let i = start + 1; i <= end; i++) {
      hours.push(i);
    }
    return hours;
  }
  forEach(weekArray, (day) => {
    let hours = [];
    const dateData = orderBy(
      schedules
        .filter((item) => !item.deleted && includes(item.days_of_week, day))
        .map((item) => ({
          ...item,
          start_time: parseInt(item.start_time),
          end_time: parseInt(item.end_time),
        })),
      "start_time",
      "asc"
    );
    if (dateData.length > 0) {
      dateData.forEach((item) => {
        const startHour = parseInt(
          moment(item.start_time / 60, "HH").format("HH")
        );
        let endHour = parseInt(moment(item.end_time / 60, "HH").format("HH"));
        if (endHour === 0) {
          endHour = 24;
        }
        hours = [...hours, ...generateHoursFromStartToEnd(startHour, endHour)];
      });
      validations[day] = difference(hoursArray, hours);
    } else {
      validations[day] = false;
    }
  });
  return validations;
}
function scrollToError(formErrors) {
  // find schedule id validate error -> scroll to that
  if (formErrors && formErrors.schedules) {
    let scheduleIdError = null;
    forOwn(formErrors.schedules, (value, index) => {
      if (value && !scheduleIdError) {
        scheduleIdError = index;
        return;
      }
    });
    const containerContent = document.getElementById(
      `daypart-${scheduleIdError}`
    );
    if (containerContent) {
      containerContent.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }
  }
}
const EditDaypart = (props) => {
  const {
    intl,
    stations,
    onSaveDaypart,
    isLoading,
    bem,
    selectedClock,
    addEmptySchedule,
    shouldBlockNavigation,
    setShouldBlockNavigation,
    deleteDaypartSchedule,
    setDeleteDaypartSchedule,
    onResetDaypart,
    isOpenConfirmationResetModal,
    setIsOpenConfirmationResetModal,
    setIsLoading,
  } = props;
  const [clocksValidationsError, setClocksValidationsError] = useState(null);
  const initialValues = getInitialValues(selectedClock);

  const validationSchema = Yup.object().shape({
    type: Yup.string()
      .trim()
      .required(
        intl.formatMessage({
          id: "clock creator > type is required",
        })
      ),
    title: Yup.string()
      .trim()
      .required(
        intl.formatMessage({
          id: "clock creator > title is required",
        })
      ),
    station: Yup.string()
      .trim()
      .required(
        intl.formatMessage({
          id: "clock creator > station is required",
        })
      ),
    schedules: Yup.lazy((obj) =>
      Yup.array().of(
        Yup.object({
          deleted: Yup.boolean(),
          start_time: Yup.string().when("deleted", {
            is: false,
            then: Yup.string()
              .trim()
              .required(
                intl.formatMessage({
                  id: "clock creator > daypart start_time is required",
                })
              ),
          }),
          end_time: Yup.string().when("deleted", {
            is: false,
            then: Yup.string()
              .trim()
              .required(
                intl.formatMessage({
                  id: "clock creator > daypart end_time is required",
                })
              ),
          }),
          start_date: Yup.date().when("deleted", {
            is: false,
            then: Yup.date(),
          }),
          end_date: Yup.date()
            .when(["deleted", "start_date"], {
              is: (deleted, start_date) => !deleted && start_date,
              then: Yup.date().test(
                "end_date",
                intl.formatMessage({
                  id:
                    "clock creator > daypart end_time must be greater than start time",
                }),
                function(value) {
                  return true;
                }
              ),
            })
            .test(
              "schedules-overlap",
              intl.formatMessage({
                id: "clock creator > daypart is conflict",
              }),
              function() {
                const startDate = this.parent.start_date;
                const endDate = this.parent.end_date;
                if (this.parent.deleted) return true;

                const duplicateStartDate = filter(obj, (item) => {
                  if (item.deleted) return false;
                  const isMatchDays = isEqual(
                    item.days_of_week.sort(),
                    this.parent.days_of_week.sort()
                  );
                  return (
                    parseInt(item.start_time) ===
                      parseInt(this.parent.start_time) && isMatchDays
                  );
                });
                // start is same with base dates. (duplicate)(base: 3Pm-7Pm, date: 3Pm-12Pm)
                if (duplicateStartDate.length > 1) {
                  return false;
                }

                const overlap = find(obj, (item) => {
                  const baselineStartDate = item.start_date;
                  const baselineEndDate = item.end_date;
                  if (item.deleted) return false;
                  const isBetweenStartDate = isBetweenDates({
                    date: startDate,
                    startDate: baselineStartDate,
                    endDate: baselineEndDate,
                    inclusivity: "()",
                  });
                  const isBetweenEndDate = isBetweenDates({
                    date: endDate,
                    startDate: baselineStartDate,
                    endDate: baselineEndDate,
                    inclusivity: "()",
                  });
                  const isBorderStartDate = isBorderRangeDates({
                    date: startDate,
                    startDate: baselineStartDate,
                    endDate: baselineEndDate,
                  });
                  const isBorderEndDate = isBorderRangeDates({
                    date: endDate,
                    startDate: baselineStartDate,
                    endDate: baselineEndDate,
                  });
                  const isMatchDays = isEqual(
                    item.days_of_week.sort(),
                    this.parent.days_of_week.sort()
                  );
                  let isOverlap = false;
                  if (isBetweenStartDate || isBetweenEndDate) {
                    // start or end is between dates.(base: 3Pm-7Pm, date: 4Pm-6Pm)
                    isOverlap = true;
                  } else if (isBorderStartDate && isBorderEndDate) {
                    // start and end is same with base dates. (duplicate)(base: 3Pm-7Pm, date: 3Pm-7Pm)
                    isOverlap = true;
                  }
                  if (isOverlap && item.id !== this.parent.id && isMatchDays) {
                    return true;
                  }
                  return false;
                });
                return overlap ? false : true;
              }
            ),
          days_of_week: Yup.array().when("deleted", {
            is: false,
            then: Yup.array().required(
              intl.formatMessage({
                id: "clock creator > daypart days are required",
              })
            ),
          }),
          label: Yup.string().when("deleted", {
            is: false,
            then: Yup.string()
              .trim()
              .required(
                intl.formatMessage({
                  id: "clock creator > daypart > label is required",
                })
              )
              .test(
                "label-overlap",
                intl.formatMessage({
                  id: "clock creator > daypart > label is unique",
                }),
                function(value) {
                  if (this.parent.deleted) return true;
                  const duplicateLabel = filter(obj, (item) => {
                    if (item.deleted) return false;
                    if (
                      item.id !== this.parent.id &&
                      toLower(item.label) === toLower(value)
                    )
                      return true;
                    return false;
                  });
                  return duplicateLabel.length > 0 ? false : true;
                }
              ),
          }),
        })
      )
    ),
  });

  const renderSchedules = ({ formProps, setDeleteDaypartSchedule }) => {
    const { values, setFieldValue, errors } = formProps;

    let schedules = get(values, "schedules", []);
    return map(schedules, (schedule, key) => {
      if (!schedule.deleted) {
        let scheduleErrors = errors.schedules ? errors.schedules[key] : {};

        return (
          <DaypartSchedule
            firstRow={isEqual(first(getValues(schedules)), schedule)}
            key={key}
            index={key}
            id={schedule.id}
            schedules={schedules}
            onChange={(schedules) => {
              setFieldValue("schedules", schedules);
            }}
            onDelete={() => {
              setDeleteDaypartSchedule({
                schedule,
                id: schedule.id,
              });
            }}
            errors={scheduleErrors}
          />
        );
      }
    });
  };
  const showToastError = () => {
    ToastManager.show({
      title: intl.formatMessage({
        id: "toast > title not saved",
      }),
      message: intl.formatMessage({
        id: "toast > message error please correct the hilighted fields",
      }),
      level: "error",
    });
  };
  const formikRef = useRef();
  const form = (
    <div className={bem.e("clock-details")}>
      <Row>
        <Col className="m-0">
          <div>
            <Formik
              validateOnChange={false}
              validateOnBlur={false}
              validateOnMount={false}
              validationSchema={validationSchema}
              enableReinitialize
              initialValues={initialValues}
              isInitialValid={validationSchema.isValidSync(initialValues)}
              onSubmit={(data) => {
                setClocksValidationsError(null);
                const validations = clocksValidations(values(data.schedules));
                const is7Days =
                  Object.keys(validations).length === 7 &&
                  Object.values(validations).filter((item) => item === false)
                    .length === 0;
                const is24HoursPerDay =
                  Object.values(validations).filter((item) => item.length > 0)
                    .length === 0;
                if (is7Days && is24HoursPerDay) {
                  onSaveDaypart(data);
                  setClocksValidationsError(null);
                } else {
                  setClocksValidationsError(validations);
                  showToastError();
                }
              }}
              ref={formikRef}
            >
              {(formProps) => {
                const { handleSubmit, values, dirty } = formProps;
                const isChanged = !isEqual(initialValues, values) && dirty;
                if (isChanged !== shouldBlockNavigation) {
                  setTimeout(() => setShouldBlockNavigation(isChanged), 20);
                }

                return (
                  <React.Fragment>
                    <Form onSubmit={handleSubmit}>
                      <div
                        className={classnames(
                          bem.e("form-panel"),
                          bem.e("form-daypart")
                        )}
                      >
                        <div className={classnames(bem.e("header-wrapper"))}>
                          <h2 className={classnames(bem.e("header"))}>
                            <FormattedMessage id="clock creator > define dayparts" />
                          </h2>
                        </div>
                        <div className={bem.e("daypart-helper")}>
                          <FormattedMessage id="clock creator > define dayparts helper" />
                        </div>
                        <Row
                          className={classnames(
                            bem.e("form-container"),
                            bem.e("form-daypart-container"),
                            "m-0"
                          )}
                        >
                          <ClockFields
                            fieldSpan={4}
                            bem={bem}
                            stations={stations}
                            formProps={formProps}
                          />
                        </Row>
                        <Row
                          className={classnames(
                            bem.e("daypart-schedules-container"),
                            "m-0"
                          )}
                        >
                          <Col className="p-0 m-0" xs={12}>
                            {renderSchedules({
                              formProps,
                              setDeleteDaypartSchedule,
                            })}
                            {!!clocksValidationsError && (
                              <span className="text-danger">
                                {map(
                                  generateClockValidationsMessage(
                                    clocksValidationsError,
                                    intl
                                  ),
                                  (error, index) => (
                                    <span
                                      key={index}
                                      className={bem.e(
                                        "error-clock-validation"
                                      )}
                                    >
                                      {error}
                                    </span>
                                  )
                                )}
                              </span>
                            )}
                          </Col>
                        </Row>
                        <Row
                          className={classnames(
                            bem.e("add-row-button-container"),
                            "m-0 p-0"
                          )}
                        >
                          <Col xs={12} className="m-0">
                            <ButtonAdd
                              onClick={() => addEmptySchedule(formProps)}
                              showDefaultIcon={false}
                            >
                              <div>
                                <PlusIconSmaller />
                                <FormattedMessage id="clock creator > add another daypart" />
                              </div>
                            </ButtonAdd>
                          </Col>
                        </Row>
                      </div>
                      <div className={"bottom-actions-sticky"}>
                        <div className={"buttons daypart-buttons"}>
                          <div className="buttons-left">
                            <Button
                              color="primary"
                              type="submit"
                              className="btn btn-blue btn-radius"
                              disabled={isLoading || isEmpty(values.schedules)}
                              onClick={() => {
                                setTimeout(() => {
                                  const formErrors = get(
                                    formikRef.current,
                                    "state.errors"
                                  );

                                  if (
                                    formErrors &&
                                    Object.keys(formErrors).length
                                  ) {
                                    showToastError();
                                    scrollToError(formErrors);
                                    return;
                                  }
                                }, 20);
                              }}
                            >
                              <FormattedMessage
                                id={`clock creator > button save`}
                              />
                            </Button>
                            <Button
                              type="button"
                              to={URL.CLOCK_MANAGER()}
                              tag={Link}
                              outline
                              className="btn-outline-blue btn-radius button-cancel"
                            >
                              <FormattedMessage id="clock creator > button cancel" />
                            </Button>
                          </div>
                          {selectedClock.is_changed ? (
                            <div className="buttons-right">
                              <Button
                                type="button"
                                outline
                                className="btn-outline-blue btn-radius button-reset"
                                onClick={() => {
                                  setIsOpenConfirmationResetModal(true);
                                }}
                              >
                                <ReCalculateIcon />
                                <FormattedMessage id="clock creator > button revert" />
                              </Button>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </Form>
                    <ConfirmationModal
                      isOpen={isOpenConfirmationResetModal}
                      title={intl.formatMessage({
                        id: "clock creator > confirm daypart revert schedule",
                      })}
                      onConfirm={() => {
                        setIsLoading(true);
                        setIsOpenConfirmationResetModal(false);
                        onResetDaypart((response) => {
                          formProps.resetForm(getInitialValues(response));
                          setClocksValidationsError(null);
                          setShouldBlockNavigation(false);
                          setIsLoading(false);
                          ToastManager.show({
                            message: intl.formatMessage({
                              id: "clock creator > revert success",
                            }),
                            autoDismiss: 1.5,
                            level: "success",
                          });
                        });
                      }}
                      onCancel={() => setIsOpenConfirmationResetModal(false)}
                      isCloseOutside={false}
                    />
                    <ConfirmationModal
                      isOpen={!!deleteDaypartSchedule}
                      title={intl.formatMessage({
                        id: "clock creator > confirm daypart delete schedule",
                      })}
                      onConfirm={() => {
                        let newSchedules = [];
                        if (
                          filter(
                            values.schedules,
                            (schedule) => !schedule.deleted
                          ).length === 1
                        ) {
                          newSchedules = [
                            ...values.schedules,
                            {
                              ...omit(deleteDaypartSchedule.schedule, [
                                "start_time",
                                "start_date",
                                "end_time",
                                "end_date",
                              ]),
                              days_of_week: [],
                              label: "",
                            },
                          ];
                        } else {
                          newSchedules = map(values.schedules, (item) => {
                            if (item.id === deleteDaypartSchedule.id) {
                              return {
                                ...item,
                                deleted: true,
                              };
                            }
                            return item;
                          });
                        }
                        formProps.setFieldValue("schedules", newSchedules);
                        setDeleteDaypartSchedule(null);
                      }}
                      onCancel={() => setDeleteDaypartSchedule(null)}
                      isCloseOutside={false}
                    />
                  </React.Fragment>
                );
              }}
            </Formik>
          </div>
        </Col>
      </Row>
      <Spinner isLoading={isLoading} />
    </div>
  );
  return (
    <React.Fragment>
      {form}
      <BlockingComponent
        isBlocked={shouldBlockNavigation}
        modalProps={{
          isShowConfirm: true,
          isShowDiscard: false,
          isCancelConfirmedNavigation: true,
          onCancel: () => {
            setShouldBlockNavigation(false); // reset
          },
          // onConfirm: () => {
          //   const buttonSubmit = document.getElementById(`btn-submit`);
          //   // trigger click to submit the form
          //   if (buttonSubmit) buttonSubmit.click();
          // },
        }}
      />
    </React.Fragment>
  );
  // return (
  //   <WarnAboutUnsavedChanges when={shouldBlockNavigation} children={form} />
  // );
};

export default EditDaypart;
