import React, { useRef, useState } from "react";
import bn from "utils/bemnames";
import {
  ArrowRightSmallIcon,
  ArrowLeftSmallIcon,
  ScheduleSmallIcon,
} from "components/CustomIcons";
import classNames from "classnames";
import moment from "moment";
import DatePicker from "components/DatePicker";
import useOutsideClick from "utils/useOutsideClick";
const bem = bn.create("jock-console");

export default function CalendarInline(props) {
  const { selectedDate, setSelectedDate, clockData } = props;
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);
  useOutsideClick(ref, () => {
    setIsOpen(false);
  });
  return (
    <div className={classNames(bem.e("calendar-inline"))} ref={ref}>
      <div className={bem.e("calendar-slide")}>
        <span
          className={bem.e("calendar-arrow-left")}
          onClick={() => {
            const prevDate = moment(selectedDate)
              .subtract(1, "days")
              .toDate();
            setSelectedDate(prevDate);
          }}
        >
          <ArrowLeftSmallIcon />
        </span>
        <span className={bem.e("calendar-slide-content")}>
          <span
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            className={bem.e("calendar-slide-trigger")}
          >
            <ScheduleSmallIcon />
            <span className={bem.e("calendar-date")}>
              {moment(selectedDate).format("dddd, MMM DD")}
            </span>
          </span>
          {isOpen && (
            <div className={classNames("cr-textinput", bem.e("datepicker"))}>
              <DatePicker
                field={{
                  value: selectedDate,
                }}
                onChange={(e) => {
                  setSelectedDate(moment(e, "YYYY-MM-DD HH:mm:ss").toDate());
                  setIsOpen(false);
                }}
                format="YYYY-MM-DD HH:mm:ss"
                inline
              />
            </div>
          )}
        </span>
        <span
          className={bem.e("calendar-arrow-right")}
          onClick={() => {
            const nextDate = moment(selectedDate)
              .add(1, "days")
              .toDate();
            setSelectedDate(nextDate);
          }}
        >
          <ArrowRightSmallIcon />
        </span>
      </div>
      {clockData && (
        <div className={bem.e("calendar-inline-time")}>
          {moment(clockData).format("h:mm a")}
        </div>
      )}
    </div>
  );
}
