import { connect } from "react-redux";
import { compose, lifecycle, withState } from "recompose";
import { withRouter } from "react-router-dom";
import history from "components/History";
import { get } from "lodash";
import ProcessesList from "./view";
import {
  getProcesses,
  setActiveTab,
  setActiveEditTab,
  resetTab,
  getProcessesInComing,
  resetProcess,
  setActiveFilterProcesses,
  forgetProcessesFilter,
} from "store/actions/processes";
import { setSchedulingAvailability } from "store/actions/programmings";
import { setFetchFulfillmentSettings } from "store/actions/stations";
import { getTemplate } from "store/actions/template";
import { setSortProcessesQueue } from "store/actions/auth";

import { injectIntl } from "react-intl";
const Enhanced = compose(
  connect(
    (state, props) => {
      return {
        user: state.auth.user,
        users: get(state, "auth.info.users", []),
        processes: state.processes.processes,
        auth: state.auth,
        template: state.templates.template.data,
        serverInfo: get(state, "auth.info", {}),
        dateFormatByServer: get(
          state,
          "servers.currentServer.data.date_format",
          "MM/DD/YYYY"
        ),
        sortProcessesQueue: get(state, "auth.sort_processes_queue", {}),
        ...props,
      };
    },
    {
      getProcessesInComing,
      getProcesses,
      setActiveTab,
      setActiveEditTab,
      getTemplate,
      resetTab,
      setSchedulingAvailability,
      setFetchFulfillmentSettings,
      resetProcess,
      setActiveFilterProcesses,
      forgetProcessesFilter,
      setSortProcessesQueue,
    }
  ),
  withState("advancedSearch", "setAdvancedSearch", {}),
  withState("keyword", "setKeyword", ""),
  withState("filterViewValues", "setFilterViewValues", {
    accounts: "all",
    account_executive: "",
    search: "",
  }),
  withState(
    "filterViewProducedSpotsValues",
    "setFilterViewProducedSpotsValues",
    {
      stations: [],
      client_id: "",
      expiring_in_days: "",
      statuses: [],
      spot_types: [],
      start_date: "",
      end_date: "",
    }
  ),
  withState(
    "filterViewProductionArchiveValues",
    "setFilterViewProductionArchiveValues",
    {
      stations: [],
      client_id: "",
      client_name: "",
      status: "",
      spot_types: [],
      start_date: "",
      end_date: "",
    }
  ),
  withState(
    "filterViewProductionQueueValues",
    "setFilterViewProductionQueueValues",
    {
      stations: [],
      client_id: "",
      client_name: "",
      status: "",
      spot_types: [],
      deadline: [],
    }
  ),
  withState("isShowPageTitle", "setIsShowPageTitle", true),
  withState("isShowAdvancedSearchModal", "setIsShowAdvancedSearchModal", false),
  withState("sidebarType", "setSidebarType", ""),
  withState("isOpenSidebar", "setIsOpenSidebar", false),
  withState(
    "isOpenProductionPromptModal",
    "setIsOpenProductionPromptModal",
    false
  ),
  lifecycle({
    componentDidMount() {
      this.props.setActiveFilterProcesses(get(this.props, "filter"));
      this.props.getTemplate("basic_production", this.props.auth.info);
      this.unblock = history.block(() => {
        // this.props.stop();
        return true;
      });
    },
    componentWillReceiveProps(nextProps) {
      // const filter = get(nextProps, "filter");
      // clear interval on Produced Spots
      // if (filter === "active") {
      //   nextProps.stop();
      // } else {
      //   const fromEndFilters = [
      //     "liners",
      //     "promotion_contests",
      //     "expiring_spots",
      //   ];
      //   const fromDueDateFilters = ["continuity_queue"];
      //   const deadlineFromEnd = includes(fromEndFilters, filter);
      //   let deadlineTime = deadlineFromEnd
      //     ? "order_end_date"
      //     : "order_start_date";
      //   if (includes(fromDueDateFilters, filter)) {
      //     deadlineTime = "due_date";
      //   }
      //   // render list days for countdown
      //   /* eslint-disable */
      //   if (
      //     (get(this.props, "filter") !== get(nextProps, "filter") &&
      //       get(nextProps, `processes.filter_${filter}.data`)) ||
      //     (get(this.props, "processes") !== get(nextProps, "processes") &&
      //       get(nextProps, `processes.filter_${filter}.data`)) ||
      //     (!get(this.props, "intervalData") &&
      //       get(nextProps, `processes.filter_${filter}.data`))
      //   ) {
      //     const listDays = get(nextProps, `processes.filter_${filter}.data`, [])
      //       .filter((item) => get(item, "_source.order_start_date", null))
      //       .map((item) => {
      //         let deadline = get(item, `_source.${deadlineTime}`, null);
      //         const timezone = get(item, "_source.timezone", null);
      //         if (!deadlineFromEnd) {
      //           let _timezone = !timezone ? moment.tz.guess() : timezone;
      //           deadline = moment
      //             .tz(deadline, timezone)
      //             .subtract(1, "days")
      //             .format("YYYY-MM-DD");
      //         }
      //         const id = get(item, "_id", null);
      //         return {
      //           id,
      //           deadline,
      //           timezone,
      //         };
      //       });
      //     nextProps.setListDays(listDays);
      //   }
      // }
      if (get(this.props, "filter") !== get(nextProps, "filter")) {
        this.props.setIsShowPageTitle(true);
        this.props.setActiveFilterProcesses(get(nextProps, "filter"));
      }
    },
    componentWillUnmount() {
      // this.props.stop();
      this.unblock();
    },
  })
)(ProcessesList);
export default withRouter(injectIntl(Enhanced));
