import * as React from "react";
import { debounce } from "lodash";

const useInfiniteScroll = ({
  useWindow = true,
  elementId,
  threshold = 250,
  debounceTime = 500,
  onLoadMore,
  shouldLoadMore = true,
  isHorizontal = false,
}) => {
  const handleScroll = React.useCallback(
    debounce(async () => {
      let element = window.document.body;

      if (!useWindow) {
        element = document.getElementById(elementId);
      }
      if (!element) return;
      let offsetValue = element.offsetHeight;
      let offsetSize = element.scrollHeight;
      let offsetInitialValue = element.scrollTop;
      if (isHorizontal) {
        offsetValue = element.offsetWidth;
        offsetSize = element.scrollWidth;
        offsetInitialValue = element.scrollLeft;
      }
      if (offsetValue + offsetInitialValue < offsetSize - threshold) {
        return;
      }

      if (onLoadMore && shouldLoadMore) {
        onLoadMore();
      }
    }, debounceTime),
    [elementId, debounceTime, onLoadMore, shouldLoadMore]
  );

  // observe scroll
  React.useEffect(() => {
    let element = window;

    if (!useWindow) {
      element = document.getElementById(elementId);
    }

    if (element) {
      element.addEventListener("scroll", handleScroll);
      return () => element.removeEventListener("scroll", handleScroll);
    }
  }, [handleScroll, useWindow, elementId]);

  // load more if element is not scrollable and shouldLoadMore is true
  React.useEffect(() => {
    let element = window.document.body;

    if (!useWindow) {
      element = document.getElementById(elementId);
    }
    if (!element || !shouldLoadMore) {
      return () => {};
    }

    const hasScroll = isHorizontal
      ? element.scrollWidth > element.clientWidth
      : element.scrollHeight > element.clientHeight;

    if (!hasScroll) {
      onLoadMore && onLoadMore();
    }
  }, [elementId, onLoadMore, shouldLoadMore]);
};

export default useInfiniteScroll;
