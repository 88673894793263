import React, { useRef } from "react";
import TextInput from "components/TextInput";
import { Formik } from "formik";
import * as Yup from "yup";
import { find, get, split, trim, includes } from "lodash";
import {
  languageList,
  PhoneNumberFormat,
  language as defaultLangCode,
} from "utils/config";
import { convertArrayToString } from "utils/helpers";
import { FormattedMessage } from "react-intl";
import { Row, Col, Form, FormGroup, Button, Label } from "reactstrap";
import { Asterisk } from "components/Elements";
import TextInputFormatted from "components/TextInputFormatted";
import Dropdown from "components/Dropdown";
import TimezonePicker from "components/TimezonePicker";
import ToastManager from "components/ToastManager";
import Spinner from "components/Spinner";

import OutOfOffice from "components/EditProfileForm/OutOfOffice";
import moment from "moment";
import { validatePrivileges } from "utils/helpers";
import { PRIVILEGES } from "utils/constants";
import PersonalDetails from "../PersonalDetails";
import CreativeDetails from "components/EditProfileForm/CreativeDetails";
import classNames from "classnames";

const ProfileForm = (props) => {
  const {
    user,
    selectedTagOptions,
    currentServer,
    intl,
    hasMultimarket,
    bem,
    setShouldBlockNavigation,
    isSpecialUser,
    shouldBlockNavigation,
    isLoading,
    privileges,
    auth,
  } = props;
  console.log("privileges", privileges);
  const isEditInfo = validatePrivileges({
    requires: [PRIVILEGES.EDIT_USERS],
    privileges,
    user: auth.user,
  });

  const formikRef = useRef();

  const userLangCode = get(user, "lang", null);
  const defaultLanguage = find(
    languageList,
    (item) => item.value === defaultLangCode
  );
  const validEmailDomains =
    currentServer && currentServer.domain_filter
      ? split(currentServer.domain_filter, ",").map((email) => trim(email))
      : [];

  const initialValues = {
    is_edit_info: isEditInfo,
    first_name: user.first_name || "",
    last_name: user.last_name || "",
    email: user.email || "",
    title: user.title || "",
    phone: user.phone || "",
    language: userLangCode
      ? {
          value: userLangCode,
          label: get(
            find(languageList, (item) => item.value === userLangCode),
            "label",
            "English"
          ),
        }
      : {
          value: defaultLangCode,
          label: get(defaultLanguage, "label", "English"),
        },
    location: user.location || "",
    location_text: user.location_text || "",
    summary: user.summary || "",
    reel: user.reel || "",
    tags: selectedTagOptions,
    out_of_office_settings:
      user.out_of_office_settings && user.out_of_office_settings.length > 0
        ? user.out_of_office_settings
        : [
            {
              from_date: "",
              to_date: "",
            },
          ],
  };
  return (
    <Formik
      ref={formikRef}
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        is_edit_info: Yup.bool().nullable(),
        first_name: Yup.string().when("is_edit_info", {
          is: (value) => !!value,
          then: Yup.string()
            .required(
              props.intl.formatMessage({
                id: "validate > first name is required",
              })
            )
            .min(
              2,
              props.intl.formatMessage({
                id: "validate > the first name must be at least 2 characters.",
              })
            ),
          otherwise: Yup.string().nullable(),
        }),
        last_name: Yup.string().when("is_edit_info", {
          is: (value) => !!value,
          then: Yup.string()
            .required(
              props.intl.formatMessage({
                id: "validate > last name is required",
              })
            )
            .min(
              2,
              props.intl.formatMessage({
                id: "validate > the last name must be at least 2 characters.",
              })
            ),
          otherwise: Yup.string().nullable(),
        }),
        email: Yup.string().when("is_edit_info", {
          is: (value) => !!value,
          then: Yup.string()
            .email(props.intl.formatMessage({ id: "validate > invalid email" }))
            .required(
              props.intl.formatMessage({
                id: "validate > email is required",
              })
            )
            .test(
              "email",
              props.intl.formatMessage(
                {
                  id: "validate > email should be in domain",
                },
                {
                  domains: convertArrayToString(validEmailDomains),
                }
              ),
              (value) => {
                if (!value) return false;
                if (validEmailDomains.length === 0) return true;
                const domain = value.replace(/.*@/, "");
                return includes(validEmailDomains, domain);
              }
            ),
          otherwise: Yup.string().nullable(),
        }),
        language: Yup.string().when("is_edit_info", {
          is: (value) => !!value,
          then: Yup.string().required(
            intl.formatMessage({ id: "validate > language is required" })
          ),
          otherwise: Yup.string().nullable(),
        }),
        phone: Yup.string().when("is_edit_info", {
          is: (value) => !!value,
          then: Yup.string()
            .nullable()
            .test(
              "len",
              intl.formatMessage({
                id: "validate > phone number must be 10 digits",
              }),
              (val) => !val || val.length === 10
            ),
          otherwise: Yup.string().nullable(),
        }),
        title: Yup.string().nullable(),
        location: Yup.string().when("is_edit_info", {
          is: (value) => !!value,
          then: hasMultimarket
            ? Yup.string().required(
                props.intl.formatMessage({
                  id: "validate > timezone is required",
                })
              )
            : Yup.string().nullable(),
          otherwise: Yup.string().nullable(),
        }),
        location_text: Yup.string().nullable(),
        summary: Yup.string().max(
          150,
          intl.formatMessage(
            { id: "validate > max characters" },
            {
              FIELD_NAME: "Summary",
              MAX: 150,
            }
          )
        ),
        out_of_office_settings: Yup.array().of(
          Yup.object().shape({
            from_date: Yup.string().nullable(),
            to_date: Yup.string()
              .test(
                "return_date",
                intl.formatMessage({
                  id: `validate > end date should be later than start date`,
                }),
                function(value) {
                  if (!this.parent.from_date) return true;
                  if (!value) return false;
                  const startDate = this.parent.from_date;
                  const isAfterEndDate = moment(startDate).isAfter(value);
                  return !isAfterEndDate;
                }
              )
              .nullable(),
          })
        ),
      })}
      onSubmit={props.onFormSubmit}
    >
      {(formProps) => {
        const errors = formProps.errors;
        const touched = formProps.touched;
        const values = formProps.values;

        return (
          <Form
            onSubmit={formProps.handleSubmit}
            className={bem.e("user-form-content")}
            onChange={() => {
              if (!shouldBlockNavigation) setShouldBlockNavigation(true);
            }}
          >
            <div className={bem.e("content-form")}>
              {values.is_edit_info ? (
                <div
                  className={classNames(
                    bem.e("form-fields"),
                    bem.e("box-form-fields")
                  )}
                >
                  <Row>
                    <Col xs={6} className={bem.e("col-reset")}>
                      <FormGroup className={bem.e("form-group-edit")}>
                        <TextInput
                          label={
                            <span>
                              <FormattedMessage id="user > first name" />
                              <Asterisk>*</Asterisk>
                            </span>
                          }
                          type="text"
                          name="first_name"
                          placeholder={props.intl.formatMessage({
                            id: "user > enter first name",
                          })}
                          value={values.first_name}
                          error={touched.first_name && errors.first_name}
                          onChange={formProps.handleChange}
                          onBlur={formProps.handleBlur}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={6} className={bem.e("col-reset")}>
                      <FormGroup className={bem.e("form-group-edit")}>
                        <TextInput
                          label={
                            <span>
                              <FormattedMessage id="user > last name" />
                              <Asterisk>*</Asterisk>
                            </span>
                          }
                          type="text"
                          name="last_name"
                          placeholder={props.intl.formatMessage({
                            id: "user > enter last name",
                          })}
                          value={values.last_name}
                          error={touched.last_name && errors.last_name}
                          onChange={formProps.handleChange}
                          onBlur={formProps.handleBlur}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={6} className={bem.e("col-reset")}>
                      <FormGroup className={bem.e("form-group-edit")}>
                        <TextInput
                          label={
                            <span>
                              <FormattedMessage id="user > job title" />
                            </span>
                          }
                          type="text"
                          name="title"
                          placeholder={props.intl.formatMessage({
                            id: "user > enter job title",
                          })}
                          value={values.title}
                          error={touched.title && errors.title}
                          onChange={formProps.handleChange}
                          onBlur={formProps.handleBlur}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={6} className={bem.e("col-reset")}>
                      <FormGroup className={bem.e("form-group-edit")}>
                        <TextInput
                          label={
                            <span>
                              <FormattedMessage id="user > email" />
                              <Asterisk>*</Asterisk>
                            </span>
                          }
                          type="email"
                          name="email"
                          placeholder={props.intl.formatMessage({
                            id: "user > enter email",
                          })}
                          value={values.email}
                          error={touched.email && errors.email}
                          onChange={formProps.handleChange}
                          onBlur={formProps.handleBlur}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} className={bem.e("col-reset")}>
                      <FormGroup className={bem.e("form-group-edit")}>
                        <TextInputFormatted
                          format={PhoneNumberFormat}
                          label={
                            <span>
                              <FormattedMessage id="user > phone" />
                            </span>
                          }
                          type="text"
                          name="phone"
                          placeholder={intl.formatMessage({
                            id: "user > enter phone",
                          })}
                          value={values.phone}
                          error={touched.phone && errors.phone}
                          onChange={(value) => {
                            formProps.setFieldValue("phone", value);
                            setShouldBlockNavigation(true);
                          }}
                          onBlur={formProps.handleBlur}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={6} className={bem.e("col-reset")}>
                      <FormGroup className={bem.e("form-group-edit")}>
                        <Dropdown
                          label={
                            <span>
                              <FormattedMessage id="my profile > language" />
                              <Asterisk>*</Asterisk>
                            </span>
                          }
                          value={values.language}
                          placeholder={intl.formatMessage({
                            id: "my profile > language placeholder",
                          })}
                          onChange={(selectedOption) => {
                            formProps.setFieldValue("language", selectedOption);
                            setShouldBlockNavigation(true);
                          }}
                          options={languageList}
                          name="language"
                          error={touched.language && errors.language}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} className={bem.e("col-reset")}>
                      <FormGroup className={bem.e("form-group-edit")}>
                        <TextInput
                          label={<FormattedMessage id="user > location" />}
                          type="text"
                          name="location_text"
                          placeholder={props.intl.formatMessage({
                            id: "user > enter location",
                          })}
                          value={values.location_text}
                          error={touched.location_text && errors.location_text}
                          onChange={formProps.handleChange}
                          onBlur={formProps.handleBlur}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={6} className={bem.e("col-reset")}>
                      <FormGroup className={bem.e("form-group-edit")}>
                        <Label>
                          <FormattedMessage id="station > timezone" />
                          {hasMultimarket && <Asterisk>*</Asterisk>}
                        </Label>
                        <TimezonePicker
                          value={values.location}
                          onChange={({ value }) => {
                            formProps.setFieldValue("location", value);
                            setShouldBlockNavigation(true);
                          }}
                          placeholder={props.intl.formatMessage({
                            id: "user > enter timezone",
                          })}
                        />
                        {touched.location && errors.location && (
                          <div className="text-danger">{errors.location}</div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              ) : (
                <PersonalDetails bem={bem} user={user} intl={intl} />
              )}
              {isSpecialUser && (
                <CreativeDetails
                  isView={!values.is_edit_info}
                  user={user}
                  intl={intl}
                  selectedTagOptions={selectedTagOptions}
                  formProps={formProps}
                />
              )}
              <OutOfOffice intl={intl} formProps={formProps} isView={false} />
            </div>

            <div className={bem.e("form-buttons")}>
              <FormGroup>
                <Button
                  color="blue"
                  type="submit"
                  className="btn btn-radius"
                  disabled={isLoading}
                  onClick={() => {
                    setTimeout(() => {
                      const formErrors = get(formikRef, "current.state.errors");
                      if (formErrors && Object.keys(formErrors).length) {
                        ToastManager.show({
                          title: props.intl.formatMessage({
                            id: "toast > title not saved",
                          }),
                          message: props.intl.formatMessage({
                            id:
                              "toast > message error please correct the hilighted fields",
                          }),
                          level: "error",
                        });
                      }
                    }, 40);
                  }}
                >
                  <FormattedMessage id={`user > button submit`} />
                </Button>
              </FormGroup>
            </div>
            <Spinner isLoading={isLoading} />
          </Form>
        );
      }}
    </Formik>
  );
};

export default ProfileForm;
