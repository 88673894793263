import {
  isArray,
  differenceWith,
  forOwn,
  isEqual,
  get,
  find,
  findIndex,
  map,
  filter,
  forEach,
  uniqBy,
  includes,
  first,
  cloneDeep,
  join,
  split,
  last,
  sortBy,
  flatten,
  intersection,
  values,
  reject,
  lowerCase,
  trim,
  isEmpty,
  toLower,
  orderBy,
} from "lodash";
import {
  TAG_TYPE_LOCATION,
  TAG_TYPE_FORMAT,
  TAG_TYPE_EXCEPT_LOCATION_FORMAT,
} from "components/SelectTags";
import messages_en from "../locale/en.json";
import messages_es from "../locale/es.json";
import { PRIVILEGES } from "utils/constants";
import moment from "moment-timezone"; //"moment";
import * as mimeTypes from "mime-types";
import { DateFormat, DateTimeFormat } from "./config";
const localeData = {
  en: messages_en,
  es: messages_es,
};
export const getExt = (path) => {
  if (!path) return "";
  let pos = path.lastIndexOf(".");
  return path.substring(pos + 1);
};
export const isValidTimezone = (timezone) => {
  return moment.tz.zone(timezone) !== null;
};
export const combineStationPivileges = (station = {}) => {
  let station_privileges = Object.values(station.user_privileges || {}).filter(
    function(e) {
      return this.indexOf(e) > -1;
    },
    Object.values(station.station_privileges || {})
  );
  return station_privileges;
};

export const combineStationPivilegesWithID = (station = {}, auth) => {
  const privileges = [];
  const authStation = find(
    get(auth, "info.stations", []),
    (item) => item.user_station_id === station.id
  );
  if (authStation) {
    for (let key in authStation.user_privileges || {}) {
      const priv = authStation.user_privileges[key];
      if (priv.indexOf("_servers") > -1) continue;
      if (priv.indexOf("_stations") > -1 && auth.user.id !== 1) continue;
      privileges.push({
        id: key,
        name: priv,
      });
    }
  }

  return privileges;
};

export const combineStationUserPrivilegesID = (station = {}) => {
  const privileges = [];
  for (let key in station.user_privileges || {}) {
    const priv = station.user_privileges[key];
    privileges.push({
      id: key,
      name: priv,
    });
  }
  return privileges;
};
export const combinePivilegesOfRolesAndCurrentPrivileges = (
  newRoles,
  currentPrivs,
  currentRoles
) => {
  const roleWasRemoved = differenceWith(currentRoles, newRoles, isEqual);
  let newPrivs = [];

  (newRoles || []).map((role) => {
    if (!isArray(role.privileges)) {
      forOwn(role.privileges, (priv, id) => {
        newPrivs.push({
          label: priv,
          value: id,
        });
      });
    }
    return role;
  });
  const seen = new Set();
  const filteredArr = newPrivs.filter((el) => {
    const duplicate = seen.has(el.value);
    seen.add(el.value);
    return !duplicate;
  });
  return filteredArr;
};

// Split locales with a region code
export const languageWithoutRegionCode = (locale) =>
  locale ? locale.toLowerCase().split(/[_-]+/)[0] : "en";

// Try full locale, try locale without region code, fallback to 'en'
export const getTranslates = (locale) => {
  const messages =
    localeData[languageWithoutRegionCode(locale)] ||
    localeData[locale] ||
    localeData.en;
  return messages;
};
export const isBetweenDates = ({
  date,
  startDate,
  endDate,
  timezone,
  inclusivity = "[]",
  dateTimezone,
}) => {
  if (!timezone) {
    try {
      timezone = moment.tz.guess();
    } catch (e) {
      timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
  }
  if (!dateTimezone) dateTimezone = timezone;
  const dateUnix =
    moment
      .tz(date, dateTimezone)
      .utc()
      .valueOf() / 1000;
  const startDateUnix =
    moment
      .tz(startDate, timezone)
      .utc()
      .valueOf() / 1000;
  const endDateUnix =
    moment
      .tz(endDate, timezone)
      .utc()
      .valueOf() / 1000;
  if (inclusivity === "()") {
    return dateUnix > startDateUnix && dateUnix < endDateUnix;
  }
  return (
    (dateUnix >= startDateUnix && dateUnix <= endDateUnix) ||
    dateUnix === startDateUnix ||
    dateUnix === endDateUnix
  );
};

export const isBorderRangeDates = ({ date, startDate, endDate, timezone }) => {
  if (!timezone) {
    try {
      timezone = moment.tz.guess();
    } catch (e) {
      timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
  }
  const dateUnix = moment.tz(date, timezone).valueOf() / 1000;
  const startDateUnix = moment.tz(startDate, timezone).valueOf() / 1000;
  const endDateUnix = moment.tz(endDate, timezone).valueOf() / 1000;
  return dateUnix === startDateUnix || dateUnix === endDateUnix;
};

export const countdownWithTimezone = (date, timezone, time = null) => {
  let deadline = `${date} 00:00:00`; // start of date

  if (time) {
    deadline = `${date} ${time}`;
  }
  if (!timezone) {
    try {
      timezone = moment.tz.guess();
    } catch (e) {
      // let lmoment = moment;
      timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
  }
  const currentUnix =
    moment()
      .tz(timezone)
      .valueOf() / 1000;
  const deadlineObj = moment.tz(deadline, timezone).valueOf() / 1000;
  let diff = deadlineObj - currentUnix;
  const timeLeft = {
    years: 0,
    days: 0,
    hours: 0,
    min: 0,
    sec: 0,
    millisec: 0,
    isNegative: false,
  };
  // clear countdown when date is reached
  if (diff <= 0) {
    diff = -1 * diff;
    timeLeft.isNegative = true;
  }
  // calculate time difference between now and expected date
  if (diff >= 365.25 * 86400) {
    // 365.25 * 24 * 60 * 60
    timeLeft.years = Math.floor(diff / (365.25 * 86400));
    diff -= timeLeft.years * 365.25 * 86400;
  }
  if (diff >= 86400) {
    // 24 * 60 * 60
    timeLeft.days = Math.floor(diff / 86400);
    diff -= timeLeft.days * 86400;
  }
  if (diff >= 3600) {
    // 60 * 60
    timeLeft.hours = Math.floor(diff / 3600);
    diff -= timeLeft.hours * 3600;
  }
  if (diff >= 60) {
    timeLeft.min = Math.floor(diff / 60);
    diff -= timeLeft.min * 60;
  }
  timeLeft.sec = parseInt(diff);
  return timeLeft;
};
export const countdown = (deadline) => {
  const currentUnix = new Date().getTime() / 1000;
  let diff = deadline - currentUnix;
  const timeLeft = {
    years: 0,
    days: 0,
    hours: 0,
    min: 0,
    sec: 0,
    millisec: 0,
    isNegative: false,
  };
  // clear countdown when date is reached
  if (diff <= 0) {
    diff = -1 * diff;
    timeLeft.isNegative = true;
  }
  // calculate time difference between now and expected date
  if (diff >= 365.25 * 86400) {
    // 365.25 * 24 * 60 * 60
    timeLeft.years = Math.floor(diff / (365.25 * 86400));
    diff -= timeLeft.years * 365.25 * 86400;
  }
  if (diff >= 86400) {
    // 24 * 60 * 60
    timeLeft.days = Math.floor(diff / 86400);
    diff -= timeLeft.days * 86400;
  }
  if (diff >= 3600) {
    // 60 * 60
    timeLeft.hours = Math.floor(diff / 3600);
    diff -= timeLeft.hours * 3600;
  }
  if (diff >= 60) {
    timeLeft.min = Math.floor(diff / 60);
    diff -= timeLeft.min * 60;
  }
  timeLeft.sec = parseInt(diff);
  return timeLeft;
};

export const addLeadingZeros = (value) => {
  value = String(value);
  while (value.length < 2) {
    value = "0" + value;
  }
  return value;
};
export const calculateDurationMp3 = (file) => {
  return new Promise((resolve, reject) => {
    const audio = new Audio();
    if (audio.canPlayType(file.type)) {
      const url = URL.createObjectURL(file);
      audio.addEventListener("canplaythrough", function(e) {
        const duration = e.currentTarget.duration;
        URL.revokeObjectURL(url);
        resolve(duration);
      });
      audio.addEventListener("error", function(e) {
        resolve(0);
        // reject(e);
      });
      audio.src = url;
      audio.load();
    } else {
      resolve(0);
    }
  });
};
const mimeTypesMp3 = [
  "audio/mpeg",
  "application/zip",
  "audio/mp3",
  "application/octet-stream",
  "audio/aac",
  "audio/x-aac",
  "audio/wav",
  "audio/wave",
  "audio/x-wav",
  "audio/flac",
  "audio/x-flac",
  "audio/m4a",
  "audio/x-m4a",
  // "audio/aiff",
  // "audio/x-aiff",
  "audio/mp4",
];
export const isMp3 = (mime = "", path) => {
  // exceptional extentions
  const extension = (path ? path.substr(-4) : "").toLowerCase();
  if (
    extension.toLowerCase() === ".ini" ||
    extension.toLowerCase() === ".m4a"
  ) {
    return false;
  }
  // by extention
  if (extension.toLowerCase() === ".mp3") {
    return true;
  }
  // by mime
  return mimeTypesMp3.includes(mime.toLowerCase());
};
export const isMp4 = (mime = "") => {
  if (!mime) return false;
  return mime.indexOf("mp4") > -1;
};
export const isImage = (mime = "") => {
  if (!mime) return false;
  return mime.indexOf("image/") > -1;
};
export const isPdf = (mime = "") => {
  if (!mime) return false;
  return mime.indexOf("pdf") > -1;
};
export const isRejectedUploadFiles = (name = "") => {
  const extension = lowerCase(getExt(name));
  return ["csv", "ods", "numbers", "xlsm"].indexOf(extension) !== -1;
};
const fileTypesSupportedViewer = [
  "doc",
  "docx",
  "htm",
  "html",
  "pdf",
  "ppt",
  "pptx",
  "txt",
  "xls",
  "xlsx",
];
export const checkIsSupportViewer = (file) => {
  if (!file) return false;
  const { path, type } = file;
  if (isMp3(type, path) || isImage(type) || isMp4(type)) return true;
  // support viewer by https://github.com/Alcumus/react-doc-viewer#readme
  const extension = lowerCase(getExt(path));
  return includes(fileTypesSupportedViewer, extension);
};

export const checkIsDocViewer = (file) => {
  if (!file) return false;
  const { path } = file;
  // support viewer by https://github.com/Alcumus/react-doc-viewer#readme
  const extension = lowerCase(getExt(path));
  return includes(fileTypesSupportedViewer, extension);
};

export const toHHMMSS = (secs) => {
  secs = Math.round(secs);
  var sec_num = parseInt(secs, 10); // don't forget the second param
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - hours * 3600) / 60);
  var seconds = sec_num - hours * 3600 - minutes * 60;

  if (hours < 10 && hours != "0") {
    hours = "0" + hours;
  }

  if (minutes < 10 && minutes != "0") {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return (
    (hours != "00" && hours != "0" ? hours + ":" : "") + minutes + ":" + seconds
  );
};

export const formatDisplayDate = (date, format) => {
  if (!date) return null;
  if (!moment(date).isValid()) return date; // return back origin string

  let _format =
    format || localStorage.getItem("dateFormatByServer") || DateFormat;
  const dateFormat = moment(date).format(_format);
  return dateFormat;
};

export const formatDisplayDateWithTimezone = (date, format, timezone) => {
  if (!date) return null;
  if (!moment(date).isValid()) return date; // return back origin string
  let _format = format || DateFormat;
  let localTimezone = null;
  try {
    localTimezone = moment.tz.guess();
  } catch (e) {
    localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  if (!timezone || !isValidTimezone(timezone)) {
    timezone = localTimezone;
  }

  const dateFormat = moment
    .tz(date, timezone)
    .tz(localTimezone)
    .format(_format);
  return dateFormat;
};

export const diffDateWithTimezone = (date, timezone, diffType = "days") => {
  let localTimezone = null;
  const now = moment().startOf("day");
  try {
    localTimezone = moment.tz.guess();
  } catch (e) {
    localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  if (!timezone || !isValidTimezone(timezone)) {
    timezone = localTimezone;
  }
  return moment
    .tz(date, timezone)
    .tz(localTimezone)
    .diff(now, diffType);
};
export const formatDisplayDateTime = (date) => {
  if (!date) return null;
  const momentDate = moment(date);
  let _format = localStorage.getItem("dateFormatByServer")
    ? `${localStorage.getItem("dateFormatByServer")} - hh:mm A`
    : DateTimeFormat;

  if (momentDate.isValid()) return momentDate.format(_format);
  return null;
};
export const getSortUserName = (name) => {
  const matches = name.match(/\b(\w)/g);
  const objOutput =
    matches.length >= 2 ? [matches[0], [...matches].pop()] : [matches[0]];
  return join(objOutput, "");
};
/*
 *   Below method will find all @ recursively replace with user name
 */

export const resolveTaggedUsers = (text = "", users) => {
  let finalStr = text || "";
  let arr = (text || "").match(/(@\[.*?\]\(.*?\))/g);
  if (arr) {
    for (let index = 0; index < arr.length; index++) {
      const item = arr[index]; // @[Sarju](1)
      const endSquareBracketPos = item.indexOf("]");
      const userId = item
        .substr(endSquareBracketPos + 1)
        .replace("(", "")
        .replace(")", "");
      const userIdInt = Number(userId);
      const user = find(users, { id: userIdInt }) || {};
      finalStr = finalStr.replace(item, user.name);
    }
  }
  return finalStr;
};

export const overrideDismiss = (className, val) => {
  if (className) {
    if (typeof className === "object") {
      return Object.values(className).indexOf(val) > -1;
    } else {
      return className.includes(val);
    }
  }
  return true;
};

export const dismissNotification = (elmnt) => {
  if (elmnt.path) {
    let index = elmnt.path.findIndex(
      (element) =>
        element.className &&
        typeof element.className === "string" &&
        element.className.includes("emoji-mart")
    );
    if (index > -1) return false;
  }
  return true;
};

export const setScriptField = (value) => {
  if (value) {
    if (Array.isArray(value)) {
      return value[value.length - 1];
    } else {
      if (typeof value === "object") {
        return value.value;
      }
      return value;
    }
  } else {
    return "";
  }
};

export const getUserName = (id, userArr) => {
  let userData = userArr.find((user) => user.id === id);
  return userData ? userData.name : "";
};

export const htmlToText = (html) =>
  html ? html.replace(/<\/?[^>]+>/gi, "") : "";

export const getFileName = (name) => {
  if (name) {
    let position = name.lastIndexOf("|");
    let final;
    if (position === -1) {
      position = name.lastIndexOf("___");
      final = name.slice(position + 3, name.length);
    } else {
      final = name.slice(position + 1, name.length);
    }

    return final;
  }
  return "";
};
export const labelToNameField = (label) => {
  // eslint-disable-next-line no-useless-escape
  return label.toLowerCase().replace(/[\s\.]/g, "_");
};
export const arrayContainsArray = (superset, subset) => {
  if (0 === subset.length) {
    return false;
  }
  return subset.every(function(value) {
    return superset.indexOf(value) >= 0;
  });
};
export const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const genRandomString = (length) => {
  var chars =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()";
  var charLength = chars.length;
  var result = "";
  for (var i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * charLength));
  }
  return result;
};

export const rolesMap = [
  {
    id: 1,
    name: "Sales Manager",
    field: "role_sales_person",
    tabs: ["order_details", "approve_script", "approve_recording", "air_check"],
  },
  {
    id: 2,
    name: "Account Executive",
    field: "role_sales_person",
    tabs: ["order_details", "approve_script", "approve_recording", "air_check"],
  },
  {
    id: 3,
    name: "Sales Assistant",
    field: "role_sales_person",
    tabs: ["order_details", "approve_script", "approve_recording", "air_check"],
  },
  {
    id: 4,
    name: "Production Manager",
    field: "role_production_manager",
    tabs: ["assign_team"],
  },
  {
    id: 5,
    name: "Producer",
    field: "role_producer",
    tabs: ["assign_vo", "record_script"],
  },
  {
    id: 6,
    name: "VO Talent",
    field: "role_voice_talent",
    tabs: ["voice_over"],
  },
  {
    id: 7,
    name: "Traffic Manager",
    tabs: ["continuity"],
  },
  {
    id: 8,
    name: "Continuity",
    field: "role_continuity",
    tabs: ["continuity"],
  },
  {
    id: 9,
    name: "Dubber",
    field: "role_dubber",
    tabs: ["dub_script"],
  },
  {
    id: 10,
    name: "Writer",
    field: "role_writer",
    tabs: ["write_script"],
  },
  {
    id: 11,
    name: "Station Admin",
    tabs: [],
  },
  {
    id: 12,
    name: "Channel Manager",
    field: "role_channel_manager",
    tabs: ["traffic", "report"],
  },
  {
    id: 13,
    name: "Digital Account Executive",
    field: "role_digital_account_executive",
    tabs: ["order_details", "review_media", "report"],
  },
  {
    id: 14,
    name: "Digital Producer",
    field: "role_digital_producer",
    privileges: { 43: "add_design", 44: "delete_design" },
    tabs: ["design", "media"],
  },
  {
    id: 15,
    name: "Market Admin/Group Admin",
    tabs: [],
  },
];

export const getFormFieldsWithConditionLogic = (channel) => {
  const fields = get(channel, "fields", []);
  let newFields = filter(fields, (item) => !item.is_controlled);
  const fieldConditionLogic = filter(fields, (item) => item.is_condition_logic);
  forEach(fieldConditionLogic, (fieldConditionLogicItem) => {
    const fieldName = labelToNameField(fieldConditionLogicItem.label);
    let val = get(channel, fieldName, "");
    if (Array.isArray(val)) {
      val = map(val, (itemVal) => itemVal.value);
    } else if (val && val.value) {
      val = val.value;
    }
    // selected
    const optionSelected = filter(
      fieldConditionLogicItem.options,
      (optionItem) => {
        if (Array.isArray(val)) {
          return (
            optionItem.rule &&
            optionItem.rule.is === "selected" &&
            includes(val, optionItem.rule.if)
          );
        }
        return (
          optionItem.rule &&
          optionItem.rule.is === "selected" &&
          optionItem.rule.if === val
        );
      }
    );
    let fieldsSelected = [];
    forEach(optionSelected, (itemOption) => {
      fieldsSelected = [
        ...fieldsSelected,
        ...get(itemOption, "rule.options", []),
      ];
    });
    // not selected
    const optionNotSelected = filter(
      fieldConditionLogicItem.options,
      (optionItem) => {
        if (Array.isArray(val)) {
          return (
            optionItem.rule &&
            optionItem.rule.is === "not selected" &&
            !includes(val, optionItem.rule.if)
          );
        }
        return (
          optionItem.rule &&
          optionItem.rule.is === "not selected" &&
          optionItem.rule.if !== val
        );
      }
    );
    let fieldsNotSelected = [];
    forEach(optionNotSelected, (itemOption) => {
      fieldsNotSelected = [
        ...fieldsNotSelected,
        ...get(itemOption, "rule.options", []),
      ];
    });
    let optionsFields = [...fieldsSelected, ...fieldsNotSelected];
    //to do map with field data from main channel fields.
    optionsFields = map(optionsFields, (itemField) => {
      const fieldData = find(
        channel.fields,
        (item) => item.key === itemField.key && item.label === itemField.label
      );
      return fieldData;
    });
    optionsFields = uniqBy(optionsFields, "label");
    let indexToAppend = findIndex(
      newFields,
      (newFieldItem) => newFieldItem.label === fieldConditionLogicItem.label
    );
    if (indexToAppend === 0) indexToAppend = 1;
    optionsFields.forEach((optionsFieldItem) => {
      // to do append current fields
      const isExists = find(
        newFields,
        (newFieldItem) =>
          get(newFieldItem, "label") === get(optionsFieldItem, "label")
      );
      if (!isExists) {
        newFields.splice(indexToAppend, 0, optionsFieldItem);
        indexToAppend++;
      }
    });
  });
  // keep order fields
  const newFieldsOrdered = [];
  fields.forEach((itemField) => {
    const fieldData = find(
      newFields,
      (f) => get(f, "label") === get(itemField, "label", "")
    );
    if (fieldData) {
      newFieldsOrdered.push(fieldData);
    }
  });
  return newFieldsOrdered;
};

export const fix_template_roles = (template, info) => {
  if (!info) return template;
  let data = cloneDeep(template);
  // let roles_map = [{id: 1, name: "Sales Manager"}
  // , {id: 2, name: "Account Executive"}
  // , {id: 3, name: "Sales assistant"}
  // , {id: 4, name: "Production manager"}
  // , {id: 5, name: "Producer"}
  // , {id: 6, name: "VO talent"}
  // , {id: 7, name: "Traffic manager"}
  // , {id: 8, name: "Continuity"}
  // , {id: 9, name: "Dubber"}
  // , {id: 10, name: "Writer"}
  // , {id: 11, name: "Station Admin"}
  // , {id: 12, name: "Channel Manager"}
  // , {id: 13, name: "Digital Account Executive"}
  // , {id: 14, name: "Digital Producer", privileges: {43: "add_design", 44: "delete_design"}}
  // , {id: 15, name: "Market Admin/Group Admin"}];

  const fix_roles = (arr) => {
    let res = [];
    arr.map((a) => {
      let role = rolesMap.find((r) => r.id === a);
      if (!role) {
        debugger;
        return;
      }
      let role2 = find(
        info.roles,
        (r) => r.name.toLowerCase() === role.name.toLowerCase()
      );
      if (role2) {
        res.push(role2.id);
        // debugger;
        // throw role.name + ' was not found in info.roles';
      } else {
        // console.error(role.name + ' was not found in info.roles');
      }
    });
    return res;
  };
  var steps = data.steps;
  Object.keys(steps).map((s) => {
    let step = data.steps[s];
    if (step.step_roles) step.step_roles = fix_roles(step.step_roles);
    if (step.step_editors_roles)
      step.step_editors_roles = fix_roles(step.step_editors_roles);
    if (step.step_viewers_roles)
      step.step_viewers_roles = fix_roles(step.step_viewers_roles);
    if (step.fields) {
      step.fields.map((f, ifield) => {
        let field = data.steps[s].fields[ifield];
        if (field.roles) field.roles = fix_roles(field.roles);
      });
    }
  });
  return data;
};
export const getStepForTabEdit = (step, process, user, userInfo) => {
  let stepKey = step.key;
  const isHubOrder = !isEmpty(
    get(process, "data.fields.team_production_manager.value", "")
  );
  const isHubManager = getRoleIdByName(
    "Hub Manager",
    get(userInfo.enterprise_roles)
  );
  const isCheckedOrderAssignments = !!get(
    userInfo,
    "show_hub_assignment",
    false
  );
  // is enable continuity queue (missed the field role_continuity in process data) and user is going to continuity tab
  const roleContinuityUsers = get(
    process,
    "data.fields.role_continuity.value.users"
  );
  if (stepKey === "continuity" && !roleContinuityUsers) return step.key;

  if (
    stepKey === "assign_team" &&
    isHubOrder &&
    (isHubManager || isCheckedOrderAssignments)
  )
    return step.key;

  const templateKey = get(process, "data.fields.template_key.value", "");
  const isUserCompletedStep = ["completed", "approved"].includes(
    get(process, `data.fields.${stepKey}_status.value`)
  );
  // if user is editing a completed tab
  if (isUserCompletedStep) return step.key;
  let assignedRoles = [];
  const rolesOrder = [
    "role_sales_person",
    "role_production_manager",
    "role_writer",
    "role_voice_talent",
    "role_producer",
    "role_continuity",
    "role_dubber",
  ];
  const spotInfo = get(process, "data.fields.spot_info.value", []).find(
    (spot) => spot.key === process.key
  );

  const producerAssignsVo = get(
    process,
    "data.fields.producer_assigns_vo.value",
    false
  );
  rolesOrder.forEach((f) => {
    let users = get(process, `data.fields.${f}.value.users`);
    if (!users) {
      users = [];
      if (get(process, `data.fields.${f}.value.id`)) {
        users.push(process.data.fields[f].value);
      }
    }
    let isAssigned = !!find(users, (u) => parseInt(u.id) === parseInt(user.id));
    if (isAssigned) {
      const roleData = find(rolesMap, (role) => role.field === f);
      // get tabs still not done and visible
      const roleDataTabs = roleData.tabs.filter((tab) => {
        const tabStatus = get(process, `data.fields.${tab}_status.value`);
        const isTabNotComplete = !["completed", "approved"].includes(tabStatus);
        let isVisible = true;
        if (tab === "assign_vo") {
          isVisible = producerAssignsVo && templateKey === "basic_production";
        } else if (tab === "approve_script") {
          isVisible =
            get(process, "data.fields.write_script_status.value") ===
              "completed" &&
            get(process, "data.fields.approve_script_status.value") !==
              "approved" &&
            get(spotInfo, "script_requires_approval", false) &&
            templateKey === "basic_production";
        } else if (tab === "approve_recording") {
          isVisible =
            get(process, "data.fields.record_script_status.value") ===
              "completed" &&
            get(process, "data.fields.approve_recording_status.value") !==
              "approved" &&
            get(spotInfo, "recording_requires_approval", false) &&
            templateKey === "basic_production";
        } else if (tab === "air_check") {
          isVisible =
            get(spotInfo, "aircheck_requires_approval", false) &&
            !get(process, "data.fields.order_sold.value");
        }
        return isTabNotComplete && isVisible;
      });
      const mainTabForRole = first(roleDataTabs);
      if (mainTabForRole) {
        assignedRoles.push({
          field: f,
          roleData: {
            ...roleData,
            tabs: roleDataTabs,
          },
        });
      }
    }
  });
  let firstAssignedRole = first(assignedRoles);
  // Vo + Producer is same
  if (
    assignedRoles.find((role) => role.field === "role_voice_talent") &&
    assignedRoles.find((role) => role.field === "role_producer") &&
    step.key === "record_script"
  ) {
    firstAssignedRole = assignedRoles.find(
      (role) => role.field === "role_producer"
    );
  }
  // Vo + producer is the same and enabled producerAssignsVo
  if (
    assignedRoles.find((role) => role.field === "role_voice_talent") &&
    assignedRoles.find((role) => role.field === "role_producer") &&
    step.key === "voice_over" &&
    producerAssignsVo
  ) {
    firstAssignedRole = assignedRoles.find(
      (role) => role.field === "role_producer"
    );
  }
  // AE + Producer is same and next tab is assign vo or record or recording_
  if (
    assignedRoles.find((role) => role.field === "role_sales_person") &&
    assignedRoles.find((role) => role.field === "role_producer") &&
    (step.key === "assign_vo" || step.key === "record_script")
  ) {
    firstAssignedRole = assignedRoles.find(
      (role) => role.field === "role_producer"
    );
  }
  // AE + PM is the same and next tab is assignments
  if (
    assignedRoles.find((role) => role.field === "role_sales_person") &&
    assignedRoles.find((role) => role.field === "role_production_manager") &&
    step.key === "assign_team"
  ) {
    firstAssignedRole = assignedRoles.find(
      (role) => role.field === "role_production_manager"
    );
  }
  // AE + Writer is the same and next tab is script
  if (
    assignedRoles.find((role) => role.field === "role_sales_person") &&
    assignedRoles.find((role) => role.field === "role_writer") &&
    step.key === "write_script"
  ) {
    firstAssignedRole = assignedRoles.find(
      (role) => role.field === "role_writer"
    );
  }
  // Continuity or Dubber + Sales is same
  const roleSalesPerson2Id = get(
    process,
    "data.fields.role_sales_person_2.value.id"
  );
  const roleSalesPersonId = get(
    process,
    "data.fields.role_sales_person.value.id"
  );
  if (
    (assignedRoles.find((role) => role.field === "role_continuity") ||
      assignedRoles.find((role) => role.field === "role_dubber")) &&
    (parseInt(roleSalesPerson2Id) === parseInt(user.id) ||
      parseInt(roleSalesPersonId) === parseInt(user.id)) &&
    step.key === "approve_recording"
  ) {
    return step.key; // return approve_recording
  }
  const lastEditTab = get(process, "lastEditTab", []);
  if (firstAssignedRole && firstAssignedRole.field === "role_producer") {
    stepKey = first(firstAssignedRole.roleData.tabs);
    // if step key = assign vo and the order don't enable the producer assign vo -> add step to Record
    if (stepKey === "assign_vo" && !producerAssignsVo) {
      stepKey = "record_script";
    }
  } else if (firstAssignedRole) {
    stepKey = first(firstAssignedRole.roleData.tabs);
  }
  // check user opened this step. User can open other tab
  if (lastEditTab.indexOf(stepKey) > -1) {
    stepKey = step.key;
  }
  return stepKey;
};

export const generateRotationChart = (process, stations, spots) => {
  const currentRotationChart = get(
    process,
    "data.fields.rotation_chart.value",
    []
  );
  const totalSpots = spots.length;
  // create rows
  const rows = map(stations, (station) => {
    // get from current rotation chart
    const exists = find(currentRotationChart, (item) => item === station);
    if (exists) return exists;
    const percent = 100 / totalSpots;
    const rotationValue = map(new Array(totalSpots), () => Math.round(percent));
    return {
      station,
      ...rotationValue,
    };
  });
  return rows;
};

const tabsByStage = {
  basic_production: {
    draft: ["order_details", "continuity"],
    assign_team: ["order_details", "assign_team", "continuity"],
    write_script: [
      "order_details",
      "assign_team",
      "write_script",
      "continuity",
    ],
    approve_script: [
      "order_details",
      "assign_team",
      "write_script",
      "approve_script",
      "continuity",
    ],
    assign_vo: [
      "order_details",
      "assign_team",
      "write_script",
      "approve_script",
      "assign_vo",
      "continuity",
    ],
    voice_over: [
      "order_details",
      "assign_team",
      "write_script",
      "approve_script",
      "assign_vo",
      "voice_over",
      "record_script", // When an order moves into the 'Voice Over' status, both the VO tab and RECORD tab will become active.
      "continuity",
    ],
    record_script: [
      "order_details",
      "assign_team",
      "write_script",
      "approve_script",
      "assign_vo",
      "voice_over",
      "record_script",
      "continuity",
    ],
    approve_recording: [
      "order_details",
      "assign_team",
      "write_script",
      "approve_script",
      "assign_vo",
      "voice_over",
      "record_script",
      "approve_recording",
      "continuity",
    ],
    continuity: [
      "order_details",
      "assign_team",
      "write_script",
      "approve_script",
      "assign_vo",
      "voice_over",
      "record_script",
      "approve_recording",
      "continuity",
    ],
    dub_script: [
      "order_details",
      "assign_team",
      "write_script",
      "approve_script",
      "assign_vo",
      "voice_over",
      "record_script",
      "approve_recording",
      "continuity",
      "dub_script",
    ],
    air_check: [
      "order_details",
      "assign_team",
      "write_script",
      "approve_script",
      "assign_vo",
      "voice_over",
      "record_script",
      "approve_recording",
      "continuity",
      "dub_script",
      "air_check",
    ],
    done: [
      "order_details",
      "assign_team",
      "write_script",
      "approve_script",
      "assign_vo",
      "voice_over",
      "record_script",
      "approve_recording",
      "continuity",
      "dub_script",
      "air_check",
    ],
  },
  production_provided: {
    draft: ["order_details", "continuity"],
    continuity: ["order_details", "continuity"],
    record_script: ["order_details", "continuity", "record_script"],
    dub_script: ["order_details", "continuity", "record_script", "dub_script"],
    air_check: [
      "order_details",
      "continuity",
      "record_script",
      "dub_script",
      "air_check",
    ],
    done: [
      "order_details",
      "continuity",
      "record_script",
      "dub_script",
      "air_check",
    ],
  },
  digital: {
    draft: ["order_details"],
    design: ["order_details", "design"],
    review_media: ["order_details", "design", "review_media"],
    traffic: ["order_details", "review_media", "traffic"],
    report: ["order_details", "review_media", "traffic", "report"],
    done: ["order_details", "design", "review_media", "traffic", "report"],
    online: ["order_details", "design", "review_media", "traffic", "report"],
  },
};
export const getEditableTabsByStage = (step, process, user) => {
  // add default process step to draft when user create new order from produced spots -> process_step is undefined
  const processStep = get(process, "data.fields.process_step.value", "draft");

  // Vo + producer is the same, assigned to current user and enabled producerAssignsVo
  const producerAssignsVo = get(
    process,
    "data.fields.producer_assigns_vo.value",
    false
  );
  const roleVoiceTalens = get(
    process,
    "data.fields.role_voice_talent.value.users",
    []
  ).map((voice) => voice.id);
  const roleProducers = get(
    process,
    "data.fields.role_producer.value.users",
    []
  ).map((producer) => producer.id);
  const userAssignedToProducers =
    user && roleProducers.includes(Number(user.id));
  const userAssignedToVoiceTalens =
    user && roleVoiceTalens.includes(Number(user.id));
  if (
    producerAssignsVo &&
    user &&
    step.key === "record_script" &&
    userAssignedToProducers &&
    userAssignedToVoiceTalens
  ) {
    return true;
  }
  const template = get(process, "data.fields.template_key.value");
  const tabsEditable = get(tabsByStage, `${template}.${processStep}`);
  return !!find(tabsEditable, (item) => item === step.key);
};
// export const checkIsShowEditButton = ({ user, step, process }) => {
//   let found = false;
//   let isShowEditButton = true;
//   let canEditAnyTime = false;
//   if (step.key === "order_details") {
//     canEditAnyTime =
//       step.can_edit_any_time &&
//       (parseInt(user.id) ===
//         parseInt(get(process, "data.fields.role_sales_person.value.id")) ||
//         parseInt(user.id) ===
//           parseInt(get(process, "data.fields.role_sales_person_2.value.id")));
//   }
//   if (step.key === "dub_script") {
//     const isDubberUser = get(
//       process,
//       "data.fields.role_dubber.value.users",
//       []
//     ).find((item) => parseInt(user.id) === parseInt(item.id));
//     canEditAnyTime = step.can_edit_any_time && isDubberUser;
//   }

//   const isUserAssigned =
//     get(process, "data.fields.next_users.value", []).indexOf(user.id) !== -1;
//   // Flag for check current use can edit step he submitted
//   let isUserCompletedStep = false;
//   const isTabCanEditByStage = getEditableTabsByStage(step, process);
//   Object.keys(user.roles).forEach((r) => {
//     if (step.step_editors_roles.indexOf(parseInt(r)) > -1) {
//       let roleName = user.roles[r];
//       const roleWithKey = mapRoleIdToFieldKey(roleName);
//       const roleKey = get(roleWithKey, "field");
//       let isUserCanAccessToTab = false;
//       const roleValue = get(process, `data.fields.${roleKey}.value`);
//       if (roleKey && roleValue) {
//         let assignedUsersRole = get(roleValue, "users")
//           ? roleValue.users
//           : [roleValue];
//         isUserCanAccessToTab = !!find(
//           assignedUsersRole,
//           (item) => parseInt(item.id) === parseInt(user.id)
//         );
//         const roleWithCurrentStep = find(
//           roleWithKey.tabs,
//           (stepKey) => stepKey === step.key
//         );
//         isUserCompletedStep = !!get(
//           process,
//           `data.fields.${roleWithCurrentStep}_status`
//         );
//       }

//       if (
//         ((isUserAssigned && isTabCanEditByStage) || isUserCompletedStep) &&
//         isUserCanAccessToTab
//       ) {
//         found = true;
//       }
//     }
//   });
//   if (!found && !canEditAnyTime) isShowEditButton = false;

//   return true;
// };

export const createMessage = (payload, changed_step_data) => {
  let { process, template, step, user } = payload;

  let message = {
    delta: { ...changed_step_data },
    from: {
      id: user.id,
      name: user.name,
      avatar_url: user.avatar_url,
    },
    order_stations:
      process.data.fields.order_stations &&
      process.data.fields.order_stations.value
        ? process.data.fields.order_stations.value
        : [],
    template: template.key,
    // process: process.key,
    order_title: process.data.fields.order_title
      ? process.data.fields.order_title.value
      : "untitled",
    from_step: step.key,
    to_step: process.data.fields.process_step
      ? process.data.fields.process_step.value
      : "",
    team: process.data.fields.team ? process.data.fields.team.value : [],
    still_required_users: process.data.fields.still_required_users
      ? process.data.fields.still_required_users.value
      : [],
    next_users: process.data.fields.next_users
      ? process.data.fields.next_users.value
      : [],
  };
  if (user.impersonated_by) {
    message.from.impersonated_by = user.impersonated_by;
  }
  return message;
};

export function getTagOptionsByType(type, auth) {
  let tagOptions = [];
  let tags = get(auth, "info.tags.tags", []);
  switch (type) {
    case TAG_TYPE_LOCATION:
      tags = filter(tags, (tag) => includes(tag, "Location"));
      break;
    case TAG_TYPE_FORMAT:
      tags = filter(tags, (tag) => includes(tag, "Format"));
      break;
    case TAG_TYPE_EXCEPT_LOCATION_FORMAT:
      tags = filter(
        tags,
        (tag) => !includes(tag, "Format") && !includes(tag, "Location")
      );
      break;
    default:
      break;
  }
  let map_tags = (tag, path) => {
    tagOptions.push({
      value: tag.id,
      label: path,
      data: tag,
    });
    tag.children &&
      tag.children.map((child) => {
        map_tags(child, path + " / " + child.title);
      });
  };
  tags.map((tag) => {
    map_tags(tag, tag.title);
  });
  return tagOptions;
}
export function getSelectedTagOptions(tagsOptions, user) {
  let selectedTagOptions = [];
  const tags = get(user, "tags", []);

  forEach(tags, (item) => {
    let existsIndex = findIndex(
      tagsOptions,
      (selected) => Number(selected.value) === Number(item)
    );
    if (existsIndex !== -1) {
      selectedTagOptions.push({
        value: get(tagsOptions, `${existsIndex}.value`),
        label: get(tagsOptions, `${existsIndex}.label`),
        data: get(tagsOptions, `${existsIndex}.data`),
      });
    }
  });
  return selectedTagOptions;
}
export function getSelectedMarketOptions(locationOptions, user) {
  let selectedMarketOptions = [];
  const tags = get(user, "tags", []);
  forEach(tags, (item) => {
    let existsIndex = findIndex(
      locationOptions,
      (selected) => Number(selected.value) === Number(item)
    );

    if (existsIndex !== -1) {
      selectedMarketOptions.push({
        value: get(locationOptions, `${existsIndex}.value`),
        label: get(locationOptions, `${existsIndex}.label`),
        data: get(locationOptions, `${existsIndex}.data`),
      });
    }
  });
  return selectedMarketOptions;
}

export function getHourLabel(hour) {
  if (hour === 0) {
    return "12 AM";
  }

  if (hour <= 11) {
    return `${hour} AM`;
  }

  if (hour === 12) {
    return "12 PM";
  }

  return `${hour - 12} PM`;
}
export function getWidthHeightFromUrl(url) {
  // url: http://promosuite-dev.s3.amazonaws.com/e/9/1/f-0e29-4ef7-be8e-747299dbb97fbbe84300-6502-4cc5-84cd-5004b4d73bf7___640x426___sample_640%C3%97426.jpg
  const array = split(url, "___");
  if (array.length !== 3) return { width: null, height: null };
  const data = split(array[1], "x");
  return { width: parseInt(first(data)), height: parseInt(last(data)) };
}

export function getFileNameFromUrl(url) {
  if (!url) return "";
  const position = url.lastIndexOf("||"); // old format
  const symbol = position !== -1 ? "||" : "___"; // keep old format file
  // url: https://promosuite-dev.s3.amazonaws.com/5/0/a/c-9f7e-454d-b86b-3ca974e190282891f1a8-de5a-4715-bf3f-95b5cfb0565a||file-sample_150kB.pdf
  const array = split(url, symbol);
  return last(array);
}

export function validURL(str) {
  try {
    new URL(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function validSSN(str) {
  const blackList = [
    "000000000",
    "111111111",
    "222222222",
    "333333333",
    "444444444",
    "555555555",
    "666666666",
    "777777777",
    "888888888",
    "999999999",
  ];

  if (!str) return false;
  const isInBackList = blackList.find((i) => i === str.toString());

  if (isInBackList) return false;
  return /^(?!(000|666|9))(\d{3}-?(?!(00))\d{2}-?(?!(0000))\d{4})$/.test(str)
    ? true
    : false;
}

export function firstUndoneStep(user, template, process, step, item) {
  let firstUndone = "done";
  Object.keys(template.steps).map((key, ind) => {
    let step = template.steps[key];
    // let is_disabled =
    //   step.is_disabled &&
    //   safeEval(step.is_disabled)(user, process, step);
    let is_hidden = step.is_hidden && step.is_hidden(user, process, step);
    let is_ignore = step.is_ignore;
    let state = "undone";
    if (get(process, `data.fields.${step.key + "_status"}.value`)) {
      if (process.data.fields[step.key + "_status"].value === "draft")
        state = "draft";
      else if (process.data.fields[step.key + "_status"].value === "rejected")
        state = "rejected";
      else state = "done";
    }
    if (
      !is_hidden &&
      !is_ignore &&
      firstUndone === "done" &&
      state !== "done" &&
      step.key !== "log"
    )
      firstUndone = step.key;
    return key;
  });
  return firstUndone;
}
export function getHours() {
  const hours = Array.from(
    {
      length: 24,
    },
    (_, hour) => {
      return {
        label: moment({
          hour: hour,
        }).format("h A"),
        value: hour,
      };
    }
  );
  return hours;
}
export function convertArrayToString(array = [], join = ",", lastJoin = "and") {
  return array.length > 0
    ? array.reduce(
        (text, value, i, array) =>
          text + (i < array.length - 1 ? `${join} ` : ` ${lastJoin} `) + value
      )
    : "";
}
export function validatePrivileges(props) {
  const { requires, user, privileges } = props;
  const authIsGodAdmin = get(user, "is_god_admin", false);
  const authIsSupportAdmin = get(user, "is_support_admin", false);
  // const authIsAdmin = get(user, "is_admin", false);
  // const authIsBroadcastingGroupAdmin = get(
  //   user,
  //   "broadcasting_group_admin",
  //   false
  // );

  if (authIsGodAdmin) {
    if (authIsSupportAdmin) {
      const supportAdminCantDoList = [
        PRIVILEGES.VIEW_SERVERS,
        PRIVILEGES.ADD_SERVERS,
        PRIVILEGES.DELETE_SERVERS,
        PRIVILEGES.ADD_STATIONS,
        PRIVILEGES.DELETE_STATIONS,
      ];

      if (requires instanceof Array) {
        return requires.every(
          (privilege) => !includes(supportAdminCantDoList, privilege)
        );
      }
    }

    return true;
  }
  // broadcasting admin can do anything except add/delete server privileges
  /*if (authIsBroadcastingGroupAdmin) {
    if (requires instanceof Array) {
      const serverPrivileges = [
        PRIVILEGES.ADD_SERVERS,
        PRIVILEGES.DELETE_SERVERS,
      ];
      return requires.every(
        (privilege) => serverPrivileges.indexOf(privilege) === -1
      );
    }
  }*/
  // Group Market admin can do anything for stations

  /*if (authIsAdmin) {
    if (requires instanceof Array) {
      const stationsPrivileges = [
        PRIVILEGES.VIEW_STATIONS,
        PRIVILEGES.EDIT_STATIONS,
        PRIVILEGES.VIEW_TEAMS,
        PRIVILEGES.EDIT_TEAMS,
      ];
      return requires.every(
        (privilege) => stationsPrivileges.indexOf(privilege) !== -1
      );
    }
  }*/
  let isValid = false;
  if (typeof requires === "function") {
    isValid = requires(privileges);
  } else if (requires instanceof Array) {
    const andConditionValid = requires.every(
      (privilege) => privileges.indexOf(privilege) > -1
    );
    isValid = andConditionValid;
  } else {
    const andConditions = requires.and || [];
    const orConditions = requires.or || [];
    const andConditionValid = andConditions.every(
      (privilege) => privileges.indexOf(privilege) > -1
    );
    const orConditionValid = orConditions.some(
      (privilege) => privileges.indexOf(privilege) > -1
    );
    isValid = andConditionValid && orConditionValid;
  }
  return isValid;
}
export const truncateString = (str, n) => {
  return str.length > n ? str.substr(0, n - 1) + "..." : str;
};
/*
 * `requires` should be either array, object.
 * 1. If array is passed then all privileges will be considered as required to able to access
 * 2. For object, below is the structure
 * {
 * 	"and": [<LIST_OF_PRIVILEGES>], // all privileges are required from array to able to access
 * 	"or": [<LIST_OF_PRIVILEGES>] // any of one privilege is required from array to able to access
 * }
 */
export const stepAssignedByRoles = {
  basic_production: {
    order_details: ["role_sales_person", "role_sales_person_2"],
    assign_team: ["role_production_manager"],
    write_script: ["role_writer"],
    approve_script: ["role_sales_person", "role_sales_person_2"],
    assign_vo: ["role_producer"],
    voice_over: ["role_voice_talent"],
    record_script: ["role_producer"],
    approve_recording: ["role_sales_person", "role_sales_person_2"],
    continuity: ["role_continuity"],
    dub_script: ["role_dubber"],
    air_check: ["role_sales_person", "role_sales_person_2"],
  },
  production_provided: {
    order_details: ["role_sales_person", "role_sales_person_2"],
    record_script: ["role_producer"],
    continuity: ["role_continuity"],
    dub_script: ["role_dubber"],
  },
  digital: {
    order_details: ["role_digital_account_executive"],
    design: ["role_digital_producer"],
    review_media: ["role_digital_account_executive"],
    traffic: ["role_channel_manager"],
    report: ["role_channel_manager"],
  },
};
export function getEditableTabByUser({
  step,
  process,
  user,
  template,
  userInfo,
}) {
  const processStep = get(process, "data.fields.process_step.value", "draft");

  const isHubOrder = !isEmpty(
    get(process, "data.fields.team_production_manager.value", "")
  );
  const isHubManager = getRoleIdByName(
    "Hub Manager",
    get(userInfo, "enterprise_roles", [])
  );
  const isCheckedOrderAssignments = !!get(
    userInfo,
    "show_hub_assignment",
    false
  );

  const isAssignedProducers = !!get(
    process,
    "data.fields.role_producer.value.users",
    []
  ).find((item) => Number(item.id) === Number(user.id));

  const stepKey = step.key;
  // check step can edit any time;
  const isStepCanEditAnyTime = step.can_edit_any_time;
  // check user completed tab by step status and user assign to step roles
  const status = ["rejected", "completed", "approved"];
  const isStepCompleted = includes(
    status,
    get(process, `data.fields.${stepKey}_status.value`)
  );
  const rolesAssignedStep = get(
    stepAssignedByRoles,
    `${template}.${stepKey}`,
    []
  );
  let usersAssignedToRoles = [];
  forEach(rolesAssignedStep, (role) => {
    let users = [];
    // assign multiple users
    if (get(process, `data.fields.${role}.value.users`)) {
      users = get(process, `data.fields.${role}.value.users`, []);
    } else if (get(process, `data.fields.${role}.value`)) {
      users = [get(process, `data.fields.${role}.value`)];
    }
    usersAssignedToRoles = [...usersAssignedToRoles, ...users];
  });

  let hasAccessFrom = [];
  if (
    includes(
      ["draft", "order_details", "approve_script", "approve_recording"],
      stepKey
    )
  ) {
    hasAccessFrom = map(get(user, "has_access_from_users", []), (user) => {
      return {
        id: user.id,
        name: user.name,
      };
    });
    usersAssignedToRoles = [...usersAssignedToRoles, ...hasAccessFrom];
  }

  const isUserAssignedToStepRoles = usersAssignedToRoles.some((item) => {
    return parseInt(item.id) === parseInt(user.id);
  });

  const privileges = get(user, "privileges", []);
  // check user assigned to current task
  const nextUsers = get(process, "data.fields.next_users.value", []).map((u) =>
    parseInt(u)
  );

  let canUserEditAllOrders = false;

  if (template === "digital") {
    if (privileges.indexOf(PRIVILEGES.EDIT_ALL_DIGITAL_ORDERS) > -1) {
      canUserEditAllOrders = true;
    }
  } else {
    if (stepKey === "continuity") {
      // https://tasks.getventive.com/projects/B5A74-567
      // continuity needs to only have access to complete the continuity step for all orders in the BG regardless if they are assigned to the order for MM and Single Market.
      canUserEditAllOrders = validatePrivileges({
        requires: {
          or: [
            PRIVILEGES.EDIT_ALL_PRODUCTION_ORDERS,
            PRIVILEGES.PERFORM_PRODUCTION_CONTINUITY,
          ],
        },
        privileges,
        user,
      });
    } else if (stepKey === "assign_team") {
      const isHasStationEnableAssignmentsQueue = !!find(
        get(userInfo, "stations", []),
        (station) =>
          get(station, "production_settings.assignments_queue", false)
      );
      if (
        validatePrivileges({
          requires: [PRIVILEGES.ASSIGN_PRODUCTION_TEAM],
          privileges,
          user,
        }) &&
        isHasStationEnableAssignmentsQueue
      ) {
        canUserEditAllOrders = true;
      } else if (
        validatePrivileges({
          requires: [PRIVILEGES.EDIT_ALL_PRODUCTION_ORDERS],
          privileges,
          user,
        })
      ) {
        canUserEditAllOrders = true;
      } else if (isHubOrder && (isHubManager || isCheckedOrderAssignments)) {
        canUserEditAllOrders = true;
      }
    } else {
      if (
        validatePrivileges({
          requires: [PRIVILEGES.EDIT_ALL_PRODUCTION_ORDERS],
          privileges,
          user,
        })
      ) {
        canUserEditAllOrders = true;
      }
    }
  }

  const isUserAssigned =
    (processStep === "voice_over" &&
      stepKey === "record_script" &&
      isAssignedProducers) || //PRODUCTION - VO and Record tab should become active at same time, in case VO is bypassed
    (nextUsers.indexOf(user.id) !== -1 && isUserAssignedToStepRoles) ||
    canUserEditAllOrders ||
    !isEmpty(
      intersection(
        nextUsers,
        map(hasAccessFrom, (user) => user.id)
      )
    );

  // Check if the user is channel manager
  const marketingChannels = get(
    process,
    "data.fields.marketing_channels.value",
    []
  );
  const allChannelManagers = flatten(
    map(marketingChannels, "channel_managers")
  );
  const isChannelManager = !!find(allChannelManagers, { value: user.id });

  // check user have privileges for edit

  const requires = get(step, "step_editors_privileges", []);
  let isValid = false;
  const checkValidPrivilege = (privilege) => {
    if (PRIVILEGES.ASSIGN_PRODUCTION_TEAM === privilege) {
      return (
        isTeamLead(user) ||
        privileges.indexOf(privilege) > -1 ||
        (isHubOrder && (isHubManager || isCheckedOrderAssignments))
      );
    }
    return privileges.indexOf(privilege) > -1;
  };
  if (requires instanceof Array) {
    const andConditionValid = requires.every((privilege) =>
      checkValidPrivilege(privilege)
    );
    isValid = andConditionValid;
  } else {
    const andConditions = requires.and || [];
    const orConditions = requires.or || [];
    const andConditionValid = andConditions.every((privilege) =>
      checkValidPrivilege(privilege)
    );
    const orConditionValid = orConditions.some((privilege) =>
      checkValidPrivilege(privilege)
    );
    isValid = andConditionValid && orConditionValid;
  }
  return {
    isAssigned: isUserAssigned,
    isEditor: isValid,
    isUserCompletedStep: isUserAssignedToStepRoles && isStepCompleted,
    isUserAssignedToStepRoles,
    isStepCanEditAnyTime,
    isChannelManager,
  };
}
// We will also check if the user is a Team Lead wherever we check on "Assign Team". So anyone enabled as Team Lead should be given the ability to Assign their Team members
export function isTeamLead(user) {
  return user.is_team_lead;
}
export function getTeamLeadMembers(teams) {
  let members = [];
  if (teams.length === 0) return [];
  forEach(teams, (team) => {
    members = [...members, ...get(team, "team_lead", [])];
  });
  members = sortBy(members, (item) => item.name.toLowerCase());
  return members;
}
export const getRoleIdByName = (roleName, roles) => {
  const role = find(roles, (item) => toLower(item.name) === toLower(roleName));
  return get(role, "id", "");
};
export const generateShowsSpanMultipleDays = (showsToDisplay) => {
  let items = [];
  const endTimeOfDay = 1439; //11h59 PM
  const showsFormatted = map(showsToDisplay, (show) => {
    return {
      ...show,
      end_time: show.end_time === 0 ? 1440 : show.end_time,
    };
  });
  forEach(showsFormatted, (show) => {
    const startTime = parseInt(get(show, "start_time"));
    const endTime = parseInt(get(show, "end_time"));

    // Shows Should Be Able To Span Multiple Days
    if (startTime > endTime) {
      // Get show from start time to end time of this day.
      items = [
        ...items,
        {
          ...show,
          // days: [parseInt(show.day)], // avoid overlap
          start_time: startTime,
          end_time: endTimeOfDay,
          original: show,
          isShowDescription: true,
        },
      ];
      // Get show from start time of next day to end time.
      items = [
        ...items,
        {
          ...show,
          start_time: endTime === 0 ? startTime : 0, // end time 12AM need to get start time
          end_time: endTime,
          day: parseInt(show.day) + 1,
          days: map(show.days, (i) => parseInt(i) + 1).map((i) => {
            if (i === 8) return 1;
            return i;
          }),
          original: show,
          isShowDescription: endTime !== 0,
        },
      ];
    } else {
      items = [
        ...items,
        {
          ...show,
          original: show,
          isShowDescription: true,
        },
      ];
    }
  });
  return items;
};
function convertToPlain(html) {
  // Create a new div element
  var tempDivElement = document.createElement("div");
  // Set the HTML content with the given value
  tempDivElement.innerHTML = html;

  // Retrieve the text property of the element
  return tempDivElement.textContent || tempDivElement.innerText || "";
}
export const copyToClipboard = (html) => {
  const textToCopy = convertToPlain(html);
  // navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    // navigator clipboard api method'
    return navigator.clipboard.writeText(textToCopy);
  } else {
    // text area method
    const textArea = document.createElement("textarea");
    textArea.value = convertToPlain;
    // make the textarea out of viewport
    textArea.style.position = "fixed";
    textArea.style.left = "-999999px";
    textArea.style.top = "-999999px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise((res, rej) => {
      // here the magic happens
      document.execCommand("copy") ? res() : rej();
      textArea.remove();
    });
  }
};

export const isUserShowStyleTags = (user) => {
  const privileges = values(user.privileges);
  /**
   * Kenny mention:
   * it should be the privileges of those roles. PRODUCTION_MANAGER, PRODUCER, WRITER, VO_TALENT share Edit Script, View Order, Upload File, and Delete File, and a couple share Edit VO priv. Let's replace checking on those roles with ensuring the user has at least 3 of those 5 permissions.
   */
  const listValidPrivileges = [
    PRIVILEGES.EDIT_PRODUCTION_SCRIPT,
    PRIVILEGES.VIEW_PRODUCTION,
    PRIVILEGES.UPLOAD_FILE,
    PRIVILEGES.DELETE_FILE,
    PRIVILEGES.EDIT_VO,
  ];
  const isSpecialUser =
    intersection(listValidPrivileges, privileges).length >= 3;
  return isSpecialUser;
};

export const dateRangeOverlaps = (a_start, a_end, b_start, b_end) => {
  if (a_start > b_start && a_start < b_end) return true;
  if (a_end > b_start && a_end < b_end) return true;
  if (b_start > a_start && b_start < a_end) return true;
  if (b_end > a_start && b_end < a_end) return true;
  return false;
};

export const canBeStacked = (item, stack) => {
  const dateFormat = "YYYY-MM-DD";
  const a_start = moment(
    item.start_from || item.order_start_date,
    dateFormat
  ).toDate();
  const a_end = moment(item.order_end_date, dateFormat).toDate();

  const hasOverlap = stack.some((stackedItem) => {
    const b_start = moment(
      stackedItem.start_from || stackedItem.order_start_date,
      dateFormat
    ).toDate();
    const b_end = moment(stackedItem.order_end_date, dateFormat).toDate();
    return dateRangeOverlaps(a_start, a_end, b_start, b_end);
  });
  return !hasOverlap;
};

export const getImageHeightAndWidth = async (file) =>
  new Promise((resolve) => {
    const fileAsDataURL = window.URL.createObjectURL(file);
    const img = new Image();
    img.onload = () => {
      resolve({
        height: img.height,
        width: img.width,
      });
    };
    img.onerror = () => {
      reject();
    };
    img.src = fileAsDataURL;
  });

export const filterUsersByStations = (users = [], stations = []) => {
  const stationIds = map(stations, (i) => i.id);
  const newUsers = filter(users, (user) => {
    return find(user.stations, (i) => includes(stationIds, i.user_station_id));
  });
  return newUsers;
};

export const getFileObjectFromURL = (url) => {
  if (url) {
    const path = url.replace(/^https:\/\//i, "http://");
    let mime = mimeTypes.lookup(path);
    let pos = path.lastIndexOf("/");
    const name = path.substring(pos + 1);
    // alac can play. But mimeTypes cant get this mime
    if (path.toLowerCase().endsWith(".alac")) {
      mime = "application/octet-stream";
    }
    return {
      url,
      type: mime,
      name,
      path,
    };
  }
  return null;
};

export const getFilenameFromPath = (path) => {
  let pos = path.lastIndexOf("/");
  return path.substring(pos + 1);
};

export function validateEmail(email) {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  return regex.test(trim(email));
}

export function humanize(num) {
  const ones = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];
  const tens = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];

  const numString = num.toString();

  if (num < 0) throw new Error("Negative numbers are not supported.");

  if (num === 0) return "zero";

  //the case of 1 - 20
  if (num < 20) {
    return ones[num];
  }

  if (numString.length === 2) {
    return tens[numString[0]] + " " + ones[numString[1]];
  }

  //100 and more
  if (numString.length === 3) {
    if (numString[1] === "0" && numString[2] === "0")
      return ones[numString[0]] + " hundred";
    else
      return (
        ones[numString[0]] +
        " hundred and " +
        humanize(+(numString[1] + numString[2]))
      );
  }

  if (numString.length === 4) {
    const end = +(numString[1] + numString[2] + numString[3]);
    if (end === 0) return ones[numString[0]] + " thousand";
    if (end < 100) return ones[numString[0]] + " thousand and " + humanize(end);
    return ones[numString[0]] + " thousand " + humanize(end);
  }
}

export const checkOutOfOfficeOfUserByDates = (
  outOfOfficeDates,
  userTimeZone
) => {
  let timezone;
  if (!outOfOfficeDates || outOfOfficeDates.length === 0) return null;
  // If current user is not set timezone. We will local timezone for all
  if (!userTimeZone || !isValidTimezone(userTimeZone)) {
    try {
      timezone = moment.tz.guess();
      userTimeZone = timezone;
    } catch (e) {
      timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      userTimeZone = timezone;
    }
  }
  const dates = outOfOfficeDates
    .map((item) => {
      // As stated in the Figma, if the Production Team Members Out of Office will begin within 7 days from the current date, then it should display those dates
      // As stated in the Figma, if the Production Team Members Out of Office has already begun
      const diffStartDays = diffDateWithTimezone(item.from_date, userTimeZone);
      const diffEndDays = diffDateWithTimezone(item.to_date, userTimeZone);
      if (diffStartDays <= 7 && diffEndDays > 0) {
        return {
          ...item,
          diffDays: diffStartDays,
        };
      }
      return null;
    })
    .filter((item) => item);
  return dates;
};

export const getNextOutOfOfficeOfUserByDates = (
  outOfOfficeDates,
  userTimeZone
) => {
  let timezone;
  if (!outOfOfficeDates || outOfOfficeDates.length === 0) return null;
  // If current user is not set timezone. We will local timezone for all
  if (!userTimeZone || !isValidTimezone(userTimeZone)) {
    try {
      timezone = moment.tz.guess();
      userTimeZone = timezone;
    } catch (e) {
      timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      userTimeZone = timezone;
    }
  }
  const dates = outOfOfficeDates
    .map((item) => {
      const diffStartDays = diffDateWithTimezone(item.from_date, userTimeZone);
      if (diffStartDays > 0) {
        return {
          ...item,
          diffDays: diffStartDays,
        };
      }
      return null;
    })
    .filter((item) => item);
  return orderBy(dates, ["diffDays"], ["asc"]);
};

export const checkUserIsAEOrAEDigital = (privileges) => {
  const privilegesAE = [
    "add_client",
    "edit_client",
    "delete_client",
    "approve_production_recording",
    "approve_production_script",
    "add_production",
    "delete_production",
    "draft_production",
    "edit_production",
    "view_production",
    "delete_file",
    "upload_file",
    "view_upcoming_tasks",
    "view_users",
  ];
  const privilegesDigitalAE = [
    "add_client",
    "add_digital",
    "approve_design",
    "delete_digital",
    "delete_file",
    "draft_digital",
    "edit_client",
    "edit_digital",
    "upload_file",
    "view_digital",
    "view_upcoming_tasks",
    "view_users",
  ];
  const otherPrivileges = privileges.filter(
    (privilege) =>
      !privilegesAE.includes(privilege) &&
      !privilegesDigitalAE.includes(privilege)
  );

  return otherPrivileges.length > 0;
};
const workflowRoles = [
  "production manager",
  "producer",
  "vo talent",
  "writer",
  "continuity",
  "dubber",
];
export const checkIsShowWorkflow = (roleStrings) => {
  // For users with role Producer, VO, Writer, Continuity, Dubber show the setting in their profile (if they don't have AE role). Then if user has turned on keep me in order
  const isShowWorkflow = roleStrings.find((str) => workflowRoles.includes(str));
  return isShowWorkflow;
};
export const checkUserRedirectToCurrentTasks = (user) => {
  const roleStrings = split(user.roles_string, ",").map((str) =>
    toLower(trim(str))
  );
  const isShowWorkflow = checkIsShowWorkflow(roleStrings);
  // If user has AE role => redirect them to My Tasks list
  if (!isShowWorkflow) return true;
  return user.return_to_current_tasks;
};

// use javascript to find next tab
export const getNextTabToActive = () => {
  const listActiveTab = document.getElementsByClassName(
    "cr-add-update-process__tab_item active"
  );
  let nextElementSibling = getNextActiveElement(listActiveTab[0]);

  if (nextElementSibling) {
    const nextStepIndex = nextElementSibling.getAttribute("data-key");
    return nextStepIndex;
  }
  return;
};

export const getNextActiveElement = (element) => {
  let nextElement = element.nextElementSibling;

  if (nextElement && nextElement.hasAttribute("hidden")) {
    nextElement = getNextActiveElement(nextElement);
  }
  return nextElement;
};

export const getTaggedUserIds = (text = "") => {
  let arr = (text || "").match(/(@\[.*?\]\(.*?\))/g);
  let idArr = [];
  if (arr) {
    for (let index = 0; index < arr.length; index++) {
      const item = arr[index]; // @[Sarju](1)
      const endSquareBracketPos = item.indexOf("]");
      const userId = item
        .substr(endSquareBracketPos + 1)
        .replace("(", "")
        .replace(")", "");
      const userIdInt = Number(userId);
      idArr.push(userIdInt);
    }
  }
  return idArr;
};
// the AE of the order, the Producer assigned, the Continuity user assigned
export const checkAllowSendBack = ({ process, user }) => {
  const templateKey = get(process, "data.fields.template_key.value", "");
  if (templateKey === "production_provided") return false;
  const privileges = get(user, "privileges", []);
  const assignTeamStatus = get(
    process,
    "data.fields.assign_team_status.value",
    ""
  );
  if (assignTeamStatus !== "completed") return false;
  // const team = get(process, "data.fields.team.value", []).map(Number);
  // const aeUser = get(process, "data.fields.role_account_executive.value");
  // const producerUsers = get(
  //   process,
  //   "data.fields.role_producer.value.users",
  //   []
  // );
  // const continuityUsers = get(
  //   process,
  //   "data.fields.role_continuity.value.users",
  //   []
  // );
  // let validUserIds = [];
  // if (aeUser) validUserIds.push(Number(aeUser.id));
  // if (producerUsers) {
  //   validUserIds = [
  //     ...validUserIds,
  //     ...producerUsers.map((item) => Number(item.id)),
  //   ];
  // }
  // if (continuityUsers) {
  //   validUserIds = [
  //     ...validUserIds,
  //     ...continuityUsers.map((item) => Number(item.id)),
  //   ];
  // }
  const isValid = validatePrivileges({
    requires: [PRIVILEGES.SEND_BACK_PRODUCTION_ORDERS],
    privileges,
    user,
  });
  return isValid;
  // return isValid && team.includes(Number(user.id));
};

export const isHtml = (string) => {
  if (!string) return false;
  let regexForHTML = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
  return regexForHTML.test(string);
};

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const titleDisplay = (process) => {
  const template_key = get(process, "_source.template_key", "");
  const isProductionProvided = (template_key.toLowerCase() === "production_provided");
  var title = get(process, "_source.order_title");

  if (!isProductionProvided) {
    return title;
  }
  if (template_key === "digital") {
    return (
        process._source.for_marketing_channel &&
          process._source.for_marketing_channel.name
    );
  }
  if (!get(process, "_source.order_title")) {
    title = ' - ';
  }
  return get(process, "_source.spot_info.length") > 1
    ? title + `(+${get(process, "_source.spot_info.length") - 1} spot)`
    : title;

};
