import React from "react";
import { Row, Col } from "reactstrap";
import Avatar from "components/Avatar";
import classnames from "classnames";
import Player from "components/Player";
import bn from "utils/bemnames";
import { FormattedMessage } from "react-intl";
const bem = bn.create("assign-team-sidebar");

function UserRows({ candidates, isAudition, values, toggleUserRole }) {
  return (
    <div className={bem.e("container-users")}>
      {candidates.map((user, ind) => {
        return (
          <div key={"user_" + ind} className={classnames(bem.e("user-row"))}>
            <Row>
              <Col xs={5} className={bem.e("col-user")}>
                <div className={bem.e("user-info")}>
                  <Avatar
                    src={user.avatar_url}
                    size={40}
                    className={bem.e("user-avatar")}
                    name={user.name}
                  />
                  <div className={bem.e("user-info-right")}>
                    <span className={bem.e("user-name")}>
                      <span>{user.name}</span>
                    </span>
                    <span className={bem.e("user-location")}>
                      <FormattedMessage id="hub manager > location" />
                      {user.location_text ? (
                        <strong>{user.location_text}</strong>
                      ) : null}
                    </span>
                  </div>
                </div>
              </Col>
              <Col xs={7} className={bem.e("col-user")}>
                <div
                  className={classnames(bem.e("user-roles-reel"), {
                    [bem.e("user-roles-reel-column")]:
                      user.candidate_for.length > 1,
                  })}
                >
                  {user.reel && isAudition && <Player url={user.reel} />}

                  <div className={bem.e("user-roles")}>
                    {user.candidate_for.map((role) => {
                      const selected_for_role =
                        role &&
                        values[role.id] &&
                        values[role.id].filter((u) => parseInt(u) === user.id)
                          .length > 0;
                      return (
                        <div
                          className={classnames(bem.e("user-role-button"), {
                            [bem.e("user-role-assigned")]: selected_for_role,
                            [bem.e("user-role-disabled")]: role.is_disabled, //add condition for show disabled style
                          })}
                          key={"user_role_" + user.id + "_" + role.id}
                          onClick={() => {
                            if (role.is_disabled) return;
                            toggleUserRole(user, role);
                          }}
                        >
                          <span className={bem.e("user-role-title")}>
                            {role.name}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        );
      })}
    </div>
  );
}
export default UserRows;
