import React, { useEffect, useMemo, useState } from "react";
import bn from "utils/bemnames";
import PropTypes from "prop-types";
import { get } from "lodash";
import {
  CheckDoneIcon,
  ArrowUpIcon,
  ArrowDownIcon,
} from "components/CustomIcons";
import { Button } from "reactstrap";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";

import ConfirmationModal from "components/ConfirmationModal";
import SelectTeamVo from "./SelectTeamVo";
import stepAssignVo from "utils/DefaultTemplates/BaseProduction/steps/assignVo";
import TextareaAutosize from "react-textarea-autosize";
import Spinner from "components/Spinner";
import FinalScriptContent from "./FinalScriptContent";

const HEADER_HEIGHT = 95;
const FOOTER_HEIGHT = 95;
const bem = bn.create("assign-vo-team-sidebar");

function SectionContent(props) {
  const {
    process,
    item,
    intl,
    info,
    selectedUsers,
    setSelectedUsers,
    members,
    onGetTeamMembers,
    isLoading,
    updateFinalScript,
  } = props;

  if (item.key === "voice_over_instructions") {
    return (
      <div className={bem.e("section-content")}>
        <div className={bem.e("content-details")}>
          <p className={bem.e("content-text")}>
            {item.data ? (
              <TextareaAutosize
                value={item.data}
                className="text-area-view"
                disabled
              />
            ) : (
              <FormattedMessage id="process > none" />
            )}
          </p>
        </div>
      </div>
    );
  }
  if (item.key === "approved_script") {
    return (
      <FinalScriptContent
        process={process}
        item={item}
        bem={bem}
        intl={intl}
        updateFinalScript={updateFinalScript}
      />
    );
  }
  if (item.key === "team_members") {
    return (
      <SelectTeamVo
        process={process}
        members={members}
        step={stepAssignVo}
        intl={intl}
        info={info}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        onGetTeamMembers={onGetTeamMembers}
        isLoading={isLoading}
      />
    );
  }
  return <div></div>;
}
function AssignVoTeam(props) {
  const {
    process,
    intl,
    isShowSuccessModal,
    setIsShowSuccessModal,
    onClose,
    info,
    selectedUsers,
    setSelectedUsers,
    members,
    onGetTeamMembers,
    isLoading,
    updateFinalScript,
  } = props;
  const [contentHeight, setContentHeight] = useState(0);
  const [isOpenDetails, setIsOpenDetails] = useState({
    voice_over_instructions: true,
    approved_script: false,
    team_members: true,
  });
  const items = useMemo(() => {
    if (!process) return [];
    const spotInfo = get(process, "_source.spot_info", []).find(
      (spot) => spot.key === process._id
    );
    const isBookend = get(spotInfo, "spot_type", "Standard") === "Book-ends";
    const isCompletedAssignVo =
      get(process, "_source.assign_vo_status", "") === "completed";
    // const approveScriptStatus =
    //   get(process, "_source.approve_script_status", "") === "approved" &&
    //   get(spotInfo, "script_requires_approval", false)
    //     ? true
    //     : !get(spotInfo, "script_requires_approval", false) &&
    //       get(process, "_source.write_script_status", "") === "completed"
    //     ? true
    //     : false;

    return [
      {
        key: "voice_over_instructions",
        title: intl.formatMessage({
          id: "process > field voice over instructions",
        }),
        data: get(spotInfo, "voice_over_instructions", ""),
        isCompleted: !!get(spotInfo, "voice_over_instructions", ""),
      },
      {
        key: "approved_script",
        title: intl.formatMessage({ id: "process > final script" }),
        data: {
          script: get(spotInfo, "script", ""),
          script_part_b: get(spotInfo, "script_part_b", ""),
        },
        isCompleted: !!get(spotInfo, "script", ""),
        isBookend,
      },
      {
        key: "team_members",
        title: intl.formatMessage({ id: "process > team members" }),
        isCompleted: !!isCompletedAssignVo,
      },
    ];
  }, [process]);

  useEffect(() => {
    const sidebar = document.getElementById("right-sidebar");
    if (sidebar) {
      setContentHeight(sidebar.offsetHeight - HEADER_HEIGHT - FOOTER_HEIGHT);
    }
  }, []);

  return (
    <div className={bem.b()}>
      <div
        className={classNames(bem.e("scroll-content"), "scroll-bar-style")}
        style={{
          height: contentHeight,
        }}
      >
        {items.map((item) => (
          <div key={item.key} className={bem.e("instruction-item")}>
            <div
              className={bem.e("instruction-header")}
              onClick={() => {
                if (isOpenDetails[item.key]) {
                  setIsOpenDetails({
                    ...isOpenDetails,
                    [item.key]: false,
                  });
                } else {
                  setIsOpenDetails({
                    ...isOpenDetails,
                    [item.key]: true,
                  });
                }
              }}
            >
              <span className={bem.e("section-title")}>
                {item.isCompleted ? (
                  <CheckDoneIcon color="#73A703" />
                ) : (
                  <span className={bem.e("fake-icon")} />
                )}
                {item.title}
              </span>
              <div className={bem.e("section-action")}>
                {isOpenDetails[item.key] ? <ArrowDownIcon /> : <ArrowUpIcon />}
              </div>
            </div>
            {isOpenDetails[item.key] && (
              <SectionContent
                info={info}
                item={item}
                process={process}
                intl={intl}
                members={members}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
                onGetTeamMembers={onGetTeamMembers}
                isLoading={isLoading}
                updateFinalScript={updateFinalScript}
              />
            )}
          </div>
        ))}
      </div>

      <div className={bem.e("assigned-footer")}>
        <Button
          color="blue"
          onClick={props.onSubmit}
          disabled={selectedUsers.length === 0}
          className={"btn btn-radius"}
        >
          {props.buttonSubmitText}
        </Button>
        <Button
          color="blue"
          outline
          className={"btn btn-radius"}
          onClick={onClose}
        >
          <FormattedMessage id={`process > button cancel`} />
        </Button>
      </div>
      <ConfirmationModal
        isOpen={isShowSuccessModal}
        state="success"
        title={<FormattedMessage id="process > title great looking team" />}
        description={
          <FormattedMessage id="process > the members have been notified about their new assignment" />
        }
        className="assigned-great-modal"
        onToggle={() => setIsShowSuccessModal(!isShowSuccessModal)}
        onCancel={() => setIsShowSuccessModal(!isShowSuccessModal)}
      />
      <Spinner isLoading={isLoading} />
    </div>
  );
}
AssignVoTeam.defaultProps = {
  process: {},
  buttonSubmitText: <FormattedMessage id={`process > button submit`} />,
};
AssignVoTeam.propTypes = {
  process: PropTypes.object,
  buttonSubmitText: PropTypes.any,
};
export default AssignVoTeam;
