import React from "react";
import bn from "utils/bemnames";
import { Row, Col } from "reactstrap";
import { map, find, filter, get } from "lodash";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import Dropdown from "components/Dropdown";
import { STATION } from "utils/constants";
import { PRIVILEGES } from "utils/constants";
import { validatePrivileges } from "utils/helpers";
const bem = bn.create("user-assignments");

const parseOptionsValue = (options) =>
  options.map((option) => ({ ...option, value: parseInt(option.value) }));

function EntityData(props) {
  const { formProps, stationsWithShows, stations, setShouldBlockNavigation } = props;
  return (
    <div className={bem.e("entity-data")}>
      <div className={bem.e("show-stations")}>
        {map(stations, (item, index) => {
          const foundStation = find(stationsWithShows, { key: item.key }) || {};
          const stationShows = foundStation.shows || [];
          const showsOptions = stationShows.map((show) => ({
            value: show.id,
            label: show.name,
          }));
          return (
            <div key={index} className={bem.e("show-station-selected")}>
              <span className={classnames(bem.e("show-station-name"))}>
                {item.name}
              </span>
              <div>
                <Dropdown
                  placeholder={props.intl.formatMessage({
                    id: "user > select shows",
                  })}
                  name={`shows_${index}`}
                  isMulti
                  allowSelectAll
                  hideSelectedOptions
                  onChange={(selectedOption, meta) => {
                    let stationsData = get(formProps, "values.stations", []);
                    let stationData = stationsData[index];
                    // assign shows to station
                    stationData = {
                      ...stationData,
                      shows: selectedOption,
                    };
                    stationsData.splice(index, 1, stationData);
                    formProps.setFieldValue("stations", stationsData);
                    setShouldBlockNavigation(true);
                  }}
                  value={parseOptionsValue(get(item, "shows", []))}
                  options={parseOptionsValue(showsOptions)}
                  closeMenuOnSelect={false}
                  error={
                    get(formProps, `touched.stations.${index}.shows`) &&
                    get(formProps, `errors.stations.${index}.shows`)
                  }
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default function ShowAssignments(props) {
  const { isView, user, allStationsAndTeams } = props;
  const stationsWithShows = filter(
    allStationsAndTeams,
    (station) => station.type === STATION
  );

  const getShowNames = (shows) => {
    let stationShows = shows;
    if (!Array.isArray(shows)) {
      shows = Object.keys(shows).map((id) => ({ id, name: stationShows[id] }));
    }
    if (!shows.length) return "-";
    return shows.map((show) => show.name).join(", ");
  };
  let stations = [];
  if (isView) {
    stations = get(user, "stations", []).filter((station) => {
      return validatePrivileges({
        requires: { or: [PRIVILEGES.VIEW_TASKS, PRIVILEGES.PERFORM_TASKS] },
        privileges: Object.values(station.user_privileges),
      });
    });
  } else {
    stations = get(props, "formProps.values.stations", []).filter((station) => {
      return validatePrivileges({
        requires: { or: [PRIVILEGES.VIEW_TASKS, PRIVILEGES.PERFORM_TASKS] },
        privileges: map(station.privileges, (item) => item.label),
      });
    });
  }

  const renderView = () => {
    const stations = get(user, "stations", []);
    const isEmptyEntities = stations.length === 0;
    return (
      <Row>
        <Col xs={12} className={bem.e("col-reset")}>
          <h2 className={bem.e("assignment-title")}>
            <FormattedMessage id="user > show assignments" />
          </h2>
          <div className={bem.e("list")}>
            {isEmptyEntities && (
              <p className={bem.e("none-text")}>
                <FormattedMessage id="user > none" />
              </p>
            )}
            {!isEmptyEntities && (
              <Row>
                <Col xs={10}>
                  {map(stations, (item, index) => (
                    <Row key={index}>
                      <Col xs={3} className={bem.e("no-margin")}>
                        <div className={bem.e("entity-label-view")}>
                          {item.call_letters}
                        </div>
                      </Col>
                      <Col xs={3} className={bem.e("no-margin")}>
                        <div className={bem.e("entity-label-view")}>
                          {item.name}
                        </div>
                      </Col>
                      <Col xs={4} className={bem.e("no-margin")}>
                        <div className={bem.e("entity-label-view")}>
                          {getShowNames(item.shows)}
                        </div>
                      </Col>
                    </Row>
                  ))}
                </Col>
              </Row>
            )}
          </div>
        </Col>
      </Row>
    );
  };

  const renderForm = () => {
    const isEmptyEntities = stations.length === 0;
    return (
      <div className={bem.e("team-form")}>
        <div className={bem.e("head-form")}>
          <h2
            className={classnames(
              bem.e("assignment-title"),
              bem.e("individual-title")
            )}
          >
            <FormattedMessage id="user > show assignments" />
          </h2>
        </div>
        <Row>
          <Col xs={12} className={bem.e("col-reset")}>
            <div className={bem.e("list")}>
              {isEmptyEntities && (
                <div>
                  <h2 className={bem.e("small-title")}>
                    <FormattedMessage id="user > entities" />
                  </h2>
                  <p className={bem.e("none-large-text")}>
                    <FormattedMessage id="user > no entities to show" />
                  </p>
                </div>
              )}
              {!isEmptyEntities && (
                <EntityData
                  formProps={props.formProps}
                  stations={stations}
                  intl={props.intl}
                  stationsWithShows={stationsWithShows}
                  setCurrentEntity={props.setCurrentEntity}
                  setIsOpenManagePrivilegesModal={
                    props.setIsOpenManagePrivilegesModal
                  }
                  setIsOpenConfirmationModal={props.setIsOpenConfirmationModal}
                  setIsOpenConfirmationApplyToAllModal={
                    props.setIsOpenConfirmationApplyToAllModal
                  }
                  auth={props.auth}
                />
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  };
  if (stations.length === 0) return null;
  return (
    <React.Fragment>
      <div className={bem.e("line")} />
      <div className={bem.e("shows-assignments")}>
        {isView ? renderView() : renderForm()}
      </div>
    </React.Fragment>
  );
}
ShowAssignments.defaultProps = {
  isView: true,
};
