import * as Const from "utils/constants";
import Api from "utils/api";
import { isFunction } from "lodash";
import * as Types from "store/types/systemTemplates";
import { get, omit } from "lodash";
import queryString from "query-string";
export const getSystemTemplates = (payload, cb) => async (
  dispatch,
  getState
) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.FETCH_SYSTEM_TEMPLATES_REQUEST, payload });
    const paramString = new URLSearchParams();
    if (payload.type) {
      paramString.set("type", payload.type);
    }

    if (payload.page) {
      paramString.set("page", payload.page);
    }

    if (payload.station_id) {
      paramString.set("station_id", payload.station_id);
    }

    const url = Const.GET_SYSTEM_TEMPLATES() + `?${paramString.toString()}`;
    let response = await Api.doCall(url, "GET");
    if (response.status === 200) {
      if (!response.data.error) {
        const page = get(payload, "page", 1);

        if (page === 1) {
          dispatch({
            type: Types.FETCH_SYSTEM_TEMPLATES_SUCCESS,
            payload: {
              ...omit(payload, ["page", "station_id"]),
              page: get(response, "data.current_page", 1),
              pages: get(response, "data.last_page", 1),
              data: get(response, "data.data", []),
            },
          });
          if (isFunction(cb)) cb(get(response, "data.data", []));
        } else {
          const systemTemplates = getState().systemTemplates;
          let data =
            get(systemTemplates, "systemTemplates.data.data", []) || [];

          data = [...data, ...(get(response, "data.data", []) || [])];
          dispatch({
            type: Types.FETCH_SYSTEM_TEMPLATES_SUCCESS,
            payload: {
              ...omit(payload, ["page", "station_id"]),
              page: get(response, "data.current_page", 1),
              pages: get(response, "data.last_page", 1),
              data: data,
            },
          });
          if (isFunction(cb)) cb(data);
        }

        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.FETCH_SYSTEM_TEMPLATES_ERROR,
    payload: errorStr,
  });
};

export const createSystemTemplate = (payload = {}, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.UPDATE_SYSTEM_TEMPLATES_REQUEST });
    //api call for store data
    let response = await Api.doCall(
      Const.GET_SYSTEM_TEMPLATES(),
      "POST",
      payload
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        //response data
        // let responseData = JSON.parse(JSON.stringify(response.data));
        dispatch({
          type: Types.UPDATE_SYSTEM_TEMPLATES_SUCCESS,
          payload: response.data,
        });
        if (isFunction(cb)) cb(response.data);
        return;
      }
      //error data
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({
    type: Types.UPDATE_SYSTEM_TEMPLATES_ERROR,
    payload: {
      error: errorStr,
    },
  });
};

export const updateSystemTemplate = (id, payload = {}, cb) => async (
  dispatch
) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.UPDATE_SYSTEM_TEMPLATES_REQUEST });
    //api call for store data
    let response = await Api.doCall(
      Const.GET_SYSTEM_TEMPLATE(id),
      "PUT",
      payload
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        //response data
        // let responseData = JSON.parse(JSON.stringify(response.data));
        dispatch({
          type: Types.UPDATE_SYSTEM_TEMPLATES_SUCCESS,
          payload: response.data,
        });
        if (isFunction(cb)) cb(response.data);
        return;
      }
      //error data
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({
    type: Types.UPDATE_SYSTEM_TEMPLATES_ERROR,
    payload: {
      error: errorStr,
    },
  });
};

export const setPrimarySystemTemplate = (id, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.UPDATE_SYSTEM_TEMPLATES_REQUEST });
    //api call for store data
    let response = await Api.doCall(
      Const.SET_SYSTEM_TEMPLATE_PRIMARY(id),
      "PUT",
      {}
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        //response data
        // let responseData = JSON.parse(JSON.stringify(response.data));
        dispatch({
          type: Types.UPDATE_SYSTEM_TEMPLATES_SUCCESS,
          payload: response.data,
        });
        if (isFunction(cb)) cb(response.data);
        return;
      }
      //error data
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({
    type: Types.UPDATE_SYSTEM_TEMPLATES_ERROR,
    payload: {
      error: errorStr,
    },
  });
};

export const duplicateSystemTemplate = (id, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.UPDATE_SYSTEM_TEMPLATES_REQUEST });
    //api call for store data
    let response = await Api.doCall(
      Const.DUPPLICATE_SYSTEM_TEMPLATE(id),
      "PUT"
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.UPDATE_SYSTEM_TEMPLATES_SUCCESS,
          payload: response.data,
        });
        if (isFunction(cb)) cb(response.data);
        return;
      }
      //error data
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({
    type: Types.UPDATE_SYSTEM_TEMPLATES_ERROR,
    payload: {
      error: errorStr,
    },
  });
};

export const deleteSystemTemplate = (id, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.UPDATE_SYSTEM_TEMPLATES_REQUEST });
    //api call for store data
    let response = await Api.doCall(Const.GET_SYSTEM_TEMPLATE(id), "DELETE");
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        //response data
        // let responseData = JSON.parse(JSON.stringify(response.data));
        dispatch({
          type: Types.UPDATE_SYSTEM_TEMPLATES_SUCCESS,
          payload: response.data,
        });
        if (isFunction(cb)) cb(response.data);
        return;
      }
      //error data
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({
    type: Types.UPDATE_SYSTEM_TEMPLATES_ERROR,
    payload: {
      error: errorStr,
    },
  });
};

export const fetchWinnerAnnouncement = (params = {}) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({
      type: Types.FETCH_WINNER_ANNOUNCEMENT_REQUEST,
    });
    const query = queryString.stringify({
      type: "winner_notification",
      category: "winner_announcement",
      published: 1,
      rpp: 999,
      ...params,
    });
    let response = await Api.doCall(
      `${Const.GET_SYSTEM_TEMPLATES()}?${query}`,
      "GET"
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.FETCH_WINNER_ANNOUNCEMENT_SUCCESS,
          payload: response.data,
        });
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.FETCH_WINNER_ANNOUNCEMENT_ERROR,
    payload: { error: errorStr },
  });
};

export const fetchFormConfirmation = (params, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({
      type: Types.FETCH_FORM_CONFIRMATION_REQUEST,
    });
    const query = queryString.stringify({
      type: "winner_notification",
      category: "received_form_confirmation",
      published: 1,
      rpp: 999,
      ...params,
    });
    let response = await Api.doCall(
      `${Const.GET_SYSTEM_TEMPLATES()}?${query}`,
      "GET"
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.FETCH_FORM_CONFIRMATION_SUCCESS,
          payload: response.data,
        });
        if (isFunction(cb)) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.FETCH_FORM_CONFIRMATION_ERROR,
    payload: { error: errorStr },
  });
};

export const fetchPrizeReleaseTemplate = (params, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({
      type: Types.FETCH_PRIZE_RELEASE_TEMPLATE_REQUEST,
    });
    const query = queryString.stringify({
      type: "release_form",
      published: 1,
      rpp: 999,
      ...params,
    });
    let response = await Api.doCall(
      `${Const.GET_SYSTEM_TEMPLATES()}?${query}`,
      "GET"
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.FETCH_PRIZE_RELEASE_TEMPLATE_SUCCESS,
          payload: response.data,
        });
        if (isFunction(cb)) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.FETCH_PRIZE_RELEASE_TEMPLATE_ERROR,
    payload: { error: errorStr },
  });
};

export const fetchPrizeFulfillmentTemplate = (params, cb) => async (
  dispatch
) => {
  let errorStr = "";
  try {
    dispatch({
      type: Types.FETCH_PRIZE_FULFILLMENT_TEMPLATE_REQUEST,
    });
    const query = queryString.stringify({
      type: "winner_notification",
      category: "prize_fulfillment",
      published: 1,
      rpp: 999,
      ...params,
    });
    let response = await Api.doCall(
      `${Const.GET_SYSTEM_TEMPLATES()}?${query}`,
      "GET"
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.FETCH_PRIZE_FULFILLMENT_TEMPLATE_SUCCESS,
          payload: response.data,
        });
        if (isFunction(cb)) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.FETCH_PRIZE_FULFILLMENT_TEMPLATE_ERROR,
    payload: { error: errorStr },
  });
};

export const fetchEmailTemplates = (params, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({
      type: Types.FETCH_EMAIL_TEMPLATES_REQUEST,
    });
    const query = queryString.stringify({
      type: "winner_notification",
      rpp: 999,
      ...params,
    });
    let response = await Api.doCall(
      `${Const.GET_SYSTEM_TEMPLATES()}?${query}`,
      "GET"
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.FETCH_EMAIL_TEMPLATES_SUCCESS,
          payload: response.data,
        });
        if (isFunction(cb)) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.FETCH_EMAIL_TEMPLATES_ERROR,
    payload: { error: errorStr },
  });
};

export const checkDuplicateTemplate = (payload = {}, cb) => async (
  dispatch
) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.CHECK_DUPLICATE_TEMPLATE_REQUEST });
    //api call for store data
    let response = await Api.doCall(
      `${Const.GET_SYSTEM_TEMPLATES()}/check_duplicate`,
      "POST",
      payload
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        //response data
        dispatch({
          type: Types.CHECK_DUPLICATE_TEMPLATE_SUCCESS,
          payload: response.data,
        });
        if (isFunction(cb)) cb(response.data);
        return;
      }
      //error data
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({
    type: Types.CHECK_DUPLICATE_TEMPLATE_ERROR,
    payload: {
      error: errorStr,
    },
  });
};
