import React from "react";
import bn from "utils/bemnames";
import Spinner from "components/Spinner";
import LeftSidebar from "./LeftSidebar";
import RightSidebar from "./RightSidebar";
import Content from "./Content";
import ConfirmationModal from "components/ConfirmationModal";
import DisplaySettingsModal from "./DisplaySettingsModal";
import { find, get } from "lodash";
const bem = bn.create("jock-console");

export default function JockConsole(props) {
  const { stations, displaySettings, onSelectSchedule } = props;
  const station = find(
    stations,
    (item) => item.key === get(props.selectedSchedule, "schedule.station_id")
  );
  const schedule = get(props, "selectedSchedule.schedule");
  let isShowDoneButton = false;
  let isDoneButtonDisabled = false;
  let isShowPickPrizeButton = false;
  if (schedule) {
    const scheduleTheme = get(schedule, "theme", "");
    const isRollOverTheme = scheduleTheme === "rollover";
    const isContest = schedule.contests_schedule;
    const winnerId = get(schedule, "winner_id");
    const isAddedWinner = !!winnerId;
    const winner = get(schedule, "winner", {});
    const selectedPrizeId = get(winner, "prizes.0.id");
    const isPrizeSelected = !!selectedPrizeId;
    const isPickYourPrizeTheme = scheduleTheme === "pick_your_prize";
    isShowDoneButton = isContest
      ? isAddedWinner &&
        !schedule.completed &&
        (isPickYourPrizeTheme ? isPrizeSelected : true)
      : !schedule.completed;
    isDoneButtonDisabled = isRollOverTheme && !winner.contest_result;

    isShowPickPrizeButton =
      isPickYourPrizeTheme && winnerId && !isPrizeSelected;
  }

  return (
    <div className={bem.b()}>
      <LeftSidebar
        selectedType={props.selectedType}
        setSelectedType={props.setSelectedType}
        selectedDate={props.selectedDate}
        setSelectedDate={props.setSelectedDate}
        selectedSchedule={props.selectedSchedule}
        currentAction={props.currentAction}
        setCurrentAction={props.setCurrentAction}
        setSelectedSchedule={(item) => {
          const isWarning =
            !get(schedule, "completed", false) && get(schedule, "winner_id");
          if (isWarning) {
            props.setSelectedDoneSchedule(item);
            props.setIsOpenConfirmationModal(true);
          } else {
            onSelectSchedule(item);
          }
        }}
      />
      <div className={bem.e("content-wrapper")}>
        <Content
          {...props}
          setSelectedSchedule={onSelectSchedule}
          onCreateFloatingPlay={props.onCreateFloatingPlay}
          isShowDoneButton={isShowDoneButton}
          isDoneButtonDisabled={isDoneButtonDisabled}
          isShowPickPrizeButton={isShowPickPrizeButton}
        />
      </div>
      <RightSidebar
        currentWidget={props.currentWidget}
        setCurrentWidget={props.setCurrentWidget}
        isOpenMultiViewModal={props.isOpenMultiViewModal}
        setIsOpenMultiViewModal={props.setIsOpenMultiViewModal}
        selectedSchedule={props.selectedSchedule}
        station={station}
        displaySettings={displaySettings}
        openRightPanel={props.openRightPanel}
        toggleRightPanel={props.toggleRightPanel}
      />
      <DisplaySettingsModal
        isOpen={props.isDisplaySettingsModalOpen}
        onToggle={props.toggleDisplaySettingsModal}
        station={station}
      />
      <ConfirmationModal
        isOpen={props.isOpenConfirmationModal}
        title={props.intl.formatMessage({
          id: "jock console > are you done this task",
        })}
        isCloseOutside={false}
        className={bem.e("confirmation-modal")}
        confirmTitle={props.intl.formatMessage({
          id: "jock console > button done",
        })}
        cancelTitle={props.intl.formatMessage({
          id: "jock console > button cancel",
        })}
        onConfirm={() => {
          const buttonSubmitTask = document.getElementById(
            "button-submit-task"
          );
          const buttonSubmitTaskPickPrize = document.getElementById(
            "button-submit-task-pick-prize"
          );
          if (buttonSubmitTaskPickPrize) {
            buttonSubmitTaskPickPrize.click();
          }
          if (buttonSubmitTask) {
            buttonSubmitTask.click();
          }
          if (!buttonSubmitTask && !buttonSubmitTaskPickPrize) {
            props.setSelectedDoneSchedule(null);
          }
          props.setIsOpenConfirmationModal(false);
        }}
        onToggle={() => {
          props.setIsOpenConfirmationModal(false);
        }}
        onCancel={() => {
          props.setSelectedDoneSchedule(null);
          props.setIsOpenConfirmationModal(false);
        }}
      />
      <Spinner isLoading={props.loading} />
    </div>
  );
}
