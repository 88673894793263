import React from "react";
import {
  DefaultChannelIcon,
  RadioIcon,
  BroadcastIcon,
  EmailIcon,
  YoutubeIcon,
  AdsIcon,
  FacebookIcon,
  SnapchatIcon,
  TiktokIcon,
  LinkedinIcon,
  TwitterIcon,
} from "components/CustomIcons";
import { map } from "lodash";
import classnames from "classnames";
import bn from "utils/bemnames";
const bem = bn.create("icon-selector");
export const IconsConfig = {
  youtube: YoutubeIcon,
  facebook_ads: FacebookIcon,
  google_ads: AdsIcon,
  website_banner_ads: RadioIcon,
  email_campaign: EmailIcon,
  default: DefaultChannelIcon,
  broadcast: BroadcastIcon,
  snapchat: SnapchatIcon,
  tiktok: TiktokIcon,
  linkedin: LinkedinIcon,
  twitter: TwitterIcon,
};
function IconSelector(props) {
  const { selected, onSelect } = props;
  return (
    <div className={classnames(bem.b(), "disable-unsaved")}>
      <span className={bem.e("arrow-icon")} />
      {map(IconsConfig, (Icon, index) => {
        return (
          <div
            className={classnames(bem.e("icon"), "disable-unsaved", {
              [bem.e("active")]: selected === index,
            })}
            onClick={() => onSelect(index)}
            key={index}
          >
            <Icon />
          </div>
        );
      })}
    </div>
  );
}
export default IconSelector;
