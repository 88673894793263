import React, { useRef } from "react";
import bn from "utils/bemnames";
import PropTypes from "prop-types";
import classnames from "classnames";
import useOutsideClick from "utils/useOutsideClick";
import { map, get, find, filter } from "lodash";
import Checkbox from "components/Checkbox";
import { FaPlus } from "react-icons/lib/fa";

const bem = bn.create("selection-dropdown");

const SelectionDropdown = (props) => {
  const {
    placeholder,
    isAllowAdd,
    options,
    intl,
    isCheckbox,
    value,
    isAllowSelectAll,
    onChange,
    onClickNew,
    error,
  } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const ref = useRef(null);
  useOutsideClick(ref, () => {
    setIsOpen(false);
  });
  const hasValue = Array.isArray(value) ? value.length > 0 : !!value;
  return (
    <div className={bem.b()} ref={ref}>
      <div
        className={classnames(bem.e("placeholder"), bem.e("select-box"), {
          [bem.e("select-error")]: !!error,
          [bem.e("select-has-value")]: hasValue,
          [bem.e("text-cap")]: hasValue && Array.isArray(value),
        })}
        onClick={() => setIsOpen(!isOpen)}
      >
        {!hasValue ? placeholder : null}
        {hasValue && Array.isArray(value)
          ? `${intl.formatMessage({ id: "selection dropdown > multiple" })} ${
              props.type
            }s`
          : hasValue
          ? value.label
          : ""}
      </div>
      {isOpen && (
        <div className={bem.e("menu")}>
          <div className={classnames(bem.e("items"), "scroll-bar-style")}>
            {isCheckbox && isAllowSelectAll && options.length > 0 && (
              <div className={bem.e("menu-item-checkbox")}>
                <Checkbox
                  checked={
                    get(value, "length", 0) > 0 &&
                    get(value, "length", 0) === get(options, "length", 0)
                  }
                  onChange={(checked) => {
                    onChange(checked ? options : []);
                  }}
                  text={intl.formatMessage({
                    id: "selection dropdown > select all",
                  })}
                />
              </div>
            )}
            {map(options, (option, index) =>
              isCheckbox ? (
                <div className={bem.e("menu-item-checkbox")} key={index}>
                  <Checkbox
                    checked={!!find(value, (o) => o.value === option.value)}
                    onChange={(checked) => {
                      let newValue = [];
                      if (checked) {
                        newValue = [...value, option];
                      } else {
                        newValue = filter(
                          value,
                          (o) => o.value !== option.value
                        );
                      }
                      onChange(newValue);
                    }}
                    text={option.label}
                  />
                </div>
              ) : (
                <div
                  className={bem.e("menu-item")}
                  key={index}
                  onClick={() => {
                    onChange(option);
                    setIsOpen(false);
                  }}
                >
                  {option.label}
                </div>
              )
            )}
          </div>
          {isAllowAdd && (
            <div className={bem.e("btn-new")} onClick={onClickNew}>
              <FaPlus color="#795AFA" size={15} />
              {intl.formatMessage(
                {
                  id: "selection dropdown > add new {item}",
                },
                {
                  item: props.type,
                }
              )}
            </div>
          )}
        </div>
      )}
      {/* {error && <div className="alert-danger">{error}</div>} */}
    </div>
  );
};
PropTypes.propTypes = {
  placeholder: PropTypes.string,
  isAllowAdd: PropTypes.bool,
  isCheckbox: PropTypes.bool,
  value: PropTypes.any,
  options: PropTypes.array.isRequired,
  type: PropTypes.string,
  isAllowSelectAll: PropTypes.bool,
  onChange: PropTypes.func,
};
PropTypes.defaultProps = {
  placeholder: "",
  options: [],
  isAllowAdd: false,
  isCheckbox: false,
  type: "",
  isAllowSelectAll: false,
  onChange: () => {},
  onClickNew: () => {},
};
export default SelectionDropdown;
