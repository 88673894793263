import { FormattedMessage } from "react-intl";
import React from "react";
import {
  get,
  filter,
  includes,
  isEmpty,
  forEach,
  delay,
  find,
  uniq,
  map,
} from "lodash";
import moment from "moment";
import history from "components/History";
import { getLocalStorageDateFormat } from "utils/dates";
import { checkUserRedirectToCurrentTasks } from "utils/helpers";

const recordScript = {
  process_step_index: 5,
  key: "record_script",
  title: <FormattedMessage id="process > title record" />,
  caption_color: "#CC1EE6",
  description: <FormattedMessage id="process > creative details" />,
  isCollapsible: true,
  step_roles: [5],
  step_viewers_roles: [6, 5, 4, 7, 2],
  step_editors_roles: [5, 4, 7],
  step_viewers_privileges: {
    or: ["produce_production", "edit_all_production_orders"],
  },
  step_editors_privileges: {
    or: ["produce_production", "edit_all_production_orders"],
  },
  is_hidden: function(user, process, step) {
    return (
      process.data.fields.contract_status &&
      process.data.fields.contract_status.value &&
      process.data.fields.contract_status.value !== "Production Needed"
    );
  },
  isOpen: function({ step, process, template, user }) {
    const voiceTracks = get(
      process,
      "data.fields.voice_tracks.value",
      []
    ).filter((item) => item.path);

    if (process.data.fields.contract_status.value === "Production Needed") {
      const spot = find(
        get(process, "data.fields.spot_info.value", []),
        (spot) => spot.key === process.key
      );
      const spotAssets = get(spot, `files`, []);

      return voiceTracks.length > 0 || spotAssets.length > 0;
    }
    return true;
  },
  status: "initial",
  workflow: {
    title: "record script",
    icon: "RecordIcon",
  },
  className: "recording",
  is_approval_step: true,
  fields: [
    {
      container: "top_container",
      component: "order_details_view",
      title: <FormattedMessage id="process > title order details" />,
    },
    {
      component: "order_approvals",
    },
    {
      field: "spot_info",
      props: {
        isViewAssets: false,
      },
    },
    {
      component: "label",
      value: <FormattedMessage id="process > field VO tracks" />,
      props: { className: "form-segment-label" },
    },
    {
      field: "voice_tracks",
      component: "voice_tracks",
      mandatory: false,
      props: {
        attachText: <FormattedMessage id="process > field upload audio" />,
        actions: [],
        isCheckMimesByExt: true,
        extensions: ["mp3", "wav", "aiff", "flac", "aac", "alac", "m4a", "mp4"],
        isShowAudit: true,
        isCanFeedback: true,
        isShowFeedbackOtherVo: true,
        isBubble: true,
      },
    },
    {
      field: "voice_feedback",
      container: "segment",
      component: "button",
      isCollapsible: false,
      text: <FormattedMessage id="process > button request change" />,
      privileges: {
        or: ["submit_production_recording", "edit_all_production_orders"],
      },
      props: {
        classNameSegment: "segment-request-change",
        isOwnSegment: true,
        is_disabled: function(user, process, step) {
          const voiceFeedbackStillNotSubmitMessage = get(
            process,
            "data.fields.voice_feedback.value",
            []
          ).filter((item) => item.feedback);
          return voiceFeedbackStillNotSubmitMessage.length > 0;
        },
      },
      is_hidden: function(user, process, step) {
        const voiceFeedback = get(
          process,
          "data.fields.voice_feedback.value",
          []
        );
        const isOpen = find(voiceFeedback, (i) => i.is_feedback);
        return !isOpen;
      },
      fields: [
        {
          key: "voice_over_status",
          value: "rejected",
        },
        {
          key: "record_script_status",
          value: "rejected",
        },
        {
          key: "voice_feedback",
          value: function(user, template, process, step, item) {
            const messages = get(process, "data.fields.messages.value", []);
            const voiceFeedback = get(
              process,
              "data.fields.voice_feedback.value",
              []
            ).map((i) => {
              // get comments of voice
              const comments = filter(messages, (m) => {
                const mData = get(m, "_source", {});
                return (
                  mData.m_type === "voice_feedback" &&
                  mData.process_key === get(process, "data.fields.key.value") &&
                  mData.recording_path === i.path
                );
              });
              return {
                ...i,
                status: comments.length > 0 ? "rejected" : "completed",
                is_feedback: false, // close all current review
              };
            });
            return voiceFeedback;
          },
        },
        {
          key: "voice_tracks",
          value: function(user, template, process, step, item) {
            const voiceTracks = get(
              process,
              "data.fields.voice_tracks.value",
              []
            );
            const messages = get(process, "data.fields.messages.value", []);
            const voiceTracksWithComments = filter(voiceTracks, (item) => {
              // get comments of voice
              const comments = filter(messages, (m) => {
                const mData = get(m, "_source", {});
                return (
                  mData.m_type === "voice_feedback" &&
                  mData.process_key === get(process, "data.fields.key.value") &&
                  mData.recording_path === item.path
                );
              });
              return comments.length === 0;
            });

            return voiceTracksWithComments;
          },
        },
        {
          key: "spot_info",
          value: function(user, template, process, step, item) {
            // marked voice_over_status to spot linked
            let spotInfo = map(
              get(process, "data.fields.spot_info.value", []),
              (spot) => ({
                ...spot,
                voice_over_status:
                  spot.key === process.key
                    ? "rejected"
                    : spot.voice_over_status,
              })
            );
            return spotInfo;
          },
        },
        {
          key: "process_step",
          value: "voice_over",
        },
        {
          key: "next_users",
          value: (user, template, process, step, item, next_users) => {
            const continuityStatus = get(
              process,
              "data.fields.continuity_status.value",
              ""
            );

            const roleContinuity = get(
              process,
              "data.fields.role_continuity.value.users",
              []
            ).map((continuity) => parseInt(continuity.id));

            const voiceTracks = get(
              process,
              "data.fields.voice_feedback.value",
              []
            );
            const messages = get(process, "data.fields.messages.value", []);
            const rejectedVoiceUserIds = filter(voiceTracks, (item) => {
              // get comments of voice
              const comments = filter(messages, (m) => {
                const mData = get(m, "_source", {});
                return (
                  mData.m_type === "voice_feedback" &&
                  mData.process_key === get(process, "data.fields.key.value") &&
                  mData.recording_path === item.path
                );
              });
              return comments.length > 0;
            }).map((item) => item.user_id);
            let nextUsers = rejectedVoiceUserIds;
            if (continuityStatus !== "completed") {
              nextUsers = [...nextUsers, ...roleContinuity];
            }
            return uniq(nextUsers);
          },
        },
      ],
      on_click: {
        validate: false,
        save: true,
        includeChatMessage: true,
        go_to: function(_this, step, item, process, template, user) {
          if (!user.return_to_current_tasks) return;
          // set delay when after success message
          delay(() => {
            if (_this.props.setSidebarActive) {
              _this.props.setSidebarActive(`/processes/my_action_items`);
            }
            history.replace(`/processes/my_action_items`);
          }, 4000);
        },
      },
      warning_dialog_props: {
        show: function(process) {
          const voiceTracks = get(
            process,
            "data.fields.voice_tracks.value",
            []
          );
          const messages = get(process, "data.fields.messages.value", []);
          const voiceTracksWithComments = filter(voiceTracks, (item) => {
            // get comments of voice
            const comments = filter(messages, (m) => {
              const mData = get(m, "_source", {});
              return (
                mData.m_type === "voice_feedback" &&
                mData.process_key === get(process, "data.fields.key.value") &&
                mData.recording_path === item.path
              );
            });
            return comments.length > 0;
          });
          /**
           * NOTE: If a user removes all their comments, the system will not submit any change request. No comments = no change request
           */
          return voiceTracksWithComments.length === 0;
        },
        titleStyle: { width: "400px" },
        title: (
          <FormattedMessage id="process > leave feedback to request changes" />
        ),
        confirmTitle: <FormattedMessage id="process > button ok" />,
        cancelTitle: "",
      },
      confirmation_dialog_props: {
        titleStyle: { width: "400px" },
        title: (
          <FormattedMessage id="process > description request changes to the voice" />
        ),
        confirmTitle: <FormattedMessage id="process > button yes" />,
        cancelTitle: <FormattedMessage id="process > button no" />,
        className: "modal-request-changes",
      },
      success_dialog_props: {
        title: <FormattedMessage id="process > submitted" />,
        state: "success",
      },
    },
    {
      field: "recording_feedback",
      container: "segment",
      component: "order_recording_review",
      isCollapsible: true,
      props: {
        classNameSegment: "segment-recording",
        className: "form-div-script",
        readOnly: true,
        isBubble: true,
      },
      title: <FormattedMessage id="process > title recording feedback" />,
      is_hidden: function(user, process, step, isView) {
        return isEmpty(
          get(process, "data.fields.recording_feedback.value.feedback", [])
        );
      },
    },
    {
      field: "script",
      container: "segment",
      component: "text_timer",
      isCollapsible: true,
      isSpot: true,
      editButton: true,
      isShowEditButtonViewMode: false,
      props: {
        classNameSegment: "segment-script",
        className: "form-div-script",
        readOnly: true,
        hideHelperText: true,
      },
      title: <FormattedMessage id="process > title final script" />,
      isOpen: function(process, isView) {
        return !get(process, "data.fields.recording_path.value", "");
      },
    },
    {
      field: "script_part_b",
    },
    {
      field: "script_history",
    },
    {
      field: "script_audit",
    },
    {
      field: "script_part_b_audit",
    },
    {
      field: "recording_path",
      component: "file_upload",
      container: "segment",
      uploadNew: true,
      isCollapsible: false,
      //segment_privileges: ["submit_production_recording"],
      props: {
        actions: ["download"],
        positionActions: "bottom",
        attachText: <FormattedMessage id="process > field upload recording" />,
        isCheckMimesByExt: true,
        extensions: ["mp3", "wav", "aiff", "flac", "aac", "alac", "m4a", "mp4"],
        isBubble: true,
        classNameSegment: "segment-recording",
        isShowFileNameInEditMode: true,
      },
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        const voiceFeedback = get(
          process,
          "data.fields.voice_feedback.value",
          []
        );
        const isOpen = find(voiceFeedback, (i) => i.is_feedback);
        // Clicked on request change button. We only effect review to vo
        if (isOpen) return true;
        return value !== "" ? true : "Please upload the recording";
      },
      title: <FormattedMessage id="process > field final recording" />,
      is_hidden: function(user, process, step, isView) {
        const voiceFeedback = get(
          process,
          "data.fields.voice_feedback.value",
          []
        );
        const isOpen = find(voiceFeedback, (i) => i.is_feedback);
        // A REQUEST CHANGE button appears below Client Details. The FINAL RECORDING section and Submit button disappears at bottom of page.
        if (isOpen && !isView) return true;
        return (
          isView &&
          isEmpty(get(process, "data.fields.recording_path.value", ""))
        );
      },
    },
    {
      field: "recording_path_b",
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        const voiceFeedback = get(
          process,
          "data.fields.voice_feedback.value",
          []
        );
        const spotInfo = get(process, "fields.spot_info.value", []).find(
          (spot) => spot.key === get(process, "fields.key.value")
        );
        const isBookend =
          get(spotInfo, "spot_type", "Standard") === "Book-ends";
        const isOpen = find(voiceFeedback, (i) => i.is_feedback); // Clicked on request change button. We only effect review to vo
        if (!isBookend || isOpen) return true;
        return value !== "" ? true : "Please upload the recording";
      },
    },
    {
      field: "recording_history",
      container: "segment",
      component: "order_recording_history",
      isCollapsible: true,
      props: {
        classNameSegment: "segment-recording segment-recording-history",
        className: "form-div-script",
        readOnly: true,
        isBubble: true,
      },
      title: <FormattedMessage id="process > title recording history" />,
      is_hidden: function(user, process, step, isView) {
        return isEmpty(get(process, "data.fields.recording_history.value", []));
      },
      isOpen: function({ step, process, template, user }) {
        return false;
      },
    },
    {
      text: <FormattedMessage id="process > button submit" />,
      component: "button",
      key: "save_button",
      props: { color: "blue" },
      container: "bottom_buttons",
      on_click: {
        validate: true,
        save: true,
        go_to: function(_this, step, item, process, template, user, extraData) {
          const return_to_current_tasks = checkUserRedirectToCurrentTasks({
            ...user,
            roles_string: extraData.roles_string,
          });
          if (!return_to_current_tasks) return;
          // set delay when after success message
          delay(() => {
            if (_this.props.setSidebarActive) {
              _this.props.setSidebarActive(`/processes/my_action_items`);
            }
            history.replace(`/processes/my_action_items`);
          }, 4000);
        },
      },
      fields: [
        {
          key: "record_script_status",
          value: "completed",
        },
        {
          key: "voice_over_status",
          value: function(user, template, process, step, item) {
            let isVoProducerSame = false;
            const roleProducers = get(
              process,
              "data.fields.role_producer.value.users",
              []
            ).map((producer) => producer.id);
            const roleVoiceTalens = get(
              process,
              "data.fields.role_voice_talent.value.users",
              []
            ).map((voice) => voice.id);
            const roleVosIsNotProducers = filter(
              roleVoiceTalens,
              (userId) => !includes(roleProducers, userId)
            );
            // empty VOs
            if (
              roleVoiceTalens.length === 0 ||
              roleVosIsNotProducers.length === 0
            )
              isVoProducerSame = true;
            // only VOs assigned that also Producers
            if (
              roleVoiceTalens.length === 1 &&
              includes(roleProducers, roleVoiceTalens[0])
            )
              isVoProducerSame = true;
            if (isVoProducerSame) return "completed";
            return get(process, "data.fields.voice_over_status.value");
          },
        },
        {
          key: "next_users",
          value: (user, template, process, step, item, next_users) => {
            const spotInfo = get(
              process,
              "data.fields.spot_info.value",
              []
            ).find((spot) => spot.key === process.key);
            const isSpotReview =
              process.data.fields.contract_status &&
              process.data.fields.contract_status.value &&
              process.data.fields.contract_status.value ===
                "Production Needed" &&
              get(spotInfo, "recording_requires_approval", false);
            const approveRecordingStatus = get(
              process,
              "data.fields.approve_recording_status.value",
              ""
            );

            let continuityStatus = get(
              process,
              "data.fields.continuity_status.value",
              ""
            );

            const roleContinuity = get(
              process,
              "data.fields.role_continuity.value.users",
              []
            ).map((continuity) => continuity.id);

            let nextUsers = next_users;
            if (continuityStatus !== "completed") {
              nextUsers = uniq([...nextUsers, ...roleContinuity]);
            }
            // ensure role_sales_person and role_sales_person_2 assigned to next users
            if (approveRecordingStatus !== "approved" && isSpotReview) {
              let salesIds = [];
              const roleSalesPerson = get(
                process,
                "data.fields.role_sales_person.value.id"
              );
              const roleSalesPerson2 = get(
                process,
                "data.fields.role_sales_person_2.value.id"
              );
              if (roleSalesPerson) salesIds.push(roleSalesPerson);
              if (roleSalesPerson2) salesIds.push(roleSalesPerson2);
              nextUsers = uniq(
                [...nextUsers, ...salesIds].map((id) => parseInt(id))
              );
            }
            return nextUsers;
          },
        },
        {
          key: "process_step",
          value: "first_undone",
        },
        {
          key: "voice_feedback",
          value: [],
        },
        {
          key: "recording_audit",
          value: (user, template, process, step, item, next_users) => {
            const format = getLocalStorageDateFormat();
            return {
              modified_at: `${moment()
                .format(format)
                .toString()} - ${moment()
                .format("h:mm A")
                .toString()}`,
              modified_by: user.name,
            };
          },
        },
        {
          key: "recording_path_b_audit",
          value: (user, template, process, step, item, next_users) => {
            const spot = find(
              get(process, "data.fields.spot_info.value", []),
              (spot) => spot.key === process.key
            );

            const isBookend =
              get(spot, "spot_type", "Standard") === "Book-ends";

            if (isBookend) {
              const format = getLocalStorageDateFormat();
              return {
                modified_at: `${moment()
                  .format(format)
                  .toString()} - ${moment()
                  .format("h:mm A")
                  .toString()}`,
                modified_by: user.name,
              };
            }

            return {};
          },
        },
        {
          key: "spot_info",
          value: (user, template, process, step, item, next_users) => {
            const spotInfo = get(
              process,
              "data.fields.spot_info.value",
              []
            ).map((spot) => {
              if (spot.key === process.key) {
                return {
                  ...spot,
                  recording_audit: get(
                    process,
                    "data.fields.recording_audit.value",
                    ""
                  ),
                  recording_path_b_audit: get(
                    process,
                    "data.fields.recording_path_b_audit.value",
                    ""
                  ),
                  voice_over_status: "completed", // approved voice
                };
              }
              return spot;
            });
            return spotInfo;
          },
        },
        {
          key: "recording_version",
          value: (user, template, process, step, item, next_users) => {
            if (!get(process, "data.fields.recording_version.value")) {
              return 1;
            }

            if (
              get(process, "data.fields.approve_recording_status.value", "") ===
                "rejected" &&
              get(process, "data.fields.process_step_index.value") === 5
            ) {
              return get(process, "data.fields.recording_version.value") + 1;
            }
          },
        },
        {
          key: "recording_history",
          value: function(user, template, process, step, item, nextUsers) {
            let currentRecordingHistory = get(
              process,
              "data.fields.recording_history.value",
              []
            );
            let recordingFeedback = get(
              process,
              "data.fields.recording_feedback.value",
              []
            );
            let newRecordingHistory = [];

            let recordingApproveStatus = get(
              process,
              "data.fields.approve_recording_status.value",
              ""
            );

            if (
              recordingApproveStatus === "rejected" &&
              get(process, "data.fields.process_step_index.value") === 5
            ) {
              newRecordingHistory.push({
                recording_path: get(recordingFeedback, "recording_path", ""),
                recording_path_b: get(
                  recordingFeedback,
                  "recording_path_b",
                  ""
                ),
                recording_audit: get(recordingFeedback, "recording_audit", ""),
                recording_path_b_audit: get(
                  recordingFeedback,
                  "recording_path_b_audit",
                  ""
                ),
              });
            }

            forEach(currentRecordingHistory, (recordingHistory) => {
              newRecordingHistory.push(recordingHistory);
            });

            return newRecordingHistory;
          },
        },
        {
          key: "recording_feedback",
          value: "",
        },
      ],
      success_dialog_props: {
        title: <FormattedMessage id="process > submitted" />,
        state: "success",
      },
      is_hidden: function(user, process, step, isView) {
        const voiceFeedback = get(
          process,
          "data.fields.voice_feedback.value",
          []
        );
        const spotInfo = get(process, "data.fields.spot_info.value", []).find(
          (spot) => spot.key === process.key
        );
        const producerSendsSpotToClient = get(
          spotInfo,
          "producer_sends_spot_to_client",
          false
        );
        // show send to client button. Will hide the submit button
        if (producerSendsSpotToClient) {
          return true;
        }
        const isOpen = find(voiceFeedback, (i) => i.is_feedback);
        // A REQUEST CHANGE button appears below Client Details. The FINAL RECORDING section and Submit button disappears at bottom of page.
        if (isOpen && !isView) return true;
        return false;
      },
    },
    {
      component: "send_mail",
      container: "bottom_buttons",
      is_hidden: function(user, process, step, isView) {
        const spotInfo = get(process, "data.fields.spot_info.value", []).find(
          (spot) => spot.key === process.key
        );
        const producerSendsSpotToClient = get(
          spotInfo,
          "producer_sends_spot_to_client",
          false
        );
        if (producerSendsSpotToClient && !isView) {
          return false;
        }
        return true;
      },
      props: {
        color: "blue",
        outline: false,
        is_spot_review: false,
        producer_sends_spot_to_client: true,
        is_save_order: true,
      },
    },
    // {
    //   text: <FormattedMessage id="process > button delete" />,
    //   component: "button",
    //   props: { color: "blue", outline: true },
    //   container: "bottom_buttons",
    //   class: null,
    //   privileges: { or: ["delete_production", "edit_all_production_order"] },
    //   confirmation_dialog_props: {
    //     title: <FormattedMessage id="process > confirm delete this spot" />,
    //     confirmTitle: <FormattedMessage id="process > button yes" />,
    //     cancelTitle: <FormattedMessage id="process > button no" />,
    //     onConfirm: (_this, step, item, process, template, user) => {
    //       _this.setState({ confirmationDialogProps: false });
    //       _this.props.deleteProcess(
    //         get(process, "data.fields.key.value"),
    //         () => {
    //           const currentTasks = URL.PROCESSES({
    //             filter: "my_action_items",
    //           });
    //           const nextRoute = _this.props.sidebarActive
    //             ? _this.props.sidebarActive
    //             : currentTasks;
    //           history.push(nextRoute);
    //         }
    //       );
    //     },
    //   },
    //   on_click: {},
    // },
    // https://tasks.getventive.com/projects/5DCE0-565
    {
      text: <FormattedMessage id="process > button move to next step" />,
      component: "button",
      props: { color: "blue", outline: true },
      container: "bottom_buttons",
      is_hidden: function(user, process, step, isView) {
        const recordStatus = get(
          process,
          "data.fields.record_script_status.value",
          ""
        );
        if (recordStatus === "rejected") {
          return false;
        }
        return true;
      },
      on_click: {
        validate: true,
        save: true,
        go_to: function(_this, step, item, process, template, user) {
          // set delay when after success message
          delay(() => {
            if (_this.props.setSidebarActive) {
              _this.props.setSidebarActive(`/processes/my_action_items`);
            }
            history.replace(`/processes/my_action_items`);
          }, 4000);
        },
      },
      fields: [
        {
          key: "approve_recording_status",
          value: "approved",
        },
        {
          key: "sent_to_client_for_spot_review",
          value: false,
        },
        {
          key: "record_script_status",
          value: "completed",
        },
        {
          key: "voice_over_status",
          value: function(user, template, process, step, item) {
            let isVoProducerSame = false;
            const roleProducers = get(
              process,
              "data.fields.role_producer.value.users",
              []
            ).map((producer) => producer.id);
            const roleVoiceTalens = get(
              process,
              "data.fields.role_voice_talent.value.users",
              []
            ).map((voice) => voice.id);
            const roleVosIsNotProducers = filter(
              roleVoiceTalens,
              (userId) => !includes(roleProducers, userId)
            );
            // empty VOs
            if (
              roleVoiceTalens.length === 0 ||
              roleVosIsNotProducers.length === 0
            )
              isVoProducerSame = true;
            // only VOs assigned that also Producers
            if (
              roleVoiceTalens.length === 1 &&
              includes(roleProducers, roleVoiceTalens[0])
            )
              isVoProducerSame = true;
            if (isVoProducerSame) return "completed";
            return get(process, "data.fields.voice_over_status.value");
          },
        },
        {
          key: "next_users",
          value: (user, template, process, step, item, next_users) => {
            const spotInfo = get(
              process,
              "data.fields.spot_info.value",
              []
            ).find((spot) => spot.key === process.key);
            const isSpotReview =
              process.data.fields.contract_status &&
              process.data.fields.contract_status.value &&
              process.data.fields.contract_status.value ===
                "Production Needed" &&
              get(spotInfo, "recording_requires_approval", false);
            const approveRecordingStatus = get(
              process,
              "data.fields.approve_recording_status.value",
              ""
            );

            let continuityStatus = get(
              process,
              "data.fields.continuity_status.value",
              ""
            );

            const roleContinuity = get(
              process,
              "data.fields.role_continuity.value.users",
              []
            ).map((continuity) => continuity.id);

            let nextUsers = next_users;
            if (continuityStatus !== "completed") {
              nextUsers = uniq([...nextUsers, ...roleContinuity]);
            }
            // ensure role_sales_person and role_sales_person_2 assigned to next users
            if (approveRecordingStatus !== "approved" && isSpotReview) {
              let salesIds = [];
              const roleSalesPerson = get(
                process,
                "data.fields.role_sales_person.value.id"
              );
              const roleSalesPerson2 = get(
                process,
                "data.fields.role_sales_person_2.value.id"
              );
              if (roleSalesPerson) salesIds.push(roleSalesPerson);
              if (roleSalesPerson2) salesIds.push(roleSalesPerson2);
              nextUsers = uniq(
                [...nextUsers, ...salesIds].map((id) => parseInt(id))
              );
            }
            return filter(nextUsers, (id) => id).map(Number);
          },
        },
        {
          key: "process_step",
          value: "first_undone",
        },
        {
          key: "voice_feedback",
          value: [],
        },
        {
          key: "recording_audit",
          value: (user, template, process, step, item, next_users) => {
            return {
              modified_at: `${moment()
                .format("MM/DD/YYYY")
                .toString()} - ${moment()
                .format("h:mm A")
                .toString()}`,
              modified_by: user.name,
            };
          },
        },
        {
          key: "recording_path_b_audit",
          value: (user, template, process, step, item, next_users) => {
            const spot = find(
              get(process, "data.fields.spot_info.value", []),
              (spot) => spot.key === process.key
            );

            const isBookend =
              get(spot, "spot_type", "Standard") === "Book-ends";

            if (isBookend) {
              return {
                modified_at: `${moment()
                  .format("MM/DD/YYYY")
                  .toString()} - ${moment()
                  .format("h:mm A")
                  .toString()}`,
                modified_by: user.name,
              };
            }

            return {};
          },
        },
        {
          key: "spot_info",
          value: (user, template, process, step, item, next_users) => {
            const spotInfo = get(
              process,
              "data.fields.spot_info.value",
              []
            ).map((spot) => {
              if (spot.key === process.key) {
                return {
                  ...spot,
                  recording_audit: get(
                    process,
                    "data.fields.recording_audit.value",
                    ""
                  ),
                  recording_path_b_audit: get(
                    process,
                    "data.fields.recording_path_b_audit.value",
                    ""
                  ),
                  voice_over_status: "completed", // approved voice
                  approve_recording_status: "approved",
                };
              }
              return spot;
            });
            return spotInfo;
          },
        },
        {
          key: "recording_version",
          value: (user, template, process, step, item, next_users) => {
            if (!get(process, "data.fields.recording_version.value")) {
              return 1;
            }

            if (
              get(process, "data.fields.approve_recording_status.value", "") ===
                "rejected" &&
              get(process, "data.fields.process_step_index.value") === 5
            ) {
              return get(process, "data.fields.recording_version.value") + 1;
            }
          },
        },
        {
          key: "recording_history",
          value: function(user, template, process, step, item, nextUsers) {
            let currentRecordingHistory = get(
              process,
              "data.fields.recording_history.value",
              []
            );
            let recordingFeedback = get(
              process,
              "data.fields.recording_feedback.value",
              []
            );
            let newRecordingHistory = [];

            let recordingApproveStatus = get(
              process,
              "data.fields.approve_recording_status.value",
              ""
            );

            if (
              recordingApproveStatus === "rejected" &&
              get(process, "data.fields.process_step_index.value") === 5
            ) {
              newRecordingHistory.push({
                recording_path: get(recordingFeedback, "recording_path", ""),
                recording_path_b: get(
                  recordingFeedback,
                  "recording_path_b",
                  ""
                ),
                recording_audit: get(recordingFeedback, "recording_audit", ""),
                recording_path_b_audit: get(
                  recordingFeedback,
                  "recording_path_b_audit",
                  ""
                ),
              });
            }

            forEach(currentRecordingHistory, (recordingHistory) => {
              newRecordingHistory.push(recordingHistory);
            });

            return newRecordingHistory;
          },
        },
        {
          key: "recording_feedback",
          value: "",
        },
      ],
      move_next_task: true,
      confirmation_dialog_props: {
        title: (
          <FormattedMessage id="process > description to submit move to next step" />
        ),
        confirmTitle: <FormattedMessage id="process > button yes" />,
        cancelTitle: <FormattedMessage id="process > button no" />,
      },
      success_dialog_props: {
        title: <FormattedMessage id="process > title submitted" />,
        state: "success",
      },
    },
  ],
};
export default recordScript;
