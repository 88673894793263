import AddStationPage from "./view";
import { get } from "lodash";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
export default injectIntl(
  connect((state, props) => {
    const { match } = props;
    let id = get(match, "params.id");
    return {
      id,
      isUpdate: id ? true : false
    };
  }, {})(AddStationPage)
);
