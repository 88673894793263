import React from "react";
import bn from "utils/bemnames";
import { MenuIcon } from "components/CustomIcons";
import classnames from "classnames";
import { FaClose } from "react-icons/lib/fa";
import { FormattedMessage } from "react-intl";
import { labelToNameField } from "utils/helpers";
import { FormGroup, Row, Col, Label } from "reactstrap";
import ToggleSwitch from "components/ToggleSwitch";
import TextInput from "components/TextInput";
import { get, filter } from "lodash";
import Checkbox from "components/Checkbox";
import TextInputFormatted from "components/TextInputFormatted";
import { PhoneNumberFormat } from "utils/config";
import NumberFormat from "react-number-format";

import { Asterisk } from "components/Elements";
import { getFieldName } from "../helper";
const bem = bn.create("form-builder-fields");

const PhoneNumber = ({
  intl,
  isNew,
  isView,
  field,
  value,
  errors,
  touched,
  onChange,
  onDelete,
  allowDelete,
  isFormBuilder,
  highlightFields,
  disabled,
  isMasterListener,
  isShowRequiredToggle,
  isRenderRow,
  colNumber,
  isShowPrimary,
  values,
  isShowOffText,
  isRenderFieldNameFromLabel,
  isPrimaryValueAsFieldName,
}) => {
  // UI for form builder
  if (isFormBuilder) {
    if (isNew) {
      const isHighlightFormField =
        filter(
          highlightFields,
          (highlightFieldItem) =>
            highlightFieldItem.uuid === value.uuid &&
            highlightFieldItem.count_highlight > 0
        ).length > 0;
      return (
        <Row>
          <Col className="form-builder-field">
            <div className={bem.b()}>
              <div
                className={classnames(
                  bem.e("form-field-group"),
                  bem.e("form-field-group-line"),
                  {
                    [bem.e("form-field-group-highlight")]: isHighlightFormField,
                    [bem.e("form-field-group-disable")]:
                      highlightFields.length > 0 &&
                      !isHighlightFormField &&
                      value.is_controlled,
                  }
                )}
              >
                <Row>
                  <Col xs={isShowRequiredToggle ? 9 : 12}>
                    <div className={bem.e("form-field-group-head")}>
                      <div
                        className={bem.e("form-field-group-head-single-left")}
                      >
                        <span className={bem.e("menu-icon")}>
                          <MenuIcon />
                        </span>
                        <div
                          className={classnames(
                            bem.e("input-label"),
                            {
                              [bem.e(
                                "full-input-label"
                              )]: !isShowRequiredToggle,
                            },
                            {
                              [bem.e("master-input-field")]: isMasterListener,
                            }
                          )}
                        >
                          <TextInput
                            label={null}
                            name="label"
                            type="text"
                            placeholder={intl.formatMessage({
                              id: "form builder > label phone number",
                            })}
                            disabled={disabled}
                            value={value.label}
                            onChange={({ target }) => {
                              onChange({
                                ...value,
                                label: target.value,
                              });
                            }}
                            error={get(errors, "label", "")}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  {isShowRequiredToggle ? (
                    <Col xs={3}>
                      <ToggleSwitch
                        leftComponent={
                          <span
                            className={classnames({
                              [bem.e("switch-active")]: !get(
                                value,
                                "is_required",
                                false
                              ),
                            })}
                          >
                            {isShowOffText
                              ? intl.formatMessage({
                                  id: "form builder > off",
                                })
                              : ""}
                          </span>
                        }
                        rightComponent={
                          <span
                            className={classnames({
                              [bem.e("switch-active")]: get(
                                value,
                                "is_required",
                                false
                              ),
                            })}
                          >
                            {intl.formatMessage({
                              id: "form builder > required",
                            })}
                          </span>
                        }
                        name="is_required"
                        switchProps={{
                          checked: !get(value, "is_required", false),
                          onChange: (checked) => {
                            onChange({
                              ...value,
                              is_required: !checked,
                            });
                          },
                          disabled: disabled,
                          offColor: "#795AFA",
                          onColor: "#E3ECF1",
                          uncheckedIcon: false,
                          checkedIcon: false,
                          handleDiameter: 22,
                          width: 50,
                          height: 26,
                        }}
                      />
                    </Col>
                  ) : null}
                </Row>
                {allowDelete && (
                  <div
                    className={classnames(
                      bem.e("form-field-group-head-right"),
                      bem.e("form-field-group-delete-icon")
                    )}
                    onClick={onDelete}
                  >
                    <FaClose size="23" color="#C2D4E0" />
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row>
          <Col>
            <div className={bem.b()}>
              <div className={bem.e("form-field-group-saved")}>
                <FormGroup>
                  <TextInputFormatted
                    label={
                      <span>
                        {get(value, "label", "")}{" "}
                        {get(value, "is_required", false) && (
                          <Asterisk>*</Asterisk>
                        )}
                      </span>
                    }
                    placeholder={intl.formatMessage({
                      id: "form builder > placeholder phone number",
                    })}
                    value={""}
                    format={PhoneNumberFormat}
                    onChange={(value) => {}}
                  />
                </FormGroup>
                {allowDelete && (
                  <div
                    className={bem.e("form-field-group-saved-button-close")}
                    onClick={onDelete}
                  >
                    <FaClose size="23" color="#C2D4E0" />
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    // ui for form
  } else {
    const fieldName = isRenderFieldNameFromLabel
      ? labelToNameField(get(field, "label", ""))
      : getFieldName(field);
    const fieldNamePrimary = isPrimaryValueAsFieldName
      ? "primary_contact"
      : labelToNameField(`${get(field, "label", "")} is primary`);
    if (isView) {
      return (
        <Row>
          <Col>
            <div className={bem.b()}>
              <div className={bem.e("form-field-group-saved")}>
                <Label>{get(field, "label", "")}</Label>
                <span className="order-view-value">
                  {value ? (
                    <NumberFormat
                      value={value}
                      displayType={"text"}
                      format={PhoneNumberFormat}
                    />
                  ) : (
                    <FormattedMessage id="form builder > none" />
                  )}
                </span>
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    const elementField = (
      <div className={bem.b()}>
        <div
          className={classnames(
            bem.e("form-field-group-saved"),
            "form-builder-edit-field-2",
            {
              [bem.e("group-primary")]: isShowPrimary,
            }
          )}
        >
          <FormGroup className={bem.e("form-group")}>
            <TextInputFormatted
              label={
                <span>
                  {get(field, "label", "")}{" "}
                  {get(field, "is_required", false) && <Asterisk>*</Asterisk>}
                </span>
              }
              placeholder={intl.formatMessage({
                id: "form builder > placeholder phone number",
              })}
              value={value || ""}
              format={PhoneNumberFormat}
              onChange={(value) => {
                onChange(value);
              }}
              disabled={disabled}
              error={
                get(touched, fieldName, false) && get(errors, fieldName, "")
                  ? get(errors, fieldName, "")
                  : ""
              }
            />

            {isShowPrimary && (
              <Checkbox
                checked={
                  isPrimaryValueAsFieldName
                    ? get(values, fieldNamePrimary, "") === fieldName
                    : !!get(values, fieldNamePrimary, false)
                }
                onChange={(checked) => {
                  if (isPrimaryValueAsFieldName) {
                    onChange(checked ? fieldName : "", {
                      field: {
                        label: `${field.label} is primary`,
                        key: "is_primary",
                      },
                    });
                  } else {
                    onChange(checked, {
                      field: {
                        label: `${field.label} is primary`,
                        key: "is_primary",
                      },
                    });
                  }
                }}
                text={intl.formatMessage({ id: "form builder > primary" })}
              />
            )}
          </FormGroup>
        </div>
      </div>
    );
    if (isRenderRow)
      return (
        <Row>
          <Col>{elementField}</Col>
        </Row>
      );
    return <Col xs={colNumber}>{elementField}</Col>;
  }
};
PhoneNumber.defaultProps = {
  isNew: false,
  isFormBuilder: true,
  errors: {},
  touched: {},
  onChange: () => {},
  onDelete: () => {},
  allowDelete: true,
  disabled: false,
  isMasterListener: false,
  isShowRequiredToggle: true,
  isRenderRow: true,
  colNumber: 6,
  isShowPrimary: false,
  isShowOffText: true,
  isRenderFieldNameFromLabel: true,
  isPrimaryValueAsFieldName: false,
};
export default PhoneNumber;
