import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { FormattedMessage } from "react-intl";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";
import PrivilegedComponent from "components/PrivilegedComponent";
import { PRIVILEGES } from "utils/constants";
import classnames from "classnames";
import bn from "utils/bemnames";
import OrderDetailsSchedule from "components/OrderDetailsSchedule";
import { CloseIcon } from "components/CustomIcons";
import ContentProductionNeeded from "./ContentProductionNeeded";
import ContentProductionProvided from "./ContentProductionProvided";
import ConfirmationModal from "components/ConfirmationModal";
import Spinner from "components/Spinner";
import CopyOrderModal from "components/OrderDetails/CopyOrderModal";
import { useEffect } from "react";
import history from "components/History";
import ToastManager from "components/ToastManager";
import URL from "utils/urls";

const bem = bn.create("process-dialog");

const ProcessDialog = (props) => {
  const { process, isOpen, onToggle, className, button } = props;
  const template_key = process ? process._source.template_key : "";
  const processData = useMemo(() => {
    // re-format process from process source
    const processSource = get(process, "_source", {});
    let fields = {};
    Object.keys(processSource).map(
      (key) => (fields[key] = { value: processSource[key] })
    );
    return {
      key: get(process, "_id"),
      data: {
        fields: {
          ...fields,
          key: {
            value: get(process, "_id"),
          },
        },
      },
    };
  }, [process]);
  const [isOpenDialog, setIsOpenDialog] = useState(props.isOpenDialog || false);
  const [isOpenScheduleAgain, setIsOpenScheduleAgain] = useState(false);
  const [isOpenConfirmUnarchive, setIsOpenConfirmUnarchive] = useState(false);

  const [
    isOpenConvertSpotConfirmationModal,
    setIsOpenConvertSpotConfirmationModal,
  ] = useState(false);
  const [
    isOpenProductionPromptModal,
    setIsOpenProductionPromptModal,
  ] = useState(false);
  useEffect(() => {
    // force open dialog from props
    if (
      props.isOpenDialog !== undefined &&
      props.isOpenDialog !== isOpenDialog
    ) {
      setIsOpenDialog(props.isOpenDialog);
    }
  }, [props.isOpenDialog]);

  const onClickSchedule = () => {
    setIsOpenScheduleAgain(true);
  };

  const onClickDuplicate = () => {
    if (button === "expiring_spots") {
      setIsOpenProductionPromptModal(true);
    } else {
      setIsOpenDialog(true);
    }
  };
  const onUnarchive = () => {
    const processKey = get(process, "_id");
    props.setIsLoading(true);
    props.unarchiveProcess(processKey, () => {
      props.setIsLoading(false);
      ToastManager.show({
        message: props.intl.formatMessage({
          id: "toast > title saved",
        }),
        level: "success",
      });
      props.onToggle();
    });
  };

  const onConvertToSold = () => {
    const template_key = process ? process._source.template_key : "";
    const processKey = get(process, "_id");
    props.setIsLoading(true);
    props.convertSpecToSold(
      {
        id: processKey,
      },
      () => {
        props.setIsLoading(false);
        history.push(
          URL.UPDATE_PROCESS({
            template_id: template_key,
            process_id: processKey,
          })
        );
        ToastManager.show({
          message: props.intl.formatMessage({
            id: "toast > title convert spec to sold success",
          }),
          level: "success",
        });
        props.onToggle();
      }
    );
  };

  const onArchive = () => {
    const processKey = get(process, "_id");
    props.setIsLoading(true);
    props.archiveProcess(processKey, () => {
      props.setIsLoading(false);
      ToastManager.show({
        message: props.intl.formatMessage({
          id: "toast > title saved",
        }),
        level: "success",
      });
      props.onToggle();
    });
  };

  const onToggleProductionPromptModal = () => {
    setIsOpenProductionPromptModal(!isOpenProductionPromptModal);
  };

  const isProductionNeeded =
    get(process, "_source.contract_status", "") === "Production Needed";

  const isSpecOrder =
    get(process, "_source.order_sold") === false &&
    props.button === "produced_spots";

  const isArchived = get(process, "_source.is_archived", false);
  const isShowArchive = isSpecOrder && !isArchived;

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        className={classnames(className, bem.b())}
        size="lg"
      >
        <ModalBody className={bem.e("body")}>
          <button className={bem.e("close")} onClick={onToggle}>
            <CloseIcon color={"#657894"} />
          </button>
          <div className={bem.e("client-name")}>
            {get(process, "_source.client_name", "")}
          </div>
          <div className={bem.e("title")}>
            {get(process, "_source.order_title", "")}
          </div>
          {isProductionNeeded ? (
            <ContentProductionNeeded process={process} />
          ) : (
            <ContentProductionProvided process={process} />
          )}
        </ModalBody>
        <ModalFooter className={bem.e("footer")}>
          {button === "produced_spots" ? (
            <>
              <PrivilegedComponent
                requires={{
                  or: [
                    PRIVILEGES.ADD_PRODUCTION,
                    PRIVILEGES.EDIT_ALL_PRODUCTION_ORDERS,
                  ],
                }}
              >
                <Button
                  className={classnames(
                    "btn-blue btn-block btn-radius",
                    bem.e("reschedule-button")
                  )}
                  color="blue"
                  onClick={
                    isSpecOrder
                      ? () => setIsOpenConvertSpotConfirmationModal(true)
                      : onClickSchedule
                  }
                >
                  {isSpecOrder ? (
                    <FormattedMessage id="process > button convert to sold" />
                  ) : (
                    <FormattedMessage id="process > button schedule again" />
                  )}
                </Button>
              </PrivilegedComponent>
              {isShowArchive ? (
                <PrivilegedComponent
                  requires={{
                    or: [PRIVILEGES.ARCHIVE_PRODUCTION],
                  }}
                >
                  <Button
                    color="blue"
                    outline
                    className={classnames("btn-radius", bem.e("edit-button"))}
                    onClick={onArchive}
                  >
                    <FormattedMessage id="process > button archive" />
                  </Button>
                </PrivilegedComponent>
              ) : (
                <PrivilegedComponent
                  requires={{
                    or: [
                      PRIVILEGES.ADD_PRODUCTION,
                      PRIVILEGES.EDIT_ALL_PRODUCTION_ORDERS,
                    ],
                  }}
                >
                  <Button
                    color="blue"
                    outline
                    className={classnames(
                      "btn-radius btn-block",
                      bem.e("edit-button")
                    )}
                    onClick={onClickDuplicate}
                  >
                    <FormattedMessage id="process > button duplicate spot" />
                  </Button>
                </PrivilegedComponent>
              )}
            </>
          ) : button === "archived_spots" ? (
            <>
              {isArchived && (
                <PrivilegedComponent
                  requires={{
                    or: [PRIVILEGES.ARCHIVE_PRODUCTION],
                  }}
                >
                  <Button
                    className={classnames(
                      "btn-blue btn-radius",
                      bem.e("unarchive-button")
                    )}
                    color="blue"
                    onClick={() => setIsOpenConfirmUnarchive(true)}
                  >
                    <FormattedMessage id="process > button unarchive" />
                  </Button>
                </PrivilegedComponent>
              )}
              <PrivilegedComponent
                requires={{
                  or: [
                    PRIVILEGES.ADD_PRODUCTION,
                    PRIVILEGES.EDIT_ALL_PRODUCTION_ORDERS,
                  ],
                }}
              >
                <Button
                  color="blue"
                  outline
                  className={classnames(
                    "btn-radius btn-block",
                    bem.e("edit-button")
                  )}
                  onClick={onClickDuplicate}
                >
                  <FormattedMessage id="process > button duplicate spot" />
                </Button>
              </PrivilegedComponent>
            </>
          ) : (
            <>
              <PrivilegedComponent
                requires={{
                  or: [
                    PRIVILEGES.ADD_PRODUCTION,
                    PRIVILEGES.EDIT_ALL_PRODUCTION_ORDERS,
                  ],
                }}
              >
                <Button
                  className={classnames(
                    "btn-blue btn-block btn-radius",
                    bem.e("reschedule-button")
                  )}
                  color="blue"
                  onClick={onClickSchedule}
                >
                  <FormattedMessage id="process > button schedule again" />
                </Button>
              </PrivilegedComponent>
              <PrivilegedComponent
                requires={{
                  or: [
                    PRIVILEGES.ADD_PRODUCTION,
                    PRIVILEGES.EDIT_ALL_PRODUCTION_ORDERS,
                  ],
                }}
              >
                <Button
                  color="blue"
                  outline
                  className={classnames(
                    "btn-radius btn-block",
                    bem.e("edit-button")
                  )}
                  onClick={onClickDuplicate}
                >
                  {button === "expiring_spots" ? (
                    <FormattedMessage id="process > button add a spot" />
                  ) : (
                    <FormattedMessage id="process > button duplicate spot" />
                  )}
                </Button>
              </PrivilegedComponent>
            </>
          )}
        </ModalFooter>
        <Spinner isLoading={props.isLoading} />
      </Modal>
      <CopyOrderModal
        isOpen={isOpenDialog}
        onToggle={() => setIsOpenDialog(!isOpenDialog)}
        process={processData}
      />
      <Modal
        isOpen={isOpenScheduleAgain}
        size="lg"
        className={classnames(className, bem.b(), bem.m("schedule-again"))}
      >
        <ModalHeader>
          <div className={bem.e("title")}>
            <FormattedMessage id="process > title copy the following to schedule again" />
          </div>
          <button className={bem.e("close")} onClick={onToggle}>
            <CloseIcon color={"#657894"} />
          </button>
        </ModalHeader>
        <ModalBody className={bem.e("body")}>
          <OrderDetailsSchedule
            process={process}
            template={template_key}
            close={() => setIsOpenScheduleAgain(false)}
            isShowSuccessModal={button === "expiring_spots"}
          />
        </ModalBody>
      </Modal>
      <ConfirmationModal
        centered
        isOpen={isOpenProductionPromptModal}
        className={"production-prompt-modal production-prompt-details-modal"}
        title={
          <FormattedMessage id="process > title who will produce the spots for this order" />
        }
        confirmTitle={
          <FormattedMessage id="process > button production needed" />
        }
        cancelTitle={
          <FormattedMessage id="process > button production provided" />
        }
        descriptionBottom={
          <div className={"description-spots-button"}>
            <div className={"description-left"}>
              <FormattedMessage id="process > description the spots need to be produced" />
            </div>
            <div className={"description-right"}>
              <FormattedMessage id="process > description the client or agency is producing the spots" />
            </div>
          </div>
        }
        onConfirm={() => {
          setIsOpenProductionPromptModal(false);
          setIsOpenDialog(true);
        }}
        onCancel={() => {
          setIsOpenProductionPromptModal(true);
          props.onDuplicate({
            template_id: "production_provided",
          });
        }}
        onToggle={onToggleProductionPromptModal}
        isCloseOutside={true}
      />
      <ConfirmationModal
        centered
        isOpen={isOpenConfirmUnarchive}
        title={
          <FormattedMessage id="process > title ready to unarchive this order" />
        }
        description={
          <FormattedMessage id="process > description unarchive this order" />
        }
        confirmTitle={<FormattedMessage id="process > button yes" />}
        cancelTitle={<FormattedMessage id="process > button cancel" />}
        onConfirm={() => {
          setIsOpenConfirmUnarchive(false);
          onUnarchive();
        }}
        onCancel={() => {
          setIsOpenConfirmUnarchive(false);
        }}
        onToggle={() => setIsOpenConfirmUnarchive(false)}
        isCloseOutside={false}
      />
      <ConfirmationModal
        centered
        isOpen={isOpenConvertSpotConfirmationModal}
        title={
          <FormattedMessage id="process > title are you sure you want to convert this order to sold" />
        }
        confirmTitle={<FormattedMessage id="process > button yes" />}
        cancelTitle={<FormattedMessage id="process > button no" />}
        onConfirm={() => {
          setIsOpenConvertSpotConfirmationModal(false);
          onConvertToSold();
        }}
        onCancel={() => {
          setIsOpenConvertSpotConfirmationModal(false);
        }}
        onToggle={() => {
          setIsOpenConvertSpotConfirmationModal(false);
        }}
        isCloseOutside={false}
      />
    </React.Fragment>
  );
};

ProcessDialog.propTypes = {
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
  process: PropTypes.any,
  className: PropTypes.string,
  button: PropTypes.string,
};

ProcessDialog.defaultProps = {
  onToggle: () => {},
  process: {},
  button: "",
};

export default ProcessDialog;
