import React from "react";
import {
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
} from "reactstrap";
import Avatar from "components/Avatar";
// import { FaUser } from "react-icons/lib/fa";
import SecurityPolicy from "components/SecurityPolicy";
import AdminRights from "components/AdminRights";
import PrivilegesEffective from "components/PrivilegesEffective";
import UserForm from "components/UserForm";
import UserAssignments from "components/UserAssignments";
import { get } from "lodash";
import EditImageModal from "components/EditImageModal";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { MODE_ADD as USER_FORM_MODE_ADD } from "components/UserForm";
import { EditProfileIcon } from "components/CustomIcons";
import bn from "utils/bemnames";
import URL from "utils/urls";
import history from "components/History";
import PrivilegedComponent from "components/PrivilegedComponent";
import { PRIVILEGES } from "utils/constants";
export const MODE_VIEW = "view";
export const MODE_EDIT = "edit";

export const TAB_PROFILE = "profile";
export const TAB_SECURITY = "security";
export const TAB_PRIVILEGES = "privileges";
export const TAB_ADMIN = "admin";
export const TAB_ASSIGNMENTS = "assignments";

const bem = bn.create("user-detail");

const AdminsDetails = ({
  auth,
  activeTab,
  setActiveTab,
  back,
  lists,
  onSubmitPrivileges,
  isLoadingPrivileges,
  isView,
  isOpenEditAvatarModal,
  setIsOpenEditAvatarModal,
  onUploadAvatar,
  user,
  onSelectedEdit,
  intl,
  aTab,
}) => {
  let backButton = (
    <Button
      type="button"
      color="blue"
      outline
      className={classnames(bem.e("button-cancel"), "btn-radius")}
      onClick={() => {
        if (document.location.href.indexOf("/users/add") > -1) {
          const url = URL.ADMINS({ tab: "users" });
          history.replace(url);
        }
        if (back) back();
      }}
    >
      <FormattedMessage id="my profile > button cancel" />
    </Button>
  );
  let ids = [];
  let single_user = false;
  if (lists) {
    ids = Object.keys(lists);
    if (lists && Object.keys(lists).length === 1) {
      single_user = true;
    }
  }

  const isServerList =
    auth.info.app_flags && auth.info.app_flags.indexOf("SERVERS_LIST") > -1;

  if (!user) user = get(auth, "user");
  /*const isVisibleTabSecurity =
    get(auth, "user.is_god_admin") ||
    get(auth, "user.broadcasting_group_admin", null);*/
  const isVisibleTabSecurity = false;
  const isVisibleTabPrivileges = !single_user && isView !== USER_FORM_MODE_ADD;
  const isVisibleTabAdminRights =
    !isServerList &&
    single_user &&
    (get(auth, "user.is_god_admin") ||
      get(auth, "user.broadcasting_group_admin", null)) &&
    isView !== USER_FORM_MODE_ADD;
  const isVisibleTabDetails = single_user;
  const isVisibleTabAssignments = !isServerList && single_user;
  return (
    <div>
      {single_user && (
        <div className={bem.e("info-profile")}>
          <div className={bem.e("user-profile-left")}>
            <div className={bem.e("user-avatar")}>
              <Avatar
                className={bem.e("avatar")}
                src={get(user, "avatar_url")}
                name={get(user, "name")}
                size={100}
              />
              <span
                className={bem.e("edit-icon")}
                onClick={() => setIsOpenEditAvatarModal(true)}
              >
                <EditProfileIcon />
              </span>
              <EditImageModal
                isOpen={isOpenEditAvatarModal}
                onToggle={() => setIsOpenEditAvatarModal(false)}
                url={get(user, "avatar_url")}
                mimes={["image/jpeg", "image/png", "image/bmp"]}
                onUploaded={(image) => {
                  onUploadAvatar(image, user);
                }}
                title={
                  <FormattedMessage id="edit image > modal profile pic title" />
                }
              />
            </div>
            <div className={bem.e("user-name")}>
              <span className={bem.e("text-head")}>
                {get(user, "name")
                  ? get(user, "name")
                  : intl.formatMessage({
                      id: "user > default user name",
                    })}
              </span>
            </div>
          </div>
          <div className={bem.e("user-position")}>
            <span className={bem.e("text-head")}>
              {get(user, "server.name", "")}
            </span>
          </div>
        </div>
      )}

      <div className={bem.b()}>
        <div>
          <Nav tabs className={bem.e("nav")}>
            {isVisibleTabDetails ? (
              <NavItem>
                <NavLink
                  className={classnames(bem.e("tab-link"), {
                    [bem.e("active")]: activeTab === TAB_PROFILE,
                  })}
                  onClick={() => {
                    setActiveTab(TAB_PROFILE);
                  }}
                >
                  <FormattedMessage id="my profile > tab user info" />
                </NavLink>
              </NavItem>
            ) : null}
            {isVisibleTabAssignments ? (
              <PrivilegedComponent requires={[PRIVILEGES.ADD_PRIVILEGES]}>
                <NavItem>
                  <NavLink
                    className={classnames(bem.e("tab-link"), {
                      [bem.e("active")]: activeTab === TAB_ASSIGNMENTS,
                    })}
                    onClick={() => {
                      setActiveTab(TAB_ASSIGNMENTS);
                    }}
                  >
                    <FormattedMessage id="my profile > tab assignments" />
                  </NavLink>
                </NavItem>
              </PrivilegedComponent>
            ) : null}
            {isVisibleTabSecurity ? (
              <NavItem>
                <NavLink
                  className={classnames(bem.e("tab-link"), {
                    [bem.e("active")]: activeTab === TAB_SECURITY,
                  })}
                  onClick={() => {
                    setActiveTab(TAB_SECURITY);
                  }}
                >
                  <FormattedMessage id="my profile > tab security policy" />
                </NavLink>
              </NavItem>
            ) : null}

            {isVisibleTabPrivileges && (
              <NavItem>
                <NavLink
                  className={classnames(bem.e("tab-link"), {
                    [bem.e("active")]: activeTab === TAB_PRIVILEGES,
                  })}
                  onClick={() => {
                    setActiveTab(TAB_PRIVILEGES);
                  }}
                >
                  <FormattedMessage id="my profile > tab privileges" />
                </NavLink>
              </NavItem>
            )}
            {/*{isVisibleTabAdminRights ? (
              <NavItem>
                <NavLink
                  className={classnames(bem.e("tab-link"), {
                    [bem.e("active")]: activeTab === TAB_ADMIN,
                  })}
                  onClick={() => {
                    setActiveTab(TAB_ADMIN);
                  }}
                >
                  <FormattedMessage id="my profile > tab admin rights" />
                </NavLink>
              </NavItem>
            ) : null}*/}
          </Nav>
          <TabContent activeTab={activeTab}>
            {isVisibleTabDetails && (
              <TabPane tabId={TAB_PROFILE}>
                {activeTab === TAB_PROFILE && (
                  <Row>
                    <Col>
                      <div className={bem.e("tab-detail")}>
                        <UserForm
                          isView={isView}
                          id={isView === USER_FORM_MODE_ADD ? "new" : user.id}
                          backButton={backButton}
                          onSelectedEdit={onSelectedEdit}
                          onBack={() => back()}
                          isAdmin={aTab === "admins" ? true : false}
                        />
                      </div>
                    </Col>
                  </Row>
                )}
              </TabPane>
            )}
            {isVisibleTabAssignments && (
              <PrivilegedComponent requires={[PRIVILEGES.ADD_PRIVILEGES]}>
                <TabPane tabId={TAB_ASSIGNMENTS}>
                  {activeTab === TAB_ASSIGNMENTS && (
                    <Row>
                      <Col className={bem.e("reset-position")}>
                        <div className={bem.e("tab-detail")}>
                          <UserAssignments
                            isFocused={activeTab === TAB_ASSIGNMENTS}
                            id={user.id}
                            mode={isView}
                            backButton={backButton}
                          />
                        </div>
                      </Col>
                    </Row>
                  )}
                </TabPane>
              </PrivilegedComponent>
            )}
            {isVisibleTabSecurity ? (
              <TabPane tabId={TAB_SECURITY}>
                <Row>
                  <Col>
                    <div className={bem.e("tab-detail")}>
                      <SecurityPolicy
                        isFocused={activeTab === TAB_SECURITY}
                        ids={ids}
                        mode={isView}
                        backButton={backButton}
                      />
                    </div>
                  </Col>
                </Row>
              </TabPane>
            ) : null}

            {isVisibleTabPrivileges ? (
              <TabPane tabId={TAB_PRIVILEGES}>
                <Row>
                  <Col>
                    <div className={bem.e("tab-detail")}>
                      <PrivilegesEffective
                        onSubmit={onSubmitPrivileges}
                        backButton={backButton}
                        isLoading={isLoadingPrivileges}
                      />
                    </div>
                  </Col>
                </Row>
              </TabPane>
            ) : null}

            {isVisibleTabAdminRights ? (
              <TabPane tabId={TAB_ADMIN}>
                <Row>
                  <Col>
                    <div className={bem.e("tab-detail")}>
                      <AdminRights
                        isFocused={activeTab === TAB_ADMIN}
                        ids={ids}
                        backButton={backButton}
                        mode={isView}
                      />
                    </div>
                  </Col>
                </Row>
              </TabPane>
            ) : null}
          </TabContent>
        </div>
      </div>
    </div>
  );
};
export default AdminsDetails;
