import React from "react";
import bn from "utils/bemnames";
import { FormattedMessage } from "react-intl";
import { find, get, map, sumBy } from "lodash";
import { fulfillmentList } from "utils/config";
const bem = bn.create("jock-console-content");
export default function PrizePackage(props) {
  const { schedule } = props;
  const prizes = get(schedule, "prizes", []);
  const totalPrizes = sumBy(prizes, "total_value");
  const isPickYourPrizeTheme = schedule.theme === "pick_your_prize";
  const is54321Theme = schedule.theme === "5_4_3_2_1";
  const isStandardGiveawayTheme = schedule.theme === "standard_giveaway";
  const prizeCategory = get(prizes, "0.category");
  const isCashVariablePrize = prizeCategory === "variable_cash";
  const isStandardCash = isStandardGiveawayTheme && isCashVariablePrize;
  const getTitle = () => {
    if (isPickYourPrizeTheme) {
      return "jock console > select a prize";
    }
    if (isStandardCash) {
      return "jock console > prize";
    }
    return "jock console > prize package";
  };

  return (
    <div className={bem.e("jock-card-table")}>
      <div className={bem.e("jock-card-table-title")}>
        <h2>
          <FormattedMessage id={getTitle()} />
        </h2>
        <div className={bem.e("jock-card-table-title-info")}>
          <FormattedMessage id="jock console > total value" />
          <strong>${totalPrizes}</strong>
        </div>
      </div>
      <div className={bem.e("jock-card-table-middle")}>
        <div className={bem.e("contest-description")}>
          <h3>
            <FormattedMessage id="jock console > contest description" />
          </h3>
          <span>{schedule.description}</span>
        </div>
      </div>
      <div className={bem.e("jock-card-table-bottom")}>
        <table className={bem.e("prize-table")}>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="jock console > prize" />
              </th>
              <th>
                <FormattedMessage id="jock console > fulfillment" />
              </th>
              <th className="text-center">
                <FormattedMessage id="jock console > quantity" />
              </th>
              <th>
                <FormattedMessage id="jock console > value" />
              </th>
            </tr>
          </thead>
          <tbody>
            {map(prizes, (prize, index) => {
              const fulfillment = find(
                fulfillmentList,
                (item) => item.value === prize.fulfillment
              );
              return (
                <tr key={`${prize.key}-${index}`}>
                  <td className={is54321Theme ? bem.e("prize-column") : ""}>
                    <strong>{prize.title}</strong>
                    {is54321Theme && (
                      <div className={bem.e("prize-text")}>
                        {get(prize, "selected_itemized_prices.0.description")}
                      </div>
                    )}
                  </td>
                  <td>
                    <span className={bem.e("prize-text")}>
                      {get(fulfillment, "label")}
                    </span>
                  </td>
                  <td className="text-center">
                    <span className={bem.e("prize-amount")}>
                      {prize.per_play}
                    </span>
                  </td>
                  <td>
                    <span className={bem.e("prize-text")}>
                      ${prize.total_value}
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
