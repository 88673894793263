import AddProcessPage from "./view";
import { get } from "lodash";
import { connect } from "react-redux";
import { unlockProcess } from "store/actions/processes";
import { compose, withHandlers } from "recompose";
const View = compose(
  connect(
    (state, props) => {
      const { match } = props;
      return {
        process: state.processes.process,
        user: state.auth.user,
        template_id: get(match, "params.template_id"),
        process_id: get(match, "params.process_id"),
        activeFilter: get(state, "processes.activeFilter", ""),
      };
    },
    { unlockProcess }
  ),
  withHandlers({
    onUnlockProcess: ({
      unlockProcess,
      process_id,
      process,
      user,
      activeFilter,
    }) => () => {
      if (
        (process_id &&
          get(process, "data.fields.being_edited_by.value.id", false) ===
            user.id) ||
        get(process, "data.fields.continuity_being_done_by.value.id", false) ===
          user.id ||
        get(
          process,
          "data.fields.team_assignments_being_done_by.value.id",
          false
        ) === user.id
      ) {
        const source = activeFilter;
        unlockProcess({
          id: process_id,
          source,
        });
      }
    },
  })
)(AddProcessPage);
export default View;
