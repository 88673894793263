import React from "react";
import bn from "utils/bemnames";
import { FormattedMessage } from "react-intl";
import { FaSpinner } from "react-icons/lib/fa";
import { LoadMoreIcon } from "components/CustomIcons";
import classNames from "classnames";
const bem = bn.create("jock-console");
export default function LoadMore(props) {
  const { onClick, isLoading } = props;
  if (isLoading)
    return (
      <div
        className={classNames(bem.e("load-more"), bem.e("load-more-loading"))}
      >
        <FaSpinner size="20" className={"cr-icon-spin"} />
      </div>
    );
  return (
    <div className={bem.e("load-more")} onClick={onClick}>
      <div className={bem.e("load-more-button")}>
        <FormattedMessage id="jock console > load more" />
        <span className={bem.e("load-more-icon")}>
          <LoadMoreIcon />
        </span>
      </div>
    </div>
  );
}
