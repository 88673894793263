import * as React from "react";
import bn from "utils/bemnames";
import { EyeIcon, DownIcon, UpIcon, CopyIcon } from "components/CustomIcons";
import classNames from "classnames";
import ToggleSwitch from "components/ToggleSwitch";
import Checkbox from "components/Checkbox";
import TemplateForm, { getCategories } from "./TemplateForm";
import TemplatePreviewModal from "./TemplatePreviewModal";
import { FormattedMessage } from "react-intl";

const bem = bn.create("system-templates");

const TemplateItem = (props) => {
  const refPrevewModal = React.useRef(null);
  const { data } = props;
  const [formValues, setFormValues] = React.useState(props.data);
  const isOpen = props.openSystemTemplate === data.id;
  function getSubtitle(data) {
    if (!data.category) return null;
    const categories = getCategories({ intl: props.intl });
    const category = categories.find((i) => i.value === data.category);
    if (!category) return null;
    return <span className={bem.e("sub-title")}>{` - ${category.label}`}</span>;
  }
  const isWinnerNotification = data.type === "winner_notification";
  const isShowPrimary = !isWinnerNotification;
  const isShowDuplicate = !isWinnerNotification;
  return (
    <div
      className={classNames("templateItem", {
        [bem.e("template-inactive")]: data.published === 0,
      })}
    >
      <div
        className={classNames("template-wrapper", {
          "template-wrapper-edit": isOpen,
        })}
      >
        <div className="templateItemContainer">
          <div
            className="template-name"
            onClick={() => {
              props.setOpenSystemTemplate(isOpen ? null : data.id);
            }}
          >
            {data.name}
            {getSubtitle(data)}
          </div>
          <div className="actions">
            {isShowPrimary && (
              <Checkbox
                checked={data.is_primary === 0 ? false : true}
                onChange={() => {
                  if (props.shouldBlockNavigation) {
                    props.setIsOpenConfirmationModal(true);
                  } else {
                    props.onSetPrimary(data);
                  }
                }}
                text={<FormattedMessage id="system templates > primary" />}
              />
            )}

            <div
              className={bem.e("btn-preview")}
              onClick={() => {
                refPrevewModal.current.toggle({
                  ...formValues,
                  currentStation: props.currentStation,
                });
              }}
            >
              <EyeIcon color="#795AFA" width={24} height={16} />
            </div>

            {isShowDuplicate && (
              <div
                className={classNames(bem.e("btn-clone"), {})}
                onClick={() => {
                  if (props.shouldBlockNavigation) {
                    props.setIsOpenConfirmationModal(true);
                  } else {
                    props.onDuplicate(data);
                  }
                }}
              >
                <CopyIcon color={"#795AFA"} width={20.55} height={20} />
              </div>
            )}

            <button
              className={bem.e("button-down")}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (props.shouldBlockNavigation) {
                  props.setIsOpenConfirmationModal(true);
                } else {
                  props.setOpenSystemTemplate(isOpen ? null : data.id);
                }
              }}
            >
              {isOpen ? (
                <UpIcon
                  className={bem.e("button-up-icon")}
                  color="#795AFA"
                  width={24}
                  height={15.46}
                />
              ) : (
                <DownIcon
                  className={bem.e("button-down-icon")}
                  color="#795AFA"
                  width={24}
                  height={15.46}
                />
              )}
            </button>
          </div>
        </div>

        {isOpen && (
          <div className="template-edit-content">
            <TemplateForm
              action="update"
              currentStation={props.currentStation}
              stations={props.stations}
              type={data.type}
              onSubmit={props.onSubmit}
              systemTemplate={data}
              onDelete={props.onDelete}
              onClose={() => {
                props.setOpenSystemTemplate(null);
              }}
              setFormValues={setFormValues}
              shouldBlockNavigation={props.shouldBlockNavigation}
              setShouldBlockNavigation={props.setShouldBlockNavigation}
              onCheckDuplicateTemplate={props.onCheckDuplicateTemplate}
            />
          </div>
        )}
      </div>

      <div className="toogle-button">
        <ToggleSwitch
          leftComponent={null}
          rightComponent={null}
          name="is_hidden"
          switchProps={{
            checked: data.published === 0 ? false : true,
            onChange: () => {
              props.onPublish(data);
            },
            offColor: "#C2D4E0",
            onColor: "#795AFA",
            uncheckedIcon: false,
            checkedIcon: false,
            boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.282)",
            height: 26.44,
            width: 48.48,
            handleDiameter: 22.92,
          }}
        />
      </div>

      <TemplatePreviewModal ref={refPrevewModal} />
    </div>
  );
};
export default TemplateItem;
