import React from "react";
import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";
import { get } from "lodash";
import Winner from "./view";
import { injectIntl } from "react-intl";
import { withState } from "recompose";
import { updateWinner } from "store/actions/winners";
import { MdImportantDevices } from "react-icons/lib/md";
import ToastManager from "components/ToastManager";
import URL from "utils/urls";
import history from "components/History";
const ParticipantForm = compose(
  connect(
    (state, props) => {
      return {
        winner: get(state, "winners.winner.data", {}),
        isLoading: get(state, "winners.updateWinner.loading", false),
        serverCountry: get(state, "auth.info.server_country", "US"),
        dateFormatByServer: get(
          state,
          "servers.currentServer.data.date_format",
          "MM/DD/YYYY"
        ),
        ...props,
      };
    },
    { updateWinner }
  ),
  withState("activeTab", "setActiveTab", "1"),
  withState("shouldBlockNavigation", "setShouldBlockNavigation", false),
  withHandlers({
    onView: ({ winner }) => () => {
      const params = {
        winner_id: winner.id,
        action: "view",
      };
      history.push(URL.WINNER(params));
    },
  }),
  withHandlers({
    onSubmit: ({ updateWinner, intl, onView }) => (values) => {
      updateWinner(values, () => {
        ToastManager.show({
          title: <MdImportantDevices />,
          message: intl.formatMessage({
            id: "winners > update successfully",
          }),
          level: "success",
        });
        onView();
      });
    },
  })
)(Winner);
export default injectIntl(ParticipantForm);
