import * as Const from "../../utils/constants";
import Api from "../../utils/api";

export const sendMail = (formData) => async() => {
  try {
    let { status } = await Api.doCall(Const.SEND_MAIL(), "POST", formData);
    if (status === 200) {
        return {
          status: true,
          message: "Mail has been sent"
        }
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};