import React from "react";
import { get } from "lodash";
import PropTypes from "prop-types";
import bn from "utils/bemnames";
import ServerForm from "../ServerForm";

export const TAB_INFO = "info";

const bem = bn.create("admin-tab-detail");

const BroadcastingDetails = ({ back, lists, isView }) => {
  let server;
  if (lists) {
    if (Object.keys(lists).length === 1) {
      server = lists[Object.keys(lists)[0]];
    }
  }
  return (
    <div className={bem.b()}>
      <ServerForm
        id={get(server, "id")}
        isView={isView}
        back={back}
      />
    </div>
  );
};
BroadcastingDetails.defaultProps = {
  isView: true
};
BroadcastingDetails.propTypes = {
  isView: PropTypes.bool.isRequired
};
export default BroadcastingDetails;
