import * as React from "react";
import classnames from "classnames";
import bn from "utils/bemnames";
import { Button } from "reactstrap";
import { PinLargeIcon } from "components/CustomIcons";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
const bem = bn.create("confirmation-media");
function Confirmation(props) {
  const { visible, isHelperTooltip, isShowCancel } = props;
  if (!visible) return null;
  return (
    <div className={bem.b()}>
      <div
        className={classNames(bem.e("position"), {
          [bem.e("tooltip-position")]: isHelperTooltip,
        })}
      >
        <div
          className={classNames(bem.e("container"), {
            [bem.e("tooltip-container")]: isHelperTooltip,
          })}
        >
          <h2 className={bem.e("title")}>
            <div className={bem.e("modal-pin-icon")}>
              <PinLargeIcon width={33} height={40} color={"#829FB1"} />
            </div>
            <span>
              {isHelperTooltip ? (
                <FormattedMessage id="process > click this icon when you want to drop a pin and give specific feedback on any artwork" />
              ) : (
                <FormattedMessage id="process > would you like to drop a pin and give specific feedback on this artwork?" />
              )}
            </span>
          </h2>
          <div className={bem.e("buttons")}>
            <Button
              className={classnames(
                "btn-blue btn-block",
                bem.e("confirm-button")
              )}
              onClick={props.onConfirm}
            >
              {isHelperTooltip ? (
                <FormattedMessage id="confirm modal > button ok" />
              ) : (
                <span>
                  <FormattedMessage id="confirm modal > button yes" />!
                </span>
              )}
            </Button>
            {isShowCancel && (
              <Button
                className={classnames(
                  "btn-outline-blue btn-block",
                  bem.e("cancel-button")
                )}
                onClick={props.onCancel}
              >
                <FormattedMessage id="confirm modal > button no" />
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

Confirmation.defaultProps = {
  onToggle: () => {},
  onCancel: () => {},
  isHelperTooltip: false,
  isShowCancel: true,
};

export default Confirmation;
