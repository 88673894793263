import React from "react";
import { Button, Table } from "reactstrap";
import bn from "utils/bemnames";
import { ShadowBox, SectionTitle, SectionFields } from "components/Elements";
import { FaPlus } from "react-icons/lib/fa";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import NotificationProgress from "../NotificationProgress";
import NotificationTable from "../NotificationTable";
import EmailTemplateModal from "../EmailTemplateModal";
import PreviewEmailTemplateModal from "../PreviewEmailTemplateModal";
import ConfirmationModal from "components/ConfirmationModal";
import { get, map } from "lodash";
const bem = bn.create("winner-notification");
const colors = {
  fulfillment_form: "#2B85FF",
  prize_release: "#A82AC0",
  require_w9: "#795AFA",
};
const WinnerNotification = (props) => {
  const { intl, winnersNotifications, onUploadedContestImage, process } = props;
  const stats = get(winnersNotifications, "stats", {});
  const notificationsData = get(winnersNotifications, "notifications", {});
  const notifications = map(
    Object.keys(stats).filter((key) => key !== "total_winners"),
    (key) => {
      const statsData = stats[key];
      return {
        label: intl.formatMessage({
          id: `process > promotion > contest > ${key}`,
        }),
        total: statsData.required,
        number: statsData.submitted,
        color: colors[key],
      };
    }
  );
  const contestImage = get(process, "data.fields.contest_image.value", "");
  return (
    <div className={bem.b()}>
      <ShadowBox className={bem.e("box-winner-notification")}>
        <SectionTitle className={bem.e("section-title")}>
          <FormattedMessage id="process > promotion > contest > winner notifications" />
        </SectionTitle>
        <SectionFields className={bem.e("section-fields")}>
          <Table className={bem.e("total-table")}>
            <thead>
              <tr>
                <th className={bem.e("column-winner")}>
                  <FormattedMessage id="process > promotion > contest > winners" />
                </th>
                <th className={bem.e("column-completed")}>
                  <FormattedMessage id="process > promotion > contest > completed" />
                </th>
                {/* <th className={bem.e("column-actions")}>
                  <FormattedMessage id="process > promotion > contest > actions" />
                </th> */}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className={bem.e("total-section")}>
                    <strong>
                      <FormattedMessage id="process > promotion > contest > total" />
                    </strong>
                    <span className={bem.e("total-number")}>
                      {get(stats, "total_winners", 0)}
                    </span>
                  </div>
                </td>
                <td>
                  <div className={bem.e("list-progress")}>
                    {notifications.map((item, index) => (
                      <NotificationProgress
                        title={item.label}
                        total={item.total}
                        number={item.number}
                        color={item.color}
                        key={index}
                        bem={bem}
                      />
                    ))}
                  </div>
                </td>
                {/* <td>
                  <Button
                    color="blue"
                    outline
                    className={classnames(
                      bem.e("btn-new-notification"),
                      "btn-radius"
                    )}
                    onClick={() => {
                      props.setSelectedEmailTemplate(null);
                      props.setIsOpenEmailTemplateModal(true);
                    }}
                  >
                    <FaPlus color="#795AFA" size={20} />
                    <FormattedMessage id="process > promotion > contest > new notification" />
                  </Button>
                </td> */}
              </tr>
            </tbody>
          </Table>
          <div className="bottom-actions-sticky">
            <div className="buttons">
              <Button
                color="blue"
                outline
                className={classnames(
                  bem.e("btn-new-notification"),
                  "btn-radius"
                )}
                onClick={() => {
                  props.setSelectedEmailTemplate(null);
                  props.setIsOpenEmailTemplateModal(true);
                }}
              >
                <FaPlus color="#795AFA" size={20} />
                <FormattedMessage id="process > promotion > contest > new notification" />
              </Button>
            </div>
          </div>
          <NotificationTable
            notificationsData={notificationsData}
            process={props.process}
            setSelectedEmailTemplate={props.setSelectedEmailTemplate}
            setIsOpenEmailTemplateModal={props.setIsOpenEmailTemplateModal}
            onPreview={(data) => {
              props.setSelectPreviewModal(data);
            }}
          />
        </SectionFields>
      </ShadowBox>
      <EmailTemplateModal
        isOpen={props.isOpenEmailTemplateModal}
        process={props.process}
        emailTemplate={props.selectedEmailTemplate}
        action={props.selectedEmailTemplate ? "update" : "create"}
        onClose={() => props.setIsOpenEmailTemplateModal(false)}
        shouldBlockNavigation={props.shouldBlockNavigation}
        setShouldBlockNavigation={props.setShouldBlockNavigation}
        setIsOpenUnsavedConfirmationModal={
          props.setIsOpenUnsavedConfirmationModal
        }
        station={props.station}
        onPreview={(data) => {
          props.setSelectPreviewModal(data);
        }}
        onUploadedContestImage={onUploadedContestImage}
      />
      <PreviewEmailTemplateModal
        isOpen={!!props.selectPreviewModal}
        emailTemplate={props.selectPreviewModal}
        station={props.station}
        onToggle={() => props.setSelectPreviewModal(null)}
        contestImage={contestImage}
      />
      <ConfirmationModal
        isOpen={props.isOpenUnsavedConfirmationModal}
        title={intl.formatMessage({
          id:
            "process > promotion > contest > you have unsaved changes save this notification",
        })}
        isCloseOutside={false}
        className={bem.e("confirmation-modal")}
        confirmTitle={intl.formatMessage({
          id: "process > promotion > contest > button yes",
        })}
        cancelTitle={intl.formatMessage({
          id: "process > promotion > contest > button cancel",
        })}
        onConfirm={() => {
          const buttonSubmit = document.getElementById(
            `btn-submit-${
              get(props, "emailTemplate.id", "")
                ? props.emailTemplate.id
                : "new"
            }`
          );
          // trigger click to submit the form
          if (buttonSubmit) {
            buttonSubmit.click();
          }
          props.setIsOpenUnsavedConfirmationModal(false);
        }}
        onToggle={() =>
          props.setIsOpenUnsavedConfirmationModal(
            !props.isOpenUnsavedConfirmationModal
          )
        }
        onCancel={() => {
          props.setShouldBlockNavigation(false);
          props.setIsOpenUnsavedConfirmationModal(false);
          props.setSelectedEmailTemplate(null);
          props.setIsOpenEmailTemplateModal(false);
        }}
      />
    </div>
  );
};
export default WinnerNotification;
