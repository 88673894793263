import React from "react";
import bn from "utils/bemnames";
import { FormattedMessage } from "react-intl";
import { FormGroup, Row, Col, Label } from "reactstrap";
import { Asterisk } from "components/Elements";
import TextInput from "components/TextInput";
import TextareaEvaluator from "components/TextareaEvaluator";
import { setScriptField } from "utils/helpers";
import { get } from "lodash";
import classNames from "classnames";
import WidgetFields from "../WidgetFields";
import moment from "moment";
import PropTypes from "prop-types";
import { formatDisplayDate } from "utils/helpers";
const bem = bn.create("jock-console-item");
export default function BulletinBoardReplacement(props) {
  const { value, isEdit, intl } = props;
  if (!isEdit) {
    return (
      <div className={bem.e("bulletin-board")}>
        <div className={bem.e("bulletin-board-dates")}>
          <Row>
            <Col>
              <FormGroup>
                <div className={bem.e("jock-console-view-field")}>
                  <Label>
                    <FormattedMessage id="jock console manager > start date" />
                  </Label>
                  <div className={bem.e("jock-console-view-field-value")}>
                    <div className={bem.e("jock-console-view-field-value")}>
                      {value.start_date ? (
                        formatDisplayDate(value.start_date)
                      ) : (
                        <FormattedMessage id="jock console manager > none" />
                      )}
                    </div>
                  </div>
                </div>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <div className={bem.e("jock-console-view-field")}>
                  <Label>
                    <FormattedMessage id="jock console manager > end date" />
                  </Label>
                  <div className={bem.e("jock-console-view-field-value")}>
                    <div className={bem.e("jock-console-view-field-value")}>
                      {value.end_date ? (
                        formatDisplayDate(value.end_date)
                      ) : (
                        <FormattedMessage id="jock console manager > none" />
                      )}
                    </div>
                  </div>
                </div>
              </FormGroup>
            </Col>
          </Row>
        </div>
        <div
          className={classNames(
            bem.e("form-padding"),
            bem.e("bulletin-board-replacement-content")
          )}
        >
          <WidgetFields {...props} isEdit={false} />
          <Row>
            <Col>
              <TextareaEvaluator
                text={setScriptField(value.text)}
                hideOrderLength={true}
                onEvaluatorCallback={() => {}}
                placeholder={intl.formatMessage({
                  id: "jock console manager > enter text",
                })}
                readOnly={true}
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
  const { touched, errors } = props;
  return (
    <div className={bem.e("bulletin-board")}>
      <div className={bem.e("bulletin-board-dates")}>
        <Row>
          <Col>
            <FormGroup>
              <TextInput
                label={
                  <span>
                    <FormattedMessage id="jock console manager > start date" />
                    <Asterisk>*</Asterisk>
                  </span>
                }
                type="date"
                min={new Date()}
                placeholder={intl.formatMessage({
                  id: "jock console manager > enter date",
                })}
                openToDate={new Date()} // open to today
                value={get(value, "start_date", "")}
                onChange={(startDate) => {
                  props.setFieldValue(
                    "fields.replacement.start_date",
                    startDate
                  );
                  if (get(value, "end_date")) {
                    const isAfterEndDate = moment(startDate).isAfter(
                      get(value, "end_date")
                    );
                    if (isAfterEndDate) {
                      props.setFieldValue("fields.replacement.end_date", "");
                    }
                  }
                }}
                error={
                  get(touched, "fields.replacement.start_date") &&
                  get(errors, "fields.replacement.start_date")
                }
              />
              {get(touched, "fields.replacement") &&
                typeof get(errors, "fields.replacement") === "string" && (
                  <span className="text-danger">
                    {errors.fields.replacement}
                  </span>
                )}
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <TextInput
                label={
                  <span>
                    <FormattedMessage id="jock console manager > end date" />
                    <Asterisk>*</Asterisk>
                  </span>
                }
                type="date"
                min={get(value, "start_date", new Date())}
                placeholder={intl.formatMessage({
                  id: "jock console manager > enter date",
                })}
                openToDate={new Date()} // open to today
                value={get(value, "end_date", "")}
                onChange={(value) => {
                  props.setFieldValue("fields.replacement.end_date", value);
                }}
                error={
                  get(touched, "fields.replacement.end_date") &&
                  get(errors, "fields.replacement.end_date")
                }
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
      <div
        className={classNames(
          bem.e("form-padding"),
          bem.e("bulletin-board-replacement-content")
        )}
      >
        <WidgetFields {...props} />
        <Row>
          <Col>
            <TextareaEvaluator
              text={setScriptField(value.text)}
              hideOrderLength={true}
              onEvaluatorCallback={(value) => {
                props.setFieldValue("fields.replacement.text", value);
              }}
              placeholder={intl.formatMessage({
                id: "jock console manager > enter text",
              })}
              isEditorOnly
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}
BulletinBoardReplacement.defaultProps = {
  isEdit: false,
};
BulletinBoardReplacement.propTypes = {
  isEdit: PropTypes.bool,
};
