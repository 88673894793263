import React from "react";
import { FormattedMessage } from "react-intl";
import { Input } from "reactstrap";
import bn from "utils/bemnames";
import classnames from "classnames";
import {
  ShadowBox,
  SectionTitle,
  SectionFields,
} from "components/Elements";
import { includes, map, isEqual, get } from 'lodash';
import { Formik } from "formik";
import * as Yup from "yup";
import TextInput from "components/TextInput";
import { Form, FormGroup, Button, Row, Col } from "reactstrap";
import { Asterisk } from "components/Elements";
const bem = bn.create("email-configurations");

const SmtpConfigurations = (props) => {
  const {
    intl,
    server,
    onValueChange,
    shouldBlockNavigation,
    setShouldBlockNavigation,
    saveSmtpConfig,
  } = props;

  let formikRef = React.createRef();

  const renderSmtpConfiguration = () => {

    const initialValues = {
      smtp_host: get(server, "smtp_host", "") || "",
      smtp_port: get(server, "smtp_port", "") || "",
    };

    return (
      <SectionFields className={classnames(bem.e("section-fields"), 'smtp-config')}>
        <div className={bem.e("table-content")}>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              smtp_host: Yup.string().required(
                intl.formatMessage({
                  id: "validate > smtp config > smtp_host is required"
                })
              ),
              smtp_port: Yup.string().required(
                intl.formatMessage({
                  id: "validate > smtp config > smtp_port is required"
                })
              ),
            })}
            ref={ref => (formikRef = ref)}
            onSubmit={saveSmtpConfig}
          >
            {formProps => {
              const errors = formProps.errors;
              const touched = formProps.touched;
              const values = formProps.values;
              const isChanged = !isEqual(initialValues, values);

              if (isChanged !== shouldBlockNavigation) {
                setTimeout(() => {
                  //setShouldBlockNavigation(isChanged);
                }, 20);
              }
    
              return (
                <Form id="server-form" onSubmit={formProps.handleSubmit} className={bem.e('server-edit-container')}>
                    <Row>
                      <Col xs={6} className={bem.e('mb-10')}>
                        <FormGroup className={bem.e('last-form-group')}>
                          <TextInput
                            label={
                              <span className={bem.e('label')}>
                                <FormattedMessage id="server > smtp config > host" />
                                <Asterisk>*</Asterisk>
                              </span>
                            }
                            type="text"
                            name="smtp_host"
                            placeholder={intl.formatMessage({
                              id: "server > smtp config > enter host"
                            })}
                            value={values.smtp_host}
                            onChange={formProps.handleChange}
                            onBlur={formProps.handleBlur}
                            error={touched.smtp_host && errors.smtp_host}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} className={bem.e('mb-10')}>
                        <FormGroup className={bem.e('last-form-group')}>
                          <TextInput
                            label={
                              <span className={bem.e('label')}>
                                <FormattedMessage id="server > smtp config > port" />
                                <Asterisk>*</Asterisk>
                              </span>
                            }
                            type="text"
                            name="smtp_port"
                            placeholder={intl.formatMessage({
                              id: "server > smtp config > enter port"
                            })}
                            value={values.smtp_port}
                            onChange={formProps.handleChange}
                            onBlur={formProps.handleBlur}
                            error={touched.smtp_port && errors.smtp_port}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} className={bem.e('mb-20')}>
                        <FormGroup>
                          <Button
                            color="blue"
                            type="submit"
                            className={classnames('btn', 'btn-radius', bem.e('save-button'))}
                          >
                            <FormattedMessage id="process > button save"/>
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                </Form>
              )
            }}
          </Formik>
        </div>
      </SectionFields>
    );
  }

  return (
    <React.Fragment>
      <ShadowBox className={classnames(bem.e("box-section"))}>
        <SectionTitle className={bem.e("section-title")}>
          <FormattedMessage id="server > smtp config > title" />
        </SectionTitle>
        {renderSmtpConfiguration()}
      </ShadowBox>
    </React.Fragment>
  )
};

export default SmtpConfigurations;
