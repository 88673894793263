import View from "./view";
import { get } from "lodash";
import { connect } from "react-redux";
import { compose, withState } from "recompose";
import { injectIntl } from "react-intl";
import { setSidebarActive } from "store/actions/auth";
export default injectIntl(
  compose(
    connect(
      (state) => {
        const authPrivileges = get(state, "auth.info.privileges", []);
        const privileges = Object.keys(authPrivileges).map((k) => ({
          id: k,
          name: authPrivileges[k],
        }));
        return {
          privileges,
          enable_production: get(state, "auth.info.enable_production", false),
          enable_promotion: get(state, "auth.info.enable_promotion", false),
          enable_digital: get(state, "auth.info.enable_digital", false),
          production_multimarket: get(
            state,
            "auth.info.production_multimarket",
            false
          ),
          user: state.auth.user,
          sidebarActive: state.auth.sidebar_active,
        };
      },
      { setSidebarActive }
    ),
    withState("isOpenPrivileges", "setIsOpenPrivileges", {})
  )(View)
);
