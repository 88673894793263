import React, { Component } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import { filter, get, isFunction, map, find } from "lodash";
import SimpleLineText from "components/FormBuilder/SimpleLineText";
import TextArea from "components/FormBuilder/TextArea";
import Dropdown from "components/FormBuilder/Dropdown";
import Checkbox from "components/FormBuilder/Checkbox";
import Gender from "components/FormBuilder/Gender";
import DateSelector from "components/FormBuilder/DateSelector";
import EmailAddress from "components/FormBuilder/EmailAddress";
import BasicFileUpload from "components/FormBuilder/BasicFileUpload";
import SectionHeader from "components/FormBuilder/SectionHeader";
import HomePhone from "components/FormBuilder/HomePhone";
import Radio from "components/FormBuilder/Radio";
import PhoneNumber from "components/FormBuilder/PhoneNumber";
import DateOfBirth from "components/FormBuilder/DateOfBirth";
import SocialSecurity from "components/FormBuilder/SocialSecurity";
import DriversLicense from "components/FormBuilder/DriversLicense";
import DriversLicensePhoto from "components/FormBuilder/DriversLicensePhoto";
import InstagramLink from "components/FormBuilder/InstagramLink";
import TikTokHandle from "components/FormBuilder/TikTokHandle";
import TwitterHandle from "components/FormBuilder/TwitterHandle";
import WinnerNote from "components/FormBuilder/WinnerNote";
import GroupHomeAddress from "components/FormBuilder/GroupHomeAddress";
import Ethnicity from "components/FormBuilder/Ethnicity";
import MaritalStatus from "components/FormBuilder/MaritalStatus";
import Education from "components/FormBuilder/Education";
import HouseholdIncome from "components/FormBuilder/HouseholdIncome";
import HomeOwnership from "components/FormBuilder/HomeOwnership";
import NumberOfChildren from "components/FormBuilder/NumberOfChildren";
import BusinessName from "components/FormBuilder/BusinessName";
import TaxId from "components/FormBuilder/TaxId";
import BusinessPhoneAndExtension from "components/FormBuilder/BusinessPhoneAndExtension";
import GroupBusinessAddress from "components/FormBuilder/GroupBusinessAddress";
import Separator from "components/FormBuilder/Separator";
import HelperText from "components/FormBuilder/HelperText";
import SingleAddress from "components/FormBuilder/SingleAddress";
import City from "components/FormBuilder/City";
import PostalCode from "components/FormBuilder/PostalCode";
import StateProvince from "components/FormBuilder/StateProvince";
import County from "components/FormBuilder/County";
import Country from "components/FormBuilder/Country";
import { arrayContainsArray } from "utils/helpers";

const SortableItem = SortableElement(({ children }) => <div>{children}</div>);
function getHiddenFieldBySetup(field, countries) {
  let isHidden = field.is_hidden || false;
  if (field.key === "home_country" || field.key === "business_country") {
    isHidden = arrayContainsArray(countries, ["US", "MX"]) ? false : true;
  }
  if (
    (field.key === "home_county" || field.key === "business_county") &&
    countries.length === 1 &&
    countries[0] === "CA"
  ) {
    // The County will disabled by default for Canada since they use Province.
    isHidden = true;
  }
  return isHidden;
}
const renderCustomFields = (fields, formProps) => {
  const validFields = fields;
  let newFields = validFields;
  const {
    setIsDirty,
    isSystemForm,
    isMasterListener,
    masterListenerFields,
    currentServer,
    values,
  } = formProps;

  if (fields.length === 0) return null;
  const serverCountries = [
    currentServer.country,
    ...currentServer.neighboring_countries,
  ];

  const fieldsEle = validFields.map((field, index) => {
    const { key } = field;
    let fieldProps = {
      key: index,
      value: field,
      formFields: filter(
        fields,
        (formFieldItem, formFieldIndex) => formFieldIndex !== index
      ),
      values,
      highlightFields: [],
      disabled: formProps.isView,
      allowDelete: !formProps.isView,
      isNew: true,
      isShowRequiredToggle: true,
      isMasterListener,
      isSystemForm,
      isShowOffText: false,
      errors:
        get(formProps, "touched.fields") &&
        get(formProps, `errors.fields.${index}`, {}),
      onChange: (value) => {
        newFields[index] = value;
        formProps.setFieldValue("fields", newFields);
        formProps.setFieldTouched("fields");
        setIsDirty(true);
      },
      onDelete: () => {
        if (isFunction(formProps.onDeleteField)) {
          formProps.onDeleteField(index);
          setIsDirty(true);
        }
      },
      onDuplicate: () => {
        formProps.setFieldValue("fields", [...newFields, field]);
        formProps.setFieldTouched("fields");
        setIsDirty(true);
      },
      onDeleteSubField: (fieldItem) => {
        if (isFunction(formProps.onDeleteField)) {
          formProps.onDeleteField({
            subfieldDeleting: fieldItem,
            deletingParentIndex: index,
          });
          setIsDirty(true);
        }
      },
    };
    let fieldElement;
    let isSortableDisabled = formProps.disabled;
    switch (key) {
      // we rendered this form on the form
      case "first_last_name":
        // isSortableDisabled = true;
        // fieldElement = (
        //   <FirstNameAndLastName {...fieldProps} isShowRequired={isSystemForm} />
        // );
        break;
      case "separator":
        fieldElement = <Separator {...fieldProps} />;
        break;
      case "helper_text":
        fieldElement = <HelperText {...fieldProps} />;
        break;
      case "single_line_text":
        fieldElement = <SimpleLineText {...fieldProps} />;
        break;
      case "textarea":
        fieldElement = <TextArea {...fieldProps} />;
        break;
      case "dropdown":
        // default 2 options
        if (field.is_new && get(fieldProps, "value.options.length", 0) < 2) {
          fieldProps.value = {
            ...field,
            options: [
              {
                value: "",
                label: "",
                rule: null,
              },
              {
                value: "",
                label: "",
                rule: null,
              },
            ],
          };
          // set default options to field
          setTimeout(() => {
            newFields[index] = fieldProps.value;
            formProps.setFieldValue("fields", newFields);
          }, 100);
        }
        fieldElement = (
          <Dropdown {...fieldProps} isAllowConditionLogic={false} />
        );
        break;
      case "checkbox":
        // default 2 options
        if (field.is_new && get(fieldProps, "value.options.length", 0) < 1) {
          fieldProps.value = {
            ...field,
            options: [
              {
                value: "",
                label: "",
                is_default: false,
                rule: null,
              },
              {
                value: "",
                label: "",
                is_default: false,
                rule: null,
              },
            ],
          };
          // set default options to field
          setTimeout(() => {
            newFields[index] = fieldProps.value;
            formProps.setFieldValue("fields", newFields);
          }, 100);
        }
        fieldElement = (
          <Checkbox {...fieldProps} isAllowConditionLogic={false} />
        );
        break;
      case "radio":
        // default 2 options
        if (field.is_new && get(fieldProps, "value.options.length", 0) < 1) {
          fieldProps.value = {
            ...field,
            options: [
              {
                value: "",
                label: "",
                is_default: false,
                rule: null,
              },
              {
                value: "",
                label: "",
                is_default: false,
                rule: null,
              },
            ],
          };
          // set default options to field
          setTimeout(() => {
            newFields[index] = fieldProps.value;
            formProps.setFieldValue("fields", newFields);
          }, 100);
        }
        fieldElement = <Radio {...fieldProps} isAllowConditionLogic={false} />;
        break;
      case "date_selector":
        fieldElement = <DateSelector {...fieldProps} />;
        break;
      case "email_address":
        fieldElement = <EmailAddress {...fieldProps} allowDelete={false} />;
        break;
      case "phone_number":
        fieldElement = <PhoneNumber {...fieldProps} allowDelete={false} />;
        break;
      case "basic_file_upload":
        if (field.is_new && get(fieldProps, "value.mimes.length", 0) === 0) {
          fieldProps.value = {
            ...field,
            mimes: [],
          };
        }
        fieldElement = <BasicFileUpload {...fieldProps} isAllowDuplicate />;
        break;
      case "section_header":
        fieldElement = <SectionHeader {...fieldProps} />;
        break;
      case "home_phone":
        fieldElement = <HomePhone {...fieldProps} />;
        break;
      case "gender":
        fieldProps = {
          ...fieldProps,
          isShowOtherCheckbox: !isSystemForm,
        };
        fieldElement = <Gender {...fieldProps} />;
        break;
      case "date_of_birth":
        fieldElement = (
          <DateOfBirth {...fieldProps} allowDelete={isSystemForm} />
        );
        break;
      case "social_security":
        fieldElement = (
          <SocialSecurity {...fieldProps} allowDelete={isSystemForm} />
        );
        break;
      case "drivers_license_number":
        fieldElement = (
          <DriversLicense {...fieldProps} allowDelete={isSystemForm} />
        );
        break;
      case "drivers_license_photo":
        fieldElement = (
          <DriversLicensePhoto {...fieldProps} allowDelete={isSystemForm} />
        );
        break;
      case "instagram_link":
        fieldElement = <InstagramLink {...fieldProps} />;
        break;
      case "tiktok_handle":
        fieldElement = <TikTokHandle {...fieldProps} />;
        break;
      case "twitter_handle":
        fieldElement = <TwitterHandle {...fieldProps} />;
        break;
      case "winner_note":
        fieldElement = <WinnerNote {...fieldProps} />;
        break;
      case "home_address_1":
        fieldElement = <SingleAddress {...fieldProps} />;
        break;
      case "home_address_2":
        fieldElement = <SingleAddress {...fieldProps} />;
        break;
      case "business_address_1":
        fieldElement = <SingleAddress {...fieldProps} />;
        break;
      case "business_address_2":
        fieldElement = <SingleAddress {...fieldProps} />;
        break;
      case "business_city":
        fieldElement = <City {...fieldProps} />;
        break;
      case "home_city":
        fieldElement = <City {...fieldProps} />;
        break;
      case "business_zip_postal_code":
        fieldElement = <PostalCode {...fieldProps} />;
        break;
      case "home_zip_postal_code":
        fieldElement = <PostalCode {...fieldProps} />;
        break;
      case "business_state_province":
        fieldElement = <StateProvince {...fieldProps} />;
        break;
      case "home_state_province":
        fieldElement = <StateProvince {...fieldProps} />;
        break;
      case "home_county":
        fieldElement = <County {...fieldProps} />;
        break;
      case "business_county":
        fieldElement = <County {...fieldProps} />;
        break;
      case "home_country":
        fieldElement = <Country {...fieldProps} />;
        break;
      case "business_country":
        fieldElement = <Country {...fieldProps} />;
        break;
      case "home_address":
        const masterListenerHomeAddress = find(
          masterListenerFields,
          (i) => i.key === "home_address"
        );
        fieldProps.value = {
          ...field,
          sub_fields: map(field.sub_fields, (item) => {
            const is_hidden = getHiddenFieldBySetup(item, serverCountries);
            return {
              ...item,
              is_hidden,
              show_hide_option: isMasterListener,
              show_delete_option: isSystemForm,
            };
            // for system form -> remove all hidden fields
          }).filter((item) => {
            // Any field that is hidden in a group (like Home Address group) should not appear in the Listener Record or in the same group in any of the child forms.
            if (masterListenerHomeAddress && isSystemForm) {
              const masterListenerField = find(
                masterListenerHomeAddress.sub_fields,
                (i) => i.key === item.key
              );
              if (masterListenerField && masterListenerField.is_hidden)
                return false;
            }

            return isSystemForm ? !item.is_hidden : true;
          }),
        };
        fieldElement = (
          <GroupHomeAddress
            {...fieldProps}
            serverCountries={serverCountries || []}
          />
        );
        break;
      case "business_address":
        const masterListenerBusinessAddress = find(
          masterListenerFields,
          (i) => i.key === "business_address"
        );
        fieldProps.value = {
          ...field,
          sub_fields: map(field.sub_fields, (item) => {
            let is_hidden = getHiddenFieldBySetup(item, serverCountries);
            return {
              ...item,
              is_hidden,
              show_hide_option: isMasterListener,
              show_delete_option: isSystemForm,
            };
            // for system form -> remove all hidden fields
          }).filter((item) => {
            // Any field that is hidden in a group (like Business Address group) should not appear in the Listener Record or in the same group in any of the child forms.
            if (masterListenerBusinessAddress && isSystemForm) {
              const masterListenerField = find(
                masterListenerBusinessAddress.sub_fields,
                (i) => i.key === item.key
              );
              if (masterListenerField && masterListenerField.is_hidden)
                return false;
            }
            return isSystemForm ? !item.is_hidden : true;
          }),
        };
        fieldElement = (
          <GroupBusinessAddress
            {...fieldProps}
            serverCountries={serverCountries || []}
          />
        );
        break;
      case "ethnicity":
        fieldElement = <Ethnicity {...fieldProps} />;
        break;
      case "marital_status":
        fieldElement = <MaritalStatus {...fieldProps} />;
        break;
      case "number_of_children":
        fieldElement = <NumberOfChildren {...fieldProps} />;
        break;
      case "education_level":
        fieldElement = <Education {...fieldProps} />;
        break;
      case "household_income":
        fieldElement = <HouseholdIncome {...fieldProps} />;
        break;
      case "home_ownership":
        fieldElement = <HomeOwnership {...fieldProps} />;
        break;
      case "business_name":
        fieldElement = <BusinessName {...fieldProps} />;
        break;
      case "business_phone_and_extension":
        fieldElement = <BusinessPhoneAndExtension {...fieldProps} />;
        break;
      case "tax_id_number":
        fieldElement = <TaxId {...fieldProps} />;
        break;
      default:
        break;
    }

    return (
      <SortableItem
        index={index}
        key={`field-item-${index}`}
        disabled={isSortableDisabled}
      >
        {fieldElement}
      </SortableItem>
    );
  });
  return fieldsEle;
};
const SortableList = SortableContainer(({ items, formProps }) => {
  return <div>{renderCustomFields(items, formProps)}</div>;
});
class SortableComponent extends Component {
  onSortEnd = ({ oldIndex, newIndex }) => {
    let newItems = arrayMove(this.props.items, oldIndex, newIndex);
    this.props.setItems(newItems);
    this.props.onSorted(newItems);
  };
  render() {
    return (
      <SortableList
        // useWindowAsScrollContainer={true}
        formProps={this.props.formProps}
        items={this.props.items}
        renderItem={this.props.renderItem}
        onSortEnd={this.onSortEnd}
        shouldCancelStart={(event) => {
          let isCanStart = false;
          const { path, composedPath } = event;
          const paths = path || (composedPath && event.composedPath()) || [];
          paths.forEach((item) => {
            if (
              item.classList &&
              item.classList.contains("cr-form-builder-fields__menu-icon")
            ) {
              isCanStart = true;
            }
          });
          return !isCanStart;
        }}
      />
    );
  }
}
export default SortableComponent;
