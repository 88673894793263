import React from "react";
import { FormattedMessage } from "react-intl";
import Avatar from "../Avatar";
// import { Link } from "react-router-dom";
// import { FaEye, FaPencil } from "react-icons/lib/fa";
import { Table, CardBody } from "reactstrap";
import { size, get } from "lodash";
import URL from "utils/urls";
import history from "components/History";

const Messages = props => {
  const constructMessage = payload => {
    let dismiss;
    switch (payload.message.m_type) {
      case "chat":
        return (
          <table
            key={payload.m_id}
            style={{ cursor: "pointer" }}
            onClick={() => {
              history.push(
                URL.VIEW_PROCESS({
                  template_id: payload.message.template,
                  process_id: payload.message.process_key
                })
              );
            }}
          >
            <tbody>
              <tr>
                <td>
                  {dismiss}
                  <Avatar src={payload.message.from.avatar_url} />
                  <br />
                  {payload.message.time}
                </td>
                <td>
                  <strong>{get(payload, "message.from.name", "")}</strong>
                  <span> wrote in </span>'{payload.message.order_title}'
                  {/* <span>
                  {` `}
                  <Button
                    className="btn-sm"
                    color="blue"
                    tag={Link}
                    to={URL.VIEW_PROCESS({
                      template_id: payload.message.template,
                      process_id: payload.message.process_key
                    })}>
                    <FaEye />
                  </Button>
                  {` `}
                  <Button
                    className="btn-sm"
                    color="blue"
                    tag={Link}
                    to={URL.UPDATE_PROCESS({
                      template_id: payload.message.template,
                      process_id: payload.message.process_key
                    })}>
                    <FaPencil />
                  </Button>
                  {` `}
                </span><br/> */}
                  {payload.message.chat}
                </td>
              </tr>
            </tbody>
          </table>
        );

      case "proc":
        return (
          <table
            key={payload.m_id}
            style={{ cursor: "pointer" }}
            onClick={() => {
              history.push(
                URL.VIEW_PROCESS({
                  template_id: payload.message.template,
                  process_id: payload.message.process_key
                })
              );
            }}
          >
            <tbody>
              <tr>
                <td>
                  <Avatar src={payload.message.from.avatar_url} />
                  <br />
                  {payload.message.time}
                </td>
                <td>
                  <strong>{get(payload, "message.from.name", "")}</strong>
                  <span> saved </span>'{payload.message.order_title}'
                  {/* <span>
                    {` `}
                    <Button
                      className="btn-sm"
                      color="blue"
                      tag={Link}
                      to={URL.VIEW_PROCESS({
                        template_id: payload.message.template,
                        process_id: payload.message.process_key
                      })}>
                      <FaEye />
                    </Button>
                    {` `}
                    <Button
                      className="btn-sm"
                      color="blue"
                      tag={Link}
                      to={URL.UPDATE_PROCESS({
                        template_id: payload.message.template,
                        process_id: payload.message.process_key
                      })}>
                      <FaPencil />
                    </Button>
                    {` `}
                  </span> */}
                  {payload.message.to_step &&
                    ", now in status '" +
                      payload.message.to_step.replace(/_/, " ", "gi") +
                      "'"}
                </td>
              </tr>
            </tbody>
          </table>
        );
      default:
        return <div />;
    }
  };

  let { messages } = props;
  messages = messages.filter(m => m.message.m_type === "proc");
  if (props.count) messages = messages.slice(0, props.count);
  return (
    <div>
      {props.title && <CardBody>{props.title}</CardBody>}
      {size(messages) > 0 ? (
        <Table>
          <thead>
            <tr>
              <th xs={1} />
              <th>
                <FormattedMessage id="messages > Message" />
              </th>
            </tr>
          </thead>
          <tbody>{messages.map(message => constructMessage(message))}</tbody>
        </Table>
      ) : (
        <FormattedMessage id="messages > empty messages" />
      )}
    </div>
  );
};

export default Messages;
