import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import PropTypes from "prop-types";
import { FaAngleLeft, FaAngleRight } from "react-icons/lib/fa";
import { createPortal } from "react-dom";

const DatePickerIcon = ({ onClick }) => (
  <span className="datePickerIcon" onClick={onClick}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#657894"
        d="M17 0v1.967H7V0H4.002v1.967h-1.5A2.488 2.488 0 000 4.427v16.726a2.488 2.488 0 002.5 2.459h19a2.487 2.487 0 002.5-2.46V4.428a2.488 2.488 0 00-2.5-2.46H20V0h-3zm4.5 21.153h-19V8.116h19v13.037z"
      ></path>
    </svg>
  </span>
);
const DatePickerComponent = ({
  field,
  onChange,
  idx,
  allowShowIcon,
  format,
  autoComplete = "off",
  ...rest
}) => {
  let refDatePicker = React.createRef();
  let datePickerProps = {};
  if (rest.min) {
    datePickerProps.minDate =
      typeof rest.min === "string" ? moment(rest.min).toDate() : rest.min;
  }
  if (rest.max) {
    datePickerProps.maxDate =
      typeof rest.max === "string" ? moment(rest.max).toDate() : rest.max;
  }
  if (rest.placeholder) {
    datePickerProps.placeholderText = rest.placeholder;
  }
  return (
    <div className="fullDate">
      <DatePicker
        selected={
          field && field.value && typeof field.value === "string"
            ? moment(field.value).toDate()
            : field.value
        }
        {...datePickerProps}
        autoComplete={autoComplete}
        onChange={(e) => {
          if (!e) {
            onChange("", idx);
            return;
          }
          const date = moment(e)
            .format(format)
            .toString();
          const fullYear = e.getFullYear();
          if (Number(fullYear) <= 9999) {
            onChange(date, idx);
          }
        }}
        popperModifiers={{
          preventOverflow: {
            enabled: true,
            escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
            boundariesElement: "viewport",
          },
        }}
        onMonthChange={() => {
          window.scrollBy(0, -1);
          window.scrollBy(0, 1);
        }}
        onKeyDown={() => {
          window.scrollBy(0, -1);
          window.scrollBy(0, 1);
        }}
        onCalendarOpen={() => {
          document.body.classList.add("date-picker-open");
        }}
        onCalendarClose={() => {
          document.body.classList.remove("date-picker-open");
        }}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className="datePickerHeader">
            <button
              className="datePickerButtons"
              onClick={(e) => {
                e.preventDefault();
                decreaseMonth();
              }}
              disabled={prevMonthButtonDisabled}
            >
              <FaAngleLeft />
            </button>
            <p className="datePickerTxt">{moment(date).format("MMMM YYYY")}</p>
            <button
              className="datePickerButtons"
              onClick={(e) => {
                e.preventDefault();
                increaseMonth();
              }}
              disabled={nextMonthButtonDisabled}
            >
              <FaAngleRight />
            </button>
          </div>
        )}
        ref={refDatePicker}
        popperContainer={({ children }) =>
          createPortal(children, document.body)
        }
        {...rest}
      />
      {allowShowIcon && (
        <DatePickerIcon
          onClick={() => {
            if (refDatePicker.current) {
              refDatePicker.current.deferFocusInput();
            }
          }}
        />
      )}
    </div>
  );
};
DatePickerComponent.propTypes = {
  format: PropTypes.string,
};
DatePickerComponent.defaultProps = {
  format: "YYYY-MM-DD",
};
export default DatePickerComponent;
