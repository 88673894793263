import {
  FETCH_CHANNELS_REQUEST,
  FETCH_CHANNELS_ERROR,
  FETCH_CHANNELS_SUCCESS,
  UPDATE_CHANNEL_ERROR,
  UPDATE_CHANNEL_REQUEST,
  UPDATE_CHANNEL_SUCCESS,
  FORGET_CHANNELS,
  ADD_CHANNEL_REQUEST,
  ADD_CHANNEL_SUCCESS,
  ADD_CHANNEL_ERROR,
  DELETE_CHANNEL_ERROR,
  DELETE_CHANNEL_SUCCESS,
  DELETE_CHANNEL_REQUEST,
  CLONE_CHANNEL_ERROR,
  CLONE_CHANNEL_REQUEST,
  CLONE_CHANNEL_SUCCESS,
  CANCEL_CHANNEL_ERROR,
  CANCEL_CHANNEL_REQUEST,
  CANCEL_CHANNEL_SUCCESS,
  UPDATE_CHANNELS_ERROR,
  UPDATE_CHANNELS_REQUEST,
  UPDATE_CHANNELS_SUCCESS,
  SET_ORDER_CHANNELS,
} from "../types/channels";
import { filter } from "lodash";
import { LOGOUT_USER } from "../types/auth";
import get from "lodash/get";
const initialState = {
  channels: {
    data: [],
    error: null,
    loading: false,
  },
  channel: {
    data: {},
    error: null,
    loading: null,
  },
  addChannel: {
    error: null,
    loading: false,
  },
  updateChannel: {
    error: null,
    loading: false,
  },
  deleteChannel: {
    error: null,
    loading: false,
  },
  cloneChannel: {
    error: null,
    loading: false,
  },
  cancelChannel: {
    error: null,
    loading: false,
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_CHANNELS_REQUEST: {
      return {
        ...state,
        channels: {
          ...state.channels,
          is_dirty: false,
          loading: true,
          error: null,
        },
      };
    }
    case FETCH_CHANNELS_SUCCESS: {
      return {
        ...state,
        channels: {
          data: action.payload,
          is_dirty: false,
          loading: false,
          error: null,
        },
      };
    }
    case FETCH_CHANNELS_ERROR: {
      return {
        ...state,
        channels: {
          ...state.channels,
          is_dirty: false,
          loading: false,
          error: action.payload.error,
        },
      };
    }
    // Update multiple channels Actions
    case UPDATE_CHANNELS_REQUEST: {
      return {
        ...state,
        updateChannel: {
          loading: true,
          error: null,
        },
      };
    }
    case UPDATE_CHANNELS_SUCCESS: {
      return {
        ...state,
        updateChannel: {
          loading: false,
          error: null,
        },
      };
    }
    case UPDATE_CHANNELS_ERROR: {
      return {
        ...state,
        updateChannel: {
          loading: false,
          error: action.payload,
        },
      };
    }
    // Update channel Actions
    case UPDATE_CHANNEL_REQUEST: {
      return {
        ...state,
        updateChannel: {
          loading: true,
          error: null,
        },
      };
    }
    case UPDATE_CHANNEL_SUCCESS: {
      return {
        ...state,
        updateChannel: {
          loading: false,
          error: null,
        },
      };
    }
    case UPDATE_CHANNEL_ERROR: {
      return {
        ...state,
        updateChannel: {
          loading: false,
          error: action.payload,
        },
      };
    }
    // Add channel Actions
    case ADD_CHANNEL_REQUEST: {
      return {
        ...state,
        addChanel: {
          loading: true,
          error: null,
        },
      };
    }
    case ADD_CHANNEL_SUCCESS: {
      return {
        ...state,
        channels: {
          data: {
            ...state.channels.data,
            data: [
              ...state.channels.data.data,
              {
                _id: action.payload.id,
                _source: action.payload,
              },
            ],
          },
          is_dirty: false,
          loading: false,
          error: null,
        },
        addChanel: {
          loading: false,
          error: null,
        },
      };
    }
    case ADD_CHANNEL_ERROR: {
      return {
        ...state,
        addChanel: {
          loading: false,
          error: action.payload,
        },
      };
    }
    // clone channel Actions
    case CLONE_CHANNEL_REQUEST: {
      return {
        ...state,
        cloneChannel: {
          loading: true,
          error: null,
        },
      };
    }
    case CLONE_CHANNEL_SUCCESS: {
      // append new clone channel after origin channel
      return {
        ...state,
        channels: {
          data: {
            ...state.channels.data,
            data: [
              ...state.channels.data.data,
              {
                ...action.payload.channel,
                _id: action.payload.channel._source.id,
              },
            ],
          },
        },
        cloneChannel: {
          loading: false,
          error: null,
        },
      };
    }
    case CLONE_CHANNEL_ERROR: {
      return {
        ...state,
        cloneChannel: {
          loading: false,
          error: action.payload,
        },
      };
    }
    case FORGET_CHANNELS: {
      return {
        ...state,
        channels: {
          ...state.channels,
          is_dirty: true,
        },
      };
    }
    // delete channel Actions
    case DELETE_CHANNEL_REQUEST: {
      return {
        ...state,
        deleteChanel: {
          loading: true,
          error: null,
        },
      };
    }
    case DELETE_CHANNEL_SUCCESS: {
      return {
        ...state,
        channels: {
          data: {
            ...state.channels.data,
            data: filter(
              state.channels.data.data,
              (item) => item._id !== action.payload.id
            ),
          },
          is_dirty: false,
          loading: false,
          error: null,
        },
        deleteChanel: {
          loading: false,
          error: null,
        },
      };
    }
    case DELETE_CHANNEL_ERROR: {
      return {
        ...state,
        deleteChanel: {
          loading: false,
          error: action.payload,
        },
      };
    }
    // cancel channel Actions
    case CANCEL_CHANNEL_REQUEST: {
      return {
        ...state,
        cancelChannel: {
          loading: true,
          error: null,
        },
      };
    }
    case CANCEL_CHANNEL_SUCCESS: {
      return {
        ...state,
        channels: {
          ...state.channels,
          data: {
            ...state.channels.data,
            data: get(state, "channels.data.data", []).map((item) => {
              if (item._id === action.payload._id)
                return {
                  ...item,
                  _source: action.payload._source,
                };
              return item;
            }),
          },
        },
        cancelChannel: {
          loading: false,
          error: null,
        },
      };
    }
    case CANCEL_CHANNEL_ERROR: {
      return {
        ...state,
        cancelChannel: {
          loading: false,
          error: action.payload,
        },
      };
    }
    case SET_ORDER_CHANNELS: {
      return {
        ...state,
        channels: {
          ...state.channels,
          data: {
            ...state.channels.data,
            data: action.payload,
          },
        },
      };
    }
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
}
