import React from "react";
import bn from "utils/bemnames";
import classNames from "classnames";
import ScheduleCalendar from "components/ScheduleComponents/Calendar";

const bem = bn.create("promotion-schedule");

export default function PromotionSchedule(props) {
  const {
    isRendered,
    filterProps,
    process,
    step,
    template,
    user,
    valueChanged,
    buttonClicked,
  } = props;
  const isView = props.mode === "view";
  const onValueChanged = (field, value) => {
    valueChanged(field, process, template, user, { value });
  };
  return (
    <div
      className={classNames(bem.b(), {
        [bem.m("edit")]: !isView,
        [bem.m("view")]: isView,
      })}
    >
      {/* waiting for set current date from process */}
      {isRendered && (
        <ScheduleCalendar
          isScheduleTab
          filterProps={{
            ...filterProps,
            process,
            step,
            template,
            user,
            onValueChanged,
            buttonClicked,
          }}
        />
      )}
    </div>
  );
}
