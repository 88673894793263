import { get, includes } from "lodash";
import { LOGOUT_USER } from "../types/auth";
import * as Types from "../types/resources";
import { pageSize } from "../../utils/config";

const initialState = {
  resources: {
    data: [],
    error: null,
    loading: false
  },
  resource: {
    data: {},
    error: null,
    loading: null
  },
  addResources: {
    is_added: false,
    data: null,
    error: null,
    loading: false
  },
  updateResources: {
    data: null,
    error: null,
    loading: false
  },
  deleteResources: {
    data: {},
    error: null,
    loading: false
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_RESOURCES_REQUEST: {
      return {
        ...state,
        resources: {
          ...state.resources,
          ["filter_" + action.payload.filter]: {
            data: state.resources["filter_" + action.payload.filter]
              ? state.resources["filter_" + action.payload.filter].data
              : null,
            pages: state.resources["filter_" + action.payload.filter]
              ? state.resources["filter_" + action.payload.filter].pages
              : null,
            page: state.resources["filter_" + action.payload.filter]
              ? state.resources["filter_" + action.payload.filter].page
              : 1,
            rpp: state.resources["filter_" + action.payload.filter]
              ? state.resources["filter_" + action.payload.filter].rpp
              : pageSize,
            category: state.resources["filter_" + action.payload.filter]
              ? state.resources["filter_" + action.payload.filter].category
              : "",
            error: null,
            loading: true
          }
        }
      };
    }
    case Types.FETCH_RESOURCES_SUCCESS: {
      return {
        ...state,
        resource: {
          data: get(action, "payload.data", {}),
          error: null,
          loading: null
          //   lastEditTab: [],
          //   activeTab: null,
        },
        resources: {
          ...state.resources,
          ["filter_" + action.payload.filter]: {
            ...action.payload,
            error: null,
            loading: false
          }
        }
      };
    }
    case Types.FETCH_RESOURCES_ERROR: {
      return {
        ...state,
        resources: {
          ...state.resources,
          ["filter_" + action.payload.filter]: {
            data: [],
            error: action.error,
            loading: false
          }
        }
      };
    }

    case Types.FORGET_RESOURCES: {
      const keys = get(action, "payload.keys");
      let new_resources = { ...state.resources };
      Object.keys(new_resources).map(k => {
        if (keys) {
          // only dirty with keys from payload. Avoid affect other list
          if (k.indexOf("filter_") === 0 && includes(keys, k)) {
            new_resources[k].is_dirty = true;
          }
        } else {
          if (k.indexOf("filter_") === 0) new_resources[k].is_dirty = true;
        }
        return true;
      });
      return {
        ...state,
        resources: new_resources
      };
    }

    case Types.INIT_NEW_RESOURCE:
      return {
        ...state,
        resource: action.payload
      };

    // Add Resource Actions
    case Types.ADD_RESOURCE_REQUEST: {
      return {
        ...state,
        addResources: {
          is_added: false,
          loading: true,
          error: null
        }
      };
    }
    case Types.ADD_RESOURCE_SUCCESS: {
      return {
        ...state,
        resource: {
          ...get(state, "resource"),
          data: {
            ...get(state, "resource.data"),
            data: get(state, "resource.data.data")
          }
        },
        addResources: {
          is_added: true,
          data: action.payload,
          loading: false,
          error: null
        }
      };
    }
    case Types.ADD_RESOURCE_ERROR: {
      return {
        ...state,
        addResources: {
          is_added: false,
          loading: false,
          error: action.payload
        }
      };
    }

    // Update Resources Actions
    case Types.UPDATE_RESOURCE_REQUEST: {
      return {
        ...state,
        updateResources: {
          is_updated: false,
          loading: true,
          error: null
        }
      };
    }
    case Types.UPDATE_RESOURCE_SUCCESS: {
      return {
        ...state,
        updateResources: {
          is_updated: true,
          data: action.payload,
          loading: false,
          error: null
        }
      };
    }
    case Types.UPDATE_RESOURCE_ERROR: {
      return {
        ...state,
        updateResources: {
          is_updated: false,
          loading: false,
          error: action.payload
        }
      };
    }

    // Particular Resource Actions
    case Types.FETCH_RESOURCE_REQUEST: {
      const existingResource = get(state, "resource");
      const { id } = action.payload;
      return {
        ...state,
        resource: {
          ...existingResource,
          ...(existingResource[id] || {}),
          loading: true,
          error: null
        }
      };
    }
    case Types.FETCH_RESOURCE_SUCCESS: {
      const existingResource = get(state, "resource");
      const { data } = action.payload;
      return {
        ...state,
        resource: {
          ...existingResource,
          loading: false,
          error: null,
          data
        }
      };
    }
    case Types.FETCH_RESOURCE_ERROR: {
      const existingResource = get(state, "resource");
      const { error } = action.payload;
      return {
        ...state,
        resource: {
          ...existingResource,
          loading: false,
          error
        }
      };
    }

    // delete resources Actions
    case Types.DELETE_RESOURCE_REQUEST: {
      return {
        ...state,
        updateResources: {
          is_updated: false,
          loading: true,
          error: null
        }
      };
    }
    case Types.DELETE_RESOURCE_SUCCESS: {
      return {
        ...state,
        resources: {
          ...state.resources,
          filter_resources_list: {
            ...state.resources.filter_resources_list,
            data: state.resources.filter_resources_list.data.filter(
              resource => resource._id !== action.payload
            )
          }
        },
        updateResources: {
          is_updated: true,
          data: action.payload,
          loading: false,
          error: null
        }
      };
    }
    case Types.DELETE_RESOURCE_ERROR: {
      return {
        ...state,
        updateResources: {
          is_updated: false,
          loading: false,
          error: action.payload
        }
      };
    }
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
}
