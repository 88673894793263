import React from "react";
import bn from "utils/bemnames";
import { BadgeRemoveIcon } from "components/CustomIcons";

const Badge = (props) => {
  const bem = bn.create("text-badge");

  const { text, onClick, readOnly } = props;

  return (
    <div className={bem.b()}>
      {!readOnly && (<div className={bem.e("close-icon")} onClick={onClick}>
        <BadgeRemoveIcon />
      </div>)}
      <div className={bem.e("text")}>{text}</div>
    </div>
  );
};

export default Badge;
