import React, { useState, useEffect, useRef } from 'react';
import bn from "utils/bemnames";
import { Row, Col } from 'reactstrap';
import classnames from 'classnames';
import { CheckboxIcon } from 'components/CustomIcons';
import { colorPickerColors } from 'utils/constants';


const ColorPicker = (props) => {

	const bem = bn.create('color-picker');

	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {

	  document.addEventListener("mousedown", handleClick);

	  return () => {
	    document.removeEventListener("mousedown", handleClick);
	  };
	}, []);


	const handleClick = e => {
	  if (node.current.contains(e.target)) {
	    setIsOpen(!isOpen)
	  }else{
	  	setIsOpen(false);
	  }
	};

	const {
		selectedColor,
		colors,
		onChange	
	} = props;

	const supportedColors = colors || colorPickerColors;

	const node = useRef();

	const renderColorList = () => {

		let span = supportedColors.length / 3;

		return supportedColors.map( color => {
			return (
				<Col xs={span}  
					key={color}
					className={classnames(bem.e('color'), 'p-0 m-0')}
					style={{backgroundColor: color, textAlign: 'center'}} 
					onClick={ () => {
						onChange(color);
						setIsOpen(false);
					}}
				>
					{color === selectedColor && (
						<CheckboxIcon 
	                    	color="#FFFFFF"
	                    	width="12"
	                    	height="11"
		                />
					)}
				</Col>
			);
		})
	}
	return (
		<div className={bem.b()} ref={node}>
			<div 
				className={bem.e('selected-color')} 
				style={{backgroundColor: selectedColor, borderColor: selectedColor}}
			></div>
			{isOpen && (<Row className={classnames(bem.e('color-list'), 'p-0 m-0')}>
				{renderColorList()}
			</Row>)}
		</div>

	);
}

export default ColorPicker;