import React from "react";
import bn from "utils/bemnames";
import { map } from "lodash";
import { FormattedMessage } from "react-intl";
// import { FaAngleDown, FaAngleUp } from "react-icons/lib/fa";
import StartIcon from "assets/img/tabs/Star.png";
import TeamIcon from "assets/img/tabs/Team.png";
import UnionIcon from "assets/img/tabs/Union.png";
import ContinuityIcon from "assets/img/tabs/Continuity.png";
import DubbingIcon from "assets/img/tabs/Dub.png";
import ApprovalIcon from "assets/img/tabs/Approval.png";
import RecordIcon from "assets/img/tabs/Record.png";
import ScriptIcon from "assets/img/tabs/Script.png";
import MediaIcon from "assets/img/tabs/tab-media.png";

const bem = bn.create("workflow-role");

const icons = {
  StartIcon,
  TeamIcon,
  UnionIcon,
  ContinuityIcon,
  DubbingIcon,
  ApprovalIcon,
  RecordIcon,
  ScriptIcon,
  MediaIcon
};

const WorkflowRole = props => {
  const { name, stepIcon } = props;
  let icon = (
    <img src={icons[icon]} className={bem.e("start-icon")} alt={name} />
  );
  if (stepIcon)
    icon = (
      <img src={icons[stepIcon]} className={bem.e("start-icon")} alt={name} />
    );
  else {
    switch (name) {
      case "Order Entry":
        icon = (
          <img src={StartIcon} className={bem.e("start-icon")} alt={name} />
        );
        break;
      case "Assign Production":
        icon = <img src={TeamIcon} className={bem.e("team-icon")} alt={name} />;
        break;
      case "Traffic":
        icon = (
          <img src={UnionIcon} className={bem.e("union-icon")} alt={name} />
        );
        break;
      case "Continuity":
        icon = (
          <img
            src={ContinuityIcon}
            className={bem.e("continuity-icon")}
            alt={name}
          />
        );
        break;
      case "Dubbing":
        icon = (
          <img src={DubbingIcon} className={bem.e("dub-icon")} alt={name} />
        );
        break;
      case "Approval":
        icon = (
          <img
            src={ApprovalIcon}
            className={bem.e("approval-icon")}
            alt={name}
          />
        );
        break;
      default:
        break;
    }
  }
  return (
    <div className={bem.b()}>
      <div className={bem.e("name-section")}>
        <span className={bem.e("icon")}>{icon}</span>
        <span className={bem.e("name")}>
          <FormattedMessage id={`process > ${name}`} />:
        </span>
      </div>
      <div className={bem.e("items")}>
        {map(props.roles, (item, index) => (
          <div key={index} className={bem.e("item")}>
            {item.name}
          </div>
        ))}
      </div>
    </div>
  );
};
export default WorkflowRole;
