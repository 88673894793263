import { compose, withHandlers } from "recompose";
import { get } from "lodash";
import SchedulingSimple from "./view";
import { connect } from "react-redux";
const Enhanced = compose(
  connect((state) => {
    const clocksData = get(state, "programmings.clocksData.data", {});
    return {
      auth: state.auth,
      shows: get(clocksData, "shows", []),
      dayparts: get(clocksData, "dayparts", []),
      daypartsHours: get(clocksData, "dayparts_hours", []),
      showsHours: get(clocksData, "shows_hours", []),
      dateFormatByServer: get(
        state,
        "servers.currentServer.data.date_format",
        "MM/DD/YYYY"
      ),
    };
  }, {}),
  withHandlers({
    checkIsInValidSchedulingCheck: ({ process }) => () => {
      const stationKey = get(
        process,
        "data.fields.order_stations_array.value.0.key"
      );
      const orderTitle = get(process, "data.fields.order_title.value");
      const selectedBy = get(process, "data.fields.selected_by.value");
      const typeId = get(process, "data.fields.type_id.value");
      const startDate = get(process, "data.fields.order_start_date.value");
      const endDate = get(process, "data.fields.order_end_date.value");
      const daysOfWeek = get(process, "data.fields.days_of_week.value");
      const schedulingType = get(process, "data.fields.scheduling_type.value");
      if (schedulingType === "targeted") {
        const per = get(process, "data.fields.per.value");
        const mentions = get(process, "data.fields.mentions.value");
        const selectedItems = get(
          process,
          "data.fields.selected_items.value",
          []
        ).map((item) => item.value);
        return (
          // !processKey ||
          !orderTitle ||
          !selectedBy ||
          !stationKey ||
          !typeId ||
          !startDate ||
          !endDate ||
          !daysOfWeek ||
          !schedulingType ||
          !per ||
          !mentions ||
          selectedItems.length === 0
        );
      }
      return (
        !orderTitle ||
        !stationKey ||
        !typeId ||
        !startDate ||
        !endDate ||
        !daysOfWeek ||
        !schedulingType
      );
    },
  })
)(SchedulingSimple);

export default Enhanced;
