import React from "react";
import { Row, Col, Label } from "reactstrap";
import classnames from "classnames";
import {
  find,
  filter,
  forOwn,
  get,
  isEmpty,
  keyBy,
  sortBy,
  map,
  isEqual,
} from "lodash";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { Asterisk } from "components/Elements";
import bn from "utils/bemnames";
import {
  TrashListIcon,
  LockOpenIcon,
  LockClosedIcon,
} from "components/CustomIcons";
import Dropdown from "components/Dropdown";
import InputTime from "components/InputTime";
const bem = bn.create("timeslot-line-items");

const getSlotIds = (_slot) => map(_slot.label_ids, (i) => i.value);
function getSlotsWithSameLabel(slots, slot) {
  const slotsWithSameLabel = filter(slots, (_slot) => {
    return (
      !_slot.deleted &&
      isEqual(getSlotIds(_slot).sort(), getSlotIds(slot).sort())
    );
  });
  return slotsWithSameLabel;
}
const Timeslot = (props) => {
  const {
    intl,
    firstRow,
    id,
    slots,
    onChange,
    slotDefinitions,
    errors,
  } = props;

  const slot = find(
    slots,
    (slot) =>
      (typeof slot.id === "string" && slot.id === id) ||
      parseInt(slot.id) === parseInt(id)
  );

  const dateTimeFormat = "YYYY-MM-DD HH:mm:ss";

  const slotsWithSameLabel = getSlotsWithSameLabel(slots, slot);
  const activeSlotsWithSameLabel = filter(
    slotsWithSameLabel,
    (_slot) => !_slot.deleted
  );
  const priorityOptions = Array.from({ length: slotsWithSameLabel.length }).map(
    (_, index) => index + 1
  );
  const sortByTimeSet = (_slot) => !_slot.is_time_set;
  const sortOptions = [sortByTimeSet, "start_time"];

  const getUpdatedSlots = (newSlots) => {
    let itemsWithPriority = [];

    forOwn(newSlots, (_slot) => {
      const slotsWithSameLabel = getSlotsWithSameLabel(slots, _slot);
      const sortedSame = sortBy(slotsWithSameLabel, [
        "deleted",
        ...sortOptions,
      ]).map((_slot, index1) => {
        // Check if slot have priority or assigned index + 1
        const currentPriority =
          get(_slot, "priority.value") || parseInt(index1) + 1;
        if (_slot.deleted) {
          return {
            ..._slot,
            priority: {
              label: "",
              value: "",
            },
          };
        }
        return {
          ..._slot,
          priority: {
            label: currentPriority,
            value: currentPriority,
          },
        };
      });
      const prioritySlot = find(sortedSame, (item) => item.id === _slot.id);
      const newSlot = {
        ..._slot,
        priority: prioritySlot
          ? prioritySlot.priority
          : {
              value: slotsWithSameLabel.length + 1,
              label: slotsWithSameLabel.length + 1,
            },
      };
      itemsWithPriority.push(newSlot);
    });
    const sortedSlots = sortBy(itemsWithPriority, sortOptions);
    return keyBy(sortedSlots, "id");
  };
  const slotDefinitionsOptions = slotDefinitions.map((slotDefinition) => ({
    label: slotDefinition.name,
    value: slotDefinition.id,
    color: slotDefinition.color,
  }));

  let countSlot = 0;
  for (const p in slots) {
    if (slots[p].deleted === false) countSlot++;
  }
  return (
    <div
      className={classnames(
        bem.b(),
        { special: !firstRow },
        { error: !isEmpty(errors) }
      )}
    >
      <Row className={classnames(bem.e("slot-row"), "mr-0")}>
        <Col xs={3}>
          <InputTime
            id="startTime"
            time={get(slot, "start_date")}
            emptyMinute={!get(slot, "is_time_set", false)}
            onChange={(startDateTime) => {
              if (startDateTime) {
                let minutes =
                  startDateTime.get("hours") * 60 +
                  startDateTime.get("minutes");
                onChange({
                  ...slots,
                  [id]: {
                    ...slot,
                    start_time: minutes,
                    start_date: startDateTime.format(dateTimeFormat),
                    is_time_set: true,
                  },
                });
              } else {
                onChange({
                  ...slots,
                  [id]: {
                    ...slot,
                    start_time: startDateTime,
                    is_time_set: false,
                  },
                });
              }
            }}
            hourLabel={
              firstRow ? (
                <>
                  {intl.formatMessage({
                    id: "clock creator > hr",
                  })}
                  <Asterisk>*</Asterisk>
                </>
              ) : (
                ""
              )
            }
            minLabel={
              firstRow ? (
                <>
                  {intl.formatMessage({
                    id: "clock creator > min",
                  })}
                  <Asterisk>*</Asterisk>
                </>
              ) : (
                ""
              )
            }
            hourReadOnly={true}
            amPmReadOnly={true}
            useWrapper={true}
            noPlaceHolder={true}
            error={errors && errors.is_time_set}
          />
        </Col>

        <Col xs={6}>
          {firstRow && (
            <Label>
              <FormattedMessage id="clock creator > hour block label" />
              <Asterisk>*</Asterisk>
            </Label>
          )}
          <div className={bem.e("dd-icon-wrapper")}>
            <div
              className="color-indicator"
              style={
                get(slot, "slot_definition.color")
                  ? { backgroundColor: get(slot, "slot_definition.color") }
                  : {}
              }
            ></div>
            <div className={bem.e("dd")}>
              <Dropdown
                name="label_ids"
                placeholder={intl.formatMessage({
                  id: "clock creator > select",
                })}
                onChange={(selectedOptions) => {
                  const newSlot = { ...slot, label_ids: selectedOptions };
                  const newSlots = { ...slots, [slot.id]: newSlot };
                  onChange(getUpdatedSlots(newSlots));
                }}
                isMulti
                value={get(slot, "label_ids", [])}
                options={slotDefinitionsOptions}
                error={errors && errors.label_ids}
              />
            </div>
            <div
              className={classnames(bem.e("icon"))}
              onClick={() => {
                onChange({ ...slots, [id]: { ...slot, locked: !slot.locked } });
              }}
            >
              {get(slot, "locked", false) ? (
                <LockClosedIcon />
              ) : (
                <LockOpenIcon />
              )}
            </div>
          </div>
        </Col>
        <Col xs={3}>
          {firstRow && (
            <Label>
              <FormattedMessage id="clock creator > hour block priority" />
            </Label>
          )}
          <div className={bem.e("dd-icon-wrapper")}>
            <div className={bem.e("dd")}>
              {activeSlotsWithSameLabel.length > 1 && (
                <Dropdown
                  name="priority"
                  onChange={(selectedOption) => {
                    onChange({
                      ...slots,
                      [id]: { ...slot, priority: selectedOption },
                    });
                  }}
                  value={get(slot, "priority")}
                  options={priorityOptions.map((option) => ({
                    label: option,
                    value: option,
                  }))}
                  error={errors && errors.priority}
                />
              )}
            </div>
            {countSlot > 1 && (
              <div
                className={classnames(bem.e("icon"), "ml-auto")}
                onClick={() => {
                  if (filter(slots, (slot) => !slot.deleted).length === 1) {
                    let startDate = moment(get(slot, "start_date", null)).set({
                      minutes: 0,
                      seconds: 0,
                      millisecond: 0,
                    });

                    onChange({
                      ...slots,
                      [id]: {
                        ...slot,
                        start_date: startDate.format(dateTimeFormat),
                        start_time: startDate.get("hours") * 60,
                        is_time_set: false,
                        slot_definition: null,
                        locked: false,
                        priority: null,
                      },
                    });
                  } else {
                    const newSlots = {
                      ...slots,
                      [id]: { ...slot, deleted: true },
                    };
                    onChange(getUpdatedSlots(newSlots));
                  }
                }}
              >
                <TrashListIcon width={15} height={18} />
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Timeslot;
