import React, { useRef } from "react";
import { Formik } from "formik";
import classnames from "classnames";
import ToggleSwitch from "components/ToggleSwitch";
import * as Yup from "yup";
import LocationModal from "components/LocationModal";
import ToastManager from "components/ToastManager";
import RadioButtons from "components/RadioButtons";
import ButtonAdd from "components/ButtonAdd";
import { DownIcon } from "components/CustomIcons";
import { ChevronWrapper } from "components/Elements";
import Spinner from "components/Spinner";
import SelectAudience from "components/SelectAudience";
import ConfirmationModal from "components/ConfirmationModal";
import {
  get,
  keys,
  isEqual,
  isString,
  map,
  filter,
  omit,
  sum,
  sumBy,
  forOwn,
  find,
  split,
  includes,
  last,
  first,
  forEach,
} from "lodash";
import TextInput from "components/TextInput";
import { FormattedMessage } from "react-intl";
import { Asterisk } from "components/Elements";
import Dropdown from "components/Dropdown";
import Checkbox from "components/Checkbox";
import TextInputFormatted from "components/TextInputFormatted";
import {
  Row,
  Col,
  Button,
  Label,
  Form,
  FormGroup,
  Nav,
  NavItem,
  NavLink as BSNavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { categoryList, fulfillmentList } from "utils/config";
import { PointerArrow, TrashSmallIcon } from "components/CustomIcons";
import BlockingComponent from "components/BlockingComponent";
import AllocationTable from "./components/AllocationTable";
import FulfillDigital from "./components/FulfillDigital";
import FulfillLocation from "./components/FulfillLocation";
import FulfillMailOut from "./components/FulfillMailOut";
import PrizeFormView from "./components/PrizeFormView";
import { genRandomString } from "utils/helpers";
import bn from "utils/bemnames";
const bem = bn.create("prize-form");

const prizeFormTabs = {
  audience: {
    tabKey: "audience",
    tabFields: ["stations"],
  },
  "item-details": {
    tabKey: "item-details",
    tabFields: [
      "category",
      "title",
      "itemize",
      "quantity",
      "allocation",
      "unit_value",
      "total_cash",
      "max_per_winner",
    ],
  },
  itemize: {
    tabKey: "itemize",
    tabFields: ["itemized_prices"],
  },
  allotment: {
    tabKey: "allotment",
    tabFields: ["stations_itemized_quantity", "allocated", "stations_quantity"],
  },
  fulfillment: {
    tabKey: "fulfillment",
    tabFields: ["fulfillment", "expiration_date"],
  },
};

const emptyItemizedPrice = {
  description: "",
  quantity: "",
  value: "",
  id: genRandomString(12),
};

// Itemized - this should only be enabled for Tickets, Prize, and Station Merchandise
const checkCanItemize = (category) =>
  ["tickets", "prize", "station_merchandise"].includes(category);
export const getItemizedQuantityTotal = (items) => {
  let total = 0;
  forEach(items, (item) => {
    total += sumBy(item.allotment, (i) =>
      i.quantity ? Number(i.quantity) : 0
    );
  });
  return total;
};
const getButtonText = ({ id, isLastStep, isSplitForm }) => {
  if (isSplitForm) {
    return (
      <FormattedMessage
        id={
          isLastStep
            ? id
              ? "prizes > button submit"
              : "prizes > button save"
            : "prizes > button next"
        }
      />
    );
  }

  return (
    <FormattedMessage
      id={id ? "prizes > button submit" : "prizes > button save"}
    />
  );
};
//stations_itemized_quantity
function generateStationsItemizedQuantity(values) {
  const stationsItemizedQuantity = map(values.stations, (station) => ({
    station,
    allotment: map(values.itemized_prices, (item) => ({
      item_id: item.id,
      quantity: "",
      available: "",
    })),
  }));
  return stationsItemizedQuantity;
}
const PrizeForm = ({
  prize,
  setShouldBlockNavigation,
  shouldBlockNavigation,
  intl,
  locations,
  isModal,
  auth,
  isLoading,
  checkCanItemize,
  bem,
  isSplitForm,
  activeTab,
  setActiveTab,
  isAllowDeleteStation,
  setIsAllowDeleteStation,
  ...rest
}) => {
  const formikRef = useRef();
  const locationOptions = map(get(locations, "data", []), (item) => ({
    label: item.name,
    value: item.id,
    data: omit(item, ["id", "active"]),
  }));
  const stationsData = get(auth, "info.stations", []);
  const findStation = (item) =>
    find(
      stationsData,
      (i) => i.station_key === item || i.call_letters === item
    );
  const tags_string = get(prize, "tags_string")
    ? get(prize, "tags_string")
    : get(prize, "tags.length") > 0
    ? map(prize.tags, (item) => item.title).join(", ")
    : "";
  const stationsNumber = get(prize, "stations_number", {});
  const stationsNumberKeys = keys(stationsNumber);
  const stationsQuantity = get(prize, "stations_quantity")
    ? get(prize, "stations_quantity")
    : stationsNumberKeys.map((key) => ({
        station: key,
        quantity: stationsNumber[key],
      }));
  const itemizedPrices = get(prize, "itemized_prices", [
    { ...emptyItemizedPrice },
  ]);
  const initialStations = prize
    ? get(prize, "stations", [])
    : get(rest, "initialStations", []);
  const initialValues = {
    id: get(prize, "id", ""),
    title: get(prize, "title", ""),
    subtitle: get(prize, "subtitle", "") || "",
    description: get(prize, "description", "") || "",
    category: get(prize, "category", ""),
    quantity: get(prize, "quantity", 0),
    allocation: get(prize, "allocation", "shared"),
    unit_value: get(prize, "unit_value", ""),
    supplier: get(prize, "supplier", ""),
    stations: initialStations,
    stations_number: get(prize, "stations_number", {}),
    stations_quantity: stationsQuantity,
    check_received: get(prize, "check_received", false),
    check_exclude: get(prize, "check_exclude", false),
    expiration_date: get(prize, "expiration_date", ""),
    fulfillment: get(prize, "fulfillment", "") || "",
    tags_string: tags_string,
    // optional
    winner_instructions: get(prize, "winner_instructions", ""),
    digital_link: get(prize, "digital_link", ""),
    location: get(prize, "location", ""),
    contact: get(prize, "contact", ""),
    phone: get(prize, "phone", ""),
    add1: get(prize, "add1", ""),
    add2: get(prize, "add2", ""),
    city: get(prize, "city", ""),
    state: get(prize, "state", ""),
    zip: get(prize, "zip", ""),
    time_open: get(prize, "time_open", ""),
    time_close: get(prize, "time_close", ""),
    office_days: get(prize, "office_days", "")
      ? split(prize.office_days, ",").map(Number)
      : [],
    allocated: 0,
    itemize: get(prize, "itemize", false),
    itemized_prices: itemizedPrices,
    stations_itemized_quantity: get(prize, "stations_itemized_quantity")
      ? get(prize, "stations_itemized_quantity", [])
      : generateStationsItemizedQuantity({
          stations: initialStations,
          itemized_prices: itemizedPrices,
        }),
    total_cash: get(prize, "total_cash", ""),
    max_per_winner: get(prize, "max_per_winner", ""),
    isSplitForm,
  };

  const getItemizedTotal = (items) =>
    sumBy(items, (item) => (item.quantity ? parseFloat(item.quantity) : 0));

  const validationShape = {
    isSplitForm: Yup.bool(),
    isUpdate: Yup.bool(),
    itemize: Yup.bool(),
    title: Yup.string().required(
      intl.formatMessage({ id: "prizes validate > title is required" })
    ),
    category: Yup.string().required(
      intl.formatMessage({
        id: "prizes validate > type is required",
      })
    ),
    subtitle: Yup.string().nullable(),
    description: Yup.string().nullable(),
    allocation: Yup.string().required(
      intl.formatMessage({
        id: "prizes validate > allocation is required",
      })
    ),
    fulfillment: Yup.string().required(
      intl.formatMessage({
        id: "prizes validate > fulfillment is required",
      })
    ),
    quantity: Yup.mixed().when("category", {
      is: (val) => {
        return val === "variable_cash";
      },
      then: Yup.mixed(),
      otherwise: Yup.number()
        .required(
          intl.formatMessage({ id: "prizes validate > quantity is required" })
        )
        .test(
          "min",
          intl.formatMessage({ id: "prizes validate > quantity is required" }),
          function(val) {
            return !!val && Number(val) > 0;
          }
        ),
    }),
    stations: Yup.array()
      .min(
        1,
        intl.formatMessage({ id: "prizes validate > station is required" })
      )
      .required(
        intl.formatMessage({ id: "prizes validate > station is required" })
      ),
    expiration_date: Yup.mixed().when("category", {
      is: "tickets",
      then: Yup.string().required(
        intl.formatMessage({
          id: "prizes validate > expiration is required",
        })
      ),
      otherwise: Yup.mixed(),
    }),
    total_cash: Yup.mixed().when("category", {
      is: "variable_cash",
      then: Yup.string().required(
        intl.formatMessage({
          id: "prizes validate > total cash is required",
        })
      ),
      otherwise: Yup.mixed(),
    }),
    unit_value: Yup.mixed().when(["category", "itemize"], {
      is: (category, itemize) => {
        if (category === "variable_cash" || itemize) return true;
      },
      then: Yup.mixed(),
      otherwise: Yup.number()
        .required(
          intl.formatMessage({
            id: "prizes validate > unit value is required",
          })
        )
        .test(
          "min",
          intl.formatMessage({
            id: "prizes validate > unit value is required",
          }),
          function(val) {
            return parseInt(val) > 0;
          }
        ),
    }),
    max_per_winner: Yup.mixed().when("category", {
      is: "variable_cash",
      then: Yup.string()
        .required(
          intl.formatMessage({
            id: "prizes validate > max per winner is required",
          })
        )
        .test(
          "min",
          intl.formatMessage({
            id: "prizes validate > max per winner is required",
          }),
          function(val) {
            return parseInt(val) > 0;
          }
        ),
      otherwise: Yup.mixed(),
    }),
    // optional
    digital_link: Yup.string()
      .url(
        intl.formatMessage({
          id: "prizes validate > url invalid",
        })
      )
      .nullable(),
    phone: Yup.string()
      .nullable()
      .test(
        "len",
        intl.formatMessage({
          id: "validate > phone number must be 10 digits",
        }),
        (val) => !val || val.length === 10
      ),
    allocated: Yup.number()
      .test(
        "min",
        intl.formatMessage({
          id: "validate > user allocations is required",
        }),
        function(val) {
          const { allocation } = this.parent;
          if (allocation !== "assigned") {
            return true;
          }
          return parseInt(val) > 0;
        }
      )
      .test(
        "max",
        intl.formatMessage({
          id:
            "validate > user allocations must be less than or equal to quantity",
        }),
        function(val) {
          const { quantity, allocation } = this.parent;
          if (allocation !== "assigned") {
            return true;
          }
          const isValid = parseInt(quantity) >= parseInt(val);
          return isValid;
        }
      )
      .test(
        "maxAssigned",
        intl.formatMessage({
          id:
            "validate > itemized allocations must be less than or equal to stations quantity",
        }),
        function(val) {
          const { stations } = this.parent;
          if (get(stations, "length", 0) === 0) return true;
          const {
            itemize,
            itemized_prices,
            stations_itemized_quantity,
            allocation,
          } = this.parent;
          if (itemize && itemized_prices.length && allocation === "assigned") {
            const stationsQuantities = filter(
              stations_itemized_quantity,
              (item, key) => includes(stations, key)
            );
            const isValid = !itemized_prices.some(
              (itemizedPrice, itemIndex) => {
                const itemQuantity = sumBy(
                  stationsQuantities,
                  (stationQuantity) => parseInt(stationQuantity[itemIndex])
                );
                return itemQuantity > parseInt(itemizedPrice.quantity);
              }
            );
            return isValid;
          }
          return true;
        }
      ),
    stations_quantity: Yup.lazy(function(value, item) {
      switch (typeof value) {
        case "array":
        case "object":
          const { allocation, itemize, quantity } = item.parent;
          const isAssigned = allocation === "assigned";
          if (isAssigned && !itemize && value.length > 0) {
            return Yup.array().of(
              Yup.object().shape({
                station: Yup.string(),
                quantity: Yup.string()
                  // .required(
                  //   intl.formatMessage({
                  //     id: "prizes validate > quantity is required",
                  //   })
                  // )
                  // .test(
                  //   "min",
                  //   intl.formatMessage({
                  //     id: "validate > station allocation quantity required",
                  //   }),
                  //   function(val) {
                  //     return parseInt(val) > 0;
                  //   }
                  // )
                  .test(
                    "max",
                    intl.formatMessage({
                      id:
                        "validate > station allocations should be less than or equal total quantity",
                    }),
                    function(val) {
                      return parseInt(val) <= parseInt(quantity);
                    }
                  )
                  .nullable(),
              })
            );
          }
          return Yup.mixed();
        default:
          return Yup.mixed();
      }
    }),
    stations_itemized_quantity: Yup.lazy(function(value, item) {
      const { allocation, itemize, itemized_prices } = item.parent;
      const isAssigned = allocation === "assigned";

      if (isAssigned && itemize && value.length > 0) {
        return Yup.array().of(
          Yup.object().shape({
            station: Yup.string(),
            allotment: Yup.array().of(
              Yup.object().shape({
                quantity: Yup.string()
                  .test(
                    "max",
                    intl.formatMessage({
                      id:
                        "validate > allotted station quantity should be less or equal to quantity",
                    }),
                    function() {
                      const totalQuantityOfItem = Number(
                        get(
                          find(
                            itemized_prices,
                            (i) => i.id === this.parent.item_id
                          ),
                          "quantity",
                          0
                        ) || 0
                      );
                      let totalAssignedToItem = 0;
                      forEach(value, (i) => {
                        totalAssignedToItem += sumBy(
                          i.allotment.filter(
                            (o) => o.item_id === this.parent.item_id
                          ),
                          (o) => Number(o.quantity || 0)
                        );
                      });
                      return totalQuantityOfItem >= totalAssignedToItem;
                    }
                  )
                  .nullable(),
                available: Yup.string(),
              })
            ),
          })
        );
      }
      return Yup.array();
    }),
    itemized_prices: Yup.lazy(function(value, item) {
      switch (typeof value) {
        case "array":
        case "object":
          const { itemize, quantity, itemized_prices } = item.parent;
          if (itemize) {
            return Yup.array()
              .of(
                Yup.object().shape({
                  description: Yup.string()
                    .required(
                      intl.formatMessage({
                        id: "prizes validate > description is required",
                      })
                    )
                    .nullable(),
                  value: Yup.string()
                    .required(
                      intl.formatMessage({
                        id: "prizes validate > value is required",
                      })
                    )
                    .nullable(),
                  quantity: Yup.string()
                    .required(
                      intl.formatMessage({
                        id: "prizes validate > quantity is required",
                      })
                    )
                    .nullable()
                    .test(
                      "min",
                      intl.formatMessage({
                        id: "validate > itemized allocation quantity required",
                      }),
                      function(val) {
                        return parseInt(val || 0) > 0;
                      }
                    )
                    .test(
                      "max",
                      intl.formatMessage({
                        id:
                          "validate > itemized allocations should be less than total quantity",
                      }),
                      function(val) {
                        const parsedValue = parseInt(val || 0);
                        if (parsedValue < 1) return true;
                        if (itemized_prices.length === 1) {
                          return parsedValue <= parseInt(quantity);
                        }
                        return parsedValue < parseInt(quantity);
                      }
                    )
                    .test(
                      "maxAssigned",
                      intl.formatMessage({
                        id:
                          "validate > itemized allocations must be equal to quantity",
                      }),
                      function(val) {
                        const parsedValue = parseInt(val || 0);
                        if (parsedValue < 1) return true;
                        const itemizedTotal = getItemizedTotal(itemized_prices);
                        return (
                          parseFloat(itemizedTotal) === parseFloat(quantity)
                        );
                      }
                    ),
                })
              )
              .test(
                "assignment",
                intl.formatMessage({
                  id:
                    "validate > total itemized allocations must be equal to quantity",
                }),
                function() {
                  const itemizedTotal = getItemizedTotal(itemized_prices);
                  return parseFloat(itemizedTotal) === parseFloat(quantity);
                }
              );
          }
          return Yup.mixed();
        default:
          return Yup.mixed();
      }
    }),
  };
  const serverCountry = get(auth, "info.server_country", "US");
  const validationSchema = Yup.object().shape(validationShape);
  const isLinkedToContests = get(prize, "linked_to_contests", false);

  const form = (
    <Formik
      enableReinitialize
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={initialValues}
      ref={formikRef}
      validationSchema={validationSchema}
      onSubmit={rest.onSubmit}
    >
      {(formProps) => {
        const errors = formProps.errors;
        const touched = formProps.touched;
        const values = formProps.values;

        if (
          !isEqual(rest.currentPrizeData, values) &&
          rest.setCurrentPrizeData
        ) {
          rest.setCurrentPrizeData(values);
        }
        const canItemize = checkCanItemize(values.category);
        const isAssigned = values.allocation === "assigned";
        const isTickets = values.category === "tickets";
        const isVariableCash = values.category === "variable_cash";
        const isItemized = values.itemize;
        const itemizedValues = get(values, "itemized_prices", []);
        const itemizedQuantities = get(
          values,
          "stations_itemized_quantity",
          []
        );
        const itemizedTotal = getItemizedTotal(itemizedValues);
        const total = isVariableCash ? values.total_cash : values.quantity;
        const stationsQuantity = values.stations_quantity;

        const stationsNumberAllocation = sum(
          stationsQuantity.map((stationQuantity) =>
            Number(stationQuantity.quantity)
          )
        );
        const stationsQuantityPrice = getItemizedQuantityTotal(
          values.stations_itemized_quantity
        );
        const allocation =
          isItemized && isAssigned && canItemize
            ? stationsQuantityPrice
            : stationsNumberAllocation;
        const unAllocation = total - allocation;
        if (values.allocated !== allocation) {
          formProps.setFieldValue("allocated", allocation);
        }
        const getItemizedPrizeError = (index, field) => {
          const itemizedErrors =
            touched.itemized_prices && errors.itemized_prices;
          return (
            itemizedErrors &&
            itemizedErrors[index] &&
            itemizedErrors[index][field]
          );
        };
        const getItemizedPrizeValue = (index, field) => {
          return get(itemizedValues, `${index}.${field}`, "");
        };
        const setItemizedPrizeValue = (index, field, value) => {
          const newItemizedValues = [...itemizedValues];
          newItemizedValues[index][field] = value;
          formProps.setFieldValue("itemized_prices", newItemizedValues);
        };
        const getStationsItemizedQuantityError = (station, index) => {
          const itemizedErrors =
            touched.stations_itemized_quantity &&
            errors.stations_itemized_quantity;
          return get(
            itemizedErrors,
            `${index}.allotment.${index}.quantity`,
            ""
          );
        };
        const getStationsItemizedQuantityValue = (station, itemId) => {
          const itemizedQuantity = find(
            itemizedQuantities,
            (item) => item.station === station
          );
          const allotmentData = find(
            get(itemizedQuantity, "allotment", []),
            (item) => item.item_id === itemId
          );
          return get(allotmentData, "quantity", "") || "";
        };
        const setStationsItemizedQuantityValue = (station, itemId) => (
          value
        ) => {
          const newItemizedQuantities = map(itemizedQuantities, (data) => {
            if (data.station === station) {
              return {
                ...data,
                allotment: map(data.allotment, (i) => ({
                  ...i,
                  quantity: i.item_id === itemId ? value : i.quantity,
                  available: i.item_id === itemId ? value : i.available,
                })),
              };
            }
            return data;
          });
          formProps.setFieldValue(
            "stations_itemized_quantity",
            newItemizedQuantities
          );
          setShouldBlockNavigation(true);
        };
        const setStationsItemizedQuantityValueForShared = (itemId) => (
          value
        ) => {
          const newItemizedQuantities = map(itemizedQuantities, (data) => {
            return {
              ...data,
              allotment: map(data.allotment, (i) => ({
                ...i,
                quantity: i.item_id === itemId ? value : i.quantity,
                available: i.item_id === itemId ? value : i.available,
              })),
            };
          });
          formProps.setFieldValue(
            "stations_itemized_quantity",
            newItemizedQuantities
          );
          setShouldBlockNavigation(true);
        };
        const getStationQuantityError = (index, field) => {
          const stationErrors =
            touched.stations_quantity && errors.stations_quantity;
          return (
            stationErrors && stationErrors[index] && stationErrors[index][field]
          );
        };

        const handleNextClick = (event) => {
          const isLastStep = activeTab === prizeFormTabs.fulfillment.tabKey;
          if (isLastStep) {
            formikRef.current.submitForm();
            setTimeout(() => {
              const formErrors = get(formikRef.current, "state.errors");
              if (formErrors && Object.keys(formErrors).length) {
                const errorTabs = filter(tabs, (tab) => {
                  return !isStepValid(tab.tabKey);
                });
                ToastManager.show({
                  title: intl.formatMessage({
                    id: "toast > title not saved",
                  }),
                  message: intl.formatMessage(
                    {
                      id: "toast > the following sections have errors : ",
                    },
                    {
                      SECTIONS: errorTabs.map((tab) => tab.title).join(", "),
                    }
                  ),
                  level: "error",
                });
                return;
              }
            }, 20);
          }
          if (activeTab === prizeFormTabs.audience.tabKey) {
            setActiveTab(prizeFormTabs["item-details"].tabKey);
          }
          if (activeTab === prizeFormTabs["item-details"].tabKey) {
            if (canItemize && isItemized) {
              setActiveTab(prizeFormTabs.itemize.tabKey);
            } else if (isAssigned) {
              setActiveTab(prizeFormTabs.allotment.tabKey);
            } else {
              setActiveTab(prizeFormTabs.fulfillment.tabKey);
            }
          }
          if (activeTab === prizeFormTabs.itemize.tabKey) {
            if (isAssigned) {
              setActiveTab(prizeFormTabs.allotment.tabKey);
            } else {
              setActiveTab(prizeFormTabs.fulfillment.tabKey);
            }
          }
          if (activeTab === prizeFormTabs.allotment.tabKey) {
            setActiveTab(prizeFormTabs.fulfillment.tabKey);
          }
        };
        const renderAudience = () => (
          <div className={bem.e("section-form")}>
            {!isSplitForm && (
              <div className={bem.e("section-title")}>
                <FormattedMessage id="prizes > audience" />
              </div>
            )}
            <div
              className={classnames(
                bem.e("fields"),
                bem.e("allocation-fields")
              )}
            >
              <Row>
                <Col className={bem.e("col-reset")} xs={6}>
                  <FormGroup>
                    <SelectAudience
                      name="stations"
                      contract={true}
                      className={bem.e("select-stations")}
                      placeholder={intl.formatMessage({
                        id: "prizes > select",
                      })}
                      item={{
                        field: "stations",
                        title: <FormattedMessage id="prizes > stations" />,
                      }}
                      isRenderDefaultSelected={false}
                      required
                      template={{ key: "liner" }}
                      isFilterByServerConfig={true}
                      isShortGroupByNumber
                      isGroup
                      isMulti={true}
                      value={values.stations}
                      onChange={(selectedStations) => {
                        let isSelectedByDefault = false;
                        const value = map(selectedStations, (i) => {
                          // selected default value from SelectAudience component will return array object
                          if (typeof i === "object" && i.value) {
                            isSelectedByDefault = true;
                            if (isAllowDeleteStation)
                              setIsAllowDeleteStation(false);
                            return i.value;
                          }
                          return i;
                        });
                        if (!isSelectedByDefault) {
                          setShouldBlockNavigation(true);
                        }
                        formProps.setFieldValue("stations", value);
                        const quantity =
                          value.length === 1 ? values.quantity : "0";

                        const newStationsQuantity = [];
                        const newStationsItemizedQuantities = [];
                        forEach(value, (station) => {
                          newStationsQuantity.push({
                            station,
                            quantity,
                          });
                          newStationsItemizedQuantities.push({
                            station,
                            allotment: map(values.itemized_prices, (item) => ({
                              item_id: item.id || genRandomString(12),
                              quantity: "",
                              available: "",
                            })),
                          });
                        });
                        formProps.setFieldValue(
                          "stations_quantity",
                          newStationsQuantity
                        );
                        formProps.setFieldValue(
                          "stations_itemized_quantity",
                          newStationsItemizedQuantities
                        );
                      }}
                      closeMenuOnSelect={true}
                      checked={{ value: values.stations }}
                      validationState={{
                        stations: {
                          validation_error: errors.stations,
                        },
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {isSplitForm && get(values, "stations.length", 0) === 0 && (
                <div className={bem.e("no-audience")}>
                  <PointerArrow />
                  <Label>
                    <FormattedMessage id="prizes > form select audience" />
                  </Label>
                </div>
              )}
              {get(values, "stations.length", 0) > 0 && (
                <Row>
                  <Col className={bem.e("col-reset")} xs={12}>
                    <FormGroup>
                      {!isSplitForm && (
                        <Label>
                          <FormattedMessage id="prizes > form stations" />
                        </Label>
                      )}
                      <div className={bem.e("station-shared")}>
                        {map(values.stations, (item, index) => {
                          const stationData = findStation(item);
                          const arr = [];
                          if (stationData) {
                            if (get(stationData, "call_letters")) {
                              arr.push(stationData.call_letters);
                            }
                            if (get(stationData, "name")) {
                              arr.push(stationData.name);
                            }
                          } else {
                            // old data save only the station name
                            arr.push(item);
                          }

                          return (
                            <div className={bem.e("station-item")} key={index}>
                              <div className={bem.e("station-name")}>
                                {arr.length > 0 ? (
                                  arr.join(" - ")
                                ) : (
                                  <FormattedMessage id="prizes > none" />
                                )}
                                {isAllowDeleteStation && (
                                  <span
                                    className={bem.e("station-trash")}
                                    onClick={() => {
                                      let newStations = filter(
                                        values.stations,
                                        (station) => station !== item
                                      );
                                      const quantity =
                                        newStations.length === 1
                                          ? values.quantity
                                          : "0";
                                      const newStationsQuantity = [];
                                      newStations.forEach((station) => {
                                        newStationsQuantity.push({
                                          station,
                                          quantity,
                                        });
                                      });
                                      const newStationsItemizedQuantities = filter(
                                        values.stations_itemized_quantity,
                                        (i) => i.station !== item
                                      );
                                      formProps.setFieldValue(
                                        "stations_quantity",
                                        newStationsQuantity
                                      );
                                      formProps.setFieldValue(
                                        "stations_itemized_quantity",
                                        newStationsItemizedQuantities
                                      );
                                      formProps.setFieldValue(
                                        "stations",
                                        newStations
                                      );
                                    }}
                                  >
                                    <TrashSmallIcon />
                                  </span>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              )}
            </div>
          </div>
        );

        const renderItemize = () => {
          if (!(isItemized && canItemize)) {
            return null;
          }
          return (
            <>
              <div
                className={classnames(
                  bem.e("section-title"),
                  bem.e("section-itemize-price")
                )}
              >
                <FormattedMessage id="prizes > itemized price" />
                <div className="itemized-counts">
                  <span className="count-title">
                    <FormattedMessage id="prizes > itemized" />
                  </span>
                  <span className="count">{itemizedTotal || 0}</span>
                  <span className="count-title">
                    <FormattedMessage id="prizes > remaining" />
                  </span>
                  <span className="count">
                    {values.quantity - itemizedTotal}
                  </span>
                </div>
              </div>
              <div className={classnames(bem.e("fields"))}>
                <Row>
                  <Col className={bem.e("col-reset")}>
                    <FormGroup>
                      <div className={bem.e("itemized-prize-assigned")}>
                        {map(values.itemized_prices, (item, index) => {
                          return (
                            <div
                              key={index}
                              className="d-flex justify-content-between"
                            >
                              <TextInput
                                label={
                                  index ? null : (
                                    <span>
                                      <FormattedMessage id="prizes > form description" />
                                      <Asterisk>*</Asterisk>
                                    </span>
                                  )
                                }
                                placeholder={intl.formatMessage({
                                  id: "prizes > enter prize name",
                                })}
                                disabled={total === 0 || !total}
                                value={getItemizedPrizeValue(
                                  index,
                                  "description"
                                )}
                                onChange={(e) => {
                                  setShouldBlockNavigation(true);
                                  setItemizedPrizeValue(
                                    index,
                                    "description",
                                    e.target.value
                                  );
                                }}
                                onBlur={formProps.handleBlur}
                                error={getItemizedPrizeError(
                                  index,
                                  "description"
                                )}
                              />
                              <TextInputFormatted
                                allowNegative={false}
                                label={
                                  index ? null : (
                                    <span>
                                      <FormattedMessage id="prizes > form quantity" />
                                      <Asterisk>*</Asterisk>
                                    </span>
                                  )
                                }
                                decimalScale={0}
                                placeholder="0"
                                disabled={total === 0 || !total}
                                type="number"
                                value={getItemizedPrizeValue(index, "quantity")}
                                onChange={(value) => {
                                  setShouldBlockNavigation(true);
                                  setItemizedPrizeValue(
                                    index,
                                    "quantity",
                                    value
                                  );
                                  if (values.allocation === "shared") {
                                    setStationsItemizedQuantityValueForShared(
                                      item.id
                                    )(value);
                                  }
                                }}
                                onBlur={formProps.handleBlur}
                                error={getItemizedPrizeError(index, "quantity")}
                              />
                              <TextInputFormatted
                                allowNegative={false}
                                prefix={"$"}
                                thousandSeparator={true}
                                label={
                                  index ? null : (
                                    <span>
                                      <FormattedMessage id="prizes > form unit_value" />
                                      <Asterisk>*</Asterisk>
                                    </span>
                                  )
                                }
                                placeholder={intl.formatMessage({
                                  id: "prizes > enter prize value",
                                })}
                                disabled={total === 0 || !total}
                                type="number"
                                value={getItemizedPrizeValue(index, "value")}
                                onChange={(value) => {
                                  setShouldBlockNavigation(true);
                                  setItemizedPrizeValue(index, "value", value);
                                }}
                                onBlur={formProps.handleBlur}
                                error={getItemizedPrizeError(index, "value")}
                              />
                              <span
                                className={classnames(
                                  bem.e("itemized-price-trash"),
                                  { "first-trash": !index }
                                )}
                                onClick={() => {
                                  setShouldBlockNavigation(true);
                                  const newItemizedValues = [...itemizedValues];
                                  newItemizedValues.splice(index, 1);
                                  formProps.setFieldValue(
                                    "itemized_prices",
                                    newItemizedValues
                                  );

                                  const newStationsQuantity = [];
                                  const newStationsItemizedQuantities = map(
                                    values.stations_itemized_quantity,
                                    (i) => {
                                      return {
                                        ...i,
                                        allotment: filter(
                                          i.allotment,
                                          (o) => o.item_id !== item.id
                                        ),
                                      };
                                    }
                                  );
                                  formProps.setFieldValue(
                                    "stations_quantity",
                                    newStationsQuantity
                                  );
                                  formProps.setFieldValue(
                                    "stations_itemized_quantity",
                                    newStationsItemizedQuantities
                                  );
                                }}
                              >
                                <TrashSmallIcon />
                              </span>
                            </div>
                          );
                        })}
                        {!!errors.itemized_prices &&
                          isString(errors.itemized_prices) && (
                            <div className={"error-block"}>
                              <span className="text-danger">
                                {errors.itemized_prices}
                              </span>
                            </div>
                          )}
                        <ButtonAdd
                          onClick={() => {
                            const newItemizedValues = [...itemizedValues];
                            const itemId = genRandomString(12);
                            newItemizedValues.push({
                              ...emptyItemizedPrice,
                              id: itemId,
                            });
                            formProps.setFieldValue(
                              "itemized_prices",
                              newItemizedValues
                            );
                            const newStationsItemizedQuantities = map(
                              values.stations_itemized_quantity,
                              (item) => {
                                return {
                                  ...item,
                                  allotment: [
                                    ...item.allotment,
                                    {
                                      item_id: itemId,
                                      quantity: "",
                                      available: "",
                                    },
                                  ],
                                };
                              }
                            );
                            formProps.setFieldValue(
                              "stations_itemized_quantity",
                              newStationsItemizedQuantities
                            );
                            setShouldBlockNavigation(true);
                          }}
                          color="primary"
                          className={classnames(
                            "btn-radius btn-outline-blue",
                            "add-itemized-price"
                          )}
                          iconProps={{
                            color: "#795AFA",
                            width: 19,
                            height: 19,
                          }}
                        >
                          <FormattedMessage id="prizes > add item" />
                        </ButtonAdd>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </>
          );
        };

        const renderItemDetails = () => (
          <div className={bem.e("section-form")}>
            {!isSplitForm && (
              <div className={bem.e("section-title")}>
                <FormattedMessage id="prizes > item details" />
              </div>
            )}
            <div className={classnames(bem.e("fields"), bem.e("item-fields"))}>
              <Row>
                <Col className={bem.e("col-reset")}>
                  <FormGroup>
                    <TextInput
                      label={
                        <span>
                          <FormattedMessage id="prizes > form title" />
                          <Asterisk>*</Asterisk>
                        </span>
                      }
                      type="text"
                      name="title"
                      placeholder={intl.formatMessage({
                        id: "prizes > enter prize title",
                      })}
                      value={values.title}
                      error={touched.title && errors.title}
                      onChange={formProps.handleChange}
                      onBlur={formProps.handleBlur}
                    />
                  </FormGroup>
                </Col>
                <Col className={bem.e("col-reset")}>
                  <FormGroup>
                    <TextInput
                      label={
                        <span>
                          <FormattedMessage id="prizes > form subtitle" />
                        </span>
                      }
                      type="text"
                      name="subtitle"
                      placeholder={intl.formatMessage({
                        id: "prizes > enter prize subtitle",
                      })}
                      value={values.subtitle}
                      error={touched.subtitle && errors.subtitle}
                      onChange={formProps.handleChange}
                      onBlur={formProps.handleBlur}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <TextInput
                  label={
                    <span>
                      <FormattedMessage id="prizes > form description" />
                    </span>
                  }
                  required
                  type="textarea"
                  name="description"
                  placeholder={intl.formatMessage({
                    id: "prizes > enter description",
                  })}
                  aria-multiline="true"
                  value={values.description}
                  onChange={formProps.handleChange}
                  onBlur={formProps.handleBlur}
                  error={touched.description && errors.description}
                />
              </FormGroup>
              <Row>
                <Col className={bem.e("col-reset")}>
                  <FormGroup>
                    <Dropdown
                      label={
                        <span>
                          <FormattedMessage id="prizes > type" />
                          <Asterisk>*</Asterisk>
                        </span>
                      }
                      placeholder={intl.formatMessage({
                        id: "prizes > select",
                      })}
                      name="category"
                      error={touched.category && errors.category}
                      onChange={(selectedOption) => {
                        formProps.setFieldValue(
                          "category",
                          selectedOption.value
                        );
                        setShouldBlockNavigation(true);
                      }}
                      value={
                        find(
                          categoryList,
                          (item) => item.value === values.category
                        ) || ""
                      }
                      options={categoryList}
                    />
                  </FormGroup>
                </Col>
                <Col className={bem.e("col-reset")}>
                  <FormGroup>
                    <TextInput
                      label={<FormattedMessage id="prizes > tags" />}
                      type="text"
                      name="tags_string"
                      placeholder={intl.formatMessage({
                        id: "prizes > enter tags separated by commas",
                      })}
                      value={values.tags_string}
                      error={touched.tags_string && errors.tags_string}
                      onChange={formProps.handleChange}
                      onBlur={formProps.handleBlur}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className={bem.e("col-reset")}>
                  <FormGroup>
                    <TextInput
                      label={<FormattedMessage id="prizes > form supplier" />}
                      type="text"
                      name="supplier"
                      placeholder={intl.formatMessage({
                        id: "prizes > enter prize supplier",
                      })}
                      value={values.supplier}
                      error={touched.supplier && errors.supplier}
                      onChange={formProps.handleChange}
                      onBlur={formProps.handleBlur}
                    />
                  </FormGroup>
                </Col>
                <Col className={bem.e("col-reset")}>
                  <FormGroup className={bem.e("group-radios")}>
                    <Label>
                      <FormattedMessage id="prizes > allotment" />
                      <Asterisk>*</Asterisk>
                    </Label>
                    <RadioButtons
                      onSelected={(selected) => {
                        setShouldBlockNavigation(true);
                        formProps.setFieldValue("allocation", selected);
                        if (selected === "assigned") {
                          const newStationsItemizedQuantity = generateStationsItemizedQuantity(
                            values
                          );
                          formProps.setFieldValue(
                            "stations_itemized_quantity",
                            newStationsItemizedQuantity
                          );
                        } else {
                          formProps.setFieldValue(
                            "stations_itemized_quantity",
                            []
                          );
                        }
                      }}
                      selectedValue={values.allocation}
                      buttons={[
                        {
                          label: <FormattedMessage id="prizes > shared" />,
                          value: "shared",
                        },
                        {
                          label: <FormattedMessage id="prizes > assigned" />,
                          value: "assigned",
                        },
                      ]}
                      error={touched.allocation && errors.allocation}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {/* Hide when cash variable */}
              {!isVariableCash && (
                <Row>
                  <Col className={bem.e("col-reset")}>
                    <FormGroup>
                      <div className="d-flex w-100">
                        <TextInputFormatted
                          className={classnames({ "w-100": !canItemize })}
                          label={
                            <span>
                              <FormattedMessage id="prizes > form quantity" />
                              <Asterisk>*</Asterisk>
                            </span>
                          }
                          thousandSeparator={true}
                          allowNegative={false}
                          type="number"
                          name="quantity"
                          decimalScale={0}
                          placeholder={intl.formatMessage({
                            id: "prizes > enter prize quantity",
                          })}
                          error={touched.quantity && errors.quantity}
                          value={values.quantity}
                          onChange={(value) => {
                            setShouldBlockNavigation(true);
                            formProps.setFieldValue("quantity", value);
                            const newStationsQuantity = values.stations.map(
                              (station) => ({
                                station,
                                quantity: "0",
                              })
                            );
                            formProps.setFieldValue(
                              "stations_quantity",
                              newStationsQuantity
                            );
                          }}
                          onBlur={formProps.handleBlur}
                        />
                        {canItemize && (
                          <div className={bem.e("toggle-switch-input")}>
                            <ToggleSwitch
                              rightComponent={
                                <FormattedMessage id="prizes > itemize" />
                              }
                              name="itemize"
                              className="text-uppercase"
                              switchProps={{
                                checked: values.itemize,
                                onChange: (checked) => {
                                  setShouldBlockNavigation(true);
                                  formProps.setFieldValue("itemize", checked);
                                  if (checked) {
                                    const newStationsItemizedQuantity = generateStationsItemizedQuantity(
                                      values
                                    );
                                    formProps.setFieldValue(
                                      "stations_itemized_quantity",
                                      newStationsItemizedQuantity
                                    );
                                  } else {
                                    formProps.setFieldValue(
                                      "stations_itemized_quantity",
                                      []
                                    );
                                  }
                                  formProps.setFieldValue("unit_value", "");
                                },
                                offColor: "#C2D4E0",
                                onColor: "#795AFA",
                                uncheckedIcon: false,
                                checkedIcon: false,
                                boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.282)",
                                height: 26.44,
                                width: 48.48,
                                handleDiameter: 22.92,
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col className={bem.e("col-reset")}>
                    {(!isItemized || !canItemize) && (
                      <FormGroup>
                        <TextInputFormatted
                          label={
                            <>
                              <FormattedMessage id="prizes > form unit_value" />
                              <Asterisk>*</Asterisk>
                            </>
                          }
                          prefix={"$"}
                          thousandSeparator={true}
                          allowNegative={false}
                          type="number"
                          name="unit_value"
                          placeholder={intl.formatMessage({
                            id: "prizes > enter prize unit value",
                          })}
                          value={values.unit_value}
                          error={touched.unit_value && errors.unit_value}
                          onChange={(value) => {
                            setShouldBlockNavigation(true);
                            formProps.setFieldValue("unit_value", value);
                          }}
                          onBlur={formProps.handleBlur}
                        />
                      </FormGroup>
                    )}
                  </Col>
                </Row>
              )}
              {isVariableCash && (
                <Row>
                  <Col className={bem.e("col-reset")}>
                    <FormGroup>
                      <TextInputFormatted
                        label={
                          <span>
                            <FormattedMessage id="prizes > form total cash" />
                            <Asterisk>*</Asterisk>
                          </span>
                        }
                        prefix={"$"}
                        thousandSeparator={true}
                        allowNegative={false}
                        type="number"
                        name="total_cash"
                        placeholder={intl.formatMessage({
                          id: "prizes > enter prize quantity",
                        })}
                        error={touched.total_cash && errors.total_cash}
                        value={values.total_cash}
                        onChange={(value) => {
                          setShouldBlockNavigation(true);
                          formProps.setFieldValue("total_cash", value);
                        }}
                        onBlur={formProps.handleBlur}
                      />
                    </FormGroup>
                  </Col>
                  <Col className={bem.e("col-reset")}>
                    <FormGroup>
                      <TextInputFormatted
                        label={
                          <>
                            <FormattedMessage id="prizes > form max winner" />
                            <Asterisk>*</Asterisk>
                          </>
                        }
                        prefix={"$"}
                        thousandSeparator={true}
                        allowNegative={false}
                        type="number"
                        name="max_per_winner"
                        placeholder={intl.formatMessage({
                          id: "prizes > enter prize unit value",
                        })}
                        value={values.max_per_winner}
                        error={touched.max_per_winner && errors.max_per_winner}
                        onChange={(value) => {
                          setShouldBlockNavigation(true);
                          formProps.setFieldValue("max_per_winner", value);
                        }}
                        onBlur={formProps.handleBlur}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}
            </div>
            {/* itemized_prices */}
            {!isSplitForm && renderItemize()}
          </div>
        );

        const renderAllotment = () => {
          if (!(isAssigned && get(values, "stations.length", 0) > 0)) {
            return null;
          }
          return (
            <div className={bem.e("section-form")}>
              {canItemize && isItemized ? (
                <>
                  {/* Allotment => Assigned with itemized */}
                  {!isSplitForm && (
                    <div
                      className={classnames(
                        bem.e("section-title"),
                        bem.e("section-itemize-price")
                      )}
                    >
                      <FormattedMessage id="prizes > allotment" />
                    </div>
                  )}
                  {map(values.stations, (station, index) => {
                    const itemizedQuantities = get(
                      values,
                      "stations_itemized_quantity",
                      []
                    );
                    const quantitiesForStation = find(
                      itemizedQuantities,
                      (item) => item.station === station
                    );
                    const itemizedQuantityTotal = sum(
                      get(quantitiesForStation, "allotment", []).map((i) =>
                        i.quantity ? Number(i.quantity) : 0
                      )
                    );
                    const stationData = findStation(station);
                    return (
                      <div
                        key={`${station}-price-section`}
                        className="station-itemized-section section-open"
                      >
                        <div
                          className={classnames(
                            bem.e("section-title"),
                            bem.e("section-itemize-price"),
                            "smaller"
                          )}
                        >
                          {stationData ? (
                            stationData.name
                          ) : (
                            <FormattedMessage id="prizes > none" />
                          )}
                          <div className="itemized-counts">
                            <span className="count-title">
                              <FormattedMessage id="prizes > allotted" />
                            </span>
                            <span className="count">
                              {itemizedQuantityTotal || 0}
                            </span>
                            <span className="count-title">
                              <FormattedMessage id="prizes > remaining" />
                            </span>
                            <span className="count">
                              {values.quantity - allocation}
                            </span>
                            <ChevronWrapper
                              className="chevron"
                              onClick={(e) => {
                                const itemizedSection = e.target.closest(
                                  ".station-itemized-section"
                                );
                                if (itemizedSection) {
                                  if (
                                    itemizedSection.classList.contains(
                                      "section-open"
                                    )
                                  ) {
                                    itemizedSection.className =
                                      "station-itemized-section section-close";
                                  } else {
                                    itemizedSection.className =
                                      "station-itemized-section section-open";
                                  }
                                }
                              }}
                            >
                              <DownIcon />
                            </ChevronWrapper>
                          </div>
                        </div>
                        <div
                          className={classnames(
                            bem.e("fields"),
                            "section-body"
                          )}
                        >
                          <Row>
                            <Col className={bem.e("col-reset")} xs={12}>
                              <FormGroup
                                className={bem.e("form-group-stations")}
                              >
                                <div className={bem.e("station-head")}>
                                  <Label>
                                    <FormattedMessage id="prizes > form description" />
                                    <Asterisk>*</Asterisk>
                                  </Label>
                                  <Label>
                                    <FormattedMessage id="prizes > form quantity" />
                                    <Asterisk>*</Asterisk>
                                  </Label>
                                </div>
                                <div className={bem.e("station-assigned")}>
                                  {map(
                                    values.itemized_prices,
                                    (item, index) => {
                                      const errorMessage = getStationsItemizedQuantityError(
                                        station,
                                        index
                                      );
                                      return (
                                        <React.Fragment
                                          key={`${station}-itemized-prices-${index}`}
                                        >
                                          <div
                                            className={bem.e("station-item")}
                                          >
                                            <div
                                              className={bem.e("station-name")}
                                            >
                                              {item.description}
                                            </div>
                                            <div
                                              className={bem.e("station-qty")}
                                            >
                                              <TextInputFormatted
                                                allowNegative={false}
                                                disabled={total === 0 || !total}
                                                type="number"
                                                placeholder={intl.formatMessage(
                                                  {
                                                    id:
                                                      "prizes > enter prize quantity",
                                                  }
                                                )}
                                                decimalScale={0}
                                                value={getStationsItemizedQuantityValue(
                                                  station,
                                                  item.id
                                                )}
                                                onChange={setStationsItemizedQuantityValue(
                                                  station,
                                                  item.id
                                                )}
                                                onBlur={formProps.handleBlur}
                                              />
                                            </div>
                                          </div>
                                          {!!errorMessage && (
                                            <div
                                              className={classnames(
                                                bem.e("station-item"),
                                                "error-block"
                                              )}
                                            >
                                              <div
                                                className={bem.e(
                                                  "station-name"
                                                )}
                                              ></div>
                                              <div
                                                className={bem.e("station-qty")}
                                              >
                                                <span className="text-danger">
                                                  {errorMessage}
                                                </span>
                                              </div>
                                            </div>
                                          )}
                                        </React.Fragment>
                                      );
                                    }
                                  )}
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    );
                  })}
                  <div
                    className={classnames(
                      bem.e("fields"),
                      bem.e("itemized-allocation")
                    )}
                  >
                    <Row>
                      <AllocationTable
                        bem={bem}
                        total={total}
                        allocation={allocation}
                        unAllocation={unAllocation}
                        errors={errors}
                        touched={touched}
                      />
                    </Row>
                  </div>
                </>
              ) : (
                <>
                  {/* Allotment => Assigned with not itemized */}
                  {!isSplitForm && (
                    <div className={bem.e("section-title")}>
                      <FormattedMessage id="prizes > allotment" />
                    </div>
                  )}
                  <div
                    className={classnames(
                      bem.e("fields"),
                      bem.e("allocation-fields")
                    )}
                  >
                    <Row>
                      <Col className={bem.e("col-reset")} xs={12}>
                        <FormGroup className={bem.e("form-group-stations")}>
                          <div className={bem.e("station-head")}>
                            <Label>
                              <FormattedMessage id="prizes > form stations" />
                            </Label>
                            <Label>
                              <FormattedMessage
                                id={
                                  isVariableCash
                                    ? "prizes > form total cash"
                                    : "prizes > form quantity"
                                }
                              />
                              <Asterisk>*</Asterisk>
                            </Label>
                          </div>
                          <div className={bem.e("station-assigned")}>
                            {map(values.stations_quantity, (item, index) => {
                              const station = item.station;
                              const stationData = findStation(item.station);
                              return (
                                <React.Fragment
                                  key={`${station}-allocated-price-${index}`}
                                >
                                  <div className={bem.e("station-item")}>
                                    <div className={bem.e("station-name")}>
                                      {stationData ? (
                                        stationData.name
                                      ) : (
                                        <FormattedMessage id="prizes > none" />
                                      )}
                                    </div>
                                    <div className={bem.e("station-qty")}>
                                      <TextInputFormatted
                                        allowNegative={false}
                                        thousandSeparator={true}
                                        disabled={total === 0 || !total}
                                        type="number"
                                        decimalScale={0}
                                        value={get(item, "quantity", 0)}
                                        onChange={(value) => {
                                          const newStationsQuantity = [
                                            ...values.stations_quantity,
                                          ];
                                          newStationsQuantity[
                                            index
                                          ].quantity = value;
                                          formProps.setFieldValue(
                                            "stations_quantity",
                                            newStationsQuantity
                                          );
                                          setShouldBlockNavigation(true);
                                        }}
                                        onBlur={formProps.handleBlur}
                                      />
                                    </div>
                                  </div>
                                  {!!getStationQuantityError(
                                    index,
                                    "quantity"
                                  ) && (
                                    <div
                                      className={classnames(
                                        bem.e("station-item"),
                                        "error-block"
                                      )}
                                    >
                                      <div
                                        className={bem.e("station-name")}
                                      ></div>
                                      <div className={bem.e("station-qty")}>
                                        <span className="text-danger">
                                          {getStationQuantityError(
                                            index,
                                            "quantity"
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </React.Fragment>
                              );
                            })}
                          </div>
                        </FormGroup>
                        <Row>
                          <AllocationTable
                            bem={bem}
                            total={total}
                            allocation={allocation}
                            unAllocation={unAllocation}
                            errors={errors}
                            touched={touched}
                          />
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </>
              )}
            </div>
          );
        };

        const renderFulfillment = () => (
          <div className={bem.e("section-form")}>
            {!isSplitForm && (
              <div className={bem.e("section-title")}>
                <FormattedMessage id="prizes > fulfillment" />
              </div>
            )}
            <div
              className={classnames(
                bem.e("fields"),
                bem.e("fulfillment-fields")
              )}
            >
              <Row>
                <Col xs={6} className={bem.e("col-reset")}>
                  <FormGroup>
                    <Dropdown
                      containerClass="d-flex flex-column"
                      label={
                        <span>
                          <FormattedMessage id="prizes > fulfillment method" />
                          <Asterisk>*</Asterisk>
                        </span>
                      }
                      placeholder={intl.formatMessage({
                        id: "prizes > select",
                      })}
                      name="fulfillment"
                      error={touched.fulfillment && errors.fulfillment}
                      onChange={(selectedOption) => {
                        formProps.setFieldValue(
                          "fulfillment",
                          selectedOption.value
                        );
                        setShouldBlockNavigation(true);
                      }}
                      value={
                        find(
                          fulfillmentList,
                          (item) => item.value === values.fulfillment
                        ) || ""
                      }
                      options={fulfillmentList}
                      closeMenuOnSelect
                    />
                  </FormGroup>
                </Col>
                <Col xs={6} className={bem.e("col-reset")}>
                  <FormGroup>
                    <TextInput
                      label={
                        <span>
                          <FormattedMessage id="prizes > form expiration date" />
                          {isTickets && <Asterisk>*</Asterisk>}
                        </span>
                      }
                      type="date"
                      name="expiration_date"
                      placeholder={intl.formatMessage({
                        id: "prizes > enter prize expiration_date",
                      })}
                      value={values.expiration_date}
                      error={touched.expiration_date && errors.expiration_date}
                      onChange={(date) => {
                        setShouldBlockNavigation(true);
                        formProps.setFieldValue("expiration_date", date);
                      }}
                      allowShowIcon
                    />
                  </FormGroup>
                </Col>
                <Col xs={6} className={bem.e("col-reset")}>
                  <div className={bem.e("checkboxes")}>
                    <Checkbox
                      name="check_received"
                      onChange={(checked) => {
                        formProps.setFieldValue("check_received", checked);
                      }}
                      checked={values.check_received}
                      text={
                        <FormattedMessage id="prizes > form check_received" />
                      }
                    />
                    <Checkbox
                      name="check_exclude"
                      onChange={(checked) => {
                        setShouldBlockNavigation(true);
                        formProps.setFieldValue("check_exclude", checked);
                      }}
                      checked={values.check_exclude}
                      text={
                        <FormattedMessage id="prizes > form check_exclude_1099" />
                      }
                    />
                  </div>
                </Col>
              </Row>
              {values.fulfillment === "digital_link" && (
                <FulfillDigital bem={bem} {...formProps} intl={intl} />
              )}
              {values.fulfillment === "mail_out" && (
                <FulfillMailOut bem={bem} {...formProps} intl={intl} />
              )}
              {(values.fulfillment === "will_call" ||
                values.fulfillment === "pickup_client_provider" ||
                values.fulfillment === "pickup_station") && (
                <FulfillLocation
                  bem={bem}
                  {...formProps}
                  intl={intl}
                  setShouldBlockNavigation={setShouldBlockNavigation}
                  locations={locationOptions}
                  setIsOpenLocationModal={rest.setIsOpenLocationModal}
                  serverCountry={serverCountry}
                />
              )}
            </div>
          </div>
        );

        const isStepValid = (tabId) => {
          let isValid = false;
          try {
            isValid = !!validationSchema.validateSync(values, {
              abortEarly: false,
            });
          } catch (error) {
            const errorPaths = map(error.inner, "path");
            const isArrayTabFields = [
              "itemized_prices",
              "stations_quantity",
              "stations_itemized_quantity",
            ];

            const stepErrors = filter(
              prizeFormTabs[tabId].tabFields,
              (tabField) => {
                if (isArrayTabFields.includes(tabField)) {
                  return (
                    errorPaths.filter((item) => item.indexOf(tabField) !== -1)
                      .length > 0
                  );
                }
                return errorPaths.includes(tabField);
              }
            );
            isValid = get(stepErrors, "length", 0) === 0;
          }
          return isValid;
        };

        const renderButtons = () => {
          if (isSplitForm) {
            const isLastStep = activeTab === prizeFormTabs.fulfillment.tabKey;
            return (
              <div className="split-form-buttons">
                <Button
                  color="blue"
                  type={"button"}
                  className={classnames("btn btn-radius", bem.e("btn-submit"))}
                  onClick={handleNextClick}
                >
                  {getButtonText({ id: values.id, isLastStep, isSplitForm })}
                </Button>
                <Button
                  color="blue"
                  outline
                  className={classnames("btn btn-radius", bem.e("btn-cancel"))}
                  onClick={rest.onCancel}
                >
                  <FormattedMessage id={`prizes > button cancel`} />
                </Button>
              </div>
            );
          }
          return (
            <div
              className={classnames(
                bem.e("form-buttons"),
                "bottom-actions-sticky"
              )}
            >
              <div className="buttons">
                <div className="left-buttons">
                  <Button
                    color="blue"
                    type="submit"
                    id="btn-submit"
                    className={classnames(
                      "btn btn-radius",
                      bem.e("btn-submit")
                    )}
                    onClick={() => {
                      setTimeout(() => {
                        const formErrors = get(
                          formikRef,
                          "current.state.errors",
                          {}
                        );
                        const errorKeys = Object.keys(formErrors);
                        const hasError = !!get(errorKeys, "length", 0);
                        if (hasError) {
                          ToastManager.show({
                            title: intl.formatMessage({
                              id: "toast > title not saved",
                            }),
                            message: intl.formatMessage({
                              id:
                                "toast > message error please correct the hilighted fields",
                            }),
                            level: "error",
                          });
                        }
                      }, 40);
                    }}
                  >
                    {getButtonText({ id: values.id })}
                  </Button>
                  <Button
                    color="blue"
                    outline
                    className={classnames(
                      "btn btn-radius",
                      bem.e("btn-cancel")
                    )}
                    onClick={rest.onCancel}
                  >
                    <FormattedMessage id={`prizes > button cancel`} />
                  </Button>
                </div>
                {!isLinkedToContests && values.id && (
                  <Button
                    color="blue"
                    outline
                    className={classnames(
                      "btn btn-radius",
                      bem.e("btn-delete")
                    )}
                    onClick={() => {
                      if (values.id) {
                        rest.setIsOpenConfirmationModal(true);
                      } else {
                        rest.onCancel();
                      }
                    }}
                  >
                    <FormattedMessage id={`prizes > button delete`} />
                  </Button>
                )}
              </div>
            </div>
          );
        };

        const tabs = [
          {
            tabKey: prizeFormTabs.audience.tabKey,
            title: intl.formatMessage({ id: "prizes > audience" }),
            content: renderAudience(),
          },
          {
            tabKey: prizeFormTabs["item-details"].tabKey,
            title: intl.formatMessage({ id: "prizes > item details" }),
            content: renderItemDetails(),
          },
        ];
        if (canItemize && isItemized) {
          tabs.push({
            tabKey: prizeFormTabs.itemize.tabKey,
            title: intl.formatMessage({ id: "prizes > itemize" }),
            content: renderItemize(),
          });
        }
        if (isAssigned) {
          tabs.push({
            tabKey: prizeFormTabs.allotment.tabKey,
            title: intl.formatMessage({ id: "prizes > allotment" }),
            content: renderAllotment(),
          });
        }
        tabs.push({
          tabKey: prizeFormTabs.fulfillment.tabKey,
          title: intl.formatMessage({ id: "prizes > fulfillment" }),
          content: renderFulfillment(),
        });

        const renderTabs = () => {
          return (
            <div className={bem.e("nav-wrapper")}>
              <Nav tabs className={bem.e("nav")}>
                {tabs.map((tab) => (
                  <NavItem
                    key={tab.tabKey}
                    className={"is-clickable"}
                    onClick={() => {
                      setActiveTab(tab.tabKey);
                    }}
                  >
                    <BSNavLink
                      className={classnames({
                        active: activeTab === tab.tabKey,
                      })}
                    >
                      {tab.title}
                    </BSNavLink>
                  </NavItem>
                ))}
              </Nav>
              <TabContent activeTab={activeTab}>
                {tabs.map((tab) => (
                  <TabPane key={tab.tabKey} tabId={tab.tabKey}>
                    {tab.content}
                  </TabPane>
                ))}
              </TabContent>
              {renderButtons()}
            </div>
          );
        };

        const renderPlainForm = () => (
          <>
            <div className={bem.e("content-form")}>
              {/* Audience */}
              {renderAudience()}
              {/* Item Details */}
              {renderItemDetails()}
              {/* Allotment */}
              {renderAllotment()}
              {/* Fulfillment */}
              {renderFulfillment()}
            </div>
            <div className={bem.e("buttons")}>{renderButtons()}</div>
          </>
        );

        return (
          <React.Fragment>
            <Form
              onSubmit={formProps.handleSubmit}
              noValidate
              className={bem.e("form")}
              onChange={() => {
                setShouldBlockNavigation(true);
              }}
            >
              {isSplitForm ? renderTabs() : renderPlainForm()}
            </Form>
            <LocationModal
              isOpen={rest.isOpenLocationModal}
              onToggle={() =>
                rest.setIsOpenLocationModal(!rest.isOpenLocationModal)
              }
              optionalFields={["contact", "phone"]}
              onSubmitted={(newLocation) => {
                forOwn(omit(newLocation, ["id", "name"]), (value, key) => {
                  let fieldValue = value;
                  if (key === "office_days") {
                    fieldValue = split(value, ",").map(Number);
                  }
                  formProps.setFieldValue(key, fieldValue);
                });
                formProps.setFieldValue("location", newLocation.id);
                rest.setIsOpenLocationModal(false);
              }}
            />
            <Spinner isLoading={isLoading} />
            <ConfirmationModal
              isOpen={rest.isOpenConfirmationModal}
              title={intl.formatMessage({
                id: "prizes > are you sure you want to delete this prize",
              })}
              onConfirm={rest.onDelete}
              onToggle={() => rest.setIsOpenConfirmationModal(false)}
              onCancel={() => rest.setIsOpenConfirmationModal(false)}
              isCloseOutside={false}
            />
          </React.Fragment>
        );
      }}
    </Formik>
  );
  if (isModal) return form;
  return (
    <React.Fragment>
      {form}
      <BlockingComponent
        isBlocked={shouldBlockNavigation}
        modalProps={{
          isShowConfirm: true,
          isShowDiscard: false,
          isCancelConfirmedNavigation: true,
          onCancel: () => {
            setShouldBlockNavigation(false); // reset
          },
          // onConfirm: () => {
          //   const buttonSubmit = document.getElementById(`btn-submit`);
          //   // trigger click to submit the form
          //   if (buttonSubmit) buttonSubmit.click();
          // },
        }}
      />
    </React.Fragment>
  );
};

export default function View(props) {
  const { isView } = props;
  const prizeProps = {
    ...props,
    checkCanItemize,
    bem,
  };
  return (
    <div className={bem.b()}>
      {!isView ? (
        <PrizeForm {...prizeProps} />
      ) : (
        <PrizeFormView {...prizeProps} />
      )}
    </div>
  );
}
