import React from "react";
import DaysOfWeek from "components/DaysOfWeek";
import DropdownLocation from "components/DropdownLocation";
import NumberFormat from "react-number-format";
import {
    get,
    map,
    forOwn,
    find,
    split,
} from "lodash";
import TextInput from "components/TextInput";
import { FormattedMessage } from "react-intl";
import Dropdown from "components/Dropdown";
import TextInputFormatted from "components/TextInputFormatted";
import { Row, Col, Label, FormGroup } from "reactstrap";
import {
    PhoneNumberFormat,
    statesList,
} from "utils/config";

const timeOpenOptions = map([...Array(12).keys()], (item) => {
    return {
        value: item + 1,
        label: `${item + 1} AM`,
    };
});
const timeCloseOptions = map([...Array(12).keys()], (item) => {
    return {
        value: item + 1,
        label: `${item + 1} PM`,
    };
});

export default function FulfillLocation(props) {
    const { bem, isView, serverCountry } = props;
    if (isView) {
        const { prize, locations } = props;
        const selectedLocation = find(
            locations,
            (item) => item.value === prize.location
        );
        const selectedState = find(
            statesList,
            (item) => item.value === prize.state
        );
        return (
            <div className={bem.e("fulfill-location")}>
                <Row>
                    <Col className={bem.e("col-reset")} xs={12}>
                        <FormGroup>
                            <Label>
                                <FormattedMessage id="prizes > winner instructions" />
                            </Label>
                            <div className={bem.e("prize-value-view")}>
                                {get(prize, "winner_instructions") ? (
                                    get(prize, "winner_instructions")
                                ) : (
                                    <FormattedMessage id="prizes > none" />
                                )}
                            </div>
                        </FormGroup>
                    </Col>
                    <Col className={bem.e("col-reset")} xs={6}>
                        <FormGroup>
                            <Label>
                                {prize.fulfillment === "will_call" ? (
                                    <FormattedMessage id="prizes > location/venue" />
                                ) : (
                                    <FormattedMessage id="prizes > location" />
                                )}{" "}
                            </Label>
                            <div className={bem.e("prize-value-view")}>
                                {get(selectedLocation, "label") ? (
                                    get(selectedLocation, "label")
                                ) : (
                                    <FormattedMessage id="prizes > none" />
                                )}
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className={bem.e("col-reset")}>
                        <FormGroup>
                            <Label>
                                <FormattedMessage id="prizes > contact" />
                            </Label>
                            <div className={bem.e("prize-value-view")}>
                                {get(prize, "contact") ? (
                                    get(prize, "contact")
                                ) : (
                                    <FormattedMessage id="prizes > none" />
                                )}
                            </div>
                        </FormGroup>
                    </Col>
                    <Col className={bem.e("col-reset")}>
                        <FormGroup>
                            <Label>
                                <FormattedMessage id="prizes > phone" />
                            </Label>
                            <div className={bem.e("prize-value-view")}>
                                {prize.phone ? (
                                    <NumberFormat
                                        value={prize.phone}
                                        displayType={"text"}
                                        format={PhoneNumberFormat}
                                    />
                                ) : (
                                    <FormattedMessage id="prizes > none" />
                                )}
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className={bem.e("col-reset")} xs={6}>
                        <FormGroup>
                            <Label>
                                <FormattedMessage id="prizes > address 1" />
                            </Label>
                            <div className={bem.e("prize-value-view")}>
                                {prize.add1 ? (
                                    prize.add1
                                ) : (
                                    <FormattedMessage id="prizes > none" />
                                )}
                            </div>
                        </FormGroup>
                    </Col>
                    <Col className={bem.e("col-reset")} xs={6}>
                        <FormGroup>
                            <Label>
                                <FormattedMessage id="prizes > address 2" />
                            </Label>
                            <div className={bem.e("prize-value-view")}>
                                {prize.add2 ? (
                                    prize.add2
                                ) : (
                                    <FormattedMessage id="prizes > none" />
                                )}
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className={bem.e("col-reset")} xs={6}>
                        <FormGroup>
                            <Label>
                                <FormattedMessage id="prizes > city" />
                            </Label>
                            <div className={bem.e("prize-value-view")}>
                                {prize.city ? (
                                    prize.city
                                ) : (
                                    <FormattedMessage id="prizes > none" />
                                )}
                            </div>
                        </FormGroup>
                    </Col>
                    <Col className={bem.e("col-reset")} xs={6}>
                        <Row>
                            <Col className={bem.e("col-reset")} xs={6}>
                                <Label>
                                    <FormattedMessage id={serverCountry === "CA" ? "prizes > province" : "prizes > state" }/>
                                </Label>
                                <div className={bem.e("prize-value-view")}>
                                    {selectedState ? (
                                        selectedState.label
                                    ) : (
                                        <FormattedMessage id="prizes > none" />
                                    )}
                                </div>
                            </Col>
                            <Col className={bem.e("col-reset")} xs={6}>
                                <FormGroup>
                                    <Label>
                                        <FormattedMessage id={serverCountry === "CA" ? "prizes > postal code" : "prizes > zip code" }/>
                                    </Label>
                                    <div className={bem.e("prize-value-view")}>
                                        {prize.zip ? (
                                            prize.zip
                                        ) : (
                                            <FormattedMessage id="prizes > none" />
                                        )}
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col className={bem.e("col-reset")} xs={6}>
                        <Row>
                            <Col xs={12} className={bem.e("col-reset")}>
                                <Label>
                                    <FormattedMessage id="prizes > office hours" />
                                </Label>
                            </Col>
                            <Col xs={5} className={bem.e("col-reset")}>
                                <FormGroup>
                                    <div className={bem.e("prize-value-view")}>
                                        {prize.time_open && prize.time_close ? (
                                            `${prize.time_open} AM to ${prize.time_close} PM`
                                        ) : (
                                            <FormattedMessage id="prizes > none" />
                                        )}
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                    <Col className={bem.e("col-reset")} xs={6}>
                        <FormGroup>
                            <div className={bem.e("prize-value-view")}>
                                <DaysOfWeek
                                    value={prize.office_days}
                                    label={<FormattedMessage id="prizes > office days" />}
                                    onChange={() => { }}
                                />
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        );
    }
    const { values, errors, touched, intl, locations } = props;
    const selectedState = find(statesList, (item) => item.value === values.state);
    return (
        <div className={bem.e("fulfill-location")}>
            <Row>
                <Col className={bem.e("col-reset")} xs={12}>
                    <FormGroup>
                        <TextInput
                            label={<FormattedMessage id="prizes > winner instructions" />}
                            required
                            type="textarea"
                            name="winner_instructions"
                            placeholder={intl.formatMessage({
                                id: "prizes > enter text",
                            })}
                            aria-multiline="true"
                            value={values.winner_instructions}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            error={touched.winner_instructions && errors.winner_instructions}
                        />
                    </FormGroup>
                </Col>
                <Col className={bem.e("col-reset")} xs={6}>
                    <FormGroup>
                        <DropdownLocation
                            label={
                                values.fulfillment === "will_call" ? (
                                    <FormattedMessage id="prizes > location/venue" />
                                ) : (
                                    <FormattedMessage id="prizes > location" />
                                )
                            }
                            placeholder={intl.formatMessage({
                                id: "prizes > select",
                            })}
                            name="location"
                            error={touched.location && errors.location}
                            onChange={(selectedOption) => {
                                if(props.setShouldBlockNavigation) {
                                    props.setShouldBlockNavigation(true);
                                }
                                props.setFieldValue("location", selectedOption.value);
                                forOwn(selectedOption.data, (value, key) => {
                                    let fieldValue = value;
                                    if (key === "office_days") {
                                        fieldValue = split(value, ",").map(Number);
                                    }
                                    props.setFieldValue(key, fieldValue);
                                });
                            }}
                            value={
                                find(locations, (item) => item.value === values.location) || ""
                            }
                            options={locations}
                            onAddNew={() => props.setIsOpenLocationModal(true)}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col className={bem.e("col-reset")}>
                    <FormGroup>
                        <TextInput
                            label={
                                <span>
                                    <FormattedMessage id="prizes > contact" />
                                </span>
                            }
                            type="text"
                            name="contact"
                            placeholder={intl.formatMessage({
                                id: "prizes > enter contact",
                            })}
                            value={values.contact}
                            error={touched.contact && errors.contact}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            disabled={!values.location}
                        />
                    </FormGroup>
                </Col>
                <Col className={bem.e("col-reset")}>
                    <FormGroup>
                        <TextInputFormatted
                            format={PhoneNumberFormat}
                            label={
                                <span>
                                    <FormattedMessage id="prizes > phone" />
                                </span>
                            }
                            type="text"
                            name="phone"
                            placeholder={intl.formatMessage({
                                id: "prizes > enter phone",
                            })}
                            value={values.phone}
                            error={touched.phone && errors.phone}
                            onChange={(value) => {
                                if(props.setShouldBlockNavigation) {
                                    props.setShouldBlockNavigation(true);
                                }
                                props.setFieldValue("phone", value);
                            }}
                            onBlur={props.handleBlur}
                            disabled={!values.location}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col className={bem.e("col-reset")} xs={6}>
                    <FormGroup>
                        <TextInput
                            label={<FormattedMessage id="prizes > address 1" />}
                            type="text"
                            name="add1"
                            placeholder={intl.formatMessage({
                                id: "prizes > enter address 1",
                            })}
                            value={values.add1}
                            error={touched.add1 && errors.add1}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            disabled={!values.location}
                        />
                    </FormGroup>
                </Col>
                <Col className={bem.e("col-reset")} xs={6}>
                    <FormGroup>
                        <TextInput
                            label={<FormattedMessage id="prizes > address 2" />}
                            type="text"
                            name="add2"
                            placeholder={intl.formatMessage({
                                id: "prizes > enter address 2",
                            })}
                            value={values.add2}
                            error={touched.add2 && errors.add2}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            disabled={!values.location}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col className={bem.e("col-reset")} xs={6}>
                    <FormGroup>
                        <TextInput
                            label={<FormattedMessage id="prizes > city" />}
                            type="text"
                            name="city"
                            placeholder={intl.formatMessage({
                                id: "prizes > enter city",
                            })}
                            value={values.city}
                            error={touched.city && errors.city}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            disabled={!values.location}
                        />
                    </FormGroup>
                </Col>
                <Col className={bem.e("col-reset")} xs={6}>
                    <Row>
                        <Col className={bem.e("col-reset")} xs={6}>
                            <Dropdown
                                label={<FormattedMessage id={serverCountry === "CA" ? "prizes > province" : "prizes > state" }/>}
                                name="state"
                                placeholder={intl.formatMessage({
                                    id: serverCountry === "CA" ? "prizes > enter province" : "prizes > enter state",
                                })}
                                value={selectedState}
                                error={touched.state && errors.state}
                                onChange={(option) => {
                                    if(props.setShouldBlockNavigation) {
                                        props.setShouldBlockNavigation(true);
                                    }
                                    props.setFieldValue("state", option.value);
                                }}
                                onBlur={props.handleBlur}
                                disabled={!values.location}
                                options={statesList}
                            />
                        </Col>
                        <Col className={bem.e("col-reset")} xs={6}>
                            <FormGroup>
                                <TextInput
                                    label={<FormattedMessage id={serverCountry === "CA" ? "prizes > postal code" : "prizes > zip code"} />}
                                    type="text"
                                    name="zip"
                                    placeholder={intl.formatMessage({
                                        id: serverCountry === "CA" ? "prizes > enter postal code" : "prizes > enter zip code",
                                    })}
                                    value={values.zip}
                                    error={touched.zip && errors.zip}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    disabled={!values.location}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col className={bem.e("col-reset")} xs={6}>
                    <Row>
                        <Col xs={12} className={bem.e("col-reset")}>
                            <Label>
                                <FormattedMessage id="prizes > office hours" />
                            </Label>
                        </Col>
                        <Col xs={5} className={bem.e("col-reset")}>
                            <FormGroup>
                                <Dropdown
                                    value={
                                        values.time_open
                                            ? {
                                                value: values.time_open,
                                                label: `${values.time_open} AM`,
                                            }
                                            : ""
                                    }
                                    placeholder={intl.formatMessage({
                                        id: "prizes > select start time",
                                    })}
                                    onChange={(selectedOption) => {
                                        if(props.setShouldBlockNavigation) {
                                            props.setShouldBlockNavigation(true);
                                        }
                                        props.setFieldValue("time_open", selectedOption.value);
                                    }}
                                    options={timeOpenOptions}
                                    name="time_open"
                                    error={touched.time_open && errors.time_open}
                                    disabled={!values.location}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={2} className={bem.e("col-reset")}>
                            <Label className={bem.e("to-title")}>
                                <FormattedMessage id="prizes > to" />
                            </Label>
                        </Col>
                        <Col xs={5} className={bem.e("col-reset")}>
                            <FormGroup>
                                <Dropdown
                                    value={
                                        values.time_close
                                            ? {
                                                value: values.time_close,
                                                label: `${values.time_close} PM`,
                                            }
                                            : ""
                                    }
                                    placeholder={intl.formatMessage({
                                        id: "prizes > select end time",
                                    })}
                                    onChange={(selectedOption) => {
                                        if(props.setShouldBlockNavigation) {
                                            props.setShouldBlockNavigation(true);
                                        }
                                        props.setFieldValue("time_close", selectedOption.value);
                                    }}
                                    options={timeCloseOptions}
                                    name="time_close"
                                    error={touched.time_close && errors.time_open}
                                    disabled={!values.location}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Col className={bem.e("col-reset")} xs={6}>
                        <FormGroup>
                            <DaysOfWeek
                                value={values.office_days}
                                label={<FormattedMessage id="prizes > office days" />}
                                onChange={(days) => {
                                    if(props.setShouldBlockNavigation) {
                                        props.setShouldBlockNavigation(true);
                                    }
                                    props.setFieldValue("office_days", days);
                                }}
                                disabled={!values.location}
                            />
                            {touched.office_days && errors.office_days && (
                                <span className="alert-danger">
                                    {touched.office_days && errors.office_days}
                                </span>
                            )}
                        </FormGroup>
                    </Col>
                </Col>
            </Row>
        </div>
    );
}