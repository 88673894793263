import { get, map, isFunction, includes, filter, find } from "lodash";
import { connect } from "react-redux";
import * as React from "react";
import { compose, lifecycle, withHandlers, withState } from "recompose";
import { addStation, getStation, updateStation } from "store/actions/stations";
import { getAllServers } from "store/actions/servers";
import { injectIntl } from "react-intl";
import ToastManager from "components/ToastManager";
import { MdImportantDevices } from "react-icons/lib/md";
import {
  TAG_TYPE_DMAs,
  TAG_TYPE_LOCATION,
  TAG_TYPE_FORMAT,
  TAG_TYPE_EXCEPT_OTHER,
} from "components/SelectTags";
import StationFrom, { MODE_EDIT, MODE_VIEW, MODE_ADD } from "./view";
export { MODE_EDIT, MODE_VIEW, MODE_ADD };

function getTagOptionsByType(type, auth) {
  let tagOptions = [];
  let tags = get(auth, "info.tags.tags", []);
  let showRoots = true;
  switch (type) {
    case TAG_TYPE_LOCATION:
      tags = filter(tags, (tag) => includes(tag, "Location"));
      showRoots = false;
      break;
    case TAG_TYPE_FORMAT:
      tags = filter(tags, (tag) => includes(tag, "Format"));
      showRoots = false;
      break;
    case TAG_TYPE_DMAs:
      tags = filter(tags, (tag) => includes(tag, "DMAs"));
      showRoots = false;
      break;
    case TAG_TYPE_EXCEPT_OTHER:
      tags = filter(
        tags,
        (tag) =>
          !includes(tag, "Format") &&
          !includes(tag, "Location") &&
          !includes(tag, "DMAs")
      );
      break;
    default:
      break;
  }
  let map_tags = (tag, path) => {
    tagOptions.push({
      value: tag.id,
      label: path,
    });
    tag.children &&
      tag.children.map((child, index) => {
        let childPath = path ? path + " / " + child.title : child.title;
        map_tags(child, childPath);
      });
  };
  tags.map((tag) => {
    map_tags(tag, showRoots ? tag.title : false);
  });
  return tagOptions;
}
function getPrivilegesByUser(auth) {
  let privileges = [];
  const userPrivileges = get(auth, "info.privileges", {});
  for (let key in userPrivileges) {
    const priv = userPrivileges[key];
    if (priv !== "add_stations" && priv !== "delete_stations") {
      privileges.push({
        id: key,
        name: priv,
      });
    }
  }
  return privileges;
}

function getAlreadySelectedPrivileges(props) {
  let alreadySelectedPrivileges = [];
  const stationData = get(props, "station.data", {});

  get(stationData, "privileges", []).forEach((item) => {
    const privilegeData = find(
      props.privileges,
      (privilege) => parseInt(privilege.id) === parseInt(item.privileges_id)
    );

    if (
      privilegeData &&
      privilegeData.name !== "add_stations" &&
      privilegeData.name !== "delete_stations"
    ) {
      alreadySelectedPrivileges.push({
        value: privilegeData.id,
        label: privilegeData.name,
      });
    }
  });
  return alreadySelectedPrivileges;
}
function getServersList(props) {
  let serverList = [];
  if (get(props, "auth.user.is_god_admin")) {
    serverList = map(props.servers, (server) => ({
      value: server.id,
      label: server.name,
    }));
  }
  return serverList;
}

export default injectIntl(
  compose(
    connect(
      (state, props) => {
        const { match } = props;
        let id = get(match, "params.id");
        if (props.id) id = props.id;
        const timeOpenOptions = map([...Array(12).keys()], (item) => {
          return {
            value: item + 1,
            label: `${item + 1} am`,
          };
        });
        const timeCloseOptions = map([...Array(12).keys()], (item) => {
          return {
            value: item + 1,
            label: `${item + 1} pm`,
          };
        });
        const locationOptions = getTagOptionsByType(
          TAG_TYPE_DMAs,
          get(state, "auth")
        );
        const formatOptions = getTagOptionsByType(
          TAG_TYPE_FORMAT,
          get(state, "auth")
        );
        const categoryTagOptions = getTagOptionsByType(
          TAG_TYPE_EXCEPT_OTHER,
          get(state, "auth")
        );
        const privileges = getPrivilegesByUser(get(state, "auth"));

        const stationPrivilegesOption = (privileges || []).map((privilege) => ({
          value: privilege.id,
          label: privilege.name,
        }));
        return {
          id,
          isLoading:
            get(state, "stations.addStation.loading") ||
            get(state, "stations.updateStation.loading") ||
            get(state, `stations.station.${id}.loading`),
          isUpdate: id ? true : false,
          station: get(state, `stations.station.${id}`, {}),
          servers: get(state, "servers.allServers.data", []),
          currentServer: get(state, "servers.currentServer.data", {}),
          auth: get(state, "auth"),
          timeCloseOptions: timeCloseOptions,
          timeOpenOptions: timeOpenOptions,
          categoryTagOptions: categoryTagOptions,
          formatOptions: formatOptions,
          locationOptions: locationOptions,
          authIsBroadCastingGroupAdmin: get(
            state,
            "auth.user.broadcasting_group_admin"
          ),
          authIsGodAdmin: get(state, "auth.user.is_god_admin"),
          privileges: privileges,
          stationPrivilegesOption: stationPrivilegesOption,
        };
      },
      {
        addStation,
        updateStation,
        getStation,
        getAllServers,
      }
    ),
    withState("isOpenAlert", "setIsOpenAlert", false),
    withState("isOpenTagFormModal", "setIsOpenTagFormModal", false),
    withState("tagFormType", "setTagFormType", null),
    withState("shouldBlockNavigation", "setShouldBlockNavigation", false),
    withState("isViewMode", "setIsViewMode", (props) => get(props, "isView")),
    withState("serverList", "setServerList", (props) => getServersList(props)),
    withState("isOpenEditLogoModal", "setIsOpenEditLogoModal", false),
    withState("isOpenPreviewFileModal", "setIsOpenPreviewFileModal", false),
    withState(
      "alreadySelectedPrivileges",
      "setAlreadySelectedPrivileges",
      (props) => getAlreadySelectedPrivileges(props)
    ),
    withHandlers({
      onFormSubmit: ({
        addStation,
        updateStation,
        isUpdate,
        id,
        onBack,
        intl,
      }) => (values) => {
        const stationPrivileges = get(values, "station_privileges", [])
          .map((item) => {
            if (item) return item.value;
            return false;
          })
          .join(",");
        const logo = get(values, "logo.path", "");
        const tagsJson = [
          ...map(get(values, "format", []), (item) => ({
            id: item.value,
            title: item.label,
            type: "format",
          })),
          ...map(get(values, "dmas", []), (item) => ({
            id: item.value,
            title: item.label,
            type: "dmas",
          })),
          ...map(get(values, "categories", []), (item) => ({
            id: item.value,
            title: item.label,
            type: "categories",
          })),
        ];
        const dataToSend = {
          ...values,
          logo,
          categories: map(get(values, "categories", []), (item) => item.value),
          format: map(get(values, "format", []), (item) => item.value),
          tags: tagsJson,
          tags_string: tagsJson.map((item) => item.title).join(","),
          dmas: map(get(values, "dmas", []), (item) => item.value),
          time_open: get(values, "time_open.value"),
          time_close: get(values, "time_close.value"),
          office_days: get(values, "office_days", []).join(","),
          station_privileges: stationPrivileges,
        };
        if (isUpdate) {
          updateStation(id, dataToSend, () => {
            ToastManager.show({
              title: <MdImportantDevices />,
              message: intl.formatMessage({
                id: "station > update successfully",
              }),
              level: "success",
            });
            if (isFunction(onBack)) onBack();
          });
        } else {
          addStation(dataToSend, () => {
            ToastManager.show({
              title: <MdImportantDevices />,
              message: intl.formatMessage({
                id: "station > create successfully",
              }),
              level: "success",
            });
            if (isFunction(onBack)) onBack();
          });
        }
      },
    }),
    lifecycle({
      componentDidMount() {
        const { match, auth } = this.props;
        let id = get(match, "params.id");
        if (this.props.id) id = this.props.id;
        if (id) {
          this.props.getStation(id);
        }
        if (get(auth, "user.is_god_admin")) {
          this.props.getAllServers();
        }
      },
      componentDidUpdate(prevProps) {
        if (get(this.props, "servers") !== get(prevProps, "servers")) {
          this.props.setServerList(getServersList(this.props));
        }
        if (
          get(this.props, "station.data.privileges") !==
          get(prevProps, "station.data.privileges")
        ) {
          this.props.setAlreadySelectedPrivileges(
            getAlreadySelectedPrivileges(this.props)
          );
        }
      },
    })
  )(StationFrom)
);
