import { FormattedMessage } from "react-intl";
import React from "react";
const linerSchedule = {
  process_step_index: 3,
  key: "liner_schedule",
  caption_color: "#CC1EE6",
  title: <FormattedMessage id="process > title liner schedule" />,
  step_roles: [1, 2, 3],
  step_viewers_roles: [1, 2, 3, 4, 7],
  step_editors_roles: [1, 2, 3, 4],
  step_viewers_privileges: ["add_promotion_liner_schedule"],
  step_editors_privileges: ["add_promotion_liner_schedule"],
  description: null,
  status: "initial",
  disabled_until_completed_steps: [],
  step_container: "none",
  no_edit_button: true,
  fields: [
    {
      component: "liner_schedule",
    },
    {
      field: "liner_schedule",
      title: <FormattedMessage id="process > field prizes" />,
    },
  ],
};
export default linerSchedule;
