import * as Types from "store/types/clients";
import { get, map, isFunction, delay } from "lodash";
// import history from "components/History";
import Api from "utils/api";
import * as Const from "utils/constants";
// import URL from "utils/urls";
import queryString from "query-string";
import { SET_MESSAGES } from "../types/messages";
export const getClients = (state) => async (dispatch) => {
  let errorStr = "";
  try {
    const { pageSize, page, sorted, filtered } = state;

    const params = {
      page: page,
      rpp: pageSize,
    };
    if (sorted.length > 0) {
      const sortedColumns = map(sorted, "id").join(",");
      params.sort = `${sortedColumns} ${
        get(sorted, `${sorted.length - 1}.desc`) ? "desc" : "asc"
      }`;
    }

    const index = filtered.findIndex((item) => item.id === "q");

    if (index !== -1) {
      params.q = filtered[index].value;
    }

    dispatch({ type: Types.FETCH_CLIENTS_REQUEST });

    let response = await Api.doCall(
      `${Const.GET_CLIENTS()}?${queryString.stringify(params)}`,
      "GET"
    );

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.FETCH_CLIENTS_SUCCESS,
          payload: response.data,
        });
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({
    type: Types.FETCH_CLIENTS_ERROR,
    payload: { data: errorStr },
  });
};

export const getClient = (id) => async (dispatch) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.FETCH_CLIENT_REQUEST, payload: { id } });

    let response = await Api.doCall(Const.GET_CLIENT(id), "GET");

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.FETCH_CLIENT_SUCCESS,
          payload: response.data,
        });
        if (response.data.messages) {
          const messages = response.data.messages.map((m) => ({
            message: m._source,
            m_id: m._id,
          }));
          dispatch({
            type: SET_MESSAGES,
            payload: { messages },
          });
        }
        return;
      }

      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({
    type: Types.FETCH_CLIENT_ERROR,
    payload: { id, error: errorStr },
  });
};

export const createClient = (data, cb) => async (dispatch) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.CREATE_CLIENT_REQUEST });

    let response = await Api.doCall(Const.ADD_CLIENT(), "POST", data);

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.CREATE_CLIENT_SUCCESS,
          payload: response.data,
        });
        delay(() => {
          dispatch(getClient(response.data.id));
        }, 1000);
        if (isFunction(cb)) {
          cb(response.data);
        }

        return;
      }

      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({ type: Types.CREATE_CLIENT_ERROR, payload: errorStr });
};
export const updateClient = (id, data, cb) => async (dispatch) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.UPDATE_CLIENT_REQUEST });

    let response = await Api.doCall(Const.GET_CLIENT(id), "PUT", data);

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.UPDATE_CLIENT_SUCCESS,
          payload: {
            id,
            data: response.data,
          },
        });
        delay(() => {
          dispatch(getClient(id));
        }, 1000);
        if (isFunction(cb)) {
          cb(response.data);
        }

        return;
      }

      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({ type: Types.UPDATE_CLIENT_ERROR, payload: errorStr });
};

export const reassignClients = (payload, cb) => async (dispatch) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.REASSIGN_CLIENTS_REQUEST, payload });

    let response = await Api.doCall(Const.REASSIGN_CLIENTS(), "POST", payload);

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.REASSIGN_CLIENTS_SUCCESS,
        });
        if (isFunction(cb)) {
          cb(response.data);
        }
        return;
      }

      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({
    type: Types.REASSIGN_CLIENTS_ERROR,
    payload: errorStr,
  });
};

export const deleteClient = (payload, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.DELETE_CLIENT_REQUEST, payload });
    let response = await Api.doCall(Const.GET_CLIENT(payload._id), "DELETE");
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.DELETE_CLIENT_SUCCESS,
        });
        if (isFunction(cb)) {
          cb(response.data);
        }
        return;
      }

      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({
    type: Types.DELETE_CLIENT_ERROR,
    payload: errorStr,
  });
};

export const sendMessage = (id, data, cb) => async (dispatch) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.SEND_MESSAGE_CLIENT_REQUEST });
    const mId = ("" + Math.random()).replace("0.", "");
    const now = Math.floor(new Date().getTime() / 1000);
    dispatch({
      type: SET_MESSAGES,
      payload: {
        messages: [
          {
            m_id: mId,
            message: {
              ...data.message,
              m_type: data.m_type || "chat",
              process_key: "current",
              created_ts: now,
            },
          },
        ],
      },
    });
    let response = await Api.doCall(Const.MESSAGE_CLIENT(id), "PUT", data);

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.SEND_MESSAGE_CLIENT_SUCCESS,
        });
        delay(() => {
          dispatch(getClient(id));
        }, 1000);
        return;
      }

      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({ type: Types.SEND_MESSAGE_CLIENT_ERROR, payload: errorStr });
};

export const setClient = (data) => async (dispatch) => {
  dispatch({ type: Types.SET_CLIENT, payload: data });
};

export const getDefaultApprovalContacts = (id, cb) => async () => {
  try {
    let response = await Api.doCall(
      `${Const.GET_CLIENT(id)}/approval_contacts`,
      "GET"
    );

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        cb(response.data);
        return;
      }
    }
  } catch (error) {}
  cb();
};
