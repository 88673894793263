import React from "react";
import bn from "utils/bemnames";
import { get } from "lodash";
import AdminBackgroundImage from "assets/img/profile_background_admin.png";
const bem = bn.create("admin-card");

export default function AdminCard(props) {
  const { auth, isShowTitle, isShowLogo = true } = props;
  return (
    <div className={bem.b()}>
      <div
        className={bem.e("background-swirl")}
        style={{
          backgroundImage: `url(${AdminBackgroundImage})`,
        }}
      />
      {isShowLogo && get(auth, "info.server_logo") && (
        <div className={bem.e("server-image")}>
          <img src={get(auth, "info.server_logo")} alt="" />
        </div>
      )}
      {
        isShowTitle && (
          <div className={bem.e("server-text")}>
            {get(auth, "info.server_name")}
          </div>
        )
      }

    </div>
  );
}
AdminCard.defaultProps = {
  isShowTitle: true
}