import React, { useState } from "react";
import { Row, Col, FormGroup, Label } from "reactstrap";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { get } from "lodash";
import { setScriptField } from "utils/helpers";
import TextareaEvaluator from "components/TextareaEvaluator";
import { ShadowBox, SectionTitle, SectionFields } from "components/Elements";
import { DownIcon, UpIcon } from "components/CustomIcons";
import { contestScriptBeforeTags, contestScriptAfterTags } from "utils/config";
import ToastManager from "components/ToastManager";
const ContestScript = (props) => {
  const {
    validationState,
    process,
    bem,
    step,
    onValueChanged,
    intl,
    isView,
    openDetails,
    setOpenDetails,
    setDefaultJockIntroductionsText,
  } = props;
  const [fieldRefs, setFieldRefs] = useState(null);
  const getFieldRefs = () => {
    if (fieldRefs) return fieldRefs;

    const script_before = step.fields.find((f) => f.field === "script_before");
    const script_after = step.fields.find((f) => f.field === "script_after");
    const jock_instructions = step.fields.find(
      (f) => f.field === "jock_instructions"
    );

    const payload = {
      script_before,
      script_after,
      jock_instructions,
    };

    setFieldRefs(payload);

    return payload;
  };

  const { script_before, script_after, jock_instructions } = getFieldRefs();
  const jockInstructionsText = get(
    process,
    "data.fields.jock_instructions.value",
    ""
  );
  const isEmptyIntroText =
    jockInstructionsText === "<p><br></p>" || !jockInstructionsText;
  const isOpen = get(openDetails, "script", false);
  return (
    <ShadowBox className={bem.e("box-script-details")}>
      <SectionTitle
        className={bem.e("contest-script-section-title")}
        onClick={() =>
          setOpenDetails({
            ...openDetails,
            script: !isOpen,
          })
        }
      >
        <FormattedMessage id="process > promotion > contest > title script details" />
        <span className={bem.e("box-action")}>
          {isOpen ? <UpIcon /> : <DownIcon />}
        </span>
      </SectionTitle>
      {isOpen && (
        <React.Fragment>
          <SectionFields
            className={classnames(
              bem.e("section-fields"),
              bem.e("contest-script-section-fields")
            )}
          >
            {/* render view */}
            {isView ? (
              <React.Fragment>
                <Row>
                  <Col xs={12} className={bem.e("col-reset")}>
                    <FormGroup>
                      <span className={bem.e("script-subtitle")}>
                        <FormattedMessage id="process > script" />
                      </span>
                      <div
                        className={classnames(
                          bem.e("order-view-value"),
                          bem.e("script-view-value")
                        )}
                      >
                        <TextareaEvaluator
                          readOnly={true}
                          title={
                            <FormattedMessage id="process > promotion > contest > before winner selection" />
                          }
                          text={setScriptField(
                            get(process, "data.fields.script_before.value", "")
                          )}
                          orderLengthText={
                            <FormattedMessage id="process > promotion > contest > length" />
                          }
                          hideOrderLength={true}
                          onEvaluatorCallback={() => {}}
                          hideHelperText={true}
                          showInnerTitle
                          isShowWordCounting
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col xs={12} className={bem.e("col-reset")}>
                    <FormGroup>
                      <span className={bem.e("script-subtitle")}>
                        <FormattedMessage id="process > script" />
                      </span>
                      <div
                        className={classnames(
                          bem.e("order-view-value"),
                          bem.e("script-view-value")
                        )}
                      >
                        <TextareaEvaluator
                          readOnly={true}
                          title={
                            <FormattedMessage id="process > promotion > contest > after winner selection" />
                          }
                          text={setScriptField(
                            get(process, "data.fields.script_after.value", "")
                          )}
                          orderLengthText={
                            <FormattedMessage id="process > promotion > contest > length" />
                          }
                          hideOrderLength={true}
                          onEvaluatorCallback={() => {}}
                          hideHelperText={true}
                          showInnerTitle
                          isShowWordCounting
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Row>
                  <Col xs={12} className={bem.e("reset-col")}>
                    <FormGroup>
                      <span className={bem.e("script-subtitle")}>
                        <FormattedMessage id="process > script" />
                      </span>
                      <TextareaEvaluator
                        text={setScriptField(
                          get(process, "data.fields.script_before.value", "")
                        )}
                        title={
                          <FormattedMessage id="process > promotion > contest > before winner selection" />
                        }
                        orderLengthText={
                          <FormattedMessage id="process > promotion > contest > length" />
                        }
                        placeholder={intl.formatMessage({
                          id: "process > enter script",
                        })}
                        hideOrderLength={true}
                        tags={contestScriptBeforeTags}
                        onEvaluatorCallback={(value) => {
                          onValueChanged(script_before, value);
                        }}
                        showInnerTitle
                        hideHelperText={true}
                        error={get(
                          validationState,
                          "script_before.validation_error"
                        )}
                        isShowWordCounting
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className={bem.e("reset-col")}>
                    <FormGroup>
                      <span className={bem.e("script-subtitle")}>
                        <FormattedMessage id="process > script" />
                      </span>
                      <TextareaEvaluator
                        text={setScriptField(
                          get(process, "data.fields.script_after.value", "")
                        )}
                        title={
                          <FormattedMessage id="process > promotion > contest > after winner selection" />
                        }
                        orderLengthText={
                          <FormattedMessage id="process > promotion > contest > length" />
                        }
                        hideOrderLength={true}
                        placeholder={intl.formatMessage({
                          id: "process > enter script",
                        })}
                        tags={contestScriptAfterTags}
                        onEvaluatorCallback={(value) => {
                          onValueChanged(script_after, value);
                        }}
                        showInnerTitle
                        hideHelperText={true}
                        error={get(
                          validationState,
                          "script_after.validation_error"
                        )}
                        isShowWordCounting
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </SectionFields>
          <div className={bem.e("line")} />
          {/* jock instructions */}
          <SectionFields
            className={classnames(
              bem.e("section-fields"),
              bem.e("contest-script-section-fields"),
              bem.e("contest-script-section-jock-fields")
            )}
          >
            <Row>
              <Col className={bem.e("reset-col")}>
                {isView ? (
                  <FormGroup>
                    <Label>{jock_instructions.title}</Label>
                    <div className={bem.e("order-view-value")}>
                      <TextareaEvaluator
                        readOnly={true}
                        text={setScriptField(isEmptyIntroText)}
                        placeholder={intl.formatMessage({
                          id: "process > enter here",
                        })}
                        hideOrderLength={true}
                        hideHelperText={true}
                        onEvaluatorCallback={() => {}}
                        showInnerTitle
                      />
                    </div>
                  </FormGroup>
                ) : (
                  <FormGroup
                    className={classnames(bem.e("form-section-jock"), {
                      [bem.e("introduction-field-empty")]: isEmptyIntroText,
                    })}
                  >
                    <TextareaEvaluator
                      text={setScriptField(jockInstructionsText)}
                      title={jock_instructions.title}
                      onSetAsDefault={() => {
                        if (isEmptyIntroText) return;
                        ToastManager.show({
                          title: intl.formatMessage({
                            id: "toast > title added",
                          }),
                          level: "success",
                        });
                        setDefaultJockIntroductionsText({
                          [props.user.id]: jockInstructionsText,
                        });
                      }}
                      placeholder={intl.formatMessage({
                        id: "process > enter here",
                      })}
                      onEvaluatorCallback={(value) => {
                        onValueChanged(jock_instructions, value);
                      }}
                      showScriptLength={false}
                      hideHelperText={true}
                      showInnerTitle
                      error={get(
                        validationState,
                        "jock_instructions.validation_error",
                        ""
                      )}
                    />
                  </FormGroup>
                )}
              </Col>
            </Row>
          </SectionFields>
        </React.Fragment>
      )}
    </ShadowBox>
  );
};

export default ContestScript;
