import React, { useState } from "react";
import bn from "utils/bemnames";
import { includes, orderBy, get, filter } from "lodash";
import moment from "moment";

import Buttons from "./Buttons";
import AudienceDetails from "./AudienceDetails";
import ItemAvailability from "./ItemAvailability";
import ItemDetails from "./ItemDetails";
import ItemScheduling from "./ItemScheduling";
import Associated from "components/PromotionContest/Common/ContestAssociated";
import classNames from "classnames";

const bem = bn.create("promotion-liner-details");

export default function PromotionLinerDetails(props) {
  const [fieldRefs, setFieldRefs] = useState(null);
  const {
    step,
    process,
    template,
    user,
    auth,
    validationState,
    valueChanged,
    intl,
  } = props;
  const isView = props.mode === "view";
  // const isNew = window.location.href.indexOf("add") !== -1;

  const stations = filter(auth.info.stations, (station) => station.station_key);

  const getFieldRefs = () => {
    if (fieldRefs) return fieldRefs;
    const select_stations = step.fields.find(
      (f) => f.field === "order_stations"
    );
    const timezone = step.fields.find((f) => f.field === "timezone");

    const payload = {
      select_stations,
      timezone,
    };

    setFieldRefs(payload);

    return payload;
  };

  const onValueChanged = (
    field,
    value,
    restProps = {
      shouldBlockNavigation: true,
    }
  ) => {
    const ignoreFields = ["relax_restrictions_for_weeks"];
    if (!includes(ignoreFields, field.field)) {
      props.setShouldBlockNavigation(restProps.shouldBlockNavigation);
    }
    valueChanged(field, process, template, user, { value });
  };

  const onValueTimezoneChanged = (orderStations) => {
    const { timezone } = getFieldRefs();
    let timezoneValue;
    if (orderStations.length === 1) {
      const selectStation = orderStations[0];
      const stationWithData = stations.find(
        (item) => item.station_key === selectStation
      );
      if (stationWithData) {
        timezoneValue = stationWithData.timezone;
      }
    } else {
      let listTimezone = [];
      stations.forEach((item) => {
        if (
          includes(orderStations, item.station_key) &&
          item.timezone &&
          !includes(listTimezone, item.timezone)
        ) {
          const gmt = moment.tz(item.timezone).format("Z");
          listTimezone.push({
            key: parseInt(gmt),
            gmt: gmt,
            timezone: item.timezone,
          });
        }
      });
      listTimezone = orderBy(listTimezone, ["key"], ["desc"]);
      timezoneValue = get(listTimezone, `0.timezone`);
    }
    if (!timezoneValue) {
      timezoneValue = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    onValueChanged(timezone, timezoneValue, {
      shouldBlockNavigation: false,
    });
  };

  const [
    isOpenDeleteConfirmationModal,
    setIsOpenDeleteConfirmationModal,
  ] = useState(false);
  const orderEndDate = get(process, "data.fields.order_end_date.value", "");
  let isShowItemScheduling = true;
  if (orderEndDate && moment(orderEndDate).isBefore(moment(), "day")) {
    isShowItemScheduling = false;
  }
  return (
    <div
      className={classNames(bem.b(), {
        [bem.m("edit")]: !isView,
        [bem.m("view")]: isView,
      })}
    >
      <AudienceDetails
        bem={bem}
        process={process}
        template={template}
        user={user}
        auth={auth}
        step={step}
        intl={intl}
        stations={stations}
        isView={isView}
        onValueTimezoneChanged={onValueTimezoneChanged}
        validationState={validationState}
        onValueChanged={onValueChanged}
        onGetClocksData={props.onGetClocksData}
        setShowItemAvailability={props.setShowItemAvailability}
      />
      <ItemAvailability
        onCheckItemsAvailability={props.onCheckItemsAvailability}
        isView={isView}
        bem={bem}
        process={process}
        showItemAvailability={props.showItemAvailability}
        setShowItemAvailability={props.setShowItemAvailability}
      />
      <ItemDetails
        bem={bem}
        process={process}
        template={template}
        user={user}
        auth={auth}
        step={step}
        intl={intl}
        isView={isView}
        validationState={validationState}
        onValueChanged={onValueChanged}
      />
      {isShowItemScheduling && (
        <ItemScheduling
          bem={bem}
          process={process}
          template={template}
          user={user}
          auth={auth}
          step={step}
          intl={intl}
          isView={isView}
          validationState={validationState}
          onValueChanged={onValueChanged}
          buttonClicked={props.buttonClicked}
          onCheckSaveDraft={props.onCheckSaveDraft}
        />
      )}

      <Associated
        sectionKey={"associated"}
        process={process}
        template={template}
        user={user}
        auth={auth}
        step={step}
        intl={intl}
        isView={isView}
        validationState={validationState}
        onValueChanged={onValueChanged}
        buttonClicked={props.buttonClicked}
        openDetails={props.openDetails}
        setOpenDetails={props.setOpenDetails}
      />
      <Buttons
        buttonClicked={props.buttonClicked}
        step={step}
        process={process}
        template={template}
        user={user}
        isView={isView}
        bem={bem}
        onValueChanged={onValueChanged}
        isOpenDeleteConfirmationModal={isOpenDeleteConfirmationModal}
        onDeleteCancel={() => setIsOpenDeleteConfirmationModal(false)}
        onDeleteConfirm={() => {}}
        setValidationError={props.setValidationError}
      />
    </div>
  );
}
