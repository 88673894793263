import React, { useState } from "react";
import AutocompleteInput from "components/AutocompleteInput";
import { debounce, get, map } from "lodash";
import bn from "utils/bemnames";
import PropTypes from "prop-types";
import { SearchSmallIcon, CloseIcon } from "components/CustomIcons";
import Tooltip from "rc-tooltip";
import { FormattedMessage } from "react-intl";
const bem = bn.create("search-autocomplete-input");
function ItemTooltip(props) {
  const { isHighlighted, item } = props;
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div
      className={`item ${isHighlighted ? "item-highlighted" : ""}`}
      key={item.id}
    >
      <Tooltip
        placement="top"
        overlayClassName={"search-input-tooltip"}
        trigger={["hover"]}
        destroyTooltipOnHide
        visible={isOpen}
        onVisibleChange={(visible) => setIsOpen(visible)}
        overlay={<span className={"tooltip-title"}>{item.title}</span>}
      >
        <span
          className={bem.e("item-title")}
          onMouseLeave={() => setIsOpen(false)}
        >
          {item.title}
        </span>
      </Tooltip>
      <span className={bem.e("item-subtitle")}>{item.type}</span>
    </div>
  );
}
export default function SearchInput(props) {
  const {
    searchCallback,
    onSelect,
    value,
    onChange,
    isShowSavedSearches,
    savedSearches,
    onSelectSavedSearch,
    isShowMenuItems,
    onSearchClicked,
    onClearSearch,
  } = props;
  const [items, setItems] = useState([]);
  const onSearch = debounce((value) => {
    // only search > 1 characters
    if (!value || value.length < 2) {
      setItems([]);
    } else {
      searchCallback({
        q: value,
        cb: (items) => {
          setItems(items);
        },
      });
    }
  }, 300);
  return (
    <div className={bem.b()}>
      <AutocompleteInput
        label={null}
        isRequired={false}
        value={value}
        items={items}
        getItemValue={(item) => get(item, props.itemValueKey)}
        inputProps={{
          placeholder: props.placeholder || "",
          onKeyDown: (e) => {
            if (e.key === "Enter") {
              const newValue = e.target.value;
              onChange(newValue);
              onSearch(newValue);
              if (onSearchClicked) onSearchClicked();
            }
          },
        }}
        renderInput={(inputProps) => {
          return (
            <div className={bem.e("search-input-wrapper")}>
              <input {...inputProps} />
              {value && (
                <span
                  className={bem.e("clear-icon")}
                  onClick={() => {
                    onSearch("");
                    onChange("");
                    onSelect("", {}); // reset
                    onClearSearch();
                  }}
                >
                  <CloseIcon color="#C2D4E0" />
                </span>
              )}
              <span
                className={bem.e("search-icon")}
                onClick={() => onSearchClicked()}
              >
                <SearchSmallIcon />
              </span>
            </div>
          );
        }}
        renderMenu={(items) => {
          return (
            <div
              className={items.length > 0 || isShowSavedSearches ? "menu" : ""}
            >
              {isShowMenuItems ? items : null}
              {isShowSavedSearches && (
                <div className={bem.e("saved-searches")}>
                  <span className={bem.e("saved-search-title")}>
                    <FormattedMessage id="save search > saved searches" />
                  </span>
                  <div className={bem.e("saved-search-list")}>
                    {map(savedSearches, (item, index) => (
                      <span
                        key={index}
                        onClick={() => {
                          setItems([]);
                          onSelectSavedSearch(item);
                        }}
                      >
                        {item.title}
                      </span>
                    ))}
                  </div>
                </div>
              )}
            </div>
          );
        }}
        renderItem={(item, isHighlighted) => {
          if (item.title && item.title.length < 24)
            return (
              <div
                className={`item ${isHighlighted ? "item-highlighted" : ""}`}
                key={item.id}
              >
                <span className={bem.e("item-title")}>{item.title}</span>
                <span className={bem.e("item-subtitle")}>{item.type}</span>
              </div>
            );
          return <ItemTooltip item={item} isHighlighted={isHighlighted} />;
        }}
        onSelect={onSelect}
        onChange={(e, value) => {
          onChange(value);
          onSearch(value);
        }}
      />
    </div>
  );
}
SearchInput.propTypes = {
  searchCallback: PropTypes.func,
  onSelect: PropTypes.func,
  itemValueKey: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  isShowSavedSearches: PropTypes.bool,
  savedSearches: PropTypes.array,
  onSelectSavedSearch: PropTypes.func,
  isShowMenuItems: PropTypes.bool,
  onSearchClicked: PropTypes.func,
  onClearSearch: PropTypes.func,
};
SearchInput.defaultProps = {
  searchCallback: () => {},
  onSelect: () => {},
  onChange: () => {},
  itemValueKey: "title",
  value: "",
  isShowSavedSearches: false,
  savedSearches: [],
  onSelectSavedSearch: () => {},
  isShowMenuItems: true,
  onSearchClicked: () => {},
  onClearSearch: () => {},
};
