import TagsList from "../view";

import { injectIntl } from "react-intl";
import { compose, withState, withHandlers, lifecycle } from "recompose";
import { connect } from "react-redux";
import { get, includes, isEmpty, forEach, pick, find, isEqual, keys } from "lodash";

export const TAG_TYPE_FORMAT = "format";
export const TAG_TYPE_DMAs = "market";

export default injectIntl(
  compose(
    connect((state) => {
      return {
        allStationsAndTeams: get(state, "teams.allStationsAndTeams.data.data", []),
      };
    }),
    withState("tags", "setTags", {}),
    withState("expandedRow", "setExpandedRow", null),
    withState("selectedTeamId", "setSelectedTeamId", null),
    withState("isTeamWarningModalOpen", "setIsTeamWarningModalOpen", false),
    withHandlers({
      expandRow: ({expandedRow, setExpandedRow}) => (id) => {
        if(expandedRow !== id){
          setExpandedRow(id);
        }else{
          setExpandedRow(null);
        }
      },
      onToggleTeamWarningModal: ({ isTeamWarningModalOpen, setIsTeamWarningModalOpen }) => () => {
        setIsTeamWarningModalOpen(!isTeamWarningModalOpen);
      },
    }),
    withHandlers({
      openTeamWarningModal: ({setSelectedTeamId, setIsTeamWarningModalOpen}) => (teamId) => {
        setSelectedTeamId(teamId);
        setIsTeamWarningModalOpen(true);
      }
    }),
    lifecycle({
      componentDidMount () {
        
        let stations = get(this.props, "availableStations", []);
        let teams = get(this.props, "availableTeams", []);

        let selectedTeamKey = get(this.props.selectedRecord, "data.key", "");

        let teamOptions = [];

        teams.map(team => {

          if(!isEmpty(team.selected_stations)){

              let showWarning = false;

              forEach(team.selected_stations, station => {
                if(!isEmpty(station.formatChange)){
                  showWarning = true;
                }
              });

              let teamStations = {};

              stations.filter(station => includes(keys(team.selected_stations), station.key)).forEach(station => {
                  teamStations[station.key] = pick(station, ['key', 'station_key', 'market', 'format', 'team', 'audience', 'name', 'call_letters'])
              });

              if(!isEmpty(teamStations) && !isEqual(team.key, selectedTeamKey)){
                teamOptions[team.key] = {
                  primaryKey: team.id,
                  id: team.key,
                  key: team.key,
                  label: team.name,
                  name: team.name,
                  station_key: team.station_key,
                  type: 'team',
                  stations : teamStations,
                  showWarning: showWarning,
                  format: {},
                  market : {},
                  roles : team.roles,
                };
              } 
          }
        });

        //set tags
        this.props.setTags((previousTags) => {

          let newTeamOptions = {...previousTags, ...teamOptions};
          
          //get saved tags and set them against selected tags
          let savedTags = get(this.props.selectedRecord, "data.selected_tags", {});

          forEach(savedTags, savedTag => {
            let tag = find(newTeamOptions, {id : savedTag}); 
            if(tag){
              this.props.updateSelectedTags(true, tag, tag.type, false);
            }
          });

          return newTeamOptions;
        });
      }
    })
  )(TagsList)
);