import React from "react";
import { Redirect } from "react-router";

import PrivilegedComponent from "components/PrivilegedComponent";
import PageAdmin from "components/PageAdmin";
import ClocksList from "components/ClockManager/ClocksList";
import URL from "utils/urls";
import bn from "utils/bemnames";

const bem = bn.create("clock-manager");

const ClockManagerPage = (props) => {
  const { intl } = props;

  const renderBreadcrumbs = () => {
    const { intl } = props;
    let breadcrumbs = [
      {
        name: intl.formatMessage({ id: "breadcrumbs > home" }),
        to: URL.HOME(),
        active: false,
      },
      {
        name: intl.formatMessage({ id: "breadcrumbs > clock manager" }),
        active: true,
      },
    ];

    return breadcrumbs;
  };

  return (
    <PrivilegedComponent requires={[]}>
      {({ isValid }) => {
        if (isValid) {
          return (
            <PageAdmin
              title={intl.formatMessage({ id: "pages > clock manager" })}
              breadcrumbs={renderBreadcrumbs()}
              className={bem.b()}
            >
              <ClocksList {...props} />
            </PageAdmin>
          );
        } else {
          return <Redirect to="/" />;
        }
      }}
    </PrivilegedComponent>
  );
};

export default ClockManagerPage;
