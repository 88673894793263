import { connect } from "react-redux";
import View from "./view";
import { injectIntl } from "react-intl";
import { compose, withState } from "recompose";
import { get } from "lodash";
const Enhanced = compose(
  connect((state, props) => {
    const filter = "contest";
    return {
      auth: state.auth,
      isLoading:
        get(state, `winners.filterWinners.filter_${filter}.loading`, false) ||
        get(
          state,
          `winners.filterWinners.filter_grand_prize_winner.loading`,
          false
        ) ||
        get(state, `winners.pickGrandPrizeWinner.loading`, false) ||
        get(state, "winners.winnerNotification.loading", false) ||
        get(state, "winners.addWinnerNotification.loading", false) ||
        get(state, "winners.winnersNotifications.loading", false) ||
        get(state, "winners.updateWinnerNotification.loading", false),
      ...props,
    };
  }, {}),
  withState("activeTab", "setActiveTab", "1")
)(View);

export default injectIntl(Enhanced);
