import React from "react";
import { Row, Col, Label } from "reactstrap";
import classnames from "classnames";
import { get, find, isEmpty, map } from "lodash";
import { FormattedMessage } from "react-intl";
import bn from "utils/bemnames";

// import { Asterisk } from "components/Elements";
import { TrashListIcon } from "components/CustomIcons";
import DaysOfWeek from "components/DaysOfWeek";
import InputTime from "components/InputTime";
import TextInput from "components/TextInput";
const bem = bn.create("daypart-scheduler");

const DaypartSchedule = (props) => {
  const {
    intl,
    firstRow,
    id,
    schedules,
    index,
    onChange,
    errors,
    onDelete,
  } = props;

  let schedule = find(
    schedules,
    (schedule) => parseInt(schedule.id) === parseInt(id)
  );

  const dateTimeFormat = "YYYY-MM-DD HH:mm:ss";
  const onUpdate = (data) => {
    const newSchedules = map(schedules, (item) => {
      if (parseInt(item.id) === parseInt(id)) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
    onChange(newSchedules);
  };
  return (
    <div
      className={classnames(bem.b(), { error: !isEmpty(errors) })}
      id={`daypart-${index}`}
    >
      <Row className={classnames(bem.e("schedule-row"), "p-0 m-0")}>
        <Col
          xs={3}
          className={classnames(bem.e("schedule-start-time"), "p-0 m-0")}
        >
          {firstRow && (
            <Label>
              <FormattedMessage id="clock creator > daypart schedule start time" />
            </Label>
          )}
          <InputTime
            id="startTimerInput"
            time={get(schedule, "start_date")}
            onChange={(startDateTime) => {
              if (startDateTime) {
                let minutes =
                  startDateTime.get("hours") * 60 +
                  startDateTime.get("minutes");
                onUpdate({
                  ...schedule,
                  start_time: minutes,
                  start_date: startDateTime.format(dateTimeFormat),
                });
              } else {
                onUpdate({
                  ...schedule,
                  end_time: undefined,
                  end_date: undefined,
                });
              }
            }}
            error={errors && errors.start_time}
          />
        </Col>
        <div
          className={classnames(bem.e("start-end-time-separator"), {
            "row-1": firstRow,
          })}
        >
          To
        </div>
        <Col
          xs={3}
          className={classnames(bem.e("schedule-end-time"), "p-0 m-0")}
        >
          {firstRow && (
            <Label>
              <FormattedMessage id="clock creator > daypart schedule end time" />
            </Label>
          )}
          <InputTime
            id="endTimerInput"
            time={get(schedule, "end_date")}
            onChange={(endDateTime) => {
              if (endDateTime) {
                let minutes =
                  endDateTime.get("hours") * 60 + endDateTime.get("minutes");
                onUpdate({
                  ...schedule,
                  end_time: minutes,
                  end_date: endDateTime.format(dateTimeFormat),
                });
              } else {
                onUpdate({
                  ...schedule,
                  end_time: undefined,
                  end_date: undefined,
                });
              }
            }}
            error={errors && (errors.end_time || errors.end_date)}
          />
        </Col>
        <Col xs={3} className={classnames(bem.e("schedule-days"), "p-0 m-0")}>
          <DaysOfWeek
            value={get(schedule, "days_of_week")}
            showLabel={firstRow}
            checkStyle={{ width: "8px", height: "7.3px" }}
            label={intl.formatMessage({
              id: "clock creator > daypart schedule days",
            })}
            onChange={(days) => onUpdate({ ...schedule, days_of_week: days })}
            error={errors && errors.days_of_week}
          />
        </Col>
        <Col xs={3} className={classnames(bem.e("schedule-label"), "p-0 m-0")}>
          <TextInput
            label={
              firstRow ? (
                <span>
                  <FormattedMessage id="clock creator > daypart schedule label" />
                </span>
              ) : null
            }
            type="text"
            name="name"
            placeholder={intl.formatMessage({
              id: "clock creator > enter daypart schedule label",
            })}
            value={get(schedule, "label")}
            onChange={(e) => {
              onUpdate({ ...schedule, label: e.target.value });
            }}
            error={errors && errors.label}
          />
          <div
            className="trash-icon"
            onClick={() => {
              onDelete();
            }}
          >
            <TrashListIcon width={15} height={18} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DaypartSchedule;
