import React from "react";
import UserForm, { MODE_VIEW, MODE_EDIT, MODE_ADD } from "./view";
import { get, isFunction, pick, forOwn, pickBy, map, first } from "lodash";
import { connect } from "react-redux";
import { compose, lifecycle, withHandlers, withState } from "recompose";
import {
  addUser,
  getUser,
  updateUser,
  deleteUser,
  invitationUser,
  updateAssignmentUser,
  setNewUser,
  setIsNewUser,
} from "store/actions/users";
import { MdImportantDevices } from "react-icons/lib/md";
import ToastManager from "components/ToastManager";
import { injectIntl } from "react-intl";
import { getTagOptionsByType, getSelectedTagOptions } from "utils/helpers";
import { TAG_TYPE_EXCEPT_LOCATION_FORMAT } from "components/SelectTags";
import { isUserShowStyleTags } from "utils/helpers";
import { getRoles } from "store/actions/roles";
import { getRoleIdByName } from "utils/helpers";
import { getHubs } from "store/actions/hubs";

export { MODE_VIEW, MODE_EDIT, MODE_ADD };

export default injectIntl(
  compose(
    connect(
      (state, props) => {
        let id = props.id;
        const auth = get(state, "auth", {});
        const user = get(state, `users.user.${id}.data`, {});
        const authPrivileges = pickBy(
          get(state, "auth.info.privileges", {}),
          (privilege) =>
            privilege !== "add_stations" && privilege !== "delete_stations"
        );
        let privileges = [];
        forOwn(authPrivileges, (privilege, id) => {
          privileges.push({
            id,
            name: privilege,
          });
        });
        const tagsOptions = getTagOptionsByType(
          TAG_TYPE_EXCEPT_LOCATION_FORMAT,
          auth
        );

        let hasMultimarket = false;

        const digitalMultimarket = get(auth, "info.digital_multimarket", false);
        const productionMultimarket = get(
          auth,
          "info.production_multimarket",
          false
        );
        const promotionMultimarket = get(
          auth,
          "info.promotion_multimarket",
          false
        );

        if (
          digitalMultimarket ||
          productionMultimarket ||
          promotionMultimarket
        ) {
          hasMultimarket = true;
        }

        return {
          id,
          isLoading:
            get(state, "users.addUser.loading") ||
            get(state, "users.updateUser.loading") ||
            get(state, `users.user.${id}.loading`) ||
            get(state, `users.inviteUser.loading`),
          isUpdate: id && id !== "new" ? true : false,
          user,
          isSpecialUser: isUserShowStyleTags(user),
          embedded: !!props.id,
          auth,
          privileges,
          tagsOptions,
          hasMultimarket: hasMultimarket,
          selectedTagOptions: getSelectedTagOptions(
            tagsOptions,
            get(state, `users.user.${id}.data`, {})
          ),
          currentServer: get(state, "servers.currentServer.data", {}),
          isShowEnterpriseRoles: productionMultimarket,
          roles: get(state, "roles.roles.data", []),
        };
      },
      {
        addUser,
        updateUser,
        getUser,
        deleteUser,
        invitationUser,
        updateAssignmentUser,
        setNewUser,
        setIsNewUser,
        getRoles,
        getHubs,
      }
    ),
    withState("isViewMode", "setIsViewMode", (props) => get(props, "isView")),
    withState("isDeleteModalOpen", "setIsDeleteModalOpen", false),
    withState(
      "isCreativeDetailsCollapsed",
      "setIsCreativeDetailsCollapsed",
      true
    ),
    withState(
      "isOpenManagePrivilegesModal",
      "setIsOpenManagePrivilegesModal",
      false
    ),
    withState("isOpenEnterpriseRoles", "setIsOpenEnterpriseRoles", true),
    withState("shouldBlockNavigation", "setShouldBlockNavigation", false),
    withState("deleteUserId", "setDeleteUserId", null),
    withState("assignmentsData", "setAssignmentsData", null),
    withState(
      "isChangePasswordModalOpen",
      "setIsChangePasswordModalOpen",
      false
    ),
    withState("isOpenAssignmentsModal", "setIsOpenAssignmentsModal", false),
    withHandlers({
      onFormSubmit: ({
        addUser,
        updateUser,
        isUpdate,
        id,
        onBack,
        intl,
        setIsViewMode,
        getUser,
        isSpecialUser,
        assignmentsData,
        updateAssignmentUser,
        onSelectedEdit,
        user,
        setNewUser,
        setIsNewUser,
        auth,
        isShowEnterpriseRoles,
        getHubs,
      }) => (values, formikProps) => {
        let dataToSend = pick(values, [
          "first_name",
          "last_name",
          "email",
          "language",
          "phone",
          "location",
          "title",
          "timezone",
          "enterprise_privileges",
          "location_text",
        ]);
        if (isShowEnterpriseRoles) {
          dataToSend.enterprise_roles = values.enterprise_roles
            ? values.enterprise_roles.map((item) => item.value)
            : [];
          dataToSend.enterprise_privileges = values.enterprise_privileges || [];
        }
        if (
          auth.info.app_flags &&
          auth.info.app_flags.indexOf("SERVERS_LIST") > -1
        ) {
          dataToSend.is_god_admin = values.is_support_admin ? 2 : 1;
        }

        if (values.password) {
          dataToSend.password = values.password;
        }

        if (values.is_group_admin) {
          dataToSend.is_group_admin = values.is_group_admin;
        }

        if (isSpecialUser) {
          dataToSend = {
            ...dataToSend,
            summary: values.summary,
            tags_string: map(
              get(values, "tags", []),
              (item) => item.data.title
            ).join("#"),
            reel: get(values, "reel"),
            tags: map(get(values, "tags", []), (item) => item.value),
          };
        }

        if (isUpdate) {
          updateUser(id, dataToSend, (response) => {
            const newUserData = get(response, "data.user");
            if (newUserData) {
              getUser(id);

              ToastManager.show({
                title: <MdImportantDevices />,
                message: intl.formatMessage({
                  id: "user > update successfully",
                }),
                level: "success",
              });
              setIsViewMode(MODE_VIEW);
            } else {
              forOwn(response, (value, key) => {
                formikProps.setFieldError(key, first(value));
              });
            }
          });
        } else {
          if (user.avatar) dataToSend.avatar = user.avatar;
          addUser(dataToSend, (response) => {
            const newUserData = get(response, "data.user");
            // update assignment for new user
            if (assignmentsData && newUserData) {
              updateAssignmentUser(
                {
                  ...assignmentsData,
                  id: newUserData.id,
                },
                () => {}
              );
            }
            if (newUserData) {
              const isShowHubAssignments = !!getRoleIdByName(
                "Hub Manager",
                newUserData.enterprise_roles
              );
              if (isShowHubAssignments) {
                getHubs({
                  pageSize: 1000,
                  sorted: [],
                  filtered: [],
                  page: 0,
                });
              }
              if (
                isFunction(onSelectedEdit) &&
                !(
                  auth.info.app_flags &&
                  auth.info.app_flags.indexOf("SERVERS_LIST") > -1
                )
              ) {
                onSelectedEdit({ [newUserData.id]: newUserData }); // back to edit mode new user
              }

              ToastManager.show({
                title: <MdImportantDevices />,
                message: intl.formatMessage({
                  id: "user > create successfully",
                }),
                level: "success",
              });
              formikProps.resetForm({});
              getUser(newUserData.id);
              setNewUser({
                id: "new",
                data: {},
                isForce: true,
              });
              if (setIsNewUser && isFunction(setIsNewUser)) {
                setIsNewUser(true);
              }
              if (
                isFunction(onBack) &&
                auth.info.app_flags &&
                auth.info.app_flags.indexOf("SERVERS_LIST") > -1
              )
                onBack();
            } else {
              forOwn(response, (value, key) => {
                formikProps.setFieldError(key, first(value));
              });
            }
          });
        }
      },
      onResendInvitationUser: ({ id, invitationUser, intl }) => () =>
        invitationUser(id, () =>
          ToastManager.show({
            title: <MdImportantDevices />,
            message: intl.formatMessage({
              id: "user > resend invite successfully",
            }),
            autoDismiss: 1.5,
            level: "success",
          })
        ),
      onSetNewUser: ({ setNewUser }) => (values) => {
        setNewUser({
          id: "new",
          data: values,
        });
      },
    }),
    withHandlers({
      openDeleteModal: ({ setIsDeleteModalOpen, setDeleteUserId }) => (id) => {
        setIsDeleteModalOpen(true);
        setDeleteUserId(id);
      },
      closeDeleteModal: ({ setIsDeleteModalOpen, setDeleteUserId }) => () => {
        setIsDeleteModalOpen(false);
        setDeleteUserId(null);
      },
    }),
    withHandlers({
      onDelete: ({
        closeDeleteModal,
        deleteUserId,
        deleteUser,
        onBack,
      }) => () => {
        deleteUser(deleteUserId, () => {
          if (isFunction(onBack)) onBack();
        });
        closeDeleteModal();
      },
    }),
    lifecycle({
      componentDidMount() {
        this.props.getRoles();
      },
      componentWillReceiveProps(nextProps) {
        if (get(this.props, "isView") !== get(nextProps, "isView")) {
          this.props.setIsViewMode(get(nextProps, "isView"));
        }
      },
    })
  )(UserForm)
);
