import { FormattedMessage } from "react-intl";
import React from "react";
import { get, find, map, isEmpty, delay, filter, includes, uniq } from "lodash";
import history from "components/History";
import {
  getNextTabToActive,
  checkUserRedirectToCurrentTasks,
} from "utils/helpers";

const approveRecording = {
  process_step_index: 6,
  key: "approve_recording",
  title: <FormattedMessage id="process > title spot review" />,
  caption_color: "#6803CC",
  description: <FormattedMessage id="process > creative details" />,
  isCollapsible: true,
  step_roles: [1, 2, 3],
  step_viewers_roles: [1, 2, 3, 7],
  step_editors_roles: [1, 2, 3],
  step_viewers_privileges: {
    or: ["approve_production_recording", "edit_all_production_orders"],
  },
  step_editors_privileges: {
    or: ["approve_production_recording", "edit_all_production_orders"],
  },
  is_check_hidden_tab: true,
  status: "initial",
  className: "approve-recording",
  is_hidden: function(user, process, step) {
    const spotInfo = get(process, "data.fields.spot_info.value", []).find(
      (spot) => spot.key === process.key
    );
    return (
      (process.data.fields.contract_status &&
        process.data.fields.contract_status.value &&
        process.data.fields.contract_status.value !== "Production Needed") ||
      !get(spotInfo, "recording_requires_approval", false)
    );
  },
  isOpen: function({ step, process, template, user }) {
    return false;
  },
  workflow: {
    title: "approve recording",
    icon: "ApprovalIcon",
  },
  is_approval_step: true,
  fields: [
    {
      component: "order_approvals",
    },
    {
      field: "spot_info",
    },
    {
      component: "label",
      value: <FormattedMessage id="process > field VO tracks" />,
      props: { className: "form-segment-label" },
    },
    {
      field: "voice_tracks",
      component: "voice_tracks",
      mandatory: false,
      props: {
        attachText: <FormattedMessage id="process > field upload audio" />,
        actions: [],
        isCheckMimesByExt: true,
        extensions: ["mp3", "wav", "aiff", "flac", "aac", "alac", "m4a", "mp4"],
      },
    },
    {
      field: "script",
      container: "segment",
      component: "order_script_review",
      isCollapsible: true,
      isSpot: true,
      isOpen: function(process, isView) {
        return (
          get(process, "data.fields.record_script_status.value", "") !==
          "completed"
        );
      },
      props: {
        classNameSegment: "segment-script segment-approve-recording-script",
        className: "form-div-script",
        readOnly: true,
        isShowOrderLength: true,
      },
      title: <FormattedMessage id="process > title final script" />,
    },
    {
      field: "recording_feedback",
      container: "segment",
      component: "order_recording_review",
      isCollapsible: false,
      props: {
        classNameSegment: "segment-recording",
        className: "form-div-script",
        readOnly: true,
        isBubble: true,
      },
      title: <FormattedMessage id="process > title final recording" />,
    },
    {
      field: "recording_history",
      container: "segment",
      component: "order_recording_history",
      isCollapsible: true,
      props: {
        classNameSegment: "segment-recording segment-recording-history",
        className: "form-div-script",
        readOnly: true,
        isBubble: true,
      },
      title: <FormattedMessage id="process > title recording history" />,
      is_hidden: function(user, process, step, isView) {
        return isEmpty(get(process, "data.fields.recording_history.value", []));
      },
      isOpen: function({ step, process, template, user }) {
        return false;
      },
    },
    {
      field: "sent_to_client_for_spot_review",
    },
    {
      field: "approve",
      text: <FormattedMessage id="process > button approve" />,
      component: "button",
      props: { color: "blue" },
      container: "bottom_buttons",
      is_hidden: function(user, process, step) {
        return (
          !(
            process.data.fields.recording_path &&
            process.data.fields.recording_path.value
          ) ||
          get(
            process,
            "data.fields.recording_feedback.value.is_feedback",
            false
          ) ||
          get(process, "data.fields.approve_recording_status.value", "") ===
            "approved"
        );
      },
      fields: [
        {
          key: "approve_script_status",
          value: "approved",
        },
        {
          key: "approve_recording_status",
          value: "approved",
        },
        {
          key: "sent_to_client_for_spot_review",
          value: false,
        },
        {
          key: "spot_info",
          value: function(user, template, process, step, item) {
            // marked approve_recording_status to spot linked
            let spotInfo = map(
              get(process, "data.fields.spot_info.value", []),
              (spot) => ({
                ...spot,
                approve_script_status:
                  spot.key === process.key
                    ? "approved"
                    : spot.approve_recording_status,
                approve_recording_status:
                  spot.key === process.key
                    ? "approved"
                    : spot.approve_recording_status,
              })
            );
            return spotInfo;
          },
        },
        {
          key: "markets",
          value: function(user, template, process, step, item) {
            // sync the final recording to all the spots
            const markets = map(
              get(process, "data.fields.markets.value", []),
              (market) => {
                const spotInfo = map(market.spot_info, (spot) => {
                  let spotData = {
                    ...spot,
                    recording_path: get(
                      process,
                      "data.fields.recording_path.value",
                      ""
                    ),
                    recording_audit: get(
                      process,
                      "data.fields.recording_audit.value",
                      ""
                    ),
                  };
                  if (spot.spot_type === "Book-ends") {
                    spotData = {
                      ...spotData,
                      recording_path_b: get(
                        process,
                        "data.fields.recording_path_b.value",
                        ""
                      ),
                      recording_path_b_audit: get(
                        process,
                        "data.fields.recording_path_b_audit.value",
                        ""
                      ),
                    };
                  }
                  return spotData;
                });
                return {
                  ...market,
                  spot_info: spotInfo,
                };
              }
            );
            return markets;
          },
        },
        {
          key: "process_step",
          value: "first_undone",
        },
        {
          key: "next_users",
          value: (user, template, process, step, item, next_users) => {
            const continuityStatus = get(
              process,
              "data.fields.continuity_status.value",
              ""
            );
            let requiredNextUsers = [];
            const roleContinuity = get(
              process,
              "data.fields.role_continuity.value.users",
              []
            ).map((continuity) => continuity.id);

            const roleDubber = get(
              process,
              "data.fields.role_dubber.value.users",
              []
            ).map((dubber) => dubber.id);

            let nextUsers = next_users;

            if (continuityStatus !== "completed") {
              nextUsers = [...nextUsers, ...roleContinuity];
              requiredNextUsers = [...roleContinuity];
            }
            if (continuityStatus === "completed") {
              nextUsers = [...nextUsers, ...roleDubber];
              requiredNextUsers = [...requiredNextUsers, ...roleDubber];
            }
            requiredNextUsers = requiredNextUsers.map(Number);

            let roleAE = [];

            if (
              get(
                process,
                "data.fields.role_account_executive.value.users",
                false
              )
            ) {
              roleAE = get(
                process,
                "data.fields.role_account_executive.value.users",
                []
              ).map((ae) => ae.id);
            } else if (
              get(process, "data.fields.role_account_executive.value.id", false)
            ) {
              roleAE.push(
                get(process, "data.fields.role_account_executive.value.id", "")
              );
            }
            // add external sales
            if (
              get(process, "data.fields.role_sales_person_2.value.id", false)
            ) {
              roleAE.push(
                get(process, "data.fields.role_sales_person_2.value.id", "")
              );
            }
            // case: AE assigned to: role_account_executive, role_continuity or role_dubber. We need assign to continuity, dubber if spot approved
            roleAE = roleAE
              .map(Number)
              .filter((id) => !requiredNextUsers.includes(id));

            nextUsers = filter(nextUsers, (nextUserId) => {
              return !includes(roleAE, nextUserId);
            });
            return uniq(nextUsers);
          },
        },
      ],
      on_click: {
        validate: true,
        save: true,
        go_to: function(_this, step, item, process, template, user, extraData) {
          const nextUsers = get(
            process,
            "data.fields.next_users.value",
            []
          ).map(Number);
          const return_to_current_tasks = checkUserRedirectToCurrentTasks({
            ...user,
            roles_string: extraData.roles_string,
          });
          const isUserAssignedNextStep = nextUsers.includes(Number(user.id));

          if (!return_to_current_tasks && _this.props.setActiveTab) {
            const nextStepIndex = getNextTabToActive(); // use javascript to find next tab
            _this.props.setActiveTab(nextStepIndex);
            if (isUserAssignedNextStep) {
              _this.props.setActiveEditTab(nextStepIndex);
            }
            return;
          }
          // set delay when after success message
          delay(() => {
            if (_this.props.setSidebarActive) {
              _this.props.setSidebarActive(`/processes/my_action_items`);
            }
            history.replace(`/processes/my_action_items`);
          }, 4000);
        },
      },
      confirmation_dialog_props: {
        titleStyle: { width: "400px" },
        title: (
          <FormattedMessage id="process > description approve the recording?" />
        ),
        confirmTitle: <FormattedMessage id="process > button yes" />,
        cancelTitle: <FormattedMessage id="process > button no" />,
      },
      success_dialog_props: {
        title: <FormattedMessage id="process > title approved" />,
        state: "success",
      },
    },
    {
      field: "request_changes",
      text: <FormattedMessage id="process > button request changes" />,
      component: "button",
      props: { outline: true, color: "blue" },
      container: "bottom_buttons",
      fields: [
        {
          key: "recording_feedback",
          value: function(user, template, process, step, item) {
            const spot = find(
              get(process, "data.fields.spot_info.value", []),
              (spot) => spot.key === process.key
            );
            const isBookend =
              get(spot, "spot_type", "Standard") === "Book-ends";
            let defaultRecordingFeedback = {
              ...get(process, "data.fields.recording_feedback.value", {}),
              recording_path: get(
                process,
                "data.fields.recording_path.value",
                ""
              ),
              recording_audit: get(
                process,
                "data.fields.recording_audit.value",
                null
              ),
              include_timestamp: false,
            };
            if (isBookend) {
              defaultRecordingFeedback = {
                ...defaultRecordingFeedback,
                recording_path_b: get(
                  process,
                  "data.fields.recording_path_b.value",
                  ""
                ),
                recording_path_b_audit: get(
                  process,
                  "data.fields.recording_path_b_audit.value",
                  null
                ),
                include_recording_path_b_timestamp: false,
              };
            }
            return { ...defaultRecordingFeedback, is_feedback: true };
          },
        },
      ],
      on_click: {
        validate: false,
        save: false,
        updateFormValue: true,
        go_to: function(_this, step, item, process, template, user) {
          // if (!user.return_to_current_tasks) return;
          // set delay when after success message
          delay(() => {
            if (_this.props.setSidebarActive) {
              _this.props.setSidebarActive(`/processes/my_action_items`);
            }
            history.replace(`/processes/my_action_items`);
          }, 4000);
        },
      },
      is_hidden: function(user, process, step) {
        return (
          !(
            process.data.fields.recording_path &&
            process.data.fields.recording_path.value
          ) ||
          get(
            process,
            "data.fields.recording_feedback.value.is_feedback",
            false
          ) ||
          get(process, "data.fields.approve_recording_status.value", "") ===
            "approved"
        );
      },
    },
    {
      field: "submit_feedback",
      text: <FormattedMessage id="process > button submit" />,
      component: "button",
      props: { color: "blue" },
      container: "bottom_buttons",
      key: "save_button",
      is_hidden: function(user, process, step) {
        return (
          !get(
            process,
            "data.fields.recording_feedback.value.is_feedback",
            false
          ) ||
          get(process, "data.fields.approve_recording_status.value", "") ===
            "approved"
        );
      },
      fields: [
        {
          key: "approve_recording_status",
          value: "rejected",
        },
        {
          key: "record_script_status",
          value: "rejected",
        },
        {
          key: "sent_to_client_for_spot_review",
          value: false,
        },
        {
          key: "spot_info",
          value: function(user, template, process, step, item) {
            // marked approve_recording_status to spot linked
            let spotInfo = map(
              get(process, "data.fields.spot_info.value", []),
              (spot) => ({
                ...spot,
                approve_script_status:
                  spot.key === process.key
                    ? "rejected"
                    : spot.approve_recording_status,
                approve_recording_status:
                  spot.key === process.key
                    ? "rejected"
                    : spot.approve_recording_status,
              })
            );
            return spotInfo;
          },
        },
        {
          key: "markets",
          value: function(user, template, process, step, item) {
            // sync the final recording to all the spots
            const markets = map(
              get(process, "data.fields.markets.value", []),
              (market) => {
                const spotInfo = map(market.spot_info, (spot) => {
                  let spotData = {
                    ...spot,
                    recording_path: "",
                    recording_audit: "",
                  };
                  if (spot.spot_type === "Book-ends") {
                    spotData = {
                      ...spotData,
                      recording_path_b: "",
                      recording_path_b_audit: "",
                    };
                  }
                  return spotData;
                });
                return {
                  ...market,
                  spot_info: spotInfo,
                };
              }
            );
            return markets;
          },
        },
        {
          key: "process_step",
          value: "record_script",
        },
        {
          key: "recording_path",
          value: "",
        },
        {
          key: "recording_path_b",
          value: "",
        },
        {
          key: "recording_audit",
          value: null,
        },
        {
          key: "recording_path_b_audit",
          value: null,
        },
        {
          key: "next_users",
          value: (user, template, process, step, item, next_users) => {
            let continuityStatus = get(
              process,
              "data.fields.continuity_status.value",
              ""
            );
            let requiredNextUsers = [];
            const roleContinuity = get(
              process,
              "data.fields.role_continuity.value.users",
              []
            ).map((continuity) => continuity.id);

            const roleProducers = get(
              process,
              "data.fields.role_producer.value.users",
              []
            ).map((producer) => producer.id);

            let nextUsers = next_users;

            nextUsers = [...nextUsers, ...roleProducers];
            requiredNextUsers = roleProducers;
            if (continuityStatus !== "completed") {
              nextUsers = [...nextUsers, ...roleContinuity];
              requiredNextUsers = [...roleProducers, ...roleContinuity];
            }
            requiredNextUsers = requiredNextUsers.map(Number);
            let roleAE = [];

            if (
              get(
                process,
                "data.fields.role_account_executive.value.users",
                false
              )
            ) {
              roleAE = get(
                process,
                "data.fields.role_account_executive.value.users",
                []
              ).map((ae) => ae.id);
            } else if (
              get(process, "data.fields.role_account_executive.value.id", false)
            ) {
              roleAE.push(
                get(process, "data.fields.role_account_executive.value.id", "")
              );
            }
            // add additional sales
            if (
              get(process, "data.fields.role_sales_person_2.value.id", false)
            ) {
              roleAE.push(
                get(process, "data.fields.role_sales_person_2.value.id", "")
              );
            }

            // case: AE assigned to: role_account_executive, role_producer. We need assign back to producer if spot rejected
            roleAE = roleAE
              .map(Number)
              .filter((id) => !requiredNextUsers.includes(id));

            nextUsers = filter(nextUsers, (nextUserId) => {
              return !includes(roleAE, nextUserId);
            });
            return uniq(nextUsers);
          },
        },
      ],
      on_click: {
        validate: true,
        save: true,
        includeChatMessage: true,
        go_to: function(_this, step, item, process, template, user, extraData) {
          const return_to_current_tasks = checkUserRedirectToCurrentTasks({
            ...user,
            roles_string: extraData.roles_string,
          });
          if (!return_to_current_tasks) return;
          // set delay when after success message
          delay(() => {
            if (_this.props.setSidebarActive) {
              _this.props.setSidebarActive(`/processes/my_action_items`);
            }
            history.replace(`/processes/my_action_items`);
          }, 4000);
        },
      },
      warning_dialog_props: {
        show: function(process) {
          const spot = find(
            get(process, "data.fields.spot_info.value", []),
            (spot) => spot.key === process.key
          );
          const isBookend = get(spot, "spot_type", "Standard") === "Book-ends";
          const isRecordingFeedback = get(
            process,
            "data.fields.recording_feedback.value.feedback",
            null
          );
          if (isBookend) {
            const isRecordingBFeedback = get(
              process,
              "data.fields.recording_feedback.value.recording_path_b_feedback",
              null
            );
            return !isRecordingFeedback && !isRecordingBFeedback;
          }
          return !isRecordingFeedback;
        },
        titleStyle: { width: "400px" },
        title: (
          <FormattedMessage id="process > leave feedback to request changes" />
        ),
        confirmTitle: <FormattedMessage id="process > button ok" />,
        cancelTitle: "",
      },
      confirmation_dialog_props: {
        titleStyle: { width: "400px" },
        title: (
          <FormattedMessage id="process > description request changes to the recording" />
        ),
        confirmTitle: <FormattedMessage id="process > button yes" />,
        cancelTitle: <FormattedMessage id="process > button no" />,
        className: "modal-request-changes",
      },
      success_dialog_props: {
        title: <FormattedMessage id="process > submitted" />,
        state: "success",
      },
    },
    {
      component: "send_mail",
      container: "bottom_buttons",
      is_hidden: function(user, process, step, isView) {
        let spotInfo = find(
          get(process, "data.fields.spot_info.value", []),
          (spot) => {
            return spot.key == process.key;
          }
        );
        return (
          isView ||
          !spotInfo ||
          !spotInfo.recording_requires_approval ||
          !(
            process.data.fields.recording_path &&
            process.data.fields.recording_path.value
          ) ||
          get(process, "data.fields.record_script_status.value") ===
            "rejected" ||
          get(
            process,
            "data.fields.recording_feedback.value.is_feedback",
            false
          ) ||
          get(process, "data.fields.approve_recording_status.value", "") ===
            "approved"
        );
      },
      props: {
        color: "blue",
        outline: true,
      },
    },
    // {
    //     "text": "REQUEST CHANGES TO SCRIPT",
    //     "component": "button",
    //     "props": { "outline": true, "color": "blue" },
    //     "container": "bottom_buttons",
    //     "fields": [
    //         {
    //             "key": "approve_script_status",
    //             "value": "rejected"
    //         },
    //         {
    //             "key": "approve_recording_status",
    //             "value": "rejected"
    //         },
    //         {
    //             "key": "write_script_status",
    //             "value": "rejected"
    //         },
    //         {
    //             "key": "record_script_status",
    //             "value": "rejected"
    //         },
    //         {
    //             "key": "requires_approval",
    //             "value": "true"
    //         },
    //         {
    //             "key": "process_step",
    //             "value": "write_script"
    //         }
    //     ],
    //     "on_click": {
    //         "validate": true,
    //         "save": true
    //     },
    //     "confirmation_dialog_props": {
    //         "description": "request changes to the script?",
    //         "confirmTitle": "Yes",
    //         "cancelTitle": "No"
    //     },
    //     "success_dialog_props": {
    //         "title": "Change request sent.",
    //         "state": "success"
    //     }
    // }
  ],
};
export default approveRecording;
