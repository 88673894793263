export const FETCH_TEAMS_REQUEST = "FETCH_TEAMS_REQUEST";
export const FETCH_TEAMS_SUCCESS = "FETCH_TEAMS_SUCCESS";
export const FETCH_TEAMS_ERROR = "FETCH_TEAMS_ERROR";

export const FETCH_MORE_TEAMS_REQUEST = "FETCH_MORE_TEAMS_REQUEST";
export const FETCH_MORE_TEAMS_SUCCESS = "FETCH_MORE_TEAMS_SUCCESS";
export const FETCH_MORE_TEAMS_ERROR = "FETCH_MORE_TEAMS_ERROR";

export const FETCH_TEAM_REQUEST = "FETCH_TEAM_REQUEST";
export const FETCH_TEAM_SUCCESS = "FETCH_TEAM_SUCCESS";
export const FETCH_TEAM_ERROR = "FETCH_TEAM_ERROR";

export const SAVE_TEAM_REQUEST = "SAVE_TEAM_REQUEST";
export const SAVE_TEAM_SUCCESS = "SAVE_TEAM_SUCCESS";
export const SAVE_TEAM_ERROR = "SAVE_TEAM_ERROR";

export const UPDATE_TEAM_REQUEST = "UPDATE_TEAM_REQUEST";
export const UPDATE_TEAM_SUCCESS = "UPDATE_TEAM_SUCCESS";
export const UPDATE_TEAM_ERROR = "UPDATE_TEAM_ERROR";

export const DELETE_TEAM_REQUEST = "DELETE_TEAM_REQUEST";
export const DELETE_TEAM_SUCCESS = "DELETE_TEAM_SUCCESS";
export const DELETE_TEAM_ERROR = "DELETE_TEAM_ERROR";

export const DELETE_TEAM_MEMBER_REQUEST = "DELETE_TEAM_MEMBER_REQUEST";
export const DELETE_TEAM_MEMBER_SUCCESS = "DELETE_TEAM_MEMBER_SUCCESS";
export const DELETE_TEAM_MEMBER_ERROR = "DELETE_TEAM_MEMBER_ERROR";

export const FETCH_ALL_STATIONS_AND_TEAMS_REQUEST =
  "FETCH_ALL_STATIONS_AND_TEAMS_REQUEST";
export const FETCH_ALL_STATIONS_AND_TEAMS_SUCCESS =
  "FETCH_ALL_STATIONS_AND_TEAMS_SUCCESS";
export const FETCH_ALL_STATIONS_AND_TEAMS_ERROR =
  "FETCH_ALL_STATIONS_AND_TEAMS_ERROR";

export const FETCH_TEAM_MEMBERS_REQUEST = "FETCH_TEAM_MEMBERS_REQUEST";
export const FETCH_TEAM_MEMBERS_SUCCESS = "FETCH_TEAM_MEMBERS_SUCCESS";
export const FETCH_TEAM_MEMBERS_ERROR = "FETCH_TEAM_MEMBERS_ERROR";

export const UPDATE_TEAM_LEAD_REQUEST = "UPDATE_TEAM_LEAD_REQUEST";
export const UPDATE_TEAM_LEAD_SUCCESS = "UPDATE_TEAM_LEAD_SUCCESS";
export const UPDATE_TEAM_LEAD_ERROR = "UPDATE_TEAM_LEAD_ERROR";

export const CHECK_TEAM_ASSIGNMENT_PRIVILEGES_REQUEST =
  "CHECK_TEAM_ASSIGNMENT_PRIVILEGES_REQUEST";
export const CHECK_TEAM_ASSIGNMENT_PRIVILEGES_SUCCESS =
  "CHECK_TEAM_ASSIGNMENT_PRIVILEGES_SUCCESS";
export const CHECK_TEAM_ASSIGNMENT_PRIVILEGES_ERROR =
  "CHECK_TEAM_ASSIGNMENT_PRIVILEGES_ERROR";

export const FETCH_TEAMS_AND_LEADS_REQUEST = "FETCH_TEAMS_AND_LEADS_REQUEST";
export const FETCH_TEAMS_AND_LEADS_SUCCESS = "FETCH_TEAMS_AND_LEADS_SUCCESS";
export const FETCH_TEAMS_AND_LEADS_ERROR = "FETCH_TEAMS_AND_LEADS_ERROR";
