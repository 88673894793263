import { get, map, isFunction } from "lodash";
import moment from "moment-timezone";
import Api from "utils/api";
import * as Const from "utils/constants";
import * as Types from "store/types/jocks";
import queryString from "query-string";
export const getJockSchedules = ({ date, type }) => async (dispatch) => {
  let errorStr = "";
  try {
    const params = {
      date,
      group_by: type,
      tz: moment.tz.guess(),
      tzOffset: new Date().getTimezoneOffset(),
    };
    dispatch({ type: Types.GET_JOCK_SCHEDULES_REQUEST });
    const url = `${Const.GET_JOCK_SCHEDULES()}?${queryString.stringify(
      params
    )}`;
    const response = await Api.doCall(url, "GET");
    if (response.status === 200) {
      if (!response.error) {
        dispatch({
          type: Types.GET_JOCK_SCHEDULES_SUCCESS,
          payload: response.data,
        });
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.GET_JOCK_SCHEDULES_ERROR, payload: errorStr });
};

export const getJockSchedulesFloatingContest = ({ date, type }) => async (
  dispatch
) => {
  let errorStr = "";
  try {
    const params = {
      date,
      group_by: type,
      tz: moment.tz.guess(),
      tzOffset: new Date().getTimezoneOffset(),
    };
    dispatch({ type: Types.GET_JOCK_SCHEDULES_FLOATING_CONTEST_REQUEST });
    const url = `${Const.GET_JOCK_SCHEDULES_FLOATING()}?${queryString.stringify(
      params
    )}`;
    const response = await Api.doCall(url, "GET");
    if (response.status === 200) {
      if (!response.error) {
        dispatch({
          type: Types.GET_JOCK_SCHEDULES_FLOATING_CONTEST_SUCCESS,
          payload: response.data,
        });
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.GET_JOCK_SCHEDULES_FLOATING_CONTEST_ERROR,
    payload: errorStr,
  });
};

export const updateJockSchedule = (payload, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.UPDATE_SCHEDULE_REQUEST });
    const url = `${Const.UPDATE_JOCK_SCHEDULE()}`;
    const response = await Api.doCall(url, "POST", payload);
    if (response.status === 200) {
      if (!response.error) {
        dispatch({
          type: Types.UPDATE_SCHEDULE_SUCCESS,
          payload: response.data,
        });
        cb && cb(response.data);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.UPDATE_SCHEDULE_ERROR, payload: errorStr });
};

export const getJockSchedule = (payload, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.GET_SCHEDULE_REQUEST });
    const url = `${Const.GET_JOCK_SCHEDULE(
      payload.id
    )}?tz=${moment.tz.guess()}&tzOffset=${new Date().getTimezoneOffset()}`;
    const response = await Api.doCall(url, "GET");
    if (response.status === 200) {
      if (!response.error) {
        const schedule = response.data;
        dispatch({
          type: Types.GET_SCHEDULE_SUCCESS,
          payload: {
            schedule: response.data,
            title: schedule.order_title,
            subtitle: schedule.station_name,
            isDone: schedule.completed || false,
          },
        });
        cb && cb(response.data);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.GET_SCHEDULE_ERROR, payload: errorStr });
};

export const getJockConsoleWidgets = (payload = {}) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.FETCH_JOCK_CONSOLE_WIDGETS_REQUEST, payload });
    const paramString = new URLSearchParams();
    if (payload.sort) {
      paramString.set("sort", payload.sort);
    } else {
      paramString.set("sort", `order asc`);
    }
    if (payload.search) {
      paramString.set("search", payload.search);
    }
    if (payload.page) {
      paramString.set("page", payload.page);
    }
    if (payload.station_id) {
      paramString.set("station_id", payload.station_id);
    }

    paramString.set("rpp", payload.rpp ? payload.rpp : 999);
    const url = Const.JOCK_CONSOLE_WIDGETS() + `?${paramString.toString()}`;
    const response = await Api.doCall(url, "GET");
    if (response.status === 200) {
      if (!response.data.error) {
        dispatch({
          type: Types.FETCH_JOCK_CONSOLE_WIDGETS_SUCCESS,
          payload: {
            ...get(response, "data", {}),
            items: map(response.data.items, (item) => item._source),
          },
        });
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.FETCH_JOCK_CONSOLE_WIDGETS_ERROR,
    payload: {
      error: errorStr,
    },
  });
};
export const setJockConsoleWidgets = (payload) => (dispatch) =>
  dispatch({ type: Types.SET_JOCK_CONSOLE_WIDGETS_SUCCESS, payload });

export const setJockSchedule = (payload) => (dispatch) =>
  dispatch({ type: Types.SET_SCHEDULE_SUCCESS, payload });

export const showDisplaySettingsModal = () => (dispatch) =>
  dispatch({ type: Types.SHOW_DISPLAY_SETTINGS_MODAL });

export const hideDisplaySettingsModal = () => (dispatch) =>
  dispatch({ type: Types.HIDE_DISPLAY_SETTINGS_MODAL });

export const setStationDisplaySettingsModal = (payload = {}) => (dispatch) =>
  dispatch({ type: Types.SET_STATION_DISPLAY_SETTINGS_MODAL, payload });

export const getJockConsoleDisplaySettings = () => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.FETCH_DISPLAY_SETTINGS_REQUEST });
    const response = await Api.doCall(
      Const.JOCK_CONSOLE_DISPLAY_SETTINGS(),
      "GET"
    );
    if (response.status === 200) {
      if (!response.data.error) {
        dispatch({
          type: Types.FETCH_DISPLAY_SETTINGS_SUCCESS,
          payload: {
            ...get(response, "data", {}),
          },
        });
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.FETCH_DISPLAY_SETTINGS_ERROR,
    payload: {
      error: errorStr,
    },
  });
};

export const updateJockConsoleDisplaySettings = (payload, cb) => async (
  dispatch
) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.UPDATE_DISPLAY_SETTINGS_REQUEST });
    const response = await Api.doCall(
      Const.JOCK_CONSOLE_DISPLAY_SETTINGS(),
      "POST",
      payload
    );
    if (response.status === 200) {
      if (!response.data.error) {
        dispatch({ type: Types.UPDATE_DISPLAY_SETTINGS_SUCCESS });
        dispatch(getJockConsoleDisplaySettings());
        if (isFunction(cb)) {
          cb(true);
        }
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.UPDATE_DISPLAY_SETTINGS_ERROR,
    payload: {
      error: errorStr,
    },
  });
};

export const switchActive = (item, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.UPDATE_ACTIVE_JOCK_CONSOLE_WIDGET_REQUEST });
    //api call for store data
    const response = await Api.doCall(
      `${Const.JOCK_CONSOLE_WIDGETS()}/${item.id}`,
      "PUT",
      {
        name: item.name,
        color: item.color,
        is_enabled: item.is_enabled,
      }
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        //response data
        const responseData = JSON.parse(JSON.stringify(response.data));
        dispatch({
          type: Types.UPDATE_ACTIVE_JOCK_CONSOLE_WIDGET_SUCCESS,
          payload: responseData,
        });
        // dispatch(forgetWidgets());
        if (cb) cb();
        return;
      }
      //error data
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.UPDATE_ACTIVE_JOCK_CONSOLE_WIDGET_ERROR,
    payload: {
      error: errorStr,
    },
  });
};

export const updateWidgetsOrder = (payload) => async (dispatch) => {
  let errorStr = "";
  try {
    const orderParams = map(payload, (item, index) => ({
      id: item.id,
      order: index + 1,
    }));
    dispatch({ type: Types.UPDATE_ORDER_WIDGETS_REQUEST, payload });
    const url = Const.UPDATE_ORDER_WIDGETS();
    let response = await Api.doCall(url, "POST", { data: orderParams });
    if (response.status === 200) {
      if (!response.data.error) {
        dispatch({
          type: Types.UPDATE_ORDER_WIDGETS_SUCCESS,
        });
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.UPDATE_ORDER_WIDGETS_ERROR,
    payload: errorStr,
  });
};
export function forgetWidgets() {
  return {
    type: Types.FORGET_JOCK_CONSOLE_WIDGETS,
  };
}

export const updateWidget = (data, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.UPDATE_JOCK_CONSOLE_WIDGET_REQUEST });
    //api call for store data
    let response = await Api.doCall(
      `${Const.JOCK_CONSOLE_WIDGETS()}/${data.id}`,
      "PUT",
      data
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        //response data
        let responseData = JSON.parse(JSON.stringify(response.data));
        dispatch({
          type: Types.UPDATE_JOCK_CONSOLE_WIDGET_SUCCESS,
          payload: responseData,
        });
        if (isFunction(cb)) cb(responseData);
        return;
      }
      //error data
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.UPDATE_JOCK_CONSOLE_WIDGET_ERROR,
    payload: {
      error: errorStr,
    },
  });
};

export const addWidget = (data, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.ADD_JOCK_CONSOLE_WIDGET_REQUEST });
    //api call for store data
    let response = await Api.doCall(
      `${Const.JOCK_CONSOLE_WIDGETS()}`,
      "POST",
      data
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        //response data
        let responseData = JSON.parse(JSON.stringify(response.data));
        dispatch({
          type: Types.ADD_JOCK_CONSOLE_WIDGET_SUCCESS,
          payload: responseData,
        });
        if (isFunction(cb)) cb(responseData);
        return;
      }
      //error data
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.ADD_JOCK_CONSOLE_WIDGET_ERROR,
    payload: {
      error: errorStr,
    },
  });
};
export const deleteWidget = (id, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.DELETE_JOCK_CONSOLE_WIDGET_REQUEST });
    //api call for store data
    let response = await Api.doCall(
      `${Const.JOCK_CONSOLE_WIDGETS()}/${id}`,
      "DELETE"
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        //response data
        dispatch({
          type: Types.DELETE_JOCK_CONSOLE_WIDGET_SUCCESS,
        });
        if (isFunction(cb)) cb();
        return;
      }
      //error data
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.DELETE_JOCK_CONSOLE_WIDGET_ERROR,
    payload: {
      error: errorStr,
    },
  });
};
export const widgetFetchFeed = (data, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.JOCK_CONSOLE_WIDGET_FETCH_FEED_REQUEST });
    //api call for store data
    const response = await Api.doCall(
      `${Const.JOCK_CONSOLE_WIDGETS()}/fetch_feed`,
      "POST",
      data
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        //response data
        dispatch({
          type: Types.JOCK_CONSOLE_WIDGET_FETCH_FEED_SUCCESS,
          payload: {
            id: data.id,
            data: response.data,
          },
        });
        if (isFunction(cb)) cb();
        return;
      }
      //error data
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.JOCK_CONSOLE_WIDGET_FETCH_FEED_ERROR,
    payload: {
      error: errorStr,
    },
  });
};
export const addWidgetLocal = (data, cb) => async (dispatch) => {
  dispatch({
    type: Types.ADD_JOCK_CONSOLE_WIDGET_LOCAL_SUCCESS,
    payload: data,
  });
  return;
};
export const deleteWidgetLocal = (data, cb) => async (dispatch) => {
  dispatch({
    type: Types.DELETE_JOCK_CONSOLE_WIDGET_LOCAL_SUCCESS,
    payload: data,
  });
  return;
};
