import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import OrderDetailsIncompleteContract from "./view";

const Enhanced = connect(
    (state, props) => ({
      user: state.auth.user,
      ...props
    }),
    {
    }
  )(OrderDetailsIncompleteContract);

export default withRouter(Enhanced);