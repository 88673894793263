import React from "react";
import { Row, Col } from "reactstrap";
import Spinner from "components/Spinner";
export default () => (
  <Row>
    <Col>
      <Spinner isLoading={true} className="admin-login-loading" />
    </Col>
  </Row>
);
