import StationSelectors from "./view";
import { injectIntl } from "react-intl";
import { connect } from 'react-redux'
import { get } from 'lodash';
import { compose, withState, withHandlers } from "recompose";

export default injectIntl(
  compose(
    connect((state) => ({
      productionMultimarket: get(
        state,
        "auth.info.production_multimarket",
        false
      ),
      digitalMultimarket: get(
        state, 
        "auth.info.digital_multimarket", 
        false
      ),
      promotionMultimarket: get(
        state,
        "auth.info.promotion_multimarket",
        false
      ),
    }), {}),
    withState("activeTab", "setActiveTab", "market"),
    withHandlers({
      toggleTab: ({ activeTab, setActiveTab }) => (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
      },
    })
  )(StationSelectors)
);
