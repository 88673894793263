import React from "react";
import bn from "utils/bemnames";
import classnames from "classnames";
import { FaClose } from "react-icons/lib/fa";
import { labelToNameField } from "utils/helpers";
import UploadFiles from "components/UploadFiles";
import { FormGroup, Row, Col, Label } from "reactstrap";
import ToggleSwitch from "components/ToggleSwitch";
import TextInput from "components/TextInput";
import { default as CheckboxDefault } from "components/Checkbox";
import { get, xor, concat, filter } from "lodash";
import { MenuIcon, CopyIcon } from "components/CustomIcons";
import HandleAssets from "components/HandleAssets";
import { mimesFileWithExt } from "../helper";
import { Asterisk } from "components/Elements";
import { FormattedMessage } from "react-intl";
import { arrayContainsArray } from "utils/helpers";

const bem = bn.create("form-builder-fields");
const allows = [
  {
    label: '.doc(x)',
    value: ['.doc', '.docx']
  },
  {
    label: '.pdf',
    value: ['.pdf']
  },
  {
    label: '.xls(x)',
    value: ['.xls', '.xlsx']
  },
  {
    label: '.ppt(x)',
    value: ['.ppt', '.pptx']
  },
  {
    label: '.jpg',
    value: ['.jpg']
  },
  {
    label: '.png',
    value: ['.png']
  },
  {
    label: '.gif',
    value: ['.gif']
  },
  {
    label: '.eps',
    value: ['.eps']
  },
  {
    label: '.wav',
    value: ['.wav']
  },
  {
    label: '.aiff',
    value: ['.aiff']
  },
  {
    label: '.mp3',
    value: ['.mp3']
  },
  {
    label: '.mp4',
    value: ['.mp4']
  },
  {
    label: '.mpg',
    value: ['.mpg']
  },
  {
    label: '.mov',
    value: ['.mov']
  },
  {
    label: '.wmv',
    value: ['.wmv']
  },
  {
    label: '.qt',
    value: ['.qt']
  },
];
const BasicFileUpload = ({
  intl,
  isNew,
  isView,
  value,
  field,
  errors,
  onChange,
  onDelete,
  allowDelete,
  touched,
  isFormBuilder,
  highlightFields,
  disabled,
  setIsDirty,
  isMasterListener,
  colNumber,
  isRenderRow,
  isAllowDuplicate,
  onDuplicate,
  isShowOffText,
}) => {
  // UI for form builder
  if (isFormBuilder) {
    if (isNew) {
      const isHighlightFormField =
        filter(
          highlightFields,
          (highlightFieldItem) =>
            highlightFieldItem.uuid === value.uuid &&
            highlightFieldItem.count_highlight > 0
        ).length > 0;
      return (
        <Row>
          <Col className="form-builder-field">
            <div className={bem.b()}>
              <div
                className={classnames(bem.e("form-field-group"), {
                  [bem.e("form-field-group-highlight")]: isHighlightFormField,
                  [bem.e("form-field-group-disable")]:
                    highlightFields.length > 0 &&
                    !isHighlightFormField &&
                    value.is_controlled,
                })}
              >
                <div
                  className={classnames(
                    bem.e("form-field-group-head"),
                    bem.e("form-field-group-media")
                  )}
                >
                  <div className={bem.e("form-field-group-head-single-left")}>
                    <span className={bem.e("menu-icon")}>
                      <MenuIcon />
                    </span>
                    <Row>
                      <Col xs={9}>
                        <div
                          className={classnames(bem.e("input-label"), {
                            [bem.e("master-input-field")]: isMasterListener,
                          })}
                        >
                          <TextInput
                            label={null}
                            name="label"
                            type="text"
                            placeholder={intl.formatMessage({
                              id: "form builder > label basic file upload",
                            })}
                            value={value.label}
                            onChange={({ target }) => {
                              onChange({
                                ...value,
                                label: target.value,
                              });
                            }}
                            error={get(errors, "label", "")}
                            disabled={disabled}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                  {isAllowDuplicate && (
                    <div className={bem.e("duplicate")} onClick={onDuplicate}>
                      <CopyIcon width={20.55} height={20} />
                      <FormattedMessage id="form builder > duplicate" />
                    </div>
                  )}
                  {allowDelete && (
                    <div
                      className={bem.e("form-field-group-head-right")}
                      onClick={onDelete}
                    >
                      <FaClose size="23" color="#C2D4E0" />
                    </div>
                  )}
                </div>
                <Row className={bem.e("form-field-group-bottom")}>
                  <Col xs={9}>
                    <div className={bem.e("file-mimes")}>
                      <div className={bem.e("subtitle")}>
                        <FormattedMessage id="form builder > allow file types" />
                      </div>
                      {allows.map((option, index) => (
                        <FormGroup key={index}>
                          <CheckboxDefault
                            text={
                              <span className={bem.e("media-mime-label")}>
                                {option.label}
                              </span>
                            }
                            checked={
                              arrayContainsArray(
                                value.mimes,
                                option.value
                              )
                            }
                            onChange={() => {
                              let mimes = xor(value.mimes,option.value);
                              onChange({
                                ...value,
                                mimes,
                              });
                            }}
                            disabled={disabled}
                          />
                        </FormGroup>
                      ))}
                    </div>
                  </Col>
                  <Col xs={3}>
                    <ToggleSwitch
                      leftComponent={
                        <span
                          className={classnames({
                            [bem.e("switch-active")]: !get(
                              value,
                              "is_required",
                              false
                            ),
                          })}
                        >
                          {isShowOffText
                            ? intl.formatMessage({
                                id: "form builder > off",
                              })
                            : ""}
                        </span>
                      }
                      rightComponent={
                        <span
                          className={classnames({
                            [bem.e("switch-active")]: get(
                              value,
                              "is_required",
                              false
                            ),
                          })}
                        >
                          {intl.formatMessage({
                            id: "form builder > required",
                          })}
                        </span>
                      }
                      name="is_required"
                      switchProps={{
                        checked: !get(value, "is_required", false),
                        onChange: (checked) => {
                          onChange({
                            ...value,
                            is_required: !checked,
                          });
                        },
                        disabled: disabled,
                        offColor: "#795AFA",
                        onColor: "#E3ECF1",
                        uncheckedIcon: false,
                        checkedIcon: false,
                        handleDiameter: 22,
                        width: 50,
                        height: 26,
                      }}
                    />
                    <div
                      className={classnames(
                        bem.e("description-text"),
                        bem.e("media-description-text")
                      )}
                    >
                      <p>
                        <FormattedMessage id="form builder > media description max file size" />
                      </p>
                      <p>
                        <FormattedMessage id="form builder > media description max files" />
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row>
          <Col>
            <div className={bem.b()}>
              <div className={bem.e("form-field-group-saved")}>
                <Label className={bem.e("form-field-label")}>
                  <span>
                    {get(value, "label", "")}{" "}
                    {get(value, "is_required", false) && <Asterisk>*</Asterisk>}
                  </span>
                </Label>
                <UploadFiles
                  attachText={intl.formatMessage({
                    id: "form builder > media placeholder",
                  })}
                  // mimes
                  mimes={get(value, "mimes")}
                  acceptMultiple={true}
                  disabled
                  files={null}
                  isHideFilesDragArea={true}
                  onUploadedFiles={(value) => {}}
                  onDeleted={(value) => {}}
                >
                  <FormattedMessage id="form builder > button upload" />
                </UploadFiles>
                {isAllowDuplicate && (
                  <div className={bem.e("duplicate")} onClick={onDuplicate}>
                    <CopyIcon />
                    <FormattedMessage id="form builder > duplicate" />
                  </div>
                )}
                {allowDelete && (
                  <div
                    className={bem.e("form-field-group-saved-button-close")}
                    onClick={onDelete}
                  >
                    <FaClose size="23" color="#C2D4E0" />
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    // ui for form
  } else {
    const fieldName = labelToNameField(get(field, "label", ""));
    if (isView) {
      return (
        <Row>
          <Col>
            <div className={bem.b()}>
              <div className={bem.e("form-field-group-saved")}>
                <Label className={bem.e("form-field-label")}>
                  <span>{get(field, "label", "")} </span>
                </Label>
                {get(value, "length") > 0 ? (
                  <HandleAssets
                    files={value}
                    viewDisabled={false}
                    renamingDisabled={true}
                    renamingHidden={true}
                    deleteDisabled={true}
                    deleteHidden={true}
                    downloadDisabled={false}
                    isView={isView}
                  />
                ) : (
                  <FormattedMessage id="form builder > none" />
                )}
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    const extensions = get(field, "mimes", []);
    let mimes = [];
    extensions.forEach((ext) => {
      mimes.push(ext);
      const mimeForExt = mimesFileWithExt.find((item) => item.ext.toLowerCase() === ext.toLowerCase());
      if (mimeForExt) {
        mimes = mimes.concat(mimeForExt.mimes);
      }
    });

    const elementField = (
      <div className={bem.b()}>
        <div
          className={classnames(
            bem.e("form-field-group-saved"),
            "form-builder-edit-field-2"
          )}
        >
          <FormGroup className={bem.e("form-group")}>
            <Label className={bem.e("form-field-label")}>
              <span>
                {get(field, "label", "")}{" "}
                {get(field, "is_required", false) && <Asterisk>*</Asterisk>}
              </span>
            </Label>
            <HandleAssets
              files={value}
              onUploadedFiles={(newValue) => {
                onChange(newValue);
                if (setIsDirty) setIsDirty(true); //will touch auto save in the custom form
              }}
              onDeleted={(newValue) => {
                onChange(newValue);
              }}
            />
            <UploadFiles
              attachText={intl.formatMessage({
                id: "form builder > upload assets",
              })}
              // mimes
              mimes={mimes}
              acceptMultiple={true}
              files={value || []}
              isHideFilesDragArea={true}
              onUploadedFiles={(updatedFiles) => {
                let newValue = updatedFiles;
                if (value) {
                  newValue = concat(value, newValue);
                }
                onChange(newValue);
              }}
              onDeleted={(newValue) => {
                // onChange(newValue);
              }}
              disabled={disabled}
              maxFiles={20} // 20files
              maxSize={10485760} // 10M
              className={bem.e("assets")}
              dropTextClass={bem.e("drop-text-class")}
            >
              <FormattedMessage id="form builder > button upload" />
            </UploadFiles>
            {!!get(errors, fieldName, "") && get(touched, fieldName, false) && (
              <span className="text-danger">{get(errors, fieldName, "")}</span>
            )}
          </FormGroup>
        </div>
      </div>
    );
    if (isRenderRow)
      return (
        <Row>
          <Col>{elementField}</Col>
        </Row>
      );
    return <Col xs={colNumber}>{elementField}</Col>;
  }
};
BasicFileUpload.defaultProps = {
  isNew: false,
  isFormBuilder: true,
  errors: {},
  touched: {},
  onChange: () => {},
  onDelete: () => {},
  onDuplicate: () => {},
  allowDelete: true,
  disabled: false,
  isMasterListener: false,
  colNumber: 6,
  isRenderRow: true,
  isAllowDuplicate: false,
  isShowOffText: true,
};
export default BasicFileUpload;
