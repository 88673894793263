import React from "react";
import { connect } from "react-redux";
import { compose, lifecycle, withState, withHandlers } from "recompose";
import { withRouter } from "react-router-dom";
import { get, find } from "lodash";
import WinnersList from "./view";
import { injectIntl } from "react-intl";
import {
  getWinners,
  saveSearch,
  exportWinners,
  getSavedSearches,
  deleteSavedSearch,
  getAllSavedSearches,
  setAdvancedSearch,
} from "store/actions/winners";
import { getClocksData } from "store/actions/programmings";
import { pageSize, fulfillmentList } from "utils/config";
import { download } from "components/UploadFile/helper";
import { MdImportantDevices } from "react-icons/lib/md";
import ToastManager from "components/ToastManager";

const Enhanced = compose(
  connect(
    (state, props) => {
      return {
        isLoading:
          get(state, "winners.winners.loading", false) ||
          get(state, "winners.exportWinners.loading", false) ||
          get(state, "winners.saveSearch.loading", false),
        winners: get(state, "winners.winners", {}),
        savedSearches: get(state, "winners.savedSearches.data", {}),
        allSavedSearches: get(state, "winners.allSavedSearches.data", {}),
        user: state.auth.user,
        clocksData: get(state, "programmings.clocksData.data", {}),
        info: state.auth.info,
        advancedSearch: get(state, "winners.advancedSearch", {}),
        ...props,
      };
    },
    {
      getClocksData,
      getWinners,
      saveSearch,
      exportWinners,
      getSavedSearches,
      deleteSavedSearch,
      getAllSavedSearches,
      setAdvancedSearch,
    }
  ),
  withState("isSubmittedAdvancedSearch", "setIsSubmittedAdvancedSearch", false),
  withState("isShowSaveSearchModal", "setIsShowSaveSearchModal", false),
  withState("isShowExportModal", "setIsShowExportModal", false),
  withState("isShowAdvancedSearchModal", "setIsShowAdvancedSearchModal", false),
  withState("filtered", "setFiltered", [
    {
      key: "keyword",
      value: "",
    },
    {
      key: "stations",
      value: [],
    },
  ]),
  withState("sorted", "setSorted", [{ id: "created_ts", desc: true }]),
  withState("page", "setPage", 1),
  withState("isShowSaveSearch", "setIsShowSaveSearch", false),
  withHandlers({
    onGetClocksData: ({ getClocksData }) => (stationKey) => {
      getClocksData({
        data: {
          station_id: stationKey,
        },
      });
    },
    getAllSavedSearchesList: ({ getAllSavedSearches }) => async (
      params = {}
    ) => {
      getAllSavedSearches({
        pageSize: 999,
        ...params, //override
      });
    },
    getSavedSearchList: ({ getSavedSearches, page }) => async (params = {}) => {
      getSavedSearches({
        pageSize: 4,
        page,
        ...params, //override
      });
    },
    getMoreSavedSearchList: ({ getSavedSearches }) => (page) => {
      getSavedSearches({
        pageSize: 4,
        page: Number(page) + 1,
      });
    },
    getWinnersList: ({ getWinners, sorted, filtered, page }) => async (
      params = {},
      cb = null
    ) => {
      getWinners(
        {
          pageSize,
          page,
          sorted,
          filtered,
          ...params, //override
        },
        cb
      );
    },
    getMoreWinners: ({ getWinners, sorted, filtered, setPage }) => (page) => {
      const newPage = Number(page) + 1;
      getWinners({
        pageSize,
        page: newPage,
        sorted,
        filtered,
      });
      setPage(newPage);
    },
    onChangeFiltered: ({ setFiltered, filtered }) => (key, newValue) => {
      const newFiltered = filtered.map((item) => {
        if (item.key === key) {
          return {
            ...item,
            value: newValue,
          };
        }
        return item;
      });
      setFiltered(newFiltered);
      return newFiltered;
    },
  }),
  withHandlers({
    onSelectedSavedSearch: ({
      filtered,
      setFiltered,
      setAdvancedSearch,
      getWinnersList,
      sorted,
      setIsShowSaveSearch,
      setIsSubmittedAdvancedSearch,
      onChangeFiltered,
    }) => (item) => {
      const searchData = get(item, "search", {});
      const newFiltered = filtered.map((item) => {
        if (item.key === "stations") {
          return {
            ...item,
            value: searchData.stations || [],
          };
        }
        if (item.key === "keyword") {
          return {
            ...item,
            value: searchData.keyword || "",
          };
        }
        return item;
      });
      let winnersParams = {
        page: 1,
        sorted,
        filtered: newFiltered,
      };
      if (get(searchData, "isAdvancedSearch", false)) {
        const advancedSearch = get(searchData, "advancedSearch", {});
        const advancedSearchData = {
          stations: advancedSearch.stations || [],
          prize_status: advancedSearch.prize_status || [],
          keyword: advancedSearch.keyword || "",
          contest_category: advancedSearch.contest_category || "",
          min_prize_value: advancedSearch.min_prize_value || "",
          prize_won_start_date: advancedSearch.prize_won_start_date || "",
          prize_won_end_date: advancedSearch.prize_won_end_date || "",
          prize_distribution_method:
            advancedSearch.prize_distribution_method || "",
        };
        setAdvancedSearch(advancedSearchData);
        winnersParams = {
          ...winnersParams,
          advance_search: advancedSearchData,
        };
      }
      setFiltered(newFiltered);
      getWinnersList(winnersParams, () => {
        // set text in the searchbar
        if (searchData.isAdvancedSearch) {

          const advancedSearch = get(searchData, "advancedSearch", {});
          const advancedSearchData = {
            stations: advancedSearch.stations || [],
            prize_status: advancedSearch.prize_status || [],
            keyword: advancedSearch.keyword || "",
            contest_category: advancedSearch.contest_category || "",
            min_prize_value: advancedSearch.min_prize_value || "",
            prize_won_start_date: advancedSearch.prize_won_start_date || "",
            prize_won_end_date: advancedSearch.prize_won_end_date || "",
            prize_distribution_method:
              advancedSearch.prize_distribution_method || "",
          };

          let numberFieldsData = 0;
          const fields = [
            'keyword',
            'contest_category', 
            'min_prize_value', 
            'prize_distribution_method', 
            'prize_won_start_date', 
            'prize_won_end_date',
            'prize_status'
          ]

          fields.map(item => {
            if (item === 'prize_status') {
              numberFieldsData = numberFieldsData + get(advancedSearchData, "prize_status.length", 0)
            } else if (get(advancedSearchData, item)) 
              numberFieldsData = numberFieldsData + 1;
            return true;
          })

          const isMultipleCriteria = numberFieldsData > 1 ? true : false

          // onChangeFiltered("keyword", "Multiple Criteria");
          let text = ''
          fields.find(item => {
            if (item === 'prize_status' && get(advancedSearchData, "prize_status.0")) {
              text = get(advancedSearchData, "prize_status.0");
              return true;
            } else if (get(advancedSearchData, item)) {
              text = get(advancedSearchData, item)

              if (item === 'prize_distribution_method') {
                const method = fulfillmentList.find( f => f.value === text);
                text = method.label;
              } else if ( item === 'min_prize_value') {
                text = `Minimum Prize Value $${text}`
              }

              return true;
            }
            
            return false
          })

          // set text in the searchbar
          if (isMultipleCriteria) {
            onChangeFiltered("keyword", "Multiple Criteria");
          } else if (text) {
            onChangeFiltered("keyword", text);
          }
        }
      });
      setIsSubmittedAdvancedSearch(searchData.isAdvancedSearch);
      setIsShowSaveSearch(false);
    },
    onDeleteSavedSearch: ({
      getSavedSearchList,
      getAllSavedSearchesList,
      deleteSavedSearch,
      intl,
    }) => (item) => {
      deleteSavedSearch(item.id, () => {
        getSavedSearchList();
        getAllSavedSearchesList();
        ToastManager.show({
          title: <MdImportantDevices />,
          message: intl.formatMessage({
            id: "winners > deleted successfully",
          }),
          level: "success",
        });
      });
    },
    onExportList: ({
      setIsShowExportModal,
      filtered,
      sorted,
      exportWinners,
      page,
      advancedSearch,
      isSubmittedAdvancedSearch,
    }) => (values) => {
      setIsShowExportModal(false);
      let params = {
        sorted,
        filtered,
        page,
        selected_fields: values.fields,
      };
      if (isSubmittedAdvancedSearch) {
        const newFiltered = filtered.map((item) => {
          if (item.key === "keyword") {
            return {
              ...item,
              value: "",
            };
          }
          return item;
        });
        params = {
          ...params,
          filtered: newFiltered,
          advance_search: advancedSearch,
        };
      }
      exportWinners(params, (data) => {
        download(data, "winners.csv");
      });
    },
    onSaveSearch: ({
      setIsShowSaveSearchModal,
      advancedSearch,
      saveSearch,
      intl,
      getSavedSearchList,
      setIsShowSaveSearch,
      getAllSavedSearchesList,
      filtered,
      isSubmittedAdvancedSearch,
    }) => (values, formProps) => {
      const searchText = find(filtered, (i) => i.key === "keyword");
      const stations = find(filtered, (i) => i.key === "stations");

      let saveSearchParams = {
        keyword: get(searchText, "value", ""),
        stations: get(stations, "value", []),
      };
      if (isSubmittedAdvancedSearch) {
        saveSearchParams = {
          advancedSearch,
          keyword: "",
          stations: get(stations, "value", []),
          isAdvancedSearch: true,
        };
      }
      saveSearch(
        {
          ...values,
          search: saveSearchParams,
        },
        (response) => {
          if (typeof response === "string") {
            formProps.setFieldError("title", response);
          } else {
            setIsShowSaveSearchModal(false);
            setIsShowSaveSearch(false);
            getSavedSearchList();
            getAllSavedSearchesList();

            ToastManager.show({
              title: <MdImportantDevices />,
              message: intl.formatMessage({
                id: "winners > saved successfully",
              }),
              level: "success",
            });
          }
        }
      );
    },
    onSort: ({
      setSorted,
      sorted,
      getWinnersList,
      setIsSubmittedAdvancedSearch,
    }) => (sort) => {
      let newSort = {};
      const sortedData = find(sorted, (item) => item.id === sort);
      if (sortedData) {
        newSort = {
          id: sort,
          desc: !sortedData.desc,
        };
      } else {
        newSort = {
          id: sort,
          desc: true,
        };
      }
      setSorted([newSort]);
      getWinnersList({ sorted: [newSort], page: 1 });
      setIsSubmittedAdvancedSearch(false);
    },
    onSearch: ({
      filtered,
      getWinnersList,
      setIsSubmittedAdvancedSearch,
    }) => () => {
      getWinnersList({
        page: 1,
        filtered,
      });
      setIsSubmittedAdvancedSearch(false);
    },
    onAdvancedSearch: ({
      setIsShowAdvancedSearchModal,
      getWinnersList,
      setAdvancedSearch,
      setIsShowSaveSearch,
      filtered,
      setFiltered,
      setIsSubmittedAdvancedSearch,
      onChangeFiltered,
    }) => (values) => {
      setIsShowAdvancedSearchModal(false);
      setIsShowSaveSearch(true);
      setAdvancedSearch(values);
      setIsSubmittedAdvancedSearch(true);

      let numberFieldsData = 0;
      const fields = [
        'keyword',
        'contest_category', 
        'min_prize_value', 
        'prize_distribution_method', 
        'prize_won_start_date', 
        'prize_won_end_date',
        'prize_status'
      ]

      fields.map(item => {
        if (item === 'prize_status') {
          numberFieldsData = numberFieldsData + get(values, "prize_status.length", 0)
        } else if (get(values, item)) 
          numberFieldsData = numberFieldsData + 1;
        return true;
      })

      const isMultipleCriteria = numberFieldsData > 1 ? true : false

      // console.log('values', values)
      const newFiltered = filtered.map((item) => {
        if (item.key === "keyword") {
          return {
            ...item,
            value: isMultipleCriteria ? "" : values.keyword,
          };
        }
        if (item.key === "stations") {
          return {
            ...item,
            value: values.stations,
          };
        }
        return item;
      });
      setFiltered(newFiltered);
      getWinnersList(
        {
          page: 1,
          filtered: newFiltered,
          advance_search: values,
        },
        () => {

          let text = ''
          fields.find(item => {
            if (item === 'prize_status' && get(values, "prize_status.0")) {
              text = get(values, "prize_status.0", "");
              return true;
            } else if (get(values, item) && typeof get(values, item) === 'string') {
              text = get(values, item)

              if (item === 'prize_distribution_method') {
                const method = fulfillmentList.find( f => f.value === text);
                text = method.label;
              } else if ( item === 'min_prize_value') {
                text = `Minimum Prize Value $${text}`
              }

              return true;
            }
            
            return false
          })

          // set text in the searchbar
          if (isMultipleCriteria) {
            onChangeFiltered("keyword", "Multiple Criteria");
          } else if (text) {
            onChangeFiltered("keyword", text);
          }
        }
      );
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.getAllSavedSearchesList();
    },
    componentDidUpdate() {
      if (get(this.props, "winners.is_dirty")) {
        this.props.getWinnersList();
        this.props.setIsSubmittedAdvancedSearch(false);
      }
    },
  })
)(WinnersList);
export default withRouter(injectIntl(Enhanced));
