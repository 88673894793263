import React from "react";
import { get, includes } from "lodash";
import TextInput from "components/TextInput";
import { FaSearch } from "react-icons/lib/fa";
import { Row, Col, ButtonGroup, Button, Card, CardBody } from "reactstrap";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import Table from "components/Table";
import BroadcastingModal from "components/BroadcastingModal";
import classnames from "classnames";
import ConfirmationModal from "components/ConfirmationModal";
import PrivilegedComponent from "components/PrivilegedComponent";
import { PRIVILEGES } from "utils/constants";
import Spinner from "components/Spinner";
import useInfiniteScroll from "utils/useInfiniteScroll";
import { validatePrivileges } from "utils/helpers";
import { TrashListIcon } from "components/CustomIcons";

const ServersList = (props) => {
  const {
    filtered,
    sorted,
    setSorted,
    servers,
    searchText,
    onChangeInput,
    onSearchKeyDown,
    onSearchClicked,
    auth,
    isLoading,
    isLazyLoading,
    onLoadMore,
    isFocused,
  } = props;
  const privileges = get(auth, "user.privileges", []);
  const serverList = get(servers, "data.data") || [];
  const pages = get(servers, "data.last_page") || 1;
  const total = get(servers, "data.total");
  const index_view =
    props.auth.info.app_flags &&
    props.auth.info.app_flags.indexOf("SERVERS_LIST") > -1;

  const authIsGodAdmin = get(auth, "user.is_god_admin", false);
  const authIsVentiveAdmin = get(auth, "user.id") === 1;
  const onClickItem = (item) => {
    const isEditable = validatePrivileges({
      requires: [PRIVILEGES.EDIT_SERVERS],
      privileges,
      user: props.auth.user
    });
    if (isEditable) {
      props.onSelectedEdit({ [item.id]: item });
      return;
    }
    const isViewable = validatePrivileges({
      requires: [PRIVILEGES.VIEW_SERVERS],
      privileges,
      user: props.auth.user
    });
    if (isViewable) {
      props.onSelectedView({
        [item.id]: item,
      });
    }
  };

  useInfiniteScroll({
    useWindow: false,
    elementId: "cr-app-main",
    onLoadMore: onLoadMore,
    shouldLoadMore:
      isFocused &&
      !isLazyLoading &&
      get(servers, "data.last_page") !== get(servers, "data.current_page"),
  });

  return (
    <>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <Row>
                <Col sm="12">
                  <div className={classnames("head-admin-list-wrapper")}>
                    {authIsGodAdmin &&
                    auth.info.app_flags &&
                    auth.info.app_flags.indexOf("SERVERS_LIST") > -1 ? (
                      <PrivilegedComponent requires={[PRIVILEGES.ADD_SERVERS]}>
                        <Button
                          color="blue"
                          onClick={() => props.onBroadcastModalToggle()}
                          className="mb-2"
                        >
                          <FormattedMessage id="server > add new broadcasting group" />
                        </Button>
                      </PrivilegedComponent>
                    ) : null}
                    <TextInput
                      type="text"
                      name="q"
                      value={searchText}
                      onChange={onChangeInput}
                      onKeyDown={onSearchKeyDown}
                      placeholder={props.intl.formatMessage({
                        id: "server > placeholder search",
                      })}
                      rightComponent={
                        <Button color="" className="btn-search">
                          <FaSearch size={17} onClick={onSearchClicked} />
                        </Button>
                      }
                    />
                  </div>
                </Col>
              </Row>
              <Table
                className="table-hidden-checkbox"
                manual
                data={serverList}
                pages={pages}
                loading={servers.loading}
                showPagination={false}
                total={total}
                // Controlled props
                filtered={filtered}
                sorted={sorted}
                // Callbacks
                onSortedChange={(sorted) => setSorted(sorted)}
                getTdProps={(state, rowInfo, column, instance) => {
                  return {
                    onClick: (e, handleOriginal) => {
                      const { original } = rowInfo;
                      const columnId = column.id;
                      if (!includes(["actions"], columnId)) {
                        onClickItem(original);
                      } else if (handleOriginal) {
                        handleOriginal();
                      }
                    },
                  };
                }}
                columns={[
                  {
                    Header: "",
                    sortable: false,
                    resizable: false,
                    filterable: false,
                    width: 100,
                    Cell: ({ original: server }) => {
                      return (
                        server.logo && (
                          <div
                            className="w-100 h-100 centered-bg-image"
                            style={{
                              background: "url(" + server.logo + ")",
                            }}
                          ></div>
                        )
                      );
                    },
                  },
                  {
                    Header: <FormattedMessage id="server > Name" />,
                    accessor: "name",
                    Cell: ({ value, original: server }) => (
                      <a href={server.url} className="link-subdomain">
                        {value}
                      </a>
                    ),
                  },
                  {
                    Header: <FormattedMessage id="server > Actions" />,
                    sortable: false,
                    resizable: false,
                    filterable: false,
                    width: 300,
                    style: { justifyContent: "center", textAlign: "center" },
                    id: "actions",
                    Cell: ({ original: server }) => {
                      return (
                        <ButtonGroup className={"servers-actions"}>
                          {authIsVentiveAdmin && (
                            <PrivilegedComponent
                              requires={[PRIVILEGES.DELETE_SERVERS]}
                            >
                              <Button
                                color="link"
                                onClick={() => {
                                  props.openDeleteModal(server.id);
                                }}
                              >
                                <TrashListIcon />
                              </Button>
                            </PrivilegedComponent>
                          )}
                          {index_view && server.url && (
                            <Button
                              color="blue"
                              className="ml-5"
                              onClick={() =>
                                props.onRequestLoginTargetServer(server)
                              }
                            >
                              <FormattedMessage id="server > log in" />
                            </Button>
                          )}
                        </ButtonGroup>
                      );
                    },
                  },
                ]}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ConfirmationModal
        isOpen={props.isDeleteModalOpen}
        title={props.intl.formatMessage({
          id: "server > are you sure you want to remove broadcasting group",
        })}
        onConfirm={props.onDelete}
        onToggle={props.closeDeleteModal}
        onCancel={props.closeDeleteModal}
        isCloseOutside={false}
      />
      <BroadcastingModal
        isOpen={props.isBroadcastModalOpen}
        onToggle={props.onBroadcastModalToggle}
      />
      <Spinner isLoading={isLoading} />
    </>
  );
};
ServersList.defaultProps = {
  onSelectedEdit: () => {},
  onSelectedView: () => {},
  onSelectedAdd: () => {},
};
ServersList.propsType = {
  onSelectedEdit: PropTypes.func,
  onSelectedView: PropTypes.func,
  onSelectedAdd: PropTypes.func,
};
export default ServersList;
