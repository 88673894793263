import React, { useEffect } from "react";
import Page from "components/Page";
import { FormattedMessage } from "react-intl";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { adminTabs } from "utils/config";
import URL from "utils/urls";
import bn from "utils/bemnames";
import classnames from "classnames";
import SelectAudience from "components/SelectAudience";
import PrivilegedComponent from "components/PrivilegedComponent";
import { Asterisk } from "components/Elements";
import { PRIVILEGES } from "utils/constants";
import TemplateItem from "components/SystemTemplates/TemplateItem";
import CreateTemplate from "components/SystemTemplates/CreateTemplate";
import useInfiniteScroll from "utils/useInfiniteScroll";
import BlockingComponent from "components/BlockingComponent";
import ConfirmationModal from "components/ConfirmationModal";
import Spinner from "components/Spinner";
import { find, get, isArray } from "lodash";
import history from "components/History";

const bem = bn.create("system-templates");

const SystemTemplates = (props) => {
  const {
    stations,
    intl,
    currentStation,
    setCurrentStation,
    activeTab,
    setActiveTab,
    listTemplates,
    getMoreItems,
    hasMore,
    isLoading,
  } = props;

  useInfiniteScroll({
    useWindow: false,
    elementId: "cr-app-main",
    onLoadMore: getMoreItems,
    shouldLoadMore: hasMore,
  });

  useEffect(() => {
    if (activeTab && get(currentStation, "key")) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const form = urlParams.get("form");
      props.getSystemTemplates(
        {
          type: activeTab,
          station_id: get(currentStation, "key"),
        },
        (data) => {
          // set active form from url
          let searchParams = {
            tab: activeTab,
            station: get(currentStation, "key", ""),
          };
          const selectedFormId = find(data, (i) => i.id === Number(form));
          if (selectedFormId) {
            props.setOpenSystemTemplate(Number(form));
            searchParams.form = form;
          }
          // replace params url
          history.push({
            path: "system-templates",
            search: "?" + new URLSearchParams(searchParams).toString(),
          });
        }
      );
    }
  }, [activeTab, currentStation]);
  return (
    <Page
      className={bem.b()}
      applyPadding={false}
      title={
        <div>
          <nav
            className="cr-page__breadcrumb"
            aria-label="breadcrumb"
            style={{ fontSize: "initial" }}
          >
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={URL.ADMINS({ tab: adminTabs.TAB_UNKNOWN })}>
                  <FormattedMessage id="system templates > home" />
                </Link>
              </li>
              <li className="active breadcrumb-item" aria-current="page">
                <FormattedMessage id="system templates > system template" />
              </li>
            </ol>
          </nav>
          <span className="page-title">
            <FormattedMessage id="system templates > page title" />
          </span>
        </div>
      }
    >
      <div className={"page-container"}>
        {stations.length > 0 ? (
          <SelectAudience
            name="select_stations"
            item={{ field: "stations" }}
            label={
              <span>
                {intl.formatMessage({
                  id: "system templates > station",
                })}
                <Asterisk> *</Asterisk>
              </span>
            }
            placeholder={intl.formatMessage({
              id: "system templates > select station",
            })}
            template={{ key: "liner" }}
            isFilterByServerConfig={true}
            onChange={(station) => {
              setCurrentStation(station);
            }}
            required={false}
            isMulti={false}
            value={get(currentStation, "value", "")}
          />
        ) : null}
        {currentStation ? (
          <>
            <Nav tabs>
              <PrivilegedComponent
                requires={{
                  or: [PRIVILEGES.VIEW_FORMS, PRIVILEGES.EDIT_FORMS],
                }}
              >
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "release_form",
                    })}
                    onClick={() => {
                      setActiveTab("release_form");
                    }}
                  >
                    <FormattedMessage id="system templates > prize release" />
                  </NavLink>
                  <NavLink
                    className={classnames({
                      active: activeTab === "contest_rules",
                    })}
                    onClick={() => {
                      setActiveTab("contest_rules");
                    }}
                  >
                    <FormattedMessage id="system templates > contest rules" />
                  </NavLink>
                  <NavLink
                    className={classnames({
                      active: activeTab === "winner_notification",
                    })}
                    onClick={() => {
                      setActiveTab("winner_notification");
                    }}
                  >
                    <FormattedMessage id="system templates > winner notification" />
                  </NavLink>
                  <NavLink
                    className={classnames({
                      active: activeTab === "rejection_notification",
                    })}
                    onClick={() => {
                      setActiveTab("rejection_notification");
                    }}
                  >
                    <FormattedMessage id="system templates > rejection notification" />
                  </NavLink>
                </NavItem>
              </PrivilegedComponent>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="release_form">
                {activeTab === "release_form" && (
                  <CreateTemplate type="release_form" {...props} />
                )}
              </TabPane>
              <TabPane tabId="contest_rules">
                {activeTab === "contest_rules" && (
                  <CreateTemplate type="contest_rules" {...props} />
                )}
              </TabPane>
              <TabPane tabId="winner_notification">
                {activeTab === "winner_notification" && (
                  <CreateTemplate type="winner_notification" {...props} />
                )}
              </TabPane>
              <TabPane tabId="rejection_notification">
                {activeTab === "rejection_notification" && (
                  <CreateTemplate type="rejection_notification" {...props} />
                )}
              </TabPane>
            </TabContent>

            {isArray(listTemplates) && listTemplates.length > 0 && (
              <div className="publish-bar">
                <FormattedMessage id="system templates > published" />
              </div>
            )}

            {isArray(listTemplates) &&
              listTemplates.map((template) => (
                <TemplateItem key={template.id} {...props} data={template} />
              ))}
          </>
        ) : null}
      </div>
      <BlockingComponent
        isBlocked={props.shouldBlockNavigation}
        modalProps={{
          title: intl.formatMessage({
            id: "reminder modal > wait, you have unsaved changes in this form",
          }),
          confirmTitle: intl.formatMessage({
            id: "reminder modal > button save",
          }),
          cancelTitle: intl.formatMessage({
            id: "reminder modal > button dismiss",
          }),
          isCancelConfirmedNavigation: true,
          onConfirm: () => {
            const buttonSubmit = document.getElementById(
              `btn-submit-${props.openSystemTemplate}`
            );
            // trigger click to submit the form
            if (buttonSubmit) buttonSubmit.click();
          },
        }}
      />
      <ConfirmationModal
        isOpen={props.isOpenConfirmationModal}
        title={intl.formatMessage({
          id: "reminder modal > wait, you have unsaved changes in this form",
        })}
        isCloseOutside={false}
        className={bem.e("confirmation-modal")}
        description={intl.formatMessage({
          id:
            "reminder modal > before you can perform this step, you must save or discard your changes",
        })}
        confirmTitle={intl.formatMessage({
          id: "reminder modal > button save",
        })}
        discardTitle={intl.formatMessage({
          id: "reminder modal > button discard",
        })}
        cancelTitle={intl.formatMessage({
          id: "reminder modal > button cancel",
        })}
        onConfirm={() => {
          const buttonSubmit = document.getElementById(
            `btn-submit-${props.openSystemTemplate}`
          );
          // trigger click to submit the form
          if (buttonSubmit) {
            buttonSubmit.click();
          }
        }}
        onToggle={() =>
          props.setIsOpenConfirmationModal(!props.isOpenConfirmationModal)
        }
        onDiscard={() => {
          props.setShouldBlockNavigation(false);
          props.setIsOpenConfirmationModal(false);
          props.setOpenSystemTemplate(null);
          props.getSystemTemplates({
            type: activeTab,
            station_id: get(currentStation, "key"),
          });
        }}
        onCancel={() => props.setIsOpenConfirmationModal(false)}
      />
      <Spinner isLoading={isLoading} />
    </Page>
  );
};

export default SystemTemplates;
