import React from "react";
import PrizeForm from "./view";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { get, join, pick, sumBy, isFunction } from "lodash";
import { compose, lifecycle, withHandlers, withState } from "recompose";
import history from "components/History";
import URL from "utils/urls";
import {
  getPrize,
  saveFormValues,
  updatePrizes,
  deletePrize,
} from "store/actions/prizes";
import { getLocations, updateLocation } from "store/actions/locations";
import { MdImportantDevices } from "react-icons/lib/md";
import ToastManager from "components/ToastManager";
export default injectIntl(
  compose(
    connect(
      (state) => {
        return {
          auth: state.auth,
          locations: get(state, "locations.locations"),
          dateFormatByServer: get(
            state,
            "servers.currentServer.data.date_format",
            "MM/DD/YYYY"
          )
        };
      },
      {
        saveFormValues,
        updatePrizes,
        getPrize,
        deletePrize,
        updateLocation,
        getLocations,
      }
    ),
    withState("isLoading", "setIsLoading", false),
    withState("isAllowDeleteStation", "setIsAllowDeleteStation", true),
    withState("isOpenConfirmationModal", "setIsOpenConfirmationModal", false),
    withState("shouldBlockNavigation", "setShouldBlockNavigation", false),
    withState("isOpenLocationModal", "setIsOpenLocationModal", false),
    withState("activeTab", "setActiveTab", "audience"),
    withHandlers({
      onCancel: ({ onCloseModal, isModal }) => () => {
        if (onCloseModal && isModal) {
          onCloseModal();
        } else {
          history.push(URL.PRIZES({ filter: "prizes_list" }));
        }
      },
      onDelete: ({
        prize,
        deletePrize,
        setIsOpenConfirmationModal,
        intl,
        setIsLoading,
      }) => async () => {
        setIsLoading(true);
        deletePrize(prize.id, () => {
          setIsLoading(false);
          ToastManager.show({
            title: <MdImportantDevices />,
            message: intl.formatMessage({
              id: "prizes > deleted successfully",
            }),
            level: "success",
          });
          setIsOpenConfirmationModal(false);
        });
      },
      onSubmit: ({
        saveFormValues,
        updatePrizes,
        updateLocation,
        intl,
        isModal,
        onCloseModal,
        setIsLoading,
        onSubmitted,
      }) => (values) => {
        let data = {
          ...values,
          office_days: values.office_days ? join(values.office_days, ",") : "",
        };
        // set unit value for itemize
        if (values.itemize) {
          data = {
            ...data,
            unit_value: sumBy(values.itemized_prices, (item) =>
              parseFloat(item.value)
            ),
          };
        }
        setIsLoading(true);
        if (values.id) {
          updatePrizes(values.id, data, () => {
            ToastManager.show({
              title: <MdImportantDevices />,
              message: intl.formatMessage({
                id: "prizes > updated successfully",
              }),
              level: "success",
            });
            setIsLoading(false);
            if (data.location) {
              updateLocation(
                data.location,
                pick(data, [
                  "add1",
                  "add2",
                  "city",
                  "contact",
                  "name",
                  "office_days",
                  "phone",
                  "state",
                  "time_close",
                  "time_open",
                  "zip",
                ])
              );
            }
          });
        } else {
          saveFormValues(data, (response) => {
            if (isFunction(onSubmitted)) {
              onSubmitted(response);
            } else {
              ToastManager.show({
                title: <MdImportantDevices />,
                message: intl.formatMessage({
                  id: "prizes > created successfully",
                }),
                level: "success",
              });
            }
            setIsLoading(false);
            if (data.location) {
              updateLocation(
                data.location,
                pick(data, [
                  "add1",
                  "add2",
                  "city",
                  "contact",
                  "name",
                  "office_days",
                  "phone",
                  "state",
                  "time_close",
                  "time_open",
                  "zip",
                ])
              );
            }
            if (isModal) {
              onCloseModal();
            } else {
              history.push(URL.PRIZES({ filter: "prizes_list" }));
            }
          });
        }
      },
    }),
    lifecycle({
      componentDidMount() {
        this.props.getLocations();
      },
      componentWillReceiveProps(nextProps) {
        if (get(nextProps, "locations.is_dirty")) {
          this.props.getLocations();
        }
      },
    })
  )(PrizeForm)
);
