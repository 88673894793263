import React, { useEffect, useMemo } from "react";
import Page from "components/Page";
import { FormattedMessage } from "react-intl";
import bn from "utils/bemnames";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import URL from "utils/urls";
import { getRoleIdByName } from "utils/helpers";
import { get } from "lodash";
import BlockingComponent from "components/BlockingComponent";
import Spinner from "components/Spinner";
import HubManagerStations from "components/HubManagerComponents/HubManagerStations";
import HubManagerMembers from "components/HubManagerComponents/HubManagerMembers";
import classnames from "classnames";
import Dropdown from "components/Dropdown";
import history from "components/History";
const bem = bn.create("hub-manager-page");

const HubManagerPage = (props) => {
  const {
    intl,
    auth,
    isLoading,
    activeTab,
    setActiveTab,
    assignedHubs,
    currentHub,
    setCurrentHub,
  } = props;
  const { production_multimarket } = get(auth, "info", {});
  const authIsGodAdmin = get(props, "auth.user.is_god_admin", false);
  const authIsSupportAdmin = get(props, "auth.user.is_support_admin", false);
  const isShowHubManager =
    (getRoleIdByName("Hub Manager", auth.info.enterprise_roles) ||
      authIsGodAdmin ||
      authIsSupportAdmin) &&
    production_multimarket;
  useEffect(() => {
    if (!isShowHubManager) history.push("/");
  }, []);
  const renderBreadcrumbs = () => {
    let breadcrumbs = [
      {
        name: intl.formatMessage({ id: "breadcrumbs > home" }),
        to: URL.HOME(),
        active: false,
      },
      {
        name: intl.formatMessage({ id: "breadcrumbs > configure hub" }),
        active: true,
      },
    ];

    return breadcrumbs;
  };
  const hubOptions = useMemo(() => {
    if (assignedHubs.length === 0) {
      setCurrentHub(null);
      return [];
    }
    const options = assignedHubs.map((item) => ({
      label: item.name,
      value: item.id,
      data: item,
    }));
    setCurrentHub(options[0]);
    return options;
  }, [assignedHubs]);

  return (
    <Page
      applyPadding={false}
      breadcrumbs={renderBreadcrumbs()}
      className={bem.b()}
    >
      <div className={"page-container"}>
        <div className={bem.e("dropdown")}>
          <Dropdown
            value={currentHub || ""}
            placeholder={intl.formatMessage({
              id: "hub manager > select",
            })}
            onChange={(selectedOption) => {
              setCurrentHub(selectedOption);
              props.setShouldBlockNavigation(true);
            }}
            options={hubOptions}
          />
        </div>
        <Nav tabs className={bem.e("nav-tabs")}>
          <NavItem>
            <NavLink
              className={classnames(
                {
                  active: activeTab === "stations",
                },
                bem.e("nav-link")
              )}
              onClick={() => {
                setActiveTab("stations");
              }}
            >
              <FormattedMessage id="hub manager > hub stations" />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames(
                {
                  active: activeTab === "members",
                },
                bem.e("nav-link")
              )}
              onClick={() => {
                setActiveTab("members");
              }}
            >
              <FormattedMessage id="hub manager > hub members" />
            </NavLink>
          </NavItem>
        </Nav>
        {currentHub ? (
          <TabContent activeTab={activeTab} className={bem.e("tab-content")}>
            <TabPane tabId="stations">
              {activeTab === "stations" && (
                <HubManagerStations
                  isAllowEditStations={true}
                  selectedHub={currentHub.data}
                />
              )}
            </TabPane>
            <TabPane tabId="members">
              {activeTab === "members" && (
                <HubManagerMembers selectedHub={currentHub.data} />
              )}
            </TabPane>
          </TabContent>
        ) : null}
      </div>
      <BlockingComponent
        isBlocked={props.shouldBlockNavigation}
        modalProps={{
          title: intl.formatMessage({
            id: "reminder modal > wait, you have unsaved changes in this form",
          }),
          confirmTitle: intl.formatMessage({
            id: "reminder modal > button save",
          }),
          cancelTitle: intl.formatMessage({
            id: "reminder modal > button dismiss",
          }),
          isCancelConfirmedNavigation: true,
          onConfirm: () => {
            const buttonSubmit = document.getElementById(`btn-submit-`);
            // trigger click to submit the form
            if (buttonSubmit) buttonSubmit.click();
          },
        }}
      />
      <Spinner isLoading={isLoading} />
    </Page>
  );
};

export default HubManagerPage;
