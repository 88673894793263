import React, { useEffect } from "react";
import bn from "utils/bemnames";
import { CopyIcon, DownIcon, UpIcon, MenuIcon } from "components/CustomIcons";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { Formik } from "formik";
import * as Yup from "yup";
import Checkbox from "components/Checkbox";
import ToastManager from "components/ToastManager";
import ToggleSwitch from "components/ToggleSwitch";
import ListUsers from "../ListUsers";
import { Form, Button, Col, Row, FormGroup } from "reactstrap";
import TextInput from "components/TextInput";
import { Asterisk } from "components/Elements";
import {
  get,
  filter,
  omit,
  map,
  lowerCase,
  forEach,
  includes,
  xor,
} from "lodash";
import { arrayContainsArray } from "utils/helpers";
import { privileges_meta as privilegesMetaConfig } from "utils/privilegesMeta";
import { labelToNameField } from "utils/helpers";

const bem = bn.create("roles-manager");
const RoleItem = (props) => {
  const {
    currentOpenForm,
    role,
    setCurrentOpenForm,
    onCloneRole,
    isOpenPrivileges,
    setIsOpenPrivileges,
    intl,
    privileges,
    isLoading,
    setShouldBlockNavigation,
    setIsOpenConfirmationUnsavedModal,
    shouldBlockNavigation,
    setIsOpenConfirmationModal,
    onToggleActivate,
    setSidebarActive,
    sidebarActive,
  } = props;
  const [privilegesMeta, setPrivilegesMeta] = React.useState(
    privilegesMetaConfig
  );
  useEffect(() => {
    const {
      enable_production,
      enable_promotion,
      enable_digital,
      production_multimarket,
    } = props;
    let privilegesMeta = Object.assign({}, privilegesMetaConfig);

    if (!enable_production) {
      privilegesMeta = {
        ...privilegesMeta,
        privileges_groups: {
          ...omit(privilegesMeta.privileges_groups, ["Production"]),
        },
      };
    }

    if (!enable_digital) {
      privilegesMeta = {
        ...privilegesMeta,
        privileges_groups: {
          ...omit(privilegesMeta.privileges_groups, ["Digital"]),
        },
      };
    }

    if (!enable_promotion) {
      privilegesMeta = {
        ...privilegesMeta,
        privileges_groups: {
          ...omit(privilegesMeta.privileges_groups, [
            "Promotions",
            "Jock Console",
          ]),
        },
      };
    }
    if (enable_production && !enable_promotion && !enable_digital) {
      privilegesMeta = {
        ...privilegesMeta,
        privileges_groups: {
          ...privilegesMeta.privileges_groups,
          Admin: {
            ...privilegesMeta.privileges_groups.Admin,
            sub_groups: filter(
              privilegesMeta.privileges_groups.Admin.sub_groups,
              (subGroup) => subGroup.name !== "Role Manager"
            ),
          },
        },
      };
    }

    if (!enable_digital) {
      privilegesMeta = {
        ...privilegesMeta,
        privileges_groups: {
          ...privilegesMeta.privileges_groups,
          Admin: {
            ...privilegesMeta.privileges_groups.Admin,
            sub_groups: filter(
              privilegesMeta.privileges_groups.Admin.sub_groups,
              (subGroup) => subGroup.name !== "Workflows"
            ),
          },
        },
      };
    }

    if (!production_multimarket) {
      privilegesMeta = {
        ...privilegesMeta,
        privileges_groups: {
          ...privilegesMeta.privileges_groups,
          Admin: {
            ...privilegesMeta.privileges_groups.Admin,
            sub_groups: filter(
              privilegesMeta.privileges_groups.Admin.sub_groups,
              (subGroup) => subGroup.name !== "Teams"
            ),
          },
        },
      };
    }
    setPrivilegesMeta(privilegesMeta);
  }, [
    props.enable_production,
    props.enable_digital,
    props.enable_promotion,
    props.production_multimarket,
  ]);
  const isOpen = currentOpenForm === role.id;

  const initialValues = {
    id: get(role, "id", ""),
    name: get(role, "name", ""),
    privileges: get(role, "privileges", []).map((i) => Number(i.id)),
  };
  return (
    <div
      className={classnames(bem.e("role-item-wrapper"), {
        [bem.e("role-open-form")]: isOpen,
        [bem.e("role-item-inactive")]: !get(role, "active", false),
      })}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          name: Yup.string().required(
            intl.formatMessage({
              id: "validate > name is required",
            })
          ),
          privileges: Yup.array()
            .min(
              1,
              intl.formatMessage({
                id: "validate > privileges is required",
              })
            )
            .required(
              intl.formatMessage({
                id: "validate > privileges is required",
              })
            ),
        })}
        onSubmit={props.onFormSubmit}
      >
        {(formProps) => {
          const { values, touched, errors, setFieldValue } = formProps;
          // get privileges disabled by role
          const privilegesByRole = get(
            privilegesMeta,
            `privileges_by_role.${values.name}`,
            {}
          );
          const groups = [role.group];
          return (
            <Form
              onSubmit={formProps.handleSubmit}
              onChange={() => setShouldBlockNavigation(true)}
            >
              <div className={bem.e("content-wrapper")}>
                <div className={bem.e("role-head")}>
                  <div className={bem.e("section-main-info")}>
                    <span className={"menu-selection-icon"}>
                      <MenuIcon />
                    </span>
                    <h3 className={classnames(bem.e("role-title"))}>
                      {values.name}
                      <ListUsers role={role} />
                      {groups.length > 0 ? (
                        <span className={bem.e("product-list")}>
                          {map(groups, (group) =>
                            intl.formatMessage({ id: `role > group ${group}` })
                          ).join(", ")}
                        </span>
                      ) : null}
                    </h3>
                  </div>
                  <div className={bem.e("section-main-action")}>
                    {role.is_default ? (
                      <span className={bem.e("default-text")}>
                        <FormattedMessage id="role > default" />
                      </span>
                    ) : null}
                    <div
                      className={bem.e("btn-clone")}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (shouldBlockNavigation) {
                          setIsOpenConfirmationUnsavedModal(true);
                        } else {
                          onCloneRole(role);
                        }
                      }}
                    >
                      <CopyIcon color={"#795AFA"} width={20.55} height={20} />
                      <FormattedMessage id="role > clone" />
                    </div>
                    <button
                      className={bem.e("button-down")}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (shouldBlockNavigation) {
                          setIsOpenConfirmationUnsavedModal(true);
                        } else {
                          setCurrentOpenForm(isOpen ? null : role.id);
                        }
                      }}
                    >
                      {isOpen ? (
                        <UpIcon
                          className={bem.e("button-up-icon")}
                          color="#795AFA"
                          width={24}
                          height={15.46}
                        />
                      ) : (
                        <DownIcon
                          className={bem.e("button-down-icon")}
                          color="#795AFA"
                          width={24}
                          height={15.46}
                        />
                      )}
                    </button>
                  </div>
                </div>
                {isOpen && (
                  <div className={bem.e("role-content")}>
                    {/* role name */}
                    <Row>
                      <Col xs={6}>
                        {!role.is_default && (
                          <FormGroup className={bem.e("role-name")}>
                            <TextInput
                              label={
                                <span>
                                  <FormattedMessage id="role > name" />
                                  <Asterisk>*</Asterisk>
                                </span>
                              }
                              type="text"
                              name="name"
                              placeholder={intl.formatMessage({
                                id: "role > enter name",
                              })}
                              value={values.name}
                              error={touched.name && errors.name}
                              onChange={formProps.handleChange}
                              onBlur={formProps.handleBlur}
                            />
                          </FormGroup>
                        )}
                      </Col>
                      {/* <Col xs={6}>
                        <div className={bem.e("clone-text")}>
                          Producer (clone)
                        </div>
                      </Col> */}
                    </Row>
                    {/* privileges */}
                    {map(
                      Object.keys(privilegesMeta.privileges_groups),
                      (privileges_group, indexGroup) => {
                        let totalSelectedPrivileges = 0;
                        forEach(
                          privilegesMeta.privileges_groups[privileges_group]
                            .sub_groups,
                          (sub_group) => {
                            const currentTotal = Object.keys(
                              privilegesMeta.privileges
                            ).reduce((total, privilege_key) => {
                              let privilege =
                                privilegesMeta.privileges[privilege_key];
                              privilege = privilege.groups.find(
                                (group) =>
                                  group.group === privileges_group &&
                                  sub_group.name === group.sub_group
                              );
                              if (
                                privilege &&
                                privileges &&
                                privileges.find((p) => p.name === privilege_key)
                              ) {
                                let privilege_id = privileges.find(
                                  (p) => p.name === privilege_key
                                ).id;
                                if (
                                  includes(
                                    values.privileges,
                                    Number(privilege_id)
                                  )
                                ) {
                                  total += 1;
                                }
                              }
                              return total;
                            }, 0);
                            totalSelectedPrivileges += currentTotal;
                          }
                        );
                        return (
                          <div
                            className={classnames(bem.e("section-container"))}
                            key={indexGroup}
                          >
                            <div className={bem.e("section-title")}>
                              <span
                                className="cursor"
                                onClick={() => {
                                  setIsOpenPrivileges({
                                    ...isOpenPrivileges,
                                    [indexGroup]: !isOpenPrivileges[indexGroup],
                                  });
                                }}
                              >
                                {intl.formatMessage({
                                  id: `role > title ${lowerCase(
                                    privileges_group
                                  )}`,
                                })}{" "}
                                {intl.formatMessage({
                                  id: "role > privileges",
                                })}
                                {totalSelectedPrivileges > 0 ? (
                                  <span className={bem.e("total")}>
                                    ({totalSelectedPrivileges})
                                  </span>
                                ) : null}
                              </span>
                              <span
                                className={bem.e("section-icon")}
                                onClick={() => {
                                  setIsOpenPrivileges({
                                    ...isOpenPrivileges,
                                    [indexGroup]: !isOpenPrivileges[indexGroup],
                                  });
                                }}
                              >
                                {isOpenPrivileges[indexGroup] ? (
                                  <UpIcon />
                                ) : (
                                  <DownIcon />
                                )}
                              </span>
                            </div>
                            <div
                              className={classnames(bem.e("content-view"), {
                                [bem.e("show")]: isOpenPrivileges[indexGroup],
                                [bem.e("hide")]: !isOpenPrivileges[indexGroup],
                              })}
                            >
                              <Row>
                                {map(
                                  privilegesMeta.privileges_groups[
                                    privileges_group
                                  ].sub_groups,
                                  (sub_group, index) => {
                                    const privilegesConfigByRoleSubGroup = get(
                                      privilegesByRole,
                                      `${sub_group.name}`
                                    );
                                    const privilegeDisabled = get(
                                      privilegesConfigByRoleSubGroup,
                                      "disabled",
                                      []
                                    );
                                    let sub_group_privileges = Object.keys(
                                      privilegesMeta.privileges
                                    ).filter((privilege_key) => {
                                      let privilege =
                                        privilegesMeta.privileges[
                                          privilege_key
                                        ];
                                      return privilege.groups.find(
                                        (group) =>
                                          labelToNameField(group.group) ===
                                            labelToNameField(
                                              privileges_group
                                            ) &&
                                          labelToNameField(sub_group.name) ===
                                            labelToNameField(group.sub_group)
                                      );
                                    });
                                    let sub_group_privilege_ids = [];
                                    if (sub_group.select_all) {
                                      sub_group_privilege_ids = sub_group_privileges
                                        .filter(
                                          (privilege_key) =>
                                            !includes(
                                              privilegeDisabled,
                                              privilege_key
                                            )
                                        )
                                        .map((privilege_key) => {
                                          if (
                                            privileges &&
                                            privileges.find(
                                              (p) => p.name === privilege_key
                                            )
                                          ) {
                                            return privileges.find(
                                              (p) => p.name === privilege_key
                                            ).id;
                                          }
                                        })
                                        .map(Number);
                                    }
                                    const isDisabledSelectedAll =
                                      privilegesConfigByRoleSubGroup &&
                                      !privilegesConfigByRoleSubGroup.is_can_select_all;
                                    return (
                                      <Col xs="4" key={index}>
                                        <div
                                          className={bem.e("sub-group-title")}
                                        >
                                          {intl.formatMessage({
                                            id: `role > title ${lowerCase(
                                              sub_group.name
                                            )}`,
                                          })}
                                        </div>
                                        {sub_group.select_all && (
                                          <Col
                                            key={index}
                                            className={bem.e("p-left-0")}
                                          >
                                            <Checkbox
                                              text={"Select All"}
                                              checked={
                                                arrayContainsArray(
                                                  values.privileges,
                                                  sub_group_privilege_ids
                                                ) && !isDisabledSelectedAll
                                              }
                                              disabled={
                                                isDisabledSelectedAll ||
                                                get(role, "is_default", "")
                                              }
                                              onChange={() => {
                                                let selectedPrivileges = [];
                                                if (
                                                  arrayContainsArray(
                                                    values.privileges,
                                                    sub_group_privilege_ids
                                                  )
                                                ) {
                                                  selectedPrivileges = xor(
                                                    values.privileges,
                                                    sub_group_privilege_ids
                                                  );
                                                } else {
                                                  selectedPrivileges = [
                                                    ...values.privileges,
                                                    ...sub_group_privilege_ids.filter(
                                                      (item) =>
                                                        !includes(
                                                          values.privileges,
                                                          item
                                                        )
                                                    ),
                                                  ];
                                                }
                                                setFieldValue(
                                                  "privileges",
                                                  selectedPrivileges
                                                );
                                                setShouldBlockNavigation(true);
                                              }}
                                            />
                                          </Col>
                                        )}
                                        {map(
                                          sub_group_privileges,
                                          (privilege_key, index) => {
                                            if (
                                              !privileges.find(
                                                (p) => p.name === privilege_key
                                              )
                                            )
                                              return null;

                                            let privilege =
                                              privilegesMeta.privileges[
                                                privilege_key
                                              ];
                                            let privilege_id = privileges.find(
                                              (p) => p.name === privilege_key
                                            ).id;
                                            const isDisabledSelectedPrivilege =
                                              get(
                                                privilegesConfigByRoleSubGroup,
                                                "is_disabled_all",
                                                false
                                              ) ||
                                              includes(
                                                get(
                                                  privilegesConfigByRoleSubGroup,
                                                  "disabled",
                                                  []
                                                ),
                                                privilege_key
                                              );
                                            return (
                                              <Col
                                                key={index}
                                                style={{
                                                  paddingLeft: 0,
                                                }}
                                              >
                                                <Checkbox
                                                  text={privilege.display_name}
                                                  checked={includes(
                                                    values.privileges,
                                                    Number(privilege_id)
                                                  )}
                                                  disabled={
                                                    isDisabledSelectedPrivilege ||
                                                    get(role, "is_default", "")
                                                  }
                                                  onChange={(checked) => {
                                                    const selectedPrivileges = xor(
                                                      values.privileges,
                                                      [Number(privilege_id)]
                                                    );
                                                    setFieldValue(
                                                      "privileges",
                                                      selectedPrivileges
                                                    );
                                                    setShouldBlockNavigation(
                                                      true
                                                    );
                                                  }}
                                                />
                                              </Col>
                                            );
                                          }
                                        )}
                                      </Col>
                                    );
                                  }
                                )}
                              </Row>
                            </div>
                          </div>
                        );
                      }
                    )}
                    {/* error */}
                    {touched.privileges && errors.privileges ? (
                      <div className="text-danger">{errors.privileges}</div>
                    ) : null}
                    {!role.is_default && (
                      <Row className={bem.e("form-buttons")}>
                        <Col>
                          <Button
                            color="blue"
                            type="submit"
                            className="btn btn-radius"
                            id={`btn-submit-${values.id}`}
                            data-error={values.privileges.length === 0}
                            disabled={isLoading}
                            onClick={() => {
                              formProps
                                .validateForm(values)
                                .then((onfulfilled) => {
                                  if (
                                    onfulfilled.isCanceled &&
                                    values.privileges.length > 0
                                  )
                                    return;
                                  if (Object.keys(onfulfilled).length > 0) {
                                    ToastManager.show({
                                      title: intl.formatMessage({
                                        id: "toast > title not saved",
                                      }),
                                      message: intl.formatMessage({
                                        id:
                                          "toast > message error please correct the hilighted fields",
                                      }),
                                      level: "error",
                                    });
                                    if (sidebarActive !== "/admins/unknown") {
                                      setSidebarActive("/admins/unknown");
                                    }
                                  }
                                });
                            }}
                          >
                            <FormattedMessage
                              id={`role > button ${
                                !isLoading ? "submit" : "submitting"
                              }`}
                            />
                          </Button>
                          <Button
                            type="button"
                            outline
                            color="blue"
                            className={classnames(
                              bem.e("button-cancel"),
                              "btn-radius"
                            )}
                            onClick={() => setCurrentOpenForm(null)}
                          >
                            <FormattedMessage id="role > button cancel" />
                          </Button>
                        </Col>
                        {role.users.length === 0 && (
                          <Col>
                            <div className={bem.e("button-right")}>
                              <Button
                                type="button"
                                outline
                                color="blue"
                                className={classnames(
                                  bem.e("button-delete"),
                                  "btn-radius"
                                )}
                                onClick={() => {
                                  setIsOpenConfirmationModal(true);
                                }}
                              >
                                <FormattedMessage id="role > button delete" />
                              </Button>
                            </div>
                          </Col>
                        )}
                      </Row>
                    )}
                  </div>
                )}
              </div>
              {!get(role, "is_default", false) ? (
                <ToggleSwitch
                  leftComponent={null}
                  rightComponent={null}
                  name="active"
                  switchProps={{
                    checked: get(role, "active", false),
                    onChange: (checked) => {
                      onToggleActivate(role, checked);
                    },
                    disabled: false,
                    offColor: "#C2D4E0",
                    onColor: "#795AFA",
                    uncheckedIcon: false,
                    checkedIcon: false,
                    boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.282)",
                    height: 26.44,
                    width: 48.48,
                    handleDiameter: 22.92,
                  }}
                />
              ) : (
                <Col xs={1} style={{ flex: "0 0 68px" }}></Col>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
export default RoleItem;
