import * as React from "react";
import { Asterisk } from "components/Elements";
import * as Yup from "yup";
import { Formik } from "formik";
import TextInput from "components/TextInput";
import { Form, Button, Col, Row, FormGroup, Label } from "reactstrap";
import TextareaEvaluator from "components/TextareaEvaluator";
import { setScriptField } from "utils/helpers";
import { get, find, first, map } from "lodash";
import { EyeIcon } from "components/CustomIcons";
import { templateTags } from "utils/config";
import ConfirmationModal from "components/ConfirmationModal";
import { FormattedMessage } from "react-intl";
import MergeTags from "components/SystemTemplates/MergeTags";
import bn from "utils/bemnames";
import Dropdown from "components/Dropdown";
import ToastManager from "components/ToastManager";
import UploadFile from "components/UploadFile";
import FileView from "components/FileView";
import { Modal, ModalBody } from "reactstrap";
import InputTime from "components/InputTime";
import moment from "moment";
export const dateTimeFormat = "YYYY-MM-DD HH:mm:ss";
const bem = bn.create("email-template-modal");

export const getCategories = ({ intl }) => {
  return [
    {
      value: "winner_announcement",
      label: intl.formatMessage({
        id: "system templates > winner announcement",
      }),
    },
    {
      value: "received_form_confirmation",
      label: intl.formatMessage({
        id: "system templates > received form confirmation",
      }),
    },
    {
      value: "prize_release",
      label: intl.formatMessage({ id: "system templates > prize release" }),
    },
    {
      value: "prize_fulfillment",
      label: intl.formatMessage({ id: "system templates > prize fulfillment" }),
    },
    {
      value: "other",
      label: intl.formatMessage({ id: "system templates > other" }),
      data: {
        className: "option-other",
      },
    },
  ];
};
const getRecipients = ({ intl }) => {
  return [
    {
      value: "all_winners",
      label: intl.formatMessage({ id: "system templates > all winners" }),
    },
    {
      value: "winners_without_completed_fulfillment_form",
      label: intl.formatMessage({
        id: "system templates > winners without completed fulfillment form",
      }),
    },
    {
      value: "winner_without_signed_prize_release",
      label: intl.formatMessage({
        id: "system templates > winners without signed prize release",
      }),
    },
    {
      value: "winners_without_signed_w9s",
      label: intl.formatMessage({
        id: "system templates > winners without signed W-9s",
      }),
    },
    {
      value: "winners_with_unfulfilled_prizes",
      label: intl.formatMessage({
        id: "system templates > winners with unfulfilled prizes",
      }),
    },
  ];
};
function getInitialValues(props) {
  const { emailTemplate, process } = props;
  const contestImage = get(process, "data.fields.contest_image.value", "");
  let values = {
    template_id: get(emailTemplate, "template_id", ""),
    contest_id:
      get(emailTemplate, "contest_id") || get(process, "data.fields.key.value"),
    recipients: get(emailTemplate, "recipients", ""),
    subject: get(emailTemplate, "subject", ""),
    content: get(emailTemplate, "content", ""),
    contest_image: contestImage,
    schedule: get(emailTemplate, "schedule", "") ? emailTemplate.schedule : "",
    type: get(emailTemplate, "type", "scheduled"),
  };
  if (emailTemplate) {
    values = {
      ...values,
      id: emailTemplate.id,
    };
  }
  return values;
}
const EmailTemplateModal = (props) => {
  const {
    intl,
    action,
    isOpenConfirmationModal,
    setIsOpenConfirmationModal,
    confirmationType,
    setConfirmationType,
    isOpenSuccessModal,
    setIsOpenSuccessModal,
  } = props;
  const emailTemplatesOptions = map(props.emailTemplates, (item) => ({
    value: item.id,
    label: item.name,
    data: item,
  }));

  const formikRef = React.useRef(null);

  const formSchema = Yup.object().shape({
    type: Yup.string().nullable(),
    contest_id: Yup.string().nullable(),
    template_id: Yup.string().nullable(),
    recipients: Yup.string().required(
      intl.formatMessage({ id: "validate > recipients is required" })
    ),
    subject: Yup.string().required(
      intl.formatMessage({ id: "validate > subject is required" })
    ),
    content: Yup.string().nullable(),
    contest_image: Yup.string().nullable(),
    schedule: Yup.string().when("type", {
      is: (value) => value === "scheduled",
      then: Yup.string().required(
        intl.formatMessage({ id: "validate > date is required" })
      ),
      otherwise: Yup.string().nullable(),
    }),
  });

  const initialValues = getInitialValues(props);
  const onSubmit = async (data) => {
    await props.onSubmit(data);
  };
  const recipientOptions = getRecipients({ intl });
  return (
    <Modal isOpen={props.isOpen} className={bem.b()}>
      <ModalBody>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={formSchema}
          ref={formikRef}
          onSubmit={async (values, { resetForm }) => {
            await onSubmit(values);
            resetForm();
            if (action === "create") {
              props.onClose();
            }
          }}
        >
          {(formProps) => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              setFieldValue,
            } = formProps;
            const selectedEmailTemplate = find(
              emailTemplatesOptions,
              (i) => i.value === values.template_id
            );
            return (
              <Form
                onSubmit={formProps.handleSubmit}
                noValidate
                onChange={() => {
                  props.setShouldBlockNavigation(true);
                }}
              >
                <div className={bem.e("head")}>
                  <h2>
                    {values.name || (
                      <FormattedMessage id="process > promotion > contest > enter title" />
                    )}
                  </h2>
                  <div
                    className={bem.e("preview")}
                    onClick={() => {
                      props.onPreview(values);
                    }}
                  >
                    <EyeIcon color="#795AFA" width={24} height={16} />
                    <FormattedMessage id="process > promotion > contest > preview" />
                  </div>
                </div>
                <div className={bem.e("template-box")}>
                  <Row>
                    <Col xs={6}>
                      <FormGroup>
                        <Dropdown
                          label={
                            <span>
                              <FormattedMessage id="process > promotion > contest > email template" />
                            </span>
                          }
                          placeholder={intl.formatMessage({
                            id: "process > promotion > contest > none",
                          })}
                          name="template_id"
                          isClearable
                          onChange={(option) => {
                            formProps.setFieldValue(
                              "template_id",
                              option ? option.value : ""
                            );
                            if (option) {
                              formProps.setFieldValue(
                                "content",
                                get(option, "data.content", "")
                              );
                              props.setCurrentKey(Date.now());
                            }
                            props.setShouldBlockNavigation(true);
                          }}
                          // defaultMenuIsOpen
                          value={selectedEmailTemplate || ""}
                          options={emailTemplatesOptions}
                          closeMenuOnSelect={true}
                          error={touched.template_id && errors.template_id}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <FormGroup>
                        <Dropdown
                          label={
                            <span>
                              <FormattedMessage id="process > promotion > contest > recipients" />
                              <Asterisk> *</Asterisk>
                            </span>
                          }
                          placeholder={intl.formatMessage({
                            id:
                              "process > promotion > contest > placeholder recipients",
                          })}
                          name="recipients"
                          onChange={(option) => {
                            formProps.setFieldValue(
                              "recipients",
                              option ? option.value : ""
                            );
                            props.setShouldBlockNavigation(true);
                          }}
                          isClearable
                          value={
                            find(
                              recipientOptions,
                              (item) => item.value === values.recipients
                            ) || ""
                          }
                          options={recipientOptions}
                          closeMenuOnSelect={true}
                          error={touched.recipients && errors.recipients}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <TextInput
                          label={
                            <span>
                              <FormattedMessage id="process > promotion > contest > subject line" />
                              <Asterisk> *</Asterisk>
                              <MergeTags
                                onChange={(tag) => {
                                  const { subject } = values;
                                  formProps.setFieldValue(
                                    "subject",
                                    `${subject} {${tag}}`
                                  );
                                  props.setShouldBlockNavigation(true);
                                }}
                                bem={bem}
                              />
                            </span>
                          }
                          type="text"
                          name="subject"
                          placeholder={intl.formatMessage({
                            id:
                              "process > promotion > contest > placeholder subject line",
                          })}
                          value={values.subject}
                          error={touched.subject && errors.subject}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>
                          <FormattedMessage id="process > promotion > contest > notification text" />
                        </Label>
                        <TextareaEvaluator
                          key={props.currentKey}
                          text={setScriptField(get(values, "content", ""))}
                          placeholder={
                            <FormattedMessage id="process > promotion > contest > placeholder content" />
                          }
                          onEvaluatorCallback={(value) => {
                            formProps.setFieldValue("content", value);
                          }}
                          showScriptLength={false}
                          hideHelperText={true}
                          showInnerTitle={false}
                          isShowSlider={false}
                          hideOrderLength={true}
                          tagsInModal={templateTags}
                          tagsIsModal={true}
                          isShowUpload={true}
                          isShowHyperlink={true}
                          isShowInsertButton={true}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} className={bem.e("reset-col")}>
                      <FormGroup className={bem.e("contest-image")}>
                        <Label>
                          <FormattedMessage id="process > promotion > contest > contest image" />
                        </Label>
                        {values.contest_image && (
                          <FileView
                            background
                            file={values.contest_image}
                            onDeleted={() => {
                              setFieldValue("contest_image", "");
                              props.setShouldBlockNavigation(true);
                            }}
                            onUploaded={(value) => {
                              setFieldValue(
                                "contest_image",
                                value ? first(value) : ""
                              );
                              props.setShouldBlockNavigation(true);
                            }}
                            onRenameFile={(value) => {
                              setFieldValue("contest_image", value);
                              props.setShouldBlockNavigation(true);
                            }}
                            actions={["trash", "view", "download", "rename"]}
                          />
                        )}
                        {!values.contest_image && (
                          <React.Fragment>
                            <UploadFile
                              url={""}
                              attachText={props.intl.formatMessage({
                                id: "process > attach file",
                              })}
                              mimes={["image/jpeg", "image/png"]}
                              fieldName={"contest_image"}
                              actions={[]}
                              onDeleted={() => {
                                setFieldValue("contest_image", "");
                                props.setShouldBlockNavigation(true);
                              }}
                              onUploaded={(value) => {
                                setFieldValue("contest_image", value);
                                props.setShouldBlockNavigation(true);
                              }}
                              isHideFilesDragArea={true}
                              showRemoveIcon={false}
                              maxSize={300000} //300kb
                            />
                          </React.Fragment>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xs={6} className={bem.e("reset-col")}>
                      <div className={bem.e("contest-image-help-text")}>
                        <FormattedMessage id="process > promotion > contest > contest image max size" />
                        <FormattedMessage id="process > promotion > contest > contest image suggest" />
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className={bem.e("schedule")}>
                  <h2>
                    <FormattedMessage id="process > promotion > contest > schedule" />
                  </h2>
                  <span className={bem.e("schedule-text")}>
                    {values.type === "scheduled" ? (
                      <FormattedMessage id="process > promotion > contest > send your email now or schedule it to send later" />
                    ) : null}
                    {values.type === "automatic" ? (
                      <FormattedMessage id="process > promotion > contest > this email will be sent automatically" />
                    ) : null}
                  </span>
                  {values.type === "automatic" ? (
                    <span className={bem.e("schedule-status")}>
                      <FormattedMessage id="process > promotion > contest > automatic" />
                    </span>
                  ) : null}
                  {values.type !== "automatic" ? (
                    <Row>
                      <Col xs={4}>
                        <FormGroup>
                          <TextInput
                            label={
                              <span>
                                <FormattedMessage id="process > promotion > contest > date" />
                                <Asterisk>*</Asterisk>
                              </span>
                            }
                            min={moment().format("YYYY-MM-DD")}
                            type="date"
                            name="schedule"
                            placeholder={intl.formatMessage({
                              id: "process > promotion > contest > enter date",
                            })}
                            value={values.schedule}
                            onChange={(value) => {
                              setFieldValue("schedule", value);
                              props.setShouldBlockNavigation(true);
                            }}
                            error={touched.schedule && errors.schedule}
                            allowShowIcon
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={4}>
                        <FormGroup>
                          <Label>
                            <FormattedMessage id="process > promotion > contest > time" />
                            <Asterisk>*</Asterisk>
                          </Label>
                          <InputTime
                            time={values.schedule || ""}
                            onChange={(startDateTime) => {
                              props.setShouldBlockNavigation(true);
                              setFieldValue(
                                "schedule",
                                startDateTime.format(dateTimeFormat)
                              );
                            }}
                            error={touched.time && errors.time}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  ) : null}
                </div>
                <div className={bem.e("buttons")}>
                  <div className={bem.e("left-buttons")}>
                    <Button
                      color="blue"
                      type="submit"
                      className="btn btn-radius btn-submit"
                      id={`btn-submit-${
                        action === "create" ? "new" : props.emailTemplate.id
                      }`}
                      onClick={() => {
                        if (values.type === "draft") {
                          formProps.setFieldValue("type", "scheduled");
                        }
                        formProps.validateForm(values).then((onfulfilled) => {
                          if (Object.keys(onfulfilled).length > 0) {
                            ToastManager.show({
                              title: intl.formatMessage({
                                id: "toast > title not saved",
                              }),
                              message: intl.formatMessage({
                                id:
                                  "toast > message error please correct the hilighted fields",
                              }),
                              level: "error",
                            });
                          }
                        });
                      }}
                    >
                      <FormattedMessage id="process > promotion > contest > save" />
                    </Button>
                    {action === "create" && (
                      <Button
                        color="blue"
                        outline
                        className="btn btn-radius btn-draft"
                        type="submit"
                        onClick={() => {
                          formProps.setFieldValue("type", "draft");
                        }}
                      >
                        <FormattedMessage id="process > promotion > contest > save draft" />
                      </Button>
                    )}
                    <Button
                      color="blue"
                      outline
                      className="btn btn-radius btn-cancel"
                      onClick={() => {
                        if (props.shouldBlockNavigation) {
                          props.setIsOpenUnsavedConfirmationModal(true);
                        } else {
                          props.onClose();
                        }
                      }}
                    >
                      {action === "create" ? (
                        <FormattedMessage id="process > promotion > contest > button delete" />
                      ) : (
                        <FormattedMessage id="process > promotion > contest > button cancel" />
                      )}
                    </Button>
                    {action === "update" && (
                      <Button
                        color="blue"
                        outline
                        className="btn btn-radius"
                        onClick={() => {
                          setIsOpenConfirmationModal(true);
                          setConfirmationType("delete");
                        }}
                      >
                        <FormattedMessage id="process > promotion > contest > button delete" />
                      </Button>
                    )}
                  </div>
                  {values.type !== "automatic" ? (
                    <Button
                      color="blue"
                      outline
                      className="btn btn-radius "
                      onClick={() => {
                        setIsOpenConfirmationModal(true);
                        setConfirmationType("send");
                      }}
                    >
                      <FormattedMessage id="process > promotion > contest > send now" />
                    </Button>
                  ) : null}
                </div>
                <ConfirmationModal
                  isOpen={isOpenConfirmationModal}
                  className={bem.e("confirmation-modal")}
                  title={
                    confirmationType === "delete"
                      ? intl.formatMessage({
                          id:
                            "process > promotion > contest > are you sure you want to delete",
                        })
                      : intl.formatMessage({
                          id:
                            "process > promotion > contest > are you sure you're ready to send this notification",
                        })
                  }
                  confirmTitle={
                    confirmationType === "delete"
                      ? intl.formatMessage({
                          id: "process > promotion > contest > yes delete",
                        })
                      : intl.formatMessage({
                          id: "process > promotion > contest > yes",
                        })
                  }
                  onCancel={() => {
                    setIsOpenConfirmationModal(false);
                  }}
                  onConfirm={async () => {
                    if (confirmationType === "delete") {
                      await props.onDelete();
                    } else if (confirmationType === "send") {
                      formProps.setFieldValue("type", "immediate");
                      onSubmit({
                        ...values,
                        type: "immediate",
                      });
                      setIsOpenConfirmationModal(false);
                    } else {
                      setIsOpenConfirmationModal(false);
                    }
                  }}
                  onToggle={() =>
                    setIsOpenConfirmationModal(!isOpenConfirmationModal)
                  }
                  isCloseOutside={false}
                />
                <ConfirmationModal
                  isOpen={isOpenSuccessModal}
                  onCancel={() => setIsOpenSuccessModal(false)}
                  state="success"
                  title={intl.formatMessage({
                    id: "process > promotion > contest > notification sent",
                  })}
                />
              </Form>
            );
          }}
        </Formik>
      </ModalBody>
    </Modal>
  );
};
export default EmailTemplateModal;
