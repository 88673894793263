import React, { useEffect } from "react";
import SelectStations from "components/SelectStations";
import { first, omit } from "lodash";
import { Label } from "reactstrap";
import { get } from "lodash";
import bn from "utils/bemnames";
import PropTypes from "prop-types";
import { Asterisk } from "components/Elements";
import { FormattedMessage } from "react-intl";
const bem = bn.create("select-audience");
export default function SelectAudience(props) {
  const { filteredStations, item, isRenderDefaultSelected, label } = props;
  const selectedStation = first(filteredStations);
  /**
   * If the user only has access to one station (or there is only one entity in the BG) they should not have to select Audience, it should just display the station name/call letters. This also applies to Contests and any other place where you have to select Audience in Promotions.
   */
  useEffect(() => {
    if (
      (!props.value ||
        (Array.isArray(props.value) && props.value.length === 0)) &&
      filteredStations.length === 1
    ) {
      let selectedValue = null;
      const market = first(Object.values(selectedStation.market));
      const option = {
        value: selectedStation.station_key,
        label: selectedStation.name,
        letters: selectedStation.call_letters,
        slogan: selectedStation.slogan,
        name: selectedStation.name,
        key: selectedStation.key,
        data: { market },
      };
      if (props.isMulti) {
        selectedValue = [option];
      } else {
        selectedValue = option;
      }
      props.onChange(selectedValue);
    }
  }, [filteredStations]);

  if (filteredStations.length <= 1) {
    if (!isRenderDefaultSelected) return null;
    const arr = [];
    if (get(selectedStation, "call_letters")) {
      arr.push(selectedStation.call_letters);
    }
    if (get(selectedStation, "name")) {
      arr.push(selectedStation.name);
    }

    return (
      <div className={bem.b()}>
        <div className={bem.e("selected-audience")}>
          <Label>
            {item.title || label}
            {props.required ? <Asterisk>*</Asterisk> : null}
          </Label>
          <span className={bem.e("selected-value")}>
            {arr.length > 0 ? (
              arr.join(" - ")
            ) : (
              <FormattedMessage id="prizes > none" />
            )}
          </span>
        </div>
      </div>
    );
  }
  return <SelectStations {...omit(props, ["filteredStations"])} />;
}
SelectAudience.propTypes = {
  isRenderDefaultSelected: PropTypes.bool,
};
SelectAudience.defaultProps = {
  isRenderDefaultSelected: true,
};
