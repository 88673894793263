import React from "react";
import bn from "utils/bemnames";
import PSLogo from "assets/img/logo/ps+logo.png";
const bem = bn.create("header-mobile");

const HeaderMobile = () => {
  return (
    <div className={bem.b()}>
      <img src={PSLogo} alt="logo" />
    </div>
  );
};
export default HeaderMobile;
