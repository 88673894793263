import React from "react";
import bn from "utils/bemnames";
import PropTypes from "prop-types";
import { FaTrash } from "react-icons/lib/fa";
import { FormattedMessage } from "react-intl";
import { Formik } from "formik";
import { get, find } from "lodash";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import Dropdown from "components/Dropdown";
import { Asterisk } from "components/Elements";
import { Form, FormGroup, Label } from "reactstrap";
import { fulfillmentList } from "utils/config";

const bem = bn.create("prize-item");

class PrizeItem extends React.Component {
  render() {
    const { prize, onChange, onDelete, mode, process } = this.props;
    const OPTIONS_PER_PLAY = [
      {
        value: 1,
        label: 1
      },
      {
        value: 2,
        label: 2
      },
      {
        value: 3,
        label: 3
      },
      {
        value: 4,
        label: 4
      }
    ];

    const fulfillment = find(
      fulfillmentList,
      item => item.value === get(prize, "fulfillment")
    );
    return (
      <div className={bem.b()}>
        <div className={bem.e("body")}>
          <div className={bem.e("top-title")}>
            <h2 className={bem.e("title")}>{get(prize, "title")}</h2>
            {mode === "edit" ? (
              <span
                className={bem.e("trash-icon")}
                onClick={() => onDelete(prize)}
              >
                <FaTrash color="#795AFA" size={23} />
              </span>
            ) : null}
          </div>
          <p className={bem.e("time")}>{get(prize, "subtitle")}</p>
          <div className={bem.e("value-section")}>
            <div className={bem.e("value")}>
              <FormattedMessage id="process > title prize value" />
              <span>${get(prize, "unit_value", 0)}</span>
            </div>
            {get(prize, "on_hold_quantity") > 0 && (
              <div className={bem.e("on-hold")}>
                <FormattedMessage id="process > title prize on hold" />
              </div>
            )}
          </div>
          <textarea
            className={bem.e("description")}
            defaultValue={get(prize, "description", "")}
            disabled
          />
          <div className={bem.e("form-fields")}>
            <Formik
              enableReinitialize
              initialValues={{
                per_play: {
                  value: get(prize, "per_play"),
                  label: get(prize, "per_play")
                },
                fulfillment: fulfillment,
                total: get(prize, "total")
              }}
              validationSchema={Yup.object().shape({
                per_play: Yup.string().required(
                  this.props.intl.formatMessage({
                    id: "prizes validate > per play is required"
                  })
                ),
                fulfillment: Yup.string().required(
                  this.props.intl.formatMessage({
                    id: "prizes validate > fulfillment is required"
                  })
                )
              })}
              onSubmit={values => {}}
            >
              {formProps => {
                const errors = formProps.errors;
                const touched = formProps.touched;
                const values = formProps.values;
                const setFieldValue = formProps.setFieldValue;
                return (
                  <Form
                    onSubmit={formProps.handleSubmit}
                    className={bem.e("form")}
                    noValidate
                  >
                    <FormGroup className={bem.e("group-col")}>
                      <Dropdown
                        label={
                          <span className={bem.e("label")}>
                            <FormattedMessage id="prizes > form per play" />{" "}
                            <Asterisk>*</Asterisk>
                          </span>
                        }
                        disabled={mode === "view"}
                        placeholder=""
                        name="per_play"
                        error={touched.per_play && errors.per_play}
                        onChange={selectedOption => {
                          setFieldValue("per_play", selectedOption);
                          onChange(prize, {
                            per_play: selectedOption.value,
                            fulfillment: get(values, "fulfillment.value")
                          });
                        }}
                        value={values.per_play}
                        options={OPTIONS_PER_PLAY}
                        closeMenuOnSelect
                      />
                    </FormGroup>
                    <FormGroup className={bem.e("group")}>
                      <Dropdown
                        label={
                          <span className={bem.e("label")}>
                            <FormattedMessage id="prizes > form fulfillment" />{" "}
                            <Asterisk>*</Asterisk>
                          </span>
                        }
                        disabled={mode === "view"}
                        name="fulfillment"
                        error={touched.fulfillment && errors.fulfillment}
                        onChange={selectedOption => {
                          setFieldValue("fulfillment", selectedOption);
                          onChange(prize, {
                            per_play: get(values, "per_play.value"),
                            fulfillment: selectedOption.value
                          });
                        }}
                        value={values.fulfillment}
                        options={fulfillmentList}
                        closeMenuOnSelect
                      />
                    </FormGroup>
                    <FormGroup className={bem.e("group-col")}>
                      <Label className={bem.e("label")}>
                        {" "}
                        <FormattedMessage id="prizes > form total value" />{" "}
                      </Label>
                      <div className={bem.e("total-row")}>${values.total}</div>
                    </FormGroup>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
        <div className={bem.e("footer")}>
          <div className={bem.e("footer-left")}>
            <span className={bem.e("footer-label")}>
              <FormattedMessage id="prizes > title plays" />
            </span>
            <span className={bem.e("footer-value")}>
              {get(process, "data.fields.contest_number_of_plays.value", 0)}
            </span>
          </div>
          <div className={bem.e("footer-right")}>
            <span className={bem.e("footer-label")}>
              <FormattedMessage id="prizes > title total allotment" />
            </span>
            <span className={bem.e("footer-value")}>
              {get(prize, "total_allotment", 0)}
            </span>
          </div>
        </div>
      </div>
    );
  }
}
PrizeItem.propTypes = {
  mode: PropTypes.string,
  process: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  prize: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};
PrizeItem.defaultProps = {
  mode: "view",
  onChange: () => {},
  onDelete: () => {}
};
export default injectIntl(PrizeItem);
