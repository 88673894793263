import React, { useState } from "react";
import bn from "utils/bemnames";
import Dropdown from "components/Dropdown";
import FilterDropdown from "components/ClockManager/ClockCreator/Calendar/ProgrammingClock/FilterDropdown";
import SearchInput from "components/SearchAutocompleteInput";
import { autoSuggestProgrammingElements } from "store/actions/process";
import { find, map, filter, includes, get, orderBy } from "lodash";
import SelectStations from "components/SelectStations";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import { Button } from "reactstrap";
import ScheduleSettingsModal from "../ScheduleSettingsModal";
import PrivilegedComponent from "components/PrivilegedComponent";
import { PRIVILEGES } from "utils/constants";
import ConfirmationModal from "components/ConfirmationModal";
import { SettingIcon } from "components/CustomIcons";
const bem = bn.create("schedule-calendar");
export default function ScheduleFilter(props) {
  const {
    intl,
    values,
    definitions,
    onChange,
    isShowSearchInput,
    onChangeSelectedStation,
    onSearchSelectItem,
    startWeekOn,
    isShowStationDropdown,
    isDisableStationDropdown,
    isDropdownGroup,
    isShortGroupByNumber,
    isShowButtons,
    step,
    template,
    user,
    process,
    onValueChanged,
    buttonClicked,
    isShowSettings,
  } = props;
  const [isOpenDialogPublish, setIsOpenDialogPublish] = useState(false);

  const definitionOptions = orderBy(
    map(definitions, (slotDefinition) => ({
      label: slotDefinition.name,
      value: slotDefinition.id,
      color: slotDefinition.color || "#6A9C00",
    })),
    "label",
    "asc"
  );
  let weekdaysOptions = [
    {
      value: "Monday-Sunday",
      label: intl.formatMessage({
        id: "process > promotion > liner > schedule > Monday-Sunday",
      }),
    },
    {
      value: "Monday-Friday",
      label: intl.formatMessage({
        id: "process > promotion > liner > schedule > Monday-Friday",
      }),
    },
  ];
  if (startWeekOn === "sunday") {
    weekdaysOptions = [
      {
        value: "Sunday-Saturday",
        label: intl.formatMessage({
          id: "process > promotion > liner > schedule > Sunday-Saturday",
        }),
      },
      ...weekdaysOptions,
    ];
  }
  const typeOptions = [
    {
      value: "daypart",
      label: intl.formatMessage({
        id: "process > promotion > liner > schedule > by Daypart",
      }),
    },
    {
      value: "show",
      label: intl.formatMessage({
        id: "process > promotion > liner > schedule > by Show",
      }),
    },
  ];
  const isPublishedValue = get(process, "data.fields.is_publish.value", false);
  // They should only be able to do this after they have completed both Details and Script sections.
  const isDisabledPublish =
    get(process, "data.fields.process_step_index.value") < 3 ||
    !get(process, "data.fields.key.value");
  const is_publish = step && step.fields.find((f) => f.field === "is_publish");

  return (
    <div className={bem.e("filter-wrapper")}>
      <div className={bem.e("filter-dropdown")}>
        {isShowStationDropdown && (
          <SelectStations
            item={{ field: "stations" }}
            placeholder={intl.formatMessage({
              id: "process > promotion > liner > schedule > placeholder select",
            })}
            name="station_id"
            template={template}
            isFilterByServerConfig={true}
            isFilterByClock={true}
            isShortGroupByNumber={isShortGroupByNumber}
            isGroup={isDropdownGroup}
            disabled={isDisableStationDropdown}
            onChange={(option) => {
              if (isDisableStationDropdown) return;
              onChangeSelectedStation(get(option, "value")); // performance to call clock data only change
            }}
            value={values.station_id}
            isMulti={false}
            closeMenuOnSelect={true}
            // defaultMenuIsOpen
          />
        )}

        {props.isShowWeekdaysStationDropdown && (
          <Dropdown
            placeholder={intl.formatMessage({
              id: "process > promotion > liner > schedule > placeholder select",
            })}
            name="weekdays"
            containerClass={bem.e("dropdown-weekdays")}
            onChange={(option) => {
              onChange({
                ...values,
                weekdays: option.value,
              });
            }}
            value={find(
              weekdaysOptions,
              (item) => item.value === values.weekdays
            )}
            options={weekdaysOptions}
            closeMenuOnSelect={true}
          />
        )}

        {props.isShowTypeIdDropdown && (
          <Dropdown
            placeholder={intl.formatMessage({
              id: "process > promotion > liner > schedule > placeholder select",
            })}
            name="type_id"
            onChange={(option) => {
              onChange({
                ...values,
                type_id: option.value,
              });
            }}
            containerClass={bem.e("dropdown-type")}
            value={find(typeOptions, (item) => item.value === values.type_id)}
            options={typeOptions}
            closeMenuOnSelect={true}
          />
        )}

        {props.isShowSlotDefinitionDropdown && (
          <FilterDropdown
            name="slot_definition"
            className={bem.e("dropdown-slot")}
            placeholder={intl.formatMessage({
              id:
                "process > promotion > liner > schedule > placeholder display",
            })}
            onFilter={(selected) => {
              onChange({
                ...values,
                definition: map(selected, (item) => item.value),
              });
            }}
            isClearable
            value={filter(definitionOptions, (item) =>
              includes(get(values, "definition", []), item.value)
            )}
            isShowPlaceholder
            options={definitionOptions}
          />
        )}

        {isShowSettings && (
          <div
            className={bem.e("setting-icon")}
            onClick={() => props.setIsScheduleSettingsModalOpen(true)}
          >
            <SettingIcon />
          </div>
        )}
      </div>
      {isShowSearchInput && (
        <SearchInput
          searchCallback={(params) => {
            autoSuggestProgrammingElements({
              data: {
                keyword: params.q,
                station_id: values.station_id,
              },
              cb: params.cb,
            });
          }}
          onChange={(value) => {
            onChange({
              ...values,
              search: value,
            });
          }}
          value={get(values, "search", "")}
          placeholder={intl.formatMessage({
            id: "process > promotion > liner > schedule > search placeholder",
          })}
          onSelect={onSearchSelectItem}
        />
      )}
      {isShowButtons && (
        <div className={bem.e("schedule-buttons")}>
          <PrivilegedComponent
            requires={
              template.key === "contest"
                ? [PRIVILEGES.CONTEST_PUBLISH]
                : [PRIVILEGES.PROGRAMMING_ELEMENT_PUBLISH]
            }
          >
            <Button
              color="blue"
              className={classnames("btn-radius", bem.e("btn-publish"))}
              onClick={() => setIsOpenDialogPublish(true)}
              disabled={isDisabledPublish}
            >
              {isPublishedValue ? (
                <FormattedMessage id="process > button unpublish" />
              ) : (
                <FormattedMessage id="process > button publish" />
              )}
            </Button>
          </PrivilegedComponent>
          {/* button save draft */}
          <PrivilegedComponent
            requires={
              template.key === "contest"
                ? [PRIVILEGES.CONTEST_SAVE_DRAFT]
                : [PRIVILEGES.PROGRAMMING_ELEMENT_SAVE_DRAFT]
            }
          >
            <Button
              color="blue"
              outline
              className={classnames(bem.e("btn-draft"), "btn-radius")}
              onClick={(event) => {
                buttonClicked(
                  step,
                  {
                    ...step.fields.find((f) => f.key === "save_button"),
                    draft: true,
                  },
                  process,
                  template,
                  user,
                  event,
                  null,
                  true
                );
              }}
            >
              <FormattedMessage id="process > button save draft" />
            </Button>
          </PrivilegedComponent>
          <ConfirmationModal
            isOpen={isOpenDialogPublish}
            onToggle={() => setIsOpenDialogPublish(!isOpenDialogPublish)}
            onCancel={() => setIsOpenDialogPublish(false)}
            className={bem.e("confirmation-publish-modal")}
            title={
              isPublishedValue ? (
                <span>
                  <FormattedMessage id="process > confirm unpublish item" />
                  <FormattedMessage id="process > confirm unpublish item description" />
                </span>
              ) : (
                <span>
                  <FormattedMessage id="process > confirm publish item" />
                  <FormattedMessage id="process > confirm publish item description" />
                </span>
              )
            }
            cancelTitle={<FormattedMessage id="process > button cancel" />}
            description={null}
            onConfirm={(event) => {
              setIsOpenDialogPublish(false);
              onValueChanged(is_publish, !isPublishedValue);
              process.data.fields.is_publish = {
                value: !isPublishedValue,
                is_dirty: true,
              };
              buttonClicked(
                step,
                step.fields.find((f) => f.key === "save_button"),
                process,
                template,
                user,
                event,
                null,
                true
              );
            }}
            isCloseOutside={false}
          />
        </div>
      )}
      {isShowSettings && (
        <ScheduleSettingsModal
          isOpen={props.isScheduleSettingsModalOpen}
          onCancel={() => props.setIsScheduleSettingsModalOpen(false)}
          onToggle={() =>
            props.setIsScheduleSettingsModalOpen(
              !props.isScheduleSettingsModalOpen
            )
          }
          stationId={values.station_id}
          onSubmit={props.onSubmitScheduleSettings}
        />
      )}
    </div>
  );
}
ScheduleFilter.defaultProps = {
  isShowSearchInput: true,
  isShowWeekdaysStationDropdown: true,
  isShowTypeIdDropdown: true,
  isShowSlotDefinitionDropdown: true,
  isShowDropdown: true,
  isDisableStationDropdown: false,
  isShowButtons: false,
  isShowSettings: false,
};
