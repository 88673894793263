import React from "react";
import Page from "components/Page";
import PrivilegedComponent from "components/PrivilegedComponent";
import PrizeList from "components/PrizeList";
import { Redirect } from "react-router";

const PrizesPage = (props) => {
  return (
    <PrivilegedComponent requires={[]}>
      {({ isValid }) => {
        if (isValid) {
          return (
            <Page title="" applyPadding={false}>
              <PrizeList {...props} />
            </Page>
          );
        } else {
          return <Redirect to="/" />;
        }
      }}
    </PrivilegedComponent>
  );
};

export default PrizesPage;
