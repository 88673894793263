import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Prompt, useLocation, useHistory } from "react-router-dom";
import ReminderModal from "components/ReminderModal";
// sandbox: https://codesandbox.io/s/custom-route-blocking-modal-r5dpu?file=/package.json:253-258
const BlockingComponent = ({
  modalProps,
  isBlocked,
  callBackDismissBlockNavigation,
  sidebarActive,
  setSidebarActive,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [prevSidebarActive, setPrevSidebarActive] = useState("");
  const location = useLocation();
  const history = useHistory();
  const [lastLocation, setLastLocation] = useState(location);
  const [shouldUnload, setShouldUnload] = useState(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
    setShouldUnload(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const showModal = (nextLocation) => {
    openModal();
    setLastLocation(nextLocation);
  };

  const handleBlockedRoute = (nextLocation) => {
    if (!confirmedNavigation && isBlocked && nextLocation.isReminder) {
      showModal(nextLocation);
      setPrevSidebarActive(sidebarActive);
      return false;
    }

    return true;
  };

  const handleConfirmNavigationClick = () => {
    closeModal();
    setConfirmedNavigation(true);
    if (modalProps.onDiscard) modalProps.onDiscard();
  };
  const handleConfirmClick = () => {
    closeModal();
    setSidebarActive(prevSidebarActive);
    if (modalProps.onConfirm) modalProps.onConfirm();
  };
  const handleCancelClick = () => {
    closeModal();
    if (modalProps.isCancelConfirmedNavigation) setConfirmedNavigation(true);
    if (modalProps.onCancel) modalProps.onCancel();
  };

  // Block react routes
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      setShouldUnload(true);
      history.push(lastLocation.pathname);
      if (callBackDismissBlockNavigation) callBackDismissBlockNavigation();
    }
  }, [confirmedNavigation, lastLocation, history]);

  // Block non-react routes
  useEffect(() => {
    const unload = (event) => {
      if (isBlocked && !shouldUnload) {
        event.returnValue = modalProps.title;
      }
      if (shouldUnload) {
        event.returnValue = "";
      }
    };
    window.addEventListener("beforeunload", unload);

    return () => window.removeEventListener("beforeunload", unload);
  }, [isBlocked, shouldUnload]);

  return (
    <>
      <Prompt when message={handleBlockedRoute} />
      <ReminderModal
        {...modalProps}
        isOpen={isModalOpen}
        onToggle={closeModal}
        onConfirm={handleConfirmClick}
        onCancel={handleCancelClick}
        onDiscard={handleConfirmNavigationClick}
      />
    </>
  );
};

BlockingComponent.propTypes = {
  isBlocked: PropTypes.bool.isRequired,
  modalProps: PropTypes.any,
};

export default BlockingComponent;
