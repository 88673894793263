import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { compose, withHandlers, withState, lifecycle } from "recompose";
import { get, map, omit, filter, sortBy, keyBy } from "lodash";
import moment from "moment";

// import ToastManager from "components/ToastManager";
import { updateClockRelations, getClock } from "store/actions/clocks";
import DefineHourModal from "./view";

export default injectIntl(
	compose(
		connect(
			(state, ownProps) => {
				return {
					updateClockError: get(state, "clocks.updateClock.error", false),
				}
			},
			{
				updateClockRelations,
				getClock
			}
		),
		withState("isLoading", "setIsLoading", false),
		withState("isSaveSuccessModalOpen", "setIsSaveSuccessModalOpen", false),
		withState("isDeleteSuccessModalOpen", "setIsDeleteSuccessModalOpen", false),
		withState("isCopyEnabled", "setIsCopyEnabled", false),
		withState("isDeleteHourBlockModalOpen", "setIsDeleteHourBlockModalOpen", false),
		withState("deleteAll", "setDeleteAll", false),
		withHandlers({
			addEmptyTimeSlot: ({ selectedBlock }) => ({ values, setFieldValue, setFieldTouched }) => {

				let startTime = get(selectedBlock, "hour") * 60;
				let startDate = moment().set({ hours: 0, minutes: parseInt(startTime), seconds: 0, millisecond: 0 });

				let id = `${Date.now()}_new`;

				let slot = {
					id,
					day: get(selectedBlock, "day"),
					start_time: startTime,
					start_date: startDate,
					is_time_set: false,
					slot_definition: null,
					locked: false,
					priority: {
						label: "",
						value: "",
					},
					deleted: false,
				}

				setFieldValue("time_slots", { ...keyBy(sortBy(values.time_slots, [function (_slot) { return !_slot.is_time_set }, "start_time"]), "id"), [id]: slot });
				setFieldTouched("time_slots", true);
			},
			onSaveTimeslot: ({ getClock, setIsLoading, toggle, updateClockRelations, clockId, setIsSaveSuccessModalOpen, setIsDeleteSuccessModalOpen, deleteAll, setDeleteAll, setIsDeleteHourBlockModalOpen }) => ({ time_slots, days, hours, randomize }) => {

				setIsLoading(true);

				let timeSlotsToSave = filter(time_slots, ({ id, deleted }) => !(deleted && typeof id === "string" && id.includes("_new")));
				// TODO check for implement with new label_ids
				// const groupedByLabel = groupBy(values(timeSlotsToSave), (_slot) => get(_slot, "slot_definition.label", ""));
				// let isValid = true;
				// forOwn(groupedByLabel, (slotsWithSameLabel) => {
				// 	const activeSlots = filter(slotsWithSameLabel, (_slot) => !_slot.deleted);
				// 	if (activeSlots.length < 2) {
				// 		return;
				// 	}
				// 	const priorities = map(activeSlots, "priority.value");
				// 	if (uniq(priorities).length < activeSlots.length) {
				// 		isValid = false;
				// 	}
				// });
				// if (!isValid) {
				// 	ToastManager.show({
				// 		message: intl.formatMessage({
				// 			id: `clock creator > define hour > slot priority must be unique`
				// 		}),
				// 		autoDismiss: 2,
				// 		level: "error"
				// 	});
				// 	setIsLoading(false);
				// 	return;
				// }
				let data = {
					clock_id: clockId,
					copy_for_days: days,
					copy_for_hours: map(hours, hour => hour.value),
					randomize,
					slots: map(timeSlotsToSave, ({ id, start_time, day, label_ids, locked, priority, deleted }) => {

						let _slot = {
							id,
							start_time,
							day,
							label_ids: map(label_ids, i => i.value),
							priority: get(priority, "value", ""),
							locked,
						}

						if (typeof id === "string" && id.includes("_new")) {
							_slot = omit(_slot, ["id"]);
						} else if (deleted) {
							_slot = { ..._slot, deleted }
						}

						if (deleteAll) {
							_slot = { ..._slot, deleted: true }
						}

						return _slot;
					}),
				};

				updateClockRelations("define_block", data, (clock) => {
					setIsLoading(false);
					getClock(clockId)
					if (deleteAll) {
						setIsDeleteHourBlockModalOpen(false);
						setIsDeleteSuccessModalOpen(true);
					} else {
						setIsSaveSuccessModalOpen(true);
					}


					setTimeout(() => {
						if (deleteAll) {
							setIsDeleteSuccessModalOpen(false);
							setDeleteAll(false);
						} else {
							setIsSaveSuccessModalOpen(false);
						}
						toggle();
					}, 2000);
				});

			},
		}),
		lifecycle({
			componentDidUpdate(prevProps) {
				if ((this.props.updateClockError || this.props.updateClockError) && this.props.isLoading) {
					this.props.setIsLoading(false);
				}
			}
		}),
	)(DefineHourModal)
);