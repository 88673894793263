import UserAssignments from "./view";
import { get, map, uniq, isFunction, toLower, sortBy } from "lodash";
import { connect } from "react-redux";
import { compose, withState, lifecycle, withHandlers } from "recompose";
import { injectIntl } from "react-intl";
import { getAllStationsAndTeams } from "store/actions/teams";
import { getHubs } from "store/actions/hubs";
import {
  updateAssignmentUser,
  getUser,
  setIsNewUser,
} from "store/actions/users";
import URL from "utils/urls";
import history from "components/History";
import { adminTabs } from "utils/config";
import { getRoleIdByName } from "utils/helpers";

export default injectIntl(
  compose(
    connect(
      (state, props) => {
        let id = props.id;
        return {
          id,
          isLoading:
            get(state, "users.addUser.loading") ||
            get(state, "users.updateUser.loading") ||
            get(state, `users.user.${id}.loading`),
          isNewUser: get(state, "users.isNewUser", false),
          user: get(state, `users.user.${id}.data`, {}),
          auth: state.auth,
          allStationsAndTeams: get(
            state,
            "teams.allStationsAndTeams.data.data",
            []
          ),
          hubs: get(state, "hubs.hubs.data.data", []),
          productionMultimarket: get(
            state,
            "auth.info.production_multimarket",
            false
          ),
          digitalMultimarket: get(
            state,
            "auth.info.digital_multimarket",
            false
          ),
          promotionMultimarket: get(
            state,
            "auth.info.promotion_multimarket",
            false
          ),
        };
      },
      {
        getAllStationsAndTeams,
        updateAssignmentUser,
        getUser,
        setIsNewUser,
        getHubs,
      }
    ),
    withState("isView", "setIsView", (props) =>
      props.mode === "edit" || props.mode === "add" ? false : true
    ),
    withState("isEntitiesSaved", "setIsEntitiesSaved", false),
    withHandlers({
      onSubmit: ({
        updateAssignmentUser,
        id,
        getUser,
        setIsView,
        setIsEntitiesSaved,
        isNewUser,
        setIsNewUser,
        onSubmit,
      }) => (values) => {
        let stationsTeams = [];
        let allRoles = [];
        let dataUpdate = {
          id,
          stations: map(values.stations, (station) => {
            let stationArr = [];
            if (get(station, "call_letters", ""))
              stationArr.push(station.call_letters);
            if (get(station, "name", ""))
              stationArr.push(station.name);
            stationsTeams.push(
              toLower(stationArr.join(' - '))
            );
            return {
              id: station.id,
              key: station.key,
              roles: map(station.roles, (role) => {
                allRoles.push(toLower(role.label));
                return parseInt(role.value);
              }),
              privileges: map(station.privileges, (privilege) =>
                parseInt(privilege.value)
              ),
              shows: map(station.shows, (item) => {
                return parseInt(item.value);
              }),
            };
          }),
          // teams: map(values.teams, (team) => {
          //   stationsTeams.push(toLower(team.name));
          //   return parseInt(team.id);
          // }),
          hubs: values.hubs,
        };
        dataUpdate = {
          ...dataUpdate,
          stations_string: uniq(sortBy(stationsTeams)).join(", "),
          roles_string: uniq(sortBy(allRoles)).join(", "),
          is_apply_entities_to_all: !values.is_separately,
        };

        const handleNewUserRedirect = () => {
          if (isNewUser) {
            setTimeout(() => {
              setIsNewUser(false);
              history.push(
                URL.ADMINS({
                  tab: adminTabs.TAB_USERS,
                })
              );
            }, 2000);
          }
        };
        // submit the callback function to create new user
        if (isFunction(onSubmit)) {
          onSubmit(dataUpdate);
          handleNewUserRedirect();
        } else {
          // update user
          updateAssignmentUser(dataUpdate, () => {
            if (id) {
              getUser(id);
            }
            setIsEntitiesSaved(true);
            handleNewUserRedirect();
            setTimeout(() => setIsView(true), 2000);
          });
        }
      },
    }),
    lifecycle({
      componentDidMount() {
        this.props.getAllStationsAndTeams();
        const isShowHubAssignments = !!getRoleIdByName(
          "Hub Manager",
          this.props.user.enterprise_roles
        );
        if (isShowHubAssignments) {
          this.props.getHubs({
            pageSize: 1000,
            sorted: [],
            filtered: [],
            page: 0,
          });
        }
      },
      componentDidUpdate(prevProps) {
        if (this.props.mode !== prevProps.mode) {
          this.props.setIsView(this.props.mode === "edit" ? false : true);
        }
      },
    })
  )(UserAssignments)
);
