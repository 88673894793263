import EditDaypart from "./view";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import {
  get,
  omit,
  map,
  filter,
  orderBy,
  groupBy,
  forOwn,
  includes,
} from "lodash";
import history from "components/History";
import URL from "utils/urls";
import { compose, withState, withHandlers, lifecycle } from "recompose";

import {
  getClock,
  updateClockRelations,
  resetClock,
} from "store/actions/clocks";
function sortSchedules(slots) {
  const groups = groupBy(slots, "days");
  const schedules1 = orderBy(get(groups, "2,3,4,5,6", []), "start_time", "asc"); // Mon to Fri
  const schedules2 = orderBy(get(groups, "7", []), "start_time", "asc"); // Saturday
  const schedules3 = orderBy(get(groups, "1", []), "start_time", "dascesc"); // Sunday
  let items = [...schedules1, ...schedules2, ...schedules3];
  // sort other days group
  forOwn(groups, (value, key) => {
    if (!includes(["2,3,4,5,6", "7", "1"], key)) {
      const data = orderBy(value, "start_time", "asc");
      items = [...items, ...data];
    }
  });
  return items;
}
export default injectIntl(
  compose(
    connect(
      (state, ownProps) => {
        const selectedClock = get(
          state,
          `clocks.viewedClocks.${ownProps.clockId}.data`,
          {}
        );
        return {
          selectedClock: {
            ...selectedClock,
            slots: sortSchedules(selectedClock.slots),
          },
          updateClockError: get(state, "clocks.updateClock.error", false),
        };
      },
      {
        getClock,
        resetClock,
        updateClockRelations,
      }
    ),
    withState("isLoading", "setIsLoading", false),
    withState("deleteDaypartSchedule", "setDeleteDaypartSchedule", null),
    withState(
      "isOpenConfirmationResetModal",
      "setIsOpenConfirmationResetModal",
      false
    ),
    withState("shouldBlockNavigation", "setShouldBlockNavigation", false),
    withHandlers({
      onResetDaypart: ({ resetClock, clockId }) => (cb) => {
        resetClock({
          data: {
            clock_id: clockId,
          },
          cb,
        });
      },
      onSaveDaypart: ({ updateClockRelations, setIsLoading, clockId }) => ({
        title,
        schedules,
      }) => {
        setIsLoading(true);
        const slots = map(
          filter(
            schedules,
            ({ id, deleted }) =>
              !(deleted && typeof id === "string" && id.includes("_new"))
          ),
          (schedule) => {
            let _schedule = {
              ...omit(schedule, ["label", "days_of_week", "deleted"]),
              name: schedule.label,
              days: schedule.days_of_week,
            };

            if (schedule.deleted) {
              return { ..._schedule, deleted: true };
            }

            if (
              typeof schedule.id === "string" &&
              schedule.id.includes("_new")
            ) {
              return omit(_schedule, ["id"]);
            }

            return _schedule;
          }
        );
        const data = {
          clock_id: clockId,
          clock_title: title,
          slots,
        };

        updateClockRelations("update_dayparts", data, (clock) => {
          setIsLoading(false);
          history.push(URL.CLOCK_MANAGER());
        });
      },
      addEmptySchedule: () => ({ values, setFieldValue }) => {
        const id = `${Date.now()}_new`;
        const schedule = {
          id,
          start_time: undefined,
          start_date: undefined,
          end_time: undefined,
          end_date: undefined,
          days_of_week: [1, 2, 3, 4, 5, 6, 7],
          label: "",
          deleted: false,
        };
        setFieldValue("schedules", [...values.schedules, schedule]);
      },
    }),
    lifecycle({
      componentDidMount() {
        if (this.props.clockId) {
          this.props.getClock(this.props.clockId);
        }
      },
      componentDidUpdate() {
        if (
          (this.props.updateClockError || this.props.updateClockError) &&
          this.props.isLoading
        ) {
          this.props.setIsLoading(false);
        }
      },
    })
  )(EditDaypart)
);
