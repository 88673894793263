import View from "./view";
import { compose, withState, lifecycle } from "recompose";
import { isEqual } from "lodash";
export default compose(
  withState("list", "setList", ({ items }) => items),
  lifecycle({
    componentDidUpdate() {
      if (!isEqual(this.props.list, this.props.items)) {
        this.props.setList(this.props.items);
      }
    }
  })
)(View);
