import * as React from "react";
import moment from "moment";
import { get, map } from "lodash";
import Avatar from "components/Avatar";
import { getSortUserName } from "utils/helpers";
import { DateTimeFormat } from "utils/config";
import FormattedTaggedMessage from "components/FormattedTaggedMessage";
import classNames from "classnames";
import Attachment from "components/ChatInput/attachment";

const Message = (props) => {
  const { bem, message } = props;
  const { timestamp, from, highlight } = message;
  const getPrettyTime = () => {
    const currentTime = new Date().getTime();
    const diffInSec = (currentTime - timestamp) / 1000;
    if (diffInSec <= 3600 * 24 * 2) {
      return moment(timestamp).fromNow();
    } else {
      return moment(timestamp).format(DateTimeFormat);
    }
  };
  const time = getPrettyTime();
  return (
    <div
      className={classNames(bem.e("message-item"), {
        [bem.e("message-highlight")]: highlight,
      })}
    >
      <div className={bem.e("message-avatar")}>
        {get(from, "avatar_url") ? (
          <Avatar src={from.avatar_url} size={40} alt={get(from, "name")} />
        ) : (
          <div className={bem.e("message-avatar-text")}>
            {getSortUserName(get(from, "name", ""))}
          </div>
        )}
      </div>
      <div className={bem.e("message-content")}>
        <div>
          <span className={bem.e("message-author")}>
            {get(from, "name", "")}
          </span>
          <span className={bem.e("message-time")}>{time}</span>
        </div>
        {get(message, "attachments", []).length > 0 ? (
          <div className={bem.e("message-attachments")}>
            {map(message.attachments, (item, i) => (
              <Attachment
                file={item}
                key={`message-attachment-${i}`}
                actions={["view", "download"]}
              />
            ))}
          </div>
        ) : null}
        <div className={bem.e("message-text")}>
          <FormattedTaggedMessage text={message.text} isShowTagIcon={true} />
        </div>
      </div>
    </div>
  );
};
export default Message;
