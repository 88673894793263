import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Dropdown from "components/Dropdown";
import { FaChevronLeft, FaChevronRight } from "react-icons/lib/fa";
// import { FiChevronsLeft, FiChevronsRight } from "react-icons/fi";
import { injectIntl } from "react-intl";
import { pageSizeOptions } from "utils/config";
const defaultButton = props => <button {...props}>{props.children}</button>;

class Pagination extends React.Component {
  constructor(props) {
    super();
    this.changePage = this.changePage.bind(this);
    this.state = {
      visiblePages: this.getVisiblePages(null, props.pages)
    };
  }
  static propTypes = {
    pages: PropTypes.number,
    page: PropTypes.number,
    rpp: PropTypes.number,
    PageButtonComponent: PropTypes.any,
    onPageChange: PropTypes.func,
    previousText: PropTypes.string,
    nextText: PropTypes.string,
    onPageSizeChange: PropTypes.func,
    showPageSizeOptions: PropTypes.bool
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.pages !== nextProps.pages) {
      this.setState({
        visiblePages: this.getVisiblePages(null, nextProps.pages)
      });
    }
    // this.changePage(nextProps.page + 1);
  }

  changePageSize = rpp => {
    const { value } = rpp;
    const { page } = this.props;
    this.props.onPageSizeChange(value, page);
  };

  filterPages = (visiblePages, totalPages) => {
    return visiblePages.filter(
      page => page <= totalPages || page === 0 || page === -1
    );
  };

  getVisiblePages = (page, total) => {
    if (total < 4) {
      return this.filterPages([1, 2, 3], total);
    } else {
      if (page % 3 >= 0 && page > 2 && page + 2 < total) {
        return [-1, page - 1, page, page + 1, 0, total];
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [-1, total - 3, total - 2, total - 1, 0, total];
      } else {
        return [-1, 1, 2, 3, 0, total];
      }
    }
  };

  changePage(page) {
    const activePage = this.props.page + 1;

    if (page === activePage) {
      return;
    }

    const visiblePages = this.getVisiblePages(page, this.props.pages);

    this.setState({
      visiblePages: this.filterPages(visiblePages, this.props.pages)
    });

    this.props.onPageChange(page - 1);
  }

  render() {
    const {
      PageButtonComponent = defaultButton,
      showPageSizeOptions
    } = this.props;
    const { visiblePages } = this.state;
    const activePage = this.props.page + 1;
    const lastTab = visiblePages.slice(-1)[0];

    let totalPages = [];
    for (var i = 1; i <= lastTab; i++) {
      totalPages.push(i);
    }
    let morePages = _.differenceWith(totalPages, visiblePages).filter(pages => {
      return pages > activePage;
    });
    let lessPages = _.differenceWith(totalPages, visiblePages).filter(pages => {
      return pages < activePage;
    });
    if (visiblePages.length === 0) return null;
    return (
      <div className="Table__pagination">
        {showPageSizeOptions && (
          <div className="Table__rppContainer">
            <Dropdown
              placeholder={this.props.intl.formatMessage({
                id: "pagination > page size"
              })}
              value={{
                value: this.props.pageSize,
                label: this.props.pageSize
              }}
              options={pageSizeOptions}
              onChange={this.changePageSize}
            />
          </div>
        )}

        {/* to first page */}
        <div className="Table__firstPageWrapper">
          <PageButtonComponent
            className="Table__pageButton"
            onClick={() => {
              if (activePage === 1) return;
              this.changePage(1);
            }}
            disabled={activePage === 1}
          >
            <div className="icon">
              <svg
                viewBox="0 0 24 24"
                width="24"
                height="24"
                stroke="currentColor"
                strokeWidth="3"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <polyline points="11 17 6 12 11 7"></polyline>
                <polyline points="18 17 13 12 18 7"></polyline>
              </svg>
            </div>
          </PageButtonComponent>
        </div>

        {/* to previous page */}
        <div className="Table__prevPageWrapper">
          <PageButtonComponent
            className="Table__pageButton"
            onClick={() => {
              if (activePage === 1) return;
              this.changePage(activePage - 1);
            }}
            disabled={activePage === 1}
          >
            <FaChevronLeft size={20} className={"icon"} />
          </PageButtonComponent>
        </div>

        {/* to page number */}
        <div className="Table__visiblePagesWrapper">
          {visiblePages.map((page, index, array) => {
            if (page === -1) {
              return (
                lessPages.length !== 0 && (
                  <select
                    defaultValue=""
                    key={index}
                    className="Dropdown_Pagination"
                    id="my-select"
                    onChange={event => {
                      this.changePage(event.target.value);
                      document.getElementById("my-select").selectedIndex = 0;
                    }}
                  >
                    <option disabled value="">
                      ...
                    </option>
                    {lessPages.map((value, key) => (
                      <option key={key}>{value}</option>
                    ))}
                  </select>
                )
              );
            } else if (page === 0) {
              return (
                morePages.length !== 0 && (
                  <select
                    defaultValue=""
                    key={index}
                    className="Dropdown_Pagination"
                    id="my-select"
                    onChange={event => {
                      this.changePage(event.target.value);
                      document.getElementById("my-select").selectedIndex = 0;
                    }}
                  >
                    <option disabled value="">
                      ...
                    </option>
                    {morePages.map((value, key) => (
                      <option key={key}>{value}</option>
                    ))}
                  </select>
                )
              );
            } else {
              return (
                <PageButtonComponent
                  key={index}
                  className={
                    activePage === page
                      ? "Table__pageButton Table__pageButton--active"
                      : "Table__pageButton"
                  }
                  onClick={this.changePage.bind(null, page)}
                >
                  {page}
                </PageButtonComponent>
              );
            }
          })}
        </div>

        {/* to next page */}
        <div className="Table__nextPageWrapper">
          <PageButtonComponent
            className="Table__pageButton"
            onClick={() => {
              if (activePage === this.props.pages) return;
              this.changePage(activePage + 1);
            }}
            disabled={activePage === this.props.pages}
          >
            <FaChevronRight size={20} className={"icon"} />
          </PageButtonComponent>
        </div>

        {/* to last page */}
        <div className="Table__lastPageWrapper">
          <PageButtonComponent
            className="Table__pageButton"
            onClick={() => {
              if (activePage === this.props.pages) return;
              this.changePage(lastTab);
            }}
            disabled={activePage === this.props.pages}
          >
            <div className="icon">
              <svg
                viewBox="0 0 24 24"
                width="24"
                height="24"
                stroke="currentColor"
                strokeWidth="3"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <polyline points="13 17 18 12 13 7"></polyline>
                <polyline points="6 17 11 12 6 7"></polyline>
              </svg>
            </div>
          </PageButtonComponent>
        </div>
      </div>
    );
  }
}
Pagination.defaultProps = {
  pageSize: 25,
  onPageSizeChange: () => {}
};
export default injectIntl(Pagination);
