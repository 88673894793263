import React from "react";
import { Row, Col, Label } from "reactstrap";
import classnames from "classnames";
import { get, find, map, forEach, filter, isEmpty, forOwn } from "lodash";
import { FormattedMessage } from "react-intl";
import bn from "utils/bemnames";

import { Asterisk } from "components/Elements";
import { TrashListIcon, PlusGreenIcon } from "components/CustomIcons";
import TextInput from "components/TextInput";
import Checkbox from "components/Checkbox";
import Dropdown from "components/Dropdown";
import ColorPicker from "components/CustomColorPicker";
import ConfirmationModal from "components/ConfirmationModal";
import { getRandomColor } from "utils/colors";
const bem = bn.create("progclock-slots");
const ProgClockSlots = (props) => {
  const {
    intl,
    firstRow,
    id,
    slots,
    onChange,
    stations,
    selectedStation,
    addNewOption,
    onDelete,
    errors,
    currentDeleteCategory,
    setCurrentDeleteCategory,
    onDeleteClockSlotCategory,
    isShowTrash,
  } = props;

  const station = find(
    stations,
    (station) => station.key === selectedStation.value
  );
  const slotTypes = get(station, "clock_slot_types", {});
  const slotCategories = get(station, "clock_slot_categories", {});
  const slotCategoriesOptions = map(slotCategories, (category) => ({
    label: get(category, "title") ? get(category, "title").toString() : "",
    value: get(category, "id") ? get(category, "id").toString() : "",
    allowDeleteOption: !get(category, "is_default", 0),
  }));
  let slot = find(
    slots,
    (slot) =>
      (typeof slot.id === "string" && slot.id === id) ||
      parseInt(slot.id) === parseInt(id)
  );
  const selectedCategory = find(
    slotCategoriesOptions,
    (item) => parseInt(item.value) === parseInt(get(slot, "category.value", ""))
  );

  const updateRelatedSlotAssignedTo = (slot, assignedTo) => {
    let relatedSlots = {};

    let _relatedSlots = filter(
      slots,
      (_relatedSlot) =>
        parseInt(get(_relatedSlot, "assign_to.guid")) === parseInt(slot.guid)
    );

    forEach(_relatedSlots, (relatedSlot) => {
      relatedSlots[relatedSlot.id] = { ...relatedSlot, assign_to: assignedTo };
    });

    return relatedSlots;
  };
  const isShowReset =
    !isShowTrash && (slot.category || slot.type || slot.label || slot.color);
  return (
    <Row
      className={classnames(bem.e("slot-row"), "p-0 m-0", {
        error: !isEmpty(errors),
      })}
    >
      <Col
        xs={2}
        className={classnames(bem.e("national"), "p-0 m-0", {
          "row-1": firstRow,
        })}
      >
        <Checkbox
          checked={get(slot, "national", true)}
          onChange={(checked) => {
            let relatedSlots = checked
              ? updateRelatedSlotAssignedTo(slot, null)
              : {};

            onChange({
              ...slots,
              ...relatedSlots,
              [id]: {
                ...slot,
                national: checked,
                release_to_local: false,
                assign_to: null,
                days_before_date: null,
              },
            });
          }}
          text={intl.formatMessage({
            id: "clock creator > progclock slot national",
          })}
          checkStyle={{ color: "#fff", width: "16", height: "14" }}
        />
      </Col>
      <Col xs={3} className={classnames(bem.e("category"), "p-0 m-0")}>
        {firstRow && (
          <Label>
            <FormattedMessage id="clock creator > progclock slot category" />
            <Asterisk>*</Asterisk>
          </Label>
        )}
        <Dropdown
          name="category"
          placeholder={intl.formatMessage({
            id: "clock creator > select",
          })}
          onChange={(selectedOption) => {
            let label =
              get(selectedOption, "label", "") && get(slot, "type.label")
                ? `${get(selectedOption, "label", "")} ${get(
                    slot,
                    "type.label"
                  )}`
                : slot.label;

            let updatedSlot = { ...slot, category: selectedOption, label };

            let relatedSlots = updateRelatedSlotAssignedTo(slot, updatedSlot);

            onChange({ ...slots, ...relatedSlots, [id]: updatedSlot });
          }}
          value={selectedCategory || null}
          options={slotCategoriesOptions}
          // defaultMenuIsOpen
          onRemoveOption={setCurrentDeleteCategory}
          allowNew={true}
          formatCreateLabel={(value) => {
            return (
              <div className={bem.e("create-new-option")}>
                <span className="text">{`Create ${value}`}</span>
                <span className="plus-btn">
                  <PlusGreenIcon />
                </span>
              </div>
            );
          }}
          onCreateOption={(value) => {
            addNewOption(
              { station_id: station.key, name: value },
              "category",
              (addedOption) => {
                let label =
                  get(addedOption, "title", "") && get(slot, "type.label")
                    ? `${get(addedOption, "title", "")} ${get(
                        slot,
                        "type.label"
                      )}`
                    : slot.label;

                let updatedSlot = {
                  ...slot,
                  category: {
                    label: addedOption.title.toString(),
                    value: addedOption.id.toString(),
                  },
                  label,
                };

                let relatedSlots = updateRelatedSlotAssignedTo(
                  slot,
                  updatedSlot
                );

                onChange({ ...slots, ...relatedSlots, [id]: updatedSlot });
              }
            );
          }}
          error={errors && errors.category}
          // menuPortalTarget={document.body}
        />
      </Col>
      <Col xs={3} className={classnames(bem.e("type"), "p-0 m-0")}>
        {firstRow && (
          <Label>
            <FormattedMessage id="clock creator > progclock slot type" />
            <Asterisk>*</Asterisk>
          </Label>
        )}
        <Dropdown
          name="type"
          placeholder={intl.formatMessage({
            id: "clock creator > select",
          })}
          onChange={(selectedOption) => {
            const label =
              get(selectedOption, "label", "") && get(slot, "category.label")
                ? `${get(slot, "category.label")} ${get(
                    selectedOption,
                    "label",
                    ""
                  )}`
                : slot.label;
            let color = null;
            let order_color = 0;
            if (get(slot, "color", "")) {
              color = get(slot, "color", "");
              order_color = get(slot, "order_color", 0);
            } else {
              const colorData = getRandomColor(slots);
              color = get(colorData, "color", "");
              order_color = colorData.order_color;
            }

            const updatedSlot = {
              ...slot,
              type: selectedOption,
              label,
              color,
              order_color,
            };
            const relatedSlots = updateRelatedSlotAssignedTo(slot, updatedSlot);

            onChange({ ...slots, ...relatedSlots, [id]: updatedSlot });
          }}
          value={get(slot, "type")}
          options={map(slotTypes, (type) => ({
            label: get(type, "title") ? get(type, "title").toString() : "",
            value: get(type, "id") ? get(type, "id").toString() : "",
          }))}
          error={errors && errors.type}
        />
      </Col>
      <Col
        xs={1}
        className={classnames(bem.e("color"), "m-0", { "row-1": firstRow })}
      >
        {firstRow && (
          <Label>
            <FormattedMessage id="clock creator > progclock slot color" />
          </Label>
        )}
        <ColorPicker
          selectedColor={get(slot, "color")}
          onChange={(color) => {
            onChange({ ...slots, [id]: { ...slot, color: color } });
          }}
        />
      </Col>
      <Col xs={3} className={classnames(bem.e("slot-label"), "p-0 m-0")}>
        <TextInput
          label={
            firstRow ? (
              <span>
                <FormattedMessage id="clock creator > progclock slot label" />
                <Asterisk>*</Asterisk>
              </span>
            ) : null
          }
          type="text"
          name="name"
          placeholder={intl.formatMessage({
            id: "clock creator > progclock slot label",
          })}
          value={get(slot, "label")}
          onChange={(e) => {
            let updatedSlot = { ...slot, label: e.target.value };

            let relatedSlots = updateRelatedSlotAssignedTo(slot, updatedSlot);

            onChange({ ...slots, ...relatedSlots, [id]: updatedSlot });
          }}
          colorIndicator={get(slot, "color", "")}
          error={errors && errors.label}
        />
        {isShowTrash ? (
          <div className={"trash-icon"} onClick={() => onDelete(slot)}>
            <TrashListIcon width={15} height={18} />
          </div>
        ) : isShowReset ? (
          <div className={"reset-icon"} onClick={() => onDelete(slot)}>
            <FormattedMessage id="clock creator > button reset" />
          </div>
        ) : null}
      </Col>
      <ConfirmationModal
        isOpen={!!currentDeleteCategory}
        title={intl.formatMessage({
          id: "clock creator > confirm programming clock delete category",
        })}
        onConfirm={() => {
          onDeleteClockSlotCategory((category) => {
            // remove all selected category
            let newSlots = {};
            forOwn(slots, (slot) => {
              let newSlot = slot;
              if (
                parseInt(get(slot, "category.value")) ===
                parseInt(category.value)
              ) {
                newSlot.category = null;
              }
              newSlots = { ...newSlots, [slot.id]: newSlot };
            });
            onChange(newSlots);
          });
        }}
        onCancel={() => setCurrentDeleteCategory(null)}
        isCloseOutside={false}
      />
    </Row>
  );
};

export default ProgClockSlots;
