import View from "./view";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withState, compose } from "recompose";
import { getProductionSettings } from "store/actions/settings";
import { get, map } from "lodash";
function getTeamLeadMembers(teams) {
  if (teams.length === 0) return [];
  return map(teams, (team) => ({
    id: team.id,
    label: team.name || "",
    value: team.id,
    info: team,
    isTeam: true,
  }));
}
export default injectIntl(
  compose(
    connect(
      (state) => ({
        auth: state.auth,
        teamLeadMembers: getTeamLeadMembers(
          get(state, "teams.teamsAndLeads.data", [])
        ),
        teamLeadMembersLoading: get(
          state,
          "teams.teamsAndLeads.loading",
          false
        ),
        // allStationsAndTeams: get(
        //   state,
        //   "teams.allStationsAndTeams.data.data",
        //   []
        // ),
        productionSettings: get(state, "settings.production_settings.data", {}),
      }),
      { getProductionSettings }
    ),
    withState("isOpenDetail", "setIsOpenDetail", true)
  )(View)
);
