import DisplaySettingsForm from "./view";
import { get } from "lodash";
import { connect } from "react-redux";
import { compose, withHandlers, withState } from "recompose";
import { injectIntl } from "react-intl";
import ToastManager from "components/ToastManager";
import { updateJockConsoleDisplaySettings } from "store/actions/jocks";

export default injectIntl(
  compose(
    connect(
      (state, props) => {
        const displaySettings = get(state, "jocks.displaySettings.data");
        const selected_station = get(
          state,
          "jocks.displaySettings.selected_station"
        );
        const { loading } = displaySettings;
        const settings = get(
          displaySettings,
          `widget_settings.${selected_station}`
        );
        return {
          loading,
          settings,
          displaySettings,
          ...props,
        };
      },
      { updateJockConsoleDisplaySettings }
    ),
    withState("isActiveDragging", "setIsActiveDragging", false),
    withState("isMultiDragging", "setIsMultiDragging", false),
    withHandlers({
      onFormSubmit: ({ intl, updateJockConsoleDisplaySettings, onBack }) => (
        values
      ) => {
        const payload = {
          ...values,
          font: get(values, "font.value", ""),
          font_case: get(values, "font_case.value", ""),
        };
        updateJockConsoleDisplaySettings(payload, (success) => {
          const type = success ? "success" : "error";
          ToastManager.show({
            message: intl.formatMessage({
              id: `jock console > settings submit ${type}`,
            }),
            autoDismiss: 1.5,
            level: type,
          });
          onBack();
        });
      },
    })
  )(DisplaySettingsForm)
);
