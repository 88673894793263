import AddUpdateClientManager from "./view";
import { connect } from "react-redux";
import { compose, lifecycle, withHandlers } from "recompose";
import { get } from "lodash";
import { getClient, sendMessage, setClient } from "store/actions/clients";

export default compose(
  connect(
    (state, props) => {
      const { match } = props;
      const client_id = get(match, "params.client_id");
      const isView =
        document.location.pathname.split("/")[2] === "view" ? true : false;
      const isSidebarOpen = get(state.auth, "is_sidebar_open", false);

      return {
        isSidebarOpen,
        client_id: client_id,
        client: get(state, "clients.client.data"),
        isLoading: get(state, "clients.client.loading", false),
        process: get(state, "processes.process"),
        isView,
        user: get(state, "auth.user"),
      };
    },
    {
      getClient,
      sendMessage,
      setClient,
    }
  ),
  withHandlers({
    onSendMessage: ({ sendMessage, client_id }) => (message) => {
      sendMessage(client_id, { message });
    },
  }),
  lifecycle({
    componentDidMount() {
      if (this.props.client_id) {
        this.props.getClient(this.props.client_id);
      } else {
        this.props.setClient({});
      }
    },
    componentDidUpdate(prevProps) {
      if (this.props.client_id === prevProps.client_id) return;
      if (this.props.client_id) {
        this.props.getClient(this.props.client_id);
      } else {
        this.props.setClient({});
      }
    },
  })
)(AddUpdateClientManager);
