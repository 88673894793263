import View from "./view";
import { injectIntl } from "react-intl";
import { get } from "lodash";
import { connect } from "react-redux";
import { compose, withState } from "recompose";
import {
  syncWOCartNumbers,
  deleteSpotProcess,
  getProcess,
  addRotation,
} from "store/actions/processes";
import { withRouter } from "react-router-dom";

const Enhanced = compose(
  connect(
    (state) => ({
      info: get(state, "auth.info"),
      dateFormatByServer: get(
        state,
        "servers.currentServer.data.date_format",
        "MM/DD/YYYY"
      ),
      productionSettings: get(state, "settings.production_settings.data", {}),
    }),
    {
      syncWOCartNumbers,
      deleteSpotProcess,
      getProcess,
      addRotation,
    }
  ),
  withState("newIndexes", "setNewIndexes", []),
  withState("showFinalScriptModal", "setShowFinalScriptModal", null),
  withState(
    "isSyncCartNumberProgressModalOpen",
    "setIsSyncCartNumberProgressModalOpen",
    false
  ),
  withState("isCartNumberEditable", "setIsCartNumberEditable", [])
)(View);

export default withRouter(injectIntl(Enhanced));
