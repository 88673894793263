import React from "react";
import { FormattedMessage } from "react-intl";
import bn from "utils/bemnames";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import Spinner from "components/Spinner";
import HubManagerStations from "components/HubManagerComponents/HubManagerStations";
import HubManagerMembers from "components/HubManagerComponents/HubManagerMembers";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { adminTabs } from "utils/config";
import URL from "utils/urls";

const bem = bn.create("hub-details");

const HubDetails = (props) => {
  const { setActiveTab, activeTab, hub, isLoading } = props;
  return (
    <div className={bem.b()}>
      <Link
        className={bem.e("back-to-list")}
        to={URL.ADMINS({
          tab: adminTabs.TAB_HUBS,
        })}
      >
        {`< `}
        <FormattedMessage id="hub manager > back to hub list" />
      </Link>
      <Nav tabs className={bem.e("nav-tabs")}>
        <NavItem>
          <NavLink
            className={classnames(
              {
                active: activeTab === "stations",
              },
              bem.e("nav-link")
            )}
            onClick={() => {
              setActiveTab("stations");
            }}
          >
            <FormattedMessage id="hub manager > hub stations" />
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames(
              {
                active: activeTab === "members",
              },
              bem.e("nav-link")
            )}
            onClick={() => {
              setActiveTab("members");
            }}
          >
            <FormattedMessage id="hub manager > hub members" />
          </NavLink>
        </NavItem>
      </Nav>
      {hub ? (
        <TabContent activeTab={activeTab} className={bem.e("tab-content")}>
          <TabPane tabId="stations">
            <HubManagerStations selectedHub={hub} isAllowEditStations={false} />
          </TabPane>
          <TabPane tabId="members">
            <HubManagerMembers
              selectedHub={hub}
              isAllowEditUser={false}
              isAllowEditOrderAssignment={false}
              selectedHubManager={hub.hub_manager}
            />
          </TabPane>
        </TabContent>
      ) : null}
      <Spinner isLoading={isLoading} />
    </div>
  );
};
export default HubDetails;
