import React, { useState } from "react";
import { Row, Col, FormGroup, Label } from "reactstrap";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { get } from "lodash";
import { MenuIcon } from "components/CustomIcons";
import {
  ShadowBox,
  SectionTitle,
  SectionFields,
  Asterisk,
} from "components/Elements";
import { DownIcon, UpIcon } from "components/CustomIcons";
import TextInput from "components/TextInput";
const ContestClient = (props) => {
  const {
    validationState,
    process,
    bem,
    step,
    onValueChanged,
    intl,
    isView,
    openDetails,
    setOpenDetails,
    sectionKey,
  } = props;
  const [fieldRefs, setFieldRefs] = useState(null);
  const getFieldRefs = () => {
    if (fieldRefs) return fieldRefs;

    const client_name = step.fields.find((f) => f.field === "client_name");
    const contract_number = step.fields.find(
      (f) => f.field === "contract_number"
    );
    const salesperson = step.fields.find((f) => f.field === "salesperson");

    const payload = {
      client_name,
      contract_number,
      salesperson,
    };

    setFieldRefs(payload);

    return payload;
  };
  const { client_name, contract_number, salesperson } = getFieldRefs();
  const isOpen = get(openDetails, sectionKey, false);

  return (
    <ShadowBox className={bem.e("box-client-details")}>
      <SectionTitle
        className={bem.e("contest-client-section-title")}
        onClick={() =>
          setOpenDetails({
            ...openDetails,
            [sectionKey]: !isOpen,
          })
        }
      >
        <div className={bem.e("box-head-title")}>
          <span className={"menu-selection-icon"}>
            <MenuIcon />
          </span>
          <FormattedMessage id="process > promotion > contest > title contest client" />
        </div>
        <span className={bem.e("box-action")}>
          {isOpen ? <UpIcon /> : <DownIcon />}
        </span>
      </SectionTitle>
      {isOpen && (
        <React.Fragment>
          <SectionFields
            className={classnames(bem.e("section-fields"), {
              [bem.e("contest-details-section-fields-view")]: isView,
              [bem.e("contest-details-section-fields")]: !isView,
            })}
          >
            {isView ? (
              <React.Fragment>
                <Row>
                  <Col xs={6} className={bem.e("reset-col")}>
                    <FormGroup>
                      <Label>{client_name.title}</Label>
                      <span className={bem.e("order-view-value")}>
                        {process.data.fields[client_name.field]
                          ? process.data.fields[client_name.field].value
                          : intl.formatMessage({ id: "process > none" })}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col xs={6} className={bem.e("reset-col")}>
                    <FormGroup>
                      <Label>{contract_number.title}</Label>
                      <span className={bem.e("order-view-value")}>
                        {process.data.fields[contract_number.field]
                          ? process.data.fields[contract_number.field].value
                          : intl.formatMessage({ id: "process > none" })}
                      </span>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} className={bem.e("reset-col")}>
                    <FormGroup>
                      <Label>{salesperson.title}</Label>
                      <span className={bem.e("order-view-value")}>
                        {process.data.fields[salesperson.field]
                          ? process.data.fields[salesperson.field].value
                          : intl.formatMessage({ id: "process > none" })}
                      </span>
                    </FormGroup>
                  </Col>
                </Row>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Row>
                  <Col xs={6} className={bem.e("reset-col")}>
                    <FormGroup>
                      <TextInput
                        label={
                          <span>
                            {client_name.title}
                            {client_name.mandatory && <Asterisk>*</Asterisk>}
                          </span>
                        }
                        required
                        name="client_name"
                        placeholder={intl.formatMessage({
                          id: "process > promotion > contest > enter name",
                        })}
                        value={
                          process.data.fields[client_name.field]
                            ? process.data.fields[client_name.field].value
                            : ""
                        }
                        onChange={(event) => {
                          onValueChanged(
                            client_name,
                            get(event, "target.value", "")
                          );
                        }}
                        error={get(
                          validationState,
                          "client_name.validation_error"
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6} className={bem.e("reset-col")}>
                    <FormGroup>
                      <TextInput
                        label={
                          <span>
                            {contract_number.title}
                            {contract_number.mandatory && (
                              <Asterisk>*</Asterisk>
                            )}
                          </span>
                        }
                        required
                        name="contract_number"
                        placeholder={intl.formatMessage({
                          id: "process > promotion > contest > enter number",
                        })}
                        value={
                          process.data.fields[contract_number.field]
                            ? process.data.fields[contract_number.field].value
                            : ""
                        }
                        onChange={(event) => {
                          onValueChanged(
                            contract_number,
                            get(event, "target.value", "")
                          );
                        }}
                        error={get(
                          validationState,
                          "contract_number.validation_error"
                        )}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} className={bem.e("reset-col")}>
                    <FormGroup>
                      <TextInput
                        label={
                          <span>
                            {salesperson.title}
                            {salesperson.mandatory && <Asterisk>*</Asterisk>}
                          </span>
                        }
                        required
                        name="salesperson"
                        placeholder={intl.formatMessage({
                          id: "process > promotion > contest > none",
                        })}
                        value={
                          process.data.fields[salesperson.field]
                            ? process.data.fields[salesperson.field].value
                            : ""
                        }
                        onChange={(event) => {
                          onValueChanged(
                            salesperson,
                            get(event, "target.value", "")
                          );
                        }}
                        error={get(
                          validationState,
                          "salesperson.validation_error"
                        )}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </SectionFields>
        </React.Fragment>
      )}
    </ShadowBox>
  );
};

export default ContestClient;
