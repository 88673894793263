import * as yup from "yup";
import { validSSN } from "utils/helpers";
import { upperFirst, get, replace } from "lodash";
import { getFieldName } from "components/FormBuilder/helper";
export function createYupSchema(schema, config, intl) {
  const { is_required, key } = config;
  let validationType = "string";
  // switch (key) {
  //   case "single_line_text":
  //     validationType = "string";
  //     break;
  //   case "textarea":
  //     validationType = "string";
  //     break;
  //   default:
  //     break;
  // }
  if (!yup[validationType]) {
    return schema;
  }
  let validator = yup[validationType]();
  const fieldName = getFieldName(config);
  let errorFieldName = upperFirst(
    replace(fieldName, new RegExp("_", "g"), " ")
  );
  if (is_required) {
    let type = "required";
    let params = [
      intl.formatMessage(
        { id: `validate > {field} is required` },
        {
          field: errorFieldName,
        }
      ),
    ];
    if (validator[type]) {
      validator = validator[type](...params).nullable();
    }
  } else {
    validator = yup[validationType]().nullable();
  }
  if (key === "email_address") {
    let type = "email";
    let params = [
      intl.formatMessage(
        { id: `validate > {field} is email` },
        {
          field: errorFieldName,
        }
      ),
    ];
    if (validator[type]) {
      validator = validator[type](...params);
    }
  }
  if (key === "url" || key === "instagram_link") {
    let type = "url";
    let params = [
      intl.formatMessage(
        { id: `validate > {field} is url` },
        {
          field: errorFieldName,
        }
      ),
    ];
    if (validator[type]) {
      validator = validator[type](...params);
    }
  }
  if (key === "social_security") {
    let type = "test";
    let params = [
      "ssn",
      intl.formatMessage({ id: `validate > invalid SSN` }),
      function() {
        const originalValue = get(this, "options.originalValue", {});
        const isRequired =
          originalValue.number1 &&
          originalValue.number2 &&
          originalValue.number3;
        if (!isRequired) return false;
        return validSSN(
          `${originalValue.number1}${originalValue.number2}${originalValue.number3}`
        );
      },
    ];
    if (validator[type]) {
      validator = validator[type](...params);
    }
  }
  if (key === "demographics") {
    let type = "shape";
    let params = [];
    if (get(config, "includes", []).includes("age_range")) {
      params.push({
        age_range: yup.object().shape({
          from: is_required
            ? yup
                .number()
                .typeError(
                  intl.formatMessage({ id: `validate > age from is required` })
                )
            : yup.string().nullable(),
          to: is_required
            ? yup
                .string()
                .nullable()
                .when("from", (from, schema) => {
                  return from
                    ? schema.test(
                        "to",
                        intl.formatMessage({
                          id: `validate > to should be greater than from`,
                        }),
                        (value) => {
                          return Number(from) <= Number(value) - 1;
                        }
                      )
                    : schema.min(0);
                })
                .when("from", (from, schema) => {
                  return from
                    ? schema.test(
                        "to",
                        intl.formatMessage({
                          id: `validate > age to is required`,
                        }),
                        (value) => {
                          return !!value;
                        }
                      )
                    : schema.min(0);
                })
            : yup.string().nullable(),
        }),
      });
    }
    if (get(config, "includes", []).includes("gender")) {
      params.push({
        gender: is_required
          ? yup.string().required(
              intl.formatMessage(
                { id: `validate > {field} is required` },
                {
                  field: upperFirst("gender"),
                }
              )
            )
          : yup.string().nullable(),
      });
    }
    if (validator[type]) {
      validator = validator[type](...params);
    }
  }
  if (get(config, "max_characters")) {
    let type = "max";
    const maxCharacters = get(config, "max_characters");
    let params = [
      maxCharacters,
      intl.formatMessage(
        { id: `validate > max {field} can be {max}` },
        {
          field: errorFieldName,
          max: maxCharacters,
        }
      ),
    ];
    if (validator[type]) {
      validator = validator[type](...params);
    }
  }

  schema[fieldName] = validator;
  return schema;
}
