import React from "react";
import Page from "components/Page";
import PrivilegedComponent from "components/PrivilegedComponent";
import WinnersList from "components/Winners/WinnersList";
import { Redirect } from "react-router";
import { PRIVILEGES } from "utils/constants";

const WinnersPage = (props) => {
  return (
    <PrivilegedComponent requires={[PRIVILEGES.VIEW_WINNERS_LIST]}>
      {({ isValid }) => {
        if (isValid) {
          return (
            <Page title="" applyPadding={false}>
              {props.stations.length > 0 && (
                <WinnersList stations={props.stations} />
              )}
            </Page>
          );
        } else {
          return <Redirect to="/" />;
        }
      }}
    </PrivilegedComponent>
  );
};

export default WinnersPage;
