import View from "./view";
import React from "react";
import { connect } from "react-redux";
import { compose, withHandlers, withState } from "recompose";
import { changePassword } from "store/actions/auth";
import { changePasswordUser } from "store/actions/users";
import { MdImportantDevices } from "react-icons/lib/md";
import ToastManager from "components/ToastManager";
import { injectIntl } from "react-intl";
import { forOwn, join, get } from "lodash";
export default injectIntl(
  compose(
    connect(
      ({ servers }) => ({
        currentServer: get(servers, "currentServer.data", {}),
      }),
      { changePassword, changePasswordUser }
    ),
    withState("isLoading", "setIsLoading", false),
    withHandlers({
      onSubmit: ({
        setIsLoading,
        changePassword,
        intl,
        onCancel,
        isAuthChangePassword,
        user,
        changePasswordUser,
      }) => (values, formikProps) => {
        setIsLoading(true);
        const callback = (res) => {
          setIsLoading(false);
          if (res.status) {
            ToastManager.show({
              title: <MdImportantDevices />,
              message: intl.formatMessage({
                id: `my profile > updated successfully`,
              }),
              autoDismiss: 1.5,
              level: "success",
            });
            onCancel();
          } else {
            let errors = {};
            forOwn(res.error, (value, key) => {
              errors[key] = join(value, ", ");
            });
            formikProps.setErrors(errors);
          }
        };
        if (isAuthChangePassword) {
          changePassword(values, (res) => callback(res));
        } else {
          changePasswordUser(
            {
              ...values,
              id: user.id,
            },
            (res) => callback(res)
          );
        }
      },
    })
  )(View)
);
