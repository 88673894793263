import React from "react";
import { Route } from "react-router-dom";

const LayoutRoute = ({
  component: Component,
  layout: Layout,
  render,
  ...rest
}) => (
  <Route
    {...rest}
    render={
      render
        ? props => <Layout {...rest.componentProps}>{render(props)}</Layout>
        : props => (
            <Layout {...rest.componentProps}>
              <Component {...props} />
            </Layout>
          )
    }
  />
);

export default LayoutRoute;
