import { get, remove, includes, filter  } from "lodash";
import { connect } from "react-redux";
import {
  compose,
  lifecycle,
  withState,
  withHandlers,
} from "recompose";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import { updateServer } from "store/actions/servers";
import ToastManager from "components/ToastManager";
import SmtpConfigurations from "./view";


export default injectIntl(
  compose(
    connect(
      (state) => {
        const serverId = get(state, "auth.info.server_id");
        return {
          serverId,
          server: get(state, "servers.currentServer.data", {}),
        };
      },
      {
        updateServer,
      }
    ),
    withHandlers({
      saveSmtpConfig: ({updateServer, serverId, intl }) => (values) => {
        updateServer(serverId, { ...values }, () => {
          ToastManager.show({
              title: intl.formatMessage({ id: "server > success" }),
              message: intl.formatMessage({
                id: `server > smtp save > success`,
              }),
              autoDismiss: 1.5,
              level: "success",
          });
        });
      },
    }),
  )(SmtpConfigurations)
);
