import React from "react";
import { get } from "lodash";
import { FaSpinner } from "react-icons/lib/fa";
import { Document, Page } from "react-pdf";

const MediaPdf = (props) => {
  const { bem, asset, intl, onClose } = props;
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [isLoaded, setIsLoaded] = React.useState(false);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const url = get(asset, "path");

  return (
    <div className={bem.e("asset-pdf-view")}>
      <div className={bem.e("pdf-container")}>
        <Document
          file={url}
          loading={
            <span className="loading">
              <FaSpinner size={20} className={"cr-icon-spin"} />
            </span>
          }
          onPassword={(callback, reason) => {
            function callbackProxy(password) {
              // Cancel button handler
              if (password === null) {
                setIsLoaded(false);
                onClose();
              }

              callback(password);
            }
            switch (reason) {
              case 1: {
                const password = prompt(
                  intl.formatMessage({
                    id: "preview file > prompt password",
                  })
                );
                callbackProxy(password);
                break;
              }
              case 2: {
                const password = prompt(
                  intl.formatMessage({
                    id: "preview file > prompt invalid password",
                  })
                );
                callbackProxy(password);
                break;
              }
              default:
            }
          }}
          onSourceSuccess={() => {
            setIsLoaded(true);
          }}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page
            pageNumber={pageNumber}
            width={516}
            renderAnnotationLayer={false}
          />
        </Document>
        {isLoaded && numPages && (
          <div className="page-controls">
            <button
              type="button"
              disabled={pageNumber === 1}
              onClick={() => {
                if (pageNumber > 1) setPageNumber(pageNumber - 1);
              }}
            >
              ‹
            </button>
            <span>
              {intl.formatMessage(
                { id: "preview file > page of total" },
                {
                  page: pageNumber,
                  total: numPages,
                }
              )}
            </span>
            <button
              type="button"
              disabled={pageNumber === numPages}
              onClick={() => {
                if (pageNumber < numPages) setPageNumber(pageNumber + 1);
              }}
            >
              ›
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
export default MediaPdf;
