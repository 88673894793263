import React from "react";
import classnames from "classnames";
import { get } from "lodash";
import TextInput from "components/TextInput";
import { FormattedMessage } from "react-intl";
import { Row, Col, Label, FormGroup } from "reactstrap";

export default function FulfillMailOut(props) {
    const { bem, isView } = props;
    if (isView) {
        const { prize } = props;
        return (
            <Row
                className={classnames(
                    bem.e("fulfill-mail-out"),
                    bem.e("fulfill-mail-out-view")
                )}
            >
                <Col className={bem.e("col-reset")} xs={12}>
                    <FormGroup>
                        <Label>
                            <FormattedMessage id="prizes > winner instructions" />
                        </Label>
                        <div className={bem.e("prize-value-view")}>
                            {get(prize, "winner_instructions") ? (
                                get(prize, "winner_instructions")
                            ) : (
                                <FormattedMessage id="prizes > none" />
                            )}
                        </div>
                    </FormGroup>
                </Col>
            </Row>
        );
    }
    const { values, errors, touched, intl } = props;
    return (
        <Row className={bem.e("fulfill-mail-out")}>
            <Col className={bem.e("col-reset")} xs={12}>
                <FormGroup>
                    <TextInput
                        label={<FormattedMessage id="prizes > winner instructions" />}
                        required
                        type="textarea"
                        name="winner_instructions"
                        placeholder={intl.formatMessage({
                            id: "prizes > enter text",
                        })}
                        aria-multiline="true"
                        value={values.winner_instructions}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        error={touched.winner_instructions && errors.winner_instructions}
                    />
                </FormGroup>
            </Col>
        </Row>
    );
}
