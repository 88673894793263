import React from "react";
import { FormGroup, Label } from "reactstrap";
import bn from "utils/bemnames";
import { FormattedMessage } from "react-intl";
import TextInput from "components/TextInput";
import { Asterisk } from "components/Elements";
import { CloseIcon } from "components/CustomIcons";
import { get } from "lodash";
import PropTypes from "prop-types";
const bem = bn.create("jock-console-item");
export default function RSSFeed(props) {
  const { isEdit, value, intl } = props;
  if (!isEdit) {
    return (
      <div className={bem.e("rss-feed-url")}>
        <FormGroup>
          <div className={bem.e("rss-feed-input")}>
            <div className={bem.e("jock-console-view-field")}>
              <Label>
                <FormattedMessage id="jock console manager > feed url" />
              </Label>
              <div className={bem.e("jock-console-view-field-value")}>
                <div className={bem.e("jock-console-view-field-value")}>
                  {value ? (
                    value
                  ) : (
                    <FormattedMessage id="jock console manager > none" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </FormGroup>
      </div>
    );
  }
  const { touched, errors } = props;
  const isShowDelete = !!value;
  return (
    <div className={bem.e("rss-feed-url")}>
      <FormGroup>
        <div className={bem.e("rss-feed-input")}>
          <TextInput
            label={
              <span>
                <FormattedMessage id="jock console manager > feed url" />
                <Asterisk>*</Asterisk>
              </span>
            }
            type="text"
            name={"fields.url"}
            placeholder={intl.formatMessage({
              id: "jock console manager > enter feed url",
            })}
            value={value}
            error={
              get(touched, "fields.url", false) && get(errors, "fields.url", "")
            }
            onChange={props.handleChange}
            onBlur={props.handleBlur}
          />
          {isShowDelete ? (
            <span
              className={bem.e("remove-icon-rss")}
              onClick={() => props.setFieldValue("fields.url", "")}
            >
              <CloseIcon />
            </span>
          ) : null}
        </div>
      </FormGroup>
    </div>
  );
}
RSSFeed.defaultProps = {
  isEdit: false,
};
RSSFeed.propTypes = {
  isEdit: PropTypes.bool,
};
