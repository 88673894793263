import React from "react";
import { MenuIcon } from "components/CustomIcons";
import SortableComponent from "components/SortableComponent";
import { FormattedMessage } from "react-intl";
import { get } from "lodash";
const PriorityItems = (props) => {
  const { bem, onChange } = props;
  if (get(props, "items.length", 0) === 0) return null;
  return (
    <div className={bem.e("priority-items")}>
      <div className={bem.e("priority-title")}>
        <FormattedMessage id="clock creator > priority of item slot type" />
      </div>
      <SortableComponent
        data={props.items}
        onSorted={onChange}
        lockToContainerEdges={false}
        renderItem={(item, index) => {
          return (
            <div className={bem.e("selection-item-container")} key={index}>
              <div className={bem.e("selection-item")}>
                <span className={bem.e("sort-number")}>{index + 1}.</span>
                <span className={"menu-selection-icon"}>
                  <MenuIcon />
                </span>
                <span className={bem.e("priority-label")}>{item}</span>
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};
export default PriorityItems;
