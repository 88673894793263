import React from "react";
import classnames from "classnames";
import bn from "utils/bemnames";
import { isImage } from "utils/helpers";
import { injectIntl } from "react-intl";
import { getExt } from "utils/helpers";
import {
  PdfIcon,
  DocIcon,
  WavIcon,
  Pm3Icon,
  DeleteIcon,
  DownloadFileIcon,
  ViewIcon,
  PptIcon,
  XlsIcon,
  TxtIcon,
  CsvIcon,
} from "components/CustomIcons";
import truncate from "truncate";
import { checkIsSupportViewer } from "utils/helpers";
import PreviewFileModal from "components/PreviewFileModal";
import Spinner from "components/Spinner";

const bem = bn.create("chat-attachment");
const MAX_FILE_NAME_LENGTH = 50;

const renderImage = (file) => {
  return (
    <div className={classnames(bem.e("image"))}>
      {/* `file.preview` when we drop the file, `file.path` when we are showing already uploaded file */}
      <img src={file.path} alt="" />
    </div>
  );
};

const renderAttachIcon = (type = "") => {
  switch (type) {
    case "pdf":
      return <PdfIcon />;
    case "doc":
      return <DocIcon />;
    case "docx":
      return <DocIcon />;
    case "wav":
      return <WavIcon />;
    case "mp3":
      return <Pm3Icon />;
    case "ppt":
      return <PptIcon />;
    case "pptx":
      return <PptIcon />;
    case "xls":
      return <XlsIcon />;
    case "xlsx":
      return <XlsIcon />;
    case "txt":
      return <TxtIcon />;
    case "csv":
      return <CsvIcon />;
    default:
      break;
  }
};

const Attachment = (props) => {
  const {
    onDelete,
    actions = [],
    onDownloadFile,
    onViewFile,
    isLoading,
  } = props;
  if (!props.file) return null;
  const file = props.file;

  let fileType = getExt(file.name);
  if (isImage(props.file.type)) {
    fileType = "image";
  }
  const fileName = file.name;
  const isSupportViewer = checkIsSupportViewer(file);
  return (
    <div className={bem.b()}>
      <div
        onClick={() => {
          if (actions.includes("view") && isSupportViewer) {
            onViewFile();
          }
        }}
        className={classnames({
          [bem.e("pointer")]: actions.includes("view") && isSupportViewer,
        })}
      >
        {fileType.toLowerCase() === "image"
          ? renderImage(props.file)
          : renderAttachIcon(fileType)}
      </div>
      <div className={bem.e("file-bottom")}>
        <div className={bem.e("file-name")}>
          {truncate(fileName, MAX_FILE_NAME_LENGTH)}
        </div>
        <div className={bem.e("file-buttons")}>
          {actions.includes("view") && isSupportViewer && (
            <span className={bem.e("file-view")} onClick={onViewFile}>
              <ViewIcon />
            </span>
          )}
          {actions.includes("download") && (
            <span className={bem.e("file-download")} onClick={onDownloadFile}>
              <DownloadFileIcon />
            </span>
          )}
          {actions.includes("trash") && (
            <span className={bem.e("file-trash")} onClick={onDelete}>
              <DeleteIcon />
            </span>
          )}
        </div>
      </div>
      <Spinner isLoading={isLoading} />
      <PreviewFileModal
        isOpen={props.isOpenPreviewFileModal}
        onToggle={props.onTogglePreviewModal}
        onCancel={props.onCancelPreviewModal}
        file={file}
        files={[file]}
        isLarge={true}
      />
    </div>
  );
};
export default injectIntl(Attachment);
