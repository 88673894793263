import * as Const from "utils/constants";
import Api from "utils/api";
import { get, omit, isFunction } from "lodash";
import {
  FETCH_CHANNELS_REQUEST,
  FETCH_CHANNELS_SUCCESS,
  FETCH_CHANNELS_ERROR,
  ADD_CHANNEL_REQUEST,
  ADD_CHANNEL_ERROR,
  ADD_CHANNEL_SUCCESS,
  UPDATE_CHANNEL_REQUEST,
  UPDATE_CHANNEL_SUCCESS,
  UPDATE_CHANNEL_ERROR,
  FORGET_CHANNELS,
  DELETE_CHANNEL_SUCCESS,
  DELETE_CHANNEL_REQUEST,
  DELETE_CHANNEL_ERROR,
  CLONE_CHANNEL_REQUEST,
  CLONE_CHANNEL_SUCCESS,
  CLONE_CHANNEL_ERROR,
  CANCEL_CHANNEL_REQUEST,
  CANCEL_CHANNEL_SUCCESS,
  CANCEL_CHANNEL_ERROR,
  UPDATE_ORDER_CHANNELS_ERROR,
  UPDATE_ORDER_CHANNELS_REQUEST,
  UPDATE_ORDER_CHANNELS_SUCCESS,
  UPDATE_CHANNELS_REQUEST,
  UPDATE_CHANNELS_SUCCESS,
  UPDATE_CHANNELS_ERROR,
  SET_ORDER_CHANNELS,
} from "store/types/channels";
export const updateOrderChannels = (payload) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: UPDATE_ORDER_CHANNELS_REQUEST, payload });
    const url = Const.UPDATE_ORDER_CHANNELS();
    let response = await Api.doCall(url, "POST", { data: payload });
    if (response.status === 200) {
      if (!response.data.error) {
        dispatch({
          type: UPDATE_ORDER_CHANNELS_SUCCESS,
        });
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: UPDATE_ORDER_CHANNELS_ERROR,
    payload: errorStr,
  });
};

export const getChannels = (payload) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: FETCH_CHANNELS_REQUEST, payload });
    const paramString = new URLSearchParams();
    if (payload.sort) {
      paramString.set("sort", payload.sort);
    } else {
      paramString.set("sort", `order asc`);
    }
    if (payload.search) {
      paramString.set("search", payload.search);
    }
    if (payload.page) {
      paramString.set("page", payload.page);
    }
    if (payload.station_id) {
      paramString.set("station_id", payload.station_id);
    }
    if (payload.list_channels) {
      paramString.set("list_channels", payload.list_channels);
    }
    paramString.set("rpp", payload.rpp ? payload.rpp : 999);
    const url = Const.GET_CHANNELS() + `?${paramString.toString()}`;
    let response = await Api.doCall(url, "GET");
    if (response.status === 200) {
      if (!response.data.error) {
        dispatch(
          fetchChannelsSuccess({
            ...omit(payload, ["page", "station_id"]),
            page: get(response, "data.page", 1),
            pages: get(response, "data.pages", 1),
            data: get(response, "data.items", []),
          })
        );
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch(fetchChannelsError({ error: errorStr }));
};
export const updateChannel = (id, data, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: UPDATE_CHANNEL_REQUEST });
    //api call for store data
    let response = await Api.doCall(Const.GET_CHANNEL(id), "PUT", data);
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        //response data
        let responseData = response.data;
        dispatch({
          type: UPDATE_CHANNEL_SUCCESS,
          payload: responseData,
        });
        if (isFunction(cb))
          cb({
            status: true,
            errors: null,
            data: responseData,
          });
        return;
      }
      //error data
      errorStr = response.data.error;
    }
  } catch (error) {
    if (isFunction(cb))
      cb({
        status: false,
        errors: error,
      });
    errorStr = error.toString();
  }
  dispatch({
    type: UPDATE_CHANNEL_ERROR,
    payload: {
      error: errorStr,
    },
  });
};

export const updateChannels = (data, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: UPDATE_CHANNELS_REQUEST });
    //api call for store data
    let response = await Api.doCall(Const.UPDATE_CHANNELS(), "POST", data);
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        //response data
        let responseData = response.data;
        dispatch({
          type: UPDATE_CHANNELS_SUCCESS,
          payload: responseData,
        });
        if (isFunction(cb))
          cb({
            status: true,
            errors: null,
          });
        return;
      }
      //error data
      errorStr = response.data.error;
    }
  } catch (error) {
    if (isFunction(cb))
      cb({
        status: false,
        errors: error,
      });
    errorStr = error.toString();
  }
  dispatch({
    type: UPDATE_CHANNELS_ERROR,
    payload: {
      error: errorStr,
    },
  });
};

export const cancelChannel = (id, cb) => async (dispatch) => {
  let errorStr = "";
  let errorArr = [];
  try {
    dispatch({ type: CANCEL_CHANNEL_REQUEST });
    //api call for store data
    let response = await Api.doCall(Const.CANCEL_CHANNEL(id), "GET");
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        //response data
        let responseData = response.data;
        dispatch({
          type: CANCEL_CHANNEL_SUCCESS,
          payload: responseData,
        });
        if (isFunction(cb))
          cb({
            status: true,
            errors: null,
          });
        return;
      }
      //error data
      errorStr = response.data.error;
    }
  } catch (error) {
    errorArr =
      typeof error === "object" ? JSON.parse(JSON.stringify(error)) : [];
    if (isFunction(cb))
      cb({
        status: false,
        errors: errorArr,
      });
    errorStr = error.toString();
  }
  dispatch({
    type: CANCEL_CHANNEL_ERROR,
    payload: {
      error: errorStr,
    },
  });
};

export const addChannel = (data, cb) => async (dispatch) => {
  let errorStr = "";
  let errorArr = [];
  try {
    dispatch({ type: ADD_CHANNEL_REQUEST });
    //api call for store data
    let response = await Api.doCall(Const.ADD_CHANNEL(), "POST", data);
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        //response data
        let responseData = response.data;
        dispatch({
          type: ADD_CHANNEL_SUCCESS,
          payload: responseData,
        });
        if (isFunction(cb))
          cb({
            status: true,
            errors: null,
            data: responseData,
          });
        return;
      }
      //error data
      errorStr = response.data.error;
    }
  } catch (error) {
    errorArr =
      typeof error === "object" ? JSON.parse(JSON.stringify(error)) : [];
    if (isFunction(cb))
      cb({
        status: false,
        errors: errorArr,
      });
    errorStr = error.toString();
  }
  dispatch({
    type: ADD_CHANNEL_ERROR,
    payload: {
      error: errorStr,
    },
  });
};

export const addChannelLocal = (data, cb) => async (dispatch) => {
  dispatch({
    type: ADD_CHANNEL_SUCCESS,
    payload: data,
  });
  if (isFunction(cb))
    cb({
      status: true,
      errors: null,
      data,
    });
  return;
};

export const cloneChannel = (data, fromChannelId, cb) => async (dispatch) => {
  let errorStr = "";
  let errorArr = [];
  try {
    dispatch({ type: CLONE_CHANNEL_REQUEST });
    //api call for store data
    let response = await Api.doCall(Const.ADD_CHANNEL(), "POST", data);
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        //response data
        let responseData = response.data;
        dispatch({
          type: CLONE_CHANNEL_SUCCESS,
          payload: {
            channel: {
              _source: responseData,
            },
            from_channel_id: fromChannelId,
          },
        });
        if (isFunction(cb))
          cb({
            status: true,
            errors: null,
          });
        return;
      }
      //error data
      errorStr = response.data.error;
    }
  } catch (error) {
    errorArr =
      typeof error === "object" ? JSON.parse(JSON.stringify(error)) : [];
    if (isFunction(cb))
      cb({
        status: false,
        errors: errorArr,
      });
    errorStr = error.toString();
  }
  dispatch({
    type: CLONE_CHANNEL_ERROR,
    payload: {
      error: errorStr,
    },
  });
};

export const deleteChannel = (id, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: DELETE_CHANNEL_REQUEST });
    let response = await Api.doCall(Const.GET_CHANNEL(id), "DELETE");
    if (response.status === 200) {
      if (!response.error) {
        dispatch({ type: DELETE_CHANNEL_SUCCESS, payload: { id } });
        if (cb) cb(response);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: DELETE_CHANNEL_ERROR, payload: { error: errorStr } });
};

export const deleteChannelLocal = (id, cb) => async (dispatch) => {
  dispatch({ type: DELETE_CHANNEL_SUCCESS, payload: { id } });
  if (cb) cb();
};

export function fetchChannelsSuccess(payload) {
  return {
    type: FETCH_CHANNELS_SUCCESS,
    payload,
  };
}
export function setOrderChannels(payload) {
  return {
    type: SET_ORDER_CHANNELS,
    payload,
  };
}
export function fetchChannelsError(payload) {
  return {
    type: FETCH_CHANNELS_ERROR,
    payload,
  };
}
export function forgetChannels() {
  return {
    type: FORGET_CHANNELS,
  };
}
