import View from "./view";
import axios from "axios";
import { compose, withHandlers, withState } from "recompose";
import { download, convertUrlHttps } from "components/UploadFile/helper";

export default compose(
  withState("isLoading", "setIsLoading", false),
  withState("isOpenPreviewFileModal", "setIsOpenPreviewFileModal", false),
  withHandlers({
    onCancelPreviewModal: ({ setIsOpenPreviewFileModal }) => () => {
      setIsOpenPreviewFileModal(false);
    },
    onTogglePreviewModal: ({
      isOpenPreviewFileModal,
      setIsOpenPreviewFileModal,
    }) => () => {
      setIsOpenPreviewFileModal(!isOpenPreviewFileModal);
    },
    onViewFile: ({ setIsOpenPreviewFileModal }) => () => {
      setIsOpenPreviewFileModal(true);
    },
    onDownloadFile: ({ setError, file, setIsLoading }) => async () => {
      const { path } = file;
      setIsLoading(true);
      axios({
        url: convertUrlHttps(path) + "?ts=" + (new Date() - 0),
        method: "GET",
        responseType: "blob",
      })
        .then((response) => {
          setIsLoading(false);
          const { data } = response;
          if (data) {
            const fileName = path.replace(/^.*[\\/]/, "");
            download(data, fileName);
          } else {
            setError(`Can't download`);
          }
        })
        .catch((e) => {
          const { message } = e;
          setError(message);
          setIsLoading(false);
        });
    },
  })
)(View);
