import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, Button } from "reactstrap";
import classnames from "classnames";
import bn from "utils/bemnames";
import { CloseIcon } from "components/CustomIcons";
import { FormattedMessage } from "react-intl";
import ServerForm from "components/ServerForm";
const bem = bn.create("broadcasting-modal");

const BroadcastingModal = ({ isOpen, onToggle }) => {
  let backButton = (
    <Button
      type="button"
      color="blue"
      outline
      className={classnames(bem.e("button-cancel"), "btn-radius")}
      onClick={onToggle}
    >
      <FormattedMessage id="server > button cancel" />
    </Button>
  );
  return (
    <Modal isOpen={isOpen} className={classnames(bem.b())}>
      <ModalBody className={bem.e("body")}>
        <div className={bem.e("close")} onClick={onToggle}>
          <CloseIcon />
        </div>
        <ServerForm isView={false} backButton={backButton} onBack={onToggle} />
      </ModalBody>
    </Modal>
  );
};

BroadcastingModal.propTypes = {
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
};

BroadcastingModal.defaultProps = {
  onToggle: () => {},
};

export default BroadcastingModal;
