import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody } from "reactstrap";
import AdminListManual from "./ListManual";
import bn from "utils/bemnames";
const bem = bn.create("list-users");
const AdminsList = (props) => {
  const {
    selectedUsersChanged,
    isFocused,
    onSelectedEdit,
    onSelectedView,
    onSelectedAdd,
    locationOptions,
  } = props;
  return (
    <>
      <div className={bem.b()}>
        <Card>
          <CardBody>
            <AdminListManual
              selectedUsersChanged={selectedUsersChanged}
              isFocused={isFocused}
              onSelectedView={onSelectedView}
              onSelectedEdit={onSelectedEdit}
              onSelectedAdd={onSelectedAdd}
              locationOptions={locationOptions}
            />
          </CardBody>
        </Card>
      </div>
    </>
  );
};
AdminsList.defaultProps = {
  onSelectedEdit: () => {},
  onSelectedView: () => {},
  onSelectedAdd: () => {},
};
AdminsList.propsType = {
  onSelectedEdit: PropTypes.func,
  onSelectedView: PropTypes.func,
  onSelectedAdd: PropTypes.func,
};
export default AdminsList;
