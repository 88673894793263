import View from "./view";
import { compose } from "recompose";
import { connect } from "react-redux";
import withState from "recompose/withState";
import { get } from "lodash";
import { syncWOOrders } from "store/actions/processes";
import { injectIntl } from "react-intl";
import { setSchedulingAvailability } from "store/actions/programmings";
import { setFetchFulfillmentSettings } from "store/actions/stations";
import { resetProcess } from "store/actions/processes";
export default injectIntl(
  compose(
    connect(
      (state) => ({
        auth: state.auth,
        isSidebarOpen: state.auth.is_sidebar_open,
        isEnableProduction: get(state, "auth.info.enable_production", false),
        isEnablePromotion: get(state, "auth.info.enable_promotion", false),
        isEnableDigital: get(state, "auth.info.enable_digital", false),
        isEnableEnterprise: get(
          state,
          "auth.info.production_multimarket",
          false
        ),
        isEnableProductionNeeded: !get(
          state,
          "auth.info.disabled_production_needed",
          false
        ),
        isEnableProductionProvided: !get(
          state,
          "auth.info.disabled_production_provided",
          false
        ),
        isEnableContesting: !get(state, "auth.info.disabled_contesting", false),
        isEnableClientManagement: !get(
          state,
          "auth.info.disabled_client_management",
          false
        ),
        isEnablePrizeCloset: !get(
          state,
          "auth.info.disabled_prize_closet",
          false
        ),
      }),
      {
        syncWOOrders,
        setSchedulingAvailability,
        setFetchFulfillmentSettings,
        resetProcess,
      }
    ),
    withState("isVisible", "setIsVisible", false),
    withState("isSyncProgressModalOpen", "setIsSyncProgressModalOpen", false),
    withState(
      "isOpenProductionPromptModal",
      "setIsOpenProductionPromptModal",
      false
    )
  )(View)
);
