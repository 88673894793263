import React from "react";
import { FaSortDesc, FaSortAsc, FaPlus } from "react-icons/lib/fa";
import classnames from "classnames";
import { get, find, filter, map } from "lodash";
import { Button } from "reactstrap";
import { FormattedMessage } from "react-intl";
import Table from "components/Table";
import bn from "utils/bemnames";
import HubSidebar from "../HubSidebar";
import ContentAssignStations from "../ContentAssignStations";
import PropTypes from "prop-types";

const bem = bn.create("hub-manager-details");
const filterTagsByType = (tags, type) =>
  filter(tags, (item) => item.type === type)
    .map((item) => item.title)
    .join(", ");
const HubManagerStations = (props) => {
  const {
    sorted,
    setSorted,
    getHubStations,
    assignedStations,
    selectedHub,
    intl,
    isOpenSidebar,
    setIsOpenSidebar,
    setSidebarType,
    sidebarType,
    isAllowEditStations,
  } = props;
  const items = map(get(assignedStations, "data", []), (item) => {
    const tags = get(item, "tags_json", []);
    return {
      ...item,
      market: filterTagsByType(tags, "dmas"),
    };
  });
  let columns = [];
  const sortedName = find(sorted, (item) => item.id === "name");
  columns.push({
    Header: (
      <div className={classnames(bem.e("th-name"), bem.e("th-sort"))}>
        <FormattedMessage id="hub manager > station name" />
        <div className={bem.e("sortable")}>
          <FaSortAsc
            className={classnames(bem.e("sortable-icon"), {
              [bem.e("sortable-active")]: sortedName && !sortedName.desc,
            })}
          />
          <FaSortDesc
            className={classnames(bem.e("sortable-icon"), {
              [bem.e("sortable-active")]: sortedName && sortedName.desc,
            })}
          />
        </div>
      </div>
    ),
    accessor: "name",
    Cell: ({ original }) => {
      const array = [];
      if (original.call_letters) {
        array.push(original.call_letters);
      }
      if (original.name) {
        array.push(original.name);
      }
      const name = array.join(" - ");
      return <div className={bem.e("name-column")}>{name}</div>;
    },
  });
  const sortedMarket = find(sorted, (item) => item.id === "market");
  columns.push({
    Header: (
      <div className={bem.e("th-sort")}>
        <FormattedMessage id="hub manager > market" />
        <div className={bem.e("sortable")}>
          <FaSortAsc
            className={classnames(bem.e("sortable-icon"), {
              [bem.e("sortable-active")]: sortedMarket && !sortedMarket.desc,
            })}
          />
          <FaSortDesc
            className={classnames(bem.e("sortable-icon"), {
              [bem.e("sortable-active")]: sortedMarket && sortedMarket.desc,
            })}
          />
        </div>
      </div>
    ),
    accessor: "market",
    Cell: ({ original }) => {
      return (
        <span className={classnames(bem.e("td-value"), bem.e("market-value"))}>
          {original.market || intl.formatMessage({ id: "process > none" })}
        </span>
      );
    },
  });
  const sortedFormat = find(sorted, (item) => item.id === "format_tag");

  columns.push({
    Header: (
      <div className={bem.e("th-sort")}>
        <FormattedMessage id="hub manager > format" />
        <div className={bem.e("sortable")}>
          <FaSortAsc
            className={classnames(bem.e("sortable-icon"), {
              [bem.e("sortable-active")]: sortedFormat && !sortedFormat.desc,
            })}
          />
          <FaSortDesc
            className={classnames(bem.e("sortable-icon"), {
              [bem.e("sortable-active")]: sortedFormat && sortedFormat.desc,
            })}
          />
        </div>
      </div>
    ),
    accessor: "format_tag",
    Cell: ({ original }) => {
      return (
        <span className={classnames(bem.e("td-value"), bem.e("market-value"))}>
          {original.format_tag || intl.formatMessage({ id: "process > none" })}
        </span>
      );
    },
  });

  return (
    <div className={bem.b()}>
      <div className={bem.e("header")}>
        <div className={bem.e("hub-name")}>{selectedHub.name}</div>
        {isAllowEditStations && (
          <Button
            outline
            color="blue"
            className={classnames("btn-radius", bem.e("btn-stations"))}
            onClick={() => {
              setSidebarType("hub-stations");
              setIsOpenSidebar(true);
            }}
          >
            <FaPlus color="#795AFA" size={20} />
            <FormattedMessage id="hub manager > edit stations" />
          </Button>
        )}
      </div>
      <Table
        className={bem.e("table")}
        manual
        data={items}
        showPagination={false}
        total={assignedStations.length}
        sorted={sorted}
        // Callbacks
        onSortedChange={(sorted) => {
          setSorted(sorted);
          getHubStations({ sorted });
        }}
        columns={columns}
      />
      <HubSidebar
        isOpen={isOpenSidebar}
        onCancel={() => setIsOpenSidebar(false)}
        selectedHub={selectedHub}
        type={sidebarType}
        isShowSubtitle={false}
        children={
          <ContentAssignStations
            onCancel={() => setIsOpenSidebar(false)}
            selectedHub={selectedHub}
          />
        }
      />
    </div>
  );
};
HubManagerStations.propTypes = {
  isAllowEditStations: PropTypes.bool,
};
HubManagerStations.propsType = {
  isAllowEditStations: true,
};

export default HubManagerStations;
