import React from "react";
import bn from "utils/bemnames";
import { MenuIcon } from "components/CustomIcons";
import classnames from "classnames";
import { FaClose } from "react-icons/lib/fa";
import { FormattedMessage } from "react-intl";
import { labelToNameField } from "utils/helpers";
import { FormGroup, Row, Col, Label } from "reactstrap";
import ToggleSwitch from "components/ToggleSwitch";
import TextInput from "components/TextInput";
import { get, filter } from "lodash";
import { Asterisk } from "components/Elements";
const bem = bn.create("form-builder-fields");
const StreetAddress = ({
  intl,
  isNew,
  isView,
  field,
  value,
  errors,
  touched,
  onChange,
  onDelete,
  allowDelete,
  isFormBuilder,
  highlightFields,
  disabled,
  isShowOffText,
}) => {
  // UI for form builder
  if (isFormBuilder) {
    if (isNew) {
      const isHighlightFormField =
        filter(
          highlightFields,
          (highlightFieldItem) =>
            highlightFieldItem.uuid === value.uuid &&
            highlightFieldItem.count_highlight > 0
        ).length > 0;
      return (
        <Row>
          <Col className="form-builder-field">
            <div className={bem.b()}>
              <div
                className={classnames(
                  bem.e("form-field-group"),
                  bem.e("form-field-group-line"),
                  {
                    [bem.e("form-field-group-highlight")]: isHighlightFormField,
                    [bem.e("form-field-group-disable")]:
                      highlightFields.length > 0 &&
                      !isHighlightFormField &&
                      value.is_controlled,
                  }
                )}
              >
                <Row>
                  <Col xs={9}>
                    <div className={bem.e("form-field-group-head")}>
                      <div
                        className={bem.e("form-field-group-head-single-left")}
                      >
                        <span className={bem.e("menu-icon")}>
                          <MenuIcon />
                        </span>
                        <div className={bem.e("input-label")}>
                          <TextInput
                            label={null}
                            name="label"
                            type="text"
                            placeholder={intl.formatMessage({
                              id: "form builder > label street address",
                            })}
                            disabled={disabled}
                            value={value.label}
                            onChange={({ target }) => {
                              onChange({
                                ...value,
                                label: target.value,
                              });
                            }}
                            error={get(errors, "label", "")}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xs={3}>
                    <ToggleSwitch
                      leftComponent={
                        <span
                          className={classnames({
                            [bem.e("switch-active")]: !get(
                              value,
                              "is_required",
                              false
                            ),
                          })}
                        >
                          {isShowOffText
                            ? intl.formatMessage({
                                id: "form builder > off",
                              })
                            : ""}
                        </span>
                      }
                      rightComponent={
                        <span
                          className={classnames({
                            [bem.e("switch-active")]: get(
                              value,
                              "is_required",
                              false
                            ),
                          })}
                        >
                          {intl.formatMessage({
                            id: "form builder > required",
                          })}
                        </span>
                      }
                      name="is_required"
                      switchProps={{
                        checked: !get(value, "is_required", false),
                        onChange: (checked) => {
                          onChange({
                            ...value,
                            is_required: !checked,
                          });
                        },
                        disabled: disabled,
                        offColor: "#795AFA",
                        onColor: "#E3ECF1",
                        uncheckedIcon: false,
                        checkedIcon: false,
                        handleDiameter: 22,
                        width: 50,
                        height: 26,
                      }}
                    />
                  </Col>
                </Row>
                {allowDelete && (
                  <div
                    className={classnames(
                      bem.e("form-field-group-head-right"),
                      bem.e("form-field-group-delete-icon")
                    )}
                    onClick={onDelete}
                  >
                    <FaClose size="23" color="#C2D4E0" />
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row>
          <Col xs={6}>
            <div className={bem.b()}>
              <div className={bem.e("form-field-group-saved")}>
                <Label className={bem.e("form-field-label")}>
                  <span>
                    {get(value, "label", "")}{" "}
                    {get(value, "is_required", false) && <Asterisk>*</Asterisk>}
                  </span>
                </Label>
                <Row>
                  <Col className={bem.e("reset-margin")}>
                    <FormGroup>
                      <TextInput
                        label={null}
                        name={""}
                        type="text"
                        placeholder={intl.formatMessage({
                          id: "form builder > placeholder address line 1",
                        })}
                        value={""}
                        onChange={() => {}}
                      />
                    </FormGroup>
                    <FormGroup>
                      <TextInput
                        label={null}
                        name={""}
                        type="text"
                        placeholder={intl.formatMessage({
                          id: "form builder > placeholder address line 2",
                        })}
                        value={""}
                        onChange={() => {}}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <TextInput
                      label={null}
                      name={""}
                      type="text"
                      placeholder={intl.formatMessage({
                        id: "form builder > placeholder state",
                      })}
                      value={""}
                      onChange={() => {}}
                    />
                  </Col>
                  <Col xs={6}>
                    <TextInput
                      label={null}
                      name={""}
                      type="text"
                      placeholder={intl.formatMessage({
                        id: "form builder > placeholder city",
                      })}
                      value={""}
                      onChange={() => {}}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <TextInput
                      label={null}
                      name={""}
                      type="text"
                      placeholder={intl.formatMessage({
                        id: "form builder > placeholder zip/postal code",
                      })}
                      value={""}
                      onChange={() => {}}
                    />
                  </Col>
                </Row>
                {allowDelete && (
                  <div
                    className={bem.e("form-field-group-saved-button-close")}
                    onClick={onDelete}
                  >
                    <FaClose size="23" color="#C2D4E0" />
                  </div>
                )}
              </div>
            </div>
          </Col>
          <Col xs={6} />
        </Row>
      );
    }
    // ui for form
  } else {
    const fieldName = labelToNameField(get(field, "label", ""));
    if (isView) {
      const isEmpty =
        !get(value, "address_1") &&
        !get(value, "city") &&
        !get(value, "state_abb") &&
        !get(value, "zipcode");
      return (
        <Row>
          <Col xs={12}>
            <div className={bem.b()}>
              <div className={bem.e("form-field-group-saved")}>
                <Label className={bem.e("form-field-label")}>
                  <span>{get(field, "label", "")}</span>
                </Label>
                {!isEmpty ? (
                  <Row>
                    <Col xs={12}>
                      <span className="order-view-value">
                        {get(value, "address_1")}, {` `}
                        {get(value, "address_2")}
                      </span>
                    </Col>
                    <Col xs={12}>
                      <span className="order-view-value">
                        {get(value, "city")}, {get(value, "state_abb")},{" "}
                        {get(value, "zipcode")}
                      </span>
                    </Col>
                  </Row>
                ) : (
                  <span className="order-view-value">
                    <FormattedMessage id="form builder > none" />
                  </span>
                )}
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    return (
      <Row>
        <Col xs={12}>
          <div className={bem.b()}>
            <div className={bem.e("form-field-group-saved")}>
              <Label className={bem.e("form-field-label")}>
                <span>
                  {get(field, "label", "")}{" "}
                  {get(field, "is_required", false) && <Asterisk>*</Asterisk>}
                </span>
              </Label>
              <Row>
                <Col xs={12}>
                  <FormGroup>
                    <TextInput
                      label={null}
                      name={""}
                      type="text"
                      placeholder={intl.formatMessage({
                        id: "form builder > placeholder address line 1",
                      })}
                      value={get(value, "address_1", "")}
                      onChange={({ target }) => {
                        onChange({
                          ...value,
                          address_1: target.value,
                        });
                      }}
                      disabled={disabled}
                      error={
                        get(touched, fieldName, false) &&
                        get(errors, `${fieldName}.address_1`, "")
                          ? get(errors, `${fieldName}.address_1`, "")
                          : ""
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12}>
                  <FormGroup>
                    <TextInput
                      label={null}
                      name={""}
                      type="text"
                      placeholder={intl.formatMessage({
                        id: "form builder > placeholder address line 2",
                      })}
                      value={get(value, "address_2", "")}
                      onChange={({ target }) => {
                        onChange({
                          ...value,
                          address_2: target.value,
                        });
                      }}
                      disabled={disabled}
                      error={
                        get(touched, fieldName, false) &&
                        get(errors, `${fieldName}.address_2`, "")
                          ? get(errors, `${fieldName}.address_2`, "")
                          : ""
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <FormGroup>
                    <TextInput
                      label={null}
                      name={""}
                      type="text"
                      placeholder={intl.formatMessage({
                        id: "form builder > placeholder state",
                      })}
                      value={get(value, "state_abb", "")}
                      onChange={({ target }) => {
                        onChange({
                          ...value,
                          state_abb: target.value,
                        });
                      }}
                      disabled={disabled}
                      error={
                        get(touched, fieldName, false) &&
                        get(errors, `${fieldName}.state_abb`, "")
                          ? get(errors, `${fieldName}.state_abb`, "")
                          : ""
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <TextInput
                      label={null}
                      name={""}
                      type="text"
                      placeholder={intl.formatMessage({
                        id: "form builder > placeholder city",
                      })}
                      value={get(value, "city", "")}
                      onChange={({ target }) => {
                        onChange({
                          ...value,
                          city: target.value,
                        });
                      }}
                      disabled={disabled}
                      error={
                        get(touched, fieldName, false) &&
                        get(errors, `${fieldName}.city`, "")
                          ? get(errors, `${fieldName}.city`, "")
                          : ""
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className={bem.e("reset-margin")}>
                <Col xs={6}>
                  <FormGroup>
                    <TextInput
                      label={null}
                      name={""}
                      type="number"
                      placeholder={intl.formatMessage({
                        id: "form builder > placeholder zip/postal code",
                      })}
                      disabled={disabled}
                      value={get(value, "zipcode", "")}
                      onChange={({ target }) =>
                        onChange({ ...value, zipcode: target.value })
                      }
                      error={
                        get(touched, fieldName, false) &&
                        get(errors, `${fieldName}.zipcode`, "")
                          ? get(errors, `${fieldName}.zipcode`, "")
                          : ""
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
};
StreetAddress.defaultProps = {
  isNew: false,
  isFormBuilder: true,
  errors: {},
  touched: {},
  onChange: () => {},
  onDelete: () => {},
  allowDelete: true,
  disabled: false,
  isShowOffText: true,
};
export default StreetAddress;
