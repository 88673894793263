import { get, includes } from "lodash";
import { LOGOUT_USER } from "../types/auth";
import * as Types from "../types/audits";
import { pageSize } from "../../utils/config";

const initialState = {
  audits: {
    data: [],
    error: null,
    loading: false
  },
  audit: {
    data: {},
    error: null,
    loading: null
  },
  addAudits: {
    is_added: false,
    data: null,
    error: null,
    loading: false
  },
  updateAudits: {
    data: null,
    error: null,
    loading: false
  },
  deleteAudits: {
    data: {},
    error: null,
    loading: false
  },
  autoRefresh: true
};

export default function(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_AUDITS_REQUEST: {
      return {
        ...state,
        audits: {
          ...state.audits,
          ["filter_" + action.payload.filter]: {
            data: state.audits["filter_" + action.payload.filter]
              ? state.audits["filter_" + action.payload.filter].data
              : null,
            pages: state.audits["filter_" + action.payload.filter]
              ? state.audits["filter_" + action.payload.filter].pages
              : null,
            page: state.audits["filter_" + action.payload.filter]
              ? state.audits["filter_" + action.payload.filter].page
              : 1,
            rpp: state.audits["filter_" + action.payload.filter]
              ? state.audits["filter_" + action.payload.filter].rpp
              : pageSize,
            category: state.audits["filter_" + action.payload.filter]
              ? state.audits["filter_" + action.payload.filter].category
              : "",
            error: null,
            loading: true
          }
        }
      };
    }
    case Types.FETCH_AUDITS_SUCCESS: {
      return {
        ...state,
        audit: {
          data: get(action, "payload.data", {}),
          error: null,
          loading: null
          //   lastEditTab: [],
          //   activeTab: null,
        },
        audits: {
          ...state.audits,
          ["filter_" + action.payload.filter]: {
            ...action.payload,
            error: null,
            loading: false
          }
        }
      };
    }
    case Types.FETCH_AUDITS_ERROR: {
      return {
        ...state,
        audits: {
          ...state.audits,
          ["filter_" + action.payload.filter]: {
            data: [],
            error: action.error,
            loading: false
          }
        }
      };
    }
    
    // set auto refresh
    case Types.SET_AUDITS_AUTO_REFRESH: 
    case Types.FORGET_AUDITS: {
      const refrsh = action.type === Types.SET_AUDITS_AUTO_REFRESH;
      if(refrsh){
      
        // just setting auto refresh to false
      
        if(action.payload.state === false)
        return {
          ...state,
          autoRefresh: action.payload.state
        }
      }
    
      // either auto refresh true 
      // or forget causes forgetting

      else if(!state.autoRefresh) return state;
      const keys = get(action, "payload.keys");
      let new_audits = { ...state.audits };
      Object.keys(new_audits).map(k => {
        if (keys) {
          // only dirty with keys from payload. Avoid affect other list
          if (k.indexOf("filter_") === 0 && includes(keys, k)) {
            new_audits[k].is_dirty = true;
          }
        } 
        else if (k.indexOf("filter_") === 0) {
          new_audits[k].is_dirty = true;
        }
        return true;
      });
      return {
        ...state,
        audits: new_audits,
        autoRefresh: refrsh ? action.payload.state : state.autoRefresh
      };
    }

    case Types.INIT_NEW_AUDIT:
      return {
        ...state,
        audit: action.payload
      };

    // Add Audit Actions
    case Types.ADD_AUDIT_REQUEST: {
      return {
        ...state,
        addAudits: {
          is_added: false,
          loading: true,
          error: null
        }
      };
    }
    case Types.ADD_AUDIT_SUCCESS: {
      return {
        ...state,
        audit: {
          ...get(state, "audit"),
          data: {
            ...get(state, "audit.data"),
            data: get(state, "audit.data.data")
          }
        },
        addAudits: {
          is_added: true,
          data: action.payload,
          loading: false,
          error: null
        }
      };
    }
    case Types.ADD_AUDIT_ERROR: {
      return {
        ...state,
        addAudits: {
          is_added: false,
          loading: false,
          error: action.payload
        }
      };
    }

    // Update Audits Actions
    case Types.UPDATE_AUDIT_REQUEST: {
      return {
        ...state,
        updateAudits: {
          is_updated: false,
          loading: true,
          error: null
        }
      };
    }
    case Types.UPDATE_AUDIT_SUCCESS: {
      return {
        ...state,
        updateAudits: {
          is_updated: true,
          data: action.payload,
          loading: false,
          error: null
        }
      };
    }
    case Types.UPDATE_AUDIT_ERROR: {
      return {
        ...state,
        updateAudits: {
          is_updated: false,
          loading: false,
          error: action.payload
        }
      };
    }

    // Particular Audit Actions
    case Types.FETCH_AUDIT_REQUEST: {
      const existingAudit = get(state, "audit");
      const { id } = action.payload;
      return {
        ...state,
        audit: {
          ...existingAudit,
          ...(existingAudit[id] || {}),
          loading: true,
          error: null
        }
      };
    }
    case Types.FETCH_AUDIT_SUCCESS: {
      const existingAudit = get(state, "audit");
      const { data } = action.payload;
      return {
        ...state,
        audit: {
          ...existingAudit,
          loading: false,
          error: null,
          data
        }
      };
    }
    case Types.FETCH_AUDIT_ERROR: {
      const existingAudit = get(state, "audit");
      const { error } = action.payload;
      return {
        ...state,
        audit: {
          ...existingAudit,
          loading: false,
          error
        }
      };
    }

    // delete audits Actions
    case Types.DELETE_AUDIT_REQUEST: {
      return {
        ...state,
        updateAudits: {
          is_updated: false,
          loading: true,
          error: null
        }
      };
    }
    case Types.DELETE_AUDIT_SUCCESS: {
      return {
        ...state,
        audits: {
          ...state.audits,
          filter_audits_list: {
            ...state.audits.filter_audits_list,
            data: state.audits.filter_audits_list.data.filter(
              audit => audit._id !== action.payload
            )
          }
        },
        updateAudits: {
          is_updated: true,
          data: action.payload,
          loading: false,
          error: null
        }
      };
    }
    case Types.DELETE_AUDIT_ERROR: {
      return {
        ...state,
        updateAudits: {
          is_updated: false,
          loading: false,
          error: action.payload
        }
      };
    }
    
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
}
