import React, { useEffect, useState } from "react";
import bn from "utils/bemnames";
import { includes, orderBy, get, filter } from "lodash";
import moment from "moment";

import AudienceDetails from "../Common/AudienceDetails";
import ContestDescription from "../Common/ContestDescription";
import ContestScript from "../Common/ContestScript";
import ContestsQuickView from "../Common/ContestsQuickView";
import ContestSections from "../Common/ContestSections";
import ContestQualifying from "../Common/ContestQualifying";
import GrandPrizeGiveaway from "../Common/GrandPrizeGiveaway";
import Buttons from "../Common/Buttons";
import classNames from "classnames";
const bem = bn.create("promotion-contest-details");

export default function PromotionContestDetails(props) {
  const [fieldRefs, setFieldRefs] = useState(null);
  const {
    step,
    process,
    template,
    user,
    auth,
    validationState,
    valueChanged,
    intl,
    openDetails,
    setOpenDetails,
    onUpdateOrder,
    onGetClocksData,
    validate,
    clocksData,
    setPromotionsContestJockIntroductionsText,
  } = props;
  const isView = props.mode === "view";
  const stations = filter(auth.info.stations, (station) => station.station_key);
  useEffect(() => {
    let newIsOpenDetails = openDetails;
    // open sections for show error message.
    if (
      get(validationState, "desired_items_scheduling.validation_error") ||
      get(validationState, "total_available_items.validation_error")
    ) {
      newIsOpenDetails = {
        ...newIsOpenDetails,
        schedule: true,
      };
    }
    if (get(validationState, "associated_items_ids.validation_error")) {
      newIsOpenDetails = {
        ...newIsOpenDetails,
        associated: true,
      };
    }
    if (
      get(validationState, "rule_deadline.validation_error") ||
      get(validationState, "rule_number_of_days.validation_error")
    ) {
      newIsOpenDetails = {
        ...newIsOpenDetails,
        rules: true,
      };
    }
    setOpenDetails(newIsOpenDetails);
  }, [validationState]);
  const getFieldRefs = () => {
    if (fieldRefs) return fieldRefs;
    const select_stations = step.fields.find(
      (f) => f.field === "order_stations"
    );
    const timezone = step.fields.find((f) => f.field === "timezone");

    const payload = {
      select_stations,
      timezone,
    };

    setFieldRefs(payload);

    return payload;
  };

  const onValueChanged = (
    field,
    value,
    restProps = {
      shouldBlockNavigation: true,
    }
  ) => {
    const ignoreFields = [];
    if (
      !includes(ignoreFields, field.field) &&
      restProps.shouldBlockNavigation
    ) {
      props.setShouldBlockNavigation(restProps.shouldBlockNavigation);
      props.setIsShowClearForm(restProps.shouldBlockNavigation);
    }
    valueChanged(field, process, template, user, { value });
  };

  const onValueTimezoneChanged = (orderStations) => {
    const { timezone } = getFieldRefs();
    let timezoneValue;
    if (orderStations.length === 1) {
      const selectStation = orderStations[0];
      const stationWithData = stations.find(
        (item) => item.station_key === selectStation
      );
      if (stationWithData) {
        timezoneValue = stationWithData.timezone;
      }
    } else {
      let listTimezone = [];
      stations.forEach((item) => {
        if (
          includes(orderStations, item.station_key) &&
          item.timezone &&
          !includes(listTimezone, item.timezone)
        ) {
          const gmt = moment.tz(item.timezone).format("Z");
          listTimezone.push({
            key: parseInt(gmt),
            gmt: gmt,
            timezone: item.timezone,
          });
        }
      });
      listTimezone = orderBy(listTimezone, ["key"], ["desc"]);
      timezoneValue = get(listTimezone, `0.timezone`);
    }
    if (!timezoneValue) {
      timezoneValue = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    onValueChanged(timezone, timezoneValue, {
      shouldBlockNavigation: false,
    });
  };
  const selectedTheme = get(process, "data.fields.theme.value", "");
  const processKey = get(process, "data.fields.key.value", false);
  const isShowQualifying = selectedTheme === "qualifying_with_grand_prize";
  const orderEndDate = get(process, "data.fields.order_end_date.value", "");
  let sections = get(props, "auth.order_sections_contest", []);
  if (orderEndDate && moment(orderEndDate).isBefore(moment(), "day")) {
    sections = filter(sections, (item) => item !== "schedule");
  }
  return (
    <div
      className={classNames(bem.b(), {
        [bem.m("edit")]: !isView,
        [bem.m("view")]: isView,
      })}
    >
      <AudienceDetails
        bem={bem}
        process={process}
        template={template}
        user={user}
        auth={auth}
        step={step}
        intl={intl}
        stations={stations}
        isView={isView}
        onValueTimezoneChanged={onValueTimezoneChanged}
        validationState={validationState}
        onValueChanged={onValueChanged}
        shouldBlockNavigation={props.shouldBlockNavigation}
        onGetClocksData={onGetClocksData}
        onResetFormData={props.onResetFormData}
        onGetFulfillmentSettings={props.onGetFulfillmentSettings}
      />
      <ContestsQuickView
        bem={bem}
        process={process}
        template={template}
        user={user}
        auth={auth}
        step={step}
        intl={intl}
        isView={isView}
        validationState={validationState}
        onValueChanged={onValueChanged}
      />
      <ContestDescription
        bem={bem}
        process={process}
        template={template}
        user={user}
        auth={auth}
        step={step}
        intl={intl}
        isView={isView}
        validationState={validationState}
        onValueChanged={onValueChanged}
      />

      {isShowQualifying ? (
        <React.Fragment>
          <ContestQualifying
            bem={bem}
            process={process}
            template={template}
            user={user}
            auth={auth}
            step={step}
            intl={intl}
            isView={isView}
            validationState={validationState}
            onValueChanged={onValueChanged}
            openDetails={openDetails}
            setOpenDetails={setOpenDetails}
          />
          <GrandPrizeGiveaway
            bem={bem}
            process={process}
            template={template}
            user={user}
            auth={auth}
            step={step}
            intl={intl}
            isView={isView}
            validationState={validationState}
            onValueChanged={onValueChanged}
            openDetails={openDetails}
            setOpenDetails={setOpenDetails}
            clocksData={clocksData}
          />
        </React.Fragment>
      ) : (
        <ContestScript
          bem={bem}
          process={process}
          template={template}
          user={user}
          auth={auth}
          step={step}
          intl={intl}
          isView={isView}
          validationState={validationState}
          onValueChanged={onValueChanged}
          openDetails={openDetails}
          setOpenDetails={setOpenDetails}
          setDefaultJockIntroductionsText={
            setPromotionsContestJockIntroductionsText
          }
        />
      )}

      <ContestSections
        bem={bem}
        process={process}
        template={template}
        user={user}
        auth={auth}
        step={step}
        intl={intl}
        isView={isView}
        validationState={validationState}
        buttonClicked={props.buttonClicked}
        onValueChanged={onValueChanged}
        openDetails={openDetails}
        setOpenDetails={setOpenDetails}
        sections={sections}
        onUpdateOrder={onUpdateOrder}
      />
      <Buttons
        buttonClicked={props.buttonClicked}
        step={step}
        process={process}
        template={template}
        user={user}
        isView={isView}
        bem={bem}
        validate={validate}
        onValueChanged={onValueChanged}
        isShowClearForm={props.isShowClearForm && !isView}
        setIsShowClearForm={props.setIsShowClearForm}
        onClearForm={props.onClearForm}
        isShowDeleteButton={!!processKey && !isView}
        isShowPublishButton={false}
        setValidationError={props.setValidationError}
      />
    </div>
  );
}
