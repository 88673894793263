import { connect } from "react-redux";
import { compose, lifecycle, withHandlers, withState } from "recompose";
import { withRouter } from "react-router-dom";
import {
  setActiveTab,
  setActiveEditTab,
  saveFormValues,
  notifyEditingProcess,
} from "store/actions/processes";
import Tabs from "./tabsView";
import { get } from "lodash";
import { releaseReservedSlots } from "store/actions/programmings";

const Enhanced = compose(
  connect(
    (state, props) => ({
      userInfo: state.auth.info,
      user: state.auth.user,
      process: props.process,
      template: props.template,
      messages: state.messages.messages,
      editMode: props.editMode,
      isSidebarOpen: get(state, "auth.is_sidebar_open", false),
      isScheduleAvailabilityChecked: get(
        state,
        "programmings.scheduleAvailability.isChecked",
        false
      ),
    }),
    {
      setActiveTab,
      setActiveEditTab,
      saveFormValues,
      releaseReservedSlots,
      notifyEditingProcess,
    }
  ),
  withState("blockNavigationAction", "setBlockNavigationAction", "unsaved"),
  withState("shouldBlockNavigation", "setShouldBlockNavigation", false),
  withState("confirmationDialogProps", "setConfirmationDialogProps", null),
  withState("tabWidth", "setTabWidth", null),
  withHandlers({
    onReleaseReservedSlots: ({
      process,
      template,
      releaseReservedSlots,
      isScheduleAvailabilityChecked,
      setIsReminderModalOpen,
    }) => (cb) => {
      setIsReminderModalOpen(false);
      const processKey = get(process, "data.fields.key.value");
      if (
        ["liners", "contest"].indexOf(get(template, "key")) &&
        isScheduleAvailabilityChecked
      ) {
        const orderStation = get(process, "data.fields.order_stations.value.0");
        releaseReservedSlots({
          data: {
            station_id: orderStation,
            process_key: processKey,
          },
          cb,
        });
      }
    },
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      // get tab width
      const elementNav = document.querySelector("#tabs-sticky-nav");
      const navWidth = elementNav ? elementNav.clientWidth : null;
      if (this.props.tabWidth !== navWidth) {
        this.props.setTabWidth(navWidth);
      }
      // when user is editing any field -> send notify to BE. https://tasks.getventive.com/projects/9FF98-4F9?bug=54803-B24E
      /*const processKey = get(this.props.process, "data.fields.key.value");
      const templateKey = get(
        this.props.process,
        "data.fields.template_key.value"
      );
      if (
        this.props.shouldBlockNavigation &&
        !prevProps.shouldBlockNavigation &&
        ["basic_production", "production_provided"].indexOf(templateKey) !== -1
      ) {
        this.props.notifyEditingProcess({
          id: processKey,
          status: "started",
        });
      }*/
      const element = document.querySelector("#tabs-sticky");
      const body = document.querySelector("body");
      // To check when element get's position sticky
      const observer = new IntersectionObserver(
        function(entries) {
          // No intersection
          if (
            entries[0].intersectionRatio === 0 &&
            element &&
            element.classList
          ) {
            element.classList.add("tabs-sticky");
            body.classList.add("body-tabs-sticky");
          }
          // Fully intersects
          else if (
            entries[0].intersectionRatio === 1 &&
            element &&
            element.classList
          ) {
            element.classList.remove("tabs-sticky");
            body.classList.remove("body-tabs-sticky");
          }
        },
        {
          threshold: [0, 1],
        }
      );
      if (document.querySelector("#tabs-container-bottom")) {
        observer.observe(document.querySelector("#tabs-container-bottom"));
      }
    },
    /*componentWillUnmount() {
      const processKey = get(this.props.process, "data.fields.key.value");
      const templateKey = get(
        this.props.process,
        "data.fields.template_key.value"
      );
      if (
        this.props.shouldBlockNavigation &&
        ["basic_production", "production_provided"].indexOf(templateKey) !== -1
      ) {
        // https://tasks.getventive.com/projects/9FF98-4F9?bug=54803-B24E
        this.props.notifyEditingProcess({
          id: processKey,
          status: "ended",
        });
      }
    },*/
  })
)(Tabs);
export default withRouter(Enhanced);
