import React from "react";
import {
  Button,
  Row,
  Col,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { FormattedMessage } from "react-intl";
import { Formik } from "formik";
import classnames from "classnames";
import { values as getValues, isEqual, filter } from "lodash";
import StationSelectors from "components/StationsPicker/StationSelectors";
import AddedStationsList from "./AddedStationsList";
import { ArrowRightIcon } from "components/CustomIcons";
import ButtonCircle from "components/ButtonCircle";
import ConfirmationModal from "../ConfirmationModal";
import bn from "utils/bemnames";
import { STATION, TEAM } from "utils/constants";
const bem = bn.create("create-team-view");
const renderForm = (props) => {
  const {
    closeModal,
    allStationsAndTeams,
    selectedTags,
    setSelectedTags,
    updateSelectedTags,
    selectedStations,
    setSelectedStations,
    updateSelectedStations,
    addedStations,
    setAddedStations,
    isLoading,
    onSaveEntities,
    isEntitiesSaved,
    setIsEntitiesSaved,
  } = props;
  let stations = filter(
    allStationsAndTeams,
    (station) => station.type === STATION
  );
  let teams = filter(allStationsAndTeams, (station) => station.type === TEAM);

  const tabs = {
    market: {
      allowStationsSelect: true,
      tabId: "market",
    },
    search: {
      tabId: "search",
    },
  };

  return (
    <div className="entities-pickert-body">
      <div className={bem.b()}>
        <Formik onSubmit={onSaveEntities}>
          {(formProps) => {
            return (
              <>
                <div className={bem.e("form-container")}>
                  <Form onSubmit={formProps.handleSubmit}>
                    <div className={classnames(bem.e("section-container"))}>
                      <div className={bem.e("content-view")}>
                        <Row>
                          <Col>
                            <div className="info">
                              <FormattedMessage id="entities picker > module info" />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6} md={6} className={bem.e("col1")}>
                            <div>
                              <StationSelectors
                                bem={bem}
                                tabs={tabs}
                                stations={stations}
                                teams={teams}
                                selectedTags={selectedTags}
                                setSelectedTags={setSelectedTags}
                                updateSelectedTags={updateSelectedTags}
                                selectedStations={selectedStations}
                                setSelectedStations={setSelectedStations}
                                updateSelectedStations={updateSelectedStations}
                              />
                            </div>
                          </Col>

                          <Col xs={6} md={6} className={bem.e("col2")}>
                            <div className="add-stations-btn">
                              <ButtonCircle
                                width="52px"
                                height="52px"
                                background="#795AFA"
                                disabled={isEqual(
                                  selectedStations,
                                  addedStations
                                )}
                                boxShadow="0px 0px 10px rgba(0, 0, 0, 0.102)"
                                onClick={(e) =>
                                  setAddedStations({
                                    ...addedStations,
                                    ...selectedStations,
                                  })
                                }
                              >
                                <ArrowRightIcon
                                  color="#fff"
                                  width="14px"
                                  height="16px"
                                />
                              </ButtonCircle>
                            </div>
                            <div className={bem.e("stations-container")}>
                              <div className="table-container">
                                <AddedStationsList
                                  addedStations={addedStations}
                                  setAddedStations={setAddedStations}
                                  isView={false}
                                  showHeader={true}
                                  showTrashIcon={true}
                                />
                              </div>
                              <div className="buttons-container">
                                <div className={bem.e("form-buttons")}>
                                  <Button
                                    color="blue"
                                    type="submit"
                                    className="btn btn-radius"
                                    disabled={isLoading}
                                    onClick={formProps.handleSubmit}
                                  >
                                    <FormattedMessage
                                      id={`entities picker > module button ${
                                        !isLoading ? "submit" : "submitting"
                                      }`}
                                    />
                                  </Button>
                                  <Button
                                    type="button"
                                    outline
                                    disabled={
                                      getValues(addedStations).length === 0
                                    }
                                    className={classnames(
                                      bem.e("button-clear"),
                                      "btn-outline-blue btn-radius"
                                    )}
                                    onClick={() => setAddedStations({})}
                                  >
                                    <FormattedMessage id="teams > button clear" />
                                  </Button>
                                  <Button
                                    type="button"
                                    outline
                                    className={classnames(
                                      bem.e("button-cancel"),
                                      "btn-outline-blue btn-radius"
                                    )}
                                    onClick={() => closeModal()}
                                  >
                                    <FormattedMessage id="teams > button cancel" />
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Form>
                </div>
              </>
            );
          }}
        </Formik>
      </div>
      {isEntitiesSaved && (
        <ConfirmationModal
          isOpen={isEntitiesSaved}
          onToggle={() => setIsEntitiesSaved(false)}
          onCancel={() => isEntitiesSaved(false)}
          state="success"
          title={
            <FormattedMessage id={`entities pickert > saved successfully`} />
          }
        />
      )}
    </div>
  );
};
const ModuleEntitiesAssignmentModal = (props) => {
  const { isOpen, closeModal, isLoading } = props;
  return (
    <Modal
      isOpen={isOpen}
      // no auto closing on esc or out click
      // toggle={closeModal}
      className={bem.b()}
      backdropClassName="entities-picker"
    >
      <ModalHeader toggle={closeModal}>
        <FormattedMessage id="entities picker > title" />
      </ModalHeader>
      <ModalBody className="entities-picker">
        {!isLoading && renderForm(props)}
      </ModalBody>
    </Modal>
  );
};

export default ModuleEntitiesAssignmentModal;
