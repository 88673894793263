import { Content, Header, Sidebar } from "../Layout";
import React, { useEffect, useMemo } from "react";
import PropsType from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";
import { get, replace } from "lodash";
import classnames from "classnames";
import {
  setIsSidebarOpen,
  setSidebarActive,
  userInfo,
} from "store/actions/auth";
import { showDisplaySettingsModal } from "store/actions/jocks";
import { getCurrentServer } from "store/actions/servers";
import useWindowDimensions from "utils/useWindowDimensions";
import { useLocation } from "react-router-dom";
function MainLayout(props) {
  const {
    children,
    auth,
    setIsSidebarOpen,
    setSidebarActive,
    isShowSidebar,
    showDisplaySettingsModal,
    isShowDisplaySetting,
  } = props;
  const location = useLocation();
  const { width: widthScreen } = useWindowDimensions();
  const isMobile = useMemo(() => {
    return widthScreen < 480;
  }, [widthScreen]);

  const classNameMobile = useMemo(() => {
    if (!isMobile) return "";
    const validPathname = ["processes/view", "processes/add", "processes/edit"];
    if (validPathname.find((item) => location.pathname.includes(item)))
      return "cr-page-processes-details-mobile";
  }, [location, isMobile]);
  useEffect(() => {
    let className = `cr-page${replace(
      window.location.pathname,
      new RegExp("/", "g"),
      "-"
    )}`;
    const isUserProfile = document.location.pathname.indexOf("/user/") !== -1;
    if (isUserProfile) {
      className = "cr-page-profile";
    }
    const appEle = document.getElementById("cr-app-main");
    appEle.classList.add(className);
    return () => {
      appEle.classList.remove(className);
    };
  }, [location]);

  useEffect(() => {
    const isClientReview =
      document.location.pathname.split("/")[1] === "review";
    const authenticated = get(props, "auth.authenticated");
    if (authenticated && !isClientReview) {
      props.userInfo();
      props.getCurrentServer();
    }
    //location
  }, []);
  return (
    <main
      id="cr-app-main"
      className={classnames("cr-app bg-app", classNameMobile)}
    >
      {auth.authenticated && isShowSidebar && (
        <Sidebar
          auth={auth}
          setIsSidebarOpen={setIsSidebarOpen}
          setSidebarActive={setSidebarActive}
        />
      )}
      <Content fluid>
        <Header
          showDisplaySettingsModal={showDisplaySettingsModal}
          setIsSidebarOpen={setIsSidebarOpen}
          isShowDisplaySetting={isShowDisplaySetting}
        />
        {children}
      </Content>
    </main>
  );
}
MainLayout.propsTypes = {
  isShowSidebar: PropsType.boolean,
};
MainLayout.defaultProps = {
  isShowSidebar: true,
};
export default compose(
  connect(
    (state) => ({
      auth: state.auth,
      isShowDisplaySetting: !!get(
        state,
        "jocks.displaySettings.selected_station"
      ),
    }),
    {
      setIsSidebarOpen,
      setSidebarActive,
      showDisplaySettingsModal,
      getCurrentServer,
      userInfo,
    }
  )
)(MainLayout);
