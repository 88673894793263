import React, { useState, forwardRef, useImperativeHandle } from "react";

import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { CloseIcon } from "components/CustomIcons";
import get from "lodash/get";
import bn from "utils/bemnames";
import { FormattedMessage } from "react-intl";
import PreviewView from "./PreviewView";
const bem = bn.create("system-templates");

const TemplatePreviewModal = forwardRef((props, ref) => {
  const [isOpenModal, setOpenModal] = useState(false);
  const [data, setData] = useState("");

  useImperativeHandle(ref, () => ({
    toggle: (d) => {
      setData(d);
      setOpenModal(!isOpenModal);
    },
  }));
  const type = get(data, "type");

  return (
    <Modal isOpen={isOpenModal} className={bem.b()} size="lg">
      <ModalHeader>
        <FormattedMessage id="system templates > preview" />
        <button
          className="close-modal-icon"
          onClick={() => setOpenModal(false)}
        >
          <CloseIcon color={"#C2D4E0"} />
        </button>
      </ModalHeader>

      <ModalBody>
        <PreviewView
          bem={bem}
          data={data}
          onClickHere={() => setOpenModal(false)}
        />
      </ModalBody>

      {!["winner_notification", "rejection_notification"].includes(type) && (
        <ModalFooter>
          <Button color="blue" onClick={() => setOpenModal(false)}>
            <FormattedMessage id="system templates > close" />
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
});

export default TemplatePreviewModal;
