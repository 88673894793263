export const FETCH_WINNERS_REQUEST = "FETCH_WINNERS_REQUEST";
export const FETCH_WINNERS_SUCCESS = "FETCH_WINNERS_SUCCESS";
export const FETCH_WINNERS_ERROR = "FETCH_WINNERS_ERROR";

export const UPDATE_WINNER_REQUEST = "UPDATE_WINNER_REQUEST";
export const UPDATE_WINNER_SUCCESS = "UPDATE_WINNER_SUCCESS";
export const UPDATE_WINNER_ERROR = "UPDATE_WINNER_ERROR";

export const ADD_WINNER_REQUEST = "ADD_WINNER_REQUEST";
export const ADD_WINNER_SUCCESS = "ADD_WINNER_SUCCESS";
export const ADD_WINNER_ERROR = "ADD_WINNER_ERROR";

export const MARK_WIN_LOSS_REQUEST = "MARK_WIN_LOSS_REQUEST";
export const MARK_WIN_LOSS_SUCCESS = "MARK_WIN_LOSS_SUCCESS";
export const MARK_WIN_LOSS_ERROR = "MARK_WIN_LOSS_ERROR";

export const PICK_PRIZE_FOR_WINNER_REQUEST = "PICK_PRIZE_FOR_WINNER_REQUEST";
export const PICK_PRIZE_FOR_WINNER_SUCCESS = "PICK_PRIZE_FOR_WINNER_SUCCESS";
export const PICK_PRIZE_FOR_WINNER_ERROR = "PICK_PRIZE_FOR_WINNER_ERROR";

export const ADD_VALUE_FOR_STANDARD_PRIZE_REQUEST =
  "ADD_VALUE_FOR_STANDARD_PRIZE_REQUEST";
export const ADD_VALUE_FOR_STANDARD_PRIZE_SUCCESS =
  "ADD_VALUE_FOR_STANDARD_PRIZE_SUCCESS";
export const ADD_VALUE_FOR_STANDARD_PRIZE_ERROR =
  "ADD_VALUE_FOR_STANDARD_PRIZE_ERROR";

export const FORGET_WINNERS = "FORGET_WINNERS";

export const FETCH_WINNER_REQUEST = "FETCH_WINNER_REQUEST";
export const FETCH_WINNER_SUCCESS = "FETCH_WINNER_SUCCESS";
export const FETCH_WINNER_ERROR = "FETCH_WINNER_ERROR";

export const FETCH_WINNERS_BY_FILTER_REQUEST =
  "FETCH_WINNERS_BY_FILTER_REQUEST";
export const FETCH_WINNERS_BY_FILTER_SUCCESS =
  "FETCH_WINNERS_BY_FILTER_SUCCESS";
export const FETCH_WINNERS_BY_FILTER_ERROR = "FETCH_WINNERS_BY_FILTER_ERROR";

export const ASSIGN_WINNER_TO_CONTEST_REQUEST =
  "ASSIGN_WINNER_TO_CONTEST_REQUEST";
export const ASSIGN_WINNER_TO_CONTEST_SUCCESS =
  "ASSIGN_WINNER_TO_CONTEST_SUCCESS";
export const ASSIGN_WINNER_TO_CONTEST_ERROR = "FETCH_WINNERS_BY_FILTER_ERROR";

export const UPDATE_WINNER_FULFILLED_REQUEST =
  "UPDATE_WINNER_FULFILLED_REQUEST";
export const UPDATE_WINNER_FULFILLED_SUCCESS =
  "UPDATE_WINNER_FULFILLED_SUCCESS";
export const UPDATE_WINNER_FULFILLED_ERROR = "UPDATE_WINNER_FULFILLED_ERROR";

export const PICK_GRAND_PRIZE_WINNER_REQUEST =
  "PICK_GRAND_PRIZE_WINNER_REQUEST";
export const PICK_GRAND_PRIZE_WINNER_SUCCESS =
  "PICK_GRAND_PRIZE_WINNER_SUCCESS";
export const PICK_GRAND_PRIZE_WINNER_ERROR = "PICK_GRAND_PRIZE_WINNER_ERROR";

export const EXPORT_WINNERS_LIST_REQUEST = "EXPORT_WINNERS_LIST_REQUEST";
export const EXPORT_WINNERS_LIST_SUCCESS = "EXPORT_WINNERS_LIST_SUCCESS";
export const EXPORT_WINNERS_LIST_ERROR = "EXPORT_WINNERS_LIST_ERROR";

export const SAVE_WINNERS_SEARCH_REQUEST = "SAVE_WINNERS_SEARCH_REQUEST";
export const SAVE_WINNERS_SEARCH_SUCCESS = "SAVE_WINNERS_SEARCH_SUCCESS";
export const SAVE_WINNERS_SEARCH_ERROR = "SAVE_WINNERS_SEARCH_ERROR";

export const DELETE_WINNERS_SEARCH_REQUEST = "DELETE_WINNERS_SEARCH_REQUEST";
export const DELETE_WINNERS_SEARCH_SUCCESS = "DELETE_WINNERS_SEARCH_SUCCESS";
export const DELETE_WINNERS_SEARCH_ERROR = "DELETE_WINNERS_SEARCH_ERROR";

export const GET_WINNERS_SAVED_SEARCHES_REQUEST =
  "GET_WINNERS_SAVED_SEARCHES_REQUEST";
export const GET_WINNERS_SAVED_SEARCHES_SUCCESS =
  "GET_WINNERS_SAVED_SEARCHES_SUCCESS";
export const GET_WINNERS_SAVED_SEARCHES_ERROR =
  "GET_WINNERS_SAVED_SEARCHES_ERROR";

export const GET_ALL_WINNERS_SAVED_SEARCHES_REQUEST =
  "GET_ALL_WINNERS_SAVED_SEARCHES_REQUEST";
export const GET_ALL_WINNERS_SAVED_SEARCHES_SUCCESS =
  "GET_ALL_WINNERS_SAVED_SEARCHES_SUCCESS";
export const GET_ALL_WINNERS_SAVED_SEARCHES_ERROR =
  "GET_ALL_WINNERS_SAVED_SEARCHES_ERROR";

export const FETCH_REPORT_WINNERS_REQUEST = "FETCH_REPORT_WINNERS_REQUEST";
export const FETCH_REPORT_WINNERS_SUCCESS = "FETCH_REPORT_WINNERS_SUCCESS";
export const FETCH_REPORT_WINNERS_ERROR = "FETCH_REPORT_WINNERS_ERROR";

export const EXPORT_1099_REPORTS_WINNERS_LIST_REQUEST =
  "EXPORT_1099_REPORTS_WINNERS_LIST_REQUEST";
export const EXPORT_1099_REPORTS_WINNERS_LIST_SUCCESS =
  "EXPORT_1099_REPORTS_WINNERS_LIST_SUCCESS";
export const EXPORT_1099_REPORTS_WINNERS_LIST_ERROR =
  "EXPORT_1099_REPORTS_WINNERS_LIST_ERROR";

export const SET_ADVANCED_SEARCH_DATA = "SET_ADVANCED_SEARCH_DATA";

export const FETCH_WINNERS_NOTIFICATIONS_REQUEST =
  "FETCH_WINNERS_NOTIFICATIONS_REQUEST";
export const FETCH_WINNERS_NOTIFICATIONS_SUCCESS =
  "FETCH_WINNERS_NOTIFICATIONS_SUCCESS";
export const FETCH_WINNERS_NOTIFICATIONS_ERROR =
  "FETCH_WINNERS_NOTIFICATIONS_ERROR";

export const UPDATE_WINNER_NOTIFICATION_REQUEST =
  "UPDATE_WINNER_NOTIFICATION_REQUEST";
export const UPDATE_WINNER_NOTIFICATION_SUCCESS =
  "UPDATE_WINNER_NOTIFICATION_SUCCESS";
export const UPDATE_WINNER_NOTIFICATION_ERROR =
  "UPDATE_WINNER_NOTIFICATION_ERROR";

export const ADD_WINNER_NOTIFICATION_REQUEST =
  "ADD_WINNER_NOTIFICATION_REQUEST";
export const ADD_WINNER_NOTIFICATION_SUCCESS =
  "ADD_WINNER_NOTIFICATION_SUCCESS";
export const ADD_WINNER_NOTIFICATION_ERROR = "ADD_WINNER_NOTIFICATION_ERROR";

export const DELETE_WINNER_NOTIFICATION_REQUEST =
  "DELETE_WINNER_NOTIFICATION_REQUEST";
export const DELETE_WINNER_NOTIFICATION_SUCCESS =
  "DELETE_WINNER_NOTIFICATION_SUCCESS";
export const DELETE_WINNER_NOTIFICATION_ERROR =
  "DELETE_WINNER_NOTIFICATION_ERROR";

export const UPDATE_WINNER_TO_CONTEST_REQUEST =
  "UPDATE_WINNER_TO_CONTEST_REQUEST";
export const UPDATE_WINNER_TO_CONTEST_SUCCESS =
  "UPDATE_WINNER_TO_CONTEST_SUCCESS";
export const UPDATE_WINNER_TO_CONTEST_ERROR = "UPDATE_WINNER_TO_CONTEST_ERROR";

export const CREATE_FLOATING_CONTEST_REQUEST =
  "CREATE_FLOATING_CONTEST_REQUEST";
export const CREATE_FLOATING_CONTEST_SUCCESS =
  "CREATE_FLOATING_CONTEST_SUCCESS";
export const CREATE_FLOATING_CONTEST_ERROR = "CREATE_FLOATING_CONTEST_ERROR";
