import UploadFile from './view';
import { connect } from 'react-redux';
import { compose } from 'recompose';

export default compose(
  connect(
    state => ({ ...state }),
    dispatch => ({})
  )
)(UploadFile);
