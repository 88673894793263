import ContestRules from "./view";
import { injectIntl } from "react-intl";
import { get } from "lodash";
import { connect } from "react-redux";
const Enhanced = connect(
  (state) => ({
    dateFormatByServer: get(
      state,
      "servers.currentServer.data.date_format",
      "MM/DD/YYYY"
    ),
  }),
  {}
)(ContestRules);

export default injectIntl(Enhanced);
