import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Spinner from "components/Spinner";
import UploadFile from "components/UploadFile";
import {
  Form,
  FormGroup,
  Button,
  CardHeader,
  Card,
  CardBody
} from "reactstrap";
import bn from "utils/bemnames";
import { setTimeout } from "timers";
const bem = bn.create("bulk-upload-form");

class BulkUploadForm extends React.Component {
  renderForm = () => {
    const { isLoading, intl } = this.props;
    return (
      <Formik
        enableReinitialize
        initialValues={{
          file: ""
        }}
        validationSchema={Yup.object().shape({
          file: Yup.string().required(
            intl.formatMessage({
              id: "validate > file is required"
            })
          )
        })}
        onSubmit={this.props.onFormSubmit}
      >
        {formProps => {
          const errors = formProps.errors;
          const touched = formProps.touched;
          // const values = formProps.values;
          return (
            <Form onSubmit={formProps.handleSubmit}>
              <FormGroup>
                <label>
                  <FormattedMessage id="user > file" />
                </label>
                <UploadFile
                  url={null}
                  fieldName={"file"}
                  actions={["replace"]}
                  onDeleted={() => {
                    setTimeout(() => {
                      formProps.setFieldValue("file", "", true);
                      formProps.setFieldTouched("file", true);
                    }, 20);
                  }}
                  onUploaded={({ path }) => {
                    setTimeout(() => {
                      formProps.setFieldValue("file", path);
                    }, 20);
                  }}
                  showRemoveIcon={false}
                />
                {!!(touched.file && errors.file) && (
                  <span className="text-danger">{errors.file}</span>
                )}
              </FormGroup>

              <FormGroup>
                <Button
                  color="blue"
                  type="submit"
                  className="btn btn-radius"
                  disabled={isLoading}
                >
                  <FormattedMessage
                    id={`user > button ${!isLoading ? "submit" : "submitting"}`}
                  />
                </Button>
                {` `}
                {this.props.backButton}
              </FormGroup>
            </Form>
          );
        }}
      </Formik>
    );
  };

  render() {
    const { isLoading } = this.props;
    return (
      <Card className={bem.b()}>
        <CardHeader>
          <h2 className={bem.e("title")}>
            <FormattedMessage id={"user > bulk upload title"} />
          </h2>
        </CardHeader>
        <CardBody>{this.renderForm()} </CardBody>
        <Spinner isLoading={isLoading} />
      </Card>
    );
  }
}
BulkUploadForm.defaultProps = {
  backButton: null,
  onBack: () => {}
};
BulkUploadForm.propTypes = {
  backButton: PropTypes.any,
  onBack: PropTypes.func.isRequired
};
export default BulkUploadForm;
