import OrderDetails from "./orderDetails";
import ApproveOrder from "./approveOrder";
import Design from "./design";
// import Media from "./media";
import ReviewMedia from "./reviewMedia";
import Traffic from "./traffic";
import Report from "./report";
import Log from "./log";
const steps = {
  order_details: OrderDetails,
  approve_order: ApproveOrder,
  design: Design,
  // media: Media,
  review_media: ReviewMedia,
  traffic: Traffic,
  report: Report,
  log: Log,
};
export default steps;
