import { FormattedMessage } from "react-intl";
import React from "react";
import { isNaN, get, includes, isEmpty, map } from "lodash";
import moment from "moment";
const fields = [
  {
    field: "timezone",
  },
  {
    field: "order_stations",
    title: <FormattedMessage id="process > field station" />,
    mandatory: true,
    validation: function(step, item, process, template, user, value) {
      return value.length > 0
        ? true
        : "At least one station has to be selected";
    },
    step: ["order_details"],
  },
  {
    field: "contract_stations",
  },
  {
    field: "order_stations_array",
  },
  {
    field: "order_title",
    title: (
      <FormattedMessage id="process > promotion > contest > contest title" />
    ),
    mandatory: true,
    validation: function(step, item, process, template, user, value) {
      return value && value.length > 2
        ? true
        : "Item title must be at least 3 letters long";
    },
    step: ["order_details"],
  },
  {
    field: "order_type",
    title: (
      <FormattedMessage id="process > promotion > contest > contest type" />
    ),
    mandatory: true,
    validation: function(step, item, process, template, user, value) {
      if (!value || value.length === 0) return "Please select contest type";
      return true;
    },
    step: ["order_details"],
    props: {
      options: [
        {
          label: (
            <FormattedMessage id="process > promotion > contest > call in contest" />
          ),
          value: "call_in_contest",
        },
        {
          label: (
            <FormattedMessage id="process > promotion > contest > online registration" />
          ),
          value: "online_registration",
        },
        {
          label: (
            <FormattedMessage id="process > promotion > contest > text to win" />
          ),
          value: "text_to_win",
        },
      ],
    },
  },
  {
    field: "type_id",
    title: (
      <FormattedMessage id="process > promotion > contest > category type" />
    ),
    mandatory: true,
    step: ["order_details"],
  },
  {
    field: "theme",
    title: <FormattedMessage id="process > promotion > contest > theme" />,
    mandatory: false,
    props: {
      options: [
        {
          label: (
            <FormattedMessage id="process > promotion > contest > standard giveaway" />
          ),
          value: "standard_giveaway",
        },
        {
          label: (
            <FormattedMessage id="process > promotion > contest > qualifying with grand prize" />
          ),
          value: "qualifying_with_grand_prize",
        },
        {
          label: (
            <FormattedMessage id="process > promotion > contest > 5 4 3 2 1" />
          ),
          value: "5_4_3_2_1",
        },
        {
          label: (
            <FormattedMessage id="process > promotion > contest > rollover" />
          ),
          value: "rollover",
        },
        {
          label: (
            <FormattedMessage id="process > promotion > contest > pick your prize" />
          ),
          value: "pick_your_prize",
        },
      ],
    },
  },
  {
    field: "winner_selection",
    title: (
      <FormattedMessage id="process > promotion > contest > winner selection" />
    ),
    mandatory: true,
    step: ["order_details"],
    validation: function(step, item, process, template, user, value) {
      const orderType = get(process, "fields.order_type.value");
      const isEnableWinnerSelection = includes(
        ["online_registration", "text_to_win"],
        orderType
      );
      if (!isEnableWinnerSelection) return true;
      if (!value || value.length === 0) return "Please select winner selection";
      return true;
    },
    props: {
      options: [
        {
          label: (
            <FormattedMessage id="process > promotion > contest > on air shout-out" />
          ),
          value: "on_air_shout_out",
        },
        {
          label: (
            <FormattedMessage id="process > promotion > contest > off air selection" />
          ),
          value: "off_air_selection",
        },
      ],
    },
  },
  {
    field: "plays",
    title: <FormattedMessage id="process > promotion > contest > plays" />,
    // mandatory: true,
    // validation: function(step, item, process, template, user, value) {
    //   if (!value || value.length === 0) return "Please select plays";
    //   return true;
    // },
    props: {
      options: [
        {
          label: (
            <FormattedMessage id="process > promotion > contest > set number" />
          ),
          value: "set_number",
        },
        {
          label: (
            <FormattedMessage id="process > promotion > contest > open ended" />
          ),
          value: "open_ended",
        },
      ],
    },
  },
  {
    field: "plays_number",
    title: <FormattedMessage id="process > promotion > contest > number" />,
    mandatory: true,
    step: ["order_details"],
    validation: function(step, item, process, template, user, value) {
      const isSetNumber = get(process, "fields.plays.value") === "set_number";
      if (!isSetNumber) return true;
      if (!value || value.length === 0) return "Please enter number";
      return true;
    },
  },
  {
    field: "description",
    title: (
      <FormattedMessage id="process > promotion > contest > description" />
    ),
    mandatory: false,
    step: ["order_details"],
  },
  {
    field: "contest_image",
    title: (
      <FormattedMessage id="process > promotion > contest > contest image" />
    ),
    mandatory: false,
    step: ["order_details"],
  },
  {
    field: "automation_workflow_setting",
    title: (
      <FormattedMessage id="process > promotion > contest > automation workflow" />
    ),
    mandatory: true,
    step: ["order_details"],
    validation: function(step, item, process, template, user, value) {
      if (!value) return "Please select automation workflow";
      return true;
    },
  },
  {
    field: "order_start_date",
    title: <FormattedMessage id="process > promotion > contest > start date" />,
    mandatory: true,
    validation: function(step, item, process, template, user, value) {
      if (!value) return "Please enter a date";
      if (value.length === 0) return "Please enter a date";
      if (isNaN(Date.parse(value))) return "Please enter a valid date";
      return true;
    },
    step: ["order_details"],
  },
  {
    field: "order_end_date",
    title: <FormattedMessage id="process > promotion > contest > end date" />,
    mandatory: true,
    validation: function(step, item, process, template, user, value) {
      if (!value) return "Please enter a date";
      if (value.length === 0) return "Please enter a date";
      if (isNaN(Date.parse(value))) return "Please enter a valid date";
      if (
        process.fields.order_start_date &&
        !isNaN(Date.parse(process.fields.order_start_date.value)) &&
        Date.parse(value) < Date.parse(process.fields.order_start_date.value)
      )
        return "Please enter a date later than the start date";
      return true;
    },
    step: ["order_details"],
  },
  {
    field: "days_of_week",
    title: (
      <FormattedMessage id="process > promotion > contest > days of the week" />
    ),
    mandatory: true,
    validation: function(step, item, process, template, user, value) {
      if (!value || value.length === 0) return "Please select days";
      return true;
    },
    step: ["order_details"],
  },
  {
    field: "excluded_dates",
    title: (
      <FormattedMessage id="process > promotion > contest > excluded dates" />
    ),
    mandatory: false,
  },
  {
    field: "excluded_hours_array",
    title: (
      <FormattedMessage id="process > promotion > liner > excluded hours" />
    ),
    mandatory: false,
  },

  {
    field: "jock_instructions",
    title: (
      <FormattedMessage id="process > promotion > contest > field jock instructions" />
    ),
    mandatory: false,
  },
  {
    field: "script_before",
    title: <FormattedMessage id="process > field script" />,
    mandatory: false,
  },
  {
    field: "script_after",
    title: <FormattedMessage id="process > field script" />,
    mandatory: false,
  },
  {
    field: "scheduling_type",
    mandatory: true,
    props: {
      options: [
        {
          label: (
            <FormattedMessage id="process > promotion > contest > simple" />
          ),
          value: "simple",
        },
        {
          label: (
            <FormattedMessage id="process > promotion > contest > targeted" />
          ),
          value: "targeted",
        },
        {
          label: (
            <FormattedMessage id="process > promotion > contest > floating" />
          ),
          value: "floating",
        },
      ],
    },
    validation: function(step, item, process, template, user, value) {
      const orderEndDate = get(process, "fields.order_end_date.value", "");
      if (orderEndDate && moment(orderEndDate).isBefore(moment(), "day")) {
        return true;
      }
      if (!value || value.length === 0) return "Please select schedule method";
      return true;
    },
    step: ["order_details"],
  },
  {
    field: "schedule_by",
    title: <FormattedMessage id="process > promotion > liner > select by" />,
    mandatory: true,
    props: {
      options: [
        {
          label: (
            <FormattedMessage id="process > promotion liner > scheduling > ros" />
          ),
          value: "ROS",
          scheduling_type: ["simple"],
        },
        {
          label: (
            <FormattedMessage id="process > promotion liner > scheduling > neilsen dayparts" />
          ),
          value: "daypart",
          scheduling_type: ["floating", "simple"],
        },
        {
          label: (
            <FormattedMessage id="process > promotion liner > scheduling > shows" />
          ),
          value: "show",
          scheduling_type: ["floating", "simple"],
        },
      ],
    },
    step: ["order_details"],
    validation: function(step, item, process, template, user, value) {
      const orderEndDate = get(process, "fields.order_end_date.value", "");
      if (orderEndDate && moment(orderEndDate).isBefore(moment(), "day")) {
        return true;
      }
      const scheduleMethod = get(process, "fields.scheduling_type.value", "");
      if (scheduleMethod === "targeted" || value) return true;
      return "Please select By";
    },
  },
  {
    field: "selected_by",
    title: <FormattedMessage id="process > promotion > liner > select by" />,
    mandatory: true,
    props: {
      options: [
        {
          label: (
            <FormattedMessage id="process > promotion liner > scheduling > ros" />
          ),
          value: "ROS",
          scheduling_type: ["simple"],
        },
        {
          label: (
            <FormattedMessage id="process > promotion liner > scheduling > neilsen dayparts" />
          ),
          value: "daypart",
          scheduling_type: ["floating", "targeted", "simple"],
        },
        {
          label: (
            <FormattedMessage id="process > promotion liner > scheduling > shows" />
          ),
          value: "show",
          scheduling_type: ["floating", "targeted", "simple"],
        },
        {
          label: (
            <FormattedMessage id="process > promotion liner > scheduling > hour" />
          ),
          value: "hour",
          scheduling_type: ["targeted"],
        },
      ],
    },
    step: ["order_details"],
    validation: function(step, item, process, template, user, value) {
      const orderEndDate = get(process, "fields.order_end_date.value", "");
      if (orderEndDate && moment(orderEndDate).isBefore(moment(), "day")) {
        return true;
      }
      const scheduleMethod = get(process, "fields.scheduling_type.value", "");
      if (scheduleMethod === "simple" || scheduleMethod === "floating")
        return true;
      const per = get(process, "fields.per.value", "");
      const perRequiredSelectedBy = ["week", "day", "hour"];
      if (perRequiredSelectedBy.indexOf(per) !== -1 && !value)
        return "Please select Selected By";
      return true;
    },
  },
  {
    field: "selected_items",
    mandatory: true,
    validation: function(step, item, process, template, user, value) {
      const orderEndDate = get(process, "fields.order_end_date.value", "");
      if (orderEndDate && moment(orderEndDate).isBefore(moment(), "day")) {
        return true;
      }
      const scheduleMethod = get(process, "fields.scheduling_type.value", "");
      const selectedBy = get(process, "fields.selected_by.value", "");
      const scheduleBy = get(process, "fields.schedule_by.value", "");
      if (
        scheduleMethod === "targeted" &&
        selectedBy === "hour" &&
        (!value || value.length === 0)
      ) {
        return "Please select Hours";
      }
      if (
        scheduleMethod === "targeted" &&
        selectedBy === "daypart" &&
        (!value || value.length === 0)
      ) {
        return "Please select dayparts";
      }
      if (
        scheduleMethod === "targeted" &&
        selectedBy === "show" &&
        (!value || value.length === 0)
      ) {
        return "Please select Shows";
      }
      if (scheduleMethod === "floating" || scheduleMethod === "simple") {
        if (scheduleBy === "show" && (!value || value.length === 0)) {
          return "Please select Shows";
        }
        if (scheduleBy === "daypart" && (!value || value.length === 0)) {
          return "Please select dayparts";
        }
      }
      return true;
    },
    step: ["order_details"],
  },
  {
    field: "mentions",
    title: <FormattedMessage id="process > promotion > contest > plays" />,
    mandatory: true,
    validation: function(step, item, process, template, user, value) {
      const orderEndDate = get(process, "fields.order_end_date.value", "");
      if (orderEndDate && moment(orderEndDate).isBefore(moment(), "day")) {
        return true;
      }
      const scheduleMethod = get(process, "fields.scheduling_type.value", "");
      if (scheduleMethod === "targeted" && !value) {
        return "Please select Plays";
      }
      return true;
    },
    step: ["order_details"],
  },
  {
    field: "per",
    title: (
      <FormattedMessage id="process > promotion > liner > scheduling > per" />
    ),
    mandatory: true,
    validation: function(step, item, process, template, user, value) {
      const orderEndDate = get(process, "fields.order_end_date.value", "");
      if (orderEndDate && moment(orderEndDate).isBefore(moment(), "day")) {
        return true;
      }
      const scheduleMethod = get(process, "fields.scheduling_type.value", "");
      if (scheduleMethod === "targeted" && !value) {
        return "Please select Per";
      }
      return true;
    },
    props: {
      options: [
        {
          label: (
            <FormattedMessage id="process > promotion > liner > per week" />
          ),
          value: "week",
        },
        {
          label: (
            <FormattedMessage id="process > promotion > liner > per day" />
          ),
          value: "day",
        },
        {
          label: (
            <FormattedMessage id="process > promotion > liner > per hour" />
          ),
          value: "hour",
        },
        {
          label: (
            <FormattedMessage id="process > promotion > liner > per show" />
          ),
          value: "show",
        },
        {
          label: (
            <FormattedMessage id="process > promotion > liner > per nielsen daypart" />
          ),
          value: "daypart",
        },
      ],
    },
    step: ["order_details"],
  },
  {
    field: "supplemental_items",
  },
  {
    field: "desired_items_scheduling",
    mandatory: true,
    validation: function(step, item, process, template, user, value) {
      const orderEndDate = get(process, "fields.order_end_date.value", "");
      if (orderEndDate && moment(orderEndDate).isBefore(moment(), "day")) {
        return true;
      }
      const errorMessage = "Please enter valid desired items";
      const schedulingType = get(process, "fields.scheduling_type.value");
      if (schedulingType === "floating") {
        return true;
      }
      const totalSupplementalItems = get(
        process,
        "fields.supplemental_items.value",
        0
      );
      const totalAvailableItems = get(
        process,
        "fields.total_available_items.value"
      );
      try {
        const item_count = Number(value);
        if (!item_count) {
          return errorMessage;
        }

        if (
          parseFloat(totalAvailableItems) +
            parseFloat(totalSupplementalItems) >=
          item_count
        ) {
          return true;
        }
        return "Desired items should be smaller than available items";
      } catch {
        return errorMessage;
      }
    },
    step: ["order_details"],
  },
  {
    field: "total_available_items",
    mandatory: true,
    validation: function(step, item, process, template, user, value) {
      const orderEndDate = get(process, "fields.order_end_date.value", "");
      const schedulingType = get(process, "fields.scheduling_type.value");

      if (orderEndDate && moment(orderEndDate).isBefore(moment(), "day")) {
        return true;
      }
      if (schedulingType === "floating") return true;
      const errorMessage = "Please select dates with items available";
      try {
        const item_count = Number(value);
        return !!item_count || errorMessage;
      } catch {
        return errorMessage;
      }
    },
    step: ["order_details"],
  },
  {
    field: "relax_restrictions_for",
  },
  {
    field: "relax_restrictions_for_weeks",
  },
  {
    field: "age_restrictions",
    title: (
      <FormattedMessage id="process > promotion > contest > age restrictions" />
    ),
    mandatory: false,
    props: {
      options: [
        {
          label: (
            <FormattedMessage id="process > promotion > contest > age none" />
          ),
          value: "",
        },
        {
          label: (
            <FormattedMessage id="process > promotion > contest > age 18+" />
          ),
          value: "18",
        },
        {
          label: (
            <FormattedMessage id="process > promotion > contest > age 21+" />
          ),
          value: "21",
        },
      ],
    },
  },
  {
    field: "contest_rules",
    title: (
      <FormattedMessage id="process > promotion > contest > rules and legal notes" />
    ),
    mandatory: false,
  },
  {
    field: "rule_eligibility",
    title: (
      <FormattedMessage id="process > promotion > contest > eligibility for this contest" />
    ),
    mandatory: false,
    props: {
      options: [
        {
          label: (
            <FormattedMessage id="process > promotion > contest > eligibility default" />
          ),
          value: "default",
        },
        {
          label: (
            <FormattedMessage id="process > promotion > contest > eligibility everybody" />
          ),
          value: "everybody",
        },
      ],
    },
  },
  {
    field: "rule_future_eligibility",
    title: (
      <FormattedMessage id="process > promotion > contest > future eligibility for winners" />
    ),
    mandatory: false,
    props: {
      options: [
        {
          label: (
            <FormattedMessage id="process > promotion > contest > no restrictions, default eligibility in effect" />
          ),
          value: "no restrictions, default eligibility in effect",
        },
        {
          label: (
            <FormattedMessage id="process > promotion > contest > no restrictions, exclude from eligibility checking" />
          ),
          value: "no restrictions, exclude from eligibility checking",
        },
        {
          label: (
            <FormattedMessage id="process > promotion > contest > winner can only win once, default eligibility in effect" />
          ),
          value: "winner can only win once, default eligibility in effect",
        },
        {
          label: (
            <FormattedMessage id="process > promotion > contest > winner can only win once, exclude from eligibility checking" />
          ),
          value: "winner can only win once, exclude from eligibility checking",
        },
        {
          label: (
            <FormattedMessage id="process > promotion > contest > winner can only win once, ineligible to win any other contest while this contest is active" />
          ),
          value:
            "winner can only win once, ineligible to win any other contest while this contest is active",
        },
      ],
    },
  },
  {
    field: "rule_number_of_days",
    title: (
      <FormattedMessage id="process > promotion > contest > prize pick up window or deadline" />
    ),
    mandatory: true,
    validation: function(step, item, process, template, user, value) {
      const ruleDeadline = get(process, "fields.rule_deadline.value", "");
      if (ruleDeadline) return true;
      if (!value) {
        return "Please enter number of days";
      }
      if (parseInt(value) > 365)
        return "End number of days field must be smaller than 365";
      return true;
    },
    step: ["order_details"],
  },
  {
    field: "rule_deadline",
    mandatory: true,
    validation: function(step, item, process, template, user, value) {
      const ruleDays = get(process, "fields.rule_number_of_days.value", "");
      if (ruleDays) return true;
      if (!value) {
        return "Please select deadline";
      }
      return true;
    },
    step: ["order_details"],
  },
  {
    field: "associated_items_ids",
    // Note: making this optional as per requirements
    // mandatory: true,
    // validation: function(step, item, process, template, user, value) {
    //   if (!value || value.length === 0) {
    //     return "Please select associate item";
    //   }
    //   return true;
    // },
  },
  {
    field: "associated_items",
  },
  {
    field: "enforce_forfeiture",
    title: (
      <FormattedMessage id="process > promotion > contest > enforce forfeiture" />
    ),
  },
  {
    field: "client_name",
    title: <FormattedMessage id="process > promotion > contest > client" />,
    mandatory: false,
  },
  {
    field: "contract_number",
    title: (
      <FormattedMessage id="process > promotion > contest > contract number" />
    ),
    mandatory: false,
  },
  {
    field: "salesperson",
    title: (
      <FormattedMessage id="process > promotion > contest > salesperson" />
    ),
    mandatory: false,
  },
  {
    field: "documents",
    title: <FormattedMessage id="process > promotion > contest > documents" />,
    mandatory: false,
  },
  {
    field: "qualifying_prize",
    mandatory: false,
  },
  {
    field: "grand_prize_execution_time",
    title: (
      <FormattedMessage id="process > promotion > contest > execution time" />
    ),
    mandatory: true,
    validation: function(step, item, process, template, user, value) {
      const theme = get(process, "fields.theme.value");
      const prizeExecution = get(process, "fields.prize_execution.value");
      const isOffAir = prizeExecution === "off_air";
      if (theme !== "qualifying_with_grand_prize" || value || isOffAir)
        return true;
      return "Please select execution time";
    },
    step: ["order_details"],
  },
  {
    field: "prize_execution",
    title: (
      <FormattedMessage id="process > promotion > contest > prize execution" />
    ),
    mandatory: true,
    validation: function(step, item, process, template, user, value) {
      const theme = get(process, "fields.theme.value");
      if (theme !== "qualifying_with_grand_prize" || value) return true;
      return "Please select execution";
    },
    step: ["order_details"],
    props: {
      options: [
        {
          label: (
            <FormattedMessage id="process > promotion > contest > on air" />
          ),
          value: "on_air",
        },
        {
          label: (
            <FormattedMessage id="process > promotion > contest > off air" />
          ),
          value: "off_air",
        },
      ],
    },
  },
  {
    field: "grand_prize_execution_date",
    title: (
      <FormattedMessage id="process > promotion > contest > execution date" />
    ),
    mandatory: true,
    validation: function(step, item, process, template, user, value) {
      const theme = get(process, "fields.theme.value");
      const prizeExecution = get(process, "fields.prize_execution.value");
      const isOffAir = prizeExecution === "off_air";
      if (theme !== "qualifying_with_grand_prize" || value || isOffAir)
        return true;
      return "Please select execution date";
    },
    step: ["order_details"],
  },
  {
    field: "show_daypart",
    title: (
      <FormattedMessage id="process > promotion > contest > show / daypart" />
    ),
    mandatory: true,
    validation: function(step, item, process, template, user, value) {
      const theme = get(process, "fields.theme.value");
      const prizeExecution = get(process, "fields.prize_execution.value");
      const isOffAir = prizeExecution === "off_air";
      if (theme !== "qualifying_with_grand_prize" || value || isOffAir)
        return true;
      return "Please select show / daypart";
    },
    step: ["order_details"],
  },
  {
    field: "grand_prize_script_before",
    title: <FormattedMessage id="process > field script" />,
    mandatory: false,
  },
  {
    field: "grand_prize_script_after",
    title: <FormattedMessage id="process > field script" />,
    mandatory: false,
  },
  {
    field: "grand_prize_jock_instructions",
    title: (
      <FormattedMessage id="process > promotion > contest > field jock instructions" />
    ),
    mandatory: false,
  },
  {
    field: "is_publish",
  },
  {
    field: "prizes",
    title: <FormattedMessage id="process > promotion > contest > prize" />,
    mandatory: true,
    validation: function(step, item, process, template, user, value) {
      const qualifyingPrize = get(
        process,
        "fields.qualifying_prize.value",
        false
      );
      const theme = get(process, "fields.theme.value", "");
      const isQualifyingGrandPrizeTheme =
        theme === "qualifying_with_grand_prize";
      const isHidePrizePackage =
        isQualifyingGrandPrizeTheme && !qualifyingPrize;
      if (isHidePrizePackage) return true;

      if (!value || value.length === 0) {
        return "Please select prizes";
      }
      let isValid = true;
      const isRolloverTheme = theme === "rollover";
      const is54321Theme = theme === "5_4_3_2_1";
      const isPickYourPrizeTheme = theme === "pick_your_prize";
      const isStandardGiveawayTheme = theme === "standard_giveaway";
      const firstPrizeCategory = get(process, "fields.prizes.value.0.category");
      const isCashVariablePrize = firstPrizeCategory === "variable_cash";
      const isStandardCash = isStandardGiveawayTheme && isCashVariablePrize;

      const isPerPlayRequired = is54321Theme || isRolloverTheme;
      const isFulfillmentRequired = is54321Theme || isRolloverTheme;
      /**
       * ERROR HANDLING
       * Total Allotment is more than the AVAILABLE quantity of the Prize, then system should show error message.
       * ALSO, If user changes PER PLAY and Total Avail is too high, it throws error
       */
      const validate = map(value, (prize) => {
        let error = {};
        if (isPickYourPrizeTheme && !prize.total_allotment) {
          error.total_allotment = "Total allotment is required";
        }
        if (!isStandardCash && prize.total_allotment > prize.available) {
          error.total_allotment =
            "Total allotment should smaller than the available quantity";
        }
        if (isPerPlayRequired && !prize.per_play) {
          error.per_play = "Per play is required";
        }
        if (isFulfillmentRequired && !prize.fulfillment) {
          error.fulfillment = "Fulfillment is required";
        }
        if (
          is54321Theme &&
          get(
            process,
            "fields.prizes.value.0.selected_itemized_prices.length"
          ) !== get(template, "numberOfPlaysFor54321Theme")
        ) {
          error.selected_items = `Total of ${template.numberOfPlaysFor54321Theme} items are required`;
        }
        if (!isEmpty(error)) {
          isValid = false;
        }
        return error;
      });
      if (isValid) return true;
      return validate;
    },
    step: ["prizes"],
  },
  {
    field: "grand_prizes",
    title: <FormattedMessage id="process > promotion > contest > prize" />,
    mandatory: true,
    validation: function(step, item, process, template, user, value) {
      const theme = get(process, "fields.theme.value", "");
      const isQualifyingGrandPrizeTheme =
        theme === "qualifying_with_grand_prize";
      if (!isQualifyingGrandPrizeTheme) return true;
      if (!value || value.length === 0) {
        return "Please select grand prizes";
      }
      let isValid = true;
      const isPerPlayRequired = isQualifyingGrandPrizeTheme;
      const isFulfillmentRequired = isQualifyingGrandPrizeTheme;
      /**
       * ERROR HANDLING
       * Total Allotment is more than the AVAILABLE quantity of the Prize, then system should show error message.
       * ALSO, If user changes PER PLAY and Total Avail is too high, it throws error
       */
      const validate = map(value, (prize) => {
        let error = {};
        if (isPerPlayRequired && !prize.per_play) {
          error.per_play = "Per play is required";
        }
        if (isFulfillmentRequired && !prize.fulfillment) {
          error.fulfillment = "Fulfillment is required";
        }
        if (!isEmpty(error)) {
          isValid = false;
        }
        return error;
      });
      if (isValid) return true;
      return validate;
    },
    step: ["prizes"],
  },
  {
    field: "winner_fulfillment_form",
    title: (
      <FormattedMessage id="process > promotion > contest > winner fulfillment form" />
    ),
    mandatory: true,
    validation: function(step, item, process, template, user, value) {
      if (!value) {
        return "Please select winner fulfillment form";
      }
      return true;
    },
    step: ["winners"],
  },
  {
    field: "station_logo_url",
    title: (
      <FormattedMessage id="process > promotion > contest > station logo" />
    ),
    mandatory: false,
    step: ["winners"],
  },
  {
    field: "winner_fulfillment_intro_text",
    title: (
      <FormattedMessage id="process > promotion > contest > introduction text" />
    ),
    mandatory: false,
    step: ["winners"],
  },
  {
    field: "winner_fulfillment_fields",
    mandatory: false,
    step: ["winners"],
  },
  { field: "set_intro_text_as_default", step: ["winners"] },
  {
    field: "winner_fulfillment_name",
    title: (
      <FormattedMessage id="process > promotion > contest > contest title" />
    ),
    mandatory: true,
    validation: function(step, item, process, template, user, value) {
      return value && value.length > 2
        ? true
        : "Contest name must be at least 3 letters long";
    },
    step: ["winners"],
  },
];
export default fields;
