export const FETCH_WORKFLOWS_REQUEST = "FETCH_WORKFLOWS_REQUEST";
export const FETCH_WORKFLOWS_SUCCESS = "FETCH_WORKFLOWS_SUCCESS";
export const FETCH_WORKFLOWS_ERROR = "FETCH_WORKFLOWS_ERROR";
export const ADD_WORKFLOW_REQUEST = "ADD_WORKFLOW_REQUEST";
export const ADD_WORKFLOW_ERROR = "ADD_WORKFLOW_ERROR";
export const ADD_WORKFLOW_SUCCESS = "ADD_WORKFLOW_SUCCESS";
export const UPDATE_WORKFLOW_REQUEST = "UPDATE_WORKFLOW_REQUEST";
export const UPDATE_WORKFLOW_SUCCESS = "UPDATE_WORKFLOW_SUCCESS";
export const UPDATE_WORKFLOW_ERROR = "UPDATE_WORKFLOW_ERROR";
export const FORGET_WORKFLOWS = "FORGET_WORKFLOWS";
export const DELETE_WORKFLOW_SUCCESS = "DELETE_WORKFLOW_SUCCESS";
export const DELETE_WORKFLOW_REQUEST = "DELETE_WORKFLOW_REQUEST";
export const DELETE_WORKFLOW_ERROR = "DELETE_WORKFLOW_ERROR";
