import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import { Button } from "reactstrap";
import { get, replace } from "lodash";
import moment from "moment";
import { DoneIcon, NoWinnerIcon } from "components/CustomIcons";
import JockCard from "../Common/JockCard";
import AddWinnerModal from "../AddWinnerModal";
import PreviewScriptModal from "components/PreviewScriptModal";
import PrizePackage from "../Common/PrizePackage";
import WinnerRecap from "../Common/WinnerRecap";
import {
  jockScriptTags,
  contestScriptBeforeTags,
  contestScriptAfterTags,
  grandScriptAfterTags,
  grandScriptTags,
} from "utils/config";
import bn from "utils/bemnames";
import TitleTooltip from "components/TitleTooltip";
import ToastManager from "components/ToastManager";
import PrivilegedComponent from "components/PrivilegedComponent";
import { PRIVILEGES } from "utils/constants";
import AddNoteNoWinnerModal from "../AddNoteNoWinnerModal/AddNoteNoWinnerModal";
import FontSlider from "components/JockConsole/FontSlider";
const DEFAULT_SLIDER = 24;
const bem = bn.create("jock-console-content");
const configScriptTags = {
  jock: jockScriptTags,
  script_after: contestScriptAfterTags,
  script_before: contestScriptBeforeTags,
  grand_script_after: grandScriptAfterTags,
  grand_script_before: grandScriptTags,
};

export default function Content(props) {
  const {
    intl,
    selectedSchedule,
    setSelectedSchedule,
    displaySettings,
    loading,
    onPickGrandPrizeWinner,
    isShowDoneButton,
    isDoneButtonDisabled,
    isShowPickPrizeButton,
    setCurrentAction,
    selectedDate,
    onCreateFloatingPlay,
  } = props;
  const [isOpenWinnerModal, setIsOpenWinnerModal] = useState(false);
  const [isOpenNoWinnerModal, setIsOpenNoWinnerModal] = useState(false);
  const [localPrizeId, setLocalPrizeId] = useState("");
  const [selectedScript, setSelectedScript] = useState(null);
  const [fontSize, setFontSize] = useState(
    Number(get(displaySettings, "font_size", DEFAULT_SLIDER))
  );
  const [localPrizeUnitValue, setLocalPrizeUnitValue] = useState("");
  const schedule = selectedSchedule && selectedSchedule.schedule;
  useEffect(() => {
    if (get(displaySettings, "font_size")) {
      setFontSize(get(displaySettings, "font_size", DEFAULT_SLIDER));
    }
  }, [displaySettings, schedule]);
  if (!schedule) {
    // TODO: show some content that helps users to select a task from left side menu
    return null;
  }
  const startDate = get(schedule, "start_date");
  const startTime = get(schedule, "start_time");
  const date = moment(startDate);
  if (startTime) {
    const [startHour, startMinutes] = startTime.split(":");
    date.hour(startHour);
    date.minute(startMinutes);
  }
  const isOverdue = !schedule.completed && moment().diff(date) > 0;
  const formattedDate = date.format("MMMM DD, YYYY");
  const formattedTime = date.format("h:mm a");
  const scheduleTheme = get(schedule, "theme", "");
  const isRollOverTheme = scheduleTheme === "rollover";
  const isPickYourPrizeTheme = scheduleTheme === "pick_your_prize";
  const isStandardGiveawayTheme = schedule.theme === "standard_giveaway";

  const scheduleFirstPrize = get(schedule, "prizes.0", {});
  const prizeCategory = get(scheduleFirstPrize, "category");
  const isCashVariablePrize = prizeCategory === "variable_cash";
  const isStandardCash = isStandardGiveawayTheme && isCashVariablePrize;
  const isPickGrandPrizeWinner = get(
    selectedSchedule,
    "schedule.contests_grand_prize_schedule"
  );

  const showUnitValueInput = isStandardCash && !schedule.completed;

  const isContest = schedule.contests_schedule;
  const winnerId = get(schedule, "winner_id");
  const isAddedWinner = !!winnerId;
  const winner = get(schedule, "winner", {});
  const selectedPrizeId = get(winner, "prizes.0.id");
  const isPrizeSelected = !!selectedPrizeId;
  const isCompleted = schedule.completed;
  const handleUpdateSchedule = (
    status,
    noWinner = false,
    notes = "",
    payload = {}
  ) => {
    let params = { id: schedule.id, status, ...payload };
    // save current script to schedule item.
    if (isContest) {
      params = {
        ...params,
        script_after: schedule.script_after,
        script_before: schedule.script_before,
      };
    } else {
      params = {
        ...params,
        script: schedule.script,
      };
    }

    if (noWinner) {
      params = {
        ...params,
        no_winner: true,
        no_winner_notes: notes,
      };
    }

    props.onUpdateJockSchedule(params, (updatedSchedule) => {
      // switch to new task. This task was selected previous
      if (props.selectedDoneSchedule) {
        setSelectedSchedule(props.selectedDoneSchedule);
        props.setSelectedDoneSchedule(null);
      } else {
        // keep current task if user just clicked on Submit task button
        setSelectedSchedule({
          ...selectedSchedule,
          schedule: {
            ...selectedSchedule.schedule,
            ...updatedSchedule,
            winner_id: status === "undo" ? null : updatedSchedule.winner_id,
          },
        });
      }
      if (noWinner) {
        setCurrentAction("noWinner");
      }
      props.fetchJockSchedules();
      if (noWinner) {
        ToastManager.show({
          title: intl.formatMessage({ id: "jock console > successfully" }),
          level: "success",
        });
      }
    });
  };
  const onAddNoteNoWinner = (status, noWinner = false, notes = "") => {
    if (!schedule.floating_contest) {
      handleUpdateSchedule(status, noWinner, notes);
    } else {
      onCreateFloatingPlay((response) => {
        handleUpdateSchedule(status, noWinner, notes, {
          id: response.id,
        });
      });
    }
  };
  const handleMarkWinLoss = (status) => () => {
    props.onMarkWinOrLoss({
      winner_id: winnerId,
      event_id: schedule.id,
      result: status ? "win" : "loss",
    });
  };

  const handlePickPrize = (prize) => () => {
    if (!isPrizeSelected) {
      setLocalPrizeId(prize.id);
    }
  };

  const handlePrizeUnitValue = (prize) => {
    try {
      const parsedValue = parseInt(prize);
      setLocalPrizeUnitValue(parsedValue);
    } catch (error) {
      console.log("Error in parsing local unit prize value");
    }
  };

  const handleMarkDone = () => {
    handleUpdateSchedule("completed");
  };

  const handleUndo = () => {
    handleUpdateSchedule("undo");
  };

  const handleSubmit = () => {
    props.onPickPrize({
      winner_id: winnerId,
      event_id: schedule.id,
      prize_id: localPrizeId,
    });
  };

  const handleViewNote = () => {
    setIsOpenNoWinnerModal(true);
  };

  const handleStandardMarkDone = () => {
    if (isStandardCash) {
      if (localPrizeUnitValue > scheduleFirstPrize.max_per_winner) {
        ToastManager.show({
          title: intl.formatMessage({ id: "jock console > error" }),
          message: intl.formatMessage({
            id: "jock console > error max value",
          }),
          level: "error",
        });
      } else {
        props.onAaddValueForStandardPrize(
          {
            winner_id: winnerId,
            event_id: schedule.id,
            amount: localPrizeUnitValue,
          },
          () => {
            handleMarkDone();
          }
        );
      }
    } else {
      handleMarkDone();
    }
  };

  const replaceTags = (script, type) => {
    const scheduleData = {
      ...schedule,
      winner_name: schedule.no_winner
        ? intl.formatMessage({ id: "jock console > no winner" })
        : schedule.winner_name
        ? schedule.winner_name
        : schedule.winner
        ? `${schedule.winner.first_name} ${schedule.winner.last_name}`
        : "",
    };
    const pNode = document.createElement("p");
    pNode.innerHTML = script;
    if (isPickGrandPrizeWinner) {
      type = `grand_${type}`;
    }
    configScriptTags[type].forEach((element) => {
      const tagNodes = pNode.querySelectorAll(
        `.tag[data-tag-replace-with="${element.tag_key}"]`
      );
      const tagValue = get(scheduleData, element.data_key);
      if (tagNodes) {
        tagNodes.forEach((node) => {
          if (tagValue) {
            const text = Array.isArray(tagValue)
              ? tagValue.join(", ")
              : tagValue;
            node.outerHTML = `<span class="replace-tag ${
              schedule.no_winner && element.tag_key ? "replace-tag-red" : ""
            }">${text}</span>`;
          } else {
            node.outerHTML = "";
          }
        });
      }
    });
    return pNode.innerHTML;
  };

  const getAddbuttonText = () => {
    if (isRollOverTheme) {
      return "jock console > add a player";
    }
    if (isPickGrandPrizeWinner) {
      return "jock console > pick a winner";
    }
    return "jock console > add a winner";
  };

  const getDoneButtonClasses = () => {
    if (isRollOverTheme) {
      return {
        [bem.e("btn-completed")]: !winner.contest_result,
        [bem.e("btn-done")]: !!winner.contest_result,
      };
    }
    if (isPickYourPrizeTheme) {
      return {
        [bem.e("btn-completed")]: !isPrizeSelected,
        [bem.e("btn-done")]: isPrizeSelected,
      };
    }
    if (isStandardCash) {
      return {
        [bem.e("btn-completed")]: !localPrizeUnitValue && !isPrizeSelected,
        [bem.e("btn-done")]: localPrizeUnitValue || isPrizeSelected,
      };
    }
    return bem.e("btn-done");
  };

  return (
    <div className={bem.b()}>
      <FontSlider
        fontSize={fontSize}
        setFontSize={(value) => setFontSize(value)}
        title={<FormattedMessage id="jock console > adjust text" />}
      />
      {!isContest ? (
        <div className={bem.e("jock-type")}>{schedule.type_name}</div>
      ) : scheduleTheme ? (
        <div className={bem.e("jock-type")}>
          {intl.formatMessage({
            id: `process > promotion > contest > ${replace(
              scheduleTheme,
              new RegExp("_", "g"),
              " "
            )}`,
          })}
        </div>
      ) : null}
      <div className={bem.e("jock-header")}>
        <h2 className={bem.e("jock-title")}>
          <TitleTooltip
            title={isContest ? schedule.order_title : schedule.title}
            width={370}
          />
        </h2>
        <div className={bem.e("jock-header-right")}>
          <div className={bem.e("deadline-time")}>
            {!isOverdue ? (
              <React.Fragment>
                <span className={bem.e("deadline-text")}>
                  <FormattedMessage id="jock console > deadline" />
                </span>
                <span className={bem.e("deadline-date")}>
                  <span>{formattedDate}</span> <strong>{formattedTime}</strong>
                </span>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <span className={bem.e("overdue-text")}>
                  <FormattedMessage id="jock console > overdue" />
                </span>
                <span className={bem.e("overdue-date")}>
                  {intl.formatMessage({
                    id: "jock console > scheduled to run",
                  })}
                  {` ${formattedDate} ${formattedTime}`}
                </span>
              </React.Fragment>
            )}
          </div>
          {isContest ? (
            <div className={bem.e("restrictions")}>
              <FormattedMessage id="jock console > restrictions" />
              <strong>
                {schedule.age_restrictions
                  ? `${schedule.age_restrictions}+`
                  : intl.formatMessage({ id: "jock console > none" })}
              </strong>
            </div>
          ) : null}
        </div>
      </div>
      {isContest ? (
        <div className={bem.e("jock-content")}>
          {!!schedule.script_before && !schedule.completed && (
            <JockCard
              copyText={replaceTags(schedule.script_before, "script_before")}
              content={replaceTags(schedule.script_before, "script_before")}
              editable={true}
              fontSize={fontSize}
              displaySettings={displaySettings}
              title={intl.formatMessage({
                id: "jock console > liner before selection",
              })}
              cardType="contest"
              isShowSearchIcon
              onClickSearch={(currentContent) => {
                setSelectedScript({
                  cardType: "contest",
                  title: intl.formatMessage({
                    id: "jock console > liner after selection",
                  }),
                  script: replaceTags(currentContent, "script_before"),
                });
              }}
            />
          )}
          {!!schedule.script_after &&
            schedule.completed &&
            (isPickYourPrizeTheme ? isPrizeSelected : true) && (
              <JockCard
                copyText={replaceTags(schedule.script_after, "script_after")}
                content={replaceTags(schedule.script_after, "script_after")}
                editable={true}
                cardType="contest"
                displaySettings={displaySettings}
                title={intl.formatMessage({
                  id: "jock console > liner after selection",
                })}
                fontSize={fontSize}
                isShowSearchIcon
                isShowTweetIcon={!schedule.no_winner}
                onClickSearch={(currentContent) => {
                  setSelectedScript({
                    cardType: "contest",
                    title: intl.formatMessage({
                      id: "jock console > liner after selection",
                    }),
                    script: replaceTags(currentContent, "script_after"),
                  });
                }}
              />
            )}
          {!!schedule.jock_instructions && (
            <JockCard
              title={intl.formatMessage({
                id: "jock console > jock instructions",
              })}
              displaySettings={displaySettings}
              copyText={schedule.jock_instructions}
              isShowSearchIcon
              fontSize={fontSize}
              onClickSearch={(currentContent) => {
                setSelectedScript({
                  cardType: "instructions",
                  title: intl.formatMessage({
                    id: "jock console > jock instructions",
                  }),
                  script: currentContent,
                });
              }}
              content={schedule.jock_instructions}
              editable={true}
            />
          )}
        </div>
      ) : (
        <div className={bem.e("jock-content")}>
          {!!schedule.script && (
            <JockCard
              copyText={replaceTags(schedule.script, "jock")}
              content={replaceTags(schedule.script, "jock")}
              editable={true}
              isLive
              displaySettings={displaySettings}
              title={schedule.type_name}
              fontSize={fontSize}
              isShowCopyIcon
              isShowSearchIcon
              onClickSearch={(currentContent) => {
                setSelectedScript({
                  cardType: "live",
                  title: schedule.type_name,
                  subtitle: "live",
                  script: replaceTags(currentContent, "jock"),
                });
              }}
            />
          )}
          {!!schedule.jock_instructions && (
            <JockCard
              title={intl.formatMessage({
                id: "jock console > jock instructions",
              })}
              displaySettings={displaySettings}
              fontSize={fontSize}
              copyText={schedule.jock_instructions}
              isShowSearchIcon
              onClickSearch={(currentContent) => {
                setSelectedScript({
                  cardType: "instructions",
                  title: intl.formatMessage({
                    id: "jock console > jock instructions",
                  }),
                  script: currentContent,
                });
              }}
              content={schedule.jock_instructions}
              editable={true}
            />
          )}
        </div>
      )}
      {isContest && !isAddedWinner && !isCompleted && (
        <div className={bem.e("buttons")}>
          <Button
            className={classNames(
              bem.e("btn-add-winner"),
              bem.e("btn-done"),
              "btn-radius"
            )}
            color=""
            onClick={() => {
              if (isPickGrandPrizeWinner) {
                onPickGrandPrizeWinner({
                  event_id: schedule.id,
                });
              } else {
                setIsOpenWinnerModal(true);
              }
            }}
          >
            <DoneIcon />
            <FormattedMessage id={getAddbuttonText()} />
          </Button>

          <PrivilegedComponent requires={[PRIVILEGES.PERFORM_TASKS]}>
            <Button
              className={classNames(bem.e("btn-no-winner"), "btn-radius")}
              color=""
              // onClick={handleMarkDone}
              onClick={() => {
                setIsOpenNoWinnerModal(true);
              }}
            >
              <NoWinnerIcon />
              <FormattedMessage id={"jock console > no winner"} />
            </Button>
          </PrivilegedComponent>
        </div>
      )}
      {isShowDoneButton && (
        <PrivilegedComponent requires={[PRIVILEGES.PERFORM_TASKS]}>
          <div className={bem.e("buttons")}>
            <Button
              className={classNames("btn-radius", getDoneButtonClasses())}
              color=""
              disabled={isDoneButtonDisabled}
              onClick={
                isStandardGiveawayTheme
                  ? handleStandardMarkDone
                  : handleMarkDone
              }
              id="button-submit-task"
            >
              <DoneIcon />
              <FormattedMessage id="jock console > button submit task" />
            </Button>
          </div>
        </PrivilegedComponent>
      )}
      {isShowPickPrizeButton && (
        <PrivilegedComponent requires={[PRIVILEGES.PERFORM_TASKS]}>
          <div className={bem.e("buttons")}>
            <Button
              className={classNames(
                "btn-radius",
                bem.e(localPrizeId ? "btn-done" : "btn-completed")
              )}
              color=""
              onClick={handleSubmit}
              id="button-submit-task-pick-prize"
            >
              <DoneIcon />
              <FormattedMessage id="jock console > button submit task" />
            </Button>
          </div>
        </PrivilegedComponent>
      )}
      {schedule.completed && (
        <Button
          className={classNames(bem.e("btn-completed"), "btn-radius")}
          color=""
        >
          <DoneIcon />
          <span className={bem.e("completed-button-text")}>
            <FormattedMessage id="jock console > completed button" />
            {schedule.no_winner && (
              <span className={bem.e("with-no-winner")}>
                <FormattedMessage id="jock console > with no winner" />
              </span>
            )}
          </span>
          <span className={bem.e("completed-time-text")}>
            {moment.unix(schedule.completed_ts).format("L hh:mma")}
          </span>
          <span className={bem.e("undo")} onClick={handleUndo}>
            <FormattedMessage id="jock console > undo" />
          </span>
          {schedule.no_winner ? (
            <span className={bem.e("view-note")} onClick={handleViewNote}>
              <FormattedMessage id="jock console > view note" />
            </span>
          ) : null}
        </Button>
      )}
      {isContest && (
        <React.Fragment>
          {!isAddedWinner && <PrizePackage schedule={schedule} />}
          {isAddedWinner && (
            <WinnerRecap
              loading={loading}
              handleMarkWinLoss={handleMarkWinLoss}
              handlePickPrize={handlePickPrize}
              handlePrizeUnitValue={handlePrizeUnitValue}
              localPrizeUnitValue={localPrizeUnitValue}
              selectedPrizeId={selectedPrizeId || localPrizeId}
              schedule={schedule}
              winner={winner}
              showUnitValueInput={showUnitValueInput}
            />
          )}
        </React.Fragment>
      )}

      <AddWinnerModal
        isOpen={isOpenWinnerModal}
        schedule={schedule}
        selectedSchedule={selectedSchedule}
        stationId={get(schedule, "station_id", "")}
        isRollOverTheme={isRollOverTheme}
        isStandardCash={isStandardCash}
        onToggle={() => setIsOpenWinnerModal(!isOpenWinnerModal)}
        onCancel={() => {
          setIsOpenWinnerModal(false);
        }}
        selectedDate={selectedDate}
        onSelectSchedule={props.onSelectSchedule}
        onSubmitted={() => props.fetchJockSchedules()}
      />

      <AddNoteNoWinnerModal
        isOpen={isOpenNoWinnerModal}
        onToggle={() => setIsOpenNoWinnerModal(!isOpenNoWinnerModal)}
        onCancel={() => {
          setIsOpenNoWinnerModal(false);
        }}
        value={get(schedule, "no_winner_notes", "")}
        onSubmitted={onAddNoteNoWinner}
      />

      {!!selectedScript && (
        <PreviewScriptModal
          isOpen={!!selectedScript}
          onToggle={() => setSelectedScript(null)}
          onCancel={() => setSelectedScript(null)}
          {...selectedScript}
        />
      )}
    </div>
  );
}
