export const FETCH_SYSTEM_FORMS_REQUEST = "FETCH_SYSTEM_FORMS_REQUEST";
export const FETCH_SYSTEM_FORMS_SUCCESS = "FETCH_SYSTEM_FORMS_SUCCESS";
export const FETCH_SYSTEM_FORMS_ERROR = "FETCH_SYSTEM_FORMS_ERROR";
export const FORGET_SYSTEM_FORMS = "FORGET_SYSTEM_FORMS";

export const UPDATE_SYSTEM_FORMS_REQUEST = "UPDATE_SYSTEM_FORMS_REQUEST";
export const UPDATE_SYSTEM_FORMS_SUCCESS = "UPDATE_SYSTEM_FORMS_SUCCESS";
export const UPDATE_SYSTEM_FORMS_ERROR = "UPDATE_SYSTEM_FORMS_ERROR";

export const DUPLICATE_SYSTEM_FORMS_REQUEST = "DUPLICATE_SYSTEM_FORMS_REQUEST";
export const DUPLICATE_SYSTEM_FORMS_SUCCESS = "DUPLICATE_SYSTEM_FORMS_SUCCESS";
export const DUPLICATE_SYSTEM_FORMS_ERROR = "DUPLICATE_SYSTEM_FORMS_ERROR";

export const DEFAULT_SYSTEM_FORMS_REQUEST = "DEFAULT_SYSTEM_FORMS_REQUEST";
export const DEFAULT_SYSTEM_FORMS_SUCCESS = "DEFAULT_SYSTEM_FORMS_SUCCESS";
export const DEFAULT_SYSTEM_FORMS_ERROR = "DEFAULT_SYSTEM_FORMS_ERROR";

export const FETCH_SYSTEM_FORM_REQUEST = "FETCH_SYSTEM_FORM_REQUEST";
export const FETCH_SYSTEM_FORM_SUCCESS = "FETCH_SYSTEM_FORM_SUCCESS";
export const FETCH_SYSTEM_FORM_ERROR = "FETCH_SYSTEM_FORM_ERROR";

export const FETCH_FULFILLMENT_FORM_TEMPLATE_REQUEST =
  "FETCH_FULFILLMENT_FORM_TEMPLATE_REQUEST";
export const FETCH_FULFILLMENT_FORM_TEMPLATE_SUCCESS =
  "FETCH_FULFILLMENT_FORM_TEMPLATE_SUCCESS";
export const FETCH_FULFILLMENT_FORM_TEMPLATE_ERROR =
  "FETCH_FULFILLMENT_FORM_TEMPLATE_ERROR";
export const FORGET_FULFILLMENT_FORM_TEMPLATE =
  "FORGET_FULFILLMENT_FORM_TEMPLATE";

export const GET_SYSTEM_FORMS_DEFAULT_REQUEST = "FETCH_SYSTEM_FORM_REQUEST";
export const GET_SYSTEM_FORMS_DEFAULT_SUCCESS =
  "GET_SYSTEM_FORMS_DEFAULT_SUCCESS";
export const GET_SYSTEM_FORMS_DEFAULT_ERROR = "GET_SYSTEM_FORMS_DEFAULT_ERROR";
