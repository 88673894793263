import React from "react";
import { get } from "lodash";
import { FaSpinner } from "react-icons/lib/fa";
import { Document, Page } from "react-pdf";
import { uuidv4 } from "utils/helpers";

const ReviewPdf = (props) => {
  const { bem, mediaField, index, intl, setUniqueId } = props;
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [isLoaded, setIsLoaded] = React.useState(false);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setUniqueId(uuidv4());
  }

  const url = get(mediaField, "value.path");
  const mediaWrapperId = `media-wrapper-${index}`;

  return (
    <>
      <div className={bem.e("media-name")}>{mediaField.media_name}</div>
      <div className={bem.e("slide-media-pdf-items")}>
        <div key={index} className={bem.e("media-wrapper")} id={mediaWrapperId}>
          <div className={bem.e("pdf-container")}>
            <Document
              file={url}
              loading={
                <span className="loading">
                  <FaSpinner size={20} className={"cr-icon-spin"} />
                </span>
              }
              onPassword={(callback, reason) => {
                function callbackProxy(password) {
                  // Cancel button handler
                  if (password === null) {
                    setIsLoaded(false);
                  }

                  callback(password);
                }
                switch (reason) {
                  case 1: {
                    const password = prompt(
                      intl.formatMessage({
                        id: "preview file > prompt password",
                      })
                    );
                    callbackProxy(password);
                    break;
                  }
                  case 2: {
                    const password = prompt(
                      intl.formatMessage({
                        id: "preview file > prompt invalid password",
                      })
                    );
                    callbackProxy(password);
                    break;
                  }
                  default:
                }
              }}
              onSourceSuccess={() => {
                setIsLoaded(true);
              }}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page
                pageNumber={pageNumber}
                width={516}
                renderAnnotationLayer={false}
              />
            </Document>
            {isLoaded && numPages && (
              <div className="page-controls">
                <button
                  type="button"
                  disabled={pageNumber === 1}
                  onClick={() => {
                    if (pageNumber > 1) setPageNumber(pageNumber - 1);
                  }}
                >
                  ‹
                </button>
                <span>
                  {intl.formatMessage(
                    { id: "preview file > page of total" },
                    {
                      page: pageNumber,
                      total: numPages,
                    }
                  )}
                </span>
                <button
                  type="button"
                  disabled={pageNumber === numPages}
                  onClick={() => {
                    if (pageNumber < numPages) setPageNumber(pageNumber + 1);
                  }}
                >
                  ›
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default ReviewPdf;
