import { compose, lifecycle, withHandlers } from "recompose";
import { connect } from "react-redux";
import React from "react";
import View from "./view";
import { injectIntl } from "react-intl";
import withState from "recompose/withState";
import {
  updateWidget,
  deleteWidgetLocal,
  addWidget,
  deleteWidget,
} from "store/actions/jocks";
import { get, omit } from "lodash";
import ToastManager from "components/ToastManager";
import { MdImportantDevices } from "react-icons/lib/md";
export default injectIntl(
  compose(
    connect(
      ({ jocks }) => ({
        widgets: get(jocks, "widgets.data.items", []),
      }),
      {
        updateWidget,
        deleteWidgetLocal,
        addWidget,
        deleteWidget,
      }
    ),
    withState("isOpenActiveConfirmation", "setIsOpenActiveConfirmation", false),
    withState("isOpenDeleteConfirmation", "setIsOpenDeleteConfirmation", false),
    withHandlers({
      onDeleteWidgetLocal: ({ jockConsole, deleteWidgetLocal }) => () => {
        deleteWidgetLocal(jockConsole);
      },
      onDelete: ({
        setIsOpenDeleteConfirmation,
        deleteWidgetLocal,
        jockConsole,
        deleteWidget,
        intl,
      }) => () => {
        if (jockConsole.id.indexOf("_new") === 0) {
          deleteWidgetLocal(jockConsole);
        } else {
          deleteWidget(jockConsole.id, () => {
            ToastManager.show({
              title: <MdImportantDevices />,
              message: intl.formatMessage({
                id: "jock console manager > deleted successfully",
              }),
              level: "success",
            });
            setIsOpenDeleteConfirmation(false);
          });
        }
      },
      onSubmit: ({
        updateWidget,
        jockConsole,
        intl,
        setIsEditForm,
        addWidget,
        setCurrentActiveConfirmation,
        setShouldBlockNavigation,
        station,
      }) => (values) => {

        if (values.id.indexOf("_new") === 0) {

          if (!get(station, 'key')) {
            ToastManager.show({
            title: <MdImportantDevices />,
            message: intl.formatMessage({
              id: "jock console manager > select station",
            }),
            level: "error",
          });
          } else {
            addWidget(
              {
                ...values,
                order: jockConsole.order,
                station_id: station.key,
              },
              (response) => {
                setShouldBlockNavigation(false);
                if (!response.is_enabled) {
                  setCurrentActiveConfirmation(response);
                }
                setIsEditForm(false);
                ToastManager.show({
                  title: <MdImportantDevices />,
                  message: intl.formatMessage({
                    id: "jock console manager > created successfully",
                  }),
                  level: "success",
                });
              }
            );
          }
          
        } else {
          const updatePrams = {
            ...omit(values, ["type"]),
            order: jockConsole.order,
            station_id: station.key,
          };
          if (!jockConsole.is_default) {
            updatePrams.type = values.type;
          }
          updateWidget(updatePrams, () => {
            setShouldBlockNavigation(false);
            setIsEditForm(false);
            ToastManager.show({
              title: <MdImportantDevices />,
              message: intl.formatMessage({
                id: "jock console manager > updated successfully",
              }),
              level: "success",
            });
          });
        }
      },
    }),
    lifecycle({
      componentDidMount() {
        const { jockConsole } = this.props;
        // show as edit when new widget
        if (!this.props.isEditForm && jockConsole.id.indexOf("_new") === 0) {
          this.props.setIsEditForm(true);
          // scroll to bottom of page
          const appDiv = document.getElementById("cr-app-main");
          appDiv.scrollTop = appDiv.scrollHeight;
        }
      },
    })
  )(View)
);
