import ProcessesPage from "./view";
import {get} from "lodash";
import { connect } from "react-redux";
import { compose, withState, withHandlers } from "recompose";
import { getProcesses, deleteProcess } from "store/actions/processes";
import { injectIntl } from "react-intl";

export default injectIntl(compose(
  connect(
    (state, props) => {
      return {
      filter: get(props, "match.params.filter") + get(props, "location.search",''),
      ...props
    }
    },
    {
      getProcesses,
      deleteProcess
    }
  ),
  withState("isDeleteModalOpen", "setIsDeleteModalOpen", false),
  withState("deleteProcessId", "setDeleteProcessId", null),
  withHandlers({
    openDeleteModal: ({ setIsDeleteModalOpen, setDeleteProcessId }) => id => {
      setIsDeleteModalOpen(true);
      setDeleteProcessId(id);
    },
    closeDeleteModal: ({ setIsDeleteModalOpen, setDeleteProcessId }) => () => {
      setIsDeleteModalOpen(false);
      setDeleteProcessId(null);
    }
  }),
  withHandlers({
    onDelete: ({ closeDeleteModal, deleteProcessId, deleteProcess }) => () => {
      deleteProcess(deleteProcessId);
      closeDeleteModal();
    }
  })
)(ProcessesPage));
