import React, { useEffect } from "react";
import bn from "utils/bemnames";
import {
  MenuIcon,
  EyeSmallIcon,
  EyeSmallDisableIcon,
} from "components/CustomIcons";
import classnames from "classnames";
import { FaClose } from "react-icons/lib/fa";
import { FormattedMessage } from "react-intl";
import { labelToNameField } from "utils/helpers";
import { FormGroup, Row, Col, Label } from "reactstrap";
import ToggleSwitch from "components/ToggleSwitch";
import Dropdown from "components/Dropdown";
import TextInput from "components/TextInput";
import { filter, find, get, includes, map, replace, isEqual } from "lodash";
import { countryList } from "utils/config";
import { Asterisk } from "components/Elements";
import {
  getPlaceholderForZipCode,
  getPlaceholderForState,
  getErrorForState,
  getStateOptions,
  getSelectedCountry,
} from "components/FormBuilder/helper";
import { getFieldName } from "../helper";
const bem = bn.create("form-builder-fields");

const GroupHomeAddress = ({
  intl,
  isNew,
  isView,
  field,
  value,
  errors,
  onChange,
  touched,
  onDelete,
  allowDelete,
  isFormBuilder,
  disabled,
  isShowRequiredToggle,
  isMasterListener,
  isSystemForm,
  onDeleteSubField,
  values,
  serverCountries,
  isFulfillment,
  isShowOffText,
  isRenderFieldNameFromLabel,
}) => {
  // UI for form builder
  if (isFormBuilder) {
    if (isNew) {
      const subFields = get(value, "sub_fields", []);
      useEffect(() => {
        // Detect if has any change for is_hidden -> we need to update data to formik. Because the is_hidden attribute depend on setup countries of current Server
        const savedFormValue = find(values.fields, (i) => i.key === value.key);
        const savedFormValueSubFields = get(
          savedFormValue,
          "sub_fields",
          []
        ).map((i) => ({
          key: i.key,
          is_hidden: i.is_hidden,
        }));
        const currentSubFields = subFields.map((i) => ({
          key: i.key,
          is_hidden: i.is_hidden,
        }));
        if (!isEqual(savedFormValueSubFields, currentSubFields)) {
          onChange(value);
        }
      }, []);
      return (
        <Row>
          <Col className="form-builder-field">
            <div className={bem.b()}>
              <div
                className={classnames(
                  bem.e("form-field-group"),
                  bem.e("form-field-group-line")
                )}
              >
                <Row>
                  <Col xs={12}>
                    <div className={bem.e("form-field-group-head")}>
                      <div
                        className={bem.e("form-field-group-head-single-left")}
                      >
                        <span className={bem.e("menu-icon")}>
                          <MenuIcon />
                        </span>
                        <div className={bem.e("group-label")}>
                          <FormattedMessage id="form builder > field home address" />
                        </div>
                      </div>
                    </div>
                    {subFields.length > 0 ? (
                      <div className={bem.e("form-field-sub-fields")}>
                        {map(subFields, (fieldItem, index) => {
                          let placeholder = intl.formatMessage({
                            id: `form builder > fields ${replace(
                              fieldItem.key,
                              /_/g,
                              " "
                            )}`,
                          });
                          if (fieldItem.key === "home_address_2") {
                            const homeAddress1 = find(
                              subFields,
                              (i) => i.key === "home_address_1"
                            );
                            if (!homeAddress1) {
                              placeholder = intl.formatMessage({
                                id: `form builder > fields home address 1`,
                              });
                            }
                          }
                          return (
                            <Row className={bem.e("sub-field")} key={index}>
                              <Col
                                xs={isShowRequiredToggle ? 9 : 12}
                                className={bem.e("col-reset")}
                              >
                                <div
                                  className={classnames(
                                    bem.e("input-label"),
                                    {
                                      [bem.e(
                                        "full-input-label"
                                      )]: !isShowRequiredToggle,
                                    },
                                    {
                                      [bem.e(
                                        "master-input-field"
                                      )]: isMasterListener,
                                    },
                                    {
                                      [bem.e(
                                        "system-input-field"
                                      )]: isSystemForm,
                                    },

                                    {
                                      [bem.e(
                                        "master-input-disable"
                                      )]: fieldItem.is_hidden,
                                    }
                                  )}
                                >
                                  <TextInput
                                    label={null}
                                    name="label"
                                    type="text"
                                    placeholder={placeholder}
                                    value={fieldItem.label}
                                    onChange={({ target }) => {
                                      const newSubFields = map(
                                        subFields,
                                        (i) => {
                                          if (i.key === fieldItem.key) {
                                            return {
                                              ...i,
                                              label: target.value,
                                            };
                                          }
                                          return i;
                                        }
                                      );
                                      onChange({
                                        ...value,
                                        sub_fields: newSubFields,
                                      });
                                    }}
                                    disabled={fieldItem.is_hidden || disabled}
                                    error={
                                      !get(
                                        value,
                                        `sub_fields.${index}.label`
                                      ) && get(errors, "sub_fields", "")
                                        ? get(errors, "sub_fields", "")
                                        : ""
                                    }
                                  />
                                  {fieldItem.show_hide_option && (
                                    <span
                                      className={bem.e("eye-icon")}
                                      onClick={() => {
                                        const newSubFields = map(
                                          subFields,
                                          (i) => {
                                            if (i.key === fieldItem.key) {
                                              return {
                                                ...i,
                                                is_hidden: !fieldItem.is_hidden,
                                              };
                                            }
                                            return i;
                                          }
                                        );
                                        onChange({
                                          ...value,
                                          sub_fields: newSubFields,
                                        });
                                      }}
                                    >
                                      {!fieldItem.is_hidden ? (
                                        <EyeSmallIcon
                                          color={"#795AFA"}
                                          width={24}
                                          height={16}
                                        />
                                      ) : (
                                        <EyeSmallDisableIcon
                                          width={24}
                                          height={16}
                                        />
                                      )}
                                    </span>
                                  )}
                                </div>
                              </Col>
                              {isShowRequiredToggle && (
                                <Col xs={3} className={bem.e("col-reset")}>
                                  <ToggleSwitch
                                    leftComponent={
                                      <span
                                        className={classnames({
                                          [bem.e("switch-active")]: !get(
                                            fieldItem,
                                            "is_required",
                                            false
                                          ),
                                        })}
                                      >
                                        {isShowOffText
                                          ? intl.formatMessage({
                                              id: "form builder > off",
                                            })
                                          : ""}
                                      </span>
                                    }
                                    rightComponent={
                                      <span
                                        className={classnames({
                                          [bem.e("switch-active")]: get(
                                            fieldItem,
                                            "is_required",
                                            false
                                          ),
                                        })}
                                      >
                                        {intl.formatMessage({
                                          id: "form builder > required",
                                        })}
                                      </span>
                                    }
                                    name="is_required"
                                    switchProps={{
                                      checked: !get(
                                        fieldItem,
                                        "is_required",
                                        false
                                      ),
                                      onChange: (checked) => {
                                        const newSubFields = map(
                                          subFields,
                                          (i) => {
                                            if (i.key === fieldItem.key) {
                                              return {
                                                ...i,
                                                is_required: !checked,
                                              };
                                            }
                                            return i;
                                          }
                                        );
                                        onChange({
                                          ...value,
                                          sub_fields: newSubFields,
                                        });
                                      },
                                      disabled: disabled,
                                      offColor: "#795AFA",
                                      onColor: "#E3ECF1",
                                      uncheckedIcon: false,
                                      checkedIcon: false,
                                      handleDiameter: 22,
                                      width: 50,
                                      height: 26,
                                    }}
                                  />
                                </Col>
                              )}
                              {fieldItem.show_delete_option && (
                                <div
                                  className={bem.e("sub-field-delete-icon")}
                                  onClick={() => onDeleteSubField(fieldItem)}
                                >
                                  <FaClose size="23" color="#C2D4E0" />
                                </div>
                              )}
                            </Row>
                          );
                        })}
                      </div>
                    ) : null}
                  </Col>
                </Row>
                {allowDelete && (
                  <div
                    className={classnames(
                      bem.e("form-field-group-head-right"),
                      bem.e("form-field-group-delete-icon")
                    )}
                    onClick={onDelete}
                  >
                    <FaClose size="23" color="#C2D4E0" />
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    // ui for form
  } else {
    const isShowRequired = find(field.sub_fields, (item) => item.is_required);
    const address1 = get(field, "sub_fields", []).find(
      (item) => item.key === "home_address_1"
    );
    const address2 = get(field, "sub_fields", []).find(
      (item) => item.key === "home_address_2"
    );
    const homeCity = get(field, "sub_fields", []).find(
      (item) => item.key === "home_city"
    );
    const homeCountry = get(field, "sub_fields", []).find(
      (item) => item.key === "home_country"
    );
    const homeStateProvince = get(field, "sub_fields", []).find(
      (item) => item.key === "home_state_province"
    );
    const homeZipPostalCode = get(field, "sub_fields", []).find(
      (item) => item.key === "home_zip_postal_code"
    );
    const homeCounty = get(field, "sub_fields", []).find(
      (item) => item.key === "home_county"
    );
    const fieldNameOfAddress1 = isRenderFieldNameFromLabel
      ? labelToNameField(address1 ? address1.label : "")
      : getFieldName(address1);
    const fieldNameOfAddress2 = isRenderFieldNameFromLabel
      ? labelToNameField(address2 ? address2.label : "")
      : getFieldName(address2);
    const fieldNameOfCity = isRenderFieldNameFromLabel
      ? labelToNameField(homeCity ? homeCity.label : "")
      : getFieldName(homeCity);
    const fieldNameOfCounty = isRenderFieldNameFromLabel
      ? labelToNameField(homeCounty ? homeCounty.label : "")
      : getFieldName(homeCounty);
    const fieldNameOfCountry = isRenderFieldNameFromLabel
      ? labelToNameField(homeCountry ? homeCountry.label : "")
      : getFieldName(homeCountry);

    const fieldNameOfHomeStateProvince = isRenderFieldNameFromLabel
      ? labelToNameField(homeStateProvince ? homeStateProvince.label : "")
      : getFieldName(homeStateProvince);
    const fieldNameOfHomeZipPostalCode = isRenderFieldNameFromLabel
      ? labelToNameField(homeZipPostalCode ? homeZipPostalCode.label : "")
      : getFieldName(homeZipPostalCode);
    const countryOptions = filter(countryList, (item) =>
      includes(serverCountries, item.value)
    );
    const selectedCountry = getSelectedCountry({
      values,
      fieldNameOfCountry,
      serverCountries,
    });
    const stateOptions = getStateOptions(selectedCountry);

    if (isView) {
      return (
        <Row>
          <Col>
            <div className={bem.b()}>
              <div className={bem.e("form-field-group-saved")}>
                <Label>{get(field, "label", "")}</Label>
                <span className="order-view-value">
                  {value ? (
                    value
                  ) : (
                    <FormattedMessage id="form builder > none" />
                  )}
                </span>
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    return (
      <Col xs={12}>
        <div className={classnames(bem.b(), "form-builder-edit-field")}>
          <Label>
            <FormattedMessage id="form builder > field home address" />
            {isShowRequired && <Asterisk>*</Asterisk>}
          </Label>
          <Row>
            {fieldNameOfAddress1 ? (
              <Col xs={12}>
                <FormGroup className={bem.e("sub-form-group")}>
                  <TextInput
                    label={null}
                    name={fieldNameOfAddress1}
                    maxCharacters={address1.max_characters}
                    type="text"
                    placeholder={
                      get(address1, "placeholder")
                        ? address1.placeholder
                        : intl.formatMessage({
                            id: "form builder > placeholder address line 1",
                          })
                    }
                    value={get(values, fieldNameOfAddress1, "")}
                    disabled={disabled}
                    onChange={({ target }) =>
                      onChange(target.value, { field: address1 })
                    }
                    error={
                      get(touched, fieldNameOfAddress1, false) &&
                      get(errors, fieldNameOfAddress1, "")
                        ? intl.formatMessage({
                            id: "validate > address 1 is required",
                          })
                        : ""
                    }
                  />
                </FormGroup>
              </Col>
            ) : null}
            {fieldNameOfAddress2 ? (
              <Col xs={12}>
                <FormGroup className={bem.e("form-group")}>
                  <TextInput
                    label={null}
                    name={fieldNameOfAddress2}
                    maxCharacters={address2.max_characters}
                    type="text"
                    placeholder={
                      isFulfillment && !address2.is_required
                        ? address2.placeholder
                          ? address2.placeholder
                          : intl.formatMessage({
                              id:
                                "form builder > placeholder address line 2 optional",
                            })
                        : address2.placeholder
                        ? address2.placeholder
                        : intl.formatMessage({
                            id: "form builder > placeholder address line 2",
                          })
                    }
                    value={get(values, fieldNameOfAddress2, "")}
                    disabled={disabled}
                    onChange={({ target }) =>
                      onChange(target.value, { field: address2 })
                    }
                    error={
                      get(touched, fieldNameOfAddress2, false) &&
                      get(errors, fieldNameOfAddress2, "")
                        ? intl.formatMessage({
                            id: "validate > address 2 is required",
                          })
                        : ""
                    }
                  />
                </FormGroup>
              </Col>
            ) : null}
            {fieldNameOfCounty ? (
              <Col xs={12}>
                <FormGroup className={bem.e("sub-form-group")}>
                  <TextInput
                    label={null}
                    name={fieldNameOfCounty}
                    maxCharacters={homeCounty.max_characters}
                    type="text"
                    placeholder={
                      get(homeCounty, "placeholder")
                        ? homeCounty.placeholder
                        : intl.formatMessage({
                            id: "form builder > placeholder county",
                          })
                    }
                    value={get(values, fieldNameOfCounty, "")}
                    disabled={disabled}
                    onChange={({ target }) =>
                      onChange(target.value, { field: homeCounty })
                    }
                    error={
                      get(touched, fieldNameOfCounty, false) &&
                      get(errors, fieldNameOfCounty, "")
                        ? intl.formatMessage({
                            id: "validate > county is required",
                          })
                        : ""
                    }
                  />
                </FormGroup>
              </Col>
            ) : null}
            {fieldNameOfCity ? (
              <Col xs={6}>
                <FormGroup className={bem.e("sub-form-group")}>
                  <TextInput
                    label={null}
                    name={fieldNameOfCity}
                    maxCharacters={homeCity.max_characters}
                    type="text"
                    placeholder={
                      get(homeCity, "placeholder")
                        ? homeCity.placeholder
                        : intl.formatMessage({
                            id: "form builder > placeholder city",
                          })
                    }
                    value={get(values, fieldNameOfCity, "")}
                    disabled={disabled}
                    onChange={({ target }) =>
                      onChange(target.value, { field: homeCity })
                    }
                    error={
                      get(touched, fieldNameOfCity, false) &&
                      get(errors, fieldNameOfCity, "")
                        ? intl.formatMessage({
                            id: "validate > city is required",
                          })
                        : ""
                    }
                  />
                </FormGroup>
              </Col>
            ) : null}

            {fieldNameOfHomeStateProvince ? (
              <Col xs={6}>
                <FormGroup className={bem.e("sub-form-group")}>
                  <Dropdown
                    label={null}
                    value={
                      find(
                        stateOptions,
                        (item) =>
                          item.value === values[fieldNameOfHomeStateProvince]
                      ) || null
                    }
                    placeholder={
                      get(homeStateProvince, "placeholder")
                        ? homeStateProvince.placeholder
                        : getPlaceholderForState({
                            serverCountries,
                            intl,
                          })
                    }
                    onChange={(selectedOption) => {
                      onChange(get(selectedOption, "value", ""), {
                        field: homeStateProvince,
                      });
                    }}
                    options={stateOptions}
                    name={fieldNameOfHomeStateProvince}
                    error={
                      get(touched, fieldNameOfHomeStateProvince, false) &&
                      get(errors, fieldNameOfHomeStateProvince, "")
                        ? getErrorForState({ serverCountries, intl })
                        : ""
                    }
                  />
                </FormGroup>
              </Col>
            ) : null}
            {fieldNameOfHomeZipPostalCode ? (
              <Col xs={6}>
                <FormGroup className={bem.e("sub-form-group")}>
                  <TextInput
                    label={null}
                    name={fieldNameOfHomeZipPostalCode}
                    maxCharacters={homeZipPostalCode.max_characters}
                    type="text"
                    placeholder={
                      get(homeZipPostalCode, "placeholder")
                        ? homeZipPostalCode.placeholder
                        : getPlaceholderForZipCode({
                            serverCountries,
                            intl,
                          })
                    }
                    value={get(values, fieldNameOfHomeZipPostalCode, "")}
                    disabled={disabled}
                    onChange={({ target }) =>
                      onChange(target.value, { field: homeZipPostalCode })
                    }
                    error={
                      get(touched, fieldNameOfHomeZipPostalCode, false) &&
                      get(errors, fieldNameOfHomeZipPostalCode, "")
                        ? intl.formatMessage({
                            id: "validate > zip code is required",
                          })
                        : ""
                    }
                  />
                </FormGroup>
              </Col>
            ) : null}
            {fieldNameOfCountry ? (
              <Col xs={6}>
                <FormGroup className={bem.e("sub-form-group")}>
                  <Dropdown
                    label={null}
                    value={
                      find(
                        countryOptions,
                        (item) => item.value === values[fieldNameOfCountry]
                      ) || null
                    }
                    placeholder={
                      get(homeCountry, "placeholder")
                        ? homeCountry.placeholder
                        : intl.formatMessage({
                            id: "form builder > placeholder country",
                          })
                    }
                    onChange={(selectedOption) => {
                      onChange(get(selectedOption, "value", ""), {
                        field: homeCountry,
                      });
                      onChange("", {
                        field: homeStateProvince,
                      });
                    }}
                    options={countryOptions}
                    name={fieldNameOfCountry}
                    disabled={disabled}
                    error={
                      get(touched, fieldNameOfCountry, false) &&
                      get(errors, fieldNameOfCountry, "")
                        ? intl.formatMessage({
                            id: "validate > country is required",
                          })
                        : ""
                    }
                  />
                </FormGroup>
              </Col>
            ) : null}
          </Row>
        </div>
      </Col>
    );
  }
};
GroupHomeAddress.defaultProps = {
  isNew: false,
  isFormBuilder: true,
  errors: {},
  touched: {},
  onChange: () => {},
  onDelete: () => {},
  allowDelete: true,
  disabled: false,
  isShowRequiredToggle: true,
  isMasterListener: false,
  isSystemForm: false,
  isShowOffText: true,
  isRenderFieldNameFromLabel: true,
};
export default GroupHomeAddress;
