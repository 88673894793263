import * as React from "react";
import classnames from "classnames";
import { get, includes, isEqual, map, first, find, has, filter } from "lodash";
import moment from "moment-timezone";
import TextInput from "components/TextInput";
import Dropdown from "components/Dropdown";
import { FormattedMessage } from "react-intl";
import { formatDisplayDate } from "utils/helpers";
import { Row, Col, Label } from "reactstrap";
import SelectStations from "components/SelectStations";
import NumberFormat from "react-number-format";
import {
  ShadowBox,
  Asterisk,
  SectionTitle,
  SectionFields,
  ChevronWrapper,
} from "components/Elements";
import { generateRotationChart } from "utils/helpers";
import { DownIcon, UpIcon } from "components/CustomIcons";
import { PhoneNumberFormat, RotationFormatSuffix } from "utils/config";
import bn from "utils/bemnames";
import ToggleSwitch from "components/ToggleSwitch";
import { getRoleIdByName } from "utils/helpers";
import ProductionManagerDropdown from "./productionManagerDropdown";
import TextareaAutosize from "react-textarea-autosize";
import ContinuityDropdown from "./continuityDropdown";

const bem = bn.create("order-details");
class ProductionDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldRefs: null,
      productionManagerOptions: [],
    };
  }
  componentWillReceiveProps(nextProps) {
    const {
      getRoleCandidates,
      process,
      template,
      user,
      auth,
      step,
    } = this.props;
    const isEnableEnterprise = get(auth, "info.production_multimarket", false);
    let select_production_manager = step.fields.find(
      (f) => f.field === "role_production_manager"
    );
    let select_production_manager_options = getRoleCandidates(
      select_production_manager,
      process,
      template,
      user
    );
    if (isEnableEnterprise) {
      // - The dropdown should show the Teams associated with the selected stations as well as any user who has assign_team privileges but without 'Hub Manager' role (this is for showing normal production manager but exclude Hub Managers)
      // - I would suggest we can use a different attribute to set the dropdown value instead of role_production_manager attribute if user selects a Team in the dropdown.
      // - We also need to modify how we are setting next_users, still_required_users and team attribute if a Team is selected under Production Manager dropdown.
      select_production_manager_options = filter(
        select_production_manager_options,
        (option) => {
          return !getRoleIdByName(
            "Hub Manager",
            get(option, "info.enterpriseRoles", [])
          );
        }
      );
      const managerOptions = [
        ...get(nextProps, "teamLeadMembers", []),
        ...(select_production_manager_options || []),
      ].map((item, index) => ({
        ...item,
        value: index, // add index array for can duplicate by id. Also if team lead and production manager are same then it should show both the name of team as well as person who is team lead as well as production manager.
        id: Number(item.value),
      }));
      select_production_manager_options = managerOptions;
    }
    if (
      !isEqual(
        select_production_manager_options,
        this.state.productionManagerOptions
      )
    ) {
      this.setState({
        productionManagerOptions: select_production_manager_options,
      });
    }
    if (JSON.stringify(nextProps.info) !== JSON.stringify(this.props.info)) {
      this.setState({ fieldRefs: false });
      return;
    }
    for (
      let i = 0;
      i < Object.keys(nextProps.process.data.fields).length;
      i++
    ) {
      let key = Object.keys(nextProps.process.data.fields)[i];
      let changed = false;
      if (
        !nextProps.process.data.fields[key] &&
        !this.props.process.data.fields[key]
      )
        continue;
      else if (
        !nextProps.process.data.fields[key] &&
        this.props.process.data.fields[key]
      )
        changed = true;
      else if (
        nextProps.process.data.fields[key] &&
        !this.props.process.data.fields[key]
      )
        changed = true;
      else if (
        !nextProps.process.data.fields[key].value &&
        !this.props.process.data.fields[key].value
      )
        continue;
      else if (
        !nextProps.process.data.fields[key].value &&
        this.props.process.data.fields[key].value
      )
        changed = true;
      else if (
        nextProps.process.data.fields[key].value &&
        !this.props.process.data.fields[key].value
      )
        changed = true;
      else if (
        nextProps.process.data.fields[key].value.toString() !==
        this.props.process.data.fields[key].value.toString()
      )
        changed = true;
      else if (
        !isEqual(
          get(nextProps, `process.data.fields.${key}.value`),
          get(this.props, `process.data.fields.${key}.value`)
        )
      ) {
        changed = true;
      }
      if (changed) {
        if (
          [
            "contract_stations",
            // "contract_stations_order_number",
            "contract_stations_order_rotation",
            "order_stations",
            "order_sold",
            "spots",
            "key",
            "role_sales_person",
            "role_production_manager",
            "role_continuity",
            "role_producer",
            "role_sales_person_2",
            "team_production_manager",
          ].indexOf(key) > -1
        ) {
          this.setState({ fieldRefs: false });
          break;
        }
      }
    }
  }

  getFieldRefs() {
    if (this.state.fieldRefs) return this.state.fieldRefs;
    let {
      process,
      template,
      user,
      step,
      valueChanged,
      getRoleCandidates,
      // auth,
    } = this.props;
    // const isEnableEnterprise = get(auth, "info.production_multimarket", false);

    step = template.steps.order_details;

    let rotation_chart = step.fields.find((f) => f.field === "rotation_chart");

    let spot_info = step.fields.find((f) => f.field === "spot_info");

    let contract_start_date = step.fields.find(
      (f) => f.field === "contract_start_date"
    );
    let contract_end_date = step.fields.find(
      (f) => f.field === "contract_end_date"
    );
    // let due_date = step.fields.find((f) => f.field === "due_date");
    let contract_status = step.fields.find(
      (f) => f.field === "contract_status"
    );
    let contract_stations = step.fields.find(
      (f) => f.field === "contract_stations"
    );
    let producer_assigns_vo = step.fields.find(
      (f) => f.field === "producer_assigns_vo"
    );

    let traffic_instructions = step.fields.find(
      (f) => f.field === "traffic_instructions"
    );

    let order_start_date = step.fields.find(
      (f) => f.field === "order_start_date"
    );
    let aircheck_requires_approval = step.fields.find(
      (f) => f.field === "aircheck_requires_approval"
    );

    let order_end_date = step.fields.find((f) => f.field === "order_end_date");
    let order_length = step.fields.find((f) => f.field === "order_length");
    let order_length_custom = step.fields.find(
      (f) => f.field === "order_length_custom"
    );
    let select_stations = step.fields.find((f) => f.field === "order_stations");
    let contract_stations_checked = [];
    if (get(this.props, "process.data.fields.contract_stations.value")) {
      contract_stations_checked = this.props.process.data.fields.contract_stations.value.map(
        (s) => ({ station_key: s })
      );
    }

    let estimation_number = step.fields.find(
      (f) => f.field === "estimation_number"
    );

    const is_coop_order = step.fields.find((f) => f.field === "is_coop_order");

    // const stationsData = get(auth, "info.stations", []);
    // const orderStationsData = get(
    //   this.props.process,
    //   "data.fields.order_stations.value",
    //   []
    // ).map((item) => find(stationsData, (i) => i.station_key === item));

    // const isAllStationsEnableAssignmentsQueue =
    //   filter(orderStationsData, (station) =>
    //     get(station, "production_settings.assignments_queue", false)
    //   ).length === orderStationsData.length;

    // must have order stations
    if (!this.props.process.data.fields.contract_stations) {
      let init_stations = []; // this.props.info.stations[0].station_key
      this.props.valueChanged(
        { field: "contract_stations" },
        this.props.process,
        this.props.template,
        this.props.user,
        { value: init_stations }
      );
    }

    // must have order stations
    if (!this.props.process.data.fields.order_stations) {
      // let init_stations = this.props.process.data.fields.contract_stations ? [...this.props.process.data.fields.contract_stations.value] : [this.props.info.stations[0].station_key]
      let init_stations = [];
      this.props.valueChanged(
        { field: "order_stations" },
        this.props.process,
        this.props.template,
        this.props.user,
        { value: init_stations }
      );
    }
    let select_sales_person = step.fields.find(
      (f) => f.field === "role_sales_person"
    );
    let select_sales_person_options = getRoleCandidates(
      select_sales_person,
      process,
      template,
      user
    );
    let select_sales_person_value;
    if (process.data.fields[select_sales_person.field])
      select_sales_person_value = {
        value: process.data.fields[select_sales_person.field].value.id,
        label: process.data.fields[select_sales_person.field].value.name,
        role: process.data.fields[select_sales_person.field].value.role,
      };
    else if (
      select_sales_person.default &&
      select_sales_person_options &&
      select_sales_person_options.length > 0
    ) {
      select_sales_person_value = select_sales_person_options.reduce(
        (e) => e.value === user.id
      );
      if (select_sales_person_value)
        valueChanged(select_sales_person, process, template, user, {
          value: {
            id: user.id,
            name: user.name,
            role: select_sales_person.default,
          },
        });
    }

    // continuity
    let select_continuity = step.fields.find(
      (f) => f.field === "role_continuity"
    );
    let select_continuity_options = getRoleCandidates(
      select_continuity,
      process,
      template,
      user
    );
    let select_continuity_value;
    if (process.data.fields[select_continuity.field]) {
      select_continuity_value = get(
        process,
        `data.fields.${select_continuity.field}.value.users`,
        []
      ).map((item) => ({
        label: item.name,
        value: item.id,
        role: item.role,
      }));
    }
    // else if (
    //   select_continuity_options &&
    //   select_continuity_options.length === 1 // only 1 choice in continuity dop-downs. System should pre-populate with that choice
    // ) {
    //   select_continuity_value = select_continuity_options[0];
    //   map(select_continuity_options, (e) => {
    //     if (
    //       get(e, "info.availability.upcoming_items") <
    //       get(select_continuity_value, "info.availability.upcoming_items")
    //     )
    //       select_continuity_value = e;
    //     return false;
    //   });
    //   if (select_continuity_value && !isEnableEnterprise)
    //     valueChanged(select_continuity, process, template, user, {
    //       value: {
    //         users: [
    //           {
    //             id: select_continuity_value.value,
    //             name: select_continuity_value.label,
    //             role: select_continuity_value.role,
    //           },
    //         ],
    //       },
    //     });
    // }
    // producer
    let select_producer = step.fields.find((f) => f.field === "role_producer");
    let select_producer_options = getRoleCandidates(
      select_producer,
      process,
      template,
      user
    );
    let select_producer_value;
    if (select_producer && process.data.fields[select_producer.field]) {
      select_producer_value = get(
        process,
        `data.fields.${select_producer.field}.value.users`,
        []
      ).map((item) => ({
        label: item.name,
        value: item.id,
        role: item.role,
      }));
    } else if (
      select_producer &&
      select_producer.default &&
      select_producer_options &&
      select_producer_options.length > 0
    ) {
      select_producer_value = select_producer_options[0];
      map(select_producer_options, (e) => {
        if (
          get(e, "info.availability.upcoming_items") <
          get(select_producer_value, "info.availability.upcoming_items")
        )
          select_producer_value = e;
        return false;
      });
      if (select_producer_value)
        valueChanged(select_producer, process, template, user, {
          value: {
            users: [
              {
                id: select_producer_value.value,
                name: select_producer_value.label,
                role: select_producer_value.role,
              },
            ],
          },
        });
    }

    let select_sales_person_2 = step.fields.find(
      (f) => f.field === "role_sales_person_2"
    );
    let select_sales_person_2_options = getRoleCandidates(
      select_sales_person_2,
      process,
      template,
      this.props.user
    );
    if (select_sales_person_2_options) {
      select_sales_person_2_options = select_sales_person_2_options.filter(
        (item) => Number(item.value) !== Number(this.props.user.id)
      );
    }
    let select_sales_person_2_value;
    if (
      process.data.fields[select_sales_person_2.field] &&
      get(process, `data.fields.${select_sales_person_2.field}.value.id`)
    )
      select_sales_person_2_value = {
        value: process.data.fields[select_sales_person_2.field].value.id,
        label: process.data.fields[select_sales_person_2.field].value.name,
        role: process.data.fields[select_sales_person_2.field].value.role,
      };
    // production manager
    let select_production_manager = step.fields.find(
      (f) => f.field === "role_production_manager"
    );
    let team_production_manager = step.fields.find(
      (f) => f.field === "team_production_manager"
    );
    let select_production_manager_value;
    if (
      process.data.fields[select_production_manager.field] &&
      get(process, `data.fields.${select_production_manager.field}.value.id`)
    ) {
      select_production_manager_value = {
        value: process.data.fields[select_production_manager.field].value.id,
        label: process.data.fields[select_production_manager.field].value.name,
        role: process.data.fields[select_production_manager.field].value.role,
      };
    }
    let select_team_production_manager_value;
    if (
      team_production_manager &&
      process.data.fields[team_production_manager.field] &&
      get(process, `data.fields.${team_production_manager.field}.value.id`)
    ) {
      select_team_production_manager_value = {
        value: process.data.fields[team_production_manager.field].value.id,
        label: process.data.fields[team_production_manager.field].value.name,
      };
    }
    let payload = {
      contract_start_date,
      contract_end_date,
      contract_status,
      contract_stations,
      order_start_date,
      order_end_date,
      order_length,
      order_length_custom,
      select_stations,
      select_sales_person,
      select_sales_person_options,
      select_sales_person_value,
      select_production_manager,
      select_production_manager_value,
      select_continuity,
      select_continuity_options,
      select_continuity_value,
      contract_stations_checked,
      traffic_instructions,
      aircheck_requires_approval,
      // due_date,
      select_producer,
      select_producer_options,
      select_producer_value,
      rotation_chart,
      spot_info,
      estimation_number,
      is_coop_order,
      select_sales_person_2,
      select_sales_person_2_options,
      select_sales_person_2_value,
      producer_assigns_vo,
      team_production_manager,
      select_team_production_manager_value,
    };
    this.setState({ fieldRefs: payload });
    return payload;
  }

  onValueChanged = (field, value) => {
    const { valueChanged, template, user, process } = this.props;
    valueChanged(field, process, template, user, { value });
    this.props.setIsFieldsChanged && this.props.setIsFieldsChanged(true);
  };

  orderLengthDisplay = () => {
    const { process, intl } = this.props;
    const orderLengthCustom = get(
      process,
      "data.fields.order_length_custom.value"
    );
    const orderLength = get(process, "data.fields.order_length.value");
    if (orderLengthCustom && orderLength) {
      const formatted = moment
        .utc(parseInt(orderLength) * 1000)
        .format("mm:ss");
      return formatted;
    }
    if (orderLength) {
      return `${orderLength} ${intl.formatMessage({
        id: "process > title seconds",
      })}`;
    }
    return intl.formatMessage({ id: "process > none" });
  };

  onSelectedManager = (selectedOption) => {
    const { setShouldBlockNavigation, auth } = this.props;
    const isEnableEnterprise = get(auth, "info.production_multimarket", false);
    setShouldBlockNavigation(true);
    let {
      select_production_manager,
      team_production_manager,
    } = this.getFieldRefs();
    if (selectedOption && selectedOption.isTeam && team_production_manager) {
      this.onValueChanged(team_production_manager, {
        id: selectedOption.id,
        name: selectedOption.label,
      });
      this.onValueChanged(select_production_manager, "");
    } else {
      const value = selectedOption
        ? {
            id: isEnableEnterprise ? selectedOption.id : selectedOption.value,
            name: selectedOption.label,
            role: selectedOption.role,
          }
        : "";
      this.onValueChanged(select_production_manager, value);
      if (isEnableEnterprise && team_production_manager) {
        this.onValueChanged(team_production_manager, "");
      }
    }
  };

  renderEdit() {
    const {
      process,
      template,
      user,
      validationState,
      multipleStationSelectionChanged,
      step,
      auth,
      setShouldBlockNavigation,
    } = this.props;
    let {
      order_start_date,
      order_end_date,
      order_length,
      select_stations,
      select_continuity,
      select_continuity_options,
      select_continuity_value,
      select_production_manager,
      select_production_manager_value,
      traffic_instructions,
      rotation_chart,
      spot_info,
      order_length_custom,
      estimation_number,
      is_coop_order,
      select_sales_person_2,
      select_sales_person_2_options,
      select_sales_person_2_value,
      producer_assigns_vo,
      team_production_manager,
      select_team_production_manager_value,
    } = this.getFieldRefs();
    const productionManagerOptions = this.state.productionManagerOptions;
    const isEnableEnterprise = get(auth, "info.production_multimarket", false);
    const isWOTrafficEnabled = get(user, "enable_wo_traffic", false);

    let valueOrEmpty = this.valueOrEmpty.bind(this);
    const spotInfoData = get(process, "data.fields.spot_info.value", []);
    let spotData = find(
      spotInfoData,
      (i) => i.key === get(process, "data.fields.key.value", "")
    );
    if (!spotData && !get(process, "data.fields.key.value", "")) {
      spotData = first(spotInfoData);
    }
    const isSpecOrder = get(process, "data.fields.order_sold.value") === false;
    const isProductionNeeded =
      get(spotData, "contract_status") === "Production Needed";

    let has_order_stations =
      !!process.data.fields[select_stations.field] &&
      process.data.fields[select_stations.field].value.length > 0;
    const isSelectProductionManager = find(
      spotInfoData,
      (spot) => get(spot, "contract_status") === "Production Needed"
    );
    const isContinuityOrDubber =
      step.key === "continuity" || step.key === "dub_script";
    const isTrafficView = isContinuityOrDubber;
    const isDisableOrderDates = !has_order_stations;
    const stationsData = get(auth, "info.stations", []);
    const orderStationsData = get(
      process,
      "data.fields.order_stations.value",
      []
    )
      .map((item) => {
        const station = find(stationsData, (i) => i.station_key === item);
        return station;
      })
      .filter((station) => station);

    const isAllStationsEnableAssignmentsQueue =
      filter(orderStationsData, (station) =>
        get(station, "production_settings.assignments_queue", false)
      ).length === orderStationsData.length;

    const isShowProductionManager =
      !isAllStationsEnableAssignmentsQueue &&
      isSelectProductionManager &&
      !isContinuityOrDubber;
    const isAllStationsEnableContinuityQueue =
      filter(orderStationsData, (station) =>
        get(station, "production_settings.continuity_queue", false)
      ).length === orderStationsData.length;
    const isShowContinuity =
      !isEnableEnterprise &&
      (!isAllStationsEnableContinuityQueue ||
        get(process, "data.fields.continuity_status.value") === "completed") &&
      !isSpecOrder;
    const isShowCOOP =
      !includes(["continuity"], step.key) &&
      includes(["basic_production", "production_provided"], template.key);
    const continuityUsers = get(
      process,
      "data.fields.role_continuity.value.users",
      []
    );
    const stationsOrderNumber = get(
      process,
      "data.fields.contract_stations_order_number_array.value",
      []
    );
    const isShowStationOrderNumber = includes(["continuity"], step.key);

    const onGetProductionSettings = (selectedStations) => {
      if (selectedStations.length === 1) {
        this.props.getProductionSettings(
          {
            station_id: selectedStations[0],
          },
          (data) => {
            if (!data) return;
            if (producer_assigns_vo) {
              this.onValueChanged(
                producer_assigns_vo,
                Boolean(data.producer_assigns_vo)
              );
            }
            if (order_length) {
              this.onValueChanged(order_length, data.default_spot_length);
            }
            if (order_length_custom) {
              this.onValueChanged(
                order_length_custom,
                Boolean(data.custom_spot_length)
              );
            }

            const newSpotInfo = get(
              process,
              "data.fields.spot_info.value",
              []
            ).map((spot) => ({
              ...spot,
              recording_requires_approval: Boolean(data.recording_approval),
              script_requires_approval: Boolean(data.script_approval),
              writer_sends_script_to_client: Boolean(
                data.writer_sends_script_to_client
              ),
              producer_sends_spot_to_client: Boolean(
                data.producer_sends_spot_to_client
              ),
              default_words_per_min: data.default_words_per_min
                ? Number(data.default_words_per_min)
                : null,
              default_words_per_min_b: data.default_words_per_min
                ? Number(data.default_words_per_min)
                : null,
              producer_assigns_vo: Boolean(data.producer_assigns_vo),
              order_length: data.default_spot_length || "",
              order_length_custom: Boolean(data.custom_spot_length),
            }));
            if (spot_info) {
              this.onValueChanged(spot_info, newSpotInfo);
            }
          }
        );
      }
    };

    return (
      <ShadowBox className={bem.e("box-production-details")}>
        <SectionTitle
          className={bem.e("section-title")}
          onClick={() => {
            if (this.props.isCollapse) {
              this.props.setIsOpenDetail(!this.props.isOpenDetail);
            }
          }}
        >
          <FormattedMessage id="process > title production details" />
          {this.props.isCollapse && (
            <ChevronWrapper>
              {this.props.isOpenDetail ? <UpIcon /> : <DownIcon />}
            </ChevronWrapper>
          )}
        </SectionTitle>
        <SectionFields
          className={classnames(
            {
              hidden: !this.props.isOpenDetail,
            },
            bem.e("section-production-details-fields")
          )}
        >
          <Row>
            <Col className={bem.e("col-stations-field")}>
              {isContinuityOrDubber ? (
                <React.Fragment>
                  <Label className={bem.e("production-type-label")}>
                    {select_stations.title}
                  </Label>
                  <span className={bem.e("order-view-value")}>
                    {orderStationsData.length > 0
                      ? map(orderStationsData, (stationData) => {
                          let stationArr = [];
                          if (get(stationData, "call_letters", ""))
                            stationArr.push(stationData.call_letters);
                          if (get(stationData, "name", ""))
                            stationArr.push(stationData.name);
                          return (
                            <span className={bem.e("entity-row")}>
                              {stationArr.join(" - ")}
                            </span>
                          );
                        })
                      : this.props.intl.formatMessage({
                          id: "process > none",
                        })}
                  </span>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <SelectStations
                    contract={false}
                    checkboxes={true}
                    validationState={validationState}
                    item={select_stations}
                    {...select_stations.props}
                    checked={process.data.fields[select_stations.field]}
                    onChange={(value) => {
                      multipleStationSelectionChanged(
                        select_stations,
                        process,
                        template,
                        user,
                        value
                      );
                      onGetProductionSettings(value);
                      if (select_continuity) {
                        this.onValueChanged(select_continuity, ""); // reset
                      }
                      if (select_production_manager) {
                        this.onValueChanged(select_production_manager, ""); // reset
                      }
                      if (team_production_manager && isEnableEnterprise) {
                        this.onValueChanged(team_production_manager, ""); // reset
                      }
                      if (select_sales_person_2) {
                        this.onValueChanged(select_sales_person_2, ""); // reset
                      }

                      this.props.onValueTimezoneChanged(value);
                      this.props.setIsFieldsChanged(true);
                      if (rotation_chart) {
                        const rotationChart = generateRotationChart(
                          process,
                          value,
                          get(process, "data.fields.spot_info.value", [])
                        );
                        this.onValueChanged(rotation_chart, rotationChart);
                      }

                      setShouldBlockNavigation(true);
                    }}
                    template={template}
                    isFilterByServerConfig={true}
                    process={process}
                  />
                </React.Fragment>
              )}
            </Col>
            {isShowStationOrderNumber && (
              <Col className={bem.e("col-view-field")} xs={6}>
                <Label className={bem.e("order-view-label")}>
                  <FormattedMessage id="process > field order number" />
                </Label>
                <span className={bem.e("order-view-value")}>
                  {orderStationsData.length > 0
                    ? map(orderStationsData, (stationData) => {
                        const orderNumber = find(
                          stationsOrderNumber,
                          (item) => item.station === stationData.station_key
                        );
                        return (
                          <span className={bem.e("entity-row")}>
                            {get(orderNumber, "number", "")}
                          </span>
                        );
                      })
                    : this.props.intl.formatMessage({ id: "process > none" })}
                </span>
              </Col>
            )}
          </Row>

          {/* <Row>
            {isProductionNeeded ? (
              <React.Fragment>
                <Col
                  xs={6}
                  className={classnames(bem.e("col-production-type-field"), {
                    "con-dub": isContinuityOrDubber,
                  })}
                >
                  <Label className={bem.e("production-type-label")}>
                    {contract_status.title}
                  </Label>
                  <span className={bem.e("order-view-value")}>
                    {valueOrEmpty(contract_status)}
                  </span>
                </Col>
                <Col xs={6} className={bem.e("col-field")}>
                  {isContinuityOrDubber ? (
                    <React.Fragment>
                      <Label className={bem.e("production-type-label")}>
                        <FormattedMessage id="process > field spot length" />
                      </Label>
                      <span className={bem.e("order-view-value")}>
                        {this.orderLengthDisplay()}
                      </span>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <DropdownLength
                        isClearable={true}
                        name="order_length"
                        {...order_length.props}
                        label={
                          <span>
                            {order_length.title}
                            {order_length.mandatory && <Asterisk>*</Asterisk>}
                          </span>
                        }
                        placeholder={this.props.intl.formatMessage({
                          id: "process > placeholder select",
                        })}
                        value={
                          process.data.fields[order_length.field]
                            ? {
                                label:
                                  process.data.fields[order_length.field].value,
                                value:
                                  process.data.fields[order_length.field].value,
                              }
                            : ""
                        }
                        onChange={(value, isCustom) => {
                          this.onValueChanged(order_length, value);
                          this.onValueChanged(order_length_custom, isCustom);
                          const spotInfo = get(
                            process,
                            "data.fields.spot_info.value",
                            []
                          );
                          let newSpotInfo = [];

                          forEach(spotInfo, (spot) => {
                            newSpotInfo.push({
                              ...spot,
                              length1: "00",
                              length2: "00",
                            });
                          });
                          this.onValueChanged(spot_info, newSpotInfo);
                          setShouldBlockNavigation(true);
                        }}
                        isCustom={get(
                          process,
                          `data.fields.${order_length_custom.field}.value`,
                          false
                        )}
                        disabled={!has_order_stations}
                        error={
                          validationState[order_length.field] &&
                          validationState[order_length.field].validation_error
                        }
                      />
                    </React.Fragment>
                  )}
                </Col>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Col
                  xs={6}
                  className={classnames(
                    bem.e("col-field"),
                    bem.e("col-contract-status-field")
                  )}
                >
                  {step.key === "dub_script" ? (
                    <React.Fragment>
                      <Label className={bem.e("production-type-label")}>
                        {contract_status.title}
                      </Label>
                      <span className={bem.e("order-view-value")}>
                        {valueOrEmpty(contract_status)}
                      </span>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Dropdown
                        name="contract_status"
                        {...contract_status.props}
                        label={
                          <span>
                            {contract_status.title}
                            {contract_status.mandatory && (
                              <Asterisk>*</Asterisk>
                            )}
                          </span>
                        }
                        placeholder={this.props.intl.formatMessage({
                          id: "process > placeholder select",
                        })}
                        value={
                          process.data.fields[contract_status.field]
                            ? {
                                label:
                                  process.data.fields[contract_status.field]
                                    .value,
                                value:
                                  process.data.fields[contract_status.field]
                                    .value,
                              }
                            : ""
                        }
                        onChange={(event) => {
                          this.onValueChanged(
                            contract_status,
                            get(event, "value", "")
                          );

                          if (
                            get(event, "value", "") ===
                            "Production Provided - Needs tags"
                          ) {
                            const spotInfo = get(
                              process,
                              "data.fields.spot_info.value",
                              []
                            );
                            let newSpotInfo = [];

                            forEach(spotInfo, (spot) => {
                              newSpotInfo.push({
                                ...spot,
                                spot_type: "Standard",
                              });
                            });

                            this.onValueChanged(spot_info, newSpotInfo);
                            setShouldBlockNavigation(true);
                          }
                        }}
                        disabled={!has_order_stations}
                        error={
                          validationState[contract_status.field] &&
                          validationState[contract_status.field]
                            .validation_error
                        }
                      />
                    </React.Fragment>
                  )}
                </Col>
                <Col>
                  <React.Fragment>
                    <Label className={bem.e("production-type-label")}>
                      {estimation_number.title}
                    </Label>
                    <span className={bem.e("order-view-value")}>
                      {valueOrEmpty(estimation_number)}
                    </span>
                  </React.Fragment>
                </Col>
              </React.Fragment>
            )}
          </Row> */}
          {isProductionNeeded && isWOTrafficEnabled && (
            <Row>
              <Col
                xs={6}
                className={classnames(
                  bem.e("col-field"),
                  bem.e("col-contract-status-field")
                )}
              >
                <React.Fragment>
                  <Label className={bem.e("production-type-label")}>
                    {estimation_number.title}
                  </Label>
                  <span className={bem.e("order-view-value")}>
                    {valueOrEmpty(estimation_number)}
                  </span>
                </React.Fragment>
              </Col>
            </Row>
          )}
          {!isSpecOrder ? (
            <>
              <Row>
                <Col xs={6} className={bem.e("col-field")}>
                  {isContinuityOrDubber ? (
                    <React.Fragment>
                      <Label className={bem.e("production-type-label")}>
                        {order_start_date.title}
                      </Label>
                      <span className={bem.e("order-view-value")}>
                        {formatDisplayDate(valueOrEmpty(order_start_date))}
                      </span>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <TextInput
                        label={
                          <span>
                            {order_start_date.title}
                            {order_start_date.mandatory && (
                              <Asterisk>*</Asterisk>
                            )}
                          </span>
                        }
                        type="date"
                        allowShowIcon
                        disabled={isDisableOrderDates}
                        min={get(
                          this.props,
                          "process.data.fields.contract_start_date.value",
                          moment().format("YYYY-MM-DD")
                        )}
                        placeholder={this.props.intl.formatMessage({
                          id: "process > placeholder enter date",
                        })}
                        max={get(
                          this.props,
                          "process.data.fields.contract_end_date.value"
                        )}
                        value={
                          process.data.fields[order_start_date.field]
                            ? process.data.fields[order_start_date.field].value
                            : ""
                        }
                        onChange={(value) => {
                          setShouldBlockNavigation(true);
                          this.props.onValueTimezoneChanged(
                            get(
                              this.props,
                              `process.data.fields.${select_stations.field}.value`,
                              []
                            )
                          );
                          this.onValueChanged(order_start_date, value);
                          // clear order end date if order start date if after
                          if (
                            value &&
                            get(
                              process,
                              `data.fields.${order_end_date.field}.value`
                            )
                          ) {
                            const isAfterEndDate = moment(value).isAfter(
                              process.data.fields[order_end_date.field].value
                            );
                            if (isAfterEndDate) {
                              this.onValueChanged(order_end_date, "");
                            }
                          }
                        }}
                        error={
                          validationState[order_start_date.field] &&
                          validationState[order_start_date.field]
                            .validation_error
                        }
                      />
                    </React.Fragment>
                  )}
                </Col>
                <Col xs={6} className={bem.e("col-field")}>
                  {isContinuityOrDubber ? (
                    <React.Fragment>
                      <Label className={bem.e("production-type-label")}>
                        {order_end_date.title}
                      </Label>
                      <span className={bem.e("order-view-value")}>
                        {formatDisplayDate(valueOrEmpty(order_end_date))}
                      </span>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <TextInput
                        label={
                          <span>
                            {order_end_date.title}
                            {order_end_date.mandatory && <Asterisk>*</Asterisk>}
                          </span>
                        }
                        type="date"
                        allowShowIcon
                        min={
                          get(
                            this.props,
                            "process.data.fields.order_start_date.value"
                          )
                            ? moment(
                                get(
                                  this.props,
                                  "process.data.fields.order_start_date.value"
                                )
                              ).format("YYYY-MM-DD")
                            : get(
                                this.props,
                                "process.data.fields.contract_start_date.value"
                              )
                            ? moment(
                                get(
                                  this.props,
                                  "process.data.fields.contract_start_date.value"
                                )
                              ).format("YYYY-MM-DD")
                            : new Date()
                        }
                        placeholder={this.props.intl.formatMessage({
                          id: "process > placeholder enter date",
                        })}
                        max={
                          get(
                            this.props,
                            "process.data.fields.contract_end_date.value"
                          )
                            ? moment(
                                get(
                                  this.props,
                                  "process.data.fields.contract_end_date.value"
                                )
                              ).format("YYYY-MM-DD")
                            : null
                        }
                        openToDate={
                          get(
                            this.props,
                            "process.data.fields.order_end_date.value"
                          )
                            ? new Date(
                                moment(
                                  get(
                                    this.props,
                                    "process.data.fields.order_end_date.value" // open to entered end date if available
                                  )
                                ).format("YYYY/MM/DD")
                              )
                            : get(
                                this.props,
                                "process.data.fields.order_start_date.value"
                              )
                            ? new Date(
                                moment(
                                  get(
                                    this.props,
                                    "process.data.fields.order_start_date.value" // open to entered start date if available
                                  )
                                ).format("YYYY/MM/DD")
                              )
                            : new Date()
                        } // open to today
                        value={
                          process.data.fields[order_end_date.field]
                            ? process.data.fields[order_end_date.field].value
                            : ""
                        }
                        onChange={(value) => {
                          setShouldBlockNavigation(true);
                          this.onValueChanged(order_end_date, value);
                        }}
                        disabled={isDisableOrderDates}
                        error={
                          validationState[order_end_date.field] &&
                          validationState[order_end_date.field].validation_error
                        }
                      />
                    </React.Fragment>
                  )}
                </Col>
              </Row>
              {/* <Row>
                <Col className={bem.e("col-field")}>
                  {!isContinuityOrDubber ? (
                    <>
                      <TextInput
                        disabled={isDisableOrderDates}
                        label={<span>{due_date.title}</span>}
                        excludeDates={
                          get(process, "data.fields[due_date.field].value")
                            ? [
                                get(
                                  process,
                                  "data.fields[due_date.field].value"
                                ),
                              ]
                            : []
                        }
                        min={moment().format("YYYY-MM-DD")}
                        type="date"
                        value={
                          process.data.fields[due_date.field]
                            ? process.data.fields[due_date.field].value
                            : ""
                        }
                        placeholder={this.props.intl.formatMessage({
                          id: "process > placeholder select date",
                        })}
                        onChange={(value) => {
                          setShouldBlockNavigation(true);
                          this.onValueChanged(due_date, value);
                        }}
                      />
                    </>
                  ) : (
                    <div>
                      <Label className={bem.e("production-type-label")}>
                        {due_date.title}
                      </Label>
                      <span className={bem.e("order-view-value")}>
                        {process.data.fields[due_date.field]
                          ? formatDisplayDate(
                              process.data.fields[due_date.field].value
                            )
                          : ""}
                      </span>
                    </div>
                  )}
                  <div className="alert-danger">
                    {validationState[due_date.field] &&
                      validationState[due_date.field].validation_error}
                  </div>
                </Col>
                <Col className={bem.e("col-field")}></Col>
              </Row> */}
            </>
          ) : null}
          <Row>
            {isShowProductionManager ? (
              <Col xs={6} className={bem.e("col-field")}>
                <ProductionManagerDropdown
                  bem={bem}
                  isEnableEnterprise={isEnableEnterprise}
                  selectedTeamProductionManager={
                    select_team_production_manager_value
                  }
                  selectedProductionManager={select_production_manager_value}
                  team_production_manager_field={team_production_manager}
                  select_production_manager_field={select_production_manager}
                  valueOrEmpty={valueOrEmpty}
                  options={productionManagerOptions}
                  onSelectedManager={this.onSelectedManager}
                  disabled={!has_order_stations}
                  intl={this.props.intl}
                  loading={this.props.teamLeadMembersLoading}
                  error={
                    isEnableEnterprise
                      ? (validationState[select_production_manager.field] &&
                          validationState[select_production_manager.field]
                            .validation_error) ||
                        (team_production_manager &&
                          validationState[team_production_manager.field] &&
                          validationState[team_production_manager.field]
                            .validation_error)
                      : validationState[select_production_manager.field] &&
                        validationState[select_production_manager.field]
                          .validation_error
                  }
                />
              </Col>
            ) : isContinuityOrDubber && isSelectProductionManager ? (
              <Col xs={6} className={bem.e("col-field")}>
                <Label className={bem.e("production-type-label")}>
                  {select_production_manager.title}
                </Label>
                <span className={bem.e("order-view-value")}>
                  {isEnableEnterprise
                    ? select_team_production_manager_value
                      ? valueOrEmpty(team_production_manager)
                      : valueOrEmpty(select_production_manager)
                    : valueOrEmpty(select_production_manager)}
                </span>
              </Col>
            ) : null
            // (
            //   <Col xs={6} className={bem.e("col-field")}>
            //     {isContinuityOrDubber ? (
            //       <React.Fragment>
            //         <Label>{order_length.title}</Label>
            //         <span className={bem.e("order-view-value")}>
            //           {this.orderLengthDisplay()}
            //         </span>
            //       </React.Fragment>
            //     ) : (
            //       <DropdownLength
            //         isClearable={true}
            //         name="order_length"
            //         {...order_length.props}
            //         label={
            //           <span>
            //             {order_length.title}
            //             {order_length.mandatory && <Asterisk>*</Asterisk>}
            //           </span>
            //         }
            //         placeholder={this.props.intl.formatMessage({
            //           id: "process > placeholder select",
            //         })}
            //         value={
            //           process.data.fields[order_length.field]
            //             ? {
            //                 label:
            //                   process.data.fields[order_length.field].value,
            //                 value:
            //                   process.data.fields[order_length.field].value,
            //               }
            //             : ""
            //         }
            //         isCustom={get(
            //           process,
            //           `data.fields.${order_length_custom.field}.value`,
            //           false
            //         )}
            //         onChange={(value, isCustom) => {
            //           setShouldBlockNavigation(true);
            //           this.onValueChanged(order_length, value);
            //           this.onValueChanged(order_length_custom, isCustom);
            //         }}
            //         disabled={!has_order_stations}
            //         error={
            //           validationState[order_length.field] &&
            //           validationState[order_length.field].validation_error
            //         }
            //       />
            //     )}
            //   </Col>
            // )
            }
            {isShowContinuity && (
              <Col xs={6} className={bem.e("col-field")}>
                {isContinuityOrDubber ? (
                  <React.Fragment>
                    <Label className={bem.e("production-type-label")}>
                      {select_continuity.title}
                    </Label>
                    <span className={bem.e("order-view-value")}>
                      {continuityUsers.length > 0
                        ? map(continuityUsers, (item) => item.name).join(",")
                        : this.props.intl.formatMessage({
                            id: "process > none",
                          })}
                    </span>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <ContinuityDropdown
                      intl={this.props.intl}
                      bem={bem}
                      onChange={(selectedOption) => {
                        setShouldBlockNavigation(true);
                        let selectContinuity = "";
                        if (selectedOption) {
                          selectContinuity = {
                            users: [
                              {
                                id: selectedOption.value,
                                name: selectedOption.label,
                                role: selectedOption.role,
                              },
                            ],
                          };
                        }
                        this.onValueChanged(
                          select_continuity,
                          selectContinuity
                        );
                      }}
                      selectedContinuity={select_continuity_value}
                      isEnableEnterprise={isEnableEnterprise}
                      options={select_continuity_options}
                      selectContinuityField={select_continuity}
                      disabled={!has_order_stations}
                      error={
                        validationState[select_continuity.field] &&
                        validationState[select_continuity.field]
                          .validation_error
                      }
                    />
                    {/* <Dropdown
                      isClearable={true}
                      name="select_continuity"
                      {...select_continuity.props}
                      placeholder={this.props.intl.formatMessage({
                        id: "process > placeholder select",
                      })}
                      options={select_continuity_options}
                      label={
                        <span>
                          {select_continuity.title}
                          {select_continuity.mandatory &&
                            !isEnableEnterprise && <Asterisk>*</Asterisk>}
                        </span>
                      }
                      value={
                        get(select_continuity_value, "length") > 0
                          ? first(select_continuity_value)
                          : ""
                      }
                      onChange={(selectedOption) => {
                        setShouldBlockNavigation(true);
                        let selectContinuity = "";
                        if (selectedOption) {
                          selectContinuity = {
                            users: [
                              {
                                id: selectedOption.value,
                                name: selectedOption.label,
                                role: selectedOption.role,
                              },
                            ],
                          };
                        }
                        this.onValueChanged(
                          select_continuity,
                          selectContinuity
                        );
                      }}
                      disabled={!has_order_stations}
                      error={
                        validationState[select_continuity.field] &&
                        validationState[select_continuity.field]
                          .validation_error
                      }
                    /> */}
                  </React.Fragment>
                )}
              </Col>
            )}
            {/* {isSpecOrder && (
              <Col xs={6} className={bem.e("col-field")}>
                {isContinuityOrDubber ? (
                  <React.Fragment>
                    <Label className={bem.e("production-type-label")}>
                      {due_date.title}
                    </Label>
                    <span className={bem.e("order-view-value")}>
                      {formatDisplayDate(valueOrEmpty(due_date))}
                    </span>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <TextInput
                      label={
                        <span>
                          {due_date.title}
                          {due_date.mandatory && <Asterisk>*</Asterisk>}
                        </span>
                      }
                      type="date"
                      min={new Date()}
                      placeholder={this.props.intl.formatMessage({
                        id: "process > placeholder enter date",
                      })}
                      openToDate={new Date()} // open to today
                      value={
                        process.data.fields[due_date.field]
                          ? process.data.fields[due_date.field].value
                          : ""
                      }
                      onChange={(value) => {
                        setShouldBlockNavigation(true);
                        this.onValueChanged(due_date, value);
                        this.onValueChanged(order_start_date, value); // apply to order_start_date for get deadline
                      }}
                      disabled={!has_order_stations}
                      error={
                        validationState[due_date.field] &&
                        validationState[due_date.field].validation_error
                      }
                    />
                  </React.Fragment>
                )}
              </Col>
            )} */}
          </Row>
          <Row>
            <Col xs={6} className={bem.e("col-field")}>
              {isContinuityOrDubber ? (
                <React.Fragment>
                  <Label>{select_sales_person_2.title}</Label>
                  <span className={bem.e("order-view-value")}>
                    {valueOrEmpty(select_sales_person_2)}
                  </span>
                </React.Fragment>
              ) : (
                <Dropdown
                  isClearable={true}
                  name="select_sales_person_2"
                  {...select_sales_person_2.props}
                  placeholder={this.props.intl.formatMessage({
                    id: "process > placeholder select",
                  })}
                  options={select_sales_person_2_options}
                  label={
                    <span>
                      {select_sales_person_2.title}
                      {select_sales_person_2.mandatory && (
                        <Asterisk>*</Asterisk>
                      )}
                    </span>
                  }
                  value={
                    process.data.fields[select_sales_person_2.field]
                      ? select_sales_person_2_value
                      : ""
                  }
                  onChange={(selectedOption) => {
                    const value = selectedOption
                      ? {
                          id: selectedOption.value,
                          name: selectedOption.label,
                          role: selectedOption.role,
                        }
                      : null;
                    this.onValueChanged(select_sales_person_2, value);
                  }}
                  disabled={!has_order_stations}
                  error={
                    validationState[select_sales_person_2.field] &&
                    validationState[select_sales_person_2.field]
                      .validation_error
                  }
                />
              )}
            </Col>
            {isShowCOOP ? (
              <Col xs={6} className={bem.e("col-field")}>
                <ToggleSwitch
                  leftComponent={this.props.intl.formatMessage({
                    id: "process > field co-op order",
                  })}
                  rightComponent={this.props.intl.formatMessage({
                    id: "process > off",
                  })}
                  containerClassName={bem.e("coop-order-switch")}
                  name="is_coop_order"
                  switchProps={{
                    checked: !get(
                      process,
                      `data.fields.${is_coop_order.field}.value`,
                      false
                    ),
                    onChange: (checked) => {
                      if (step.key === "dub_script") return;
                      this.onValueChanged(is_coop_order, !checked);
                      this.props.setShouldBlockNavigation(true);
                    },
                    disabled: !has_order_stations,
                    offColor: "#795AFA",
                    onColor: "#C2D4E0",
                    uncheckedIcon: false,
                    checkedIcon: false,
                    boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.282)",
                    height: 26.44,
                    width: 48.48,
                    handleDiameter: 22.92,
                  }}
                />
              </Col>
            ) : null}
          </Row>

          <Row>
            <Col className={bem.e("col-field")}>
              {isTrafficView ? (
                <React.Fragment>
                  <Label>{traffic_instructions.title}</Label>
                  <span className={bem.e("order-view-value")}>
                    {valueOrEmpty(traffic_instructions)}
                  </span>
                </React.Fragment>
              ) : (
                <TextInput
                  label={
                    <span>
                      {traffic_instructions.title}
                      {traffic_instructions.mandatory && <Asterisk>*</Asterisk>}
                    </span>
                  }
                  type="textarea"
                  placeholder={this.props.intl.formatMessage({
                    id: "process > placeholder type here",
                  })}
                  value={
                    process.data.fields[traffic_instructions.field]
                      ? process.data.fields[traffic_instructions.field].value
                      : ""
                  }
                  onChange={({ target }) => {
                    setShouldBlockNavigation(true);
                    this.onValueChanged(traffic_instructions, target.value);
                  }}
                  disabled={!has_order_stations}
                  error={
                    validationState[traffic_instructions.field] &&
                    validationState[traffic_instructions.field].validation_error
                  }
                />
              )}
            </Col>
          </Row>
        </SectionFields>
      </ShadowBox>
    );
  }

  formattedValueOrEmpty(value, item) {
    if (get(item, "formatted")) {
      const formatted = get(item, "formatted");
      switch (formatted) {
        case "number":
          return (
            <NumberFormat
              prefix={get(item, "prefix", null)}
              value={value}
              displayType={"text"}
              thousandSeparator={true}
            />
          );
        case "phone":
          return (
            <NumberFormat
              value={value}
              displayType={"text"}
              format={PhoneNumberFormat}
            />
          );
        case "percentage":
          return (
            <NumberFormat
              value={value}
              displayType={"text"}
              suffix={get(item, "suffix", RotationFormatSuffix)}
            />
          );
        default:
          break;
      }
    }
    return value;
  }
  renderFieldsView = () => {
    let { process, step, auth, template } = this.props;
    let {
      order_start_date,
      order_end_date,
      select_production_manager,
      traffic_instructions,
      // contract_status,
      // due_date,
      select_stations,
      select_continuity,
      estimation_number,
      select_sales_person_2,
      team_production_manager,
    } = this.getFieldRefs();
    let valueOrEmpty = this.valueOrEmpty.bind(this);
    const spotInfoData = get(process, "data.fields.spot_info.value", []);
    const isSelectProductionManager = find(
      spotInfoData,
      (spot) => get(spot, "contract_status") === "Production Needed"
    );
    const isSpecOrder = get(process, "data.fields.order_sold.value") === false;
    const isEnableEnterprise = get(auth, "info.production_multimarket", false);
    const stationsData = get(auth, "info.stations", []);
    const stationsOrderNumber = get(
      process,
      "data.fields.contract_stations_order_number_array.value",
      []
    );
    const teamProductionManager = get(
      process,
      "data.fields.team_production_manager.value"
    );
    const orderStationsData = get(
      process,
      "data.fields.order_stations.value",
      []
    )
      .map((item) => {
        const station = find(stationsData, (i) => i.station_key === item);
        return station;
      })
      .filter((station) => station);
    const isAllStationsEnableAssignmentsQueue =
      filter(orderStationsData, (station) =>
        get(station, "production_settings.assignments_queue", false)
      ).length === orderStationsData.length;

    const isShowProductionManager =
      !isAllStationsEnableAssignmentsQueue && isSelectProductionManager;
    const isAllStationsEnableContinuityQueue =
      filter(orderStationsData, (station) =>
        get(station, "production_settings.continuity_queue", false)
      ).length === orderStationsData.length;
    const isShowContinuity =
      !isEnableEnterprise &&
      (!isAllStationsEnableContinuityQueue ||
        get(process, "data.fields.continuity_status.value") === "completed") &&
      !isSpecOrder;
    const isWOTrafficEnabled = get(auth, "info.enable_wo_traffic", false);
    const isShowCOOP =
      !includes(["continuity"], step.key) &&
      includes(["basic_production", "production_provided"], template.key);
    const isShowStationOrderNumber = includes(["continuity"], step.key);

    return (
      <React.Fragment>
        {!isEnableEnterprise && (
          <Row>
            <Col className={bem.e("col-stations-field")} xs={6}>
              <Label className={bem.e("order-view-label")}>
                {select_stations.title}
              </Label>
              <span className={bem.e("order-view-value")}>
                {orderStationsData.length > 0
                  ? map(orderStationsData, (stationData) => {
                      let stationArr = [];
                      if (get(stationData, "call_letters", ""))
                        stationArr.push(stationData.call_letters);
                      if (get(stationData, "name", ""))
                        stationArr.push(stationData.name);
                      return (
                        <span className={bem.e("entity-row")}>
                          {stationArr.join(" - ")}
                        </span>
                      );
                    })
                  : this.props.intl.formatMessage({ id: "process > none" })}
              </span>
            </Col>
            {isShowStationOrderNumber && (
              <Col className={bem.e("col-view-field")} xs={6}>
                <Label className={bem.e("order-view-label")}>
                  <FormattedMessage id="process > field order number" />
                </Label>
                <span className={bem.e("order-view-value")}>
                  {orderStationsData.length > 0
                    ? map(orderStationsData, (stationData) => {
                        const orderNumber = find(
                          stationsOrderNumber,
                          (item) =>
                            item.station === get(stationData, "station_key", "")
                        );
                        return (
                          <span className={bem.e("entity-row")}>
                            {get(orderNumber, "number", "")}
                          </span>
                        );
                      })
                    : this.props.intl.formatMessage({ id: "process > none" })}
                </span>
              </Col>
            )}
          </Row>
        )}
        {/* <Row>
          <Col className={bem.e("col-view-field")}>
            <Label className={bem.e("order-view-label")}>
              {contract_status.title}
            </Label>
            <span className={bem.e("order-view-value")}>
              {valueOrEmpty(contract_status) === "Production Needed" &&
              isContinuity ? (
                <FormattedMessage id="process > title needs production" />
              ) : (
                valueOrEmpty(contract_status)
              )}
            </span>
          </Col>
          {isProductionNeeded && (
            <Col className={bem.e("col-view-field")}>
              <Label className={bem.e("order-view-label")}>
                <FormattedMessage id="process > field spot length" />
              </Label>
              <span className={bem.e("order-view-value")}>
                {this.orderLengthDisplay()}
              </span>
            </Col>
          )}
          {!isProductionNeeded && isWOTrafficEnabled && (
            <Col className={bem.e("col-view-field")}>
              <Label className={bem.e("order-view-label")}>
                <FormattedMessage id="process > field estimation number" />
              </Label>
              <span className={bem.e("order-view-value")}>
                {valueOrEmpty(estimation_number)}
              </span>
            </Col>
          )}
        </Row> */}
        {isWOTrafficEnabled && (
          <Row>
            <Col className={bem.e("col-view-field")}>
              <Label className={bem.e("order-view-label")}>
                <FormattedMessage id="process > field estimation number" />
              </Label>
              <span className={bem.e("order-view-value")}>
                {valueOrEmpty(estimation_number)}
              </span>
            </Col>
          </Row>
        )}
        {!isSpecOrder && (
          <>
            <Row>
              <Col className={bem.e("col-view-field")}>
                <Label className={bem.e("order-view-label")}>
                  {order_start_date.title}
                </Label>
                <span className={bem.e("order-view-value")}>
                  {formatDisplayDate(valueOrEmpty(order_start_date))}
                </span>
              </Col>
              <Col className={bem.e("col-view-field")}>
                <Label className={bem.e("order-view-label")}>
                  {order_end_date.title}
                </Label>
                <span className={bem.e("order-view-value")}>
                  {formatDisplayDate(valueOrEmpty(order_end_date))}
                </span>
              </Col>
            </Row>
            {/* <Row>
              <Col xs={6} className={bem.e("col-view-field")}>
                <Label>{due_date.title}</Label>
                <span className={bem.e("order-view-value")}>
                  {formatDisplayDate(valueOrEmpty(due_date))}
                </span>
              </Col>
              <Col xs={6} className={bem.e("col-view-field")}></Col>
            </Row> */}
          </>
        )}
        <Row>
          {isShowProductionManager ? (
            <Col className={bem.e("col-view-field")}>
              <Label className={bem.e("order-view-label")}>
                {select_production_manager.title}
              </Label>
              <span className={bem.e("order-view-value")}>
                {isEnableEnterprise
                  ? teamProductionManager
                    ? valueOrEmpty(team_production_manager)
                    : valueOrEmpty(select_production_manager)
                  : valueOrEmpty(select_production_manager)}
              </span>
            </Col>
          ) : null}
          {isShowContinuity && (
            <Col className={bem.e("col-view-field")}>
              <Label className={bem.e("order-view-label")}>
                {select_continuity.title}
              </Label>
              <span className={bem.e("order-view-value")}>
                {get(
                  process,
                  "data.fields.role_continuity.value.users.length",
                  0
                ) > 0
                  ? map(
                      get(
                        process,
                        "data.fields.role_continuity.value.users",
                        []
                      ),
                      (item, index) => item.name
                    ).join(",")
                  : this.props.intl.formatMessage({ id: "process > none" })}
              </span>
            </Col>
          )}
          {/* <Col className={bem.e("col-view-field")}></Col> */}
          {/* {isSpecOrder && (
            <Col className={bem.e("col-view-field")}>
              <Label className={bem.e("order-view-label")}>
                {due_date.title}
              </Label>
              <span className={bem.e("order-view-value")}>
                {formatDisplayDate(valueOrEmpty(due_date))}
              </span>
            </Col>
          )} */}
        </Row>
        <Row>
          <Col xs={6} className={bem.e("col-view-field")}>
            <Label>{select_sales_person_2.title}</Label>
            <span className={bem.e("order-view-value")}>
              {valueOrEmpty(select_sales_person_2)}
            </span>
          </Col>
          {isShowCOOP && !includes(["continuity"], step.key) ? (
            <Col xs={6} className={bem.e("col-view-field")}>
              <Label>{` `}</Label>
              <ToggleSwitch
                leftComponent={this.props.intl.formatMessage({
                  id: "process > field co-op order",
                })}
                rightComponent={this.props.intl.formatMessage({
                  id: "process > off",
                })}
                name="is_coop_order"
                switchProps={{
                  checked: !get(
                    process,
                    `data.fields.is_coop_order.value`,
                    false
                  ),
                  onChange: () => {},
                  disabled: true,
                  offColor: "#795AFA",
                  onColor: "#C2D4E0",
                  uncheckedIcon: false,
                  checkedIcon: false,
                  boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.282)",
                  height: 26.44,
                  width: 48.48,
                  handleDiameter: 22.92,
                }}
              />
            </Col>
          ) : null}
        </Row>
        <Row>
          <Col className={bem.e("col-view-field")}>
            <Label className={bem.e("order-view-label")}>
              {traffic_instructions.title}
            </Label>
            {get(process, `data.fields.${traffic_instructions.field}.value`) ? (
              <TextareaAutosize
                value={get(
                  process,
                  `data.fields.${traffic_instructions.field}.value`
                )}
                className="text-area-view"
                disabled
              />
            ) : (
              <span className={bem.e("order-view-value")}>
                <FormattedMessage id="process > none" />
              </span>
            )}
          </Col>
        </Row>
      </React.Fragment>
    );
  };
  renderFieldsViewForContinuity = () => {
    let { process } = this.props;
    let {
      order_start_date,
      order_end_date,
      traffic_instructions,
      // select_stations,
      // contract_status,
    } = this.getFieldRefs();
    let valueOrEmpty = this.valueOrEmpty.bind(this);
    // let isShowEditProductionType = isContinuity && mode === "edit";
    // let has_order_stations =
    //   !!process.data.fields[select_stations.field] &&
    //   process.data.fields[select_stations.field].value.length > 0;
    return (
      <React.Fragment>
        {/* <Row>
          {isShowEditProductionType ? (
            <Col className={classnames(bem.e("col-contract-status-field"))}>
              <Dropdown
                isClearable={true}
                name="contract_status"
                {...contract_status.props}
                label={
                  <span className={bem.e("order-view-label")}>
                    {contract_status.title}
                    {contract_status.mandatory && <Asterisk>*</Asterisk>}
                  </span>
                }
                placeholder={this.props.intl.formatMessage({
                  id: "process > placeholder select",
                })}
                value={
                  process.data.fields[contract_status.field]
                    ? {
                        label: process.data.fields[contract_status.field].value,
                        value: process.data.fields[contract_status.field].value,
                      }
                    : ""
                }
                onChange={(event) => {
                  this.onValueChanged(contract_status, get(event, "value", ""));
                }}
                disabled={!has_order_stations}
              />
              <div className="alert-danger">
                {validationState[contract_status.field] &&
                  validationState[contract_status.field].validation_error}
              </div>
            </Col>
          ) : (
            <Col className={bem.e("col-view-field")}>
              <Label className={bem.e("order-view-label")}>
                {contract_status.title}
              </Label>
              <span className={bem.e("order-view-value")}>
                {valueOrEmpty(contract_status)}
              </span>
            </Col>
          )}
        </Row> */}
        <Row>
          <Col className={bem.e("col-view-field")}>
            <Label className={bem.e("order-view-label")}>
              {order_start_date.title}
            </Label>
            <span className={bem.e("order-view-value")}>
              {formatDisplayDate(valueOrEmpty(order_start_date))}
            </span>
          </Col>
          <Col className={bem.e("col-view-field")}>
            <Label className={bem.e("order-view-label")}>
              {order_end_date.title}
            </Label>
            <span className={bem.e("order-view-value")}>
              {formatDisplayDate(valueOrEmpty(order_end_date))}
            </span>
          </Col>
        </Row>
        {/* <Row>
          <Col className={bem.e("col-view-field")}>
            <Label className={bem.e("order-view-label")}>
              <FormattedMessage id="process > field spot length" />
            </Label>
            <span className={bem.e("order-view-value")}>
              {this.orderLengthDisplay()}
            </span>
          </Col>
        </Row> */}
        <Row>
          <Col className={bem.e("col-view-field")}>
            <Label className={bem.e("order-view-label")}>
              {traffic_instructions.title}
            </Label>
            {get(process, `data.fields.${traffic_instructions.field}.value`) ? (
              <TextareaAutosize
                value={get(
                  process,
                  `data.fields.${traffic_instructions.field}.value`
                )}
                className="text-area-view"
                disabled
              />
            ) : (
              <span className={bem.e("order-view-value")}>
                <FormattedMessage id="process > none" />
              </span>
            )}
          </Col>
        </Row>
      </React.Fragment>
    );
  };
  valueOrEmpty(item) {
    let { process, intl } = this.props;
    let value = null;
    if (has(process, `data.fields.${item.field}.value.name`)) {
      value = process.data.fields[item.field].value.name || "";
    } else if (get(process, `data.fields.${item.field}.value`)) {
      value = process.data.fields[item.field].value;
    } else {
      return intl.formatMessage({ id: "process > none" });
    }
    return this.formattedValueOrEmpty(value, item);
  }

  renderView() {
    let { process } = this.props;
    const spotInfoData = get(process, "data.fields.spot_info.value", []);
    // const spotData = find(
    //   spotInfoData,
    //   (i) => i.key === get(process, "data.fields.key.value", "")
    // );
    // const isProductionNeeded =
    //   get(spotData, "contract_status") === "Production Needed";
    // const isProductionComplete =
    //   get(spotData, "contract_status") === "Production Complete";
    // const isProductionProvidedNeededTags =
    //   get(spotData, "contract_status") === "Production Provided - Needs tags";
    return (
      <ShadowBox className={bem.e("box-production-details")}>
        <SectionTitle
          className={bem.e("section-title")}
          onClick={() => {
            if (this.props.isCollapse) {
              this.props.setIsOpenDetail(!this.props.isOpenDetail);
            }
          }}
        >
          <FormattedMessage id="process > title production details" />
          {this.props.isCollapse && (
            <ChevronWrapper>
              {this.props.isOpenDetail ? <UpIcon /> : <DownIcon />}
            </ChevronWrapper>
          )}
        </SectionTitle>
        <SectionFields
          className={classnames(
            {
              hidden: !this.props.isOpenDetail,
            },
            bem.e("section-view-production-details-fields")
          )}
        >
          {this.renderFieldsView()}
          {/* {isProductionNeeded && this.renderFieldsView()} */}
          {/* {includes(["order_details", "dub_script"], step.key) &&
            isProductionComplete &&
            this.renderFieldsView()}
          {includes(["order_details", "dub_script"], step.key) &&
            isProductionProvidedNeededTags &&
            this.renderFieldsView()}
          {includes(["continuity"], step.key) &&
            (isProductionComplete || isProductionProvidedNeededTags) &&
            this.renderFieldsViewForContinuity()} */}
        </SectionFields>
      </ShadowBox>
    );
  }

  render() {
    let { isContinuity } = this.props;
    let isEdit =
      get(this.props, "mode") === "edit" && !get(this.props, "isOnlyViewMode");
    return (
      <div
        className={classnames({
          [bem.e("production-details-continuity")]: isContinuity,
        })}
      >
        {isEdit ? this.renderEdit() : this.renderView()}
      </div>
    );
  }
}
ProductionDetails.defaultProps = {
  isCollapse: true,
  mode: "edit",
};
export default ProductionDetails;
