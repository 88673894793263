import * as Types from "../types/clocks";
import { get, uniqBy } from "lodash";

const initialState = {
  clocks: {
    data: [],
    error: null,
    loading: false,
    is_dirty: false,
  },
  createClock: {
    is_added: false,
    data: null,
    error: null,
    loading: false,
  },
  viewedClocks: {},
  deleteClock: {
    is_deleted: false,
    data: null,
    error: null,
    loading: false,
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_CLOCKS_REQUEST: {
      return {
        ...state,
        clocks: {
          data: get(state, "clocks.data"),
          error: null,
          loading: true,
          is_dirty: false,
        },
      };
    }

    case Types.FETCH_CLOCKS_SUCCESS: {
      return {
        ...state,
        clocks: {
          data: action.payload,
          error: null,
          loading: false,
          is_dirty: false,
        },
      };
    }

    case Types.FETCH_CLOCKS_ERROR: {
      return {
        ...state,
        clocks: {
          data: null,
          error: action.payload,
          loading: false,
          is_dirty: false,
        },
      };
    }

    case Types.FETCH_MORE_CLOCKS_REQUEST: {
      return {
        ...state,
        clocks: {
          ...state.clocks,
          lazyLoad: true,
        },
      };
    }
    case Types.FETCH_MORE_CLOCKS_SUCCESS: {
      const { data: newData, ...rest } = action.payload;

      return {
        ...state,

        clocks: {
          ...state.clocks,
          data: {
            data: uniqBy(
              [...get(state, "clocks.data.data", []), ...newData],
              "id"
            ),
            ...rest,
          },
          lazyLoad: false,
        },
      };
    }
    case Types.FETCH_MORE_CLOCKS_ERROR: {
      return {
        ...state,
        clocks: {
          ...state.clocks,
          lazyLoad: false,
        },
      };
    }

    case Types.CREATE_CLOCK_REQUEST: {
      return {
        ...state,
        createClock: {
          is_added: false,
          loading: true,
          error: null,
        },
      };
    }

    case Types.CREATE_CLOCK_SUCCESS: {
      return {
        clocks: {
          ...get(state, "clocks"),
          is_dirty: true,
        },
        createClock: {
          is_added: true,
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    }

    case Types.CREATE_CLOCK_ERROR: {
      return {
        ...state,
        createClock: {
          is_added: false,
          loading: false,
          error: action.payload,
        },
      };
    }

    case Types.FETCH_CLOCK_REQUEST: {
      const existingClocks = get(state, "viewedClocks", {});
      const { id } = action.payload;

      return {
        ...state,
        clocks: {
          ...get(state, "clocks"),
          is_dirty: true,
        },
        viewedClocks: {
          ...existingClocks,
          [id]: {
            ...(existingClocks[id] || {}),
            error: null,
            loading: true,
          },
        },
      };
    }

    case Types.FETCH_CLOCK_SUCCESS: {
      const existingClocks = get(state, "viewedClocks", {});
      const { id, data } = action.payload;

      return {
        ...state,
        viewedClocks: {
          ...existingClocks,
          [id]: {
            data: data,
            error: null,
            loading: false,
          },
        },
      };
    }

    case Types.FETCH_CLOCK_ERROR: {
      const existingClocks = get(state, "viewedClocks", {});
      const { id, error } = action.payload;

      return {
        ...state,
        viewedClocks: {
          ...existingClocks,
          [id]: {
            error: error,
            loading: false,
          },
        },
      };
    }

    case Types.UPDATE_CLOCK_REQUEST: {
      return {
        ...state,
        updateClock: {
          loading: true,
          error: null,
        },
      };
    }

    case Types.UPDATE_CLOCK_SUCCESS: {
      const existingClocks = get(state, "viewedClocks", {});

      const { id, data } = action.payload;

      return {
        ...state,
        clocks: {
          ...get(state, "clocks"),
          is_dirty: true,
        },
        updateClock: {
          data: data,
          loading: false,
          error: null,
        },
        viewedClocks: {
          ...existingClocks,
          [id]: {
            data: data,
            error: null,
            loading: false,
          },
        },
      };
    }

    case Types.UPDATE_CLOCK_ERROR: {
      return {
        ...state,
        updateClock: {
          loading: false,
          error: action.payload,
        },
      };
    }

    case Types.DELETE_CLOCK_REQUEST: {
      return {
        ...state,
        deleteClock: {
          is_deleted: false,
          loading: true,
          error: null,
        },
      };
    }

    case Types.DELETE_CLOCK_SUCCESS: {
      return {
        ...state,
        clocks: {
          ...get(state, "clocks"),
          is_dirty: true,
        },
        deleteClock: {
          is_deleted: true,
          loading: false,
          error: null,
        },
      };
    }
    case Types.DELETE_CLOCK_ERROR: {
      return {
        ...state,
        deleteClock: {
          is_deleted: false,
          loading: false,
          error: action.payload,
        },
      };
    }

    default:
      return state;
  }
}
