import React, { Component } from "react";
import { Button } from "reactstrap";
import ToastManager from "components/ToastManager";
import { injectIntl, FormattedMessage } from "react-intl";

class FormFactory extends Component {
  findItem(key) {
    let item = false;
    let { template, validationState } = this.props;
    let validation_error =
      validationState[key] && validationState[key].validation_error;

    Object.keys(template.steps).map(step_key => {
      let step = template.steps[step_key];
      step.fields.map(field => {
        if (field.field === key && !item)
          item = {
            item: { ...field, key: field.field },
            validation_error,
            step
          };
        return true;
      });
      return true;
    });
    return item || { item: { key }, validation_error, step: "form" };
  }

  save() {
    let { process, template, user, ui_hint } = this.props;

    // set or change an existing or new value
    this.props.formValueChanged({
      process,
      template,
      item: { field: "a_filed" },
      user,
      value: "a_value"
    });
    process.data.fields["a_filed"] = { value: "a_value", is_dirty: true };

    let success_callback = ToastManager.show.bind(this, {
      title: this.props.intl.formatMessage({id: "toast > title saved"}),
      message: this.props.intl.formatMessage({id: "toast > message all good"}),
      level: "info"
    });

    // apply custom validations
    let valid = true;

    // save all changed fields regardless of steps
    let save_all_dirty = true;

    // other possibilities:
    // call recomputeRoles

    this.props.saveFormValues({
      process,
      template,
      step: { key: ui_hint },
      user,
      success_callback,
      valid,
      save_all_dirty
    });
  }

  render() {
    let { process, template, user } = this.props;
    let client_name = this.findItem("client_name");
    let step = client_name.step;
    let button = step.fields.find(f => f.component === "button");

    const isView = document.location.pathname.split("/")[2] === "view";

    return (
      <div>
        {" "}
        {isView ? (
          <div>
            {process.data.fields[client_name.item.key]
              ? process.data.fields[client_name.item.key].value
              : ""}
          </div>
        ) : (
          <span>
            <input
              className={"input-group-text w-100 "}
              value={
                process.data.fields[client_name.item.key]
                  ? process.data.fields[client_name.item.key].value
                  : ""
              }
              onChange={this.props.valueChanged.bind(
                this,
                client_name.item,
                process,
                template,
                user
              )}
            />
            <div className="alert-danger">{client_name.validation_error}</div>
          </span>
        )}
        {isView ? null : (
          <Button
            onClick={this.props.buttonClicked.bind(
              this,
              step,
              button,
              process,
              template,
              user
            )}>
            {process.loading ? "Submitting..." : button.text}
          </Button>
        )}
        {isView ? null : (
          <Button
            onClick={this.save.bind(
              this,
              step,
              button,
              process,
              template,
              user
            )}>
            {process.loading ? <FormattedMessage id="button > custom saving..." /> : <FormattedMessage id="button > custom save" />}
          </Button>
        )}
      </div>
    );
  }
}

export default injectIntl(FormFactory);
