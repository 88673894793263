import * as Types from "store/types/auth";
import { SET_MESSAGES } from "store/types/messages";
import { FORGET_PRCESSES } from "store/types/processes";
import * as Const from "utils/constants";
import history from "components/History";
import URL from "utils/urls";
import { get, isFunction, find, filter } from "lodash";
import Api from "utils/api";
import { fetchCitiesSuccess, fetchGroupsSuccess } from "./tags";
import { getNotifications } from "./messages";
export const adminRequestLogin = ({ email, guid }, callback) => async (
  dispatch
) => {
  try {
    dispatch({
      type: Types.AUTH_REQUEST,
    });
    let response = await Api.doCall(
      Const.LOGIN_ADMIN(),
      "POST",
      {
        email,
        guid,
      },
      callback,
      false
    );
    if (response.status === 200) {
      dispatch({
        type: Types.AUTH_USER,
        payload: response.data,
      });
      dispatch(setLanguage(get(response, "data.user.lang")));
      history.push(URL.HOME());
    } else {
      dispatch(authError("Login failure"));
    }
  } catch (error) {
    dispatch(authError("These credentials do not match our records."));
  }
};
export const loginUser = (
  { email, password, url, isMobile },
  callback
) => async (dispatch) => {
  try {
    dispatch({
      type: Types.AUTH_REQUEST,
    });
    let response = await Api.doCall(
      Const.LOGIN_USER(),
      "POST",
      {
        email,
        password,
      },
      callback,
      false
    );
    if (response.status === 200) {
      dispatch({
        type: Types.AUTH_USER,
        payload: response.data,
      });
      dispatch(setLanguage(get(response, "data.user.lang")));
      dispatch(getNotifications());
      dispatch(userInfo());
      if (url !== undefined && url !== null) {
        history.push(url, { requestFrom: null });
      } else {
        history.push(isMobile ? URL.HOME_MOBILE() : URL.HOME());
      }
    } else {
      dispatch(authError("Login failure"));
    }
  } catch (error) {
    let errorMsg = error.toString();
    dispatch(
      authError(
        errorMsg ? errorMsg : "These credentials do not match our records."
      )
    );
  }
};

export const impersonateUser = (id, auth) => async (dispatch) => {
  try {
    if (auth && !auth.user.is_god_admin) {
      dispatch({
        type: Types.IMPERSONATE_STOP_REQUEST,
        payload: { go_dark: true },
      });
    }
    dispatch({
      type: Types.IMPERSONATE_REQUEST,
    });
    let response = await Api.doCall(
      Const.IMPERSONATE_USER(id),
      "POST",
      {
        id,
      },
      null,
      false
    );
    if (response.status === 200) {
      dispatch({
        type: FORGET_PRCESSES,
      });

      dispatch({
        type: Types.IMPERSONATE_SUCCESS,
        payload: {
          name: get(response, "data.user.name"),
          id: get(response, "data.user.id"),
          avatar_url: get(response, "data.user.avatar_url"),
        },
      });

      dispatch({
        type: Types.AUTH_USER,
        payload: response.data,
      });

      dispatch(setLanguage(get(response, "data.user.lang")));
      if (document.location.href.toLowerCase().indexOf("/admins/") > -1)
        history.replace(URL.HOME());
      setTimeout(() => history.go(0), 500);
    } else {
      debugger;
      dispatch(authError("Login failure"));
    }
  } catch (error) {
    dispatch(authError(error));
  }
};

export const stopImpersonate = () => async (dispatch) => {
  try {
    Api.doCall(
      Const.IMPERSONATE_USER(0),
      "POST",
      {
        id: 0,
      },
      null,
      false
    );

    dispatch({
      type: Types.IMPERSONATE_STOP_REQUEST,
      payload: { go_dark: false },
    });

    setTimeout(() => {
      history.push("/admins/users");
      document.location.reload();
    }, 500);
  } catch (error) {
    dispatch(authError(error));
  }
};

export const userInfoOnly = () => async (dispatch, getState) => {
  const state = getState();
  const authenticated = get(state, "auth.authenticated", false);
  if (!authenticated) return;
  try {
    const response = await Api.doCall(Const.USER_INFO(), "GET");
    dispatch({ type: Types.USER_INFO_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({
      type: Types.USER_INFO_ERROR,
      error: error.toString(),
    });
  }
};

export const userInfo = () => async (dispatch, getState) => {
  const state = getState();
  const authenticated = get(state, "auth.authenticated", false);
  const tags = get(state, "auth.info.tags.tags");
  if (!authenticated) return;
  try {
    const response = await Api.doCall(Const.USER_INFO(), "GET");
    dispatch({ type: Types.USER_INFO_SUCCESS, payload: response.data });
    dispatch(userInfoMessages());
    dispatch(userInfoUsers());
    // fetched after login
    if (!tags) dispatch(userInfoTags());
  } catch (error) {
    dispatch({
      type: Types.USER_INFO_ERROR,
      error: error.toString(),
    });
  }
};

export const userInfoTags = () => async (dispatch, getState) => {
  const state = getState();
  const authenticated = get(state, "auth.authenticated", false);
  if (!authenticated) return;
  try {
    dispatch({
      type: Types.GET_USER_INFO_TAGS_REQUEST,
    });
    const response = await Api.doCall(Const.USER_INFO_TAGS(), "GET");
    const info = response.data;
    // update tags
    if (info) {
      dispatch({
        type: Types.GET_USER_INFO_TAGS_SUCCESS,
        payload: info,
      });
      if (info.tags) {
        dispatch(
          fetchCitiesSuccess(find(info.tags, (t) => t.id === 1).children)
        );
        dispatch(fetchGroupsSuccess(filter(info.tags, (t) => t.id !== 1)));
      }
    }
  } catch (error) {
    dispatch({
      type: Types.GET_USER_INFO_TAGS_ERROR,
      error: error.toString(),
    });
  }
};

export const userInfoMessages = () => async (dispatch) => {
  try {
    dispatch({
      type: Types.GET_USER_INFO_MESSAGES_REQUEST,
    });
    const response = await Api.doCall(Const.USER_INFO_MESSAGES(), "GET");
    const messages = response.data.messages;
    // update messages
    if (messages) {
      dispatch({
        type: Types.GET_USER_INFO_MESSAGES_SUCCESS,
        payload: messages,
      });
      const messagesFiltered = messages.map((m) => ({
        message: m._source,
        m_id: m._id,
        dismissable:
          m._source.needs_confirmation &&
          m._source.needs_confirmation.indexOf(response.data.id) > -1,
      }));
      dispatch({
        type: SET_MESSAGES,
        payload: { messages: messagesFiltered },
      });
    }
  } catch (error) {
    dispatch({
      type: Types.GET_USER_INFO_MESSAGES_ERROR,
      error: error.toString(),
    });
  }
};

export const userInfoUsers = () => async (dispatch) => {
  try {
    dispatch({
      type: Types.GET_USER_INFO_USERS_REQUEST,
    });
    const response = await Api.doCall(Const.USER_INFO_USERS(), "GET");
    const users = response.data;
    // update messages
    if (users) {
      dispatch({
        type: Types.GET_USER_INFO_USERS_SUCCESS,
        payload: users,
      });
    }
  } catch (error) {
    dispatch({
      type: Types.GET_USER_INFO_USERS_ERROR,
      error: error.toString(),
    });
  }
};

export const setLanguage = (lang) => async (dispatch) => {
  dispatch({ type: Types.SET_LANGUAGE, payload: lang });
};
export const registerUser = ({ name, email, password, c_password }) => async (
  dispatch
) => {
  try {
    let response = await Api.doCall(Const.REGISTER_USER(), "POST", {
      name,
      email,
      password,
      c_password,
    });
    // let response = await Api.doCall(Const.REGISTER_USER(), "POST", payload);
    if (response.status === 200 && response.data.user) {
      dispatch({ type: Types.AUTH_USER, payload: get(response, "data") });
      history.push(URL.HOME());
    }
  } catch (error) {
    dispatch(authError(error.toString()));
  }
};
export const changePassword = (data, cb) => async (dispatch) => {
  try {
    let response = await Api.doCall(
      Const.AUTH_CHANGE_PASSWORD(),
      "POST",
      data,
      null,
      false
    );
    if (response.status === 200) {
      dispatch({ type: Types.USER_UPDATE_SUCCESS, payload: response.data });
      // dispatch(userInfo());
      if (isFunction(cb))
        cb({
          status: true,
          message: "",
        });
    }
  } catch (error) {
    if (isFunction(cb))
      cb({
        status: false,
        error,
      });
  }
};
export const updateProfile = (data, cb) => async (dispatch) => {
  try {
    let response = await Api.doCall(
      Const.UPDATE_PROFILE(),
      "POST",
      data,
      null,
      false
    );
    if (response.status === 200) {
      dispatch({ type: Types.USER_UPDATE_SUCCESS, payload: response.data });
      dispatch(setLanguage(get(data, "language")));
      dispatch(userInfo());
      if (isFunction(cb)) cb(true);
    } else {
      dispatch(authError("Something went wrong..."));
    }
  } catch (error) {
    dispatch(authError(error.toString()));
    if (isFunction(cb)) cb(false);
  }
};
export const updateSettings = (
  { notify, language, email, first_name, last_name, location },
  cb
) => async (dispatch) => {
  try {
    let response = await Api.doCall(Const.UPDATE_PROFILE(), "POST", {
      notify,
      language,
      email,
      first_name,
      last_name,
      location,
    });
    if (response.status === 200) {
      dispatch({ type: Types.USER_UPDATE_SUCCESS, payload: response.data });
      dispatch(setLanguage(language));
      dispatch(userInfo());
      if (isFunction(cb)) cb();
      return {
        status: true,
        message: "Updated",
      };
    }
    return {
      status: true,
      message: "Updated",
    };
  } catch (error) {
    dispatch(authError(error.toString()));
  }
};

export const getUserStations = () => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({
      type: Types.GET_USER_STATIONS_REQUEST,
    });
    let response = await Api.doCall(Const.GET_USER_STATIONS(), "GET");
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.GET_USER_STATIONS_SUCCESS,
          payload: { data: response.data },
        });
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.GET_USER_STATIONS_ERROR,
    payload: { error: errorStr },
  });
};

export function setIsNotification(status) {
  return {
    type: Types.SET_NOTIFICATION,
    payload: status,
  };
}
export function authError(error) {
  return {
    type: Types.AUTH_ERROR,
    payload: error,
  };
}

export const logoutUser = (isRedirect = true, cb) => async (dispatch) => {
  try {
    await Api.doCall(
      Const.LOGOUT_USER(),
      "POST",
      {
        logout: true,
      },
      null,
      false
    );
    localStorage.removeItem("_expiredTime");
    dispatch({
      type: Types.LOGOUT_USER,
    });
    if (cb) cb();
    if (isRedirect) {
      history.push("/login");
    }
  } catch (error) {
    dispatch({
      type: Types.LOGOUT_USER,
    });
  }
};
export const setIsReminderOpen = (isReminderOpen) => {
  return {
    type: Types.SET_IS_REMINDER_OPEN,
    payload: isReminderOpen,
  };
};
export const setIsSidebarOpen = (isOpen) => {
  return {
    type: Types.SET_IS_SIDEBAR_OPEN,
    payload: isOpen,
  };
};

export const setSidebarActive = (link) => {
  return {
    type: Types.SET_SIDEBAR_ACTIVE,
    payload: link,
  };
};

export const setIsTeamLead = (isTeamLead) => {
  return {
    type: Types.SET_IS_TEAM_LEAD,
    payload: isTeamLead,
  };
};

export const validateSubdomain = async (subdomain, cb) => {
  try {
    let response = await Api.doCall(Const.VALIDATE_SUBDOMAIN(), "POST", {
      subdomain,
    });

    if (get(response, "data.isValidSubdomain", false) === true) {
      let validSubdomains =
        JSON.parse(window.localStorage.getItem("valid_subdomains")) || [];
      validSubdomains.push(subdomain);

      window.localStorage.setItem(
        "valid_subdomains",
        JSON.stringify(validSubdomains)
      );
      cb();
    } else {
      window.location.href = window.location.href.replace(subdomain, "admin");
    }
  } catch (error) {
    window.location.href = window.location.href.replace(subdomain, "admin");
  }
};

export const refreshAccessToken = async (params = {}, callback) => {
  await Api.doCall(Const.REFRESH_TOKEN(), "POST", params, callback, false);
};

export const setScheduleCalendarFilter = (payload = {}) => {
  return {
    type: Types.SET_SCHEDULE_CALENDAR_FILTER,
    payload,
  };
};
export const setOrderSectionsContest = (payload = {}) => {
  return {
    type: Types.SET_ORDER_SECTIONS_CONTEST,
    payload,
  };
};

export const toggleRightPanel = (payload) => async (dispatch) => {
  dispatch({
    type: Types.TOGGLE_RIGHT_PANEL,
    payload,
  });
  return;
};

export const setCountConfirmationMasterList = (payload) => (dispatch) => {
  dispatch({
    type: Types.SET_COUNT_CONFIRMATION_MASTER_LIST,
    payload,
  });
};
export const setSchedulingType = (payload) => (dispatch) => {
  dispatch({
    type: Types.SET_SCHEDULING_TYPE,
    payload,
  });
};

export const setPromotionsContestJockIntroductionsText = (payload) => (
  dispatch
) => {
  dispatch({
    type: Types.SET_PROMOTIONS_CONTEST_JOCK_INTRODUCTIONS_TEXT,
    payload,
  });
};

export const refreshAccessTokenSuccess = (payload) => async (dispatch) => {
  dispatch({
    type: Types.REFRESH_ACCESS_TOKEN_SUCCESS,
    payload,
  });
  return;
};

export const setSortProcessesQueue = (payload) => async (dispatch) => {
  dispatch({
    type: Types.SET_SORT_PROCESSES_QUEUE_SUCCESS,
    payload,
  });
  return;
};
