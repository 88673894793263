import React from "react";
import { FormattedMessage } from "react-intl";
import steps from "./steps";

const promotionRequest = {
  _index: "templates",
  _type: "template",
  _id: "liner",
  _score: 1,
  _source: {
    key: "liner",
    templates_name: "Promotion Liner",
    templates_description: "Create a promotion liner",
    breadcrumbs_title: () => <FormattedMessage id="process > home" />,
    breadcrumbs_subtitle: () => <FormattedMessage id="process > liner management" />,
    priviliges: {},
    steps: steps,
    active: true,
  },
};
export default promotionRequest;
