import View from "./view";
import { connect } from "react-redux";
import { get } from 'lodash'
import { compose, lifecycle } from "recompose";
import { adminRequestLogin } from "store/actions/auth";
import URL from "utils/urls";
export default compose(
  connect((state, props) => ({
    auth: state.auth
  }), { adminRequestLogin }),
  lifecycle({
    componentDidMount() {
      const { match } = this.props;
      const { params } = match;
      const authenticated = get(this.props, "auth.authenticated");
      if(!authenticated){
        this.props.adminRequestLogin(params);
      }else{
        this.props.history.push(URL.HOME())
      }
      
    }
  })
)(View);
