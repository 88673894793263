import View from "./view";
import { compose, withState, withHandlers } from "recompose";
import { connect } from "react-redux";
import { get, findIndex, filter, forEach } from "lodash";
import { saveFormValues } from "store/actions/processes";
import { labelToNameField, uuidv4 } from "utils/helpers";
import { injectIntl } from "react-intl";
export default injectIntl(
  compose(
    connect((state, props) => ({ ...props }), {
      saveFormValues,
    }),
    withState(
      "isCollapse",
      "setIsCollapse",
      (props) =>
        props.process.data.fields.for_marketing_channel &&
        props.process.data.fields.for_marketing_channel.value.id ===
          props.channel.id
    ),
    withState(
      "currentPreviewMediaModalOpen",
      "setCurrentPreviewMediaModalOpen",
      null
    ),
    withState("isLoading", "setIsLoading", false),
    withState("uniqueId", "setUniqueId", uuidv4()),
    withState("isDeleteModalOpen", "setIsDeleteModalOpen", false),
    withState("isOpenEnlargeAssetModal", "setIsOpenEnlargeAssetModal", false),
    withState("isOpenHelperTooltipModal", "setIsOpenHelperTooltipModal", true),
    withState("isOpenAddComment", "setIsOpenAddComment", false),
    withState("currentPosition", "setCurrentPosition", null),
    withState("watchPosition", "setWatchPosition", null),
    withState("isShowDraggable", "setIsShowDraggable", false),
    withState("currentMediaSlideIndex", "setCurrentMediaSlideIndex", 0),
    withState("visibleComments", "setVisibleComments", {}),
    withState("isEditComment", "setIsEditComment", false),
    withState("currentDeleteComment", "setCurrentDeleteComment", null),
    withHandlers({
      onSubmitAllFeedback: ({
        process,
        buttonClicked,
        template,
        user,
        step,
        setIsShowDraggable,
        setCurrentPosition,
      }) => (event) => {
        const item = step.fields.find((f) => f.key === "save_button");
        buttonClicked(
          step,
          {
            ...item,
            is_submit_all_feedback: true,
          },
          process,
          template,
          user,
          event,
          null,
          true
        );
        setIsShowDraggable(false);
        setCurrentPosition(null);
      },
    }),
    withHandlers({
      onDeleteComment: ({
        process,
        channel,
        currentMedia,
        currentAsset,
        valueChanged,
        template,
        user,
        step,
        setIsEditComment,
        setVisibleComments,
        onAutoSubmit,
      }) => (values) => {
        const marketingChannelsField = step.fields.find(
          (f) => f.field === "marketing_channels"
        );
        let channels = get(process, "data.fields.marketing_channels.value", []);
        channels = channels
          .map((item) => {
            if (channel.id === item.id) {
              const fieldName = labelToNameField(currentMedia.label);
              let fieldValue = channel[fieldName];
              if (fieldValue) {
                fieldValue = fieldValue.map((fieldValueItem) => {
                  // assign new comment to current asset
                  if (fieldValueItem.path === currentAsset.path) {
                    let number = 0;
                    let comments = get(fieldValueItem, "comments", [])
                      .map((commentItem) => {
                        if (commentItem.id === values.id) {
                          return {
                            ...commentItem,
                            is_deleted: true,
                          };
                        }
                        return commentItem;
                      })
                      .map((i) => {
                        if (i.is_deleted) return i;
                        number += 1;
                        return {
                          ...i,
                          number,
                        };
                      });
                    return {
                      ...fieldValueItem,
                      comments,
                    };
                  }
                  return fieldValueItem;
                });
                return {
                  ...item,
                  [fieldName]: fieldValue,
                };
              }
            }
            return item;
          })
          .map((item) => {
            if (channel.id === item.id) {
              const fieldName = labelToNameField(currentMedia.label);
              let fieldValue = item[fieldName];
              const fields = item.fields.map((field) => {
                if (
                  currentMedia.label === field.label &&
                  currentMedia.key === field.key &&
                  field.value_arr
                ) {
                  let newValueHistory = [];
                  const valueHistory = get(field, "value_history", []);
                  forEach(fieldValue, (valueItem) => {
                    const valueHistoryIndex = findIndex(
                      get(field, "value_history", []),
                      (valueHistoryItem) =>
                        valueHistoryItem.path === valueItem.path
                    );
                    if (valueHistoryIndex === -1) {
                      newValueHistory.push({
                        ...valueItem,
                        time: new Date(),
                      });
                    } else {
                      newValueHistory.push({
                        ...valueHistory[valueHistoryIndex],
                        comments: valueItem.comments || [],
                        time: new Date(),
                      });
                    }
                  });
                  return {
                    ...field,
                    value_history: newValueHistory,
                  };
                }
                return field;
              });
              return {
                ...item,
                fields,
              };
            }
            return item;
          });
        valueChanged(marketingChannelsField, process, template, user, {
          value: channels,
        });
        setIsEditComment(false);
        setVisibleComments({
          [values.id]: false,
        });
        process.data.fields.marketing_channels = {
          value: channels,
          is_dirty: true,
        };
        onAutoSubmit(process);
      },
      onSubmitEditComment: ({
        process,
        channel,
        currentMedia,
        currentAsset,
        valueChanged,
        template,
        user,
        step,
        setIsEditComment,
        onAutoSubmit,
      }) => (values) => {
        const marketingChannelsField = step.fields.find(
          (f) => f.field === "marketing_channels"
        );
        let channels = get(process, "data.fields.marketing_channels.value", []);
        channels = channels
          .map((item) => {
            if (channel.id === item.id) {
              const fieldName = labelToNameField(currentMedia.label);
              let fieldValue = channel[fieldName];
              if (fieldValue) {
                fieldValue = fieldValue.map((fieldValueItem) => {
                  // assign new comment to current asset
                  if (fieldValueItem.path === currentAsset.path) {
                    let comments = get(fieldValueItem, "comments", []);
                    const commentIndex = findIndex(
                      comments,
                      (commentItem) => commentItem.id === values.id
                    );
                    if (commentIndex !== -1) {
                      comments[commentIndex] = values;
                    }
                    return {
                      ...fieldValueItem,
                      comments: [...comments],
                    };
                  }
                  return fieldValueItem;
                });
                return {
                  ...item,
                  [fieldName]: fieldValue,
                };
              }
            }
            return item;
          })
          .map((item) => {
            if (channel.id === item.id) {
              const fieldName = labelToNameField(currentMedia.label);
              let fieldValue = item[fieldName];
              const fields = item.fields.map((field) => {
                if (
                  currentMedia.label === field.label &&
                  currentMedia.key === field.key &&
                  field.value_arr
                ) {
                  let newValueHistory = [];
                  const valueHistory = get(field, "value_history", []);
                  forEach(fieldValue, (valueItem) => {
                    const valueHistoryIndex = findIndex(
                      get(field, "value_history", []),
                      (valueHistoryItem) =>
                        valueHistoryItem.path === valueItem.path
                    );
                    if (valueHistoryIndex === -1) {
                      newValueHistory.push({
                        ...valueItem,
                        time: new Date(),
                      });
                    } else {
                      newValueHistory.push({
                        ...valueHistory[valueHistoryIndex],
                        comments: valueItem.comments || [],
                        time: new Date(),
                      });
                    }
                  });
                  return {
                    ...field,
                    value_history: newValueHistory,
                  };
                }
                return field;
              });
              return {
                ...item,
                fields,
              };
            }
            return item;
          });
        valueChanged(marketingChannelsField, process, template, user, {
          value: channels,
        });
        setIsEditComment(false);
        process.data.fields.marketing_channels = {
          value: channels,
          is_dirty: true,
        };
        onAutoSubmit(process);
      },
      onSubmitNewMessage: ({
        process,
        template,
        step,
        user,
        channel,
        // submitMessage,
        currentAsset,
        currentMedia,
        valueChanged,
        currentPosition,
        setCurrentPosition,
        setIsOpenAddComment,
        onAutoSubmit,
        setIsShowDraggable,
        setUniqueId,
        isClientReview,
      }) => (message) => {
        const marketingChannelsField = step.fields.find(
          (f) => f.field === "marketing_channels"
        );
        let channels = get(process, "data.fields.marketing_channels.value", []);
        channels = channels
          .map((item) => {
            if (channel.id === item.id) {
              const fieldName = labelToNameField(currentMedia.label);
              let fieldValue = channel[fieldName];
              if (fieldValue) {
                fieldValue = fieldValue.map((fieldValueItem) => {
                  // assign new comment to current asset
                  if (fieldValueItem.path === currentAsset.path) {
                    let comments = get(fieldValueItem, "comments", []);
                    const totalActiveComments = filter(
                      comments,
                      (i) => !i.is_deleted
                    );
                    const number = totalActiveComments.length + 1;
                    return {
                      ...fieldValueItem,
                      comments: [
                        ...comments,
                        {
                          id: uuidv4(),
                          number,
                          message,
                          is_deleted: false,
                          ...currentPosition,
                          from: {
                            id: user.id,
                            name: user.name,
                            avatar_url: user.avatar_url,
                          },
                        },
                      ],
                    };
                  }
                  return fieldValueItem;
                });
                return {
                  ...item,
                  [fieldName]: fieldValue,
                };
              }
            }
            return item;
          })
          .map((item) => {
            if (channel.id === item.id) {
              const fieldName = labelToNameField(currentMedia.label);
              let fieldValue = item[fieldName];
              const fields = item.fields.map((field) => {
                if (
                  currentMedia.label === field.label &&
                  currentMedia.key === field.key &&
                  field.value_arr
                ) {
                  let newValueHistory = [];
                  let newValueArr = [];
                  const valueHistory = get(field, "value_history", []);
                  const valueArray = get(field, "value_arr", []);
                  forEach(fieldValue, (valueItem) => {
                    const time = new Date();
                    // For history
                    const valueHistoryIndex = findIndex(
                      get(field, "value_history", []),
                      (valueHistoryItem) =>
                        valueHistoryItem.path === valueItem.path
                    );
                    if (valueHistoryIndex === -1) {
                      newValueHistory.push({
                        ...valueItem,
                        time,
                      });
                    } else {
                      newValueHistory.push({
                        ...valueHistory[valueHistoryIndex],
                        comments: valueItem.comments || [],
                        time,
                      });
                    }
                    // for value
                    const valueArrayIndex = findIndex(
                      get(field, "value_arr", []),
                      (valueArrItem) => valueArrItem.path === valueItem.path
                    );
                    if (valueArrayIndex === -1) {
                      newValueArr.push({
                        ...valueItem,
                      });
                    } else {
                      newValueArr.push({
                        ...valueArray[valueArrayIndex],
                        comments: valueItem.comments || [],
                      });
                    }
                  });
                  return {
                    ...field,
                    value_arr: newValueArr,
                    value_history: newValueHistory,
                  };
                }
                return field;
              });
              return {
                ...item,
                fields,
              };
            }
            return item;
          });
        valueChanged(marketingChannelsField, process, template, user, {
          value: channels,
        });
        process.data.fields.marketing_channels = {
          value: channels,
          is_dirty: true,
        };
        onAutoSubmit(process, () => {
          // need re-render for display pin icon from UI
          if (isClientReview) {
            setTimeout(() => {
              setUniqueId(uuidv4());
            }, 1000);
          }
        });
        setTimeout(() => {
          setIsOpenAddComment(false);
          setCurrentPosition(null);
          setIsShowDraggable(false);
        }, 100);
      },
    })
  )(View)
);
