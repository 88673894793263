import React from "react";
import PropTypes from "utils/propTypes";
import { get } from "lodash";
import { withRouter } from "react-router-dom";
import bn from "utils/bemnames";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import Typography from "./Typography";

const bem = bn.create("page-admin");

const Page = ({
  title,
  breadcrumbs,
  tag: Tag,
  className,
  applyPadding,
  children,
  history,
  ...restProps
}) => {
  const classes = bem.b(applyPadding ? "page-padding" : "", className);
  const onGoToBreadCrumb = (breadcrumb) => {
    if (get(breadcrumb, "to")) {
      history.push(breadcrumb.to);
    }
  };
  let safeProps = { ...restProps };
  Object.keys(safeProps).map((p) => {
    if (p === "staticContext") delete safeProps[p];
  });
  return (
    <Tag className={classes} {...safeProps}>
      {breadcrumbs && (
        <Breadcrumb className={bem.e("breadcrumb")}>
          {breadcrumbs.length &&
            breadcrumbs.map((breadcrumb, index) => (
              <BreadcrumbItem
                key={index}
                active={breadcrumb.active}
                onClick={() => onGoToBreadCrumb(breadcrumb)}
              >
                {breadcrumb.name}
              </BreadcrumbItem>
            ))}
        </Breadcrumb>
      )}
      <div className={bem.e("header")}>
        {title && (
          <Typography type="h1" className={bem.e("title")}>
            {title}
          </Typography>
        )}
      </div>
      {children}
    </Tag>
  );
};

Page.propTypes = {
  tag: PropTypes.component,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
  children: PropTypes.node,
  breadcrumbs: PropTypes.any,
  applyPadding: PropTypes.bool,
};

Page.defaultProps = {
  tag: "div",
  title: "",
  applyPadding: true,
};

export default withRouter(Page);
