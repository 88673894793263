import { useEffect } from "react";
const useLeaves = (callback) => {
  // prompt the user if they try and leave with unsaved changes
  useEffect(() => {
    const handleWindowClose = (e) => {
      callback();
      e.returnValue = "onbeforeunload";
    };
    const handleUnmount = () => {
      callback();
    };
    window.addEventListener("beforeunload", handleWindowClose);
    return () => {
      handleUnmount();
      window.removeEventListener("beforeunload", handleWindowClose);
    };
  }, []);
};
export default useLeaves;
