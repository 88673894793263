import { get, remove, includes, filter  } from "lodash";
import { connect } from "react-redux";
import {
  compose,
  lifecycle,
  withState,
  withHandlers,
} from "recompose";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import { getWideOrbitConfigurations, updateWideOrbitConfigurations, getMaterialGroups, updateMaterialGroups } from "store/actions/servers";
import WideOrbitConfigurations from "./view";

const integrationSchema = Yup.object().shape({
  ftp_path: Yup.string().required('Required'),
  subfolder: Yup.string(),
  username: Yup.string().required('Required'),
  password: Yup.string().required('Required'),
  port: Yup.string().required('Required'),
});

export default injectIntl(
  compose(
    connect(
      (state) => {
        const serverId = get(state, "auth.info.server_id");
        return {
          serverId,
          productsSettings: get(state, `servers.productsSettings.${serverId}.data`, {}),
          isError: get(state, `servers.productsSettings.${serverId}.error`, false),
          isDirty: get(state, `servers.productsSettings.${serverId}.is_dirty`, false),
          isLoading: get(state, `servers.productsSettings.${serverId}.loading`, false),
          materialGroups: get(state, `servers.materialGroups.${serverId}.data`, {}),
          isMGError: get(state, `servers.materialGroups.${serverId}.error`, false),
          isMGDirty: get(state, `servers.materialGroups.${serverId}.is_dirty`, false),
          isMGLoading: get(state, `servers.materialGroups.${serverId}.loading`, false),
          isWODubbingEnabled: get(state, "auth.info.enable_wo_dubbing", false),
          isWOTrafficEnabled: get(state, "auth.info.enable_wo_traffic", false),
        };
      },
      {
        getWideOrbitConfigurations,
        updateWideOrbitConfigurations,
        updateMaterialGroups,
        getMaterialGroups
      }
    ),
    withState("openSections", "setOpenSections", ['api_configuration']),
    withState("editingIntegrations", "setEditingIntegrations", {}),
    withState("editingIds", "setEditingIds", []),
    withState("ftpConnectionProgress", "setFtpConnectionProgress", 0),
    withState("isFtpConfigSaved", "setIsFtpConfigSaved", false),
    withState("isFtpSaveLoading", "setFtpSaveLoging", false),
    withState("isApiConfigSaveLoading", "setIsApiConfigSaveLoading", false),
    withState("isMGSaveLoading", "setIsMGSaveLoading", false),
    withState("dubbingFtpButtonStatus", "setDubbintFtpButtonStatus", {}),
    withHandlers({
      onToggleSection: ({
        openSections,
        setOpenSections,
      }) => (sectionId) => {
        if(includes(openSections, sectionId)){
          setOpenSections(filter(openSections, section => section !== sectionId));
        }else{
          openSections.push(sectionId)
          setOpenSections(openSections);
        }
      },
      onToggleEdit: ({
        editingIds,
        setEditingIds,
        editingIntegrations,
        setEditingIntegrations,
      }) => (integration) => {
        const id = integration.id;
        if (editingIds.includes(id)) {
          setEditingIds(remove(editingIds, id));
        } else {
          setEditingIntegrations({
            ...editingIntegrations,
            [id]: { ...integration },
          });
          setEditingIds([...editingIds, id]);
        }
      },
      onValueChange: ({
        editingIntegrations,
        setEditingIntegrations,
      }) => (id) => ({ target: { name, value }}) => {
        setEditingIntegrations({
          ...editingIntegrations,
          [id]: {
            ...editingIntegrations[id],
            [name]: value,
          },
        });
      },
    }),
    withHandlers({
      onUpdateIntegration: ({
        onToggleEdit,
        updateWideOrbitConfigurations,
        serverId,
        editingIntegrations,
        setEditingIntegrations,
        dubbingFtpButtonStatus,
        setDubbintFtpButtonStatus,
        isError
      }) => (integration) => {
        try {
          integrationSchema.validateSync(integration, { abortEarly: false });
          
          let id = integration.id;

          let buttonStatus = dubbingFtpButtonStatus[integration.id] || {
            isFtpConfigSaved: false,
            isError: false,
            ftpConnectionProgress: 0,
            isLoading: false,
          };

          const {
            isFtpConfigSaved,
            isLoading,
            ftpConnectionProgress,
          } = buttonStatus;

          delete integration.market;
          delete integration.errors;

          if(isFtpConfigSaved || isError){
            return false;
          }

          setDubbintFtpButtonStatus({...dubbingFtpButtonStatus, [integration.id]: {...buttonStatus, isLoading: true}});

          let progress = ftpConnectionProgress > 0 ? ftpConnectionProgress : 30;

          let interval = setInterval(function(){

            if(progress <= 88){
              progress = progress + 2;
            }else {
              progress = progress - 2;
            }

            setDubbintFtpButtonStatus({...dubbingFtpButtonStatus, [integration.id]: {...buttonStatus, ftpConnectionProgress: progress}});
          },500);

          updateWideOrbitConfigurations(serverId, [integration], (isError) => {
            clearInterval(interval);
            
            if(!isError){
              setDubbintFtpButtonStatus({...dubbingFtpButtonStatus, [integration.id]: {...buttonStatus, ftpConnectionProgress: 0, isLoading: false, isFtpConfigSaved: true}});
            }else{
              setDubbintFtpButtonStatus({...dubbingFtpButtonStatus, [integration.id]: {...buttonStatus, ftpConnectionProgress: 0, isLoading: false}});
            }
          });

        } catch (errors) {

          const validationError = errors;
          integration.errors = {};
          
          validationError.inner.forEach((error) => {
            integration.errors[error.path] = error.message;
            setEditingIntegrations({
              ...editingIntegrations,
              [integration.id]: {
                ...editingIntegrations[integration.id],
                ...integration,
              },
            });
          });
        }
      },
      saveWideOrbitApiConfig: ({updateWideOrbitConfigurations, serverId, setIsApiConfigSaveLoading}) => (values) => {
        
        setIsApiConfigSaveLoading(true);

        updateWideOrbitConfigurations(serverId, {...values, isApiConfig: true}, () => {
          setIsApiConfigSaveLoading(false);
        });
      },
      saveWideOrbitFtpConfig: ({updateWideOrbitConfigurations, serverId, ftpConnectionProgress, setFtpConnectionProgress, setIsFtpConfigSaved, isFtpConfigSaved, isError, setFtpSaveLoging}) => (values) => {

        if(isFtpConfigSaved || isError){
          return false;
        }

        setFtpSaveLoging(true);

        let progress = ftpConnectionProgress > 0 ? ftpConnectionProgress : 30;

        let interval = setInterval(function(){

          if(progress <= 88){
            progress = progress + 2;
          }else {
            progress = progress - 2;
          }

          setFtpConnectionProgress(progress);
        },500);

        updateWideOrbitConfigurations(serverId, {...values, isFtpConfig: true}, (isError) => {
          clearInterval(interval);
          if(!isError){
            setIsFtpConfigSaved(true);
          }
          setFtpConnectionProgress(0);
          setFtpSaveLoging(false);
        });
      },
      saveMaterialGroups: ({updateMaterialGroups, serverId, setIsMGSaveLoading}) => (values) => {
        
        setIsMGSaveLoading(true);

        updateMaterialGroups(serverId, values, () => {
          setIsMGSaveLoading(false);
        });
      },
    }),
    lifecycle({
      componentDidMount() {
        const { serverId, getWideOrbitConfigurations, getMaterialGroups } = this.props;
        getWideOrbitConfigurations(serverId);
        getMaterialGroups(serverId);
      },
      componentDidUpdate(prevProps) {
        
        const { 
          serverId, 
          getWideOrbitConfigurations, 
          setIsFtpConfigSaved, 
          isDirty, 
          setDubbintFtpButtonStatus, 
          setEditingIds ,
          isMGDirty,
          getMaterialGroups
        } = this.props;

        if(isDirty && isDirty !== prevProps.isDirty){
          setTimeout(function (){
            getWideOrbitConfigurations(serverId);
            setIsFtpConfigSaved(false);
            setDubbintFtpButtonStatus({});
            setEditingIds([]);
          },1000);
        }

        if(isMGDirty && isMGDirty !== prevProps.isMGDirty){
          setTimeout(function (){
            getMaterialGroups(serverId);
          },1000);
        }
      }
    })
  )(WideOrbitConfigurations)
);
