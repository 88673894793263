import React from "react";
import { Redirect } from "react-router";

import Page from "components/Page";
import PrivilegedComponent from "components/PrivilegedComponent";
import AuditList from "components/AuditList";

const AuditPage = (props) => {
  return (
    <PrivilegedComponent requires={[]}>
      {({ isValid }) => {
        if (isValid) {
          return (
            <Page title="" applyPadding={false}>
              <AuditList {...props} />
            </Page>
          );
        } else {
          return <Redirect to="/" />;
        }
      }}
    </PrivilegedComponent>
  );
};

export default AuditPage;
