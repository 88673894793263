import { connect } from "react-redux";
import ProcessesList from "./view";
import { compose } from "recompose";
const Enhanced = compose(
  connect(
    (state, props) => {
      return {
        ...props,
      };
    },
    {}
  ),
)(ProcessesList);
export default Enhanced;
