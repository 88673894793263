import React from "react";
import {
  findIndex,
  isEmpty,
  first,
  get,
  split,
  orderBy,
  map,
  filter,
  uniqBy,
  includes,
  intersection,
} from "lodash";
import classnames from "classnames";
import Select, { components } from "react-select";
import bn from "utils/bemnames";
import { injectIntl } from "react-intl";
import { CaretIcon } from "components/CustomIcons";
import { FormattedMessage } from "react-intl";
import Checkbox from "components/Checkbox";
import TitleTooltip from "components/TitleTooltip";
const bem = bn.create("custom-multiselect-dropdown");

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretIcon />
    </components.DropdownIndicator>
  );
};

const ControlComponent = (controlProps) => {
  const {
    isMenuOpen,
    onMenuClose,
    onMenuOpen,
  } = controlProps.selectProps.extraProps;
  return (
    <div onClick={isMenuOpen ? onMenuClose : onMenuOpen}>
      <components.Control {...controlProps} />
    </div>
  );
};
const GroupHeading = ({
  children,
  value,
  onChange,
  options,
  isAllowAllOption,
  ...rest
}) => {
  const index = split(rest.id, "-").findIndex((item) => item === "group") + 1;
  const indexOfGroupOption = !isAllowAllOption
    ? get(split(rest.id, "-"), index)
    : get(split(rest.id, "-"), index) - 1;
  const group = get(options, indexOfGroupOption);
  const groupOptions = get(group, "options", []);
  const valueArr = orderBy(
    map(value, (item) => item.value),
    "desc"
  );
  const groupOptionsArr = orderBy(
    map(groupOptions, (item) => item.value),
    "desc"
  );
  const isSelected =
    intersection(groupOptionsArr, valueArr).length === groupOptionsArr.length;
  return (
    <div className={classnames(bem.e("group-heading-checkbox"))}>
      <components.GroupHeading {...rest}>
        <div className={bem.e("option")}>
          <Checkbox
            checked={isSelected}
            text={children}
            onChange={(checked) => {
              let newValue = [];
              if (checked) {
                newValue = uniqBy([...value, ...groupOptions], "value");
              } else {
                newValue = filter(
                  value,
                  (item) => !includes(groupOptionsArr, item.value)
                );
              }
              onChange(newValue);
            }}
          />
        </div>
      </components.GroupHeading>
    </div>
  );
};
const getAllOptions = (props) => {
  const { isAllowSelectGroup, options } = props;
  let allOptions = [];
  if (isAllowSelectGroup) {
    options.forEach((item) => {
      allOptions = [...allOptions, ...item.options];
    });
  } else {
    allOptions = options;
  }
  return allOptions;
};
const CustomMultiSelectDropdown = (props) => {
  const {
    intl,
    allOption,
    name,
    className,
    isMenuOpen,
    setIsMenuOpen,
    options,
    onChange,
    value,
    error,
    isStation,
    multiText,
    isAllowAllOption,
    isAllowSelectGroup,
    exceptOptions,
    ...rest
  } = props;
  const allOptions = getAllOptions(props);

  const onMenuOpen = () => {
    setIsMenuOpen(true);
  };

  const onMenuClose = () => {
    setIsMenuOpen(false);
  };

  let dropdownOptions = isAllowAllOption ? [allOption, ...options] : options;
  if(exceptOptions && exceptOptions.length > 0){
    dropdownOptions = dropdownOptions.filter(i => !exceptOptions.includes(i.value))
  }
  const CustomOption = ({ data, ...optionProps }) => {
    const value = optionProps.getValue();

    const optionSelectedIndex = findIndex(
      value,
      (item) => item.value === optionProps.value
    );
    let isOptionSelected = optionSelectedIndex > -1;

    if (optionProps.value === "*") {
      isOptionSelected = value.length === allOptions.length;
    }
    let optionLabel;
    if (isStation && data.data) {
      const optionData = data.data;
      optionLabel = (
        <div className="value-container">
          {optionData.firstLabel && (
            <div className="label">
              <TitleTooltip title={optionData.firstLabel} />
            </div>
          )}
          {optionData.lastLabel && (
            <div className="type">
              <TitleTooltip title={optionData.lastLabel} />
            </div>
          )}
        </div>
      );
    } else {
      optionLabel = data.label;
    }
    return (
      <div
        className={classnames(bem.e("option-container"), {
          [bem.e("option-select-group")]: isAllowSelectGroup,
          [bem.e("option-all")]: optionProps.value === "*",
        })}
      >
        <components.Option {...optionProps}>
          <div className={bem.e("option")}>
            <Checkbox checked={isOptionSelected} text={optionLabel} />
          </div>
        </components.Option>
      </div>
    );
  };
  const ValueContainer = ({ children, ...rest }) => {
    const renderValue = () => {
      let value = rest.getValue();

      if (isEmpty(value)) {
        return rest.selectProps.placeholder;
      }

      if (value.length === 1) {
        return first(value).label;
      }
      if (value.length === get(allOptions, "length", 0) && isAllowAllOption) {
        return get(props, "allOption.label", "All");
      }

      return multiText;
    };

    return (
      <components.ValueContainer {...rest}>
        <div className={bem.e("value-container")}>{renderValue()}</div>
      </components.ValueContainer>
    );
  };
  return (
    <div className={bem.b()}>
      <Select
        {...rest}
        value={value}
        options={dropdownOptions}
        className={classnames(
          "react-select-container",
          className && className.toString(),
          {
            "react-select-menu-open": isMenuOpen,
          }
        )}
        classNamePrefix="react-select"
        onChange={(selected) => {
          let isAllSelected = selected.find((value) => value.value === "*");
          if (selected.length - 1 === allOptions.length && isAllowAllOption) {
            onChange([]); // reset when unselect all
            return;
          }
          if (isAllSelected) {
            onChange(allOptions);
          } else {
            onChange(selected);
          }
        }}
        components={{
          Option: CustomOption,
          DropdownIndicator: DropdownIndicator,
          ValueContainer: ValueContainer,
          Control: ControlComponent,
          GroupHeading: (data) => (
            <GroupHeading
              {...data}
              value={value}
              options={props.options}
              onChange={onChange}
              isAllowAllOption={isAllowAllOption}
            />
          ),
        }}
        closeMenuOnSelect={false}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        menuIsOpen={isMenuOpen}
        styles={{
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
        }}
        isMulti={true}
        hideSelectedOptions={false}
        isClearable={false}
        extraProps={{ ...props, onMenuOpen, onMenuClose, isMenuOpen }}
      />
      {props.customInput}
      {isMenuOpen ? (
        <div
          className="position-fixed top-0 left-0 w-100 h-100"
          onClick={(e) => {
            e.preventDefault();
            onMenuClose();
          }}
        />
      ) : null}
      {error && <span className="text-danger">{error}</span>}
    </div>
  );
};

CustomMultiSelectDropdown.defaultProps = {
  allOption: {
    label: "All",
    value: "*",
  },
  onChange: () => {},
  name: "",
  isStation: false,
  isAllowAllOption: true,
  isAllowSelectGroup: false,
  multiText: (
    <FormattedMessage id="process > promotion > liner > scheduling > multiple selected" />
  ),
  exceptOptions: []
};

export default injectIntl(CustomMultiSelectDropdown);
