import React from "react";
import bn from "utils/bemnames";
import { get, find } from "lodash";
import { Row, Col } from "reactstrap";
import { FormattedMessage } from "react-intl";
import TextareaAutosize from "react-textarea-autosize";

const bem = bn.create("voice-over-details");

const VoiceOverDetails = (props) => {
  const { process } = props;
  const spot = find(
    get(process, "data.fields.spot_info.value", []),
    (spot) => spot.key === process.key
  );

  return (
    <div className={bem.b()}>
      <Row>
        <Col className={bem.e("col-field")} xs={12}>
          <span className={bem.e("order-view-value")}>
            {get(spot, "voice_over_instructions", "") ? (
              <TextareaAutosize
                value={spot.voice_over_instructions}
                className="text-area-view"
                disabled
              />
            ) : (
              <FormattedMessage id="process > none" />
            )}
          </span>
        </Col>
      </Row>
    </div>
  );
};
export default VoiceOverDetails;
