import bn from "utils/bemnames";
import Select, { components } from "react-select";
import React from "react";
import { Label } from "reactstrap";
import classnames from "classnames";
import Checkbox from "components/Checkbox";
import { FormattedMessage } from "react-intl";
import { findIndex, get } from "lodash";
import { CaretIcon, CloseSmallIcon, CloseTagDropdownIcon } from "components/CustomIcons";

const bem = bn.create("channel-manager-dropdown");
const ChannelManagerDropdown = (props) => {
	const { value } = props;
	let configComponents = {};
	const Option = (option) => {
		const optionSelectedIndex = findIndex(
			value,
			(item) => item.value === option.data.value
		);
		const isOptionSelected = optionSelectedIndex > -1;
		const isPrimary =
			isOptionSelected &&
			(get(value, `${optionSelectedIndex}.primary`) ||
				value.length === 1);
		const labelText = (
			<span
				className={classnames(bem.e("option-text"), {
					[bem.e("option-text-primary")]: isPrimary,
				})}
			>
				{option.data.label}
			</span>
		);
		return (
			<div className={bem.e("option-container")}>
				<components.Option {...option}>
					<div className={bem.e("option")}>
						<Checkbox
							checked={isOptionSelected}
							onChange={() => props.onToggleCheckbox(option)}
							text={labelText}
						/>
						<div className={bem.e("option-action")}>
							{isPrimary ? (
								<span className={bem.e("option-text-primary")}>
									<FormattedMessage id="channel-manager-dropdown > text primary" />
								</span>
							) : isOptionSelected ? (
								<span
									className={bem.e("link-primary")}
									onClick={() => props.onSetPrimary(option)}
								>
									<FormattedMessage id="channel-manager-dropdown > text make primary?" />
								</span>
							) : null}
						</div>
					</div>
				</components.Option>
			</div>
		);
	};
	configComponents.Option = Option;
	const DropdownIndicator = (props) => {
		return (
			components.DropdownIndicator && (
				<components.DropdownIndicator {...props} className="disable-unsaved">
					<CaretIcon className="disable-unsaved" />
				</components.DropdownIndicator>
			)
		);
	};
  configComponents.DropdownIndicator = DropdownIndicator;
  const ClearIndicator = props => {
    return (
      components.ClearIndicator && (
        <components.ClearIndicator {...props} className="disable-unsaved">
          <CloseSmallIcon className="disable-unsaved" />
        </components.ClearIndicator>
      )
    )
  }
  configComponents.ClearIndicator = ClearIndicator;
  const MultiValueRemove = props => {
    return (
      components.MultiValueRemove && (
        <components.MultiValueRemove {...props}>
          <CloseTagDropdownIcon />
        </components.MultiValueRemove>
      )
    )
  }
  configComponents.MultiValueRemove = MultiValueRemove
	const options = props.getChannelManagers();
	return (
		<div>
			{!!props.label && <Label for={props.name}>{props.label}</Label>}
			<Select
				{...props}
				classNamePrefix="react-select"
				className={classnames(bem.b(), {
					"react-select-menu-open": props.isMenuOpen,
				})}
				placeholder={props.intl.formatMessage({
					id: props.placeholder,
				})}
				value={props.value ? props.value : null}
				options={options}
				onChange={(selected, { action }) => {
					if (action === "remove-value" || action === "clear") {
						const newValue = props.autoSetPrimary(selected);
						props.onChange(newValue);
					}
				}}
				// styles={{
				// 	menu: (provided) => ({
				// 		...provided,
				// 		width: "auto",
				// 	}),
				// }}
				components={configComponents}
				onMenuOpen={() => props.setIsMenuOpen(true)}
				onMenuClose={() => props.setIsMenuOpen(false)}
			/>
			{!!props.error && (
				<span className="text-danger">{props.error}</span>
			)}
		</div>
	);
};
ChannelManagerDropdown.defaultProps = {
	closeMenuOnSelect: false,
	hideSelectedOptions: false,
	isMulti: true,
	placeholder: "channel-manager-dropdown > placeholder",
	label: "",
	error: null,
};
export default ChannelManagerDropdown;
