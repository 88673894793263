import { FormattedMessage } from "react-intl";
import React from "react";

const log = {
  process_step_index: 10,
  key: "log",
  title: <FormattedMessage id="process > title log" />,
  no_edit_button: true,
  description: null,
  isCollapsible: true,
  step_roles: [],
  step_viewers_roles: [],
  step_editors_roles: [],
  step_viewers_privileges: {
    or: ["submit_production_log", "edit_all_production_orders"],
  },
  step_editors_privileges: {
    or: ["submit_production_log", "edit_all_production_orders"],
  },
  className: "log-container",
  status: "initial",
  step_container: "none",
  fields: [
    {
      component: "log",
    },
  ],
};
export default log;
