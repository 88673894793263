import React from "react";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import { get, find, toLower, first } from "lodash";
import { FaSortDesc, FaSortAsc } from "react-icons/lib/fa";
import DeadlineTimer from "components/DeadlineTimer";
import { Button, Table } from "reactstrap";
import NoItemsFound from "components/NoItemsFound";
import useInfiniteScroll from "utils/useInfiniteScroll";
import Spinner from "components/Spinner";
import { default_templates } from "utils/defaults";
import { titleDisplay } from "utils/helpers";

const View = ({
  actionSort,
  sort,
  processList,
  bem,
  onClickRow,
  user,
  hasMoreItems,
  loadItems,
  isLoading,
  onUpdateAssignment,
  assignmentProcessLoading,
}) => {
  const assignmentDisplay = (process) => {
    const roleContinuity = get(process, "_source.role_continuity.users");
    if (!roleContinuity) {
      // case use can take order
      return (
        <Button
          className={classnames("btn-radius", bem.e("take-order"))}
          color="primary"
          onClick={() => onUpdateAssignment(process, "take")}
        >
          <FormattedMessage id="process > button take order" />
        </Button>
      );
    }
    const continuityAssigned = first(roleContinuity);
    const isAssignedToUser = Number(continuityAssigned.id) === Number(user.id);
    if (isAssignedToUser) {
      return (
        <div
          className={classnames(
            bem.e("assignment-action"),
            bem.e("assignment-action-assigned")
          )}
        >
          <span className={bem.e("assignment-name")}>
            {get(continuityAssigned, "name")}
          </span>
          <Button
            className={classnames("btn-radius", bem.e("release"))}
            color="primary"
            outline
            onClick={() => onUpdateAssignment(process, "release")}
          >
            <FormattedMessage id="process > button release" />
          </Button>
        </div>
      );
    }
    return (
      <div className={bem.e("assignment-action")}>
        <span className={bem.e("assignment-name")}>
          {get(continuityAssigned, "name")}
        </span>
      </div>
    );
  };
  const timeToAirDisplay = (process) => {
    const date = get(process, "_source.order_start_date");
    const timezone = get(process, "_source.timezone", null);
    if (!date) return null;
    return <DeadlineTimer date={date} timezone={timezone} />;
  };
  const spotDisplay = (process) => {
    const template_key = get(process, "_source.template_key", "");
    if (template_key === "digital") {
      return (
        <span className={"small"}>
          {" "}
          {process._source.for_marketing_channel &&
            process._source.for_marketing_channel.name}
        </span>
      );
    }
    if (get(process, "_source.contract_status") === "Production Needed")
      return null;
    return get(process, "_source.spot_info.length") > 1
      ? `(+${get(process, "_source.spot_info.length") - 1} spot)`
      : "";
  };
  const typeDisplay = (process) => {
    const template_key = get(process, "_source.template_key", true);

    switch (template_key) {
      case "digital":
        // let media_type = get(process, "_source.for_marketing_channel.name", '');
        const forMarketingChannel = get(
          process,
          "_source.for_marketing_channel",
          {}
        );
        const marketingChannel = find(
          get(process, "_source.marketing_channels", []),
          (channel) => channel.id === forMarketingChannel.id
        );
        const media_type = get(marketingChannel, "media_type.value", "");
        switch (media_type) {
          case "Social Media":
          case "Banner Ad":
          case "Video Ad":
          case "Text Ad":
            return (
              <div
                className={classnames(
                  bem.e("type"),
                  {
                    [bem.e("type-digital")]: true,
                  },
                  bem.e(`type-digital-${media_type.split(" ").join("")}`)
                )}
              >
                <FormattedMessage id={"process > digital " + media_type} />
              </div>
            );
          default:
            return (
              <div
                className={classnames(bem.e("type"), {
                  [bem.e("type-digital")]: true,
                })}
              >
                <FormattedMessage id={"process > digital"} />
              </div>
            );
        }
      case "promotion":
        return (
          <div
            className={classnames(bem.e("type"), {
              [bem.e("type-sold")]: true,
            })}
          >
            <FormattedMessage id={"process > promotion"} />
          </div>
        );
      default:
        const orderSold = get(process, "_source.order_sold", true);
        return (
          <div
            className={classnames(bem.e("type"), {
              [bem.e("type-sold")]: orderSold,
              [bem.e("type-spec")]: !orderSold,
            })}
          >
            <FormattedMessage
              id={orderSold ? "process > order sold" : "process > order spec"}
            />
          </div>
        );
    }
  };
  const statusDisplay = (process) => {
    const processStep = get(process, "_source.process_step", "draft");
    let row_template = default_templates.find(
      (t) => t._id === get(process, "_source.template_key", "basic_production")
    );
    let step = get(row_template, `_source.steps.${processStep}`);
    let title = <FormattedMessage id={"process > title draft"} />;
    let color = "#9AA4CA";
    if (title !== "draft") {
      color = get(step, "caption_color", "#9AA4CA");
    }
    if (get(step, "title")) {
      title = get(step, "title");
      if (step.key === "approve_recording") {
        if (get(process, "_source.sent_to_client_for_spot_review")) {
          title = <FormattedMessage id={"process > title client review"} />;
        }
      }
      if (step.key === "approve_script") {
        if (get(process, "_source.sent_to_client_for_script_review")) {
          title = <FormattedMessage id={"process > title client review"} />;
        }
      }
      if (step.key === "order_details") {
        if (get(process, "_source.order_details_status") === "draft")
          title = "draft";
      }
      if (step.key === "record_script") {
        if (get(process, "_source.approve_recording_status") === "rejected") {
          title = "SPOT CHANGE";
        }
      }
      if (step.key === "write_script") {
        if (get(process, "_source.approve_script_status") === "rejected")
          title = "SCRIPT CHANGE";
      }
      if (step.key === "assign_vo") {
        title = <FormattedMessage id={"process > title assign vo"} />;
        color = "#A82AC0";
      }
      if (step.key === "voice_over") {
        title = <FormattedMessage id={"process > title voice over"} />;
      }
    }

    if (process._source.template_key === "digital") {
      switch (get(process, "_source.process_step")) {
        case "order_details":
          if (get(process, "_source.order_details_status") === "rejected")
            title = "CHANGE REQUEST";
          break;
        case "approve_order":
          title = "APPROVAL";
          break;
        case "design":
          title = "PRODUCTION";
          if (get(process, "_source.design_status") === "rejected") {
            title = "CHANGE REQUEST";
            color = "#E40061";
          }
          break;
        case "review_media":
          title = "REVIEW";
          break;
        default:
          break;
      }
    }
    return (
      <span
        className={classnames(
          bem.e("order-status"),
          bem.e(
            `order-status-${
              title.replace ? toLower(title.replace(/ /g, "-")) : ""
            }`
          )
        )}
        style={{ color }}
      >
        {title}
      </span>
    );
  };

  const renderEmpty = () => {
    return <NoItemsFound />;
  };

  const onSelectProcess = (process) => {
    return onClickRow(process);
  };
  useInfiniteScroll({
    useWindow: false,
    elementId: "cr-app-main",
    onLoadMore: loadItems,
    shouldLoadMore: hasMoreItems,
  });
  return (
    <div className={bem.e("table-wrapper")}>
      <Table className={bem.e("table")}>
        <thead>
          <tr>
            <th
              onClick={() => actionSort("order_sold")}
              className={classnames(
                "can-click",
                "col-table-10",
                bem.e("table-first-head")
              )}
            >
              <div className={bem.e("item-sortable")}>
                <FormattedMessage id="process > Type" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_sold",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_sold desc",
                    })}
                  />
                </div>
              </div>
            </th>

            <th
              onClick={() => actionSort("client_name")}
              className="can-click col-table-15"
            >
              <div className={bem.e("item-sortable")}>
                <FormattedMessage id="process > Client" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "client_name",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "client_name desc",
                    })}
                  />
                </div>
              </div>
            </th>
            <th
              onClick={() => actionSort("order_title")}
              className="can-click col-table-20"
            >
              <div className={bem.e("item-sortable")}>
                <FormattedMessage id="process > Title" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_title",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_title desc",
                    })}
                  />
                </div>
              </div>
            </th>
            <th
              onClick={() => actionSort("order_length")}
              className="can-click col-table-10"
            >
              <div className={bem.e("item-sortable-center")}>
                <FormattedMessage id="process > length" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_length",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_length desc",
                    })}
                  />
                </div>
              </div>
            </th>

            <th className="text-center align-center col-table-20">
              <FormattedMessage id="process > assignment" />
            </th>
            <th
              onClick={() => actionSort("order_start_date")}
              className="can-click col-table-10"
            >
              <div className={bem.e("item-sortable-center")}>
                <FormattedMessage id="process > Deadline" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_start_date",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        sort === "order_start_date desc",
                    })}
                  />
                </div>
              </div>
            </th>
            <th
              onClick={() => actionSort("process_step")}
              className="can-click col-table-15"
            >
              <div className={bem.e("item-sortable-center")}>
                <FormattedMessage id="process > Status" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "process_step",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "process_step desc",
                    })}
                  />
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {processList.length === 0 && !isLoading ? (
            <tr>
              <td colSpan="7">{renderEmpty()}</td>
            </tr>
          ) : (
            processList.map((process) => {
              if (!process._source.template_key) return null;
              let spot = spotDisplay(process);
              return (
                <tr key={process._id}>
                  {/* type */}
                  <td
                    className={classnames(
                      "align-middle col-table-10",
                      bem.e("first-col")
                    )}
                    onClick={() => {
                      onSelectProcess(process);
                    }}
                  >
                    <span
                      className={classnames(
                        bem.e("td-value"),
                        bem.e("type-block")
                      )}
                    >
                      {typeDisplay(process)}
                    </span>
                  </td>
                  {/* client */}
                  <td
                    className={classnames(
                      "align-middle col-table-15",
                      bem.e("client-name")
                    )}
                    onClick={() => onSelectProcess(process)}
                  >
                    <span className={bem.e("td-value")}>
                      {process._source.client_name}
                    </span>
                  </td>
                  {/* title */}
                  <td
                    className={classnames(
                      "align-middle col-table-20",
                      bem.e("order-title")
                    )}
                    onClick={() => onSelectProcess(process)}
                  >
                    <span className={bem.e("td-value")}>
                      {titleDisplay(process)}
                    </span>
                  </td>
                  {/* order_length */}
                  <td
                    className="align-middle col-table-10 text-center"
                    onClick={() => onSelectProcess(process)}
                  >
                    <span className={bem.e("td-value")}>
                      {process._source.order_length
                        ? `:${process._source.order_length}`
                        : ""}
                    </span>
                  </td>
                  {/* assignments */}
                  <td
                    className={classnames(
                      "align-middle col-table-20 text-center"
                    )}
                  >
                    <span className={bem.e("td-value")}>
                      {assignmentDisplay(process)}
                    </span>
                  </td>
                  {/* time to air */}
                  <td
                    className="align-middle col-table-10 text-center"
                    onClick={() => onSelectProcess(process)}
                  >
                    <span className={bem.e("td-value")}>
                      {timeToAirDisplay(process)}
                    </span>
                  </td>
                  {/* status */}
                  <td
                    className="align-middle col-table-15 text-center"
                    onClick={() => onSelectProcess(process)}
                  >
                    <span className={bem.e("td-value")}>
                      {statusDisplay(process)}
                    </span>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </Table>
      <Spinner isLoading={assignmentProcessLoading} />
    </div>
  );
};
export default View;
