import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { get } from "lodash";
import ServerFormSection from "./ServerFormSection";
import ModuleEntitiesAssignment from "./ModuleEntitiesAssignment";

class ProductionConfiguration extends Component {
  onSwitchToggle = (checked) => {
    const { formProps } = this.props;
    formProps.setFieldValue("is_production", checked);
    formProps.setFieldTouched("is_production", true);
    if (!checked) {
      formProps.setFieldValue("production_multimarket", false);
      formProps.setFieldTouched("production_multimarket", true);
    }
  };

  render() {
    const { bem, edit, formProps, intl } = this.props;
    const moduleEnabled = get(formProps, "values.is_production", false);
    const multiMarketEnabled = get(
      formProps,
      "values.production_multimarket",
      false
    );

    return (
      <ServerFormSection
        bem={bem}
        checked={moduleEnabled}
        titleId="server > production"
        sectionClass={bem.e("box-production-details")}
        sectionFieldsClass={bem.e(
          "section-view-production-configuration-fields"
        )}
        hasToggle={edit}
        onSwitchToggle={this.onSwitchToggle}
        showMultiMarket={false} //edit ? true : false
        onChangeMultiMarket={(checked) => {
          formProps.setFieldValue("production_multimarket", checked);
          formProps.setFieldTouched("production_multimarket", true);
        }}
        intl={intl}
        multiMarketEnabled={multiMarketEnabled}
      >
        <ModuleEntitiesAssignment
          {...this.props}
          moduleEnabled={moduleEnabled}
          featureModule={"production"}
        />
      </ServerFormSection>
    );
  }
}

export default injectIntl(ProductionConfiguration);
