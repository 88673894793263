import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";
import { get } from 'lodash';

import TeamWarningModal from "./view";
import { getTeam } from "store/actions/teams";

export default injectIntl(
  compose(
    connect(
      (state, ownProps) => {
        return {
          selectedTeam: get(state, `teams.viewedTeams.${ownProps.selectedTeamId}`, {}),
        }
      },
      {
        getTeam,
      }
    ),
    lifecycle({
      componentDidMount() {
      	this.props.getTeam(this.props.selectedTeamId);
      },
    })
  )(TeamWarningModal)
);
