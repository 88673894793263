import * as React from "react";
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";
import { Row, Col, FormGroup, Form, Button, Label } from "reactstrap";
import Spinner from "components/Spinner";
import ToastManager from "components/ToastManager";
import Checkbox from "components/Checkbox";
import bn from "utils/bemnames";
import URL from "utils/urls";
import TextInput from "components/TextInput";
import TextInputFormatted from "components/TextInputFormatted";
import {
  PhoneNumberFormat,
  statesList as statesListUs,
  statesListCanada,
} from "utils/config";
import { get, map, isEqual, find, isString } from "lodash";
import Dropdown from "components/Dropdown";
import { FormattedMessage } from "react-intl";
import { PlusSmallIcon, DeleteIcon } from "components/CustomIcons";
import BlockingComponent from "components/BlockingComponent";
import ConfirmationModal from "components/ConfirmationModal";
import {
  ShadowBox,
  SectionTitle,
  SectionFields,
  Asterisk,
} from "components/Elements";
import PrivilegedComponent from "components/PrivilegedComponent";
import classnames from "classnames";
import categories from "utils/categories";
import { PRIVILEGES } from "utils/constants";

const bem = bn.create("client-manager-form");
function getContacts(items) {
  const contacts = map(items, (contact) => {
    return {
      id: contact.id,
      contract_client_contact: get(contact, "contract_client_contact", ""),
      contract_client_nickname: get(contact, "contract_client_nickname", ""),
      contract_client_email: get(contact, "contract_client_email", ""),
      contract_client_phone: get(contact, "contract_client_phone", ""),
      is_production: get(contact, "is_production", false),
      is_digital: get(contact, "is_digital", false),
      is_promotion: get(contact, "is_promotion", false),
      primary_production: get(contact, "primary_production", false),
      primary_digital: get(contact, "primary_digital", false),
      primary_promotion: get(contact, "primary_promotion", false),
    };
  });
  return contacts;
}
function ContentForm(props) {
  const formikRef = React.useRef();
  const { client, shouldBlockNavigation, isLoading, intl, auth } = props;
  const isEnableDigital = get(auth, "info.enable_digital", false);
  const isEnablePromotion = get(auth, "info.enable_promotion", false);
  const isEnableProduction = get(auth, "info.enable_production", false);
  const BGCountry = get(auth, "info.server_country", "US");
  let statesList = BGCountry === "CA" ? statesListCanada : statesListUs;
  const initialValues = {
    id: get(client, "id", ""),
    wo_id: get(client, "wo_id", ""),
    client_name: get(client, "client_name", ""),
    contract_category: get(client, "contract_category", ""),
    add1: get(client, "add1", ""),
    add2: get(client, "add2", ""),
    city: get(client, "city", ""),
    state: get(client, "state", ""),
    zip: get(client, "zip", ""),
    agency: get(client, "agency", ""),
    contacts:
      get(client, "contacts.length", 0) > 0
        ? getContacts(client.contacts)
        : [
            {
              contract_client_contact: "",
              contract_client_nickname: "",
              contract_client_email: "",
              contract_client_phone: "",
              is_production: true,
              is_digital: true,
              is_promotion: true,
              primary_production: true,
              primary_digital: true,
              primary_promotion: true,
            },
          ],
  };

  const onChangePrimary = ({ contactIndex, checked, key, formProps }) => {
    const previousPrimaryContactIndex = get(
      formProps,
      "values.contacts",
      []
    ).findIndex((item) => item[key]);
    if (previousPrimaryContactIndex !== -1) {
      // set to false
      formProps.setFieldValue(
        `contacts.${previousPrimaryContactIndex}.${key}`,
        false
      );
    }
    formProps.setFieldValue(`contacts.${contactIndex}.${key}`, checked);
  };
  const formElement = (
    <Formik
      enableReinitialize
      ref={formikRef}
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        client_name: Yup.string().required(
          intl.formatMessage({
            id: "validate > client name is required",
          })
        ),
        contract_category: Yup.string().nullable(),
        add1: Yup.string().nullable(),
        add2: Yup.string().nullable(),
        city: Yup.string().nullable(),
        state: Yup.string().nullable(),
        zip: Yup.string().nullable(),
        agency: Yup.string().nullable(),
        contacts: Yup.array()
          .of(
            Yup.object().shape({
              contract_client_contact: Yup.string().required(
                intl.formatMessage({ id: "validate > name is required" })
              ),
              contract_client_nickname: Yup.string().nullable(),
              contract_client_email: Yup.string()
                .email(
                  intl.formatMessage({
                    id: "validate > invalid email",
                  })
                )
                .required(
                  intl.formatMessage({
                    id: "validate > email is required",
                  })
                ),
              contract_client_phone: Yup.string()
                .nullable()
                .test(
                  "len",
                  intl.formatMessage({
                    id: "validate > phone number must be 10 digits",
                  }),
                  (val) => !val || val.length === 10
                ),

              is_production: Yup.boolean(),
              is_digital: Yup.boolean(),
              is_promotion: Yup.boolean(),
              primary_production: Yup.boolean(),
              primary_digital: Yup.boolean(),
              primary_promotion: Yup.boolean(),
            })
          )
          .test(
            "least_one_contact_for_each_product",
            intl.formatMessage({
              id: "validate > at least one contact for each product",
            }),
            (value) => {
              const isDigital = !!find(value, (item) => item.is_digital);
              const isProduction = !!find(value, (item) => item.is_production);
              const isPromotion = !!find(value, (item) => item.is_promotion);
              if (isDigital || isProduction || isPromotion) return true;
              return false;
            }
          ),
      })}
      onSubmit={props.onSubmit}
    >
      {(formProps) => {
        const errors = formProps.errors;
        const touched = formProps.touched;
        const values = formProps.values;
        const isChanged = !isEqual(initialValues, values);
        if (isChanged !== shouldBlockNavigation) {
          setTimeout(() => props.setShouldBlockNavigation(isChanged), 20);
        }
        const selectedState = find(
          statesList,
          (item) => item.value === values.state
        );
        return (
          <Form onSubmit={formProps.handleSubmit}>
            <div className={bem.e("content-form")}>
              <ShadowBox
                className={classnames(
                  bem.e("box-client"),
                  bem.e("box-client-details")
                )}
              >
                <SectionTitle className={bem.e("section-title")}>
                  <FormattedMessage id="client manager > client details" />
                </SectionTitle>
                <SectionFields
                  className={classnames(
                    bem.e("section-fields"),
                    bem.e("section-client-fields")
                  )}
                >
                  <Row>
                    <Col xs={6} className={bem.e("reset")}>
                      <FormGroup>
                        <TextInput
                          label={
                            <span>
                              <FormattedMessage id="client manager > client name" />
                              <Asterisk>*</Asterisk>
                            </span>
                          }
                          type="text"
                          name="client_name"
                          placeholder={""}
                          value={values.client_name}
                          onChange={formProps.handleChange}
                          onBlur={formProps.handleBlur}
                          error={touched.client_name && errors.client_name}
                          disabled={get(values, "wo_id", false)}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={6} className={bem.e("reset")}>
                      <FormGroup>
                        <Dropdown
                          label={
                            <FormattedMessage id="process > field product category" />
                          }
                          value={
                            find(
                              categories,
                              (item) => item.value === values.contract_category
                            ) || ""
                          }
                          placeholder={intl.formatMessage({
                            id: "client manager > select",
                          })}
                          onChange={(selectedOption) => {
                            formProps.setFieldValue(
                              "contract_category",
                              selectedOption.value
                            );
                          }}
                          options={categories}
                          name="contract_category"
                          error={
                            touched.contract_category &&
                            errors.contract_category
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} className={bem.e("reset")}>
                      <FormGroup>
                        <TextInput
                          label={
                            <FormattedMessage id="client manager > address 1" />
                          }
                          type="text"
                          name="add1"
                          placeholder={intl.formatMessage({
                            id: "client manager > enter address",
                          })}
                          value={values.add1}
                          onChange={formProps.handleChange}
                          onBlur={formProps.handleBlur}
                          error={touched.add1 && errors.add1}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={6} className={bem.e("reset")}>
                      <FormGroup>
                        <TextInput
                          label={
                            <FormattedMessage id="client manager > address 2" />
                          }
                          type="text"
                          name="add2"
                          placeholder={intl.formatMessage({
                            id: "client manager > enter address",
                          })}
                          value={values.add2}
                          onChange={formProps.handleChange}
                          onBlur={formProps.handleBlur}
                          error={touched.add2 && errors.add2}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} className={bem.e("reset")}>
                      <FormGroup>
                        <TextInput
                          label={
                            <FormattedMessage id="client manager > city" />
                          }
                          type="text"
                          name="city"
                          placeholder={intl.formatMessage({
                            id: "client manager > enter city",
                          })}
                          value={values.city}
                          onChange={formProps.handleChange}
                          onBlur={formProps.handleBlur}
                          error={touched.city && errors.city}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={6} className={bem.e("reset")}>
                      <Row>
                        <Col xs={6} className={bem.e("reset")}>
                          <FormGroup>
                            <Dropdown
                              label={
                                <FormattedMessage
                                  id={
                                    BGCountry === "CA"
                                      ? "client manager > province"
                                      : "client manager > state"
                                  }
                                />
                              }
                              type="text"
                              name="state"
                              placeholder={intl.formatMessage({
                                id:
                                  BGCountry === "CA"
                                    ? "client manager > enter province"
                                    : "client manager > enter state",
                              })}
                              value={selectedState}
                              error={touched.state && errors.state}
                              onChange={(option) => {
                                formProps.setFieldValue("state", option.value);
                              }}
                              onBlur={formProps.handleBlur}
                              options={statesList}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={6} className={bem.e("reset")}>
                          <FormGroup>
                            <TextInput
                              label={
                                <FormattedMessage
                                  id={
                                    BGCountry === "CA"
                                      ? "client manager > postal code"
                                      : "client manager > zip code"
                                  }
                                />
                              }
                              type="text"
                              name="zip"
                              placeholder={intl.formatMessage({
                                id:
                                  BGCountry === "CA"
                                    ? "client manager > enter postal code"
                                    : "client manager > enter zip code",
                              })}
                              value={values.zip}
                              onChange={formProps.handleChange}
                              onBlur={formProps.handleBlur}
                              error={touched.zip && errors.zip}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} className={bem.e("reset")}>
                      <FormGroup>
                        <TextInput
                          label={
                            <FormattedMessage id="client manager > agency" />
                          }
                          type="text"
                          name="agency"
                          placeholder={intl.formatMessage({
                            id: "client manager > enter agency",
                          })}
                          value={values.agency}
                          onChange={formProps.handleChange}
                          onBlur={formProps.handleBlur}
                          error={touched.agency && errors.agency}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </SectionFields>
              </ShadowBox>
              <ShadowBox
                className={classnames(
                  bem.e("box-client"),
                  bem.e("box-client-contacts")
                )}
              >
                <SectionTitle
                  className={classnames(
                    bem.e("section-title"),
                    bem.e("section-contacts-title")
                  )}
                >
                  <FormattedMessage id="client manager > client contacts" />
                </SectionTitle>
                <SectionFields
                  className={classnames(
                    bem.e("section-fields"),
                    bem.e("section-contact-fields")
                  )}
                >
                  <FieldArray name="contacts">
                    {(arrayHelpers) => {
                      return (
                        <React.Fragment>
                          {map(values.contacts, (contact, index) => (
                            <div
                              key={index}
                              className={bem.e("contact-container")}
                            >
                              <h2 className={bem.e("contact-title")}>
                                <span>
                                  <FormattedMessage id="client manager > contact" />{" "}
                                  {index + 1}
                                </span>
                                <button
                                  className={bem.e("contact-remove")}
                                  onClick={() => {
                                    if (values.contacts.length > 1) {
                                      arrayHelpers.remove(index);
                                    }
                                  }}
                                  disabled={values.contacts.length <= 1}
                                >
                                  <DeleteIcon
                                    color={
                                      values.contacts.length > 1
                                        ? "#795afa"
                                        : "#C2D4E0"
                                    }
                                  />
                                </button>
                              </h2>
                              <Row>
                                <Col xs={6}>
                                  <FormGroup>
                                    <TextInput
                                      label={
                                        <span>
                                          <FormattedMessage id="client manager > contact name" />
                                          <Asterisk>*</Asterisk>
                                        </span>
                                      }
                                      type="text"
                                      name={`contacts.${index}.contract_client_contact`}
                                      placeholder={intl.formatMessage({
                                        id: "client manager > enter name",
                                      })}
                                      value={contact.contract_client_contact}
                                      onChange={formProps.handleChange}
                                      onBlur={formProps.handleBlur}
                                      error={
                                        get(
                                          touched,
                                          `contacts.${index}.contract_client_contact`
                                        ) &&
                                        get(
                                          errors,
                                          `contacts.${index}.contract_client_contact`
                                        )
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col xs={6}>
                                  <FormGroup>
                                    <TextInput
                                      label={
                                        <FormattedMessage id="client manager > contact nickname" />
                                      }
                                      type="text"
                                      name={`contacts.${index}.contract_client_nickname`}
                                      placeholder={intl.formatMessage({
                                        id: "client manager > enter nickname",
                                      })}
                                      value={contact.contract_client_nickname}
                                      onChange={formProps.handleChange}
                                      onBlur={formProps.handleBlur}
                                      error={
                                        get(
                                          touched,
                                          `contacts.${index}.contract_client_nickname`
                                        ) &&
                                        get(
                                          errors,
                                          `contacts.${index}.contract_client_nickname`
                                        )
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={6}>
                                  <FormGroup>
                                    <TextInput
                                      label={
                                        <span>
                                          <FormattedMessage id="client manager > contact email" />
                                          <Asterisk>*</Asterisk>
                                        </span>
                                      }
                                      type="text"
                                      name={`contacts.${index}.contract_client_email`}
                                      placeholder={intl.formatMessage({
                                        id: "client manager > enter email",
                                      })}
                                      value={contact.contract_client_email}
                                      onChange={formProps.handleChange}
                                      onBlur={formProps.handleBlur}
                                      error={
                                        get(
                                          touched,
                                          `contacts.${index}.contract_client_email`
                                        ) &&
                                        get(
                                          errors,
                                          `contacts.${index}.contract_client_email`
                                        )
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col xs={6}>
                                  <FormGroup>
                                    <TextInputFormatted
                                      label={
                                        <FormattedMessage id="client manager > contact phone" />
                                      }
                                      format={PhoneNumberFormat}
                                      name={`contacts.${index}.contract_client_phone`}
                                      placeholder={intl.formatMessage({
                                        id: "client manager > enter phone",
                                      })}
                                      value={contact.contract_client_phone}
                                      onChange={(phone) =>
                                        formProps.setFieldValue(
                                          `contacts.${index}.contract_client_phone`,
                                          phone
                                        )
                                      }
                                      onBlur={formProps.handleBlur}
                                      error={
                                        get(
                                          touched,
                                          `contacts.${index}.contract_client_phone`
                                        ) &&
                                        get(
                                          errors,
                                          `contacts.${index}.contract_client_phone`
                                        )
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={6}>
                                  <FormGroup>
                                    <Label>
                                      <FormattedMessage id="client manager > primary contact" />
                                    </Label>
                                    <div className={bem.e("checkboxes")}>
                                      {isEnableProduction && (
                                        <Checkbox
                                          checked={contact.primary_production}
                                          onChange={(checked) => {
                                            onChangePrimary({
                                              contactIndex: index,
                                              checked,
                                              key: "primary_production",
                                              formProps,
                                            });
                                          }}
                                          text={
                                            <FormattedMessage id="client manager > production" />
                                          }
                                        />
                                      )}
                                      {isEnableDigital && (
                                        <Checkbox
                                          checked={contact.primary_digital}
                                          onChange={(checked) => {
                                            onChangePrimary({
                                              contactIndex: index,
                                              checked,
                                              key: "primary_digital",
                                              formProps,
                                            });
                                          }}
                                          text={
                                            <FormattedMessage id="client manager > digital" />
                                          }
                                        />
                                      )}
                                      {isEnablePromotion && (
                                        <Checkbox
                                          checked={contact.primary_promotion}
                                          onChange={(checked) => {
                                            onChangePrimary({
                                              contactIndex: index,
                                              checked,
                                              key: "primary_promotion",
                                              formProps,
                                            });
                                          }}
                                          text={
                                            <FormattedMessage id="client manager > promotions" />
                                          }
                                        />
                                      )}
                                    </div>
                                  </FormGroup>
                                </Col>
                                <Col xs={6}>
                                  <FormGroup>
                                    <Label>
                                      <FormattedMessage id="client manager > use contact for" />
                                    </Label>
                                    <div className={bem.e("checkboxes")}>
                                      {isEnableProduction && (
                                        <Checkbox
                                          checked={contact.is_production}
                                          onChange={(checked) => {
                                            formProps.setFieldValue(
                                              `contacts.${index}.is_production`,
                                              checked
                                            );
                                          }}
                                          text={
                                            <FormattedMessage id="client manager > production" />
                                          }
                                        />
                                      )}
                                      {isEnableDigital && (
                                        <Checkbox
                                          checked={contact.is_digital}
                                          onChange={(checked) => {
                                            formProps.setFieldValue(
                                              `contacts.${index}.is_digital`,
                                              checked
                                            );
                                          }}
                                          text={
                                            <FormattedMessage id="client manager > digital" />
                                          }
                                        />
                                      )}
                                      {isEnablePromotion && (
                                        <Checkbox
                                          checked={contact.is_promotion}
                                          onChange={(checked) => {
                                            formProps.setFieldValue(
                                              `contacts.${index}.is_promotion`,
                                              checked
                                            );
                                          }}
                                          text={
                                            <FormattedMessage id="client manager > promotions" />
                                          }
                                        />
                                      )}
                                    </div>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </div>
                          ))}
                          <div className={bem.e("contact-button")}>
                            <Button
                              className={classnames(
                                bem.e("add-button"),
                                "btn-radius"
                              )}
                              outline
                              color="blue"
                              onClick={() => {
                                arrayHelpers.push({
                                  contract_client_contact: "",
                                  contract_client_nickname: "",
                                  contract_client_email: "",
                                  contract_client_phone: "",
                                  is_production: false,
                                  is_digital: false,
                                  is_promotion: false,
                                  primary_production: false,
                                  primary_digital: false,
                                  primary_promotion: false,
                                });
                              }}
                            >
                              <span className={bem.e("add-icon")}>
                                <PlusSmallIcon />
                              </span>
                              <FormattedMessage id="client manager > add a contact" />
                            </Button>
                            {touched.contacts &&
                              errors.contacts &&
                              isString(errors.contacts) && (
                                <span className="alert-danger">
                                  {errors.contacts}
                                </span>
                              )}
                          </div>
                        </React.Fragment>
                      );
                    }}
                  </FieldArray>
                </SectionFields>
              </ShadowBox>
            </div>
            <div className={"bottom-actions-sticky"}>
              <div className={"buttons"}>
                <Button
                  color="blue"
                  type="submit"
                  className="btn-radius"
                  disabled={isLoading}
                  onClick={() => {
                    setTimeout(() => {
                      const formErrors = get(formikRef, "current.state.errors");
                      if (formErrors && Object.keys(formErrors).length) {
                        ToastManager.show({
                          title: props.intl.formatMessage({
                            id: "toast > title not saved",
                          }),
                          message: props.intl.formatMessage({
                            id:
                              "toast > message error please correct the hilighted fields",
                          }),
                          level: "error",
                        });
                      }
                    }, 40);
                  }}
                >
                  <FormattedMessage
                    id={`client manager > button ${
                      !isLoading ? "save" : "saving"
                    }`}
                  />
                </Button>
                <Button
                  color="blue"
                  type="button"
                  outline
                  className="btn-radius"
                  onClick={() => {
                    if (props.onCancel) {
                      props.onCancel();
                    } else {
                      props.history.push(URL.CLIENT_MANAGER());
                    }
                  }}
                >
                  <FormattedMessage id={`client manager > button cancel`} />
                </Button>
              </div>
            </div>
            <ConfirmationModal
              isOpen={props.isConfirmationSuccessModal}
              title={
                get(client, "id") ? (
                  <FormattedMessage id="client manager > client details updated" />
                ) : (
                  <FormattedMessage id="client manager > client details created" />
                )
              }
              onCancel={() => props.setIsConfirmationSuccessModal(false)}
              className={bem.e("success-modal")}
              state="success"
            />
          </Form>
        );
      }}
    </Formik>
  );
  if (props.isModal) return formElement;
  return (
    <React.Fragment>
      {formElement}
      <BlockingComponent
        isBlocked={shouldBlockNavigation}
        modalProps={{
          isShowConfirm: true,
          isShowDiscard: false,
          isCancelConfirmedNavigation: true,
          onCancel: () => {
            props.setShouldBlockNavigation(false); // reset
          },
          // onConfirm: () => {
          //   const buttonSubmit = document.getElementById(`btn-submit`);
          //   // trigger click to submit the form
          //   if (buttonSubmit) buttonSubmit.click();
          // },
        }}
      />
    </React.Fragment>
  );
  // return (
  //   <WarnAboutUnsavedChanges
  //     when={shouldBlockNavigation}
  //     children={formElement}
  //   />
  // );
}
function ContentView(props) {
  const { client, history, auth, showCancel } = props;
  const isEnableDigital = get(auth, "info.enable_digital", false);
  const isEnablePromotion = get(auth, "info.enable_promotion", false);
  const isEnableProduction = get(auth, "info.enable_production", false);
  const BGCountry = get(auth, "info.server_country", "US");
  let statesList = BGCountry === "CA" ? statesListCanada : statesListUs;
  return (
    <Form>
      <div className={bem.e("content-view")}>
        <ShadowBox className={bem.e("box-client-details")}>
          <SectionTitle className={bem.e("section-title")}>
            <FormattedMessage id="client manager > client details" />
          </SectionTitle>
          <SectionFields
            className={classnames(
              bem.e("section-fields"),
              bem.e("section-client-fields")
            )}
          >
            <Row>
              <Col className={bem.e("col-view-field")}>
                <Label>
                  <FormattedMessage id="client manager > client name" />
                </Label>
                <span className={bem.e("order-view-value")}>
                  {get(client, "client_name") ? (
                    get(client, "client_name")
                  ) : (
                    <FormattedMessage id="client manager > none" />
                  )}
                </span>
              </Col>
              <Col className={bem.e("col-view-field")}>
                <Label>
                  <FormattedMessage id="client manager > product category" />
                </Label>
                <span className={bem.e("order-view-value")}>
                  {get(client, "contract_category") ? (
                    get(
                      find(
                        categories,
                        (item) => item.value === client.contract_category
                      ),
                      "label"
                    )
                  ) : (
                    <FormattedMessage id="client manager > none" />
                  )}
                </span>
              </Col>
            </Row>
            <Row>
              <Col className={bem.e("col-view-field")}>
                <Label>
                  <FormattedMessage id="client manager > address 1" />{" "}
                </Label>
                <span className={bem.e("order-view-value")}>
                  {get(client, "add1") ? (
                    get(client, "add1")
                  ) : (
                    <FormattedMessage id="client manager > none" />
                  )}
                </span>
              </Col>
              <Col className={bem.e("col-view-field")}>
                <Label>
                  <FormattedMessage id="client manager > address 2" />
                </Label>
                <span className={bem.e("order-view-value")}>
                  {get(client, "add2") ? (
                    get(client, "add2")
                  ) : (
                    <FormattedMessage id="client manager > none" />
                  )}
                </span>
              </Col>
            </Row>
            <Row>
              <Col className={bem.e("col-view-field")}>
                <Label>
                  <FormattedMessage id="client manager > city" />
                </Label>
                <span className={bem.e("order-view-value")}>
                  {get(client, "city") ? (
                    get(client, "city")
                  ) : (
                    <FormattedMessage id="client manager > none" />
                  )}
                </span>
              </Col>

              <Col xs={6} className={bem.e("reset")}>
                <Row>
                  <Col className={bem.e("col-view-field")}>
                    <Label>
                      <FormattedMessage
                        id={
                          BGCountry === "CA"
                            ? "client manager > province"
                            : "client manager > state"
                        }
                      />
                    </Label>
                    <span className={bem.e("order-view-value")}>
                      {get(client, "state") ? (
                        get(
                          find(
                            statesList,
                            (item) => item.value === client.state
                          ),
                          "label"
                        )
                      ) : (
                        <FormattedMessage id="client manager > none" />
                      )}
                    </span>
                  </Col>
                  <Col className={bem.e("col-view-field")}>
                    <Label>
                      <FormattedMessage
                        id={
                          BGCountry === "CA"
                            ? "client manager > postal code"
                            : "client manager > zip code"
                        }
                      />
                    </Label>
                    <span className={bem.e("order-view-value")}>
                      {get(client, "zip") ? (
                        get(client, "zip")
                      ) : (
                        <FormattedMessage id="client manager > none" />
                      )}
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col className={bem.e("col-view-field")}>
                <Label>
                  <FormattedMessage id="client manager > agency" />
                </Label>
                <span className={bem.e("order-view-value")}>
                  {get(client, "agency") ? (
                    get(client, "agency")
                  ) : (
                    <FormattedMessage id="client manager > none" />
                  )}
                </span>
              </Col>
            </Row>
          </SectionFields>
        </ShadowBox>
        <ShadowBox className={bem.e("box-client-details")}>
          <SectionTitle className={bem.e("section-title")}>
            <FormattedMessage id="client manager > client contacts" />
          </SectionTitle>
          <SectionFields
            className={classnames(
              bem.e("section-fields"),
              bem.e("section-contact-fields")
            )}
          >
            <React.Fragment>
              {map(
                getContacts(get(client, "contacts", [])),
                (contact, index) => (
                  <div key={index} className={bem.e("contact-container")}>
                    <h2 className={bem.e("contact-title")}>
                      {index === 0 ? (
                        <FormattedMessage id="client manager > primary contact" />
                      ) : (
                        <span>
                          <FormattedMessage id="client manager > contact" />
                          {` `}
                          {index + 1}
                        </span>
                      )}
                    </h2>
                    <Row>
                      <Col className={bem.e("col-view-field")}>
                        <Label>
                          <FormattedMessage id="client manager > contact name" />
                        </Label>
                        <span className={bem.e("order-view-value")}>
                          {get(contact, "contract_client_contact") ? (
                            get(contact, "contract_client_contact")
                          ) : (
                            <FormattedMessage id="client manager > none" />
                          )}
                        </span>
                      </Col>
                      <Col className={bem.e("col-view-field")}>
                        <Label>
                          <FormattedMessage id="client manager > contact nickname" />
                        </Label>
                        <span className={bem.e("order-view-value")}>
                          {get(contact, "contract_client_nickname") ? (
                            get(contact, "contract_client_nickname")
                          ) : (
                            <FormattedMessage id="client manager > none" />
                          )}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col className={bem.e("col-view-field")}>
                        <Label>
                          <FormattedMessage id="client manager > contact email" />
                        </Label>
                        <span className={bem.e("order-view-value")}>
                          {get(contact, "contract_client_email") ? (
                            get(contact, "contract_client_email")
                          ) : (
                            <FormattedMessage id="client manager > none" />
                          )}
                        </span>
                      </Col>
                      <Col className={bem.e("col-view-field")}>
                        <Label>
                          <FormattedMessage id="client manager > contact phone" />
                        </Label>
                        <span className={bem.e("order-view-value")}>
                          {get(contact, "contract_client_phone") ? (
                            get(contact, "contract_client_phone")
                          ) : (
                            <FormattedMessage id="client manager > none" />
                          )}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6}>
                        <FormGroup>
                          <Label>
                            <FormattedMessage id="client manager > primary contact" />
                          </Label>
                          <div className={bem.e("checkboxes")}>
                            {isEnableProduction && (
                              <Checkbox
                                checked={contact.primary_production}
                                onChange={() => {}}
                                text={
                                  <FormattedMessage id="client manager > production" />
                                }
                              />
                            )}
                            {isEnableDigital && (
                              <Checkbox
                                checked={contact.primary_digital}
                                onChange={() => {}}
                                text={
                                  <FormattedMessage id="client manager > digital" />
                                }
                              />
                            )}

                            {isEnablePromotion && (
                              <Checkbox
                                checked={contact.primary_promotion}
                                onChange={() => {}}
                                text={
                                  <FormattedMessage id="client manager > promotions" />
                                }
                              />
                            )}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col xs={6}>
                        <FormGroup>
                          <Label>
                            <FormattedMessage id="client manager > use contact for" />
                          </Label>
                          <div className={bem.e("checkboxes")}>
                            {isEnableProduction && (
                              <Checkbox
                                checked={contact.is_production}
                                onChange={() => {}}
                                text={
                                  <FormattedMessage id="client manager > production" />
                                }
                              />
                            )}
                            {isEnableDigital && (
                              <Checkbox
                                checked={contact.is_digital}
                                onChange={() => {}}
                                text={
                                  <FormattedMessage id="client manager > digital" />
                                }
                              />
                            )}

                            {isEnablePromotion && (
                              <Checkbox
                                checked={contact.is_promotion}
                                onChange={() => {}}
                                text={
                                  <FormattedMessage id="client manager > promotions" />
                                }
                              />
                            )}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                )
              )}
            </React.Fragment>
          </SectionFields>
        </ShadowBox>
      </div>
      <div className={"bottom-actions-sticky"}>
        <div className={"buttons"}>
          <PrivilegedComponent requires={[PRIVILEGES.EDIT_CLIENT]}>
            <Button
              color="blue"
              type="submit"
              className="btn btn-radius"
              onClick={() => {
                history.push(
                  URL.CLIENT_MANAGER_FORM({
                    action: "edit",
                    client_id: client.id,
                  })
                );
              }}
            >
              <FormattedMessage id={`client manager > button edit`} />
            </Button>
          </PrivilegedComponent>
          {showCancel && (
            <Button
              color="blue"
              type="button"
              outline
              className="btn btn-radius"
              onClick={() => props.history.push(URL.CLIENT_MANAGER())}
            >
              <FormattedMessage id={`client manager > button cancel`} />
            </Button>
          )}
        </div>
      </div>
    </Form>
  );
}
function ClientManagerFrom(props) {
  const isEdit =
    document.location.pathname.split("/")[2] === "view" ? false : true;
  const showCancel = get(props, "location.state.showCancel", true);
  return (
    <div className={bem.b()}>
      {isEdit ? (
        <ContentForm {...props} />
      ) : (
        <ContentView {...props} showCancel={showCancel} />
      )}
      <Spinner loading={props.isLoading} />
    </div>
  );
}
export default ClientManagerFrom;
