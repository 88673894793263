import React from "react";
import { get, find } from "lodash";
import { Redirect } from "react-router";
import TextInput from "components/TextInput";
import Spinner from "components/Spinner";
import * as Yup from "yup";
import { Formik } from "formik";
import RadioButtons from "components/RadioButtons";
import { FormattedMessage } from "react-intl";
import PrivilegedComponent from "components/PrivilegedComponent";
// import SelectEffective from "components/SelectEffective";
import { Row, Col, Button, Form, FormGroup } from "reactstrap";
import { PRIVILEGES } from "utils/constants";
// import Dropdown from "components/Dropdown";
import URL from "utils/urls";
import bn from "utils/bemnames";
const bem = bn.create("admin-rights");
class AdminRights extends React.Component {
  getDefaultBroadcastingGroupAdmin = (serverList) => {
    const { auth, user } = this.props;
    const userDetails = get(user, "data", {});

    const authIsGodAdmin = get(auth, "user.is_god_admin", false);
    if (window.location.href.indexOf("admin.ps.com") === -1 && authIsGodAdmin) {
      const serverId = get(auth, "info.server_id");
      return find(serverList, (item) => item.value === serverId);
    } else if (userDetails.broadcasting_group_admin) {
      return find(
        serverList,
        (item) => item.value === userDetails.broadcasting_group_admin
      );
    }
    return "";
  };
  renderForm() {
    const {
      isLoading,
      isUpdate,
      // stations,
      auth,
      user,
      isMultiple,
      // servers,
    } = this.props;
    // const isLoggedAsSpecific =
    //   window.location.href.indexOf("admin.ps.com") === -1;
    const userDetails = get(user, "data", {});
    const authIsGodAdmin = get(auth, "user.is_god_admin", false);
    const authIsBroadcastingGroupAdmin = get(
      auth,
      "user.broadcasting_group_admin",
      false
    );
    // const serverList = map(servers, (server) => ({
    //   value: server.id,
    //   label: server.name,
    // }));
    return (
      <PrivilegedComponent
        requires={(privileges) => {
          if (authIsGodAdmin || authIsBroadcastingGroupAdmin) return true;
          if (isUpdate) return privileges.indexOf(PRIVILEGES.EDIT_USERS) > -1;
          else return privileges.indexOf(PRIVILEGES.ADD_USERS) > -1;
        }}
      >
        {({ isValid }) => {
          if (isValid) {
            return (
              <Formik
                enableReinitialize
                initialValues={{
                  // is_broadcasting_group_admin: Boolean(
                  //   adminType === "broadcasting_group_admin"
                  // ),
                  // is_market_admin: Boolean(adminType === "market_admin"),
                  is_god_admin: Boolean(userDetails.is_god_admin),
                  broadcasting_group_admin: get(
                    userDetails,
                    "broadcasting_group_admin",
                    false
                  ),
                  admin_type: get(userDetails, "admin_type", ""),
                  password: "",
                  // stations: map(get(userDetails, "admin_stations"), (item) => ({
                  //   id: item.user_station_id,
                  //   label: item.name,
                  // })),
                }}
                validationSchema={Yup.object().shape({
                  // is_market_admin: Yup.bool(),
                  // is_broadcasting_group_admin: Yup.bool(),
                  is_god_admin: Yup.bool(),
                  admin_type: Yup.string().required(
                    this.props.intl.formatMessage({
                      id: "validate > admin type is required",
                    })
                  ),
                  // broadcasting_group_admin: Yup.mixed().nullable(),
                  // stations: Yup.mixed().when(["is_market_admin"], {
                  //   is: true,
                  //   then: Yup.array().required(
                  //     this.props.intl.formatMessage({
                  //       id: "validate > stations is required",
                  //     })
                  //   ),
                  //   otherwise: Yup.string().nullable(),
                  // }),
                  password: Yup.string()
                    .required(
                      this.props.intl.formatMessage({
                        id: "validate > password is required",
                      })
                    )
                    .min(
                      6,
                      this.props.intl.formatMessage({
                        id: "validate > password must be at least 6 characters",
                      })
                    ),
                })}
                onSubmit={(values) => {
                  this.props.onFormSubmit(values);
                }}
              >
                {(formProps) => {
                  const errors = formProps.errors;
                  const touched = formProps.touched;
                  const values = formProps.values;
                  return (
                    <Form onSubmit={formProps.handleSubmit} className={bem.b()}>
                      <div className={bem.e("content-form")}>
                        <Row>
                          {(authIsBroadcastingGroupAdmin ||
                            get(auth, "user.is_god_admin")) &&
                          !isMultiple ? (
                            <Col xs={12}>
                              <FormGroup>
                                <RadioButtons
                                  label={
                                    <FormattedMessage id="user > admin type" />
                                  }
                                  onSelected={(selected) => {
                                    formProps.setFieldValue(
                                      "admin_type",
                                      selected
                                    );

                                    // switch (selected) {
                                    //   case "broadcasting_group_admin":
                                    //     formProps.setFieldValue("stations", []);
                                    //     break;
                                    //   case "market_admin":
                                    //     break;
                                    //   case "god_admin":
                                    //     formProps.setFieldValue("stations", []);
                                    //     break;
                                    //   case "not_admin":
                                    //     formProps.setFieldValue("stations", []);
                                    //     break;
                                    //   default:
                                    //     break;
                                    // }
                                    // if (
                                    //   selected !== "broadcasting_group_admin"
                                    // ) {
                                    //   formProps.setFieldValue(
                                    //     "broadcasting_group_admin",
                                    //     null
                                    //   );
                                    // }
                                    // formProps.setFieldValue(
                                    //   "is_god_admin",
                                    //   selected === "god_admin"
                                    // );
                                    // formProps.setFieldValue(
                                    //   "is_market_admin",
                                    //   selected === "market_admin"
                                    // );
                                    // formProps.setFieldValue(
                                    //   "is_broadcasting_group_admin",
                                    //   selected === "broadcasting_group_admin"
                                    // );
                                  }}
                                  selectedValue={values.admin_type}
                                  buttons={[
                                    {
                                      label: (
                                        <FormattedMessage id="user > not admin" />
                                      ),
                                      value: "market_admin",
                                    },
                                    // {
                                    //   label: (
                                    //     <FormattedMessage id="user > market admin" />
                                    //   ),
                                    //   value: "market_admin"
                                    // },
                                    {
                                      label: (
                                        <FormattedMessage id="user > broadcasting group admin" />
                                      ),
                                      value: "broadcasting_group_admin",
                                    },
                                    // {
                                    //   label: (
                                    //     <FormattedMessage id="user > got admin" />
                                    //   ),
                                    //   value: "god_admin"
                                    // }
                                  ]}
                                />
                              </FormGroup>
                            </Col>
                          ) : null}
                          {/* {get(values, "admin_type") !==
                            "broadcasting_group_admin" &&
                          get(values, "admin_type") !== "not_admin" &&
                          get(values, "admin_type") !== "god_admin" ? (
                            <Col xs={12}>
                              <FormGroup>
                                <SelectEffective
                                  leftTitle={this.props.intl.formatMessage({
                                    id: "user > select stations",
                                  })}
                                  rightTitle={this.props.intl.formatMessage({
                                    id: "user > selected stations",
                                  })}
                                  name="stations"
                                  items={stations}
                                  selected={values.stations}
                                  disabled={
                                    get(values, "admin_type") ===
                                      "broadcasting_group_admin" ||
                                    get(values, "admin_type") === "god_admin" ||
                                    get(values, "admin_type") === "not_admin"
                                  }
                                  onSelectItem={(item) => {
                                    if (
                                      get(values, "admin_type") ===
                                        "broadcasting_group_admin" ||
                                      get(values, "admin_type") ===
                                        "god_admin" ||
                                      get(values, "admin_type") === "not_admin"
                                    )
                                      return false;
                                    const {
                                      stations,
                                      setStations,
                                    } = this.props;
                                    const newStations = filter(
                                      stations,
                                      (station) => station.id !== item.id
                                    );
                                    setStations(newStations);
                                    formProps.setFieldValue("stations", [
                                      ...values.stations,
                                      item,
                                    ]);
                                  }}
                                  onRemoveItem={(item) => {
                                    if (
                                      get(values, "admin_type") ===
                                        "broadcasting_group_admin" ||
                                      get(values, "admin_type") ===
                                        "god_admin" ||
                                      get(values, "admin_type") === "not_admin"
                                    )
                                      return false;
                                    const {
                                      stations,
                                      setStations,
                                    } = this.props;
                                    const newSelectedStations = filter(
                                      values.stations,
                                      (station) => station.id !== item.id
                                    );
                                    setStations([...stations, item]);
                                    formProps.setFieldValue(
                                      "stations",
                                      newSelectedStations
                                    );
                                  }}
                                />
                                <div className="text-danger">
                                  {formProps.touched.stations &&
                                    formProps.errors.stations}
                                </div>
                              </FormGroup>
                            </Col>
                          ) : null} */}

                          {/* {(authIsBroadcastingGroupAdmin ||
                            get(auth, "user.is_god_admin")) &&
                          !isMultiple ? (
                            <Col xs={12}>
                              {get(values, "admin_type") ===
                                "broadcasting_group_admin" &&
                              !isLoggedAsSpecific ? (
                                <FormGroup>
                                  <Dropdown
                                    label={
                                      <FormattedMessage id="user > broadcasting group admin" />
                                    }
                                    placeholder={this.props.intl.formatMessage({
                                      id: "user > broadcasting group admin",
                                    })}
                                    name="broadcasting_group_admin"
                                    error={
                                      formProps.touched
                                        .broadcasting_group_admin &&
                                      formProps.errors.broadcasting_group_admin
                                    }
                                    onChange={(selectedOption) => {
                                      formProps.setFieldValue(
                                        "broadcasting_group_admin",
                                        selectedOption
                                      );
                                    }}
                                    value={values.broadcasting_group_admin}
                                    options={serverList}
                                  />
                                </FormGroup>
                              ) : null}
                            </Col>
                          ) : null} */}
                          <Col xs={5}>
                            <FormGroup>
                              <TextInput
                                label={
                                  <FormattedMessage id="user > re-enter own password" />
                                }
                                name="password"
                                type="password"
                                value={values.password}
                                error={touched.password && errors.password}
                                onChange={formProps.handleChange}
                                onBlur={formProps.handleBlur}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      <div className={bem.e("form-buttons")}>
                        <Row>
                          <Col xs={12}>
                            <Button
                              color="blue"
                              type="submit"
                              className="btn btn-radius"
                              disabled={isLoading}
                            >
                              <FormattedMessage
                                id={`user > button ${
                                  !isLoading ? "submit" : "submitting"
                                }`}
                              />
                            </Button>
                            {this.props.backButton}
                          </Col>
                        </Row>
                      </div>
                      <Spinner isLoading={isLoading} />
                    </Form>
                  );
                }}
              </Formik>
            );
          } else {
            return <Redirect to={URL.DASHBOARD()} />;
          }
        }}
      </PrivilegedComponent>
    );
  }
  renderView() {
    // const { auth, user, isMultiple, servers, stations } = this.props;
    const { user } = this.props;

    // const isLoggedAsSpecific =
    //   window.location.href.indexOf("admin.ps.com") === -1;
    const userDetails = get(user, "data", {});
    // const selectedServer = find(
    //   servers,
    //   (server) => userDetails.broadcasting_group_admin === server.id
    // );
    // const authIsBroadcastingGroupAdmin = get(
    //   auth,
    //   "user.broadcasting_group_admin",
    //   false
    // );
    // const selectedStations = map(
    //   get(userDetails, "admin_stations"),
    //   (item) => ({
    //     id: item.user_station_id,
    //     label: item.name,
    //   })
    // );
    // let adminType;
    // if (userDetails.is_god_admin) {
    //   adminType = "god_admin";
    // } else if (userDetails.broadcasting_group_admin) {
    //   adminType = "broadcasting_group_admin";
    // } else if (
    //   size(userDetails.admin_stations) > 0 &&
    //   !userDetails.broadcasting_group_admin
    // ) {
    //   adminType = "market_admin";
    // } else {
    //   adminType = "not_admin";
    // }
    return (
      <div className={bem.b()}>
        <div className={bem.e("view-wrapper")}>
          <div className={bem.e("content-form")}>
            <Row>
              <Col xs={12} className={bem.e("col-reset")}>
                <FormGroup className={bem.e("form-group-view")}>
                  <RadioButtons
                    label={<FormattedMessage id="user > admin type" />}
                    onSelected={() => {}}
                    selectedValue={get(userDetails, "admin_type", "")}
                    buttons={[
                      {
                        label: <FormattedMessage id="user > not admin" />,
                        value: "market_admin",
                      },
                      {
                        label: (
                          <FormattedMessage id="user > broadcasting group admin" />
                        ),
                        value: "broadcasting_group_admin",
                      },
                    ]}
                  />
                </FormGroup>
              </Col>
              {/* {adminType !== "broadcasting_group_admin" &&
              adminType !== "not_admin" &&
              adminType !== "god_admin" ? (
                <Col xs={12} className={bem.e("col-reset")}>
                  <FormGroup className={bem.e("form-group-view")}>
                    <SelectEffective
                      leftTitle={this.props.intl.formatMessage({
                        id: "user > select stations",
                      })}
                      rightTitle={this.props.intl.formatMessage({
                        id: "user > selected stations",
                      })}
                      name="stations"
                      items={stations}
                      selected={selectedStations}
                      disabled={
                        adminType === "broadcasting_group_admin" ||
                        adminType === "god_admin" ||
                        adminType === "not_admin"
                      }
                      onSelectItem={(item) => {}}
                      onRemoveItem={(item) => {}}
                    />
                  </FormGroup>
                </Col>
              ) : null} */}
              {/* {(authIsBroadcastingGroupAdmin ||
                get(auth, "user.is_god_admin")) &&
              !isMultiple &&
              adminType === "broadcasting_group_admin" &&
              !isLoggedAsSpecific ? (
                <Col xs={12} className={bem.e("col-reset")}>
                  <FormGroup className={bem.e("form-group-view")}>
                    <Label className={bem.e("label")}>
                      <FormattedMessage id="user > broadcasting group admin" />
                    </Label>
                    <div className={bem.e("field-view-value")}>
                      {selectedServer ? (
                        selectedServer.name
                      ) : (
                        <FormattedMessage id="user > none" />
                      )}
                    </div>
                  </FormGroup>
                </Col>
              ) : null} */}
            </Row>
          </div>
          <div className={bem.e("form-buttons")}>
            <Row>
              <Col xs={12}>
                <Button
                  color="blue"
                  onClick={() => this.props.setIsView(false)}
                  className="btn btn-radius"
                >
                  <FormattedMessage id={`user > button edit`} />
                </Button>
                {this.props.backButton}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
  render() {
    const { isView } = this.props;
    if (isView) return this.renderView();
    return this.renderForm();
  }
}

export default AdminRights;
