import React from "react";
import bn from "utils/bemnames";
import { Row, Col, Label } from "reactstrap";
import { EditLargeIcon, ApproveIcon } from "components/CustomIcons";
import URL from "utils/urls";
import history from "components/History";
import { FormattedMessage } from "react-intl";
import { PRIVILEGES } from "utils/constants";
import PrivilegedComponent from "components/PrivilegedComponent";
import { PhoneNumberFormat } from "utils/config";
import NumberFormat from "react-number-format";
import moment from "moment";
import { capitalize, get } from "lodash";
import classNames from "classnames";
const bem = bn.create("winner-details");

export default function ParticipantView(props) {
  const { winner, isShowEditIcon, dateFormatByServer } = props;
  const onEdit = () => {
    const params = {
      winner_id: winner.id,
      action: "edit",
    };
    history.push(URL.WINNER(params));
  };
  let textGender = winner.gender ? [capitalize(winner.gender)] : ["Female"];
  if (winner.date_of_birth) {
    const years =
      new Date().getFullYear() -
      parseInt(moment(winner.date_of_birth).format("Y"));
    textGender = [...textGender, years];
  }
  let addressText = [];
  if (winner.home_address_1) {
    addressText = [winner.home_address_1];
    if (winner.home_address_2) {
      addressText = [...addressText, winner.home_address_2];
    }
    addressText = [
      ...addressText,
      winner.home_city,
      `${winner.home_state_province} ${winner.home_zip_postal_code}`,
    ];
  }
  return (
    <div className={bem.e("participant-view")}>
      <div className={bem.e("participant-head")}>
        <span
          className={bem.e("participant-name")}
        >{`${winner.first_name} ${winner.last_name}`}</span>
        {textGender.length > 0 && (
          <span className={bem.e("participant-sex")}>
            ({textGender.join(", ")})
          </span>
        )}
        {isShowEditIcon && (
          <PrivilegedComponent requires={[PRIVILEGES.EDIT_WINNER]}>
            <span className={bem.e("participant-edit")} onClick={onEdit}>
              <EditLargeIcon />
            </span>
          </PrivilegedComponent>
        )}
      </div>
      <Row>
        <Col xs={6}>
          <div className={bem.e("participant-info")}>
            <p className={bem.e("info-item")}>
              <Label>
                <FormattedMessage id="winners > address" />:
              </Label>
              <span className={bem.e("info-item-value")}>
                {addressText.length > 0 ? (
                  addressText.join(", ")
                ) : (
                  <FormattedMessage id="winners > none" />
                )}
              </span>
            </p>
            <p className={bem.e("info-item")}>
              <Label>
                <FormattedMessage id="winners > phone" />:
              </Label>
              <span className={bem.e("info-item-value")}>
                {winner.phone_number ? (
                  <NumberFormat
                    value={winner.phone_number}
                    displayType={"text"}
                    format={PhoneNumberFormat}
                  />
                ) : (
                  <FormattedMessage id="winners > none" />
                )}
              </span>
            </p>
            <p className={bem.e("info-item")}>
              <Label>
                <FormattedMessage id="winners > email" />:
              </Label>
              <span className={bem.e("info-item-value")}>
                {winner.email_address ? (
                  winner.email_address
                ) : (
                  <FormattedMessage id="winners > none" />
                )}
              </span>
            </p>
            <p className={bem.e("info-item")}>
              <Label>
                <FormattedMessage id="winners > dob" />:
              </Label>
              <span className={bem.e("info-item-value")}>
                {winner.date_of_birth ? (
                  moment(winner.date_of_birth).format(dateFormatByServer)
                ) : (
                  <FormattedMessage id="winners > none" />
                )}
              </span>
            </p>
            <p className={bem.e("info-item")}>
              <Label>
                <FormattedMessage id="winners > ss" />:
              </Label>
              <span className={bem.e("info-item-value")}>
                {winner.social_security ? (
                  `***-**-${winner.social_security.substring(5, 9)}`
                ) : (
                  <FormattedMessage id="winners > none" />
                )}
              </span>
            </p>
          </div>
        </Col>
        <Col xs={6}>
          <div className={bem.e("participant-right-info")}>
            <div
              className={classNames({
                [bem.e("participant-right-info-contact")]: get(
                  winner,
                  "first_contact",
                  ""
                ),
              })}
            >
              {get(winner, "first_contact", "") ? (
                <div className={bem.e("participant-right-item")}>
                  <span className={bem.e("winnings-label")}>
                    <FormattedMessage id="winners > first contact" />:
                  </span>
                  <span className={bem.e("winnings-value")}>
                    {moment(winner.first_contact).format(dateFormatByServer)}
                  </span>
                </div>
              ) : null}
              {get(winner, "last_contact", "") ? (
                <div className={bem.e("participant-right-item")}>
                  <span className={bem.e("winnings-label")}>
                    <FormattedMessage id="winners > last contact" />:
                  </span>
                  <span className={bem.e("winnings-value")}>
                    {moment(winner.last_contact).format(dateFormatByServer)}
                  </span>
                </div>
              ) : null}

              <div className={bem.e("total-winnings")}>
                <span className={bem.e("winnings-label")}>
                  <FormattedMessage id="winners > YTD Winnings" />:
                </span>
                <span className={bem.e("winnings-total")}>
                  ${get(winner, "ytd", 0)}
                </span>
              </div>
              <div className={bem.e("participant-approval")}>
                <ApproveIcon />
                <span>W-9 form</span>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
ParticipantView.defaultProps = {
  isShowEditIcon: true,
};
