export const privileges_meta = {
  privileges_groups: {
    Admin: {
      sub_groups: [
        {
          name: "Users",
          select_all: true,
        },
        {
          name: "Teams",
          select_all: true,
        },
        {
          name: "Role Manager",
          select_all: true,
        },
        /*{
          name: "Workflows",
        },*/
        {
          name: "General",
          select_all: false,
        },
      ],
      description: "Administrative privileges",
    },
    Shared: {
      sub_groups: [
        {
          name: "Order Entry",
          select_all: false,
        },
        {
          name: "File Upload",
          select_all: true,
        },
        {
          name: "Client Management",
          select_all: false,
        },
      ],
    },
    Production: {
      sub_groups: [
        {
          name: "Production Order Entry",
          select_all: true,
        },
        {
          name: "Production",
          select_all: true,
        },
        {
          name: "Approvals",
          select_all: true,
        },
      ],
      description:
        "Privileges related to the various steps in a production order",
    },
    Digital: {
      sub_groups: [
        {
          name: "Digital Order Entry",
          select_all: true,
        },
        {
          name: "Digital Production",
          select_all: true,
        },
        // add this for break column
        // {
        // 	name: "",
        // },
        {
          name: "Digital Approvals",
          select_all: true,
        },
        {
          name: "Digital Channels",
        },
        {
          name: "Digital Traffic",
        },
      ],
      description:
        "Privileges addressing the digital order flow and channels management",
    },
    "Jock Console": {
      sub_groups: [
        {
          name: "Jock Console",
        },
      ],
      description: "Privileges related to the various steps in a jock console",
    },
    Promotions: {
      sub_groups: [
        {
          select_all: true,
          name: "Programming Elements",
        },
        {
          select_all: true,
          name: "Contests",
        },
        {
          select_all: true,
          name: "Winners List",
        },
        {
          select_all: true,
          name: "Prize Closet",
        },
        {
          select_all: true,
          name: "Clocks",
        },
        {
          select_all: true,
          name: "System Forms",
        },
        {
          select_all: true,
          name: "Station Settings Forms",
        },
      ],
      description:
        "Privileges related to the various steps in a promotion order",
    },
  },
  privileges: {
    // Users
    add_users: {
      display_name: "Add users",
      description: "",
      groups: [{ group: "Admin", sub_group: "Users" }],
    },
    edit_users: {
      display_name: "Edit users",
      description: "",
      groups: [{ group: "Admin", sub_group: "Users" }],
    },
    view_users: {
      display_name: "View users",
      description: "",
      groups: [{ group: "Admin", sub_group: "Users" }],
    },
    delete_users: {
      display_name: "Delete users",
      description: "",
      groups: [{ group: "Admin", sub_group: "Users" }],
    },
    user_scheduling: {
      display_name: "User Scheduling",
      description: "",
      groups: [{ group: "Admin", sub_group: "Users" }],
    },
    // Teams
    create_team: {
      display_name: "Create a team",
      description: "",
      groups: [{ group: "Admin", sub_group: "Teams" }],
    },
    add_user_to_team: {
      display_name: "Add user to team",
      description: "",
      groups: [{ group: "Admin", sub_group: "Teams" }],
    },
    view_user_on_team: {
      display_name: "View uses on team",
      description: "",
      groups: [{ group: "Admin", sub_group: "Teams" }],
    },
    remove_user_from_team: {
      display_name: "Remove user from team",
      description: "",
      groups: [{ group: "Admin", sub_group: "Teams" }],
    },

    // Role Manager
    add_privileges: {
      display_name: "Add Privileges",
      description: "",
      groups: [{ group: "Admin", sub_group: "Role Manager" }],
    },
    delete_privileges: {
      display_name: "Remove Privileges",
      description: "",
      groups: [{ group: "Admin", sub_group: "Role Manager" }],
    },
    view_privileges: {
      display_name: "View Privileges",
      description: "",
      groups: [{ group: "Admin", sub_group: "Role Manager" }],
    },
    // Workflows
    /*edit_workflows: {
      display_name: "Configure Workflows",
      description: "",
      groups: [{ group: "Admin", sub_group: "Workflows" }],
    },
    publish_workflows: {
      display_name: "Publish/Unpublish Workflows",
      description: "",
      groups: [{ group: "Admin", sub_group: "Workflows" }],
    },*/
    //General
    edit_stations: {
      display_name: "Edit Stations Details",
      description: "",
      groups: [{ group: "Admin", sub_group: "General" }],
    },
    // File Upload
    upload_file: {
      display_name: "Upload File",
      description: "",
      groups: [
        {
          group: "Shared",
          sub_group: "File Upload",
        },
      ],
    },
    delete_file: {
      display_name: "Delete File",
      description: "",
      groups: [
        {
          group: "Shared",
          sub_group: "File Upload",
        },
      ],
    },
    // Client Management
    add_client: {
      display_name: "Add Client",
      description: "",
      groups: [
        {
          group: "Shared",
          sub_group: "Client Management",
        },
      ],
    },
    edit_client: {
      display_name: "Edit Client",
      description: "",
      groups: [
        {
          group: "Shared",
          sub_group: "Client Management",
        },
      ],
    },
    delete_client: {
      display_name: "Delete Client",
      description: "",
      groups: [
        {
          group: "Shared",
          sub_group: "Client Management",
        },
      ],
    },
    re_assign_client: {
      display_name: "Re-assign Client",
      description: "",
      groups: [
        {
          group: "Shared",
          sub_group: "Client Management",
        },
      ],
    },
    // Production Order Entry
    draft_production: {
      display_name: "Save Draft Order",
      description: "",
      groups: [
        {
          group: "Production",
          sub_group: "Production Order Entry",
        },
      ],
    },
    add_production: {
      display_name: "Submit order",
      description: "",
      groups: [
        {
          group: "Production",
          sub_group: "Production Order Entry",
        },
      ],
    },
    // edit_process: {
    //   display_name: "Edit Process",
    //   description: "",
    //   groups: [
    //     {
    //       group: "Shared",
    //       sub_group: "Order Entry",
    //     },
    //   ],
    // },
    // view_process: {
    //   display_name: "View Process",
    //   description: "",
    //   groups: [
    //     {
    //       group: "Shared",
    //       sub_group: "Order Entry",
    //     },
    //   ],
    // },
    // delete_process: {
    //   display_name: "Delete Process",
    //   description: "",
    //   groups: [
    //     {
    //       group: "Shared",
    //       sub_group: "Order Entry",
    //     },
    //   ],
    // },
    edit_production: {
      display_name: "Edit order",
      description: "",
      groups: [
        {
          group: "Production",
          sub_group: "Production Order Entry",
        },
      ],
    },
    view_production: {
      display_name: "View order",
      description: "",
      groups: [
        {
          group: "Production",
          sub_group: "Production Order Entry",
        },
      ],
    },
    // Production
    assign_production_team: {
      display_name: "Assign Team",
      description: "",
      groups: [{ group: "Production", sub_group: "Production" }],
    },
    draft_production_script: {
      display_name: "Save Draft Script",
      description: "",
      groups: [{ group: "Production", sub_group: "Production" }],
    },
    submit_production_script: {
      display_name: "Submit Script",
      description: "",
      groups: [{ group: "Production", sub_group: "Production" }],
    },
    edit_production_script: {
      display_name: "Edit Script",
      description: "",
      groups: [{ group: "Production", sub_group: "Production" }],
    },
    edit_vo: {
      display_name: "Edit VO",
      description: "",
      groups: [{ group: "Production", sub_group: "Production" }],
    },
    // add_production: {
    // 	display_name: "Add order",
    // 	description: "",
    // 	groups: [{ group: "Production", sub_group: "Production Order Entry" }]
    // },
    /*add_production: {
			display_name: "Submit order",
			description: "",
			groups: [
				{
					group: "Production",
					sub_group: "Production Order Entry",
				},
			],
		},*/
    submit_production_recording: {
      display_name: "Upload Recording",
      description: "",
      groups: [{ group: "Production", sub_group: "Production" }],
    },
    delete_production_recording: {
      display_name: "Delete Recording",
      description: "",
      groups: [{ group: "Production", sub_group: "Production" }],
    },
    perform_production_dubbing: {
      display_name: "Perform Dubbing",
      description: "",
      groups: [{ group: "Production", sub_group: "Production" }],
    },
    // assign_production_team: {
    // 	display_name: "Assign Team",
    // 	description: "",
    // 	groups: [{ group: "Production", sub_group: "Production" }]
    // },
    perform_production_continuity: {
      display_name: "Perform Continuity",
      description: "",
      groups: [{ group: "Production", sub_group: "Production" }],
    },
    produce_production: {
      display_name: "Produce Order",
      description: "",
      groups: [{ group: "Production", sub_group: "Production" }],
    },
    // submit_production_script: {
    // 	display_name: "Submit Script",
    // 	description: "",
    // 	groups: [{ group: "Production", sub_group: "Production" }]
    // },
    // edit_production_script: {
    // 	display_name: "Edit Script",
    // 	description: "",
    // 	groups: [{ group: "Production", sub_group: "Production" }],
    // },
    // edit_vo: {
    // 	display_name: "Edit VO",
    // 	description: "",
    // 	groups: [{ group: "Production", sub_group: "Production" }]
    // },
    view_all_production_orders: {
      display_name: "View All Production Orders",
      groups: [{ group: "Production", sub_group: "Production" }],
    },
    edit_all_production_orders: {
      display_name: "Edit All Production Orders",
      groups: [{ group: "Production", sub_group: "Production" }],
    },
    edit_production_settings: {
      display_name: "Edit Production Settings",
      groups: [{ group: "Production", sub_group: "Production" }],
    },
    send_back_orders: {
      display_name: "Send Back Orders",
      description: "",
      groups: [{ group: "Production", sub_group: "Production" }],
    },
    view_expiring_spots: {
      display_name: "View Expiring Spots",
      description: "",
      groups: [{ group: "Production", sub_group: "Production" }],
    },
    // Approvals
    approve_production_script: {
      display_name: "Approve Script",
      description: "",
      groups: [{ group: "Production", sub_group: "Approvals" }],
    },
    approve_production_recording: {
      display_name: "Approve Recording",
      description: "",
      groups: [{ group: "Production", sub_group: "Approvals" }],
    },
    archive_production_order: {
      display_name: "Archive Production Order",
      groups: [{ group: "Production", sub_group: "Production" }],
    },
    /*submit_production_air_check: {
      display_name: "Submit Air Check",
      description: "",
      groups: [{ group: "Production", sub_group: "Approvals" }],
    },*/
    /*approve_production_order: {
      display_name: "Approve Order",
      description: "",
      groups: [{ group: "Production", sub_group: "Approvals" }],
    },*/

    // add_promotion: {
    //   display_name: "Add Order",
    //   description: "",
    //   groups: [{group: "Promotions", sub_group: "Production Order Entry"}]
    // },
    // add_resources: {
    //   display_name: "Add Resources",
    //   description: "",
    //   groups: [{group: "Promotions", sub_group: "Resources"}]
    // },
    // edit_resources: {
    //   display_name: "Edit Resources",
    //   description: "",
    //   groups: [{group: "Promotions", sub_group: "Resources"}]
    // },
    // view_resources: {
    //   display_name: "View Resources",
    //   description: "",
    //   groups: [{group: "Promotions", sub_group: "Resources"}]
    // },
    // delete_resources: {
    //   display_name: "Delete Resources",
    //   description: "",
    //   groups: [{group: "Promotions", sub_group: "Resources"}]
    // },
    // Digital Order Entry
    draft_digital: {
      display_name: "Save Draft Order",
      description: "",
      groups: [
        {
          group: "Digital",
          sub_group: "Digital Order Entry",
        },
      ],
    },
    add_digital: {
      display_name: "Submit order",
      description: "",
      groups: [
        {
          group: "Digital",
          sub_group: "Digital Order Entry",
        },
      ],
    },
    edit_digital: {
      display_name: "Edit order",
      description: "",
      groups: [
        {
          group: "Digital",
          sub_group: "Digital Order Entry",
        },
      ],
    },
    view_digital: {
      display_name: "View order",
      description: "",
      groups: [
        {
          group: "Digital",
          sub_group: "Digital Order Entry",
        },
      ],
    },
    view_all_digital_orders: {
      display_name: "View All Digital Orders",
      groups: [{ group: "Digital", sub_group: "Digital Production" }],
    },
    edit_all_digital_orders: {
      display_name: "Edit All Digital Orders",
      groups: [{ group: "Digital", sub_group: "Digital Production" }],
    },
    delete_digital: {
      display_name: "Delete order",
      description: "",
      groups: [
        {
          group: "Digital",
          sub_group: "Digital Order Entry",
        },
      ],
    },
    // Digital Channels
    edit_channels: {
      display_name: "Configure Channels",
      description: "",
      groups: [
        {
          group: "Digital",
          sub_group: "Digital Channels",
        },
      ],
    },
    publish_channels: {
      display_name: "Publish/Unpublish Channels",
      description: "",
      groups: [
        {
          group: "Digital",
          sub_group: "Digital Channels",
        },
      ],
    },

    // Digital Production
    add_design: {
      display_name: "Submit Design",
      description: "",
      groups: [
        {
          group: "Digital",
          sub_group: "Digital Production",
        },
      ],
    },
    delete_design: {
      display_name: "Delete Design",
      description: "",
      groups: [
        {
          group: "Digital",
          sub_group: "Digital Production",
        },
      ],
    },
    // Digital Approvals
    approve_order: {
      display_name: "Approve Order",
      description: "",
      groups: [
        {
          group: "Digital",
          sub_group: "Digital Approvals",
        },
      ],
    },
    approve_design: {
      display_name: "Approve Design",
      description: "",
      groups: [
        {
          group: "Digital",
          sub_group: "Digital Approvals",
        },
      ],
    },
    // Digital Traffic
    perform_digital_traffic: {
      display_name: "Perform Digital Traffic",
      description: "",
      groups: [
        {
          group: "Digital",
          sub_group: "Digital Traffic",
        },
      ],
    },
    // Jock Console Privileges
    view_tasks: {
      display_name: "View Tasks",
      description: "",
      groups: [
        {
          group: "Jock Console",
          sub_group: "Jock Console",
        },
      ],
    },
    perform_tasks: {
      display_name: "Perform Tasks",
      description: "",
      groups: [
        {
          group: "Jock Console",
          sub_group: "Jock Console",
        },
      ],
    },
    configure_console: {
      display_name: "Configure Console",
      description: "",
      groups: [
        {
          group: "Jock Console",
          sub_group: "Jock Console",
        },
      ],
    },
    re_assign_shift: {
      display_name: "Reassign Shift",
      description: "",
      groups: [
        {
          group: "Jock Console",
          sub_group: "Jock Console",
        },
      ],
    },
    // Promotions privileges
    // Programming Elements
    programming_element_save_draft: {
      display_name: "Save Draft",
      description: "",
      groups: [
        {
          group: "Promotions",
          sub_group: "Programming Elements",
        },
      ],
    },
    programming_element_save: {
      display_name: "Save",
      description: "",
      groups: [
        {
          group: "Promotions",
          sub_group: "Programming Elements",
        },
      ],
    },
    programming_element_publish: {
      display_name: "Publish",
      description: "",
      groups: [
        {
          group: "Promotions",
          sub_group: "Programming Elements",
        },
      ],
    },
    programming_element_edit: {
      display_name: "Edit",
      description: "",
      groups: [
        {
          group: "Promotions",
          sub_group: "Programming Elements",
        },
      ],
    },
    programming_element_view: {
      display_name: "View",
      description: "",
      groups: [
        {
          group: "Promotions",
          sub_group: "Programming Elements",
        },
      ],
    },
    programming_element_delete: {
      display_name: "Delete",
      description: "",
      groups: [
        {
          group: "Promotions",
          sub_group: "Programming Elements",
        },
      ],
    },
    // Contests
    contest_save_draft: {
      display_name: "Save Draft",
      description: "",
      groups: [
        {
          group: "Promotions",
          sub_group: "Contests",
        },
      ],
    },
    contest_save: {
      display_name: "Save",
      description: "",
      groups: [
        {
          group: "Promotions",
          sub_group: "Contests",
        },
      ],
    },
    contest_publish: {
      display_name: "Publish",
      description: "",
      groups: [
        {
          group: "Promotions",
          sub_group: "Contests",
        },
      ],
    },
    contest_edit: {
      display_name: "Edit",
      description: "",
      groups: [
        {
          group: "Promotions",
          sub_group: "Contests",
        },
      ],
    },
    contest_view: {
      display_name: "View",
      description: "",
      groups: [
        {
          group: "Promotions",
          sub_group: "Contests",
        },
      ],
    },
    contest_delete: {
      display_name: "Delete",
      description: "",
      groups: [
        {
          group: "Promotions",
          sub_group: "Contests",
        },
      ],
    },
    // Prize Closet
    prize_closet_save: {
      display_name: "Save",
      description: "",
      groups: [
        {
          group: "Promotions",
          sub_group: "Prize Closet",
        },
      ],
    },
    prize_closet_edit: {
      display_name: "Edit",
      description: "",
      groups: [
        {
          group: "Promotions",
          sub_group: "Prize Closet",
        },
      ],
    },
    prize_closet_view: {
      display_name: "View",
      description: "",
      groups: [
        {
          group: "Promotions",
          sub_group: "Prize Closet",
        },
      ],
    },
    prize_closet_delete: {
      display_name: "Delete",
      description: "",
      groups: [
        {
          group: "Promotions",
          sub_group: "Prize Closet",
        },
      ],
    },
    // Clocks
    edit_daypart_clock: {
      display_name: "Edit Daypart Clock",
      description: "",
      groups: [
        {
          group: "Promotions",
          sub_group: "Clocks",
        },
      ],
    },
    create_show_clock: {
      display_name: "Create Show Clock",
      description: "",
      groups: [
        {
          group: "Promotions",
          sub_group: "Clocks",
        },
      ],
    },
    edit_show_clock: {
      display_name: "Edit Show Clock",
      description: "",
      groups: [
        {
          group: "Promotions",
          sub_group: "Clocks",
        },
      ],
    },
    view_show_clock: {
      display_name: "View Show Clock",
      description: "",
      groups: [
        {
          group: "Promotions",
          sub_group: "Clocks",
        },
      ],
    },
    delete_show_clock: {
      display_name: "Delete Show Clock",
      description: "",
      groups: [
        {
          group: "Promotions",
          sub_group: "Clocks",
        },
      ],
    },
    create_programming_clock: {
      display_name: "Create Programming Clock",
      description: "",
      groups: [
        {
          group: "Promotions",
          sub_group: "Clocks",
        },
      ],
    },
    edit_programming_clock: {
      display_name: "Edit Programming Clock",
      description: "",
      groups: [
        {
          group: "Promotions",
          sub_group: "Clocks",
        },
      ],
    },
    view_programming_clock: {
      display_name: "View Programming Clock",
      description: "",
      groups: [
        {
          group: "Promotions",
          sub_group: "Clocks",
        },
      ],
    },
    delete_programming_clock: {
      display_name: "Delete Programming Clock",
      description: "",
      groups: [
        {
          group: "Promotions",
          sub_group: "Clocks",
        },
      ],
    },
    // Winners list
    view_winners_list: {
      display_name: "View winners list",
      description: "",
      groups: [
        {
          group: "Promotions",
          sub_group: "Winners List",
        },
      ],
    },
    edit_winner: {
      display_name: "Edit winner",
      description: "",
      groups: [
        {
          group: "Promotions",
          sub_group: "Winners List",
        },
      ],
    },
    fulfill_winner_prizes: {
      display_name: "Fulfill Winner Prizes",
      description: "",
      groups: [
        {
          group: "Promotions",
          sub_group: "Winners List",
        },
      ],
    },
    view_winner: {
      display_name: "View winner",
      description: "",
      groups: [
        {
          group: "Promotions",
          sub_group: "Winners List",
        },
      ],
    },
    verify_winner_address: {
      display_name: "Verify winner address",
      description: "",
      groups: [
        {
          group: "Promotions",
          sub_group: "Winners List",
        },
      ],
    },
    view_upcoming_tasks: {
      display_name: "View upcoming tasks",
      description: "",
      groups: [
        {
          group: "Shared",
          sub_group: "Order Entry",
        },
      ],
    },
    // System forms
    view_forms: {
      display_name: "View Forms",
      description: "",
      groups: [{ group: "Promotions", sub_group: "System Forms" }],
    },
    edit_forms: {
      display_name: "Edit Forms",
      description: "",
      groups: [{ group: "Promotions", sub_group: "System Forms" }],
    },
    view_station_settings_forms: {
      display_name: "View Forms",
      description: "",
      groups: [{ group: "Promotions", sub_group: "Station Settings Forms" }],
    },
    edit_station_settings_forms: {
      display_name: "Edit Forms",
      description: "",
      groups: [{ group: "Promotions", sub_group: "Station Settings Forms" }],
    },
  },
  privileges_by_role: {
    // PRODUCTION ROLES
    "Market Admin": {
      Users: {
        is_can_select_all: true,
        disabled: [],
      },
      Teams: {
        is_can_select_all: true,
        disabled: [],
      },
      "Role Manager": {
        is_can_select_all: true,
        disabled: [],
      },
      /*Workflows: {
        is_can_select_all: false,
        disabled: [],
      },*/
      General: {
        is_can_select_all: false,
        disabled: ["edit_stations"],
      },
      "Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "File Upload": {
        is_can_select_all: true,
        disabled: [],
      },
      "Client Management": {
        is_can_select_all: false,
        disabled: [],
      },
      "Production Order Entry": {
        is_can_select_all: true,
        disabled: [],
      },
      Production: {
        is_can_select_all: true,
        disabled: ["edit_production_settings"],
      },
      Approvals: {
        is_can_select_all: true,
        disabled: [],
      },
      "Digital Order Entry": {
        is_can_select_all: true,
        disabled: [],
      },
      "Digital Production": {
        is_can_select_all: true,
        disabled: [],
      },
      "Digital Approvals": {
        is_can_select_all: true,
        disabled: [],
      },
      "Digital Channels": {
        is_can_select_all: true,
        disabled: [],
      },
      "Digital Traffic": {
        is_can_select_all: true,
        disabled: [],
      },
      "Jock Console": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Programming Elements": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Contests: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Winners List": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Prize Closet": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Clocks: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "System Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Station Settings Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
    },
    "Account Executive": {
      Users: {
        is_can_select_all: false,
        disabled: [
          // USERS
          "add_users",
          "edit_users",
          "delete_users",
        ],
      },
      Teams: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Role Manager": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      /*Workflows: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },*/
      General: {
        is_can_select_all: false,
        disabled: ["edit_stations"],
      },
      "Order Entry": {
        is_can_select_all: false,
        disabled: ["view_expiring_spots"],
      },
      "File Upload": {
        is_can_select_all: true,
        disabled: [],
      },
      "Client Management": {
        is_can_select_all: false,
        disabled: ["re_assign_client"],
      },
      "Production Order Entry": {
        is_can_select_all: true,
        disabled: [],
      },
      Production: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: ["edit_production_settings"],
      },
      Approvals: {
        is_can_select_all: true,
        disabled: [
          // Approvals
          "approve_production_order",
        ],
      },
      "Digital Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Production": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Approvals": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Channels": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Traffic": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Jock Console": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Programming Elements": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Contests: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Winners List": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Prize Closet": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Clocks: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "System Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Station Settings Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
    },
    "Sales Assistant": {
      Users: {
        is_can_select_all: false,
        disabled: [
          // USERS
          "add_users",
          "edit_users",
          "delete_users",
        ],
      },
      Teams: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Role Manager": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      /*Workflows: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },*/
      General: {
        is_can_select_all: false,
        disabled: ["edit_stations"],
      },
      "Order Entry": {
        is_can_select_all: false,
        disabled: [],
      },
      "File Upload": {
        is_can_select_all: true,
        disabled: [],
      },
      "Client Management": {
        is_can_select_all: false,
        disabled: ["re_assign_client"],
      },
      "Production Order Entry": {
        is_can_select_all: true,
        disabled: [],
      },
      Production: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: ["edit_production_settings"],
      },
      Approvals: {
        is_can_select_all: true,
        disabled: [
          // Approvals
          "approve_production_order",
        ],
      },
      "Digital Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Production": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Approvals": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Channels": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Traffic": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Jock Console": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Programming Elements": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Contests: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Winners List": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Prize Closet": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Clocks: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "System Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Station Settings Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
    },
    "Order Entry": {
      Users: {
        is_can_select_all: false,
        disabled: [
          // USERS
          "add_users",
          "edit_users",
          "delete_users",
        ],
      },
      Teams: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Role Manager": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      /*Workflows: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },*/
      General: {
        is_can_select_all: false,
        disabled: ["edit_stations"],
      },
      "Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "File Upload": {
        is_can_select_all: true,
        disabled: [],
      },
      "Client Management": {
        is_can_select_all: false,
        disabled: ["re_assign_client"],
      },
      "Production Order Entry": {
        is_can_select_all: true,
        disabled: [],
      },
      Production: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: ["edit_production_settings"],
      },
      Approvals: {
        is_can_select_all: true,
        disabled: [
          // Approvals
          "approve_production_order",
        ],
      },
      "Digital Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Production": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Approvals": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Channels": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Traffic": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Jock Console": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Programming Elements": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Contests: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Winners List": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Prize Closet": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Clocks: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "System Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Station Settings Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
    },
    "Production Manager": {
      Users: {
        is_can_select_all: false,
        disabled: [
          // USERS
          "add_users",
          "edit_users",
          "delete_users",
        ],
      },
      Teams: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Role Manager": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      /*Workflows: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },*/
      General: {
        is_can_select_all: false,
        disabled: ["edit_stations"],
      },
      "Order Entry": {
        is_can_select_all: false,
        disabled: [],
      },
      "File Upload": {
        is_can_select_all: true,
        disabled: [],
      },
      "Client Management": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Production Order Entry": {
        is_can_select_all: false,
        disabled: [
          "draft_production",
          "add_production",
          "edit_production",
          "delete_production",
        ],
      },
      Production: {
        is_can_select_all: true,
        disabled: ["perform_production_continuity"],
      },
      Approvals: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Production": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Approvals": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Channels": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Traffic": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Jock Console": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Programming Elements": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Contests: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Winners List": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Prize Closet": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Clocks: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "System Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Station Settings Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
    },
    Producer: {
      Users: {
        is_can_select_all: false,
        disabled: [
          // USERS
          "add_users",
          "edit_users",
          "delete_users",
        ],
      },
      Teams: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Role Manager": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      /*Workflows: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },*/
      General: {
        is_can_select_all: false,
        disabled: ["edit_stations"],
      },
      "Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "File Upload": {
        is_can_select_all: true,
        disabled: [],
      },
      "Client Management": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Production Order Entry": {
        is_can_select_all: false,
        disabled: [
          "draft_production",
          "add_production",
          "edit_production",
          "delete_production",
        ],
      },
      Production: {
        is_can_select_all: true,
        disabled: ["assign_production_team", "edit_production_settings"],
      },
      Approvals: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Production": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Approvals": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Channels": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Traffic": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Jock Console": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Programming Elements": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Contests: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Winners List": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Prize Closet": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Clocks: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "System Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Station Settings Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
    },
    "VO Talent": {
      Users: {
        is_can_select_all: false,
        disabled: [
          // USERS
          "add_users",
          "edit_users",
          "delete_users",
        ],
      },
      Teams: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Role Manager": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      /*Workflows: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },*/
      General: {
        is_can_select_all: false,
        disabled: ["edit_stations"],
      },
      "Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "File Upload": {
        is_can_select_all: true,
        disabled: [],
      },
      "Client Management": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Production Order Entry": {
        is_can_select_all: false,
        disabled: [
          "draft_production",
          "add_production",
          "edit_production",
          "delete_production",
        ],
      },
      Production: {
        is_can_select_all: true,
        disabled: [
          "assign_production_team",
          "draft_production_script",
          "submit_production_script",
          "perform_production_dubbing",
          "perform_production_continuity",
          "produce_production",
          "edit_production_settings",
        ],
      },
      Approvals: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Production": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Approvals": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Channels": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Traffic": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Jock Console": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Programming Elements": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Contests: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Winners List": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Prize Closet": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Clocks: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "System Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Station Settings Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
    },
    "Traffic Manager": {
      Users: {
        is_can_select_all: false,
        disabled: [
          // USERS
          "add_users",
          "edit_users",
          "delete_users",
        ],
      },
      Teams: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Role Manager": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      /*Workflows: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },*/
      General: {
        is_can_select_all: false,
        disabled: ["edit_stations"],
      },
      "Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "File Upload": {
        is_can_select_all: true,
        disabled: [],
      },
      "Client Management": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Production Order Entry": {
        is_can_select_all: false,
        disabled: [
          "draft_production",
          "add_production",
          "edit_production",
          "delete_production",
        ],
      },
      Production: {
        is_can_select_all: true,
        disabled: [
          "assign_production_team",
          "draft_production_script",
          "edit_vo",
          "edit_production_settings",
        ],
      },
      Approvals: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Production": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Approvals": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Channels": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Traffic": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Jock Console": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Programming Elements": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Contests: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Winners List": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Prize Closet": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Clocks: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "System Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Station Settings Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
    },
    Continuity: {
      Users: {
        is_can_select_all: false,
        disabled: [
          // USERS
          "add_users",
          "edit_users",
          "delete_users",
        ],
      },
      Teams: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Role Manager": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      /*Workflows: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },*/
      General: {
        is_can_select_all: false,
        disabled: ["edit_stations"],
      },
      "Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "File Upload": {
        is_can_select_all: true,
        disabled: [],
      },
      "Client Management": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Production Order Entry": {
        is_can_select_all: false,
        disabled: [
          "draft_production",
          "add_production",
          "edit_production",
          "delete_production",
        ],
      },
      Production: {
        is_can_select_all: true,
        disabled: [
          "assign_production_team",
          "draft_production_script",
          "edit_vo",
          "edit_production_settings",
        ],
      },
      Approvals: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Production": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Approvals": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Channels": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Traffic": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Jock Console": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Programming Elements": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Contests: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Winners List": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Prize Closet": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Clocks: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "System Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Station Settings Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
    },
    Dubber: {
      Users: {
        is_can_select_all: false,
        disabled: [
          // USERS
          "add_users",
          "edit_users",
          "delete_users",
        ],
      },
      Teams: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Role Manager": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      /*Workflows: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },*/
      General: {
        is_can_select_all: false,
        disabled: ["edit_stations"],
      },
      "Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "File Upload": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Client Management": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Production Order Entry": {
        is_can_select_all: false,
        disabled: [
          "draft_production",
          "add_production",
          "edit_production",
          "delete_production",
        ],
      },
      Production: {
        is_can_select_all: true,
        disabled: [
          "assign_production_team",
          "draft_production_script",
          "submit_production_script",
          "edit_production_script",
          "edit_vo",
          "submit_production_recording",
          "delete_production_recording",
          "perform_production_continuity",
          "produce_production",
          "edit_production_settings",
        ],
      },
      Approvals: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Production": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Approvals": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Channels": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Traffic": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Jock Console": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Programming Elements": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Contests: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Winners List": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Prize Closet": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Clocks: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "System Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Station Settings Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
    },
    Writer: {
      Users: {
        is_can_select_all: false,
        disabled: [
          // USERS
          "add_users",
          "edit_users",
          "delete_users",
        ],
      },
      Teams: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Role Manager": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      /*Workflows: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },*/
      General: {
        is_can_select_all: false,
        disabled: ["edit_stations"],
      },
      "Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "File Upload": {
        is_can_select_all: true,
        disabled: [],
      },
      "Client Management": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Production Order Entry": {
        is_can_select_all: true,
        disabled: [],
      },
      Production: {
        is_can_select_all: true,
        disabled: [
          "assign_production_team",
          "edit_vo",
          "submit_production_recording",
          "delete_production_recording",
          "perform_production_dubbing",
          "perform_production_continuity",
          "produce_production",
          "edit_production_settings",
        ],
      },
      Approvals: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Production": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Approvals": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Channels": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Traffic": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Jock Console": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Programming Elements": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Contests: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Winners List": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Prize Closet": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Clocks: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "System Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Station Settings Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
    },
    "Hub Manager": {
      Users: {
        is_can_select_all: false,
        is_disabled_all: false,
        disabled: [
          // USERS
          "add_users",
          "edit_users",
          "delete_users",
        ],
      },
      Teams: {
        is_can_select_all: true,
        disabled: [],
      },
      "Role Manager": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      /*Workflows: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },*/
      General: {
        is_can_select_all: false,
        disabled: ["edit_stations"],
      },
      "Order Entry": {
        is_can_select_all: false,
        is_disabled_all: false,
        disabled: [],
      },
      "File Upload": {
        is_can_select_all: true,
        is_disabled_all: false,
        disabled: [],
      },
      "Client Management": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Production Order Entry": {
        is_can_select_all: false,
        disabled: [
          "draft_production",
          "add_production",
          "edit_production",
          "delete_production",
        ],
      },
      Production: {
        is_can_select_all: true,
        disabled: [
          "perform_production_continuity",
          "edit_all_production_orders",
        ],
      },
      Approvals: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Order Entry": {
        is_can_select_all: false,
        disabled: [
          "draft_digital",
          "add_digital",
          "edit_digital",
          "delete_digital",
        ],
      },
      "Digital Production": {
        is_can_select_all: false,
        is_disabled_all: false,
        disabled: ["edit_all_digital_orders", "add_design", "delete_design"],
      },
      "Digital Approvals": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Channels": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Traffic": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Jock Console": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Programming Elements": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Contests: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Winners List": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Prize Closet": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Clocks: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "System Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Station Settings Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
    },
    // DIGITAL ROLES
    "Digital Account Executive": {
      Users: {
        is_can_select_all: false,
        disabled: [
          // USERS
          "add_users",
          "edit_users",
          "delete_users",
        ],
      },
      Teams: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Role Manager": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      /*Workflows: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },*/
      General: {
        is_can_select_all: false,
        disabled: ["edit_stations"],
      },
      "Order Entry": {
        is_can_select_all: false,
        disabled: [],
      },
      "File Upload": {
        is_can_select_all: true,
        disabled: [],
      },
      "Client Management": {
        is_can_select_all: true,
        disabled: ["re_assign_client"],
      },
      "Production Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Production: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Approvals: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Order Entry": {
        is_can_select_all: true,
        disabled: [],
      },
      "Digital Production": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Approvals": {
        is_can_select_all: false,
        disabled: ["approve_order"],
      },
      "Digital Channels": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Traffic": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Jock Console": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Programming Elements": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Contests: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Winners List": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Prize Closet": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Clocks: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "System Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Station Settings Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
    },
    "Digital Sales Assistant": {
      Users: {
        is_can_select_all: false,
        disabled: [
          // USERS
          "add_users",
          "edit_users",
          "delete_users",
        ],
      },
      Teams: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Role Manager": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      /*Workflows: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },*/
      General: {
        is_can_select_all: false,
        disabled: ["edit_stations"],
      },
      "Order Entry": {
        is_can_select_all: false,
        disabled: [],
      },
      "File Upload": {
        is_can_select_all: true,
        disabled: [],
      },
      "Client Management": {
        is_can_select_all: true,
        disabled: ["re_assign_client"],
      },
      "Production Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Production: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Approvals: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Order Entry": {
        is_can_select_all: true,
        disabled: [],
      },
      "Digital Production": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Approvals": {
        is_can_select_all: false,
        disabled: ["approve_order"],
      },
      "Digital Channels": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Traffic": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Jock Console": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Programming Elements": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Contests: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Winners List": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Prize Closet": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Clocks: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "System Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Station Settings Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
    },
    "Digital Channel Manager": {
      Users: {
        is_can_select_all: false,
        disabled: [
          // USERS
          "add_users",
          "edit_users",
          "delete_users",
        ],
      },
      Teams: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Role Manager": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      /*Workflows: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },*/
      General: {
        is_can_select_all: false,
        disabled: ["edit_stations"],
      },
      "Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "File Upload": {
        is_can_select_all: true,
        disabled: [],
      },
      "Client Management": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Production Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Production: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Approvals: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Order Entry": {
        is_can_select_all: false,
        disabled: [
          "draft_digital",
          "add_digital",
          "edit_digital",
          "delete_digital",
        ],
      },
      "Digital Production": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Approvals": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Channels": {
        is_can_select_all: true,
        disabled: [],
      },
      "Digital Traffic": {
        is_can_select_all: false,
        disabled: [],
      },
      "Jock Console": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Programming Elements": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Contests: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Winners List": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Prize Closet": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Clocks: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "System Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Station Settings Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
    },
    "Digital Producer": {
      Users: {
        is_can_select_all: false,
        disabled: [
          // USERS
          "add_users",
          "edit_users",
          "delete_users",
        ],
      },
      Teams: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Role Manager": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      /*Workflows: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },*/
      General: {
        is_can_select_all: false,
        disabled: ["edit_stations"],
      },
      "Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "File Upload": {
        is_can_select_all: true,
        disabled: [],
      },
      "Client Management": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Production Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Production: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Approvals: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Order Entry": {
        is_can_select_all: false,
        disabled: [
          "draft_digital",
          "add_digital",
          "edit_digital",
          "delete_digital",
        ],
      },
      "Digital Production": {
        is_can_select_all: true,
        disabled: [],
      },
      "Digital Approvals": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Channels": {
        is_can_select_all: true,
        disabled: ["edit_channels"],
      },
      "Digital Traffic": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Jock Console": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Programming Elements": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Contests: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Winners List": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Prize Closet": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Clocks: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "System Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Station Settings Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
    },
    "Full Digital Access": {
      Users: {
        is_can_select_all: false,
        disabled: [
          // USERS
          "add_users",
          "edit_users",
          "delete_users",
        ],
      },
      Teams: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Role Manager": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      /*Workflows: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },*/
      General: {
        is_can_select_all: false,
        disabled: ["edit_stations"],
      },
      "Order Entry": {
        is_can_select_all: false,
        disabled: [],
      },
      "File Upload": {
        is_can_select_all: true,
        disabled: [],
      },
      "Client Management": {
        is_can_select_all: true,
        disabled: ["re_assign_client"],
      },
      "Production Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Production: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Approvals: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Order Entry": {
        is_can_select_all: true,
        disabled: [],
      },
      "Digital Production": {
        is_can_select_all: true,
        disabled: [],
      },
      "Digital Approvals": {
        is_can_select_all: false,
        disabled: ["approve_order"],
      },
      "Digital Channels": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Traffic": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Jock Console": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Programming Elements": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Contests: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Winners List": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Prize Closet": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Clocks: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "System Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Station Settings Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
    },
    // MANAGEMENT ROLES
    "Sales Manager": {
      Users: {
        is_can_select_all: true,
        disabled: [],
      },
      Teams: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Role Manager": {
        is_can_select_all: true,
        disabled: [],
      },
      /*Workflows: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },*/
      General: {
        is_can_select_all: false,
        disabled: ["edit_stations"],
      },
      "Order Entry": {
        is_can_select_all: false,
        disabled: [],
      },
      "File Upload": {
        is_can_select_all: true,
        disabled: [],
      },
      "Client Management": {
        is_can_select_all: true,
        disabled: [],
      },
      "Production Order Entry": {
        is_can_select_all: true,
        disabled: [],
      },
      Production: {
        is_can_select_all: false,
        disabled: [
          "assign_production_team",
          "draft_production_script",
          "submit_production_script",
          "edit_production_script",
          "edit_vo",
          "submit_production_recording",
          "delete_production_recording",
          "perform_production_dubbing",
          "perform_production_continuity",
          "produce_production",
        ],
      },
      Approvals: {
        is_can_select_all: true,
        disabled: [],
      },
      "Digital Order Entry": {
        is_can_select_all: true,
        disabled: [],
      },
      "Digital Production": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Approvals": {
        is_can_select_all: true,
        disabled: [],
      },
      "Digital Channels": {
        is_can_select_all: true,
        disabled: [],
      },
      "Digital Traffic": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Jock Console": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Programming Elements": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Contests: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Winners List": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Prize Closet": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Clocks: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "System Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Station Settings Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
    },
    "Team Management": {
      Users: {
        is_can_select_all: true,
        disabled: [],
      },
      Teams: {
        is_can_select_all: true,
        disabled: [],
      },
      "Role Manager": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      /*Workflows: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },*/
      General: {
        is_can_select_all: false,
        disabled: ["edit_stations"],
      },
      "Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "File Upload": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Client Management": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Production Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Production: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Approvals: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Production": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Approvals": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Channels": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Traffic": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Jock Console": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Programming Elements": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Contests: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Winners List": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Prize Closet": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Clocks: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "System Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Station Settings Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
    },
    "User Management": {
      Users: {
        is_can_select_all: true,
        disabled: ["view_expiring_spots"],
      },
      Teams: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Role Manager": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      /*Workflows: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },*/
      General: {
        is_can_select_all: false,
        disabled: ["edit_stations"],
      },
      "Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "File Upload": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Client Management": {
        is_can_select_all: false,
        disabled: ["add_client", "edit_client", "delete_client"],
      },
      "Production Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Production: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Approvals: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Production": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Approvals": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Channels": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Traffic": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Jock Console": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Programming Elements": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Contests: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Winners List": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Prize Closet": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Clocks: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "System Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Station Settings Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
    },
    "Workflow Management": {
      Users: {
        is_can_select_all: false,
        disabled: [
          // USERS
          "add_users",
          "edit_users",
          "delete_users",
          // Production
          "view_expiring_spots",
        ],
      },
      Teams: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Role Manager": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      /*Workflows: {
        is_can_select_all: true,
        disabled: [],
      },*/
      General: {
        is_can_select_all: false,
        disabled: ["edit_stations"],
      },
      "Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "File Upload": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Client Management": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Production Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Production: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Approvals: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Production": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Approvals": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Channels": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Traffic": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Jock Console": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Programming Elements": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Contests: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Winners List": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Prize Closet": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Clocks: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "System Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Station Settings Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
    },
    "Digital Channel Management": {
      Users: {
        is_can_select_all: false,
        disabled: [
          // USERS
          "add_users",
          "edit_users",
          "delete_users",
          // Production
          "view_expiring_spots",
        ],
      },
      Teams: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Role Manager": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      /*Workflows: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },*/
      General: {
        is_can_select_all: false,
        disabled: ["edit_stations"],
      },
      "Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "File Upload": {
        is_can_select_all: true,
        is_disabled_all: true,
        disabled: [],
      },
      "Client Management": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Production Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Production: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Approvals: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Production": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Approvals": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Channels": {
        is_can_select_all: true,
        disabled: [],
      },
      "Digital Traffic": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Jock Console": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Programming Elements": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Contests: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Winners List": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Prize Closet": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Clocks: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "System Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Station Settings Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
    },
    "Role Management": {
      Users: {
        is_can_select_all: false,
        disabled: [
          // USERS
          "add_users",
          "edit_users",
          "delete_users",
          // Production
          "view_expiring_spots",
        ],
      },
      Teams: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Role Manager": {
        is_can_select_all: true,
        disabled: [],
      },
      /*Workflows: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },*/
      General: {
        is_can_select_all: false,
        disabled: ["edit_stations"],
      },
      "Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "File Upload": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Client Management": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Production Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Production: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Approvals: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Production": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Approvals": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Channels": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Traffic": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Jock Console": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Programming Elements": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Contests: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Winners List": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Prize Closet": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Clocks: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "System Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Station Settings Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
    },
    "Program Director": {
      Users: {
        is_can_select_all: false,
        disabled: [
          // USERS
          "add_users",
          "edit_users",
          "delete_users",
          // Production
          "view_expiring_spots",
        ],
      },
      Teams: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Role Manager": {
        is_can_select_all: true,
        disabled: [],
      },
      /*Workflows: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },*/
      General: {
        is_can_select_all: false,
        disabled: ["edit_stations"],
      },
      "Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "File Upload": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Client Management": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Production Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Production: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Approvals: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Production": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Approvals": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Channels": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Traffic": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Jock Console": {
        is_can_select_all: false,
        disabled: [],
      },
      "Programming Elements": {
        is_can_select_all: true,
        disabled: [],
      },
      Contests: {
        is_can_select_all: true,
        disabled: [],
      },
      "Winners List": {
        is_can_select_all: true,
        disabled: [],
      },
      "Prize Closet": {
        is_can_select_all: true,
        disabled: [],
      },
      Clocks: {
        is_can_select_all: true,
        disabled: [],
      },
      "System Forms": {
        is_can_select_all: false,
        disabled: [],
      },
      "Station Settings Forms": {
        is_can_select_all: false,
        disabled: [],
      },
    },
    "Promotions Director": {
      Users: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [
          // Production
          "view_expiring_spots",
        ],
      },
      Teams: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Role Manager": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      /*Workflows: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },*/
      General: {
        is_can_select_all: false,
        disabled: ["edit_stations"],
      },
      "Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "File Upload": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Client Management": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Production Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Production: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Approvals: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Production": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Approvals": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Channels": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Traffic": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Jock Console": {
        is_can_select_all: false,
        disabled: ["configure_console", "re_assign_shift"],
      },
      "Programming Elements": {
        is_can_select_all: true,
        disabled: [],
      },
      Contests: {
        is_can_select_all: true,
        disabled: [],
      },
      "Winners List": {
        is_can_select_all: true,
        disabled: [],
      },
      "Prize Closet": {
        is_can_select_all: true,
        disabled: [],
      },
      Clocks: {
        is_can_select_all: true,
        disabled: ["delete_programming_clock"],
      },
      "System Forms": {
        is_can_select_all: true,
        disabled: [],
      },
      "Station Settings Forms": {
        is_can_select_all: true,
        disabled: [],
      },
    },
    "Promotions Assistant": {
      Users: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [
          // Production
          "view_expiring_spots",
        ],
      },
      Teams: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Role Manager": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      /*Workflows: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },*/
      General: {
        is_can_select_all: false,
        disabled: ["edit_stations"],
      },
      "Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "File Upload": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Client Management": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Production Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Production: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Approvals: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Production": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Approvals": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Channels": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Traffic": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Jock Console": {
        is_can_select_all: false,
        is_disabled_all: true,
      },
      "Programming Elements": {
        is_can_select_all: true,
        disabled: [],
      },
      Contests: {
        is_can_select_all: true,
        disabled: [],
      },
      "Winners List": {
        is_can_select_all: true,
        disabled: [],
      },
      "Prize Closet": {
        is_can_select_all: true,
        disabled: [],
      },
      Clocks: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "System Forms": {
        is_can_select_all: false,
        disabled: [],
      },
      "Station Settings Forms": {
        is_can_select_all: false,
        disabled: [],
      },
    },
    "Prize Fulfillment": {
      Users: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [
          // Production
          "view_expiring_spots",
        ],
      },
      Teams: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Role Manager": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      /*Workflows: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },*/
      General: {
        is_can_select_all: false,
        disabled: ["edit_stations"],
      },
      "Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "File Upload": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Client Management": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Production Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Production: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Approvals: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Production": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Approvals": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Channels": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Traffic": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Jock Console": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Programming Elements": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Contests: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Winners List": {
        is_can_select_all: true,
        disabled: [],
      },
      "Prize Closet": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Clocks: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "System Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Station Settings Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
    },
    // JOCK CONSOLE ROLES
    "On-Air Talent": {
      Users: {
        is_can_select_all: false,
        disabled: [
          // USERS
          "add_users",
          "edit_users",
          "delete_users",
        ],
      },
      Teams: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Role Manager": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      /*Workflows: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },*/
      General: {
        is_can_select_all: false,
        disabled: ["edit_stations"],
      },
      "Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "File Upload": {
        is_can_select_all: true,
        disabled: [],
      },
      "Client Management": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Production Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Production: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Approvals: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Production": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Approvals": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Channels": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Traffic": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Jock Console": {
        is_can_select_all: false,
        disabled: [],
      },
      "Programming Elements": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Contests: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Winners List": {
        is_can_select_all: true,
        disabled: [],
      },
      "Prize Closet": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Clocks: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "System Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Station Settings Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
    },
    "On-Air Producer": {
      Users: {
        is_can_select_all: false,
        disabled: [
          // USERS
          "add_users",
          "edit_users",
          "delete_users",
        ],
      },
      Teams: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Role Manager": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      /*Workflows: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },*/
      General: {
        is_can_select_all: false,
        disabled: ["edit_stations"],
      },
      "Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "File Upload": {
        is_can_select_all: true,
        disabled: [],
      },
      "Client Management": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Production Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Production: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Approvals: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Order Entry": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Production": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Approvals": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Channels": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Digital Traffic": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Jock Console": {
        is_can_select_all: false,
        disabled: [],
      },
      "Programming Elements": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Contests: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Winners List": {
        is_can_select_all: true,
        disabled: [],
      },
      "Prize Closet": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      Clocks: {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "System Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
      "Station Settings Forms": {
        is_can_select_all: false,
        is_disabled_all: true,
        disabled: [],
      },
    },
  },
};
