import React from "react";
import { FormattedMessage } from "react-intl";
import UploadFiles from "components/UploadFiles";
import HandleAssets from "components/HandleAssets";
import classnames from "classnames";
import { Row, Col, FormGroup } from "reactstrap";
import { get } from "lodash";
import { ShadowBox, SectionTitle, SectionFields } from "components/Elements";
export default function Documents(props) {
  const { bem, isView, onValueChanged, validationState, process, intl } = props;
  const client_documents = get(props, "fieldRefs.client_documents", {});
  const documentsValue = get(process, "data.fields.client_documents.value", []);
  return (
    <ShadowBox className={bem.e("box-documents-details")}>
      <SectionTitle className={bem.e("section-title")}>
        <FormattedMessage id="process > title documents" />
      </SectionTitle>
      <SectionFields
        className={classnames(bem.e("section-fields"), {
          [bem.e("section-documents-details")]: !isView,
          [bem.e("section-documents-details-view")]: isView,
        })}
      >
        <Row>
          <Col xs={12} className={bem.e("reset")}>
            <FormGroup>
              {documentsValue.length > 0 && (
                <HandleAssets
                  files={documentsValue}
                  onUploadedFiles={(value) => {
                    onValueChanged(client_documents, value);
                  }}
                  onDeleted={(value) => {
                    onValueChanged(client_documents, value);
                  }}
                  isView={isView}
                  deleteHidden={isView}
                  renamingHidden={isView}
                />
              )}
              {isView && documentsValue.length === 0 && (
                <span className={bem.e("order-view-value")}>
                  {intl.formatMessage({ id: "process > none" })}
                </span>
              )}
              {!isView && (
                <React.Fragment>
                  <UploadFiles
                    attachText={props.intl.formatMessage({
                      id: "process > attach files",
                    })}
                    actions={["view", "download"]}
                    acceptMultiple={true}
                    files={get(
                      process,
                      "data.fields.client_documents.value",
                      []
                    )}
                    isHideFilesDragArea={true}
                    onUploadedFiles={(value) => {
                      onValueChanged(client_documents, [
                        ...documentsValue,
                        ...value,
                      ]);
                    }}
                    onDeleted={(value) => {
                      onValueChanged(client_documents, value);
                    }}
                    className={bem.e("upload-files")}
                  >
                    <FormattedMessage id="process > button upload" />
                  </UploadFiles>
                  {validationState[client_documents.field] && (
                    <div className="alert-danger">
                      {validationState[client_documents.field].validation_error}
                    </div>
                  )}
                </React.Fragment>
              )}
            </FormGroup>
          </Col>
        </Row>
      </SectionFields>
    </ShadowBox>
  );
}
