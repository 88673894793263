import React, { useState } from "react";
import { Row, Col, FormGroup } from "reactstrap";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { get } from "lodash";
import UploadFiles from "components/UploadFiles";
import HandleAssets from "components/HandleAssets";
import { MenuIcon } from "components/CustomIcons";
import { ShadowBox, SectionTitle, SectionFields } from "components/Elements";
import { DownIcon, UpIcon } from "components/CustomIcons";
const ContestDocuments = (props) => {
  const {
    validationState,
    process,
    bem,
    step,
    onValueChanged,
    intl,
    isView,
    openDetails,
    setOpenDetails,
    sectionKey,
  } = props;
  const [fieldRefs, setFieldRefs] = useState(null);
  const getFieldRefs = () => {
    if (fieldRefs) return fieldRefs;

    const documents = step.fields.find((f) => f.field === "documents");

    const payload = {
      documents,
    };

    setFieldRefs(payload);

    return payload;
  };
  const { documents } = getFieldRefs();
  const documentsValue = get(process, "data.fields.documents.value", []);
  const isOpen = get(openDetails, sectionKey, false);

  return (
    <ShadowBox className={bem.e("box-script-details")}>
      <SectionTitle
        className={bem.e("contest-script-section-title")}
        onClick={() =>
          setOpenDetails({
            ...openDetails,
            [sectionKey]: !isOpen,
          })
        }
      >
        <div className={bem.e("box-head-title")}>
          <span className={"menu-selection-icon"}>
            <MenuIcon />
          </span>
          <FormattedMessage id="process > promotion > contest > title contest documents" />
        </div>
        <span className={bem.e("box-action")}>
          {isOpen ? <UpIcon /> : <DownIcon />}
        </span>
      </SectionTitle>
      {isOpen && (
        <React.Fragment>
          <SectionFields
            className={classnames(bem.e("section-fields"), {
              [bem.e("contest-details-section-fields-view")]: isView,
              [bem.e("contest-details-section-fields")]: !isView,
            })}
          >
            <Row>
              <Col xs={12} className={bem.e("reset-col")}>
                <FormGroup>
                  {documentsValue.length > 0 && (
                    <HandleAssets
                      files={documentsValue}
                      onUploadedFiles={(value) => {
                        onValueChanged(documents, value);
                      }}
                      onDeleted={(value) => {
                        onValueChanged(documents, value);
                      }}
                      isView={isView}
                      deleteHidden={isView}
                      renamingHidden={isView}
                    />
                  )}
                  {isView && documentsValue.length === 0 && (
                    <span className={bem.e("order-view-value")}>
                      {intl.formatMessage({ id: "process > none" })}
                    </span>
                  )}
                  {!isView && (
                    <React.Fragment>
                      <UploadFiles
                        attachText={props.intl.formatMessage({
                          id: "process > attach files",
                        })}
                        actions={["view", "download"]}
                        acceptMultiple={true}
                        files={get(process, "data.fields.documents.value", [])}
                        isHideFilesDragArea={true}
                        onUploadedFiles={(value) => {
                          onValueChanged(documents, [
                            ...documentsValue,
                            ...value,
                          ]);
                        }}
                        onDeleted={(value) => {
                          onValueChanged(documents, value);
                        }}
                        className={bem.e("upload-files")}
                      >
                        <FormattedMessage id="process > button upload" />
                      </UploadFiles>
                      {validationState[documents.field] && (
                        <div className="alert-danger">
                          {validationState[documents.field].validation_error}
                        </div>
                      )}
                    </React.Fragment>
                  )}
                </FormGroup>
              </Col>
            </Row>
          </SectionFields>
        </React.Fragment>
      )}
    </ShadowBox>
  );
};

export default ContestDocuments;
