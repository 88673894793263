import * as React from "react";
import { Col, Label } from "reactstrap";
import { forEach, filter } from "lodash";
import Dropdown from "components/Dropdown";
import Checkbox from "components/Checkbox";
import { getHourLabel } from "utils/helpers";

const HoursDropdown = (props) => {
  const { intl, label, onChange, value, checkboxText, hours, ...rest } = props;

  let hoursList = [];

  forEach(hours, (hour) => {
    hoursList.push({
      label: getHourLabel(hour),
      value: hour,
    });
  });

  let isAllSelected = value && value.length === hoursList.length;

  return (
    <React.Fragment>
      <Col xs={10} className="dropdown-container">
        {label && (
          <Label>
            <span>{label}</span>
          </Label>
        )}
        <Dropdown
          onChange={(selectedOption) => {
            onChange(selectedOption);
          }}
          options={hoursList}
          value={value}
          optionContainerClass="col-md-4 time-option-list"
          {...rest}
        />
      </Col>
      <Col xs={2} className="checkbox-container">
        <Checkbox
          text={checkboxText}
          checked={isAllSelected}
          onChange={(checked) => {
            if (!checked) {
              onChange(
                filter(hoursList, (option) => parseInt(option.value) !== 0)
              );
            } else {
              onChange(hoursList);
            }
          }}
          disableSelectOnTextClick={true}
          checkStyle={{ color: "#fff", width: "15", height: "14" }}
        />
      </Col>
    </React.Fragment>
  );
};

HoursDropdown.defaultProps = {
  hours: [
    0,
    8,
    16,
    1,
    9,
    17,
    2,
    10,
    18,
    3,
    11,
    19,
    4,
    12,
    20,
    5,
    13,
    21,
    6,
    14,
    22,
    7,
    15,
    23,
  ],
  placeholder: "Select hours",
  isMulti: true,
  allowSelectAll: true,
  isCheckbox: true,
  menuPlacement: "top",
  allOption: { label: "All", value: "*" },
  checkboxText: "Every hour",
  hideSelectedOptions: false,
};

export default HoursDropdown;
