import * as React from "react";
import { compose, lifecycle, withHandlers, withState } from "recompose";
import { connect } from "react-redux";
import { MdImportantDevices } from "react-icons/lib/md";
import { omit, map, get } from "lodash";
import ToastManager from "components/ToastManager";
import { updateProfile } from "store/actions/auth";
import MyProfile, { MODE_VIEW, TAB_PROFILE } from "./view";
import { TAG_TYPE_LOCATION } from "components/SelectTags";
import { getTagOptionsByType } from "utils/helpers";
import { injectIntl } from "react-intl";
import { getUser } from "store/actions/users";

export default injectIntl(
  compose(
    connect(
      ({ auth, users, servers }, props) => {
        const id = props.id;
        const user = get(users, `user.${id}.data`, {});
        const locationOptions = getTagOptionsByType(TAG_TYPE_LOCATION, auth);

        const digitalMultimarket = get(auth, "info.digital_multimarket", false);
        const productionMultimarket = get(
          auth,
          "info.production_multimarket",
          false
        );
        const promotionMultimarket = get(
          auth,
          "info.promotion_multimarket",
          false
        );

        return {
          auth,
          user,
          locationOptions,
          dateFormatByServer: get(
            servers,
            "currentServer.data.date_format",
            "MM/DD/YYYY"
          ),
          currentServer: get(servers, "currentServer.data", {}),
          isLoading: get(users, `user.${id}.loading`),

          privileges: get(auth, "user.privileges", []) || [],
          hasMultimarket:
            digitalMultimarket || productionMultimarket || promotionMultimarket,
        };
      },
      {
        updateProfile,
        getUser,
      }
    ),
    withState("shouldBlockNavigation", "setShouldBlockNavigation", false),
    withState("currentMode", "setCurrentMode", MODE_VIEW),
    withState("isLoading", "setIsLoading", false),
    withState("activeTab", "setActiveTab", TAB_PROFILE),
    withHandlers({
      onFormSubmit: ({
        updateProfile,
        setIsLoading,
        setIsChangedLanguage,
        intl,
        setCurrentMode,
      }) => async (data) => {
        setIsLoading(true);
        updateProfile(
          {
            ...omit(data, [
              "password",
              "markets",
              "tags",
              "language",
              "out_of_office_settings",
            ]),
            reel: get(data, "reel"),
            markets: map(get(data, "markets", []), (item) => item.value),
            tags: map(get(data, "tags", []), (item) => item.value),
            tags_string: map(
              get(data, "tags", []),
              (item) => item.data.title
            ).join("#"),
            language: get(data, "language.value", "en-US"),
            out_of_office_settings: get(
              data,
              "out_of_office_settings",
              []
            ).filter((i) => i.from_date && i.to_date),
          },
          (status) => {
            setIsLoading(false);
            setCurrentMode(MODE_VIEW);
            ToastManager.show({
              title: <MdImportantDevices />,
              message: intl.formatMessage({
                id: `my profile > ${
                  status ? "updated successfully" : "updated error"
                }`,
              }),
              autoDismiss: 1.5,
              level: status ? "success" : "error",
            });
            if (status) {
              setIsChangedLanguage(true);
            }
          }
        );
      },
    }),
    lifecycle({
      componentDidMount() {
        const id = this.props.id;
        this.props.getUser(id);
      },
    })
  )(MyProfile)
);
