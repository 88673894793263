import React from 'react';
import { ListGroup, ListGroupItem} from "reactstrap";
import { values, has, forEach, isEqual, get } from 'lodash';

import Checkbox from "components/Checkbox";


const StationsList = ({ tag, selectedStations, updateSelectedStations, allowStationsSelect }) => {

	const {stations, type} = tag;

	const renderStationName = (station) => {

		const {key} = station;
		let stationArr = [];
		if (get(station, "call_letters", ""))
			stationArr.push(station.call_letters);
		if (get(station, "name", ""))
			stationArr.push(station.name);
		const stationName = stationArr.join(' - ');
		if(allowStationsSelect){
			return (
				<Checkbox 
    				checked={has(selectedStations, key)} 
    				onChange={checked => updateSelectedStations(checked, station)}
    				text={stationName} 
    				disableSelectOnTextClick={true}
    				checkStyle={{color: "#fff", width: '15', height:'14'}}
    			/>
			);
		}

		return (
			<span className="col-6">{stationName}</span>
		);
	}

	const displaySecondColumn = ({market, format}) => {
		let values = [];
        forEach(isEqual(type, 'market') ? format : market, value => {
          values.push(value.title);
        })
        return values.join();
	}

	const stationsList = values(stations).map((station) => {

		const {key} = station;

		return (
			 <ListGroupItem key={key}>
			 	<div className="station-line-item">
        			{renderStationName(station)}
        			<span className="col-6">{displaySecondColumn(station)}</span>
        		</div>
        	</ListGroupItem>
		);
	});

	return (
		<div className="stations-selection-list">
			<ListGroup>{stationsList}</ListGroup>
		</div>
	);
}

export default StationsList;