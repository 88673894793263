import React from "react";
import ReactTable from "react-table";
import { Button } from "reactstrap";
import Pagination from "components/Pagination";
import Spinner from "components/Spinner";
import { FormattedMessage } from "react-intl";
import { pageSize as defaultPageSize } from "utils/config";
import "./styles.css";
class Table extends React.Component {
  static propTypes = {
    ...ReactTable.prototype
  };

  static defaultProps = {
    defaultPageSize: defaultPageSize,
    multiSort: false,
    getTdProps: (tableState, row) => {
      if (!row) {
        // if it is empty padded row, then remove bottom border
        return {
          style: {
            borderRight: "0px",
            display: "flex",
            alignItems: "center"
          }
        };
      }
      return {
        style: {
          display: "flex",
          alignItems: "center"
        }
      };
    },
    getTrGroupProps: (tableState, row) => {
      if (!row) {
        // if it is empty padded row, then remove bottom border
        return {
          style: {
            borderBottom: "0px"
          }
        };
      }
      return {};
    },
    getPaginationProps: (...args) => {
      return {};
    },
    getNoDataProps: () => {
      return {};
    }
  };

  render() {
    const {
      defaultPageSize,
      multiSort,
      getTdProps,
      getTrGroupProps,
      pageSize,
      NoDataComponent,
      ...rest
    } = this.props;
    const PreviousComponent = ({ disabled, onClick }) => {
      return (
        <Button color="link" disabled={disabled} onClick={onClick}>
          <FormattedMessage id="table > button prev" />
        </Button>
      );
    };

    const NextComponent = ({ disabled, onClick }) => {
      return (
        <Button color="link" disabled={disabled} onClick={onClick}>
          <FormattedMessage id="table > button next" />
        </Button>
      );
    };

    const NoData = () => NoDataComponent ? NoDataComponent : (
      <div className="table-no-data">
        <FormattedMessage id="table > no data" />
      </div>
    );
    return (
      <ReactTable
        defaultPageSize={pageSize}
        multiSort={multiSort}
        getTdProps={getTdProps}
        getTrGroupProps={getTrGroupProps}
        PreviousComponent={PreviousComponent}
        NextComponent={NextComponent}
        LoadingComponent={({ loading }) => <Spinner isLoading={loading} />}
        PaginationComponent={Pagination}
        minRows={0}
        NoDataComponent={NoData}
        className="highlight"
        {...rest}
      />
    );
  }
}

export default Table;
