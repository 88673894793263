import { API_URL } from "./config";

export const BASE_URL = API_URL() + "/api";
export const PUBLIC_URL = API_URL() + "/app";

export const PRIVILEGES = {
  // users
  EDIT_USERS: "edit_users",
  VIEW_USERS: "view_users",
  DELETE_USERS: "delete_users",
  ADD_USERS: "add_users",
  USER_SCHEDULING: "user_scheduling",
  // Teams
  CREATE_TEAM: "create_team",
  ADD_USER_TO_TEAM: "add_user_to_team",
  VIEW_USER_ON_TEAM: "view_user_on_team",
  REMOVE_USER_FROM_TEAM: "remove_user_from_team",
  // Role Manager
  ADD_PRIVILEGES: "add_privileges",
  DELETE_PRIVILEGES: "delete_privileges",
  VIEW_PRIVILEGES: "view_privileges",
  // Workflows
  EDIT_WORKFLOWS: "edit_workflows",
  PUBLISH_WORKFLOWS: "publish_workflows",
  // File Upload
  UPLOAD_FILE: "upload_file",
  DELETE_FILE: "delete_file",
  // Client Management
  ADD_CLIENT: "add_client",
  EDIT_CLIENT: "edit_client",
  DELETE_CLIENT: "delete_client",
  REASSIGN_CLIENTS: "re_assign_client",
  // Production Order Entry
  DRAFT_PRODUCTION: "draft_production",
  ADD_PRODUCTION: "add_production",
  EDIT_PRODUCTION: "edit_production",
  VIEW_PRODUCTION: "view_production",
  DELETE_PRODUCTION: "delete_production",
  // Production
  ASSIGN_PRODUCTION_TEAM: "assign_production_team",
  DRAFT_PRODUCTION_SCRIPT: "draft_production_script",
  SUBMIT_PRODUCTION_SCRIPT: "submit_production_script",
  EDIT_PRODUCTION_SCRIPT: "edit_production_script",
  EDIT_VO: "edit_vo",
  SUBMIT_PRODUCTION_RECORDING: "submit_production_recording",
  DELETE_PRODUCTION_RECORDING: "delete_production_recording",
  PERFORM_PRODUCTION_DUBBING: "perform_production_dubbing",
  PERFORM_PRODUCTION_CONTINUITY: "perform_production_continuity",
  PRODUCE_PRODUCTION: "produce_production",
  VIEW_ALL_PRODUCTION_ORDERS: "view_all_production_orders",
  EDIT_ALL_PRODUCTION_ORDERS: "edit_all_production_orders",
  EDIT_PRODUCTION_SETTINGS: "edit_production_settings",
  SEND_BACK_PRODUCTION_ORDERS: "send_back_orders",
  VIEW_EXPIRING_SPOTS: "view_expiring_spots",
  // Approvals
  APPROVE_PRODUCTION_SCRIPT: "approve_production_script",
  APPROVE_PRODUCTION_RECORDING: "approve_production_recording",
  SUBMIT_PRODUCTION_AIRCHECK: "submit_production_air_check",
  APPROVE_PRODUCTION_ORDER: "approve_production_order",
  // Digital Order Entry
  DRAFT_DIGITAL: "draft_digital",
  ADD_DIGITAL: "add_digital",
  EDIT_DIGITAL: "edit_digital",
  VIEW_DIGITAL: "view_digital",
  DELETE_DIGITAL: "delete_digital",
  // Digital Production
  ADD_DESIGN: "add_design",
  DELETE_DESIGN: "delete_design",
  VIEW_ALL_DIGITAL_ORDERS: "view_all_digital_orders",
  EDIT_ALL_DIGITAL_ORDERS: "edit_all_digital_orders",
  // Digital Approvals
  APPROVE_ORDER: "approve_order",
  APPROVE_DESIGN: "approve_design",
  // Digital Channels
  EDIT_CHANNELS: "edit_channels",
  PUBLISH_CHANNELS: "publish_channels",
  // Digital Traffic
  PERFORM_DIGITAL_TRAFFIC: "perform_digital_traffic",
  // JOCK CONSOLE PRIVILEGES
  VIEW_TASKS: "view_tasks",
  PERFORM_TASKS: "perform_tasks",
  CONFIGURE_CONSOLE: "configure_console",
  RE_ASSIGN_SHIFT: "re_assign_shift",
  // PROMOTIONS PRIVILEGES
  // Programming Elements
  PROGRAMMING_ELEMENT_SAVE_DRAFT: "programming_element_save_draft",
  PROGRAMMING_ELEMENT_SAVE: "programming_element_save",
  PROGRAMMING_ELEMENT_PUBLISH: "programming_element_publish",
  PROGRAMMING_ELEMENT_EDIT: "programming_element_edit",
  PROGRAMMING_ELEMENT_VIEW: "programming_element_view",
  PROGRAMMING_ELEMENT_DELETE: "programming_element_delete",
  // Contests
  CONTEST_SAVE_DRAFT: "contest_save_draft",
  CONTEST_SAVE: "contest_save",
  CONTEST_PUBLISH: "contest_publish",
  CONTEST_EDIT: "contest_edit",
  CONTEST_VIEW: "contest_view",
  CONTEST_DELETE: "contest_delete",
  // Prize Closet
  PRIZE_CLOSET_SAVE: "prize_closet_save",
  PRIZE_CLOSET_EDIT: "prize_closet_edit",
  PRIZE_CLOSET_VIEW: "prize_closet_view",
  PRIZE_CLOSET_DELETE: "prize_closet_delete",
  // Clocks
  EDIT_DAYPART_CLOCK: "edit_daypart_clock",
  CREATE_SHOW_CLOCK: "create_show_clock",
  EDIT_SHOW_CLOCK: "edit_show_clock",
  VIEW_SHOW_CLOCK: "view_show_clock",
  DELETE_SHOW_CLOCK: "delete_show_clock",
  CREATE_PROGRAMMING_CLOCK: "create_programming_clock",
  EDIT_PROGRAMMING_CLOCK: "edit_programming_clock",
  VIEW_PROGRAMMING_CLOCK: "view_programming_clock",
  DELETE_PROGRAMMING_CLOCK: "delete_programming_clock",
  // Winners list
  VIEW_WINNERS_LIST: "view_winners_list",
  EDIT_WINNER: "edit_winner",
  FULFILL_WINNER_PRIZES: "fulfill_winner_prizes",
  VIEW_WINNER: "view_winner",
  VERIFY_WINNER_ADDRESS: "verify_winner_address",
  // Stations
  ADD_STATIONS: "add_stations",
  EDIT_STATIONS: "edit_stations",
  VIEW_STATIONS: "view_stations",
  DELETE_STATIONS: "delete_stations",
  MANAGE_CLIENTS: "manage_clients",
  // Servers
  ADD_SERVERS: "add_servers",
  EDIT_SERVERS: "edit_servers",
  VIEW_SERVERS: "view_servers",
  DELETE_SERVERS: "delete_servers",
  // Other
  ASSIGN_PRIVILEGES: "assign_privileges",
  REVOKE_PRIVILEGES: "revoke_privileges",
  VIEW_TEMPLATES: "view_templates",
  EDIT_TEMPLATES: "edit_templates",
  DELETE_TEMPLATES: "delete_templates",
  MY_PROFILE: "my_profile",
  SETTINGS: "settings",
  HELP: "help",
  ADD_EVENT: "add_events",
  EDIT_EVENT: "edit_events",
  DELETE_EVENT: "delete_events",
  VIEW_PROCESSES: "view_process",
  EDIT_PROCESSES: "edit_process",
  DELETE_PROCESSES: "delete_process",
  ADD_PROMOTION: "add_promotion",
  ADD_TEAMS: "add_teams",
  EDIT_TEAMS: "edit_teams",
  VIEW_TEAMS: "view_teams",
  DELETE_TEAMS: "delete_teams",
  VIEW_UPCOMING_TASKS: "view_upcoming_tasks",
  // System forms
  VIEW_FORMS: "view_forms",
  EDIT_FORMS: "edit_forms",
  // Station Setting Forms
  VIEW_STATION_SETTINGS_FORMS: "view_station_settings_forms",
  EDIT_STATION_SETTINGS_FORMS: "edit_station_settings_forms",
  ARCHIVE_PRODUCTION: "archive_production_order",
};
export const ROLES = {
  GENERAL_SALES_MANAGE: 1,
  ACCOUNT_EXECUTIVE: 2,
  SALES_ASSISTANT: 3,
  PRODUCTION_MANAGER: 4,
  STATION_ADMIN: 11,
  PRODUCER: 5,
  WRITER: 10,
  VO_TALENT: 6,
};
export const PUSHER = (id) => BASE_URL + `/message`;
// User Authentication URLs
export const REGISTER_USER = () => BASE_URL + "/register";
export const LOGIN_USER = () => BASE_URL + "/login";
export const LOGOUT_USER = () => BASE_URL + "/logout";
export const IMPERSONATE_USER = (id) => `${BASE_URL}/users/impersonate/${id}`;
export const LOGIN_ADMIN = () => BASE_URL + "/admin/login";
export const USER_INFO = () => BASE_URL + "/user/info";
export const USER_INFO_TAGS = () => BASE_URL + "/tag/getTagsCached";
export const USER_INFO_MESSAGES = () => BASE_URL + "/get_messages";
export const USER_INFO_USERS = () => BASE_URL + "/get_related_users/info";
export const REFRESH_TOKEN = () => BASE_URL + "/generate_access_token";

export const GET_USER_STATIONS = () => BASE_URL + `/user/stations`;
export const GET_NOTIFICATIONS = () => BASE_URL + `/notifications/unseen`;
export const MARK_SEEN_NOTIFICATIONS = () =>
  BASE_URL + `/notifications/mark_as_seen`;
export const MARK_SEEN_PROCESS_MESSAGES = (id) =>
  BASE_URL + `/processes/readMessage/${id}`;
// User Servers URLs
export const GET_SERVERS = () => BASE_URL + "/servers";
export const GET_SERVER = (id) => BASE_URL + `/servers/${id}`;
export const REQUEST_LOGIN_TARGET_SERVER = () => BASE_URL + `/request/servers`;
export const PRODUCTS_SETTINGS = (id) =>
  BASE_URL + `/servers/${id}/products_settings`;

export const GET_SECURITY_POLICY_SERVER = (id) =>
  BASE_URL + `/servers/security_policy/${id}`;
export const UPDATE_SECURITY_POLICY_SERVER = (id) =>
  BASE_URL + `/servers/security_policy/${id}`;
export const GET_CURRENT_SERVER = () => BASE_URL + `/current-server`;

// User stations urls
export const GET_STATIONS = () => BASE_URL + "/stations";
export const GET_STATION = (id) => BASE_URL + `/stations/${id}`;

export const GET_TEAMS = () => BASE_URL + "/teams";
export const GET_TEAM = (id) => BASE_URL + `/teams/${id}`;
export const GET_TEAM_MEMBERS = (id) => BASE_URL + `/teams/${id}/team_members`;
export const DELETE_TEAM_MEMBER = (id, userId) =>
  BASE_URL + `/teams/${id}/delete_member/${userId}`;
export const UPDATE_TEAM_LEAD = (id) =>
  BASE_URL + `/teams/${id}/select_team_lead`;
export const GET_TEAMS_AND_LEADS = () =>
  BASE_URL + `/teams/teams_and_leads_by_stations`;

// Hubs
export const GET_HUBS = () => BASE_URL + "/hubs";
export const GET_HUB = (id) => BASE_URL + `/hubs/${id}`;

export const GET_AUDIENCE_GROUPS = (id) => BASE_URL + "/groups";
export const GET_AUDIENCE_GROUP = (id) => BASE_URL + `/groups/${id}`;

export const UPDATE_CITIES_GROUPS_STATIONS = () =>
  BASE_URL + `/stations/cities_groups`;
export const UPDATE_PRIVILEGES_STATIONS = () =>
  BASE_URL + `/stations/privileges`;
//User Stations
export const GET_USER_STATION = () => BASE_URL + "/userStation";

// User events urls
export const GET_EVENTS = () => BASE_URL + "/events";
export const GET_EVENT = (id) => BASE_URL + `/events/${id}`;

// Users api urls
export const GET_ADMINS = () => BASE_URL + "/admins";
export const GET_USERS = () => BASE_URL + "/users";
export const UPDATE_PRIVILEGES_USERS = () => BASE_URL + `/users/privileges`;
export const GET_USER = (id) => BASE_URL + `/users/${id}`;
export const UPDATE_USER = (id) => `${BASE_URL}/users/update/${id}`;
export const UPDATE_PROFILE = () => BASE_URL + "/user/my_profile";
export const AUTH_CHANGE_PASSWORD = () => BASE_URL + "/user/change_password";
export const USER_CHANGE_PASSWORD = (id) =>
  `${BASE_URL}/users/change-password/${id}`;
export const UNLOCK_USER = (id) => `${BASE_URL}/user/unlock/${id}`;
export const GET_SECURITY_POLICY_USER = (id) =>
  BASE_URL + `/users/security_policy/${id}`;
export const UPDATE_SECURITY_POLICY_USER = (id) =>
  BASE_URL + `/users/security_policy/${id}`;
export const GET_ADMIN_RIGHTS_USER = (id) =>
  BASE_URL + `/users/admin_rights/${id}`;
export const UPDATE_ADMIN_RIGHTS_USER = (id) =>
  BASE_URL + `/users/admin_rights/${id}`;
export const UPDATE_ASSIGNMENT_USER = (id) =>
  BASE_URL + `/users/assignment/${id}`;
export const UPDATE_SECURITY_POLICY_USERS = () =>
  BASE_URL + `/users/security_policy`;
export const UPDATE_ADMIN_RIGHTS_USERS = () => BASE_URL + `/users/admin_rights`;
export const INVITATION_USER = (id) => `${BASE_URL}/users/invite/${id}`;
export const INVITATION_USERS = () => `${BASE_URL}/users/invite-users`;
export const ACTIVE_USERS = () => `${BASE_URL}/users/active-users`;
export const CHECK_TEAM_ASSIGNMENT_PRIVILEGES = (userId) =>
  `${BASE_URL}/users/${userId}/check_privileges_for_team_assignment`;

export const BULK_UPLOAD_USERS = () => BASE_URL + "/";
export const VALIDATE_USER_DATA = () => BASE_URL + `/users/validate`;
// User Processes URLs
export const GET_PROCESSES = (filter) =>
  BASE_URL + `/processes${filter ? "/filter/" + filter : ""}`;
export const GET_EXPIRING_SPOTS = () => BASE_URL + `/production/expiring_spots`;
export const GET_PRODUCED_SPOTS = () => BASE_URL + `/production/produced_spots`;
export const GET_ARCHIVED_SPOTS = () => BASE_URL + `/production/archived_spots`;

export const GET_PROCESS = (id) => BASE_URL + `/processes/${id ? id : "__new"}`;
export const GET_PUBLIC_PROCESS = (id) => PUBLIC_URL + `/processes/${id}`;
export const DUPLICATE_PROCESS = (id) =>
  BASE_URL + `/processes/duplicate/${id}`;
export const RESCHEDULE_PROCESS = (id) =>
  BASE_URL + `/processes/reschedule/${id}`;
export const DUPLICATE_CONTEST_PROCESS = (id) =>
  BASE_URL + `/processes/duplicate_contest/${id}`;
export const ANOTHER_PROCESS = (id) => BASE_URL + `/processes/duplicateProcess`;
export const CONTINUITY_TAKEN_PROCESS = (id) =>
  BASE_URL + `/processes/continuity_taken_order/${id}`;
export const CONTINUITY_RELEASE_PROCESS = (id) =>
  BASE_URL + `/processes/continuity_released_order/${id}`;
export const SEND_MESSAGE_PROCESS = (id) =>
  BASE_URL + `/processes/${id}/new_message`;
export const ASSIGN_TEAM_PROCESS = (id) =>
  BASE_URL + `/processes/${id}/assign-team`;
export const ASSIGN_VO_TEAM_PROCESS = (id) =>
  BASE_URL + `/processes/${id}/assign-vo`;
export const GET_MESSAGES_BY_PROCESS = (id) =>
  BASE_URL + `/processes/${id}/messages`;

export const GET_PROCESSES_BY_IDS = () =>
  BASE_URL + `/processes/list/find-by-ids`;
export const ADD_ROTATION_PROCESS = (id) =>
  BASE_URL + `/processes/add-rotation/${id}`;
export const COPY_ORDER = (id) => BASE_URL + `/processes/copy-order/${id}`;
export const UPDATE_FINAL_SCRIPT = (id) =>
  BASE_URL + `/processes/${id}/update_final_script`;
export const SEND_BACK_PROCESS = () => BASE_URL + `/processes/send_back`;
export const CONVERT_SPEC_TO_SOLD = (id) =>
  BASE_URL + `/production/${id}/convert_spec_to_sold`;
export const CHECK_AUTO_DUBBING = (id) =>
  BASE_URL + `/production/${id}/check_auto_dubbing`;
// programing elements
export const GET_ITEMS_AVAILABILITY = () =>
  BASE_URL + `/prog_elements/items_availability`;
export const CHECK_SCHEDULING_AVAILABILITY = () =>
  BASE_URL + `/prog_elements/check_scheduling_availability`;
export const GET_CLOCKS_DATA = () => BASE_URL + `/prog_elements/clocks_data`;
export const FIND_INVENTORY = () => BASE_URL + `/prog_elements/find_inventory`;
export const SAVE_INVENTORY = () => BASE_URL + `/prog_elements/save_inventory`;
export const RELEASE_INVENTORY = () =>
  BASE_URL + `/prog_elements/release_inventory`;
export const RELEASE_RESERVED_INVENTORY = () =>
  BASE_URL + `/prog_elements/release_reserved_slots`;
export const GET_SCHEDULES = () => BASE_URL + `/prog_elements/schedules`;
export const PROGRAMMING_VALIDATE_COPY_GROUPS = () =>
  BASE_URL + `/prog_elements/validate_copy_groups`;

export const SEARCH_ELEMENTS = () => BASE_URL + `/processes/filter/liners`;
export const SWAP_OVERRIDE_CHECK = () =>
  `${BASE_URL}/prog_elements/schedules/swap_override_check`;
export const SWAP_SCHEDULE = () => `${BASE_URL}/prog_elements/schedules/swap`;
export const GET_SWAPPABLE_SLOTS = () =>
  `${BASE_URL}/prog_elements/schedules/get_swappable_slots`;
export const GET_SCHEDULES_LOG = () =>
  `${BASE_URL}/prog_elements/schedules/items_logs`;
export const PROGRAMMING_ITEMS_LOGS_PDF = () =>
  `${BASE_URL}/prog_elements/schedules/items_logs_pdf`;
export const PROGRAMMING_DELETE_SLOT = () =>
  `${BASE_URL}/prog_elements/schedules/delete`;
export const PROGRAMMING_UNDO_DELETE_SLOT = () =>
  `${BASE_URL}/prog_elements/schedules/delete/undo`;
export const GET_ORDERS_LIST = () =>
  `${BASE_URL}/prog_elements/orders_list_for_fill_rotation`;
export const GET_FILL_SETTINGS = () =>
  `${BASE_URL}/prog_elements/get_fill_settings`;
export const SAVE_FILL_SETTINGS = () =>
  `${BASE_URL}/prog_elements/save_fill_settings`;
export const FILL_SCHEDULING_MANUALLY = () =>
  `${BASE_URL}/prog_elements/fill_scheduling_manually`;
export const FILL_SCHEDULING_UNDO = () =>
  `${BASE_URL}/prog_elements/fill_scheduling_undo`;
export const AUTOSUGGEST_CLIENTS = (filter, template_key) =>
  BASE_URL +
  `/clients${filter ? "?q=" + filter : "?q="}${
    template_key ? "&template_key=" + template_key : "&template_key="
  }`;

export const AUTOSUGGEST_AGENCIES = (filter, template_key) =>
  BASE_URL +
  `/agencies${filter ? "?q=" + filter : "?q="}${
    template_key ? "&template_key=" + template_key : "&template_key="
  }`;

export const AUTOSUGGEST_CONTRACTS = (id, filter) =>
  BASE_URL + `/client_contracts/${id}${filter ? "?q=" + filter : ""}`;
export const AUTOSUGGEST_CONTACTS = (id, filter, template_key) =>
  BASE_URL +
  `/client_contacts/${id}${filter ? "?q=" + filter : "?q="}${
    template_key ? "&template_key=" + template_key : "&template_key="
  }`;
// BASE_URL + `/client_contacts/${id}${filter ? "?q=" + filter : ""}`;

export const AUTOSUGGEST_MATERIAL_GROUPS = (filter, template_key) =>
  BASE_URL +
  `/material_groups${filter ? "?q=" + filter : "?q="}${
    template_key ? "&template_key=" + template_key : "&template_key="
  }`;

export const MATERIAL_GROUPS = (id) =>
  BASE_URL + `/servers/${id}/material_groups`;

export const PROCESS_REPLY_MESSAGE = (id) =>
  BASE_URL + `/processes/replyMessage/${id}`;

export const GET_PRIZES = (filter) =>
  BASE_URL + `/prizes${filter ? "/filter/" + filter : ""}`;
export const GET_PRIZE = (id) => BASE_URL + `/prize/${id}`;
export const ADD_PRIZES = () => `${BASE_URL}/prize`;
export const GET_PRIZES_ALL = () => BASE_URL + "/prizes";
export const DUPLICATE_PRIZE = (id) => BASE_URL + `/prizes/duplicate/${id}`;

//resources
export const GET_RESOURCES = (filter) =>
  BASE_URL + `/resources${filter ? "/filter/" + filter : ""}`;
export const GET_RESOURCE = (id) => BASE_URL + `/resource/${id}`;
export const ADD_RESOURCES = () => `${BASE_URL}/resource`;
export const GET_RESOURCES_ALL = () => BASE_URL + "/resources";
export const DUPLICATE_RESOURCE = (id) =>
  BASE_URL + `/resources/duplicate/${id}`;

//audit
export const GET_AUDITS = (filter) =>
  BASE_URL + `/audits${filter ? "/filter/" + filter : ""}`;
export const GET_AUDIT = (id) => BASE_URL + `/audit/${id}`;
export const ADD_AUDITS = () => `${BASE_URL}/audit`;
export const GET_AUDITS_ALL = () => BASE_URL + "/audits";
export const DUPLICATE_AUDIT = (id) => BASE_URL + `/audits/duplicate/${id}`;

// tags
export const GET_TAG_TYPES = () => BASE_URL + `/tag_type`;
export const GET_CITIES = () => BASE_URL + `/tag/getCities`;
export const UPDATE_CITIES = () => BASE_URL + `/tag/city/updateCities`;
export const UPDATE_CITY = (id) => BASE_URL + `/tag/${id}`;
export const DELETE_CITY = (id) => BASE_URL + `/tag/${id}`;
export const ADD_CITY = () => BASE_URL + `/tag`;

export const GET_GROUPS = () => BASE_URL + `/tag/getGroups`;
export const UPDATE_GROUPS = () => BASE_URL + `/tag/group/updateGroups`;
export const UPDATE_GROUP = (id) => BASE_URL + `/tag/${id}`;
export const DELETE_GROUP = (id) => BASE_URL + `/tag/${id}`;
export const ADD_GROUP = () => BASE_URL + `/tag`;
export const ADD_MARKET = () => BASE_URL + `/tag`;

export const GET_TEMPLATES = () => BASE_URL + `/templates`;
export const GET_TEMPLATE = (id) => BASE_URL + `/templates/${id}`;
export const SAVE_TEMPLATE = () => BASE_URL + `/templates/save`;

// upload file
export const FILE_UPLOADS = () => BASE_URL + `/file/uploads`;
export const FILES_UPLOADS = () => BASE_URL + `/file/uploadsfiles`;
export const FILES_DOWNLOADS = () => BASE_URL + `/file/downloadfiles`;
export const FILE_DELETE = () => BASE_URL + `/file/delete`;
export const FILE_RENAME = () => BASE_URL + `/file/rename`;
// pdf
export const PDF_DOWNLOAD = () => BASE_URL + `/pdf`;
// settings
export const UPDATE_SETTINGS = () => BASE_URL + `/settings`;
// send mail
export const SEND_MAIL = () => BASE_URL + `/mail`;

// importing files
export const IMPORT_RECORD = () => BASE_URL + `/import`;
export const PURGE_RECORD = () => BASE_URL + `/purgerecords`;
export const DELETE_INDEXES = () => BASE_URL + `/deleteindexes`;
export const DELETE_INDEX = (id) => BASE_URL + `/deleteindex/${id}`;

// roles
export const GET_ROLES = () => BASE_URL + `/roles`;

// Jock Console
export const GET_JOCK_SCHEDULES = () => BASE_URL + `/jock_console/schedules`;
export const GET_JOCK_SCHEDULES_FLOATING = () =>
  BASE_URL + `/jock_console/floating_contest`;
export const UPDATE_JOCK_SCHEDULE = () =>
  BASE_URL + `/jock_console/schedules/update`;
export const GET_JOCK_SCHEDULE = (id) =>
  BASE_URL + `/jock_console/schedules/${id}`;
export const CREATE_FLOATING_CONTEST = () =>
  BASE_URL + `/jock_console/create_floating_contest_play`;
// channels
export const GET_CHANNELS = () => BASE_URL + `/channels`;
export const GET_CHANNEL = (id) => BASE_URL + `/channel/${id}`;
export const CANCEL_CHANNEL = (id) => BASE_URL + `/cancel_channel/${id}`;
export const ADD_CHANNEL = () => BASE_URL + `/channels`;
export const UPDATE_ORDER_CHANNELS = () => BASE_URL + `/channels/order`;
export const UPDATE_CHANNELS = () => BASE_URL + `/channels/update-multiple`;
// system forms
export const GET_SYSTEM_FORMS = () => BASE_URL + `/system_forms`;
export const DUPLICATE_SYSTEM_FORMS = (id) =>
  BASE_URL + `/system_forms/${id}/duplicate`;
export const DEFAULT_SYSTEM_FORMS = (id) =>
  BASE_URL + `/system_forms/${id}/make_default`;
// workflows
export const GET_WORKFLOWS = () => BASE_URL + `/workflows`;
export const GET_WORKFLOW = (id) => BASE_URL + `/workflows/${id}`;
export const ADD_WORKFLOW = () => BASE_URL + `/workflows`;

export const VALIDATE_SUBDOMAIN = () => BASE_URL + `/validate-subdomain`;

export const GET_CLOCKS = (id) => BASE_URL + "/clocks";
export const GET_CLOCK = (id) => BASE_URL + `/clocks/${id}`;
export const RESET_CLOCK = () => BASE_URL + `/clocks/reset_dayparts`;
export const DELETE_SLOT_CATEGORY = () =>
  `${BASE_URL}/clocks/delete_slot_category`;

export const GET_LOCATIONS = () => BASE_URL + "/locations";
export const GET_LOCATION = (id) => BASE_URL + `/locations/${id}`;

export const GET_CLIENTS = () => BASE_URL + "/clients/get-clients";
export const GET_CLIENT = (id) => BASE_URL + `/clients/${id}`;
export const ADD_CLIENT = () => BASE_URL + `/clients`;
export const MESSAGE_CLIENT = (id) => BASE_URL + `/clients/${id}/message`;
export const REASSIGN_CLIENTS = () => BASE_URL + `/clients/reassign-clients`;

export const WIDE_ORBIT_SYNC = () => BASE_URL + `/wideorbit/sync`;
export const WIDE_ORBIT_CART_NUMBER_SYNC = () =>
  BASE_URL + `/wideorbit/cart-number/sync`;
export const PROCESS_AUTOMATE_DUBBING = (id) =>
  BASE_URL + `/processes/${id}/automated-dubbing`;

export const JOCK_CONSOLE_WIDGETS = () => BASE_URL + `/jc_widgets`;
export const UPDATE_ORDER_WIDGETS = () => BASE_URL + `/jc_widgets/order`;
export const JOCK_CONSOLE_DISPLAY_SETTINGS = () =>
  BASE_URL + `/jc_widgets/display_settings`;

// Winners
export const GET_WINNERS = () => BASE_URL + "/winners";
export const GET_WINNER = (id) => BASE_URL + `/winners/${id}`;
export const MARK_WIN_LOSS = () => BASE_URL + "/winners/mark_win_or_loss";
export const PICK_PRIZE_FOR_WINNER = () => BASE_URL + "/winners/pick_prize";
export const PICK_GRAND_PRIZE_WINNER = () =>
  BASE_URL + "/winners/pick_grand_prize_winner";
export const ADD_UNIT_PRIZE_FOR_WINNER = () =>
  BASE_URL + "/winners/add_standard_giveaway_cash_amount";
export const SEARCH_WINNERS = (id) => BASE_URL + `/winners/search`;
export const ASSIGN_WINNER_TO_CONTEST = (winnerId, eventId) =>
  BASE_URL + `/winners/${winnerId}/event/${eventId}`;
export const UPDATE_WINNER_TO_CONTEST = (winnerId, contestId) =>
  BASE_URL + `/winners/${winnerId}/contest/${contestId}`;
export const WINNER_FULFILL_PRIZE = (winnerId, eventId, prizeId) =>
  BASE_URL + `/winners/${winnerId}/fulfill/${eventId}/${prizeId}`;
export const EXPORT_WINNERS = () => BASE_URL + `/winners/export`;
export const EXPORT_1099_REPORTS_WINNERS = () =>
  BASE_URL + `/winners/1099_report_export`;
export const WINNER_SEARCHES = () => BASE_URL + `/winner_searches`;
export const GET_WINNERS_1099_REPORT = () => BASE_URL + "/winners/1099_report";
export const GET_WINNERS_NOTIFICATIONS = () =>
  BASE_URL + "/winners/notifications";

// Contests
export const CONTESTS_QUICK_VIEW = () => BASE_URL + "/contests/quick_view";
export const CONTESTS_END_EARLY = () => BASE_URL + "/contests/end_early";
// message
export const EDIT_MESSAGE = (id) => BASE_URL + `/processes/editMessage/${id}`;
export const DELETE_MESSAGE = (id) =>
  BASE_URL + `/processes/deleteMessage/${id}`;
export const DELETE_MESSAGES = () => BASE_URL + `/processes/deleteMessages`;
// Station settings
export const GET_STATES_FOR_COUNTRIES = () => BASE_URL + "/countries/states";
export const GET_COUNTIES = () => BASE_URL + "/countries/counties";
export const STATION_ELIGIBILITY_SETTINGS = () =>
  BASE_URL + "/station_eligibility_settings";
export const STATION_W9_FORMS_SETTINGS = () =>
  BASE_URL + "/station_w9_forms_settings";
export const STATION_ADDRESS_VERIFICATION_SETTINGS = () =>
  BASE_URL + "/station_address_verification_settings";
export const SAVE_FULFILLMENT_REMINDERS_DAYS = () =>
  BASE_URL + "/fulfillment_reminders_days_settings";
export const STATION_SETTINGS = () => BASE_URL + "/station_settings";
export const FULFILLMENT_AUTOMATION_SETTINGS = () =>
  BASE_URL + "/fulfillment_automation_settings";

export const GET_SYSTEM_TEMPLATES = () => BASE_URL + "/system_templates";
export const GET_SYSTEM_TEMPLATE = (id) => BASE_URL + `/system_templates/${id}`;
export const SET_SYSTEM_TEMPLATE_PRIMARY = (id) =>
  BASE_URL + `/system_templates/${id}/make_primary`;
export const DUPPLICATE_SYSTEM_TEMPLATE = (id) =>
  BASE_URL + `/system_templates/${id}/duplicate`;
// production settings
export const GET_PRODUCTION_SETTINGS = () => BASE_URL + `/production_settings`;
// enterprise settings
export const GET_ENTERPRISE_SETTINGS = () => BASE_URL + `/enterprise_settings`;
// zetta
export const ZETTA = () => BASE_URL + `/zetta`;

export const NOTIFICATION_SYSTEM_STYLE = {
  NotificationItem: {
    DefaultStyle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",

      borderRadius: "4px",
      fontSize: "14px",
    },

    success: {
      borderTop: 0,
      backgroundColor: "#45b649",
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow: 0,
    },

    error: {
      borderTop: 0,
      backgroundColor: "#f85032",
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow: 0,
    },

    warning: {
      borderTop: 0,
      backgroundColor: "#ffd700",
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow: 0,
    },

    info: {
      borderTop: 0,
      background: "linear-gradient(to right, #6a82fb, #fc5c7d)",
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow: 0,
    },
  },

  Title: {
    DefaultStyle: {
      margin: 0,
      padding: 0,
      paddingRight: 5,
      color: "#fff",
      display: "inline-flex",
      fontSize: 20,
      fontWeight: "bold",
      // left: '15px',
      // position: 'absolute',
      // top: '50%',
    },
  },

  MessageWrapper: {
    DefaultStyle: {
      display: "block",
      color: "#fff",
      width: "100%",
    },
  },

  Dismiss: {
    DefaultStyle: {
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      fontFamily: "inherit",
      fontSize: 20,
      color: "#f2f2f2",
      position: "relative",
      margin: 0,
      padding: 0,
      background: "none",
      borderRadius: 0,
      opacity: 1,
      width: 20,
      height: 20,
      textAlign: "initial",
      float: "none",
      top: "unset",
      right: "unset",
      lineHeight: "inherit",
    },
  },

  Action: {
    DefaultStyle: {
      background: "#fff",
      borderRadius: "2px",
      padding: "6px 20px",
      fontWeight: "bold",
      margin: "10px 0 0 0",
      border: 0,
    },

    success: {
      backgroundColor: "#45b649",
      color: "#fff",
    },

    error: {
      backgroundColor: "#f85032",
      color: "#fff",
    },

    warning: {
      backgroundColor: "#ffd700",
      color: "#fff",
    },

    info: {
      backgroundColor: "#00c9ff",
      color: "#fff",
    },
  },

  ActionWrapper: {
    DefaultStyle: {
      margin: 0,
      padding: 0,
    },
  },
};

export const STATION = 0;
export const TEAM = 1;
export const AUDIENCE = 0;
export const DAYPART = "Daypart";
export const SHOWCLOCK = "Show Clock";
export const PROGCLOCK = "Programming Clock";

export const colorPickerColors = [
  "#6A9C00",
  "#009C77",
  "#3282E1",
  "#9F5AB3",
  "#507403",
  "#017257",
  "#004DC1",
  "#792E91",
  "#F6C435",
  "#EE7E32",
  "#F04C43",
  "#F043AB",
  "#CA904D",
  "#DB541C",
  "#B92A22",
  "#CA186D",
  "#888264",
  "#698864",
  "#656488",
  "#886475",
];

export const spotTypes = [
  {
    label: "Standard",
    value: "Standard",
  },
  {
    label: "Book-ends",
    value: "Book-ends",
  },
];

export const defaultOrderSectionsContest = [
  "schedule",
  "rules",
  "associated",
  "client",
  "document",
];

export const voicePreferences = ["no_preference", "male", "female", "multiple"];
