import { first, findIndex, get } from "lodash";
class CustomError extends Error {
  constructor(...params) {
    console.log("Errors", params);
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params);
    this.error = params;
    //  Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CustomError);
    }
    let messages = [];
    if (typeof first(params) === "string") {
      messages.push({
        key: "",
        message: first(params)
      });
    } else if (params.length) {
      params.map(error => {
        for (const key in error) {
          if (error.hasOwnProperty(key)) {
            const element = error[key];
            messages.push({
              key,
              message: element.join("")
            });
          }
        }
      });
    }
    if (messages.length) {
      const index = findIndex(messages, item => item.key === "global");
      if (index > -1) {
        this.message = get(messages, `${index}.message`, "");
      } else {
        this.message = messages.map(item => `${item.message}`).join(', ');
      }
    }
  }
}
export default CustomError;
