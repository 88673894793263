import React, { useEffect } from "react";
import { first, get, map } from "lodash";
import Dropdown from "components/Dropdown";
import { Asterisk } from "components/Elements";
import { Label } from "reactstrap";

const SelectedContinuity = (props) => {
  const {
    error,
    options,
    onChange,
    selectContinuityField,
    isEnableEnterprise,
    disabled,
    selectedContinuity,
    intl,
    bem,
  } = props;
  const selectedOption =
    get(selectedContinuity, "length") > 0 ? first(selectedContinuity) : "";
  useEffect(() => {
    if (options && options.length === 1) {
      const option = first(options);
      if (
        !option ||
        get(option, "value", "") === get(selectedOption, "value")
      ) {
        return;
      } else {
        onChange(option);
      }
    }
  }, [options]);

  if (options && options.length === 1) {
    return (
      <React.Fragment>
        <Label className={bem.e("production-type-label")}>
          {selectContinuityField.title}
        </Label>
        <span className={bem.e("order-view-value")}>
          {get(selectedContinuity, "length", 0) > 0
            ? map(selectedContinuity, (item) => item.label).join(",")
            : intl.formatMessage({
                id: "process > none",
              })}
        </span>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <Dropdown
        isClearable={get(options, "length", 0) > 1 ? true : false}
        name="select_continuity"
        {...selectContinuityField.props}
        placeholder={intl.formatMessage({
          id: "process > placeholder select",
        })}
        options={options}
        label={
          <span>
            {selectContinuityField.title}
            {selectContinuityField.mandatory && !isEnableEnterprise && (
              <Asterisk>*</Asterisk>
            )}
          </span>
        }
        value={selectedOption}
        onChange={onChange}
        disabled={disabled}
        error={error}
      />
    </React.Fragment>
  );
};
export default SelectedContinuity;
