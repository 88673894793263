import View from "./view";
import React from "react";
import { compose, withState, withHandlers, lifecycle } from "recompose";
import { connect } from "react-redux";
import { map, get, isFunction, indexOf } from "lodash";
import { default_templates } from "utils/defaults";
import { MdImportantDevices } from "react-icons/lib/md";
import ToastManager from "components/ToastManager";
import {
  getWorkflows,
  addWorkflow,
  updateWorkflow,
  deleteWorkflow
} from "store/actions/workflows";
import { injectIntl } from "react-intl";
import { fix_template_roles } from "../../utils/helpers";

export default injectIntl(
  compose(
    connect(
      state => ({
        default_templates,
        info: state.auth.info,
        roles: state.auth.info.roles,
        users: state.auth.info.users,
        workflows: get(state, "workflows.workflows.data.data", []),
        is_dirty: get(state, "workflows.workflows.is_dirty", false),
        isFetch: get(state, 'workflows.workflows.is_dirty', false) || !get(state, 'workflows.workflows.data.page'),
        loading:
          get(state, "workflows.workflows.loading", false) ||
          get(state, "workflows.updateWorkflow.loading", false) ||
          get(state, "workflows.addWorkflow.loading", false) ||
          get(state, "workflows.deleteWorkflow.loading", false)
      }),
      { getWorkflows, addWorkflow, updateWorkflow, deleteWorkflow }
    ),
    withState("isOpenSteps", "setIsOpenSteps", {}),
    withState(
      "isOpenDropdownNewWorkflow",
      "setIsOpenDropdownNewWorkflow",
      false
    ),
    withState("newWorkflow", "setNewWorkflow", null),
    withState("currentWorkflowDelete", "setCurrentWorkflowDelete", null),
    withState("isOpenConfirmationModal", "setIsOpenConfirmationModal", false),
    withHandlers({
      setIsOpenWorkflow: ({ setIsOpenSteps }) => value => {
        setIsOpenSteps({
          ...value
        });
      },
      onToggleOpenDropdownNewWorkflow: ({
        isOpenDropdownNewWorkflow,
        setIsOpenDropdownNewWorkflow
      }) => () => {
        setIsOpenDropdownNewWorkflow(!isOpenDropdownNewWorkflow);
      },

      getDefaultSystemWorkfolows: ({ default_templates, roles, info }) => () => {
        let fixed_default_templates={};
        Object.keys(default_templates).filter(template_key => {
          let template = JSON.parse(JSON.stringify(default_templates[template_key]));
          if(template._id === 'basic_production' || template._id === 'production_provided'){
            return get(info, 'enable_production', false)
          }
          if(template._id === 'digital'){
            return get(info, 'enable_digital', false)
          }
          if(template._id === 'promotion'){
            return get(info, 'enable_promotion', false)
          }
          return true;
        }).map(template_key => {
          let template = JSON.parse(JSON.stringify(default_templates[template_key]));
          template._source = fix_template_roles(template._source, {roles});
          fixed_default_templates[template_key] = template;
        });
        const getStepRoles = step => {
          let stepRoles = roles.filter(role => {
            return (
              Object.values(role.privileges).find(
                rp => indexOf(step.step_editors_privileges, rp) > -1
              ) || indexOf(step.step_editors_roles, role.id) > -1
            );
          });
          return stepRoles;
        };
        let res = [];
        // debugger
        map(fixed_default_templates, t => {
          if (!t._source.workflow) return false;
          let steps = [];
          Object.keys(t._source.steps).map(stepKey => {
            const step = t._source.steps[stepKey];
            if (!step.workflow) return false;
            let flowStep = {
              id: step.key,
              name: step.workflow.title,
              is_approval_step: step.is_approval_step,
              stepIcon: step.workflow.icon,
              roles: getStepRoles(step)
            };
            steps.push(flowStep);
          });
          let flow = {
            id: t._id,
            name: t._source.workflow.title,
            steps,
            is_default: true,
            is_published: true,
            is_allow_create_custom_workflow:
              t._source.is_allow_create_custom_workflow
          };
          res.push(flow);
        });
        return res;
      }
    }),
    withHandlers({
      onCreateNewWorkflow: ({
        setNewWorkflow,
        setIsOpenDropdownNewWorkflow,
        onToggleOpenDropdownNewWorkflow,
        setIsOpenWorkflow
      }) => data => {
        let name = `New Digital Workflow`;
        if (data.id === "basic_production") {
          name = `New Production Workflow`;
        }
        const workflowData = {
          id: -1,
          name,
          type: data.id,
          is_published: false,
          is_default: false,
          steps: data.steps
        };
        setNewWorkflow(workflowData);
        onToggleOpenDropdownNewWorkflow();
        setIsOpenDropdownNewWorkflow(false);
        setTimeout(() => setIsOpenWorkflow({ "-1": true }), 20);
      },
      onSubmitForm: ({
        addWorkflow,
        setNewWorkflow,
        updateWorkflow,
        intl,
      }) => (values, cb) => {
        if (values.id === -1) {
          addWorkflow(values, response => {
            if (response.status) {
              setNewWorkflow(null);
              ToastManager.show({
                title: <MdImportantDevices />,
                message: intl.formatMessage({
                  id: `workflow manager > created successfully`
                }),
                autoDismiss: 1.5,
                level: "success"
              });
              if(isFunction(cb)){
                cb()
              }
            }
          });
        } else {
          updateWorkflow(values.id, values, response => {
            if (response.status) {
              ToastManager.show({
                title: <MdImportantDevices />,
                message: intl.formatMessage({
                  id: `workflow manager > updated successfully`
                }),
                autoDismiss: 1.5,
                level: "success"
              });
              if(isFunction(cb)){
                cb()
              }
            }
          });
        }
      },
      onDeleteWorkflow: ({
        deleteWorkflow,
        currentWorkflowDelete,
        setCurrentWorkflowDelete,
        setIsOpenConfirmationModal,
        intl
      }) => () => {
        deleteWorkflow(currentWorkflowDelete, (response) => {
          if (response.status) {
            setCurrentWorkflowDelete(null);
            setIsOpenConfirmationModal(false);
            ToastManager.show({
              title: <MdImportantDevices />,
              message: intl.formatMessage({
                id: `workflow manager > deleted successfully`
              }),
              autoDismiss: 1.5,
              level: "success"
            });
          }
        })
      }
    }),
    lifecycle({
      componentDidMount() {
        if(this.props.isFetch){
          this.props.getWorkflows({});
        }
      },
      componentDidUpdate(prevProps) {
        if (this.props.is_dirty !== prevProps.is_dirty && this.props.is_dirty) {
          this.props.getWorkflows({});
        }
      }
    })
  )(View)
);
