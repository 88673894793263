import React, { useState, useEffect } from "react";
import bn from "utils/bemnames";
import PropTypes from "prop-types";
import { get, map, omit, find, isArray } from "lodash";
import classnames from "classnames";
import NumberFormat from "react-number-format";
import { FormattedMessage } from "react-intl";
const bem = bn.create("rotation-chart");
export default function RotationChart(props) {
  const {
    process,
    isContinuity,
    valueChanged,
    template,
    user,
    step,
    isShowAdjust,
    isView,
    title,
    error,
    stations,
    setShouldBlockNavigation,
  } = props;
  const [isEditable, setIsEditable] = useState(!props.isView);
  useEffect(() => {
    setIsEditable(!props.isView);
  }, [props.isView]);
  const onAdjustRotations = () => {
    setIsEditable(!isEditable);
  };
  const spotInfo = get(process, `data.fields.spot_info.value`, []);
  const totalSpotsLength = props.totalSpots
    ? props.totalSpots
    : spotInfo.length;
  const rotationChart = props.rotationChartValue
    ? props.rotationChartValue
    : get(process, "data.fields.rotation_chart.value", []);
  const onChangeRotationValue = (indexRow, indexColumn, value) => {
    let newRotationChart = rotationChart;
    newRotationChart[indexRow][indexColumn] = value;
    if (props.onChange) {
      props.onChange(newRotationChart);
    } else {
      const rotationChartField = step
        ? step.fields.find((f) => f.field === "rotation_chart")
        : null;
      valueChanged(rotationChartField, process, template, user, {
        value: rotationChart,
      });
    }
    if (setShouldBlockNavigation) setShouldBlockNavigation(true);
  };
  if (!rotationChart.length || totalSpotsLength < 2) return null;
  const validationError =
    error && isArray(error)
      ? error
      : get(props, "validationState.rotation_chart.validation_error", []);
  const isError = !!find(validationError, (item) => item);
  return (
    <div className={bem.b()}>
      <div className={bem.e("head")}>
        <h3 className={bem.e("field-label")}>
          {title ? (
            title
          ) : (
            <FormattedMessage id="process > spot rotation chart" />
          )}
        </h3>
        {isShowAdjust && !isView && (
          <span
            onClick={onAdjustRotations}
            className={bem.e("adjust-rotations")}
          >
            <FormattedMessage id="process > adjust rotations" />
          </span>
        )}
      </div>
      <div
        className={classnames(bem.e("table"), {
          [bem.e("table-view-mode")]: !isEditable,
        })}
      >
        <div className={bem.e("thead")}>
          <div className={bem.e("tr")}>
            <div className={classnames(bem.e("first-head"), bem.e("th"))}>
              <span className={bem.e("head-label")}>
                <FormattedMessage id="process > station" />
              </span>
            </div>
            {map(new Array(totalSpotsLength), (item, index) => {
              return (
                <div
                  className={classnames(bem.e("head-label"), bem.e("th"), {
                    [bem.e("head-label-first")]: index === 0,
                    [bem.e("head-label-last")]: index === totalSpotsLength - 1,
                  })}
                  key={index}
                >
                  {index + 1}
                </div>
              );
            })}
          </div>
        </div>
        <div className={bem.e("tbody")}>
          {map(rotationChart, (row, index) => {
            const rows = omit(row, ["station"]);
            const totalCols = Object.keys(rows).length;
            const rowValues = map(rows, (item, index2) => {
              return (
                <div
                  key={index2}
                  className={classnames(bem.e("rotation-value"), bem.e("td"), {
                    [bem.e("rotation-value-first")]: Number(index2) === 0,
                    [bem.e("rotation-value-last")]:
                      Number(index2) === totalCols - 1,
                  })}
                >
                  <NumberFormat
                    value={item}
                    displayType={!isEditable ? "text" : "input"}
                    className={classnames({
                      [bem.e("rotation-input")]: isEditable,
                      [bem.e("rotation-text")]: !isEditable,
                      [bem.e("rotation-continuity-text")]:
                        !isEditable && isContinuity,
                    })}
                    suffix={get(item, "suffix", "%")}
                    allowNegative={false}
                    onValueChange={(values) => {
                      onChangeRotationValue(
                        index,
                        index2,
                        get(values, "floatValue", 0)
                      );
                    }}
                  />
                </div>
              );
            });
            const stationData = find(
              stations,
              (i) => i.station_key === row.station
            );
            return (
              <div
                key={index}
                className={classnames(bem.e("tr"), {
                  [bem.e("rotation-error")]: validationError[index],
                })}
              >
                <div className={classnames(bem.e("station-name"), bem.e("td"))}>
                  {stationData ? stationData.name : row.station}
                </div>
                {rowValues}
              </div>
            );
          })}
        </div>
      </div>
      {isError && (
        <div className="alert-danger">
          <FormattedMessage id="process > spot rotation percentages must add up to 100%" />
        </div>
      )}
    </div>
  );
}
RotationChart.defaultProps = {
  isView: false,
  isContinuity: false,
  isShowAdjust: false,
  onAdjustRotations: () => {},
  totalSpots: null,
  rotationChartValue: null,
};
RotationChart.propTypes = {
  isView: PropTypes.bool,
  isContinuity: PropTypes.bool,
  isShowAdjust: PropTypes.bool,
  onAdjustRotations: PropTypes.func,
  totalSpots: PropTypes.number,
  rotationChartValue: PropTypes.array,
};
