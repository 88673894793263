import React from "react";
import bn from "utils/bemnames";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import { map } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
const bem = bn.create("jock-console");
function EventItem(props) {
  const { item } = props;

  return (
    <div className={bem.e("event-item")}>
      <div className={bem.e("event-left")}>
        <div
          className={bem.e("event-image")}
          style={{ backgroundImage: `url(${item.image})` }}
        ></div>
        <div className={bem.e("event-content")}>
          <div className={bem.e("event-title")}>{item.title}</div>
          <div className={bem.e("event-subtitle")}>
            {moment(item.date).format("ddd, MMM DD, YYYY @ HH:mm A")}
          </div>
        </div>
      </div>
      <div className={bem.e("event-date")}>
        <span className={bem.e("event-date-text")}>
          {moment(item.date).format("DD")}
        </span>
        <span className={bem.e("event-month-text")}>
          {moment(item.date).format("MMM")}
        </span>
      </div>
    </div>
  );
}
const items = [
  {
    image: "https://picsum.photos/200/300",
    title: "SummerStage Anywhere",
    date: new Date(),
  },
  {
    image: "https://picsum.photos/200/300",
    title: "Clear the Shelters 2020",
    date: new Date(),
  },
  {
    image: "https://picsum.photos/200/300",
    title: "Station Event Title Here",
    date: new Date(),
  },
  {
    image: "https://picsum.photos/200/300",
    title: "Station Event Title Here",
    date: new Date(),
  },
  {
    image: "https://picsum.photos/200/300",
    title: "Station Event Title Here",
    date: new Date(),
  },
];
export default function EventCalendar(props) {
  const { isMultiView, widget } = props;
  return (
    <div
      className={classNames(bem.e("calendars"), bem.e("widget-detail-padding"))}
    >
      <div
        className={classNames(bem.e("calendars-head-title"), {
          [bem.e("head-title-multi-view")]: isMultiView,
        })}
        style={{
          backgroundColor: isMultiView ? widget.color : "",
        }}
      >
        {widget.name}
      </div>
      <div className={bem.e("calendars-events")}>
        {map([], (item, index) => (
          <EventItem key={index} item={item} />
        ))}
      </div>
    </div>
  );
}
EventCalendar.defaultProps = {
  isMultiView: false,
  widget: {},
};
EventCalendar.propsTypes = {
  isMultiView: PropTypes.bool,
  widget: PropTypes.object.isRequired,
};
