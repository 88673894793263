import * as React from "react";
import bn from "utils/bemnames";
import Spinner from "components/Spinner";
import { map, get } from "lodash";
import { Col, Row } from "reactstrap";
import { FormattedMessage } from "react-intl";
import Dropdown from "components/Dropdown";
import FulfillmentAutomationForm from "../FulfillmentAutomationForm";
import PreviewFulfillmentAutomationModal from "../PreviewFulfillmentAutomationModal";

const bem = bn.create("station-settings-form");

const FulfillmentAutomation = (props) => {
  const {
    loading,
    setShouldBlockNavigation,
    shouldBlockNavigation,
    intl,
    reminderDays,
    onUpdateReminderDays,
    stationSettingsData,
  } = props;
  const daysOptions = [3, 5, 7, 10, 14].map((item) => ({
    label: intl.formatMessage(
      { id: "station settings > {number} days" },
      {
        number: item,
      }
    ),
    value: item,
  }));
  return (
    <div className={bem.e("fulfillment-automation")}>
      <div className={bem.e("station-wrapper")}>
        <Row>
          <Col xs={7} className={bem.e("col-reset")}>
            <div className={bem.e("fulfillment-help-text")}>
              <FormattedMessage id="station settings > fulfillment automation help text" />
            </div>
          </Col>
          <Col className={bem.e("col-reset")}>
            <Dropdown
              label={
                <FormattedMessage id="station settings > days before reminders are sent" />
              }
              placeholder={intl.formatMessage({
                id: "station settings > placeholder select",
              })}
              name="days"
              onChange={(option) => {
                onUpdateReminderDays(option.value);
              }}
              containerClass={bem.e("select-days")}
              value={{
                value: reminderDays,
                label: intl.formatMessage(
                  { id: "station settings > {number} days" },
                  {
                    number: reminderDays,
                  }
                ),
              }}
              options={daysOptions}
              closeMenuOnSelect={true}
            />
          </Col>
        </Row>
      </div>
      <div className={bem.e("active-text")}>
        <FormattedMessage id="station settings > active" />
      </div>
      <div className={bem.e("fulfillment-list")}>
        {map(stationSettingsData, (item, index) => {
          const isOpen =
            get(props, "selectedFulfillmentAutomation.id") === get(item, "id");
          return (
            <FulfillmentAutomationForm
              key={index}
              type={item.type}
              isOpen={isOpen}
              currentStation={props.currentStation}
              toggleIsOpen={() => {
                props.setSelectedFulfillmentAutomation(isOpen ? null : item);
              }}
              fulfillmentSetting={item}
              stations={props.stations}
              setShouldBlockNavigation={setShouldBlockNavigation}
              shouldBlockNavigation={shouldBlockNavigation}
              isOpenConfirmationModal={props.isOpenConfirmationModal}
              setIsOpenConfirmationModal={props.setIsOpenConfirmationModal}
              onClickPreview={(data) => {
                props.fetchFulfillmentSetting(data, () => {
                  props.setIsOpenPreview(true);
                });
              }}
              reminderDays={reminderDays}
              onGetFulfillmentSettings={props.onGetFulfillmentSettings}
            />
          );
        })}
      </div>
      <PreviewFulfillmentAutomationModal
        isOpen={props.isOpenPreview}
        fulfillmentSetting={props.fulfillmentSetting}
        onToggle={() => props.setIsOpenPreview(null)}
        currentServer={props.currentServer}
        currentStation={props.currentStation}
      />
      <Spinner isLoading={loading} />
    </div>
  );
};
export default FulfillmentAutomation;
