import React from "react";
import { FaSortDesc, FaSortAsc, FaPlus } from "react-icons/lib/fa";
import classnames from "classnames";
import { get, find } from "lodash";
import { Button } from "reactstrap";
import { FormattedMessage } from "react-intl";
import Table from "components/Table";
import bn from "utils/bemnames";
import HubSidebar from "../HubSidebar";
import ContentAssignUsers from "../ContentAssignUsers";
import Tooltip from "rc-tooltip";
import Checkbox from "components/Checkbox";
import ConfirmationModal from "components/ConfirmationModal";
import { ApproveIcon } from "components/CustomIcons";
import PropTypes from "prop-types";

const bem = bn.create("hub-manager-details");

const HubManagerMembers = (props) => {
  const {
    sorted,
    setSorted,
    onGetHubAssignedUsers,
    assignedUsers,
    selectedHub,
    intl,
    isOpenSidebar,
    setIsOpenSidebar,
    setSidebarType,
    sidebarType,
    isAllowEditUser,
    isAllowEditOrderAssignment,
    selectedHubManager,
    assignmentsCount,
  } = props;
  let columns = [];
  const sortedName = find(sorted, (item) => item.id === "name");
  columns.push({
    Header: (
      <div className={classnames(bem.e("th-name"), bem.e("th-sort"))}>
        <FormattedMessage id="hub manager > name" />
        <div className={bem.e("sortable")}>
          <FaSortAsc
            className={classnames(bem.e("sortable-icon"), {
              [bem.e("sortable-active")]: sortedName && !sortedName.desc,
            })}
          />
          <FaSortDesc
            className={classnames(bem.e("sortable-icon"), {
              [bem.e("sortable-active")]: sortedName && sortedName.desc,
            })}
          />
        </div>
      </div>
    ),
    accessor: "name",
    Cell: ({ original }) => {
      const array = [];
      if (original.first_name) {
        array.push(original.first_name);
      }
      if (original.last_name) {
        array.push(original.last_name);
      }
      const name = array.join(" ");
      return <div className={bem.e("name-column")}>{name}</div>;
    },
  });
  const sortedRoles = find(sorted, (item) => item.id === "hub_roles_string");
  columns.push({
    Header: (
      <div className={bem.e("th-sort")}>
        <FormattedMessage id="hub manager > hub role(s)" />
        <div className={bem.e("sortable")}>
          <FaSortAsc
            className={classnames(bem.e("sortable-icon"), {
              [bem.e("sortable-active")]: sortedRoles && !sortedRoles.desc,
            })}
          />
          <FaSortDesc
            className={classnames(bem.e("sortable-icon"), {
              [bem.e("sortable-active")]: sortedRoles && sortedRoles.desc,
            })}
          />
        </div>
      </div>
    ),
    accessor: "hub_roles_string",
    Cell: ({ original }) => {
      return (
        <span className={classnames(bem.e("td-value"), bem.e("market-value"))}>
          {original.hub_roles
            ? original.hub_roles.map((role) => (
                <span className={bem.e("role-name")}>
                  {role.name.charAt(0)}
                </span>
              ))
            : null}
        </span>
      );
    },
  });
  const sortedOtherHubs = find(sorted, (item) => item.id === "other_hubs");

  columns.push({
    Header: (
      <div className={bem.e("th-sort")}>
        <FormattedMessage id="hub manager > other market hub" />
        <div className={bem.e("sortable")}>
          <FaSortAsc
            className={classnames(bem.e("sortable-icon"), {
              [bem.e("sortable-active")]:
                sortedOtherHubs && !sortedOtherHubs.desc,
            })}
          />
          <FaSortDesc
            className={classnames(bem.e("sortable-icon"), {
              [bem.e("sortable-active")]:
                sortedOtherHubs && sortedOtherHubs.desc,
            })}
          />
        </div>
      </div>
    ),
    accessor: "other_hubs",
    Cell: ({ original }) => {
      const otherHubs = original.other_hubs;
      return (
        <span className={classnames(bem.e("td-value"))}>
          {otherHubs ? (
            <Tooltip
              placement="topLeft"
              trigger={["click", "hover"]}
              overlayClassName={bem.e("tooltip")}
              overlay={
                <div className={"other-market-hub-value"}>{otherHubs}</div>
              }
            >
              <div className={"other-market-hub-value"}>{otherHubs}</div>
            </Tooltip>
          ) : null}
        </span>
      );
    },
  });

  columns.push({
    Header: (
      <div className={bem.e("th-center")}>
        <FormattedMessage id="hub manager > order assignments" />
      </div>
    ),
    sortable: false,
    accessor: "order_assignments",
    style: { justifyContent: "center", textAlign: "center" },
    Cell: ({ original }) => {
      if (!isAllowEditOrderAssignment) {
        return (
          <div className={bem.e("order-approved")}>
            <ApproveIcon color="#73A703" />
          </div>
        );
      }
      return (
        <div className={bem.e("checkbox-column")}>
          <Checkbox
            checked={original.order_assignments}
            onChange={() => {
              props.onUpdateOrderAssignments({
                member_id: original.id,
                hub_id: selectedHub.id,
                order_assignments: !original.order_assignments,
              });
            }}
            text={intl.formatMessage({ id: "hub manager > order assignments" })}
          />
        </div>
      );
    },
  });
  const totalAssigned = get(assignedUsers, "length", 0);
  return (
    <div className={bem.b()}>
      <div className={bem.e("header")}>
        <div className={bem.e("hub-name")}>
          {selectedHub.name} ({totalAssigned})
        </div>
        {isAllowEditUser ? (
          <Button
            outline
            color="blue"
            className={classnames("btn-radius", bem.e("btn-stations"))}
            onClick={() => {
              setSidebarType("hub-members");
              setIsOpenSidebar(true);
            }}
          >
            <FaPlus color="#795AFA" size={20} />
            <FormattedMessage id="hub manager > edit users" />
          </Button>
        ) : null}
      </div>
      {assignmentsCount ? (
        <div className={bem.e("assignments-summary")}>
          <div className={bem.e("assignments-summary-text")}>
            <FormattedMessage id="hub manager > assignments" />:
          </div>
          <div className={bem.e("role-summary")}>
            {Object.keys(assignmentsCount).map((role) => (
              <div className={bem.e("role-summary-item")}>
                <span>{role}:</span>
                <strong>{assignmentsCount[role]}</strong>
              </div>
            ))}
            {selectedHubManager ? (
              <div className={bem.e("role-summary-item")}>
                <span>
                  {intl.formatMessage({
                    id: `hub manager > hub manager`,
                  })}
                  :
                </span>
                <strong>
                  {selectedHubManager
                    .map((item) => `${item.first_name} ${item.last_name}`)
                    .join(", ")}
                </strong>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}

      <Table
        className={bem.e("table")}
        manual
        data={assignedUsers}
        showPagination={false}
        total={totalAssigned}
        sorted={sorted}
        // Callbacks
        onSortedChange={(sorted) => {
          setSorted(sorted);
          onGetHubAssignedUsers({ sorted });
        }}
        columns={columns}
      />
      <HubSidebar
        isOpen={isOpenSidebar}
        onCancel={() => setIsOpenSidebar(false)}
        selectedHub={selectedHub}
        type={sidebarType}
        isShowSubtitle={true}
        children={
          <ContentAssignUsers
            onCancel={() => setIsOpenSidebar(false)}
            selectedHub={selectedHub}
          />
        }
      />
      <ConfirmationModal
        isOpen={props.isOpenSuccessModal}
        state="success"
        title={<FormattedMessage id="hub manager > saved successfully" />}
        onToggle={() => props.setIsOpenSuccessModal(!props.isOpenSuccessModal)}
        onCancel={() => props.setIsOpenSuccessModal(!props.isOpenSuccessModal)}
      />
    </div>
  );
};
HubManagerMembers.propTypes = {
  isAllowEditUser: PropTypes.bool,
  isAllowEditOrderAssignment: PropTypes.bool,
  selectedHub: PropTypes.object.isRequired,
  selectedHubManager: PropTypes.object,
};
HubManagerMembers.defaultProps = {
  isAllowEditUser: true,
  isAllowEditOrderAssignment: true,
  selectedHubManager: null,
};
export default HubManagerMembers;
