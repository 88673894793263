import Api from "utils/api";
import {
  PUSHER,
  PROCESS_REPLY_MESSAGE,
  GET_PROCESS,
  GET_PUBLIC_PROCESS,
} from "utils/constants";
import ToastManager from "components/ToastManager";
import { get, isFunction, includes, omit } from "lodash";
import * as Const from "utils/constants";
import * as TypesMessages from "store/types/messages";
import { resolveTaggedUsers, labelToNameField } from "utils/helpers";
import {
  UPDATE_PROCESS_INCOMING,
  FORGET_PROCESS,
  REMOVE_PROCESS_FROM_LIST,
  SET_DIRTY_PROCESS_INCOMING_SUCCESS,
  UNARCHIVE_PROCESS_FROM_PUSHER,
  ARCHIVED_PROCESS_FROM_PUSHER,
} from "store/types/processes";
import queryString from "query-string";
import { userInfo } from "./auth";
import { getServer, getServers } from "./servers";
import { FORGET_WORKFLOWS } from "store/types/workflows";

import { FORGET_CHANNELS } from "store/types/channels";
import { FORGET_AUDITS } from "store/types/audits";
import { FORGET_SYSTEM_TEMPLATES_RELATED_FORMS } from "store/types/systemTemplates";
import { FORGET_FULFILLMENT_FORM_TEMPLATE } from "store/types/systemForms";

import { pageSize } from "utils/config";
import { getProcessInComing, getProcessesByIds } from "store/actions/processes";
import { validatePrivileges } from "utils/helpers";
import { PRIVILEGES } from "utils/constants";
import history from "components/History";

export const replyMessage = (payload, cb) => async () => {
  try {
    const response = await Api.doCall(
      PROCESS_REPLY_MESSAGE(get(payload, "message.process_key")),
      "POST",
      {
        message: {
          ...get(payload, "message"),
          chat: payload.chat,
          m_type: "chat",
        },
      }
    );
    if (isFunction(cb)) {
      cb(response);
    }
  } catch (error) {
    return false;
  }
};

export const sendMessage = (payload) => async () => {
  try {
    const { status } = await Api.doCall(
      PUSHER() + "?payload=" + JSON.stringify(payload),
      "GET"
    );
    if (status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
export const getNotifications = (params = {}) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({
      type: TypesMessages.FETCH_NOTIFICATIONS_REQUEST,
    });
    const response = await Api.doCall(
      `${Const.GET_NOTIFICATIONS()}?${queryString.stringify(params)}`,
      "GET"
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: TypesMessages.FETCH_NOTIFICATIONS_SUCCESS,
          payload: response.data,
        });
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: TypesMessages.FETCH_NOTIFICATIONS_ERROR,
    payload: { error: errorStr },
  });
};

export const editMessage = (params = {}) => async (dispatch) => {
  let errorStr = "";
  try {
    const { id } = params;
    dispatch({
      type: TypesMessages.EDIT_MESSAGE_REQUEST,
    });
    const response = await Api.doCall(Const.EDIT_MESSAGE(id), "POST", params);
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: TypesMessages.EDIT_MESSAGE_SUCCESS,
          payload: params,
        });
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: TypesMessages.EDIT_MESSAGE_ERROR,
    payload: { error: errorStr },
  });
};

export const deleteMessage = (params = {}) => async (dispatch) => {
  let errorStr = "";
  try {
    const { id } = params;
    dispatch({
      type: TypesMessages.DELETE_MESSAGE_REQUEST,
    });
    const response = await Api.doCall(
      Const.DELETE_MESSAGE(id),
      "DELETE",
      params
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: TypesMessages.DELETE_MESSAGE_SUCCESS,
          payload: { id },
        });
        dispatch(getNotifications());
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: TypesMessages.DELETE_MESSAGE_ERROR,
    payload: { error: errorStr },
  });
};

export const deleteMessages = (params = [], cb) => async (dispatch) => {
  let errorStr = "";
  try {
    const ids = params.map((item) => item._id);
    dispatch({
      type: TypesMessages.DELETE_MESSAGES_REQUEST,
    });
    const response = await Api.doCall(Const.DELETE_MESSAGES(), "POST", {
      ids,
    });
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: TypesMessages.DELETE_MESSAGES_SUCCESS,
          payload: { ids },
        });
        if (isFunction(cb)) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: TypesMessages.DELETE_MESSAGES_ERROR,
    payload: { error: errorStr },
  });
};

export const markAsSeenNotifications = (params = {}) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({
      type: TypesMessages.MARK_MESSAGES_AS_READ_REQUEST,
    });
    const response = await Api.doCall(
      Const.MARK_SEEN_NOTIFICATIONS(),
      "POST",
      params
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: TypesMessages.MARK_MESSAGES_AS_READ_SUCCESS,
          payload: { data: response.data },
        });
        dispatch(getNotifications());
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: TypesMessages.MARK_MESSAGES_AS_READ_ERROR,
    payload: { error: errorStr },
  });
};

export const markAsSeenMessages = (id, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({
      type: TypesMessages.MARK_PROCESS_MESSAGES_AS_READ_REQUEST,
    });
    const response = await Api.doCall(
      Const.MARK_SEEN_PROCESS_MESSAGES(id),
      "POST",
      {}
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: TypesMessages.MARK_PROCESS_MESSAGES_AS_READ_SUCCESS,
        });
        if (cb) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: TypesMessages.MARK_PROCESS_MESSAGES_AS_READ_ERROR,
    payload: { error: errorStr },
  });
};

export const dismissNotifications = (payload) => (dispatch) => {
  let { process_id } = payload;
  dispatch(
    markAsSeenNotifications({
      by_process_key: process_id,
    })
  );
};

export const getMessagesByProcess = (id, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({
      type: TypesMessages.GET_MESSAGES_BY_PROCESS_REQUEST,
    });
    const response = await Api.doCall(Const.GET_MESSAGES_BY_PROCESS(id), "GET");
    if (response.status === 200 && response.data) {
      const messages = response.data.map((m) => ({
        message: m._source,
        m_id: m._id,
      }));
      dispatch({
        type: TypesMessages.GET_MESSAGES_BY_PROCESS_SUCCESS,
        payload: messages,
      });
      if (cb) cb();
      return;
    }
    errorStr = response.data.error;
  } catch (error) {
    errorStr = error.toString();
  }
  if (cb) cb();
  dispatch({
    type: TypesMessages.GET_MESSAGES_BY_PROCESS_ERROR,
    payload: { error: errorStr },
  });
};
let showNotification = (auth, title, message, avatar) => {
  if (auth.notify) {
    const options = {
      body: message,
      icon: avatar || "",
      vibrate: [50, 100, 150],
    };

    const notification = new Notification(title, options);
    setTimeout(() => {
      notification.close();
    }, 6000);
    // click on notification
    const anchor = "notification";
    const onClickNotification = () => async (anchor) => {
      // debugger
    };

    notification.onclick = () => onClickNotification(anchor);
  } else {
    ToastManager.show({
      title,
      message,
      level: "info",
    });
  }
};

var last_message_id = 0;
var audit_refresh_delay_timer = false;
export const incomingMessage = (payload, auth, messages, processes) => async (
  dispatch
) => {
  const activeFilter = get(processes, "activeFilter", "");
  const privileges = get(auth, "user.privileges", []) || [];
  const user = get(auth, "user");
  // every sync message causes audit refresh
  // if in auto refresh mode
  // but not more frequent than once in 5 seconds
  console.log("==================");
  console.log("incoming message", payload);
  console.log("==================");
  const refreshDelay = get(payload, "message.refresh_delay", 0);

  if (audit_refresh_delay_timer) clearTimeout(audit_refresh_delay_timer);
  audit_refresh_delay_timer = setTimeout(() => {
    audit_refresh_delay_timer = false;
    dispatch({
      type: FORGET_AUDITS,
    });
  }, 5000);

  if (payload.message.m_type === "workflow") {
    dispatch({
      type: FORGET_WORKFLOWS,
    });
    return;
  }
  const isCanFetchChannels = validatePrivileges({
    requires: {
      or: [PRIVILEGES.EDIT_CHANNELS, PRIVILEGES.PUBLISH_CHANNELS],
    },
    privileges,
    user,
  });
  if (payload.message.m_type === "channel" && isCanFetchChannels) {
    dispatch({
      type: FORGET_CHANNELS,
    });
    return;
  }

  if (payload.message.m_type === "process_editing") {
    // when other user is editing a share order -> will refresh this order. https://tasks.getventive.com/projects/9FF98-4F9?bug=54803-B24E
    dispatch({
      type: SET_DIRTY_PROCESS_INCOMING_SUCCESS,
      payload: {
        process_key: payload.message.process_key,
      },
    });
    return;
  }

  if (payload.message.m_type === "system_template") {
    dispatch({
      type: FORGET_SYSTEM_TEMPLATES_RELATED_FORMS,
    });
    return;
  }

  if (payload.message.m_type === "form") {
    dispatch({
      type: FORGET_FULFILLMENT_FORM_TEMPLATE,
    });
    return;
  }

  payload.dismissable =
    payload.message.needs_confirmation &&
    payload.message.needs_confirmation.indexOf(auth.user.id) > -1;

  const { process } = processes;
  // if (
  //   last_message_id === payload.m_id ||
  //   messages.find((m) => m.m_id === payload.m_id)
  // )
  //   return;

  last_message_id = payload.m_id;

  if (payload.message.m_type === "info") {
    dispatch(userInfo());
    if (get(auth, "user.is_god_admin", false)) {
      dispatch(
        getServers(
          {
            page: 0,
            pageSize: pageSize,
          },
          true
        )
      );
    } else if (get(auth, "user.broadcasting_group_admin", false)) {
      dispatch(getServer(get(auth, "user.server")));
    }
    if (payload.message.process_key) {
      dispatch({
        type: SET_DIRTY_PROCESS_INCOMING_SUCCESS,
        payload: {
          process_key: payload.message.process_key,
        },
      });
    }

    return;
  }

  let add_notification = false;
  let is_fetch_info = false;

  // refresh process.
  let chat_from_me = false;
  if (payload.message.m_type === "chat") {
    if (
      get(payload, "message.chat", "").indexOf(
        `@[${auth.user.name}](${auth.user.id})`
      ) > -1 ||
      (payload.message.tagged || []).indexOf(auth.user.id) > -1
    ) {
      add_notification = true;
      const title = get(payload, "message.from.name", "") + " mentioned you";
      const message =
        "in order " +
        payload.message.order_title +
        ": \r\n" +
        resolveTaggedUsers(get(payload, "message.chat", ""), auth.info.users);
      showNotification(
        auth,
        title,
        message,
        get(payload, "message.from.avatar_url", "")
      );
    }
    if (get(payload, "message.from.id", "") === auth.user.id) {
      chat_from_me = true;
    }
  }

  if (
    payload.message.m_type === "jc_tasks" &&
    includes(get(payload, "message.notification_users", []), auth.user.id)
  ) {
    const title = payload.message.notification_title;
    const message = payload.message.message_string;

    showNotification(auth, title, message, null);
  }

  if (includes(get(payload, "message.notification_users", []), auth.user.id)) {
    add_notification = true;
  }

  if (
    get(process, "key") === get(payload, "message.process_key") ||
    includes(get(payload, "message.spots", []), get(process, "key"))
  ) {
    getProcessDelta({
      process,
      payload,
      chat_from_me,
      auth,
      dispatch,
      activeFilter,
    });
  }

  if (payload.message.m_type === "proc") {
    // reload user info
    is_fetch_info = true;
    if (
      payload.message.next_users &&
      payload.message.next_users.indexOf(auth.user.id) > -1
    ) {
      // me now

      // disabled for testing
      if (get(payload, "message.from.id", "") !== auth.user.id) {
        add_notification = true;
        const title = "Order updated";
        const message =
          "Order " +
          payload.message.order_title +
          (payload.message.to_step
            ? ", now in status '" +
              payload.message.to_step.replace(/_/, " ", "gi") +
              "'"
            : "");
        showNotification(
          auth,
          title,
          message,
          get(payload, "message.from.avatar_url", "")
        );
      }
    }
    if (
      payload.message.still_required_users &&
      payload.message.still_required_users.indexOf(auth.user.id) > -1
    ) {
      // I'm soon
      add_notification = true;
    }
  }

  if (
    [
      "wo_update",
      "trafficked",
      "process_destroy",
      "process_unarchived",
      "process_archived",
    ].includes(payload.message.m_type)
  ) {
    is_fetch_info = true;
  }

  if (
    payload.message.m_type === "wo_new_order" ||
    payload.message.m_type === "wo_order_update"
  ) {
    if (get(auth, "user.id") === get(payload, "message.to.id", "")) {
      if (payload.message.m_type === "wo_new_order") {
        add_notification = true;
        const title = "Wide Orbit";
        const message = "A new order has been synced from Wide Orbit \r\n";

        showNotification(auth, title, message);
      }
      is_fetch_info = true;
    }
  }

  if (payload.message.m_type === "sent_to_client") {
    if (includes(get(payload, "message.to", []), get(auth, "user.id"))) {
      add_notification = true;
      const title = "Order updated";
      const message = `${get(payload, "message.from.name", "")} ${
        payload.message.text
      } for ${payload.message.order_title}`;

      showNotification(
        auth,
        title,
        message,
        get(payload, "message.from.avatar_url", "")
      );
      is_fetch_info = true;
    }
  }

  if (payload.message.m_type === "unlocked_process") {
    const unlockedOrder = payload.message.unlocked_order || [];
    if (unlockedOrder && unlockedOrder.length > 0) {
      dispatch(getProcessesByIds(unlockedOrder));
    }
  }
  if (payload.message.m_type === "process_unarchived") {
    dispatch({
      type: UNARCHIVE_PROCESS_FROM_PUSHER,
      payload: payload.message.process_key,
    });
  }
  if (payload.message.m_type === "process_archived") {
    dispatch({
      type: ARCHIVED_PROCESS_FROM_PUSHER,
      payload: payload.message.process_key,
    });
  }
  // update process data in the list
  if (
    payload.message.process_key &&
    !["process_unarchived", "process_archived"].includes(payload.message.m_type)
  ) {
    dispatch({
      type: SET_DIRTY_PROCESS_INCOMING_SUCCESS,
      payload: {
        process_key: payload.message.process_key,
      },
    });
  }

  // remove process in the list
  if (payload.message.m_type === "process_destroy") {
    dispatch({
      type: REMOVE_PROCESS_FROM_LIST,
      payload: {
        process_key: payload.message.process_key,
      },
    });
    is_fetch_info = true;
  }
  if (add_notification || payload.message.m_type === "process_destroy") {
    dispatch(getNotifications());
  }
  if (is_fetch_info) {
    setTimeout(function() {
      dispatch(userInfo());
    }, refreshDelay);
  }
  dispatch({
    type: TypesMessages.SET_MESSAGES,
    payload: { messages: [payload] },
  });
};

async function getProcessDelta({
  process,
  payload,
  chat_from_me,
  auth,
  dispatch,
  activeFilter,
}) {
  let update_open_process = false;
  let is_forget_processes = false;
  try {
    let response = await Api.doCall(
      `${
        auth.user.id && auth.user.id !== -1
          ? GET_PROCESS(get(process, "key"))
          : GET_PUBLIC_PROCESS(get(process, "key"))
      }/delta`,
      "GET"
    );
    if (response.status === 200) {
      if (!response.data.error) {
        const responseData = response.data;
        // update incoming messages
        if (!chat_from_me) {
          const messages = get(responseData, "messages", []).map((message) => ({
            m_id: message._id,
            message: message._source,
          }));
          dispatch({
            type: TypesMessages.UPDATE_MESSAGES_INCOMING,
            payload: messages,
          });
        }

        if (
          !chat_from_me &&
          (get(process, "key") === get(responseData, "key") ||
            includes(get(responseData, "spots", []), get(process, "key")))
        ) {
          update_open_process = true;
        }
        // update related spots process;
        if (
          includes(get(responseData, "spots", []), get(process, "key")) &&
          (payload.message.m_type === "proc" ||
            payload.message.m_type === "chat" ||
            payload.message.m_type === "wo_update")
        ) {
          update_open_process = true;
        }

        if (payload.message.m_type === "process_destroy") {
          is_forget_processes = true;
          update_open_process = false;
        }

        if (update_open_process) {
          // spread channel field values as sub objects
          if (
            responseData.template_key === "digital" &&
            responseData.marketing_channels
          ) {
            responseData.marketing_channels.map((channel) => {
              if (channel.fields) {
                channel.fields.map((channel_field) => {
                  let field_name = labelToNameField(channel_field.label);
                  if (!channel[field_name]) {
                    if (channel_field.value_arr)
                      channel[field_name] = channel_field.value_arr;
                    else if (channel_field.value_obj)
                      channel[field_name] = channel_field.value_obj;
                    else if (channel_field.value_bool)
                      channel[field_name] = channel_field.value_bool;
                    else if (channel_field.value_num)
                      channel[field_name] = channel_field.value_num;
                    else if (channel_field.value_str)
                      channel[field_name] = channel_field.value_str;
                    else channel[field_name] = channel_field.value; // backward compability
                  }
                });
              }
            });
          }
          let fields = {};
          Object.keys(omit(responseData, "order_viewed")).map(
            (key) => (fields[key] = { value: responseData[key] })
          );
          dispatch({
            type: UPDATE_PROCESS_INCOMING,
            payload: {
              data: fields,
            },
          });
          // If any user takes over an order -> then on another user is editing this order will redirect to the list.
          const userId = get(auth, "user.id");
          const teamAssignmentsBeingDoneBy = get(
            fields,
            "team_assignments_being_done_by.value"
          );
          if (
            get(teamAssignmentsBeingDoneBy, "id") &&
            Number(get(teamAssignmentsBeingDoneBy, "id")) !== Number(userId)
          ) {
            history.push(`/processes/${activeFilter || "my_action_items"}`);
          }
        }
        if (is_forget_processes) {
          dispatch({
            type: FORGET_PROCESS,
            payload,
          });
        }
      }
      // debugger;
    }
  } catch (error) {}
}
