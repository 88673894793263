import { get, omit, isFunction } from "lodash";

import history from "../../components/History";
import * as Types from "../types/audits";
import * as Const from "../../utils/constants";
import Api from "../../utils/api";
import URL from "../../utils/urls";

export function formValueChanged(payload) {
  return {
    type: Types.AUDIT_VALUE_CHANGED,
    payload
  };
}

export const initNewAudit = payload => dispatch => {
  dispatch({
    type: Types.INIT_NEW_AUDIT,
    payload
  });
};

export const setActiveTab = payload => dispatch => {
  dispatch({
    type: Types.SET_ACTIVE_TAB,
    payload
  });
};

export const setActiveEditTab = payload => dispatch => {
  dispatch({
    type: Types.SET_TAB_TO_EDIT,
    payload
  });
};
//get all audits
export const getAudit = id => async dispatch => {
  let errorStr = "";
  try {
    dispatch({ type: Types.FETCH_AUDIT_REQUEST, payload: { id } });
    let response = await Api.doCall(Const.GET_AUDIT(id), "GET");
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch(fetchAuditsSucces({ id, data: response.data }));
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch(fetchAuditsError({ id, error: errorStr }));
};

//save audits values to ES
export const saveFormValues = (data, success_callback) => async dispatch => {
  let errorStr = "";
  try {
    dispatch({ type: Types.ADD_AUDIT_REQUEST });
    //api call for store data
    let response = await Api.doCall(Const.ADD_AUDITS(), "POST", data);
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        //response data
        let responseData = JSON.parse(JSON.stringify(response.data));
        dispatch({ type: Types.ADD_AUDIT_SUCCESS, payload: responseData });
        // refresh audits when adding new
        dispatch(forgetAudits());

        if (isFunction(success_callback)) {
          success_callback();
        } else {
          //redirect to list page
          history.push(URL.AUDITS({ filter: "audits_list" }));
        }
        return;
      }
      //error data
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  //return error
  dispatch({ type: Types.ADD_AUDIT_ERROR, payload: errorStr });
};

//update audits values to ES
export const updateAudits = (id, data, cb) => async dispatch => {
  let errorStr = "";
  try {
    dispatch({ type: Types.UPDATE_AUDIT_REQUEST });
    //api call for store data
    let response = await Api.doCall(Const.GET_AUDIT(id), "PUT", data);
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        //response data
        let responseData = JSON.parse(JSON.stringify(response.data));
        dispatch({
          type: Types.UPDATE_AUDIT_SUCCESS,
          payload: responseData
        });
        // refresh audits when adding new
        dispatch(forgetAudits());
        history.push(URL.AUDITS({ filter: "audits_list" }));
        if (isFunction(cb)) cb();
        return;
      }
      //error data
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  //return error
  dispatch({ type: Types.UPDATE_AUDIT_ERROR, payload: errorStr });
};

export function saveFormValuesSuccess(payload) {
  return {
    type: Types.SAVE_FORM_VALUES_SUCCESS,
    payload
  };
}

export function forgetAudits(payload) {
  return {
    type: Types.FORGET_AUDITS,
    payload
  };
}

export const setAuditsAutoRefresh = state => async dispatch => {
  dispatch( {
    type: Types.SET_AUDITS_AUTO_REFRESH,
    payload : { state }
  });
}
export function saveFormValuesError(payload) {
  return {
    type: Types.SAVE_FORM_VALUES_ERROR,
    payload
  };
}

export function fetchAuditSuccess(payload) {
  return {
    type: Types.FETCH_AUDIT_SUCCESS,
    payload
  };
}

export function fetchAuditError(payload) {
  return {
    type: Types.FETCH_AUDIT_ERROR,
    payload
  };
}

export const deleteAudit = id => async dispatch => {
  let errorStr = "";
  try {
    dispatch({ type: Types.DELETE_AUDIT_REQUEST });
    let response = await Api.doCall(Const.GET_AUDIT(id), "DELETE");
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({ type: Types.DELETE_AUDIT_SUCCESS, payload: id });
        history.push(URL.AUDITS({ filter: "audits_list" }));
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.DELETE_AUDIT_ERROR, payload: errorStr });
};

export const getAuditsAll = state => async dispatch => {
  let errorStr = "";
  try {
    dispatch({ type: Types.FETCH_AUDIT_REQUEST });
    let response = await Api.doCall(`${Const.GET_AUDITS_ALL()}`, "GET");
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch(fetchAuditsSucces(response.data));
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch(fetchAuditsError({ data: errorStr }));
};

export const getAudits = payload => async dispatch => {
  let errorStr = "";
  try {
    dispatch({ type: Types.FETCH_AUDITS_REQUEST, payload });
    const url =
      Const.GET_AUDITS(payload.filter) +
      "?sort=" +
      payload.sort +
      "&page=" +
      payload.page +
      "&rpp=" +
      payload.rpp +
      "&search=" +
      payload.search +
      "&category=" +
      get(payload, "category", "") +
      "&stations=" +
      get(payload, "stations", "");
    let response = await Api.doCall(url, "GET");
    if (response.status === 200) {
      if (!response.data.error) {
        dispatch(
          fetchAuditsSucces({
            ...omit(payload, ["page"]),
            page: get(response, "data.page", 1),
            pages: get(response, "data.pages", 1),
            data: get(response, "data.items", [])
          })
        );
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch(fetchAuditsError({ filter: payload.filter, error: errorStr }));
};

export function fetchAuditsSucces(payload) {
  return {
    type: Types.FETCH_AUDITS_SUCCESS,
    payload
  };
}

export function fetchAuditsError(payload) {
  return {
    type: Types.FETCH_AUDITS_ERROR,
    payload
  };
}

export function resetTab() {
  return {
    type: Types.RESET_TAB
  };
}
