import React from "react";
import Page from "components/Page";
import PrivilegedComponent from "components/PrivilegedComponent";
import ClientManagerForm from "components/ClientManagerForm";
// import CommentsSidebar from "components/CommentsSidebar";
import ClientManagerHeader from "components/ClientManagerList/ClientManagerHeader";
import { Redirect } from "react-router";
import { PRIVILEGES } from "utils/constants";
import bn from "utils/bemnames";
const bem = bn.create("add-update-client-manager");

const AddUpdateClientManagerPage = (props) => {
  const { user, client, history } = props;
  const isEdit = document.location.pathname.split("/")[2] === "edit";
  const requiredPrivileges = [];
  if (isEdit) {
    requiredPrivileges.push(PRIVILEGES.EDIT_CLIENT);
  }
  return (
    <PrivilegedComponent requires={requiredPrivileges}>
      {({ isValid }) => {
        if (isValid) {
          return (
            <Page title="" applyPadding={false}>
              <div className={bem.b()}>
                <ClientManagerHeader isShowSearch={false} />
                <div className={bem.e("container-padding")}>
                  <div className={bem.e("content")}>
                    <ClientManagerForm {...props} />
                    {/* Hidden comments section as per requirements */}
                    {/* https://tasks.getventive.com/projects/50DB9-A3B */}
                    {/* <CommentsSidebar
                      isSidebarOpen={props.isSidebarOpen}
                      defaultIsCollapse={true}
                      isStatic={false}
                      current_step={null}
                      instance={client}
                      template={null}
                      user={user}
                      onSubmit={props.onSendMessage}
                    /> */}
                  </div>
                </div>
              </div>
            </Page>
          );
        } else {
          const viewModePath = history.location.pathname.replace('/edit', '/view');
          if (viewModePath) {
            return history.replace(viewModePath);
          } else {
            return <Redirect to="/" />;
          }
        }
      }}
    </PrivilegedComponent>
  );
};

export default AddUpdateClientManagerPage;
