import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import bn from "utils/bemnames";
import { Row, Col, Label, Button } from "reactstrap";
import CustomMultiSelectDropdown from "components/CustomMultiSelectDropdown";
import { filter, get, find, map, includes } from "lodash";
import TitleTooltip from "components/TitleTooltip";
import { CloseSmallIcon, PlusSmallIcon } from "components/CustomIcons";
import classnames from "classnames";
import AudiencePickerModal from "components/AudiencePickerModal";
const bem = bn.create("station-settings-form");

const CopyStations = (props) => {
  const [isAudiencePickerModalOpen, setIsAudiencePickerModalOpen] = useState(
    false
  );
  const {
    intl,
    formProps,
    stations,
    currentServer,
    label,
    currentStation,
  } = props;
  const { values, errors, touched } = formProps;
  const isShowAudience =
    (currentServer.digital_multimarket && currentServer.enable_digital) ||
    (currentServer.production_multimarket && currentServer.is_production) ||
    (currentServer.promotion_multimarket && currentServer.is_promotion);
  const stationOptions = map(stations, (station) => ({
    label: (
      <div className="value-container">
        {station.call_letters && (
          <div className="label">
            <TitleTooltip title={station.call_letters} />
          </div>
        )}
        {station.name && (
          <div className="type">
            <TitleTooltip title={station.name} />
          </div>
        )}
      </div>
    ),
    value: station.key,
  }));
  const selectedStations = stations
    .filter((item) => {
      return includes(values.settings_copy_to_stations, item.key);
    })
    .map((item) => ({
      label: item.call_letters,
      value: item.key,
    }));
  const onSelectedAudience = (audienceGroup, addedStations, selectedTags) => {
    const stations = audienceGroup
      ? get(audienceGroup, "selected_stations", [])
      : addedStations;
    const selectedAudience = map(stations, ({ key, station_key, market }) => {
      return {
        key,
        station_key,
      };
    });
    const selectedStations = selectedAudience.map(
      (station) => station.station_key
    );
    formProps.setFieldValue("settings_copy_to_stations", selectedStations);
  };
  return (
    <div className={bem.e("copy-stations")}>
      <div className={bem.e("form-padding")}>
        <div className={bem.e("copy-stations-title")}>
          {label || (
            <FormattedMessage id="station settings > select stations to copy these selections to" />
          )}
        </div>
        <Row>
          <Col xs={4} className={bem.e("col-reset")}>
            {!isShowAudience ? (
              <div>
                <Label>
                  <FormattedMessage id="station settings > station" />
                </Label>
                <CustomMultiSelectDropdown
                  name="settings_copy_to_stations"
                  value={selectedStations}
                  options={stationOptions}
                  placeholder={intl.formatMessage({
                    id: "station settings > please select",
                  })}
                  isAllowAllOption={false}
                  isMulti={true}
                  error={
                    get(touched, "settings_copy_to_stations") &&
                    get(errors, "settings_copy_to_stations")
                  }
                  onChange={(value) => {
                    const newValue = filter(value, (item) =>
                      find(stationOptions, (i) => i.value === item.value)
                    ).map((item) => item.value);
                    formProps.setFieldValue(
                      "settings_copy_to_stations",
                      newValue
                    );
                    if (props.setShouldBlockNavigation) {
                      props.setShouldBlockNavigation(true);
                    }
                  }}
                  multiText={intl.formatMessage({
                    id: "station settings > multiple",
                  })}
                  exceptOptions={currentStation ? [currentStation.value] : []}
                />
              </div>
            ) : (
              <Button
                className={classnames(bem.e("button-audience"), "btn-radius")}
                outline
                color="blue"
                onClick={() => setIsAudiencePickerModalOpen(true)}
              >
                <span className={bem.e("add-icon")}>
                  <PlusSmallIcon />
                </span>
                <span className="button-text">
                  <FormattedMessage id={"process > select audience"} />
                </span>
              </Button>
            )}
          </Col>
        </Row>
        {selectedStations.length > 0 ? (
          <div className={bem.e("selected-stations")}>
            {map(selectedStations, (item, index) => (
              <span className={bem.e("station")}>
                <span
                  className={bem.e("remove-tag")}
                  onClick={() => {
                    selectedStations.splice(index, 1);
                    formProps.setFieldValue(
                      "settings_copy_to_stations",
                      selectedStations.map((item) => item.value)
                    );
                  }}
                >
                  <CloseSmallIcon />
                </span>
                {item.label}
              </span>
            ))}
          </div>
        ) : null}
      </div>
      <AudiencePickerModal
        isOpen={isAudiencePickerModalOpen}
        onToggle={() =>
          setIsAudiencePickerModalOpen(!isAudiencePickerModalOpen)
        }
        isForceUpdateSelect={true}
        onClose={onSelectedAudience}
        template={{
          key: "promotion",
        }}
        isFilterByServerConfig={true}
        selectedGroupId={null}
        selectedAudience={selectedStations.map((item) => item.value)}
      />
    </div>
  );
};
export default CopyStations;
