import ExportModal from "./view";
import { injectIntl } from "react-intl";
import { get } from "lodash";
import { connect } from "react-redux";
import { compose } from "recompose";
const ExportModalForm = compose(
  connect(
    (state, props) => {
      return {
        serverCountry: get(state, "auth.info.server_country", "US"),
      };
    },
    {}
  ),
)(ExportModal);

export default injectIntl(ExportModalForm);
