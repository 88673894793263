import * as Const from "utils/constants";
import Api from "utils/api";
import { get, omit, isFunction } from "lodash";
import {
  FETCH_WORKFLOWS_REQUEST,
  FETCH_WORKFLOWS_SUCCESS,
  FETCH_WORKFLOWS_ERROR,
  ADD_WORKFLOW_REQUEST,
  ADD_WORKFLOW_ERROR,
  ADD_WORKFLOW_SUCCESS,
  UPDATE_WORKFLOW_REQUEST,
  UPDATE_WORKFLOW_SUCCESS,
  UPDATE_WORKFLOW_ERROR,
  FORGET_WORKFLOWS,
  DELETE_WORKFLOW_SUCCESS,
  DELETE_WORKFLOW_REQUEST,
  DELETE_WORKFLOW_ERROR
} from "../types/workflows";
import { forgetChannels } from "./channels";
export const getWorkflows = payload => async dispatch => {
  let errorStr = "";
  try {
    dispatch({ type: FETCH_WORKFLOWS_REQUEST, payload });
    const paramString = new URLSearchParams();
    if (payload.sort) {
      paramString.set("sort", payload.sort);
    } else {
      paramString.set("sort", `order asc`);
    }
    if (payload.search) {
      paramString.set("search", payload.search);
    }
    if (payload.page) {
      paramString.set("page", payload.page);
    }
    paramString.set("rpp", payload.rpp ? payload.rpp : 999);
    const url = Const.GET_WORKFLOWS() + `?${paramString.toString()}`;
    let response = await Api.doCall(url, "GET");
    if (response.status === 200) {
      if (!response.data.error) {
        dispatch(
          fetchWorkflowsSuccess({
            ...omit(payload, ["page"]),
            page: get(response, "data.page", 1),
            pages: get(response, "data.pages", 1),
            data: get(response, "data.items", [])
          })
        );
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch(fetchWorkflowsError({ error: errorStr }));
};
export const updateWorkflow = (id, data, cb) => async dispatch => {
  let errorStr = "";
  let errorArr = [];
  try {
    dispatch({ type: UPDATE_WORKFLOW_REQUEST });
    //api call for store data
    let response = await Api.doCall(Const.GET_WORKFLOW(id), "PUT", data);
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        //response data
        let responseData = JSON.parse(JSON.stringify(response.data));
        dispatch({
          type: UPDATE_WORKFLOW_SUCCESS,
          payload: responseData
        });
        // refresh resources when adding new
        // dispatch(forgetWorkflows()); // we changed to use pusher for refresh workflows resources
        dispatch(forgetChannels());
        if (isFunction(cb))
          cb({
            status: true,
            errors: null
          });
        return;
      }
      //error data
      errorStr = response.data.error;
    }
  } catch (error) {
    errorArr = JSON.parse(JSON.stringify(error.error));
    if (isFunction(cb))
      cb({
        status: false,
        errors: errorArr
      });
    errorStr = error.toString();
  }
  dispatch({
    type: UPDATE_WORKFLOW_ERROR,
    payload: {
      error: errorStr
    }
  });
};
export const addWorkflow = (data, cb) => async dispatch => {
  let errorStr = "";
  let errorArr = [];
  try {
    dispatch({ type: ADD_WORKFLOW_REQUEST });
    //api call for store data
    let response = await Api.doCall(Const.ADD_WORKFLOW(), "POST", data);
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        //response data
        let responseData = JSON.parse(JSON.stringify(response.data));
        dispatch({
          type: ADD_WORKFLOW_SUCCESS,
          payload: responseData
        });
        // refresh resources when adding new
        // dispatch(forgetWorkflows()); // we changed to use pusher for refresh workflows resources
        if (isFunction(cb))
          cb({
            status: true,
            errors: null,
            data: responseData
          });
        return;
      }
      //error data
      errorStr = response.data.error;
    }
  } catch (error) {
    errorArr = JSON.parse(JSON.stringify(error.error));
    if (isFunction(cb))
      cb({
        status: false,
        errors: errorArr
      });
    errorStr = error.toString();
  }
  dispatch({
    type: ADD_WORKFLOW_ERROR,
    payload: {
      error: errorStr
    }
  });
};

export const deleteWorkflow = (id, cb) => async dispatch => {
  let errorStr = "";
  try {
    dispatch({ type: DELETE_WORKFLOW_REQUEST });
    let response = await Api.doCall(Const.GET_WORKFLOW(id), "DELETE");
    if (response.status === 200) {
      if (!response.error) {
        dispatch({ type: DELETE_WORKFLOW_SUCCESS, payload: { id } });
        if (cb) cb(response);
        // dispatch(forgetWorkflows()); // we changed to use pusher for refresh workflows resources
        dispatch(forgetChannels());
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: DELETE_WORKFLOW_ERROR, payload: { error: errorStr } });
};
export function fetchWorkflowsSuccess(payload) {
  return {
    type: FETCH_WORKFLOWS_SUCCESS,
    payload
  };
}
export function fetchWorkflowsError(payload) {
  return {
    type: FETCH_WORKFLOWS_ERROR,
    payload
  };
}
export function forgetWorkflows() {
  return {
    type: FORGET_WORKFLOWS
  };
}
