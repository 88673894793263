import cn from "classnames";
import PropTypes from "prop-types";
import React, { Component } from "react";

import BackgroundWrapper from "./BackgroundWrapper";
import { get } from "lodash";

export default class TimeSlotGroup extends Component {
  render() {
    const {
      renderSlot,
      resource,
      group,
      getters,
      widthElement,
      components: { timeSlotWrapper: Wrapper = BackgroundWrapper } = {}
    } = this.props;
    return (
      <div className="rbc-timeslot-group ps-rbc-timeslot-group">
        {group.map((value, idx) => {
          const slotProps = getters ? getters.slotProp(value, resource) : {};
          if (widthElement) {
            slotProps.style = {
              ...get(slotProps, "style", {}),
              minWidth: widthElement - 1 // subtract 1 is border left 1px
            };
          }
          return (
            <Wrapper key={idx} value={value} resource={resource}>
              <div
                {...slotProps}
                className={cn("rbc-time-slot", slotProps.className)}
              >
                {renderSlot && renderSlot(value, idx)}
              </div>
            </Wrapper>
          );
        })}
      </div>
    );
  }
}

TimeSlotGroup.propTypes = {
  renderSlot: PropTypes.func,
  group: PropTypes.array.isRequired,
  resource: PropTypes.any,
  components: PropTypes.object,
  getters: PropTypes.object
};
