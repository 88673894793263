import React from "react";
import { FormattedMessage } from "react-intl";
import { Row, Col, Button } from "reactstrap";
import classnames from "classnames";
import TextInputFormatted from "components/TextInputFormatted";
import bn from "utils/bemnames";
import { convertArrayToString } from "utils/helpers";
import PropTypes from "prop-types";
import {
  get,
  isUndefined,
  map,
  isEqual,
  first,
  last,
  toLower,
  find,
  forEach,
  toString,
  includes,
} from "lodash";
import { Asterisk } from "components/Elements";
import { CheckedIcon, TrashIcon } from "components/CustomIcons";
import ToggleSwitch from "components/ToggleSwitch";
import Spinner from "components/Spinner";
import AvailabilityTable from "../AvailabilityTable";
import SupplementItemsModal from "components/SupplementItemsModal";
import moment from "moment";

const weekArray = moment.weekdays();
const bem = bn.create("schedule-preview");

const isSelectedDay = ({ date, process }) => {
  let selectedDays = process.data.fields.days_of_week
    ? process.data.fields.days_of_week.value
    : [];
  const day = moment(date).day() + 1;
  return includes(selectedDays, day);
};
const getAvailableDates = (process) => {
  if (
    !process.data.fields.order_start_date ||
    !process.data.fields.order_end_date
  ) {
    return [];
  }

  let startDate = moment(process.data.fields.order_start_date.value);
  let endDate = moment(process.data.fields.order_end_date.value);

  let dates = [];
  let currentDate = startDate.subtract(1, "days");
  let addDays = function(days) {
    let date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };
  while (currentDate <= endDate) {
    if (isSelectedDay({ date: currentDate, process })) {
      dates.push(currentDate);
    }

    currentDate = addDays.call(currentDate, 1);
  }

  return dates;
};
const getSelectedDates = (process) => {
  let selectedDates = [];

  let excludedDates = process.data.fields.excluded_dates
    ? process.data.fields.excluded_dates.value
    : [];

  excludedDates = excludedDates.map((date) =>
    moment(date).format("YYYY-MM-DD")
  );

  let availableDates = getAvailableDates(process);

  forEach(availableDates, (date) => {
    if (!includes(excludedDates, moment(date).format("YYYY-MM-DD"))) {
      selectedDates.push(date);
    }
  });

  return selectedDates;
};

export function renderFinalScheduleStatement(process, formatDate = "MM/DD/YYYY") {
  const templateKey = get(process, "data.fields.template_key.value");
  const selectedBy = get(process, "data.fields.selected_by.value");
  const daysOfWeek = get(process, "data.fields.days_of_week.value", []);
  const orderStartDate = get(process, "data.fields.order_start_date.value");
  const orderEndDate = get(process, "data.fields.order_end_date.value");
  const excludedDates = get(process, "data.fields.excluded_dates.value");
  const excludedHours = get(process, "data.fields.excluded_hours_array.value");
  const schedulingType = get(process, "data.fields.scheduling_type.value");
  const scheduleBy = get(process, "data.fields.schedule_by.value");
  const isContestFloating =
    schedulingType === "floating" && templateKey === "contest";
  if (
    !orderStartDate ||
    !orderEndDate ||
    !daysOfWeek ||
    daysOfWeek.length === 0
  )
    return <FormattedMessage id="process > final schedule statement text" />;
  const selectedItems = get(process, "data.fields.selected_items.value", []);

  // render selected items
  let textArray = [];
  if (selectedItems.length === 0) {
    textArray.push(
      isContestFloating ? `Contest will play ` : `Items will play `
    );
  }
  if (selectedItems.length > 0) {
    const isToLower = selectedBy === "hour";
    const items = map(selectedItems, (item) =>
      isToLower ? toLower(item.label) : item.label
    );
    if (isContestFloating && items.length > 0) {
      textArray.push(
        `Contest will be available during ${convertArrayToString(items)}, `
      );
    } else if (schedulingType === "fill") {
      textArray.push(
        `Items will fill empty slots in ${convertArrayToString(items)}, `
      );
    } else {
      textArray.push(`Items will play during ${convertArrayToString(items)}, `);
    }
  }
  // renter day of week text
  if (daysOfWeek && daysOfWeek.length > 0) {
    let dayRange = "";
    const startDayOfWeek = weekArray[first(daysOfWeek) - 1];
    const endDayOfWeek = weekArray[last(daysOfWeek) - 1];
    if (daysOfWeek.length === 7) {
      dayRange = ` Monday - Sunday`;
    } else if (
      daysOfWeek.length === 5 &&
      isEqual(daysOfWeek, [2, 3, 4, 5, 6])
    ) {
      dayRange = ` ${startDayOfWeek} through ${endDayOfWeek}`;
    } else {
      dayRange = daysOfWeek.map((day) => {
        return weekArray[day - 1];
      });
      dayRange = convertArrayToString(dayRange);
    }
    textArray.push(dayRange);
  }
  if (excludedHours && excludedHours.length > 0) {
    const hourAll = find(excludedHours, (item) => item.key === "all");
    const excludedHoursAll = get(hourAll, "value", []).map((item) =>
      toLower(item.label)
    );
    const excludedHoursAllText =
      excludedHoursAll.length > 0
        ? `${convertArrayToString(excludedHoursAll)} every day`
        : "";
    let arrExcluded = [];
    if (excludedHoursAllText) arrExcluded.push(excludedHoursAllText);
    forEach(excludedHours, (hourItem) => {
      const { key } = hourItem;
      if (key === "all") return;
      const itemsValue = get(hourItem, "value", [])
        .filter((item) => {
          return !find(
            get(hourAll, "value", []),
            (i) => i.value === item.value
          );
        })
        .map((item) => toLower(item.label));
      const excludedHoursKeyText =
        itemsValue.length > 0
          ? `${convertArrayToString(itemsValue)} ${weekArray[key - 1]}`
          : "";
      if (excludedHoursKeyText) {
        arrExcluded.push(excludedHoursKeyText);
      }
    });
    const finalExcludedHoursText =
      arrExcluded.length > 0 ? convertArrayToString(arrExcluded) : "";
    if (finalExcludedHoursText) {
      textArray.push(`excluding ${finalExcludedHoursText}`);
    }
  }
  if (orderStartDate && orderEndDate) {
    textArray.push(
      `starting ${moment(orderStartDate).format(
        formatDate
      )} and ending ${moment(orderEndDate).format(formatDate)}`
    );
  }
  // render excluded dates
  if (excludedDates && excludedDates.length > 0) {
    const excludedDatesFormatted = excludedDates.map((item) =>
      moment(item).format("MM/DD")
    );
    textArray.push(`excluding ${convertArrayToString(excludedDatesFormatted)}`);
  }
  if (schedulingType !== "targeted" && scheduleBy === "ROS") {
    textArray.push(
      `. Fill items will be entered in schedule at 12:00am night before`
    );
  }
  const selectedDates = getSelectedDates(process);
  const totalDays = selectedDates.length;
  if (!isContestFloating) {
    if (totalDays < 14) {
      textArray.push(`Item will run for ${totalDays} days`);
    } else {
      const totalWeeks = moment(orderEndDate).diff(
        moment(orderStartDate),
        "weeks"
      );
      textArray.push(`Item will run for ${totalWeeks + 1} weeks`);
    }
  }
  return `${textArray.join(" ")}.`;
}
const SchedulingPreview = (props) => {
  const {
    process,
    validationState,
    intl,
    dayparts,
    schedulingType,
    availability,
    shows,
    scheduleAvailability,
    step,
    onValueChanged,
    checkIsInValidSchedulingCheck,
    checkIsInValidFindInventoryCheck,
    isAllowShowDesiredItemsDescription,
    isAllowManualDesiredItems,
    schedulingPreviewHelpText,
  } = props;
  const desired_items_scheduling = step.fields.find(
    (f) => f.field === "desired_items_scheduling"
  );
  const selected_by = step.fields.find((f) => f.field === "selected_by");
  const supplemental_items = step.fields.find(
    (f) => f.field === "supplemental_items"
  );
  const relax_restrictions_for = step.fields.find(
    (f) => f.field === "relax_restrictions_for"
  );
  const relax_restrictions_for_weeks = step.fields.find(
    (f) => f.field === "relax_restrictions_for_weeks"
  );
  const totalItems = get(
    process,
    "data.fields.total_available_items.value",
    "undefined"
  );
  const isShowStatusValid =
    totalItems !== "undefined" && schedulingType === "targeted";
  const totalSupplementalItems = get(
    process,
    "data.fields.supplemental_items.value",
    0
  );
  const totalDesiredItems = get(
    process,
    "data.fields.desired_items_scheduling.value"
  );
  const scheduleBy = get(process, "data.fields.schedule_by.value");
  const selectedBy = get(process, "data.fields.selected_by.value");
  const isValidDesiredItems =
    Number(totalDesiredItems) &&
    Number(totalItems) + Number(totalSupplementalItems) >=
      Number(totalDesiredItems);
  const totalNeededInventory = Number(totalDesiredItems) - Number(totalItems);
  return (
    <div className={bem.e("scheduling-preview-container")}>
      <h2 className={bem.e("preview-title")}>
        <span>
          <FormattedMessage id="process > promotion > liner > scheduling > scheduling preview" />
          {props.isSchedulingPreviewRequired && <Asterisk>*</Asterisk>}
        </span>
        {schedulingPreviewHelpText ? (
          <span className={bem.e("preview-help-text")}>
            {schedulingPreviewHelpText}
          </span>
        ) : null}
      </h2>
      <div className={bem.e("preview-content")}>
        <div className={bem.e("preview-head")}>
          <Row>
            <Col xs={7} className={bem.e("col-reset")}>
              <div
                className={classnames("no-flex", bem.e("preview-total-text"))}
              >
                <div>
                  <FormattedMessage id="process > promotion > liner > scheduling > total available items" />
                  <strong>{totalItems}</strong>
                </div>
                {get(
                  validationState,
                  "total_available_items.validation_error"
                ) && (
                  <span className="text-danger">
                    {get(
                      validationState,
                      "total_available_items.validation_error"
                    )}
                  </span>
                )}
              </div>
              {schedulingType === "targeted" && totalSupplementalItems > 0 && (
                <div className={bem.e("preview-total-text")}>
                  <FormattedMessage id="process > promotion > liner > scheduling > total supplemental items" />
                  :<strong>{totalSupplementalItems}</strong>
                </div>
              )}
              <div
                className={classnames("no-flex", bem.e("preview-total-text"))}
              >
                <div>
                  <FormattedMessage id="process > promotion > liner > scheduling > total desired items" />
                  {isAllowManualDesiredItems && schedulingType === "simple" ? (
                    <TextInputFormatted
                      value={totalDesiredItems}
                      placeholder={
                        Number(totalItems) ? toString(totalItems) : ""
                      }
                      onChange={(value) => {
                        onValueChanged(desired_items_scheduling, value);
                      }}
                    />
                  ) : (
                    <strong>
                      {!isUndefined(totalDesiredItems)
                        ? totalDesiredItems
                        : "undefined"}
                    </strong>
                  )}
                  {isShowStatusValid ? (
                    isValidDesiredItems ? (
                      <CheckedIcon />
                    ) : (
                      <TrashIcon color="#e40061" />
                    )
                  ) : null}
                </div>
                {get(
                  validationState,
                  "desired_items_scheduling.validation_error"
                ) && (
                  <span className="text-danger">
                    {get(
                      validationState,
                      "desired_items_scheduling.validation_error"
                    )}
                  </span>
                )}
              </div>
              {!isValidDesiredItems && isShowStatusValid ? (
                <p className={bem.e("desired-items-alert")}>
                  <FormattedMessage id="process > promotion > liner > scheduling > scheduling preview error" />
                </p>
              ) : null}

              {isAllowShowDesiredItemsDescription &&
                isUndefined(totalDesiredItems) && (
                  <p className={bem.e("desired-items-description")}>
                    <FormattedMessage id="process > promotion > liner > scheduling > fill in your criteria above then check availability. You may then adjust criteria if necessary" />
                  </p>
                )}
            </Col>
            <Col xs={5} className={bem.e("col-reset")}>
              <div className={bem.e("preview-buttons")}>
                <Button
                  color="primary"
                  disabled={checkIsInValidSchedulingCheck()}
                  outline
                  className={"btn-radius"}
                  onClick={() => {
                    props.onCheckSchedulingAvailability({
                      selected_by: selectedBy,
                    });
                  }}
                >
                  <FormattedMessage id="process > promotion > liner > scheduling > button check availability" />
                </Button>
                {schedulingType === "targeted" &&
                  scheduleAvailability &&
                  totalNeededInventory > 0 && (
                    <Button
                      color="primary"
                      outline
                      className={"btn-radius"}
                      disabled={checkIsInValidFindInventoryCheck()}
                      onClick={() => {
                        props.onFindInventory();
                      }}
                    >
                      <FormattedMessage id="process > promotion > liner > scheduling > button find inventory" />
                    </Button>
                  )}
              </div>
              {schedulingType === "simple" && scheduleBy === "ROS" && (
                <div className={bem.e("section-toolbar")}>
                  <div>
                    <p className={bem.e("preview-distribution")}>
                      <FormattedMessage id="process > promotion > liner > scheduling > preview distribution by" />
                    </p>
                    <ToggleSwitch
                      className={bem.e("switch-toolbar")}
                      leftComponent={
                        <span
                          className={classnames("switch-text", {
                            active: selectedBy === "show",
                          })}
                        >
                          <FormattedMessage id="process > promotion > liner item availability > show" />
                        </span>
                      }
                      rightComponent={
                        <span
                          className={classnames("switch-text", {
                            active: selectedBy === "daypart",
                          })}
                        >
                          <FormattedMessage id="process > promotion > liner item availability > daypart" />
                        </span>
                      }
                      name="is_show"
                      switchProps={{
                        checked: selectedBy === "daypart",
                        onChange: (checked) => {
                          const newSelectedBy = checked ? "daypart" : "show";
                          onValueChanged(selected_by, newSelectedBy);
                          props.onCheckSchedulingAvailability({
                            selected_by: newSelectedBy,
                          });
                          console.log("newSelectedBy", newSelectedBy);
                        },
                        disabled: false,
                        offColor: "#C2D4E0",
                        onColor: "#795AFA",
                        uncheckedIcon: false,
                        checkedIcon: false,
                        boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.282)",
                        height: 26.44,
                        width: 48.48,
                        handleDiameter: 22.92,
                      }}
                    />
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </div>
        {availability && Object.keys(availability).length > 0 ? (
          <div className={bem.e("scheduling-table-preview")}>
            <AvailabilityTable
              intl={intl}
              availability={availability}
              showSelected={selectedBy}
              shows={shows}
              dayparts={dayparts}
              bem={bem}
              isVerticalScroll
              isDistribution
              isShowRule={props.isShowRule}
              isShowTotal
              distributionText={selectedBy}
              restrictions={props.restrictions}
              rules={get(
                process,
                "data.fields.relax_restrictions_for.value",
                []
              )}
              selectedRestrictionWeeks={get(
                process,
                "data.fields.relax_restrictions_for_weeks.value",
                null
              )}
              onRelaxRestriction={(values) => {
                onValueChanged(relax_restrictions_for, values);
              }}
              onRelaxRestrictionWeeks={(values) => {
                onValueChanged(relax_restrictions_for_weeks, values);
              }}
              onRecalculate={() => {
                if (!checkIsInValidSchedulingCheck())
                  props.onCheckSchedulingAvailability({
                    selected_by: selectedBy,
                  });
              }}
            />
          </div>
        ) : null}
      </div>
      <Spinner isLoading={props.isLoading} />
      <SupplementItemsModal
        isOpen={props.isOpenSupplementItemsModal}
        onToggle={() => props.onReleaseInventory()}
        totalNeeded={totalDesiredItems - totalItems}
        items={props.inventory}
        onSubmit={(total, values) => {
          onValueChanged(supplemental_items, total);
          props.onSaveInventory(values);
        }}
      />
    </div>
  );
};
SchedulingPreview.defaultProps = {
  isAllowShowDesiredItemsDescription: true,
  isAllowManualDesiredItems: true,
  schedulingPreviewHelpText: null,
  isSchedulingPreviewRequired: false,
};
SchedulingPreview.propTypes = {
  isAllowShowDesiredItemsDescription: PropTypes.bool,
  isAllowManualDesiredItems: PropTypes.bool,
  schedulingPreviewHelpText: PropTypes.any,
  isSchedulingPreviewRequired: PropTypes.bool,
};
export default SchedulingPreview;
