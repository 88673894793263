import OrderDetails from "./orderDetails";
import WriteScript from "./writeScript";
import Schedule from "./schedule";
import Client from "./client";
import Log from "./log";

const steps = {
  order_details: OrderDetails,
  write_script: WriteScript,
  schedule: Schedule,
  client: Client,
  log: Log,
};
export default steps;
