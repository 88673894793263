import React from 'react';
import PropTypes from "prop-types";
import { isFunction } from "lodash";
import classnames from "classnames";
import { Form } from 'reactstrap';
import { MdSearch, MdClose } from 'react-icons/lib/md';
import { get } from "lodash"
import bn from "utils/bemnames";
const bem = bn.create("search-form");

class SearchInput extends React.Component {
  constructor(props){
    super();
    this.state = {
      value: props.value
    }
  }
  componentWillUpdate(nextProps){
    if(get(this.props, "value") !== get(nextProps, "value")){
      this.setState({
        value: get(nextProps, "value")
      })
    }
  }
  onChange = ({target}) => {
    this.setState({
      value: target.value
    });
    if(this.props.onChange){
      this.props.onChange(target.value);
    }
  }
  render(){
    const {
      onSubmit,
      onClear,
      placeholder,
    } = this.props;
    return (
      <Form inline className={bem.b()} onSubmit={onSubmit}>
        <input
          value={this.state.value}
          onChange={this.onChange}
          type="search"
          className={classnames(bem.e('input'), "form-control")}
          placeholder={placeholder}
        />
        {
          isFunction(onClear)
          && get(this.state, "value")
          && (
            <MdClose
              onClick={(event) => {
                event.preventDefault();
                this.setState({
                  value: ""
                });
                onClear();
              }}
              className={bem.e('icon-close')}
            />
          )
        }
        <button
            type='submit'
            className={bem.e('icon-search')}>
            <MdSearch/>
        </button>
      </Form>
    );
  }
}
SearchInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClear: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func
};
SearchInput.defaultProps = {
  placeholder: 'Search...',
  onChange: () => {},
  onSubmit: () => {}
}
export default SearchInput;
