import React from "react";
import {
  ShadowBox,
  Asterisk,
  SectionTitle,
  SectionFields,
  ChevronWrapper,
} from "components/Elements";
import Dropdown from "components/Dropdown";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import bn from "utils/bemnames";
import TextInput from "components/TextInput";
import UploadFiles from "components/UploadFiles";
import UploadFile from "components/UploadFile";
import HandleAssets from "components/HandleAssets";
import classnames from "classnames";
import { get, map, findIndex, isEmpty, difference, first, pick } from "lodash";
import ToggleSwitch from "components/ToggleSwitch";
import Checkbox from "components/Checkbox";
import { setScriptField, uuidv4 } from "utils/helpers";
import TextareaEvaluator, {
  DEFAULT_WORDS_PER_MIN,
} from "components/TextareaEvaluator";
import { DownIcon, UpIcon, PlusSmallIcon } from "components/CustomIcons";
import { Row, Col, Button, Label } from "reactstrap";
import FileView from "components/FileView";
import Badge from "components/Badge";
import CustomMultiSelectDropdown from "components/CustomMultiSelectDropdown";
import { spotTypes, voicePreferences } from "utils/constants";
import moment from "moment";
import RotationChart from "components/RotationChart";
import { formatDisplayDate, isHtml } from "utils/helpers";
import DropdownLength from "components/DropdownLength";
import { productionTypeOptions } from "utils/config";
import TextareaAutosize from "react-textarea-autosize";
import useWindowDimensions from "utils/useWindowDimensions";
import CommentToChat from "../CommentToChat";

const bem = bn.create("order-details");

function RenderDraftOrFinalSwitch(props) {
  const { onValueChangedSpot, spotIndex, spot, disabled } = props;
  const isChecked = get(spot, "script_type", "draft") === "final";
  return (
    <div className="switch-draft">
      <ToggleSwitch
        leftComponent={<FormattedMessage id="process > draft script" />}
        rightComponent={<FormattedMessage id="process > final script" />}
        switchProps={{
          onChange: (checked) => {
            let value = "draft";
            if (checked) {
              value = "final";
            }
            onValueChangedSpot(spotIndex, {
              field: "script_type",
              value,
            });

            if (value === "final") {
              onValueChangedSpot(spotIndex, {
                field: "script",
                value: get(spot, "draft_script", ""),
              });
              onValueChangedSpot(spotIndex, {
                field: "draft_script",
                value: "",
              });
            } else {
              onValueChangedSpot(spotIndex, {
                field: "draft_script",
                value: get(spot, "script", ""),
              });
              onValueChangedSpot(spotIndex, {
                field: "script",
                value: "",
              });
            }
          },
          disabled,
          checked: isChecked,
          offColor: "#8165f3",
          onColor: "#8165f3",
          uncheckedIcon: false,
          checkedIcon: false,
        }}
      />
    </div>
  );
}

const renderSelectedLineHashes = (lineHashes, props) => {
  const { isEdit, onValueChangedSpot, spotIndex } = props;

  return lineHashes.map((item) => {
    return (
      <Badge
        key={item.value}
        text={item.label}
        onClick={() => {
          onValueChangedSpot(spotIndex, {
            field: "line_hashes",
            value: difference(lineHashes, [item]),
          });
        }}
        readOnly={!isEdit}
      />
    );
  });
};

const renderSelectedLineHashesView = (lineHashes, availableLineNumbers) => {
  if (lineHashes.length === availableLineNumbers.length) {
    return "All";
  }

  if (lineHashes.length > 1) {
    return "Multiple Selected";
  }

  if (lineHashes.length === 1) {
    return get(first(lineHashes), "label");
  }

  return "None";
};

function RenderSpotDetailForProductionNeed(props) {
  const {
    onValueChangedSpot,
    spot,
    spotIndex,
    valueChanged,
    orderTitle,
    template,
    user,
    auth,
    disabled,
    validationState,
    isEdit,
    process,
    onClickAddSpot,
    isShowAddButton,
    allValidationState,
    isShowDeleteSpotButton,
    onClickDeleteSpot,
    step,
    setShouldBlockNavigation,
    intl,
    isShowRotationChar,
    setIsFieldsChanged,
    isShowComment,
  } = props;

  let contractStatusField = step.fields.find(
    (f) => f.field === "contract_status"
  );

  let orderLengthField = step.fields.find((f) => f.field === "order_length");
  let orderLengthCustomField = step.fields.find(
    (f) => f.field === "order_length_custom"
  );

  const refreshTextEditor = get(process, "data.fields.refreshed_at.value", 0);
  const producerAssignsVo = get(
    process,
    "data.fields.producer_assigns_vo.value",
    false
  );
  const teamProductionManager = get(
    process,
    "data.fields.team_production_manager.value"
  );
  const isEnableEnterprise = get(auth, "info.production_multimarket", false);
  const isWOTrafficEnabled = get(auth, "info.enable_wo_traffic", false);
  const availableLineNumbers = get(
    process,
    "data.fields.available_line_numbers.value",
    []
  );
  const isUpdateProcessData =
    (!get(process, "data.fields.order_title.value") && spotIndex === 0) ||
    get(process, "data.fields.key.value") === spot.key;

  const showLineNumber = !isEmpty(availableLineNumbers);
  const lineHashes = get(spot, "line_hashes", []);
  const isBookend = get(spot, "spot_type", "Standard") === "Book-ends";
  const voicePreferenceOptions = map(voicePreferences, (item) => ({
    label: intl.formatMessage({ id: `process > ${item}` }),
    value: item,
  }));
  const contractStatus = get(spot, "contract_status", "");
  const orderLengthCustom = get(spot, "order_length_custom", false);
  const orderLength = get(spot, "order_length", "");
  const dueDate = get(spot, "due_date", "");
  const orderLengthDisplay = () => {
    if (orderLengthCustom && orderLength) {
      const formatted = moment
        .utc(parseInt(orderLength) * 1000)
        .format("mm:ss");
      return formatted;
    }
    if (orderLength) {
      return `${orderLength} ${intl.formatMessage({
        id: "process > title seconds",
      })}`;
    }
    return intl.formatMessage({ id: "process > none" });
  };
  const isSpecOrder = get(process, "data.fields.order_sold.value") === false;
  const isDisableOrderDates = disabled;
  const isShowVoicePreference = isEnableEnterprise && teamProductionManager;
  if (!isEdit) {
    return (
      <React.Fragment>
        <div className={bem.e("spot-title")}>
          <FormattedMessage id="process > spot" /> {spotIndex + 1}
        </div>
        <Row>
          <Col xs={6} className={bem.e("col-view-field")}>
            <Label className={bem.e("order-view-label")}>
              <FormattedMessage id={`process > field title`} />
            </Label>
            <span className={bem.e("order-view-value")}>
              {get(spot, "title", "") ? (
                get(spot, "title", "")
              ) : (
                <FormattedMessage id="process > none" />
              )}
            </span>
          </Col>
          <Col xs={6} className={bem.e("col-view-field")}>
            <Label className={bem.e("order-view-label")}>
              <FormattedMessage id="process > field isci / ad id" />
            </Label>
            <span className={bem.e("order-view-value")}>
              {get(spot, "isci", "") ? (
                get(spot, "isci", "")
              ) : (
                <FormattedMessage id="process > none" />
              )}
            </span>
          </Col>
        </Row>
        {!isSpecOrder && (
          <Row>
            <Col xs={6} className={bem.e("col-view-field")}>
              <Label className={bem.e("order-view-label")}>
                <FormattedMessage id="process > field production type" />
              </Label>
              <span className={bem.e("order-view-value")}>
                {contractStatus ? (
                  contractStatus
                ) : (
                  <FormattedMessage id="process > none" />
                )}
              </span>
            </Col>
            <Col className={bem.e("col-view-field")}>
              <Label className={bem.e("order-view-label")}>
                <FormattedMessage id="process > field spot length" />
              </Label>
              <span className={bem.e("order-view-value")}>
                {orderLengthDisplay()}
              </span>
            </Col>
          </Row>
        )}
        <Row>
          <Col className={bem.e("col-view-field")}>
            <Label className={bem.e("order-view-label")}>
              <FormattedMessage id="process > field due date" />
            </Label>
            <span className={bem.e("order-view-value")}>
              {dueDate ? (
                formatDisplayDate(dueDate)
              ) : (
                <FormattedMessage id="process > none" />
              )}
            </span>
          </Col>
          <Col className={bem.e("col-view-field")}>
            <Label className={bem.e("order-view-label")}>
              <FormattedMessage id="process > field spot type" />
            </Label>
            <span className={bem.e("order-view-value")}>
              {get(spot, "spot_type", "") ? (
                get(spot, "spot_type", "")
              ) : (
                <FormattedMessage id="process > none" />
              )}
            </span>
          </Col>

          {isWOTrafficEnabled && showLineNumber && (
            <Col className={bem.e("col-view-field")}>
              <Label className={bem.e("order-view-label")}>
                <FormattedMessage id="process > field line #" />
              </Label>
              {renderSelectedLineHashesView(lineHashes, availableLineNumbers)}
            </Col>
          )}
          {isShowVoicePreference && (
            <Col xs={6} className={bem.e("col-view-field")}>
              <Label className={bem.e("order-view-label")}>
                <FormattedMessage id="process > field voice preference" />
              </Label>
              <span className={bem.e("order-view-value")}>
                {get(spot, "voice_preference", "") ? (
                  intl.formatMessage({
                    id: `process > ${get(spot, "voice_preference", "")}`,
                  })
                ) : (
                  <FormattedMessage id="process > none" />
                )}
              </span>
            </Col>
          )}
        </Row>
        {isWOTrafficEnabled && showLineNumber && !isEmpty(lineHashes) && (
          <Row>
            <Col className="selected-line-hashes">
              <Label>
                <FormattedMessage id="process > field selected line #" />
              </Label>
              <div className="line-hashes-badges-container">
                {renderSelectedLineHashes(lineHashes, props)}
              </div>
            </Col>
          </Row>
        )}
        <Row className={bem.e("approvals")}>
          <Col className={bem.e("col-view-field")} xs={6}>
            <Label>
              <FormattedMessage id="production settings > script approvals" />
            </Label>
            <Checkbox
              checked={get(spot, "script_requires_approval", false)}
              onChange={() => {}}
              disabled={disabled}
              text={props.intl.formatMessage({
                id: "production settings > script review required",
              })}
            />
            <Checkbox
              checked={get(spot, "writer_sends_script_to_client", false)}
              onChange={() => {}}
              disabled={
                !get(spot, "script_requires_approval", false) || disabled
              }
              text={props.intl.formatMessage({
                id: "production settings > writer sends script to Client",
              })}
            />
          </Col>
          <Col className={bem.e("col-view-field")} xs={6}>
            <Label>
              <FormattedMessage id="production settings > spot approvals" />
            </Label>
            <Checkbox
              checked={get(spot, "recording_requires_approval", false)}
              onChange={() => {}}
              disabled={disabled}
              text={props.intl.formatMessage({
                id: "production settings > spot review required",
              })}
            />
            <Checkbox
              checked={get(spot, "producer_sends_spot_to_client", false)}
              onChange={() => {}}
              disabled={
                !get(spot, "recording_requires_approval", false) || disabled
              }
              text={props.intl.formatMessage({
                id: "production settings > producer sends spot to Client",
              })}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} className={bem.e("col-view-field")}>
            <Label className={bem.e("order-view-label")}>
              {isEnableEnterprise ? (
                <FormattedMessage id="process > field copy points" />
              ) : (
                <FormattedMessage id="process > field copy points/instructions" />
              )}
            </Label>
            <span className={bem.e("order-view-value")}>
              {get(spot, "copy_points", "") ? (
                isHtml(get(spot, "copy_points", "")) ? (
                  <TextareaEvaluator
                    text={setScriptField(get(spot, "copy_points", ""))}
                    isEditorOnly={true}
                    hideOrderLength={true}
                    readOnly={true}
                    process={process}
                    onEvaluatorCallback={() => {}}
                    disabled={disabled}
                    key={refreshTextEditor}
                  />
                ) : (
                  <TextareaAutosize
                    value={get(spot, "copy_points", "")}
                    className="text-area-view"
                    disabled
                  />
                )
              ) : (
                <FormattedMessage id="process > none" />
              )}
            </span>
          </Col>
        </Row>
        {!isBookend ? (
          <Row>
            <Col className={bem.e("col-field")}>
              <TextareaEvaluator
                text={setScriptField(
                  get(spot, "script_type", "draft") === "final"
                    ? get(spot, "script", "")
                    : get(spot, "draft_script", "")
                )}
                isOrderLength={true}
                onEvaluatorCallback={(value) => {}}
                process={process}
                spot={spot}
                wordsPerMin={get(
                  spot,
                  "default_words_per_min",
                  DEFAULT_WORDS_PER_MIN
                )}
                title={<FormattedMessage id="process > field script" />}
                disabled={disabled}
                readOnly={true}
                showInnerTitle={true}
                showScriptLength={true}
                key={refreshTextEditor}
              />
            </Col>
          </Row>
        ) : (
          <div className={bem.e("script-fields-container")}>
            <Row>
              <Col className={bem.e("col-field")}>
                <TextareaEvaluator
                  text={setScriptField(
                    get(spot, "script_type", "draft") === "final"
                      ? get(spot, "script", "")
                      : get(spot, "draft_script", "")
                  )}
                  isOrderLength={true}
                  onEvaluatorCallback={(value) => {}}
                  process={process}
                  spot={spot}
                  title={
                    <FormattedMessage id="process > field script part A" />
                  }
                  wordsPerMin={get(
                    spot,
                    "default_words_per_min",
                    DEFAULT_WORDS_PER_MIN
                  )}
                  disabled={disabled}
                  isBookend={isBookend}
                  part="a"
                  readOnly={true}
                  showInnerTitle={true}
                  showScriptLength={true}
                  key={refreshTextEditor}
                />
              </Col>
            </Row>
            <Row>
              <Col className={bem.e("col-field")}>
                <TextareaEvaluator
                  text={setScriptField(
                    get(spot, "script_type", "draft") === "final"
                      ? get(spot, "script_part_b", "")
                      : get(spot, "draft_script_part_b", "")
                  )}
                  isOrderLength
                  onEvaluatorCallback={(value) => {}}
                  process={process}
                  spot={spot}
                  title={
                    <FormattedMessage id="process > field script part B" />
                  }
                  disabled={disabled}
                  isBookend={isBookend}
                  part="b"
                  wordsPerMin={get(
                    spot,
                    "default_words_per_min_b",
                    DEFAULT_WORDS_PER_MIN
                  )}
                  readOnly={true}
                  showInnerTitle={true}
                  showScriptLength={true}
                  key={refreshTextEditor}
                />
              </Col>
            </Row>
          </div>
        )}
        {get(spot, "script_type", "draft") === "final" && producerAssignsVo && (
          <Row>
            <Col className={bem.e("col-field")} xs={12}>
              <Label className={bem.e("order-view-label")}>
                <FormattedMessage id="process > field voice over instructions" />
              </Label>
              {get(spot, "voice_over_instructions", "") ? (
                <TextareaAutosize
                  value={get(spot, "voice_over_instructions", "")}
                  className="text-area-view"
                  disabled
                />
              ) : (
                <span className={bem.e("order-view-value")}>
                  <FormattedMessage id="process > none" />
                </span>
              )}
            </Col>
          </Row>
        )}

        <Row>
          <Col className={bem.e("col-field")}>
            <Label>
              <FormattedMessage id="process > field assets" />
            </Label>
            {get(spot, "files.length", 0) > 0 ? (
              get(spot, "files", []).map((file) => (
                <div key={file.path} className={bem.e("asset")}>
                  {file.audit && (
                    <span className={bem.e("asset-audit")}>
                      {intl.formatMessage({ id: "process > file" })} -{" "}
                      {intl.formatMessage({ id: "process > by" })}{" "}
                      {file.audit.modified_by}{" "}
                      {intl.formatMessage({ id: "process > on" })}{" "}
                      {file.audit.modified_at}
                    </span>
                  )}
                  <FileView file={file} actions={["view", "download"]} />
                </div>
              ))
            ) : (
              <FormattedMessage id="process > none" />
            )}
          </Col>
        </Row>
        {isShowRotationChar && (
          <Row>
            <Col>
              <RotationChart
                valueChanged={props.valueChanged}
                step={step}
                process={process}
                template={template}
                user={user}
                validationState={allValidationState}
                isView={true}
              />
            </Col>
          </Row>
        )}
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <div className={bem.e("spot-title")}>
        <FormattedMessage id="process > spot" /> {spotIndex + 1}
        {isShowDeleteSpotButton && (
          <div
            className={bem.e("delete-spot-button")}
            onClick={() => onClickDeleteSpot(spotIndex)}
          >
            <FormattedMessage id="process > button delete" />
          </div>
        )}
      </div>
      <Row>
        <Col xs={6} className={bem.e("col-field")}>
          <TextInput
            label={
              <span>
                <FormattedMessage id={`process > field title`} />
                <Asterisk>*</Asterisk>
              </span>
            }
            placeholder={
              isEnableEnterprise
                ? props.intl.formatMessage({
                    id: "process > placeholder enter title",
                  })
                : props.intl.formatMessage({
                    id: "process > placeholder enter spot title",
                  })
            }
            value={get(spot, "title", "")}
            onChange={({ target }) => {
              onValueChangedSpot(spotIndex, {
                field: "title",
                value: target.value,
              });
              if (isUpdateProcessData) {
                valueChanged(orderTitle, process, template, user, {
                  value: target.value,
                  orderTitle,
                });
              }
            }}
            disabled={disabled}
            error={get(validationState, "title", "")}
          />
        </Col>
        <Col className={bem.e("col-field")}>
          <TextInput
            label={<FormattedMessage id="process > field isci / ad id" />}
            placeholder={props.intl.formatMessage({
              id: "process > placeholder enter ID",
            })}
            value={get(spot, "isci", "")}
            onChange={({ target }) => {
              onValueChangedSpot(spotIndex, {
                field: "isci",
                value: target.value,
              });
            }}
            disabled={disabled}
          />
        </Col>
      </Row>
      {!isSpecOrder && (
        <Row>
          {contractStatus === "Production Needed" ? (
            <Col xs={6} className={bem.e("col-view-field")}>
              <Label className={bem.e("order-view-label")}>
                <FormattedMessage id="process > field production type" />
              </Label>
              <span className={bem.e("order-view-value")}>
                {contractStatus ? (
                  contractStatus
                ) : (
                  <FormattedMessage id="process > none" />
                )}
              </span>
            </Col>
          ) : (
            <Col xs={6} className={bem.e("col-field")}>
              <Dropdown
                name="contract_status"
                options={productionTypeOptions}
                label={
                  <span>
                    {contractStatusField.title}
                    {contractStatusField.mandatory && <Asterisk>*</Asterisk>}
                  </span>
                }
                placeholder={intl.formatMessage({
                  id: "process > placeholder select",
                })}
                value={
                  contractStatus
                    ? {
                        label: contractStatus,
                        value: contractStatus,
                      }
                    : ""
                }
                onChange={(event) => {
                  if (isUpdateProcessData) {
                    valueChanged(contractStatusField, process, template, user, {
                      value: get(event, "value", ""),
                    });
                  }
                  onValueChangedSpot(spotIndex, {
                    field: "contract_status",
                    value: get(event, "value", ""),
                  });
                  setShouldBlockNavigation(true);
                }}
                disabled={disabled}
                error={get(validationState, "contract_status", "")}
              />
            </Col>
          )}
          <Col className={bem.e("col-field")}>
            <DropdownLength
              isClearable={true}
              name="order_length"
              {...orderLengthField.props}
              label={
                <span>
                  {orderLengthField.title}
                  {orderLengthField.mandatory && <Asterisk>*</Asterisk>}
                </span>
              }
              placeholder={intl.formatMessage({
                id: "process > placeholder select",
              })}
              value={
                orderLength
                  ? {
                      label: orderLength,
                      value: orderLength,
                    }
                  : ""
              }
              onChange={(value, isCustom) => {
                if (isUpdateProcessData) {
                  valueChanged(orderLengthField, process, template, user, {
                    value: value,
                  });
                  valueChanged(
                    orderLengthCustomField,
                    process,
                    template,
                    user,
                    {
                      value: isCustom,
                    }
                  );
                }
                onValueChangedSpot(spotIndex, {
                  field: "order_length_custom",
                  value: isCustom,
                });
                onValueChangedSpot(spotIndex, {
                  field: "order_length",
                  value: value,
                });
                setShouldBlockNavigation(true);
              }}
              isCustom={get(spot, "order_length_custom", false)}
              disabled={disabled}
              error={get(validationState, "order_length", "")}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col className={bem.e("col-field")}>
          <TextInput
            disabled={isDisableOrderDates}
            label={<FormattedMessage id="process > field due date" />}
            excludeDates={dueDate ? [dueDate] : []}
            min={moment()}
            type="date"
            value={dueDate}
            placeholder={intl.formatMessage({
              id: "process > placeholder select date",
            })}
            onChange={(value) => {
              setShouldBlockNavigation(true);
              onValueChangedSpot(spotIndex, {
                field: "due_date",
                value,
              });
            }}
            error={get(validationState, "due_date", "")}
          />
        </Col>
        <Col className={bem.e("col-field")}>
          <Dropdown
            name="spot_type"
            label={
              <span>
                <FormattedMessage id="process > field spot type" />
                <Asterisk>*</Asterisk>
              </span>
            }
            placeholder={props.intl.formatMessage({
              id: "process > placeholder select spot type",
            })}
            value={{
              label: get(spot, "spot_type", ""),
              value: get(spot, "spot_type", ""),
            }}
            disabled={disabled}
            onChange={(event) => {
              onValueChangedSpot(spotIndex, {
                field: "spot_type",
                value: get(event, "value", ""),
              });
            }}
            options={spotTypes}
          />
          <div className="alert-danger">
            {get(validationState, "spot_type", "")}
          </div>
        </Col>
        {isWOTrafficEnabled && showLineNumber && (
          <Col className={bem.e("col-field")}>
            <Label>
              <FormattedMessage id="process > field line #" />
              <Asterisk>*</Asterisk>
            </Label>
            <CustomMultiSelectDropdown
              name="line_hashes"
              placeholder={props.intl.formatMessage({
                id: "process > placeholder select line #",
              })}
              value={lineHashes}
              onChange={(selectedValues) => {
                onValueChangedSpot(spotIndex, {
                  field: "line_hashes",
                  value: selectedValues,
                });
              }}
              isMulti={true}
              disabled={disabled}
              options={availableLineNumbers.map((lineHash) => {
                return {
                  label: lineHash,
                  value: lineHash,
                };
              })}
            />
            <div className="alert-danger">
              {get(validationState, "line_hashes", "")}
            </div>
          </Col>
        )}
        {isShowVoicePreference && (
          <Col className={bem.e("col-field")} xs={6}>
            <Dropdown
              name="voice_preference"
              label={<FormattedMessage id="process > field voice preference" />}
              placeholder={props.intl.formatMessage({
                id: "process > placeholder select",
              })}
              value={
                get(spot, "voice_preference", "")
                  ? {
                      label: intl.formatMessage({
                        id: `process > ${get(spot, "voice_preference", "")}`,
                      }),
                      value: get(spot, "voice_preference", ""),
                    }
                  : ""
              }
              disabled={disabled}
              onChange={(event) => {
                onValueChangedSpot(spotIndex, {
                  field: "voice_preference",
                  value: get(event, "value", ""),
                });
              }}
              options={voicePreferenceOptions}
            />
            <div className="alert-danger">
              {get(validationState, "voice_preference", "")}
            </div>
          </Col>
        )}
      </Row>
      {isWOTrafficEnabled && showLineNumber && !isEmpty(lineHashes) && (
        <Row>
          <Col className="selected-line-hashes">
            <Label className="label">
              <FormattedMessage id="process > field selected line #" />
            </Label>
            <div className="line-hashes-badges-container">
              {renderSelectedLineHashes(lineHashes, props)}
            </div>
          </Col>
        </Row>
      )}
      <Row className={bem.e("approvals")}>
        <Col className={bem.e("col-field")} xs={6}>
          <Label>
            <FormattedMessage id="production settings > script approvals" />
          </Label>
          <Checkbox
            checked={get(spot, "script_requires_approval", false)}
            onChange={(checked) => {
              onValueChangedSpot(spotIndex, {
                field: "script_requires_approval",
                value: checked,
              });
              if (!checked) {
                onValueChangedSpot(spotIndex, {
                  field: "writer_sends_script_to_client",
                  value: false,
                });
              }
              props.setShouldBlockNavigation(true);
            }}
            disabled={disabled}
            text={props.intl.formatMessage({
              id: "production settings > script review required",
            })}
          />
          <Checkbox
            checked={get(spot, "writer_sends_script_to_client", false)}
            onChange={(checked) => {
              onValueChangedSpot(spotIndex, {
                field: "writer_sends_script_to_client",
                value: checked,
              });
              props.setShouldBlockNavigation(true);
            }}
            disabled={!get(spot, "script_requires_approval", false) || disabled}
            text={props.intl.formatMessage({
              id: "production settings > writer sends script to Client",
            })}
          />
        </Col>
        <Col className={bem.e("col-field")} xs={6}>
          <Label>
            <FormattedMessage id="production settings > spot approvals" />
          </Label>
          <Checkbox
            checked={get(spot, "recording_requires_approval", false)}
            onChange={(checked) => {
              onValueChangedSpot(spotIndex, {
                field: "recording_requires_approval",
                value: checked,
              });
              if (!checked) {
                onValueChangedSpot(spotIndex, {
                  field: "producer_sends_spot_to_client",
                  value: false,
                });
              }
              props.setShouldBlockNavigation(true);
            }}
            disabled={disabled}
            text={props.intl.formatMessage({
              id: "production settings > spot review required",
            })}
          />
          <Checkbox
            checked={get(spot, "producer_sends_spot_to_client", false)}
            onChange={(checked) => {
              onValueChangedSpot(spotIndex, {
                field: "producer_sends_spot_to_client",
                value: checked,
              });
              props.setShouldBlockNavigation(true);
            }}
            disabled={
              !get(spot, "recording_requires_approval", false) || disabled
            }
            text={props.intl.formatMessage({
              id: "production settings > producer sends spot to Client",
            })}
          />
        </Col>
      </Row>
      <Row>
        <Col className={bem.e("col-field")}>
          {isEnableEnterprise ? (
            <TextInput
              label={<FormattedMessage id="process > field copy points" />}
              placeholder={props.intl.formatMessage({
                id: "process > placeholder type here",
              })}
              type="textarea"
              value={get(spot, "copy_points", "")}
              onChange={({ target }) => {
                onValueChangedSpot(spotIndex, {
                  field: "copy_points",
                  value: target.value,
                });
              }}
              disabled={disabled}
              error={get(validationState, "copy_points", "")}
            />
          ) : (
            <React.Fragment>
              <Label>
                <FormattedMessage id="process > field copy points/instructions" />
              </Label>
              <TextareaEvaluator
                text={setScriptField(get(spot, "copy_points", ""))}
                isEditorOnly={true}
                hideOrderLength={true}
                process={process}
                onEvaluatorCallback={(value) => {
                  onValueChangedSpot(spotIndex, {
                    field: "copy_points",
                    value,
                  });
                }}
                disabled={disabled}
                key={refreshTextEditor}
              />
            </React.Fragment>
          )}
        </Col>
      </Row>
      <Row>
        <Col className={bem.e("col-field")}>
          <RenderDraftOrFinalSwitch
            {...props}
            onValueChangedSpot={onValueChangedSpot}
          />
        </Col>
      </Row>
      {!isBookend ? (
        <Row>
          <Col className={bem.e("col-field")}>
            <TextareaEvaluator
              text={setScriptField(
                get(spot, "script_type", "draft") === "final"
                  ? get(spot, "script", "")
                  : get(spot, "draft_script", "")
              )}
              isOrderLength
              process={process}
              spot={spot}
              onEvaluatorCallback={(value) => {
                if (get(spot, "script_type", "draft") === "final") {
                  onValueChangedSpot(spotIndex, {
                    field: "script",
                    value,
                  });
                } else {
                  onValueChangedSpot(spotIndex, {
                    field: "draft_script",
                    value,
                  });
                }
              }}
              wordsPerMin={get(
                spot,
                "default_words_per_min",
                DEFAULT_WORDS_PER_MIN
              )}
              onWordsPerMinChangeCallback={(value) => {
                onValueChangedSpot(spotIndex, {
                  field: "default_words_per_min",
                  value,
                });
              }}
              title={<FormattedMessage id="process > field script" />}
              disabled={disabled}
              showInnerTitle={true}
              key={refreshTextEditor}
            />
          </Col>
        </Row>
      ) : (
        <div className={bem.e("script-fields-container")}>
          <Row>
            <Col className={bem.e("col-field")}>
              <TextareaEvaluator
                text={setScriptField(
                  get(spot, "script_type", "draft") === "final"
                    ? get(spot, "script", "")
                    : get(spot, "draft_script", "")
                )}
                isOrderLength
                process={process}
                spot={spot}
                onEvaluatorCallback={(value) => {
                  if (get(spot, "script_type", "draft") === "final") {
                    onValueChangedSpot(spotIndex, {
                      field: "script",
                      value,
                    });
                  } else {
                    onValueChangedSpot(spotIndex, {
                      field: "draft_script",
                      value,
                    });
                  }
                }}
                onLengthChangeCallback={(value, part) => {
                  const orderLength =
                    get(spot, "order_length") ||
                    get(process, "_source.order_length") ||
                    0;
                  if (value > orderLength || isNaN(value)) {
                    value = 0;
                  }
                  onValueChangedSpot(spotIndex, {
                    field: part,
                    value: value.toString(),
                  });

                  if (value !== "" && part === "length1") {
                    let length2 = parseInt(orderLength) - parseInt(value);

                    if (!length2) {
                      length2 = "00";
                    }

                    onValueChangedSpot(spotIndex, {
                      field: "length2",
                      value: length2.toString(),
                    });
                  }
                }}
                title={<FormattedMessage id="process > field script part A" />}
                disabled={disabled}
                isBookend={isBookend}
                part="a"
                showInnerTitle={true}
                error={get(validationState, "length1", "")}
                wordsPerMin={get(
                  spot,
                  "default_words_per_min",
                  DEFAULT_WORDS_PER_MIN
                )}
                onWordsPerMinChangeCallback={(value) => {
                  onValueChangedSpot(spotIndex, {
                    field: "default_words_per_min",
                    value,
                  });
                }}
                key={refreshTextEditor}
              />
            </Col>
          </Row>
          <Row>
            <Col className={bem.e("col-field")}>
              <TextareaEvaluator
                text={setScriptField(
                  get(spot, "script_type", "draft") === "final"
                    ? get(spot, "script_part_b", "")
                    : get(spot, "draft_script_part_b", "")
                )}
                isOrderLength
                process={process}
                spot={spot}
                onEvaluatorCallback={(value) => {
                  if (get(spot, "script_type", "draft") === "final") {
                    onValueChangedSpot(spotIndex, {
                      field: "script_part_b",
                      value,
                    });
                  } else {
                    onValueChangedSpot(spotIndex, {
                      field: "draft_script_part_b",
                      value,
                    });
                  }
                }}
                onLengthChangeCallback={(value, part) => {
                  const orderLength =
                    get(process, "data.fields.order_length.value") ||
                    get(process, "_source.order_length") ||
                    0;
                  if (value > orderLength || isNaN(value)) {
                    value = 0;
                  }
                  onValueChangedSpot(spotIndex, {
                    field: part,
                    value: value.toString(),
                  });

                  if (value !== "" && part === "length2") {
                    let length1 = parseInt(orderLength) - parseInt(value);

                    if (!length1) {
                      length1 = "00";
                    }

                    onValueChangedSpot(spotIndex, {
                      field: "length1",
                      value: length1.toString(),
                    });
                  }
                }}
                title={<FormattedMessage id="process > field script part B" />}
                disabled={disabled}
                isBookend={isBookend}
                part="b"
                showInnerTitle={true}
                error={get(validationState, "length2", "")}
                wordsPerMin={get(
                  spot,
                  "default_words_per_min_b",
                  DEFAULT_WORDS_PER_MIN
                )}
                onWordsPerMinChangeCallback={(value) => {
                  onValueChangedSpot(spotIndex, {
                    field: "default_words_per_min_b",
                    value,
                  });
                }}
                key={refreshTextEditor}
              />
            </Col>
          </Row>
        </div>
      )}
      {get(spot, "script_type", "draft") === "final" && producerAssignsVo && (
        <Row>
          <Col className={bem.e("col-field")}>
            <TextInput
              label={
                <span>
                  <FormattedMessage id="process > field voice over instructions" />
                  <Asterisk>*</Asterisk>
                </span>
              }
              type="textarea"
              placeholder={intl.formatMessage({
                id: "process > placeholder type here",
              })}
              value={spot.voice_over_instructions || ""}
              onChange={({ target }) => {
                if (setShouldBlockNavigation) setShouldBlockNavigation(true);
                onValueChangedSpot(spotIndex, {
                  field: "voice_over_instructions",
                  value: target.value,
                });
              }}
              error={get(validationState, "voice_over_instructions", "")}
            />
          </Col>
        </Row>
      )}

      <Row>
        <Col className={bem.e("col-field")}>
          <Label>
            <FormattedMessage id="process > field assets" />
          </Label>

          <div className={bem.e("creative-upload-files")}>
            <UploadFiles
              attachText={
                isEnableEnterprise
                  ? props.intl.formatMessage({
                      id: "process > attach files",
                    })
                  : props.intl.formatMessage({
                      id: "process > upload assets",
                    })
              }
              actions={["view", "download"]}
              acceptMultiple={true}
              files={get(spot, "files", [])}
              isHideFilesDragArea={true}
              onUploadedFiles={(value) => {
                const newValue = map(value, (item) => ({
                  ...item,
                  audit: {
                    modified_at: `${moment()
                      .format("MM/DD/YYYY")
                      .toString()} - ${moment()
                      .format("h:mm A")
                      .toString()}`,
                    modified_by: user.name,
                  },
                }));
                onValueChangedSpot(spotIndex, {
                  field: "files",
                  value: [...newValue, ...get(spot, "files", [])],
                });
              }}
              onDeleted={(value) => {
                onValueChangedSpot(spotIndex, {
                  field: "files",
                  value,
                });
              }}
              disabled={disabled}
              className={bem.e("upload-files")}
            >
              <FormattedMessage id="process > button upload" />
            </UploadFiles>
          </div>
          <HandleAssets
            files={get(spot, "files", [])}
            onUploadedFiles={(value) => {
              onValueChangedSpot(spotIndex, {
                field: "files",
                value,
              });
            }}
            onDeleted={(value) => {
              onValueChangedSpot(spotIndex, {
                field: "files",
                value,
              });
            }}
          />
        </Col>
      </Row>
      {isShowAddButton && (
        <>
          {isShowComment && (
            <CommentToChat
              step={step}
              process={process}
              valueChanged={valueChanged}
              template={template}
              user={user}
              validationState={validationState}
              setIsFieldsChanged={setIsFieldsChanged}
            />
          )}
          <div className={bem.e("spot-buttons")}>
            <div
              className={bem.e("add-rotation-provided")}
              id="add-rotation-button"
            >
              <Button
                className={classnames(bem.e("add-button"), "btn-radius")}
                outline
                color="blue"
                onClick={() =>
                  onClickAddSpot({
                    contract_status: "Production Needed",
                  })
                }
              >
                <span className={bem.e("add-icon")}>
                  <PlusSmallIcon />
                </span>
                <FormattedMessage id="process > button add to rotation" />
              </Button>
            </div>
          </div>
        </>
      )}
      {isShowRotationChar && (
        <Row>
          <Col>
            <RotationChart
              valueChanged={props.valueChanged}
              step={step}
              process={process}
              template={template}
              user={user}
              validationState={allValidationState}
              isView={false}
              setShouldBlockNavigation={setShouldBlockNavigation}
            />
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
}
function RenderSpotDetailForProductionComplete(props) {
  const {
    onValueChangedSpot,
    spot,
    spotIndex,
    auth,
    disabled,
    validationState,
    onClickAddSpot,
    isEdit,
    process,
    isShowAddButton,
    isShowDeleteSpotButton,
    onClickDeleteSpot,
    allValidationState,
    step,
    template,
    user,
    setShouldBlockNavigation,
    intl,
    valueChanged,
    orderTitle,
    isShowRotationChar,
    isShowComment,
    setIsFieldsChanged,
  } = props;

  let dueDateField = step.fields.find((f) => f.field === "due_date");

  let contractStatusField = step.fields.find(
    (f) => f.field === "contract_status"
  );

  let orderLengthField = step.fields.find((f) => f.field === "order_length");
  let orderLengthCustomField = step.fields.find(
    (f) => f.field === "order_length_custom"
  );

  const refreshTextEditor = get(process, "data.fields.refreshed_at.value", 0);
  const isEnableEnterprise = get(auth, "info.production_multimarket", false);
  const isWOTrafficEnabled = get(auth, "info.enable_wo_traffic", false);
  const availableLineNumbers = get(
    process,
    "data.fields.available_line_numbers.value",
    []
  );
  const rootTemplateKey = get(process, "data.fields.template_key.value", "");
  const spotInfo = get(process, "data.fields.spot_info.value", []);
  // only update root data for first provided spot
  const spotIndexForProductionProvided = spotInfo.findIndex(
    (item) =>
      item.template_key === "production_provided" &&
      get(process, "data.fields.key.value") === item.key
  );

  const isUpdateProcessData =
    (!get(process, "data.fields.order_title.value") && spotIndex === 0) ||
    spotIndexForProductionProvided === spotIndex;

  const showLineNumber = !isEmpty(availableLineNumbers);
  const lineHashes = get(spot, "line_hashes", []);
  const isBookend = get(spot, "spot_type", "Standard") === "Book-ends";
  const isCOOPOrder = get(process, `data.fields.is_coop_order.value`, false);

  const contractStatus = get(spot, "contract_status", "");
  const orderLengthCustom = get(spot, "order_length_custom", false);
  const orderLength = get(spot, "order_length", "");
  const dueDate = get(spot, "due_date", "");
  const orderLengthDisplay = () => {
    if (orderLengthCustom && orderLength) {
      const formatted = moment
        .utc(parseInt(orderLength) * 1000)
        .format("mm:ss");
      return formatted;
    }
    if (orderLength) {
      return `${orderLength} ${intl.formatMessage({
        id: "process > title seconds",
      })}`;
    }
    return intl.formatMessage({ id: "process > none" });
  };
  const isDisableOrderDates = disabled;

  if (!isEdit) {
    return (
      <React.Fragment>
        <div className={bem.e("spot-title")}>
          <FormattedMessage id="process > spot" /> {spotIndex + 1}
        </div>
        <Row>
          <Col xs={6} className={bem.e("col-view-field")}>
            <Label className={bem.e("order-view-label")}>
              <FormattedMessage id={`process > field title`} />
            </Label>
            <span className={bem.e("order-view-value")}>
              {get(spot, "title", "") ? (
                get(spot, "title", "")
              ) : (
                <FormattedMessage id="process > none" />
              )}
            </span>
          </Col>
          <Col xs={6} className={bem.e("col-view-field")}>
            <Label className={bem.e("order-view-label")}>
              <FormattedMessage id="process > field isci / ad id" />
            </Label>
            <span className={bem.e("order-view-value")}>
              {get(spot, "isci", "") ? (
                get(spot, "isci", "")
              ) : (
                <FormattedMessage id="process > none" />
              )}
            </span>
          </Col>
        </Row>
        <Row>
          <Col xs={6} className={bem.e("col-view-field")}>
            <Label className={bem.e("order-view-label")}>
              <FormattedMessage id="process > field production type" />
            </Label>
            <span className={bem.e("order-view-value")}>
              {contractStatus ? (
                contractStatus
              ) : (
                <FormattedMessage id="process > none" />
              )}
            </span>
          </Col>
          <Col className={bem.e("col-view-field")}>
            <Label className={bem.e("order-view-label")}>
              <FormattedMessage id="process > field spot length" />
            </Label>
            <span className={bem.e("order-view-value")}>
              {orderLengthDisplay()}
            </span>
          </Col>
        </Row>
        <Row>
          <Col className={bem.e("col-view-field")}>
            <Label className={bem.e("order-view-label")}>
              <FormattedMessage id="process > field due date" />
            </Label>
            <span className={bem.e("order-view-value")}>
              {dueDate ? formatDisplayDate(dueDate) : ""}
            </span>
          </Col>
          <Col className={bem.e("col-view-field")}>
            <Label className={bem.e("order-view-label")}>
              <FormattedMessage id="process > field spot type" />
            </Label>
            <span className={bem.e("order-view-value")}>
              {get(spot, "spot_type", "") ? (
                get(spot, "spot_type", "")
              ) : (
                <FormattedMessage id="process > none" />
              )}
            </span>
          </Col>
          {isWOTrafficEnabled && showLineNumber && (
            <Col className={bem.e("col-view-field")}>
              <Label className={bem.e("order-view-label")}>
                <FormattedMessage id="process > field line #" />
              </Label>
              {renderSelectedLineHashesView(lineHashes, availableLineNumbers)}
            </Col>
          )}
        </Row>
        {isWOTrafficEnabled && showLineNumber && !isEmpty(lineHashes) && (
          <Row>
            <Col className="selected-line-hashes">
              <Label>
                <FormattedMessage id="process > field selected line #" />
              </Label>
              <div className="line-hashes-badges-container">
                {renderSelectedLineHashes(lineHashes, props)}
              </div>
            </Col>
          </Row>
        )}
        {isCOOPOrder && (
          <Row>
            <Col className={bem.e("col-view-field")}>
              <Label className={bem.e("order-view-label")}>
                <FormattedMessage id="process > provided co-op script" />
              </Label>
              <TextareaEvaluator
                text={setScriptField(get(spot, "provided_coop_script", ""))}
                process={process}
                spot={spot}
                onEvaluatorCallback={() => {}}
                title={
                  <FormattedMessage id="process > provided co-op script" />
                }
                disabled={disabled}
                showInnerTitle={true}
                hideHelperText={true}
                isShowSlider={false}
                readOnly={true}
                key={refreshTextEditor}
              />
            </Col>
          </Row>
        )}

        <Row>
          <Col className={bem.e("col-upload-field")}>
            <Label>
              <FormattedMessage id="process > field provided audio" />
            </Label>
            <UploadFile
              mode={"edit"}
              process={process}
              onUploaded={(value) => {}}
              attachText={props.intl.formatMessage({
                id: "process > field upload audio",
              })}
              disabled={true}
              isDisabledDelete={true}
              onDeleted={() => {}}
              isCheckMimesByExt={true}
              extensions={[
                "mp3",
                "wav",
                "aiff",
                "flac",
                "aac",
                "alac",
                "m4a",
                "mp4",
              ]}
              url={get(spot, "recording_path", "")}
              actions={["download"]}
              positionActions="bottom"
              auditInfo={get(spot, "recording_audit", "")}
              isShowFileNameInEditMode={true}
              isBookend={isBookend}
              path={isBookend ? "a" : ""}
              hideSpotLength={true}
            >
              <FormattedMessage id="process > button upload" />
            </UploadFile>
            {isBookend && (
              <React.Fragment>
                <div style={{ marginTop: "20px" }}>
                  <UploadFile
                    mode={"edit"}
                    process={process}
                    onUploaded={(value) => {}}
                    attachText={props.intl.formatMessage({
                      id: "process > field upload audio",
                    })}
                    disabled={true}
                    isDisabledDelete={true}
                    onDeleted={() => {}}
                    isCheckMimesByExt={true}
                    extensions={[
                      "mp3",
                      "wav",
                      "aiff",
                      "flac",
                      "aac",
                      "alac",
                      "m4a",
                      "mp4",
                    ]}
                    url={get(spot, "recording_path_b", "")}
                    actions={["download"]}
                    positionActions="bottom"
                    auditInfo={get(spot, "recording_path_b_audit", "")}
                    isShowFileNameInEditMode={true}
                    isBookend={isBookend}
                    path={isBookend ? "b" : ""}
                    hideSpotLength={true}
                  >
                    <FormattedMessage id="process > button upload" />
                  </UploadFile>
                </div>
              </React.Fragment>
            )}
          </Col>
        </Row>
        {isShowRotationChar && (
          <Row>
            <Col>
              <RotationChart
                valueChanged={props.valueChanged}
                step={step}
                process={process}
                template={template}
                user={user}
                validationState={allValidationState}
                isView={true}
              />
            </Col>
          </Row>
        )}
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <div className={bem.e("spot-title")}>
        <FormattedMessage id="process > spot" /> {spotIndex + 1}
        {isShowDeleteSpotButton && (
          <div
            className={bem.e("delete-spot-button")}
            onClick={() => onClickDeleteSpot(spotIndex)}
          >
            <FormattedMessage id="process > button delete" />
          </div>
        )}
      </div>
      <Row className={bem.e("spot-details-provided")}>
        <Col xs={6} className={bem.e("col-field")}>
          <TextInput
            label={
              <span>
                <FormattedMessage id={`process > field title`} />
                <Asterisk>*</Asterisk>
              </span>
            }
            placeholder={
              isEnableEnterprise
                ? props.intl.formatMessage({
                    id: "process > placeholder enter title",
                  })
                : props.intl.formatMessage({
                    id: "process > placeholder enter spot title",
                  })
            }
            value={get(spot, "title", "")}
            onChange={({ target }) => {
              onValueChangedSpot(spotIndex, {
                field: "title",
                value: target.value,
              });
              if (isUpdateProcessData) {
                valueChanged(orderTitle, process, template, user, {
                  value: target.value,
                  orderTitle,
                });
              }
            }}
            disabled={disabled}
          />
          <div className="alert-danger">
            {get(validationState, "title", "")}
          </div>
        </Col>
        <Col className={bem.e("col-field")}>
          <TextInput
            label={<FormattedMessage id="process > field isci / ad id" />}
            placeholder={props.intl.formatMessage({
              id: "process > placeholder enter number",
            })}
            value={get(spot, "isci", "")}
            onChange={({ target }) => {
              onValueChangedSpot(spotIndex, {
                field: "isci",
                value: target.value,
              });
            }}
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row>
        {contractStatus === "Production Needed" ? (
          <Col xs={6} className={bem.e("col-view-field")}>
            <Label className={bem.e("order-view-label")}>
              <FormattedMessage id="process > field production type" />
            </Label>
            <span className={bem.e("order-view-value")}>
              {contractStatus ? (
                contractStatus
              ) : (
                <FormattedMessage id="process > none" />
              )}
            </span>
          </Col>
        ) : (
          <Col xs={6} className={bem.e("col-field")}>
            <Dropdown
              name="contract_status"
              options={productionTypeOptions}
              label={
                <span>
                  {contractStatusField.title}
                  {contractStatusField.mandatory && <Asterisk>*</Asterisk>}
                </span>
              }
              placeholder={intl.formatMessage({
                id: "process > placeholder select",
              })}
              value={
                contractStatus
                  ? {
                      label: contractStatus,
                      value: contractStatus,
                    }
                  : ""
              }
              onChange={(event) => {
                if (rootTemplateKey === "production_provided") {
                  // any spot change to need tags. Update root level data to show producer tab
                  if (
                    get(event, "value", "") ===
                    "Production Provided - Needs tags"
                  ) {
                    valueChanged(contractStatusField, process, template, user, {
                      value: get(event, "value", ""),
                    });
                  }
                }
                onValueChangedSpot(spotIndex, {
                  field: "contract_status",
                  value: get(event, "value", ""),
                });
                setShouldBlockNavigation(true);
              }}
              disabled={disabled}
              error={get(validationState, "contract_status", "")}
            />
          </Col>
        )}
        <Col className={bem.e("col-field")}>
          <DropdownLength
            isClearable={true}
            name="order_length"
            {...orderLengthField.props}
            label={
              <span>
                {orderLengthField.title}
                {orderLengthField.mandatory && <Asterisk>*</Asterisk>}
              </span>
            }
            placeholder={intl.formatMessage({
              id: "process > placeholder select",
            })}
            value={
              orderLength
                ? {
                    label: orderLength,
                    value: orderLength,
                  }
                : ""
            }
            onChange={(value, isCustom) => {
              if (isUpdateProcessData) {
                valueChanged(orderLengthField, process, template, user, {
                  value: value,
                });
                valueChanged(orderLengthCustomField, process, template, user, {
                  value: isCustom,
                });
              }
              onValueChangedSpot(spotIndex, {
                field: "order_length_custom",
                value: isCustom,
              });
              onValueChangedSpot(spotIndex, {
                field: "order_length",
                value: value,
              });
              setShouldBlockNavigation(true);
            }}
            isCustom={get(spot, "order_length_custom", false)}
            disabled={disabled}
            error={get(validationState, "order_length", "")}
          />
        </Col>
      </Row>
      <Row>
        <Col className={bem.e("col-field")}>
          <TextInput
            disabled={isDisableOrderDates}
            label={<FormattedMessage id="process > field due date" />}
            excludeDates={dueDate ? [dueDate] : []}
            min={moment()}
            type="date"
            value={dueDate}
            placeholder={intl.formatMessage({
              id: "process > placeholder select date",
            })}
            onChange={(value) => {
              setShouldBlockNavigation(true);
              if (isUpdateProcessData) {
                valueChanged(dueDateField, process, template, user, {
                  value: value,
                });
              }
              onValueChangedSpot(spotIndex, {
                field: "due_date",
                value,
              });
            }}
            error={get(validationState, "due_date", "")}
          />
        </Col>
        <Col className={bem.e("col-field")}>
          <Dropdown
            name="spot_type"
            label={
              <span>
                <FormattedMessage id="process > field spot type" />
                <Asterisk>*</Asterisk>
              </span>
            }
            placeholder={props.intl.formatMessage({
              id: "process > placeholder select spot type",
            })}
            value={{
              label: get(spot, "spot_type", ""),
              value: get(spot, "spot_type", ""),
            }}
            onChange={(event) => {
              onValueChangedSpot(spotIndex, {
                field: "spot_type",
                value: get(event, "value", ""),
              });
            }}
            disabled={disabled}
            options={spotTypes}
          />
          <div className="alert-danger">
            {get(validationState, "spot_type", "")}
          </div>
        </Col>
        {isWOTrafficEnabled && showLineNumber && (
          <Col className={bem.e("col-field")}>
            <Label>
              <FormattedMessage id="process > field line #" />
              <Asterisk>*</Asterisk>
            </Label>
            <CustomMultiSelectDropdown
              name="line_hashes"
              placeholder={props.intl.formatMessage({
                id: "process > placeholder select line #",
              })}
              value={lineHashes}
              onChange={(selectedValues) => {
                onValueChangedSpot(spotIndex, {
                  field: "line_hashes",
                  value: selectedValues,
                });
              }}
              isMulti={true}
              disabled={disabled}
              options={availableLineNumbers.map((lineHash) => {
                return {
                  label: lineHash,
                  value: lineHash,
                };
              })}
            />
            <div className="alert-danger">
              {get(validationState, "line_hashes", "")}
            </div>
          </Col>
        )}
      </Row>
      {isWOTrafficEnabled && showLineNumber && !isEmpty(lineHashes) && (
        <Row>
          <Col className="selected-line-hashes">
            <Label className="label">
              <FormattedMessage id="process > field selected line #" />
            </Label>
            <div className="line-hashes-badges-container">
              {renderSelectedLineHashes(lineHashes, props)}
            </div>
          </Col>
        </Row>
      )}
      {isCOOPOrder && (
        <Row className={bem.e("provided-coop-script")}>
          <Col className={bem.e("col-field")}>
            <TextareaEvaluator
              text={setScriptField(get(spot, "provided_coop_script", ""))}
              process={process}
              spot={spot}
              onEvaluatorCallback={(value) => {
                onValueChangedSpot(spotIndex, {
                  field: "provided_coop_script",
                  value,
                });
              }}
              title={<FormattedMessage id="process > provided co-op script" />}
              disabled={disabled}
              showInnerTitle={true}
              hideHelperText={true}
              isShowSlider={false}
              key={refreshTextEditor}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col className={bem.e("col-upload-field")}>
          <Label>
            <FormattedMessage id="process > field provided audio" />
          </Label>
          <div className={bem.e("creative-upload-file")}>
            <UploadFile
              mode={"edit"}
              process={process}
              onUploaded={(value) => {
                onValueChangedSpot(spotIndex, {
                  field: "recording_path",
                  value: get(value, "path"),
                });
              }}
              attachText={props.intl.formatMessage({
                id: "process > field upload audio",
              })}
              disabled={disabled}
              onDeleted={() => {
                onValueChangedSpot(spotIndex, {
                  field: "recording_path",
                  value: "",
                });
              }}
              isCheckMimesByExt={true}
              extensions={[
                "mp3",
                "wav",
                "aiff",
                "flac",
                "aac",
                "alac",
                "m4a",
                "mp4",
              ]}
              url={get(spot, "recording_path", "")}
              actions={["download"]}
              positionActions="bottom"
              auditInfo={get(spot, "recording_audit", "")}
              isShowFileNameInEditMode={true}
              isBookend={isBookend}
              path={isBookend ? "a" : ""}
              hideSpotLength={true}
            >
              <FormattedMessage id="process > button upload" />
            </UploadFile>
          </div>
          {isBookend && (
            <div
              className={bem.e("creative-upload-file")}
              style={{ marginTop: "15px" }}
            >
              <UploadFile
                mode={"edit"}
                process={process}
                onUploaded={(value) => {
                  onValueChangedSpot(spotIndex, {
                    field: "recording_path_b",
                    value: get(value, "path"),
                  });
                }}
                attachText={props.intl.formatMessage({
                  id: "process > field upload audio",
                })}
                disabled={disabled}
                onDeleted={() => {
                  onValueChangedSpot(spotIndex, {
                    field: "recording_path_b",
                    value: "",
                  });
                }}
                isCheckMimesByExt={true}
                extensions={[
                  "mp3",
                  "wav",
                  "aiff",
                  "flac",
                  "aac",
                  "alac",
                  "m4a",
                  "mp4",
                ]}
                url={get(spot, "recording_path_b", "")}
                actions={["download"]}
                positionActions="bottom"
                auditInfo={get(spot, "recording_path_b_audit", "")}
                isShowFileNameInEditMode={true}
                isBookend={isBookend}
                path={isBookend ? "b" : ""}
                hideSpotLength={true}
              >
                <FormattedMessage id="process > button upload" />
              </UploadFile>
            </div>
          )}
        </Col>
      </Row>
      {isShowAddButton && (
        <>
          {isShowComment && (
            <CommentToChat
              step={step}
              process={process}
              valueChanged={valueChanged}
              template={template}
              user={user}
              validationState={validationState}
              setIsFieldsChanged={setIsFieldsChanged}
            />
          )}
          <div className={bem.e("spot-buttons")}>
            <div
              className={bem.e("add-rotation-provided")}
              id="add-rotation-button"
            >
              <Button
                className={classnames(bem.e("add-button"), "btn-radius")}
                outline
                color="blue"
                // disabled={isLimitedSpots}
                onClick={() =>
                  onClickAddSpot({
                    contract_status: "Production Complete",
                  })
                }
              >
                <span className={bem.e("add-icon")}>
                  <PlusSmallIcon />
                </span>
                <FormattedMessage id="process > button add to rotation" />
              </Button>
            </div>
          </div>
        </>
      )}
      {isShowRotationChar && (
        <Row>
          <Col>
            <RotationChart
              valueChanged={props.valueChanged}
              step={step}
              process={process}
              template={template}
              user={user}
              validationState={allValidationState}
              isView={false}
              setShouldBlockNavigation={setShouldBlockNavigation}
            />
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
}
function RenderSpotDetailForProductionNeededTags(props) {
  const {
    onValueChangedSpot,
    spot,
    spotIndex,
    auth,
    disabled,
    validationState,
    onClickAddSpot,
    isEdit,
    process,
    isShowAddButton,
    onUseAudioForAllSpots,
    allValidationState,
    isShowDeleteSpotButton,
    onClickDeleteSpot,
    template,
    user,
    step,
    isShowRotationChar,
    setShouldBlockNavigation,
    intl,
    valueChanged,
    orderTitle,
    isShowComment,
    setIsFieldsChanged,
  } = props;

  let dueDateField = step.fields.find((f) => f.field === "due_date");
  let contractStatusField = step.fields.find(
    (f) => f.field === "contract_status"
  );

  let orderLengthField = step.fields.find((f) => f.field === "order_length");
  let orderLengthCustomField = step.fields.find(
    (f) => f.field === "order_length_custom"
  );
  const rootTemplateKey = get(process, "data.fields.template_key.value", "");
  const spotInfo = get(process, "data.fields.spot_info.value", []);

  //
  const refreshTextEditor = get(process, "data.fields.refreshed_at.value", 0);
  const isEnableEnterprise = get(auth, "info.production_multimarket", false);
  const isCOOPOrder = get(process, `data.fields.is_coop_order.value`, false);

  const spotIndexWithUseAudioForAll = findIndex(
    get(process, "data.fields.spot_info.value", []),
    (item) => item.is_audio_for_all
  );
  let isHiddenUploadAudio = false;

  const isWOTrafficEnabled = get(auth, "info.enable_wo_traffic", false);
  const availableLineNumbers = get(
    process,
    "data.fields.available_line_numbers.value",
    []
  );
  // only update root data for first provided spot
  const spotIndexForProductionProvided = spotInfo.findIndex(
    (item) =>
      item.template_key === "production_provided" &&
      get(process, "data.fields.key.value") === item.key
  );

  const isUpdateProcessData =
    (!get(process, "data.fields.order_title.value") && spotIndex === 0) ||
    spotIndexForProductionProvided === spotIndex;

  const showLineNumber = !isEmpty(availableLineNumbers);
  const lineHashes = get(spot, "line_hashes", []);

  const contractStatus = get(spot, "contract_status", "");
  const orderLengthCustom = get(spot, "order_length_custom", false);
  const orderLength = get(spot, "order_length", "");
  const dueDate = get(spot, "due_date", "");
  const orderLengthDisplay = () => {
    if (orderLengthCustom && orderLength) {
      const formatted = moment
        .utc(parseInt(orderLength) * 1000)
        .format("mm:ss");
      return formatted;
    }
    if (orderLength) {
      return `${orderLength} ${intl.formatMessage({
        id: "process > title seconds",
      })}`;
    }
    return intl.formatMessage({ id: "process > none" });
  };
  const isDisableOrderDates = disabled;

  if (
    spotIndexWithUseAudioForAll !== -1 &&
    spotIndex !== spotIndexWithUseAudioForAll
  ) {
    isHiddenUploadAudio = true;
  }
  if (!isEdit) {
    return (
      <React.Fragment>
        <div className={bem.e("spot-title")}>
          <FormattedMessage id="process > spot" /> {spotIndex + 1}
          {isShowDeleteSpotButton && (
            <div
              className={bem.e("delete-spot-button")}
              onClick={() => onClickDeleteSpot(spotIndex)}
            >
              <FormattedMessage id="process > button delete" />
            </div>
          )}
        </div>
        <Row>
          <Col xs={6} className={bem.e("col-view-field")}>
            <Label className={bem.e("order-view-label")}>
              <FormattedMessage id={`process > field title`} />
            </Label>
            <span className={bem.e("order-view-value")}>
              {get(spot, "title", "") ? (
                get(spot, "title", "")
              ) : (
                <FormattedMessage id="process > none" />
              )}
            </span>
          </Col>
          <Col xs={6} className={bem.e("col-view-field")}>
            <Label className={bem.e("order-view-label")}>
              <FormattedMessage id="process > field isci / ad id" />
            </Label>
            <span className={bem.e("order-view-value")}>
              {get(spot, "isci", "") ? (
                get(spot, "isci", "")
              ) : (
                <FormattedMessage id="process > none" />
              )}
            </span>
          </Col>
        </Row>
        <Row>
          <Col xs={6} className={bem.e("col-view-field")}>
            <Label className={bem.e("order-view-label")}>
              <FormattedMessage id="process > field production type" />
            </Label>
            <span className={bem.e("order-view-value")}>
              {contractStatus ? (
                contractStatus
              ) : (
                <FormattedMessage id="process > none" />
              )}
            </span>
          </Col>
          <Col className={bem.e("col-view-field")}>
            <Label className={bem.e("order-view-label")}>
              <FormattedMessage id="process > field spot length" />
            </Label>
            <span className={bem.e("order-view-value")}>
              {orderLengthDisplay()}
            </span>
          </Col>
        </Row>
        <Row>
          <Col className={bem.e("col-view-field")}>
            <Label className={bem.e("order-view-label")}>
              <FormattedMessage id="process > field due date" />
            </Label>
            <span className={bem.e("order-view-value")}>
              {dueDate ? formatDisplayDate(dueDate) : ""}
            </span>
          </Col>
          <Col className={bem.e("col-view-field")}>
            <Label className={bem.e("order-view-label")}>
              <FormattedMessage id="process > field spot type" />
            </Label>
            <span className={bem.e("order-view-value")}>
              {get(spot, "spot_type", "") ? (
                get(spot, "spot_type", "")
              ) : (
                <FormattedMessage id="process > none" />
              )}
            </span>
          </Col>
          {isWOTrafficEnabled && showLineNumber && (
            <Col className={bem.e("col-view-field")}>
              <Label className={bem.e("order-view-label")}>
                <FormattedMessage id="process > field line #" />
              </Label>
              {renderSelectedLineHashesView(lineHashes, availableLineNumbers)}
            </Col>
          )}
        </Row>
        {isWOTrafficEnabled && showLineNumber && !isEmpty(lineHashes) && (
          <Row>
            <Col className="selected-line-hashes">
              <Label>
                <FormattedMessage id="process > field selected line #" />
              </Label>
              <div className="line-hashes-badges-container">
                {renderSelectedLineHashes(lineHashes, props)}
              </div>
            </Col>
          </Row>
        )}
        {isCOOPOrder && (
          <Row>
            <Col className={bem.e("col-view-field")}>
              <Label className={bem.e("order-view-label")}>
                <FormattedMessage id="process > provided co-op script" />
              </Label>
              <TextareaEvaluator
                text={setScriptField(get(spot, "provided_coop_script", ""))}
                process={process}
                spot={spot}
                onEvaluatorCallback={() => {}}
                title={
                  <FormattedMessage id="process > provided co-op script" />
                }
                disabled={disabled}
                showInnerTitle={true}
                hideHelperText={true}
                isShowSlider={false}
                readOnly={true}
                key={refreshTextEditor}
              />
            </Col>
          </Row>
        )}
        {!isHiddenUploadAudio && (
          <Row>
            <Col
              className={classnames(
                bem.e("col-upload-field"),
                bem.e("col-upload-field-tags")
              )}
            >
              <Label>
                <FormattedMessage id="process > field provided audio" />
              </Label>
              <UploadFile
                mode={"edit"}
                process={process}
                onUploaded={(value) => {}}
                attachText={props.intl.formatMessage({
                  id: "process > field upload audio",
                })}
                disabled={true}
                isDisabledDelete={true}
                onDeleted={() => {}}
                isCheckMimesByExt={true}
                extensions={[
                  "mp3",
                  "wav",
                  "aiff",
                  "flac",
                  "aac",
                  "alac",
                  "m4a",
                  "mp4",
                ]}
                url={get(spot, "recording_path", "")}
                actions={["download"]}
                positionActions="bottom"
                auditInfo={get(spot, "recording_audit", "")}
                isShowFileNameInEditMode={true}
              >
                <FormattedMessage id="process > button upload" />
              </UploadFile>
              <div className={bem.e("checkbox-use-audio")}>
                <Checkbox
                  checked={get(spot, "is_audio_for_all", false)}
                  onChange={() => {}}
                  text={
                    <FormattedMessage id="process > field use this audio for all spots" />
                  }
                />
              </div>
            </Col>
          </Row>
        )}

        <Row>
          <Col xs={12} className={bem.e("col-view-field")}>
            <Label className={bem.e("order-view-label")}>
              <FormattedMessage id="process > field tag" />
            </Label>
            <span className={bem.e("order-view-value")}>
              {get(spot, "tag", "") ? (
                get(spot, "tag", "")
              ) : (
                <FormattedMessage id="process > none" />
              )}
            </span>
          </Col>
        </Row>
        {isShowRotationChar && (
          <Row>
            <Col>
              <RotationChart
                valueChanged={props.valueChanged}
                step={step}
                process={process}
                template={template}
                user={user}
                validationState={allValidationState}
                isView={true}
              />
            </Col>
          </Row>
        )}
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <div className={bem.e("spot-title")}>
        <FormattedMessage id="process > spot" /> {spotIndex + 1}
        {isShowDeleteSpotButton && (
          <div
            className={bem.e("delete-spot-button")}
            onClick={() => onClickDeleteSpot(spotIndex)}
          >
            <FormattedMessage id="process > button delete" />
          </div>
        )}
      </div>
      <Row className={bem.e("spot-details-provided")}>
        <Col xs={6} className={bem.e("col-field")}>
          <TextInput
            label={
              <span>
                <FormattedMessage id={`process > field title`} />
                <Asterisk>*</Asterisk>
              </span>
            }
            placeholder={
              isEnableEnterprise
                ? props.intl.formatMessage({
                    id: "process > placeholder enter title",
                  })
                : props.intl.formatMessage({
                    id: "process > placeholder enter spot title",
                  })
            }
            value={get(spot, "title", "")}
            onChange={({ target }) => {
              onValueChangedSpot(spotIndex, {
                field: "title",
                value: target.value,
              });
              if (isUpdateProcessData) {
                valueChanged(orderTitle, process, template, user, {
                  value: target.value,
                  orderTitle,
                });
              }
            }}
            disabled={disabled}
          />
          <div className="alert-danger">
            {get(validationState, "title", "")}
          </div>
        </Col>
        <Col className={bem.e("col-field")}>
          <TextInput
            label={<FormattedMessage id="process > field isci / ad id" />}
            placeholder={props.intl.formatMessage({
              id: "process > placeholder enter number",
            })}
            value={get(spot, "isci", "")}
            onChange={({ target }) => {
              onValueChangedSpot(spotIndex, {
                field: "isci",
                value: target.value,
              });
            }}
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row>
        {contractStatus === "Production Needed" ? (
          <Col xs={6} className={bem.e("col-view-field")}>
            <Label className={bem.e("order-view-label")}>
              <FormattedMessage id="process > field production type" />
            </Label>
            <span className={bem.e("order-view-value")}>
              {contractStatus ? (
                contractStatus
              ) : (
                <FormattedMessage id="process > none" />
              )}
            </span>
          </Col>
        ) : (
          <Col xs={6} className={bem.e("col-field")}>
            <Dropdown
              name="contract_status"
              options={productionTypeOptions}
              label={
                <span>
                  {contractStatusField.title}
                  {contractStatusField.mandatory && <Asterisk>*</Asterisk>}
                </span>
              }
              placeholder={intl.formatMessage({
                id: "process > placeholder select",
              })}
              value={
                contractStatus
                  ? {
                      label: contractStatus,
                      value: contractStatus,
                    }
                  : ""
              }
              onChange={(event) => {
                if (rootTemplateKey === "production_provided") {
                  const currentProductionNeededTagSpots = spotInfo.filter(
                    (item) =>
                      item.template_key === "production_provided" &&
                      item.contract_status ===
                        "Production Provided - Needs tags"
                  ).length;
                  if (
                    // if all spot info is updated to Production Complete. Update root data to remove the producer tab
                    get(event, "value", "") === "Production Complete" &&
                    (currentProductionNeededTagSpots === 1 ||
                      currentProductionNeededTagSpots === 0)
                  ) {
                    valueChanged(contractStatusField, process, template, user, {
                      value: get(event, "value", ""),
                    });
                  }
                }
                onValueChangedSpot(spotIndex, {
                  field: "contract_status",
                  value: get(event, "value", ""),
                });
                setShouldBlockNavigation(true);
              }}
              disabled={disabled}
              error={get(validationState, "contract_status", "")}
            />
          </Col>
        )}
        <Col className={bem.e("col-field")}>
          <DropdownLength
            isClearable={true}
            name="order_length"
            {...orderLengthField.props}
            label={
              <span>
                {orderLengthField.title}
                {orderLengthField.mandatory && <Asterisk>*</Asterisk>}
              </span>
            }
            placeholder={intl.formatMessage({
              id: "process > placeholder select",
            })}
            value={
              orderLength
                ? {
                    label: orderLength,
                    value: orderLength,
                  }
                : ""
            }
            onChange={(value, isCustom) => {
              if (isUpdateProcessData) {
                valueChanged(orderLengthField, process, template, user, {
                  value: value,
                });
                valueChanged(orderLengthCustomField, process, template, user, {
                  value: isCustom,
                });
              }
              onValueChangedSpot(spotIndex, {
                field: "order_length_custom",
                value: isCustom,
              });
              onValueChangedSpot(spotIndex, {
                field: "order_length",
                value: value,
              });
              setShouldBlockNavigation(true);
            }}
            isCustom={get(spot, "order_length_custom", false)}
            disabled={disabled}
            error={get(validationState, "order_length", "")}
          />
        </Col>
      </Row>
      <Row>
        <Col className={bem.e("col-field")}>
          <TextInput
            disabled={isDisableOrderDates}
            label={<FormattedMessage id="process > field due date" />}
            excludeDates={dueDate ? [dueDate] : []}
            min={moment()}
            type="date"
            value={dueDate}
            placeholder={intl.formatMessage({
              id: "process > placeholder select date",
            })}
            onChange={(value) => {
              setShouldBlockNavigation(true);
              if (isUpdateProcessData) {
                valueChanged(dueDateField, process, template, user, {
                  value: value,
                });
              }
              onValueChangedSpot(spotIndex, {
                field: "due_date",
                value,
              });
            }}
            error={get(validationState, "due_date", "")}
          />
        </Col>
        <Col className={bem.e("col-field")} xs={6}>
          <Label className={bem.e("order-view-label")}>
            <FormattedMessage id="process > field spot type" />
          </Label>
          <span className={bem.e("order-view-value")}>
            {get(spot, "spot_type", "") ? (
              get(spot, "spot_type", "")
            ) : (
              <FormattedMessage id="process > none" />
            )}
          </span>
        </Col>
        {isWOTrafficEnabled && showLineNumber && (
          <Col xs={6} className={bem.e("col-field")}>
            <Label>
              <FormattedMessage id="process > field line #" />
            </Label>
            <CustomMultiSelectDropdown
              name="line_hashes"
              placeholder={props.intl.formatMessage({
                id: "process > placeholder select line #",
              })}
              value={lineHashes}
              onChange={(selectedValues) => {
                onValueChangedSpot(spotIndex, {
                  field: "line_hashes",
                  value: selectedValues,
                });
              }}
              isMulti={true}
              disabled={disabled}
              options={availableLineNumbers.map((lineHash) => {
                return {
                  label: lineHash,
                  value: lineHash,
                };
              })}
            />
            <div className="alert-danger">
              {get(validationState, "line_hashes", "")}
            </div>
          </Col>
        )}
      </Row>
      {isWOTrafficEnabled && showLineNumber && !isEmpty(lineHashes) && (
        <Row>
          <Col className="selected-line-hashes">
            <Label className="label">
              <FormattedMessage id="process > field selected line #" />
            </Label>
            <div className="line-hashes-badges-container">
              {renderSelectedLineHashes(lineHashes, props)}
            </div>
          </Col>
        </Row>
      )}
      {isCOOPOrder && (
        <Row className={bem.e("provided-coop-script")}>
          <Col className={bem.e("col-field")}>
            <TextareaEvaluator
              text={setScriptField(get(spot, "provided_coop_script", ""))}
              process={process}
              spot={spot}
              onEvaluatorCallback={(value) => {
                onValueChangedSpot(spotIndex, {
                  field: "provided_coop_script",
                  value,
                });
              }}
              title={<FormattedMessage id="process > provided co-op script" />}
              disabled={disabled}
              showInnerTitle={true}
              hideHelperText={true}
              isShowSlider={false}
              key={refreshTextEditor}
            />
          </Col>
        </Row>
      )}
      {!isHiddenUploadAudio && (
        <Row>
          <Col
            className={classnames(
              bem.e("col-upload-field"),
              bem.e("col-production-needed-tags-upload-field")
            )}
          >
            <Label>
              <FormattedMessage id="process > field provided audio" />
            </Label>
            <div className={bem.e("creative-upload-file")}>
              <UploadFile
                mode={"edit"}
                process={process}
                onUploaded={(value) => {
                  onValueChangedSpot(spotIndex, {
                    field: "recording_path",
                    value: get(value, "path", ""),
                  });
                }}
                attachText={props.intl.formatMessage({
                  id: "process > field upload audio",
                })}
                disabled={disabled}
                onDeleted={() => {
                  onValueChangedSpot(spotIndex, {
                    field: "recording_path",
                    value: "",
                  });
                }}
                isCheckMimesByExt={true}
                extensions={[
                  "mp3",
                  "wav",
                  "aiff",
                  "flac",
                  "aac",
                  "alac",
                  "m4a",
                  "mp4",
                ]}
                url={get(spot, "recording_path", "")}
                actions={["download"]}
                positionActions="bottom"
                auditInfo={get(spot, "recording_audit", "")}
                isShowFileNameInEditMode={true}
              >
                <FormattedMessage id="process > button upload" />
              </UploadFile>
            </div>
            <div className={bem.e("checkbox-use-audio")}>
              <Checkbox
                checked={get(spot, "is_audio_for_all", false)}
                disabled={!get(spot, "recording_path", "")}
                onChange={() => {
                  onUseAudioForAllSpots(
                    spotIndex,
                    !get(spot, "is_audio_for_all", false)
                  );
                }}
                text={
                  <FormattedMessage id="process > field use this audio for all spots" />
                }
              />
            </div>
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={12} className={bem.e("col-field-production-needed-tags")}>
          <TextInput
            label={<FormattedMessage id="process > field tag" />}
            placeholder={props.intl.formatMessage({
              id: "process > placeholder type here",
            })}
            value={get(spot, "tag", "")}
            onChange={({ target }) => {
              onValueChangedSpot(spotIndex, {
                field: "tag",
                value: target.value,
              });
            }}
            disabled={disabled}
          />
        </Col>
      </Row>
      {isShowRotationChar && (
        <Row>
          <Col>
            <RotationChart
              valueChanged={props.valueChanged}
              step={step}
              process={process}
              template={template}
              user={user}
              validationState={allValidationState}
              isView={false}
              setShouldBlockNavigation={setShouldBlockNavigation}
            />
          </Col>
        </Row>
      )}

      {isShowAddButton && (
        <>
          {isShowComment && (
            <CommentToChat
              step={step}
              process={process}
              valueChanged={valueChanged}
              template={template}
              user={user}
              validationState={validationState}
              setIsFieldsChanged={setIsFieldsChanged}
            />
          )}
          <div className={bem.e("spot-buttons")}>
            <div
              className={bem.e("add-rotation-provided")}
              id="add-rotation-button"
            >
              <Button
                className={classnames(bem.e("add-button"), "btn-radius")}
                outline
                color="blue"
                // disabled={isLimitedSpots}
                onClick={() =>
                  onClickAddSpot({
                    contract_status: "Production Provided - Needs tags",
                  })
                }
              >
                <span className={bem.e("add-icon")}>
                  <PlusSmallIcon />
                </span>
                <FormattedMessage id="process > button add to rotation" />
              </Button>
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  );
}
function SpotDetails(props) {
  const {
    disabled,
    spot,
    valueChanged,
    template,
    user,
    auth,
    step,
    process,
    setIsFieldsChanged,
    validationState,
    spotIndex,
    isEdit,
    onClickAddSpot,
    isShowAddButton,
    isShowDeleteSpotButton,
    onClickDeleteSpot,
    totalSpots,
    allValidationState,
    setShouldBlockNavigation,
    dateFormatByServer,
    intl,
  } = props;
  const isEnableEnterprise = get(auth, "info.production_multimarket", false);
  let orderTitle = step.fields.find((f) => f.field === "order_title");
  const { width: widthScreen } = useWindowDimensions();
  const isMobile = React.useMemo(() => {
    return widthScreen < 480;
  }, [widthScreen]);

  const onValueChangedSpot = (index, { field, value }) => {
    const spotInfoField = step.fields.find((f) => f.field === "spot_info");
    const marketsField = step.fields.find((f) => f.field === "markets");
    const spotInfo = get(process, "data.fields.spot_info.value", []);
    const spot = spotInfo[index];
    if (spot) {
      let params = {
        ...spot,
        [field]: value,
      };
      if (!params.uuid) {
        params = {
          ...params,
          uuid: uuidv4(),
        };
      }
      if (field === "recording_path") {
        params = {
          ...params,
          recording_audit: {
            modified_at: `${moment()
              .format(dateFormatByServer)
              .toString()} - ${moment()
              .format("h:mm A")
              .toString()}`,
            modified_by: user.name,
          },
        };
      }
      if (field === "recording_path_b") {
        params = {
          ...params,
          recording_path_b_audit: {
            modified_at: `${moment()
              .format(dateFormatByServer)
              .toString()} - ${moment()
              .format("h:mm A")
              .toString()}`,
            modified_by: user.name,
          },
        };
      }
      spotInfo[index] = params;
      valueChanged(
        {
          ...spotInfoField,
          changing: spotInfo.map((item, i) => {
            if (index === i) return [field];
            return [];
          }),
        },
        process,
        template,
        user,
        {
          value: spotInfo,
        }
      );
      if (isEnableEnterprise) {
        let selectedMarkets = get(process, "data.fields.markets.value", []);
        selectedMarkets = map(selectedMarkets, (market) => ({
          ...market,
          spot_info: map(get(market, "spot_info", []), (spot, i) => {
            if (i === index) {
              let keys = [];
              if (isProductionNeeded) {
                keys = [
                  "cart_number",
                  "isci",
                  "recording_audit",
                  "recording_path",
                  "dubbed_at",
                  "is_dubbed",
                ];
              } else if (
                isProductionComplete ||
                isProductionProvidedNeededTags
              ) {
                keys = ["cart_number", "dubbed_at", "is_dubbed", "line_hashes"];
              }
              return {
                ...params,
                ...pick(spot, keys), // keep own for market spot
              };
            }
            return spot;
          }),
        }));
        // spotInfo
        valueChanged(marketsField, process, template, user, {
          value: selectedMarkets,
        });
      }
      setIsFieldsChanged(true);
      // the editor will callback the changes for for componentDidMount
      if (
        ["copy_points", "draft_script", "provided_coop_script"].indexOf(
          field
        ) === -1
      ) {
        setShouldBlockNavigation(true);
      }
    }
  };
  const onUseAudioForAllSpots = (spotIndex, isUse) => {
    const spotInfoField = step.fields.find((f) => f.field === "spot_info");

    const spotInfo = get(process, "data.fields.spot_info.value", []);
    const spot = spotInfo[spotIndex];
    if (spot) {
      let isUncheckedUse = false;
      if (!isUse && spot.is_audio_for_all) {
        isUncheckedUse = true;
      }
      let newSpotInfo = map(spotInfo, (item, index) => {
        if (item.contract_status !== spot.contract_status) return item;
        let isAudioForAll = item.is_audio_for_all || false;
        let recordingPath = get(item, "recording_path");
        if (isUse) {
          recordingPath = get(spot, "recording_path");
        }
        if (spotIndex === index) {
          isAudioForAll = isUse;
        } else if (isUse) {
          isAudioForAll = false;
        }
        // case: un-checked checkbox for use audio for all spot. Will remove current audio path for other spots.
        if (
          isUncheckedUse &&
          spotIndex !== index &&
          item.contract_status === spot.contract_status
        ) {
          recordingPath = "";
        }
        return {
          ...item,
          recording_path: recordingPath,
          is_audio_for_all: isAudioForAll,
        };
      });
      valueChanged(
        {
          ...spotInfoField,
          changing: spotInfo.map((item) => {
            return ["recording_path", "is_audio_for_all"];
          }),
        },
        process,
        template,
        user,
        {
          value: newSpotInfo,
        }
      );
      setIsFieldsChanged(true);
      setShouldBlockNavigation(true);
    }
  };
  const isProductionComplete =
    get(spot, "contract_status", "") === "Production Complete";
  const isProductionNeeded =
    get(spot, "contract_status", "") === "Production Needed";
  const isProductionProvidedNeededTags =
    get(spot, "contract_status", "") === "Production Provided - Needs tags";
  const spotTitle = get(spot, "title", "");
  return (
    <ShadowBox className={bem.e("box-spot-container")}>
      <SectionTitle
        className={bem.e("section-title")}
        onClick={() => {
          props.setIsOpenDetail(!props.isOpenDetail);
        }}
      >
        <div className={bem.e("creative-title")}>
          <span>
            <FormattedMessage id="process > creative details" />
          </span>
          {totalSpots > 1 && (
            <React.Fragment>
              :{" "}
              <span className={bem.e("spot-production-type-text")}>
                {props.isOpenDetail
                  ? spotTitle
                  : `${intl.formatMessage({
                      id: "process > spot",
                    })} ${spotIndex + 1}`}
              </span>
            </React.Fragment>
          )}
        </div>
        <ChevronWrapper>
          {props.isOpenDetail ? <UpIcon /> : <DownIcon />}
        </ChevronWrapper>
      </SectionTitle>
      <SectionFields
        className={classnames(
          {
            hidden: !props.isOpenDetail,
          },
          bem.e("section-creative-details")
        )}
      >
        {isProductionNeeded && (
          <RenderSpotDetailForProductionNeed
            onValueChangedSpot={onValueChangedSpot}
            spot={spot}
            spotIndex={spotIndex}
            valueChanged={valueChanged}
            orderTitle={orderTitle}
            template={template}
            user={user}
            auth={auth}
            disabled={disabled}
            intl={props.intl}
            validationState={validationState}
            onClickAddSpot={onClickAddSpot}
            isEdit={isEdit}
            process={process}
            isShowAddButton={isShowAddButton}
            allValidationState={allValidationState}
            isShowDeleteSpotButton={isShowDeleteSpotButton}
            onClickDeleteSpot={onClickDeleteSpot}
            step={step}
            setShouldBlockNavigation={setShouldBlockNavigation}
            isShowRotationChar={!isMobile}
            isShowComment={isMobile}
            setIsFieldsChanged={setIsFieldsChanged}
          />
        )}
        {isProductionComplete && (
          <RenderSpotDetailForProductionComplete
            onValueChangedSpot={onValueChangedSpot}
            spot={spot}
            spotIndex={spotIndex}
            valueChanged={valueChanged}
            orderTitle={orderTitle}
            template={template}
            user={user}
            auth={auth}
            disabled={disabled}
            intl={props.intl}
            validationState={validationState}
            onClickAddSpot={onClickAddSpot}
            isEdit={isEdit}
            step={step}
            process={process}
            isShowAddButton={isShowAddButton}
            allValidationState={allValidationState}
            isShowDeleteSpotButton={isShowDeleteSpotButton}
            onClickDeleteSpot={onClickDeleteSpot}
            isShowRotationChar={!isMobile}
            setShouldBlockNavigation={setShouldBlockNavigation}
            isShowComment={isMobile}
            setIsFieldsChanged={setIsFieldsChanged}
          />
        )}
        {isProductionProvidedNeededTags && (
          <RenderSpotDetailForProductionNeededTags
            onValueChangedSpot={onValueChangedSpot}
            onUseAudioForAllSpots={onUseAudioForAllSpots}
            spot={spot}
            spotIndex={spotIndex}
            valueChanged={valueChanged}
            orderTitle={orderTitle}
            template={template}
            user={user}
            auth={auth}
            disabled={disabled}
            intl={props.intl}
            validationState={validationState}
            onClickAddSpot={onClickAddSpot}
            isEdit={isEdit}
            step={step}
            process={process}
            isShowAddButton={isShowAddButton}
            allValidationState={allValidationState}
            isShowDeleteSpotButton={isShowDeleteSpotButton}
            onClickDeleteSpot={onClickDeleteSpot}
            isShowRotationChar={!isMobile}
            setShouldBlockNavigation={setShouldBlockNavigation}
            isShowComment={isMobile}
            setIsFieldsChanged={setIsFieldsChanged}
          />
        )}
      </SectionFields>
    </ShadowBox>
  );
}
SpotDetails.props = {
  disabled: PropTypes.bool,
  spot: PropTypes.object,
  valueChanged: PropTypes.func,
  template: PropTypes.object,
  user: PropTypes.object,
  step: PropTypes.object,
  process: PropTypes.object,
  setIsFieldsChanged: PropTypes.fund,
  validationState: PropTypes.object,
  allValidationState: PropTypes.object,
  onValueChanged: PropTypes.func,
  spotIndex: PropTypes.number,
  isShowAddButton: PropTypes.bool,
  setShouldBlockNavigation: PropTypes.func,
};
SpotDetails.defaultProps = {
  disabled: false,
  spot: {},
  valueChanged: () => {},
  template: {},
  user: {},
  step: {},
  process: {},
  setIsFieldsChanged: () => {},
  validationState: {},
  allValidationState: {},
  onValueChanged: () => {},
  spotIndex: 0,
  isShowAddButton: false,
  setShouldBlockNavigation: () => {},
};
export default SpotDetails;
