import { connect } from "react-redux";
import View from "./view";
import { injectIntl } from "react-intl";
import { compose, lifecycle, withHandlers, withState } from "recompose";
import { setOrderSectionsContest } from "store/actions/auth";
import { getClocksData, setClocksData } from "store/actions/programmings";
import { initNewProcess } from "store/actions/processes";
import {
  fetchFulfillmentSettings,
  setFetchFulfillmentSettings,
} from "store/actions/stations";
import { get, includes, mapValues, find } from "lodash";
import { setPromotionsContestJockIntroductionsText } from "store/actions/auth";
import { defaultOrderSectionsContest } from "utils/constants";
const Enhanced = compose(
  connect(
    (state) => {
      return {
        auth: state.auth,
        isLoading: get(state, "programmings.associateElements.loading"),
        clocksData: get(state, "programmings.clocksData.data", {}),
      };
    },
    {
      setOrderSectionsContest,
      getClocksData,
      initNewProcess,
      setClocksData,
      setPromotionsContestJockIntroductionsText,
      fetchFulfillmentSettings,
      setFetchFulfillmentSettings,
    }
  ),
  withState("openDetails", "setOpenDetails", {
    schedule: true,
    script: true,
  }),
  withHandlers({
    onResetFormData: ({ initNewProcess, process }) => () => {
      const resetFields = [
        "age_restrictions",
        "associated_items",
        "associated_items_ids",
        "client_name",
        "contest_rules",
        "contract_number",
        "days_of_week",
        "description",
        "documents",
        "excluded_dates",
        "jock_instructions",
        "order_end_date",
        "order_start_date",
        "order_title",
        "order_type",
        "plays",
        "plays_number",
        "prizes",
        // "prizes_status",
        "relax_restrictions_for_weeks",
        "rule_deadline",
        "rule_eligibility",
        "rule_future_eligibility",
        "rule_number_of_days",
        "salesperson",
        "scheduling_type",
        "script_after",
        "script_before",
        "theme",
        "total_available_items",
        "winner_selection",
      ];
      const fields = mapValues(process.data.fields, (item, key) => {
        if (includes(resetFields, key)) {
          if (key === "days_of_week") {
            return {
              ...item,
              value: [2, 3, 4, 5, 6],
              is_dirty: true,
            };
          }
          if (key === "scheduling_type") {
            return {
              ...item,
              value: "simple",
              is_dirty: true,
            };
          }
          return {
            ...item,
            value:
              typeof item.value === "string"
                ? ""
                : Array.isArray(item.value)
                ? []
                : null,
            is_dirty: true,
          };
        }
        return item;
      });
      initNewProcess({
        ...process,
        data: {
          ...process.data,
          fields: fields,
        },
      });
    },
    onUpdateOrder: ({ setOrderSectionsContest }) => (data) => {
      const sections = find(data, (i) => i === "schedule")
        ? data
        : ["schedule", ...data];
      setOrderSectionsContest(sections);
    },
    onClearForm: ({ onPreparePage, setIsShowClearForm }) => () => {
      setIsShowClearForm(false);
      onPreparePage();
    },
    onGetClocksData: ({ getClocksData }) => (stationKey) => {
      getClocksData({
        data: {
          station_id: stationKey,
        },
      });
    },
    onGetFulfillmentSettings: ({ fetchFulfillmentSettings }) => (
      stationKey
    ) => {
      fetchFulfillmentSettings({
        station_id: stationKey,
        active: 1,
        apply_address_verification_setting: true,
      });
    },
  }),
  lifecycle({
    componentDidMount() {
      const sections = get(this.props, "auth.order_sections_contest", []);
      if (sections.length === 0) {
        this.props.onUpdateOrder(defaultOrderSectionsContest);
      }

      const selectedStationKey = get(
        this.props,
        "process.data.fields.order_stations_array.value.0.key"
      );
      if (selectedStationKey) {
        this.props.onGetClocksData(selectedStationKey);
        this.props.onGetFulfillmentSettings(selectedStationKey);
      } else {
        this.props.setClocksData({});
        this.props.setFetchFulfillmentSettings([]);
      }
    },
  })
)(View);

export default injectIntl(Enhanced);
