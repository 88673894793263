import React, { useEffect, useState } from "react";
import bn from "utils/bemnames";
import PropTypes from "prop-types";
import { SearchPlusIcon, CopyIcon } from "components/CustomIcons";
import { Share } from "components/TwitterWidgets";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { copyToClipboard, htmlToText } from "utils/helpers";
import { get } from "lodash";
import ContentEditable from "react-contenteditable";
const bem = bn.create("jock-console-content");
export default function JockCard(props) {
  const {
    content,
    isShowSearchIcon,
    title,
    isLive,
    isShowCopyIcon,
    displaySettings,
    copyText,
    cardType,
    isShowTweetIcon,
    onClickSearch,
    editable,
  } = props;

  const [isCopied, setIsCopied] = useState(false);
  const [currentContent, setCurrentContent] = useState(content);
  const fontValue = get(displaySettings, "font");
  const fontCase = get(displaySettings, "font_case");
  const fontSize = props.fontSize
    ? props.fontSize
    : get(displaySettings, "font_size");

  const handleChange = (evt) => {
    setCurrentContent(evt.target.value);
  };
  useEffect(() => {
    setCurrentContent(content);
  }, [content]);
  return (
    <div
      className={classnames(bem.e("jock-card"), bem.e(`jock-card-${cardType}`))}
    >
      <div className={bem.e("card-header")}>
        <span className={bem.e("card-title")}>{title}</span>
        {isLive && (
          <span className={bem.e("card-title")}>
            <FormattedMessage id="jock console > live" />
          </span>
        )}
      </div>
      <div
        className={classnames(
          bem.e("card-content"),
          `dynamic-text font-${fontValue} fs-${fontSize} case-${fontCase}`
        )}
      >
        {editable ? (
          <ContentEditable
            className="content-editable"
            html={currentContent}
            onChange={handleChange}
          />
        ) : (
          <div
            className="content-editable disabled"
            dangerouslySetInnerHTML={{
              __html: currentContent,
            }}
          />
        )}
      </div>
      {isShowTweetIcon && (
        <span className={bem.e("tweet-icon")}>
          <Share options={{ text: htmlToText(copyText) }} />
        </span>
      )}
      {isShowSearchIcon && (
        <span
          className={bem.e("search-icon")}
          onClick={() => onClickSearch(currentContent)}
        >
          <SearchPlusIcon />
        </span>
      )}
      {isShowCopyIcon && (
        <span
          className={classnames(bem.e("copy-icon"), {
            [bem.e("copied")]: isCopied,
          })}
          onClick={() => {
            setIsCopied(true);
            copyToClipboard(htmlToText(copyText));
            setTimeout(() => {
              setIsCopied(false);
            }, 500);
          }}
        >
          {isCopied ? (
            <FormattedMessage id="jock console > copied" />
          ) : (
            <CopyIcon />
          )}
        </span>
      )}
    </div>
  );
}
JockCard.propTypes = {
  content: PropTypes.any,
  isShowSearchIcon: PropTypes.bool,
  isShowCopyIcon: PropTypes.bool,
  title: PropTypes.string,
  cardType: PropTypes.string,
  isLive: PropTypes.bool,
  isShowTweetIcon: PropTypes.bool,
  onClickSearch: PropTypes.func,
};
JockCard.defaultProps = {
  isShowSearchIcon: false,
  title: "",
  isLive: false,
  isShowCopyIcon: false,
  cardType: "default",
  isShowTweetIcon: false,
  onClickSearch: () => {},
  editable: false,
};
