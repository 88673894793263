import * as Const from "utils/constants";
import Api from "utils/api";
import queryString from "query-string";
import {get, map} from 'lodash'
export const autoSuggestClients = payload =>  {
    let { q, cb, template_key } = payload
    try {
        Api.doCall(Const.AUTOSUGGEST_CLIENTS(q, template_key || ''), "GET", null, (response) => {
            if (response.status === 200) {
                cb(response.data)
            } else {
                debugger
            }
        });
    } catch (error) {
        debugger
    }
}

export const autoSuggestAgencies = payload =>  {
    let { q, cb, template_key } = payload
    try {
        Api.doCall(Const.AUTOSUGGEST_AGENCIES(q, template_key || ''), "GET", null, (response) => {
            if (response.status === 200) {
                cb(response.data)
            } else {
                debugger
            }
        });
    } catch (error) {
        debugger
    }
}

export const autoSuggestContracts = payload =>  {
    let { cliant_id, q, cb } = payload
    try {
        Api.doCall(Const.AUTOSUGGEST_CONTRACTS(cliant_id, q), "GET", null, (response) => {
            if (response.status === 200) {
                cb(response.data)
            } else {
                debugger
            }
        });
    } catch (error) {
        debugger
    }
}

export const autoSuggestContacts = payload =>  {
    let { client_id, q, cb, template_key } = payload
    try {
        Api.doCall(Const.AUTOSUGGEST_CONTACTS(client_id, q, template_key), "GET", null, (response) => {
            if (response.status === 200) {
                cb(response.data)
            } else {
                debugger
            }
        });
    } catch (error) {
        debugger
    }
}


export const fetchWideOrbitClients = async (payload) => {
    let { q, template_key } = payload
    try {

        let response = await Api.doCall(Const.AUTOSUGGEST_CLIENTS(q, template_key || ''), "GET", null);
        return response.data;

    } catch (error) {
        debugger
    }
}

export const fetchWideOrbitMaterialGroups = async (payload) => {
    let { q, template_key, stations } = payload
    try {
        
        let response = await Api.doCall(Const.AUTOSUGGEST_MATERIAL_GROUPS(q, template_key || ''), "POST", {stations});
        return response.data;

    } catch (error) {
        debugger
    }
}
export const autoSuggestProgrammingElements = (payload) => {
  let { data, cb } = payload;
  try {
    Api.doCall(
      `${Const.SEARCH_ELEMENTS()}?${queryString.stringify(data, {
        arrayFormat: "bracket",
      })}`,
      "GET",
      null,
      (response) => {
        if (response && response.status === 200) {
          const newItems = map(get(response, "data.items", []), (item) => ({
            // ...item._source,
            title: item._source.order_title,
            order_title: item._source.order_title,
            id: item._id,
            order_process_id: item._id,
            type: item._source.order_type,
            order_start_date: item._source.order_start_date,
            order_end_date: item._source.order_end_date,
          }));
          cb(newItems);
        } else {
          cb([]);
        }
      }
    );
  } catch (error) {
    debugger;
  }
};

export const fetchClients = async (params) =>  {
    try {
        const response = await Api.doCall(`${Const.GET_CLIENTS()}?${queryString.stringify(params, {
        arrayFormat: "bracket",
        })}`, "GET", null);
        return get(response, 'data', {})
    } catch (error) {
        debugger
    }
}
