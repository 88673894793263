import { connect } from "react-redux";
import View from "./view";
import { compose, withState, withHandlers } from "recompose";
import { get } from "lodash";

export default compose(
  connect(
    (state) => ({
      allMessages: get(state, "messages.messages", []),
    }),
    {}
  ),
  withState("timer", "setTimer", 0),
  withState("isShowVolume", "setIsShowVolume", false),
  withState("currentBubble", "setCurrentBubble", null),
  withState("prevVolume", "setPrevVolume", 0),
  withHandlers({
    toggleSetVolume: ({
      prevVolume,
      setPrevVolume,
      volume,
      setVolume,
    }) => async () => {
      if (volume === 0) {
        setVolume(prevVolume);
      } else {
        setPrevVolume(volume);
        setVolume(0);
      }
    },
  })
)(View);
