import PreviewFileModal from "./view";
import { connect } from "react-redux";
import { renameFile } from "store/actions/uploads";
import { compose, withState, withHandlers } from "recompose";
import { getExt } from "utils/helpers";
import ToastManager from "components/ToastManager";
import { injectIntl } from "react-intl";
import { get } from "lodash";

export default injectIntl(
  compose(
    connect(state => ({}), { renameFile }),
    withState("isLoading", "setIsLoading", false),
    withHandlers({
      onRename: ({
        intl,
        renameFile,
        file,
        setIsLoading,
        onUploadedFiles,
        files,
        onCancel
      }) => (values) => {
        const fileName = values.file_name;
        if (!fileName) {
          ToastManager.show({
            title: intl.formatMessage({ id: "toast > title not saved" }),
            message: intl.formatMessage({
              id: "toast > message error please correct the hilighted fields"
            }),
            level: "error"
          });
          return false;
        }
        const ext = getExt(file.name);
        setIsLoading(true);
        renameFile(
          {
            ...file,
            new_name: `${fileName}.${ext}`
          },
          response => {
            setIsLoading(false);
            if (get(response, "status") === "success") {
              const indexOf = files.findIndex(item => item.name === file.name);
              if (indexOf !== -1) {
                files[indexOf] = response.file;
                onUploadedFiles(files);
              }
              ToastManager.show({
                title: intl.formatMessage({ id: "toast > title saved" }),
                message: intl.formatMessage({
                  id: "toast > message changed file successfully"
                }),
                level: "success"
              });
              onCancel();
            } else {
              ToastManager.show({
                title: intl.formatMessage({ id: "toast > title not saved" }),
                message: intl.formatMessage({
                  id: "toast > message error file not exists"
                }),
                level: "error"
              });
            }
          }
        );
      }
    })
  )(PreviewFileModal)
);
