import React, { useEffect, useMemo } from "react";
import { Button } from "reactstrap";
import {
  get,
  filter,
  includes,
  toLower,
  map,
  find,
  uniq,
  uniqBy,
  reduce,
} from "lodash";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import bn from "utils/bemnames";
import ConfirmationModal from "components/ConfirmationModal";
import AssignTeam from "../AssignTeam";

const bem = bn.create("assign-team-sidebar");
const ContentAssignUsers = (props) => {
  const {
    intl,
    onSubmit,
    isOpenSuccessModal,
    setIsOpenSuccessModal,
    onCancel,
    contentHeight,
    setContentHeight,
    selectedRole,
    setSelectedRole,
    searchText,
    setSearchText,
    isAudition,
    setIsAudition,
    values,
    setValues,
    activeTab,
    setActiveTab,
    assignedUsers,
    hubUsers,
  } = props;
  const roles = React.useMemo(() => {
    const allRoles = reduce(
      hubUsers,
      (accumulator, currentValue) => {
        return currentValue.enterprise_roles
          ? [...currentValue.enterprise_roles, ...accumulator]
          : accumulator;
      },
      []
    );
    return uniqBy(allRoles, "id")
      .filter((item) => toLower(item.name) !== toLower("Hub Manager"))
      .map((item) => ({
        id: item.id,
        name: item.name,
      }));
  }, [hubUsers]);

  useEffect(() => {
    // set values for selected members
    let newValues = {};
    assignedUsers.forEach((user) => {
      const validRoles = user.hub_roles.filter(
        (role) => roles.findIndex((item) => item.id === role.id) !== -1
      );
      if (validRoles) {
        validRoles.forEach((item) => {
          newValues = {
            ...newValues,
            [item.id]: newValues[item.id]
              ? uniq([...newValues[item.id], user.id])
              : [user.id],
          };
        });
      }
    });
    setValues(newValues);
  }, [roles, assignedUsers]);
  // get candidate by role
  const { allCandidates } = useMemo(() => {
    let roleCandidates = {};
    let allCandidates = [];
    roles.forEach((role) => {
      if (role && (!selectedRole || get(selectedRole, "id") === role.id)) {
        const candidates = filter(
          hubUsers,
          (user) =>
            user.is_active &&
            user.enterprise_roles.find((i) => i.id === role.id)
        );
        roleCandidates[role.id] = map(candidates, (user) => ({
          id: user.id,
          name: user.name,
          avatar_url: user.avatar_url,
          reel: user.reel,
          roles: user.enterprise_roles,
          location_text: user.location_text || "",
        }));
      }
    });

    Object.keys(roleCandidates).map((roleId) => {
      let candidates = roleCandidates[roleId];
      candidates.forEach((candidate) => {
        const existIndex = allCandidates.findIndex(
          (item) => item.id === candidate.id
        );
        if (existIndex === -1) {
          allCandidates.push({
            ...candidate,
            candidate_for: map(candidate.roles, (r) =>
              roles.find((i) => i.id === r.id)
            ).filter((i) => i),
          });
        }
      });
    });

    return { roleCandidates, allCandidates };
  }, [selectedRole, roles]);

  const roleClicked = (role) => {
    if (!role) setIsAudition(false);
    if (selectedRole === role) setSelectedRole(null);
    else setSelectedRole(role);
  };
  const toggleUserRole = (user, role) => {
    const roleUsers = get(values, role.id, []);
    const isExists = roleUsers.findIndex((userId) => userId === user.id) !== -1;
    if (isExists) {
      setValues({
        ...values,
        [role.id]: roleUsers.filter((userId) => userId !== user.id),
      });
    } else {
      setValues({
        ...values,
        [role.id]: roleUsers.concat([Number(user.id)]),
      });
    }
  };
  const isShowAudition =
    includes(
      ["vo talent", "producer"],
      toLower(get(selectedRole, "name", ""))
    ) || !selectedRole;

  const membersCandidates = allCandidates.filter((user) => {
    const isExists = find(assignedUsers, (item) => item.id === user.id);
    if (searchText) {
      return toLower(user.name).includes(toLower(searchText)) && isExists;
    }
    return isExists;
  });
  const remainCandidates = allCandidates.filter((user) => {
    const isExists = find(assignedUsers, (item) => item.id === user.id);
    if (searchText) {
      return toLower(user.name).includes(toLower(searchText)) && !isExists;
    }
    return !isExists;
  });
  const rolesOptionFilter = [
    {
      label: props.intl.formatMessage({
        id: "select team > all",
      }),
      value: "",
    },
    ...roles.map((r) => {
      return {
        label: get(r, "name", "").toString(),
        value: r,
      };
    }),
  ];

  return (
    <div className={bem.b()}>
      <div className={bem.e("assigned-header")}>
        <span className={bem.e("assignments-text")}>
          <FormattedMessage id="process > assignments" />:
        </span>
        <div className={bem.e("roles")}>
          {filter(roles, (role) => !role.is_disabled).map((role, ind) => {
            const totalAssigned = values[role.id] ? values[role.id].length : 0;
            return (
              <div key={ind} className={bem.e("role-item")}>
                <div className={bem.e("role-title")}>
                  <span>{get(role, "name")}:</span>{" "}
                  <strong className={bem.e("role-assigned-number")}>
                    {totalAssigned}
                  </strong>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Nav tabs className={bem.e("nav-tabs")}>
        <NavItem>
          <NavLink
            className={classnames(
              {
                active: activeTab === "assign",
              },
              bem.e("nav-link")
            )}
            onClick={() => {
              setActiveTab("assign");
            }}
          >
            <FormattedMessage id="hub manager > assign team" />
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames(
              {
                active: activeTab === "members",
              },
              bem.e("nav-link")
            )}
            onClick={() => {
              setActiveTab("members");
            }}
          >
            <FormattedMessage id="hub manager > team members" /> (
            {membersCandidates.length})
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent
        activeTab={activeTab}
        className={classnames(bem.e("tab-content"), "scroll-bar-style")}
      >
        <TabPane tabId="assign">
          {activeTab === "assign" ? (
            <AssignTeam
              intl={intl}
              candidates={remainCandidates}
              bem={bem}
              roles={roles}
              values={values}
              isAudition={isAudition}
              toggleUserRole={toggleUserRole}
              roleClicked={roleClicked}
              rolesOptionFilter={rolesOptionFilter}
              setSearchText={setSearchText}
              searchText={searchText}
              isShowAudition={isShowAudition}
              setIsAudition={setIsAudition}
              selectedRole={selectedRole}
              contentHeight={contentHeight}
              setContentHeight={setContentHeight}
            />
          ) : null}
        </TabPane>
        <TabPane tabId="members">
          {activeTab === "members" ? (
            <AssignTeam
              intl={intl}
              candidates={membersCandidates}
              bem={bem}
              roles={roles}
              values={values}
              isAudition={isAudition}
              toggleUserRole={toggleUserRole}
              roleClicked={roleClicked}
              rolesOptionFilter={rolesOptionFilter}
              setSearchText={setSearchText}
              searchText={searchText}
              isShowAudition={isShowAudition}
              setIsAudition={setIsAudition}
              selectedRole={selectedRole}
              contentHeight={contentHeight}
              setContentHeight={setContentHeight}
            />
          ) : null}
        </TabPane>
      </TabContent>
      <div className={bem.e("assigned-footer")}>
        <Button
          color="blue"
          type="submit"
          className={"btn btn-radius"}
          onClick={() => onSubmit(values)}
        >
          {activeTab === "assign" ? (
            <FormattedMessage id={`hub manager > button assign`} />
          ) : (
            <FormattedMessage id={`hub manager > button update`} />
          )}
        </Button>
        <Button
          color="blue"
          outline
          className={"btn btn-radius"}
          onClick={onCancel}
        >
          <FormattedMessage id={`hub manager > button cancel`} />
        </Button>
      </div>
      <ConfirmationModal
        isOpen={isOpenSuccessModal}
        state="success"
        title={<FormattedMessage id="select team > team assigned" />}
        onToggle={() => setIsOpenSuccessModal(!isOpenSuccessModal)}
        onCancel={() => setIsOpenSuccessModal(!isOpenSuccessModal)}
      />
    </div>
  );
};

export default ContentAssignUsers;
