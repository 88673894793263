import React, { useState } from "react";
import { Button } from "reactstrap";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import PrivilegedComponent from "components/PrivilegedComponent";
import { PRIVILEGES } from "utils/constants";
import { ApproveIcon, TrashIcon, PinIcon } from "components/CustomIcons";
import Confirmation from "../Confirmation";
import classNames from "classnames";
import SendMailDialog from "components/SendMailDialog";
import ConfirmationModal from "components/ConfirmationModal";
import PropTypes from "prop-types";

function Buttons(props) {
  const {
    isClientReview,
    mediaField,
    isView,
    mainSlick,
    channel,
    onClickPin,
    index,
    setCurrentPosition,
    setIsShowDraggable,
    bem,
    countAssetsReviewed,
    step,
    template,
    user,
    process,
    isShowSelectionButtonsForWhoReview,
    setIsShowSelectionButtonsForWhoReview,
    isShowPinIcon,
    setCurrentMediaSlideIndex,
    ...rest
  } = props;
  const [isShowReviewModalOpen, setIsShowReviewModalOpen] = useState(false);
  return (
    <div className={bem.e("buttons")}>
      {isShowSelectionButtonsForWhoReview && !isClientReview ? (
        <div className={bem.e("button-selections")}>
          <div className={bem.e("selections")}>
            <Button
              color="primary"
              outline
              className={classNames(bem.e("button-select"), "btn-radius")}
              onClick={() => setIsShowReviewModalOpen(true)}
              disabled={isView}
            >
              <FormattedMessage id="process > button i'm reviewing" />
            </Button>
            <SendMailDialog
              step={step}
              process={process}
              template={template}
              user={user}
              disableButton={isView}
            />
          </div>
        </div>
      ) : (
        <PrivilegedComponent
          requires={{
            or: [PRIVILEGES.APPROVE_DESIGN, PRIVILEGES.EDIT_ALL_DIGITAL_ORDERS],
          }}
        >
          {({ isValid }) => {
            if (isValid || isClientReview) {
              return (
                <React.Fragment>
                  <Button
                    color=""
                    className={classnames(
                      bem.e("button-approve"),
                      bem.e("button-review"),
                      {
                        [bem.e("button-approved")]: mediaField.is_approved,
                      }
                    )}
                    disabled={isView}
                    onClick={() => {
                      if (isView) return;
                      rest.onButtonClick(
                        channel,
                        mediaField,
                        "approved",
                        () => {
                          if (mainSlick.current) {
                            const nextMediaForReview =
                              rest.currentMediaSlideIndex + 1;
                            mainSlick.current.slickGoTo(nextMediaForReview);
                            setCurrentMediaSlideIndex(nextMediaForReview);
                          }
                        }
                      );
                    }}
                  >
                    <span className={bem.e("button-icon")}>
                      <ApproveIcon color="#fff" />
                    </span>
                    <FormattedMessage id="process > button approve" />
                  </Button>
                  <div className={bem.e("reject-container")}>
                    <Button
                      color=""
                      className={classnames(
                        bem.e("button-reject"),
                        bem.e("button-review"),
                        {
                          [bem.e("button-rejected")]: mediaField.is_rejected,
                        }
                      )}
                      disabled={isView}
                      onClick={() => {
                        if (isView) return;
                        rest.onButtonClick(channel, mediaField, "rejected");
                        if (
                          rest.isRejectConfirmationModalOpen !== index &&
                          isShowPinIcon
                        ) {
                          rest.setIsRejectConfirmationModalOpen(index);
                        }
                      }}
                    >
                      <span className={bem.e("button-icon")}>
                        <TrashIcon />
                      </span>
                      <FormattedMessage id="process > button reject" />
                    </Button>
                    {/* confirmation modal for reject */}
                    <Confirmation
                      onToggle={() => {
                        setIsShowDraggable(false);
                        setCurrentPosition(null);
                        rest.setIsRejectConfirmationModalOpen(null);
                      }}
                      visible={rest.isRejectConfirmationModalOpen === index}
                      onCancel={() => {
                        setIsShowDraggable(false);
                        setCurrentPosition(null);
                        rest.setIsRejectConfirmationModalOpen(null);
                        // automatically taken to the next asset in the carousel to review
                        if (mainSlick.current) {
                          const nextMediaForReview =
                            rest.currentMediaSlideIndex + 1;
                          mainSlick.current.slickGoTo(nextMediaForReview);
                          setCurrentMediaSlideIndex(nextMediaForReview);
                        }
                      }}
                      onConfirm={() => {
                        rest.setIsRejectConfirmationModalOpen(false);
                        onClickPin();
                      }}
                      isCloseOutside={false}
                    />
                  </div>
                  {isShowPinIcon && (
                    <div className={bem.e("pin-icon-top")}>
                      <div
                        className={bem.e("pin-icon")}
                        onClick={() => {
                          if (isView) return;
                          onClickPin();
                        }}
                      >
                        <PinIcon color={"#829FB1"} />
                      </div>
                      {/* helper tooltip shows up to let them know how to use the pin drop */}
                      <Confirmation
                        onToggle={() => {
                          rest.setIsOpenHelperTooltipModal(false);
                        }}
                        isShowCancel={false}
                        isHelperTooltip={true}
                        visible={
                          rest.isOpenHelperTooltipModal &&
                          rest.isShowHelperTooltip
                        }
                        onCancel={() => {
                          rest.setIsOpenHelperTooltipModal(false);
                        }}
                        onConfirm={() => {
                          rest.setIsOpenHelperTooltipModal(false);
                        }}
                        isCloseOutside={false}
                      />
                    </div>
                  )}
                </React.Fragment>
              );
            }
          }}
        </PrivilegedComponent>
      )}
      <ConfirmationModal
        isOpen={isShowReviewModalOpen}
        title={props.intl.formatMessage({
          id: "process > title digital review confirmation",
        })}
        onConfirm={() => {
          setIsShowReviewModalOpen(false);
          setIsShowSelectionButtonsForWhoReview(false);
        }}
        confirmTitle={props.intl.formatMessage({
          id: "confirm modal > button ok",
        })}
        cancelTitle={props.intl.formatMessage({
          id: "confirm modal > button cancel",
        })}
        onToggle={() => {
          setIsShowReviewModalOpen(false);
          setIsShowSelectionButtonsForWhoReview(true);
        }}
        onCancel={() => {
          setIsShowReviewModalOpen(false);
          setIsShowSelectionButtonsForWhoReview(true);
        }}
        isCloseOutside={false}
      />
    </div>
  );
}
Buttons.propTypes = {
  isShowPinIcon: PropTypes.bool,
};
Buttons.defaultProps = {
  isShowPinIcon: true,
};

export default Buttons;
