import * as Types from "store/types/winners";
import { LOGOUT_USER } from "store/types/auth";
import { get } from "lodash";
import { pageSize } from "utils/config";
const initialState = {
  winners: {
    data: {},
    error: null,
    loading: false,
    is_dirty: false,
  },
  filterWinners: {
    data: {},
    error: null,
    loading: false,
    is_dirty: false,
  },
  winner: {
    data: null,
    error: null,
    loading: false,
    is_dirty: false,
  },
  addWinner: {
    error: null,
    loading: false,
  },
  markWinLoss: {
    error: null,
    loading: false,
  },
  pickPrizeForWinner: {
    error: null,
    loading: false,
  },
  pickGrandPrizeWinner: {
    error: null,
    loading: false,
  },
  addUnitPrizeForWinner: {
    error: null,
    loading: false,
  },
  updateWinner: {
    error: null,
    loading: false,
  },
  assignWinner: {
    error: null,
    loading: false,
  },
  fulfilledWinner: {
    error: null,
    loading: false,
  },
  exportWinners: {
    error: null,
    loading: false,
  },
  saveSearch: {
    error: null,
    loading: false,
  },
  savedSearches: {
    error: null,
    loading: false,
    data: {},
  },
  allSavedSearches: {
    error: null,
    loading: false,
    data: {},
  },
  reportWinners: {
    data: {},
    error: null,
    loading: false,
    is_dirty: false,
  },
  advancedSearch: {
    stations: [],
    prize_status: [],
    keyword: "",
    contest_category: "",
    min_prize_value: "",
    prize_won_start_date: "",
    prize_won_end_date: "",
    prize_distribution_method: "",
  },
  winnersNotifications: {
    data: {},
    error: null,
    loading: false,
    is_dirty: false,
  },
  updateWinnerNotification: {
    error: null,
    loading: false,
  },
  addWinnerNotification: {
    error: null,
    loading: false,
  },
  deleteWinnerNotification: {
    error: null,
    loading: false,
  },
  createFloatingContestPlay: {
    error: null,
    loading: false,
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_WINNERS_BY_FILTER_REQUEST: {
      let data = [];
      if (
        // case: first search will reset data or change search text
        (get(action, "payload.search") &&
          get(state, `filterWinners.filter_${action.payload.filter}.search`) !==
            get(action, "payload.search")) ||
        // case: empty search (not first load)
        (get(action, "payload.search") === "" &&
          get(state, `filterWinners.filter_${action.payload.filter}.search`)) ||
        // case: first load
        get(action, "payload.page") === 1
      ) {
        data = [];
        // case: other
      } else {
        data = get(
          state,
          `filterWinners.filter_${action.payload.filter}.data`,
          []
        );
      }
      return {
        ...state,
        filterWinners: {
          ...state.filterWinners,
          ["filter_" + action.payload.filter]: {
            ...state.filterWinners["filter_" + action.payload.filter],
            data,
            pages: state.filterWinners["filter_" + action.payload.filter]
              ? state.filterWinners["filter_" + action.payload.filter].pages
              : null,
            page: state.filterWinners["filter_" + action.payload.filter]
              ? state.filterWinners["filter_" + action.payload.filter].page
              : 1,
            rpp: state.filterWinners["filter_" + action.payload.filter]
              ? state.filterWinners["filter_" + action.payload.filter].rpp
              : pageSize,
            error: null,
            loading: true,
          },
        },
      };
    }
    case Types.FETCH_WINNERS_BY_FILTER_SUCCESS: {
      return {
        ...state,
        filterWinners: {
          ...state.filterWinners,
          ["filter_" + action.payload.filter]: {
            ...action.payload,
            data: [
              ...state.filterWinners["filter_" + action.payload.filter].data,
              ...action.payload.data,
            ],
            error: null,
            loading: false,
          },
        },
      };
    }
    case Types.FETCH_WINNERS_BY_FILTER_ERROR: {
      return {
        ...state,
        filterWinners: {
          ...state.filterWinners,
          ["filter_" + action.payload.filter]: {
            data: [],
            error: action.error,
            loading: false,
          },
        },
      };
    }
    case Types.FETCH_WINNERS_REQUEST: {
      return {
        ...state,
        winners: {
          data: get(state, "winners.data"),
          error: null,
          loading: true,
          is_dirty: false,
        },
      };
    }

    case Types.FETCH_WINNERS_SUCCESS: {
      const winnersData = action.payload;
      const isMore = get(action, "payload.page") > 1;
      if (isMore) {
        winnersData.items = [
          ...get(state, "winners.data.items"),
          ...get(action, "payload.items", []),
        ];
      }
      return {
        ...state,
        winners: {
          data: winnersData,
          error: null,
          loading: false,
          is_dirty: false,
        },
      };
    }

    case Types.FETCH_WINNERS_ERROR: {
      return {
        ...state,
        winners: {
          data: null,
          error: action.payload,
          loading: false,
          is_dirty: false,
        },
      };
    }
    case Types.FETCH_WINNER_REQUEST: {
      return {
        ...state,
        winner: {
          ...state.winner,
          loading: true,
          error: null,
        },
      };
    }

    case Types.FETCH_WINNER_SUCCESS: {
      return {
        ...state,
        winner: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    }

    case Types.FETCH_WINNER_ERROR: {
      return {
        ...state,
        winner: {
          winner: null,
          loading: false,
          error: action.payload,
        },
      };
    }

    case Types.UPDATE_WINNER_REQUEST: {
      return {
        ...state,
        updateWinner: {
          loading: true,
          error: null,
        },
      };
    }

    case Types.UPDATE_WINNER_SUCCESS: {
      return {
        ...state,
        winners: {
          ...get(state, "winners"),
          is_dirty: true,
        },
        winner: {
          data: action.payload,
          loading: false,
          error: null,
        },
        updateWinner: {
          loading: false,
          error: null,
        },
      };
    }

    case Types.UPDATE_WINNER_ERROR: {
      return {
        ...state,
        updateWinner: {
          loading: false,
          error: action.payload,
        },
      };
    }
    case Types.ASSIGN_WINNER_TO_CONTEST_REQUEST: {
      return {
        ...state,
        assignWinner: {
          loading: true,
          error: null,
        },
      };
    }

    case Types.ASSIGN_WINNER_TO_CONTEST_SUCCESS: {
      return {
        ...state,
        assignWinner: {
          loading: false,
          error: null,
        },
      };
    }

    case Types.ASSIGN_WINNER_TO_CONTEST_ERROR: {
      return {
        ...state,
        assignWinner: {
          loading: false,
          error: action.payload,
        },
      };
    }

    case Types.UPDATE_WINNER_FULFILLED_REQUEST: {
      return {
        ...state,
        fulfilledWinner: {
          loading: true,
          error: null,
        },
      };
    }

    case Types.UPDATE_WINNER_FULFILLED_SUCCESS: {
      return {
        ...state,
        winner: {
          data: action.payload,
          loading: false,
          error: null,
        },
        fulfilledWinner: {
          loading: false,
          error: null,
        },
      };
    }

    case Types.UPDATE_WINNER_FULFILLED_ERROR: {
      return {
        ...state,
        fulfilledWinner: {
          loading: false,
          error: action.payload,
        },
      };
    }
    case Types.ADD_WINNER_REQUEST: {
      return {
        ...state,
        addWinner: {
          loading: true,
          error: null,
        },
      };
    }

    case Types.ADD_WINNER_SUCCESS: {
      return {
        ...state,
        addWinner: {
          loading: false,
          error: null,
        },
      };
    }

    case Types.ADD_WINNER_ERROR: {
      return {
        ...state,
        addWinner: {
          loading: false,
          error: action.payload,
        },
      };
    }

    case Types.MARK_WIN_LOSS_REQUEST: {
      return {
        ...state,
        markWinLoss: {
          loading: true,
          error: null,
        },
      };
    }

    case Types.MARK_WIN_LOSS_SUCCESS: {
      return {
        ...state,
        markWinLoss: {
          loading: false,
          error: null,
        },
      };
    }

    case Types.MARK_WIN_LOSS_ERROR: {
      return {
        ...state,
        markWinLoss: {
          loading: false,
          error: action.payload,
        },
      };
    }

    case Types.PICK_PRIZE_FOR_WINNER_REQUEST: {
      return {
        ...state,
        pickPrizeForWinner: {
          loading: true,
          error: null,
        },
      };
    }

    case Types.PICK_PRIZE_FOR_WINNER_SUCCESS: {
      return {
        ...state,
        pickPrizeForWinner: {
          loading: false,
          error: null,
        },
      };
    }

    case Types.PICK_PRIZE_FOR_WINNER_ERROR: {
      return {
        ...state,
        pickPrizeForWinner: {
          loading: false,
          error: action.payload,
        },
      };
    }

    case Types.PICK_GRAND_PRIZE_WINNER_REQUEST: {
      return {
        ...state,
        pickGrandPrizeWinner: {
          loading: true,
          error: null,
        },
      };
    }

    case Types.PICK_GRAND_PRIZE_WINNER_SUCCESS: {
      return {
        ...state,
        pickGrandPrizeWinner: {
          loading: false,
          error: null,
        },
      };
    }

    case Types.PICK_GRAND_PRIZE_WINNER_ERROR: {
      return {
        ...state,
        pickGrandPrizeWinner: {
          loading: false,
          error: action.payload,
        },
      };
    }

    case Types.ADD_VALUE_FOR_STANDARD_PRIZE_REQUEST: {
      return {
        ...state,
        addUnitPrizeForWinner: {
          loading: true,
          error: null,
        },
      };
    }

    case Types.ADD_VALUE_FOR_STANDARD_PRIZE_SUCCESS: {
      return {
        ...state,
        addUnitPrizeForWinner: {
          loading: false,
          error: null,
        },
      };
    }

    case Types.ADD_VALUE_FOR_STANDARD_PRIZE_ERROR: {
      return {
        ...state,
        addUnitPrizeForWinner: {
          loading: false,
          error: action.payload,
        },
      };
    }
    case Types.EXPORT_WINNERS_LIST_REQUEST: {
      return {
        ...state,
        exportWinners: {
          loading: true,
          error: null,
        },
      };
    }

    case Types.EXPORT_WINNERS_LIST_SUCCESS: {
      return {
        ...state,
        exportWinners: {
          loading: false,
          error: null,
        },
      };
    }

    case Types.EXPORT_WINNERS_LIST_ERROR: {
      return {
        ...state,
        exportWinners: {
          loading: false,
          error: action.payload,
        },
      };
    }
    case Types.SAVE_WINNERS_SEARCH_REQUEST: {
      return {
        ...state,
        saveSearch: {
          loading: true,
          error: null,
        },
      };
    }

    case Types.SAVE_WINNERS_SEARCH_SUCCESS: {
      return {
        ...state,
        saveSearch: {
          loading: false,
          error: null,
        },
      };
    }

    case Types.SAVE_WINNERS_SEARCH_ERROR: {
      return {
        ...state,
        saveSearch: {
          loading: false,
          error: action.payload,
        },
      };
    }

    case Types.GET_WINNERS_SAVED_SEARCHES_REQUEST: {
      return {
        ...state,
        savedSearches: {
          data: get(state, "savedSearches.data"),
          error: null,
          loading: true,
          is_dirty: false,
        },
      };
    }

    case Types.GET_WINNERS_SAVED_SEARCHES_SUCCESS: {
      const savedSearchesData = action.payload;
      const isMore = get(action, "payload.page") > 1;
      if (isMore) {
        savedSearchesData.items = [
          ...get(state, "savedSearches.data.items"),
          ...get(action, "payload.items", []),
        ];
      }
      return {
        ...state,
        savedSearches: {
          data: savedSearchesData,
          error: null,
          loading: false,
          is_dirty: false,
        },
      };
    }

    case Types.GET_WINNERS_SAVED_SEARCHES_ERROR: {
      return {
        ...state,
        savedSearches: {
          data: null,
          error: action.payload,
          loading: false,
          is_dirty: false,
        },
      };
    }
    case Types.DELETE_WINNERS_SEARCH_REQUEST: {
      return {
        ...state,
        saveSearch: {
          loading: true,
          error: null,
        },
      };
    }

    case Types.DELETE_WINNERS_SEARCH_SUCCESS: {
      return {
        ...state,
        saveSearch: {
          loading: false,
          error: null,
        },
      };
    }

    case Types.DELETE_WINNERS_SEARCH_ERROR: {
      return {
        ...state,
        saveSearch: {
          loading: false,
          error: action.payload,
        },
      };
    }
    case Types.GET_ALL_WINNERS_SAVED_SEARCHES_REQUEST: {
      return {
        ...state,
        allSavedSearches: {
          data: get(state, "allSavedSearches.data"),
          error: null,
          loading: true,
          is_dirty: false,
        },
      };
    }

    case Types.GET_ALL_WINNERS_SAVED_SEARCHES_SUCCESS: {
      return {
        ...state,
        allSavedSearches: {
          data: action.payload,
          error: null,
          loading: false,
          is_dirty: false,
        },
      };
    }

    case Types.GET_ALL_WINNERS_SAVED_SEARCHES_ERROR: {
      return {
        ...state,
        allSavedSearches: {
          data: null,
          error: action.payload,
          loading: false,
          is_dirty: false,
        },
      };
    }
    case Types.FETCH_REPORT_WINNERS_REQUEST: {
      return {
        ...state,
        reportWinners: {
          data: get(state, "reportWinners.data"),
          error: null,
          loading: true,
          is_dirty: false,
        },
      };
    }

    case Types.FETCH_REPORT_WINNERS_SUCCESS: {
      const winnersData = action.payload;
      const isMore = get(action, "payload.page") > 1;
      if (isMore) {
        winnersData.items = [
          ...get(state, "reportWinners.data.items"),
          ...get(action, "payload.items", []),
        ];
      }
      return {
        ...state,
        reportWinners: {
          data: winnersData,
          error: null,
          loading: false,
          is_dirty: false,
        },
      };
    }

    case Types.FETCH_REPORT_WINNERS_ERROR: {
      return {
        ...state,
        reportWinners: {
          data: null,
          error: action.payload,
          loading: false,
          is_dirty: false,
        },
      };
    }
    case Types.EXPORT_1099_REPORTS_WINNERS_LIST_REQUEST: {
      return {
        ...state,
        exportWinners: {
          loading: true,
          error: null,
        },
      };
    }

    case Types.EXPORT_1099_REPORTS_WINNERS_LIST_SUCCESS: {
      return {
        ...state,
        exportWinners: {
          loading: false,
          error: null,
        },
      };
    }

    case Types.EXPORT_1099_REPORTS_WINNERS_LIST_ERROR: {
      return {
        ...state,
        exportWinners: {
          loading: false,
          error: action.payload,
        },
      };
    }
    case Types.SET_ADVANCED_SEARCH_DATA: {
      return {
        ...state,
        advancedSearch: {
          ...state.advancedSearch,
          ...action.payload,
        },
      };
    }
    // winners notifications
    case Types.FETCH_WINNERS_NOTIFICATIONS_REQUEST: {
      return {
        ...state,
        winnersNotifications: {
          ...state.winnersNotifications,
          loading: true,
          error: null,
          is_dirty: false,
        },
      };
    }

    case Types.FETCH_WINNERS_NOTIFICATIONS_SUCCESS: {
      let winnersNotifications = action.payload;
      const isMore = get(action, "payload.notifications.current_page") > 1;
      if (isMore) {
        winnersNotifications = {
          ...action.payload,
          notifications: {
            ...action.payload.notifications,
            data: !isMore
              ? action.payload.notifications.data
              : [
                  ...state.winnersNotifications.data.notifications.data,
                  ...action.payload.notifications.data,
                ],
          },
        };
      }
      return {
        ...state,
        winnersNotifications: {
          data: winnersNotifications,
          loading: false,
          error: null,
        },
      };
    }

    case Types.FETCH_WINNERS_NOTIFICATIONS_ERROR: {
      return {
        ...state,
        winnersNotifications: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }
    // update winner notification
    case Types.UPDATE_WINNER_NOTIFICATION_REQUEST: {
      return {
        ...state,
        updateWinnerNotification: {
          loading: true,
          error: null,
        },
      };
    }

    case Types.UPDATE_WINNER_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        updateWinnerNotification: {
          loading: false,
          error: null,
        },
        winnersNotifications: {
          ...state.winnersNotifications,
          is_dirty: true,
        },
      };
    }

    case Types.UPDATE_WINNER_NOTIFICATION_ERROR: {
      return {
        ...state,
        updateWinnerNotification: {
          loading: false,
          error: action.payload,
        },
      };
    }
    // add winner notification
    case Types.ADD_WINNER_NOTIFICATION_REQUEST: {
      return {
        ...state,
        addWinnerNotification: {
          loading: true,
          error: null,
        },
      };
    }

    case Types.ADD_WINNER_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        addWinnerNotification: {
          loading: false,
          error: null,
        },
        winnersNotifications: {
          ...state.winnersNotifications,
          is_dirty: true,
        },
      };
    }

    case Types.ADD_WINNER_NOTIFICATION_ERROR: {
      return {
        ...state,
        addWinnerNotification: {
          loading: false,
          error: action.payload,
        },
      };
    }
    // delete winner notification
    case Types.DELETE_WINNER_NOTIFICATION_REQUEST: {
      return {
        ...state,
        deleteWinnerNotification: {
          loading: true,
          error: null,
        },
      };
    }

    case Types.DELETE_WINNER_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        deleteWinnerNotification: {
          loading: false,
          error: null,
        },
        winnersNotifications: {
          ...state.winnersNotifications,
          is_dirty: true,
        },
      };
    }

    case Types.DELETE_WINNER_NOTIFICATION_ERROR: {
      return {
        ...state,
        deleteWinnerNotification: {
          loading: false,
          error: action.payload,
        },
      };
    }
    // create floating contest play
    case Types.CREATE_FLOATING_CONTEST_REQUEST: {
      return {
        ...state,
        createFloatingContestPlay: {
          loading: true,
          error: null,
        },
      };
    }

    case Types.CREATE_FLOATING_CONTEST_SUCCESS: {
      return {
        ...state,
        createFloatingContestPlay: {
          loading: false,
          error: null,
        },
      };
    }

    case Types.CREATE_FLOATING_CONTEST_ERROR: {
      return {
        ...state,
        createFloatingContestPlay: {
          loading: false,
          error: action.payload,
        },
      };
    }
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
}
