import React from "react";
import Slider from "rc-slider";
import Tooltip from "rc-tooltip";
import bn from "utils/bemnames";
import { FormattedMessage } from "react-intl";
const bem = bn.create("preview-script-modal");

const MIN_SLIDER = 18;
const MAX_SLIDER = 42;
const DEFAULT_SLIDER = 24;
const Handle = Slider.Handle;

const handle = ({ value, dragging, index, ...restProps }) => (
  <Tooltip
    parent={null}
    prefixCls="rc-slider-tooltip"
    overlay={value + "px"}
    overlayStyle={{ zIndex: 1051 /* because modal is 1050 */ }}
    visible={dragging}
    placement="top"
    key={index}
  >
    <Handle value={value} {...restProps} />
  </Tooltip>
);

const FontSlider = (props) => {
  const { title, fontSize = DEFAULT_SLIDER, setFontSize } = props;

  return (
    <div className={bem.e("slider-wrapper")}>
      <span className={bem.e("label")}>{title}</span>
      <div className={bem.e("slider")}>
        <span
          className={bem.e("minus")}
          onClick={() => {
            if (fontSize > 18) {
              const newFontSize = fontSize - 1;
              setFontSize(newFontSize);
            }
          }}
        >
          -
        </span>
        <Slider
          handle={handle}
          min={MIN_SLIDER}
          max={MAX_SLIDER}
          value={fontSize}
          defaultValue={DEFAULT_SLIDER}
          onChange={setFontSize}
        />
        <span
          className={bem.e("plus")}
          onClick={() => {
            if (fontSize < 42) {
              const newFontSize = fontSize + 1;
              setFontSize(newFontSize);
            }
          }}
        >
          +
        </span>
      </div>
    </div>
  );
};
FontSlider.defaultProps = {
  title: <FormattedMessage id="jock console > font size" />,
};
export default FontSlider;
