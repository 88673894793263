import * as React from "react";
import { compose, lifecycle, withHandlers } from "recompose";
import { connect } from "react-redux";
import { get } from "lodash";
import View from "./view";
import { injectIntl } from "react-intl";
import ToastManager from "components/ToastManager";
import { MdImportantDevices } from "react-icons/lib/md";
import {
  getStationW9Forms,
  updateStationW9Forms,
} from "store/actions/stations";

export default injectIntl(
  compose(
    connect(
      ({ auth, servers, ...rest }) => {
        return {
          auth,
          currentServer: get(servers, "currentServer.data", {}),
          loading: get(rest, "stations.stationW9Forms.loading", false),
          stationSettingsData: get(rest, "stations.stationW9Forms.data", null),
        };
      },
      {
        getStationW9Forms,
        updateStationW9Forms,
      }
    ),
    withHandlers({
      onFormSubmit: ({
        updateStationW9Forms,
        intl,
        setShouldBlockNavigation,
      }) => (values) => {
        updateStationW9Forms(values, () => {
          ToastManager.show({
            title: <MdImportantDevices />,
            message: intl.formatMessage({
              id: "form manager > update successfully",
            }),
            level: "success",
          });
          setShouldBlockNavigation(false);
        });
      },
    }),
    withHandlers({
      onGetStationSettings: ({ currentStation, getStationW9Forms }) => () => {
        getStationW9Forms({
          station_id: currentStation.key,
        });
      },
    }),
    lifecycle({
      componentDidMount() {
        const { onGetStationSettings } = this.props;
        onGetStationSettings();
      },
      componentDidUpdate(prevProps) {
        const isStationChanged =
          this.props.currentStation &&
          this.props.currentStation.key !== prevProps.currentStation.key;
        if (isStationChanged) {
          this.props.onGetStationSettings();
        }
      },
    })
  )(View)
);
