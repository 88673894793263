export const ADD_ROLE_REQUEST = "ADD_ROLE_REQUEST";
export const ADD_ROLE_SUCCESS = "ADD_ROLE_SUCCESS";
export const ADD_ROLE_ERROR = "ADD_ROLE_ERROR";

export const UPDATE_ROLE_REQUEST = "UPDATE_ROLE_REQUEST";
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
export const UPDATE_ROLE_ERROR = "UPDATE_ROLE_ERROR";

export const DELETE_ROLE_REQUEST = "DELETE_ROLE_REQUEST";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const DELETE_ROLE_ERROR = "DELETE_ROLE_ERROR";

export const FETCH_ROLES_REQUEST = "FETCH_ROLES_REQUEST";
export const FETCH_ROLES_SUCCESS = "FETCH_ROLES_SUCCESS";
export const FETCH_ROLES_ERROR = "FETCH_ROLES_ERROR";

export const UPDATE_ORDER_ROLES_REQUEST = "UPDATE_ORDER_ROLES_REQUEST";
export const UPDATE_ORDER_ROLES_SUCCESS = "UPDATE_ORDER_ROLES_SUCCESS";
export const UPDATE_ORDER_ROLES_ERROR = "UPDATE_ORDER_ROLES_ERROR";

export const UPDATE_ROLE_ACTIVATE_REQUEST = "UPDATE_ROLE_ACTIVATE_REQUEST";
export const UPDATE_ROLE_ACTIVATE_SUCCESS = "UPDATE_ROLE_ACTIVATE_SUCCESS";
export const UPDATE_ROLE_ACTIVATE_ERROR = "UPDATE_ROLE_ACTIVATE_ERROR";

export const CLONE_ROLE_REQUEST = "CLONE_ROLE_REQUEST";
export const CLONE_ROLE_SUCCESS = "CLONE_ROLE_SUCCESS";
export const CLONE_ROLE_ERROR = "CLONE_ROLE_ERROR";

export const FETCH_ROLES_PRODUCTION_TEAM_REQUEST = "FETCH_ROLES_PRODUCTION_TEAM_REQUEST";
export const FETCH_ROLES_PRODUCTION_TEAM_SUCCESS = "FETCH_ROLES_PRODUCTION_TEAM_SUCCESS";
export const FETCH_ROLES_PRODUCTION_TEAM_ERROR = "FETCH_ROLES_PRODUCTION_TEAM_ERROR";

