import React, { useRef } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Row } from "reactstrap";
import * as Yup from "yup";
import bn from "utils/bemnames";
import { Formik } from "formik";
import { createYupSchema } from "components/ChannelForm/yupSchemaCreator";
import { Form } from "reactstrap";
import renderCustomFields, {
  getFormFields,
  getInitialValues,
} from "../renderCustomFields";
import classNames from "classnames";

const bem = bn.create("preview-system-form");

const FormContent = (props) => {
  const {
    systemForm,
    intl,
    onClose,
    currentStation,
    currentServer,
    isConsoleForm,
    isCommonForm,
    isFulfillment,
    isShowMobile,
  } = props;
  const serverCountries = [
    currentServer.country,
    ...currentServer.neighboring_countries,
  ];
  const formikRef = useRef();
  let formFields = getFormFields(systemForm, { currentServer });
  const initialValues = getInitialValues(
    formFields,
    {},
    {
      countries: serverCountries,
    }
  );
  const yepSchema = formFields.reduce(
    (schema, config) => createYupSchema(schema, config, intl),
    {}
  );
  return (
    <Formik
      enableReinitialize={false}
      initialValues={initialValues}
      ref={formikRef}
      validationSchema={Yup.object().shape({
        ...yepSchema,
      })}
      validateOnChange={true}
      validateOnBlur={false}
      onSubmit={() => {}}
    >
      {(formProps) => {
        return (
          <Form
            className={classNames(bem.e("form"), {
              [bem.e("form-fulfillment")]: isFulfillment,
            })}
            onSubmit={formProps.handleSubmit}
          >
            <div
              className={classNames({
                [bem.e("custom-console-winner-fields")]: isConsoleForm,
                [bem.e("custom-fields")]: isCommonForm,
                [bem.e("fulfillment-fields")]: isFulfillment,
              })}
            >
              <Row>
                {renderCustomFields(systemForm, {
                  formProps,
                  currentStation,
                  currentServer,
                  isShowMobile,
                  isRenderForm: true,
                  isFulfillment,
                  isConsoleForm,
                  isPreview: true,
                })}
              </Row>
            </div>
            {isConsoleForm && (
              <div className={bem.e("console-winner-buttons")}>
                <Button
                  color="blue"
                  type="submit"
                  className="button-submit btn-radius btn-disabled"
                  disabled
                >
                  <FormattedMessage id="form manager > button save new winner" />
                </Button>
                <Button
                  color="blue"
                  type="button"
                  outline
                  className="button-cancel btn-radius"
                  onClick={onClose}
                >
                  <FormattedMessage id="form manager > button cancel" />
                </Button>
              </div>
            )}
            {isCommonForm && (
              <div className={bem.e("common-buttons")}>
                <Button
                  color="blue"
                  type="button"
                  className="button-close btn-radius"
                  onClick={onClose}
                >
                  <FormattedMessage id="form manager > button close" />
                </Button>
              </div>
            )}
            {isFulfillment && (
              <div className={bem.e("fulfillment-buttons")}>
                <Button
                  color="blue"
                  type="submit"
                  className="button-submit btn-radius"
                >
                  <FormattedMessage id="form manager > button submit" />
                </Button>
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};
FormContent.propTypes = {};
FormContent.defaultProps = {};
export default FormContent;
