export const IMPORT_ACTION_REQUEST = "IMPORT_ACTION_REQUEST";
export const IMPORT_ACTION_SUCCESS = "IMPORT_ACTION_SUCCESS";
export const IMPORT_ACTION_ERROR = "IMPORT_ACTION_ERROR";

export const PURGE_ACTION_REQUEST = "PURGE_ACTION_REQUEST"
export const PURGE_ACTION_SUCCESS = "PURGE_ACTION_SUCCESS"
export const PURGE_ACTION_ERROR = "PURGE_ACTION_ERROR"

export const DELETE_INDEXES_REQUEST = "DELETE_INDEXES_REQUEST"
export const DELETE_INDEXES_SUCCESS = "DELETE_INDEXES_SUCCESS"
export const DELETE_INDEXES_ERROR = "DELETE_INDEXES_ERROR"

export const DELETE_INDEX_REQUEST = "DELETE_INDEX_REQUEST"
export const DELETE_INDEX_SUCCESS = "DELETE_INDEX_SUCCESS"
export const DELETE_INDEX_ERROR = "DELETE_INDEX_ERROR"