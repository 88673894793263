import { STATE_LOGIN } from "components/AuthForm";
import * as actions from "store/actions/auth";
import { connect } from "react-redux";
import { compose, withHandlers, lifecycle } from "recompose";
import AuthPage from "./view";

export default compose(
  connect(
    (state) => ({
      errorMessage: state.auth.error,
      authenticated: state.auth.authenticated,
      loading: state.auth.loading,
    }),
    {
      loginUser: actions.loginUser,
      registerUser: actions.registerUser,
      authError: actions.authError,
    }
  ),
  withHandlers({
    handleSubmit: ({ loginUser, authState, registerUser }) => (
      values,
      params = {}
    ) => {
      if (authState === STATE_LOGIN) {
        let body = {
          email: values.email,
          password: values.password,
          url: params.url,
          isMobile: params.isMobile,
        };
        loginUser(body, (response) => {
          if (
            response &&
            response.data &&
            response.data.error === "ChangePassword"
          ) {
            let redirectUrl = `/app/password/reset/${response.data.reset_token}?password_expired=true`;
            window.location.href = redirectUrl;
          }
        });
      } else {
        let body = {
          name: values.name,
          email: values.email,
          password: values.password,
          c_password: values.c_password,
        };
        registerUser(body);
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.authError("");
    },
  })
)(AuthPage);
