import React, { useState } from "react";
import bn from "utils/bemnames";
import { ShadowBox, SectionTitle, SectionFields } from "components/Elements";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import { map, get, find, trim } from "lodash";
import classnames from "classnames";
import { PlusSmallIcon } from "components/CustomIcons";
import { Button } from "reactstrap";
import RotationChart from "components/RotationChart";
import SpotTrafficProductionNeeded from "./SpotTrafficProductionNeeded";
import SpotTrafficProductionComplete from "./SpotTrafficProductionComplete";
import SpotTrafficProductionProvidedNeededTags from "./SpotTrafficProductionProvidedNeededTags";
import history from "components/History";
import moment from "moment";
import FinalScriptModal from "../FinalScriptModal";
import ConfirmationModal from "components/ConfirmationModal";
import { generateRotationChart, uuidv4 } from "utils/helpers";
import ToastManager from "components/ToastManager";

const bem = bn.create("production-continuity-traffic-details");
function ContinuityBasic(props) {
  const {
    process,
    step,
    template,
    user,
    valueChanged,
    isView,
    validationState,
    newIndexes,
    // setNewIndexes,
    // valueEvaluatorChanged,
    getRoleCandidates,
    setShouldBlockNavigation,
    dateFormatByServer,
    isCartNumberEditable,
    setIsCartNumberEditable,
  } = props;
  const [currentSpotIndex, setCurrentSpotIndex] = useState(null);
  const [isOpenDialogDeleteSpot, setIsOpenDialogDeleteSpot] = useState(false);
  const [isOpenRotationPromptModal, setIsOpenRotationPromptModal] = useState(
    false
  );
  const spotInfo = get(process, "data.fields.spot_info.value", []);
  let orderTitleField = step.fields.find((f) => f.field === "order_title");
  const spotInfoField = step.fields.find((f) => f.field === "spot_info");
  const rotationChartField = step.fields.find(
    (f) => f.field === "rotation_chart"
  );
  const productionAssetAuditField = step.fields.find(
    (f) => f.field === "production_asset_audit"
  );
  const productionAssetAuditFieldB = step.fields.find(
    (f) => f.field === "production_asset_b_audit"
  );
  const contractStatusField = step.fields.find(
    (f) => f.field === "contract_status"
  );

  const onValueChangedSpot = (index, { field, value, isSelectedDefault }) => {
    const spot = spotInfo[index];
    if (spot) {
      if (setShouldBlockNavigation && !isSelectedDefault)
        setShouldBlockNavigation(true);
      spotInfo[index] = {
        ...spot,
        [field]: value,
      };
      valueChanged(
        {
          ...spotInfoField,
          changing: spotInfo.map((item, i) => {
            if (index === i) return [field];
            return [];
          }),
        },
        process,
        template,
        user,
        {
          value: spotInfo,
        }
      );

      if (field === "recording_path") {
        valueChanged(productionAssetAuditField, process, template, user, {
          value: {
            modified_at: `${moment()
              .format(dateFormatByServer)
              .toString()} - ${moment()
              .format("h:mm A")
              .toString()}`,
            modified_by: user.name,
          },
        });
      }

      if (field === "recording_path_b") {
        valueChanged(productionAssetAuditFieldB, process, template, user, {
          value: {
            modified_at: `${moment()
              .format(dateFormatByServer)
              .toString()} - ${moment()
              .format("h:mm A")
              .toString()}`,
            modified_by: user.name,
          },
        });
      }
    }
  };
  const onRemoveSpot = (index) => {
    setCurrentSpotIndex(index);
    setIsOpenDialogDeleteSpot(true);
  };

  const onConfirmDeleteSpot = () => {
    let { process, template, user, valueChanged, step } = props;
    const currentSpotInfo = get(process, "data.fields.spot_info.value", []);
    const processKey = get(props, "process.data.fields.key.value", "");
    const spotRemove = currentSpotInfo[currentSpotIndex];
    setIsOpenDialogDeleteSpot(false);

    if (!spotRemove) {
      return;
    }
    let isCallApiDeleteSpot = false;
    if (spotRemove.template_key === "production_provided") {
      isCallApiDeleteSpot =
        currentSpotInfo.filter(
          (item) =>
            item.uuid !== spotRemove.uuid &&
            item.template_key === "production_provided"
        ).length === 0;
    } else {
      isCallApiDeleteSpot = true;
    }
    if (isCallApiDeleteSpot) {
      props.deleteSpotProcess(spotRemove["key"], (response) => {
        const nextOrderId = trim(response.data);
        const nextSpot = nextOrderId
          ? currentSpotInfo.find((item) => item.key === nextOrderId)
          : null;
        // if next process id is the same to current process id. Don't need to redirect
        if (nextSpot && nextOrderId === processKey) {
          props.getProcess({
            template_id: nextSpot["template_key"],
            process_id: nextOrderId,
            spotSwitching: true,
          });
          return;
        }
        if (nextSpot) {
          history.replace({
            pathname: `/processes/edit/${nextSpot["template_key"]}/${nextOrderId}`,
            state: {
              isKeepPosition: true,
              spotSwitching: true,
            },
          });
        } else {
          props.getProcess({
            template_id: spotRemove["template_key"],
            process_id: spotRemove["key"],
          });
        }
      });
    } else {
      // Otherwise just call the process update api by removing the deleted spot from the spot_info
      currentSpotInfo.splice(currentSpotIndex, 1);
      const rotationChart = generateRotationChart(
        process,
        get(process, "data.fields.order_stations.value", []),
        currentSpotInfo
      );
      // update rotation chart
      valueChanged(rotationChartField, process, template, user, {
        value: rotationChart,
      });

      valueChanged(spotInfoField, process, template, user, {
        value: currentSpotInfo,
      });
      const contractStatus = get(
        process,
        "data.fields.contract_status.value",
        ""
      );
      // recheck contract status for production provided
      if (
        ["Production Provided - Needs tags", "Production Complete"].includes(
          contractStatus
        )
      ) {
        let newContactStatus = "Production Complete";
        if (
          find(
            currentSpotInfo,
            (item) =>
              item.contract_status === "Production Provided - Needs tags"
          )
        ) {
          newContactStatus = "Production Provided - Needs tags";
        }
        valueChanged(contractStatusField, process, template, user, {
          value: newContactStatus,
        });
        process.data.fields.contract_status = {
          value: newContactStatus,
          is_dirty: true,
        };
      }
      process.data.fields.rotation_chart = {
        value: rotationChart,
        is_dirty: true,
      };
      process.data.fields.spot_info = {
        value: currentSpotInfo,
        is_dirty: true,
      };
      // call api update
      props.buttonClicked(
        step,
        {
          ...step.fields.find((f) => f.key === "save_button"),
          is_keep_on: true,
        },
        process,
        template,
        user,
        null,
        null,
        true
      );
    }
  };

  const handleSelectRotation = (params = {}) => {
    setIsOpenRotationPromptModal(false);
    addRotationSpot(params);
  };

  const addRotationSpot = (params = {}) => {
    const processKey = get(props, "process.data.fields.key.value", "");
    if (!processKey) return;
    const newTemplateKey = params.template_key;
    const productionSettings = get(props, "productionSettings", {});
    const currentTemplateKey = get(
      props,
      "process.data.fields.template_key.value",
      ""
    );
    /**
     * Case1: when a production provided spot is added to a production provided order. We just add to spot data info of the order. Don't need to create new order url
     */
    if (
      newTemplateKey === "production_provided" &&
      currentTemplateKey === "production_provided"
    ) {
      const spotInfoField = step.fields.find((f) => f.field === "spot_info");
      const isProductionProvidedNeededTagsOfOrder =
        get(process, "data.fields.contract_status.value") ===
        "Production Provided - Needs tags";
      // Add new spot container
      const spotInfo = get(process, "data.fields.spot_info.value", []);

      const spot = find(spotInfo, (spot) => spot.key === processKey);

      let newSpotInfo = {
        title: "",
        spot_type: get(spot, "spot_type", "Standard"),
        line_hashes: get(spot, "line_hashes", []),
        isci: "",
        recording_path: "",
        recording_path_b: "",
        recording_audit: "",
        recording_path_b_audit: "",
        cart_number: "",
        role_producer: null,
        role_dubber: get(spot, "role_dubber", null),
        length1: "00",
        length2: "00",
        order_length: productionSettings.default_spot_length || "",
        order_length_custom: Boolean(productionSettings.custom_spot_length),
        due_date: get(spot, "due_date", ""),
        contract_status: "Production Complete",
        template_key: "production_provided",
        uuid: uuidv4(),
        key: processKey, // linked to current order
      };

      const spotWithUseAudioForAll = find(
        get(process, "data.fields.spot_info.value", []),
        (item) => item.is_audio_for_all
      );
      // assign audio when have an spot selected use this audio for all spots
      if (isProductionProvidedNeededTagsOfOrder && spotWithUseAudioForAll) {
        newSpotInfo.recording_path = spotWithUseAudioForAll.recording_path;
        newSpotInfo.recording_path_b = spotWithUseAudioForAll.recording_path_b;
      }
      const spots = [...spotInfo, newSpotInfo];
      const rotationChart = generateRotationChart(
        process,
        get(process, "data.fields.order_stations.value", []),
        spots
      );
      valueChanged(rotationChartField, process, template, user, {
        value: rotationChart,
      });
      valueChanged(spotInfoField, process, template, user, {
        value: spots,
      });
      return;
    }
    let isOpenNewOrderUrl = true;
    /**
     * Case1: when a production provided spot is added to a production needed order, we need to create a new order
     * Case2: When a new production needed spot is added to a production needed order
     * Case3: When a new production needed spot is added to a production provided order
     */
    if (
      (newTemplateKey === "production_provided" &&
        currentTemplateKey === "basic_production") ||
      (newTemplateKey === "basic_production" &&
        currentTemplateKey === "basic_production") ||
      (newTemplateKey === "basic_production" &&
        currentTemplateKey === "production_provided")
    ) {
      isOpenNewOrderUrl = true;
    }
    props.addRotation(
      {
        ...params,
        id: processKey,
      },
      (newProcessKey) => {
        if (isOpenNewOrderUrl) {
          history.push({
            pathname: `/processes/edit/${newTemplateKey}/${newProcessKey}`,
            state: {
              isKeepPosition: true,
              spotSwitching: true,
            },
          });
        } else {
          // call api to reload process data
          props.getProcess({
            template_id: newTemplateKey,
            process_id: newProcessKey,
          });
          props.setActiveEditTab("order_details");
        }
      }
    );
  };
  const onClickAddSpot = (postData = {}) => {
    const spotInfo = get(process, "data.fields.spot_info.value", []);
    const totalSpots = spotInfo.length;
    if (totalSpots >= 8) {
      ToastManager.show({
        message: props.intl.formatMessage({
          id: "toast > message no more than 8 spots can be added to rotation",
        }),
        level: "error",
      });
      return;
    }
    setIsOpenRotationPromptModal(true);

    // const paramButton = step.fields.find((f) => f.key === "save_button");
    // props.buttonClicked(
    //   step,
    //   paramButton,
    //   process,
    //   template,
    //   user,
    //   null,
    //   ({ data }) => {
    //     if (!data) return;
    //     // PRODUCTION (BELL) - Hybrid Order with Prod Needed and Provided => https://tasks.getventive.com/projects/43CA8-12E
    //     setIsOpenRotationPromptModal(true);
    //   },
    //   true
    // );
  };

  const spotLength = get(process, "data.fields.spot_info.value.length");
  const clientName = get(process, "data.fields.client_name.value", "");
  return (
    <div className={classNames(bem.b(), bem.m("list"))}>
      <ShadowBox
        className={classnames(bem.e("box-traffic-container"), {
          [bem.e("box-traffic-container-view")]: isView,
        })}
      >
        <SectionTitle className={bem.e("section-title")}>
          <FormattedMessage id="process > title traffic details" />
        </SectionTitle>
        <SectionFields
          className={classNames(bem.e("section-fields"), {
            [bem.e("section-rotation-spots")]:
              spotLength > 1 && spotLength <= 5,
          })}
        >
          {map(spotInfo, (spot, index) => {
            const isProductionComplete =
              get(spot, "contract_status", "") === "Production Complete";
            const isProductionNeeded =
              get(spot, "contract_status", "") === "Production Needed";
            const isProductionProvidedNeededTags =
              get(spot, "contract_status", "") ===
              "Production Provided - Needs tags";
            //  production needed
            if (isProductionNeeded) {
              return (
                <SpotTrafficProductionNeeded
                  key={index}
                  spot={spot}
                  spotIndex={index}
                  intl={props.intl}
                  isView={isView}
                  valueChanged={valueChanged}
                  isShowDelete={spotInfo.length > 1 && !isView}
                  onRemoveSpot={onRemoveSpot}
                  onValueChangedSpot={onValueChangedSpot}
                  process={process}
                  step={step}
                  template={template}
                  user={user}
                  orderTitleField={orderTitleField}
                  validationState={get(
                    validationState,
                    `spot_info.validation_error.${index}`
                  )}
                  getRoleCandidates={getRoleCandidates}
                  setShowFinalScriptModal={props.setShowFinalScriptModal}
                  setIsSyncCartNumberProgressModalOpen={
                    props.setIsSyncCartNumberProgressModalOpen
                  }
                  syncWOCartNumbers={props.syncWOCartNumbers}
                  isCartNumberEditable={isCartNumberEditable}
                  setIsCartNumberEditable={setIsCartNumberEditable}
                />
              );
            }
            // production complete
            if (isProductionComplete) {
              return (
                <SpotTrafficProductionComplete
                  key={index}
                  spot={spot}
                  spotIndex={index}
                  intl={props.intl}
                  isView={isView}
                  valueChanged={valueChanged}
                  onValueChangedSpot={onValueChangedSpot}
                  isShowDelete={spotInfo.length > 1 && !isView}
                  process={process}
                  step={step}
                  onRemoveSpot={onRemoveSpot}
                  newIndexes={newIndexes}
                  user={user}
                  template={template}
                  spotLength={spotLength}
                  validationState={get(
                    validationState,
                    `spot_info.validation_error.${index}`
                  )}
                  getRoleCandidates={getRoleCandidates}
                  setShowFinalScriptModal={props.setShowFinalScriptModal}
                  setIsSyncCartNumberProgressModalOpen={
                    props.setIsSyncCartNumberProgressModalOpen
                  }
                  syncWOCartNumbers={props.syncWOCartNumbers}
                  isCartNumberEditable={isCartNumberEditable}
                  setIsCartNumberEditable={setIsCartNumberEditable}
                />
              );
            }
            if (isProductionProvidedNeededTags)
              // production provided needed tags
              return (
                <SpotTrafficProductionProvidedNeededTags
                  key={index}
                  spot={spot}
                  spotIndex={index}
                  intl={props.intl}
                  isView={isView}
                  valueChanged={valueChanged}
                  onValueChangedSpot={onValueChangedSpot}
                  isShowDelete={spotInfo.length > 1 && !isView}
                  process={process}
                  step={step}
                  onRemoveSpot={onRemoveSpot}
                  newIndexes={newIndexes}
                  user={user}
                  template={template}
                  validationState={get(
                    validationState,
                    `spot_info.validation_error.${index}`
                  )}
                  getRoleCandidates={getRoleCandidates}
                  setShowFinalScriptModal={props.setShowFinalScriptModal}
                  setIsSyncCartNumberProgressModalOpen={
                    props.setIsSyncCartNumberProgressModalOpen
                  }
                  syncWOCartNumbers={props.syncWOCartNumbers}
                  isCartNumberEditable={isCartNumberEditable}
                  setIsCartNumberEditable={setIsCartNumberEditable}
                />
              );
          })}
        </SectionFields>
        {!isView && (
          <div
            className={classnames({
              [bem.e("spot-button")]: spotLength <= 1 || spotLength > 5,
              [bem.e("rotation-spot-button")]:
                spotLength > 1 && spotLength <= 5,
            })}
          >
            <div id="add-rotation-button" className="d-inline-block">
              <Button
                className={classnames(bem.e("add-button"), "btn-radius")}
                outline
                color="blue"
                onClick={onClickAddSpot}
              >
                <span className={bem.e("add-icon")}>
                  <PlusSmallIcon />
                </span>
                <FormattedMessage id="process > button add to rotation" />
              </Button>
            </div>
          </div>
        )}
        <div className={bem.e("rotation-chart")}>
          <RotationChart
            valueChanged={props.valueChanged}
            step={step}
            process={process}
            template={template}
            user={user}
            validationState={validationState}
            isView={isView}
            isShowAdjust={false}
            isContinuity={true}
          />
        </div>
      </ShadowBox>
      <FinalScriptModal
        isOpen={!!props.showFinalScriptModal}
        onToggle={() => props.setShowFinalScriptModal(null)}
        onCancel={() => props.setShowFinalScriptModal(null)}
        clientName={clientName}
        data={props.showFinalScriptModal}
      />
      {/* delete spot modal confirmation */}
      <ConfirmationModal
        isOpen={isOpenDialogDeleteSpot}
        centered
        onToggle={() => setIsOpenDialogDeleteSpot(!isOpenDialogDeleteSpot)}
        onCancel={() => setIsOpenDialogDeleteSpot(false)}
        title={<FormattedMessage id="process > confirm delete this spot" />}
        cancelTitle={<FormattedMessage id="confirm modal > button cancel" />}
        description={null}
        className={bem.e("confirmation-modal-delete-spot")}
        onConfirm={onConfirmDeleteSpot}
        isCloseOutside={false}
      />
      <ConfirmationModal
        isOpen={props.isSyncCartNumberProgressModalOpen}
        title={props.intl.formatMessage({
          id: "menu > wo sync progress",
        })}
        description={props.intl.formatMessage({
          id: "menu > wo cart number sync progress description",
        })}
        confirmTitle={<FormattedMessage id="menu > wo sync progress > ok" />}
        onConfirm={() => props.setIsSyncCartNumberProgressModalOpen(false)}
        cancelTitle=""
        discardTitle=""
        isCloseOutside={false}
      />
      {/* rotation prompt modal */}
      <ConfirmationModal
        centered
        isOpen={isOpenRotationPromptModal}
        className={"production-prompt-modal production-prompt-details-modal"}
        title={
          <FormattedMessage id="process > title select a production option for this spot" />
        }
        confirmTitle={
          <FormattedMessage id="process > button production needed" />
        }
        cancelTitle={
          <FormattedMessage id="process > button production provided" />
        }
        descriptionBottom={
          <div className={"description-spots-button"}>
            <div className={"description-left"}>
              <FormattedMessage id="process > description the spots need to be produced" />
            </div>
            <div className={"description-right"}>
              <FormattedMessage id="process > description the client or agency is producing the spots" />
            </div>
          </div>
        }
        onConfirm={() =>
          handleSelectRotation({ template_key: "basic_production" })
        }
        onCancel={() => {
          handleSelectRotation({ template_key: "production_provided" });
        }}
        onToggle={() =>
          setIsOpenRotationPromptModal(!isOpenRotationPromptModal)
        }
        // isCloseOutside={false}
      />
    </div>
  );
}
export default ContinuityBasic;
