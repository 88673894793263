import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import TextInput from "components/TextInput";
import { Row, Col, FormGroup, Label } from "reactstrap";
import {
  get,
  find,
  filter,
  includes,
  map,
  difference,
  isEmpty,
  uniq,
  forOwn,
  forEach,
} from "lodash";
import { Asterisk } from "components/Elements";
import SchedulingPreview, {
  renderFinalScheduleStatement,
} from "components/ScheduleComponents/SchedulingPreview";
import Dropdown from "components/Dropdown";
import CustomMultiSelectDropdown from "components/CustomMultiSelectDropdown";
import ExcludeHoursModal from "components/ExcludeHoursModal";
import Badge from "components/Badge";
import classNames from "classnames";
import moment from "moment";
import bn from "utils/bemnames";
const bem = bn.create("schedule-preview");
const weekdaysMin = moment.weekdaysShort();

export default function SchedulingTargeted(props) {
  const {
    fields: { mentions, per, selected_by, selected_items, excluded_hours },
    intl,
    onValueChanged,
    process,
    step,
    validationState,
    dayparts,
    shows,
    hours,
    daypartsHours,
    showsHours,
    isShowSchedulingPreview,
    dateFormatByServer,
  } = props;
  const [isOpenExcludeHours, setIsOpenExcludeHours] = useState(false);
  const selectedBy = get(process, "data.fields.selected_by.value");
  const perSelected = get(process, "data.fields.per.value");
  const schedulingType = get(process, "data.fields.scheduling_type.value");
  const selectedByOptions = filter(selected_by.props.options, (item) => {
    if (includes(["week", "day", "hour"], perSelected)) {
      return (
        includes(item.scheduling_type, schedulingType) &&
        includes(["daypart", "show", "hour"], item.value)
      );
    }
    return false;
  });
  const onSetActiveHoursBySelectedItems = () => {
    const selectedItems = process.data.fields[selected_items.field]
      ? process.data.fields[selected_items.field].value
      : [];
    const data = selectedBy === "daypart" ? daypartsHours : showsHours;
    let hours = [];
    filter(data, (item) =>
      find(selectedItems, (i) => i.value === item.id)
    ).forEach((item) => {
      hours = uniq([...hours, ...item.hours]);
    });
    return hours;
  };
  const renderSelectedItems = () => {
    const selectedItems = process.data.fields[selected_items.field]
      ? process.data.fields[selected_items.field].value
      : [];

    return selectedItems.map((item) => {
      return (
        <Badge
          key={item.value}
          text={item.label}
          onClick={() => {
            const newValue = difference(selectedItems, [item]);
            onValueChanged(selected_items, newValue);
          }}
        />
      );
    });
  };
  const renderExcludedHours = () => {
    const excludedHours = get(
      process,
      "data.fields.excluded_hours_array.value"
    );
    if (!excludedHours && excludedHours.length === 0) return null;
    let items = [];
    const excludedHoursAll = get(
      find(excludedHours, (item) => item.key === "all"),
      "value",
      []
    ).map((item) => ({
      value: item.value,
      label: item.label,
      text: `${item.label} - ALL`,
      key: "all",
    }));
    items = [...excludedHoursAll];
    forEach(excludedHours, (hourItem) => {
      const { key, value } = hourItem;
      if (key === "all") return;
      const itemsValue = value
        .filter((item) => {
          return !find(excludedHoursAll, (i) => i.value === item.value);
        })
        .map((item) => ({
          value: item.value,
          label: item.label,
          text: `${item.label} - ${weekdaysMin[parseInt(key) - 1]}`,
          key,
        }));
      items = [...items, ...itemsValue];
    });
    return items.map((hour, index) => {
      return (
        <Badge
          key={`badge-excluded-hour-${index}`}
          text={hour.text}
          onClick={() => {
            let newValue = [];
            if (hour.key === "all") {
              newValue = excludedHours.map((hourItem) => {
                return {
                  ...hourItem,
                  value: filter(
                    get(hourItem, "value", []),
                    (i) => i.value !== hour.value
                  ),
                };
              });
            } else {
              newValue = excludedHours.map((hourItem) => {
                if (hourItem.key === hour.key) {
                  return {
                    ...hourItem,
                    value: filter(
                      hourItem.value,
                      (i) => i.value !== hour.value
                    ),
                  };
                }
                return hourItem;
              });
            }

            const newValueFormatted = filter(newValue, (hourItem) => {
              return get(hourItem, "value.length", 0) > 0;
            });
            onValueChanged(excluded_hours, newValueFormatted);
          }}
        />
      );
    });
  };

  const ExcludeHoursButton = () => {
    return (
      <div
        className="exclude-dates-btn"
        onClick={() => {
          setIsOpenExcludeHours(true);
        }}
      >
        <FormattedMessage id="process > promotion > liner > exclude hours" />
      </div>
    );
  };
  return (
    <div className={bem.e("targeted-scheduling-preview")}>
      <Row>
        <Col xs={6} className={bem.e("col-reset")}>
          <Row>
            <Col className={bem.e("col-reset")} xs={4}>
              <FormGroup>
                <TextInput
                  label={
                    <span>
                      {mentions.title}
                      {mentions.mandatory && <Asterisk>*</Asterisk>}
                    </span>
                  }
                  required
                  name="mentions"
                  placeholder={"0"}
                  value={
                    process.data.fields[mentions.field]
                      ? process.data.fields[mentions.field].value
                      : ""
                  }
                  onChange={(event) => {
                    onValueChanged(mentions, get(event, "target.value", ""));
                  }}
                  error={get(validationState, "mentions.validation_error")}
                />
              </FormGroup>
            </Col>
            <Col className={bem.e("col-reset")} xs={8}>
              <FormGroup>
                <Dropdown
                  label={
                    <span>
                      {per.title}
                      {per.mandatory && <Asterisk>*</Asterisk>}
                    </span>
                  }
                  name="per"
                  placeholder={intl.formatMessage({
                    id: "process > promotion > liner > select",
                  })}
                  className={bem.e("item-dropdown")}
                  onChange={(selectedOption) => {
                    onValueChanged(per, selectedOption.value);
                    if (
                      includes(
                        ["show", "daypart", "hour"],
                        selectedOption.value
                      )
                    ) {
                      onValueChanged(selected_by, selectedOption.value);
                      onValueChanged(selected_items, []);
                      onValueChanged(excluded_hours, "");
                    }
                  }}
                  value={find(
                    per.props.options,
                    (item) =>
                      item.value === get(process, "data.fields.per.value")
                  )}
                  options={per.props.options}
                  error={get(validationState, "per.validation_error")}
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
        <Col xs={6} className={bem.e("col-reset")}>
          <Row>
            {selectedByOptions.length > 0 ? (
              <Col xs={6} className={bem.e("col-reset")}>
                <FormGroup>
                  <Dropdown
                    label={
                      <span>
                        {selected_by.title}
                        {selected_by.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    placeholder={intl.formatMessage({
                      id: "process > promotion > liner > select",
                    })}
                    name="selected_by"
                    onChange={(selectedOption) => {
                      onValueChanged(selected_by, selectedOption.value);
                      onValueChanged(selected_items, []);
                      onValueChanged(excluded_hours, "");
                    }}
                    value={find(
                      selectedByOptions,
                      (item) =>
                        item.value ===
                        get(process, "data.fields.selected_by.value", "")
                    )}
                    error={get(validationState, "selected_by.validation_error")}
                    options={selectedByOptions}
                  />
                </FormGroup>
              </Col>
            ) : null}

            <Col xs={6} className={bem.e("col-reset")}>
              {selectedBy === "daypart" && (
                <FormGroup>
                  <Label>
                    <FormattedMessage id="process > promotion > liner > scheduling > dayparts" />
                    <Asterisk>*</Asterisk>
                  </Label>
                  <CustomMultiSelectDropdown
                    name="selected_items"
                    value={
                      get(
                        process.data.fields[selected_items.field],
                        "value",
                        null
                      )
                        ? get(
                            process.data.fields[selected_items.field],
                            "value",
                            []
                          )
                        : []
                    }
                    options={map(dayparts, (daypart) => ({
                      label: daypart.name,
                      value: daypart.id,
                    }))}
                    placeholder={intl.formatMessage({
                      id: "process > promotion > liner > select",
                    })}
                    isMulti={true}
                    error={get(
                      validationState,
                      "selected_items.validation_error"
                    )}
                    onChange={(value) => {
                      onValueChanged(selected_items, value);
                      onValueChanged(excluded_hours, "");
                    }}
                    customInput={<ExcludeHoursButton />}
                  />
                </FormGroup>
              )}
              {selectedBy === "show" && (
                <FormGroup>
                  <Label>
                    <FormattedMessage id="process > promotion > liner > scheduling > shows" />
                    <Asterisk>*</Asterisk>
                  </Label>
                  <CustomMultiSelectDropdown
                    name="selected_items"
                    error={get(
                      validationState,
                      "selected_items.validation_error"
                    )}
                    value={
                      get(
                        process.data.fields[selected_items.field],
                        "value",
                        null
                      )
                        ? get(
                            process.data.fields[selected_items.field],
                            "value",
                            []
                          )
                        : []
                    }
                    options={map(shows, (show) => ({
                      label: show.name,
                      value: show.id,
                    }))}
                    placeholder={intl.formatMessage({
                      id: "process > promotion > liner > select",
                    })}
                    isMulti={true}
                    onChange={(value) => {
                      onValueChanged(selected_items, value);
                      onValueChanged(excluded_hours, "");
                    }}
                    customInput={<ExcludeHoursButton />}
                  />
                </FormGroup>
              )}
              {selectedBy === "hour" && (
                <FormGroup>
                  <Label>
                    <FormattedMessage id="process > promotion > liner > scheduling > select hours" />
                    <Asterisk>*</Asterisk>
                  </Label>
                  <CustomMultiSelectDropdown
                    name="selected_items"
                    error={get(
                      validationState,
                      "selected_items.validation_error"
                    )}
                    value={
                      get(
                        process.data.fields[selected_items.field],
                        "value",
                        null
                      )
                        ? get(
                            process.data.fields[selected_items.field],
                            "value",
                            []
                          )
                        : []
                    }
                    options={hours}
                    placeholder={intl.formatMessage({
                      id: "process > promotion > liner > select",
                    })}
                    isMulti={true}
                    onChange={(value) => {
                      onValueChanged(selected_items, value);
                      onValueChanged(excluded_hours, "");
                    }}
                  />
                </FormGroup>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={bem.e("badges")}>
        {process.data.fields[selected_items.field] &&
          !isEmpty(process.data.fields[selected_items.field].value) && (
            <Col className={bem.e("reset-col")}>
              <FormGroup>
                <Label>
                  {selectedBy === "show" ? (
                    <FormattedMessage id="process > promotion > liner > scheduling > selected shows" />
                  ) : selectedBy === "daypart" ? (
                    <FormattedMessage id="process > promotion > liner > scheduling > selected dayparts" />
                  ) : (
                    <FormattedMessage id="process > promotion > liner > scheduling > select hours" />
                  )}
                </Label>
                <div
                  className={classNames(bem.e("badges-container"), {
                    [bem.e("badges-hours")]: selectedBy === "hour",
                  })}
                >
                  {renderSelectedItems()}
                </div>
              </FormGroup>
            </Col>
          )}
        {process.data.fields[excluded_hours.field] &&
          !isEmpty(process.data.fields[excluded_hours.field].value) && (
            <Col className={bem.e("reset-col")}>
              <FormGroup>
                <Label>
                  <FormattedMessage id="process > promotion > liner > scheduling > excluded hours" />
                </Label>
                <div
                  className={classNames(
                    bem.e("badges-container"),
                    bem.e("badges-excluded-hours")
                  )}
                >
                  {renderExcludedHours()}
                </div>
              </FormGroup>
            </Col>
          )}
      </Row>
      <Row>
        <Col xs={12} className={bem.e("col-reset")}>
          <div className={bem.e("scheduling-statement-header")}>
            <FormattedMessage id="process > promotion > liner > scheduling > final statement" />
          </div>
          <div className={bem.e("scheduling-statement-text")}>
            {renderFinalScheduleStatement(process, dateFormatByServer)}
          </div>
        </Col>
      </Row>
      {isShowSchedulingPreview && (
        <SchedulingPreview
          intl={intl}
          bem={bem}
          process={process}
          schedulingType={schedulingType}
          onValueChanged={onValueChanged}
          validationState={validationState}
          step={step}
          isShowRule={true}
          user={props.user}
          template={props.template}
          buttonClicked={props.buttonClicked}
          isAllowShowDesiredItemsDescription={
            props.isAllowShowDesiredItemsDescription
          }
          isAllowManualDesiredItems={props.isAllowManualDesiredItems}
          schedulingPreviewHelpText={props.schedulingPreviewHelpText}
          isSchedulingPreviewRequired={props.isSchedulingPreviewRequired}
        />
      )}

      <ExcludeHoursModal
        isOpen={isOpenExcludeHours}
        onToggle={() => setIsOpenExcludeHours(!isOpenExcludeHours)}
        daysOfWeek={get(process, "data.fields.days_of_week.value", [])}
        onSubmit={(values) => {
          let valuesArray = [];
          forOwn(values, (value, key) => {
            valuesArray = [
              ...valuesArray,
              {
                key,
                value,
              },
            ];
          });
          onValueChanged(excluded_hours, valuesArray);
          setIsOpenExcludeHours(false);
        }}
        value={get(process, "data.fields.excluded_hours_array.value")}
        activeHours={onSetActiveHoursBySelectedItems()}
      />
    </div>
  );
}
SchedulingTargeted.propTypes = {
  isShowSchedulingPreview: PropTypes.bool,
};
SchedulingTargeted.defaultProps = {
  isShowSchedulingPreview: true,
};
