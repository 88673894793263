import React, { useEffect, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import bn from "utils/bemnames";
import URL from "utils/urls";
import { getRoleIdByName } from "utils/helpers";
import { get, find, toLower } from "lodash";
import BlockingComponent from "components/BlockingComponent";
import Spinner from "components/Spinner";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import SearchBox from "components/SearchBox";
import history from "components/History";
import { RefineIcon, CloseSmallIcon } from "components/CustomIcons";
import TeamSidebar from "components/TeamManagementComponents/TeamSidebar";
import ContentFilterView from "components/TeamManagementComponents/ContentFilterView";
import Table from "components/Table";
import classnames from "classnames";
import { FaSortDesc, FaSortAsc } from "react-icons/lib/fa";
import Avatar from "components/Avatar";
import AssignTeam from "components/MultipleMarketComponents/AssignTeam";
import { DownIcon, UpIcon, PalmTreeIcon } from "components/CustomIcons";
import {
  checkOutOfOfficeOfUserByDates,
  formatDisplayDateWithTimezone,
  getNextOutOfOfficeOfUserByDates,
} from "utils/helpers";
import Tooltip from "rc-tooltip";
import TeamListOrders from "components/TeamManagementComponents/TeamListOrders";

const bem = bn.create("team-management-page");

const getUserStatus = (user, enterpriseSettings) => {
  if (!enterpriseSettings) return "";
  let currentLoad =
    get(user, "current_tasks_count", 0) + get(user, "assigned_tasks_count", 0);

  let isOverLoaded = false;

  get(user, "roles", []).forEach((role) => {
    const roleName = toLower(role.name);
    let roleData = {
      field: "",
    };
    switch (roleName) {
      case "vo talent":
        roleData.field = "role_voice_talent";
        break;
      case "writer":
        roleData.field = "role_writer";
        break;
      case "producer":
        roleData.field = "role_producer";
        break;
      case "dubber":
        roleData.field = "dubber_capacity";
        break;
      default:
        break;
    }

    let capacity = getCapacityForRole(roleData, enterpriseSettings);

    if (capacity > 0 && currentLoad >= capacity) {
      isOverLoaded = true;
      return;
    }
  });

  if (!user.is_active) return "inactive";
  if (isOverLoaded) return "overload";
  return "available";
};

const getCapacityForRole = (role, enterpriseSettings) => {
  let capacity = 0;

  if (get(role, "field") === "role_writer") {
    capacity = get(enterpriseSettings, "writer_capacity", 0);
  } else if (get(role, "field") === "role_voice_talent") {
    capacity = get(enterpriseSettings, "voice_over_capacity", 0);
  } else if (get(role, "field") === "role_producer") {
    capacity = get(enterpriseSettings, "producer_capacity", 0);
  } else if (get(role, "field") === "role_dubber") {
    capacity = get(enterpriseSettings, "dubber_capacity", 0);
  }

  return capacity;
};

const TeamManagement = (props) => {
  const {
    intl,
    auth,
    isLoading,
    onSubmitFilter,
    teams,
    enterpriseSettings,
    expanded,
    setExpanded,
  } = props;
  const { production_multimarket } = get(auth, "info", {});
  const isShowHubManager =
    getRoleIdByName("Hub Manager", auth.info.enterprise_roles) &&
    production_multimarket;

  useEffect(() => {
    if (!isShowHubManager) history.push("/");
  }, []);
  const renderBreadcrumbs = () => {
    let breadcrumbs = [
      {
        name: intl.formatMessage({ id: "breadcrumbs > home" }),
        to: URL.HOME(),
        active: false,
      },
      {
        name: intl.formatMessage({ id: "breadcrumbs > team management" }),
        active: true,
      },
    ];

    return breadcrumbs;
  };
  const sortedName = find(props.sorted, (item) => item.id === "name");
  const sortedRoles = find(props.sorted, (item) => item.id === "roles_string");
  const sortedHubs = find(props.sorted, (item) => item.id === "hubs_string");
  const sortedCurrent = find(
    props.sorted,
    (item) => item.id === "current_tasks_count"
  );
  const sortedAssigned = find(
    props.sorted,
    (item) => item.id === "assigned_tasks_count"
  );

  const breadcrumbs = renderBreadcrumbs();

  const formattedTeams = useMemo(() => {
    return teams.map((item) => {
      const userTimeZone = get(item, "timezone");
      const datesOutOfOffice = checkOutOfOfficeOfUserByDates(
        get(item, "out_of_office_settings", []),
        userTimeZone
      );
      const nextDatesOutOfOffline = getNextOutOfOfficeOfUserByDates(
        get(item, "out_of_office_settings", []),
        userTimeZone
      );
      const userStatus = getUserStatus(item, enterpriseSettings);
      const isHasOOOPast = find(datesOutOfOffice, (date) => date.diffDays <= 0);
      return {
        ...item,
        is_has_ooo_past: isHasOOOPast,
        dates_out_of_office: isHasOOOPast
          ? datesOutOfOffice
          : nextDatesOutOfOffline,
        roles_string: item.roles
          ? item.roles.map((r) => r.name.charAt(0)).join(",")
          : null,
        hubs_string: item.hubs
          ? item.hubs.map((hub) => hub.name).join(", ")
          : null,
        user_status: userStatus,
      };
    });
  }, [teams]);
  const selectedHubsData = find(
    props.assignedHubs,
    (item) => props.selectedHubs && props.selectedHubs.includes(item.id)
  );
  const selectedRolesData = find(
    props.roles,
    (item) => props.selectedRoles && props.selectedRoles.includes(item.id)
  );
  const columns = useMemo(() => {
    return [
      {
        Header: (
          <div className={classnames(bem.e("th-name"), bem.e("th-sort"))}>
            <FormattedMessage id="hub manager > name" />
            <div className={bem.e("sortable")}>
              <FaSortAsc
                className={classnames(bem.e("sortable-icon"), {
                  [bem.e("sortable-active")]: sortedName && !sortedName.desc,
                })}
              />
              <FaSortDesc
                className={classnames(bem.e("sortable-icon"), {
                  [bem.e("sortable-active")]: sortedName && sortedName.desc,
                })}
              />
            </div>
          </div>
        ),
        accessor: "name",
        Cell: ({ original }) => {
          return (
            <div className={bem.e("user-name-column")}>
              <Avatar
                src={original.avatar_url}
                size={40}
                className={bem.e("user-avatar")}
                name={original.name}
              />
              <div className={bem.e("user-info")}>
                <span className={bem.e("user-status")} />
                <span className={bem.e("user-name")}>
                  {original.name}
                  {original.is_has_ooo_past ? (
                    <span className={bem.e("vacation-icon")}>
                      <PalmTreeIcon />
                    </span>
                  ) : null}
                </span>
                {original.dates_out_of_office &&
                original.dates_out_of_office.length > 0
                  ? original.dates_out_of_office.map((date) => (
                      <div className={bem.e("out-of-office")}>
                        {props.intl.formatMessage({
                          id: "process > out as of",
                        })}{" "}
                        {formatDisplayDateWithTimezone(
                          date.from_date,
                          props.dateFormatByServer,
                          original.timezone
                        )}
                        {" - "}
                        {props.intl.formatMessage({
                          id: "process > returns",
                        })}{" "}
                        {formatDisplayDateWithTimezone(
                          date.to_date,
                          props.dateFormatByServer,
                          original.timezone
                        )}
                      </div>
                    ))
                  : null}
              </div>
            </div>
          );
        },
      },
      {
        Header: (
          <div className={bem.e("th-sort")}>
            <FormattedMessage id="hub manager > role(s)" />
            <div className={bem.e("sortable")}>
              <FaSortAsc
                className={classnames(bem.e("sortable-icon"), {
                  [bem.e("sortable-active")]: sortedRoles && !sortedRoles.desc,
                })}
              />
              <FaSortDesc
                className={classnames(bem.e("sortable-icon"), {
                  [bem.e("sortable-active")]: sortedRoles && sortedRoles.desc,
                })}
              />
            </div>
          </div>
        ),
        accessor: "roles_string",
        Cell: ({ original }) => {
          return (
            <span className={bem.e("list-roles")}>
              {original.roles.map((role, index) => (
                <span key={index} className={bem.e("role-name")}>
                  {role.name.charAt(0)}
                </span>
              ))}
            </span>
          );
        },
      },
      {
        Header: (
          <div className={bem.e("th-sort")}>
            <FormattedMessage id="hub manager > hub market" />
            <div className={bem.e("sortable")}>
              <FaSortAsc
                className={classnames(bem.e("sortable-icon"), {
                  [bem.e("sortable-active")]: sortedHubs && !sortedHubs.desc,
                })}
              />
              <FaSortDesc
                className={classnames(bem.e("sortable-icon"), {
                  [bem.e("sortable-active")]: sortedHubs && sortedHubs.desc,
                })}
              />
            </div>
          </div>
        ),
        accessor: "hubs_string",
        Cell: ({ original }) => {
          const hubsString = original.hubs_string;
          return (
            <Tooltip
              placement="topLeft"
              trigger={["click", "hover"]}
              overlayClassName={bem.e("tooltip")}
              overlay={
                <div className={"hubs-string text-caps"}>{hubsString}</div>
              }
            >
              <div className={"hubs-string text-caps"}>{hubsString}</div>
            </Tooltip>
          );
        },
      },
      {
        Header: (
          <div className={bem.e("th-sort")}>
            <FormattedMessage id="hub manager > current" />
            <div className={bem.e("sortable")}>
              <FaSortAsc
                className={classnames(bem.e("sortable-icon"), {
                  [bem.e("sortable-active")]:
                    sortedCurrent && !sortedCurrent.desc,
                })}
              />
              <FaSortDesc
                className={classnames(bem.e("sortable-icon"), {
                  [bem.e("sortable-active")]:
                    sortedCurrent && sortedCurrent.desc,
                })}
              />
            </div>
          </div>
        ),
        accessor: "current_tasks_count",
        headerClassName: bem.e("rt-header-center"),
        Cell: ({ original }) => {
          const latestOriginal =
            formattedTeams.find((item) => item.id === original.id) || original;

          return (
            <span className={bem.e("item-number")}>
              {latestOriginal.current_tasks_count}
            </span>
          );
        },
      },
      {
        Header: (
          <div className={bem.e("th-sort")}>
            <FormattedMessage id="hub manager > assigned" />
            <div className={bem.e("sortable")}>
              <FaSortAsc
                className={classnames(bem.e("sortable-icon"), {
                  [bem.e("sortable-active")]:
                    sortedAssigned && !sortedAssigned.desc,
                })}
              />
              <FaSortDesc
                className={classnames(bem.e("sortable-icon"), {
                  [bem.e("sortable-active")]:
                    sortedAssigned && sortedAssigned.desc,
                })}
              />
            </div>
          </div>
        ),
        accessor: "assigned_tasks_count",
        headerClassName: bem.e("rt-header-center"),
        Cell: ({ original }) => {
          const latestOriginal =
            formattedTeams.find((item) => item.id === original.id) || original;

          return (
            <span className={bem.e("item-number")}>
              {latestOriginal.assigned_tasks_count}
            </span>
          );
        },
      },
      {
        accessor: "actions",
        expander: true,
        Expander: ({ isExpanded, original }) => {
          return (
            <div
              className={bem.e("action-icon")}
              onClick={() => {
                props.setSelectedTeam(original);
              }}
            >
              {isExpanded ? <UpIcon /> : <DownIcon />}
            </div>
          );
        },
      },
    ];
  }, [formattedTeams]);
  return (
    <div className={bem.b()}>
      <div className={bem.e("page-top")}>
        <Breadcrumb className={bem.e("breadcrumb")}>
          {breadcrumbs.length &&
            breadcrumbs.map((breadcrumb, index) => (
              <BreadcrumbItem
                key={index}
                active={breadcrumb.active}
                onClick={() => history.push(breadcrumb.to)}
              >
                {breadcrumb.name}
              </BreadcrumbItem>
            ))}
        </Breadcrumb>
        <div className={bem.e("header-filter")}>
          <div
            className={bem.e("filter-view")}
            onClick={() => {
              props.setSidebarType("filter-view");
              props.setIsOpenSidebar(true);
              setExpanded({});
            }}
          >
            <FormattedMessage id="hub manager > filter view" />
            <RefineIcon />
          </div>
          <SearchBox
            placeholder={intl.formatMessage({
              id: "hub manager > placeholder search team management",
            })}
            onSearchClicked={props.onSearch}
            searchText={props.searchText}
            setSearchText={props.setSearchText}
          />
        </div>
      </div>
      {/* table */}
      <div className={classnames("page-container", bem.e("content"))}>
        <div className={bem.e("top-filtered")}>
          <div className={bem.e("total-text")}>
            <span>{intl.formatMessage({ id: "hub manager > viewing" })}</span>
            <strong>{formattedTeams.length}</strong>
            <span>{intl.formatMessage({ id: "hub manager > results" })}:</span>
          </div>
          {selectedHubsData ? (
            <div className={bem.e("filter-tag")}>
              {selectedHubsData.name}
              <span
                onClick={() =>
                  props.onSubmitFilter({
                    hub: "",
                    role:
                      props.selectedRoles && props.selectedRoles.length > 0
                        ? props.selectedRoles[0]
                        : "",
                  })
                }
              >
                <CloseSmallIcon />
              </span>
            </div>
          ) : null}
          {selectedRolesData ? (
            <div className={bem.e("filter-tag")}>
              {selectedRolesData.name}
              <span
                onClick={() =>
                  props.onSubmitFilter({
                    hub:
                      props.selectedHubs && props.selectedHubs.length > 0
                        ? props.selectedHubs[0]
                        : "",
                    role: "",
                  })
                }
              >
                <CloseSmallIcon />
              </span>
            </div>
          ) : null}
        </div>
        <Table
          className={classnames(bem.e("table"), {
            "empty-list": !get(formattedTeams, "length", 0),
          })}
          SubComponent={({ original, index }) => {
            return (
              <TeamListOrders
                user={original}
                key={index}
                onSelectProcess={props.onSelectProcess}
              />
            );
          }}
          onExpandedChange={(newExpanded, index) => {
            if (newExpanded[index[0]] === false) {
              newExpanded = {};
            } else {
              Object.keys(newExpanded).forEach((k) => {
                newExpanded[k] = parseInt(k) === index[0] ? {} : false;
              });
            }
            setExpanded(newExpanded);
          }}
          expanded={expanded}
          data={formattedTeams}
          showPagination={false}
          total={get(formattedTeams, "length", 0)}
          freezeWhenExpanded
          // Controlled props
          filtered={props.filtered}
          sorted={props.sorted}
          // Callbacks
          onSortedChange={(sorted) => props.setSorted(sorted)}
          onFilteredChange={(filtered) => props.setFiltered(filtered)}
          resized={[
            { id: "roles_string", value: 200 },
            { id: "hubs_string", value: 200 },
            { id: "current_tasks_count", value: 150 },
            { id: "assigned_tasks_count", value: 150 },
            { id: "actions", value: 65 },
          ]}
          getTrGroupProps={(_, row) => {
            const user = row.original;
            return {
              className: classnames(
                {
                  [bem.e("row-out-as")]: user.is_has_ooo_past,
                },
                bem.e(`row-${user.user_status}`)
              ),
            };
          }}
          columns={columns}
        />
      </div>
      <TeamSidebar
        isOpen={props.isOpenSidebar}
        onCancel={() => props.setIsOpenSidebar(false)}
        type={props.sidebarType}
        title={
          props.sidebarType === "filter-view"
            ? intl.formatMessage({ id: "hub manager > filter your view" })
            : get(props, "selectedProcess._source.client_name", "")
        }
        subtitle={
          props.sidebarType === "filter-view"
            ? ""
            : get(props, "selectedProcess._source.order_title", "")
        }
        children={
          props.sidebarType === "filter-view" ? (
            <ContentFilterView
              initialValues={{
                hub:
                  props.selectedHubs && props.selectedHubs.length > 0
                    ? props.selectedHubs[0]
                    : "",
                role:
                  props.selectedRoles && props.selectedRoles.length > 0
                    ? props.selectedRoles[0]
                    : "",
              }}
              onSubmit={onSubmitFilter}
            />
          ) : (
            <AssignTeam
              process={props.selectedProcess}
              onClose={() => props.setIsOpenSidebar(false)}
              buttonSubmitText={
                <FormattedMessage id={`process > button reassign tasks`} />
              }
              onSubmittedCallback={props.onAssignedSuccess}
              isShowAssignedVo={true}
            />
          )
        }
      />
      <BlockingComponent
        isBlocked={props.shouldBlockNavigation}
        modalProps={{
          title: intl.formatMessage({
            id: "reminder modal > wait, you have unsaved changes in this form",
          }),
          confirmTitle: intl.formatMessage({
            id: "reminder modal > button save",
          }),
          cancelTitle: intl.formatMessage({
            id: "reminder modal > button dismiss",
          }),
          isCancelConfirmedNavigation: true,
          onConfirm: () => {
            const buttonSubmit = document.getElementById(`btn-submit-`);
            // trigger click to submit the form
            if (buttonSubmit) buttonSubmit.click();
          },
        }}
      />
      <Spinner isLoading={isLoading} />
    </div>
  );
};

export default TeamManagement;
