import React from "react";
import { Row, Col } from "reactstrap";
import Messages from "components/Messages";
import Page from "components/Page";
import { FormattedMessage } from "react-intl";
import Spinner from "components/Spinner";
// import PrivilegedComponent from 'components/PrivilegedComponent';
// import { PRIVILEGES } from 'utils/constants';
// import { Redirect } from 'react-router';

const View = ({ isLoading }) => {
  return (
    <Page title={<FormattedMessage id="pages > activity stream" />}>
      {isLoading ? (
        <Spinner isLoading={isLoading} />
      ) : (
        <Row>
          <Col sm={12} md={{ size: 8, offset: 2 }}>
            <Messages />
          </Col>
        </Row>
      )}
    </Page>
  );
};

export default View;
