import View from "./view";
import { get } from "lodash";
import { EditorState, RichUtils, convertFromRaw } from "draft-js";
import { stateFromHTML } from "draft-js-import-html";
import { stateToHTML } from "draft-js-export-html";
import { compose, withState, withHandlers, lifecycle } from "recompose";
import createStyles from "draft-js-custom-styles";
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_STEP_FONT_SIZE,
  styleMap,
  decorator,
  importHTMLOptions,
  exportHTMLOptions,
  fontFamilyOptions,
  fixCursorBug,
} from "./config";
export const { styles, customStyleFn, exporter } = createStyles(
  ["font-size", "color", "text-transform", "font-family"],
  "CUSTOM_",
  styleMap
);
const defaultFontFamily = fontFamilyOptions.find((i) => i.is_default);

export default compose(
  withState(
    "editorState",
    "setEditorState",
    EditorState.createEmpty(decorator())
  ),
  withState(
    "isEditorReadonly",
    "setIsEditorReadonly",
    (props) => props.editorReadonly
  ),
  withState("currentEntityKey", "setCurrentEntityKey", null),
  withState("currentInsertButton", "setCurrentInsertButton", null),
  withState("fontSize", "setFontSize", DEFAULT_FONT_SIZE),
  withState(
    "fontFamily",
    "setFontFamily",
    defaultFontFamily.value || "sans-serif"
  ),
  withHandlers({
    toggleFontSize: ({
      fontSize,
      setEditorState,
      editorState,
      setFontSize,
    }) => (inlineStyle) => {
      let newFontSize = fontSize;
      if (inlineStyle === "INCREMENT_FONTSIZE") {
        newFontSize = fontSize + DEFAULT_STEP_FONT_SIZE;
        setFontSize(newFontSize);
      } else {
        if (fontSize > DEFAULT_STEP_FONT_SIZE) {
          newFontSize = fontSize - DEFAULT_STEP_FONT_SIZE;
          setFontSize(newFontSize);
        }
      }
      const newEditorState = styles.fontSize.add(
        editorState,
        `${newFontSize}px`
      );
      setEditorState(newEditorState);
    },
  }),
  withHandlers({
    handleKeyCommand: ({ editorState, setEditorState }) => async (command) => {
      const newState = RichUtils.handleKeyCommand(editorState, command);
      if (newState) {
        setEditorState(newState);
        return true;
      }
      return false;
    },
    toggleBlockType: ({ setEditorState, editorState }) => (blockType) => {
      setEditorState(RichUtils.toggleBlockType(editorState, blockType));
    },
    toggleInlineStyle: ({
      setEditorState,
      editorState,
      toggleFontSize,
      setFontFamily,
    }) => (inlineStyle, value) => {
      if (
        inlineStyle === "INCREMENT_FONTSIZE" ||
        inlineStyle === "DECREMENT_FONTSIZE"
      ) {
        toggleFontSize(inlineStyle);
      } else if (inlineStyle === "red") {
        setEditorState(styles.color.toggle(editorState, `red`));
      } else if (inlineStyle === "FONT_FAMILY") {
        setFontFamily(value);
        if (value) {
          setEditorState(styles.fontFamily.add(editorState, value));
        } else {
          setEditorState(styles.fontFamily.remove(editorState));
        }
      } else {
        setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
      }
    },
    onTab: ({ setEditorState, editorState }) => (e) => {
      const maxDepth = 4;
      setEditorState(RichUtils.onTab(e, editorState, maxDepth));
    },
    onChange: ({ setEditorState, onChangeEditor, fontFamily, editorState }) => (
      editor
    ) => {
      // always set font family
      const editorStateFontFamily = styles.fontFamily.add(editor, fontFamily);
      const nextEditorState = fixCursorBug(editorState, editorStateFontFamily);
      setEditorState(nextEditorState);
      const inlineStyles = exporter(nextEditorState);
      const html = stateToHTML(nextEditorState.getCurrentContent(), {
        ...inlineStyles,
        ...exportHTMLOptions,
      });
      onChangeEditor(html, nextEditorState);
    },
  }),
  withHandlers({
    onAddOrEditLink: (props) => (url) => {
      const { currentEntityKey } = props;
      const editorState = props.editorState;
      const contentState = editorState.getCurrentContent();
      let nextEditorState;

      if (currentEntityKey) {
        const contentStateWithEntity = contentState.replaceEntityData(
          currentEntityKey,
          {
            url,
          }
        );
        // Apply entity
        nextEditorState = EditorState.set(editorState, {
          currentContent: contentStateWithEntity,
        });
        // Apply selection
        nextEditorState = RichUtils.toggleLink(
          nextEditorState,
          nextEditorState.getSelection(),
          currentEntityKey
        );
      } else {
        const contentStateWithEntity = contentState.createEntity(
          "LINK",
          "MUTABLE",
          { url }
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        // Apply entity
        nextEditorState = EditorState.set(editorState, {
          currentContent: contentStateWithEntity,
        });
        // Apply selection
        nextEditorState = RichUtils.toggleLink(
          nextEditorState,
          nextEditorState.getSelection(),
          entityKey
        );
      }
      props.setEditorState(nextEditorState);
      props.onChange(nextEditorState);
      props.setCurrentEntityKey(null);
    },
  }),
  lifecycle({
    componentDidMount() {
      const { text } = this.props;
      if (text) {
        let initEditorState;
        try {
          initEditorState = convertFromRaw(JSON.parse(text));
          return typeof json === "object";
        } catch (e) {
          this.parseScript(this.props);
          return;
        }
      }
    },
    parseScript(props) {
      const {
        text,
        setCurrentInsertButton,
        onAddOrEditLink,
        setCurrentEntityKey,
        setIsEditorReadonly,
      } = props;
      let newStateFromHTML = stateFromHTML(text, importHTMLOptions);
      let editorState = EditorState.createWithContent(
        newStateFromHTML,
        decorator({
          setCurrentInsertButton,
          setIsEditorReadonly,
          setCurrentEntityKey,
          onAddOrEditLink,
        })
      );
      this.props.setEditorState(editorState);
      this.props.onChangeEditor(text, editorState);
    },
    componentWillReceiveProps(nextProps) {
      if (
        get(this.props, "text") !== get(nextProps, "text") ||
        (!get(this.props, "text") && get(this.props, "seconds") !== 0)
      ) {
        if (!get(nextProps, "text")) {
          this.props.setEditorState(
            EditorState.createEmpty(
              decorator({
                setCurrentInsertButton: this.props.setCurrentInsertButton,
                onAddOrEditLink: this.props.onAddOrEditLink,
                setCurrentEntityKey: this.props.setCurrentEntityKey,
                setIsEditorReadonly: this.props.setIsEditorReadonly,
              })
            )
          );
          this.props.onChangeEditor("", nextProps.editorState);
        } else {
          if (
            nextProps.process.data.fields.key !==
            this.props.process.data.fields.key
          ) {
            this.parseScript(nextProps);
          }
        }
      }
    },
    componentDidUpdate(nextProps, nextState) {
      if (get(this.props, "text") !== get(nextProps, "text")) {
        if (get(this.props, "text") && get(this.props, "seconds") === 0) {
          this.props.onChange(this.props.editorState);
        }
      }
    },
  })
)(View);
