import { compose } from "recompose";
import View from "./view";
import { injectIntl } from "react-intl";
import { withState, withHandlers, withPropsOnChange } from "recompose";
import moment from "moment-timezone";
const StationTime = compose(
  withState("realTime", "setRealTime", null),
  withState("intervalData", "setIntervalData", null),
  withHandlers({
    stop: ({ intervalData, setIntervalData }) => async () => {
      clearInterval(intervalData);
      setIntervalData(null);
    },
  }),
  withPropsOnChange(
    ["station"],
    ({ setRealTime, intervalData, stop, setIntervalData, station }) => {
      if (intervalData) {
        stop();
      }
      const interval = setInterval(() => {
        const time = station.timezone
          ? moment.tz(station.timezone).format("h:mma z")
          : moment().format("h:mma");
        setRealTime(time);
      }, 1000);
      setIntervalData(interval);
    }
  )
)(View);
export default injectIntl(StationTime);
