import { connect } from "react-redux";
import { deleteProcess, setActiveEditTab } from "store/actions/processes";
import { validateCopyGroups } from "store/actions/programmings";
import { injectIntl } from "react-intl";

import View from "./view";
const Enhanced = connect(
  (state) => ({
    auth: state.auth,
  }),
  {
    deleteProcess,
    setActiveEditTab,
    validateCopyGroups,
  }
)(View);

export default injectIntl(Enhanced);
