import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { get } from "lodash";
import { compose, lifecycle, withHandlers } from "recompose";
import { getClocksData, setClocksData } from "store/actions/programmings";
import ContestDescription from "./view";

const Enhanced = compose(
  connect(
    (state) => {
      const clocksData = get(state, "programmings.clocksData.data", {});
      return {
        contestTypes: get(clocksData, "contest_types", []),
        fulfillmentSettings: get(
          state,
          "stations.fulfillmentSettings.data",
          null
        ),
        dateFormatByServer: get(
          state,
          "servers.currentServer.data.date_format",
          "MM/DD/YYYY"
        ),
      };
    },
    { getClocksData, setClocksData }
  ),
  withHandlers({
    onGetClocksData: ({ getClocksData }) => (stationKey) => {
      getClocksData({
        data: {
          station_id: stationKey,
        },
      });
    },
  }),
  lifecycle({
    componentDidMount() {
      const selectedStationKey = get(
        this.props,
        "process.data.fields.order_stations_array.value.0.key"
      );
      if (selectedStationKey) {
        this.props.onGetClocksData(selectedStationKey);
      } else {
        this.props.setClocksData({});
      }
    },
  })
)(ContestDescription);

export default injectIntl(Enhanced);
