import OrderDetails from "./orderDetails";
import AssignTeam from "./assignTeam";
import ApproveScript from "./approveScript";
import WriteScript from "./writeScript";
import AssignVo from "./assignVo";
import VoiceOver from "./voiceOver";
import RecordScript from "./recordScript";
import ApproveRecording from "./approveRecording";
import Continuity from "./continuity";
import DubScript from "./dubScript";
import AirCheck from "./airCheck";
import Log from "./log";
const steps = {
  order_details: OrderDetails,
  assign_team: AssignTeam,
  write_script: WriteScript,
  approve_script: ApproveScript,
  assign_vo: AssignVo,
  voice_over: VoiceOver,
  record_script: RecordScript,
  approve_recording: ApproveRecording,
  continuity: Continuity,
  dub_script: DubScript,
  air_check: AirCheck,
  log: Log,
};
export default steps;
