import React from "react";
import bn from "utils/bemnames";
import { FormattedMessage } from "react-intl";
import JockWidget from "../Common/JockWidget";
import { chunk, find, get, includes, map } from "lodash";
import { StarIcon, HamburgerIcon } from "components/CustomIcons";
import StationImage from "../Common/StationImage";
import BulletinBoard from "../Common/BulletinBoard";
import TrendingHashtags from "../Common/TrendingHashtags";
import StormClosings from "../Common/StormClosings";
import SocialMedia from "../Common/SocialMedia";
import OtherLinks from "../Common/OtherLinks";
import TextEditor from "../Common/TextEditor";
import News from "../Common/News";
import PhoneNumbers from "../Common/PhoneNumbers";
import EventCalendar from "../Common/EventCalendar";
import NoWidgets from "../Common/NoWidgets";
import MultiViewModal from "../MultiViewModal";
import RssFeed from "../Common/RssFeed";
import StationTime from "../Common/StationTime";
import cs from "classnames";

const bem = bn.create("jock-console-right-sidebar");

export default function RightSidebar(props) {
  const {
    currentWidget,
    setCurrentWidget,
    isLoading,
    isOpenMultiViewModal,
    selectedSchedule,
    station,
    displaySettings,
    openRightPanel,
    toggleRightPanel,
  } = props;

  const getContent = () => {
    if (!currentWidget) return null;
    switch (currentWidget.type) {
      case "news":
        return <News widget={currentWidget} />;
      case "trending_hashtags":
        return <TrendingHashtags widget={currentWidget} />;
      case "social_media":
        return <SocialMedia widget={currentWidget} />;
      case "phone_numbers":
        return <PhoneNumbers widget={currentWidget} />;
      case "calendar":
      case "event_calendar":
        return <EventCalendar widget={currentWidget} />;
      case "other_links":
        return <OtherLinks widget={currentWidget} />;
      case "text_editor":
        return <TextEditor widget={currentWidget} />;
      case "rss_feed":
        return <RssFeed widget={currentWidget} />;
      case "bulletin_board":
      case "bulletin_board_replacement":
        return <BulletinBoard widget={currentWidget} />;
      case "storm_closings":
        return <StormClosings widget={currentWidget} />;
      default:
        return null;
    }
  };
  const contentTab = getContent();
  if (!selectedSchedule && !station) return null;
  const widgetSettings = get(
    displaySettings,
    `widget_settings.${station.id}`,
    {}
  );

  const widgets = map(widgetSettings.active_widgets, (widgetId) => {
    return find(widgetSettings.widgets, (item) => item.id === widgetId);
  });
  const multiViewWidgets = map(
    widgetSettings.multi_view_widgets,
    (widgetId) => {
      return find(widgetSettings.widgets, (item) => item.id === widgetId);
    }
  );
  const isEmptyWidgets = get(widgets, "length", 0) === 0 && !isLoading;

  return (
    <div
      className={cs(bem.b(), !openRightPanel && "hide")}
      onClick={() => {
        if (openRightPanel === false) toggleRightPanel(!openRightPanel);
      }}
    >
      <div
        className="collapseIcon"
        onClick={() => toggleRightPanel(!openRightPanel)}
      >
        <HamburgerIcon />
      </div>

      <div className={bem.e("jock-head")}>
        <StationImage station={station} />
        <div className={bem.e("jock-head-right")}>
          <div className={bem.e("jock-info")}>
            <strong>{station.name}</strong>
            <StationTime station={station} />
            {/* EDT */}
          </div>
          <div className={bem.e("jock-info")}>
            <strong>{station.call_letters}</strong>
            <div className={bem.e("text-small")}>{station.name}</div>
          </div>
        </div>
      </div>

      {isEmptyWidgets ? (
        <NoWidgets />
      ) : (
        <React.Fragment>
          <div className={bem.e("jock-tags")}>
            {map(chunk(widgets, 4), (chunkedWidgets, index) => (
              <div className="d-flex" key={`chunked-widgets-${index}`}>
                {map(chunkedWidgets, (widget) => (
                  <JockWidget
                    widget={widget}
                    key={widget.id}
                    isMultiView={includes(
                      widgetSettings.multi_view_widgets,
                      widget.id
                    )}
                    isActive={get(currentWidget, "id") === widget.id}
                    onClick={() => setCurrentWidget(widget)}
                  />
                ))}
              </div>
            ))}
          </div>
          <div className={bem.e("multiple-content")}>
            <div
              className={bem.e("multiple-view")}
              onClick={() => props.setIsOpenMultiViewModal(true)}
            >
              <span className={bem.e("star-icon")}>
                <StarIcon />
              </span>
              <FormattedMessage id="jock console > multiple view" />
            </div>
          </div>
          {contentTab}
        </React.Fragment>
      )}
      <MultiViewModal
        isOpen={isOpenMultiViewModal}
        onCancel={() => props.setIsOpenMultiViewModal(false)}
        onToggle={() => props.setIsOpenMultiViewModal(!isOpenMultiViewModal)}
        widgets={multiViewWidgets}
        station={station}
      />
    </div>
  );
}
