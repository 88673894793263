import { FormattedMessage } from "react-intl";
import React from "react";
import { get } from "lodash";
import moment from "moment";

const Schedule = {
  process_step_index: 3,
  key: "schedule",
  title: <FormattedMessage id="process > title schedule" />,
  caption_color: "#CC1EE6",
  step_roles: [],
  step_viewers_roles: [],
  step_editors_roles: [],
  step_viewers_privileges: ["programming_element_view"],
  step_editors_privileges: {
    or: ["programming_element_edit", "programming_element_delete"],
  },
  is_hidden_chat: true,
  no_edit_button: true,
  is_check_hidden_tab: true,
  is_ignore: true, // flag for ignore user have to complete this step.
  is_hidden: function(user, process, step) {
    const orderEndDate = get(process, "data.fields.order_end_date.value", "");
    if (orderEndDate && moment(orderEndDate).isBefore(moment(), "day")) {
      return true;
    }
    return false;
  },
  isOpen: function({ step, process, template, user }) {
    return true;
  },
  className: "programming-schedule",
  fields: [
    {
      component: "promotion_schedule",
      props: {
        filterProps: {
          isShowStationDropdown: false,
          isShowButtons: true,
          isShowSearchInput: false,
          isShowSettings: true,
        },
      },
    },
    {
      field: "is_publish",
    },
    {
      key: "save_button",
      text: <FormattedMessage id="process > button save" />,
      on_click: {
        validate: true,
        save: true,
      },
      fields: [
        {
          key: "schedule_status",
          value: "completed",
        },
        {
          key: "process_step",
          value: "first_undone",
        },
      ],
      confirmation_dialog_props: {
        description: (
          <FormattedMessage id="process > description to submit production order" />
        ),
        confirmTitle: <FormattedMessage id="process > button yes" />,
        cancelTitle: <FormattedMessage id="process > button no" />,
      },
      success_dialog_props: {
        title: <FormattedMessage id="process > title submitted" />,
        state: "success",
      },
    },
  ],
};
export default Schedule;
