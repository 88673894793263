import React from "react";
import ToastManager from "components/ToastManager";
import FormattedTaggedMessage from "components/FormattedTaggedMessage";
import moment from "moment";
import { FaClose, FaMailForward } from "react-icons/lib/fa";
import URL from "utils/urls";
import ChatInput from "components/ChatInput";
import { get, isObject } from "lodash";
import history from "components/History";
import { getSortUserName, overrideDismiss } from "utils/helpers";
import { FormattedMessage, injectIntl } from "react-intl";
import Avatar from "components/Avatar";
import bn from "utils/bemnames";
import classnames from "classnames";
import Spinner from "components/Spinner";
import useInfiniteScroll from "utils/useInfiniteScroll";
import useOutsideClick from "utils/useOutsideClick";
import { useRef } from "react";

const chatClass = bn.create("chatbox");
const bem = bn.create("notifications");
const Notifications = (props) => {
  const {
    notificationsData,
    getMoreItems,
    loading,
    toggleNotificationPopover,
  } = props;
  const items = get(notificationsData, "data.items", []);
  const isLoading = get(notificationsData, "loading", false) || loading;
  let totalNotifications = get(notificationsData, "data.total", 0);

  if (isObject(totalNotifications)) {
    totalNotifications = get(notificationsData, "data.total.value", 0);
  }
    
  const isShowEmpty = totalNotifications === 0 && !loading;
  const hasMore =
    get(notificationsData, "data.pages", 0) >
    get(notificationsData, "data.page", 0);
  useInfiniteScroll({
    useWindow: false,
    elementId: "notifications-scroll",
    onLoadMore: getMoreItems,
    shouldLoadMore: hasMore,
  });
  const containerRef = useRef();
  useOutsideClick(containerRef, (event) => {
    toggleNotificationPopover(event);
  });
  const createMessage = (message) => {
    let { user } = props;
    return {
      ...message,
      from: {
        id: user.id,
        name: user.name,
        avatar_url: user.avatar_url,
      },
    };
  };
  const onSubmitReply = ({ value: message }) => {
    const {
      intl,
      markAsSeenNotifications,
      setViewing,
      viewing,
      setLoading,
      setMessage,
    } = props;
    if (!message) {
      ToastManager.show({
        message: intl.formatMessage({
          id: "validate > message is required",
        }),
        autoDismiss: 1.5,
        level: "error",
      });
    } else {
      setLoading(true);
      props.replyMessage(
        {
          message: createMessage(viewing._source),
          chat: message, // chat text
        },
        (response) => {
          setLoading(false);
          const { data } = response;
          let alertMessage = "";
          if (data.message === "success") {
            markAsSeenNotifications({
              ids: [viewing._id],
            });
            setViewing(null);
            setMessage("");
            alertMessage = intl.formatMessage({
              id: "notifications > message send success",
            });
          } else {
            alertMessage = intl.formatMessage({
              id: "notifications > message send error",
            });
          }
          ToastManager.show({
            message: alertMessage,
            autoDismiss: 1.5,
            level: data.message === "success" ? "success" : "error",
          });
        }
      );
    }
  };
  const getMessage = (message) => {
    let text;
    switch (message.m_type) {
      case "chat":
        text = <FormattedTaggedMessage text={message.chat} />;
        break;
      case "placement_information":
        text = `${message.order_title} placement confirmation submitted`;
        break;
      case "trafficked":
        text = `${message.order_title} now in status "Trafficked"`;
        break;
      case "wo_new_order":
        text = `${message.order_title} is imported from Wide Orbit`;
        break;
      case "jc_tasks":
        text = `${message.message_string}`;
        break;
      case "sent_to_client":
        text = `${get(message, "from.name", "")} ${message.text} for ${message.order_title}`;
        break;
      default:
        text = `now in status ${
          get(message, "to_step", "")
            ? get(message, "to_step", "").replace(/_/g, " ")
            : "".replace(/_/, " ", "gi")
        }`;
        break;
    }
    return text;
  };
  const constructNotification = (payload, index) => {
    const {
      markAsSeenNotifications,
      notificationsData,
      user,
      viewing,
      setViewing,
    } = props;

    const message = payload._source;
    let totalNotifications = get(notificationsData, "data.total", 0);

    if (isObject(totalNotifications)) {
      totalNotifications = get(notificationsData, "data.total.value", 0);
    }
    let dismiss =
      message.needs_confirmation &&
      message.needs_confirmation.indexOf(user.id) > -1 ? (
        <button
          name="dismiss"
          className={bem.e("btn-dismiss")}
          onClick={() =>
            markAsSeenNotifications({
              ids: [payload._id],
            })
          }
        >
          <FaClose />
        </button>
      ) : null;
    // returns true if the element or one of its parents has the class classname
    const isFromDidmiss = (element) => {
      if (element.name === "dismiss" || element.name === "message") return true;
      return element.parentNode && isFromDidmiss(element.parentNode);
    };
    return (
      <div
        className={bem.e("notification-item")}
        key={`${payload._id}-${index}`}
        onClick={(e) => {
          if (overrideDismiss(e.target.classList[0], "cr-chatbox")) {
            return false;
          } else {
            if (!isFromDidmiss(e.target.parentNode)) {
              if (message.m_type === "jc_tasks") {
                history.push(URL.JOCK_CONSOLE());
              } else {
                history.push(
                  URL.VIEW_PROCESS({
                    template_id: message.template,
                    process_id: message.process_key,
                  })
                );
              }
              toggleNotificationPopover(e);
            }
          }
        }}
      >
        {dismiss}
        <h5 className={bem.e("subtitle")}>{get(message, "from.name", "")}</h5>
        <h2 className={bem.e("title")}>{message.order_title}</h2>
        <div className={bem.e("content")}>
          <div
            className={classnames(
              bem.e("user-avatar", {
                default: !get(message, "from.avatar_url", ""),
              })
            )}
          >
            {get(message, "from.avatar_url", "") ? (
              <Avatar
                src={get(payload, "message.from.avatar_url", "")}
                className={bem.e("avatar")}
              />
            ) : (
              <div className={classnames(bem.e("avatar"), "default")}>
                {getSortUserName(get(message.from, "name", ""))}
              </div>
            )}
          </div>
          <div className={bem.e("message")}>{getMessage(message)}</div>
        </div>
        {message.m_type !== "wo_new_order" && message.m_type !== "jc_tasks" ? (
          get(viewing, "_id") !== payload._id ? (
            <div className={bem.e("actions")}>
              <p className={bem.e("time")}>
                {moment.unix(message.created_ts).fromNow()}
              </p>
              <button
                className={bem.e("btn-reply")}
                onClick={() => setViewing(payload)}
              >
                <FaMailForward />
              </button>
            </div>
          ) : (
            <div className={chatClass.b()}>
              <ChatInput
                onSubmit={onSubmitReply}
                submitOnEnter
                emojiPosition={index > 1 ? "top" : "bottom"}
                isFilterUsers={false}
                mentionPosition={
                  totalNotifications === 1
                    ? "one"
                    : index === totalNotifications - 1
                    ? "bottom"
                    : "top"
                }
              />
            </div>
          )
        ) : null}
      </div>
    );
  };
  return (
    <div className={bem.b()} ref={containerRef}>
      {notificationsData && totalNotifications > 0 && (
        <React.Fragment>
          <div id="notifications-scroll">
            {items.map((message, index) =>
              constructNotification(message, index)
            )}
          </div>
        </React.Fragment>
      )}
      {isShowEmpty && (
        <div className={bem.e("no-notification")}>
          <FormattedMessage id="notifications > no new notifications" />
        </div>
      )}
      <Spinner size={20} isLoading={isLoading} />
    </div>
  );
};

Notifications.defaultProps = {
  notificationsData: {},
};

export default injectIntl(Notifications);
