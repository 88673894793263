import React, { useState, useEffect } from "react";
import bn from "utils/bemnames";
import Dropdown from "components/Dropdown";
import Checkbox from "components/Checkbox";
import { FormattedMessage } from "react-intl";
import TextInputFormatted from "components/TextInputFormatted";
import { Label } from "reactstrap";
import { get } from "lodash";
const bem = bn.create("dropdown-length");
export default function DropdownLength(props) {
  const { intl, onChange, isCustom, ...rest } = props;
  const length = get(rest, "value.value", 0);
  const minutes = Math.floor(length / 60);
  const seconds = length - minutes * 60;
  const [customValue, setCustomValue] = useState({
    min: minutes,
    sec: seconds,
  });
  useEffect(() => {
    setCustomValue({
      min: minutes,
      sec: seconds,
    });
  }, [minutes, seconds]);
  return (
    <div className={bem.b()}>
      {!isCustom ? (
        <div className={bem.e("dropdown")}>
          <Dropdown
            {...rest}
            onChange={(event) => {
              onChange(get(event, "value", null), false);
            }}
          />
        </div>
      ) : (
        <div className={bem.e("custom-inputs")}>
          <Label>{rest.label}</Label>
          <div className={bem.e("inputs")}>
            <TextInputFormatted
              placeholder={intl.formatMessage({
                id: "process > dropdown length min",
              })}
              value={customValue.min}
              onChange={(value) => {
                setCustomValue({
                  ...customValue,
                  min: value,
                });
                let newValue = parseInt(value);
                if (!value) {
                  newValue = 0;
                }
                const sec = customValue.sec ? parseInt(customValue.sec) : 0;
                onChange(newValue * 60 + sec, true);
              }}
            />
            <span className={bem.e("divider")}>:</span>
            <TextInputFormatted
              placeholder={intl.formatMessage({
                id: "process > dropdown length sec",
              })}
              value={customValue.sec}
              onChange={(value) => {
                setCustomValue({
                  ...customValue,
                  sec: value,
                });
                let newValue = parseInt(value);
                if (!value) {
                  newValue = 0;
                }
                const minutes = customValue.min ? parseInt(customValue.min) : 0;
                onChange(newValue + minutes * 60, true);
              }}
            />
          </div>
          {!!props.error && <span className="text-danger">{props.error}</span>}
        </div>
      )}
      <div className={bem.e("checkbox")}>
        <Checkbox
          checked={isCustom}
          disabled={rest.disabled}
          text={<FormattedMessage id="process > dropdown length custom" />}
          onChange={(checked) => {
            onChange(null, checked);
            setCustomValue({
              min: "",
              sec: "",
            });
          }}
        />
      </div>
    </div>
  );
}
