import React from "react";
import bn from "utils/bemnames";
import classNames from "classnames";
import PropTypes from "prop-types";
const bem = bn.create("jock-console");
export default function StormClosings(props) {
  const { isMultiView, widget } = props;
  return (
    <div
      className={classNames(
        bem.e("storm-closings"),
        bem.e("widget-detail-padding")
      )}
    >
      <div
        className={classNames(bem.e("storm-closings-head-title"), {
          [bem.e("head-title-multi-view")]: isMultiView,
        })}
        style={{
          backgroundColor: isMultiView ? widget.color : "",
        }}
      >
        {widget.name}
      </div>
      <div className={bem.e("storm-closings")}></div>
    </div>
  );
}
StormClosings.defaultProps = {
  isMultiView: false,
  widget: {},
};
StormClosings.propsTypes = {
  isMultiView: PropTypes.bool,
  widget: PropTypes.object.isRequired,
};
