import React, { useCallback, useMemo, useState } from "react";
import bn from "utils/bemnames";
import { Card, CardBody } from "reactstrap";
import { FormattedMessage } from "react-intl";
import ToggleSwitch from "components/ToggleSwitch";
import { UpIcon, DownIcon } from "components/CustomIcons";
import StationItem from "./components/StationItem";
import classNames from "classnames";
import Spinner from "components/Spinner";
import { useEffect } from "react";
import ConfirmationModal from "components/ConfirmationModal";

const bem = bn.create("zetta");

const Zetta = (props) => {
  const { intl, items, isLoading, onDelete } = props;
  const [isOpenDetails, setIsOpenDetails] = useState(true);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const [isActive, setIsActive] = useState(props.isZettaDubbingEnabled);
  const [selectedItem, setSelectedItem] = useState();
  const [isEditStations, setIsEditStations] = useState({});
  const markets = useMemo(() => {
    if (!items || typeof items !== "object") return [];
    let data = [];

    for (const [key, value] of Object.entries(items)) {
      data = [
        ...data,
        {
          name: key,
          stations: value,
        },
      ];
    }
    return data;
  }, [items]);

  useEffect(() => {
    if (props.isZettaDubbingEnabled !== isActive) {
      setIsActive(props.isZettaDubbingEnabled);
    }
  }, [props.isZettaDubbingEnabled]);
  const onToggleEdit = useCallback(
    (id) => {
      setIsEditStations({
        ...isEditStations,
        [id]: !isEditStations[id],
      });
    },
    [isEditStations]
  );
  return (
    <Card className={bem.b()}>
      <CardBody>
        <div className={bem.e("section-header")}>
          <div className={bem.e("card-title")}>
            <FormattedMessage id="zetta > zetta dubbing" />
          </div>
          <div className={bem.e("section-actions")}>
            <ToggleSwitch
              leftComponent={intl.formatMessage({
                id: "zetta > off",
              })}
              rightComponent={intl.formatMessage({
                id: "zetta > activated",
              })}
              name="type"
              switchProps={{
                checked: isActive,
                onChange: () => {
                  setIsActive(!isActive);
                  props.onToggle(isActive ? 0 : 1);
                },
                offColor: "#C2D4E0",
                onColor: "#795AFA",
                uncheckedIcon: false,
                checkedIcon: false,
                handleDiameter: 22,
                width: 50,
                height: 26,
              }}
            />
            <span
              className={bem.e("btn-icon")}
              onClick={() => setIsOpenDetails(!isOpenDetails)}
            >
              {isOpenDetails ? <UpIcon /> : <DownIcon />}
            </span>
          </div>
        </div>
        {isOpenDetails && (
          <div className={bem.e("section-content")}>
            <div className={bem.e("table")}>
              <div className={bem.e("head")}>
                <div className={bem.e("head-label")}>
                  <FormattedMessage id="zetta > entity" />
                </div>
                <div
                  className={classNames(bem.e("head-label"), bem.e("ftp-path"))}
                >
                  <FormattedMessage id="zetta > ftp path" />
                </div>
                <div className={classNames(bem.e("head-label"), bem.e("port"))}>
                  <FormattedMessage id="zetta > port" />
                </div>
                <div
                  className={classNames(
                    bem.e("head-label"),
                    bem.e("subfolder")
                  )}
                >
                  <FormattedMessage id="zetta > subfolder" />
                </div>
                <div
                  className={classNames(bem.e("head-label"), bem.e("username"))}
                >
                  <FormattedMessage id="zetta > username" />
                </div>
                <div
                  className={classNames(bem.e("head-label"), bem.e("password"))}
                >
                  <FormattedMessage id="zetta > password" />
                </div>
                <div className={bem.e("head-label")}></div>
              </div>
              <div className={bem.e("body")}>
                {markets.map((item, index) => {
                  return (
                    <div key={`market-${index}`} className={bem.e("tr")}>
                      <div className={bem.e("market-name")}>{item.name}</div>
                      {item.stations.map((station) => (
                        <StationItem
                          station={station}
                          bem={bem}
                          intl={intl}
                          key={`station-${station.id}`}
                          isEdit={isEditStations[station.id]}
                          setIsEdit={() => onToggleEdit(station.id)}
                          onSubmit={props.onSubmit}
                          onDelete={() => {
                            setIsConfirmationModalOpen(true);
                            setSelectedItem(station);
                          }}
                        />
                      ))}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </CardBody>
      <Spinner isLoading={isLoading} />
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        title={intl.formatMessage({
          id: "zetta > are you ready to remove",
        })}
        isCloseOutside={false}
        onConfirm={() => {
          setIsConfirmationModalOpen(false);
          onDelete(selectedItem.id);
        }}
        onToggle={() => setIsConfirmationModalOpen(!isConfirmationModalOpen)}
        onCancel={() => setIsConfirmationModalOpen(!isConfirmationModalOpen)}
      />
    </Card>
  );
};
export default Zetta;
