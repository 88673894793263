import View from "./view";
import { connect } from "react-redux";
import { setSidebarActive } from "store/actions/auth";
export default connect(
  (state) => {
    return {
      sidebarActive: state.auth.sidebar_active,
    };
  },
  { setSidebarActive }
)(View);
