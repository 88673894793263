import { FormattedMessage } from "react-intl";
import React from "react";
import timeOptions from "assets/data/time-options.json";
import { categoryList } from "utils/config";

const promotionRequestDetails = {
  process_step_index: 1,
  key: "promotion_request_details",
  caption_color: "#CC1EE6",
  title: <FormattedMessage id="process > title form" />,
  step_roles: [1, 2, 3], // this is the roles that are required to perform this step
  step_viewers_roles: [],
  step_editors_roles: [1, 2, 3],
  step_viewers_privileges: ["add_promotion_request"],
  step_editors_privileges: ["add_promotion_request"],
  description: null,
  priviliges: ["add_promotion_request"],
  status: "initial",
  disabled_until_completed_steps: [],
  step_container: "none",
  no_edit_button: true,
  fields: [
    {
      component: "promotion_request_details",
    },
    {
      field: "order_stations",
      title: <FormattedMessage id="process > field stations" />,
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        return value.length > 0
          ? true
          : "At least one station has to be selected";
      },
    },
    {
      field: "client_name",
      title: <FormattedMessage id="process > field client name" />,
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        return value.length > 1
          ? true
          : "Customer name must be at least 2 letters long";
      },
    },
    {
      field: "agency_name",
      title: <FormattedMessage id="process > field agency name" />,
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        return value.length > 2
          ? true
          : "Agency name must be at least 3 letters long";
      },
    },
    {
      field: "budget_amount",
      title: <FormattedMessage id="process > field budget amount" />,
      mandatory: true,
      formatted: "number",
      validation: function(step, item, process, template, user, value) {
        return value.length > 0 ? true : "Please enter the budget amount";
      },
    },
    {
      field: "account_executive",
      title: <FormattedMessage id="process > field account executive" />,
      roles: [4],
      validation: function(step, item, process, template, user, value) {
        return value !== "" ? true : "Please select a account manager";
      },
      mandatory: true,
    },
    {
      field: "account_exec_phone",
      title: <FormattedMessage id="process > field account exec phone" />,
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        return value.length > 0 &&
          value.match(new RegExp("^[0-9()-]{5,}$", "gi"))
          ? true
          : "Please enter a valid account exec phone number";
      },
    },
    {
      field: "account_exec_email",
      title: <FormattedMessage id="process > field account exec email" />,
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        return value.length > 0 &&
          value.match(new RegExp("[^@]+@[^\\.]+\\..+", "gi"))
          ? true
          : "Please enter a valid account exec email";
      },
    },
    {
      field: "appearance_event",
      title: <FormattedMessage id="process > field appearance event" />,
    },
    {
      field: "contest_giveaway",
      title: <FormattedMessage id="process > field contest giveaway" />,
    },
    {
      field: "digital",
      title: <FormattedMessage id="process > field digital" />,
    },
    {
      field: "other",
      title: <FormattedMessage id="process > field other" />,
    },
    {
      field: "event_type",
      title: <FormattedMessage id="process > field event type" />,
      props: {
        options: [
          {
            label: "Event type 1",
            value: "Event type 1",
          },
        ],
      },
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        if (
          !(
            process.fields.appearance_event &&
            process.fields.appearance_event.value
          )
        )
          return true;
        return value ? true : "Please select a event type";
      },
    },
    {
      field: "event_show_requested",
      title: <FormattedMessage id="process > field event show requested" />,
      props: {
        options: [
          {
            label: "Show 1",
            value: "Show 2",
          },
        ],
      },
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        if (
          !(
            process.fields.appearance_event &&
            process.fields.appearance_event.value
          )
        )
          return true;
        return value ? true : "Please select a show";
      },
    },
    {
      field: "event_pay_jock_directly",
      title: <FormattedMessage id="process > field event pay jock directly" />,
    },
    {
      field: "event_marketing_objective",
      title: (
        <FormattedMessage id="process > field event marketing objective" />
      ),
    },
    {
      field: "event_occurrence",
      title: <FormattedMessage id="process > field event occurrence" />,
      props: {
        options: [
          {
            label: "Show 1",
            value: "Show 2",
          },
        ],
      },
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        if (
          !(
            process.fields.appearance_event &&
            process.fields.appearance_event.value
          )
        )
          return true;
        return value ? true : "Please select a show";
      },
    },
    {
      field: "notes",
      title: <FormattedMessage id="process > field event notes" />,
    },
    {
      field: "event_date",
      title: <FormattedMessage id="process > field event date" />,
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        if (
          !(
            process.fields.appearance_event &&
            process.fields.appearance_event.value
          )
        )
          return true;
        if (value.length === 0) return "Please enter a date";
        if (isNaN(Date.parse(value))) return "Please enter a valid date";
        // if (moment(value).isBefore(moment())) return 'Date cannot be in the past';
        return true;
      },
    },
    {
      field: "start_time",
      title: <FormattedMessage id="process > field event start time" />,
      formatted: "time",
      mandatory: true,
      props: {
        width: "300",
        height: "55",
        allowNew: true,
        options: timeOptions,
      },
      validation: function(step, item, process, template, user, value) {
        if (
          !(
            process.fields.appearance_event &&
            process.fields.appearance_event.value
          )
        )
          return true;
        return value ? true : "Please select a time";
      },
    },
    {
      field: "venue_name",
      title: (
        <FormattedMessage id="process > field event location venue name" />
      ),
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        if (
          !(
            process.fields.appearance_event &&
            process.fields.appearance_event.value
          )
        )
          return true;
        return value ? true : "Please enter event venue name";
      },
    },
    {
      field: "location_information",
      title: (
        <FormattedMessage id="process > field event location information" />
      ),
    },
    {
      field: "event_venue_capacity",
      title: <FormattedMessage id="process > field event venue capacity" />,
      formatted: "number",
    },
    {
      field: "event_held_indoors",
      title: <FormattedMessage id="process > field event held indoors" />,
    },
    {
      field: "order_title",
      title: <FormattedMessage id="process > field contest name" />,
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        if (
          !(
            process.fields.contest_giveaway &&
            process.fields.contest_giveaway.value
          )
        )
          return true;
        return value.length > 2
          ? true
          : "Contest name must be at least 3 letters long";
      },
    },
    {
      field: "contest_type",
      title: (
        <FormattedMessage id="process > field contest requested contest type" />
      ),
      props: {
        options: [
          {
            label: (
              <FormattedMessage id="process > option label call in contest" />
            ),
            value: "Call in Contest",
          },
          {
            label: <FormattedMessage id="process > option label ..." />,
            value: "...",
          },
        ],
      },
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        if (
          !(
            process.fields.contest_giveaway &&
            process.fields.contest_giveaway.value
          )
        )
          return true;
        return value ? true : "Please enter a contest type";
      },
    },
    {
      field: "contest_marketing_objective",
      title: (
        <FormattedMessage id="process > field contest marketing objective" />
      ),
    },
    {
      field: "contest_prize",
      title: <FormattedMessage id="process > field contest prize" />,
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        if (
          !(
            process.fields.contest_giveaway &&
            process.fields.contest_giveaway.value
          )
        )
          return true;
        return value.length > 2
          ? true
          : "Prize name must be at least 3 letters long";
      },
    },
    {
      field: "contest_prize_description",
      title: (
        <FormattedMessage id="process > field contest prize description" />
      ),
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        if (
          !(
            process.fields.contest_giveaway &&
            process.fields.contest_giveaway.value
          )
        )
          return true;
        return value.length > 2
          ? true
          : "Prize description must be at least 3 letters long";
      },
    },
    {
      field: "contest_categories",
      title: <FormattedMessage id="process > field contest categories" />,
      props: {
        options: categoryList,
      },
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        if (
          !(
            process.fields.contest_giveaway &&
            process.fields.contest_giveaway.value
          )
        )
          return true;
        return value ? true : "Please select a category";
      },
    },
    {
      field: "contest_quantity",
      title: <FormattedMessage id="process > field contest quantity" />,
      props: {
        options: [
          {
            label: "1",
            value: "1",
          },
          {
            label: "2",
            value: "2",
          },
          {
            label: "3",
            value: "3",
          },
          {
            label: "4",
            value: "4",
          },
          {
            label: "5",
            value: "5",
          },
        ],
      },
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        if (
          !(
            process.fields.contest_giveaway &&
            process.fields.contest_giveaway.value
          )
        )
          return true;
        return value ? true : "Please select a quantity";
      },
    },
    {
      field: "contest_unit_value",
      title: <FormattedMessage id="process > field contest unit value" />,
      mandatory: true,
      formatted: "number",
      validation: function(step, item, process, template, user, value) {
        if (
          !(
            process.fields.contest_giveaway &&
            process.fields.contest_giveaway.value
          )
        )
          return true;
        return value.length > 0 ? true : "Please enter the unit value";
      },
    },
    {
      key: "save_button",
      text: <FormattedMessage id="process > button save" />,
      // "component": "button",
      on_click: {
        validate: true,
        save: true,
      },
      fields: [
        {
          key: "promotion_request_details_status",
          // "value": "submitted"
        },
        {
          key: "process_step",
          value: "done",
        },
      ],
      confirmation_dialog_props: {
        description: (
          <FormattedMessage id="process > description to submit promotions" />
        ),
        confirmTitle: <FormattedMessage id="process > button yes" />,
        cancelTitle: <FormattedMessage id="process > button no" />,
      },
      success_dialog_props: {
        title: <FormattedMessage id="process > title submitted" />,
        state: "success",
      },
    },
  ],
};
export default promotionRequestDetails;
