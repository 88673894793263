import React from 'react';
import { Col} from "reactstrap";
import classnames from 'classnames';
import moment from 'moment';
import { get, first, map, slice, filter, includes } from 'lodash';

import { EditIcon } from 'components/CustomIcons';

const HourBlock = (props) => {

	const {
		bem,
		isBlockFocused,
		setIsBlockFocused,
		onClick,
		selectedTimeSlots,
		isCollapsed,
	} = props;

	const renderColorIndicators = (colors) => {
		return colors.map((color, index) => {

			let indicatorWidth = 13;
			let maxNoOfIndicators = window.innerWidth < 1699 ? parseInt(128 / indicatorWidth) : parseInt( 164 / indicatorWidth);
			let style = {
				backgroundColor: color,
				right: `${index * indicatorWidth}px`,
				borderRadius: (index === 0) ? '0px 4px 4px 0px' : 'unset',
			};

			if(index + 1 <= maxNoOfIndicators){
				return (
					<span 
						key={color}
						className={bem.e('slot-color-indicator')} 
						style={style}
					></span>
				);
			}
		});
	}


	const renderSlots = () => {

		let slots = props.slots;
		const selectedTimeSlotsId = selectedTimeSlots.map(item => item.value);
		if(isCollapsed){
			slots = slice(slots, 0, 1);
		}

		return map(slots, slot => {

			const slotTime = moment().set({hours: 0, minutes : parseInt(slot.start_time), seconds : 0, millisecond: 0});
			const slotLabels = get(slot, "slot_labels", []);
			const colors = filter(slotLabels, slotLabel => includes(selectedTimeSlotsId, slotLabel.id)).map(i => i.color);
			let backgroundColor = (colors.length === 1) ? first(colors) : ((selectedTimeSlots.length > 0 && colors.length === 0) ? '#B6CDDB' : '#829FB1');

			if(isBlockFocused){
				backgroundColor = '#795AFA';
			}

			return (
				<div 
					key={slot.id}
					className={classnames(bem.e('timeslot'), {'active' : isBlockFocused})}
					style={{backgroundColor: backgroundColor}}
				>
					<span className="time">{slotTime.format('h:mm a')}</span>
					{!isBlockFocused && colors.length > 1 ? renderColorIndicators(colors) : null}
					{isBlockFocused && (<span className={bem.e('slot-edit-icon')}>
						<EditIcon color="#FFFFFF" width="19" height="18" />
					</span>)}
				</div>
			);
		})
	}
	return (
		<Col xs={1} 
			className={classnames(bem.e('hour-block'), 'p-0 m-0')}
			onMouseEnter={e => setIsBlockFocused(true)}
			onMouseLeave={e => setIsBlockFocused(false)}
			onClick={onClick}
		>
			{renderSlots()}
		</Col>
	);
}

export default HourBlock;