import React, { useEffect, useRef } from "react";
import bn from "utils/bemnames";
import { FaPlus } from "react-icons/lib/fa";
import * as Yup from "yup";
import PropTypes from "prop-types";
import ToggleSwitch from "components/ToggleSwitch";
import { Formik, yupToFormErrors, validateYupSchema } from "formik";
import classnames from "classnames";
import SelectStations from "components/SelectStations";
import ConfirmationModal from "components/ConfirmationModal";
import ChannelManagerDropdown from "components/ChannelManagerDropdown";
import TextInput from "components/TextInput";
import { FormattedMessage } from "react-intl";
import { Asterisk } from "components/Elements";
import Dropdown from "components/Dropdown";
import ToastManager from "components/ToastManager";
import { get, findIndex, map, split, intersection, values, find } from "lodash";
import { Button, Form, FormGroup, Row, Col, Label } from "reactstrap";
import FieldsFormBuilderModal from "components/FormBuilder/FieldsFormBuilderModal";
import PrivilegedComponent from "components/PrivilegedComponent";
import { PRIVILEGES } from "utils/constants";
import SortableComponent from "./SortableComponent";
import { uuidv4 } from "utils/helpers";

const bem = bn.create("channel-manager-form");

const ChannelManagerForm = ({
  intl,
  onSubmitForm,
  onClose,
  setChannelName,
  channel,
  onDelete,
  isOpenContent,
  setIsOpenContent,
  isView,
  setIsView,
  setChannelNewName,
  isOpenFieldsFormBuilderModal,
  setIsOpenFieldsFormBuilderModal,
  onCancelChannel,
  setIsOpenForm,
  setCurrentAction,
  setIsHiddenForNew,
  isOpenConfirmationActionsUnsavedModal,
  isOpenConfirmationActionsUnsavedModalNewChannel,
  setIsOpenConfirmationActionsUnsavedModal,
  setIsOpenConfirmationActionsUnsavedModalNewChannel,
  setCurrentPreviewChannel,
  setIsOpenChannelPreviewModal,
  isOpenChannelPreviewModal,
  onCloneChannel,
  currentAction,
  onSaveHiddenChannel,
  highlightFields,
  setHighlightFields,
  getDefaultSystemWorkfolows,
  channelIcon,
  sharedStationData,
  currentStation,
  highlightSelection,
  setHighlightSelection,
  setShouldBlockNavigation,
  shouldBlockNavigation,
  isOpenAddRulesConfirmationModal,
  setIsOpenAddRulesConfirmationModal,
  setIsForceSubmitForm,
  onGetChannels,
}) => {
  const sharedStationPrivileges = values(
    get(sharedStationData, "user_privileges")
  ).filter((item) => item);
  const isAdd = get(channel, "id", "").indexOf("_new_") === 0;
  const formikRef = useRef(null);
  const validateBeforeSubmit = (values, formikObject) => {
    const { fields } = values;
    let errors;
    let isWarningMissingRules = false;
    fields.forEach((field, index) => {
      const { key } = field;
      if (key === "dropdown" || "radio" || "checkbox") {
        const { options } = field;
        let indexError = findIndex(
          options,
          ({ label, value }) => !label || !value
        );
        if (indexError > -1) {
          let fieldsErrors = errors ? errors.fields : [];
          fieldsErrors[index] = {
            ...fieldsErrors[index],
            options: intl.formatMessage({
              id: "validate > option is required",
            }),
          };
          errors = {
            ...errors,
            fields: fieldsErrors,
          };
        }
        // the condition logic is only show for dropdown and radio
        if (field.is_condition_logic && key !== "checkbox") {
          const optionMissingRule = find(field.options, (i) => {
            return i.rule;
          });
          if (!optionMissingRule) isWarningMissingRules = true;
        }
      }
      if (key === "demographics") {
        const { includes } = field;
        let isMissingIncludes = !includes || includes.length === 0;
        if (isMissingIncludes) {
          let fieldsErrors = errors ? errors.fields : [];
          fieldsErrors[index] = {
            ...fieldsErrors[index],
            includes: intl.formatMessage({
              id: "validate > option is required",
            }),
          };
          errors = {
            ...errors,
            fields: fieldsErrors,
          };
        }
      }
    });
    if (errors) {
      formikObject.setErrors(errors);
    } else {
      if (isWarningMissingRules) {
        setIsOpenAddRulesConfirmationModal(true);
      } else {
        onSubmitForm(values, formikObject);
      }
    }
  };
  const initialValues = {
    name: get(channel, "name", ""),
    channel_managers: get(channel, "channel_managers", []),
    stations: get(channel, "stations", []),
    is_hidden: get(channel, "is_hidden", true),
    id: get(channel, "id", null),
    fields: get(channel, "fields", []),
    workflow: get(channel, "workflow", ""),
    media_type: get(channel, "media_type", ""),
    channel_icon: channelIcon,
  };
  useEffect(() => {
    if (formikRef.current && formikRef.current.setFieldValue) {
      formikRef.current.setFieldValue("channel_icon", channelIcon);
    }
  }, [channelIcon]);
  if (initialValues.workflow) {
    initialValues.workflow = {
      value: initialValues.workflow.name,
      label: initialValues.workflow.name,
      workflow: initialValues.workflow,
    };
  }
  const defaultSystemWorkfolows = getDefaultSystemWorkfolows();
  let workflowOptions = defaultSystemWorkfolows
    .filter((workflow) => workflow.id === "digital")
    .map((workflow) => {
      let w = workflow;
      return { value: w.name, label: w.name, workflow: w };
    });
  initialValues.workflow = workflowOptions[0];

  const onSubmitOutSideForm = () => {
    formikRef.current.submitForm();
    setTimeout(() => {
      const formErrors = get(formikRef, "current.state.errors");
      if (formErrors && Object.keys(formErrors).length) {
        ToastManager.show({
          title: intl.formatMessage({
            id: "toast > title not saved",
          }),
          message: intl.formatMessage({
            id: "toast > message error please correct the hilighted fields",
          }),
          level: "error",
        });
        return;
      }
    }, 20);
  };

  const formSchema = Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage({ id: "validate > name is required" })
    ),
    workflow: Yup.object().required(
      intl.formatMessage({ id: "validate > workflow is required" })
    ),
    media_type: Yup.object().required(
      intl.formatMessage({ id: "validate > media type is required" })
    ),
    channel_managers: Yup.array().required(
      intl.formatMessage({ id: "validate > channel manager is required" })
    ),
    fields: Yup.array().of(
      Yup.object().shape({
        label: Yup.string()
          .required(intl.formatMessage({ id: "validate > label is required" }))
          .test({
            name: "duplicate_label",
            message: intl.formatMessage({
              id: "validate > label name already exists",
            }),
            test: function(value) {
              let count = 0;
              this.options.context.customFields.forEach((label) => {
                if (
                  value.trim() !== "" &&
                  label.trim().toLowerCase() === value.trim().toLowerCase()
                )
                  count++;
              });
              return !(count > 1);
            },
          }),
      })
    ),
  });

  const renderForm = () => {
    const form = (
      <React.Fragment>
        <Formik
          ref={formikRef}
          enableReinitialize
          initialValues={initialValues}
          validate={(values) => {
            try {
              validateYupSchema(values, formSchema, true, {
                customFields: map(values.fields, (field) => field.label),
              });
            } catch (err) {
              return yupToFormErrors(err);
            }
            return {};
          }}
          onSubmit={validateBeforeSubmit}
        >
          {(formProps) => {
            const errors = formProps.errors;
            const touched = formProps.touched;
            const values = formProps.values;
            const onSelectedField = (value) => {
              setIsOpenFieldsFormBuilderModal(false);
              // eslint-disable-next-line no-lone-blocks
              let newField = {
                ...value,
                label: "",
                value: "",
                placeholder: "",
                is_required: true,
                is_new: true,
                uuid: uuidv4(),
              };
              switch (value.key) {
                case "dropdown":
                  newField.is_condition_logic = false;
                  break;
                case "radio":
                  newField.is_condition_logic = false;
                  break;
                case "checkbox":
                  newField.is_condition_logic = false;
                  break;
                case "date_selector":
                  newField.type = "single";
                  break;
                default:
                  break;
              }
              const fields = [...values.fields, newField];
              formProps.setFieldValue("fields", fields);
              formProps.setFieldTouched("fields", false);
              setShouldBlockNavigation(true);
            };
            return (
              <Form
                onSubmit={formProps.handleSubmit}
                noValidate
                className={bem.e("form")}
                onChange={() => {
                  setShouldBlockNavigation(true);
                }}
              >
                <div
                  className={classnames(bem.e("channel-form"), {
                    [bem.e("channel-form-add")]: isAdd,
                  })}
                >
                  <div className={bem.e("fields")}>
                    <Row>
                      <Col xs={6}>
                        <FormGroup>
                          <TextInput
                            label={
                              <span>
                                <FormattedMessage id="channel manager > name" />
                                <Asterisk>*</Asterisk>
                              </span>
                            }
                            type="text"
                            name="name"
                            placeholder={intl.formatMessage({
                              id: "channel manager > name",
                            })}
                            value={values.name}
                            error={touched.name && errors.name}
                            onChange={({ target }) => {
                              formProps.setFieldValue("name", target.value);
                              setChannelName(target.value);
                              if (isAdd) {
                                setChannelNewName(target.value);
                              }
                              setShouldBlockNavigation(true);
                            }}
                            onBlur={formProps.handleBlur}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={6}>
                        <FormGroup>
                          <ChannelManagerDropdown
                            label={
                              <span>
                                <FormattedMessage id="channel manager > channel manager" />{" "}
                                <Asterisk>*</Asterisk>
                              </span>
                            }
                            filterStation={currentStation}
                            value={values.channel_managers}
                            onChange={(value) => {
                              formProps.setFieldValue(
                                "channel_managers",
                                value
                              );
                              setShouldBlockNavigation(true);
                            }}
                            error={
                              touched.channel_managers &&
                              errors.channel_managers
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6}>
                        <FormGroup>
                          <div>
                            <Label>
                              <FormattedMessage id="channel manager > media type" />
                              <Asterisk>*</Asterisk>
                            </Label>
                            <Dropdown
                              options={[
                                {
                                  value: "Social Media",
                                  label: "Social Media",
                                },
                                {
                                  value: "Banner Ad",
                                  label: "Banner Ad",
                                },
                                {
                                  value: "Video Ad",
                                  label: "Video Ad",
                                },
                                {
                                  value: "Text Ad",
                                  label: "Text Ad",
                                },
                                {
                                  value: "Other",
                                  label: "Other",
                                },
                              ]}
                              name="media_type"
                              placeholder={intl.formatMessage({
                                id:
                                  "channel manager > select media type placeholder",
                              })}
                              value={values.media_type}
                              error={touched.media_type && errors.media_type}
                              onChange={(target) => {
                                formProps.setFieldValue("media_type", target);
                                setShouldBlockNavigation(true);
                              }}
                              onBlur={formProps.handleBlur}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col xs={6}>
                        <FormGroup>
                          <SelectStations
                            name="stations"
                            item={{ field: "stations" }}
                            label={
                              <FormattedMessage id="channel manager > share channel with stations" />
                            }
                            required={false}
                            placeholder={intl.formatMessage({
                              id: "channel manager > select one or more",
                            })}
                            onChange={(value) => {
                              formProps.setFieldValue("stations", value);
                              setShouldBlockNavigation(true);
                            }}
                            template={{ key: "digital" }}
                            isFilterByServerConfig={true}
                            checked={{ value: values.stations }}
                            validationState={errors}
                            hideSelectedOptions={true}
                            allowSortOptions={true}
                            allowDeselectOption={false}
                            isInit={isAdd}
                            allowHideSelectIfOptions={0}
                            exceptOptions={
                              currentStation ? [currentStation.value] : []
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6}>
                        <FormGroup>
                          <div>
                            <Label>
                              <FormattedMessage id="channel manager > workflow" />
                              <Asterisk>*</Asterisk>
                            </Label>
                            <div style={{ lineHeight: "36px" }}>
                              <Dropdown
                                // placeholder={<FormattedMessage id="channel manager > select workflow"/>}
                                // value={values.workflow ? values.workflow : null}
                                styles={{
                                  option: (base, state) => {
                                    if (state.value !== "Create new workflow")
                                      return base;
                                    return {
                                      ...base,
                                      marginTop: `10px`,
                                      paddingTop: `16px`,
                                      borderTop: `1px solid #ccc`,
                                      color: `blue`,
                                    };
                                  },
                                }}
                                options={workflowOptions}
                                name="workflow"
                                placeholder={intl.formatMessage({
                                  id:
                                    "channel manager > select workflow placeholder",
                                })}
                                value={initialValues.workflow}
                                error={touched.workflow && errors.workflow}
                                onChange={(target) => {
                                  if (target.value === "Create new workflow") {
                                    // open flow manager
                                    return;
                                  }
                                  setShouldBlockNavigation(true);
                                  formProps.setFieldValue("workflow", target);
                                }}
                                onBlur={formProps.handleBlur}
                              />
                            </div>
                            {!!get(errors, "workflow", "") &&
                              get(touched, "workflow", false) && (
                                <span className="text-danger">
                                  {get(errors, "workflow", "")}
                                </span>
                              )}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <Row>
                    <Col>
                      <div className={bem.e("custom-fields")}>
                        <div className={bem.e("custom-fields-container")}>
                          <div className={bem.e("custom-fields-sort")}>
                            {/* {renderCustomFields(values.fields, formProps, false)} */}
                            <SortableComponent
                              data={values.fields}
                              onSorted={(sortItems) => {
                                formProps.setFieldValue("fields", sortItems);
                                setShouldBlockNavigation(true);
                              }}
                              formProps={{
                                ...formProps,
                                highlightFields,
                                setHighlightFields,
                                isView: false,
                                disabled: false,
                                highlightSelection,
                                setHighlightSelection,
                                setIsOpenConfirmationActionsUnsavedModal,
                                setIsForceSubmitForm,
                                setShouldBlockNavigation,
                              }}
                            />
                          </div>
                        </div>
                        <div className={bem.e("custom-fields-button")}>
                          <Button
                            className={bem.e("button-create")}
                            color=""
                            onClick={() =>
                              setIsOpenFieldsFormBuilderModal(
                                !isOpenFieldsFormBuilderModal
                              )
                            }
                          >
                            <i className={bem.e("button-icon")}>
                              <FaPlus />
                            </i>
                            <FormattedMessage id="channel manager > add a field" />
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className={bem.e("buttons")}>
                    {formProps.touched.fields && get(errors, "fields.length") && (
                      <div className={bem.e("alert-error-custom-fields")}>
                        <FormattedMessage id="channel manager > please customize your field labels" />
                      </div>
                    )}
                    <PrivilegedComponent
                      privileges={sharedStationPrivileges}
                      requires={[PRIVILEGES.EDIT_CHANNELS]}
                    >
                      <Row>
                        <Col xs={6}>
                          <FormGroup>
                            <Button
                              color="blue"
                              type="submit"
                              className="btn btn-radius"
                              id={
                                isAdd
                                  ? `btn-submit-add`
                                  : `btn-submit-${channel.id}`
                              }
                              onClick={() => {
                                setTimeout(() => {
                                  const formErrors = get(
                                    formikRef,
                                    "current.state.errors"
                                  );
                                  if (
                                    formErrors &&
                                    Object.keys(formErrors).length
                                  ) {
                                    ToastManager.show({
                                      title: intl.formatMessage({
                                        id: "toast > title not saved",
                                      }),
                                      message: intl.formatMessage({
                                        id:
                                          "toast > message error please correct the hilighted fields",
                                      }),
                                      level: "error",
                                    });
                                    return;
                                  }
                                }, 20);
                              }}
                            >
                              <FormattedMessage
                                id={`channel manager > button submit`}
                              />
                            </Button>
                            <Button
                              color="blue"
                              outline
                              className="btn btn-radius"
                              onClick={() => {
                                if (isAdd) {
                                  setIsOpenConfirmationActionsUnsavedModalNewChannel(
                                    true
                                  );
                                  return;
                                }
                                if (values.id) {
                                  formikRef.current.resetForm({
                                    ...initialValues,
                                    // clear any option is empty
                                    fields: initialValues.fields.map(
                                      (field) => {
                                        if (
                                          field.options &&
                                          field.options.length > 0
                                        ) {
                                          return {
                                            ...field,
                                            options: field.options.filter(
                                              (i) => i.value
                                            ),
                                          };
                                        }
                                        return field;
                                      }
                                    ),
                                  });
                                  onCancelChannel();
                                }
                                onClose();
                              }}
                            >
                              <FormattedMessage
                                id={`channel manager > button cancel`}
                              />
                            </Button>
                          </FormGroup>
                        </Col>
                        <Col xs={6} className={bem.e("align-right")}>
                          <Button
                            color="blue"
                            outline
                            type="button"
                            className="btn btn-radius"
                            onClick={() => {
                              if (isAdd) {
                                setIsOpenConfirmationActionsUnsavedModalNewChannel(
                                  true
                                );
                                return;
                              } else {
                                onDelete(channel);
                              }
                            }}
                          >
                            <FormattedMessage
                              id={`channel manager > button delete channel`}
                            />
                          </Button>
                        </Col>
                      </Row>
                    </PrivilegedComponent>
                  </div>
                </div>

                {isAdd && (
                  <div className={classnames(bem.e("channel-checkbox"), "new")}>
                    <PrivilegedComponent
                      requires={[PRIVILEGES.PUBLISH_CHANNELS]}
                    >
                      {({ inValid }) => {
                        if (inValid) {
                          return (
                            <ToggleSwitch
                              leftComponent={null}
                              rightComponent={null}
                              name="is_hidden"
                              switchProps={{
                                checked: !values.is_hidden,
                                onChange: (checked) => {
                                  formProps.setFieldValue(
                                    "is_hidden",
                                    !checked
                                  );
                                  if (isAdd) {
                                    setIsHiddenForNew(!checked);
                                  }
                                },
                                disabled: false,
                                offColor: "#C2D4E0",
                                onColor: "#795AFA",
                                uncheckedIcon: false,
                                checkedIcon: false,
                                boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.282)",
                                height: 26.44,
                                width: 48.48,
                                handleDiameter: 22.92,
                              }}
                            />
                          );
                        } else {
                          return (
                            <ToggleSwitch
                              leftComponent={null}
                              rightComponent={null}
                              name="is_hidden"
                              switchProps={{
                                checked: !values.is_hidden,
                                onChange: (checked) => {},
                                disabled: true,
                                offColor: "#C2D4E0",
                                onColor: "#795AFA",
                                uncheckedIcon: false,
                                checkedIcon: false,
                                boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.282)",
                                height: 26.44,
                                width: 48.48,
                                handleDiameter: 22.92,
                              }}
                            />
                          );
                        }
                      }}
                    </PrivilegedComponent>
                  </div>
                )}
                {/* formbuilder modal select fields */}
                <FieldsFormBuilderModal
                  isOpen={isOpenFieldsFormBuilderModal}
                  onSelect={onSelectedField}
                  onToggle={() =>
                    setIsOpenFieldsFormBuilderModal(
                      !isOpenFieldsFormBuilderModal
                    )
                  }
                />
                {/* Add rules confirmation modal */}
                <ConfirmationModal
                  isOpen={isOpenAddRulesConfirmationModal}
                  title={intl.formatMessage({
                    id:
                      "channel manager > would you like to add rules to your fields",
                  })}
                  isCloseOutside={false}
                  className={bem.e("confirmation-modal")}
                  description={intl.formatMessage({
                    id:
                      "channel manager > rules allow you to add conditional logic to form answers",
                  })}
                  confirmTitle={intl.formatMessage({
                    id: "channel manager > button yes",
                  })}
                  cancelTitle={intl.formatMessage({
                    id: "channel manager > button no",
                  })}
                  onConfirm={() => {
                    setIsForceSubmitForm(false);
                    setIsOpenAddRulesConfirmationModal(false);
                  }}
                  onToggle={() =>
                    setIsOpenAddRulesConfirmationModal(
                      !isOpenAddRulesConfirmationModal
                    )
                  }
                  onCancel={() => {
                    setIsOpenAddRulesConfirmationModal(false);
                    onSubmitForm(values, formProps);
                  }}
                />
              </Form>
            );
          }}
        </Formik>
        {/* <ConfirmationModal
          isOpen={isOpenConfirmationModal}
          title={intl.formatMessage({
            id: "channel manager > wait, you have unsaved changes in this form",
          })}
          isCloseOutside={false}
          className={bem.e("confirmation-modal")}
          description={""}
          confirmTitle={intl.formatMessage({
            id: "channel manager > button save",
          })}
          cancelTitle={intl.formatMessage({
            id: "channel manager > button dismiss",
          })}
          onConfirm={() => {
            setIsOpenConfirmationModal(false);
            setIsForceSubmitForm(false);
            if (formikRef.current) {
              onSubmitOutSideForm();
            }
          }}
          onToggle={() => setIsOpenConfirmationModal(!isOpenConfirmationModal)}
          onCancel={() => {
            setIsForceSubmitForm(false);
            if (isFunction(setIsOpenForm)) setIsOpenForm(channel.id);
            setChannelName(initialValues.name); // reset to default name
            setIsOpenConfirmationModal(false);
            if (formikRef.current) {
              formikRef.current.resetForm(initialValues);
            }
          }}
        /> */}
        {/* actions confirmation  unsaved */}
        <ConfirmationModal
          isOpen={isOpenConfirmationActionsUnsavedModal}
          title={intl.formatMessage({
            id: "channel manager > wait, you have unsaved changes in this form",
          })}
          isCloseOutside={false}
          className={bem.e("confirmation-modal")}
          description={intl.formatMessage({
            id:
              "channel manager > before you can perform this step, you must save or discard your changes",
          })}
          confirmTitle={intl.formatMessage({
            id: "channel manager > button save",
          })}
          discardTitle={intl.formatMessage({
            id: "channel manager > button discard",
          })}
          cancelTitle={intl.formatMessage({
            id: "channel manager > button cancel",
          })}
          onConfirm={() => {
            if (formikRef.current) {
              onSubmitOutSideForm();
            }
            setIsOpenConfirmationActionsUnsavedModal(false);
          }}
          onToggle={() =>
            setIsOpenConfirmationActionsUnsavedModal(
              !isOpenConfirmationActionsUnsavedModal
            )
          }
          onDiscard={() => {
            if (formikRef) {
              formikRef.current.resetForm({
                ...initialValues,
                // clear any option is empty
                fields: initialValues.fields.map((field) => {
                  if (field.options && field.options.length > 0) {
                    return {
                      ...field,
                      options: field.options.filter((i) => i.value),
                    };
                  }
                  return field;
                }),
              });
              setChannelName(initialValues.name);
            }
            setIsOpenConfirmationActionsUnsavedModal(false);
            setShouldBlockNavigation(false);
            onGetChannels();
            // if (
            //   isFunction(setIsOpenChannelPreviewModal) &&
            //   isFunction(setCurrentPreviewChannel) &&
            //   currentAction === "preview"
            // ) {
            //   setTimeout(() => {
            //     setIsOpenChannelPreviewModal(true);
            //     setCurrentPreviewChannel(channel);
            //     setCurrentAction(null);
            //   }, 20);
            // }
            // if (isFunction(onCloneChannel) && currentAction === "copy") {
            //   setTimeout(() => {
            //     onCloneChannel(channel);
            //     setCurrentAction(null);
            //   }, 20);
            // }
            // if (isFunction(onCloneChannel) && currentAction === "switch") {
            //   setTimeout(() => {
            //     onSaveHiddenChannel(channel, !channel.is_hidden);
            //     setCurrentAction(null);
            //   }, 20);
            // }
          }}
          onCancel={() => {
            setIsOpenConfirmationActionsUnsavedModal(false);
            setCurrentAction(null);
          }}
        />
      </React.Fragment>
    );
    return <React.Fragment>{form}</React.Fragment>;
    // return (
    //   <WarnAboutUnsavedChanges when={shouldBlockNavigation} children={form} />
    // );
  };
  const renderView = () => {
    return (
      <div className={bem.e("form")}>
        <div className={bem.e("channel-form")}>
          <div className={bem.e("fields")}>
            <Row>
              <Col xs={6}>
                <FormGroup>
                  <TextInput
                    label={
                      <span>
                        <FormattedMessage id="channel manager > name" />
                        <Asterisk>*</Asterisk>
                      </span>
                    }
                    type="text"
                    name="name"
                    placeholder={intl.formatMessage({
                      id: "channel manager > name",
                    })}
                    value={initialValues.name}
                    onChange={() => {}}
                  />
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <ChannelManagerDropdown
                    label={
                      <span>
                        <FormattedMessage id="channel manager > channel manager" />{" "}
                        <Asterisk>*</Asterisk>
                      </span>
                    }
                    value={initialValues.channel_managers}
                    onChange={() => {}}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <FormGroup>
                  <div>
                    <Label>
                      <FormattedMessage id="channel manager > media type" />
                      <Asterisk>*</Asterisk>
                    </Label>
                    <Dropdown
                      options={[
                        {
                          value: "Social Media",
                          label: "Social Media",
                        },
                        {
                          value: "Banner Ad",
                          label: "Banner Ad",
                        },
                        {
                          value: "Video Ad",
                          label: "Video Ad",
                        },
                        {
                          value: "Text Ad",
                          label: "Text Ad",
                        },
                        {
                          value: "Other",
                          label: "Other",
                        },
                      ]}
                      name="media_type"
                      placeholder={intl.formatMessage({
                        id: "channel manager > select media type placeholder",
                      })}
                      value={initialValues.media_type}
                      onChange={() => {}}
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <SelectStations
                    name="stations"
                    item={{ field: "stations" }}
                    label={
                      <FormattedMessage id="channel manager > share channel with stations" />
                    }
                    required={false}
                    placeholder={intl.formatMessage({
                      id: "channel manager > select stations",
                    })}
                    template={{ key: "digital" }}
                    isFilterByServerConfig={true}
                    onChange={() => {}}
                    allowDisplaySelectedNone={true}
                    checked={{ value: initialValues.stations }}
                    validationState={{}}
                    allowSelectNone={true}
                    hideSelectedOptions={true}
                    allowSortOptions={true}
                    allowDeselectOption={false}
                    allowHideSelectIfOptions={0}
                    exceptOptions={currentStation ? [currentStation.value] : []}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <FormGroup>
                  <div>
                    <Label>
                      <FormattedMessage id="channel manager > workflow" />
                      <Asterisk>*</Asterisk>
                    </Label>
                    <Dropdown
                      styles={{
                        option: (base, state) => {
                          if (state.value !== "Create new workflow")
                            return base;
                          return {
                            ...base,
                            marginTop: `10px`,
                            paddingTop: `16px`,
                            borderTop: `1px solid #ccc`,
                            color: `blue`,
                          };
                        },
                      }}
                      options={workflowOptions}
                      name="workflow"
                      placeholder={intl.formatMessage({
                        id: "channel manager > select workflow placeholder",
                      })}
                      value={initialValues.workflow}
                      onChange={() => {}}
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </div>
          {get(initialValues, "fields.length", 0) > 0 && (
            <Row>
              <Col>
                <div className={bem.e("custom-fields")}>
                  <div className={bem.e("custom-fields-container")}>
                    <SortableComponent
                      data={initialValues.fields}
                      onSorted={(sortItems) => {}}
                      formProps={{
                        highlightFields,
                        setHighlightFields,
                        isView: isView,
                        disabled: true,
                      }}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          )}

          <PrivilegedComponent
            privileges={sharedStationPrivileges}
            requires={[PRIVILEGES.EDIT_CHANNELS]}
          >
            <div className={bem.e("buttons")}>
              <Row>
                <Col xs={6}>
                  <FormGroup>
                    <Button
                      color="blue"
                      className="btn btn-radius"
                      onClick={setIsView}
                    >
                      <FormattedMessage id={`channel manager > button edit`} />
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </PrivilegedComponent>
        </div>
      </div>
    );
  };
  const useOutsideAlerter = (ref) => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      // detect click on scrollbar
      if (event.clientX + 20 >= window.innerWidth) {
        return;
      }
      const classNamesDisabled = [
        "react-select__clear-indicator",
        "disable-unsaved",
        "rc-tooltip-inner",
      ];
      const eventClasses = split(
        get(event, "target.parentElement.parentElement.className", ""),
        " "
      );
      let isDisabled =
        intersection(eventClasses, classNamesDisabled).length > 0 ||
        event.target.classList.contains("disable-unsaved");
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !isDisabled &&
        get(
          event,
          "target.parentElement.parentElement.className.animVal",
          ""
        ) !== "disable-unsaved" // this is edit channel button
      ) {
        if (
          !isOpenChannelPreviewModal &&
          !isOpenConfirmationActionsUnsavedModal &&
          !isOpenFieldsFormBuilderModal &&
          !isOpenConfirmationActionsUnsavedModalNewChannel
        ) {
          if (shouldBlockNavigation) {
            if (!document.body.classList.contains("modal-open")) {
              setIsOpenConfirmationActionsUnsavedModal(true);
              setCurrentAction(null);
            }
          } else {
            if (isAdd) {
              // if (!document.body.classList.contains("modal-open")) {
              //   setIsOpenConfirmationActionsUnsavedModalNewChannel(true);
              // }
            } else {
              setIsOpenContent(!isOpenContent);
              if (!document.body.classList.contains("modal-open")) {
                setIsOpenForm(channel.id);
              }
            }
            // if (event.clientX + 7 >= event.target.clientWidth) {
            //   return;
            // } else {
            //   if (event.target.classList.contains("modal")) return;
            //   if (!document.body.classList.contains("modal-open")) {
            //     onClose();
            //   }
            // }
          }
        }
      }
    }
    useEffect(() => {
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    });
  };
  const wrapperRef = useRef(null);
  // useOutsideAlerter(wrapperRef);
  return (
    <div
      className={classnames(bem.b(), { [bem.m("new")]: isAdd })}
      ref={wrapperRef}
    >
      {/* only overlay ui for edit mode */}
      {
        <div className={bem.e("header-section-overlay")}>
          {/* overlay for detect show modal unsaved navigation  */}
          <div
            className={bem.e("overlay-navigation")}
            onClick={() => {
              if (shouldBlockNavigation) {
                setIsOpenConfirmationActionsUnsavedModal(true);
                setCurrentAction(null);
              } else {
                setIsOpenForm(channel.id);
              }
            }}
          ></div>
          {/* overlay for detect show modal unsaved actions */}
          <div className={bem.e("overlay-actions")}>
            <div
              className={bem.e("overlay-action-preview")}
              onClick={() => {
                if (isAdd) {
                  setIsOpenConfirmationActionsUnsavedModalNewChannel(true);
                  return;
                }
                if (shouldBlockNavigation) {
                  setIsOpenConfirmationActionsUnsavedModal(true);
                  setCurrentAction(null);
                } else {
                  setIsOpenChannelPreviewModal(true);
                  setCurrentPreviewChannel(
                    get(formikRef, "current.state.values", channel)
                  );
                }
              }}
            ></div>
            <PrivilegedComponent requires={[PRIVILEGES.EDIT_CHANNELS]}>
              {({ isValid }) => {
                if (isValid) {
                  return (
                    <div
                      style={isAdd ? { visibility: "hidden" } : {}}
                      className={bem.e("overlay-action-copy")}
                      onClick={() => {
                        if (shouldBlockNavigation) {
                          setIsOpenConfirmationActionsUnsavedModal(true);
                          // setCurrentAction("copy");
                        } else {
                          onCloneChannel(channel);
                        }
                      }}
                    ></div>
                  );
                }
                return (
                  <div
                    style={isAdd ? { visibility: "hidden" } : {}}
                    className={bem.e("overlay-action-copy")}
                  ></div>
                );
              }}
            </PrivilegedComponent>

            <div
              className={bem.e("overlay-action-dropdown")}
              onClick={() => {
                if (isAdd) {
                  setIsOpenConfirmationActionsUnsavedModalNewChannel(true);
                  return;
                }
                if (shouldBlockNavigation) {
                  setIsOpenConfirmationActionsUnsavedModal(true);
                  setCurrentAction(null);
                } else {
                  setIsOpenForm(channel.id);
                }
              }}
            ></div>
            <PrivilegedComponent requires={[PRIVILEGES.PUBLISH_CHANNELS]}>
              {({ isValid }) => {
                if (isValid) {
                  return (
                    <ToggleSwitch
                      leftComponent={null}
                      rightComponent={null}
                      name="is_hidden"
                      switchProps={{
                        checked: !get(channel, "is_hidden", false),
                        onChange: (checked) => {
                          if (shouldBlockNavigation) {
                            setIsOpenConfirmationActionsUnsavedModal(true);
                            setCurrentAction("switch");
                          } else {
                            onSaveHiddenChannel(channel, checked);
                          }
                        },
                        disabled: false,
                        offColor: "#C2D4E0",
                        onColor: "#795AFA",
                        uncheckedIcon: false,
                        checkedIcon: false,
                        boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.282)",
                        height: 26.44,
                        width: 48.48,
                        handleDiameter: 22.92,
                      }}
                    />
                  );
                } else {
                  return (
                    <ToggleSwitch
                      leftComponent={null}
                      rightComponent={null}
                      name="is_hidden"
                      switchProps={{
                        checked: !get(channel, "is_hidden", false),
                        onChange: (checked) => {},
                        disabled: true,
                        offColor: "#C2D4E0",
                        onColor: "#795AFA",
                        uncheckedIcon: false,
                        checkedIcon: false,
                        boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.282)",
                        height: 26.44,
                        width: 48.48,
                        handleDiameter: 22.92,
                      }}
                    />
                  );
                }
              }}
            </PrivilegedComponent>
          </div>
        </div>
      }

      {/* show edit mode */}
      {/* {(isOpenContent || !isAdd) && !isView && renderForm()} */}
      {/* show view mode */}
      {/* {isOpenContent && isView && renderView()} */}
      {renderForm()}
    </div>
  );
};
ChannelManagerForm.propTypes = {
  onClose: PropTypes.func,
  isView: PropTypes.bool,
  setIsView: PropTypes.func,
};
export default ChannelManagerForm;
