import React, { useRef } from "react";
import { FaPlus } from "react-icons/lib/fa";
import { FormattedMessage } from "react-intl";
import useOutsideClick from "utils/useOutsideClick";
const tags = [
  "First Name",
  "Last Name",
  "Entity Name",
  "Contest Name",
  "Prize",
];
const MergeTags = (props) => {
  const { bem, onChange } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const ref = useRef(null);
  useOutsideClick(ref, () => {
    setIsOpen(false);
  });
  return (
    <div className={bem.e("merge-tags")} ref={ref}>
      <span className={bem.e("btn-merge")} onClick={() => setIsOpen(!isOpen)}>
        <FaPlus color="#795AFA" size={17} />
        <FormattedMessage id="system templates > merge" />
      </span>
      {isOpen && (
        <div className={bem.e("list-merge-tags")}>
          {tags.map((tag, index) => (
            <div
              key={index}
              onClick={() => {
                onChange(tag);
                setIsOpen(false);
              }}
              className={bem.e("tag")}
            >
              {tag}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export default MergeTags;
