import React from "react";
import { Row, Col, FormGroup, Button, Label } from "reactstrap";
import { FieldArray } from "formik";
import bn from "utils/bemnames";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import TextInput from "components/TextInput";
import { Asterisk } from "components/Elements";
import TextInputFormatted from "components/TextInputFormatted";
import { TrashLagerIcon, PlusIconSmaller } from "components/CustomIcons";
import { get } from "lodash";
import { PhoneNumberFormat } from "utils/config";
import NumberFormat from "react-number-format";
import classNames from "classnames";
const bem = bn.create("jock-console-item");
export default function PhoneNumbers(props) {
  const { isEdit, value, intl } = props;
  if (!isEdit) {
    return (
      <div
        className={classNames(bem.e("view-phone-numbers"), bem.e("view-rows"))}
      >
        {value && value.length > 0
          ? value.map((item, index) => (
              <Row key={index}>
                <Col>
                  <FormGroup>
                    <div className={bem.e("jock-console-view-field")}>
                      {index === 0 && (
                        <Label>
                          <FormattedMessage id="jock console manager > label" />
                        </Label>
                      )}

                      <div className={bem.e("jock-console-view-field-value")}>
                        {item.label ? (
                          item.label
                        ) : (
                          <FormattedMessage id="jock console manager > none" />
                        )}
                      </div>
                    </div>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <div className={bem.e("jock-console-view-field")}>
                      {index === 0 && (
                        <Label>
                          <FormattedMessage id="jock console manager > phone" />
                        </Label>
                      )}
                      <div className={bem.e("jock-console-view-field-value")}>
                        {item.number ? (
                          <NumberFormat
                            value={item.number}
                            displayType={"text"}
                            format={PhoneNumberFormat}
                          />
                        ) : (
                          <FormattedMessage id="jock console manager > none" />
                        )}
                      </div>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            ))
          : null}
      </div>
    );
  }
  const { touched, errors } = props;
  const isShowDelete = get(value, "length", 0) > 1;
  return (
    <div
      className={classNames(bem.e("phone-numbers"), {
        [bem.e("view-rows")]: isShowDelete,
      })}
    >
      <FieldArray
        name="fields.numbers"
        render={(arrayHelpers) => (
          <div>
            {value && value.length > 0
              ? value.map((item, index) => (
                  <Row key={index}>
                    <Col>
                      <FormGroup>
                        <TextInput
                          label={
                            index === 0 ? (
                              <span>
                                <FormattedMessage id="jock console manager > label" />
                                <Asterisk>*</Asterisk>
                              </span>
                            ) : null
                          }
                          type="text"
                          name={`fields.numbers.${index}.label`}
                          placeholder={intl.formatMessage({
                            id: "jock console manager > name",
                          })}
                          value={item.label}
                          error={
                            get(touched, `fields.numbers.${index}.label`) &&
                            get(errors, `fields.numbers.${index}.label`)
                          }
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup className={bem.e("col-row-delete")}>
                        <TextInputFormatted
                          format={PhoneNumberFormat}
                          label={
                            index === 0 ? (
                              <span>
                                <FormattedMessage id="jock console manager > phone" />
                                <Asterisk>*</Asterisk>
                              </span>
                            ) : null
                          }
                          type="text"
                          name={`fields.numbers.${index}.number`}
                          placeholder={intl.formatMessage({
                            id: "jock console manager > phone",
                          })}
                          value={item.number}
                          error={
                            get(touched, `fields.numbers.${index}.number`) &&
                            get(errors, `fields.numbers.${index}.number`)
                          }
                          onChange={(value) => {
                            props.setFieldValue(
                              `fields.numbers.${index}.number`,
                              value
                            );
                          }}
                          onBlur={props.handleBlur}
                        />
                        {isShowDelete ? (
                          <span
                            className={classNames(bem.e("remove-icon"), {
                              [bem.e("remove-icon-first")]: index === 0,
                            })}
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            <TrashLagerIcon />
                          </span>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                ))
              : null}
            <div className={bem.e("add-row-button")}>
              <Button
                onClick={() =>
                  arrayHelpers.push({
                    label: "",
                    number: "",
                  })
                }
                color="blue"
                outline
                className="btn-add-row btn-radius"
                type="button"
              >
                <span className={bem.e("plus-icon")}>
                  <PlusIconSmaller />
                </span>
                <FormattedMessage id="jock console manager > add a row" />
              </Button>
            </div>
          </div>
        )}
      />
    </div>
  );
}
PhoneNumbers.defaultProps = {
  isEdit: false,
};
PhoneNumbers.propTypes = {
  isEdit: PropTypes.bool,
};
