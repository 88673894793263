import React from "react";
import PropTypes from "prop-types";
import bn from "utils/bemnames";
import classnames from "classnames";
import { addLeadingZeros } from "utils/helpers";
import { FormattedMessage } from "react-intl";
const bem = bn.create("count-down");

const Countdown = ({ days, hours, min, sec, isNegative }) => {
  return (
    <div
      className={classnames(bem.b(), {
        [bem.e("negative")]: isNegative,
        [bem.e("container-has-days")]: days > 0,
      })}
    >
      {isNegative ? (
        <span className={bem.e("negative-overdue-text")}>
          <FormattedMessage id="countdown > overdue" />
        </span>
      ) : (
        <div
          className={classnames(bem.b(), {
            [bem.e("has-days")]: days > 0,
            [bem.e("only-timer")]: days === 0,
          })}
        >
          {days > 0 && (
            <span className={bem.e("col-days")}>
              <strong>{days}</strong>
              <span>{days === 1 ? "Day" : "Days"}</span>
            </span>
          )}

          <span
            className={classnames({
              [bem.e("col-timer")]: days === 0,
              [bem.e("child-timer")]: days > 0,
            })}
          >
            <strong>{addLeadingZeros(hours)}</strong>:
            <strong>{addLeadingZeros(min)}</strong>:
            <strong>{addLeadingZeros(sec)}</strong>
          </span>
        </div>
      )}
    </div>
  );
};

Countdown.propTypes = {
  years: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  days: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  hours: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  sec: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isNegative: PropTypes.bool,
};

Countdown.defaultProps = {
  years: 0,
  days: 0,
  hours: 0,
  min: 0,
  sec: 0,
  isNegative: false,
};

export default Countdown;
