export const TEMPLATE_CHANGED = "TEMPLATE_CHANGED"
export const SAVE_TEMPLATE_REQUEST = "SAVE_TEMPLATE_REQUEST";
export const SAVE_TEMPLATE_SUCCESS = "SAVE_TEMPLATE_SUCCESS"
export const SAVE_TEMPLATE_ERROR = "SAVE_TEMPLATE_ERROR"
export const UPDATE_CURRENT_TEMPLATE = "UPDATE_CURRENT_TEMPLATE";

export const FETCH_TEMPLATE_REQUEST = "FETCH_TEMPLATE_REQUEST";
export const FETCH_TEMPLATE_SUCCESS = "FETCH_TEMPLATE_SUCCESS"
export const FETCH_TEMPLATE_ERROR = "FETCH_TEMPLATE_ERROR"

export const DELETE_TEMPLATE_REQUEST = "DELETE_TEMPLATE_REQUEST";
export const DELETE_TEMPLATE_SUCCESS = "DELETE_TEMPLATE_SUCCESS"
export const DELETE_TEMPLATE_ERROR = "DELETE_TEMPLATE_ERROR"

export const UPDATE_TEMPLATE_REQUEST = "UPDATE_TEMPLATE_REQUEST";
export const UPDATE_TEMPLATE_SUCCESS = "UPDATE_TEMPLATE_SUCCESS" 
export const UPDATE_TEMPLATE_ERROR = "UPDATE_TEMPLATE_ERROR"

export const FETCH_TEMPLATES_REQUEST = "FETCH_TEMPLATES_REQUEST";
export const FETCH_TEMPLATES_SUCCESS = "FETCH_TEMPLATES_SUCCESS"
export const FETCH_TEMPLATES_ERROR = "FETCH_TEMPLATES_ERROR"

export const ADD_TEMPLATE_REQUEST = "ADD_TEMPLATE_REQUEST";
export const ADD_TEMPLATE_SUCCESS = "ADD_TEMPLATE_SUCCESS"
export const ADD_TEMPLATE_ERROR = "ADD_TEMPLATE_ERROR"


