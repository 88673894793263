import { get } from "lodash";
import { getTaggedUserIds } from "utils/helpers";
export const createChatMessageForQueue = (
  { chatText = "", attachments = [] },
  payload
) => {
  let { process, user } = payload;

  const message = {
    from: {
      id: user.id,
      name: user.name,
      avatar_url: user.avatar_url,
    },
    tagged: getTaggedUserIds(chatText),
    order_stations: process.data.fields.order_stations
      ? process.data.fields.order_stations.value
      : [],
    chat: chatText,
    attachments,
    m_type: "chat",
    template: get(process, "data.fields.template_key.value", ""),
    process: get(process, "data.fields.key.value", ""),
    process_key: get(process, "data.fields.key.value", ""),
    order_title: process.data.fields.order_title
      ? process.data.fields.order_title.value
      : "untitled",
    from_step: get(process, "data.fields.process_step.value", ""),
    team: process.data.fields.team ? process.data.fields.team.value : [],
    still_required_users: process.data.fields.still_required_users
      ? process.data.fields.still_required_users.value
      : [],
    next_users: process.data.fields.next_users
      ? process.data.fields.next_users.value
      : [],
  };

  if (user.impersonated_by) {
    message.from.impersonated_by = user.impersonated_by;
  }
  return message;
};

export const createChatMessageForProcessDetails = (
  { chatText = "", attachments = [] },
  payload
) => {
  let { process, template, step, user } = payload;
  let mType = "chat";

  if (process) {
    let message = {
      from: {
        id: user.id,
        name: user.name,
        avatar_url: user.avatar_url,
      },
      tagged: getTaggedUserIds(chatText),
      order_stations: process.data.fields.order_stations
        ? process.data.fields.order_stations.value
        : [],
      chat: chatText,
      attachments: attachments || [],
      m_type: mType,
      template: template.key,
      process: process.key,
      order_title: process.data.fields.order_title
        ? process.data.fields.order_title.value
        : "untitled",
      from_step: step ? step.key : "",
      team: process.data.fields.team ? process.data.fields.team.value : [],
      still_required_users: process.data.fields.still_required_users
        ? process.data.fields.still_required_users.value
        : [],
      next_users: process.data.fields.next_users
        ? process.data.fields.next_users.value
        : [],
    };

    if (user.impersonated_by) {
      message.from.impersonated_by = user.impersonated_by;
    }

    return message;
  }
};
