import React from "react";
import bn from "utils/bemnames";
import { Views } from "react-big-calendar";
import PropTypes from "prop-types";
import { DownSmallIcon, UpSmallIcon } from "components/CustomIcons";
import {
  findIndex,
  get,
  includes,
  map,
  intersection,
  orderBy,
  filter,
  find,
} from "lodash";
import CalendarItem from "components/ScheduleComponents/CalendarItem";
import classNames from "classnames";
import Tooltip from "rc-tooltip";
import moment from "moment";
import TooltipHourRange from "../TooltipHourRange";
import TooltipWeekend from "../TooltipWeekend";
const bem = bn.create("calendar-time");
function getElementData({
  item,
  selectedItemSwap,
  date,
  selectedItemTargetSwap,
}) {
  let status = item.fill_schedule ? "fill" : get(item, "slot_status");
  let type = get(selectedItemSwap, "order_process_id", "")
    ? "swapping"
    : "regular";
  if (type === "swapping") {
    if (
      get(item, "order_process_id") &&
      get(item, "order_process_id", "") ===
        get(selectedItemSwap, "order_process_id", "")
    ) {
      status =
        get(item, "slot_id") === get(selectedItemSwap, "slot_id", "") &&
        moment(date).diff(selectedItemSwap.date, "day") === 0
          ? "swapped"
          : "filled-same";
      // swapped same
      if (
        status === "filled-same" &&
        get(item, "slot_id") === get(selectedItemTargetSwap, "slot_id") &&
        moment(date).diff(selectedItemTargetSwap.date, "day") === 0
      ) {
        status = "swapped";
      }
    } else if (
      get(item, "slot_id") === get(selectedItemTargetSwap, "slot_id") &&
      moment(date).diff(selectedItemTargetSwap.date, "day") === 0
    ) {
      status = "swapped";
    } else {
      status = "ineligible";
    }
  }
  return { status, type };
}
function checkIsHighlighWithSelectedItems(element, items) {
  if (!get(element, "order_process_id")) return false;
  const indexExists = findIndex(
    items,
    (item) => get(item, "order_process_id") === get(element, "order_process_id")
  );
  return indexExists !== -1;
}
function checkIsHighlighByFilterType(element, types = []) {
  const labelsIds = map(element.labels, (item) => item.id);
  return intersection(types, labelsIds).length > 0;
}
function getElementHighlight({
  sameTypeSlots,
  swappableSlots,
  selectedItemSwap,
  item,
  scheduleCalendarFilter,
  selectedItem,
  selectedItems,
  status,
  type,
}) {
  let isClickable = true;
  // reset clickable when swapping.
  if (get(selectedItemSwap, "order_process_id", "")) {
    isClickable = false;
  }
  // Any element that is available for swapping becomes clickable.
  const itemSlotIds = [get(item, "slot_id")];
  const itemSlotTypeIds = item.labels
    ? map(item.labels, (i) => i.slot_type_id)
    : [get(item, "slot_type_id")];

  if (
    intersection(swappableSlots, itemSlotIds).length > 0 &&
    get(selectedItemSwap, "slot_id") &&
    includes(itemSlotTypeIds, get(selectedItemSwap, "labels.0.slot_type_id"))
  ) {
    if (
      status === "swapped" // get color for selected item
    ) {
      return { isHighlight: false, isClickable: true };
    }
    const isHighlight = intersection(sameTypeSlots, itemSlotIds).length > 0;
    return { isHighlight, isClickable: true };
  }
  // disable all element when swapping.
  if (type === "swapping") {
    return { isHighlight: false, isClickable: false };
  }
  let isHighlight =
    checkIsHighlighByFilterType(
      item,
      get(scheduleCalendarFilter, "definition", [])
    ) ||
    checkIsHighlighWithSelectedItems(item, [selectedItem]) ||
    checkIsHighlighWithSelectedItems(item, selectedItems);
  return { isHighlight, isClickable };
}
export default function CalendarTime(props) {
  const {
    hours,
    rangeDates,
    isOpen,
    setIsOpen,
    rangeColor,
    idx,
    scheduleCalendarFilter,
    onSelectItem,
    selectedItem,
    selectedItems,
    items,
    intl,
    onSwapItem,
    onViewItem,
    onDeleteItem,
    isScheduleTab,
    onSelectItemSwap,
    selectedItemSwap,
    onSelectItemTargetSwap,
    selectedItemTargetSwap,
    weekdayClockData,
    swappableSlots,
    saturdaysData,
    sundaysData,
    sameTypeSlots,
  } = props;
  const total = get(hours, "length", 0);
  const rangeDatesTotal = get(rangeDates, "length", 0);
  const isStartSunday =
    get(scheduleCalendarFilter, "weekdays") === "Sunday-Saturday";
  const isStartMonday =
    get(scheduleCalendarFilter, "weekdays") === "Monday-Sunday";
  return (
    <div
      className={classNames(bem.b(), {
        [bem.m("first")]: idx === 0,
      })}
    >
      <div
        style={{
          backgroundColor: rangeColor,
        }}
        className={bem.e("hour-color")}
      />
      {/* the tooltip range hour */}
      <Tooltip
        // defaultVisible
        trigger={["hover"]}
        placement="top"
        overlay={
          <TooltipHourRange
            startTime={get(weekdayClockData, "start_time", 0)}
            endTime={get(weekdayClockData, "end_time", 0)}
            title={get(weekdayClockData, "name", "")}
          />
        }
        destroyTooltipOnHide
        overlayClassName={"calendar-tooltip hour-range-tooltip"}
      >
        <div
          className={classNames(
            bem.e("hour-color-tooltip"),
            bem.e("hour-color")
          )}
        />
      </Tooltip>
      {map(hours, (hour, index) => {
        const hourDisplayFormatted = moment(hour, "HH").format("hA");
        return (
          <div
            key={index}
            className={classNames(bem.e("row-time"), {
              [bem.e("row-time-first")]: index === 0,
              [bem.e("row-time-last")]: index === total - 1,
            })}
            id={`schedule-calendar-hour-${hour}`}
          >
            <div className={bem.e("hour")}>
              <span>{hourDisplayFormatted}</span>
              {index === 0 ? (
                <span
                  className={bem.e("open-icon")}
                  onClick={() => setIsOpen(!isOpen)}
                >
                  {isOpen ? <UpSmallIcon /> : <DownSmallIcon />}
                </span>
              ) : null}
            </div>
            <div className={bem.e("slots")}>
              {map(rangeDates, (date, indexDate) => {
                const dateKey = moment(date).format("MMM DD");
                const dateData = get(items, dateKey, {});
                const hourItems = orderBy(
                  get(dateData, `items.${hour}`, []),
                  "priority",
                  "asc"
                );
                const isShowRangeSide =
                  ((indexDate === 0 || indexDate === rangeDatesTotal - 2) &&
                    isStartSunday) ||
                  ((indexDate === rangeDatesTotal - 2 ||
                    indexDate === rangeDatesTotal - 3) &&
                    isStartMonday);
                let tooltipTitle = "";
                let clockWeekendData = {};
                let clockWeekendDataHourIndex = -1;
                let isExistsClockWeekendData = false;
                if (isShowRangeSide) {
                  if (
                    (isStartSunday && indexDate === 0) ||
                    (isStartMonday && indexDate === rangeDatesTotal - 2)
                  ) {
                    clockWeekendData = find(sundaysData, (i) =>
                      includes(i.hours, hour)
                    );
                    tooltipTitle = get(clockWeekendData, "name", "");
                    clockWeekendDataHourIndex = findIndex(
                      get(clockWeekendData, "hours", []),
                      (i) => i === hour
                    );
                    isExistsClockWeekendData =
                      get(sundaysData, "length", 0) > 0;
                  } else {
                    clockWeekendData = find(saturdaysData, (i) =>
                      includes(i.hours, hour)
                    );
                    tooltipTitle = get(clockWeekendData, "name", "");
                    clockWeekendDataHourIndex = findIndex(
                      get(clockWeekendData, "hours", []),
                      (i) => i === hour
                    );
                    isExistsClockWeekendData =
                      get(saturdaysData, "length", 0) > 0;
                  }
                }
                if (!isOpen)
                  return (
                    <div
                      className={classNames(
                        bem.e("time-slots"),
                        bem.e("time-slots-collapsed"),
                        {
                          [bem.e("time-slots-side")]:
                            isShowRangeSide && isExistsClockWeekendData,
                        },
                        bem.e(`time-slots-side-color-${idx}`) // defined class name for set color by index
                      )}
                      key={indexDate}
                    >
                      {/* the tooltip range hour */}
                      {isShowRangeSide && isExistsClockWeekendData && (
                        <TooltipWeekend
                          clockWeekendData={clockWeekendData}
                          index={index}
                          tooltipTitle={tooltipTitle}
                          total={total}
                          clockWeekendDataHourIndex={clockWeekendDataHourIndex}
                          hour={hour}
                        />
                      )}
                      <CalendarItem status="collapsed" />
                    </div>
                  );
                return (
                  <div
                    className={classNames(
                      bem.e("time-slots"),
                      bem.e("time-slots-opened"),
                      {
                        [bem.e("time-slots-side")]:
                          isShowRangeSide && isExistsClockWeekendData,
                        [bem.e("item-slots-multiple")]: hourItems.length > 1,
                        [bem.e("item-slots-single")]:
                          hourItems.length === 1 || hourItems.length === 0,
                      },
                      bem.e(`time-slots-side-color-${idx}`) // defined class name for set color by index
                    )}
                    key={indexDate}
                  >
                    {/* the tooltip range hour */}
                    {isShowRangeSide && isExistsClockWeekendData && (
                      <TooltipWeekend
                        clockWeekendData={clockWeekendData}
                        index={index}
                        tooltipTitle={tooltipTitle}
                        total={total}
                        clockWeekendDataHourIndex={clockWeekendDataHourIndex}
                        hour={hour}
                      />
                    )}
                    {hourItems.length === 0 ? (
                      <CalendarItem status="collapsed" />
                    ) : (
                      map(hourItems, (item, indexItem) => {
                        const isEmpty = get(item, "slot_status") === "empty";
                        let title = get(item, "order_title", "");
                        if (get(item, "slot_status") === "empty") {
                          title = intl.formatMessage({
                            id:
                              "process > promotion > liner > schedule > empty",
                          });
                        }
                        const { status, type } = getElementData({
                          item,
                          selectedItemSwap,
                          date,
                          selectedItemTargetSwap,
                        });
                        const {
                          isHighlight,
                          isClickable,
                        } = getElementHighlight({
                          sameTypeSlots,
                          swappableSlots,
                          selectedItemSwap,
                          item,
                          scheduleCalendarFilter,
                          selectedItem,
                          selectedItems,
                          status,
                          type,
                        });
                        const timeFormatted = moment
                          .utc(item.time * 1000 * 60)
                          .format("mm");
                        let multipleTypes = [];

                        if (item.labels && isEmpty) {
                          multipleTypes = filter(item.labels, (type) => {
                            return includes(
                              get(scheduleCalendarFilter, "definition", []),
                              type.id
                            );
                          });
                        }
                        return (
                          <CalendarItem
                            key={`hour-item-${indexItem}`}
                            title={title}
                            isEmpty={isEmpty}
                            status={status}
                            number={timeFormatted}
                            item={item}
                            type={type}
                            date={date}
                            isScheduleTab={isScheduleTab}
                            selectedItemSwap={selectedItemSwap}
                            isHighlight={isHighlight}
                            onSelectItemSwap={onSelectItemSwap}
                            onSwapItem={onSwapItem}
                            onViewItem={onViewItem}
                            onDeleteItem={onDeleteItem}
                            onSelectItem={onSelectItem}
                            onSelectItemTargetSwap={onSelectItemTargetSwap}
                            isClickable={isClickable}
                            multipleTypes={multipleTypes}
                            intl={intl}
                          />
                        );
                      })
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}
CalendarTime.defaultProps = {
  hours: [],
  scheduleCalendarFilter: {},
  onSelectItemSwap: () => {},
  onSelectItemTargetSwap: () => {},
  onDeleteItem: () => {},
  isScheduleTab: false,
  swappableSlots: [],
  clocksData: [],
};
CalendarTime.propTypes = {
  hours: PropTypes.array,
  defaultView: PropTypes.oneOf(Object.values(Views)),
  scheduleCalendarFilter: PropTypes.object,
  isScheduleTab: PropTypes.bool,
  onSelectItemSwap: PropTypes.func,
  onSelectItemTargetSwap: PropTypes.func,
  swappableSlots: PropTypes.array,
  clocksData: PropTypes.object,
  onDeleteItem: PropTypes.func,
};
