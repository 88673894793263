import { FormattedMessage } from "react-intl";
import React from "react";
import { get, map, filter, find, delay, difference } from "lodash";
import history from "components/History";
import { validatePrivileges } from "utils/helpers";
import { PRIVILEGES } from "utils/constants";

const dubScript = {
  process_step_index: 8,
  key: "dub_script",
  title: <FormattedMessage id="process > title dubbing" />,
  caption_color: "#6803CC",
  description: null,
  isCollapsible: false,
  step_roles: [9],
  step_viewers_roles: [9, 4, 7],
  step_editors_roles: [9, 4],
  step_viewers_privileges: {
    or: ["perform_production_dubbing", "edit_all_production_orders"],
  },
  step_editors_privileges: {
    or: ["perform_production_dubbing", "edit_all_production_orders"],
  },
  status: "initial",
  className: "dubbing-container",
  is_open_edit: true,
  can_edit_any_time: true,
  is_check_hidden_tab: function(user, process, step) {
    return (
      process.data.fields.order_sold && !process.data.fields.order_sold.value
    );
  },
  is_hidden: function(user, process, step) {
    return (
      process.data.fields.order_sold && !process.data.fields.order_sold.value
    );
  },
  no_edit_button: true,
  workflow: {
    title: "dubbing",
    icon: "DubbingIcon",
  },
  is_approval_step: false,
  fields: [
    {
      component: "production_details",
      props: {
        isEditable: false,
        isCollapse: true,
      },
    },
    {
      component: "production_dubbing_traffic_details",
    },
    {
      field: "spot_info",
      validation: function(step, item, process, template, user, value) {
        let isValid = true;
        const spotInfo = map(value, (spot, index) => {
          let error = {};
          //if wo is enabled, Cart number is not mandatory
          if (!get(user, "enable_wo_traffic", false) && !spot["cart_number"]) {
            isValid = false;
            error.cart_number = "You must enter a cart number";
          }
          return error;
        });
        if (isValid) return true;
        return spotInfo;
      },
      mandatory: true,
    },
    // {
    //   component: "title",
    //   value: <FormattedMessage id="process > title continuity" />,
    //   container: "top_container"
    // },
    // {
    //   field: "continuity_cart",
    //   title: <FormattedMessage id="process > field continuity cart" />,
    //   container: "top_container",
    //   component: "text",
    //   props: {
    //     style: { maxWidth: 300, marginBottom: "1rem" }
    //   },
    //   isView: true,
    //   mandatory: true,
    //   validation: function (step, item, process, template, user, value) {
    //     return value.length > 0 ? true : "Please enter the cart number";
    //   }
    // },
    // {
    //   field: "recording_path",
    //   component: "file_upload",
    //   props: {
    //     mode: "view"
    //   }
    // },
    {
      field: "markets",
    },
    {
      text: <FormattedMessage id="process > button done" />,
      // component: "button",
      key: "save_button",
      // props: { color: "blue" },
      // container: "bottom_buttons",
      fields: [
        {
          key: "dub_script_status",
          value: function(user, template, process, step, item) {
            let status = "completed";
            const spotInfo = get(process, "data.fields.spot_info.value", []);
            const isMissingDubbed =
              filter(spotInfo, (spot) => !spot.is_dubbed).length > 0;
            if (isMissingDubbed) {
              status = null;
            }
            return status;
          },
        },
        {
          key: "process_step",
          value: function(user, template, process, step, item) {
            let processStep = "first_undone";
            const spotInfo = get(process, "data.fields.spot_info.value", []);
            const isMissingDubbed =
              filter(spotInfo, (spot) => !spot.is_dubbed).length > 0;
            if (isMissingDubbed) {
              processStep = "dub_script";
            }
            return processStep;
          },
        },
        {
          key: "next_users",
          value: function(user, template, process, step, item) {
            let nextUsers = get(process, "data.fields.next_users.value", []);
            const spotInfo = get(process, "data.fields.spot_info.value", []);
            const spotDataLinked = find(
              spotInfo,
              (spot) => spot.key === process.data.fields.key.value
            );
            const isMissingDubbed =
              filter(spotInfo, (spot) => !spot.is_dubbed).length > 0;
            if (isMissingDubbed) {
              const roleDubber = map(
                get(process, "data.fields.role_dubber.value.users", []),
                (item) => item.id
              );
              // remove the task from his Current tasks queue
              if (spotDataLinked.is_dubbed) {
                nextUsers = difference(nextUsers, roleDubber);
              } else {
                nextUsers = roleDubber; // keep current user for continuity dubbing
              }
            } else if (
              get(process, "data.fields.air_check_status.value") !==
                "completed" &&
              get(spotDataLinked, "aircheck_requires_approval")
            ) {
              nextUsers = [];
              const role_sales_person = get(
                process,
                "data.fields.role_sales_person.value.id"
              );
              const role_sales_person_2 = get(
                process,
                "data.fields.role_sales_person_2.value.id"
              );
              if (role_sales_person) {
                nextUsers = [...nextUsers, parseInt(role_sales_person)];
              }
              if (role_sales_person_2) {
                nextUsers = [...nextUsers, parseInt(role_sales_person_2)];
              }
            }
            return nextUsers;
          },
        },
      ],
      class: null,
      on_click: {
        validate: true,
        save: true,
        go_to: function(_this, step, item, process, template, user, extraData) {
          // const enableEnterprise = get(
          //   extraData,
          //   "production_multimarket",
          //   false
          // );
          const privileges = get(user, "privileges", []) || [];
          let isRedirect = false;
          // set delay when after success message
          // if (!enableEnterprise) {
          const missingDubbed = get(
            process,
            "data.fields.spot_info.value",
            []
          ).filter((spot) => {
            const isDubber = validatePrivileges({
              requires: {
                or: [
                  PRIVILEGES.PERFORM_PRODUCTION_DUBBING,
                  PRIVILEGES.EDIT_ALL_PRODUCTION_ORDERS,
                ],
              },
              privileges,
              user,
            });
            return (
              (get(spot, "role_dubber.users", []).find(
                (item) => Number(item.id) === Number(user.id)
              ) ||
                isDubber) &&
              !spot.is_dubbed
            );
          });
          isRedirect = missingDubbed.length === 0;
          // } else {
          //   const marketsAssigned = filter(
          //     get(process, "data.fields.markets.value", []),
          //     (market) => {
          //       const isAssigned = find(
          //         get(market, "role_dubber.users", []),
          //         (item) => Number(item.id) === Number(user.id)
          //       );
          //       return isAssigned;
          //     }
          //   );
          //   isRedirect =
          //     marketsAssigned.filter((market) => !market.is_dubbed).length ===
          //     0;
          // }

          if (isRedirect) {
            if (!user.return_to_current_tasks) return;
            delay(() => {
              if (_this.props.setSidebarActive) {
                _this.props.setSidebarActive(`/processes/my_action_items`);
              }
              history.replace(`/processes/my_action_items`);
            }, 1000);
          }
        },
      },
      confirmation_dialog_props: {
        title: (
          <FormattedMessage id="process > description mark dubbing as done" />
        ),
        confirmTitle: <FormattedMessage id="process > button yes" />,
        cancelTitle: <FormattedMessage id="process > button no" />,
      },
      success_dialog_props: null,
    },
    // {
    //   text: <FormattedMessage id="process > button delete" />,
    //   component: "button",
    //   props: { color: "blue", outline: true },
    //   container: "bottom_buttons",
    //   class: null,
    //   privileges: { or: ["delete_production", "edit_all_production_order"] },
    //   confirmation_dialog_props: {
    //     title: <FormattedMessage id="process > confirm delete this spot" />,
    //     confirmTitle: <FormattedMessage id="process > button yes" />,
    //     cancelTitle: <FormattedMessage id="process > button no" />,
    //     onConfirm: (_this, step, item, process, template, user) => {
    //       _this.setState({ confirmationDialogProps: false });
    //       _this.props.deleteProcess(
    //         get(process, "data.fields.key.value"),
    //         () => {
    //           const currentTasks = URL.PROCESSES({
    //             filter: "my_action_items",
    //           });
    //           const nextRoute = _this.props.sidebarActive
    //             ? _this.props.sidebarActive
    //             : currentTasks;
    //           history.push(nextRoute);
    //         }
    //       );
    //     },
    //   },
    //   on_click: {},
    // },
  ],
};
export default dubScript;
