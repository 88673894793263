import * as React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import classnames from "classnames";
import bn from "utils/bemnames";
import { BadgeRemoveIcon } from 'components/CustomIcons';
import { get } from 'lodash';

const bem = bn.create("button-add");

const ButtonClear = ({ to, children, onClick, className, showDefaultIcon = true, iconProps }) => {
  if (to) {
    return (
      <Button className={classnames(bem.b(), className)} tag={Link} to={to}>
        {showDefaultIcon && (<BadgeRemoveIcon 
          className={bem.e("icon-add")}
          {...iconProps} 
        />)}
        {children}
      </Button>
    );
  }
  return (
    <Button className={classnames(bem.b(), className)} onClick={onClick}>
      {showDefaultIcon && (<BadgeRemoveIcon 
        className={bem.e("icon-add")} 
        {...iconProps}
      />)}
      {children}
    </Button>
  );
};
ButtonClear.defaultProps = {
  onClick: () => {}
};
ButtonClear.propTypes = {
  onClick: PropTypes.func.isRequired,
  to: PropTypes.string,
  children: PropTypes.element.isRequired
};
export default ButtonClear;
