import React, { useMemo } from "react";
import { CloseSmallIcon } from "components/CustomIcons";
import {
  deadlineConfig,
  spotTypeConfig,
  statusConfig,
} from "../ContentProductionQueueView/view";
import classNames from "classnames";

const FilteredProductionQueueTags = ({ bem, values, onSubmit }) => {
  if (!values) return null;
  const filterStatus = statusConfig.find(
    (item) => item.value === values.status
  );
  return (
    <>
      {values.stations
        ? values.stations.map((station) => {
            let stationName = [];
            if (station.data.firstLabel) {
              stationName.push(station.data.firstLabel);
            }
            if (station.data.lastLabel) {
              stationName.push(station.data.lastLabel);
            }
            return (
              <div className={bem.e("filter-tag")}>
                <span>{stationName.join(" - ")}</span>
                <span
                  className={bem.e("remove-tag")}
                  onClick={() => {
                    onSubmit({
                      ...values,
                      stations: values.stations.filter(
                        (item) => item.value !== station.value
                      ),
                    });
                  }}
                >
                  <CloseSmallIcon />
                </span>
              </div>
            );
          })
        : null}
      {values.client_id ? (
        <div className={bem.e("filter-tag")}>
          <span>{values.client_name}</span>
          <span
            className={bem.e("remove-tag")}
            onClick={() => {
              onSubmit({
                ...values,
                client_id: "",
                client_name: "",
              });
            }}
          >
            <CloseSmallIcon />
          </span>
        </div>
      ) : null}
      {values.deadline
        ? values.deadline.map((type) => (
            <div className={bem.e("filter-tag")}>
              {deadlineConfig.find((item) => item.value === type).label}
              <span
                className={bem.e("remove-tag")}
                onClick={() => {
                  onSubmit({
                    ...values,
                    deadline: values.deadline.filter((item) => item !== type),
                  });
                }}
              >
                <CloseSmallIcon />
              </span>
            </div>
          ))
        : null}
      {values.spot_types
        ? values.spot_types.map((type) => (
            <div className={bem.e("filter-tag")}>
              {spotTypeConfig.find((item) => item.value === type).label}
              <span
                className={bem.e("remove-tag")}
                onClick={() => {
                  onSubmit({
                    ...values,
                    spot_types: values.spot_types.filter(
                      (item) => item !== type
                    ),
                  });
                }}
              >
                <CloseSmallIcon />
              </span>
            </div>
          ))
        : null}
      {filterStatus ? (
        <div
          className={classNames(
            bem.e("filter-tag"),
            bem.e("filter-status"),
            bem.e(`filter-status-${filterStatus.value.replace(/_/g, "-")}`)
          )}
        >
          {filterStatus.label}
          <span
            className={bem.e("remove-tag")}
            onClick={() => {
              onSubmit({
                ...values,
                status: "",
              });
            }}
          >
            <CloseSmallIcon />
          </span>
        </div>
      ) : null}
    </>
  );
};

export default FilteredProductionQueueTags;
