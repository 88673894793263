import * as React from "react";
import { FormattedMessage } from "react-intl";
import {
  Button,
} from "reactstrap";
import bn from "utils/bemnames";
const bem = bn.create("incomplete-contract-modal");
class OrderDetailsIncompleteContract extends React.Component {

  render() {
    let warning
    warning = this.props.stationsWithoutSpots.map((s, ind) => {
      if (ind % 2 === 1) return null;
      return (
        <div className={bem.e('item')} key={ind}>
          <b>{this.props.stationsWithoutSpots[ind]}</b>
          <b>{this.props.stationsWithoutSpots.length > ind + 1 ? this.props.stationsWithoutSpots[ind + 1] : null}</b>
        </div>
      );
    })
    return (
      <div className={bem.b()}>
        <h2 className={bem.e('title')}><FormattedMessage id="process > title warning: these stations have no spots assigned" /></h2>
        <div className={bem.e('items')}>
          {warning}
        </div>
        <div className={bem.e('buttons')}>
          <Button
            block
            color="blue"
            onClick={this.props.close}
            className={bem.e('button-dismiss')}
          >
            <FormattedMessage id="process > button dismiss" />
          </Button>
        </div>
      </div>
    )
  }
}

export default OrderDetailsIncompleteContract;
