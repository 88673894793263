import React, { useEffect } from "react";
import { Row, Col, Table } from "reactstrap";
import Checkbox from "components/Checkbox";
import classnames from "classnames";
import {
  find,
  get,
  includes,
  isEqual,
  map,
  uniqBy,
  filter,
  difference,
  sortBy,
  xor,
} from "lodash";
import { FormattedMessage } from "react-intl";
import TextInputFormatted from "components/TextInputFormatted";
import PriorityItems from "../PriorityItems";
const priorityLinkedItemsOrderDefault = [
  "Contest",
  "Live Sponsorships",
  "Recorded Sponsorships",
  "Promos",
  "Liner",
  "Live PSAs",
  "Recorded PSAs",
  "Call-Ins",
  "Giveaway",
];

const consecutiveDays = [
  {
    label: "Contest",
    value: "Contest",
  },
  {
    label: "Live Sponsorship",
    value: "Live Sponsorship",
  },
  {
    label: "Liner",
    value: "Liner",
  },
  {
    label: "Recorded Sponsorship",
    value: "Recorded Sponsorship",
  },
  {
    label: "Promo",
    value: "Promo",
  },
  {
    label: "Call-in",
    value: "Call-In",
  },
  {
    label: "Live PSA",
    value: "Live PSA",
  },
  {
    label: "Talk Break",
    value: "Talk Break",
  },
  {
    label: "Recorded PSA",
    value: "Recorded PSA",
  },
];
function SeparationTimeTable(props) {
  const { items, bem, onChange } = props;
  const onChangeTime = (index, time) => {
    const newValue = items;
    newValue[index] = {
      ...newValue[index],
      time,
    };
    onChange(newValue);
  };
  return (
    <div className={bem.e("time-table")}>
      <div className={bem.e("time-table-title")}>
        <FormattedMessage id="clock creator > minimum separation time" />
      </div>
      <Table>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="clock creator > slot type" />
            </th>
            <th>
              <FormattedMessage id="clock creator > hours" />
            </th>
            <th>
              <FormattedMessage id="clock creator > minutes" />
            </th>
          </tr>
        </thead>
        <tbody>
          {map(items, (item, index) => {
            const num = item.time;
            const hours = num / 60;
            const rHours = Math.floor(hours);
            const minutes = (hours - rHours) * 60;
            const rMinutes = Math.round(minutes);
            return (
              <tr key={index}>
                <td>
                  <span className={bem.e("time-label")}>{item.label}</span>
                </td>
                <td className={bem.e("time-width")}>
                  <div className={bem.e("time-hour")}>
                    <TextInputFormatted
                      label={null}
                      type="text"
                      name="hours"
                      placeholder={"00"}
                      value={rHours}
                      onChange={(value) => {
                        onChangeTime(
                          index,
                          parseInt(value || 0) * 60 + rMinutes
                        );
                      }}
                    />
                    <span className={bem.e("time-divider")}>:</span>
                  </div>
                </td>
                <td className={bem.e("time-width")}>
                  <TextInputFormatted
                    label={null}
                    type="text"
                    name="minutes"
                    placeholder={"00"}
                    value={rMinutes}
                    onChange={(value) => {
                      onChangeTime(
                        index,
                        parseInt(value || 0) + parseInt(rHours || 0) * 60
                      );
                    }}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}
const SeparationRules = (props) => {
  const { bem, separation_rules, onChange, intl, slots } = props;
  let allSelectedTypes = slots
    ? Object.values(slots)
        .filter((item) => item.type && !get(item, "deleted"))
        .map((item) => {
          return item.type;
        })
    : [];
  allSelectedTypes = uniqBy(allSelectedTypes, "value");
  // auto assign new type to separation rules
  useEffect(() => {
    // Checking auto update linked_items_priority when any type changing or removing
    const assignedLinkedItems = filter(
      get(separation_rules, "linked_items_priority", []),
      (item) => find(allSelectedTypes, (i) => i.label === item)
    );
    const newLinkedItems = filter(
      allSelectedTypes,
      (item) => !includes(assignedLinkedItems, item.label)
    ).map((item) => item.label);

    const newAssignedLinkedItems = [...assignedLinkedItems, ...newLinkedItems];
    const sameLinkedItems = difference(
      newAssignedLinkedItems,
      get(separation_rules, "linked_items_priority", [])
    );
    if (sameLinkedItems.length !== 0) {
      const sortedCollection = sortBy(newAssignedLinkedItems, (item) => {
        return priorityLinkedItemsOrderDefault.indexOf(item);
      });
      onChange(
        {
          ...separation_rules,
          linked_items_priority: sortedCollection,
        },
        false
      );
    }

    // Checking auto update time_rule_min_separation_time when any type changing or removing
    const assignedTimeRule = filter(
      get(separation_rules, "time_rule_min_separation_time", []),
      (item) => find(allSelectedTypes, (i) => i.label === item.type)
    );
    const newTimeRule = filter(
      allSelectedTypes,
      (item) => !find(assignedTimeRule, (i) => i.type === item.label)
    ).map((item) => ({
      type: item.label,
      time: 60, // default
    }));
    const newAssignedTimeRule = [...assignedTimeRule, ...newTimeRule];
    const isSameTimeRule = isEqual(
      newAssignedTimeRule,
      get(separation_rules, "time_rule_min_separation_time", [])
    );
    if (!isSameTimeRule) {
      onChange(
        {
          ...separation_rules,
          time_rule_min_separation_time: newAssignedTimeRule,
        },
        false
      );
    }
  }, [allSelectedTypes]);
  return (
    <div>
      <Row
        className={classnames(
          bem.e("form-container"),
          "m-0",
          bem.e("rules-item")
        )}
      >
        <Col className="p-0 m-0" xs={12}>
          <div className={bem.e("rule-wrapper")}>
            <Checkbox
              checked={!!get(separation_rules, "consecutive_days_rule", true)}
              text={null}
              onChange={(checked) => {
                onChange({
                  ...separation_rules,
                  consecutive_days_rule: checked,
                });
              }}
              disableSelectOnTextClick={true}
              checkStyle={{ color: "#fff", width: "15", height: "14" }}
            />
            <div className={bem.e("rule-content")}>
              <div className={bem.e("rule-title")}>
                <FormattedMessage id="clock creator > consecutive days rule" />
              </div>
              <p className={bem.e("rule-description")}>
                <FormattedMessage id="clock creator > consecutive days rule description" />
              </p>
              <div className={bem.e("rule-content-checkboxes")}>
                {consecutiveDays.map((item, index) => (
                  <Checkbox
                    key={index}
                    checked={
                      !!find(
                        separation_rules.consecutive_days_rule_types,
                        (i) => i === item.value
                      )
                    }
                    text={item.label}
                    onChange={() => {
                      onChange({
                        ...separation_rules,
                        consecutive_days_rule_types: xor(
                          separation_rules.consecutive_days_rule_types,
                          [item.value]
                        ),
                      });
                    }}
                    checkStyle={{ color: "#fff", width: "15", height: "14" }}
                  />
                ))}
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row
        className={classnames(
          bem.e("form-container"),
          "m-0",
          bem.e("rules-item")
        )}
      >
        <Col className="p-0 m-0" xs={12}>
          <div className={bem.e("rule-wrapper")}>
            <Checkbox
              checked={!!get(separation_rules, "label_rule", true)}
              text={null}
              onChange={(checked) => {
                onChange({
                  ...separation_rules,
                  label_rule: checked,
                });
              }}
              disableSelectOnTextClick={true}
              checkStyle={{ color: "#fff", width: "15", height: "14" }}
            />
            <div className={bem.e("rule-content")}>
              <div className={bem.e("rule-title")}>
                <FormattedMessage id="clock creator > label rule" />
              </div>
              <p className={bem.e("rule-description")}>
                <FormattedMessage id="clock creator > label rule description" />
              </p>
            </div>
          </div>
        </Col>
      </Row>
      <Row
        className={classnames(
          bem.e("form-container"),
          "m-0",
          bem.e("rules-item")
        )}
      >
        <Col className="p-0 m-0" xs={12}>
          <div className={bem.e("rule-wrapper")}>
            <Checkbox
              checked={!!get(separation_rules, "linked_items_rule", true)}
              text={null}
              onChange={(checked) => {
                onChange({
                  ...separation_rules,
                  linked_items_rule: checked,
                });
              }}
              disableSelectOnTextClick={true}
              checkStyle={{ color: "#fff", width: "15", height: "14" }}
            />
            <div className={bem.e("rule-content")}>
              <div className={bem.e("rule-title")}>
                <FormattedMessage id="clock creator > linked items rule" />
              </div>
              <p className={bem.e("rule-description")}>
                <FormattedMessage id="clock creator > linked items rule description" />
              </p>
              <SeparationTimeTable
                items={[
                  {
                    label: intl.formatMessage({
                      id: "clock creator > all linked items",
                    }),
                    time: get(
                      separation_rules,
                      "linked_items_min_separation_time"
                    ),
                  },
                ]}
                onChange={(value) => {
                  const time = get(value, `0.time`, 0);
                  onChange({
                    ...separation_rules,
                    linked_items_min_separation_time: time,
                  });
                }}
                bem={bem}
              />
              <PriorityItems
                bem={bem}
                items={get(separation_rules, "linked_items_priority", [])}
                onChange={(value) => {
                  onChange({
                    ...separation_rules,
                    linked_items_priority: value,
                  });
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row
        className={classnames(
          bem.e("form-container"),
          "m-0",
          bem.e("rules-item")
        )}
      >
        <Col className="p-0 m-0" xs={12}>
          <div className={bem.e("rule-wrapper")}>
            <Checkbox
              checked={!!get(separation_rules, "time_rule", true)}
              text={null}
              onChange={(checked) => {
                onChange({
                  ...separation_rules,
                  time_rule: checked,
                });
              }}
              disableSelectOnTextClick={true}
              checkStyle={{ color: "#fff", width: "15", height: "14" }}
            />
            <div className={bem.e("rule-content")}>
              <div className={bem.e("rule-title")}>
                <FormattedMessage id="clock creator > time rule" />
              </div>
              <p className={bem.e("rule-description")}>
                <FormattedMessage id="clock creator > time rule description" />
              </p>
              <SeparationTimeTable
                items={map(
                  get(separation_rules, "time_rule_min_separation_time", []),
                  (item) => ({
                    label: item.type,
                    time: item.time,
                  })
                )}
                onChange={(value) => {
                  onChange({
                    ...separation_rules,
                    time_rule_min_separation_time: map(value, (item) => ({
                      type: item.label,
                      time: item.time,
                    })),
                  });
                }}
                bem={bem}
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default SeparationRules;
