import React from "react";
import Page from "components/Page";
import { FormattedMessage } from "react-intl";
import JockConsoleManager from "components/JockConsoleManager";
import PrivilegedComponent from "components/PrivilegedComponent";
import { PRIVILEGES } from "utils/constants";
import bn from "utils/bemnames";
import { Redirect } from "react-router";
import URL from "utils/urls";

const bem = bn.create("jock-console-manager-page");
const ScheduleHeader = (props) => {
  return (
    <div className={bem.e("header")}>
      <div className={bem.e("page-title")}>
        <FormattedMessage id="jock console manager > page title" />
      </div>
    </div>
  );
};
const JockConsoleManagerPage = (props) => {
  const { intl } = props;

  const renderBreadcrumbs = () => {
    let breadcrumbs = [
      {
        name: intl.formatMessage({ id: "breadcrumbs > home" }),
        to: URL.HOME(),
        active: false,
      },
      {
        name: intl.formatMessage({ id: "breadcrumbs > jock console manager" }),
        active: true,
      },
    ];

    return breadcrumbs;
  };
  return (
    <PrivilegedComponent requires={[PRIVILEGES.CONFIGURE_CONSOLE]}>
      {({ isValid }) => {
        if (isValid) {
          return (
            <Page
              applyPadding={false}
              breadcrumbs={renderBreadcrumbs()}
              className={bem.b()}
              headerChildren={<ScheduleHeader intl={intl} />}
            >
              <div className={bem.e("padding-container")}>
                <JockConsoleManager />
              </div>
            </Page>
          );
        } else {
          return <Redirect to="/" />;
        }
      }}
    </PrivilegedComponent>
  );
};

export default JockConsoleManagerPage;
