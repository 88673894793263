import UserTableManual from "./view";
import { get } from "lodash";
import { connect } from "react-redux";
import { compose, lifecycle, withState, withHandlers } from "recompose";
import { injectIntl } from "react-intl";
import { pageSize } from "utils/config";
import { getHubAssignedStations } from "store/actions/hubs";
const defaultSorted = [
  {
    id: "name",
    desc: false,
  },
];
export default injectIntl(
  compose(
    connect(
      (state) => ({
        assignedStations: get(state, "hubs.assignedStations"),
      }),
      {
        getHubAssignedStations,
      }
    ),
    withState("sidebarType", "setSidebarType", ""),
    withState("sorted", "setSorted", defaultSorted),
    withState("isOpenSidebar", "setIsOpenSidebar", false),
    withHandlers({
      getHubStations: ({
        getHubAssignedStations,
        sorted,
        selectedHub,
      }) => async (params = {}) => {
        getHubAssignedStations({
          id: selectedHub.id,
          pageSize,
          page: 0,
          sorted,
          ...params,
        });
      },
    }),
    lifecycle({
      componentDidMount() {
        this.props.setSorted(defaultSorted);
        this.props.getHubAssignedStations({
          id: this.props.selectedHub.id,
          pageSize,
          page: 0,
          sorted: defaultSorted,
        });
      },
      componentDidUpdate(nextProps) {
        if (
          this.props.selectedHub.id !== nextProps.selectedHub.id ||
          this.props.assignedStations.is_dirty
        ) {
          this.props.setSorted(defaultSorted);
          this.props.getHubAssignedStations({
            id: this.props.selectedHub.id,
            pageSize,
            page: 0,
            sorted: defaultSorted,
          });
        }
      },
    })
  )(UserTableManual)
);
