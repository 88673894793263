import React from "react";
import bn from "utils/bemnames";
import classnames from "classnames";
import { FaClose } from "react-icons/lib/fa";
import { labelToNameField } from "utils/helpers";
import UploadFiles from "components/UploadFiles";
import { FormGroup, Row, Col, Label } from "reactstrap";
import ToggleSwitch from "components/ToggleSwitch";
import TextInput from "components/TextInput";
import { default as CheckboxDefault } from "components/Checkbox";
import { get, findIndex, xor, concat, filter, find, first, map } from "lodash";
import HandleAssets from "components/HandleAssets";
import { mimesImageWithExt, mimesVideoWithExt } from "../helper";
import { ApproveIcon, TrashIcon, MenuIcon } from "components/CustomIcons";
import { Asterisk } from "components/Elements";
import { FormattedMessage } from "react-intl";
import FileView from "components/FileView";
import PreviewMediaModal from "../PreviewMediaModal";
const bem = bn.create("form-builder-fields");
const allowImages = [".jpg", ".png", ".gif", ".ai", ".bmp", ".pdf"];
const renderAssetModal = (props) => {
  return <PreviewMediaModal {...props} />;
};
const Media = ({
  intl,
  isNew,
  isView,
  value,
  field,
  errors,
  onChange,
  onDelete,
  allowDelete,
  touched,
  isFormBuilder,
  highlightFields,
  disabled,
  setIsDirty,
  isShowOffText,
  ...rest
}) => {
  // UI for form builder
  if (isFormBuilder) {
    if (isNew) {
      const isHighlightFormField =
        filter(
          highlightFields,
          (highlightFieldItem) =>
            highlightFieldItem.uuid === value.uuid &&
            highlightFieldItem.count_highlight > 0
        ).length > 0;
      return (
        <Row>
          <Col className="form-builder-field">
            <div className={bem.b()}>
              <div
                className={classnames(bem.e("form-field-group"), {
                  [bem.e("form-field-group-highlight")]: isHighlightFormField,
                  [bem.e("form-field-group-disable")]:
                    highlightFields.length > 0 &&
                    !isHighlightFormField &&
                    value.is_controlled,
                })}
              >
                <div
                  className={classnames(
                    bem.e("form-field-group-head"),
                    bem.e("form-field-group-media")
                  )}
                >
                  <div className={bem.e("form-field-group-head-single-left")}>
                    <span className={bem.e("menu-icon")}>
                      <MenuIcon />
                    </span>
                    <Row>
                      <Col xs={9}>
                        <div className={bem.e("input-label")}>
                          <TextInput
                            label={null}
                            name="label"
                            type="text"
                            placeholder={intl.formatMessage({
                              id: "form builder > label media",
                            })}
                            value={value.label}
                            onChange={({ target }) => {
                              onChange({
                                ...value,
                                label: target.value,
                              });
                            }}
                            disabled={disabled}
                            error={get(errors, "label", "")}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                  {allowDelete && (
                    <div
                      className={bem.e("form-field-group-head-right")}
                      onClick={onDelete}
                    >
                      <FaClose size="23" color="#C2D4E0" />
                    </div>
                  )}
                </div>
                <Row className={bem.e("form-field-group-bottom")}>
                  <Col xs={8}>
                    <div className={bem.e("media-mimes")}>
                      <Row>
                        <Col xs={12}>
                          <div className={bem.e("subtitle")}>
                            <FormattedMessage id="form builder > allow image file types" />
                          </div>
                          {allowImages.map((item, index) => (
                            <FormGroup key={index}>
                              <CheckboxDefault
                                text={
                                  <span className={bem.e("media-mime-label")}>
                                    {item}
                                  </span>
                                }
                                checked={
                                  findIndex(
                                    value.mimes,
                                    (selectedItem) => selectedItem === item
                                  ) > -1
                                }
                                onChange={(checked) => {
                                  let mimes = xor(value.mimes, [item]);
                                  onChange({
                                    ...value,
                                    mimes,
                                  });
                                }}
                                disabled={disabled}
                              />
                            </FormGroup>
                          ))}
                        </Col>
                        {/*<Col xs={6}>
                          <div className={bem.e("subtitle")}>
                            <FormattedMessage id="form builder > allow video file types" />
                          </div>
                          {allowVideos.map((item, index) => (
                            <FormGroup key={index}>
                              <CheckboxDefault
                                text={
                                  <span className={bem.e("media-mime-label")}>
                                    {item}
                                  </span>
                                }
                                checked={
                                  findIndex(
                                    value.mimes,
                                    selectedItem => selectedItem === item
                                  ) > -1
                                }
                                disabled={disabled}
                                onChange={checked => {
                                  let mimes = xor(value.mimes, [item]);
                                  onChange({
                                    ...value,
                                    mimes
                                  });
                                }}
                              />
                            </FormGroup>
                          ))}
                        </Col>*/}
                      </Row>
                    </div>
                  </Col>
                  <Col xs={4}>
                    <ToggleSwitch
                      leftComponent={
                        <span
                          className={classnames({
                            [bem.e("switch-active")]: !get(
                              value,
                              "is_required",
                              false
                            ),
                          })}
                        >
                          {isShowOffText
                            ? intl.formatMessage({
                                id: "form builder > off",
                              })
                            : ""}
                        </span>
                      }
                      rightComponent={
                        <span
                          className={classnames({
                            [bem.e("switch-active")]: get(
                              value,
                              "is_required",
                              false
                            ),
                          })}
                        >
                          {intl.formatMessage({
                            id: "form builder > required",
                          })}
                        </span>
                      }
                      name="is_required"
                      switchProps={{
                        checked: !get(value, "is_required", false),
                        onChange: (checked) => {
                          onChange({
                            ...value,
                            is_required: !checked,
                          });
                        },
                        disabled: disabled,
                        offColor: "#795AFA",
                        onColor: "#E3ECF1",
                        uncheckedIcon: false,
                        checkedIcon: false,
                        handleDiameter: 22,
                        width: 50,
                        height: 26,
                      }}
                    />
                    <div
                      className={classnames(
                        bem.e("description-text"),
                        bem.e("media-description-text")
                      )}
                    >
                      <p>
                        <FormattedMessage id="form builder > media description max file size" />
                      </p>
                      <p>
                        <FormattedMessage id="form builder > media description max files" />
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row>
          <Col>
            <div className={bem.b()}>
              <div className={bem.e("form-field-group-saved")}>
                <Label className={bem.e("form-field-label")}>
                  <span>
                    {get(value, "label", "")}{" "}
                    {get(value, "is_required", false) && <Asterisk>*</Asterisk>}
                  </span>
                </Label>
                <UploadFiles
                  attachText={intl.formatMessage({
                    id: "channel manager > media placeholder",
                  })}
                  // mimes
                  mimes={get(value, "mimes")}
                  acceptMultiple={true}
                  disabled
                  files={null}
                  isHideFilesDragArea={true}
                  onUploadedFiles={(value) => {}}
                  onDeleted={(value) => {}}
                >
                  <FormattedMessage id="channel manager > button upload" />
                </UploadFiles>
                {allowDelete && (
                  <div
                    className={bem.e("form-field-group-saved-button-close")}
                    onClick={onDelete}
                  >
                    <FaClose size="23" color="#C2D4E0" />
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    // ui for form
  } else {
    const originValue = value;
    const fieldName = labelToNameField(get(field, "label", ""));
    const isShowFileViewStatus = rest.isShowFileViewStatus || false;
    const isReviewed =
      filter(value, (valueItem) => valueItem.review_media_status).length > 0;
    let isHideHandleAssets = isShowFileViewStatus && isReviewed;
    const valueHistory = get(field, "value_history", []);
    // remove history files from value
    if (isShowFileViewStatus) {
      value = filter(value, (valueItem) => {
        return (
          !find(
            valueHistory,
            (valueHistoryItem) => valueHistoryItem.path === valueItem.path
          ) && !valueItem.is_replace
        ); // expect the asset for replaced by reject handle
      });
    }
    if (isView) {
      return (
        <Row>
          <Col>
            <div className={bem.b()}>
              <div className={bem.e("form-field-group-saved")}>
                <Label className={bem.e("form-field-label")}>
                  <span>{get(field, "label", "")} </span>
                </Label>
                {isShowFileViewStatus && valueHistory.length > 0 && (
                  <div className={bem.e("fields-status")}>
                    {map(valueHistory, (file, index) => {
                      return (
                        <div
                          key={index}
                          className={bem.e("fields-status-item")}
                        >
                          <div className={bem.e("fields-status-content")}>
                            {get(file, "review_media_status") ===
                              "approved" && (
                              <span className={bem.e("status-icon")}>
                                <ApproveIcon color="#73A703" />
                              </span>
                            )}
                            {get(file, "review_media_status") ===
                              "rejected" && (
                              <span className={bem.e("status-icon")}>
                                <TrashIcon color="#E40061" />
                              </span>
                            )}
                            <FileView
                              file={file}
                              isOverrideViewAction={true}
                              onClickViewAction={() => {
                                rest.setIsOpenPreviewAssetModal(true);
                                rest.setCurrentAsset(file);
                              }}
                              actions={["view", "download"]}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {!isHideHandleAssets &&
                  (get(value, "length") > 0 ? (
                    <HandleAssets
                      files={value}
                      viewDisabled={false}
                      renamingDisabled={true}
                      renamingHidden={true}
                      deleteDisabled={true}
                      deleteHidden={true}
                      downloadDisabled={false}
                      isView={isView}
                      isOverrideViewAction={!!rest.setIsOpenPreviewMediaModal}
                      onClickViewAction={(file) => {
                        rest.setIsOpenPreviewMediaModal({
                          field,
                          asset: file,
                        });
                      }}
                    />
                  ) : null)
                // <FormattedMessage id="form builder > none" />
                }
              </div>
            </div>
          </Col>
          {isShowFileViewStatus &&
            renderAssetModal({
              isOpen: rest.isOpenPreviewAssetModal,
              onToggle: () =>
                rest.setIsOpenPreviewAssetModal(!rest.isOpenPreviewAssetModal),
              asset: rest.currentAsset,
              media: field,
            })}
        </Row>
      );
    }
    const extensions = get(field, "mimes", []);
    let mimes = [];
    extensions.forEach((ext) => {
      mimes.push(ext);
      const mimeImageForExt = mimesImageWithExt.find(
        (item) => item.ext.toLowerCase() === ext.toLowerCase()
      );
      if (mimeImageForExt) {
        mimes = mimes.concat(mimeImageForExt.mimes);
      }
      const mimeVideoForExt = mimesVideoWithExt.find(
        (item) => item.ext.toLowerCase() === ext.toLowerCase()
      );
      if (mimeVideoForExt) {
        mimes = mimes.concat(mimeVideoForExt.mimes);
      }
    });
    return (
      <Row>
        <Col>
          <div className={bem.b()}>
            <div className={bem.e("form-field-group-saved")}>
              <FormGroup className={bem.e("form-group")}>
                <Label className={bem.e("form-field-label")}>
                  <span>
                    {get(field, "label", "")}{" "}
                    {get(field, "is_required", false) && <Asterisk>*</Asterisk>}
                  </span>
                </Label>
                {isShowFileViewStatus && valueHistory.length > 0 && (
                  <div className={bem.e("fields-status")}>
                    {map(valueHistory, (file, index) => {
                      const originFile = get(originValue, index);
                      return (
                        <div
                          key={index}
                          className={bem.e("fields-status-item")}
                        >
                          <div className={bem.e("fields-status-content")}>
                            {get(file, "review_media_status") ===
                              "approved" && (
                              <span className={bem.e("status-icon")}>
                                <ApproveIcon color="#73A703" />
                              </span>
                            )}
                            {get(file, "review_media_status") ===
                              "rejected" && (
                              <span className={bem.e("status-icon")}>
                                <TrashIcon color="#E40061" />
                              </span>
                            )}
                            <FileView
                              file={file}
                              isOverrideViewAction={true}
                              onClickViewAction={() => {
                                rest.setIsOpenPreviewAssetModal(true);
                                rest.setCurrentAsset(file);
                              }}
                              actions={["view", "download"]}
                            />
                          </div>
                          {get(file, "review_media_status") === "rejected" && (
                            <div className={bem.e("fields-status-upload")}>
                              {/* Only show handle assets for new upload when asset rejected */}
                              {!get(originFile, "review_media_status") &&
                                get(originFile, "is_replace") && (
                                  <HandleAssets
                                    files={[originFile]}
                                    onUploadedFiles={(updatedFiles) => {
                                      let newValue = first(updatedFiles);
                                      originValue[index] = {
                                        ...newValue,
                                        is_replace: true,
                                      };
                                      onChange(originValue);
                                    }}
                                    onDeleted={(newValue) => {
                                      // assign back rejected file
                                      originValue[index] = file;
                                      onChange(originValue);
                                    }}
                                  />
                                )}
                              {/* only show upload component for asset rejected */}
                              {get(originFile, "review_media_status") ===
                                "rejected" && (
                                <UploadFiles
                                  attachText={intl.formatMessage({
                                    id: "channel manager > attach file",
                                  })}
                                  // mimes
                                  mimes={mimes}
                                  multiple={false}
                                  files={[originFile] || []}
                                  isHideFilesDragArea={true}
                                  onUploadedFiles={(updatedFiles) => {
                                    let newValue = first(updatedFiles);
                                    originValue[index] = {
                                      ...newValue,
                                      is_replace: true,
                                    };
                                    onChange(originValue);
                                  }}
                                  disabled={disabled}
                                  onDeleted={(value) => {
                                    // onChange(value);
                                  }}
                                  maxFiles={2} // 2 files
                                  maxSize={10485760} // 10M
                                  className={bem.e("assets")}
                                  dropTextClass={bem.e("drop-text-class")}
                                >
                                  <FormattedMessage id="channel manager > button upload" />
                                </UploadFiles>
                              )}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                )}
                {!isHideHandleAssets && get(value, "length") > 0 && (
                  <HandleAssets
                    files={value}
                    onUploadedFiles={(value) => {
                      onChange(value);
                      if (setIsDirty) setIsDirty(true); //will touch auto save in the custom form
                    }}
                    onDeleted={(value) => {
                      onChange(value);
                    }}
                  />
                )}
                {!isReviewed && (
                  <UploadFiles
                    attachText={intl.formatMessage({
                      id: "channel manager > attach file",
                    })}
                    // mimes
                    mimes={mimes}
                    multiple={true}
                    files={value || []}
                    isHideFilesDragArea={true}
                    onUploadedFiles={(updatedFiles) => {
                      let newValue = updatedFiles;
                      if (originValue) {
                        newValue = concat(originValue, newValue);
                      }
                      onChange(newValue);
                    }}
                    disabled={disabled}
                    onDeleted={(value) => {
                      // onChange(value);
                    }}
                    maxFiles={10} // 10 files
                    maxSize={10485760} // 10M
                    className={bem.e("assets")}
                    dropTextClass={bem.e("drop-text-class")}
                  >
                    <FormattedMessage id="channel manager > button upload" />
                  </UploadFiles>
                )}
                {!!get(errors, fieldName, "") &&
                  get(touched, fieldName, false) && (
                    <span className="text-danger">
                      {get(errors, fieldName, "")}
                    </span>
                  )}
              </FormGroup>
            </div>
          </div>
        </Col>
        {isShowFileViewStatus &&
          renderAssetModal({
            isOpen: rest.isOpenPreviewAssetModal,
            onToggle: () =>
              rest.setIsOpenPreviewAssetModal(!rest.isOpenPreviewAssetModal),
            asset: rest.currentAsset,
            media: field,
          })}
      </Row>
    );
  }
};
Media.defaultProps = {
  isNew: false,
  isFormBuilder: true,
  errors: {},
  touched: {},
  onChange: () => {},
  onDelete: () => {},
  allowDelete: true,
  disabled: false,
  isShowOffText: true,
};
export default Media;
