import React, { useState } from "react";
import { Redirect } from "react-router";

import PrivilegedComponent from "components/PrivilegedComponent";
import Page from "components/Page";
import Calendar from "components/ScheduleComponents/Calendar";
import URL from "utils/urls";
import bn from "utils/bemnames";
import SearchInput from "components/SearchAutocompleteInput";
import { FormattedMessage } from "react-intl";
import { autoSuggestProgrammingElements } from "store/actions/process";
import AdvancedSearchModal from "components/AdvancedSearchModal";
import Spinner from "components/Spinner";
import { get, map, orderBy } from "lodash";
import { countdownWithTimezone } from "utils/helpers";
import moment from "moment";
const bem = bn.create("schedule");
const ScheduleHeader = (props) => {
  const {
    intl,
    onChange,
    searchValue,
    onSelect,
    scheduleCalendarFilter,
  } = props;
  return (
    <div className={bem.e("header")}>
      <div className={bem.e("page-title")}>
        <FormattedMessage id="schedule > page title" />
      </div>
      <div className={bem.e("search-input")}>
        <div className={bem.e("search-wrapper")}>
          <span
            onClick={() => props.setIsShowAdvancedSearchModal(true)}
            className={bem.e("advanced-search-text")}
          >
            <FormattedMessage id="process > advanced search" />
          </span>
          <SearchInput
            searchCallback={(params) => {
              autoSuggestProgrammingElements({
                data: {
                  keyword: params.q,
                  station_id: scheduleCalendarFilter.station_id,
                },
                cb: params.cb,
              });
            }}
            onChange={onChange}
            value={searchValue}
            placeholder={intl.formatMessage({
              id:
                "process > promotion > liner > schedule > search scheduled items",
            })}
            onSelect={onSelect}
          />
        </div>
      </div>
    </div>
  );
};
const SchedulePage = (props) => {
  const {
    intl,
    scheduleCalendarFilter,
    setScheduleCalendarFilter,
    onAdvancedSearch,
    loading,
    clocksData,
    isRendered,
  } = props;
  const definitions = [
    ...get(clocksData, "definitions", []),
    ...get(clocksData, "contest_types", []),
  ];

  const typeOptions = orderBy(
    map(definitions, (item) => ({
      value: item.id,
      label: item.name,
    })),
    ["label"],
    "asc"
  );
  const [isShowAdvancedSearchModal, setIsShowAdvancedSearchModal] = useState(
    false
  );
  const [selectedItems, setSelectedItems] = useState([]);
  const renderBreadcrumbs = () => {
    const { intl } = props;
    let breadcrumbs = [
      {
        name: intl.formatMessage({ id: "breadcrumbs > home" }),
        to: URL.HOME(),
        active: false,
      },
      {
        name: intl.formatMessage({ id: "breadcrumbs > on air schedule" }),
        active: true,
      },
    ];

    return breadcrumbs;
  };
  return (
    <PrivilegedComponent requires={[]}>
      {({ isValid }) => {
        if (isValid) {
          return (
            <Page
              applyPadding={false}
              breadcrumbs={renderBreadcrumbs()}
              className={bem.b()}
              headerChildren={
                <ScheduleHeader
                  intl={intl}
                  isShowAdvancedSearchModal={isShowAdvancedSearchModal}
                  setIsShowAdvancedSearchModal={setIsShowAdvancedSearchModal}
                  searchValue={get(scheduleCalendarFilter, "search", "")}
                  scheduleCalendarFilter={scheduleCalendarFilter}
                  onChange={(value) => {
                    setScheduleCalendarFilter({
                      ...scheduleCalendarFilter,
                      search: value,
                    });

                    if (!value) props.setAdvancedSearch({});
                  }}
                  onSelect={(value, item) => {
                    // After clicking enter, elements containing the keyword are highlighted in the schedule. Schedule moves to the first week the element appears if it’s in the future.
                    let calendarDate = scheduleCalendarFilter.current_date;
                    const orderStartDate = get(item, "order_start_date");
                    const timeData = countdownWithTimezone(orderStartDate); // default get current timezone of user browser
                    if (!get(timeData, "isNegative", false) && item) {
                      calendarDate = orderStartDate;
                    }
                    let weekdays = scheduleCalendarFilter.weekdays
                      ? scheduleCalendarFilter.weekdays
                      : "Monday-Friday";
                    const calendarDateObj = moment(calendarDate);
                    const dayOfWeek = calendarDateObj.day();

                    if (weekdays === "Monday-Friday") {
                      const isWeekend = dayOfWeek === 6 || dayOfWeek === 0; // 6 = Saturday, 0 = Sunday
                      if (isWeekend) {
                        // + 1 or 2 day to move next week for calendar view
                        const newCalendarDateObj = calendarDateObj.add(
                          dayOfWeek === 6 ? 2 : 1,
                          "days"
                        );
                        calendarDate = moment(newCalendarDateObj).format(
                          "YYYY-MM-DD"
                        );
                      }
                    }
                    // reset selected items
                    if (!value) {
                      setSelectedItems([]);
                    }
                    setScheduleCalendarFilter({
                      ...scheduleCalendarFilter,
                      current_date: calendarDate,
                      search: value,
                      selectedItem: item,
                    });
                  }}
                />
              }
            >
              <div className={bem.e("padding-container")}>
                {/* waiting for set current date */}
                {isRendered && props.stations.length > 0 && (
                  <Calendar
                    filterProps={{
                      isShowSearchInput: false,
                      isDropdownGroup: true,
                      isShortGroupByNumber: true,
                      isShowStationDropdown: true,
                    }}
                    selectedItems={selectedItems}
                    isOnAirSchedule
                    stations={props.stations}
                  />
                )}
              </div>
              <AdvancedSearchModal
                isOpen={isShowAdvancedSearchModal}
                onToggle={() =>
                  setIsShowAdvancedSearchModal(!isShowAdvancedSearchModal)
                }
                initialValues={props.advancedSearch}
                isSupportSearchResults
                isSupportType
                types={typeOptions}
                onSubmit={onAdvancedSearch}
                onSelectedAdvancedSearch={(items) => {
                  const itemsFormatted = map(items, (item) => ({
                    title: get(item, "_source.order_title", ""),
                    order_title: get(item, "_source.order_title", ""),
                    id: item._id,
                    order_process_id: item._id,
                    type_id: get(item, "_source.type_id", ""),
                    type: get(item, "_source.order_type", ""),
                  }));
                  setSelectedItems(itemsFormatted);
                  setIsShowAdvancedSearchModal(false);
                  setScheduleCalendarFilter({
                    ...scheduleCalendarFilter,
                    search: "Advanced Search", // add input search value
                  });
                }}
              />
              <Spinner isLoading={loading} />
            </Page>
          );
        } else {
          return <Redirect to="/" />;
        }
      }}
    </PrivilegedComponent>
  );
};

export default SchedulePage;
