import React from 'react';
import { Col} from "reactstrap";
import classnames from 'classnames';

import { PlusIcon } from 'components/CustomIcons';

const Slot = (props) => {

	const {
		bem,
		isSlotFocused,
		setIsSlotFocused,
		onClick,
		cssClass,
		showActiveState,
		styleInline
	} = props;

	return (
		<Col xs={1} 
			className={classnames(bem.e('slot'), 'p-0 m-0', {'active' : isSlotFocused && showActiveState}, cssClass)}
			onMouseEnter={e => setIsSlotFocused(true)}
			onMouseLeave={e => setIsSlotFocused(false)}
			onClick={onClick}
			style={styleInline}
		>
				{isSlotFocused && showActiveState && (<PlusIcon
					color="#FFFFFF"
					width={18}
					height={18}
				/>)}
		</Col>
	);
}

export default Slot;