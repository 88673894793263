import React from "react";
import bn from "utils/bemnames";
import classNames from "classnames";
import { get, map } from "lodash";
import PropTypes from "prop-types";
const bem = bn.create("jock-console");
function LinkItem(props) {
  const { item } = props;

  return (
    <div className={bem.e("link-item")}>
      <div className={bem.e("link-name")}>{item.label}:</div>
      <a href={item.url} target="__blank" className={bem.e("link-url")}>
        {item.url}
      </a>
    </div>
  );
}
export default function OtherLinks(props) {
  const { widget, isMultiView } = props;
  const links = get(widget, "fields.links", []);
  return (
    <div
      className={classNames(
        bem.e("other-links"),
        bem.e("widget-detail-padding")
      )}
    >
      <div
        className={classNames(bem.e("other-links-head-title"), {
          [bem.e("head-title-multi-view")]: isMultiView,
        })}
        style={{
          backgroundColor: isMultiView ? widget.color : "",
        }}
      >
        {get(widget, "name", "")}
      </div>
      <div className={bem.e("links-list")}>
        {map(links, (item, index) => (
          <LinkItem key={index} item={item} />
        ))}
      </div>
    </div>
  );
}

OtherLinks.defaultProps = {
  isMultiView: false,
  widget: {},
};
OtherLinks.propsTypes = {
  isMultiView: PropTypes.bool,
  widget: PropTypes.object.isRequired,
};
