import React from "react";
import { Label } from "reactstrap";
import Select, { components } from "react-select";
import {
  get,
  findIndex,
  includes,
  has,
  map,
  find,
  first,
  groupBy,
  forOwn,
  orderBy,
  split,
  last,
} from "lodash";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Checkbox from "components/Checkbox";
import { Asterisk } from "components/Elements";
import classnames from "classnames";
import TitleTooltip from "components/TitleTooltip";
import {
  CaretIcon,
  CloseSmallIcon,
  CloseTagDropdownIcon,
} from "components/CustomIcons";
import { STATION } from "utils/constants";

class SelectStations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closeMenuOnSelect: false,
      currentAction: props.isInit ? "init" : null,
    };
  }
  setCloseMenuOnSelect = (closeMenuOnSelect) => {
    this.setState({ closeMenuOnSelect });
  };

  setCurrentAction = (currentAction) => {
    this.setState({ currentAction });
  };

  checked_checkboxes = [];
  renderSingleSelect = ({ configComponents }) => {
    const SingleValue = (option) => {
      const { data } = option;
      return (
        <components.SingleValue {...option}>
          <div className="value-container">
            {data.firstLabel && (
              <div className="label">
                <TitleTooltip title={data.firstLabel} />
              </div>
            )}
            {data.lastLabel && (
              <div className="type">
                <TitleTooltip title={data.lastLabel} />
              </div>
            )}
          </div>
        </components.SingleValue>
      );
    };
    configComponents.SingleValue = SingleValue;
    const {
      name,
      disabled,
      item,
      validationState,
      onChange,
      placeholder,
      hideSelectedOptions,
      value,
      isGroup,
      isClearable,
      filteredStations,
      ...rest
    } = this.props;
    const stations = rest.stations ? rest.stations : filteredStations;
    const getOptions = () => {
      let selectedOption = null;
      const options = stations
        .filter((station) => !has(station, "type") || station.type === STATION)
        .map((station) => {
          const market = first(Object.values(station.market));
          return {
            value: station.station_key,
            label: station.name,
            firstLabel: get(station, this.props.optionKeys.first),
            lastLabel: get(station, this.props.optionKeys.last),
            name: station.name,
            key: station.key,
            data: {
              call_letters: station.call_letters,
              name: station.name,
              logo: station.logo,
              slogan: station.slogan,
              contact_email: station.contact_email,
              market,
              eligible_geographies: station.eligible_geographies || [],
            },
          };
        });
      selectedOption =
        find(options, (station) => station.value === value) || "";

      if (isGroup) {
        const groupedMarket = groupBy(options, "data.market.title");
        let groupedOptions = [];
        forOwn(groupedMarket, (items, label) => {
          if (label !== "undefined") {
            // remove option all
            const tempArr2 = split(label, /\d+\. /);
            const groupLabel = last(tempArr2); // remove prefix number for sort by alphabetically
            groupedOptions = [
              ...groupedOptions,
              {
                label: groupLabel,
                sort_key: groupLabel, // sort by label
                options: orderBy(items, ["label"], ["asc"]),
              },
            ];
          }
        });
        return {
          options: orderBy(groupedOptions, ["sort_key"], ["asc"]).map(
            (group, index) => ({
              ...group,
              label: this.props.isShortGroupByNumber
                ? `${index + 1}. ${group.label}`
                : group.label,
            })
          ),
          selectedOption,
        };
      }
      return { options, selectedOption };
    };
    const { options, selectedOption } = getOptions();
    if (disabled) {
      return (
        <span className={"select-stations-disabled"}>
          <Label>{item.title}</Label>
          <span>{get(selectedOption, "label", "")}</span>
        </span>
      );
    }
    const formatGroupLabel = (data) => (
      <div className={"select-group-option-label"}>
        <span>{data.label}</span>
      </div>
    );
    return (
      <div className="select-stations-wrapper">
        <Label for={name}>
          {this.props.label}
          {this.props.required ? <Asterisk>*</Asterisk> : null}
        </Label>
        {get(options, "length", 0) > 0 && (
          <Select
            ref={(ref) => (this.selectRef = ref)}
            hideSelectedOptions={hideSelectedOptions}
            placeholder={placeholder}
            options={options}
            className={classnames(
              "react-select-container",
              this.props.className && this.props.className.toString(),
              {
                "react-select-menu-open": rest.isMenuOpen,
              }
            )}
            classNamePrefix="react-select"
            value={selectedOption}
            onChange={(selected, { action }) => {
              this.setCurrentAction(action);
              return onChange(selected);
            }}
            components={configComponents}
            onMenuOpen={() => rest.setIsMenuOpen(true)}
            onMenuClose={() => rest.setIsMenuOpen(false)}
            formatGroupLabel={formatGroupLabel}
            defaultMenuIsOpen={this.props.defaultMenuIsOpen}
            menuShouldBlockScroll={this.props.menuShouldBlockScroll}
            isClearable={isClearable}
          />
        )}

        {get(validationState, `${item.field}`) &&
          get(validationState, `${item.field}.validation_error`) && (
            <div className="text-danger">
              {validationState[item.field] &&
                validationState[item.field].validation_error}
            </div>
          )}
      </div>
    );
  };
  renderMultipleSelect = ({ configComponents }) => {
    const {
      user,
      name,
      checked,
      disabled,
      item,
      allowSelectAll,
      allowSelectNone,
      noneOption,
      allOption,
      validationState,
      onChange,
      placeholder,
      hideSelectedOptions,
      allowDeselectOption,
      allowDisplaySelectedAll,
      allowDisplaySelectedNone,
      isInit,
      allowCloseMenuOnSelect,
      template,
      isGroup,
      filteredStations,
      ...rest
    } = this.props;
    const { closeMenuOnSelect } = this.state;

    let stations = filteredStations;
    const checked_items =
      checked ||
      stations
        .map((item) => ({
          value: get(item, "station_key"),
        }))
        .filter((value) => value);
    let checked_stations = map(checked_items.value, (item) => {
      const station = stations.find((s1) => s1.station_key === item);
      if (station) {
        let labelText = [];
        if (station.call_letters) {
          labelText.push(station.call_letters);
        }
        if (station.name) {
          labelText.push(station.name);
        }
        return {
          label: labelText.join(" - "),
          value: station.station_key,
          data: {
            call_letters: station.call_letters,
            name: station.name,
          },
          firstLabel: get(station, this.props.optionKeys.first, ""),
          lastLabel: get(station, this.props.optionKeys.last, ""),
        };
      }
      return {
        label: item,
        value: item,
      };
    });

    if (!this.props.contract && this.props.process) {
      if (
        this.props.process.data.fields.contract_stations &&
        this.props.process.data.fields.contract_stations.value
      ) {
        stations = this.props.process.data.fields.contract_stations.value
          .filter((s) =>
            filteredStations.find((item) => item.station_key === s)
          )
          .map((s) => ({
            station_key: s,
            ...filteredStations.find((item) => item.station_key === s),
          }));

        checked_stations = (checked_stations || []).filter((s) =>
          stations.find((s1) => s1.station_key === s.value)
        );
      } else {
        // reset stations options when new order
        stations = [];
      }
    }
    if (disabled) {
      return (
        <span className={"select-stations-disabled"}>
          <Label>{item.title}</Label>
          <br />
          {(checked_stations || []).map((s, i) => (
            <span
              key={i}
              style={{ paddingRight: "20px", display: "inline-block" }}
            >
              {s.label}
            </span>
          ))}
        </span>
      );
    }
    let value = checked_stations;
    this.checked_checkboxes = value.map((v) => v.value);

    const stationOptions = (stations || [])
      .filter((station) => !has(station, "type") || station.type === STATION)
      .map((station) => {
        const market = first(Object.values(station.market));
        return {
          value: station.station_key,
          label: station.name,
          firstLabel: get(station, this.props.optionKeys.first, ""),
          lastLabel: get(station, this.props.optionKeys.last, ""),
          name: station.name,
          data: {
            logo: station.logo,
            slogan: station.slogan,
            call_letters: station.call_letters,
            name: station.name,
            market,
            contact_email: station.contact_email,
            eligible_geographies: station.eligible_geographies || [],
          },
        };
      });

    const getOptions = () => {
      let options = stationOptions;
      if (this.props.allowSortOptions) {
        options = [
          ...value,
          ...stationOptions.filter(
            (item) =>
              value.findIndex((selected) => selected.value === item.value) ===
              -1
          ),
        ];
      }
      if (
        allowSelectNone &&
        stationOptions.length > 1 &&
        !this.props.checkboxes
      ) {
        options = [noneOption, ...options];
      }
      if (
        allowSelectAll &&
        stationOptions.length > 1 &&
        !this.props.checkboxes
      ) {
        options = [allOption, ...options];
      }
      if (isGroup) {
        const groupedMarket = groupBy(options, "data.market.title");
        let groupedOptions = [];
        forOwn(groupedMarket, (items, label) => {
          if (label !== "undefined") {
            // remove option all
            const tempArr2 = split(label, /\d+\. /);
            const groupLabel = last(tempArr2); // remove prefix number for sort by alphabetically
            groupedOptions = [
              ...groupedOptions,
              {
                label: groupLabel,
                sort_key: groupLabel, // sort by label
                options: orderBy(items, ["label"], ["asc"]),
              },
            ];
          }
        });
        return orderBy(groupedOptions, ["sort_key"], ["asc"]).map(
          (group, index) => ({
            ...group,
            label: this.props.isShortGroupByNumber
              ? `${index + 1}. ${group.label}`
              : group.label,
          })
        );
      }
      return options;
    };
    const options = getOptions();
    if (allowDisplaySelectedAll && value.length === stationOptions.length) {
      value = [allOption];
    }
    if (
      allowDisplaySelectedNone &&
      value.length === 0 &&
      !includes(["clear", "remove-value", "init"], this.state.currentAction)
    ) {
      value = [noneOption];
    }
    const formatGroupLabel = (data) => (
      <div className={"select-group-option-label"}>
        <span>{data.label}</span>
      </div>
    );
    return (
      <div>
        <Label for={name}>
          {this.props.label}
          {this.props.required ? <Asterisk>*</Asterisk> : null}
        </Label>
        {this.props.checkboxes && (
          <div>
            {options.length === 0 ? (
              <span>No stations selected in contract</span>
            ) : (
              options.map((o, index) => {
                let labelText = [];
                if (o.firstLabel) {
                  labelText.push(o.firstLabel);
                }
                if (o.lastLabel) {
                  labelText.push(o.lastLabel);
                }
                return (
                  <div
                    key={"station_" + index}
                    className="selected-station-item"
                  >
                    <Checkbox
                      checked={this.checked_checkboxes.indexOf(o.value) > -1}
                      style={{ display: "inline-block", marginRight: "10px" }}
                      onChange={(checked) => {
                        let cbx = this.checked_checkboxes;
                        if (!checked) {
                          let ind = cbx.indexOf(o.value);
                          if (ind > -1) cbx.splice(ind, 1);
                        } else cbx.push(o.value);
                        onChange(cbx);
                      }}
                      text={labelText.join(" - ")}
                    />
                  </div>
                );
              })
            )}
          </div>
        )}
        {!this.props.checkboxes && get(options, "length", 0) > 0 && (
          <Select
            ref={(ref) => (this.selectRef = ref)}
            hideSelectedOptions={hideSelectedOptions}
            placeholder={placeholder}
            options={options}
            className={classnames(
              "react-select-container",
              this.props.className && this.props.className.toString(),
              {
                "react-select-menu-open": rest.isMenuOpen,
              }
            )}
            formatGroupLabel={formatGroupLabel}
            classNamePrefix="react-select"
            isMulti
            value={value}
            closeMenuOnSelect={closeMenuOnSelect}
            onChange={(selected, { action }) => {
              this.setCurrentAction(action);
              if (!allowDeselectOption && action === "deselect-option") {
                return;
              }
              if (
                allowDisplaySelectedNone &&
                action === "select-option" &&
                findIndex(value, (item) => item.value === noneOption.value) > -1
              ) {
                return;
              }

              // selected none
              if (
                selected.length > 0 &&
                selected[selected.length - 1].value === noneOption.value
              ) {
                if (allowCloseMenuOnSelect) {
                  this.setCloseMenuOnSelect(true); // close dropdown
                }
                this.selectRef.blur(); // close dropdown
                return onChange([]);
              }

              if (
                (selected.length > 0 &&
                  selected[selected.length - 1].value === allOption.value) ||
                selected.length === stationOptions.length
              ) {
                if (allowCloseMenuOnSelect) {
                  this.setCloseMenuOnSelect(true); // close dropdown
                }
                this.selectRef.blur(); // close dropdown
                return onChange(stationOptions.map((item) => item.value));
              }

              this.setCloseMenuOnSelect(false); // close dropdown
              return onChange(
                selected
                  .filter(
                    (item) =>
                      item.value !== allOption.value &&
                      item.value !== noneOption.value
                  )
                  .map((item) => item.value)
              );
            }}
            components={configComponents}
            onMenuOpen={() => rest.setIsMenuOpen(true)}
            onMenuClose={() => rest.setIsMenuOpen(false)}
            defaultMenuIsOpen={this.props.defaultMenuIsOpen}
            menuShouldBlockScroll={this.props.menuShouldBlockScroll}
          />
        )}
        {validationState[item.field] &&
          validationState[item.field].validation_error && (
            <div className="text-danger">
              {validationState[item.field] &&
                validationState[item.field].validation_error}
            </div>
          )}
      </div>
    );
  };
  render() {
    const { isMulti, filteredStations, allowHideSelectIfOptions } = this.props;
    if (
      allowHideSelectIfOptions !== null &&
      filteredStations.length === Number(allowHideSelectIfOptions)
    ) {
      return null;
    }
    let configComponents = {};
    const DropdownIndicator = (props) => {
      return (
        components.DropdownIndicator && (
          <components.DropdownIndicator {...props} className="disable-unsaved">
            <CaretIcon className="disable-unsaved" />
          </components.DropdownIndicator>
        )
      );
    };
    configComponents.DropdownIndicator = DropdownIndicator;
    const ClearIndicator = (props) => {
      return (
        components.ClearIndicator && (
          <components.ClearIndicator {...props} className="disable-unsaved">
            <CloseSmallIcon className="disable-unsaved" />
          </components.ClearIndicator>
        )
      );
    };
    configComponents.ClearIndicator = ClearIndicator;
    const MultiValueRemove = (props) => {
      return (
        components.MultiValueRemove && (
          <components.MultiValueRemove {...props}>
            <CloseTagDropdownIcon />
          </components.MultiValueRemove>
        )
      );
    };
    configComponents.MultiValueRemove = MultiValueRemove;
    const CustomOption = (option) => {
      const { data } = option;
      const isDefaultOption =
        data.value === get(this.props, "noneOption.value") ||
        data.value === get(this.props, "allOption.value");
      return (
        <components.Option {...option}>
          {isDefaultOption ? (
            <div className="value-container">{data.label}</div>
          ) : (
            <div className="value-container">
              {data.firstLabel && (
                <div className="label">
                  <TitleTooltip title={data.firstLabel} />
                </div>
              )}
              {data.lastLabel && (
                <div className="type">
                  <TitleTooltip title={data.lastLabel} />
                </div>
              )}
            </div>
          )}
        </components.Option>
      );
    };
    configComponents.Option = CustomOption;
    if (!isMulti) {
      return this.renderSingleSelect({
        configComponents,
      });
    } else {
      return this.renderMultipleSelect({
        configComponents,
      });
    }
  }
}
SelectStations.propTypes = {
  name: PropTypes.string,
  allowSelectNone: PropTypes.bool,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  label: PropTypes.any,
  required: PropTypes.bool,
  hideSelectedOptions: PropTypes.bool,
  allowSortOptions: PropTypes.bool,
  allowDeselected: PropTypes.bool,
  allowDisplaySelectedAll: PropTypes.bool,
  allowDisplaySelectedNone: PropTypes.bool,
  allowCloseMenuOnSelect: PropTypes.bool,
  isMulti: PropTypes.bool,
  isGroup: PropTypes.bool,
  isClearable: PropTypes.bool,
  isFilterByClock: PropTypes.bool,
  isFilterByServerConfig: PropTypes.bool,
  isShortGroupByNumber: PropTypes.bool,
  defaultMenuIsOpen: PropTypes.bool,
  allowHideSelectIfOptions: PropTypes.number,
  exceptOptions: PropTypes.array,
  menuShouldBlockScroll: PropTypes.bool,
};

SelectStations.defaultProps = {
  allowSelectAll: true,
  allowSelectNone: false,
  allOption: {
    label: "Select All",
    value: "*",
  },
  noneOption: {
    label: "None",
    value: "-",
  },
  name: "select_stations",
  label: <FormattedMessage id="process > field stations" />,
  required: true,
  placeholder: "Select...",
  hideSelectedOptions: true,
  allowSortOptions: false,
  allowDeselectOption: true,
  allowDisplaySelectedAll: false,
  allowDisplaySelectedNone: false,
  allowCloseMenuOnSelect: true,
  isMulti: true,
  isGroup: false,
  isClearable: false,
  isFilterByClock: false,
  isFilterByServerConfig: false,
  isShortGroupByNumber: false,
  defaultMenuIsOpen: false,
  optionKeys: {
    first: "call_letters",
    last: "name",
  },
  allowHideSelectIfOptions: null,
  exceptOptions: [],
  menuShouldBlockScroll: true,
};
export default SelectStations;
