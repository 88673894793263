import { omit, map, get } from "lodash";
import { arrayContainsArray } from "utils/helpers";

export function generateField(field, countries, props = {}) {
  let newField = {
    ...omit(field, ["options"]),
    value: "",
    placeholder: "",
    is_new: true,
  };
  if (!field.multi_field) {
    newField.is_required = true;
  }
  switch (field.key) {
    case "gender":
      const genderMasterListener = get(
        props,
        "masterListenerForm._source.fields",
        []
      ).find((i) => i.key === field.key);
      if (get(props, "isSystemForm")) {
        newField.show_other = get(genderMasterListener, "show_other", false);
      }
      break;
    case "separator":
      newField.is_required = false;
      break;
    case "helper_text":
      newField.is_required = false;
      break;
    case "date_of_birth":
      newField.is_required = true;
      break;
    case "section_header":
      newField.label = "";
      break;
    case "business_address":
    case "home_address":
      const sub_fields = map(newField.sub_fields, (item) => {
        let label = item.value;
        if (
          item.key === "home_state_province" ||
          item.key === "business_state_province"
        ) {
          label = "State";
          if (countries.length === 1 && countries[0] === "CA") {
            label = "Province";
          }
          if (countries.length > 1 && arrayContainsArray(countries, ["CA"])) {
            label = "State / Province";
          }
          return {
            ...item,
            label,
          };
        }
        if (
          item.key === "home_zip_postal_code" ||
          item.key === "business_zip_postal_code"
        ) {
          label = "Zip Code";
          if (countries.length === 1 && countries[0] === "CA") {
            label = "Postal Code";
          }
          if (arrayContainsArray(countries, ["CA", "US"])) {
            label = "Zip / Postal Code";
          }
          return {
            ...item,
            label,
          };
        }

        if (item.key === "home_country" || item.key === "business_country") {
          return {
            ...item,
            is_hidden: arrayContainsArray(countries, ["US", "MX"])
              ? false
              : true,
          };
        }
        if (
          (field.key === "home_county" || field.key === "business_county") &&
          countries.length === 1 &&
          countries[0] === "CA"
        ) {
          // The County will disabled by default for Canada since they use Province.
          return {
            ...item,
            is_hidden: true,
          };
        }
        return item;
      });
      newField.sub_fields = sub_fields;
      break;
    default:
      break;
  }
  return newField;
}
