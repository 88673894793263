import { compose } from "recompose";
import View from "./view";
import { injectIntl } from "react-intl";
import { withState, withHandlers, withPropsOnChange } from "recompose";
import { countdownWithTimezone } from "utils/helpers";
import { get } from "lodash";

const Contest = compose(
  withState("isOpen", "setIsOpen", false),
  withState("countdowns", "setCountdowns", null),
  withState("intervalData", "setIntervalData", null),
  withHandlers({
    calculateCountdown: () => (deadline, timezone) =>
      countdownWithTimezone(deadline, timezone),
    stop: ({ intervalData, setIntervalData }) => async () => {
      clearInterval(intervalData);
      setIntervalData(null);
    },
  }),
  withPropsOnChange(
    ["item"],
    ({
      item,
      calculateCountdown,
      setCountdowns,
      isPast,
      intervalData,
      stop,
      setIntervalData,
    }) => {
      if (isPast) return;
      if (intervalData) {
        stop();
      }
      const deadline = get(item, `order_end_date`, null);
      const timezone = get(item, "timezone", null);
      const interval = setInterval(() => {
        const countdowns = calculateCountdown(deadline, timezone);
        setCountdowns(countdowns);
      }, 1000);
      setIntervalData(interval);
    }
  )
)(View);
export default injectIntl(Contest);
