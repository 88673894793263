import CalendarEventsModal from "./view";
import { get, map, sample } from "lodash";
import { connect } from "react-redux";
import { debounce } from "lodash";
import { calendarEvents } from "utils/config";
import * as moment from "moment";
import {
  compose,
  withHandlers,
  lifecycle,
  withState,
  withProps
} from "recompose";
import { getEvents } from "store/actions/events";
const calendarEventsArray = [
  calendarEvents.ON_HOLD_EVENT,
  calendarEvents.INTERNAL_EVENT,
  calendarEvents.CLIENT_EVENT,
  calendarEvents.SUCCESS_EVENT
];
const users = [
  {
    name: "ABC",
    id: 1,
    avatar:
      "https://promosuite-live.s3.amazonaws.com/9/c/d/e-8597-446b-860a-2c3ea47ca8013540f458-cfcb-4f07-b94f-3b7b173bf785||avatar_white_circle.png"
  },
  {
    name: "ABC",
    id: 2,  
    avatar:
      "https://promosuite-live.s3.amazonaws.com/9/c/d/e-8597-446b-860a-2c3ea47ca8013540f458-cfcb-4f07-b94f-3b7b173bf785||avatar_white_circle.png"
  }
];
export default compose(
  connect(
    state => {
      const events = get(state, `events.events.data`, []);
      const eventList = map(events, item => {
        return {
          id: get(item, "_source.id"),
          // allDay: get(item, "_source.allDay"),
          title: get(item, "_source.title"),
          subtitle: get(item, "_source.description"),
          address: "1106 W Lawrence Ave Chicago, IL 60640",
          start: get(item, "_source.start_date"),
          end: get(item, "_source.end_date"),
          type: sample(calendarEventsArray),
          leads: users,
          teams: users
        };
      });
      return {
        stations: get(state, "auth.info.stations", []),
        events: eventList,
        isLoading: get(state, `events.events.loading`, [])
      };
    },
    { getEvents }
  ),
  withProps(({ getEvents }) => ({
    getStationDelayed: debounce(params => {
      getEvents(params);
    }, 200)
  })),
  withState("currentType", "setCurrentType", false),
  withState("station", "setStation", ""),
  withState("day", "setDay", 7),
  withState("month", "setMonth", () => {
    const day = new Date();
    const monthFirstDay = moment(day)
      .startOf("month")
      .format("YYYY-MM-DD");
    const monthLastDay = moment(day)
      .endOf("month")
      .format("YYYY-MM-DD");
    return {
      start_date: monthFirstDay,
      end_date: monthLastDay
    };
  }),
  withHandlers({
    onChangeStation: ({
      setStation,
      getStationDelayed,
      currentType,
      month
    }) => station => {
      setStation(get(station, "key"));
      getStationDelayed({
        type: currentType,
        station: get(station, "key"),
        ...month
      });
    },
    onChangeMonth: ({
      setMonth,
      getStationDelayed,
      currentType,
      station
    }) => day => {
      const monthFirstDay = moment(day)
        .startOf("month")
        .format("YYYY-MM-DD");
      const monthLastDay = moment(day)
        .endOf("month")
        .format("YYYY-MM-DD");
      const month = {
        start_date: monthFirstDay,
        end_date: monthLastDay
      };
      setMonth(month);
      getStationDelayed({
        type: currentType,
        station,
        ...month
      });
    },
    onChangeType: ({
      setCurrentType,
      getStationDelayed,
      station,
      month
    }) => type => {
      setCurrentType(type);
      getStationDelayed({
        type,
        station,
        ...month
      });
    }
  }),
  lifecycle({
    componentDidMount() {
      const { station, currentType, month } = this.props;
      this.props.getStationDelayed({
        station,
        type: currentType,
        ...month
      });
    }
  })
)(CalendarEventsModal);
