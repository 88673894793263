import React from "react";
import Page from "components/Page";
import { FormattedMessage } from "react-intl";
import WorkflowManager from "components/WorkflowManager";
import PrivilegedComponent from "components/PrivilegedComponent";
import { get } from "lodash";
import { Redirect } from "react-router";
import { PRIVILEGES } from "utils/constants";
import { Link } from "react-router-dom";
import { adminTabs } from "utils/config";
import URL from "utils/urls";

const WorkflowManagerPage = props => {
  return (
    <PrivilegedComponent
      requires={privileges => privileges.indexOf(PRIVILEGES.EDIT_CHANNELS) > -1 || get(props, "auth.user.is_god_admin", false)}
    >
      {({ isValid }) => {
        if (isValid) {
          return (
            <Page
              applyPadding={false}
              title={
                <span>
                  <nav
                    className="cr-page__breadcrumb"
                    aria-label="breadcrumb"
                    style={{ fontSize: "initial" }}
                  >
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to={URL.ADMINS({ tab: adminTabs.TAB_UNKNOWN })}>
                          <FormattedMessage id="menu > admins" />
                        </Link>
                      </li>
                      <li
                        className="active breadcrumb-item"
                        aria-current="page"
                      >
                        <FormattedMessage id="workflow manager > page title" />
                      </li>
                    </ol>
                  </nav>
                  <span className="page-title">
                    <FormattedMessage id="workflow manager > page title" />
                  </span>
                </span>
              }
            >
              <div className={"page-container"}>
                <WorkflowManager />
              </div>
            </Page>
          );
        } else {
          return <Redirect to="/" />;
        }
      }}
    </PrivilegedComponent>
  );
};

export default WorkflowManagerPage;
