export const FETCH_ALL_SERVERS_REQUEST = "FETCH_ALL_SERVERS_REQUEST";
export const FETCH_ALL_SERVERS_SUCCESS = "FETCH_ALL_SERVERS_SUCCESS";
export const FETCH_ALL_SERVERS_ERROR = "FETCH_ALL_SERVERS_ERROR";

export const FETCH_SERVERS_REQUEST = "FETCH_SERVERS_REQUEST";
export const FETCH_SERVERS_SUCCESS = "FETCH_SERVERS_SUCCESS";
export const FETCH_SERVERS_ERROR = "FETCH_SERVERS_ERROR";

export const FETCH_MORE_SERVERS_REQUEST = "FETCH_MORE_SERVERS_REQUEST";
export const FETCH_MORE_SERVERS_SUCCESS = "FETCH_MORE_SERVERS_SUCCESS";
export const FETCH_MORE_SERVERS_ERROR = "FETCH_MORE_SERVERS_ERROR";

export const FETCH_SERVER_REQUEST = "FETCH_SERVER_REQUEST";
export const FETCH_SERVER_SUCCESS = "FETCH_SERVER_SUCCESS";
export const FETCH_SERVER_ERROR = "FETCH_SERVER_ERROR";

export const ADD_SERVER_REQUEST = "ADD_SERVER_REQUEST";
export const ADD_SERVER_SUCCESS = "ADD_SERVER_SUCCESS";
export const ADD_SERVER_ERROR = "ADD_SERVER_ERROR";

export const UPDATE_SERVER_REQUEST = "UPDATE_SERVER_REQUEST";
export const UPDATE_SERVER_SUCCESS = "UPDATE_SERVER_SUCCESS";
export const UPDATE_SERVER_ERROR = "UPDATE_SERVER_ERROR";

export const DELETE_SERVER_REQUEST = "DELETE_SERVER_REQUEST";
export const DELETE_SERVER_SUCCESS = "DELETE_SERVER_SUCCESS";
export const DELETE_SERVER_ERROR = "DELETE_SERVER_ERROR";

export const FETCH_SECURITY_POLICY_SERVER_REQUEST = "FETCH_SECURITY_POLICY_SERVER_REQUEST";
export const FETCH_SECURITY_POLICY_SERVER_SUCCESS = "FETCH_SECURITY_POLICY_SERVER_SUCCESS";
export const FETCH_SECURITY_POLICY_SERVER_ERROR = "FETCH_SECURITY_POLICY_SERVER_ERROR";

export const UPDATE_SECURITY_POLICY_SERVER_REQUEST = "UPDATE_SECURITY_POLICY_SERVER_REQUEST";
export const UPDATE_SECURITY_POLICY_SERVER_SUCCESS = "UPDATE_SECURITY_POLICY_SERVER_SUCCESS";
export const UPDATE_SECURITY_POLICY_SERVER_ERROR = "UPDATE_SECURITY_POLICY_SERVER_ERROR";

export const FETCH_PRODUCTS_SETTINGS_REQUEST = "FETCH_PRODUCTS_SETTINGS_REQUEST";
export const FETCH_PRODUCTS_SETTINGS_SUCCESS = "FETCH_PRODUCTS_SETTINGS_SUCCESS";
export const FETCH_PRODUCTS_SETTINGS_ERROR = "FETCH_PRODUCTS_SETTINGS_ERROR";

export const UPDATE_PRODUCTS_SETTINGS_REQUEST = "UPDATE_PRODUCTS_SETTINGS_REQUEST";
export const UPDATE_PRODUCTS_SETTINGS_SUCCESS = "UPDATE_PRODUCTS_SETTINGS_SUCCESS";
export const UPDATE_PRODUCTS_SETTINGS_ERROR = "UPDATE_PRODUCTS_SETTINGS_ERROR";

export const FETCH_CURRENT_SERVER_REQUEST = "FETCH_CURRENT_SERVER_REQUEST";
export const FETCH_CURRENT_SERVER_SUCCESS = "FETCH_CURRENT_SERVER_SUCCESS";
export const FETCH_CURRENT_SERVER_ERROR = "FETCH_CURRENT_SERVER_ERROR";

export const UPDATE_MATERIAL_GROUPS_REQUEST = "UPDATE_MATERIAL_GROUPS_REQUEST";
export const UPDATE_MATERIAL_GROUPS_SUCCESS = "UPDATE_MATERIAL_GROUPS_SUCCESS";
export const UPDATE_MATERIAL_GROUPS_ERROR = "UPDATE_MATERIAL_GROUPS_ERROR";

export const FETCH_MATERIAL_GROUPS_REQUEST = 'FETCH_MATERIAL_GROUPS_REQUEST';
export const FETCH_MATERIAL_GROUPS_SUCCESS = 'FETCH_MATERIAL_GROUPS_SUCCESS';
export const FETCH_MATERIAL_GROUPS_ERROR = 'FETCH_MATERIAL_GROUPS_ERROR';

