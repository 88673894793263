import React from "react";
import { Row, Button, Col, Input, Label } from "reactstrap";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { PlusSmallIcon, DeleteIcon } from "components/CustomIcons";
import AudiencePickerModal from "./AudiencePickerModal";
import SelectAudience from "components/SelectAudience";
import {
  get,
  isEqual,
  map,
  includes,
  uniqBy,
  filter,
  find,
  forEach,
  join,
} from "lodash";
import { generateRotationChart } from "utils/helpers";

import { ShadowBox, SectionTitle, SectionFields } from "components/Elements";

import bn from "utils/bemnames";
const bem = bn.create("order-details");

class AudienceDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldRefs: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps.info) !== JSON.stringify(this.props.info)) {
      this.setState({ fieldRefs: false });
      return;
    }

    for (
      let i = 0;
      i < Object.keys(nextProps.process.data.fields).length;
      i++
    ) {
      let key = Object.keys(nextProps.process.data.fields)[i];
      let changed = false;
      if (
        !nextProps.process.data.fields[key] &&
        !this.props.process.data.fields[key]
      )
        continue;
      else if (
        !nextProps.process.data.fields[key] &&
        this.props.process.data.fields[key]
      )
        changed = true;
      else if (
        nextProps.process.data.fields[key] &&
        !this.props.process.data.fields[key]
      )
        changed = true;
      else if (
        !nextProps.process.data.fields[key].value &&
        !this.props.process.data.fields[key].value
      )
        continue;
      else if (
        !nextProps.process.data.fields[key].value &&
        this.props.process.data.fields[key].value
      )
        changed = true;
      else if (
        nextProps.process.data.fields[key].value &&
        !this.props.process.data.fields[key].value
      )
        changed = true;
      else if (
        nextProps.process.data.fields[key].value.toString() !==
        this.props.process.data.fields[key].value.toString()
      )
        changed = true;
      else if (
        !isEqual(
          get(nextProps, `process.data.fields.${key}.value`),
          get(this.props, `process.data.fields.${key}.value`)
        )
      ) {
        changed = true;
      }
      if (changed) {
        if (
          ["audience_group_id", "audience_group_name", "audience"].indexOf(
            key
          ) > -1
        ) {
          this.setState({ fieldRefs: false });
          break;
        }
      }
    }
  }

  getFieldRefs() {
    if (this.state.fieldRefs) {
      return this.state.fieldRefs;
    }

    let { process, template, step } = this.props;

    step = template.steps.order_details;

    const producer_assigns_vo = step.fields.find(
      (f) => f.field === "producer_assigns_vo"
    );
    const audience_group_id = step.fields.find(
      (f) => f.field === "audience_group_id"
    );
    const audience_group_name = step.fields.find(
      (f) => f.field === "audience_group_name"
    );

    const order_stations = step.fields.find(
      (f) => f.field === "order_stations"
    );
    const is_multi_market = step.fields.find(
      (f) => f.field === "is_multi_market"
    );

    const audience = step.fields.find((f) => f.field === "audience");
    const rotation_chart = step.fields.find(
      (f) => f.field === "rotation_chart"
    );
    const order_start_date = step.fields.find(
      (f) => f.field === "order_start_date"
    );
    const order_end_date = step.fields.find(
      (f) => f.field === "order_end_date"
    );

    const contract_stations = step.fields.find(
      (f) => f.field === "contract_stations"
    );

    const contract_stations_order_number_array = step.fields.find(
      (f) => f.field === "contract_stations_order_number_array"
    );
    const markets = step.fields.find((f) => f.field === "markets");
    const select_stations = step.fields.find(
      (f) => f.field === "order_stations"
    );
    const order_length = step.fields.find((f) => f.field === "order_length");
    const order_length_custom = step.fields.find(
      (f) => f.field === "order_length_custom"
    );
    const spot_info = step.fields.find((f) => f.field === "spot_info");
    // must have order stations
    if (!this.props.process.data.fields.contract_stations) {
      let init_stations = []; // this.props.info.stations[0].station_key
      this.props.valueChanged(
        { field: "contract_stations" },
        this.props.process,
        this.props.template,
        this.props.user,
        { value: init_stations }
      );
    }

    let contractSaved = process.data.fields.contract_id !== undefined;

    let payload = {
      audience_group_id,
      audience_group_name,
      audience,
      contract_stations,
      contract_stations_order_number_array,
      select_stations,
      contractSaved,
      order_stations,
      rotation_chart,
      order_start_date,
      order_end_date,
      is_multi_market,
      markets,
      order_length,
      order_length_custom,
      spot_info,
      producer_assigns_vo,
    };

    this.setState({ fieldRefs: payload });

    return payload;
  }

  onValueChanged = (field, value) => {
    const { valueChanged, template, user, process } = this.props;

    valueChanged(field, process, template, user, { value });

    this.props.setIsFieldsChanged && this.props.setIsFieldsChanged(true);
  };

  onSelectedStations = (value) => {
    const {
      rotation_chart,
      contract_stations,
      order_stations,
    } = this.getFieldRefs();
    const { process } = this.props;
    this.onValueChanged(contract_stations, value);
    this.onValueChanged(order_stations, value);
    this.props.onValueTimezoneChanged(value);
    const rotationChart = generateRotationChart(
      process,
      value,
      get(process, "data.fields.spot_info.value", [])
    );
    this.onValueChanged(rotation_chart, rotationChart);
  };

  onSelectedAudience = (audienceGroup, addedStations, selectedTags) => {
    let {
      audience_group_id,
      audience_group_name,
      audience,
      markets,
      is_multi_market,
    } = this.getFieldRefs();
    const { process } = this.props;
    const marketsValue = get(process, "data.fields.markets.value", []);
    const stations = audienceGroup
      ? get(audienceGroup, "selected_stations", [])
      : addedStations;
    const selectedAudience = map(stations, ({ key, station_key, market }) => {
      return {
        key,
        station_key,
      };
    });
    let selectedMarkets = selectedTags ? Object.values(selectedTags) : [];
    let newSelectedMarkets = [];
    forEach(uniqBy(selectedMarkets, "id"), (item) => {
      const marketStations = filter(stations, (station) => {
        return (
          station.market &&
          includes(Object.keys(station.market).map(Number), Number(item.id))
        );
      }).map((station) => station.station_key);
      if (marketStations.length === 0) return;
      const existsMarketData = find(
        marketsValue,
        (market) => market.id === item.id
      );
      if (existsMarketData) {
        // Only sync order stations and step when exists market data
        newSelectedMarkets = [
          ...newSelectedMarkets,
          {
            ...existsMarketData,
            order_stations: marketStations,
            role_continuity: get(existsMarketData, "role_continuity")
              ? get(existsMarketData, "role_continuity")
              : get(process, "data.fields.role_continuity.value", null),
          },
        ];
      } else {
        newSelectedMarkets = [
          ...newSelectedMarkets,
          {
            id: item.id,
            title: item.label,
            order_stations: marketStations,
            role_continuity: get(
              process,
              "data.fields.role_continuity.value",
              null
            ),
            role_dubber: null,
            role_producer: null,
            spot_info: get(process, "data.fields.spot_info.value", []),
          },
        ];
      }
    });
    const selectedStations = selectedAudience.map(
      (station) => station.station_key
    );
    if (audienceGroup) {
      this.onValueChanged(audience_group_id, audienceGroup.id);
      this.onValueChanged(audience_group_name, audienceGroup.name);
    } else {
      this.onValueChanged(audience_group_id, "");
      this.onValueChanged(audience_group_name, "");
    }
    this.props.onGetTeamsAndLeads(selectedAudience);
    this.onValueChanged(markets, newSelectedMarkets);
    this.onValueChanged(is_multi_market, true);
    this.onValueChanged(audience, selectedAudience);
    this.onSelectedStations(selectedStations);
  };
  displaySelectedAudience() {
    let {
      audience_group_id,
      audience_group_name,
      audience,
    } = this.getFieldRefs();

    let { process, intl } = this.props;

    if (
      process.data.fields[audience_group_id.field] &&
      process.data.fields[audience_group_id.field].value &&
      process.data.fields[audience_group_name.field] &&
      process.data.fields[audience_group_name.field].value
    ) {
      return (
        <div className={bem.e("audience-selected")}>
          <span className={"audience-name"}>
            {process.data.fields[audience_group_name.field].value}
          </span>
          <span className="audience-stations">
            {get(process, `data.fields.${audience.field}.value.length`)}
            {` `}
            {intl.formatMessage({ id: "process > audience stations" })}
          </span>
        </div>
      );
    } else if (process.data.fields[audience.field]) {
      return (
        <div className={bem.e("audience-selected")}>
          <span className="audience-stations">
            {get(process, `data.fields.${audience.field}.value.length`)}
            {` `}
            {intl.formatMessage({ id: "process > audience stations" })}
          </span>
        </div>
      );
    } else {
      return intl.formatMessage({ id: "process > no audience selected" });
    }
  }

  renderForm() {
    const {
      audience_group_id,
      audience_group_name,
      audience,
      contract_stations,
      contract_stations_order_number_array,
      select_stations,
      rotation_chart,
      contractSaved,
      order_length,
      order_length_custom,
      spot_info,
      producer_assigns_vo,
    } = this.getFieldRefs();
    const {
      isAudiencePickerModalOpen,
      setIsAudiencePickerModalOpen,
      process,
      validationState,
      multipleStationSelectionChanged,
      template,
      user,
      auth,
      setShouldBlockNavigation,
    } = this.props;
    const isShowAudience = false; // get(auth, "info.production_multimarket", false);
    const isSpecOrder = get(process, "data.fields.order_sold.value") === false;
    const stationsData = get(auth, "info.stations", []);
    const isEnableEnterprise = get(auth, "info.production_multimarket", false);

    return (
      <>
        <ShadowBox className={bem.e("box-audience-details")}>
          <SectionTitle className={bem.e("section-title")}>
            <FormattedMessage id="process > title audience details" />
          </SectionTitle>
          <SectionFields
            className={classnames(
              bem.e("section-fields"),
              bem.e("section-audience")
            )}
          >
            {// multi market
            isShowAudience ? (
              <React.Fragment>
                <Row>
                  <Col xs="12" className={bem.e("col-reset")}>
                    <div className={bem.e("select-audience-wrapper")}>
                      <div
                        className={classnames("audience", {
                          "no-audience-selected":
                            !process.data.fields[audience.field] &&
                            !process.data.fields[audience_group_name.field],
                        })}
                      >
                        {this.displaySelectedAudience()}
                        <div className="alert-danger">
                          {validationState[audience.field] &&
                            validationState[audience.field].validation_error}
                        </div>
                      </div>
                      <div
                        className={classnames(bem.e("select-audience"), {
                          "no-audience-selected":
                            !process.data.fields[audience.field] &&
                            !process.data.fields[audience_group_name.field],
                        })}
                        id="select-audience-button"
                      >
                        <Button
                          className={classnames(
                            bem.e("add-button"),
                            "btn-radius"
                          )}
                          outline
                          color="blue"
                          onClick={() => setIsAudiencePickerModalOpen(true)}
                        >
                          <span className={bem.e("add-icon")}>
                            <PlusSmallIcon />
                          </span>
                          <span className="button-text">
                            <FormattedMessage
                              id={
                                process.data.fields[audience.field]
                                  ? "process > edit audience"
                                  : "process > select audience"
                              }
                            />
                          </span>
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Row>
                  <Col xs="12" lg="6" xl={4} className={bem.e("col-field")}>
                    <SelectAudience
                      contract={true}
                      validationState={validationState}
                      item={contract_stations}
                      label={contract_stations.title}
                      disabled={contractSaved}
                      {...contract_stations.props}
                      checked={process.data.fields[contract_stations.field]}
                      placeholder={this.props.intl.formatMessage({
                        id: "process > placeholder select entities",
                      })}
                      isMulti={true}
                      value={get(
                        process,
                        `data.fields.${contract_stations.field}.value`
                      )}
                      onChange={(selectedStations) => {
                        let isSelectedByDefault = false;
                        const value = map(selectedStations, (i) => {
                          // selected default value from SelectAudience component will return array object
                          if (typeof i === "object" && i.value) {
                            isSelectedByDefault = true;
                            return i.value;
                          }
                          return i;
                        });
                        if (!isSelectedByDefault) {
                          setShouldBlockNavigation(true);
                        }
                        multipleStationSelectionChanged(
                          contract_stations,
                          process,
                          template,
                          user,
                          value
                        );
                        this.props.setIsFieldsChanged(true);
                        const currentSelectedOrderStations = get(
                          process,
                          "data.fields.order_stations.value",
                          []
                        );
                        const isSingle = value.length === 1;
                        let newSelectOrderStations = currentSelectedOrderStations.filter(
                          (item) => includes(value, item)
                        );
                        // selected the order stations by default if has only station. https://tasks.getventive.com/projects/DE6D8-71A?bug=288C6-BE4D
                        if (isSingle && newSelectOrderStations.length === 0) {
                          newSelectOrderStations = value;
                          this.props.onValueTimezoneChanged(value);
                          const rotationChart = generateRotationChart(
                            process,
                            value,
                            get(process, "data.fields.spot_info.value", [])
                          );
                          this.onValueChanged(rotation_chart, rotationChart);
                          this.props.getProductionSettings(
                            {
                              station_id: value[0],
                            },
                            (data) => {
                              if (!data) return;
                              if (producer_assigns_vo) {
                                this.onValueChanged(
                                  producer_assigns_vo,
                                  Boolean(data.producer_assigns_vo)
                                );
                              }
                              if (order_length) {
                                this.onValueChanged(
                                  order_length,
                                  data.default_spot_length
                                );
                              }
                              if (order_length_custom) {
                                this.onValueChanged(
                                  order_length_custom,
                                  Boolean(data.custom_spot_length)
                                );
                              }

                              const newSpotInfo = get(
                                process,
                                "data.fields.spot_info.value",
                                []
                              ).map((spot) => ({
                                ...spot,
                                recording_requires_approval: Boolean(
                                  data.recording_approval
                                ),
                                script_requires_approval: Boolean(
                                  data.script_approval
                                ),
                                writer_sends_script_to_client: Boolean(
                                  data.writer_sends_script_to_client
                                ),
                                producer_sends_spot_to_client: Boolean(
                                  data.producer_sends_spot_to_client
                                ),
                                default_words_per_min: data.default_words_per_min
                                  ? Number(data.default_words_per_min)
                                  : null,
                                default_words_per_min_b: data.default_words_per_min
                                  ? Number(data.default_words_per_min)
                                  : null,
                                order_length: data.default_spot_length || "",
                                order_length_custom: Boolean(
                                  data.custom_spot_length
                                ),
                              }));

                              if (spot_info) {
                                this.onValueChanged(spot_info, newSpotInfo);
                              }
                            }
                          );
                        }
                        multipleStationSelectionChanged(
                          select_stations,
                          process,
                          template,
                          user,
                          newSelectOrderStations
                        );
                        if (isEnableEnterprise) {
                          this.props.onGetTeamsAndLeads(newSelectOrderStations);
                        }
                      }}
                      isFilterByServerConfig={true}
                      template={template}
                    />
                  </Col>
                </Row>
                {get(
                  process,
                  `data.fields.${contract_stations.field}.value.length`
                ) > 0 && (
                  <Row>
                    <Col xs={12} className={bem.e("rotations")}>
                      <Label>
                        {contract_stations_order_number_array.title}
                      </Label>
                      {process.data.fields[contract_stations.field].value.map(
                        (station, index) => {
                          let num = "";
                          if (
                            process.data.fields[
                              contract_stations_order_number_array.field
                            ] !== undefined
                          ) {
                            let contract_stations_order_number_field =
                              process.data.fields[
                                contract_stations_order_number_array.field
                              ];
                            if (
                              contract_stations_order_number_field.value !==
                              undefined
                            ) {
                              contract_stations_order_number_field.value.forEach(
                                (item) => {
                                  if (item.station === station) {
                                    num = item.number;
                                  }
                                }
                              );
                            }
                          } else if (
                            process.data.fields[
                              "contract_stations_order_number"
                            ] !== undefined
                          ) {
                            let contract_stations_order_number_field =
                              process.data.fields[
                                "contract_stations_order_number"
                              ];
                            if (
                              contract_stations_order_number_field.value !==
                              undefined
                            ) {
                              num =
                                contract_stations_order_number_field.value[
                                  station
                                ];
                            }
                          }
                          const stationData = find(
                            stationsData,
                            (item) => item.station_key === station
                          );
                          let stationArr = [];
                          if (get(stationData, "call_letters", ""))
                            stationArr.push(stationData.call_letters);
                          if (get(stationData, "name", ""))
                            stationArr.push(stationData.name);
                          return (
                            <div
                              className={bem.e("rotation-table")}
                              key={index}
                            >
                              <div className={bem.e("rotation-name")}>
                                {stationArr.length > 0 ? (
                                  join(stationArr, " - ")
                                ) : (
                                  <FormattedMessage id="process > none" />
                                )}
                              </div>
                              <div className={bem.e("rotation-value")}>
                                <Input
                                  value={num}
                                  placeholder={
                                    isSpecOrder
                                      ? this.props.intl.formatMessage({
                                          id: "process > placeholder order id",
                                        })
                                      : this.props.intl.formatMessage({
                                          id:
                                            "process > placeholder order number",
                                        })
                                  }
                                  onChange={(event) => {
                                    let val = event.target.value;
                                    let vals = [];
                                    if (
                                      process.data.fields[
                                        contract_stations_order_number_array
                                          .field
                                      ] !== undefined
                                    ) {
                                      let contract_stations_order_number_field =
                                        process.data.fields[
                                          contract_stations_order_number_array
                                            .field
                                        ];
                                      if (
                                        contract_stations_order_number_field.value !==
                                        undefined
                                      ) {
                                        vals =
                                          contract_stations_order_number_field.value;
                                      }
                                    } else if (
                                      process.data.fields[
                                        "contract_stations_order_number"
                                      ] !== undefined
                                    ) {
                                      let contract_stations_order_number_field =
                                        process.data.fields[
                                          "contract_stations_order_number"
                                        ];
                                      if (
                                        contract_stations_order_number_field.value !==
                                        undefined
                                      ) {
                                        for (var key in contract_stations_order_number_field.value) {
                                          vals.push({
                                            station: key,
                                            number:
                                              contract_stations_order_number_field
                                                .value[key],
                                          });
                                        }
                                      }
                                    }

                                    let numberIndex = null;
                                    vals.forEach((item, key) => {
                                      if (item.station === station) {
                                        numberIndex = key;
                                      }
                                    });

                                    if (numberIndex !== null) {
                                      vals[numberIndex].number = val;
                                    } else {
                                      vals.push({
                                        station: station,
                                        number: val,
                                      });
                                    }

                                    this.onValueChanged(
                                      contract_stations_order_number_array,
                                      vals
                                    );
                                    this.props.setIsFieldsChanged(true);
                                    setShouldBlockNavigation(true);
                                  }}
                                />
                              </div>
                              <span
                                className={bem.e("delete-order-number")}
                                onClick={(e) => {
                                  let vals = [];
                                  if (
                                    process.data.fields[
                                      contract_stations_order_number_array.field
                                    ] !== undefined
                                  ) {
                                    let contract_stations_order_number_field =
                                      process.data.fields[
                                        contract_stations_order_number_array
                                          .field
                                      ];
                                    if (
                                      contract_stations_order_number_field.value !==
                                      undefined
                                    ) {
                                      vals =
                                        contract_stations_order_number_field.value;
                                    }
                                  } else if (
                                    process.data.fields[
                                      "contract_stations_order_number"
                                    ] !== undefined
                                  ) {
                                    let contract_stations_order_number_field =
                                      process.data.fields[
                                        "contract_stations_order_number"
                                      ];
                                    if (
                                      contract_stations_order_number_field.value !==
                                      undefined
                                    ) {
                                      for (var key in contract_stations_order_number_field.value) {
                                        vals.push({
                                          station: key,
                                          number:
                                            contract_stations_order_number_field
                                              .value[key],
                                        });
                                      }
                                    }
                                  }

                                  vals.forEach((item, key) => {
                                    if (item.station === station) {
                                      vals.splice(key, 1);
                                    }
                                  });

                                  this.onValueChanged(
                                    contract_stations_order_number_array,
                                    vals
                                  );
                                  let value =
                                    process.data.fields[contract_stations.field]
                                      .value || [];
                                  if (value.indexOf(station) > -1)
                                    value.splice(value.indexOf(station), 1);
                                  multipleStationSelectionChanged(
                                    contract_stations,
                                    process,
                                    template,
                                    user,
                                    value
                                  );
                                  this.props.setIsFieldsChanged(true);
                                  const currentSelectedOrderStations = get(
                                    process,
                                    "data.fields.order_stations.value",
                                    []
                                  );
                                  const newSelectOrderStations = currentSelectedOrderStations.filter(
                                    (item) => includes(value, item)
                                  );
                                  multipleStationSelectionChanged(
                                    select_stations,
                                    process,
                                    template,
                                    user,
                                    newSelectOrderStations
                                  );
                                  setShouldBlockNavigation(true);
                                }}
                              >
                                <DeleteIcon />
                              </span>
                            </div>
                          );
                        }
                      )}
                      {validationState[
                        contract_stations_order_number_array.field
                      ] && (
                        <div className="alert-danger">
                          {
                            validationState[
                              contract_stations_order_number_array.field
                            ].validation_error
                          }
                        </div>
                      )}
                    </Col>
                  </Row>
                )}
              </React.Fragment>
            )}
          </SectionFields>
        </ShadowBox>
        <AudiencePickerModal
          isOpen={isAudiencePickerModalOpen}
          onToggle={() =>
            setIsAudiencePickerModalOpen(!isAudiencePickerModalOpen)
          }
          isForceUpdateSelect={true}
          onClose={this.onSelectedAudience}
          isEdit={
            process.data.fields[audience_group_id.field] &&
            process.data.fields[audience_group_id.field].value
          }
          selectedGroupId={
            process.data.fields[audience_group_id.field] &&
            process.data.fields[audience_group_id.field].value
              ? process.data.fields[audience_group_id.field].value
              : null
          }
          selectedAudience={
            (!process.data.fields[audience_group_id.field] ||
              !process.data.fields[audience_group_id.field].value) &&
            process.data.fields[audience.field]
              ? map(
                  process.data.fields[audience.field].value,
                  (station) => station.key
                )
              : []
          }
        />
      </>
    );
  }

  renderView() {
    const {
      audience_group_name,
      audience,
      contract_stations,
      contract_stations_order_number_array,
    } = this.getFieldRefs();

    const { process, auth } = this.props;
    const isEnableEnterprise = false; //get(auth, "info.production_multimarket", false);
    const stationsData = get(auth, "info.stations", []);

    return (
      <>
        <ShadowBox className={bem.e("box-audience-details")}>
          <SectionTitle className={bem.e("section-title")}>
            <FormattedMessage id="process > title audience details" />
          </SectionTitle>
          <SectionFields
            className={classnames(
              bem.e("section-fields"),
              bem.e("section-audience")
            )}
          >
            {isEnableEnterprise ? (
              <React.Fragment>
                <Row>
                  <Col xs="12" className={bem.e("col-reset")}>
                    <div className={bem.e("select-audience-wrapper")}>
                      <div
                        className={classnames("audience", {
                          "no-audience-selected":
                            !process.data.fields[audience.field] &&
                            !process.data.fields[audience_group_name.field],
                        })}
                      >
                        {this.displaySelectedAudience()}
                      </div>
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            ) : (
              <Row>
                <Col xs={12} className={bem.e("col-view-rotation-field")}>
                  <Label>{contract_stations_order_number_array.title}</Label>
                  <div
                    className={classnames(bem.e("rotation-container-view"), {
                      [bem.e("rotation-container-view-empty")]:
                        get(
                          process,
                          "data.fields.contract_stations.value.length"
                        ) === 0,
                    })}
                  >
                    {process.data.fields[contract_stations.field] &&
                      process.data.fields[contract_stations.field].value.map(
                        (station, index) => {
                          let num = "";
                          if (
                            process.data.fields[
                              contract_stations_order_number_array.field
                            ] !== undefined
                          ) {
                            let contract_stations_order_number_field =
                              process.data.fields[
                                contract_stations_order_number_array.field
                              ];
                            if (
                              contract_stations_order_number_field.value !==
                              undefined
                            ) {
                              contract_stations_order_number_field.value.forEach(
                                (item) => {
                                  if (item.station === station) {
                                    num = item.number;
                                  }
                                }
                              );
                            }
                          } else if (
                            process.data.fields[
                              "contract_stations_order_number"
                            ] !== undefined
                          ) {
                            let contract_stations_order_number_field =
                              process.data.fields[
                                "contract_stations_order_number"
                              ];
                            if (
                              contract_stations_order_number_field.value !==
                              undefined
                            ) {
                              num =
                                contract_stations_order_number_field.value[
                                  station
                                ];
                            }
                          }
                          const stationData = find(
                            stationsData,
                            (item) => item.station_key === station
                          );
                          let stationArr = [];
                          if (get(stationData, "call_letters", ""))
                            stationArr.push(stationData.call_letters);
                          if (get(stationData, "name", ""))
                            stationArr.push(stationData.name);
                          return (
                            <div
                              className={classnames(
                                bem.e("rotation-table"),
                                bem.e("rotation-view-table")
                              )}
                              key={index}
                            >
                              <div className={bem.e("rotation-name")}>
                                {stationArr.length > 0 ? (
                                  join(stationArr, " - ")
                                ) : (
                                  <FormattedMessage id="process > none" />
                                )}
                              </div>
                              <div className={bem.e("rotation-value")}>
                                <span className={bem.e("rotation-number-view")}>
                                  {num
                                    ? num
                                    : this.props.intl.formatMessage({
                                        id: "process > none",
                                      })}
                                </span>
                              </div>
                            </div>
                          );
                        }
                      )}
                    {get(
                      process,
                      "data.fields.contract_stations.value.length"
                    ) === 0 && (
                      <span className={bem.e("order-view-value")}>
                        {this.props.intl.formatMessage({
                          id: "process > none",
                        })}
                      </span>
                    )}
                  </div>
                </Col>
              </Row>
            )}
          </SectionFields>
        </ShadowBox>
      </>
    );
  }

  render() {
    const { isView } = this.props;

    if (isView) {
      return this.renderView();
    } else {
      return this.renderForm();
    }
  }
}

export default AudienceDetails;
