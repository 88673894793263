import React from 'react';
import { Col} from "reactstrap";
import classnames from 'classnames';

import { ClockIcon, PlusIcon } from 'components/CustomIcons';

const Slot = (props) => {

	const {
		bem,
		isSlotFocused,
		setIsSlotFocused,
		onClick,
	} = props;

	return (
		<Col xs={1} 
			className={classnames(bem.e('slot'), 'p-0 m-0', {'active' : isSlotFocused})}
			onMouseEnter={e => setIsSlotFocused(true)}
			onMouseLeave={e => setIsSlotFocused(false)}
			onClick={onClick}
		>
				{!isSlotFocused ? (
					<ClockIcon
						color="#FFFFFF"
						width={19}
						height={20}
					/>
				) : (
					<PlusIcon
						color="#FFFFFF"
						width={18}
						height={18}
					/>
				)}
		</Col>
	);
}

export default Slot;