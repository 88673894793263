import { connect } from "react-redux";
import DubButton from "./view";
import { compose, withHandlers, lifecycle } from "recompose";
import { get, isFunction } from "lodash";
import { automateDubbing, updateDubbingProgress, updateDubbingButtonTimeout } from 'store/actions/processes';

const Enhanced = compose(
  connect(
    (state, props) => {
      return {
        ...props,
        dubbingProgress: get(state, `processes.dubbingProcesses.data.${props.buttonKey}.progress`, 0),
        dubbingError: get(state, `processes.dubbingProcesses.data.${props.buttonKey}.error`, null),
        buttonTimeout: get(state, `processes.dubbingProcesses.data.${props.buttonKey}.timeout`, null),
      };
    },
    {
      automateDubbing,
      updateDubbingProgress,
      updateDubbingButtonTimeout
    }
  ),
  withHandlers({
    setDubbingProgress: ({ updateDubbingProgress, buttonKey }) => (progress) => {
      updateDubbingProgress(buttonKey, progress);
    },
    setDubbingButtonTimeout: ({ buttonKey, updateDubbingButtonTimeout }) => (timeout) => {
      updateDubbingButtonTimeout(buttonKey, timeout);
    },
  }),
  withHandlers({
    callAutomateDubbing: ({ automateDubbing, setDubbingButtonTimeout, spotIndex, maxProgressBarLength, setDubbingProgress, buttonKey, dubCallback }) => (id, dubbingProgress) => {

      let timeout = setTimeout(function (){        
        let progress = dubbingProgress;

        let interval = setInterval(function(){

          if(progress <= maxProgressBarLength){
            progress = progress + 2;
            setDubbingProgress(progress);
          }
        }, 1000);

        let callback = (process) => { 
          setDubbingProgress(0);         
          clearInterval(interval);
          if(isFunction(dubCallback)){
            setTimeout(function (){
              dubCallback(process);
            }, 3000);
          }
        }

        automateDubbing(id, {
          callback,
          spotIndex,
        });
      }, 3000);

      setDubbingButtonTimeout(timeout);
    },
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      if(this.props.dubbingError && !prevProps.dubbingError){
        this.props.setDubbingProgress(0);
        this.props.setDubbingButtonTimeout(null);
      }
    },
  })
)(DubButton);

export default Enhanced;
