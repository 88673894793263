import PropTypes from "prop-types";
import React from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/lib/fa";
import { map, isFunction } from "lodash";
import { Row, Col } from "reactstrap";
import { navigate } from "./utils/constants";
import Dropdown from "components/Dropdown";
import { FormattedMessage } from "react-intl";
import ToggleSwitch from "components/ToggleSwitch";
import FilterDay from "./FilterDay";
class Toolbar extends React.Component {
  render() {
    let {
      // localizer: { messages },
      date,
      stations,
      onChangeStation,
      onChangeDay,
      onChangeType,
      currentType,
      currentModeDay,
      days
    } = this.props;
    const year = date.getFullYear();
    const month = date.toLocaleString("default", { month: "long" });
    const stationsOption = map(stations, station => ({
      value: station.user_station_id,
      key: station.key,
      label: station.name
    }));

    return (
      <Row className="rbc-toolbar ps-rbc-toolbar">
        <Col>
          <Row>
            <Col>
              <Dropdown
                onChange={selectedOption => {
                  if (isFunction(onChangeStation))
                    onChangeStation(selectedOption);
                }}
                options={stationsOption}
              />
            </Col>
            <Col>
              <ToggleSwitch
                leftComponent={
                  <FormattedMessage id="calendar > private event" />
                }
                rightComponent={
                  <FormattedMessage id="calendar > public event" />
                }
                switchProps={{
                  checked: currentType,
                  onChange: checked => {
                    if (isFunction(onChangeType)) onChangeType(checked);
                  }
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col className="rbc-btn-group ps-rbc-btn-group-center">
          {/* <button
            type="button"
            onClick={this.navigate.bind(null, navigate.TODAY)}
          >
            {messages.today}
          </button> */}
          <button
            type="button"
            onClick={this.navigate.bind(null, navigate.PREVIOUS)}
            className="ps-rbc-toolbar-button"
          >
            <FaChevronLeft width={8} />
          </button>
          <span className="rbc-toolbar-label ps-rbc-toolbar-label">
            {`${month} ${year}`}
          </span>
          <button
            type="button"
            onClick={this.navigate.bind(null, navigate.NEXT)}
            className="ps-rbc-toolbar-button"
          >
            <FaChevronRight width={8} />
          </button>
        </Col>
        <Col className="rbc-btn-group">
          <FilterDay
            days={days}
            currentDay={currentModeDay}
            onChange={onChangeDay}
          />
          {/* <span className="rbc-btn-group">{this.viewNamesGroup(messages)}</span> */}
        </Col>
      </Row>
    );
  }

  navigate = action => {
    this.props.onNavigate(action);
  };

  // view = view => {
  //   this.props.onView(view);
  // };

  // viewNamesGroup(messages) {
  //   let viewNames = this.props.views;
  //   const view = this.props.view;

  //   if (viewNames.length > 1) {
  //     return viewNames.map(name => (
  //       <button
  //         type="button"
  //         key={name}
  //         className={cn({ "rbc-active": view === name })}
  //         onClick={this.view.bind(null, name)}
  //       >
  //         {messages[name]}
  //       </button>
  //     ));
  //   }
  // }
}
Toolbar.defaultProps = {
  currentType: false
};
Toolbar.propTypes = {
  // view: PropTypes.string.isRequired,
  // views: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.node.isRequired,
  localizer: PropTypes.object,
  onNavigate: PropTypes.func.isRequired,
  onChangeStation: PropTypes.func,
  onChangeDay: PropTypes.func,
  onChangeType: PropTypes.func,
  currentType: PropTypes.any,
  days: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  )
  // onView: PropTypes.func.isRequired
};

export default Toolbar;
