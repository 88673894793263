import View from "./view";
import { get } from "lodash";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
export default injectIntl(
  connect(
    (state) => ({
      users: get(state, "auth.info.users", []),
    }),
    {}
  )(View)
);
