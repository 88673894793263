import React from "react";
import bn from "utils/bemnames";
import { FormattedMessage } from "react-intl";
import { Button } from "reactstrap";
import { get, find } from "lodash";
import { Link } from "react-router-dom";
import classnames from "classnames";
import SearchInput from "components/SearchAutocompleteInput";
import { PlusIcon, HelpArrowIcon } from "components/CustomIcons";
import CustomMultiSelectDropdown from "components/CustomMultiSelectDropdown";
import Dropdown from "components/Dropdown";
import { adminTabs } from "utils/config";
import URL from "utils/urls";

const bem = bn.create("process-list");
function ReportsListHeader(props) {
  const {
    exportWinners,
    saveSearch,
    intl,
    optionStations,
    selectedStations,
    setSelectedStations,
    total,
    optionYears,
    setSelectedYear,
    selectedYear,
    setIsAudiencePickerModalOpen,
    isShowAudience,
    selectedItems,
  } = props;
  const savedSearchesItems = get(props, "savedSearches", []);
  const selectedYearData =
    find(optionYears, (i) => i.value === selectedYear) || "";
  const isExportDisabled =
    selectedItems && Object.keys(selectedItems).length === 0;
  return (
    <div
      className={classnames(
        bem.e("winners-header"),
        bem.e("1099-report-winners-header")
      )}
    >
      <div className={bem.e("header")}>
        <div className={bem.e("title")}>
          <Link to={URL.ADMINS({ tab: adminTabs.TAB_UNKNOWN })}>
            <FormattedMessage id="breadcrumbs > home" /> /{` `}
          </Link>
          <strong>
            <FormattedMessage id={`breadcrumbs > 1099 report`} />
          </strong>
        </div>
        <div className={bem.e("winners-filter")}>
          {/* stations */}
          <div className={bem.e("winners-stations")}>
            <span className={bem.e("viewing-text")}>
              <FormattedMessage id="winners > viewing" />
            </span>
            {isShowAudience ? (
              <Button
                className={classnames(bem.e("button-audience"), "btn-radius")}
                outline
                color="blue"
                onClick={() => setIsAudiencePickerModalOpen(true)}
              >
                <span className={bem.e("add-icon")}>
                  <PlusIcon />
                </span>
                <span className="button-text">
                  {selectedStations.length > 0 ? (
                    intl.formatMessage(
                      {
                        id: "winners > count entities",
                      },
                      {
                        COUNT: selectedStations.length,
                      }
                    )
                  ) : (
                    <FormattedMessage id={"winners > select audience"} />
                  )}
                </span>
              </Button>
            ) : (
              <CustomMultiSelectDropdown
                name="stations"
                value={selectedStations}
                options={optionStations}
                placeholder={intl.formatMessage({
                  id: "winners > please select",
                })}
                isAllowAllOption={true}
                allOption={{
                  label: intl.formatMessage({
                    id: "winners > all stations",
                  }),
                  value: "*",
                }}
                isStation
                isMulti={true}
                error={null}
                onChange={(value) => {
                  setSelectedStations(value);
                }}
                multiText={`${selectedStations.length} ${intl.formatMessage({
                  id: "winners > stations",
                })}`}
              />
            )}
          </div>
          <div className={bem.e("filter-years")}>
            <Dropdown
              onChange={(selectedOption) => {
                setSelectedYear(selectedOption);
              }}
              value={selectedYearData}
              options={optionYears}
              placeholder={intl.formatMessage({
                id: "winners > please select",
              })}
            />
          </div>
        </div>
      </div>
      <div className={bem.e("winners-actions")}>
        <div className={bem.e("export-winners")}>
          <div className={bem.e("total")}>
            <strong>{total}</strong>
            <FormattedMessage id="winners > winners" />
          </div>
          {props.isShowSaveSearch && (
            <Button
              color="link"
              className={bem.e("btn-save-search")}
              onClick={saveSearch}
            >
              <FormattedMessage id="winners > save search" />
            </Button>
          )}
          {total > 0 ? (
            <Button
              color="link"
              className={bem.e("btn-export")}
              onClick={exportWinners}
              disabled={isExportDisabled}
            >
              <FormattedMessage id="winners > export list" />
            </Button>
          ) : null}
        </div>
        {props.isShowGuide && (
          <div className={bem.e("guide-help")}>
            <FormattedMessage id="winners > start by selecting stations here" />
            <span className={bem.e("arrow-icon")}>
              <HelpArrowIcon />
            </span>
          </div>
        )}
        {props.isShowSearch && (
          <div className={bem.e("search-wrapper")}>
            <span
              onClick={() => {
                props.setIsShowAdvancedSearchModal(true);
              }}
              className={bem.e("advanced-search-text-relative")}
            >
              <FormattedMessage id="process > advanced search" />
            </span>
            <SearchInput
              searchCallback={(params) => {
                // response fake data to display the menu items of auto-complete lib
                params.cb([
                  {
                    title: "",
                  },
                ]);
              }}
              onChange={(value) => {
                props.setSearchText(value);
              }}
              onClearSearch={props.onClearSearch}
              value={props.searchText}
              placeholder={""}
              isShowSavedSearches
              savedSearches={savedSearchesItems}
              isShowMenuItems={false}
              onSelectSavedSearch={props.onSelectedSavedSearch}
              onSearchClicked={props.onSearchClicked}
            />
          </div>
        )}
      </div>
    </div>
  );
}
ReportsListHeader.defaultProps = {
  isShowSearch: true,
  isShowSaveSearch: false,
  exportWinners: () => {},
  saveSearch: () => {},
};
export default ReportsListHeader;
