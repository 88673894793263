import React from "react";
import bn from "utils/bemnames";
import classnames from "classnames";
import { FaClose, FaPlus, FaInfoCircle } from "react-icons/lib/fa";
import { labelToNameField, humanize, uuidv4 } from "utils/helpers";
import { FormGroup, Row, Col, Label } from "reactstrap";
import ToggleSwitch from "components/ToggleSwitch";
import TextInput from "components/TextInput";
import BranchIcon from "assets/img/icons/branch.png";
import BranchBlueIcon from "assets/img/icons/branch-blue.png";
import RuleModal from "../RuleModal";
import { MenuIcon } from "components/CustomIcons";
import { default as RadioButtonDefault } from "components/Radio";
import { get, findIndex, filter, map, forEach, uniqBy } from "lodash";
import { Asterisk } from "components/Elements";
import { EditConditionIcon } from "components/CustomIcons";
import { FormattedMessage } from "react-intl";
import Tooltip from "rc-tooltip";
import SortableComponent from "../SortableComponent";

const bem = bn.create("form-builder-fields");

const Radio = ({
  intl,
  isNew,
  isView,
  field,
  value,
  errors,
  onChange,
  onDelete,
  allowDelete,
  touched,
  isFormBuilder,
  highlightFields,
  onToggleSetHighlighFormFields,
  onChangeOtherFields,
  formFields,
  disabled,
  isAllowConditionLogic,

  isOpenRuleModal,
  condition,
  setCondition,
  setIsOpenRuleModal,
  highlightSelection,
  setHighlightSelection,
  isMasterListener,
  colNumber,
  isRenderRow,
  isShowOffText,
  isShowSectionHeadingOfList,
}) => {
  // UI for form builder
  // if(!value.label) value = {label: value, value}
  if (isFormBuilder) {
    if (isNew) {
      const isHighlightFormField =
        filter(
          highlightFields,
          (highlightFieldItem) =>
            highlightFieldItem.uuid === get(value, "uuid") &&
            highlightFieldItem.count_highlight > 0
        ).length > 0;
      let highlightParentSelection = null;
      forEach(highlightSelection, (i) => {
        if (get(i, "parent.uuid") === value.uuid) {
          highlightParentSelection = i.parent;
        }
      });
      return (
        <Row>
          <Col className="form-builder-field">
            <div className={bem.b()}>
              <div
                className={classnames(bem.e("form-field-group"), {
                  [bem.e("form-field-group-highlight")]: isHighlightFormField,
                  [bem.e("form-field-group-disable")]:
                    highlightFields.length > 0 &&
                    !isHighlightFormField &&
                    value.is_controlled &&
                    !highlightParentSelection,
                })}
              >
                <div className={bem.e("form-field-group-head")}>
                  <div className={bem.e("form-field-group-head-single-left")}>
                    <span className={bem.e("menu-icon")}>
                      <MenuIcon />
                    </span>
                    <Row>
                      <Col xs={9}>
                        <div
                          className={classnames(bem.e("input-label"), {
                            [bem.e("master-input-field")]: isMasterListener,
                          })}
                        >
                          <TextInput
                            label={null}
                            name="label"
                            type="text"
                            placeholder={intl.formatMessage({
                              id: "form builder > label radio label",
                            })}
                            value={value.label}
                            onChange={({ target }) => {
                              onChange({
                                ...value,
                                label: target.value,
                              });
                            }}
                            disabled={disabled}
                            error={get(errors, "label", "")}
                          />
                        </div>
                      </Col>
                      <Col xs={3}>
                        <ToggleSwitch
                          leftComponent={
                            <span
                              className={classnames({
                                [bem.e("switch-active")]: !get(
                                  value,
                                  "is_required",
                                  false
                                ),
                              })}
                            >
                              {isShowOffText
                                ? intl.formatMessage({
                                    id: "form builder > off",
                                  })
                                : ""}
                            </span>
                          }
                          rightComponent={
                            <span
                              className={classnames({
                                [bem.e("switch-active")]: get(
                                  value,
                                  "is_required",
                                  false
                                ),
                              })}
                            >
                              {intl.formatMessage({
                                id: "form builder > required",
                              })}
                            </span>
                          }
                          name="is_required"
                          switchProps={{
                            checked: !get(value, "is_required", false),
                            onChange: (checked) => {
                              onChange({
                                ...value,
                                is_required: !checked,
                              });
                            },
                            disabled: disabled,
                            offColor: "#795AFA",
                            onColor: "#E3ECF1",
                            uncheckedIcon: false,
                            checkedIcon: false,
                            handleDiameter: 22,
                            width: 50,
                            height: 26,
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                  {allowDelete && (
                    <div
                      className={bem.e("form-field-group-head-right")}
                      onClick={onDelete}
                    >
                      <FaClose size="23" color="#C2D4E0" />
                    </div>
                  )}
                </div>
                <Row className={bem.e("form-field-group-bottom")}>
                  <Col xs={9}>
                    <div
                      className={classnames(
                        bem.e("selections"),
                        bem.e("radio-selections"),
                        bem.e("field-draggable")
                      )}
                    >
                      {isShowSectionHeadingOfList ? (
                        <h3 className={bem.e("selections-heading")}>
                          <FormattedMessage id="form builder > selections" />
                        </h3>
                      ) : null}
                      <SortableComponent
                        data={value.options}
                        onSorted={(options) => {
                          onChange({
                            ...value,
                            options,
                          });
                        }}
                        disabled={disabled}
                        renderItem={(item, index) => {
                          const isExistsHighlight = findIndex(
                            highlightSelection,
                            (itemHighligh) =>
                              (itemHighligh.value === item.value &&
                                get(itemHighligh, "parent.label", "") ===
                                  get(value, "label", "")) ||
                              (itemHighligh.key &&
                                itemHighligh.key === item.key)
                          );
                          return (
                            <FormGroup
                              className={classnames(
                                bem.e("selection-item-container"),
                                {
                                  [bem.e("selection-item-container-selected")]:
                                    isExistsHighlight !== -1,
                                }
                              )}
                              key={index}
                            >
                              <div className={bem.e("selection-item")}>
                                <span className={bem.e("menu-selection-icon")}>
                                  <MenuIcon />
                                </span>
                                <RadioButtonDefault
                                  className={bem.e("menu-selection-icon")}
                                  checked={get(item, "is_default")}
                                  disabled={disabled}
                                  onChange={(checked) => {
                                    let options = (value.options || []).map(
                                      (itemValue) => ({
                                        ...itemValue,
                                        is_default: false,
                                      })
                                    );

                                    options[index] = {
                                      ...options[index],
                                      is_default: checked,
                                    };
                                    onChange({
                                      ...value,
                                      options,
                                    });
                                  }}
                                />
                                <TextInput
                                  label={null}
                                  name=""
                                  type="text"
                                  placeholder={`Selection Number ${humanize(
                                    index + 1
                                  )}`}
                                  disabled={disabled}
                                  value={item.value}
                                  onChange={({ target }) => {
                                    let options = value.options || [];
                                    options[index] = {
                                      ...options[index],
                                      label: target.value,
                                      value: target.value,
                                    };
                                    onChange({
                                      ...value,
                                      options,
                                    });
                                  }}
                                  error={
                                    !get(value, `options.${index}.value`) &&
                                    get(errors, "options", "")
                                      ? get(errors, "options", "")
                                      : ""
                                  }
                                />
                                {get(value, "is_condition_logic", false) && (
                                  <span className={bem.e("branch-icon")}>
                                    {get(item, "rule.options.length", 0) > 0 ? (
                                      <img
                                        src={BranchBlueIcon}
                                        alt="branch-icon"
                                        className={classnames({
                                          [bem.e("branch-icon-selected")]:
                                            isExistsHighlight !== -1,
                                        })}
                                        onClick={() => {
                                          let newHighlightFields = [];
                                          if (isExistsHighlight !== -1) {
                                            // remove highlight for current selection
                                            highlightSelection.splice(
                                              isExistsHighlight,
                                              1
                                            );
                                            // remove highlight for form parent
                                            newHighlightFields = map(
                                              newHighlightFields,
                                              (newHighlightItem) => {
                                                if (
                                                  findIndex(
                                                    item.rule.options,
                                                    (itemRuleOption) =>
                                                      itemRuleOption.value ===
                                                      newHighlightItem.value
                                                  ) !== -1
                                                ) {
                                                  let newCountHighlight =
                                                    Number(
                                                      newHighlightItem.count_highlight
                                                    ) - 1;
                                                  if (newCountHighlight < 0)
                                                    newCountHighlight = 0;
                                                  return {
                                                    ...newHighlightItem,
                                                    count_highlight: newCountHighlight,
                                                  };
                                                }
                                                return newHighlightItem;
                                              }
                                            ).filter(
                                              (newHighlightItem) =>
                                                newHighlightItem.count_highlight >
                                                0
                                            );
                                          } else {
                                            // set highlight for current selection
                                            highlightSelection = [
                                              {
                                                ...item,
                                                parent: value,
                                              },
                                            ];
                                            // set highligh for form parent
                                            if (
                                              newHighlightFields.length === 0
                                            ) {
                                              newHighlightFields = map(
                                                item.rule.options,
                                                (itemRuleOption) => ({
                                                  ...itemRuleOption,
                                                  count_highlight: 1,
                                                })
                                              );
                                            } else {
                                              forEach(
                                                item.rule.options,
                                                (itemRuleOption) => {
                                                  const indexExistsHighlighField = findIndex(
                                                    newHighlightFields,
                                                    (newHighlightItem) =>
                                                      itemRuleOption.value ===
                                                      newHighlightItem.value
                                                  );
                                                  if (
                                                    indexExistsHighlighField !==
                                                    -1
                                                  ) {
                                                    newHighlightFields[
                                                      indexExistsHighlighField
                                                    ] = {
                                                      ...newHighlightFields[
                                                        indexExistsHighlighField
                                                      ],
                                                      count_highlight:
                                                        Number(
                                                          newHighlightFields[
                                                            indexExistsHighlighField
                                                          ].count_highlight
                                                        ) + 1,
                                                    };
                                                  } else {
                                                    newHighlightFields = [
                                                      ...newHighlightFields,
                                                      {
                                                        ...itemRuleOption,
                                                        count_highlight: 1,
                                                      },
                                                    ];
                                                  }
                                                }
                                              );
                                            }
                                          }
                                          setHighlightSelection(
                                            highlightSelection
                                          );
                                          onToggleSetHighlighFormFields(
                                            newHighlightFields
                                          );
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={BranchIcon}
                                        alt="branch-icon"
                                        className="branch-disabled"
                                      />
                                    )}
                                  </span>
                                )}
                                {index >= 2 && (
                                  <div
                                    className={classnames(
                                      bem.e("selection-remove"),
                                      {
                                        [bem.e("selection-remove-logic")]: get(
                                          value,
                                          "is_condition_logic",
                                          false
                                        ),
                                      }
                                    )}
                                    onClick={() => {
                                      if (disabled) return;
                                      let options = value.options || [];
                                      options.splice(index, 1);
                                      onChange({
                                        ...value,
                                        options,
                                      });
                                    }}
                                  >
                                    <FaClose size="23" color="#795AFA" />
                                  </div>
                                )}
                              </div>
                              {get(value, "is_condition_logic", false) &&
                                get(item, "rule.options.length", 0) === 0 && (
                                  <span
                                    className={classnames(bem.e("push-rule"), {
                                      [bem.e("push-rule-disabled")]: !get(
                                        item,
                                        "value",
                                        ""
                                      ),
                                    })}
                                    onClick={() => {
                                      if (disabled || !get(item, "value", ""))
                                        return;
                                      setIsOpenRuleModal(!isOpenRuleModal);
                                      setCondition(item);
                                    }}
                                  >
                                    <FaPlus />
                                    <FormattedMessage id="form builder > add a rule" />
                                  </span>
                                )}
                              {get(value, "is_condition_logic", false) &&
                                get(item, "rule.options.length", 0) > 0 && (
                                  <div className={bem.e("rule-container")}>
                                    <span
                                      className={classnames(
                                        bem.e("rule-edit"),
                                        {
                                          [bem.e("push-rule-disabled")]: !get(
                                            item,
                                            "value",
                                            ""
                                          ),
                                        }
                                      )}
                                      onClick={() => {
                                        if (disabled || !get(item, "value", ""))
                                          return;
                                        setIsOpenRuleModal(true);
                                        setCondition(item);
                                      }}
                                    >
                                      <EditConditionIcon
                                        color={
                                          disabled || !get(item, "value", "")
                                            ? "#e3ecf1"
                                            : "#795afa"
                                        }
                                      />
                                      <FormattedMessage id="form builder > edit" />
                                    </span>
                                    {get(item, "rule.is") ? (
                                      <div
                                        className={bem.e(
                                          "rule-condition-label"
                                        )}
                                      >
                                        <FormattedMessage id="form builder > condition" />
                                        :
                                        <span
                                          className={bem.e(
                                            "rule-condition-label-value"
                                          )}
                                        >
                                          <FormattedMessage
                                            id={`rule > ${get(
                                              item,
                                              "rule.is",
                                              ""
                                            )}`}
                                          />
                                        </span>
                                      </div>
                                    ) : null}
                                    <div className={bem.e("list-rule-options")}>
                                      {get(item, "rule.options", []).map(
                                        (ruleOption, indexRueOption) => {
                                          return (
                                            <div
                                              className={bem.e("rule-option")}
                                              key={indexRueOption}
                                            >
                                              <span
                                                className={bem.e("rule-show")}
                                              >
                                                <FormattedMessage id="form builder > show" />
                                              </span>
                                              <span
                                                className={bem.e(
                                                  "rule-option-value"
                                                )}
                                              >
                                                {ruleOption.label}
                                              </span>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                )}
                            </FormGroup>
                          );
                        }}
                      />
                      <div
                        className={bem.e("button-new-selection")}
                        onClick={() => {
                          if (disabled) return;
                          let options = value.options || [];
                          onChange({
                            ...value,
                            options: [
                              ...options,
                              {
                                label: "",
                                value: "",
                                is_default: false,
                                key: uuidv4(),
                              },
                            ],
                          });
                        }}
                      >
                        <FaPlus />
                        <FormattedMessage id="form builder > add selection" />
                      </div>
                    </div>
                  </Col>
                  {isAllowConditionLogic && (
                    <Col xs={3}>
                      <div className={bem.e("description-text")}>
                        <FormattedMessage id="form builder > radio description" />
                      </div>
                      <div className={bem.e("selection-condition")}>
                        <Tooltip
                          placement="right"
                          trigger={["click", "hover"]}
                          overlayClassName={bem.e("tooltip")}
                          overlay={
                            <div className={bem.e("tooltip-content")}>
                              <p className={bem.e("tooltip-title")}>
                                <FormattedMessage id="form builder > tooltip conditional logic" />
                              </p>
                              <p className={bem.e("tooltip-text")}>
                                <FormattedMessage id="form builder > create rules to show other fields based on values entered in this field." />
                              </p>
                            </div>
                          }
                        >
                          <span>
                            <FormattedMessage id="form builder > conditional logic" />
                            <FaInfoCircle color="#795AFA" />
                          </span>
                        </Tooltip>
                      </div>
                      <FormGroup className={bem.e("selection-checkbox")}>
                        <ToggleSwitch
                          leftComponent={
                            <span
                              className={classnames({
                                [bem.e("switch-active")]: !get(
                                  value,
                                  "is_condition_logic",
                                  false
                                ),
                              })}
                            >
                              {intl.formatMessage({
                                id: "form builder > off",
                              })}
                            </span>
                          }
                          rightComponent={
                            <span
                              className={classnames({
                                [bem.e("switch-active")]: get(
                                  value,
                                  "is_condition_logic",
                                  false
                                ),
                              })}
                            >
                              {intl.formatMessage({
                                id: "form builder > enabled",
                              })}
                            </span>
                          }
                          name="is_condition_logic"
                          switchProps={{
                            checked: !get(value, "is_condition_logic", false),
                            onChange: (checked) => {
                              const newConditionLogic = !checked;
                              const options = get(value, "options", []);
                              if (!newConditionLogic) {
                                // reset highlight
                                setHighlightSelection([]);
                                onToggleSetHighlighFormFields([]);
                              }
                              onChange({
                                ...value,
                                options: options.map((i) => ({
                                  ...i,
                                  rule: null,
                                })),
                                is_condition_logic: newConditionLogic,
                              });
                            },
                            disabled: disabled,
                            offColor: "#77D1FF",
                            onColor: "#E3ECF1",
                            uncheckedIcon: false,
                            checkedIcon: false,
                            handleDiameter: 22,
                            width: 50,
                            height: 26,
                          }}
                        />
                      </FormGroup>
                    </Col>
                  )}
                </Row>
              </div>
            </div>
          </Col>
          <RuleModal
            condition={condition}
            formFields={formFields}
            isOpen={isOpenRuleModal}
            onToggle={() => setIsOpenRuleModal(!isOpenRuleModal)}
            onSubmit={(newOptionValue) => {
              let options = value.options;
              const index = findIndex(options, (item) => {
                return item.value === newOptionValue.value;
              });
              if (index !== -1) {
                options[index] = newOptionValue;
              }
              onChange({
                ...value,
                options,
              });
              let formFieldsCondition = [];
              forEach(options, (item) => {
                const conditions = get(item, "rule.options", []);
                formFieldsCondition = [...formFieldsCondition, ...conditions];
              });
              onChangeOtherFields(uniqBy(formFieldsCondition, "value"));
              setIsOpenRuleModal(false);
              // set highlight for form parent
              const isExistsHighlight = findIndex(
                highlightSelection,
                (itemHighligh) => itemHighligh.value === newOptionValue.value
              );
              if (isExistsHighlight !== -1) {
                let newHighlightFields = highlightFields;
                if (newHighlightFields.length === 0) {
                  newHighlightFields = map(
                    newOptionValue.rule.options,
                    (itemRuleOption) => ({
                      ...itemRuleOption,
                      count_highlight: 1,
                    })
                  );
                } else {
                  forEach(newOptionValue.rule.options, (itemRuleOption) => {
                    const indexExistsHighlighField = findIndex(
                      newHighlightFields,
                      (newHighlightItem) =>
                        itemRuleOption.value === newHighlightItem.value
                    );
                    if (indexExistsHighlighField === -1) {
                      newHighlightFields = [
                        ...newHighlightFields,
                        {
                          ...itemRuleOption,
                          count_highlight: 1,
                        },
                      ];
                    }
                  });
                  // remove if not selected
                  newHighlightFields = newHighlightFields
                    .map((newHighlightItem) => {
                      const indexExistsHighlighField = findIndex(
                        newOptionValue.rule.options,
                        (itemRuleOption) =>
                          itemRuleOption.value === newHighlightItem.value
                      );
                      if (indexExistsHighlighField === -1) {
                        let newCountHighlight =
                          Number(newHighlightItem.count_highlight) - 1;
                        if (newCountHighlight < 0) newCountHighlight = 0;
                        return {
                          ...newHighlightItem,
                          count_highlight: newCountHighlight,
                        };
                      }
                      return newHighlightItem;
                    })
                    .filter(
                      (newHighlightItem) => newHighlightItem.count_highlight > 0
                    );
                }
                onToggleSetHighlighFormFields(newHighlightFields);
              }
            }}
          />
        </Row>
      );
    } else {
      return (
        <Row>
          <Col>
            <div className={bem.b()}>
              <div className={bem.e("form-field-group-saved")}>
                <Label className={bem.e("form-field-label")}>
                  <span>
                    {get(value, "label", "")}{" "}
                    {get(value, "is_required", false) && <Asterisk>*</Asterisk>}
                  </span>
                </Label>
                {map(value.options, (option, index) => (
                  <FormGroup key={index}>
                    <RadioButtonDefault
                      text={option.label}
                      checked={option.is_default || false}
                      disabled={true}
                      onChange={(checked) => {}}
                    />
                  </FormGroup>
                ))}
                {allowDelete && (
                  <div
                    className={bem.e("form-field-group-saved-button-close")}
                    onClick={onDelete}
                  >
                    <FaClose size="23" color="#C2D4E0" />
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    // ui for form
  } else {
    const fieldName = labelToNameField(get(field, "label", ""));
    if (isView) {
      return (
        <Row>
          <Col>
            <div className={bem.b()}>
              <div className={bem.e("form-field-group-saved")}>
                <Label>{get(field, "label", "")}</Label>
                <span className="order-view-value">
                  {value ? (
                    value
                  ) : (
                    <FormattedMessage id="form builder > none" />
                  )}
                </span>
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    const elementField = (
      <div className={bem.b()}>
        <div
          className={classnames(
            bem.e("form-field-group-saved"),
            "form-builder-edit-field-2"
          )}
        >
          <FormGroup className={bem.e("form-group")}>
            <Label className={bem.e("form-field-label")}>
              <span>
                {get(field, "label", "")}{" "}
                {get(field, "is_required", false) && <Asterisk>*</Asterisk>}
              </span>
            </Label>
            {map(field.options, (option, index) => (
              <FormGroup key={index}>
                <RadioButtonDefault
                  text={option.label}
                  checked={
                    (option.is_default && !value) || value === option.value
                  }
                  disabled={disabled}
                  onChange={() => {
                    onChange({
                      label: option.label,
                      value: option.value,
                      key: option.key,
                    });
                  }}
                />
              </FormGroup>
            ))}
            {!!get(errors, fieldName, "") && get(touched, fieldName, false) && (
              <span className="text-danger">{get(errors, fieldName, "")}</span>
            )}
          </FormGroup>
        </div>
      </div>
    );
    if (isRenderRow)
      return (
        <Row>
          <Col>{elementField}</Col>
        </Row>
      );
    return <Col xs={colNumber}>{elementField}</Col>;
  }
};
Radio.defaultProps = {
  isNew: false,
  isFormBuilder: true,
  errors: {},
  touched: {},
  onChange: () => {},
  onDelete: () => {},
  allowDelete: true,
  disabled: false,
  isAllowConditionLogic: true,
  isMasterListener: false,
  colNumber: 6,
  isRenderRow: true,
  isShowOffText: true,
  isDirty: false,
  setIsOpenConfirmationActionsUnsavedModal: () => {},
  setIsForceSubmitForm: () => {},
  isShowSectionHeadingOfList: false,
};
export default Radio;
