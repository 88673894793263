import * as React from "react";
import { Col, Row } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { get, map, find } from "lodash";
import UploadFile from "components/UploadFile";
import Chat from "components/NotificationsPusher";

const OrderRecordingHistory = (props) => {

	const {
		process,
		template,
		user,
		isView,
		step,
		item,
	} = props;

	let recordingHistory = get(process, "data.fields.recording_history.value", []);
	let iterations = recordingHistory.length;

	const spot = find(
      get(process, "data.fields.spot_info.value", []),
      (spot) => spot.key === process.key
    );

    let isBookend = get(spot, "spot_type", "Standard") === "Book-ends";

	const renderHistory = () => {

		return map(recordingHistory, (recording, index) => {
			return (
		    	<div key={index} className="history-block">
		    		<Row>
						<Col className="history-col">
					      <div className="recording-feedback">
						      <div className="version spot">{isBookend && (<><FormattedMessage id="process > part A" /><span>&nbsp;:&nbsp;</span></>)}<FormattedMessage id="process > spot feedback" /></div>
							    <Chat
					              step={step}
					              process={process}
					              template={template}
					              user={user}
					              defaultExpand={true}
					              onToggleCallback={() => {}}
					              recordingFeedback={true}
					              showEmojiPicker={false}
					              position="left"
					              boxStyle={{width: "100%"}}
					              hideChatInput={true}
					              recordingUrl={get(recording, "recording_path", "")}
					              path={isBookend ? "a" : ""}
			            		/>
			            	</div>
						</Col>
					</Row>
					<Row>
						<Col className="history-col">
				            <div className="final-recording">
				            	<div className="version"><FormattedMessage id="process > field version" />&nbsp;{iterations - index}</div>
						        <UploadFile
						          actions={['download']}
						          positionActions="bottom"
						          mode={isView ? "view" : ""}
						          {...item.props}
						          showRemoveIcon={false}
						          process={process}
						          spot={spot}
						          url={get(recording, "recording_path", "")}
						          auditInfo={get(recording, "recording_audit", "")}
						          className={item.class}
						          isShowFileNameInEditMode={true}
						          isBookend={isBookend}
                  				  path={isBookend ? "a" : ""}
						        >
						          <FormattedMessage id="process > button upload" />
						        </UploadFile>
					      	</div>
						</Col>
					</Row>
					{isBookend && (
						<React.Fragment>
							<Row>
								<Col className="history-col">
							      <div className="recording-feedback">
								      <div className="version spot">{isBookend && (<><FormattedMessage id="process > part B" /><span>&nbsp;:&nbsp;</span></>)}<FormattedMessage id="process > spot feedback" /></div>
									    <Chat
							              step={step}
							              process={process}
							              template={template}
							              user={user}
							              defaultExpand={true}
							              onToggleCallback={() => {}}
							              recordingFeedback={true}
							              showEmojiPicker={false}
							              position="left"
							              boxStyle={{width: "100%"}}
							              hideChatInput={true}
							              recordingUrl={get(recording, "recording_path_b", "")}
							              path={isBookend ? "b" : ""}
					            		/>
					            	</div>
								</Col>
							</Row>
							<Row>
								<Col className="history-col">
						            <div className="final-recording">
						            	<div className="version"><FormattedMessage id="process > field version" />&nbsp;{iterations - index}</div>
								        <UploadFile
								          actions={['download']}
								          positionActions="bottom"
								          mode={isView ? "view" : ""}
								          {...item.props}
								          showRemoveIcon={false}
								          process={process}
								          spot={spot}
								          url={get(recording, "recording_path_b", "")}
								          auditInfo={get(recording, "recording_path_b_audit", "")}
								          className={item.class}
								          isShowFileNameInEditMode={true}
								          isBookend={isBookend}
		                  				  path="b"
								        >
								          <FormattedMessage id="process > button upload" />
								        </UploadFile>
							      	</div>
								</Col>
							</Row>
						</React.Fragment>
					)}
				</div>
		    ); 
		});
	}

	return (
		<div className="recording-history-wrapper">{renderHistory()}</div>
	);
};

export default OrderRecordingHistory;
