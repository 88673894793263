import UploadFiles from "./view";
import { connect } from "react-redux";
import axios from "axios";
import { compose, withHandlers, withState, lifecycle } from "recompose";
import { uploadFiles } from "store/actions/uploads";
import { convertUrlHttps, download } from "../UploadFile/helper";
import { injectIntl } from "react-intl";

const Enhancer = compose(
  withState("isLoading", "setIsLoading", false),
  withState("droppedFiles", "setDroppedFiles", []),
  withState("error", "setError", null),
  connect(() => ({}), {
    uploadFiles,
  }),
  withHandlers({
    onFormSubmit: ({ setIsLoading, uploadFiles, onUploadedFiles }) => async (
      files
    ) => {
      setIsLoading(true);
      const response = await uploadFiles(files);
      if (response) {
        // TODO: Set Files based on received response using `setDroppedFiles`
        if (onUploadedFiles) {
          onUploadedFiles(response);
        }
      }
      setIsLoading(false);
    },
    onDeleteFile: ({ setIsLoading, onDeleted }) => async (allFiles, index) => {
      setIsLoading(true);
      const newFiles = [...allFiles];
      newFiles.splice(index, 1);
      if (onDeleted) {
        onDeleted(newFiles);
      }
      setIsLoading(false);
    },
    onDownloadFile: ({ setError }) => async (path) => {
      axios({
        url: convertUrlHttps(path) + "?ts=" + (new Date() - 0),
        method: "GET",
        responseType: "blob",
      })
        .then((response) => {
          const { data } = response;
          if (data) {
            const fileName = path.replace(/^.*[\\/]/, "");
            download(data, fileName);
          } else {
            setError(`Can't download`);
          }
        })
        .catch((e) => {
          const { message } = e;
          setError(message);
        });
    },
  }),
  withHandlers({
    onDropFiles: ({ droppedFiles, setDroppedFiles, onFormSubmit }) => async (
      upcomingFiles
    ) => {
      const updatedFiles = upcomingFiles.map((file) => {
        const url = URL.createObjectURL(file);
        return Object.assign(file, {
          preview: url,
        });
      });
      // set files locally first
      setDroppedFiles([].concat(droppedFiles, updatedFiles));
      // upload new dropped files to server
      await onFormSubmit(updatedFiles);
    },
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (
        prevProps.files !== this.props.files &&
        JSON.stringify(prevProps.files.length) !==
          JSON.stringify(this.props.files.length)
      ) {
        this.props.setDroppedFiles(this.props.files);
      }
    },
  })
)(UploadFiles);
export default injectIntl(Enhancer);
