import { FormattedMessage } from "react-intl";
import { find, get, map } from "lodash";
import { validURL } from "utils/helpers";
import React from "react";
const traffic = {
  process_step_index: 7,
  key: "traffic",
  title: <FormattedMessage id="process > title digital traffic" />,
  caption_color: "#129AA3",
  description: null,
  step_roles: [12],
  step_viewers_roles: [12],
  step_editors_roles: [12],
  step_viewers_privileges: {
    or: ["perform_digital_traffic", "edit_all_digital_orders"],
  },
  step_editors_privileges: {
    or: ["perform_digital_traffic", "edit_all_digital_orders"],
  },
  is_check_hidden_tab: true,
  status: "initial",
  workflow: {
    title: "traffic",
    icon: "UnionIcon",
  },
  is_disabled: function(user, process, step) {
    const processKey = get(process, "data.fields.key.value", false);
    return !processKey;
  },
  is_approval_step: false,
  className: "traffic-container",
  form_class_name: "traffic-tab-container",
  no_edit_button: true,
  is_open_edit: true,
  fields: [
    {
      component: "traffic",
    },
    {
      field: "marketing_channels",
      title: <FormattedMessage id="process > field marketing channels" />,
      validation: function(step, item, process, template, user, value) {
        const currentChannel = get(process, "loaded_fields.channel_id.value");
        let isValid = true;
        const channels = map(value, (item) => {
          if (currentChannel !== item.id) return;
          let error = {};
          const isRequiredPlacement = item.is_required_placement;
          if (
            isRequiredPlacement &&
            (!item["placement_url_link"] &&
              (!item["placement_files"] ||
              get(item, "placement_files.length", 0) === 0))
          ) {
            isValid = false;
            error.placement =
              "You must enter url link or upload screenshot/file";
          }
          if (
            item["placement_url_link"] &&
            !validURL(item["placement_url_link"])
          ) {
            isValid = false;
            error.placement_url_link = "Invalid URL";
          }
          return error;
        });
        if (isValid) return true;
        return channels;
      },
      mandatory: true,
    },
    {
      field: "campaign_schedule",
      title: <FormattedMessage id="process > field campaign schedule" />,
    },
    {
      text: <FormattedMessage id="process > button done" />,
      key: "save_button",
      fields: [
        {
          key: "traffic_status",
          value: (user, template, process, step, item) => {
            let status = "completed";
            const marketingChannels = get(
              process,
              "data.fields.marketing_channels.value",
              []
            );
            const forMarketingChannel = get(
              process,
              "data.fields.for_marketing_channel.value.id"
            );
            const channel = find(
              marketingChannels,
              (channel) => channel.id === forMarketingChannel
            );
            if (!channel.completed_ts) status = null;
            return status;
          },
        },
        {
          key: "next_users",
          value: (user, template, process, step, item) => {
            const marketingChannels = get(
              process,
              "data.fields.marketing_channels.value",
              []
            );
            const forMarketingChannel = get(
              process,
              "data.fields.for_marketing_channel.value.id"
            );
            const channel = find(
              marketingChannels,
              (channel) => channel.id === forMarketingChannel
            );
            if (channel.channel_step === "traffic") {
              // Assigned back to channel manager when undo
              return [channel.channel_manager];
            }
            // Keep current next users when channel are in design or review
            return get(process, "data.fields.next_users.value", []);
          },
        },
        {
          key: "process_step",
          value: (user, template, process, step, item) => {
            let processStep = "traffic";
            const marketingChannels = get(
              process,
              "data.fields.marketing_channels.value",
              []
            );
            const forMarketingChannel = get(
              process,
              "data.fields.for_marketing_channel.value.id"
            );
            const channel = find(
              marketingChannels,
              (channel) => channel.id === forMarketingChannel
            );
            if (channel.completed_ts) processStep = "online"; // We should me the order to Online stage so that it will appear under Produced Digital.The task should move back to Report stage only when the flight date is expired.
            return processStep;
          },
        },
      ],
      on_click: {
        validate: true,
        save: true,
      },
    },
  ],
};
export default traffic;
