import { connect } from "react-redux";
import {
  compose,
  lifecycle,
  withHandlers,
  withState,
  withPropsOnChange
} from "recompose";
import { withRouter } from "react-router-dom";
import { get } from "lodash";
import AuditList, { MODE_IN_TAB, MODE_IN_SIDEBAR } from "./view";
import {
  getAudits,
  setActiveTab,
  setActiveEditTab,
  resetTab,
  setAuditsAutoRefresh
} from "store/actions/audits";
import { countdown } from "utils/helpers";
import { getTemplate } from "store/actions/template";
import { injectIntl } from "react-intl";
export { MODE_IN_TAB, MODE_IN_SIDEBAR };
const Enhanced = compose(
  connect(
    (state, props) => {
      return {
        user: state.auth.user,
        users: get(state, "auth.info.users", []),
        audits: state.audits.audits,
        autoRefresh: state.audits.autoRefresh,
        auth: state.auth,
        template: state.templates.template.data,
        dateFormatByServer: get(state, "servers.currentServer.data.date_format", "MM/DD/YYYY"),
        ...props
      };
    },
    {
      getAudits,
      setActiveTab,
      setActiveEditTab,
      getTemplate,
      resetTab,
      setAuditsAutoRefresh
    }
  ),
  withState("singleItem", "setSingleItem", null),
  withState("listDays", "setListDays", []),
  withState("countdowns", "setCountdowns", []),
  withHandlers({
    calculateCountdown: () => endDate => countdown(endDate),

    stop: ({ intervalData }) => async () => {
      clearInterval(intervalData);
    }
  }),
  withPropsOnChange(
    ["listDays"],
    ({
      listDays,
      setCountdowns,
      setIntervalData,
      calculateCountdown,
      intervalData,
      stop
    }) => {
      if (intervalData) {
        stop();
      }
      const interval = setInterval(async () => {
        const countdowns = (listDays || []).map(item => {
          if (!item) return {};
          const date = calculateCountdown(item.date);
          if (date) {
            return {
              id: item.id,
              ...date
            };
          }
          return {};
        });
        setCountdowns(countdowns);
      }, 1000);
     // setIntervalData(interval);
    }
  ),
  lifecycle({
    componentWillReceiveProps(nextProps) {
      // const filter = get(this.props, "filter");
      // clear interval on Produced Spots
      this.props.stop();

    },
    componentWillUnmount() {
      this.props.stop();
    }
  })
)(AuditList);
export default withRouter(injectIntl(Enhanced));
