import React from "react";
import { Row, Col, UncontrolledTooltip } from "reactstrap";
import moment from "moment";
import { get, isEmpty, first, forEach, has, includes, sortBy } from "lodash";
import classnames from "classnames";
import bn from "utils/bemnames";
import { getHourLabel, generateShowsSpanMultipleDays } from "utils/helpers";

import Slot from "./Slot";
import DefineShowModal from "./DefineShowModal";
import { FormattedMessage } from "react-intl";
const bem = bn.create("show-clock-calendar");

function getSlotWidth() {
  return window.innerWidth < 1699 ? 140 : 171;
}
const Calendar = (props) => {
  const {
    isShowModalOpen,
    setIsShowModalOpen,
    selectedSlot,
    setSelectedSlot,
    selectedShow,
    setSelectedShow,
    overflowingShows,
    clock,
  } = props;

  const clockId = get(clock, "data.id");
  const shows = sortBy(get(clock, "data.slots", []), ["name", "day"]);

  const renderWeekDays = () => {
    const width = getSlotWidth();
    return [""].concat(moment.weekdays()).map((day) => {
      return (
        <Col
          xs={1}
          key={day}
          className="week-day p-0 m-0"
          style={{ width, flex: "inherit" }}
        >
          {day}
        </Col>
      );
    });
  };

  const renderEmptySlots = () => {
    let totalSlots = [];
    const width = getSlotWidth();

    for (let hour = 0; hour <= 23; hour++) {
      for (let day = 1; day <= 7; day++) {
        totalSlots.push(
          <Slot
            key={`${hour}${day}`}
            cssClass={{ [`slot-${hour}${day}`]: true, "column-1": day === 1 }}
            styleInline={{ width, flex: "inherit" }}
            bem={bem}
            onClick={() => {
              setSelectedSlot({ hour, day });
              setIsShowModalOpen(true);
            }}
          />
        );
      }
    }

    let rows = [];
    let cells = [];

    totalSlots.forEach((row, i) => {
      if (i % 7 !== 0) {
        cells.push(row); // if index not equal 7 that means not go to next hour
      } else {
        if (!isEmpty(cells)) {
          rows.push(cells); // when reach next hour we contain all td in last hour to rows
        }

        cells = []; // empty container
        cells.push(row); // in current loop we still push current row to new container
      }
      if (i === totalSlots.length - 1) {
        // when end loop we add remain slots
        rows.push(cells);
      }
    });

    return rows.map((slot, i) => {
      return (
        <Row
          className={classnames(bem.e("hour"), "p-0 m-0", `hour-${i}`)}
          key={i}
        >
          <Col xs={1} className={classnames(bem.e("time"), "p-0 m-0 ")}>
            {getHourLabel(i)}
          </Col>
          {slot}
        </Row>
      );
    });
  };

  let showsToDisplay = [];

  //render shows
  const renderShows = () => {
    let filteredShows = {};

    forEach(shows, (show) => {
      let startTime = moment().set({
        hours: 0,
        minutes: parseInt(get(show, "start_time")),
        seconds: 0,
        millisecond: 0,
      });
      let endTime = moment().set({
        hours: 0,
        minutes: parseInt(get(show, "end_time")),
        seconds: 0,
        millisecond: 0,
      });

      let showKey = `${startTime.format("HH:mm A")}_${endTime.format(
        "HH:mm A"
      )}_${get(show, "name")}_${get(show, "color")}`;

      //grouping shows based on start - end time
      if (has(filteredShows, showKey)) {
        filteredShows[showKey]["days"].push(parseInt(show.day));
        filteredShows[showKey]["relatedShows"].push(show);
      } else {
        filteredShows[showKey] = {
          ...show,
          days: [parseInt(show.day)],
          relatedShows: [show],
        };
      }
    });

    //splitting shows if the days are not consecutive
    forEach(filteredShows, (show) => {
      splitShow(show);
    });
    const showsToDisplaySpanMultipleDays = generateShowsSpanMultipleDays(
      showsToDisplay
    );

    return showsToDisplaySpanMultipleDays.map((show, index) => {
      const showEndTime = show.end_time === 0 ? 1439 : show.end_time; //11h59 PM
      const showStartTime = show.start_time;
      const isShowDescription = show.isShowDescription;

      let duration = showEndTime - showStartTime;
      let height = 0.95 * duration - 2;
      let top = 0.95 * showStartTime;

      let width = getSlotWidth();
      let showWidth = width * show.days.length - 2;
      let minLeft = 75;

      let left = first(show.days.sort()) - 1;
      let leftOffset = width * left + minLeft;
      let style = {
        height: `${height}px`,
        maxHeight: `${height}px`,
        overflowY: "hidden",
        width: `${showWidth}px`,
        background: show.color,
        transform: `translate(${leftOffset}px, ${top}px)`,
      };

      let startTime = moment().set({
        hours: 0,
        minutes: parseInt(get(show, "original.start_time")),
        seconds: 0,
        millisecond: 0,
      });
      let endTime = moment().set({
        hours: 0,
        minutes: parseInt(get(show, "original.end_time")),
        seconds: 0,
        millisecond: 0,
      });

      let format = "h:mm A";
      let startTimeFormatted = startTime.format(format);
      let endTimeFormatted = endTime.format(format);
      return (
        <div
          className={classnames(bem.e("show"), `show-${show.id}`)}
          style={style}
          key={index}
          id={`show-card-${index}`}
          onClick={() => {
            setSelectedShow(show.original);
            setIsShowModalOpen(true);
          }}
        >
          {duration >= 30 && isShowDescription && (
            <div className="description">
              <div
                className={classnames("name", {
                  "truncate-one-line": duration <= 30,
                })}
              >
                {show.name}
              </div>
              {duration >= 30 && (
                <div className="time">{`${startTimeFormatted} - ${endTimeFormatted}`}</div>
              )}
            </div>
          )}
          {(duration <= 30 || includes(overflowingShows, show.id)) && (
            <UncontrolledTooltip
              placement="top"
              delay={0}
              target={`show-card-${index}`}
              className={bem.e("show-tooltip-popper")}
              innerClassName={bem.e("show-tooltip")}
            >
              <span>{`${show.name} - ${startTimeFormatted} - ${endTimeFormatted}`}</span>
            </UncontrolledTooltip>
          )}
          <div
            className={classnames(
              bem.e("show-type-label"),
              bem.e("show-top-left-small")
            )}
          >
            {get(show, "show_type", "live") === "live" ? (
              <FormattedMessage id="clock creator > live" />
            ) : (
              <FormattedMessage id="clock creator > voice track" />
            )}
          </div>
        </div>
      );
    });
  };

  //splitting shows
  const splitShow = (show) => {
    let showDays = show.days;

    for (let i = 0; i <= showDays.length; i++) {
      if (
        i + 1 <= showDays.length &&
        parseInt(showDays[i]) + 1 !== parseInt(showDays[i + 1])
      ) {
        let newShowDays = showDays.slice(0, i + 1);

        showsToDisplay.push({ ...show, days: newShowDays });

        splitShow({
          ...show,
          days: showDays.slice(i + 1, showDays.length + 1),
        });
        break;
      }
    }
  };

  return (
    <React.Fragment>
      <div className={bem.e("calendar")}>
        <Row className="p-0 m-0">{renderWeekDays()}</Row>
        {renderShows()}
        {renderEmptySlots()}
      </div>
      {isShowModalOpen && (
        <DefineShowModal
          clockId={clockId}
          isOpen={isShowModalOpen}
          selectedSlot={selectedSlot}
          selectedShow={selectedShow}
          allShows={shows}
          toggle={() => {
            setIsShowModalOpen(!isShowModalOpen);
            setSelectedSlot(null);
            setSelectedShow(null);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default Calendar;
