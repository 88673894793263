import React from "react";
import { connect } from "react-redux";
import { compose, lifecycle, withState, withHandlers } from "recompose";
import { withRouter } from "react-router-dom";
import { get, find, omit } from "lodash";
import WinnersList from "./view";
import { injectIntl } from "react-intl";
import {
  getReportWinners,
  saveSearch,
  export1099ReportsWinners,
  getSavedSearches,
  deleteSavedSearch,
  getAllSavedSearches,
} from "store/actions/winners";
import { getClocksData } from "store/actions/programmings";
import { pageSize } from "utils/config";
import { download } from "components/UploadFile/helper";
import { MdImportantDevices } from "react-icons/lib/md";
import ToastManager from "components/ToastManager";
const Enhanced = compose(
  connect(
    (state, props) => {
      return {
        isLoading:
          get(state, "winners.reportWinners.loading", false) ||
          get(state, "winners.exportWinners.loading", false) ||
          get(state, "winners.saveSearch.loading", false),
        winners: get(state, "winners.reportWinners", {}),
        savedSearches: get(state, "winners.savedSearches.data", {}),
        allSavedSearches: get(state, "winners.allSavedSearches.data", {}),
        user: state.auth.user,
        clocksData: get(state, "programmings.clocksData.data", {}),
        info: get(state, "auth.info", {}),
        promotionMultiMarket: get(
          state,
          "auth.info.promotion_multimarket",
          false
        ),
        ...props,
      };
    },
    {
      getClocksData,
      getWinners: getReportWinners,
      saveSearch,
      export1099ReportsWinners,
      getSavedSearches,
      deleteSavedSearch,
      getAllSavedSearches,
    }
  ),
  withState("isAudiencePickerModalOpen", "setIsAudiencePickerModalOpen", false),
  withState("isSubmittedAdvancedSearch", "setIsSubmittedAdvancedSearch", false),
  withState("advancedSearch", "setAdvancedSearch", {
    prize_types: ["cash", "non_cash"],
    keyword: "",
    w9_status: ["signed", "unsigned"],
    min_value: "",
    start_date: "",
    end_date: "",
    prize_date_range: "distributed",
  }),
  withState("selectedItem", "setSelectedItem", null),
  withState("selectedItems", "setSelectedItems", {}),
  withState("isShowExportSuccessModal", "setIsShowExportSuccessModal", false),
  withState("isShowSaveSearchModal", "setIsShowSaveSearchModal", false),
  withState("isShowExportModal", "setIsShowExportModal", false),
  withState("isShowAdvancedSearchModal", "setIsShowAdvancedSearchModal", false),
  withState("filtered", "setFiltered", [
    {
      key: "keyword",
      value: "",
    },
    {
      key: "stations",
      value: [],
    },
    {
      key: "year",
      value: "",
    },
  ]),
  withState("sorted", "setSorted", [{ id: "created_ts", desc: true }]),
  withState("page", "setPage", 1),
  withState("isShowSaveSearch", "setIsShowSaveSearch", false),
  withHandlers({
    onGetClocksData: ({ getClocksData }) => (stationKey) => {
      getClocksData({
        data: {
          station_id: stationKey,
        },
      });
    },
    getAllSavedSearchesList: ({ getAllSavedSearches }) => async (
      params = {}
    ) => {
      getAllSavedSearches({
        pageSize: 999,
        type: "1099_report",
        ...params, //override
      });
    },
    getSavedSearchList: ({ getSavedSearches, page }) => async (params = {}) => {
      getSavedSearches({
        pageSize: 4,
        page,
        type: "1099_report",
        ...params, //override
      });
    },
    getMoreSavedSearchList: ({ getSavedSearches }) => (page) => {
      getSavedSearches({
        pageSize: 4,
        page: Number(page) + 1,
        type: "1099_report",
      });
    },
    getWinnersList: ({ getWinners, sorted, filtered, page }) => async (
      params = {},
      cb
    ) => {
      getWinners(
        {
          pageSize,
          page,
          sorted,
          filtered,
          ...params, //override
        },
        cb
      );
    },
    getMoreWinners: ({ getWinners, sorted, filtered, setPage }) => (page) => {
      const newPage = Number(page) + 1;
      getWinners({
        pageSize,
        page: newPage,
        sorted,
        filtered,
      });
      setPage(newPage);
    },
    onChangeFiltered: ({ setFiltered, filtered }) => (key, newValue) => {
      const newFiltered = filtered.map((item) => {
        if (item.key === key) {
          return {
            ...item,
            value: newValue,
          };
        }
        return item;
      });
      setFiltered(newFiltered);
      return newFiltered;
    },
  }),
  withHandlers({
    onSelectedSavedSearch: ({
      filtered,
      setFiltered,
      setAdvancedSearch,
      getWinnersList,
      sorted,
      setIsShowSaveSearch,
      setIsSubmittedAdvancedSearch,
      onChangeFiltered,
    }) => (item) => {
      const searchData = get(item, "search", {});
      const newFiltered = filtered.map((item) => {
        if (item.key === "stations") {
          return {
            ...item,
            value: searchData.stations || [],
          };
        }
        if (item.key === "keyword") {
          return {
            ...item,
            value: searchData.isAdvancedSearch ? "" : searchData.keyword,
          };
        }
        if (item.key === "year") {
          return {
            ...item,
            value: searchData.year,
          };
        }
        return item;
      });
      let winnersParams = {
        page: 1,
        sorted,
        filtered: newFiltered,
      };
      let isMultipleCriteria = false;
      if (searchData.isAdvancedSearch) {
        let advancedSearchData = {
          prize_types: searchData.prize_types || ["cash", "non_cash"],
          keyword: searchData.keyword || "",
          w9_status: searchData.w9_status || ["signed", "unsigned"],
          min_value: searchData.min_value || "",
          start_date: searchData.start_date || "",
          end_date: searchData.end_date || "",
          prize_date_range: get(searchData, "prize_date_range", "distributed"),
        };
        let advancedSearchParams = omit(advancedSearchData, [
          "start_date",
          "end_date",
          "prize_date_range",
        ]);
        if (searchData.prize_date_range === "distributed") {
          advancedSearchParams = {
            ...advancedSearchParams,
            prize_dist_start_date: searchData.start_date || "",
            prize_dist_end_date: searchData.end_date || "",
          };
        } else {
          advancedSearchParams = {
            ...advancedSearchParams,
            prize_won_start_date: searchData.start_date || "",
            prize_won_end_date: searchData.end_date || "",
          };
        }
        isMultipleCriteria =
          get(advancedSearchData, "prize_types.length", 0) !== 2 ||
          get(advancedSearchData, "w9_status.length", 0) !== 2 ||
          advancedSearchData.min_value ||
          advancedSearchData.start_date ||
          advancedSearchData.end_date;
        setAdvancedSearch(advancedSearchData);
        winnersParams = {
          ...winnersParams,
          advance_search: advancedSearchParams,
        };
      }
      setFiltered(newFiltered);
      getWinnersList(winnersParams, () => {
        // set text in the searchbar
        onChangeFiltered(
          "keyword",
          isMultipleCriteria ? "Multiple Criteria" : searchData.keyword
        );
      });
      setIsSubmittedAdvancedSearch(searchData.isAdvancedSearch);
      setIsShowSaveSearch(false);
    },
    onDeleteSavedSearch: ({
      getSavedSearchList,
      getAllSavedSearchesList,
      deleteSavedSearch,
      intl,
    }) => (item) => {
      deleteSavedSearch(item.id, () => {
        getSavedSearchList();
        getAllSavedSearchesList();
        ToastManager.show({
          title: <MdImportantDevices />,
          message: intl.formatMessage({
            id: "winners > deleted successfully",
          }),
          level: "success",
        });
      });
    },
    onExportList: ({
      setIsShowExportModal,
      filtered,
      export1099ReportsWinners,
      setIsShowExportSuccessModal,
      selectedItems,
      sorted,
      page,
      isSubmittedAdvancedSearch,
      advancedSearch,
    }) => (values) => {
      setIsShowExportModal(false);
      const filteredYear = find(filtered, (i) => i.key === "year");
      let params = {
        year: get(filteredYear, "value"),
        view: values.type,
        selected_fields: values.fields,
        selected_items: Object.keys(selectedItems).filter((id) =>
          get(selectedItems, id, false)
        ),
        sorted,
        filtered,
        page,
      };
      if (isSubmittedAdvancedSearch) {
        const newFiltered = filtered.map((item) => {
          if (item.key === "keyword") {
            return {
              ...item,
              value: "", // advancedSearch.keyword
            };
          }
          return item;
        });
        params = {
          ...params,
          filtered: newFiltered,
          advance_search: advancedSearch,
        };
      }
      export1099ReportsWinners(params, (data) => {
        download(data, "1099_report.xlsx");
        setIsShowExportSuccessModal(true);
      });
    },
    onSaveSearch: ({
      setIsShowSaveSearchModal,
      advancedSearch,
      saveSearch,
      intl,
      getSavedSearchList,
      setIsShowSaveSearch,
      getAllSavedSearchesList,
      filtered,
      isSubmittedAdvancedSearch,
    }) => (values) => {
      setIsShowSaveSearchModal(false);
      const filteredStationKeys = find(filtered, (i) => i.key === "stations");

      const filteredYear = find(filtered, (i) => i.key === "year");

      const searchText = find(filtered, (i) => i.key === "keyword");
      let saveSearchParams = {
        stations: get(filteredStationKeys, "value", []),
        keyword: get(searchText, "value", ""),
        year: get(filteredYear, "value", ""),
      };
      if (isSubmittedAdvancedSearch) {
        saveSearchParams = {
          ...saveSearchParams,
          ...advancedSearch,
          isAdvancedSearch: true,
        };
      }
      saveSearch(
        {
          ...values,
          type: "1099_report",
          search: saveSearchParams,
        },
        () => {
          setIsShowSaveSearch(false);
          getSavedSearchList();
          getAllSavedSearchesList();

          ToastManager.show({
            title: <MdImportantDevices />,
            message: intl.formatMessage({
              id: "winners > saved successfully",
            }),
            level: "success",
          });
        }
      );
    },
    onSort: ({
      setSorted,
      sorted,
      getWinnersList,
      setIsSubmittedAdvancedSearch,
    }) => (sort) => {
      let newSort = {};
      const sortedData = find(sorted, (item) => item.id === sort);
      if (sortedData) {
        newSort = {
          id: sort,
          desc: !sortedData.desc,
        };
      } else {
        newSort = {
          id: sort,
          desc: true,
        };
      }
      setSorted([newSort]);
      getWinnersList({ sorted: [newSort], page: 1 });
      setIsSubmittedAdvancedSearch(false);
    },
    onSearch: ({
      filtered,
      getWinnersList,
      setIsSubmittedAdvancedSearch,
      setSelectedItems,
    }) => () => {
      getWinnersList({
        filtered,
        page: 1,
      });
      setIsSubmittedAdvancedSearch(false);
      setSelectedItems({});
    },
    onAdvancedSearch: ({
      setIsShowAdvancedSearchModal,
      getWinnersList,
      setAdvancedSearch,
      setIsShowSaveSearch,
      filtered,
      setFiltered,
      setIsSubmittedAdvancedSearch,
      onChangeFiltered,
      setSelectedItems,
    }) => (values) => {
      setIsShowAdvancedSearchModal(false);
      setIsShowSaveSearch(true);
      setAdvancedSearch(values);
      setIsSubmittedAdvancedSearch(true);
      const isMultipleCriteria =
        get(values, "prize_types.length", 0) !== 2 ||
        get(values, "w9_status.length", 0) !== 2 ||
        values.min_value ||
        values.start_date ||
        values.end_date;
      const newFiltered = filtered.map((item) => {
        if (item.key === "keyword") {
          return {
            ...item,
            value: isMultipleCriteria ? "" : values.keyword,
          };
        }
        return item;
      });
      setFiltered(newFiltered);
      let paramsAdvanceSearch = omit(values, ["start_date", "end_date"]);
      if (values.prize_date_range === "distributed") {
        paramsAdvanceSearch = {
          ...paramsAdvanceSearch,
          prize_dist_start_date: values.start_date || "",
          prize_dist_end_date: values.end_date || "",
        };
      } else {
        paramsAdvanceSearch = {
          ...paramsAdvanceSearch,
          prize_won_start_date: values.start_date || "",
          prize_won_end_date: values.end_date || "",
        };
      }
      getWinnersList(
        {
          filtered: newFiltered,
          advance_search: paramsAdvanceSearch,
          page: 1,
        },
        () => {
          // set text in the searchbar
          if (isMultipleCriteria) {
            onChangeFiltered("keyword", "Multiple Criteria");
          }
          setSelectedItems({});
        }
      );
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.getAllSavedSearchesList();
    },
    componentDidUpdate() {
      if (get(this.props, "winners.is_dirty")) {
        this.props.getWinnersList();
        this.props.setIsSubmittedAdvancedSearch(false);
      }
    },
  })
)(WinnersList);
export default withRouter(injectIntl(Enhanced));
