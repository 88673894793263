import React from "react";
import bn from "utils/bemnames";
import { MenuIcon } from "components/CustomIcons";
import { FaClose } from "react-icons/lib/fa";
import { FormattedMessage } from "react-intl";
import { Row, Col, Label } from "reactstrap";
import TextInput from "components/TextInput";
import { get } from "lodash";
import classnames from "classnames";
const bem = bn.create("form-builder-fields");

const HelperText = ({
  intl,
  isNew,
  field,
  value,
  errors,
  onChange,
  onDelete,
  allowDelete,
  isFormBuilder,
  isView,
  disabled,
  colNumber,
  isRenderRow,
  isTextarea,
}) => {
  // ui for form builder
  if (isFormBuilder) {
    if (isNew) {
      return (
        <Row>
          <Col className="form-builder-field">
            <div className={bem.b()}>
              <div
                className={classnames(
                  bem.e("form-field-group"),
                  bem.e("form-field-group-helper-text")
                )}
              >
                <div className={bem.e("form-field-group-head")}>
                  <div className={bem.e("form-field-group-head-single-left")}>
                    <span className={bem.e("menu-icon")}>
                      <MenuIcon />
                    </span>
                    <Row>
                      <Col xs={12}>
                        <TextInput
                          label={null}
                          name="label"
                          type={isTextarea ? "textarea" : "text"}
                          placeholder={intl.formatMessage({
                            id: "form builder > label helper text",
                          })}
                          disabled={disabled}
                          value={value.value}
                          onChange={({ target }) => {
                            onChange({
                              ...value,
                              value: target.value,
                            });
                          }}
                          error={get(errors, "value", "")}
                        />
                      </Col>
                    </Row>
                  </div>
                  {allowDelete && (
                    <div
                      className={bem.e("form-field-group-head-right")}
                      onClick={onDelete}
                    >
                      <FaClose size="23" color="#C2D4E0" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    // ui for form
  } else {
    if (isView) {
      return (
        <Row>
          <Col>
            <div className={bem.b()}>
              <div className={bem.e("form-field-group-saved")}>
                <Label>{get(field, "label", "")}</Label>
                <span className="order-view-value">
                  {value ? (
                    value
                  ) : (
                    <FormattedMessage id="form builder > none" />
                  )}
                </span>
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    const elementField = (
      <div className={bem.b()}>
        <div
          className={classnames(
            bem.e("form-field-group-saved"),
            "form-builder-edit-field-2"
          )}
        >
          <div className={bem.e("form-field-helper-text-saved")}>
            {field.value}
          </div>
        </div>
      </div>
    );
    if (isRenderRow)
      return (
        <Row>
          <Col>{elementField}</Col>
        </Row>
      );
    return <Col xs={colNumber}>{elementField}</Col>;
  }
};
HelperText.defaultProps = {
  isNew: false,
  errors: {},
  touched: {},
  onChange: () => {},
  onDelete: () => {},
  allowDelete: true,
  isFormBuilder: true,
  disabled: false,
  isMasterListener: false,
  isRenderRow: true,
  colNumber: 12,
  isTextarea: true,
  isShowOffText: true,
};
export default HelperText;
