import * as React from "react";
import PropsType from "prop-types";
import styled from "styled-components";
import ReactPlayer from "react-player";
import PlayerUI from "components/PlayerUI";
import { find, get } from "lodash";

const Container = styled.div`
  margin-bottom: 10px;
`;
const WrapperPlayer = styled.div`
  margin-bottom: 10px;
`;
class Player extends React.Component {
  onSeekMouseUp = (value) => {
    this.player.seekTo(parseFloat(value));
    setTimeout(() => {
      this.props.setPlaying(true);
    }, 5);
  };
  render() {
    const {
      url,
      playing,
      setPlaying,
      setPlayedSeconds,
      setDuration,
      volume,
      setVolume,
      playedSeconds,
      duration,
      width,
      height,
      isBubble,
      recordingUrl,
      process,
      path,
    } = this.props;
    return (
      <Container className="player-container">
        <WrapperPlayer className="player-wrapper">
          <ReactPlayer
            ref={(ref) => {
              this.player = ref;
              // window.player = ref;
              const currentPlayers = window.players || [];
              const isExists = find(
                currentPlayers,
                (item) => get(item, "props.url") === get(ref, "props.url")
              );
              if (!isExists && ref) {
                window.players = [...currentPlayers, ref];
              }
            }}
            className="player-hidden"
            url={url + "?ts=" + new Date().getDay()}
            playing={playing}
            controls={true}
            volume={volume}
            height={height}
            width={width}
            config={{
              file: {
                attributes: {
                  controlsList: "nodownload",
                },
              },
            }}
            onProgress={({ playedSeconds }) => {
              if (path === "b") {
                window.playerBPlayedSeconds = playedSeconds;
              } else {
                window.playerPlayedSeconds = playedSeconds;
              }

              setPlayedSeconds(playedSeconds);
            }}
            onDuration={(duration) => {
              setDuration(parseInt(duration));
            }}
            onEnded={() => {
              setPlaying(false);
            }}
          />
          <PlayerUI
            playedSeconds={playedSeconds}
            duration={duration}
            width={width}
            height={height}
            volume={volume}
            setVolume={setVolume}
            playing={playing}
            onClickPlaying={setPlaying}
            setPlayedSeconds={setPlayedSeconds}
            onSeekMouseUp={this.onSeekMouseUp}
            isBubble={isBubble}
            recordingUrl={recordingUrl}
            process={process}
          />
        </WrapperPlayer>
      </Container>
    );
  }
}

Player.propsType = {
  url: PropsType.string.isRequired,
  width: PropsType.any,
  height: PropsType.any,
  isBubble: PropsType.bool,
};
Player.defaultProps = {
  isBubble: false,
};
export default Player;
