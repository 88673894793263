import React from "react";
import {
  Editor,
  convertFromHTML,
  ContentState,
  EditorState,
  RichUtils,
} from "draft-js";
import { toLower } from "lodash";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import {
  InlineStyleControls,
  styleMap,
  getBlockStyle,
  decorator,
  importHTMLOptions,
  exportHTMLOptions,
} from "./config";
import { customStyleFn, exporter } from "./index";
import { FormattedMessage } from "react-intl";
import TagsModal from "./TagsModal";
import InsertButtonModal from "./InsertButtonModal";

class RichEditor extends React.Component {
  editorRef = React.createRef();
  tagsRef = React.createRef();
  componentDidMount() {
    if (this.props.getRef) {
      this.props.getRef(this);
    }
  }
  // insert html where cursor is
  insertBlocksFromHtml(htmlString) {
    const editorState = this.props.editorState;
    const newBlockMap = convertFromHTML(htmlString);
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const key = selectionState.getAnchorKey();
    const blocksAfter = contentState
      .getBlockMap()
      .skipUntil(function(_, k) {
        return k === key;
      })
      .skip(1)
      .toArray();
    const blocksBefore = contentState
      .getBlockMap()
      .takeUntil(function(_, k) {
        return k === key;
      })
      .toArray();

    newBlockMap.contentBlocks = blocksBefore
      .concat([contentState.getBlockForKey(key)])
      .concat(newBlockMap.contentBlocks)
      .concat(blocksAfter);

    const newContentState = ContentState.createFromBlockArray(
      newBlockMap,
      newBlockMap.entityMap
    );
    const newEditorState = EditorState.createWithContent(newContentState);
    this.props.setEditorState(newEditorState);
  }

  insertBlockFromHtml(htmlString) {
    let newStateFromHTML = stateFromHTML(htmlString, importHTMLOptions);
    const newBlockArray = newStateFromHTML.getBlocksAsArray();
    const newBlock = newBlockArray[0];
    const newBlockCharacters = newBlock.get("characterList");
    const newBlockText = newBlock.get("text");

    const editorState = this.props.editorState;
    const contentState = editorState.getCurrentContent();
    let selectionState = editorState.getSelection();
    const positionToInsert = selectionState.getFocusOffset();

    const key = selectionState.getAnchorKey();
    let focusedBlock = contentState.getBlockForKey(key);
    let focusedBlockCharacters = focusedBlock.get("characterList");
    let focusedText = focusedBlock.get("text");
    const startArr = focusedBlockCharacters.slice(0, positionToInsert);
    const endArr = focusedBlockCharacters.slice(positionToInsert);
    focusedBlock = focusedBlock.set(
      "characterList",
      startArr.concat(newBlockCharacters, endArr)
    );
    const startStr = focusedText.substr(0, positionToInsert);
    const endStr = focusedText.substr(positionToInsert);
    focusedBlock = focusedBlock.set("text", startStr + newBlockText + endStr);

    const blocksAfter = contentState
      .getBlockMap()
      .skipUntil(function(_, k) {
        return k === key;
      })
      .skip(1)
      .toArray();
    const blocksBefore = contentState
      .getBlockMap()
      .takeUntil(function(_, k) {
        return k === key;
      })
      .toArray();

    let blocks = blocksBefore.concat([focusedBlock]).concat(blocksAfter);

    const newContentState = ContentState.createFromBlockArray(
      blocks,
      newStateFromHTML.getEntityMap()
    );
    const newEditorState = EditorState.createWithContent(
      newContentState,
      decorator({
        setCurrentInsertButton: this.props.setCurrentInsertButton,
        setIsEditorReadonly: this.props.setIsEditorReadonly,
        setCurrentEntityKey: this.props.setCurrentEntityKey,
        onAddOrEditLink: this.props.onAddOrEditLink,
      })
    );
    selectionState = selectionState.set(
      "focusOffset",
      positionToInsert + newBlockText.length
    );
    selectionState = selectionState.set(
      "anchorOffset",
      positionToInsert + newBlockText.length
    );
    this.props.setEditorState(
      EditorState.forceSelection(newEditorState, selectionState)
    );
  }

  addTag = (tag, replaceWith) => {
    let htmlString = `&nbsp;<span class="tag" data-tag-label="${tag}" data-tag-replace-with="${replaceWith}">${tag}</span>&nbsp;`;
    this.insertBlockFromHtml(htmlString);
  };

  addImages = (files) => {
    const htmlArray = files.map((file) => {
      return `&nbsp;<span class="image" data-image-name="${file.name}" data-image-type="${file.type}" data-image-path="${file.path}">[img]${file.name}[/img]</span>&nbsp;`;
    });
    let htmlString = htmlArray.join("");
    this.insertBlockFromHtml(htmlString);
  };

  removeLink = () => {
    const editorState = this.props.editorState;
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      const nextEditorState = RichUtils.toggleLink(
        editorState,
        selection,
        null
      );
      this.props.setEditorState(nextEditorState);
      this.props.onChange(nextEditorState);
      this.props.setCurrentEntityKey(null);
    }
  };

  addOrUpdateButton = (values) => {
    const newColor = toLower(values.color);
    let htmlString = `<a class="button" href="${values.url}" data-text="${
      values.text
    }" data-color="${values.color}" style="background-color: ${
      values.color
    }; display: inline-block; border-radius: 24px; padding: 13px 30px; font-size: 14px; color: ${
      newColor === "#ffffff" ? "#795afa" : "#fff"
    }; text-transform: uppercase; cursor: pointer; text-decoration: none; border: ${
      newColor === "#ffffff" ? "1px solid #acc4d4" : "none"
    }" data-link-to-form="${
      values.link_to_form
    }" data-id="${new Date().getTime()}">${values.text}</a>`;
    // edit the button
    if (values.id) {
      const editorState = this.props.editorState;
      const inlineStyles = exporter(editorState);
      const html = stateToHTML(editorState.getCurrentContent(), {
        ...inlineStyles,
        ...exportHTMLOptions,
      });
      // replace by html
      const pNode = document.createElement("p");
      pNode.innerHTML = html;
      const buttonNodes = pNode.querySelectorAll(`.button`);
      if (buttonNodes) {
        buttonNodes.forEach((node) => {
          // find the button by id
          const dataId = node.getAttribute("data-id");
          if (parseInt(dataId) === parseInt(values.id)) {
            node.outerHTML = htmlString;
          }
        });
      }
      let newStateFromHTML = stateFromHTML(pNode.innerHTML, importHTMLOptions);
      let editorStateUpdated = EditorState.createWithContent(
        newStateFromHTML,
        decorator({
          setCurrentInsertButton: this.props.setCurrentInsertButton,
          setIsEditorReadonly: this.props.setIsEditorReadonly,
          setCurrentEntityKey: this.props.setCurrentEntityKey,
          onAddOrEditLink: this.props.onAddOrEditLink,
        })
      );
      this.props.setEditorState(editorStateUpdated);
      this.props.onChangeEditor(pNode.innerHTML, editorStateUpdated);
    } else {
      // add the button
      this.insertBlockFromHtml(`&nbsp;${htmlString}&nbsp;`);
    }
  };

  render() {
    const props = this.props;
    let {
      editorState,
      toggleInlineStyle,
      handleKeyCommand,
      onTab,
      onChange,
      setCurrentInsertButton,
      currentInsertButton,
      spellCheck,
    } = props;
    let className = props.readOnly ? "" : "RichEditor-editor";
    var contentState = editorState.getCurrentContent();
    let isEmptyText = false;
    if (!contentState.hasText()) {
      isEmptyText = true;
      className += " RichEditor-emptyText";
      if (
        contentState
          .getBlockMap()
          .first()
          .getType() !== "unstyled"
      ) {
        className += " RichEditor-hidePlaceholder";
      }
    }
    return (
      <div className={props.readOnly ? "RichEditor-none" : "RichEditor-root"}>
        {!props.readOnly && (
          <div className="RichEditor-toolbar">
            <InlineStyleControls
              editorState={editorState}
              onToggle={toggleInlineStyle}
              tags={props.tags}
              tagsIsModal={props.tagsIsModal}
              addTag={this.addTag}
              refTagModal={this.tagsRef}
              onSetAsDefault={props.onSetAsDefault}
              isShowUpload={props.isShowUpload}
              addImages={this.addImages}
              isShowHyperlink={props.isShowHyperlink}
              addLink={this.props.onAddOrEditLink}
              removeLink={this.removeLink}
              isShowInsertButton={props.isShowInsertButton}
              setCurrentEntityKey={props.setCurrentEntityKey}
              addButton={() => {
                setCurrentInsertButton({
                  id: "",
                  link_to_form: "",
                  url: "",
                  text: "",
                  color: "",
                });
              }}
            />
          </div>
        )}
        {props.readOnly && isEmptyText ? (
          <FormattedMessage id="process > none" />
        ) : (
          <div className={className}>
            <Editor
              blockStyleFn={getBlockStyle}
              customStyleFn={customStyleFn}
              customStyleMap={styleMap}
              editorState={editorState}
              handleKeyCommand={handleKeyCommand}
              onChange={onChange}
              onTab={onTab}
              placeholder=""
              spellCheck={spellCheck}
              ref={(ref) => (this.editorRef = ref)}
              readOnly={props.isEditorReadonly}
              {...props}
            />

            <TagsModal
              ref={this.tagsRef}
              addTag={this.addTag}
              tagsInModal={props.tagsInModal}
            />
            <InsertButtonModal
              isOpen={!!currentInsertButton}
              onToggle={() => {
                setCurrentInsertButton(null);
              }}
              currentInsertButton={currentInsertButton}
              onSubmit={(values) => {
                this.addOrUpdateButton(values);
                setCurrentInsertButton(null);
              }}
            />
          </div>
        )}
      </div>
    );
  }
}
RichEditor.defaultProps = {
  editorReadonly: false,
  isShowUpload: false,
  isShowHyperlink: false,
  isShowInsertButton: false,
  spellCheck: true,
};
export default RichEditor;
