import { connect } from "react-redux";
import { compose, withHandlers, withState, lifecycle } from "recompose";
import { get, find } from "lodash";
import {
  getTeamManagement,
  getAssignedHubs,
  getAssignedOrdersByTeam,
  getCurrentOrdersByTeam,
} from "store/actions/hubs";
import { injectIntl } from "react-intl";
import TeamManagement from "./view";
import { pageSize } from "utils/config";
import { getEnterpriseSettings } from "store/actions/settings";
import { getRolesProductionTeam } from "store/actions/roles";

const Enhanced = compose(
  connect(
    (state, props) => {
      return {
        auth: get(state, "auth"),
        isLoading:
          get(state, "hubs.teams.loading", false) ||
          get(state, "processes.assignTeamProcess.loading", true),
        isDirty: get(state, "hubs.teams.is_dirty", false),
        assignedHubs: get(state, "hubs.assignedHubs.data.data", []),
        teams: get(state, "hubs.teams.data", []),
        enterpriseSettings: get(state, "settings.enterprise_settings.data", {}),
        dateFormatByServer: get(
          state,
          "servers.currentServer.data.date_format",
          "MM/DD/YYYY"
        ),
        roles: get(state, "roles.rolesProductionTeam.data", []),
        ...props,
      };
    },
    {
      getTeamManagement,
      getAssignedHubs,
      getEnterpriseSettings,
      getAssignedOrdersByTeam,
      getCurrentOrdersByTeam,
      getRolesProductionTeam,
    }
  ),
  withState("sidebarType", "setSidebarType", ""),
  withState("isOpenSidebar", "setIsOpenSidebar", false),
  withState("searchText", "setSearchText", ""),
  withState("sorted", "setSorted", [{ id: "name", desc: false }]),
  withState("selectedTeam", "setSelectedTeam", null),
  withState("selectedHubs", "setSelectedHubs", []),
  withState("selectedRoles", "setSelectedRoles", []),
  withState("filtered", "setFiltered", []),
  withState("expanded", "setExpanded", {}),
  withState("selectedProcess", "setSelectedProcess", null),
  withHandlers({
    onGetTeamManagement: ({ getTeamManagement, sorted, filtered }) => async (
      params = {}
    ) => {
      getTeamManagement({
        pageSize,
        page: 1,
        sorted,
        filtered,
        ...params, //override
      });
    },
    onGetTeamManagementMore: ({ getTeamManagement, sorted, filtered }) => (
      page
    ) => {
      getTeamManagement({
        pageSize,
        page: page + 1,
        sorted,
        filtered,
      });
    },
  }),
  withHandlers({
    onSelectProcess: ({
      setSelectedProcess,
      setIsOpenSidebar,
      setSidebarType,
    }) => (process) => {
      setSelectedProcess(process);
      setIsOpenSidebar(true);
      setSidebarType("assign");
    },
    onSort: ({ setSorted, sorted, onGetTeamManagement }) => (sort) => {
      let newSort = {};
      const sortedData = find(sorted, (item) => item.id === sort);
      if (sortedData) {
        newSort = {
          id: sort,
          desc: !sortedData.desc,
        };
      } else {
        newSort = {
          id: sort,
          desc: true,
        };
      }
      setSorted([newSort]);
      onGetTeamManagement({ sorted: [newSort] });
    },
    onSearch: ({ setFiltered, searchText, onGetTeamManagement }) => () => {
      const filtered = [
        {
          id: "q",
          value: searchText,
        },
      ];
      setFiltered(filtered);
      onGetTeamManagement({
        filtered,
      });
    },
    onSubmitFilter: ({
      setIsOpenSidebar,
      setSelectedHubs,
      setSelectedRoles,
      onGetTeamManagement,
    }) => (values = {}) => {
      const newRoles = values && values.role ? [Number(values.role)] : [];
      const newHubs = values && values.hub ? [Number(values.hub)] : [];
      setSelectedHubs(newHubs);
      setSelectedRoles(newRoles);
      onGetTeamManagement({
        roles: newRoles,
        hubs: newHubs,
      });
      setIsOpenSidebar(false);
    },
    onAssignedSuccess: ({
      selectedTeam,
      getCurrentOrdersByTeam,
      getAssignedOrdersByTeam,
      onGetTeamManagement,
    }) => () => {
      onGetTeamManagement();
      if (selectedTeam) {
        getCurrentOrdersByTeam({ id: selectedTeam.id, rpp: 1000 });
        getAssignedOrdersByTeam({ id: selectedTeam.id, rpp: 1000 });
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.getAssignedHubs();
      this.props.onGetTeamManagement();
      this.props.getEnterpriseSettings();
      this.props.getRolesProductionTeam();
    },
    componentDidUpdate(prevProps) {
      if (this.props.isDirty && this.props.isDirty !== prevProps.isDirty) {
        this.props.onGetTeamManagement();
      }
    },
  })
)(TeamManagement);
export default injectIntl(Enhanced);
