import React from "react";
import bn from "utils/bemnames";
import { MenuIcon } from "components/CustomIcons";
import classnames from "classnames";
import { FaClose } from "react-icons/lib/fa";
import { FormattedMessage } from "react-intl";
import { Row, Col, Label } from "reactstrap";
import { get } from "lodash";
const bem = bn.create("form-builder-fields");

const Separator = ({
  intl,
  isNew,
  isView,
  field,
  value,
  errors,
  onChange,
  touched,
  onDelete,
  allowDelete,
  isFormBuilder,
  disabled,
  isRenderRow,
  colNumber,
}) => {
  // UI for form builder
  if (isFormBuilder) {
    if (isNew) {
      return (
        <Row>
          <Col className="form-builder-field">
            <div className={bem.b()}>
              <div
                className={classnames(
                  bem.e("form-field-group"),
                  bem.e("form-field-group-separator")
                )}
              >
                <Row>
                  <Col xs={12} className={bem.e("col-reset")}>
                    <div className={bem.e("form-field-group-head")}>
                      <div
                        className={bem.e("form-field-group-head-single-left")}
                      >
                        <span className={bem.e("menu-icon")}>
                          <MenuIcon />
                        </span>
                        <div className={bem.e("separator")}>
                          <FormattedMessage id="form builder > separator" />
                          <div className={bem.e("separator-line")} />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                {allowDelete && (
                  <div
                    className={classnames(
                      bem.e("form-field-group-head-right"),
                      bem.e("form-field-group-delete-icon")
                    )}
                    onClick={onDelete}
                  >
                    <FaClose size="23" color="#C2D4E0" />
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    // ui for form
  } else {
    if (isView) {
      return (
        <Row>
          <Col>
            <div className={bem.b()}>
              <div className={bem.e("form-field-group-saved")}>
                <Label>{get(field, "label", "")}</Label>
                <span className="order-view-value">
                  {value ? (
                    value
                  ) : (
                    <FormattedMessage id="form builder > none" />
                  )}
                </span>
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    const elementField = (
      <div className={bem.b()}>
        <div className={bem.e("form-field-group-saved")}>
          <div className={bem.e("form-field-separator-saved")}></div>
        </div>
      </div>
    );
    if (isRenderRow)
      return (
        <Row>
          <Col>{elementField}</Col>
        </Row>
      );
    return <Col xs={colNumber}>{elementField}</Col>;
  }
};
Separator.defaultProps = {
  isNew: false,
  isFormBuilder: true,
  errors: {},
  touched: {},
  onChange: () => {},
  onDelete: () => {},
  allowDelete: true,
  disabled: false,
  isShowRequiredToggle: true,
  isMasterListener: false,
  isRenderRow: true,
  colNumber: 12,
};
export default Separator;
