import * as Types from "store/types/systemForms";
import { LOGOUT_USER } from "store/types/auth";
import get from "lodash/get";

const initialState = {
  systemForms: {
    data: {},
    error: null,
    loading: false,
  },
  updateSystemForm: {
    error: null,
    loading: false,
  },
  duplicateSystemForm: {
    error: null,
    loading: false,
  },
  setDefaultSystemForm: {
    error: null,
    loading: false,
  },
  fulfillmentForms: {
    error: null,
    loading: false,
    data: null,
    is_dirty: false,
  },
  systemForm: {
    error: null,
    loading: false,
    data: null,
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_SYSTEM_FORMS_REQUEST: {
      return {
        ...state,
        systemForms: {
          ...state.systemForms,
          is_dirty: false,
          error: null,
          loading: true,
        },
      };
    }
    case Types.FETCH_SYSTEM_FORMS_SUCCESS: {
      return {
        ...state,
        systemForms: {
          data: action.payload,
          is_dirty: false,
          error: null,
          loading: false,
        },
      };
    }
    case Types.FETCH_SYSTEM_FORMS_ERROR: {
      return {
        ...state,
        systemForms: {
          data: {},
          is_dirty: false,
          error: action.error,
          loading: false,
        },
      };
    }
    case Types.FETCH_SYSTEM_FORM_REQUEST: {
      return state;
    }
    case Types.FETCH_SYSTEM_FORM_SUCCESS: {
      return {
        ...state,
        systemForms: {
          data: {
            ...get(state, "systemForms.data", {}),
            data: get(state, "systemForms.data.data", []).map((item) => {
              if (item._id === action.payload.id) {
                return {
                  ...item,
                  _source: action.payload,
                };
              }
              return item;
            }),
          },
          is_dirty: false,
          error: null,
          loading: false,
        },
      };
    }
    case Types.FETCH_SYSTEM_FORM_ERROR: {
      return {
        ...state,
        systemForms: {
          data: {},
          is_dirty: false,
          error: action.error,
          loading: false,
        },
      };
    }
    // Update
    case Types.UPDATE_SYSTEM_FORMS_REQUEST: {
      return {
        ...state,
        updateSystemForm: {
          loading: true,
          error: null,
        },
      };
    }
    case Types.UPDATE_SYSTEM_FORMS_SUCCESS: {
      return {
        ...state,
        updateSystemForm: {
          loading: false,
          error: null,
        },
      };
    }
    case Types.UPDATE_SYSTEM_FORMS_ERROR: {
      return {
        ...state,
        updateSystemForm: {
          loading: false,
          error: action.payload,
        },
      };
    }
    // Duplicate
    case Types.DUPLICATE_SYSTEM_FORMS_REQUEST: {
      return {
        ...state,
        duplicateSystemForm: {
          loading: true,
          error: null,
        },
      };
    }
    case Types.DUPLICATE_SYSTEM_FORMS_SUCCESS: {
      return {
        ...state,
        duplicateSystemForm: {
          loading: false,
          error: null,
        },
      };
    }
    case Types.DUPLICATE_SYSTEM_FORMS_ERROR: {
      return {
        ...state,
        duplicateSystemForm: {
          loading: false,
          error: action.payload,
        },
      };
    }
    // Default
    case Types.DEFAULT_SYSTEM_FORMS_REQUEST: {
      return {
        ...state,
        setDefaultSystemForm: {
          loading: true,
          error: null,
        },
      };
    }
    case Types.DEFAULT_SYSTEM_FORMS_SUCCESS: {
      return {
        ...state,
        setDefaultSystemForm: {
          loading: false,
          error: null,
        },
      };
    }
    case Types.DEFAULT_SYSTEM_FORMS_ERROR: {
      return {
        ...state,
        setDefaultSystemForm: {
          loading: false,
          error: action.payload,
        },
      };
    }
    // fulfillment form
    case Types.FETCH_FULFILLMENT_FORM_TEMPLATE_REQUEST: {
      return {
        ...state,
        fulfillmentForms: {
          ...state.fulfillmentForms,
          loading: true,
          error: null,
          is_dirty: false,
        },
      };
    }
    case Types.FETCH_FULFILLMENT_FORM_TEMPLATE_SUCCESS: {
      return {
        ...state,
        fulfillmentForms: {
          data: action.payload,
          loading: false,
          error: null,
          is_dirty: false,
        },
      };
    }
    case Types.FETCH_FULFILLMENT_FORM_TEMPLATE_ERROR: {
      return {
        ...state,
        fulfillmentForms: {
          data: null,
          loading: false,
          error: action.payload,
          is_dirty: false,
        },
      };
    }
    // system form
    case Types.GET_SYSTEM_FORMS_DEFAULT_REQUEST: {
      return {
        ...state,
        systemForm: {
          data: null,
          loading: true,
          error: null,
        },
      };
    }
    case Types.GET_SYSTEM_FORMS_DEFAULT_SUCCESS: {
      return {
        ...state,
        systemForm: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    }
    case Types.GET_SYSTEM_FORMS_DEFAULT_ERROR: {
      return {
        ...state,
        systemForm: {
          data: null,
          loading: false,
          error: action.payload,
        },
      };
    }

    case Types.FORGET_SYSTEM_FORMS: {
      return {
        ...state,
        systemForms: {
          ...state.systemForms,
          is_dirty: true,
        },
      };
    }
    case Types.FORGET_FULFILLMENT_FORM_TEMPLATE:
      return {
        ...state,
        fulfillmentForms: {
          ...state.fulfillmentForms,
          is_dirty: true,
        },
      };
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
}
