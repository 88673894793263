import React from "react";
import { SortableContainer } from "react-sortable-hoc";
import SortableItem from "../SortableItem";
import { get } from "lodash";
import ChannelManagerItem from "../ChannelManagerItem";
import classnames from "classnames";
const SortableList = SortableContainer(({ items, ...rest }) => {
  const {
    bem,
    setCurrentOpenForm,
    onCloneChannel,
    onSaveHiddenChannel,
    setCurrentPreviewChannel,
    setIsOpenChannelPreviewModal,
    isViewModeForms,
    currentStation,
    hasUnsavedNew,
    setIsOpenConfirmationActionsUnsavedModalNewChannel,
  } = rest;
  return (
    <div className={bem.e("channel-manager-items")} id="channel-manager-items">
      {items.map((item, index) => (
        <SortableItem
          key={`item-${index}`}
          index={index}
          item={item}
          sortableIndex={index}
          {...rest}
          renderItem={({
            channel,
            channelName,
            channelIcon,
            setChannelIcon,
          }) => {
            const is_new =
              get(channel, "_source.id", "").indexOf("_new_") === 0;
            const isOpenForm = rest.currentOpenForm === get(item, "_source.id");
            const isShowEditIcon =
              !get(isViewModeForms, `${get(item, "_source.id")}`, false) &&
              isOpenForm;
            return (
              <div
                className={classnames(bem.e("channel-row"), {
                  [bem.e("channel-new")]: is_new,
                })}
              >
                <ChannelManagerItem
                  bem={bem}
                  channel={channel}
                  channelName={channelName}
                  channelIcon={channelIcon}
                  setChannelIcon={setChannelIcon}
                  isShowEditIcon={isShowEditIcon}
                  isOpenForm={isOpenForm}
                  onSaveHiddenChannel={onSaveHiddenChannel}
                  setIsOpenForm={setCurrentOpenForm}
                  setCurrentPreviewChannel={setCurrentPreviewChannel}
                  setIsOpenChannelPreviewModal={setIsOpenChannelPreviewModal}
                  onCloneChannel={onCloneChannel}
                  currentStation={currentStation}
                  hasUnsavedNew={hasUnsavedNew}
                  setIsOpenConfirmationActionsUnsavedModalNewChannel={
                    setIsOpenConfirmationActionsUnsavedModalNewChannel
                  }
                />
              </div>
            );
          }}
        />
      ))}
    </div>
  );
});
export default SortableList;
