import React from "react";
import Tooltip from "rc-tooltip";
import { FormattedMessage } from "react-intl";
import { RemoveHyperlinkIcon } from "components/CustomIcons";
const RemoveHyperlink = ({ onClick }) => {
  return (
    <div className="editor-upload">
      <Tooltip
        trigger={["hover"]}
        placement="top"
        overlayClassName={"RichEditor-tooltip"}
        overlay={
          <FormattedMessage id="textarea evaluator > remove hyperlink" />
        }
      >
        <span className="RichEditor-styleButton" onClick={onClick}>
          <RemoveHyperlinkIcon />
        </span>
      </Tooltip>
    </div>
  );
};
export default RemoveHyperlink;
