import * as React from "react";
import { Col, Row, Label } from "reactstrap";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { setScriptField, getFileNameFromUrl } from "utils/helpers";
import { get, find } from "lodash";
import bn from "utils/bemnames";
import TextareaEvaluator from "components/TextareaEvaluator";
import FileView from "components/FileView";
const bem = bn.create("order-script");

const renderClientFeedback = (history) => {
  return history.map((feedback, index) => {
    return (
      <React.Fragment key={index}>
        <div className="audit-info">
          By {get(feedback, "modified_by", "")} &nbsp;
          {get(feedback, "modified_at", "")}
        </div>
        <div
          className={classnames(
            bem.e("form-div"),
            bem.e("form-div-draft-script")
          )}
          style={{ color: "#F50A18", fontWeight: "bold" }}
        >
          {feedback.feedback}
        </div>
      </React.Fragment>
    );
  });
};

const renderHistory = ({ isBookend, process }) => {
  const allScriptHistory = get(process, "data.fields.script_history.value", []);
  const iterations = allScriptHistory.length;
  return allScriptHistory.map((scriptHistory, index) => {
    return (
      <div key={index} className={bem.e("history-block")}>
        {scriptHistory.feedback &&
          scriptHistory.script_review_status !== "approved" && (
            <Row>
              <Col className="history-col">
                <Label className="script-history form-label">
                  <FormattedMessage
                    id={
                      isBookend
                        ? "process > field feedback part A"
                        : "process > field script feedback"
                    }
                  />
                </Label>
                <div className="audit-info">
                  By{" "}
                  {get(scriptHistory, "script_feedback_audit.modified_by", "")}{" "}
                  &nbsp;
                  {get(scriptHistory, "script_feedback_audit.modified_at", "")}
                </div>
                <div
                  className={classnames(
                    bem.e("form-div"),
                    bem.e("form-div-draft-script")
                  )}
                >
                  <TextareaEvaluator
                    readOnly={true}
                    isOrderLength={false}
                    hideOrderLength={true}
                    text={setScriptField(scriptHistory.feedback)}
                    process={process}
                    onEvaluatorCallback={() => {}}
                  />
                </div>
              </Col>
            </Row>
          )}
        {scriptHistory.client_feedback && (
          <Row>
            <Col className="history-col">
              <Label className="script-history form-label">
                <FormattedMessage id="process > field script feedback" />
              </Label>
              {renderClientFeedback(get(scriptHistory, "client_feedback", []))}
            </Col>
          </Row>
        )}
        {scriptHistory.script_review_status !== "approved" && (
          <Row>
            <Col className="history-col">
              <Label className="script-history form-label">
                <FormattedMessage id="process > field version" />{" "}
                {iterations - index}
                {isBookend && (
                  <>
                    <span>: </span>
                    <FormattedMessage id="process > part A" />
                  </>
                )}
              </Label>
              <div className="audit-info">
                By {get(scriptHistory, "script_audit.modified_by", "")} &nbsp;
                {get(scriptHistory, "script_audit.modified_at", "")}
              </div>
              <div
                className={classnames(
                  bem.e("form-div"),
                  bem.e("form-div-draft-script")
                )}
              >
                <TextareaEvaluator
                  readOnly={true}
                  isOrderLength={false}
                  hideOrderLength={true}
                  text={setScriptField(scriptHistory.script)}
                  process={process}
                  onEvaluatorCallback={() => {}}
                />
              </div>
            </Col>
          </Row>
        )}

        {isBookend && (
          <React.Fragment>
            {scriptHistory.script_part_b_feedback &&
              scriptHistory.script_part_b_review_status !== "approved" && (
                <Row>
                  <Col className="history-col">
                    <Label className="script-history form-label">
                      <FormattedMessage id="process > field feedback part B" />
                    </Label>
                    <div className="audit-info">
                      By{" "}
                      {get(
                        scriptHistory,
                        "script_part_b_feedback_audit.modified_by",
                        ""
                      )}{" "}
                      &nbsp;
                      {get(
                        scriptHistory,
                        "script_part_b_feedback_audit.modified_at",
                        ""
                      )}
                    </div>
                    <div
                      className={classnames(
                        bem.e("form-div"),
                        bem.e("form-div-draft-script")
                      )}
                    >
                      <TextareaEvaluator
                        readOnly={true}
                        isOrderLength={false}
                        hideOrderLength={true}
                        text={setScriptField(
                          scriptHistory.script_part_b_feedback
                        )}
                        process={process}
                        onEvaluatorCallback={() => {}}
                      />
                    </div>
                  </Col>
                </Row>
              )}
            {scriptHistory.client_feedback && (
              <Row>
                <Col className="history-col">
                  <Label className="script-history form-label">
                    <FormattedMessage id="process > field script feedback" />
                  </Label>
                  {renderClientFeedback(
                    get(scriptHistory, "client_feedback_b", [])
                  )}
                </Col>
              </Row>
            )}
            {scriptHistory.script_part_b_review_status !== "approved" && (
              <Row>
                <Col className="history-col">
                  <Label className="script-history form-label">
                    <FormattedMessage id="process > field version" />{" "}
                    {iterations - index}
                    <span>: </span>
                    <FormattedMessage id="process > part B" />
                  </Label>
                  <div className="audit-info">
                    By{" "}
                    {get(scriptHistory, "script_part_b_audit.modified_by", "")}{" "}
                    &nbsp;
                    {get(scriptHistory, "script_part_b_audit.modified_at", "")}
                  </div>
                  <div
                    className={classnames(
                      bem.e("form-div"),
                      bem.e("form-div-draft-script")
                    )}
                  >
                    <TextareaEvaluator
                      readOnly={true}
                      isOrderLength={false}
                      hideOrderLength={true}
                      text={setScriptField(scriptHistory.script_part_b)}
                      process={process}
                      onEvaluatorCallback={() => {}}
                    />
                  </div>
                </Col>
              </Row>
            )}
          </React.Fragment>
        )}
      </div>
    );
  });
};
const renderHistoryFile = ({ isBookend, process }) => {
  const allScriptHistory = get(process, "data.fields.script_history.value", []);
  const iterations = allScriptHistory.length;
  return allScriptHistory.map((scriptHistory, index) => {
    return (
      <div key={index} className={bem.e("history-block")}>
        {scriptHistory.feedback &&
          scriptHistory.script_review_status !== "approved" && (
            <Row>
              <Col className="history-col history-col-feedback">
                <Label className="script-history form-label">
                  <FormattedMessage
                    id={
                      isBookend
                        ? "process > field feedback part A"
                        : "process > field script feedback"
                    }
                  />
                </Label>
                <div
                  className={classnames(
                    bem.e("form-div"),
                    bem.e("form-div-draft-script")
                  )}
                >
                  <div className="audit-info-file">
                    By{" "}
                    {get(
                      scriptHistory,
                      "script_feedback_audit.modified_by",
                      ""
                    )}{" "}
                    &nbsp;
                    {get(
                      scriptHistory,
                      "script_feedback_audit.modified_at",
                      ""
                    )}
                  </div>
                  <FileView
                    file={{
                      path: scriptHistory.feedback,
                      name: getFileNameFromUrl(scriptHistory.feedback),
                    }}
                    actions={["view", "download"]}
                  />
                </div>
              </Col>
            </Row>
          )}
        {scriptHistory.script_review_status !== "approved" && (
          <Row>
            <Col className="history-col">
              <Label className="script-history form-label">
                <FormattedMessage id="process > field version" />{" "}
                {iterations - index}
                {isBookend && (
                  <>
                    <span>: </span>
                    <FormattedMessage id="process > part A" />
                  </>
                )}
              </Label>
              <div className="audit-info-file">
                By {get(scriptHistory, "script_audit.modified_by", "")} &nbsp;
                {get(scriptHistory, "script_audit.modified_at", "")}
              </div>
              <div
                className={classnames(
                  bem.e("form-div"),
                  bem.e("form-div-draft-script")
                )}
              >
                <FileView
                  file={{
                    path: scriptHistory.script,
                    name: getFileNameFromUrl(scriptHistory.script),
                  }}
                  actions={["view", "download"]}
                />
              </div>
            </Col>
          </Row>
        )}

        {isBookend && (
          <React.Fragment>
            {scriptHistory.script_part_b_feedback &&
              scriptHistory.script_part_b_review_status !== "approved" && (
                <Row>
                  <Col className="history-col history-col-feedback">
                    <Label className="script-history form-label">
                      <FormattedMessage id="process > field feedback part B" />
                    </Label>
                    <div
                      className={classnames(
                        bem.e("form-div"),
                        bem.e("form-div-draft-script")
                      )}
                    >
                      <div className="audit-info-file">
                        By{" "}
                        {get(
                          scriptHistory,
                          "script_part_b_feedback_audit.modified_by",
                          ""
                        )}{" "}
                        &nbsp;
                        {get(
                          scriptHistory,
                          "script_part_b_feedback_audit.modified_at",
                          ""
                        )}
                      </div>
                      <FileView
                        file={{
                          path: scriptHistory.script_part_b_feedback,
                          name: getFileNameFromUrl(
                            scriptHistory.script_part_b_feedback
                          ),
                        }}
                        actions={["view", "download"]}
                      />
                    </div>
                  </Col>
                </Row>
              )}
            {scriptHistory.script_part_b_review_status !== "approved" && (
              <Row>
                <Col className="history-col">
                  <Label className="script-history form-label">
                    <FormattedMessage id="process > field version" />{" "}
                    {iterations - index}
                    <span>: </span>
                    <FormattedMessage id="process > part B" />
                  </Label>
                  <div
                    className={classnames(
                      bem.e("form-div"),
                      bem.e("form-div-draft-script")
                    )}
                  >
                    <div className="audit-info-file">
                      By{" "}
                      {get(
                        scriptHistory,
                        "script_part_b_audit.modified_by",
                        ""
                      )}{" "}
                      &nbsp;
                      {get(
                        scriptHistory,
                        "script_part_b_audit.modified_at",
                        ""
                      )}
                    </div>
                    <FileView
                      file={{
                        path: scriptHistory.script_part_b,
                        name: getFileNameFromUrl(scriptHistory.script_part_b),
                      }}
                      actions={["view", "download"]}
                    />
                  </div>
                </Col>
              </Row>
            )}
          </React.Fragment>
        )}
      </div>
    );
  });
};
const OrderScriptReviewHistory = (props) => {
  const { process } = props;

  const spot = find(
    get(process, "data.fields.spot_info.value", []),
    (spot) => spot.key === process.key
  );

  const isBookend = get(spot, "spot_type", "Standard") === "Book-ends";
  const isUploadScriptFile = get(spot, "is_upload_script_file", false);

  return (
    <div className={`${bem.b()} ${bem.e("review-history")}`}>
      {!isUploadScriptFile
        ? renderHistory({
            isBookend,
            process,
          })
        : renderHistoryFile({
            isBookend,
            process,
          })}
    </div>
  );
};

export default OrderScriptReviewHistory;
