import React, { useRef } from "react";
import classnames from "classnames";
import bn from "utils/bemnames";
import { AddIcon, SyncIcon } from "components/CustomIcons";
import { FormattedMessage } from "react-intl";
import { Button } from "reactstrap";
import Tooltip from "rc-tooltip";
import { map, get, forEach, isEmpty, intersection } from "lodash";
import { NavLink } from "react-router-dom";
import URL from "utils/urls";
import { adminTabs } from "utils/config";
import { PRIVILEGES } from "utils/constants";
import useOutsideClick from "utils/useOutsideClick";
import history from "components/History";
import useElementScroll from "utils/useElementScroll";
import ConfirmationModal from "components/ConfirmationModal";
import { validatePrivileges } from "utils/helpers";
const bem = bn.create("sidebar-new");
function getProductionLinks({
  privileges,
  isEnableProductionNeeded,
  isEnableProductionProvided,
  user,
}) {
  const links = [];
  if (
    validatePrivileges({
      requires: {
        or: [PRIVILEGES.ADD_PRODUCTION, PRIVILEGES.EDIT_ALL_PRODUCTION_ORDERS],
      },
      privileges,
      user,
    })
  ) {
    if (isEnableProductionNeeded) {
      links.push({
        to: "",
        onClick: () => {
          history.push({
            pathname: URL.ADD_PROCESS({
              template_id: "basic_production",
            }),
            state: {
              contract_status: "Production Needed",
            },
            isReminder: true,
          });
        },
        title: <FormattedMessage id="menu > new production needed" />,
      });
    }
    if (isEnableProductionProvided) {
      links.push({
        to: "",
        onClick: () => {
          history.push({
            pathname: URL.ADD_PROCESS({
              template_id: "production_provided",
            }),
            state: {
              contract_status: "Production Complete",
            },
            isReminder: true,
          });
        },
        title: <FormattedMessage id="menu > new production provided" />,
      });
    }
  }
  return links;
}
function getDigitalLinks({ privileges, user }) {
  if (
    validatePrivileges({
      requires: {
        or: [PRIVILEGES.ADD_DIGITAL, PRIVILEGES.EDIT_ALL_DIGITAL_ORDERS],
      },
      privileges,
      user,
    })
  )
    return [
      {
        to: URL.ADD_PROCESS({
          template_id: "digital",
        }),
        isReminder: true,
        title: <FormattedMessage id="menu > new digital order" />,
      },
    ];
  return [];
}

function getPromotionLinks({
  privileges,
  isEnableContesting,
  isEnablePromotion,
  isEnablePrizeCloset,
  setSchedulingAvailability,
  setFetchFulfillmentSettings,
  resetProcess,
  user,
}) {
  const isShowPrizeCloset = validatePrivileges({
    requires: {
      or: [
        PRIVILEGES.PRIZE_CLOSET_SAVE,
        PRIVILEGES.PRIZE_CLOSET_EDIT,
        PRIVILEGES.PRIZE_CLOSET_VIEW,
        PRIVILEGES.PRIZE_CLOSET_DELETE,
      ],
    },
    privileges,
    user,
  });
  const links = [];
  if (
    isEnableContesting &&
    validatePrivileges({
      requires: [PRIVILEGES.CONTEST_SAVE],
      privileges,
      user,
    })
  ) {
    links.push({
      to: "",
      onClick: () => {
        resetProcess();
        setSchedulingAvailability({});
        setFetchFulfillmentSettings([]);
        // waiting for reset
        setTimeout(() => {
          history.push({
            pathname: URL.ADD_PROCESS({
              template_id: "contest",
            }),
            isReminder: true,
          });
        }, 100);
      },
      title: <FormattedMessage id="menu > new contest" />,
    });
  }
  if (
    isEnablePromotion &&
    validatePrivileges({
      requires: [PRIVILEGES.PROGRAMMING_ELEMENT_SAVE],
      privileges,
      user,
    })
  ) {
    links.push({
      to: "",
      onClick: () => {
        setSchedulingAvailability({});
        history.push({
          pathname: URL.ADD_PROCESS({
            template_id: "liner",
          }),
          isReminder: true,
        });
      },
      title: <FormattedMessage id="menu > new programming elements" />,
    });
  }
  if (isEnablePrizeCloset && isShowPrizeCloset) {
    links.push({
      to: "",
      onClick: () => {
        history.push(
          URL.ADD_PRIZES({
            template_id: "new_prize",
          })
        );
      },
      title: <FormattedMessage id="menu > new prize" />,
    });
  }
  return links;
}

function getAdminLinks({
  privileges,
  authIsGodAdmin,
  authIsSupportAdmin,
  isEnableClientManagement,
  user,
}) {
  const items = [];
  if (
    validatePrivileges({
      requires: [PRIVILEGES.ADD_USERS],
      privileges,
      user,
    })
  ) {
    items.push({
      to: {
        pathname: URL.ADMINS_TAB_ADD({ tab: adminTabs.TAB_USERS }),
        isReminder: true,
      },
      title: <FormattedMessage id="menu > new user" />,
    });
  }

  if (authIsGodAdmin && !authIsSupportAdmin) {
    items.push({
      to: {
        pathname: URL.ADMINS_TAB_ADD({ tab: adminTabs.TAB_STATIONS }),
        isReminder: true,
      },
      title: <FormattedMessage id="menu > new station" />,
    });
  }
  if (isEnableClientManagement && privileges.includes(PRIVILEGES.ADD_CLIENT)) {
    items.push({
      to: URL.CLIENT_MANAGER_FORM({
        action: "add",
      }),
      title: <FormattedMessage id="menu > new client record" />,
    });
  }
  return items;
}
function Overlay(props) {
  const { sections, menuRef, auth, isEnableProduction } = props;
  const privileges = get(props, "auth.user.privileges", []) || [];

  let isWoTrafficEnabled = get(auth, "user.enable_wo_traffic", false);
  let stations = get(props, "auth.info.stations", []);
  let userHasStationsWithWO = false;

  forEach(stations, (station) => {
    if (
      !isEmpty(intersection(station.products, ["wo_traffic", "wo_dubbing"]))
    ) {
      userHasStationsWithWO = true;
      return false;
    }
  });

  return (
    <div className={bem.e("tooltip-container")} ref={menuRef}>
      <div className={bem.e("header")}>
        <FormattedMessage id="menu > create new" />
        {isWoTrafficEnabled &&
          isEnableProduction &&
          userHasStationsWithWO &&
          validatePrivileges({
            requires: {
              or: [
                PRIVILEGES.ADD_PRODUCTION,
                PRIVILEGES.EDIT_ALL_PRODUCTION_ORDERS,
              ],
            },
            privileges,
            user: auth.user,
          }) && (
            <div className={bem.e("wo-button-container")}>
              <Button
                className={bem.e("wo-button")}
                onClick={() => {
                  props.syncWOOrders({
                    cb: () => {
                      props.setIsSyncProgressModalOpen(true);
                      props.onCloseMenu();
                    },
                  });
                }}
                color="link"
              >
                <SyncIcon />
                <FormattedMessage id="menu > sync with wide orbit" />
              </Button>
            </div>
          )}
      </div>
      <div className={bem.e("sections")}>
        {map(sections, (section, index) => (
          <div
            className={classnames(bem.e("section"), {
              [bem.e("section-admin")]: section.isAdmin,
            })}
            key={index}
          >
            <h2 className={bem.e("section-title")}>{section.title}</h2>
            <div className={bem.e("links")}>
              {map(section.links, (link, index2) => {
                if (link.onClick) {
                  return (
                    <Button
                      onClick={() => {
                        link.onClick();
                        props.onCloseMenu();
                      }}
                      color="link"
                      key={`sidebar-new-link-${index2}`}
                    >
                      {link.title}
                    </Button>
                  );
                }
                return (
                  <NavLink
                    to={link.to}
                    onClick={() => props.onCloseMenu()}
                    key={`sidebar-new-link-${index2}`}
                  >
                    {link.title}
                  </NavLink>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
function SidebarNew(props) {
  const authIsGodAdmin = get(props, "auth.user.is_god_admin", false);
  const authIsSupportAdmin = get(props, "auth.user.is_support_admin", false);
  const privileges = get(props, "auth.user.privileges", []) || [];
  const {
    isSidebarOpen,
    isEnableProduction,
    isEnableDigital,
    isEnablePromotion,
    isEnableProductionNeeded,
    isEnableProductionProvided,
    isEnableContesting,
    isEnableClientManagement,
    setSchedulingAvailability,
    setFetchFulfillmentSettings,
    resetProcess,
    isEnablePrizeCloset,
  } = props;
  const sections = [];
  const menuRef = useRef();
  useOutsideClick(menuRef, () => {
    props.setIsVisible(false);
  });
  useElementScroll({
    useWindow: false,
    elementId: "layout-sidebar",
    handler: () => props.setIsVisible(false),
  });
  const productionLinks = getProductionLinks({
    privileges,
    isEnableProductionNeeded,
    isEnableProductionProvided,
    user: props.auth.user,
  });
  const digitalLinks = getDigitalLinks({
    privileges,
    user: props.auth.user,
  });
  const promotionLinks = getPromotionLinks({
    privileges,
    isEnableContesting,
    isEnablePromotion,
    isEnablePrizeCloset,
    setSchedulingAvailability,
    setFetchFulfillmentSettings,
    resetProcess,
    user: props.auth.user,
  });

  const adminLinks = getAdminLinks({
    privileges,
    authIsGodAdmin,
    authIsSupportAdmin,
    isEnableClientManagement,
    user: props.auth.user,
  });
  if (productionLinks.length > 0 && isEnableProduction) {
    sections.push({
      title: <FormattedMessage id="menu > production" />,
      links: productionLinks,
    });
  }
  if (promotionLinks.length > 0 && isEnablePromotion) {
    sections.push({
      title: <FormattedMessage id="menu > promotions" />,
      links: promotionLinks,
    });
  }
  if (digitalLinks.length > 0 && isEnableDigital) {
    sections.push({
      title: <FormattedMessage id="menu > digital" />,
      links: digitalLinks,
    });
  }
  if (adminLinks.length > 0) {
    sections.push({
      title: <FormattedMessage id="menu > admin" />,
      links: adminLinks,
      isAdmin: true,
    });
  }
  if (sections.length === 0) return null;
  return (
    <div
      className={classnames(bem.b(), {
        [bem.m("opened")]: isSidebarOpen,
        [bem.m("closed")]: !isSidebarOpen,
      })}
      id="sidebar-new-tooltip"
    >
      <Tooltip
        placement="right"
        trigger={["click"]}
        visible={props.isVisible}
        overlay={
          <Overlay
            sections={sections}
            menuRef={menuRef}
            onCloseMenu={() => props.setIsVisible(false)}
            auth={props.auth}
            syncWOOrders={props.syncWOOrders}
            setIsSyncProgressModalOpen={props.setIsSyncProgressModalOpen}
            isEnableProduction={isEnableProduction}
          />
        }
        overlayClassName={classnames(bem.e("tooltip-new"), {
          [bem.e("tooltip-new-opened")]: isSidebarOpen,
          [bem.e("tooltip-new-closed")]: !isSidebarOpen,
          [bem.e("tooltip-new-admin")]: authIsGodAdmin,
          [bem.e("tooltip-new-normal")]: !authIsGodAdmin,
        })}
      >
        <Button
          color="primary"
          onClick={() => props.setIsVisible(!props.isVisible)}
          className={classnames(bem.e("btn-new"), {
            "btn-radius": isSidebarOpen,
            [bem.e("btn-circle")]: !isSidebarOpen,
          })}
        >
          <AddIcon color="#fff" />
          {isSidebarOpen && <FormattedMessage id="menu > new" />}
        </Button>
      </Tooltip>
      <ConfirmationModal
        isOpen={props.isSyncProgressModalOpen}
        title={props.intl.formatMessage({
          id: "menu > wo sync progress",
        })}
        description={props.intl.formatMessage({
          id: "menu > wo sync progress description",
        })}
        confirmTitle={<FormattedMessage id="menu > wo sync progress > ok" />}
        onConfirm={() => props.setIsSyncProgressModalOpen(false)}
        cancelTitle=""
        discardTitle=""
        isCloseOutside={false}
      />
    </div>
  );
}
export default SidebarNew;
