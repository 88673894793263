import React from "react";
import bn from "utils/bemnames";
import classNames from "classnames";
import LoadMore from "../LoadMore";
import { get, map } from "lodash";
import PropTypes from "prop-types";
const bem = bn.create("jock-console");
function NewsItem(props) {
  const { item } = props;
  return (
    <div className={bem.e("news-item")}>
      <div
        className={bem.e("news-image")}
        style={{ backgroundImage: `url(${item.image})` }}
      ></div>
      <div className={bem.e("news-content")}>
        <div className={bem.e("news-title")}>{item.title}</div>
        <div className={bem.e("news-subtitle")}>{item.subtitle}</div>
      </div>
    </div>
  );
}
export default function News(props) {
  const { isMultiView, widget } = props;
  const news = [
    {
      image: "https://picsum.photos/200/300",
      title: "‘Tonight': Selfie Expressions With Jaden Smith",
      subtitle: "Growing fastest with Males 18+",
    },
    {
      image: "https://picsum.photos/200/300",
      title: "Jennifer Garner and John Miller Break Up After Almost 2 Years",
      subtitle: "Growing fastest with Males 18+",
    },
    {
      image: "https://picsum.photos/200/300",
      title: "Jennifer Garner and John Miller Break Up After Almost 2 Years",
      subtitle: "Growing fastest with Males 18+",
    },
    {
      image: "https://picsum.photos/200/300",
      title: "Jennifer Garner and John Miller Break Up After Almost 2 Years",
      subtitle: "Growing fastest with Males 18+",
    },
    {
      image: "https://picsum.photos/200/300",
      title: "Jennifer Garner and John Miller Break Up After Almost 2 Years",
      subtitle: "Growing fastest with Males 18+",
    },
  ];
  return (
    <div className={classNames(bem.e("news"), bem.e("widget-detail-padding"))}>
      <div
        className={classNames(bem.e("news-head-title"), {
          [bem.e("head-title-multi-view")]: isMultiView,
        })}
        style={{
          backgroundColor: isMultiView ? widget.color : "",
        }}
      >
        {get(widget, "name", "")}
      </div>
      <div className={bem.e("news-list")}>
        {map(news, (item, index) => (
          <NewsItem key={index} item={item} />
        ))}
      </div>
      <LoadMore onClick={() => {}} />
    </div>
  );
}
News.defaultProps = {
  isMultiView: false,
  widget: {},
};
News.propsTypes = {
  isMultiView: PropTypes.bool,
  widget: PropTypes.object.isRequired,
};
