import React from "react";
import { FormattedMessage } from "react-intl";
import { Row, Col, Label } from "reactstrap";
import { find, get } from "lodash";
import {
  language as defaultLangCode,
  languageList,
  PhoneNumberFormat,
} from "utils/config";
import NumberFormat from "react-number-format";

const PersonalDetails = (props) => {
  const { bem, user, intl } = props;

  const defaultLanguage = find(
    languageList,
    (item) => item.value === defaultLangCode
  );
  const userLangCode = get(user, "lang", null);

  const language = userLangCode
    ? {
        value: userLangCode,
        label: get(
          find(languageList, (item) => item.value === userLangCode),
          "label",
          "English"
        ),
      }
    : {
        value: defaultLangCode,
        label: get(defaultLanguage, "label", "English"),
      };
  return (
    <div className={bem.e("form-content")}>
      <h4 className={bem.e("section-title")}>
        <FormattedMessage id="my profile > title personal details" />
      </h4>
      <div className={bem.e("form-fields")}>
        <Row>
          <Col className={bem.e("reset-col")}>
            <div className={bem.e("view-row")}>
              <Label className={bem.e("view-label")}>
                <FormattedMessage id="my profile > first name" />
              </Label>
              <div className={bem.e("view-value")}>
                {get(user, "first_name", "")}
              </div>
            </div>
          </Col>
          <Col className={bem.e("reset-col")}>
            <div className={bem.e("view-row")}>
              <Label className={bem.e("view-label")}>
                <FormattedMessage id="my profile > last name" />
              </Label>
              <div className={bem.e("view-value")}>
                {get(user, "last_name", "")}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className={bem.e("reset-col")}>
            <div className={bem.e("view-row")}>
              <Label className={bem.e("view-label")}>
                <FormattedMessage id="my profile > field title" />
              </Label>
              <div className={bem.e("view-value")}>
                {get(user, "title", "") ? get(user, "title", "") : "None"}
              </div>
            </div>
          </Col>
          <Col className={bem.e("reset-col")}>
            <div className={bem.e("view-row")}>
              <Label className={bem.e("view-label")}>
                <FormattedMessage id="my profile > email" />
              </Label>
              <div className={bem.e("view-value")}>
                {get(user, "email", "")}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className={bem.e("reset-col")}>
            <div className={bem.e("view-row")}>
              <Label className={bem.e("view-label")}>
                <FormattedMessage id="my profile > phone" />
              </Label>
              {user.phone ? (
                <NumberFormat
                  className={bem.e("view-value")}
                  format={PhoneNumberFormat}
                  displayType={"text"}
                  value={user.phone}
                />
              ) : (
                "None"
              )}
            </div>
          </Col>
          <Col className={bem.e("reset-col")}>
            <div className={bem.e("view-row")}>
              <Label className={bem.e("view-label")}>
                <FormattedMessage id="my profile > language" />
              </Label>
              <div className={bem.e("view-value")}>
                {get(language, "label", "")}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className={bem.e("reset-col")}>
            <div className={bem.e("view-row")}>
              <Label className={bem.e("view-label")}>
                <FormattedMessage id="my profile > location" />
              </Label>
              <div className={bem.e("view-value")}>
                {get(user, "location_text", "")
                  ? get(user, "location_text", "")
                  : intl.formatMessage({ id: "user > none" })}
              </div>
            </div>
          </Col>
          <Col className={bem.e("reset-col")}>
            <div className={bem.e("view-row")}>
              <Label className={bem.e("view-label")}>
                <FormattedMessage id="user > timezone" />
              </Label>
              <div className={bem.e("view-value")}>
                {get(user, "location", "")
                  ? get(user, "location", "")
                  : intl.formatMessage({ id: "user > none" })}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PersonalDetails;
