import { injectIntl } from "react-intl";

import Search from "./view";
import { compose, withHandlers, withState } from "recompose";
import { forEach, pick, isEmpty, get } from "lodash";
import { STATION } from "utils/constants";
import Fuse from "fuse.js";

export default injectIntl(
  compose(
    withState("searchText", "setSearchText", ""),
    withState("stations", "setStations", {}),
    withHandlers({
      getStationsList: ({ availableStations }) => () => {
        let stations = {};
        forEach(availableStations, (station) => {
          if (station.type === STATION) {
            stations[station.key] = pick(station, [
              "key",
              "station_key",
              "market",
              "format",
              "team",
              "audience",
              "digits",
              "name",
            ]);
          }
        });

        return stations;
      },
    }),
    withHandlers({
      onChangeInput: ({ setSearchText, getStationsList, setStations }) => ({
        target,
      }) => {
        const value = target.value;

        setSearchText(value);

        let searchedStations = {};

        if (!isEmpty(value)) {
          let stations = getStationsList();
          let data = [];

          for (const s in stations) {
            const marketId = Object.keys(stations[s].market)[0];

            const title = get(stations, `${s}.market.${marketId}.title`);
            if (title)
              data.push({
                ...stations[s],
                market_title: title,
              });
            else {
              data.push({ ...stations[s] });
            }
          }

          const options = {
            threshold: 0.5,
            keys: ["name", "market_title"],
          };

          const fuse = new Fuse(data, options);
          const result = fuse.search(`${value}`);


          result.map((r) => {
            const key = r.item.key;
            searchedStations = {
              ...searchedStations,
              [key]: r.item,
            };
            return true;
          });
        }

        setStations(searchedStations);
      },
      onKeyDown: (props) => (e) => {
        if (e.keyCode === 13) {
          e.preventDefault();
        }
      },
    }),
    withHandlers({
      onClearSearch: ({ onChangeInput, setStations }) => (e) => {
        e.preventDefault();
        onChangeInput({
          target: {
            name: "q",
            value: "",
          },
        });
        setStations({});
      },
    })
  )(Search)
);
