import { compose, withState, lifecycle, withHandlers } from "recompose";
import { connect } from "react-redux";
import { getChannels } from "store/actions/channels";
import { getCurrentServer } from "store/actions/servers";
import {
  get,
  forOwn,
  uniqBy,
  first,
  orderBy,
  includes,
  filter,
  find,
} from "lodash";
import View from "./view";
import { injectIntl } from "react-intl";
import { validatePrivileges } from "utils/helpers";
import { PRIVILEGES } from "utils/constants";

export default injectIntl(
  compose(
    connect(
      ({ auth, servers }) => {
        return {
          auth,
          user: auth.user,
          servers,
        };
      },
      {
        getChannels,
        getCurrentServer,
      }
    ),
    withState("stations", "setStations", []),
    withState("activeTab", "setActiveTab", ({ auth, user }) => {
      const privileges = Object.values(get(auth, "info.privileges", [])) || [];
      const { enable_promotion, enable_digital } = auth.info;
      const isAccessChannel = validatePrivileges({
        requires: {
          or: [PRIVILEGES.EDIT_CHANNELS, PRIVILEGES.PUBLISH_CHANNELS],
        },
        privileges,
        user,
      });
      const isAccessSystemForms = validatePrivileges({
        requires: {
          or: [PRIVILEGES.VIEW_FORMS, PRIVILEGES.EDIT_FORMS],
        },
        privileges,
        user,
      });
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const activeTab = urlParams.get("tab");
      if (
        activeTab === "promotion" &&
        isAccessSystemForms &&
        enable_promotion
      ) {
        return "promotion";
      }
      if (isAccessChannel && enable_digital) {
        return "digital";
      }

      if (isAccessSystemForms && enable_promotion) {
        return "promotion";
      }
      return null;
    }),
    withState("currentStation", "setCurrentStation", null),
    withHandlers({
      getStations: ({ servers, auth }) => (key = "digital") => {
        // Get stations of current server

        let stations = [];
        const entities = get(servers, `currentServer.data.entities.${key}`, {});

        forOwn(entities, (entityStations) => {
          stations = [...stations, ...entityStations];
        });
        const stationKeys = uniqBy(stations, "id").map((item) => item.key);
        const finalStations = filter(auth.info.stations, (item) =>
          includes(stationKeys, item.station_key)
        );
        return orderBy(finalStations, ["name"], ["asc"]);
      },
    }),
    lifecycle({
      componentDidMount() {
        const { activeTab, setStations } = this.props;
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const station = urlParams.get("station");
        this.props.getCurrentServer(() => {
          const newStations = this.props.getStations(activeTab);
          setStations(newStations);

          const firstStation = first(newStations);
          const selectedStationUrl = find(
            newStations,
            (i) => i.key === station
          );
          const currentStation = selectedStationUrl
            ? {
                value: selectedStationUrl.key,
                label: selectedStationUrl.name,
                key: selectedStationUrl.key,
                name: selectedStationUrl.name,
              }
            : firstStation
            ? {
                value: firstStation.key,
                label: firstStation.name,
                key: firstStation.key,
                name: firstStation.name,
              }
            : null;
          this.props.setCurrentStation(currentStation);
        });
      },
    })
  )(View)
);
