import React from "react";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import SelectionDropdown from "components/SelectionDropdown";
import { FulfillmentArrow } from "components/CustomIcons";
import SelectionBox from "../SelectionBox";
import SelectionBoxText from "../SelectionBoxText";
import { get } from "lodash";
export default function EmailAutomationNoVerification(props) {
  const {
    bem,
    intl,
    formProps,
    fulfillmentFormOptions,
    formConfirmationOptions,
    winnerAnnouncementOptions,
    setShouldBlockNavigation,
    reminderDays,
  } = props;
  const { values, errors, touched } = formProps;
  const selectedFulfillmentFormOption = fulfillmentFormOptions.find(
    (item) =>
      item.value === get(values, "settings.fulfillment_form_template", "")
  );
  const selectedFormConfirmationOption = formConfirmationOptions.find(
    (item) => item.value === get(values, "settings.form_confirmation", "")
  );
  const selectedAnnouncementOption = winnerAnnouncementOptions.find(
    (item) => item.value === get(values, "settings.winner_announcement", "")
  );
  return (
    <>
      <div className={classNames(bem.e("step"), bem.e("step-start"))}>
        <span className={bem.e("start-text")}>
          <FormattedMessage id="station settings > start" />
        </span>
        <SelectionBox bem={bem} intl={intl}>
          <span
            dangerouslySetInnerHTML={{
              __html: `${intl.formatMessage({
                id:
                  "station settings > upon selection of a winner in the studio",
              })}.`,
            }}
          ></span>
          <SelectionDropdown
            placeholder={intl.formatMessage({
              id: "station settings > select winner announcement",
            })}
            type="notification"
            isAllowAdd
            name="winner_announcement"
            value={selectedAnnouncementOption}
            options={winnerAnnouncementOptions}
            onChange={(option) => {
              formProps.setFieldValue(
                "settings.winner_announcement",
                option ? option.value : ""
              );
              setShouldBlockNavigation(true);
            }}
            onClickNew={() => {
              const { location } = window;
              const url = `${location.origin}/system-templates?tab=winner_notification&station=${values.station_id}`;
              window.open(url, "_blank").focus();
            }}
            error={
              get(touched, "settings.winner_announcement") &&
              get(errors, "settings.winner_announcement")
            }
          />
          <span
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: "station settings > include a link to",
              }),
            }}
          ></span>
          <SelectionDropdown
            placeholder={intl.formatMessage({
              id: "station settings > select fulfillment form template",
            })}
            type="form"
            isAllowAdd
            value={selectedFulfillmentFormOption}
            options={fulfillmentFormOptions}
            onChange={(option) => {
              formProps.setFieldValue(
                "settings.fulfillment_form_template",
                option ? option.value : ""
              );
              setShouldBlockNavigation(true);
            }}
            onClickNew={() => {
              const { location } = window;
              const url = `${location.origin}/form-manager?tab=promotion&station=${values.station_id}`;
              window.open(url, "_blank").focus();
            }}
            error={
              get(touched, "settings.fulfillment_form_template") &&
              get(errors, "settings.fulfillment_form_template")
            }
          />
          .
        </SelectionBox>
      </div>
      <div className={classNames(bem.e("step"), bem.e("step-middle"))}>
        <SelectionBoxText
          bem={bem}
          intl={intl}
          text={intl.formatMessage(
            {
              id: `station settings > completed form is not received within {days} days, send reminder email.`,
            },
            {
              days: reminderDays,
            }
          )}
        />
      </div>
      <div className={bem.e("step")}>
        <SelectionBox bem={bem} intl={intl}>
          <span
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id:
                  "station settings > upon receiving a completed fulfillment form, send winner the",
              }),
            }}
          ></span>
          <SelectionDropdown
            placeholder={intl.formatMessage({
              id: "station settings > select form confirmation",
            })}
            type="form"
            isAllowAdd
            name="form_confirmation"
            value={selectedFormConfirmationOption}
            options={formConfirmationOptions}
            onChange={(option) => {
              formProps.setFieldValue(
                "settings.form_confirmation",
                option ? option.value : ""
              );
              setShouldBlockNavigation(true);
            }}
            onClickNew={() => {
              const { location } = window;
              const url = `${location.origin}/system-templates?tab=winner_notification&station=${values.station_id}`;
              window.open(url, "_blank").focus();
            }}
            error={
              get(touched, "settings.form_confirmation") &&
              get(errors, "settings.form_confirmation")
            }
          />
        </SelectionBox>
        <span className={bem.e("arrow")}>
          <FulfillmentArrow />
        </span>
      </div>
      <div className={bem.e("step")}>
        <SelectionBox bem={bem} intl={intl}>
          <strong>
            {intl.formatMessage({
              id:
                "station settings > manually schedule the Release Form, W-9 and Prize Fulfillment in each contest",
            })}
          </strong>
        </SelectionBox>
        <span className={bem.e("arrow")}>
          <FulfillmentArrow />
        </span>
      </div>
    </>
  );
}
