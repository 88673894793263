import { connect } from "react-redux";
import View from "./view";
import { getClocksData } from "store/actions/programmings";
import { injectIntl } from "react-intl";
import { compose, lifecycle, withHandlers, withState } from "recompose";
import { setScheduleCalendarFilter } from "store/actions/auth";
import { countdownWithTimezone } from "utils/helpers";
import moment from "moment";
import { get } from "lodash";
const Enhanced = compose(
  connect(
    (state) => {
      const selectedStationId = get(
        state,
        "auth.schedule_calendar_filter.selected_station_id"
      );
      const scheduleCalendarFilter = get(
        state,
        `auth.schedule_calendar_filter.${selectedStationId}`,
        {}
      );
      return {
        auth: state.auth,
        scheduleCalendarFilter,
      };
    },
    { getClocksData, setScheduleCalendarFilter }
  ),
  withState("isRendered", "setIsRendered", false),
  withHandlers({
    onGetClocksData: ({ getClocksData }) => (stationKey) => {
      getClocksData({
        data: {
          station_id: stationKey,
        },
      });
    },
  }),
  lifecycle({
    componentDidMount() {
      const { process } = this.props;
      // const startWeekOn = get(clocksData, "start_week_on", "monday");
      const orderStartDate = get(process, "data.fields.order_start_date.value");
      const timezone = get(process, "data.fields.timezone.value");
      const timeData = countdownWithTimezone(orderStartDate, timezone);
      let calendarDate = moment().format("YYYY-MM-DD");
      if (!get(timeData, "isNegative", false)) {
        calendarDate = orderStartDate;
      }
      let weekdays = this.props.scheduleCalendarFilter.weekdays
        ? this.props.scheduleCalendarFilter.weekdays
        : "Monday-Friday";
      const calendarDateObj = moment(calendarDate);
      const dayOfWeek = calendarDateObj.day();

      if (weekdays === "Monday-Friday") {
        const isWeekend = dayOfWeek === 6 || dayOfWeek === 0; // 6 = Saturday, 0 = Sunday
        if (isWeekend) {
          // + 1 or 2 day to move next week for calendar view
          const newCalendarDateObj = calendarDateObj.add(
            dayOfWeek === 6 ? 2 : 1,
            "days"
          );
          calendarDate = moment(newCalendarDateObj).format("YYYY-MM-DD");
        }
      }
      const selectedItem = {
        id: get(process, "data.fields.key.value"),
        order_process_id: get(process, "data.fields.key.value", ""),
        title: get(process, "data.fields.order_title.value", ""),
        order_title: get(process, "data.fields.order_title.value", ""),
        order_start_date: get(
          process,
          "data.fields.order_start_date.value",
          ""
        ),
        order_end_date: get(process, "data.fields.order_end_date.value", ""),
        type: get(process, "data.fields.order_type.value", ""),
      };
      const selectedStation = get(
        process,
        "data.fields.order_stations_array.value.0"
      );
      const type_id = this.props.scheduleCalendarFilter.type_id
        ? this.props.scheduleCalendarFilter.type_id
        : "daypart";
      this.props.setScheduleCalendarFilter({
        ...this.props.scheduleCalendarFilter,
        station_id: get(selectedStation, "key")
          ? selectedStation.key
          : this.props.scheduleCalendarFilter.station_id,
        type_id,
        search: "",
        weekdays,
        current_date: calendarDate,
        selectedItem,
        selectedItemSwap: {},
        selectedItemTargetSwap: {},
      });
      this.props.setIsRendered(true);
    },
  })
)(View);

export default injectIntl(Enhanced);
