import * as Types from "../types/stations";
import { get, map, isFunction, join } from "lodash";
import { userInfo } from "../actions/auth";
import history from "components/History";
import Api from "utils/api";
import * as Const from "utils/constants";
import URL from "utils/urls";
import queryString from "query-string";
import { adminTabs } from "utils/config";
export const getStations = (state) => async (dispatch) => {
  let errorStr = "";
  try {
    const { pageSize, page, sorted, filtered, filteredTags } = state;
    const params = {
      page: page + 1,
      rpp: pageSize,
    };
    if (sorted.length > 0) {
      const sortedColumns = map(sorted, "id").join(",");
      params.sort = `${sortedColumns} ${
        get(sorted, `${sorted.length - 1}.desc`) ? "desc" : "asc"
      }`;
    }
    if (filteredTags.length > 0) {
      params.tags = join(filteredTags, ",");
    }
    const index = filtered.findIndex((item) => item.id === "q");
    if (index !== -1) {
      params.q = filtered[index].value;
    }

    dispatch({ type: Types.FETCH_STATIONS_REQUEST });
    let response = await Api.doCall(
      `${Const.GET_STATIONS()}?${queryString.stringify(params)}`,
      "GET"
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch(fetchStationsSuccess(response.data));
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch(fetchStationsError({ data: errorStr }));
};

export const getAllStations = (state) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.FETCH_STATIONS_REQUEST });
    let response = await Api.doCall(Const.GET_STATIONS(), "GET");
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch(fetchStationsSuccess(response.data));
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch(fetchStationsError({ data: errorStr }));
};

export const getMoreStations = (state) => async (dispatch) => {
  let errorStr = "";

  try {
    const { pageSize, page, sorted, filtered } = state;

    const params = {
      page: page + 1,
      rpp: pageSize,
    };

    if (sorted.length > 0) {
      const sortedColumns = map(sorted, "id").join(",");
      params.sort = `${sortedColumns} ${
        get(sorted, `${sorted.length - 1}.desc`) ? "desc" : "asc"
      }`;
    }

    const index = filtered.findIndex((item) => item.id === "q");

    if (index !== -1) {
      params.q = filtered[index].value;
    }

    dispatch({ type: Types.FETCH_MORE_STATIONS_REQUEST });
    let response = await Api.doCall(
      `${Const.GET_STATIONS()}?${queryString.stringify(params)}`,
      "GET"
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch(fetchMoreStationsSuccess(response.data));
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch(fetchMoreStationsError({ data: errorStr }));
};

export const getStation = (id) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.FETCH_STATION_REQUEST, payload: { id } });
    let response = await Api.doCall(Const.GET_STATION(id), "GET");
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch(fetchStationSuccess({ id, data: response.data }));
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch(fetchStationError({ id, error: errorStr }));
};

export const addStation = (data, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.ADD_STATION_REQUEST });
    let response = await Api.doCall(Const.GET_STATIONS(), "POST", data);
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({ type: Types.ADD_STATION_SUCCESS, payload: response.data });
        dispatch(userInfo());
        if (isFunction(cb)) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.ADD_STATION_ERROR, payload: errorStr });
};

export const updateStation = (id, data, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.UPDATE_STATION_REQUEST });
    let response = await Api.doCall(Const.GET_STATION(id), "PUT", data);
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({ type: Types.UPDATE_STATION_SUCCESS });
        dispatch(userInfo());
        if (isFunction(cb)) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.UPDATE_STATION_ERROR, payload: errorStr });
};

export const deleteStation = (id) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.DELETE_STATION_REQUEST });
    let response = await Api.doCall(Const.GET_STATION(id), "DELETE");
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({ type: Types.DELETE_STATION_SUCCESS });
        dispatch(userInfo());
        history.push(
          URL.ADMINS({
            tab: adminTabs.TAB_STATIONS,
          })
        );

        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.DELETE_STATION_ERROR, payload: errorStr });
};

export const updateCitiesGroupsStations = (data, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({
      type: Types.UPDATE_CITIES_GROUPS_STATIONS_REQUEST,
    });
    let response = await Api.doCall(
      Const.UPDATE_CITIES_GROUPS_STATIONS(),
      "POST",
      data
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.UPDATE_CITIES_GROUPS_STATIONS_SUCCESS,
        });
        if (isFunction(cb)) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.UPDATE_CITIES_GROUPS_STATIONS_ERROR,
    payload: { error: errorStr },
  });
};

export const getStatesForCountries = (params, cb) => async () => {
  try {
    let response = await Api.doCall(
      `${Const.GET_STATES_FOR_COUNTRIES()}?${queryString.stringify(params, {
        arrayFormat: "bracket",
      })}`,
      "GET"
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        if (isFunction(cb)) cb(response.data);
        return;
      }
    }
  } catch (error) {}
};

export const getCounties = (params, cb) => async () => {
  try {
    let response = await Api.doCall(
      `${Const.GET_COUNTIES()}?${queryString.stringify(params, {
        arrayFormat: "bracket",
      })}`,
      "GET"
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        if (isFunction(cb)) cb(response.data);
        return;
      }
    }
  } catch (error) {}
};

export const updateStationSettings = (data, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({
      type: Types.UPDATE_STATION_SETTINGS_REQUEST,
    });
    let response = await Api.doCall(
      Const.STATION_ELIGIBILITY_SETTINGS(),
      "POST",
      data
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.UPDATE_STATION_SETTINGS_SUCCESS,
          payload: response.data,
        });
        if (isFunction(cb)) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.UPDATE_STATION_SETTINGS_ERROR,
    payload: { error: errorStr },
  });
};

export const getStationSettings = (params, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.FETCH_STATION_SETTINGS_REQUEST });
    let response = await Api.doCall(
      `${Const.STATION_ELIGIBILITY_SETTINGS()}?${queryString.stringify(
        params
      )}`,
      "GET"
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.FETCH_STATION_SETTINGS_SUCCESS,
          payload: response.data,
        });
        if (isFunction(cb)) cb(response.data);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.FETCH_STATION_SETTINGS_ERROR,
    payload: errorStr,
  });
};

export const getStationW9Forms = (params, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.FETCH_STATION_W9_FORMS_REQUEST });
    let response = await Api.doCall(
      `${Const.STATION_W9_FORMS_SETTINGS()}?${queryString.stringify(params)}`,
      "GET"
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.FETCH_STATION_W9_FORMS_SUCCESS,
          payload: response.data,
        });
        if (isFunction(cb)) cb(response.data);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.FETCH_STATION_W9_FORMS_ERROR,
    payload: errorStr,
  });
};

export const updateStationW9Forms = (data, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({
      type: Types.UPDATE_STATION_W9_FORMS_REQUEST,
    });
    let response = await Api.doCall(
      Const.STATION_W9_FORMS_SETTINGS(),
      "POST",
      data
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.UPDATE_STATION_W9_FORMS_SUCCESS,
          payload: response.data,
        });
        if (isFunction(cb)) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.UPDATE_STATION_W9_FORMS_ERROR,
    payload: { error: errorStr },
  });
};

export const getStationAddressVerification = (params, cb) => async (
  dispatch
) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.FETCH_STATION_ADDRESS_VERIFICATION_REQUEST });
    let response = await Api.doCall(
      `${Const.STATION_ADDRESS_VERIFICATION_SETTINGS()}?${queryString.stringify(
        params
      )}`,
      "GET"
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.FETCH_STATION_ADDRESS_VERIFICATION_SUCCESS,
          payload: response.data,
        });
        if (isFunction(cb)) cb(response.data);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.FETCH_STATION_ADDRESS_VERIFICATION_ERROR,
    payload: errorStr,
  });
};

export const updateStationAddressVerification = (data, cb) => async (
  dispatch
) => {
  let errorStr = "";
  try {
    dispatch({
      type: Types.UPDATE_STATION_ADDRESS_VERIFICATION_REQUEST,
    });
    let response = await Api.doCall(
      Const.STATION_ADDRESS_VERIFICATION_SETTINGS(),
      "POST",
      data
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.UPDATE_STATION_ADDRESS_VERIFICATION_SUCCESS,
          payload: response.data,
        });
        if (isFunction(cb)) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.UPDATE_STATION_ADDRESS_VERIFICATION_ERROR,
    payload: { error: errorStr },
  });
};

export function fetchStationsSuccess(payload) {
  return {
    type: Types.FETCH_STATIONS_SUCCESS,
    payload,
  };
}

export function fetchStationsError(payload) {
  return {
    type: Types.FETCH_STATIONS_ERROR,
    payload,
  };
}

export function fetchMoreStationsSuccess(payload) {
  return {
    type: Types.FETCH_MORE_STATIONS_SUCCESS,
    payload,
  };
}

export function fetchMoreStationsError(payload) {
  return {
    type: Types.FETCH_MORE_STATIONS_ERROR,
    payload,
  };
}

export function fetchStationSuccess(payload) {
  return {
    type: Types.FETCH_STATION_SUCCESS,
    payload,
  };
}

export function fetchStationError(payload) {
  return {
    type: Types.FETCH_STATION_ERROR,
    payload,
  };
}

export const updatePrivilegesStations = (data, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({
      type: Types.UPDATE_PRIVILEGES_STATIONS_REQUEST,
    });
    let response = await Api.doCall(
      Const.UPDATE_PRIVILEGES_STATIONS(),
      "POST",
      data
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.UPDATE_PRIVILEGES_STATIONS_SUCCESS,
        });
        if (isFunction(cb)) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.UPDATE_PRIVILEGES_STATIONS_ERROR,
    payload: { error: errorStr },
  });
};

export const fetchReminderDays = (params) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({
      type: Types.FETCH_REMINDER_DAYS_REQUEST,
    });
    let response = await Api.doCall(
      `${Const.STATION_SETTINGS()}?${queryString.stringify(params)}`,
      "GET"
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.FETCH_REMINDER_DAYS_SUCCESS,
          payload: response.data,
        });
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.FETCH_REMINDER_DAYS_ERROR,
    payload: { error: errorStr },
  });
};

export const updateReminderDays = (data = {}) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({
      type: Types.UPDATE_REMINDER_DAYS_REQUEST,
    });
    let response = await Api.doCall(
      Const.SAVE_FULFILLMENT_REMINDERS_DAYS(),
      "POST",
      data
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.UPDATE_REMINDER_DAYS_SUCCESS,
          payload: {
            fulfillment_reminder_days_before: data.reminder_days_before,
          },
        });
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.UPDATE_REMINDER_DAYS_ERROR,
    payload: { error: errorStr },
  });
};

export const fetchFulfillmentSettings = (params) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({
      type: Types.FETCH_FULFILLMENT_SETTINGS_REQUEST,
    });
    let response = await Api.doCall(
      `${Const.FULFILLMENT_AUTOMATION_SETTINGS()}?${queryString.stringify(
        params
      )}`,
      "GET"
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.FETCH_FULFILLMENT_SETTINGS_SUCCESS,
          payload: response.data,
        });
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.FETCH_FULFILLMENT_SETTINGS_ERROR,
    payload: { error: errorStr },
  });
};

export const updateFulfillmentSettings = (params, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({
      type: Types.UPDATE_FULFILLMENT_SETTINGS_REQUEST,
    });
    let response = await Api.doCall(
      Const.FULFILLMENT_AUTOMATION_SETTINGS(),
      "POST",
      params
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.UPDATE_FULFILLMENT_SETTINGS_SUCCESS,
          payload: response.data,
        });
        if (isFunction(cb)) cb(response.data);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.UPDATE_FULFILLMENT_SETTINGS_ERROR,
    payload: { error: errorStr },
  });
};

export const setActivateFulfillmentSettings = (params, cb) => async (
  dispatch
) => {
  let errorStr = "";
  try {
    dispatch({
      type: Types.SET_FULFILLMENT_SETTINGS_ACTIVATE_REQUEST,
    });
    let response = await Api.doCall(
      `${Const.FULFILLMENT_AUTOMATION_SETTINGS()}/${params.id}/activate`,
      "PUT",
      params
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.SET_FULFILLMENT_SETTINGS_ACTIVATE_SUCCESS,
          payload: params,
        });
        if (isFunction(cb)) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.SET_FULFILLMENT_SETTINGS_ACTIVATE_ERROR,
    payload: { error: errorStr },
  });
};

export const fetchFulfillmentSetting = (params, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({
      type: Types.FETCH_FULFILLMENT_SETTING_REQUEST,
    });
    let response = await Api.doCall(
      `${Const.FULFILLMENT_AUTOMATION_SETTINGS()}/${params.id}`,
      "GET"
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.FETCH_FULFILLMENT_SETTING_SUCCESS,
          payload: response.data,
        });
        if (isFunction(cb)) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.FETCH_FULFILLMENT_SETTING_ERROR,
    payload: { error: errorStr },
  });
};

export const copyFulfillmentSettings = (params, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({
      type: Types.COPY_FULFILLMENT_SETTINGS_REQUEST,
    });
    let response = await Api.doCall(
      `${Const.FULFILLMENT_AUTOMATION_SETTINGS()}/copy`,
      "POST",
      params
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.COPY_FULFILLMENT_SETTINGS_SUCCESS,
          payload: response.data,
        });
        if (isFunction(cb)) cb(response.data);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.COPY_FULFILLMENT_SETTINGS_ERROR,
    payload: { error: errorStr },
  });
};

export const setFetchFulfillmentSettings = (payload) => (dispatch) => {
  dispatch({
    type: Types.SET_FULFILLMENT_SETTINGS_SUCCESS,
    payload,
  });
};
