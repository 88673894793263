import { combineReducers } from "redux";
import authReducer from "./auth";
import serverReducer from "./servers";
import processReducer from "./processes";
import templatesReducer from "./templates";
import stationReducer from "./stations";
import teamReducer from "./teams";
import audienceReducer from "./audience";
import userReducer from "./users";
import messagesReducer from "./messages";
import chatReducer from "./chat";
import eventsReducer from "./events";
import importReducer from "./imports";
import prizesReducer from "./prizes";
import resourcesReducer from "./resources";
import auditsReducer from "./audits";
import tagsReducer from "./tags";
import channelsReducer from "./channels";
import workflowsReducer from "./workflows";
import clocksReducer from "./clocks";
import locationsReducer from "./locations";
import clientsReducer from "./clients";
import programmingsReducer from "./programmings";
import jocksScheduleReducer from "./jocks";
import winnersReducer from "./winners";
import systemFormsReducer from "./systemForms";
import systemTemplatesReducer from "./systemTemplates";
import settingsReducer from "./settings";
import rolesReducer from "./roles";
import hubsReducer from "./hubs";
import zettaReducer from "./zetta";

const rootReducer = combineReducers({
  auth: authReducer,
  servers: serverReducer,
  processes: processReducer,
  templates: templatesReducer,
  stations: stationReducer,
  teams: teamReducer,
  audience: audienceReducer,
  users: userReducer,
  messages: messagesReducer,
  chat: chatReducer,
  events: eventsReducer,
  imports: importReducer,
  prizes: prizesReducer,
  tags: tagsReducer,
  resources: resourcesReducer,
  audits: auditsReducer,
  channels: channelsReducer,
  workflows: workflowsReducer,
  clocks: clocksReducer,
  locations: locationsReducer,
  clients: clientsReducer,
  programmings: programmingsReducer,
  jocks: jocksScheduleReducer,
  winners: winnersReducer,
  systemForms: systemFormsReducer,
  systemTemplates: systemTemplatesReducer,
  settings: settingsReducer,
  roles: rolesReducer,
  hubs: hubsReducer,
  zetta: zettaReducer,
});

export default rootReducer;
