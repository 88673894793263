import Template from "./view";
import get from "lodash/get";
import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";
import { getTemplate } from "store/actions/template";


export default compose(
  connect( 
    (state, props) => {
      return {
        isUpdate: false,
        template: state.templates.template.data,
        isLoading: state.templates.template.loading || state.processes.process.loading
      };
    },
    {
      getTemplate
    }
  ), 
  lifecycle({
    componentDidMount() {
      const { match } = this.props;
      const template_id = get(match, "params.template_id");
      
      this.props.getTemplate(template_id);
    }
  })
)(Template);


