import * as React from "react";
import { compose, withState, lifecycle, withHandlers } from "recompose";
import { connect } from "react-redux";
import { get } from "lodash";
import { getSystemForms } from "store/actions/systemForms";
import View from "./view";
import { injectIntl } from "react-intl";
import ToastManager from "components/ToastManager";
import { MdImportantDevices } from "react-icons/lib/md";
import {
  updateSystemForm,
  duplicateSystemForm,
  defaultSystemForm,
  getSystemForm,
} from "store/actions/systemForms";
export default injectIntl(
  compose(
    connect(
      ({ auth, systemForms, servers }) => {
        return {
          auth,
          user: auth.user,
          isDirty: get(systemForms, "systemForms.is_dirty", false),
          currentServer: get(servers, "currentServer.data", {}),
          systemForms: get(systemForms, "systemForms.data.data", []),
          loading:
            get(systemForms, "systemForms.loading", false) ||
            get(systemForms, "updateSystemForm.loading", false) ||
            get(systemForms, "setDefaultSystemForm.loading", false) ||
            get(systemForms, "duplicateSystemForm.loading", false),
        };
      },
      {
        getSystemForms,
        updateSystemForm,
        duplicateSystemForm,
        defaultSystemForm,
        getSystemForm,
      }
    ),
    withState("isOpenPreviewModal", "setIsOpenPreviewModal", false),
    withState("selectedSystemForm", "setSelectedSystemForm", null),
    withState("isOpenForm", "setIsOpenForm", () => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const form = urlParams.get("form");
      return form || null;
    }),
    withHandlers({
      onSaveHidden: ({ updateSystemForm, intl }) => (systemForm, checked) => {
        const values = {
          id: systemForm._id,
          name: systemForm._source.name,
          station_id: systemForm._source.station_id,
          form_type: systemForm._source.form_type,
          is_hidden: checked,
        };
        updateSystemForm(values, () => {
          ToastManager.show({
            title: <MdImportantDevices />,
            message: intl.formatMessage({
              id: "form manager > update successfully",
            }),
            level: "success",
          });
        });
      },
      onSaveDefault: ({ defaultSystemForm, intl }) => (systemForm, checked) => {
        const values = {
          id: systemForm._id,
          station_id: systemForm._source.station_id,
          form_type: systemForm._source.form_type,
          is_default: checked,
        };
        defaultSystemForm(values, () => {
          ToastManager.show({
            title: <MdImportantDevices />,
            message: intl.formatMessage({
              id: "form manager > update successfully",
            }),
            level: "success",
          });
        });
      },
      onDuplicate: ({ duplicateSystemForm, intl }) => (systemForm, checked) => {
        const values = {
          id: systemForm._id,
          station_id: systemForm._source.station_id,
          form_type: systemForm._source.form_type,
          is_hidden: checked,
        };
        duplicateSystemForm(values, () => {
          ToastManager.show({
            title: <MdImportantDevices />,
            message: intl.formatMessage({
              id: "form manager > duplicate successfully",
            }),
            level: "success",
          });
        });
      },
    }),
    lifecycle({
      componentDidMount() {
        const { currentStation } = this.props;
        this.props.getSystemForms({
          station_id: currentStation.key,
        });
      },
      componentDidUpdate(prevProps) {
        const { currentStation } = this.props;
        const isDirty =
          this.props.isDirty !== prevProps.isDirty && this.props.isDirty;
        const isStationChanged =
          this.props.currentStation &&
          this.props.currentStation.key !== prevProps.currentStation.key;

        if (isDirty || isStationChanged) {
          let params = {};
          if (currentStation) {
            params.station_id = currentStation.key;
          }
          this.props.getSystemForms(params);
        }
      },
    })
  )(View)
);
