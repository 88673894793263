import React, { useMemo, useState } from "react";
import bn from "utils/bemnames";
import { FormattedMessage } from "react-intl";
import { Row, Col, Label } from "reactstrap";
import classnames from "classnames";
import UploadFile from "components/UploadFile";
import moment from "moment";
import {
  ShadowBox,
  SectionTitle,
  SectionFields,
  ChevronWrapper,
} from "components/Elements";
import { DownIcon, UpIcon } from "components/CustomIcons";
import { get, map, includes, find } from "lodash";

const bem = bn.create("production-recording");
function SpotRecording(props) {
  const [isOpen, setIsOpen] = useState(true);
  const {
    spot,
    spotIndex,
    process,
    isView,
    onValueChangedSpot,
    isLast,
    validationState,
    step,
    user,
  } = props;
  const isShowTag = !includes(["record_script"], step.key);
  const finalRecording = get(spot, "final_recording_path", "");
  const isUserAssignedProducers = !!find(
    get(spot, "role_producer.users", []),
    (item) => {
      if (item.id === user.id) return true;
      return false;
    }
  );
  return (
    <div className={bem.e("spot-item")}>
      <ShadowBox className={bem.e("spot-info")}>
        <SectionTitle
          className={bem.e("section-title")}
          onClick={() => setIsOpen(!isOpen)}
        >
          <FormattedMessage id="process > creative details" />
          <ChevronWrapper>{isOpen ? <DownIcon /> : <UpIcon />}</ChevronWrapper>
        </SectionTitle>
        <SectionFields
          className={classnames(
            {
              hidden: !isOpen,
            },
            bem.e("section-fields")
          )}
        >
          <Row>
            <Col className={bem.e("spot-title-field")}>
              <h2 className={bem.e("spot-title")}>
                <FormattedMessage id="process > title spot" />
                {` `}
                {spotIndex + 1}
              </h2>
            </Col>
          </Row>
          <Row>
            <Col className={bem.e("title-field")} xs={6}>
              <Label className={bem.e("label")}>
                <FormattedMessage id="process > field title" />
              </Label>
              <div className={bem.e("field-value")}>
                {get(spot, "title", "")
                  ? get(spot, "title", "")
                  : props.intl.formatMessage({ id: "process > none" })}
              </div>
            </Col>
            <Col className={bem.e("spot-length-field")} xs={6}>
              <Label className={bem.e("label")}>
                <FormattedMessage id="process > field spot length" />
              </Label>
              {get(process, "data.fields.order_length.value", "") ? (
                <div className={bem.e("field-value")}>
                  {get(process, "data.fields.order_length.value", "")}{" "}
                  {props.intl.formatMessage({
                    id: "process > title seconds",
                  })}
                </div>
              ) : (
                <div className={bem.e("field-value")}>
                  {props.intl.formatMessage({
                    id: "process > none",
                  })}
                </div>
              )}
            </Col>
          </Row>
          {isShowTag && (
            <Row>
              <Col className={bem.e("tag-field")}>
                <Label className={bem.e("label")}>
                  <FormattedMessage id="process > field tag instructions" />
                </Label>
                <div
                  className={classnames(bem.e("field-value"), {
                    "tag-instructions": get(spot, "tag", ""),
                  })}
                >
                  {get(spot, "tag", "") ? (
                    get(spot, "tag", "")
                  ) : (
                    <FormattedMessage id="process > none" />
                  )}
                </div>
              </Col>
            </Row>
          )}

          <Row>
            <Col className={bem.e("recoding-field")}>
              <Label className={bem.e("label")}>
                <FormattedMessage id="process > field production asset" />
              </Label>
              <UploadFile
                mode={"view"}
                process={process}
                onUploaded={() => {}}
                disabled={true}
                isDisabledDelete={true}
                onDeleted={() => {}}
                url={get(spot, "recording_path", "")}
                actions={["download"]}
                positionActions="bottom"
                attachText={props.intl.formatMessage({
                  id: "process > field upload audio",
                })}
                auditInfo={get(spot, "recording_audit", "")}
                isShowFileNameInEditMode={true}
              >
                <FormattedMessage id="process > button upload" />
              </UploadFile>
            </Col>
          </Row>
        </SectionFields>
      </ShadowBox>
      <ShadowBox
        className={classnames(bem.e("spot-final-recording"), {
          [bem.e("spot-final-recording-last")]: isLast,
        })}
      >
        <SectionTitle className={bem.e("section-title")}>
          <div>
            <FormattedMessage id="process > field final recording" />:{` `}
            <span className={bem.e("spot-title")}>
              <FormattedMessage id="process > title spot" />
              {` `}
              {spotIndex + 1}
            </span>
          </div>
        </SectionTitle>
        <SectionFields
          className={classnames({
            [bem.e("final-recording-fields-empty")]: !finalRecording,
            [bem.e("final-recording-fields")]: finalRecording,
          })}
        >
          {isUserAssignedProducers ? (
            <div className={bem.e("spot-final-recording-field")}>
              <UploadFile
                mode={isView ? "view" : "edit"}
                process={process}
                onUploaded={(response) => {
                  onValueChangedSpot(spot.uuid, {
                    field: "final_recording_path",
                    value: get(response, "path"),
                  });
                }}
                isCheckMimesByExt={true}
                extensions={[
                  "mp3",
                  "wav",
                  "aiff",
                  "flac",
                  "aac",
                  "alac",
                  "m4a",
                  "mp4",
                ]}
                disabled={isView}
                isDisabledDelete={isView}
                onDeleted={() => {
                  onValueChangedSpot(spot.uuid, {
                    field: "final_recording_path",
                    value: "",
                  });
                }}
                url={finalRecording}
                actions={["download"]}
                positionActions="bottom"
                attachText={
                  <span className={bem.e("recording-helper-text")}>
                    {props.intl.formatMessage({
                      id: "process > field upload recording",
                    })}
                  </span>
                }
                auditInfo={get(spot, "final_recording_audit", "")}
                isShowFileNameInEditMode={true}
              >
                <FormattedMessage id="process > button upload" />
              </UploadFile>
              {get(validationState, "final_recording_path", "") && (
                <div className="alert-danger">
                  {get(validationState, "final_recording_path", "")}
                </div>
              )}
            </div>
          ) : (
            <div className={bem.e("spot-production")}>
              <FormattedMessage id="process > title spot in production" />
            </div>
          )}
        </SectionFields>
      </ShadowBox>
    </div>
  );
}
export default function View(props) {
  const {
    process,
    step,
    template,
    user,
    valueChanged,
    validationState,
    dateFormatByServer,
    shouldBlockNavigation,
    setShouldBlockNavigation,
  } = props;

  const spotInfo = get(process, "data.fields.spot_info.value", []);
  const spotInfoField = step.fields.find((f) => f.field === "spot_info");
  const rootTemplateKey = get(process, "data.fields.template_key.value", "");
  const validSpots = useMemo(() => {
    // get only spots for production needed tags
    if (rootTemplateKey === "production_provided") {
      return spotInfo.filter(
        (spot) => spot.contract_status === "Production Provided - Needs tags"
      );
    }
    return spotInfo.filter(
      (spot) => spot.contract_status === "Production Needed"
    );
  }, [rootTemplateKey, spotInfo]);
  const onValueChangedSpot = (uuid, { field, value }) => {
    const spot = spotInfo.find((spot) => spot.uuid === uuid);
    if (spot) {
      let params = {
        ...spot,
        [field]: value,
      };
      if (field === "recording_path") {
        params = {
          ...params,
          recording_audit: {
            modified_at: `${moment()
              .format(dateFormatByServer)
              .toString()} - ${moment()
              .format("h:mm A")
              .toString()}`,
            modified_by: user.name,
          },
        };
      }
      if (field === "final_recording_path") {
        params = {
          ...params,
          final_recording_audit: {
            modified_at: `${moment()
              .format(dateFormatByServer)
              .toString()} - ${moment()
              .format("h:mm A")
              .toString()}`,
            modified_by: user.name,
          },
          record_script_status: value ? "completed" : "",
        };
      }
      const newSpotInfo = spotInfo.map((spot) => {
        if (spot.uuid === uuid) return params;
        return spot;
      });

      valueChanged(
        {
          ...spotInfoField,
          changing: spotInfo.map((item) => {
            if (item.uuid === uuid) return [field];
            return [];
          }),
        },
        process,
        template,
        user,
        {
          value: newSpotInfo,
        }
      );
      if (!shouldBlockNavigation) {
        setShouldBlockNavigation(true);
      }
    }
  };
  return (
    <div className={bem.b()}>
      {map(validSpots, (spot, index) => {
        const sportValidationState = find(
          get(validationState, "spot_info.validation_error", []),
          (item) => item.uuid === spot.uuid
        );
        return (
          <SpotRecording
            key={index}
            spotIndex={index}
            spot={spot}
            isLast={validSpots.length - 1 === index}
            onValueChangedSpot={onValueChangedSpot}
            step={step}
            {...props}
            validationState={sportValidationState}
          />
        );
      })}
    </div>
  );
}
