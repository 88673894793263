import React, { useEffect } from "react";
import PropTypes from "prop-types";
import bn from "utils/bemnames";
import classNames from "classnames";
import { ArrowRightIcon } from "components/CustomIcons";
import useOutsideClick from "utils/useOutsideClick";
import { useRef } from "react";
import { get } from "lodash";
const bem = bn.create("right-sidebar");
function RightSidebar(props) {
  const { onCancel, isOpen, children, type, subtitle, title } = props;
  const containerRef = useRef();
  const filter = get(props, "match.params.filter", "");
  // const positionTop = useRef(0);
  useOutsideClick(containerRef, (event) => {
    if (props.isAllowCloseAnywhere) {
      const openModal = document
        .querySelector("body")
        .classList.contains("modal-open");
      const isClickDropdownIcon = event.target
        ? event.target.classList.contains("disable-unsaved")
        : false;
      if (!openModal && !isClickDropdownIcon) {
        onCancel();
      }
    }
  });

  useEffect(() => {
    if (filter) onCancel();
    // const processesHeader = document.getElementById("processes-header");
    // if (processesHeader) {
    //   positionTop.current = processesHeader.offsetHeight;
    // }
  }, [filter]);
  useEffect(() => {
    const appMain = document.getElementById("cr-app-main");
    if (!appMain) {
      return;
    }
    if (isOpen) appMain.classList.add("right-sidebar-open");
    if (!isOpen) appMain.classList.remove("right-sidebar-open");
  }, [isOpen]);
  return (
    <div ref={containerRef}>
      <div className={bem.b()}>
        {isOpen && (
          <div
            className={bem.e("background")}
            onClick={() => {
              if (props.isAllowCloseAnywhere) {
                onCancel();
              }
            }}
          ></div>
        )}
        <div
          className={classNames(bem.e("wrapper"), bem.e(type), {
            [bem.e("open")]: isOpen,
            [bem.e("close")]: !isOpen,
          })}
          // style={
          //   positionTop.current
          //     ? {
          //         top: positionTop.current,
          //       }
          //     : {}
          // }
          id={"right-sidebar"}
        >
          <div className={bem.e("header")}>
            <div className={bem.e("btn-close")} onClick={() => onCancel()}>
              <ArrowRightIcon color="#8165F3" />
            </div>
            <div className={bem.e("title")}>
              <h3>{title}</h3>
              {subtitle ? <span>{subtitle}</span> : null}
            </div>
          </div>
          {isOpen && children}
        </div>
      </div>
    </div>
  );
}
RightSidebar.defaultProps = {
  isOpen: false,
  onCancel: () => {},
  children: null,
  type: "",
  title: "",
  subtitle: "",
  isAllowCloseAnywhere: true,
};
RightSidebar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  children: PropTypes.element,
  type: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isAllowCloseAnywhere: PropTypes.bool,
};
export default RightSidebar;
