import { connect } from "react-redux";
import { compose, withHandlers, withState } from "recompose";
import { get } from "lodash";
import { injectIntl } from "react-intl";
import SearchResults from "./view";
import { searchElements } from "store/actions/programmings";
import { pageSize } from "utils/config";
const Enhanced = compose(
  connect(
    (state, props) => {
      const searchData = get(state, "programmings.search.data", {});
      return {
        searchData,
        searchParams: get(state, "programmings.search.params", {}),
        hasMore:
          parseInt(get(searchData, "pages", 1)) >
          parseInt(get(searchData, "page", 1)),
        dateFormatByServer: get(state, "servers.currentServer.data.date_format", "MM/DD/YYYY")
      };
    },
    {
      searchElements,
    }
  ),
  withState("filtered", "setFiltered", []),
  withState("sorted", "setSorted", { id: "updated_ts", desc: true }),
  withState("selectedItems", "setSelectedItems", []),
  withHandlers({
    getItemsList: ({
      searchElements,
      sorted,
      filtered,
      searchParams,
    }) => async (params = {}) => {
      searchElements({
        data: {
          ...searchParams,
          rpp: pageSize,
          page: 1,
          sort: `${sorted.id} ${sorted.desc ? "desc" : "asc"}`,
          filtered,
          ...params, //override
        },
      });
    },
    getMoreItems: ({
      searchElements,
      sorted,
      filtered,
      searchParams,
      searchData,
    }) => () => {
      const page = parseInt(get(searchData, "page", 1));
      searchElements({
        data: {
          ...searchParams,
          rpp: pageSize,
          page: page + 1,
          sort: `${sorted.id} ${sorted.desc ? "desc" : "asc"}`,
          filtered,
        },
      });
    },
  }),
  withHandlers({
    onSort: ({ setSorted, sorted, getItemsList }) => (field) => {
      let newSort = {
        id: field,
        desc: !sorted.desc,
      };
      let sortString = `${newSort.id} ${newSort.desc ? "desc" : "asc"}`;
      // detect third click
      if (sorted.id === field && sorted.desc && !newSort.desc) {
        sortString = `updated_ts desc`;
        newSort = {
          id: "updated_ts",
          desc: true,
        };
      }
      setSorted(newSort);
      getItemsList({
        sort: sortString,
      });
    },
  })
)(SearchResults);
export default injectIntl(Enhanced);
