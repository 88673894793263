import React from "react";
import Page from "components/Page";
import { FormattedMessage } from "react-intl";
import SystemForms from "components/SystemForms";
import ChannelManager from "components/ChannelManager";
import PrivilegedComponent from "components/PrivilegedComponent";
import { PRIVILEGES } from "utils/constants";
import { Redirect } from "react-router";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { adminTabs } from "utils/config";
import URL from "utils/urls";
import { Asterisk } from "components/Elements";
import { get, first, find } from "lodash";
import bn from "utils/bemnames";
import classnames from "classnames";
import SelectAudience from "components/SelectAudience";
import history from "components/History";

const bem = bn.create("form-manager");
const FormManagerPage = ({
  stations,
  intl,
  getChannels,
  currentStation,
  setCurrentStation,
  activeTab,
  setActiveTab,
  auth,
  getStations,
  setStations,
}) => {
  const { enable_promotion, enable_digital } = auth.info;
  const onActiveTab = (tab) => {
    setActiveTab(tab);
    const newStations = getStations(tab);
    setStations(newStations);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const stationParams = urlParams.get("station");
    const firstStation = first(newStations);
    const selectedStationUrl = find(
      newStations,
      (i) => i.key === stationParams
    );
    const currentStation = selectedStationUrl
      ? {
          value: selectedStationUrl.key,
          label: selectedStationUrl.name,
          key: selectedStationUrl.key,
          name: selectedStationUrl.name,
        }
      : firstStation
      ? {
          value: firstStation.key,
          label: firstStation.name,
          key: firstStation.key,
          name: firstStation.name,
        }
      : null;
    setCurrentStation(currentStation);
    // replace params url
    history.push({
      path: "form-manager",
      search:
        "?" +
        new URLSearchParams({
          tab,
          station: get(currentStation, "key", ""),
        }).toString(),
    });
  };
  return (
    <PrivilegedComponent
      requires={{
        or: [
          PRIVILEGES.EDIT_CHANNELS,
          PRIVILEGES.PUBLISH_CHANNELS,
          PRIVILEGES.VIEW_FORMS,
          PRIVILEGES.EDIT_FORMS,
        ],
      }}
    >
      {({ isValid }) => {
        if (isValid) {
          return (
            <Page
              className={bem.b()}
              applyPadding={false}
              title={
                <div>
                  <nav
                    className="cr-page__breadcrumb"
                    aria-label="breadcrumb"
                    style={{ fontSize: "initial" }}
                  >
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to={URL.ADMINS({ tab: adminTabs.TAB_UNKNOWN })}>
                          <FormattedMessage id="form manager > admin" />
                        </Link>
                      </li>
                      <li
                        className="active breadcrumb-item"
                        aria-current="page"
                      >
                        <FormattedMessage id="form manager > form manager" />
                      </li>
                    </ol>
                  </nav>
                  <span className="page-title">
                    <FormattedMessage id="form manager > page title" />
                  </span>
                </div>
              }
            >
              <div className={"page-container"}>
                {stations.length > 0 ? (
                  <SelectAudience
                    name="select_stations"
                    item={{ field: "stations" }}
                    label={
                      <span>
                        {intl.formatMessage({
                          id: "form manager > station",
                        })}
                        <Asterisk>*</Asterisk>
                      </span>
                    }
                    placeholder={intl.formatMessage({
                      id: "form manager > select station",
                    })}
                    onChange={(station) => {
                      setCurrentStation(station);
                      if (activeTab === "digital") {
                        let params = {};
                        if (station) {
                          params = {
                            station_id: station.key,
                          };
                        }
                        getChannels(params);
                      }
                      // replace params url
                      history.push({
                        path: "form-manager",
                        search:
                          "?" +
                          new URLSearchParams({
                            tab: activeTab,
                            station: get(station, "key", ""),
                          }).toString(),
                      });
                    }}
                    stations={stations}
                    required={false}
                    isMulti={false}
                    isAllowUseStationsProps={true}
                    value={get(currentStation, "value", "")}
                  />
                ) : null}
                {currentStation ? (
                  <React.Fragment>
                    <Nav tabs>
                      {enable_digital && (
                        <PrivilegedComponent
                          requires={{
                            or: [
                              PRIVILEGES.EDIT_CHANNELS,
                              PRIVILEGES.PUBLISH_CHANNELS,
                            ],
                          }}
                        >
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: activeTab === "digital",
                              })}
                              onClick={() => {
                                onActiveTab("digital");
                              }}
                            >
                              {intl.formatMessage({
                                id: "form manager > digital channels",
                              })}
                            </NavLink>
                          </NavItem>
                        </PrivilegedComponent>
                      )}
                      {enable_promotion && (
                        <PrivilegedComponent
                          requires={{
                            or: [PRIVILEGES.VIEW_FORMS, PRIVILEGES.EDIT_FORMS],
                          }}
                        >
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: activeTab === "promotion",
                              })}
                              onClick={() => {
                                onActiveTab("promotion");
                              }}
                            >
                              {intl.formatMessage({
                                id: "form manager > system forms",
                              })}
                            </NavLink>
                          </NavItem>
                        </PrivilegedComponent>
                      )}
                    </Nav>
                    <TabContent activeTab={activeTab}>
                      {enable_digital && activeTab === "digital" && (
                        <PrivilegedComponent
                          requires={{
                            or: [
                              PRIVILEGES.EDIT_CHANNELS,
                              PRIVILEGES.PUBLISH_CHANNELS,
                            ],
                          }}
                        >
                          <TabPane tabId="digital">
                            <div
                              className={classnames(bem.e("channels-filter"), {
                                [bem.e("channels-filter-empty")]:
                                  stations.length === 0,
                              })}
                            >
                              <FormattedMessage id="channel manager > published" />
                            </div>
                            <ChannelManager currentStation={currentStation} />
                          </TabPane>
                        </PrivilegedComponent>
                      )}
                      {enable_promotion && activeTab === "promotion" && (
                        <PrivilegedComponent
                          requires={{
                            or: [PRIVILEGES.VIEW_FORMS, PRIVILEGES.EDIT_FORMS],
                          }}
                        >
                          <TabPane tabId="promotion">
                            <SystemForms currentStation={currentStation} />
                          </TabPane>
                        </PrivilegedComponent>
                      )}
                    </TabContent>
                  </React.Fragment>
                ) : null}
              </div>
            </Page>
          );
        } else {
          return <Redirect to="/" />;
        }
      }}
    </PrivilegedComponent>
  );
};

export default FormManagerPage;
