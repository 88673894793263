import React from "react";
import bn from "utils/bemnames";
import classnames from "classnames";
import { FaClose, FaCalendar } from "react-icons/lib/fa";
import { labelToNameField } from "utils/helpers";
import { FormGroup, Row, Col, Label } from "reactstrap";
import ToggleSwitch from "components/ToggleSwitch";
import TextInput from "components/TextInput";
import { formatDisplayDate } from "utils/helpers";
import { MenuIcon } from "components/CustomIcons";
import moment from "moment";
import { get, filter } from "lodash";
import { Asterisk } from "components/Elements";
import { FormattedMessage } from "react-intl";

const bem = bn.create("form-builder-fields");
const SINGLE_DATE = "single";
const DATE_RANGE = "range";
const DateSelector = ({
  intl,
  isNew,
  isView,
  field,
  value,
  errors,
  onChange,
  onDelete,
  allowDelete,
  touched,
  isFormBuilder,
  highlightFields,
  disabled,
  isMasterListener,
  isRenderRow,
  colNumber,
  isShowOffText,
  isShowRequiredToggle,
}) => {
  // UI for form builder
  if (isFormBuilder) {
    if (isNew) {
      const isHighlightFormField =
        filter(
          highlightFields,
          (highlightFieldItem) =>
            highlightFieldItem.uuid === value.uuid &&
            highlightFieldItem.count_highlight > 0
        ).length > 0;
      return (
        <Row>
          <Col className="form-builder-field">
            <div className={bem.b()}>
              <div
                className={classnames(bem.e("form-field-group"), {
                  [bem.e("form-field-group-highlight")]: isHighlightFormField,
                  [bem.e("form-field-group-disable")]:
                    highlightFields.length > 0 &&
                    !isHighlightFormField &&
                    value.is_controlled,
                })}
              >
                <div className={bem.e("form-field-group-head")}>
                  <div className={bem.e("form-field-group-head-single-left")}>
                    <span className={bem.e("menu-icon")}>
                      <MenuIcon />
                    </span>
                    <Row>
                      <Col xs={9}>
                        <div
                          className={classnames(bem.e("input-label"), {
                            [bem.e("master-input-field")]: isMasterListener,
                          })}
                        >
                          <TextInput
                            label={null}
                            name="label"
                            type="text"
                            placeholder={intl.formatMessage({
                              id: "form builder > label date selector",
                            })}
                            value={value.label}
                            onChange={({ target }) => {
                              onChange({
                                ...value,
                                label: target.value,
                              });
                            }}
                            disabled={disabled}
                            error={get(errors, "label", "")}
                          />
                        </div>
                      </Col>
                      {isShowRequiredToggle && (
                        <Col xs={3}>
                          <ToggleSwitch
                            leftComponent={
                              <span
                                className={classnames({
                                  [bem.e("switch-active")]: !get(
                                    value,
                                    "is_required",
                                    false
                                  ),
                                })}
                              >
                                {isShowOffText
                                  ? intl.formatMessage({
                                      id: "form builder > off",
                                    })
                                  : ""}
                              </span>
                            }
                            rightComponent={
                              <span
                                className={classnames({
                                  [bem.e("switch-active")]: get(
                                    value,
                                    "is_required",
                                    false
                                  ),
                                })}
                              >
                                {intl.formatMessage({
                                  id: "form builder > required",
                                })}
                              </span>
                            }
                            name="is_required"
                            switchProps={{
                              checked: !get(value, "is_required", false),
                              onChange: (checked) => {
                                onChange({
                                  ...value,
                                  is_required: !checked,
                                });
                              },
                              disabled: disabled,
                              offColor: "#795AFA",
                              onColor: "#E3ECF1",
                              uncheckedIcon: false,
                              checkedIcon: false,
                              handleDiameter: 22,
                              width: 50,
                              height: 26,
                            }}
                          />
                        </Col>
                      )}
                    </Row>
                  </div>
                  {allowDelete && (
                    <div
                      className={bem.e("form-field-group-head-right")}
                      onClick={onDelete}
                    >
                      <FaClose size="23" color="#C2D4E0" />
                    </div>
                  )}
                </div>
                <Row
                  className={classnames(
                    bem.e("form-field-group-bottom"),
                    bem.e("form-field-switch-date-selector")
                  )}
                >
                  <Col xs={9}>
                    <ToggleSwitch
                      containerClassName={bem.e("form-date-selector")}
                      leftComponent={
                        <span
                          className={classnames({
                            [bem.e("switch-active")]:
                              get(value, "type", "") === SINGLE_DATE,
                          })}
                        >
                          {intl.formatMessage({
                            id: "form builder > single date",
                          })}
                        </span>
                      }
                      rightComponent={
                        <span
                          className={classnames({
                            [bem.e("switch-active")]:
                              get(value, "type", "") === DATE_RANGE,
                          })}
                        >
                          {intl.formatMessage({
                            id: "form builder > date range",
                          })}
                        </span>
                      }
                      name=""
                      switchProps={{
                        checked: get(value, "type", "") === SINGLE_DATE,
                        onChange: (checked) => {
                          let type = checked ? SINGLE_DATE : DATE_RANGE;
                          onChange({
                            ...value,
                            type,
                          });
                        },
                        disabled: disabled,
                        offColor: "#795AFA",
                        onColor: "#795AFA",
                        uncheckedIcon: false,
                        checkedIcon: false,
                        handleDiameter: 22,
                        width: 50,
                        height: 26,
                      }}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row>
          <Col>
            <div className={bem.b()}>
              <div className={bem.e("form-field-group-saved")}>
                <div className={bem.e("col-label")}>
                  <Label>
                    {get(value, "label", "")}{" "}
                    {get(value, "is_required", false) && <Asterisk>*</Asterisk>}
                  </Label>
                </div>
                {get(value, "type", false) === SINGLE_DATE && (
                  <Row>
                    <Col xs={6}>
                      <FormGroup className={bem.e("date-selector")}>
                        <TextInput
                          name=""
                          type="date"
                          placeholder={intl.formatMessage({
                            id:
                              "form builder > placeholder single date selector",
                          })}
                          onChange={() => {}}
                        />
                        <div className={bem.e("calendar-icon")}>
                          <FaCalendar color="#657894" size={30} />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                )}
                {get(value, "type", false) === DATE_RANGE && (
                  <Row>
                    <Col xs={3}>
                      <FormGroup className={bem.e("date-selector")}>
                        <TextInput
                          name=""
                          type="date"
                          placeholder={intl.formatMessage({
                            id:
                              "form builder > placeholder start date selector",
                          })}
                          onChange={() => {}}
                        />
                        <div className={bem.e("calendar-icon")}>
                          <FaCalendar color="#657894" size={30} />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col xs={3}>
                      <FormGroup className={bem.e("date-selector")}>
                        <TextInput
                          name=""
                          type="date"
                          placeholder={intl.formatMessage({
                            id: "form builder > placeholder end date selector",
                          })}
                          onChange={() => {}}
                        />
                        <div className={bem.e("calendar-icon")}>
                          <FaCalendar color="#657894" size={30} />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                )}
                {allowDelete && (
                  <div
                    className={bem.e("form-field-group-saved-button-close")}
                    onClick={onDelete}
                  >
                    <FaClose size="23" color="#C2D4E0" />
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    // ui for form
  } else {
    const fieldName = labelToNameField(get(field, "label", ""));
    if (isView) {
      return (
        <Row>
          <Col>
            <div className={bem.b()}>
              <div className={bem.e("form-field-group-saved")}>
                <div className={bem.e("col-label")}>
                  <Label>{get(field, "label", "")} </Label>
                </div>
                {get(field, "type", false) === SINGLE_DATE && (
                  <Row>
                    <Col xs={12}>
                      <span className="order-view-value">
                        {value ? (
                          formatDisplayDate(value)
                        ) : (
                          <FormattedMessage id="form builder > none" />
                        )}
                      </span>
                    </Col>
                  </Row>
                )}
                {get(field, "type", false) === DATE_RANGE && (
                  <Row>
                    <Col xs={6}>
                      <span className="order-view-value">
                        {get(value, "start") ? (
                          formatDisplayDate(get(value, "start"))
                        ) : (
                          <FormattedMessage id="form builder > none" />
                        )}
                      </span>
                    </Col>
                    <Col xs={6}>
                      <span className="order-view-value">
                        {formatDisplayDate(get(value, "end"))}
                      </span>
                    </Col>
                  </Row>
                )}
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    const elementField = (
      <div className={bem.b()}>
        <div
          className={classnames(
            bem.e("form-field-group-saved"),
            "form-builder-edit-field-2"
          )}
        >
          <FormGroup className={bem.e("form-group")}>
            <div className={bem.e("col-label")}>
              <Label>
                {get(field, "label", "")}{" "}
                {get(field, "is_required", false) && <Asterisk>*</Asterisk>}
              </Label>
            </div>
            {get(field, "type", false) === SINGLE_DATE && (
              <Row>
                <Col xs={12}>
                  <FormGroup className={bem.e("date-selector")}>
                    <TextInput
                      name={fieldName}
                      type="date"
                      placeholder={intl.formatMessage({
                        id: "form builder > placeholder single date selector",
                      })}
                      value={value}
                      min={moment().format("YYYY-MM-DD")}
                      onChange={(date) => {
                        onChange(date);
                      }}
                      disabled={disabled}
                      error={
                        get(touched, fieldName, false) &&
                        get(errors, fieldName, "")
                          ? get(errors, fieldName, "")
                          : ""
                      }
                      allowShowIcon={true}
                    />
                  </FormGroup>
                </Col>
              </Row>
            )}
            {get(field, "type", false) === DATE_RANGE && (
              <Row>
                <Col xs={6}>
                  <FormGroup className={bem.e("date-selector")}>
                    <TextInput
                      name={fieldName}
                      type="date"
                      placeholder={intl.formatMessage({
                        id: "form builder > placeholder start date selector",
                      })}
                      value={get(value, "start")}
                      min={moment().format("YYYY-MM-DD")}
                      max={get(value, "end") ? value.end : null}
                      onChange={(date) => {
                        onChange({
                          ...value,
                          start: date,
                        });
                      }}
                      disabled={disabled}
                      error={
                        get(touched, fieldName, false) &&
                        get(errors, fieldName, "")
                          ? get(errors, fieldName, "")
                          : ""
                      }
                      allowShowIcon={true}
                    />
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup className={bem.e("date-selector")}>
                    <TextInput
                      name={fieldName}
                      type="date"
                      min={
                        get(value, "start")
                          ? value.start
                          : moment().format("YYYY-MM-DD")
                      }
                      placeholder={intl.formatMessage({
                        id: "form builder > placeholder end date selector",
                      })}
                      disabled={disabled}
                      value={get(value, "end")}
                      onChange={(date) =>
                        onChange({
                          ...value,
                          end: date,
                        })
                      }
                      error={
                        get(touched, fieldName, false) &&
                        get(errors, fieldName, "")
                          ? get(errors, fieldName, "")
                          : ""
                      }
                      allowShowIcon={true}
                    />
                  </FormGroup>
                </Col>
              </Row>
            )}
          </FormGroup>
        </div>
      </div>
    );
    if (isRenderRow)
      return (
        <Row>
          <Col>{elementField}</Col>
        </Row>
      );
    return <Col xs={colNumber}>{elementField}</Col>;
  }
};
DateSelector.defaultProps = {
  isNew: false,
  isFormBuilder: true,
  errors: {},
  touched: {},
  onChange: () => {},
  onDelete: () => {},
  allowDelete: true,
  isMasterListener: false,
  isRenderRow: true,
  colNumber: 6,
  isShowOffText: true,
  isShowRequiredToggle: true,
};
export default DateSelector;
