import PrivilegesEffective from "./view";
import { injectIntl } from "react-intl";
import { filter, findIndex } from "lodash";
import { compose, withState, withHandlers } from "recompose";

export default injectIntl(
  compose(
    withState("options", "setOptions", ({ allOptions }) => allOptions),
    withState("selectedOptions", "setSelectedOptions", []),
    withState("optionsAdd", "setOptionsAdd", []),
    withState("optionsRemove", "setOptionsRemove", []),
    withHandlers({
      onSelectItem: ({ selectedOptions, setSelectedOptions }) => current => {
        const exists = findIndex(
          selectedOptions,
          option => option.id === current.id
        );
        // add
        if (exists === -1) {
          setSelectedOptions([current, ...selectedOptions]);
        } else {
          // remove
          selectedOptions.splice(exists, 1);
          setSelectedOptions(selectedOptions);
        }
      },
      onMoveToAddList: ({
        selectedOptions,
        options,
        setOptions,
        optionsAdd,
        setOptionsAdd,
        setSelectedOptions,
        onCallBackSetItemsToAdd
      }) => () => {
        // remove selected options from left options
        const newOptions = filter(
          options,
          current =>
            findIndex(selectedOptions, option => option.id === current.id) ===
            -1
        );
        setOptions(newOptions);
        setSelectedOptions([]);
        setOptionsAdd([...selectedOptions, ...optionsAdd]);
        onCallBackSetItemsToAdd([...selectedOptions, ...optionsAdd]);
      },
      onMoveToRemoveList: ({
        selectedOptions,
        options,
        setOptions,
        optionsRemove,
        setOptionsRemove,
        setSelectedOptions,
        onCallBackSetItemsToRemove
      }) => () => {
        // remove selected options from left options
        const newOptions = filter(
          options,
          current =>
            findIndex(selectedOptions, option => option.id === current.id) ===
            -1
        );
        setOptions(newOptions);
        setSelectedOptions([]);
        setOptionsRemove([...selectedOptions, ...optionsRemove]);
        onCallBackSetItemsToRemove([...selectedOptions, ...optionsRemove]);
      },
      onRemoveItemFromListAdd: ({
        options,
        setOptions,
        optionsAdd,
        setOptionsAdd,
        onCallBackSetItemsToAdd
      }) => item => {
        setOptions([item, ...options]);
        const newOptionsAdd = filter(
          optionsAdd,
          option => option.id !== item.id
        );
        setOptionsAdd(newOptionsAdd);
        onCallBackSetItemsToAdd(newOptionsAdd);
      },
      onRemoveItemFromListRemove: ({
        options,
        setOptions,
        optionsRemove,
        setOptionsRemove,
        onCallBackSetItemsToRemove
      }) => item => {
        setOptions([item, ...options]);
        const newOptionsRemove = filter(
          optionsRemove,
          option => option.id !== item.id
        );
        setOptionsRemove(newOptionsRemove);
        onCallBackSetItemsToRemove(newOptionsRemove);
      }
    })
  )(PrivilegesEffective)
);
