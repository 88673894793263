import View from "./view";
import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";
import { createLocation } from "store/actions/locations";
import { join, get } from "lodash";
export default compose(
  connect(
    (state) => ({
      isLoading: get(state, "locations.createLocation.loading", false),
      serverCountry: get(state, "auth.info.server_country", "US"),
    }),
    {
      createLocation,
    }
  ),
  withHandlers({
    onSubmit: ({ onSubmitted, createLocation }) => (values) => {
      let data = {
        ...values,
        office_days:
          get(values, "office_days.length") > 0
            ? join(values.office_days, ",")
            : "",
      };
      createLocation(data, (response) => {
        if (onSubmitted) onSubmitted(response);
      });
    },
  })
)(View);
