import React, { useEffect, useMemo, useState } from "react";
import bn from "utils/bemnames";
import { get, uniqBy, includes, map } from "lodash";
import CommentsPanel from "components/MultipleMarketComponents/CommentsPanel";
import { CommentsIcon } from "components/CustomIcons";
import Avatar from "components/Avatar";
import { createChatMessageForProcessDetails } from "components/MultipleMarketComponents/CommentsPanel/helpers";
const bem = bn.create("comments-sidebar");

export default function CommentsSidebar(props) {
  const [isOpenCommentsPanel, setIsOpenCommentsPanel] = useState(false);
  const [processKey, setProcessKey] = useState("");
  const { process, user, step } = props;
  useEffect(() => {
    const element = document.querySelector("#comments-panel-icon");
    // To check when element get's position sticky
    const observer = new IntersectionObserver(
      function(entries) {
        // No intersection
        if (
          entries[0].intersectionRatio === 0 &&
          element &&
          element.classList
        ) {
          element.classList.add("comments-panel-icon-sticky");
        }
        // Fully intersects
        else if (
          entries[0].intersectionRatio === 1 &&
          element &&
          element.classList
        ) {
          element.classList.remove("comments-panel-icon-sticky");
        }
      },
      {
        threshold: [0, 1],
      }
    );
    if (document.querySelector("#comments-container-bottom")) {
      observer.observe(document.querySelector("#comments-container-bottom"));
    }
  }, []);
  const allMessages = useMemo(() => {
    if (!process) return [];
    const process_key = process.key ? process.key : "-1";
    const messagesToShow = get(props, "messages", []).filter((item) => {
      const message = get(item, "message");
      const message_process_key = message.process_key;
      const isChat = message.m_type === "chat";
      if (
        message.chat &&
        isChat &&
        ((message_process_key && message_process_key === process_key) ||
          message_process_key === "current")
      ) {
        return true;
      }
      return false;
    });
    return messagesToShow;
  }, [process, get(props, "messages", [])]);
  useEffect(() => {
    const body = document.querySelector("body");
    const appEle = document.getElementById("cr-app-main");
    if (isOpenCommentsPanel) {
      body.classList.add("body-right-sidebar-open");
      appEle.classList.add("scroll-bar-style");
    } else {
      body.classList.remove("body-right-sidebar-open");
      appEle.classList.remove("scroll-bar-style");
    }
    return () => {
      body.classList.remove("body-right-sidebar-open");
      appEle.classList.remove("scroll-bar-style");
    };
  }, [isOpenCommentsPanel]);
  const taggedMessages = useMemo(() => {
    const messagesTagged = get(props, "messages", []).filter(
      (item) =>
        includes(item.message.tagged, Number(user.id)) && item.message.from
    );
    return uniqBy(messagesTagged, "from.id");
  }, [get(props, "messages", [])]);
  // Actions
  const onSubmit = (chatValue) => {
    const chatText = chatValue ? chatValue.value : "";
    const attachments = get(chatValue, "attachments", []);
    if (!chatText && attachments.length === 0) return;
    let { process, user, template } = props;
    const chat_message = createChatMessageForProcessDetails(
      { chatText, attachments },
      {
        process,
        template,
        step,
        user,
      }
    );
    if (props.recomputeFields) {
      process = props.recomputeFields(user, template, process, step);
    }
    // We will call single to send message when editing an order
    if (processKey && processKey !== "new") {
      props.sendMessage({
        message: chat_message,
        process_id: processKey,
        process,
      });
    } else {
      props.saveFormValues({
        process,
        template,
        step,
        user,
        success_callback: (response) => {
          // set current process key after create success
          if (response && response.data) {
            setProcessKey(response.data.key);
          }
        },
        valid: true,
        chat_message,
        recordingFeedback: false,
        scriptFeedback: false,
        clientReview: false,
      });
    }
  };

  const totalMessages = get(allMessages, "length", 0);
  const conversationData = {
    role_account_executive: get(
      process,
      "data.fields.role_account_executive.value"
    ),
    role_production_manager: get(
      process,
      "data.fields.role_production_manager.value"
    ),
    role_writer: get(process, "data.fields.role_writer.value"),
    role_voice_talent: get(process, "data.fields.role_voice_talent.value"),
    role_producer: get(process, "data.fields.role_producer.value"),
    role_continuity: get(process, "data.fields.role_continuity.value"),
    role_dubber: get(process, "data.fields.role_dubber.value"),
  };

  return (
    <div className={bem.b()}>
      <div id="comments-container-bottom"></div>
      <div
        className={bem.e("comments")}
        id="comments-panel-icon"
        onClick={() => {
          setIsOpenCommentsPanel(true);
          setProcessKey(get(process, "data.fields.key.value", "") || "new");
        }}
      >
        <CommentsIcon />
        {taggedMessages.length > 0 ? (
          <span className={bem.e("comment-red")}>{taggedMessages.length}</span>
        ) : totalMessages > 0 ? (
          <span className={bem.e("comment-white")}>{totalMessages}</span>
        ) : null}
      </div>
      {taggedMessages.length > 0 ? (
        <div className={bem.e("tagged-users")}>
          {map(taggedMessages, (item, index) => {
            const userTagged = get(item, "message.from");
            if (!userTagged) return null;
            return (
              <Avatar
                key={index}
                src={userTagged.avatar_url}
                name={userTagged.name}
                className={bem.e("avatar")}
                onClick={() => {
                  setIsOpenCommentsPanel(true);
                  setProcessKey(get(process, "data.fields.key.value", ""));
                }}
              />
            );
          })}
        </div>
      ) : null}
      <CommentsPanel
        isOpen={isOpenCommentsPanel}
        onCancel={() => setIsOpenCommentsPanel(false)}
        processKey={processKey}
        templateKey={get(process, "data.fields.template_key.value", "")}
        conversationData={conversationData}
        onSubmit={onSubmit}
      />
    </div>
  );
}
