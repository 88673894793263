import React from "react";
import { FaDropbox } from "react-icons/lib/fa";
import { FormattedMessage } from "react-intl";
import bn from "utils/bemnames";
const bem = bn.create("prizes-details");
const EmptyPrizes = () => (
  <div className={bem.e("empty-container")}>
    <span className={bem.e("box-icon")}>
      <FaDropbox color="#6F96D1" />
    </span>
    <p className={bem.e("empty-text")}>
      <FormattedMessage id="process > description prizes empty" />
    </p>
  </div>
);
EmptyPrizes.propTypes = {};
export default EmptyPrizes;
