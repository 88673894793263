import { FormattedMessage } from "react-intl";
import React from "react";
import {
  get,
  find,
  filter,
  forEach,
  delay,
  findIndex,
  uniqBy,
  pick,
} from "lodash";
import { getFormFieldsWithConditionLogic } from "utils/helpers";
import history from "components/History";

const reviewMedia = {
  process_step_index: 6,
  key: "review_media",
  title: <FormattedMessage id="process > title review" />,
  caption_color: "#0A53C0",
  description: null,
  step_roles: [13],
  step_viewers_roles: [13],
  step_editors_roles: [13],
  step_viewers_privileges: {
    or: ["add_digital", "edit_digital", "edit_all_digital_orders"],
  },
  step_editors_privileges: {
    or: ["add_digital", "edit_digital", "edit_all_digital_orders"],
  },
  is_check_hidden_tab: true,
  status: "initial",
  is_disabled: function(user, process, step) {
    const processKey = get(process, "data.fields.key.value", false);
    return !processKey;
  },
  is_hidden: function(user, process, step) {
    // if (!process.data.fields.for_marketing_channel) return true;
    const marketingChannels = get(
      process,
      "data.fields.marketing_channels.value",
      []
    );
    const forMarketingChannel = get(
      process,
      "data.fields.for_marketing_channel.value.id"
    );
    const channel = find(
      marketingChannels,
      (channel) => channel.id === forMarketingChannel
    );
    // If a channel requires production, it will have review steps
    // check have data for the fields media_for_review_approval
    const isHaveMediaForReview =
      get(channel, "production") === "Requires Production" ||
      get(channel, "production") === "Production Needed";
    return !isHaveMediaForReview;
  },
  no_edit_button: false,
  is_approval_step: false,
  className: "review-media-container",
  form_class_name: "review-media-tab-container",
  fields: [
    {
      component: "review_media",
    },
    {
      field: "marketing_channels",
    },
    {
      field: "media_review_history",
    },
    {
      key: "save_button",
      text: <FormattedMessage id="process > button submit all feedback" />,
      // component: "button",
      // container: "bottom_buttons",
      on_click: {
        validate: true,
        save: true,
        go_to: function(_this, step, item, process, template, user) {
          if (!user.return_to_current_tasks) return;
          // set delay when after success message
          delay(() => {
            if (_this.props.setSidebarActive) {
              _this.props.setSidebarActive(`/processes/my_action_items`);
            }
            history.replace(`/processes/my_action_items`);
          }, 4000);
        },
      },
      fields: [
        {
          key: "media_review_history",
          value: (user, template, process, step, item) => {
            const isSubmitAll = get(item, "is_submit_all_feedback");
            let mediaReviewHistory = get(
              process,
              "data.fields.media_review_history.value",
              []
            );
            if (!isSubmitAll) {
              return mediaReviewHistory;
            }
            let reviewHistoryValue = [];
            const now = new Date();
            const marketingChannels = get(
              process,
              "data.fields.marketing_channels.value",
              []
            );
            const forMarketingChannel = get(
              process,
              "data.fields.for_marketing_channel.value.id"
            );
            const channel = find(
              marketingChannels,
              (channel) => channel.id === forMarketingChannel
            );
            let newFields = getFormFieldsWithConditionLogic(channel);
            filter(
              newFields,
              (field) => field.key === "media_for_review_approval"
            ).forEach((field) => {
              const currentHistoryIndex = findIndex(
                reviewHistoryValue,
                (i) => i.label === field.label
              );
              let reviewHistoryValueOfField = {};
              // get current rejected history of current field.
              if (currentHistoryIndex !== -1) {
                reviewHistoryValueOfField =
                  reviewHistoryValue[currentHistoryIndex];
              } else {
                reviewHistoryValueOfField = {
                  ...pick(field, [
                    "is_required",
                    "key",
                    "label",
                    "mimes",
                    "placeholder",
                  ]),
                  value_arr: [],
                };
              }
              // get value array of media and comments
              let rejectedHistoriesValueArr = get(
                reviewHistoryValueOfField,
                "value_arr",
                []
              );
              forEach(field.value_arr, (fieldValue) => {
                if (get(fieldValue, "review_media_status") === "rejected") {
                  rejectedHistoriesValueArr = [
                    ...rejectedHistoriesValueArr,
                    {
                      ...fieldValue,
                      time: now.getTime() / 1000,
                      user: {
                        avatar_url: user.avatar_url,
                        name: user.name,
                        id: user.id,
                      },
                    },
                  ];
                }
              });
              if (rejectedHistoriesValueArr.length > 0) {
                reviewHistoryValueOfField = {
                  ...reviewHistoryValueOfField,
                  value_arr: uniqBy(rejectedHistoriesValueArr, "path"),
                };
                if (currentHistoryIndex !== -1) {
                  reviewHistoryValue[
                    currentHistoryIndex
                  ] = reviewHistoryValueOfField;
                } else {
                  reviewHistoryValue.push(reviewHistoryValueOfField);
                }
              }
            });
            const newMediaReviewHistory =
              reviewHistoryValue.length > 0
                ? [...mediaReviewHistory, reviewHistoryValue]
                : mediaReviewHistory;
            // remove old data for previous version code.
            return newMediaReviewHistory.filter((item) => Array.isArray(item));
          },
        },
        {
          key: "next_users",
          value: (user, template, process, step, item, next_users) => {
            let nextUsers = [];
            const isSubmitAll = get(item, "is_submit_all_feedback");
            // When digital sales just approve/reject or edit/create/delete comment with auto save.
            if (!isSubmitAll)
              return [
                get(
                  process,
                  "data.fields.role_digital_account_executive.value.id"
                ),
              ];

            // When digital sales submit all feedback. We will assign to next users
            const marketingChannels = get(
              process,
              "data.fields.marketing_channels.value",
              []
            );
            const forMarketingChannel = get(
              process,
              "data.fields.for_marketing_channel.value.id"
            );
            const channel = find(
              marketingChannels,
              (channel) => channel.id === forMarketingChannel
            );
            let newFields = getFormFieldsWithConditionLogic(channel);
            let isRejected = false;
            let isMissingReviewed = false;
            filter(
              newFields,
              (field) => field.key === "media_for_review_approval"
            ).forEach((field) => {
              const assetsMissReviewed = filter(
                field.value_arr,
                (fieldValue) => {
                  if (get(fieldValue, "review_media_status") === "rejected") {
                    isRejected = true;
                  }
                  return !get(fieldValue, "review_media_status");
                }
              );
              if (assetsMissReviewed.length > 0) isMissingReviewed = true;
            });
            if (isRejected) {
              // if any of the media for review is "Rejected" it will assigned to "Digital Producer" in charge of the channel
              nextUsers = [
                get(process, "data.fields.role_digital_producer.value.id"),
              ];
            } else if (isMissingReviewed) {
              // If the channel is missing review any media. Next users will still go to digital sales
              nextUsers = [
                get(
                  process,
                  "data.fields.role_digital_account_executive.value.id"
                ),
              ];
            } else {
              // If the channel approved by digital sales. Next users will go to channel manager
              nextUsers = [
                get(process, "data.fields.role_channel_manager.value.id"),
              ];
            }
            return nextUsers;
          },
        },
        {
          key: "review_media_status",
          value: (user, template, process, step, item) => {
            const marketingChannels = get(
              process,
              "data.fields.marketing_channels.value",
              []
            );
            const forMarketingChannel = get(
              process,
              "data.fields.for_marketing_channel.value.id"
            );
            const channel = find(
              marketingChannels,
              (channel) => channel.id === forMarketingChannel
            );
            const isSubmitAll = get(item, "is_submit_all_feedback");
            let newFields = getFormFieldsWithConditionLogic(channel);
            let isRejected = false;
            let isMissingReviewed = false;
            filter(
              newFields,
              (field) => field.key === "media_for_review_approval"
            ).forEach((field) => {
              const assetsMissReviewed = filter(
                field.value_arr,
                (fieldValue) => {
                  if (get(fieldValue, "review_media_status") === "rejected") {
                    isRejected = true;
                  }
                  return !get(fieldValue, "review_media_status");
                }
              );
              if (assetsMissReviewed.length > 0) isMissingReviewed = true;
            });
            let status = "completed";
            if (isRejected) {
              status = "rejected";
            }
            if (isMissingReviewed || !isSubmitAll) {
              status = null;
            }
            return status;
          },
        },
        {
          key: "design_status",
          value: (user, template, process, step, item) => {
            const marketingChannels = get(
              process,
              "data.fields.marketing_channels.value",
              []
            );
            const forMarketingChannel = get(
              process,
              "data.fields.for_marketing_channel.value.id"
            );
            const channel = find(
              marketingChannels,
              (channel) => channel.id === forMarketingChannel
            );
            let newFields = getFormFieldsWithConditionLogic(channel);
            let isRejected = false;
            filter(
              newFields,
              (field) => field.key === "media_for_review_approval"
            ).forEach((field) => {
              forEach(field.value_arr, (fieldValue) => {
                if (get(fieldValue, "review_media_status") === "rejected") {
                  isRejected = true;
                }
              });
            });
            if (isRejected) {
              return "rejected";
            }
            return "completed";
          },
        },
        {
          key: "process_step",
          value: (user, template, process, step, item) => {
            const isSubmitAll = get(item, "is_submit_all_feedback");
            let process_step = "review_media";
            if (isSubmitAll) {
              const marketingChannels = get(
                process,
                "data.fields.marketing_channels.value",
                []
              );
              const forMarketingChannel = get(
                process,
                "data.fields.for_marketing_channel.value.id"
              );
              const channel = find(
                marketingChannels,
                (channel) => channel.id === forMarketingChannel
              );
              let newFields = getFormFieldsWithConditionLogic(channel);
              let isRejected = false;
              let isMissingReviewed = false;
              filter(
                newFields,
                (field) => field.key === "media_for_review_approval"
              ).forEach((field) => {
                const assetsMissReviewed = filter(
                  field.value_arr,
                  (fieldValue) => {
                    if (get(fieldValue, "review_media_status") === "rejected") {
                      isRejected = true;
                    }
                    return !get(fieldValue, "review_media_status");
                  }
                );
                if (assetsMissReviewed.length > 0) isMissingReviewed = true;
              });
              process_step = "first_undone";
              if (isRejected) {
                process_step = "design";
              }
              // Keep current process step for review
              if (isMissingReviewed) {
                process_step = "review_media";
              }
            }
            return process_step;
          },
        },
      ],
      success_dialog_props: {
        title: <FormattedMessage id="process > title submitted" />,
        state: "success",
      },
    },
  ],
};
export default reviewMedia;
