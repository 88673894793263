import React, { useEffect, useState } from "react";
import View from "./view";
import { get, cloneDeep, findIndex, map } from "lodash";
import { generateRotationChart } from "utils/helpers";
import { uuidv4 } from "utils/helpers";

function generateDefaultFieldsGroup(process, status = "") {
  const orderStartDate = get(process, "data.fields.order_start_date.value", "");
  const orderEndDate = get(process, "data.fields.order_end_date.value", "");
  const copyGroupsNumber = get(process, "data.fields.copy_groups.value.length", 0);

  return {
    title: `Copy group ${copyGroupsNumber + 1}`,
    copy_id: "",
    start_date: orderStartDate,
    end_date: orderEndDate,
    days_of_week: get(process, "data.fields.days_of_week.value", []),
    start_date_time: orderStartDate ? "00:00:00" : "", // Copy group start /end times default to 12am - 11:59pm and to PER FLIGHT
    end_date_time: orderEndDate ? "23:59:00" : "",
    per_day: false,
    list_scripts: [
      {
        script: 1,
        text: "",
        uuid: uuidv4(),
      },
    ],
    jock_instructions: "",
    status,
  };
}
export default function PromotionLinerScripts(props) {
  const { step, valueChanged, template, user, process } = props;
  const copyGroupsValue = get(process, "data.fields.copy_groups.value", [
    generateDefaultFieldsGroup(process, "baseline"),
  ]);

  const [isOpenGroupIndex, setIsOpenGroupIndex] = useState(
    copyGroupsValue.length === 1 ? 0 : null
  );
  const [
    isOpenDeleteConfirmationModal,
    setIsOpenDeleteConfirmationModal,
  ] = useState(false);
  const [fieldRefs, setFieldRefs] = useState(null);
  const [currentGroupIndex, setCurrentGroupIndex] = useState(null);
  useEffect(() => {
    // set default first group
    if (!get(process, "data.fields.copy_groups.value")) {
      valueChanged(copy_groups, process, template, user, {
        value: [generateDefaultFieldsGroup(process, "baseline")],
      });
    }
  }, []);
  const getFieldRefs = () => {
    if (fieldRefs) return fieldRefs;
    let copy_groups = step.fields.find((f) => f.field === "copy_groups");
    let payload = {
      copy_groups,
    };
    setFieldRefs(payload);
    return payload;
  };

  const { copy_groups } = getFieldRefs();
  const onValueChangedField = (field, value) => {
    valueChanged(field, process, template, user, { value });
  };
  const onValueChanged = ({ idx, key, value }) => {
    let newCopyGroupsValue = cloneDeep(copyGroupsValue);
    newCopyGroupsValue[idx] = {
      ...newCopyGroupsValue[idx],
      [key]: value,
    };
    valueChanged(copy_groups, process, template, user, {
      value: newCopyGroupsValue,
    });
  };
  const onAddNewGroup = () => {
    let newCopyGroupsValue = cloneDeep(copyGroupsValue);
    // set first copy group status is baseline
    if (newCopyGroupsValue[0]) {
      newCopyGroupsValue[0] = {
        ...newCopyGroupsValue[0],
        status: "baseline",
      };
    }
    newCopyGroupsValue = [
      ...newCopyGroupsValue,
      generateDefaultFieldsGroup(process),
    ];
    setIsOpenGroupIndex(newCopyGroupsValue.length - 1);

    valueChanged(copy_groups, process, template, user, {
      value: newCopyGroupsValue,
    });
  };
  const onDeleteGroup = () => {
    let newCopyGroupsValueRemoved = copyGroupsValue.filter(
      (item, index) => index !== currentGroupIndex
    );
    // set first copy group status is baseline
    if (newCopyGroupsValueRemoved.length > 1 && newCopyGroupsValueRemoved[0]) {
      newCopyGroupsValueRemoved[0] = {
        ...newCopyGroupsValueRemoved[0],
        status: "baseline",
      };
    } else {
      // reset status when have only copy group
      newCopyGroupsValueRemoved = map(newCopyGroupsValueRemoved, (item) => ({
        ...item,
        status: "",
      }));
    }
    setIsOpenDeleteConfirmationModal(false);
    setCurrentGroupIndex(null);
    setIsOpenGroupIndex(
      findIndex(newCopyGroupsValueRemoved, (item) => item.status === "baseline")
    );
    valueChanged(copy_groups, process, template, user, {
      value: newCopyGroupsValueRemoved,
    });
  };
  const onAddToRotation = ({ idx }) => {
    const group = copyGroupsValue[idx];
    let newGroupScripts = group.list_scripts;
    newGroupScripts = [
      ...newGroupScripts,
      {
        script: get(group, "list_scripts.length", 0) + 1,
        text: "",
        uuid: uuidv4(),
      },
    ];
    const rotationChart = generateRotationChart(
      process,
      get(process, "data.fields.order_stations.value", []),
      newGroupScripts
    );
    let newCopyGroupsValue = cloneDeep(copyGroupsValue);
    newCopyGroupsValue[idx] = {
      ...newCopyGroupsValue[idx],
      list_scripts: newGroupScripts,
      rotation_chart: rotationChart,
    };
    valueChanged(copy_groups, process, template, user, {
      value: newCopyGroupsValue,
    });
  };
  return (
    <View
      {...props}
      {...Object.assign(
        {},
        {
          copyGroups: copyGroupsValue,
          isOpenGroupIndex,
          setIsOpenGroupIndex,
          onValueChanged,
          onAddNewGroup,
          isOpenDeleteConfirmationModal,
          setIsOpenDeleteConfirmationModal,
          currentGroupIndex,
          setCurrentGroupIndex,
          onDeleteGroup,
          onAddToRotation,
          onValueChangedField,
        }
      )}
    />
  );
}
