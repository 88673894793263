import React from "react";
import PropsType from "prop-types";
import Dropzone from "react-dropzone";
import styled from "styled-components";
import { MdImportantDevices } from "react-icons/lib/md";
import { join, first } from "lodash";
import ToastManager from "components/ToastManager";
import { FileIcon } from "components/CustomIcons";
import { getImageHeightAndWidth } from "utils/helpers";

const getColor = (props) => {
  if (props.isDragReject) {
    return "#c66";
  }
  if (props.isDragActive) {
    return "#6c6";
  }
  return "#829FB1";
};

const DropTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const DropText = styled.div`
  font-style: normal;
  text-align: center;
  font-weight: 600;
  color: #795afa;
  font-size: 16px;
  margin-left: 17px;
`;
const DropArea = styled.div`
  border-width: 1px;
  border-radius: 6px;
  border-color: ${(props) => getColor(props)};
  border-style: ${(props) =>
    props.isDragReject || props.isDragActive || props.mode === "view"
      ? "solid"
      : "dashed"};
  background-color: #eff3f6;
  padding: 12px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  outline: none;
  flex-wrap: wrap;
  cursor: pointer;
`;

class FormUploadFile extends React.Component {
  onDrop = async (acceptedFiles, rejectedFiles, fieldName) => {
    const { minDimensions, extensions, isCheckMimesByExt } = this.props;
    let isValid = false;
    if (isCheckMimesByExt) {
      if (acceptedFiles.length) {
        acceptedFiles.forEach((file) => {
          extensions.forEach((ext) => {
            if (file.name.toLowerCase().endsWith(`.${ext.toLowerCase()}`)) {
              isValid = true;
            }
          });
        });
      }
    } else {
      isValid = true;
    }
    if (rejectedFiles.length || !isValid) {
      let message = `This file format is not supported`;
      if (fieldName === "avatar") {
        message = this.props.intl.formatMessage({
          id: "upload > The avatar must be an image",
        });
      }
      let file = first(rejectedFiles);
      if (this.props.maxSize && this.props.maxSize < file.size) {
        message = this.props.intl.formatMessage(
          {
            id: `upload > maximum file upload size is :maximum`,
          },
          {
            maximum: Number(this.props.maxSize) / 1000,
          }
        );
      }
      ToastManager.show({
        title: <MdImportantDevices />,
        message,
        autoDismiss: 1.5,
        level: "error",
      });
    }
    if (minDimensions && acceptedFiles.length) {
      const getDimensionsPromises = acceptedFiles.map(getImageHeightAndWidth);
      const filesDimensions = await Promise.all(getDimensionsPromises);
      const invalidFiles = filesDimensions.filter((fileDimension) => {
        if (minDimensions.width && fileDimension.width < minDimensions.width) {
          return true;
        }
        if (
          minDimensions.height &&
          fileDimension.height < minDimensions.height
        ) {
          return true;
        }
        return false;
      });
      if (invalidFiles.length) {
        const message = this.props.intl.formatMessage(
          {
            id: `upload > minimum file dimensions are :dimensions`,
          },
          {
            dimensions: `${minDimensions.width}x${minDimensions.height}`,
          }
        );
        ToastManager.show({
          title: <MdImportantDevices />,
          message,
          autoDismiss: 1.5,
          level: "error",
        });
        return;
      }
    }

    if (acceptedFiles.length && isValid) {
      const formData = new FormData();
      acceptedFiles.map((file) => formData.append("file_name", file));
      this.props.onSubmit(acceptedFiles);
    }
  };

  renderDropAreaText = ({ isDragActive, isDragReject }) => {
    const { mode, attachText } = this.props;
    if (mode === "view") {
      return null;
    }
    let dropDom = (
      <DropTextWrapper>
        <FileIcon />
        <DropText>{attachText}</DropText>
      </DropTextWrapper>
    );
    if (isDragActive) {
      dropDom = (
        <div>
          <DropText>
            {this.props.intl.formatMessage({
              id: "upload > Drop files here...",
            })}
          </DropText>
        </div>
      );
    }
    if (isDragReject) {
      dropDom = (
        <DropText>
          {this.props.intl.formatMessage({
            id: "upload > File is not valid",
          })}
        </DropText>
      );
    }
    return dropDom;
  };

  render() {
    const { acceptMultiple, mimes, fieldName, disabled, maxSize } = this.props;
    return (
      <Dropzone
        onDrop={(acceptedFiles, rejectedFiles) =>
          this.onDrop(acceptedFiles, rejectedFiles, fieldName)
        }
        multiple={acceptMultiple}
        disabled={disabled}
        maxSize={maxSize}
        accept={join(mimes, ",")}
      >
        {({
          getRootProps,
          isDragActive,
          getInputProps,
          // isDragAccept,
          isDragReject,
          // acceptedFiles
        }) => {
          return (
            <DropArea
              isDragActive={isDragActive}
              isDragReject={isDragReject}
              className="drop-area"
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              {this.renderDropAreaText({ isDragActive, isDragReject })}
            </DropArea>
          );
        }}
      </Dropzone>
    );
  }
}
FormUploadFile.propsType = {
  onSubmit: PropsType.func.isRequired,
  acceptMultiple: PropsType.bool,
  disabled: PropsType.bool,
  attachText: PropsType.string,
  isCheckMimesByExt: PropsType.bool,
};

FormUploadFile.defaultProps = {
  acceptMultiple: false,
  attachText: "Attach File",
  disabled: false,
  isCheckMimesByExt: false,
};

export default FormUploadFile;
