import React from "react";

import Table from "components/Table";
import { FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';
import { TrashListIcon } from 'components/CustomIcons';
import { get, values, forEach, isEmpty } from 'lodash';

const AddedStationsList = (props) => {
  const {
    auth,
    addedStations,
    removeStation,
    showHeader,
    showTrashIcon,
    NoStationsSelectedComp,
  } = props;

  let stations = values(addedStations);
  const isEnableEnterprise = get(auth, "info.production_multimarket", false);

  const NoDataComponent = NoStationsSelectedComp || (
    <div className="no-stations-selected">
      <FormattedMessage id="picker > no entities selected" />
    </div>
  );

  const tableColumns = [
    {
      Header: (
        <div className="header">
          <FormattedMessage id="picker > stations > name" />
        </div>
      ),
      id: 'station',
      accessor: (d) => {
        let stationArr = [];
        if (get(d, "call_letters", ""))
          stationArr.push(d.call_letters);
        if (get(d, "name", ""))
          stationArr.push(d.name);
        return stationArr.join(' - ');
      }
    },
    {
      Header: (
        <div className="header">
          <FormattedMessage id="picker > stations > market" />
        </div>
      ),
      id : 'market',
      accessor: d => {
        let markets = [];
        forEach(d.market, market => {
          markets.push(market.title);
        })
        return markets.join();
      },
    },
    {
      Header: (
        <div className="header">
          <FormattedMessage id="picker > stations > format" />
        </div>
      ),
      id: 'format',
      accessor: d => {
        let formats = [];
        forEach(d.format, format => {
          formats.push(format.title);
        })
        return formats.join();
      },
    },
  ];

  if(showTrashIcon){
    tableColumns.push({
      Header: (
        <div className="header last">
          {stations.length}&nbsp;
          {
            isEnableEnterprise ? <FormattedMessage id={`${stations.length === 1 ? "picker > station" : "picker > stations"}`} />  : <FormattedMessage id={`${stations.length === 1 ? "picker > entity" : "picker > entities"}`} /> 
          }
        </div>
      ),
      Cell: ({ original }) => {
        return (
          <Button
              color="link"
              className="trash-icon"
              onClick={ e => removeStation(original)}
          >
            <TrashListIcon color="#795afa"/>
          </Button>
        );
      }
    })
  }
  
  return (
    <Table
      className="table-hidden-checkbox"
      data={stations}
      showPageSizeOptions={false}
      total={stations.length}
      sortable={false}
      resizable={false}
      filterable={false}
      showPagination={false}
      NoDataComponent={NoDataComponent}
      getTheadThProps={(state, rowInfo, column) => {
        return !showHeader ? {
          style: { display: 'none' }
        } : {};
      }}
      getTdProps={(state, {original: station}, column, instance) => {
        return {
          style: {
            color: !isEmpty(station.formatChange) ? "#E40061" : '#111B2B',
          }
        };
      }}
      columns={tableColumns}
    />
  );
};

export default AddedStationsList;
