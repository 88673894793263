import React from "react";
import PropTypes from "prop-types";
function NotificationProgress(props) {
  const { bem, title, total, number, color } = props;
  return (
    <div className={bem.e("notification-progress")}>
      <div className={bem.e("progress-title")}>
        <h3>{title}</h3>
        <span>
          {number}/{total}
        </span>
      </div>
      <div className={bem.e("progress-bar")}>
        <span
          className={bem.e("progress-value")}
          style={{
            width: `${(number / total) * 100}%`,
            backgroundColor: color,
          }}
        ></span>
      </div>
    </div>
  );
}
NotificationProgress.propTypes = {
  total: PropTypes.number.isRequired,
  number: PropTypes.number.isRequired,
};
NotificationProgress.defaultProps = {
  total: 0,
  number: 0,
};
export default NotificationProgress;
