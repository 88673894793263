import React from "react";
import bn from "utils/bemnames";
import { Row, Col, Label, Table } from "reactstrap";
import { FormattedMessage } from "react-intl";
import {
  ApproveIcon,
  ViewIcon,
  DownloadFileIcon,
  SendIcon,
} from "components/CustomIcons";
import classNames from "classnames";
import moment from "moment";
import URL from "utils/urls";
import history from "components/History";
const bem = bn.create("winner-details");
export default function ContestDetails(props) {
  const { item, stationDisplay, dateFormatByServer } = props;
  const onView = () => {
    const params = {
      template_id: item.template_key,
      process_id: item.key,
    };
    history.push(URL.VIEW_PROCESS(params));
  };
  return (
    <div
      className={classNames(
        bem.e("contest-section"),
        bem.e("contest-section-details")
      )}
    >
      <div className={bem.e("contest-section-title")}>
        <FormattedMessage id="winners > contest details" />
      </div>
      <div className={bem.e("contest-section-box")}>
        <Row>
          <Col xs={3} className={bem.e("col-reset")}>
            <div className={bem.e("contest-group")}>
              <Label>
                <FormattedMessage id="winners > date won" />
              </Label>
              <div className={bem.e("contest-group-value")}>
                {moment.utc(item.won_at_ts * 1000).format("L")}
              </div>
            </div>
          </Col>
          <Col xs={3} className={bem.e("col-reset")}>
            <div className={bem.e("contest-group")}>
              <Label>
                <FormattedMessage id="winners > station" />
              </Label>
              <div className={bem.e("contest-group-value")}>
                {stationDisplay}
              </div>
            </div>
          </Col>
          <Col xs={3} className={bem.e("col-reset")}>
            <div className={bem.e("contest-group")}>
              <Label>
                <FormattedMessage id="winners > show" />
              </Label>
              <div className={bem.e("contest-group-value")}>
                {stationDisplay}
              </div>
            </div>
          </Col>
          <Col xs={3} className={bem.e("col-reset")}>
            <div className={bem.e("contest-group")}>
              <Label>
                <FormattedMessage id="winners > flight dates" />
              </Label>
              <div className={bem.e("contest-group-value")}>
                {moment(item.order_start_date).format(dateFormatByServer)}-
                {moment(item.order_end_date).format(dateFormatByServer)}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className={bem.e("col-reset")}>
            <div className={bem.e("contest-group")}>
              <Label>
                <FormattedMessage id="winners > contest details" />
              </Label>
              <div className={bem.e("contest-details-text")}>
                {item.description}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className={bem.e("col-reset")}>
            <Table className={bem.e("contest-table")}>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="winners > form" />
                  </th>
                  <th>
                    <FormattedMessage id="winners > status" />
                  </th>
                  <th className={bem.e("text-center")}>
                    <FormattedMessage id="winners > action" />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span className={bem.e("contest-table-text")}>
                      <FormattedMessage id="winners > release form" />
                    </span>
                  </td>
                  <td>
                    <div className={bem.e("contest-table-status")}>
                      <ApproveIcon />
                      <span className={bem.e("contest-table-text")}>
                        <FormattedMessage id="winners > signed" />
                      </span>
                    </div>
                  </td>
                  <td className={bem.e("text-center")}>
                    <div className={bem.e("contest-table-actions")}>
                      <span onClick={onView}>
                        <ViewIcon />
                      </span>
                      <span>
                        <DownloadFileIcon />
                      </span>
                      <span>
                        <SendIcon />
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    </div>
  );
}
