import React from "react";
import bn from "utils/bemnames";
import { FormattedMessage } from "react-intl";
import Spinner from "components/Spinner";
import classnames from "classnames";
import ConfirmationModal from "components/ConfirmationModal";
import URL from "utils/urls";
import { first, get } from "lodash";
import ClientReassignModal from "components/ClientReassignModal";
import ClientManagerTable from "./ClientManagerTable";
import ClientManagerHeader from "./ClientManagerHeader";
import history from "components/History";
import { PRIVILEGES } from "utils/constants";
import { validatePrivileges } from "utils/helpers";
const bem = bn.create("process-list");

export default function ClientManagerList(props) {
  const {
    clients,
    selectedClients,
    setSelectedClients,
    isOpenClientReassignModal,
    setIsOpenClientReassignModal,
    isOpenConfirmationModal,
    setIsOpenConfirmationModal,
    isOpenConfirmationSuccessModal,
    setIsOpenConfirmationSuccessModal,
    isOpenDeleteConfirmationModal,
    setIsOpenDeleteConfirmationModal,
    // selectedUser,
    setSelectedUser,
    isLoading,
    onSort,
    sorted,
    getMoreClients,
    onRequestDelete,
    onConfirmDelete,
    user,
    dateFormatByServer
  } = props;
  const privileges = get(user, "privileges", []);
  const items = get(clients, "data.items", []);
  const pages = get(clients, "data.pages", 1);
  const page = get(clients, "data.page", 1);
  const users = props.getUsers();
  const onEdit = (item) => {
    history.push(
      URL.CLIENT_MANAGER_FORM({
        action: "edit",
        client_id: get(item, "_source.client_id", null),
      })
    );
  };
  const onView = (item) => {
    history.push({
      pathname: URL.CLIENT_MANAGER_FORM({
        action: "view",
        client_id: get(item, "_source.client_id", null),
      }),
      state: { showCancel: false },
    });
  };
  const onClickItem = (item) => {
    const isEditable = validatePrivileges({
      requires: [PRIVILEGES.EDIT_CLIENT],
      privileges,
      user,
    });
    if (isEditable) {
      history.push(
        URL.CLIENT_MANAGER_FORM({
          action: "edit",
          client_id: get(item, "_source.client_id", null),
        })
      );
    } else {
      history.push({
        pathname: URL.CLIENT_MANAGER_FORM({
          action: "view",
          client_id: get(item, "_source.client_id", null),
        }),
        state: { showCancel: false },
      });
    }
  };
  const totalSelectedClients = selectedClients
    ? Object.values(selectedClients).filter((item) => item).length
    : 0;
  return (
    <div className={classnames("fluid-container", bem.b())}>
      <ClientManagerHeader
        onClearSearch={props.onClearSearch}
        onSearchClicked={props.onSearch}
        searchText={props.searchText}
        setSearchText={props.setSearchText}
      />
      <div className={classnames(bem.e("process-list-container"))}>
        <ClientManagerTable
          items={items}
          bem={bem}
          selectedClients={selectedClients}
          setSelectedClients={setSelectedClients}
          onReassignBulk={(user) => {
            setIsOpenConfirmationModal(true);
            props.setAction("bulk");
            setSelectedUser(user);
          }}
          onReassign={(client) => {
            props.setAction("reassign");
            setIsOpenClientReassignModal(true);
            props.setSelectedClient(client);
          }}
          totalSelectedClients={totalSelectedClients}
          intl={props.intl}
          sort={first(sorted)}
          actionSort={onSort}
          onClickItem={onClickItem}
          onEdit={onEdit}
          onView={onView}
          onDelete={onRequestDelete}
          users={users}
          hasMoreItems={pages > page && !isLoading}
          handleLoadMore={() => getMoreClients(page)}
          isLoading={isLoading}
          dateFormatByServer={dateFormatByServer}
        />
      </div>
      <Spinner isLoading={isLoading} />
      <ClientReassignModal
        isOpen={isOpenClientReassignModal}
        users={users}
        onToggle={() =>
          setIsOpenClientReassignModal(!isOpenClientReassignModal)
        }
        onSubmit={(user) => {
          setSelectedUser(user.data);
          setIsOpenClientReassignModal(false);
          setIsOpenConfirmationModal(true);
        }}
      />
      {/* client assign confirmation modal */}
      <ConfirmationModal
        isOpen={isOpenConfirmationModal}
        onToggle={() => setIsOpenConfirmationModal(!isOpenConfirmationModal)}
        onCancel={() => {
          setIsOpenConfirmationModal(false);
          props.setSelectedClient(null);
          props.setSelectedClients(null);
        }}
        cancelTitle={<FormattedMessage id="client manager > button cancel" />}
        title={props.intl.formatMessage(
          {
            id:
              totalSelectedClients > 1
                ? "client manager > are you sure you want to reassign these clients"
                : "client manager > are you sure you want to reassign this client",
          },
          {
            TOTAL: props.action === "bulk" ? totalSelectedClients : 1,
          }
        )}
        onConfirm={props.onReassign}
        isCloseOutside={false}
      />
      {/* client assign success modal */}
      <ConfirmationModal
        isOpen={isOpenConfirmationSuccessModal}
        state="success"
        onToggle={() =>
          setIsOpenConfirmationSuccessModal(!isOpenConfirmationModal)
        }
        onCancel={() => setIsOpenConfirmationSuccessModal(false)}
        title={<FormattedMessage id="client manager > client reassigned" />}
        onConfirm={() => {
          setIsOpenConfirmationSuccessModal(false);
        }}
      />
      {/* client delete confirm modal */}
      <ConfirmationModal
        isOpen={isOpenDeleteConfirmationModal}
        onToggle={() =>
          setIsOpenDeleteConfirmationModal(!isOpenDeleteConfirmationModal)
        }
        onCancel={() => setIsOpenDeleteConfirmationModal(false)}
        title={
          <FormattedMessage id="client manager > are you sure you want to delete this client" />
        }
        onConfirm={() => {
          onConfirmDelete();
          setIsOpenDeleteConfirmationModal(false);
        }}
        isCloseOutside={false}
      />
    </div>
  );
}
