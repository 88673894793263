import View from "./view";
import { connect } from "react-redux";
import { compose, lifecycle, withState, withHandlers } from "recompose";
import { injectIntl } from "react-intl";
import { getChannels } from "store/actions/channels";
import { filter, get, uniqBy } from "lodash";
export default injectIntl(
  compose(
    connect(
      ({ channels, auth, servers }) => {
        return {
          auth,
          channels: filter(
            channels.channels.data.data,
            (channel) => channel._source
          ).map((channel) => ({
            ...channel._source,
          })),
          isDirty: get(channels, "channels.is_dirty", false),
          isFetch:
            get(channels, "channels.is_dirty", false) ||
            !get(channels, "channels.data.page"),
          dateFormatByServer: get(
            servers,
            "currentServer.data.date_format",
            "MM/DD/YYYY"
          ),
        };
      },
      { getChannels }
    ),
    withState("currentChannelDelete", "setCurrentChannelDelete", null),
    withState("isOpenConfirmationModal", "setIsOpenConfirmationModal", false),
    withState("isOpenFlightSchedule", "setIsOpenFlightSchedule", false),
    withHandlers({
      // get all approvers for all steps
      getApprovers: () => (marketingChannels) => {
        let approvals = [];
        marketingChannels.forEach((channel) => {
          const { workflow } = channel;
          if (workflow) {
            workflow.steps
              .filter((item) => item.approvals)
              .map((item) => item.approvals)
              .forEach((item) => {
                approvals = [...approvals, ...item];
              });
          }
        });
        let approvers = [];
        approvals.forEach((item) => {
          approvers = [...approvers, ...item.users];
        });
        approvers = uniqBy(approvers, "id");
        return approvers;
      },
      onDeleteChannel: ({
        valueChanged,
        step,
        template,
        user,
        process,
        setCurrentChannelDelete,
      }) => (channel, selectedChannels) => {
        const spots = get(process, "data.fields.spots.value", []);
        const spotsField = step.fields.find((f) => f.field === "spots");

        const newSelectedChannels = selectedChannels.filter(
          (selected) => selected.id !== channel.id
        );
        const marketing_channels = step.fields.find(
          (f) => f.field === "marketing_channels"
        );
        let newSpots = filter(spots, (item) => item !== channel["es_id"]);
        valueChanged(marketing_channels, process, template, user, {
          value: newSelectedChannels,
        });
        valueChanged(spotsField, process, template, user, {
          value: newSpots,
        });
        setCurrentChannelDelete(null);
      },
    }),
    lifecycle({
      componentDidMount() {
        const template = this.props.template;
        if (this.props.isFetch && get(template, "key") === "digital") {
          this.props.getChannels({ list_channels: true });
        }
      },
      componentDidUpdate(prevProps) {
        const template = this.props.template;
        if (
          this.props.isDirty !== prevProps.isDirty &&
          this.props.isDirty &&
          get(template, "key") === "digital"
        ) {
          this.props.getChannels({ list_channels: true });
        }
      },
    })
  )(View)
);
