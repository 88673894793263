import { FormattedMessage } from "react-intl";
import React from "react";
import { get, map, delay, find } from "lodash";
import history from "components/History";

const recordScript = {
  process_step_index: 3,
  key: "record_script",
  title: <FormattedMessage id="process > title record" />,
  caption_color: "#CC1EE6",
  description: null,
  isCollapsible: false,
  step_roles: [5],
  step_viewers_roles: [6, 5, 4, 7, 2],
  step_editors_roles: [5, 4, 7],
  step_viewers_privileges: {
    or: ["submit_production_recording", "edit_all_production_orders"],
  },
  step_editors_privileges: {
    or: ["submit_production_recording", "edit_all_production_orders"],
  },
  is_check_hidden_tab: true,
  is_hidden: function(user, process, step) {
    const contractStatus = get(
      process,
      "data.fields.contract_status.value",
      ""
    );
    return contractStatus !== "Production Provided - Needs tags";
  },
  status: "initial",
  workflow: {
    title: "record script",
    icon: "RecordIcon",
  },
  className: "production-provided-recording",
  is_approval_step: true,
  fields: [
    {
      component: "production_recording",
    },
    {
      field: "spot_info",
      validation: function(step, item, process, template, user, value) {
        let isValid = true;
        const spotInfo = map(value, (spot) => {
          const isUserAssignedProducers = !!find(
            get(spot, "role_producer.users", []),
            (item) => {
              if (item.id === user.id) return true;
              return false;
            }
          );
          let error = {};
          if (!spot["final_recording_path"] && isUserAssignedProducers) {
            isValid = false;
            error.final_recording_path = "Please upload the recording";
            error.uuid = spot.uuid;
          }
          return error;
        });
        if (isValid) return true;
        return spotInfo;
      },
      mandatory: true,
    },
    {
      text: <FormattedMessage id="process > button submit" />,
      component: "button",
      key: "save_button",
      props: { color: "blue" },
      container: "bottom_buttons",
      on_click: {
        validate: true,
        save: true,
        go_to: function(_this, step, item, process, template, user) {
          if (!user.return_to_current_tasks) return;
          // set delay when after success message
          delay(() => {
            if (_this.props.setSidebarActive) {
              _this.props.setSidebarActive(`/processes/my_action_items`);
            }
            history.replace(`/processes/my_action_items`);
          }, 4000);
        },
      },
      fields: [
        {
          key: "record_script_status",
          value: (user, template, process, step, item, next_users) => {
            const spotInfo = get(process, "data.fields.spot_info.value", []);

            const validSpots = spotInfo.filter(
              (spot) =>
                spot.contract_status === "Production Provided - Needs tags"
            );
            const completedSpots = validSpots.filter(
              (spot) => spot.record_script_status === "completed"
            );
            if (completedSpots.length === validSpots.length) {
              return "completed";
            }
            return "";
          },
        },
        {
          key: "process_step",
          value: "first_undone",
        },
        {
          key: "next_users",
          value: (user, template, process, step, item, next_users) => {
            let dubberStatus = get(
              process,
              "data.fields.dub_script_status.value",
              ""
            );
            const roleProducers = get(
              process,
              "data.fields.role_producer.value.users",
              []
            ).map((i) => Number(i.id));
            const roleDubbers = get(
              process,
              "data.fields.role_dubber.value.users",
              []
            ).map((i) => Number(i.id));

            const spotInfo = get(process, "data.fields.spot_info.value", []);

            const validSpots = spotInfo.filter(
              (spot) =>
                spot.contract_status === "Production Provided - Needs tags"
            );
            const completedSpots = validSpots.filter(
              (spot) => spot.record_script_status === "completed"
            );
            if (completedSpots.length === validSpots.length) {
              // assigned to dubbers
              return dubberStatus !== "completed" ? roleDubbers : [];
            }
            return roleProducers;
          },
        },
        {
          key: "process_step",
          value: (user, template, process, step, item) => {
            const spotInfo = get(process, "data.fields.spot_info.value", []);

            const validSpots = spotInfo.filter(
              (spot) =>
                spot.contract_status === "Production Provided - Needs tags"
            );
            const completedSpots = validSpots.filter(
              (spot) => spot.record_script_status === "completed"
            );
            if (completedSpots.length === validSpots.length) {
              return "first_undone";
            }
            return get(process, "data.fields.process_step.value");
          },
        },
      ],
      confirmation_dialog_props: {
        title: (
          <FormattedMessage id="process > description confirm submit recording" />
        ),
        confirmTitle: <FormattedMessage id="process > button yes" />,
        cancelTitle: <FormattedMessage id="process > button no" />,
      },
      success_dialog_props: {
        title: <FormattedMessage id="process > title recording submitted" />,
        state: "success",
      },
    },
  ],
};
export default recordScript;
