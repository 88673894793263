import * as React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { find, get, lowerCase, map, has } from "lodash";
import { FormattedMessage } from "react-intl";
import { formatDisplayDate } from "utils/helpers";
import bn from "utils/bemnames";
import "moment-timezone";
import { Row, Col, Form, Label } from "reactstrap";
import NumberFormat from "react-number-format";
import { ShadowBox, SectionTitle, SectionFields } from "components/Elements";
import { PhoneNumberFormat, RotationFormatSuffix } from "utils/config";
import TrafficDetails from "components/TrafficDetails";
const bem = bn.create("digital-order-details");
class Traffic extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			fieldRefs: null
		};
	}
	static propTypes = {
		mode: PropTypes.string
	};

	static defaultProps = {
		mode: "edit"
	};

	getFieldRefs() {
		if (this.state.fieldRefs) return this.state.fieldRefs;
		let { process, template, step } = this.props;

		step = template.steps.order_details;
		let client_name = step.fields.find(f => f.field === "client_name");
		let client_id = step.fields.find(f => f.field === "client_id");
		let contract_start_date = step.fields.find(
			f => f.field === "contract_start_date"
		);
		let contract_end_date = step.fields.find(
			f => f.field === "contract_end_date"
		);
		let contract_client_contact = step.fields.find(
			f => f.field === "contract_client_contact"
		);
		let contract_client_email = step.fields.find(
			f => f.field === "contract_client_email"
		);
		let contract_client_nickname = step.fields.find(
			f => f.field === "contract_client_nickname"
		);
		let contract_client_phone = step.fields.find(
			f => f.field === "contract_client_phone"
		);
		let requested_by = step.fields.find(f => f.field === "requested_by");
		let contract_stations = step.fields.find(
			f => f.field === "contract_stations"
		);
		let contract_stations_order_number_array = step.fields.find(
			f => f.field === "contract_stations_order_number_array"
		);
		let contract_category = step.fields.find(
			f => f.field === "contract_category"
		);
		let order_title = step.fields.find(f => f.field === "order_title");
		let order_start_date = step.fields.find(
			f => f.field === "order_start_date"
		);
		let order_end_date = step.fields.find(
			f => f.field === "order_end_date"
		);

		let timezone = step.fields.find(f => f.field === "timezone");
		let contractSaved = process.data.fields.contract_id !== undefined;
		let payload = {
			client_name,
			client_id,
			contract_start_date,
			contract_end_date,
			contract_client_contact,
			contract_client_email,
			contract_client_nickname,
			contract_client_phone,
			contract_stations,
			contract_stations_order_number_array,
			contract_category,
			order_title,
			order_start_date,
			order_end_date,
			contractSaved,
			timezone,
			requested_by
		};
		this.setState({ fieldRefs: payload });
		return payload;
	}
	formattedValueOrEmpty(value, item) {
		if (get(item, "formatted")) {
			const formatted = get(item, "formatted");
			switch (formatted) {
				case "number":
					return (
						<NumberFormat
							prefix={get(item, "prefix", null)}
							value={value}
							displayType={"text"}
							thousandSeparator={true}
						/>
					);
				case "phone":
					return (
						<NumberFormat
							value={value}
							displayType={"text"}
							format={PhoneNumberFormat}
						/>
					);
				case "percentage":
					return (
						<NumberFormat
							value={value}
							displayType={"text"}
							suffix={get(item, "suffix", RotationFormatSuffix)}
						/>
					);
				default:
					break;
			}
		}
		return value;
	}

	valueOrEmpty(item) {
		let { process, intl } = this.props;
		let value = null;
		if(has(process, `data.fields.${item.field}.value.name`)){
			value = process.data.fields[item.field].value.name || '';
		}else if(get(process, `data.fields.${item.field}.value`)){
			value = process.data.fields[item.field].value;
		}else{
			return intl.formatMessage({id: "process > none"});
		}
		return this.formattedValueOrEmpty(value, item);
	}

	renderView() {
		let { process, template, user, info, step, buttonClicked, valueChanged, validationState, shouldBlockNavigation, setShouldBlockNavigation, setBlockNavigationAction } = this.props;
		let {
			client_name,
			contract_start_date,
			contract_end_date,
			contract_client_email,
			contract_client_nickname,
			contract_category
		} = this.getFieldRefs();
        let valueOrEmpty = this.valueOrEmpty.bind(this);
        //contract_stations_order_number
				let orderNumbers = [];
				if(process.data.fields.contract_stations_order_number_array !== undefined) {
					
					let contract_stations_order_number_field = process.data.fields['contract_stations_order_number_array'];
					if(contract_stations_order_number_field.value !== undefined) {
						contract_stations_order_number_field.value.forEach((item) => {
							orderNumbers.push(item.number);
						});
					}
				}else{
					orderNumbers = get(process, 'data.fields.order_stations.value', []).filter(station => get(process, `data.fields.contract_stations_order_number.value.${station}`)).map(station => get(process, `data.fields.contract_stations_order_number.value.${station}`));
				}
				const stationsData = get(info, 'stations', []);
				const orderStations = get(process, "data.fields.order_stations.value", []).map(item => {
					return find(stationsData, i => i.station_key === item) || item;
				}).map(i => i.name);
            return (
			<div className="text-left">
				<Form>
					<ShadowBox className={bem.e("section")}>
						<SectionTitle className={bem.e("section-title")}>
							<FormattedMessage id="process > title order information" />
						</SectionTitle>
						<SectionFields className={bem.e("section-fields")}>
							<Row>
								<Col>
									<Label className={bem.e("view-field-label")}>
										<FormattedMessage id="process > field order number" />
									</Label>
									<span className="order-view-value">
										{orderNumbers.length > 0 ? orderNumbers.join(', ') : this.props.intl.formatMessage({id: "process > none"})}
									</span>
								</Col>
								<Col>
									<Label className={bem.e("view-field-label")}>{contract_start_date.title}</Label>
									<span className="order-view-value">
										{formatDisplayDate(
											valueOrEmpty(contract_start_date)
										)}
									</span>
								</Col>
							</Row>
							<Row>
								<Col>
									<Label className={bem.e("view-field-label")}>
										<FormattedMessage id="process > client name" />
									</Label>
									<span className="order-view-value">
										{valueOrEmpty(client_name)}
									</span>
								</Col>
								<Col>
									<Label className={bem.e("view-field-label")}>{contract_end_date.title}</Label>
									<span className="order-view-value">
										{formatDisplayDate(
											valueOrEmpty(contract_end_date)
										)}
									</span>
								</Col>
							</Row>
							<Row>
								<Col>
									<Label className={bem.e("view-field-label")}>
										{contract_client_nickname.title}
									</Label>
									<span className="order-view-value">
										{valueOrEmpty(contract_client_nickname)}
									</span>
								</Col>
								<Col>
									<Label className={bem.e("view-field-label")}>
										<FormattedMessage id="process > field product category" />
									</Label>
									<span className="order-view-value">
										<FormattedMessage
											id={`process > option label ${lowerCase(
												valueOrEmpty(contract_category)
											)}`}
										/>
									</span>
								</Col>
							</Row>
							<Row>
								<Col>
									<Label className={bem.e("view-field-label")}>{contract_client_email.title}</Label>
									<span className="order-view-value">
										{valueOrEmpty(contract_client_email)}
									</span>
								</Col>
								<Col>
									<Label className={bem.e("view-field-label")}>
										<FormattedMessage id="process > field stations" />
									</Label>
									<div className="order-stations-view">
										{orderStations.length > 0 ? map(orderStations,
											(item, index) => (
												<span
													key={index}
													className="order-station-item"
												>
													{item}
												</span>
											)
										) : this.props.intl.formatMessage({id: "process > none"})}
									</div>
								</Col>
							</Row>
						</SectionFields>
					</ShadowBox>
					<TrafficDetails
						step={step}
						process={process}
						template={template}
						isTrafficView={true}
						isView={true}
						user={user}
            buttonClicked={buttonClicked}
            valueChanged={valueChanged}
						validationState={validationState}
						shouldBlockNavigation={shouldBlockNavigation}
						setShouldBlockNavigation={setShouldBlockNavigation}
						setBlockNavigationAction={setBlockNavigationAction}
					/>
				</Form>
			</div>
		);
	}

	render() {
		let { process } = this.props;
		if (
			!process.data.fields ||
			Object.keys(process.data.fields).length === 0
		)
			return null;
		return (
			<span className={classnames("orderForm", bem.b())}>
				{this.renderView()}
			</span>
		);
	}
}
Traffic.defaultProps = {
	isShowApprove: true,
	isTrafficView: false,
	isShowViewModeButtons: true,
	mode: "edit"
};
export default Traffic;
