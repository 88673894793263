import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalHeader, Button, ModalFooter } from "reactstrap";
import classnames from "classnames";
import bn from "utils/bemnames";
import { FormattedMessage } from "react-intl";
import checkedIcon from "assets/img/icons/checked.png";
import { get, isEmpty, isFunction } from "lodash";
import { delay } from "utils/helpers";

const bem = bn.create("confirmation-modal");
const ConfirmationModal = (props) => {
  const [clicking, setClicking] = useState(false);
  const onButtonClick = async (callback) => {
    if (clicking) return;
    setClicking(true);
    callback();
    await delay(2000);
    setClicking(false);
  };
  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.isCloseOutside ? props.onToggle : null}
      className={classnames(props.className, bem.b(), [
        bem.e(get(props, "state", "")),
      ])}
      centered={props.centered}
      onOpened={() => {
        if (
          get(props, "isOpen") &&
          get(props, "state") === "success" &&
          isFunction(props.onCancel)
        ) {
          setTimeout(() => {
            props.onCancel();
          }, 1000);
        }
      }}
    >
      {!isEmpty(props.title) && (
        <ModalHeader toggle={props.onToggle} className={bem.e("header")}>
          {props.state === "success" && (
            <div className={bem.e("checked-icon")}>
              <img
                src={checkedIcon}
                alt=""
                style={{ width: "80px", height: "74px" }}
              />
            </div>
          )}
          <div style={props.titleStyle}>{props.title}</div>
        </ModalHeader>
      )}
      {!!props.description && (
        <ModalBody
          className={classnames(bem.e("body"), { mtop: !isEmpty(props.title) })}
        >
          {props.description}
        </ModalBody>
      )}
      <ModalFooter className={bem.e("footer")}>
        {props.onConfirm && (
          <Button
            color="blue"
            className={classnames("btn-radius", bem.e("confirm-button"))}
            onClick={() => {
              onButtonClick(props.onConfirm);
            }}
            disabled={props.isConfirmButtonDisabled || clicking}
          >
            {props.confirmTitle}
          </Button>
        )}
        {props.discardTitle && (
          <Button
            color="blue"
            className={classnames("btn-radius", bem.e("confirm-button"))}
            disabled={clicking}
            onClick={() => {
              onButtonClick(props.onDiscard);
            }}
          >
            {props.discardTitle}
          </Button>
        )}
        {props.cancelTitle && (
          <Button
            color="blue"
            outline={!props.cancelBlueButton ? true : false}
            className={classnames(
              "btn-radius",
              props.cancelBlueButton
                ? bem.e("confirm-button")
                : bem.e("cancel-button")
            )}
            disabled={clicking}
            onClick={() => {
              onButtonClick(props.onCancel);
            }}
          >
            {props.cancelTitle}
          </Button>
        )}
      </ModalFooter>
      {props.descriptionBottom && (
        <div className={bem.e("description-bottom")}>
          {props.descriptionBottom}
        </div>
      )}
    </Modal>
  );
};

ConfirmationModal.prototype = {
  onToggle: PropTypes.func,
  onConfirm: PropTypes.func,
  onDiscard: PropTypes.func,
  onCancel: PropTypes.func,
  confirmTitle: PropTypes.string,
  cancelTitle: PropTypes.string,
  discardTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  className: PropTypes.string,
  isCloseOutside: PropTypes.bool,
  centered: PropTypes.bool,
};

ConfirmationModal.defaultProps = {
  onToggle: () => {},
  onCancel: () => {},
  onDiscard: () => {},
  confirmTitle: <FormattedMessage id="confirm modal > button yes" />,
  cancelTitle: <FormattedMessage id="confirm modal > button no" />,
  discardTitle: "",
  title: <FormattedMessage id="confirm modal > title are you ready" />,
  description: "",
  isCloseOutside: true,
  centered: false,
};

export default ConfirmationModal;
