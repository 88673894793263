import React from 'react';
import styled from "styled-components";
import { Button } from "reactstrap";


const ButtonCircle = ({onClick, children, width, height, background, shadow, disabled}) => {
	return (
		<Button color="link"
				onClick={onClick} 
				disabled={disabled}
				style={{
					width: width, 
					minWidth:width,
					height: height, 
					backgroundColor: background, 
					boxShadow: shadow,
					borderRadius: '100px',
					border:'none',
					justifyContent: 'center',
					alignItems: 'center',
				}}
		>
        	{children}
      	</Button>
	);
}

export default ButtonCircle;