import React from "react";
import bn from "utils/bemnames";
import classNames from "classnames";
import {
  FacebookSocialIcon,
  TwitterSocialIcon,
  YoutubeSocialIcon,
  InstagramSocialIcon,
  SnapchatSocialIcon,
  TiktokSocialIcon,
  LinkedinSocialIcon,
  PinterestSocialIcon,
  DefaultSocialIcon,
} from "components/CustomIcons";
import { get, map } from "lodash";
import PropTypes from "prop-types";
const bem = bn.create("jock-console");
const icons = {
  facebook: <FacebookSocialIcon />,
  twitter: <TwitterSocialIcon />,
  youtube: <YoutubeSocialIcon />,
  instagram: <InstagramSocialIcon />,
  snapchat: <SnapchatSocialIcon />,
  tiktok: <TiktokSocialIcon />,
  linkedin: <LinkedinSocialIcon />,
  pinterest: <PinterestSocialIcon />,
  default: <DefaultSocialIcon />,
};
function SocialItem(props) {
  const { item } = props;

  return (
    <div className={bem.e("social-item")}>
      <div className={bem.e("social-icon")}>{icons[item.label]}</div>
      <a href={item.url} target="__blank" className={bem.e("social-link")}>
        {item.url}
      </a>
    </div>
  );
}
export default function SocialMedia(props) {
  const { widget, isMultiView } = props;
  const links = get(widget, "fields.links", []).filter((item) => item.url);
  return (
    <div
      className={classNames(
        bem.e("social-links"),
        bem.e("widget-detail-padding")
      )}
    >
      <div
        className={classNames(bem.e("social-head-title"), {
          [bem.e("head-title-multi-view")]: isMultiView,
        })}
        style={{
          backgroundColor: isMultiView ? widget.color : "",
        }}
      >
        {get(widget, "name", "")}
      </div>
      <div className={bem.e("social-list")}>
        {map(links, (item, index) => (
          <SocialItem key={index} item={item} />
        ))}
      </div>
    </div>
  );
}
SocialMedia.defaultProps = {
  isMultiView: false,
  widget: {},
};
SocialMedia.propsTypes = {
  isMultiView: PropTypes.bool,
  widget: PropTypes.object.isRequired,
};
