import React from "react";
import PropTypes from "prop-types";
import { validatePrivileges } from "utils/helpers";
/**
 * This Component is used to handle privileges for logged in user
 * It expect `requires` prop which will defined if user is valid for given child component or not
 * `requires` should be either array, object or function.
 * 1. If array is passed then all privileges will be considered as required to able to access child component
 * 2. For object, below is the structure
 * {
 * 	"and": [<LIST_OF_PRIVILEGES>], // all privileges are required from array to able to access child component
 * 	"or": [<LIST_OF_PRIVILEGES>] // any of one privilege is required from array to able to access child component
 * }
 * 3. Function will pass privileges as a parameter and it should return true/false based on valid/invalid user
 * ----------
 * - Children can be react component or render prop function
 * - If you use render prop function, function will pass, `privileges`, `requires`, `isValid` as parameters.
 *
 * Examples
 * 1. `requires` can be array
 * <PrivilegedComponent requires={["ADD_SERVERS","EDIT_SERVERS"]}>
 * 	<ChildrenComponent />
 * </PrivilegedComponent>
 *
 * 2. `requires` can be object
 * <PrivilegedComponent requires={{and:["ADD_SERVERS","EDIT_SERVERS"],or:["VIEW_SERVERS"]}}>
 * 	<ChildrenComponent />
 * </PrivilegedComponent>
 *
 * 3. `requires` can be function
 * <PrivilegedComponent requires={(privileges) => { ...logic... return true }}>
 * 	<ChildrenComponent />
 * </PrivilegedComponent>
 *
 * 4. `children` can be render function
 * <PrivilegedComponent requires={(privileges) => { ...logic... return true }}>
 * 	{({privileges, requires, isValid}) => {
 * 		if(isValid) {
 * 			return <AuthComponent />
 * 		} else {
 * 			return <UnAuthComponent />
 * 		}
 * 	}}
 * </PrivilegedComponent>
 */

class PrivilegedComponent extends React.Component {
  static propTypes = {
    privileges: PropTypes.arrayOf(PropTypes.string),
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    requires: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.shape({
        and: PropTypes.arrayOf(PropTypes.string),
        or: PropTypes.arrayOf(PropTypes.string)
      }),
      PropTypes.func
    ]).isRequired
  };

  static defaultProps = {
    requires: []
  };

  
  render() {
    const { children, requires, privileges, user } = this.props;
    const isValid = validatePrivileges({
      requires,
      privileges,
      user
    });
    let childrenDom = null;
    if (typeof children === "function") {
      childrenDom = children({ privileges, requires, isValid });
    } else if (isValid) {
      childrenDom = children;
    }
    return <React.Fragment>{childrenDom}</React.Fragment>;
  }
}

export default PrivilegedComponent;
