import NotificationsPusher from "./view";
import { connect } from "react-redux";
import { compose } from "recompose";
import { saveFormValues, sendMessage } from "store/actions/processes";
import { get } from "lodash";

export default compose(
  connect(
    (state, props) => ({
      auth: state.auth,
      user: state.auth.user,
      users: get(state, "auth.info.users", []),
      messages: state.messages.messages,
      ...props,
    }),
    {
      saveFormValues,
      sendMessage,
    }
  )
)(NotificationsPusher);
