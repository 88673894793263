import * as Types from "store/types/roles";
import Api from "utils/api";
import * as Const from "utils/constants";
import { userInfo } from "store/actions/auth";
import isFunction from "lodash/isFunction";
import queryString from "query-string";
import get from "lodash/get";

export const addRole = (data, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.ADD_ROLE_REQUEST });
    let response = await Api.doCall(Const.GET_ROLES(), "POST", data);
    if (response.status === 200) {
      if (!response.data.error) {
        dispatch({ type: Types.ADD_ROLE_SUCCESS, payload: data });
        dispatch(userInfo());
        if (isFunction(cb)) cb(true);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.ADD_ROLE_ERROR, payload: errorStr });
  if (isFunction(cb)) cb(false);
};

export const updateRole = (id, data, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.UPDATE_ROLE_REQUEST });
    let response = await Api.doCall(`${Const.GET_ROLES()}/${id}`, "PUT", data);
    if (response.status === 200) {
      if (!response.data.error) {
        dispatch({ type: Types.UPDATE_ROLE_SUCCESS });
        dispatch(userInfo());
        if (isFunction(cb)) cb(true);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.UPDATE_ROLE_ERROR, payload: errorStr });
  if (isFunction(cb)) cb(false);
};

export const deleteRole = (id, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.DELETE_ROLE_REQUEST });
    let response = await Api.doCall(`${Const.GET_ROLES()}/${id}`, "DELETE");
    if (response.status === 200) {
      if (!response.data.error) {
        dispatch({ type: Types.DELETE_ROLE_SUCCESS });
        dispatch(userInfo());
        if (isFunction(cb)) cb(true);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.UPDATE_ROLE_ERROR, payload: errorStr });
  if (isFunction(cb)) cb(false);
};

export const getRoles = (data, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.FETCH_ROLES_REQUEST, payload: data });
    const response = await Api.doCall(
      `${Const.GET_ROLES()}?${queryString.stringify(data)}`,
      "GET"
    );
    if (response.status === 200) {
      if (!response.error) {
        dispatch({
          type: Types.FETCH_ROLES_SUCCESS,
          payload: response.data.data,
        });
        if (cb) cb(response);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.FETCH_ROLES_ERROR, payload: errorStr });
};

export const updateOrderRoles = (data, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    const sortIds = data.map((item) => item.id);

    dispatch({ type: Types.UPDATE_ORDER_ROLES_REQUEST, payload: data });
    const response = await Api.doCall(
      `${Const.GET_ROLES()}/sort`,
      "POST",
      sortIds
    );
    if (response.status === 200) {
      if (!response.error) {
        dispatch({
          type: Types.UPDATE_ORDER_ROLES_SUCCESS,
          payload: data,
        });
        if (cb) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.UPDATE_ORDER_ROLES_ERROR, payload: errorStr });
};

export const toggleRoleActivate = (data, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.UPDATE_ROLE_ACTIVATE_REQUEST, payload: data });
    const response = await Api.doCall(
      `${Const.GET_ROLES()}/${data.id}/${
        data.active ? "activate" : "deactivate"
      }`,
      "POST"
    );

    if (response.status === 200) {
      if (!response.error) {
        dispatch({
          type: Types.UPDATE_ROLE_ACTIVATE_SUCCESS,
          payload: data,
        });
        dispatch(userInfo());
        if (cb) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.UPDATE_ROLE_ACTIVATE_ERROR, payload: errorStr });
};

export const cloneRole = (data, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.CLONE_ROLE_REQUEST, payload: data });
    const response = await Api.doCall(
      `${Const.GET_ROLES()}/${data.id}/clone`,
      "POST",
      data
    );
    if (response.status === 200) {
      if (!response.error) {
        dispatch({
          type: Types.CLONE_ROLE_SUCCESS,
          payload: data,
        });
        dispatch(userInfo());
        if (cb) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.CLONE_ROLE_ERROR, payload: errorStr });
};

export const getRolesProductionTeam = (cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.FETCH_ROLES_PRODUCTION_TEAM_REQUEST });
    const response = await Api.doCall(
      `${Const.GET_ROLES()}/production_team`,
      "GET",
      
    );
    if (response.status === 200) {
      if (!response.error) {
        dispatch({
          type: Types.FETCH_ROLES_PRODUCTION_TEAM_SUCCESS,
          payload: get(response, 'data.data', []),
        });
        if (cb) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.FETCH_ROLES_PRODUCTION_TEAM_ERROR, payload: errorStr });
};