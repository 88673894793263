import React from "react";
import classnames from "classnames";
import history from "components/History";
import URL from "utils/urls";
import ConfirmationModal from "components/ConfirmationModal";
import { DownIcon } from "components/CustomIcons";
import { ChevronWrapper } from "components/Elements";
import { get, keys, map, omit, sum, find } from "lodash";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { Asterisk } from "components/Elements";
import Checkbox from "components/Checkbox";
import { Row, Col, Button, Label, FormGroup } from "reactstrap";
import { categoryList, fulfillmentList, allocationList } from "utils/config";
import AllocationTable from "./AllocationTable";
import FulfillDigital from "./FulfillDigital";
import FulfillLocation from "./FulfillLocation";
import FulfillMailOut from "./FulfillMailOut";
import { getItemizedQuantityTotal } from "../view";
const PrizeFormView = ({
  bem,
  prize,
  intl,
  locations,
  auth,
  checkCanItemize,
  dateFormatByServer,
  ...rest
}) => {
  const type = find(categoryList, (item) => item.value === prize.category);
  const fulfillment = find(
    fulfillmentList,
    (item) => item.value === prize.fulfillment
  );
  const allocationData = find(
    allocationList,
    (item) => prize.allocation === item.value
  );
  const total = prize.quantity;
  const stationsNumber = get(prize, "stations_number", {});
  const stationsNumberKeys = keys(stationsNumber);
  const stationsQuantity = get(prize, "stations_quantity")
    ? get(prize, "stations_quantity")
    : stationsNumberKeys.map((key) => ({
        station: key,
        quantity: stationsNumber[key],
      }));
  const canItemize = checkCanItemize(prize.category);
  const isAssigned = prize.allocation === "assigned";
  const isVariableCash = prize.category === "variable_cash";
  const isItemized = prize.itemize;

  const stationsQuantityPrice = getItemizedQuantityTotal(
    prize.stations_itemized_quantity
  );
  const stationsNumberAllocation = sum(
    stationsQuantity.map((stationQuantity) => Number(stationQuantity.quantity))
  );
  const allocation =
    isItemized && isAssigned && canItemize
      ? stationsQuantityPrice
      : stationsNumberAllocation;

  const unAllocation = total - allocation;
  const locationOptions = map(get(locations, "data", []), (item) => ({
    label: item.name,
    value: item.id,
    data: omit(item, ["id", "active", "name"]),
  }));

  const itemizedQuantities = get(prize, "stations_itemized_quantity", []);
  const getStationsItemizedQuantityValue = (station, index) => {
    return itemizedQuantities[station]
      ? itemizedQuantities[station][index]
      : "";
  };
  const tags_string = get(prize, "tags_string")
    ? get(prize, "tags_string")
    : get(prize, "tags.length") > 0
    ? map(prize.tags, (item) => item.title).join(", ")
    : "";
  const stationsData = get(auth, "info.stations", []);
  const findStation = (item) =>
    find(
      stationsData,
      (i) => i.station_key === item || i.call_letters === item
    );
  const prizeStations = get(prize, "stations", []).map((item) => {
    const station = findStation(item);
    return get(station, "name", item);
  });
  const serverCountry = get(auth, "info.server_country", "US");
  return (
    <React.Fragment>
      <div className={bem.e("content-view")}>
        {/* Audience */}
        <div className={bem.e("section-form")}>
          <div className={bem.e("section-title")}>
            <FormattedMessage id="prizes > audience" />
          </div>
          <div
            className={classnames(bem.e("fields"), bem.e("allocation-fields"))}
          >
            <Row>
              <Col className={bem.e("col-reset")} xs={6}>
                <FormGroup>
                  <Label>
                    <FormattedMessage id="prizes > form stations" />
                  </Label>
                  <div className={bem.e("prize-value-view")}>
                    {prizeStations.length > 0 ? (
                      prizeStations.join(", ")
                    ) : (
                      <FormattedMessage id="prizes > none" />
                    )}
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </div>
        </div>
        {/* Order Details */}
        <div className={bem.e("section-form")}>
          <div className={bem.e("section-title")}>
            <FormattedMessage id="prizes > item details" />
          </div>
          <div className={classnames(bem.e("fields"), bem.e("item-fields"))}>
            <Row>
              <Col className={bem.e("col-reset")}>
                <FormGroup>
                  <Label>
                    <FormattedMessage id="prizes > form title" />
                  </Label>
                  <div className={bem.e("prize-value-view")}>
                    {get(prize, "title") ? (
                      get(prize, "title")
                    ) : (
                      <FormattedMessage id="prizes > none" />
                    )}
                  </div>
                </FormGroup>
              </Col>
              <Col className={bem.e("col-reset")}>
                <FormGroup>
                  <Label>
                    <FormattedMessage id="prizes > form subtitle" />
                  </Label>
                  <div className={bem.e("prize-value-view")}>
                    {get(prize, "subtitle") ? (
                      get(prize, "subtitle")
                    ) : (
                      <FormattedMessage id="prizes > none" />
                    )}
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <Label>
                <FormattedMessage id="prizes > form description" />
              </Label>
              <div className={bem.e("prize-value-view")}>
                {get(prize, "description") ? (
                  get(prize, "description")
                ) : (
                  <FormattedMessage id="prizes > none" />
                )}
              </div>
            </FormGroup>
            <Row>
              <Col className={bem.e("col-reset")}>
                <FormGroup>
                  <Label>
                    <FormattedMessage id="prizes > type" />
                  </Label>
                  <div className={bem.e("prize-value-view")}>
                    {type ? (
                      get(type, "label")
                    ) : (
                      <FormattedMessage id="prizes > none" />
                    )}
                  </div>
                </FormGroup>
              </Col>
              <Col className={bem.e("col-reset")}>
                <FormGroup>
                  <Label>
                    <FormattedMessage id="prizes > tags" />
                  </Label>
                  <div className={bem.e("prize-value-view")}>
                    {tags_string || <FormattedMessage id="prizes > none" />}
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className={bem.e("col-reset")}>
                <FormGroup>
                  <Label>
                    <FormattedMessage id="prizes > form quantity" />
                  </Label>
                  <div className={bem.e("prize-value-view")}>
                    {get(prize, "quantity") ? (
                      prize.quantity
                    ) : (
                      <FormattedMessage id="prizes > none" />
                    )}
                  </div>
                </FormGroup>
              </Col>
              <Col className={bem.e("col-reset")}>
                <FormGroup>
                  <Label>
                    <FormattedMessage id="prizes > form unit_value" />
                  </Label>
                  <div className={bem.e("prize-value-view")}>
                    {get(prize, "unit_value") ? (
                      `$${prize.unit_value}`
                    ) : (
                      <FormattedMessage id="prizes > none" />
                    )}
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className={bem.e("col-reset")}>
                <FormGroup>
                  <Label>
                    <FormattedMessage id="prizes > form supplier" />
                  </Label>
                  <div className={bem.e("prize-value-view")}>
                    {get(prize, "supplier") ? (
                      prize.supplier
                    ) : (
                      <FormattedMessage id="prizes > none" />
                    )}
                  </div>
                </FormGroup>
              </Col>
              <Col className={bem.e("col-reset")}>
                <FormGroup>
                  <Label>
                    <FormattedMessage id="prizes > allotment" />
                  </Label>
                  <div className={bem.e("prize-value-view")}>
                    {allocationData ? (
                      allocationData.label
                    ) : (
                      <FormattedMessage id="prizes > none" />
                    )}
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </div>
          {isItemized && canItemize && (
            <>
              <div
                className={classnames(
                  bem.e("section-title"),
                  bem.e("section-itemize-price"),
                  "bordered",
                  "smaller"
                )}
              >
                <FormattedMessage id="prizes > itemization" />
              </div>
              <div
                className={classnames(
                  bem.e("fields"),
                  bem.e("allocation-fields"),
                  "small-padding"
                )}
              >
                <Row>
                  <Col className={bem.e("col-reset")}>
                    <Label>
                      <FormattedMessage id="prizes > form description" />
                    </Label>
                  </Col>
                  <Col className={bem.e("col-reset")}>
                    <Label>
                      <FormattedMessage id="prizes > form quantity" />
                    </Label>
                  </Col>
                  <Col className={bem.e("col-reset")}>
                    <Label>
                      <FormattedMessage id="prizes > form unit_value" />
                    </Label>
                  </Col>
                </Row>
                <Row>
                  <Col className={bem.e("col-reset")}>
                    <div
                      className={classnames(
                        bem.e("itemized-prize-assigned"),
                        "bordered"
                      )}
                    >
                      {map(prize.itemized_prices, (item, index) => {
                        return (
                          <div
                            key={`view-itemized-${index}`}
                            className="d-flex justify-content-between item-row"
                          >
                            <div
                              className={classnames(
                                bem.e("itemization-detail"),
                                "bold"
                              )}
                            >
                              {item.description}
                            </div>
                            <div className={bem.e("itemization-detail")}>
                              {item.quantity}
                            </div>
                            <div className={bem.e("itemization-detail")}>
                              ${item.value}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          )}
        </div>
        {/* Allotment section */}
        {isAssigned && get(prize, "stations.length", 0) > 1 && (
          <div className={bem.e("section-form")}>
            {canItemize && isItemized ? (
              <>
                {/* Allotment => Assigned with itemized */}
                <div
                  className={classnames(
                    bem.e("section-title"),
                    bem.e("section-itemize-price")
                  )}
                >
                  <FormattedMessage id="prizes > allotment" />
                </div>
                {map(prize.stations, (station) => {
                  const itemizedQuantities = get(
                    prize,
                    "stations_itemized_quantity",
                    []
                  );
                  const quantitiesForStation =
                    itemizedQuantities[station] || [];
                  const itemizedQuantityTotal = sum(
                    quantitiesForStation.map(Number)
                  );
                  const stationData = findStation(station);
                  return (
                    <div
                      key={`${station}-price-section`}
                      className="station-itemized-section section-open"
                    >
                      <div
                        className={classnames(
                          bem.e("section-title"),
                          bem.e("section-itemize-price"),
                          "smaller"
                        )}
                      >
                        {stationData ? (
                          stationData.name
                        ) : (
                          <FormattedMessage id="prizes > none" />
                        )}
                        <div className="itemized-counts">
                          <span className="count-title">
                            <FormattedMessage id="prizes > allotted" />
                          </span>
                          <span className="count">
                            {itemizedQuantityTotal || 0}
                          </span>
                          <span className="count-title">
                            <FormattedMessage id="prizes > remaining" />
                          </span>
                          <span className="count">
                            {prize.quantity - allocation}
                          </span>
                          <ChevronWrapper
                            className="chevron"
                            onClick={(e) => {
                              const itemizedSection = e.target.closest(
                                ".station-itemized-section"
                              );
                              if (itemizedSection) {
                                if (
                                  itemizedSection.classList.contains(
                                    "section-open"
                                  )
                                ) {
                                  itemizedSection.className =
                                    "station-itemized-section section-close";
                                } else {
                                  itemizedSection.className =
                                    "station-itemized-section section-open";
                                }
                              }
                            }}
                          >
                            <DownIcon />
                          </ChevronWrapper>
                        </div>
                      </div>
                      <div
                        className={classnames(
                          bem.e("fields"),
                          bem.e("allocation-fields"),
                          "small-padding"
                        )}
                      >
                        <Row>
                          <Col className={bem.e("col-reset")}>
                            <Label>
                              <FormattedMessage id="prizes > form description" />
                              <Asterisk>*</Asterisk>
                            </Label>
                          </Col>
                          <Col className={bem.e("col-reset")}>
                            <Label>
                              <FormattedMessage id="prizes > form quantity" />
                              <Asterisk>*</Asterisk>
                            </Label>
                          </Col>
                        </Row>
                        <Row>
                          <Col className={bem.e("col-reset")}>
                            <div
                              className={classnames(
                                bem.e("itemized-prize-assigned"),
                                "bordered"
                              )}
                            >
                              {map(prize.itemized_prices, (item, index) => {
                                return (
                                  <div
                                    key={`view-itemized-${index}`}
                                    className="d-flex justify-content-between item-row"
                                  >
                                    <div
                                      className={classnames(
                                        bem.e("itemization-detail"),
                                        "bold"
                                      )}
                                    >
                                      {item.description}
                                    </div>
                                    <div
                                      className={bem.e("itemization-detail")}
                                    >
                                      {getStationsItemizedQuantityValue(
                                        station,
                                        index
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  );
                })}
                <div
                  className={classnames(
                    bem.e("fields"),
                    bem.e("itemized-allocation")
                  )}
                >
                  <Row>
                    <AllocationTable
                      bem={bem}
                      total={total}
                      allocation={allocation}
                      unAllocation={unAllocation}
                    />
                  </Row>
                </div>
              </>
            ) : (
              <>
                {/* Allotment => Assigned with not itemized */}
                <div className={bem.e("section-title")}>
                  <FormattedMessage id="prizes > allotment" />
                </div>
                <div
                  className={classnames(
                    bem.e("fields"),
                    bem.e("allocation-fields")
                  )}
                >
                  <Row>
                    <Col className={bem.e("col-reset")} xs={12}>
                      <FormGroup className={bem.e("form-group-stations")}>
                        <div className={bem.e("station-head")}>
                          <Label>
                            <FormattedMessage id="prizes > form stations" />
                          </Label>
                          <Label>
                            <FormattedMessage
                              id={
                                isVariableCash
                                  ? "prizes > form total cash"
                                  : "prizes > form quantity"
                              }
                            />
                            <Asterisk>*</Asterisk>
                          </Label>
                        </div>
                        <div className={bem.e("station-assigned")}>
                          {map(stationsQuantity, (item, index) => {
                            const stationData = findStation(item.station);
                            return (
                              <div
                                className={bem.e("station-item")}
                                key={`${item.station}-allocated-price-${index}`}
                              >
                                <div className={bem.e("station-name")}>
                                  {stationData ? (
                                    stationData.name
                                  ) : (
                                    <FormattedMessage id="prizes > none" />
                                  )}
                                </div>
                                <div className={bem.e("station-qty")}>
                                  {get(item, "quantity", 0)}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </FormGroup>
                      <Row>
                        <AllocationTable
                          bem={bem}
                          total={total}
                          allocation={allocation}
                          unAllocation={unAllocation}
                        />
                      </Row>
                    </Col>
                  </Row>
                </div>
              </>
            )}
          </div>
        )}
        <div className={bem.e("section-form")}>
          <div className={bem.e("section-title")}>
            <FormattedMessage id="prizes > fulfillment" />
          </div>
          <div
            className={classnames(bem.e("fields"), bem.e("fulfillment-fields"))}
          >
            <Row>
              <Col xs={6} className={bem.e("col-reset")}>
                <FormGroup>
                  <Label>
                    <FormattedMessage id="prizes > fulfillment method" />
                  </Label>
                  <div className={bem.e("prize-value-view")}>
                    {fulfillment ? (
                      fulfillment.label
                    ) : (
                      <FormattedMessage id="prizes > none" />
                    )}
                  </div>
                </FormGroup>
              </Col>
              <Col xs={6} className={bem.e("col-reset")}>
                <FormGroup>
                  <Label>
                    <FormattedMessage id="prizes > form expiration date" />
                  </Label>
                  <div className={bem.e("prize-value-view")}>
                    {get(prize, "expiration_date") ? (
                      moment(prize.expiration_date).format(dateFormatByServer)
                    ) : (
                      <FormattedMessage id="prizes > none" />
                    )}
                  </div>
                </FormGroup>
              </Col>
              <Col xs={6} className={bem.e("col-reset")}>
                <div className={bem.e("checkboxes")}>
                  <Checkbox
                    name="check_received"
                    onChange={() => {}}
                    checked={prize.check_received}
                    text={
                      <FormattedMessage id="prizes > form check_received" />
                    }
                  />
                  <Checkbox
                    name="check_exclude"
                    onChange={() => {}}
                    checked={prize.check_exclude}
                    text={
                      <FormattedMessage id="prizes > form check_exclude_1099" />
                    }
                  />
                </div>
              </Col>
            </Row>
            {prize.fulfillment === "digital_link" && (
              <FulfillDigital
                bem={bem}
                prize={prize}
                isView={true}
                intl={intl}
              />
            )}
            {prize.fulfillment === "mail_out" && (
              <FulfillMailOut
                bem={bem}
                prize={prize}
                isView={true}
                intl={intl}
              />
            )}

            {(prize.fulfillment === "will_call" ||
              prize.fulfillment === "pickup_client_provider" ||
              prize.fulfillment === "pickup_station") && (
              <FulfillLocation
                bem={bem}
                prize={prize}
                isView={true}
                intl={intl}
                locations={locationOptions}
                serverCountry={serverCountry}
              />
            )}
          </div>
        </div>
      </div>
      <div className="bottom-actions-sticky">
        <div className={"buttons"}>
          <Button
            color="blue"
            type="button"
            onClick={() => {
              const params = {
                prizes_id: prize.id,
              };
              history.push(URL.UPDATE_PRIZES(params));
            }}
            className={classnames("btn btn-radius", bem.e("btn-submit"))}
          >
            <FormattedMessage id={`prizes > button edit`} />
          </Button>
          <Button
            color="blue"
            type="button"
            className="btn btn-radius"
            outline
            onClick={() => {
              rest.setIsOpenConfirmationModal(true);
            }}
          >
            <FormattedMessage id="delete" />
          </Button>
          <Button
            color="blue"
            type="button"
            className={classnames(
              "float-right btn btn-radius",
              bem.e("btn-submit")
            )}
            onClick={() => {
              history.push(URL.PRIZE());
            }}
          >
            <FormattedMessage id="prizes > button back to list" />
          </Button>
        </div>
      </div>
      {/* confirmation delete modal */}
      <ConfirmationModal
        isOpen={rest.isOpenConfirmationModal}
        className={bem.e("confirmation-modal")}
        title={<FormattedMessage id="prizes > title delete confirmation" />}
        cancelTitle={<FormattedMessage id="prizes > button no" />}
        confirmTitle={<FormattedMessage id="prizes > button yes" />}
        onCancel={() => {
          rest.setIsOpenConfirmationModal(false);
        }}
        onConfirm={rest.onDelete}
        onToggle={() =>
          rest.setIsOpenConfirmationModal(!rest.isOpenConfirmationModal)
        }
      />
    </React.Fragment>
  );
};

export default PrizeFormView;
