import View from "./view";
import { compose, withState, withHandlers } from "recompose";

export default compose(
  withState("isOpenPreviewModal", "setIsOpenPreviewModal", false),
  withState("currentProcess", "setCurrentProcess", null),
  withHandlers({
    onTogglePreviewModal: ({
      isOpenPreviewModal,
      setIsOpenPreviewModal,
    }) => () => setIsOpenPreviewModal(!isOpenPreviewModal),
  })
)(View);
