import View from "./view";
import { compose, withState, withHandlers, lifecycle } from "recompose";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { get } from "lodash";
import { checkAutoDubbing } from "store/actions/processes";

export default injectIntl(
  compose(
    connect(
      (state) => ({
        info: get(state, "auth.info"),
        isWODubbingEnabled: get(state, "auth.info.enable_wo_dubbing", false),
        autoDubbingEnabled: get(
          state,
          "processes.autoDubbingEnabled.data",
          false
        ),
        dateFormatByServer: get(
          state,
          "servers.currentServer.data.date_format",
          "MM/DD/YYYY"
        ),
      }),
      { checkAutoDubbing }
    ),
    withState("isOpenConfirmationModal", "setIsOpenConfirmationModal", false),
    withState("currentSpotIndex", "setCurrentSpotIndex", null),
    withHandlers({
      onAutoSubmit: ({ buttonClicked, template, user, step }) => (process) => {
        const item = step.fields.find((f) => f.key === "save_button");
        buttonClicked(
          step,
          item,
          process,
          template,
          user,
          null,
          null,
          true,
          true
        );
      },
    }),
    lifecycle({
      componentDidMount() {
        const processId = get(this.props.process, "data.fields.key.value", "");
        if (processId) {
          this.props.checkAutoDubbing({ id: processId });
        }
      },
    })
  )(View)
);
