import React from "react";
import * as _ from "lodash";
import { connect } from "formik";

export default connect(
	class FormikAutoSave extends React.Component {
		state = {
			isSaving: false
		};

    componentDidUpdate(prevProps) {
			if (!_.isEqual(prevProps.values, this.props.values) || this.props.isDirty) {
				this.save();
			}
		}

		save = _.debounce(() => {
			if (!this.props.formik.isValid && !this.props.isDirty) {
				return;
			}
			this.setState({ isSaving: true, saveError: undefined }, () => {
				Promise.resolve(this.props.onSave(this.props.values)).then(
          () => {
            this.props.setIsDirty(false);
						this.setState({
							isSaving: false,
							lastSaved: new Date()
						});
          },
					() => this.setState({ isSaving: false, saveError: "" })
				);
			});
		}, this.props.debounce || 300);

		render() {
			return this.props.render ? this.props.render(this.state) : null;
		}
	}
);
