import React from "react";
import bn from "utils/bemnames";
import { get, includes } from "lodash";
import PrizePackage from "../Common/PrizePackage";
import GrandPrizePackage from "../Common/GrandPrizePackage";
import Buttons from "../Common/Buttons";
import classNames from "classnames";
import Spinner from "components/Spinner";
const bem = bn.create("promotion-contest-prizes");

export default function PromotionContestDetails(props) {
  const {
    step,
    process,
    template,
    user,
    auth,
    validationState,
    valueChanged,
    intl,
    isOpenDetails,
    setIsOpenDetails,
  } = props;
  const sectionKey = "prize_package";
  const isView = props.mode === "view";
  const onValueChanged = (field, value) => {
    const ignoreFields = [];
    if (!includes(ignoreFields, field.field)) {
      props.setShouldBlockNavigation(true);
      props.setIsShowClearForm(true);
    }
    valueChanged(field, process, template, user, { value });
  };

  const processKey = get(process, "data.fields.key.value", false);
  const qualifyingPrize = get(
    process,
    "data.fields.qualifying_prize.value",
    false
  );
  const selectedTheme = get(process, "data.fields.theme.value", "");
  const isQualifyingGrandPrizeTheme =
    selectedTheme === "qualifying_with_grand_prize";
  const isHidePrizePackage = isQualifyingGrandPrizeTheme && !qualifyingPrize;
  return (
    <div
      className={classNames(bem.b(), {
        [bem.m("edit")]: !isView,
        [bem.m("view")]: isView,
      })}
    >
      {isQualifyingGrandPrizeTheme && (
        <GrandPrizePackage
          bem={bem}
          process={process}
          template={template}
          user={user}
          auth={auth}
          step={step}
          sectionKey={"grand_prizes"}
          intl={intl}
          isView={isView}
          validationState={validationState}
          onValueChanged={onValueChanged}
          isOpenDetails={isOpenDetails}
          setIsOpenDetails={setIsOpenDetails}
        />
      )}
      {!isHidePrizePackage && (
        <PrizePackage
          bem={bem}
          process={process}
          template={template}
          user={user}
          auth={auth}
          step={step}
          sectionKey={sectionKey}
          intl={intl}
          isView={isView}
          validationState={validationState}
          onValueChanged={onValueChanged}
          isOpenDetails={isOpenDetails}
          setIsOpenDetails={setIsOpenDetails}
        />
      )}

      <Buttons
        buttonClicked={props.buttonClicked}
        step={step}
        process={process}
        template={template}
        user={user}
        isView={isView}
        bem={bem}
        onValueChanged={onValueChanged}
        isShowClearForm={false}
        setIsShowClearForm={props.setIsShowClearForm}
        onClearForm={props.onClearForm}
        isShowDeleteButton={!!processKey && !isView}
        isShowPublishButton={!isView}
        sectionKey={sectionKey}
        setIsOpenDetails={setIsOpenDetails}
        setValidationError={props.setValidationError}
        validate={props.validate}
      />
      <Spinner isLoading={props.isLoading} />
    </div>
  );
}
