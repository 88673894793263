import React, { Component } from "react";
import cx from "classnames";
import { FormattedMessage } from "react-intl";
import { DownIcon, UpIcon } from "components/CustomIcons";
import {
  ShadowBox,
  SectionTitle,
  SectionFields,
  ChevronWrapper,
} from "components/Elements";
import ToggleSwitch from "components/ToggleSwitch";
import Checkbox from "components/Checkbox";

class ServerFormSection extends Component {
  state = {
    isSectionOpen: false,
  };

  toggleSection = (e) => {
    this.setState({ isSectionOpen: !this.state.isSectionOpen });
  };

  render() {
    const {
      bem,
      checked,
      children,
      hasToggle,
      onSwitchToggle,
      titleId,
      sectionClass,
      sectionFieldsClass,
      intl,
      showMultiMarket,
      onChangeMultiMarket,
      multiMarketEnabled,
      switchDisabled,
      showWOCartPrefixConfig,
      showWOCartPrefix,
      onchangeWOCartPrefix
    } = this.props;
    const { isSectionOpen } = this.state;
    return (
      <ShadowBox className={cx(sectionClass, bem.e("box-section"), isSectionOpen ? 'is-open' : 'is-closed')}>
        <SectionTitle
          className={cx(bem.e("section-title"), isSectionOpen ? 'is-open' : 'is-closed')}
          onClick={this.toggleSection}
        >
          <FormattedMessage id={titleId} />
          {showMultiMarket && (
            <div
              className={bem.e("section-mm-checkbox")}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <Checkbox
                checked={multiMarketEnabled}
                onChange={checked => {onChangeMultiMarket(checked)}}
                text={intl.formatMessage({
                    id: "server > multi market",
                })} 
                checkStyle={{ color: "#fff", width: "15", height: "15" }}
                disabled={!checked}
              />
            </div>
          )}
          {/*{showWOCartPrefixConfig && (
            <div
              className={bem.e("section-mm-checkbox")}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <Checkbox
                checked={showWOCartPrefix}
                onChange={checked => {onchangeWOCartPrefix(checked)}}
                text={intl.formatMessage({
                    id: "server > show wo cart prefix",
                })} 
                checkStyle={{ color: "#fff", width: "15", height: "15" }}
                disabled={!checked}
              />
            </div>
          )}*/}
          {hasToggle && <div
            className={cx("ml-auto", bem.e("section-toggle-switch"))}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <ToggleSwitch
              leftComponent={
                <FormattedMessage id="server > switch > off" />
              }
              rightComponent={
                <FormattedMessage id="server > switch > on" />
              }
              switchProps={{
                checked,
                onChange: onSwitchToggle,
                disabled: switchDisabled,
                uncheckedIcon: false,
                checkedIcon: false,
                offColor: "#C2D4E0",
                onColor: "#795AFA",
                handleDiameter: 22,
                width: 50,
                height: 26,
              }}
            />
          </div>}
          <ChevronWrapper>
            {isSectionOpen ? <UpIcon /> : <DownIcon />}
          </ChevronWrapper>
        </SectionTitle>
        <SectionFields className={cx({ hidden: !isSectionOpen }, sectionFieldsClass, bem.e("section-fields"))}>
          {typeof children === "function" ? children() : children}
        </SectionFields>
      </ShadowBox>
    )
  }
}
ServerFormSection.defaultProps = {
  switchDisabled: false
}
export default ServerFormSection;
