import { compose, withState } from "recompose";
import { connect } from "react-redux";
import View from "./view";
import { injectIntl } from "react-intl";
export default injectIntl(
  compose(
    connect(({ auth }) => {
      return {
        auth,
      };
    }, {}),
    withState("isOpenConfirmationModal", "setIsOpenConfirmationModal", false),
    withState("shouldBlockNavigation", "setShouldBlockNavigation", false),
    withState("activeTab", "setActiveTab", "settings")
  )(View)
);
