import React, { useEffect, useRef } from "react";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  Button,
} from "reactstrap";
import classnames from "classnames";
import bn from "utils/bemnames";
import { FormattedMessage } from "react-intl";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  get,
  forEach,
  map,
  values as getValues,
  find,
  isEmpty,
  uniq,
  mapValues,
  sortBy,
  findIndex
} from "lodash";
import moment from "moment";

import { PlusIconSmaller } from "components/CustomIcons";
import ButtonAdd from "components/ButtonAdd";
import DaysOfWeek from "components/DaysOfWeek";
import HoursDropdown from "components/HoursDropdown";
import Checkbox from "components/Checkbox";
import { getHourLabel } from "utils/helpers";
import ConfirmationModal from "components/ConfirmationModal";
import Timeslot from "./Timeslot";
const bem = bn.create("clock-calendar-modal");

const DefineHourModal = (props) => {
  const {
    intl,
    isOpen,
    toggle,
    slotDefinitions,
    selectedTimeSlots,
    onSaveTimeslot,
    isLoading,
    selectedBlock,
    addEmptyTimeSlot,
    isSaveSuccessModalOpen,
    isDeleteSuccessModalOpen,
    isCopyEnabled,
    isDeleteHourBlockModalOpen,
    setIsDeleteHourBlockModalOpen,
    setIsCopyEnabled,
    setDeleteAll
  } = props;
  const randomize = findIndex(selectedTimeSlots, item => item.randomize === 0) === -1

  let startTime = get(selectedBlock, "hour") * 60;
  let endTime = startTime + 59;

  let startDate = moment().set({ hours: 0, minutes: parseInt(startTime), seconds: 0, millisecond: 0 });
  let endDate = moment().set({ hours: 0, minutes: parseInt(endTime), seconds: 0, millisecond: 0 });

  let slotDays = selectedBlock ? [get(selectedBlock, "day")] : [];
  let slotHours = selectedBlock ? [{ label: getHourLabel(get(selectedBlock, "hour")), value: get(selectedBlock, "hour") }] : [];

  const headerTimeFormat = "h:mm a";
  const dateTimeFormat = "YYYY-MM-DD HH:mm:ss";

  const isCopySectionEnabled = isEmpty(selectedTimeSlots) || isCopyEnabled;
  let _timeSlots = {};

  forEach(selectedTimeSlots, ({ id, day, start_time, slot_labels, locked, priority }) => {

    let startDate = moment().set({ hours: 0, minutes: parseInt(start_time), seconds: 0, millisecond: 0 });

    _timeSlots[id] = {
      id,
      day,
      start_time: start_time,
      start_date: startDate,
      is_time_set: true,
      label_ids: map(slot_labels, item => ({
        label: item.name,
        value: item.id,
        color: item.color,
      })),
      locked,
      priority: {
        label: priority || "",
        value: priority || "",
      },
      deleted: false,
    }
  });
  let initialValues = {
    time_slots: isEmpty(_timeSlots) ? {
      _new_slot: {
        id: "_new_slot",
        day: get(selectedBlock, "day"),
        start_time: startTime,
        start_date: startDate.format(dateTimeFormat),
        is_time_set: false,
        label_ids: [],
        locked: false,
        priority: {
          label: "",
          value: "",
        },
        deleted: false,
      }
    } : _timeSlots,
    day: get(selectedBlock, "day"),
    days: slotDays,
    hour: get(selectedBlock, "hour"),
    hours: slotHours,
    randomize,
  };

  // let copyForDays = get(first(selectedTimeSlots), "copy_for_days", []) ? get(first(selectedTimeSlots), "copy_for_days", []).filter(day => day !== get(selectedBlock, "day")) : [];
  // let copyForHours = get(first(selectedTimeSlots), "copy_for_hours", []) ? get(first(selectedTimeSlots), "copy_for_hours", []).filter(hour => hour !== get(selectedBlock, "hour")).map(hour => ({
  //   label: getHourLabel(hour),
  //   value: hour,
  // })) : [];

  const validationSchema = Yup.object().shape({
    days: Yup.array()
      .required(
        intl.formatMessage({
          id: "clock creator > define hour > days are required",
        })
      ),
    hours: Yup.array()
      .required(
        intl.formatMessage({
          id: "clock creator > define hour > hours are required",
        })
      ),
    time_slots: Yup.lazy(obj => Yup.object(
      mapValues(obj, () => Yup.object({
        deleted: Yup.boolean(),
        is_time_set: Yup.boolean().when("deleted", {
          is: false,
          then: Yup.boolean().oneOf([true], intl.formatMessage({
            id: "clock creator > define hour > slot time is required",
          })),
        }),
        label_ids: Yup.array().nullable().when("deleted", {
          is: false,
          then: Yup.array().required(intl.formatMessage({
            id: "clock creator > define hour > slot label is required",
          })),
        }),
      }))
    ))
  });

  const formikRef = useRef();

  useEffect(() => {
    formikRef.current.setValues(initialValues)
  }, [selectedBlock, selectedTimeSlots])


  const renderTimeslots = (formProps) => {

    const {
      values,
      setFieldValue,
      errors,
      setFieldTouched
    } = formProps;

    let timeSlots = get(values, "time_slots", {});
    let sortedTimeSlots = sortBy(getValues(timeSlots), [function (_slot) { return !_slot.is_time_set }, function (_slot) { return !_slot.label_ids || _slot.label_ids.length === 0 }, "start_time"]);

    return map(sortedTimeSlots, slot => {

      const firstSlot = sortedTimeSlots.find(s => s.deleted === false);

      if (!slot.deleted) {

        let timeSlotErrors = errors.time_slots ? errors.time_slots[slot.id] : {};

        return (
          <React.Fragment key={slot.id}>
            <Timeslot
              firstRow={firstSlot.id === slot.id}
              id={slot.id}
              slotDefinitions={slotDefinitions}
              slots={timeSlots}
              onChange={slots => {
                setFieldValue("time_slots", slots);
                setFieldTouched("time_slots", true);
              }}
              errors={timeSlotErrors}
            />
          </React.Fragment>
        );
      }
    });
  }

  return (
    <Modal
      isOpen={isOpen}
      size="lg"
      className={classnames(bem.b(), "modal-dialog-centered")}
    >
      <ModalHeader toggle={toggle}>
        <div className="title">
          <FormattedMessage id="clock creator > define block" />
          <span>{`: ${startDate.format(headerTimeFormat)} - ${endDate.format(headerTimeFormat)}`}</span>
        </div>
        <div className="sub-title">
          <FormattedMessage id="clock creator > define hour block helper" />
        </div>
      </ModalHeader>
      <ModalBody>
        <Formik
          validationSchema={validationSchema}
          isInitialValid={validationSchema.isValidSync(initialValues)}
          validateOnChange={false}
          validateOnBlur={false}
          validateOnMount={false}
          onSubmit={onSaveTimeslot}
          ref={formikRef}
        >
          {(formProps) => {
            const {
              handleSubmit,
              values,
              setFieldValue,
              touched,
              errors
            } = formProps;
            return (
              <React.Fragment>
                <div>
                  <Form onSubmit={handleSubmit}>
                    <Row className="field-container m-0">
                      <Col xs={12} className={classnames(bem.e("timeslot-container"), "p-0")}>
                        {renderTimeslots(formProps)}
                      </Col>
                    </Row>
                    <Row className={classnames(bem.e("add-timeslot-button-container"), "m-0 p-0")}>
                      <Col xs={12} className="m-0">
                        <ButtonAdd
                          onClick={() => addEmptyTimeSlot(formProps)}
                          showDefaultIcon={false}
                        >
                          <div>
                            <PlusIconSmaller />
                            <FormattedMessage id="clock creator > add another progclock time slot" />
                          </div>
                        </ButtonAdd>
                      </Col>
                    </Row>
                    <Row className={classnames(bem.e("separator"), { "m-b20": !isCopySectionEnabled })}></Row>
                    {isCopySectionEnabled ? (<React.Fragment>
                      <Row className="field-container">
                        <Col>
                          <div className="title">
                            <FormattedMessage id="clock creator > copy block" />
                          </div>
                          <div className="sub-title">
                            <FormattedMessage id="clock creator > copy block helper" />
                          </div>
                        </Col>
                      </Row>
                      <Row className="field-container">
                        <Col>
                          <DaysOfWeek
                            value={values.days}
                            label={intl.formatMessage({
                              id: "clock creator > show days",
                            })}
                            onChange={(days) => {
                              setFieldValue("days", uniq(days));
                            }}
                            enableWeekendWeekdayCheckboxes={true}
                            mustInclude={get(selectedBlock, "day")}
                            error={touched.days && errors.days}
                          />
                        </Col>
                      </Row>
                      <Row className="field-container hours-container">
                        <HoursDropdown
                          label={intl.formatMessage({
                            id: "clock creator > hours",
                          })}
                          name="hours"
                          onChange={(selectedOption) => {
                            let selectedOptions = !find(selectedOption, hour => hour.value === get(selectedBlock, "hour")) ? selectedOption.concat(slotHours) : selectedOption

                            setFieldValue("hours", uniq(selectedOptions));
                          }}
                          value={sortBy(values.hours, "value")}
                          error={touched.hours && errors.hours}
                        />
                      </Row>
                      <Row className="field-container randomize">
                        <Col>
                          <Checkbox
                            text={intl.formatMessage({
                              id: "clock creator > randomize unlocked slots",
                            })}
                            checked={values.randomize}
                            onChange={(checked) => {
                              setFieldValue("randomize", checked);
                            }}
                            disableSelectOnTextClick={true}
                            checkStyle={{ color: "#fff", width: "15", height: "14" }}
                          />
                        </Col>
                      </Row>
                    </React.Fragment>) : (
                      <Row className="field-container m-0 p-0">
                        <Col style={{ padding: "0px 20px" }}>
                          <div className={bem.e("copy-overlay")}>
                            <div className="box">
                              <FormattedMessage id="clock creator > copy confirm" />
                              <Button
                                color="primary"
                                type="button"
                                className="btn btn-blue btn-radius"
                                onClick={() => {
                                  // setFieldValue("days", values.days.concat(copyForDays));
                                  setFieldValue('days', []);
                                  // setFieldValue("hours", values.hours.concat(copyForHours));
                                  setFieldValue('hours', []);
                                  setFieldValue('randomize', false);
                                  setIsCopyEnabled(true);
                                }}
                              >
                                <FormattedMessage id="clock creator > copy yes" />
                              </Button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    )}
                    <Row className="field-container">
                      <Col xs={12}>
                        <div className={bem.e("form-buttons")}>
                          <Button
                            color="primary"
                            type="submit"
                            className="btn btn-blue btn-radius"
                            disabled={isLoading}
                            onClick={handleSubmit}
                          >
                            <FormattedMessage
                              id={`clock creator > button ${!isLoading ? "save" : "submitting"}`}
                            />
                          </Button>
                          <Button
                            type="button"
                            onClick={() => {
                              toggle(false);
                            }}
                            outline
                            className="btn-outline-blue btn-radius button-cancel"
                          >
                            <FormattedMessage id="clock creator > button cancel" />
                          </Button>
                          {!isEmpty(selectedTimeSlots) && (<Button
                            type="button"
                            onClick={() => setIsDeleteHourBlockModalOpen(true)}
                            outline
                            className="btn-outline-blue btn-radius button-delete"
                          >
                            <FormattedMessage id="clock creator > button delete" />
                          </Button>)}
                        </div>
                      </Col>
                    </Row>
                  </Form>
                  <ConfirmationModal
                    isOpen={isSaveSuccessModalOpen}
                    title={props.intl.formatMessage({ id: "clock creator > block created" })}
                    state="success"
                  />
                  <ConfirmationModal
                    isOpen={isDeleteSuccessModalOpen}
                    title={props.intl.formatMessage({ id: "clock creator > block deleted" })}
                    state="success"
                  />
                  <ConfirmationModal
                    isOpen={isDeleteHourBlockModalOpen}
                    title={intl.formatMessage({ id: "clock creator > confirm programming clock delete hour" })}
                    onConfirm={() => {
                      setDeleteAll(true, prevState => {
                        onSaveTimeslot(values);
                      });
                    }}
                    onCancel={() => setIsDeleteHourBlockModalOpen(false)}
                    isCloseOutside={false}
                  />
                </div>
              </React.Fragment>
            );
          }}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default DefineHourModal;
