import React, { useEffect } from "react";
import bn from "utils/bemnames";
import PropTypes from "prop-types";
import classnames from "classnames";
import { map } from "lodash";
import CalendarCard from "../CalendarCard";
import { FormattedMessage } from "react-intl";
const bem = bn.create("jock-console");
export default function CalendarFloating(props) {
  const {
    tasks,
    intl,
    selectedSchedule,
    setSelectedSchedule,
    isOpen,
    setIsOpen,
    checkIsScrollable,
  } = props;
  useEffect(() => {
    setTimeout(() => {
      checkIsScrollable();
    }, 300);
  }, [isOpen]);
  return (
    <div
      className={classnames(bem.e("calendar-floating-tasks"), {
        open: isOpen,
      })}
      id="floating-tasks"
    >
      <div className={bem.e("left-line")} />
      <div className={bem.e("calendar-hour-head")}>
        <span className={bem.e("calendar-hour-text")}>
          <strong className={bem.e("calendar-hour-floating-text")}>
            <FormattedMessage id="jock console > floating" />
          </strong>
        </span>
        <span
          className={bem.e("calendar-hour-total-tasks")}
          onClick={() => {
            const newOpen = !isOpen;
            setIsOpen(newOpen);
            const eleContainer = document.getElementById("floating-tasks");
            eleContainer.classList.remove("floating-bottom");
          }}
        >
          {tasks.length}
          {` `}
          {intl.formatMessage({
            id: "jock console > tasks",
          })}
          <span
            className={classnames(bem.e("triangle"), {
              [bem.e("triangle-up")]: isOpen,
              [bem.e("triangle-down")]: !isOpen,
            })}
          ></span>
        </span>
      </div>
      {isOpen && (
        <div
          className={classnames(
            bem.e("calendar-hour-tasks"),
            "scroll-bar-style",
            {
              [bem.e("calendar-hour-open")]: isOpen,
            }
          )}
        >
          <div className={bem.e("calendar-hour")}>
            {map(tasks, (task, index) => {
              return (
                <CalendarCard
                  key={"floating" + task.id + index}
                  task={task}
                  selectedSchedule={selectedSchedule}
                  setSelectedSchedule={setSelectedSchedule}
                  isShowWinnerIcon={true}
                  isShowCartStatus={false}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
CalendarFloating.propTypes = {
  tasks: PropTypes.array,
  setSelectedSchedule: PropTypes.func,
};
CalendarFloating.defaultProps = {
  tasks: [],
  setSelectedSchedule: () => {},
};
