import React from "react";
import PropsType from "prop-types";
import Slider from "rc-slider";
import Tooltip from "rc-tooltip";
import { get, omit, isFunction } from "lodash";
import { FormattedMessage } from "react-intl";
import DraftjsEditor from "components/DraftjsEditor";
import classnames from "classnames";
import bn from "utils/bemnames";
import TextInputFormatted from "components/TextInputFormatted";
import moment from "moment";
import { pad } from "./helper";
const bem = bn.create("textarea-evaluator");

const MIN_SLIDER = 120;
const MAX_SLIDER = 210;
export const DEFAULT_WORDS_PER_MIN = 180;
const Handle = Slider.Handle;

const handle = ({ value, dragging, index, ...restProps }) => (
  <Tooltip
    parent={null}
    prefixCls="rc-slider-tooltip"
    overlay={value + " words per minute"}
    visible={dragging}
    placement="top"
    key={index}
  >
    <Handle value={value} {...restProps} />
  </Tooltip>
);
const TextareaEvaluator = (props) => {
  let {
    text,
    timer,
    onChangeSlider,
    onChangeEditorCallback,
    title,
    isOrderLength,
    process,
    disabled,
    isEditorOnly,
    hideOrderLength,
    hideHelperText,
    part,
    isBookend,
    showInnerTitle,
    readOnly,
    helperText,
    linerTime,
    showScriptLength,
    showDelete,
    onDelete,
    deleteText,
    onLengthChangeCallback,
    spot,
    error,
    wordCounting,
    isShowWordCounting,
    isShowSlider,
  } = props;
  let otherProps = omit(props, [
    "onChangeSlider",
    "onChangeEditorCallback",
    "onUpdateSeconds",
    "onEvaluatorCallback",
    "disabled",
    "title",
  ]);
  const orderLengthCustom =
    get(process, "data.fields.order_length_custom.value") ||
    get(process, "_source.order_length_custom");
  let orderLength =
    get(process, "data.fields.order_length.value") ||
    get(process, "_source.order_length") ||
    "00";
  if (orderLengthCustom && !isShowWordCounting) {
    const momentTime = moment.utc(parseInt(orderLength) * 1000);
    const formatted = momentTime.format(
      momentTime.minutes() > 0 ? "mm:ss" : ":ss"
    );
    orderLength = formatted;
  }
  if (isShowWordCounting) {
    orderLength = wordCounting ? pad(wordCounting) : "00";
  }
  let length1 = get(spot, "length1", "00") || "";
  let length2 = get(spot, "length2", "00") || "";
  let partLength;

  if (isBookend) {
    if (part === "a") {
      partLength = length1;
    } else {
      partLength = length2;
    }
  }
  return (
    <div className={bem.b()}>
      {disabled && <div className={bem.e("textarea-disabled")} />}
      {isOrderLength && !isEditorOnly && !showInnerTitle && (
        <div
          className={classnames(
            bem.e("title-wrapper"),
            bem.e("main-title-wrapper")
          )}
        >
          <div className={bem.e("title")}>{title}</div>
        </div>
      )}
      <div
        className={classnames(
          bem.e("container", {
            [bem.e("container-edit")]:
              document.location.href.indexOf("/edit/") > -1,
          })
        )}
      >
        {!readOnly && linerTime && !isEditorOnly && (
          <div className={bem.e("wrapper-top")}>
            <div className={bem.e("timer")}>{linerTime} liner</div>
          </div>
        )}
        {showInnerTitle && (
          <div
            className={classnames(
              bem.e("wrapper-top"),
              bem.e("wrapper-top-length"),
              "title"
            )}
          >
            <div
              className={classnames(bem.e("inner-title"), { edit: !readOnly })}
            >
              {title}
            </div>
            {error && <span className="text-danger">{error}</span>}
          </div>
        )}
        <div
          className={classnames(
            bem.e("wrapper-top"),
            bem.e("wrapper-top-length"),
            { "with-inner-title": showInnerTitle }
          )}
        >
          {!hideOrderLength && orderLength && (
            <div
              className={classnames(bem.e("timer"), bem.e("length"), {
                "with-inner-title": showInnerTitle,
              })}
            >
              {props.orderLengthText}
              {!isFunction(onLengthChangeCallback) ? (
                <span className={bem.e("text-value")}>
                  {!orderLengthCustom ? ":" : ""}
                  {!isBookend ? orderLength : part === "a" ? length1 : length2}
                </span>
              ) : (
                <React.Fragment>
                  <TextInputFormatted
                    placeholder=""
                    value={partLength}
                    onChange={(value) => {
                      onLengthChangeCallback(
                        value,
                        part === "a" ? "length1" : "length2"
                      );
                    }}
                    disabled={disabled}
                  />
                </React.Fragment>
              )}
            </div>
          )}
          {(showScriptLength || (!readOnly && !isEditorOnly)) && (
            <div
              className={classnames(bem.e("timer"), {
                "with-inner-title": showInnerTitle,
              })}
            >
              <FormattedMessage id="textarea evaluator > script" />
              <span className={bem.e("text-value")}>{timer}</span>
            </div>
          )}
          {showDelete && (
            <span onClick={() => onDelete()} className={bem.e("delete-text")}>
              {deleteText}
            </span>
          )}
        </div>
        {!readOnly && !isEditorOnly && (
          <div className={bem.e("title-wrapper")}>
            {!showInnerTitle && <div className={bem.e("title")}>{title}</div>}
            {isShowSlider && (
              <div className={bem.e("wrapper-top")}>
                <div className={bem.e("slide-label")}>Slow</div>
                <Slider
                  min={MIN_SLIDER}
                  max={MAX_SLIDER}
                  handle={handle}
                  value={props.slider}
                  onChange={onChangeSlider}
                />
                <div
                  className={classnames(
                    bem.e("slide-label"),
                    bem.e("fast-slide-label")
                  )}
                >
                  Fast
                </div>
              </div>
            )}
          </div>
        )}
        <DraftjsEditor
          process={process}
          text={text}
          onChangeEditor={onChangeEditorCallback}
          {...otherProps}
        />
        {!hideHelperText && !readOnly && !isEditorOnly && (
          <div className={bem.e("info")}>{helperText}</div>
        )}
      </div>
    </div>
  );
};

TextareaEvaluator.propsType = {
  text: PropsType.string.isRequired,
  timer: PropsType.string.isRequired,
  onChangeSlider: PropsType.func.isRequired,
  onChangeEditorCallback: PropsType.func,
  wordsPerMin: PropsType.number.isRequired,
  title: PropsType.string,
  disabled: PropsType.bool,
  isEditorOnly: PropsType.bool,
  linerTime: PropsType.number,
  tags: PropsType.arrayOf(
    PropsType.shape({
      label: PropsType.string,
      replaceWith: PropsType.string,
    })
  ),
  helperText: PropsType.string,
};

TextareaEvaluator.defaultProps = {
  wordsPerMin: 120,
  isShowWordCounting: false,
  isOrderLength: false,
  isEditorOnly: false,
  onChangeEditorCallback: () => {},
  disabled: false,
  tags: [],
  title: "",
  helperText: <FormattedMessage id="textarea evaluator > helper text" />,
  showDelete: false,
  onDelete: () => {},
  deleteText: <FormattedMessage id="textarea evaluator > delete script" />,
  orderLengthText: <FormattedMessage id="textarea evaluator > length" />,
  isShowSlider: true,
};
export default TextareaEvaluator;
