import React from "react";
import { Col, Row } from "reactstrap";
import { FormattedMessage } from "react-intl";
import cx from "classnames";
import { floor, find, get, sortBy, keys, values, flattenDeep } from "lodash";
import moment from "moment-timezone";
import Tooltip from "rc-tooltip";
import URL from "utils/urls";
import { ArrowLeftIcon, ArrowRightIcon, EyeIcon } from "components/CustomIcons";
import Spinner from "components/Spinner";
import bn from "utils/bemnames";
import { canBeStacked } from "utils/helpers";

const bem = bn.create("quick-view-calender");

const QuickViewCalendar = (props) => {
  const {
    intl,
    contests = {},
    dayparts = [],
    isOneMonth,
    onNextClick,
    onBackClick,
    isLoading,
    dateFormatByServer
  } = props;

  const weekDivider = 8;
  const getWeekByDate = (date) => floor(date / weekDivider);
  
  const sortedContestMonths = sortBy(values(contests).map(data => data[0]), contest => new Date(contest.start_date));
  const mappedDayparts = dayparts.map((daypart) => {
    const allItems = sortedContestMonths.map((contestMonth, monthIndex) => {
      const currentDayPart = find(contestMonth.dayparts, { id: daypart.id });
      return get(currentDayPart, "items").map((item) => {
        const date = moment(item.start_from || item.order_start_date, "YYYY-MM-DD").date();
        return {
          ...item,
          monthIndex,
          date,
          week: getWeekByDate(date),
          month: contestMonth.month,
        };
      });
    });
    daypart.allItems = flattenDeep(allItems);
    // const stackItems = [[1, 3], [6, 7], [4,2, 5]];
    const stackItems = [];
    daypart.allItems.forEach((item, index) => {
      if (!index) {
        item.visited = true;
        stackItems.push({
          [item.id]: item
        });
        return true;
      }
      stackItems.forEach((stack, stackIndex) => {
        const canStacked = canBeStacked(item, values(stack));
        if (canStacked && !item.visited) {
          item.visited = true;
          stackItems[stackIndex][item.id] = item;
        }
      });
      if (!item.visited) {
        stackItems.push({
          [item.id]: item,
        });
      }
    });
    daypart.stackItems = stackItems.map((stack) => keys(stack));
    return daypart;
  });

  const getTypeTitle = (item) => {
    let title = '';
    switch (item.order_type) {
      // TODO: add other type titles
      case 'shoutouts':
        title = intl.formatMessage({
          id: "process > option label call in contest",
        });
        break;
      case 'call_in_contest':
        title = intl.formatMessage({
          id: "process > option label call in contest",
        });
        break;

      default:
        title = intl.formatMessage({
          id: "process > option label call in contest",
        });
        break;
    }
    return title;
  };

  const getBackgroundColor = (item) => {
    let bgColor = '';
    switch (item.order_type) {
      case 'shoutouts':
        bgColor = '#40A3FF';
        break;
      case 'call_in_contest':
        bgColor = '#73A703';
        break;
    
      default:
        bgColor = '#40A3FF';
        break;
    }
    return bgColor;
  };

  const getItemStyles = ({ item, stackItems, isOneMonth }) => {
    
    const weeksSplit = 4;
    const weeksSeparatorPadding = 4;
    const monthWidth = isOneMonth ? 100 : 33.33333;
    const weekWidth = monthWidth / weeksSplit;
    const startDate = moment(item.start_from || item.order_start_date);
    const endDate = moment(item.order_end_date);
    const diffInMonths = endDate.month() - startDate.month();
    const defaultPaddingLeft = 15;
    const defaultPaddingRight = 0; // 27
    const defaultTop = 5;
    const itemHeight = 30;
    let topNumber = defaultTop;
    let leftNumber = defaultPaddingLeft;
    let leftPercentage = 0;
    let rightNumber = defaultPaddingRight;
    let rightPercentage = 0;
    if (item.monthIndex) {
      leftNumber = item.monthIndex === 1 ? 12 : 7;
      leftPercentage = item.monthIndex * monthWidth;
    }
    
    const month = Math.min(item.monthIndex + diffInMonths, 3);
    if (month) {
      rightNumber += month === 1 ? 12 : 7;
      rightPercentage = month * monthWidth;
      // Note: adding padding for a day
      rightPercentage += weekWidth / weekDivider;
    }

    const stackIndex = stackItems.findIndex(stack => stack.includes(item.id));
    if (stackIndex) {
      topNumber += stackIndex * (itemHeight + defaultTop);
    }
    const itemLeftNumber = leftNumber + (weeksSeparatorPadding * item.week);
    const itemLeftPercent = leftPercentage + (weekWidth * item.week);
    const rightWeek = getWeekByDate(endDate.date());
    const itemRightNumber = rightNumber + (weeksSeparatorPadding * rightWeek);
    const itemRightPercent = rightPercentage + (weekWidth * rightWeek);
    const diffDays = endDate.diff(startDate, 'days');
    const isSmall = diffDays < (isOneMonth ? 4 : 10);
    return {
      itemLeftPercent,
      itemLeftNumber,
      itemRightNumber,
      itemRightPercent,
      topNumber,
      diffDays,
      isSmall,
      typeTitle: getTypeTitle(item),
      backgroundColor: getBackgroundColor(item),
    };
  };

  const renderDayParts = () => mappedDayparts.map((daypart) => {
    const numberOfRows = Math.max(daypart.stackItems.length, 3);
    const dynamicHeight = (numberOfRows * (30 + 5)) + 5;
    const weeksSplit = 4;
    return (
      <Row key={`daypart-${daypart.id}`} className="day-part-row">
        <Col sm={1} className="d-flex align-items-center" style={{ height: dynamicHeight }}>
          <div className="daypart-title">{daypart.name}</div>
        </Col>
        <Col className="mb-0 p-0">
          <Row className="h-100 m-0">
            {sortedContestMonths.map((contestMonth, monthIndex) => {
              return <Col sm={isOneMonth ? 12 : 4} key={`day-part-${contestMonth.month}`} className="day-part-col">
                {Array.from({ length: weeksSplit }).map((_, week) => {
                  return (
                    <div key={`${contestMonth.month}-${week}`} className="month-week">
                      {week === 0 && monthIndex === 0 ? daypart.allItems.map((item) => {
                        const {
                          itemLeftNumber,
                          itemLeftPercent,
                          itemRightNumber,
                          itemRightPercent,
                          topNumber,
                          typeTitle,
                          backgroundColor,
                          isSmall,
                        } = getItemStyles({ item, stackItems: daypart.stackItems, isOneMonth });
                        const startDate = moment(item.order_start_date);
                        const endDate = moment(item.order_end_date);
                        const endDateText = endDate.format('DD MMM YYYY');
                        const isOverflowing = (100 - itemRightPercent) < 1;
                        return (
                          <Tooltip
                            key={`${week}-${item.id}`}
                            id="quick-view-contest-tooltip"
                            placement="top"
                            trigger={["hover"]}
                            overlayClassName={bem.e("tooltip")}
                            destroyTooltipOnHide
                            overlay={
                              <div className={bem.e("tooltip-content")} onClick={(event) => {
                                event.currentTarget.classList.add('show-view-contest');
                              }}>
                                <p className={"tooltip-title"}>
                                  {item.order_title}
                                </p>
                                <div className={"tooltip-type"}>
                                  <div className="tooltip-type-icon" style={{ backgroundColor }} />
                                  {typeTitle}
                                </div>
                                <p className={"tooltip-text"}>
                                  {`${startDate.format(dateFormatByServer)} - ${endDate.format(dateFormatByServer)}`}
                                </p>
                                <p className={"tooltip-text"}>
                                  {item.days_string}
                                </p>
                                
                                <div className="view-contest" onClick={() => {
                                  const params = {
                                    template_id: 'contest',
                                    process_id: item.id
                                  };
                                  const url = `${URL.VIEW_PROCESS(params)}?tab=schedule`;
                                  window.open(url, '_blank').focus();
                                }}>
                                  <EyeIcon />
                                  <FormattedMessage id="process > promotion > contest > view" />
                                </div>
                              </div>
                            }
                          >
                            <div
                              key={`${week}-${item.id}`}
                              className={cx("quick-view-item", { "small-item": isSmall })}
                              data-start_from={item.start_from}
                              data-order_start_date={item.order_start_date}
                              data-order_end_date={item.order_end_date}
                              style={{
                                left: `calc(${itemLeftPercent}% + ${itemLeftNumber}px)`,
                                right: isOverflowing ? 17 : `calc(${100 - itemRightPercent}% - ${itemRightNumber}px)`,
                                top: topNumber,
                                backgroundColor,
                              }}
                              onClick={(event) => {
                                const node = document.querySelector('#quick-view-contest-tooltip .cr-quick-view-calender__tooltip-content');
                                if (node) {
                                  node.classList.add('show-view-contest');
                                }
                              }}
                            >
                              {!isSmall && <div className="title">{item.order_title}</div>}
                              {isOverflowing || isSmall ? <div className="overflow">
                                <div className="dot" />
                                <div className="dot" />
                                <div className="dot" />
                              </div> : <div className="date">{endDateText}</div>}
                            </div>
                          </Tooltip>
                        );
                      }) : null}
                    </div>
                  );
                })}
              </Col>
            })}
          </Row>
        </Col>
        <div className="day-part-divider" />
      </Row>
    );
  });

  return (
    <div className={bem.b()}>
      <Row>
        <Col sm={1} className="d-flex align-items-center justify-content-center">
          <div className="daypart-small-title"><FormattedMessage id="process > promotion > contest > viewing  dayparts" /></div>
        </Col>
        <Col className="mb-0 p-0">
          <Row className="h-100 m-0">
            {sortedContestMonths.map((contestMonth, index) => {
              return (
                <Col sm={isOneMonth ? 12 : 4} key={contestMonth.month}>
                  <div className={bem.e("month-wrapper")}>
                    {index === 0 && <div className="month-arrow-left">
                      <button className={bem.e("btn-back")} onClick={onBackClick}>
                        <ArrowLeftIcon />
                      </button>
                    </div>}
                    <div className="month">{contestMonth.month}</div>
                    <div className="year">{moment(contestMonth.start_date).year()}</div>
                    {index === (sortedContestMonths.length - 1) && <div className="month-arrow-right">
                      <button className={bem.e("btn-next")} onClick={onNextClick}>
                        <ArrowRightIcon />
                      </button>
                    </div>}
                  </div>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
      <div className={cx(bem.e('wrapper'))}>
        {renderDayParts()}
      </div>
      <Spinner isLoading={isLoading} />
    </div>
  );
};

export default QuickViewCalendar;
