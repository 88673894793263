import React from "react";
import {
  get,
  map,
  keys,
  includes,
  xor,
  isEqual,
  forEach,
  filter,
  omit,
  lowerCase,
} from "lodash";
import classnames from "classnames";
import TextInput from "components/TextInput";
import { Asterisk } from "components/Elements";
import { Formik } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import Checkbox from "components/Checkbox";
import { FormattedMessage } from "react-intl";
import Spinner from "components/Spinner";
import ConfirmationModal from "components/ConfirmationModal";
import BlockingComponent from "components/BlockingComponent";
import { arrayContainsArray } from "utils/helpers";
import Dropdown from "components/Dropdown";
import ToastManager from "components/ToastManager";
import { DownIcon, UpIcon, RoleIcon } from "components/CustomIcons";
import { Form, FormGroup, Button, Row, Col } from "reactstrap";
import { labelToNameField } from "utils/helpers";
import PrivilegedComponent from "components/PrivilegedComponent";
import { PRIVILEGES } from "utils/constants";
import bn from "utils/bemnames";
import { privileges_meta as privilegesMetaConfig } from "utils/privilegesMeta";
const bem = bn.create("role-form");
export const MODE_EDIT = "edit";
export const MODE_VIEW = "view";

class RoleForm extends React.Component {
  formikRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      template_role: false,
      name: "",
      isNameChanged: false,
      privilegesMeta: privilegesMetaConfig,
    };
  }
  componentDidMount() {
    const {
      enable_production,
      enable_promotion,
      enable_digital,
      production_multimarket,
    } = this.props;
    let privilegesMeta = Object.assign({}, privilegesMetaConfig);

    if (!enable_production) {
      privilegesMeta = {
        ...privilegesMeta,
        privileges_groups: {
          ...omit(privilegesMeta.privileges_groups, ["Production"]),
        },
      };
    }

    if (!enable_digital) {
      privilegesMeta = {
        ...privilegesMeta,
        privileges_groups: {
          ...omit(privilegesMeta.privileges_groups, ["Digital"]),
        },
      };
    }

    if (!enable_promotion) {
      privilegesMeta = {
        ...privilegesMeta,
        privileges_groups: {
          ...omit(privilegesMeta.privileges_groups, [
            "Promotions",
            "Jock Console",
          ]),
        },
      };
    }
    if (enable_production && !enable_promotion && !enable_digital) {
      privilegesMeta = {
        ...privilegesMeta,
        privileges_groups: {
          ...privilegesMeta.privileges_groups,
          Admin: {
            ...privilegesMeta.privileges_groups.Admin,
            sub_groups: filter(
              privilegesMeta.privileges_groups.Admin.sub_groups,
              (subGroup) => subGroup.name !== "Role Manager"
            ),
          },
        },
      };
    }

    if (!enable_digital) {
      privilegesMeta = {
        ...privilegesMeta,
        privileges_groups: {
          ...privilegesMeta.privileges_groups,
          Admin: {
            ...privilegesMeta.privileges_groups.Admin,
            sub_groups: filter(
              privilegesMeta.privileges_groups.Admin.sub_groups,
              (subGroup) => subGroup.name !== "Workflows"
            ),
          },
        },
      };
    }

    if (!production_multimarket) {
      privilegesMeta = {
        ...privilegesMeta,
        privileges_groups: {
          ...privilegesMeta.privileges_groups,
          Admin: {
            ...privilegesMeta.privileges_groups.Admin,
            sub_groups: filter(
              privilegesMeta.privileges_groups.Admin.sub_groups,
              (subGroup) => subGroup.name !== "Teams"
            ),
          },
        },
      };
    }
    this.setState({
      privilegesMeta,
    });
  }

  renderForm = () => {
    const {
      isLoading,
      intl,
      role,
      roles,
      setShouldBlockNavigation,
      shouldBlockNavigation,
      isUpdate,
      setIsOpenPrivileges,
      isOpenPrivileges,
    } = this.props;
    let { privileges } = this.props;
    const privileges_meta = this.state.privilegesMeta;

    let initialValues = {
      name: "",
      privileges: [],
    };

    if (isUpdate) {
      initialValues = {
        name: get(role, "name", ""),
        privileges: keys(get(role, "privileges", {})).map(Number),
      };
    } else if (this.state.template_role) {
      let templateRolePrivileges = [];
      forEach(
        Object.keys(privileges_meta.privileges_groups),
        (privileges_group) => {
          forEach(Object.keys(privileges_meta.privileges), (privilege_key) => {
            let privilege = privileges_meta.privileges[privilege_key];

            map(
              privileges_meta.privileges_groups[privileges_group].sub_groups,
              (sub_group, index) => {
                if (
                  !privilege.groups.find(
                    (group) =>
                      group.group === privileges_group &&
                      sub_group === group.sub_group
                  )
                ) {
                  if (
                    privileges &&
                    privileges.find((p) => p.name === privilege_key)
                  ) {
                    let privilege_id = privileges.find(
                      (p) => p.name === privilege_key
                    ).id;
                    templateRolePrivileges.push(privilege_id);
                  }
                }
              }
            );
          });
        }
      );
      templateRolePrivileges = templateRolePrivileges.map(Number);
      // only use generated name if no manual name was entered
      initialValues = {
        name: this.state.isNameChanged
          ? this.state.name
          : get(this.state.template_role, "name", "") + " copy",
        privileges: filter(
          keys(get(this.state.template_role, "privileges", {})).map(Number),
          (item) => includes(templateRolePrivileges, item)
        ),
      };
    } else {
      initialValues = {
        ...initialValues,
        name: this.state.name,
      };
    }
    let isChanged = false;
    const form = (
      <Formik
        ref={(ref) => (this.formikRef = ref)}
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          name: Yup.string().required(
            intl.formatMessage({
              id: "validate > name is required",
            })
          ),
          privileges: Yup.array().required(
            intl.formatMessage({
              id: "validate > privileges is required",
            })
          ),
        })}
        onSubmit={this.props.onFormSubmit}
      >
        {(formProps) => {
          const errors = formProps.errors;
          const touched = formProps.touched;
          const values = formProps.values;
          const setFieldValue = formProps.setFieldValue;
          isChanged = !isEqual(initialValues, values);
          if (isChanged !== shouldBlockNavigation) {
            setTimeout(() => setShouldBlockNavigation(isChanged), 20);
          }
          // get privileges disabled by role
          const privilegesByRole = get(
            privileges_meta,
            `privileges_by_role.${values.name}`,
            {}
          );
          return (
            <Form onSubmit={formProps.handleSubmit}>
              <div className={classnames(bem.e("section-container"))}>
                {isUpdate ? (
                  <h2 className={bem.e("view-title")}>
                    <span className={bem.e("role-icon")}>
                      <RoleIcon />
                    </span>
                    {values.name}
                  </h2>
                ) : (
                  <h2 className={bem.e("title")}>
                    {values.name ? (
                      values.name
                    ) : (
                      <FormattedMessage id="role > create new role" />
                    )}
                  </h2>
                )}
                {!isUpdate && (
                  <div className={bem.e("content-view")}>
                    <Row>
                      <Col xs="6">
                        <FormGroup>
                          <TextInput
                            label={
                              <span>
                                <FormattedMessage id="role > name" />
                                <Asterisk>*</Asterisk>
                              </span>
                            }
                            type="text"
                            name="name"
                            placeholder={intl.formatMessage({
                              id: "role > enter name",
                            })}
                            value={values.name}
                            error={touched.name && errors.name}
                            onChange={(e) => {
                              // document manual change to the name
                              this.setState({
                                name: e.target.value,
                                isNameChanged: true,
                              });
                              formProps.handleChange(e);
                            }}
                            onBlur={formProps.handleBlur}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="6">
                        <FormGroup>
                          <Dropdown
                            label={
                              <FormattedMessage id="role > template role" />
                            }
                            placeholder={this.props.intl.formatMessage({
                              id: "role > select template role",
                            })}
                            name="template_role"
                            isMulti={false}
                            allowSelectAll={false}
                            allowSelectNone={true}
                            onChange={(selectedOption) => {
                              if (selectedOption.value === "-") {
                                selectedOption = false;
                              }
                              this.setState({
                                template_role: selectedOption,
                              });
                            }}
                            noneOption={{
                              label: this.props.intl.formatMessage({
                                id: "role > select none",
                              }),
                              value: "-",
                            }}
                            value={this.state.template_role}
                            options={roles.map((r) => {
                              return {
                                ...r,
                                label: r.name,
                                value: r.id,
                              };
                            })}
                            closeMenuOnSelect={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                )}
              </div>
              {/* privileges */}
              {map(
                Object.keys(privileges_meta.privileges_groups),
                (privileges_group, indexGroup) => {
                  let totalSelectedPrivileges = 0;
                  forEach(
                    privileges_meta.privileges_groups[privileges_group]
                      .sub_groups,
                    (sub_group) => {
                      const currentTotal = Object.keys(
                        privileges_meta.privileges
                      ).reduce((total, privilege_key) => {
                        let privilege =
                          privileges_meta.privileges[privilege_key];
                        privilege = privilege.groups.find(
                          (group) =>
                            group.group === privileges_group &&
                            sub_group.name === group.sub_group
                        );
                        if (
                          privilege &&
                          privileges &&
                          privileges.find((p) => p.name === privilege_key)
                        ) {
                          let privilege_id = privileges.find(
                            (p) => p.name === privilege_key
                          ).id;
                          if (
                            includes(values.privileges, Number(privilege_id))
                          ) {
                            total += 1;
                          }
                        }
                        return total;
                      }, 0);
                      totalSelectedPrivileges += currentTotal;
                    }
                  );
                  return (
                    <div
                      className={classnames(bem.e("section-container"))}
                      key={indexGroup}
                    >
                      <div
                        className={bem.e("title")}
                        onClick={() => {
                          setIsOpenPrivileges({
                            ...isOpenPrivileges,
                            [indexGroup]: !isOpenPrivileges[indexGroup],
                          });
                        }}
                      >
                        {intl.formatMessage({
                          id: `role > title ${lowerCase(privileges_group)}`,
                        })}{" "}
                        {intl.formatMessage({
                          id: "role > privileges",
                        })}
                        {totalSelectedPrivileges > 0 ? (
                          <span className={bem.e("total")}>
                            ({totalSelectedPrivileges})
                          </span>
                        ) : null}
                        <span className={bem.e("section-icon")}>
                          {isOpenPrivileges[indexGroup] ? (
                            <UpIcon />
                          ) : (
                            <DownIcon />
                          )}
                        </span>
                      </div>
                      <div
                        className={classnames(bem.e("content-view"), {
                          [bem.e("show")]: isOpenPrivileges[indexGroup],
                          [bem.e("hide")]: !isOpenPrivileges[indexGroup],
                        })}
                      >
                        <Row>
                          {map(
                            privileges_meta.privileges_groups[privileges_group]
                              .sub_groups,
                            (sub_group, index) => {
                              const privilegesConfigByRoleSubGroup = get(
                                privilegesByRole,
                                `${sub_group.name}`
                              );
                              const privilegeDisabled = get(
                                privilegesConfigByRoleSubGroup,
                                "disabled",
                                []
                              );
                              let sub_group_privileges = Object.keys(
                                privileges_meta.privileges
                              ).filter((privilege_key) => {
                                let privilege =
                                  privileges_meta.privileges[privilege_key];
                                return privilege.groups.find(
                                  (group) =>
                                    labelToNameField(group.group) ===
                                      labelToNameField(privileges_group) &&
                                    labelToNameField(sub_group.name) ===
                                      labelToNameField(group.sub_group)
                                );
                              });
                              let sub_group_privilege_ids = [];
                              if (sub_group.select_all) {
                                sub_group_privilege_ids = sub_group_privileges
                                  .filter(
                                    (privilege_key) =>
                                      !includes(
                                        privilegeDisabled,
                                        privilege_key
                                      )
                                  )
                                  .map((privilege_key) => {
                                    if (
                                      privileges &&
                                      privileges.find(
                                        (p) => p.name === privilege_key
                                      )
                                    ) {
                                      return privileges.find(
                                        (p) => p.name === privilege_key
                                      ).id;
                                    }
                                  })
                                  .map(Number);
                              }
                              const isDisabledSelectedAll =
                                privilegesConfigByRoleSubGroup &&
                                !privilegesConfigByRoleSubGroup.is_can_select_all;
                              return (
                                <Col xs="4" key={index}>
                                  <div className={bem.e("sub-group-title")}>
                                    {intl.formatMessage({
                                      id: `role > title ${lowerCase(
                                        sub_group.name
                                      )}`,
                                    })}
                                  </div>
                                  {sub_group.select_all && (
                                    <Col
                                      key={index}
                                      className={bem.e("p-left-0")}
                                    >
                                      <Checkbox
                                        text={intl.formatMessage({
                                          id: "role > select all",
                                        })}
                                        checked={
                                          arrayContainsArray(
                                            values.privileges,
                                            sub_group_privilege_ids
                                          ) && !isDisabledSelectedAll
                                        }
                                        disabled={isDisabledSelectedAll}
                                        onChange={() => {
                                          let selectedPrivileges = [];
                                          if (
                                            arrayContainsArray(
                                              values.privileges,
                                              sub_group_privilege_ids
                                            )
                                          ) {
                                            selectedPrivileges = xor(
                                              values.privileges,
                                              sub_group_privilege_ids
                                            );
                                          } else {
                                            selectedPrivileges = [
                                              ...values.privileges,
                                              ...sub_group_privilege_ids.filter(
                                                (item) =>
                                                  !includes(
                                                    values.privileges,
                                                    item
                                                  )
                                              ),
                                            ];
                                          }
                                          setFieldValue(
                                            "privileges",
                                            selectedPrivileges
                                          );
                                        }}
                                      />
                                    </Col>
                                  )}
                                  {map(
                                    sub_group_privileges,
                                    (privilege_key, index) => {
                                      if (
                                        !privileges.find(
                                          (p) => p.name === privilege_key
                                        )
                                      )
                                        return null;

                                      let privilege =
                                        privileges_meta.privileges[
                                          privilege_key
                                        ];
                                      let privilege_id = privileges.find(
                                        (p) => p.name === privilege_key
                                      ).id;
                                      const isDisabledSelectedPrivilege =
                                        get(
                                          privilegesConfigByRoleSubGroup,
                                          "is_disabled_all",
                                          false
                                        ) ||
                                        includes(
                                          get(
                                            privilegesConfigByRoleSubGroup,
                                            "disabled",
                                            []
                                          ),
                                          privilege_key
                                        );
                                      return (
                                        <Col
                                          key={index}
                                          className={bem.e("p-left-0")}
                                        >
                                          <Checkbox
                                            text={privilege.display_name}
                                            checked={includes(
                                              values.privileges,
                                              Number(privilege_id)
                                            )}
                                            disabled={
                                              isDisabledSelectedPrivilege
                                            }
                                            onChange={() => {
                                              const selectedPrivileges = xor(
                                                values.privileges,
                                                [Number(privilege_id)]
                                              );
                                              setFieldValue(
                                                "privileges",
                                                selectedPrivileges
                                              );
                                            }}
                                          />
                                        </Col>
                                      );
                                    }
                                  )}
                                </Col>
                              );
                            }
                          )}
                        </Row>
                      </div>
                    </div>
                  );
                }
              )}
              <FormGroup>
                <div className={bem.e("form-buttons")}>
                  <Button
                    color="blue"
                    type="submit"
                    className="btn btn-radius"
                    disabled={isLoading || values.privileges.length === 0}
                    onClick={() => {
                      setTimeout(() => {
                        const formErrors = get(this.formikRef, "state.errors");
                        if (formErrors && Object.keys(formErrors).length) {
                          ToastManager.show({
                            title: intl.formatMessage({
                              id: "toast > title not saved",
                            }),
                            message: intl.formatMessage({
                              id:
                                "toast > message error please correct the hilighted fields",
                            }),
                            level: "error",
                          });
                          return;
                        }
                      }, 20);
                    }}
                  >
                    <FormattedMessage
                      id={`role > button ${
                        !isLoading ? "submit" : "submitting"
                      }`}
                    />
                  </Button>
                  {this.props.isViewMode === "edit" && this.props.isView ? (
                    <Button
                      type="button"
                      outline
                      color="blue"
                      className={classnames(
                        bem.e("button-cancel"),
                        "btn-radius"
                      )}
                      onClick={() => this.props.setIsViewMode(MODE_VIEW)}
                    >
                      <FormattedMessage id="role > button cancel" />
                    </Button>
                  ) : (
                    this.props.backButton
                  )}
                </div>
              </FormGroup>
            </Form>
          );
        }}
      </Formik>
    );
    return (
      <React.Fragment>
        {form}
        <BlockingComponent
          isBlocked={shouldBlockNavigation}
          modalProps={{
            isShowConfirm: true,
            isShowDiscard: false,
            isCancelConfirmedNavigation: true,
            onCancel: () => {
              setShouldBlockNavigation(false); // reset
            },
            // onConfirm: () => {
            //   const buttonSubmit = document.getElementById(`btn-submit`);
            //   // trigger click to submit the form
            //   if (buttonSubmit) buttonSubmit.click();
            // },
          }}
        />
      </React.Fragment>
    );
    // return (
    //   <WarnAboutUnsavedChanges when={shouldBlockNavigation} children={form} />
    // );
  };

  renderView = () => {
    const {
      isOpenPrivileges,
      setIsOpenPrivileges,
      privileges,
      user,
    } = this.props;
    const privileges_meta = this.state.privilegesMeta;
    const roleDetails = get(this.props, "role", {});
    const rolePrivileges = keys(get(roleDetails, "privileges", {})).map(Number);
    const authIsGodAdmin =
      get(user, "is_god_admin", false) && !get(user, "is_support_admin", false);
    let privs = map(
      Object.keys(privileges_meta.privileges_groups),
      (privileges_group, index) => {
        let has_privs_in_group = false;
        let totalSelectedPrivileges = 0;
        forEach(
          privileges_meta.privileges_groups[privileges_group].sub_groups,
          (sub_group, index) => {
            const currentTotal = Object.keys(privileges_meta.privileges).reduce(
              (total, privilege_key) => {
                let privilege = privileges_meta.privileges[privilege_key];
                privilege = privilege.groups.find(
                  (group) =>
                    group.group === privileges_group &&
                    sub_group.name === group.sub_group
                );
                if (
                  privilege &&
                  privileges &&
                  privileges.find((p) => p.name === privilege_key)
                ) {
                  let privilege_id = privileges.find(
                    (p) => p.name === privilege_key
                  ).id;
                  if (includes(rolePrivileges, Number(privilege_id))) {
                    total += 1;
                  }
                }
                return total;
              },
              0
            );
            totalSelectedPrivileges += currentTotal;
          }
        );
        let label = (
          <div
            className={bem.e("title")}
            onClick={() => {
              setIsOpenPrivileges({
                ...isOpenPrivileges,
                [index]: !isOpenPrivileges[index],
              });
            }}
          >
            {privileges_group}{" "}
            {this.props.intl.formatMessage({
              id: "role > privileges",
            })}
            <span className={bem.e("total")}>({totalSelectedPrivileges})</span>
            <span className={bem.e("section-icon")}>
              {isOpenPrivileges[index] ? <UpIcon /> : <DownIcon />}
            </span>
          </div>
        );
        let dit = (
          <div
            className={classnames(bem.e("content-view"), {
              [bem.e("show")]: isOpenPrivileges[index],
              [bem.e("hide")]: !isOpenPrivileges[index],
            })}
          >
            <Row>
              {map(
                privileges_meta.privileges_groups[privileges_group].sub_groups,
                (sub_group, index) => {
                  let has_privs_in_sub_group = false;
                  let sub_column = (
                    <Col xs="4" key={index}>
                      <div className={bem.e("sub-group-title")}>
                        {sub_group.name}
                      </div>
                      {map(
                        Object.keys(privileges_meta.privileges),
                        (privilege_key, index) => {
                          let privilege =
                            privileges_meta.privileges[privilege_key];
                          if (
                            !privilege.groups.find(
                              (group) =>
                                group.group === privileges_group &&
                                sub_group.name === group.sub_group
                            )
                          )
                            return null;
                          if (!includes(roleDetails.privileges, privilege_key))
                            return null;
                          has_privs_in_group = true;
                          has_privs_in_sub_group = true;
                          return (
                            <div
                              key={index}
                              className={bem.e("privilege-name")}
                            >
                              {privilege.display_name}
                            </div>
                          );
                        }
                      )}
                    </Col>
                  );
                  if (has_privs_in_sub_group) return sub_column;
                  else return null;
                }
              )}
            </Row>
          </div>
        );
        return has_privs_in_group ? (
          <div key={index} className={bem.e("section-container")}>
            {label}
            {dit}
          </div>
        ) : null;
      }
    );

    return (
      <>
        <div className={bem.e("section-container")}>
          <h2 className={bem.e("view-title")}>
            <span className={bem.e("role-icon")}>
              <RoleIcon />
            </span>
            {roleDetails.name}
          </h2>
        </div>
        {privs}
        <Row>
          <Col>
            <div className={bem.e("form-buttons")}>
              <PrivilegedComponent requires={[PRIVILEGES.ADD_PRIVILEGES]}>
                <Button
                  color="blue"
                  type="button"
                  className="btn btn-radius"
                  onClick={() => {
                    this.props.setIsViewMode(MODE_EDIT);
                  }}
                >
                  <FormattedMessage id="edit" />
                </Button>
              </PrivilegedComponent>
              {authIsGodAdmin && (
                <PrivilegedComponent requires={[PRIVILEGES.DELETE_PRIVILEGES]}>
                  <Button
                    color="blue"
                    type="button"
                    className="btn btn-radius"
                    onClick={() => {
                      this.props.openDeleteModal(roleDetails.id);
                    }}
                  >
                    <FormattedMessage id="delete" />
                  </Button>
                </PrivilegedComponent>
              )}
              {this.props.backButton}
            </div>
          </Col>
        </Row>
        <ConfirmationModal
          isOpen={this.props.isDeleteModalOpen}
          title={this.props.intl.formatMessage({
            id: "role > are you sure you want to remove role",
          })}
          onConfirm={this.props.onDelete}
          onToggle={this.props.closeDeleteModal}
          onCancel={this.props.closeDeleteModal}
          isCloseOutside={false}
        />
      </>
    );
  };
  render() {
    const { isViewMode, isLoading } = this.props;
    return (
      <Row className={bem.b()}>
        <Col>
          {isViewMode === MODE_EDIT ? this.renderForm() : this.renderView()}
          <Spinner isLoading={isLoading} />
        </Col>
      </Row>
    );
  }
}
RoleForm.defaultProps = {
  isView: false,
  backButton: null,
  onBack: () => {},
};
RoleForm.propTypes = {
  isView: PropTypes.bool.isRequired,
  backButton: PropTypes.any,
  onBack: PropTypes.func.isRequired,
};
export default RoleForm;
