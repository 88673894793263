import View from "./view";
import { get } from "lodash";
import { connect } from "react-redux";
import {
  compose,
  lifecycle,
  withState,
  withHandlers,
  withPropsOnChange,
} from "recompose";
import { getUsers, getMoreUsers } from "store/actions/users";
import { injectIntl } from "react-intl";
import { pageSize } from "utils/config";

export default injectIntl(
  compose(
    connect(
      (state) => ({
        isLazyLoading: get(state, "users.lazyLoad", false),
        users: get(state, "users.users"),
        auth: get(state, "auth", {}),
        privileges: get(state, "auth.user.privileges") || [],
      }),
      {
        getUsers,
        getMoreUsers,
      }
    ),

    withState("searchText", "setSearchText", ""),
    withState("filtered", "setFiltered", []),
    withState("sorted", "setSorted", [
      {
        id: "out_of_office",
        desc: false,
      },
    ]),
    withHandlers({
      getUsersList: ({ getUsers, sorted, filtered }) => async () => {
        getUsers({
          pageSize,
          page: 0,
          sorted,
          filtered,
          outOfOffice: true,
        });
      },
      getMoreUsersList: ({ getMoreUsers, sorted, filtered }) => async (
        page
      ) => {
        getMoreUsers({
          pageSize,
          page,
          sorted,
          filtered,
          outOfOffice: true,
        });
      },
    }),
    withHandlers({
      onLoadMore: ({ getMoreUsersList, users }) => () => {
        getMoreUsersList(get(users, "data.current_page", 1));
      },
    }),
    withHandlers({
      onSearchKeyDown: ({ getUsersList }) => (e) => {
        if (e.keyCode === 13) {
          getUsersList();
        }
      },
      onSearchClicked: ({ getUsersList }) => () => {
        getUsersList();
      },
      onChangeInput: ({
        filtered,
        setFiltered,
        setSearchText,
        getUsersList,
      }) => ({ target }) => {
        const value = target.value;
        const name = target.name;
        const filterSearch = {
          id: name,
          value,
        };
        const index = filtered.findIndex((item) => item.id === name);
        if (index === -1) {
          filtered.push(filterSearch);
        } else {
          filtered[index] = filterSearch;
        }
        setSearchText(value);
        setFiltered(filtered);

        if (value === "") getUsersList();
      },
    }),
    withHandlers({
      onClearSearch: ({ onChangeInput, onSearchClicked }) => (e) => {
        e.preventDefault();
        onChangeInput({
          target: {
            name: "q",
            value: "",
          },
        });
        onSearchClicked();
      },
    }),
    withPropsOnChange(
      ["sorted", "filtered"],
      ({ getUsersList, sorted, filtered, isFocused }) => {
        if (!isFocused) return;
        if (sorted.length > 0 || filtered.length > 0) {
          getUsersList();
        }
      }
    ),
    lifecycle({
      componentDidMount() {
        if (get(this.props, "isFocused")) {
          this.props.setFiltered([]);
          this.props.setSorted([
            {
              id: "out_of_office",
              desc: false,
            },
          ]);
          this.props.getUsersList();
        }
      },
      componentWillReceiveProps(nextProps) {
        // only call when focused to this tab
        if (
          get(this.props, "isFocused") !== get(nextProps, "isFocused") &&
          get(nextProps, "isFocused") &&
          !get(nextProps, "users.data.data")
        ) {
          this.props.getUsersList();
        }
      },
    })
  )(View)
);
