import { FormattedMessage } from "react-intl";
import React from "react";
import { htmlToText } from "utils/helpers";
import { get, filter, includes, uniq, map, delay, find } from "lodash";
import moment from "moment";
import history from "components/History";
import { getLocalStorageDateFormat } from "utils/dates";
import { checkUserRedirectToCurrentTasks } from "utils/helpers";

const writeScript = {
  process_step_index: 3,
  key: "write_script",
  title: <FormattedMessage id="process > title script" />,
  caption_color: "#CC1EE6",
  description: <FormattedMessage id="process > creative details" />,
  isCollapsible: true,
  step_roles: [10],
  step_viewers_roles: [10, 4, 7, 2],
  step_editors_roles: [10, 4],
  step_viewers_privileges: {
    or: ["submit_production_script", "edit_all_production_orders"],
  },
  step_editors_privileges: {
    or: ["submit_production_script", "edit_all_production_orders"],
  },
  is_hidden: function(user, process, step) {
    const spotInfo = get(process, "data.fields.spot_info.value", []).find(
      (spot) => spot.key === process.key
    );

    if (
      !spotInfo ||
      get(process, "data.fields.contract_status.value") !==
        "Production Needed" ||
      get(spotInfo, "script_type") === "final"
    )
      return true;
    return false;
  },
  isOpen: function({ step, process, template, user }) {
    return !get(process, "data.fields.write_script_status.value", false);
  },
  className: "write-script",
  status: "initial",
  workflow: {
    title: "write script",
    icon: "ScriptIcon",
  },
  is_approval_step: true,
  fields: [
    {
      component: "order_approvals",
    },
    {
      field: "spot_info",
      props: {
        isViewAssets: true,
      },
      validation: function(step, item, process, template, user, value) {
        let isValid = true;
        const scriptKey = "script";
        const scriptKeyB = "script_part_b";
        const producerAssignsVo = get(
          process,
          "fields.producer_assigns_vo.value",
          false
        );
        const spotInfo = map(value, (spot) => {
          if (spot.key !== get(process, "fields.key.value")) return {};
          let error = {};
          const isUploadFile = spot.is_upload_script_file;
          if (isUploadFile) {
            if (!spot[scriptKey]) {
              isValid = false;
              error[scriptKey] = "Script file is required";
            }

            if (
              get(spot, "spot_type", "Standard") === "Book-ends" &&
              !spot[scriptKeyB]
            ) {
              isValid = false;
              error[scriptKeyB] = "Script file is required";
            }
          } else {
            if (!spot[scriptKey] || htmlToText(spot[scriptKey]).length <= 9) {
              isValid = false;
              error[scriptKey] = "Script must be at least 10 letters long";
            }

            if (
              get(spot, "spot_type", "Standard") === "Book-ends" &&
              (!spot[scriptKeyB] || htmlToText(spot[scriptKeyB]).length <= 9)
            ) {
              isValid = false;
              error[scriptKeyB] = "Script must be at least 10 letters long";
            }
            if (!get(spot, "voice_over_instructions") && producerAssignsVo) {
              isValid = false;
              error["voice_over_instructions"] =
                "Please enter voice over instructions";
            }
          }

          return error;
        });
        if (isValid) return true;
        return spotInfo;
      },
      mandatory: true,
    },
    {
      field: "script",
      container: "segment",
      component: "order_script",
      isCollapsible: false,
      isSpot: true,
      editButton: true,
      isShowEditButtonViewMode: true,
      props: {
        classNameSegment: "segment-script",
        className: "form-div-script",
        readOnly: true,
      },
      title: <FormattedMessage id="process > title final script" />,
      is_hidden: function(user, process, step, isView) {
        const spotInfo = get(process, "data.fields.spot_info.value", []).find(
          (spot) => spot.key === process.key
        );

        return (
          isView &&
          ((get(process, "data.fields.approve_script_status.value", "") ===
            "rejected" &&
            get(process, "data.fields.process_step_index.value") === 3) ||
            !get(spotInfo, "script", ""))
        );
      },
      isOpen: function({ step, process, template, user }) {
        return true;
      },
    },
    {
      field: "script_history",
      container: "segment",
      component: "order_script_review_history",
      props: {
        classNameSegment: "segment-script-history",
        className: "form-div-script-history",
        readOnly: true,
      },
      isCollapsible: true,
      title: <FormattedMessage id="process > field script history" />,
      isOpen: function({ step, process, template, user }) {
        return (
          get(process, "data.fields.approve_script_status.value", "") !==
          "approved"
        );
      },
    },
    {
      text: <FormattedMessage id="process > button submit" />,
      component: "button",
      container: "bottom_buttons",
      key: "save_button",
      confirmation_dialog_props: {
        title: (
          <FormattedMessage id="process > description to submit this script" />
        ),
        confirmTitle: <FormattedMessage id="process > button yes" />,
        cancelTitle: <FormattedMessage id="process > button no" />,
      },
      success_dialog_props: {
        title: <FormattedMessage id="process > title submitted" />,
        state: "success",
      },
      props: { color: "blue" },
      is_hidden: function(user, process, step, isView) {
        const spotInfo = get(process, "data.fields.spot_info.value", []).find(
          (spot) => spot.key === process.key
        );
        const writerSendsScriptToClient = get(
          spotInfo,
          "writer_sends_script_to_client",
          false
        );
        // show send to client button. Will hide the submit button
        if (writerSendsScriptToClient) {
          return true;
        }
        return false;
      },
      on_click: {
        validate: true,
        save: true,
        go_to: function(_this, step, item, process, template, user, extraData) {
          // set delay when after success message
          const return_to_current_tasks = checkUserRedirectToCurrentTasks({
            ...user,
            roles_string: extraData.roles_string,
          });
          if (!return_to_current_tasks) return;
          delay(() => {
            if (_this.props.setSidebarActive) {
              _this.props.setSidebarActive(`/processes/my_action_items`);
            }
            history.replace(`/processes/my_action_items`);
          }, 4000);
        },
      },
      fields: [
        {
          key: "script_version",
          value: (user, template, process, step, item, next_users) => {
            if (
              get(process, "data.fields.process_step.value") === "write_script"
            ) {
              return get(process, "data.fields.script_version.value", 0) + 1;
            }

            return get(process, "data.fields.script_version.value", 0);
          },
        },
        {
          key: "write_script_status",
          value: "completed",
        },
        {
          key: "process_step",
          value: "first_undone",
        },
        {
          key: "script_feedback",
          value: null,
        },
        {
          key: "script_part_b_feedback",
          value: null,
        },
        {
          key: "script_audit",
          value: (user, template, process, step, item, next_users) => {
            const format = getLocalStorageDateFormat();
            return {
              modified_at: `${moment()
                .format(format)
                .toString()} at ${moment()
                .format("h:mm A")
                .toString()}`,
              modified_by: user.name,
            };
          },
        },
        {
          key: "script_part_b_audit",
          value: (user, template, process, step, item, next_users) => {
            const spot = find(
              get(process, "data.fields.spot_info.value", []),
              (spot) => spot.key === process.key
            );

            const isBookend =
              get(spot, "spot_type", "Standard") === "Book-ends";

            if (isBookend) {
              const format = getLocalStorageDateFormat();
              return {
                modified_at: `${moment()
                  .format(format)
                  .toString()} at ${moment()
                  .format("h:mm A")
                  .toString()}`,
                modified_by: user.name,
              };
            }

            return {};
          },
        },
        {
          key: "next_users",
          value: (user, template, process, step, item, next_users) => {
            const spotInfo = get(
              process,
              "data.fields.spot_info.value",
              []
            ).find((spot) => spot.key === process.key);

            let continuityStatus = get(
              process,
              "data.fields.continuity_status.value",
              ""
            );

            const roleContinuity = get(
              process,
              "data.fields.role_continuity.value.users",
              []
            ).map((continuity) => continuity.id);

            let nextUsers = next_users;

            if (continuityStatus !== "completed") {
              nextUsers = [...nextUsers, ...roleContinuity];
            }

            if (
              (process.data.fields.contract_status &&
                process.data.fields.contract_status.value &&
                process.data.fields.contract_status.value !==
                  "Production Needed") ||
              !get(spotInfo, "script_requires_approval", false)
            ) {
              const roleProducers = get(
                process,
                "data.fields.role_producer.value.users",
                []
              ).map((producer) => producer.id);
              const roleVoiceTalens = get(
                process,
                "data.fields.role_voice_talent.value.users",
                []
              ).map((voice) => voice.id);
              const roleVosIsNotProducers = filter(
                roleVoiceTalens,
                (userId) => !includes(roleProducers, userId)
              );

              if (
                roleVoiceTalens.length === 0 ||
                roleVosIsNotProducers.length === 0
              ) {
                nextUsers = [...nextUsers, ...roleProducers];
              } else {
                nextUsers = filter(nextUsers, (nexUserId) => {
                  return !includes(roleProducers, nexUserId);
                });
              }
              nextUsers = uniq(nextUsers);
              return nextUsers;
            } else {
              return nextUsers;
            }
          },
        },
      ],
    },
    {
      component: "send_mail",
      container: "bottom_buttons",
      is_hidden: function(user, process, step, isView) {
        const spotInfo = get(process, "data.fields.spot_info.value", []).find(
          (spot) => spot.key === process.key
        );
        const writerSendsScriptToClient = get(
          spotInfo,
          "writer_sends_script_to_client",
          false
        );
        if (writerSendsScriptToClient && !isView) {
          return false;
        }
        return true;
      },
      props: {
        color: "blue",
        outline: false,
        is_spot_review: false,
        is_save_order: true,
      },
    },
    // {
    //   text: <FormattedMessage id="process > button delete" />,
    //   component: "button",
    //   props: { color: "blue", outline: true },
    //   container: "bottom_buttons",
    //   privileges: { or: ["delete_production", "edit_all_production_order"] },
    //   confirmation_dialog_props: {
    //     title: <FormattedMessage id="process > confirm delete this spot" />,
    //     confirmTitle: <FormattedMessage id="process > button yes" />,
    //     cancelTitle: <FormattedMessage id="process > button no" />,
    //     onConfirm: (_this, step, item, process, template, user) => {
    //       _this.setState({ confirmationDialogProps: false });
    //       _this.props.deleteProcess(
    //         get(process, "data.fields.key.value"),
    //         () => {
    //           const currentTasks = URL.PROCESSES({
    //             filter: "my_action_items",
    //           });
    //           const nextRoute = _this.props.sidebarActive
    //             ? _this.props.sidebarActive
    //             : currentTasks;
    //           history.push(nextRoute);
    //         }
    //       );
    //     },
    //   },
    //   on_click: {},
    // },
    {
      text: <FormattedMessage id="process > button save draft" />,
      component: "button",
      props: { color: "blue", outline: true },
      container: "bottom_buttons",
      is_hidden: function(user, process, step, isView) {
        const spotInfo = get(process, "data.fields.spot_info.value", []).find(
          (spot) => spot.key === process.key
        );
        const writeScriptStatus = get(
          process,
          "data.fields.write_script_status.value",
          ""
        );
        const writerSendsScriptToClient = get(
          spotInfo,
          "writer_sends_script_to_client",
          false
        );
        if (writerSendsScriptToClient && writeScriptStatus === "rejected") {
          return true;
        }
        return false;
      },
      fields: [
        {
          key: "write_script_status",
          value: "draft",
        },
      ],
      on_click: {
        validate: false,
        save: true,
      },
      success_dialog_props: {
        title: <FormattedMessage id="toast > title saved as draft" />,
        state: "success",
      },
    },
    // https://tasks.getventive.com/projects/5DCE0-565
    {
      text: <FormattedMessage id="process > button move to next step" />,
      component: "button",
      props: { color: "blue", outline: true },
      container: "bottom_buttons",
      is_hidden: function(user, process, step, isView) {
        const writeScriptStatus = get(
          process,
          "data.fields.write_script_status.value",
          ""
        );
        if (writeScriptStatus === "rejected") {
          return false;
        }
        return true;
      },
      fields: [
        {
          key: "script_version",
          value: (user, template, process, step, item, next_users) => {
            if (
              get(process, "data.fields.process_step.value") === "write_script"
            ) {
              return get(process, "data.fields.script_version.value", 0) + 1;
            }

            return get(process, "data.fields.script_version.value", 0);
          },
        },
        {
          key: "approve_script_status",
          value: "approved",
        },
        {
          key: "sent_to_client_for_script_review",
          value: false,
        },
        {
          key: "write_script_status",
          value: "completed",
        },
        {
          key: "process_step",
          value: "first_undone",
        },
        {
          key: "script_feedback",
          value: null,
        },
        {
          key: "script_part_b_feedback",
          value: null,
        },
        {
          key: "script_audit",
          value: (user, template, process, step, item, next_users) => {
            return {
              modified_at: `${moment()
                .format("MM/DD/YYYY")
                .toString()} at ${moment()
                .format("h:mm A")
                .toString()}`,
              modified_by: user.name,
            };
          },
        },
        {
          key: "script_part_b_audit",
          value: (user, template, process, step, item, next_users) => {
            const spot = find(
              get(process, "data.fields.spot_info.value", []),
              (spot) => spot.key === process.key
            );

            const isBookend =
              get(spot, "spot_type", "Standard") === "Book-ends";

            if (isBookend) {
              return {
                modified_at: `${moment()
                  .format("MM/DD/YYYY")
                  .toString()} at ${moment()
                  .format("h:mm A")
                  .toString()}`,
                modified_by: user.name,
              };
            }

            return {};
          },
        },
        {
          key: "next_users",
          value: (user, template, process, step, item, next_users) => {
            const spotInfo = get(
              process,
              "data.fields.spot_info.value",
              []
            ).find((spot) => spot.key === process.key);

            let continuityStatus = get(
              process,
              "data.fields.continuity_status.value",
              ""
            );

            const roleContinuity = get(
              process,
              "data.fields.role_continuity.value.users",
              []
            ).map((continuity) => continuity.id);

            let nextUsers = next_users;

            if (continuityStatus !== "completed") {
              nextUsers = [...nextUsers, ...roleContinuity];
            }

            if (
              (process.data.fields.contract_status &&
                process.data.fields.contract_status.value &&
                process.data.fields.contract_status.value !==
                  "Production Needed") ||
              !get(spotInfo, "script_requires_approval", false)
            ) {
              const roleProducers = get(
                process,
                "data.fields.role_producer.value.users",
                []
              ).map((producer) => producer.id);
              const roleVoiceTalens = get(
                process,
                "data.fields.role_voice_talent.value.users",
                []
              ).map((voice) => voice.id);
              const roleVosIsNotProducers = filter(
                roleVoiceTalens,
                (userId) => !includes(roleProducers, userId)
              );

              if (
                roleVoiceTalens.length === 0 ||
                roleVosIsNotProducers.length === 0
              ) {
                nextUsers = [...nextUsers, ...roleProducers];
              } else {
                nextUsers = filter(nextUsers, (nexUserId) => {
                  return !includes(roleProducers, nexUserId);
                });
              }
              nextUsers = uniq(nextUsers);
              return nextUsers;
            } else {
              return nextUsers;
            }
          },
        },
      ],
      move_next_task: true,
      on_click: {
        validate: true,
        save: true,
        go_to: function(_this, step, item, process, template, user) {
          // set delay when after success message
          delay(() => {
            if (_this.props.setSidebarActive) {
              _this.props.setSidebarActive(`/processes/my_action_items`);
            }
            history.replace(`/processes/my_action_items`);
          }, 4000);
        },
      },
      confirmation_dialog_props: {
        title: (
          <FormattedMessage id="process > description to submit move to next step" />
        ),
        confirmTitle: <FormattedMessage id="process > button yes" />,
        cancelTitle: <FormattedMessage id="process > button no" />,
      },
      success_dialog_props: {
        title: <FormattedMessage id="process > title submitted" />,
        state: "success",
      },
    },
  ],
};
export default writeScript;
