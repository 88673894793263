import * as React from "react";
import ChatInput from "components/ChatInput";
import MessageList from "./MessageList";
import bn from "utils/bemnames";
import { FormattedMessage } from "react-intl";
import { ArrowRightIcon, CommentIcon } from "components/CustomIcons";
import { map, get } from "lodash";
import Avatar from "../Avatar";
import ConfirmationModal from "components/ConfirmationModal";
const bem = bn.create("chatbox");
const toggleClassName = (action = "add") => {
  const rootApp = document.getElementById("cr-app-main");
  if (!rootApp) return;
  if (action === "add") {
    rootApp.classList.add("sidebar-comments-open");
  } else if (action === "remove") {
    rootApp.classList.remove("sidebar-comments-open");
  }
};
class ChatBox extends React.Component {
  render() {
    const {
      messages,
      minimized,
      style,
      submitOnEnter,
      isCollapse,
      recordingFeedback,
      scriptFeedback,
      hideChatInput,
      chatInputPlaceHolder,
      showEmojiPicker,
      onSubmit,
      onInputChange,
      process,
      setIsCollapse,
      messagesTagged,
      clientReview,
      clearInput,
      isHiddenTitle,
      isShowMessageActions,
      onEditMessage,
      setEditingMessage,
      editingComment,
      setDeletingMessage,
      editingMessage,
      voiceFeedback,
      user,
      onMarkAsSeenMessages,
      chatInputDisabled,
      isKeepInputValueAfterClickSend,
      ...rest
    } = this.props;
    if (isCollapse) {
      return (
        <div className={bem.b()}>
          <div className={bem.e("comment-button")}>
            <button
              className={bem.e("button-circle")}
              onClick={() => {
                toggleClassName("add");
                setIsCollapse(!isCollapse);
                onMarkAsSeenMessages(messagesTagged); // This api will update all messages associated with the process and remove the current user from tagged attribute.
              }}
            >
              <CommentIcon />
            </button>
          </div>

          {messagesTagged.length > 0 ? (
            <div className={bem.e("tagged-users")}>
              {map(messagesTagged, (message, index) => {
                const userTagged = get(message, "from");
                if (!userTagged) return null;
                return (
                  <Avatar
                    key={index}
                    src={userTagged.avatar_url}
                    name={userTagged.name}
                    className={bem.e("avatar")}
                    onClick={() => setIsCollapse(!isCollapse)}
                  />
                );
              })}
            </div>
          ) : null}
        </div>
      );
    }
    return (
      <div className={bem.b()} style={style}>
        {!isHiddenTitle &&
          !recordingFeedback &&
          !scriptFeedback &&
          !clientReview && (
            <div className={bem.e("title")}>
              <FormattedMessage id="chat system > title" />
              <button
                className={bem.e("button-circle")}
                onClick={() => {
                  toggleClassName("remove");
                  setIsCollapse(!isCollapse);
                  onMarkAsSeenMessages(messagesTagged); // This api will update all messages associated with the process and remove the current user from tagged attribute.
                }}
              >
                <ArrowRightIcon height={24} color="#795AFA" />
              </button>
            </div>
          )}
        {!minimized && (
          <MessageList
            isShowMessageActions={isShowMessageActions}
            messages={messages}
            process={process}
            setEditingMessage={setEditingMessage}
            onEditMessage={onEditMessage}
            editingComment={editingComment}
            editingMessage={editingMessage}
            chatInputPlaceHolder={chatInputPlaceHolder}
            bem={bem}
            setDeletingMessage={(id) => {
              setDeletingMessage(id);
              rest.setIsDeleteModalOpen(true);
            }}
            recordingFeedback={recordingFeedback}
            voiceFeedback={voiceFeedback}
            user={user}
          />
        )}
        {!hideChatInput && !minimized && (
          <ChatInput
            onSubmit={onSubmit}
            submitOnEnter={submitOnEnter}
            mentionPosition={"top"}
            chatInputPlaceHolder={chatInputPlaceHolder}
            showEmojiPicker={showEmojiPicker}
            onInputChange={onInputChange}
            clearInput={clearInput}
            isFilterUsers={true}
            template={get(process, "data.fields.template_key.value")}
            disabled={chatInputDisabled}
            isKeepInputValueAfterClickSend={isKeepInputValueAfterClickSend}
          />
        )}
        <ConfirmationModal
          isOpen={rest.isDeleteModalOpen}
          title={rest.intl.formatMessage({
            id: "process > are you sure you want to delete this comment",
          })}
          onConfirm={() => {
            if (rest.deletingMessage) {
              rest.setIsDeleteModalOpen(false);
              rest.onDeleteMessage(rest.deletingMessage);
            }
          }}
          onToggle={() => {
            rest.setIsDeleteModalOpen(false);
            setDeletingMessage(null);
          }}
          onCancel={() => {
            setDeletingMessage(null);
            rest.setIsDeleteModalOpen(false);
          }}
          isCloseOutside={false}
        />
      </div>
    );
  }
}

ChatBox.defaultProps = {
  title: "Comments",
  isHiddenTitle: false,
  isShowMessageActions: false,
  chatInputDisabled: false,
};

export default ChatBox;
