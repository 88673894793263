import React from "react";
import PropTypes from "prop-types";
import { truncate } from "lodash";
import bn from "utils/bemnames";
const bem = bn.create("jock-console");

export default function StationImage(props) {
  const { station: { name, logo } = {} } = props;
  const stationName = truncate(name, 25);
  if (logo) {
    return (
      <img src={logo} className={bem.e("station-logo-img")} alt="station" />
    );
  }
  return (
    <div className={bem.e("station-logo")}>
      <span className={bem.e("station-name")}>{stationName}</span>
    </div>
  );
}

StationImage.propTypes = {
  station: PropTypes.object.isRequired,
};
