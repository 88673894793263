import Messages from "./view";
import { connect } from "react-redux";
import { compose } from "recompose";

export default compose(
  connect(
    (state, props) => ({
      auth: state.auth,
      messages: state.messages.messages,
      ...props
    }),
    {
    }
  ),
)(Messages);
