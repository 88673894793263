import React, { useEffect, useRef, useState } from "react";
import bn from "utils/bemnames";
import TextareaEvaluator, {
  DEFAULT_WORDS_PER_MIN,
} from "components/TextareaEvaluator";
import PropTypes from "prop-types";
import { get } from "lodash";
import {
  CheckDoneIcon,
  ArrowUpIcon,
  ArrowDownIcon,
} from "components/CustomIcons";
import { setScriptField } from "utils/helpers";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import FileView from "components/FileView";
import FinalScriptContent from "../AssignVoTeam/FinalScriptContent";
import { getFileInstructions } from "./helper";
import UploadFile from "components/UploadFile";
import TextareaAutosize from "react-textarea-autosize";
import { isHtml } from "utils/helpers";
import Spinner from "components/Spinner";
import { useMemo } from "react";

const HEADER_HEIGHT = 95;
const LIST_ITEMS_HEIGHT = 210;
const bem = bn.create("file-instructions");
function SectionContent(props) {
  const { process, item, intl, updateFinalScript } = props;
  const spotInfo = get(process, "_source.spot_info", []).find(
    (spot) => spot.key === process._id
  );
  if (item.key === "traffic_instructions") {
    return (
      <div className={bem.e("content-details")}>
        <h4>{item.title}</h4>
        <p className={bem.e("content-text")}>{item.data}</p>
      </div>
    );
  }
  if (item.key === "creative_details") {
    const isCopyPointsHtml = isHtml(get(item, "data.copy_points"));

    return (
      <div className={bem.e("content-details")}>
        <label>
          {intl.formatMessage({ id: "process > voice" })}
          <strong className={bem.e("bold-text")}>
            {get(item, "data.voice_preference", "")
              ? intl.formatMessage({
                  id: `process > ${get(item, "data.voice_preference", "")}`,
                })
              : ""}
          </strong>
        </label>
        <div className={bem.e("content-item")}>
          <label>
            {intl.formatMessage({
              id: "process > field copy points/instructions",
            })}
          </label>
          <p className={bem.e("content-text")}>
            {get(item, "data.copy_points", "") ? (
              isCopyPointsHtml ? (
                <TextareaEvaluator
                  text={setScriptField(get(item, "data.copy_points", ""))}
                  isEditorOnly={true}
                  hideOrderLength={true}
                  readOnly={true}
                  process={process}
                  onEvaluatorCallback={() => {}}
                />
              ) : (
                <TextareaAutosize
                  value={get(item, "data.copy_points", "")}
                  className="text-area-view"
                  disabled
                />
              )
            ) : (
              <FormattedMessage id="process > none" />
            )}
          </p>
        </div>
        {item.isBookend ? (
          <>
            <div className={bem.e("content-item")}>
              <TextareaEvaluator
                text={setScriptField(get(item, "data.draft_script", ""))}
                isOrderLength={true}
                onEvaluatorCallback={() => {}}
                process={process}
                spot={spotInfo}
                title={intl.formatMessage({
                  id: "process > field script part A",
                })}
                disabled={true}
                isBookend={true}
                part="a"
                readOnly={true}
                showInnerTitle={true}
                showScriptLength={true}
                orderLengthText={<FormattedMessage id="process > length" />}
                wordsPerMin={get(
                  spotInfo,
                  "default_words_per_min",
                  DEFAULT_WORDS_PER_MIN
                )}
              />
            </div>
            <div className={bem.e("content-item")}>
              <TextareaEvaluator
                text={setScriptField(get(item, "data.draft_script_part_b", ""))}
                isOrderLength={true}
                onEvaluatorCallback={() => {}}
                process={process}
                spot={spotInfo}
                title={intl.formatMessage({
                  id: "process > field script part B",
                })}
                disabled={true}
                isBookend={true}
                part="a"
                readOnly={true}
                showInnerTitle={true}
                showScriptLength={true}
                orderLengthText={<FormattedMessage id="process > length" />}
                wordsPerMin={get(
                  spotInfo,
                  "default_words_per_min_b",
                  DEFAULT_WORDS_PER_MIN
                )}
              />
            </div>
          </>
        ) : (
          <TextareaEvaluator
            text={setScriptField(get(item, "data.draft_script", ""))}
            isOrderLength={true}
            onEvaluatorCallback={() => {}}
            process={process}
            spot={spotInfo}
            title={intl.formatMessage({ id: "process > draft script" })}
            disabled={true}
            readOnly={true}
            showInnerTitle={true}
            showScriptLength={true}
            orderLengthText={<FormattedMessage id="process > length" />}
            wordsPerMin={get(
              spotInfo,
              "default_words_per_min",
              DEFAULT_WORDS_PER_MIN
            )}
          />
        )}
      </div>
    );
  }
  if (item.key === "assets") {
    return (
      <div className={bem.e("content-details")}>
        <div className={bem.e("assets")}>
          {get(item, "data", []).map((file) => (
            <div key={file.path} className={bem.e("asset")}>
              {file.audit && (
                <span className={bem.e("asset-audit")}>
                  {intl.formatMessage({ id: "process > file" })} -{" "}
                  {intl.formatMessage({ id: "process > by" })}{" "}
                  {file.audit.modified_by}{" "}
                  {intl.formatMessage({ id: "process > on" })}{" "}
                  {file.audit.modified_at}
                </span>
              )}
              <FileView file={file} actions={["view", "download"]} />
            </div>
          ))}
        </div>
      </div>
    );
  }
  if (item.key === "approved_script") {
    return (
      <FinalScriptContent
        process={process}
        item={item}
        bem={bem}
        intl={intl}
        containerClass={bem.e("final-script-content")}
        updateFinalScript={updateFinalScript}
      />
    );
  }
  if (item.key === "approved_recoding") {
    return (
      <div className={bem.e("content-details")}>
        {get(item, "data", []).map((spot, index) => {
          const isBookend =
            get(spotInfo, "spot_type", "Standard") === "Book-ends";
          return (
            <div key={spot.key} className={bem.e("spot-recording-item")}>
              <div className={bem.e("spot-title")}>
                {intl.formatMessage({ id: "process > spot" })} {index + 1}:{" "}
                {intl.formatMessage({ id: "process > approved recording" })}
              </div>
              <div className={bem.e("spot-files")}>
                {spot.recording_path ? (
                  <UploadFile
                    actions={["download"]}
                    positionActions="bottom"
                    mode={"view"}
                    showRemoveIcon={false}
                    process={process}
                    spot={spot}
                    url={spot.recording_path}
                    className={item.class}
                    isShowFileNameInEditMode={true}
                    auditInfo={spot.recording_audit}
                    isBookend={isBookend}
                    path={isBookend ? "a" : ""}
                  >
                    <FormattedMessage id="process > button upload" />
                  </UploadFile>
                ) : (
                  <div className={bem.e("spot-production")}>
                    <FormattedMessage id="process > title spot in production" />
                  </div>
                )}

                {isBookend &&
                  (spot.recording_path_b ? (
                    <UploadFile
                      actions={["download"]}
                      positionActions="bottom"
                      mode={"view"}
                      showRemoveIcon={false}
                      process={process}
                      spot={spot}
                      url={spot.recording_path_b}
                      isShowFileNameInEditMode={true}
                      auditInfo={spot.recording_path_b_audit}
                      isBookend={isBookend}
                      path={"b"}
                    >
                      <FormattedMessage id="process > button upload" />
                    </UploadFile>
                  ) : (
                    <div className={bem.e("spot-production")}>
                      <FormattedMessage id="process > title spot in production" />
                    </div>
                  ))}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
  return <div></div>;
}
function FileInstructions(props) {
  const { process, intl, isLoading, updateFinalScript } = props;
  const [isOpen, setIsOpen] = useState("");
  const items = useMemo(() => {
    if (!process) return [];
    return getFileInstructions(process, intl);
  }, [process]);
  const contentHeight = useRef(0);
  const processStep = get(process, "_source.process_step", "");
  useEffect(() => {
    let newOpen = "";
    if (
      processStep === "assign_team" &&
      items.find((i) => i.key === "creative_details").isCompleted
    ) {
      newOpen = "creative_details";
    } else if (
      (processStep === "write_script" || processStep === "approve_script") &&
      items.find((i) => i.key === "approved_script").isCompleted
    ) {
      newOpen = "approved_script";
    } else if (
      (processStep === "record_script" ||
        processStep === "approve_recording") &&
      items.find((i) => i.key === "approved_recoding").isCompleted
    ) {
      newOpen = "approved_recoding";
    } else if (
      processStep === "continuity" &&
      items.find((i) => i.key === "traffic_instructions").isCompleted
    ) {
      newOpen = "traffic_instructions";
    }
    if (newOpen !== isOpen) setIsOpen(newOpen);
  }, [processStep]);
  useEffect(() => {
    const sidebar = document.getElementById("right-sidebar");
    contentHeight.current =
      sidebar.offsetHeight - HEADER_HEIGHT - LIST_ITEMS_HEIGHT;
  }, [isOpen]);
  return (
    <div className={bem.b()}>
      {items.map((item) => (
        <div key={item.key} className={bem.e("instruction-item")}>
          <div
            className={bem.e("instruction-header")}
            onClick={() => {
              if (isOpen === item.key) {
                setIsOpen(null);
              } else {
                setIsOpen(item.key);
              }
            }}
          >
            <span className={bem.e("section-title")}>
              {item.isCompleted ? (
                <CheckDoneIcon color="#73A703" />
              ) : (
                <span className={bem.e("fake-icon")} />
              )}
              {item.title}
            </span>
            <div className={bem.e("section-action")}>
              {isOpen === item.key ? <ArrowDownIcon /> : <ArrowUpIcon />}
            </div>
          </div>
          {isOpen === item.key && (
            <div
              className={classNames(
                bem.e("section-content"),
                "scroll-bar-style"
              )}
              style={{
                height: contentHeight.current,
              }}
            >
              <SectionContent
                item={item}
                process={process}
                intl={intl}
                updateFinalScript={updateFinalScript}
              />
            </div>
          )}
        </div>
      ))}
      <Spinner isLoading={isLoading} />
    </div>
  );
}
FileInstructions.defaultProps = {
  process: {},
};
FileInstructions.propTypes = {
  process: PropTypes.object,
};
export default FileInstructions;
