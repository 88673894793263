import * as AuthTypes from "store/types/auth";
import { pick, get, uniq, forEach } from "lodash";
import { combineStationPivileges, getTranslates } from "utils/helpers";
import * as Types from "store/types/users";
import { defaultOrderSectionsContest } from "utils/constants";

const initialState = {
  info: {},
  user: {
    avatar: "", // https://promosuite-live.s3.amazonaws.com/9/c/d/e-8597-446b-860a-2c3ea47ca8013540f458-cfcb-4f07-b94f-3b7b173bf785||avatar_white_circle.png
    stations: [{ user_privileges: {}, station_privileges: {} }],
    privileges: [],
    is_team_lead: false,
  },
  user_defaults: {
    name: "",
    avatar_url: "",
    stations: [
      {
        station_key: "ventive",
        user_station_id: 1,
        last_login: "",
        user_privileges: {
          station_privileges: [
            "add_stations",
            "edit_stations",
            "view_stations",
            "delete_stations",
            "add_servers",
            "edit_servers",
            "view_servers",
            "delete_servers",
            "add_users",
            "edit_users",
            "view_users",
            "delete_users",
            "assign_privileges",
            "revoke_privileges",
            "view_templates",
            "edit_templates",
            "delete_templates",
          ],
        },
        station_privileges: [
          "add_stations",
          "edit_stations",
          "view_stations",
          "delete_stations",
          "add_servers",
          "edit_servers",
          "view_servers",
          "delete_servers",
          "add_users",
          "edit_users",
          "view_users",
          "delete_users",
          "assign_privileges",
          "view_templates",
          "edit_templates",
          "delete_templates",
        ],
      },
    ],
    current_station: "ventive",
  },
  token: "",
  refresh_token: "",
  expires_at: null,
  messages: [],
  authenticated: false,
  notify: localStorage.getItem("notify") === "true",
  user_stations: [],
  loading: false,
  is_reminder_open: false,
  is_sidebar_open: true,
  sidebar_active: null,
  schedule_calendar_filter: {},
  order_sections_contest: defaultOrderSectionsContest,
  scheduling_type: "simple",
  openRightPanel: true,
  countConfirmationMasterList: 0,
  promotions_contest_jock_introductions_text: {},
  should_block_navigation: false,
  sort_processes_queue: {},
};

const combinePivileges = (payload) => {
  let combined = [];
  forEach(payload.stations, (station) => {
    combined = uniq(combined.concat(combineStationPivileges(station)) || []);
  });
  // privileges
  const privileges =
    typeof get(payload, "privileges") === "object"
      ? Object.values(payload.privileges)
      : [];
  return uniq(combined.concat(privileges));
};

export default function(state = initialState, action) {
  switch (action.type) {
    case AuthTypes.TOGGLE_RIGHT_PANEL: {
      return {
        ...state,
        openRightPanel: action.payload,
      };
    }

    case AuthTypes.SET_NOTIFICATION:
      localStorage.setItem("notify", action.payload);
      return {
        ...state,
        notify: action.payload,
      };
    case AuthTypes.SET_IS_SIDEBAR_OPEN:
      return { ...state, is_sidebar_open: action.payload };
    case AuthTypes.USER_INFO_SUCCESS:
      // var addedroles = [];
      // var roles = {};
      // action.payload.stations &&
      //   action.payload.stations.map(
      //     (s) =>
      //       s.station_users &&
      //       s.station_users.map((su) => {
      //         if (su.id === action.payload.id) {
      //           su.stations.map((us) =>
      //             Object.keys(us.user_roles).map((urk) => {
      //               var ur = us.user_roles[urk];
      //               if (addedroles.indexOf(ur) === -1) {
      //                 addedroles.push(ur);
      //                 roles[urk] = us.user_roles[urk];
      //               }
      //             })
      //           );
      //         }
      //       })
      //   );
      // let loggedInUser = (
      //   get(action, "payload.users", []) ||
      //   get(action, "payload.info.users", [])
      // ).find((su) => su.id === (action.payload.id || action.payload.info.id));
      // if (loggedInUser)
      //   loggedInUser.stations.map((us) =>
      //     Object.keys(us.user_roles).map((urk) => {
      //       var ur = us.user_roles[urk];
      //       if (addedroles.indexOf(ur) === -1) {
      //         addedroles.push(ur);
      //         roles[urk] = us.user_roles[urk];
      //       }
      //     })
      //   );
      return {
        ...state,
        info: { ...state.info, ...action.payload },
        user: {
          ...state.user,
          privileges: combinePivileges(action.payload),
        },
      };
    case AuthTypes.AUTH_REQUEST:
      return { ...state, loading: true };
    case AuthTypes.IMPERSONATE_REQUEST:
      return {
        ...state,
        loading: true,
        original_impersonating_admin_state: { ...state, info: {} },
      };
    case AuthTypes.IMPERSONATE_STOP_REQUEST:
      const original_impersonating_admin_state = {
        ...state.original_impersonating_admin_state,
      };
      return {
        ...original_impersonating_admin_state,
        loading: true,
        go_dark: action.payload.go_dark,
        original_impersonating_admin_state: null,
      };
    case AuthTypes.IMPERSONATE_SUCCESS:
      let impersonation_history =
        state.original_impersonating_admin_state &&
        state.original_impersonating_admin_state.impersonation_history
          ? [...state.original_impersonating_admin_state.impersonation_history]
          : [];
      if (!impersonation_history.find((u) => u.id === action.payload.id))
        impersonation_history.unshift(action.payload);
      if (impersonation_history.length > 10) impersonation_history.pop();
      return {
        ...state,
        loading: false,
        user: {
          ...state.user,
          impersonated_by: {
            id: state.original_impersonating_admin_state.user.id,
            name: state.original_impersonating_admin_state.user.name,
            avatar_url:
              state.original_impersonating_admin_state.user.avatar_url,
          },
        },
        impersonation_history,
        original_impersonating_admin_state: {
          ...state.original_impersonating_admin_state,
          impersonation_history,
        },
      };
    case AuthTypes.AUTH_USER: {
      const {
        token,
        refresh_token,
        expires_at,
        user,
        user: { stations },
        ...rest
      } = action.payload;
      user.avatar_url =
        typeof user !== "undefined" && user.avatar_url
          ? user.avatar_url
          : get(state, "user_defaults.avatar_url", "");
      user.avatar_url = user.avatar_url.replace("http://", "https://");

      return {
        ...state,
        authenticated: true,
        error: null,
        token,
        refresh_token,
        expires_at,
        notify: !!+get(user, "notify"),
        user: {
          ...state.user,
          ...user,
          stations,
          current_station: get(stations, "0.station_key"),
          privileges: combinePivileges(user),
        },
        loading: false,
        go_dark: false,
        ...rest,
      };
    }
    case AuthTypes.USER_UPDATE_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          ...get(action, "payload.user"),
          notify: !!+get(action, "payload.user.notify"),
          privileges: get(state, "user.privileges"),
          stations: get(state, "user.stations"),
        },
      };
    case AuthTypes.GET_USER_STATIONS_SUCCESS:
      return {
        ...state,
        user_stations: get(action, "payload.data", []),
      };
    case AuthTypes.SET_LANGUAGE:
      const translates = getTranslates(action.payload);
      return {
        ...state,
        info: {
          ...state.info,
          translates,
        },
      };
    case AuthTypes.GET_USER_INFO_TAGS_REQUEST:
      return state;
    case AuthTypes.GET_USER_INFO_TAGS_SUCCESS:
      return {
        ...state,
        info: {
          ...state.info,
          tags: action.payload,
        },
      };
    case AuthTypes.GET_USER_INFO_TAGS_ERROR:
      return { ...state, error: action.payload };
    case AuthTypes.GET_USER_INFO_USERS_REQUEST:
      return state;
    case AuthTypes.GET_USER_INFO_USERS_SUCCESS:
      const relatedUsers = get(action, "payload", []).map((u) => {
        return {
          ...u,
          avatar_url: !u.avatar_url
            ? state.user_defaults.avatar_url
            : u.avatar_url.replace("http://", "https://"),
        };
      });
      return {
        ...state,
        info: { ...state.info, users: relatedUsers },
      };
    case AuthTypes.GET_USER_INFO_USERS_ERROR:
      return { ...state, error: action.payload };
    case Types.UPDATE_SECURITY_POLICY_USER_SUCCESS:
      const existingUsers = get(state, "user");
      const { data } = action.payload;
      if (data.id !== existingUsers.id) return state;
      return {
        ...state,
        user: {
          ...existingUsers,
          ...pick(data, [
            "domain_filter",
            "password_complexity_pattern",
            "replace_password_days",
            "reuse_password_days",
            "system_idle_timeout",
          ]),
        },
      };
    case AuthTypes.LOGOUT_USER:
      return {
        ...initialState,
        order_sections_contest: state.order_sections_contest,
        promotions_contest_jock_introductions_text:
          state.promotions_contest_jock_introductions_text,
        scheduling_type: state.scheduling_type,
        countConfirmationMasterList: state.countConfirmationMasterList,
        impersonation_history: state.impersonation_history,
      };
    case AuthTypes.AUTH_ERROR:
      return { ...state, error: action.payload, loading: false };
    case AuthTypes.SET_IS_REMINDER_OPEN:
      return {
        ...state,
        is_reminder_open: get(action, "payload", false),
      };
    case AuthTypes.SET_IS_TEAM_LEAD:
      return {
        ...state,
        user: {
          ...get(state, "user", {}),
          is_team_lead: get(action, "payload", false),
        },
      };
    case AuthTypes.SET_SIDEBAR_ACTIVE:
      return {
        ...state,
        sidebar_active: get(action, "payload", null),
      };
    case AuthTypes.SET_SCHEDULE_CALENDAR_FILTER:
      if (!get(action, "payload.station_id")) {
        return state;
      }
      return {
        ...state,
        schedule_calendar_filter: {
          ...get(state, "schedule_calendar_filter", {}),
          [get(action, "payload.station_id")]: get(action, "payload", {}),
          selected_station_id: get(action, "payload.station_id"),
        },
      };
    case AuthTypes.SET_ORDER_SECTIONS_CONTEST:
      return {
        ...state,
        order_sections_contest: get(action, "payload", []),
      };
    case AuthTypes.SET_COUNT_CONFIRMATION_MASTER_LIST: {
      return {
        ...state,
        countConfirmationMasterList: action.payload,
      };
    }
    case AuthTypes.SET_SCHEDULING_TYPE: {
      return {
        ...state,
        scheduling_type: action.payload,
      };
    }
    case AuthTypes.SET_PROMOTIONS_CONTEST_JOCK_INTRODUCTIONS_TEXT: {
      return {
        ...state,
        promotions_contest_jock_introductions_text: {
          ...state.promotions_contest_jock_introductions_text,
          ...action.payload,
        },
      };
    }
    case AuthTypes.REFRESH_ACCESS_TOKEN_SUCCESS: {
      return {
        ...state,
        token: action.payload.token,
        refresh_token: action.payload.refresh_token,
        expires_at: action.payload.expires_at,
      };
    }
    case AuthTypes.SET_SORT_PROCESSES_QUEUE_SUCCESS: {
      return {
        ...state,
        sort_processes_queue: {
          ...(state.sort_processes_queue || {}),
          ...(action.payload || {}),
        },
      };
    }

    default:
      return state;
  }
}
