import { FormattedMessage } from "react-intl";
import React from "react";
import { get, delay } from "lodash";
import history from "components/History";
import { checkUserRedirectToCurrentTasks } from "utils/helpers";

const assignVo = {
  process_step_index: 4.1,
  key: "assign_vo",
  title: <FormattedMessage id="process > title assign vo" />,
  caption_color: "#129AA3",
  description: <FormattedMessage id="process > voice over instructions" />,
  step_roles: [5],
  step_viewers_roles: [6, 5, 4, 7, 2],
  step_editors_roles: [5, 4, 7],
  step_viewers_privileges: {
    or: ["submit_production_recording", "edit_all_production_orders"],
  },
  step_editors_privileges: {
    or: ["submit_production_recording", "edit_all_production_orders"],
  },
  className: "assign-vo",
  isCollapsible: true,
  is_check_hidden_tab: true,
  isOpen: function({ step, process, template, user }) {
    return true;
  },
  is_hidden: function(user, process, step) {
    const producerAssignsVo = get(
      process,
      "data.fields.producer_assigns_vo.value",
      false
    );
    if (!producerAssignsVo) return true;
    return false;
  },
  status: "initial",
  fields: [
    {
      component: "voice_over_details",
    },
    {
      field: "voice_deadline",
      component: "production_deadlines",
      container: "segment",
      isCollapsible: true,
      title: <FormattedMessage id="process > voice deadline" />,
      props: {
        classNameSegment: "segment-production-deadline",
        isOwnSegment: true,
      },
    },
    {
      field: "record_deadline",
      title: <FormattedMessage id="process > record deadline" />,
    },
    {
      field: "voice_deadline_modified",
    },
    {
      field: "record_deadline_modified",
    },
    {
      field: "script",
      container: "segment",
      component: "text_timer",
      isCollapsible: false,
      isSpot: true,
      editButton: true,
      isShowEditButtonViewMode: false,
      hide_open_in_new_window: true,
      props: {
        classNameSegment: "segment-script voice-script",
        className: "form-div-script",
        readOnly: true,
        hideHelperText: true,
      },
      title: <FormattedMessage id="process > title final script" />,
    },
    {
      field: "role_voice_talent",
      roles: [6],
      privileges: { or: ["edit_vo"] },
      mandatory: true,
      container: "segment",
      component: "select_team_vo",
      isCollapsible: true,
      props: {
        classNameSegment: "segment-select-team-vo",
        isOwnSegment: true,
      },
      title: <FormattedMessage id="process > field voice over" />,
      validation: function(step, item, process, template, user, value) {
        return get(value, "users.length", 0) > 0 ? true : "Please select team";
      },
    },
    { field: "spot_info" },

    {
      // component: "button",
      key: "save_button",
      text: <FormattedMessage id="process > button assign vo team" />,
      fields: [
        {
          key: "assign_vo_status",
        },
        {
          key: "process_step",
          value: "first_undone",
        },
      ],
      confirmation_dialog_props: {
        title: (
          <FormattedMessage id="process > description to submit assignment vo" />
        ),
        confirmTitle: <FormattedMessage id="process > button yes" />,
        cancelTitle: <FormattedMessage id="process > button no" />,
      },
      success_dialog_props: {
        title: <FormattedMessage id="process > title great looking team" />,
        description: (
          <FormattedMessage id="process > the members have been notified about their new assignment" />
        ),
        className: "assigned-great-modal",
        state: "success",
      },
      on_click: {
        validate: true,
        save: true,
        go_to: function(_this, step, item, process, template, user, extraData) {
          const return_to_current_tasks = checkUserRedirectToCurrentTasks({
            ...user,
            roles_string: extraData.roles_string,
          });
          if (!return_to_current_tasks) return;
          // set delay when after success message
          delay(() => {
            if (_this.props.setSidebarActive) {
              _this.props.setSidebarActive(`/processes/my_action_items`);
            }
            history.replace(`/processes/my_action_items`);
          }, 4000);
        },
      },
    },
  ],
};
export default assignVo;
