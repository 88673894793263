import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import {
  compose,
  withHandlers,
  withState,
  withPropsOnChange,
} from "recompose";
import { isEqual, get } from "lodash";
import moment from "moment";
import {
  getQuickViewDetails,
} from "store/actions/programmings";
import ContestsQuickView from "./view";

const defaultDateFormat = "YYYY-MM-DD";

const Enhanced = compose(
  connect(
    (state, props) => {
      const quickView = get(state, "programmings.quickView", {});
      return {
        defaultDateFormat,
        loading: quickView.loading,
        data: get(quickView, "data", {}),
        params: get(quickView, "params", {}),
        ...props,
      };
    },
    {
      getQuickViewDetails,
    }
  ),
  withState("isOneMonth", "setIsOneMonth", false),
  withState("startDate", "setStartDate", moment().startOf("month").format(defaultDateFormat)),
  withState("endDate", "setEndDate", moment().add(2, "months").endOf("month").format(defaultDateFormat)),
  withState("showQuickView", "setShowQuickView", false),
  withState("selectedStation", "setSelectedStation", ""),
  withState("selectedDate", "setSelectedDate", new Date()),
  withState("selectedSchedule", "setSelectedSchedule", null),
  withState("currentWidget", "setCurrentWidget", null),  
  withHandlers({
    fetchQuickViewDetails: ({ getQuickViewDetails, params }) => (paramOverride) => {
      const data = {
        ...params,
        ...paramOverride,
      };
      if (!isEqual(params, data)) {
        getQuickViewDetails({ data });
      }
    },
    onBackClick: ({ startDate, setStartDate, endDate, setEndDate }) => () => {
      const newStartDate = moment(startDate, defaultDateFormat).subtract(1, "month").startOf("month").format(defaultDateFormat);
      const newEndDate = moment(endDate, defaultDateFormat).subtract(1, "month").endOf("month").format(defaultDateFormat);
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    },
    onNextClick: ({ startDate, setStartDate, endDate, setEndDate }) => () => {
      const newStartDate = moment(startDate, defaultDateFormat).add(1, "month").startOf("month").format(defaultDateFormat);
      const newEndDate = moment(endDate, defaultDateFormat).add(1, "month").endOf("month").format(defaultDateFormat);
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    },
  }),
  withPropsOnChange(
    ["isOneMonth"],
    ({ data, startDate, setStartDate, endDate, setEndDate, fetchQuickViewDetails, isOneMonth, process }) => {
      const newStartDate = moment(startDate, defaultDateFormat).startOf("month").format(defaultDateFormat);
      const newEndDate = moment(startDate, defaultDateFormat).add(isOneMonth ? 0 : 2, "months").endOf("month").format(defaultDateFormat);
      setStartDate(newStartDate);
      setEndDate(newEndDate);
      if (!data) return;
      const selectedStation = get(
        process,
        "data.fields.order_stations_array.value.0"
      );
      if (selectedStation) {
        const overrideData = {
          station_id: selectedStation.key,
          start_date: newStartDate,
          end_date: newEndDate,
        };
        fetchQuickViewDetails(overrideData);
      }
    }
  ),
  withPropsOnChange(
    ["startDate"],
    ({ data, startDate, endDate, fetchQuickViewDetails, process }) => {
      if (!data) return;
      const selectedStation = get(
        process,
        "data.fields.order_stations_array.value.0"
      );
      if (selectedStation) {
        const overrideData = {
          station_id: selectedStation.key,
          start_date: startDate,
          end_date: endDate,
        };
        fetchQuickViewDetails(overrideData);
      }
    }
  ),
)(ContestsQuickView);

export default injectIntl(Enhanced);
