import { injectIntl } from "react-intl";
import {
  omit,
  forEach,
  keys,
  includes,
  has,
  get,
  map,
  concat,
  intersection,
  isEmpty,
  pick,
  forOwn,
  orderBy,
} from "lodash";
import { compose, withState, withHandlers, lifecycle } from "recompose";
import { connect } from "react-redux";

import ContentAssignStations from "./view";
import { updateHubAssignedStations } from "store/actions/hubs";

export default injectIntl(
  compose(
    connect(
      (state, props) => {
        let keyPickEntities = "enterprise_production";
        const entities = get(
          state.servers,
          `currentServer.data.entities.${keyPickEntities}`,
          {}
        );
        let stations = [];
        forOwn(entities, (entityStations) => {
          stations = [...stations, ...entityStations];
        });
        // filter stations from stations assigned to user and stations assigned to config broadcast
        // const filteredStations = auth.info.stations.filter((station) => {
        //   return (
        //     station.station_key &&
        //     find(stations, (i) => i.key === station.station_key)
        //   );
        // });
        const formattedStations = orderBy(
          stations,
          ["call_letters"],
          ["asc"]
        ).map((station) => ({
          ...station,
          station_key: station.key,
          market: station.market
            ? {
                [station.market.id]: station.market,
              }
            : null,
          format: station.format
            ? {
                [station.format.id]: station.format,
              }
            : null,
          tags: station.tags_json.map((item) => item.id),
          team: [],
        }));
        return {
          ...props,
          stations: formattedStations,
          assignedStations: get(state, "hubs.assignedStations.data", []),
        };
      },
      { updateHubAssignedStations }
    ),
    withState("isOpenSuccessModal", "setIsOpenSuccessModal", false),
    withState("selectedTags", "setSelectedTags", {}),
    withState("selectedStations", "setSelectedStations", {}),
    withState("addedStations", "setAddedStations", {}),
    withState("isLoading", "setIsLoading", false),
    withHandlers({
      updateSelectedTags: ({
        setSelectedTags,
        selectedStations,
        setSelectedStations,
      }) => (
        checked,
        { id, label, stations },
        tagType,
        updateStations = true
      ) => {
        if (checked) {
          let tagStations = {};

          forEach(stations, (station) => {
            station = has(selectedStations, station.key)
              ? selectedStations[station.key]
              : station;

            tagStations[station.key] = {
              ...station,
              [tagType]: {
                ...station[tagType],
                [id]: { ...station[tagType][id], selected: true },
              },
            };
          });

          setSelectedTags((previousSelectedTags) => {
            if (updateStations) {
              setSelectedStations({ ...selectedStations, ...tagStations });
            }

            return {
              ...previousSelectedTags,
              [id]: { id, label, type: tagType, stations: tagStations },
            };
          });
        } else {
          setSelectedTags((previousSelectedTags) => {
            let newSelectedTags = omit(previousSelectedTags, id);

            if (updateStations) {
              /*
               * if the stations of the removed tag are not part of any other tag we need to remove those stations from selected stations list
               * If they were selected using another tag, we will unset the selected property for the deleted tag
               */

              let selectedTagStations = {};

              forEach(newSelectedTags, (tag) => {
                selectedTagStations = concat(
                  selectedTagStations,
                  keys(tag.stations)
                );
              });

              let newSelectedStations = {};

              //all stations other than the removed tag's
              forEach(selectedStations, (station) => {
                if (!includes(keys(stations), station.key)) {
                  newSelectedStations[station.key] = station;
                }
              });

              //now loop through removed tag's stations and remove  their selected attribute for the removed tag if they are part of other tag's stations
              forEach(stations, (station) => {
                station = has(selectedStations, station.key)
                  ? selectedStations[station.key]
                  : station;

                if (includes(selectedTagStations, station.key)) {
                  newSelectedStations[station.key] = {
                    ...station,
                    [tagType]: {
                      ...station[tagType],
                      [id]: omit(station[tagType][id], "selected"),
                    },
                  };
                }
              });

              setSelectedStations(newSelectedStations);
            }

            return newSelectedTags;
          });
        }
      },
      closeModal: ({
        setSelectedStations,
        setSelectedTags,
        setAddedStations,
        onToggle,
      }) => () => {
        setSelectedTags({});
        setSelectedStations({});
        setAddedStations({});
        onToggle();
      },
    }),
    withHandlers({
      updateSelectedStations: ({
        selectedStations,
        setSelectedStations,
        selectedTags,
        updateSelectedTags,
      }) => (checked, station) => {
        if (checked) {
          setSelectedStations({ ...selectedStations, [station.key]: station });
        } else {
          let newSelectedStations = omit(selectedStations, station.key);
          setSelectedStations(newSelectedStations);

          forEach(selectedTags, (tag) => {
            if (
              isEmpty(
                intersection(keys(tag.stations), keys(newSelectedStations))
              )
            ) {
              updateSelectedTags(false, tag, tag.type, false);
            }
          });
        }
      },
      onSubmit: ({
        addedStations,
        updateHubAssignedStations,
        selectedHub,
        setIsOpenSuccessModal,
        onCancel,
      }) => () => {
        updateHubAssignedStations(
          {
            id: selectedHub.id,
            stations: addedStations,
          },
          () => {
            setIsOpenSuccessModal(true);
            setTimeout(() => onCancel(), 2500);
          }
        );
      },
    }),
    lifecycle({
      componentDidMount() {
        const assignedStations = map(
          this.props.assignedStations,
          (item) => item.key
        );
        let selectedStations = {};

        this.props.stations
          .filter((station) => includes(assignedStations, station.key))
          .forEach((station) => {
            selectedStations[station.key] = pick(station, [
              "key",
              "station_key",
              "market",
              "format",
              "name",
              "call_letters"
            ]);
          });
        this.props.setAddedStations(selectedStations);
      },
    })
  )(ContentAssignStations)
);
