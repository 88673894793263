import history from "components/History";
import * as Types from "store/types/prizes";
import * as Const from "utils/constants";
import Api from "utils/api";
import URL from "utils/urls";
import { get, omit, isFunction } from "lodash";
import {
  INIT_NEW_PRIZE,
  PRIZE_VALUE_CHANGED,
  SAVE_FORM_VALUES_SUCCESS,
  SAVE_FORM_VALUES_ERROR,
  FETCH_PRIZES_REQUEST,
  FETCH_PRIZES_SUCCESS,
  FETCH_PRIZES_ERROR,
  FETCH_PRIZE_SUCCESS,
  FETCH_PRIZE_ERROR,
  SET_ACTIVE_TAB,
  FORGET_PRIZES,
  SET_TAB_TO_EDIT,
  RESET_TAB,
  PRIZE_SET_PRIZES_SUCCESS,
} from "store/types/prizes";
import queryString from "query-string";
export function formValueChanged(payload) {
  return {
    type: PRIZE_VALUE_CHANGED,
    payload,
  };
}

export const initNewPrize = (payload) => (dispatch) => {
  dispatch({
    type: INIT_NEW_PRIZE,
    payload,
  });
};

export const setActiveTab = (payload) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_TAB,
    payload,
  });
};

export const setActiveEditTab = (payload) => (dispatch) => {
  dispatch({
    type: SET_TAB_TO_EDIT,
    payload,
  });
};
//get all prizes
export const getPrize = (id) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.FETCH_PRIZE_REQUEST, payload: { id } });
    let response = await Api.doCall(Const.GET_PRIZE(id), "GET");
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch(fetchPrizeSuccess({ id, data: response.data }));
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch(fetchPrizeError({ id, error: errorStr }));
};

//save prizes values to ES
export const saveFormValues = (data, success_callback) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.ADD_PRIZE_REQUEST });
    //api call for store data
    const response = await Api.doCall(Const.ADD_PRIZES(), "POST", data);
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        //response data
        const responseData = JSON.parse(JSON.stringify(response.data));
        dispatch({ type: Types.ADD_PRIZE_SUCCESS, payload: responseData });
        // refresh prizes when adding new
        dispatch(forgetPrizes());

        if (isFunction(success_callback)) {
          success_callback(responseData);
        } else {
          //redirect to list page
          history.push(URL.PRIZES({ filter: "prizes_list" }));
        }
        return;
      }
      //error data
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  //return error
  dispatch({ type: Types.ADD_PRIZE_ERROR, payload: errorStr });
};

//update prizes values to ES
export const updatePrizes = (id, data, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.UPDATE_PRIZE_REQUEST });
    //api call for store data
    let response = await Api.doCall(Const.GET_PRIZE(id), "PUT", data);
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        //response data
        let responseData = JSON.parse(JSON.stringify(response.data));
        dispatch({ type: Types.UPDATE_PRIZE_SUCCESS, payload: responseData });
        // refresh prizes when updating
        dispatch(forgetPrizes());
        history.push(URL.PRIZES({ filter: "prizes_list" }));
        if (isFunction(cb)) cb();
        return;
      }
      //error data
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  //return error
  dispatch({ type: Types.UPDATE_PRIZE_ERROR, payload: errorStr });
};

export function saveFormValuesSuccess(payload) {
  return {
    type: SAVE_FORM_VALUES_SUCCESS,
    payload,
  };
}
export function forgetPrizes(payload) {
  return {
    type: FORGET_PRIZES,
    payload,
  };
}
export function saveFormValuesError(payload) {
  return {
    type: SAVE_FORM_VALUES_ERROR,
    payload,
  };
}

export function fetchPrizeSuccess(payload) {
  return {
    type: FETCH_PRIZE_SUCCESS,
    payload,
  };
}

export function fetchPrizeError(payload) {
  return {
    type: FETCH_PRIZE_ERROR,
    payload,
  };
}

export const deletePrize = (id, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.DELETE_PRIZE_REQUEST });
    let response = await Api.doCall(Const.GET_PRIZE(id), "DELETE");
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({ type: Types.DELETE_PRIZE_SUCCESS, payload: id });
        dispatch(forgetPrizes());
        history.push(URL.PRIZES({ filter: "prizes_list" }));
        if (isFunction(cb)) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.DELETE_PRIZE_ERROR, payload: errorStr });
};

export const getPrizesAll = (state) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.FETCH_PRIZE_REQUEST });
    let response = await Api.doCall(`${Const.GET_PRIZES_ALL()}`, "GET");
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch(fetchPrizeSuccess(response.data));
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch(fetchPrizeError({ data: errorStr }));
};

export const getPrizes = (payload) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: FETCH_PRIZES_REQUEST, payload });
    if (get(payload, "reqQuantity")) {
      payload.req_quantity = payload.reqQuantity;
    }
    const url = `${Const.GET_PRIZES(payload.filter)}?${queryString.stringify(
      payload,
      {
        arrayFormat: "bracket",
      }
    )}`;
    const response = await Api.doCall(url, "GET");
    if (response.status === 200) {
      if (!response.data.error) {
        dispatch(
          fetchPrizesSuccess({
            ...omit(payload, ["page"]),
            page: get(response, "data.page", 1),
            pages: get(response, "data.pages", 1),
            data: get(response, "data.items", []),
          })
        );
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch(fetchPrizesError({ filter: payload.filter, error: errorStr }));
};

export function fetchPrizesSuccess(payload) {
  return {
    type: FETCH_PRIZES_SUCCESS,
    payload,
  };
}

export function fetchPrizesError(payload) {
  return {
    type: FETCH_PRIZES_ERROR,
    payload,
  };
}

export function resetTab() {
  return {
    type: RESET_TAB,
  };
}

export const setPrizes = (payload = {}) => async (dispatch) => {
  dispatch({
    type: PRIZE_SET_PRIZES_SUCCESS,
    payload,
  });
};
