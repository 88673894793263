import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalHeader, Button, FormGroup } from "reactstrap";
import classnames from "classnames";
import bn from "utils/bemnames";
import { FormattedMessage, injectIntl } from "react-intl";

const bem = bn.create("note-no-winner-modal");

const AddNoteNoWinnerModal = ({
  isOpen,
  onToggle,
  onSubmitted,
  value,
  intl,
}) => {
  const [note, setNote] = useState("");
  useEffect(() => {
    if (value !== note) {
      setNote(value);
    }
  }, [value, isOpen]);
  const handleSubmit = () => {
    onSubmitted("completed", true, note);
    setNote("");
    onToggle();
  };

  const handleDismiss = () => {
    setNote("");
    onToggle();
  };

  return (
    <Modal isOpen={isOpen} size="md" className={classnames(bem.b())}>
      <ModalHeader toggle={onToggle} className={bem.e("header")}>
        <FormattedMessage id="jock console > add a note" />
      </ModalHeader>
      <ModalBody className={bem.e("body")}>
        <span className={bem.e("description-text")}>
          <FormattedMessage id="jock console > add a note description" />
        </span>
        <textarea
          placeholder={intl.formatMessage({
            id: "jock console > start typing",
          })}
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
        <div className={bem.e("buttons")}>
          <FormGroup>
            <Button
              color="blue"
              type="button"
              className="btn btn-radius"
              onClick={handleSubmit}
            >
              <FormattedMessage id="jock console > save button submit" />
            </Button>
            <Button
              color="blue"
              outline
              className="btn btn-radius"
              onClick={handleDismiss}
            >
              <FormattedMessage id={`jock console > button cancel`} />
            </Button>
          </FormGroup>
        </div>
      </ModalBody>
    </Modal>
  );
};

AddNoteNoWinnerModal.propTypes = {
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
};

AddNoteNoWinnerModal.defaultProps = {
  onToggle: () => {},
};

export default injectIntl(AddNoteNoWinnerModal);
