import * as Types from "store/types/clients";
import { get } from "lodash";

const initialState = {
  clients: {
    data: {},
    error: null,
    loading: false,
    is_dirty: false,
  },
  client: {
    data: null,
    error: null,
    loading: false,
    is_dirty: false,
  },
  updateClient: {
    error: null,
    loading: false,
  },
  createClient: {
    error: null,
    loading: false,
  },
  reassignClients: {
    error: null,
    loading: false,
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_CLIENTS_REQUEST: {
      return {
        ...state,
        clients: {
          data: get(state, "clients.data"),
          error: null,
          loading: true,
          is_dirty: false,
        },
      };
    }

    case Types.FETCH_CLIENTS_SUCCESS: {
      const clientsData = action.payload;
      const isMore = get(action, "payload.page") > 1;
      if (isMore) {
        clientsData.items = [
          ...get(state, "clients.data.items"),
          ...get(action, "payload.items", []),
        ];
      }
      return {
        ...state,
        clients: {
          data: clientsData,
          error: null,
          loading: false,
          is_dirty: false,
        },
      };
    }

    case Types.FETCH_CLIENTS_ERROR: {
      return {
        ...state,
        clients: {
          data: null,
          error: action.payload,
          loading: false,
          is_dirty: false,
        },
      };
    }
    case Types.FETCH_CLIENT_REQUEST: {
      return {
        ...state,
        client: {
          ...state.client,
          loading: true,
          error: null,
        },
      };
    }

    case Types.FETCH_CLIENT_SUCCESS: {
      return {
        ...state,
        client: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    }

    case Types.FETCH_CLIENT_ERROR: {
      return {
        ...state,
        client: {
          client: null,
          loading: false,
          error: action.payload,
        },
      };
    }

    case Types.UPDATE_CLIENT_REQUEST: {
      return {
        ...state,
        updateClient: {
          loading: true,
          error: null,
        },
      };
    }

    case Types.UPDATE_CLIENT_SUCCESS: {
      return {
        ...state,
        clients: {
          ...get(state, "clients"),
          is_dirty: true,
        },
        updateClient: {
          loading: false,
          error: null,
        },
      };
    }

    case Types.UPDATE_CLIENT_ERROR: {
      return {
        ...state,
        updateClient: {
          loading: false,
          error: action.payload,
        },
      };
    }

    case Types.SET_CLIENT: {
      return {
        ...state,
        client: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    }
    case Types.CREATE_CLIENT_REQUEST: {
      return {
        ...state,
        createClient: {
          loading: true,
          error: null,
        },
      };
    }

    case Types.CREATE_CLIENT_SUCCESS: {
      return {
        ...state,
        clients: {
          ...get(state, "clients"),
          is_dirty: true,
        },
        createClient: {
          loading: false,
          error: null,
        },
      };
    }

    case Types.CREATE_CLIENT_ERROR: {
      return {
        ...state,
        createClient: {
          loading: false,
          error: action.payload,
        },
      };
    }

    case Types.REASSIGN_CLIENTS_REQUEST: {
      return {
        ...state,
        reassignClients: {
          loading: true,
          error: null,
        },
      };
    }
    case Types.REASSIGN_CLIENTS_SUCCESS: {
      return {
        ...state,
        clients: {
          ...get(state, "clients"),
          is_dirty: true,
        },
      };
    }

    case Types.REASSIGN_CLIENTS_ERROR: {
      return {
        ...state,
        reassignClients: {
          loading: false,
          error: action.payload,
        },
      };
    }
      
    case Types.DELETE_CLIENT_REQUEST: {
      return {
        ...state,
        deleteClient: {
          loading: true,
          error: null,
        },
      };
    }
    case Types.DELETE_CLIENT_SUCCESS: {
      return {
        ...state,
        clients: {
          ...get(state, "clients"),
          is_dirty: true,
        },
        deleteClient: {
          loading: false,
          error: null,
        }
      };
    }

    case Types.DELETE_CLIENT_ERROR: {
      return {
        ...state,
        deleteClient: {
          loading: false,
          error: action.payload,
        },
      };
    }

    case Types.SEND_MESSAGE_CLIENT_REQUEST: {
      return {
        ...state,
        sendMessage: {
          loading: true,
          error: null,
        },
      };
    }

    case Types.SEND_MESSAGE_CLIENT_SUCCESS: {
      return {
        ...state,
        sendMessage: {
          loading: false,
          error: null,
        },
      };
    }

    case Types.SEND_MESSAGE_CLIENT_ERROR: {
      return {
        ...state,
        sendMessage: {
          loading: false,
          error: action.payload,
        },
      };
    }

    default:
      return state;
  }
}
