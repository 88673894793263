import React from "react";
import Page from "components/Page";
import { Row, Col, Button, Form, FormGroup, Label, Input, Card, CardBody, FormText } from "reactstrap";
import * as Yup from "yup";
import { Formik } from "formik";
import { FormattedMessage } from "react-intl";

class AdminImport extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      requestData :{},
      indexName:''
    }
    this.purgeAll = this.purgeAll.bind(this)
    this.deleteIndexes = this.deleteIndexes.bind(this)
    this.deleteIndex = this.deleteIndex.bind(this)

  }

  purgeAll = ()=>{
    let confirm = window.confirm(this.props.intl.formatMessage({id: "admin import > are you sure? do you want to delete all stations and users?"}))
    if (confirm) {
      this.props.purgeRecords()
    }
  }

  deleteIndexes = ()=>{
    let confirm = window.confirm(this.props.intl.formatMessage({id: "admin import > are you sure? do you want to delete all indexes?"}))
    if (confirm) {
      this.props.deleteAllIndexes()
    }
  }

  deleteIndex = (indexName)=>{
    this.setState({
      indexName : indexName
    })
    let confirm = window.confirm(this.props.intl.formatMessage({id: "admin import > are you sure? do you want to delete this index?"}))
    if (confirm) {
      this.props.deleteIndex(indexName)
    }
  }
  
  render() {
    const {requestData,indexName} = this.state;
    const {import_loading, purge_loading, index_loading, single_loading} = this.props;
    const {servers} = this.props;
    return (<Page
                title={<FormattedMessage id="pages > admin import" />}
                breadcrumbs={[
                  { name: this.props.intl.formatMessage({id: "breadcrumbs > import"}), active: false },
                ]}>
                <Row>
                  <Col>
                    <Card>
                      <CardBody>
                      <Col>
                        <Button onClick={this.purgeAll} disabled={purge_loading}>
                           <FormattedMessage id={`admin import > ${!purge_loading ? "purge all station data" : "purging"}`} />
                           </Button>
                      </Col>
                      <Col>
                        <Button onClick={this.deleteIndexes} disabled={index_loading}>
                           <FormattedMessage id={`admin import > ${!index_loading ? "delete all indexes" : "deleting"}`} />
                        </Button>  
                      </Col>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <CardBody>
                      <Col>
                        <Button onClick={() =>this.deleteIndex('processes')} disabled={single_loading}>
                           <FormattedMessage id={`admin import > ${single_loading && indexName == 'processes' ? "deleting" : "delete index processes"}`} />
                           </Button>  
                      </Col>
                      <Col>
                        <Button onClick={() =>this.deleteIndex('emails')} disabled={single_loading}>
                           <FormattedMessage id={`admin import > ${single_loading && indexName == "emails" ?"deleting" : "delete index emails"}`} />
                           </Button>  
                      </Col>
                      <Col>
                        <Button onClick={() =>this.deleteIndex('log')} disabled={single_loading}>
                           <FormattedMessage id={`admin import > ${single_loading && indexName == "log" ? "deleting" : "delete index log"}`} />
                           </Button>  
                      </Col>
                      <Col>
                        <Button onClick={() =>this.deleteIndex('events')} disabled={single_loading}>
                           <FormattedMessage id={`admin import > ${single_loading && indexName == "events" ? "deleting" : "delete index events"}`} />
                           </Button>  
                      </Col>
                      <Col>
                        <Button onClick={() =>this.deleteIndex('clients')} disabled={single_loading}>
                           <FormattedMessage id={`admin import > ${single_loading && indexName == "clients" ? "deleting" : "delete index clients"}`} />
                           </Button>  
                      </Col>
                      <Col>
                        <Button onClick={() =>this.deleteIndex('contracts')} disabled={single_loading}>
                           <FormattedMessage id={`admin import > ${single_loading && indexName == "contracts" ? "deleting" : "delete index contracts"}`} />
                           </Button>  
                      </Col>
                      <Col>
                        <Button onClick={() =>this.deleteIndex('messages')} disabled={single_loading}>
                           <FormattedMessage id={`admin import > ${single_loading && indexName == "messages"? "deleting" : "delete index messages"}`} />
                           </Button>  
                      </Col>
                       <Col>
                        <Button onClick={() => this.deleteIndex('prizes')} disabled={single_loading}>
                           <FormattedMessage id={`admin import > ${single_loading && indexName == "prizes" ? "deleting" : "delete index prizes" }`} />
                           </Button>  
                      </Col>
                      </CardBody>
                    </Card>
                  </Col>
                <Col>
                    <Card>
                      <CardBody>
                          <Formik 
                            enableReinitialize
                            initialValues={{
                            server_id:requestData.server_id || '',
                            user_file: requestData.user_file || null,
                            station_file: requestData.station_file || null,
                            }}
                            validationSchema={Yup.object().shape({
                                server_id: Yup.string().required(this.props.intl.formatMessage({id: "validate > please select broadcasting group"})),
                                // user_file: Yup.mixed().required("Please upload user file").test(
                                //   "fileFormat",
                                //   "Unsupported File Format",
                                //   value => {debugger; return value && SUPPORTED_FORMATS.includes(value.type)}
                                // ),
                                // station_file: Yup.mixed().required("Please upload station file").test(
                                //   "fileFormat",
                                //   "Unsupported File Format",
                                //   value => value && SUPPORTED_FORMATS.includes(value.type)
                                // ),

                            })}
                            onSubmit={this.props.onFormSubmit}>
                            {formProps => {
                            const {errors, touched, values, setFieldValue, handleSubmit} = formProps
                            return (
                              <Form onSubmit={handleSubmit} noValidate>
                                <FormGroup className={touched.server_id && errors.server_id ? 'danger' : ''}>
                                <Label for="userFile">
                                  <FormattedMessage id="admin import > user file" />
                                </Label>
                                  <Input type="file"
                                   name="user_file"
                                   accept=".csv"
                                   id="userFile"
                                   onChange={(event) => {
                                      setFieldValue("user_file", event.target.files[0]);
                                   }}
                                    />
                                  <FormText color="muted">
                                    <FormattedMessage id="admin import > only .csv format allowed" />
                                  </FormText>
                                  {errors.user_file && <span className="text-danger"> {errors.user_file} </span>}
                                </FormGroup>
                                  <FormGroup>
                                  <Label for="stationFile"><FormattedMessage id="admin import > station file" /></Label>
                                    <Input type="file"
                                      name="station_file"
                                      id="stationFile"
                                      accept=".csv"
                                      onChange={(event) => {
                                      setFieldValue("station_file", event.target.files[0]);
                                      }}
                                    />
                                    <FormText color="muted">
                                      <FormattedMessage id="admin import > only .csv format allowed" />
                                    </FormText>
                                    {errors.station_file && <span className="text-danger"> {errors.station_file} </span>}
                                </FormGroup>
                                <FormGroup>
                                   <Label for="selectServer"><FormattedMessage id="admin import > broadcasting group" /></Label>
                                    <Input 
                                    type="select" 
                                    name="server_id" 
                                    id="selectServer"
                                    value={values.server_id}
                                    onChange={(event) => {
                                        setFieldValue("server_id", event.target.value);
                                    }}
                                    >
                                    <option key={null} value={null}><FormattedMessage id="admin import > select" /></option>
                                    {
                                      servers.data.map((server)=>{
                                        return (
                                          <option key={server.id} value={server.id}>{server.name}</option>
                                          )
                                      })
                                    }
                                    </Input>
                                    {errors.server_id && <span className="text-danger"> {errors.server_id} </span>}
                                  </FormGroup>                                                                  
                                <FormGroup>
                                  <Button color="blue"
                                    type="submit"
                                    disabled={import_loading}
                                    >
                                    <FormattedMessage id={`process > button ${!import_loading ? "submit" : "submitting"}`} />
                                  </Button>
                                </FormGroup>
                              </Form>
                            );
                          }}
                        </Formik>
                </CardBody>
                </Card>
                </Col>
                </Row>
                </Page>
                )
  }
}
export default AdminImport;
