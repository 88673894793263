import { get } from "lodash";
import { connect } from "react-redux";
import { compose } from "recompose";
import { injectIntl } from "react-intl";

import ResourcesPage from "./view";
import { getResources, deleteResource } from "../../store/actions/resources";

export default injectIntl(
  compose(
    connect(
      (state, props) => {
        return {
          filter:
            get(props, "match.params.filter") +
            get(props, "location.search", ""),
          ...props
        };
      },
      {
        getResources,
        deleteResource
      }
    )
  )(ResourcesPage)
);
