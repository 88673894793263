import * as React from "react";
import PropTypes from "prop-types";
import { FaClose, FaChevronCircleRight } from "react-icons/lib/fa";
import Spinner from "components/Spinner";
import bn from "utils/bemnames";
import { map, includes, get } from "lodash";
import classnames from "classnames";
import { Row, Col, Label, Button } from "reactstrap";
const bem = bn.create("select-multiple-effective");

class SelectMultipleEffective extends React.Component {
  render() {
    const {
      optionsRemove,
      options,
      optionsAdd,
      onRemoveItemFromListAdd,
      onRemoveItemFromListRemove,
      onSelectItem,
      onMoveToAddList,
      onMoveToRemoveList,
      isLoading,
      selectedOptions,
      leftTitle,
      optionsToAddTitle,
      optionsToRemoveTitle
    } = this.props;
    return (
      <div className={bem.b()}>
        <Row className={bem.e("content")}>
          <Col xs={6}>
            <div className={bem.e("left-vertical")}>
              <div className={bem.e("left-box")}>
                <Label className={bem.e("title")}>{leftTitle}</Label>
                <div className={bem.e("list-items")}>
                  <ul>
                    {map(options, (option, index) => (
                      <li
                        className={classnames(
                          bem.e("item"),
                          bem.e("item-text-capitalize"),
                          {
                            [bem.e("item-selected")]: includes(
                              selectedOptions,
                              option
                            )
                          }
                        )}
                        onClick={() => onSelectItem(option)}
                        key={index}
                      >
                        <span>{get(option, "label")}</span>
                        {includes(selectedOptions, option) ? (
                          <span className={bem.e("remove-icon")}>
                            <FaClose />
                          </span>
                        ) : null}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className={bem.e("buttons")}>
                  <Button
                    disabled={selectedOptions.length === 0}
                    color="link"
                    className={bem.e("button")}
                    onClick={onMoveToAddList}
                  >
                    <FaChevronCircleRight />
                  </Button>
                  <Button
                    disabled={selectedOptions.length === 0}
                    color="link"
                    className={bem.e("button")}
                    onClick={onMoveToRemoveList}
                  >
                    <FaChevronCircleRight />
                  </Button>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={6}>
            <div className={bem.e("right-box")}>
              <Label className={bem.e("title")}>{optionsToAddTitle}</Label>
              <div className={bem.e("list-selected-items")}>
                <ul>
                  {map(optionsAdd, (option, index) => (
                    <li
                      key={index}
                      onClick={() => onRemoveItemFromListAdd(option)}
                      className={classnames(
                        bem.e("selected-item"),
                        bem.e("item-text-capitalize")
                      )}
                    >
                      <span>{get(option, "label")}</span>
                      <span className={bem.e("remove-icon")}>
                        <FaClose />
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className={bem.e("right-box")}>
              <Label className={bem.e("title")}>{optionsToRemoveTitle}</Label>
              <div className={bem.e("list-selected-items")}>
                <ul>
                  {map(optionsRemove, (option, index) => (
                    <li
                      key={index}
                      onClick={() => onRemoveItemFromListRemove(option)}
                      className={classnames(
                        bem.e("selected-item"),
                        bem.e("item-text-capitalize")
                      )}
                    >
                      <span>{get(option, "label")}</span>
                      <span className={bem.e("remove-icon")}>
                        <FaClose />
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <Spinner isLoading={isLoading} />
      </div>
    );
  }
}
SelectMultipleEffective.defaultProps = {
  allOptions: [],
  onSubmit: () => {},
  isLoading: false,
  leftTitle: "",
  optionsToAddTitle: "",
  optionsToRemoveTitle: ""
};
SelectMultipleEffective.propTypes = {
  allOptions: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  leftTitle: PropTypes.any.isRequired,
  optionsToAddTitle: PropTypes.any.isRequired,
  optionsToRemoveTitle: PropTypes.any.isRequired
};
export default SelectMultipleEffective;
