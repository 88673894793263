import React, { useEffect, useState } from "react";
import bn from "utils/bemnames";
import Spinner from "components/Spinner";
import classnames from "classnames";
import URL from "utils/urls";
import {
  first,
  get,
  map,
  groupBy,
  orderBy,
  last,
  split,
  forOwn,
  find,
} from "lodash";
import WinnersTable from "../Common/WinnersTable";
import WinnersListHeader from "../Common/WinnersListHeader";
import history from "components/History";
import AdvancedSearchModal from "../Common/AdvancedSearchModal";
import { validatePrivileges } from "utils/helpers";
import { PRIVILEGES } from "utils/constants";
import ExportModal from "components/ExportModal";
import SaveSearchModal from "components/SaveSearchModal";
const bem = bn.create("process-list");

export default function WinnersList(props) {
  const {
    winners,
    isLoading,
    onSort,
    sorted,
    getMoreWinners,
    onAdvancedSearch,
    advancedSearch,
    user,
    isShowSaveSearch,
    setIsShowSaveSearch,
    filtered,
    onChangeFiltered,
    setIsSubmittedAdvancedSearch,
    setAdvancedSearch,
    setFiltered,
  } = props;
  const [optionStations, setOptionStations] = useState([]);
  const [allStations, setAllStations] = useState([]);
  useEffect(() => {
    if (optionStations.length > 0) return;
    const newAllStations = map(props.stations, (item) => {
      const market = first(Object.values(item.market));
      return {
        value: item.station_key,
        label: item.call_letters,
        market,
        data: {
          firstLabel: item.call_letters,
          lastLabel: item.name,
        },
      };
    });
    const groupedMarket = groupBy(newAllStations, "market.title");
    let groupedOptions = [];
    forOwn(groupedMarket, (items, label) => {
      const tempArr2 = split(label, /\d+\. /);
      const groupLabel = last(tempArr2); // remove prefix number for sort by alphabetically
      groupedOptions = [
        ...groupedOptions,
        {
          label: groupLabel,
          value: groupLabel,
          sort_key: groupLabel, // sort by label
          options: orderBy(items, ["label"], ["asc"]),
        },
      ];
    });
    groupedOptions = orderBy(groupedOptions, ["sort_key"], ["asc"]);
    setOptionStations(groupedOptions);
    setAllStations(newAllStations);
    const newStations = newAllStations.map((i) => i.value);
    const newAdvancedSearch = {
      ...advancedSearch,
      stations:
        get(advancedSearch, "stations.length", 0) === 0
          ? newStations
          : advancedSearch.stations,
    };
    const newFiltered = filtered.map((item) => {
      if (item.key === "keyword") {
        return {
          ...item,
          value: get(advancedSearch, "keyword", ""),
        };
      }
      if (item.key === "stations") {
        return {
          ...item,
          value: newAdvancedSearch.stations,
        };
      }
      return item;
    });
    setFiltered(newFiltered);

    setAdvancedSearch(newAdvancedSearch);
    props.onAdvancedSearch(newAdvancedSearch);
  }, []);
  const hasMoreItemsSavedSearch =
    get(props, "savedSearches.page", 1) < get(props, "savedSearches.pages", 1);
  const items = get(winners, "data.items", []);
  const pages = get(winners, "data.pages", 1);
  const page = get(winners, "data.page", 1);
  const total = get(winners, "data.total", 0);
  const privileges = get(user, "privileges", []);
  const filteredStationKeys = find(filtered, (i) => i.key === "stations");
  const selectedStations = get(filteredStationKeys, "value", []).map(
    (stationKey) => {
      return find(allStations, (item) => item.value === stationKey);
    }
  );

  const searchText = find(filtered, (i) => i.key === "keyword");
  const isSelectedAllStations =
    get(selectedStations, "length", 0) === get(allStations, "length", 0) &&
    selectedStations.length > 0;
  const onView = (item) => {
    const isUserHasEditPrivileges = validatePrivileges({
      requires: {
        or: [PRIVILEGES.EDIT_WINNER, PRIVILEGES.FULFILL_WINNER_PRIZES],
      },
      privileges,
      user,
    });
    history.push({
      pathname: URL.WINNER({
        action: isUserHasEditPrivileges ? "edit" : "view",
        winner_id: item._id,
      }),
    });
  };
  return (
    <div className={classnames("fluid-container", bem.b())}>
      <WinnersListHeader
        onClearSearch={() => {
          const newStations = allStations.map((i) => i.value);
          const newFiltered = filtered.map((item) => {
            if (item.key === "keyword") {
              return {
                ...item,
                value: "",
              };
            }
            if (item.key === "stations") {
              return {
                ...item,
                value: newStations,
              };
            }
            return item;
          });
          setFiltered(newFiltered);
          props.getWinnersList({ filtered: newFiltered });
          setIsSubmittedAdvancedSearch(false);
          if (isSelectedAllStations) setIsShowSaveSearch(false);
          setAdvancedSearch({
            stations: newStations,
            prize_status: [],
            keyword: "",
            contest_category: "",
            min_prize_value: "",
            prize_won_start_date: "",
            prize_won_end_date: "",
            prize_distribution_method: "",
          });
        }}
        onSearchClicked={props.onSearch}
        searchText={get(searchText, "value", "")}
        setSearchText={(newSearchText) => {
          onChangeFiltered("keyword", newSearchText);
          setAdvancedSearch({
            ...advancedSearch,
            keyword: newSearchText,
          });
          if (isSelectedAllStations && !newSearchText) {
            setIsShowSaveSearch(false);
          } else {
            setIsShowSaveSearch(true);
          }
        }}
        isShowSaveSearch={isShowSaveSearch}
        setIsShowAdvancedSearchModal={props.setIsShowAdvancedSearchModal}
        saveSearch={() => {
          props.getSavedSearchList();
          props.setIsShowSaveSearchModal(true);
        }}
        exportWinners={() => props.setIsShowExportModal(true)}
        optionStations={optionStations}
        selectedStations={selectedStations}
        setSelectedStations={(newStations) => {
          const newStationsId = map(newStations, (i) => i.value);
          const newFiltered = onChangeFiltered("stations", newStationsId);
          setAdvancedSearch({
            ...advancedSearch,
            stations: newStationsId,
          });
          const newIsSelectedAllStations =
            get(newStations, "length", 0) === get(allStations, "length", 0);
          if (newIsSelectedAllStations && !get(searchText, "value", "")) {
            setIsShowSaveSearch(false);
          } else {
            setIsShowSaveSearch(true);
          }
          if (get(newStations, "length", 0) === 1) {
            props.onGetClocksData(newStations[0].value);
          }
          props.getWinnersList({ filtered: newFiltered });
        }}
        total={total}
        savedSearches={get(props, "allSavedSearches.items", [])}
        getSavedSearchList={props.getSavedSearchList}
        onSelectedSavedSearch={props.onSelectedSavedSearch}
      />
      <div
        className={classnames(
          bem.e("process-list-container"),
          bem.e("winners-list-table")
        )}
      >
        <WinnersTable
          items={items}
          bem={bem}
          intl={props.intl}
          sort={first(sorted)}
          actionSort={onSort}
          onView={onView}
          hasMoreItems={pages > page && !isLoading}
          handleLoadMore={() => getMoreWinners(page)}
          isLoading={isLoading}
          info={props.info}
        />
      </div>
      <AdvancedSearchModal
        isOpen={props.isShowAdvancedSearchModal}
        onToggle={() =>
          props.setIsShowAdvancedSearchModal(!props.isShowAdvancedSearchModal)
        }
        allStations={allStations}
        onSubmit={onAdvancedSearch}
        initialValues={advancedSearch}
        optionStations={optionStations}
        clocksData={props.clocksData}
        onGetClocksData={props.onGetClocksData}
      />
      <ExportModal
        isOpen={props.isShowExportModal}
        onToggle={() => props.setIsShowExportModal(!props.isShowExportModal)}
        onSubmit={props.onExportList}
        isDefaultSelectedAll
      />
      <SaveSearchModal
        isOpen={props.isShowSaveSearchModal}
        onToggle={() =>
          props.setIsShowSaveSearchModal(!props.isShowSaveSearchModal)
        }
        onSubmit={props.onSaveSearch}
        savedSearches={get(props, "savedSearches.items", [])}
        hasMoreItems={hasMoreItemsSavedSearch}
        onDelete={props.onDeleteSavedSearch}
        handleLoadMore={() => {
          props.getMoreSavedSearchList(get(props, "savedSearches.page", 1));
        }}
        isLoading={isLoading}
        isSelectableItem
        onSelectItem={(item) => {
          props.onSelectedSavedSearch(item);
          props.setIsShowSaveSearchModal(false);
        }}
      />
      <Spinner isLoading={isLoading} />
    </div>
  );
}
