import React, { useState, useEffect, forwardRef } from "react";
import { Row, Col, Label, FormGroup } from "reactstrap";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import {
  get,
  includes,
  difference,
  isEmpty,
  forEach,
  intersection,
  isEqual,
  find,
  first,
} from "lodash";
import moment from "moment";

import {
  ShadowBox,
  SectionTitle,
  SectionFields,
  Asterisk,
} from "components/Elements";
import Dropdown from "components/Dropdown";
import TextInput from "components/TextInput";
import DaysOfWeek from "components/DaysOfWeek";
import DatePicker from "components/DatePicker";
import Badge from "components/Badge";

const ItemDetails = (props) => {
  const {
    validationState,
    process,
    bem,
    step,
    onValueChanged,
    intl,
    isView,
    slotDefinitions,
    dateFormatByServer
  } = props;
  const [fieldRefs, setFieldRefs] = useState(null);
  const [selectedDates, setSelectedDates] = useState([]);
  const slotDefinitionsOptions = slotDefinitions.map((slotDefinition) => ({
    label: `${slotDefinition.type} - ${slotDefinition.name}`,
    value: slotDefinition.id,
  }));
  useEffect(() => {
    const typeId = get(process, "data.fields.type_id.value");
    const slotDefinition = first(slotDefinitionsOptions);
    // If there is only one item type defined. It should automatically be selected in the dropdown
    if (
      slotDefinitionsOptions &&
      slotDefinitionsOptions.length === 1 &&
      typeId !== get(slotDefinition, "value")
    ) {
      onValueChanged(order_type, slotDefinition.label, {
        shouldBlockNavigation: false,
      });
      onValueChanged(type_id, slotDefinition.value, {
        shouldBlockNavigation: false,
      });
    }
  }, [slotDefinitionsOptions]);
  const getFieldRefs = () => {
    if (fieldRefs) return fieldRefs;

    const order_title = step.fields.find((f) => f.field === "order_title");
    const order_start_date = step.fields.find(
      (f) => f.field === "order_start_date"
    );
    const order_end_date = step.fields.find(
      (f) => f.field === "order_end_date"
    );
    const days_of_week = step.fields.find((f) => f.field === "days_of_week");
    const excluded_dates = step.fields.find(
      (f) => f.field === "excluded_dates"
    );
    const order_type = step.fields.find((f) => f.field === "order_type");
    const type_id = step.fields.find((f) => f.field === "type_id");
    const order_length = step.fields.find((f) => f.field === "order_length");

    if (!process.data.fields.days_of_week) {
      onValueChanged(days_of_week, [1, 2, 3, 4, 5, 6, 7], {
        shouldBlockNavigation: false,
      });
    }

    const payload = {
      order_title,
      order_start_date,
      order_end_date,
      days_of_week,
      excluded_dates,
      order_type,
      type_id,
      order_length,
    };

    setFieldRefs(payload);

    return payload;
  };

  const {
    order_title,
    order_start_date,
    order_end_date,
    days_of_week,
    excluded_dates,
    order_type,
    type_id,
    order_length,
  } = getFieldRefs();

  const isSelectedDay = (date) => {
    let selectedDays = process.data.fields[days_of_week.field]
      ? process.data.fields[days_of_week.field].value
      : [];
    const day = moment(date).day() + 1;
    return includes(selectedDays, day);
  };

  const getAvailableDates = () => {
    if (
      !process.data.fields[order_start_date.field] ||
      !process.data.fields[order_end_date.field]
    ) {
      return [];
    }

    let startDate = moment(process.data.fields[order_start_date.field].value);
    let endDate = moment(process.data.fields[order_end_date.field].value);

    let dates = [];
    let currentDate = startDate.subtract(1, "days");
    let addDays = function(days) {
      let date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };
    while (currentDate <= endDate) {
      if (isSelectedDay(currentDate)) {
        dates.push(currentDate);
      }

      currentDate = addDays.call(currentDate, 1);
    }

    return dates;
  };

  const _setSelectedDates = () => {
    let selectedDates = [];

    let excludedDates = process.data.fields[excluded_dates.field]
      ? process.data.fields[excluded_dates.field].value
      : [];

    excludedDates = excludedDates.map((date) =>
      moment(date).format("YYYY-MM-DD")
    );

    let availableDates = getAvailableDates();

    forEach(availableDates, (date) => {
      if (!includes(excludedDates, moment(date).format("YYYY-MM-DD"))) {
        selectedDates.push(date);
      }
    });

    setSelectedDates(selectedDates);

    let _excludedDates = intersection(
      excludedDates,
      availableDates.map((date) => moment(date).format("YYYY-MM-DD"))
    );

    if (!isEqual(excludedDates, _excludedDates)) {
      onValueChanged(excluded_dates, _excludedDates);
    }
  };

  useEffect(() => {
    _setSelectedDates();
  }, [process]);

  const ExcludeDatesButton = forwardRef(({ value, onClick }, ref) => (
    <div className="exclude-dates-btn" onClick={onClick} ref={ref}>
      <FormattedMessage id="process > promotion > liner > exclude dates" />
    </div>
  ));

  const renderExcludedDates = () => {
    const excludedDates = process.data.fields[excluded_dates.field]
      ? process.data.fields[excluded_dates.field].value
      : [];

    return excludedDates.map((date) => {
      return (
        <Badge
          key={date}
          text={moment(date).format("MMM D")}
          onClick={() => {
            onValueChanged(excluded_dates, difference(excludedDates, [date]));
          }}
        />
      );
    });
  };
  return (
    <ShadowBox className={bem.e("box-item-details")}>
      <SectionTitle className={bem.e("item-details-section-title")}>
        <FormattedMessage id="process > prmotion > liner title item details" />
      </SectionTitle>
      <SectionFields
        className={classnames(bem.e("section-fields"), {
          [bem.e("item-details-section-fields-view")]: isView,
          [bem.e("item-details-section-fields")]: !isView,
        })}
      >
        {/* render view */}
        {isView ? (
          <React.Fragment>
            <Row>
              <Col xs={12} className={bem.e("reset-col")}>
                <FormGroup>
                  <Label>{order_title.title}</Label>
                  <span className={bem.e("order-view-value")}>
                    {process.data.fields[order_title.field]
                      ? process.data.fields[order_title.field].value
                      : intl.formatMessage({ id: "process > none" })}
                  </span>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={6} className={bem.e("reset-col")}>
                <FormGroup>
                  <Label>{order_start_date.title}</Label>
                  <span className={bem.e("order-view-value")}>
                    {process.data.fields[order_start_date.field]
                      ? moment(
                          process.data.fields[order_start_date.field].value
                        ).format(dateFormatByServer)
                      : intl.formatMessage({ id: "process > none" })}
                  </span>
                </FormGroup>
              </Col>
              <Col xs={6} className={bem.e("reset-col")}>
                <FormGroup>
                  <Label>{order_end_date.title}</Label>
                  <span className={bem.e("order-view-value")}>
                    {process.data.fields[order_end_date.field]
                      ? moment(
                          process.data.fields[order_end_date.field].value
                        ).format(dateFormatByServer)
                      : intl.formatMessage({ id: "process > none" })}
                  </span>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={6} className={bem.e("reset-col")}>
                <FormGroup>
                  <Label>
                    <FormattedMessage id="process > days of the week" />
                  </Label>
                  <span className={bem.e("order-view-value")}>
                    {process.data.fields[days_of_week.field] ? (
                      <DaysOfWeek
                        isView={true}
                        value={get(
                          process,
                          "data.fields.days_of_week.value",
                          []
                        )}
                      />
                    ) : (
                      intl.formatMessage({ id: "process > none" })
                    )}
                  </span>
                </FormGroup>
              </Col>
              <Col xs={6} className={bem.e("reset-col")}>
                <FormGroup>
                  <Label>{excluded_dates.title}</Label>
                  <span
                    className={classnames(bem.e("order-view-value"), {
                      [bem.e("order-view-value-excluded-dates")]: get(
                        process,
                        `data.fields.${excluded_dates.field}.value`
                      ),
                    })}
                  >
                    {process.data.fields[excluded_dates.field]
                      ? process.data.fields[excluded_dates.field].value
                          .map((date) => moment(date).format("MMM D"))
                          .join(", ")
                      : intl.formatMessage({ id: "process > none" })}
                  </span>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={6} className={bem.e("reset-col")}>
                <FormGroup>
                  <Label>{order_type.title}</Label>
                  <span className={bem.e("order-view-value")}>
                    {get(process, "data.fields.order_type.value")
                      ? process.data.fields[order_type.field].value
                      : intl.formatMessage({ id: "process > none" })}
                  </span>
                </FormGroup>
              </Col>
              <Col xs={4} className={bem.e("reset-col")}>
                <FormGroup>
                  <Label>{order_length.title}</Label>
                  <span className={bem.e("order-view-value")}>
                    {process.data.fields[order_length.field]
                      ? `${intl.formatMessage(
                          {
                            id: "process > second",
                          },
                          {
                            second:
                              process.data.fields[order_length.field].value,
                          }
                        )}`
                      : intl.formatMessage({ id: "process > none" })}
                  </span>
                </FormGroup>
              </Col>
            </Row>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Row>
              <Col xs={12} className={bem.e("reset-col")}>
                <FormGroup>
                  <TextInput
                    label={
                      <span>
                        {order_title.title}
                        {order_title.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    required
                    name="order_title"
                    placeholder={intl.formatMessage({
                      id: "process > promotion > liner > enter item name",
                    })}
                    value={
                      process.data.fields[order_title.field]
                        ? process.data.fields[order_title.field].value
                        : ""
                    }
                    onChange={(event) => {
                      onValueChanged(
                        order_title,
                        get(event, "target.value", "")
                      );
                    }}
                    error={get(validationState, "order_title.validation_error")}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={4} className={bem.e("reset-col")}>
                <FormGroup>
                  <TextInput
                    label={
                      <span>
                        {order_start_date.title}
                        {order_start_date.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    type="date"
                    name="order_start_date"
                    placeholder={intl.formatMessage({
                      id: "process > promotion > liner > select date",
                    })}
                    value={
                      process.data.fields[order_start_date.field]
                        ? process.data.fields[order_start_date.field].value
                        : ""
                    }
                    onChange={(value) => {
                      onValueChanged(order_start_date, value);
                      onValueChanged(order_end_date, ""); // reset order end date
                    }}
                    error={get(
                      validationState,
                      "order_start_date.validation_error"
                    )}
                    allowShowIcon
                  />
                </FormGroup>
              </Col>
              <Col xs={4} className={bem.e("reset-col")}>
                <FormGroup>
                  <TextInput
                    label={
                      <span>
                        {order_end_date.title}
                        {order_end_date.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    type="date"
                    name="order_end_date"
                    placeholder={intl.formatMessage({
                      id: "process > promotion > liner > select date",
                    })}
                    min={get(
                      process,
                      "data.fields.order_start_date.value",
                      undefined
                    )}
                    value={
                      process.data.fields[order_end_date.field]
                        ? process.data.fields[order_end_date.field].value
                        : ""
                    }
                    onChange={(value) => {
                      onValueChanged(order_end_date, value);
                    }}
                    error={get(
                      validationState,
                      "order_end_date.validation_error"
                    )}
                    allowShowIcon
                  />
                  {process.data.fields[order_start_date.field] &&
                    process.data.fields[order_start_date.field].value &&
                    process.data.fields[order_end_date.field] &&
                    process.data.fields[order_end_date.field].value && (
                      <div className="cr-textinput exclude-date-picker">
                        <DatePicker
                          field={
                            process.data.fields[order_start_date.field]
                              ? process.data.fields[order_start_date.field]
                              : null
                          }
                          onChange={(date) => {
                            let excludedDates = process.data.fields[
                              excluded_dates.field
                            ]
                              ? process.data.fields[excluded_dates.field].value
                              : [];

                            if (!includes(excludedDates, date)) {
                              excludedDates.push(date);
                            } else {
                              excludedDates = difference(excludedDates, [date]);
                            }

                            onValueChanged(excluded_dates, excludedDates);
                          }}
                          name="selected_dates"
                          minDate={moment(
                            process.data.fields[order_start_date.field].value
                          ).toDate()}
                          maxDate={moment(
                            process.data.fields[order_end_date.field].value
                          ).toDate()}
                          filterDate={isSelectedDay}
                          highlightDates={selectedDates}
                          selected={null}
                          customInput={<ExcludeDatesButton />}
                          shouldCloseOnSelect={false}
                        />
                      </div>
                    )}
                </FormGroup>
              </Col>
              <Col xs={4} className={bem.e("reset-col")}>
                <FormGroup>
                  <DaysOfWeek
                    value={get(process, "data.fields.days_of_week.value", [])}
                    label={
                      <span>
                        <FormattedMessage id="process > days of the week" />{" "}
                        <Asterisk>*</Asterisk>
                      </span>
                    }
                    error={get(
                      validationState,
                      "days_of_week.validation_error"
                    )}
                    onChange={(days) => {
                      onValueChanged(days_of_week, days);
                    }}
                    checkStyle={{ color: "#fff", width: "11", height: "11" }}
                  />
                </FormGroup>
              </Col>
            </Row>
            {process.data.fields[excluded_dates.field] &&
              !isEmpty(process.data.fields[excluded_dates.field].value) && (
                <Row className={bem.e("badges")}>
                  <Col className={bem.e("reset-col")}>
                    <FormGroup>
                      <Label>
                        {excluded_dates.title}
                        {excluded_dates.mandatory && <Asterisk>*</Asterisk>}
                      </Label>
                      <div className={bem.e("badges-container")}>
                        {renderExcludedDates()}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              )}
            <Row>
              <Col xs={8} className={bem.e("reset-col")}>
                <FormGroup className={bem.e("reset-form-group")}>
                  <Dropdown
                    label={
                      <span>
                        {order_type.title}
                        {order_type.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    name="order_type"
                    placeholder={intl.formatMessage({
                      id: "process > promotion > liner > select item type",
                    })}
                    onChange={(selectedOption) => {
                      onValueChanged(order_type, selectedOption.label);
                      onValueChanged(type_id, selectedOption.value);
                    }}
                    value={
                      find(
                        slotDefinitionsOptions,
                        (item) =>
                          item.value ===
                          get(process, "data.fields.type_id.value")
                      ) || ""
                    }
                    options={slotDefinitionsOptions}
                    error={get(validationState, "order_type.validation_error")}
                  />
                </FormGroup>
              </Col>
              <Col xs={4} className={bem.e("reset-col")}>
                <FormGroup className={bem.e("reset-form-group")}>
                  <Dropdown
                    label={
                      <span>
                        {order_length.title}
                        {order_length.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                    }
                    name="order_length"
                    placeholder={intl.formatMessage({
                      id: "process > select",
                    })}
                    onChange={(selectedOption) => {
                      onValueChanged(order_length, selectedOption.value);
                    }}
                    value={
                      get(
                        process.data.fields[order_length.field],
                        "value",
                        null
                      )
                        ? find(
                            order_length.props.options,
                            (item) =>
                              item.value ===
                              process.data.fields[order_length.field].value
                          )
                        : ""
                    }
                    {...order_length.props}
                  />
                </FormGroup>
              </Col>
            </Row>
          </React.Fragment>
        )}
      </SectionFields>
    </ShadowBox>
  );
};

export default ItemDetails;
