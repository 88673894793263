import React, { useRef } from "react";
import { Formik } from "formik";
import bn from "utils/bemnames";
import { Button, Form } from "reactstrap";
import EntityAssignments from "../EntityAssignments";
import UserEntitiesAssignmentModal from "components/UserEntitiesAssignmentModal";
import ConfirmationModal from "components/ConfirmationModal";
import HubAssignments from "../HubAssignments";
import ShowAssignments from "../ShowAssignments";
import { FormattedMessage } from "react-intl";
import { TEAM } from "utils/constants";
import ToastManager from "components/ToastManager";
import BlockingComponent from "components/BlockingComponent";
import {
  map,
  get,
  includes,
  uniqBy,
  first,
  isEqual,
  forEach,
  find,
  pick,
  cloneDeep,
  orderBy,
} from "lodash";
const bem = bn.create("user-assignments");
export function isSameSelectedStations(stations) {
  const firstSelectedStation = first(stations);
  if (!firstSelectedStation) return true;
  const firstRoles = orderBy(
    map(firstSelectedStation.roles, (role) => role.value),
    "asc"
  );
  const firstPrivileges = orderBy(
    map(firstSelectedStation.privileges, (privilege) => privilege.value),
    "asc"
  );
  const stationsDiff = stations.filter((station) => {
    const roles = orderBy(
      map(station.roles, (role) => role.value),
      "asc"
    );
    const privileges = orderBy(
      map(station.privileges, (privilege) => privilege.value),
      "asc"
    );
    return !isEqual(roles, firstRoles) || !isEqual(privileges, firstPrivileges);
  });
  return stationsDiff;
}
function mapSelectedTeamsStations({ stations, allStationsAndTeams }) {
  let selectedTeamsStations = {};
  const allStationsAndTeamsData = cloneDeep(allStationsAndTeams);
  const allStationsAndTeamsArray = Object.values(allStationsAndTeamsData) || [];
  // [...teams, ...stations]
  forEach(stations, (station) => {
    const stationData = find(
      allStationsAndTeamsArray,
      (item) => parseInt(item.id) === parseInt(station.id)
    );
    if (stationData) {
      stationData.type = stationData.type === TEAM ? "team" : "station";
      selectedTeamsStations[stationData.key] = pick(stationData, [
        "format",
        "key",
        "market",
        "station_key",
        "team",
        "roles",
        "stations",
        "type",
        "call_letters",
      ]);
    }
  });
  return selectedTeamsStations;
}
function View(props) {
  const {
    user,
    auth,
    productionMultimarket,
    digitalMultimarket,
    promotionMultimarket,
    isShowHubAssignments,
    hubs,
  } = props;

  const selectedStations = map(user.stations, (item) => {
    const userRoles = Object.keys(item.user_roles).map((roleId) =>
      parseInt(roleId)
    );
    const stationData = get(auth, "info.stations", []).find(
      (i) => i.station_key === item.station_key
    );
    // map user role with privileges
    const roles = get(auth, "info.roles", [])
      .filter((role) => includes(userRoles, parseInt(role.id)))
      .map((role) => ({
        value: parseInt(role.id),
        label: role.name,
        privileges: Object.keys(role.privileges).map((privilegeId) => ({
          value: parseInt(privilegeId),
          label: role.privileges[privilegeId],
        })),
      }));
    let stationShows = item.shows;
    if (!Array.isArray(stationShows)) {
      stationShows = Object.keys(stationShows).map((id) => ({
        id,
        name: stationShows[id],
      }));
    }
    const shows = stationShows.map((show) => ({
      label: show.name,
      value: show.id,
    }));
    return {
      name: item.name,
      key: item.key,
      id: item.user_station_id,
      roles,
      shows,
      call_letters: item.call_letters,
      privileges: Object.keys(item.user_privileges).map((privilegeId) => ({
        value: parseInt(privilegeId),
        label: item.user_privileges[privilegeId],
      })),
      market: stationData.market,
    };
  });
  // const selectedTeams = map(user.teams, (item) => {
  //   return {
  //     id: item.user_station_id,
  //     name: item.name,
  //     stations: item.selected_stations
  //       ? Object.values(item.selected_stations)
  //       : [],
  //     roles: item.roles,
  //   };
  // });
  const selectedHubs = map(user.hubs_as_manager, (item) =>
    Number(item.id)
  ).filter((i) => i);
  const initialValues = {
    stations: selectedStations,
    // teams: selectedTeams,
    hubs: selectedHubs,
    is_separately: !get(user, "is_apply_entities_to_all", false),
  };

  const formikRef = useRef(null);
  const checkForToast = () => {
    setTimeout(() => {
      const formErrors = get(formikRef, "current.state.errors");
      if (formErrors && Object.keys(formErrors).length) {
        ToastManager.show({
          title: props.intl.formatMessage({
            id: "toast > title not saved",
          }),
          message: props.intl.formatMessage({
            id: "toast > message error please correct the hilighted fields",
          }),
          level: "error",
        });
      }
    }, 40);
  };

  const isServerList =
    auth.info.app_flags && auth.info.app_flags.indexOf("SERVERS_LIST") > -1;

  const isMissingAssignments =
    get(user, "stations.length", 0) === 0 &&
    // get(user, "teams.length", 0) === 0 &&
    user.id;

  if (
    !isServerList &&
    isMissingAssignments !== props.shouldBlockNavigation &&
    !props.shouldBlockNavigation
  ) {
    setTimeout(
      () => props.setShouldBlockNavigation(!!isMissingAssignments),
      20
    );
  }
  const form = (
    <Formik
      ref={formikRef}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={props.onSubmit}
      validate={(values) => {
        let errors = {};
        let stations = [];
        // stations validate
        values.stations.forEach((station) => {
          let error = {
            roles: "",
            privileges: "",
          };
          if (!station.roles || station.roles.length === 0) {
            error.roles = props.intl.formatMessage({
              id: "validate > role is required",
            });
          }
          if (!station.privileges || station.privileges.length === 0) {
            error.privileges = props.intl.formatMessage({
              id: "validate > please select privileges",
            });
          }
          stations.push(error);
        });
        if (
          stations.filter((item) => item.roles || item.privileges).length > 0
        ) {
          errors.stations = stations;
        }
        return errors;
      }}
    >
      {(formProps) => {
        // const isSeparately = get(formProps, "values.is_separately", true);
        let selectedTeamsStations = mapSelectedTeamsStations(
          // formProps.values.teams,
          {
            stations: formProps.values.stations,
            allStationsAndTeams: props.allStationsAndTeams,
          }
        );
        return (
          <Form onSubmit={formProps.handleSubmit}>
            {isShowHubAssignments && (
              <React.Fragment>
                <HubAssignments
                  formProps={formProps}
                  isView={false}
                  user={user}
                  hubs={hubs}
                  setShouldBlockNavigation={props.setShouldBlockNavigation}
                />
              </React.Fragment>
            )}
            <div className={bem.e("view-content")}>
              <EntityAssignments
                formProps={formProps}
                isView={false}
                user={user}
                auth={auth}
                setIsOpenAssignEntitiesModal={
                  props.setIsOpenAssignEntitiesModal
                }
                productionMultimarket={productionMultimarket}
                digitalMultimarket={digitalMultimarket}
                promotionMultimarket={promotionMultimarket}
                setShouldBlockNavigation={props.setShouldBlockNavigation}
              />
              <ShowAssignments
                formProps={formProps}
                isView={false}
                user={user}
                auth={auth}
                setShouldBlockNavigation={props.setShouldBlockNavigation}
              />
            </div>
            <div className={bem.e("buttons")}>
              <Button
                color="blue"
                type="submit"
                className="btn btn-radius"
                onClick={checkForToast}
                id="btn-submit"
                disabled={!get(user, "id", null)}
              >
                <FormattedMessage id={`user > button submit`} />
              </Button>
              {props.backButton}
            </div>
            <UserEntitiesAssignmentModal
              selectedUserId={get(user, "id", null)}
              selectedEntitiesAndTeams={selectedTeamsStations}
              isOpen={props.isOpenAssignEntitiesModal}
              onToggle={() =>
                props.setIsOpenAssignEntitiesModal(
                  !props.isOpenAssignEntitiesModal
                )
              }
              onSubmit={(selectedAssign) => {
                // let newTeams = [];
                let newStations = [];
                // let defaultRoles = [];
                // let defaultPrivileges = [];
                // if checked apply to all roles + privileges
                // if (!isSeparately) {
                //   const firstStation = first(formProps.values.stations);
                //   defaultRoles = get(firstStation, "roles", []);
                //   defaultPrivileges = get(firstStation, "privileges", []);
                // }
                Object.values(selectedAssign).forEach((element) => {
                  const stationData = Object.values(
                    props.allStationsAndTeams
                  ).find((item) => item.key === element.key);
                  if (element.type === "team") {
                    // const teamDataSelected = find(
                    //   formProps.values.teams,
                    //   (team) => team.key === element.key
                    // );
                    // if (teamDataSelected) {
                    //   newTeams = [...newTeams, teamDataSelected];
                    // } else {
                    //   newTeams = [
                    //     ...newTeams,
                    //     {
                    //       id: stationData.id,
                    //       name: element.name,
                    //       key: element.key,
                    //       stations: Object.values(element.stations),
                    //       roles: element.roles,
                    //     },
                    //   ];
                    // }
                  } else {
                    const stationDataSelected = find(
                      formProps.values.stations,
                      (station) => station.key === element.key
                    );
                    if (stationDataSelected) {
                      newStations = [...newStations, stationDataSelected];
                    } else {
                      newStations = [
                        ...newStations,
                        {
                          id: stationData.id,
                          name: element.name,
                          call_letters: stationData.call_letters,
                          key: element.key,
                          roles: [],
                          privileges: [],
                          market: element.market,
                        },
                      ];
                    }
                  }
                });
                const stations = uniqBy(newStations, "id");
                // const teams = uniqBy(newTeams, "id");
                formProps.setFieldValue("is_separately", true); // reset
                formProps.setFieldValue("stations", stations);
                // formProps.setFieldValue("teams", teams);
              }}
            />
            {props.isEntitiesSaved && (
              <ConfirmationModal
                isOpen={props.isEntitiesSaved}
                onToggle={() => props.setIsEntitiesSaved(false)}
                onCancel={() => props.setIsEntitiesSaved(false)}
                state="success"
                title={
                  <FormattedMessage
                    id={`user > assignment saved successfully`}
                  />
                }
              />
            )}
          </Form>
        );
      }}
    </Formik>
  );
  return (
    <React.Fragment>
      {form}
      <BlockingComponent
        isBlocked={props.shouldBlockNavigation}
        modalProps={{
          isShowConfirm: true,
          isShowDiscard: false,
          isCancelConfirmedNavigation: true,
          confirmTitle: <FormattedMessage id="reminder modal > button yes" />,
          cancelTitle: <FormattedMessage id="reminder modal > button no" />,
          title: props.intl.formatMessage({
            id:
              "reminder modal > would you like to assign this user to an entity",
          }),
          onCancel: () => {
            props.setShouldBlockNavigation(false); // reset
          },
          onConfirm: () => {
            const buttonSubmit = document.getElementById(`btn-submit`);
            // trigger click to submit the form
            if (buttonSubmit) buttonSubmit.click();
          },
        }}
      />
    </React.Fragment>
  );
}

export default View;
