import React, { useState, useRef, useEffect } from "react";
import { Button } from "reactstrap";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { IconsConfig } from "components/ChannelManager/IconSelector";
import { getEditableTabByUser, getEditableTabsByStage } from "utils/helpers";
import EnlargeAssetModal from "components/EnlargeAssetModal";
import ConfirmationModal from "components/ConfirmationModal";
import {
  UpIcon,
  DownIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
} from "components/CustomIcons";
import Slider from "react-slick";
import { labelToNameField } from "utils/helpers";
import PreviewMediaModal from "components/PreviewMediaModal";
import {
  get,
  filter,
  map,
  findIndex,
  find,
  forEach,
  delay,
  first,
  split,
} from "lodash";
import ReviewImage from "./ReviewImage";
import ReviewPdf from "./ReviewPdf";

import Buttons from "../Buttons";
import bn from "utils/bemnames";
import ReviewCompleteImage from "assets/img/bg/review-complete.png";
import ReviewNotDoneImage from "assets/img/bg/review.png";
import { isPdf } from "utils/helpers";

const bem = bn.create("channel-media");
export const DEFAULT_POSITION = {
  x: 510,
  y: 148,
};
// will add class slick-moving for overflow hidden when moving to new slide and delay for remove this class
function addClassSlickList(action = "add") {
  const listEle = document.getElementsByClassName("slick-list")[0];
  if (!listEle) return;
  if (action === "add") {
    listEle.classList.add(bem.e("slick-moving"));
    // ensure we removed the class slick-moving
    delay(() => {
      listEle.classList.remove(bem.e("slick-moving"));
    }, 1000);
  } else {
    listEle.classList.remove(bem.e("slick-moving"));
  }
}
const NextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowRightIcon color="#829FB1" />
    </div>
  );
};

const PrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowLeftIcon color="#829FB1" />
    </div>
  );
};

const mediaSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipe: false,
  adaptiveHeight: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};
function ChannelMedia(props) {
  const {
    channel,
    isView,
    isCollapse,
    setIsCollapse,
    currentAsset,
    setCurrentPosition,
    currentPosition,
    setIsShowDraggable,
    isShowDraggable,
    currentMedia,
    isLoading,
    isOpenAddComment,
    setIsOpenAddComment,
    isClientReview,
    ...rest
  } = props;
  const mainSlick = useRef();
  const [mediaFields, setMediaFields] = useState([]);
  const [countAssetsReviewed, setCountAssetsReviewed] = useState(0);
  const [isCompleteReviewed, setIsIsCompleteReviewed] = useState(false);
  const [
    isShowSelectionButtonsForWhoReview,
    setIsShowSelectionButtonsForWhoReview,
  ] = useState(true);
  const onClickPin = () => {
    props.setIsShowDraggable(true);
  };
  const channelIcon = get(channel, "channel_icon");
  const submitButton =
    !isClientReview &&
    find(rest.step.fields, (field) => field.key === "save_button");
  let ChannelIcon = IconsConfig[channelIcon]
    ? IconsConfig[channelIcon]
    : IconsConfig["default"];

  useEffect(() => {
    let newMediaFields = mediaFields;
    filter(
      get(channel, "fields", []),
      (item) => item.key === "media_for_review_approval"
    )
      .filter((field) => {
        const fieldName = labelToNameField(field.label);
        return get(channel, fieldName, []);
      })
      .forEach((field, index) => {
        const fieldName = labelToNameField(field.label);
        const value = get(channel, fieldName, [])
          ? get(channel, fieldName, [])
          : [];
        forEach(value, (valueItem, indexValue) => {
          const idx = `${index}_${indexValue}`; // merge field index + value index;
          const existsIndex = findIndex(
            mediaFields,
            (item) => item.idx === idx
          );
          // to replace current value
          const newMediaField = {
            review_media_status: get(valueItem, "review_media_status", null),
            is_approved: get(valueItem, "review_media_status") === "approved",
            is_rejected: get(valueItem, "review_media_status") === "rejected",
            label: field.label,
            key: field.key,
            fieldName,
            value: valueItem,
            idx,
            media_name: first(split(valueItem.name, ".")),
          };
          if (existsIndex !== -1) {
            newMediaFields[existsIndex] = newMediaField;
          } else {
            newMediaFields.push(newMediaField);
          }
        });
      });
    let newCountAssetsReviewed = filter(newMediaFields, (item) =>
      get(item, "review_media_status")
    ).length;
    const newIsCompleteReviewed =
      newCountAssetsReviewed === newMediaFields.length &&
      newMediaFields.length > 0;
    setMediaFields(newMediaFields);
    setIsIsCompleteReviewed(newIsCompleteReviewed);
    setCountAssetsReviewed(newCountAssetsReviewed);
  }, [get(channel, "fields", [])]);
  // push last item for complete
  const sliders = [...mediaFields, { id: -1 }];
  const isTabCanEditByStage = getEditableTabsByStage(
    rest.step,
    rest.process,
    rest.user
  );
  const { isAssigned, isEditor, isUserCompletedStep } = getEditableTabByUser({
    step: rest.step,
    process: rest.process,
    user: rest.user,
    template: rest.template.key,
  });
  const isTabCanEditByUser = isAssigned && isEditor;
  let isShowEditButton = false;
  if (
    (isTabCanEditByStage && isTabCanEditByUser) || // case when user go to the tab from current tasks
    (isEditor && isUserCompletedStep)
  ) {
    // case when user assigned to step by role and completed this step
    isShowEditButton = true;
  }
  let isDisabledButtonSubmit =
    !isCompleteReviewed || isView || (!isClientReview && !isShowEditButton);
  useEffect(() => {
    const nextMediaForReview = findIndex(
      mediaFields,
      (item) => !item.review_media_status
    );
    if (mainSlick.current && nextMediaForReview !== -1) {
      mainSlick.current.slickGoTo(nextMediaForReview);
      rest.setCurrentMediaSlideIndex(nextMediaForReview);
      delay(() => {
        addClassSlickList("remove");
      }, 100);
    }
    let newCurrentMedia = get(
      mediaFields,
      `${nextMediaForReview}`,
      mediaFields[0]
    );
    if (newCurrentMedia) {
      rest.setCurrentMedia(newCurrentMedia);
      rest.setCurrentAsset(newCurrentMedia.value);
    }
  }, []);
  return (
    <div className={bem.b()}>
      <div
        className={bem.e("form-header")}
        onClick={() => {
          setIsCollapse(!isCollapse);
        }}
      >
        <span className={bem.e("channel-icon")}>
          <ChannelIcon />
        </span>
        <h4 className={bem.e("title")}>{get(channel, "name", "")}</h4>
        <div className={bem.e("head-right")}>
          <div className={bem.e("assets-approvals")}>
            <span className={bem.e("assets-approvals-title")}>
              <FormattedMessage id="process > title assets reviewed" />:
            </span>
            <span className={bem.e("assets-approvals-value")}>
              {countAssetsReviewed}/{mediaFields.length}
            </span>
          </div>
          {isCollapse ? (
            <span className={bem.e("button-up-icon")}>
              <UpIcon />
            </span>
          ) : (
            <span className={bem.e("button-down-icon")}>
              <DownIcon />
            </span>
          )}
        </div>
      </div>
      <div
        className={classnames(bem.e("form-content"), {
          [bem.e("show")]: isCollapse,
          [bem.e("hide")]: !isCollapse,
        })}
      >
        <div
          className={classnames(bem.e("content"), {
            [bem.e("content-review")]:
              rest.currentMediaSlideIndex === sliders.length - 1,
            [bem.e("content-who-review")]:
              isShowSelectionButtonsForWhoReview && !isClientReview,
          })}
          // Disable for only cursor in the media
          // style={{
          //   cursor:
          //     isShowDraggable && !isOpenAddComment
          //       ? `${PinDefaultIcon}`
          //       : "default",
          // }}
        >
          {/* add unique id for re-init slick */}
          <div>
            {isShowSelectionButtonsForWhoReview && !isClientReview && (
              <h2 className={bem.e("title-preview")}>
                <FormattedMessage id="process > review media > review title" />
              </h2>
            )}
            <Slider
              ref={(ref) => {
                mainSlick.current = ref;
                if (rest.mainSlickRef) {
                  rest.mainSlickRef.current = ref; // assign to props ref for get from client review page
                }
              }}
              {...mediaSettings}
              beforeChange={(current, next) => {
                addClassSlickList("add");
                rest.setVisibleComments(null);
                props.setIsShowDraggable(false);
                let newCurrentMedia = mediaFields[next];
                if (newCurrentMedia) {
                  rest.setCurrentMedia(newCurrentMedia);
                  rest.setCurrentAsset(newCurrentMedia.value);
                }
              }}
              afterChange={(currentSlide) => {
                addClassSlickList("remove");
                rest.setCurrentMediaSlideIndex(currentSlide);
              }}
              onReInit={() => {
                let newCurrentMedia =
                  mediaFields[rest.currentMediaSlideIndex || 0];
                if (!currentAsset && newCurrentMedia) {
                  rest.setCurrentMedia(newCurrentMedia);
                  rest.setCurrentAsset(newCurrentMedia.value);
                }
              }}
            >
              {map(sliders, (mediaField, index) => {
                const isPdfFile = isPdf(get(mediaField, "value.name", ""));
                return (
                  <div
                    className={bem.e("slide-media")}
                    key={`${index}-${rest.uniqueId}`}
                  >
                    {mediaField.id !== -1 ? (
                      <React.Fragment>
                        {!rest.isClientReviewed && (
                          <Buttons
                            isClientReview={isClientReview}
                            mediaField={mediaField}
                            intl={rest.intl}
                            countAssetsReviewed={countAssetsReviewed}
                            isView={isView}
                            mainSlick={mainSlick}
                            channel={channel}
                            onClickPin={onClickPin}
                            onButtonClick={rest.onButtonClick}
                            currentMediaSlideIndex={rest.currentMediaSlideIndex}
                            index={index}
                            setIsOpenHelperTooltipModal={
                              rest.setIsOpenHelperTooltipModal
                            }
                            setCurrentPosition={setCurrentPosition}
                            bem={bem}
                            setIsShowDraggable={setIsShowDraggable}
                            isRejectConfirmationModalOpen={
                              rest.isRejectConfirmationModalOpen
                            }
                            setIsRejectConfirmationModalOpen={
                              rest.setIsRejectConfirmationModalOpen
                            }
                            isOpenHelperTooltipModal={
                              rest.isOpenHelperTooltipModal
                            }
                            isShowHelperTooltip={rest.isShowHelperTooltip}
                            step={rest.step}
                            template={rest.template}
                            user={rest.user}
                            process={rest.process}
                            isShowSelectionButtonsForWhoReview={
                              isShowSelectionButtonsForWhoReview
                            }
                            setIsShowSelectionButtonsForWhoReview={
                              setIsShowSelectionButtonsForWhoReview
                            }
                            isShowPinIcon={!isPdfFile}
                            setCurrentMediaSlideIndex={
                              rest.setCurrentMediaSlideIndex
                            }
                          />
                        )}
                        {isPdfFile ? (
                          <>
                            {/** only show if current slide is active to avoid the adaptiveHeight issue */}
                            {rest.currentMediaSlideIndex === index ? (
                              <ReviewPdf
                                bem={bem}
                                mediaField={mediaField}
                                index={index}
                                intl={rest.intl}
                                setUniqueId={rest.setUniqueId}
                              />
                            ) : null}
                          </>
                        ) : (
                          <ReviewImage
                            bem={bem}
                            mediaField={mediaField}
                            index={index}
                            isShowDraggable={isShowDraggable}
                            isOpenAddComment={isOpenAddComment}
                            isView={isView}
                            setIsOpenAddComment={setIsOpenAddComment}
                            setCurrentPosition={setCurrentPosition}
                            currentPosition={currentPosition}
                            setIsOpenEnlargeAssetModal={
                              rest.setIsOpenEnlargeAssetModal
                            }
                            visibleComments={rest.visibleComments}
                            setCurrentPreviewMediaModalOpen={
                              rest.setCurrentPreviewMediaModalOpen
                            }
                            setWatchPosition={rest.setWatchPosition}
                            watchPosition={rest.watchPosition}
                            onSubmitNewMessage={rest.onSubmitNewMessage}
                            isEditComment={rest.isEditComment}
                            isClientReviewed={rest.isClientReviewed}
                            setVisibleComments={rest.setVisibleComments}
                            setIsEditComment={rest.setIsEditComment}
                            intl={rest.intl}
                            onSubmitEditComment={rest.onSubmitEditComment}
                            onDeleteComment={rest.onDeleteComment}
                            setCurrentDeleteComment={
                              rest.setCurrentDeleteComment
                            }
                            setIsDeleteModalOpen={rest.setIsDeleteModalOpen}
                          />
                        )}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <div
                          className={classnames(bem.e("review-complete"), {
                            [bem.e(
                              "review-complete-disabled"
                            )]: !isCompleteReviewed,
                          })}
                        >
                          {!rest.isClientReviewed && (
                            <Button
                              className={classnames(
                                bem.e("submit-all-feedback"),
                                "btn-radius"
                              )}
                              disabled={isDisabledButtonSubmit}
                              onClick={rest.onSubmitAllFeedback}
                              color="blue"
                            >
                              {isClientReview ? (
                                <FormattedMessage id="process > button submit all feedback" />
                              ) : (
                                submitButton.text
                              )}
                            </Button>
                          )}

                          <div className={bem.e("review-complete-image")}>
                            {isCompleteReviewed ? (
                              <img src={ReviewCompleteImage} alt="" />
                            ) : (
                              <img src={ReviewNotDoneImage} alt="" />
                            )}
                          </div>
                          <h2 className={bem.e("review-complete-title")}>
                            {isCompleteReviewed ? (
                              isClientReview ? (
                                <FormattedMessage id="process > title thanks for your review!" />
                              ) : (
                                <FormattedMessage id="process > title review is complete!" />
                              )
                            ) : (
                              <FormattedMessage id="process > title please approve or reject all before submitting" />
                            )}
                          </h2>
                          {isCompleteReviewed && isClientReview && (
                            <p className={bem.e("review-complete-description")}>
                              <FormattedMessage id="process > title click button above to submit all feedback to complete" />
                            </p>
                          )}
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
      <EnlargeAssetModal
        isOpen={rest.isOpenEnlargeAssetModal}
        onToggle={() =>
          rest.setIsOpenEnlargeAssetModal(!rest.isOpenEnlargeAssetModal)
        }
        asset={currentAsset}
      />
      <ConfirmationModal
        isOpen={rest.isDeleteModalOpen}
        title={rest.intl.formatMessage({
          id: "process > are you sure you want to delete this comment",
        })}
        onConfirm={() => {
          if (rest.currentDeleteComment) {
            rest.setIsDeleteModalOpen(false);
            rest.onDeleteComment(rest.currentDeleteComment);
          }
        }}
        onToggle={() => {
          rest.setIsDeleteModalOpen(false);
          rest.setCurrentDeleteComment(null);
        }}
        onCancel={() => {
          rest.setCurrentDeleteComment(null);
          rest.setIsDeleteModalOpen(false);
        }}
        isCloseOutside={false}
      />
      <PreviewMediaModal
        isOpen={!!rest.currentPreviewMediaModalOpen}
        channel={channel}
        currentMedia={rest.currentPreviewMediaModalOpen}
        isShowFileName
        onToggle={() =>
          rest.setCurrentPreviewMediaModalOpen(
            rest.currentPreviewMediaModalOpen
              ? null
              : rest.currentPreviewMediaModalOpen
          )
        }
      />
    </div>
  );
}
export default ChannelMedia;
