import React, { useMemo, useState } from "react";
import bn from "utils/bemnames";
import classnames from "classnames";
import { ShadowBox, SectionTitle, SectionFields } from "components/Elements";
import { filter, get } from "lodash";
import { FaClose, FaCheckCircle } from "react-icons/lib/fa";
import { FormattedMessage } from "react-intl";
import HandIcon from "assets/img/icons/hand-icon.png";
import ArrowGuideIcon from "assets/img/icons/arrow-guide.png";
import ToggleSwitch from "components/ToggleSwitch";
import ConfirmationModal from "components/ConfirmationModal";

const bem = bn.create("select-team-guide");
const SelectTeamGuide = ({
  roles,
  process,
  validationState,
  selectedRole,
  toggleUserRole,
  allCandidates,
  isView,
  intl,
  toggleProducerAssignsVO,
}) => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  let isAssignedAnyRole = false;
  let rolesSelected = (
    <div className={bem.e("roles")}>
      {filter(roles, (role) => role && !role.is_disabled).map((role, ind) => {
        let assigned = false;
        let assigned_users = [];
        let is_array =
          role &&
          process.data.fields[get(role, "field")] &&
          process.data.fields[get(role, "field")].value &&
          process.data.fields[get(role, "field")].value.users;
        if (role) {
          if (is_array) {
            assigned =
              process.data.fields[get(role, "field")].value.users.length > 0;
            assigned_users = assigned
              ? process.data.fields[get(role, "field")].value.users
              : [];
          } else {
            assigned =
              process.data.fields[get(role, "field")] &&
              process.data.fields[get(role, "field")].value &&
              process.data.fields[get(role, "field")].value.id;
            assigned_users = assigned
              ? [process.data.fields[get(role, "field")].value]
              : [];
          }
        }

        if (assigned) isAssignedAnyRole = true;
        return (
          <div key={ind} className={bem.e("role-item")}>
            <div
              key={get(role, "field") + "_" + ind}
              className={classnames(bem.e("role-item-content"), {
                [bem.e("role-selected")]: selectedRole === role,
              })}
            >
              {assigned ? (
                <div
                  className={classnames(bem.e("role-info"), {
                    [bem.e("role-info-view")]: isView,
                  })}
                >
                  <div className={bem.e("role-left")}>
                    <div className={bem.e("checked")}>
                      <FaCheckCircle color="#10BA37" size={25} />
                    </div>
                    <span
                      className={classnames(
                        bem.e("role-title"),
                        classnames({
                          [bem.e("role-error")]:
                            validationState[get(role, "field")] &&
                            validationState[get(role, "field")]
                              .validation_error,
                        })
                      )}
                    >
                      {get(role, "title")}:
                    </span>
                  </div>
                  <div className={bem.e("role-right")}>
                    {assigned_users.map((assigned_user, index) => {
                      let user = allCandidates.find((candidate) => {
                        return (
                          Number(candidate.info.id) === Number(assigned_user.id)
                        );
                      });
                      return (
                        <div key={index} className={bem.e("assigned-user")}>
                          <span className={bem.e("assigned-user-name")}>
                            {assigned_user.name}
                          </span>
                          {!isView && (
                            <span
                              className={bem.e("close-icon")}
                              onClick={() => {
                                toggleUserRole(user, role);
                              }}
                            >
                              <FaClose size={20} color="#C2D4E0" />
                            </span>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <div
                  className={classnames(bem.e("role-info"), {
                    [bem.e("role-info-view")]: isView,
                  })}
                >
                  <div className={bem.e("role-icon-checked")} />
                  <span className={bem.e("role-title")}>
                    {get(role, "title")}:
                  </span>
                </div>
              )}
              {validationState[get(role, "field")] && (
                <div className="alert-danger">
                  {validationState[get(role, "field")].validation_error}
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
  const isShowAssignedVoWarning = useMemo(() => {
    const assignedVo = get(
      process,
      "data.fields.role_voice_talent.value.users",
      []
    );
    const uploadedVoiceTracks = get(
      process,
      "data.fields.voice_tracks.value",
      []
    );
    return (
      get(assignedVo, "length", 0) > 0 &&
      get(uploadedVoiceTracks, "length", 0) > 0
    );
  }, [process]);

  const producerAssignsVo = get(
    process,
    "data.fields.producer_assigns_vo.value",
    false
  );
  return (
    <div className={bem.b()}>
      <ShadowBox
        className={classnames({
          [bem.e("box-view")]: isView,
        })}
      >
        <SectionTitle>
          <FormattedMessage
            id={isView ? `select team > team` : `select team > assign team`}
          />
          <ToggleSwitch
            leftComponent={intl.formatMessage({
              id: "production settings > producer assigns vo",
            })}
            rightComponent={null}
            name="producer_assigns_vo"
            switchProps={{
              checked: !producerAssignsVo,
              onChange: (checked) => {
                if (isShowAssignedVoWarning) {
                  setIsConfirmationModalOpen(true);
                } else {
                  toggleProducerAssignsVO(!checked);
                }
              },
              disabled: isView,
              offColor: "#795AFA",
              onColor: "#C2D4E0",
              uncheckedIcon: false,
              checkedIcon: false,
              boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.282)",
              height: 26.44,
              width: 48.48,
              handleDiameter: 22.92,
            }}
          />
        </SectionTitle>
        <SectionFields>
          {!isAssignedAnyRole ? (
            <div className={bem.e("content")}>
              <ul className={bem.e("list")}>
                {filter(roles, (item) => item && !item.is_disabled).map(
                  (item, index) => (
                    <li
                      key={index}
                      className={classnames({
                        [bem.e("role-error")]:
                          validationState[get(item, "field")] &&
                          validationState[get(item, "field")].validation_error,
                      })}
                    >
                      {get(item, "title")}
                      <div
                        className="alert-danger"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {validationState[get(item, "field")] &&
                          validationState[get(item, "field")].validation_error}
                      </div>
                    </li>
                  )
                )}
              </ul>
              <div className={bem.e("description")}>
                <img
                  className={bem.e("arrow-icon")}
                  src={ArrowGuideIcon}
                  alt=""
                />
                <FormattedMessage id="select team > assign team guide description" />
              </div>
              <div className={bem.e("hand")}>
                <img src={HandIcon} alt="" />
              </div>
            </div>
          ) : (
            <div>{rolesSelected}</div>
          )}
        </SectionFields>
      </ShadowBox>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        title={intl.formatMessage({
          id: "process > title producer assign vo confirmation",
        })}
        confirmTitle={intl.formatMessage({
          id: "process > button ok",
        })}
        cancelTitle={intl.formatMessage({
          id: "process > button cancel",
        })}
        isCloseOutside={false}
        onConfirm={() => {
          setIsConfirmationModalOpen(false);
          toggleProducerAssignsVO(!producerAssignsVo);
        }}
        onToggle={() => setIsConfirmationModalOpen(false)}
        onCancel={() => setIsConfirmationModalOpen(false)}
      />
    </div>
  );
};
export default SelectTeamGuide;
