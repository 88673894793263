import { connect } from "react-redux";
import ItemDetails from "./view";
import { get } from "lodash";

import { injectIntl } from "react-intl";
const Enhanced = connect((state) => {
  const clocksData = get(state, "programmings.clocksData.data", {});
  return {
    auth: state.auth,
    slotDefinitions: get(clocksData, "definitions", []),
    dateFormatByServer: get(
      state,
      "servers.currentServer.data.date_format",
      "MM/DD/YYYY"
    ),
  };
}, {})(ItemDetails);

export default injectIntl(Enhanced);
