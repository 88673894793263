import React, { useRef } from "react";
import Select, { components } from "react-select";
import { FormattedMessage } from "react-intl";
import { Label } from "reactstrap";
import classnames from "classnames";
import {
  CaretIcon,
  CloseSmallIcon,
  PlusGreenIcon,
} from "components/CustomIcons";
import bn from "utils/bemnames";
import TextInput from "components/TextInput";
const bem = bn.create("dropdown-location");

const Option = ({ children, ...props }) => {
  return <components.Option {...props}>{children}</components.Option>;
};

const DropdownLocation = (props) => {
  const { value, intl, onAddNew, ...rest } = props;
  const SingleValue = (componentProps) => {
    return (
      <components.SingleValue {...componentProps}>
        {componentProps.data.label}
      </components.SingleValue>
    );
  };
  const DropdownIndicator = (componentProps) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...componentProps}>
          <CaretIcon />
        </components.DropdownIndicator>
      )
    );
  };
  const ClearIndicator = (componentProps) => {
    return (
      components.ClearIndicator && (
        <components.ClearIndicator {...componentProps}>
          <CloseSmallIcon />
        </components.ClearIndicator>
      )
    );
  };
  const MenuList = (componentProps) => {
    return (
      <components.MenuList {...componentProps}>
        {componentProps.children}
        {onAddNew && (
          <div className={bem.e("menu-create-location")}>
            <TextInput
              placeholder={intl.formatMessage({
                id: "location > create new location",
              })}
              value={props.newLocationName}
              onChange={({ target }) => props.setNewLocationName(target.value)}
            />
            <span className={bem.e("plus-icon")} onClick={onAddNew}>
              <PlusGreenIcon />
            </span>
          </div>
        )}
      </components.MenuList>
    );
  };
  return (
    <>
      {!!props.label && (
        <Label for={props.name} {...props.labelProps}>
          {props.label}
        </Label>
      )}

      <Select
        {...rest}
        isSearchable={false}
        className={classnames(
          "react-select-container",
          bem.b(),
          props.className,
          {
            "react-select-menu-open": props.isMenuOpen,
          }
        )}
        onChange={(selected, meta) => {
          return props.onChange(selected, meta);
        }}
        classNamePrefix="react-select"
        value={value ? value : ""}
        options={props.options}
        onMenuOpen={() => props.setIsMenuOpen(true)}
        onMenuClose={() => props.setIsMenuOpen(false)}
        // menuIsOpen={true}
        styles={{
          menu: (provided) => ({
            ...provided,
            width: "100%",
          }),
        }}
        components={{
          Option,
          SingleValue,
          DropdownIndicator,
          ClearIndicator,
          MenuList,
        }}
      />
      {!!props.error && <span className="text-danger">{props.error}</span>}
    </>
  );
};
export default DropdownLocation;
