import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  ModalBody,
  Button,
  ModalFooter,
  ModalHeader,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import bn from "utils/bemnames";
import {
  map,
  forEach,
  includes,
  xor,
  get,
  intersection,
  filter,
  lowerCase,
} from "lodash";
import { CloseIcon } from "components/CustomIcons";
import { privileges_meta } from "utils/privilegesMeta";
import { FormattedMessage } from "react-intl";
import Checkbox from "components/Checkbox";
import { DownIcon, UpIcon } from "components/CustomIcons";
import { arrayContainsArray } from "utils/helpers";
const bem = bn.create("manage-privileges-modal");

function ManagePrivilegesModal(props) {
  const {
    isOpen,
    onToggle,
    title,
    privileges,
    enable_production,
    enable_digital,
    enable_promotion,
    production_multimarket,
    digital_multimarket,
    promotion_multimarket,
    onCancel,
    validPrivileges,
  } = props;
  const { station } = props;
  const [values, setValues] = useState({
    privileges: [],
  });
  const [isOpenPrivileges, setIsOpenPrivileges] = useState({
    0: true,
  });
  useEffect(() => {
    setIsOpenPrivileges({
      0: true,
    });
  }, [isOpen]);
  useEffect(() => {
    if (!station) return;
    const selectedPrivileges = get(station, "privileges", []).map(
      (item) => item.value
    );
    setValues({
      privileges: selectedPrivileges,
    });
  }, [station]);

  if (!enable_production) {
    delete privileges_meta.privileges_groups.Production;
  }

  if (!enable_digital) {
    delete privileges_meta.privileges_groups.Digital;
  }

  if (!enable_promotion) {
    delete privileges_meta.privileges_groups.Promotions;
    delete privileges_meta.privileges_groups["Jock Console"];
  }

  if (
    !production_multimarket &&
    !digital_multimarket &&
    !promotion_multimarket
  ) {
    privileges_meta.privileges_groups.Admin.sub_groups = filter(
      privileges_meta.privileges_groups.Admin.sub_groups,
      (subGroup) => subGroup.name !== "Teams"
    );
  }
  return (
    <Modal isOpen={isOpen} centered className={classnames(bem.b())}>
      <ModalHeader>
        <span className={bem.e("title")}>{title}</span>
        <div className={bem.e("close")} onClick={onToggle}>
          <CloseIcon color="#657894" />
        </div>
      </ModalHeader>
      <ModalBody className={bem.e("body")}>
        <div>
          {map(
            Object.keys(privileges_meta.privileges_groups),
            (privileges_group, indexGroup) => {
              let totalSelectedPrivileges = 0;
              forEach(
                privileges_meta.privileges_groups[privileges_group].sub_groups,
                (sub_group, index) => {
                  const currentTotal = Object.keys(
                    privileges_meta.privileges
                  ).reduce((total, privilege_key) => {
                    let privilege = privileges_meta.privileges[privilege_key];
                    privilege = privilege.groups.find(
                      (group) =>
                        group.group === privileges_group &&
                        sub_group.name === group.sub_group
                    );
                    if (
                      privilege &&
                      privileges &&
                      privileges.find((p) => p.name === privilege_key)
                    ) {
                      let privilege_id = privileges.find(
                        (p) => p.name === privilege_key
                      ).id;
                      if (includes(values.privileges, Number(privilege_id))) {
                        total += 1;
                      }
                    }
                    return total;
                  }, 0);
                  totalSelectedPrivileges += currentTotal;
                }
              );
              let isShowChild = isOpenPrivileges[indexGroup];
              return (
                <div
                  className={classnames(bem.e("section-container"))}
                  key={indexGroup}
                >
                  <div
                    className={bem.e("section-title")}
                    onClick={() => {
                      setIsOpenPrivileges({
                        [indexGroup]: !isOpenPrivileges[indexGroup],
                      });
                    }}
                  >
                    {props.intl.formatMessage({
                      id: `role > title ${lowerCase(privileges_group)}`,
                    })}{" "}
                    {props.intl.formatMessage({
                      id: "role > privileges",
                    })}
                    {totalSelectedPrivileges > 0 ? (
                      <span className={bem.e("total")}>
                        ({totalSelectedPrivileges})
                      </span>
                    ) : null}
                    <span className={bem.e("section-icon")}>
                      {isOpenPrivileges[indexGroup] ? <UpIcon /> : <DownIcon />}
                    </span>
                  </div>
                  <div
                    className={classnames(bem.e("content-view"), {
                      [bem.e("show")]: isShowChild,
                      [bem.e("hide")]: !isShowChild,
                    })}
                  >
                    <Row>
                      {map(
                        privileges_meta.privileges_groups[privileges_group]
                          .sub_groups,
                        (sub_group, index) => {
                          let sub_group_privileges = Object.keys(
                            privileges_meta.privileges
                          ).filter((privilege_key) => {
                            let privilege =
                              privileges_meta.privileges[privilege_key];
                            return (
                              privilege.groups.find(
                                (group) =>
                                  group.group === privileges_group &&
                                  sub_group.name === group.sub_group
                              ) &&
                              privileges.find((p) => p.name === privilege_key)
                            );
                          });
                          let sub_group_privilege_ids = [];
                          if (sub_group.select_all) {
                            sub_group_privilege_ids = sub_group_privileges
                              .map((privilege_key) => {
                                if (
                                  privileges &&
                                  privileges.find(
                                    (p) => p.name === privilege_key
                                  )
                                ) {
                                  return privileges.find(
                                    (p) => p.name === privilege_key
                                  ).id;
                                }
                              })
                              .map(Number);
                          }
                          // remove group when current auth don't assigned
                          if (sub_group_privileges.length === 0) return null;
                          const subGroupPrivilegesIds = intersection(
                            sub_group_privilege_ids,
                            validPrivileges
                          );
                          return (
                            <Col xs="4" key={index}>
                              <div className={bem.e("sub-group-title")}>
                                {props.intl.formatMessage({
                                  id: `role > title ${lowerCase(
                                    sub_group.name
                                  )}`,
                                })}
                              </div>
                              {sub_group.select_all && (
                                <Col key={index} className={bem.e("p-left-0")}>
                                  <Checkbox
                                    text={props.intl.formatMessage({
                                      id: "role > select all",
                                    })}
                                    disabled={
                                      subGroupPrivilegesIds.length === 0
                                    }
                                    checked={arrayContainsArray(
                                      values.privileges,
                                      subGroupPrivilegesIds
                                    )}
                                    onChange={(checked) => {
                                      let selectedPrivileges = [];
                                      if (
                                        arrayContainsArray(
                                          values.privileges,
                                          subGroupPrivilegesIds
                                        )
                                      ) {
                                        selectedPrivileges = xor(
                                          values.privileges,
                                          subGroupPrivilegesIds
                                        );
                                      } else {
                                        selectedPrivileges = [
                                          ...values.privileges,
                                          ...subGroupPrivilegesIds.filter(
                                            (item) =>
                                              !includes(values.privileges, item)
                                          ),
                                        ];
                                      }
                                      setValues({
                                        privileges: selectedPrivileges,
                                      });
                                    }}
                                  />
                                </Col>
                              )}
                              {map(
                                sub_group_privileges,
                                (privilege_key, index) => {
                                  if (
                                    !privileges.find(
                                      (p) => p.name === privilege_key
                                    )
                                  )
                                    return null;

                                  let privilege =
                                    privileges_meta.privileges[privilege_key];
                                  let privilege_id = privileges.find(
                                    (p) => p.name === privilege_key
                                  ).id;
                                  return (
                                    <Col
                                      key={index}
                                      className={bem.e("p-left-0")}
                                    >
                                      <Checkbox
                                        text={privilege.display_name}
                                        checked={includes(
                                          values.privileges,
                                          parseInt(privilege_id)
                                        )}
                                        disabled={
                                          !includes(
                                            validPrivileges,
                                            parseInt(privilege_id)
                                          )
                                        }
                                        onChange={() => {
                                          const selectedPrivileges = xor(
                                            values.privileges,
                                            [parseInt(privilege_id)]
                                          );
                                          setValues({
                                            privileges: selectedPrivileges,
                                          });
                                        }}
                                      />
                                    </Col>
                                  );
                                }
                              )}
                            </Col>
                          );
                        }
                      )}
                    </Row>
                  </div>
                </div>
              );
            }
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          className={classnames(bem.e("button-submit"), "btn-blue btn-radius")}
          onClick={() => {
            const newPrivileges = privileges
              .filter((item) =>
                includes(get(values, "privileges", []), parseInt(item.id))
              )
              .map((item) => ({
                value: parseInt(item.id),
                label: item.name,
              }));
            props.onConfirm(newPrivileges);
          }}
        >
          <FormattedMessage id="user > button submit" />
        </Button>
        <Button
          type="button"
          outline
          className={classnames(
            bem.e("button-cancel"),
            "btn-outline-blue btn-radius"
          )}
          onClick={onCancel}
        >
          <FormattedMessage id="user > button cancel" />
        </Button>
      </ModalFooter>
    </Modal>
  );
}

ManagePrivilegesModal.propTypes = {
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};

ManagePrivilegesModal.defaultProps = {
  onToggle: () => {},
  onCancel: () => {},
  onConfirm: () => {},
};

export default ManagePrivilegesModal;
