import View from "./view";
import { compose, withHandlers, withState } from "recompose";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { delay, get } from "lodash";
import { updateClient, createClient } from "store/actions/clients";
import { withRouter } from "react-router";
import URL from "utils/urls";
import { PRIVILEGES } from "utils/constants";
import { validatePrivileges } from "utils/helpers";
export default injectIntl(
  withRouter(
    compose(
      connect(
        (state, props) => {
          return {
            auth: get(state, "auth"),
            isLoading: get(state, "clients.updateClient.loading", false),
            ...props,
          };
        },
        {
          createClient,
          updateClient,
        }
      ),
      withState("shouldBlockNavigation", "setShouldBlockNavigation", false),
      withState(
        "isConfirmationSuccessModal",
        "setIsConfirmationSuccessModal",
        false
      ),
      withHandlers({
        onSubmit: ({
          setIsConfirmationSuccessModal,
          updateClient,
          client,
          history,
          createClient,
          onSubmittedSuccess,
          auth,
        }) => (values) => {
          const privileges = get(auth, "user.privileges", []);
          const isEditable = validatePrivileges({
            requires: [PRIVILEGES.EDIT_CLIENT],
            privileges,
            user: auth.user
          });
          if (get(values, "id")) {
            updateClient(client.id, values, (response) => {
              setIsConfirmationSuccessModal(true);
              if (onSubmittedSuccess) {
                onSubmittedSuccess({...values, contacts: response.contacts});
              } else {
                delay(() => {
                  history.push(
                    URL.CLIENT_MANAGER_FORM({
                      action: isEditable ? "edit" : "view",
                      client_id: client.id,
                    })
                  );
                }, 1000);
              }
            });
          } else {
            createClient(values, (response) => {
              setIsConfirmationSuccessModal(true);
              delay(() => {
                history.push(
                  URL.CLIENT_MANAGER_FORM({
                    action: isEditable ? "edit" : "view",
                    client_id: response.id,
                  })
                );
              }, 1000);
            });
          }
        },
      })
    )(View)
  )
);
