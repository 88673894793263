import React from "react";
import bn from "utils/bemnames";
import { MenuIcon } from "components/CustomIcons";
import classnames from "classnames";
import { FaClose } from "react-icons/lib/fa";
import { FormattedMessage } from "react-intl";
import { labelToNameField } from "utils/helpers";
import { FormGroup, Row, Col, Label } from "reactstrap";
import ToggleSwitch from "components/ToggleSwitch";
import TextInput from "components/TextInput";
import { get } from "lodash";
import { Asterisk } from "components/Elements";
import TextInputFormatted from "components/TextInputFormatted";
import NumberFormat from "react-number-format";
import { getFieldName } from "../helper";
const bem = bn.create("form-builder-fields");

const SocialSecurity = ({
  intl,
  isNew,
  isView,
  field,
  value,
  errors,
  onChange,
  touched,
  onDelete,
  allowDelete,
  isFormBuilder,
  disabled,
  isShowRequiredToggle,
  isMasterListener,
  isRenderRow,
  colNumber,
  isShowOffText,
  isRenderFieldNameFromLabel,
}) => {
  // UI for form builder
  if (isFormBuilder) {
    if (isNew) {
      return (
        <Row>
          <Col className="form-builder-field">
            <div className={bem.b()}>
              <div
                className={classnames(
                  bem.e("form-field-group"),
                  bem.e("form-field-group-line")
                )}
              >
                <Row>
                  <Col xs={isShowRequiredToggle ? 9 : 12}>
                    <div className={bem.e("form-field-group-head")}>
                      <div
                        className={bem.e("form-field-group-head-single-left")}
                      >
                        <span className={bem.e("menu-icon")}>
                          <MenuIcon />
                        </span>
                        <div
                          className={classnames(
                            bem.e("input-label"),
                            {
                              [bem.e(
                                "full-input-label"
                              )]: !isShowRequiredToggle,
                            },
                            {
                              [bem.e("master-input-field")]: isMasterListener,
                            }
                          )}
                        >
                          <TextInput
                            label={null}
                            name="label"
                            type="text"
                            placeholder={intl.formatMessage({
                              id: "form builder > label social security",
                            })}
                            value={value.label}
                            onChange={({ target }) => {
                              onChange({
                                ...value,
                                label: target.value,
                              });
                            }}
                            disabled={disabled}
                            error={get(errors, "label", "")}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  {isShowRequiredToggle && (
                    <Col xs={3}>
                      <ToggleSwitch
                        leftComponent={
                          <span
                            className={classnames({
                              [bem.e("switch-active")]: !get(
                                value,
                                "is_required",
                                false
                              ),
                            })}
                          >
                            {isShowOffText
                              ? intl.formatMessage({
                                  id: "form builder > off",
                                })
                              : ""}
                          </span>
                        }
                        rightComponent={
                          <span
                            className={classnames({
                              [bem.e("switch-active")]: get(
                                value,
                                "is_required",
                                false
                              ),
                            })}
                          >
                            {intl.formatMessage({
                              id: "form builder > required",
                            })}
                          </span>
                        }
                        name="is_required"
                        switchProps={{
                          checked: !get(value, "is_required", false),
                          onChange: (checked) => {
                            onChange({
                              ...value,
                              is_required: !checked,
                            });
                          },
                          disabled: disabled,
                          offColor: "#795AFA",
                          onColor: "#E3ECF1",
                          uncheckedIcon: false,
                          checkedIcon: false,
                          handleDiameter: 22,
                          width: 50,
                          height: 26,
                        }}
                      />
                    </Col>
                  )}
                </Row>
                {allowDelete && (
                  <div
                    className={classnames(
                      bem.e("form-field-group-head-right"),
                      bem.e("form-field-group-delete-icon")
                    )}
                    onClick={onDelete}
                  >
                    <FaClose size="23" color="#C2D4E0" />
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    // ui for form
  } else {
    const fieldName = isRenderFieldNameFromLabel
      ? labelToNameField(get(field, "label", ""))
      : getFieldName(field);
    if (isView) {
      return (
        <Row>
          <Col>
            <div className={bem.b()}>
              <div className={bem.e("form-field-group-saved")}>
                <Label>{get(field, "label", "")}</Label>
                <span className="order-view-value">
                  {value ? (
                    <NumberFormat
                      value={value}
                      displayType={"text"}
                      format={"###-##-####"}
                    />
                  ) : (
                    <FormattedMessage id="form builder > none" />
                  )}
                </span>
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    const elementField = (
      <div className={bem.b()}>
        <div
          className={classnames(
            bem.e("form-field-group-saved"),
            "form-builder-edit-field-2"
          )}
        >
          <FormGroup className={bem.e("form-group")}>
            <Label>
              {get(field, "label", "")}{" "}
              {get(field, "is_required", false) && <Asterisk>*</Asterisk>}
            </Label>
            <div className={bem.e("social-security-group")}>
              <TextInputFormatted
                placeholder={intl.formatMessage({
                  id: "form builder > placeholder xxx",
                })}
                value={get(value, "number1", "")}
                format={"###"}
                onChange={(newValue) => {
                  onChange({
                    number1: newValue,
                    number2: get(value, "number2", ""),
                    number3: get(value, "number3", ""),
                  });
                }}
                disabled={disabled}
              />
              <span className="divider">-</span>
              <TextInputFormatted
                placeholder={intl.formatMessage({
                  id: "form builder > placeholder xx",
                })}
                value={get(value, "number2", "")}
                format={"##"}
                onChange={(newValue) => {
                  onChange({
                    number1: get(value, "number1", ""),
                    number2: newValue,
                    number3: get(value, "number3", ""),
                  });
                }}
                disabled={disabled}
              />
              <span className="divider">-</span>
              <TextInputFormatted
                placeholder={intl.formatMessage({
                  id: "form builder > placeholder xxxx",
                })}
                value={get(value, "number3", "")}
                format={"####"}
                onChange={(newValue) => {
                  onChange({
                    number1: get(value, "number1", ""),
                    number2: get(value, "number2", ""),
                    number3: newValue,
                  });
                }}
                disabled={disabled}
              />
            </div>
            {get(touched, fieldName, false) && get(errors, fieldName, "") ? (
              <span className="text-danger">{get(errors, fieldName, "")}</span>
            ) : null}
          </FormGroup>
        </div>
      </div>
    );
    if (isRenderRow)
      return (
        <Row>
          <Col>{elementField}</Col>
        </Row>
      );
    return <Col xs={colNumber}>{elementField}</Col>;
  }
};
SocialSecurity.defaultProps = {
  isNew: false,
  isFormBuilder: true,
  errors: {},
  touched: {},
  onChange: () => {},
  onDelete: () => {},
  allowDelete: true,
  disabled: false,
  isShowRequiredToggle: true,
  isMasterListener: false,
  isRenderRow: true,
  colNumber: 6,
  isShowPrimary: false,
  isShowOffText: true,
  isRenderFieldNameFromLabel: true,
};
export default SocialSecurity;
