import React from "react";
import { FormattedMessage } from "react-intl";
import { Col } from "reactstrap";

export default function AllocationTable(props) {
	const { unAllocation, allocation, touched, errors, bem } = props;
	return (
		<Col className={bem.e("col-reset")} xs={12}>
			<div className={bem.e("allocation-table")}>
				<div className={bem.e("table-head")}>
					<div className={bem.e("table-left")}>
						<FormattedMessage id="prizes > allotment" />
					</div>
					<div className={bem.e("table-right")}>
						<FormattedMessage id="prizes > quantity" />
					</div>
				</div>
				<div className={bem.e("table-content")}>
					<div className={bem.e("table-row")}>
						<div className={bem.e("table-left")}>
							<span className={bem.e("table-text")}>
								<FormattedMessage id="prizes > total allotted" />
							</span>
						</div>
						<div className={bem.e("table-right")}>
							<span className={bem.e("table-text")}>{allocation}</span>
						</div>
					</div>
					<div className={bem.e("table-row")}>
						<div className={bem.e("table-left")}>
							<span className={bem.e("table-text")}>
								<FormattedMessage id="prizes > total unallotted" />
							</span>
						</div>
						<div className={bem.e("table-right")}>
							<span className={bem.e("table-text")}>{unAllocation}</span>
						</div>
					</div>
				</div>
			</div>
			{touched && errors && (
				<span className="alert-danger">
					{touched.allocated && errors.allocated}
				</span>
			)}
		</Col>
	);
}