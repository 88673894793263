import React from "react";
import bn from "utils/bemnames";
import { FormattedMessage } from "react-intl";
import SearchBox from "components/SearchBox";
import { Link } from "react-router-dom";
import { adminTabs } from "utils/config";
import URL from "utils/urls";

const bem = bn.create("process-list");
function ClientManagerHeader(props) {
  return (
    <div className={bem.e("header")}>
      <div className={bem.e("title")}>
        <Link to={URL.ADMINS({ tab: adminTabs.TAB_UNKNOWN })}>
          <FormattedMessage id="breadcrumbs > home" /> /{` `}
        </Link>

        <strong>
          <FormattedMessage id={`client manager > client management`} />
        </strong>
      </div>
      <div className={bem.e("header-actions")}>
        <div className="process-title">
          <FormattedMessage id={`client manager > client management`} />
        </div>
        {props.isShowSearch && (
          <SearchBox
            placeholder={props.intl.formatMessage({
              id: "client manager > placeholder search input",
            })}
            onSearchClicked={props.onSearchClicked}
            searchText={props.searchText}
            setSearchText={props.setSearchText}
          />
        )}
      </div>
    </div>
  );
}
ClientManagerHeader.defaultProps = {
  isShowSearch: true,
};
export default ClientManagerHeader;
