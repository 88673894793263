import React, { useEffect, useMemo, useState } from "react";
import bn from "utils/bemnames";
import { useRef } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "reactstrap";
import classnames from "classnames";
import Dropdown from "components/Dropdown";
import PropTypes from "prop-types";
import classNames from "classnames";

const HEADER_HEIGHT = 95;

const bem = bn.create("team-management-filter-view");
function ContentFilterView(props) {
  const { initialValues, assignedHubs, intl, onSubmit, roles } = props;
  const [values, setValues] = useState({
    hub: "",
    role: "",
  });
  useEffect(() => {
    setValues(initialValues);
  }, [initialValues]);
  const contentHeight = useRef(0);
  useEffect(() => {
    const sidebar = document.getElementById("right-sidebar");
    if (sidebar) {
      contentHeight.current = sidebar.offsetHeight - HEADER_HEIGHT;
    }
  }, []);
  const hubOptions = useMemo(() => {
    if (assignedHubs.length === 0) {
      return [];
    }
    const options = assignedHubs.map((item) => ({
      label: item.name,
      value: item.id,
      data: item,
    }));
    return options;
  }, [assignedHubs]);

  const roleOptions = useMemo(() => {
    if (roles.length === 0) {
      return [];
    }
    const options = roles.map((item) => ({
      label: item.name,
      value: item.id,
      data: item,
    }));
    return options;
  }, [roles]);
  return (
    <div
      style={{
        height: contentHeight.current,
      }}
      className={classnames(bem.b(), "scroll-bar-style")}
    >
      <div className={bem.e("filter-view-content")}>
        <div>
          <Dropdown
            label={<FormattedMessage id="hub manager > market hub" />}
            containerClass={bem.e("dropdown")}
            placeholder={intl.formatMessage({
              id: "hub manager > please select",
            })}
            name="hub"
            onChange={(selectedOption) => {
              setValues({
                ...values,
                hub: selectedOption ? selectedOption.value : "",
              });
            }}
            value={
              hubOptions.find((item) => item.value === values.hub) || {
                label: intl.formatMessage({ id: "hub manager > all hubs" }),
                value: "",
              }
            }
            options={hubOptions}
            allowSelectAll={true}
            allOption={{
              label: intl.formatMessage({ id: "hub manager > all hubs" }),
              value: "",
            }}
          />
          <Dropdown
            label={<FormattedMessage id="hub manager > role" />}
            placeholder={intl.formatMessage({
              id: "hub manager > please select",
            })}
            containerClass={bem.e("dropdown")}
            name="role"
            onChange={(selectedOption) => {
              setValues({
                ...values,
                role: selectedOption ? selectedOption.value : "",
              });
            }}
            value={
              roleOptions.find((role) => role.value === values.role) || {
                label: intl.formatMessage({ id: "hub manager > all roles" }),
                value: "",
              }
            }
            options={roleOptions}
            allowSelectAll={true}
            allOption={{
              label: intl.formatMessage({ id: "hub manager > all roles" }),
              value: "",
            }}
          />
        </div>
        <div className={bem.e("buttons")}>
          <Button
            color="blue"
            className={classNames("btn-radius", bem.e("btn-apply"))}
            disabled={!values.hub && !values.role}
            onClick={() => {
              onSubmit(values);
            }}
          >
            <FormattedMessage id="hub manager > apply" />
          </Button>
          <Button
            color="link"
            className={classNames("btn-radius", bem.e("btn-clear"))}
            onClick={() => {
              setValues({
                hub: "",
                role: "",
              });
              onSubmit({
                hub: "",
                role: "",
              });
            }}
          >
            <FormattedMessage id="hub manager > clear" />
          </Button>
        </div>
      </div>
    </div>
  );
}
ContentFilterView.defaultProps = {
  initialValues: {
    hub: "",
    role: "",
  },
};
ContentFilterView.propTypes = {
  initialValues: PropTypes.object,
};

export default ContentFilterView;
