import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, Button, ModalHeader } from "reactstrap";
import classnames from "classnames";
import bn from "utils/bemnames";
import { FormattedMessage } from "react-intl";
import UserAssignments from "components/UserAssignments";
import { CloseIcon } from "components/CustomIcons";
const bem = bn.create("user-assignments-modal");

const UserAssignmentsModal = ({ isOpen, onToggle, onSubmit }) => {
  return (
    <Modal
      isOpen={isOpen}
      className={classnames(bem.b())}
    >
      <ModalHeader toggle={onToggle}>
        <FormattedMessage id="user > assignments" />
        <button className={bem.e("close")} onClick={onToggle}>
          <CloseIcon color={"#657894"} />
        </button>
      </ModalHeader>
      <ModalBody className={bem.e("body")}>
        <UserAssignments
          mode="edit"
          onSubmit={onSubmit}
          backButton={
            <Button
              type="button"
              outline
              className={classnames(
                bem.e("button-cancel"),
                "btn-outline-blue btn-radius"
              )}
              onClick={onToggle}
            >
              <FormattedMessage id="user > button cancel" />
            </Button>
          }
        />
      </ModalBody>
    </Modal>
  );
};

UserAssignmentsModal.propTypes = {
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
};

UserAssignmentsModal.defaultProps = {
  onToggle: () => {},
};

export default UserAssignmentsModal;
