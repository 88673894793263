import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import bn from "utils/bemnames";
import { Button } from "reactstrap";
import classnames from "classnames";
import { CloseIcon } from "components/CustomIcons";
import { FormattedMessage } from "react-intl";
import TextInput from "components/TextInput";
const bem = bn.create("contest-rules-modal");
function ContestRulesModal(props) {
  const { onSubmit, value, isAllowEdit } = props;
  const [isView, setIsView] = useState(true);
  const [currentValue, setCurrentValue] = useState(value);
  return (
    <Modal isOpen={props.isOpen} className={bem.b()}>
      <ModalHeader>
        <FormattedMessage id="process > promotion > contest > contest rules" />
        <div className={bem.e("close-icon")} onClick={props.onToggle}>
          <CloseIcon color="#657894" />
        </div>
      </ModalHeader>
      <ModalBody className={bem.e("body")}>
        {isView ? (
          <span className={bem.e("contest-rules-view")}>
            {currentValue ? (
              currentValue
            ) : (
              <FormattedMessage id="process > none" />
            )}
          </span>
        ) : (
          <TextInput
            label={
              <FormattedMessage id="process > promotion > contest > contest rules description" />
            }
            type="textarea"
            value={currentValue}
            onChange={({ target }) => {
              setCurrentValue(target.value || "");
            }}
          />
        )}
      </ModalBody>
      {isAllowEdit && (
        <ModalFooter>
          <Button
            color="blue"
            className={classnames(bem.e("btn-submit"), "btn-radius")}
            onClick={() => {
              if (isView) {
                setIsView(false);
              } else {
                onSubmit(currentValue);
                setIsView(true);
              }
            }}
          >
            {isView ? (
              <FormattedMessage id="process > button edit" />
            ) : (
              <FormattedMessage id="process > button submit" />
            )}
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
}

ContestRulesModal.defaultProps = {
  isOpen: false,
  value: "",
  onSubmit: () => {},
  isAllowEdit: true,
};

export default ContestRulesModal;
