import React from "react";
import Page from "components/Page";
import PrivilegedComponent from "components/PrivilegedComponent";
import Dashboard from "components/Dashboard";
import { Redirect } from "react-router";
const DashboardPage = props => {
  return (
    <PrivilegedComponent requires={[]}>
      {({ isValid }) => {
        if (isValid) {
          return (
            <Page title="" applyPadding={false}>
              <Dashboard {...props} />
            </Page>
          );
        } else {
          return <Redirect to="/" />;
        }
      }}
    </PrivilegedComponent>
  );
};

export default DashboardPage;
