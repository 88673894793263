import React, { useEffect, useState, forwardRef } from "react";
import { Row, Col, FormGroup, Label } from "reactstrap";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import {
  difference,
  find,
  forEach,
  get,
  includes,
  intersection,
  isEmpty,
  isEqual,
} from "lodash";
import { setScriptField } from "utils/helpers";
import TextareaEvaluator from "components/TextareaEvaluator";
import TextInputFormatted from "components/TextInputFormatted";
import TextInput from "components/TextInput";
import ToggleSwitch from "components/ToggleSwitch";
import {
  ShadowBox,
  SectionTitle,
  SectionFields,
  Asterisk,
} from "components/Elements";
import { DownIcon, UpIcon } from "components/CustomIcons";
import { grandScriptTags, qualifierScriptAfterTags } from "utils/config";
import moment from "moment";
import DaysOfWeek from "components/DaysOfWeek";
import DatePicker from "components/DatePicker";
import Badge from "components/Badge";
import Dropdown from "components/Dropdown";
const ContestQualifying = (props) => {
  const {
    validationState,
    process,
    bem,
    step,
    onValueChanged,
    intl,
    isView,
    openDetails,
    setOpenDetails,
    dateFormatByServer
  } = props;
  const [fieldRefs, setFieldRefs] = useState(null);
  const getFieldRefs = () => {
    if (fieldRefs) return fieldRefs;

    const script_before = step.fields.find((f) => f.field === "script_before");
    const script_after = step.fields.find((f) => f.field === "script_after");
    const jock_instructions = step.fields.find(
      (f) => f.field === "jock_instructions"
    );
    const winner_selection = step.fields.find(
      (f) => f.field === "winner_selection"
    );
    const plays_number = step.fields.find((f) => f.field === "plays_number");

    const order_start_date = step.fields.find(
      (f) => f.field === "order_start_date"
    );
    const order_end_date = step.fields.find(
      (f) => f.field === "order_end_date"
    );
    const days_of_week = step.fields.find((f) => f.field === "days_of_week");
    const excluded_dates = step.fields.find(
      (f) => f.field === "excluded_dates"
    );
    const desired_items_scheduling = step.fields.find(
      (f) => f.field === "desired_items_scheduling"
    );
    const qualifying_prize = step.fields.find(
      (f) => f.field === "qualifying_prize"
    );
    if (!process.data.fields.qualifying_prize) {
      onValueChanged(qualifying_prize, true, {
        shouldBlockNavigation: false,
      });
    }

    const payload = {
      excluded_dates,
      days_of_week,
      order_end_date,
      order_start_date,
      plays_number,
      script_before,
      script_after,
      jock_instructions,
      desired_items_scheduling,
      winner_selection,
      qualifying_prize,
    };

    setFieldRefs(payload);

    return payload;
  };

  const {
    script_before,
    script_after,
    jock_instructions,
    excluded_dates,
    days_of_week,
    order_end_date,
    order_start_date,
    plays_number,
    winner_selection,
    desired_items_scheduling,
    qualifying_prize,
  } = getFieldRefs();
  const [selectedDates, setSelectedDates] = useState([]);
  const winnerSelection = get(
    process,
    `data.fields.${winner_selection.field}.value`,
    ""
  );
  const orderType = get(process, `data.fields.order_type.value`, "");
  const isPrizeValue = get(
    process,
    `data.fields.qualifying_prize.value`,
    false
  );
  const selectedWinnerSelection =
    find(winner_selection.props.options, (i) => i.value === winnerSelection) ||
    "";
  const isEnableWinnerSelection = includes(
    ["online_registration", "text_to_win"],
    orderType
  );
  const isSelectedDay = (date) => {
    let selectedDays = process.data.fields[days_of_week.field]
      ? process.data.fields[days_of_week.field].value
      : [];
    const day = moment(date).day() + 1;
    return includes(selectedDays, day);
  };

  const getAvailableDates = () => {
    if (
      !process.data.fields[order_start_date.field] ||
      !process.data.fields[order_end_date.field]
    ) {
      return [];
    }

    let startDate = moment(process.data.fields[order_start_date.field].value);
    let endDate = moment(process.data.fields[order_end_date.field].value);

    let dates = [];
    let currentDate = startDate.subtract(1, "days");
    let addDays = function(days) {
      let date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };
    while (currentDate <= endDate) {
      if (isSelectedDay(currentDate)) {
        dates.push(currentDate);
      }

      currentDate = addDays.call(currentDate, 1);
    }

    return dates;
  };

  const _setSelectedDates = () => {
    let selectedDates = [];

    let excludedDates = process.data.fields[excluded_dates.field]
      ? process.data.fields[excluded_dates.field].value
      : [];

    excludedDates = excludedDates.map((date) =>
      moment(date).format("YYYY-MM-DD")
    );

    let availableDates = getAvailableDates();

    forEach(availableDates, (date) => {
      if (!includes(excludedDates, moment(date).format("YYYY-MM-DD"))) {
        selectedDates.push(date);
      }
    });

    setSelectedDates(selectedDates);

    let _excludedDates = intersection(
      excludedDates,
      availableDates.map((date) => moment(date).format("YYYY-MM-DD"))
    );

    if (!isEqual(excludedDates, _excludedDates)) {
      onValueChanged(excluded_dates, _excludedDates);
    }
  };
  const ExcludeDatesButton = forwardRef(({ value, onClick }, ref) => (
    <div className="exclude-dates-btn" onClick={onClick} ref={ref}>
      <FormattedMessage id="process > promotion > liner > exclude dates" />
    </div>
  ));

  const renderExcludedDates = () => {
    const excludedDates = process.data.fields[excluded_dates.field]
      ? process.data.fields[excluded_dates.field].value
      : [];

    return excludedDates.map((date) => {
      return (
        <Badge
          key={date}
          text={moment(date).format("MMM D")}
          onClick={() => {
            onValueChanged(excluded_dates, difference(excludedDates, [date]));
          }}
        />
      );
    });
  };
  useEffect(() => {
    _setSelectedDates();
  }, [process]);
  const isOpen = get(openDetails, "script", false);

  return (
    <ShadowBox className={bem.e("qualifying-details")}>
      <SectionTitle
        className={bem.e("contest-script-section-title")}
        onClick={() =>
          setOpenDetails({
            ...openDetails,
            script: !isOpen,
          })
        }
      >
        <FormattedMessage id="process > promotion > contest > title qualifying" />
        <span className={bem.e("box-action")}>
          {isOpen ? <UpIcon /> : <DownIcon />}
        </span>
      </SectionTitle>
      {isOpen && (
        <React.Fragment>
          <SectionFields
            className={classnames(bem.e("section-fields"), {
              [bem.e("contest-qualifying-section-fields-view")]: isView,
              [bem.e("contest-qualifying-section-fields")]: !isView,
            })}
          >
            {/* render view */}
            {isView ? (
              <React.Fragment>
                <Row>
                  <Col xs={4} className={bem.e("reset-col")}>
                    <FormGroup>
                      <Label>
                        <FormattedMessage id="process > promotion > contest > qualifier selection" />
                      </Label>
                      <span className={bem.e("order-view-value")}>
                        {winnerSelection
                          ? winnerSelection === "selected_on_air"
                            ? intl.formatMessage({
                                id:
                                  "process > promotion > contest > selected on air",
                              })
                            : get(selectedWinnerSelection, "label", "")
                          : intl.formatMessage({ id: "process > none" })}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col xs={4} className={bem.e("reset-col")}>
                    <FormGroup>
                      <ToggleSwitch
                        leftComponent={intl.formatMessage({
                          id: "process > promotion > contest > prize",
                        })}
                        rightComponent={intl.formatMessage({
                          id: "process > promotion > contest > no prize",
                        })}
                        containerClassName={bem.e("toggle-switch")}
                        name="qualifying_prize"
                        switchProps={{
                          checked: !isPrizeValue,
                          onChange: (checked) => {},
                          disabled: isView,
                          offColor: "#795AFA",
                          onColor: "#E3ECF1",
                          uncheckedIcon: false,
                          checkedIcon: false,
                          handleDiameter: 22,
                          width: 50,
                          height: 26,
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={4} className={bem.e("reset-col")}>
                    <FormGroup>
                      <Label>{plays_number.title}</Label>
                      <span className={bem.e("order-view-value")}>
                        {process.data.fields[plays_number.field]
                          ? process.data.fields[plays_number.field].value
                          : intl.formatMessage({ id: "process > none" })}
                      </span>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={4} className={bem.e("reset-col")}>
                    <FormGroup>
                      <Label>{order_start_date.title}</Label>
                      <span className={bem.e("order-view-value")}>
                        {process.data.fields[order_start_date.field]
                          ? moment(
                              process.data.fields[order_start_date.field].value
                            ).format(dateFormatByServer)
                          : intl.formatMessage({ id: "process > none" })}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col xs={4} className={bem.e("reset-col")}>
                    <FormGroup>
                      <Label>
                        <FormattedMessage id="process > days of the week" />
                      </Label>
                      <span className={bem.e("order-view-value")}>
                        {process.data.fields[days_of_week.field] ? (
                          <DaysOfWeek
                            isView={true}
                            value={get(
                              process,
                              "data.fields.days_of_week.value",
                              []
                            )}
                          />
                        ) : (
                          intl.formatMessage({ id: "process > none" })
                        )}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col xs={4} className={bem.e("reset-col")}>
                    <FormGroup>
                      <Label>{order_end_date.title}</Label>
                      <span className={bem.e("order-view-value")}>
                        {process.data.fields[order_end_date.field]
                          ? moment(
                              process.data.fields[order_end_date.field].value
                            ).format(dateFormatByServer)
                          : intl.formatMessage({ id: "process > none" })}
                      </span>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} className={bem.e("reset-col")}>
                    <FormGroup>
                      <Label>{excluded_dates.title}</Label>
                      <span
                        className={classnames(
                          bem.e("order-view-value"),
                          bem.e("order-view-value-excluded-dates")
                        )}
                      >
                        {process.data.fields[excluded_dates.field]
                          ? process.data.fields[excluded_dates.field].value
                              .map((date) => moment(date).format("MMM D"))
                              .join(", ")
                          : intl.formatMessage({ id: "process > none" })}
                      </span>
                    </FormGroup>
                  </Col>
                </Row>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Row>
                  <Col xs={4} className={bem.e("reset-col")}>
                    <FormGroup>
                      {winnerSelection === "selected_on_air" ? (
                        <div className={bem.e("winner-on-air")}>
                          <Label>
                            <FormattedMessage id="process > promotion > contest > qualifier selection" />
                          </Label>
                          <span className={bem.e("order-view-value")}>
                            {intl.formatMessage({
                              id:
                                "process > promotion > contest > selected on air",
                            })}
                          </span>
                        </div>
                      ) : (
                        <Dropdown
                          label={
                            <span>
                              <FormattedMessage id="process > promotion > contest > qualifier selection" />
                              {winner_selection.mandatory &&
                                isEnableWinnerSelection && (
                                  <Asterisk>*</Asterisk>
                                )}
                            </span>
                          }
                          name="winner_selection"
                          placeholder={intl.formatMessage({
                            id: "process > promotion > contest > select",
                          })}
                          onChange={(selectedOption) => {
                            onValueChanged(
                              winner_selection,
                              selectedOption.value
                            );
                          }}
                          value={selectedWinnerSelection}
                          {...winner_selection.props}
                          error={get(
                            validationState,
                            "winner_selection.validation_error"
                          )}
                        />
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs={4} className={bem.e("reset-col")}>
                    <FormGroup>
                      <ToggleSwitch
                        leftComponent={intl.formatMessage({
                          id: "process > promotion > contest > prize",
                        })}
                        rightComponent={intl.formatMessage({
                          id: "process > promotion > contest > no prize",
                        })}
                        containerClassName={bem.e("toggle-switch")}
                        name="qualifying_prize"
                        switchProps={{
                          checked: !isPrizeValue,
                          onChange: (checked) => {
                            onValueChanged(qualifying_prize, !checked);
                          },
                          disabled: isView,
                          offColor: "#795AFA",
                          onColor: "#E3ECF1",
                          uncheckedIcon: false,
                          checkedIcon: false,
                          handleDiameter: 22,
                          width: 50,
                          height: 26,
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col xs={4} className={bem.e("reset-col")}>
                    <Row>
                      <Col xs={12} className={bem.e("reset-col")}>
                        <FormGroup>
                          <TextInputFormatted
                            label={
                              <span>
                                {plays_number.title}
                                {plays_number.mandatory && (
                                  <Asterisk>*</Asterisk>
                                )}
                              </span>
                            }
                            name={`plays_number`}
                            value={
                              process.data.fields[plays_number.field]
                                ? process.data.fields[plays_number.field].value
                                : ""
                            }
                            onChange={(value) => {
                              onValueChanged(desired_items_scheduling, value);
                              onValueChanged(plays_number, value);
                            }}
                            error={get(
                              validationState,
                              "plays_number.validation_error"
                            )}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col xs={4} className={bem.e("reset-col")}>
                    <FormGroup>
                      <TextInput
                        label={
                          <span>
                            {order_start_date.title}
                            {order_start_date.mandatory && (
                              <Asterisk>*</Asterisk>
                            )}
                          </span>
                        }
                        type="date"
                        name="order_start_date"
                        placeholder={intl.formatMessage({
                          id: "process > promotion > contest > select date",
                        })}
                        min={moment().format("YYYY-MM-DD")}
                        value={
                          process.data.fields[order_start_date.field]
                            ? process.data.fields[order_start_date.field].value
                            : ""
                        }
                        onChange={(value) => {
                          onValueChanged(order_start_date, value);
                          onValueChanged(order_end_date, ""); // reset order end date
                        }}
                        error={get(
                          validationState,
                          "order_start_date.validation_error"
                        )}
                        allowShowIcon
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={4} className={bem.e("reset-col")}>
                    <FormGroup>
                      <TextInput
                        label={
                          <span>
                            {order_end_date.title}
                            {order_end_date.mandatory && <Asterisk>*</Asterisk>}
                          </span>
                        }
                        type="date"
                        name="order_end_date"
                        placeholder={intl.formatMessage({
                          id: "process > promotion > contest > select date",
                        })}
                        min={get(
                          process,
                          "data.fields.order_start_date.value",
                          moment().format("YYYY-MM-DD")
                        )}
                        value={
                          process.data.fields[order_end_date.field]
                            ? process.data.fields[order_end_date.field].value
                            : ""
                        }
                        onChange={(value) => {
                          onValueChanged(order_end_date, value);
                        }}
                        error={get(
                          validationState,
                          "order_end_date.validation_error"
                        )}
                        allowShowIcon
                      />
                      {process.data.fields[order_start_date.field] &&
                        process.data.fields[order_start_date.field].value &&
                        process.data.fields[order_end_date.field] &&
                        process.data.fields[order_end_date.field].value && (
                          <div className="cr-textinput exclude-date-picker">
                            <DatePicker
                              field={
                                process.data.fields[order_start_date.field]
                                  ? process.data.fields[order_start_date.field]
                                  : null
                              }
                              onChange={(date) => {
                                let excludedDates = process.data.fields[
                                  excluded_dates.field
                                ]
                                  ? process.data.fields[excluded_dates.field]
                                      .value
                                  : [];

                                if (!includes(excludedDates, date)) {
                                  excludedDates.push(date);
                                } else {
                                  excludedDates = difference(excludedDates, [
                                    date,
                                  ]);
                                }

                                onValueChanged(excluded_dates, excludedDates);
                              }}
                              name="selected_dates"
                              minDate={moment(
                                process.data.fields[order_start_date.field]
                                  .value
                              ).toDate()}
                              maxDate={moment(
                                process.data.fields[order_end_date.field].value
                              ).toDate()}
                              filterDate={isSelectedDay}
                              highlightDates={selectedDates}
                              selected={null}
                              customInput={<ExcludeDatesButton />}
                              shouldCloseOnSelect={false}
                            />
                          </div>
                        )}
                    </FormGroup>
                  </Col>
                  <Col xs={4} className={bem.e("reset-col")}>
                    <FormGroup>
                      <DaysOfWeek
                        value={get(
                          process,
                          "data.fields.days_of_week.value",
                          []
                        )}
                        label={
                          <span>
                            {days_of_week.title}
                            {days_of_week.mandatory && <Asterisk>*</Asterisk>}
                          </span>
                        }
                        error={get(
                          validationState,
                          "days_of_week.validation_error"
                        )}
                        onChange={(days) => {
                          onValueChanged(days_of_week, days);
                        }}
                        checkStyle={{
                          color: "#fff",
                          width: "11",
                          height: "11",
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {process.data.fields[excluded_dates.field] &&
                  !isEmpty(process.data.fields[excluded_dates.field].value) && (
                    <Row className={bem.e("badges")}>
                      <Col className={bem.e("reset-col")}>
                        <FormGroup>
                          <span className={bem.e("badge-title")}>
                            {excluded_dates.title}
                            {excluded_dates.mandatory && <Asterisk>*</Asterisk>}
                          </span>
                          <div className={bem.e("badges-container")}>
                            {renderExcludedDates()}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  )}
              </React.Fragment>
            )}
          </SectionFields>
          <SectionTitle className={bem.e("contest-script-section-title")}>
            <FormattedMessage id="process > promotion > contest > title script details" />
          </SectionTitle>
          <SectionFields
            className={classnames(
              bem.e("section-fields"),
              bem.e("contest-script-section-fields")
            )}
          >
            {/* render view */}
            {isView ? (
              <React.Fragment>
                <Row>
                  <Col xs={12} className={bem.e("col-reset")}>
                    <FormGroup>
                      <span className={bem.e("script-subtitle")}>
                        <FormattedMessage id="process > script" />
                      </span>
                      <div
                        className={classnames(
                          bem.e("order-view-value"),
                          bem.e("script-view-value")
                        )}
                      >
                        <TextareaEvaluator
                          readOnly={true}
                          title={
                            <FormattedMessage id="process > promotion > contest > before qualifier selection" />
                          }
                          text={setScriptField(
                            get(process, "data.fields.script_before.value", "")
                          )}
                          orderLengthText={
                            <FormattedMessage id="process > promotion > contest > length" />
                          }
                          showScriptLength={true}
                          onEvaluatorCallback={() => {}}
                          hideHelperText={true}
                          showInnerTitle
                          isShowWordCounting
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col xs={12} className={bem.e("col-reset")}>
                    <FormGroup>
                      <span className={bem.e("script-subtitle")}>
                        <FormattedMessage id="process > script" />
                      </span>
                      <div
                        className={classnames(
                          bem.e("order-view-value"),
                          bem.e("script-view-value")
                        )}
                      >
                        <TextareaEvaluator
                          readOnly={true}
                          title={
                            <FormattedMessage id="process > promotion > contest > after qualifier selection" />
                          }
                          text={setScriptField(
                            get(process, "data.fields.script_after.value", "")
                          )}
                          orderLengthText={
                            <FormattedMessage id="process > promotion > contest > length" />
                          }
                          showScriptLength={true}
                          onEvaluatorCallback={() => {}}
                          hideHelperText={true}
                          showInnerTitle
                          isShowWordCounting
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Row>
                  <Col xs={12} className={bem.e("reset-col")}>
                    <FormGroup>
                      <span className={bem.e("script-subtitle")}>
                        <FormattedMessage id="process > script" />
                      </span>
                      <TextareaEvaluator
                        text={setScriptField(
                          get(process, "data.fields.script_before.value", "")
                        )}
                        title={
                          <FormattedMessage id="process > promotion > contest > before qualifier selection" />
                        }
                        orderLengthText={
                          <FormattedMessage id="process > promotion > contest > length" />
                        }
                        placeholder={intl.formatMessage({
                          id: "process > enter script",
                        })}
                        showScriptLength={true}
                        tags={grandScriptTags}
                        onEvaluatorCallback={(value) => {
                          onValueChanged(script_before, value);
                        }}
                        showInnerTitle
                        hideHelperText={true}
                        error={get(
                          validationState,
                          "script_before.validation_error"
                        )}
                        isShowWordCounting
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className={bem.e("reset-col")}>
                    <FormGroup>
                      <span className={bem.e("script-subtitle")}>
                        <FormattedMessage id="process > script" />
                      </span>
                      <TextareaEvaluator
                        text={setScriptField(
                          get(process, "data.fields.script_after.value", "")
                        )}
                        title={
                          <FormattedMessage id="process > promotion > contest > after qualifier selection" />
                        }
                        orderLengthText={
                          <FormattedMessage id="process > promotion > contest > length" />
                        }
                        showScriptLength={true}
                        placeholder={intl.formatMessage({
                          id: "process > enter script",
                        })}
                        tags={qualifierScriptAfterTags}
                        onEvaluatorCallback={(value) => {
                          onValueChanged(script_after, value);
                        }}
                        showInnerTitle
                        hideHelperText={true}
                        error={get(
                          validationState,
                          "script_after.validation_error"
                        )}
                        isShowWordCounting
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </SectionFields>
          <div className={bem.e("line")} />
          {/* jock instructions */}
          <SectionFields
            className={classnames(
              bem.e("section-fields"),
              bem.e("contest-script-section-fields"),
              bem.e("contest-script-section-jock-fields")
            )}
          >
            <Row>
              <Col className={bem.e("reset-col")}>
                {isView ? (
                  <FormGroup>
                    <Label>{jock_instructions.title}</Label>
                    <div className={bem.e("order-view-value")}>
                      <TextareaEvaluator
                        readOnly={true}
                        text={setScriptField(
                          get(
                            process,
                            "data.fields.jock_instructions.value",
                            ""
                          )
                        )}
                        placeholder={intl.formatMessage({
                          id: "process > enter here",
                        })}
                        hideOrderLength={true}
                        hideHelperText={true}
                        onEvaluatorCallback={() => {}}
                        showInnerTitle
                      />
                    </div>
                  </FormGroup>
                ) : (
                  <FormGroup className={bem.e("form-section-jock")}>
                    <TextareaEvaluator
                      text={setScriptField(
                        get(process, "data.fields.jock_instructions.value", "")
                      )}
                      title={jock_instructions.title}
                      placeholder={intl.formatMessage({
                        id: "process > enter here",
                      })}
                      onEvaluatorCallback={(value) => {
                        onValueChanged(jock_instructions, value);
                      }}
                      showScriptLength={false}
                      hideHelperText={true}
                      showInnerTitle
                      error={get(
                        validationState,
                        "jock_instructions.validation_error",
                        ""
                      )}
                    />
                  </FormGroup>
                )}
              </Col>
            </Row>
          </SectionFields>
        </React.Fragment>
      )}
    </ShadowBox>
  );
};

export default ContestQualifying;
