import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import classnames from "classnames";
import bn from "utils/bemnames";
import { CloseIcon, DesktopIcon, MobileIcon } from "components/CustomIcons";
import { FormattedMessage } from "react-intl";
import PreviewForm from "../PreviewForm";
import { get } from "lodash";
const bem = bn.create("system-form-preview-modal");
function getSupportView(systemForm) {
  if (!systemForm) return false;
  if (
    systemForm._source.form_type === "business_winner_fulfillment_form" ||
    systemForm._source.form_type === "individual_online_registration_form" ||
    systemForm._source.form_type === "individual_winner_fulfillment_form" ||
    systemForm._source.form_type === "business_online_registration_form"
  ) {
    return true;
  }
  return false;
}
const PreviewModal = ({
  isOpen,
  onToggle,
  systemForm,
  currentStation,
  currentServer,
  ...rest
}) => {
  const [view, setView] = useState("desktop");
  const formType = get(systemForm, "_source.form_type");
  const isSupportView = getSupportView(systemForm);
  return (
    <Modal isOpen={isOpen} className={classnames(bem.b(), bem.m(view))}>
      <ModalHeader>
        <FormattedMessage id="form manager > title preview" />
        <div className={bem.e("actions")}>
          {isSupportView && (
            <React.Fragment>
              <div
                className={bem.e("btn-action")}
                onClick={() => setView("desktop")}
              >
                <DesktopIcon color={view === "desktop" ? "#fff" : "#4683B8"} />
              </div>
              <div
                className={bem.e("btn-action")}
                onClick={() => setView("mobile")}
              >
                <MobileIcon color={view === "mobile" ? "#F7FBFD" : "#4683B8"} />
              </div>
            </React.Fragment>
          )}

          <div className={bem.e("btn-action")} onClick={onToggle}>
            <CloseIcon />
          </div>
        </div>
      </ModalHeader>
      <ModalBody
        className={classnames(bem.e("body"), {
          [bem.e("body-mobile")]: view === "mobile",
          [bem.e("body-desktop")]: view === "desktop",
        })}
      >
        <PreviewForm
          systemForm={systemForm}
          currentStation={currentStation}
          currentServer={currentServer}
          onClose={onToggle}
          isShowMobile={view === "mobile"}
          isConsoleForm={formType.indexOf("console") !== -1}
          isFulfillment={formType.indexOf("fulfillment") !== -1}
          isCommonForm={
            formType.indexOf("console") === -1 &&
            formType.indexOf("fulfillment") === -1
          }
          isContestPreview={rest.isContestPreview}
          title={rest.title}
          description={rest.description}
        />
      </ModalBody>
    </Modal>
  );
};

PreviewModal.propTypes = {
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
  systemForm: PropTypes.object.isRequired,
  currentStation: PropTypes.object.isRequired,
  currentServer: PropTypes.object.isRequired,
};

PreviewModal.defaultProps = {
  onToggle: () => {},
};

export default PreviewModal;
