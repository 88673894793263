export default {
  control: {
    backgroundColor: "#fff",
    fontSize: 14
  },
  input: {
    margin: 0
  },
  textarea: {
    color: "blue"
  },
  "&multiLine": {
    control: {
      border: "0px solid silver",
      maxHeight: 100,
      overflow: "auto",
      wordBreak: "break-word"
    },
    highlighter: {
      padding: 9,
      maxHeight: 100,
      overflow: "auto"
    },
    input: {
      padding: 9,
      outline: 0,
      border: 0,
      borderTop: "1px solid #f4f4f4",
      overflow: "auto"
    }
  },
  "&singleLine": {
    control: {
      border: "0px solid silver",
      maxHeight: 100,
      overflow: "auto"
    },
    highlighter: {
      padding: 9,
      maxHeight: 100,
      overflow: "auto"
    },
    input: {
      padding: 9,
      outline: 0,
      border: 0,
      borderTop: "1px solid #f4f4f4",
      overflow: "auto"
    }
  },
  suggestions: {
    list: {
      backgroundColor: "#fff",
      border: "0.5px solid #eee",
      maxHeight: 200,
      width: 275,
      overflow: "auto",
      position: "absolute",
      // bottom: 14,
      bottom: "auto",
      top: "23px",
      borderRadius: 5,
      // right: "0px",
      left: "0",
      // right:"0",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)"
    },
    item: {
      padding: "5px 15px",
      borderBottom: "0.5px solid #eee",
      color: "#657894",
      "&focused": {
        backgroundColor: "rgba(188, 213, 229,0.3)"
      }
    }
  }
};

export const mentionStyle = { backgroundColor: "#cee4e5" };
