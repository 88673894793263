import React from "react";
import bn from "utils/bemnames";
import { DownIcon, UpIcon } from "components/CustomIcons";
import { FormattedMessage } from "react-intl";
import ContestDetails from "../ContestDetails";
import PropTypes from "prop-types";
import PrizeDetails from "../PrizeDetails";
import classNames from "classnames";
import { get, filter, includes } from "lodash";
import moment from "moment";
const bem = bn.create("winner-details");
const getWinnerType = (item) => {
  let sortName;
  let name;
  let className;
  if (item.order_type === "call_in_contest") {
    sortName = "Call";
    name = "Call-in";
    className = "call-in";
  }
  return (
    <div className={className}>
      <span className={bem.e("contest-type-name")}>{sortName}</span>
      <span className={bem.e("contest-type-text")}>{name}</span>
    </div>
  );
};
export default function Contest(props) {
  const {
    isPast,
    isOpen,
    item,
    countdowns,
    intl,
    onUpdateFulfilled,
    stations,
  } = props;
  const stationData = filter(stations, (i) =>
    includes(item.order_stations, i.station_key)
  );
  const stationDisplay = stationData
  .map((item) => {
    let stationArr = [];
    if (get(item, "call_letters", ""))
      stationArr.push(item.call_letters);
    if (get(item, "name", ""))
      stationArr.push(item.name);
    return stationArr.join(' - ');
  })
  .join(", ")
  return (
    <div
      className={classNames(bem.e("contest-container"), {
        [bem.e("contest-past-container")]: isPast,
      })}
    >
      <div className={bem.e("contest-head")}>
        <div className={bem.e("contest-type")}>{getWinnerType(item)}</div>
        <div className={bem.e("contest-head-center")}>
          <div className={bem.e("contest-item-title")}>{item.order_title}</div>
          <div className={bem.e("contest-station")}>
            {stationDisplay}
          </div>
        </div>
        <div className={bem.e("contest-head-right")}>
          {isPast || get(countdowns, "isNegative", false) ? (
            <div className={bem.e("contest-expired")}>
              <FormattedMessage id="winners > expired" />
            </div>
          ) : (
            countdowns && (
              <div className={bem.e("contest-time")}>
                {countdowns.days > 0 && (
                  <span className={bem.e("contest-date")}>
                    {countdowns.days}{" "}
                    {intl.formatMessage({ id: "winners > days" })}
                  </span>
                )}

                <span className={bem.e("contest-hour")}>
                  {moment(
                    `${countdowns.hours}:${countdowns.min}`,
                    "hh:mm"
                  ).format("hh:mm")}
                  {` `}
                  HRS
                </span>
              </div>
            )
          )}
          <div
            className={bem.e("action")}
            onClick={() => props.setIsOpen(!isOpen)}
          >
            {isOpen ? <UpIcon /> : <DownIcon />}
          </div>
        </div>
      </div>
      {isOpen && (
        <div className={bem.e("contest-content")}>
          <ContestDetails item={item} stationDisplay={stationDisplay} />
          <PrizeDetails
            item={item}
            isPast={isPast}
            onUpdateFulfilled={onUpdateFulfilled}
            isDeadlineNegative={get(countdowns, "isNegative", false)}
          />
        </div>
      )}
    </div>
  );
}
Contest.propTypes = {
  isPast: PropTypes.bool,
};
Contest.defaultProps = {
  isPast: false,
};
