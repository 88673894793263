import { FormattedMessage } from "react-intl";
import React from "react";
import { get } from "lodash";

const prizes = {
  process_step_index: 2,
  key: "prizes",
  caption_color: "#CC1EE6",
  title: <FormattedMessage id="process > title prizes" />,
  step_roles: [1, 2, 3],
  step_viewers_roles: [1, 2, 3, 4, 7],
  step_editors_roles: [1, 2, 3, 4],
  step_viewers_privileges: ["select_promotion_event_prizes"],
  step_editors_privileges: ["select_promotion_event_prizes"],
  description: null,
  status: "initial",
  disabled_until_completed_steps: [],
  step_container: "none",
  no_edit_button: true,
  fields: [
    {
      component: "prizes",
    },
    {
      field: "qualifying_prizes",
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        const qualifying_prizes = get(
          process,
          "fields.qualifying_prizes.value",
          []
        );
        // if (
        //   get(process, "fields.qualifying_prizes.value.length") === 0
        // ) {
        //   return "Please select a qualifying prizes from the Prize Closet below";
        // }
        const required = qualifying_prizes.filter(
          (prize) => !prize.per_play || !prize.fulfillment
        );
        if (required.length !== 0) {
          return "Please select per play or fulfillment in each qualifying prizes";
        }
        return true;
      },
    },
    {
      field: "grand_prizes",
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        const grand_prizes = get(process, "fields.grand_prizes.value", []);
        // if (get(process, "fields.grand_prizes.value.length") === 0) {
        //   return "Please select a grand prizes from the Prize Closet below";
        // }
        const required = grand_prizes.filter(
          (prize) => !prize.per_play || !prize.fulfillment
        );
        if (required.length !== 0) {
          return "Please select per play or fulfillment in each grand prizes";
        }
        return true;
      },
    },
    {
      field: "contest_prize_list",
      mandatory: true,
      title: <FormattedMessage id="process > field prizes grand total" />,
      validation: function(step, item, process, template, user, value) {
        if (!get(process, "fields.contest_prize_list.value")) {
          return "Please select a qualifying prizes and grand prizes from the Prize Closet below";
        }
        return true;
      },
    },
    {
      field: "qualifying_prizes_total",
      mandatory: true,
      title: <FormattedMessage id="process > field prizes total" />,
      validation: function(step, item, process, template, user, value) {
        const qualifying_prizes = get(
          process,
          "fields.qualifying_prizes.value",
          []
        );
        if (
          qualifying_prizes.length !== 0 &&
          !get(process, "fields.qualifying_prizes_total.value")
        ) {
          return "Please select a qualifying prizes from the Prize Closet below";
        }
        return true;
      },
    },
    {
      field: "grand_prizes_total",
      mandatory: true,
      title: <FormattedMessage id="process > field prizes total" />,
      validation: function(step, item, process, template, user, value) {
        const grand_prizes = get(process, "fields.grand_prizes.value", []);
        if (
          grand_prizes.length !== 0 &&
          !get(process, "fields.grand_prizes_total.value")
        ) {
          return "Please select a qualifying prizes from the Prize Closet below";
        }
        return true;
      },
    },
    {
      key: "save_button",
      text: <FormattedMessage id="process > button save" />,
      on_click: {
        validate: true,
        save: true,
      },
      fields: [
        {
          key: "prizes_status",
        },
        {
          key: "process_step",
          value: "done",
        },
      ],
      confirmation_dialog_props: {
        description: (
          <FormattedMessage id="process > description to submit prizes" />
        ),
        confirmTitle: <FormattedMessage id="process > button yes" />,
        cancelTitle: <FormattedMessage id="process > button no" />,
      },
      success_dialog_props: {
        title: <FormattedMessage id="process > title submitted" />,
        state: "success",
      },
    },
  ],
};
export default prizes;
