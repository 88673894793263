import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Row, Col, Alert, Button } from "reactstrap";
import { get, isEmpty } from "lodash";
import FormUploadFile from "components/UploadFile/FormUploadFile";
import Player from "components/Player";
import Image from "components/Image";
import { FormattedMessage } from "react-intl";
import { getColor } from "utils/colors";
import { FaSpinner, FaFileO } from "react-icons/lib/fa";
import { getExt, getFilenameFromPath } from "utils/helpers";
import { TrashLagerIcon } from "components/CustomIcons";
import truncate from "truncate";
import {
  isMp3,
  formatDisplayDateTime,
  getFileName,
  isImage,
  isMp4,
} from "utils/helpers";
import PlayerUI from "components/PlayerUI";

const MAX_FILE_NAME_LENGTH = 30;
const Ext = styled.div`
  position: absolute;
  font-size: 10px;
`;
const ExtIcon = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const PlainFileName = styled.div`
  font-size: 12px;
`;
const Buttons = styled.div`
  display: flex;
`;
const DeleteIcon = styled.div`
  position: absolute;
  top: calc(50% - 11px);
  right: -31px;
  cursor: pointer;
  display: block;
`;
const FilesWrapper = styled.div`
  position: relative;
  width: ${(props) => (props.showRemoveIcon ? "calc(100% - 30px)" : "100%")};
`;
const renderPlainFileIcon = (file) => {
  const ext = getExt(file.path);
  return (
    <div>
      <ExtIcon>
        <FaFileO size={60} color={getColor("primary")} />
        <Ext>{ext}</Ext>
      </ExtIcon>
      <PlainFileName>{truncate(file.name, MAX_FILE_NAME_LENGTH)}</PlainFileName>
    </div>
  );
};
const UploadFile = ({
  isLoading,
  error,
  file,
  onFormSubmit,
  onDeleteFile,
  onDownloadFile,
  isShowFormUpload,
  setIsShowFormUpload,
  mimes,
  mode,
  actions,
  acceptMultiple,
  fieldName,
  showRemoveIcon,
  positionActions,
  attachText,
  width,
  height,
  process,
  isBubble,
  disabled,
  alwaysShowUpload,
  isCheckMimesByExt,
  extensions,
  isUploadViewFile,
  isDisabledDelete,
  isShowFileNameInEditMode,
  auditInfo,
  url,
  maxSize,
  path,
  isBookend,
  hideSpotLength,
  spot,
  minDimensions,
  hideFileName,
  customAction,
}) => {
  let fileView;
  if (file) {
    if (
     ( isMp3(file.type, file.path) &&
      file.path.substr(-4).toLowerCase() !== ".ini" &&
      file.path.substr(-4).toLowerCase() !== ".m4a") || isMp4(file.type)
    ) {
      fileView = (
        <Player
          url={file.path}
          width={width}
          height={height}
          isBubble={isBubble}
          process={process}
          recordingUrl={url}
          path={path}
        />
      );
    } else if (isImage(file.mime)) {
      fileView = <Image url={file.path} />;
    } else {
      fileView = renderPlainFileIcon(file);
    }
  }
  let filename;
  if (file) {
    filename = getFilenameFromPath(file.path);
  }
  const actionsView = (
    <Buttons>
      {file && actions.includes("download") && (
        <Button onClick={() => onDownloadFile(file)} size="sm" color="link">
          {acceptMultiple ? (
            filename
          ) : (
            <FormattedMessage id="upload > button download" />
          )}
        </Button>
      )}
      {file && actions.includes("discard") && (
        <Button onClick={() => onDeleteFile(file)} size="sm" color="link">
          <FormattedMessage id="upload > button discard" />
        </Button>
      )}
      {!isShowFormUpload && actions.includes("replace") && (
        <Button
          onClick={() => setIsShowFormUpload(true)}
          color="link"
          size="sm"
        >
          <FormattedMessage id="upload > button replace" />
        </Button>
      )}
    </Buttons>
  );

  const displayAuditInfo = () => {
    if (!isEmpty(auditInfo)) {
      return `By ${get(auditInfo, "modified_by")} on ${get(
        auditInfo,
        "modified_at"
      )}`;
    } else if (
      !isEmpty(
        get(process, "data.fields.old_recording_history.value.value", [])
      )
    ) {
      return formatDisplayDateTime(
        get(process, "data.fields.old_recording_history.value[0].time")
      );
    }

    return null;
  };

  let length1 = get(spot, "length1", "00") || "";
  let length2 = get(spot, "length2", "00") || "";

  return (
    <div className={"upload-file"}>
      {isLoading ? (
        <Row>
          <Col className="d-flex align-items-center justify-content-center upload-loading">
            <FaSpinner size="40" className={"cr-icon-spin"} />
          </Col>
        </Row>
      ) : mode === "view" ? (
        <Row>
          <Col xs={12}>
            {error && <Alert color="danger">{error}</Alert>}
            {file ? (
              <React.Fragment>
                {isBookend && (
                  <div className="file-upload-title-container">
                    <div className="title">
                      {path === "a" ? (
                        <FormattedMessage id="process > part A" />
                      ) : (
                        <FormattedMessage id="process > part B" />
                      )}
                    </div>
                    {!hideSpotLength && (
                      <div className="spot-length">
                        <FormattedMessage id="process > title spot length" />:{" "}
                        <span className="value">
                          {path === "a" ? length1 : length2}
                        </span>
                      </div>
                    )}
                  </div>
                )}
                <FilesWrapper
                  showRemoveIcon={showRemoveIcon}
                  className="files-wrapper"
                >
                  <div className="file-value">
                    {!hideFileName && (
                      <span>{getFileName(get(file, "path"))}</span>
                    )}
                    <span className="file-aduit">{displayAuditInfo()}</span>
                  </div>
                  {fileView}
                </FilesWrapper>
              </React.Fragment>
            ) : (
              <div>
                <PlayerUI
                  width={width}
                  height={height}
                  volume={0}
                  setVolume={() => {}}
                  onClickPlaying={() => {}}
                  recordingUrl={url}
                />
                <span className="message-no-upload">
                  <FormattedMessage id="upload > not submitted yet" />
                </span>
              </div>
            )}
            {actions.length > 0 ? (
              <div className={"upload-file-buttons"}>
                {file && actions.includes("download") && (
                  <Button
                    onClick={() => onDownloadFile(file)}
                    size="sm"
                    color="link"
                  >
                    {acceptMultiple ? (
                      filename
                    ) : (
                      <FormattedMessage id="upload > button download" />
                    )}
                  </Button>
                )}
                {customAction && actions.includes("custom") && (
                  <Button onClick={customAction.onClick} size="sm" color="link">
                    {customAction.title}
                  </Button>
                )}
              </div>
            ) : null}
          </Col>
        </Row>
      ) : (
        <Row>
          <Col xs={12} className="form-upload-file">
            {isBookend && (
              <div className="file-upload-title-container">
                <div className="title">
                  {path === "a" ? (
                    <FormattedMessage id="process > part A" />
                  ) : (
                    <FormattedMessage id="process > part B" />
                  )}
                </div>
                {!hideSpotLength && (
                  <div className="spot-length">
                    <FormattedMessage id="process > title spot length" />:{" "}
                    <span className="value">
                      {path === "a" ? length1 : length2}
                    </span>
                  </div>
                )}
              </div>
            )}
            {error && <Alert color="danger">{error}</Alert>}
            {(isShowFormUpload || alwaysShowUpload) && (
              <FormUploadFile
                mimes={mimes}
                onSubmit={onFormSubmit}
                acceptMultiple={acceptMultiple}
                fieldName={fieldName}
                attachText={attachText}
                disabled={disabled}
                minDimensions={minDimensions}
                extensions={extensions}
                isCheckMimesByExt={isCheckMimesByExt}
                maxSize={maxSize}
              />
            )}
            {positionActions === "top" && actionsView}
          </Col>
          {file && isUploadViewFile && (
            <Col xs={12} className="upload-view-file">
              <FilesWrapper showRemoveIcon={showRemoveIcon}>
                {isShowFileNameInEditMode && (
                  <div className="file-value">
                    <span>{getFileName(get(file, "path"))}</span>
                    <span className="file-aduit">{displayAuditInfo()}</span>
                  </div>
                )}
                {fileView}
                {showRemoveIcon && (
                  <DeleteIcon
                    onClick={() => {
                      if (!isDisabledDelete) onDeleteFile(file);
                    }}
                    className="delete-icon"
                  >
                    <TrashLagerIcon />
                  </DeleteIcon>
                )}
              </FilesWrapper>
              {positionActions === "bottom" && actionsView}
            </Col>
          )}
        </Row>
      )}
    </div>
  );
};

UploadFile.defaultProps = {
  actions: ["discard", "download", "replace"],
  fieldName: "",
  showRemoveIcon: true,
  positionActions: "top",
  disabled: false,
  alwaysShowUpload: false,
  isCheckMimesByExt: false,
  extensions: [],
  isUploadViewFile: true,
  isDisabledDelete: false,
  isShowFileNameInEditMode: false,
  customAction: null,
};

UploadFile.propsTypes = {
  extensions: PropTypes.array,
  mimes: PropTypes.array,
  isCheckMimesByExt: PropTypes.bool,
  mode: PropTypes.oneOf(["view"]),
  actions: PropTypes.arrayOf(
    PropTypes.oneOf(["discard", "download", "replace", "custom"])
  ),
  acceptMultiple: PropTypes.bool,
  fieldName: PropTypes.string,
  positionActions: PropTypes.oneOf(["top", "bottom"]),
  disabled: PropTypes.bool,
  alwaysShowUpload: PropTypes.bool,
  isUploadViewFile: PropTypes.bool,
  isDisabledDelete: PropTypes.bool,
  isShowFileNameInEditMode: PropTypes.bool,
  customAction: PropTypes.shape({
    title: PropTypes.string | PropTypes.element,
    onClick: PropTypes.func,
  }),
};
export default UploadFile;
