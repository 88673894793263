import React from 'react';
import moment from 'moment';
import bn from "utils/bemnames";


import InputHour from './InputHour';
import InputMinute from './InputMinute';
import Dropdown from "components/Dropdown";

const InputTime = props => {

  const bem = bn.create("input-time");

  let currentDateTime = props.time ? moment(props.time) : null;

  const times = {
    hours: currentDateTime ? currentDateTime.format('HH') : '',
    minutes: currentDateTime && !props.emptyMinute ? currentDateTime.format('mm') : '',
    AMPM: currentDateTime ? currentDateTime.format('A') : 'AM',
  };

  const onChange = (field, value) => {


    if(value === ''){
       props.onChange(undefined);
       return true;
    }

    if(!currentDateTime){

      if(value !== ''){
        currentDateTime = moment().set({hours: 0, minutes: 0, seconds: 0});
      }else{
        return false;
      }
    }

    switch (field) {
      case 'hours':
        currentDateTime.set('hour', value);
        break;

      case 'minutes':
        currentDateTime.set('minute', value);

        break;

      default:
        break;
    }

    props.onChange(currentDateTime);
  };

  const handleChangeAMPM = () => {

    const hour = times.hours * 1;

    if (hour === 0) {
      onChange('hours', 12);
    } else if (hour === 12) {
      onChange('hours', 0);
    } else if (times.AMPM === 'AM') {
      const hours = hour < 12 ? hour + 12 : hour;
      onChange('hours', hours);
    } else {
      const hours = hour > 12 ? hour - 12 : hour;
      onChange('hours', hours);
    }
  };

  return (
    <div
      id={props.id}
    >
      <div className={bem.b()}>
        <InputHour
          bem={bem}
          name="hours"
          value={times.hours}
          onChange={data => {
            onChange('hours', data);
          }}
          AMPM={times.AMPM}
          hourLabel={props.hourLabel}
          hourReadOnly={props.hourReadOnly}
          noPlaceHolder={props.noPlaceHolder}
          useWrapper={props.useWrapper}
        />
        <span className={bem.e('separator')}>:</span>
        <InputMinute
          bem={bem}
          key="minutes"
          name="minutes"
          value={times.minutes}
          onChange={data => {
            onChange('minutes', data);
          }}
          minLabel={props.minLabel}
          noPlaceHolder={props.noPlaceHolder}
          useWrapper={props.useWrapper}
        />
        <div className={bem.e('ampm')}>
          {!props.amPmReadOnly ? (<Dropdown
            name="am_pm"
            onChange={() => {
              handleChangeAMPM();
            }}
            value={{
                label: times.AMPM,
                value: times.AMPM,
            }}
            options={[
              {
                label: "AM",
                value: "AM",
              },
              {
                label: "PM",
                value: "PM",
              }
            ]}
          />) : <div className={bem.e("time-text")}>{times.AMPM}</div>}
        </div>
      </div>
      {props.error && (
        <span className="text-danger">{props.error}</span>
      )}
    </div>
  );
};

export default InputTime;
