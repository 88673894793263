import * as Const from "utils/constants";
import Api from "utils/api";
import { get, omit, isFunction } from "lodash";
import * as Types from "store/types/systemForms";
import queryString from "query-string";

export const getSystemForms = (payload, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.FETCH_SYSTEM_FORMS_REQUEST, payload });
    if (!payload.rpp) payload.rpp = 999;
    const url = Const.GET_SYSTEM_FORMS() + `?${queryString.stringify(payload)}`;
    let response = await Api.doCall(url, "GET");
    if (response.status === 200) {
      if (!response.data.error) {
        dispatch({
          type: Types.FETCH_SYSTEM_FORMS_SUCCESS,
          payload: {
            ...omit(payload, ["page", "station_id"]),
            page: get(response, "data.page", 1),
            pages: get(response, "data.pages", 1),
            data: get(response, "data.items", []),
          },
        });
        if (isFunction(cb)) cb(get(response, "data.items", []));
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.FETCH_SYSTEM_FORMS_ERROR,
    payload: errorStr,
  });
};

export const getSystemForm = (payload, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.FETCH_SYSTEM_FORM_REQUEST, payload });
    const url = Const.GET_SYSTEM_FORMS() + `/${payload.id}`;
    let response = await Api.doCall(url, "GET");
    if (response.status === 200) {
      if (!response.data.error) {
        dispatch({
          type: Types.FETCH_SYSTEM_FORM_SUCCESS,
          payload: response.data,
        });
        if (isFunction(cb)) cb(get(response, "data.items", []));
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.FETCH_SYSTEM_FORM_ERROR,
    payload: errorStr,
  });
};

export const updateSystemForm = (payload = {}, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.UPDATE_SYSTEM_FORMS_REQUEST });
    //api call for store data
    let response = await Api.doCall(Const.GET_SYSTEM_FORMS(), "PUT", payload);
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        //response data
        let responseData = JSON.parse(JSON.stringify(response.data));
        dispatch({
          type: Types.UPDATE_SYSTEM_FORMS_SUCCESS,
          payload: responseData,
        });
        dispatch(forgetSystemForms());
        if (isFunction(cb)) cb();
        return;
      }
      //error data
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.UPDATE_SYSTEM_FORMS_ERROR,
    payload: {
      error: errorStr,
    },
  });
};

export const defaultSystemForm = (payload = {}, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.DEFAULT_SYSTEM_FORMS_REQUEST });
    //api call for store data
    let response = await Api.doCall(
      Const.DEFAULT_SYSTEM_FORMS(payload.id),
      "POST",
      payload
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        //response data
        let responseData = JSON.parse(JSON.stringify(response.data));
        dispatch({
          type: Types.DEFAULT_SYSTEM_FORMS_SUCCESS,
          payload: responseData,
        });
        dispatch(forgetSystemForms());
        if (isFunction(cb)) cb();
        return;
      }
      //error data
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.DEFAULT_SYSTEM_FORMS_ERROR,
    payload: {
      error: errorStr,
    },
  });
};

export const duplicateSystemForm = (payload = {}, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.DUPLICATE_SYSTEM_FORMS_REQUEST });
    //api call for store data
    let response = await Api.doCall(
      Const.DUPLICATE_SYSTEM_FORMS(payload.id),
      "POST",
      payload
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        //response data
        let responseData = JSON.parse(JSON.stringify(response.data));
        dispatch({
          type: Types.DUPLICATE_SYSTEM_FORMS_SUCCESS,
          payload: responseData,
        });
        dispatch(forgetSystemForms());
        if (isFunction(cb)) cb();
        return;
      }
      //error data
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.DUPLICATE_SYSTEM_FORMS_ERROR,
    payload: {
      error: errorStr,
    },
  });
};

export function forgetSystemForms() {
  return {
    type: Types.FORGET_SYSTEM_FORMS,
  };
}

export const fetchFulfillmentFormTemplate = (params) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({
      type: Types.FETCH_FULFILLMENT_FORM_TEMPLATE_REQUEST,
    });
    const query = queryString.stringify({
      form_type: "individual_winner_fulfillment_form",
      active_only: 1,
      rpp: 999,
      ...params,
    });
    let response = await Api.doCall(
      `${Const.GET_SYSTEM_FORMS()}?${query}`,
      "GET"
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.FETCH_FULFILLMENT_FORM_TEMPLATE_SUCCESS,
          payload: response.data,
        });
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.FETCH_FULFILLMENT_FORM_TEMPLATE_ERROR,
    payload: { error: errorStr },
  });
};

export const getSystemFormDefault = (payload = {}, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.GET_SYSTEM_FORMS_DEFAULT_REQUEST });
    //api call for store data
    let response = await Api.doCall(
      `${Const.GET_SYSTEM_FORMS()}/get_default`,
      "POST",
      payload
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        //response data
        let responseData = JSON.parse(JSON.stringify(response.data));
        dispatch({
          type: Types.GET_SYSTEM_FORMS_DEFAULT_SUCCESS,
          payload: responseData,
        });
        if (isFunction(cb)) cb();
        return;
      }
      //error data
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.GET_SYSTEM_FORMS_DEFAULT_ERROR,
    payload: {
      error: errorStr,
    },
  });
};
