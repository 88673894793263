import React, { useState, forwardRef, useImperativeHandle } from "react";

import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { CloseIcon } from "components/CustomIcons";
import { DownIcon, UpIcon } from "components/CustomIcons";
import { map } from "lodash";
import bn from "utils/bemnames";
const bem = bn.create("tags-modal");

const TagsModal = forwardRef((props, ref) => {
  const [isOpenModal, setOpenModal] = useState(false);

  const { addTag, tagsInModal = [] } = props;

  useImperativeHandle(ref, () => ({
    toggle: () => {
      setOpenModal(!isOpenModal);
    },
  }));

  const renderTags = (data) => {
    const [expand, setExpand] = useState(true);
    const { title, list } = data;
    return (
      <>
        <div className="group_tags" onClick={() => setExpand(!expand)}>
          {!expand ? (
            <UpIcon
              className={bem.e("button-up-icon")}
              color="#795AFA"
              width={24}
              height={15.46}
            />
          ) : (
            <DownIcon
              className={bem.e("button-down-icon")}
              color="#795AFA"
              width={24}
              height={15.46}
            />
          )}
          {title}
        </div>

        {expand && (
          <ul>
            {list.map((item) => {
              return (
                <li
                  key={item.data_key}
                  onClick={() => {
                    addTag(item.label, item.replaceWith);
                  }}
                >
                  <button>{item.label}</button>
                </li>
              );
            })}
          </ul>
        )}
      </>
    );
  };

  return (
    <Modal isOpen={isOpenModal} className={bem.b()} size="sm">
      <ModalHeader>
        Select
        <button
          className="close-modal-icon"
          onClick={() => setOpenModal(false)}
        >
          <CloseIcon color={"#C2D4E0"} />
        </button>
      </ModalHeader>

      <ModalBody>
        {map(tagsInModal, (item, index) => {
          return <div key={index}>{renderTags(item)}</div>;
        })}
      </ModalBody>
    </Modal>
  );
});

export default TagsModal;
