import * as Types from "store/types/teams";
import { get, map, isFunction, find } from "lodash";
import { userInfo, setIsTeamLead } from "store/actions/auth";
import history from "components/History";
import Api from "utils/api";
import * as Const from "utils/constants";
import URL from "utils/urls";
import queryString from "query-string";
import { adminTabs } from "utils/config";
import { getTeamLeadMembers } from "utils/helpers";

export const getTeams = (state) => async (dispatch) => {
  let errorStr = "";
  try {
    const { pageSize, page, sorted, filtered } = state;
    const params = {
      page: page + 1,
      rpp: pageSize,
    };
    if (sorted.length > 0) {
      const sortedColumns = map(sorted, "id").join(",");
      params.sort = `${sortedColumns} ${
        get(sorted, `${sorted.length - 1}.desc`) ? "desc" : "asc"
      }`;
    }

    const index = filtered.findIndex((item) => item.id === "q");

    if (index !== -1) {
      params.q = filtered[index].value;
    }

    dispatch({ type: Types.FETCH_TEAMS_REQUEST });

    let response = await Api.doCall(
      `${Const.GET_TEAMS()}?${queryString.stringify(params)}`,
      "GET"
    );

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch(fetchTeamsSuccess(response.data));
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch(fetchTeamsError({ data: errorStr }));
};

export const getMoreTeams = (state) => async (dispatch) => {
  let errorStr = "";

  try {
    const { pageSize, page, sorted, filtered } = state;
    const params = {
      page: page + 1,
      rpp: pageSize,
    };
    if (sorted.length > 0) {
      const sortedColumns = map(sorted, "id").join(",");
      params.sort = `${sortedColumns} ${
        get(sorted, `${sorted.length - 1}.desc`) ? "desc" : "asc"
      }`;
    }

    const index = filtered.findIndex((item) => item.id === "q");

    if (index !== -1) {
      params.q = filtered[index].value;
    }

    dispatch({ type: Types.FETCH_MORE_TEAMS_REQUEST });

    let response = await Api.doCall(
      `${Const.GET_TEAMS()}?${queryString.stringify(params)}`,
      "GET"
    );

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch(fetchMoreTeamsSuccess(response.data));
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch(fetchMoreTeamsError({ data: errorStr }));
};

export const getAllStationsAndTeams = (cb) => async (dispatch) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.FETCH_ALL_STATIONS_AND_TEAMS_REQUEST });

    let response = await Api.doCall(
      `${Const.GET_TEAMS()}/stations-and-teams/list`,
      "GET"
    );

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch(fetchAllStationsAndTeamsSuccess(response.data));
        if (isFunction(cb)) {
          cb();
        }
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch(fetchAllStationsAndTeamsError({ data: errorStr }));
};

export const saveTeam = (data, cb) => async (dispatch) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.SAVE_TEAM_REQUEST });

    let response = await Api.doCall(Const.GET_TEAMS(), "POST", data);

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({ type: Types.SAVE_TEAM_SUCCESS, payload: response.data });
        dispatch(userInfo());

        if (isFunction(cb)) {
          cb();
        }

        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({ type: Types.SAVE_TEAM_ERROR, payload: errorStr });
};

export const getTeam = (id) => async (dispatch) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.FETCH_TEAM_REQUEST, payload: { id } });
    let response = await Api.doCall(Const.GET_TEAM(id), "GET");

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch(fetchTeamSuccess({ id, data: response.data }));
        return;
      }

      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch(fetchTeamError({ id, error: errorStr }));
};

export const updateTeam = (id, data, cb) => async (dispatch) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.UPDATE_TEAM_REQUEST });

    let response = await Api.doCall(Const.GET_TEAM(id), "PUT", data);

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.UPDATE_TEAM_SUCCESS,
          payload: {
            id,
            data: response.data,
          },
        });

        dispatch(userInfo());

        if (isFunction(cb)) {
          cb();
        }

        return;
      }

      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({ type: Types.UPDATE_TEAM_ERROR, payload: errorStr });
};

export const deleteTeam = (id) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.DELETE_TEAM_REQUEST });
    let response = await Api.doCall(Const.GET_TEAM(id), "DELETE");
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({ type: Types.DELETE_TEAM_SUCCESS });
        dispatch(userInfo());
        history.push(
          URL.ADMINS({
            tab: adminTabs.TAB_TEAMS,
          })
        );
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.DELETE_TEAM_ERROR, payload: errorStr });
};

export const getTeamMembers = (state) => async (dispatch) => {
  let errorStr = "";
  try {
    const { pageSize, page, sorted, id } = state;
    const params = {
      page,
      rpp: pageSize,
    };
    if (sorted.length > 0) {
      const sortedColumns = map(sorted, "id").join(",");
      params.sort = `${sortedColumns} ${
        get(sorted, `${sorted.length - 1}.desc`) ? "desc" : "asc"
      }`;
    }

    dispatch({ type: Types.FETCH_TEAM_MEMBERS_REQUEST, payload: { id } });

    let response = await Api.doCall(
      `${Const.GET_TEAM_MEMBERS(id)}?${queryString.stringify(params)}`,
      "GET"
    );

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.FETCH_TEAM_MEMBERS_SUCCESS,
          payload: {
            id,
            data: response.data,
            page,
          },
        });
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.FETCH_TEAM_MEMBERS_ERROR, payload: errorStr });
};

export const deleteTeamMember = (teamId, userId, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.DELETE_TEAM_MEMBER_REQUEST });
    let response = await Api.doCall(
      Const.DELETE_TEAM_MEMBER(teamId, userId),
      "DELETE"
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({ type: Types.DELETE_TEAM_MEMBER_SUCCESS });
        if (isFunction(cb)) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.DELETE_TEAM_MEMBER_ERROR, payload: errorStr });
};

export const updateTeamLead = (state, cb) => async (dispatch) => {
  let errorStr = "";
  const { id, user_id } = state;
  dispatch({ type: Types.UPDATE_TEAM_LEAD_REQUEST });

  let response = await Api.doCall(`${Const.UPDATE_TEAM_LEAD(id)}`, "POST", {
    user_id,
  });

  if (response.status === 200 || response.status === 201) {
    if (!response.data.error) {
      dispatch({
        type: Types.UPDATE_TEAM_LEAD_SUCCESS,
        payload: { id, user_id },
      });
      if (isFunction(cb)) {
        cb();
      }
      return;
    }
    errorStr = response.data.error;
  }
  try {
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.UPDATE_TEAM_LEAD_ERROR, payload: errorStr });
};

export const checkPrivilegesForTeamAssignment = (
  userId,
  team_ids,
  cb
) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.CHECK_TEAM_ASSIGNMENT_PRIVILEGES_REQUEST });
    let response = await Api.doCall(
      Const.CHECK_TEAM_ASSIGNMENT_PRIVILEGES(userId),
      "POST",
      { team_ids }
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({ type: Types.CHECK_TEAM_ASSIGNMENT_PRIVILEGES_SUCCESS });
        if (isFunction(cb)) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.CHECK_TEAM_ASSIGNMENT_PRIVILEGES_ERROR,
    payload: errorStr,
  });
};
export const getTeamsAndLeads = (params) => async (dispatch, getState) => {
  let errorStr = "";

  try {
    const state = getState();
    const user = get(state, "auth.user", {});

    dispatch({ type: Types.FETCH_TEAMS_AND_LEADS_REQUEST, payload: params });
    let response = await Api.doCall(
      `${Const.GET_TEAMS_AND_LEADS()}?${queryString.stringify(params, {
        arrayFormat: "bracket",
      })}`,
      "GET"
    );

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        const teamLeadMembers = getTeamLeadMembers(
          get(response, "data.data", [])
        );

        const isUserTeamLead = !!find(
          teamLeadMembers,
          (item) => parseInt(item.id) === parseInt(user.id)
        );
        dispatch(setIsTeamLead(isUserTeamLead)); // update current user is team lead of the group
        dispatch({
          type: Types.FETCH_TEAMS_AND_LEADS_SUCCESS,
          payload: get(response, "data.data", []),
        });
        return;
      }

      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.FETCH_TEAMS_AND_LEADS_ERROR,
    error: errorStr,
  });
};
export function fetchTeamsSuccess(payload) {
  return {
    type: Types.FETCH_TEAMS_SUCCESS,
    payload,
  };
}

export function fetchTeamsError(payload) {
  return {
    type: Types.FETCH_TEAMS_ERROR,
    payload,
  };
}

export function fetchMoreTeamsSuccess(payload) {
  return {
    type: Types.FETCH_MORE_TEAMS_SUCCESS,
    payload,
  };
}

export function fetchMoreTeamsError(payload) {
  return {
    type: Types.FETCH_MORE_TEAMS_ERROR,
    payload,
  };
}

export function fetchAllStationsAndTeamsSuccess(payload) {
  return {
    type: Types.FETCH_ALL_STATIONS_AND_TEAMS_SUCCESS,
    payload,
  };
}

export function fetchAllStationsAndTeamsError(payload) {
  return {
    type: Types.FETCH_ALL_STATIONS_AND_TEAMS_ERROR,
    payload,
  };
}

export function fetchTeamSuccess(payload) {
  return {
    type: Types.FETCH_TEAM_SUCCESS,
    payload,
  };
}

export function fetchTeamError(payload) {
  return {
    type: Types.FETCH_TEAM_ERROR,
    payload,
  };
}
