import * as Types from "../types/users";
import { get, map, isFunction, join } from "lodash";
import history from "../../components/History";
import Api from "../../utils/api";
import * as Const from "../../utils/constants";
import URL from "../../utils/urls";
import queryString from "query-string";
// import { userInfo } from "./auth";
import { adminTabs } from "../../utils/config";

export const validateUserData = (data, cb) => async (dispatch) => {
  try {
    let response = await Api.doCall(Const.VALIDATE_USER_DATA(), "POST", data);
    if (cb) cb(response);
  } catch (error) {
    if (cb) cb(false);
  }
};
export const getAdmins = (state) => async (dispatch) => {
  let errorStr = "";

  try {
    const { pageSize, page, sorted, filtered } = state;
    const params = {
      page: page + 1,
      rpp: pageSize,
    };
    if (sorted.length > 0) {
      const sortedColumns = map(sorted, "id").join(",");
      params.sort = `${sortedColumns} ${
        get(sorted, `${sorted.length - 1}.desc`) ? "desc" : "asc"
      }`;
    }

    const index = filtered.findIndex((item) => item.id === "q");
    if (index !== -1) {
      params.q = filtered[index].value;
    }
    dispatch({ type: Types.FETCH_ADMINS_REQUEST });
    let response = await Api.doCall(
      `${Const.GET_ADMINS()}?${queryString.stringify(params)}`,
      "GET"
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.FETCH_ADMINS_SUCCESS,
          payload: response.data,
        });
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.FETCH_ADMINS_ERROR,
    payload: errorStr,
  });
};

export const getMoreAdmins = (state) => async (dispatch) => {
  let errorStr = "";

  try {
    const { pageSize, page, sorted, filtered } = state;

    const params = {
      page: page + 1,
      rpp: pageSize,
    };

    if (sorted.length > 0) {
      const sortedColumns = map(sorted, "id").join(",");
      params.sort = `${sortedColumns} ${
        get(sorted, `${sorted.length - 1}.desc`) ? "desc" : "asc"
      }`;
    }

    const index = filtered.findIndex((item) => item.id === "q");
    if (index !== -1) {
      params.q = filtered[index].value;
    }

    dispatch({ type: Types.FETCH_MORE_ADMINS_REQUEST });

    let response = await Api.doCall(
      `${Const.GET_ADMINS()}?${queryString.stringify(params)}`,
      "GET"
    );

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.FETCH_MORE_ADMINS_SUCCESS,
          payload: response.data,
        });
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({
    type: Types.FETCH_MORE_ADMINS_ERROR,
    payload: errorStr,
  });
};

export const getUsers = (state) => async (dispatch) => {
  let errorStr = "";

  try {
    const {
      pageSize,
      page,
      sorted,
      filtered,
      filteredTags,
      outOfOffice,
    } = state;
    const params = {
      page: page + 1,
      rpp: pageSize,
    };
    if (sorted.length > 0) {
      const sortedColumns = map(sorted, "id").join(",");
      params.sort = `${sortedColumns} ${
        get(sorted, `${sorted.length - 1}.desc`) ? "desc" : "asc"
      }`;
    }
    if (filteredTags && filteredTags.length > 0) {
      params.tags = join(filteredTags, ",");
    }
    const index = filtered.findIndex((item) => item.id === "q");
    if (index !== -1) {
      params.q = filtered[index].value;
    }
    if (outOfOffice) {
      params.out_of_office_settings = 1;
    }
    dispatch({ type: Types.FETCH_USERS_REQUEST });
    let response = await Api.doCall(
      `${Const.GET_USERS()}?${queryString.stringify(params)}`,
      "GET"
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.FETCH_USERS_SUCCESS,
          payload: response.data,
        });
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.FETCH_USERS_ERROR,
    payload: errorStr,
  });
};

export const getMoreUsers = (state) => async (dispatch) => {
  let errorStr = "";

  try {
    const {
      pageSize,
      page,
      sorted,
      filtered,
      filteredTags,
      outOfOffice,
    } = state;

    const params = {
      page: page + 1,
      rpp: pageSize,
    };

    if (sorted.length > 0) {
      const sortedColumns = map(sorted, "id").join(",");
      params.sort = `${sortedColumns} ${
        get(sorted, `${sorted.length - 1}.desc`) ? "desc" : "asc"
      }`;
    }

    if (filteredTags && filteredTags.length > 0) {
      params.tags = join(filteredTags, ",");
    }

    const index = filtered.findIndex((item) => item.id === "q");

    if (index !== -1) {
      params.q = filtered[index].value;
    }

    if (outOfOffice) {
      params.out_of_office_settings = 1;
    }

    dispatch({ type: Types.FETCH_MORE_USERS_REQUEST });

    let response = await Api.doCall(
      `${Const.GET_USERS()}?${queryString.stringify(params)}`,
      "GET"
    );

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.FETCH_MORE_USERS_SUCCESS,
          payload: response.data,
        });
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({
    type: Types.FETCH_MORE_USERS_ERROR,
    payload: errorStr,
  });
};

export const getUser = (id) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.FETCH_USER_REQUEST, payload: { id } });
    let response = await Api.doCall(Const.GET_USER(id), "GET");
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.FETCH_USER_SUCCESS,
          payload: { id, data: response.data },
        });
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.FETCH_USER_ERROR,
    payload: { id, error: errorStr },
  });
};

export const addUser = (data, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.ADD_USER_REQUEST });
    let response = await Api.doCall(Const.GET_USERS(), "POST", data);
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({ type: Types.ADD_USER_SUCCESS, payload: data });
        // this will be triggered from the server
        // dispatch(userInfo());
        if (isFunction(cb)) cb(response);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
    if (isFunction(cb)) cb(error);
  }
  dispatch({ type: Types.ADD_USER_ERROR, payload: errorStr });
};

export const updateUser = (id, data, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.UPDATE_USER_REQUEST });
    let response = await Api.doCall(Const.UPDATE_USER(id), "POST", data);
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.UPDATE_USER_SUCCESS,
        });
        if (isFunction(cb)) {
          cb(response);
        } else {
          history.push(
            URL.ADMINS({
              tab: adminTabs.TAB_USERS,
            })
          );
        }

        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
    if (isFunction(cb)) cb(error);
  }
  dispatch({ type: Types.UPDATE_USER_ERROR, payload: errorStr });
};

export const deleteUser = (id, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.DELETE_USER_REQUEST });
    let response = await Api.doCall(Const.GET_USER(id), "DELETE");
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({ type: Types.DELETE_USER_SUCCESS });
        // this will be triggered from the server
        // dispatch(userInfo());
        if (isFunction(cb)) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.DELETE_USER_ERROR, payload: errorStr });
};
export const changePasswordUser = (data, cb) => async (dispatch) => {
  try {
    let response = await Api.doCall(
      Const.USER_CHANGE_PASSWORD(data.id),
      "POST",
      data,
      null,
      true
    );
    if (response.status === 200) {
      dispatch({ type: Types.UPDATE_USER_SUCCESS, payload: response.data });
      if (isFunction(cb))
        cb({
          status: true,
          message: "",
        });
    }
  } catch (error) {
    if (isFunction(cb))
      cb({
        status: false,
        error,
      });
  }
};
export const getSecurityPolicyUser = (id) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({
      type: Types.FETCH_SECURITY_POLICY_USER_REQUEST,
      payload: { id },
    });
    let response = await Api.doCall(Const.GET_SECURITY_POLICY_USER(id), "GET");
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.FETCH_SECURITY_POLICY_USER_SUCCESS,
          payload: { id, data: response.data },
        });
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.FETCH_SECURITY_POLICY_USER_ERROR,
    payload: { id, error: errorStr },
  });
};

export const updateSecurityPolicyUser = (id, data, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({
      type: Types.UPDATE_SECURITY_POLICY_USER_REQUEST,
    });
    let response = await Api.doCall(
      Const.UPDATE_SECURITY_POLICY_USER(id),
      "POST",
      data
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.UPDATE_SECURITY_POLICY_USER_SUCCESS,
          payload: { id, data: response.data },
        });
        if (isFunction(cb)) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.UPDATE_SECURITY_POLICY_USER_ERROR,
    payload: { error: errorStr },
  });
};

export const updateSecurityPolicyUsers = (data, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({
      type: Types.UPDATE_SECURITY_POLICY_USERS_REQUEST,
    });
    let response = await Api.doCall(
      Const.UPDATE_SECURITY_POLICY_USERS(),
      "POST",
      data
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.UPDATE_SECURITY_POLICY_USERS_SUCCESS,
        });
        if (isFunction(cb)) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.UPDATE_SECURITY_POLICY_USERS_ERROR,
    payload: { error: errorStr },
  });
};

export const getAdminRightsUser = (id) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({
      type: Types.FETCH_ADMIN_RIGHTS_USER_REQUEST,
      payload: { id },
    });
    let response = await Api.doCall(Const.GET_ADMIN_RIGHTS_USER(id), "GET");
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.FETCH_ADMIN_RIGHTS_USER_SUCCESS,
          payload: { id, data: response.data },
        });
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.FETCH_ADMIN_RIGHTS_USER_ERROR,
    payload: { id, error: errorStr },
  });
};

export const updateAdminRightsUser = (id, data, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({
      type: Types.UPDATE_ADMIN_RIGHTS_USER_REQUEST,
    });
    let response = await Api.doCall(
      Const.UPDATE_ADMIN_RIGHTS_USER(id),
      "POST",
      data
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.UPDATE_ADMIN_RIGHTS_USER_SUCCESS,
          payload: { id, data: response.data },
        });
        if (isFunction(cb)) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.UPDATE_ADMIN_RIGHTS_USER_ERROR,
    payload: { error: errorStr },
  });
};

// export const updateAdminRightsUsers = (data, cb) => async dispatch => {
//   let errorStr = "";
//   try {
//     dispatch({
//       type: Types.UPDATE_ADMIN_RIGHTS_USERS_REQUEST
//     });
//     let response = await Api.doCall(
//       Const.UPDATE_ADMIN_RIGHTS_USERS(),
//       "POST",
//       data
//     );
//     if (response.status === 200 || response.status === 201) {
//       if (!response.data.error) {
//         dispatch({
//           type: Types.UPDATE_ADMIN_RIGHTS_USERS_SUCCESS
//         });
//         if (isFunction(cb)) cb();
//         return;
//       }
//       errorStr = response.data.error;
//     }
//   } catch (error) {
//     errorStr = error.toString();
//   }
//   dispatch({
//     type: Types.UPDATE_ADMIN_RIGHTS_USERS_ERROR,
//     payload: { error: errorStr }
//   });
// };

export const updatePrivilegesUsers = (data, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({
      type: Types.UPDATE_PRIVILEGES_USERS_REQUEST,
    });
    let response = await Api.doCall(
      Const.UPDATE_PRIVILEGES_USERS(),
      "POST",
      data
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.UPDATE_PRIVILEGES_USERS_SUCCESS,
        });
        if (isFunction(cb)) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.UPDATE_PRIVILEGES_USERS_ERROR,
    payload: { error: errorStr },
  });
};

export const invitationUser = (id, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({
      type: Types.INVITATION_USER_REQUEST,
    });
    let response = await Api.doCall(Const.INVITATION_USER(id), "POST", { id });
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.INVITATION_USER_SUCCESS,
        });
        if (isFunction(cb)) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.INVITATION_USER_ERROR,
    payload: { error: errorStr },
  });
};
export const bulkUpload = (data, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({
      type: Types.BULK_UPLOAD_USERS_REQUEST,
    });
    let response = await Api.doCall(Const.BULK_UPLOAD_USERS(), "POST", data);
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.BULK_UPLOAD_USERS_SUCCESS,
        });
        if (isFunction(cb)) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.BULK_UPLOAD_USERS_ERROR,
    payload: { error: errorStr },
  });
};

export const updateAssignmentUser = (data, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({
      type: Types.UPDATE_ASSIGNMENT_USER_REQUEST,
    });
    let response = await Api.doCall(
      Const.UPDATE_ASSIGNMENT_USER(data.id),
      "POST",
      data
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.UPDATE_ASSIGNMENT_USER_SUCCESS,
        });
        if (isFunction(cb)) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.UPDATE_ASSIGNMENT_USER_ERROR,
    payload: { error: errorStr },
  });
};

export const setIsNewUser = (payload) => async (dispatch) => {
  dispatch({
    type: Types.SET_IS_NEW_USER,
    payload,
  });
};

export const setNewUser = (payload) => async (dispatch) => {
  dispatch({
    type: Types.SET_NEW_USER_REQUEST,
  });
  try {
    dispatch({
      type: Types.SET_NEW_USER_SUCCESS,
      payload,
    });
  } catch (error) {
    dispatch({
      type: Types.SET_NEW_USER_ERROR,
      payload: { id: payload.id, error },
    });
  }
};
export const invitationUsers = (params, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({
      type: Types.INVITATION_USERS_REQUEST,
    });
    let response = await Api.doCall(Const.INVITATION_USERS(), "POST", params);
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.INVITATION_USERS_SUCCESS,
          payload: response.data,
        });
        if (isFunction(cb)) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.INVITATION_USERS_ERROR,
    payload: { error: errorStr },
  });
};
export const activeUsers = (params, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({
      type: Types.ACTIVE_USERS_REQUEST,
    });
    let response = await Api.doCall(Const.ACTIVE_USERS(), "POST", params);
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.ACTIVE_USERS_SUCCESS,
          payload: response.data,
        });
        if (isFunction(cb)) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.ACTIVE_USERS_ERROR,
    payload: { error: errorStr },
  });
};

export const invitationAdmins = (params, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({
      type: Types.INVITATION_ADMINS_REQUEST,
    });
    let response = await Api.doCall(Const.INVITATION_USERS(), "POST", params);
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.INVITATION_ADMINS_SUCCESS,
          payload: response.data,
        });
        if (isFunction(cb)) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.INVITATION_ADMINS_ERROR,
    payload: { error: errorStr },
  });
};
export const activeAdmins = (params, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({
      type: Types.ACTIVE_ADMINS_REQUEST,
    });
    let response = await Api.doCall(Const.ACTIVE_USERS(), "POST", params);
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.ACTIVE_ADMINS_SUCCESS,
          payload: response.data,
        });
        if (isFunction(cb)) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.ACTIVE_ADMINS_ERROR,
    payload: { error: errorStr },
  });
};

export const unlockUser = ({ id, password }, cb) => async (dispatch) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.UNLOCK_USER_REQUEST });

    let response = await Api.doCall(Const.UNLOCK_USER(id), "POST", {
      password: password,
    });

    if (response.status === 200) {
      dispatch({ type: Types.UNLOCK_USER_SUCCESS });
      if (isFunction(cb)) {
        cb({
          status: true,
          message: "",
        });
      }
      return true;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({ type: Types.UNLOCK_USER_ERROR, payload: errorStr });
};

export const updateUserOOOSettings = (id, data, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.UPDATE_USER_OOO_REQUEST, payload: { id } });
    let response = await Api.doCall(
      `${Const.GET_USER(id)}/update_ooo_settings`,
      "POST",
      data
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.UPDATE_USER_OOO_SUCCESS,
          payload: { id, data: response.data },
        });
        if (cb) cb(response);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.UPDATE_USER_OOO_ERROR,
    payload: { id, error: errorStr },
  });
};
