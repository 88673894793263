import React, { useState } from "react";

import bn from "utils/bemnames";
import { Range, getTrackBackground } from "react-range";

const bem = bn.create("setting-font-size");

const SettingFontSize = (p) => {
  const [fontSize, setFontSize] = useState([p.value]);

  const STEP = 1;
  const MIN = 0;
  const MAX = 24;

  return (
    <div className={bem.b()}>
      <button
        type="button"
        className="btControl left"
        onClick={() => {
          const calFontSize = fontSize[0] - 1 <= MIN ? MIN : fontSize[0] - 1;
          setFontSize([calFontSize]);
          p.onChange(calFontSize);
        }}
      >
        -
      </button>
      <div className="range">
        <Range
          values={fontSize}
          step={STEP}
          min={MIN}
          max={MAX}
          onChange={(values) => {
            setFontSize(values);
            p.onChange(values[0]);
          }}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: "36px",
                display: "flex",
                width: "100%",
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: "8px",
                  width: "100%",
                  borderRadius: "4px",
                  background: getTrackBackground({
                    values: fontSize,
                    colors: ["#00A6E0", "#ccc"],
                    min: MIN,
                    max: MAX,
                  }),
                  alignSelf: "center",
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: "25px",
                width: "25px",
                borderRadius: "50%",
                backgroundColor: "#FFF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "4px solid #00A6E0",
                outline: "none",
              }}
            >
              <div
                style={{
                  borderRadius: "50%",
                  height: "15px",
                  width: "15px",
                  backgroundColor: "#FFF",
                  outline: "none",
                }}
              />
            </div>
          )}
        />
      </div>

      <button
        className="btControl right"
        type="button"
        onClick={() => {
          const calFontSize = fontSize[0] + 1 >= MAX ? MAX : fontSize[0] + 1;
          setFontSize([calFontSize]);
          p.onChange(calFontSize);
        }}
      >
        +
      </button>
    </div>
  );
};

SettingFontSize.defaultProps = {
  onChange: () => {},
};

export default SettingFontSize;
