import * as React from "react";
import bn from "utils/bemnames";
import Spinner from "components/Spinner";
import { get } from "lodash";
import * as Yup from "yup";
import { Formik } from "formik";
import ToggleSwitch from "components/ToggleSwitch";
import ToastManager from "components/ToastManager";
import { Form, Button } from "reactstrap";
import { FormattedMessage } from "react-intl";
import CopyStations from "components/StationSettings/CopyStations";
import classNames from "classnames";
import {
  EyeIcon,
  DownIcon,
  UpIcon,
  ApproveIcon,
  TrashIcon,
} from "components/CustomIcons";
import EmailAutomationNoVerification from "./EmailAutomationNoVerification";
import EmailAutomationVerification from "./EmailAutomationVerification";
import ScheduleNoVerification from "./ScheduleNoVerification";
import ScheduleVerification from "./ScheduleVerification";
import { forOwn } from "lodash";
import ConfirmationModal from "components/ConfirmationModal";
const bem = bn.create("station-settings-form");
function getInitialSettings(data) {
  switch (data.type) {
    case "full_email_automation_no_verification":
      return {
        fulfillment_form_template: get(
          data,
          "settings.fulfillment_form_template.id",
          ""
        ),
        prize_fulfillment_template: get(
          data,
          "settings.prize_fulfillment_template.id",
          ""
        ),
        prize_release_template: get(
          data,
          "settings.prize_release_template.id",
          ""
        ),
        winner_announcement: get(data, "settings.winner_announcement.id", ""),
      };
    case "full_email_automation_with_verification":
      return {
        form_confirmation: get(data, "settings.form_confirmation.id", ""),
        fulfillment_form_template: get(
          data,
          "settings.fulfillment_form_template.id",
          ""
        ),
        prize_fulfillment_template: get(
          data,
          "settings.prize_fulfillment_template.id",
          ""
        ),
        prize_release_template: get(
          data,
          "settings.prize_release_template.id",
          ""
        ),
        verification_user: get(data, "settings.verification_user")
          ? get(data, "settings.verification_user", []).map((user) => user.id)
          : [],
        winner_announcement: get(data, "settings.winner_announcement.id", ""),
      };
    case "release_form_and_prize_fulfillment_email_no_verification":
      return {
        form_confirmation: get(data, "settings.form_confirmation.id", ""),
        fulfillment_form_template: get(
          data,
          "settings.fulfillment_form_template.id",
          ""
        ),
        winner_announcement: get(data, "settings.winner_announcement.id", ""),
      };
    case "release_form_and_prize_fulfillment_email_with_verification":
      return {
        form_confirmation: get(data, "settings.form_confirmation.id", ""),
        fulfillment_form_template: get(
          data,
          "settings.fulfillment_form_template.id",
          ""
        ),
        verification_user: get(data, "settings.verification_user")
          ? get(data, "settings.verification_user", []).map((user) => user.id)
          : [],
        winner_announcement: get(data, "settings.winner_announcement.id", ""),
      };
    default:
      return null;
  }
}
const FulfillmentAutomationForm = (props) => {
  const {
    loading,
    onFormSubmit,
    intl,
    currentServer,
    isOpen,
    toggleIsOpen,
    getUsers,
    fulfillmentSetting,
    onSetActive,
    winnerAnnouncements,
    fulfillmentForms,
    prizeReleases,
    prizeFulfillments,
    formConfirmations,
  } = props;
  const formikRef = React.useRef(null);
  const initialValues = {
    id: fulfillmentSetting.id,
    station_id: props.currentStation.key,
    type: fulfillmentSetting.type || "",
    settings_copy_to_stations: [],
    active: Boolean(fulfillmentSetting.active) || false,
    settings: getInitialSettings(fulfillmentSetting),
  };
  const userOptions = getUsers();

  const winnerAnnouncementOptions = get(winnerAnnouncements, "data", []).map(
    (item) => ({
      label: item.name,
      value: item.id,
    })
  );
  const fulfillmentFormOptions = get(fulfillmentForms, "items", []).map(
    (item) => ({
      label: item._source.name,
      value: item._id,
    })
  );
  const prizeReleaseOptions = get(prizeReleases, "data", []).map((item) => ({
    label: item.name,
    value: item.id,
  }));
  const prizeFulfillmentOptions = get(prizeFulfillments, "data", []).map(
    (item) => ({
      label: item.name,
      value: item.id,
    })
  );
  const formConfirmationOptions = get(formConfirmations, "data", []).map(
    (item) => ({
      label: item.name,
      value: item.id,
    })
  );
  const form = (
    <div className={bem.b()}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        ref={formikRef}
        validationSchema={Yup.object().shape({
          station_id: Yup.string().nullable(),
          type: Yup.string().required(),
          active: Yup.bool().nullable(),
          settings_copy_to_stations: Yup.mixed().nullable(),
          settings: Yup.object().when("type", (type, schema) => {
            switch (type) {
              case "full_email_automation_no_verification":
                return schema.shape({
                  fulfillment_form_template: Yup.string()
                    .required(
                      intl.formatMessage({
                        id: "validate > field is required",
                      })
                    )
                    .nullable(),
                  prize_fulfillment_template: Yup.string()
                    .required(
                      intl.formatMessage({
                        id: "validate > field is required",
                      })
                    )
                    .nullable(),
                  prize_release_template: Yup.string()
                    .required(
                      intl.formatMessage({
                        id: "validate > field is required",
                      })
                    )
                    .nullable(),
                  winner_announcement: Yup.string()
                    .required(
                      intl.formatMessage({
                        id: "validate > field is required",
                      })
                    )
                    .nullable(),
                });
              case "full_email_automation_with_verification":
                return schema.shape({
                  form_confirmation: Yup.string()
                    .required(
                      intl.formatMessage({
                        id: "validate > field is required",
                      })
                    )
                    .nullable(),
                  fulfillment_form_template: Yup.string()
                    .required(
                      intl.formatMessage({
                        id: "validate > field is required",
                      })
                    )
                    .nullable(),
                  prize_fulfillment_template: Yup.string()
                    .required(
                      intl.formatMessage({
                        id: "validate > field is required",
                      })
                    )
                    .nullable(),
                  prize_release_template: Yup.string()
                    .required(
                      intl.formatMessage({
                        id: "validate > field is required",
                      })
                    )
                    .nullable(),
                  verification_user: Yup.array()
                    .required(
                      intl.formatMessage({
                        id: "validate > field is required",
                      })
                    )
                    .nullable(),
                  winner_announcement: Yup.string()
                    .required(
                      intl.formatMessage({
                        id: "validate > field is required",
                      })
                    )
                    .nullable(),
                });
              case "release_form_and_prize_fulfillment_email_no_verification":
                return schema.shape({
                  form_confirmation: Yup.string()
                    .required(
                      intl.formatMessage({
                        id: "validate > field is required",
                      })
                    )
                    .nullable(),
                  fulfillment_form_template: Yup.string()
                    .required(
                      intl.formatMessage({
                        id: "validate > field is required",
                      })
                    )
                    .nullable(),
                  winner_announcement: Yup.string()
                    .required(
                      intl.formatMessage({
                        id: "validate > field is required",
                      })
                    )
                    .nullable(),
                });
              case "release_form_and_prize_fulfillment_email_with_verification":
                return schema.shape({
                  form_confirmation: Yup.string()
                    .required(
                      intl.formatMessage({
                        id: "validate > field is required",
                      })
                    )
                    .nullable(),
                  fulfillment_form_template: Yup.string()
                    .required(
                      intl.formatMessage({
                        id: "validate > field is required",
                      })
                    )
                    .nullable(),
                  verification_user: Yup.string()
                    .required(
                      intl.formatMessage({
                        id: "validate > field is required",
                      })
                    )
                    .nullable(),
                  winner_announcement: Yup.string()
                    .required(
                      intl.formatMessage({
                        id: "validate > field is required",
                      })
                    )
                    .nullable(),
                });

              default:
                return schema.nullable();
            }
          }),
        })}
        onSubmit={onFormSubmit}
      >
        {(formProps) => {
          const { values } = formProps;
          let isCompleted = true;
          forOwn(values.settings, (value) => {
            // break if current form don't complete
            if (isCompleted === false) return;

            if (Array.isArray(value)) {
              isCompleted = value.length > 0;
            } else {
              isCompleted = !!value;
            }
          });
          return (
            <React.Fragment>
              <Form
                onSubmit={formProps.handleSubmit}
                onChange={() => {
                  props.setShouldBlockNavigation(true);
                }}
                className={classNames(bem.e("station-fulfillment-form"), {
                  [bem.e("form-opened")]: isOpen,
                  [bem.e("form-inactive")]: !values.active,
                })}
              >
                <div className={bem.e("station-wrapper")}>
                  <div
                    className={classNames(
                      bem.e("form-head"),
                      bem.e("form-padding")
                    )}
                  >
                    <div className={bem.e("form-title")}>
                      <FormattedMessage
                        id={`station settings > ${props.type}`}
                      />
                    </div>
                    <div className={bem.e("form-actions")}>
                      <div
                        className={classNames(bem.e("btn-preview"), {
                          [bem.e("btn-disabled")]: !isCompleted,
                        })}
                        onClick={() => {
                          if (!isCompleted) return;
                          if (props.shouldBlockNavigation) {
                            props.setIsOpenConfirmationModal(true);
                          } else {
                            props.onClickPreview(fulfillmentSetting);
                          }
                        }}
                      >
                        <EyeIcon color="#795AFA" width={24} height={16} />
                      </div>
                      <button
                        className={bem.e("button-down")}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          if (props.shouldBlockNavigation) {
                            props.setIsOpenConfirmationModal(true);
                          } else {
                            toggleIsOpen();
                          }
                        }}
                      >
                        {isOpen ? (
                          <UpIcon
                            className={bem.e("button-up-icon")}
                            color="#795AFA"
                            width={24}
                            height={15.46}
                          />
                        ) : (
                          <DownIcon
                            className={bem.e("button-down-icon")}
                            color="#795AFA"
                            width={24}
                            height={15.46}
                          />
                        )}
                      </button>
                    </div>
                    <ToggleSwitch
                      name="active"
                      switchProps={{
                        checked: get(values, "active", false),
                        onChange: (checked) => {
                          if (props.shouldBlockNavigation) {
                            props.setIsOpenConfirmationModal(true);
                          } else {
                            onSetActive(checked);
                          }
                        },
                        offColor: "#C2D4E0",
                        onColor: "#795AFA",
                        uncheckedIcon: false,
                        checkedIcon: false,
                        handleDiameter: 22,
                        width: 50,
                        height: 26,
                        disabled: !isCompleted,
                      }}
                    />
                  </div>

                  {isOpen ? (
                    <>
                      {props.type ===
                        "release_form_and_prize_fulfillment_email_with_verification" && (
                        <div
                          className={bem.e("step-help-text")}
                          dangerouslySetInnerHTML={{
                            __html: intl.formatMessage({
                              id:
                                "station settings > if a Winner is already verified, system will use the verification not required automation",
                            }),
                          }}
                        ></div>
                      )}
                      {props.type ===
                        "full_email_automation_with_verification" && (
                        <div
                          className={bem.e("step-help-text")}
                          dangerouslySetInnerHTML={{
                            __html: intl.formatMessage({
                              id:
                                "station settings > if a Winner is already verified, system will use the verification not required automation above",
                            }),
                          }}
                        ></div>
                      )}
                      {(props.type ===
                        "release_form_and_prize_fulfillment_email_with_verification" ||
                        props.type ===
                          "full_email_automation_with_verification") && (
                        <div className={bem.e("step-help-text")}>
                          <strong>If</strong> a Winner{" "}
                          <strong>requires verification</strong>, system will
                          use the following automation.
                        </div>
                      )}
                      <div className={bem.e("steps-padding")}>
                        <div className={bem.e("steps")}>
                          {props.type ===
                            "full_email_automation_no_verification" && (
                            <EmailAutomationNoVerification
                              bem={bem}
                              intl={intl}
                              formProps={formProps}
                              setShouldBlockNavigation={
                                props.setShouldBlockNavigation
                              }
                              winnerAnnouncementOptions={
                                winnerAnnouncementOptions
                              }
                              fulfillmentFormOptions={fulfillmentFormOptions}
                              prizeReleaseOptions={prizeReleaseOptions}
                              prizeFulfillmentOptions={prizeFulfillmentOptions}
                              reminderDays={props.reminderDays}
                            />
                          )}
                          {props.type ===
                            "full_email_automation_with_verification" && (
                            <EmailAutomationVerification
                              bem={bem}
                              intl={intl}
                              userOptions={userOptions}
                              formProps={formProps}
                              setShouldBlockNavigation={
                                props.setShouldBlockNavigation
                              }
                              reminderDays={props.reminderDays}
                              winnerAnnouncementOptions={
                                winnerAnnouncementOptions
                              }
                              prizeReleaseOptions={prizeReleaseOptions}
                              fulfillmentFormOptions={fulfillmentFormOptions}
                              formConfirmationOptions={formConfirmationOptions}
                              prizeFulfillmentOptions={prizeFulfillmentOptions}
                            />
                          )}
                          {props.type ===
                            "release_form_and_prize_fulfillment_email_no_verification" && (
                            <ScheduleNoVerification
                              bem={bem}
                              intl={intl}
                              formProps={formProps}
                              winnerAnnouncements={winnerAnnouncements}
                              setShouldBlockNavigation={
                                props.setShouldBlockNavigation
                              }
                              reminderDays={props.reminderDays}
                              fulfillmentFormOptions={fulfillmentFormOptions}
                              formConfirmationOptions={formConfirmationOptions}
                              winnerAnnouncementOptions={
                                winnerAnnouncementOptions
                              }
                            />
                          )}
                          {props.type ===
                            "release_form_and_prize_fulfillment_email_with_verification" && (
                            <ScheduleVerification
                              bem={bem}
                              intl={intl}
                              userOptions={userOptions}
                              formProps={formProps}
                              setShouldBlockNavigation={
                                props.setShouldBlockNavigation
                              }
                              reminderDays={props.reminderDays}
                              fulfillmentFormOptions={fulfillmentFormOptions}
                              formConfirmationOptions={formConfirmationOptions}
                              winnerAnnouncementOptions={
                                winnerAnnouncementOptions
                              }
                            />
                          )}
                          <div
                            className={classNames(bem.e("step-status"), {
                              [bem.e("step-completed")]: isCompleted,
                            })}
                          >
                            <FormattedMessage
                              id={
                                isCompleted
                                  ? "station settings > automation complete"
                                  : "station settings > automation incomplete"
                              }
                            />
                            <span className={bem.e("icon-status")}>
                              {isCompleted ? (
                                <ApproveIcon color="#73A703" />
                              ) : (
                                <TrashIcon color="#E40061" />
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <CopyStations
                        formProps={formProps}
                        intl={intl}
                        stations={props.stations}
                        currentServer={currentServer}
                        setShouldBlockNavigation={
                          props.setShouldBlockNavigation
                        }
                        currentStation={props.currentStation}
                        label={
                          <FormattedMessage id="station settings > stations to copy this workflow to" />
                        }
                      />
                      <div className={bem.e("form-buttons")}>
                        <Button
                          color="blue"
                          type="submit"
                          className="btn btn-radius"
                          disabled={loading}
                          id="btn-submit-fulfillment-automation"
                          onClick={() => {
                            setTimeout(() => {
                              const formErrors = get(
                                formikRef,
                                "current.state.errors"
                              );
                              if (
                                formErrors &&
                                Object.keys(formErrors).length
                              ) {
                                ToastManager.show({
                                  title: intl.formatMessage({
                                    id: "toast > title not saved",
                                  }),
                                  message: intl.formatMessage({
                                    id:
                                      "toast > message error please correct the hilighted fields",
                                  }),
                                  level: "error",
                                });
                              }
                            }, 40);
                          }}
                        >
                          <FormattedMessage
                            id={`station settings > button save`}
                          />
                        </Button>
                        <Button
                          color="blue"
                          outline
                          className="btn btn-radius"
                          disabled={loading}
                          onClick={() => {
                            formProps.resetForm(initialValues);
                            props.setShouldBlockNavigation(false);
                            toggleIsOpen();
                          }}
                        >
                          <FormattedMessage
                            id={`station settings > button cancel`}
                          />
                        </Button>
                      </div>
                    </>
                  ) : null}
                </div>
              </Form>
              <Spinner isLoading={loading} />
              <ConfirmationModal
                isOpen={props.copyConfirmationModal}
                title={props.intl.formatMessage({
                  id:
                    "station settings > the stations listed below do not have all selected templates associated with them, copying to these stations will create these templates as part of their workflow",
                })}
                className={bem.e("copy-stations-modal")}
                isCloseOutside={false}
                onConfirm={() => props.onConfirmCopy(values, formProps)}
                onToggle={() =>
                  props.setCopyConfirmationModal(!props.copyConfirmationModal)
                }
                onCancel={() =>
                  props.setCopyConfirmationModal(!props.copyConfirmationModal)
                }
                confirmTitle={intl.formatMessage({
                  id: "station settings > copy",
                })}
                cancelTitle={intl.formatMessage({
                  id: "station settings > do not copy",
                })}
              />
            </React.Fragment>
          );
        }}
      </Formik>
    </div>
  );
  return form;
};
export default FulfillmentAutomationForm;
