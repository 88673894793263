import { connect } from "react-redux";
import { compose, withHandlers, lifecycle } from "recompose";
import { get } from "lodash";
import { widgetFetchFeed } from "store/actions/jocks";
import View from "./view";

const Enhanced = compose(
  connect(
    (state, props) => {
      const { widget } = props;
      const widgetsDisplay = get(state, "jocks.widgetsDisplay");
      const widgetData = get(widgetsDisplay, `data.${widget.id}`, {});
      return {
        widgetData,
        loading: widgetsDisplay.loading,
        ...props,
      };
    },
    {
      widgetFetchFeed,
    }
  ),
  withHandlers({
    onFetchFeed: ({ widgetFetchFeed, widget }) => (page = 1) => {
      widgetFetchFeed(
        {
          page,
          per_page: 8,
          feed_url: widget.fields.url,
          id: widget.id,
        },
        () => {}
      );
    },
  }),
  withHandlers({
    onLoadMore: ({ onFetchFeed, widgetData }) => () => {
      const nextPage = get(widgetData, "current_page", 1) + 1;
      onFetchFeed(nextPage);
    },
  }),

  lifecycle({
    componentDidMount() {
      const { onFetchFeed } = this.props;
      onFetchFeed();
    },
    componentDidUpdate(prevProps) {
      if (
        get(this, "props.widget.id", "") !== get(prevProps, "widget.id") &&
        get(this, "props.widget.id", "")
      ) {
        this.props.onFetchFeed();
      }
    },
  })
)(View);

export default Enhanced;
