import * as Types from "../types/imports";
import Api from "../../utils/api";
import * as Const from "../../utils/constants";

export const importRecord = data => async dispatch => {
  let errorStr = "";
  try {
    dispatch({ type: Types.IMPORT_ACTION_REQUEST });
    let response = await Api.doCall(Const.IMPORT_RECORD(), "POST", data);
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        let responseData = JSON.parse(JSON.stringify(response.data))
        dispatch({ type: Types.IMPORT_ACTION_SUCCESS, payload: responseData });
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.IMPORT_ACTION_ERROR, payload: errorStr });
};

export const purgeRecords = data => async dispatch => {
  let errorStr = "";
  try {
    dispatch({ type: Types.PURGE_ACTION_REQUEST });
    let response = await Api.doCall(Const.PURGE_RECORD(), "GET");
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        let responseData = JSON.parse(JSON.stringify(response.data))
        dispatch({ type: Types.PURGE_ACTION_SUCCESS, payload: responseData });
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.PURGE_ACTION_ERROR, payload: errorStr });
};

export const deleteAllIndexes = data => async dispatch =>{
  let errorStr = "";
  try {
    dispatch({ type: Types.DELETE_INDEXES_REQUEST });
    let response = await Api.doCall(Const.DELETE_INDEXES(), "GET");
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        let responseData = JSON.parse(JSON.stringify(response.data))
        dispatch({ type: Types.DELETE_INDEXES_SUCCESS, payload: responseData });
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.DELETE_INDEXES_ERROR, payload: errorStr });
}

export const deleteIndex = data => async dispatch =>{
  let errorStr = "";
  try {
    dispatch({ type: Types.DELETE_INDEX_REQUEST });
    let response = await Api.doCall(Const.DELETE_INDEX(data), "DELETE");
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        let responseData = JSON.parse(JSON.stringify(response.data))
        dispatch({ type: Types.DELETE_INDEX_SUCCESS, payload: responseData });
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.DELETE_INDEX_ERROR, payload: errorStr });
}

