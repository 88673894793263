import React, { useState, useEffect } from 'react';
import { Label } from 'reactstrap';
import classnames from 'classnames';
const InputHour = props => {
  const { name, value, onChange, AMPM, bem, hourLabel, hourReadOnly, noPlaceHolder, useWrapper } = props;

  const [data, setData] = useState(12);

  useEffect(() => {
    if(value === ''){
      setData('');
      return;
    }

    let hour = parseInt(value);

    if (hour > 12) hour -= 12;
    else if (hour === 0) hour = 12;

    setData(hour);
  }, [value]);

  const validateTime = e => {
    const re = /^[0-9\b]+$/;

    let value = e.target.value;

    if (value === '0') {
      setData(12);
      return true;
    }

    if (value === '') {
      setData('');
      return true;
    }

    if (re.test(value)) {
      const iValue = parseInt(value);

      if (iValue > 12) value = 12;

      setData(value);
    }
  };

  const handleOnBlur = e => {
    let value = e.target.value;
    
    if (value === '0') {
      value = '12';
      setData(value);
      onChange(value);
      return true;
    }

    let iValue = value === '' ? value : parseInt(value);

    setData(iValue);

    let hour = iValue === '' ? iValue : parseInt(iValue);

    if (AMPM === 'PM' && hour < 12){
      hour += 12;
    }else if (AMPM === 'AM' && hour === 12){
      hour = 0;
    } 

    onChange(hour);
  };

  if(!useWrapper){
    return (
      !hourReadOnly ? <input
        autoComplete="off"
        className={classnames(bem.e('hour'), bem.e('input'))}
        placeholder={noPlaceHolder ? "" : "00"}
        type="text"
        name={name}
        value={data}
        maxLength="2"
        onChange={validateTime}
        onBlur={handleOnBlur}
      /> : <div className={bem.e("time-text")}>{data}</div>
    )
  }

  return (
    <div className="hour-container">
      {hourLabel && (<Label>
        <span>{hourLabel}</span>
      </Label>)}
      {!hourReadOnly ? (<input
        autoComplete="off"
        className={classnames(bem.e('hour'), bem.e('input'))}
        placeholder={noPlaceHolder ? "" : "00"}
        type="text"
        name={name}
        value={data}
        maxLength="2"
        onChange={validateTime}
        onBlur={handleOnBlur}
      />) : <div className={bem.e("time-text")}>{data}</div>}
    </div>
  );
};


export default InputHour;
