import React, { useEffect, useMemo } from "react";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
} from "reactstrap";
import Avatar from "components/Avatar";
import { get } from "lodash";
import { FormattedMessage } from "react-intl";
import BlockingComponent from "components/BlockingComponent";
import classnames from "classnames";
import bn from "utils/bemnames";
import Spinner from "components/Spinner";
import { isUserShowStyleTags } from "utils/helpers";
import ProfileView from "./components/ProfileView";
import ProfileForm from "./components/ProfileForm";
import { validatePrivileges } from "utils/helpers";
import { PRIVILEGES } from "utils/constants";

export const MODE_VIEW = "view";
export const MODE_EDIT = "edit";
export const TAB_PROFILE = "profile";

const bem = bn.create("profile");

const UserProfileForm = (props) => {
  const {
    auth,
    activeTab,
    setActiveTab,
    currentMode,
    isLoading,
    shouldBlockNavigation,
    setShouldBlockNavigation,
    setCurrentMode,
    intl,
    user,
    dateFormatByServer,
    privileges,
    currentServer,
    hasMultimarket,
  } = props;
  console.log("privileges 1", privileges);

  const isEditable = validatePrivileges({
    requires: {
      or: [PRIVILEGES.EDIT_USERS, PRIVILEGES.USER_SCHEDULING],
    },
    privileges,
    user: auth.user,
  });
  const isSpecialUser = useMemo(() => {
    return isUserShowStyleTags(user);
  }, [user]);
  useEffect(() => {
    if (isEditable && currentMode === MODE_VIEW) {
      setCurrentMode(MODE_EDIT);
    }
  }, [isEditable]);

  return (
    <React.Fragment>
      <div className={bem.b()}>
        <div className={bem.e("info-profile")}>
          <div className={bem.e("user-avatar")}>
            <div className={bem.e("avatar-wrapper")}>
              <div className={bem.e("round-avatar")}>
                <Avatar
                  className={bem.e("avatar")}
                  src={get(auth, "user.avatar_url")}
                  size={150}
                  name={get(auth, "user.name", "")}
                />
              </div>
            </div>
            <div className={bem.e("user-name")}>
              <span className={bem.e("text-head")}>{get(user, "name")}</span>
            </div>
          </div>
          <div className={bem.e("user-position")}>
            <span className={bem.e("text-head")}>
              {get(auth, "info.server_name", "")}
            </span>
          </div>
        </div>
        <div className={bem.e("tab-container")}>
          <Nav tabs className={bem.e("nav")}>
            <NavItem>
              <NavLink
                className={classnames(bem.e("tab-link"), {
                  [bem.e("active")]: activeTab === TAB_PROFILE,
                })}
                onClick={() => {
                  setActiveTab(TAB_PROFILE);
                }}
              >
                <FormattedMessage id="my profile > tab profile" />
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={TAB_PROFILE}>
              <Row>
                <Col>
                  <div className={bem.e("tab-detail")}>
                    {currentMode === MODE_EDIT ? (
                      <ProfileForm
                        user={user}
                        bem={bem}
                        intl={intl}
                        isSpecialUser={isSpecialUser}
                        setCurrentMode={setCurrentMode}
                        currentServer={currentServer}
                        dateFormatByServer={dateFormatByServer}
                        hasMultimarket={hasMultimarket}
                        setShouldBlockNavigation={setShouldBlockNavigation}
                        shouldBlockNavigation={shouldBlockNavigation}
                      />
                    ) : (
                      <ProfileView
                        user={user}
                        bem={bem}
                        intl={intl}
                        isSpecialUser={isSpecialUser}
                        setCurrentMode={setCurrentMode}
                        dateFormatByServer={dateFormatByServer}
                      />
                    )}
                  </div>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </div>
        <Spinner isLoading={isLoading} />
      </div>
      <BlockingComponent
        isBlocked={shouldBlockNavigation}
        modalProps={{
          isShowConfirm: true,
          isShowDiscard: false,
          isCancelConfirmedNavigation: true,
          onCancel: () => {
            setShouldBlockNavigation(false);
          },
        }}
      />
    </React.Fragment>
  );
};
export default UserProfileForm;
