import React, { useState } from "react";
import { Row, Col, Label } from "reactstrap";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { get } from "lodash";
import SelectAudience from "components/SelectAudience";
import { ShadowBox, SectionTitle, SectionFields } from "components/Elements";

export default function AudienceDetails(props) {
  const {
    validationState,
    process,
    bem,
    step,
    onValueTimezoneChanged,
    onValueChanged,
    intl,
    isView,
    onGetClocksData,
    template,
    setShowItemAvailability,
  } = props;
  const [fieldRefs, setFieldRefs] = useState(null);
  const getFieldRefs = () => {
    if (fieldRefs) return fieldRefs;

    const select_stations = step.fields.find(
      (f) => f.field === "order_stations"
    );
    const contract_stations = step.fields.find(
      (f) => f.field === "contract_stations"
    );
    const order_stations_array = step.fields.find(
      (f) => f.field === "order_stations_array"
    );
    const order_type = step.fields.find((f) => f.field === "order_type");
    const type_id = step.fields.find((f) => f.field === "type_id");
    const payload = {
      select_stations,
      contract_stations,
      order_stations_array,
      order_type,
      type_id,
    };

    setFieldRefs(payload);

    return payload;
  };

  const selectedStation = get(
    process,
    "data.fields.order_stations.value.0",
    ""
  );
  const selectedStationData = get(
    process,
    "data.fields.order_stations_array.value.0",
    ""
  );
  const {
    select_stations,
    contract_stations,
    order_stations_array,
    order_type,
    type_id,
  } = getFieldRefs();
  return (
    <ShadowBox className={bem.e("box-audience-details")}>
      <SectionTitle className={bem.e("section-title")}>
        <FormattedMessage id="process > title audience details" />
      </SectionTitle>
      <SectionFields
        className={classnames(bem.e("section-fields"), {
          [bem.e("section-audience")]: !isView,
          [bem.e("section-audience-view")]: isView,
        })}
      >
        <Row>
          {!isView ? (
            <Col className={bem.e("col-reset")}>
              <SelectAudience
                validationState={validationState}
                item={select_stations}
                name="select_stations"
                {...select_stations.props}
                label={select_stations.title}
                required={select_stations.mandatory}
                placeholder={intl.formatMessage({
                  id: "process > placeholder select station",
                })}
                isMulti={false}
                isGroup={true}
                value={selectedStation}
                allowSelectFullOption
                template={template}
                isFilterByClock={true}
                isFilterByServerConfig={true}
                onChange={(selectedOptions) => {
                  const newStations = [selectedOptions.value];
                  onValueChanged(select_stations, newStations);
                  onValueChanged(contract_stations, newStations);
                  onValueChanged(order_stations_array, [selectedOptions]);
                  onValueChanged(order_type, "");
                  onValueChanged(type_id, "");
                  onValueTimezoneChanged(newStations);
                  onGetClocksData(selectedOptions.key);
                  setShowItemAvailability(false);
                }}
              />
            </Col>
          ) : (
            <Col className={classnames(bem.e("col-reset"))}>
              <Label className={bem.e("order-view-label")}>
                <FormattedMessage id="process > advertiser name" />
              </Label>
              <span className={bem.e("order-view-value")}>
                {selectedStationData
                  ? selectedStationData.name
                  : intl.formatMessage({ id: "process > none" })}
              </span>
            </Col>
          )}
        </Row>
      </SectionFields>
    </ShadowBox>
  );
}
