import React, { useState } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { get, forOwn, chunk, uniq, keys, isEmpty } from "lodash";
import cx from "classnames";
import { Button } from "reactstrap";
import { FaPlus } from "react-icons/lib/fa";
import Tooltip from "rc-tooltip";

import ModuleEntitiesAssignmentModal from "components/ModuleEntitiesAssignmentModal";

function ModuleEntitiesAssignment(props) {
  const [isOpenAssignEntitiesModal, setIsOpenAssignEntitiesModal] = useState(
    false
  );
  const [assignedEntities, setAssignedEntities] = useState(null);
  const [selectedAssignedEntities, setSelectedAssignedEntities] = useState(
    null
  );

  const {
    bem,
    edit,
    formProps,
    hasEntitiesToAssign,
    serverDetails,
    featureModule,
    moduleEnabled,
    validEntities,
  } = props;
  const featureEntities = get(serverDetails, `entities.${featureModule}`, {});
  const featureEntitiesKeys = keys(featureEntities || {});
  const assignedEntitiesKeys = keys(assignedEntities || {});
  const hasEntries = featureEntitiesKeys.length || assignedEntitiesKeys.length;
  const selectedEntities = {};
  forOwn(featureEntities, (value) => {
    value.forEach((station) => {
      station.market.selected = true;
      station.market = {
        [station.market.id]: station.market,
      };
      station.format = {
        [station.format.id]: station.format,
      };
      if (selectedEntities[station.key]) {
        selectedEntities[station.key].format = Object.assign(
          {},
          selectedEntities[station.key].format,
          station.format
        );
        selectedEntities[station.key].market = Object.assign(
          {},
          selectedEntities[station.key].market,
          station.market
        );
      } else {
        selectedEntities[station.key] = {
          format: station.format,
          key: station.key,
          market: station.market,
          station_key: station.name,
        };
      }
    });
  });
  const renderAssignments = () => {
    const entities = assignedEntities || featureEntities;
    if (!isEmpty(entities)) {
      const entitiesKeys = keys(entities);
      const chunkedEntries = chunk(
        entitiesKeys,
        Math.ceil(entitiesKeys.length / 2)
      );
      return (
        <div className={bem.e("assigned-entities")}>
          {chunkedEntries.map((entriesColumn, index) => (
            <div
              key={`${featureModule}-column-${index}`}
              className={bem.e("assigned-entry-column")}
            >
              {entriesColumn.map((entityKey) => {
                const entityAssignments = entities[entityKey];
                const stationsDialInfo = entityAssignments.map(
                  ({ call_letters, name }) => {
                    const arr = [];
                    if (call_letters) arr.push(call_letters);
                    if (name) arr.push(name);
                    return arr.join(" - ");
                  }
                );
                const entityKeyId = `${featureModule}-entity-${entityKey}`;
                return (
                  <Tooltip
                    key={entityKeyId}
                    placement="top"
                    overlayClassName={bem.e("dial-info-popover")}
                    overlay={
                      <div>
                        {stationsDialInfo.map((info) => (
                          <div
                            key={`${entityKeyId}-${info}`}
                            className={bem.e("stations-dial-info")}
                          >
                            {info}
                          </div>
                        ))}
                      </div>
                    }
                  >
                    <div className={bem.e("assigned-entry")}>
                      <div className={bem.e("assigned-entry-market")}>
                        {entityKey}
                      </div>
                      <div className={bem.e("assigned-entry-stations")}>
                        ({entityAssignments.length}{" "}
                        <FormattedMessage id="server > stations" />)
                      </div>
                    </div>
                  </Tooltip>
                );
              })}
            </div>
          ))}
        </div>
      );
    }
    return (
      <div className={bem.e("empty-assignment")}>
        {hasEntitiesToAssign ? (
          <FormattedMessage id="server > no assignment" />
        ) : (
          <FormattedMessage id="server > no entities" />
        )}
      </div>
    );
  };
  const isDisabled = !hasEntitiesToAssign || !moduleEnabled;
  return (
    <div className={cx(bem.e("wrapper"))}>
      <div className={cx(bem.e("assigned-wrapper"))}>
        {renderAssignments()}
        {edit && (
          <Button
            color=""
            disabled={isDisabled}
            onClick={() => {
              setIsOpenAssignEntitiesModal(true);
            }}
            className={cx(
              { disabled: isDisabled },
              bem.e("btn-assign"),
              "btn btn-outline-blue btn-radius ml-auto"
            )}
          >
            <FaPlus color="#795AFA" size={20} />
            {edit && hasEntries ? (
              <FormattedMessage id="server > edit assignment" />
            ) : (
              <FormattedMessage id="server > assignment" />
            )}
          </Button>
        )}
      </div>
      {isOpenAssignEntitiesModal && (
        <ModuleEntitiesAssignmentModal
          selectedEntitiesAndTeams={
            selectedAssignedEntities || selectedEntities
          }
          validEntities={validEntities}
          isOpen={isOpenAssignEntitiesModal}
          onToggle={() =>
            setIsOpenAssignEntitiesModal(!isOpenAssignEntitiesModal)
          }
          onSubmit={(selectedAssign, assignedStations) => {
            let newStations = [];
            const mappedSelectedEntries = {};
            Object.values(selectedAssign).forEach((element) => {
              const stationData = assignedStations.find(
                (item) => item.key === element.key
              );
              newStations.push(stationData.id);
              const elementData = {
                ...element,
                name: stationData.name,
                digits: stationData.digits,
              };
              forOwn(element.market, (market) => {
                if (mappedSelectedEntries[market.title]) {
                  mappedSelectedEntries[market.title].push(elementData);
                } else {
                  mappedSelectedEntries[market.title] = [elementData];
                }
              });
            });
            const entities = get(formProps, "values.entities");
            entities[featureModule] = uniq(newStations);
            formProps.setFieldValue("entities", entities);
            setSelectedAssignedEntities(selectedAssign);
            setAssignedEntities(mappedSelectedEntries);
          }}
        />
      )}
    </div>
  );
}

export default injectIntl(ModuleEntitiesAssignment);
