import AdminRights from "./view";
import * as React from "react";
import { get, map, includes, filter, first } from "lodash";
import { connect } from "react-redux";
import { updateCitiesGroupsStations } from "store/actions/stations";
import { compose, withHandlers } from "recompose";
import { MdImportantDevices } from "react-icons/lib/md";
import ToastManager from "components/ToastManager";
import { injectIntl } from "react-intl";
export default injectIntl(
  compose(
    connect(
      (state, props) => {
        let groupOptions = [];
        const groups = filter(
          get(state, "auth.info.tags.tags"),
          item =>
            !includes(item.title, "Location") && !includes(item.title, "Format")
        );
        let map_tags = (tag, title) => {
          groupOptions.push({
            id: tag.id,
            label: title
          });
          tag.children &&
            tag.children.map(child => {
              map_tags(child, child.title);
            });
        };
        groups.map(tag => {
          map_tags(tag, tag.title);
        });
        const ids = get(props, "ids", []);
        if (ids.length === 1) {
          const id = first(ids);
          return {
            isMultiple: false,
            id,
            station: get(state, `stations.station.${id}`, {}),
            allGroups: groupOptions,
            auth: state.auth,
            isLoading:
              get(state, `stations.station.${id}.loading`, false) ||
              get(state, `stations.updateStation.loading`, false)
          };
        } else {
          return {
            ids,
            isMultiple: true,
            isLoading: get(state, `stations.updateStations.loading`, false),
            auth: state.auth,
            allGroups: groupOptions
          };
        }
      },
      {
        updateCitiesGroupsStations
      }
    ),
    withHandlers({
      onFormSubmit: ({
        id,
        isMultiple,
        ids,
        intl,
        updateCitiesGroupsStations
      }) => values => {
        let dataToSend = {
          group_add: map(values.group_add, group => group.id),
          group_remove: map(values.group_remove, group => group.id)
        };
        if (isMultiple) {
          updateCitiesGroupsStations(
            {
              ...dataToSend,
              ids
            },
            () => {
              ToastManager.show({
                title: <MdImportantDevices />,
                message: intl.formatMessage({
                  id: "station > update successfully"
                }),
                level: "success"
              });
            }
          );
        }
      }
    })
  )(AdminRights)
);
