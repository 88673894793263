import React from "react";
import bn from "utils/bemnames";
import { Button } from "reactstrap";
import { FaPlus } from "react-icons/lib/fa";
import cn from "classnames";
import PropTypes from "prop-types";
import PrizeList from "components/PrizeList";
import { FormattedMessage } from "react-intl";
import { MODE_IN_TAB } from "components/PrizeList";
const bem = bn.create("prizes-closet");
class PrizeCloset extends React.Component {
  render() {
    const { onToggleAddModal, mode } = this.props;
    return (
      <div className={bem.b()}>
        <div className={bem.e("top-title")}>
          <h2 className={bem.e("title")}>
            <FormattedMessage id="process > title prize closet" />
          </h2>
          {mode === "edit" ? (
            <div>
              <Button
                color="blue"
                outline
                onClick={onToggleAddModal}
                className={cn(
                  "btn btn-radius",
                  bem.e("button-add")
                )}
              >
                <span className={bem.e("button-icon")}>
                  <FaPlus />
                </span>
                <FormattedMessage id={`prizes > button add to prize closet`} />
              </Button>
            </div>
          ) : null}
        </div>
        <div>
          <div>
            <PrizeList
              onSelectItem={this.props.onSelectItem}
              {...this.props}
              mode={MODE_IN_TAB}
              reqQuantity={this.props.reqQuantity}
            />
          </div>
        </div>
      </div>
    );
  }
}
PrizeCloset.propTypes = {
  mode: PropTypes.string,
  onSelectItem: PropTypes.func,
  onToggleAddModal: PropTypes.func,
  process: PropTypes.object,
  reqQuantity: PropTypes.number
};
PrizeCloset.defaultProps = {
  mode: "view",
  onToggleAddModal: () => {},
  onSelectItem: () => {},
  reqQuantity: 0
};
export default PrizeCloset;
