import React, { Component } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import { map } from "lodash";
const SortableItem = SortableElement(({ children }) => children);
const SortableList = SortableContainer(({ items, disabled, renderItem }) => {
  return (
    <div>
      {map(items, (item, i) => (
        <SortableItem key={`form-item-${i}`} index={i} disabled={disabled}>
          {renderItem(item, i)}
        </SortableItem>
      ))}
    </div>
  );
});
class SortableComponent extends Component {
  onSortEnd = ({ oldIndex, newIndex }) => {
    let newItems = arrayMove(this.props.items, oldIndex, newIndex);
    this.props.setItems(newItems);
    this.props.onSorted(newItems);
  };
  render() {
    return (
      <SortableList
        lockToContainerEdges={this.props.lockToContainerEdges}
        disabled={this.props.disabled}
        items={this.props.items}
        renderItem={this.props.renderItem}
        onSortEnd={this.onSortEnd}
        useWindowAsScrollContainer={this.props.useWindowAsScrollContainer}
        shouldCancelStart={(event) => {
          let isCanStart = false;
          const { path, composedPath } = event;
          const paths = path || (composedPath && event.composedPath()) || [];
          paths.forEach((item) => {
            if (
              item.classList &&
              item.classList.contains("menu-selection-icon")
            ) {
              isCanStart = true;
            }
          });
          return !isCanStart;
        }}
      />
    );
  }
}
SortableComponent.defaultProps = {
  lockToContainerEdges: true,
  useWindowAsScrollContainer: false,
};
export default SortableComponent;
