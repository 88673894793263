import * as React from "react";
import PropTypes from "prop-types";
import Message from "./Message";
import { get, isEmpty } from "lodash";

class MessageList extends React.Component {
  render() {
    const {
      messages,
      process,
      isShowMessageActions,
      editingMessage,
      onEditMessage,
      setEditingMessage,
      setDeletingMessage,
      chatInputPlaceHolder,
      bem,
      recordingFeedback,
      voiceFeedback,
      user,
    } = this.props;

    if (isEmpty(messages)) {
      return null;
    }

    return (
      <div className="messages-wrapper">
        {messages.map((message, index) => {
          const fromId = get(message, "from.id");
          const nextFromId = get(messages, `${index + 1}.from.id`);
          const prevFromId = get(messages, `${index - 1}.from.id`);
          const process_key = get(message, "data.process_key");

          return (
            <Message
              {...message}
              key={message.id}
              process={process}
              isLastInGroup={fromId !== nextFromId}
              isFirstInGroup={fromId !== prevFromId}
              status={process_key === "current" ? "waiting" : "delivered"}
              isShowMessageActions={isShowMessageActions}
              isEditing={editingMessage === message.id}
              onEditMessage={onEditMessage}
              setEditingMessage={setEditingMessage}
              setDeletingMessage={setDeletingMessage}
              chatInputPlaceHolder={chatInputPlaceHolder}
              bem={bem}
              recordingFeedback={recordingFeedback}
              voiceFeedback={voiceFeedback}
              user={user}
            />
          );
        })}
        <div
          ref={(el) => {
            this.messagesEnd = el;
          }}
        />
      </div>
    );
  }
}

MessageList.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.shape(Message.propTypes)),
  lockScroll: PropTypes.bool,
  isShowMessageActions: PropTypes.bool,
};

MessageList.defaultProps = {
  lockScroll: false,
  isShowMessageActions: false,
};

export default MessageList;
