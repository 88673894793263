import React, { useRef, useState } from "react";
import bn from "utils/bemnames";
import PropTypes from "prop-types";
import { find, first, get, map } from "lodash";
import classNames from "classnames";
import ScheduleSwap from "components/ScheduleComponents/Swap";
import Tooltip from "rc-tooltip";
import useOutsideClick from "utils/useOutsideClick";
import moment from "moment";
const bem = bn.create("schedule-calendar-item");
const elementColors = {
  regular: {
    collapsed: "rgba(194, 212, 224, 0.6)",
    empty: "#E7F0F5",
    filled: "#9EBACC",
    on_hold: "#9EBACC",
    fill: "#9EBACC",
  },
  swapping: {
    collapsed: "rgba(194, 212, 224, 0.6)",
    empty: "#E7F0F5",
    "filled-same": "#829FB1",
    swapped: "#131E30",
    ineligible: "#C2D4E0",
  },
};
export default function CalendarItem(props) {
  const {
    title,
    number,
    type,
    status,
    isHighlight,
    onSelectItem,
    item,
    onSwapItem,
    onViewItem,
    onSelectItemSwap,
    selectedItemSwap,
    onSelectItemTargetSwap,
    onDeleteItem,
    date,
    isEmpty,
    isClickable,
    multipleTypes,
    intl,
  } = props;
  const [isShowSwap, setIsShowSwap] = useState(false);
  const [isShowOverride, setIsShowOverride] = useState(false);
  const [rulesOverride, setRulesOverride] = useState([]);
  const [isConfirmOverride, setIsConfirmOverride] = useState(false);

  const [isVisible, setIsVisible] = useState(false);
  const [actionBehavior, setActionBehavior] = useState("hover");
  const tooltipRef = useRef();
  useOutsideClick(tooltipRef, (event) => {
    const listElements =
      event.path && Array.isArray(event.path) ? [...event.path] : null;
    const isClickedTooltip =
      listElements &&
      find(listElements, (el) => {
        return el && el.classList && el.classList.contains("rc-tooltip");
      });
    if (isClickedTooltip) return;
    setIsVisible(false);
  });
  // useElementScroll({
  //   useWindow: false,
  //   elementId: "cr-app-main",
  //   handler: () => {
  //     // reset
  //     setIsVisible(false);
  //     setActionBehavior("hover");
  //   },
  // });
  // regular, override, on hold, fill
  let swapType = get(item, "overridden", false)
    ? "override"
    : get(item, "slot_status") === "on_hold"
    ? "on_hold"
    : "regular";
  if (status === "fill" && get(item, "slot_status") !== "on_hold") {
    swapType = "fill";
  }

  let itemMatch = first(get(item, "labels", []));
  // When empty slot with multiple types. We will find slot type match with selected item
  if (get(item, "labels.length", 0) > 0 && get(selectedItemSwap, "slot_id")) {
    itemMatch = item.labels.find(
      (i) => i.slot_type_id === get(selectedItemSwap, "labels.0.slot_type_id")
    );
  }
  let backgroundColor = isHighlight
    ? get(itemMatch, "color")
    : get(elementColors, `${type}.${status}`);
  // check if is highlight and have only labels. We will show only color to full
  if (
    isHighlight &&
    get(item, "slot_status") === "empty" &&
    multipleTypes.length > 1 &&
    !get(selectedItemSwap, "slot_id")
  ) {
    backgroundColor = `#9EBACC`;
  }
  const isShowActionSwap =
    get(selectedItemSwap, "slot_id") ||
    (get(item, "order_process_id", null) &&
      get(item, "slot_status", null) === "filled" &&
      status !== "fill");
  // check fill schedule is past
  const isPast =
    moment()
      .startOf("days")
      .diff(date, "days") > 0;
  return (
    <div ref={tooltipRef} className={bem.b()}>
      <Tooltip
        visible={isVisible}
        onVisibleChange={setIsVisible}
        placement="top"
        trigger={actionBehavior === "click" ? [] : ["hover"]}
        mouseEnterDelay={0.5}
        destroyTooltipOnHide
        afterVisibleChange={(visible) => {
          // reset action behavior
          if (!visible) {
            setActionBehavior("hover");
          }
        }}
        getTooltipContainer={() => tooltipRef.current}
        overlay={
          <ScheduleSwap
            action={actionBehavior}
            status={status}
            type={swapType}
            item={item}
            title={title}
            isShowSwap={isShowSwap}
            isShowActionSwap={!!isShowActionSwap}
            isShowActionView={!!get(item, "order_process_id", null)}
            isShowOverride={isShowOverride}
            setIsConfirmOverride={setIsConfirmOverride}
            isConfirmOverride={isConfirmOverride}
            multipleTypes={multipleTypes}
            setIsShowOverride={setIsShowOverride}
            rulesOverride={rulesOverride}
            intl={intl}
            onClose={() => setIsVisible(false)}
            onSelectSwap={(item) => {
              onSelectItemSwap({
                ...item,
                date,
              });
            }}
            onSwapClick={(item) => {
              onSwapItem({
                data: { ...item, date, is_override: isShowOverride },
                cb: (resp) => {
                  setIsVisible(false);
                  setIsShowOverride(false);
                },
              });
            }}
            onViewClick={(data) => {
              onViewItem(data);
              setIsVisible(false);
            }}
            onDeleteClick={(data) => {
              onDeleteItem({
                ...data,
                date,
              });
              setIsVisible(false);
            }}
          />
        }
        overlayClassName={classNames(
          "calendar-tooltip",
          `calendar-tooltip--${status}`,
          {
            "calendar-tooltip-none": !item,
          }
        )}
      >
        <div
          className={classNames(
            bem.e("item-container"),
            bem.e(`item-container-${status}`),
            bem.e(`item-${get(item, "slot_status")}`),
            { [bem.e("item-disabled")]: !isClickable }
          )}
          data-status={status}
          data-type={type}
          style={{
            backgroundColor,
            opacity:
              status === "collapsed" ||
              (isPast && status === "fill" && isHighlight)
                ? 0.5
                : 1,
          }}
          onClick={() => {
            if (status === "collapsed" || !isClickable) return;
            setActionBehavior("click");
            setIsVisible(true);
            setIsShowOverride(false); // reset
            setIsShowSwap(false); // reset
            if (onSelectItem && !get(selectedItemSwap, "slot_id")) {
              onSelectItem({
                title,
                ...item,
                date,
              });
            }

            if (onSelectItemTargetSwap && get(selectedItemSwap, "slot_id")) {
              setIsShowSwap(true); // show swap button
              // this action call api to check slot override
              onSelectItemTargetSwap({
                data: {
                  ...item,
                  date,
                },
                cb: ({ override, rules }) => {
                  setRulesOverride(rules);
                  setIsShowOverride(override);
                  setIsConfirmOverride(!override); // enable swap button if element not override or disable the button when element is override
                },
              });
            }
          }}
        >
          <React.Fragment>
            {number && <span className={bem.e("number")}>:{number}</span>}
            {multipleTypes.length > 1 && !get(selectedItemSwap, "slot_id") ? (
              <div className={bem.e("multiple-types")}>
                {map(multipleTypes, (i, index) => (
                  <span
                    className={bem.e("type-item")}
                    style={{
                      backgroundColor: i.color,
                    }}
                    key={index}
                  />
                ))}
              </div>
            ) : (
              <span className={bem.e("title")}>{isEmpty ? "" : title}</span>
            )}

            {(get(item, "slot_status") === "on_hold" || status === "fill") && (
              <span
                className={classNames(bem.e("flag"), {
                  [bem.e("flag-ineligible")]:
                    type === "swapping" && status === "on_hold",
                })}
              >
                {status === "fill" ? (
                  <span className={bem.e("filled-text")}>F</span>
                ) : (
                  ""
                )}
              </span>
            )}
          </React.Fragment>
        </div>
      </Tooltip>
    </div>
  );
}
CalendarItem.propTypes = {
  item: PropTypes.any,
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  type: PropTypes.oneOf(["regular", "swapping"]),
  status: PropTypes.oneOf([
    "collapsed",
    "empty",
    "filled",
    "fill",
    "on_hold",
    "filled-same",
    "swapped",
    "ineligible",
  ]).isRequired,
  isHighlight: PropTypes.bool,
  onSwapItem: PropTypes.func,
  isScheduleTab: PropTypes.bool,
  onSelectItemSwap: PropTypes.func,
  onSelectItemTargetSwap: PropTypes.func,
  onDeleteItem: PropTypes.func,
  isEmpty: PropTypes.bool,
  isClickable: PropTypes.bool,
  multipleTypes: PropTypes.array,
};
CalendarItem.defaultProps = {
  title: "",
  type: "regular",
  status: "collapsed",
  number: "",
  isHighlight: false,
  onSwapItem: () => {},
  isScheduleTab: false,
  onSelectItemSwap: () => {},
  onSelectItemTargetSwap: () => {},
  onDeleteItem: () => {},
  isEmpty: false,
  isClickable: true,
  multipleTypes: [],
};
