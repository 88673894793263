import React from "react";
import { map } from 'lodash'
import { sortable } from "react-sortable";
const Item = props => <div {...props}>{props.children}</div>;
const SortableItem = sortable(Item);
const SortableList = ({ list, setList, renderItem }) => {
  return map(list, (item, i) => (
    <SortableItem key={i} onSortItems={setList} items={list} sortId={i}>
      {renderItem(item, i)}
    </SortableItem>
  ));
};
export default SortableList;
