import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, Button, Form } from "reactstrap";
import bn from "utils/bemnames";
import { get } from "lodash";
import Dropdown from "components/Dropdown";
import { Formik } from "formik";
import TextInput from "components/TextInput";
import * as Yup from "yup";
import { FormattedMessage } from "react-intl";
import Spinner from "components/Spinner";

const bem = bn.create("send-back-order-status-modal");
const validStatus = ["completed", "approved"];
const View = (props) => {
  const { isOpen, onToggle, intl, process, onSubmit, isLoading, step } = props;
  const stepKey = get(step, "key", "");
  const options = useMemo(() => {
    let newOptions = [];
    const processStep = get(process, "data.fields.process_step.value", "");

    const assignTeamStatus = get(
      process,
      "data.fields.assign_team_status.value",
      ""
    );
    const writeScriptStatus = get(
      process,
      "data.fields.write_script_status.value",
      ""
    );
    const approveScriptStatus = get(
      process,
      "data.fields.approve_script_status.value",
      ""
    );
    const recordScriptStatus = get(
      process,
      "data.fields.record_script_status.value",
      ""
    );
    const approveRecordingStatus = get(
      process,
      "data.fields.approve_recording_status.value",
      ""
    );
    const assignVoStatus = get(
      process,
      "data.fields.assign_vo_status.value",
      ""
    );
    const voiceOverStatus = get(
      process,
      "data.fields.voice_over_status.value",
      ""
    );
    // assignments
    if (validStatus.includes(assignTeamStatus))
      newOptions.push({
        label: intl.formatMessage({ id: "process > title assignments" }),
        value: "assign_team",
      });
    // script
    // From the Script Review tab, remove the ability to send back to Writer (this is already covered by the Approve/Request Change buttons)
    if (
      validStatus.includes(writeScriptStatus) &&
      stepKey !== "approve_script" &&
      !["approve_script"].includes(processStep)
    )
      newOptions.push({
        label: intl.formatMessage({ id: "process > title script" }),
        value: "write_script",
      });
    // script review
    if (validStatus.includes(approveScriptStatus))
      newOptions.push({
        label: intl.formatMessage({ id: "process > title script review" }),
        value: "approve_script",
      });
    // assign vo
    if (validStatus.includes(assignVoStatus)) {
      newOptions.push({
        label: intl.formatMessage({ id: "process > title assign vo" }),
        value: "assign_vo",
      });
    }
    // voice over
    // From the RECORD tab, remove the ability to send back to Voice Over role (this is already covered by the Request Change button shown under VO's audio)
    if (validStatus.includes(voiceOverStatus) && stepKey !== "record_script")
      newOptions.push({
        label: intl.formatMessage({ id: "process > title voice over" }),
        value: "voice_over",
      });
    // recording
    // From the Spot Review tab, remove the ability to send back to Producer (this is already covered by the Approve/Request Change buttons)
    if (
      validStatus.includes(recordScriptStatus) &&
      stepKey !== "approve_recording" &&
      !["approve_recording"].includes(processStep)
    )
      newOptions.push({
        label: intl.formatMessage({ id: "process > title record" }),
        value: "record_script",
      });
    // spot review
    if (validStatus.includes(approveRecordingStatus))
      newOptions.push({
        label: intl.formatMessage({ id: "process > title spot review" }),
        value: "approve_recording",
      });

    return newOptions;
  }, [process, stepKey]);
  return (
    <>
      <Modal isOpen={isOpen} className={bem.b()} size="lg">
        <ModalBody className={bem.e("body")}>
          <div className={bem.e("title")}>
            <FormattedMessage id="process > send back order status title" />
          </div>
          <div className={bem.e("description")}>
            <FormattedMessage id="process > send back order status description" />
          </div>
          <Formik
            onSubmit={onSubmit}
            initialValues={{
              status: "",
              instructions: "",
            }}
            validationSchema={Yup.object().shape({
              status: Yup.string().required(
                props.intl.formatMessage({
                  id: "validate > status is required",
                })
              ),
              instructions: Yup.string().required(
                props.intl.formatMessage({
                  id: "validate > instructions is required",
                })
              ),
            })}
          >
            {(formProps) => {
              const { values, setFieldValue, touched, errors } = formProps;
              return (
                <Form onSubmit={formProps.handleSubmit} noValidate>
                  <Dropdown
                    label={<FormattedMessage id="process > field status" />}
                    placeholder={intl.formatMessage({
                      id: "process > select one",
                    })}
                    containerClass={bem.e("select-status")}
                    name="status"
                    onChange={(option) => {
                      setFieldValue("status", option ? option.value : "");
                    }}
                    value={
                      options.find((item) => item.value === values.status) || ""
                    }
                    options={options}
                    closeMenuOnSelect={true}
                    error={touched.status && errors.status}
                  />
                  <TextInput
                    label={<FormattedMessage id="process > instructions" />}
                    required
                    type="textarea"
                    name="instructions"
                    placeholder={intl.formatMessage({
                      id: "process > leave a note for production",
                    })}
                    aria-multiline="true"
                    value={values.instructions}
                    onChange={formProps.handleChange}
                    onBlur={formProps.handleBlur}
                    error={touched.instructions && errors.instructions}
                  />
                  <div className={bem.e("buttons")}>
                    <Button
                      color="primary"
                      className={"btn-radius"}
                      type="submit"
                    >
                      <FormattedMessage id="process > button submit" />
                    </Button>
                    <Button
                      color="primary"
                      onClick={onToggle}
                      outline
                      className={"btn-radius"}
                    >
                      <FormattedMessage id="process > button cancel" />
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </ModalBody>
        <Spinner isLoading={isLoading} />
      </Modal>
    </>
  );
};
View.propTypes = {
  onToggle: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
};
View.defaultProps = {
  isOpen: false,
  onToggle: () => {},
  onFormSubmit: () => {},
};
export default View;
