import { FormattedMessage } from "react-intl";
import React from "react";
import {
  get,
  map,
  omit,
  sum,
  includes,
  forEach,
  isEmpty,
  find,
  filter,
} from "lodash";
import categories from "utils/categories";
import { orderLengthOptions } from "utils/config";

const orderDetails = {
  process_step_index: 1,
  key: "order_details",
  caption_color: "#CC1EE6",
  title: <FormattedMessage id="process > title order" />,
  step_roles: [1, 2, 3],
  step_viewers_roles: [1, 2, 3, 4, 7],
  step_editors_roles: [1, 2, 3, 4],
  step_viewers_privileges: {
    or: ["add_production", "edit_all_production_orders"],
  },
  step_editors_privileges: {
    or: ["add_production", "edit_all_production_orders"],
  },
  description: null,
  can_edit_any_time: true,
  priviliges: [],
  status: "initial",
  disabled_until_completed_steps: [],
  step_container: "none",
  no_edit_button: true,
  className: "order-details-container",
  workflow: {
    title: "order entry",
    icon: "StartIcon",
  },
  is_approval_step: true,
  fields: [
    {
      component: "order_details",
    },
    {
      field: "client_name",
      title: <FormattedMessage id="process > field client name" />,
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        return value.length > 1
          ? true
          : "Advertiser name must be at least 2 letters long";
      },
    },
    {
      field: "client_id",
    },
    {
      field: "contact_id",
    },
    {
      field: "agency_name",
      title: <FormattedMessage id="process > field agency group name" />,
      mandatory: false,
    },
    {
      field: "agency_id",
    },
    {
      field: "order_sold",
      title: "Sold",
    },
    {
      field: "order_title",
      title: <FormattedMessage id="process > field title" />,
      /*mandatory: true,
      validation: function(step, item, process, template, user, value) {
        return value.length > 2
          ? true
          : "Order title must be at least 3 letters long";
      },*/
    },
    {
      field: "order_stations",
      title: <FormattedMessage id="process > field stations" />,
    },
    {
      field: "role_sales_person",
      title: <FormattedMessage id="process > field salesPerson" />,
      roles: [1, 2, 3],
      privileges: {
        or: ["add_production"],
      },
      default: 3,
      mandatory: true,
    },
    {
      field: "role_sales_person_2",
      title: <FormattedMessage id="process > field additional salesPerson" />,
      privileges: {
        or: ["add_production"],
      },
      roles: [1, 2, 3],
    },
    {
      field: "role_production_manager",
      title: <FormattedMessage id="process > field production manager" />,
      roles: [4],
      privileges: {
        or: ["assign_production_team"],
      },
      mandatory: true,
      validation: function(
        step,
        item,
        process,
        template,
        user,
        value,
        process_key,
        extraData
      ) {
        const isEnableEnterprise = get(
          extraData,
          "production_multimarket",
          false
        );
        const orderStations = get(process, "fields.order_stations.value", []);
        const orderStationsData = orderStations
          .map((item) => {
            const station = find(
              extraData.stations,
              (i) => i.station_key === item
            );
            return station;
          })
          .filter((station) => station);
        const isAllStationsEnableAssignmentsQueue =
          filter(orderStationsData, (station) =>
            get(station, "production_settings.assignments_queue", false)
          ).length === orderStationsData.length;
        const isEnableAssignmentsQueue = isAllStationsEnableAssignmentsQueue;
        // Show Assignments queue https://tasks.getventive.com/projects/D86EC-70B
        if (isEnableAssignmentsQueue) return true;

        const teamProductionManager = get(
          process,
          "fields.team_production_manager.value.id"
        );
        if (isEnableEnterprise && teamProductionManager) return true;

        return get(value, "id") ? true : "Please select production manager";
      },
    },
    {
      field: "team_production_manager",
      title: <FormattedMessage id="process > field production manager" />,
      mandatory: true,
      validation: function(
        step,
        item,
        process,
        template,
        user,
        value,
        process_key,
        extraData
      ) {
        const isEnableEnterprise = get(
          extraData,
          "production_multimarket",
          false
        );

        const orderStations = get(process, "fields.order_stations.value", []);
        const orderStationsData = orderStations
          .map((item) => {
            const station = find(
              extraData.stations,
              (i) => i.station_key === item
            );
            return station;
          })
          .filter((station) => station);
        const isAllStationsEnableAssignmentsQueue =
          filter(orderStationsData, (station) =>
            get(station, "production_settings.assignments_queue", false)
          ).length === orderStationsData.length;
        const isEnableAssignmentsQueue = isAllStationsEnableAssignmentsQueue;
        // Show Assignments queue https://tasks.getventive.com/projects/D86EC-70B
        if (!isEnableEnterprise || isEnableAssignmentsQueue) return true;

        const productionManager = get(
          process,
          "fields.role_production_manager.value.id"
        );
        if (productionManager) return true;

        return get(value, "id") ? true : "Please select production manager";
      },
    },
    {
      field: "role_continuity",
      title: <FormattedMessage id="process > field continuity" />,
      roles: [8],
      privileges: {
        or: ["perform_production_continuity"],
      },
      default: 8,
      validation: function(
        step,
        item,
        process,
        template,
        user,
        value,
        process_key,
        extraData
      ) {
        const orderStations = get(process, "fields.order_stations.value", []);
        const isEnableEnterprise = get(
          extraData,
          "production_multimarket",
          false
        );
        const orderStationsData = orderStations
          .map((item) => {
            const station = find(
              extraData.stations,
              (i) => i.station_key === item
            );
            return station;
          })
          .filter((station) => station);
        const isAllStationsEnableContinuityQueue =
          filter(orderStationsData, (station) =>
            get(station, "production_settings.continuity_queue", false)
          ).length === orderStationsData.length;
        const isEnableContinuityQueue = isAllStationsEnableContinuityQueue;
        if (isEnableEnterprise || isEnableContinuityQueue) return true;
        return !(
          !process.fields.order_sold || process.fields.order_sold.value
        ) ||
          (value !== "" && value.id) ||
          (value.users && value.users.length > 0)
          ? true
          : "Please select continuity";
      },
      mandatory: true,
    },
    {
      field: "role_dubber",
      title: <FormattedMessage id="process > field dubber" />,
      roles: [9],
      privileges: {
        or: ["perform_production_dubbing"],
      },
      default: 9,
      validation: function(step, item, process, template, user, value) {
        return true;
      },
      mandatory: false,
    },
    {
      field: "order_length_custom",
    },
    {
      field: "order_length",
      title: <FormattedMessage id="process > field length" />,
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        return value || get(process, "fields.order_sold.value") === false
          ? true
          : "Please enter a length";
      },
      props: {
        options: orderLengthOptions,
      },
    },
    {
      field: "contract_stations",
      title: <FormattedMessage id="process > field entities" />,
      validation: function(step, item, process, template, user, value) {
        return value.length > 0 ||
          get(process, "fields.order_sold.value") === false
          ? true
          : "At least one station has to be selected";
      },
    },
    {
      field: "contract_stations_order_number_array",
      mandatory: true,
      title: (
        <FormattedMessage id="process > field contract stations order number" />
      ),
      validation: function(
        step,
        item,
        process,
        template,
        user,
        value,
        process_key,
        extraData
      ) {
        const isEnableEnterprise = get(
          extraData,
          "production_multimarket",
          false
        );

        const isSpecOrder = get(process, "fields.order_sold.value") === false;

        if (
          !get(user, "enable_wo", false) ||
          isEnableEnterprise ||
          isSpecOrder
        ) {
          return true;
        }

        let valid = true;

        let stationsWithNumbers = filter(value, (item) => item.number);

        forEach(
          get(process, "fields.contract_stations.value", []),
          (station) => {
            if (
              !includes(
                stationsWithNumbers.map((item) => item.station),
                station
              )
            ) {
              valid = false;
            }
          }
        );

        return !valid ? "Please enter entity/order number" : true;
      },
    },
    {
      field: "contract_status",
      title: <FormattedMessage id="process > field production type" />,
      props: {
        options: [
          {
            label: (
              <FormattedMessage id="process > option label production needed" />
            ),
            value: "Production Needed",
          },
          {
            label: (
              <FormattedMessage id="process > option label production provided" />
            ),
            value: "Production Provided",
          },
        ],
      },
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        return get(value, "length") > 0 ||
          (get(process, "fields.order_sold") &&
            !get(process, "fields.order_sold.value"))
          ? true
          : "Please select an order status";
      },
    },
    {
      field: "estimation_number",
      title: <FormattedMessage id="process > field estimation number" />,
    },
    {
      field: "contract_start_date",
      title: <FormattedMessage id="process > field contract start date" />,
    },
    {
      field: "contract_end_date",
      title: <FormattedMessage id="process > field contract end date" />,
    },
    {
      field: "contract_client_contact",
      mandatory: true,
      title: <FormattedMessage id="process > field client contact" />,
      validation: function(step, item, process, template, user, value) {
        return value ? true : "Please enter a client contact";
      },
    },
    {
      field: "contract_client_email",
      title: <FormattedMessage id="process > field email" />,
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        return value.length > 0 &&
          value.match(new RegExp("[^@]+@[^\\.]+\\..+", "gi"))
          ? true
          : "Please enter a valid client email";
      },
    },
    {
      field: "contract_client_nickname",
      title: <FormattedMessage id="process > field nickname" />,
    },
    {
      field: "contract_client_phone",
      title: <FormattedMessage id="process > field phone" />,
      formatted: "phone",
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        if (value && value.length !== 10)
          return "Phone number must be 10 digits";
        return true;
      },
    },
    {
      field: "contract_category",
      title: <FormattedMessage id="process > field contract category" />,
      props: {
        options: categories,
      },
    },
    {
      field: "audience_group_id",
    },
    {
      field: "audience_group_name",
    },
    {
      field: "audience",
      mandatory: false,
      // validation: function(
      //   step,
      //   item,
      //   process,
      //   template,
      //   user,
      //   value,
      //   process_key,
      //   extraData
      // ) {
      //   const isEnableEnterprise = get(
      //     extraData,
      //     "production_multimarket",
      //     false
      //   );
      //   if (!isEnableEnterprise) return true;
      //   return !get(user, "production_multimarket", false) || value.length > 0
      //     ? true
      //     : "Please select the audience";
      // },
    },
    {
      field: "order_start_date",
      title: <FormattedMessage id="process > field order start date" />,
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        const isSpecOrder = get(process, "fields.order_sold.value") === false;
        if (isSpecOrder) return true;

        if (value.length === 0) return "Please enter a date";
        if (isNaN(Date.parse(value))) return "Please enter a valid date";
        if (
          process.fields.order_end_date &&
          !isNaN(Date.parse(process.fields.order_end_date.value)) &&
          Date.parse(value) > Date.parse(process.fields.order_end_date.value)
        )
          return "Please enter a date later than the end date";
        if (
          process.fields.contract_start_date &&
          !isNaN(Date.parse(process.fields.contract_start_date.value)) &&
          process.fields.contract_end_date &&
          !isNaN(Date.parse(process.fields.contract_end_date.value)) &&
          (Date.parse(value) <
            Date.parse(process.fields.contract_start_date.value) ||
            Date.parse(value) >
              Date.parse(process.fields.contract_end_date.value))
        )
          return "Please enter a date in the contract dates range";
        return true;
      },
    },
    {
      field: "order_end_date",
      title: <FormattedMessage id="process > field order end date" />,
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        const isSpecOrder = get(process, "fields.order_sold.value") === false;
        if (isSpecOrder) return true;

        if (value.length === 0) return "Please enter a date";
        if (isNaN(Date.parse(value))) return "Please enter a valid date";
        if (
          process.fields.order_start_date &&
          !isNaN(Date.parse(process.fields.order_start_date.value)) &&
          Date.parse(value) < Date.parse(process.fields.order_start_date.value)
        )
          return "Please enter a date later than the start date";
        if (
          process.fields.contract_start_date &&
          !isNaN(Date.parse(process.fields.contract_start_date.value)) &&
          process.fields.contract_end_date &&
          !isNaN(Date.parse(process.fields.contract_end_date.value)) &&
          (Date.parse(value) <
            Date.parse(process.fields.contract_start_date.value) ||
            Date.parse(value) >
              Date.parse(process.fields.contract_end_date.value))
        )
          return "Please enter a date in the contract dates range";
        return true;
      },
    },
    // {
    //   field: "due_date",
    //   title: <FormattedMessage id="process > field due date" />,
    //   mandatory: true,
    //   validation: function(step, item, process, template, user, value) {
    //     if (value.length === 0) return true;
    //     if (isNaN(Date.parse(value))) return "Please enter a valid date";
    //     if (
    //       process.fields.contract_end_date &&
    //       !isNaN(Date.parse(process.fields.contract_end_date.value)) &&
    //       Date.parse(value) > Date.parse(process.fields.contract_end_date.value)
    //     )
    //       return "Due date can't be greater than contract end date";

    //     if (
    //       process.fields.order_end_date &&
    //       !isNaN(Date.parse(process.fields.order_end_date.value)) &&
    //       Date.parse(value) > Date.parse(process.fields.order_end_date.value)
    //     )
    //       return "Due date can't be greater than spot end date";

    //     return true;
    //   },
    // },
    {
      field: "timezone",
    },
    {
      field: "spots",
    },
    {
      field: "traffic_instructions",
      title: <FormattedMessage id="process > field traffic instructions" />,
      mandatory: false,
    },
    {
      field: "draft_template",
    },
    {
      field: "template_selected_for_wo",
    },
    {
      field: "spot_info",
      validation: function(step, item, process, template, user, value) {
        const isSpecOrder = get(process, "fields.order_sold.value") === false;
        const availableLineNumbers = get(
          process,
          "fields.available_line_numbers.value",
          []
        );
        const producerAssignsVo = get(
          process,
          "fields.producer_assigns_vo.value",
          false
        );
        const showLineNumber = !isEmpty(availableLineNumbers);

        let isValid = true;
        const spotInfo = map(value, (spot, index) => {
          let error = {};
          // if (!spot["title"] && !spot["isci"]) {
          //   isValid = false;
          //   error.title = "Order title or isci is required";
          // }
          if (!spot["title"]) {
            isValid = false;
            error.title = "Order title is required";
          }
          if (!spot["spot_type"]) {
            isValid = false;
            error.spot_type = "Please select spot type";
          }
          if (!spot["order_length"] && !isSpecOrder) {
            isValid = false;
            error.order_length = "Please enter a length";
          }

          if (showLineNumber && isEmpty(spot["line_hashes"])) {
            isValid = false;
            error.line_hashes = "Please select a line #";
          }

          if (
            spot["spot_type"] === "Book-ends" &&
            (isEmpty(spot["length1"]) || spot["length1"] <= "00")
          ) {
            isValid = false;
            error.length1 = "Please set the spot length";
          }

          if (
            spot["spot_type"] === "Book-ends" &&
            (isEmpty(spot["length2"]) || spot["length2"] <= "00")
          ) {
            isValid = false;
            error.length2 = "Please set the spot length";
          }
          if (
            !get(spot, "voice_over_instructions") &&
            producerAssignsVo &&
            get(spot, "script_type", "draft") === "final"
          ) {
            isValid = false;
            error["voice_over_instructions"] =
              "Please enter voice over instructions";
          }
          return error;
        });
        if (isValid) return true;
        return spotInfo;
      },
      mandatory: true,
    },
    {
      field: "rotation_chart",
      validation: function(step, item, process, template, user, value) {
        let isValid = true;
        const rotationChart = map(value, (rotation) => {
          const rotationSpot = Object.values(omit(rotation, ["station"]));
          const total = sum(rotationSpot);
          if (total < 99 || total > 100) {
            isValid = false;
            return "Spot rotation percentages must add up to 100%";
          }
          return "";
        });
        if (isValid) return true;
        return rotationChart;
      },
      mandatory: true,
    },
    {
      field: "role_account_executive",
      title: <FormattedMessage id="process > field account executive owner" />,
      roles: [2],
      privileges: {
        or: ["add_production"],
      },
      validation: function(
        step,
        item,
        process,
        template,
        user,
        value,
        process_key,
        extraData
      ) {
        return get(value, "id")
          ? true
          : "Please select account executive/owner";
      },
      mandatory: true,
    },
    {
      field: "is_multi_market",
    },
    {
      field: "markets",
    },
    { field: "is_coop_order" },
    { field: "producer_assigns_vo" },
    { field: "comment" },
    {
      key: "save_button",
      text: <FormattedMessage id="process > button save" />,
      on_click: {
        validate: true,
        save: true,
      },
      fields: [
        {
          key: "order_details_status",
        },
        {
          key: "process_step",
          value: "first_undone",
        },
      ],
      confirmation_dialog_props: {
        title: (
          <FormattedMessage id="process > description to submit production order" />
        ),
        confirmTitle: <FormattedMessage id="process > button yes" />,
        cancelTitle: <FormattedMessage id="process > button no" />,
      },
      success_dialog_props: {
        title: <FormattedMessage id="process > title submitted" />,
        state: "success",
        centered: true,
      },
    },
  ],
};
export default orderDetails;
