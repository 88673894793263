import { arrayContainsArray } from "utils/helpers";
import { statesListCanada, statesListMexico, statesList } from "utils/config";
import { get } from "lodash";
export const mimesFileWithExt = [
  {
    ext: ".doc",
    mimes: ["application/msword"],
  },
  {
    ext: ".docx",
    mimes: ["application/vnd.openxmlformats-officedocument.wordprocessingml.document"],
  },
  {
    ext: ".pdf",
    mimes: ["application/pdf"],
  },
  {
    ext: ".xls",
    mimes: [
      "application/excel",
      "application/vnd.ms-excel",
      "application/x-excel",
      "application/x-msexcel",
    ],
  },
  {
    ext: ".xlsx",
    mimes: [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ],
  },
  {
    ext: ".ppt",
    mimes: [
      "application/mspowerpoint",
      "application/powerpoint",
      "application/vnd.ms-powerpoint",
      "application/x-mspowerpoint",
    ],
  },
  {
    ext: ".pptx",
    mimes: [
      "application/vnd.openxmlformats-officedocument.presentationml.presentation"
    ],
  },
  {
    ext: ".jpg",
    mimes: ["image/jpeg", "image/pjpeg"],
  },
  {
    ext: ".png",
    mimes: ["image/png"],
  },
  {
    ext: ".gif",
    mimes: ["image/gif"],
  },
  {
    ext: ".eps",
    mimes: ["application/postscript"],
  },
  {
    ext: ".wav",
    mimes: ["audio/wav", "audio/x-wav"],
  },
  {
    ext: ".aiff",
    mimes: ["audio/aiff", "audio/x-aiff"],
  },
  {
    ext: ".mp3",
    mimes: [
      "audio/mpeg3",
      "audio/x-mpeg-3",
      "video/mpeg",
      "video/x-mpeg",
      "audio/mp3",
      "audio/MP3",
    ],
  },
  {
    ext: ".mp4",
    mimes: ["video/mp4"],
  },
  {
    ext: ".mpg",
    mimes: ["audio/mpeg", "video/mpeg"],
  },
  {
    ext: ".wmv",
    mimes: ["video/quicktime"],
  },
  {
    ext: ".wmv",
    mimes: ["video/x-ms-wmv"],
  },
  {
    ext: ".qt",
    mimes: ["video/quicktime"],
  },
];
export const mimesImageWithExt = [
  {
    ext: ".jpg",
    mimes: ["image/jpeg", "image/pjpeg"],
  },
  {
    ext: ".png",
    mimes: ["image/png"],
  },
  {
    ext: ".gif",
    mimes: ["image/gif"],
  },
  {
    ext: ".ai",
    mimes: ["application/postscript"],
  },
  {
    ext: ".bmp",
    mimes: ["image/bmp", "image/x-windows-bmp"],
  },
  {
    ext: ".pdf",
    mimes: ["application/pdf"],
  },
];

export const mimesVideoWithExt = [
  {
    ext: ".mpg",
    mimes: ["video/mpeg"], //"audio/mpeg"
  },
  {
    ext: ".mov",
    mimes: ["video/quicktime"],
  },
  {
    ext: ".wmv",
    mimes: ["video/x-ms-wmv"],
  },
  {
    ext: ".mp4",
    mimes: ["video/mp4"],
  },
  {
    ext: ".qt",
    mimes: ["video/quicktime"],
  },
  {
    ext: ".webm",
    mimes: ["video/webm"],
  },
];

export const getPlaceholderForState = ({ serverCountries, intl }) => {
  let placeholder = intl.formatMessage({
    id: "form builder > placeholder state",
  });
  if (serverCountries.length === 1 && serverCountries[0] === "CA") {
    placeholder = intl.formatMessage({
      id: "form builder > placeholder province",
    });
  }
  if (
    serverCountries.length > 1 &&
    arrayContainsArray(serverCountries, ["CA"])
  ) {
    placeholder = intl.formatMessage({
      id: "form builder > placeholder state province",
    });
  }
  return placeholder;
};
export const getPlaceholderForZipCode = ({ serverCountries, intl }) => {
  let placeholder = intl.formatMessage({
    id: "form builder > placeholder zip code",
  });
  if (serverCountries.length === 1 && serverCountries[0] === "CA") {
    placeholder = intl.formatMessage({
      id: "form builder > placeholder postal code",
    });
  }
  if (arrayContainsArray(serverCountries, ["CA", "US"])) {
    placeholder = intl.formatMessage({
      id: "form builder > placeholder zip postal code",
    });
  }
  return placeholder;
};

export const getStateOptions = (country) => {
  if (country === "US") return statesList;
  if (country === "MX") return statesListMexico;
  if (country === "CA") return statesListCanada;

  return [];
};

export const getErrorForState = ({ serverCountries, intl }) => {
  let error = intl.formatMessage({
    id: "validate > state is required",
  });
  if (serverCountries.length === 1 && serverCountries[0] === "CA") {
    error = intl.formatMessage({
      id: "validate > province is required",
    });
  }
  if (
    serverCountries.length > 1 &&
    arrayContainsArray(serverCountries, ["CA"])
  ) {
    error = intl.formatMessage({
      id: "validate > state province is required",
    });
  }
  return error;
};

export function getFieldName(field) {
  if (!field) return "";
  const fieldKey = field.key;
  let fieldName = field.key;
  if (fieldKey === "is_primary") {
    fieldName = "primary_contact";
  } else if (fieldKey === "extension") {
    fieldName = "business_phone_extension";
  } else if (fieldKey === "business_phone_and_extension") {
    fieldName = "business_phone";
  }
  return fieldName;
}

export function getSelectedCountry({
  values,
  fieldNameOfCountry,
  serverCountries,
}) {
  let countryValue = get(values, fieldNameOfCountry);
  if (!countryValue && serverCountries.length > 0)
    countryValue = serverCountries[0];
  return countryValue;
}
