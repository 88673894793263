import React from "react";
import { FormattedMessage } from "react-intl";
import { Nav, NavItem } from "reactstrap";
import classnames from "classnames";
import ButtonAdd from "components/ButtonAdd";
import bn from "utils/bemnames";
import URL from "utils/urls";
import PrivilegedComponent from "components/PrivilegedComponent";
import { PRIVILEGES } from "utils/constants";
import ConfirmationModal from "components/ConfirmationModal";
import history from "components/History";
const bem = bn.create("dashboard");
class Dashboard extends React.Component {

  render() {

    const {
      enable_production,
      enable_promotion,
      enable_digital,
    } = this.props.serverInfo;

    return (
      <div className={classnames("fluid-container", bem.b())}>
        <div className={bem.e("header")}>
          <div className={classnames("container", bem.e("title"))}>
            <FormattedMessage id={`dashboard > title`} />
          </div>
          <div className={classnames("container", bem.e("header-actions"))}>
            <Nav tabs className={bem.e("nav-tabs")}>
              {/* TAB PRODUCTION ORDER */}
              {enable_production && (<PrivilegedComponent requires={{
                or: [PRIVILEGES.ADD_PRODUCTION, PRIVILEGES.EDIT_ALL_PRODUCTION_ORDERS]
              }}>
                {({ isValid }) => {
                  if (!isValid) return null;
                  return (
                    <NavItem>
                      <ButtonAdd
                        // to={URL.ADD_PROCESS({
                        //   template_id: "basic_production"
                        // })}
                        onClick={() => {
													this.props.setIsOpenProductionPromptModal(
														true
													);
												}}
                        children={
                          <FormattedMessage id="dashboard > add new process" />
                        }
                      />
                      <ConfirmationModal
												centered
												isOpen={
													this.props
														.isOpenProductionPromptModal
												}
												className={"production-prompt-modal production-prompt-details-modal"}
												title={
													<FormattedMessage id="process > title who will produce the spots for this order" />
												}
												confirmTitle={
													<FormattedMessage id="process > button production needed" />
												}
												cancelTitle={
													<FormattedMessage id="process > button production provided" />
												}
                        descriptionBottom={<div className={'description-spots-button'}>
                          <div className={'description-left'}><FormattedMessage id="process > description the spots need to be produced"/></div> 
                          <div className={'description-right'}><FormattedMessage id="process > description the client or agency is producing the spots"/></div> 
                        </div>}
												onConfirm={() => {
													history.push({
														pathname: URL.ADD_PROCESS(
															{
																template_id:
																	"basic_production",
															}
														),
														state: {
															contract_status:
																"Production Needed",
														},
													});
												}}
												onCancel={() => {
                          history.push({
                            pathname: URL.ADD_PROCESS({
                              template_id: "production_provided",
                            }),
                            state: {
                              contract_status: "Production Complete",
                            },
                          });
                        }}
												onToggle={() =>
													this.props.setIsOpenProductionPromptModal(
														!this.props
															.isOpenProductionPromptModal
													)
												}
                        isCloseOutside={false}
											/>
                    </NavItem>
                  );
                }}
              </PrivilegedComponent>)}
              {enable_digital && (<PrivilegedComponent requires={{ or: [PRIVILEGES.ADD_DIGITAL, PRIVILEGES.EDIT_ALL_DIGITAL_ORDERS] }}>
                <NavItem>
                  <ButtonAdd
                    to={URL.ADD_PROCESS({
                      template_id: "digital"
                    })}
                    children={
                      <FormattedMessage id="dashboard > add digital" />
                    }
                  />
                </NavItem>
              </PrivilegedComponent>)}
              {/* TAB_PROMOTION_REQUEST */}
              {enable_promotion && (<PrivilegedComponent requires={[PRIVILEGES.ADD_PROMOTION]}>
                {({ isValid }) => {
                  return (
                    <NavItem>
                      <ButtonAdd
                        to={URL.ADD_PROCESS({
                          template_id: "promotion_request"
                        })}
                        children={
                          <FormattedMessage id="dashboard > add promotion" />
                        }
                      />
                    </NavItem>
                  );
                }}
              </PrivilegedComponent>)}
              {/* TAB_PROMOTION_EVENT */}
              {enable_promotion && (<PrivilegedComponent requires={[PRIVILEGES.ADD_PROMOTION]}>
                {({ isValid }) => {
                  return (
                    <NavItem>
                      <ButtonAdd
                        to={URL.ADD_PROCESS({
                          template_id: "promotion_event"
                        })}
                        children={
                          <FormattedMessage id="dashboard > add promotion event" />
                        }
                      />
                    </NavItem>
                  );
                }}
              </PrivilegedComponent>)}
              {/* TAB_PROMOTION_CONTEST */}
              {enable_promotion && (<PrivilegedComponent
                requires={[PRIVILEGES.TAB_PROMOTION_CONTEST]}
              >
                {({ isValid }) => {
                  return (
                    <NavItem>
                      <ButtonAdd
                        to={URL.ADD_PROCESS({
                          template_id: "promotion"
                        })}
                        children={
                          <FormattedMessage id="dashboard > add promotion contest" />
                        }
                      />
                    </NavItem>
                  );
                }}
              </PrivilegedComponent>)}
            </Nav>
          </div>
        </div>
        <div className="container">
          <div className={bem.e("tab-content")}>
            <h4>Dashboard</h4>
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
