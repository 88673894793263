import * as React from "react";
import ChannelManagerForm from "./view";
import { omit, get, isFunction, map, forOwn, first, find } from "lodash";
import { compose, withHandlers, withState, lifecycle } from "recompose";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { MdImportantDevices } from "react-icons/lib/md";
import ToastManager from "components/ToastManager";
import { default_templates } from "utils/defaults";
import { fix_template_roles, validatePrivileges } from "utils/helpers";
import {
  addChannel,
  updateChannel,
  deleteChannel,
  cancelChannel,
  deleteChannelLocal,
} from "store/actions/channels";

export default injectIntl(
  compose(
    connect(
      (state, { currentStation }) => {
        const { channels, workflows, auth } = state;
        const stations = get(auth, "info.stations", []);
        return {
          isDirty: get(channels, "channels.is_dirty", false),
          channels: get(channels, "channels.data.data", []),
          workflows: get(workflows, "workflows.data.data", []),
          default_templates,
          roles: state.auth.info.roles,
          sharedStationData: find(
            stations,
            (item) => item.key === get(currentStation, "value")
          ),
        };
      },
      {
        addChannel,
        updateChannel,
        deleteChannel,
        cancelChannel,
        deleteChannelLocal,
      }
    ),
    withState("isHiddenForNew", "setIsHiddenForNew", ({ channel }) =>
      get(channel, "_source.is_hidden", true)
    ),
    withState("highlightSelection", "setHighlightSelection", []),
    withState("currentAction", "setCurrentAction", null),
    withState("isOpenContent", "setIsOpenContent", true),
    withState("highlightFields", "setHighlightFields", []),
    withState("channelNewName", "setChannelNewName", "New Marketing Channel"),
    // withState("isOpenConfirmationModal", "setIsOpenConfirmationModal", false),
    withState(
      "isOpenConfirmationActionsUnsavedModal",
      "setIsOpenConfirmationActionsUnsavedModal",
      false
    ),
    withState(
      "isOpenFieldsFormBuilderModal",
      "setIsOpenFieldsFormBuilderModal",
      false
    ),
    withState(
      "isOpenAddRulesConfirmationModal",
      "setIsOpenAddRulesConfirmationModal",
      false
    ),
    withState(
      "isOpenPublishConfirmationModal",
      "setIsOpenPublishConfirmationModal",
      false
    ),
    withState("isForceSubmitForm", "setIsForceSubmitForm", false),
    withHandlers({
      onCancelChannel: ({ cancelChannel, channel }) => () => {
        const id = get(channel, "id", "");
        if (id) {
          cancelChannel(id);
        }
      },
      onSubmitForm: ({
        channels,
        addChannel,
        updateChannel,
        intl,
        onHandledChannelForm,
        setIsOpenForm,
        currentAction,
        setIsOpenChannelPreviewModal,
        setCurrentPreviewChannel,
        onCloneChannel,
        onSaveHiddenChannel,
        currentStation,
        setCurrentAction,
        setCurrentPublishChannel,
        setShouldBlockNavigation,
      }) => (value, formikProps) => {
        let data = value;
        if (value.fields) {
          data.fields = value.fields.map((field) => ({
            ...omit(field, ["is_new"]),
          }));
        }

        /*data.workflow = data.workflow.workflow;
        if(data.workflow){
          data.workflow.label && delete data.workflow.label;
          data.workflow.value && delete data.workflow.value;
          data.workflow.workflow && delete data.workflow.workflow;
        }*/
        if (value.id) {
          if (value.id.indexOf("_new_") === 0) {
            data.order = channels.length;
          }
          data.station_id = currentStation.value;

          updateChannel(value.id, data, (response) => {
            let { status, errors } = response;
            if (status) {
              setShouldBlockNavigation(false);
              ToastManager.show({
                title: <MdImportantDevices />,
                message: intl.formatMessage({
                  id: `channel manager > updated successfully`,
                }),
                autoDismiss: 1.5,
                level: "success",
              });
              onHandledChannelForm("update", value);
              if (isFunction(setIsOpenForm) && currentAction === "confirm") {
                setTimeout(() => setIsOpenForm(value.id), 20);
                setCurrentAction(null);
              }
              if (
                isFunction(setIsOpenChannelPreviewModal) &&
                isFunction(setCurrentPreviewChannel) &&
                currentAction === "preview"
              ) {
                setTimeout(() => {
                  setIsOpenChannelPreviewModal(true);
                  setCurrentPreviewChannel(data);
                  setCurrentAction(null);
                }, 20);
              }
              // if (isFunction(onCloneChannel) && currentAction === "copy") {
              //   setTimeout(() => {
              //     onCloneChannel(response.data);
              //     setCurrentAction(null);
              //   }, 20);
              // }
              if (
                isFunction(onSaveHiddenChannel) &&
                currentAction === "switch"
              ) {
                setTimeout(() => {
                  onSaveHiddenChannel(response.data, !response.data.is_hidden);
                  setCurrentAction(null);
                }, 20);
              }

              if (!currentAction && get(response, "data.is_hidden", false)) {
                setCurrentPublishChannel(response.data);
              }
            } else {
              forOwn(errors, (value, key) => {
                formikProps.setFieldError(key, first(value));
              });
            }
          });
        } else {
          const addNewData = {
            ...data,
            order: channels.length,
            station_id: currentStation.value,
          };
          addChannel(addNewData, (response) => {
            let { status, errors } = response;
            if (status) {
              setShouldBlockNavigation(false);
              ToastManager.show({
                title: <MdImportantDevices />,
                message: intl.formatMessage({
                  id: `channel manager > created successfully`,
                }),
                autoDismiss: 1.5,
                level: "success",
              });
              onHandledChannelForm("add", response.data);
              setIsOpenForm(response.data.id);
              if (get(response, "data.is_hidden", false)) {
                setCurrentPublishChannel(response.data);
              }
            } else {
              forOwn(errors, (value, key) => {
                formikProps.setFieldError(key, first(value));
              });
            }
          });
        }
      },
      getDefaultSystemWorkfolows: ({ default_templates, roles }) => () => {
        let fixed_default_templates = {};
        Object.keys(default_templates).map((template_key) => {
          let template = JSON.parse(
            JSON.stringify(default_templates[template_key])
          );
          template._source = fix_template_roles(template._source, { roles });
          fixed_default_templates[template_key] = template;
        });
        const getStepRoles = (step) => {
          let stepRoles = roles.filter((role) => {
            return (
              validatePrivileges({
                requires: step.step_editors_privileges,
                privileges: Object.values(role.privileges),
              }) || step.step_editors_roles.indexOf(role.id) > -1
            );
          });
          return stepRoles;
        };
        let res = [];
        // debugger
        map(fixed_default_templates, (t) => {
          if (!t._source.workflow) return false;
          let steps = [];
          Object.keys(t._source.steps).map((stepKey) => {
            const step = t._source.steps[stepKey];
            if (!step.workflow) return false;
            let flowStep = {
              id: step.key,
              name: step.workflow.title,
              is_approval_step: step.is_approval_step,
              stepIcon: step.workflow.icon,
              roles: getStepRoles(step),
            };
            steps.push(flowStep);
          });
          let flow = {
            id: t._id,
            name: t._source.workflow.title,
            steps,
            is_default: true,
            is_published: true,
            is_allow_create_custom_workflow:
              t._source.is_allow_create_custom_workflow,
          };
          res.push(flow);
        });
        return res;
      },
    }),
    lifecycle({
      componentDidUpdate(prevProps) {
        if (
          get(this.props, "channel._source.is_hidden", true) !==
          get(prevProps, "channel._source.is_hidden", true)
        ) {
          this.props.setIsHiddenForNew(
            get(this.props, "channel._source.is_hidden", true)
          );
        }
      },
    })
  )(ChannelManagerForm)
);
