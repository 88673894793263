import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { get } from "lodash";
import ServerFormSection from "./ServerFormSection";
import ModuleEntitiesAssignment from "./ModuleEntitiesAssignment";

class WideOrbitAutomationConfiguration extends Component {

  onSwitchToggle = (checked) => {
    const { formProps } = this.props;
    formProps.setFieldValue("enable_wo_dubbing", checked);
    formProps.setFieldTouched("enable_wo_dubbing", true);
  };

  render() {
    const { bem, edit, formProps } = this.props;
    const moduleEnabled = get(formProps, "values.enable_wo_dubbing", false);
    return (
      <ServerFormSection
        bem={bem}
        checked={moduleEnabled}
        titleId="server > wide orbit automation"
        sectionClass={bem.e("box-wo-automation-details")}
        sectionFieldsClass={bem.e("section-view-wo-automation-configuration-fields")}
        hasToggle={edit}
        onSwitchToggle={this.onSwitchToggle}
      >
        <ModuleEntitiesAssignment {...this.props} moduleEnabled={moduleEnabled} featureModule={"wo_dubbing"} />
      </ServerFormSection>
    )
  }
}

export default injectIntl(WideOrbitAutomationConfiguration);
