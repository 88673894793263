import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import bn from "utils/bemnames";
import { getFileNameFromUrl } from "utils/helpers";
import FileView from "components/FileView";
import UploadFile from "components/UploadFile";

const bem = bn.create("client-review");

const SlideScriptItem = (props) => {
  const {
    item,
    isBookend,
    intl,
    process,
    onChangeFeedback,
    isDisabled,
    isOpenFeedback,
    renderButtons,
    renderSummary,
  } = props;
  const [value, setValue] = useState("");
  return (
    <div className={bem.e("slide-script-item")}>
      <div className={bem.e("script-recording-header")}>
        <FormattedMessage id={"process > title final script"} />
      </div>
      {isBookend && (
        <div className="script-part-header">
          {item.key === "script" ? (
            <FormattedMessage id="process > part A" />
          ) : (
            <FormattedMessage id="process > part B" />
          )}
        </div>
      )}
      <div className={bem.e("script-file")}>
        <FileView
          file={{
            path: item.script,
            name: getFileNameFromUrl(item.script),
          }}
          actions={["view", "download"]}
        />
      </div>
      {/* render buttons for mobile */}
      {renderSummary ? renderSummary : null}
      {renderButtons ? renderButtons : null}
      {isOpenFeedback && (
        <div className={bem.e("production-change-request")}>
          <div className={bem.e("script-recording-header")}>
            <FormattedMessage id={"process > feedback"} />
          </div>
          <div className={bem.e("feedback-description")}>
            <FormattedMessage id="process > client review feedback helper text" />
          </div>
          <UploadFile
            mode={"edit"}
            process={process}
            onUploaded={(value) => {
              if (isDisabled) return;
              setValue(value.path);
              onChangeFeedback(item.key, "feedback", value.path);
            }}
            attachText={intl.formatMessage({
              id: "process > placeholder attach revised script",
            })}
            isDisabledDelete={true}
            onDeleted={() => {}}
            isCheckMimesByExt={true}
            extensions={["doc", "docx", "pdf"]}
            url={value}
            actions={["download"]}
            positionActions="bottom"
            isShowFileNameInEditMode={true}
            hideSpotLength={true}
            part={item.field === "script" ? "a" : "b"}
            isBookend={isBookend}
          >
            <FormattedMessage id="process > button upload" />
          </UploadFile>
        </div>
      )}
    </div>
  );
};
export default SlideScriptItem;
