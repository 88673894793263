import React from "react";
import { FormattedMessage } from "react-intl";
import { Input } from "reactstrap";
import bn from "utils/bemnames";
import classnames from "classnames";
import { 
  DownIcon, 
  EditIcon, 
  SaveIcon, 
  UpIcon, 
  DubbingSentButton, 
  ErrorCrossIcon, 
  PlusSmallIcon ,
  TrashSmallIcon
} from "components/CustomIcons";
import {
  ShadowBox,
  SectionTitle,
  SectionFields,
  ChevronWrapper,
} from "components/Elements";
import { 
  includes, 
  map, 
  isEqual, 
  get, 
  values, 
  mapValues, 
  keyBy, 
  isEmpty,
  filter,
} from 'lodash';
import { Formik } from "formik";
import * as Yup from "yup";
import TextInput from "components/TextInput";
import { Form, FormGroup, Button, Row, Col } from "reactstrap";
import { Asterisk } from "components/Elements";
import ToastManager from "components/ToastManager";
const bem = bn.create("wideorbit");

const WideOrbitConfigurations = (props) => {
  const {
    intl,
    openSections,
    onToggleSection,
    productsSettings,
    editingIds,
    onToggleEdit,
    editingIntegrations,
    onValueChange,
    onUpdateIntegration,
    shouldBlockNavigation,
    setShouldBlockNavigation,
    saveWideOrbitApiConfig,
    saveWideOrbitFtpConfig,
    isFtpSaveLoading,
    isApiConfigSaveLoading,
    ftpConnectionProgress,
    isFtpConfigSaved,
    isError,
    dubbingFtpButtonStatus,
    setDubbintFtpButtonStatus,
    isLoading,
    showOnlyGlobalWOConfig,
    materialGroups,
    saveMaterialGroups,
    isMGSaveLoading,
    isWODubbingEnabled,
    isWOTrafficEnabled
  } = props;

  let apiConfigformikRef = React.createRef();
  let ftopConfiformikRef = React.createRef();

  const wideOrbitConfig = get(productsSettings, "wideorbit_config", {});

  const renderErrorMessage = (data, field, isEdit) => {
    if (isEdit && data.errors && data.errors[field]) {
      return <span className="text-danger">{data.errors[field]}</span>
    }
    return null;
  }

  const getFtpSaveButtonText = (isFtpConfigSaved, ftpConnectionProgress) => {

    if(isError){
      return "server > button error";
    }

    if(isFtpConfigSaved){
      return "server > button ftp";
    }

    if(ftpConnectionProgress  === 0){
      return "process > button save";
    }
  }

  const renderApiConfiguration = () => {

    let apiConfigInitialValues = {};

    if(showOnlyGlobalWOConfig) {
      apiConfigInitialValues = {
        base_url: get(wideOrbitConfig, "base_url", "") || "",
        partner_id: get(wideOrbitConfig, "partner_id", "") || "",
        api_key: get(wideOrbitConfig, "api_key", "") || "",
      };
    }else {
      apiConfigInitialValues = {
        agreement_key: get(wideOrbitConfig, "agreement_key", "") || "",
      };
    }
    
    const ftpServerInitialValues = {
      ftp_path: get(wideOrbitConfig, "ftp_path", "") || "",
      subfolder: get(wideOrbitConfig, "subfolder", "") || "",
      username: get(wideOrbitConfig, "username", "") || "",
      password: get(wideOrbitConfig, "password", "") || "",
      port: get(wideOrbitConfig, "port", "") || "21",
    }

    let validationSchema = {};

    if(showOnlyGlobalWOConfig) {
      validationSchema = Yup.object().shape({
          base_url: Yup.string().required(
            intl.formatMessage({
              id: "validate > wo > base_url is required"
            })
          ),
          partner_id: Yup.string().required(
            intl.formatMessage({
              id: "validate > wo > partner_id is required"
            })
          ),
          api_key: Yup.string().required(
            intl.formatMessage({
              id: "validate > wo > api_key is required"
            })
          ),
        });
    }else {
      validationSchema = Yup.object().shape({
          agreement_key: Yup.string().required(
            intl.formatMessage({
              id: "validate > wo > agreement_key is required"
            })
          ),
        });
    }

    const renderForm = (formProps) => {
      const errors = formProps.errors;
      const touched = formProps.touched;
      const values = formProps.values;

      if(showOnlyGlobalWOConfig) {
        return (
          <Form id="server-form" onSubmit={formProps.handleSubmit} className={bem.e('server-edit-container')}>
            <Row>
              <Col xs={6} className={bem.e('mb-10')}>
                <FormGroup className={bem.e('last-form-group')}>
                  <TextInput
                    label={
                      <span className={bem.e('label')}>
                        <FormattedMessage id="server > wideorbit > base url" />
                        <Asterisk>*</Asterisk>
                      </span>
                    }
                    type="text"
                    name="base_url"
                    placeholder={intl.formatMessage({
                      id: "server > wideorbit > enter text"
                    })}
                    value={values.base_url}
                    onChange={formProps.handleChange}
                    onBlur={formProps.handleBlur}
                    error={touched.base_url && errors.base_url}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={6} className={bem.e('mb-10')}>
                <FormGroup className={bem.e('last-form-group')}>
                  <TextInput
                    label={
                      <span className={bem.e('label')}>
                        <FormattedMessage id="server > wideorbit > partner id" />
                        <Asterisk>*</Asterisk>
                      </span>
                    }
                    type="text"
                    name="partner_id"
                    placeholder={intl.formatMessage({
                      id: "server > wideorbit > enter text"
                    })}
                    value={values.partner_id}
                    onChange={formProps.handleChange}
                    onBlur={formProps.handleBlur}
                    error={touched.partner_id && errors.partner_id}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={6} className={bem.e('mb-10')}>
                <FormGroup className={bem.e('last-form-group')}>
                  <TextInput
                    label={
                      <span className={bem.e('label')}>
                        <FormattedMessage id="server > wideorbit > api key" />
                        <Asterisk>*</Asterisk>
                      </span>
                    }
                    type="text"
                    name="api_key"
                    placeholder={intl.formatMessage({
                      id: "server > wideorbit > enter text"
                    })}
                    value={values.api_key}
                    onChange={formProps.handleChange}
                    onBlur={formProps.handleBlur}
                    error={touched.api_key && errors.api_key}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={6} className={bem.e('mb-20')}>
                <FormGroup>
                  <Button
                    color="blue"
                    type="submit"
                    className={classnames('btn', 'btn-radius', bem.e('save-button'))}
                    disabled={isApiConfigSaveLoading}
                  >
                    <FormattedMessage
                      id={`process > button ${!isApiConfigSaveLoading ? "save" : "saving"
                        }`}
                    />
                  </Button>
                </FormGroup>
              </Col>
            </Row>
        </Form>
        );
      }

      return (
        <Form id="server-form" onSubmit={formProps.handleSubmit} className={bem.e('server-edit-container')}>
          <Row>
            <Col xs={6} className={bem.e('mb-10')}>
              <FormGroup className={bem.e('last-form-group')}>
                <TextInput
                  label={
                    <span className={bem.e('label')}>
                      <FormattedMessage id="server > wideorbit > agreement key" />
                      <Asterisk>*</Asterisk>
                    </span>
                  }
                  type="text"
                  name="agreement_key"
                  placeholder={intl.formatMessage({
                    id: "server > wideorbit > enter text"
                  })}
                  value={values.agreement_key}
                  onChange={formProps.handleChange}
                  onBlur={formProps.handleBlur}
                  error={touched.agreement_key && errors.agreement_key}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={6} className={bem.e('mb-20')}>
              <FormGroup>
                <Button
                  color="blue"
                  type="submit"
                  className={classnames('btn', 'btn-radius', bem.e('save-button'))}
                  disabled={isApiConfigSaveLoading}
                >
                  <FormattedMessage
                    id={`process > button ${!isApiConfigSaveLoading ? "save" : "saving"
                      }`}
                  />
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      )
    }
    return (
      <SectionFields className={classnames(bem.e("section-fields"), 'api-config')}>
        <div className={bem.e("table-content")}>
          <Formik
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            validateOnMount={false}
            initialValues={apiConfigInitialValues}
            validationSchema={validationSchema}
            ref={ref => (apiConfigformikRef = ref)}
            onSubmit={saveWideOrbitApiConfig}
          >
            {formProps => {
              return renderForm(formProps);
            }}
          </Formik>
          {showOnlyGlobalWOConfig && (
            <div className={bem.e('api-ftp-details')}>
              <div className={bem.e('header')}>
                <FormattedMessage id="server > wideorbit > api ftp > details" />
              </div>
              <Formik
                enableReinitialize
                validateOnChange={false}
                validateOnBlur={false}
                validateOnMount={false}
                initialValues={ftpServerInitialValues}
                validationSchema={Yup.object().shape({
                  ftp_path: Yup.string().required(
                    intl.formatMessage({
                      id: "validate > wo > ftp_path is required"
                    })
                  ),
                  subfolder: Yup.string().required(
                    intl.formatMessage({
                      id: "validate > wo > subfolder is required"
                    })
                  ),
                  username: Yup.string().required(
                    intl.formatMessage({
                      id: "validate > wo > username is required"
                    })
                  ),
                  password: Yup.string().required(
                    intl.formatMessage({
                      id: "validate > wo > password is required"
                    })
                  ),
                  port: Yup.string().required(
                    intl.formatMessage({
                      id: "validate > wo > port is required"
                    })
                  ),
                })}
                ref={ref => (ftopConfiformikRef = ref)}
                onSubmit={saveWideOrbitFtpConfig}
              >
                {formProps => {
                  const errors = formProps.errors;
                  const touched = formProps.touched;
                  const values = formProps.values;
      
                  return (
                    <Form id="server-form" onSubmit={formProps.handleSubmit} className={bem.e('server-edit-container')}>
                        <Row>
                          <Col xs={6} className={bem.e('mb-10')}>
                            <FormGroup className={bem.e('last-form-group')}>
                              <TextInput
                                label={
                                  <span className={bem.e('label')}>
                                    <FormattedMessage id="server > wideorbit > ftppath" />
                                    <Asterisk>*</Asterisk>
                                  </span>
                                }
                                type="text"
                                name="ftp_path"
                                placeholder={intl.formatMessage({
                                  id: "server > wideorbit > enter text"
                                })}
                                value={values.ftp_path}
                                onChange={formProps.handleChange}
                                onBlur={formProps.handleBlur}
                                error={touched.ftp_path && errors.ftp_path}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6} className={bem.e('mb-10')}>
                            <FormGroup className={bem.e('last-form-group')}>
                              <TextInput
                                label={
                                  <span className={bem.e('label')}>
                                    <FormattedMessage id="server > wideorbit > username" />
                                    <Asterisk>*</Asterisk>
                                  </span>
                                }
                                type="text"
                                name="username"
                                placeholder={intl.formatMessage({
                                  id: "server > wideorbit > enter text"
                                })}
                                value={values.username}
                                onChange={formProps.handleChange}
                                onBlur={formProps.handleBlur}
                                error={touched.username && errors.username}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6} className={bem.e('mb-10')}>
                            <FormGroup className={bem.e('last-form-group')}>
                              <TextInput
                                label={
                                  <span className={bem.e('label')}>
                                    <FormattedMessage id="server > wideorbit > password" />
                                    <Asterisk>*</Asterisk>
                                  </span>
                                }
                                type="text"
                                name="password"
                                placeholder={intl.formatMessage({
                                  id: "server > wideorbit > enter text"
                                })}
                                value={values.password}
                                onChange={formProps.handleChange}
                                onBlur={formProps.handleBlur}
                                error={touched.password && errors.password}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6} className={bem.e('mb-10')}>
                            <FormGroup className={bem.e('last-form-group')}>
                              <TextInput
                                label={
                                  <span className={bem.e('label')}>
                                    <FormattedMessage id="server > wideorbit > port" />
                                    <Asterisk>*</Asterisk>
                                  </span>
                                }
                                type="text"
                                name="port"
                                placeholder={intl.formatMessage({
                                  id: "server > wideorbit > enter text"
                                })}
                                value={values.port}
                                onChange={formProps.handleChange}
                                onBlur={formProps.handleBlur}
                                error={touched.port && errors.port}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6} className={bem.e('mb-10')}>
                            <FormGroup className={bem.e('last-form-group')}>
                              <TextInput
                                label={
                                  <span className={bem.e('label')}>
                                    <FormattedMessage id="server > wideorbit > subfolder" />
                                    <Asterisk>*</Asterisk>
                                  </span>
                                }
                                type="text"
                                name="subfolder"
                                placeholder={intl.formatMessage({
                                  id: "server > wideorbit > enter text"
                                })} 
                                value={values.subfolder}
                                onChange={formProps.handleChange}
                                onBlur={formProps.handleBlur}
                                error={touched.subfolder && errors.subfolder}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6} className={bem.e('mb-20')}>
                            <FormGroup>
                              {ftpConnectionProgress > 0 && (<div className={classnames(bem.e('ftp-connection-progress'), {complete: isFtpConfigSaved}, {error: isError})} style={{width: `${ftpConnectionProgress}px`}}></div>)}
                              <Button
                                color="blue"
                                type="submit"
                                className={classnames('btn', 'btn-radius', bem.e('save-button'), {complete: isFtpConfigSaved}, {error: isError})}
                                disabled={isLoading}
                              >
                                {isFtpConfigSaved ? (<DubbingSentButton />) : (isError ? (<ErrorCrossIcon />) : ``)}
                                {(ftpConnectionProgress === 0 || isFtpConfigSaved) && (<span className="success-text"><FormattedMessage id={getFtpSaveButtonText(isFtpConfigSaved, ftpConnectionProgress)} /></span>)}
                              </Button>
                            </FormGroup>
                          </Col>
                        </Row>
                    </Form>
                  )
                }}
              </Formik>
            </div>
          )}
        </div>
      </SectionFields>
    );
  }


  const renderDubbingConfigurations = () => {
    return (
      <SectionFields className={classnames(bem.e("section-fields"))}>
        <div className={bem.e("table-content")}>
          {map(get(productsSettings, "entity_assignment", []), (row) => {

            const hasData = !!row.ftp_path;
            const id = row.id;
            const isEdit = editingIds.includes(id);
            const editData = editingIntegrations[id];

            let buttonStatus = dubbingFtpButtonStatus[id] || {
              isFtpConfigSaved: false,
              isError: false,
              ftpConnectionProgress: 0,
              isLoading: false,
            };

            const {
              isFtpConfigSaved,
              ftpConnectionProgress,
            } = buttonStatus;

            return (
              <div key={id} className={bem.e("table-row")}>
                <div className="col-table-25 market-title">
                  {row.market}
                </div>
                {hasData || isEdit ? <>
                  <div className="col-table-20 bw">
                    {isEdit ? <Input
                      className={classnames(bem.e("input"), "left-rounded")}
                      placeholder={
                        intl.formatMessage({
                          id: "server > wideorbit > ftppath"
                        })
                      }
                      value={get(editData, "ftp_path", "") || ""}
                      name="ftp_path"
                      onChange={onValueChange(id)}
                    /> : row.ftp_path}
                    {renderErrorMessage(editData, 'ftp_path', isEdit)}
                  </div>
                  <div className="col-table-15 bw">
                    {isEdit ? <Input
                      placeholder={
                        intl.formatMessage({
                          id: "server > wideorbit > username"
                        })
                      }
                      className={classnames(bem.e("input"))}
                      name="username"
                      value={get(editData, "username", "") || ""}
                      onChange={onValueChange(id)}
                    /> : row.username}
                    {renderErrorMessage(editData, 'username', isEdit)}
                  </div>
                  <div className="col-table-15 bw">
                    {isEdit ? <Input
                      placeholder={
                        intl.formatMessage({
                          id: "server > wideorbit > password"
                        })
                      }
                      className={classnames(bem.e("input"))}
                      value={get(editData, "password", "") || ""}
                      name="password"
                      onChange={onValueChange(id)}
                    /> : '********'}
                    {renderErrorMessage(editData, 'password', isEdit)}
                  </div>
                  <div className="col-table-15 bw">
                    {isEdit ? <Input
                      placeholder={
                        intl.formatMessage({
                          id: "server > wideorbit > port"
                        })
                      }
                      className={classnames(bem.e("input"))}
                      value={get(editData, "port", "") || ""}
                      name="port"
                      onChange={onValueChange(id)}
                    /> : row.port}
                    {renderErrorMessage(editData, 'port', isEdit)}
                  </div>
                  <div className="col-table-15 bw">
                    {isEdit ? <Input
                      placeholder={
                        intl.formatMessage({
                          id: "server > wideorbit > subfolder"
                        })
                      }
                      className={classnames(bem.e("input"), "right-rounded")}
                      value={get(editData, "subfolder", "") || ""}
                      name="subfolder"
                      onChange={onValueChange(id)}
                    /> : row.subfolder}
                    {renderErrorMessage(editData, 'subfolder', isEdit)}
                  </div>
                  <div className="col-table-10 can-click pl-4 action-item" onClick={() => {
                    if(!isEdit && !isLoading){
                      onToggleEdit(row);
                    }
                  }}>
                    {isEdit ? (
                      <div>
                        {ftpConnectionProgress > 0 && (<div className={classnames(bem.e('ftp-connection-progress'), {complete: isFtpConfigSaved}, {error: isError})} style={{width: `${ftpConnectionProgress}px`}}></div>)}
                        <Button
                          color="blue"
                          type="button"
                          className={classnames('btn', 'btn-radius', bem.e('save-button'), {complete: isFtpConfigSaved}, {error: isError})}
                          disabled={isLoading}
                          onClick={() => {
                            onUpdateIntegration(editData);
                          }}
                        >
                          {isFtpConfigSaved ? (<DubbingSentButton />) : (isError ? (<ErrorCrossIcon />) : ``)}
                          {(ftpConnectionProgress === 0 || isFtpConfigSaved) && (<span className="success-text"><FormattedMessage id={getFtpSaveButtonText(isFtpConfigSaved, ftpConnectionProgress)} /></span>)}
                        </Button>
                      </div>
                    ) : <EditIcon />}
                  </div>
                </> : <>
                  <div className="muted-info d-flex">
                    <Col xs={10} className={bem.e('info-req-container')}><FormattedMessage id="server > wideorbit > info required" /></Col>
                    <div className="ml-4 can-click action-item" onClick={() => onToggleEdit(row)}>
                      {isEdit ? <SaveIcon /> : <EditIcon />}
                    </div>
                  </div>
                </>}
              </div>
            );
          })}
        </div>
      </SectionFields>
    );
  };

  const renderMaterialGroupRows = (formProps) => {

    let materialGroups = get(formProps.values, "material_groups", {});
    let activeMaterialGroups = filter(materialGroups, (materialGroup) => !materialGroup.deleted);

    const {
      errors,
      setFieldValue,
      setFieldTouched
    }  = formProps;

    if(isWOTrafficEnabled && isEmpty(activeMaterialGroups)) {
      return (
        <div className={bem.e('no-material-group')}>
          <FormattedMessage id= "server > wideorbit > no material groups"/>
        </div>
      );
    }

    return map(activeMaterialGroups, (materialGroup, index) => {
                
      let id = materialGroup.id;
      let rowErrors = get(errors, `material_groups[${id}]`, {});


      return (
        <Row key={id}>
          <Col xs={6} className={classnames(bem.e('mb-10'), bem.e('padr-0'))}>
            <FormGroup className={bem.e('last-form-group')}>
              <TextInput
                type="text"
                name="name"
                label={(index === 0 ? (
                  <span className={bem.e('label')}>
                    <FormattedMessage id={isWOTrafficEnabled ? `server > wideorbit > material group` :  `server > wideorbit > material group/group`}/>
                  </span>) : ''
                )}
                placeholder={intl.formatMessage({
                  id: "server > wideorbit > enter text"
                })} 
                value={get(materialGroup, "name", "")}
                onChange={(e) => {
                  setFieldValue('material_groups', {...materialGroups, [id]: {...materialGroup, name: e.target.value}});
                }}
                disabled={isWOTrafficEnabled}
                onBlur={formProps.handleBlur}
                error={rowErrors && rowErrors.name} 
              />
            </FormGroup>
          </Col>
          <Col xs={6} className={classnames(bem.e('mb-10'), bem.e('padl-0'))}>
            <FormGroup className={bem.e('last-form-group')}>
              <TextInput
                type="text"
                name="partner_id"
                label={(index === 0 ? (
                  <span className={bem.e('label')}>
                    <FormattedMessage id="server > wideorbit > audio source" />
                  </span>) : ''
                )}
                placeholder={intl.formatMessage({
                  id: "server > wideorbit > enter prefix"
                })} 
                value={get(materialGroup, "prefix", "")}
                onChange={(e) => {
                  setFieldValue('material_groups', {...materialGroups, [id]: {...materialGroup, prefix: e.target.value}});
                }}
                onBlur={formProps.handleBlur}
                error={rowErrors && rowErrors.prefix} 
              />
              {!isWOTrafficEnabled && (activeMaterialGroups.length > 1 ? (<div className={classnames(bem.e('delete-button'), {'error-row' : !isEmpty(rowErrors)})}
                onClick={() => {
                  setFieldValue('material_groups', {...materialGroups, [id]: {...materialGroup, deleted: true}});
                    formProps.setFieldTouched('material_groups');
                }}
              >
                <TrashSmallIcon />
              </div>) : ((!isEmpty(get(materialGroup, "name", "")) || !isEmpty(get(materialGroup, "prefix", ""))) ? (
                <div className={classnames(bem.e('reset-button'), {'error-row' : !isEmpty(rowErrors)})} 
                  onClick={() => {
                     setFieldValue('material_groups', {...materialGroups, [id]: {...materialGroup, name: '', prefix: ''}});
                    formProps.setFieldTouched('material_groups');
                  }}
                > 
                   <FormattedMessage id="server > wideorbit > material group reset row"/>
                </div>
              ) : ``))}
            </FormGroup>
          </Col>
        </Row>
      );
    })
  }

  const renderMaterialGroupConfigurations = () => {

    let defaultRowId = `${Date.now()}_new`;
    let initialValues = {
      material_groups: !isWOTrafficEnabled && isEmpty(materialGroups)  ? {
        [defaultRowId] : {
          id: defaultRowId,
          name: '',
          prefix: '',
          deleted: false,
        }
      } : keyBy(materialGroups, 'id'),
    };

    return (
      <SectionFields className={classnames(bem.e("section-fields"))}> 
        <div className={bem.e("table-content")}>
          <Formik
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            validateOnMount={false}
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              material_groups: Yup.lazy((obj) =>
                Yup.object(mapValues(obj, () => Yup.object({
                  deleted: Yup.boolean(),
                  name: Yup.string().when(["deleted"], {
                    is: (deleted) => !deleted && isWOTrafficEnabled,
                    then: Yup.string()
                      .trim()
                      .required(
                        intl.formatMessage({
                          id: "server > wideorbit > material group is required",
                        })
                      ),
                  }),
                  prefix: Yup.string().when(["deleted"], {
                    is: false,
                    then: Yup.string()
                      .trim()
                      .required(
                        intl.formatMessage({
                          id: "server > wideorbit > material group prefix is required",
                        })
                      ),
                  }),
                })))
              )
            })} 
            onSubmit={saveMaterialGroups}
          >
            {formProps => {

              let materialGroups = get(formProps.values, "material_groups", {});
              let activeMaterialGroups = filter(materialGroups, (materialGroup) => !materialGroup.deleted);

              return (
                <React.Fragment>
                  <Form onSubmit={formProps.handleSubmit}>
                    {renderMaterialGroupRows(formProps)}
                    <Row>
                      {!isEmpty(materialGroups) && (<Col xs={6} className={classnames(bem.e('mb-20'), bem.e('mt-10'))}>
                        <FormGroup>
                          <Button
                            color="blue"
                            type="submit"
                            className={classnames('btn', 'btn-radius', bem.e('save-button'))}
                            disabled={isMGSaveLoading}
                          >
                            <FormattedMessage
                              id={`process > button ${!isMGSaveLoading ? "save" : "saving"
                                }`}
                            />
                          </Button>
                        </FormGroup>
                      </Col>)}
                      {!isWOTrafficEnabled && (<Col xs={!isEmpty(materialGroups) ? 6 : 12} className={classnames(bem.e('mb-20'), bem.e('mt-10'))}>
                        <FormGroup className={bem.e('add-button-container')}>
                          <Button
                            className={classnames(bem.e("add-button"), "btn-radius")}
                            outline
                            color="blue"
                            onClick={() => {
                              const id = `${Date.now()}_new`;
                              formProps.setFieldValue('material_groups', {...materialGroups, [id]: {
                                id: id,
                                name: '',
                                prefix: '',
                                deleted: false,
                              }});
                              formProps.setFieldTouched('material_groups');
                            }}
                          >
                            <span className={bem.e("add-icon")}>
                              <PlusSmallIcon />
                            </span>
                            <FormattedMessage id="server > wideorbit > material group add row"/>
                          </Button>
                        </FormGroup>
                      </Col>)}
                    </Row>
                  </Form>
                </React.Fragment>
              );
            }}
          </Formik> 
        </div> 
      </SectionFields>
    );
  };

  return (
    <React.Fragment>
      {(isWOTrafficEnabled || showOnlyGlobalWOConfig) && (<ShadowBox className={classnames(bem.e("box-section"))}>
        <SectionTitle
          className={bem.e("section-title")}
          onClick={() => onToggleSection('api_configuration')}
        >
          <FormattedMessage id="server > wideorbit > api config > title" />
          <ChevronWrapper>
            {includes(openSections, 'api_configuration') ? <UpIcon /> : <DownIcon />}
          </ChevronWrapper>
        </SectionTitle>
        {includes(openSections, 'api_configuration') && renderApiConfiguration()}
      </ShadowBox>)}
      {!showOnlyGlobalWOConfig && (<ShadowBox className={classnames(bem.e("box-section"))}>
        <SectionTitle
          className={bem.e("section-title")}
          onClick={() => onToggleSection('wo_material_group_configuration')}
        >
          <FormattedMessage id="server > wideorbit > material group config > title" />
          <ChevronWrapper>
            {includes(openSections, 'wo_material_group_configuration') ? <UpIcon /> : <DownIcon />}
          </ChevronWrapper>
        </SectionTitle>
        {includes(openSections, 'wo_material_group_configuration') && renderMaterialGroupConfigurations()}
      </ShadowBox>
      )}
      {!showOnlyGlobalWOConfig && isWODubbingEnabled && (<ShadowBox className={classnames(bem.e("box-section"))}>
        <SectionTitle
          className={bem.e("section-title")}
          onClick={() => onToggleSection('dubbing_configuration')}
        >
          <FormattedMessage id="server > wideorbit > dubbing config > title" />
          <ChevronWrapper>
            {includes(openSections, 'dubbing_configuration') ? <UpIcon /> : <DownIcon />}
          </ChevronWrapper>
        </SectionTitle>
        {includes(openSections, 'dubbing_configuration') && renderDubbingConfigurations()}
      </ShadowBox>
      )}
    </React.Fragment>
  )
};

export default WideOrbitConfigurations;
