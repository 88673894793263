import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Input, Label } from "reactstrap";
import bn from "utils/bemnames";
const bem = bn.create("textinput");

class TextInput extends React.Component {
  static propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    error: PropTypes.string,
    name: PropTypes.string,
    leftComponent: PropTypes.node,
    rightComponent: PropTypes.node,
    labelProps: PropTypes.object,
    ableToAddNew: PropTypes.bool, // whether to give functionality to add new textfield
    onAddNew: PropTypes.func,
    fields: PropTypes.arrayOf(PropTypes.shape({
      error: PropTypes.string,
      name: PropTypes.string,
      value: PropTypes.string,
      type: PropTypes.string
    })) // this is useful in case of we want to support multiple textbox , see `ableToAddNew` prop
  };

  static defaultProps = {
    type: "text",
    labelProps: {},
    leftComponent: null,
    rightComponent: null,
    ableToAddNew: false,
    onAddNew: () => { }
  };

  renderRightComponent = (index, arrIndex, onDelete) => {
    const { rightComponent, fields } = this.props;
    if (!rightComponent) {
      return null;
    }
    switch (rightComponent) {
      case "delete":
        if (index === 0 && fields.length > 1)
          return <div className={bem.e("right-component", "delete")} onClick={() => onDelete(arrIndex)}>X</div>
        break;
      default:
        return <div className={bem.e("right-component")}>{rightComponent}</div>;
    }

  };

  renderLeftComponent = (objKey) => {
    const { leftComponent } = this.props;
    if (!leftComponent) {
      return null;
    }
    return <div className={bem.e("left-component")}>{objKey ? objKey : leftComponent}</div>;
  };

  renderInput = (field, error, onChange, index, objKey, ObjIndex, onDelete, rest) => (
    <div style={{ marginBottom: 5 }} key={`txt_${index}_${objKey} `}>
      <div
        className={cx(bem.e("input-wrapper"), {
          invalid: Boolean(error),
          textarea: field.type === "textarea"
        })}>
        {this.renderLeftComponent(objKey)}
        <div
          className={cx(bem.e("input-field"), {
            textarea: field.type === "textarea"
          })}>
          <Input
            type={field.type}
            name={field.name}
            invalid={Boolean(field.error)}
            value={field}
            onChange={e => {
              onChange(e, index, objKey);
            }}
            {...rest}
          />
        </div>
        {this.renderRightComponent(ObjIndex, index, onDelete)}
      </div>
      {!!field.error && (
        <span className="text-danger">{field.error}</span>
      )}
    </div>)

  render() {
    const {
      label,
      labelProps,
      leftComponent,
      rightComponent,
      ableToAddNew,
      name,
      error,
      value,
      type,
      fields,
      onAddNew,
      onChange,
      onDelete,
      ...rest
    } = this.props;
    let finalFields = fields || [{ name, error, value, type }];
    return (
      <div className={bem.b()} {...rest}>
        {!!label && (
          <Label for={name} {...labelProps}>
            {label}
          </Label>
        )}
        {finalFields.map((fieldObj, index) => (
          typeof fieldObj === "object" && Object.keys(fieldObj).map((field, ObjIndex) =>
            this.renderInput(fieldObj[field], error, onChange, index, field, ObjIndex, onDelete, rest)
          )
        ))}
        {ableToAddNew && (
          <div className={bem.e("add-new-link")} onClick={onAddNew}>
            + Add New
          </div>
        )}
      </div>
    );
  }
}

export default TextInput;
