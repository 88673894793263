import * as Types from "store/types/hubs";
import { get, map, isFunction } from "lodash";
import { userInfo } from "store/actions/auth";
import history from "components/History";
import Api from "utils/api";
import * as Const from "utils/constants";
import URL from "utils/urls";
import queryString from "query-string";
import { adminTabs } from "utils/config";

export const getHubs = (state) => async (dispatch) => {
  let errorStr = "";
  try {
    const { pageSize, page, sorted, filtered } = state;
    const params = {
      page: page + 1,
      rpp: pageSize,
    };
    if (sorted.length > 0) {
      const sortedColumns = map(sorted, "id").join(",");
      params.sort = `${sortedColumns} ${
        get(sorted, `${sorted.length - 1}.desc`) ? "desc" : "asc"
      }`;
    }

    const index = filtered.findIndex((item) => item.id === "q");

    if (index !== -1) {
      params.q = filtered[index].value;
    }

    dispatch({ type: Types.FETCH_HUBS_REQUEST });

    let response = await Api.doCall(
      `${Const.GET_HUBS()}?${queryString.stringify(params)}`,
      "GET"
    );

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch(fetchHubsSuccess(response.data));
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch(fetchHubsError({ data: errorStr }));
};

export const getMoreHubs = (state) => async (dispatch) => {
  let errorStr = "";

  try {
    const { pageSize, page, sorted, filtered } = state;
    const params = {
      page: page + 1,
      rpp: pageSize,
    };
    if (sorted.length > 0) {
      const sortedColumns = map(sorted, "id").join(",");
      params.sort = `${sortedColumns} ${
        get(sorted, `${sorted.length - 1}.desc`) ? "desc" : "asc"
      }`;
    }

    const index = filtered.findIndex((item) => item.id === "q");

    if (index !== -1) {
      params.q = filtered[index].value;
    }

    dispatch({ type: Types.FETCH_MORE_HUBS_REQUEST });

    let response = await Api.doCall(
      `${Const.GET_HUBS()}?${queryString.stringify(params)}`,
      "GET"
    );

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch(fetchMoreHubsSuccess(response.data));
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch(fetchMoreHubsError({ data: errorStr }));
};

export const saveHub = (data, cb) => async (dispatch) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.SAVE_HUB_REQUEST });

    let response = await Api.doCall(Const.GET_HUBS(), "POST", data);

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({ type: Types.SAVE_HUB_SUCCESS, payload: response.data });
        if (isFunction(cb)) {
          cb();
        }
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({ type: Types.SAVE_HUB_ERROR, payload: errorStr });
};

export const getHub = (id) => async (dispatch) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.FETCH_HUB_REQUEST, payload: { id } });
    let response = await Api.doCall(Const.GET_HUB(id), "GET");

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch(fetchHubSuccess(response.data));
        return;
      }

      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch(fetchHubError({ id, error: errorStr }));
};

export const updateHub = (id, data, cb) => async (dispatch) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.UPDATE_HUB_REQUEST });

    let response = await Api.doCall(Const.GET_HUB(id), "PUT", data);

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.UPDATE_HUB_SUCCESS,
          payload: response.data,
        });

        dispatch(userInfo());

        if (isFunction(cb)) {
          cb();
        }

        return;
      }

      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({ type: Types.UPDATE_HUB_ERROR, payload: errorStr });
};

export const deleteHub = (id) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.DELETE_HUB_REQUEST });
    let response = await Api.doCall(Const.GET_HUB(id), "DELETE");
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({ type: Types.DELETE_HUB_SUCCESS });
        // dispatch(userInfo());
        history.push(
          URL.ADMINS({
            tab: adminTabs.TAB_HUBS,
          })
        );
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.DELETE_HUB_ERROR, payload: errorStr });
};

export function fetchHubsSuccess(payload) {
  return {
    type: Types.FETCH_HUBS_SUCCESS,
    payload,
  };
}

export function fetchHubsError(payload) {
  return {
    type: Types.FETCH_HUBS_ERROR,
    payload,
  };
}

export function fetchMoreHubsSuccess(payload) {
  return {
    type: Types.FETCH_MORE_HUBS_SUCCESS,
    payload,
  };
}

export function fetchMoreHubsError(payload) {
  return {
    type: Types.FETCH_MORE_HUBS_ERROR,
    payload,
  };
}

export function fetchHubSuccess(payload) {
  return {
    type: Types.FETCH_HUB_SUCCESS,
    payload,
  };
}

export function fetchHubError(payload) {
  return {
    type: Types.FETCH_HUB_ERROR,
    payload,
  };
}

export const getAssignedHubs = () => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.FETCH_ASSIGNED_HUBS_REQUEST });
    const params = {
      rpp: 1000,
    };
    const response = await Api.doCall(
      `${Const.GET_HUBS()}?${queryString.stringify(params)}`,
      "GET"
    );

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.FETCH_ASSIGNED_HUBS_SUCCESS,
          payload: response.data,
        });
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({
    type: Types.FETCH_ASSIGNED_HUBS_ERROR,
    payload: errorStr,
  });
};

export const getHubAssignedStations = (payload) => async (dispatch) => {
  let errorStr = "";

  try {
    const { pageSize, page, sorted } = payload;
    const params = {
      page: page + 1,
      rpp: pageSize,
    };
    if (sorted.length > 0) {
      const sortedColumns = map(sorted, "id").join(",");
      params.sort = `${sortedColumns} ${
        get(sorted, `${sorted.length - 1}.desc`) ? "desc" : "asc"
      }`;
    }

    dispatch({ type: Types.FETCH_HUB_ASSIGNED_STATIONS_REQUEST });
    let response = await Api.doCall(
      `${Const.GET_HUBS()}/${payload.id}/stations?${queryString.stringify(
        params
      )}`,
      "GET"
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.FETCH_HUB_ASSIGNED_STATIONS_SUCCESS,
          payload: response.data,
        });
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.FETCH_HUB_ASSIGNED_STATIONS_ERROR,
    payload: errorStr,
  });
};

export const getHubAssignedUsers = (payload) => async (dispatch) => {
  let errorStr = "";

  try {
    const { pageSize, page, sorted } = payload;
    const params = {
      page: page + 1,
      rpp: pageSize,
    };
    if (sorted.length > 0) {
      const sortedColumns = map(sorted, "id").join(",");
      params.sort = `${sortedColumns} ${
        get(sorted, `${sorted.length - 1}.desc`) ? "desc" : "asc"
      }`;
    }

    dispatch({ type: Types.FETCH_HUB_ASSIGNED_USERS_REQUEST });
    let response = await Api.doCall(
      `${Const.GET_HUBS()}/${payload.id}/members?${queryString.stringify(
        params
      )}`,
      "GET"
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.FETCH_HUB_ASSIGNED_USERS_SUCCESS,
          payload: response.data,
        });
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.FETCH_HUB_ASSIGNED_USERS_ERROR,
    payload: errorStr,
  });
};

export const updateHubAssignedStations = (payload, cb) => async (dispatch) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.UPDATE_HUB_ASSIGNED_STATIONS_REQUEST });
    const response = await Api.doCall(
      `${Const.GET_HUBS()}/${payload.id}/assign_stations`,
      "POST",
      payload
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.UPDATE_HUB_ASSIGNED_STATIONS_SUCCESS,
          payload: response.data,
        });
        if (cb) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.UPDATE_HUB_ASSIGNED_STATIONS_ERROR,
    payload: errorStr,
  });
};

export const updateHubAssignedUsers = (payload, cb) => async (dispatch) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.UPDATE_HUB_ASSIGNED_USERS_REQUEST });
    const response = await Api.doCall(
      `${Const.GET_HUBS()}/${payload.id}/assign_members`,
      "POST",
      payload
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.UPDATE_HUB_ASSIGNED_USERS_SUCCESS,
          payload: response.data,
        });
        if (cb) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.UPDATE_HUB_ASSIGNED_USERS_ERROR,
    payload: errorStr,
  });
};

export const updateOrderAssignments = (payload, cb) => async (dispatch) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.UPDATE_HUB_ORDER_ASSIGNMENTS_REQUEST });
    const response = await Api.doCall(
      `${Const.GET_HUBS()}/order_assignments`,
      "POST",
      payload
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.UPDATE_HUB_ORDER_ASSIGNMENTS_SUCCESS,
          payload: response.data,
        });
        if (cb) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.UPDATE_HUB_ORDER_ASSIGNMENTS_ERROR,
    payload: errorStr,
  });
};

export const getHubUsers = (params = {}) => async (dispatch) => {
  let errorStr = "";

  try {
    const payload = queryString.stringify({
      enterprise_users: 1,
      rpp: 1000,
      ...params,
    });
    console.log("params", params);
    dispatch({ type: Types.FETCH_HUB_USERS_REQUEST });
    const response = await Api.doCall(`${Const.GET_USERS()}?${payload}`, "GET");
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.FETCH_HUB_USERS_SUCCESS,
          payload: get(response, "data.data", []),
        });
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.FETCH_HUB_USERS_REQUEST,
    payload: errorStr,
  });
};

export const getTeamManagement = (payload, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    const { pageSize, page, sorted, filtered, roles, hubs } = payload;
    const params = {
      page,
      rpp: pageSize,
      roles,
      hubs,
    };
    if (sorted.length > 0) {
      const sortedColumns = map(sorted, "id").join(",");
      params.sort = `${sortedColumns} ${
        get(sorted, `${sorted.length - 1}.desc`) ? "desc" : "asc"
      }`;
    }

    const index = filtered.findIndex((item) => item.id === "q");

    if (index !== -1) {
      params.q = filtered[index].value;
    }

    dispatch({ type: Types.FETCH_TEAM_MANAGEMENT_REQUEST });

    let response = await Api.doCall(
      `${Const.GET_HUBS()}/team?${queryString.stringify(params, {
        arrayFormat: "bracket",
      })}`,
      "GET"
    );

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.FETCH_TEAM_MANAGEMENT_SUCCESS,
          payload: response.data,
        });
        if (cb) cb();
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.FETCH_TEAM_MANAGEMENT_ERROR,
    payload: errorStr,
  });
};

export const getTeamMembers = (payload) => async (dispatch) => {
  let errorStr = "";
  const { sorted, stations, all_members } = payload;
  const params = {
    stations,
    all_members,
  };
  if (sorted.length > 0) {
    const sortedColumns = map(sorted, "id").join(",");
    params.sort = `${sortedColumns} ${
      get(sorted, `${sorted.length - 1}.desc`) ? "desc" : "asc"
    }`;
  }

  dispatch({ type: Types.FETCH_HUB_MEMBERS_REQUEST });

  let response = await Api.doCall(
    `${Const.GET_HUBS()}/team?${queryString.stringify(params, {
      arrayFormat: "bracket",
    })}`,
    "GET"
  );

  if (response.status === 200 || response.status === 201) {
    if (!response.data.error) {
      dispatch({
        type: Types.FETCH_HUB_MEMBERS_SUCCESS,
        payload: response.data,
      });
      return;
    }
    errorStr = response.data.error;
  }
  try {
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.FETCH_HUB_MEMBERS_ERROR,
    payload: errorStr,
  });
};

export const getTeamMembersForAssignment = (payload) => async (dispatch) => {
  let errorStr = "";
  const { sorted, stations, all_members } = payload;
  const params = {
    stations,
    all_members,
  };
  if (sorted.length > 0) {
    const sortedColumns = map(sorted, "id").join(",");
    params.sort = `${sortedColumns} ${
      get(sorted, `${sorted.length - 1}.desc`) ? "desc" : "asc"
    }`;
  }

  dispatch({ type: Types.FETCH_HUB_MEMBERS_ASSIGNMENT_REQUEST });

  let response = await Api.doCall(
    `${Const.GET_HUBS()}/team?${queryString.stringify(params, {
      arrayFormat: "bracket",
    })}`,
    "GET"
  );

  if (response.status === 200 || response.status === 201) {
    if (!response.data.error) {
      dispatch({
        type: Types.FETCH_HUB_MEMBERS_ASSIGNMENT_SUCCESS,
        payload: response.data,
      });
      return;
    }
    errorStr = response.data.error;
  }
  try {
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.FETCH_HUB_MEMBERS_ASSIGNMENT_ERROR,
    payload: errorStr,
  });
};

export const getCurrentOrdersByTeam = (params = {}) => async (dispatch) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.FETCH_CURRENT_ORDERS_BY_TEAM_REQUEST });
    const payload = {
      team_member_id: params.id,
      rpp: params.rpp,
    };
    const response = await Api.doCall(
      `${Const.GET_PROCESSES("my_action_items")}?${queryString.stringify(
        payload
      )}`,
      "GET"
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.FETCH_CURRENT_ORDERS_BY_TEAM_SUCCESS,
          payload: get(response, "data.items", []),
        });
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.FETCH_CURRENT_ORDERS_BY_TEAM_ERROR,
    payload: errorStr,
  });
};

export const getAssignedOrdersByTeam = (params = {}) => async (dispatch) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.FETCH_ASSIGNED_ORDERS_BY_TEAM_REQUEST });
    const payload = {
      team_member_id: params.id,
      rpp: params.rpp,
    };
    const response = await Api.doCall(
      `${Const.GET_PROCESSES("my_upcoming_items")}?${queryString.stringify(
        payload
      )}`,
      "GET"
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.FETCH_ASSIGNED_ORDERS_BY_TEAM_SUCCESS,
          payload: get(response, "data.items", []),
        });
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.FETCH_ASSIGNED_ORDERS_BY_TEAM_ERROR,
    payload: errorStr,
  });
};
