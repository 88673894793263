import { connect } from "react-redux";
import {
  get,
  filter,
  includes,
  orderBy,
  split,
  last,
  forEach,
  uniq,
  isEqual,
} from "lodash";
import { compose, withState, defaultProps, withPropsOnChange, lifecycle } from "recompose";
import SelectTags, {
  TAG_TYPE_ALL,
  TAG_TYPE_LOCATION,
  TAG_TYPE_FORMAT,
  TAG_TYPE_DMAs,
  TAG_TYPE_EXCEPT_LOCATION_FORMAT,
  TAG_TYPE_EXCEPT_OTHER,
} from "./view";
export {
  TAG_TYPE_ALL,
  TAG_TYPE_LOCATION,
  TAG_TYPE_FORMAT,
  TAG_TYPE_DMAs,
  TAG_TYPE_EXCEPT_OTHER,
  TAG_TYPE_EXCEPT_LOCATION_FORMAT,
};
function getValidMarketIds(authInfo) {
  let { stations } = authInfo;
  stations = filter(stations, (station) => {
    return includes(station.products, "production") || 
            includes(station.products, "digital") || 
            includes(station.products, "promotion");
  });
  let marketIds = [];
  forEach(stations, (station) => {
    const markets = station.market
      ? Object.keys(station.market).map(Number)
      : [];
    marketIds = [...marketIds, ...markets];
  });
  return uniq(marketIds);
}
function generateTags(props) {
  const { auth, type, isRemovePrefixNumber, isFilterBroadcaster } = props;
  let tagOptions = [];
  let tags = get(auth, "info.tags.tags", []);
  let showRoots = true;
  switch (type) {
    case TAG_TYPE_LOCATION:
      tags = filter(tags, (tag) => includes(tag, "Location"));
      showRoots = false;
      break;
    case TAG_TYPE_FORMAT:
      tags = filter(tags, (tag) => includes(tag, "Format"));
      showRoots = false;
      break;
    case TAG_TYPE_DMAs:
      tags = filter(tags, (tag) => includes(tag, "DMAs"));
      showRoots = false;
      break;
    case TAG_TYPE_EXCEPT_LOCATION_FORMAT:
      tags = filter(
        tags,
        (tag) => !includes(tag, "Format") && !includes(tag, "Location")
      );
      break;
    case TAG_TYPE_EXCEPT_OTHER:
      tags = filter(
        tags,
        (tag) =>
          !includes(tag, "Format") &&
          !includes(tag, "Location") &&
          !includes(tag, "DMAs")
      );
      break;
    default:
      break;
  }
  if (!tags) tags = [];

  let map_tags = (tag, path, root) => {
    if (!root) root = tag;
    if (path) {
      if (
        root.title === "Location" &&
        tag.children &&
        tag.children.length > 0
      ) {
        // just a state
      } else {
        tagOptions.push({
          value: tag.id,
          label: path,
        });
      }
    }
    tag.children &&
      tag.children.map((child) => {
        let childPath = path ? path + " / " + child.title : child.title;
        if (props.isHideRootPath && includes(["Location", "Format"], path)) {
          childPath = path ? child.title : child.title;
        }
        map_tags(child, childPath, root);
      });
  };
  tags.map((tag) => {
    if (tag) {
      map_tags(tag, showRoots && tag.title !== "Location" ? tag.title : false);
    }
  });
  if (isRemovePrefixNumber) {
    tagOptions = tagOptions.map(({ label, value }) => {
      const tempArr2 = split(label, /\d+\. /);
      const newLabel = last(tempArr2); // remove prefix number for sort by alphabetically
      return {
        label: newLabel,
        value,
      };
    });
  }
  if (isFilterBroadcaster) {
    const validMarketIds = getValidMarketIds(auth.info);
    tagOptions = tagOptions.filter((item) =>
      includes(validMarketIds, item.value)
    );
  }

  let options = orderBy(tagOptions, ["label"], ["asc"]);
  if (props.allowSelectAll && tagOptions.length > 1) {
    options = [props.allOption, ...tagOptions];
  }
  return options;
}
const Enhanced = compose(
  connect(
    (state, props) => ({
      ...props,
      auth: state.auth,
      allTags: get(state, "auth.info.tags.tags", []),
    }),
    {}
  ),
  defaultProps({
    allowSelectAll: false,
    allOption: {
      label: "Select All",
      value: "*",
    },
    name: "tags",
    isMulti: true,
    isHideRootPath: false,
    closeMenuOnSelect: true,
    type: TAG_TYPE_ALL,
    isFilterBroadcaster: false,
  }),
  withState("isMenuOpen", "setIsMenuOpen", false),
  withState("currentSelected", "setCurrentSelected", null),
  withState("typeTag", "setTypeTag", (props) => props.type),
  withState("tags", "setTags", (props) => generateTags(props)),
  withPropsOnChange(["allTags"], (props) => props.setTags(generateTags(props))),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (!isEqual(prevProps.auth, this.props.auth)) {
        this.props.setTags(generateTags(this.props));
      }
    },
  })
)(SelectTags);
export default Enhanced;
