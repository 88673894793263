import { connect } from "react-redux";
import View from "./view";
import { injectIntl } from "react-intl";
import { compose, lifecycle, withState } from "recompose";
import { get } from "lodash";
import { setActiveEditTab } from "store/actions/processes";
import history from "components/History";
const Enhanced = compose(
  connect(
    (state) => {
      const filter = "prizes_list";
      return {
        auth: state.auth,
        isLoading: get(state, `prizes.prizes.filter_${filter}.loading`, false),
      };
    },
    { setActiveEditTab }
  ),
  withState("isOpenDetails", "setIsOpenDetails", ({ process }) => {
    const selectedTheme = get(process, "data.fields.theme.value", "");
    const isQualifyingGrandPrizeTheme =
      selectedTheme === "qualifying_with_grand_prize";
    return isQualifyingGrandPrizeTheme ? "grand_prizes" : "prize_package";
  }),
  lifecycle({
    componentDidMount() {
      const { process, template } = this.props;
      const prizes = get(process, "data.fields.prizes.value", []);
      const processKey = get(process, "data.fields.key.value", "");

      // In the prizes tab, if there is no prize selected , we should start with EDIT mode.
      if (
        prizes.length === 0 &&
        document.location.href.indexOf("/processes/edit/") === -1
      ) {
        setTimeout(() => {
          history.push("/processes/edit/" + template.key + "/" + processKey);
          this.props.setActiveEditTab("prizes");
        }, 200);
      }
    },
  })
)(View);

export default injectIntl(Enhanced);
