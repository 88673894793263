import React from "react";
import bn from "utils/bemnames";
import { Row, Col, Button } from "reactstrap";
import { map, first, get, uniqBy, forEach, find, uniq, toLower } from "lodash";
import ConfirmationModal from "components/ConfirmationModal";
import ManagePrivilegesModal from "components/ManagePrivilegesModal";
import classnames from "classnames";
import { CloseIcon } from "components/CustomIcons";
import ToggleSwitch from "components/ToggleSwitch";
import { FormattedMessage } from "react-intl";
import Dropdown from "components/Dropdown";
import PrivilegedComponent from "components/PrivilegedComponent";
import { PRIVILEGES } from "utils/constants";
import { isSameSelectedStations } from "../FormAssignments";
import { FaPlus } from "react-icons/lib/fa";

const bem = bn.create("user-assignments");

function getValidPrivileges(stations, authStations) {
  let validPrivileges = [];
  forEach(stations, (station) => {
    if (station) {
      const _station = authStations.find((item) => item.key === station.key);
      const stationPrivileges = Object.keys(_station.station_privileges).map(
        Number
      );
      validPrivileges = [...validPrivileges, ...stationPrivileges];
    }
  });
  return uniq(validPrivileges);
}
function EntityData(props) {
  const { formProps, auth, setShouldBlockNavigation } = props;
  const authStations = get(auth, "info.stations", []);
  const stations = formProps.values.stations;
  const isSeparately = get(formProps, "values.is_separately");
  const stationsWithRoles = !isSeparately ? [first(stations)] : stations;
  const allRolesOptions = get(auth, "info.roles", []).map((item) => ({
    value: parseInt(item.id),
    label: item.name,
    market_id: item.market_id,
    privileges: Object.keys(item.privileges).map((privilegeId) => ({
      value: parseInt(privilegeId),
      label: item.privileges[privilegeId],
    })),
  }));
  const authIsGodAdmin = get(auth, "user.is_god_admin", false);
  const authIsSupportAdmin = get(auth, "user.is_support_admin", false);
  return (
    <div className={bem.e("entity-data")}>
      <div className={bem.e("selected-stations")}>
        {map(stations, (item, index) => {
          let stationArr = [];
          if (get(item, "call_letters", "")) stationArr.push(item.call_letters);
          if (get(item, "name", "")) stationArr.push(item.name);
          return (
            <div key={index} className={bem.e("item-selected")}>
              <span
                className={bem.e("pointer")}
                onClick={() => {
                  props.setIsOpenConfirmationModal(true);
                  props.setCurrentEntity(item);
                }}
              >
                <CloseIcon />
              </span>
              <span
                className={classnames(
                  bem.e("item-selected-label"),
                  bem.e("station-name")
                )}
              >
                {stationArr.join(" - ")}
              </span>
            </div>
          );
        })}
      </div>
      <div className={bem.e("switch-apply")}>
        <ToggleSwitch
          leftComponent={
            <span
              className={classnames(bem.e("switch-label"), {
                [bem.e("switch-label-active")]: !isSeparately,
              })}
            >
              <FormattedMessage id="user > apply to all" />
            </span>
          }
          rightComponent={
            <span
              className={classnames(bem.e("switch-label"), {
                [bem.e("switch-label-active")]: isSeparately,
              })}
            >
              <FormattedMessage id="user > assign separately" />
            </span>
          }
          switchProps={{
            onChange: (checked) => {
              if (!checked) {
                const isStationsConflicts = isSameSelectedStations(stations);
                if (isStationsConflicts.length === 0) {
                  props.setIsOpenConfirmationApplyToAllModal(true);
                } else {
                  props.setStationsConflicts(isStationsConflicts);
                }
              } else {
                formProps.setFieldValue("is_separately", checked);
                setShouldBlockNavigation(true);
              }
            },
            checked: isSeparately,
            disabled: false,
            uncheckedIcon: false,
            checkedIcon: false,
            offColor: "#795AFA",
            onColor: "#795AFA",
            handleDiameter: 22,
            width: 50,
            height: 26,
          }}
        />
      </div>
      <div className={bem.e("assigned-roles")}>
        <div className={bem.e("small-title")}>
          <FormattedMessage id="user > roles" />
        </div>
        {map(stationsWithRoles, (item, index) => {
          const rolesOptions = allRolesOptions.filter((i) => {
            return (
              (!i.market_id || (item.market && item.market[i.market_id])) &&
              toLower(i.label) !== toLower("Hub Manager")
            );
          });
          let stationName = [];
          if (item.call_letters) {
            stationName.push(item.call_letters);
          }
          if (item.name) {
            stationName.push(item.name);
          }
          return (
            <div key={index} className={bem.e("select-roles")}>
              {isSeparately && (
                <p className={bem.e("role-station-name")}>
                  {stationName.join(" - ")}
                </p>
              )}
              <Dropdown
                placeholder={props.intl.formatMessage({
                  id: "user > select roles",
                })}
                name={`roles_${index}`}
                isMulti
                allowSelectAll
                onChange={(selectedOption, meta) => {
                  let stationsData = get(formProps, "values.stations", []);
                  let stationData = stationsData[index];
                  let privileges = stationData.privileges;
                  switch (meta.action) {
                    case "remove-value":
                      // remove privileges of role from privileges
                      let validPrivilegesWithSelectedRoles = [];
                      // get valid privileges for selected roles
                      forEach(selectedOption, (role) => {
                        validPrivilegesWithSelectedRoles = [
                          ...validPrivilegesWithSelectedRoles,
                          ...role.privileges,
                        ];
                      });
                      validPrivilegesWithSelectedRoles = uniqBy(
                        validPrivilegesWithSelectedRoles,
                        "value"
                      );
                      // remove privileges when privilege is not exists in selected roles
                      privileges = privileges.filter((privilegeItem) => {
                        return find(
                          validPrivilegesWithSelectedRoles,
                          (validItem) => validItem.value === privilegeItem.value
                        );
                      });
                      break;
                    case "clear":
                      // remove all privileges when clear all roles
                      privileges = [];
                      break;
                    case "select-option":
                      // assign all privileges of new role to station
                      // for select all
                      if (meta.option.value === "*") {
                        privileges = []; // reset
                        // get all privileges
                        forEach(rolesOptions, (role) => {
                          privileges = [...privileges, ...role.privileges];
                        });
                      } else {
                        privileges = [...privileges, ...meta.option.privileges];
                      }
                      break;
                    default:
                      break;
                  }
                  // ensure remove duplicate
                  privileges = uniqBy(privileges, "value");
                  const isSeparately = get(
                    props,
                    "formProps.values.is_separately"
                  );
                  if (!isSeparately) {
                    stationsData = stationsData.map((station) => ({
                      ...station,
                      roles: selectedOption,
                      privileges,
                    }));
                  } else {
                    // assign roles + privileges to station
                    stationData = {
                      ...stationData,
                      roles: selectedOption,
                      privileges,
                    };
                    stationsData.splice(index, 1, stationData);
                  }
                  formProps.setFieldValue("stations", stationsData);
                  setShouldBlockNavigation(true);
                }}
                value={item.roles}
                options={rolesOptions}
                closeMenuOnSelect={false}
                error={
                  get(formProps, `touched.stations.${index}.roles`) &&
                  get(formProps, `errors.stations.${index}.roles`)
                }
              />
              {/*<PrivilegedComponent
                requires={{
                  or: [
                    PRIVILEGES.ASSIGN_PRIVILEGES,
                    PRIVILEGES.REVOKE_PRIVILEGES,
                  ],
                }}
              >*/}
              {(authIsGodAdmin || authIsSupportAdmin) && (
                <div
                  className={bem.e("manage-privileges")}
                  onClick={() => {
                    props.setIsOpenManagePrivilegesModal(true);
                    props.setCurrentEntity(item);
                    let selectedStations = [item];
                    if (!isSeparately) {
                      selectedStations = stations;
                    }
                    const newValidPrivileges = getValidPrivileges(
                      selectedStations,
                      authStations
                    );
                    props.setValidPrivileges(newValidPrivileges);
                  }}
                >
                  <FormattedMessage id="user > manage privileges" />
                </div>
              )}
              {/*</PrivilegedComponent>*/}
              <div className="text-danger">
                {get(formProps, `touched.stations.${index}.privileges`) &&
                  get(formProps, `errors.stations.${index}.privileges`)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default function EntityAssignments(props) {
  const { isView, user, setShouldBlockNavigation } = props;

  const renderView = () => {
    const stations = get(user, "stations", []);
    const isEmptyEntities = stations.length === 0;
    return (
      <Row>
        <Col xs={12} className={bem.e("col-reset")}>
          <h2 className={bem.e("assignment-title")}>
            <FormattedMessage id="user > entity assignments" />
          </h2>
          <div className={bem.e("list")}>
            {isEmptyEntities && (
              <p className={bem.e("none-text")}>
                <FormattedMessage id="user > none" />
              </p>
            )}
            {!isEmptyEntities && (
              <Row>
                <Col xs={10}>
                  {map(stations, (item, index) => (
                    <Row key={index}>
                      <Col xs={3} className={bem.e("no-margin")}>
                        <div className={bem.e("entity-label-view")}>
                          {item.call_letters}
                        </div>
                      </Col>
                      <Col xs={3} className={bem.e("no-margin")}>
                        <div className={bem.e("entity-label-view")}>
                          {item.name}
                        </div>
                      </Col>
                      <Col xs={4} className={bem.e("no-margin")}>
                        <div className={bem.e("entity-label-view")}>
                          {map(item.user_roles, (role) => role).join(", ")}
                        </div>
                      </Col>
                    </Row>
                  ))}
                </Col>
              </Row>
            )}
          </div>
        </Col>
      </Row>
    );
  };

  const renderForm = () => {
    const stations = get(props, "formProps.values.stations", []);
    const isEmptyEntities = stations.length === 0;
    const isDisableAssign = !get(props, "user.id");
    return (
      <div className={bem.e("team-form")}>
        <div className={bem.e("head-form")}>
          <h2
            className={classnames(
              bem.e("assignment-title"),
              bem.e("individual-title")
            )}
          >
            <FormattedMessage id="user > individual entity assignments" />
          </h2>
          {/* {!props.productionMultimarket &&
            !props.digitalMultimarket &&
            !props.promotionMultimarket && ( */}
          <PrivilegedComponent requires={[PRIVILEGES.EDIT_USERS]}>
            <Button
              color=""
              disabled={isDisableAssign}
              onClick={() => props.setIsOpenAssignEntitiesModal(true)}
              className={classnames(
                bem.e("btn-assign"),
                "btn btn-outline-blue btn-radius"
              )}
            >
              <FaPlus color="#795AFA" size={20} />
              <FormattedMessage id="user > assignment" />
            </Button>
          </PrivilegedComponent>
          {/* )} */}
        </div>
        <Row>
          <Col xs={12} className={bem.e("col-reset")}>
            <div className={bem.e("list")}>
              {isEmptyEntities && (
                <div>
                  <h2 className={bem.e("small-title")}>
                    <FormattedMessage id="user > entities" />
                  </h2>
                  <p className={bem.e("none-large-text")}>
                    <FormattedMessage id="user > no entities to show" />
                  </p>
                </div>
              )}
              {!isEmptyEntities && (
                <EntityData
                  formProps={props.formProps}
                  intl={props.intl}
                  setCurrentEntity={props.setCurrentEntity}
                  setIsOpenManagePrivilegesModal={
                    props.setIsOpenManagePrivilegesModal
                  }
                  setIsOpenConfirmationModal={props.setIsOpenConfirmationModal}
                  setIsOpenConfirmationApplyToAllModal={
                    props.setIsOpenConfirmationApplyToAllModal
                  }
                  auth={props.auth}
                  setValidPrivileges={props.setValidPrivileges}
                  setStationsConflicts={props.setStationsConflicts}
                  stationsConflicts={props.stationsConflicts}
                  setShouldBlockNavigation={setShouldBlockNavigation}
                />
              )}
            </div>
          </Col>
        </Row>

        <ManagePrivilegesModal
          title={get(props, "currentEntity.name", "")}
          station={props.currentEntity}
          validPrivileges={props.validPrivileges}
          isOpen={props.isOpenManagePrivilegesModal}
          onToggle={() =>
            props.setIsOpenManagePrivilegesModal(
              !props.isOpenManagePrivilegesModal
            )
          }
          onCancel={() => {
            props.setIsOpenManagePrivilegesModal(false);
            props.setCurrentEntity(null);
          }}
          onConfirm={(newPrivileges) => {
            const isSeparately = get(props, "formProps.values.is_separately");
            let stationsData = props.formProps.values.stations;
            const index = stationsData.findIndex(
              (item) => item.id === props.currentEntity.id
            );
            if (!isSeparately) {
              stationsData = stationsData.map((station) => ({
                ...station,
                privileges: newPrivileges,
              }));
            }
            {
              let stationData = {
                ...stationsData[index],
                privileges: newPrivileges,
              };
              stationsData.splice(index, 1, stationData);
            }

            props.formProps.setFieldValue("stations", stationsData);
            props.setIsOpenManagePrivilegesModal(false);
            setShouldBlockNavigation(true);
          }}
        />
        {/* confirmation switch apply to all */}
        <ConfirmationModal
          centered
          isOpen={props.isOpenConfirmationApplyToAllModal}
          className={bem.e("confirmation-modal")}
          title={
            <FormattedMessage id="user > title are you sure you want to apply to all" />
          }
          confirmTitle={<FormattedMessage id="user > button yes" />}
          cancelTitle={<FormattedMessage id="user > button cancel" />}
          onConfirm={() => {
            props.setIsOpenConfirmationApplyToAllModal(false);
            props.formProps.setFieldValue("is_separately", false);
            setShouldBlockNavigation(true);
            let defaultRoles = [];
            let defaultPrivileges = [];
            // assign roles + privileges first station to all
            const firstStation = first(stations);
            defaultRoles = get(firstStation, "roles", []);
            defaultPrivileges = get(firstStation, "privileges", []);
            const newStations = stations.map((item) => ({
              ...item,
              roles: defaultRoles,
              privileges: defaultPrivileges,
            }));
            props.formProps.setFieldValue("stations", newStations);
            setShouldBlockNavigation(true);
          }}
          onCancel={() => {
            props.setIsOpenConfirmationApplyToAllModal(false);
          }}
          onToggle={() =>
            props.setIsOpenConfirmationApplyToAllModal(
              !props.isOpenConfirmationApplyToAllModal
            )
          }
          isCloseOutside={false}
        />
        {/* confirmation remove assignment */}
        <ConfirmationModal
          centered
          isOpen={props.isOpenConfirmationModal}
          className={bem.e("confirmation-modal")}
          title={
            <FormattedMessage id="user > title are you sure you want to remove this assignment" />
          }
          confirmTitle={<FormattedMessage id="user > button yes" />}
          cancelTitle={<FormattedMessage id="user > button cancel" />}
          onConfirm={() => {
            props.setIsOpenConfirmationModal(false);
            const newStations = stations.filter(
              (item) => item.key !== props.currentEntity.key
            );
            props.formProps.setFieldValue("stations", newStations);
            setShouldBlockNavigation(true);
          }}
          onCancel={() => {
            props.setIsOpenConfirmationModal(false);
          }}
          onToggle={() =>
            props.setIsOpenConfirmationModal(!props.isOpenConfirmationModal)
          }
          isCloseOutside={false}
        />
        {/* alert stations conflicts */}
        <ConfirmationModal
          centered
          isOpen={!!props.stationsConflicts}
          className={bem.e("conflict-stations-modal")}
          title={
            <div>
              <FormattedMessage id="user > title station conflicts" />
              <div className={bem.e("stations")}>
                <div>
                  <FormattedMessage id="user > stations" />:
                </div>
                <div className={bem.e("stations-conflicts")}>
                  {map(props.stationsConflicts, (item) => item.name).join(", ")}
                </div>
              </div>
            </div>
          }
          confirmTitle={<FormattedMessage id="process > button ok" />}
          cancelTitle={null}
          onConfirm={() => {
            props.setStationsConflicts(null);
          }}
          onCancel={() => {
            props.setStationsConflicts(null);
          }}
          onToggle={() => props.setStationsConflicts(null)}
          isCloseOutside={false}
        />
      </div>
    );
  };
  return (
    <div className={bem.e("team-assignments")}>
      {isView ? renderView() : renderForm()}
    </div>
  );
}
