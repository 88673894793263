import * as React from "react";
import { compose, withState, lifecycle, withHandlers } from "recompose";
import { connect } from "react-redux";
import StationsDetails, { TAB_INFO, TAB_PRIVILEGES } from "./view";
import { injectIntl } from "react-intl";
import { MdImportantDevices } from "react-icons/lib/md";
import { MODE_ADD as STATION_FORM_MODE_ADD } from "components/StationForm";
import ToastManager from "components/ToastManager";

import { updatePrivilegesStations, getStation } from "store/actions/stations";
import { get, isEqual, size, first } from "lodash";

export default injectIntl(
  compose(
    connect(
      state => ({
        auth: state.auth,
        isLoadingPrivileges: get(
          state,
          "stations.updateStations.loading",
          false
        ),
        station: state.stations.station
      }),
      {
        updatePrivilegesStations,
        getStation
      }
    ),
    withState("activeTab", "setActiveTab", props => {
      if (props.isView === STATION_FORM_MODE_ADD) return TAB_INFO;
      return size(get(props, "lists")) === 1 ? TAB_INFO : TAB_PRIVILEGES;
    }),
    withHandlers({
      onSubmitPrivileges: ({
        lists,
        intl,
        updatePrivilegesStations,
        getStation
      }) => (privilegesAdd, privilegesRemove) => {
        updatePrivilegesStations(
          {
            ids: Object.keys(lists),
            list_add: privilegesAdd,
            list_remove: privilegesRemove
          },
          () => {
            ToastManager.show({
              title: <MdImportantDevices />,
              message: intl.formatMessage({
                id: "station > update successfully"
              }),
              level: "success"
            });
            if (size(Object.keys(lists)) === 1) {
              getStation(first(Object.keys(lists)));
            }
          }
        );
      }
    }),
    lifecycle({
      componentWillReceiveProps(nextProps) {
        if (
          !isEqual(get(this.props, "lists", []), get(nextProps, "lists", []))
        ) {
          let activeTab;
          if (nextProps.isView === STATION_FORM_MODE_ADD) {
            activeTab = TAB_INFO;
          } else {
            activeTab =
              size(get(nextProps, "lists")) === 1 ? TAB_INFO : TAB_PRIVILEGES;
          }
          this.props.setActiveTab(activeTab);
        }
      }
    })
  )(StationsDetails)
);
