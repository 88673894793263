import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import PrizeForm from "components/PrizeForm";
import Spinner from "components/Spinner";

import bn from "utils/bemnames";
const bem = bn.create("add-prize-modal");

class AddPrizeModal extends React.Component {
  render() {
    const {
      isOpen,
      onToggle,
      isLoading,
      isSplitForm,
      initialStations,
      onSubmitted,
    } = this.props;
    return (
      <>
        <Modal
          isOpen={isOpen}
          className={classnames(bem.b(), { "is-split-form": isSplitForm })}
          size="lg"
        >
          <ModalHeader toggle={onToggle} className={bem.e("header")}>
            <FormattedMessage id="prize > new prize" />
          </ModalHeader>
          <ModalBody className={bem.e("body")}>
            <PrizeForm
              isModal={true}
              isLoading={isLoading}
              isSplitForm={isSplitForm}
              onCloseModal={onToggle}
              initialStations={initialStations}
              onSubmitted={onSubmitted}
            />
          </ModalBody>
        </Modal>
        <Spinner isLoading={isLoading} />
      </>
    );
  }
}
AddPrizeModal.propTypes = {
  onToggle: PropTypes.func.isRequired,
  onSubmitted: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  isOpenAlert: PropTypes.bool.isRequired,
  isSplitForm: PropTypes.bool.isRequired,
};
AddPrizeModal.defaultProps = {
  isOpen: false,
  isOpenAlert: false,
  isSplitForm: false,
  onToggle: () => {},
  onSubmitted: () => {},
};
export default AddPrizeModal;
