import classNames from "classnames";
import React from "react";
const SelectionBoxText = (props) => {
  const { bem, intl, text } = props;
  return (
    <div
      className={classNames(
        bem.e("selection-box"),
        bem.e("selection-box-text")
      )}
    >
      <strong>{intl.formatMessage({ id: "station settings > if" })}</strong>
      {` `}
      {text}
    </div>
  );
};
export default SelectionBoxText;
