import { FormattedMessage } from "react-intl";
import React from "react";
const linerSchedule = {
  process_step_index: 3,
  key: "liner_schedule",
  caption_color: "#CC1EE6",
  title: <FormattedMessage id="process > title event liner schedule" />,
  step_roles: [1, 2, 3],
  step_viewers_roles: [1, 2, 3, 4, 7],
  step_editors_roles: [1, 2, 3, 4],
  step_viewers_privileges: ["add_promotion_event_liner"],
  step_editors_privileges: ["add_promotion_event_liner"],
  description: null,
  status: "initial",
  disabled_until_completed_steps: [],
  step_container: "none",
  no_edit_button: true,
  fields: [
    {
      component: "liner_schedule",
    },
    {
      field: "liner_schedule",
      title: <FormattedMessage id="process > field prizes" />,
    },
    {
      key: "save_button",
      text: <FormattedMessage id="process > button save" />,
      on_click: {
        validate: true,
        save: true,
      },
      fields: [
        {
          key: "liner_schedule_status",
        },
        {
          key: "process_step",
          value: "done",
        },
      ],
      confirmation_dialog_props: {
        description: (
          <FormattedMessage id="process > description to submit prizes" />
        ),
        confirmTitle: <FormattedMessage id="process > button yes" />,
        cancelTitle: <FormattedMessage id="process > button no" />,
      },
      success_dialog_props: {
        title: <FormattedMessage id="process > title submitted" />,
        state: "success",
      },
    },
  ],
};
export default linerSchedule;
