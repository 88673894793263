import React from 'react';
import { Button } from "reactstrap";
import { FaSearch } from "react-icons/lib/fa";
import { MdClose } from "react-icons/lib/md";
import { FormattedMessage } from "react-intl";

import bn from "utils/bemnames";
import TextInput from "components/TextInput";
import StationsList from "./Results";
import { isEmpty } from 'lodash';


const Search = (props) => {
	
	const bem = bn.create("stations-search-view");

	const {
		intl,
		searchText,
		onChangeInput,
		onKeyDown,
		onClearSearch,
		stations,
		selectedTags,
		selectedStations,
		updateSelectedStations,
		addedStations
	} = props;

	return (
		<>
			<div className="search-header">
				<h3><FormattedMessage id="picker > search entities" /></h3>
				<TextInput
		            type="text"
		            name="q"
		            value={searchText}
	                onChange={onChangeInput}
	                onKeyDown={onKeyDown}
		            placeholder={intl.formatMessage({
		              id: "picker > enter station name or call letter",
		            })}
		            rightComponent={
		              <div className="search-buttons">
		                {!isEmpty(searchText) && (
		                  <div
		                    onClick={onClearSearch}
		                    className={"button-search-close"}
		                  >
		                    <MdClose />
		                  </div>
		                )}
		                {isEmpty(searchText) && (
		                	<Button color="link" className="btn-search" onClick={()=>{}}>
			                  <FaSearch size={17}  color="#334856" />
			                </Button>
		                )}
		              </div>
		            }
		          />
			</div>
			<div className="search-results">
				<h3><FormattedMessage id="picker > search results" /></h3>
				<div className="table-container">
					<StationsList 
		                bem={bem}
		                stations={stations}
		                selectedTags={selectedTags}
    					selectedStations={selectedStations}
    					updateSelectedStations={updateSelectedStations}
						addedStations={addedStations}
		            />
		        </div>
			</div>
		</>
	);
}

export default Search;