import { FormattedMessage } from "react-intl";
import React from "react";
import moment from "moment";
import {
  get,
  map,
  sum,
  omit,
  includes,
  forEach,
  isEmpty,
  find,
  filter,
  uniq,
} from "lodash";
import { getLocalStorageDateFormat } from "utils/dates";
import { orderLengthOptions, productionTypeOptions } from "utils/config";

const orderDetails = {
  process_step_index: 1,
  key: "order_details",
  caption_color: "#CC1EE6",
  title: <FormattedMessage id="process > title order" />,
  step_roles: [1, 2, 3],
  step_viewers_roles: [1, 2, 3, 4, 7],
  step_editors_roles: [1, 2, 3, 4],
  step_viewers_privileges: {
    or: ["add_production", "edit_all_production_orders"],
  },
  step_editors_privileges: {
    or: ["add_production", "edit_all_production_orders"],
  },
  description: null,
  can_edit_any_time: true,
  priviliges: [],
  status: "initial",
  disabled_until_completed_steps: [],
  step_container: "none",
  no_edit_button: true,
  className: "order-details-container",
  workflow: {
    title: "order entry",
    icon: "StartIcon",
  },
  is_approval_step: true,
  fields: [
    {
      component: "order_details",
    },
    {
      field: "client_name",
      title: <FormattedMessage id="process > field client name" />,
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        return value.length > 1
          ? true
          : "Advertiser name must be at least 2 letters long";
      },
    },
    {
      field: "dub_script_status",
    },
    {
      field: "continuity_status",
    },
    {
      field: "record_script_status",
    },
    {
      field: "client_id",
    },
    {
      field: "contact_id",
    },
    {
      field: "order_title",
      title: <FormattedMessage id="process > field title" />,
    },
    {
      field: "order_stations",
      title: <FormattedMessage id="process > field stations" />,
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        return value.length > 0
          ? true
          : "At least one station has to be selected";
      },
    },
    {
      field: "role_sales_person",
      title: <FormattedMessage id="process > field salesPerson" />,
      roles: [1, 2, 3],
      privileges: {
        or: ["add_production"],
      },
      default: 3,
      mandatory: true,
    },
    {
      field: "role_sales_person_2",
      title: <FormattedMessage id="process > field additional salesPerson" />,
      privileges: {
        or: ["add_production"],
      },
      roles: [1, 2, 3],
    },
    {
      field: "role_producer",
      title: <FormattedMessage id="process > field producer" />,
      roles: [5],
      privileges: {
        or: ["produce_production", "submit_production_recording"],
      },
      mandatory: false,
    },
    {
      field: "role_production_manager",
      title: <FormattedMessage id="process > field production manager" />,
      roles: [4],
      privileges: {
        or: ["assign_production_team"],
      },
      mandatory: true,
      validation: function(
        step,
        item,
        process,
        template,
        user,
        value,
        process_key,
        extraData
      ) {
        const spots = get(process, "fields.spot_info.value", []);

        const isHybridOrderWithProductionNeeded = !!find(
          spots,
          (spot) => spot.contract_status === "Production Needed"
        );
        if (!isHybridOrderWithProductionNeeded) return true;
        const isEnableEnterprise = get(
          extraData,
          "production_multimarket",
          false
        );
        const orderStations = get(process, "fields.order_stations.value", []);
        const orderStationsData = orderStations
          .map((item) => {
            const station = find(
              extraData.stations,
              (i) => i.station_key === item
            );
            return station;
          })
          .filter((station) => station);
        const isAllStationsEnableAssignmentsQueue =
          filter(orderStationsData, (station) =>
            get(station, "production_settings.assignments_queue", false)
          ).length === orderStationsData.length;
        const isEnableAssignmentsQueue = isAllStationsEnableAssignmentsQueue;
        // Show Assignments queue https://tasks.getventive.com/projects/D86EC-70B
        if (isEnableAssignmentsQueue) return true;

        const teamProductionManager = get(
          process,
          "fields.team_production_manager.value.id"
        );
        if (isEnableEnterprise && teamProductionManager) return true;

        return get(value, "id") ? true : "Please select production manager";
      },
    },
    {
      field: "team_production_manager",
      title: <FormattedMessage id="process > field production manager" />,
      mandatory: true,
      validation: function(
        step,
        item,
        process,
        template,
        user,
        value,
        process_key,
        extraData
      ) {
        const spots = get(process, "fields.spot_info.value", []);
        const isHybridOrderWithProductionNeeded = !!find(
          spots,
          (spot) => spot.contract_status === "Production Needed"
        );
        if (!isHybridOrderWithProductionNeeded) return true;

        const isEnableEnterprise = get(
          extraData,
          "production_multimarket",
          false
        );

        const orderStations = get(process, "fields.order_stations.value", []);
        const orderStationsData = orderStations
          .map((item) => {
            const station = find(
              extraData.stations,
              (i) => i.station_key === item
            );
            return station;
          })
          .filter((station) => station);
        const isAllStationsEnableAssignmentsQueue =
          filter(orderStationsData, (station) =>
            get(station, "production_settings.assignments_queue", false)
          ).length === orderStationsData.length;
        const isEnableAssignmentsQueue = isAllStationsEnableAssignmentsQueue;
        // Show Assignments queue https://tasks.getventive.com/projects/D86EC-70B
        if (!isEnableEnterprise || isEnableAssignmentsQueue) return true;

        const productionManager = get(
          process,
          "fields.role_production_manager.value.id"
        );
        if (productionManager) return true;

        return get(value, "id") ? true : "Please select production manager";
      },
    },
    {
      field: "role_continuity",
      title: <FormattedMessage id="process > field continuity" />,
      roles: [8],
      privileges: {
        or: ["perform_production_continuity"],
      },
      // default: 8,
      validation: function(
        step,
        item,
        process,
        template,
        user,
        value,
        process_key,
        extraData
      ) {
        const orderStations = get(process, "fields.order_stations.value", []);
        const orderStationsData = orderStations
          .map((item) => {
            const station = find(
              extraData.stations,
              (i) => i.station_key === item
            );
            return station;
          })
          .filter((station) => station);
        const isAllStationsEnableContinuityQueue =
          filter(orderStationsData, (station) =>
            get(station, "production_settings.continuity_queue", false)
          ).length === orderStationsData.length;
        const isEnableEnterprise = get(
          extraData,
          "production_multimarket",
          false
        );
        const isEnableContinuityQueue = isAllStationsEnableContinuityQueue;
        if (isEnableEnterprise || isEnableContinuityQueue) return true;
        return get(value, "users.length") > 0
          ? true
          : "Please select continuity";
      },
      mandatory: true,
    },
    {
      field: "role_dubber",
      title: <FormattedMessage id="process > field dubber" />,
      roles: [9],
      privileges: {
        or: ["perform_production_dubbing"],
      },
      default: 9,
      validation: function(step, item, process, template, user, value) {
        return true;
      },
      mandatory: false,
    },
    {
      field: "order_length_custom",
    },
    {
      field: "order_length",
      title: <FormattedMessage id="process > field length" />,
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        return value ? true : "Please enter a length";
      },
      props: {
        options: orderLengthOptions,
      },
    },
    {
      field: "contract_stations",
      mandatory: true,
      title: <FormattedMessage id="process > field contract stations" />,
      validation: function(step, item, process, template, user, value) {
        return value.length > 0 ||
          get(process, "fields.order_sold.value") === false
          ? true
          : "At least one station has to be selected";
      },
    },
    {
      field: "contract_stations_order_number_array",
      mandatory: true,
      title: (
        <FormattedMessage id="process > field contract stations order number" />
      ),
      validation: function(
        step,
        item,
        process,
        template,
        user,
        value,
        process_key,
        extraData
      ) {
        const isEnableEnterprise = get(
          extraData,
          "production_multimarket",
          false
        );
        if (!get(user, "enable_wo", false) || isEnableEnterprise) {
          return true;
        }

        let valid = true;

        let stationsWithNumbers = filter(value, (item) => item.number);

        forEach(
          get(process, "fields.contract_stations.value", []),
          (station) => {
            if (
              !includes(
                stationsWithNumbers.map((item) => item.station),
                station
              )
            ) {
              valid = false;
            }
          }
        );

        return !valid ? "Please enter entity/order number" : true;
      },
    },
    {
      field: "contract_status",
      title: <FormattedMessage id="process > field production type" />,
      props: {
        options: productionTypeOptions,
      },
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        return get(value, "length") > 0
          ? true
          : "Please select an order status";
      },
    },
    {
      field: "estimation_number",
      title: <FormattedMessage id="process > field estimation number" />,
    },
    {
      field: "contract_start_date",
      title: <FormattedMessage id="process > field contract start date" />,
    },
    {
      field: "contract_end_date",
      title: <FormattedMessage id="process > field contract end date" />,
    },
    {
      field: "due_date",
    },
    // {
    //   field: "due_date",
    //   title: <FormattedMessage id="process > field due date" />,
    //   mandatory: true,
    //   validation: function(step, item, process, template, user, value) {
    //     if (value.length === 0) return true;
    //     if (isNaN(Date.parse(value))) return "Please enter a valid date";

    //     // if (moment(value).isBefore(moment(), 'day'))
    //     //   return "Date cannot be in the past";

    //     if (
    //       process.fields.contract_end_date &&
    //       !isNaN(Date.parse(process.fields.contract_end_date.value)) &&
    //       Date.parse(value) > Date.parse(process.fields.contract_end_date.value)
    //     )
    //       return "Due date can't be greater than contract end date";

    //     if (
    //       process.fields.order_end_date &&
    //       !isNaN(Date.parse(process.fields.order_end_date.value)) &&
    //       Date.parse(value) > Date.parse(process.fields.order_end_date.value)
    //     )
    //       return "Due date can't be greater than spot end date";

    //     return true;
    //   },
    // },
    {
      field: "contract_client_contact",
      mandatory: true,
      title: <FormattedMessage id="process > field client contact" />,
      validation: function(step, item, process, template, user, value) {
        return value ? true : "Please enter a client contact";
      },
    },
    {
      field: "contract_client_email",
      title: <FormattedMessage id="process > field email" />,
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        return value.length > 0 &&
          value.match(new RegExp("[^@]+@[^\\.]+\\..+", "gi"))
          ? true
          : "Please enter a valid client email";
      },
    },
    {
      field: "contract_client_nickname",
      title: <FormattedMessage id="process > field nickname" />,
    },
    {
      field: "contract_client_phone",
      title: <FormattedMessage id="process > field phone" />,
      formatted: "phone",
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        if (value && value.length !== 10)
          return "Phone number must be 10 digits";
        return true;
      },
    },
    {
      field: "contract_category",
      title: <FormattedMessage id="process > field contract category" />,
      props: {
        options: [
          {
            label: (
              <FormattedMessage id="process > option label arts and entertainment" />
            ),
            value: "Arts & Entertainment",
          },
          {
            label: <FormattedMessage id="process > option label attorneys" />,
            value: "Attorneys",
          },
          {
            label: <FormattedMessage id="process > option label automotive" />,
            value: "Automotive",
          },
          {
            label: <FormattedMessage id="process > option label community" />,
            value: "Community",
          },
          {
            label: <FormattedMessage id="process > option label education" />,
            value: "Education",
          },
          {
            label: <FormattedMessage id="process > option label finance" />,
            value: "Finance",
          },
          {
            label: (
              <FormattedMessage id="process > option label food and beverage" />
            ),
            value: "Food & Beverage",
          },
          {
            label: (
              <FormattedMessage id="process > option label health and medical" />
            ),
            value: "Health & Medical",
          },
          {
            label: (
              <FormattedMessage id="process > option label home and garden" />
            ),
            value: "Home & Garden",
          },
          {
            label: (
              <FormattedMessage id="process > option label marine and boating" />
            ),
            value: "Marine & Boating",
          },
          {
            label: <FormattedMessage id="process > option label media" />,
            value: "Media",
          },
          {
            label: (
              <FormattedMessage id="process > option label pets and animals" />
            ),
            value: "Pets & Animals",
          },
          {
            label: (
              <FormattedMessage id="process > option label professionals" />
            ),
            value: "Professionals",
          },
          {
            label: <FormattedMessage id="process > option label real estate" />,
            value: "Real Estate",
          },
          {
            label: <FormattedMessage id="process > option label religion" />,
            value: "Religion",
          },
          {
            label: <FormattedMessage id="process > option label services" />,
            value: "Services",
          },
          {
            label: <FormattedMessage id="process > option label shopping" />,
            value: "Shopping",
          },
          {
            label: (
              <FormattedMessage id="process > option label sports and recreation" />
            ),
            value: "Sports & Recreation",
          },
          {
            label: <FormattedMessage id="process > option label travel" />,
            value: "Travel",
          },
        ],
      },
    },
    {
      field: "audience_group_id",
    },
    {
      field: "audience_group_name",
    },
    {
      field: "audience",
      mandatory: false,
      // validation: function(
      //   step,
      //   item,
      //   process,
      //   template,
      //   user,
      //   value,
      //   process_key,
      //   extraData
      // ) {
      //   const isEnableEnterprise = get(
      //     extraData,
      //     "production_multimarket",
      //     false
      //   );
      //   if (!isEnableEnterprise) return true;
      //   return !get(user, "production_multimarket", false) || value.length > 0
      //     ? true
      //     : "Please select the audience";
      // },
    },
    {
      field: "order_start_date",
      title: <FormattedMessage id="process > field order start date" />,
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        if (value.length === 0) return "Please enter a date";
        if (isNaN(Date.parse(value))) return "Please enter a valid date";
        if (
          process.fields.order_end_date &&
          !isNaN(Date.parse(process.fields.order_end_date.value)) &&
          Date.parse(value) > Date.parse(process.fields.order_end_date.value)
        )
          return "Please enter a date later than the end date";
        if (
          process.fields.contract_start_date &&
          !isNaN(Date.parse(process.fields.contract_start_date.value)) &&
          process.fields.contract_end_date &&
          !isNaN(Date.parse(process.fields.contract_end_date.value)) &&
          (Date.parse(value) <
            Date.parse(process.fields.contract_start_date.value) ||
            Date.parse(value) >
              Date.parse(process.fields.contract_end_date.value))
        )
          return "Please enter a date in the contract dates range";
        return true;
      },
    },
    {
      field: "order_end_date",
      title: <FormattedMessage id="process > field order end date" />,
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        if (value.length === 0) return "Please enter a date";
        if (isNaN(Date.parse(value))) return "Please enter a valid date";
        if (
          process.fields.order_start_date &&
          !isNaN(Date.parse(process.fields.order_start_date.value)) &&
          Date.parse(value) < Date.parse(process.fields.order_start_date.value)
        )
          return "Please enter a date later than the start date";
        if (
          process.fields.contract_start_date &&
          !isNaN(Date.parse(process.fields.contract_start_date.value)) &&
          process.fields.contract_end_date &&
          !isNaN(Date.parse(process.fields.contract_end_date.value)) &&
          (Date.parse(value) <
            Date.parse(process.fields.contract_start_date.value) ||
            Date.parse(value) >
              Date.parse(process.fields.contract_end_date.value))
        )
          return "Please enter a date in the contract dates range";
        return true;
      },
    },
    {
      field: "agency_name",
      title: <FormattedMessage id="process > field agency group name" />,
      mandatory: false,
    },
    {
      field: "agency_id",
    },
    {
      field: "timezone",
    },
    {
      field: "assign_team_status",
    },
    {
      field: "traffic_instructions",
      title: <FormattedMessage id="process > field traffic instructions" />,
      mandatory: false,
    },
    {
      field: "spot_info",
      validation: function(step, item, process, template, user, value) {
        const availableLineNumbers = get(
          process,
          "fields.available_line_numbers.value",
          []
        );
        const showLineNumber = !isEmpty(availableLineNumbers);

        let isValid = true;

        const spotInfo = map(value, (spot, index) => {
          let error = {};

          if (!spot["title"]) {
            isValid = false;
            error.title = "Order title is required";
          }

          if (!spot["spot_type"]) {
            isValid = false;
            error.spot_type = "Please select spot type";
          }

          if (showLineNumber && isEmpty(spot["line_hashes"])) {
            isValid = false;
            error.line_hashes = "Please select a line #";
          }

          if (!spot["contract_status"]) {
            isValid = false;
            error.contract_status = "Please select an order status";
          }
          if (!spot["order_length"]) {
            isValid = false;
            error.order_length = "Please enter a length";
          }

          if (spot["due_date"]) {
            const dueDate = spot["due_date"];
            if (isNaN(Date.parse(dueDate))) {
              isValid = false;
              error.due_date = "Please enter a valid date";
            }
            if (
              process.fields.contract_end_date &&
              !isNaN(Date.parse(process.fields.contract_end_date.value)) &&
              Date.parse(dueDate) >
                Date.parse(process.fields.contract_end_date.value)
            ) {
              isValid = false;
              error.due_date =
                "Due date can't be greater than contract end date";
            }
            if (isNaN(Date.parse(dueDate))) {
              isValid = false;
              error.due_date = "Please enter a valid date";
            }
            if (
              process.fields.order_end_date &&
              !isNaN(Date.parse(process.fields.order_end_date.value)) &&
              Date.parse(dueDate) >
                Date.parse(process.fields.order_end_date.value)
            ) {
              isValid = false;
              error.due_date = "Due date can't be greater than spot end date";
            }
          }

          return error;
        });
        if (isValid) return true;
        return spotInfo;
      },
      mandatory: true,
    },
    {
      field: "rotation_chart",
      validation: function(step, item, process, template, user, value) {
        let isValid = true;
        const rotationChart = map(value, (rotation) => {
          const rotationSpot = Object.values(omit(rotation, ["station"]));
          const total = sum(rotationSpot);
          if (total < 99 || total > 100) {
            isValid = false;
            return "Spot rotation percentages must add up to 100%";
          }
          return "";
        });
        if (isValid) return true;
        return rotationChart;
      },
      mandatory: true,
    },
    {
      field: "role_account_executive",
      title: <FormattedMessage id="process > field account executive owner" />,
      roles: [2],
      privileges: {
        or: ["add_production"],
      },
      validation: function(step, item, process, template, user, value) {
        return get(value, "id")
          ? true
          : "Please select account executive/owner";
      },
      mandatory: true,
    },
    {
      field: "is_multi_market",
    },
    {
      field: "markets",
    },
    { field: "is_coop_order" },
    { field: "comment" },
    {
      key: "save_button",
      text: <FormattedMessage id="process > button save" />,
      on_click: {
        validate: true,
        save: true,
      },
      fields: [
        {
          key: "order_details_status",
        },
        // Order should go to Continuity Assignments queue once assignments is done. For the Production provided flow -> we will mark assign step as completed
        {
          key: "assign_team_status",
          value: "completed",
        },
        {
          key: "process_step",
          value: "first_undone",
        },
        {
          key: "provided_audio_audit",
          value: (user, template, process, step, item, next_users) => {
            const format = getLocalStorageDateFormat();
            return {
              modified_at: `${moment()
                .format(format)
                .toString()} - ${moment()
                .format("h:mm A")
                .toString()}`,
              modified_by: user.name,
            };
          },
        },
        {
          key: "provided_audio_b_audit",
          value: (user, template, process, step, item, next_users) => {
            const format = getLocalStorageDateFormat();
            return {
              modified_at: `${moment()
                .format(format)
                .toString()} - ${moment()
                .format("h:mm A")
                .toString()}`,
              modified_by: user.name,
            };
          },
        },
      ],
      recomputeFields: function(user, template, process, step, extraData) {
        const isCompletedOrder =
          get(process, "data.fields.dub_script_status.value", "") ===
          "completed";
        const orderDetailsStatus = get(
          process,
          "data.fields.order_details_status.value",
          ""
        );
        const contractStatus = get(
          process,
          "data.fields.contract_status.value",
          ""
        );
        // PRODUCTION - Adding to Rotation after spot produced
        // https://tasks.getventive.com/projects/AD321-D98
        if (isCompletedOrder && orderDetailsStatus === "completed") {
          const continuityUsers = get(
            process,
            "data.fields.role_continuity.value.users",
            []
          ).map((u) => Number(u.id));

          process.data.fields.continuity_status = {
            value: null,
            is_dirty: true,
          };
          process.data.fields.dub_script_status = {
            value: null,
            is_dirty: true,
          };
          process.data.fields.process_step = {
            value: "continuity",
            is_dirty: true,
          };
          process.data.fields.process_step_index = {
            value: 2,
            is_dirty: true,
          };
          process.data.fields.next_users = {
            value: uniq(continuityUsers),
            is_dirty: true,
          };
          if (contractStatus === "Production Provided - Needs tags") {
            process.data.fields.record_script_status = {
              value: null,
              is_dirty: true,
            };
          }
        }
        return process;
      },
      confirmation_dialog_props: {
        title: (
          <FormattedMessage id="process > description to submit production order" />
        ),
        confirmTitle: <FormattedMessage id="process > button yes" />,
        cancelTitle: <FormattedMessage id="process > button no" />,
      },
      success_dialog_props: {
        title: <FormattedMessage id="process > title submitted" />,
        state: "success",
        centered: true,
      },
    },
  ],
};
export default orderDetails;
