import React from "react";
import Tooltip from "rc-tooltip";
import { FormattedMessage } from "react-intl";
import { HyperlinkIcon, SaveIcon } from "components/CustomIcons";
import { injectIntl } from "react-intl";
import ToastManager from "components/ToastManager";
import useOutsideClick from "utils/useOutsideClick";
import ConfirmationModal from "components/ConfirmationModal";
import * as Yup from "yup";
import ContentEditable from "react-contenteditable";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const Hyperlink = ({ intl, onSave, setCurrentEntityKey }) => {
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = React.useState(
    false
  );
  const [value, setValue] = React.useState("");
  const [type, setType] = React.useState(null);
  const [isOpen, setIsOpen] = React.useState(false);

  const ref = React.useRef(null);
  useOutsideClick(ref, () => {
    setIsOpen(false);
  });
  return (
    <div className="editor-upload" ref={ref}>
      <Tooltip
        trigger={["hover"]}
        placement="top"
        overlayClassName={"RichEditor-tooltip"}
        overlay={
          <FormattedMessage id="textarea evaluator > insert hyperlink" />
        }
      >
        <span
          className={"RichEditor-upload-icon"}
          onClick={() => {
            setIsOpen(!isOpen);
            setCurrentEntityKey(null);
            setValue("");
          }}
        >
          <HyperlinkIcon />
        </span>
      </Tooltip>
      {isOpen && (
        <div className="editor-wrapper hyperlink-wrapper">
          <div className={"hyperlink-form component-form"}>
            <ContentEditable
              className="content-editable"
              html={value}
              onChange={(event) => {
                setValue(event.target.value);
              }}
              placeholder={
                value
                  ? ""
                  : intl.formatMessage({
                      id: "textarea evaluator > placeholder enter url",
                    })
              }
            />
            <span
              className="upload-save"
              onClick={async () => {
                let isValid = !!value;
                const isUrl = await Yup.string()
                  .url()
                  .validate(value)
                  .catch(() => {
                    isValid = false;
                  });
                const isEmail = await Yup.string()
                  .email()
                  .validate(value)
                  .catch(() => {
                    if (isValid) isValid = false;
                  });
                const isPhone = await Yup.string()
                  .matches(phoneRegExp)
                  .validate(value)
                  .catch(() => {
                    if (isValid) isValid = false;
                  });
                if (isUrl) {
                  isValid = true;
                  setType("url");
                }
                if (isEmail) {
                  isValid = true;
                  setType("email");
                }
                if (isPhone) {
                  isValid = true;
                  setType("phone");
                }
                if (!isValid) {
                  ToastManager.show({
                    message: intl.formatMessage({
                      id: "textarea evaluator > error save hyperlink",
                    }),
                    level: "error",
                  });
                } else {
                  if (isUrl) {
                    onSave(value);
                    setValue("");
                    setIsOpen(false);
                    return;
                  }
                  setIsOpenConfirmationModal(true);
                }
              }}
            >
              <SaveIcon />
            </span>
          </div>
        </div>
      )}
      <ConfirmationModal
        isOpen={isOpenConfirmationModal}
        title={intl.formatMessage(
          {
            id:
              "textarea evaluator > the URL you entered seems to be an {type}. Do you want to add the required {prefix}: prefix?",
          },
          {
            type,
            prefix: type === "email" ? "mailto" : type === "phone" ? "tel" : "",
          }
        )}
        isCloseOutside={false}
        onConfirm={() => {
          setIsOpenConfirmationModal(false);
          const prefix =
            type === "email" ? "mailto" : type === "phone" ? "tel" : "";
          onSave(`${prefix}:${value}`);
          setIsOpen(false);
        }}
        onToggle={() => setIsOpenConfirmationModal(!isOpenConfirmationModal)}
        onCancel={() => {
          setIsOpenConfirmationModal(false);
          onSave(value);
          setValue("");
          setIsOpen(false);
        }}
      />
    </div>
  );
};
export default injectIntl(Hyperlink);
