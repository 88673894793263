import React from "react";
import {
  Form,
  FormGroup,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
  Label,
  Modal,
  ModalBody,
} from "reactstrap";
import RadioButtons from "components/RadioButtons";
import ToastManager from "components/ToastManager";
import { Asterisk } from "components/Elements";
import Avatar from "components/Avatar";
import TextInputFormatted from "components/TextInputFormatted";
import { get, find, isEqual, map } from "lodash";
import { FormattedMessage } from "react-intl";
import { Formik } from "formik";
import * as Yup from "yup";
import NumberFormat from "react-number-format";
import BlockingComponent from "components/BlockingComponent";
import TimezonePicker from "components/TimezonePicker";
import TextInput from "components/TextInput";
import Dropdown from "components/Dropdown";
import classnames from "classnames";
import bn from "utils/bemnames";
import Spinner from "components/Spinner";
import EditImageModal from "components/EditImageModal";
import {
  language as defaultLangCode,
  languageList,
  PhoneNumberFormat,
  genderList,
} from "utils/config";
import { EditProfileLargeIcon } from "components/CustomIcons";
import Security from "./Security";
import OutOfOffice from "./OutOfOffice";
import ShareAccess from "./ShareAccess";
import CreativeDetails from "./CreativeDetails";
import Workflow from "./Workflow";
import moment from "moment";
import PrivilegedComponent from "components/PrivilegedComponent";
import { PRIVILEGES } from "utils/constants";
import { checkUserIsAEOrAEDigital } from "utils/helpers";
export const MODE_VIEW = "view";
export const MODE_EDIT = "edit";
export const TAB_PROFILE = "profile";

const bem = bn.create("profile");
class EditProfileForm extends React.Component {
  formikRef = React.createRef();
  submitOutSiteForm = (e) => {
    e.persist();
    setTimeout(() => this.formikRef.handleSubmit(e), 20);
    setTimeout(() => {
      const formErrors = get(this.formikRef, "state.errors");
      if (formErrors && Object.keys(formErrors).length) {
        ToastManager.show({
          title: this.props.intl.formatMessage({
            id: "toast > title not saved",
          }),
          message: this.props.intl.formatMessage({
            id: "toast > message error please correct the hilighted fields",
          }),
          level: "error",
        });
      }
    }, 40);
  };
  renderProfileView = () => {
    const {
      auth,
      isSpecialUser,
      intl,
      roles,
      selectedTagOptions,
      isLoading,
      setCurrentMode,
      getUsersForGrantAccess,
      dateFormatByServer,
      isShowWorkflow,
    } = this.props;
    const usersForGrantAccessOptions = getUsersForGrantAccess();
    const user = get(auth, "user");
    const userLangCode = get(auth, "user.lang", null);
    const defaultLanguage = find(
      languageList,
      (item) => item.value === defaultLangCode
    );
    // const isGodAdmin = get(auth, "user.is_god_admin", false);
    const language = userLangCode
      ? {
          value: userLangCode,
          label: get(
            find(languageList, (item) => item.value === userLangCode),
            "label",
            "English"
          ),
        }
      : {
          value: defaultLangCode,
          label: get(defaultLanguage, "label", "English"),
        };
    const privileges = get(auth, "user.privileges", []) || [];
    const isAE = !checkUserIsAEOrAEDigital(privileges);

    return (
      <div className={bem.e("view-container")}>
        <div className={bem.e("form-content")}>
          <h4 className={bem.e("section-title")}>
            <FormattedMessage id="my profile > title personal details" />
          </h4>
          <div className={bem.e("form-fields")}>
            <Row>
              <Col className={bem.e("reset-col")}>
                <div className={bem.e("view-row")}>
                  <Label className={bem.e("view-label")}>
                    <FormattedMessage id="my profile > first name" />
                  </Label>
                  <div className={bem.e("view-value")}>
                    {get(user, "first_name", "")}
                  </div>
                </div>
              </Col>
              <Col className={bem.e("reset-col")}>
                <div className={bem.e("view-row")}>
                  <Label className={bem.e("view-label")}>
                    <FormattedMessage id="my profile > last name" />
                  </Label>
                  <div className={bem.e("view-value")}>
                    {get(user, "last_name", "")}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className={bem.e("reset-col")}>
                <div className={bem.e("view-row")}>
                  <Label className={bem.e("view-label")}>
                    <FormattedMessage id="my profile > field title" />
                  </Label>
                  <div className={bem.e("view-value")}>
                    {get(user, "title", "") ? get(user, "title", "") : "None"}
                  </div>
                </div>
              </Col>
              <Col className={bem.e("reset-col")}>
                <div className={bem.e("view-row")}>
                  <Label className={bem.e("view-label")}>
                    <FormattedMessage id="my profile > email" />
                  </Label>
                  <div className={bem.e("view-value")}>
                    {get(user, "email", "")}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className={bem.e("reset-col")}>
                <div className={bem.e("view-row")}>
                  <Label className={bem.e("view-label")}>
                    <FormattedMessage id="my profile > phone" />
                  </Label>
                  {user.phone ? (
                    <NumberFormat
                      className={bem.e("view-value")}
                      format={PhoneNumberFormat}
                      displayType={"text"}
                      value={user.phone}
                    />
                  ) : (
                    "None"
                  )}
                </div>
              </Col>
              <Col className={bem.e("reset-col")}>
                <div className={bem.e("view-row")}>
                  <Label className={bem.e("view-label")}>
                    <FormattedMessage id="my profile > language" />
                  </Label>
                  <div className={bem.e("view-value")}>
                    {get(language, "label", "")}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className={bem.e("reset-col")} xs={6}>
                <div className={bem.e("view-row")}>
                  <Label className={bem.e("view-label")}>
                    <FormattedMessage id="my profile > location" />
                  </Label>
                  <div className={bem.e("view-value")}>
                    {get(user, "location", "")
                      ? get(user, "location", "")
                      : "None"}
                  </div>
                </div>
              </Col>
              {isSpecialUser && (
                <Col className={bem.e("reset-col")} xs={6}>
                  <div className={bem.e("view-row")}>
                    <Label className={bem.e("view-label")}>
                      <FormattedMessage id="my profile > gender" />
                    </Label>
                    <div className={bem.e("view-value")}>
                      {get(user, "gender", "")}
                    </div>
                  </div>
                </Col>
              )}
            </Row>
          </div>
          <div className={bem.e("section-title-wrapper")}>
            <Row>
              <Col xs={6}>
                <div className={bem.e("section-sub-title")}>
                  <FormattedMessage id="my profile > title stations" />
                </div>
              </Col>
              <Col xs={6}>
                <div className={bem.e("section-sub-title")}>
                  <FormattedMessage id="my profile > title roles" />
                </div>
              </Col>
            </Row>
          </div>
          <Row>
            <Col className={bem.e("reset-col")} xs={6}>
              <div className={bem.e("view-stations")}>
                {get(auth, "info.stations") && (
                  <ul className={bem.e("list-inline")}>
                    {get(auth, "info.stations", []).map((station, index) => (
                      <li
                        className={classnames(
                          bem.e("list-inline-item"),
                          bem.e("list-inline-item-station")
                        )}
                        key={index}
                      >{`${station.name} `}</li>
                    ))}
                  </ul>
                )}
              </div>
            </Col>
            <Col className={bem.e("reset-col")} xs={6}>
              <div className={bem.e("view-roles")}>
                {roles && (
                  <ul className={bem.e("list-inline")}>
                    {roles.map((role, index) => (
                      <li
                        className={bem.e("list-inline-item")}
                        key={index}
                      >{`${role.name} `}</li>
                    ))}
                  </ul>
                )}
              </div>
            </Col>
          </Row>
        </div>
        {isSpecialUser && (
          <CreativeDetails
            isView={true}
            user={user}
            auth={auth}
            selectedTagOptions={selectedTagOptions}
          />
        )}
        {!isAE && (
          <OutOfOffice
            intl={intl}
            user={user}
            isView={true}
            dateFormatByServer={dateFormatByServer}
          />
        )}
        <PrivilegedComponent
          requires={{
            or: [
              PRIVILEGES.ADD_PRODUCTION,
              PRIVILEGES.EDIT_ALL_PRODUCTION_ORDERS,
            ],
          }}
        >
          <ShareAccess
            intl={intl}
            user={user}
            isView={true}
            options={usersForGrantAccessOptions}
          />
        </PrivilegedComponent>
        {isShowWorkflow && <Workflow intl={intl} user={user} isView={true} />}

        <Security user={user} isView={true} />
        <div className={bem.e("buttons")}>
          <Button
            color="blue"
            type="button"
            className={classnames("btn-radius", bem.e("button-edit"))}
            disabled={isLoading}
            onClick={() => setCurrentMode(MODE_EDIT)}
          >
            <FormattedMessage id="my profile > button edit" />
          </Button>
        </div>
      </div>
    );
  };
  renderProfileForm = () => {
    const {
      auth,
      currentMode,
      setCurrentMode,
      isLoading,
      onFormSubmit,
      setLanguage,
      intl,
      selectedTagOptions,
      isSpecialUser,
      shouldBlockNavigation,
      setShouldBlockNavigation,
      roles,
      hasMultimarket,
      getUsersForGrantAccess,
      isShowWorkflow,
    } = this.props;
    const usersForGrantAccessOptions = getUsersForGrantAccess();
    const userLangCode = get(auth, "user.lang", null);
    // const isGodAdmin = get(auth, "user.is_god_admin", false);
    const user = get(auth, "user");
    const defaultLanguage = find(
      languageList,
      (item) => item.value === defaultLangCode
    );
    const privileges = get(auth, "user.privileges", []) || [];
    const isAE = !checkUserIsAEOrAEDigital(privileges);
    const initialValues = {
      isSpecialUser: isSpecialUser,
      name: user.name || "",
      title: user.title || "",
      email: user.email || "",
      first_name: user.first_name || "",
      last_name: user.last_name || "",
      language: userLangCode
        ? {
            value: userLangCode,
            label: get(
              find(languageList, (item) => item.value === userLangCode),
              "label",
              "English"
            ),
          }
        : {
            value: defaultLangCode,
            label: get(defaultLanguage, "label", "English"),
          },
      password: "",
      phone: user.phone || "",
      location: user.location || "",
      summary: user.summary || "",
      gender: user.gender || "",
      reel: user.reel || null,
      // markets: selectedMarketOptions,
      tags: selectedTagOptions,
      out_of_office_settings:
        user.out_of_office_settings && user.out_of_office_settings.length > 0
          ? user.out_of_office_settings
          : [
              {
                from_date: "",
                to_date: "",
              },
            ],
      grant_access_to_users: get(user, "access_granted_to_users", []).map(
        (i) => i.id
      ),
      return_to_current_tasks: get(user, "return_to_current_tasks", true),
    };
    const form = (
      <Formik
        ref={(ref) => (this.formikRef = ref)}
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          title: Yup.string().nullable(),
          email: Yup.string()
            .required(
              intl.formatMessage({ id: "validate > email is required" })
            )
            .email(intl.formatMessage({ id: "validate > invalid email" })),
          first_name: Yup.string()
            .required(
              intl.formatMessage({ id: "validate > first name is required" })
            )
            .min(
              2,
              this.props.intl.formatMessage({
                id: "validate > the first name must be at least 2 characters.",
              })
            ),
          last_name: Yup.string()
            .required(
              intl.formatMessage({ id: "validate > last name is required" })
            )
            .min(
              2,
              this.props.intl.formatMessage({
                id: "validate > the last name must be at least 2 characters.",
              })
            ),
          language: Yup.string().required(
            intl.formatMessage({ id: "validate > language is required" })
          ),
          phone: Yup.string()
            .nullable()
            .test(
              "len",
              intl.formatMessage({
                id: "validate > phone number must be 10 digits",
              }),
              (val) => !val || val.length === 10
            ),
          gender: isSpecialUser
            ? Yup.string().required(
                intl.formatMessage({ id: "validate > gender is required" })
              )
            : Yup.string().nullable(),
          location: hasMultimarket
            ? Yup.string().required(
                intl.formatMessage({ id: "validate > location is required" })
              )
            : Yup.string().nullable(),
          summary: Yup.string().max(
            150,
            intl.formatMessage(
              { id: "validate > max characters" },
              {
                FIELD_NAME: "Summary",
                MAX: 150,
              }
            )
          ),
          // markets: isSpecialUser ? Yup.array().required(
          //   intl.formatMessage({
          //     id: "validate > market is required"
          //   })
          // ): Yup.string().nullable()
          out_of_office_settings: Yup.array().of(
            Yup.object().shape({
              from_date: Yup.string().nullable(),
              to_date: Yup.string()
                .test(
                  "return_date",
                  intl.formatMessage({
                    id: `validate > end date should be later than start date`,
                  }),
                  function(value) {
                    if (!this.parent.from_date) return true;
                    if (!value) return false;
                    const startDate = this.parent.from_date;
                    const isAfterEndDate = moment(startDate).isAfter(value);
                    return !isAfterEndDate;
                  }
                )
                .nullable(),
            })
          ),
          grant_access_to_users: Yup.array().nullable(),
          return_to_current_tasks: Yup.bool(),
        })}
        onSubmit={onFormSubmit}
      >
        {(formProps) => {
          const errors = formProps.errors;
          const touched = formProps.touched;
          const values = formProps.values;
          const isDisabled = currentMode === MODE_VIEW;
          const isChanged = !isEqual(initialValues, values);
          if (isChanged !== shouldBlockNavigation) {
            setTimeout(() => setShouldBlockNavigation(isChanged), 20);
          }
          return (
            <Form onSubmit={formProps.handleSubmit}>
              <div className={bem.e("form-content")}>
                <h4 className={bem.e("section-title")}>
                  <FormattedMessage id="my profile > title personal details" />
                </h4>
                <div className={bem.e("form-fields")}>
                  <Row>
                    <Col xs={6} className={bem.e("reset-col")}>
                      <FormGroup className={bem.e("form-group-edit")}>
                        <TextInput
                          label={
                            <span>
                              <FormattedMessage id="my profile > first name" />
                              <Asterisk>*</Asterisk>
                            </span>
                          }
                          disabled={isDisabled}
                          type="text"
                          name="first_name"
                          placeholder={intl.formatMessage({
                            id: "my profile > first name placeholder",
                          })}
                          value={values.first_name}
                          error={touched.first_name && errors.first_name}
                          onChange={formProps.handleChange}
                          onBlur={formProps.handleBlur}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={6} className={bem.e("reset-col")}>
                      <FormGroup className={bem.e("form-group-edit")}>
                        <TextInput
                          label={
                            <span>
                              <FormattedMessage id="my profile > last name" />{" "}
                              <Asterisk>*</Asterisk>
                            </span>
                          }
                          disabled={isDisabled}
                          required={true}
                          type="text"
                          name="last_name"
                          placeholder={intl.formatMessage({
                            id: "my profile > last name placeholder",
                          })}
                          value={values.last_name}
                          error={touched.last_name && errors.last_name}
                          onChange={formProps.handleChange}
                          onBlur={formProps.handleBlur}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} className={bem.e("reset-col")}>
                      <FormGroup className={bem.e("form-group-edit")}>
                        <TextInput
                          label={
                            <span>
                              <FormattedMessage id="my profile > field title" />
                              {/* <Asterisk>*</Asterisk> */}
                            </span>
                          }
                          disabled={isDisabled}
                          type="text"
                          name="title"
                          placeholder={intl.formatMessage({
                            id: "my profile > title placeholder",
                          })}
                          value={values.title}
                          error={touched.title && errors.title}
                          onChange={formProps.handleChange}
                          onBlur={formProps.handleBlur}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={6} className={bem.e("reset-col")}>
                      <FormGroup className={bem.e("form-group-edit")}>
                        <TextInput
                          label={
                            <span>
                              <FormattedMessage id="my profile > email" />
                              <Asterisk>*</Asterisk>
                            </span>
                          }
                          disabled={isDisabled}
                          type="email"
                          name="email"
                          placeholder={intl.formatMessage({
                            id: "my profile > email placeholder",
                          })}
                          value={values.email}
                          error={touched.email && errors.email}
                          onChange={formProps.handleChange}
                          onBlur={formProps.handleBlur}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} className={bem.e("reset-col")}>
                      <FormGroup className={bem.e("form-group-edit")}>
                        <TextInputFormatted
                          format={PhoneNumberFormat}
                          label={
                            <span>
                              <FormattedMessage id="my profile > phone" />
                              {/* <Asterisk>*</Asterisk> */}
                            </span>
                          }
                          disabled={isDisabled}
                          type="text"
                          name="phone"
                          placeholder={intl.formatMessage({
                            id: "my profile > phone placeholder",
                          })}
                          value={values.phone}
                          error={touched.phone && errors.phone}
                          onChange={(value) =>
                            formProps.setFieldValue("phone", value)
                          }
                          onBlur={formProps.handleBlur}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={6} className={bem.e("reset-col")}>
                      <FormGroup className={bem.e("form-group-edit")}>
                        <Dropdown
                          label={
                            <span>
                              <FormattedMessage id="my profile > language" />
                              <Asterisk>*</Asterisk>
                            </span>
                          }
                          isDisabled={isDisabled}
                          value={values.language}
                          placeholder={intl.formatMessage({
                            id: "my profile > language placeholder",
                          })}
                          onChange={(selectedOption) => {
                            formProps.setFieldValue("language", selectedOption);
                            setLanguage(get(selectedOption, "value"));
                          }}
                          options={languageList}
                          name="language"
                          error={touched.language && errors.language}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} className={bem.e("reset-col")}>
                      <FormGroup className={bem.e("form-group-edit")}>
                        <Label>
                          <FormattedMessage id="my profile > location" />
                          {hasMultimarket && <Asterisk>*</Asterisk>}
                        </Label>
                        <TimezonePicker
                          value={values.location}
                          onChange={({ value }) =>
                            formProps.setFieldValue("location", value)
                          }
                          placeholder={intl.formatMessage({
                            id: "my profile > location placeholder",
                          })}
                        />
                        {touched.location && errors.location && (
                          <div className="text-danger">{errors.location}</div>
                        )}
                      </FormGroup>
                    </Col>
                    {isSpecialUser && (
                      <Col xs={6} className={bem.e("col-reset")}>
                        <FormGroup className={bem.e("form-group-edit")}>
                          <RadioButtons
                            label={
                              <span>
                                <FormattedMessage id="user > gender" />
                                <Asterisk>*</Asterisk>
                              </span>
                            }
                            onSelected={(selected) => {
                              formProps.setFieldValue("gender", selected);
                            }}
                            selectedValue={values.gender}
                            buttons={genderList}
                            error={touched.gender && errors.gender}
                          />
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                </div>

                <div className={bem.e("section-title-wrapper")}>
                  <Row>
                    <Col xs={6}>
                      <div className={bem.e("section-sub-title")}>
                        <FormattedMessage id="my profile > title stations" />
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className={bem.e("section-sub-title")}>
                        <FormattedMessage id="my profile > title roles" />
                      </div>
                    </Col>
                  </Row>
                </div>
                <Row>
                  <Col className={bem.e("reset-col")} xs={6}>
                    <div className={bem.e("view-stations")}>
                      {get(auth, "info.stations.length", 0) > 0 && (
                        <ul className={bem.e("list-inline")}>
                          {get(auth, "info.stations", []).map(
                            (station, index) => (
                              <li
                                className={classnames(
                                  bem.e("list-inline-item"),
                                  bem.e("list-inline-item-station")
                                )}
                                key={index}
                              >
                                {`${station.name} `}
                              </li>
                            )
                          )}
                        </ul>
                      )}
                    </div>
                  </Col>
                  <Col className={bem.e("reset-col")} xs={6}>
                    <div className={bem.e("view-roles")}>
                      {roles && (
                        <ul className={bem.e("list-inline")}>
                          {map(roles, (role, index) => (
                            <li
                              className={bem.e("list-inline-item")}
                              key={index}
                            >{`${role.name} `}</li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
              {isSpecialUser && (
                <CreativeDetails
                  auth={auth}
                  formProps={formProps}
                  isDisabled={isDisabled}
                />
              )}
              {!isAE && (
                <OutOfOffice intl={intl} formProps={formProps} isView={false} />
              )}
              <PrivilegedComponent
                requires={{
                  or: [
                    PRIVILEGES.ADD_PRODUCTION,
                    PRIVILEGES.EDIT_ALL_PRODUCTION_ORDERS,
                  ],
                }}
              >
                <ShareAccess
                  intl={intl}
                  formProps={formProps}
                  isView={false}
                  options={usersForGrantAccessOptions}
                  setShouldBlockNavigation={setShouldBlockNavigation}
                />
              </PrivilegedComponent>
              {isShowWorkflow && (
                <Workflow
                  intl={intl}
                  formProps={formProps}
                  isView={false}
                  setShouldBlockNavigation={setShouldBlockNavigation}
                />
              )}

              <Security user={user} isView={false} isLoading={isLoading} />
              <FormGroup>
                <div className={bem.e("buttons")}>
                  <Button
                    color="blue"
                    type="submit"
                    disabled={isLoading}
                    className={classnames("btn-radius", bem.e("button-submit"))}
                    onClick={() => {
                      setTimeout(() => {
                        const formErrors = get(this.formikRef, "state.errors");
                        if (formErrors && Object.keys(formErrors).length) {
                          ToastManager.show({
                            title: this.props.intl.formatMessage({
                              id: "toast > title not saved",
                            }),
                            message: this.props.intl.formatMessage({
                              id:
                                "toast > message error please correct the hilighted fields",
                            }),
                            level: "error",
                          });
                        }
                      }, 20);
                    }}
                  >
                    <FormattedMessage
                      id={
                        !isLoading
                          ? "my profile > button save"
                          : "my profile > button saving"
                      }
                    />
                  </Button>
                  {` `}
                  <Button
                    type="button"
                    outline
                    color="blue"
                    disabled={isLoading}
                    className={classnames("btn-radius", bem.e("button-cancel"))}
                    onClick={() => {
                      formProps.resetForm();
                      setCurrentMode(MODE_VIEW);
                      setLanguage(userLangCode);
                    }}
                  >
                    <FormattedMessage id="my profile > button cancel" />
                  </Button>
                </div>
              </FormGroup>
            </Form>
          );
        }}
      </Formik>
    );
    return (
      <React.Fragment>
        {form}
        <BlockingComponent
          isBlocked={shouldBlockNavigation}
          modalProps={{
            isShowConfirm: true,
            isShowDiscard: false,
            isCancelConfirmedNavigation: true,
            onCancel: () => {
              setShouldBlockNavigation(false); // reset
            },
            // onConfirm: () => {
            //   const buttonSubmit = document.getElementById(`btn-submit`);
            //   // trigger click to submit the form
            //   if (buttonSubmit) buttonSubmit.click();
            // },
          }}
        />
      </React.Fragment>
    );
    // return (
    //   <WarnAboutUnsavedChanges when={shouldBlockNavigation} children={form} />
    // );
  };
  render() {
    const {
      auth,
      currentMode,
      isLoading,
      activeTab,
      setActiveTab,
      isOpenEditAvatarModal,
      setIsOpenEditAvatarModal,
      onToggleOpenEditAvatarModal,
      limitAccess,
      islimitAccessModalOpen,
      setIslimitAccessModalOpen,
    } = this.props;

    if (limitAccess) {
      return (
        <div className={bem.b()}>
          <Modal
            isOpen={islimitAccessModalOpen}
            className={bem.e("limit-access-modal")}
          >
            <ModalBody>
              <div className={bem.e("limit-access-content")}>
                <div className={bem.e("limit-access-message")}>
                  <FormattedMessage id="my profile > no role assigned" />
                </div>
                <Button
                  color="blue"
                  type="button"
                  className={classnames("btn-radius", bem.e("button-ok"))}
                  onClick={() => setIslimitAccessModalOpen(false)}
                >
                  <FormattedMessage id="my profile > ok" />
                </Button>
              </div>
            </ModalBody>
          </Modal>
        </div>
      );
    }
    return (
      <div className={bem.b()}>
        <div className={bem.e("info-profile")}>
          <div className={bem.e("user-avatar")}>
            <div
              className={bem.e("avatar-wrapper")}
              onClick={() => setIsOpenEditAvatarModal(true)}
            >
              <div className={bem.e("round-avatar")}>
                <Avatar
                  className={bem.e("avatar")}
                  src={get(auth, "user.avatar_url")}
                  size={150}
                  name={get(auth, "user.name", "")}
                />
              </div>
              <span className={bem.e("edit-icon")}>
                <EditProfileLargeIcon />
              </span>
            </div>
            <EditImageModal
              isOpen={isOpenEditAvatarModal}
              onToggle={onToggleOpenEditAvatarModal}
              mimes={["image/jpeg", "image/png", "image/bmp"]}
              onUploaded={this.props.onUploadAvatar}
              url={get(auth, "user.avatar_url")}
              title={
                <FormattedMessage id="edit image > modal profile pic title" />
              }
            />
            <div className={bem.e("user-name")}>
              <span className={bem.e("text-head")}>
                {get(auth, "user.name")}
              </span>
            </div>
          </div>
          <div className={bem.e("user-position")}>
            <span className={bem.e("text-head")}>
              {get(auth, "info.server_name", "")}
            </span>
          </div>
        </div>
        <div className={bem.e("tab-container")}>
          <Nav tabs className={bem.e("nav")}>
            <NavItem>
              <NavLink
                className={classnames(bem.e("tab-link"), {
                  [bem.e("active")]: activeTab === TAB_PROFILE,
                })}
                onClick={() => {
                  setActiveTab(TAB_PROFILE);
                }}
              >
                <FormattedMessage id="my profile > tab profile" />
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={TAB_PROFILE}>
              <Row>
                <Col>
                  <div className={bem.e("tab-detail")}>
                    {currentMode === MODE_EDIT
                      ? this.renderProfileForm()
                      : this.renderProfileView()}
                  </div>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </div>
        <Spinner isLoading={isLoading} />
      </div>
    );
  }
}

export default EditProfileForm;
