import React from "react";
import Tooltip from "rc-tooltip";
import { FormattedMessage } from "react-intl";
import { ImageIcon, SaveIcon } from "components/CustomIcons";
import UploadFiles from "components/UploadFiles";
import { injectIntl } from "react-intl";
import classNames from "classnames";
import ToastManager from "components/ToastManager";
import useOutsideClick from "utils/useOutsideClick";

const EditorUpload = ({ intl, onSave }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const ref = React.useRef(null);
  useOutsideClick(ref, () => {
    setIsOpen(false);
  });
  return (
    <div className="editor-upload" ref={ref}>
      <Tooltip
        trigger={["hover"]}
        placement="top"
        overlayClassName={"RichEditor-tooltip"}
        overlay={<FormattedMessage id="textarea evaluator > insert image" />}
      >
        <span
          className={"RichEditor-upload-icon"}
          onClick={() => setIsOpen(!isOpen)}
        >
          <ImageIcon />
        </span>
      </Tooltip>
      {isOpen && (
        <div className="editor-wrapper">
          <div
            className={classNames("upload-form component-form", {
              "upload-form-has-files": files.length > 0,
            })}
          >
            <UploadFiles
              attachText={intl.formatMessage({
                id: "textarea evaluator > attach file",
              })}
              // mimes
              maxSize={300000} //300kb
              acceptMultiple={false}
              files={files}
              mimes={["image/jpeg", "image/png"]}
              isRenderFilesName={true}
              onUploadedFiles={(updatedFiles) => {
                setFiles(updatedFiles);
              }}
              maxFiles={1} // 1files
              className={"upload-files"}
            />
            <span
              className="upload-save"
              onClick={() => {
                if (files.length === 0) {
                  ToastManager.show({
                    message: intl.formatMessage({
                      id: "textarea evaluator > error save image",
                    }),
                    level: "error",
                  });
                } else {
                  onSave(files);
                  setFiles([]);
                  setIsOpen(false);
                }
              }}
            >
              <SaveIcon />
            </span>
          </div>
          <div className="upload-description">
            <FormattedMessage id="textarea evaluator > attach file description" />
          </div>
        </div>
      )}
    </div>
  );
};
export default injectIntl(EditorUpload);
