export const FETCH_STATIONS_REQUEST = "FETCH_STATIONS_REQUEST";
export const FETCH_STATIONS_SUCCESS = "FETCH_STATIONS_SUCCESS";
export const FETCH_STATIONS_ERROR = "FETCH_STATIONS_ERROR";

export const FETCH_MORE_STATIONS_REQUEST = "FETCH_MORE_STATIONS_REQUEST";
export const FETCH_MORE_STATIONS_SUCCESS = "FETCH_MORE_STATIONS_SUCCESS";
export const FETCH_MORE_STATIONS_ERROR = "FETCH_MORE_STATIONS_ERROR";

export const FETCH_STATION_REQUEST = "FETCH_STATION_REQUEST";
export const FETCH_STATION_SUCCESS = "FETCH_STATION_SUCCESS";
export const FETCH_STATION_ERROR = "FETCH_STATION_ERROR";

export const ADD_STATION_REQUEST = "ADD_STATION_REQUEST";
export const ADD_STATION_SUCCESS = "ADD_STATION_SUCCESS";
export const ADD_STATION_ERROR = "ADD_STATION_ERROR";

export const UPDATE_STATION_REQUEST = "UPDATE_STATION_REQUEST";
export const UPDATE_STATION_SUCCESS = "UPDATE_STATION_SUCCESS";
export const UPDATE_STATION_ERROR = "UPDATE_STATION_ERROR";

export const DELETE_STATION_REQUEST = "DELETE_STATION_REQUEST";
export const DELETE_STATION_SUCCESS = "DELETE_STATION_SUCCESS";
export const DELETE_STATION_ERROR = "DELETE_STATION_ERROR";

export const FETCH_USER_STATION_REQUEST = "FETCH_USER_STATIONS_REQUEST";
export const FETCH_USER_STATION_SUCCESS = "FETCH_USER_STATIONS_SUCCESS";
export const FETCH_USER_STATIONS_ERROR = "FETCH_USER_STATIONS_ERROR";

export const UPDATE_CITIES_GROUPS_STATIONS_REQUEST =
  "UPDATE_CITIES_GROUPS_STATIONS_REQUEST";
export const UPDATE_CITIES_GROUPS_STATIONS_SUCCESS =
  "UPDATE_CITIES_GROUPS_STATIONS_SUCCESS";
export const UPDATE_CITIES_GROUPS_STATIONS_ERROR =
  "UPDATE_CITIES_GROUPS_STATIONS_ERROR";

export const UPDATE_PRIVILEGES_STATIONS_REQUEST =
  "UPDATE_PRIVILEGES_STATIONS_REQUEST";
export const UPDATE_PRIVILEGES_STATIONS_SUCCESS =
  "UPDATE_PRIVILEGES_STATIONS_SUCCESS";
export const UPDATE_PRIVILEGES_STATIONS_ERROR =
  "UPDATE_PRIVILEGES_STATIONS_ERROR";

export const FETCH_STATION_SETTINGS_REQUEST = "FETCH_STATION_SETTINGS_REQUEST";
export const FETCH_STATION_SETTINGS_SUCCESS = "FETCH_STATION_SETTINGS_SUCCESS";
export const FETCH_STATION_SETTINGS_ERROR = "FETCH_STATION_SETTINGS_ERROR";

export const UPDATE_STATION_SETTINGS_REQUEST =
  "UPDATE_STATION_SETTINGS_REQUEST";
export const UPDATE_STATION_SETTINGS_SUCCESS =
  "UPDATE_STATION_SETTINGS_SUCCESS";
export const UPDATE_STATION_SETTINGS_ERROR = "UPDATE_STATION_SETTINGS_ERROR";

export const FETCH_STATION_W9_FORMS_REQUEST = "FETCH_STATION_W9_FORMS_REQUEST";
export const FETCH_STATION_W9_FORMS_SUCCESS = "FETCH_STATION_W9_FORMS_SUCCESS";
export const FETCH_STATION_W9_FORMS_ERROR = "FETCH_STATION_W9_FORMS_ERROR";

export const UPDATE_STATION_W9_FORMS_REQUEST =
  "UPDATE_STATION_W9_FORMS_REQUEST";
export const UPDATE_STATION_W9_FORMS_SUCCESS =
  "UPDATE_STATION_W9_FORMS_SUCCESS";
export const UPDATE_STATION_W9_FORMS_ERROR = "UPDATE_STATION_W9_FORMS_ERROR";

export const FETCH_STATION_ADDRESS_VERIFICATION_REQUEST =
  "FETCH_STATION_ADDRESS_VERIFICATION_REQUEST";
export const FETCH_STATION_ADDRESS_VERIFICATION_SUCCESS =
  "FETCH_STATION_ADDRESS_VERIFICATION_SUCCESS";
export const FETCH_STATION_ADDRESS_VERIFICATION_ERROR =
  "FETCH_STATION_ADDRESS_VERIFICATION_ERROR";

export const UPDATE_STATION_ADDRESS_VERIFICATION_REQUEST =
  "UPDATE_STATION_ADDRESS_VERIFICATION_REQUEST";
export const UPDATE_STATION_ADDRESS_VERIFICATION_SUCCESS =
  "UPDATE_STATION_ADDRESS_VERIFICATION_SUCCESS";
export const UPDATE_STATION_ADDRESS_VERIFICATION_ERROR =
  "UPDATE_STATION_ADDRESS_VERIFICATION_ERROR";

export const FETCH_REMINDER_DAYS_REQUEST = "FETCH_REMINDER_DAYS_REQUEST";
export const FETCH_REMINDER_DAYS_SUCCESS = "FETCH_REMINDER_DAYS_SUCCESS";
export const FETCH_REMINDER_DAYS_ERROR = "FETCH_REMINDER_DAYS_ERROR";

export const UPDATE_REMINDER_DAYS_REQUEST = "UPDATE_REMINDER_DAYS_REQUEST";
export const UPDATE_REMINDER_DAYS_SUCCESS = "UPDATE_REMINDER_DAYS_SUCCESS";
export const UPDATE_REMINDER_DAYS_ERROR = "UPDATE_REMINDER_DAYS_ERROR";

export const FETCH_FULFILLMENT_SETTINGS_REQUEST =
  "FETCH_FULFILLMENT_SETTINGS_REQUEST";
export const FETCH_FULFILLMENT_SETTINGS_SUCCESS =
  "FETCH_FULFILLMENT_SETTINGS_SUCCESS";
export const FETCH_FULFILLMENT_SETTINGS_ERROR =
  "FETCH_FULFILLMENT_SETTINGS_ERROR";

export const UPDATE_FULFILLMENT_SETTINGS_REQUEST =
  "UPDATE_FULFILLMENT_SETTINGS_REQUEST";
export const UPDATE_FULFILLMENT_SETTINGS_SUCCESS =
  "UPDATE_FULFILLMENT_SETTINGS_SUCCESS";
export const UPDATE_FULFILLMENT_SETTINGS_ERROR =
  "UPDATE_FULFILLMENT_SETTINGS_ERROR";

export const SET_FULFILLMENT_SETTINGS_ACTIVATE_REQUEST =
  "SET_FULFILLMENT_SETTINGS_ACTIVATE_REQUEST";
export const SET_FULFILLMENT_SETTINGS_ACTIVATE_SUCCESS =
  "SET_FULFILLMENT_SETTINGS_ACTIVATE_SUCCESS";
export const SET_FULFILLMENT_SETTINGS_ACTIVATE_ERROR =
  "SET_FULFILLMENT_SETTINGS_ACTIVATE_ERROR";

export const FETCH_FULFILLMENT_SETTING_REQUEST =
  "FETCH_FULFILLMENT_SETTING_REQUEST";
export const FETCH_FULFILLMENT_SETTING_SUCCESS =
  "FETCH_FULFILLMENT_SETTING_SUCCESS";
export const FETCH_FULFILLMENT_SETTING_ERROR =
  "FETCH_FULFILLMENT_SETTING_ERROR";

export const SET_FULFILLMENT_SETTINGS_SUCCESS =
  "SET_FULFILLMENT_SETTINGS_SUCCESS";

export const COPY_FULFILLMENT_SETTINGS_REQUEST =
  "COPY_FULFILLMENT_SETTINGS_REQUEST";
export const COPY_FULFILLMENT_SETTINGS_SUCCESS =
  "COPY_FULFILLMENT_SETTINGS_SUCCESS";
export const COPY_FULFILLMENT_SETTINGS_ERROR =
  "COPY_FULFILLMENT_SETTINGS_ERROR";
