import * as Types from "store/types/systemTemplates";
import { LOGOUT_USER } from "store/types/auth";

const initialState = {
  systemTemplates: {
    data: {},
    error: null,
    loading: false,
  },
  updateSystemTemplate: {
    error: null,
    loading: false,
  },
  duplicateSystemTemplate: {
    error: null,
    loading: false,
  },
  checkDuplicateTemplate: {
    error: null,
    loading: false,
  },
  defaultSystemTemplate: {
    error: null,
    loading: false,
  },
  winnerAnnouncements: {
    error: null,
    loading: false,
    data: null,
    is_dirty: false,
  },
  formConfirmations: {
    error: null,
    loading: false,
    data: null,
    is_dirty: false,
  },
  prizeReleases: {
    error: null,
    loading: false,
    data: null,
    is_dirty: false,
  },
  prizeFulfillments: {
    error: null,
    loading: false,
    data: null,
    is_dirty: false,
  },
  emailTemplates: {
    error: null,
    loading: false,
    data: null,
    is_dirty: false,
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    // List
    case Types.FETCH_SYSTEM_TEMPLATES_REQUEST: {
      return {
        ...state,
        systemTemplates: {
          ...state.systemTemplates,
          is_dirty: false,
          error: null,
          loading: true,
        },
      };
    }
    case Types.FETCH_SYSTEM_TEMPLATES_SUCCESS: {
      return {
        ...state,
        systemTemplates: {
          data: action.payload,
          is_dirty: false,
          error: null,
          loading: false,
        },
      };
    }
    case Types.FETCH_SYSTEM_TEMPLATES_ERROR: {
      return {
        ...state,
        systemTemplates: {
          data: {},
          is_dirty: false,
          error: action.error,
          loading: false,
        },
      };
    }
    // Update
    case Types.UPDATE_SYSTEM_TEMPLATES_REQUEST: {
      return {
        ...state,
        updateSystemTemplate: {
          loading: true,
          error: null,
        },
      };
    }
    case Types.UPDATE_SYSTEM_TEMPLATES_SUCCESS: {
      return {
        ...state,
        updateSystemTemplate: {
          loading: false,
          error: null,
        },
      };
    }
    case Types.UPDATE_SYSTEM_TEMPLATES_ERROR: {
      return {
        ...state,
        updateSystemTemplate: {
          loading: false,
          error: action.payload,
        },
      };
    }
    // winner announcement
    case Types.FETCH_WINNER_ANNOUNCEMENT_REQUEST: {
      return {
        ...state,
        winnerAnnouncements: {
          ...state.winnerAnnouncements,
          error: null,
          loading: true,
          is_dirty: false,
        },
      };
    }
    case Types.FETCH_WINNER_ANNOUNCEMENT_SUCCESS: {
      return {
        ...state,
        winnerAnnouncements: {
          data: action.payload,
          error: null,
          loading: false,
          is_dirty: false,
        },
      };
    }
    case Types.FETCH_WINNER_ANNOUNCEMENT_ERROR: {
      return {
        ...state,
        winnerAnnouncements: {
          data: null,
          error: action.error,
          loading: false,
          is_dirty: false,
        },
      };
    }
    // form confirmation
    case Types.FETCH_FORM_CONFIRMATION_REQUEST: {
      return {
        ...state,
        formConfirmations: {
          ...state.formConfirmations,
          error: null,
          loading: true,
          is_dirty: false,
        },
      };
    }
    case Types.FETCH_FORM_CONFIRMATION_SUCCESS: {
      return {
        ...state,
        formConfirmations: {
          data: action.payload,
          error: null,
          loading: false,
          is_dirty: false,
        },
      };
    }
    case Types.FETCH_FORM_CONFIRMATION_ERROR: {
      return {
        ...state,
        formConfirmations: {
          data: null,
          error: action.error,
          loading: false,
          is_dirty: false,
        },
      };
    }
    // prize release
    case Types.FETCH_PRIZE_RELEASE_TEMPLATE_REQUEST: {
      return {
        ...state,
        prizeReleases: {
          ...state.prizeReleases,
          error: null,
          loading: true,
          is_dirty: false,
        },
      };
    }
    case Types.FETCH_PRIZE_RELEASE_TEMPLATE_SUCCESS: {
      return {
        ...state,
        prizeReleases: {
          data: action.payload,
          error: null,
          loading: false,
          is_dirty: false,
        },
      };
    }
    case Types.FETCH_PRIZE_RELEASE_TEMPLATE_ERROR: {
      return {
        ...state,
        prizeReleases: {
          data: null,
          error: action.error,
          loading: false,
          is_dirty: false,
        },
      };
    }
    // prize fulfillment
    case Types.FETCH_PRIZE_FULFILLMENT_TEMPLATE_REQUEST: {
      return {
        ...state,
        prizeFulfillments: {
          ...state.prizeFulfillments,
          error: null,
          loading: true,
          is_dirty: false,
        },
      };
    }
    case Types.FETCH_PRIZE_FULFILLMENT_TEMPLATE_SUCCESS: {
      return {
        ...state,
        prizeFulfillments: {
          data: action.payload,
          error: null,
          loading: false,
          is_dirty: false,
        },
      };
    }
    case Types.FETCH_PRIZE_FULFILLMENT_TEMPLATE_ERROR: {
      return {
        ...state,
        prizeFulfillments: {
          data: null,
          error: action.error,
          loading: false,
          is_dirty: false,
        },
      };
    }
    // email templates
    case Types.FETCH_EMAIL_TEMPLATES_REQUEST: {
      return {
        ...state,
        emailTemplates: {
          ...state.emailTemplates,
          error: null,
          loading: true,
          is_dirty: false,
        },
      };
    }
    case Types.FETCH_EMAIL_TEMPLATES_SUCCESS: {
      return {
        ...state,
        emailTemplates: {
          data: action.payload,
          error: null,
          loading: false,
          is_dirty: false,
        },
      };
    }
    case Types.FETCH_EMAIL_TEMPLATES_ERROR: {
      return {
        ...state,
        emailTemplates: {
          data: null,
          error: action.error,
          loading: false,
          is_dirty: false,
        },
      };
    }
    case Types.FORGET_SYSTEM_TEMPLATES_RELATED_FORMS: {
      return {
        ...state,
        winnerAnnouncements: {
          ...state.winnerAnnouncements,
          is_dirty: true,
        },
        formConfirmations: {
          ...state.formConfirmations,
          is_dirty: true,
        },
        prizeReleases: {
          ...state.prizeReleases,
          is_dirty: true,
        },
        prizeFulfillments: {
          ...state.prizeFulfillments,
          is_dirty: true,
        },
        emailTemplates: {
          ...state.emailTemplates,
          is_dirty: true,
        },
      };
    }
    // check duplicate templates
    case Types.CHECK_DUPLICATE_TEMPLATE_REQUEST: {
      return {
        ...state,
        checkDuplicateTemplate: {
          error: null,
          loading: true,
        },
      };
    }
    case Types.CHECK_DUPLICATE_TEMPLATE_SUCCESS: {
      return {
        ...state,
        checkDuplicateTemplate: {
          error: null,
          loading: false,
        },
      };
    }
    case Types.CHECK_DUPLICATE_TEMPLATE_ERROR: {
      return {
        ...state,
        checkDuplicateTemplate: {
          error: action.error,
          loading: false,
        },
      };
    }

    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
}
