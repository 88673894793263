import cn from "classnames";
import React from "react";
import { calendarEvents } from "utils/config";
/* eslint-disable react/prop-types */
function TimeGridEvent(props) {
  const {
    style,
    className,
    event,
    accessors,
    rtl,
    selected,
    label,
    continuesEarlier,
    continuesLater,
    getters,
    onClick,
    onDoubleClick,
    components: { event: Event, eventWrapper: EventWrapper }
  } = props;
  let title = accessors.title(event);
  let tooltip = accessors.tooltip(event);
  let end = accessors.end(event);
  let start = accessors.start(event);

  let userProps = getters.eventProp(event, start, end, selected);

  let { height, top, width, xOffset } = style;
  const inner = [
    <div key="1" className="rbc-event-label">
      {label}
    </div>,
    <div key="2" className="rbc-event-content">
      {Event ? <Event event={event} title={title} /> : title}
    </div>
  ];
  const timeClass = event.start.getHours();

  return (
    <EventWrapper type="time" {...props}>
      <div
        onClick={onClick}
        onDoubleClick={onDoubleClick}
        style={{
          ...userProps.style,
          top: `${top}%`,
          height: `${height}%`,
          [rtl ? "right" : "left"]: `${Math.max(0, xOffset)}%`,
          width: `${width}%`
        }}
        title={
          tooltip
            ? (typeof label === "string" ? label + ": " : "") + tooltip
            : undefined
        }
        className={cn(
          "rbc-event ps-rbc-event",
          className,
          userProps.className,
          [`ps-rbs-event-time-at-${timeClass}`],
          {
            "rbc-selected": selected,
            "rbc-event-continues-earlier": continuesEarlier,
            "rbc-event-continues-later": continuesLater,
            "ps-rcb-on-hold-event": event.type === calendarEvents.ON_HOLD_EVENT,
            "ps-rcb-internal-event":
              event.type === calendarEvents.INTERNAL_EVENT,
            "ps-rcb-client-event": event.type === calendarEvents.CLIENT_EVENT,
            "ps-rcb-success-event": event.type === calendarEvents.SUCCESS_EVENT
          }
        )}
      >
        {inner}
      </div>
    </EventWrapper>
  );
}

export default TimeGridEvent;
