import pathToRegexp from "path-to-regexp";

// This function process any url/path given path regex and config options
// - pathRegex: is the regex by which we can construct actual url, format is similar to react-router route urls
// - config options: when we pass { path: true } in options it will return plain path regex otherwise it will return compiled path with actual parameter values
// For e.g. when we define react-router routes , we need path regex with parameter placement holder
// so at that time pass { path : true } with will directly return pathRegex
// Suppose in case we need actual url to redirect at that time pass parameters values with { path : false } & it will return actual url to redirect
const processURL = (pathRegex, { path, ...params } = {}) => {
  if (path) {
    return pathRegex;
  }
  // if pathRegex = `servers/:id` and params = {id: '123'}
  // then this function will return `servers/123`
  const toPath = pathToRegexp.compile(pathRegex);
  return toPath(params, { encode: (value, token) => value });
};

const abstractURL = (pathRegex) => (options) => processURL(pathRegex, options);

export default {
  HOME: abstractURL("/"),
  HOME_MOBILE: abstractURL("/home-mobile"),
  ADMIN: abstractURL("/admin"),
  LOGIN: abstractURL("/login"),
  SIGN_UP: abstractURL("/signup"),
  ADMIN_LOGIN: abstractURL("/admin/login/:email/:guid"),
  FORM_MANAGER: abstractURL("/form-manager"),
  WORKFLOW_MANAGER: abstractURL("/workflow"),
  STATION_SETTINGS: abstractURL("/station-settings"),

  ADD_STATION: abstractURL("/stations/add"),

  ADD_USER: abstractURL("/users/add"),

  TEMPLATE: abstractURL("/template"),
  TEMPLATES: abstractURL("/templates"),
  UPDATE_TEMPLATES: abstractURL("/templates/update/:template_id"),

  PRIZE: abstractURL("/prizes"),
  PRIZES: abstractURL("/prizes/:filter?"),
  UPDATE_PRIZES: abstractURL("/prizes/edit/:prizes_id"),
  ADD_PRIZES: abstractURL("/prizes/add/:template_id"),
  VIEW_PRIZES: abstractURL("/prizes/view/:prizes_id"),

  RESOURCE: abstractURL("/resources"),
  RESOURCES: abstractURL("/resources/:filter?"),
  UPDATE_RESOURCES: abstractURL("/resources/edit/:resources_id"),
  ADD_RESOURCES: abstractURL("/resources/add/:template_id"),
  VIEW_RESOURCES: abstractURL("/resources/view/:resources_id"),

  PROCESS: abstractURL("/process"),
  PROCESSES: abstractURL("/processes/:filter?"),
  UPDATE_PROCESS: abstractURL("/processes/edit/:template_id/:process_id"),
  ADD_PROCESS: abstractURL("/processes/add/:template_id"),
  VIEW_PROCESS: abstractURL("/processes/view/:template_id/:process_id"),

  UPLOAD_FILE: abstractURL("/upload/file"),

  MY_PROFILE: abstractURL("/profile"),
  SETTINGS: abstractURL("/settings"),
  HELP: abstractURL("/help"),
  MESSAGES: abstractURL("/messages"),
  AUDIT: abstractURL("/audit/:filter?"),
  ADMINS: abstractURL("/admins/:tab?"),
  ADMINS_TAB_ADD: abstractURL("/admins/:tab/add"),
  DASHBOARD: abstractURL("/dashboard"),
  TEST: abstractURL("/test"),
  ADD_TEAM: abstractURL("/teams/add"),

  CLOCK_MANAGER: abstractURL("/clock_manager"),
  CLOCK_CREATOR: abstractURL("/clock_manager/create"),
  CLOCK_EDITOR: abstractURL("/clock_manager/edit/:type/:clock_id"),
  // client review
  CLIENT_REVIEW: abstractURL("/review/:template_id/:process_email_key"),
  // client management
  CLIENT_MANAGER: abstractURL("/client_manager"),
  ADD_CLIENT_MANAGER: abstractURL("/client_manager/create"),
  CLIENT_MANAGER_FORM: abstractURL("/client_manager/:action/:client_id?"),
  // schedule
  SCHEDULE: abstractURL("/schedule"),
  // jock console
  JOCK_CONSOLE: abstractURL("/jock-console"),
  JOCK_CONSOLE_MANAGER: abstractURL("/jock-console-manager"),
  // winners list
  WINNERS: abstractURL("/winners"),
  WINNER: abstractURL("/winner/:action/:winner_id"),
  // system template
  SYSTEM_TEMPLATES: abstractURL("/system-templates"),
  // promotion 1099 reports
  PROMOTION_REPORTS: abstractURL("/1099-reports"),
  // production settings
  PRODUCTION_SETTINGS: abstractURL("/production-settings"),
  // logout
  LOGOUT: abstractURL("/logout"),
  // enterprise settings
  ENTERPRISE_SETTINGS: abstractURL("/enterprise-settings"),
  // hub manager
  HUB_MANAGER: abstractURL("/hub-manager"),
  // team management
  TEAM_MANAGEMENT: abstractURL("/team-management"),
  // user profile
  USER: abstractURL("/user/:user_id"),
};
