import React from "react";
import { get, find } from "lodash";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";

const OrderHeadInfo = (props) => {
  const { process, bem } = props;
  const spotInfo = get(process, "data.fields.spot_info.value", []);
  const processKey = get(process, "data.fields.key.value", "");
  const spotLinked =
    spotInfo.length === 1
      ? spotInfo[0]
      : find(spotInfo, (spot) => spot.key === processKey);
  const isci = get(spotLinked, "isci", "");
  const cartNumber = get(spotLinked, "cart_number", "");

  if (!isci && !cartNumber) return null;
  return (
    <div className={bem.e("order-head-info")}>
      {isci ? (
        <div className={bem.e("order-head-info-item")}>
          <strong>
            <FormattedMessage id="process > field isci / ad id" />:
          </strong>
          <span>{isci}</span>
        </div>
      ) : null}
      {cartNumber ? (
        <div
          className={classNames(
            bem.e("order-head-info-item"),
            bem.e("order-head-info-cart")
          )}
        >
          <strong>
            <FormattedMessage id="process > cart text" />:
          </strong>
          <span>{cartNumber}</span>
        </div>
      ) : null}
    </div>
  );
};
export default OrderHeadInfo;
