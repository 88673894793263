import React from "react";
import { Redirect } from "react-router";

import Page from "components/Page";
import PrivilegedComponent from "components/PrivilegedComponent";
import ResourceList from "components/ResourceList";

const ResourcesPage = props => {
  return (
    <PrivilegedComponent requires={[]}>
      {({ isValid }) => {
        if (isValid) {
          return (
            <Page title="" applyPadding={false}>
              <ResourceList {...props} />
            </Page>
          );
        } else {
          return <Redirect to="/" />;
        }
      }}
    </PrivilegedComponent>
  );
};

export default ResourcesPage;
