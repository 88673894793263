import { connect } from "react-redux";
import ItemScheduling from "./view";
import { get } from "lodash";
import { getHours } from "utils/helpers";
import { injectIntl } from "react-intl";
const Enhanced = connect((state) => {
  const clocksData = get(state, "programmings.clocksData.data", {});
  return {
    auth: state.auth,
    dayparts: get(clocksData, "dayparts", []),
    daypartsHours: get(clocksData, "dayparts_hours", []),
    showsHours: get(clocksData, "shows_hours", []),
    shows: get(clocksData, "shows", []),
    hours: getHours(),
    dateFormatByServer: get(
      state,
      "servers.currentServer.data.date_format",
      "MM/DD/YYYY"
    ),
  };
}, {})(ItemScheduling);

export default injectIntl(Enhanced);
