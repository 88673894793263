import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import bn from "utils/bemnames";
import ServerSecurityPolicyForm from "components/ServerSecurityPolicyForm";
import { FormattedMessage } from "react-intl";
const bem = bn.create("security-policy-modal");

class View extends React.Component {
  render() {
    const { isOpen, onToggle, onFormSubmit,ids } = this.props;
    return (
      <>
        <Modal isOpen={isOpen} className={bem.b()} size="lg">
          <ModalHeader toggle={onToggle} className={bem.e("header")}>
            <FormattedMessage id="security > title" />
          </ModalHeader>
          <ModalBody className={bem.e("body")}>
            <ServerSecurityPolicyForm
              onFormSubmit={onFormSubmit}
              onCancel={onToggle}
              ids={ids}
              onToggle={onToggle}
            />
          </ModalBody>
        </Modal>
      </>
    );
  }
}
View.propTypes = {
  onToggle: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};
View.defaultProps = {
  isOpen: false,
  onToggle: () => {},
  onFormSubmit: () => {}
};
export default View;
