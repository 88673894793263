import React from "react";
import classnames from "classnames";
import bn from "utils/bemnames";
import { get } from "lodash";
import { Row, Col, Button } from "reactstrap";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { getEditableTabsByStage, getEditableTabByUser } from "utils/helpers";

const bem = bn.create("production-reschedule");
const ProductionReschedule = (props) => {
  const { process, step, template, user, dateFormatByServer } = props;
  const isReschedule = !!get(
    process,
    "data.fields.original_order_end_date.value"
  );
  if (!isReschedule) return null;
  const isTabCanEditByStage = getEditableTabsByStage(step, process, user);
  const {
    isAssigned,
    isEditor,
    isUserCompletedStep,
    isUserAssignedToStepRoles,
    isStepCanEditAnyTime,
    isChannelManager,
  } = getEditableTabByUser({
    step,
    process,
    user,
    template: template.key,
  });
  let isShowEditButton = false;
  const processStep = get(process, "data.fields.process_step.value");
  const isDraft = processStep === "draft";
  const isTabCanEditByUser = isAssigned && isEditor;
  if (
    (isTabCanEditByStage && isTabCanEditByUser) || // case when user go to the tab from current tasks
    (isEditor && isUserAssignedToStepRoles && isStepCanEditAnyTime) || // case order details can edit any time by config
    (isEditor && isDraft) || // case when order is still in draft should be to be edited by editor
    (isChannelManager && !isDraft) ||
    (isEditor && isUserCompletedStep)
  ) {
    // case when user assigned to step by role and completed this step
    isShowEditButton = true;
  }
  const isCompleted =
    get(process, "data.fields.continuity_status.value") === "completed";
  const originalStartDate = moment(
    get(process, "data.fields.original_order_start_date.value")
  ).format(dateFormatByServer);
  const originalEndDate = moment(
    get(process, "data.fields.original_order_end_date.value")
  ).format(dateFormatByServer);
  const startDate = moment(
    get(process, "data.fields.order_start_date.value")
  ).format(dateFormatByServer);
  const endDate = moment(
    get(process, "data.fields.order_end_date.value")
  ).format(dateFormatByServer);
  return (
    <div className={bem.b()}>
      <Row>
        <Col xs={8}>
          <h2 className={bem.e("title")}>
            <FormattedMessage id="process > title rescheduled" />
          </h2>
          <div className={bem.e("info")}>
            <div className={classnames(bem.e("date"), bem.e("original-date"))}>
              <strong>
                <FormattedMessage id="process > original" />:
              </strong>
              <span>
                {originalStartDate}-{originalEndDate}
              </span>
            </div>
            <div className={bem.e("date")}>
              <strong>
                <FormattedMessage id="process > new" />:
              </strong>
              <span>
                {startDate}-{endDate}
              </span>
            </div>
          </div>
          <p className={bem.e("description")}>
            <FormattedMessage id="process > description rescheduled" />
          </p>
        </Col>
        <Col xs={4}>
          <div className={bem.e("buttons")}>
            {!isCompleted ? (
              <Button
                color="blue"
                className={classnames(
                  "btn-radius",
                  bem.e("button-mark-as-done")
                )}
                disabled={!isShowEditButton}
                onClick={(event) => {
                  props.buttonClicked(
                    step,
                    step.fields.find((f) => f.key === "save_button"),
                    process,
                    template,
                    user,
                    event,
                    null,
                    true
                  );
                }}
              >
                <FormattedMessage id="process > button mark as done" />
              </Button>
            ) : (
              <Button
                color="blue"
                outline
                className={classnames("btn-radius", bem.e("button-completed"))}
                disabled
              >
                <FormattedMessage id="process > completed" />
              </Button>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default ProductionReschedule;
