import * as React from "react";
import { Formik } from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { Row, Col, FormGroup, Form, Button, Label } from "reactstrap";
import SelectTags, {
  TAG_TYPE_FORMAT,
  TAG_TYPE_DMAs,
} from "components/SelectTags";
import { FormattedMessage } from "react-intl";
import NumberFormat from "react-number-format";
import classnames from "classnames";
import { get, first, map, split, isEqual, filter, join } from "lodash";
import { Asterisk } from "components/Elements";
import Spinner from "components/Spinner";
import TextInput from "components/TextInput";
import DaysOfWeek from "components/DaysOfWeek";
import UploadFile from "components/UploadFile";
import FileView from "components/FileView";
import EditImageModal from "components/EditImageModal";
import CustomMultiSelectDropdown from "components/CustomMultiSelectDropdown";
import mimeTypes from "components/MimeTypes";
import TextInputFormatted from "components/TextInputFormatted";
import Dropdown from "components/Dropdown";
import PrivilegedComponent from "components/PrivilegedComponent";
import BlockingComponent from "components/BlockingComponent";
import TimezonePicker from "components/TimezonePicker";
import TagFormModal from "components/TagFormModal";
import ConfirmationModal from "components/ConfirmationModal";
import history from "components/History";
import ToastManager from "components/ToastManager";
import bn from "utils/bemnames";
import { PhoneNumberFormat, productsMap } from "utils/config";
import { PRIVILEGES } from "utils/constants";
import { getFileName } from "utils/helpers";
import StationProductSection from "./components/StationProductSection";

const bem = bn.create("station-form");
export const MODE_EDIT = "edit";
export const MODE_VIEW = "view";
export const MODE_ADD = "add";
class StationFrom extends React.PureComponent {
  renderForm = () => {
    const {
      intl,
      isLoading,
      station,
      isUpdate,
      auth,
      currentServer,
      alreadySelectedPrivileges,
      shouldBlockNavigation,
      setShouldBlockNavigation,
      setIsOpenEditLogoModal,
      isOpenEditLogoModal,
    } = this.props;

    //support admins are not allowed to add stations
    if (
      get(auth, "user.is_support_admin") &&
      window.location.href.indexOf("stations/add") > -1
    ) {
      history.push("/");
    }
    const isGodAdmin = get(this.props, "auth.user.is_god_admin", false);
    // const isGroupAdmin = get(this.props, "auth.user.broadcasting_group_admin");
    const formikRef = React.createRef();
    const stationData = get(station, "data", {});
    const currentZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const BGCountry = get(auth, "info.server_country", "US");

    const initialValues = {
      isUpdate: isUpdate,
      name: stationData.name || "",
      call_letters: stationData.call_letters || "",
      digits: stationData.digits || "",
      format: filter(
        stationData.tags_json,
        (item) => item.type === "format"
      ).map((item) => ({ value: item.id, label: item.title })),
      dmas: filter(
        stationData.tags_json,
        (item) => item.type === "dmas"
      ).map((item) => ({ value: item.id, label: item.title })),
      logo: stationData.logo
        ? {
            path: stationData.logo,
            name: getFileName(stationData.logo),
            type: mimeTypes.lookup(stationData.logo),
          }
        : "",
      slogan: stationData.slogan || "",
      add1: stationData.add1 || "",
      add2: stationData.add2 || "",
      city: stationData.city || "",
      state: stationData.state || "",
      zip: stationData.zip || "",
      phone: stationData.phone || "",
      admin_email: stationData.admin_email || "",
      admin_first_name: stationData.admin_first_name || "",
      admin_last_name: stationData.admin_last_name || "",
      contact_email: stationData.contact_email || "",
      time_open: stationData.time_open
        ? {
            value: stationData.time_open,
            label: `${stationData.time_open} am`,
          }
        : "",
      time_close: stationData.time_close
        ? {
            value: stationData.time_close,
            label: `${stationData.time_close} pm`,
          }
        : "",
      office_days: stationData.office_days
        ? split(stationData.office_days, ",").map(Number)
        : [],
      station_privileges: alreadySelectedPrivileges || [],
      timezone: stationData.timezone || currentZone,
      products: stationData.products || [],
      eligible_geographies: stationData.eligible_geographies || [],
    };
    const serverData = currentServer || {};
    const geographiesOptions = map(
      get(serverData, "neighboring_countries", []),
      (item) => ({
        label: `${item} citizens`,
        value: item,
      })
    );
    const form = (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        ref={formikRef}
        validationSchema={Yup.object().shape({
          isUpdate: Yup.boolean(),
          name: Yup.string().nullable(),
          call_letters: Yup.string().required(
            intl.formatMessage({
              id: "validate > call letters is required",
            })
          ),
          digits: Yup.string().nullable(),
          dmas: Yup.array().required(
            intl.formatMessage({
              id: "validate > dmas is required",
            })
          ),
          format: Yup.array().required(
            intl.formatMessage({
              id: "validate > format is required",
            })
          ),
          city: Yup.string().required(
            intl.formatMessage({
              id: "validate > city is required",
            })
          ),
          slogan: Yup.string().max(200),
          add1: Yup.string().nullable(),
          state: Yup.string().nullable(),
          zip: Yup.string().nullable(),
          phone: Yup.string().nullable(),
          contact_email: Yup.string()
            .email(intl.formatMessage({ id: "validate > invalid email" }))
            .nullable(),
          time_open: Yup.string().nullable(),
          time_close: Yup.string().nullable(),
          office_days: Yup.array().nullable(),
          timezone: Yup.string().nullable(),
          eligible_geographies: Yup.array().nullable(),
        })}
        onSubmit={this.props.onFormSubmit}
      >
        {(formProps) => {
          const errors = formProps.errors;
          const touched = formProps.touched;
          const values = formProps.values;
          const isChanged = !isEqual(initialValues, values);
          if (isChanged !== shouldBlockNavigation) {
            setTimeout(() => setShouldBlockNavigation(isChanged), 20);
          }

          const toggleProduct = (product) => () => {
            let newProducts = [...values.products];
            if (newProducts.includes(product)) {
              newProducts = newProducts.filter((p) => p !== product);
            } else {
              newProducts.push(product);
            }
            if (
              !newProducts.includes("production") &&
              serverData.production_enterprise_enabled
            ) {
              newProducts = newProducts.filter(
                (p) => p !== "enterprise_production"
              );
            }
            formProps.setFieldValue("products", newProducts);
            setShouldBlockNavigation(true);
          };
          const productsOptions = [];
          if (serverData.is_production) {
            productsOptions.push({
              label: productsMap.production.label,
              showMultiMarket: false,
              multiMarketChecked: false,
              enabled: values.products.includes(productsMap.production.value),
              onToggle: toggleProduct(productsMap.production.value),
            });
          }
          console.log("serverData", serverData);
          if (serverData.production_enterprise_enabled) {
            productsOptions.push({
              label: productsMap.enterprise_production.label,
              showMultiMarket: false,
              enabled: values.products.includes(
                productsMap.enterprise_production.value
              ),
              onToggle: toggleProduct(productsMap.enterprise_production.value),
              sectionClass: bem.e("section-enterprise"),
            });
          }
          if (serverData.is_promotion) {
            productsOptions.push({
              label: productsMap.promotion.label,
              showMultiMarket: false,
              multiMarketChecked: serverData.promotion_multimarket,
              enabled: values.products.includes(productsMap.promotion.value),
              onToggle: toggleProduct(productsMap.promotion.value),
            });
          }
          if (serverData.enable_digital) {
            productsOptions.push({
              label: productsMap.digital.label,
              showMultiMarket: false,
              multiMarketChecked: serverData.digital_multimarket,
              enabled: values.products.includes(productsMap.digital.value),
              onToggle: toggleProduct(productsMap.digital.value),
            });
          }
          if (serverData.enable_wo_traffic) {
            productsOptions.push({
              label: productsMap.wo_traffic.label,
              enabled: values.products.includes(productsMap.wo_traffic.value),
              onToggle: toggleProduct(productsMap.wo_traffic.value),
            });
          }
          if (serverData.enable_wo_dubbing) {
            productsOptions.push({
              label: productsMap.wo_dubbing.label,
              enabled: values.products.includes(productsMap.wo_dubbing.value),
              onToggle: toggleProduct(productsMap.wo_dubbing.value),
            });
          }

          return (
            <React.Fragment>
              <Form
                onSubmit={formProps.handleSubmit}
                onChange={() => {
                  setShouldBlockNavigation(true);
                }}
              >
                <div className={bem.e("station-wrapper")}>
                  <h2 className={bem.e("title")}>
                    <FormattedMessage id={"station > detail title"} />
                  </h2>
                  <div className={bem.e("content-form")}>
                    <div className={bem.e("form-padding")}>
                      <Row>
                        <Col xs={6} className={bem.e("col-reset")}>
                          <FormGroup>
                            <TextInput
                              label={
                                <span>
                                  <FormattedMessage id="station > entity name" />
                                </span>
                              }
                              type="text"
                              name="name"
                              placeholder={intl.formatMessage({
                                id: "station > enter entity name",
                              })}
                              value={values.name}
                              onChange={({ target }) => {
                                formProps.setFieldValue("name", target.value);
                                setShouldBlockNavigation(true);
                              }}
                              onBlur={formProps.handleBlur}
                              error={touched.name && errors.name}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={6} className={bem.e("col-reset")}>
                          <FormGroup>
                            {!(
                              !isGodAdmin && this.props.isViewMode === MODE_EDIT
                            ) && !get(auth, "user.is_support_admin") ? (
                              <TextInput
                                label={
                                  <span>
                                    <FormattedMessage id="station > letters" />
                                    <Asterisk>*</Asterisk>
                                  </span>
                                }
                                type="text"
                                name="call_letters"
                                placeholder={intl.formatMessage({
                                  id: "station > enter letters",
                                })}
                                value={values.call_letters}
                                onChange={({ target }) => {
                                  formProps.setFieldValue(
                                    "call_letters",
                                    target.value
                                  );
                                  setShouldBlockNavigation(true);
                                }}
                                onBlur={formProps.handleBlur}
                                error={
                                  touched.call_letters && errors.call_letters
                                }
                              />
                            ) : (
                              <>
                                <Label>
                                  <FormattedMessage id="station > letters" />
                                </Label>
                                <div className={bem.e("view-value")}>
                                  {values.call_letters}
                                </div>
                              </>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6} className={bem.e("col-reset")}>
                          <FormGroup>
                            <TextInput
                              label={
                                <span>
                                  <FormattedMessage id="station > digits" />
                                </span>
                              }
                              type="text"
                              name="digits"
                              placeholder={intl.formatMessage({
                                id: "station > enter digits",
                              })}
                              value={values.digits}
                              onChange={formProps.handleChange}
                              onBlur={formProps.handleBlur}
                              error={touched.digits && errors.digits}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={6} className={bem.e("col-reset")}>
                          <FormGroup>
                            <TextInput
                              label={<FormattedMessage id="station > slogan" />}
                              type="text"
                              name="slogan"
                              placeholder={intl.formatMessage({
                                id: "station > enter slogan",
                              })}
                              value={values.slogan}
                              onChange={formProps.handleChange}
                              onBlur={formProps.handleBlur}
                              error={touched.slogan && errors.slogan}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6} className={bem.e("col-reset")}>
                          <FormGroup>
                            <Label>
                              <FormattedMessage id="station > locations" />
                              <Asterisk>*</Asterisk>
                            </Label>
                            <SelectTags
                              isMulti={false}
                              allowSelectAll={false}
                              checked={values.dmas}
                              placeholder={intl.formatMessage({
                                id: "station > placeholder select",
                              })}
                              name="dmas"
                              onChange={(data) => {
                                formProps.setFieldValue("dmas", [data]);
                                setShouldBlockNavigation(true);
                              }}
                              type={TAG_TYPE_DMAs}
                              allowNew={true}
                              createLabel={intl.formatMessage({
                                id: "station > add new unrated market",
                              })}
                              onCreateOption={() => {
                                this.props.setIsOpenTagFormModal(true);
                                this.props.setTagFormType(TAG_TYPE_DMAs);
                              }}
                              error={touched.dmas && errors.dmas}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={6} className={bem.e("col-reset")}>
                          <FormGroup>
                            <Label>
                              <FormattedMessage id="station > format" />
                              <Asterisk>*</Asterisk>
                            </Label>
                            <SelectTags
                              isMulti={false}
                              allowSelectAll={false}
                              checked={values.format}
                              name="format"
                              placeholder={intl.formatMessage({
                                id: "station > placeholder select",
                              })}
                              onChange={(data) => {
                                formProps.setFieldValue("format", [data]);
                                setShouldBlockNavigation(true);
                              }}
                              allowNew={true}
                              createLabel={intl.formatMessage({
                                id: "station > add new format",
                              })}
                              onCreateOption={() => {
                                this.props.setIsOpenTagFormModal(true);
                                this.props.setTagFormType(TAG_TYPE_FORMAT);
                              }}
                              type={TAG_TYPE_FORMAT}
                              error={touched.format && errors.format}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6} className={bem.e("col-reset")}>
                          <FormGroup>
                            <Label>
                              <FormattedMessage id="station > eligible listeners" />
                            </Label>
                            {geographiesOptions.length === 1 ? (
                              <div
                                className={bem.e("selected-single-eligible")}
                              >
                                {first(geographiesOptions).label}
                              </div>
                            ) : (
                              <CustomMultiSelectDropdown
                                name="eligible_geographies"
                                value={map(
                                  values.eligible_geographies,
                                  (item) => ({
                                    label: `${item} citizens`,
                                    value: item,
                                  })
                                )}
                                options={geographiesOptions}
                                placeholder={intl.formatMessage({
                                  id: "station > please select",
                                })}
                                isAllowAllOption={false}
                                isMulti={true}
                                error={get(errors, "eligible_geographies")}
                                onChange={(value) => {
                                  formProps.setFieldValue(
                                    "eligible_geographies",
                                    map(value, (item) => item.value)
                                  );
                                  setShouldBlockNavigation(true);
                                }}
                              />
                            )}
                          </FormGroup>
                        </Col>
                        {geographiesOptions.length > 1 && (
                          <Col xs={6} className={bem.e("col-reset")}>
                            <span className={bem.e("help-text")}>
                              <FormattedMessage id="station > eligible geographies help text" />
                            </span>
                          </Col>
                        )}
                      </Row>
                      <Row>
                        <Col xs={6} className={bem.e("col-reset")}>
                          <FormGroup>
                            <Label>
                              <FormattedMessage id="station > logo" />
                            </Label>
                            {get(values, "logo.path") ? (
                              <FileView
                                file={values.logo}
                                onDeleted={() => {
                                  setTimeout(() => {
                                    formProps.setFieldValue("logo", "", true);
                                    formProps.setFieldTouched("logo", true);
                                    setShouldBlockNavigation(true);
                                  }, 20);
                                }}
                                onUploaded={(files) => {
                                  const file = first(files);
                                  formProps.setFieldValue("logo", file);
                                  setShouldBlockNavigation(true);
                                }}
                                actions={["rename", "trash", "view"]}
                              />
                            ) : (
                              <UploadFile
                                url={get(values, "logo.path")}
                                mimes={["image/jpeg", "image/png"]}
                                fieldName={"logo"}
                                actions={[]}
                                onDeleted={() => {
                                  setTimeout(() => {
                                    formProps.setFieldValue("logo", "", true);
                                    formProps.setFieldTouched("logo", true);
                                    setShouldBlockNavigation(true);
                                  }, 20);
                                }}
                                onUploaded={(value) => {
                                  formProps.setFieldValue("logo", value);
                                  setShouldBlockNavigation(true);
                                }}
                                showRemoveIcon={false}
                                maxSize={200000} //200kb
                              />
                            )}
                          </FormGroup>
                        </Col>
                        <Col xs={6} className={bem.e("col-reset")}>
                          <FormGroup>
                            <Label>
                              <span>&nbsp;</span>
                            </Label>
                            <span className={bem.e("upload-description")}>
                              <FormattedMessage id="station > upload logo format" />
                              <FormattedMessage id="station > upload logo orientation" />
                              <FormattedMessage id="station > upload logo dimensions" />
                              <FormattedMessage id="station > upload logo size" />
                            </span>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <div className={bem.e("divider")} />
                    <div className={bem.e("form-padding")}>
                      <Row>
                        <Col xs={6} className={bem.e("col-reset")}>
                          <FormGroup>
                            <TextInput
                              label={
                                <FormattedMessage id="station > address 1" />
                              }
                              type="text"
                              name="add1"
                              placeholder={intl.formatMessage({
                                id: "station > enter address",
                              })}
                              value={values.add1}
                              onChange={formProps.handleChange}
                              onBlur={formProps.handleBlur}
                              error={touched.add1 && errors.add1}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={6} className={bem.e("col-reset")}>
                          <FormGroup>
                            <TextInput
                              label={
                                <FormattedMessage id="station > address 2" />
                              }
                              type="text"
                              name="add2"
                              placeholder={intl.formatMessage({
                                id: "station > enter address",
                              })}
                              value={values.add2}
                              onChange={formProps.handleChange}
                              onBlur={formProps.handleBlur}
                              error={touched.add2 && errors.add2}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6} className={bem.e("col-reset")}>
                          <Row>
                            <Col xs={12}>
                              <FormGroup>
                                <TextInput
                                  label={
                                    <>
                                      <FormattedMessage id="station > city" />
                                      <Asterisk>*</Asterisk>
                                    </>
                                  }
                                  type="text"
                                  name="city"
                                  placeholder={intl.formatMessage({
                                    id: "station > enter city",
                                  })}
                                  value={values.city}
                                  onChange={formProps.handleChange}
                                  onBlur={formProps.handleBlur}
                                  error={touched.city && errors.city}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={6} className={bem.e("col-reset")}>
                          <Row>
                            <Col xs={6}>
                              <FormGroup>
                                <TextInput
                                  label={
                                    <FormattedMessage
                                      id={
                                        BGCountry === "CA"
                                          ? "station > province"
                                          : "station > state"
                                      }
                                    />
                                  }
                                  type="text"
                                  name="state"
                                  placeholder={intl.formatMessage({
                                    id:
                                      BGCountry === "CA"
                                        ? "station > enter province"
                                        : "station > enter state",
                                  })}
                                  value={values.state}
                                  onChange={formProps.handleChange}
                                  onBlur={formProps.handleBlur}
                                  error={touched.state && errors.state}
                                />
                              </FormGroup>
                            </Col>
                            <Col xs={6}>
                              <FormGroup>
                                <TextInput
                                  label={
                                    <FormattedMessage
                                      id={
                                        BGCountry === "CA"
                                          ? "station > postal code"
                                          : "station > zip code"
                                      }
                                    />
                                  }
                                  type="text"
                                  name="zip"
                                  placeholder={intl.formatMessage({
                                    id:
                                      BGCountry === "CA"
                                        ? "station > enter postal code"
                                        : "station > enter zip code",
                                  })}
                                  value={values.zip}
                                  onChange={formProps.handleChange}
                                  onBlur={formProps.handleBlur}
                                  error={touched.zip && errors.zip}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6} className={bem.e("col-reset")}>
                          <FormGroup>
                            <TextInputFormatted
                              label={<FormattedMessage id="station > phone" />}
                              format={PhoneNumberFormat}
                              name="phone"
                              placeholder={intl.formatMessage({
                                id: "station > enter phone",
                              })}
                              value={values.phone}
                              onChange={(phone) => {
                                formProps.setFieldValue("phone", phone);
                                setShouldBlockNavigation(true);
                              }}
                              onBlur={formProps.handleBlur}
                              error={touched.phone && errors.phone}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={6} className={bem.e("col-reset")}>
                          <FormGroup>
                            <TextInput
                              label={<FormattedMessage id="station > email" />}
                              type="email"
                              name="contact_email"
                              placeholder={intl.formatMessage({
                                id: "station > enter email",
                              })}
                              value={values.contact_email}
                              onChange={formProps.handleChange}
                              onBlur={formProps.handleBlur}
                              error={
                                touched.contact_email && errors.contact_email
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      {/* Show only when add new station */}
                      {!isUpdate && false && (
                        <>
                          <Row>
                            <Col xs={6}>
                              <FormGroup>
                                <TextInput
                                  label={
                                    <span>
                                      <FormattedMessage id="station > admin first name" />
                                      <Asterisk>*</Asterisk>
                                    </span>
                                  }
                                  type="text"
                                  name="admin_first_name"
                                  placeholder={intl.formatMessage({
                                    id: "station > enter admin first name",
                                  })}
                                  value={values.admin_first_name}
                                  onChange={formProps.handleChange}
                                  onBlur={formProps.handleBlur}
                                  error={
                                    touched.admin_first_name &&
                                    errors.admin_first_name
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col xs={6}>
                              <FormGroup>
                                <TextInput
                                  label={
                                    <span>
                                      <FormattedMessage id="station > admin last name" />
                                      <Asterisk>*</Asterisk>
                                    </span>
                                  }
                                  type="text"
                                  name="admin_last_name"
                                  placeholder={intl.formatMessage({
                                    id: "station > enter admin last name",
                                  })}
                                  value={values.admin_last_name}
                                  onChange={formProps.handleChange}
                                  onBlur={formProps.handleBlur}
                                  error={
                                    touched.admin_last_name &&
                                    errors.admin_last_name
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12}>
                              <FormGroup>
                                <TextInput
                                  label={
                                    <span>
                                      <FormattedMessage id="station > admin email" />
                                      <Asterisk>*</Asterisk>
                                    </span>
                                  }
                                  type="text"
                                  name="admin_email"
                                  placeholder={intl.formatMessage({
                                    id: "station > enter admin email",
                                  })}
                                  value={values.admin_email}
                                  onChange={formProps.handleChange}
                                  onBlur={formProps.handleBlur}
                                  error={
                                    touched.admin_email && errors.admin_email
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </>
                      )}
                      <Row>
                        <Col xs={6} className={bem.e("col-reset")}>
                          <FormGroup>
                            <Label>
                              <FormattedMessage id="station > timezone" />
                            </Label>
                            <TimezonePicker
                              value={values.timezone}
                              onChange={({ value }) => {
                                formProps.setFieldValue("timezone", value);
                                setShouldBlockNavigation(true);
                              }}
                            />
                            {touched.timezone && errors.timezone && (
                              <div className="text-danger">
                                {errors.timezone}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6} className={bem.e("col-reset")}>
                          <FormGroup>
                            <Label>
                              <FormattedMessage id="station > office hours" />
                            </Label>
                            <Row>
                              <Col xs={5}>
                                <Dropdown
                                  value={values.time_open}
                                  placeholder={intl.formatMessage({
                                    id: "station > select start time",
                                  })}
                                  onChange={(selectedOption) => {
                                    formProps.setFieldValue(
                                      "time_open",
                                      selectedOption
                                    );
                                    setShouldBlockNavigation(true);
                                  }}
                                  options={this.props.timeOpenOptions}
                                  name="time_open"
                                  error={touched.time_open && errors.time_open}
                                />
                              </Col>
                              <Col xs={2}>
                                <Label className={bem.e("to-title")}>
                                  <FormattedMessage id="station > to" />
                                </Label>
                              </Col>
                              <Col xs={5}>
                                <Dropdown
                                  value={values.time_close}
                                  placeholder={intl.formatMessage({
                                    id: "station > select end time",
                                  })}
                                  onChange={(selectedOption) => {
                                    formProps.setFieldValue(
                                      "time_close",
                                      selectedOption
                                    );
                                    setShouldBlockNavigation(true);
                                  }}
                                  options={this.props.timeCloseOptions}
                                  name="time_close"
                                  error={touched.time_close && errors.time_open}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        <Col xs={6} className={bem.e("col-reset")}>
                          <FormGroup>
                            <Label>
                              <FormattedMessage id="station > office days" />
                            </Label>
                            <DaysOfWeek
                              value={values.office_days}
                              onChange={(days) => {
                                formProps.setFieldValue("office_days", days);
                                setShouldBlockNavigation(true);
                              }}
                            />
                            {touched.office_days && errors.office_days && (
                              <div className="text-danger">
                                {errors.office_days}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  {isGodAdmin && (
                    <div
                      className={classnames(
                        bem.e("station-wrapper"),
                        bem.e("products-wrapper")
                      )}
                    >
                      <h2 className={bem.e("title")}>
                        <FormattedMessage
                          id={"station > products integrations"}
                        />
                      </h2>
                      <div className={bem.e("content-form")}>
                        <div className={bem.e("form-padding")}>
                          {productsOptions.map((option, index) => (
                            <StationProductSection
                              title={option.label}
                              bem={bem}
                              intl={intl}
                              key={index}
                              sectionClass={option.sectionClass}
                              checked={option.enabled}
                              onSwitchToggle={option.onToggle}
                              showMultiMarket={option.showMultiMarket}
                              multiMarketChecked={option.multiMarketChecked}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <FormGroup>
                  <div className={bem.e("form-buttons")}>
                    <Button
                      color="blue"
                      type="submit"
                      className="btn btn-radius"
                      disabled={isLoading}
                      onClick={() => {
                        setTimeout(() => {
                          const formErrors = get(
                            formikRef,
                            "current.state.errors"
                          );
                          if (formErrors && Object.keys(formErrors).length) {
                            ToastManager.show({
                              title: intl.formatMessage({
                                id: "toast > title not saved",
                              }),
                              message: intl.formatMessage({
                                id:
                                  "toast > message error please correct the hilighted fields",
                              }),
                              level: "error",
                            });
                          }
                        }, 40);
                      }}
                    >
                      <FormattedMessage
                        id={`process > button ${
                          !isLoading ? "submit" : "submitting"
                        }`}
                      />
                    </Button>
                    {this.props.deleteButton}
                    {this.props.backButton}
                  </div>
                </FormGroup>
              </Form>
              <TagFormModal
                isOpen={!!this.props.isOpenTagFormModal}
                type={this.props.tagFormType}
                onToggle={() => this.props.setIsOpenTagFormModal(false)}
                onSubmit={(newMarket) => {
                  const fieldName =
                    this.props.tagFormType === TAG_TYPE_FORMAT
                      ? "format"
                      : "dmas";
                  formProps.setFieldValue(fieldName, [
                    {
                      value: get(newMarket, "id"),
                      label: get(newMarket, "title"),
                    },
                  ]);
                  setShouldBlockNavigation(true);
                  this.props.setIsOpenAlert(true);
                  setTimeout(() => {
                    this.props.setIsOpenAlert(false);
                  }, 2000);
                }}
              />
              <ConfirmationModal
                isOpen={this.props.isOpenAlert}
                state="success"
                title={
                  this.props.tagFormType === TAG_TYPE_FORMAT ? (
                    <FormattedMessage id="station > message add format success" />
                  ) : (
                    <FormattedMessage id="station > message add unrated market success" />
                  )
                }
                onToggle={() =>
                  this.props.setIsOpenAlert(!this.props.isOpenAlert)
                }
              />
              <EditImageModal
                isOpen={isOpenEditLogoModal}
                onToggle={() => setIsOpenEditLogoModal(!isOpenEditLogoModal)}
                mimes={["image/png"]}
                maxSize={200000} //200kb
                onUploaded={(file) => {
                  setIsOpenEditLogoModal(false);
                  setTimeout(() => {
                    formProps.setFieldValue("logo", file);
                    setShouldBlockNavigation(true);
                  }, 20);
                }}
                url={get(values, "logo.path")}
                title={<FormattedMessage id="server > modal edit logo title" />}
              />
              {/* <PreviewFileModal
                isOpen={isOpenPreviewFileModal}
                onToggle={() =>
                  setIsOpenPreviewFileModal(!isOpenPreviewFileModal)
                }
                onCancel={() => setIsOpenPreviewFileModal(false)}
                file={values.logo}
                files={[values.logo]}
                isEdit={true}
                onUploadedFiles={(files) => {
                  setIsOpenPreviewFileModal(false);
                  setTimeout(() => {
                    formProps.setFieldValue("logo", first(files));
                  }, 20);
                }}
              /> */}
            </React.Fragment>
          );
        }}
      </Formik>
    );
    return (
      <React.Fragment>
        {form}
        <BlockingComponent
          isBlocked={shouldBlockNavigation}
          modalProps={{
            isShowConfirm: true,
            isShowDiscard: false,
            isCancelConfirmedNavigation: true,
            onCancel: () => {
              setShouldBlockNavigation(false); // reset
            },
            // onConfirm: () => {
            //   const buttonSubmit = document.getElementById(`btn-submit`);
            //   // trigger click to submit the form
            //   if (buttonSubmit) buttonSubmit.click();
            // },
          }}
        />
      </React.Fragment>
    );
    // return (
    //   <WarnAboutUnsavedChanges when={shouldBlockNavigation} children={form} />
    // );
  };
  renderView = () => {
    const { setIsViewMode, station, intl } = this.props;
    const stationData = get(station, "data", {});
    const isGodAdmin = get(this.props, "auth.user.is_god_admin", false);
    const BGCountry = get(this.props, "auth.info.server_country", "US");
    const logo = {
      path: stationData.logo,
      name: getFileName(stationData.logo),
      type: mimeTypes.lookup(stationData.logo),
    };

    const format = filter(
      stationData.tags_json,
      (item) => item.type === "format"
    )
      .map((item) => item.title)
      .join(", ");
    const dmas = filter(stationData.tags_json, (item) => item.type === "dmas")
      .map((item) => item.title)
      .join(", ");

    const renderProductsAndIntegration = (products) => {
      return products.map((product) => {
        const productItem = productsMap[product];
        const productLabel = productItem ? productItem.label : "";
        if (productLabel) {
          return (
            <span className={bem.e("assigned-product")}>
              <FormattedMessage id={`station > title ${productLabel}`} />
            </span>
          );
        }

        return "";
      });
    };

    return (
      <>
        <div className={bem.e("station-wrapper")}>
          <h2 className={bem.e("title")}>
            <FormattedMessage id={"station > detail title"} />
          </h2>
          <div className={bem.e("content-view")}>
            <div className={bem.e("form-padding")}>
              <Row>
                <Col xs={6} className={bem.e("col-reset")}>
                  <FormGroup>
                    <Label>
                      <FormattedMessage id="station > entity name" />
                    </Label>
                    <div className={bem.e("view-value")}>
                      {get(stationData, "name", "") ? (
                        stationData.name
                      ) : (
                        <FormattedMessage id="station > none" />
                      )}
                    </div>
                  </FormGroup>
                </Col>
                <Col xs={6} className={bem.e("col-reset")}>
                  <FormGroup>
                    <Label>
                      <FormattedMessage id="station > letters" />
                    </Label>
                    <div className={bem.e("view-value")}>
                      {get(stationData, "call_letters", "") ? (
                        stationData.call_letters
                      ) : (
                        <FormattedMessage id="station > none" />
                      )}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={6} className={bem.e("col-reset")}>
                  <FormGroup>
                    <Label>
                      <FormattedMessage id="station > digits" />
                    </Label>
                    <div className={bem.e("view-value")}>
                      {get(stationData, "digits", "") ? (
                        stationData.digits
                      ) : (
                        <FormattedMessage id="station > none" />
                      )}
                    </div>
                  </FormGroup>
                </Col>
                <Col xs={6} className={bem.e("col-reset")}>
                  <FormGroup>
                    <Label>
                      <FormattedMessage id="station > slogan" />
                    </Label>
                    <div className={bem.e("view-value")}>
                      {get(stationData, "slogan", "") ? (
                        stationData.slogan
                      ) : (
                        <FormattedMessage id="station > none" />
                      )}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={6} className={bem.e("col-reset")}>
                  <FormGroup>
                    <Label>
                      <FormattedMessage id="station > locations" />
                    </Label>
                    <div className={bem.e("view-value")}>
                      {dmas || <FormattedMessage id="station > none" />}
                    </div>
                  </FormGroup>
                </Col>
                <Col xs={6} className={bem.e("col-reset")}>
                  <FormGroup>
                    <Label>
                      <FormattedMessage id="station > format" />
                    </Label>
                    <div className={bem.e("view-value")}>
                      {format || <FormattedMessage id="station > none" />}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={6} className={bem.e("col-reset")}>
                  <FormGroup>
                    <Label>
                      <FormattedMessage id="station > eligible listeners" />
                    </Label>
                    <div className={bem.e("view-value")}>
                      {stationData.eligible_geographies ? (
                        join(
                          stationData.eligible_geographies.map(
                            (item) => `${item} citizens`
                          ),
                          ", "
                        )
                      ) : (
                        <FormattedMessage id="station > none" />
                      )}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={6} className={bem.e("col-reset")}>
                  <FormGroup>
                    <Label>
                      <FormattedMessage id="station > logo" />
                    </Label>
                    {get(logo, "path") ? (
                      <FileView file={logo} actions={["view"]} />
                    ) : (
                      <FormattedMessage id="station > none" />
                    )}
                  </FormGroup>
                </Col>
                <Col xs={6} className={bem.e("col-reset")}>
                  <FormGroup>
                    <Label>
                      <span>&nbsp;</span>
                    </Label>
                    <span className={bem.e("upload-description")}>
                      <FormattedMessage id="station > upload logo format" />
                      <FormattedMessage id="station > upload logo orientation" />
                      <FormattedMessage id="station > upload logo dimensions" />
                      <FormattedMessage id="station > upload logo size" />
                    </span>
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <div className={bem.e("divider")} />
            <div className={bem.e("form-padding")}>
              <Row>
                <Col xs={6} className={bem.e("col-reset")}>
                  <FormGroup>
                    <Label>
                      <FormattedMessage id="station > address 1" />
                    </Label>
                    <div className={bem.e("view-value")}>
                      {get(stationData, "add1", "") || (
                        <FormattedMessage id="station > none" />
                      )}
                    </div>
                  </FormGroup>
                </Col>
                <Col xs={6} className={bem.e("col-reset")}>
                  <FormGroup>
                    <Label>
                      <FormattedMessage id="station > address 2" />
                    </Label>
                    <div className={bem.e("view-value")}>
                      {get(stationData, "add2", "") || (
                        <FormattedMessage id="station > none" />
                      )}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={6} className={bem.e("col-reset")}>
                  <FormGroup>
                    <Label>
                      <FormattedMessage id="station > city" />
                    </Label>
                    <div className={bem.e("view-value")}>
                      {stationData.city || (
                        <FormattedMessage id="station > none" />
                      )}
                    </div>
                  </FormGroup>
                </Col>
                <Col xs={6} className={bem.e("col-reset")}>
                  <Row>
                    <Col xs={6} className={bem.e("col-reset")}>
                      <FormGroup>
                        <Label>
                          <FormattedMessage
                            id={
                              BGCountry === "CA"
                                ? "station > province"
                                : "station > state"
                            }
                          />
                        </Label>
                        <div className={bem.e("view-value")}>
                          {get(stationData, "state", "") || (
                            <FormattedMessage id="station > none" />
                          )}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col xs={6} className={bem.e("col-reset")}>
                      <FormGroup>
                        <Label>
                          <FormattedMessage
                            id={
                              BGCountry === "CA"
                                ? "station > postal code"
                                : "station > zip code"
                            }
                          />
                        </Label>
                        <div className={bem.e("view-value")}>
                          {get(stationData, "zip", "") || (
                            <FormattedMessage id="station > none" />
                          )}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs={6} className={bem.e("col-reset")}>
                  <FormGroup>
                    <Label>
                      <FormattedMessage id="station > phone" />
                    </Label>
                    <div className={bem.e("view-value")}>
                      {stationData.phone ? (
                        <NumberFormat
                          value={get(stationData, "phone", "")}
                          displayType={"text"}
                          format={PhoneNumberFormat}
                        />
                      ) : (
                        <FormattedMessage id="station > none" />
                      )}
                    </div>
                  </FormGroup>
                </Col>
                <Col xs={6} className={bem.e("col-reset")}>
                  <FormGroup>
                    <Label>
                      <FormattedMessage id="station > email" />
                    </Label>
                    <div className={bem.e("view-value")}>
                      {get(stationData, "contact_email", "") || (
                        <FormattedMessage id="station > none" />
                      )}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={6} className={bem.e("col-reset")}>
                  <FormGroup>
                    <Label>
                      <FormattedMessage id="station > office hours" />
                    </Label>
                    <div
                      className={bem.e("view-value")}
                    >{`${stationData.time_open || ""} am ${intl.formatMessage({
                      id: "station > to",
                    })} ${stationData.time_close || ""} pm`}</div>
                  </FormGroup>
                </Col>
                <Col xs={6} className={bem.e("col-reset")}>
                  <FormGroup>
                    <Label>
                      <FormattedMessage id="station > office days" />
                    </Label>
                    <DaysOfWeek
                      value={split(stationData.office_days, ",").map(Number)}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
            {isGodAdmin && (
              <React.Fragment>
                <h2 className={bem.e("title")}>
                  <FormattedMessage id={"station > products integrations"} />
                </h2>
                <div className={bem.e("form-padding")}>
                  <Row>
                    <Col className={bem.e("col-reset")}>
                      <FormGroup>
                        <div
                          className={classnames(
                            bem.e("view-value"),
                            bem.e("product-int-list")
                          )}
                        >
                          {renderProductsAndIntegration(
                            get(stationData, "products", [])
                          )}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
        <div className={bem.e("form-buttons")}>
          <Row>
            <Col className={bem.e("col-reset")}>
              <PrivilegedComponent requires={[PRIVILEGES.EDIT_STATIONS]}>
                <Button
                  type="button"
                  color="blue"
                  className="btn btn-radius"
                  onClick={() => setIsViewMode(MODE_EDIT)}
                >
                  <FormattedMessage id={`station > button edit`} />
                </Button>
              </PrivilegedComponent>

              {this.props.deleteButton}
              {this.props.backButton}
            </Col>
          </Row>
        </div>
      </>
    );
  };
  render() {
    const { isViewMode, isLoading } = this.props;
    return (
      <div className={bem.b()}>
        {isViewMode === MODE_EDIT || isViewMode === MODE_ADD
          ? this.renderForm()
          : this.renderView()}
        <Spinner isLoading={isLoading} />
      </div>
    );
  }
}
StationFrom.defaultProps = {
  isView: false,
  deleteButton: null,
  backButton: null,
};
StationFrom.propTypes = {
  isView: PropTypes.oneOf([MODE_ADD, MODE_VIEW, MODE_EDIT]),
  deleteButton: PropTypes.any,
  backButton: PropTypes.any,
};
export default StationFrom;
