import React from "react";
import bn from "utils/bemnames";
import { MenuIcon } from "components/CustomIcons";
import classnames from "classnames";
import { FaClose } from "react-icons/lib/fa";
import { FormattedMessage } from "react-intl";
import { labelToNameField } from "utils/helpers";
import { FormGroup, Row, Col, Label } from "reactstrap";
import ToggleSwitch from "components/ToggleSwitch";
import TextInput from "components/TextInput";
import { get, filter } from "lodash";
import { Asterisk } from "components/Elements";
import TextInputFormatted from "components/TextInputFormatted";

const bem = bn.create("form-builder-fields");

const SimpleLineText = ({
  intl,
  isNew,
  field,
  value,
  errors,
  touched,
  onChange,
  onDelete,
  allowDelete,
  isFormBuilder,
  highlightFields,
  isView,
  disabled,
  isMasterListener,
  colNumber,
  isRenderRow,
  isShowOffText,
}) => {
  // UI for form builder
  if (isFormBuilder) {
    if (isNew) {
      const isHighlightFormField =
        filter(
          highlightFields,
          (highlightFieldItem) =>
            highlightFieldItem.uuid === value.uuid &&
            highlightFieldItem.count_highlight > 0
        ).length > 0;
      return (
        <Row>
          <Col className="form-builder-field">
            <div className={bem.b()}>
              <div
                className={classnames(bem.e("form-field-group"), {
                  [bem.e("form-field-group-highlight")]: isHighlightFormField,
                  [bem.e("form-field-group-disable")]:
                    highlightFields.length > 0 &&
                    !isHighlightFormField &&
                    value.is_controlled,
                })}
              >
                <div className={bem.e("form-field-group-head")}>
                  <div className={bem.e("form-field-group-head-single-left")}>
                    <span className={bem.e("menu-icon")}>
                      <MenuIcon />
                    </span>
                    <Row>
                      <Col xs={9}>
                        <div
                          className={classnames(bem.e("input-label"), {
                            [bem.e("master-input-field")]: isMasterListener,
                          })}
                        >
                          <TextInput
                            label={null}
                            name="label"
                            type="text"
                            placeholder={intl.formatMessage({
                              id: "form builder > label single line text",
                            })}
                            value={value.label}
                            disabled={disabled}
                            onChange={({ target }) => {
                              onChange({
                                ...value,
                                label: target.value,
                              });
                            }}
                            error={get(errors, "label", "")}
                          />
                        </div>
                      </Col>
                      <Col xs={3}>
                        <FormGroup>
                          <ToggleSwitch
                            leftComponent={
                              <span
                                className={classnames({
                                  [bem.e("switch-active")]: !get(
                                    value,
                                    "is_required",
                                    false
                                  ),
                                })}
                              >
                                {isShowOffText
                                  ? intl.formatMessage({
                                      id: "form builder > off",
                                    })
                                  : ""}
                              </span>
                            }
                            rightComponent={
                              <span
                                className={classnames({
                                  [bem.e("switch-active")]: get(
                                    value,
                                    "is_required",
                                    false
                                  ),
                                })}
                              >
                                {intl.formatMessage({
                                  id: "form builder > required",
                                })}
                              </span>
                            }
                            name="is_required"
                            switchProps={{
                              checked: !get(value, "is_required", false),
                              onChange: (checked) => {
                                onChange({
                                  ...value,
                                  is_required: !checked,
                                });
                              },
                              disabled: disabled,
                              offColor: "#795AFA",
                              onColor: "#E3ECF1",
                              uncheckedIcon: false,
                              checkedIcon: false,
                              handleDiameter: 22,
                              width: 50,
                              height: 26,
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  {allowDelete && (
                    <div
                      className={bem.e("form-field-group-head-right")}
                      onClick={onDelete}
                    >
                      <FaClose size="23" color="#C2D4E0" />
                    </div>
                  )}
                </div>
                <Row className={bem.e("form-field-group-bottom")}>
                  <Col xs={8}>
                    <FormGroup>
                      <TextInput
                        label={
                          <span className={bem.e("label-hidden")}>{`  `}</span>
                        }
                        name=""
                        type="text"
                        placeholder={
                          value.placeholder ||
                          intl.formatMessage({
                            id: "form builder > placeholder single line text",
                          })
                        }
                        disabled={disabled}
                        value={value.placeholder || ""}
                        className={bem.e("text-placeholder")}
                        onChange={({ target }) => {
                          onChange({
                            ...value,
                            placeholder: target.value,
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={4}>
                    <FormGroup>
                      <TextInputFormatted
                        label={
                          <FormattedMessage id="form builder > max characters" />
                        }
                        type="text"
                        name="max_characters"
                        placeholder={intl.formatMessage({
                          id: "form builder > max characters placeholder",
                        })}
                        value={get(value, "max_characters", "")}
                        disabled={disabled}
                        onChange={(newValue) => {
                          onChange({
                            ...value,
                            max_characters: newValue,
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row>
          <Col>
            <div className={bem.b()}>
              <div className={bem.e("form-field-group-saved")}>
                <FormGroup>
                  <TextInput
                    label={
                      <span>
                        {get(value, "label", "")}{" "}
                        {get(value, "is_required", false) && (
                          <Asterisk>*</Asterisk>
                        )}
                      </span>
                    }
                    name=""
                    type="text"
                    placeholder={intl.formatMessage({
                      id: "form builder > placeholder single line text",
                    })}
                    onChange={() => {}}
                  />
                </FormGroup>
                {allowDelete && (
                  <div
                    className={bem.e("form-field-group-saved-button-close")}
                    onClick={onDelete}
                  >
                    <FaClose size="23" color="#C2D4E0" />
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    // ui for form
  } else {
    const fieldName = labelToNameField(get(field, "label", ""));
    if (isView) {
      return (
        <Row>
          <Col>
            <div className={bem.b()}>
              <div className={bem.e("form-field-group-saved")}>
                <Label>{get(field, "label", "")}</Label>
                <span className="order-view-value">
                  {value ? (
                    value
                  ) : (
                    <FormattedMessage id="form builder > none" />
                  )}
                </span>
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    const elementField = (
      <div className={bem.b()}>
        <div
          className={classnames(
            bem.e("form-field-group-saved"),
            "form-builder-edit-field-2"
          )}
        >
          <FormGroup className={bem.e("form-group")}>
            <TextInput
              label={
                <span>
                  {get(field, "label", "")}{" "}
                  {get(field, "is_required", false) && <Asterisk>*</Asterisk>}
                </span>
              }
              name={fieldName}
              maxCharacters={field.max_characters}
              type="text"
              placeholder={
                field.placeholder ||
                intl.formatMessage({
                  id: "form builder > placeholder single line text",
                })
              }
              value={value || ""}
              disabled={disabled}
              onChange={({ target }) => onChange(target.value)}
              error={
                get(touched, fieldName, false) && get(errors, fieldName, "")
                  ? get(errors, fieldName, "")
                  : ""
              }
            />
          </FormGroup>
        </div>
      </div>
    );
    if (isRenderRow)
      return (
        <Row>
          <Col>{elementField}</Col>
        </Row>
      );
    return <Col xs={colNumber}>{elementField}</Col>;
  }
};
SimpleLineText.defaultProps = {
  isNew: false,
  isFormBuilder: true,
  errors: {},
  touched: {},
  onChange: () => {},
  onDelete: () => {},
  allowDelete: true,
  isView: false,
  disabled: false,
  isMasterListener: false,
  colNumber: 6,
  isRenderRow: true,
  isShowOffText: true,
};
export default SimpleLineText;
