import EditImageModal from "./view";
import { compose, withState, withHandlers, lifecycle } from "recompose";
import { connect } from "react-redux";
import { uploadFile } from "store/actions/uploads";
import { injectIntl } from "react-intl";
import { getFileName, getFileObjectFromURL } from "utils/helpers";
export default injectIntl(
  compose(
    connect(() => ({}), {
      uploadFile,
    }),
    withState("isLoading", "setIsLoading", false),
    withState("file", "setFile", (props) => {
      const fileObject = getFileObjectFromURL(props.url);
      return fileObject;
    }),
    withState("image", "setImage", (props) => props.url || null),
    withState("isDisableDropZone", "setIsDisableDropZone", false),
    withState("imageCropped", "setImageCropped", null),
    withHandlers({
      onUploadImageCropped: ({
        imageCropped,
        setIsLoading,
        file,
        onUploaded,
        uploadFile,
      }) => async () => {
        if (imageCropped) {
          setIsLoading(true);
          const fileName = getFileName(file.name);
          const values = {
            isBlob: true,
            file: imageCropped,
            fileName,
          };
          const response = await uploadFile(values);
          setIsLoading(false);
          if (response.path) {
            onUploaded(response);
          }
        }
      },
    }),
    lifecycle({
      componentDidUpdate(prevProps) {
        if (this.props.url && this.props.url !== prevProps.url) {
          const fileObject = getFileObjectFromURL(this.props.url);
          this.props.setFile(fileObject);
          this.props.setImage(this.props.url);
        }
      },
    })
  )(EditImageModal)
);
