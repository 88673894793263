import React from "react";
import {
  Form,
  FormGroup,
  Button,
  Card,
  CardBody,
  CardHeader
} from "reactstrap";
import { get, find, isEqual } from "lodash";
import { FormattedMessage } from "react-intl";
import { Label } from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import Dropdown from "components/Dropdown";
import TextInput from "components/TextInput";
import bn from "utils/bemnames";
import BlockingComponent from "components/BlockingComponent";
import ToggleSwitch from "components/ToggleSwitch";

import { language as defaultLangCode, languageList } from "utils/config";
const bem = bn.create("setting-form");
const View = ({
  auth,
  isLoading,
  onFormSubmit,
  onNotify,
  setLanguage,
  intl,
  setShouldBlockNavigation,
  shouldBlockNavigation
}) => {
  const { user } = auth;
  const userLangCode = get(user, "lang", null);

  const defaultLanguage = find(
    languageList,
    item => item.value === defaultLangCode
  );
  const settingInitialValues = {
    notify: auth.notify || false,
    language: userLangCode
      ? {
          value: userLangCode,
          label: get(
            find(languageList, item => item.value === userLangCode),
            "label",
            "English"
          )
        }
      : {
          value: defaultLangCode,
          label: get(defaultLanguage, "label", "English")
        }
  };
  const form = (
    <Card className={bem.b()}>
      <CardHeader>
        <FormattedMessage id={"settings > title"} />
      </CardHeader>
      <CardBody>
        <Formik
          enableReinitialize
          initialValues={settingInitialValues}
          validationSchema={Yup.object().shape({
            language: Yup.string()
              .required(
                intl.formatMessage({ id: "validate > please select language" })
              )
              .nullable()
          })}
          onSubmit={onFormSubmit}
        >
          {formProps => {
            const errors = formProps.errors;
            const touched = formProps.touched;
            const values = formProps.values;
            const isChanged = !isEqual(settingInitialValues, values);
            if (isChanged !== shouldBlockNavigation) {
              setTimeout(() => setShouldBlockNavigation(isChanged), 20);
            }
            return (
              <Form onSubmit={formProps.handleSubmit}>
                <FormGroup>
                  <Label>
                    <FormattedMessage id="settings > switch notification" />
                  </Label>
                  <ToggleSwitch
                    leftComponent={intl.formatMessage({ id: "settings > disable" })}
                    rightComponent={intl.formatMessage({ id: "settings > enable" })}
                    switchProps={{
                      onChange: (checked) => {
                        formProps.setFieldValue("notify", checked);
                      },
                      checked: values.notify,
                      disabled: false,
                      uncheckedIcon: false,
                      checkedIcon: false,
                      offColor: "#795AFA",
                      onColor: "#795AFA",
                      handleDiameter: 22,
                      width: 50,
                      height: 26,
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Dropdown
                    label={<FormattedMessage id="my profile > language" />}
                    value={values.language}
                    placeholder="Select language"
                    onChange={selectedOption => {
                      formProps.setFieldValue("language", selectedOption);
                      setLanguage(get(selectedOption, "value"));
                    }}
                    options={languageList}
                    name="language"
                    error={touched.language && errors.language}
                  />
                </FormGroup>
                <FormGroup>
                  <Button color="blue" className="btn-radius" type="submit" disabled={isLoading}>
                    <FormattedMessage
                      id={
                        !isLoading
                          ? "settings > button save"
                          : "settings > button saving"
                      }
                    />
                  </Button>
                </FormGroup>
              </Form>
            );
          }}
        </Formik>
        <Formik
          enableReinitialize
          initialValues={{
            title: ""
          }}
          validationSchema={Yup.object().shape({
            title: Yup.string().required(
              intl.formatMessage({ id: "validate > title is required" })
            )
          })}
          onSubmit={onNotify}
        >
          {formProps => {
            const errors = formProps.errors;
            const touched = formProps.touched;
            const values = formProps.values;
            return (
              <Form onSubmit={formProps.handleSubmit}>
                <FormGroup>
                  <TextInput
                    label={
                      <FormattedMessage id="setting > title show window notification" />
                    }
                    type="text"
                    name="title"
                    placeholder={intl.formatMessage({
                      id: "setting > enter title"
                    })}
                    value={values.title}
                    error={touched.title && errors.title}
                    onChange={formProps.handleChange}
                    onBlur={formProps.handleBlur}
                  />
                </FormGroup>
                <FormGroup>
                  <Button color="blue" className="btn-radius" type="submit" disabled={isLoading}>
                    <FormattedMessage id="settings > button notify" />
                  </Button>
                </FormGroup>
              </Form>
            );
          }}
        </Formik>
      </CardBody>
    </Card>
  );
  return (
    <React.Fragment>
      {form}
      <BlockingComponent
        isBlocked={shouldBlockNavigation}
        modalProps={{
          isShowConfirm: true,
          isShowDiscard: false,
          isCancelConfirmedNavigation: true,
          onCancel: () => {
            setShouldBlockNavigation(false); // reset
          },
          // onConfirm: () => {
          //   const buttonSubmit = document.getElementById(`btn-submit`);
          //   // trigger click to submit the form
          //   if (buttonSubmit) buttonSubmit.click();
          // },
        }}
      />
    </React.Fragment>
  )
  // return (
  //   <WarnAboutUnsavedChanges when={shouldBlockNavigation} children={form} />
  // );
};

export default View;
