import React from "react";
import Page from "components/Page";
import ConfirmationModal from "components/ConfirmationModal";
import PrivilegedComponent from "components/PrivilegedComponent";
import ProcessesList from "components/ProcessesList";
import { Redirect } from "react-router";

const ProcessesPage = (props) => {
  return (
    <PrivilegedComponent requires={[]}>
      {({ isValid }) => {
        if (isValid) {
          return (
            <Page title="" applyPadding={false}>
              <ProcessesList {...props} />
              <ConfirmationModal
                isOpen={props.isDeleteModalOpen}
                title={props.intl.formatMessage({
                  id: "process > remove process",
                })}
                description={props.intl.formatMessage({
                  id: "process > are you sure you want to remove process",
                })}
                onConfirm={props.onDelete}
                onToggle={props.closeDeleteModal}
                onCancel={props.closeDeleteModal}
                isCloseOutside={false}
              />
            </Page>
          );
        } else {
          return <Redirect to="/" />;
        }
      }}
    </PrivilegedComponent>
  );
};

export default ProcessesPage;
