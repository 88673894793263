import React from "react";
import { Button } from "reactstrap";
import cx from "classnames";
import { FormattedMessage } from "react-intl";
import NumberFormat from "react-number-format";
import { get, map, find } from "lodash";
import moment from "moment";
import { Asterisk } from "components/Elements";
import { CheckedIcon } from "components/CustomIcons";
import TextInput from "components/TextInput";
import bn from "utils/bemnames";
import { fulfillmentList, PhoneNumberFormat } from "utils/config";

const bem = bn.create("jock-console-content");

export default function WinnerRecap(props) {
  const {
    winner,
    schedule,
    selectedPrizeId,
    localPrizeUnitValue,
    handleMarkWinLoss,
    handlePrizeUnitValue,
    handlePickPrize,
    showUnitValueInput,
  } = props;
  let prizes = get(schedule, "prizes", []);
  const isWin = winner.contest_result === "win";
  const isLoss = winner.contest_result === "loss";
  const isPickYourPrizeTheme = schedule.theme === "pick_your_prize";
  const isRollOverTheme = schedule.theme === "rollover";
  const is54321Theme = schedule.theme === "5_4_3_2_1";
  const isStandardGiveawayTheme = schedule.theme === "standard_giveaway";
  const prizeCategory = get(prizes, "0.category");
  const isCashVariablePrize = prizeCategory === "variable_cash";
  const isStandardCash = isStandardGiveawayTheme && isCashVariablePrize;
  if (
    isPickYourPrizeTheme &&
    schedule.completed &&
    get(schedule, "winner.prizes.length", 0)
  ) {
    prizes = prizes.filter((prize) => prize.id === selectedPrizeId);
  }
  if (isStandardCash && schedule.completed) {
    prizes = get(schedule, "winner.prizes");
  }

  const getTitle = () => {
    if (isRollOverTheme) {
      return "jock console > contest recap";
    }
    if (isPickYourPrizeTheme) {
      return "jock console > winner selects a prize";
    }
    return "jock console > winner recap";
  };
  let winnerName = `${winner.first_name} ${winner.last_name}`;
  if (!winner.first_name && !winner.last_name) {
    winnerName = winner.name;
  }
  const renderWinnerTable = () => {
    return (
      <table className={bem.e("winner-table")}>
        <thead>
          <tr>
            <th>
              <FormattedMessage
                id={`jock console > ${
                  isStandardCash ? "player" : "winner"
                } name`}
              />
            </th>
            {showUnitValueInput && (
              <th>
                <FormattedMessage id="jock console > prize" />
                <span> $</span>
                <Asterisk className="asterisk">*</Asterisk>
              </th>
            )}
            <th>
              <FormattedMessage id="jock console > mobile phone" />
            </th>
            <th>
              <FormattedMessage
                id={
                  isStandardCash
                    ? "jock console > date of play"
                    : "jock console > email"
                }
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <strong>{winnerName}</strong>
            </td>
            {showUnitValueInput && (
              <td>
                <TextInput
                  name="unit_value"
                  type="number"
                  className={cx("prize-input", bem.e("no-arrow"))}
                  value={localPrizeUnitValue}
                  onChange={(event) => {
                    handlePrizeUnitValue(event.target.value);
                  }}
                />
              </td>
            )}
            <td>
              {winner.phone_number ? (
                <NumberFormat
                  value={winner.phone_number}
                  displayType={"text"}
                  format={PhoneNumberFormat}
                />
              ) : (
                <FormattedMessage id="process > none" />
              )}
            </td>
            <td>
              {isStandardCash ? (
                <span>
                  {moment(
                    winner.player_select_ts
                      ? winner.player_select_ts * 1000
                      : undefined
                  ).format("L")}
                </span>
              ) : winner.email_address ? (
                <span>{winner.email_address}</span>
              ) : (
                <FormattedMessage id="process > none" />
              )}
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  const renderPlayerTable = () => {
    const unitPrize = get(prizes, "0.unit_value", 0);
    const prize =
      unitPrize + get(schedule, "prizes_rollover_count", 0) * unitPrize;
    return (
      <table className={bem.e("winner-table")}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="jock console > player name" />
            </th>
            <th>
              <FormattedMessage id="jock console > prize" />
            </th>
            <th>
              <FormattedMessage id="jock console > previous plays" />
            </th>
            <th>
              <FormattedMessage id="jock console > win or loss" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <strong>{winnerName}</strong>
            </td>
            <td>
              {prize ? (
                <NumberFormat value={prize} displayType={"text"} prefix={"$"} />
              ) : (
                <FormattedMessage id="process > none" />
              )}
            </td>
            <td>
              {schedule.prizes_rollover_count ? (
                <span>{schedule.prizes_rollover_count}</span>
              ) : (
                <FormattedMessage id="process > none" />
              )}
            </td>
            <td>
              <div className="d-flex">
                <div
                  className={cx("action-button", {
                    completed: schedule.completed,
                    "has-won": isWin,
                    disabled: isLoss,
                  })}
                  onClick={handleMarkWinLoss(true)}
                >
                  <FormattedMessage id="jock console > win" />
                </div>
                <div
                  className={cx("action-button", {
                    completed: schedule.completed,
                    "has-lost": isLoss,
                    disabled: isWin,
                  })}
                  onClick={handleMarkWinLoss(false)}
                >
                  <FormattedMessage id="jock console > loss" />
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  return (
    <div className={bem.e("jock-card-table")}>
      <div className={bem.e("jock-card-table-title")}>
        <h2>
          <FormattedMessage id={getTitle()} />
        </h2>
      </div>
      <div className={bem.e("jock-card-table-middle")}>
        <div className={bem.e("jock-card-table-selected-winner")}>
          {isRollOverTheme ? renderPlayerTable() : renderWinnerTable()}
        </div>
      </div>
      <div className={bem.e("jock-card-table-bottom")}>
        <table className={bem.e("prize-table")}>
          <thead>
            <tr>
              <th>
                <FormattedMessage
                  id={
                    isStandardCash
                      ? "jock console > prize"
                      : "jock console > prize won"
                  }
                />
              </th>
              {isStandardCash && (
                <th>
                  <FormattedMessage
                    id={
                      schedule.completed
                        ? "jock console > value"
                        : "jock console > max value"
                    }
                  />
                </th>
              )}
              <th>
                <FormattedMessage id="jock console > fulfillment" />
              </th>
              <th className={isPickYourPrizeTheme ? "" : "text-center"}>
                <FormattedMessage id="jock console > deadline" />
              </th>
            </tr>
          </thead>
          <tbody>
            {map(prizes, (prize, index) => {
              // TODO: check me
              const fulfillment = find(
                fulfillmentList,
                (item) => item.value === prize.fulfillment
              );
              const isSelected = selectedPrizeId === prize.id;
              return (
                <tr key={index}>
                  <td className={is54321Theme ? bem.e("prize-column") : ""}>
                    <strong>{prize.title}</strong>
                    {is54321Theme && (
                      <div className={bem.e("prize-text")}>
                        {get(prize, "selected_itemized_prices.0.description")}
                      </div>
                    )}
                  </td>
                  {isStandardCash && (
                    <td>
                      $
                      {schedule.completed
                        ? prize.unit_value
                        : prize.max_per_winner}
                    </td>
                  )}
                  <td>
                    <span className={bem.e("prize-text")}>
                      {get(fulfillment, "label", "")}
                    </span>
                  </td>
                  <td
                    className={
                      isPickYourPrizeTheme
                        ? "d-flex align-items-center justify-content-between"
                        : "text-center"
                    }
                  >
                    {schedule.deadline_date ? (
                      <span className={bem.e("prize-deadline-text")}>
                        {moment(schedule.deadline_date, "YYYY-MM-DD").format(
                          "L"
                        )}
                      </span>
                    ) : (
                      <FormattedMessage id="jock console > none" />
                    )}
                    {isPickYourPrizeTheme && !schedule.completed && (
                      <Button
                        color={isSelected ? "green" : "blue"}
                        type="submit"
                        className="btn btn-radius ml-4 d-flex align-items-center justify-content-center"
                        onClick={handlePickPrize(prize)}
                      >
                        {isSelected && (
                          <span className={bem.e("checked-icon")}>
                            <CheckedIcon />
                          </span>
                        )}
                        <FormattedMessage
                          id={
                            isSelected
                              ? "jock console > selected"
                              : "jock console > select"
                          }
                        />
                      </Button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
