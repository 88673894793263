import * as Types from "store/types/audience";
import { isFunction } from "lodash";
import { userInfo } from "store/actions/auth";
import Api from "utils/api";
import * as Const from "utils/constants";
export const saveGroup = (data, cb) => async dispatch => {

  let errorStr = "";
  
  try {
    
    dispatch({ type: Types.SAVE_GROUP_REQUEST });
    
    let response = await Api.doCall(Const.GET_AUDIENCE_GROUPS(), "POST", data);
    
    if (response.status === 200 || response.status === 201) {

      if (!response.data.error) {

        dispatch({ type: Types.SAVE_GROUP_SUCCESS, payload: response.data });
        dispatch(userInfo());

        if (isFunction(cb)){
          cb(response.data);
        } 

        return;
      }
      errorStr = response.data.error;
    }

  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({ type: Types.SAVE_GROUP_ERROR, payload: errorStr });

};

export const getGroup = id => async (dispatch) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.FETCH_GROUP_REQUEST, payload: { id } });
    let response = await Api.doCall(Const.GET_AUDIENCE_GROUP(id), "GET");

    if (response.status === 200 || response.status === 201) {

      if (!response.data.error) {
        dispatch(fetchGroupSuccess({ id, data: response.data }));
        return;
      }

      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch(fetchGroupError({ id, error: errorStr }));
};


export const updateGroup = (id, data, cb) => async dispatch => {
    
  let errorStr = "";

  try {
    dispatch({ type: Types.UPDATE_GROUP_REQUEST });

    let response = await Api.doCall(Const.GET_AUDIENCE_GROUP(id), "PUT", data);

    if (response.status === 200 || response.status === 201) {

      if (!response.data.error) {

        dispatch({ 
          type: Types.UPDATE_GROUP_SUCCESS, 
          payload: { 
            id, 
            data: response.data 
          }
        });
        
        dispatch(userInfo());

        if (isFunction(cb)){
           cb(response.data);
        }

        return;
      }

      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({ type: Types.UPDATE_GROUP_ERROR, payload: errorStr });
};



export function fetchGroupSuccess(payload) {
  return {
    type: Types.FETCH_GROUP_SUCCESS,
    payload
  };
}

export function fetchGroupError(payload) {
  return {
    type: Types.FETCH_GROUP_ERROR,
    payload
  };
}
