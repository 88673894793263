import { FormattedMessage } from "react-intl";
import React from "react";
import { isNaN, get, map, omit, sum } from "lodash";
import moment from "moment";
import { orderLengthOptions } from "utils/config";

const fields = [
  {
    field: "timezone",
  },
  {
    field: "order_stations",
    title: <FormattedMessage id="process > field station" />,
    mandatory: true,
    validation: function(step, item, process, template, user, value) {
      return value.length > 0
        ? true
        : "At least one station has to be selected";
    },
    step: ["order_details"],
  },
  {
    field: "contract_stations",
  },
  {
    field: "order_stations_array",
  },
  {
    field: "is_publish",
  },
  {
    field: "order_title",
    title: <FormattedMessage id="process > promotion > liner > item title" />,
    mandatory: true,
    validation: function(step, item, process, template, user, value) {
      return value && value.length > 0
        ? true
        : "Item title must be at least 3 letters long";
    },
    step: ["order_details"],
  },
  {
    field: "order_start_date",
    title: (
      <FormattedMessage id="process > promotion > liner > item start date" />
    ),
    mandatory: true,
    validation: function(step, item, process, template, user, value) {
      if (!value) return "Please enter a date";
      if (value.length === 0) return "Please enter a date";
      if (isNaN(Date.parse(value))) return "Please enter a valid date";
      return true;
    },
    step: ["order_details"],
  },
  {
    field: "order_end_date",
    title: (
      <FormattedMessage id="process > promotion > liner > item end date" />
    ),
    mandatory: true,
    validation: function(step, item, process, template, user, value) {
      if (!value) return "Please enter a date";
      if (value.length === 0) return "Please enter a date";
      if (isNaN(Date.parse(value))) return "Please enter a valid date";
      if (
        process.fields.order_start_date &&
        !isNaN(Date.parse(process.fields.order_start_date.value)) &&
        Date.parse(value) < Date.parse(process.fields.order_start_date.value)
      )
        return "Please enter a date later than the start date";
      return true;
    },
    step: ["order_details"],
  },
  {
    field: "days_of_week",
    title: (
      <FormattedMessage id="process > promotion > liner > item days of the week" />
    ),
    mandatory: true,
    validation: function(step, item, process, template, user, value) {
      if (!value || value.length === 0) return "Please select days";
      return true;
    },
    step: ["order_details"],
  },
  {
    field: "excluded_dates",
    title: (
      <FormattedMessage id="process > promotion > liner > excluded days" />
    ),
    mandatory: false,
  },
  {
    field: "excluded_hours_array",
    title: (
      <FormattedMessage id="process > promotion > liner > excluded hours" />
    ),
    mandatory: false,
  },

  {
    field: "order_type",
    title: <FormattedMessage id="process > promotion > liner > item type" />,
    mandatory: true,
    validation: function(step, item, process, template, user, value) {
      if (!value || value.length === 0) return "Please select type";
      return true;
    },
    step: ["order_details"],
  },
  {
    field: "type_id",
  },
  {
    field: "order_length",
    title: <FormattedMessage id="process > promotion > liner > item length" />,
    mandatory: false,
    props: {
      options: orderLengthOptions,
    },
  },
  {
    field: "scheduling_type",
    mandatory: true,
    props: {
      options: [
        {
          label: (
            <FormattedMessage id="process > promotion liner > scheduling > simple" />
          ),
          value: "simple",
        },
        {
          label: (
            <FormattedMessage id="process > promotion liner > scheduling > targeted" />
          ),
          value: "targeted",
        },
        {
          label: (
            <FormattedMessage id="process > promotion liner > scheduling > fill" />
          ),
          value: "fill",
        },
      ],
    },
    validation: function(step, item, process, template, user, value) {
      if (!value || value.length === 0) return "Please select schedule method";
      return true;
    },
    step: ["order_details"],
  },
  {
    field: "schedule_by",
    title: <FormattedMessage id="process > promotion > liner > select by" />,
    mandatory: true,
    props: {
      options: [
        {
          label: (
            <FormattedMessage id="process > promotion liner > scheduling > ros" />
          ),
          value: "ROS",
          scheduling_type: ["fill", "simple"],
        },
        {
          label: (
            <FormattedMessage id="process > promotion liner > scheduling > neilsen dayparts" />
          ),
          value: "daypart",
          scheduling_type: ["fill", "simple"],
        },
        {
          label: (
            <FormattedMessage id="process > promotion liner > scheduling > shows" />
          ),
          value: "show",
          scheduling_type: ["fill", "simple"],
        },
      ],
    },
    step: ["order_details"],
    validation: function(step, item, process, template, user, value) {
      const scheduleMethod = get(process, "fields.scheduling_type.value", "");
      if (scheduleMethod === "targeted" || value) return true;
      return "Please select By";
    },
  },
  {
    field: "selected_by",
    title: <FormattedMessage id="process > promotion > liner > select by" />,
    mandatory: true,
    props: {
      options: [
        {
          label: (
            <FormattedMessage id="process > promotion liner > scheduling > ros" />
          ),
          value: "ROS",
          scheduling_type: ["fill", "simple"],
        },
        {
          label: (
            <FormattedMessage id="process > promotion liner > scheduling > neilsen dayparts" />
          ),
          value: "daypart",
          scheduling_type: ["fill", "targeted", "simple"],
        },
        {
          label: (
            <FormattedMessage id="process > promotion liner > scheduling > shows" />
          ),
          value: "show",
          scheduling_type: ["fill", "targeted", "simple"],
        },
        {
          label: (
            <FormattedMessage id="process > promotion liner > scheduling > hour" />
          ),
          value: "hour",
          scheduling_type: ["targeted"],
        },
      ],
    },
    step: ["order_details"],
  },
  {
    field: "selected_items",
    mandatory: true,
    validation: function(step, item, process, template, user, value) {
      const scheduleMethod = get(process, "fields.scheduling_type.value", "");
      const selectedBy = get(process, "fields.selected_by.value", "");
      const scheduleBy = get(process, "fields.schedule_by.value", "");
      if (
        scheduleMethod === "targeted" &&
        selectedBy === "hour" &&
        (!value || value.length === 0)
      ) {
        return "Please select Hours";
      }
      if (
        scheduleMethod === "targeted" &&
        selectedBy === "daypart" &&
        (!value || value.length === 0)
      ) {
        return "Please select dayparts";
      }
      if (
        scheduleMethod === "targeted" &&
        selectedBy === "show" &&
        (!value || value.length === 0)
      ) {
        return "Please select Shows";
      }
      if (scheduleMethod === "fill" || scheduleMethod === "simple") {
        if (scheduleBy === "show" && (!value || value.length === 0)) {
          return "Please select Shows";
        }
        if (scheduleBy === "daypart" && (!value || value.length === 0)) {
          return "Please select dayparts";
        }
      }
      return true;
    },
    step: ["order_details"],
  },
  {
    field: "mentions",
    title: (
      <FormattedMessage id="process > promotion > liner > scheduling > mentions" />
    ),
    mandatory: true,
    validation: function(step, item, process, template, user, value) {
      const scheduleMethod = get(process, "fields.scheduling_type.value", "");
      if (scheduleMethod === "targeted" && !value) {
        return "Please select Mentions";
      }
      return true;
    },
    step: ["order_details"],
  },
  {
    field: "per",
    title: (
      <FormattedMessage id="process > promotion > liner > scheduling > per" />
    ),
    mandatory: true,
    validation: function(step, item, process, template, user, value) {
      const scheduleMethod = get(process, "fields.scheduling_type.value", "");
      if (scheduleMethod === "targeted" && !value) {
        return "Please select Per";
      }
      return true;
    },
    props: {
      options: [
        {
          label: (
            <FormattedMessage id="process > promotion > liner > per week" />
          ),
          value: "week",
        },
        {
          label: (
            <FormattedMessage id="process > promotion > liner > per day" />
          ),
          value: "day",
        },
        {
          label: (
            <FormattedMessage id="process > promotion > liner > per hour" />
          ),
          value: "hour",
        },
        {
          label: (
            <FormattedMessage id="process > promotion > liner > per show" />
          ),
          value: "show",
        },
        {
          label: (
            <FormattedMessage id="process > promotion > liner > per nielsen daypart" />
          ),
          value: "daypart",
        },
      ],
    },
    step: ["order_details"],
  },
  {
    field: "supplemental_items",
  },
  {
    field: "desired_items_scheduling",
    mandatory: true,
    validation: function(step, item, process, template, user, value) {
      const orderEndDate = get(process, "fields.order_end_date.value", "");
      if (orderEndDate && moment(orderEndDate).isBefore(moment(), "day")) {
        return true;
      }
      const errorMessage = "Please enter valid desired items";
      const schedulingType = get(process, "fields.scheduling_type.value");
      if (schedulingType === "fill") {
        return true;
      }
      const totalSupplementalItems = get(
        process,
        "fields.supplemental_items.value",
        0
      );
      const totalAvailableItems = get(
        process,
        "fields.total_available_items.value"
      );
      try {
        const itemCount = Number(value);
        if (!itemCount) {
          return errorMessage;
        }
        if (totalAvailableItems + totalSupplementalItems >= itemCount) {
          return true;
        }
        return "Desired items should be smaller than available items";
      } catch {
        return errorMessage;
      }
    },
    step: ["order_details"],
  },
  {
    field: "total_available_items",
    mandatory: true,
    validation: function(step, item, process, template, user, value) {
      const orderEndDate = get(process, "fields.order_end_date.value", "");
      if (orderEndDate && moment(orderEndDate).isBefore(moment(), "day")) {
        return true;
      }
      const schedulingType = get(process, "fields.scheduling_type.value");
      if (schedulingType === "fill") {
        return true;
      }
      const errorMessage = "Please select dates with items available";
      try {
        const item_count = Number(value);
        return !!item_count || errorMessage;
      } catch {
        return errorMessage;
      }
    },
    step: ["order_details"],
  },
  {
    field: "relax_restrictions_for",
  },
  {
    field: "relax_restrictions_for_weeks",
  },
  {
    field: "associated_items_ids",
  },
  {
    field: "associated_items",
  },
  // {
  //   field: "fill_item_rotation",
  //   validation: function(step, item, process, template, user, value) {
  //     const schedulingType = get(process, "fields.scheduling_type.value");
  //     if (schedulingType !== "fill") {
  //       return true;
  //     }
  //     const fill_item_rotation = get(
  //       process,
  //       "fields.fill_item_rotation.value",
  //       []
  //     );
  //     const total = sumBy(fill_item_rotation, "percentage");
  //     const length = fill_item_rotation.length;
  //     if (length === 0) return true;
  //     let isValid = true;
  //     const dataErrors = map(fill_item_rotation, (item) => {
  //       if (!item.percentage || parseFloat(item.percentage) > 100) {
  //         isValid = false;
  //         return "Please set rotation percentage";
  //       }
  //       return null;
  //     });
  //     if (!isValid) {
  //       return dataErrors;
  //     }
  //     if (length === 3 && total === 99) return true;

  //     if (total !== 100) {
  //       return "Fill item rotation percentages must add up to 100%";
  //     }
  //     return true;
  //   },
  //   mandatory: true,
  //   step: ["order_details"],
  // },
  {
    field: "copy_groups",
    title: (
      <FormattedMessage id="process > promotion > liner > field copy groups" />
    ),
    mandatory: true,
    step: ["write_script"],
    validation: function(step, item, process, template, user, value) {
      let isValid = true;
      const copyGroups = map(value, (group, index) => {
        let error = {};
        if (!group["title"] || group["title"].length <= 2) {
          isValid = false;
          error.title = "Title must be at least 3 letters long";
        }

        if (!group["start_date"]) {
          isValid = false;
          error.start_date = "Please enter a date";
        } else if (isNaN(Date.parse(group["end_date"]))) {
          error.start_date = "Please enter a valid date";
        }
        if (!group["end_date"]) {
          isValid = false;
          error.end_date = "Please enter a date";
        } else if (isNaN(Date.parse(group["end_date"]))) {
          error.end_date = "Please enter a valid date";
        } else if (
          group["start_date"] &&
          !isNaN(Date.parse(group["start_date"])) &&
          Date.parse(group["end_date"]) < Date.parse(group["start_date"])
        ) {
          error.end_date = "Please enter a date later than the start date";
        }
        if (!group["days_of_week"] || group["days_of_week"].length === 0) {
          isValid = false;
          error.days_of_week = "Please select days";
        }
        if (!group["start_date_time"]) {
          isValid = false;
          error.start_date_time = "Please select start time";
        }
        if (!group["end_date_time"]) {
          isValid = false;
          error.end_date_time = "Please select end time";
        }
        // validate for rotation chart
        if (group["rotation_chart"] && group["rotation_chart"].length > 0) {
          let isRotationError = false;
          const rotationError = map(group["rotation_chart"], (rotation) => {
            const rotationSpot = Object.values(omit(rotation, ["station"]));
            if (sum(rotationSpot) < 99 || sum(rotationSpot) > 100) {
              isRotationError = true;
              return "Spot rotation percentages must add up to 100%";
            }
            return "";
          });
          if (isRotationError) {
            isValid = false;
            error.rotation_chart = rotationError;
          }
        }
        return error;
      });
      if (isValid) return true;
      return copyGroups;
    },
  },
];
export default fields;
