import React from "react";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import { FaSortDesc, FaSortAsc } from "react-icons/lib/fa";
import { Table } from "reactstrap";
import { get, truncate } from "lodash";
import Tooltip from "rc-tooltip";
import { MODE_IN_TAB } from "../view";
import useInfiniteScroll from "utils/useInfiniteScroll";
import moment from "moment";
import NoItemsFound from "components/NoItemsFound";
const getUnitValue = (prize) => {
  if (prize._source.category === "variable_cash") {
    return (
      <strong>
        <FormattedMessage id="prizes > variable" />
      </strong>
    );
  }
  return prize._source.unit_value ? (
    <strong>${prize._source.unit_value}</strong>
  ) : (
    <FormattedMessage id="prizes > none" />
  );
};
const getAvailable = (prize) => {
  if (prize._source.category === "variable_cash") {
    return prize._source.available || prize._source.total_cash;
  }
  return prize._source.available || prize._source.quantity;
};
const getTotal = (prize) => {
  if (prize._source.category === "variable_cash") {
    return prize._source.total_cash;
  }
  return prize._source.quantity;
};
const View = ({
  actionSort,
  sort,
  prizeList,
  bem,
  onClickRow,
  mode,
  isLoading,
  handleLoadMore,
  hasMoreItems,
  dateFormatByServer
}) => {
  const getPrizeType = (prizeCategory) => {
    let sortName;
    let name;
    let className;
    if (prizeCategory === "cash_check") {
      sortName = "$";
      name = "Cash";
      className = bem.e("prize-cash");
    } else if (prizeCategory === "tickets") {
      sortName = "TX";
      name = "Tickets";
      className = bem.e("prize-tickets");
    } else if (prizeCategory === "prize") {
      sortName = "P";
      name = "Prize";
      className = bem.e("prize-prize");
    } else if (prizeCategory === "station_merchandise") {
      sortName = "M";
      name = "Merch";
      className = bem.e("prize-merch");
    } else if (prizeCategory === "variable_cash") {
      sortName = "V";
      name = "Variable";
      className = bem.e("prize-variable");
    }
    return (
      <div className={classnames(bem.e("prize-type"), className)}>
        <span className={bem.e("prize-sort-name")}>{sortName}</span>
        <span className={bem.e("prize-name")}>{name}</span>
      </div>
    );
  };
  useInfiniteScroll({
    useWindow: false,
    elementId: "cr-app-main",
    onLoadMore: handleLoadMore,
    shouldLoadMore: hasMoreItems,
  });
  return (
    <div
      className={classnames(bem.e("table-wrapper"), bem.e("table-prizes"), {
        [bem.e("table-wrapper-mode-in-tab")]: mode === MODE_IN_TAB,
      })}
    >
      <Table className={bem.e("table")}>
        <thead>
          <tr>
            <th
              onClick={() => actionSort("category")}
              className={classnames("can-click", bem.e("table-first-head"))}
            >
              <div className={bem.e("item-sortable")}>
                <FormattedMessage id="prizes > type" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "category",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "category desc",
                    })}
                  />
                </div>
              </div>
            </th>
            <th onClick={() => actionSort("title")} className="can-click">
              <div className={bem.e("item-sortable")}>
                <FormattedMessage id="prizes > title" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "title",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "title desc",
                    })}
                  />
                </div>
              </div>
            </th>
            <th>
              <div className={bem.e("item-sortable")}>
                <FormattedMessage id="prizes > description" />
              </div>
            </th>
            <th onClick={() => actionSort("quantity")} className="can-click">
              <div
                className={classnames(
                  bem.e("item-sortable"),
                  bem.e("item-center")
                )}
              >
                <FormattedMessage id="prizes > total" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "quantity",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "quantity desc",
                    })}
                  />
                </div>
              </div>
            </th>
            <th onClick={() => actionSort("available")} className="can-click">
              <div
                className={classnames(
                  bem.e("item-sortable"),
                  bem.e("item-center")
                )}
              >
                <FormattedMessage id="prizes > available" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "available",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "available desc",
                    })}
                  />
                </div>
              </div>
            </th>
            <th
              onClick={() => actionSort("on_hold_quantity")}
              className="can-click"
            >
              <div
                className={classnames(
                  bem.e("item-sortable"),
                  bem.e("item-center")
                )}
              >
                <FormattedMessage id="prizes > on_hold_quantity" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "on_hold_quantity",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        sort === "on_hold_quantity desc",
                    })}
                  />
                </div>
              </div>
            </th>
            <th onClick={() => actionSort("unit_value")} className="can-click">
              <div
                className={classnames(
                  bem.e("item-sortable"),
                  bem.e("item-center")
                )}
              >
                <FormattedMessage id="prizes > unit_value" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "unit_value",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "unit_value desc",
                    })}
                  />
                </div>
              </div>
            </th>
            <th
              onClick={() => actionSort("expiration_date_ts")}
              className="can-click"
            >
              <div
                className={classnames(
                  bem.e("item-sortable"),
                  bem.e("item-center")
                )}
              >
                <FormattedMessage id="prizes > expiration" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "expiration_date_ts",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        sort === "expiration_date_ts desc",
                    })}
                  />
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {prizeList.length === 0 && !isLoading ? (
            <tr>
              <td colSpan={8}>
                <NoItemsFound />
              </td>
            </tr>
          ) : (
            prizeList.map((prize) => {
              const maxDescriptionLength = 120;
              const originalDescription =
                get(prize, "_source.description", "") || "";
              const description = truncate(originalDescription, {
                length: maxDescriptionLength,
              });
              const isTruncated =
                originalDescription.length > maxDescriptionLength;
              return (
                <tr key={prize._id} onClick={() => onClickRow(prize)}>
                  {/* type */}
                  <td
                    className={classnames(bem.e("first-col"), "align-middle")}
                  >
                    {getPrizeType(prize._source.category)}
                  </td>
                  {/* title */}
                  <td
                    className={classnames(
                      "align-middle",
                      bem.e("prize-text"),
                      bem.e("prize-title")
                    )}
                  >
                    <strong>{prize._source.title}</strong>
                    <span className={bem.e("subtitle")}>
                      {prize._source.subtitle}
                    </span>
                  </td>
                  {/* description */}
                  <td
                    className={classnames(
                      "align-middle",
                      bem.e("prize-description")
                    )}
                  >
                    {isTruncated ? (
                      <Tooltip
                        placement="top"
                        trigger={["click", "hover"]}
                        overlayClassName={bem.e("tooltip")}
                        overlay={
                          <div className={bem.e("tooltip-content")}>
                            {originalDescription}
                          </div>
                        }
                      >
                        <div className={bem.e("tooltip-content")}>
                          {description}
                        </div>
                      </Tooltip>
                    ) : (
                      description
                    )}
                  </td>
                  {/* quantity */}
                  <td
                    className={classnames(
                      "align-middle",
                      bem.e("prize-text"),
                      bem.e("text-center")
                    )}
                  >
                    {getTotal(prize)}
                  </td>
                  {/* available */}
                  <td
                    className={classnames(
                      "align-middle",
                      bem.e("prize-text"),
                      bem.e("text-center")
                    )}
                  >
                    {getAvailable(prize)}
                  </td>
                  {/* on hold */}
                  <td
                    className={classnames(
                      "align-middle",
                      bem.e("prize-text"),
                      bem.e("text-center")
                    )}
                  >
                    {prize._source.on_hold_quantity || 0}
                  </td>
                  {/* unit value */}
                  <td
                    className={classnames(
                      "align-middle",
                      bem.e("prize-text"),
                      bem.e("text-center")
                    )}
                  >
                    {getUnitValue(prize)}
                  </td>
                  {/* expiration_date */}
                  <td
                    className={classnames(
                      "align-middle",
                      bem.e("prize-text"),
                      bem.e("text-center")
                    )}
                  >
                    {prize._source.expiration_date ? (
                      moment(prize._source.expiration_date).format(dateFormatByServer)
                    ) : (
                      <FormattedMessage id="prizes > none" />
                    )}
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </Table>
    </div>
  );
};
export default View;
