import { MdImportantDevices } from "react-icons/lib/md";
import ToastManager from "components/ToastManager";
import React from "react";
import RoleForm, { MODE_VIEW, MODE_EDIT } from "./view";
import { get, isFunction, find } from "lodash";
import { connect } from "react-redux";
import { compose, lifecycle, withHandlers, withState } from "recompose";
import { addRole, updateRole, deleteRole } from "store/actions/roles";
import { injectIntl } from "react-intl";
export default injectIntl(
  compose(
    connect(
      (state, props) => {
        const id = props.id;
        const role = find(
          get(state, `auth.info.roles`, []),
          (role) => role.id === id
        );
        const authPrivileges = get(state, "auth.info.privileges", []);
        const privileges = Object.keys(authPrivileges).map((k) => ({
          id: k,
          name: authPrivileges[k],
        }));
        return {
          id,
          isUpdate: id ? true : false,
          role: role,
          privileges,
          roles: get(state, `auth.info.roles`, []),
          enable_production: get(state, "auth.info.enable_production", false),
          enable_promotion: get(state, "auth.info.enable_promotion", false),
          enable_digital: get(state, "auth.info.enable_digital", false),
          production_multimarket: get(
            state,
            "auth.info.production_multimarket",
            false
          ),
          user: state.auth.user,
        };
      },
      {
        addRole,
        updateRole,
        deleteRole,
      }
    ),
    withState("isOpenPrivileges", "setIsOpenPrivileges", {}),
    withState("shouldBlockNavigation", "setShouldBlockNavigation", false),
    withState("isLoading", "setIsLoading", false),
    withState("isChangeLogo", "setIsChangeLogo", false),
    withState("isViewMode", "setIsViewMode", (props) =>
      get(props, "isView") ? MODE_VIEW : MODE_EDIT
    ),
    withState("deleteRoleId", "setDeleteRoleId", null),
    withState("isDeleteModalOpen", "setIsDeleteModalOpen", false),
    withHandlers({
      onFormSubmit: ({
        addRole,
        updateRole,
        isUpdate,
        id,
        onBack,
        setIsLoading,
        intl,
        onDismissReminderModal,
        setIsViewMode,
        setIsOpenPrivileges,
      }) => (values) => {
        setIsLoading(true);
        if (isUpdate) {
          updateRole(id, values, (status) => {
            setIsViewMode(MODE_VIEW);
            setIsLoading(false);
            setIsOpenPrivileges({});
            if (status) {
              ToastManager.show({
                title: <MdImportantDevices />,
                message: intl.formatMessage({
                  id: "role > updated successfully",
                }),
                level: "success",
              });
              onDismissReminderModal();
            }
          });
        } else {
          addRole(values, (status) => {
            setIsLoading(false);
            setIsOpenPrivileges({});
            if (status) {
              setIsViewMode(MODE_VIEW);
              if (isFunction(onBack)) onBack();
            }
          });
        }
      },
      openDeleteModal: ({ setIsDeleteModalOpen, setDeleteRoleId }) => (id) => {
        setIsDeleteModalOpen(true);
        setDeleteRoleId(id);
      },
      closeDeleteModal: ({ setIsDeleteModalOpen, setDeleteRoleId }) => () => {
        setIsDeleteModalOpen(false);
        setDeleteRoleId(null);
      },
    }),
    withHandlers({
      onDelete: ({
        closeDeleteModal,
        deleteRoleId,
        deleteRole,
        onBack,
      }) => () => {
        deleteRole(deleteRoleId, () => {
          if (isFunction(onBack)) onBack();
        });
        closeDeleteModal();
      },
    }),
    lifecycle({
      componentDidUpdate(prevProps) {
        if (get(this.props, "isView") !== get(prevProps, "isView")) {
          const isViewMode = get(this.props, "isView") ? MODE_VIEW : MODE_EDIT;
          this.props.setIsViewMode(isViewMode);
        }
      },
    })
  )(RoleForm)
);
