import React from "react";
import { Col, Label, FormGroup } from "reactstrap";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { get, isFunction } from "lodash";

import Dropdown from "components/Dropdown";
import TextInput from "components/TextInput";
import { Asterisk } from "components/Elements";
import { DAYPART, SHOWCLOCK, PROGCLOCK } from "utils/constants";
import SelectAudience from "components/SelectAudience";

const ClockFields = (props) => {
  const { intl, fieldSpan, bem, formProps, isEdit, onStationChange } = props;

  const { values, setFieldValue, handleBlur, handleChange, errors } = formProps;

  const clockType = get(values, "type.value");
  const isDaypart = clockType === DAYPART;
  const isShowClock = clockType === SHOWCLOCK;
  const isShowClass = { show: isShowClock };

  const isDayPartOrEdit = isDaypart || isEdit;
  const isDayPartClass = { "edit-daypart": isDayPartOrEdit };
  const getSpan = (span) => {
    if (isShowClock) {
      return {};
    }
    return { xs: isDayPartOrEdit ? span : fieldSpan };
  };
  return (
    <React.Fragment>
      <Col
        className={classnames("col clock-type", isDayPartClass, isShowClass, {
          edit: isEdit,
        })}
        {...getSpan(3)}
      >
        <FormGroup>
          <Label
            className={classnames({
              [bem.e("readonly-label")]: isEdit || isDaypart,
            })}
          >
            <FormattedMessage id="clock creator > type" />
            {!isDaypart && <Asterisk>*</Asterisk>}
          </Label>
          {!isDaypart && !isEdit ? (
            <Dropdown
              name="type"
              placeholder={intl.formatMessage({
                id: "clock creator > select",
              })}
              onChange={(selectedOption) => {
                setFieldValue("type", selectedOption);
              }}
              value={values.type}
              options={[
                {
                  label: intl.formatMessage({
                    id: "clock creator > programming clock",
                  }),
                  value: PROGCLOCK,
                },
                {
                  label: intl.formatMessage({
                    id: "clock creator > show clock",
                  }),
                  value: SHOWCLOCK,
                },
              ]}
              onBlur={handleBlur}
              error={errors.type}
            />
          ) : (
            <div className={bem.e("field-value")}>
              {get(values, "type.label", "")}
            </div>
          )}
        </FormGroup>
      </Col>
      <Col
        className={classnames("col title", isDayPartClass, isShowClass)}
        {...getSpan(6)}
      >
        <FormGroup>
          <TextInput
            label={
              <span>
                <FormattedMessage id="clock creator > title" />
                <Asterisk>*</Asterisk>
              </span>
            }
            type="text"
            name="title"
            placeholder={intl.formatMessage({
              id: "clock creator > enter title",
            })}
            value={values.title}
            onChange={(e) => {
              handleChange(e);
            }}
            onBlur={handleBlur}
            error={errors.title}
          />
        </FormGroup>
      </Col>
      <Col
        className={classnames("col station", isDayPartClass)}
        {...getSpan(3)}
      >
        <FormGroup>
          <Label
            className={classnames({
              [bem.e("readonly-label")]: isDaypart || (isEdit && isShowClock),
            })}
          >
            <FormattedMessage id="clock creator > station" />
            {!isDaypart && <Asterisk>*</Asterisk>}
          </Label>
          {!isDaypart && !isEdit ? (
            <>
              <SelectAudience
                name="select_stations"
                item={{ field: "stations" }}
                label={null}
                placeholder={intl.formatMessage({
                  id: "clock creator > select",
                })}
                onChange={(station) => {
                  setFieldValue("station", station);
                  if (isFunction(onStationChange)) {
                    onStationChange();
                  }
                }}
                isFilterByServerConfig={true}
                template={{ key: "liner" }}
                onBlur={handleBlur}
                required={false}
                isMulti={false}
                value={get(values, "station.value")}
                // error={errors.station}
                optionKeys={{
                  first: "call_letters",
                  last: "name",
                }}
              />
              {errors.station && (
                <div className="text-danger">{errors.station}</div>
              )}
            </>
          ) : (
            <div className={bem.e("field-value")}>
              {get(values, "station.name", get(values, "station.label", ""))}
            </div>
          )}
        </FormGroup>
      </Col>
    </React.Fragment>
  );
};

export default ClockFields;
