import React, { useState } from "react";
import bn from "utils/bemnames";
import { get } from "lodash";

import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import { Button } from "reactstrap";

import PrivilegedComponent from "components/PrivilegedComponent";
import { PRIVILEGES } from "utils/constants";
import ConfirmationModal from "components/ConfirmationModal";

const bem = bn.create("schedule-calendar");
export default function ScheduleFilterBottom(props) {
  const {
    step,
    template,
    user,
    process,
    onValueChanged,
    buttonClicked,
  } = props;
  const [isOpenDialogPublish, setIsOpenDialogPublish] = useState(false);

  const isPublishedValue = get(process, "data.fields.is_publish.value", false);
  // They should only be able to do this after they have completed both Details and Script sections.
  const isDisabledPublish =
    get(process, "data.fields.process_step_index.value") < 3 ||
    !get(process, "data.fields.key.value");
  const is_publish = step && step.fields.find((f) => f.field === "is_publish");

  return (
    <div className={"bottom-actions-sticky"}>
      <div className={"buttons"}>
        <PrivilegedComponent
          requires={
            get(template, "key") === "contest"
              ? [PRIVILEGES.CONTEST_PUBLISH]
              : get(template, "key") === "liner"
              ? [PRIVILEGES.PROGRAMMING_ELEMENT_PUBLISH]
              : [
                  PRIVILEGES.CONTEST_PUBLISH,
                  PRIVILEGES.PROGRAMMING_ELEMENT_PUBLISH,
                ]
          }
        >
          <Button
            color="blue"
            className={classnames("btn-radius", bem.e("btn-publish"))}
            onClick={() => setIsOpenDialogPublish(true)}
            disabled={isDisabledPublish}
          >
            {isPublishedValue ? (
              <FormattedMessage id="process > button unpublish" />
            ) : (
              <FormattedMessage id="process > button publish" />
            )}
          </Button>
        </PrivilegedComponent>
        {/* button save draft */}
        <PrivilegedComponent
          requires={
            get(template, "key") === "contest"
              ? [PRIVILEGES.CONTEST_SAVE_DRAFT]
              : get(template, "key") === "liner"
              ? [PRIVILEGES.PROGRAMMING_ELEMENT_SAVE_DRAFT]
              : [
                  PRIVILEGES.CONTEST_SAVE_DRAFT,
                  PRIVILEGES.PROGRAMMING_ELEMENT_SAVE_DRAFT,
                ]
          }
        >
          <Button
            color="blue"
            outline
            className={classnames(bem.e("btn-draft"), "btn-radius")}
            onClick={(event) => {
              buttonClicked(
                step,
                {
                  ...step.fields.find((f) => f.key === "save_button"),
                  draft: true,
                },
                process,
                template,
                user,
                event,
                null,
                true
              );
            }}
          >
            <FormattedMessage id="process > button save draft" />
          </Button>
        </PrivilegedComponent>
        <ConfirmationModal
          isOpen={isOpenDialogPublish}
          onToggle={() => setIsOpenDialogPublish(!isOpenDialogPublish)}
          onCancel={() => setIsOpenDialogPublish(false)}
          className={bem.e("confirmation-publish-modal")}
          title={
            isPublishedValue ? (
              <span>
                <FormattedMessage id="process > confirm unpublish item" />
                <FormattedMessage id="process > confirm unpublish item description" />
              </span>
            ) : (
              <span>
                <FormattedMessage id="process > confirm publish item" />
                <FormattedMessage id="process > confirm publish item description" />
              </span>
            )
          }
          cancelTitle={<FormattedMessage id="process > button cancel" />}
          description={null}
          onConfirm={(event) => {
            setIsOpenDialogPublish(false);
            onValueChanged(is_publish, !isPublishedValue);
            process.data.fields.is_publish = {
              value: !isPublishedValue,
              is_dirty: true,
            };
            buttonClicked(
              step,
              step.fields.find((f) => f.key === "save_button"),
              process,
              template,
              user,
              event,
              null,
              true
            );
          }}
          isCloseOutside={false}
        />
      </div>
    </div>
  );
}
