import React from "react";
import Page from "components/Page";
import { FormattedMessage } from "react-intl";
import EligibilityForm from "components/StationSettings/EligibilityForm";
import W9Form from "components/StationSettings/W9Form";
import FulfillmentAutomation from "components/StationSettings/FulfillmentAutomation";
import AddressVerification from "components/StationSettings/AddressVerification";
import PrivilegedComponent from "components/PrivilegedComponent";
import { PRIVILEGES } from "utils/constants";
import { Redirect } from "react-router";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { adminTabs } from "utils/config";
import URL from "utils/urls";
import { Asterisk } from "components/Elements";
import { get } from "lodash";
import bn from "utils/bemnames";
import classnames from "classnames";
import SelectAudience from "components/SelectAudience";
import BlockingComponent from "components/BlockingComponent";
import ConfirmationModal from "components/ConfirmationModal";
const bem = bn.create("station-settings");
const StationSettings = ({
  stations,
  intl,
  currentStation,
  setCurrentStation,
  activeTab,
  setActiveTab,
  shouldBlockNavigation,
  setShouldBlockNavigation,
  currentServer,
  isOpenConfirmationModal,
  setIsOpenConfirmationModal,
  setSelectedFulfillmentAutomation,
  selectedFulfillmentAutomation,
}) => {
  return (
    <PrivilegedComponent
      requires={{
        or: [
          PRIVILEGES.VIEW_STATION_SETTINGS_FORMS,
          PRIVILEGES.EDIT_STATION_SETTINGS_FORMS,
        ],
      }}
    >
      {({ isValid }) => {
        if (isValid) {
          const isShowW9Forms = get(currentServer, "country", "") === "US";
          return (
            <Page
              className={bem.b()}
              applyPadding={false}
              title={
                <div>
                  <nav
                    className="cr-page__breadcrumb"
                    aria-label="breadcrumb"
                    style={{ fontSize: "initial" }}
                  >
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to={URL.ADMINS({ tab: adminTabs.TAB_UNKNOWN })}>
                          <FormattedMessage id="station settings > admin" />
                        </Link>
                      </li>
                      <li
                        className="active breadcrumb-item"
                        aria-current="page"
                      >
                        <strong>
                          <FormattedMessage id="station settings > station settings" />
                        </strong>
                      </li>
                    </ol>
                  </nav>
                  <span className="page-title">
                    <FormattedMessage id="station settings > page title" />
                  </span>
                </div>
              }
            >
              <div className={"page-container"}>
                {stations.length > 0 ? (
                  <SelectAudience
                    name="select_stations"
                    item={{ field: "stations" }}
                    label={
                      <span>
                        {intl.formatMessage({
                          id: "station settings > station",
                        })}
                        <Asterisk>*</Asterisk>
                      </span>
                    }
                    placeholder={intl.formatMessage({
                      id: "station settings > select station",
                    })}
                    template={{ key: "liner" }}
                    isFilterByServerConfig={true}
                    onChange={(station) => {
                      if (shouldBlockNavigation) {
                        setIsOpenConfirmationModal(true);
                      } else {
                        setCurrentStation(station);
                      }
                    }}
                    required={false}
                    isMulti={false}
                    value={get(currentStation, "value", "")}
                  />
                ) : null}
                {currentStation ? (
                  <React.Fragment>
                    <Nav tabs>
                      <PrivilegedComponent
                        requires={{
                          or: [
                            PRIVILEGES.VIEW_STATION_SETTINGS_FORMS,
                            PRIVILEGES.EDIT_STATION_SETTINGS_FORMS,
                          ],
                        }}
                      >
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "eligibility",
                            })}
                            onClick={() => {
                              setActiveTab("eligibility");
                            }}
                          >
                            {intl.formatMessage({
                              id: "station settings > eligibility",
                            })}
                          </NavLink>
                        </NavItem>
                        {isShowW9Forms && (
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: activeTab === "w9-form",
                              })}
                              onClick={() => {
                                setActiveTab("w9-form");
                              }}
                            >
                              {intl.formatMessage({
                                id: "station settings > w9-form",
                              })}
                            </NavLink>
                          </NavItem>
                        )}

                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "address-verification",
                            })}
                            onClick={() => {
                              setActiveTab("address-verification");
                            }}
                          >
                            {intl.formatMessage({
                              id: "station settings > address verification",
                            })}
                          </NavLink>
                        </NavItem>

                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "fulfillment-automation",
                            })}
                            onClick={() => {
                              setActiveTab("fulfillment-automation");
                            }}
                          >
                            {intl.formatMessage({
                              id: "station settings > fulfillment automation",
                            })}
                          </NavLink>
                        </NavItem>
                      </PrivilegedComponent>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="eligibility">
                        {activeTab === "eligibility" ? (
                          <EligibilityForm
                            currentStation={currentStation}
                            stations={stations}
                            shouldBlockNavigation={shouldBlockNavigation}
                            setShouldBlockNavigation={setShouldBlockNavigation}
                          />
                        ) : null}
                      </TabPane>
                      {isShowW9Forms && (
                        <TabPane tabId="w9-form">
                          {activeTab === "w9-form" ? (
                            <W9Form
                              currentStation={currentStation}
                              stations={stations}
                              shouldBlockNavigation={shouldBlockNavigation}
                              setShouldBlockNavigation={
                                setShouldBlockNavigation
                              }
                            />
                          ) : null}
                        </TabPane>
                      )}

                      <TabPane tabId="address-verification">
                        {activeTab === "address-verification" ? (
                          <AddressVerification
                            currentStation={currentStation}
                            stations={stations}
                            shouldBlockNavigation={shouldBlockNavigation}
                            setShouldBlockNavigation={setShouldBlockNavigation}
                          />
                        ) : null}
                      </TabPane>
                      <TabPane tabId="fulfillment-automation">
                        {activeTab === "fulfillment-automation" ? (
                          <FulfillmentAutomation
                            currentStation={currentStation}
                            stations={stations}
                            shouldBlockNavigation={shouldBlockNavigation}
                            setShouldBlockNavigation={setShouldBlockNavigation}
                            isOpenConfirmationModal={isOpenConfirmationModal}
                            setIsOpenConfirmationModal={
                              setIsOpenConfirmationModal
                            }
                            setSelectedFulfillmentAutomation={
                              setSelectedFulfillmentAutomation
                            }
                            selectedFulfillmentAutomation={
                              selectedFulfillmentAutomation
                            }
                          />
                        ) : null}
                      </TabPane>
                    </TabContent>
                    <BlockingComponent
                      isBlocked={shouldBlockNavigation}
                      modalProps={{
                        title: intl.formatMessage({
                          id:
                            "reminder modal > wait, you have unsaved changes in this form",
                        }),
                        confirmTitle: intl.formatMessage({
                          id: "reminder modal > button save",
                        }),
                        cancelTitle: intl.formatMessage({
                          id: "reminder modal > button dismiss",
                        }),
                        isCancelConfirmedNavigation: true,
                        onConfirm: () => {
                          const buttonSubmit = document.getElementById(
                            `btn-submit-${activeTab}`
                          );
                          // trigger click to submit the form
                          if (buttonSubmit) buttonSubmit.click();
                        },
                      }}
                    />
                    <ConfirmationModal
                      isOpen={isOpenConfirmationModal}
                      title={intl.formatMessage({
                        id:
                          "reminder modal > wait, you have unsaved changes in this form",
                      })}
                      isCloseOutside={false}
                      className={bem.e("confirmation-modal")}
                      description={intl.formatMessage({
                        id:
                          "reminder modal > before you can perform this step, you must save or discard your changes",
                      })}
                      confirmTitle={intl.formatMessage({
                        id: "reminder modal > button save",
                      })}
                      discardTitle={intl.formatMessage({
                        id: "reminder modal > button discard",
                      })}
                      cancelTitle={intl.formatMessage({
                        id: "reminder modal > button cancel",
                      })}
                      onConfirm={() => {
                        const buttonSubmit = document.getElementById(
                          `btn-submit-${activeTab}`
                        );
                        // trigger click to submit the form
                        if (buttonSubmit) buttonSubmit.click();
                        setIsOpenConfirmationModal(false);
                      }}
                      onToggle={() =>
                        setIsOpenConfirmationModal(!isOpenConfirmationModal)
                      }
                      onDiscard={() => {
                        setShouldBlockNavigation(false);
                        setIsOpenConfirmationModal(false);
                        setSelectedFulfillmentAutomation(null);
                      }}
                      onCancel={() => setIsOpenConfirmationModal(false)}
                    />
                  </React.Fragment>
                ) : null}
              </div>
            </Page>
          );
        } else {
          return <Redirect to="/" />;
        }
      }}
    </PrivilegedComponent>
  );
};

export default StationSettings;
