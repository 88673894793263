import React, { useState } from "react";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import { get, toLower, indexOf, includes, map } from "lodash";
import { FaSortDesc, FaSortAsc } from "react-icons/lib/fa";
import { Table } from "reactstrap";
import { getSortUserName } from "utils/helpers";
import Avatar from "components/Avatar";
import ConfirmationModal from "components/ConfirmationModal";
import NoItemsFound from "components/NoItemsFound";
import useInfiniteScroll from "utils/useInfiniteScroll";
import { BlockIcon } from "components/CustomIcons";
import { default_templates } from "utils/defaults";
import moment from "moment";
import DeadlineTimer from "components/DeadlineTimer";

const CommentAvatar = (props) => {
  const { bem, user, isShowAvatar } = props;
  return (
    <div className={bem.e("comment-display")}>
      <div
        className={classnames(bem.e("comment-avatar"), {
          "avatar-sort-name": !isShowAvatar,
        })}
      >
        {isShowAvatar ? (
          <img src={user.avatar_url} alt={user.name} />
        ) : (
          <span>{getSortUserName(get(user, "name", ""))}</span>
        )}
      </div>
    </div>
  );
};
const getIsBlocked = (process, user) => {
  const assignmentsBeingDone = get(
    process,
    "_source.team_assignments_being_done_by",
    ""
  );
  const isBlocked =
    get(assignmentsBeingDone, "id") &&
    get(assignmentsBeingDone, "id") !== user.id;
  return isBlocked;
};

const dueDateDisplay = (process) => {
  const timezone = get(process, "_source.timezone", null);
  const stepDeadline = get(process, "_source.step_deadline");
  if (stepDeadline) {
    return <DeadlineTimer date={stepDeadline} timezone={timezone} />;
  }
  const orderStartDate = get(process, "_source.order_start_date");
  const dueDate = get(process, "_source.due_date");
  const date = dueDate || orderStartDate;
  if (!date) return null;
  return <DeadlineTimer date={date} timezone={timezone} />;
};

const View = ({
  actionSort,
  sort,
  processList,
  bem,
  onClickRow,
  hasMoreItems,
  loadItems,
  isLoading,
  user,
  intl,
  dateFormatByServer,
}) => {
  const [takeOverOrder, setTakeOverOrder] = useState(null);
  const [isOpenTakeOverModal, setIsOpenTakeOverModal] = useState(null);

  const commentDisplay = (process) => {
    const lastAction = get(process, "_source.last_action");
    if (!lastAction) return null;
    // It SHOULD display the commenter's avatar in the recipients Current Tasks list.
    if (get(lastAction, "m_type") === "chat") {
      const commenter = get(lastAction, "from", {});
      const isShowAvatar =
        commenter.avatar_url &&
        indexOf(commenter.avatar_url, "avatar_white_circle") === -1;
      return (
        commenter.id !== user.id &&
        includes(get(lastAction, "tagged", []), user.id) && (
          <CommentAvatar
            isShowAvatar={isShowAvatar}
            bem={bem}
            user={commenter}
          />
        )
      );
    }
    return null;
  };

  const timeToAirDisplay = (process) => {
    const orderStartDate = get(process, "_source.order_start_date", "");
    return orderStartDate
      ? moment(orderStartDate).format(dateFormatByServer)
      : "";
  };

  const typeDisplay = (process) => {
    const orderSold = get(process, "_source.order_sold", true);
    const usersGranted = process._source.users_granted_me_access
      ? Object.values(process._source.users_granted_me_access)
      : [];
    return (
      <div
        className={classnames(bem.e("type"), {
          [bem.e("type-sold")]: orderSold,
          [bem.e("type-spec")]: !orderSold,
        })}
      >
        <FormattedMessage
          id={orderSold ? "process > order sold" : "process > order spec"}
        />
        {map(usersGranted, (u) => (
          <Avatar
            src={u.avatar_url}
            name={u.name}
            size={24}
            id={u.id}
            className="avatar-small"
          />
        ))}
      </div>
    );
  };
  const statusDisplay = (process) => {
    const processStep = get(process, "_source.process_step", "draft");
    let row_template = default_templates.find(
      (t) => t._id === get(process, "_source.template_key", "basic_production")
    );
    let step = get(row_template, `_source.steps.${processStep}`);
    let title =
      get(process, "_source.imported_from_wo", false) &&
      get(process, "_source.template_selected_for_wo", false) == "" ? (
        <FormattedMessage id={"process > title wo draft"} />
      ) : (
        <FormattedMessage id={"process > title draft"} />
      );
    let color = "#9AA4CA";
    if (title !== "draft") {
      color = get(step, "caption_color", "#9AA4CA");
    }
    if (get(step, "title")) {
      title = get(step, "title");
      if (step.key === "approve_recording") {
        if (get(process, "_source.sent_to_client_for_spot_review")) {
          title = <FormattedMessage id={"process > title client review"} />;
        }
      }
      if (step.key === "approve_script") {
        if (get(process, "_source.sent_to_client_for_script_review")) {
          title = <FormattedMessage id={"process > title client review"} />;
        }
      }
      if (step.key === "order_details") {
        if (get(process, "_source.order_details_status") === "draft")
          title = "draft";
      }
      if (step.key === "record_script") {
        if (get(process, "_source.approve_recording_status") === "rejected") {
          title = <FormattedMessage id={"process > title spot change"} />;
          color = "#E40061";
        }
      }
      if (step.key === "write_script") {
        if (get(process, "_source.approve_script_status") === "rejected")
          title = <FormattedMessage id={"process > title script change"} />;
        color = "#E40061";
      }
      if (step.key === "assign_vo") {
        title = <FormattedMessage id={"process > title assign vo"} />;
        color = "#A82AC0";
      }
      if (step.key === "voice_over") {
        if (get(process, "_source.voice_over_status") === "rejected") {
          title = <FormattedMessage id={"process > title change request"} />;
          color = "#E40061";
        } else {
          title = <FormattedMessage id={"process > title voice over"} />;
        }
      }
      if (step.key === "continuity") {
        if (get(process, "_source.continuity_status") === "change_request") {
          title = <FormattedMessage id={"process > title change request"} />;
          color = "#E40061";
        }
      }
    }
    return (
      <span
        className={classnames(
          bem.e("order-status"),
          bem.e(
            `order-status-${
              title.replace ? toLower(title.replace(/ /g, "-")) : ""
            }`
          )
        )}
        style={{ color }}
      >
        {title}
      </span>
    );
  };

  const titleDisplay = (process) => {
    if (get(process, "_source.order_title")) {
      return process._source.order_title;
    }
    return <FormattedMessage id="process > title of spot" />;
  };

  const renderEmpty = () => {
    return <NoItemsFound />;
  };
  const orderLengthDisplay = (process) => {
    const orderLengthCustom = get(process, "_source.order_length_custom");
    let orderLength = get(process, "_source.order_length") || "00";
    if (orderLengthCustom) {
      const momentTime = moment.utc(parseInt(orderLength) * 1000);
      const formatted = momentTime.format(
        momentTime.minutes() > 0 ? "mm:ss" : ":ss"
      );
      return formatted;
    }
    return process._source.order_length
      ? `:${process._source.order_length}`
      : "";
  };
  const onSelectProcess = (process) => {
    const isBlocked = getIsBlocked(process, user);
    if (isBlocked) {
      setTakeOverOrder(process);
      setIsOpenTakeOverModal(true);
    } else {
      return onClickRow(process);
    }
  };

  useInfiniteScroll({
    useWindow: false,
    elementId: "cr-app-main",
    onLoadMore: loadItems,
    shouldLoadMore: hasMoreItems,
  });
  return (
    <div className={bem.e("table-wrapper")}>
      <Table className={bem.e("table")}>
        <thead>
          <tr>
            <th
              onClick={() => actionSort("order_sold")}
              className={classnames(
                "can-click",
                "col-table-10",
                bem.e("table-first-head")
              )}
            >
              <div className={bem.e("item-sortable")}>
                <FormattedMessage id="process > Type" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_sold",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_sold desc",
                    })}
                  />
                </div>
              </div>
            </th>
            <th
              onClick={() => actionSort("order_title")}
              className="col-table-20 can-click"
            >
              <div className={bem.e("item-sortable")}>
                <FormattedMessage id="process > client/title" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_title",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_title desc",
                    })}
                  />
                </div>
              </div>
            </th>
            <th
              onClick={() => actionSort("order_length")}
              className="col-table-10 can-click"
            >
              <div className={bem.e("item-sortable")}>
                <FormattedMessage id="process > length" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_length",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_length desc",
                    })}
                  />
                </div>
              </div>
            </th>

            <th className="col-table-15">
              <FormattedMessage id="process > title comments" />
            </th>
            <th
              onClick={() => actionSort("order_due_date")}
              className="col-table-10 can-click"
            >
              <div className={bem.e("item-sortable")}>
                <FormattedMessage id="process > field due date" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_due_date",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        sort === "order_due_date desc",
                    })}
                  />
                </div>
              </div>
            </th>
            <th
              onClick={() => actionSort("order_start_date")}
              className="col-table-10 can-click"
            >
              <div className={bem.e("item-sortable")}>
                <FormattedMessage id="process > start date" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_start_date",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        sort === "order_start_date desc",
                    })}
                  />
                </div>
              </div>
            </th>
            <th
              onClick={() => actionSort("process_step")}
              className="col-table-10 can-click"
            >
              <div className={bem.e("item-sortable-center")}>
                <FormattedMessage id="process > Status" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "process_step",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "process_step desc",
                    })}
                  />
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {processList.length === 0 && !isLoading ? (
            <tr>
              <td colSpan="7">{renderEmpty()}</td>
            </tr>
          ) : (
            processList.map((process) => {
              if (!process._source.template_key) return null;
              const isBlocked = getIsBlocked(process, user);
              return (
                <tr
                  key={process._id}
                  onClick={() => {
                    onSelectProcess(process);
                  }}
                  className={classnames({
                    [bem.e("row-blocked")]: isBlocked,
                  })}
                >
                  {/* type */}
                  <td
                    className={classnames(
                      "align-middle col-table-10",
                      bem.e("first-col")
                    )}
                  >
                    <span
                      className={classnames(
                        bem.e("td-value"),
                        bem.e("type-block")
                      )}
                    >
                      {typeDisplay(process)}
                      {isBlocked && <BlockIcon />}
                    </span>
                  </td>
                  {/* title */}
                  <td
                    className={classnames(
                      "align-middle col-table-20",
                      bem.e("order-title")
                    )}
                  >
                    <span className={bem.e("td-value")}>
                      <span className={"small"}>
                        {process._source.client_name}
                      </span>
                      {titleDisplay(process)}
                    </span>
                  </td>
                  {/* order_length */}
                  <td className="align-middle col-table-10">
                    <span className={bem.e("td-value")}>
                      {orderLengthDisplay(process)}
                    </span>
                  </td>
                  {/* comment */}
                  <td className={classnames("align-middle col-table-15")}>
                    <div
                      className={classnames("align-middle", bem.e("comment"))}
                    >
                      <span className={bem.e("mentions")}>
                        {commentDisplay(process)}
                      </span>
                    </div>
                  </td>
                  {/* due date */}
                  <td className="align-middle col-table-10">
                    <span className={bem.e("td-value")}>
                      {dueDateDisplay(process)}
                    </span>
                  </td>
                  {/* time to air */}
                  <td className="align-middle col-table-10">
                    <span className={bem.e("td-value")}>
                      {timeToAirDisplay(process)}
                    </span>
                  </td>
                  {/* status */}
                  <td className="align-middle col-table-10 text-center">
                    <span className={bem.e("td-value")}>
                      {statusDisplay(process)}
                    </span>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </Table>
      <ConfirmationModal
        isOpen={isOpenTakeOverModal}
        title={
          takeOverOrder
            ? intl.formatMessage(
                {
                  id: "process > title take over order",
                },
                {
                  NAME:
                    takeOverOrder &&
                    takeOverOrder._source.team_assignments_being_done_by
                      ? takeOverOrder._source.team_assignments_being_done_by
                          .name
                      : "",
                }
              )
            : ""
        }
        isCloseOutside={false}
        className={bem.e("confirmation-modal")}
        confirmTitle={intl.formatMessage({
          id: "process > button take over",
        })}
        cancelTitle={intl.formatMessage({
          id: "process > button cancel",
        })}
        onConfirm={() => {
          onClickRow(takeOverOrder);
          setTakeOverOrder(null);
          setIsOpenTakeOverModal(false);
        }}
        onToggle={() => setIsOpenTakeOverModal(false)}
        onCancel={() => {
          setIsOpenTakeOverModal(false);
        }}
      />
    </div>
  );
};
export default View;
