import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";
import { FormattedMessage } from "react-intl";
import bn from "utils/bemnames";
import { CloseIcon } from "components/CustomIcons";
import CustomForm from "components/CustomForm";
const bem = bn.create("channel-preview-modal");

const ChannelPreviewModal = ({ onToggle, isOpen, title, channel }) => (
  <Modal isOpen={isOpen} className={bem.b()} size="lg">
    <ModalHeader toggle={onToggle} className={bem.e("header")}>
      {title}
      <span onClick={onToggle} className={bem.e("close")}>
        <CloseIcon />
      </span>
    </ModalHeader>
    <ModalBody className={bem.e("body")}>
      <CustomForm
        channel={channel}
        isShowAdjustChannelSchedule={true}
        isPreview={true}
      />
    </ModalBody>
    <ModalFooter className={bem.e("footer")}>
      <Button onClick={onToggle} className={"btn-blue btn-radius"}>
        <FormattedMessage id="channel manager > button close" />
      </Button>
    </ModalFooter>
  </Modal>
);

ChannelPreviewModal.defaultProps = {
  isOpen: false,
  onToggle: () => {},
  title: <FormattedMessage id="channel manager > preview modal title" />,
};
ChannelPreviewModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  title: PropTypes.any.isRequired,
};
export default ChannelPreviewModal;
