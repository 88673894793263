import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalHeader, Button, ModalFooter } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { join, get, last, first } from "lodash";
import { MdImportantDevices } from "react-icons/lib/md";
import { FaUser } from "react-icons/lib/fa";
import ToastManager from "components/ToastManager";
import Spinner from "components/Spinner";
import bn from "utils/bemnames";
import Dropzone from "react-dropzone";
import "cropperjs/dist/cropper.css";
import Cropper from "react-cropper";
import { CloseIcon } from "components/CustomIcons";
import classnames from "classnames";

const bem = bn.create("edit-image-modal");

class EditImageModal extends React.Component {
  cropper = React.createRef(null);
  onDrop = (acceptedFiles, rejectedFiles, mimes) => {
    if (rejectedFiles.length) {
      const mimesText = mimes
        .map((mime) => {
          const mimeExt = last(mime.split("/"));
          return `*.${mimeExt}`;
        })
        .join(",");
      let message = this.props.intl.formatMessage(
        {
          id: `edit image > only :mimesText formats are supported`,
        },
        {
          mimesText,
        }
      );
      let file = first(rejectedFiles);
      if (this.props.maxSize && this.props.maxSize < file.size) {
        message = this.props.intl.formatMessage(
          {
            id: `edit image > maximum file upload size is :maximum`,
          },
          {
            maximum: Number(this.props.maxSize) / 1000,
          }
        );
      }
      ToastManager.show({
        title: <MdImportantDevices />,
        message,
        autoDismiss: 1.5,
        level: "error",
      });
    }
    if (acceptedFiles.length) {
      const url = URL.createObjectURL(acceptedFiles[0]);
      this.props.setImage(url);
      this.props.setFile(acceptedFiles[0]);
    }
  };
  onPlus = () => {
    const getCropBoxData = this.cropper.getCropBoxData();
    const width = getCropBoxData.width + (getCropBoxData.width * 10) / 100;
    const height = getCropBoxData.height + (getCropBoxData.height * 10) / 100;
    this.cropper.setCropBoxData({
      ...getCropBoxData,
      width,
      height,
    });
  };
  onReduce = () => {
    const getCropBoxData = this.cropper.getCropBoxData();
    const width = getCropBoxData.width - (getCropBoxData.width * 10) / 100;
    const height = getCropBoxData.height - (getCropBoxData.height * 10) / 100;
    this.cropper.setCropBoxData({
      ...getCropBoxData,
      width,
      height,
    });
  };
  onCrop = () => {
    const { file } = this.props;
    this.cropper.getCroppedCanvas().toBlob(
      (blob) => {
        this.props.setImageCropped(blob);
      },
      file ? file.type : "image/jpeg",
      1
    );
  };
  render() {
    const {
      onToggle,
      isOpen,
      mimes,
      isDisableDropZone,
      isLoading,
      title,
      maxSize,
    } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        className={bem.b()}
        size="lg"
      >
        <ModalHeader toggle={onToggle} className={bem.e("header")}>
          {title}
          <button className={bem.e("close")} onClick={onToggle}>
            <CloseIcon color={"#657894"} />
          </button>
        </ModalHeader>
        <ModalBody className={bem.e("body")}>
          <div className={bem.e("sub-title")}>
            <FormattedMessage id="edit image > drag to reposition" />
          </div>
          <div className={bem.e("drop-container")}>
            <Dropzone
              onDrop={(acceptedFiles, rejectedFiles) =>
                this.onDrop(acceptedFiles, rejectedFiles, mimes)
              }
              disabled={isDisableDropZone}
              multiple={false}
              accept={join(mimes, ",")}
              maxSize={maxSize}
            >
              {({ getRootProps, getInputProps }) => {
                return (
                  <div
                    className={classnames("drop-area", {
                      [bem.e("no-image")]: !this.props.image,
                    })}
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    {this.props.image ? (
                      <Cropper
                        ref={(ref) => (this.cropper = ref)}
                        src={get(this.props, "image")}
                        style={{ height: "100%", width: "100%" }}
                        // Cropper.js options
                        aspectRatio={1 / 1}
                        guides={false}
                        crop={this.onCrop}
                        background={false}
                        cropmove={(e) => {
                          this.props.setIsDisableDropZone(true);
                        }}
                        cropend={() => {
                          setTimeout(() => {
                            this.props.setIsDisableDropZone(false);
                          }, 200);
                        }}
                      />
                    ) : (
                      <div className="placeholder">
                        <FaUser />
                        &nbsp;
                        <FormattedMessage id="edit image > upload photo" />
                      </div>
                    )}
                  </div>
                );
              }}
            </Dropzone>
          </div>
          <div className={bem.e("resize-action")}>
            <span className={bem.e("resize-action-text")}>
              <FormattedMessage id="edit image > resize" />:
            </span>

            <div className={bem.e("button-resize")}>
              <span className={bem.e("resize-icon")} onClick={this.onPlus}>
                +
              </span>
              <span className={bem.e("resize-icon")} onClick={this.onReduce}>
                -
              </span>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="blue"
            className="btn btn-radius"
            onClick={this.props.onUploadImageCropped}
          >
            <FormattedMessage id={`edit image > button save`} />
          </Button>
          <Button
            color="blue"
            outline
            className="btn btn-radius"
            onClick={onToggle}
          >
            <FormattedMessage id={`edit image > button cancel`} />
          </Button>
        </ModalFooter>
        <Spinner isLoading={isLoading} />
      </Modal>
    );
  }
}
EditImageModal.defaultProps = {
  isOpen: false,
  onToggle: () => {},
  onSave: () => {},
  title: <FormattedMessage id="edit image > modal title" />,
};
EditImageModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  title: PropTypes.any.isRequired,
  onSave: PropTypes.func.isRequired,
};
export default EditImageModal;
