import React, { useState, useRef, useEffect } from "react";
import {
  CopyIcon,
  EyeIcon,
  DownIcon,
  UpIcon,
  MenuIcon,
  EditCircleIcon,
} from "components/CustomIcons";
import classnames from "classnames";
import PropTypes from "prop-types";
import { get, includes } from "lodash";
import { FormattedMessage } from "react-intl";
import ToggleSwitch from "components/ToggleSwitch";
import PrivilegedComponent from "components/PrivilegedComponent";
import IconSelector, {
  IconsConfig,
} from "components/ChannelManager/IconSelector";
import useOutsideClick from "utils/useOutsideClick";
import { PRIVILEGES } from "utils/constants";
const ChannelManagerItem = ({
  channel,
  bem,
  isOpenForm,
  setIsOpenForm,
  channelName,
  onCloneChannel,
  onSaveHiddenChannel,
  setCurrentPreviewChannel,
  setIsOpenChannelPreviewModal,
  setChannelIcon,
  channelIcon,
  isShowEditIcon,
  currentStation,
  hasUnsavedNew,
  setIsOpenConfirmationActionsUnsavedModalNewChannel,
}) => {
  const isShared = includes(
    get(channel, "_source.stations"),
    get(currentStation, "value")
  );
  const iconSelectorRef = useRef(null);
  let ChannelIcon = IconsConfig[channelIcon]
    ? IconsConfig[channelIcon]
    : IconsConfig["default"];
  const isNew = get(channel, "_source.id", "").indexOf("_new_") === 0;
  const [isVisibleIconSelector, setIsVisibleIconSelector] = useState(false);
  useOutsideClick(iconSelectorRef, () => {
    setIsVisibleIconSelector(false);
  });
  useEffect(() => {
    if (!isOpenForm && isVisibleIconSelector) setIsVisibleIconSelector(false);
  }, [isOpenForm]);
  return (
    <React.Fragment>
      <div
        className={classnames(bem.e("channel"), {
          [bem.e("channel-open-form")]: isOpenForm,
        })}
      >
        <div className={bem.e("channel-head")}>
          <div className={bem.e("section-main-info")}>
            <span className={bem.e("menu-icon")}>
              <MenuIcon />
            </span>
            <span
              className={bem.e("channel-icon")}
              id={`selector-tooltip-${get(channel, "_source.id", "")}`}
              ref={iconSelectorRef}
            >
              <ChannelIcon />
              {isShowEditIcon ? (
                <span
                  className={classnames(bem.e("edit-icon"), "disable-unsaved")}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsVisibleIconSelector(!isVisibleIconSelector);
                  }}
                >
                  <EditCircleIcon
                    className={"disable-unsaved"}
                    width={17}
                    height={17}
                  />
                  {isVisibleIconSelector ? (
                    <IconSelector
                      selected={channelIcon}
                      onSelect={(data) => {
                        setChannelIcon(data);
                        setIsVisibleIconSelector(false);
                      }}
                    />
                  ) : null}
                </span>
              ) : (
                <span />
              )}
            </span>
            <h3
              className={classnames(bem.e("channel-title"), {
                [bem.e("channel-title-inactive")]: get(
                  channel,
                  "_source.is_hidden",
                  false
                ),
              })}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsOpenForm(get(channel, "_source.id", ""));
              }}
            >
              {channelName}
              {` `}
              {isShared ? (
                <span className={bem.e("shared-text")}>
                  (<FormattedMessage id="channel manager > shared" />)
                </span>
              ) : null}
            </h3>
          </div>
          <div className={bem.e("section-main-action")}>
            <div
              className={bem.e("btn-preview")}
              onClick={() => {
                if (hasUnsavedNew) {
                  setIsOpenConfirmationActionsUnsavedModalNewChannel(true);
                  return;
                }
                setIsOpenChannelPreviewModal(true);
                setCurrentPreviewChannel(get(channel, "_source"));
              }}
            >
              <EyeIcon color="#795AFA" width={24} height={16} />
              <FormattedMessage id="channel manager > preview channel" />
            </div>
            <PrivilegedComponent requires={[PRIVILEGES.EDIT_CHANNELS]}>
              {({ isValid }) => {
                if (isValid) {
                  return (
                    <div
                      className={classnames(bem.e("btn-clone"), {
                        [bem.e("btn-clone-disabled")]: isNew,
                      })}
                      onClick={() => {
                        if (hasUnsavedNew) {
                          setIsOpenConfirmationActionsUnsavedModalNewChannel(
                            true
                          );
                          return;
                        }
                        if (!isNew) {
                          onCloneChannel(get(channel, "_source"));
                        }
                      }}
                    >
                      <CopyIcon
                        color={isNew ? "#BCCEDB" : "#795AFA"}
                        width={20.55}
                        height={20}
                      />
                      <FormattedMessage id="channel manager > clone channel" />
                    </div>
                  );
                }
                return (
                  <div
                    className={classnames(
                      bem.e("btn-clone"),
                      bem.e("btn-clone-disabled")
                    )}
                  >
                    <CopyIcon
                      color={isNew ? "#BCCEDB" : "#795AFA"}
                      width={20.55}
                      height={20}
                    />
                    <FormattedMessage id="channel manager > clone channel" />
                  </div>
                );
              }}
            </PrivilegedComponent>
          </div>
          <button
            className={bem.e("button-down")}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (hasUnsavedNew) {
                setIsOpenConfirmationActionsUnsavedModalNewChannel(true);
              } else {
                setIsOpenForm(get(channel, "_source.id", ""));
              }
            }}
          >
            {isOpenForm ? (
              <UpIcon
                className={bem.e("button-up-icon")}
                color="#795AFA"
                width={24}
                height={15.46}
              />
            ) : (
              <DownIcon
                className={bem.e("button-down-icon")}
                color="#795AFA"
                width={24}
                height={15.46}
              />
            )}
          </button>
        </div>
      </div>
      <div
        className={classnames(bem.e("channel-checkbox"), {
          [bem.e("channel-checkbox-new")]: isNew,
          [bem.e("channel-checkbox-hidden")]: isOpenForm && !isNew, // use the switch in the form
        })}
      >
        <PrivilegedComponent requires={[PRIVILEGES.PUBLISH_CHANNELS]}>
          {({ isValid }) => {
            if (isValid) {
              return (
                <ToggleSwitch
                  leftComponent={null}
                  rightComponent={null}
                  name="is_hidden"
                  switchProps={{
                    checked: !get(channel, "_source.is_hidden", false),
                    onChange: (checked) => {
                      onSaveHiddenChannel(get(channel, "_source", {}), checked);
                    },
                    disabled: false,
                    offColor: "#C2D4E0",
                    onColor: "#795AFA",
                    uncheckedIcon: false,
                    checkedIcon: false,
                    boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.282)",
                    height: 26.44,
                    width: 48.48,
                    handleDiameter: 22.92,
                  }}
                />
              );
            } else {
              return (
                <ToggleSwitch
                  leftComponent={null}
                  rightComponent={null}
                  name="is_hidden"
                  switchProps={{
                    checked: !get(channel, "_source.is_hidden", false),
                    onChange: (checked) => {},
                    disabled: true,
                    offColor: "#C2D4E0",
                    onColor: "#795AFA",
                    uncheckedIcon: false,
                    checkedIcon: false,
                    boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.282)",
                    height: 26.44,
                    width: 48.48,
                    handleDiameter: 22.92,
                  }}
                />
              );
            }
          }}
        </PrivilegedComponent>
      </div>
    </React.Fragment>
  );
};
ChannelManagerItem.propTypes = {
  isOpenForm: PropTypes.bool,
  setIsOpenForm: PropTypes.func,
  channel: PropTypes.object,
  onCloneChannel: PropTypes.func,
  channelName: PropTypes.string,
};
ChannelManagerItem.defaultProps = {
  channel: {},
  onCloneChannel: () => {},
  setIsOpenForm: () => {},
  isOpenForm: false,
  channelName: "",
};
export default ChannelManagerItem;
