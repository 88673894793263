import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { Label } from "reactstrap";
import { get, forEach, find, omit, split } from "lodash";
import Select, { components } from "react-select";
import Radio from "components/Radio";
import { CaretIcon, CloseSmallIcon, DownIcon } from "components/CustomIcons";
import bn from "utils/bemnames";

const bem = bn.create("group-section-dropdown");
function getValue(props) {
  let value;
  let selectedGroup;
  forEach(props.options, (group) => {
    const newValue = find(group.options, (item) => item.value === props.value);
    if (!value) {
      selectedGroup = group;
      value = newValue;
      return;
    }
  });
  return { value, group: selectedGroup };
}

const Group = ({ isOpenGroup, setIsOpenGroup, ...rest }) => {
  const id = rest.data.value;
  let props = omit(rest, ["children"]);
  if (isOpenGroup === id) {
    props = rest;
  }
  return (
    <div className={bem.e("group")}>
      <components.Group {...props} />
    </div>
  );
};
const GroupHeading = ({ isOpenGroup, setIsOpenGroup, options, ...rest }) => {
  const index = split(rest.id, "-").findIndex((item) => item === "group") + 1;
  const indexOfGroupOption = get(split(rest.id, "-"), index);
  const group = get(options, indexOfGroupOption);
  return (
    <div
      className={bem.e("group-heading")}
      onClick={() =>
        setIsOpenGroup(group.value === isOpenGroup ? null : group.value)
      }
    >
      <span
        className={classnames(
          {
            [bem.e("group-heading-icon-active")]: group.value === isOpenGroup,
          },
          bem.e("group-heading-icon")
        )}
      >
        <DownIcon />
      </span>
      <components.GroupHeading {...rest} />
    </div>
  );
};
const ClearIndicator = (data) => {
  return (
    components.ClearIndicator && (
      <components.ClearIndicator {...data} className="disable-unsaved">
        <CloseSmallIcon className="disable-unsaved" />
      </components.ClearIndicator>
    )
  );
};
const DropdownIndicator = (data) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...data} className="disable-unsaved">
        <CaretIcon className="disable-unsaved" />
      </components.DropdownIndicator>
    )
  );
};
const CustomOption = (option) => {
  const isOptionSelected =
    get(option, "selectProps.value.value") === option.value;
  return (
    <div className={classnames(bem.e("option-container"))}>
      <components.Option {...option}>
        <Radio checked={isOptionSelected} text={option.data.label} />
      </components.Option>
    </div>
  );
};
export default function GroupSectionDropdown(props) {
  const { value, group } = getValue(props);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isOpenGroup, setIsOpenGroup] = useState(null);
  useEffect(() => {
    setIsOpenGroup(get(group, "value", null));
  }, [group]);

  const configComponents = {
    DropdownIndicator,
    ClearIndicator,
    GroupHeading: (data) => (
      <GroupHeading
        {...data}
        setIsOpenGroup={setIsOpenGroup}
        isOpenGroup={isOpenGroup}
        options={props.options}
      />
    ),
    Group: (data) => (
      <Group
        {...data}
        setIsOpenGroup={setIsOpenGroup}
        isOpenGroup={isOpenGroup}
      />
    ),
    Option: CustomOption,
  };
  return (
    <div className={bem.b()}>
      {!!props.label && (
        <Label for={props.name} {...props.labelProps}>
          {props.label}
        </Label>
      )}
      <Select
        {...props}
        value={value}
        components={configComponents}
        className={classnames(
          "react-select-container",
          props.className && props.className.toString(),
          {
            "react-select-menu-open": isMenuOpen,
          }
        )}
        classNamePrefix="react-select"
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => setIsMenuOpen(false)}
        styles={{
          // Fixes the overlapping problem of the component
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
        }}
      />
      {!!props.error && <span className="text-danger">{props.error}</span>}
    </div>
  );
}
GroupSectionDropdown.defaultProps = {};
