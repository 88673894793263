import React from "react";
import PropTypes from "prop-types";
import bn from "utils/bemnames";
import classNames from "classnames";
import { ArrowRightIcon } from "components/CustomIcons";
import useOutsideClick from "utils/useOutsideClick";
import { useRef } from "react";

const bem = bn.create("right-sidebar");
function TeamSidebar(props) {
  const { onCancel, isOpen, children, type, subtitle, title } = props;
  const containerRef = useRef();
  useOutsideClick(containerRef, (event) => {
    const openModal = document
      .querySelector("body")
      .classList.contains("modal-open");
    const isClickDropdownIcon = event.target
      ? event.target.classList.contains("disable-unsaved")
      : false;
    if (!openModal && !isClickDropdownIcon) {
      onCancel();
    }
  });
  return (
    <div ref={containerRef}>
      <div className={bem.b()}>
        {isOpen && (
          <div className={bem.e("background")} onClick={() => onCancel()}></div>
        )}
        <div
          className={classNames(bem.e("wrapper"), bem.e(type), {
            [bem.e("open")]: isOpen,
            [bem.e("close")]: !isOpen,
          })}
          id={"right-sidebar"}
        >
          <div className={bem.e("header")}>
            <div className={bem.e("btn-close")} onClick={() => onCancel()}>
              <ArrowRightIcon color="#8165F3" />
            </div>
            <div className={bem.e("title")}>
              <h3>{title}</h3>
              {subtitle ? <span>{subtitle}</span> : null}
            </div>
          </div>
          {isOpen && children}
        </div>
      </div>
    </div>
  );
}
TeamSidebar.defaultProps = {
  isOpen: false,
  onCancel: () => {},
  children: null,
  type: "",
  title: "",
  subtitle: "",
};
TeamSidebar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  children: PropTypes.element,
  type: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
export default TeamSidebar;
