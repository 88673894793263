export const INIT_NEW_PROCESS = "INIT_NEW_PROCESS";
export const PROCESS_VALUE_CHANGED = "PROCESS_VALUE_CHANGED";
export const SAVE_FORM_VALUES_REQUEST = "SAVE_FORM_VALUES_REQUEST";
export const SAVE_FORM_VALUES_SUCCESS = "SAVE_FORM_VALUES_SUCCESS";
export const SAVE_FORM_VALUES_ERROR = "SAVE_FORM_VALUES_ERROR";

export const FETCH_PROCESS_REQUEST = "FETCH_PROCESS_REQUEST";
export const FETCH_PROCESS_SUCCESS = "FETCH_PROCESS_SUCCESS";
export const FETCH_PROCESS_ERROR = "FETCH_PROCESS_ERROR";

export const RESET_PROCESS = "RESET_PROCESS";

export const FETCH_REVIEW_PROCESS_REQUEST = "FETCH_REVIEW_PROCESS_REQUEST";
export const FETCH_REVIEW_PROCESS_SUCCESS = "FETCH_REVIEW_PROCESS_SUCCESS";
export const FETCH_REVIEW_PROCESS_ERROR = "FETCH_REVIEW_PROCESS_ERROR";
export const SET_EXTRA_DATA_REVIEW_PROCESS = "SET_EXTRA_DATA_REVIEW_PROCESS";

export const DELETE_PROCESS_REQUEST = "DELETE_PROCESS_REQUEST";
export const DELETE_PROCESS_SUCCESS = "DELETE_PROCESS_SUCCESS";
export const DELETE_PROCESS_ERROR = "DELETE_PROCESS_ERROR";

export const UPDATE_PROCESS_REQUEST = "UPDATE_PROCESS_REQUEST";
export const UPDATE_PROCESS_SUCCESS = "UPDATE_PROCESS_SUCCESS";
export const UPDATE_PROCESS_ERROR = "UPDATE_PROCESS_ERROR";
export const UPDATE_PROCESS_INCOMING = "UPDATE_PROCESS_INCOMING";

export const FETCH_PROCESSES_REQUEST = "FETCH_PROCESSES_REQUEST";
export const FETCH_PROCESSES_SUCCESS = "FETCH_PROCESSES_SUCCESS";
export const FETCH_PROCESSES_ERROR = "FETCH_PROCESSES_ERROR";
export const FETCH_FORCE_PROCESSES_SUCCESS = "FETCH_FORCE_PROCESSES_SUCCESS";

export const FETCH_PROCESSES_INCOMING_REQUEST =
  "FETCH_PROCESSES_INCOMING_REQUEST";
export const FETCH_PROCESSES_INCOMING_SUCCESS =
  "FETCH_PROCESSES_INCOMING_SUCCESS";

export const ADD_PROCESS_REQUEST = "ADD_PROCESS_REQUEST";
export const ADD_PROCESS_SUCCESS = "ADD_PROCESS_SUCCESS";
export const ADD_PROCESS_ERROR = "ADD_PROCESS_ERROR";

export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
export const SET_TAB_TO_EDIT = "SET_TAB_TO_EDIT";
export const RESET_TAB = "RESET_TAB";

export const FORGET_PRCESSES = "FORGET_PRCESSES";

export const UPDATE_PROCESSES_INCOMING = "UPDATE_PROCESSES_INCOMING";

export const FORGET_PROCESS = "FORGET_PROCESS";

export const ASSIGNMENT_PROCESS_REQUEST = "ASSIGNMENT_PROCESS_REQUEST";
export const ASSIGNMENT_PROCESS_SUCCESS = "ASSIGNMENT_PROCESS_SUCCESS";
export const ASSIGNMENT_PROCESS_ERROR = "ASSIGNMENT_PROCESS_ERROR";

export const SYNC_WO_ORDERS_REQUEST = "SYNC_WO_ORDERS_REQUEST";
export const SYNC_WO_ORDERS_SUCCESS = "SYNC_WO_ORDERS_SUCCESS";
export const SYNC_WO_ORDERS_ERROR = "SYNC_WO_ORDERS_ERROR";

export const SYNC_WO_CART_NUMBERS_REQUEST = "SYNC_WO_CART_NUMBERS_REQUEST";
export const SYNC_WO_CART_NUMBERS_SUCCESS = "SYNC_WO_CART_NUMBERS_SUCCESS";
export const SYNC_WO_CART_NUMBERS_ERROR = "SYNC_WO_CART_NUMBERS_ERROR";

export const AUTOMATE_DUBBING_REQUEST = "AUTOMATE_DUBBING_REQUEST";
export const AUTOMATE_DUBBING_SUCCESS = "AUTOMATE_DUBBING_SUCCESS";
export const AUTOMATE_DUBBING_ERROR = "AUTOMATE_DUBBING_ERROR";
export const AUTOMATE_DUBBING_PROGRESS = "AUTOMATE_DUBBING_PROGRESS";

export const SEND_MESSAGE_REQUEST = "SEND_MESSAGE_REQUEST";
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS";
export const SEND_MESSAGE_ERROR = "SEND_MESSAGE_ERROR";

export const NOTIFY_EDITING_PROCESS_REQUEST = "NOTIFY_EDITING_PROCESS_REQUEST";
export const NOTIFY_EDITING_PROCESS_SUCCESS = "NOTIFY_EDITING_PROCESS_SUCCESS";
export const NOTIFY_EDITING_PROCESS_ERROR = "NOTIFY_EDITING_PROCESS_ERROR";

export const FORGET_PROCESSES_FILTER_SUCCESS =
  "FORGET_PROCESSES_FILTER_SUCCESS";

export const ARCHIVE_PROCESS_REQUEST = "ARCHIVE_PROCESS_REQUEST";
export const ARCHIVE_PROCESS_SUCCESS = "ARCHIVE_PROCESS_SUCCESS";
export const ARCHIVE_PROCESS_ERROR = "ARCHIVE_PROCESS_ERROR";

export const UNLOCK_PROCESS_REQUEST = "UNLOCK_PROCESS_REQUEST";
export const UNLOCK_PROCESS_SUCCESS = "UNLOCK_PROCESS_SUCCESS";
export const UNLOCK_PROCESS_ERROR = "UNLOCK_PROCESS_ERROR";

export const ASSIGN_TEAM_PROCESS_REQUEST = "ASSIGN_TEAM_PROCESS_REQUEST";
export const ASSIGN_TEAM_PROCESS_SUCCESS = "ASSIGN_TEAM_PROCESS_SUCCESS";
export const ASSIGN_TEAM_PROCESS_ERROR = "ASSIGN_TEAM_PROCESS_ERROR";

export const FETCH_PROCESSES_BY_IDS_REQUEST = "FETCH_PROCESSES_BY_IDS_REQUEST";
export const FETCH_PROCESSES_BY_IDS_SUCCESS = "FETCH_PROCESSES_BY_IDS_SUCCESS";
export const FETCH_PROCESSES_BY_IDS_ERROR = "FETCH_PROCESSES_BY_IDS_ERROR";

export const REMOVE_PROCESS_FROM_LIST = "REMOVE_PROCESS_FROM_LIST";

export const SET_DIRTY_PROCESS_INCOMING_SUCCESS =
  "SET_DIRTY_PROCESS_INCOMING_SUCCESS";

export const ADD_ROTATION_REQUEST = "ADD_ROTATION_REQUEST";
export const ADD_ROTATION_SUCCESS = "ADD_ROTATION_SUCCESS";
export const ADD_ROTATION_ERROR = "ADD_ROTATION_ERROR";

export const SET_ACTIVE_FILTER_PROCESSES = "SET_ACTIVE_FILTER_PROCESSES";

export const SEND_BACK_PROCESS_REQUEST = "SEND_BACK_PROCESS_REQUEST";
export const SEND_BACK_PROCESS_SUCCESS = "SEND_BACK_PROCESS_SUCCESS";
export const SEND_BACK_PROCESS_ERROR = "SEND_BACK_PROCESS_ERROR";

export const COPY_ORDER_REQUEST = "COPY_ORDER_REQUEST";
export const COPY_ORDER_SUCCESS = "COPY_ORDER_SUCCESS";
export const COPY_ORDER_ERROR = "COPY_ORDER_ERROR";

export const ASSIGN_VO_TEAM_PROCESS_REQUEST = "ASSIGN_VO_TEAM_PROCESS_REQUEST";
export const ASSIGN_VO_TEAM_PROCESS_SUCCESS = "ASSIGN_VO_TEAM_PROCESS_SUCCESS";
export const ASSIGN_VO_TEAM_PROCESS_ERROR = "ASSIGN_VO_TEAM_PROCESS_ERROR";

export const UPDATE_FINAL_SCRIPT_REQUEST = "UPDATE_FINAL_SCRIPT_REQUEST";
export const UPDATE_FINAL_SCRIPT_SUCCESS = "UPDATE_FINAL_SCRIPT_SUCCESS";
export const UPDATE_FINAL_SCRIPT_ERROR = "UPDATE_FINAL_SCRIPT_ERROR";

export const UNARCHIVE_PROCESS_REQUEST = "UNARCHIVE_PROCESS_REQUEST";
export const UNARCHIVE_PROCESS_SUCCESS = "UNARCHIVE_PROCESS_SUCCESS";
export const UNARCHIVE_PROCESS_ERROR = "UNARCHIVE_PROCESS_ERROR";

export const CONVERT_SPEC_TO_SOLD_REQUEST = "CONVERT_SPEC_TO_SOLD_REQUEST";
export const CONVERT_SPEC_TO_SOLD_SUCCESS = "CONVERT_SPEC_TO_SOLD_SUCCESS";
export const CONVERT_SPEC_TO_SOLD_ERROR = "CONVERT_SPEC_TO_SOLD_ERROR";

export const UNARCHIVE_PROCESS_FROM_PUSHER = "UNARCHIVE_PROCESS_FROM_PUSHER";
export const ARCHIVED_PROCESS_FROM_PUSHER = "ARCHIVED_PROCESS_FROM_PUSHER";

export const CHECK_AUTO_DUBBING_REQUEST = "CHECK_AUTO_DUBBING_REQUEST";
export const CHECK_AUTO_DUBBING_SUCCESS = "CHECK_AUTO_DUBBING_SUCCESS";
export const CHECK_AUTO_DUBBING_ERROR = "CHECK_AUTO_DUBBING_ERROR";
