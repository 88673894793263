import { compose, withHandlers, withState } from "recompose";
import { injectIntl } from "react-intl";
import View from "./view";
import { includes, difference, isEmpty, uniq } from "lodash";

export default injectIntl(
  compose(
    withState("isWeekendSelected", "setIsWeekendSelected", false),
    withState("isWeekDaysSelected", "setIsWeekDaysSelected", false),
    withState("isWeekDaysSelected", "setIsWeekDaysSelected", false),
    withState("weekDays", "setWeekDays", [2, 3, 4, 5, 6]),
    withState("weekends", "setWeekends", [1, 7]),
    withHandlers({
      onClick: ({
        value,
        onChange,
        setIsWeekDaysSelected,
        setIsWeekendSelected,
        disabledDays,
        weekDays,
        weekends,
      }) => (day, checked) => {
        let newDays = [];
        let days = value || [];
        if (!checked) {
          newDays = days.filter((item) => item !== day);
        } else {
          newDays = [...days, day];
        }
        if (disabledDays && disabledDays.length > 0) {
          newDays = difference(newDays, disabledDays);
        }
        onChange(newDays.sort());

        setIsWeekDaysSelected(isEmpty(difference(weekDays, newDays)));
        setIsWeekendSelected(isEmpty(difference(weekends, newDays)));
      },
      onWeekOrWeekendCheck: ({ value, onChange, mustInclude, disabledDays }) => ({
        checked, currentDays, isOtherSelected
      }) => {
        let newDays = [];
        let days = value || [];

        if (!checked) {
          newDays = days.filter((item) => !includes(currentDays, item));
        } else {
          // Keep selected days if weekdays/weekend checkbox is checked
          newDays = [...(isOtherSelected ? days : []), ...currentDays];
        }

        if (mustInclude && !includes(newDays, mustInclude)) {
          newDays = newDays.concat([mustInclude]);
        }
        if (disabledDays && disabledDays.length > 0) {
          newDays = difference(newDays, disabledDays);
        }
        onChange(uniq(newDays.sort()));
      },
    }),
    withHandlers({
      onClickWeekend: ({ onWeekOrWeekendCheck, weekends, isWeekDaysSelected }) => (
        checked
      ) => {
        onWeekOrWeekendCheck({ checked, currentDays: weekends, isOtherSelected: isWeekDaysSelected });
      },
      onClickWeekdays: ({ onWeekOrWeekendCheck, weekDays, isWeekendSelected }) => (
        checked
      ) => {
        onWeekOrWeekendCheck({ checked, currentDays: weekDays, isOtherSelected: isWeekendSelected });
      },
    })
  )(View)
);
