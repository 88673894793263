import ProductionProvided from "./ProductionProvided";
import BaseProduction from "./BaseProduction";
import Promotion from "./Promotion";
import PromotionEvent from "./PromotionEvent";
import Digital from "./Digital";
import PromotionRequest from "./PromotionRequest";
import PromotionLiner from "./PromotionLiner";
import PromotionContest from "./PromotionContest";
const DefaultTemplates = [
  BaseProduction,
  Promotion,
  PromotionEvent,
  PromotionRequest,
  PromotionLiner,
  PromotionContest,
  Digital,
  ProductionProvided
];
export default DefaultTemplates;
