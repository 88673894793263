import React from "react";
import bn from "utils/bemnames";
import { MdMenu } from "react-icons/lib/md";
import { get, map } from "lodash";
import classnames from "classnames";
import Dropdown from "components/Dropdown";
import { FormGroup, Row, Col } from "reactstrap";
import ApprovalIcon from "assets/img/tabs/Approval.png";
import { FaClose } from "react-icons/lib/fa";
import { FormattedMessage } from "react-intl";
import { arrayContainsArray } from "utils/helpers";
import { Asterisk } from "components/Elements";
const bem = bn.create("workflow-manager-approval");

const Approval = props => {
  if (props.isView) {
    return (
      <div className={bem.b()}>
        <Row>
          <Col className={bem.e("approval-view-col")}>
            <div className={bem.e("approval-view-container")}>
              <div className={classnames(bem.e("head-view"))}>
                <div className={bem.e("title")}>
                  <img
                    src={ApprovalIcon}
                    className={bem.e("approval-icon")}
                    alt={""}
                  />
                  <FormattedMessage id="workflow manager > approval" />:
                </div>
              </div>
              <div className={bem.e("approval-view-list")}>
                {map(props.users, (item, index) => (
                  <div key={index} className={bem.e("approval-view-item")}>
                    <span>{item.name}</span>
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
  const roleIdsSelected = map(get(props, 'value.roles', []), item => item.id);
  const { users, roles } = props;
  const usersOption = users.filter(user => {
    const userRoles = user.roles.map(roleItem => roleItem.id);
    return roleIdsSelected.length===0 || arrayContainsArray(userRoles, roleIdsSelected);
  }).map(user => ({
    label: user.name,
    value: user.id
  }));
  const rolesOption = map(roles, role => ({
    label: role.name,
    value: role.id
  }));
  return (
    <div className={bem.b()}>
      <Row>
        <Col>
          <div className={bem.e("head")}>
            <MdMenu className={bem.e("menu-icon")} />
            <div className={bem.e("title")}>
              <img
                src={ApprovalIcon}
                className={bem.e("approval-icon")}
                alt={""}
              />
              <FormattedMessage id="workflow manager > approval" />
              <div className={bem.e("close-icon")} onClick={props.onRemove}>
                <FaClose size="20" color="#C2D4E0" />
              </div>
            </div>
          </div>
          <div>
            <Row>
              <Col xs={6}>
                <FormGroup>
                  <Dropdown
                    label={
                      <span>
                        <FormattedMessage id="workflow manager > select roles" />
                        <Asterisk>*</Asterisk>
                      </span>
                    }
                    isMulti
                    isCheckbox={true}
                    options={rolesOption}
                    value={get(props, "value.roles", []).map(item => ({
                      label: item.name,
                      value: item.id
                    }))}
                    onChange={selected => {
                      props.onChange({
                        roles: map(selected, item => ({
                          id: item.value,
                          name: item.label
                        })),
                        users: get(props, "value.users")
                      });
                    }}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                  />
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <Dropdown
                    label={
                      <span>
                        <FormattedMessage id="workflow manager > assign users" />
                        <Asterisk>*</Asterisk>
                      </span>
                    }
                    isMulti
                    isCheckbox={true}
                    options={usersOption}
                    value={get(props, "value.users", []).map(item => ({
                      label: item.name,
                      value: item.id
                    }))}
                    onChange={selected => {
                      props.onChange({
                        users: map(selected, item => ({
                          id: item.value,
                          name: item.label
                        })),
                        roles: get(props, "value.roles")
                      });
                    }}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};
Approval.defaultProps = {
  isView: false
};
export default Approval;
