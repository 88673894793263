import * as Types from "store/types/clocks";
import { USER_INFO_SUCCESS } from "store/types/auth";
import { get, map, omit, isFunction } from "lodash";
import { userInfo } from "store/actions/auth";
import Api from "utils/api";
import * as Const from "utils/constants";
import queryString from "query-string";

export const getClocks = (state) => async (dispatch) => {
  let errorStr = "";
  try {
    const { pageSize, page, sorted, filtered } = state;

    const params = {
      page: page + 1,
      rpp: pageSize,
    };
    if (sorted.length > 0) {
      const sortedColumns = map(sorted, "id").join(",");
      params.sort = `${sortedColumns} ${
        get(sorted, `${sorted.length - 1}.desc`) ? "desc" : "asc"
      }`;
    }

    const index = filtered.findIndex((item) => item.id === "q");

    if (index !== -1) {
      params.q = filtered[index].value;
    }

    dispatch({ type: Types.FETCH_CLOCKS_REQUEST });

    let response = await Api.doCall(
      `${Const.GET_CLOCKS()}?${queryString.stringify(params)}`,
      "GET"
    );

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.FETCH_CLOCKS_SUCCESS,
          payload: response.data,
        });
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({
    type: Types.FETCH_CLOCKS_ERROR,
    payload: { data: errorStr },
  });
};

export const getMoreClocks = (state) => async (dispatch) => {
  let errorStr = "";

  try {
    const { pageSize, page, sorted, filtered } = state;
    const params = {
      page: page + 1,
      rpp: pageSize,
    };
    if (sorted.length > 0) {
      const sortedColumns = map(sorted, "id").join(",");
      params.sort = `${sortedColumns} ${
        get(sorted, `${sorted.length - 1}.desc`) ? "desc" : "asc"
      }`;
    }

    const index = filtered.findIndex((item) => item.id === "q");

    if (index !== -1) {
      params.q = filtered[index].value;
    }

    dispatch({ type: Types.FETCH_MORE_CLOCKS_REQUEST });

    let response = await Api.doCall(
      `${Const.GET_CLOCKS()}?${queryString.stringify(params)}`,
      "GET"
    );

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.FETCH_MORE_CLOCKS_SUCCESS,
          payload: response.data,
        });
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({
    type: Types.FETCH_MORE_CLOCKS_ERROR,
    payload: { data: errorStr },
  });
};

export const createClock = (data, cb) => async (dispatch) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.CREATE_CLOCK_REQUEST });

    let response = await Api.doCall(Const.GET_CLOCKS(), "POST", data);

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({ type: Types.CREATE_CLOCK_SUCCESS, payload: response.data });

        if (isFunction(cb)) {
          cb(response.data);
        }

        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({ type: Types.CREATE_CLOCK_ERROR, payload: errorStr });
};

export const getClock = (id) => async (dispatch) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.FETCH_CLOCK_REQUEST, payload: { id } });

    let response = await Api.doCall(Const.GET_CLOCK(id), "GET");

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.FETCH_CLOCK_SUCCESS,
          payload: { id, data: response.data },
        });
        return;
      }

      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({
    type: Types.FETCH_CLOCK_ERROR,
    payload: { id, error: errorStr },
  });
};

export const updateClock = (id, data, cb) => async (dispatch) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.UPDATE_CLOCK_REQUEST });

    let response = await Api.doCall(Const.GET_CLOCK(id), "PUT", data);

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.UPDATE_CLOCK_SUCCESS,
          payload: {
            id,
            data: response.data,
          },
        });

        if (isFunction(cb)) {
          cb(response.data);
        }

        return;
      }

      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({ type: Types.UPDATE_CLOCK_ERROR, payload: errorStr });
};

export const updateClockStatus = (id, data, cb) => async (dispatch) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.UPDATE_CLOCK_REQUEST });

    let response = await Api.doCall(
      Const.GET_CLOCK(id) + "/update_status",
      "PUT",
      data
    );

    if (response.status === 200 || response.status === 201) {
      if (!response.data.conflict) {
        dispatch({
          type: Types.UPDATE_CLOCK_SUCCESS,
          payload: {
            id,
            data: response.data,
          },
        });
      }
      if (isFunction(cb)) {
        cb(response.data);
      }

      return;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({ type: Types.UPDATE_CLOCK_ERROR, payload: errorStr });
};

export const deleteClock = (id) => async (dispatch) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.DELETE_CLOCK_REQUEST });

    let response = await Api.doCall(Const.GET_CLOCK(id), "DELETE");

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({ type: Types.DELETE_CLOCK_SUCCESS });
        return;
      }

      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({ type: Types.DELETE_CLOCK_ERROR, payload: errorStr });
};

export const duplicateClock = (params, cb) => async (dispatch) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.CREATE_CLOCK_REQUEST });

    const response = await Api.doCall(
      `${Const.GET_CLOCK(params.id)}/duplicate`,
      "POST",
      omit(params, ["id"])
    );

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({ type: Types.CREATE_CLOCK_SUCCESS });
        if (isFunction(cb)) {
          cb(response.data);
        }
        return;
      }

      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.CREATE_CLOCK_ERROR, payload: errorStr });
};

export const addNewTag = (data, tagType, cb) => async (dispatch) => {
  let response = await Api.doCall(
    `${Const.GET_CLOCKS()}/${
      tagType === "category" ? "add_slot_category" : "add_slot_type"
    }`,
    "POST",
    data
  );

  if (response.status === 200 || response.status === 201) {
    if (!response.data.error) {
      dispatch(userInfo());

      if (isFunction(cb)) {
        cb(response.data);
      }

      return;
    }
  }
};

export const deleteClockSlotCategory = (data, cb) => async (
  dispatch,
  getState
) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.DELETE_CLOCK_SLOT_CATEGORY_REQUEST });

    let response = await Api.doCall(
      `${Const.DELETE_SLOT_CATEGORY()}?${queryString.stringify(data)}`,
      "DELETE"
    );

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        const state = getState();
        const info = get(state, "auth.info");
        info.stations = info.stations.map((station) => {
          station.clock_slot_categories = omit(station.clock_slot_categories, [
            data.category_id,
          ]);
          return station;
        });
        dispatch({ type: USER_INFO_SUCCESS, payload: info });

        dispatch({ type: Types.DELETE_CLOCK_SLOT_CATEGORY_SUCCESS });
        dispatch(userInfo());
        if (isFunction(cb)) {
          cb(response.data);
        }
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({ type: Types.DELETE_CLOCK_SLOT_CATEGORY_ERROR, payload: errorStr });
};

export const updateClockRelations = (endpoint, data, cb) => async (
  dispatch
) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.UPDATE_CLOCK_REQUEST });

    let response = await Api.doCall(
      `${Const.GET_CLOCKS()}/${endpoint}`,
      "POST",
      data
    );

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.UPDATE_CLOCK_SUCCESS,
          payload: {
            id: data.clock_id,
            data: response.data,
          },
        });

        if (isFunction(cb)) {
          cb(response.data);
        }

        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({ type: Types.UPDATE_CLOCK_ERROR, payload: errorStr });
};

export const resetClock = ({ data, cb }) => async (dispatch) => {
  let errorStr = "";

  try {
    dispatch({ type: Types.UPDATE_CLOCK_REQUEST });

    let response = await Api.doCall(`${Const.RESET_CLOCK()}`, "POST", data);

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.UPDATE_CLOCK_SUCCESS,
          payload: {
            id: data.clock_id,
            data: response.data,
          },
        });
        if (isFunction(cb)) {
          cb(response.data);
        }
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }

  dispatch({ type: Types.UPDATE_CLOCK_ERROR, payload: errorStr });
};
