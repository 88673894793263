import React from "react";
import bn from "utils/bemnames";
import { FormattedMessage } from "react-intl";
import { FormGroup, Label, Row, Col } from "reactstrap";
import Dropdown from "components/Dropdown";
import { Asterisk } from "components/Elements";
import TextInput from "components/TextInput";
import ColorPicker from "components/CustomColorPicker";
import { find, get } from "lodash";
import PropTypes from "prop-types";
import types from "../JockConsoleForm/types.json";
const bem = bn.create("jock-console-item");
export default function WidgetFields(props) {
  const { isEdit, isWidgetDefault } = props;
  if (!isEdit) {
    const { jockConsole } = props;
    const typeItem = find(types, (item) => item.key === jockConsole.type);
    return (
      <React.Fragment>
        <Row>
          <Col xs={isWidgetDefault ? 11 : 6}>
            <FormGroup className={bem.e("col-name")}>
              <div className={bem.e("jock-console-view-field")}>
                <Label>
                  <FormattedMessage id="jock console manager > widget name" />
                </Label>
                <div className={bem.e("jock-console-view-field-value")}>
                  {get(jockConsole, "name") ? (
                    jockConsole.name
                  ) : (
                    <FormattedMessage id="jock console manager > none" />
                  )}
                </div>
              </div>
            </FormGroup>
          </Col>

          <Col xs={isWidgetDefault ? 1 : 6}>
            <div className={bem.e("col-type-color")}>
              {!isWidgetDefault && (
                <FormGroup className={bem.e("group-type")}>
                  <div className={bem.e("jock-console-view-field")}>
                    <Label>
                      <FormattedMessage id="jock console manager > widget type" />
                    </Label>
                    <div className={bem.e("jock-console-view-field-value")}>
                      {get(jockConsole, "type") ? (
                        <FormattedMessage
                          id={`jock console manager > type ${get(
                            typeItem,
                            "label"
                          )}`}
                        />
                      ) : (
                        <FormattedMessage id="jock console manager > none" />
                      )}
                    </div>
                  </div>
                </FormGroup>
              )}

              <FormGroup>
                <Label>
                  <FormattedMessage id="jock console manager > color" />
                </Label>
                <ColorPicker
                  selectedColor={get(jockConsole, "color")}
                  disabled
                  onChange={() => {}}
                />
              </FormGroup>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
  const { values, touched, errors, intl, typesOptions } = props;
  return (
    <React.Fragment>
      <Row>
        <Col xs={isWidgetDefault ? 11 : 6}>
          <FormGroup className={bem.e("col-name")}>
            <TextInput
              label={
                <span>
                  <FormattedMessage id="jock console manager > widget name" />
                  <Asterisk>*</Asterisk>
                </span>
              }
              type="text"
              name="name"
              placeholder={intl.formatMessage({
                id: "jock console manager > widget name",
              })}
              value={values.name}
              error={touched.name && errors.name}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
            />
          </FormGroup>
        </Col>
        <Col xs={isWidgetDefault ? 1 : 6}>
          <div className={bem.e("col-type-color")}>
            {!isWidgetDefault && (
              <FormGroup className={bem.e("group-type")}>
                <Dropdown
                  name="type"
                  label={
                    <span>
                      <FormattedMessage id="jock console manager > widget type" />
                      <Asterisk>*</Asterisk>
                    </span>
                  }
                  placeholder={props.intl.formatMessage({
                    id: "jock console manager > select one",
                  })}
                  value={find(typesOptions, (i) => i.value === values.type)}
                  onChange={(option) => {
                    props.setFieldValue("type", option.value);
                    // props.setFieldValue("color", option.data.color);
                    if (option.value === "other_links") {
                      props.setFieldValue("fields", {
                        links: [
                          {
                            label: "",
                            url: "",
                          },
                        ],
                      });
                    } else if (option.value === "phone_numbers") {
                      props.setFieldValue("fields", {
                        numbers: [
                          {
                            label: "",
                            number: "",
                          },
                        ],
                      });
                    } else if (option.value === "bulletin_board_replacement") {
                      props.setFieldValue("fields", {
                        replacement: {
                          start_date: "",
                          end_date: "",
                          text: "",
                        },
                      });
                    } else if (option.value === "rss_feed") {
                      props.setFieldValue("fields", {
                        url: "",
                      });
                    } else if (option.value === "text_editor") {
                      props.setFieldValue("fields", {
                        text: "",
                      });
                    }
                  }}
                  error={touched.type && errors.type}
                  options={typesOptions}
                />
              </FormGroup>
            )}

            <FormGroup>
              <Label>
                <FormattedMessage id="jock console manager > color" />
              </Label>
              <ColorPicker
                selectedColor={values.color}
                onChange={(color) => {
                  props.setFieldValue("color", color);
                }}
              />
            </FormGroup>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}
WidgetFields.defaultProps = {
  isEdit: false,
};
WidgetFields.propTypes = {
  isEdit: PropTypes.bool,
};
