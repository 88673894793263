import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { get, map, split, find, includes } from "lodash";
import TextInput from "components/TextInput";
import Dropdown from "components/Dropdown";
import TextInputFormatted from "components/TextInputFormatted";
import { PhoneNumberFormat, statesList } from "utils/config";
import { FormattedMessage } from "react-intl";
import { Asterisk } from "components/Elements";
import { Button, Form, FormGroup, Row, Col, Label } from "reactstrap";
import { injectIntl } from "react-intl";
import Spinner from "components/Spinner";
import bn from "utils/bemnames";
import DaysOfWeek from "components/DaysOfWeek";

import classnames from "classnames";
const bem = bn.create("location-form");
const timeOpenOptions = map([...Array(12).keys()], (item) => {
  return {
    value: item + 1,
    label: `${item + 1} AM`,
  };
});
const timeCloseOptions = map([...Array(12).keys()], (item) => {
  return {
    value: item + 1,
    label: `${item + 1} PM`,
  };
});
function LocationForm(props) {
  const { 
    intl, 
    onCancel, 
    onSubmit, 
    location, 
    optionalFields = [], 
    serverCountry 
  } = props;
  const nullableSchema = Yup.string().nullable();

  return (
    <>
      <Formik
        enableReinitialize
        className={bem.b()}
        initialValues={{
          name: get(location, "name", ""),
          contact: get(location, "contact", ""),
          phone: get(location, "phone", ""),
          add1: get(location, "add1", ""),
          add2: get(location, "add2", ""),
          city: get(location, "city", ""),
          state: get(location, "state", ""),
          zip: get(location, "zip", ""),
          time_open: get(location, "time_open", ""),
          time_close: get(location, "time_close", ""),
          office_days: get(location, "office_days", "")
            ? split(location.office_days, ",").map(Number)
            : [],
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required(
            intl.formatMessage({ id: "validate > name is required" })
          ),
          contact: Yup.string().concat(includes(optionalFields, "contact")
            ? nullableSchema
            : Yup.string().required(
              intl.formatMessage({ id: "validate > contact is required" })
            )),
          phone: Yup.string()
            .test(
              "len",
              intl.formatMessage({
                id: "validate > phone number must be 10 digits",
              }),
              (val) => !val || val.length === 10
          )
            .concat(includes(optionalFields, "phone")
              ? nullableSchema
              : Yup.string().required(
              intl.formatMessage({
                id: "validate > phone is required",
              })
            )),
          id: Yup.string(),
        })}
        onSubmit={onSubmit}
      >
        {(formProps) => {
          const errors = formProps.errors;
          const touched = formProps.touched;
          const values = formProps.values;
          const selectedState = find(statesList, item => item.value === values.state);
          return (
            <Form
              onSubmit={formProps.handleSubmit}
              noValidate
              className={bem.b()}
            >
              <div className={bem.e("content-form")}>
                <FormGroup>
                  <TextInput
                    label={
                      <span>
                        <FormattedMessage id="location > name" />
                        <Asterisk>*</Asterisk>
                      </span>
                    }
                    type="text"
                    name="name"
                    placeholder={intl.formatMessage({
                      id: "location > name",
                    })}
                    value={values.name}
                    error={touched.name && errors.name}
                    onChange={formProps.handleChange}
                    onBlur={formProps.handleBlur}
                  />
                </FormGroup>
                <Row>
                  <Col className={bem.e("col-reset")}>
                    <FormGroup>
                      <TextInput
                        label={
                          <span>
                            <FormattedMessage id="location > contact" />
                            {!includes(optionalFields, "contact") && <Asterisk>*</Asterisk>}
                          </span>
                        }
                        type="text"
                        name="contact"
                        placeholder={intl.formatMessage({
                          id: "location > enter contact",
                        })}
                        value={values.contact}
                        error={touched.contact && errors.contact}
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                      />
                    </FormGroup>
                  </Col>
                  <Col className={bem.e("col-reset")}>
                    <FormGroup>
                      <TextInputFormatted
                        format={PhoneNumberFormat}
                        label={
                          <span>
                            <FormattedMessage id="location > phone" />
                            {!includes(optionalFields, "phone") && <Asterisk>*</Asterisk>}
                          </span>
                        }
                        type="text"
                        name="phone"
                        placeholder={intl.formatMessage({
                          id: "location > enter phone",
                        })}
                        value={values.phone}
                        error={touched.phone && errors.phone}
                        onChange={(value) =>
                          formProps.setFieldValue("phone", value)
                        }
                        onBlur={formProps.handleBlur}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className={bem.e("col-reset")} xs={6}>
                    <FormGroup>
                      <TextInput
                        label={<FormattedMessage id="location > address 1" />}
                        type="text"
                        name="add1"
                        placeholder={intl.formatMessage({
                          id: "location > enter address 1",
                        })}
                        value={values.add1}
                        error={touched.add1 && errors.add1}
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                      />
                    </FormGroup>
                  </Col>
                  <Col className={bem.e("col-reset")} xs={6}>
                    <FormGroup>
                      <TextInput
                        label={<FormattedMessage id="location > address 2" />}
                        type="text"
                        name="add2"
                        placeholder={intl.formatMessage({
                          id: "location > enter address 2",
                        })}
                        value={values.add2}
                        error={touched.add2 && errors.add2}
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className={bem.e("col-reset")} xs={6}>
                    <FormGroup>
                      <TextInput
                        label={<FormattedMessage id="location > city" />}
                        type="text"
                        name="city"
                        placeholder={intl.formatMessage({
                          id: "location > enter city",
                        })}
                        value={values.city}
                        error={touched.city && errors.city}
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                      />
                    </FormGroup>
                  </Col>
                  <Col className={bem.e("col-reset")} xs={6}>
                    <Row>
                      <Col className={bem.e("col-reset")} xs={6}>
                        <Dropdown
                          label={<FormattedMessage id={serverCountry === "CA" ? "location > province" : "location > state" }/>}
                          type="text"
                          name="state"
                          placeholder={intl.formatMessage({
                            id: serverCountry === "CA" ? "location > enter province" : "location > enter state",
                          })}
                          value={selectedState}
                          error={touched.state && errors.state}
                          onChange={(option) => {
                            formProps.setFieldValue('state', option.value)
                          }}
                          onBlur={formProps.handleBlur}
                          options={statesList}
                        />
                      </Col>
                      <Col className={bem.e("col-reset")} xs={6}>
                        <FormGroup>
                          <TextInput
                            label={
                              <FormattedMessage id={serverCountry === "CA" ? "location > postal code" : "location > zip code" }/>
                            }
                            type="text"
                            name="zip"
                            placeholder={intl.formatMessage({
                              id: serverCountry === "CA" ? "location > enter postal code" : "location > enter zip code",
                            })}
                            value={values.zip}
                            error={touched.zip && errors.zip}
                            onChange={formProps.handleChange}
                            onBlur={formProps.handleBlur}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col className={bem.e("col-reset")} xs={6}>
                    <Row>
                      <Col xs={12} className={bem.e("col-reset")}>
                        <Label>
                          <FormattedMessage id="location > office hours" />
                        </Label>
                      </Col>
                      <Col xs={5} className={bem.e("col-reset")}>
                        <FormGroup>
                          <Dropdown
                            value={
                              values.time_open
                                ? {
                                    value: values.time_open,
                                    label: `${values.time_open} AM`,
                                  }
                                : ""
                            }
                            placeholder={intl.formatMessage({
                              id: "location > select",
                            })}
                            onChange={(selectedOption) => {
                              formProps.setFieldValue(
                                "time_open",
                                selectedOption.value
                              );
                            }}
                            options={timeOpenOptions}
                            name="time_open"
                            error={touched.time_open && errors.time_open}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={2} className={bem.e("col-reset")}>
                        <Label className={bem.e("to-title")}>
                          <FormattedMessage id="location > to" />
                        </Label>
                      </Col>
                      <Col xs={5} className={bem.e("col-reset")}>
                        <FormGroup>
                          <Dropdown
                            value={
                              values.time_close
                                ? {
                                    value: values.time_close,
                                    label: `${values.time_close} PM`,
                                  }
                                : ""
                            }
                            placeholder={intl.formatMessage({
                              id: "location > select",
                            })}
                            onChange={(selectedOption) => {
                              formProps.setFieldValue(
                                "time_close",
                                selectedOption.value
                              );
                            }}
                            options={timeCloseOptions}
                            name="time_close"
                            error={touched.time_close && errors.time_open}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col className={bem.e("col-reset")} xs={6}>
                    <FormGroup>
                      <DaysOfWeek
                        value={values.office_days}
                        label={<FormattedMessage id="location > office days" />}
                        onChange={(days) =>
                          formProps.setFieldValue("office_days", days)
                        }
                      />
                      <div className="text-danger">
                        {touched.office_days && errors.office_days}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <div className={bem.e("buttons")}>
                <Button
                  color="blue"
                  type="submit"
                  className={classnames("btn btn-radius", bem.e("btn-cancel"))}
                >
                  <FormattedMessage id={`location > button submit`} />
                </Button>
                <Button
                  color="blue"
                  outline
                  className={classnames("btn btn-radius", bem.e("btn-cancel"))}
                  onClick={onCancel}
                >
                  <FormattedMessage id={`location > button cancel`} />
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
      <Spinner isLoading={props.isLoading} />
    </>
  );
}

export default injectIntl(LocationForm);
