import { connect } from "react-redux";
import ProcessesList from "./view";
import { compose, lifecycle } from "recompose";
import { updateAssignmentProcess } from "store/actions/processes";
import withHandlers from "recompose/withHandlers";
import { get } from "lodash";
import { validatePrivileges } from "utils/helpers";
import { PRIVILEGES } from "utils/constants";
import history from "components/History";

const Enhanced = compose(
  connect(
    (state, props) => {
      return {
        ...props,
        auth: state.auth,
        assignmentProcessLoading: get(
          state,
          "processes.assignmentProcess.loading",
          false
        ),
      };
    },
    {
      updateAssignmentProcess,
    }
  ),
  withHandlers({
    onUpdateAssignment: ({ updateAssignmentProcess }) => (
      process,
      action = "take"
    ) => {
      updateAssignmentProcess({
        data: {
          process_id: process._id,
          action,
        },
        cb: () => {},
      });
    },
  }),
  lifecycle({
    componentDidMount() {
      const { auth } = this.props;
      const { production_multimarket } = get(auth, "info", {});
      const privileges = get(auth, "user.privileges", []) || [];
      const user = get(auth, "user");
      const isShowContinuityAssignments = validatePrivileges({
        requires: {
          or: [
            PRIVILEGES.PERFORM_PRODUCTION_CONTINUITY,
            PRIVILEGES.EDIT_ALL_PRODUCTION_ORDERS,
          ],
        },
        privileges,
        user,
      });
      if (!isShowContinuityAssignments || !production_multimarket)
        history.push("/");
    },
  })
)(ProcessesList);
export default Enhanced;
