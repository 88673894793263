import { compose, lifecycle, withHandlers, withState } from "recompose";
import { connect } from "react-redux";
import View from "./view";
import { injectIntl } from "react-intl";
import { filter, find, findIndex, forEach, get, orderBy, random } from "lodash";
import { colorPickerColors } from "utils/constants";
import {
  getJockConsoleWidgets,
  switchActive,
  updateWidgetsOrder,
  addWidgetLocal,
  setJockConsoleWidgets,
} from "store/actions/jocks";
import ToastManager from "components/ToastManager";

export default injectIntl(
  compose(
    connect(
      ({ jocks, auth, servers }) => {
        return {
          user: auth.user,
          stations: get(auth, "info.stations", []),
          widgets: get(jocks, "widgets.data.items", []),
          isLoading:
            get(jocks, "widgets.loading", false) ||
            get(jocks, "updateWidget.loading", false) ||
            get(jocks, "addWidget.loading", false) ||
            get(jocks, "activeWidget.loading", false),
          isOrdering: get(jocks, "orderWidget.loading", false),
          isFetchWidgets: get(jocks, "widgets.is_dirty", false),
          dateFormatByServer: get(
            servers,
            "currentServer.data.date_format",
            "MM/DD/YYYY"
          ),
        };
      },
      {
        getJockConsoleWidgets,
        switchActive,
        updateWidgetsOrder,
        addWidgetLocal,
        setJockConsoleWidgets,
      }
    ),
    withState(
      "currentActiveConfirmation",
      "setCurrentActiveConfirmation",
      null
    ),
    withState("currentStation", "setCurrentStation", null),
    withState("station", "setStation", null),
    withState("isOpenDetails", "setIsOpenDetails", null),
    withHandlers({
      rearrangingWidgets: ({ widgets, updateWidgetsOrder }) => () => {
        /*
          1. When a replacement bulletin board is created, the original bulletin board displays “Baseline”. Bulletin Board can not be deleted, it can ONLY have 1 board placed over the top of it (replacement board).
          2. New activated widget shows up at the end of the activated widgets. If it isn’t activated yet, it would show up at the end of the whole list
        */

        const widgetsOrderedByEnabled = orderBy(
          widgets,
          ["is_enabled"],
          ["desc"]
        ).map((item, index) => ({
          ...item,
          order: index + 1,
        }));
        const replacementWidgets = orderBy(
          filter(
            widgetsOrderedByEnabled,
            (item) =>
              item.type === "bulletin_board_replacement" && item.is_enabled
          ),
          ["fields.replacement.start_date"],
          ["asc"]
        );
        // pick widgets without replacement
        const widgetsWithoutReplacement = filter(
          widgetsOrderedByEnabled,
          (item) => !find(replacementWidgets, (i) => i.id === item.id)
        );
        // get position of base bulletin board
        const baselineBulletinBoardIdex = findIndex(
          widgetsWithoutReplacement,
          (i) => i.type === "bulletin_board"
        );
        let widgetsOrdered = [];
        // only sort by replacement when baseline is enabled
        if (
          get(
            widgetsWithoutReplacement,
            `${baselineBulletinBoardIdex}.is_enabled`,
            false
          )
        ) {
          forEach(widgetsWithoutReplacement, (item, index) => {
            if (index === baselineBulletinBoardIdex) {
              // push the widgets replacement at the bottom of baseline
              widgetsOrdered = [...widgetsOrdered, ...replacementWidgets, item];
            } else {
              widgetsOrdered = [...widgetsOrdered, item];
            }
          });
        } else {
          widgetsOrdered = widgetsOrderedByEnabled;
        }
        updateWidgetsOrder(widgetsOrdered);
      },
    }),
    withHandlers({
      onSwitchActive: ({
        switchActive,
        setCurrentActiveConfirmation,
        setIsOpenDetails,
        rearrangingWidgets,
      }) => (item, is_enabled) => {
        switchActive({ ...item, is_enabled }, () => {
          setCurrentActiveConfirmation(null);
          setIsOpenDetails(null);
          rearrangingWidgets();
        });
      },
      onUpdateOrder: ({ updateWidgetsOrder }) => (widgets) => {
        updateWidgetsOrder(widgets);
      },
      onAddNew: ({
        addWidgetLocal,
        setIsOpenDetails,
        widgets,
        currentStation,
        intl,
      }) => () => {
        if (!currentStation) {
          ToastManager.show({
            message: intl.formatMessage({
              id: "jock console manager > select station",
            }),
            level: "error",
          });
          return true;
        }

        const addingNew = find(
          widgets,
          (item) => item.id.indexOf("_new") === 0
        );
        if (addingNew) {
          setIsOpenDetails(addingNew.id);
          return;
        }
        const newId = "_new_" + +new Date();
        setIsOpenDetails(newId);
        addWidgetLocal({
          name: "",
          id: newId,
          color: colorPickerColors[random(0, colorPickerColors.length - 1)],
        });
      },
    }),
    lifecycle({
      componentDidMount() {
        this.props.setJockConsoleWidgets({});
      },
      componentDidUpdate() {
        if (this.props.isFetchWidgets) {
          const { currentStation } = this.props;
          this.props.getJockConsoleWidgets({
            station_id: currentStation.key,
          });
        }
      },
    })
  )(View)
);
