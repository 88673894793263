import React, { useRef, useEffect, useMemo } from "react";
import { Button, Modal, ModalBody, Row, Col } from "reactstrap";
import bn from "utils/bemnames";
import { filter, get, map } from "lodash";
import { FormattedMessage } from "react-intl";
import Slider from "react-slick";
import Checkbox from "components/Checkbox";
import ToggleSwitch from "components/ToggleSwitch";
import Spinner from "components/Spinner";

const bem = bn.create("copy-order-modal");
const mediaSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  swipe: false,
  draggable: false,
  adaptiveHeight: true,
};
const fieldsByTemplate = {
  basic_production: [
    {
      key: "entities",
      label: <FormattedMessage id="process > field entities" />,
    },
    {
      key: "order_title",
      label: <FormattedMessage id="process > field title" />,
    },
    {
      key: "order_length",
      label: <FormattedMessage id="process > field length" />,
    },
    {
      key: "approvals",
      label: <FormattedMessage id="process > field approvals" />,
    },
    {
      key: "assets",
      label: <FormattedMessage id="process > field assets" />,
    },
    {
      key: "copy_points",
      label: <FormattedMessage id="process > field copy points" />,
    },

    {
      key: "script",
      label: <FormattedMessage id="process > field script" />,
    },
    {
      key: "voice_over",
      label: <FormattedMessage id="process > field voice overs" />,
    },
  ],
  production_provided: [
    {
      key: "entities",
      label: <FormattedMessage id="process > field entities" />,
    },
    {
      key: "order_title",
      label: <FormattedMessage id="process > field title" />,
    },
    {
      key: "order_length",
      label: <FormattedMessage id="process > field length" />,
    },
    {
      key: "approvals",
      label: <FormattedMessage id="process > field approvals" />,
    },
    {
      key: "assets",
      label: <FormattedMessage id="process > field assets" />,
    },
    {
      key: "recording",
      label: <FormattedMessage id="process > field recording" />,
    },
  ],
};
function CopyOrderModal(props) {
  const {
    process,
    intl,
    values,
    setValues,
    currentSlide,
    setCurrentSlide,
    loading,
  } = props;
  const mainSlick = useRef(null);
  const allSpots = get(process, "data.fields.spot_info.value", []);
  const isHasOneSpot = allSpots && allSpots.length === 1;

  useEffect(() => {
    const isHasOne = allSpots && allSpots.length === 1;
      var spotIndex = 0;
      const initialValues = map(allSpots, (spot) => {
        spotIndex++;
        const spotIdentifyer = spot.uuid ? spot.uuid : 'spot_' + spotIndex;
        return {
          uuid: spotIdentifyer,
          selected: isHasOne, // selected as default if has only one spot
          template_key: spot.template_key,
          key: spot.key,
          title: spot.title,
          fields: {
            basic_production: fieldsByTemplate.basic_production.map(
              (field) => field.key
            ),
            production_provided: fieldsByTemplate.production_provided.map(
              (field) => field.key
            ),
          },
        }
      }
    );
    setValues(initialValues);
  }, [allSpots]);
  const onSelectSpot = (checked, spotIndex) => {
    const newValues = values.map((item, i) => {
      if (i === spotIndex) {
        return {
          ...item,
          selected: checked,
        };
      }
      return item;
    });
    setValues(newValues);
  };
  const onChangeSpotTemplate = (checked, uuid) => {
    const newValues = values.map((item) => {
      if (item.uuid === uuid) {
        return {
          ...item,
          template_key: checked ? "production_provided" : "basic_production",
        };
      }
      return item;
    });
    setValues(newValues);
  };
  const disabled = useMemo(() => {
    let disabled = false;
    switch (currentSlide) {
      case 0:
        disabled = values.findIndex((spot) => spot.selected) === -1;
        break;
      default:
    }
    return disabled;
  }, [values, currentSlide]);
  const isEndSlide =
    isHasOneSpot ||
    currentSlide === filter(values, (spot) => spot.selected).length + 1;
  const onClickCancel = () => {
    if (currentSlide === 0) {
      props.onToggle();
    } else {
      mainSlick.current.slickPrev();
    }
  };
  const onClickNext = () => {
    if (isEndSlide) {
      props.onSubmit(get(process, "key"));
    } else {
      mainSlick.current.slickNext();
    }
  };

  const onSelectField = ({ fieldKey, templateKey, uuid, checked }) => {
    const newValues = values.map((item, i) => {
      if (item.uuid === uuid) {
        return {
          ...item,
          fields: {
            ...item.fields,
            [templateKey]: checked
              ? [...item.fields[templateKey], fieldKey]
              : item.fields[templateKey].filter((field) => field !== fieldKey),
          },
        };
      }
      return item;
    });
    setValues(newValues);
  };

  return (
    <Modal isOpen={props.isOpen} className={bem.b()} centered={props.centered}>
      <ModalBody className={bem.e("body")}>
        <div className={bem.e("content")}>
          <Slider
            ref={mainSlick}
            {...mediaSettings}
            afterChange={(currentSlide) => setCurrentSlide(currentSlide)}
          >
            {!isHasOneSpot && (
              <div className={bem.e("slide-spots")}>
                <h2 className={bem.e("title")}>
                  <FormattedMessage id="process > copy order modal title" />
                </h2>
                <div className={bem.e("checkbox-spots")}>
                  {map(values, (spot, index) => (
                    <div
                      key={`spot-${index}`}
                      className={bem.e("checkbox-spot")}
                    >
                      <span className={bem.e("spot-number")}>
                        <FormattedMessage id="process > spot" /> {index + 1}
                      </span>
                      <Checkbox
                        checked={spot.selected}
                        onChange={(checked) => onSelectSpot(checked, index)}
                        text={spot.title}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}

            {filter(values, (spot) => spot.selected).map((spot, index) => {
              return (
                <div className={bem.e("slide-spot")} key={spot.uuid}>
                  <div className={bem.e("select-spot-header")}>
                    <span className={bem.e("spot-number")}>
                      <FormattedMessage id="process > spot" /> {index + 1}
                    </span>
                    <h2 className={bem.e("spot-title")}>{spot.title}</h2>
                  </div>
                  <div className={bem.e("spot-switch-template")}>
                    <ToggleSwitch
                      leftComponent={intl.formatMessage({
                        id: "process > production needed",
                      })}
                      rightComponent={intl.formatMessage({
                        id: "process > production provided",
                      })}
                      name="template_key"
                      switchProps={{
                        checked: spot.template_key !== "basic_production",
                        onChange: (checked) => {
                          onChangeSpotTemplate(checked, spot.uuid);
                        },
                        disabled: false,
                        offColor: "#C2D4E0",
                        onColor: "#795AFA",
                        uncheckedIcon: false,
                        checkedIcon: false,
                        boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.282)",
                        height: 26.44,
                        width: 48.48,
                        handleDiameter: 22.92,
                      }}
                    />
                  </div>
                  <Row>
                    <Col xs={6}>
                      <span className={bem.e("copy-to-spot-text")}>
                        <FormattedMessage id="process > copy to new spot" />
                      </span>
                      <div className={bem.e("field-checkboxes")}>
                        {fieldsByTemplate.basic_production.map(
                          (field, index) => (
                            <Checkbox
                              checked={
                                spot.fields.basic_production.findIndex(
                                  (item) => item === field.key
                                ) !== -1
                              }
                              key={`checkbox-basic-${index}`}
                              disabled={
                                spot.template_key !== "basic_production"
                              }
                              onChange={(checked) => {
                                onSelectField({
                                  fieldKey: field.key,
                                  templateKey: "basic_production",
                                  uuid: spot.uuid,
                                  checked,
                                });
                              }}
                              text={field.label}
                            />
                          )
                        )}
                      </div>
                    </Col>
                    <Col xs={6}>
                      <span className={bem.e("copy-to-spot-text")}>
                        <FormattedMessage id="process > copy to new spot" />
                      </span>
                      <div className={bem.e("field-checkboxes")}>
                        {fieldsByTemplate.production_provided.map(
                          (field, index) => (
                            <Checkbox
                              checked={
                                spot.fields.production_provided.findIndex(
                                  (item) => item === field.key
                                ) !== -1
                              }
                              key={`checkbox-provided-${index}`}
                              disabled={
                                spot.template_key !== "production_provided"
                              }
                              onChange={(checked) => {
                                onSelectField({
                                  fieldKey: field.key,
                                  templateKey: "production_provided",
                                  uuid: spot.uuid,
                                  checked,
                                });
                              }}
                              text={field.label}
                            />
                          )
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              );
            })}
            {!isHasOneSpot && (
              <div className={bem.e("summary-spots")}>
                <h2 className={bem.e("title")}>
                  <FormattedMessage id="process > the following spots are ready for your new order" />
                </h2>
                <div className={bem.e("checkbox-spots")}>
                  {filter(values, (spot) => spot.selected).map(
                    (spot, index) => (
                      <div
                        key={`spot-${index}`}
                        className={bem.e("checkbox-spot")}
                      >
                        <span className={bem.e("spot-number")}>
                          <FormattedMessage id="process > spot" /> {index + 1}
                        </span>
                        <Checkbox
                          checked={spot.selected}
                          onChange={(checked) => onSelectSpot(checked, index)}
                          text={spot.title}
                        />
                        <span className={bem.e("spot-template")}>
                          {spot.template_key === "basic_production"
                            ? intl.formatMessage({
                                id: "process > production needed",
                              })
                            : intl.formatMessage({
                                id: "process > production provided",
                              })}
                        </span>
                      </div>
                    )
                  )}
                </div>
              </div>
            )}
          </Slider>
          <div className={bem.e("buttons")}>
            <Button
              color="blue"
              className="btn-radius"
              disabled={disabled}
              onClick={onClickNext}
            >
              {isEndSlide ? (
                <FormattedMessage id="process > button create order" />
              ) : (
                <FormattedMessage id="process > button next" />
              )}
            </Button>
            <Button
              color="blue"
              outline
              className="btn-radius"
              onClick={onClickCancel}
            >
              {currentSlide === 0 ? (
                <FormattedMessage id="process > button cancel" />
              ) : (
                <FormattedMessage id="process > button back" />
              )}
            </Button>
          </div>
        </div>
        <Spinner isLoading={loading} />
      </ModalBody>
    </Modal>
  );
}

CopyOrderModal.defaultProps = {
  isOpen: false,
  centered: true,
  onToggle: () => {},
  allSpots: [],
};

export default CopyOrderModal;
