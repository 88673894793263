import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody } from "reactstrap";
import bn from "utils/bemnames";
import { ArrowRightIcon } from "components/CustomIcons";
import BulletinBoard from "../Common/BulletinBoard";
import TrendingHashtags from "../Common/TrendingHashtags";
import SocialMedia from "../Common/SocialMedia";
import OtherLinks from "../Common/OtherLinks";
import TextEditor from "../Common/TextEditor";
import News from "../Common/News";
import PhoneNumbers from "../Common/PhoneNumbers";
import EventCalendar from "../Common/EventCalendar";
import RssFeed from "../Common/RssFeed";
import StationImage from "../Common/StationImage";
import StationTime from "../Common/StationTime";
import StormClosings from "../Common/StormClosings";
import { map } from "lodash";
const bem = bn.create("jock-console-multi-view-modal");
function MultiViewModal(props) {
  const { onToggle, isOpen, widgets, station } = props;
  const getContent = (widget) => {
    if (!widget) return null;
    switch (widget.type) {
      case "news":
        return <News widget={widget} isMultiView={true} />;
      case "trending_hashtags":
        return <TrendingHashtags widget={widget} isMultiView={true} />;
      case "social_media":
        return <SocialMedia widget={widget} isMultiView={true} />;
      case "phone_numbers":
        return <PhoneNumbers widget={widget} isMultiView={true} />;
      case "calendar":
      case "event_calendar":
        return <EventCalendar widget={widget} isMultiView={true} />;
      case "other_links":
        return <OtherLinks widget={widget} isMultiView={true} />;
      case "text_editor":
        return <TextEditor widget={widget} isMultiView={true} />;
      case "bulletin_board":
      case "bulletin_board_replacement":
        return <BulletinBoard widget={widget} isMultiView={true} />;
      case "rss_feed":
        return <RssFeed widget={widget} isMultiView={true} />;
      case "storm_closings":
        return <StormClosings widget={widget} isMultiView={true} />;
      default:
        return null;
    }
  };
  return (
    <Modal isOpen={isOpen} toggle={onToggle} className={bem.b()}>
      <ModalBody className={bem.e("body")}>
        <div className={bem.e("multiple-head")}>
          <span className={bem.e("btn-close")} onClick={() => props.onCancel()}>
            <ArrowRightIcon color="#C2D4E0" />
          </span>
          <div className={bem.e("station-center")}>
            <div className={bem.e("station-info")}>
              <strong>{station.name}</strong>
              <StationTime station={station} />
            </div>
            <StationImage station={station} />

            <div className={bem.e("station-info")}>
              <strong>{station.call_letters}</strong>
              <div className={bem.e("text-small")}>{station.name}</div>
            </div>
          </div>
        </div>
        <div className={bem.e("widgets")}>
          {map(widgets, (widget) => (
            <div key={widget.id} className={bem.e("widget")}>
              {getContent(widget)}
            </div>
          ))}
        </div>
      </ModalBody>
    </Modal>
  );
}
MultiViewModal.defaultProps = {
  isOpen: false,
  onToggle: () => {},
  onCancel: () => {},
  widgets: [],
};
MultiViewModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  widgets: PropTypes.array,
};
export default MultiViewModal;
