import Editor from "./editorView";
import get from "lodash/get";
import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";
import { getProcess } from "store/actions/processes";
import {
  getTemplate,
  updateCurrentTemplate,
  saveTemplate
} from "store/actions/template";

export default compose(
  connect(
    (state, props) => {
      const { match } = props;
      const proc_id = get(match, "params.proc_id");
      return {
        isUpdate: proc_id ? true : false,
        process: state.processes.process,
        template: state.templates.template.data,
        isLoading:
          state.templates.template.loading || state.processes.process.loading
      };
    },
    {
      getProcess,
      getTemplate,
      updateCurrentTemplate,
      saveTemplate
    }
  ),
  lifecycle({
    componentDidMount() {
      const { match } = this.props;
      const process_id = get(match, "params.process_id");
      const template_id = get(match, "params.template_id");
      this.props.getTemplate(template_id);
      this.props.getProcess({ template_id, process_id });
    }
  })
)(Editor);
