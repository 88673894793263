import View from "./view";
import { connect } from "react-redux";
import axios from "axios";
import { compose, withHandlers, withState } from "recompose";
import { uploadFile, deleteFile } from "store/actions/uploads";
import {
  download,
  convertUrlHttps
} from "components/UploadFile/helper";

export default compose(
  withState("isLoading", "setIsLoading", false),
  withState("isRename", "setIsRename", false),
  withState("error", "setError", null),
  withState("isOpenPreviewFileModal", "setIsOpenPreviewFileModal", false),
  connect(state => state, {
    uploadFile,
    deleteFile
  }),
  withHandlers({
    onCancelPreviewModal: ({ setIsOpenPreviewFileModal }) => () => {
      setIsOpenPreviewFileModal(false);
    },
    onTogglePreviewModal: ({
      isOpenPreviewFileModal,
      setIsOpenPreviewFileModal
    }) => () => {
      setIsOpenPreviewFileModal(!isOpenPreviewFileModal);
    },
    onViewFile: ({ setIsOpenPreviewFileModal }) => () => {
      setIsOpenPreviewFileModal(true);
    },
    onRenameFile: ({
      setIsOpenPreviewFileModal,
      setIsRename,
    }) => () => {
      setIsOpenPreviewFileModal(true);
      setIsRename(true);
    },
    onDeleteFile: ({
      deleteFile,
      setIsLoading,
      setError,
      file,
      onDeleted
    }) => async () => {
      setIsLoading(true);
      const response = await deleteFile(file);
      if (response) {
        onDeleted(file);
      } else {
        setError(`Can't delete`);
      }
      setIsLoading(false);
    },
    onDownloadFile: ({ setError, file }) => async () => {
      const { path } = file;
      axios({
        url: convertUrlHttps(path) + '?ts=' + (new Date()-0),
        method: "GET",
        responseType: "blob"
      })
        .then(response => {
          const { data } = response;
          if (data) {
            const fileName = path.replace(/^.*[\\/]/, "");
            download(data, fileName);
          } else {
            setError(`Can't download`);
          }
        })
        .catch(e => {
          const { message } = e;
          setError(message);
        });
    }
  })
)(View);
