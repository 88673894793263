import React from "react";
import PropTypes from "utils/propTypes";
import classNames from "classnames";
import Avatar from "components/Avatar";
import AdminBackgroundImage from "assets/img/profile_background.png";
import bn from "utils/bemnames";
const bem = bn.create("user-card");

function UserCard(props) {
  const { className, avatarSize, avatar, title, subtitle, children, isShowBackground, isShowTitle, isShowSubtitle } = props;
  const classes = classNames(className, bem.b());
  return (
    <div className={classes}>
      {
        isShowBackground ? (
          <div
            className={bem.e("user-avatar-background")}
            style={{
            backgroundImage: `url(${AdminBackgroundImage})`
          }}>
            <div className={bem.e("user-avatar")}>
              <Avatar src={avatar} size={avatarSize} name={title} />
            </div>
          </div>
        ): (
          <div className={bem.e("user-avatar")}>
            <Avatar src={avatar} size={avatarSize} name={title} />
          </div>
        )
      }

      {isShowTitle && <div className={bem.e("title")}>{title}</div>}
      {isShowSubtitle && <div className={bem.e("subtitle")}>{subtitle}</div>}
      <div className={bem.e('children')}>{children}</div>
    </div>
  );
}
UserCard.propTypes = {
  avatar: PropTypes.string,
  avatarSize: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
  isShowBackground: PropTypes.bool,
  isShowTitle: PropTypes.bool,
  isShowSubtitle: PropTypes.bool
};

UserCard.defaultProps = {
  avatarSize: 80,
  isShowBackground: false,
  isShowTitle: true,
  isShowSubtitle: true
};

export default UserCard;
