import * as React from "react";
import { compose, withState, lifecycle, withHandlers } from "recompose";
import { connect } from "react-redux";
import { MdImportantDevices } from "react-icons/lib/md";
import { get, isEqual } from "lodash";
import ToastManager from "components/ToastManager";
import View from "./view";
import { injectIntl } from "react-intl";
import history from "components/History";
import {
  updateRole,
  deleteRole,
  getRoles,
  updateOrderRoles,
  toggleRoleActivate,
  cloneRole,
} from "store/actions/roles";

export default injectIntl(
  compose(
    connect(
      ({ auth, roles }) => {
        return {
          user: auth.user,
          roles: get(roles, "roles.data", []),
          authInfo: auth.info || {},
          isLoading:
            get(roles, "activateRole.loading", false) ||
            get(roles, "cloneRole.loading", false) ||
            get(roles, "createRole.loading", false) ||
            get(roles, "updateRole.loading", false) ||
            get(roles, "deleteRole.loading", false),
          sidebarActive: auth.sidebar_active,
        };
      },
      {
        updateRole,
        deleteRole,
        getRoles,
        updateOrderRoles,
        toggleRoleActivate,
        cloneRole,
      }
    ),
    withState("currentOpenForm", "setCurrentOpenForm", null),
    withState("shouldBlockNavigation", "setShouldBlockNavigation", false),
    withState("currentFilter", "setCurrentFilter", {
      group: "production",
      market: "",
      search: "",
    }),
    withState("activeTab", "setActiveTab", "production"),
    withState(
      "isOpenDeactivateConfirmationModal",
      "setIsOpenDeactivateConfirmationModal",
      false
    ),
    withState("successConfirmationModal", "setSuccessConfirmationModal", {
      isOpen: false,
      message: "",
    }),
    withState("isOpenConfirmationModal", "setIsOpenConfirmationModal", false),
    withState(
      "isOpenConfirmationUnsavedModal",
      "setIsOpenConfirmationUnsavedModal",
      false
    ),
    withHandlers({
      onGetRoles: ({ getRoles, currentFilter }) => (params = {}) => {
        getRoles({
          name: currentFilter.search,
          market_id: currentFilter.market ? currentFilter.market.value : "",
          group: currentFilter.group ? currentFilter.group : "",
          ...params,
        });
      },
    }),
    withHandlers({
      toggleTab: ({
        activeTab,
        setActiveTab,
        currentFilter,
        setCurrentFilter,
        onGetRoles,
      }) => (tab) => {
        if (activeTab !== tab) {
          setActiveTab(tab);
          setCurrentFilter({ ...currentFilter, group: tab }, () =>
            onGetRoles()
          );
        }
      },
    }),
    withHandlers({
      onUpdateOrderRoles: ({ updateOrderRoles }) => (items) => {
        updateOrderRoles(items);
      },
      onFormSubmit: ({
        updateRole,
        setShouldBlockNavigation,
        intl,
        onGetRoles,
        sidebarActive,
      }) => (values) => {
        updateRole(values.id, values, (status) => {
          if (status) {
            ToastManager.show({
              title: <MdImportantDevices />,
              message: intl.formatMessage({
                id: "role > updated successfully",
              }),
              level: "success",
            });
            setShouldBlockNavigation(false);
            onGetRoles();
            if (sidebarActive !== "admin/unknown") {
              history.push(sidebarActive);
            }
          }
        });
      },
      onDelete: ({
        deleteRole,
        setShouldBlockNavigation,
        setCurrentOpenForm,
        currentOpenForm,
        setIsOpenConfirmationModal,
        intl,
        onGetRoles,
      }) => () => {
        setIsOpenConfirmationModal(false);
        deleteRole(currentOpenForm, () => {
          setCurrentOpenForm(null);
          setShouldBlockNavigation(false);
          onGetRoles();
          ToastManager.show({
            title: <MdImportantDevices />,
            message: intl.formatMessage({
              id: "role > deleted successfully",
            }),
            level: "success",
          });
        });
      },
    }),
    withHandlers({
      onSaveHiddenRole: ({
        setSuccessConfirmationModal,
        intl,
        toggleRoleActivate,
        onGetRoles,
      }) => (role, checked) => {
        toggleRoleActivate(
          {
            id: role.id,
            active: checked,
          },
          () => {
            onGetRoles();
            setSuccessConfirmationModal({
              isOpen: true,
              message: checked
                ? intl.formatMessage({ id: "role > role activated" })
                : intl.formatMessage({ id: "role > role deactivated" }),
            });
          }
        );
      },
    }),
    withHandlers({
      onCloneRole: ({ cloneRole, onGetRoles, currentFilter }) => (role) => {
        cloneRole(
          {
            id: role.id,
            market_id: currentFilter.market ? currentFilter.market.value : "",
          },
          () => {
            onGetRoles();
          }
        );
      },
      onToggleActivate: ({
        setIsOpenDeactivateConfirmationModal,
        onSaveHiddenRole,
      }) => (role, checked) => {
        if (!checked && role.users.length > 0) {
          setIsOpenDeactivateConfirmationModal(true);
        } else {
          onSaveHiddenRole(role, checked);
        }
      },
      onSearchKeyDown: ({ onGetRoles }) => (e) => {
        if (e.keyCode === 13) {
          onGetRoles();
        }
      },
      onSearchClicked: ({ onGetRoles }) => () => {
        onGetRoles();
      },
    }),
    lifecycle({
      componentDidMount() {
        this.props.onGetRoles();
      },
      componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.authInfo, this.props.authInfo)) {
          this.props.onGetRoles();
        }
      },
    })
  )(View)
);
