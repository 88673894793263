import React from "react";
import { connect } from "react-redux";
import { compose, lifecycle, withHandlers } from "recompose";
import { withRouter } from "react-router-dom";
import { get } from "lodash";
import WinnersList from "./view";
import { injectIntl } from "react-intl";
import {
  getZettaSettings,
  updateZettaSettings,
  toggleZettaEnable,
  deleteZettaSettings,
} from "store/actions/zetta";
import { MdImportantDevices } from "react-icons/lib/md";
import ToastManager from "components/ToastManager";

const Enhanced = compose(
  connect(
    (state, props) => {
      return {
        isLoading:
          get(state, "zetta.items.loading", false) ||
          get(state, "zetta.updateSettings.loading", false) ||
          get(state, "zetta.toggleEnable.loading", false) ||
          get(state, "zetta.deleteSettings.loading", false),
        items: get(state, "zetta.items.data", []),
        isZettaDubbingEnabled: get(
          state,
          "auth.info.enable_zetta_dubbing",
          false
        ),
        ...props,
      };
    },
    {
      getZettaSettings,
      updateZettaSettings,
      toggleZettaEnable,
      deleteZettaSettings,
    }
  ),
  withHandlers({
    onToggle: ({ toggleZettaEnable }) => (value) => {
      toggleZettaEnable({
        enable_zetta_dubbing: value,
      });
    },
    onSubmit: ({ updateZettaSettings }) => (values, cb) => {
      updateZettaSettings(values, cb);
    },
    onDelete: ({ deleteZettaSettings, intl }) => (stationId) => {
      console.log("stationId", stationId);
      deleteZettaSettings(
        {
          station_id: stationId,
        },
        () => {
          ToastManager.show({
            title: <MdImportantDevices />,
            message: intl.formatMessage({
              id: "toast > title deleted",
            }),
            level: "success",
          });
        }
      );
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.getZettaSettings();
    },
  })
)(WinnersList);
export default withRouter(injectIntl(Enhanced));
