import React, { useState } from "react";
import View from "./view";
import { injectIntl } from "react-intl";
function EntityAssignments(props) {
  /*const [isOpenAssignEntitiesModal, setIsOpenAssignEntitiesModal] = useState(
    false
  );*/
  const [
    isOpenManagePrivilegesModal,
    setIsOpenManagePrivilegesModal,
  ] = useState(false);
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);

  const [stationsConflicts, setStationsConflicts] = useState(null);
  const [
    isOpenConfirmationApplyToAllModal,
    setIsOpenConfirmationApplyToAllModal,
  ] = useState(false);
  const [currentEntity, setCurrentEntity] = useState(null);
  const [validPrivileges, setValidPrivileges] = useState([]);
  const [currentRole, setCurrentRole] = useState(null);
  return (
    <View
      {...props}
      {...Object.assign(
        {},
        {
          validPrivileges,
          setValidPrivileges,
          /*isOpenAssignEntitiesModal,
          setIsOpenAssignEntitiesModal,*/
          isOpenManagePrivilegesModal,
          setIsOpenManagePrivilegesModal,
          isOpenConfirmationModal,
          setIsOpenConfirmationModal,
          currentEntity,
          setCurrentEntity,
          isOpenConfirmationApplyToAllModal,
          setIsOpenConfirmationApplyToAllModal,
          currentRole,
          setCurrentRole,
          stationsConflicts,
          setStationsConflicts,
        }
      )}
    />
  );
}
export default injectIntl(EntityAssignments);
