import { connect } from "react-redux";
import View from "./view";
import { injectIntl } from "react-intl";
import { compose } from "recompose";
import { updateFinalScript } from "store/actions/processes";
import get from "lodash/get";

const Enhanced = compose(
  connect(
    (state) => ({
      isLoading: get(state, "processes.updateFinalScript.loading", false),
    }),
    {
      updateFinalScript,
    }
  )
)(View);
export default injectIntl(Enhanced);
