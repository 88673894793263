import ScriptHistoryModal from "./view";
import { get } from "lodash";
import { connect } from "react-redux";
const Enhanced = connect(
  (state) => ({
    dateFormatByServer: get(
      state,
      "servers.currentServer.data.date_format",
      "MM/DD/YYYY"
    ),
  }),
  {}
)(ScriptHistoryModal);

export default Enhanced;
