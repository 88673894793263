import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { CloseIcon } from "components/CustomIcons";
import { FormattedMessage } from "react-intl";
import * as Yup from "yup";
import { Row, Col, FormGroup, Form, Button } from "reactstrap";
import { Formik } from "formik";
import TextInput from "components/TextInput";
import Dropdown from "components/Dropdown";
import { get } from "lodash";
import ToastManager from "components/ToastManager";
import { injectIntl } from "react-intl";
import bn from "utils/bemnames";
import DropdownColor from "components/DropdownColor";
import { colors } from "utils/config";

const bem = bn.create("insert-button-modal");

const InsertButtonModal = ({
  onToggle,
  isOpen,
  onSubmit,
  intl,
  currentInsertButton,
}) => {
  const formikRef = React.useRef(null);
  const initialValues = {
    id: get(currentInsertButton, "id", ""),
    link_to_form: get(currentInsertButton, "link_to_form", ""),
    url: get(currentInsertButton, "url", ""),
    text: get(currentInsertButton, "text", ""),
    color:
      currentInsertButton && currentInsertButton.color
        ? currentInsertButton.color
        : colors[0].value,
  };
  const linkToFormOptions = [
    {
      label: intl.formatMessage({
        id: "textarea evaluator > fulfillment form",
      }),
      value: "fulfillment_form",
    },
    {
      label: intl.formatMessage({
        id: "textarea evaluator > release form",
      }),
      value: "release_form",
    },
    {
      label: intl.formatMessage({
        id: "textarea evaluator > fulfillment and release forms",
      }),
      value: "fulfillment_and_release_forms",
    },
    {
      label: intl.formatMessage({
        id: "textarea evaluator > w-9",
      }),
      value: "w_9",
    },
  ];
  return (
    <Modal isOpen={isOpen} className={bem.b()}>
      <ModalHeader>
        <FormattedMessage id="textarea evaluator > insert a button" />
        <button className={bem.e("close")} onClick={onToggle}>
          <CloseIcon color={"#657894"} />
        </button>
      </ModalHeader>
      <ModalBody>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          ref={formikRef}
          validationSchema={Yup.object().shape({
            link_to_form: Yup.string().nullable(),
            url: Yup.string().when("link_to_form", {
              is: (value) => !value,
              then: Yup.string()
                .url(
                  intl.formatMessage({
                    id: "validate > url invalid",
                  })
                )
                .required(
                  intl.formatMessage({
                    id: "validate > url is required",
                  })
                ),
              otherwise: Yup.string().nullable(),
            }),
            text: Yup.string().required(
              intl.formatMessage({
                id: "validate > text is required",
              })
            ),
            color: Yup.string().required(
              intl.formatMessage({
                id: "validate > color is required",
              })
            ),
          })}
          onSubmit={onSubmit}
        >
          {(formProps) => {
            const errors = formProps.errors;
            const touched = formProps.touched;
            const values = formProps.values;
            const isSubmitting = formProps.isSubmitting;
            const linkToForm = linkToFormOptions.find(
              (i) => i.value === values.link_to_form
            );
            return (
              <Form onSubmit={formProps.handleSubmit}>
                <Row>
                  <Col xs={12}>
                    <FormGroup>
                      <Dropdown
                        label={
                          <FormattedMessage id="textarea evaluator > link to form" />
                        }
                        value={linkToForm || ""}
                        placeholder={intl.formatMessage({
                          id: "textarea evaluator > none",
                        })}
                        onChange={(selectedOption) => {
                          formProps.setFieldValue(
                            "link_to_form",
                            selectedOption ? selectedOption.value : ""
                          );
                        }}
                        isClearable
                        options={linkToFormOptions}
                        name="link_to_form"
                        error={touched.link_to_form && errors.link_to_form}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12}>
                    <FormGroup>
                      <TextInput
                        label={
                          <FormattedMessage id="textarea evaluator > button url" />
                        }
                        type="text"
                        name="url"
                        placeholder={intl.formatMessage({
                          id: "textarea evaluator > placeholder enter url",
                        })}
                        disabled={values.link_to_form}
                        value={values.url}
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                        error={touched.url && errors.url}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12}>
                    <FormGroup>
                      <TextInput
                        label={
                          <FormattedMessage id="textarea evaluator > button text" />
                        }
                        type="text"
                        name="text"
                        placeholder={intl.formatMessage({
                          id: "textarea evaluator > placeholder enter text",
                        })}
                        value={values.text}
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                        error={touched.text && errors.text}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12}>
                    <FormGroup>
                      <DropdownColor
                        label={
                          <FormattedMessage id="textarea evaluator > button color" />
                        }
                        name="color"
                        value={values.color || ""}
                        onChange={({ value }) => {
                          formProps.setFieldValue("color", value);
                        }}
                        onBlur={formProps.handleBlur}
                        error={touched.color && errors.color}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <div className={bem.e("form-buttons")}>
                  <Button
                    color="blue"
                    className="btn btn-radius"
                    disabled={isSubmitting}
                    onClick={() => {
                      formProps.handleSubmit();
                      setTimeout(() => {
                        const formErrors = get(
                          formikRef,
                          "current.state.errors"
                        );
                        if (formErrors && Object.keys(formErrors).length) {
                          ToastManager.show({
                            title: intl.formatMessage({
                              id: "toast > title not saved",
                            }),
                            message: intl.formatMessage({
                              id:
                                "toast > message error please correct the hilighted fields",
                            }),
                            level: "error",
                          });
                        }
                      }, 40);
                    }}
                  >
                    <FormattedMessage
                      id={
                        currentInsertButton && currentInsertButton.id
                          ? `textarea evaluator > edit button`
                          : `textarea evaluator > add button`
                      }
                    />
                  </Button>
                  <Button
                    color="blue"
                    type="button"
                    outline
                    className="btn btn-radius"
                    onClick={() => {
                      formProps.resetForm();
                      onToggle();
                    }}
                  >
                    <FormattedMessage
                      id={`textarea evaluator > button cancel`}
                    />
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

InsertButtonModal.defaultProps = {
  isOpen: false,
  onToggle: () => {},
  onSubmit: () => {},
};
InsertButtonModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
export default injectIntl(InsertButtonModal);
