import React, { useEffect } from "react";
import { Row, Col, Button, FormGroup, Label } from "reactstrap";
import bn from "utils/bemnames";
import {
  ShadowBox,
  SectionTitle,
  SectionFields,
  Asterisk,
} from "components/Elements";
import TextareaEvaluator from "components/TextareaEvaluator";
import mimeTypes from "components/MimeTypes";
import { EyeIcon } from "components/CustomIcons";
import PrivilegedComponent from "components/PrivilegedComponent";
import { PRIVILEGES } from "utils/constants";
import { FaPlus } from "react-icons/lib/fa";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import Dropdown from "components/Dropdown";
import TextInput from "components/TextInput";
import { get, find, omit, clone, map, findIndex, first } from "lodash";
import history from "components/History";
import { validatePrivileges, getFileName, setScriptField } from "utils/helpers";
import UploadFile from "components/UploadFile";
import FileView from "components/FileView";
import { winnerFulfillmentTags } from "utils/config";
import SortableComponent from "../SortableComponent";
import FieldsFormBuilderModal from "components/FormBuilder/FieldsFormBuilderModal";
import ConfirmationModal from "components/ConfirmationModal";
import PreviewModal from "components/SystemForms/PreviewModal";

import * as Yup from "yup";
import { yupToFormErrors } from "formik";
import ToastManager from "components/ToastManager";

const bem = bn.create("winner-fulfillment");
function generateField(data) {
  let newField = {
    ...omit(data, ["options"]),
    value: "",
    placeholder: "",
    is_new: true,
  };
  return newField;
}
function getSelectedSystemForm({
  selectedWinnerFulfillment,
  winnerFulfillmentFields,
}) {
  let systemForm = selectedWinnerFulfillment
    ? selectedWinnerFulfillment.data
    : null;
  if (selectedWinnerFulfillment) {
    systemForm = {
      ...systemForm,
      _source: {
        ...systemForm._source,
        fields: [...systemForm._source.fields, ...winnerFulfillmentFields],
      },
    };
  }
  return systemForm;
}
const WinnerFulfillment = (props) => {
  const {
    step,
    intl,
    process,
    validationState,
    onValueChanged,
    isView,
    template,
    user,
    station,
    systemForms,
    isOpenPreviewModal,
    setIsOpenPreviewModal,
    deletingField,
    setDeletingField,
    isOpenConfirmationDeleteModal,
    setIsOpenConfirmationDeleteModal,
    fieldRefs,
    setFieldRefs,
    errors,
    setErrors,
    touched,
    setTouched,
    isOpenFieldsFormBuilderModal,
    setIsOpenFieldsFormBuilderModal,
  } = props;
  const winnerFulfillmentOptions = map(systemForms, (item) => ({
    label: item._source.name,
    value: item._id,
    data: item,
  }));
  const stationLogoValue = get(
    process,
    "data.fields.station_logo_url.value",
    ""
  );
  const winnerFulfillmentNameValue = get(
    process,
    "data.fields.winner_fulfillment_name.value",
    ""
  );
  const introText = get(
    process,
    "data.fields.winner_fulfillment_intro_text.value",
    ""
  );
  const logo = stationLogoValue
    ? {
        path: stationLogoValue,
        name: getFileName(stationLogoValue),
        type: mimeTypes.lookup(stationLogoValue),
      }
    : "";

  useEffect(() => {
    if (!stationLogoValue) {
      onValueChanged(station_logo_url, station.logo, {
        shouldBlockNavigation: false,
      });
    }
    if (!winnerFulfillmentNameValue) {
      onValueChanged(
        winner_fulfillment_name,
        get(process, "data.fields.order_title.value", ""),
        {
          shouldBlockNavigation: false,
        }
      );
    }
  }, []);
  const privileges = get(user, "info.privileges", []);
  const isAllowSave = validatePrivileges({
    requires: [PRIVILEGES.CONTEST_SAVE],
    privileges,
    user,
  });
  const winnerFulfillmentFields = get(
    process,
    "data.fields.winner_fulfillment_fields.value",
    []
  );
  const getFieldRefs = () => {
    if (fieldRefs) return fieldRefs;
    const winner_fulfillment_name = step.fields.find(
      (f) => f.field === "winner_fulfillment_name"
    );
    const winner_fulfillment_form = step.fields.find(
      (f) => f.field === "winner_fulfillment_form"
    );
    const winner_fulfillment_intro_text = step.fields.find(
      (f) => f.field === "winner_fulfillment_intro_text"
    );
    const winner_fulfillment_fields = step.fields.find(
      (f) => f.field === "winner_fulfillment_fields"
    );
    const set_intro_text_as_default = step.fields.find(
      (f) => f.field === "set_intro_text_as_default"
    );
    const station_logo_url = step.fields.find(
      (f) => f.field === "station_logo_url"
    );
    const payload = {
      winner_fulfillment_name,
      winner_fulfillment_form,
      station_logo_url,
      winner_fulfillment_intro_text,
      winner_fulfillment_fields,
      set_intro_text_as_default,
    };

    setFieldRefs(payload);

    return payload;
  };
  const customLabelFields = map(
    winnerFulfillmentFields,
    (field) => field.label
  );

  const formSchema = Yup.object().shape({
    fields: Yup.array().of(
      Yup.object().shape({
        label: Yup.string()
          .required(intl.formatMessage({ id: "validate > label is required" }))
          .test({
            name: "duplicate_label",
            message: intl.formatMessage({
              id: "validate > label name already exists",
            }),
            test: function(value) {
              if (["helper_text"].indexOf(this.parent.key) !== -1) return true;
              let count = 0;
              customLabelFields.forEach((label) => {
                if (
                  value.trim() !== "" &&
                  label.trim().toLowerCase() === value.trim().toLowerCase()
                ) {
                  count++;
                }
              });
              return !(count > 1);
            },
          }),
      })
    ),
  });
  const validateCustomOption = async (values) => {
    setTouched(null);
    try {
      await formSchema.validate(values, { abortEarly: false });
      let errors;
      values.fields.forEach((field, index) => {
        const { key } = field;
        if (key === "dropdown" || "radio" || "checkbox") {
          const { options } = field;
          let indexError = findIndex(
            options,
            ({ label, value }) => !label || !value
          );
          if (indexError > -1) {
            let fieldsErrors = errors ? errors.fields : [];
            fieldsErrors[index] = {
              ...fieldsErrors[index],
              options: intl.formatMessage({
                id: "validate > option is required",
              }),
            };
            errors = {
              ...errors,
              fields: fieldsErrors,
            };
          }
        }
      });
      if (errors) {
        setErrors(errors);
        setTouched({
          fields: true,
        });
        return false;
      }
      return true;
    } catch (err) {
      const errors = yupToFormErrors(err);
      setErrors(errors);
      setTouched({
        fields: true,
      });
      return false;
    }
  };
  const onSubmit = async (event) => {
    const isValid = await validateCustomOption({
      fields: winnerFulfillmentFields,
    });
    if (!isValid) {
      ToastManager.show({
        title: intl.formatMessage({
          id: "toast > title not saved",
        }),
        message: intl.formatMessage({
          id: "toast > message error please correct the hilighted fields",
        }),
        level: "error",
      });
      return;
    }
    const item = step.fields.find((f) => f.key === "save_button");
    props.buttonClicked(
      step,
      isAllowSave
        ? item
        : {
            ...item,
            draft: true,
          },
      process,
      template,
      user,
      event,
      null,
      true
    );
  };
  const {
    winner_fulfillment_name,
    winner_fulfillment_form,
    station_logo_url,
    winner_fulfillment_intro_text,
    winner_fulfillment_fields,
    set_intro_text_as_default,
  } = getFieldRefs();
  const selectedWinnerFulfillmentValue = get(
    process,
    "data.fields.winner_fulfillment_form.value"
  );
  const selectedWinnerFulfillment =
    find(
      winnerFulfillmentOptions,
      (item) => item.value === selectedWinnerFulfillmentValue
    ) || "";

  const onSelectedField = (value) => {
    setIsOpenFieldsFormBuilderModal(false);
    // eslint-disable-next-line no-lone-blocks
    let fields = [];
    const newField = generateField(value);
    fields = [...winnerFulfillmentFields, newField];
    onValueChanged(winner_fulfillment_fields, fields);
  };
  const selectedSystemForm = getSelectedSystemForm({
    selectedWinnerFulfillment,
    winnerFulfillmentFields,
  });
  const isEmptyIntroText = introText === "<p><br></p>" || !introText;
  return (
    <div className={bem.b()}>
      <ShadowBox className={bem.e("box-winner-fulfillment")}>
        <SectionTitle className={bem.e("title")}>
          <FormattedMessage id="process > promotion > contest > winner fulfillment page" />
          <div
            className={bem.e("btn-preview")}
            onClick={() => {
              setIsOpenPreviewModal(true);
            }}
          >
            <EyeIcon color="#795AFA" width={24} height={16} />
            <FormattedMessage id="process > promotion > contest > preview" />
          </div>
        </SectionTitle>
        <SectionFields className={classnames(bem.e("fields"))}>
          <Row>
            <Col xs={6} className={bem.e("reset-col")}>
              <FormGroup>
                <TextInput
                  label={
                    <span>
                      {winner_fulfillment_name.title}
                      {winner_fulfillment_name.mandatory && (
                        <Asterisk>*</Asterisk>
                      )}
                    </span>
                  }
                  required
                  name="winner_fulfillment_name"
                  placeholder={intl.formatMessage({
                    id: "process > promotion > contest > enter contest name",
                  })}
                  value={
                    process.data.fields[winner_fulfillment_name.field]
                      ? process.data.fields[winner_fulfillment_name.field].value
                      : ""
                  }
                  onChange={(event) => {
                    onValueChanged(
                      winner_fulfillment_name,
                      get(event, "target.value", "")
                    );
                  }}
                  error={get(
                    validationState,
                    "winner_fulfillment_name.validation_error"
                  )}
                />
              </FormGroup>
            </Col>
            <Col xs={6} className={bem.e("reset-col")}>
              <FormGroup>
                <Dropdown
                  label={
                    <span>
                      {winner_fulfillment_form.title}
                      {winner_fulfillment_form.mandatory && (
                        <Asterisk>*</Asterisk>
                      )}
                    </span>
                  }
                  name="winner_fulfillment_form"
                  placeholder={intl.formatMessage({
                    id: "process > promotion > contest > select",
                  })}
                  onChange={(selectedOption) => {
                    onValueChanged(
                      winner_fulfillment_form,
                      selectedOption.value
                    );
                  }}
                  value={selectedWinnerFulfillment || ""}
                  options={winnerFulfillmentOptions}
                  error={get(
                    validationState,
                    "winner_fulfillment_form.validation_error"
                  )}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={6} className={bem.e("col-reset")}>
              <FormGroup>
                <Label>
                  {station_logo_url.title}
                  {station_logo_url.mandatory && <Asterisk>*</Asterisk>}
                </Label>
                {get(logo, "path") ? (
                  <FileView
                    file={logo}
                    onDeleted={() => {
                      onValueChanged(station_logo_url, "");
                    }}
                    onUploaded={(files) => {
                      const file = first(files);
                      if (file) {
                        onValueChanged(station_logo_url, file.path);
                      }
                    }}
                    actions={["rename", "trash", "view"]}
                  />
                ) : (
                  <UploadFile
                    url={logo.path}
                    mimes={["image/jpeg", "image/png"]}
                    fieldName={"logo"}
                    actions={[]}
                    onDeleted={() => {
                      onValueChanged(station_logo_url, "");
                    }}
                    onUploaded={(value) => {
                      onValueChanged(station_logo_url, value ? value.path : "");
                    }}
                    showRemoveIcon={false}
                    maxSize={200000} //200kb
                  />
                )}
              </FormGroup>
            </Col>
            <Col xs={6} className={bem.e("col-reset")}>
              <FormGroup>
                <Label>
                  <span>&nbsp;</span>
                </Label>
                <span className={bem.e("upload-description")}>
                  <FormattedMessage id="process > promotion > contest > station logo description" />
                </span>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className={bem.e("col-reset")}>
              <FormGroup
                className={classnames(bem.e("introduction-field"), {
                  [bem.e("introduction-field-empty")]: isEmptyIntroText,
                })}
              >
                <Label className={bem.e("introduction-label")}>
                  {winner_fulfillment_intro_text.title}
                </Label>
                <TextareaEvaluator
                  text={setScriptField(introText)}
                  onEvaluatorCallback={(value) => {
                    onValueChanged(winner_fulfillment_intro_text, value);
                  }}
                  showScriptLength={false}
                  hideHelperText={true}
                  showInnerTitle
                  hideOrderLength={true}
                  tagsInModal={winnerFulfillmentTags}
                  tagsIsModal={true}
                  onSetAsDefault={() => {
                    if (isEmptyIntroText) return;
                    onValueChanged(set_intro_text_as_default, true);
                    ToastManager.show({
                      title: intl.formatMessage({
                        id: "toast > title added",
                      }),
                      level: "success",
                    });
                  }}
                  placeholder={intl.formatMessage({
                    id: "process > enter text",
                  })}
                  error={get(
                    validationState,
                    "winner_fulfillment_intro_text.validation_error",
                    ""
                  )}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className={bem.e("col-reset")}>
              <div className={bem.e("custom-fields-sort")}>
                <SortableComponent
                  data={winnerFulfillmentFields}
                  onSorted={(sortItems) => {
                    onValueChanged(winner_fulfillment_fields, sortItems);
                  }}
                  formProps={{
                    isView,
                    disabled: false,
                    currentStation: station,
                    errors,
                    touched,
                    fields: winnerFulfillmentFields,
                    setFieldValue: (newFields) => {
                      onValueChanged(winner_fulfillment_fields, newFields);
                    },
                    onDeleteField: (field) => {
                      setIsOpenConfirmationDeleteModal(true);
                      setDeletingField(field);
                    },
                  }}
                />
              </div>
              <div className={bem.e("option-custom")}>
                <Button
                  className={classnames(bem.e("button-create"), "btn-radius")}
                  color="blue"
                  outline
                  onClick={() => {
                    setIsOpenFieldsFormBuilderModal(
                      !isOpenFieldsFormBuilderModal
                    );
                  }}
                >
                  <i className={bem.e("button-icon")}>
                    <FaPlus />
                  </i>
                  <FormattedMessage id="process > promotion > contest > optional custom field" />
                </Button>
                <span className={bem.e("optional-text")}>
                  <FormattedMessage id="process > promotion > contest > optional custom field text" />
                </span>
              </div>
            </Col>
          </Row>
        </SectionFields>
        <div className="bottom-actions-sticky">
          <div className={"buttons"}>
            <PrivilegedComponent
              requires={{
                or: [
                  PRIVILEGES.CONTEST_SAVE_DRAFT,
                  PRIVILEGES.CONTEST_EDIT,
                  PRIVILEGES.CONTEST_SAVE,
                ],
              }}
            >
              <Button
                color="blue"
                className={classnames(bem.e("btn-submit"), "btn-radius")}
                onClick={(event) => {
                  if (isView) {
                    history.push(
                      "/processes/edit/" +
                        template.key +
                        "/" +
                        get(process, "data.fields.key.value")
                    );
                    props.setActiveEditTab(step.key);
                    return;
                  }
                  onSubmit(event);
                }}
              >
                {isView ? (
                  <FormattedMessage id="process > button edit" />
                ) : isAllowSave ? (
                  <FormattedMessage id="process > promotion > contest > button save" />
                ) : (
                  <FormattedMessage id="process > button save draft" />
                )}
              </Button>
            </PrivilegedComponent>
          </div>
        </div>
        <FieldsFormBuilderModal
          isOpen={isOpenFieldsFormBuilderModal}
          onSelect={onSelectedField}
          list={"winnerFulfillment"}
          onToggle={() =>
            setIsOpenFieldsFormBuilderModal(!isOpenFieldsFormBuilderModal)
          }
        />
        {/* actions confirmation delete field*/}
        <ConfirmationModal
          isOpen={isOpenConfirmationDeleteModal}
          title={intl.formatMessage({
            id: "form manager > title delete system field confirmation",
          })}
          isCloseOutside={false}
          className={bem.e("confirmation-modal")}
          confirmTitle={intl.formatMessage({
            id: "form manager > button yes",
          })}
          cancelTitle={intl.formatMessage({
            id: "form manager > button no",
          })}
          onToggle={() =>
            setIsOpenConfirmationDeleteModal(!isOpenConfirmationDeleteModal)
          }
          onConfirm={() => {
            let newFields = clone(winnerFulfillmentFields);
            newFields.splice(deletingField, 1);
            onValueChanged(winner_fulfillment_fields, newFields);
            setIsOpenConfirmationDeleteModal(false);
            setDeletingField(null);
          }}
          onCancel={() => {
            setIsOpenConfirmationDeleteModal(false);
          }}
        />
      </ShadowBox>
      {selectedSystemForm && (
        <PreviewModal
          isOpen={isOpenPreviewModal}
          systemForm={selectedSystemForm}
          currentStation={{ data: props.station }}
          currentServer={props.currentServer}
          isContestPreview
          title={
            process.data.fields[winner_fulfillment_name.field]
              ? process.data.fields[winner_fulfillment_name.field].value
              : ""
          }
          description={setScriptField(
            get(process, "data.fields.winner_fulfillment_intro_text.value")
          )}
          onToggle={() => {
            setIsOpenPreviewModal(!isOpenPreviewModal);
          }}
        />
      )}
    </div>
  );
};
export default WinnerFulfillment;
