import * as React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FaFileO, FaTrash } from "react-icons/lib/fa";
import { IoIosDownload } from "react-icons/lib/io";
import truncate from "truncate";
import Image from "components/Image";
import Player from "components/Player";
import { isImage, isMp3, getExt } from "utils/helpers";
import { getColor } from "utils/colors";

const MAX_FILE_NAME_LENGTH = 30;

const Container = styled.div`
  margin: 10px;
  margin-right: 20px;
  position: relative;
`;

const ExtIcon = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Ext = styled.div`
  position: absolute;
  font-size: 10px;
`;

const PlainFileName = styled.div`
  font-size: 12px;
`;

const DeleteIcon = styled.div`
    position: absolute;
    top: 10px;
    right: -40px;
    /* color: black; */
    cursor: pointer;
    display: block;
`;

const DownloadIcon = styled.div`
  position: absolute;
  bottom: -12px;
  right: -40px;
  cursor: pointer;
  display: block;
`;

class FileBlock extends React.Component {
  renderPlainFileIcon = () => {
    const { file } = this.props;
    const ext = getExt(file.name);
    return (
      <div>
        <ExtIcon>
          <FaFileO size={60} color={getColor("primary")} />
          <Ext>{ext}</Ext>
        </ExtIcon>
        <PlainFileName>
          {truncate(file.name, MAX_FILE_NAME_LENGTH)}
        </PlainFileName>
      </div>
    );
  };

  renderImage = () => {
    const { file } = this.props;
    return (
      <div>
        {/* `file.preview` when we drop the file, `file.path` when we are showing already uploaded file */}
        <Image url={file.preview || file.path} />
        <PlainFileName>
          {truncate(file.name, MAX_FILE_NAME_LENGTH)}
        </PlainFileName>
      </div>
    );
  };

  renderMp3 = () => {
    const { file, width, height } = this.props;
    return (
      <div>
        {/* `file.preview` when we drop the file, `file.path` when we are showing already uploaded file */}
        <Player url={file.preview || file.path} width={width} height={height} />
        <PlainFileName>
          {truncate(file.name, MAX_FILE_NAME_LENGTH)}
        </PlainFileName>
      </div>
    );
  };

  render() {
    const { showRemoveIcon } = this.props;
    let { file } = this.props;
    if (file.push) file = file[0];
    if (!file.path) file.path = file.name;
  
    let fileType = "default";
    if (isImage(file.type)) {
      fileType = "image";
    } else if (isMp3(file.type, file.path) && (!file.path || (file.path.substr(-4).toLowerCase() !=='.ini' && file.path.substr(-4).toLowerCase() !=='.m4a'))) {
      fileType = "mp3";
    }

    return (
      <Container>
        {fileType.toLowerCase() === "default" && this.renderPlainFileIcon()}
        {fileType.toLowerCase() === "image" && this.renderImage()}
        {fileType.toLowerCase() === "mp3" && this.renderMp3()}
        {showRemoveIcon && (
          <DeleteIcon onClick={this.props.onDeleteClick}>
            <FaTrash size={30} color="#795afa" />
          </DeleteIcon>
        )}
        <DownloadIcon onClick={this.props.onDownloadClick}>
          <IoIosDownload size={35} />
        </DownloadIcon>
      </Container>
    );
  }
}

FileBlock.propTypes = {
  showRemoveIcon: PropTypes.bool,
  file: PropTypes.oneOfType([
    PropTypes.instanceOf(File),
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
      path: PropTypes.string
    })
  ])
};

FileBlock.defaultProps = {
  showRemoveIcon: true
};

export default FileBlock;
