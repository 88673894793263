import { FormattedMessage } from "react-intl";
import React from "react";
import { get, map, filter, delay } from "lodash";
import history from "components/History";
import { validatePrivileges } from "utils/helpers";
import { PRIVILEGES } from "utils/constants";

const dubScript = {
  process_step_index: 8,
  key: "dub_script",
  title: <FormattedMessage id="process > title dubbing" />,
  caption_color: "#6803CC",
  description: null,
  isCollapsible: false,
  step_roles: [9],
  step_viewers_roles: [9, 4, 7],
  step_editors_roles: [9, 4],
  step_viewers_privileges: {
    or: ["perform_production_dubbing", "edit_all_production_orders"],
  },
  step_editors_privileges: {
    or: ["perform_production_dubbing", "edit_all_production_orders"],
  },
  status: "initial",
  className: "dubbing-container",
  is_open_edit: true,
  can_edit_any_time: true,
  is_hidden: function(user, process, step) {
    return (
      process.data.fields.order_sold && !process.data.fields.order_sold.value
    );
  },
  no_edit_button: true,
  workflow: {
    title: "dubbing",
    icon: "DubbingIcon",
  },
  is_approval_step: false,
  fields: [
    {
      component: "production_details",
      props: {
        isOnlyViewMode: true,
        isCollapse: true,
      },
    },
    {
      component: "production_dubbing_traffic_details",
    },
    {
      field: "spot_info",
    },
    {
      field: "markets",
    },
    {
      text: <FormattedMessage id="process > button done" />,
      // component: "button",
      key: "save_button",
      // props: { color: "blue" },
      // container: "bottom_buttons",
      fields: [
        {
          key: "dub_script_status",
          value: function(
            user,
            template,
            process,
            step,
            item,
            next_users,
            extraData
          ) {
            // const enableEnterprise = get(
            //   extraData,
            //   "production_multimarket",
            //   false
            // );
            let status = "completed";
            let isMissingDubbed = false;
            // if (enableEnterprise) {
            //   const markets = get(process, "data.fields.markets.value", []);
            //   isMissingDubbed =
            //     filter(markets, (market) => !market.is_dubbed).length > 0;
            // } else {
            const spotInfo = get(process, "data.fields.spot_info.value", []);
            isMissingDubbed =
              filter(spotInfo, (spot) => !spot.is_dubbed).length > 0;
            // }
            if (isMissingDubbed) {
              status = null;
            }
            return status;
          },
        },
        {
          key: "process_step",
          value: function(
            user,
            template,
            process,
            step,
            item,
            next_users,
            extraData
          ) {
            // const enableEnterprise = get(
            //   extraData,
            //   "production_multimarket",
            //   false
            // );

            let processStep = "first_undone";
            let isMissingDubbed;
            // if (enableEnterprise) {
            //   const markets = get(process, "data.fields.markets.value", []);
            //   isMissingDubbed =
            //     filter(markets, (market) => !market.is_dubbed).length > 0;
            // } else {
            const spotInfo = get(process, "data.fields.spot_info.value", []);
            isMissingDubbed =
              filter(spotInfo, (spot) => !spot.is_dubbed).length > 0;
            // }

            if (isMissingDubbed) {
              processStep = "dub_script";
            }
            return processStep;
          },
        },
        {
          key: "next_users",
          value: function(
            user,
            template,
            process,
            step,
            item,
            next_users,
            extraData
          ) {
            let isMissingDubbed;
            // const enableEnterprise = get(
            //   extraData,
            //   "production_multimarket",
            //   false
            // );
            let nextUsers = get(process, "data.fields.next_users.value", []);
            // if (enableEnterprise) {
            //   const markets = get(process, "data.fields.markets.value", []);
            //   isMissingDubbed =
            //     filter(markets, (market) => !market.is_dubbed).length > 0;
            // } else {
            const spotInfo = get(process, "data.fields.spot_info.value", []);
            isMissingDubbed =
              filter(spotInfo, (spot) => !spot.is_dubbed).length > 0;
            // }

            if (isMissingDubbed) {
              const roleDubber = map(
                get(process, "data.fields.role_dubber.value.users", []),
                (item) => item.id
              );
              nextUsers = roleDubber; // keep current user for continuity dubbing
            } else {
              // go to sales
              nextUsers = [
                get(process, "data.fields.role_sales_person.value.id"),
              ];
            }
            return nextUsers;
          },
        },
      ],
      class: null,
      on_click: {
        validate: true,
        save: true,
        go_to: function(_this, step, item, process, template, user, extraData) {
          // const enableEnterprise = get(
          //   extraData,
          //   "production_multimarket",
          //   false
          // );
          const privileges = get(user, "privileges", []) || [];
          let isRedirect = false;
          // set delay when after success message
          // if (!enableEnterprise) {
          const missingDubbed = get(
            process,
            "data.fields.spot_info.value",
            []
          ).filter((spot) => {
            const isDubber = validatePrivileges({
              requires: {
                or: [
                  PRIVILEGES.PERFORM_PRODUCTION_DUBBING,
                  PRIVILEGES.EDIT_ALL_PRODUCTION_ORDERS,
                ],
              },
              privileges,
              user,
            });
            return (
              (get(spot, "role_dubber.users", []).find(
                (item) => Number(item.id) === Number(user.id)
              ) || isDubber) && !spot.is_dubbed
            );
          });
          isRedirect = missingDubbed.length === 0;
          // } else {
          //   const marketsAssigned = filter(
          //     get(process, "data.fields.markets.value", []),
          //     (market) => {
          //       const isAssigned = find(
          //         get(market, "role_dubber.users", []),
          //         (item) => Number(item.id) === Number(user.id)
          //       );
          //       return isAssigned;
          //     }
          //   );
          //   isRedirect =
          //     marketsAssigned.filter((market) => !market.is_dubbed).length ===
          //     0;
          // }

          if (isRedirect) {
            if (!user.return_to_current_tasks) return;
            delay(() => {
              if (_this.props.setSidebarActive) {
                _this.props.setSidebarActive(`/processes/my_action_items`);
              }
              history.replace(`/processes/my_action_items`);
            }, 1000);
          }
        },
      },
      // recomputeFields: function(user, template, process, step, extraData) {
      //   const formValueChanged = get(extraData, "formValueChanged");
      //   if (!get(extraData, "production_multimarket") || !formValueChanged) {
      //     return process;
      //   }
      //   // sync process step to markets
      //   const markets = map(
      //     get(process, "data.fields.markets.value", []),
      //     (market) => ({
      //       ...market,
      //       step: get(process, "data.fields.process_step.value"),
      //     })
      //   );
      //   process.data.fields.markets = { value: markets, is_dirty: true };
      //   return process;
      // },
      confirmation_dialog_props: {
        title: (
          <FormattedMessage id="process > description mark dubbing as done" />
        ),
        confirmTitle: <FormattedMessage id="process > button yes" />,
        cancelTitle: <FormattedMessage id="process > button no" />,
      },
      success_dialog_props: {
        title: <FormattedMessage id="process > title dubbing done" />,
        state: "success",
      },
    },
  ],
};
export default dubScript;
