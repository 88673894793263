import React, { useMemo, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { forOwn } from "lodash";
import { PlusSmallIcon, TooltipArrowLeft } from "components/CustomIcons";
import useOutsideClick from "utils/useOutsideClick";

const BoxConversation = (props) => {
  const [isOpenBoxConversation, setIsOpenBoxConversation] = useState(false);
  const { bem, conversationData, onSelectUser } = props;
  const container = useRef();
  const { members, isAllMembers } = useMemo(() => {
    let users = [];
    const roles = [
      "role_account_executive",
      "role_production_manager",
      "role_writer",
      "role_voice_talent",
      "role_producer",
      "role_continuity",
      "role_dubber",
    ];
    if (!conversationData)
      return {
        members: [],
        isAllMembers: false,
      };
    forOwn(conversationData, (value, key) => {
      if (key === "role_account_executive") {
        let sales = [];
        if (value && value.users && Array.isArray(value.users)) {
          sales = value.users.map((u) => ({
            label: "sales",
            value: u,
            role: "role_account_executive",
          }));
        } else if (value) {
          sales = [
            {
              label: "sales",
              value,
              role: "role_account_executive",
            },
          ];
        }
        users = [...users, ...sales];
      }
      if (key === "role_production_manager") {
        let productManager = [];
        if (value && value.users && Array.isArray(value.users)) {
          productManager = value.users.map((u) => ({
            label: "pm",
            value: u,
            role: "role_production_manager",
          }));
        } else if (value) {
          productManager = [
            {
              label: "pm",
              value,
              role: "role_production_manager",
            },
          ];
        }
        users = [...users, ...productManager];
      }
      if (key === "role_writer") {
        let writers = [];
        if (value && value.users && Array.isArray(value.users)) {
          writers = value.users.map((u) => ({
            label: "writer",
            value: u,
            role: "role_writer",
          }));
        }
        users = [...users, ...writers];
      }
      if (key === "role_voice_talent") {
        let voices = [];
        if (value && value.users && Array.isArray(value.users)) {
          voices = value.users.map((u) => ({
            label: "voice",
            value: u,
            role: "role_voice_talent",
          }));
        }
        users = [...users, ...voices];
      }
      if (key === "role_producer") {
        let producers = [];
        if (value && value.users && Array.isArray(value.users)) {
          producers = value.users.map((u) => ({
            label: "producer",
            value: u,
            role: "role_producer",
          }));
        }
        users = [...users, ...producers];
      }
      if (key === "role_continuity") {
        let continuity = [];
        if (value && value.users && Array.isArray(value.users)) {
          continuity = value.users.map((u) => ({
            label: "continuity",
            value: u,
            role: "role_continuity",
          }));
        }
        users = [...users, ...continuity];
      }
      if (key === "role_dubber") {
        let dubber = [];
        if (value && value.users && Array.isArray(value.users)) {
          dubber = value.users.map((u) => ({
            label: "dubber",
            value: u,
            role: "role_dubber",
          }));
        }
        users = [...users, ...dubber];
      }
    });
    const missedRoles = roles.filter(
      (role) => !users.find((u) => u.role === role)
    );
    return {
      members: users,
      isAllMembers: missedRoles.length === 0,
    };
  }, [conversationData]);
  useOutsideClick(container, (event) => {
    setIsOpenBoxConversation(false);
  });
  return (
    <div className={bem.e("box-members-container")} ref={container}>
      <div
        className={bem.e("button-plus")}
        onClick={() => setIsOpenBoxConversation(true)}
      >
        <PlusSmallIcon />
      </div>
      {isOpenBoxConversation && (
        <div className={bem.e("box-conversation")}>
          <div className={bem.e("box-head")}>
            <span className={bem.e("box-arrow")}>
              <TooltipArrowLeft />
            </span>
            <FormattedMessage id="process > start a conversation" />
          </div>
          <div className={bem.e("box-title")}>
            <FormattedMessage id="process > conversation members" />
          </div>
          <div className={bem.e("box-members")}>
            {members.map((item, index) => (
              <div
                key={`user-${item.value.id}-${index}`}
                className={bem.e("box-member-item")}
                onClick={() => {
                  onSelectUser(item.value);
                  setIsOpenBoxConversation(false);
                }}
              >
                <strong>
                  <FormattedMessage id={`process > member ${item.label}`} />:
                </strong>
                <span>{item.value.name}</span>
              </div>
            ))}
            {isAllMembers && (
              <strong className={bem.e("box-member-item")}>
                <FormattedMessage id="process > all team members" />
              </strong>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default BoxConversation;
