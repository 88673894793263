import AddUpdateClientManager from "./view";
import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";
import { get } from "lodash";
import { getClient, setClient } from "store/actions/clients";

export default compose(
  connect(
    (state, props) => {
      return {
        client: get(state, "clients.client.data"),
        ...props,
      };
    },
    {
      getClient,
      setClient,
    }
  ),
  lifecycle({
    componentDidMount() {
      if (this.props.client_id) {
        this.props.getClient(this.props.client_id);
      } else {
        this.props.setClient({});
      }
    },
    componentDidUpdate(prevProps) {
      if (this.props.client_id === prevProps.client_id) return;
      if (this.props.client_id) {
        this.props.getClient(this.props.client_id);
      } else {
        this.props.setClient({});
      }
    },
  })
)(AddUpdateClientManager);
