import React, { useState, useEffect, useRef } from "react";
import bn from "utils/bemnames";
import { get, find, filter, findIndex, forEach, first, split } from "lodash";
import ReviewMedia from "components/ReviewMedia";
import Spinner from "components/Spinner";
import { labelToNameField, firstUndoneStep } from "utils/helpers";
import GuideReview from "../GuidePreview";
import CommentSidebar from "../CommentSidebar";
import FeedbackSuccessModal from "components/FeedbackSuccessModal";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import ReviewLayout from "../ReviewLayout";
const bem = bn.create("client-review");

function ReviewContent(props) {
  const {
    process,
    user,
    buttonClicked,
    template,
    mainSlickRef,
    isReviewed,
  } = props;
  const step = find(template.steps, (item) => item.key === "review_media");
  return (
    <div className={classnames(bem.e("review"), "process-container")}>
      <ReviewMedia
        process={process}
        step={step}
        template={template}
        buttonClicked={buttonClicked}
        user={user}
        isView={isReviewed}
        isClientReview={true}
        isClientReviewed={isReviewed}
        isShowHelperTooltip={true}
        valueChanged={props.valueChanged}
        mainSlickRef={mainSlickRef}
      />
    </div>
  );
}

function DigitalReview(props) {
  const { process, template, extraReviewProcess, onGetReviewProcess } = props;
  const [isShowGuide, setIsShowGuide] = useState(true);
  const [isFeedbackSuccessModal, setIsFeedbackSuccessModal] = useState(false);
  const [feedbackAction, setFeedbackAction] = useState(null);
  const [mediaFields, setMediaFields] = useState([]);
  const mainSlickRef = useRef();
  const clientName = get(process, "data.fields.client_name.value");
  const userSale = get(extraReviewProcess, "sale");
  const stations = get(extraReviewProcess, "stations", []);
  // const server = get(extraReviewProcess, "server");
  const [isReviewed, setIsReviewed] = useState(
    get(extraReviewProcess, "is_reviewed", false)
  );
  const user = {
    name: clientName,
    id: -1,
  };
  const valueChanged = (item, process, template, user, event) => {
    props.formValueChanged({
      process,
      template,
      item,
      user,
      value: !event
        ? ""
        : Array.isArray(event)
        ? event
        : event.value !== undefined
        ? event.value
        : event.target
        ? event.target.value
        : event,
    });
  };

  const buttonClicked = (
    step,
    item,
    process,
    template,
    user,
    event,
    callback,
    isForce = false,
    noSuccessMessage = false
  ) => {
    const isSubmitAllFeedback = get(item, "is_submit_all_feedback", false);
    if (isSubmitAllFeedback) {
      // re-use the fields config from template when submit button
      item.fields.map((field) => {
        if (typeof field.value === "undefined") {
          return false;
        }
        let value = field.value;
        if (typeof value === "function")
          value = field.value(
            user,
            template,
            process,
            step,
            item,
            get(process, "data.fields.next_users.value", [])
          );
        if (field.key === "process_step" && value === "first_undone")
          value = firstUndoneStep(user, template, process, step, item);
        props.formValueChanged({
          process,
          template,
          item: { field: field.key },
          user,
          value,
        });
        process.data.fields[field.key] = { value: value, is_dirty: true };
        return false;
      });
    }
    let success_callback = (response) => {
      if (isSubmitAllFeedback && response.status === 200) {
        setIsFeedbackSuccessModal(true);
        setIsReviewed(true);
      }
    };
    if (callback) success_callback = callback;
    let saveFormValues = {
      process,
      template,
      step,
      user,
      success_callback,
      valid: true,
      isForce,
    };
    // add params for check check client reviewed
    if (isSubmitAllFeedback) {
      saveFormValues.meta = {
        ...get(saveFormValues, "meta", {}),
        is_client_review: true,
      };
      const isRejected = !!find(
        mediaFields,
        (item) => item.review_media_status === "rejected"
      );
      if (isRejected) {
        setFeedbackAction("reject");
      } else {
        setFeedbackAction("approve");
      }
    }
    props.saveFormValues(saveFormValues);
  };
  const onAutoSubmit = (process) => {
    const step = find(template.steps, (item) => item.key === "review_media");
    const item = step.fields.find((f) => f.key === "save_button");
    buttonClicked(
      step,
      {
        ...item,
        is_submit_all_feedback: false,
      },
      process,
      template,
      user,
      null,
      null,
      true
    );
  };
  const channels = get(process, "data.fields.marketing_channels.value", []);
  const forMarketingChannel = get(
    process,
    "data.fields.for_marketing_channel.value.id"
  );
  const channel = find(
    channels,
    (channel) => channel.id === forMarketingChannel
  );
  useEffect(() => {
    let newMediaFields = mediaFields;
    filter(
      get(channel, "fields", []),
      (item) => item.key === "media_for_review_approval"
    )
      .filter((field) => {
        const fieldName = labelToNameField(field.label);
        return get(channel, fieldName, []);
      })
      .forEach((field, index) => {
        const fieldName = labelToNameField(field.label);
        const value = get(channel, fieldName, [])
          ? get(channel, fieldName, [])
          : [];
        forEach(value, (valueItem, indexValue) => {
          const idx = `${index}_${indexValue}`; // merge field index + value index;
          const existsIndex = findIndex(
            mediaFields,
            (item) => item.idx === idx
          );
          // to replace current value
          const newMediaField = {
            review_media_status: get(valueItem, "review_media_status", null),
            is_approved: get(valueItem, "review_media_status") === "approved",
            is_rejected: get(valueItem, "review_media_status") === "rejected",
            label: field.label,
            key: field.key,
            fieldName,
            value: valueItem,
            idx,
            media_name: first(split(valueItem.name, ".")),
          };
          if (existsIndex !== -1) {
            newMediaFields[existsIndex] = newMediaField;
          } else {
            newMediaFields.push(newMediaField);
          }
        });
      });
    setMediaFields(newMediaFields);
    props.setRefreshKey(new Date().getTime());
  }, [get(channel, "fields", [])]);

  const renderReviewContent = () => (
    <div className={bem.e("review-content")}>
      <div className={bem.e("review-left")}>
        {isShowGuide && (
          <GuideReview process={process} setIsShowGuide={setIsShowGuide} />
        )}
        {!isShowGuide && (
          <ReviewContent
            user={user}
            process={process}
            valueChanged={valueChanged}
            buttonClicked={buttonClicked}
            template={template}
            isReviewed={isReviewed}
            mainSlickRef={mainSlickRef} // set ref to this variable
          />
        )}
      </div>
      <div className={bem.e("review-right")}>
        <CommentSidebar
          stations={stations}
          process={process}
          mediaFields={mediaFields}
          mainSlickRef={mainSlickRef}
          isShowGuide={isShowGuide}
          template={template}
          user={user}
          channel={channel}
          valueChanged={valueChanged}
          onAutoSubmit={onAutoSubmit}
          onGetReviewProcess={onGetReviewProcess}
          isClientReviewed={isReviewed}
          refreshKey={props.refreshKey}
        />
      </div>
    </div>
  );

  return (
    <ReviewLayout
      extraReviewProcess={extraReviewProcess}
      renderReviewContent={renderReviewContent}
    >
      <Spinner isLoading={process.loading} />
      <FeedbackSuccessModal
        isOpen={isFeedbackSuccessModal}
        user={userSale}
        title={
          feedbackAction === "reject" ? (
            <FormattedMessage id="process > client reject title" />
          ) : (
            <FormattedMessage id="process > client approval title" />
          )
        }
        description={
          feedbackAction === "reject" ? (
            <FormattedMessage id="process > client reject description" />
          ) : (
            <FormattedMessage id="process > client approval description" />
          )
        }
        isAutoClose
        onToggle={() => {
          setIsFeedbackSuccessModal(false);
        }}
        onCancel={() => {
          setIsFeedbackSuccessModal(false);
        }}
      />
    </ReviewLayout>
  );
}
export default DigitalReview;
