import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Row, Col, Label, FormGroup } from "reactstrap";
import { get } from "lodash";
import TextInput from "components/TextInput";
import UploadFile from "components/UploadFile";
import bn from "utils/bemnames";
import { UpIcon, DownIcon } from "components/CustomIcons";

const bem = bn.create("profile");

export default function View(props) {
  const [isCreativeDetailsCollapsed, setIsCreativeDetailsCollapsed] = useState(
    true
  );
  const { isView, user, intl, isDisabled, formProps } = props;

  if (isView)
    return (
      <div className={bem.e("creative-details-view")}>
        <h2 className={bem.e("creative-title")}>
          <FormattedMessage id={"my profile > title creative details"} />
          {isCreativeDetailsCollapsed ? (
            <span
              className={bem.e("button-down-icon")}
              onClick={() => setIsCreativeDetailsCollapsed(false)}
            >
              <DownIcon color="#795AFA" />
            </span>
          ) : (
            <span
              className={bem.e("button-up-icon")}
              onClick={() => setIsCreativeDetailsCollapsed(true)}
            >
              <UpIcon color="#795AFA" />
            </span>
          )}
        </h2>
        {!isCreativeDetailsCollapsed && (
          <div className={bem.e("creative-view-content")}>
            <Row>
              <Col xs={6} className={bem.e("col-reset")}>
                <div className={bem.e("creative-view-row")}>
                  <Label className={bem.e("view-label")}>
                    <FormattedMessage id="my profile > personal bio" />
                  </Label>
                  <div className={bem.e("view-value")}>{user.summary}</div>
                </div>
                <div className={bem.e("creative-view-row-reel")}>
                  <Label className={bem.e("view-label")}>
                    <FormattedMessage id="my profile > sample reel" />
                  </Label>
                  <div className={bem.e("field-view-value")}>
                    <UploadFile
                      attachText={intl.formatMessage({
                        id: "my profile > attach files",
                      })}
                      mode="edit"
                      showRemoveIcon={true}
                      isDisabledDelete={true}
                      disabled
                      isShowFileNameInEditMode={true}
                      actions={[]}
                      url={get(user, "reel")}
                    >
                      <FormattedMessage id="process > button upload" />
                    </UploadFile>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  const values = get(formProps, "values", {});
  const touched = get(formProps, "touched", {});
  const errors = get(formProps, "errors", {});
  return (
    <div className={bem.e("creative-details-view")}>
      <h2 className={bem.e("creative-title")}>
        <FormattedMessage id={"my profile > title creative details"} />
        {isCreativeDetailsCollapsed ? (
          <span
            className={bem.e("button-down-icon")}
            onClick={() => setIsCreativeDetailsCollapsed(false)}
          >
            <DownIcon color="#795AFA" />
          </span>
        ) : (
          <span
            className={bem.e("button-up-icon")}
            onClick={() => setIsCreativeDetailsCollapsed(true)}
          >
            <UpIcon color="#795AFA" />
          </span>
        )}
      </h2>
      {!isCreativeDetailsCollapsed && (
        <div className={bem.e("creative-view-content")}>
          <Row>
            <Col xs={6} className={bem.e("col-reset")}>
              <FormGroup className={bem.e("form-group-creative-edit")}>
                <TextInput
                  label={
                    <span>
                      <FormattedMessage id="my profile > personal bio" />
                    </span>
                  }
                  disabled={isDisabled}
                  type="textarea"
                  name="summary"
                  placeholder={intl.formatMessage({
                    id: "my profile > summary placeholder",
                  })}
                  value={values.summary}
                  error={touched.summary && errors.summary}
                  onChange={formProps.handleChange}
                  onBlur={formProps.handleBlur}
                />
              </FormGroup>
              <div className={bem.e("creative-edit-reel")}>
                <FormGroup className={bem.e("form-group-creative-edit")}>
                  <Label>
                    <FormattedMessage id="my profile > sample reel" />
                  </Label>
                  <UploadFile
                    attachText={intl.formatMessage({
                      id: "my profile > attach files",
                    })}
                    onUploaded={(file) => {
                      formProps.setFieldValue("reel", file.path);
                    }}
                    showRemoveIcon={true}
                    onDeleted={() => {
                      formProps.setFieldValue("reel", null);
                    }}
                    isShowFileNameInEditMode={true}
                    actions={[]}
                    url={get(values, "reel")}
                    isCheckMimesByExt={true}
                    extensions={[
                      "mp3",
                      "wav",
                      "aiff",
                      "flac",
                      "aac",
                      "alac",
                      "m4a",
                      "mp4",
                    ]}
                  >
                    <FormattedMessage id="process > button upload" />
                  </UploadFile>
                </FormGroup>
              </div>
            </Col>
            <Col xs={6} className={bem.e("col-reset")}>
              <div className={bem.e("creative-view-row")}>
                {/* {showTags && (
         <FormGroup className={bem.e("form-group-creative-edit")}>
           <Label>
             <FormattedMessage id="my profile > style tags" />
           </Label>
           <SelectTags
             checked={values.tags}
             isInputSearch={true}
             name="tags"
             isShowPopularTags={false}
             onChange={(data) => {
               const newSelectedTags = tagsOptions.filter((item) =>
                 includes(
                   map(data, (selectedTagItem) => selectedTagItem.value),
                   item.value
                 )
               );
               formProps.setFieldValue("tags", newSelectedTags);
             }}
             type={TAG_TYPE_EXCEPT_LOCATION_FORMAT}
           />
           <div className="text-danger">
             {touched.tags && errors.tags}
           </div>
         </FormGroup>
       )} */}
              </div>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}
