import * as React from "react";
import { Row, Col, Label } from "reactstrap";
import { get, orderBy, find, filter, includes, isEmpty } from "lodash";
import { Asterisk } from "components/Elements";
import UploadFile from "components/UploadFile";
import Spinner from "components/Spinner";
import { FormattedMessage } from "react-intl";
import Checkbox from "components/Checkbox";
import PrivilegedComponent from "components/PrivilegedComponent";
import OrderVoiceReview from "./OrderVoiceReview";
import VoiceTrackProductionValue from "./VoiceTrackProductionValue";
import { PRIVILEGES } from "utils/constants";
import classNames from "classnames";
import bn from "utils/bemnames";
import moment from "moment";
import VoiceTrackValue from "./VoiceTrackValue";
import FileView from "components/FileView";
import TextareaAutosize from "react-textarea-autosize";

const bem = bn.create("voice-tracks");

const VoiceItem = ({
  bem,
  voice,
  user,
  template,
  item,
  isView,
  valueChanged,
  voiceTracks,
  validationError,
  formValueChanged,
  process,
  setShouldBlockNavigation,
  voiceFeedback,
  step,
  onDeleteMessages,
  auth,
  dateFormatByServer,
  isAssignedAsProducer,
}) => {
  const voiceOverStatus =
    get(process, "data.fields.voice_over_status.value", null) === "rejected";
  const isCanFeedback = get(item, "props.isCanFeedback", false);
  const isShowFeedbackOtherVo = get(item, "props.isShowFeedbackOtherVo", false);
  const isVoViewing = user.id === voice.id;
  const uploadedOtherVoiceTrackValue = filter(
    voiceTracks,
    (item) => item.user_id !== voice.id
  );
  let voiceTrackValue = filter(
    voiceTracks,
    (item) => item.user_id === voice.id
  );
  const currentVoiceTrackValue = filter(
    voiceTracks,
    (item) => item.user_id === voice.id
  );
  // get data from feedback value to display view mode
  let isVoiceRejected = false;
  if (isCanFeedback && voiceOverStatus) {
    const voiceTrackValueFeedback = filter(
      voiceFeedback,
      (item) => item.user_id === voice.id
    );
    if (voiceTrackValueFeedback.length > 0) {
      isVoiceRejected =
        get(voiceTrackValueFeedback, "0.status", "") === "rejected";
      voiceTrackValue = voiceTrackValueFeedback;
    }
  }

  const recordingWithProducer = find(voiceTrackValue, (item) =>
    item.hasOwnProperty("is_recording_with_producer")
  );
  const isShowRecordingWithProducer =
    filter(
      voiceTrackValue,
      (item) => !item.hasOwnProperty("is_recording_with_producer")
    ).length === 0;
  const isShowRecordingWithProducerForView =
    filter(
      voiceTrackValue,
      (item) =>
        item.hasOwnProperty("is_recording_with_producer") &&
        item.is_recording_with_producer
    ).length > 0;
  const isShowHandleAssets =
    Array.isArray(voiceTrackValue) &&
    voiceTrackValue.length > 0 &&
    !recordingWithProducer;
  // Change requests should not show the recording to the other user within an order. Only the assigned user should ever see the recording once changes have been requested.
  const isShowInProduction =
    !isVoViewing && currentVoiceTrackValue.length === 0 && isVoiceRejected;
  const voIndex = get(voice, "index", 0);
  if (isAssignedAsProducer) {
    return (
      <Row className={bem.e("voice-track-item")}>
        <Col>
          <div className={bem.e("vo-name")}>
            {voice && voice.label ? (
              voice.label
            ) : (
              <>
                <FormattedMessage id="process > vo" />
                {voIndex + 1} - {get(voice, "name", "")}
              </>
            )}
          </div>
        </Col>
      </Row>
    );
  }
  if (isView) {
    return (
      <Row className={bem.e("voice-track-item")}>
        <Col>
          <div className={bem.e("vo-name")}>
            {voice && voice.label ? (
              voice.label
            ) : (
              <>
                <FormattedMessage id="process > vo" />
                {voIndex + 1} - {get(voice, "name", "")}
              </>
            )}
            {item.mandatory &&
              isVoViewing &&
              !get(
                recordingWithProducer,
                "is_recording_with_producer",
                false
              ) && <Asterisk>*</Asterisk>}
          </div>
          {/* render view for vo is viewing */}
          {isVoViewing ? (
            <Row>
              {isShowHandleAssets ? (
                <Col xs={12} className={bem.e("col-reset")}>
                  {isCanFeedback ? (
                    <OrderVoiceReview
                      item={item}
                      isView={isView}
                      process={process}
                      voiceTrackValue={voiceTrackValue}
                      formValueChanged={formValueChanged}
                      user={user}
                      template={template}
                      setShouldBlockNavigation={setShouldBlockNavigation}
                      step={step}
                      onDeleteMessages={onDeleteMessages}
                      auth={auth}
                    />
                  ) : (
                    <VoiceTrackValue
                      voiceTrackValue={voiceTrackValue}
                      isView={isView}
                      item={item}
                      bem={bem}
                      process={process}
                    />
                  )}
                </Col>
              ) : (
                <>
                  <Col xs={7}>
                    <div className={bem.e("vo-track-empty")}>
                      <FormattedMessage id="process > title in production" />
                    </div>
                  </Col>
                  {isShowRecordingWithProducerForView && (
                    <Col xs={5}>
                      <VoiceTrackProductionValue
                        recordingWithProducer={recordingWithProducer}
                        bem={bem}
                      />
                    </Col>
                  )}
                </>
              )}
            </Row>
          ) : (
            <Row>
              {/* render view is NOT the VO viewing */}
              {isShowHandleAssets ? (
                <Col xs={12} className={bem.e("col-reset")}>
                  {isCanFeedback && isShowFeedbackOtherVo ? (
                    <OrderVoiceReview
                      item={item}
                      isView={isView}
                      process={process}
                      voiceTrackValue={voiceTrackValue}
                      formValueChanged={formValueChanged}
                      user={user}
                      template={template}
                      setShouldBlockNavigation={setShouldBlockNavigation}
                      step={step}
                      onDeleteMessages={onDeleteMessages}
                      auth={auth}
                    />
                  ) : (
                    <VoiceTrackValue
                      voiceTrackValue={
                        get(currentVoiceTrackValue, "length", 0) > 0
                          ? currentVoiceTrackValue
                          : voiceTrackValue
                      } // need to show new upload recording to view
                      isView={isView}
                      item={item}
                      bem={bem}
                      process={process}
                      isShowInProduction={isShowInProduction}
                    />
                  )}
                </Col>
              ) : (
                <>
                  <Col xs={7}>
                    <div className={bem.e("vo-track-empty")}>
                      <FormattedMessage id="process > title in production" />
                    </div>
                  </Col>
                  {isShowRecordingWithProducerForView && (
                    <Col xs={5}>
                      <VoiceTrackProductionValue
                        recordingWithProducer={recordingWithProducer}
                        bem={bem}
                      />
                    </Col>
                  )}
                </>
              )}
            </Row>
          )}
        </Col>
      </Row>
    );
  }
  return (
    <Row className={bem.e("voice-track-item")}>
      <Col>
        <div className={bem.e("vo-name")}>
          {voice && voice.label ? (
            voice.label
          ) : (
            <>
              <FormattedMessage id="process > vo" />
              {voIndex + 1} - {get(voice, "name", "")}
            </>
          )}
          {item.mandatory &&
            isVoViewing &&
            !get(
              recordingWithProducer,
              "is_recording_with_producer",
              false
            ) && <Asterisk>*</Asterisk>}
        </div>
        {/* render edit for VO is viewing */}
        <div className={bem.e("voice-track-item-content")}>
          <PrivilegedComponent
            requires={{
              or: [PRIVILEGES.EDIT_VO, PRIVILEGES.EDIT_ALL_PRODUCTION_ORDERS],
            }}
          >
            {({ isValid }) => {
              if (isValid) {
                return (
                  <React.Fragment>
                    {isVoViewing ? (
                      <Row>
                        <Col
                          xs={isShowRecordingWithProducer ? 7 : 12}
                          className={classNames({
                            [bem.e("full-width")]: !isShowRecordingWithProducer,
                            [bem.e("min-width")]: isShowRecordingWithProducer,
                          })}
                        >
                          {isCanFeedback && isVoiceRejected && (
                            <React.Fragment>
                              <OrderVoiceReview
                                item={item}
                                isView={isView}
                                process={process}
                                voiceTrackValue={voiceTrackValue}
                                formValueChanged={formValueChanged}
                                user={user}
                                template={template}
                                setShouldBlockNavigation={
                                  setShouldBlockNavigation
                                }
                                isVoEditing={true}
                                step={step}
                                isAllowMessageActions={false}
                                onDeleteMessages={onDeleteMessages}
                                auth={auth}
                              />
                            </React.Fragment>
                          )}
                          <div
                            className={classNames(bem.e("upload-record"), {
                              [bem.e(
                                "upload-record-rejected"
                              )]: isVoiceRejected,
                            })}
                          >
                            <UploadFile
                              {...item.props}
                              url={get(currentVoiceTrackValue, `0.path`)}
                              actions={["download"]}
                              positionActions="bottom"
                              isShowFileNameInEditMode={true}
                              process={process}
                              onUploaded={(value) => {
                                let newVoiceTracksRemovedRecording = filter(
                                  currentVoiceTrackValue,
                                  (item) =>
                                    item.user_id === voice.id &&
                                    !item.hasOwnProperty(
                                      "is_recording_with_producer"
                                    )
                                );
                                voiceTracks = [
                                  ...uploadedOtherVoiceTrackValue,
                                  ...newVoiceTracksRemovedRecording,
                                  {
                                    ...value,
                                    user_id: voice.id,
                                    vo_audit: {
                                      modified_at: `${moment()
                                        .format(dateFormatByServer)
                                        .toString()} - ${moment()
                                        .format("h:mm A")
                                        .toString()}`,
                                      modified_by: voice.name,
                                    },
                                  },
                                ];
                                valueChanged(item, process, template, user, {
                                  value: voiceTracks,
                                });
                              }}
                              onDeleted={() => {
                                if (isVoiceRejected && !currentVoiceTrackValue)
                                  return;
                                valueChanged(item, process, template, user, {
                                  value: uploadedOtherVoiceTrackValue,
                                });
                              }}
                              showRemoveIcon={
                                !isVoiceRejected || currentVoiceTrackValue
                              }
                              className={item.class}
                              auditInfo={
                                get(item, "props.isShowAudit") &&
                                get(currentVoiceTrackValue, `0.vo_audit`)
                              }
                            >
                              <FormattedMessage id="process > button upload" />
                            </UploadFile>
                            {!!validationError && (
                              <span className="text-danger">
                                {validationError}
                              </span>
                            )}
                          </div>
                        </Col>
                        {isShowRecordingWithProducer && (
                          <Col xs={5}>
                            <div className={bem.e("checkbox-producer")}>
                              <Checkbox
                                checked={get(
                                  recordingWithProducer,
                                  "is_recording_with_producer",
                                  false
                                )}
                                text={
                                  <FormattedMessage id="process > title recording with producer" />
                                }
                                onChange={(checked) => {
                                  voiceTracks = [
                                    ...uploadedOtherVoiceTrackValue,
                                  ];
                                  if (checked) {
                                    voiceTracks.push({
                                      user_id: user.id,
                                      is_recording_with_producer: checked,
                                    });
                                  }
                                  valueChanged(item, process, template, user, {
                                    value: voiceTracks,
                                  });
                                }}
                              />
                            </div>
                          </Col>
                        )}
                      </Row>
                    ) : (
                      <Row>
                        {/* render edit is NOT the VO viewing */}
                        {isShowHandleAssets ? (
                          <Col xs={isShowRecordingWithProducer ? 7 : 12}>
                            {isCanFeedback && isShowFeedbackOtherVo ? (
                              <OrderVoiceReview
                                item={item}
                                isView={isView}
                                process={process}
                                voiceTrackValue={voiceTrackValue}
                                formValueChanged={formValueChanged}
                                user={user}
                                template={template}
                                setShouldBlockNavigation={
                                  setShouldBlockNavigation
                                }
                                step={step}
                                onDeleteMessages={onDeleteMessages}
                                auth={auth}
                              />
                            ) : (
                              <VoiceTrackValue
                                voiceTrackValue={
                                  get(currentVoiceTrackValue, "length", 0) > 0
                                    ? currentVoiceTrackValue
                                    : voiceTrackValue
                                } // need to show new upload recording to view
                                isView={isView}
                                item={item}
                                bem={bem}
                                process={process}
                                isShowInProduction={isShowInProduction}
                              />
                            )}
                          </Col>
                        ) : (
                          <>
                            <Col xs={isShowRecordingWithProducer ? 7 : 12}>
                              <div className={bem.e("vo-track-empty")}>
                                <FormattedMessage id="process > title in production" />
                              </div>
                            </Col>
                            {isShowRecordingWithProducerForView && (
                              <Col xs={5}>
                                <VoiceTrackProductionValue
                                  recordingWithProducer={recordingWithProducer}
                                  bem={bem}
                                />
                              </Col>
                            )}
                          </>
                        )}
                      </Row>
                    )}
                  </React.Fragment>
                );
              } else {
                return (
                  <Row>
                    <Col xs={7}>
                      <div className={bem.e("vo-track-empty")}>
                        <FormattedMessage id="process > title in production" />
                      </div>
                    </Col>
                    {isShowRecordingWithProducerForView && (
                      <Col xs={5}>
                        <VoiceTrackProductionValue
                          recordingWithProducer={recordingWithProducer}
                          bem={bem}
                        />
                      </Col>
                    )}
                  </Row>
                );
              }
            }}
          </PrivilegedComponent>
        </div>
      </Col>
    </Row>
  );
};
const renderAssets = ({ spot }) => {
  if (isEmpty(get(spot, `files`, []))) {
    return (
      <span className="none-placeholder">
        <FormattedMessage id="process > none" />
      </span>
    );
  }
  const fileActions = ["view", "download"];

  return (
    <div className="view-files-component">
      <div className="view-only">
        {get(spot, `files`, []).map((file, index) => (
          <FileView key={index} file={file} actions={fileActions} />
        ))}
      </div>
    </div>
  );
};
const OrderInfo = ({ process, roleVoiceTalentUsers }) => {
  const spot = find(
    get(process, "data.fields.spot_info.value", []),
    (spot) => spot.key === process.key
  );
  return (
    <div className={bem.e("order-info")}>
      <Row>
        <Col className={bem.e("col-field")}>
          {get(spot, "voice_over_instructions", "") ? (
            <TextareaAutosize
              value={get(spot, "voice_over_instructions", "")}
              className="text-area-view"
              disabled
            />
          ) : (
            <span className={bem.e("order-view-value")}>
              <FormattedMessage id="process > none" />
            </span>
          )}
        </Col>
      </Row>
      <Row>
        <Col className={bem.e("col-field")}>
          <Label className="form-label">
            <FormattedMessage id="process > assignment" />
          </Label>
          <div className={bem.e("assignment-users")}>
            {orderBy(roleVoiceTalentUsers, ["index"], "asc").map((voice) => {
              const voIndex = get(voice, "index", 0);

              return (
                <div className={bem.e("assign-vo-name")}>
                  {voice && voice.label ? (
                    voice.label
                  ) : (
                    <>
                      <FormattedMessage id="process > vo" />
                      {voIndex + 1} - {get(voice, "name", "")}
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </Col>
      </Row>
      <Row>
        <Col className={bem.e("col-field")}>
          <Label className="form-label">
            <FormattedMessage id="process > field assets" />
          </Label>
          {renderAssets({
            spot,
          })}
        </Col>
      </Row>
    </div>
  );
};
const VoiceTracks = (props) => {
  const {
    process,
    user,
    template,
    item,
    mode,
    valueChanged,
    validationError,
    formValueChanged,
    setShouldBlockNavigation,
    step,
    onDeleteMessages,
    isLoading,
    auth,
  } = props;
  const roleProducers = get(
    process,
    "data.fields.role_producer.value.users",
    []
  ).map((producer) => producer.id);
  const roleVoiceTalentUsers = get(
    process,
    "data.fields.role_voice_talent.value.users",
    []
  );
  //.filter((voice) => !includes(roleProducers, voice.id));
  const voiceTracks = get(process, `data.fields.voice_tracks.value`, []);
  const voiceFeedback = get(process, `data.fields.voice_feedback.value`, []);
  if (isEmpty(voiceTracks) && isEmpty(roleVoiceTalentUsers)) {
    return (
      <div className={bem.b()}>
        <OrderInfo
          process={process}
          roleVoiceTalentUsers={roleVoiceTalentUsers}
        />
        <div className={bem.e("empty-producer-to-voice")}>
          <FormattedMessage id="process > producer to voice" />
        </div>
      </div>
    );
  }

  return (
    <div className={bem.b()}>
      <OrderInfo
        process={process}
        roleVoiceTalentUsers={roleVoiceTalentUsers}
      />
      {orderBy(roleVoiceTalentUsers, ["index"], "asc").map((voice, index) => (
        <VoiceItem
          key={index}
          voice={voice}
          item={item}
          isAssignedAsProducer={includes(roleProducers, voice.id)}
          isView={mode === "view"}
          template={template}
          user={user}
          bem={bem}
          valueChanged={valueChanged}
          voiceTracks={voiceTracks}
          validationError={validationError}
          formValueChanged={formValueChanged}
          setShouldBlockNavigation={setShouldBlockNavigation}
          process={process}
          step={step}
          voiceFeedback={voiceFeedback}
          onDeleteMessages={onDeleteMessages}
          auth={auth}
        />
      ))}
      <Spinner isLoading={isLoading} />
    </div>
  );
};
export default VoiceTracks;
