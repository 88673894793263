import OrderDetails from "./orderDetails";
import RecordScript from "./recordScript";
import Continuity from "./continuity";
import DubScript from "./dubScript";
import AirCheck from "./airCheck";
import Log from "./log";
const steps = {
  order_details: OrderDetails,
  continuity: Continuity,
  record_script: RecordScript,
  dub_script: DubScript,
  air_check: AirCheck,
  log: Log,
};
export default steps;
