import React from "react";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import { FaSortDesc, FaSortAsc } from "react-icons/lib/fa";
import { Table } from "reactstrap";
import useInfiniteScroll from "utils/useInfiniteScroll";
import NoItemsFound from "components/NoItemsFound";
import { get, map } from "lodash";
import {
  DownIcon,
  ApproveIcon,
  UpIcon,
  RequiredIcon,
} from "components/CustomIcons";
import { PhoneNumberFormat } from "utils/config";
import NumberFormat from "react-number-format";
import ExpandDetails from "../ExpandDetails";
import Checkbox from "components/Checkbox";
function RenderItem(props) {
  const {
    item,
    onView,
    bem,
    selectedItems,
    setSelectedItems,
    selectedItem,
    setSelectedItem,
    year,
    allStations,
  } = props;
  const onClickView = () => onView(item);
  const isOpenDetails = selectedItem && selectedItem._id === item._id;
  const winnerName = `${item._source.first_name} ${item._source.last_name}`;
  const total = Number(item._source.total_prize_value);
  const isDisabledDetails = get(item, "_source.prizes.length", 0) === 0;
  const socialSecurityNo = item._source.social_security_no
    ? item._source.social_security_no.substr(-4)
    : "";
  return (
    <React.Fragment>
      <tr key={item._id}>
        {/* checkbox */}
        <td
          className={classnames(
            bem.e("first-col"),
            "align-middle",
            "checkbox-col"
          )}
        >
          <Checkbox
            checked={selectedItems && selectedItems[item._id]}
            onChange={() => {
              setSelectedItems({
                ...selectedItems,
                [item._id]:
                  selectedItems && selectedItems[item._id] ? false : true,
              });
            }}
            checkStyle={{
              color: "#fff",
              width: "14",
              height: "13",
            }}
          />
        </td>
        {/* year */}
        <td className={"align-middle year-col"} onClick={onClickView}>
          {year}
        </td>
        {/* winner */}
        <td
          className={classnames("align-middle", bem.e("winner-text"))}
          onClick={onClickView}
        >
          {winnerName} {socialSecurityNo ? `(...${socialSecurityNo})` : null}
          {isOpenDetails && (
            <span className={bem.e("winner-phone-email-text")}>
              {item._source.phone && (
                <span>
                  <NumberFormat
                    value={item._source.phone}
                    displayType={"text"}
                    format={PhoneNumberFormat}
                  />
                </span>
              )}
              {item._source.email && <span>{item._source.email}</span>}
            </span>
          )}
        </td>
        {/* signed w-9 */}
        <td
          className={classnames("align-middle", "signed-col")}
          onClick={onClickView}
        >
          <div className={bem.e("signed-status")}>
            <span className={bem.e("signed-icon")}>
              {item._source.w9_signed ? <ApproveIcon /> : <RequiredIcon />}
            </span>
            {item._source.w9_signed ? (
              `${year} W-9`
            ) : (
              <FormattedMessage id="winners > required" />
            )}
          </div>
        </td>
        {/* cash */}
        <td
          className={classnames(
            "align-middle",
            bem.e("text-center"),
            "cash-col"
          )}
          onClick={onClickView}
        >
          ${item._source.total_cash}
        </td>
        {/* non cash */}
        <td
          className={classnames(
            "align-middle",
            bem.e("text-center"),
            "non-cash-col"
          )}
          onClick={onClickView}
        >
          ${item._source.total_non_cash}
        </td>
        {/* total */}
        <td
          className={classnames(
            "align-middle",
            bem.e("text-center"),
            "total-col"
          )}
          onClick={onClickView}
        >
          <strong>${total}</strong>
        </td>
        {/* action */}
        <td
          className={classnames(
            "align-middle action-col",
            bem.e("text-center"),
            {
              [bem.e("button-disabled")]: isDisabledDetails,
            }
          )}
          onClick={() => {
            if (isDisabledDetails) return;
            setSelectedItem(isOpenDetails ? null : item);
          }}
        >
          <span>
            {isOpenDetails ? (
              <UpIcon width={20} height={15} />
            ) : (
              <DownIcon
                color={isDisabledDetails ? "#D3E5F1" : "#795afa"}
                width={20}
                height={15}
              />
            )}
          </span>
        </td>
      </tr>
      {isOpenDetails && (
        <tr className={bem.e("tr-expand")}>
          <td colSpan={8}>
            <ExpandDetails item={item} bem={bem} allStations={allStations} />
          </td>
        </tr>
      )}
    </React.Fragment>
  );
}
const View = ({
  actionSort,
  sort,
  items,
  bem,
  onView,
  isLoading,
  handleLoadMore,
  hasMoreItems,
  selectedItems,
  setSelectedItems,
  selectedItem,
  setSelectedItem,
  isShowGuide,
  selectedYear,
  info,
}) => {
  const allStations = get(info, "stations", []);
  useInfiniteScroll({
    useWindow: false,
    elementId: "cr-app-main",
    onLoadMore: handleLoadMore,
    shouldLoadMore: hasMoreItems,
  });
  const currentSelectedItems = Object.keys(selectedItems).filter((id) =>
    get(selectedItems, id, false)
  );
  const isSelectedAll =
    currentSelectedItems.length > 0 &&
    currentSelectedItems.length === items.length;

  return (
    <div className={classnames(bem.e("table-report-winners"))}>
      <Table className={bem.e("table")}>
        <thead>
          <tr>
            <th className={bem.e("table-first-head")}>
              {items.length > 0 ? (
                <Checkbox
                  checked={isSelectedAll}
                  onChange={() => {
                    let allSelectedItems = {};
                    if (!isSelectedAll)
                      items.forEach((item) => {
                        allSelectedItems = {
                          ...allSelectedItems,
                          [item._id]: true,
                        };
                      });
                    setSelectedItems(allSelectedItems);
                  }}
                  checkStyle={{
                    color: "#fff",
                    width: "14",
                    height: "13",
                  }}
                />
              ) : null}
            </th>
            <th>
              <FormattedMessage id="winners > year" />
            </th>
            <th onClick={() => actionSort("winner")} className="can-click">
              <div className={bem.e("item-sortable")}>
                <FormattedMessage id="winners > winner" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        get(sort, "id") === "winner" && !get(sort, "desc"),
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        get(sort, "id") === "winner" && get(sort, "desc"),
                    })}
                  />
                </div>
              </div>
            </th>
            <th onClick={() => actionSort("w9_status")} className="can-click">
              <div className={classnames(bem.e("item-sortable"))}>
                <FormattedMessage id="winners > signed w-9" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        get(sort, "id") === "w9_status" && !get(sort, "desc"),
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        get(sort, "id") === "w9_status" && get(sort, "desc"),
                    })}
                  />
                </div>
              </div>
            </th>
            <th onClick={() => actionSort("total_cash")} className="can-click">
              <div
                className={classnames(
                  bem.e("item-sortable"),
                  bem.e("item-center")
                )}
              >
                <FormattedMessage id="winners > cash" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        get(sort, "id") === "total_cash" && !get(sort, "desc"),
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        get(sort, "id") === "total_cash" && get(sort, "desc"),
                    })}
                  />
                </div>
              </div>
            </th>
            <th
              onClick={() => actionSort("total_non_cash")}
              className="can-click"
            >
              <div
                className={classnames(
                  bem.e("item-sortable"),
                  bem.e("item-center")
                )}
              >
                <FormattedMessage id="winners > non-cash" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        get(sort, "id") === "total_non_cash" &&
                        !get(sort, "desc"),
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        get(sort, "id") === "total_non_cash" &&
                        get(sort, "desc"),
                    })}
                  />
                </div>
              </div>
            </th>
            <th
              onClick={() => actionSort("total_prize_value")}
              className="can-click"
            >
              <div
                className={classnames(
                  bem.e("item-sortable"),
                  bem.e("item-center")
                )}
              >
                <FormattedMessage id="winners > total" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        get(sort, "id") === "total_prize_value" &&
                        !get(sort, "desc"),
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        get(sort, "id") === "total_prize_value" &&
                        get(sort, "desc"),
                    })}
                  />
                </div>
              </div>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {items.length === 0 && !isLoading ? (
            <tr>
              <td colSpan={8}>
                {isShowGuide ? (
                  <div className={bem.e("empty-entities")}>
                    <FormattedMessage id="winners > empty entities" />
                  </div>
                ) : (
                  <NoItemsFound />
                )}
              </td>
            </tr>
          ) : (
            map(items, (item) => {
              return (
                <RenderItem
                  key={item._id}
                  item={item}
                  onView={onView}
                  bem={bem}
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  setSelectedItem={setSelectedItem}
                  selectedItem={selectedItem}
                  year={selectedYear}
                  allStations={allStations}
                />
              );
            })
          )}
        </tbody>
      </Table>
    </div>
  );
};
export default View;
