import React, { useRef, useCallback } from "react";
import bn from "utils/bemnames";
import TextInput from "components/TextInput";
import { trim } from "lodash";
// import useOutsideClick from "utils/useOutsideClick";
import { Button } from "reactstrap";
import { CloseIcon, SendMailIcon } from "components/CustomIcons";
import classNames from "classnames";
const bem = bn.create("review-comment");
const ReviewComment = (props) => {
  let { comment } = props;
  const submitMessage = useCallback(() => {
    let trimMessage = trim(comment);
    if (trimMessage) props.onSubmit(trimMessage);
  }, [comment]);
  const ref = useRef();
  // useOutsideClick(ref, (event) => {
  //   const { path } = event;
  //   const isClickedActions =
  //     path &&
  //     filter(Array.from(path), (item) => {
  //       return (
  //         item &&
  //         item.classList &&
  //         item.classList.contains("ignore-click-outside")
  //       );
  //     }).length > 0;
  //   if (isClickedActions) return;
  //   props.onClose();
  // });
  return (
    <div className={bem.b()} ref={ref}>
      <div className={classNames(bem.e("wrapper"), "ignore-click-outside")}>
        <TextInput
          type="textarea"
          placeholder={props.intl.formatMessage({
            id: "process > give us your feedback",
          })}
          value={comment}
          onChange={({ target }) => {
            props.setComment(target.value);
          }}
          onKeyDown={(e) => {
            const { key } = e;
            if (key === "Enter") {
              e.preventDefault();
              e.stopPropagation();
              submitMessage();
            }
          }}
        />
        <span onClick={props.onClose} className={bem.e("close")}>
          <CloseIcon />
        </span>
        <Button
          className={bem.e("submit")}
          color=""
          disabled={!props.comment}
          onClick={() => submitMessage()}
        >
          <SendMailIcon />
        </Button>
      </div>
    </div>
  );
};
export default ReviewComment;
