import * as Const from "utils/constants";
import mimeTypes from "components/MimeTypes";
import Api from "utils/api";

import { isFunction } from "lodash";
export const renameFile = (file, cb) => async () => {
  try {
    const response = await Api.doCall(Const.FILE_RENAME(), "PUT", file);
    if (response.status === 200) {
      if (isFunction(cb)) cb(response.data);
    } else {
      if (isFunction(cb)) cb(false);
    }
  } catch (error) {
    return false;
  }
};
export const deleteFile = (file) => async () => {
  try {
    const { status, path } = await Api.doCall(Const.FILE_DELETE(), "DELETE", {
      file_path: file.path,
    });
    if (status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
export const uploadFile = (values) => async () => {
  try {
    if (values.file.push) values.file = values.file[0];
    const formData = new FormData();
    if (values.isBlob) {
      formData.append("file_name", values.file, values.fileName);
    } else {
      formData.append("file_name", values.file);
    }
    if (values.duration) formData.append("duration", values.duration);

    let { data, status } = await Api.doCall(
      Const.FILE_UPLOADS(),
      "POST",
      formData
    );

    if (status === 200) {
      const path = data.path.replace(/^https:\/\//i, "http://");
      const mime = mimeTypes.lookup(path);
      const name = data.name;
      const type = data.type;
      return {
        path: data.path,
        mime,
        name,
        type,
      };
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const uploadFiles = (files = []) => async () => {
  try {
    const formData = new FormData();
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      formData.append("file_name[]", file);
    }
    let { data, status } = await Api.doCall(
      Const.FILES_UPLOADS(),
      "POST",
      formData
    );
    if (status === 200 && data.files instanceof Array) {
      let items = [];
      for (let index = 0; index < data.files.length; index++) {
        const item = data.files[index];
        const path = item.url.replace(/^https:\/\//i, "http://");
        // const mime = mimeTypes.lookup(item.path);
        items.push({
          path: item.url,
          type: item.type,
          name: item.name,
        });
      }
      return items;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const downloadFiles = (files = [], cb) => async () => {
  try {
    let { data, status } = await Api.doCall(Const.FILES_DOWNLOADS(), "POST", {
      files,
    });
    if (status === 200) {
      if (isFunction(cb)) cb(data);
    } else {
      if (isFunction(cb)) cb(false);
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};
