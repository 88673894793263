import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import bn from "utils/bemnames";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CloseIcon,
} from "components/CustomIcons";
import { FormattedMessage } from "react-intl";
import { debounce, get } from "lodash";
import Slider from "react-slick";
import SystemTemplatePreview from "components/SystemTemplates/PreviewView";
import classNames from "classnames";
import SystemFormPreview from "components/SystemForms/PreviewForm/FormContent";
const bem = bn.create("preview-fulfillment-automation-modal");

const NextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowRightIcon color="#829FB1" />
    </div>
  );
};

const PrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowLeftIcon color="#829FB1" />
    </div>
  );
};

const mediaSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

function getFormsOrdered({
  selectedPreview,
  currentServer,
  currentStation,
  intl,
}) {
  if (!selectedPreview) return [];
  let forms = [];
  const settings = get(selectedPreview, "settings", {});
  let formConfirmation,
    fulfillmentForm,
    winnerAnnouncement,
    prizeReleaseForm,
    prizeFulfillmentForm;
  switch (selectedPreview.type) {
    case "full_email_automation_no_verification":
      winnerAnnouncement = settings.winner_announcement
        ? {
            ...settings.winner_announcement,
            type: "receives",
            contentClass: bem.e("system-template-preview"),
            previewComponent: (
              <SystemTemplatePreview
                data={settings.winner_announcement}
                bem={bem}
              />
            ),
            onClick: () => {
              const { location } = window;
              const url = `${location.origin}/system-templates?tab=winner_notification&station=${currentStation.key}&form=${settings.winner_announcement.id}`;
              window.open(url, "_blank").focus();
            },
          }
        : null;
      if (winnerAnnouncement) forms.push(winnerAnnouncement);
      fulfillmentForm = settings.fulfillment_form_template
        ? {
            ...settings.fulfillment_form_template,
            type: "completes",
            contentClass: bem.e("system-form-preview"),
            previewComponent: (
              <SystemFormPreview
                systemForm={{
                  _source: settings.fulfillment_form_template,
                }}
                intl={intl}
                onClose={() => {}}
                currentStation={currentStation}
                currentServer={currentServer}
                isConsoleForm={false}
                isCommonForm={false}
                isFulfillment={true}
                isShowMobile={false}
              />
            ),
            onClick: () => {
              const { location } = window;
              const url = `${location.origin}/form-manager?tab=promotion&station=${currentStation.key}&form=${settings.fulfillment_form_template.id}`;
              window.open(url, "_blank").focus();
            },
          }
        : null;
      if (fulfillmentForm) forms.push(fulfillmentForm);
      prizeReleaseForm = settings.prize_release_template
        ? {
            ...settings.prize_release_template,
            type: "receives",
            contentClass: bem.e("system-template-preview"),
            previewComponent: (
              <SystemTemplatePreview
                data={settings.prize_release_template}
                bem={bem}
              />
            ),
            onClick: () => {
              const { location } = window;
              const url = `${location.origin}/system-templates?tab=release_form&station=${currentStation.key}&form=${settings.prize_release_template.id}`;
              window.open(url, "_blank").focus();
            },
          }
        : null;
      if (prizeReleaseForm) forms.push(prizeReleaseForm);

      prizeFulfillmentForm = settings.prize_fulfillment_template
        ? {
            ...settings.prize_fulfillment_template,
            type: "receives",
            contentClass: bem.e("system-template-preview"),
            previewComponent: (
              <SystemTemplatePreview
                data={settings.prize_fulfillment_template}
                bem={bem}
              />
            ),
            onClick: () => {
              const { location } = window;
              const url = `${location.origin}/system-templates?tab=winner_notification&station=${currentStation.key}&form=${settings.prize_fulfillment_template.id}`;
              window.open(url, "_blank").focus();
            },
          }
        : null;
      if (prizeFulfillmentForm) forms.push(prizeFulfillmentForm);
      break;
    case "full_email_automation_with_verification":
      winnerAnnouncement = settings.winner_announcement
        ? {
            ...settings.winner_announcement,
            type: "receives",
            contentClass: bem.e("system-template-preview"),
            previewComponent: (
              <SystemTemplatePreview
                data={settings.winner_announcement}
                bem={bem}
              />
            ),
            onClick: () => {
              const { location } = window;
              const url = `${location.origin}/system-templates?tab=winner_notification&station=${currentStation.key}&form=${settings.winner_announcement.id}`;
              window.open(url, "_blank").focus();
            },
          }
        : null;
      if (winnerAnnouncement) forms.push(winnerAnnouncement);
      fulfillmentForm = settings.fulfillment_form_template
        ? {
            ...settings.fulfillment_form_template,
            type: "completes",
            contentClass: bem.e("system-form-preview"),
            previewComponent: (
              <SystemFormPreview
                systemForm={{
                  _source: settings.fulfillment_form_template,
                }}
                intl={intl}
                onClose={() => {}}
                currentStation={currentStation}
                currentServer={currentServer}
                isConsoleForm={false}
                isCommonForm={false}
                isFulfillment={true}
                isShowMobile={false}
              />
            ),
            onClick: () => {
              const { location } = window;
              const url = `${location.origin}/form-manager?tab=promotion&station=${currentStation.key}&form=${settings.fulfillment_form_template.id}`;
              window.open(url, "_blank").focus();
            },
          }
        : null;
      if (fulfillmentForm) forms.push(fulfillmentForm);

      formConfirmation = settings.form_confirmation
        ? {
            ...settings.form_confirmation,
            type: "receives",
            contentClass: bem.e("system-template-preview"),
            previewComponent: (
              <SystemTemplatePreview
                data={settings.form_confirmation}
                bem={bem}
              />
            ),
            onClick: () => {
              const { location } = window;
              const url = `${location.origin}/system-templates?tab=winner_notification&station=${currentStation.key}&form=${settings.form_confirmation.id}`;
              window.open(url, "_blank").focus();
            },
          }
        : null;
      if (formConfirmation) forms.push(formConfirmation);
      prizeFulfillmentForm = settings.prize_fulfillment_template
        ? {
            ...settings.prize_fulfillment_template,
            type: "completes",
            contentClass: bem.e("system-template-preview"),
            previewComponent: (
              <SystemTemplatePreview
                data={settings.prize_fulfillment_template}
                bem={bem}
              />
            ),
            onClick: () => {
              const { location } = window;
              const url = `${location.origin}/system-templates?tab=winner_notification&station=${currentStation.key}&form=${settings.prize_fulfillment_template.id}`;
              window.open(url, "_blank").focus();
            },
          }
        : null;
      if (prizeFulfillmentForm) forms.push(prizeFulfillmentForm);

      prizeReleaseForm = settings.prize_release_template
        ? {
            ...settings.prize_release_template,
            type: "receives",
            contentClass: bem.e("system-template-preview"),
            previewComponent: (
              <SystemTemplatePreview
                data={settings.prize_release_template}
                bem={bem}
              />
            ),
            onClick: () => {
              const { location } = window;
              const url = `${location.origin}/system-templates?tab=release_form&station=${currentStation.key}&form=${settings.prize_release_template.id}`;
              window.open(url, "_blank").focus();
            },
          }
        : null;
      if (prizeReleaseForm) forms.push(prizeReleaseForm);

      break;
    case "release_form_and_prize_fulfillment_email_no_verification":
      winnerAnnouncement = settings.winner_announcement
        ? {
            ...settings.winner_announcement,
            type: "receives",
            contentClass: bem.e("system-template-preview"),
            previewComponent: (
              <SystemTemplatePreview
                data={settings.winner_announcement}
                bem={bem}
              />
            ),
            onClick: () => {
              const { location } = window;
              const url = `${location.origin}/system-templates?tab=winner_notification&station=${currentStation.key}&form=${settings.winner_announcement.id}`;
              window.open(url, "_blank").focus();
            },
          }
        : null;
      if (winnerAnnouncement) forms.push(winnerAnnouncement);
      fulfillmentForm = settings.fulfillment_form_template
        ? {
            ...settings.fulfillment_form_template,
            type: "completes",
            contentClass: bem.e("system-form-preview"),
            previewComponent: (
              <SystemFormPreview
                systemForm={{
                  _source: settings.fulfillment_form_template,
                }}
                intl={intl}
                onClose={() => {}}
                currentStation={currentStation}
                currentServer={currentServer}
                isConsoleForm={false}
                isCommonForm={false}
                isFulfillment={true}
                isShowMobile={false}
              />
            ),
            onClick: () => {
              const { location } = window;
              const url = `${location.origin}/form-manager?tab=promotion&station=${currentStation.key}&form=${settings.fulfillment_form_template.id}`;
              window.open(url, "_blank").focus();
            },
          }
        : null;
      if (fulfillmentForm) forms.push(fulfillmentForm);
      formConfirmation = settings.form_confirmation
        ? {
            ...settings.form_confirmation,
            type: "receives",
            contentClass: bem.e("system-template-preview"),
            previewComponent: (
              <SystemTemplatePreview
                data={settings.form_confirmation}
                bem={bem}
              />
            ),
            onClick: () => {
              const { location } = window;
              const url = `${location.origin}/system-templates?tab=winner_notification&station=${currentStation.key}&form=${settings.form_confirmation.id}`;
              window.open(url, "_blank").focus();
            },
          }
        : null;
      if (formConfirmation) forms.push(formConfirmation);
      break;
    case "release_form_and_prize_fulfillment_email_with_verification":
      winnerAnnouncement = settings.winner_announcement
        ? {
            ...settings.winner_announcement,
            type: "receives",
            contentClass: bem.e("system-template-preview"),
            previewComponent: (
              <SystemTemplatePreview
                data={settings.winner_announcement}
                bem={bem}
              />
            ),
            onClick: () => {
              const { location } = window;
              const url = `${location.origin}/system-templates?tab=winner_notification&station=${currentStation.key}&form=${settings.winner_announcement.id}`;
              window.open(url, "_blank").focus();
            },
          }
        : null;
      if (winnerAnnouncement) forms.push(winnerAnnouncement);

      formConfirmation = settings.form_confirmation
        ? {
            ...settings.form_confirmation,
            type: "receives",
            contentClass: bem.e("system-template-preview"),
            previewComponent: (
              <SystemTemplatePreview
                data={settings.form_confirmation}
                bem={bem}
              />
            ),
            onClick: () => {
              const { location } = window;
              const url = `${location.origin}/system-templates?tab=winner_notification&station=${currentStation.key}&form=${settings.form_confirmation.id}`;
              window.open(url, "_blank").focus();
            },
          }
        : null;
      if (formConfirmation) forms.push(formConfirmation);
      fulfillmentForm = settings.fulfillment_form_template
        ? {
            ...settings.fulfillment_form_template,
            type: "completes",
            contentClass: bem.e("system-form-preview"),
            previewComponent: (
              <SystemFormPreview
                systemForm={{
                  _source: settings.fulfillment_form_template,
                }}
                intl={intl}
                onClose={() => {}}
                currentStation={currentStation}
                currentServer={currentServer}
                isConsoleForm={false}
                isCommonForm={false}
                isFulfillment={true}
                isShowMobile={false}
              />
            ),
            onClick: () => {
              const { location } = window;
              const url = `${location.origin}/form-manager?tab=promotion&station=${currentStation.key}&form=${settings.fulfillment_form_template.id}`;
              window.open(url, "_blank").focus();
            },
          }
        : null;
      if (fulfillmentForm) forms.push(fulfillmentForm);
      break;

    default:
      break;
  }
  return forms;
}
const PreviewFulfillmentAutomationModal = (props) => {
  const {
    isOpen,
    onToggle,
    fulfillmentSetting,
    currentServer,
    currentStation,
    intl,
  } = props;
  const mainSlick = useRef(null);
  const debounceActiveSlide = React.useCallback(
    debounce(() => {
      mainSlick.current.slickGoTo(0);
    }, 200),
    []
  );
  const forms = getFormsOrdered({
    selectedPreview: fulfillmentSetting,
    currentServer,
    currentStation,
    intl,
  });

  return (
    <Modal isOpen={isOpen} className={bem.b()} size="lg">
      <ModalHeader toggle={onToggle} className={bem.e("header")}>
        <FormattedMessage id="station settings > preview" />
        <div className={bem.e("close")} onClick={onToggle}>
          <CloseIcon color={"#C2D4E0"} />
        </div>
      </ModalHeader>
      <ModalBody>
        <Slider
          ref={mainSlick}
          {...mediaSettings}
          onInit={() => {
            debounceActiveSlide();
          }}
        >
          {forms.map((item, index) => (
            <div key={index} className={bem.e("preview-content")}>
              <div className={bem.e("preview-head")}>
                <div className={bem.e("preview-completes")}>
                  <FormattedMessage
                    id={`station settings > winner ${item.type}`}
                  />
                  <span
                    className={bem.e("form-name")}
                    onClick={() => {
                      if (item.onClick) item.onClick();
                    }}
                  >
                    {item.name}
                  </span>
                </div>
                <div className={bem.e("notification-text")}>
                  <FormattedMessage id="station settings > notification" />
                  <strong>
                    {index + 1}/{forms.length}
                  </strong>
                </div>
              </div>
              <div className={classNames(bem.e("content"), item.contentClass)}>
                {item.previewComponent}
              </div>
            </div>
          ))}
        </Slider>
      </ModalBody>
    </Modal>
  );
};
PreviewFulfillmentAutomationModal.propTypes = {
  onToggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
PreviewFulfillmentAutomationModal.defaultProps = {
  isOpen: false,
  onToggle: () => {},
};
export default PreviewFulfillmentAutomationModal;
