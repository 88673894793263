import * as Const from "utils/constants";
import Api from "utils/api";
import history from "components/History";
// import { default_templates } from "utils/defaults";
import  defaultTemplates from "utils/DefaultTemplates";

import {
    TEMPLATE_CHANGED,
    SAVE_TEMPLATE_REQUEST, SAVE_TEMPLATE_SUCCESS, SAVE_TEMPLATE_ERROR, UPDATE_CURRENT_TEMPLATE,
    FETCH_TEMPLATE_REQUEST, FETCH_TEMPLATE_SUCCESS, FETCH_TEMPLATE_ERROR,
    DELETE_TEMPLATE_REQUEST, DELETE_TEMPLATE_SUCCESS, 
    // DELETE_TEMPLATE_ERROR, UPDATE_TEMPLATE_REQUEST, UPDATE_TEMPLATE_SUCCESS, 
    UPDATE_TEMPLATE_ERROR
} from "../types/templates";

export function updateCurrentTemplate(payload) {
    return {
        type: UPDATE_CURRENT_TEMPLATE,
        payload
    }
}


export const getTemplate = (id, info) => async dispatch => {
    let err = ''
    try {
        dispatch({ type: FETCH_TEMPLATE_REQUEST });
        // let response = await Api.doCall(Const.GET_TEMPLATE(id), "GET");
        let newDefaultTemplates = defaultTemplates;
        const template = newDefaultTemplates.find(t=>t._id===id);
        let response = {status:200, data: template._source}
        if (response.status === 200) {
            if (!response.data.error) {

              dispatch(fetchTemplateSuccess({id, data: response.data, info}))
                return
            }
            err = response.data.error
        }
    } catch (error) {
        err = error.toString()
    }
    dispatch(fetchTemplateError({ data: err }))
}

export const saveTemplate = (payload) => async dispatch => {
    try {
        dispatch({ type: SAVE_TEMPLATE_REQUEST })
        let response = await Api.doCall(Const.SAVE_TEMPLATE(), "PUT", payload);
        if (response.status === 200) {
            response.data = { ...response.data, ...payload }
            // if (payload.success_callback) payload.success_callback()
            dispatch(saveTemplateSuccess(response.data))
        } else {
            dispatch(saveTemplateError(response.error.toString()));
        }
    } catch (error) {
        debugger
        console.log(error);
        dispatch(saveTemplateError(error.toString()));
    }
}


export const deleteTemplate = id => async dispatch => {
    let err = ''
    try {
        dispatch({ type: DELETE_TEMPLATE_REQUEST });
        let response = await Api.doCall(Const.GET_TEMPLATE(id), "DELETE");
        if (response.status === 200) {
            if (!response.data.error) {
                dispatch({ type: DELETE_TEMPLATE_SUCCESS, payload: id });
                history.push("/templates");
                return
            }
            err = response.data.error
        }
    } catch (error) {
        err = error.toString()
    }
    dispatch({ type: UPDATE_TEMPLATE_ERROR, payload: err });
}

export function templateChanged(payload) {
    return {
        type: TEMPLATE_CHANGED,
        payload
    };
}

export function saveTemplateSuccess(payload) {
    return {
        type: SAVE_TEMPLATE_SUCCESS,
        payload
    };
}

export function saveTemplateError(payload) {
    return {
        type: SAVE_TEMPLATE_ERROR,
        payload
    };
}

export function fetchTemplateSuccess(payload) {
    return {
        type: FETCH_TEMPLATE_SUCCESS,
        payload
    }
}

export function fetchTemplateError(payload) {
    return {
        type: FETCH_TEMPLATE_ERROR,
        payload
    }
}
