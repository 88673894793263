import { connect } from "react-redux";
import { compose, withHandlers, withState, lifecycle } from "recompose";
import { get, find, values } from "lodash";
import {
  getClients,
  reassignClients,
  deleteClient,
} from "store/actions/clients";
import { injectIntl } from "react-intl";
import ClientManagerList from "./view";
import { PRIVILEGES } from "utils/constants";
import { validatePrivileges } from "utils/helpers";
import { pageSize } from "utils/config";
const Enhanced = compose(
  connect(
    (state, props) => {
      return {
        user: state.auth.user,
        _users: get(state, "auth.info.users", []),
        users: get(state, "auth.info.users", []).filter(
          (user) => user.is_active
        ),
        clients: get(state, "clients.clients", {}),
        isLoading:
          get(state, "clients.clients.loading", false) ||
          get(state, "clients.deleteClient.loading", false),
        dateFormatByServer: get(
          state,
          "servers.currentServer.data.date_format",
          "MM/DD/YYYY"
        ),
        ...props,
      };
    },
    {
      getClients,
      reassignClients,
      deleteClient,
    }
  ),
  withState("searchText", "setSearchText", ""),
  withState("filtered", "setFiltered", []),
  withState("sorted", "setSorted", [{ id: "created_ts", desc: true }]),
  withState("selectedUser", "setSelectedUser", null),
  withState(
    "isOpenConfirmationSuccessModal",
    "setIsOpenConfirmationSuccessModal",
    false
  ),
  withState("isOpenConfirmationModal", "setIsOpenConfirmationModal", false),
  withState("isOpenClientReassignModal", "setIsOpenClientReassignModal", false),
  withState(
    "isOpenDeleteConfirmationModal",
    "setIsOpenDeleteConfirmationModal",
    false
  ),
  withState("selectedClients", "setSelectedClients", null),
  withState("selectedClient", "setSelectedClient", null),
  withState("clientToDelete", "setClientToDelete", null),
  withState("action", "setAction", null),
  withHandlers({
    getClientsList: ({ getClients, sorted, filtered }) => async (
      params = {}
    ) => {
      getClients({
        pageSize: pageSize,
        page: 1,
        sorted,
        filtered,
        ...params, //override
      });
    },
    getMoreClients: ({ getClients, sorted, filtered }) => (page) => {
      getClients({
        pageSize: pageSize,
        page: page + 1,
        sorted,
        filtered,
      });
    },
    onRequestDelete: ({
      setIsOpenDeleteConfirmationModal,
      setClientToDelete,
    }) => (client) => {
      console.log(client);
      setClientToDelete(client);
      setIsOpenDeleteConfirmationModal(true);
    },
    onConfirmDelete: ({
      clientToDelete,
      deleteClient,
      setClientToDelete,
    }) => () => {
      deleteClient(clientToDelete, () => {
        setClientToDelete(null);
      });
    },
  }),
  withHandlers({
    onReassign: ({
      selectedClient,
      selectedClients,
      selectedUser,
      action,
      setIsOpenConfirmationSuccessModal,
      setIsOpenConfirmationModal,
      setSelectedClients,
      setSelectedClient,
      reassignClients,
    }) => () => {
      let clientIds = [];
      if (action === "reassign") {
        clientIds = [selectedClient._id];
      } else if (action === "bulk") {
        clientIds = Object.keys(selectedClients).filter(
          (id) => selectedClients[id]
        );
      }
      reassignClients(
        {
          client_ids: clientIds,
          user: selectedUser,
        },
        () => {
          setIsOpenConfirmationModal(false);
          setIsOpenConfirmationSuccessModal(true);
          setSelectedClients(null);
          setSelectedClient(null);
        }
      );
    },
    getUsers: ({ users, _users }) => () => {
      return users
        .filter((user) => {
          const isValid = validatePrivileges({
            requires: {
              or: [PRIVILEGES.ADD_CLIENT, PRIVILEGES.EDIT_CLIENT],
            },
            privileges: values(user.privileges),
            user: user,
          });
          return isValid;
        })
        .map((user) => ({
          label: user.name,
          value: user.id,
          data: user,
        }));
    },
    onSort: ({ setSorted, sorted, getClientsList }) => (sort) => {
      let newSort = {};
      const sortedData = find(sorted, (item) => item.id === sort);
      if (sortedData) {
        newSort = {
          id: sort,
          desc: !sortedData.desc,
        };
      } else {
        newSort = {
          id: sort,
          desc: true,
        };
      }
      setSorted([newSort]);
      getClientsList({ sorted: [newSort] });
    },
    onSearch: ({ setFiltered, searchText, getClientsList }) => () => {
      const filtered = [
        {
          id: "q",
          value: searchText,
        },
      ];
      setFiltered(filtered);
      getClientsList({
        filtered,
      });
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.getClientsList();
    },
    componentDidUpdate() {
      if (get(this.props, "clients.is_dirty")) {
        this.props.getClientsList();
      }
    },
  })
)(ClientManagerList);
export default injectIntl(Enhanced);
