import styled from "styled-components";
const Form = styled.div`
	background-color: #fff;
	border-radius: 6px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
`;
const SectionTitle = styled.h6`
	border-bottom: 1px solid #EDF1F6;
	padding-bottom: 10px;
	margin-bottom: 0;
	padding: 0 47.76px;
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 29px;
  color: #334856;
  height: 79px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const SectionFields = styled.div`
	padding: 10px 49px;
	margin-top: 10px;
`;
const ShadowBox = styled.div`
	margin-bottom: 20px;
  background: #FFFFFF;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);
  border-radius: 6px;
`;

const Asterisk = styled.span`
	color: red !important;
	margin-left: 1px;
`;

const DropArea = styled.div`
	width: 100%;
	height: 100px;
	border: 2px dotted #444;
	border-radius: 5px;
	padding: 10px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
`;

const SpotBar = styled.div`
	background: #eceded;
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 2px solid #1d51b7;
	margin-bottom: 20px;
`;

const DivView = styled.div`
	line-height: 1.5;
	color: #212529;
`;
const ChevronWrapper = styled.div`
	float: right;
	cursor: pointer;
`;

export {
	Form,
	SectionTitle,
	SectionFields,
	ShadowBox,
	Asterisk,
	DropArea,
	SpotBar,
	DivView,
	ChevronWrapper,
};

