export const FETCH_ZETTA_SETTINGS_REQUEST = "FETCH_ZETTA_SETTINGS_REQUEST";
export const FETCH_ZETTA_SETTINGS_SUCCESS = "FETCH_ZETTA_SETTINGS_SUCCESS";
export const FETCH_ZETTA_SETTINGS_ERROR = "FETCH_ZETTA_SETTINGS_ERROR";

export const UPDATE_ZETTA_SETTINGS_REQUEST = "UPDATE_ZETTA_SETTINGS_REQUEST";
export const UPDATE_ZETTA_SETTINGS_SUCCESS = "UPDATE_ZETTA_SETTINGS_SUCCESS";
export const UPDATE_ZETTA_SETTINGS_ERROR = "UPDATE_ZETTA_SETTINGS_ERROR";

export const TOGGLE_ZETTA_ENABLE_REQUEST = "TOGGLE_ZETTA_ENABLE_REQUEST";
export const TOGGLE_ZETTA_ENABLE_SUCCESS = "TOGGLE_ZETTA_ENABLE_SUCCESS";
export const TOGGLE_ZETTA_ENABLE_ERROR = "TOGGLE_ZETTA_ENABLE_ERROR";

export const DELETE_ZETTA_SETTINGS_REQUEST = "DELETE_ZETTA_SETTINGS_REQUEST";
export const DELETE_ZETTA_SETTINGS_SUCCESS = "DELETE_ZETTA_SETTINGS_SUCCESS";
export const DELETE_ZETTA_SETTINGS_ERROR = "DELETE_ZETTA_SETTINGS_ERROR";
