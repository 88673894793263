import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { get, includes } from "lodash";
import {
  formValueChanged,
  saveFormValues,
  setActiveEditTab,
  getProcess,
  deleteProcess,
  archiveProcess,
  resetProcess,
  setActiveTab,
  unarchiveProcess,
} from "store/actions/processes";
import { compose, withState, withHandlers, lifecycle } from "recompose";
import FormView from "./formView";
import { setSidebarActive } from "store/actions/auth";
import history from "components/History";
import URL from "utils/urls";
import {
  releaseReservedSlots,
  resetCheckSchedulingAvailability,
} from "store/actions/programmings";
import { setSchedulingType } from "store/actions/auth";
import { getProductionSettings } from "store/actions/settings";
import ToastManager from "components/ToastManager";

const Enhanced = injectIntl(
  compose(
    connect(
      (state, props) => ({
        user: state.auth.user,
        info: state.auth.info,
        process: props.process,
        template: props.template,
        step: props.step,
        toggle: props.toggle,
        editMode: props.editMode,
        sidebarActive: state.auth.sidebar_active,
        isScheduleAvailabilityChecked: get(
          state,
          "programmings.scheduleAvailability.isChecked",
          false
        ),
        productionSettings: get(state, "settings.production_settings.data", {}),
        dateFormatByServer: get(
          state,
          "servers.currentServer.data.date_format",
          "MM/DD/YYYY"
        ),
      }),
      {
        setActiveTab,
        formValueChanged,
        saveFormValues,
        setActiveEditTab,
        getProcess,
        setSidebarActive,
        releaseReservedSlots,
        resetCheckSchedulingAvailability,
        setSchedulingType,
        deleteProcess,
        getProductionSettings,
        archiveProcess,
        resetProcess,
        unarchiveProcess,
      }
    ),
    withState("isShowClearForm", "setIsShowClearForm", false),
    withHandlers({
      callBackDismissBlockNavigation: ({
        getProcess,
        process,
        template,
        location,
        releaseReservedSlots,
        isScheduleAvailabilityChecked,
      }) => () => {
        const processKey = get(process, "data.fields.key.value");
        if (
          ["liners", "contest"].indexOf(get(template, "key")) &&
          isScheduleAvailabilityChecked
        ) {
          const orderStation = get(
            process,
            "data.fields.order_stations.value.0"
          );
          releaseReservedSlots({
            data: {
              station_id: orderStation,
              process_key: processKey,
            },
          });
        }
        if (get(location, "state.origin_process_key")) {
          history.replace(
            URL.VIEW_PROCESS({
              template_id: get(
                location,
                "state.origin_template_key",
                "basic_production"
              ),
              process_id: get(location, "state.origin_process_key"),
            })
          );
        } else if (processKey) {
          getProcess({
            template_id: get(template, "key"),
            process_id: processKey,
          });
        }
      },
    }),
    lifecycle({
      componentDidMount() {
        const { resetProcess } = this.props;
        if (
          this.props.process.is_dirty &&
          get(this.props.process, "data.key")
        ) {
          this.props.getProcess({
            template_id: get(this.props.template, "key"),
            process_id: get(this.props.process, "data.key"),
          });
        }
        if (
          !get(this.props.process, "data.fields.active.value", true) &&
          get(this.props.process, "data.fields.key.value", "")
        ) {
          const currentTasks = URL.PROCESSES({
            filter: "my_action_items",
          });
          ToastManager.show({
            message: this.props.intl.formatMessage({
              id: "process > error the order you are looking for is not found",
            }),
            level: "error",
          });
          const nextRoute = this.props.sidebarActive
            ? this.props.sidebarActive
            : currentTasks;
          setTimeout(function() {
            history.push(nextRoute);
            resetProcess();
          }, 1000);
        }
        const process = this.props.process;
        const orderStations = get(
          process,
          "data.fields.order_stations.value",
          []
        );
        if (orderStations.length === 1) {
          this.props.getProductionSettings({
            station_id: orderStations[0],
          });
        }
      },
      componentDidUpdate() {
        const beingEditedBy = get(
          this.props.process,
          "data.fields.being_edited_by.value",
          false
        );
        const user = this.props.user;

        let accessGrantedTo = get(user, "access_granted_to_users", []).map(
          (user) => user.id
        );
        let hasAccessFrom = get(user, "has_access_from_users", []).map(
          (user) => user.id
        );
        const isLocked =
          beingEditedBy &&
          beingEditedBy.id != user.id &&
          (includes(accessGrantedTo, beingEditedBy.id) ||
            includes(hasAccessFrom, beingEditedBy.id));

        if (
          !get(this.props.process, "data.fields.active.value", true) ||
          isLocked
        ) {
          const currentTasks = URL.PROCESSES({
            filter: "my_action_items",
          });
          const nextRoute = this.props.sidebarActive
            ? this.props.sidebarActive
            : currentTasks;
          setTimeout(function() {
            history.push(nextRoute);
          }, 1000);
        }
      },
    })
  )(FormView)
);
export default withRouter(Enhanced);
