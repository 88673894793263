import { FormattedMessage } from "react-intl";
import React from "react";
import timeOptions from "assets/data/time-options.json";
const PromotionEventDetails = {
  process_step_index: 1,
  key: "promotion_event_details",
  caption_color: "#CC1EE6",
  title: <FormattedMessage id="process > title promotion overview" />,
  description: null,
  step_roles: [1, 2, 3],
  step_viewers_roles: [1, 2, 3, 4, 7],
  step_editors_roles: [1, 2, 3, 4],
  step_viewers_privileges: ["add_promotion_event"],
  step_editors_privileges: ["add_promotion_event"],
  status: "initial",
  disabled_until_completed_steps: [],
  step_container: "none",
  no_edit_button: true,
  fields: [
    {
      component: "promotion_event_details",
    },
    {
      field: "client_name",
      title: <FormattedMessage id="process > field client name" />,
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        if (
          !(
            (process.fields.contract_value &&
              process.fields.contract_value.value) ||
            (process.fields.contract_start_date &&
              process.fields.contract_start_date.value) ||
            (process.fields.contract_end_date &&
              process.fields.contract_end_date.value) ||
            (process.fields.contract_client_email &&
              process.fields.contract_client_email.value) ||
            (process.fields.contract_client_nickname &&
              process.fields.contract_client_nickname.value) ||
            (process.fields.contract_client_phone &&
              process.fields.contract_client_phone.value) ||
            (process.fields.contract_client_contact &&
              process.fields.contract_client_contact.value) ||
            (process.fields.contract_stations &&
              process.fields.contract_stations.value &&
              process.fields.contract_stations.value.length)
          )
        )
          return true;
        return value.length > 1
          ? true
          : "Customer name must be at least 2 letters long";
      },
    },
    {
      field: "start_date",
      title: <FormattedMessage id="process > field start date" />,
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        if (value.length === 0) return "Please enter a date";
        if (isNaN(Date.parse(value))) return "Please enter a valid date";
        // if (moment(value).isBefore(moment())) return 'Date cannot be in the past';
        return true;
      },
    },
    {
      field: "recurring",
      title: <FormattedMessage id="process > field recurring" />,
    },
    {
      field: "repeats",
      title: <FormattedMessage id="process > field repeats" />,
      formatted: "number",
    },
    // {
    //   field: "end_date",
    //   title: <FormattedMessage id="process > field end date" />,
    //   mandatory: true,
    //   validation: function(step, item, process, template, user, value) {
    //     if (value.length === 0) return "Please enter a date";
    //     if (isNaN(Date.parse(value)))
    //       return "Please enter a valid date";
    //     if (
    //       process.fields.start_date &&
    //       !isNaN(Date.parse(process.fields.start_date.value)) &&
    //       Date.parse(value) <
    //         Date.parse(process.fields.start_date.value)
    //     )
    //       return "Please enter a date later than the start date";
    //     if (moment(value).isBefore(moment()))
    //       return "Date cannot be in the past";
    //     return true;
    //   }
    // },
    {
      field: "start_time",
      title: <FormattedMessage id="process > field start time" />,
      formatted: "time",
      mandatory: true,
      props: {
        width: "300",
        height: "55",
        allowNew: true,
        options: timeOptions,
      },
      validation: function(step, item, process, template, user, value) {
        return value ? true : "Please select a time";
      },
    },
    {
      field: "end_time",
      title: <FormattedMessage id="process > field end time" />,
      // mandatory: true,
      formatted: "time",
      props: {
        width: "300",
        height: "55",
        allowNew: true,
        options: timeOptions,
      },
      validation: function(step, item, process, template, user, value) {
        if (!value) return "Please select a time";
        if (
          process.fields.start_time &&
          process.fields.end_time &&
          Date.parse(process.fields.start_time.value) ===
            Date.parse(process.fields.end_time.value) &&
          Date.parse(value) <= Date.parse(process.fields.start_time.value)
        )
          return "Please enter a time later than the start time";

        return true;
      },
    },
    {
      field: "order_stations",
      title: <FormattedMessage id="process > field stations" />,
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        return value.length > 0
          ? true
          : "At least one station has to be selected";
      },
    },
    {
      field: "event_name",
      title: <FormattedMessage id="process > field event name" />,
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        return value ? true : "Please enter event name";
      },
    },
    {
      field: "event_type",
      title: <FormattedMessage id="process > field event type" />,
      props: {
        options: [
          {
            label: "Event type 1",
            value: "Event type 1",
          },
        ],
      },
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        return value ? true : "Please select a event type";
      },
    },
    {
      field: "event_description",
      title: <FormattedMessage id="process > field event description" />,
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        return value ? true : "Please enter event description";
      },
    },
    {
      field: "jock",
      title: <FormattedMessage id="process > field event jock" />,
      props: {
        options: [
          {
            label: "jock 1",
            value: "jock 1",
          },
        ],
      },
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        return value ? true : "Please select a jock";
      },
    },
    {
      field: "jock_instructions",
      title: <FormattedMessage id="process > field event jock instructions" />,
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        return value ? true : "Please enter jock instructions";
      },
    },
    {
      field: "on_premise_copy",
      title: (
        <FormattedMessage id="process > field event jock on premise copy" />
      ),
    },
    {
      field: "notes",
      title: <FormattedMessage id="process > field event jock notes" />,
    },
    {
      field: "venue_name",
      title: (
        <FormattedMessage id="process > field event location venue name" />
      ),
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        return value.length > 2
          ? true
          : "Venue name must be at least 3 letters long";
      },
    },
    {
      field: "location_information",
      title: (
        <FormattedMessage id="process > field event location information" />
      ),
      mandatory: false,
    },
    {
      field: "contact",
      title: <FormattedMessage id="process > field event location contact" />,
      mandatory: false,
    },
    {
      field: "phone",
      title: <FormattedMessage id="process > field event location phone" />,
      mandatory: false,
      formatted: "phone",
      //   validation: function (step, item, process, template, user, value) {
      //     return value.length > 0 &&
      //       value.match(new RegExp("^[0-9()-]{5,}$", "gi"))
      //       ? true
      //       : "Please enter a valid client phone number";
      //   }
    },
    {
      field: "staff_availability",
      title: "",
      mandatory: false,
    },
    {
      key: "save_button",
      text: <FormattedMessage id="process > button save" />,
      // "component": "button",
      on_click: {
        validate: true,
        save: true,
      },
      fields: [
        {
          key: "promotion_event_details_status",
          // "value": "submitted"
        },
        {
          key: "process_step",
          value: "done",
        },
      ],
      confirmation_dialog_props: {
        description: (
          <FormattedMessage id="process > description to submit promotions" />
        ),
        confirmTitle: <FormattedMessage id="process > button yes" />,
        cancelTitle: <FormattedMessage id="process > button no" />,
      },
      success_dialog_props: {
        title: <FormattedMessage id="process > title submitted" />,
        state: "success",
      },
    },
  ],
};
export default PromotionEventDetails;
