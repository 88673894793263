import React from "react";
import { get } from "lodash";
import classNames from "classnames";
import StationImage from "components/JockConsole/Common/StationImage";
import { FormattedMessage } from "react-intl";
export default function PreviewView(props) {
  const { bem, data } = props;
  const type = get(data, "type");
  const replaceTags = (script) => {
    if (!script) return null;
    const pNode = document.createElement("p");
    pNode.innerHTML = script;
    const tagNodes = pNode.querySelectorAll(`.tag`);
    const imageNodes = pNode.querySelectorAll(`.image`);

    if (tagNodes) {
      tagNodes.forEach((node) => {
        const tag = node.getAttribute("data-tag-label");
        if (tag) node.outerHTML = `<span class="replace-tag">[${tag}]</span>`;
      });
    }
    if (imageNodes) {
      imageNodes.forEach((node) => {
        const image = node.getAttribute("data-image-path");
        if (image)
          node.outerHTML = `<img class="replace-image" src="${image}" alt="${node.getAttribute(
            "data-image-name"
          )}" />`;
      });
    }
    return pNode.innerHTML;
  };
  const isShowEmailInfo = [
    "winner_notification",
    "rejection_notification",
  ].includes(type);
  const isShowStationInfo = [
    "winner_notification",
    "rejection_notification",
  ].includes(type);
  const subject = get(data, "name", "");
  const stationName = get(data, "currentStation.name", "");
  const stationEmail = get(
    data,
    "currentStation.data.contact_email",
    "station@station.com"
  );

  return (
    <div
      className={classNames(
        bem.e("preview-container"),
        bem.e(`preview_${type}`)
      )}
    >
      {isShowEmailInfo && (
        <div className={bem.e("preview-info-head")}>
          <div className={bem.e("info-item")}>
            <strong>
              <FormattedMessage id="system templates > to" />:
            </strong>
            {"{"}
            {<FormattedMessage id="system templates > winner email" />}
            {"}"}
          </div>
          <div className={bem.e("info-item")}>
            <strong>
              <FormattedMessage id="system templates > from" />:
            </strong>
            <span>{stationEmail}</span>
          </div>
          <div className={bem.e("info-item")}>
            <strong>
              <FormattedMessage id="system templates > subject" />:
            </strong>
            <span>{subject}</span>
          </div>
        </div>
      )}
      <div className={bem.e("preview-content")}>
        {isShowStationInfo && (
          <StationImage station={get(data, "currentStation.data", {})} />
        )}

        <div
          className={bem.e("preview-text")}
          dangerouslySetInnerHTML={{
            __html: replaceTags(get(data, "content")),
          }}
        />
      </div>
      {isShowEmailInfo && (
        <div className={bem.e("preview-bottom")}>
          <p>
            <FormattedMessage id="system templates > you are receiving this email because of you interaction with" />{" "}
            {stationName}.
          </p>
          {/* <p>
            <FormattedMessage id="system templates > click here to" />{" "}
            <strong>
              <FormattedMessage id="system templates > unsubscribe" />
            </strong>
          </p> */}
          <p>
            <FormattedMessage id="system templates > powered by" />{" "}
            <strong>PromoSuite Plus.</strong>
          </p>
        </div>
      )}
    </div>
  );
}
