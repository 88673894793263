import steps from "./steps";

const promotionRequest = {
  _index: "templates",
  _type: "template",
  _id: "promotion_request",
  _score: 1,
  _source: {
    key: "promotion_request",
    templates_name: "Promotion Request",
    templates_description: "Create a promotion request",
    priviliges: {},
    steps: steps,
    active: true,
  },
};
export default promotionRequest;
