import React from "react";
import { Redirect } from "react-router";

import PrivilegedComponent from "components/PrivilegedComponent";
import PageAdmin from "components/PageAdmin";
import ClockCreator from "components/ClockManager/ClockCreator";
import URL from "utils/urls";
import bn from "utils/bemnames";

const bem = bn.create("clock-creator");

const ClockCreatorPage = (props) => {
  const { intl } = props;

  const renderBreadcrumbs = () => {
    const { intl } = props;
    let breadcrumbs = [
      {
        name: intl.formatMessage({ id: "breadcrumbs > home" }),
        to: {
          pathname: URL.HOME(),
          isReminder: true,
        },
        active: false,
      },
      {
        name: intl.formatMessage({ id: "breadcrumbs > clock manager" }),
        to: {
          pathname: URL.CLOCK_MANAGER(),
          isReminder: true,
        },
        active: false,
        isReminder: true,
      },
      {
        name: intl.formatMessage({ id: "breadcrumbs > clock creator" }),
        active: true,
      },
    ];

    return breadcrumbs;
  };

  return (
    <PrivilegedComponent requires={[]}>
      {({ isValid }) => {
        if (isValid) {
          return (
            <PageAdmin
              title={intl.formatMessage({ id: "pages > clock creator" })}
              breadcrumbs={renderBreadcrumbs()}
              className={bem.b()}
            >
              <ClockCreator {...props} />
            </PageAdmin>
          );
        } else {
          return <Redirect to="/" />;
        }
      }}
    </PrivilegedComponent>
  );
};

export default ClockCreatorPage;
