import * as Types from "../types/templates";
import { LOGOUT_USER } from "../types/auth";
import get from "lodash/get";
import { fix_template_roles } from "../../utils/helpers";
import { RESET_PROCESS } from "store/types/processes";
const initialState = {
  templates: {
    // This object contains all templates list
    // this is mainly required in templates listing page
    // where we show all templates list and loading icon if srevers are loading
    data: [],
    error: null,
    loading: false
  },
  template: {
    // This object contains particular template object with id mapping
    // this is mainly required in template details page
    // where we show particular template detail and loading icon if srever details is loading
    // below is example object
    // "<id>": {
      data: null,
      error: null,
      loading: false
    // }
  },
  addTemplate: {
    // This object will be useful to show loading icon and status when user want to create new template
    data: null,
    error: null,
    loading: false
  },
  updateTemplate: {
    // This object will be useful to show loading icon and status when user want to update template
    data: null,
    error: null,
    loading: false
  },
  deleteTemplate: {
    // This object will be useful to show loading icon and status when user want to delete template
    data: null,
    error: null,
    loading: false
  }
};

export default function (state = initialState, action) {
  switch (action.type) {
    // Templates Actions
    case Types.UPDATE_CURRENT_TEMPLATE:
      return {
        ...state,
        template: {
          ...state.template,
          data:
            action.payload
        }
      }
    case Types.FETCH_TEMPLATES_REQUEST: {
      return {
        ...state,
        templates: { data: [], error: null, loading: true }
      };
    }
    case Types.FETCH_TEMPLATES_SUCCESS: {
      return {
        ...state,
        templates: {
          data: action.payload,
          error: null,
          loading: false
        }
      };
    }
    case Types.FETCH_TEMPLATES_ERROR: {
      return {
        ...state,
        templates: {
          data: null,
          error: action.payload,
          loading: false
        }
      };
    }

    // Particular Template Actions
    case Types.FETCH_TEMPLATE_REQUEST: {
      return {
        ...state,
        template: {
          loading: true,
          error: null,
          data: null
        },
        templates: {
          error: null,
          loading: false
        }
      };
    }
    case Types.FETCH_TEMPLATE_SUCCESS: {
      let { data, info } = action.payload;
      // corrrect role IDs to machine specific
      data = fix_template_roles({...data}, info);

      return {
        ...state,
        template: {
          loading: false,
          error: null,
          data: data
        },
        templates: {
          error: null,
          loading: false
        }
      };
    }
    case Types.FETCH_TEMPLATE_ERROR: {
      const existingTemplates = get(state, "template");
      const { id, error } = action.payload;
      return {
        ...state,
        template: {
          ...existingTemplates,
          [id]: {
            loading: false,
            error
          }
        }
      };
    }

    // Add Template Actions
    case Types.ADD_TEMPLATE_REQUEST: {
      return {
        ...state,
        addTemplate: {
          loading: true,
          error: null
        }
      };
    }
    case Types.ADD_TEMPLATE_SUCCESS: {
      return {
        ...state,
        addTemplate: {
          data: action.payload,
          loading: false,
          error: null
        }
      };
    }
    case Types.ADD_TEMPLATE_ERROR: {
      return {
        ...state,
        addTemplate: {
          loading: false,
          error: action.payload
        }
      };
    }

    // Update Template Actions
    case Types.UPDATE_TEMPLATE_REQUEST: {
      return {
        ...state,
        updateTemplate: {
          loading: true,
          error: null
        }
      };
    }
    case Types.UPDATE_TEMPLATE_SUCCESS: {
      return {
        ...state,
        updateTemplate: {
          data: action.payload,
          loading: false,
          error: null
        }
      };
    }
    case Types.UPDATE_TEMPLATE_ERROR: {
      return {
        ...state,
        updateTemplate: {
          loading: false,
          error: action.payload
        }
      };
    }

    // DELETE Template Actions
    case Types.DELETE_TEMPLATE_REQUEST: {
      return {
        ...state,
        deleteTemplate: {
          loading: true,
          error: null
        }
      };
    }
    case Types.DELETE_TEMPLATE_SUCCESS: {
      let ind = state.templates.data.findIndex(s => s._id === action.payload)
      return {
        ...state,
        templates: {
          data:
            [...state.templates.data.slice(0, ind),
            ...state.templates.data.slice(ind + 1)]
        },
        deleteTemplate: {
          loading: false,
          error: null
        }
      };
    }
    case Types.DELETE_TEMPLATE_ERROR: {
      return {
        ...state,
        deleteTemplate: {
          loading: false,
          error: action.payload
        }
      };
    }
    // we need to reset template when reset process -> remove the data fetched from previous process
    case RESET_PROCESS: {
      return {
        ...state,
        template: {
          data: null,
          loading: false,
          error: null,
        },
      };
    }
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
}
