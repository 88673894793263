import React from "react";
import { Row, Col, Label, FormGroup } from "reactstrap";
import { FormattedMessage } from "react-intl";
import {
  get,
  difference,
  isEmpty,
  map,
  find,
  filter,
  forEach,
  forOwn,
  uniq,
} from "lodash";
import { Asterisk } from "components/Elements";
import Dropdown from "components/Dropdown";
import Badge from "components/Badge";
import CustomMultiSelectDropdown from "components/CustomMultiSelectDropdown";
import { renderFinalScheduleStatement } from "components/ScheduleComponents/SchedulingPreview";
import bn from "utils/bemnames";
import moment from "moment";
import classNames from "classnames";
import ExcludeHoursModal from "components/ExcludeHoursModal";

const weekdaysMin = moment.weekdaysShort();
const bem = bn.create("schedule-preview");
function SchedulingFill(props) {
  const {
    onValueChanged,
    fields: { selected_items, excluded_hours, schedule_by, selected_by },
    shows,
    intl,
    process,
    dayparts,
    validationState,
    daypartsHours,
    showsHours,
    dateFormatByServer,
  } = props;
  const [isOpenExcludeHours, setIsOpenExcludeHours] = React.useState(false);
  const schedulingType = get(process, "data.fields.scheduling_type.value");
  const selectedBy = get(process, "data.fields.selected_by.value", "");
  const scheduleBy = get(process, "data.fields.schedule_by.value", "");
  const templateKey = get(process, "data.fields.template_key.value");
  const isContestFloating =
    schedulingType === "floating" && templateKey === "contest";
  const onSetActiveHoursBySelectedItems = () => {
    if (scheduleBy === "ROS") return [];

    const selectedItems = process.data.fields[selected_items.field]
      ? process.data.fields[selected_items.field].value
      : [];
    const data = selectedBy === "daypart" ? daypartsHours : showsHours;
    let hours = [];
    filter(data, (item) =>
      find(selectedItems, (i) => i.value === item.id)
    ).forEach((item) => {
      hours = uniq([...hours, ...item.hours]);
    });
    return hours;
  };
  const renderSelectedItems = () => {
    let selectedItems = process.data.fields[selected_items.field]
      ? process.data.fields[selected_items.field].value
      : [];

    return selectedItems.map((item) => {
      return (
        <Badge
          key={item.value}
          text={item.label}
          onClick={() => {
            onValueChanged(selected_items, difference(selectedItems, [item]));
          }}
        />
      );
    });
  };
  const renderExcludedHours = () => {
    const excludedHours = get(
      process,
      "data.fields.excluded_hours_array.value"
    );
    if (!excludedHours && excludedHours.length === 0) return null;
    let items = [];
    const excludedHoursAll = get(
      find(excludedHours, (item) => item.key === "all"),
      "value",
      []
    ).map((item) => ({
      value: item.value,
      label: item.label,
      text: `${item.label} - ALL`,
      key: "all",
    }));
    items = [...excludedHoursAll];
    forEach(excludedHours, (hourItem) => {
      const { key, value } = hourItem;
      if (key === "all") return;
      const itemsValue = value
        .filter((item) => {
          return !find(excludedHoursAll, (i) => i.value === item.value);
        })
        .map((item) => ({
          value: item.value,
          label: item.label,
          text: `${item.label} - ${weekdaysMin[parseInt(key) - 1]}`,
          key,
        }));
      items = [...items, ...itemsValue];
    });
    return items.map((hour, index) => {
      return (
        <Badge
          key={`badge-excluded-hour-${index}`}
          text={hour.text}
          onClick={() => {
            let newValue = [];
            if (hour.key === "all") {
              newValue = excludedHours.map((hourItem) => {
                return {
                  ...hourItem,
                  value: filter(
                    get(hourItem, "value", []),
                    (i) => i.value !== hour.value
                  ),
                };
              });
            } else {
              newValue = excludedHours.map((hourItem) => {
                if (hourItem.key === hour.key) {
                  return {
                    ...hourItem,
                    value: filter(
                      hourItem.value,
                      (i) => i.value !== hour.value
                    ),
                  };
                }
                return hourItem;
              });
            }

            const newValueFormatted = filter(newValue, (hourItem) => {
              return get(hourItem, "value.length", 0) > 0;
            });
            onValueChanged(excluded_hours, newValueFormatted);
          }}
        />
      );
    });
  };
  const ExcludeHoursButton = () => {
    return (
      <div
        className="exclude-dates-btn"
        onClick={() => {
          setIsOpenExcludeHours(true);
        }}
      >
        <FormattedMessage id="process > promotion > liner > exclude hours" />
      </div>
    );
  };
  const selectedByOptions = schedule_by.props.options.filter((item) =>
    item.scheduling_type.includes(schedulingType)
  );

  return (
    <React.Fragment>
      <Row>
        <Col xs={6} className={bem.e("reset-col")}>
          <FormGroup>
            <Dropdown
              label={
                <span>
                  {isContestFloating
                    ? intl.formatMessage({
                        id: "process > promotion > liner > per",
                      })
                    : schedule_by.title}
                  {schedule_by.mandatory && <Asterisk>*</Asterisk>}
                </span>
              }
              name="schedule_by"
              onChange={(selectedOption) => {
                if (["daypart", "show"].includes(selectedOption.value)) {
                  onValueChanged(selected_by, selectedOption.value);
                } else {
                  onValueChanged(selected_by, "show");
                }
                onValueChanged(schedule_by, selectedOption.value);
                onValueChanged(selected_items, []);
                onValueChanged(excluded_hours, []);
              }}
              value={find(
                selectedByOptions,
                (item) =>
                  item.value ===
                  get(process, "data.fields.schedule_by.value", "")
              )}
              error={get(validationState, "schedule_by.validation_error")}
              options={selectedByOptions}
            />
            <ExcludeHoursButton />
          </FormGroup>
        </Col>
        <Col xs={6} className={bem.e("reset-col")}>
          {scheduleBy === "daypart" && (
            <FormGroup>
              <Label>
                <FormattedMessage id="process > promotion > liner > scheduling > dayparts" />
                <Asterisk>*</Asterisk>
              </Label>
              <CustomMultiSelectDropdown
                name="selected_items"
                value={
                  get(process.data.fields[selected_items.field], "value", null)
                    ? get(
                        process.data.fields[selected_items.field],
                        "value",
                        []
                      )
                    : []
                }
                options={map(dayparts, (daypart) => ({
                  label: daypart.name,
                  value: daypart.id,
                }))}
                placeholder={intl.formatMessage({
                  id: "process > promotion > liner > select",
                })}
                isMulti={true}
                error={get(validationState, "selected_items.validation_error")}
                onChange={(value) => {
                  onValueChanged(selected_items, value);
                }}
              />
            </FormGroup>
          )}
          {scheduleBy === "show" && (
            <FormGroup>
              <Label>
                <FormattedMessage id="process > promotion > liner > scheduling > shows" />
                <Asterisk>*</Asterisk>
              </Label>
              <CustomMultiSelectDropdown
                name="selected_items"
                error={get(validationState, "selected_items.validation_error")}
                value={
                  get(process.data.fields[selected_items.field], "value", null)
                    ? get(
                        process.data.fields[selected_items.field],
                        "value",
                        []
                      )
                    : []
                }
                options={map(shows, (show) => ({
                  label: show.name,
                  value: show.id,
                }))}
                placeholder={intl.formatMessage({
                  id: "process > promotion > liner > select",
                })}
                isMulti={true}
                onChange={(value) => {
                  onValueChanged(selected_items, value);
                }}
              />
            </FormGroup>
          )}
        </Col>
      </Row>
      <Row className={bem.e("badges")}>
        {process.data.fields[selected_items.field] &&
          !isEmpty(process.data.fields[selected_items.field].value) && (
            <Col className={bem.e("reset-col")}>
              <FormGroup>
                <Label>
                  {selectedBy === "show" ? (
                    <FormattedMessage id="process > promotion > liner > scheduling > selected shows" />
                  ) : selectedBy === "daypart" ? (
                    <FormattedMessage id="process > promotion > liner > scheduling > selected dayparts" />
                  ) : (
                    <FormattedMessage id="process > promotion > liner > scheduling > select hours" />
                  )}
                </Label>
                <div
                  className={classNames(bem.e("badges-container"), {
                    [bem.e("badges-hours")]: selectedBy === "hour",
                  })}
                >
                  {renderSelectedItems()}
                </div>
              </FormGroup>
            </Col>
          )}
        {process.data.fields[excluded_hours.field] &&
          !isEmpty(process.data.fields[excluded_hours.field].value) && (
            <Col className={bem.e("reset-col")}>
              <FormGroup>
                <Label>
                  <FormattedMessage id="process > promotion > liner > scheduling > excluded hours" />
                </Label>
                <div
                  className={classNames(
                    bem.e("badges-container"),
                    bem.e("badges-excluded-hours")
                  )}
                >
                  {renderExcludedHours()}
                </div>
              </FormGroup>
            </Col>
          )}
      </Row>
      <Row>
        <Col xs={12}>
          <div className={bem.e("scheduling-statement-header")}>
            <FormattedMessage id="process > promotion > liner > scheduling > final statement" />
          </div>
          <div className={bem.e("scheduling-statement-text")}>
            {renderFinalScheduleStatement(process, dateFormatByServer)}
          </div>
        </Col>
      </Row>
      <ExcludeHoursModal
        isOpen={isOpenExcludeHours}
        onToggle={() => setIsOpenExcludeHours(!isOpenExcludeHours)}
        daysOfWeek={get(process, "data.fields.days_of_week.value", [])}
        onSubmit={(values) => {
          let valuesArray = [];
          forOwn(values, (value, key) => {
            valuesArray = [
              ...valuesArray,
              {
                key,
                value,
              },
            ];
          });
          onValueChanged(excluded_hours, valuesArray);
          setIsOpenExcludeHours(false);
        }}
        value={get(process, "data.fields.excluded_hours_array.value")}
        activeHours={onSetActiveHoursBySelectedItems()}
      />
    </React.Fragment>
  );
}
export default SchedulingFill;
