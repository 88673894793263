import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import View from "./view";
import {
  deleteProcess,
  setActiveEditTab,
  duplicate,
  forgetProcess,
  saveFormValues,
  archiveProcess,
  addRotation,
  getProcess,
  deleteSpotProcess,
  setActiveTab,
  unarchiveProcess,
  convertSpecToSold,
} from "store/actions/processes";
import { injectIntl } from "react-intl";
import { compose } from "recompose";
import { get } from "lodash";
import { getTemplate } from "store/actions/template";

const Enhanced = compose(
  connect(
    (state) => {
      return {
        auth: state.auth,
        sidebarActive: state.auth.sidebar_active,
        dateFormatByServer: get(
          state,
          "servers.currentServer.data.date_format",
          "MM/DD/YYYY"
        ),
        productionSettings: get(state, "settings.production_settings.data", {}),
      };
    },
    {
      duplicate,
      deleteProcess,
      setActiveEditTab,
      forgetProcess,
      saveFormValues,
      getTemplate,
      archiveProcess,
      addRotation,
      getProcess,
      deleteSpotProcess,
      setActiveTab,
      unarchiveProcess,
      convertSpecToSold,
    }
  )
)(View);

export default withRouter(injectIntl(Enhanced));
