import React from "react";
import PropTypes from "prop-types";
import ReactQuill from "react-quill";
import bn from "utils/bemnames";
const bem = bn.create("quill-editor");

const QuillEditor = (props) => {
  const { value, onChange, toolbarOptions, placeholder } = props;
  return (
    <ReactQuill
      className={bem.b()}
      modules={{
        toolbar: toolbarOptions,
      }}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
    />
  );
};
QuillEditor.propTypes = {
  readOnly: PropTypes.bool,
  toolbarOptions: PropTypes.array,
  placeholder: PropTypes.string,
};
QuillEditor.defaultProps = {
  placeholder: "",
  readOnly: false,
  toolbarOptions: [["bold", "italic", "underline"], [{ color: [] }]],
};
export default QuillEditor;
