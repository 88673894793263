import * as React from "react";
import { Row, Col, Label } from "reactstrap";
import { get } from "lodash";
import { FormattedMessage } from "react-intl";
import Dropdown from "components/Dropdown";
import {
  ShadowBox,
  SectionTitle,
  SectionFields,
  ChevronWrapper,
} from "components/Elements";
import { UpIcon, DownIcon } from "components/CustomIcons";
import bn from "utils/bemnames";
import moment from "moment";
import TextInput from "components/TextInput";
import { formatDisplayDate } from "utils/helpers";
import { useMemo } from "react";

const bem = bn.create("production-deadlines");

const ProductionDeadlines = (props) => {
  const {
    intl,
    process,
    step,
    template,
    user,
    validationState,
    isView,
    dateFormatByServer,
  } = props;
  const [isOpenDetails, setIsOpenDetails] = React.useState(false);
  const {
    assignmentDeadlineField,
    voiceDeadlineField,
    scriptDeadlineField,
    recordDeadlineField,
  } = React.useMemo(() => {
    return {
      assignmentDeadlineField: step.fields.find(
        (f) => f.field === "assignment_deadline"
      ),
      voiceDeadlineField: step.fields.find((f) => f.field === "voice_deadline"),
      scriptDeadlineField: step.fields.find(
        (f) => f.field === "script_deadline"
      ),
      recordDeadlineField: step.fields.find(
        (f) => f.field === "record_deadline"
      ),
    };
  }, [step]);

  const assignmentDeadline = get(
    process,
    "data.fields.assignment_deadline.value",
    ""
  );
  const voiceDeadline = get(process, "data.fields.voice_deadline.value", "");
  const scriptDeadline = get(process, "data.fields.script_deadline.value", "");
  const recordDeadline = get(process, "data.fields.record_deadline.value", "");

  const assignmentDeadlineValue = React.useMemo(() => {
    if (!assignmentDeadline) {
      return {
        date: "",
        time: "",
      };
    }
    const momentDate = moment(assignmentDeadline, "YYYY-MM-DD HH:mm");
    return {
      date: momentDate.format("YYYY-MM-DD"),
      time: momentDate.format("HH:mm"),
    };
  }, [assignmentDeadline]);

  const voiceDeadlineValue = React.useMemo(() => {
    if (!voiceDeadline) {
      return {
        date: "",
        time: "",
      };
    }
    const momentDate = moment(voiceDeadline, "YYYY-MM-DD HH:mm");
    return {
      date: momentDate.format("YYYY-MM-DD"),
      time: momentDate.format("HH:mm"),
    };
  }, [voiceDeadline]);

  const scriptDeadlineValue = React.useMemo(() => {
    if (!scriptDeadline) {
      return {
        date: "",
        time: "",
      };
    }
    const momentDate = moment(scriptDeadline, "YYYY-MM-DD HH:mm");
    return {
      date: momentDate.format("YYYY-MM-DD"),
      time: momentDate.format("HH:mm"),
    };
  }, [scriptDeadline]);

  const recordDeadlineValue = React.useMemo(() => {
    if (!recordDeadline) {
      return {
        date: "",
        time: "",
      };
    }
    const momentDate = moment(recordDeadline, "YYYY-MM-DD HH:mm");
    return {
      date: momentDate.format("YYYY-MM-DD"),
      time: momentDate.format("HH:mm"),
    };
  }, [recordDeadline]);

  const hourOptions = React.useMemo(() => {
    return Array.from({ length: 24 }, (_, i) => i).map((item) => {
      return {
        label: moment(item, "HH").format("h a"),
        value: moment(item, "HH").format("HH:mm"),
      };
    });
  }, []);

  const maxDate = useMemo(() => {
    const orderStartDate = get(
      process,
      "data.fields.order_start_date.value",
      ""
    );
    if (orderStartDate) {
      return moment(orderStartDate)
        .subtract(1, "days")
        .toDate();
    }
    return null;
  }, [process]);

  const onChange = (item, value, type = "date") => {
    let formatValue = "";
    if (item.field === "assignment_deadline") {
      props.valueChanged({ field: 'assignment_deadline_modified' },process, template, user, {value: true});
      const assignmentDeadlineArr = [
        assignmentDeadlineValue.date || moment().format("YYYY-MM-DD"),
        assignmentDeadlineValue.time || "00:00",
      ];
      if (type === "date") {
        assignmentDeadlineArr[0] = value;
      } else if (type === "time") {
        assignmentDeadlineArr[1] = value;
      }
      formatValue = assignmentDeadlineArr.filter(Boolean).join(" ");
    } else if (item.field === "voice_deadline") {
      props.valueChanged({ field: 'voice_deadline_modified' },process, template, user, {value: true});
      const voiceDeadlineArr = [
        voiceDeadlineValue.date || moment().format("YYYY-MM-DD"),
        voiceDeadlineValue.time || "00:00",
      ];
      if (type === "date") {
        voiceDeadlineArr[0] = value;
      } else if (type === "time") {
        voiceDeadlineArr[1] = value;
      }
      formatValue = voiceDeadlineArr.filter(Boolean).join(" ");
    } else if (item.field === "record_deadline") {
      props.valueChanged({ field: 'record_deadline_modified' },process, template, user, {value: true});
      const recordDeadlineArr = [
        recordDeadlineValue.date || moment().format("YYYY-MM-DD"),
        recordDeadlineValue.time || "00:00",
      ];
      if (type === "date") {
        recordDeadlineArr[0] = value;
      } else if (type === "time") {
        recordDeadlineArr[1] = value;
      }
      formatValue = recordDeadlineArr.filter(Boolean).join(" ");
    } else if (item.field === "script_deadline") {
      props.valueChanged({ field: 'script_deadline_modified' },process, template, user, {value: true});
      const scriptDeadlineArr = [
        scriptDeadlineValue.date || moment().format("YYYY-MM-DD"),
        scriptDeadlineValue.time || "00:00",
      ];
      if (type === "date") {
        scriptDeadlineArr[0] = value;
      } else if (type === "time") {
        scriptDeadlineArr[1] = value;
      }
      formatValue = scriptDeadlineArr.filter(Boolean).join(" ");
    }

    if (props.setShouldBlockNavigation && props.shouldBlockNavigation) {
      props.setShouldBlockNavigation(true);
    }
    props.valueChanged(item, process, template, user, {
      value: formatValue,
    });
  };
  if (isView) {
    return (
      <ShadowBox className={bem.b()}>
        <SectionTitle
          className={bem.e("section-title")}
          onClick={() => setIsOpenDetails(!isOpenDetails)}
        >
          <FormattedMessage id="process > title production deadlines" />
          <ChevronWrapper>
            {isOpenDetails ? <DownIcon /> : <UpIcon />}
          </ChevronWrapper>
        </SectionTitle>
        <SectionFields className={!isOpenDetails && "hidden"}>
          <Row>
            {assignmentDeadlineField && (
              <Col xs={6} className={bem.e("col-view-field")}>
                <Label>{assignmentDeadlineField.title}</Label>
                <span className={bem.e("order-view-value")}>
                  {assignmentDeadline
                    ? formatDisplayDate(
                        assignmentDeadline,
                        `${dateFormatByServer} h A`
                      )
                    : intl.formatMessage({ id: "process > none" })}
                </span>
              </Col>
            )}
            {voiceDeadlineField && (
              <Col xs={6} className={bem.e("col-view-field")}>
                <Label>{voiceDeadlineField.title}</Label>
                <span className={bem.e("order-view-value")}>
                  {voiceDeadline
                    ? formatDisplayDate(
                        voiceDeadline,
                        `${dateFormatByServer} h A`
                      )
                    : intl.formatMessage({ id: "process > none" })}
                </span>
              </Col>
            )}
            {scriptDeadlineField && (
              <Col xs={6} className={bem.e("col-view-field")}>
                <Label>{scriptDeadlineField.title}</Label>
                <span className={bem.e("order-view-value")}>
                  {scriptDeadline
                    ? formatDisplayDate(
                        scriptDeadline,
                        `${dateFormatByServer} h A`
                      )
                    : intl.formatMessage({ id: "process > none" })}
                </span>
              </Col>
            )}
            {recordDeadlineField && (
              <Col xs={6} className={bem.e("col-view-field")}>
                <Label>{recordDeadlineField.title}</Label>
                <span className={bem.e("order-view-value")}>
                  {recordDeadline
                    ? formatDisplayDate(
                        recordDeadline,
                        `${dateFormatByServer} h A`
                      )
                    : intl.formatMessage({ id: "process > none" })}
                </span>
              </Col>
            )}
          </Row>
        </SectionFields>
      </ShadowBox>
    );
  }
  return (
    <ShadowBox className={bem.b()}>
      <SectionTitle
        className={bem.e("section-title")}
        onClick={() => setIsOpenDetails(!isOpenDetails)}
      >
        <FormattedMessage id="process > title production deadlines" />
        <ChevronWrapper>
          {!isOpenDetails ? <DownIcon /> : <UpIcon />}
        </ChevronWrapper>
      </SectionTitle>
      <SectionFields className={!isOpenDetails && "hidden"}>
        <Row>
          {assignmentDeadlineField && (
            <Col xs={6}>
              <div className={bem.e("deadline-group")}>
                <TextInput
                  label={assignmentDeadlineField.title}
                  type="date"
                  name="assignment_deadline"
                  placeholder={intl.formatMessage({
                    id:
                      "process > promotion > liner > schedule > placeholder select",
                  })}
                  value={assignmentDeadlineValue.date || ""}
                  onChange={(value) =>
                    onChange(assignmentDeadlineField, value, "date")
                  }
                  minDate={moment().toDate()}
                  maxDate={maxDate}
                  allowShowIcon
                />
                <Dropdown
                  options={hourOptions}
                  value={
                    hourOptions.find(
                      (item) => item.value === assignmentDeadlineValue.time
                    ) || ""
                  }
                  placeholder={intl.formatMessage({
                    id:
                      "process > promotion > liner > schedule > placeholder select",
                  })}
                  onChange={(option) => {
                    onChange(assignmentDeadlineField, option.value, "time");
                  }}
                />
              </div>
              {validationState &&
                validationState[assignmentDeadlineField.field] &&
                validationState[assignmentDeadlineField.field]
                  .validation_error && (
                  <div className="alert-danger">
                    {
                      validationState[assignmentDeadlineField.field]
                        .validation_error
                    }
                  </div>
                )}
            </Col>
          )}
          {voiceDeadlineField && (
            <Col xs={6}>
              <div className={bem.e("deadline-group")}>
                <TextInput
                  label={voiceDeadlineField.title}
                  type="date"
                  name="voice_deadline"
                  placeholder={intl.formatMessage({
                    id:
                      "process > promotion > liner > schedule > placeholder select",
                  })}
                  value={voiceDeadlineValue.date || ""}
                  onChange={(value) =>
                    onChange(voiceDeadlineField, value, "date")
                  }
                  minDate={moment().toDate()}
                  maxDate={maxDate}
                  allowShowIcon
                />
                <Dropdown
                  options={hourOptions}
                  value={
                    hourOptions.find(
                      (item) => item.value === voiceDeadlineValue.time
                    ) || ""
                  }
                  placeholder={intl.formatMessage({
                    id:
                      "process > promotion > liner > schedule > placeholder select",
                  })}
                  onChange={(option) => {
                    onChange(voiceDeadlineField, option.value, "time");
                  }}
                />
              </div>
              {validationState &&
                validationState[voiceDeadlineField.field] &&
                validationState[voiceDeadlineField.field].validation_error && (
                  <div className="alert-danger">
                    {validationState[voiceDeadlineField.field].validation_error}
                  </div>
                )}
            </Col>
          )}
          {scriptDeadlineField && (
            <Col xs={6}>
              <div className={bem.e("deadline-group")}>
                <TextInput
                  label={scriptDeadlineField.title}
                  type="date"
                  name="script_deadline"
                  placeholder={intl.formatMessage({
                    id:
                      "process > promotion > liner > schedule > placeholder select",
                  })}
                  value={scriptDeadlineValue.date || ""}
                  onChange={(value) =>
                    onChange(scriptDeadlineField, value, "date")
                  }
                  minDate={moment().toDate()}
                  maxDate={maxDate}
                  allowShowIcon
                />
                <Dropdown
                  options={hourOptions}
                  value={
                    hourOptions.find(
                      (item) => item.value === scriptDeadlineValue.time
                    ) || ""
                  }
                  placeholder={intl.formatMessage({
                    id:
                      "process > promotion > liner > schedule > placeholder select",
                  })}
                  onChange={(option) => {
                    onChange(scriptDeadlineField, option.value, "time");
                  }}
                />
              </div>
              {validationState &&
                validationState[scriptDeadlineField.field] &&
                validationState[scriptDeadlineField.field].validation_error && (
                  <div className="alert-danger">
                    {
                      validationState[scriptDeadlineField.field]
                        .validation_error
                    }
                  </div>
                )}
            </Col>
          )}
          {recordDeadlineField && (
            <Col xs={6}>
              <div className={bem.e("deadline-group")}>
                <TextInput
                  label={recordDeadlineField.title}
                  type="date"
                  name="record_deadline"
                  placeholder={intl.formatMessage({
                    id:
                      "process > promotion > liner > schedule > placeholder select",
                  })}
                  value={recordDeadlineValue.date || ""}
                  onChange={(value) =>
                    onChange(recordDeadlineField, value, "date")
                  }
                  minDate={moment().toDate()}
                  maxDate={maxDate}
                  allowShowIcon
                />
                <Dropdown
                  options={hourOptions}
                  value={
                    hourOptions.find(
                      (item) => item.value === recordDeadlineValue.time
                    ) || ""
                  }
                  placeholder={intl.formatMessage({
                    id:
                      "process > promotion > liner > schedule > placeholder select",
                  })}
                  onChange={(option) => {
                    onChange(recordDeadlineField, option.value, "time");
                  }}
                />
              </div>
              {validationState &&
                validationState[recordDeadlineField.field] &&
                validationState[recordDeadlineField.field].validation_error && (
                  <div className="alert-danger">
                    {
                      validationState[recordDeadlineField.field]
                        .validation_error
                    }
                  </div>
                )}
            </Col>
          )}
        </Row>
      </SectionFields>
    </ShadowBox>
  );
};
export default ProductionDeadlines;
