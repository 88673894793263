import { injectIntl } from 'react-intl';
import { omit, forEach, keys, includes, has, get, isEqual, concat, intersection, isEmpty, pick, find } from "lodash";
import { compose, withState, withHandlers, lifecycle } from "recompose";
import { connect } from "react-redux";

import AudiencePickerModal from './view';
import {saveGroup, getGroup, updateGroup } from 'store/actions/audience';

export default injectIntl(
  compose(
    connect((state, ownProps) => {
      return {
        ...ownProps,
        stations: get(state, "auth.info.stations", []),
        groups: get(state, "auth.info.audience_groups", []),
        saveGroupError: get(state, "audience.saveGroup.error", false),
        updateGroupError: get(state, "audience.updateGroup.error", false),
        selectedGroup: get(state, `audience.viewedGroups.${ownProps.selectedGroupId}`, {}),
      };
    }, {
      saveGroup,
      getGroup,
      updateGroup
    }),
    withState("selectedTags", "setSelectedTags", {}),
    withState("selectedStations", "setSelectedStations", {}),
    withState("addedStations", "setAddedStations", {}),
    withState("isLoading", "setIsLoading", false),
    withState("isGroupSaved", "setIsGroupSaved", false),
    withState("isAudienceAssigned", "setIsAudienceAssigned", false),
     withState("groupBeingReplaced", "setGroupBeingReplaced", false),
    withHandlers({
      updateSelectedTags: ({
        selectedTags,
        setSelectedTags,
        selectedStations,
        setSelectedStations,
        addedStations,
      }) => (checked, { id, label, stations }, tagType, updateStations = true) => {
        if (checked) {

          let tagStations = {};
          
          forEach(stations, station => {     

            station = has(selectedStations, station.key) ? selectedStations[station.key] : station;

            tagStations[station.key] = {...station, [tagType]: {...station[tagType], [id]: {...station[tagType][id], selected: true}}};

          });

          setSelectedTags((previousSelectedTags) => {

            if(updateStations){
              setSelectedStations({ ...selectedStations, ...tagStations });
            }

            return { ...previousSelectedTags, [id]: { id, label, type: tagType, stations: tagStations }};

          });
        } else {

          setSelectedTags((previousSelectedTags) => {

            let newSelectedTags = omit(previousSelectedTags, id);

            if(updateStations){

              /*
              * if the stations of the removed tag are not part of any other tag we need to remove those stations from selected stations list
              * If they were selected using another tag, we will unset the selected property for the deleted tag
              */

              let selectedTagStations = {};

              forEach(newSelectedTags, (tag) => {
                selectedTagStations = concat(selectedTagStations, keys(tag.stations));
              });


              let newSelectedStations = {};

              //all stations other than the removed tag's
              forEach(selectedStations, station => {
                if(!includes(keys(stations), station.key)){
                  newSelectedStations[station.key] = station;
                }
              });

              //now loop through removed tag's stations and remove  their selected attribute for the removed tag if they are part of other tag's stations
              forEach(stations, (station) => {

                station = has(selectedStations, station.key) ? selectedStations[station.key] : station;

                if (includes(selectedTagStations, station.key)) {
                  newSelectedStations[station.key] = {...station, [tagType]: {...station[tagType], [id]: omit(station[tagType][id], 'selected')}};
                }
              });

              setSelectedStations(newSelectedStations);
            }

            return newSelectedTags;

          });
        }
      },
      closeModal : ({ setSelectedStations, setSelectedTags, setAddedStations, onToggle }) => () => {
        setSelectedTags({});
        setSelectedStations({});
        setAddedStations({});
        onToggle();
      }
    }),
    withHandlers({
      assignAudience: ({ addedStations, closeModal, onClose, setIsAudienceAssigned, selectedTags }) => () => {
        
        setIsAudienceAssigned(true);

        setTimeout( () => {
          setIsAudienceAssigned(false)
          closeModal();
          onClose(null, addedStations, selectedTags);
        }, 2000)
      },
      closeReplaceGroupModal:({setGroupBeingReplaced}) => () => {
        setGroupBeingReplaced(false);
      },
    }),
    withHandlers({
      updateSelectedStations : ({
        selectedStations, 
        setSelectedStations, 
        selectedTags, 
        updateSelectedTags
      }) => (checked, station) => {
        if(checked){
          setSelectedStations({...selectedStations, [station.key]: station});
        }else{

          let newSelectedStations = omit(selectedStations, station.key);
          setSelectedStations(newSelectedStations);

          forEach(selectedTags, (tag) => {
              if(isEmpty(intersection(keys(tag.stations), keys(newSelectedStations)))){
                updateSelectedTags(false, tag, tag.type, false);
              }
          }); 
        }
      },
      onSaveGroup: ({ setIsLoading, addedStations, saveGroup, closeModal, onClose, setIsGroupSaved, updateGroup, isEdit, selectedGroupId, assignAudience, setGroupBeingReplaced, groups, selectedTags }) => (values) => {

        if(values.create_group){

          let groupName = get(values, "name", "");

          if(!isEdit){

            let existingGroup = find(groups, group => group.station_key.toLowerCase() === groupName.toLowerCase());

            if(existingGroup){
              setGroupBeingReplaced(get(existingGroup, "id", false));
            }else{

              setIsLoading(true);
              saveGroup({...values, stations : addedStations}, (audienceGroup) => {
                setIsLoading(false);
                setIsGroupSaved(true)
                setTimeout( () => {
                  setIsGroupSaved(false)
                  closeModal();
                  onClose(audienceGroup, null, selectedTags);
                }, 2000)
              });
            }
          }else{

            let existingGroup = find(groups, group => group.station_key.toLowerCase() === groupName.toLowerCase() && group.id !== selectedGroupId);

            if(existingGroup){
              setGroupBeingReplaced(get(existingGroup, "id", false));
            }else{
              
              setIsLoading(true);
          
              updateGroup(selectedGroupId, {...values, stations : addedStations}, (audienceGroup) => {
                setIsLoading(false);
                setIsGroupSaved(true)
                setTimeout( () => {
                  setIsGroupSaved(false)
                  closeModal();
                  onClose(audienceGroup, null, selectedTags);
                }, 2000)
              });
            }
          }
        }else{
          assignAudience();
        }
      },
    }),
    withHandlers({
      replaceGroup: ({setIsLoading, addedStations, setIsGroupSaved, closeModal, onClose, closeReplaceGroupModal, groupBeingReplaced, updateGroup, selectedTags}) => (values) => {

        setIsLoading(true);

          updateGroup(groupBeingReplaced, {...values, stations : addedStations}, (audienceGroup) => {
            setIsLoading(false);
            setIsGroupSaved(true)
            setTimeout( () => {
              setIsGroupSaved(false)
              closeReplaceGroupModal();
              closeModal();
              onClose(audienceGroup, null, selectedTags);
            }, 2000)
          });
      }
    }),
    lifecycle({
      componentDidUpdate(prevProps) {
                
        if(this.props.selectedGroupId && this.props.isOpen && !isEqual(this.props.isOpen, prevProps.isOpen)){
          this.props.getGroup(this.props.selectedGroupId);
        }

        if(!isEmpty(this.props.selectedAudience) && (!isEqual(this.props.selectedAudience, prevProps.selectedAudience) || (this.props.isOpen && !isEqual(this.props.isOpen, prevProps.isOpen)))){

          let selectedStations = {};

          this.props.stations.filter(station => includes(this.props.selectedAudience, station.key))
                              .forEach(station => {
                                selectedStations[station.key] = pick(station, ['key', 'station_key', 'market', 'format', 'team', 'audience', 'name', 'call_letters']);
                              });
                    
          this.props.setAddedStations(selectedStations);

        }else if(!isEqual(this.props.selectedGroup, prevProps.selectedGroup) || (this.props.isOpen && !isEqual(this.props.isOpen, prevProps.isOpen))){

          this.props.setAddedStations(get(this.props.selectedGroup, "data.selected_stations", {}));
          this.props.setSelectedStations(get(this.props.selectedGroup, "data.selected_stations", {}));

        }

        if((this.props.saveGroupError || this.props.updateGroupError) && this.props.isLoading){
          this.props.setIsLoading(false);
        }
      }
    }),
  )(AudiencePickerModal)
);