import React, { useEffect, useRef, useState } from "react";
import bn from "utils/bemnames";
import { NavLink } from "react-router-dom";
import { NavLink as BSNavLink } from "reactstrap";
import Tooltip from "rc-tooltip";
import { UserCard, AdminCard } from "components/Card";
import AdminBackgroundImage from "assets/img/profile_background.png";
import { PromoSuiteIcon } from "components/CustomIcons";
import { FormattedMessage } from "react-intl";
import { map, get } from "lodash";
import SidebarNew from "components/SidebarNew";
import useScrollToTopElement from "utils/useScrollToTopElement";
import useWindowDimensions from "utils/useWindowDimensions";
import classnames from "classnames";
const bem = bn.create("sidebar");
function SidebarItem(props) {
  const {
    to,
    exact,
    name,
    Icon,
    isOpen,
    counter,
    className,
    setSidebarActive,
    sidebarActive,
  } = props;
  const [visible, setVisible] = useState(false);
  const sidebar = document.getElementById("layout-sidebar");

  return (
    <BSNavLink
      tag={NavLink}
      to={to}
      activeClassName="active"
      onClick={() => setSidebarActive(to.pathname)}
      className={classnames(
        {
          [bem.e("nav-link-active")]: visible,
        },
        className
      )}
      isActive={(match, location) => {
        if (!match) {
          if (location.pathname === to.pathname) return true;
          if (location.pathname.indexOf("admins/out-of-office") !== -1)
            return false;
          // Only apply active sidebar for admin out-of-office page details

          if (
            to.pathname.indexOf(sidebarActive) !== -1 &&
            location.pathname.indexOf("admins/out-of-office") === -1 &&
            location.pathname.indexOf("user/") !== -1
          )
            return true;
          // Only apply active sidebar for admin page or page details
          if (
            to.pathname.indexOf(sidebarActive) !== -1 &&
            (location.pathname.indexOf("admins") !== -1 ||
              location.pathname.indexOf("edit") !== -1 ||
              location.pathname.indexOf("view") !== -1)
          )
            return true;
          return false;
        }
        return true;
      }}
      exact={exact}
      onMouseOver={() => {
        sidebar && sidebar.classList.add(bem.e("visible-stripe-link"));
      }}
      onMouseLeave={() => {
        sidebar && sidebar.classList.remove(bem.e("visible-stripe-link"));
      }}
    >
      <Tooltip
        placement="right"
        trigger={["hover"]}
        overlayClassName={bem.e("nav-tooltip")}
        onVisibleChange={(visible) => {
          if (isOpen) return;
          setVisible(visible);
        }}
        visible={visible}
        overlay={
          <span
            className={bem.e("stripe-link-title")}
            onClick={() => setVisible(false)}
          >
            <span>{name}</span>
          </span>
        }
      >
        <span className={bem.e("nav-icon")}>
          <Icon color="#657894" className={bem.e("nav-item-icon")} />
          {counter > 0 && <span className={bem.e("counter")}>{counter}</span>}
        </span>
      </Tooltip>

      {isOpen && <span className={bem.e("link-title")}>{name}</span>}
    </BSNavLink>
  );
}
function SidebarNav(props) {
  const { title, items, isOpen, setSidebarActive, sidebarActive } = props;
  return (
    <div className={bem.e("sidebar-section")}>
      {title && (
        <h6
          className={classnames({
            [bem.e("sidebar-title")]: isOpen,
            [bem.e("sidebar-sort-title")]: !isOpen,
          })}
        >
          {title}
        </h6>
      )}
      {map(items, (item, index) => {
        return (
          <SidebarItem
            {...item}
            setSidebarActive={setSidebarActive}
            sidebarActive={sidebarActive}
            isOpen={isOpen}
            key={index}
          />
        );
      })}
      <div className={bem.e("separator")} />
    </div>
  );
}
export default function Sidebar(props) {
  const {
    isSidebarOpen,
    sections,
    authIsGodAdmin,
    auth,
    scrollSectionHeight,
    setScrollSectionHeight,
    setSidebarActive,
  } = props;
  const isOpen = isSidebarOpen;
  const sidebarRef = useRef(null);
  const topRef = useRef(null);
  const { width: widthScreen, height: heightScreen } = useWindowDimensions();
  useScrollToTopElement(sidebarRef, isOpen);
  useEffect(() => {
    const sidebarHeight = sidebarRef.current.clientHeight;
    const topSectionHeight = topRef.current.clientHeight;
    setScrollSectionHeight(sidebarHeight - topSectionHeight);
  }, [isOpen, widthScreen, heightScreen]);
  useEffect(() => {
    const body = document.querySelector("body");
    if (isSidebarOpen) {
      body.classList.add("body-sidebar-open");
    } else {
      body.classList.remove("body-sidebar-open");
    }
  }, [isSidebarOpen]);
  return (
    <aside
      id="layout-sidebar"
      className={classnames(bem.b(), {
        [bem.e("sidebar-open-style")]: isOpen,
        [bem.e("collapsed")]: !isSidebarOpen,
        [bem.e("open")]: isSidebarOpen,
      })}
      ref={sidebarRef}
    >
      <div className={bem.e("sidebar-content")}>
        <div className={bem.e("header-fake")} />
        <div ref={topRef}>
          {authIsGodAdmin ? (
            <div className={bem.e("admin-card")}>
              <AdminCard auth={auth} isShowTitle={false} isShowLogo={false} />
              <SidebarNew />
            </div>
          ) : (
            <div
              className={bem.e("nav-top")}
              style={{
                backgroundImage: `url(${AdminBackgroundImage})`,
              }}
            >
              <UserCard
                className={bem.e("user-card")}
                title={get(auth, "user.name")}
                avatar={get(auth, "user.avatar_url")}
                subtitle={get(auth, "user.title")}
                avatarSize={isOpen ? 64 : 32}
                isShowTitle={false}
                isShowSubtitle={false}
              />
              <SidebarNew />
            </div>
          )}
          <div className={bem.e("top-separator")} />
        </div>
        <div className={bem.e("sections-wrapper")}>
          <div
            className={classnames(bem.e("sections-scroll"), "scroll-bar-style")}
            style={{
              height: scrollSectionHeight,
            }}
          >
            <div className={bem.e("sections")}>
              {map(sections, (section, index) => (
                <SidebarNav
                  {...section}
                  setSidebarActive={setSidebarActive}
                  isOpen={isOpen}
                  key={index}
                  sidebarActive={get(auth, "sidebar_active")}
                />
              ))}
            </div>
            <div className={bem.e("footer")}>
              {isOpen ? (
                <div className={bem.e("copyright-text")}>
                  <FormattedMessage id="footer > powered by" />
                  {` `}
                  <strong>
                    <FormattedMessage id="footer > promoSuite" />
                  </strong>
                </div>
              ) : (
                <span className={bem.e("copyright-icon")}>
                  <PromoSuiteIcon />
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
}
