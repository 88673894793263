import OrderDetails from "./orderDetails";
import Prizes from "./prizes";
import Schedule from "./schedule";
import Winners from "./winners";
import Reports from "./reports";
import Log from "./log";

const steps = {
  order_details: OrderDetails,
  prizes: Prizes,
  schedule: Schedule,
  winners: Winners,
  reports: Reports,
  log: Log,
};
export default steps;
