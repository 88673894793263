import React, { useEffect, useRef } from "react";
import bn from "utils/bemnames";
import { FormattedMessage } from "react-intl";
import ConfirmationModal from "components/ConfirmationModal";
import { Button, Form } from "reactstrap";
import * as Yup from "yup";
import PhoneNumbers from "../PhoneNumbers";
import SocialMedia from "../SocialMedia";
import OtherLinks from "../OtherLinks";
import RSSFeed from "../RSSFeed";
import ToastManager from "components/ToastManager";
import BulletinBoardReplacement from "../BulletinBoardReplacement";
import BulletinBoard from "../BulletinBoard";
import TextEditor from "../TextEditor";
import WidgetFields from "../WidgetFields";
import { Formik } from "formik";
import { get, map, isEqual, mapValues, find } from "lodash";
import types from "./types.json";
import classNames from "classnames";
import { isBetweenDates } from "utils/helpers";
// import WarnAboutUnsavedChanges from "components/WarnAboutUnsavedChanges";
const bem = bn.create("jock-console-item");
function FormComponent(formProps) {
  const {
    values,
    intl,
    typesOptions,
    onDelete,
    onCancel,
    setValues,
    isWidgetDefault,
    formikRef,
    actionForm,
  } = formProps;
  let contentDynamic = "";
  useEffect(() => {
    setValues(values); // callback update parent component
  }, [values]);
  switch (get(values, "type")) {
    case "phone_numbers":
      contentDynamic = (
        <PhoneNumbers
          {...formProps}
          value={get(formProps, "values.fields.numbers", [])}
          intl={intl}
        />
      );
      break;
    case "social_media":
      contentDynamic = (
        <SocialMedia
          {...formProps}
          value={get(formProps, "values.fields.links", [])}
          intl={intl}
        />
      );
      break;
    case "rss_feed":
      contentDynamic = (
        <RSSFeed
          {...formProps}
          value={get(formProps, "values.fields.url", [])}
          intl={intl}
        />
      );
      break;
    case "other_links":
      contentDynamic = (
        <OtherLinks
          {...formProps}
          value={get(formProps, "values.fields.links", [])}
          intl={intl}
        />
      );
      break;
    case "text_editor":
      contentDynamic = (
        <TextEditor
          {...formProps}
          value={get(formProps, "values.fields.text", "")}
          intl={intl}
        />
      );
      break;
    case "bulletin_board_replacement":
      contentDynamic = (
        <BulletinBoardReplacement
          {...formProps}
          intl={intl}
          value={get(formProps, "values.fields.replacement", {})}
          typesOptions={typesOptions}
          isReplacement={true}
        />
      );
      break;
    case "bulletin_board":
      contentDynamic = (
        <BulletinBoard
          {...formProps}
          intl={intl}
          value={get(formProps, "values.fields.text", "")}
          typesOptions={typesOptions}
        />
      );
      break;
    default:
      break;
  }
  return (
    <Form
      onSubmit={formProps.handleSubmit}
      noValidate
      className={classNames(bem.e(`form-type-${get(values, "type", "")}`))}
    >
      {get(values, "type", null) !== "bulletin_board_replacement" ? (
        <div className={classNames(bem.e("form-padding"), bem.e("form-top"))}>
          <WidgetFields
            {...formProps}
            intl={intl}
            typesOptions={typesOptions}
            isWidgetDefault={isWidgetDefault}
          />
        </div>
      ) : null}

      <div className={classNames(bem.e("form-padding"), bem.e("form-center"))}>
        {contentDynamic}
      </div>
      <div className={bem.e("form-buttons")}>
        <Button
          color="blue"
          type="submit"
          className="btn btn-radius"
          onClick={() => {
            setTimeout(() => {
              const formErrors = get(formikRef, "current.state.errors");
              if (formErrors && Object.keys(formErrors).length) {
                ToastManager.show({
                  title: intl.formatMessage({
                    id: "toast > title not saved",
                  }),
                  message: intl.formatMessage({
                    id:
                      "toast > message error please correct the hilighted fields",
                  }),
                  level: "error",
                });
                return;
              }
            }, 20);
          }}
        >
          <FormattedMessage id={`jock console manager > button submit`} />
        </Button>
        <Button
          color="blue"
          outline
          className="btn btn-radius"
          onClick={onCancel}
          type="button"
        >
          <FormattedMessage id={`jock console manager > button cancel`} />
        </Button>
        {!isWidgetDefault && actionForm === "edit" && (
          <Button
            color="blue"
            outline
            className="btn btn-radius btn-delete"
            onClick={onDelete}
            type="button"
          >
            <FormattedMessage
              id={`jock console manager > button delete widget`}
            />
          </Button>
        )}
      </div>
    </Form>
  );
}
function ViewComponent(props) {
  const { jockConsole, intl, typesOptions, onDelete, onEdit } = props;
  const isWidgetDefault = get(jockConsole, "is_default", false);
  let contentDynamic = "";
  switch (get(jockConsole, "type")) {
    case "phone_numbers":
      contentDynamic = (
        <PhoneNumbers
          value={get(jockConsole, "fields.numbers", [])}
          intl={intl}
          isEdit={false}
        />
      );
      break;
    case "social_media":
      contentDynamic = (
        <SocialMedia
          value={get(jockConsole, "fields.links", [])}
          isEdit={false}
          intl={intl}
        />
      );
      break;
    case "rss_feed":
      contentDynamic = (
        <RSSFeed
          value={get(jockConsole, "fields.url", [])}
          isEdit={false}
          intl={intl}
        />
      );
      break;
    case "other_links":
      contentDynamic = (
        <OtherLinks
          isEdit={false}
          value={get(jockConsole, "fields.links", [])}
          intl={intl}
        />
      );
      break;
    case "text_editor":
      contentDynamic = (
        <TextEditor
          isEdit={false}
          value={get(jockConsole, "fields.text", "")}
          intl={intl}
        />
      );
      break;
    case "bulletin_board_replacement":
      contentDynamic = (
        <BulletinBoardReplacement
          value={get(jockConsole, "fields.replacement", {})}
          intl={intl}
          jockConsole={jockConsole}
          typesOptions={typesOptions}
          isReplacement={true}
          isEdit={false}
        />
      );
      break;
    case "bulletin_board":
      contentDynamic = (
        <BulletinBoard
          intl={intl}
          isEdit={false}
          value={get(jockConsole, "fields.text", "")}
          typesOptions={typesOptions}
        />
      );
      break;
    default:
      break;
  }
  return (
    <div
      className={classNames(
        bem.e(`form-type-${get(jockConsole, "type", "")}`),
        bem.e("view-component")
      )}
    >
      {get(jockConsole, "type", null) !== "bulletin_board_replacement" ? (
        <div className={classNames(bem.e("form-padding"), bem.e("form-top"))}>
          <WidgetFields
            jockConsole={jockConsole}
            intl={intl}
            typesOptions={typesOptions}
            isWidgetDefault={isWidgetDefault}
          />
        </div>
      ) : null}

      <div className={classNames(bem.e("form-padding"), bem.e("form-center"))}>
        {contentDynamic}
      </div>
      <div className={bem.e("form-buttons")}>
        <Button
          color="blue"
          type="button"
          onClick={onEdit}
          className="btn btn-radius"
        >
          <FormattedMessage id={`jock console manager > button edit`} />
        </Button>
        {!isWidgetDefault && (
          <Button
            color="blue"
            outline
            className="btn btn-radius btn-delete"
            onClick={onDelete}
            type="button"
          >
            <FormattedMessage
              id={`jock console manager > button delete widget`}
            />
          </Button>
        )}
      </div>
    </div>
  );
}
function JockConsoleForm(props) {
  const {
    intl,
    jockConsole,
    onSubmit,
    setValues,
    setIsOpenDeleteConfirmation,
    isOpenConfirmationActionsUnsavedModal,
    setIsOpenConfirmationActionsUnsavedModal,
    shouldBlockNavigation,
    setShouldBlockNavigation,
    setIsEditForm,
    onDeleteWidgetLocal,
    widgets,
    isHaveReplacementWidgets,
    isOpen,
    toggleOpen,
  } = props;
  const formikRef = useRef();
  const typesOptions = map(types, (type) => ({
    label: intl.formatMessage({
      id: `jock console manager > type ${type.label}`,
    }),
    value: type.key,
    data: type,
  })).filter((item) => {
    if (get(jockConsole, "type", "") === "bulletin_board_replacement")
      return true;

    if (isHaveReplacementWidgets)
      //  Bulletin Board can not be deleted, it can ONLY have 1 board placed over the top of it (replacement board).
      return item.value !== "bulletin_board_replacement";
    return true;
  });
  const initialValues = {
    id: get(jockConsole, "id", ""),
    name: get(jockConsole, "name", ""),
    type: get(jockConsole, "type", ""),
    color: get(jockConsole, "color", "#077FC2"),
    fields: get(jockConsole, "fields", {}),
  };
  const onSubmitOutSideForm = () => {
    formikRef.current.submitForm();
    setTimeout(() => {
      const formErrors = get(formikRef, "current.state.errors");
      if (formErrors && Object.keys(formErrors).length) {
        // detect when having errors -> open details
        if (!isOpen) {
          toggleOpen();
        }
        ToastManager.show({
          title: intl.formatMessage({
            id: "toast > title not saved",
          }),
          message: intl.formatMessage({
            id: "toast > message error please correct the hilighted fields",
          }),
          level: "error",
        });
        return;
      }
    }, 20);
  };
  const form = (
    <div className={bem.e("content-form")}>
      <Formik
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .required(intl.formatMessage({ id: "validate > name is required" }))
            .trim(intl.formatMessage({ id: "validate > name is required" }))
            .max(
              18,
              intl.formatMessage(
                {
                  id: "validate > widget name maximum character",
                },
                {
                  NUMBER: 18,
                }
              )
            ),
          type: Yup.mixed().required(
            intl.formatMessage({ id: "validate > type is required" })
          ),
          fields: Yup.lazy((obj) =>
            Yup.object(
              mapValues(obj, (value, key) => {
                if (key === "numbers") {
                  return Yup.array().of(
                    Yup.object().shape({
                      label: Yup.string().required(
                        intl.formatMessage({
                          id: "validate > label is required",
                        })
                      ),
                      number: Yup.string()
                        .required(
                          intl.formatMessage({
                            id: "validate > phone is required",
                          })
                        )
                        .test(
                          "len",
                          intl.formatMessage({
                            id: "validate > phone number must be 10 digits",
                          }),
                          (val) => !val || val.length === 10
                        ),
                    })
                  );
                } else if (key === "links") {
                  return Yup.array().of(
                    Yup.object().shape({
                      label: Yup.string().required(
                        intl.formatMessage({
                          id: "validate > label is required",
                        })
                      ),
                      url: Yup.string()
                        .trim()
                        .url(
                          intl.formatMessage({
                            id: "validate > url invalid",
                          })
                        )
                        .test(
                          "isURL",
                          intl.formatMessage({
                            id: "validate > url invalid",
                          }),
                          function(value) {
                            if (!value) return true;
                            const expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
                            const regex = new RegExp(expression);
                            return value.match(regex);
                          }
                        )
                        .test(
                          "urlRequiredIf",
                          intl.formatMessage({
                            id: "validate > url is required",
                          }),
                          function(value) {
                            const widgetType = get(
                              formikRef.current,
                              "state.values.type"
                            );
                            if (widgetType === "other_links") {
                              return !!value;
                            }
                            return true;
                          }
                        )
                        .nullable(),
                    })
                  );
                  // .test(
                  //   "leastOneUrl",
                  //   intl.formatMessage({
                  //     id: "validate > at least one URL is necessary",
                  //   }),
                  //   function(value) {
                  //     const widgetType = get(
                  //       formikRef.current,
                  //       "state.values.type"
                  //     );
                  //     if (widgetType === "other_links") {
                  //       return true;
                  //     }
                  //     return find(value, (i) => i.url);
                  //   }
                  // );
                } else if (key === "text") {
                  return Yup.string().nullable();
                } else if (key === "url") {
                  return Yup.string()
                    .url(
                      intl.formatMessage({
                        id: "validate > url invalid",
                      })
                    )
                    .test(
                      "isURL",
                      intl.formatMessage({
                        id: "validate > url invalid",
                      }),
                      function(value) {
                        if (!value) return true;
                        const expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
                        const regex = new RegExp(expression);
                        return value.match(regex);
                      }
                    )
                    .required(
                      intl.formatMessage({
                        id: "validate > url is required",
                      })
                    )
                    .nullable();
                } else if (key === "replacement") {
                  return Yup.object()
                    .shape({
                      start_date: Yup.string().required(
                        intl.formatMessage({
                          id: "validate > start date is required",
                        })
                      ),
                      end_date: Yup.string().required(
                        intl.formatMessage({
                          id: "validate > end date is required",
                        })
                      ),
                      text: Yup.string().nullable(),
                    })
                    .test(
                      "overlapDates",
                      intl.formatMessage({
                        id: "validate > range dates cannot overlap",
                      }),
                      function(value) {
                        // Replacement bulletin boards’ date ranges can not overlap
                        const id = get(formikRef.current, "state.values.id");
                        const startDate = value.start_date;
                        const endDate = value.end_date;
                        const overlap = find(widgets, (item) => {
                          if (item.type !== "bulletin_board_replacement")
                            return;
                          const baselineStartDate = get(
                            item,
                            "fields.replacement.start_date",
                            ""
                          );
                          const baselineEndDate = get(
                            item,
                            "fields.replacement.end_date",
                            ""
                          );
                          if (
                            (isBetweenDates({
                              date: startDate,
                              startDate: baselineStartDate,
                              endDate: baselineEndDate,
                            }) ||
                              isBetweenDates({
                                date: endDate,
                                startDate: baselineStartDate,
                                endDate: baselineEndDate,
                              })) &&
                            item.id !== id
                          ) {
                            return true;
                          }
                          return false;
                        });
                        return overlap ? false : true;
                      }
                    );
                }
              })
            ).nullable()
          ),
        })}
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
        ref={formikRef}
      >
        {(formProps) => {
          const { values } = formProps;
          const isChanged = !isEqual(initialValues, values);
          if (
            isChanged !== shouldBlockNavigation ||
            values.id.indexOf("_new") === 0
          ) {
            let newShouldBlockNavigation = isChanged;
            if (values.id.indexOf("_new") === 0) {
              // mark block when adding new widget
              newShouldBlockNavigation = true;
            }
            setTimeout(
              () => setShouldBlockNavigation(newShouldBlockNavigation),
              20
            );
          }
          return (
            <FormComponent
              {...formProps}
              onDelete={() => {
                setIsOpenDeleteConfirmation(true);
              }}
              onCancel={() => {
                const noChange =
                  JSON.stringify(formProps.values) ===
                  JSON.stringify(initialValues);

                if (!noChange) {
                  setIsOpenConfirmationActionsUnsavedModal(true);
                } else {
                  if (values.id.indexOf("_new") === 0) {
                    onDeleteWidgetLocal();
                  } else {
                    if (isOpen) toggleOpen();
                    // setIsEditForm(false);
                  }
                }
              }}
              intl={intl}
              isWidgetDefault={get(jockConsole, "is_default", false)}
              isEdit={true}
              actionForm={values.id.indexOf("_new") === 0 ? "add" : "edit"}
              typesOptions={typesOptions}
              setValues={setValues}
              formikRef={formikRef}
              isHaveReplacementWidgets={isHaveReplacementWidgets}
            />
          );
        }}
      </Formik>

      {/* actions confirmation  unsaved */}
      <ConfirmationModal
        isOpen={isOpenConfirmationActionsUnsavedModal}
        title={intl.formatMessage({
          id:
            "jock console manager > wait, you have unsaved changes in this form",
        })}
        isCloseOutside={false}
        className={bem.e("confirmation-modal")}
        description={intl.formatMessage({
          id:
            "jock console manager > before you can perform this step, you must save or discard your changes",
        })}
        confirmTitle={intl.formatMessage({
          id: "jock console manager > button save",
        })}
        discardTitle={intl.formatMessage({
          id: "jock console manager > button discard",
        })}
        cancelTitle={intl.formatMessage({
          id: "jock console manager > button cancel",
        })}
        onConfirm={() => {
          if (formikRef && formikRef.current) {
            setIsOpenConfirmationActionsUnsavedModal(false);
            onSubmitOutSideForm();
          }
        }}
        onToggle={() =>
          setIsOpenConfirmationActionsUnsavedModal(
            !isOpenConfirmationActionsUnsavedModal
          )
        }
        onDiscard={() => {
          if (formikRef && formikRef.current) {
            formikRef.current.resetForm(initialValues);
          }
          if (jockConsole.id.indexOf("_new") === 0) {
            onDeleteWidgetLocal();
          }
          setIsOpenConfirmationActionsUnsavedModal(false);
          toggleOpen();
        }}
        onCancel={() => setIsOpenConfirmationActionsUnsavedModal(false)}
      />
    </div>
  );
  return form;
  // return (
  //   <WarnAboutUnsavedChanges when={shouldBlockNavigation} children={form} />
  // );
}

function JockConsoleView(props) {
  const {
    intl,
    jockConsole,
    setIsOpenDeleteConfirmation,
    setIsEditForm,
  } = props;
  const typesOptions = map(types, (type) => ({
    label: intl.formatMessage({
      id: `jock console manager > type ${type.label}`,
    }),
    value: type.key,
    data: type,
  }));
  return (
    <div className={bem.e("content-view")}>
      <ViewComponent
        onDelete={() => setIsOpenDeleteConfirmation(true)}
        onEdit={() => setIsEditForm(true)}
        intl={intl}
        typesOptions={typesOptions}
        jockConsole={jockConsole}
      />
    </div>
  );
}

export default function JockConsole(props) {
  const {
    isEditForm,
    intl,
    isOpenDeleteConfirmation,
    setIsOpenDeleteConfirmation,
    onDelete,
  } = props;
  return (
    <React.Fragment>
      {!isEditForm ? (
        <JockConsoleView {...props} />
      ) : (
        <JockConsoleForm {...props} />
      )}
      <ConfirmationModal
        isOpen={isOpenDeleteConfirmation}
        title={intl.formatMessage({
          id: "jock console manager > delete widget modal title",
        })}
        className={bem.e("confirmation-modal")}
        confirmTitle={intl.formatMessage({
          id: "jock console manager > button yes delete",
        })}
        cancelTitle={intl.formatMessage({
          id: "jock console manager > button cancel",
        })}
        onToggle={() => setIsOpenDeleteConfirmation(!isOpenDeleteConfirmation)}
        onConfirm={() => {
          onDelete();
        }}
        onCancel={() => setIsOpenDeleteConfirmation(false)}
        isCloseOutside={false}
      />
    </React.Fragment>
  );
}
