import React from "react";
import { get, isArray, isObject } from "lodash";
import { FormattedMessage } from "react-intl";
import URL from "utils/urls";
import PropTypes from "prop-types";
import classnames from "classnames";
import Pagination from "components/Pagination";
import { CardBody, Label } from "reactstrap";
import Spinner from "components/Spinner";
import ButtonClear from "components/ButtonClear";
import bn from "utils/bemnames";
import AuditsTable from "./AuditsTable";
import { pageSize } from "utils/config";
import Avatar from "../Avatar";
import ReactJson from "react-json-view";
import SearchInput from "components/SearchInput";
import Checkbox from "components/Checkbox";
import moment from "moment";
import ReactDOM from "react-dom";

const bem = bn.create("audit-list");
export const MODE_IN_TAB = "mode_in_tab";
export const MODE_IN_SIDEBAR = "mode_in_sidebar";
class AuditList extends React.Component {
  componentDidMount() {
    document.addEventListener(
      "click",
      this.handleClickOutside.bind(this),
      true
    );
    const filter = get(this.props, "filter");
    const sort = get(this.props.audits["filter_" + filter], "sort", "");
    const page = get(this.props.audits["filter_" + filter], "page", "1");
    const rpp = get(this.props.audits["filter_" + filter], "rpp", pageSize);
    const search = get(this.props.audits["filter_" + filter], "search", "");
    const category = get(this.props.audits["filter_" + filter], "category", "");

    let stations;
    if (filter === "grand_audits_list" || filter === "qualifying_audits_list") {
      stations = get(this.props, "audit.data.fields.order_stations.value", [])
        .map((station) => station)
        .join(",");
    }

    if (filter) {
      if (
        !this.props.audits["filter_" + filter] ||
        this.props.audits["filter_" + filter].is_dirty
      ) {
        this.props.getAudits({
          filter,
          sort,
          page,
          rpp,
          search,
          category,
          stations,
        });
      }
    }
  }
  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
  }

  handleClickOutside = (event) => {
    if (this.refs.singleAuditDialog) {
      const domNode = ReactDOM.findDOMNode(this.refs.singleAuditDialog);
      if (!domNode || !domNode.contains(event.target)) {
        const { setSingleItem } = this.props;
        setSingleItem(false);
      }
    }
  };

  componentDidUpdate(prevProps) {
    const filter = get(this.props, "filter");
    let sort = get(this.props.audits["filter_" + filter], "sort", "");
    let page = get(this.props.audits["filter_" + filter], "page", "1");
    let rpp = get(this.props.audits["filter_" + filter], "rpp", pageSize);
    let search = get(this.props.audits["filter_" + filter], "search", "");
    const category = get(this.props.audits["filter_" + filter], "category", "");

    let stations;
    if (filter === "grand_audits_list" || filter === "qualifying_audits_list") {
      stations = get(this.props, "audit.data.fields.order_stations.value", [])
        .map((station) => station)
        .join(",");
    }
    if (
      !this.props.audits["filter_" + filter] ||
      this.props.audits["filter_" + filter].is_dirty
    ) {
      this.props.getAudits({
        filter,
        sort,
        page,
        rpp,
        search,
        category,
        stations,
      });
    }
  }
  onCheckRefreshClicked(state) {
    this.props.setAuditsAutoRefresh({ state });
  }
  sort = (field) => {
    const filter = get(this.props, "filter");
    let sort = get(this.props.audits["filter_" + filter], "sort", "");
    let page = get(this.props.audits["filter_" + filter], "page", "1");
    let rpp = get(this.props.audits["filter_" + filter], "rpp", pageSize);
    let search = get(this.props.audits["filter_" + filter], "search", "");
    const category = get(this.props.audits["filter_" + filter], "category", "");
    let stations;
    if (filter === "grand_audits_list" || filter === "qualifying_audits_list") {
      stations = get(this.props, "audit.data.fields.order_stations.value", [])
        .map((station) => station)
        .join(",");
    }
    // detect on third click
    if (sort === field + " desc") {
      sort = "";
    } else {
      sort = sort === field ? sort + " desc" : field;
    }
    this.props.getAudits({
      filter,
      sort,
      page,
      rpp,
      search,
      category,
      stations,
    });
  };
  search(event) {
    event.preventDefault();
    const filter = get(this.props, "filter");
    let sort = "search";
    let page = get(this.props.audits["filter_" + filter], "page", "1");
    let rpp = get(this.props.audits["filter_" + filter], "rpp", pageSize);
    const category = get(this.props.audits["filter_" + filter], "category", "");
    let search = event.target.elements[0].value;
    let stations;
    if (filter === "grand_audits_list" || filter === "qualifying_audits_list") {
      stations = get(
        this.props,
        "audit.data.fields.contract_stations.value",
        []
      )
        .map((station) => station)
        .join(",");
    }
    this.props.getAudits({
      filter,
      sort,
      page,
      rpp,
      search,
      category,
      stations,
    });
  }
  onClearSearch = () => {
    const filter = get(this.props, "filter");
    let sort = get(this.props.audits["filter_" + filter], "sort", "");
    let page = get(this.props.audits["filter_" + filter], "page", "1");
    let rpp = get(this.props.audits["filter_" + filter], "rpp", pageSize);
    const category = get(this.props.audits["filter_" + filter], "category", "");
    let stations;
    if (filter === "grand_audits_list" || filter === "qualifying_audits_list") {
      stations = get(
        this.props,
        "audit.data.fields.contract_stations.value",
        []
      )
        .map((station) => station)
        .join(",");
    }
    let search = "";
    this.props.getAudits({
      filter,
      sort,
      page,
      rpp,
      search,
      category,
      stations,
    });
  };
  usersDisplay(user_ids) {
    let users = user_ids.map((user_id) =>
      this.props.auth.info.users.find((user) => user.id === user_id)
    );
    return (
      <span>
        {users.map((user, index) => (
          <div key={index}>
            <Avatar src={user.avatar_url} size={20} className="mb-2" />
            {` `}
            {user.name}
          </div>
        ))}
      </span>
    );
  }

  actionDisplay(audit) {
    if (audit._source.last_action) {
      let payload = { message: audit._source.last_action };
      if (payload.message.chat) {
        return (
          <span>
            <Avatar src={get(payload, "message.from.avatar_url", "")} size={20} />
            {get(payload, "message.from.name", "")}
            <span>
              {" "}
              <FormattedMessage id="audit > wrote" />{" "}
            </span>
            <span>
              {` `}
              {payload.message.chat}
            </span>
          </span>
        );
      } else {
        return (
          <span>
            <Avatar src={get(payload, "message.from.avatar_url", "")} />
            {get(payload, "message.from.name", "")}
            <span>
              {" "}
              <FormattedMessage id="audit > submitted" />{" "}
            </span>
            <span>
              {` `}
              {payload.message.from_step &&
                payload.message.from_step.replace(/_/, " ", "gi") + "'"}
            </span>
          </span>
        );
      }
    }
    return null;
  }

  auditFormValues(audit) {
    var user = audit._source.user_info || {};
    var path = audit._source.path;
    const { dateFormatByServer }= this.props;
    if (
      audit._source.form_values &&
      audit._source.form_values.data &&
      audit._source.form_values.data.template_key
    )
      path = (
        <a
          href={
            "/processes/view/" +
            audit._source.form_values.data.template_key +
            "/" +
            path.split("/")[1]
          }
          onClick={(e) => {
            e.stopPropagation();
            return false;
          }}
        >
          {path}
        </a>
      );
    var fields = !audit._source.delta ? [] : { ...audit._source.delta };
    if (audit._source.delta) {
      Object.keys(audit._source.delta).map((k) => {
        var d = audit._source.delta[k];
        if (isArray(d)) {
          d.map((s, i) => {
            var delimiter = "(" + i + "), ";
            if (d.length < 2) delimiter = ", ";
            Object.keys(s).map((n) => (fields[k + delimiter + n] = s[n]));
          });
        }
        if (isObject(d)) {
          Object.keys(d).map((dn) => {
            if (isArray(audit._source.delta[dn])) {
              audit._source.delta[dn].map((s, i) => {
                var delimiter = "(" + i + "), ";
                if (d.length < 2) delimiter = ", ";
                Object.keys(s).map((n) => (fields[dn + delimiter + n] = s[n]));
              });
            }
          });
        }
      });
      Object.keys(fields).map((k) => {
        var d = fields[k];
        if (
          isArray(d) ||
          isObject(d) ||
          (!k.endsWith(".new") && !k.endsWith(".old"))
        ) {
          delete fields[k];
        }
      });
    }
    var action = (
      <span>
        <h2>
          {user.id && (
            <table className="plain">
              <tbody>
                <tr>
                  <td
                    style={{
                      borderTop: "none",
                      height: "80px",
                      padding: "0 10px",
                      width: "100px",
                    }}
                  >
                    {audit._source.level === "audit" && (
                      <span className="auditicon" style={{ color: "green" }}>
                        ⓘ
                      </span>
                    )}
                    {audit._source.level === "failure" && (
                      <span className="auditicon" style={{ color: "orange" }}>
                        ⚠
                      </span>
                    )}
                    {audit._source.level === "error" && (
                      <span className="auditicon" style={{ color: "red" }}>
                        ⚠
                      </span>
                    )}

                    <Avatar
                      src={user.avatar_url}
                      name={user.name}
                      size={80}
                      className="avatar"
                      style={{ marginRight: "10px" }}
                    />
                    {` `}
                  </td>
                  <td style={{ borderTop: "none", padding: 0, height: "40px" }}>
                    <span>{user.name ? user.name : user.email}</span>
                    <br />
                    <code
                      style={{
                        color: "#777",
                        fontSize: "10px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {user.email}
                    </code>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </h2>
        <hr />
        {audit._source.description
          ? audit._source.description.replace(new RegExp("_", "gi"), " ")
          : audit._source.path.replace(new RegExp("/", "gi"), " ")}

        {audit._source.level !== "audit" && <span> {audit._source.level}</span>}
        <br />
        <code style={{ color: "#777", fontSize: "10px" }}>
          {audit._source.action}, {audit._source.method}, {path}
        </code>
        <span>
          <hr />
          <Label>Audit level: </Label>
          {audit._source.level === "audit" && (
            <span>{audit._source.level}</span>
          )}
          {audit._source.level === "failure" && (
            <span style={{ color: "orange" }}>{audit._source.level}</span>
          )}
          {audit._source.level === "error" && (
            <span style={{ color: "red" }}>{audit._source.level}</span>
          )}
          <br />
          <Label>Audit section: </Label>
          {audit._source.end_point}
          <br />
          <Label>Audit group: </Label>
          {audit._source.broadcasting_group}
          <br />
          <Label>Audit time: </Label>
          {moment
            .unix(audit._source.created_at)
            .format(dateFormatByServer)
            .toString()}{" "}
          -{" "}
          {moment
            .unix(audit._source.created_at)
            .format("h:mm A")
            .toString()}
          <br />
          <hr />
          <Label>Audit stations: </Label>
          {audit._source.stations &&
            audit._source.stations.map((station, ind) => (
              <div key={ind}>{station}</div>
            ))}
          <hr />
          {audit._source.delta && (
            <div>
              <h2>Changed Fields</h2>
              {Object.keys(fields).map((key, ind) => {
                return key.indexOf(".new") > -1 ? (
                  <div key={ind} style={{ fontSize: "12px" }}>
                    <b>{key.replace(".new", "").replace(/_/gi, " ")}</b>
                    <br />
                    <span style={{ color: "gray" }}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            ("" + fields[key.replace(".new", ".old")])
                              .replace("<p>", "")
                              .replace("</p>", "") +
                            ` ➜ ` +
                            ("" + fields[key])
                              .replace("<p>", "")
                              .replace("</p>", ""),
                        }}
                      />
                    </span>
                  </div>
                ) : null;
              })}
            </div>
          )}
        </span>
      </span>
    );
    return action;
  }
  onClickRow = (audit) => {
    const { setSingleItem } = this.props;
    setSingleItem(audit);
  };

  onPageChange = (currentPage) => {
    const page = currentPage + 1;
    const filter = get(this.props, "filter");
    let sort = get(this.props.audits["filter_" + filter], "sort", "c");
    let rpp = get(this.props.audits["filter_" + filter], "rpp", pageSize);
    let search = get(this.props.audits["filter_" + filter], "search", "");
    const category = get(this.props.audits["filter_" + filter], "category", "");
    let stations;
    if (filter === "grand_audits_list" || filter === "qualifying_audits_list") {
      stations = get(this.props, "audit.data.fields.order_stations.value", [])
        .map((station) => station)
        .join(",");
    }
    this.props.getAudits({
      filter,
      sort,
      page,
      rpp,
      search,
      category,
      stations,
    });
  };

  onPageSizeChange = (rpp) => {
    const filter = get(this.props, "filter");
    let page = get(this.props.audits["filter_" + filter], "page", "1");
    let sort = get(this.props.audits["filter_" + filter], "sort", "");
    let search = get(this.props.audits["filter_" + filter], "search", "");
    const category = get(this.props.audits["filter_" + filter], "category", "");
    let stations;
    if (filter === "grand_audits_list" || filter === "qualifying_audits_list") {
      stations = get(this.props, "audit.data.fields.order_stations.value", [])
        .map((station) => station)
        .join(",");
    }
    this.props.getAudits({
      filter,
      sort,
      page,
      rpp,
      search,
      category,
      stations,
    });
  };

  onTypeFilter = (type) => {
    const filter = get(this.props, "filter");
    let page = get(this.props.audits["filter_" + filter], "page", "1");
    let sort = get(this.props.audits["filter_" + filter], "sort", "");
    let rpp = get(this.props.audits["filter_" + filter], "rpp", pageSize);
    let search = get(this.props.audits["filter_" + filter], "search", "");
    const category = get(this.props.audits["filter_" + filter], "category", "");
    let stations;
    if (filter === "grand_audits_list" || filter === "qualifying_audits_list") {
      stations = get(this.props, "audit.data.fields.order_stations.value", [])
        .map((station) => station)
        .join(",");
    }
    this.props.getAudits({
      filter,
      sort,
      page,
      rpp,
      search,
      category: category === type ? "" : type, // toggle un check
      stations,
    });
  };
  renderHeader = () => {
    const { filter, autoRefresh, setAuditsAutoRefresh } = this.props;

    let filter_type = filter.split("_")[0];
    let filter_title = filter.replace(/_/gi, " ");
    if (filter_type !== "section" && filter_type !== "station") {
      filter_title = filter_type;
    } else {
      filter_title = (
        <span>
          {filter_type}
          <b>{filter_title.replace(filter_type, "")}</b>
        </span>
      );
    }
    return (
      <div className={bem.e("header")}>
        <div className={bem.e("title")}>
          <FormattedMessage id={`audits > title all`} />{" "}
          {filter !== "all" && <span> / filtered by {filter_title}</span>}
        </div>
        <div className={bem.e("header-actions")}>
          {filter !== "all" && (
            <ButtonClear
              to={URL.AUDIT({
                filter: "all",
              })}
              children={<FormattedMessage id="audits > clear filter" />}
            />
          )}

          <Checkbox
            checked={autoRefresh}
            onChange={(checked) => {
              setAuditsAutoRefresh(checked);
            }}
            text={"Auto-refresh"}
            checkStyle={{ color: "#fff", width: "16", height: "14" }}
          />

          <SearchInput
            placeholder={this.props.intl.formatMessage({
              id: "audits > placeholder search input",
            })}
            onClear={this.onClearSearch}
            onSubmit={this.search.bind(this)}
            value={get(this.props.audits["filter_" + filter], "search", "")}
          />
        </div>
      </div>
    );
  };
  render() {
    const { audits, filter, user, users } = this.props;
    let sort = get(this.props.audits["filter_" + filter], "sort", "");
    let pages = get(this.props.audits["filter_" + filter], "pages");
    let page = get(this.props.audits["filter_" + filter], "page", 1);
    let rpp = get(this.props.audits["filter_" + filter], "rpp", pageSize);
    const auditList =
      get(audits, `filter_${filter}.data`, []) !== null
        ? get(audits, `filter_${filter}.data`, [])
        : [];
    if (auditList.length === 0 && this.props.empty_component)
      return this.props.empty_component;
    return (
      <div className={classnames("fluid-container", bem.b())}>
        {this.renderHeader()}
        <div
          className={classnames(bem.e("audit-list-container"), {
            [bem.e("no-padding")]: this.props.mode === MODE_IN_TAB,
          })}
        >
          {this.props.full_message && auditList.length > 0 && (
            <CardBody>{this.props.full_message}</CardBody>
          )}
          {this.props.empty_message && auditList.length === 0 && (
            <CardBody>{this.props.empty_message}</CardBody>
          )}
          <AuditsTable
            auditList={auditList}
            bem={bem}
            sort={sort}
            actionSort={this.sort}
            countdowns={this.props.countdowns}
            onClickRow={this.onClickRow}
            template={this.props.template}
            user={user}
            filter={filter}
            users={users}
            mode={this.props.mode}
            isLoading={get(audits, `filter_${filter}.loading`, false)}
            dateFormatByServer={this.props.dateFormatByServer}
          />
          {pages ? (
            <Pagination
              pages={Number(pages)}
              page={Number(page) - 1}
              pageSize={Number(rpp)}
              onPageSizeChange={this.onPageSizeChange}
              onPageChange={this.onPageChange}
            />
          ) : null}
        </div>
        <Spinner
          isLoading={!auditList || get(audits, `filter_${filter}.loading`)}
        />
        {this.props.singleItem && (
          <div className="single-audit-dialog" ref="singleAuditDialog">
            <div
              className="close-button"
              onClick={() => this.props.setSingleItem(null)}
            >
              Close
            </div>
            {this.auditFormValues(this.props.singleItem)}

            {this.props.singleItem._source.form_values && (
              <span>
                <hr />
                <h2>Submitted Form Values</h2>
                <ReactJson
                  src={this.props.singleItem._source.form_values}
                  name="Form Values"
                  collapsed={2}
                  collapseStringsAfterLength={100}
                  displayObjectSize={false}
                  displayDataTypes={false}
                  quotesOnKeys={false}
                  displayArrayKey={false}
                  enableClipboard={false}
                />
              </span>
            )}
            {
              <span>
                <hr />
                <h2>Full Audit Source</h2>
                <ReactJson
                  src={this.props.singleItem._source}
                  name="Full Audit"
                  collapsed={0}
                  collapseStringsAfterLength={100}
                  displayObjectSize={false}
                  displayDataTypes={false}
                  quotesOnKeys={false}
                  displayArrayKey={false}
                  enableClipboard={false}
                />
              </span>
            }
          </div>
        )}
      </div>
    );
  }
}
AuditList.propTypes = {
  mode: PropTypes.oneOf([MODE_IN_TAB, MODE_IN_SIDEBAR]),
};
AuditList.defaultProps = {
  mode: MODE_IN_SIDEBAR,
};
export default AuditList;
