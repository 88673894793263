import SecurityPolicy from "./view";
import { get, first } from "lodash";
import { connect } from "react-redux";
import * as React from "react";
import { MdImportantDevices } from "react-icons/lib/md";
import ToastManager from "components/ToastManager";
import { compose, lifecycle, withHandlers, withState } from "recompose";
import {
  getSecurityPolicyUser,
  updateSecurityPolicyUser,
  updateSecurityPolicyUsers,
} from "store/actions/users";
import { injectIntl } from "react-intl";

export default injectIntl(
  compose(
    connect(
      (state, props) => {
        const ids = get(props, "ids", []);
        if (ids.length === 1) {
          let id = first(ids);
          return {
            id,
            isMultiple: false,
            user: get(state, `users.user.${id}`, {}),
            isLoading:
              get(state, `users.user.${id}.loading`, false) ||
              get(state, `users.updateUser.loading`, false),
            auth: state.auth,
            back_button: props.back_button,
          };
        } else {
          return {
            ids,
            isMultiple: true,
            isLoading: get(state, `users.updateUsers.loading`, false),
            auth: state.auth,
            back_button: props.back_button,
          };
        }
      },
      {
        getSecurityPolicyUser,
        updateSecurityPolicyUsers,
        updateSecurityPolicyUser,
      }
    ),
    withState("isView", "setIsView", (props) =>
      props.mode === "edit" || props.mode === "add" ? false : true
    ),
    withHandlers({
      onFormSubmit: ({
        id,
        updateSecurityPolicyUser,
        updateSecurityPolicyUsers,
        isMultiple,
        ids,
        intl,
        setIsView,
      }) => (values) => {
        if (isMultiple) {
          updateSecurityPolicyUsers(
            {
              ...values,
              ids,
            },
            () => {
              ToastManager.show({
                title: <MdImportantDevices />,
                message: intl.formatMessage({
                  id: "user > update successfully",
                }),
                level: "success",
              });
              setIsView(true);
            }
          );
        } else {
          updateSecurityPolicyUser(id, values, () => {
            ToastManager.show({
              title: <MdImportantDevices />,
              message: intl.formatMessage({
                id: "user > update successfully",
              }),
              level: "success",
            });
            setIsView(true);
          });
        }
      },
    }),
    lifecycle({
      componentDidMount() {
        const { isFocused, id } = this.props;
        // only call when focused to this tab
        if (isFocused && id) {
          this.props.getSecurityPolicyUser(id);
        }
      },
      componentWillReceiveProps(nextProps) {
        // only call when focused to this tab
        if (
          get(this.props, "isFocused") !== get(nextProps, "isFocused") &&
          get(nextProps, "isFocused") &&
          get(nextProps, "id")
        ) {
          this.props.getSecurityPolicyUser(nextProps.id);
        }
      },
    })
  )(SecurityPolicy)
);
