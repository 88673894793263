import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody } from "reactstrap";
import bn from "utils/bemnames";
import ClientManagerForm from "components/ClientManagerForm";
import { CloseIcon } from "components/CustomIcons";
const bem = bn.create("edit-client-modal");

const EditClientModal = ({ onToggle, isOpen, onSubmit, ...rest }) => {
  return (
    <Modal isOpen={isOpen} className={bem.b()}>
      <ModalBody className={bem.e("body")}>
        <button className={bem.e("close")} onClick={onToggle}>
          <CloseIcon color={"#657894"} />
        </button>
        <ClientManagerForm
          onCancel={onToggle}
          onSubmittedSuccess={onSubmit}
          isModal
          {...rest}
        />
      </ModalBody>
    </Modal>
  );
};

EditClientModal.defaultProps = {
  isOpen: false,
  onToggle: () => {},
  onSubmit: () => {},
};
EditClientModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
export default EditClientModal;
