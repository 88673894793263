import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "reactstrap";
import { get, uniqBy } from "lodash";
import { FaPlus } from "react-icons/lib/fa";
import classnames from "classnames";
import ConfirmationModal from "components/ConfirmationModal";
import history from "components/History";
import bn from "utils/bemnames";
import PropTypes from "prop-types";
import PrivilegedComponent from "components/PrivilegedComponent";
import { PRIVILEGES } from "utils/constants";
import URL from "utils/urls";
import ToastManager from "components/ToastManager";
import ProcessDialog from "components/ProcessDialog";

function generateButtonTitle({ isView }) {
  if (isView) return <FormattedMessage id="process > button edit" />;
  return <FormattedMessage id="process > promotion > contest > button save" />;
}
function generateButtonPublishTitle({ isPublishedValue }) {
  if (isPublishedValue)
    return <FormattedMessage id="process > button unpublish" />;
  return <FormattedMessage id="process > button publish" />;
}
const bem = bn.create("promotion-liner-buttons");
function PrizeButtons(props) {
  const {
    isView,
    onSubmit,
    template,
    step,
    process,
    setActiveEditTab,
    user,
    isShowSaveDraftButton,
    desiredItemsSchedulingField,
    totalDesiredItems,
    intl,
    setValidationError,
    isShowDeleteButton,
    setIsOpenDialogDelete,
    setIsOpenDialogPublish,
    isDisabledPublish,
    isPublishedValue,
    theme,
    onValueChanged,
    setIsOpenDetails,
    sectionKey,
  } = props;
  const isPrizesCompleted = get(process, "data.fields.prizes_status.value", "");
  const [isOpenPreviewModal, setIsOpenPreviewModal] = useState(false);
  const isPickYourPrizeTheme = theme === "pick_your_prize";
  const showAddPrizeButton = !isView && isPickYourPrizeTheme;
  return (
    <React.Fragment>
      <div className={classnames(bem.e("buttons"), "buttons")}>
        <div className={bem.e("buttons-left")}>
          <PrivilegedComponent requires={[PRIVILEGES.CONTEST_SAVE]}>
            <Button
              color="blue"
              className={classnames(bem.e("btn-submit"), "btn-radius")}
              onClick={(event) => {
                if (isPrizesCompleted) {
                  setActiveEditTab("winners");
                  return;
                }
                if (isView) {
                  history.push(
                    "/processes/edit/" +
                      template.key +
                      "/" +
                      get(process, "data.fields.key.value")
                  );
                  setActiveEditTab(step.key);
                } else {
                  onSubmit(event);
                }
              }}
            >
              {isPrizesCompleted ? (
                <FormattedMessage id="process > promotion > contest > button review fulfillment" />
              ) : (
                generateButtonTitle({ isView })
              )}
            </Button>
          </PrivilegedComponent>
          <PrivilegedComponent requires={[PRIVILEGES.CONTEST_PUBLISH]}>
            <Button
              color="blue"
              outline
              className={classnames("btn-radius")}
              onClick={() => {
                setIsOpenDialogPublish(true);
              }}
              disabled={isDisabledPublish}
            >
              {generateButtonPublishTitle({
                isPublishedValue,
              })}
            </Button>
          </PrivilegedComponent>
          {/* button save draft */}
          {!isView && isShowSaveDraftButton && (
            <PrivilegedComponent requires={[PRIVILEGES.CONTEST_SAVE_DRAFT]}>
              <Button
                color="blue"
                outline
                className={classnames(bem.e("btn-draft"), "btn-radius")}
                onClick={(event) => {
                  const validate = desiredItemsSchedulingField.validation(
                    step,
                    desiredItemsSchedulingField,
                    {
                      fields: process.data.fields,
                    },
                    template,
                    user,
                    totalDesiredItems
                  );
                  if (validate !== true) {
                    setValidationError(desiredItemsSchedulingField, validate);
                    const message = `${intl.formatMessage({
                      id: `process > title ${template.key} step order_details`,
                    })}: ${validate}`;
                    ToastManager.show({
                      title: intl.formatMessage({
                        id: "toast > title not saved",
                      }),
                      message,
                      level: "error",
                    });
                    return;
                  }
                  props.buttonClicked(
                    step,
                    {
                      ...step.fields.find((f) => f.key === "save_button"),
                      draft: true,
                    },
                    process,
                    template,
                    user,
                    event,
                    null,
                    true
                  );
                }}
              >
                <FormattedMessage id="process > button save draft" />
              </Button>
            </PrivilegedComponent>
          )}
          {showAddPrizeButton && (
            <Button
              onClick={() => {
                const selectedPrizes = get(
                  process,
                  "data.fields.prizes.value",
                  ""
                );
                const prizes = step.fields.find((f) => f.field === "prizes");
                onValueChanged(
                  prizes,
                  uniqBy(
                    [
                      ...selectedPrizes,
                      {
                        key: "_new",
                        per_play: 1,
                        total_allotment: 0,
                        total_value: 0,
                      },
                    ],
                    "key"
                  )
                );
                setIsOpenDetails(sectionKey);
              }}
              color="blue"
              outline
              className={"btn-radius btn btn-add"}
            >
              <FaPlus />
              <FormattedMessage id="process > promotion > contest > add another prize" />
            </Button>
          )}

          {!isView && (
            <Button
              color="link"
              className="process-link-clear-form"
              onClick={() => {
                setIsOpenPreviewModal(true);
              }}
            >
              <FormattedMessage id="process > button duplicate" />
            </Button>
          )}
        </div>
        {isShowDeleteButton && (
          <PrivilegedComponent requires={[PRIVILEGES.CONTEST_DELETE]}>
            <Button
              outline
              color="blue"
              className={classnames("btn-radius")}
              onClick={() => setIsOpenDialogDelete(true)}
            >
              <FormattedMessage id={`process > button delete`} />
            </Button>
          </PrivilegedComponent>
        )}
      </div>
      <ProcessDialog
        isOpenDialog={isOpenPreviewModal}
        onToggleDialog={() => setIsOpenPreviewModal(!isOpenPreviewModal)}
        process={{
          _id: process.data.fields.key.value,
          _source: {
            order_sold: get(process, "data.fields.order_sold.value"),
            template_key: get(process, "data.fields.template_key.value"),
          },
        }}
      />
    </React.Fragment>
  );
}
export default function View(props) {
  const [isOpenDialogDelete, setIsOpenDialogDelete] = useState(false);

  const {
    step,
    template,
    user,
    process,
    isView,
    isShowSaveDraftButton,
    isShowClearForm,
    onValueChanged,
    isShowPublishButton,
    isShowDeleteButton,
    sectionKey,
    setIsOpenDetails,
    setValidationError,
    intl,
  } = props;
  const [isOpenDialogPublish, setIsOpenDialogPublish] = useState(false);
  const [fieldRefs, setFieldRefs] = useState(null);
  const desiredItemsSchedulingField = step.fields.find(
    (f) => f.field === "desired_items_scheduling"
  );
  const orderTitleField = step.fields.find((f) => f.field === "order_title");
  const orderStationsField = step.fields.find(
    (f) => f.field === "order_stations"
  );

  const totalDesiredItems = get(
    process,
    "data.fields.desired_items_scheduling.value"
  );
  const orderTitle = get(process, "data.fields.order_title.value", "");
  const orderStations = get(process, "data.fields.order_stations.value", "");

  const onSubmit = (event) => {
    const item = step.fields.find((f) => f.key === "save_button");
    props.buttonClicked(step, item, process, template, user, event, null, true);
  };
  const getFieldRefs = () => {
    if (fieldRefs) return fieldRefs;

    const is_publish = step.fields.find((f) => f.field === "is_publish");
    const payload = {
      is_publish,
    };

    setFieldRefs(payload);

    return payload;
  };
  const { is_publish } = getFieldRefs();

  const isPublishedValue = get(process, "data.fields.is_publish.value", false);
  // They should only be able to do this after they have completed both Details and Script sections.
  const isDisabledPublish =
    get(process, "data.fields.process_step_index.value") < 3 ||
    !get(process, "data.fields.key.value");

  const theme = get(process, "data.fields.theme.value", "");
  const isPrizesTab = step.key === "prizes";
  return (
    <div className={"bottom-actions-sticky"}>
      {/* {showAddPrizeButton && (
        <div className={classnames(bem.e("buttons"), "multi-row")}>
          <div className={bem.e("buttons-left")}>
            <Button
              onClick={() => {
                const selectedPrizes = get(
                  process,
                  "data.fields.prizes.value",
                  ""
                );
                const prizes = step.fields.find((f) => f.field === "prizes");
                onValueChanged(
                  prizes,
                  uniqBy(
                    [
                      ...selectedPrizes,
                      {
                        key: "_new",
                        per_play: 1,
                        total_allotment: 0,
                        total_value: 0,
                      },
                    ],
                    "key"
                  )
                );
                setIsOpenDetails(sectionKey);
              }}
              color="blue"
              outline
              className={"btn-radius btn btn-add"}
            >
              <FaPlus />
              <FormattedMessage id="process > promotion > contest > add another prize" />
            </Button>
          </div>
        </div>
      )} */}
      {isPrizesTab ? (
        <PrizeButtons
          isView={isView}
          onSubmit={onSubmit}
          template={template}
          step={step}
          process={process}
          buttonClicked={props.buttonClicked}
          setActiveEditTab={props.setActiveEditTab}
          user={user}
          isShowSaveDraftButton={isShowSaveDraftButton}
          desiredItemsSchedulingField={desiredItemsSchedulingField}
          totalDesiredItems={totalDesiredItems}
          intl={intl}
          setValidationError={setValidationError}
          isShowDeleteButton={isShowDeleteButton}
          setIsOpenDialogDelete={setIsOpenDialogDelete}
          setIsOpenDialogPublish={setIsOpenDialogPublish}
          isPublishedValue={isPublishedValue}
          isDisabledPublish={isDisabledPublish}
          onValueChanged={onValueChanged}
          theme={theme}
          setIsOpenDetails={setIsOpenDetails}
          sectionKey={sectionKey}
        />
      ) : (
        <div className={classnames(bem.e("buttons"), "buttons")}>
          <div className={bem.e("buttons-left")}>
            <PrivilegedComponent requires={[PRIVILEGES.CONTEST_SAVE]}>
              <Button
                color="blue"
                className={classnames(bem.e("btn-submit"), "btn-radius")}
                onClick={(event) => {
                  if (isView) {
                    history.push(
                      "/processes/edit/" +
                        template.key +
                        "/" +
                        get(process, "data.fields.key.value")
                    );
                    props.setActiveEditTab(step.key);
                  } else {
                    onSubmit(event);
                  }
                }}
              >
                {generateButtonTitle({ isView })}
              </Button>
            </PrivilegedComponent>
            {/* button save draft */}
            {!isView && isShowSaveDraftButton && (
              <PrivilegedComponent requires={[PRIVILEGES.CONTEST_SAVE_DRAFT]}>
                <Button
                  color="blue"
                  outline
                  className={classnames(bem.e("btn-draft"), "btn-radius")}
                  onClick={(event) => {
                    const validateTitle = orderTitleField.validation(
                      step,
                      orderTitleField,
                      {
                        fields: process.data.fields,
                      },
                      template,
                      user,
                      orderTitle
                    );
                    const validateStations = orderStationsField.validation(
                      step,
                      orderStationsField,
                      {
                        fields: process.data.fields,
                      },
                      template,
                      user,
                      orderStations
                    );
                    let errors = [];
                    if (validateTitle !== true) {
                      setValidationError(orderTitleField, validateTitle);
                      errors.push(validateTitle);
                    }
                    if (validateStations !== true) {
                      setValidationError(orderStationsField, validateStations);
                      errors.push(validateStations);
                    }
                    if (errors.length > 0) {
                      const message = `${intl.formatMessage({
                        id: `process > title ${template.key} step order_details`,
                      })}: ${errors.join(", ")}`;
                      ToastManager.show({
                        title: intl.formatMessage({
                          id: "toast > title not saved",
                        }),
                        message,
                        level: "error",
                      });
                      return;
                    }
                    props.buttonClicked(
                      step,
                      {
                        ...step.fields.find((f) => f.key === "save_button"),
                        draft: true,
                      },
                      process,
                      template,
                      user,
                      event,
                      null,
                      true
                    );
                  }}
                >
                  <FormattedMessage id="process > button save draft" />
                </Button>
              </PrivilegedComponent>
            )}
            {isShowClearForm && (
              <Button
                color=""
                className="process-link-clear-form"
                onClick={props.onClearForm}
              >
                <FormattedMessage id="process > button clear form" />
              </Button>
            )}
            {isShowDeleteButton && (
              <PrivilegedComponent requires={[PRIVILEGES.CONTEST_DELETE]}>
                <Button
                  color="link"
                  outline
                  className="process-link-clear-form"
                  onClick={() => setIsOpenDialogDelete(true)}
                >
                  <FormattedMessage id={`process > button delete`} />
                </Button>
              </PrivilegedComponent>
            )}
          </div>

          {isShowPublishButton && (
            <PrivilegedComponent requires={[PRIVILEGES.CONTEST_PUBLISH]}>
              <Button
                color="blue"
                outline
                className={classnames("btn-radius")}
                onClick={() => {
                  setIsOpenDialogPublish(true);
                }}
                disabled={isDisabledPublish}
              >
                {generateButtonPublishTitle({
                  isPublishedValue,
                })}
              </Button>
            </PrivilegedComponent>
          )}
        </div>
      )}

      {/* delete modal */}
      <ConfirmationModal
        isOpen={isOpenDialogDelete}
        onToggle={() => setIsOpenDialogDelete(!isOpenDialogDelete)}
        onCancel={() => setIsOpenDialogDelete(false)}
        title={<FormattedMessage id="process > confirm delete contest" />}
        className="modal-delete"
        onConfirm={() => {
          props.deleteProcess(
            "@andspots@" + get(process, "data.fields.key.value"),
            () => {
              setIsOpenDialogDelete(false);
              const nextRoute = URL.PROCESSES({
                filter: "promotion_contests",
              });
              history.push(nextRoute);
            }
          );
        }}
        isCloseOutside={false}
      />
      {/* publish */}
      <ConfirmationModal
        isOpen={isOpenDialogPublish}
        onToggle={() => setIsOpenDialogPublish(!isOpenDialogPublish)}
        onCancel={() => setIsOpenDialogPublish(false)}
        className={bem.e("confirmation-publish-modal")}
        title={
          isPublishedValue ? (
            <span>
              <FormattedMessage id="process > confirm unpublish item" />
              <FormattedMessage id="process > confirm unpublish item description" />
            </span>
          ) : (
            <span>
              <FormattedMessage id="process > confirm publish item" />
              <FormattedMessage id="process > confirm publish item description" />
            </span>
          )
        }
        cancelTitle={<FormattedMessage id="process > button cancel" />}
        description={null}
        onConfirm={(event) => {
          setIsOpenDialogPublish(false);
          onValueChanged(is_publish, !isPublishedValue);
          process.data.fields.is_publish = {
            value: !isPublishedValue,
            is_dirty: true,
          };
          props.buttonClicked(
            step,
            step.fields.find((f) => f.key === "save_button"),
            process,
            template,
            user,
            event,
            null,
            true
          );
        }}
        isCloseOutside={false}
      />
    </div>
  );
}
View.propTypes = {
  isShowSaveDraftButton: PropTypes.bool,
  isShowPublishButton: PropTypes.bool,
  isShowDeleteButton: PropTypes.bool,
};
View.defaultProps = {
  isShowSaveDraftButton: true,
  isShowPublishButton: false,
  isShowDeleteButton: false,
};
