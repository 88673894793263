import React from 'react';
import UploadFile from 'components/UploadFile/index';
import PrivilegedComponent from 'components/PrivilegedComponent';
import { PRIVILEGES } from 'utils/constants';
import { Redirect } from 'react-router';

const View = ({ url, onChange }) => {
  return (
    <PrivilegedComponent requires={[PRIVILEGES.UPLOAD_FILE]}>
      {({ isValid }) => {
        if (!isValid) {
          return (
            <UploadFile
              url={
                'http://promosuite-dev.s3-website-us-east-1.amazonaws.com/qxysugXTDKRAmZX5rN6AwVA829QRfAxTUH4vfvy1.png'
              }
              mode="view"
              mimes={['audio/mp3', 'image/*']}
              onDeleted={file => {
                console.log('====================================');
                console.log({ file });
                console.log('====================================');
              }}
              onUploaded={reponse => {
                console.log('====================================');
                console.log({ reponse });
                console.log('====================================');
              }}
            />
          );
        } else {
          return <Redirect to="/" />;
        }
      }}
    </PrivilegedComponent>
  );
};

export default View;
