import React, { Component } from "react";
import {
  FaSpinner,
  FaEdit,
  FaEraser,
  FaCheck,
  FaRepeat,
} from "react-icons/lib/fa";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Input,
} from "reactstrap";
import bn from "utils/bemnames";
import classnames from "classnames";
import DynamicForm from "./formIndex";
import CommentsSidebar from "components/CommentsSidebar";
import ContinuityIcon from "assets/img/tabs/Continuity.png";
import ContinuityWhiteIcon from "assets/img/tabs/Continuity_white.png";
import DubIcon from "assets/img/tabs/Dub.png";
import DubWhiteIcon from "assets/img/tabs/Dub_white.png";
import RecordIcon from "assets/img/tabs/Record.png";
import RecordWhiteIcon from "assets/img/tabs/Record_white.png";
import ScriptIcon from "assets/img/tabs/Script.png";
import ScriptWhiteIcon from "assets/img/tabs/Script_white.png";
import StarIcon from "assets/img/tabs/Star.png";
import StarWhiteIcon from "assets/img/tabs/Star_white.png";
import LogIcon from "assets/img/tabs/Log.png";
import LogWhiteIcon from "assets/img/tabs/Log_white.png";
import TeamIcon from "assets/img/tabs/Team.png";
import TeamWhiteIcon from "assets/img/tabs/Team_white.png";
import ReviewIcon from "assets/img/tabs/Review.png";
import ReviewWhiteIcon from "assets/img/tabs/Review_white.png";
import VoIcon from "assets/img/tabs/Vo.png";
import VoWhiteIcon from "assets/img/tabs/Vo_white.png";
import PrizesIcon from "assets/img/tabs/Prizes.png";
import PrizesWhiteIcon from "assets/img/tabs/Prizes_white.png";
import DesignIcon from "assets/img/tabs/Design.png";
import DesignWhiteIcon from "assets/img/tabs/Design_white.png";
import TrafficIcon from "assets/img/tabs/traffic.png";
import TrafficWhiteIcon from "assets/img/tabs/traffic_white.png";
import ClientIcon from "assets/img/tabs/Client.png";
import ClientWhiteIcon from "assets/img/tabs/Client_white.png";
import ScheduleIcon from "assets/img/tabs/Schedule.png";
import ScheduleWhiteIcon from "assets/img/tabs/Schedule_white.png";
import WinnerIcon from "assets/img/tabs/Winner.png";
import WinnerWhiteIcon from "assets/img/tabs/Winner_white.png";
import ReportsIcon from "assets/img/tabs/Reports.png";
import ReportsWhiteIcon from "assets/img/tabs/Reports_white.png";
import ConfirmationModal from "components/ConfirmationModal";

// import { countdownWithTimezone as countdownWithTimezoneHelper } from "utils/helpers";
import {
  get,
  upperCase,
  isFunction,
  find,
  map,
  flatten,
  isEmpty,
  intersection,
} from "lodash";
import history from "components/History";
import URL from "utils/urls";
import { FormattedMessage } from "react-intl";
import AlertNotAllowed from "components/AlertNotAllowed";
import { PRIVILEGES } from "utils/constants";
import { validatePrivileges } from "utils/helpers";
import OrderHeadInfo from "./components/orderHeadInfo";
import Breadcrumb from "./components/breadcrumb";

const bem = bn.create("add-update-process");

function getConditionPrivileges(template) {
  const isDigital = get(template, "key") === "digital";
  const isProgrammingElement = get(template, "key") === "liner";
  const isContest = get(template, "key") === "contest";
  const isProduction =
    get(template, "key") === "basic_production" ||
    get(template, "key") === "production_provided";
  if (isDigital) {
    return [
      PRIVILEGES.VIEW_ALL_DIGITAL_ORDERS,
      PRIVILEGES.EDIT_ALL_DIGITAL_ORDERS,
    ];
  }
  if (isProgrammingElement) {
    return [
      PRIVILEGES.PROGRAMMING_ELEMENT_SAVE_DRAFT,
      PRIVILEGES.PROGRAMMING_ELEMENT_SAVE,
      PRIVILEGES.PROGRAMMING_ELEMENT_PUBLISH,
      PRIVILEGES.PROGRAMMING_ELEMENT_EDIT,
      PRIVILEGES.PROGRAMMING_ELEMENT_VIEW,
      PRIVILEGES.PROGRAMMING_ELEMENT_DELETE,
    ];
  }
  if (isContest) {
    return [
      PRIVILEGES.CONTEST_SAVE_DRAFT,
      PRIVILEGES.CONTEST_SAVE,
      PRIVILEGES.CONTEST_PUBLISH,
      PRIVILEGES.CONTEST_EDIT,
      PRIVILEGES.CONTEST_VIEW,
      PRIVILEGES.CONTEST_DELETE,
    ];
  }
  if (isProduction) {
    return [
      PRIVILEGES.VIEW_ALL_PRODUCTION_ORDERS,
      PRIVILEGES.EDIT_ALL_PRODUCTION_ORDERS,
    ];
  }
  return [];
}
class Tabs extends Component {
  constructor(props) {
    super(props);
    this.refTabs = [];
    this.toggle = this.toggle.bind(this);
    this.state = { editTab: false };
    this.submitRefFunc = React.createRef();
  }

  getTabIndexFromKey(tab_key) {
    if (
      tab_key == undefined &&
      history.location != undefined &&
      history.location.search != undefined &&
      history.location.search
    ) {
      let processKey = history.location.search.split("=");
      if (processKey.length >= 2) {
        tab_key = processKey[1];
      }
    }

    let template = this.props.template;
    let tab_index = 0;
    Object.keys(template.steps).map((key, ind) => {
      if (key === tab_key) tab_index = ind;
      return true;
    });
    return tab_index;
  }

  getTabKeyFromIndex(tab_index) {
    let template = this.props.template;
    let tab_key = null;
    Object.keys(template.steps).map((key, ind) => {
      if (ind === tab_index) tab_key = key;
      return true;
    });
    return tab_key;
  }

  changeTab(tab) {
    const { template, process, user } = this.props;
    const tab_key = this.getTabKeyFromIndex(tab);
    const tabData = get(template, `steps.${tab_key}`, {});
    if (process.activeTab !== tab_key) {
      this.props.setActiveTab(tab_key);
      this.scrollToTop();
      // Only keep shouldBlockNavigation when user is moving to other tabs for liner, contest
      if (!["liner", "contest"].includes(template.key)) {
        this.props.setShouldBlockNavigation(false);
      }
      if (process.data.fields.key) {
        const privileges = get(user, "privileges", []);
        let isForceEdit = false;
        // Make the order open up in EDIT mode when clicked on, if the User has the EDIT privileges . https://tasks.getventive.com/projects/D6CB3-068
        if (["liner", "contest"].includes(template.key)) {
          const isUserHasEditPrivileges = validatePrivileges({
            requires: tabData.step_editors_privileges,
            privileges,
            user,
          });
          if (isUserHasEditPrivileges) {
            isForceEdit = true;
          }
        }
        if (tabData.is_open_edit || isForceEdit) {
          history.replace(
            URL.UPDATE_PROCESS({
              template_id: process.data.fields.template_key.value,
              process_id: process.data.fields.key.value,
            })
          );
        } else {
          history.replace(
            URL.VIEW_PROCESS({
              template_id: process.data.fields.template_key.value,
              process_id: process.data.fields.key.value,
            })
          );
        }
      }
    }
  }
  toggle(tab) {
    this.changeTab(tab);
  }

  scrollToTop() {
    window.scrollTo(0, 0);
    if (document.getElementById("cr-app-main"))
      document.getElementById("cr-app-main").scrollTo(0, 0);
  }

  renderAdTypes = () => {
    const { process, template } = this.props;
    const order_sold =
      !process.data.fields.order_sold || process.data.fields.order_sold.value;
    let title;
    let className;
    if (!order_sold) {
      title = "SPEC";
      className = bem.e("ad-types-title-spec");
    } else {
      title = "SOLD";
      className = bem.e("ad-types-title-sold");
    }
    if (template.key === "liner") title = null;
    if (template.key === "contest") {
      title = "Contest";
      className = bem.e("ad-types-title-contest");
    }

    return (
      <div className={classnames(bem.e("client-name-icon"), className)}>
        <div className={bem.e("icon-char")}>{title}</div>
      </div>
    );
  };
  renderClientName({ process, template }) {
    if (get(template, "key") === "liner") {
      let title = <FormattedMessage id="process > new item" />;
      if (get(process, "data.fields.order_title.value"))
        title = get(process, "data.fields.order_title.value");
      return title;
    }

    if (get(template, "key") === "contest") {
      let title = (
        <FormattedMessage id="process > promotion > contest > contest name" />
      );
      if (get(process, "data.fields.order_title.value"))
        title = get(process, "data.fields.order_title.value");
      return title;
    }

    if (get(process, "data.fields.client_name.value")) {
      return get(process, "data.fields.client_name.value");
    }
    let title = <FormattedMessage id="process > client name" />;
    if (get(template, "key") === "promotion") return null;
    return title;
  }
  getSpotsLength(process) {
    if (
      get(process, "data.fields.contract_status.value") === "Production Needed"
    )
      return null;
    return get(process, "data.fields.spot_info.value.length") > 1
      ? `(+${get(process, "data.fields.spot_info.value.length") - 1} spot)`
      : "";
  }

  getOrderTitle({ process, template }) {
    if (get(template, "key") === "liner") {
      let title = <FormattedMessage id="process > liner type" />;
      if (get(process, "data.fields.order_type.value"))
        title = get(process, "data.fields.order_type.value");
      return title;
    }
    if (get(template, "key") === "contest") {
      const playsNumber = get(process, "data.fields.plays_number.value", "");
      if (playsNumber)
        return (
          <span>
            {playsNumber}
            {` `}
            <FormattedMessage id="process > promotion > contest > plays" />
          </span>
        );
      return <FormattedMessage id="process > promotion > contest > no plays" />;
    }
    if (get(process, "data.fields.order_title.value")) {
      return process.data.fields.order_title.value;
    }
    return <FormattedMessage id="process > title of spot" />;
  }

  getProcessStatus = () => {
    const { template, process } = this.props;
    let processStepStatus = get(process, "data.fields.process_step.value", "");
    processStepStatus = processStepStatus
      ? processStepStatus.replace(/_/, " ", "gi")
      : "";
    let defaultProcessTitle = <FormattedMessage id="process > new order" />;
    if (template.key === "liner") {
      defaultProcessTitle = <FormattedMessage id="process > liner new order" />;
    }
    if (template.key === "contest") {
      defaultProcessTitle = (
        <FormattedMessage id="process > contest new order" />
      );
    }
    const getChangeStatus = (status) => {
      if (
        get(status, "props.id") === "process > title script" &&
        get(process, "data.fields.approve_script_status.value") === "rejected"
      ) {
        return "SCRIPT CHANGE";
      }

      if (
        get(status, "props.id") === "process > title record" &&
        get(process, "data.fields.approve_recording_status.value") ===
          "rejected"
      ) {
        return "SPOT CHANGE";
      }

      return status;
    };

    if (template.key === "digital" && processStepStatus === "done") {
      processStepStatus = "online";
    }
    return process.data.fields.order_details_status &&
      process.data.fields.order_details_status.value &&
      process.data.fields.order_details_status.value === "draft"
      ? "DRAFT"
      : process.data.fields.process_step &&
        process.data.fields.process_step.value
      ? template.steps[process.data.fields.process_step.value]
        ? getChangeStatus(
            template.steps[process.data.fields.process_step.value].title
          )
        : upperCase(processStepStatus)
      : defaultProcessTitle;
  };
  render() {
    let editMode = this.props.editMode;
    let process = this.props.process;
    let template = this.props.template;
    let user = this.props.user;
    const location = this.props.location;
    const privileges = Object.values(
      get(this.props, "userInfo.privileges", {})
    );
    const isAdd = document.location.href.indexOf("processes/add") > -1;
    let toggle = this.toggle.bind(this);
    let ui_hint = this.props.ui_hint;
    if (
      !process.data ||
      !process.data.fields ||
      !process.data.fields.template_key
    ) {
      return null;
    }
    const templateKey = get(template, "key");
    const team = get(process, "data.fields.team.value", []);
    const marketing_channels = get(
      process,
      "data.fields.marketing_channels.value",
      []
    );
    const allChannelManagers = map(
      flatten(map(marketing_channels, "channel_managers")),
      "value"
    );

    const conditionPrivileges = getConditionPrivileges(template);
    const isValidAccess = validatePrivileges({
      requires: {
        or: conditionPrivileges,
      },
      privileges,
      user,
    });

    let hasAccessFrom = map(
      get(user, "has_access_from_users", []),
      (user) => user.id
    );
    const denyAccess =
      ![...allChannelManagers, ...team].includes(parseInt(user.id)) &&
      isEmpty(intersection(hasAccessFrom, team));

    const conditionCheckHasAccess = ["liner", "contest"].includes(templateKey)
      ? !process.loading && !isAdd && !isValidAccess
      : !process.loading && !isAdd && !isValidAccess && denyAccess;
    // check user allow to this order
    if (conditionCheckHasAccess) {
      return (
        <AlertNotAllowed
          title={<FormattedMessage id="process > title you are not allowed" />}
        />
      );
    }

    if (ui_hint)
      return (
        <DynamicForm
          ui_hint={ui_hint}
          process={process}
          template={template}
          tab_toggle={toggle}
          match_process_id={this.props.match_process_id}
          onPreparePage={this.props.onPreparePage}
          shouldBlockNavigation={this.props.shouldBlockNavigation}
          setShouldBlockNavigation={this.props.setShouldBlockNavigation}
          blockNavigationAction={this.props.blockNavigationAction}
          setBlockNavigationAction={this.props.setBlockNavigationAction}
          confirmationDialogProps={this.props.confirmationDialogProps}
          setConfirmationDialogProps={this.props.setConfirmationDialogProps}
        />
      );

    let activeTab = this.getTabIndexFromKey(process.activeTab);
    let current_step_key = find(Object.keys(template.steps), (key, ind) => {
      if (ind === activeTab) return key;
    });
    let current_step = current_step_key
      ? template.steps[current_step_key]
      : null;
    const is_hidden_chat = get(current_step, "is_hidden_chat", false);
    let previous_steps_completed = true; // eslint-disable-line

    let spots = [];
    let active_tab_is_hidden = false;
    let first_visible_tab = false;
    if (
      process.data.fields.key &&
      this.props.process.data.fields.spots &&
      this.props.process.data.fields.spots.value.length > 1
    ) {
      this.props.process.data.fields.spots.value.map((spot, ind) =>
        spots.push({ key: spot, component: <span> Spot {ind + 1} </span> })
      );
    }
    const isShowOrderHeadInfo =
      ["basic_production", "production_provided"].includes(templateKey) &&
      [
        "assign_team",
        "write_script",
        "assign_vo",
        "voice_over",
        "record_script",
      ].includes(get(current_step, "key", ""));

    let tabs = (
      <div
        className={classnames("fluid-container", bem.b(), bem.m(template.key))}
      >
        <div
          className={classnames(
            bem.e("header"),
            bem.e(`header-${template.key}`)
          )}
        >
          <Breadcrumb
            bem={bem}
            template={template}
            current_step={current_step}
            process={process}
          />
          <div
            className={classnames(
              bem.e("header-client"),
              bem.e("container-padding")
            )}
          >
            <div className={bem.e("header-content")}>
              <div className={bem.e("client-name-wrapper")}>
                {get(template, "key") !== "promotion" && this.renderAdTypes()}
                <div>
                  <div className={bem.e("client-name")}>
                    {this.renderClientName({ process, template })}
                  </div>
                  <div
                    className={bem.e("client-bottom")}
                    style={
                      this.props.tabWidth && isShowOrderHeadInfo
                        ? {
                            minWidth: `${this.props.tabWidth - 65}px`,
                          }
                        : {}
                    }
                  >
                    <div className={bem.e("client-text")}>
                      {this.getOrderTitle({ process, template })}

                      {this.getSpotsLength(process)}
                    </div>
                    {isShowOrderHeadInfo ? (
                      <OrderHeadInfo process={process} bem={bem} />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div id="tabs-container-bottom"></div>
          </div>
        </div>
        <div>
          {/* {
            spots.length>0 && <Nav tabs key="spots" style={{borderBottomWidth:'1px'}}>{
              spots.map((spot,ind)=><NavItem key={'spot_'+ind}><NavLink className={classnames({ active: spot.key === process.data.fields.key.value })}
              onClick={() => {this.switchToSpot(spot.key)}}>{spot.component}</NavLink></NavItem>)
              }</Nav>
					} */}
          <div
            className={classnames(
              bem.e("container-padding-tabs"),
              bem.e("container-tabs-sticky"),
              {
                // [bem.e("container-tabs-sticky")]: current_step.key !== 'schedule'
              }
            )}
            id="tabs-sticky"
          >
            <Nav tabs className={bem.e("tabs")} id="tabs-sticky-nav">
              {Object.keys(template.steps).map((key, ind) => {
                let step = template.steps[key];
                if (ind === activeTab) current_step = step;
                let is_disabled =
                  step.is_disabled && step.is_disabled(user, process, step);
                //if (!previous_steps_completed) is_disabled = true;
                let is_hidden_tab = step.is_check_hidden_tab
                  ? step.is_hidden && step.is_hidden(user, process, step)
                  : false;
                if (isFunction(step.is_check_hidden_tab)) {
                  is_hidden_tab =
                    step.is_check_hidden_tab(user, process, step) &&
                    step.is_hidden &&
                    step.is_hidden(user, process, step);
                }
                if (isFunction(step.is_check_hidden_tab_ui)) {
                  is_hidden_tab = step.is_check_hidden_tab_ui(
                    user,
                    process,
                    step,
                    is_hidden_tab
                  );
                }
                let is_hidden =
                  step.is_hidden && step.is_hidden(user, process, step);
                // When order opened from Produced Spot, it should not show the Spot Review tab.
                if (
                  get(location, "state.hidden_tabs", []).indexOf(step.key) !==
                  -1
                ) {
                  is_hidden_tab = true;
                }

                //show script review tab only when review is triggered and script is not yet finalized
                if (
                  (!get(
                    this.props.process,
                    "data.fields.process_step_index",
                    false
                  ) &&
                    key === "approve_script") ||
                  (this.props.process.data.fields["process_step_index"] &&
                    this.props.process.data.fields["process_step_index"]
                      .value != 4 &&
                    (!this.props.process.data.fields["approve_script_status"] ||
                      this.props.process.data.fields["approve_script_status"]
                        .value !== "rejected") &&
                    key === "approve_script")
                ) {
                  is_hidden_tab = true;
                }

                //show spot review tab only when review is triggered and recording is not yet finalized
                if (
                  (!get(
                    this.props.process,
                    "data.fields.process_step_index",
                    false
                  ) &&
                    key === "approve_recording") ||
                  (this.props.process.data.fields["process_step_index"] &&
                    this.props.process.data.fields["process_step_index"]
                      .value != 6 &&
                    (!this.props.process.data.fields[
                      "approve_recording_status"
                    ] ||
                      this.props.process.data.fields["approve_recording_status"]
                        .value !== "rejected") &&
                    key === "approve_recording")
                ) {
                  is_hidden_tab = true;
                }

                //added on 2nd Dec 20202 based on Katie's request
                if (key === "air_check") {
                  is_hidden_tab = true;
                }
                if (
                  !is_hidden &&
                  key !== "log" &&
                  step.step_viewers_privileges &&
                  ((Array.isArray(step.step_viewers_privileges) &&
                    step.step_viewers_privileges.length > 0) ||
                    get(step, "step_viewers_privileges.or.length", 0) > 0)
                ) {
                  // check user can view tab by privileges
                  const found =
                    validatePrivileges({
                      requires: step.step_viewers_privileges,
                      privileges,
                      user,
                    }) ||
                    validatePrivileges({
                      requires: step.step_editors_privileges,
                      privileges,
                      user,
                    });
                  if (!found) is_hidden = true;
                }

                // if (is_hidden && ind === activeTab) active_tab_is_hidden = true;
                if (!is_hidden && !first_visible_tab) first_visible_tab = key;
                let state = "undone";
                const iconMap = {
                  order_details: StarIcon,
                  order_details_hover: StarWhiteIcon,
                  assign_team: TeamIcon,
                  assign_team_hover: TeamWhiteIcon,
                  write_script: ScriptIcon,
                  write_script_hover: ScriptWhiteIcon,
                  approve_script: ReviewIcon,
                  approve_script_hover: ReviewWhiteIcon,
                  assign_vo: VoIcon,
                  assign_vo_hover: VoWhiteIcon,
                  voice_over: VoIcon,
                  voice_over_hover: VoWhiteIcon,
                  record_script: RecordIcon,
                  record_script_hover: RecordWhiteIcon,
                  approve_recording: ReviewIcon,
                  approve_recording_hover: ReviewWhiteIcon,
                  continuity: ContinuityIcon,
                  continuity_hover: ContinuityWhiteIcon,
                  dub_script: DubIcon,
                  dub_script_hover: DubWhiteIcon,
                  air_check: ReviewIcon,
                  air_check_hover: ReviewWhiteIcon,
                  log: LogIcon,
                  log_hover: LogWhiteIcon,
                  promotion_details: StarIcon,
                  promotion_details_hover: StarWhiteIcon,
                  promotion_request_details: StarIcon,
                  promotion_request_details_hover: StarWhiteIcon,
                  liner_schedule: LogIcon,
                  liner_schedule_hover: LogWhiteIcon,
                  prizes: PrizesIcon,
                  prizes_hover: PrizesWhiteIcon,
                  promotion_event_details: StarIcon,
                  promotion_event_details_hover: StarWhiteIcon,
                  team_equipment: TeamIcon,
                  team_equipment_hover: TeamWhiteIcon,
                  event_recap: StarIcon,
                  event_recap_hover: StarWhiteIcon,
                  // digital order
                  design: DesignIcon,
                  design_hover: DesignWhiteIcon,
                  review_media: ReviewIcon,
                  review_media_hover: ReviewWhiteIcon,
                  traffic: TrafficIcon,
                  traffic_hover: TrafficWhiteIcon,
                  report: ReportsIcon,
                  report_hover: ReportsWhiteIcon,
                  // promotion liner
                  schedule: ScheduleIcon,
                  schedule_hover: ScheduleWhiteIcon,
                  client: ClientIcon,
                  client_hover: ClientWhiteIcon,
                  // promotion contest
                  winners: WinnerIcon,
                  winners_hover: WinnerWhiteIcon,
                };
                let stateIconDom = null;
                if (
                  get(
                    this.props,
                    `process.data.fields.${step.key}_status.value`
                  )
                ) {
                  if (
                    this.props.process.data.fields[step.key + "_status"]
                      .value === "draft"
                  ) {
                    state = "draft";
                    stateIconDom = (
                      <span className={bem.e("tabs-icon-wrapper")}>
                        <FaEdit
                          color={activeTab === ind ? "white" : "orange"}
                        />
                        &nbsp;
                      </span>
                    );
                  } else if (
                    this.props.process.data.fields[step.key + "_status"]
                      .value === "rejected"
                  ) {
                    state = "rejected";
                    stateIconDom = (
                      <span className={bem.e("tabs-icon-wrapper")}>
                        <FaRepeat
                          color={activeTab === ind ? "white" : "#8165f3"}
                          className={bem.e("tab-checked-icon")}
                        />
                        &nbsp;
                      </span>
                    );
                  } else {
                    state = "done";
                    stateIconDom = (
                      <span className={bem.e("tabs-icon-wrapper")}>
                        <FaCheck
                          color={activeTab === ind ? "white" : "#8165f3"}
                          className={bem.e("tab-checked-icon")}
                        />
                        &nbsp;
                      </span>
                    );
                  }
                } else {
                  if (activeTab === ind) {
                    stateIconDom = (
                      <span className={bem.e("tabs-icon-wrapper")}>
                        {iconMap[`${current_step.key}_hover`] && (
                          <img
                            src={iconMap[`${current_step.key}_hover`]}
                            alt="Active"
                            width={14}
                            className={classnames(
                              "tabs-icon",
                              current_step.key
                            )}
                          />
                        )}
                        &nbsp;
                      </span>
                    );
                  } else if (iconMap[step.key]) {
                    stateIconDom = (
                      <span className={bem.e("tabs-icon-wrapper")}>
                        <img
                          src={iconMap[step.key]}
                          alt="Icon"
                          width={14}
                          className={classnames("tabs-icon", step.key)}
                          ref={(rel) => (this.refTabs[step.key] = rel)}
                        />
                        &nbsp;
                      </span>
                    );
                  } else {
                    stateIconDom = (
                      <span className={bem.e("tabs-icon-wrapper")}>
                        <img
                          src={ContinuityIcon}
                          ref={(rel) => (this.refTabs[step.key] = rel)}
                          alt="Icon"
                          width={14}
                          className={classnames("tabs-icon", step.key)}
                        />
                        &nbsp;
                      </span>
                    );
                  }
                }

                let component = (
                  <NavItem
                    key={key}
                    {...(is_hidden_tab && { hidden: "hidden" })}
                    className={classnames(bem.e("tab_item"), {
                      active: activeTab === ind,
                    })}
                    data-key={step.key}
                    data-index={ind}
                    onMouseEnter={(e) => {
                      const tab = this.refTabs[step.key];
                      if (!tab || !iconMap[`${step.key}_hover`]) return null;
                      tab.src = iconMap[`${step.key}_hover`];
                    }}
                    onMouseLeave={(e) => {
                      const tab = this.refTabs[step.key];
                      if (!tab || !iconMap[`${step.key}`]) return null;
                      tab.src = iconMap[`${step.key}`];
                    }}
                  >
                    <NavLink
                      disabled={is_disabled}
                      onClick={() => {
                        this.toggle(ind);
                      }}
                    >
                      {stateIconDom}
                      {step.title}
                      {editMode && (
                        <span>
                          &nbsp; &nbsp;
                          <FaEdit
                            color={activeTab === ind ? "white" : "white"}
                            onClick={() => {
                              this.setState({ editTab: !this.state.editTab });
                            }}
                          />
                          &nbsp;
                          <FaEraser
                            color={activeTab === ind ? "white" : "white"}
                            onClick={() => {
                              let newTemplate = editMode.clone(template);
                              delete newTemplate.steps[step.key];
                              editMode.update(newTemplate);
                            }}
                          />
                        </span>
                      )}
                    </NavLink>
                  </NavItem>
                );

                if (!is_hidden && state !== "done" && state !== "completed") {
                  previous_steps_completed = false;
                }
                return component;
              })}
              {editMode && (
                <NavItem
                  key={"edit"}
                  className={classnames(bem.e("tab_item"), {
                    active: activeTab === -1,
                  })}
                >
                  <NavLink
                    onClick={() => {
                      let newTemplate = editMode.clone(template);
                      newTemplate.steps["new_tab"] = {
                        process_step_index:
                          Object.keys(newTemplate.steps).length + 1,
                        key: "new_tab",
                        caption_color: "#CC1EE6",
                        title: "new tab",
                        step_roles: [],
                        step_viewers_roles: [],
                        step_editors_roles: [],
                        step_viewers_privileges: [],
                        step_editors_privileges: [],
                        description: null,
                        status: "initial",
                        disabled_until_completed_steps: [],
                        step_container: "none",
                        no_edit_button: false,
                        fields: [],
                      };
                      editMode.update(newTemplate);
                    }}
                  >
                    {"+ Add"}
                  </NavLink>
                </NavItem>
              )}
            </Nav>
          </div>
          {editMode && this.state.editTab && (
            <div
              className={bem.e("container-padding")}
              style={{ position: "relative" }}
            >
              Step Key:{" "}
              <Input
                value={current_step.key}
                onChange={({ target }) => {
                  let e = target;
                  let newTemplate = editMode.clone(template);
                  let newStep = editMode.clone(
                    newTemplate.steps[current_step.key]
                  );
                  newStep.title = e.value;
                  newStep.key = e.value;
                  newTemplate.steps[e.value] = newStep;
                  delete newTemplate.steps[current_step.key];
                  editMode.update(newTemplate);
                }}
              ></Input>
              <br />
              <br />
            </div>
          )}
          <div
            className={classnames(
              bem.e("container-padding"),
              bem.e("tab-content-container"),
              bem.e(`tab-content-${current_step_key}`)
            )}
          >
            <div>
              <Row className="tab-row-container">
                <TabContent activeTab={activeTab} className={"col"}>
                  {Object.keys(template.steps).map((key, ind) => {
                    if (activeTab !== ind) return null;
                    let step = template.steps[key];
                    return (
                      <TabPane tabId={ind} key={"key_" + ind}>
                        <DynamicForm
                          editMode={editMode}
                          step={step}
                          process={process}
                          template={template}
                          tab_toggle={toggle}
                          match_process_id={this.props.match_process_id}
                          onPreparePage={this.props.onPreparePage}
                          submitRefFunc={this.submitRefFunc}
                          shouldBlockNavigation={
                            this.props.shouldBlockNavigation
                          }
                          setShouldBlockNavigation={
                            this.props.setShouldBlockNavigation
                          }
                          blockNavigationAction={
                            this.props.blockNavigationAction
                          }
                          setBlockNavigationAction={
                            this.props.setBlockNavigationAction
                          }
                          confirmationDialogProps={
                            this.props.confirmationDialogProps
                          }
                          setConfirmationDialogProps={
                            this.props.setConfirmationDialogProps
                          }
                        />
                      </TabPane>
                    );
                  })}
                </TabContent>
                {!is_hidden_chat && (
                  <CommentsSidebar
                    isSidebarOpen={this.props.isSidebarOpen}
                    step={current_step}
                    current_step={current_step}
                    process={process}
                    template={template}
                    user={user}
                    recomputeFields={(user, template, process, step) => {
                      const isProduction =
                        template.key === "basic_production" ||
                        template.key === "production_provided";
                      const nextUsers = get(
                        process,
                        "data.fields.next_users.value",
                        []
                      );
                      // for production -> sales enter all fields -> not submit -> enter the comment -> submit -> we need to add current user to next_users -> it will show this order in the current tasks of user
                      if (isProduction && nextUsers.length === 0) {
                        process.data.fields.next_users = {
                          value: [user.id],
                          is_dirty: true,
                        };
                      }
                      return process;
                    }}
                  />
                )}
              </Row>
            </div>
          </div>
        </div>

        {/* {(process.loading || process.spotSwitching) && (
          <div className="cr-loading">
            <FaSpinner size="40" className={"cr-icon-spin"} />
          </div>
        )} */}
        {!!this.props.confirmationDialogProps ? (
          <ConfirmationModal
            {...this.props.confirmationDialogProps}
            isOpen={!!this.props.confirmationDialogProps}
          />
        ) : null}
      </div>
    );

    if (active_tab_is_hidden) {
      if (first_visible_tab) {
        this.props.setActiveTab(first_visible_tab);
      }
      return null;
    }

    return tabs;
  }
}

export default Tabs;
