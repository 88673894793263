import TeamStationsList from './view';
import { injectIntl } from 'react-intl';
import { compose, withHandlers } from 'recompose';
import { omit } from 'lodash';

export default injectIntl(
	compose(
		withHandlers({
			removeStation: ({setTeamStations, teamStations}) => (station) => {
				setTeamStations(omit(teamStations, station.key));
			}
		})
	)(TeamStationsList)
);