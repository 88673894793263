import { FormattedMessage } from "react-intl";
import React from "react";
import { get, find, uniq, map, delay, findIndex } from "lodash";
import history from "components/History";

const OrderDetails = {
  process_step_index: 1,
  key: "order_details",
  caption_color: "#CC1EE6",
  title: <FormattedMessage id="process > title order" />,
  step_roles: [13],
  step_viewers_roles: [12, 13, 14],
  step_editors_roles: [13],
  step_viewers_privileges: {
    or: ["add_digital", "edit_digital", "edit_all_digital_orders"],
  },
  step_editors_privileges: {
    or: ["add_digital", "edit_digital", "edit_all_digital_orders"],
  },
  can_edit_any_time: true,
  description: null,
  priviliges: [],
  status: "initial",
  disabled_until_completed_steps: [],
  step_container: "none",
  no_edit_button: true,
  workflow: {
    title: "order entry",
    icon: "StartIcon",
  },
  is_approval_step: true,
  className: "order-details-container",
  form_class_name: "order-tab-container",
  fields: [
    {
      component: "digital_order_details",
    },
    {
      field: "client_name",
      title: <FormattedMessage id="process > field client name" />,
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        if (get(process, "fields.order_internal.value", false)) return true;
        return value.length > 1
          ? true
          : "Customer name must be at least 2 letters long";
      },
    },
    {
      field: "client_id",
    },
    {
      field: "contact_id",
    },
    {
      field: "contract_id",
    },
    {
      field: "order_internal",
    },
    {
      field: "role_sales_person",
    },
    {
      field: "requested_by",
      title: <FormattedMessage id="process > field requested by" />,
      validation: function(step, item, process, template, user, value) {
        if (!get(process, "fields.order_internal.value", false)) return true;
        return value !== "" ? true : "Please select a requested by";
      },
      mandatory: true,
    },
    {
      field: "order_title",
      title: <FormattedMessage id="process > field campaign title" />,
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        return value.length > 2
          ? true
          : "Campaign title must be at least 3 letters long";
      },
    },
    {
      field: "contract_stations",
      mandatory: true,
      title: <FormattedMessage id="process > field entities" />,
      validation: function(step, item, process, template, user, value) {
        return value.length > 0
          ? true
          : "At least one station has to be selected";
      },
    },
    {
      field: "contract_stations_order_number_array",
      title: (
        <FormattedMessage id="process > field contract stations order number" />
      ),
    },
    {
      field: "contract_start_date",
      title: <FormattedMessage id="process > field contract start date" />,
    },
    {
      field: "contract_end_date",
      title: <FormattedMessage id="process > field contract end date" />,
    },
    // {
    //   field: "due_date",
    //   title: <FormattedMessage id="process > field due date" />,
    //   mandatory: true,
    //   validation: function(step, item, process, template, user, value) {
    //     if (value.length === 0) return true;
    //     if (isNaN(Date.parse(value))) return "Please enter a valid date";

    //     if (moment(value).isBefore(moment()))
    //       return "Date cannot be in the past";
    //     return true;
    //   },
    // },
    {
      field: "contract_client_contact",
      mandatory: true,
      title: <FormattedMessage id="process > field client contact" />,
      validation: function(step, item, process, template, user, value) {
        if (get(process, "fields.order_internal.value", false)) return true;
        return value ? true : "Please enter a client contact";
      },
    },
    {
      field: "contract_client_email",
      title: <FormattedMessage id="process > field email" />,
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        if (get(process, "fields.order_internal.value", false)) return true;
        return value.length > 0 &&
          value.match(new RegExp("[^@]+@[^\\.]+\\..+", "gi"))
          ? true
          : "Please enter a valid client email";
      },
    },
    {
      field: "contract_client_nickname",
      title: <FormattedMessage id="process > field nickname" />,
    },
    {
      field: "contract_client_phone",
      title: <FormattedMessage id="process > field phone" />,
      formatted: "phone",
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        if (value && value.length !== 10)
          return "Phone number must be 10 digits";
        return true;
      },
    },
    {
      field: "contract_category",
      title: <FormattedMessage id="process > field contract category" />,
      props: {
        options: [
          {
            label: (
              <FormattedMessage id="process > option label arts and entertainment" />
            ),
            value: "Arts & Entertainment",
          },
          {
            label: <FormattedMessage id="process > option label attorneys" />,
            value: "Attorneys",
          },
          {
            label: <FormattedMessage id="process > option label automotive" />,
            value: "Automotive",
          },
          {
            label: <FormattedMessage id="process > option label community" />,
            value: "Community",
          },
          {
            label: <FormattedMessage id="process > option label education" />,
            value: "Education",
          },
          {
            label: <FormattedMessage id="process > option label finance" />,
            value: "Finance",
          },
          {
            label: (
              <FormattedMessage id="process > option label food and beverage" />
            ),
            value: "Food & Beverage",
          },
          {
            label: (
              <FormattedMessage id="process > option label health and medical" />
            ),
            value: "Health & Medical",
          },
          {
            label: (
              <FormattedMessage id="process > option label home and garden" />
            ),
            value: "Home & Garden",
          },
          {
            label: (
              <FormattedMessage id="process > option label marine and boating" />
            ),
            value: "Marine & Boating",
          },
          {
            label: <FormattedMessage id="process > option label media" />,
            value: "Media",
          },
          {
            label: (
              <FormattedMessage id="process > option label pets and animals" />
            ),
            value: "Pets & Animals",
          },
          {
            label: (
              <FormattedMessage id="process > option label professionals" />
            ),
            value: "Professionals",
          },
          {
            label: <FormattedMessage id="process > option label real estate" />,
            value: "Real Estate",
          },
          {
            label: <FormattedMessage id="process > option label religion" />,
            value: "Religion",
          },
          {
            label: <FormattedMessage id="process > option label services" />,
            value: "Services",
          },
          {
            label: <FormattedMessage id="process > option label shopping" />,
            value: "Shopping",
          },
          {
            label: (
              <FormattedMessage id="process > option label sports and recreation" />
            ),
            value: "Sports & Recreation",
          },
          {
            label: <FormattedMessage id="process > option label travel" />,
            value: "Travel",
          },
        ],
      },
      mandatory: false,
    },
    {
      field: "timezone",
    },
    {
      field: "next_users",
    },
    {
      field: "spots",
    },
    {
      field: "marketing_channels",
      title: <FormattedMessage id="process > field marketing channels" />,
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        if (get(value, "length") === 0)
          return "At least one channel has to be selected";
        let isValid = true;
        const channels = map(value, (channel) => {
          let error = {};
          if (
            !get(channel, "schedule.start_date") ||
            !get(channel, "schedule.end_date") ||
            get(channel, "schedule.days.length", 0) === 0
          ) {
            isValid = false;
            error.schedule =
              "At least one channel schedule has to be selected";
          }
          return error;
        });
        if (isValid) return true;
        return channels;
      },
    },
    {
      field: "campaign_schedule",
      title: <FormattedMessage id="process > field campaign schedule" />,
      mandatory: true,
      validation: function(step, item, process, template, user, value) {
        if (!value || (!value.start_date && !value.end_date))
          return "At least one campaign schedule has to be selected";
        if (!value.start_date) return "Please select start date";
        if (!value.end_date) return "Please select end date";
        return true;
      },
    },
    {
      key: "save_button",
      text: <FormattedMessage id="process > button save" />,
      // component: "button",
      on_click: {
        validate: true,
        save: true,
        go_to: function(_this, step, item, process, template, user) {
          if (!user.return_to_current_tasks) return;
          // set delay when after success message
          delay(() => {
            if (_this.props.setSidebarActive) {
              _this.props.setSidebarActive(`/processes/my_action_items`);
            }
            history.replace(`/processes/my_action_items`);
          }, 4000);
        },
      },
      fields: [
        {
          key: "order_details_status",
          // "value": "submitted"
        },
        {
          key: "process_step",
          value: "first_undone",
        },
        {
          key: "role_digital_producer",
          value: (user, template, process, step, item, next_users) => {
            const forMarketingChannel = get(
              process,
              "data.fields.for_marketing_channel.value"
            );

            if (!forMarketingChannel) return {};

            const marketingChannel = find(
              get(process, "data.fields.marketing_channels.value", []),
              (channel) => channel.id === forMarketingChannel.id
            );

            let channelProduction = get(marketingChannel, "production", "");

            if (
              channelProduction !== "Requires Production" &&
              channelProduction !== "Production Needed"
            ) {
              return {};
            }

            const role_digital_producer = get(
              marketingChannel,
              "digital_producer"
            );
            const digital_producer_name = get(
              marketingChannel,
              "digital_producer_name"
            );
            return {
              id: role_digital_producer,
              name: digital_producer_name,
              role: 14,
            };
          },
        },
        {
          key: "role_channel_manager",
          value: (user, template, process, step, item, next_users) => {
            const forMarketingChannel = get(
              process,
              "data.fields.for_marketing_channel.value"
            );

            if (!forMarketingChannel) return {};

            const marketingChannel = find(
              get(process, "data.fields.marketing_channels.value", []),
              (channel) => channel.id === forMarketingChannel.id
            );
            const role_channel_manager = get(
              marketingChannel,
              "channel_manager"
            );
            const channel_manager_name = get(
              marketingChannel,
              "channel_manager_name"
            );
            return {
              id: role_channel_manager,
              name: channel_manager_name,
              role: 12,
            };
          },
        },
        {
          key: "next_users",
          value: (user, template, process, step, item, next_users) => {
            const forMarketingChannel = get(
              process,
              "data.fields.for_marketing_channel.value"
            );
            if (!forMarketingChannel) return next_users;
            const marketingChannel = find(
              get(process, "data.fields.marketing_channels.value", []),
              (channel) => channel.id === forMarketingChannel.id
            );
            // When order saved draft. Submit order again we need to check assign next_users
            const role_channel_manager = get(
              marketingChannel,
              "channel_manager"
            );
            const role_digital_producer = get(
              marketingChannel,
              "digital_producer"
            );
            const role_digital_account_executive = get(
              process,
              "data.fields.role_digital_account_executive.value.id"
            );
            const processStep = get(process, "data.fields.process_step.value");
            let nextUsers = [];
            switch (processStep) {
              case "design":
                nextUsers = [role_digital_producer];
                break;
              case "review_media":
                nextUsers = [role_digital_account_executive];
                break;
              case "traffic":
                nextUsers = [role_channel_manager];
                break;
              case "report":
                nextUsers = [role_channel_manager];
                break;
              default:
                nextUsers = [role_digital_account_executive];
                break;
            }
            return nextUsers;
          },
        },
        {
          key: "team",
          value: (user, template, process, step, item) => {
            const forMarketingChannel = get(
              process,
              "data.fields.for_marketing_channel.value"
            );
            if (!forMarketingChannel)
              return get(process, "data.fields.team.value", []);
            const marketingChannel = find(
              get(process, "data.fields.marketing_channels.value", []),
              (channel) => channel.id === forMarketingChannel.id
            );
            // Ensure team data update when order updating
            const role_channel_manager = get(
              marketingChannel,
              "channel_manager"
            );
            const role_digital_producer = get(
              marketingChannel,
              "digital_producer"
            );
            const role_digital_account_executive = get(
              process,
              "data.fields.role_digital_account_executive.value.id"
            );
            let team = [];
            team = uniq([
              role_digital_account_executive,
              role_channel_manager,
              role_digital_producer,
            ]);
            return team;
          },
        },
        {
          key: "marketing_channels",
          value: (user, template, process, step, item) => {
            let marketingChannels = get(
              process,
              "data.fields.marketing_channels.value",
              []
            );
            const forMarketingChannel = get(
              process,
              "data.fields.for_marketing_channel.value"
            );

            const processStep = get(process, "data.fields.process_step.value");

            if (!forMarketingChannel) {
              // we will assign default channel_step for each channel

              marketingChannels = map(marketingChannels, (channel) => {
                let channelStep = processStep;
                let channelProduction = get(channel, "production", "");
                if (processStep !== "draft") {
                  if (
                    channelProduction === "Requires Production" ||
                    channelProduction === "Production Needed"
                  ) {
                    channelStep = "design";
                  } else {
                    channelStep = "traffic";
                  }
                }
                return {
                  ...channel,
                  channel_step: channelStep,
                };
              });
            } else {
              const marketingChannel = find(
                get(process, "data.fields.marketing_channels.value", []),
                (channel) => channel.id === forMarketingChannel.id
              );
              const marketChannelIndex = findIndex(
                get(process, "data.fields.marketing_channels.value", []),
                (channel) => channel.id === forMarketingChannel.id
              );

              if (
                marketingChannel &&
                processStep !== get(marketingChannel, "channel_step")
              ) {
                let channelStep = processStep;
                let channelProduction = get(marketingChannel, "production", "");
                if (processStep !== "draft") {
                  if (
                    channelProduction === "Requires Production" ||
                    channelProduction === "Production Needed"
                  ) {
                    channelStep = "design";
                  } else {
                    channelStep = "traffic";
                  }
                }
                marketingChannels[marketChannelIndex][
                  "channel_step"
                ] = channelStep;
              }
            }

            return marketingChannels;
          },
        },
      ],
      // confirmation_dialog_props: {
      //   description: (
      //     <FormattedMessage id="process > description to submit digital order" />
      //   ),
      //   confirmTitle: <FormattedMessage id="process > button yes" />,
      //   cancelTitle: <FormattedMessage id="process > button no" />,
      // },
      success_dialog_props: {
        title: <FormattedMessage id="process > title submitted" />,
        state: "success",
      },
    },
  ],
};
export default OrderDetails;
