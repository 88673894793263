import React from "react";
import bn from "utils/bemnames";
import { Formik } from "formik";
import { FormattedMessage } from "react-intl";
import Checkbox from "components/Checkbox";
import { Row, Col, Button, Form } from "reactstrap";
import { forEach, map, pickBy } from "lodash";
import classnames from "classnames";
import Spinner from "components/Spinner";

const bem = bn.create("order-details-duplicate");
const fieldsByTemplate = {
  basic_production: [
    {
      key: "entities",
      value: true,
      label: <FormattedMessage id="process > field entities" />,
    },
    {
      key: "order_title",
      value: true,
      label: <FormattedMessage id="process > field title" />,
    },
    {
      key: "assets",
      value: true,
      label: <FormattedMessage id="process > field assets" />,
    },
    /*{
      key: "times",
      value: true,
      label: <FormattedMessage id="process > field flight dates" />,
    },*/
    {
      key: "ad_length",
      value: true,
      label: <FormattedMessage id="process > field length" />,
    },
    {
      key: "copy_points",
      value: true,
      label: <FormattedMessage id="process > field copy points" />,
    },
    {
      key: "approvals",
      value: true,
      label: <FormattedMessage id="process > field approvals" />,
    },
    {
      key: "script",
      value: true,
      label: <FormattedMessage id="process > field script" />,
    },
    {
      key: "recording_path",
      value: true,
      label: <FormattedMessage id="process > field recording" />,
    },
  ],
  production_provided: [
    {
      key: "entities",
      value: true,
      label: <FormattedMessage id="process > field entities" />,
    },
    {
      key: "order_title",
      value: true,
      label: <FormattedMessage id="process > field title" />,
    },
    {
      key: "assets",
      value: true,
      label: <FormattedMessage id="process > field assets" />,
    },
    /*{
      key: "times",
      value: true,
      label: <FormattedMessage id="process > field flight dates" />,
    },*/
    {
      key: "ad_length",
      value: true,
      label: <FormattedMessage id="process > field length" />,
    },
    {
      key: "approvals",
      value: true,
      label: <FormattedMessage id="process > field approvals" />,
    },
    {
      key: "recording_path",
      value: true,
      label: <FormattedMessage id="process > field recording" />,
    },
  ],
  liner: [
    {
      key: "order_stations",
      value: true,
      label: <FormattedMessage id="process > field audience" />,
    },
    {
      key: "item_details",
      value: true,
      label: <FormattedMessage id="process > field item details" />,
    },
    {
      key: "item_scheduling",
      value: true,
      label: <FormattedMessage id="process > field item scheduling" />,
    },
    {
      key: "scripts",
      value: true,
      label: <FormattedMessage id="process > field scripts" />,
    },
    {
      key: "client_details",
      value: true,
      label: <FormattedMessage id="process > field client details" />,
    },
  ],
  contest: [
    {
      key: "contest_details",
      value: true,
      label: (
        <FormattedMessage id="process > title promotion contest details" />
      ),
    },
    {
      key: "flight_dates",
      value: true,
      label: <FormattedMessage id="process > Flight Dates" />,
    },
    {
      key: "number_of_plays",
      value: true,
      label: <FormattedMessage id="process > field contest number of plays" />,
    },
    {
      key: "scripts",
      value: true,
      label: <FormattedMessage id="process > field scripts" />,
    },
    {
      key: "client_details",
      value: true,
      label: <FormattedMessage id="process > field client details" />,
    },
    {
      key: "rules_restrictions",
      value: true,
      label: <FormattedMessage id="process > field > contest rules" />,
    },
    {
      key: "documents",
      value: true,
      label: (
        <FormattedMessage id="process > promotion > contest > title contest documents" />
      ),
    },
  ],
};
const titleConfig = {
  liner: <FormattedMessage id="process > title liner duplicate" />,
  contest: <FormattedMessage id="process > title contest duplicate" />,
};
const submitButtonConfig = {
  liner: <FormattedMessage id="process > button duplicate" />,
  contest: <FormattedMessage id="process > button duplicate" />,
};
function View(props) {
  const { extraData, isLoading } = props;
  const template = extraData.template_id;
  const fields = fieldsByTemplate[template];
  let initialValues = {};
  forEach(fields, (field) => {
    initialValues[field.key] = field.value;
  });
  const title = titleConfig[template] ? (
    titleConfig[template]
  ) : (
    <FormattedMessage id="process > title order details duplicate" />
  );
  const submitButton = submitButtonConfig[template] ? (
    submitButtonConfig[template]
  ) : (
    <FormattedMessage id="process > button add spot" />
  );
  const isContest = template === "contest";
  return (
    <div className={bem.b()}>
      <h2 className={bem.e("title")}>{title}</h2>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={props.onSubmit}
      >
        {(formProps) => {
          const { values, setFieldValue } = formProps;
          let isCheckedAll =
            Object.keys(pickBy(values, (value, key) => !value)).length === 0;
          return (
            <Form
              onSubmit={formProps.handleSubmit}
              noValidate
              className={bem.e("form")}
            >
              <div className={bem.e("fields-list")}>
                <Row>
                  <Col xs={6}>
                    <Checkbox
                      checked={isCheckedAll}
                      onChange={(checked) => {
                        forEach(fields, (field) => {
                          setFieldValue(field.key, checked);
                        });
                      }}
                      text={<FormattedMessage id="process > field all" />}
                    />
                  </Col>
                  {map(fields, (field, index) => (
                    <Col key={index} xs={6}>
                      <Checkbox
                        checked={values[field.key]}
                        onChange={(checked) => {
                          setFieldValue(field.key, checked);
                        }}
                        text={field.label}
                      />
                    </Col>
                  ))}
                  {!isContest && <Col xs={6}>&nbsp;</Col>}
                </Row>
              </div>
              <div className={bem.e("buttons")}>
                <Button
                  color="blue"
                  type="submit"
                  className={classnames(bem.e("button-submit"), "btn-radius")}
                >
                  {submitButton}
                </Button>
                <Button
                  color="blue"
                  outline
                  onClick={() => props.close()}
                  className={classnames(bem.e("button-cancel"), "btn-radius")}
                >
                  <FormattedMessage id="process > button cancel" />
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
      <Spinner isLoading={isLoading} />
    </div>
  );
}
export default View;
