import React from "react";
import { get } from "lodash";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import ActionStatement from "components/ScheduleComponents/ActionStatement";
import bn from "utils/bemnames";
import { convertArrayToString } from "utils/helpers";

const bem = bn.create("schedule-calendar");

export default function Statement(props) {
  const {
    statementType,
    intl,
    selectedItemSwap,
    onSelectItemSwap,
    onUndoSwappedItem,
    selectedItem,
    setStatementType,
    setSelectedItem,
    itemSwapped,
    onUndoDeletedItem,
    onUndoAddFillItems,
    dateFormatByServer,
  } = props;
  const selectedItemTypes = get(selectedItem, "labels")
    ? selectedItem.labels.map((i) => `${i.type_name} - ${i.type_label}`)
    : [selectedItem.type];
  return (
    <div className={bem.e("statement-sticky")}>
      {/* render statement */}
      {/* select item for swapping */}
      {statementType === "swapping" &&
      get(selectedItemSwap, "order_process_id") ? (
        <ActionStatement
          message={
            <span className={bem.e("statement-select-item-text")}>
              {intl.formatMessage({
                id:
                  "process > promotion > liner > schedule > choose an item below to swap with",
              })}{" "}
              {moment(selectedItemSwap.date).format(dateFormatByServer)} -{" "}
              {moment.utc(selectedItemSwap.time * 1000 * 60).format("h:mm a")}{" "}
              <strong>{get(selectedItemSwap, "order_title", "")}</strong>
              {` `}
              {get(selectedItemSwap, "type", "")}
            </span>
          }
          buttonText={
            <FormattedMessage id="process > promotion > liner > schedule > cancel" />
          }
          onClick={() => {
            setStatementType(null);
            onSelectItemSwap({});
          }}
        />
      ) : null}
      {/* swapped */}
      {statementType === "swapped" && itemSwapped ? (
        <ActionStatement
          message={
            <FormattedMessage id="process > promotion > liner > schedule > swap successful" />
          }
          buttonText={
            <FormattedMessage id="process > promotion > liner > schedule > undo" />
          }
          onClick={onUndoSwappedItem}
        />
      ) : null}
      {/* deleted */}
      {statementType === "deleted" ? (
        <ActionStatement
          message={
            <FormattedMessage id="process > promotion > liner > schedule > delete successful" />
          }
          buttonText={
            <FormattedMessage id="process > promotion > liner > schedule > undo" />
          }
          onClick={onUndoDeletedItem}
        />
      ) : null}
      {/* filled */}
      {statementType === "filled" ? (
        <ActionStatement
          message={
            <FormattedMessage id="process > promotion > liner > schedule > fill successful" />
          }
          buttonText={
            <FormattedMessage id="process > promotion > liner > schedule > undo" />
          }
          onClick={onUndoAddFillItems}
        />
      ) : null}
      {/* select */}
      {statementType === "select" && get(selectedItem, "title") ? (
        <ActionStatement
          message={
            <span className={bem.e("statement-select-item-text")}>
              {intl.formatMessage({
                id: "process > promotion > liner > schedule > displaying",
              })}{" "}
              <strong>{get(selectedItem, "title", "")}</strong>
              {` `}
              {convertArrayToString(selectedItemTypes)}
            </span>
          }
          buttonText={
            <FormattedMessage id="process > promotion > liner > schedule > clear" />
          }
          onClick={() => {
            setStatementType(null);
            setSelectedItem({});
          }}
        />
      ) : null}
    </div>
  );
}
