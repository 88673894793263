import React from "react";
import { Modal, ModalBody, ModalHeader, Button, Row, Col } from "reactstrap";
import bn from "utils/bemnames";
import { CloseIcon } from "components/CustomIcons";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import Dropdown from "components/Dropdown";
import InputTime from "components/InputTime";
import { Formik, Form } from "formik";
import { find, get } from "lodash";
const bem = bn.create("schedule-settings-modal");
const dateTimeFormat = "YYYY-MM-DD HH:mm:ss";
const daysOptions = [
  {
    value: 1,
    label: (
      <FormattedMessage
        id="process > promotion > liner > schedule > day before running"
        values={{
          DAY: 1,
        }}
      />
    ),
  },
  {
    value: 2,
    label: (
      <FormattedMessage
        id="process > promotion > liner > schedule > days before running"
        values={{
          DAYS: 2,
        }}
      />
    ),
  },
  {
    value: 3,
    label: (
      <FormattedMessage
        id="process > promotion > liner > schedule > days before running"
        values={{
          DAYS: 3,
        }}
      />
    ),
  },
  {
    value: 4,
    label: (
      <FormattedMessage
        id="process > promotion > liner > schedule > days before running"
        values={{
          DAYS: 4,
        }}
      />
    ),
  },
  {
    value: 5,
    label: (
      <FormattedMessage
        id="process > promotion > liner > schedule > days before running"
        values={{
          DAYS: 5,
        }}
      />
    ),
  },
];
function ScheduleSettingsModal(props) {
  const { fillSettings, onFormSubmit } = props;
  return (
    <Modal isOpen={props.isOpen} className={bem.b()}>
      <ModalHeader>
        <FormattedMessage id="process > promotion > liner > schedule > schedule settings" />
        <div className={bem.e("close-icon")} onClick={props.onToggle}>
          <CloseIcon color="#657894" />
        </div>
      </ModalHeader>
      <ModalBody className={bem.e("body")}>
        <div className={bem.e("padding")}>
          <div className={bem.e("manage-title")}>
            <FormattedMessage id="process > promotion > liner > schedule > manage fill timing" />
          </div>
          <div className={bem.e("manage-help")}>
            <FormattedMessage id="process > promotion > liner > schedule > manage fill timing helper" />
          </div>
          <div className={bem.e("add-fill-items-title")}>
            <FormattedMessage id="process > promotion > liner > schedule > add fill items to the station schedule" />
            :
          </div>
        </div>
        <Formik
          initialValues={{
            station_id: props.stationId,
            time_in_min: get(fillSettings, "time_in_min", 0),
            days_before: get(fillSettings, "days_before", 3),
          }}
          onSubmit={onFormSubmit}
        >
          {({ values, setFieldValue, handleSubmit }) => {
            const formattedDateTime =
              values.time_in_min || values.time_in_min === 0
                ? moment
                    .utc(values.time_in_min * 60 * 1000)
                    .format(dateTimeFormat)
                : "";
            const selectedOption = find(
              daysOptions,
              (item) => item.value === values.days_before
            );
            return (
              <Form onSubmit={handleSubmit}>
                <div className={bem.e("padding")}>
                  <Row>
                    <Col xs={6}>
                      <InputTime
                        time={formattedDateTime}
                        onChange={(startDateTime) => {
                          const minutes =
                            parseInt(startDateTime.get("hours")) * 60 +
                            parseInt(startDateTime.get("minutes"));
                          setFieldValue("time_in_min", minutes);
                        }}
                      />
                    </Col>
                    <Col xs={6}>
                      <Dropdown
                        placeholder={props.intl.formatMessage({
                          id:
                            "process > promotion > liner > schedule > placeholder select",
                        })}
                        value={selectedOption}
                        options={daysOptions}
                        onChange={(option) => {
                          setFieldValue("days_before", option.value);
                        }}
                      />
                    </Col>
                  </Row>
                </div>

                <div className={bem.e("buttons")}>
                  <Button type="submit" className={"btn-blue btn-radius"}>
                    <FormattedMessage id="process > button submit" />
                  </Button>
                  <Button
                    onClick={props.onCancel}
                    color="blue"
                    className={"btn-radius"}
                    outline
                  >
                    <FormattedMessage id="process > button cancel" />
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </ModalBody>
    </Modal>
  );
}

ScheduleSettingsModal.defaultProps = {
  isOpen: false,
  onSubmit: () => {},
};

export default ScheduleSettingsModal;
