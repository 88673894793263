import { get } from "lodash";
import React, { useMemo } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import useWindowDimensions from "utils/useWindowDimensions";
import { Link } from "react-router-dom";
import URL from "utils/urls";

const Breadcrumb = (props) => {
  const { bem, template, current_step, process, intl } = props;
  const { width: widthScreen } = useWindowDimensions();
  const isMobile = useMemo(() => {
    return widthScreen < 480;
  }, [widthScreen]);

  const templateKey = get(template, "key");
  if (["promotion"].includes(templateKey)) {
    return null;
  }
  const values = {
    type: get(process, "data.fields.process_step", 0) ? "saved" : "new",
    subtitle: current_step.title,
  };
  const title = template.breadcrumbs_title ? (
    template.breadcrumbs_title(values)
  ) : (
    <FormattedMessage
      id={`process > breadcrumb > production ${values.type} title`}
    />
  );
  const subtitle = template.breadcrumbs_subtitle ? (
    template.breadcrumbs_subtitle(values)
  ) : (
    <FormattedMessage
      id="process > breadcrumb > production subtitle"
      values={values}
    />
  );
  if (isMobile) {
    return (
      <div className={bem.e("container-padding")}>
        <div className={bem.e("header-breadcrumb")}>
          <Link to={URL.HOME_MOBILE()}>
            <FormattedMessage id="breadcrumbs > home" />
            {` `}
          </Link>
          /{" "}
          <strong>
            {get(process, "data.fields.process_step", 0)
              ? intl.formatMessage({ id: "breadcrumbs > order" })
              : intl.formatMessage({ id: "breadcrumbs > new order" })}
          </strong>
        </div>
      </div>
    );
  }
  return (
    <div className={bem.e("container-padding")}>
      <span className={bem.e("header-breadcrumb")}>
        {title} / <strong>{subtitle}</strong>
      </span>
    </div>
  );
};

export default injectIntl(Breadcrumb);
