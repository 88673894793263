import React from "react";
import { getApiUrl } from "../components/Api";
import { findIndex } from "lodash";
import { FormattedMessage } from "react-intl";
export const API_URL = () => getApiUrl();

export const languageList = [
  {
    value: "en-US",
    label: "English",
  },
  {
    value: "es-ES",
    label: "Spanish",
  },
];
export const countryList = [
  {
    value: "US",
    label: "United States",
  },
  {
    value: "CA",
    label: "Canada",
  },
  {
    value: "MX",
    label: "Mexico",
  },
];
let navigator_language =
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage;

export const language =
  findIndex(languageList, (item) => item.value === navigator_language) !== -1
    ? navigator_language
    : languageList[0].value;

export const genderList = [
  {
    value: "male",
    label: "Male",
  },
  {
    value: "female",
    label: "Female",
  },
  {
    value: "other",
    label: "Other",
  },
];
export const PhoneNumberFormat = "###-###-####";
export const SocialSecurityFormat = "###-##-####";
export const RotationFormatSuffix = "%";
export const DateFormat = "MM/DD/YYYY";
export const DateTimeFormat = "MM/DD/YYYY - hh:mm A";
export const pageSize = 20;
export const pageSizeOptions = [
  {
    value: 10,
    label: 10,
  },
  {
    value: 25,
    label: 25,
  },
  {
    value: 50,
    label: 50,
  },
  {
    value: 100,
    label: 100,
  },
];

export const adminTabs = {
  TAB_BROADCASTING_GROUP: "broadcasting",
  TAB_STATIONS: "stations",
  TAB_TEAMS: "teams",
  TAB_ROLES: "roles",
  TAB_ADMINS: "admins",
  TAB_USERS: "users",
  TAB_CITIES: "cities",
  TAB_GROUPS: "groups",
  TAB_UNKNOWN: "unknown",
  TAB_WIDEORBIT: "wideorbit",
  TAB_SMTP_CONFIG: "smtpconfig",
  TAB_HUBS: "hubs",
  TAB_ZETTA: "zetta",
  TAB_OUT_OF_OFFICE: "out-of-office",
};

export const categoryList = [
  { value: "cash_check", label: "Cash/Check" },
  { value: "variable_cash", label: "Variable Cash" },
  { value: "tickets", label: "Tickets" },
  { value: "prize", label: "Prize" },
  { value: "station_merchandise", label: "Station Merchandise" },
];

export const resourceCategoryList = [
  { value: "vehicle", label: "Vehicle" },
  { value: "promotion_kit", label: "Promotion Kit" },
];

export const fulfillmentList = [
  { value: "pickup_station", label: "Pick up at station" },
  { value: "pickup_client_provider", label: "Pick up at client/provider" },
  { value: "mail_out", label: "Mail out" },
  { value: "will_call", label: "Will call" },
  { value: "digital_link", label: "Digital link" },
];
export const allocationList = [
  { value: "shared", label: "Shared" },
  { value: "assigned", label: "Assigned" },
];

export const categoryValues = { tickets: "Tickets", cash: "Cash" };
export const resourceCategoryValues = {
  vehicle: "Vehicle",
  promotion_kit: "Promotion Kit",
};
export const fulfillmentValues = {
  digital: "Digital",
  pickup_station: "Pick up at Station",
  pickup_client: "Pick up at Client",
};

export const calendarEvents = {
  ON_HOLD_EVENT: "on_hold_event",
  INTERNAL_EVENT: "internal_event",
  CLIENT_EVENT: "client_event",
  SUCCESS_EVENT: "success_event",
};

export const hide_next_version_items =
  document.location.href.toLowerCase().indexOf("promosuite.ventive.app") > -1 ||
  // document.location.href.toLowerCase().indexOf("promosuitestage.ventive.app") > -1 ||
  document.location.href.toLowerCase().indexOf("promosuitedemo.ventive.app") >
    -1 ||
  document.location.href.toLowerCase().indexOf("ps-live.ventive.app") > -1 ||
  document.location.href.toLowerCase().indexOf("promosuitenext.app") > -1 ||
  document.location.href.toLowerCase().indexOf("promosuitenext.net") > -1 ||
  document.location.href.toLowerCase().indexOf("promosuiteplus.app") > -1 ||
  document.location.href.toLowerCase().indexOf("promosuiteplus.net") > -1 ||
  document.location.href.toLowerCase().indexOf("admin.ps.com") > -1;

export const colors = [
  {
    value: "#111B2B",
    label: "black",
  },
  {
    value: "#FFFFFF",
    label: "white",
  },
  {
    value: "#CC0000",
    label: "red",
  },
  {
    value: "#660000",
    label: "dark red",
  },
  {
    value: "#224B8B",
    label: "blue",
  },
  {
    value: "#083070",
    label: "dark blue",
  },
  {
    value: "#1B8A2D",
    label: "green",
  },
  {
    value: "#0A5516",
    label: "dark green",
  },
  {
    value: "#8B98AB",
    label: "gray",
  },
  {
    value: "#334856",
    label: "dark gray",
  },
  {
    value: "#512F10",
    label: "brown",
  },
  {
    value: "#126E8B",
    label: "teal",
  },
  {
    value: "#185FCF",
    label: "blue eyes",
  },
];
export const statesList = [
  {
    label: "Alabama - AL",
    value: "AL",
  },
  {
    label: "Alaska - AK",
    value: "AK",
  },
  {
    label: "Arizona - AZ",
    value: "AZ",
  },
  {
    label: "Arkansas - AR",
    value: "AR",
  },
  {
    label: "California - CA",
    value: "CA",
  },
  {
    label: "Colorado - CO",
    value: "CO",
  },
  {
    label: "Connecticut - CT",
    value: "CT",
  },
  {
    label: "Delaware - DE",
    value: "DE",
  },
  {
    label: "Florida - FL",
    value: "FL",
  },
  {
    label: "Georgia - GA",
    value: "GA",
  },
  {
    label: "Hawaii - HI",
    value: "HI",
  },
  {
    label: "Idaho - ID",
    value: "ID",
  },
  {
    label: "Illinois - IL",
    value: "IL",
  },
  {
    label: "Indiana - IN",
    value: "IN",
  },
  {
    label: "Iowa - IA",
    value: "IA",
  },
  {
    label: "Kansas - KS",
    value: "KS",
  },
  {
    label: "Kentucky - KY",
    value: "KY",
  },
  {
    label: "Louisiana - LA",
    value: "LA",
  },
  {
    label: "Maine - ME",
    value: "ME",
  },
  {
    label: "Maryland - MD",
    value: "MD",
  },
  {
    label: "Massachusetts - MA",
    value: "MA",
  },
  {
    label: "Michigan - MI",
    value: "MI",
  },
  {
    label: "Minnesota - MN",
    value: "MN",
  },
  {
    label: "Mississippi - MS",
    value: "MS",
  },
  {
    label: "Missouri - MO",
    value: "MO",
  },
  {
    label: "Montana - MT",
    value: "MT",
  },
  {
    label: "Nebraska - NE",
    value: "NE",
  },
  {
    label: "Nevada - NV",
    value: "NV",
  },
  {
    label: "New Hampshire - NH",
    value: "NH",
  },
  {
    label: "New Jersey - NJ",
    value: "NJ",
  },
  {
    label: "New Mexico - NM",
    value: "NM",
  },
  {
    label: "New York - NY",
    value: "NY",
  },
  {
    label: "North Carolina - NC",
    value: "NC",
  },
  {
    label: "North Dakota - ND",
    value: "ND",
  },
  {
    label: "Ohio - OH",
    value: "OH",
  },
  {
    label: "Oklahoma - OK",
    value: "OK",
  },
  {
    label: "Oregon - OR",
    value: "OR",
  },
  {
    label: "Pennsylvania - PA",
    value: "PA",
  },
  {
    label: "Rhode Island - RI",
    value: "RI",
  },
  {
    label: "South Carolina - SC",
    value: "SC",
  },
  {
    label: "South Dakota - SD",
    value: "SD",
  },
  {
    label: "Tennessee - TN",
    value: "TN",
  },
  {
    label: "Texas - TX",
    value: "TX",
  },
  {
    label: "Utah - UT",
    value: "UT",
  },
  {
    label: "Vermont - VT",
    value: "VT",
  },
  {
    label: "Virginia - VA",
    value: "VA",
  },
  {
    label: "Washington - WA",
    value: "WA",
  },
  {
    label: "West Virginia - WV",
    value: "WV",
  },
  {
    label: "Wisconsin - WI",
    value: "WI",
  },
  {
    label: "Wyoming - WY",
    value: "WY",
  },
];
export const statesListCanada = [
  {
    label: "Alberta - AB",
    value: "AB",
  },
  {
    label: "British Columbia - BC",
    value: "BC",
  },
  {
    label: "Manitoba - MB",
    value: "MB",
  },
  {
    label: "New Brunswick - NB",
    value: "NB",
  },
  {
    label: "Newfoundland - NF",
    value: "NF",
  },
  {
    label: "Northwest Territories - NT",
    value: "NT",
  },
  {
    label: "Nova Scotia - NS",
    value: "NS",
  },
  {
    label: "Nunavut - NU",
    value: "NU",
  },
  {
    label: "Ontario - ON",
    value: "ON",
  },
  {
    label: "Prince Edward Island - PE",
    value: "PE",
  },
  {
    label: "Quebec - PQ",
    value: "PQ",
  },
  {
    label: "Saskatchewan - SK",
    value: "SK",
  },
  {
    label: "Yukon - YT",
    value: "YT",
  },
];
export const statesListMexico = [
  {
    label: "Aguascalientes - AG",
    value: "AG",
  },
  {
    label: "Baja California - BC",
    value: "BC",
  },
  {
    label: "Baja California Sur - BS",
    value: "BS",
  },
  {
    label: "Campeche - CM",
    value: "CM",
  },
  {
    label: "Chiapas - CS",
    value: "CS",
  },
  {
    label: "Chihuahua - CH",
    value: "CH",
  },
  {
    label: "Coahuila - CO",
    value: "CO",
  },
  {
    label: "Colima - CL",
    value: "CL",
  },
  {
    label: "Mexico City - DF",
    value: "DF",
  },
  {
    label: "Durango - DG",
    value: "DG",
  },
  {
    label: "Guanajuato - GT",
    value: "GT",
  },
  {
    label: "Guerrero - GR",
    value: "GR",
  },
  {
    label: "Hidalgo - HG",
    value: "HG",
  },
  {
    label: "Jalisco - JA",
    value: "JA",
  },
  {
    label: "México - EM",
    value: "EM",
  },
  {
    label: "Michoacán - MI",
    value: "MI",
  },
  {
    label: "Morelos - MO",
    value: "MO",
  },
  {
    label: "Nayarit - NA",
    value: "NA",
  },
  {
    label: "Nuevo León - NL",
    value: "NL",
  },
  {
    label: "Oaxaca - OA",
    value: "OA",
  },
  {
    label: "Puebla - PU",
    value: "PU",
  },
  {
    label: "Querétaro - QT",
    value: "QT",
  },
  {
    label: "Quintana Roo - QR",
    value: "QR",
  },
  {
    label: "San Luis Potosí - SL",
    value: "SL",
  },
  {
    label: "Sinaloa - SI",
    value: "SI",
  },
  {
    label: "Sonora - SO",
    value: "SO",
  },
  {
    label: "Tabasco - TB",
    value: "TB",
  },
  {
    label: "Tamaulipas - TM",
    value: "TM",
  },
  {
    label: "Tlaxcala - TL",
    value: "TL",
  },
  {
    label: "Veracruz - VE",
    value: "VE",
  },
  {
    label: "Yucatán - YU",
    value: "YU",
  },
  {
    label: "Zacatecas - ZA",
    value: "ZA",
  },
];
export const textSizes = [16, 20, 24];
export const textCases = [
  { label: "Sentence", value: "sentence" },
  { label: "Capitalize", value: "capitalize" },
  { label: "Uppercase", value: "uppercase" },
  { label: "Lowercase", value: "lowercase" },
];
export const textFonts = [
  { label: "Mulish", value: "mulish" },
  { label: "Sans Serif", value: "sans-serif" },
  { label: "Lora", value: "lora" },
];
export const widgetLengths = {
  sidebar: 8,
  multiView: 4,
};
export const jockScriptTags = [
  { tag_key: "STATION", data_key: "station_name" },
  { tag_key: "CITY", data_key: "station_city" },
];
export const contestScriptBeforeTags = [
  {
    tag_key: "STATION",
    data_key: "station_name",
    label: "STATION",
    replaceWith: "STATION",
  },
  {
    tag_key: "SHOW",
    data_key: "station_name",
    label: "SHOW",
    replaceWith: "SHOW",
  },
  {
    tag_key: "PRIZE",
    data_key: "prizes_title",
    label: "PRIZE",
    replaceWith: "PRIZE",
  },
  {
    tag_key: "SPONSOR",
    data_key: "winner_city",
    label: "SPONSOR",
    replaceWith: "SPONSOR",
  },
];
export const contestScriptAfterTags = [
  {
    tag_key: "WINNER",
    data_key: "winner_name",
    label: "WINNER",
    replaceWith: "WINNER",
  },
  {
    tag_key: "CITY",
    data_key: "winner_city",
    label: "CITY",
    replaceWith: "CITY",
  },
  {
    tag_key: "SPONSOR",
    data_key: "SPONSOR",
    label: "SPONSOR",
    replaceWith: "SPONSOR",
  },
];
export const grandScriptTags = [
  {
    tag_key: "STATION",
    data_key: "station_name",
    label: "STATION",
    replaceWith: "STATION",
  },
  {
    tag_key: "SHOW",
    data_key: "station_name",
    label: "SHOW",
    replaceWith: "SHOW",
  },
];

export const grandScriptAfterTags = [
  {
    tag_key: "WINNER",
    data_key: "winner_name",
    label: "WINNER",
    replaceWith: "WINNER",
  },
  {
    tag_key: "STATION",
    data_key: "station_name",
    label: "STATION",
    replaceWith: "STATION",
  },
  {
    tag_key: "SHOW",
    data_key: "station_name",
    label: "SHOW",
    replaceWith: "SHOW",
  },
];
export const qualifierScriptAfterTags = [
  {
    tag_key: "WINNER",
    data_key: "winner_name",
    label: "WINNER",
    replaceWith: "WINNER",
  },
];

export const productsMap = {
  production: {
    value: "production",
    label: "Production",
  },
  digital: {
    value: "digital",
    label: "Digital",
  },
  promotion: {
    value: "promotion",
    label: "Promotions",
  },
  wo_traffic: {
    value: "wo_traffic",
    label: "WideOrbit Traffic",
  },
  wo_dubbing: {
    value: "wo_dubbing",
    label: "WideOrbit Dubbing",
  },
  enterprise_production: {
    value: "enterprise_production",
    label: "Enterprise Production",
  },
};

export const templateTags = [
  {
    title: "Winner Details",
    list: [
      {
        tag_key: "WINNER_NAME",
        data_key: "winner_name",
        label: "Winner's Name",
        replaceWith: "Winner's Name",
      },
      {
        tag_key: "FIRST_NAME",
        data_key: "first_name",
        label: "First Name",
        replaceWith: "First Name",
      },
      {
        tag_key: "LAST_NAME",
        data_key: "last_name",
        label: "Last Name",
        replaceWith: "Last Name",
      },
      {
        tag_key: "HOME_ADDRESS",
        data_key: "home_address",
        label: "Home Address",
        replaceWith: "Home Address",
      },
      {
        tag_key: "HOME_CITY",
        data_key: "home_city",
        label: "Home City",
        replaceWith: "Home City",
      },
      {
        tag_key: "HOME_PHONE",
        data_key: "home_phone",
        label: "Home Phone",
        replaceWith: "Home Phone",
      },
      {
        tag_key: "WORK_PHONE",
        data_key: "work_phone",
        label: "Work Phone",
        replaceWith: "Work Phone",
      },
      {
        tag_key: "HOME_STATE",
        data_key: "home_state",
        label: "Home State",
        replaceWith: "Home State",
      },
      {
        tag_key: "HOME_ZIP_CODE",
        data_key: "home_zip_code",
        label: "Home ZIP Code",
        replaceWith: "Home ZIP Code",
      },
      {
        tag_key: "CURRENT_AGE",
        data_key: "current_age",
        label: "Current Age",
        replaceWith: "Current Age",
      },
      {
        tag_key: "DATE_OF_BIRTH",
        data_key: "date_of_birth",
        label: "Date Of Birth",
        replaceWith: "Date Of Birth",
      },
      {
        tag_key: "DATE_WON",
        data_key: "date_won",
        label: "Date Won",
        replaceWith: "Date Won",
      },
      {
        tag_key: "DRIVER_LICENSE",
        data_key: "driver_license",
        label: "Driver's License #",
        replaceWith: "Driver's License #",
      },
      {
        tag_key: "EMAIL",
        data_key: "email",
        label: "Email",
        replaceWith: "Email",
      },
      {
        tag_key: "ID",
        data_key: "id",
        label: "ID #",
        replaceWith: "ID #",
      },
      {
        tag_key: "MOBILE_PHONE",
        data_key: "mobile_phone",
        label: "Mobile Phone",
        replaceWith: "Mobile Phone",
      },
      {
        tag_key: "NEXT_DATE_ELIGIBLE",
        data_key: "next_date_eligible",
        label: "Next Date Eligible",
        replaceWith: "Next Date Eligible",
      },
    ],
  },

  {
    title: "Prize Details",
    list: [
      {
        tag_key: "CONTEST_TITLE",
        data_key: "contest_title",
        label: "Contest Title",
        replaceWith: "Contest Title",
      },
      {
        tag_key: "PRIZE_DESCRIPTION",
        data_key: "prize_description",
        label: "Prize Description",
        replaceWith: "Prize Description",
      },
      {
        tag_key: "PRIZE_TITLE",
        data_key: "prize_title",
        label: "Prize Title",
        replaceWith: "Prize Title",
      },
      {
        tag_key: "PRIZE_VALUE",
        data_key: "prize_value",
        label: "Prize Value",
        replaceWith: "Prize Value",
      },
      {
        tag_key: "YTD_WINNINGS",
        data_key: "ytd_winnings",
        label: "YTD Winnings",
        replaceWith: "YTD Winnings",
      },
      {
        tag_key: "YTD_DISTRIBUITED",
        data_key: "ytd_distributed",
        label: "YTD Distributed",
        replaceWith: "YTD Distributed",
      },
      {
        tag_key: "CURRENT_YEAR",
        data_key: "current_year",
        label: "Current Year",
        replaceWith: "Current Year",
      },
    ],
  },

  {
    title: "Station Details",
    list: [
      {
        tag_key: "STATION_CALL_LETTERS",
        data_key: "station_call_letters",
        label: "Station Call Letters",
        replaceWith: "Station Call Letters",
      },
      {
        tag_key: "STATION_NAME",
        data_key: "station_name",
        label: "Station Name",
        replaceWith: "Station Name",
      },
      {
        tag_key: "STATION_WEBSITE",
        data_key: "station_website",
        label: "Station Website",
        replaceWith: "Station Website",
      },
    ],
  },
];

export const prizeReleaseTemplateTags = [
  {
    title: "Winner Details",
    list: [
      {
        tag_key: "WINNER_NAME",
        data_key: "winner_name",
        label: "Winner's Name",
        replaceWith: "Winner's Name",
      },
      {
        tag_key: "FIRST_NAME",
        data_key: "first_name",
        label: "First Name",
        replaceWith: "First Name",
      },
      {
        tag_key: "LAST_NAME",
        data_key: "last_name",
        label: "Last Name",
        replaceWith: "Last Name",
      },
      {
        tag_key: "HOME_ADDRESS",
        data_key: "home_address",
        label: "Home Address",
        replaceWith: "Home Address",
      },
      {
        tag_key: "HOME_CITY",
        data_key: "home_city",
        label: "Home City",
        replaceWith: "Home City",
      },
      {
        tag_key: "HOME_PHONE",
        data_key: "home_phone",
        label: "Home Phone",
        replaceWith: "Home Phone",
      },
      {
        tag_key: "WORK_PHONE",
        data_key: "work_phone",
        label: "Work Phone",
        replaceWith: "Work Phone",
      },
      {
        tag_key: "HOME_STATE",
        data_key: "home_state",
        label: "Home State",
        replaceWith: "Home State",
      },
      {
        tag_key: "HOME_ZIP_CODE",
        data_key: "home_zip_code",
        label: "Home ZIP Code",
        replaceWith: "Home ZIP Code",
      },
      {
        tag_key: "CURRENT_AGE",
        data_key: "current_age",
        label: "Current Age",
        replaceWith: "Current Age",
      },
      {
        tag_key: "DATE_OF_BIRTH",
        data_key: "date_of_birth",
        label: "Date Of Birth",
        replaceWith: "Date Of Birth",
      },
      {
        tag_key: "DATE_WON",
        data_key: "date_won",
        label: "Date Won",
        replaceWith: "Date Won",
      },
      {
        tag_key: "DRIVER_LICENSE",
        data_key: "driver_license",
        label: "Driver's License #",
        replaceWith: "Driver's License #",
      },
      {
        tag_key: "EMAIL",
        data_key: "email",
        label: "Email",
        replaceWith: "Email",
      },
      {
        tag_key: "ID",
        data_key: "id",
        label: "ID #",
        replaceWith: "ID #",
      },
      {
        tag_key: "MOBILE_PHONE",
        data_key: "mobile_phone",
        label: "Mobile Phone",
        replaceWith: "Mobile Phone",
      },
      {
        tag_key: "NEXT_DATE_ELIGIBLE",
        data_key: "next_date_eligible",
        label: "Next Date Eligible",
        replaceWith: "Next Date Eligible",
      },
      {
        tag_key: "WINNER_SIGNATURE",
        data_key: "winner_signature",
        label: "Winner's Signature",
        replaceWith: "Winner's Signature",
      },
      {
        tag_key: "WINNER_INITIALS",
        data_key: "winner_initials",
        label: "Winner's Initials",
        replaceWith: "Winner's Initials",
      },
    ],
  },

  {
    title: "Prize Details",
    list: [
      {
        tag_key: "CONTEST_TITLE",
        data_key: "contest_title",
        label: "Contest Title",
        replaceWith: "Contest Title",
      },
      {
        tag_key: "PRIZE_DESCRIPTION",
        data_key: "prize_description",
        label: "Prize Description",
        replaceWith: "Prize Description",
      },
      {
        tag_key: "PRIZE_TITLE",
        data_key: "prize_title",
        label: "Prize Title",
        replaceWith: "Prize Title",
      },
      {
        tag_key: "PRIZE_VALUE",
        data_key: "prize_value",
        label: "Prize Value",
        replaceWith: "Prize Value",
      },
      {
        tag_key: "YTD_WINNINGS",
        data_key: "ytd_winnings",
        label: "YTD Winnings",
        replaceWith: "YTD Winnings",
      },
      {
        tag_key: "YTD_DISTRIBUITED",
        data_key: "ytd_distributed",
        label: "YTD Distributed",
        replaceWith: "YTD Distributed",
      },
      {
        tag_key: "CURRENT_YEAR",
        data_key: "current_year",
        label: "Current Year",
        replaceWith: "Current Year",
      },
    ],
  },

  {
    title: "Station Details",
    list: [
      {
        tag_key: "STATION_CALL_LETTERS",
        data_key: "station_call_letters",
        label: "Station Call Letters",
        replaceWith: "Station Call Letters",
      },
      {
        tag_key: "STATION_NAME",
        data_key: "station_name",
        label: "Station Name",
        replaceWith: "Station Name",
      },
      {
        tag_key: "STATION_WEBSITE",
        data_key: "station_website",
        label: "Station Website",
        replaceWith: "Station Website",
      },
    ],
  },
];

export const winnerFulfillmentTags = [
  {
    title: "Winner Details",
    list: [
      {
        tag_key: "WINNER_NAME",
        data_key: "winner_name",
        label: "Winner's Name",
        replaceWith: "Winner's Name",
      },
      {
        tag_key: "FIRST_NAME",
        data_key: "first_name",
        label: "First Name",
        replaceWith: "First Name",
      },
      {
        tag_key: "LAST_NAME",
        data_key: "last_name",
        label: "Last Name",
        replaceWith: "Last Name",
      },
      {
        tag_key: "HOME_ADDRESS",
        data_key: "home_address",
        label: "Home Address",
        replaceWith: "Home Address",
      },
      {
        tag_key: "HOME_CITY",
        data_key: "home_city",
        label: "Home City",
        replaceWith: "Home City",
      },
      {
        tag_key: "HOME_PHONE",
        data_key: "home_phone",
        label: "Home Phone",
        replaceWith: "Home Phone",
      },
      {
        tag_key: "WORK_PHONE",
        data_key: "work_phone",
        label: "Work Phone",
        replaceWith: "Work Phone",
      },
      {
        tag_key: "HOME_STATE",
        data_key: "home_state",
        label: "Home State",
        replaceWith: "Home State",
      },
      {
        tag_key: "HOME_ZIP_CODE",
        data_key: "home_zip_code",
        label: "Home ZIP Code",
        replaceWith: "Home ZIP Code",
      },
      {
        tag_key: "CURRENT_AGE",
        data_key: "current_age",
        label: "Current Age",
        replaceWith: "Current Age",
      },
      {
        tag_key: "DATE_OF_BIRTH",
        data_key: "date_of_birth",
        label: "Date Of Birth",
        replaceWith: "Date Of Birth",
      },
      {
        tag_key: "DATE_WON",
        data_key: "date_won",
        label: "Date Won",
        replaceWith: "Date Won",
      },
      {
        tag_key: "DRIVER_LICENSE",
        data_key: "driver_license",
        label: "Driver's License #",
        replaceWith: "Driver's License #",
      },
      {
        tag_key: "EMAIL",
        data_key: "email",
        label: "Email",
        replaceWith: "Email",
      },
      {
        tag_key: "ID",
        data_key: "id",
        label: "ID #",
        replaceWith: "ID #",
      },
      {
        tag_key: "MOBILE_PHONE",
        data_key: "mobile_phone",
        label: "Mobile Phone",
        replaceWith: "Mobile Phone",
      },
      {
        tag_key: "NEXT_DATE_ELIGIBLE",
        data_key: "next_date_eligible",
        label: "Next Date Eligible",
        replaceWith: "Next Date Eligible",
      },
    ],
  },

  {
    title: "Prize Details",
    list: [
      {
        tag_key: "CONTEST_TITLE",
        data_key: "contest_title",
        label: "Contest",
        replaceWith: "Contest",
      },
      {
        tag_key: "PRIZE_DESCRIPTION",
        data_key: "prize_description",
        label: "Prize Description",
        replaceWith: "Prize Description",
      },
      {
        tag_key: "PRIZE_VALUE",
        data_key: "prize_value",
        label: "Prize Value",
        replaceWith: "Prize Value",
      },
      {
        tag_key: "PRIZE_WON",
        data_key: "prize_won",
        label: "Prize Won",
        replaceWith: "Prize Won",
      },
      {
        tag_key: "YTD_WINNINGS",
        data_key: "ytd_winnings",
        label: "YTD Winnings",
        replaceWith: "YTD Winnings",
      },
      {
        tag_key: "YTD_DISTRIBUITED",
        data_key: "ytd_distributed",
        label: "YTD Distributed",
        replaceWith: "YTD Distributed",
      },
      {
        tag_key: "CURRENT_YEAR",
        data_key: "current_year",
        label: "Current Year",
        replaceWith: "Current Year",
      },
    ],
  },

  {
    title: "Station Details",
    list: [
      {
        tag_key: "STATION_CALL_LETTERS",
        data_key: "station_call_letters",
        label: "Station",
        replaceWith: "Station",
      },
      {
        tag_key: "STATION_NAME",
        data_key: "station_name",
        label: "Station Name",
        replaceWith: "Station Name",
      },
      {
        tag_key: "STATION_WEBSITE",
        data_key: "station_website",
        label: "Station Website",
        replaceWith: "Station Website",
      },
    ],
  },
];

export const orderLengthOptions = [
  {
    label: <FormattedMessage id="process > option label 10 secs" />,
    value: 10,
  },
  {
    label: <FormattedMessage id="process > option label 15 secs" />,
    value: 15,
  },
  {
    label: <FormattedMessage id="process > option label 20 secs" />,
    value: 20,
  },
  {
    label: <FormattedMessage id="process > option label 30 secs" />,
    value: 30,
  },
  {
    label: <FormattedMessage id="process > option label 60 secs" />,
    value: 60,
  },
  {
    label: <FormattedMessage id="process > option label 90 secs" />,
    value: 90,
  },
  {
    label: <FormattedMessage id="process > option label 120 secs" />,
    value: 120,
  },
];

export const productionTypeOptions = [
  {
    label: <FormattedMessage id="process > option label production complete" />,
    value: "Production Complete",
  },
  {
    label: (
      <FormattedMessage id="process > option label production provided needed tags" />
    ),
    value: "Production Provided - Needs tags",
  },
];

export const hubTypes = [
  {
    label: "production hub",
    value: "production",
  },
  {
    label: "continuity hub",
    value: "continuity",
  },
];

export const filterAccountsOptions = [
  {
    value: "all",
    label: <FormattedMessage id="process > filter all accounts" />,
  },
  {
    value: "my_account",
    label: <FormattedMessage id="process > filter my accounts" />,
  },
];
