import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";
import { injectIntl } from "react-intl";
import PrizePackage from "./view";
import { get } from "lodash";
import { forgetPrizes, setPrizes } from "store/actions/prizes";
const Enhanced = compose(
  connect(
    (state, props) => ({
      prizesList: get(state, `prizes.prizes.filter_prizes_list.data`, []),
      ...props,
    }),
    {
      forgetPrizes,
      setPrizes,
    }
  ),
  withHandlers({
    onRefreshPrizes: ({ forgetPrizes }) => () => {
      forgetPrizes();
    },
  })
)(PrizePackage);
export default injectIntl(Enhanced);
