import React from "react";
import bn from "utils/bemnames";
import { Button } from "reactstrap";
import { FormattedMessage } from "react-intl";
import truncate from "truncate";
import { get } from "lodash";
const bem = bn.create("live-preview");

const View = props => {
  const themeBackgroundColor = get(props, "theme_background_color", "");
  const isBackgroundWhite = themeBackgroundColor.toLowerCase() === "#ffffff";
  const buttonColor = get(props, "button_color", "#ffffff");
  const isButtonColorWhite = buttonColor.toLowerCase() === "#ffffff";
  const buttonStyles = {
    background: buttonColor,
    color: isButtonColorWhite ? "#334856" : "#ffffff",
    borderColor: isButtonColorWhite ? "#334856" : buttonColor
  };
  const invertButtonStyles = {
    color: buttonColor,
    borderColor: buttonColor
  };

  return (
    <div className={bem.b()}>
      <div className={bem.e('background-view')}
        style={{
          backgroundImage: `url(${get(props, "background_image.path")})`
        }}
      >
        <div
          className={bem.e("top-bar")}
          style={{
            backgroundColor: themeBackgroundColor,
            boxShadow: isBackgroundWhite ? `0px 0px 5px 0 #aaa` : "none"
          }}
        >
          {get(props, "logo.path") ? (
            <img src={get(props, "logo.path")} alt="logo" className={bem.e("logo")} />
          ) : <h3
            className={bem.e("group-name")}
            style={{ color: isBackgroundWhite ? "#334856" : "#ffffff" }}
          >
            {get(props, "name") ? truncate(get(props, "name"), 50) : <FormattedMessage id="server > radio group" />}
          </h3>}
          <h3
            className={bem.e("tag-line")}
            style={{ color: isBackgroundWhite ? "#334856" : "#ffffff" }}
          >
            {get(props, "tag_line") ? truncate(get(props, "tag_line"), 50) : <FormattedMessage id="server > radio group tagline" />}
          </h3>
        </div>
        <div className={bem.e("content")}>
          <div className={bem.e("left-content")}>
            <h4 className={bem.e("sample-title")}>
              <FormattedMessage id="live preview > sample content" />
            </h4>
            <p className={bem.e("text")}>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s
            </p>
          </div>
          <div className={bem.e("buttons")}>
            <Button
              className={bem.e("button")}
              style={buttonStyles}
            >
              <FormattedMessage id="live preview > button text" />
            </Button>
            <Button
              className={bem.e("button")}
              style={invertButtonStyles}
            >
              <FormattedMessage id="live preview > button text" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default View;
