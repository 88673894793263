import React, { useState } from "react";
import View from "./view";
export default function Security(props) {
  const [isOpenChangePasswordModal, setIsOpenChangePasswordModal] = useState(
    false
  );
  return (
    <View
      {...props}
      {...Object.assign(
        {},
        {
          isOpenChangePasswordModal,
          setIsOpenChangePasswordModal,
        }
      )}
    />
  );
}
