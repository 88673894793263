import React from "react";
import PropTypes from "prop-types";
import { Label } from "reactstrap";
import { get, findIndex, find } from "lodash";
import classnames from "classnames";
import Select, { components } from "react-select";
import bn from "utils/bemnames";
import CreatableSelect from "react-select/lib/Creatable";
import TextInput from "components/TextInput";
import Checkbox from "components/Checkbox";
import { injectIntl } from "react-intl";
import {
  CaretIcon,
  CloseTagDropdownIcon,
  CloseSmallIcon,
} from "components/CustomIcons";
import { getWindowDimensions } from "utils/useWindowDimensions";
const bem = bn.create("dropdown");
const { width: widthScreen } = getWindowDimensions();
class Dropdown extends React.Component {
  static propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    error: PropTypes.string,
    name: PropTypes.string.isRequired,
    labelProps: PropTypes.object,
    allowSelectAll: PropTypes.bool,
    allOption: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    closeMenuOnSelect: PropTypes.bool,
    isCheckbox: PropTypes.bool,
    hideSelectedOptions: PropTypes.bool,
    onRemoveOption: PropTypes.func,
    menuShouldBlockScroll: PropTypes.bool,
  };

  static defaultProps = {
    labelProps: {},
    allOption: {
      label: "Select All",
      value: "*",
    },
    onChange: () => {},
    allowSelectAll: false,
    name: "",
    closeMenuOnSelect: true,
    isCheckbox: false,
    hideSelectedOptions: true,
    onRemoveOption: () => {},
    menuShouldBlockScroll: widthScreen <= 480 ? false : true,
  };

  renderSelectComponent() {
    /* eslint-disable */
    const {
      label,
      error,
      name,
      labelProps,
      allowSelectAll,
      allOption,
      isCheckbox,
      optionContainerClass,
      allowDeleteOption,
      onRemoveOption,
      ...rest
    } = this.props;
    const closeMenuOnSelect = rest.isMulti ? false : rest.closeMenuOnSelect;
    let configComponents = {};
    if (isCheckbox) {
      const CustomOption = (option) => {
        const optionSelectedIndex = findIndex(
          get(rest, "value"),
          (item) => item.value === option.value
        );
        const isOptionSelected = optionSelectedIndex > -1;
        const labelText = (
          <span className={classnames(bem.e("option-text"))}>
            {option.data.label}
          </span>
        );
        return (
          <div
            className={classnames(
              bem.e("option-container"),
              optionContainerClass,
              option.data.label
            )}
          >
            <components.Option {...option}>
              <div className={bem.e("option")}>
                <Checkbox checked={isOptionSelected} text={labelText} />
              </div>
            </components.Option>
          </div>
        );
      };
      configComponents.Option = CustomOption;
    } else {
      const CustomOption = ({ children, ...rest }) => {
        return (
          components.Option && (
            <div
              className={classnames(
                bem.e("option-container"),
                get(rest, "data.data.className", "")
              )}
            >
              <components.Option {...rest}>{children}</components.Option>
              {get(rest, "data.allowDeleteOption", false) && (
                <span
                  className={bem.e("remove-option")}
                  onClick={() => onRemoveOption(rest.data)}
                >
                  <CloseSmallIcon />{" "}
                </span>
              )}
            </div>
          )
        );
      };
      configComponents.Option = CustomOption;
    }
    const DropdownIndicator = (props) => {
      return (
        components.DropdownIndicator && (
          <components.DropdownIndicator {...props} className="disable-unsaved">
            <CaretIcon className="disable-unsaved" />
          </components.DropdownIndicator>
        )
      );
    };
    configComponents.DropdownIndicator = DropdownIndicator;
    const ClearIndicator = (props) => {
      return (
        components.ClearIndicator && (
          <components.ClearIndicator {...props} className="disable-unsaved">
            <CloseSmallIcon className="disable-unsaved" />
          </components.ClearIndicator>
        )
      );
    };
    configComponents.ClearIndicator = ClearIndicator;
    const MultiValueRemove = (props) => {
      return (
        components.MultiValueRemove && (
          <components.MultiValueRemove {...props}>
            <CloseTagDropdownIcon />
          </components.MultiValueRemove>
        )
      );
    };
    configComponents.MultiValueRemove = MultiValueRemove;
    /* eslint-enable */
    if (rest.allowNew) {
      return (
        <CreatableSelect
          {...rest}
          isClearable
          options={rest.options}
          onChange={(selected, meta) => {
            return rest.onChange(selected, meta);
          }}
          className={classnames(
            "react-select-container",
            this.props.className && this.props.className.toString(),
            {
              "react-select-menu-open": rest.isMenuOpen,
            }
          )}
          classNamePrefix="react-select"
          closeMenuOnSelect={closeMenuOnSelect}
          components={configComponents}
          onMenuOpen={() => rest.setIsMenuOpen(true)}
          onMenuClose={() => rest.setIsMenuOpen(false)}
          styles={{
            // Fixes the overlapping problem of the component
            menu: (provided) => ({ ...provided, zIndex: 9999 }),
          }}
        />
      );
    }
    // rest.isMulti &&
    if (allowSelectAll) {
      if (get(rest, "value.length") === get(rest, "options.length")) {
        return (
          <Select
            {...rest}
            value={get(rest, "value")}
            onChange={(selected, meta) => {
              // rest.onChange(selected.slice(1))
              return rest.onChange(selected, meta);
            }}
            className={classnames(
              "react-select-container",
              this.props.className && this.props.className.toString(),
              {
                "react-select-menu-open": rest.isMenuOpen,
              }
            )}
            classNamePrefix="react-select"
            closeMenuOnSelect={closeMenuOnSelect}
            components={configComponents}
            onMenuOpen={() => rest.setIsMenuOpen(true)}
            onMenuClose={() => rest.setIsMenuOpen(false)}
            styles={{
              // Fixes the overlapping problem of the component
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
          />
        );
      }

      return (
        <Select
          {...rest}
          options={[allOption, ...rest.options]}
          className={classnames(
            "react-select-container",
            this.props.className && this.props.className.toString(),
            {
              "react-select-menu-open": rest.isMenuOpen,
            }
          )}
          classNamePrefix="react-select"
          onChange={(selected, meta) => {
            if (
              Array.isArray(selected) &&
              find(selected, (item) => item.value === allOption.value)
            ) {
              return rest.onChange(rest.options, meta);
            }
            if (
              !rest.isMulti &&
              selected &&
              selected.value === allOption.value
            ) {
              return rest.onChange(rest.options, meta);
            }
            return rest.onChange(selected, meta);
          }}
          components={configComponents}
          closeMenuOnSelect={closeMenuOnSelect}
          onMenuOpen={() => rest.setIsMenuOpen(true)}
          onMenuClose={() => rest.setIsMenuOpen(false)}
          styles={{
            // Fixes the overlapping problem of the component
            menu: (provided) => ({ ...provided, zIndex: 9999 }),
          }}
        />
      );
    }

    return (
      <Select
        name={name}
        {...rest}
        closeMenuOnSelect={closeMenuOnSelect}
        className={classnames(
          "react-select-container",
          this.props.className && this.props.className.toString(),
          {
            "react-select-menu-open": rest.isMenuOpen,
          }
        )}
        classNamePrefix="react-select"
        components={configComponents}
        onMenuOpen={() => rest.setIsMenuOpen(true)}
        onMenuClose={() => rest.setIsMenuOpen(false)}
        styles={{
          // Fixes the overlapping problem of the component
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
        }}
      />
    );
  }

  render() {
    const {
      label,
      error,
      name,
      labelProps,
      disabled,
      value,
      intl,
    } = this.props;
    if (disabled) {
      let valueInput = "";
      if (get(value, "label")) {
        valueInput =
          typeof value.label === "string"
            ? value.label
            : get(value, "label.props.id")
            ? intl.formatMessage({
                id: value.label.props.id,
              })
            : "";
      }

      return (
        <TextInput
          disabled={true}
          placeholder={this.props.placeholder}
          label={label}
          value={valueInput}
        />
      );
    }
    return (
      <div className={this.props.containerClass || ""}>
        {!!label && (
          <Label for={name} {...labelProps}>
            {label}
          </Label>
        )}
        {this.renderSelectComponent()}
        {!!error && <span className="text-danger">{error}</span>}
      </div>
    );
  }
}

export default injectIntl(Dropdown);
