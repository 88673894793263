import React from "react";
import bn from "utils/bemnames";
import classnames from "classnames";
import { map, get, filter } from "lodash";
import { FaPlus } from "react-icons/lib/fa";
import { Row, Col, Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { FormattedMessage } from "react-intl";
import WorkflowManagerItem from "./WorkflowManagerItem";
import ConfirmationModal from "components/ConfirmationModal";
import Spinner from "components/Spinner";
import PrivilegedComponent from "components/PrivilegedComponent";
import { PRIVILEGES } from "utils/constants";
const bem = bn.create("workflow-manager");

const WorkflowManager = props => {
  const {
    workflows,
    isOpenSteps,
    setIsOpenWorkflow,
    isOpenDropdownNewWorkflow,
    onToggleOpenDropdownNewWorkflow,
    newWorkflow,
    setNewWorkflow,
    onCreateNewWorkflow,
    getDefaultSystemWorkfolows,
    onSubmitForm,
    loading,
    roles,
    users,
    isOpenConfirmationModal,
    setIsOpenConfirmationModal,
    onDeleteWorkflow,
    setCurrentWorkflowDelete
  } = props;
  const defaultSystemWorkfolows = getDefaultSystemWorkfolows();
  const optionsCreate = filter(
    defaultSystemWorkfolows,
    item => item.is_allow_create_custom_workflow
  );
  const customWorkflowsSource = map(workflows, item => ({
    ...item._source
  }));
  return (
    <div className={bem.b()}>
      <Row>
        <Col xs={12}>
          <h2
            className={classnames(bem.e("subtitle"), bem.e("system-workflows"))}
          >
            <FormattedMessage id="workflow manager > default system workflows" />
          </h2>
        </Col>
        <Col xs={11}>
          {map(defaultSystemWorkfolows, (item, index) => (
            <WorkflowManagerItem
              {...item}
              key={index}
              isOpenWorkflow={get(isOpenSteps, item.id)}
              setIsOpenWorkflow={setIsOpenWorkflow}
              roles={roles}
              users={users}
            />
          ))}
        </Col>
      </Row>
      {/* <Row>
        <Col xs={11}>
          <h2
            className={classnames(bem.e("subtitle"), bem.e("custom-workflows"))}
          >
            <FormattedMessage id="workflow manager > custom workflows" />
          </h2>
          {map(customWorkflowsSource, (item, index) => (
            <WorkflowManagerItem
              {...item}
              itemValues={{...item}}
              key={index}
              onSubmitForm={onSubmitForm}
              isOpenWorkflow={get(isOpenSteps, item.id)}
              onDelete={(workflowId) => {
                setIsOpenConfirmationModal(true);
                setCurrentWorkflowDelete(workflowId);
              }}
              setIsOpenWorkflow={setIsOpenWorkflow}
              roles={roles}
              users={users}
            />
          ))}
          {newWorkflow ? (
            <WorkflowManagerItem
              {...newWorkflow}
              isOpenWorkflow={get(isOpenSteps, newWorkflow.id)}
              setIsOpenWorkflow={setIsOpenWorkflow}
              onCancel={() => {
                setIsOpenWorkflow({});
                setNewWorkflow(null);
              }}
              isView={false}
              onSubmitForm={onSubmitForm}
              roles={roles}
              users={users}
            />
          ) : null}
          <PrivilegedComponent requires={[PRIVILEGES.EDIT_WORKFLOWS]}>
            <div className={bem.e("create-container")}>
              <Dropdown
                isOpen={isOpenDropdownNewWorkflow}
                toggle={onToggleOpenDropdownNewWorkflow}
                direction="down"
              >
                <DropdownToggle
                  tag="button"
                  data-toggle="dropdown"
                  className={classnames(bem.e("button-create"), "btn")}
                  aria-expanded={isOpenDropdownNewWorkflow}
                >
                  <FaPlus className={bem.e("icon-add")} />
                  <FormattedMessage id="workflow manager > create new workflow" />
                </DropdownToggle>
                <DropdownMenu>
                  {map(optionsCreate, (item, index) => (
                    <div
                      className={bem.e("dropdown-text")}
                      onClick={() => onCreateNewWorkflow(item)}
                      key={index}
                    >
                      <FormattedMessage id={`workflow manager > ${item.id}`} />
                    </div>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </div>
          </PrivilegedComponent>
        </Col>
        <Col xs={1}>
          {customWorkflowsSource.length ? (
            <div className={bem.e("published-title")}>
              <FormattedMessage id="workflow manager > published" />
            </div>
          ) : null}
        </Col>
      </Row> */}
      <Spinner isLoading={loading} />
      {/* confirmation delete modal */}
      <ConfirmationModal
        isOpen={isOpenConfirmationModal}
        className={bem.e("confirmation-modal")}
        title={
          <FormattedMessage id="workflow manager > Are you sure you want to delete this workflow?" />
        }
        cancelTitle={<FormattedMessage id="workflow manager > cancel" />}
        confirmTitle={
          <FormattedMessage id="workflow manager > delete" />
        }
        onCancel={() => {
          setIsOpenConfirmationModal(false);
          setCurrentWorkflowDelete(null);
        }}
        onConfirm={onDeleteWorkflow}
        onToggle={() => setIsOpenConfirmationModal(!isOpenConfirmationModal)}
        isCloseOutside={false}
      />
    </div>
  );
};
export default WorkflowManager;
