import { connect } from "react-redux";
import View from "./view";
import { get } from "lodash";
import { injectIntl } from "react-intl";
import { compose, withHandlers } from "recompose";
import {
  markAsSeenMessages,
  getMessagesByProcess,
} from "store/actions/messages";
const CommentsPanel = compose(
  connect(
    (state) => ({
      info: state.auth.info,
      user: state.auth.user,
      messages: get(state, "messages.messages", []),
    }),
    {
      markAsSeenMessages,
      getMessagesByProcess,
    }
  ),
  withHandlers({
    onMarkAsSeenMessages: ({
      markAsSeenMessages,
      getMessagesByProcess,
      processKey,
      user,
      messages,
    }) => () => {
      if (processKey) {
        const taggedMessages = messages.find((item) => {
          const message = get(item, "message");
          return (
            message.m_type === "chat" && message.tagged.indexOf(user.id) !== -1
          );
        });
        if (!taggedMessages) return;
        // This api will update all messages associated with the process and remove the current user from tagged attribute.
        markAsSeenMessages(processKey, () => {
          getMessagesByProcess(processKey);
        });
      }
    },
  })
)(View);
export default injectIntl(CommentsPanel);
