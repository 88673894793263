import ChatBoxView from "./view";
import get from "lodash/get";
import { connect } from "react-redux";
import { compose, withState, withHandlers } from "recompose";
import {
  editMessage,
  deleteMessage,
  markAsSeenMessages,
} from "store/actions/messages";
const Enhancer = compose(
  connect(
    (state) => ({
      process: get(state, "processes.process"),
      user: get(state, "auth.user"),
    }),
    { editMessage, deleteMessage, markAsSeenMessages }
  ),
  withState("minimized", "setMinimized", false),
  withState("editingMessage", "setEditingMessage", null),
  withState("deletingMessage", "setDeletingMessage", null),
  withState("isDeleteModalOpen", "setIsDeleteModalOpen", false),
  withHandlers({
    toggleMinimized: ({ minimized, setMinimized }) => () => {
      setMinimized(!minimized);
    },
    onDeleteMessage: ({ deleteMessage, setEditingMessage }) => (id) => {
      deleteMessage({ id });
      setEditingMessage(false);
    },
    onEditMessage: ({ editMessage, setEditingMessage }) => (values) => {
      editMessage(values);
      setEditingMessage(false);
    },
    onMarkAsSeenMessages: ({ markAsSeenMessages, process }) => (
      messagesTagged
    ) => {
      const processKey = get(process, "data.fields.key.value");
      if (processKey && messagesTagged.length > 0) {
        // This api will update all messages associated with the process and remove the current user from tagged attribute.
        markAsSeenMessages(processKey);
      }
    },
  })
)(ChatBoxView);

export default Enhancer;
