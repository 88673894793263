import { FormattedMessage } from "react-intl";
import React from "react";
import {
  get,
  filter,
  includes,
  uniq,
  find,
  isEmpty,
  forEach,
  map,
  delay,
} from "lodash";
import moment from "moment";
import history from "components/History";
import { getLocalStorageDateFormat } from "utils/dates";
import {
  getNextTabToActive,
  checkUserRedirectToCurrentTasks,
} from "utils/helpers";

const approveScript = {
  process_step_index: 4,
  key: "approve_script",
  title: <FormattedMessage id="process > title script review" />,
  caption_color: "#CC1EE6",
  description: <FormattedMessage id="process > creative details" />,
  isCollapsible: true,
  step_roles: [1, 2, 3],
  step_viewers_roles: [1, 2, 3],
  step_editors_roles: [1, 2, 3],
  step_viewers_privileges: {
    or: ["approve_production_script", "edit_all_production_orders"],
  },
  step_editors_privileges: {
    or: ["approve_production_script", "edit_all_production_orders"],
  },
  is_check_hidden_tab: true,
  status: "initial",
  className: "approve-script",
  is_hidden: function(user, process, step) {
    const spotInfo = get(process, "data.fields.spot_info.value", []).find(
      (spot) => spot.key === process.key
    );
    if (
      get(process, "data.fields.contract_status.value") !==
        "Production Needed" ||
      !get(spotInfo, "script_requires_approval", false) ||
      get(spotInfo, "script_type", "draft") === "final"
    )
      return true;
    return false;
  },
  isOpen: function({ step, process, template, user }) {
    return step.process_step_index !== 4;
  },
  workflow: {
    title: "approve script",
    icon: "ApprovalIcon",
  },
  is_approval_step: true,
  fields: [
    {
      component: "order_approvals",
    },
    {
      field: "spot_info",
    },
    {
      field: "script",
      container: "segment",
      component: "order_script_review",
      isCollapsible: false,
      isSpot: true,
      props: {
        classNameSegment: "segment-script script-review-segment",
        className: "form-div-script",
        readOnly: true,
        isShowOrderLength: false,
      },
      title: <FormattedMessage id="process > title final script" />,
      is_hidden: function(user, process, step, isView) {
        return (
          isView &&
          !isEmpty(get(process, "data.fields.script_history.value", []))
        );
      },
    },
    {
      field: "script_part_b",
    },
    {
      field: "script_feedback",
    },
    {
      field: "script_part_b_feedback",
    },
    {
      field: "script_history",
      container: "segment",
      component: "order_script_review_history",
      props: {
        classNameSegment: "segment-script-history",
        className: "form-div-script-history",
        readOnly: true,
      },
      isCollapsible: true,
      title: <FormattedMessage id="process > field script history" />,
      is_hidden: function(user, process, step, isView) {
        return isEmpty(get(process, "data.fields.script_history.value", []));
      },
      isOpen: function({ step, process, template, user }) {
        return false;
      },
    },
    {
      field: "sent_to_client_for_script_review",
    },
    {
      field: "approve",
      text: <FormattedMessage id="process > button approve" />,
      component: "button",
      container: "bottom_buttons",
      props: { color: "blue" },
      is_hidden: function(user, process, step) {
        const spotInfo = get(process, "data.fields.spot_info.value", []).find(
          (spot) => spot.key === process.key
        );
        const scriptValue = get(spotInfo, "script");
        return (
          !scriptValue ||
          get(process, "data.fields.script_feedback.value", null) !== null ||
          get(process, "data.fields.approve_script_status.value", null) ===
            "approved"
        );
      },
      on_click: {
        validate: true,
        save: true,
        go_to: function(_this, step, item, process, template, user, extraData) {
          const nextUsers = get(
            process,
            "data.fields.next_users.value",
            []
          ).map(Number);
          const return_to_current_tasks = checkUserRedirectToCurrentTasks({
            ...user,
            roles_string: extraData.roles_string,
          });
          const isUserAssignedNextStep = nextUsers.includes(Number(user.id));

          if (!return_to_current_tasks && _this.props.setActiveTab) {
            const nextStepIndex = getNextTabToActive(); // use javascript to find next tab
            _this.props.setActiveTab(nextStepIndex);
            if (isUserAssignedNextStep) {
              _this.props.setActiveEditTab(nextStepIndex);
            }
            return;
          }
          // set delay when after success message
          delay(() => {
            if (_this.props.setSidebarActive) {
              _this.props.setSidebarActive(`/processes/my_action_items`);
            }
            history.replace(`/processes/my_action_items`);
          }, 4000);
        },
      },
      fields: [
        {
          key: "approve_script_status",
          value: "approved",
        },
        {
          key: "write_script_status",
          value: "completed",
        },
        {
          key: "process_step",
          value: "first_undone",
        },
        {
          key: "sent_to_client_for_script_review",
          value: false,
        },
        {
          key: "next_users",
          value: (user, template, process, step, item, next_users) => {
            const roleProducers = get(
              process,
              "data.fields.role_producer.value.users",
              []
            ).map((producer) => producer.id);
            let requiredNextUsers = [];
            const roleVoiceTalens = get(
              process,
              "data.fields.role_voice_talent.value.users",
              []
            ).map((voice) => voice.id);
            const roleVosIsNotProducers = filter(
              roleVoiceTalens,
              (userId) => !includes(roleProducers, userId)
            );
            let nextUsers = [];
            if (
              roleVoiceTalens.length === 0 ||
              roleVosIsNotProducers.length === 0
            ) {
              nextUsers = [...next_users, ...roleProducers];
              requiredNextUsers = roleProducers;
            } else {
              // ensure voice talents is exists next users
              nextUsers = filter(
                [...next_users, ...roleVoiceTalens],
                (nexUserId) => {
                  return !includes(roleProducers, nexUserId);
                }
              );
              requiredNextUsers = roleVoiceTalens;
            }
            requiredNextUsers = requiredNextUsers.map(Number);

            let roleAE = [];

            if (
              get(
                process,
                "data.fields.role_account_executive.value.users",
                false
              )
            ) {
              roleAE = get(
                process,
                "data.fields.role_account_executive.value.users",
                []
              ).map((ae) => ae.id);
            } else if (
              get(process, "data.fields.role_account_executive.value.id", false)
            ) {
              roleAE.push(
                get(process, "data.fields.role_account_executive.value.id", "")
              );
            }
            // add additional sales
            if (
              get(process, "data.fields.role_sales_person_2.value.id", false)
            ) {
              roleAE.push(
                get(process, "data.fields.role_sales_person_2.value.id", "")
              );
            }
            // case: AE assigned to: role_account_executive, role_producer or role_voice_talent. We need assign to producer or voice if script approved
            roleAE = roleAE
              .map(Number)
              .filter((id) => !requiredNextUsers.includes(id));

            nextUsers = filter(nextUsers, (nexUserId) => {
              return !includes(roleAE, nexUserId);
            });

            nextUsers = uniq(nextUsers);
            return nextUsers;
          },
        },
      ],
      confirmation_dialog_props: {
        title: (
          <FormattedMessage id="process > description to approve the script" />
        ),
        confirmTitle: <FormattedMessage id="process > button yes" />,
        cancelTitle: <FormattedMessage id="process > button no" />,
      },
      success_dialog_props: {
        title: <FormattedMessage id="process > title approved" />,
        state: "success",
      },
    },
    {
      field: "request_changes",
      text: <FormattedMessage id="process > button request changes" />,
      component: "button",
      props: { outline: true, color: "blue" },
      container: "bottom_buttons",
      fields: [
        {
          key: "script_feedback",
          value: "",
        },
        {
          key: "script_part_b_feedback",
          value: "",
        },
      ],
      on_click: {
        validate: false,
        save: false,
        updateFormValue: true,
        go_to: function(_this, step, item, process, template, user) {
          // if (!user.return_to_current_tasks) return;
          // set delay when after success message
          delay(() => {
            if (_this.props.setSidebarActive) {
              _this.props.setSidebarActive(`/processes/my_action_items`);
            }
            history.replace(`/processes/my_action_items`);
          }, 4000);
        },
      },
      is_hidden: function(user, process, step) {
        const spotInfo = get(process, "data.fields.spot_info.value", []).find(
          (spot) => spot.key === process.key
        );
        const scriptValue = get(spotInfo, "script");
        return (
          !scriptValue ||
          get(process, "data.fields.script_feedback.value", null) !== null ||
          get(process, "data.fields.approve_script_status.value", null) ===
            "approved"
        );
      },
    },
    {
      field: "submit_feedback",
      text: <FormattedMessage id="process > button submit" />,
      key: "save_button",
      component: "button",
      props: { color: "blue" },
      container: "bottom_buttons",
      is_hidden: function(user, process, step) {
        const spotInfo = get(process, "data.fields.spot_info.value", []).find(
          (spot) => spot.key === process.key
        );
        const scriptValue = get(spotInfo, "script");
        return (
          !scriptValue ||
          get(process, "data.fields.script_feedback.value", null) === null
        );
      },
      fields: [
        {
          key: "approve_script_status",
          value: "rejected",
        },
        {
          key: "write_script_status",
          value: "rejected",
        },
        {
          key: "process_step",
          value: "write_script",
        },
        {
          key: "sent_to_client_for_script_review",
          value: false,
        },
        {
          key: "script_feedback_audit",
          value: (user, template, process, step, item, next_users) => {
            const format = getLocalStorageDateFormat();
            return {
              modified_at: `${moment()
                .format(format)
                .toString()} at ${moment()
                .format("h:mm A")
                .toString()}`,
              modified_by: user.name,
            };
          },
        },
        {
          key: "script_part_b_feedback_audit",
          value: (user, template, process, step, item, next_users) => {
            const spot = find(
              get(process, "data.fields.spot_info.value", []),
              (spot) => spot.key === process.key
            );

            const isBookend =
              get(spot, "spot_type", "Standard") === "Book-ends";

            if (isBookend) {
              const format = getLocalStorageDateFormat();
              return {
                modified_at: `${moment()
                  .format(format)
                  .toString()} at ${moment()
                  .format("h:mm A")
                  .toString()}`,
                modified_by: user.name,
              };
            }

            return {};
          },
        },
        {
          key: "script_history",
          value: function(user, template, process, step, item, nextUsers) {
            const spotInfo = get(
              process,
              "data.fields.spot_info.value",
              []
            ).find((spot) => spot.key === process.key);

            let currentScriptHistory = get(
              process,
              "data.fields.script_history.value",
              []
            );
            let newScriptHistory = [];

            let sriptApproveStatus = get(
              process,
              "data.fields.approve_script_status.value",
              ""
            );
            if (
              sriptApproveStatus === "rejected" &&
              get(process, "data.fields.process_step_index.value") === 3
            ) {
              newScriptHistory = currentScriptHistory;
              newScriptHistory[0] = {
                script: get(spotInfo, "script"),
                script_part_b: get(spotInfo, "script_part_b"),
                script_review_status: get(spotInfo, "script_review_status", ""),
                script_part_b_review_status: get(
                  spotInfo,
                  "script_part_b_review_status",
                  ""
                ),
                script_audit: get(process, "data.fields.script_audit.value"),
                script_part_b_audit: get(
                  process,
                  "data.fields.script_part_b_audit.value"
                ),
                feedback: get(process, "data.fields.script_feedback.value"),
                script_part_b_feedback: get(
                  process,
                  "data.fields.script_part_b_feedback.value"
                ),
                script_feedback_audit: get(
                  process,
                  "data.fields.script_feedback_audit.value"
                ),
                script_part_b_feedback_audit: get(
                  process,
                  "data.fields.script_part_b_feedback_audit.value"
                ),
              };
            } else {
              newScriptHistory.push({
                script: get(spotInfo, "script"),
                script_part_b: get(spotInfo, "script_part_b"),
                script_review_status: get(spotInfo, "script_review_status", ""),
                script_part_b_review_status: get(
                  spotInfo,
                  "script_part_b_review_status",
                  ""
                ),
                script_audit: get(process, "data.fields.script_audit.value"),
                script_part_b_audit: get(
                  process,
                  "data.fields.script_part_b_audit.value"
                ),
                feedback: get(process, "data.fields.script_feedback.value"),
                script_part_b_feedback: get(
                  process,
                  "data.fields.script_part_b_feedback.value"
                ),
                script_feedback_audit: get(
                  process,
                  "data.fields.script_feedback_audit.value"
                ),
                script_part_b_feedback_audit: get(
                  process,
                  "data.fields.script_part_b_feedback_audit.value"
                ),
              });

              forEach(currentScriptHistory, (scriptHistory) => {
                newScriptHistory.push(scriptHistory);
              });
            }

            return newScriptHistory;
          },
        },
        {
          key: "spot_info",
          value: function(user, template, process, step, item) {
            let spotInfo = map(
              get(process, "data.fields.spot_info.value", []),
              (spot) => ({
                ...spot,
                script:
                  spot.key === process.key &&
                  spot.script_review_status !== "approved"
                    ? ""
                    : spot.script,
                script_part_b:
                  spot.key === process.key &&
                  spot.script_part_b_review_status !== "approved"
                    ? ""
                    : spot.script_part_b,
              })
            );
            return spotInfo;
          },
        },
        {
          key: "next_users",
          value: (user, template, process, step, item, next_users) => {
            let continuityStatus = get(
              process,
              "data.fields.continuity_status.value",
              ""
            );
            let requiredNextUsers = [];
            const roleContinuity = get(
              process,
              "data.fields.role_continuity.value.users",
              []
            ).map((continuity) => continuity.id);

            const roleWriter = get(
              process,
              "data.fields.role_writer.value.users",
              []
            ).map((writer) => writer.id);

            let nextUsers = next_users;
            requiredNextUsers = [...roleWriter];
            if (continuityStatus !== "completed") {
              nextUsers = [...nextUsers, ...roleContinuity, ...roleWriter];
              requiredNextUsers = [...roleContinuity, ...roleWriter];
            }
            requiredNextUsers = requiredNextUsers.map(Number);
            let roleAE = [];

            if (
              get(
                process,
                "data.fields.role_account_executive.value.users",
                false
              )
            ) {
              roleAE = get(
                process,
                "data.fields.role_account_executive.value.users",
                []
              ).map((ae) => ae.id);
            } else if (
              get(process, "data.fields.role_account_executive.value.id", false)
            ) {
              roleAE.push(
                get(process, "data.fields.role_account_executive.value.id", "")
              );
            }
            // add additional sales
            if (
              get(process, "data.fields.role_sales_person_2.value.id", false)
            ) {
              roleAE.push(
                get(process, "data.fields.role_sales_person_2.value.id", "")
              );
            }
            // case: AE assigned to: role_account_executive, role_writer, role_continuity. We need assign back to writer and continuity if script rejected
            roleAE = roleAE
              .map(Number)
              .filter((id) => !requiredNextUsers.includes(id));
            if (roleAE.length > 0) {
              nextUsers = filter(nextUsers, (nexUserId) => {
                return !includes(roleAE, nexUserId);
              });
            }
            return uniq(nextUsers);
          },
        },
      ],
      on_click: {
        validate: true,
        save: true,
        go_to: function(_this, step, item, process, template, user, extraData) {
          const return_to_current_tasks = checkUserRedirectToCurrentTasks({
            ...user,
            roles_string: extraData.roles_string,
          });
          if (!return_to_current_tasks) return;
          // set delay when after success message
          delay(() => {
            if (_this.props.setSidebarActive) {
              _this.props.setSidebarActive(`/processes/my_action_items`);
            }
            history.replace(`/processes/my_action_items`);
          }, 4000);
        },
      },
      confirmation_dialog_props: {
        title: (
          <FormattedMessage id="process > description request changes to the script" />
        ),
        confirmTitle: <FormattedMessage id="process > button yes" />,
        cancelTitle: <FormattedMessage id="process > button no" />,
      },
      success_dialog_props: {
        title: <FormattedMessage id="process > title change request sent" />,
        state: "success",
      },
    },
    {
      component: "send_mail",
      container: "bottom_buttons",
      is_hidden: function(user, process, step, isView) {
        let spotInfo = find(
          get(process, "data.fields.spot_info.value", []),
          (spot) => {
            return spot.key === process.key;
          }
        );
        const scriptValue = get(spotInfo, "script");
        return (
          isView ||
          !spotInfo ||
          !spotInfo.script_requires_approval ||
          !scriptValue ||
          get(process, "data.fields.write_script_status.value") ===
            "rejected" ||
          get(process, "data.fields.approve_script_status.value") ===
            "approved" ||
          get(process, "data.fields.script_feedback.value", null) !== null
        );
      },
      props: {
        color: "blue",
        outline: true,
        is_spot_review: false,
      },
    },
  ],
};
export default approveScript;
