import React, { useState } from "react";
import bn from "utils/bemnames";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import { debounce, find, get, sum, map } from "lodash";
import { FaSortDesc, FaSortAsc, FaPlus } from "react-icons/lib/fa";
import { Table, Button, Row, Col } from "reactstrap";
import NoItemsFound from "components/NoItemsFound";
import useInfiniteScroll from "utils/useInfiniteScroll";
import Dropdown from "components/Dropdown";
import AddPrizeModal from "components/AddPrizeModal";
import SearchBox from "components/SearchBox";
import { SectionFields } from "components/Elements";
import { categoryList } from "utils/config";
import PrivilegedComponent from "components/PrivilegedComponent";
import { PRIVILEGES } from "utils/constants";
const bem = bn.create("promotion-contest-prizes");
const getUnitValue = (prize) => {
  if (prize.category === "variable_cash") {
    return (
      <strong>
        <FormattedMessage id="prizes > variable" />
      </strong>
    );
  }
  return prize.unit_value ? (
    <strong>${prize.unit_value}</strong>
  ) : (
    <FormattedMessage id="prizes > none" />
  );
};
const getAvailable = (prize, selectedStation) => {
  if (prize.category === "variable_cash") {
    return prize.available || prize.total_cash;
  }
  const isShowItemizedPrize =
    get(prize, "itemize") && prize.allocation === "assigned" ? true : false;

  if (isShowItemizedPrize) {
    const stationItemizedQuantity = find(
      prize.stations_itemized_quantity,
      (item) => item.station === selectedStation.value
    );
    if (!stationItemizedQuantity) return "";
    return sum(
      map(stationItemizedQuantity.allotment, (i) => Number(i.available || 0))
    );
  }
  const isShowNoneItemized =
    !get(prize, "itemize") && prize.allocation === "assigned" ? true : false;
  if (isShowNoneItemized) {
    const stationsQuantity = find(
      prize.stations_quantity,
      (item) => item.station === selectedStation.key
    );
    return get(stationsQuantity, "available", "");
  }
  return prize.available || prize.quantity;
};
const getPrizeType = (prizeCategory) => {
  let sortName;
  let name;
  let className;
  if (prizeCategory === "cash_check") {
    sortName = "$";
    name = "Cash";
    className = bem.e("prize-cash");
  } else if (prizeCategory === "tickets") {
    sortName = "TX";
    name = "Tickets";
    className = bem.e("prize-tickets");
  } else if (prizeCategory === "prize") {
    sortName = "P";
    name = "Prize";
    className = bem.e("prize-prize");
  } else if (prizeCategory === "station_merchandise") {
    sortName = "M";
    name = "Merch";
    className = bem.e("prize-merch");
  } else if (prizeCategory === "variable_cash") {
    sortName = "V";
    name = "Variable";
    className = bem.e("prize-variable");
  }
  return (
    <div className={classnames(bem.e("prize-type"), className)}>
      <span className={bem.e("prize-sort-name")}>{sortName}</span>
      <span className={bem.e("prize-name")}>{name}</span>
    </div>
  );
};

export const titleDisplay = (process) => {
  if (get(process, "title")) {
    return process.title;
  }
  return <FormattedMessage id="process > title of spot" />;
};
const View = ({
  onSort,
  sorted,
  searchData,
  isLoading,
  getMoreItems,
  hasMore,
  onSelectItem,
  intl,
  searchText,
  setSearchText,
  onSearch,
  selectedStation,
  typeId,
  setTypeId,
  getItemsList,
  isView,
  onSelectPrize,
  is54321Theme,
  isRolloverTheme,
}) => {
  const [isOpenAddItemModal, setIsOpenAddItemModal] = useState(false);
  const [isExpandedMap, setIsExpandedMap] = useState({});
  const items = selectedStation ? get(searchData, "data", []) : [];

  const onDelaySearch = debounce(onSearch, 200);

  const renderEmpty = () => {
    return <NoItemsFound />;
  };

  const togglePrizeExpand = (prizeKey) => {
    const newExpandedMap = { ...isExpandedMap };
    newExpandedMap[prizeKey] = !newExpandedMap[prizeKey];
    setIsExpandedMap({ ...newExpandedMap });
  };

  const handlePrizeClick = (processData, itemPrize) => {
    if (is54321Theme) {
      // Note: this is nested if condition as we do not want to execute it if theme is 54321 but itemPrize is null
      if (itemPrize) {
        onSelectPrize(processData, itemPrize);
      }
    } else {
      onSelectPrize(processData);
    }
    if (document.getElementById("cr-app-main"))
      document.getElementById("cr-app-main").scrollTo(0, 0);
  };

  useInfiniteScroll({
    useWindow: false,
    elementId: "prize-items-scroll",
    onLoadMore: getMoreItems,
    shouldLoadMore: hasMore,
  });
  return (
    <div className={classnames(bem.e("prize-table"), "cr-list-table")}>
      <div className={bem.e("prize-head")}>
        <h2 className={bem.e("title")}>
          <FormattedMessage id="process > promotion > contest > title prize closet list" />
        </h2>
        {!isView && (
          <PrivilegedComponent requires={[PRIVILEGES.PRIZE_CLOSET_EDIT]}>
            <div className={bem.e("buttons")}>
              <Button
                onClick={() => {
                  if (!selectedStation || isView) return;
                  setIsOpenAddItemModal(true);
                }}
                color="blue"
                outline
                disabled={!selectedStation}
                className={"btn-radius btn btn-add"}
              >
                <FaPlus />
                <FormattedMessage id="process > promotion > contest > add prize to closet" />
              </Button>
            </div>
          </PrivilegedComponent>
        )}
      </div>
      <SectionFields
        className={classnames(
          bem.e("section-fields"),
          bem.e("contest-prize-fields")
        )}
      >
        <Row>
          <Col xs={6} className={bem.e("reset-col")}>
            {!isRolloverTheme && (
              <Dropdown
                name="type_id"
                placeholder={intl.formatMessage({
                  id: "process > promotion > contest > view by type",
                })}
                isClearable
                onChange={(selectedOption) => {
                  const typeId = get(selectedOption, "value", null);
                  setTypeId(typeId);
                  getItemsList({
                    category: typeId ? typeId : "",
                  });
                }}
                value={find(categoryList, (item) => item.value === typeId)}
                options={categoryList}
              />
            )}
          </Col>
          <Col xs={6} className={bem.e("reset-col")}>
            <SearchBox
              placeholder={intl.formatMessage({
                id:
                  "process > promotion > contest > placeholder search prize input",
              })}
              onSearchClicked={onSearch}
              searchText={searchText}
              setSearchText={(text) => {
                setSearchText(text);
                onDelaySearch(text);
              }}
            />
          </Col>
        </Row>
        <Table className={"table-scroll"}>
          <thead>
            <tr>
              <th
                onClick={() => onSort("category")}
                className={classnames("can-click", "table-first-head")}
              >
                <div className={"item-sortable"}>
                  <FormattedMessage id="process > Type" />
                  <div className={"sortable"}>
                    <FaSortAsc
                      className={classnames("sortable-icon", {
                        "sortable-active":
                          get(sorted, "id") === "category" &&
                          get(sorted, "desc"),
                      })}
                    />
                    <FaSortDesc
                      className={classnames("sortable-icon", {
                        "sortable-active":
                          get(sorted, "id") === "category" &&
                          !get(sorted, "desc"),
                      })}
                    />
                  </div>
                </div>
              </th>

              <th onClick={() => onSort("title")} className="can-click title">
                <div className={"item-sortable"}>
                  <FormattedMessage id="process > Title" />
                  <div className={"sortable"}>
                    <FaSortAsc
                      className={classnames("sortable-icon", {
                        "sortable-active":
                          get(sorted, "id") === "title" && !get(sorted, "desc"),
                      })}
                    />
                    <FaSortDesc
                      className={classnames("sortable-icon", {
                        "sortable-active":
                          get(sorted, "id") === "title" && get(sorted, "desc"),
                      })}
                    />
                  </div>
                </div>
              </th>
              <th className="text-center">
                <FormattedMessage id="process > promotion > contest > available" />
              </th>
              <th className="text-center">
                <FormattedMessage id="process > promotion > contest > on hold" />
              </th>
              <th className="text-center">
                <FormattedMessage id="process > promotion > contest > unit value" />
              </th>
            </tr>
          </thead>
          <tbody className="scroll-bar-style" id="prize-items-scroll">
            {items.length === 0 && !isLoading ? (
              <tr>
                <td colSpan="6">{renderEmpty()}</td>
              </tr>
            ) : (
              items.map((item, index1) => {
                const processKey = item._id;
                const processData = {
                  ...item._source,
                  key: processKey,
                };
                const isExpended = isExpandedMap[processKey];
                return (
                  <>
                    <tr
                      key={`tr-${processKey}-${index1}`}
                      className="list-liner-item"
                      onClick={() => handlePrizeClick(processData)}
                    >
                      {/* type */}
                      <td
                        className={classnames(
                          "align-middle align-middle first-col"
                        )}
                      >
                        <span className={"td-value"}>
                          {getPrizeType(processData.category)}
                        </span>
                      </td>
                      {/* name */}
                      <td className={classnames("align-middle title")}>
                        <span className={"td-value"}>
                          {titleDisplay(processData)}
                        </span>
                        {processData.subtitle && (
                          <span className={"sub-value"}>
                            {processData.subtitle}
                          </span>
                        )}
                      </td>
                      {/* available */}
                      <td className={"align-middle text-center"}>
                        <span className={"td-value"}>
                          {getAvailable(processData, selectedStation)}
                        </span>
                      </td>
                      {/* on hold */}
                      <td className="align-middle text-center">
                        <span className={"td-value"}>
                          {processData.on_hold_quantity || 0}
                        </span>
                      </td>
                      {/* unit value */}
                      <td className="align-middle text-center">
                        {is54321Theme ? (
                          <span className={"td-value"}>
                            <Button
                              onClick={() => togglePrizeExpand(processKey)}
                              color="blue"
                              outline
                              className={"btn-radius btn btn-add"}
                            >
                              <FormattedMessage
                                id={`process > promotion > contest > ${
                                  isExpended ? "collapse" : "expand"
                                }`}
                              />
                            </Button>
                          </span>
                        ) : (
                          <span className={"td-value"}>
                            {getUnitValue(processData)}
                          </span>
                        )}
                      </td>
                    </tr>
                    {isExpended
                      ? processData.itemized_prices.map((itemPrize, index) => (
                          <tr
                            key={`${processKey}-${index}`}
                            className="list-liner-item list-liner-prize-item"
                            onClick={() => {
                              handlePrizeClick(processData, {
                                id: index,
                                ...itemPrize,
                              });
                            }}
                          >
                            <td className="first-col"></td>
                            {/* name */}
                            <td className="align-middle title">
                              <span className={"td-value"}>
                                {itemPrize.description}
                              </span>
                            </td>
                            {/* available */}
                            <td className={"align-middle text-center"}>
                              <span className={"td-value"}>
                                {itemPrize.available || itemPrize.quantity}
                              </span>
                            </td>
                            {/* on hold */}
                            <td className="align-middle text-center">
                              <span className={"td-value"}>
                                {itemPrize.on_hold_quantity || 0}
                              </span>
                            </td>
                            {/* unit value */}
                            <td className="align-middle text-center">
                              <span className={"td-value"}>
                                ${itemPrize.value || 0}
                              </span>
                            </td>
                          </tr>
                        ))
                      : null}
                  </>
                );
              })
            )}
          </tbody>
        </Table>
        <AddPrizeModal
          isSplitForm
          isOpen={isOpenAddItemModal}
          initialStations={selectedStation ? [selectedStation.value] : []}
          onToggle={() => setIsOpenAddItemModal(!isOpenAddItemModal)}
          onSubmitted={handlePrizeClick}
        />
      </SectionFields>
    </div>
  );
};
export default View;
