import React, { useEffect, useState } from "react";
import bn from "utils/bemnames";
import { FormattedMessage } from "react-intl";
import { Button, Label } from "reactstrap";
import classnames from "classnames";
import PropTypes from "prop-types";
import classNames from "classnames";
import Checkbox from "components/Checkbox";
import { get, includes, map, orderBy, unionBy, xor } from "lodash";
import { Row, Col } from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import CustomMultiSelectDropdown from "components/CustomMultiSelectDropdown";
import Dropdown from "components/Dropdown";
import AsyncDropdown from "components/AsyncDropdown";
import { fetchClients } from "store/actions/process";
const bem = bn.create("processes-filter-view");
const HEADER_HEIGHT = 95;
const FOOTER_HEIGHT = 92;

export const statusConfig = orderBy(
  [
    {
      label: <FormattedMessage id="process > title assignments" />,
      value: "assign_team",
      sort: "assignments",
    },
    {
      label: <FormattedMessage id="process > title script" />,
      value: "write_script",
      sort: "script",
    },
    {
      label: <FormattedMessage id="process > title script change" />,
      value: "script_change",
      sort: "script change",
    },
    {
      label: <FormattedMessage id="process > title script review" />,
      value: "approve_script",
      sort: "script review",
    },
    {
      label: <FormattedMessage id="process > title assign vo" />,
      value: "assign_vo",
      sort: "assign VO",
    },
    {
      label: <FormattedMessage id="process > title voice over" />,
      value: "voice_over",
      sort: "voice over",
    },
    {
      label: <FormattedMessage id="process > title record" />,
      value: "record_script",
      sort: "record",
    },
    {
      label: <FormattedMessage id="process > title spot change" />,
      value: "spot_change",
      sort: "spot change",
    },
    {
      label: <FormattedMessage id="process > title spot review" />,
      value: "approve_recording",
      sort: "spot review",
    },
    {
      label: <FormattedMessage id="process > title client review" />,
      value: "client_review",
      sort: "client review",
    },
    {
      label: <FormattedMessage id="process > title continuity" />,
      value: "continuity",
      sort: "continuity",
    },
    {
      label: <FormattedMessage id="process > title change request" />,
      value: "change_request",
      sort: "change request",
    },
    {
      label: <FormattedMessage id="process > title dubbing" />,
      value: "dub_script",
      sort: "dubbing",
    },
    // {
    //   label: <FormattedMessage id="process > title draft" />,
    //   value: "draft",
    //   sort: "draft",
    // },
  ],
  ["sort"]
);
export const spotTypeConfig = [
  {
    label: <FormattedMessage id="process > bookend" />,
    value: "bookend",
  },
  {
    label: <FormattedMessage id="process > coop" />,
    value: "coop",
  },
  {
    label: <FormattedMessage id="process > sold spot" />,
    value: "sold",
  },

  {
    label: <FormattedMessage id="process > spec spot" />,
    value: "spec",
  },
];
export const deadlineConfig = [
  {
    label: <FormattedMessage id="process > overdue" />,
    value: "overdue",
  },
  {
    label: <FormattedMessage id="process > due tomorrow" />,
    value: "due_tomorrow",
  },
  {
    label: <FormattedMessage id="process > due today" />,
    value: "due_today",
  },

  {
    label: <FormattedMessage id="process > due this week" />,
    value: "due_this_week",
  },
];
function ContentProductionQueueView(props) {
  const { initialValues, intl, onSubmit } = props;
  const [contentHeight, setContentHeight] = useState(0);
  const [optionStations, setOptionStations] = useState([]);

  const fetchClientsOptions = async (q) => {
    const clients = await fetchClients({
      q,
      sort: "client_name asc",
      production_queue: true,
      rpp: 1000,
    });
    const options = get(clients, "items", []).map((client) => {
      return {
        label: client._source.client_name,
        value: client._source.client_id,
      };
    });
    return unionBy(options, "label");
  };

  useEffect(() => {
    const sidebar = document.getElementById("right-sidebar");
    if (sidebar) {
      setContentHeight(sidebar.offsetHeight - HEADER_HEIGHT - FOOTER_HEIGHT);
    }
  }, []);
  useEffect(() => {
    const newAllStations = map(props.stations, (item) => {
      return {
        value: item.station_key,
        label: item.call_letters,
        data: {
          firstLabel: item.call_letters,
          lastLabel: item.name,
        },
      };
    });
    const options = orderBy(newAllStations, ["label"], ["asc"]);
    setOptionStations(options);
  }, []);

  return (
    <div className={bem.b()}>
      <Formik
        enableReinitialize
        initialValues={{
          stations:
            initialValues.stations && initialValues.stations.length === 0
              ? optionStations
              : initialValues.stations,
          client_id: initialValues.client_id || "",
          client_name: initialValues.client_name || "",
          status: initialValues.status || "",
          spot_types: initialValues.spot_types || [],
          deadline: initialValues.deadline || [],
        }}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={Yup.object().shape({
          stations: Yup.mixed().nullable(),
          status: Yup.string().nullable(),
          client_id: Yup.string().nullable(),
          client_name: Yup.string().nullable(),
          spot_types: Yup.mixed().nullable(),
          deadline: Yup.mixed().nullable(),
        })}
        onSubmit={(values) => {
          onSubmit({
            ...values,
            stations:
              values.stations.length === optionStations.length
                ? []
                : values.stations,
          });
        }}
      >
        {(formProps) => {
          const { touched, errors, values } = formProps;
          return (
            <>
              <div
                className={classnames(
                  bem.e("filter-view-content-2"),
                  "scroll-bar-style"
                )}
                style={{
                  height: contentHeight,
                }}
              >
                <div>
                  {/* stations */}
                  <div className={bem.e("dropdown")}>
                    <Label>
                      <FormattedMessage id="process > entity" />
                    </Label>
                    <CustomMultiSelectDropdown
                      name="stations"
                      value={values.stations}
                      options={optionStations}
                      placeholder={intl.formatMessage({
                        id: "process > please select",
                      })}
                      isAllowAllOption={true}
                      allOption={{
                        label: intl.formatMessage({
                          id: "process > all stations",
                        }),
                        value: "*",
                      }}
                      isStation
                      isMulti={true}
                      error={null}
                      onChange={(value) => {
                        formProps.setFieldValue("stations", value);
                      }}
                      multiText={`${
                        values.stations.length
                      } ${intl.formatMessage({
                        id: "process > stations",
                      })}`}
                    />
                  </div>
                  <AsyncDropdown
                    name="client_id"
                    loadOptions={fetchClientsOptions}
                    label={<FormattedMessage id="process > client" />}
                    placeholder={intl.formatMessage({
                      id: "process > please select",
                    })}
                    value={
                      values.client_id
                        ? {
                            label: values.client_name,
                            value: values.client_id,
                          }
                        : ""
                    }
                    onChange={(selectedOption) => {
                      formProps.setFieldValue(
                        "client_id",
                        get(selectedOption, "value", "")
                      );
                      formProps.setFieldValue(
                        "client_name",
                        get(selectedOption, "label", "")
                      );
                    }}
                    containerClass={bem.e("dropdown")}
                    defaultOptions={true}
                    error={touched.client_id && errors.client_id}
                  />
                  <div className={bem.e("dropdown")}>
                    <Label>
                      <FormattedMessage id="process > status" />
                    </Label>
                    <Dropdown
                      name="status"
                      value={
                        statusConfig.find(
                          (item) => item.value === values.status
                        ) || ""
                      }
                      options={statusConfig}
                      placeholder={intl.formatMessage({
                        id: "process > please select",
                      })}
                      error={null}
                      onChange={(option) => {
                        formProps.setFieldValue(
                          "status",
                          get(option, "value", "")
                        );
                      }}
                    />
                  </div>
                  {/* deadline risk */}
                  <div className={bem.e("deadline-risk")}>
                    <Label>
                      <FormattedMessage id="process > deadline risk" />
                    </Label>
                    <Row>
                      {map(deadlineConfig, (item, index) => (
                        <Col key={index} xs={6}>
                          <Checkbox
                            checked={includes(values.deadline, item.value)}
                            onChange={() => {
                              formProps.setFieldValue(
                                "deadline",
                                xor(values.deadline, [item.value])
                              );
                            }}
                            text={item.label}
                          />
                        </Col>
                      ))}
                    </Row>
                  </div>
                  {/* spot types */}
                  <div className={bem.e("spot-types")}>
                    <Label>
                      <FormattedMessage id="process > spot type" />
                    </Label>
                    <Row>
                      {map(spotTypeConfig, (item, index) => (
                        <Col key={index} xs={6}>
                          <Checkbox
                            checked={includes(values.spot_types, item.value)}
                            onChange={() => {
                              formProps.setFieldValue(
                                "spot_types",
                                xor(values.spot_types, [item.value])
                              );
                            }}
                            text={item.label}
                          />
                        </Col>
                      ))}
                    </Row>
                  </div>
                </div>
              </div>
              <div className={bem.e("production-buttons")}>
                <Button
                  color="blue"
                  className={classNames("btn-radius", bem.e("btn-apply"))}
                  type="submit"
                  onClick={formProps.handleSubmit}
                >
                  <FormattedMessage id="process > apply" />
                </Button>
                <Button
                  color="link"
                  className={classNames("btn-radius", bem.e("btn-clear"))}
                  onClick={() => {
                    formProps.resetForm({
                      stations: [],
                      client_id: "",
                      status: [],
                      spot_types: [],
                      deadline: [],
                    });
                  }}
                >
                  <FormattedMessage id="process > clear" />
                </Button>
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
}
ContentProductionQueueView.defaultProps = {
  initialValues: {
    stations: [],
    client_id: "",
    status: [],
    spot_types: [],
    deadline: [],
  },
};
ContentProductionQueueView.propTypes = {
  initialValues: PropTypes.object,
};

export default ContentProductionQueueView;
