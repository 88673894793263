import { get, forEach, first, find, filter, last, orderBy } from "lodash";
import { colorPickerColors } from "utils/constants";

export const getColor = (availableColor = "primary") => {
  if (typeof window === "undefined") {
    return null;
  }

  const color = window
    .getComputedStyle(document.documentElement)
    .getPropertyValue(`--${availableColor}`);

  return color;
};

export const getThemeColors = () => [
  "primary",
  "secondary",
  "success",
  "info",
  "warning",
  "danger",
];

export function getRandomColor(slots) {
  let usedColors = [];
  const sortedSlots = orderBy(slots, "id", "asc");
  forEach(sortedSlots, (item, index) => {
    if (item.color && !item.deleted) {
      usedColors = [
        ...usedColors,
        {
          color: item.color,
          order_color: get(item, "order_color", index),
        },
      ];
    }
  });
  const lastUsedColor = last(usedColors);
  const colors = filter(
    colorPickerColors,
    (color) => !find(usedColors, (item) => item.color === color)
  );
  const random = Math.floor(Math.random() * colors.length);
  const color = colors[random];
  // Once all colors have been supplied (regardless of if user changes them, it does not affect this) we start over and go in the same order.
  if (!color && lastUsedColor) {
    // when go to last order -> pick to 0
    if (
      lastUsedColor &&
      lastUsedColor.order_color === colorPickerColors.length - 1
    ) {
      return {
        color: first(usedColors).color,
        order_color: 0,
      };
    } else {
      const nextColor = find(
        usedColors,
        (item) => item.order_color === lastUsedColor.order_color + 1
      );
      return nextColor;
    }
  }
  return {
    color,
    order_color: lastUsedColor ? lastUsedColor.order_color + 1 : 0,
  };
}
