import React, { useMemo } from "react";
import { Button, Modal, ModalBody, ModalHeader, Form } from "reactstrap";
import classnames from "classnames";
import moment from "moment";
import { get, first, find, split, forEach } from "lodash";
import Avatar from "components/Avatar";
import Textarea from "react-textarea-autosize";
import { FormattedMessage } from "react-intl";
import { CloseIcon } from "components/CustomIcons";
import { Formik } from "formik";
import * as Yup from "yup";
import bn from "utils/bemnames";
import { formatDisplayDate, validateEmail } from "utils/helpers";
import Spinner from "components/Spinner";
import ToastManager from "components/ToastManager";

const bem = bn.create("send-mail-dialog");

const SendMailDialog = (props) => {
  const {
    subject,
    cc,
    process_id,
    isOpenDialog,
    toggleOpenDialog,
    onFormSubmit,
    isLoading,
    color,
    user,
    userInfo,
    disableButton,
    process,
    isSpotReview,
    dateFormatByServer,
    outline,
    isSaveOrder,
    validate,
    step,
    template,
    producerSendsSpotToClient,
    contacts,
  } = props;
  const formikRef = React.createRef();
  const isEnableEnterprise = get(userInfo, "production_multimarket", false);
  const isDigital =
    get(process, "data.fields.template_key.value") === "digital";
  const contractClient = get(
    process,
    "data.fields.contract_client_contact.value",
    ""
  );

  const nickName = get(process, "data.fields.contract_client_nickname.value")
    ? get(process, "data.fields.contract_client_nickname.value")
    : first(contractClient.split(" "));
  const clientName = get(process, "data.fields.client_name.value", "");
  const orderTitle = get(process, "data.fields.order_title.value", "");
  const deadline = get(process, "data.fields.order_start_date.value")
    ? moment(get(process, "data.fields.order_start_date.value")).format(
        "MMMM DD"
      )
    : "";
  const broadcastingCompany = get(userInfo, "server_name", "");

  let message = "";

  if (isDigital) {
    message = `The web banners for Promosuite are ready for your review! Please click the link below to either approve the creative or request changes.`;
  } else {
    if (isSpotReview) {
      if (isEnableEnterprise) {
        message = `The spot for Promosuite is ready for your review! Please click the link below to either approve the recording or request changes.`;
      } else {
        message = `The radio commercial for ${clientName}, ${orderTitle}, is ready for you to review. Please click on the link below to hear the spot. You can approve the spot as is or provide a comment and request changes.`;
      }
    } else if (producerSendsSpotToClient) {
      message = `The spot for ${clientName} is ready for your review! Please click the link below to either approve the spot or request changes.`;
    } else {
      message = `The script for ${clientName} is ready for your review! Please click the link below to either approve the script or request changes.`;
    }
  }

  const stations = get(process, "data.fields.order_stations.value", []).map(
    (item) => {
      const station = find(userInfo.stations, (i) => i.station_key === item);
      return station ? station.name : item;
    }
  );
  const flightDatesDisplay = (process) => {
    const startDate = get(process, "data.fields.order_start_date.value");
    const endDate = get(process, "data.fields.order_end_date.value");

    if (!endDate || !startDate) return null;
    return (
      <div className={bem.e("flight-dates-list")}>
        <span>
          {startDate ? formatDisplayDate(startDate, dateFormatByServer) : ""} -{" "}
          {endDate ? formatDisplayDate(endDate, dateFormatByServer) : ""}
        </span>
      </div>
    );
  };
  const flightDates = flightDatesDisplay(process);

  const renderButtonText = () => {
    if (isDigital) {
      return <FormattedMessage id="send mail dialog > review creative" />;
    } else {
      if (isSpotReview || producerSendsSpotToClient) {
        return <FormattedMessage id="send mail dialog > review spot" />;
      } else {
        return <FormattedMessage id="send mail dialog > review script" />;
      }
    }
  };

  const onClick = async () => {
    if (disableButton) return;
    // for writer or producer sends to client -> validate before open the modal
    if (isSaveOrder) {
      let valid = false;
      try {
        valid = await validate(
          step,
          step.fields.find((f) => f.key === "save_button"),
          process,
          template,
          user
        );
      } catch (e) {
        valid = false;
        ToastManager.show({
          title: props.intl.formatMessage({
            id: "toast > title not saved",
          }),
          message: e.message,
          level: "error",
        });
      }
      if (valid) {
        toggleOpenDialog();
      }
    } else {
      toggleOpenDialog();
    }
  };

  const to = useMemo(() => {
    if (contacts.length === 0) {
      return get(process, "data.loaded_fields.contract_client_email.value", "");
    }
    return contacts
      .map((item) => item.contract_client_email || "")
      .filter(Boolean)
      .join(",");
  }, [process, contacts]);

  return (
    <div className={bem.b()}>
      <Button
        color={color || "blue"}
        onClick={onClick}
        outline={outline}
        className={classnames(
          "btn-radius",
          bem.e("btn-send-mail"),
          props.buttonClassName
        )}
        disabled={disableButton}
      >
        <FormattedMessage id="send mail dialog > send to client" />
      </Button>
      <Modal
        isOpen={isOpenDialog}
        className={classnames(bem.e("modal-mail"), {
          [bem.e("modal-production")]: !isDigital,
          [bem.e("modal-digital")]: isDigital,
        })}
      >
        <ModalHeader toggle={toggleOpenDialog} className={bem.e("header")}>
          <span className={bem.e("close")} onClick={toggleOpenDialog}>
            <CloseIcon color="#657894" />
          </span>
        </ModalHeader>
        <ModalBody className={bem.e("body")}>
          <Formik
            enableReinitialize
            ref={formikRef}
            initialValues={{
              subject: subject || "",
              to: to || "",
              cc: cc || "",
              message: message || "",
              link: get(process, "data.fields.email_link.value", "#") || "",
              process_id: process_id || "",
            }}
            validationSchema={Yup.object().shape({
              subject: Yup.string().required(
                props.intl.formatMessage({
                  id: "validate > subject is required",
                })
              ),
              message: Yup.string().required(
                props.intl.formatMessage({
                  id: "validate > message is required",
                })
              ),
              to: Yup.string()
                .required(
                  props.intl.formatMessage({
                    id: "validate > to is required",
                  })
                )
                .test(
                  "emailComma",
                  props.intl.formatMessage({
                    id: "validate > invalid email",
                  }),
                  (value) => {
                    const emails = split(value, ",");
                    let isValid = true;
                    forEach(emails, (email) => {
                      if (!validateEmail(email)) {
                        isValid = false;
                      }
                    });
                    return isValid;
                  }
                ),
              cc: Yup.string()
                .test(
                  "emailComma",
                  props.intl.formatMessage({
                    id: "validate > invalid email",
                  }),
                  (value) => {
                    if (!value) return true;
                    const emails = split(value, ",");
                    let isValid = true;
                    forEach(emails, (email) => {
                      if (!validateEmail(email)) {
                        isValid = false;
                      }
                    });
                    return isValid;
                  }
                )
                .nullable(),
            })}
            onSubmit={onFormSubmit}
          >
            {(formProps) => {
              const errors = formProps.errors;
              const touched = formProps.touched;
              const values = formProps.values;
              return (
                <Form onSubmit={formProps.handleSubmit}>
                  <div className={bem.e("header-information")}>
                    <div className={bem.e("form-control")}>
                      <label htmlFor="client-to" className={bem.e("label")}>
                        <FormattedMessage id="send mail dialog > to" />:
                      </label>
                      <textarea
                        type="text"
                        name="to"
                        id="client-to"
                        rows={1}
                        value={values.to}
                        className={classnames(bem.e("input"), {
                          [bem.e("error")]: touched.to && errors.to,
                        })}
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                      />
                    </div>
                    <div className={bem.e("form-control")}>
                      <label htmlFor="client-cc" className={bem.e("label")}>
                        <FormattedMessage id="send mail dialog > cc" />:
                      </label>
                      <input
                        type="text"
                        name="cc"
                        id="client-cc"
                        value={values.cc}
                        className={classnames(bem.e("input"), {
                          [bem.e("error")]: touched.cc && errors.cc,
                        })}
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                      />
                    </div>
                    <div className={bem.e("form-control")}>
                      <label
                        htmlFor="client-subject"
                        className={bem.e("label")}
                      >
                        <FormattedMessage id="send mail dialog > subject" />:
                      </label>
                      <input
                        type="text"
                        name="subject"
                        id="client-subject"
                        value={values.subject}
                        className={classnames(bem.e("input"), {
                          [bem.e("error")]: touched.subject && errors.subject,
                        })}
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                      />
                    </div>
                  </div>
                  <div className={bem.e("email-editor")}>
                    <p className={bem.e("welcome")}>Hello {nickName},</p>
                    <Textarea
                      name="message"
                      id="client-message"
                      value={values.message}
                      className={classnames(bem.e("message"), {
                        [bem.e("error")]: touched.message && errors.message,
                      })}
                      placeholder={"Message"}
                      onChange={formProps.handleChange}
                      onBlur={formProps.handleBlur}
                      maxRows={10}
                    />
                    <div className={bem.e("box-action")}>
                      <h3 className={bem.e("box-sub-title")}>{orderTitle}</h3>
                      <div className={bem.e("box-action-sub-title")}>
                        <strong>
                          {isDigital ? (
                            <FormattedMessage id="send mail dialog > stations" />
                          ) : (
                            <FormattedMessage id="send mail dialog > stations" />
                          )}
                          :
                        </strong>
                        <span>{stations.join(", ")}</span>
                      </div>
                      {flightDates && (
                        <div
                          className={classnames(
                            bem.e("box-action-sub-title"),
                            bem.e("flight-dates")
                          )}
                        >
                          <strong>
                            <FormattedMessage id="send mail dialog > flight dates" />
                            :{" "}
                          </strong>
                          {flightDates}
                        </div>
                      )}

                      <span className={bem.e("deadline")}>
                        <FormattedMessage id="send mail dialog > deadline" />:{" "}
                        {deadline}
                      </span>
                      <a
                        href={get(process, "data.fields.email_link.value", "#")}
                        className={classnames(
                          "btn btn-blue",
                          bem.e("email-button")
                        )}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {renderButtonText()}
                      </a>
                    </div>
                    <div className={bem.e("thank-you")}>
                      <FormattedMessage id="send mail dialog > thank you" />
                    </div>
                  </div>
                  <div className={bem.e("section-user")}>
                    <div className={bem.e("user")}>
                      <div className={bem.e("user-avatar")}>
                        <Avatar
                          src={get(user, "avatar_url", "")}
                          name={get(user, "name", "")}
                          size={60}
                        />
                      </div>
                      <div className={bem.e("user-info")}>
                        <span>{get(user, "name", "")}</span>
                        {user.title ? <span>{user.title}</span> : null}
                        <span>{broadcastingCompany}</span>
                      </div>
                    </div>
                  </div>
                  <div className={bem.e("buttons")}>
                    <Button
                      color="blue"
                      type="submit"
                      disabled={isLoading}
                      outline
                      className={classnames({
                        [bem.e("button-send")]: !isDigital,
                        [bem.e("button-send-digital")]: isDigital,
                      })}
                      onClick={() => {
                        setTimeout(() => {
                          const formErrors = get(
                            formikRef,
                            "current.state.errors"
                          );
                          if (formErrors && Object.keys(formErrors).length) {
                            ToastManager.show({
                              message: props.intl.formatMessage({
                                id:
                                  "toast > message error send multiple emails",
                              }),
                              level: "error",
                            });
                          }
                        }, 40);
                      }}
                    >
                      <FormattedMessage id="send mail dialog > submit" />
                    </Button>
                  </div>
                  <input
                    type="hidden"
                    name="process_id"
                    value={values.process_id}
                    onChange={formProps.handleChange}
                    onBlur={formProps.handleBlur}
                  />
                </Form>
              );
            }}
          </Formik>
        </ModalBody>
        <Spinner isLoading={isLoading} />
      </Modal>
    </div>
  );
};
SendMailDialog.defaultProps = {
  subject: "",
  message: "",
  to: "",
  cc: "",
};
export default SendMailDialog;
