import * as Types from "../types/chat";
import { LOGOUT_USER} from "../types/auth";
import get from "lodash/get";
import merge from "lodash/merge";

const initialState = {
  instances: {
    // 	<instance_id>: {
    // 		id: instance_id,
    // 		name: '',
    // 		messages: [{
    // 			id: '',
    // 			text: '',
    // 			mentions: [],
    // 			date: <date>,
    // 			position: "right|left",
    // 		}]
    // 	}
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case Types.ADD_CHAT_BOX: {
      const { instanceId, instanceName } = action.payload;
      return {
        ...state,
        instances: {
          ...state.instances,
          [instanceId]: {
            id: instanceId,
            name: instanceName,
            messages: []
          }
        }
      };
    }
    case Types.ADD_CHAT_MESSAGE: {
      const { instanceId, message } = action.payload;
      const instance = get(state, `instances.${instanceId}`, {});
      const messages = get(instance, `messages`, []);
      messages.push({
        id: "" + Math.floor(Math.random() * 90000) + 10000,
        text: message,
        position: "right",
        type: "text",
        date: new Date()
      });
      return merge(state, {
        instances: {
          [instanceId]: {
            messages
          }
        }
      });
    }
    case Types.SEND_CHAT_MESSAGE: {
      const { instanceId, message, mentions } = action.payload;
      const instance = get(state, `instances.${instanceId}`, {});
      const messages = get(instance, `messages`, []);
      messages.push({
        id: "" + Math.floor(Math.random() * 90000) + 10000,
        text: message,
        mentions,
        position: "right",
        type: "text",
        date: new Date()
      });
      return merge(state, {
        instances: {
          [instanceId]: {
            messages
          }
        }
      });
    }
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
}
