import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import View from "./view";
import { injectIntl } from "react-intl";
const Enhanced = connect(
  () => ({}),
  { }
)(View);

export default withRouter(injectIntl(Enhanced));
