import React from "react";
import bn from "utils/bemnames";
import { get } from "lodash";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import Spinner from "components/Spinner";
import PreviewFileModal from "components/PreviewFileModal";
import truncate from "truncate";
import { checkIsSupportViewer } from "utils/helpers";
import {
  DownloadFileIcon,
  ViewIcon,
  EditIcon,
  TrashSmallIcon,
} from "components/CustomIcons";
const bem = bn.create("file-view");
const MAX_FILE_NAME_LENGTH = 30;

const FileView = ({
  background,
  isLoading,
  file,
  onDeleteFile,
  onRenameFile,
  isRename,
  error,
  onReplace,
  disabled,
  actions,
  onDownloadFile,
  onViewFile,
  isOpenPreviewFileModal,
  onTogglePreviewModal,
  onCancelPreviewModal,
  onUploaded,
  isOverrideViewAction,
  onClickViewAction,
}) => {
  if (!file) return null;
  const isSupportViewer =
    isOverrideViewAction && onClickViewAction
      ? true
      : checkIsSupportViewer(file);
  return (
    <div className={bem.b()}>
      <div className={bem.e("file-item")}>
        <span className={bem.e("file-name")}>
          {truncate(get(file, "name"), MAX_FILE_NAME_LENGTH)}
        </span>
        <div className={bem.e("file-buttons")}>
          {actions.includes("view") && isSupportViewer && (
            <Button
              className={bem.e("file-view")}
              disabled={disabled}
              onClick={() => {
                if (isOverrideViewAction && onClickViewAction) {
                  onClickViewAction();
                } else {
                  onViewFile();
                }
              }}
            >
              <ViewIcon />
            </Button>
          )}
          {actions.includes("edit") && (
            <Button
              className={bem.e("file-edit")}
              disabled={disabled}
              onClick={onReplace}
            >
              <EditIcon size={20} />
            </Button>
          )}
          {actions.includes("rename") && (
            <Button
              className={bem.e("file-edit")}
              disabled={disabled}
              onClick={onRenameFile}
            >
              <EditIcon size={20} />
            </Button>
          )}
          {actions.includes("download") && (
            <Button
              className={bem.e("file-download")}
              disabled={disabled}
              onClick={onDownloadFile}
            >
              <DownloadFileIcon />
            </Button>
          )}
          {actions.includes("trash") && (
            <Button
              className={bem.e("file-trash")}
              disabled={disabled}
              onClick={onDeleteFile}
            >
              <TrashSmallIcon />
            </Button>
          )}
        </div>
      </div>
      {error && <div className="alert-danger">{error}</div>}
      <Spinner isLoading={isLoading} />
      <PreviewFileModal
        isOpen={isOpenPreviewFileModal}
        onToggle={onTogglePreviewModal}
        onCancel={onCancelPreviewModal}
        file={file}
        files={[file]}
        isEdit={isRename}
        background={background}
        onUploadedFiles={onUploaded}
      />
    </div>
  );
};
FileView.defaultProps = {
  background: false,
  disabled: false,
  actions: ["edit", "trash", "view", "download", "rename"],
  isOverrideViewAction: false,
  onClickViewAction: () => {},
};
FileView.propTypes = {
  file: PropTypes.object.isRequired,
  onDeleted: PropTypes.func,
  background: PropTypes.bool,
  disabled: PropTypes.bool,
  isOverrideViewAction: PropTypes.bool,
  onClickViewAction: PropTypes.func,
};
export default FileView;
