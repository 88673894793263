import View from "./view";
import { injectIntl } from "react-intl";
import { filter, get } from "lodash";
import { compose, withState, lifecycle, withHandlers } from "recompose";
import { connect } from 'react-redux';
import { PRIVILEGES } from "utils/constants";
import { validatePrivileges } from "utils/helpers";
export default injectIntl(
	compose(
        connect(
            state => ({
              users: get(state, "auth.info.users", []),
              privileges: get(state, "auth.user.privileges", []),
							auth: state.auth
            }),
            {}
        ),
    withState('isDirty', 'setIsDirty', false),
		withState("isOpenConfirmationDeleteChannel", "setIsOpenConfirmationDeleteChannel", false),
		withState("isOpenFlightSchedule", "setIsOpenFlightSchedule", false),
		withState("isCollapse", "setIsCollapse", (props) => props.isDefaultCollapse),
		withState("formFields", "setFormFields", (props) =>
			filter(get(props, 'channel.fields', []), item => !item.is_controlled)
        ),
        withHandlers({
					getChannelManagers: ({ users }) => () => {
					const managers = filter(users, (user) => {
						const { stations } = user;
						const stationsWithEditChannels = filter(
							stations,
							(station) => {
								const userPrivileges = Object.values(
									station.user_privileges
								);
								return validatePrivileges({
									requires: {
										or: [
											PRIVILEGES.PERFORM_DIGITAL_TRAFFIC,
											PRIVILEGES.EDIT_ALL_DIGITAL_ORDERS
										],
									},
									privileges: userPrivileges,
									user
								});
							}
						);
						return stationsWithEditChannels.length > 0;
					});
					return managers.map((user) => ({
						label: user.name,
						value: user.id,
					}));
				},
        }),
        lifecycle({
            componentDidUpdate(prevProps){
                if(get(this.props, 'channel.id') !== get(prevProps, 'channel.id')){
                    const { channel } = this.props;
                    const formFields = filter(channel.fields, item => !item.is_controlled);
                    this.props.setFormFields(formFields);
                }
            }
        })
	)(View)
);
