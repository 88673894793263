import UserTableManual from "./view";
import { get, map, forEach, findIndex, includes, forOwn } from "lodash";
import { connect } from "react-redux";
import {
  compose,
  lifecycle,
  withState,
  withHandlers,
  withPropsOnChange,
} from "recompose";
import {
  getUsers,
  getMoreUsers,
  deleteUser,
  invitationUsers,
  activeUsers,
  unlockUser,
} from "store/actions/users";
import { impersonateUser } from "store/actions/auth";
import { injectIntl } from "react-intl";
import { pageSize } from "utils/config";
import ToastManager from "components/ToastManager";

export default injectIntl(
  compose(
    connect(
      (state, props) => ({
        isLazyLoading: get(state, "users.lazyLoad", false),
        isLoading: get(state, "users.inviteUsers.loading", false),
        users: get(state, "users.users"),
        auth: get(state, "auth", {}),
        privileges: get(state, "auth.user.privileges") || [],
        locationOptions: get(props, "locationOptions", []),
        isNewUser: get(state, "users.isNewUser", false),
      }),
      {
        getUsers,
        getMoreUsers,
        deleteUser,
        impersonateUser,
        invitationUsers,
        activeUsers,
        unlockUser,
      }
    ),
    // withState("isInviteUserModalOpen", "setIsInviteUserModalOpen", false),
    withState(
      "isBulkUploadUserModalOpen",
      "setIsBulkUploadUserModalOpen",
      false
    ),
    withState("searchText", "setSearchText", ""),
    withState("filtered", "setFiltered", []),
    withState("searchTags", "setSearchTags", []),
    withState("filteredTags", "setFilteredTags", []),
    withState("sorted", "setSorted", []),
    withState("isDeleteModalOpen", "setIsDeleteModalOpen", false),
    withState("isInviteModalOpen", "setIsInviteModalOpen", false),
    withState("isSuccessModalOpen", "setIsSuccessModalOpen", false),
    withState("deleteUserId", "setDeleteUserId", null),
    withState("unlockUserId", "setUnlockUserId", null),
    withState("selectedRowsId", "setSelectedRowsId", {}),
    withState("isUnlockConifrmModalOpen", "setIsUnlockConifrmModalOpen", false),
    withState("currentUserPassword", "setCurrentUserPassword", ""),
    withHandlers({
      openDeleteModal: ({ setIsDeleteModalOpen, setDeleteUserId }) => (id) => {
        setIsDeleteModalOpen(true);
        setDeleteUserId(id);
      },
      closeDeleteModal: ({ setIsDeleteModalOpen, setDeleteUserId }) => () => {
        setIsDeleteModalOpen(false);
        setDeleteUserId(null);
      },
      // toggleInviteUserModal: ({
      //   isInviteUserModalOpen,
      //   setIsInviteUserModalOpen
      // }) => () => setIsInviteUserModalOpen(!isInviteUserModalOpen),
      toggleBulkUploadUserModal: ({
        isBulkUploadUserModalOpen,
        setIsBulkUploadUserModalOpen,
      }) => () => setIsBulkUploadUserModalOpen(!isBulkUploadUserModalOpen),
      getSelectedMarketsByUser: ({ locationOptions }) => (user) => {
        if (!get(user, "tags_string", "")) return [];
        let selectedMarketOptions = [];
        forEach(get(user, "tags_string", "").split("#"), (item) => {
          let existsIndex = findIndex(locationOptions, (selected) =>
            includes(selected.label, item)
          );
          if (existsIndex !== -1) {
            selectedMarketOptions.push({
              value: get(locationOptions, `${existsIndex}.value`),
              label: get(locationOptions, `${existsIndex}.label`),
            });
          }
        });
        return selectedMarketOptions;
      },
    }),
    withHandlers({
      getUsersList: ({
        getUsers,
        sorted,
        filtered,
        filteredTags,
      }) => async () => {
        getUsers({
          pageSize,
          page: 0,
          sorted,
          filtered,
          filteredTags,
        });
      },
      getMoreUsersList: ({
        getMoreUsers,
        sorted,
        filtered,
        filteredTags,
      }) => async (page) => {
        getMoreUsers({
          pageSize,
          page,
          sorted,
          filtered,
          filteredTags,
        });
      },
    }),
    withHandlers({
      onLoadMore: ({ getMoreUsersList, users }) => () => {
        getMoreUsersList(get(users, "data.current_page", 1));
      },
    }),
    withHandlers({
      onActiveUsers: ({ activeUsers }) => (userIds = []) => {
        activeUsers({ ids: userIds });
      },
      onInviteUsers: ({
        selectedRowsId,
        setSelectedRowsId,
        invitationUsers,
        setIsSuccessModalOpen,
        setIsInviteModalOpen,
      }) => () => {
        setIsInviteModalOpen(false);
        let userIds = [];
        forOwn(selectedRowsId, (value, key) => {
          if (value) {
            userIds.push(Number(key));
          }
        });
        invitationUsers({ ids: userIds }, () => {
          setIsSuccessModalOpen(true);
          setSelectedRowsId({});
        });
      },
      onDelete: ({
        closeDeleteModal,
        deleteUserId,
        deleteUser,
        intl,
      }) => () => {
        deleteUser(deleteUserId, () => {
          ToastManager.show({
            title: intl.formatMessage({ id: "user > success" }),
            message: intl.formatMessage({
              id: `user > delete success`,
            }),
            autoDismiss: 1.5,
            level: "success",
          });
        });
        closeDeleteModal();
      },
      onSearchKeyDown: ({ getUsersList }) => (e) => {
        if (e.keyCode === 13) {
          getUsersList();
        }
      },
      onSearchClicked: ({ getUsersList }) => () => {
        getUsersList();
      },
      onChangeInput: ({
        filtered,
        setFiltered,
        setSearchText,
        getUsersList,
      }) => ({ target }) => {
        const value = target.value;
        const name = target.name;
        const filterSearch = {
          id: name,
          value,
        };
        const index = filtered.findIndex((item) => item.id === name);
        if (index === -1) {
          filtered.push(filterSearch);
        } else {
          filtered[index] = filterSearch;
        }
        setSearchText(value);
        setFiltered(filtered);

        if (value === "") getUsersList();
      },
      onChangeTags: (props) => (target) => {
        let { filteredTags, setFilteredTags, setSearchTags } = props;
        filteredTags = target;
        setSearchTags(filteredTags);
        setFilteredTags(map(filteredTags, (item) => item.value));
      },
      onClickUnlock: ({
        intl,
        unlockUser,
        unlockUserId,
        currentUserPassword,
        setIsUnlockConifrmModalOpen,
        setCurrentUserPassword,
      }) => () => {
        const callback = (res) => {
          if (res.status) {
            ToastManager.show({
              title: intl.formatMessage({ id: "user > success" }),
              message: intl.formatMessage({
                id: `user > unlock > success`,
              }),
              autoDismiss: 1.5,
              level: "success",
            });
            setIsUnlockConifrmModalOpen(false);
            setCurrentUserPassword("");
          }
        };

        unlockUser(
          {
            password: currentUserPassword,
            id: unlockUserId,
          },
          (res) => callback(res)
        );
      },
    }),
    withHandlers({
      onClearSearch: ({ onChangeInput, onSearchClicked }) => (e) => {
        e.preventDefault();
        onChangeInput({
          target: {
            name: "q",
            value: "",
          },
        });
        onSearchClicked();
      },
    }),
    withPropsOnChange(
      ["sorted", "filtered", "filteredTags"],
      ({
        getUsersList,
        sorted,
        filtered,
        pageSize,
        isFocused,
        filteredTags,
      }) => {
        if (
          sorted.length > 0 ||
          filtered.length > 0 ||
          pageSize ||
          filteredTags.length > 0
        ) {
          if (isFocused) {
            getUsersList();
          }
        }
      }
    ),
    lifecycle({
      componentDidMount() {
        if (get(this.props, "isFocused")) {
          this.props.setFiltered([]);
          this.props.setSearchTags([]);
          this.props.setSorted([]);
          this.props.setFilteredTags([]);
          this.props.getUsers({
            pageSize,
            page: 0,
            sorted: [],
            filtered: [],
            filteredTags: [],
          });
        }
      },
      componentWillReceiveProps(nextProps) {
        // only call when focused to this tab
        if (
          get(this.props, "isFocused") !== get(nextProps, "isFocused") &&
          get(nextProps, "isFocused") &&
          !get(nextProps, "users.data.data")
        ) {
          this.props.getUsersList();
        } else if (
          get(nextProps, "users.is_dirty") &&
          get(nextProps, "isFocused")
        ) {
          this.props.getUsersList();
        } else if (
          get(this.props, "isNewUser") !== get(nextProps, "isNewUser") &&
          get(nextProps, "isNewUser")
        ) {
          this.props.getUsersList();
        }
      },
    })
  )(UserTableManual)
);
