import React, { useState } from "react";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import { find, get, includes, map, toLower } from "lodash";
import { FaSortDesc, FaSortAsc } from "react-icons/lib/fa";
import { formatDisplayDate } from "utils/helpers";
import { Table } from "reactstrap";
import { EyeIcon } from "components/CustomIcons";
import useInfiniteScroll from "utils/useInfiniteScroll";
import { BlockIcon } from "components/CustomIcons";
import ProcessDialog from "components/ProcessDialog";
import NoItemsFound from "components/NoItemsFound";
import Avatar from "components/Avatar";
import { default_templates } from "utils/defaults";
import moment from "moment";
import { titleDisplay } from "utils/helpers";

const View = ({
  actionSort,
  sort,
  processList,
  bem,
  onClickRow,
  loadItems,
  hasMoreItems,
  isLoading,
  user,
}) => {
  const [currentProcess, setCurrentProcess] = useState(null);
  const [isOpenPreviewModal, setIsOpenPreviewModal] = useState(false);
  const typeDisplay = (process) => {
    const template_key = get(process, "_source.template_key", true);

    switch (template_key) {
      case "digital":
        const forMarketingChannel = get(
          process,
          "_source.for_marketing_channel",
          {}
        );
        const marketingChannel = find(
          get(process, "_source.marketing_channels", []),
          (channel) => channel.id === forMarketingChannel.id
        );
        const media_type = get(marketingChannel, "media_type.value", "");
        switch (media_type) {
          case "Social Media":
          case "Banner Ad":
          case "Video Ad":
          case "Text Ad":
            return (
              <div
                className={classnames(
                  bem.e("type"),
                  {
                    [bem.e("type-digital")]: true,
                  },
                  bem.e(`type-digital-${media_type.split(" ").join("")}`)
                )}
              >
                <FormattedMessage id={"process > digital " + media_type} />
              </div>
            );
          default:
            return (
              <div
                className={classnames(bem.e("type"), {
                  [bem.e("type-digital")]: true,
                })}
              >
                <FormattedMessage id={"process > digital"} />
              </div>
            );
        }
      case "promotion":
        return (
          <div
            className={classnames(bem.e("type"), {
              [bem.e("type-sold")]: true,
            })}
          >
            <FormattedMessage id={"process > promotion"} />
          </div>
        );
      default:
        const orderSold = get(process, "_source.order_sold", true);
        const usersGranted = process._source.users_granted_me_access
          ? Object.values(process._source.users_granted_me_access)
          : [];
        return (
          <div
            className={classnames(bem.e("type"), {
              [bem.e("type-sold")]: orderSold,
              [bem.e("type-spec")]: !orderSold,
            })}
          >
            <FormattedMessage
              id={orderSold ? "process > order sold" : "process > order spec"}
            />
            {map(usersGranted, (u) => (
              <Avatar
                src={u.avatar_url}
                name={u.name}
                size={24}
                id={u.id}
                className="avatar-small"
              />
            ))}
          </div>
        );
    }
  };
  const flightDatesDisplay = (process, key) => {
    const orderDate = get(process, `_source.${key}`);
    return (
      <div className={bem.e("flight-dates")}>
        <span>
          {orderDate ? (
            formatDisplayDate(orderDate)
          ) : (
            <FormattedMessage id="process > none" />
          )}
        </span>
      </div>
    );
  };
  const statusDisplay = (process) => {
    const processStep = get(process, "_source.process_step", "draft");
    const spotEndDate = get(process, "_source.order_end_date", null);
    const row_template = default_templates.find(
      (t) => t._id === get(process, "_source.template_key", "basic_production")
    );
    let step = get(row_template, `_source.steps.${processStep}`);
    let title =
      get(
        process,
        "_source.draft_template",
        get(process, "_source.template_key")
      ) !== "" ? (
        <FormattedMessage id={"process > title draft"} />
      ) : (
        <FormattedMessage id={"process > title wo draft"} />
      );
    let color = "#9AA4CA";

    if (title !== "draft") {
      color = get(step, "caption_color", "#9AA4CA");
    }
    if (processStep === "done") {
      title = <FormattedMessage id="process > title on air" />;
      color = "#0000ff";
    }
    if (get(step, "title")) {
      title = get(step, "title");
      if (step.key === "approve_recording") {
        if (get(process, "_source.sent_to_client_for_spot_review")) {
          title = <FormattedMessage id={"process > title client review"} />;
        }
      }
      if (step.key === "approve_script") {
        if (get(process, "_source.sent_to_client_for_script_review")) {
          title = <FormattedMessage id={"process > title client review"} />;
        }
      }
      if (step.key === "order_details") {
        if (get(process, "_source.order_details_status") === "draft")
          title = "draft";
      }
      if (step.key === "record_script") {
        if (get(process, "_source.approve_recording_status") === "rejected") {
          title = <FormattedMessage id={"process > title spot change"} />;
          color = "#E40061";
        }
      }
      if (step.key === "write_script") {
        if (get(process, "_source.approve_script_status") === "rejected")
          title = <FormattedMessage id={"process > title script change"} />;
        color = "#E40061";
      }
      if (step.key === "assign_vo") {
        title = <FormattedMessage id={"process > title assign vo"} />;
        color = "#A82AC0";
      }
      if (step.key === "voice_over") {
        if (get(process, "_source.voice_over_status") === "rejected") {
          title = <FormattedMessage id={"process > title change request"} />;
          color = "#E40061";
        } else {
          title = <FormattedMessage id={"process > title voice over"} />;
        }
      }
      if (step.key === "continuity") {
        if (get(process, "_source.continuity_status") === "change_request") {
          title = <FormattedMessage id={"process > title change request"} />;
          color = "#E40061";
        }
      }
    }
    if (!process._source.order_sold && processStep === "done") {
      title = <FormattedMessage id="process > title spec spot" />;
      color = "#0f5589";
    }
    if (
      spotEndDate &&
      moment(spotEndDate).isBefore(moment().format("YYYY-MM-DD")) &&
      processStep === "done"
    ) {
      title = <FormattedMessage id="process > title aired spot" />;

      color = "#0000ff";
    }

    return (
      <span
        className={classnames(
          bem.e("order-status"),
          bem.e(
            `order-status-${
              title.replace ? toLower(title.replace(/ /g, "-")) : ""
            }`
          )
        )}
        style={{ color }}
      >
        {title}
      </span>
    );
  };
  const getSpotsLength = (process) => {
    if (get(process, "_source.contract_status") === "Production Needed")
      return null;
    return get(process, "_source.spot_info.length") > 1
      ? `(+${get(process, "_source.spot_info.length") - 1} spot)`
      : "";
  };
  useInfiniteScroll({
    useWindow: false,
    elementId: "cr-app-main",
    onLoadMore: loadItems,
    shouldLoadMore: hasMoreItems,
  });
  return (
    <div className={bem.e("table-wrapper")}>
      <Table className={bem.e("table")}>
        <thead>
          <tr>
            <th
              onClick={() => actionSort("order_sold")}
              className={classnames(
                "can-click",
                "col-table-10",
                bem.e("table-first-head")
              )}
            >
              <div className={bem.e("item-sortable")}>
                <FormattedMessage id="process > Type" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_sold",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_sold desc",
                    })}
                  />
                </div>
              </div>
            </th>
            <th
              onClick={() => actionSort("client_name")}
              className="can-click col-table-15"
            >
              <div className={bem.e("item-sortable")}>
                <FormattedMessage id="process > Client" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "client_name",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "client_name desc",
                    })}
                  />
                </div>
              </div>
            </th>
            <th
              onClick={() => actionSort("order_title")}
              className="can-click col-table-30"
            >
              <div className={classnames(bem.e("item-sortable"))}>
                <FormattedMessage id="process > Title" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_title",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_title desc",
                    })}
                  />
                </div>
              </div>
            </th>
            <th
              onClick={() => actionSort("order_length")}
              className="can-click col-table-10 text-center"
            >
              <div className={bem.e("item-sortable-center")}>
                <FormattedMessage id="process > length" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_length",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_length desc",
                    })}
                  />
                </div>
              </div>
            </th>
            <th
              onClick={() => actionSort("order_start_date")}
              className="can-click col-table-10 text-center"
            >
              <div className={bem.e("item-sortable-center")}>
                <FormattedMessage id="process > start date" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_start_date",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        sort === "order_start_date desc",
                    })}
                  />
                </div>
              </div>
            </th>
            <th
              onClick={() => actionSort("order_end_date")}
              className="can-click col-table-10 text-center"
            >
              <div className={bem.e("item-sortable-center")}>
                <FormattedMessage id="process > end date" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "order_end_date",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        sort === "order_end_date desc",
                    })}
                  />
                </div>
              </div>
            </th>
            <th className="col-table-10">
              <div className={bem.e("item-sortable-center")}>
                <FormattedMessage id="process > Preview" />
              </div>
            </th>
            <th
              onClick={() => actionSort("process_step")}
              className="can-click col-table-15 text-center"
            >
              <div className={bem.e("item-sortable-center")}>
                <FormattedMessage id="process > last status" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "process_step",
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]: sort === "process_step desc",
                    })}
                  />
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {processList.length === 0 && !isLoading ? (
            <tr>
              <td colSpan="7">
                <NoItemsFound />
              </td>
            </tr>
          ) : (
            processList.map((process) => {
              if (!process._source.template_key) return null;
              const beingEditedBy = get(
                process,
                "_source.being_edited_by",
                false
              );

              let accessGrantedTo = get(
                user,
                "access_granted_to_users",
                []
              ).map((user) => user.id);
              let hasAccessFrom = get(user, "has_access_from_users", []).map(
                (user) => user.id
              );
              const isLocked =
                beingEditedBy &&
                beingEditedBy.id !== user.id &&
                (includes(accessGrantedTo, beingEditedBy.id) ||
                  includes(hasAccessFrom, beingEditedBy.id));

              return (
                <tr
                  key={process._id}
                  onClick={() => onClickRow(process, "produced_spots")}
                >
                  {/* type */}
                  <td
                    className={classnames(
                      "align-middle col-table-10 align-middle",
                      bem.e("first-col")
                    )}
                  >
                    <span
                      className={classnames(
                        bem.e("td-value"),
                        bem.e("type-block")
                      )}
                    >
                      {typeDisplay(process)}
                      {isLocked && <BlockIcon />}
                    </span>
                  </td>
                  {/* client */}
                  <td
                    className={classnames(
                      "align-middle",
                      bem.e("client-name"),
                      "col-table-15"
                    )}
                  >
                    <span className={bem.e("td-value")}>
                      {process._source.client_name}
                    </span>
                  </td>
                  {/* title */}
                  <td
                    className={classnames(
                      "align-middle",
                      bem.e("order-title"),
                      "col-table-30"
                    )}
                  >
                    <span className={bem.e("td-value")}>
                      {titleDisplay(process)}
                      {/* {getSpotsLength(process)} */}
                    </span>
                  </td>
                  {/* order_length */}
                  <td className="align-middle col-table-10 text-center">
                    <span className={bem.e("td-value")}>
                      :{process._source.order_length}
                    </span>
                  </td>
                  {/* start date */}
                  <td className="align-middle col-table-10 text-center">
                    <span className={bem.e("td-value")}>
                      {flightDatesDisplay(process, "order_start_date")}
                    </span>
                  </td>
                  {/* end date */}
                  <td className="align-middle col-table-10 text-center">
                    <span className={bem.e("td-value")}>
                      {flightDatesDisplay(process, "order_end_date")}
                    </span>
                  </td>
                  {/* preview */}
                  <td
                    className={classnames(
                      "col-table-10 align-middle text-center",
                      bem.e("first-center-col")
                    )}
                  >
                    <span className={bem.e("td-value")}>
                      <div
                        className={classnames(bem.e("preview"))}
                        onClick={(e) => {
                          e.stopPropagation();
                          setCurrentProcess(process);
                          setIsOpenPreviewModal(true);
                        }}
                      >
                        <EyeIcon />
                      </div>
                    </span>
                  </td>
                  {/* status */}
                  <td className="align-middle col-table-15 text-center">
                    <span className={bem.e("td-value")}>
                      {statusDisplay(process)}
                    </span>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </Table>
      <ProcessDialog
        isOpen={isOpenPreviewModal}
        onToggle={() => setIsOpenPreviewModal(false)}
        process={currentProcess}
        button="archived_spots"
      />
    </div>
  );
};
export default View;
