import { FormattedMessage } from "react-intl";
import { get } from "lodash";
import history from "components/History";
import React from "react";
const traffic = {
  process_step_index: 5,
  key: "reports",
  title: <FormattedMessage id="process > title digital reports" />,
  caption_color: "#2B85FF",
  description: null,
  step_roles: [12],
  step_viewers_roles: [],
  step_editors_roles: [],
  step_viewers_privileges: ["contest_view"],
  step_editors_privileges: {
    or: [
      "contest_save",
      "contest_edit",
      "contest_delete",
      "contest_publish",
      "contest_save_draft",
    ],
  },
  is_check_hidden_tab: true,
  status: "initial",
  workflow: {
    title: "traffic",
    icon: "UnionIcon",
  },
  step_container: "none",
  is_approval_step: false,
  className: "report-container",
  form_class_name: "report-tab-container",
  // no_edit_button: true,
  fields: [
    {
      text: <FormattedMessage id="process > button submit" />,
      component: "button",
      props: { color: "blue" },
      container: "bottom_buttons",
      class: null,
      on_click: {
        validate: true,
        save: true,
      },
      fields: [
        {
          key: "reports_status",
          value: "completed",
        },
        {
          key: "marketing_channels",
          value: (user, template, process, step, item) => {
            const marketingChannels = get(
              process,
              "data.fields.marketing_channels.value",
              []
            ).map((channel) => {
              if (
                channel.optional_notes ||
                get(channel, "report_files.length") > 0
              ) {
                return {
                  ...channel,
                  report_status: "completed", //mark report status to channel
                  channel_step: "online",
                };
              }
              return {
                ...channel,
                channel_step: "online",
              };
            });
            return marketingChannels;
          },
        },
        {
          key: "next_users",
          value: (user, template, process, step, item, next_users) => {
            const saleRole = get(
              process,
              "data.fields.role_digital_account_executive.value.id"
            );
            const processStepIndex = get(
              process,
              "data.fields.process_step_index.value"
            );
            let isReportUploaded = false;
            get(process, "data.fields.marketing_channels.value", []).forEach(
              (channel) => {
                if (
                  channel.optional_notes ||
                  get(channel, "report_files.length") > 0
                ) {
                  isReportUploaded = true;
                }
              }
            );

            if (processStepIndex === 8 && isReportUploaded) {
              // will send email to sale when channel manager submitted first time the report and channel manager uploaded the report data.
              return [saleRole];
            }
            return next_users;
          },
        },
        {
          key: "process_step",
          value: "online",
        },
      ],
      confirmation_dialog_props: {
        description: (
          <FormattedMessage id="process > description submit the report" />
        ),
        confirmTitle: <FormattedMessage id="process > button yes" />,
        cancelTitle: <FormattedMessage id="process > button no" />,
      },
      success_dialog_props: {
        title: <FormattedMessage id="process > title report submitted" />,
        state: "success",
      },
    },
    {
      text: <FormattedMessage id="process > button cancel" />,
      component: "button",
      props: { color: "blue", outline: true },
      container: "bottom_buttons",
      class: null,
      onClick: function(_this, step, item, process, template, user) {
        const processId = get(process, "data.fields.key.value");
        const templateKey = get(process, "data.fields.template_key.value");
        history.replace(`/processes/view/${templateKey}/${processId}`);
      },
    },
  ],
};
export default traffic;
