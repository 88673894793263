import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { get } from "lodash";
import cx from "classnames";
import { SearchPlusSolidIcon, ArrowGuideIcon } from "components/CustomIcons";

import { CloseIcon } from "components/CustomIcons";
import Table from "components/Table";
import TextInput from "components/TextInput";
import Spinner from "components/Spinner";
import bn from "utils/bemnames";
import { PhoneNumberFormat } from "utils/config";
import NumberFormat from "react-number-format";
import useInfiniteScroll from "utils/useInfiniteScroll";
import AddWinnerForm from "./WinnerForm";
const bem = bn.create("jock-console-winner-modal");

function DisplayWinners(props) {
  const {
    onClose,
    onAdd,
    onPickWinner,
    selectedWinner,
    setSelectedWinner,
    winners,
    getMoreWinners,
    isLoading,
  } = props;
  const data = get(winners, "data.items", []);
  const hasData = !!get(winners, "data.items.length", 0);
  const currentPage = parseInt(get(winners, "data.page", 0));
  const totalPages = parseInt(get(winners, "data.pages", 0));
  const shouldLoadMore = currentPage < totalPages && !isLoading;
  useInfiniteScroll({
    useWindow: false,
    elementId: "add-winners-list-id",
    onLoadMore: () => getMoreWinners(currentPage),
    shouldLoadMore,
  });

  const tableColumns = [
    {
      Header: (
        <div className="header">
          <FormattedMessage id="jock console > full name" />
        </div>
      ),
      Cell: ({ original, original: { _source: winner } }) => (
        <div className={cx("col-content", "highlighted")}>
          {winner.first_name
            ? `${winner.first_name} ${winner.last_name}`
            : "--"}
        </div>
      ),
      className: "col-table-20-i",
      headerClassName: "col-table-20-i",
    },
    {
      Header: (
        <div className="header">
          <FormattedMessage id="jock console > mobile phone" />
        </div>
      ),
      Cell: ({ original: { _source: winner } }) => (
        <div className={cx("col-content")}>
          {(
            <NumberFormat
              value={winner.phone}
              displayType={"text"}
              format={PhoneNumberFormat}
            />
          ) || "--"}
        </div>
      ),
      className: "col-table-20-i",
      headerClassName: "col-table-20-i",
    },
    {
      Header: (
        <div className="header">
          <FormattedMessage id="jock console > email" />
        </div>
      ),
      Cell: ({ original: { _source: winner } }) => (
        <div className={cx("col-content")}>{winner.email || "--"}</div>
      ),
      className: "col-table-25-i",
      headerClassName: "col-table-25-i",
    },
    {
      Header: (
        <div className="header">
          <FormattedMessage id="jock console > zip code" />
        </div>
      ),
      Cell: ({ original: { _source: winner } }) => (
        <div className={cx("col-content")}>{winner.zip || "--"}</div>
      ),
      className: "col-table-15-i",
      headerClassName: "col-table-15-i",
    },
    {
      Header: (
        <div className="header text-center">
          <FormattedMessage id="jock console > eligibility" />
        </div>
      ),
      Cell: ({ original: { _source: winner } }) => (
        <div
          className={cx("col-content", "eligibility-box", {
            highlighted: winner.eligible,
          })}
        >
          <FormattedMessage
            id={
              winner.eligible
                ? "jock console > eligible"
                : "jock console > not eligible"
            }
          />
        </div>
      ),
      className: "col-table-20-i",
      headerClassName: "col-table-20-i",
    },
  ];

  return (
    <div
      id="add-winners-list-id"
      className={cx(bem.e("search-none"), { "pt-0 winner-has-data": hasData })}
    >
      {!hasData && (
        <div className={bem.e("search-none-text")}>
          <FormattedMessage id="jock console > none found" />
        </div>
      )}
      {hasData && (
        <Table
          className="add-winners-list"
          data={data}
          total={data.length}
          showPageSizeOptions={false}
          sortable={false}
          resizable={false}
          filterable={false}
          showPagination={false}
          columns={tableColumns}
          getTrProps={(state, { row: { _original } }) => {
            return {
              className:
                selectedWinner && selectedWinner._id === _original._id
                  ? "selected-winner"
                  : "not-selected",
              onClick: () => {
                setSelectedWinner(_original);
              },
            };
          }}
        />
      )}
      <div>
        {selectedWinner ? (
          <Button
            onClick={onPickWinner}
            color="blue"
            className="btn btn-radius btn-add-new"
          >
            <FormattedMessage id="jock console > button pick winner" />
          </Button>
        ) : (
          <Button
            onClick={onAdd}
            color="blue"
            className="btn btn-radius btn-add-new"
          >
            <FormattedMessage id="jock console > button add new" />
          </Button>
        )}
        <Button
          onClick={() => {
            onClose();
          }}
          color="blue"
          outline
          className="btn btn-radius btn-cancel"
        >
          <FormattedMessage id={`jock console > button cancel`} />
        </Button>
      </div>
    </div>
  );
}

function SearchHelp() {
  return (
    <div className={bem.e("search-help")}>
      <div className={bem.e("search-help-text")}>
        <FormattedMessage id="jock console > search help text" />
        <span className={bem.e("search-help-icon")}>
          <ArrowGuideIcon />
        </span>
      </div>
    </div>
  );
}

function SearchHeader(props) {
  const { intl, keyword, setKeyword, onSearch, isEdit, isShowForm } = props;
  const handleOnKeyPress = ({ keyCode }) => {
    if (keyCode === 13) {
      onSearch();
    }
  };
  return (
    <div className={bem.e("search-header")}>
      {isShowForm ? (
        <div className={bem.e("add-new-text")}>
          <FormattedMessage
            id={
              isEdit
                ? "jock console > verify contact info"
                : "jock console > new winner help text"
            }
          />
        </div>
      ) : (
        <div className={bem.e("search-input")}>
          <TextInput
            placeholder={intl.formatMessage({
              id: "jock console > placeholder search winner",
            })}
            value={keyword}
            onChange={({ target }) => setKeyword(target.value)}
            onKeyDown={handleOnKeyPress}
          />
          <span className={bem.e("search-icon")} onClick={() => onSearch()}>
            <SearchPlusSolidIcon color="#795AFA" />
          </span>
        </div>
      )}
    </div>
  );
}

function AddWinnerModal(props) {
  const {
    isOpen,
    intl,
    keyword,
    setKeyword,
    onSearch,
    isLoading,
    onSubmit,
    onSubmitEdit,
    winners,
    isShowForm,
    setIsShowForm,
    hasSearched,
    setHasSearched,
    selectedWinner,
    setSelectedWinner,
    isEdit,
    setIsEdit,
    getMoreWinners,
    isRollOverTheme,
    onCancel,
    isStandardCash,
    systemForm,
    currentServer,
  } = props;
  // const isShowForm = true;
  const onClose = () => {
    setIsShowForm(false);
    setHasSearched(false);
    setIsEdit(false);
    setKeyword("");
    setSelectedWinner(null);
    onCancel();
  };

  const onPickWinner = () => {
    setIsEdit(true);
    setIsShowForm(true);
  };

  const returnToSearch = () => {
    setIsEdit(false);
    setIsShowForm(false);
    setSelectedWinner(null);
  };

  const getTitle = () => {
    if (isRollOverTheme) {
      return "jock console > add player modal title";
    }
    return "jock console > add winner modal title";
  };

  return (
    <Modal isOpen={isOpen} className={bem.b()}>
      <ModalHeader>
        <p className={bem.e("title")}>
          <FormattedMessage id={getTitle()} />
        </p>
        <div
          className={bem.e("close")}
          onClick={() => {
            onCancel();
            setKeyword("");
            setIsShowForm(false);
          }}
        >
          <CloseIcon color="#ffffff" />
        </div>
      </ModalHeader>
      <ModalBody className={bem.e("body")}>
        <SearchHeader
          isShowForm={isShowForm}
          keyword={keyword}
          setKeyword={setKeyword}
          onSearch={onSearch}
          isEdit={isEdit}
          intl={intl}
        />
        {isShowForm && (
          <AddWinnerForm
            intl={intl}
            isEdit={isEdit}
            onSubmit={onSubmit}
            onSubmitEdit={onSubmitEdit}
            returnToSearch={returnToSearch}
            selectedWinner={get(selectedWinner, "_source", "")}
            isStandardCash={isStandardCash}
            onCancel={onClose}
            keyword={keyword}
            systemForm={systemForm}
            currentServer={currentServer}
          />
        )}
        {!isShowForm && !hasSearched && <SearchHelp />}
        {!isShowForm && hasSearched && (
          <DisplayWinners
            onClose={onClose}
            onPickWinner={onPickWinner}
            getMoreWinners={getMoreWinners}
            onAdd={() => setIsShowForm(true)}
            setIsEdit={setIsEdit}
            selectedWinner={selectedWinner}
            setSelectedWinner={setSelectedWinner}
            isEdit={isEdit}
            winners={winners}
            isLoading={isLoading}
          />
        )}
        <Spinner isLoading={isLoading} />
      </ModalBody>
    </Modal>
  );
}

AddWinnerModal.defaultProps = {
  isOpen: false,
  onCancel: () => {},
  isContest: false,
};

AddWinnerModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  isContest: PropTypes.bool,
};

export default AddWinnerModal;
