import axios from "axios";
import { BASE_URL } from "./constants";
import store from "store";
import ToastManager from "components/ToastManager";
import { get } from "lodash";
import history from "components/History";
import * as authActions from "store/actions/auth";
import CustomError from "./error";
import moment from "moment";
class Api {
  constructor() {
    this.axios = axios.create({
      baseURL: BASE_URL,
      headers: {
        "Content-Type": "application/json",
      },
    });
    // Add a request interceptor
    this.axios.interceptors.request.use(
      (config) => {
        const auth = store.getState().auth;
        const token = auth.token;
        let headers = config.headers;
        if (token) {
          headers = {
            ...config.headers,
            Authorization: `Bearer ${token}`,
          };
        }
        return {
          ...config,
          headers,
        };
      },
      (error) => Promise.reject(error)
    );
    // Add a response interceptor
    this.axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        const status = get(error, "response.status");
        if (status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          const responseRefreshToken = await this.doRefreshToken();
          if (responseRefreshToken) {
            const { token } = responseRefreshToken;
            this.axios.defaults.headers.common["Authorization"] =
              "Bearer " + token;
            return this.axios(originalRequest);
          }
        }
        if (
          status === 401 &&
          originalRequest._retry &&
          document.location.href.indexOf("/login") === -1
        ) {
          store.dispatch(authActions.logoutUser());
          history.push("/login");
        }
        return Promise.reject(error);
      }
    );
  }
  checkExpires = (expires) => {
    if (!expires) return true; // let call api to valid the refresh token
    const expiresUnix =
      moment
        .tz(expires.date, expires.timezone)
        .utc()
        .valueOf() / 1000;
    const now = moment.utc().valueOf() / 1000;

    return expiresUnix > now;
  };
  doRefreshToken = async () => {
    const auth = store.getState().auth;
    const user_id = auth.user.id;
    const { expires_at, refresh_token } = auth;
    if (!refresh_token) return null;
    // check expires
    const isValidExpires = this.checkExpires(expires_at);
    if (!isValidExpires) return null;
    return new Promise((resolve) => {
      authActions.refreshAccessToken(
        {
          user_id,
          refresh_token,
        },
        (response) => {
          if (response && response.data && response.data.token) {
            const { token, refresh_token, expires_at } = response.data;
            store.dispatch(
              authActions.refreshAccessTokenSuccess({
                token,
                refresh_token,
                expires_at,
              })
            );
            resolve({ token, refresh_token, expires_at });
          } else {
            resolve(null);
          }
        }
      );
    });
  };

  doCall = async (
    path = "",
    method,
    body,
    callback,
    isShowToast = true,
    requestConfig = {}
  ) => {
    const auth = store.getState().auth;
    let headers = {
      "Content-Type": "application/json",
    };
    if (method !== "GET") {
      const impersonator = auth.user ? auth.user.impersonated_by : false;
      if (impersonator) {
        if (path.indexOf("?") !== -1) path += "&";
        else path += "?";
        path += "ps_impersonator=" + JSON.stringify(impersonator);
      }
    }
    try {
      const res = await this.axios.request({
        url: path,
        method,
        data: body,
        headers,
        ...requestConfig,
      });
      if (callback) callback(res);
      return res;
    } catch (err) {
      // common error point
      const apiError = get(err, "response.data.error");
      const status = get(err, "response.status");
      // disable show toast when error is: ['Unauthenticated.']. Because if user system_idle_timeout -> logout user -> but we called the apis to get data on current user page
      if (isShowToast && status !== 401) {
        const error = apiError ? new CustomError(apiError) : err;
        ToastManager.show({ message: error.toString(), level: "error" });
      }
      if (callback) callback(get(err, "response"));
      // validate server side
      if (status === 422 || status === 412 || status === 419 || status === 401) {
        throw apiError;
      } else {
        throw err;
      }
    }
  };
}

export default new Api();
