import React from "react";
import Spinner from "components/Spinner";
import * as Yup from "yup";
import { Formik } from "formik";
import { FormattedMessage } from "react-intl";
import SelectMultipleEffective from "components/SelectMultipleEffective";
import { Row, Col, Button, Form } from "reactstrap";
import { get } from "lodash";

class CitiesGroups extends React.Component {
  render() {
    const { isLoading, allGroups } = this.props;
    return (
      <Formik
        enableReinitialize
        initialValues={{
          group_add: [],
          group_remove: []
        }}
        validationSchema={Yup.object().shape({})}
        onSubmit={values => {
          this.props.onFormSubmit(values);
        }}
      >
        {formProps => {
          // const errors = formProps.errors;
          // const touched = formProps.touched;
          const values = formProps.values;
          const setFieldValue = formProps.setFieldValue;
          return (
            <Form
              onSubmit={formProps.handleSubmit}
              className="form-add-update-user"
            >
              <Row>
                <Col xs={12}>
                  <SelectMultipleEffective
                    leftTitle={this.props.intl.formatMessage({
                      id: "station > select groups"
                    })}
                    optionsToAddTitle={this.props.intl.formatMessage({
                      id: "station > selected groups to add"
                    })}
                    optionsToRemoveTitle={this.props.intl.formatMessage({
                      id: "station > selected groups to remove"
                    })}
                    allOptions={allGroups}
                    onCallBackSetItemsToAdd={options => {
                      setFieldValue("group_add", options);
                    }}
                    onCallBackSetItemsToRemove={options => {
                      setFieldValue("group_remove", options);
                    }}
                  />
                </Col>
                <Col xs={12}>
                  <div className="cities-groups-buttons">
                    <Button
                      color="blue"
                      type="submit"
                      className="btn btn-radius"
                      disabled={
                        isLoading ||
                        (get(values, "group_add.length") === 0 &&
                          get(values, "group_remove.length") === 0)
                      }
                    >
                      <FormattedMessage
                        id={`user > button ${
                          !isLoading ? "submit" : "submitting"
                        }`}
                      />
                    </Button>
                    {` `}
                    {this.props.backButton}
                  </div>

                  <Spinner isLoading={isLoading} />
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

export default CitiesGroups;
