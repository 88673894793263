import View from "./view";
import { compose, withState, lifecycle } from "recompose";
import { injectIntl } from "react-intl";
import { get } from "lodash";
export default injectIntl(
	compose(
		withState("comments", "setComments", (props) =>
			get(props, "asset.comments", [])
		),
		withState("isVisibleComment", "setIsVisibleComment", null),
		withState("currentRefComment", "setCurrentRefComment", null),
		lifecycle({
			componentDidUpdate(prevProps) {
				if (
					get(this.props, "asset.path") !==
					get(prevProps, "asset.path")
				) {
					this.props.setComments(
						get(this.props, "asset.comments", [])
					);
				}
			},
		})
	)(View)
);
