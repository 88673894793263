import * as Types from "../types/tags";
import { LOGOUT_USER } from "../types/auth";

const initialState = {
  tagTypes: {
    data: [],
    error: null,
    loading: false
  },
  cities: {
    data: [],
    error: null,
    loading: false
  },
  addCity: {
    error: null,
    loading: false
  },
  updateCity: {
    error: null,
    loading: false
  },
  deleteCity: {
    error: null,
    loading: false
  },
  orderCities: {
    error: null,
    loading: false
  },
  groups: {
    data: [],
    error: null,
    loading: false
  },
  addGroup: {
    error: null,
    loading: false
  },
  updateGroup: {
    error: null,
    loading: false
  },
  deleteGroup: {
    error: null,
    loading: false
  },
  orderGroups: {
    error: null,
    loading: false
  },
  unratedMarket: {
    error: null,
    loading: false
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_CITIES_REQUEST: {
      return {
        ...state,
        cities: {
          data: [],
          error: null,
          loading: true
        }
      };
    }
    case Types.FETCH_CITIES_SUCCESS: {
      return {
        ...state,
        cities: {
          data: action.payload,
          error: null,
          loading: false
        }
      };
    }
    case Types.FETCH_CITIES_ERROR: {
      return {
        ...state,
        cities: {
          data: [],
          error: action.error,
          loading: false
        }
      };
    }
    case Types.FETCH_TAG_TYPES_REQUEST: {
      return {
        ...state,
        tagTypes: {
          data: [],
          error: null,
          loading: true
        }
      };
    }
    case Types.FETCH_TAG_TYPES_SUCCESS: {
      return {
        ...state,
        tagTypes: {
          data: action.payload,
          error: null,
          loading: false
        }
      };
    }
    case Types.FETCH_TAG_TYPES_ERROR: {
      return {
        ...state,
        tagTypes: {
          data: [],
          error: action.error,
          loading: false
        }
      };
    }
    // Add Prize Actions
    case Types.ADD_CITY_REQUEST: {
      return {
        ...state,
        addCity: {
          loading: true,
          error: null
        }
      };
    }
    case Types.ADD_CITY_SUCCESS: {
      return {
        ...state,
        cities: {
          data: action.payload,
          error: null,
          loading: false
        },
        addCity: {
          loading: false,
          error: null
        }
      };
    }
    case Types.ADD_CITY_ERROR: {
      return {
        ...state,
        addCity: {
          loading: false,
          error: action.payload
        }
      };
    }

    // Update City Actions
    case Types.UPDATE_CITY_REQUEST: {
      return {
        ...state,
        updateCity: {
          loading: true,
          error: null
        }
      };
    }
    case Types.UPDATE_CITY_SUCCESS: {
      return {
        ...state,
        cities: {
          data: action.payload,
          error: null,
          loading: false
        },
        updateCity: {
          loading: false,
          error: null
        }
      };
    }
    case Types.UPDATE_CITY_ERROR: {
      return {
        ...state,
        updateCity: {
          loading: false,
          error: action.payload
        }
      };
    }

    // delete tag Actions
    case Types.DELETE_CITY_REQUEST: {
      return {
        ...state,
        deleteCity: {
          loading: true,
          error: null
        }
      };
    }
    case Types.DELETE_CITY_SUCCESS: {
      return {
        ...state,
        cities: {
          data: action.payload,
          error: null,
          loading: false
        },
        deleteCity: {
          loading: false,
          error: null
        }
      };
    }
    case Types.DELETE_CITY_ERROR: {
      return {
        ...state,
        deleteCity: {
          loading: false,
          error: action.payload
        }
      };
    }
    // update tags
    case Types.UPDATE_CITIES_REQUEST: {
      return {
        ...state,
        cities: {
          data: [],
          error: null,
          loading: true
        }
      };
    }
    case Types.UPDATE_CITIES_SUCCESS: {
      return {
        ...state,
        cities: {
          data: action.payload,
          error: null,
          loading: false
        }
      };
    }
    case Types.UPDATE_CITIES_ERROR: {
      return {
        ...state,
        cities: {
          data: [],
          error: action.error,
          loading: false
        }
      };
    }
    // order tags
    case Types.ORDER_CITIES_REQUEST: {
      return {
        ...state,
        orderCities: {
          error: null,
          loading: true
        }
      };
    }
    case Types.ORDER_CITIES_SUCCESS: {
      return {
        ...state,
        orderCities: {
          error: null,
          loading: false
        },
        cities: {
          data: action.payload,
          error: null,
          loading: false
        }
      };
    }
    case Types.ORDER_CITIES_ERROR: {
      return {
        ...state,
        orderCities: {
          error: action.payload,
          loading: false
        }
      };
    }
    // groups
    case Types.FETCH_GROUPS_REQUEST: {
      return {
        ...state,
        groups: {
          data: [],
          error: null,
          loading: true
        }
      };
    }
    case Types.FETCH_GROUPS_SUCCESS: {
      return {
        ...state,
        groups: {
          data: action.payload,
          error: null,
          loading: false
        }
      };
    }
    case Types.FETCH_GROUPS_ERROR: {
      return {
        ...state,
        groups: {
          data: [],
          error: action.error,
          loading: false
        }
      };
    }
    // Add Groups Actions
    case Types.ADD_GROUP_REQUEST: {
      return {
        ...state,
        addGroup: {
          loading: true,
          error: null
        }
      };
    }
    case Types.ADD_GROUP_SUCCESS: {
      return {
        ...state,
        groups: {
          data: action.payload,
          error: null,
          loading: false
        },
        addGroup: {
          loading: false,
          error: null
        }
      };
    }
    case Types.ADD_GROUP_ERROR: {
      return {
        ...state,
        addGroup: {
          loading: false,
          error: action.payload
        }
      };
    }

    // Update City Actions
    case Types.UPDATE_GROUP_REQUEST: {
      return {
        ...state,
        updateGroup: {
          loading: true,
          error: null
        }
      };
    }
    case Types.UPDATE_GROUP_SUCCESS: {
      return {
        ...state,
        groups: {
          data: action.payload,
          error: null,
          loading: false
        },
        updateGroup: {
          loading: false,
          error: null
        }
      };
    }
    case Types.UPDATE_GROUP_ERROR: {
      return {
        ...state,
        updateGroup: {
          loading: false,
          error: action.payload
        }
      };
    }

    // delete tag Actions
    case Types.DELETE_GROUP_REQUEST: {
      return {
        ...state,
        deleteGroup: {
          loading: true,
          error: null
        }
      };
    }
    case Types.DELETE_GROUP_SUCCESS: {
      return {
        ...state,
        groups: {
          data: action.payload,
          error: null,
          loading: false
        },
        deleteGroup: {
          loading: false,
          error: null
        }
      };
    }
    case Types.DELETE_GROUP_ERROR: {
      return {
        ...state,
        deleteGroup: {
          loading: false,
          error: action.payload
        }
      };
    }
    // update groups
    case Types.UPDATE_GROUPS_REQUEST: {
      return {
        ...state,
        groups: {
          data: [],
          error: null,
          loading: true
        }
      };
    }
    case Types.UPDATE_GROUPS_SUCCESS: {
      return {
        ...state,
        groups: {
          data: action.payload,
          error: null,
          loading: false
        }
      };
    }
    case Types.UPDATE_GROUPS_ERROR: {
      return {
        ...state,
        groups: {
          data: [],
          error: action.error,
          loading: false
        }
      };
    }
    // order groups
    case Types.ORDER_GROUPS_REQUEST: {
      return {
        ...state,
        orderGroups: {
          error: null,
          loading: true
        }
      };
    }
    case Types.ORDER_GROUPS_SUCCESS: {
      return {
        ...state,
        orderGroups: {
          error: null,
          loading: false
        },
        groups: {
          data: action.payload,
          error: null,
          loading: false
        }
      };
    }
    case Types.ORDER_GROUPS_ERROR: {
      return {
        ...state,
        orderGroups: {
          error: action.payload,
          loading: false
        }
      };
    }
    // unratedMarket
    case Types.ADD_UNRATED_MARKET_REQUEST: {
      return {
        ...state,
        unratedMarket: {
          error: null,
          loading: true
        }
      };
    }
    case Types.ADD_UNRATED_MARKET_SUCCESS: {
      return {
        ...state,
        unratedMarket: {
          error: null,
          loading: false
        }
      };
    }
    case Types.ADD_UNRATED_MARKET_ERROR: {
      return {
        ...state,
        unratedMarket: {
          error: action.payload,
          loading: false
        }
      };
    }
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
}
