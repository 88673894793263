import { useCallback, useEffect, useRef, useState } from "react";
import Pusher from "pusher-js";
import { connect } from "react-redux";
import { compose } from "recompose";
import { logoutUser } from "store/actions/auth";
import { incomingMessage } from "store/actions/messages";
import { get } from "lodash";

const PushserListener = (props) => {
  const { auth, messages, processes, onIncomingMessageAction } = props;
  const [currentMessage, setCurrentMessage] = useState(null);
  const pusher = useRef(null);
  const subscribeChannels = useRef([]);
  const onInComingMessage = useCallback(
    (message) => {
      // We subscribed multiple stations for pusher. Then we need to only update one time for each message.
      if (
        currentMessage &&
        get(currentMessage, "m_id") === get(message, "m_id")
      ) {
        return;
      }
      setCurrentMessage(message);
      onIncomingMessageAction(message, auth, messages, processes);
    },
    [auth, messages, processes, currentMessage, onIncomingMessageAction]
  );
  useEffect(() => {
    if (get(auth, "info.server_is_active") === false) {
      props.logoutUser();
      if (pusher.current) pusher.current.disconnect();
      return;
    }

    const isClientReview =
      document.location.pathname.split("/")[1] === "review";
    if (
      !auth.info ||
      !get(auth, "info.config.pusher") ||
      !auth.info.stations ||
      auth.info.stations.length === 0 ||
      isClientReview
    ) {
      if (pusher.current) pusher.current.disconnect();
      return;
    }

    const configPusher = get(props, "auth.info.config.pusher", {});
    if (!configPusher.key) return;
    // init pusher
    if (!pusher.current) {
      pusher.current = new Pusher(configPusher.key, configPusher.options);
      // pusher.current.connection.bind("disconnected", () => {
      //   console.log("pusher disconnected");
      // });
    }
    // listener stations
    const stations = get(auth, `info.stations`, []).filter(
      (station) => station.key
    );
    for (let index = 0; index < stations.length; index++) {
      const station = stations[index];
      if (!subscribeChannels.current.includes(station.key)) {
        console.log("REGISTERED: " + station.station_key + " " + station.key);
        subscribeChannels.current = [...subscribeChannels.current, station.key];
        const channel = pusher.current.subscribe(station.key);
        channel.bind("global", onInComingMessage);
      }
    }
  }, [auth]);
  return null;
};

export default compose(
  connect(
    (state) => {
      return {
        process: state.processes.process,
        processes: state.processes,
        auth: state.auth,
        messages: state.messages.messages,
        stations: state.auth.user && state.auth.user.stations,
      };
    },
    {
      onIncomingMessageAction: incomingMessage,
      logoutUser,
    }
  )
)(PushserListener);
