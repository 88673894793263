import React, { useEffect, useState } from "react";
import bn from "utils/bemnames";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment";
import { map, get } from "lodash";
import CalendarCard from "../CalendarCard";
import { FormattedMessage } from "react-intl";
const bem = bn.create("jock-console");
export default function CalendarHour(props) {
  const {
    hour,
    tasks,
    intl,
    isDone,
    isIncomplete,
    isPast,
    selectedSchedule,
    setSelectedSchedule,
    checkIsScrollable,
    hasFloatingTasks,
    selectedType,
  } = props;
  const formattedHour = moment(hour, "HH:mm").format("h a");
  const [isOpen, setIsOpen] = useState(false);
  const hourArray = formattedHour.split(" ");
  let title = "";
  if (isDone) {
    title = (
      <strong className={bem.e("calendar-hour-done")}>
        <FormattedMessage id="jock console > done" />
      </strong>
    );
  } else if (isIncomplete) {
    title = (
      <strong className={bem.e("calendar-hour-incomplete-text")}>
        <FormattedMessage id="jock console > incomplete" />
      </strong>
    );
  } else if (selectedType === "station") {
    title = <strong className={bem.e("calendar-station-text")}>{hour}</strong>;
  } else {
    title = (
      <React.Fragment>
        <strong>{hourArray[0]} </strong>
        {hourArray[1]}
        {` `}
        {intl.formatMessage({
          id: "jock console > hour",
        })}
      </React.Fragment>
    );
  }

  useEffect(() => {
    // The DONE or Incomplete section must not open when the task is DONE and added to the section. However, the Jock can open the DONE section whenever he wants it.
    if (isDone || isIncomplete || selectedType === "station") return;
    if (selectedSchedule && !isOpen) {
      const localUserHour = selectedSchedule
        ? moment(selectedSchedule.hour, "HH:mm").format("h a")
        : moment().format("h a");
      const isCurrentHour = formattedHour === localUserHour;
      setIsOpen(isCurrentHour);
    }
  }, [selectedSchedule]);
  useEffect(() => {
    if (hasFloatingTasks) {
      setTimeout(() => {
        checkIsScrollable();
      }, 300);
    }
  }, [isOpen]);
  useEffect(() => {
    // Task list viewed by station. When viewed by station, stations are expanded by default but may be collapsed manually.
    if (isDone || isIncomplete) return;
    if (selectedType === "station" && !isOpen) {
      setIsOpen(true);
    }
  }, [selectedType]);
  return (
    <div
      className={classnames(bem.e("calendar-hour"), {
        [bem.e("calendar-hour-open")]: isOpen,
        [bem.e("calendar-hour-incomplete")]: isIncomplete,
      })}
    >
      <div className={bem.e("calendar-hour-head")}>
        <span className={bem.e("calendar-hour-text")}>{title}</span>
        <span
          className={bem.e("calendar-hour-total-tasks")}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          {tasks.length}
          {` `}
          {intl.formatMessage({
            id: "jock console > tasks",
          })}
          <span
            className={classnames(bem.e("triangle"), {
              [bem.e("triangle-up")]: isOpen,
              [bem.e("triangle-down")]: !isOpen,
            })}
          ></span>
        </span>
      </div>
      {isOpen && (
        <div className={bem.e("calendar-hour-tasks")}>
          {map(tasks, (task, index) => {
            return (
              <CalendarCard
                key={title + task.id + index}
                task={task}
                isPast={isPast}
                selectedSchedule={selectedSchedule}
                isShowWinnerIcon={
                  get(task, "schedule.order_template_key", "") === "contest"
                }
                setSelectedSchedule={setSelectedSchedule}
                isShowCartStatus={true}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}
CalendarHour.propTypes = {
  hour: PropTypes.string,
  tasks: PropTypes.array,
  isDone: PropTypes.bool,
  isIncomplete: PropTypes.bool,
  isPast: PropTypes.bool,
  setSelectedSchedule: PropTypes.func,
};
CalendarHour.defaultProps = {
  tasks: [],
  hour: "10:00",
  isDone: false,
  isIncomplete: false,
  isPast: false,
  setSelectedSchedule: () => {},
};
