import React from "react";
import classNames from "classnames";
import bn from "utils/bemnames";
import { map, get, first, find, filter } from "lodash";
import { FormattedMessage } from "react-intl";
import UploadFile from "components/UploadFile";
import Chat from "components/NotificationsPusher";
import ConfirmationModal from "components/ConfirmationModal";
import { validatePrivileges } from "utils/helpers";
import { PRIVILEGES } from "utils/constants";

const bem = bn.create("voice-tracks");
function UploadRecording(props) {
  const {
    isView,
    voTrackValue,
    isShowComments,
    onRequestChange,
    item,
    voiceTrackValue,
    isVoEditing,
    process,
    isShowActions,
  } = props;
  return map(voiceTrackValue, (file, index) => {
    return (
      <UploadFile
        {...item.props}
        mode={"view"}
        key={`file-${index}`}
        process={process}
        onUploaded={() => {}}
        disabled={isView}
        onDeleted={() => {}}
        url={voTrackValue.path}
        actions={
          isView || isVoEditing
            ? ["download"]
            : isShowActions // Producer is the only role that can download recordings, or request a change. https://tasks.getventive.com/projects/3D7FF-B9A
            ? ["download", "custom"]
            : []
        }
        customAction={{
          title: isShowComments ? (
            <FormattedMessage id="process > vo cancel request" />
          ) : (
            <FormattedMessage id="process > vo request change" />
          ),
          onClick: () => onRequestChange(),
        }}
        positionActions="bottom"
        isShowFileNameInEditMode={true}
        auditInfo={get(item, "props.isShowAudit") && file.vo_audit}
      >
        <FormattedMessage id="process > button upload" />
      </UploadFile>
    );
  });
}
export default function OrderVoiceReview(props) {
  const [currentFeedback, setCurrentFeedback] = React.useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);

  const {
    isView,
    item,
    step,
    template,
    user,
    intl,
    formValueChanged,
    setShouldBlockNavigation,
    process,
    isVoEditing,
    isShowRecording,
    isAllowMessageActions,
    onDeleteMessages,
    auth,
  } = props;
  const privileges = get(auth, "user.privileges", []) || [];
  // vo only upload single record
  const voTrackValue = first(props.voiceTrackValue) || {};

  const voiceFeedback = get(process, "data.fields.voice_feedback.value", []);
  const voiceTracks = get(process, "data.fields.voice_tracks.value", []);
  const currentVoFeedBackItem =
    find(voiceFeedback, (i) => i.user_id === voTrackValue.user_id) || {};
  const isShowComments = get(currentVoFeedBackItem, "is_feedback", false);
  const isHideChatInput = isView || !isShowComments;

  const messages = get(process, "data.fields.messages.value", []);
  // get comments of voice
  const voiceTrackWithComments = filter(messages, (m) => {
    const mData = get(m, "_source", {});
    return (
      mData.m_type === "voice_feedback" &&
      mData.process_key === get(process, "data.fields.key.value") &&
      mData.recording_path === voTrackValue.path
    );
  });
  const isShowChangeRequested = voiceTrackWithComments.length > 0;
  const setIsShowComments = (isOpen) => {
    const newVoFeedback = map(voiceTracks, (item) => {
      return {
        is_feedback:
          item.user_id === voTrackValue.user_id
            ? isOpen
            : get(item, "is_feedback", false),
        user_id: item.user_id,
        include_timestamp: false,
        feedback:
          item.user_id === voTrackValue.user_id
            ? ""
            : get(item, "feedback", ""),
      };
    });

    formValueChanged({
      process,
      template,
      item: { field: "voice_feedback" },
      user,
      value: newVoFeedback,
    });
    process.data.fields.voice_feedback = {
      value: newVoFeedback,
      is_dirty: true,
    };
  };
  const onRequestChange = () => {
    if (isShowChangeRequested && isShowComments) {
      setIsDeleteModalOpen(true);
    } else {
      setIsShowComments(!isShowComments);
    }
  };
  const updateCurrentFeedbackToProcess = () => {
    const newVoFeedback = map(voiceTracks, (item) => {
      if (item.user_id === voTrackValue.user_id)
        return {
          ...item,
          ...currentFeedback,
          feedback: "", //reset current input value. Show warning when have new message still not submit
        };
      return item;
    });
    formValueChanged({
      process,
      template,
      item: { field: "voice_feedback" },
      user,
      value: newVoFeedback,
    });
    process.data.fields.voice_feedback = {
      value: newVoFeedback,
      is_dirty: true,
    };
    return process;
  };
  const isShowActions = validatePrivileges({
    requires: {
      or: [
        PRIVILEGES.SUBMIT_PRODUCTION_RECORDING,
        PRIVILEGES.EDIT_ALL_PRODUCTION_ORDERS,
      ],
    },
    privileges,
    user,
  });
  return (
    <div className={bem.e("voice-review-wrapper")}>
      <div
        className={classNames(
          bem.e("viewing-assets"),
          bem.e("other-viewing-assets")
        )}
      >
        {isShowRecording && (
          <UploadRecording
            isView={isView}
            voTrackValue={voTrackValue}
            isShowComments={isShowComments}
            onRequestChange={onRequestChange}
            item={item}
            voiceTrackValue={props.voiceTrackValue}
            isVoEditing={isVoEditing}
            process={process}
            isShowActions={isShowActions}
          />
        )}
        <div className={bem.e("review-comments")}>
          {!isView && !isVoEditing && (
            <React.Fragment>
              <div className={bem.e("review-description")}>
                <FormattedMessage id="process > change request vo description" />
              </div>
            </React.Fragment>
          )}
          {isShowChangeRequested && (
            <div className={bem.e("change-requested")}>
              <FormattedMessage id="process > change requested" />
            </div>
          )}
          <Chat
            step={step}
            process={process}
            template={template}
            user={user}
            defaultExpand={true}
            onToggleCallback={() => {}}
            chatInputPlaceHolder={intl.formatMessage({
              id: "process > recording feedback input placeholder",
            })}
            checkBoxIconStyle={{ width: "14px", height: "14px" }}
            showEmojiPicker={false}
            position="left"
            boxStyle={{ width: "100%" }}
            hideChatInput={isHideChatInput}
            voiceFeedback={true}
            // vo only upload single record
            voiceTrackValue={voTrackValue}
            recordingUrl={voTrackValue.path}
            isShowMessageActions={!isView && isAllowMessageActions}
            recomputeFields={updateCurrentFeedbackToProcess}
            onInputChange={(value) => {
              const newFeedback = {
                ...currentVoFeedBackItem,
                ...currentFeedback,
                feedback: value,
              };
              setCurrentFeedback(newFeedback);
              setShouldBlockNavigation(true); // set unsaved modal open
              // update feedback text to process. Show warning when have new message still not submit
              const newVoFeedback = map(voiceTracks, (item) => {
                if (item.user_id === voTrackValue.user_id)
                  return {
                    ...item,
                    ...newFeedback,
                    feedback: value,
                  };
                return item;
              });
              formValueChanged({
                process,
                template,
                item: { field: "voice_feedback" },
                user,
                value: newVoFeedback,
              });
            }}
            onTimeStampSelect={(checked) => {
              setCurrentFeedback({
                ...currentVoFeedBackItem,
                ...currentFeedback,
                include_timestamp: checked,
              });
              setShouldBlockNavigation(true); // set unsaved modal open
            }}
          />
        </div>
      </div>
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        title={props.intl.formatMessage({
          id: "process > are you sure you want to cancel the request",
        })}
        onConfirm={() => {
          onDeleteMessages(voiceTrackWithComments);
          setIsDeleteModalOpen(false);
          setIsShowComments(!isShowComments);
        }}
        onCancel={() => {
          setIsDeleteModalOpen(false);
          setIsShowComments(!isShowComments);
        }}
        isCloseOutside={false}
      />
    </div>
  );
}
OrderVoiceReview.defaultProps = {
  isVoEditing: false, // flag to show review description
  isShowRecording: true, // flag to show upload recoding
  isAllowMessageActions: true, // flag to show message actions: edit/delete
};
