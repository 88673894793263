import React from "react";
import bn from "utils/bemnames";
import { Row, Col } from "reactstrap";
import { map, get, find } from "lodash";
import { FormattedMessage } from "react-intl";
import Dropdown from "components/Dropdown";

const bem = bn.create("user-assignments");
export default function HubAssignments(props) {
  const { isView, hubs, user } = props;
  const hubOptions = map(hubs, (hub) => ({
    label: hub.name,
    value: Number(hub.id),
  }));
  const renderView = () => {
    const selectedHubsView = get(user, "hubs_as_manager", []);
    return (
      <Row>
        <Col className={bem.e("col-reset")}>
          <h2 className={bem.e("assignment-title")}>
            <FormattedMessage id="user > hub assignments" />
          </h2>
          <div className={bem.e("hub-view-list")}>
            {selectedHubsView.length === 0 && (
              <p className={bem.e("none-text")}>
                <FormattedMessage id="user > none" />
              </p>
            )}
            {selectedHubsView.length > 0 &&
              map(selectedHubsView, (item, index) => (
                <div className={bem.e("hub-item")} key={index}>
                  {item.name}
                </div>
              ))}
          </div>
        </Col>
      </Row>
    );
  };
  const renderForm = () => {
    const { formProps, setShouldBlockNavigation } = props;

    return (
      <div className={bem.e("hub-form")}>
        <div className={bem.e("head-form")}>
          <h2 className={bem.e("assignment-title")}>
            <FormattedMessage id="user > hub assignments" />
          </h2>
        </div>
        <Row>
          <Col className={bem.e("col-reset")}>
            <Dropdown
              label={props.intl.formatMessage({ id: "user > hubs" })}
              placeholder={props.intl.formatMessage({
                id: "user > select hub assignment",
              })}
              isMulti
              allowSelectAll
              onChange={(selectedOption) => {
                formProps.setFieldValue(
                  "hubs",
                  map(selectedOption, (item) => item.value)
                );
                setShouldBlockNavigation(true);
              }}
              value={map(get(formProps, "values.hubs", []), (item) =>
                find(hubOptions, (i) => i.value === Number(item))
              )}
              options={hubOptions}
              closeMenuOnSelect={false}
              error={
                get(formProps, `touched.hubs`) && get(formProps, `errors.hubs`)
              }
            />
          </Col>
        </Row>
      </div>
    );
  };
  return (
    <div className={bem.e("hub-assignments")}>
      {isView ? renderView() : renderForm()}
    </div>
  );
}
HubAssignments.defaultProps = {
  isView: true,
};
