import * as React from "react";
import { compose, withState, withHandlers, lifecycle } from "recompose";
import { connect } from "react-redux";
import { get, first, delay, find, split, trim, map } from "lodash";
import { sendMail } from "store/actions/mail";
import { getProcess } from "store/actions/processes";
import { getDefaultApprovalContacts } from "store/actions/clients";
import ToastManager from "components/ToastManager";
import { MdImportantDevices } from "react-icons/lib/md";
import { formatDisplayDate } from "utils/helpers";
import moment from "moment";
import { injectIntl } from "react-intl";
import View from "./view";
import { getSortUserName } from "utils/helpers";
import history from "components/History";

const generateEmailHtml = (
  process,
  user,
  userInfo,
  intl,
  data,
  isSpotReview,
  dateFormatByServer,
  producerSendsSpotToClient
) => {
  const isDigital =
    get(process, "data.fields.template_key.value") === "digital";
  const contractClient = get(
    process,
    "data.fields.contract_client_contact.value",
    ""
  );
  const nickName = get(process, "data.fields.contract_client_nickname.value")
    ? get(process, "data.fields.contract_client_nickname.value")
    : first(contractClient.split(" "));
  const orderTitle = get(process, "data.fields.order_title.value", "");
  const deadline = get(process, "data.fields.order_start_date.value")
    ? moment(get(process, "data.fields.order_start_date.value")).format(
        "MMMM DD"
      )
    : "";
  const stations = get(process, "data.fields.order_stations.value", []).map(
    (item) => {
      const station = find(userInfo.stations, (i) => i.station_key === item);
      return station ? station.name : item;
    }
  );
  const flightDatesDisplay = (process) => {
    const startDate = get(process, "data.fields.order_start_date.value");
    const endDate = get(process, "data.fields.order_end_date.value");

    if (!endDate || !startDate) return null;
    return `${
      startDate ? formatDisplayDate(startDate, dateFormatByServer) : ""
    } - ${endDate ? formatDisplayDate(endDate, dateFormatByServer) : ""}`;
  };
  const flightDates = flightDatesDisplay(process);

  const broadcastingCompany = get(userInfo, "server_name", "");

  let buttonLabel = `${
    isDigital
      ? intl.formatMessage({ id: "send mail dialog > review creative" })
      : intl.formatMessage({ id: "send mail dialog > review spot" })
  }`;

  if (isDigital) {
    buttonLabel = intl.formatMessage({
      id: "send mail dialog > review creative",
    });
  } else {
    if (isSpotReview || producerSendsSpotToClient) {
      buttonLabel = intl.formatMessage({
        id: "send mail dialog > review spot",
      });
    } else {
      buttonLabel = intl.formatMessage({
        id: "send mail dialog > review script",
      });
    }
  }

  return `
    <!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.01 Transitional//EN" "http://www.w3.org/TR/html4/loose.dtd">
      <html>
       <head>
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" >
          <title>New Task</title>
          <link href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700" rel="stylesheet">
          <style type="text/css">
             html { -webkit-text-size-adjust: none; -ms-text-size-adjust: none;}
             @media  only screen and (min-device-width: 750px) {
             .table750 {width: 750px !important;}
             }
             @media  only screen and (max-device-width: 750px), only screen and (max-width: 750px){
             table[class="table750"] {width: 100% !important;}
             .mob_b {width: 93% !important; max-width: 93% !important; min-width: 93% !important;}
             .mob_b1 {width: 100% !important; max-width: 100% !important; min-width: 100% !important;}
             .mob_left {text-align: left !important;}
             .mob_center {text-align: center !important;}
             .mob_soc {width: 50% !important; max-width: 50% !important; min-width: 50% !important;}
             .mob_menu {width: 50% !important; max-width: 50% !important; min-width: 50% !important; box-shadow: inset -1px -1px 0 0 rgba(255, 255, 255, 0.2); }
             .mob_btn {width: 80% !important; max-width: 80% !important; min-width: 80% !important;}
             .mob_pad {width: 15px !important; max-width: 15px !important; min-width: 15px !important;}
             .top_pad {height: 15px !important; max-height: 15px !important; min-height: 15px !important;}
             .top_pad2 {height: 60px !important; max-height: 50px !important; min-height: 50px !important;}
             .mob_title1 {font-size: 42px !important; line-height: 40px !important;}
             .mob_title2 {font-size: 24px !important; line-height: 30px !important;}
             .mob_txt {font-size: 20px !important; line-height: 25px !important;}
             }
             @media  only screen and (max-device-width: 550px), only screen and (max-width: 550px){
             .mod_div {display: block !important;}
             }
             .table750 {width: 530px;}
          </style>
        <!--[if (mso)|(mso 16)]>
            <style type="text/css">
                body, table, td, a, span { font-family: Arial, Helvetica, sans-serif !important; }
                a {text-decoration: none;}
            </style>
        <![endif]-->
       </head>
       <body style="margin: 0; padding: 0;">
          <table cellpadding="50" cellspacing="0" border="0" width="100%" style="background: #ECF2F5; min-width: 340px; font-size: 1px; line-height: normal;">
             <tr>
                <td align="center" valign="top">
                   <table cellpadding="0" cellspacing="0" border="0" width="530" style="max-width: 530px;min-width: 340px;">
                      <tr>
                         <td align="left" valign="top">
                            <div style="height: 40px; line-height: 40px;">&nbsp;</div>
                            <font face="Arial, sans-serif, Geneva" color="#FFFFFF" style="font-size: 16px; line-height: 20px;color: #111B2B;">
                              <span style="font-family: Arial, sans-serif, Geneva; color: #111B2B; font-size: 16px; line-height: 20px;">
                                <p style="margin:0px;">Hello ${nickName},</p>
                                <div style="height: 20px; line-height: 20px;">&nbsp;</div>
                                <p style="margin:0px;">${get(
                                  data,
                                  "message"
                                )}</p>
                              </span>
                            </font>
                            <div class="top_pad2" style="height: 30px;line-height: 30px;">&nbsp;</div>
                         </td>
                      </tr>
                   </table>
                   <!--[if (gte mso 9)|(IE)]>
                   <table border="0" cellspacing="0" cellpadding="0">
                      <tr>
                         <td align="center" valign="top" width="530">
                            <![endif]-->
                            <table cellpadding="0" cellspacing="0" border="0" width="750" class="table750" style="width: 100%; max-width: 530px; min-width: 340px; background: #f5f8fa;box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.102);">
                               <tr>
                                  <td align="center" valign="top" style="background: #ffffff;">
                                     <table cellpadding="15" cellspacing="0" border="0" width="100%" style="width: 100% !important; min-width: 100%; max-width: 100%;background: #FFFFFF;">
                                        <tr>
                                           <td align="center" valign="top">
                                              <div style="height: 30px; line-height: 30px;">&nbsp;</div>
                                              ${
                                                isDigital
                                                  ? `<font face="Arial, sans-serif, Geneva" color="#111B2B" style="font-weight: 400;font-size: 12px;line-height: 15px;text-align: center;color: #829FB1;">
                                                <span style="font-weight: 400;font-size: 12px;line-height: 15px;text-align: center;color: #829FB1;">
                                                      <p style="margin:0">${get(
                                                        process,
                                                        "data.fields.for_marketing_channel.value.name"
                                                      )}</p>
                                                </span>
                                                </font>`
                                                  : ""
                                              }
                                              <font face="Arial, sans-serif, Geneva" color="#111B2B" style="font-weight: 800;font-size: 30px;line-height: 34px;text-align: center;color: #334856;">
                                                <span style="font-weight: 800;font-size: 30px;line-height: 34px;text-align: center;color: #334856;">
                                                      <p style="margin:0">${orderTitle}</p>
                                                </span>
                                              </font>
                                              <div style="height: 20px; line-height: 20px;">&nbsp;</div>
                                              <font face="Arial, sans-serif, Geneva" color="#9AA4CA" style="font-size: 16px;line-height: 24px;text-align: center;margin-bottom: 26px;color: #334856;">
                                                <span style="font-size: 16px;line-height: 24px;text-align: center;margin-bottom: 26px;color: #334856;">
                                                      <p style="margin:0;font-weight: bold;">
                                                      ${
                                                        isDigital
                                                          ? intl.formatMessage({
                                                              id:
                                                                "send mail dialog > stations",
                                                            })
                                                          : "ENTITIES"
                                                      }:
                                                      </p>
                                                      <p style="margin:0">${stations.join(
                                                        ", "
                                                      )}</p>
                                                </span>
                                              </font>
                                              <div style="height: 30px; line-height: 30px;">&nbsp;</div>
                                              <font face="Arial, sans-serif, Geneva" color="#9AA4CA" style="font-size: 16px;line-height: 24px;text-align: center;margin-bottom: 26px;color: #334856;">
                                                <span style="font-size: 16px;line-height: 24px;text-align: center;margin-bottom: 26px;color: #334856;">
                                                      <p style="margin:0;font-weight: bold;">FLIGHT DATES:</p>
                                                      <p style="margin:0">${flightDates}</p>
                                                </span>
                                              </font>
                                              <div style="height: 30px; line-height: 30px;">&nbsp;</div>
                                              <font face="Arial, sans-serif, Geneva" color="#9AA4CA" style="font-size: 16px;line-height: 24px;text-align: center;margin-bottom: 26px;color: #334856;">
                                                <span style="font-size: 16px;line-height: 24px;text-align: center;margin-bottom: 26px;color: #334856;">
                                                      <p style="margin:0;font-weight: bold;">DEADLINE: ${deadline}</p>
                                                </span>
                                              </font>
                                           </td>
                                        </tr>
                                     </table>
                                    <!--[if mso]>
                                        <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="${get(
                                          process,
                                          "data.fields.email_link.value",
                                          "#"
                                        )}" style="height:44px;v-text-anchor:middle;width:200px;" arcsize="50%" stroke="f" fillcolor="#324856">
                                            <w:anchorlock/>
                                            <center style="color:#ffffff;font-family:Arial, sans-serif, Geneva;font-size:14px !important;font-weight:bold;line-height: 18px !important;text-transform:uppercase;">
                                                ${buttonLabel}
                                            </center>
                                        </v:roundrect>
                                    <![endif]-->
                                    <!--[if !mso]> <!-->
                                        <table class="mob_btn" cellpadding="5" cellspacing="0" border="0" style="background: #324856; border-radius: 24px; width: 200px;height: 44px;">
                                            <tr>
                                                <td align="center" valign="top"> 
                                                    <a href="${get(
                                                      process,
                                                      "data.fields.email_link.value",
                                                      "#"
                                                    )}"
                                                  target="_blank" style="display: block; border-radius: 24px; font-family:Muli, Arial, Verdana, Tahoma, Geneva; color: #ffffff; font-size: 14px; line-height: 32px; text-decoration: none; white-space: nowrap; font-weight: lighter;">
                                                     <font face="Arial, sans-serif, Geneva" color="#ffffff" style="font-size: 14px; line-height: 32px; text-decoration: none; white-space: nowrap; font-weight: lighter;">
                                                        <span style="font-family: Muli, Arial, Verdana, Tahoma, Geneva; color: #ffffff; font-size: 14px; line-height: 32px; text-decoration: none; white-space: nowrap; font-weight: bold;text-transform: uppercase;">${buttonLabel}</span>
                                                     </font>
                                                  </a>
                                                </td>
                                            </tr>
                                         </table>
                                    <!-- <![endif]-->
                                     <table cellpadding="0" cellspacing="0" border="0" width="100%" style="width: 100% !important; min-width: 100%; max-width: 100%;background: #FFFFFF;">
                                        <tr>
                                            <td>
                                                <div style="height: 40px; line-height: 40px;">&nbsp;</div>
                                            </td>
                                        </tr>
                                    </table>
                                  </td>
                               </tr>
                            </table>
                            <!--[if (gte mso 9)|(IE)]>
                         </td>
                      </tr>
                   </table>
                   <![endif]-->
                   <table cellpadding="0" cellspacing="0" border="0" width="530" style="max-width: 530px; min-width: 340px; background: none;">
                        <tr>
                            <td align="left" valign="top" style="width:60px;">
                              <div style="height: 30px; line-height: 30px;">&nbsp;</div>
                              <!--[if mso]>
                                <v:oval xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" style="height:61px;width:61px;" fill="t" stroke="f">
                                   <v:fill src="${get(
                                     user,
                                     "avatar_url",
                                     ""
                                   )}" type="frame" style="height:60px;width:60px;box-shadow: rgba(0, 0, 0, 0.34) 0px 3px 10px;border:4px solid #fff; background-color: #0F5589;">
                                   <v:/fill>
                                </v:oval>
                                <![endif]-->
                                <!--[if !mso]> <!-->
                                   <div style="width: 61px;height: 61px;border-radius: 50%;box-shadow: rgba(0, 0, 0, 0.34) 0px 3px 10px;border:4px solid #fff; background-image: url(${get(
                                     user,
                                     "avatar_url",
                                     ""
                                   )}); background-size: cover; background-position: center;     background-repeat: no-repeat; background-color: #0F5589">
                                  ${
                                    get(user, "avatar_url", "")
                                      ? ""
                                      : `<span style="font-family: Muli, Arial, Verdana, Tahoma, Geneva; color: #ffffff; font-size: 20px; line-height: 61px; text-decoration: none; white-space: nowrap; font-weight: bold;text-transform: uppercase; display: block; text-align: center;">${getSortUserName(
                                          get(user, "name", "")
                                        )}</span>`
                                  }
                                  </div>
                              <!-- <![endif]-->
                            </td>
                            <td style="width:15px;">&nbsp;</td>
                            <td>
                              <div style="height: 30px; line-height: 30px;">&nbsp;</div>
                              <div>
                                  <font face="Arial, sans-serif, Geneva" color="#FFFFFF" style="font-size: 16px; line-height: 20px;color: #111B2B;">
                                    <span style="font-family: Arial, sans-serif, Geneva; color: #111B2B; font-size: 16px; line-height: 20px;">
                                      ${get(user, "name", "")}
                                    </span>
                                  </font>
                                </div>
                                ${
                                  user.title
                                    ? `
                                    <div>
                                      <font face="Arial, sans-serif, Geneva" color="#FFFFFF" style="font-size: 16px; line-height: 20px;color: #111B2B;">
                                        <span style="font-family: Arial, sans-serif, Geneva; color: #111B2B; font-size: 16px; line-height: 20px;">
                                          ${user.title}        
                                        </span>
                                      </font>
                                    </div>
                                    `
                                    : null
                                }
                                <div>
                                  <font face="Arial, sans-serif, Geneva" color="#FFFFFF" style="font-size: 16px; line-height: 20px;color: #111B2B;">
                                    <span style="font-family: Arial, sans-serif, Geneva; color: #111B2B; font-size: 16px; line-height: 20px;">
                                      ${broadcastingCompany}
                                    </span>
                                  </font>
                                </div>
                            </td>
                        </tr>
                    </table>
                </td>
             </tr>
          </table>
       </body>
    </html>  
  `;
};
export default injectIntl(
  compose(
    connect(
      (state, props) => {
        let stepKey = get(props, "step.key");
        let title = get(props, "process.data.fields.order_title.value");
        let subject = {
          approve_recording: `${title} Recording for Review`,
          approve_script: `${title} Script for Review`,
          write_script: `${title} Script for Review`,
          record_script: `${title} Recording for Review`,
          review_media: `${title} for Review`,
        };
        return {
          isSpotReview: get(props, "is_spot_review", true),
          producerSendsSpotToClient: get(
            props,
            "producer_sends_spot_to_client",
            false
          ),

          outline: get(props, "outline", true),
          isSaveOrder: get(props, "is_save_order", false),
          subject: subject[stepKey],
          message: get(props, "process.data.loaded_fields.email_link.value"),
          process_id: get(props, "process.data.loaded_fields.key.value"),
          user: get(state, "auth.user"),
          userInfo: get(state, "auth.info"),
          dateFormatByServer: get(
            state,
            "servers.currentServer.data.date_format",
            "MM/DD/YYYY"
          ),
        };
      },
      {
        sendMail,
        getProcess,
        getDefaultApprovalContacts,
      }
    ),
    withState("isLoading", "setIsLoading", false),
    withState("isOpenDialog", "setIsOpenDialog", false),
    withState("contacts", "setContacts", []),
    withHandlers({
      onSendEmail: ({
        sendMail,
        setIsLoading,
        setIsOpenDialog,
        process,
        user,
        userInfo,
        intl,
        isSpotReview,
        dateFormatByServer,
        getProcess,
        producerSendsSpotToClient,
      }) => async (data) => {
        const toEmails = split(data.to, ",").map((item) => trim(item));
        const ccEmails = data.cc
          ? split(data.cc, ",").map((item) => trim(item))
          : [];
        const { status, message } = await sendMail({
          ...data,
          to: toEmails,
          cc: ccEmails.length > 0 ? ccEmails : "",
          message: generateEmailHtml(
            process,
            user,
            userInfo,
            intl,
            data,
            isSpotReview,
            dateFormatByServer,
            producerSendsSpotToClient
          ),
        });
        setIsLoading(false);
        ToastManager.show({
          title: <MdImportantDevices />,
          message,
          autoDismiss: 1.5,
          level: status ? "success" : "error",
        });
        if (status) {
          setIsOpenDialog(false);
        }
        if (!user.return_to_current_tasks) {
          if (get(data, "process_id")) {
            getProcess({ process_id: get(data, "process_id") });
          }
          return;
        }
        delay(() => {
          history.replace(`/processes/my_action_items`);
        }, 4000);
      },
    }),
    withHandlers({
      toggleOpenDialog: ({ isOpenDialog, setIsOpenDialog }) => async () =>
        setIsOpenDialog(!isOpenDialog),
      onFormSubmit: ({
        process,
        user,
        isSaveOrder,
        buttonClicked,
        step,
        template,
        onSendEmail,
        setIsLoading,
      }) => async (data) => {
        setIsLoading(true);
        if (isSaveOrder) {
          buttonClicked(
            step,
            step.fields.find((f) => f.key === "save_button"),
            process,
            template,
            user,
            null,
            () => {
              onSendEmail(data);
            },
            true
          );
          return;
        } else {
          onSendEmail(data);
        }
      },
    }),
    lifecycle({
      componentDidMount() {
        const clientId = get(
          this.props,
          "process.data.fields.client_id.value",
          ""
        );
        if (clientId) {
          this.props.getDefaultApprovalContacts(clientId, (response) => {
            if (response) {
              const contacts = map(response, (item) => ({
                ...item._source,
              }));
              this.props.setContacts(contacts || []);
            }
          });
        }
      },
    })
  )(View)
);
