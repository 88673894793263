import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import ContentProductionArchiveView from "./view";
import { get } from "lodash";
export default injectIntl(
  connect((state, props) => {
    return {
      stations: get(state, "auth.info.stations", []),
      ...props,
    };
  }, {})(ContentProductionArchiveView)
);
