import { injectIntl } from "react-intl";
import { compose, withState, lifecycle, withHandlers } from "recompose";
import { get, forEach, isEmpty, isEqual } from "lodash";
import Calendar from "./view";


export default injectIntl(compose(
	withState("isShowModalOpen", "setIsShowModalOpen", false),
	withState("selectedSlot", "setSelectedSlot", null),
	withState("selectedShow", "setSelectedShow", null),
	withState("overflowingShows", "setOverflowingShows", []),
	withHandlers({
		findOverflowingShows: ({ clock, setOverflowingShows }) => () => {
			const shows = get(clock, "data.slots", []);
			const overflowingShows = [];
			forEach(shows, show => {
				const showElements = document.getElementsByClassName(`show-${show.id}`);
				if (showElements) {
					const showElement = showElements[0];
					if (showElement) {
						const height = showElement.scrollHeight;
						let maxHeight = showElement.style.maxHeight;
						maxHeight = Math.ceil(parseFloat(maxHeight.split("px")[0]));
						if (height > maxHeight) {
							overflowingShows.push(show.id);
						}
					}
				}
			});

			if (!isEmpty(overflowingShows)) {
				setOverflowingShows(overflowingShows);
			}
		}
	}),
	lifecycle({
		componentDidMount() {
			this.props.findOverflowingShows();
		},
		componentDidUpdate(prevProps) {
			const prevShows = get(prevProps.clock, "data.slots", []);
			const shows = get(this.props.clock, "data.slots", []);
			if (!isEqual(prevShows, shows)) {
				this.props.findOverflowingShows();
			}
		}
	})
)(Calendar));
