import View from "./view";
import { compose, withState, lifecycle } from "recompose";
import { isEqual } from "lodash";
export default compose(
	withState("items", "setItems", ({ data }) => data),
	lifecycle({
		componentDidUpdate() {
			if (!isEqual(this.props.data, this.props.items)) {
				this.props.setItems(this.props.data);
			}
		},
	})
)(View);
