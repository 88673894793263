import View from "./view";
import { connect } from "react-redux";
import { compose, withState, lifecycle } from "recompose";
import { get } from "lodash";
import { injectIntl } from "react-intl";
import { getAssignedHubs } from "store/actions/hubs";

export default injectIntl(
  compose(
    connect(
      (state) => ({
        auth: get(state, "auth"),
        assignedHubs: get(state, "hubs.assignedHubs.data.data", []),
        isLoading:
          get(state, "hubs.assignedStations.loading", false) ||
          get(state, "hubs.orderAssignments.loading", false) ||
          get(state, "hubs.assignedUsers.loading", false),
      }),
      { getAssignedHubs }
    ),
    withState("shouldBlockNavigation", "setShouldBlockNavigation", false),
    withState("currentHub", "setCurrentHub", null),
    withState("activeTab", "setActiveTab", "stations"),
    lifecycle({
      componentDidMount() {
        this.props.getAssignedHubs();
      },
    })
  )(View)
);
