import React from "react";
import { injectIntl } from "react-intl";
import View, { getCategories, dateTimeFormat } from "./view";
import { connect } from "react-redux";
import { compose, lifecycle, withHandlers, withState } from "recompose";
import { MdImportantDevices } from "react-icons/lib/md";
import ToastManager from "components/ToastManager";
import {
  updateWinnerNotification,
  addWinnerNotification,
  deleteWinnerNotification,
} from "store/actions/winners";
import { omit, get, startCase } from "lodash";
import moment from "moment";
import { fetchEmailTemplates } from "store/actions/systemTemplates";
export { getCategories };
const Enhanced = compose(
  connect(
    (state, props) => {
      return {
        ...props,
        emailTemplates: get(
          state,
          "systemTemplates.emailTemplates.data.data",
          []
        ),
        isDirtySystemTemplateForms: get(
          startCase,
          "systemTemplates.emailTemplates.is_dirty",
          false
        ),
      };
    },
    {
      updateWinnerNotification,
      addWinnerNotification,
      deleteWinnerNotification,
      fetchEmailTemplates,
    }
  ),
  withState("currentKey", "setCurrentKey", Date.now()),
  withState("isOpenConfirmationModal", "setIsOpenConfirmationModal", false),
  withState("confirmationType", "setConfirmationType", null),
  withState("isOpenSuccessModal", "setIsOpenSuccessModal", false),
  withHandlers({
    onSubmit: ({
      updateWinnerNotification,
      addWinnerNotification,
      intl,
      setIsOpenSuccessModal,
      onClose,
      onUploadedContestImage,
    }) => (values) => {
      const params = omit(values, ["schedule", "template_id"]);
      if (
        values.type === "scheduled" ||
        (values.type === "draft" && values.schedule)
      ) {
        params.schedule = moment(values.schedule).format(dateTimeFormat);
      }
      if (values.template_id) {
        params.template_id = values.template_id;
      }

      if (params.id) {
        updateWinnerNotification(params, () => {
          ToastManager.show({
            title: <MdImportantDevices />,
            message: intl.formatMessage({
              id: `process > promotion > contest > updated successfully`,
            }),
            autoDismiss: 1.5,
            level: "success",
          });
          onUploadedContestImage(params.contest_image);
          onClose();
        });
      } else {
        addWinnerNotification(params, () => {
          if (params.type === "immediate") {
            setIsOpenSuccessModal(true);
          } else {
            ToastManager.show({
              title: <MdImportantDevices />,
              message: intl.formatMessage({
                id: `process > promotion > contest > created successfully`,
              }),
              autoDismiss: 1.5,
              level: "success",
            });
          }
          onUploadedContestImage(params.contest_image);
          onClose();
        });
      }
    },
    onDelete: ({
      deleteWinnerNotification,
      emailTemplate,
      intl,
      onClose,
      setIsOpenConfirmationModal,
      setConfirmationType,
    }) => () => {
      setConfirmationType(null);
      setIsOpenConfirmationModal(false);
      deleteWinnerNotification(emailTemplate.id, () => {
        ToastManager.show({
          title: <MdImportantDevices />,
          message: intl.formatMessage({
            id: `process > promotion > contest > deleted successfully`,
          }),
          autoDismiss: 1.5,
          level: "success",
        });
        onClose();
      });
    },
  }),
  lifecycle({
    componentDidMount() {
      if (this.props.station) {
        this.props.fetchEmailTemplates({
          station_id: this.props.station.station_key,
        });
      }
    },
    componentDidUpdate() {
      if (this.props.isDirtySystemTemplateForms && this.props.station) {
        this.props.fetchEmailTemplates({
          station_id: this.props.station.station_key,
        });
      }
    },
  })
)(View);
export default injectIntl(Enhanced);
