import * as Types from "../types/teams";
import { get, uniqBy } from "lodash";

const initialState = {
  teams: {
    data: [],
    error: null,
    loading: false,
    is_dirty: false,
  },
  allStationsAndTeams: {
    data: [],
    error: null,
    loading: false,
    is_dirty: false,
  },
  saveTeam: {
    is_added: false,
    data: null,
    error: null,
    loading: false,
  },
  viewedTeams: {
    data: [],
    error: null,
    loading: false,
    is_dirty: false,
  },
  deleteTeam: {
    is_deleted: false,
    data: null,
    error: null,
    loading: false,
  },
  membersTeams: {
    error: null,
    loading: false,
    is_dirty: false,
  },
  updateTeamLead: {
    error: null,
    loading: false,
    is_dirty: false,
  },
  teamsAndLeads: {
    data: [],
    error: null,
    loading: false,
    is_dirty: false,
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_TEAMS_REQUEST: {
      return {
        ...state,
        teams: {
          data: get(state, "teams.data"),
          error: null,
          loading: true,
          is_dirty: false,
        },
      };
    }

    case Types.FETCH_TEAMS_SUCCESS: {
      return {
        ...state,
        teams: {
          data: action.payload,
          error: null,
          loading: false,
          is_dirty: false,
        },
      };
    }

    case Types.FETCH_TEAMS_ERROR: {
      return {
        ...state,
        teams: {
          data: null,
          error: action.payload,
          loading: false,
          is_dirty: false,
        },
      };
    }

    case Types.FETCH_MORE_TEAMS_REQUEST: {
      return {
        ...state,
        teams: {
          ...state.teams,
          lazyLoad: true,
        },
      };
    }
    case Types.FETCH_MORE_TEAMS_SUCCESS: {
      const { data: newData, ...rest } = action.payload;

      return {
        ...state,

        teams: {
          ...state.teams,
          data: {
            data: uniqBy(
              [...get(state, "teams.data.data", []), ...newData],
              "id"
            ),
            ...rest,
          },
          lazyLoad: false,
        },
      };
    }
    case Types.FETCH_MORE_TEAMS_ERROR: {
      return {
        ...state,
        teams: {
          ...state.teams,
          lazyLoad: false,
        },
      };
    }

    case Types.FETCH_ALL_STATIONS_AND_TEAMS_REQUEST: {
      return {
        ...state,
        allStationsAndTeams: {
          data: get(state, "allStationsTeams.data"),
          error: null,
          loading: true,
          is_dirty: false,
        },
      };
    }

    case Types.FETCH_ALL_STATIONS_AND_TEAMS_SUCCESS: {
      return {
        ...state,
        allStationsAndTeams: {
          data: action.payload,
          error: null,
          loading: false,
          is_dirty: false,
        },
      };
    }

    case Types.FETCH_ALL_STATIONS_AND_TEAMS_ERROR: {
      return {
        ...state,
        allStationsAndTeams: {
          data: null,
          error: action.payload,
          loading: false,
          is_dirty: false,
        },
      };
    }

    case Types.SAVE_TEAM_REQUEST: {
      return {
        ...state,
        saveTeam: {
          is_added: false,
          loading: true,
          error: null,
        },
      };
    }

    case Types.SAVE_TEAM_SUCCESS: {
      return {
        ...state,
        teams: {
          ...get(state, "teams"),
          is_dirty: true,
        },
        saveTeam: {
          is_added: true,
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    }

    case Types.SAVE_TEAM_ERROR: {
      return {
        ...state,
        saveTeam: {
          is_added: false,
          loading: false,
          error: action.payload,
        },
      };
    }

    case Types.FETCH_TEAM_REQUEST: {
      const existingTeams = get(state, "viewedTeams", {});
      const { id, data } = action.payload;

      return {
        ...state,
        viewedTeams: {
          ...existingTeams,
          [id]: {
            data: data,
            error: null,
            loading: true,
          },
        },
      };
    }

    case Types.FETCH_TEAM_SUCCESS: {
      const existingTeams = get(state, "viewedTeams", {});
      const { id, data } = action.payload;

      return {
        ...state,
        viewedTeams: {
          ...existingTeams,
          [id]: {
            data: data,
            error: null,
            loading: false,
          },
        },
      };
    }

    case Types.FETCH_TEAM_ERROR: {
      const existingTeams = get(state, "viewedTeams", {});
      const { id, error } = action.payload;

      return {
        ...state,
        viewedTeams: {
          ...existingTeams,
          [id]: {
            error: error,
            loading: false,
          },
        },
      };
    }

    case Types.UPDATE_TEAM_REQUEST: {
      return {
        ...state,
        updateTeam: {
          loading: true,
          error: null,
        },
      };
    }

    case Types.UPDATE_TEAM_SUCCESS: {
      const existingTeams = get(state, "viewedTeams", {});

      const { id, data } = action.payload;

      return {
        ...state,
        teams: {
          ...get(state, "teams"),
          is_dirty: true,
        },
        updateTeam: {
          data: action.payload,
          loading: false,
          error: null,
        },
        viewedTeams: {
          ...existingTeams,
          [id]: {
            data: data,
            error: null,
            loading: false,
          },
        },
      };
    }

    case Types.UPDATE_TEAM_ERROR: {
      return {
        ...state,
        updateTeam: {
          loading: false,
          error: action.payload,
        },
      };
    }

    case Types.DELETE_TEAM_REQUEST: {
      return {
        ...state,
        deleteTeam: {
          is_deleted: false,
          loading: true,
          error: null,
        },
      };
    }

    case Types.DELETE_TEAM_SUCCESS: {
      return {
        ...state,
        teams: {
          ...get(state, "teams"),
          is_dirty: true,
        },
        deleteTeam: {
          is_deleted: true,
          loading: false,
          error: null,
        },
      };
    }
    case Types.DELETE_TEAM_ERROR: {
      return {
        ...state,
        deleteTeam: {
          is_deleted: false,
          loading: false,
          error: action.payload,
        },
      };
    }
    case Types.FETCH_TEAM_MEMBERS_REQUEST: {
      const { id } = action.payload;
      return {
        ...state,
        membersTeams: {
          ...get(state, "membersTeams", {}),
          [id]: {
            data: get(state, `membersTeams.${id}.data`, {}),
            error: null,
            loading: true,
          },
        },
      };
    }

    case Types.FETCH_TEAM_MEMBERS_SUCCESS: {
      const { id, data } = action.payload;
      let membersData = data;
      const isMore = get(action, "payload.page") > 1;
      if (isMore) {
        membersData = {
          ...data,
          data: [
            ...get(state, `membersTeams.${id}.data.data`, []),
            ...get(action, "payload.data.data", []),
          ]
        }
      }
      return {
        ...state,
        membersTeams: {
          ...get(state, "membersTeams", {}),
          [id]: {
            data: membersData,
            error: null,
            loading: false,
          },
        },
      };
    }

    case Types.FETCH_TEAM_MEMBERS_ERROR: {
      const existingTeams = get(state, "membersTeams", {});
      const { id, error } = action.payload;

      return {
        ...state,
        membersTeams: {
          ...existingTeams,
          [id]: {
            error: error,
            loading: false,
          },
        },
      };
    }
    case Types.UPDATE_TEAM_LEAD_REQUEST: {
      return {
        ...state,
        updateTeamLead: {
          loading: true,
          error: null,
        },
      };
    }

    case Types.UPDATE_TEAM_LEAD_SUCCESS: {
      const existingTeams = get(state, "membersTeams", {});
      const { id, user_id } = action.payload;

      return {
        ...state,
        membersTeams: {
          ...existingTeams,
          [id]: {
            ...get(state, `membersTeams.${id}`, {}),
            data: {
              ...get(state, `membersTeams.${id}.data`, {}),
              data: get(state, `membersTeams.${id}.data.data`, []).map((user) => ({
                ...user,
                is_team_lead: user.id === user_id ? true : false,
              })),
            }
            
          },
        },
        updateTeamLead: {
          loading: false,
          error: null,
        },
      };
    }
    case Types.UPDATE_TEAM_LEAD_ERROR: {
      return {
        ...state,
        updateTeamLead: {
          loading: false,
          error: action.payload,
        },
      };
    }
    case Types.FETCH_TEAMS_AND_LEADS_REQUEST: {
      return {
        ...state,
        teamsAndLeads: {
          data: [],
          is_dirty: false,
          loading: true,
          error: null,
        },
      };
    }

    case Types.FETCH_TEAMS_AND_LEADS_SUCCESS: {
      return {
        ...state,
        teamsAndLeads: {
          ...get(state, 'teamsAndLeads', {}),
          data: get(action, 'payload', []),
          loading: false,
        },
      };
    }
    case Types.FETCH_TEAMS_AND_LEADS_ERROR: {
      return {
        ...state,
        teamsAndLeads: {
          data: [],
          is_dirty: false,
          loading: true,
          error: action.payload,
        },
      };
    }
    default:
      return state;
  }
}
