import React, { Component } from "react";
import Tabs from "./tabsIndex";
import { Button } from "reactstrap";
import { FormattedMessage } from "react-intl";

class Editor extends Component {
  constructor(props) {
    super(props);
    this.setState({
      editor_value: false,
      is_valid: true
    });
  }
  stringify (obj) {
    let nl=`
    `
    let s = JSON.stringify(obj,function(key, value){
      if(typeof value === 'object' && value && value.type && value.type.name==='FormattedMessage') {
        return '[FM]: '+ value.props.id
      }
      return (typeof value === 'function' ) ? (''+value.toString()).replace(/\\n/g,nl)
       : value;
    }, 2);
    return s;
  }
    
  parse (str) {
    let nl=`
    `
    // while(str.indexOf(nl)>-1) 
    //   str = str.replace(nl,'\\\\n');

      
    let p=JSON.parse(str,function(key, value){
      if(value && value.indexOf && value.indexOf('[FM]: ')===0)
        return <FormattedMessage id={value.replace('[FM]: ','')} />
      if(typeof value != 'string') return value;
      if ( value.substring(0,8) == 'function') { 
        // while(value.indexOf(nl)>-1) 
        //   value = value.replace(nl,' ');
          try{
        return eval('('+value+')')
          } catch(e){
            debugger
          }
      }
      return value;
    });
    return p
  }
  
  clone(obj) {
    return this.parse(this.stringify(obj));
    if (null == obj || "object" != typeof obj) return obj;
    var copy = obj.constructor();
    for (var attr in obj) {
        if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
    }
    return copy;
  }

  componentWillUpdate(nextProps) {
    if (!this.state) {
      this.setState({
        editor_value: false,
        is_valid: true
      });
      return null;
    }

    let template = this.props.template;
    if (template && this.state.editor_value === false) {
      this.setState({
        editor_value: this.stringify(template, null, 2),
        is_valid: true
      });
    }
  }

  update(template){
      let str = this.stringify(template);
      this.setState({ editor_value: str });
      this.editorChanged({target:{value:str}})
      this.props.updateCurrentTemplate(template);
      this.forceUpdate();
  }
  
  editorChanged(event) {
    let newValue = event.target.value;
    this.setState({ editor_value: newValue });
    try {
      this.parse(newValue);
      this.setState({ is_valid: true });
    } catch (e) {
      this.setState({ is_valid: false });
      return;
    }
    if (this.stringify(this.props.template) !== newValue) {
      let p=this.parse(newValue);
      this.props.updateCurrentTemplate(p);
    } else {
    }
  }

  saveTemplate() {
    let newValue = this.state.editor_value;
    try {
      this.parse(newValue);
    } catch (e) {
      return;
    }
    let payload = this.parse(newValue);
    payload.active = true;
    this.props.saveTemplate(payload);
  }

  render() {
    if (!this.state) {
      return null;
    }
    let template = this.props.template;
    if (!template) return null;
    let process = this.props.process;
    process.data.fields.template_key=template.key;
    let cls = ["container ", !this.state.is_valid ? " text-danger" : ""];
    let editor = (
      <div className="container">
        <textarea
          className={cls}
          rows="10"
          value={this.state.editor_value}
          onChange={this.editorChanged.bind(this)}
        />
        {this.state.editor_value && this.state.is_valid && (
          <Button onClick={this.saveTemplate.bind(this)}>Save</Button>
        )}
        <Tabs process={process} template={template} editMode={this}/>
      </div>
    );
    return editor;
  }
}

export default Editor;
