import React from "react";
import { ListGroup, ListGroupItem, Button, Collapse, Row } from "reactstrap";
import { has, keys, isEmpty, values, isEqual } from "lodash";
import classnames from 'classnames';
import { FaInfoCircle } from 'react-icons/lib/fa';

import Checkbox from "components/Checkbox";
import { ArrowRightIcon, DownIcon } from "components/CustomIcons";
import StationsList from "../StationsList";
import TeamWarningModal from 'components/TeamsList/TeamWarningModal';


const TagsList = ({
  tagType,
  tags,
  allowStationsSelect,
  expandedRow,
  expandRow,
  selectedTags,
  updateSelectedTags,
  selectedStations,
  setSelectedStations,
  updateSelectedStations,
  selectedTeamId,
  isTeamWarningModalOpen,
  onToggleTeamWarningModal,
  openTeamWarningModal,
}) => {

  const tagsList = values(tags).map((tag) => {

    const {id} = tag;
    const isOpen = expandedRow === id;

    const displayTagName = (tagType, {primaryKey, label, stations, showWarning}) => {

      if(!isEqual(tagType, 'team') && !isEqual(tagType, 'audience')){
        return label;
      }

      return showWarning ? (
        <>
          <span style={{marginRight: '15px'}}>{`${label} (${keys(stations).length})`}</span>
          <FaInfoCircle size={17} 
            onClick={() => openTeamWarningModal(primaryKey)} 
            color="#E40061"/>
        </>
      ) : <span>{`${label} (${keys(stations).length})`}</span>;
    };

    return (
      <ListGroupItem key={id}>
        <div className={classnames({open : isOpen}, 'tag-line-item')}>
          <Checkbox
            checked={has(selectedTags, id)}
            text={displayTagName(tagType, tag)}
            onChange={checked => updateSelectedTags(checked, tag, tagType)}
            disableSelectOnTextClick={true}
            checkStyle={{color: "#fff", width: '15', height:'14'}}
          />
          <Button
            color="link"
            className="expand-btn"
            onClick={(e) => expandRow(id)}
          >
            {!isOpen ? (
              <ArrowRightIcon color="#C2D4E0" width={10} height={14} />
            ) : (
              <DownIcon color="#C2D4E0" width={14} height={18} />
            )}
          </Button>
        </div>
        <Collapse isOpen={isOpen}>
          {isOpen && (
          	<StationsList 
          		tag={tag}
              allowStationsSelect={allowStationsSelect}
          		selectedTags={selectedTags}
          		selectedStations={selectedStations}
          		setSelectedStations={setSelectedStations}
          		updateSelectedStations={updateSelectedStations}
          	/>
          )}
        </Collapse>
      </ListGroupItem>
    );
  });

  if(isEmpty(tagsList)){
    return <div style={{padding: '30px'}}>{`No ${tagType}s found`}</div>
  }

  return (
    <div className="tags-selection-list">
      <ListGroup>{tagsList}</ListGroup>
      {isTeamWarningModalOpen  && (
        <TeamWarningModal
          isOpen={isTeamWarningModalOpen}
          toggle={onToggleTeamWarningModal}
          selectedTeamId={selectedTeamId}
        />
      )}
    </div>
  );
};

export default TagsList;
