import React, { useEffect } from "react";
import PropTypes from "prop-types";
import bn from "utils/bemnames";
import classNames from "classnames";
import { get } from "lodash";
import { ArrowRightIcon } from "components/CustomIcons";
// import useOutsideClick from "utils/useOutsideClick";
import { useRef } from "react";

const bem = bn.create("right-sidebar");
function RightSidebar(props) {
  const { onCancel, isOpen, children, type, process } = props;
  const clientName = get(process, "_source.client_name", "");
  const orderTitle = get(process, "_source.order_title", "");
  const containerRef = useRef();
  // useOutsideClick(containerRef, (event) => {
  //   const openModal = document
  //     .querySelector("body")
  //     .classList.contains("modal-open");
  //   const isClickDropdownIcon = event.target
  //     ? event.target.classList.contains("disable-unsaved")
  //     : false;
  //   if (!openModal && !isClickDropdownIcon) {
  //     onCancel();
  //   }
  // });
  useEffect(() => {
    const appMain = document.getElementById("cr-app-main");
    if (!appMain) return;
    if (isOpen) appMain.classList.add("right-sidebar-open");
    if (!isOpen) appMain.classList.remove("right-sidebar-open");
  }, [isOpen]);
  return (
    <div ref={containerRef}>
      <div className={bem.b()}>
        {isOpen && (
          <div className={bem.e("background")} onClick={() => onCancel()}></div>
        )}
        <div
          className={classNames(bem.e("wrapper"), bem.e(type), {
            [bem.e("open")]: isOpen,
            [bem.e("close")]: !isOpen,
          })}
          id="right-sidebar"
        >
          <div className={bem.e("header")}>
            <div className={bem.e("btn-close")} onClick={() => onCancel()}>
              <ArrowRightIcon color="#8165F3" />
            </div>
            <div className={bem.e("title")}>
              <h3>{clientName}</h3>
              <span>{orderTitle}</span>
            </div>
          </div>
          {isOpen && children}
        </div>
      </div>
    </div>
  );
}
RightSidebar.defaultProps = {
  isOpen: false,
  onCancel: () => {},
  children: null,
  type: "",
  process: {},
};
RightSidebar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  children: PropTypes.element,
  type: PropTypes.string,
  process: PropTypes.object,
};
export default RightSidebar;
