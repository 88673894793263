import * as React from "react";
import { Col } from "reactstrap";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { setScriptField, getFileNameFromUrl } from "utils/helpers";
import TextareaEvaluator, {
  DEFAULT_WORDS_PER_MIN,
} from "components/TextareaEvaluator";
import { get, first } from "lodash";
import bn from "utils/bemnames";
import FileView from "components/FileView";
import UploadFile from "components/UploadFile";
const bem = bn.create("order-script");

function RenderScriptEditor(props) {
  const {
    process,
    template,
    user,
    isView,
    valueEvaluatorChanged,
    step,
    spot,
    isBookend,
    scriptPart,
    feedbackField,
  } = props;
  const refreshTextEditor = get(process, 'data.fields.refreshed_at.value', 0)
  return (
    <div
      className={classnames(bem.e("form-div"), bem.e("form-div-draft-script"))}
    >
      {!isBookend ? (
        <TextareaEvaluator
          readOnly={isView}
          isEditorOnly={true}
          hideOrderLength={true}
          text={setScriptField(
            get(process, "data.fields.script_feedback.value", "")
          )}
          wordsPerMin={get(
            spot,
            "default_words_per_min",
            DEFAULT_WORDS_PER_MIN
          )}
          key={refreshTextEditor}
          process={process}
          onEvaluatorCallback={(value) => {
            valueEvaluatorChanged(
              step.fields.find((f) => f.field === "script_feedback"),
              process,
              template,
              user,
              value
            );
          }}
          title={<FormattedMessage id="process > field script feedback" />}
          showInnerTitle={true}
        />
      ) : (
        <TextareaEvaluator
          readOnly={isView}
          isEditorOnly={true}
          hideOrderLength={true}
          text={setScriptField(
            get(process, `data.fields.${feedbackField}.value`, "")
          )}
          process={process}
          spot={spot}
          wordsPerMin={
            scriptPart === "a"
              ? get(spot, "default_words_per_min", DEFAULT_WORDS_PER_MIN)
              : get(spot, "default_words_per_min_b", DEFAULT_WORDS_PER_MIN)
          }
          key={refreshTextEditor}
          onEvaluatorCallback={(value) => {
            valueEvaluatorChanged(
              step.fields.find((f) => f.field === feedbackField),
              process,
              template,
              user,
              value
            );
          }}
          title={
            <FormattedMessage
              id={
                scriptPart === "a"
                  ? "process > field script part A feedback"
                  : "process > field script part B feedback"
              }
            />
          }
          isBookend={isBookend}
          part={scriptPart}
          showInnerTitle={true}
        />
      )}
    </div>
  );
}
function RenderUploadFile(props) {
  const {
    process,
    intl,
    isBookend,
    isView,
    feedbackField,
    scriptPart,
    valueChanged,
    step,
    template,
    user,
  } = props;
  const fileViewActions = isView
    ? ["view", "download"]
    : ["view", "rename", "download", "trash"];
  const feedbackValue = get(process, `data.fields.${feedbackField}.value`, "");
  return (
    <div className={bem.e("script-upload-feedback")}>
      <div className={bem.e("part-upload-title")}>
        {isBookend ? (
          <FormattedMessage
            id={`process > field script feedback part ${scriptPart}`}
          />
        ) : (
          <FormattedMessage id="process > field script feedback" />
        )}
      </div>
      {feedbackValue ? (
        <React.Fragment>
          <FileView
            file={{
              path: feedbackValue,
              name: getFileNameFromUrl(feedbackValue),
            }}
            actions={fileViewActions}
            onDeleted={() => {
              valueChanged(
                step.fields.find((f) => f.field === feedbackField),
                process,
                template,
                user,
                {
                  value: "",
                }
              );
            }}
            onUploaded={(files) => {
              const file = first(files);
              valueChanged(
                step.fields.find((f) => f.field === feedbackField),
                process,
                template,
                user,
                {
                  value: get(file, "path", ""),
                }
              );
            }}
          />
        </React.Fragment>
      ) : (
        <UploadFile
          mode={"edit"}
          process={process}
          onUploaded={(value) => {
            valueChanged(
              step.fields.find((f) => f.field === feedbackField),
              process,
              template,
              user,
              {
                value: value.path,
              }
            );
          }}
          attachText={intl.formatMessage({
            id: "process > placeholder attach revised script",
          })}
          isDisabledDelete={true}
          onDeleted={() => {}}
          isCheckMimesByExt={true}
          extensions={["doc", "docx", "pdf"]}
          url={feedbackValue}
          actions={["download"]}
          positionActions="bottom"
          isShowFileNameInEditMode={true}
          hideSpotLength={true}
          path={scriptPart}
          isBookend={isBookend}
        >
          <FormattedMessage id="process > button upload" />
        </UploadFile>
      )}
    </div>
  );
}
const OrderScriptReviewFeedback = (props) => {
  const {
    process,
    template,
    user,
    isView,
    valueEvaluatorChanged,
    step,
    spot,
    isBookend,
    scriptPart,
    feedbackField,
    isUploadFile,
    intl,
    valueChanged,
  } = props;
  return (
    <Col className={classnames(bem.e("col-field"), "script-feedback")}>
      {isUploadFile ? (
        <RenderUploadFile
          process={process}
          template={template}
          user={user}
          isView={isView}
          valueEvaluatorChanged={valueEvaluatorChanged}
          step={step}
          spot={spot}
          isBookend={isBookend}
          scriptPart={scriptPart}
          feedbackField={feedbackField}
          intl={intl}
          valueChanged={valueChanged}
        />
      ) : (
        <RenderScriptEditor
          process={process}
          template={template}
          user={user}
          isView={isView}
          valueEvaluatorChanged={valueEvaluatorChanged}
          step={step}
          spot={spot}
          isBookend={isBookend}
          scriptPart={scriptPart}
          feedbackField={feedbackField}
        />
      )}
    </Col>
  );
};

export default OrderScriptReviewFeedback;
