import React from "react";
import { get, map, filter, find } from "lodash";
import Checkbox from "components/Checkbox";
import { FormattedMessage, injectIntl } from "react-intl";
import { FormGroup, Row, Col, Label } from "reactstrap";
import ServerFormSection from "./ServerFormSection";
import CustomMultiSelectDropdown from "components/CustomMultiSelectDropdown";
import { countryList } from "utils/config";

const ListenerDatabaseGeography = (props) => {
  const { bem, edit, serverDetails, intl, optionCountries } = props;
  const renderEdit = () => {
    const { formProps } = props;
    const { values, errors } = formProps;

    return (
      <Row>
        <Col xs={6}>
          <FormGroup>
            <Checkbox
              checked={values.restrict_to_home_country}
              onChange={(checked) => {
                if (checked) {
                  formProps.setFieldValue("neighboring_countries", []);
                }
                formProps.setFieldValue("restrict_to_home_country", checked);
              }}
              text={intl.formatMessage({
                id: "server > restrict to home country",
              })}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <Row>
            <Col xs={4}>
              <FormGroup>
                <Label>
                  <FormattedMessage id="server > neighboring countries" />
                </Label>
                <CustomMultiSelectDropdown
                  name="neighboring_countries"
                  value={map(values.neighboring_countries, (item) => {
                    const country = find(countryList, (i) => i.value === item);
                    return (
                      country || {
                        label: item,
                        value: item,
                      }
                    );
                  })}
                  options={optionCountries}
                  placeholder={intl.formatMessage({
                    id: "server > please select",
                  })}
                  isDisabled={values.restrict_to_home_country}
                  isAllowAllOption={false}
                  isMulti={true}
                  error={get(errors, "neighboring_countries")}
                  onChange={(value) => {
                    const newValue = filter(value, (item) =>
                      find(optionCountries, (i) => i.value === item.value)
                    ).map((item) => item.value);
                    formProps.setFieldValue("neighboring_countries", newValue);
                  }}
                />
              </FormGroup>
            </Col>
            <Col xs={3}>
              <span className={bem.e("help-text")}>
                <FormattedMessage id="server > neighboring countries help text" />
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const renderView = () => {
    return (
      <React.Fragment>
        <Row>
          <Col xs={6}>
            <FormGroup>
              <Checkbox
                checked={serverDetails.restrict_to_home_country}
                disabled={true}
                onChange={() => {}}
                text={intl.formatMessage({
                  id: "server > restrict to home country",
                })}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <FormGroup>
              <label className={bem.e("server-view-label")}>
                <FormattedMessage id="server > neighboring countries" />
              </label>
              <div className={bem.e("server-view-value")}>
                {serverDetails.neighboring_countries &&
                serverDetails.neighboring_countries.length > 0 ? (
                  serverDetails.neighboring_countries
                    .map((item) => {
                      const itemData = find(
                        countryList,
                        (i) => i.value === item
                      );
                      return itemData ? itemData.label : "";
                    })
                    .join(", ")
                ) : (
                  <FormattedMessage id="server > none" />
                )}
              </div>
            </FormGroup>
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  return (
    <ServerFormSection
      bem={bem}
      titleId="server > title listener database geography"
      sectionClass={bem.e("box-server-details")}
      sectionFieldsClass={bem.e("section-view-server-configuration-fields")}
    >
      {edit ? renderEdit() : renderView()}
    </ServerFormSection>
  );
};

export default injectIntl(ListenerDatabaseGeography);
