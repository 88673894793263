import React from "react";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import { FaSortDesc, FaSortAsc } from "react-icons/lib/fa";
import { Table, Button } from "reactstrap";
import Checkbox from "components/Checkbox";
import useInfiniteScroll from "utils/useInfiniteScroll";
import { find, get, map, xorBy } from "lodash";
import NoItemsFound from "components/NoItemsFound";
import PrivilegedComponent from "components/PrivilegedComponent";
import { PRIVILEGES } from "utils/constants";
import moment from "moment";

const View = ({
  onSort,
  sorted,
  searchData,
  bem,
  isLoading,
  getMoreItems,
  hasMore,
  selectedItems,
  onCancel,
  onSearch,
  setSelectedItems,
  setIsShowResults,
  dateFormatByServer
}) => {
  useInfiniteScroll({
    useWindow: false,
    elementId: "advance-search-table",
    onLoadMore: getMoreItems,
    shouldLoadMore: hasMore,
  });
  const items = get(searchData, "items", []);
  return (
    <div className={classnames(bem.e("table-wrapper"))}>
      <Table className={bem.e("table")}>
        <thead>
          <tr>
            <th className="text-select"></th>
            <th
              onClick={() => onSort("order_title")}
              className="can-click title-th"
            >
              <div className={bem.e("item-sortable")}>
                <FormattedMessage id="process > field title" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        get(sorted, "id") === "order_title" &&
                        !get(sorted, "desc"),
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        get(sorted, "id") === "order_title" &&
                        get(sorted, "desc"),
                    })}
                  />
                </div>
              </div>
            </th>
            <th onClick={() => onSort("order_type")} className="can-click">
              <div className={classnames(bem.e("item-sortable"))}>
                <FormattedMessage id="process > field type" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        get(sorted, "id") === "order_type" &&
                        !get(sorted, "desc"),
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        get(sorted, "id") === "order_type" &&
                        get(sorted, "desc"),
                    })}
                  />
                </div>
              </div>
            </th>
            <th
              onClick={() => onSort("order_start_date")}
              className="can-click"
            >
              <div
                className={classnames(
                  bem.e("item-sortable"),
                  bem.e("item-center")
                )}
              >
                <FormattedMessage id="process > field flight dates" />
                <div className={bem.e("sortable")}>
                  <FaSortAsc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        get(sorted, "id") === "order_start_date" &&
                        !get(sorted, "desc"),
                    })}
                  />
                  <FaSortDesc
                    className={classnames(bem.e("sortable-icon"), {
                      [bem.e("sortable-active")]:
                        get(sorted, "id") === "order_start_date" &&
                        get(sorted, "desc"),
                    })}
                  />
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody id="advance-search-table" className="scroll-bar-style">
          {items.length === 0 && !isLoading ? (
            <tr>
              <td colSpan={4}>
                <NoItemsFound />
              </td>
            </tr>
          ) : (
            <React.Fragment>
              {map(items, (item, index) => {
                return (
                  <tr key={index}>
                    <PrivilegedComponent
                      requires={[PRIVILEGES.REASSIGN_CLIENTS]}
                    >
                      {/* checkbox */}
                      <td className={bem.e("checkbox-td")}>
                        <Checkbox
                          checked={
                            !!find(selectedItems, (i) => i._id === item._id)
                          }
                          onChange={() => {
                            let newSelectedItems = xorBy(
                              selectedItems,
                              [item],
                              "_id"
                            );
                            setSelectedItems(newSelectedItems);
                          }}
                          checkStyle={{
                            color: "#fff",
                            width: "14",
                            height: "13",
                          }}
                        />
                      </td>
                    </PrivilegedComponent>
                    {/* title */}
                    <td
                      className={classnames(
                        "align-middle",
                        bem.e("title-text")
                      )}
                    >
                      <span>{get(item, "_source.order_title")}</span>
                    </td>
                    {/* type */}
                    <td
                      className={classnames("align-middle", bem.e("type-text"))}
                    >
                      {get(item, "_source.order_type")}
                    </td>
                    {/* created */}
                    <td
                      className={classnames(
                        "align-middle",
                        bem.e("text-center"),
                        bem.e("created-text")
                      )}
                    >
                      {moment(get(item, "_source.order_start_date")).format(
                        dateFormatByServer
                      )}
                      -
                      {moment(get(item, "_source.order_end_date")).format(
                        dateFormatByServer
                      )}
                    </td>
                  </tr>
                );
              })}
            </React.Fragment>
          )}
        </tbody>
      </Table>
      <div className={bem.e("buttons")}>
        <Button
          color="primary"
          className={classnames("btn-radius", bem.e("btn-search"))}
          type="button"
          onClick={() => {
            setIsShowResults(false);
            onSearch(selectedItems);
          }}
        >
          <FormattedMessage id="process > button search" />
        </Button>
        <Button
          color="primary"
          outline
          onClick={onCancel}
          className={classnames("btn-radius", bem.e("btn-cancel"))}
        >
          <FormattedMessage id="process > button cancel" />
        </Button>
      </div>
    </div>
  );
};
export default View;
