import View from "./view";
import { connect } from "react-redux";
import { compose, lifecycle, withHandlers, withState } from "recompose";
import { injectIntl } from "react-intl";
import { getSystemForms } from "store/actions/systemForms";
import { get } from "lodash";

const Enhanced = compose(
  connect(
    (state, props) => {
      const systemForms = get(state, "systemForms.systemForms.data.data", []);

      return {
        ...props,
        systemForms,
        currentServer: get(state, "servers.currentServer.data", {}),
      };
    },
    {
      getSystemForms,
    }
  ),

  withState("deletingField", "setDeletingField", null),
  withState("isOpenPreviewModal", "setIsOpenPreviewModal", false),
  withState("winnerFulfillmentOptions", "setWinnerFulfillmentOptions", []),
  withState(
    "isOpenConfirmationDeleteModal",
    "setIsOpenConfirmationDeleteModal",
    false
  ),
  withState("fieldRefs", "setFieldRefs", null),
  withState("errors", "setErrors", null),
  withState("touched", "setTouched", null),
  withState(
    "isOpenFieldsFormBuilderModal",
    "setIsOpenFieldsFormBuilderModal",
    false
  ),
  withState("selectedSystemForm", "setSelectedSystemForm", null),
  withHandlers({
    getWinnerFulfillmentOptions: ({
      getSystemForms,
      station,
      onValueChanged,
      step,
      process,
    }) => () => {
      getSystemForms(
        {
          form_type: "individual_winner_fulfillment_form",
          active_only: true,
          station_id: station.station_key,
        },
        (response) => {
          const winner_fulfillment_form = step.fields.find(
            (f) => f.field === "winner_fulfillment_form"
          );
          const selectedWinnerFulfillmentValue = get(
            process,
            "data.fields.winner_fulfillment_form.value"
          );
          if (
            response &&
            response.length > 0 &&
            !selectedWinnerFulfillmentValue
          ) {
            onValueChanged(winner_fulfillment_form, response[0]._id, {
              shouldBlockNavigation: false
            });
          }
        }
      );
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.getWinnerFulfillmentOptions();
    },
  })
)(View);
export default injectIntl(Enhanced);
