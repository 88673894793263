import React from "react";
import PropTypes from "prop-types";
import bn from "utils/bemnames";
import {
  lowerCase,
  find,
  filter,
  first,
  includes,
  last,
  uniq,
  get,
  isEqual,
} from "lodash";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import ToastManager from "components/ToastManager";
import * as Yup from "yup";
import TextInput from "components/TextInput";
import { Formik } from "formik";
import Checkbox from "components/Checkbox";
import Dropdown from "components/Dropdown";
import { FormattedMessage } from "react-intl";
import DaysOfWeek from "components/DaysOfWeek";
import moment from "moment";
import { Asterisk } from "components/Elements";
import { arrayContainsArray, convertArrayToString } from "utils/helpers";
import { FaInfoCircle } from "react-icons/lib/fa";
import Tooltip from "rc-tooltip";
import { getLocalStorageDateFormat } from "utils/dates";

const bem = bn.create("flight-schedule-modal");
const getTotalDates = ({ start_date, end_date }) => {
  const startDate = moment(start_date);
  const endDate = moment(end_date);
  const totalDates = endDate.diff(startDate, "days");
  return totalDates;
};
const weeksTypeOptions = [
  {
    label: "Every",
    value: "Every",
  },
  {
    label: "Last",
    value: "Last",
  },
  {
    label: "First",
    value: "First",
  },
];
const monthsTypeOptions = [
  {
    label: "Every",
    value: "Every",
  },
  {
    label: "Only",
    value: "Only",
  },
];
const getWeeksValueOptionsByType = (intl, type) => {
  let options = [];
  switch (type) {
    case "Every":
      options = [
        {
          label: intl.formatMessage({ id: "flight schedule > week" }),
          value: "Week",
        },
        {
          label: intl.formatMessage({
            id: "flight schedule > other week",
          }),
          value: "Other week",
        },
        {
          label: intl.formatMessage({
            id: "flight schedule > 3 weeks",
          }),
          value: "3 Weeks",
        },
      ];
      break;
    case "Last":
      options = [
        {
          label: intl.formatMessage({
            id: "flight schedule > 1 week",
          }),
          value: "1 Week",
        },
        {
          label: intl.formatMessage({
            id: "flight schedule > 2 weeks",
          }),
          value: "2 weeks",
        },
        {
          label: intl.formatMessage({
            id: "flight schedule > 3 weeks",
          }),
          value: "3 Weeks",
        },
      ];
      break;
    case "First":
      options = [
        {
          label: intl.formatMessage({
            id: "flight schedule > 1 week",
          }),
          value: "1 Week",
        },
        {
          label: intl.formatMessage({
            id: "flight schedule > 2 weeks",
          }),
          value: "2 weeks",
        },
        {
          label: intl.formatMessage({
            id: "flight schedule > 3 weeks",
          }),
          value: "3 Weeks",
        },
      ];
      break;

    default:
      break;
  }
  return options;
};
const getMonthsValueOptionsByType = (intl, type) => {
  let options = [];
  switch (type) {
    case "Every":
      options = [
        {
          label: intl.formatMessage({
            id: "flight schedule > other month",
          }),
          value: "Other month",
        },
        {
          label: intl.formatMessage({
            id: "flight schedule > 3 months",
          }),
          value: "3 months",
        },
        {
          label: intl.formatMessage({
            id: "flight schedule > quarter",
          }),
          value: "Quarter",
        },
      ];
      break;
    case "Only":
      const months = moment.months();
      options = months.map((item) => ({
        label: intl.formatMessage({
          id: `flight schedule > ${lowerCase(item)}`,
        }),
        value: item,
      }));
      break;
    default:
      break;
  }
  return options;
};
function sortByMonth(arr) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  arr.sort(function(a, b) {
    return months.indexOf(a) - months.indexOf(b);
  });
  return arr;
}
export const generateDescription = (intl, values) => {
  const dateFormatByServer = getLocalStorageDateFormat();
  let days = get(values, "days", []).sort();
  const weekArray = moment.weekdays();
  const startDay = weekArray[first(days) - 1];
  const endDay = weekArray[last(days) - 1];
  const weeksType = lowerCase(values.weeks_type);
  const monthsType = lowerCase(values.months_type);
  const weeksValue = values.weeks_value
    ? lowerCase(values.weeks_value)
    : `week`;
  const monthsValue = values.months_value
    ? convertArrayToString(
        typeof values.months_value === "string"
          ? [lowerCase(values.months_value)]
          : sortByMonth(values.months_value)
      )
    : `month`;
  const totalDates = getTotalDates(values);
  let isShowWeeks = true;
  let isShowMonths = true;
  if (totalDates < 7) {
    isShowWeeks = false;
  }
  if (totalDates < 30) {
    isShowMonths = false;
  }
  let weekText = [];
  let dayRange = "";
  if (
    days.length === 7 ||
    (days.length === 5 && isEqual(days, [2, 3, 4, 5, 6]))
  ) {
    dayRange = `${startDay}-${endDay}`;
  } else {
    dayRange = days
      .map((day) => {
        return weekArray[day - 1];
      })
      .join(", ");
  }
  if (values.weekdays) {
    weekText = [...weekText, "weekdays"];
  }
  if (values.weekends) {
    weekText = [...weekText, "weekends"];
  }
  let weeksTypeText = "";
  let monthsTypeText = "";
  if (isShowWeeks) {
    weeksTypeText = `of ${weeksType} ${weeksValue}`;
  }
  if (isShowMonths) {
    monthsTypeText = ` of ${monthsType} ${monthsValue}`;
  }
  const dueDate = values.due_date
    ? moment(values.due_date, "YYYY-MM-DD").format(dateFormatByServer)
    : null;
  if (values.start_date && values.end_date) {
    return (
      <span>
        Creative{" "}
        {dueDate && values.due_date !== values.start_date
          ? `is due ${dueDate} and `
          : ""}{" "}
        will run {dayRange} {convertArrayToString(weekText)} {weeksTypeText}
        {monthsTypeText} starting{" "}
        {moment(values.start_date, "YYYY-MM-DD").format(dateFormatByServer)} and
        ending{" "}
        {moment(values.end_date, "YYYY-MM-DD").format(dateFormatByServer)}.
      </span>
    );
  }

  return (
    <span>
      Creative will run {dayRange} {convertArrayToString(weekText)}{" "}
      {weeksTypeText} {monthsTypeText} from the beginning to end of your flight
      dates.
    </span>
  );
};

const FlightScheduleModal = ({
  isView,
  onSubmitForm,
  intl,
  isOpen,
  onToggle,
  isChannel,
  channel,
  ...rest
}) => {
  const defaultValue = rest.value || rest.initialValues || {};
  const renderForm = () => {
    let daysValue = defaultValue.days || [2, 3, 4, 5, 6];
    let initialValues = {
      start_date: defaultValue.start_date || "",
      end_date: defaultValue.end_date || "",
      due_date: isChannel
        ? defaultValue.due_date
        : defaultValue.start_date || "",
      weekends: defaultValue.weekends || arrayContainsArray(daysValue, [1, 7]),
      weekdays:
        defaultValue.weekdays || arrayContainsArray(daysValue, [2, 3, 4, 5, 6]),
      days: daysValue,
      weeks_type: defaultValue.weeks_type || "Every",
      weeks_value: defaultValue.weeks_value || "",
      months_type: defaultValue.months_type || "Every",
      months_value: defaultValue.months_value || "",
    };

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          start_date: Yup.date().required(
            intl.formatMessage({
              id: `validate > start date is required`,
            })
          ),
          end_date: Yup.date()
            .required(
              intl.formatMessage({
                id: `validate > end date is required`,
              })
            )
            .when(
              "start_date",
              (startDate, schema) =>
                startDate &&
                schema.min(
                  startDate,
                  intl.formatMessage({
                    id: `validate > end date should be later than start date`,
                  })
                )
            ),
          due_date: Yup.date()
            .when(
              "start_date",
              (startDate, schema) =>
                startDate &&
                isChannel &&
                schema.max(
                  startDate,
                  intl.formatMessage({
                    id: `validate > due date can't be greater than start date`,
                  })
                )
            )
            .nullable(),
          weekends: Yup.bool(),
          weekdays: Yup.bool(),
          days: Yup.array().required(
            intl.formatMessage({
              id: `validate > days is required`,
            })
          ),
        })}
        onSubmit={(values) => {
          onSubmitForm(values);
          ToastManager.show({
            title: intl.formatMessage({
              id: "toast > title saved",
            }),
            level: "success",
          });
        }}
      >
        {(formProps) => {
          const errors = formProps.errors;
          const touched = formProps.touched;
          const values = formProps.values;
          const weeksValueOptions = getWeeksValueOptionsByType(
            intl,
            values.weeks_type
          );
          const monthsValueOptions = getMonthsValueOptionsByType(
            intl,
            values.months_type
          );
          const weeksValue =
            find(
              weeksValueOptions,
              (item) => item.value === values.weeks_value
            ) || "";
          let monthsValue = "";
          if (values.months_type === "Only") {
            monthsValue =
              filter(monthsValueOptions, (item) =>
                includes(values.months_value, item.value)
              ) || [];
          } else {
            monthsValue =
              find(
                monthsValueOptions,
                (item) => item.value === values.months_value
              ) || "";
          }
          const totalDates = getTotalDates(values);
          let isShowWeeks = true;
          let isShowMonths = true;
          if (totalDates < 7) {
            isShowWeeks = false;
          }
          if (totalDates < 30) {
            isShowMonths = false;
          }
          return (
            <Form
              onSubmit={formProps.handleSubmit}
              noValidate
              className={bem.e("form")}
            >
              <Row>
                <Col xs={4}>
                  <FormGroup className={bem.e("form-group")}>
                    <TextInput
                      label={
                        <span className={bem.e("label")}>
                          <FormattedMessage id="flight schedule > start date" />
                          <Asterisk>*</Asterisk>
                        </span>
                      }
                      type="date"
                      value={values.start_date}
                      onChange={(value) => {
                        formProps.setFieldValue("start_date", value);
                        if (!isChannel) {
                          formProps.setFieldValue("due_date", value);
                        }
                      }}
                      allowShowIcon={true}
                      min={moment().format("YYYY-MM-DD")}
                      error={touched.start_date && errors.start_date}
                    />
                  </FormGroup>
                </Col>
                <Col xs={4}>
                  <FormGroup className={bem.e("form-group")}>
                    <TextInput
                      label={
                        <span className={bem.e("label")}>
                          <FormattedMessage id="flight schedule > end date" />
                          <Asterisk>*</Asterisk>
                        </span>
                      }
                      type="date"
                      allowShowIcon={true}
                      value={values.end_date}
                      min={moment().format("YYYY-MM-DD")}
                      onChange={(value) => {
                        formProps.setFieldValue("end_date", value);
                        const totalDates = getTotalDates({
                          start_date: values.start_date,
                          end_date: value,
                        });
                        if (totalDates < 7) {
                          formProps.setFieldValue("weeks_type", "Every");
                          formProps.setFieldValue("weeks_value", "");
                        }
                        if (totalDates < 30) {
                          formProps.setFieldValue("months_type", "Every");
                          formProps.setFieldValue("months_value", "");
                        }
                      }}
                      error={touched.end_date && errors.end_date}
                    />
                  </FormGroup>
                </Col>
                {isChannel ? (
                  <Col xs={4}>
                    <FormGroup className={bem.e("form-group")}>
                      <TextInput
                        label={
                          <span className={bem.e("label")}>
                            <FormattedMessage id="flight schedule > due date" />
                            <Tooltip
                              placement="top"
                              trigger={["click", "hover"]}
                              overlayClassName={bem.e("tooltip")}
                              overlay={
                                <div className={bem.e("tooltip-content")}>
                                  <FormattedMessage id="flight schedule > due date description" />
                                </div>
                              }
                            >
                              <span className={bem.e("tooltip-icon")}>
                                <FaInfoCircle size={15} color="#795AFA" />
                              </span>
                            </Tooltip>
                          </span>
                        }
                        type="date"
                        value={values.due_date}
                        onChange={(value) =>
                          formProps.setFieldValue("due_date", value)
                        }
                        allowShowIcon={true}
                        max={values.start_date ? values.start_date : undefined}
                        error={touched.due_date && errors.due_date}
                      />
                    </FormGroup>
                  </Col>
                ) : null}
              </Row>
              <Row>
                <Col xs={6}>
                  <FormGroup className={bem.e("form-group")}>
                    <DaysOfWeek
                      value={values.days}
                      // disabled
                      onChange={(days) => {
                        formProps.setFieldValue("days", days);
                        // check for checkbox weekdays
                        let weekdays;
                        if (arrayContainsArray(days, [2, 3, 4, 5, 6])) {
                          weekdays = true;
                        } else {
                          weekdays = false;
                        }
                        formProps.setFieldValue("weekdays", weekdays);

                        // check for checkbox weekdays
                        let weekends;
                        if (arrayContainsArray(days, [1, 7])) {
                          weekends = true;
                        } else {
                          weekends = false;
                        }
                        formProps.setFieldValue("weekends", weekends);
                      }}
                      isRequired={true}
                    />
                    {!!(touched.days && errors.days) && (
                      <span className="text-danger">{errors.days}</span>
                    )}
                  </FormGroup>
                </Col>
                <Col xs={3}>
                  <FormGroup className={bem.e("form-group")}>
                    <Label className={bem.e("label-fake")} />
                    <Checkbox
                      checked={values.weekdays}
                      onChange={(checked) => {
                        formProps.setFieldValue("weekdays", checked);
                        let newDays = [];
                        if (checked) {
                          newDays = uniq([...values.days, ...[2, 3, 4, 5, 6]]);
                        } else {
                          newDays = filter(
                            values.days,
                            (item) => !includes([2, 3, 4, 5, 6], item)
                          );
                        }
                        formProps.setFieldValue("days", newDays);
                      }}
                      text={
                        <span className={bem.e("label-week")}>
                          <FormattedMessage id="flight schedule > weekdays" />
                        </span>
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={3}>
                  <FormGroup className={bem.e("form-group")}>
                    <Label className={bem.e("label-fake")} />
                    <Checkbox
                      checked={values.weekends}
                      onChange={(checked) => {
                        formProps.setFieldValue("weekends", checked);
                        let newDays = [];
                        if (checked) {
                          newDays = uniq([...values.days, ...[1, 7]]);
                        } else {
                          newDays = filter(
                            values.days,
                            (item) => !includes([1, 7], item)
                          );
                        }
                        formProps.setFieldValue("days", newDays);
                      }}
                      text={
                        <span className={bem.e("label-week")}>
                          <FormattedMessage id="flight schedule > weekends" />
                        </span>
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              {isShowWeeks && (
                <React.Fragment>
                  <div>
                    <Label className={bem.e("label")}>
                      <FormattedMessage id="flight schedule > weeks" />
                      {/* <Asterisk>*</Asterisk> */}
                    </Label>
                  </div>
                  <Row>
                    <Col xs={4}>
                      <FormGroup className={bem.e("form-group")}>
                        <Dropdown
                          name="weeks_type"
                          options={weeksTypeOptions}
                          label={null}
                          value={find(
                            weeksTypeOptions,
                            (item) => item.value === values.weeks_type
                          )}
                          onChange={(selectedOption) => {
                            formProps.setFieldValue(
                              "weeks_type",
                              selectedOption.value
                            );
                            formProps.setFieldValue("weeks_value", "");
                          }}
                        />
                        {!!(touched.weeks_type && errors.weeks_type) && (
                          <span className="text-danger">
                            {errors.weeks_type}
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xs={4}>
                      <FormGroup className={bem.e("form-group")}>
                        <Dropdown
                          name="weeks_value"
                          options={weeksValueOptions}
                          label={null}
                          value={weeksValue}
                          onChange={(selectedOption) => {
                            formProps.setFieldValue(
                              "weeks_value",
                              selectedOption.value
                            );
                          }}
                        />
                        {!!(touched.weeks_value && errors.weeks_value) && (
                          <span className="text-danger">
                            {errors.weeks_value}
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
              {isShowMonths && (
                <Row>
                  <Col xs={12}>
                    <Label className={bem.e("label")}>
                      <FormattedMessage id="flight schedule > months" />
                      {/* <Asterisk>*</Asterisk> */}
                    </Label>
                  </Col>
                  <Col xs={4}>
                    <FormGroup className={bem.e("form-group")}>
                      <Dropdown
                        name="months_type"
                        options={monthsTypeOptions}
                        label={null}
                        value={find(
                          monthsTypeOptions,
                          (item) => item.value === values.months_type
                        )}
                        onChange={(selectedOption) => {
                          formProps.setFieldValue(
                            "months_type",
                            selectedOption.value
                          );
                          formProps.setFieldValue("months_value", "");
                        }}
                      />
                      {!!(touched.months_type && errors.months_type) && (
                        <span className="text-danger">
                          {errors.months_type}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs={4}>
                    <FormGroup className={bem.e("form-group")}>
                      <Dropdown
                        name="months_value"
                        options={monthsValueOptions}
                        label={null}
                        value={monthsValue}
                        isCheckbox={values.months_type === "Only"}
                        isMulti={values.months_type === "Only"}
                        closeMenuOnSelect={values.months_type !== "Only"}
                        hideSelectedOptions={values.months_type !== "Only"}
                        onChange={(selectedOption) => {
                          let newMonthsValue;
                          if (values.months_type === "Only") {
                            newMonthsValue = selectedOption.map(
                              (item) => item.value
                            );
                          } else {
                            newMonthsValue = selectedOption.value;
                          }
                          formProps.setFieldValue(
                            "months_value",
                            newMonthsValue
                          );
                        }}
                      />
                      {!!(touched.months_value && errors.months_value) && (
                        <span className="text-danger">
                          {errors.months_value}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              )}

              <FormGroup className={bem.e("form-group")}>
                <Label className={bem.e("label")}>
                  <FormattedMessage id="flight schedule > recurrence statement" />
                </Label>
                <div className={bem.e("text-description")}>
                  {generateDescription(intl, values)}
                </div>
              </FormGroup>
              <div className={bem.e("buttons")}>
                <Button
                  color="blue"
                  className="btn btn-radius btn-submit"
                  type="submit"
                >
                  <FormattedMessage id={`flight schedule > button submit`} />
                </Button>
                <Button
                  color="blue"
                  outline
                  className="btn btn-radius btn-cancel"
                  onClick={onToggle}
                >
                  <FormattedMessage id={`flight schedule > button cancel`} />
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  };
  return (
    <Modal isOpen={isOpen} className={bem.b()} size="lg">
      <ModalHeader toggle={onToggle} className={bem.e("header")}>
        {isChannel ? (
          <span>
            <FormattedMessage id="flight schedule > channel schedule" /> -{" "}
            {get(channel, "name", "")}
          </span>
        ) : (
          <FormattedMessage id="flight schedule > title" />
        )}
      </ModalHeader>
      <ModalBody className={bem.e("body")}>{renderForm()}</ModalBody>
    </Modal>
  );
};
FlightScheduleModal.defaultProps = {
  onSubmitForm: () => {},
  initialValues: {},
  onToggle: () => {},
  isView: false,
  isChannel: true,
};
FlightScheduleModal.propTypes = {
  isView: PropTypes.bool,
  initialValues: PropTypes.object,
  onSubmitForm: PropTypes.func,
  onToggle: PropTypes.func,
  isChannel: PropTypes.bool,
};
export default FlightScheduleModal;
