import React from "react";
import bn from "utils/bemnames";
import classNames from "classnames";
import { get, map } from "lodash";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import { PhoneNumberFormat } from "utils/config";
const bem = bn.create("jock-console");
function PhoneItem(props) {
  const { item } = props;

  return (
    <div className={bem.e("phone-item")}>
      <div className={bem.e("phone-name")}>{item.label}:</div>
      <a href={`tel:${item.number}`} className={bem.e("phone-number")}>
        <NumberFormat
          className={bem.e("view-value")}
          format={PhoneNumberFormat}
          displayType={"text"}
          value={item.number}
        />
      </a>
    </div>
  );
}
export default function PhoneNumbers(props) {
  const { widget, isMultiView } = props;
  const phones = get(widget, "fields.numbers", []);
  return (
    <div
      className={classNames(
        bem.e("phone-numbers"),
        bem.e("widget-detail-padding")
      )}
    >
      <div
        className={classNames(bem.e("phone-numbers-head-title"), {
          [bem.e("head-title-multi-view")]: isMultiView,
        })}
        style={{
          backgroundColor: isMultiView ? widget.color : "",
        }}
      >
        {get(widget, "name", "")}
      </div>
      <div className={bem.e("numbers-list")}>
        {map(phones, (item, index) => (
          <PhoneItem key={index} item={item} />
        ))}
      </div>
    </div>
  );
}
PhoneNumbers.defaultProps = {
  isMultiView: false,
  widget: {},
};
PhoneNumbers.propsTypes = {
  isMultiView: PropTypes.bool,
  widget: PropTypes.object.isRequired,
};
