import React from "react";
import bn from "utils/bemnames";
import { FormattedMessage } from "react-intl";
import { ShadowBox, SectionTitle, SectionFields } from "components/Elements";
import { map, get } from "lodash";
const bem = bn.create("winner-details");
const YearItems = ({ item }) => {
  const { year } = item;
  const items = get(item, "items", []);
  return (
    <div className={bem.e("contact-activity-item")}>
      <div className={bem.e("activity-year")}>{year}</div>
      <div className={bem.e("activity-items")}>
        {map(items, (message, index) => (
          <div key={index} className={bem.e("activity-item")}>
            <span className={bem.e("activity-item-date")}>{message.date}</span>
            <span className={bem.e("activity-item-time")}>3:28pm</span>
            <span
              className={bem.e("activity-item-text")}
              dangerouslySetInnerHTML={{ __html: message.text }}
            ></span>
          </div>
        ))}
      </div>
    </div>
  );
};
export default function ContactActivity(props) {
  const items = [
    {
      year: "2020",
      items: [
        {
          date: "03/02/2020",
          text:
            "Winner picked up 2 <span class='highlight-text'>Sheryl Crow Tickets</span> in Studio Reception",
        },
        {
          date: "03/02/2020",
          text:
            "Winner signed <span class='highlight-text'>1099</span> and <span class='highlight-text'>Winner Release form</span>",
        },
        {
          date: "03/02/2020",
          text:
            "<span class='highlight-text'>Text message</span> read by winner",
        },
        {
          date: "03/02/2020",
          text: "Text message sent",
        },
      ],
    },
    {
      year: "2019",
      items: [
        {
          date: "03/02/2020",
          text:
            "Winner picked up 2 <span class='highlight-text'>Sheryl Crow Tickets</span> in Studio Reception",
        },
        {
          date: "03/02/2020",
          text:
            "Winner signed <span class='highlight-text'>1099</span> and <span class='highlight-text'>Winner Release form</span>",
        },
        {
          date: "03/02/2020",
          text:
            "<span class='highlight-text'>Text message</span> read by winner",
        },
        {
          date: "03/02/2020",
          text: "Text message sent",
        },
      ],
    },
  ];
  return (
    <ShadowBox className={bem.e("contact-activity-box")}>
      <SectionTitle className={bem.e("section-title")}>
        <FormattedMessage id="winners > title contact activity" />
      </SectionTitle>
      <SectionFields className={bem.e("contact-activity-fields")}>
        <div className={bem.e("contact-activity-items")}>
          {map(items, (item, index) => (
            <YearItems item={item} key={index} />
          ))}
        </div>
      </SectionFields>
    </ShadowBox>
  );
}
