import * as Const from "utils/constants";
import Api from "utils/api";
import * as Types from "../types/zetta";
import { userInfoOnly } from "./auth";
import queryString from "query-string";

export const getZettaSettings = (payload) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.FETCH_ZETTA_SETTINGS_REQUEST, payload });
    const url = `${Const.ZETTA()}/get_settings`;
    let response = await Api.doCall(url, "GET");
    if (response.status === 200) {
      if (!response.data.error) {
        dispatch({
          type: Types.FETCH_ZETTA_SETTINGS_SUCCESS,
          payload: response.data,
        });
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.FETCH_ZETTA_SETTINGS_SUCCESS,
    payload: errorStr,
  });
};

export const updateZettaSettings = (data, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    dispatch({ type: Types.UPDATE_ZETTA_SETTINGS_REQUEST });
    //api call for store data
    const url = `${Const.ZETTA()}/save_settings`;
    let response = await Api.doCall(url, "POST", data);
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        //response data
        dispatch({
          type: Types.UPDATE_ZETTA_SETTINGS_SUCCESS,
        });
        dispatch(getZettaSettings());
        cb(response.data);
        return;
      }
      //error data
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
    cb();
  }
  dispatch({
    type: Types.UPDATE_ZETTA_SETTINGS_ERROR,
    payload: errorStr,
  });
};

export const toggleZettaEnable = (data) => async (dispatch) => {
  let errorStr = "";
  try {
    const url = `${Const.ZETTA()}/enable_zetta_dubbing`;
    dispatch({ type: Types.TOGGLE_ZETTA_ENABLE_REQUEST });
    //api call for store data
    let response = await Api.doCall(url, "POST", data);
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        //response data
        dispatch({
          type: Types.TOGGLE_ZETTA_ENABLE_SUCCESS,
        });
        dispatch(userInfoOnly());
        return;
      }
      //error data
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.TOGGLE_ZETTA_ENABLE_ERROR,
    payload: errorStr,
  });
};

export const deleteZettaSettings = (data, cb) => async (dispatch) => {
  let errorStr = "";
  try {
    const url = `${Const.ZETTA()}/delete_settings?${queryString.stringify(
      data || {}
    )}`;

    dispatch({ type: Types.DELETE_ZETTA_SETTINGS_REQUEST });
    //api call for store data
    let response = await Api.doCall(url, "DELETE", data);
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        //response data
        dispatch({
          type: Types.DELETE_ZETTA_SETTINGS_SUCCESS,
        });
        dispatch(getZettaSettings());
        cb(response.data);
        return;
      }
      //error data
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({
    type: Types.DELETE_ZETTA_SETTINGS_ERROR,
    payload: errorStr,
  });
};
