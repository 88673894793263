import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";
import HomeMobile from "./view";
import { get } from "lodash";

export default compose(
  connect(
    (state) => ({
      auth: state.auth,
      isEnableProductionNeeded: !get(
        state,
        "auth.info.disabled_production_needed",
        false
      ),
      isEnableProductionProvided: !get(
        state,
        "auth.info.disabled_production_provided",
        false
      ),
    }),
    {}
  ),
  withHandlers({})
)(HomeMobile);
