import View from "./view";
import { compose, withHandlers } from "recompose";
import arrayMove from "array-move";
export default compose(
  withHandlers({
    onSortEnd: ({ setOrderChannels, channels, onUpdateOrderChannels }) => ({
      oldIndex,
      newIndex,
    }) => {
      const newList = arrayMove(channels, oldIndex, newIndex);
      setOrderChannels(newList);
      onUpdateOrderChannels(newList);
    },
  })
)(View);
