import * as React from "react";
import PropTypes from "prop-types";
import { MentionsInput, Mention } from "react-mentions";
import { FaSmileO } from "react-icons/lib/fa";
import { Picker } from "emoji-mart";
import mentionInputStyle, { mentionStyle } from "./mentionStyles";
import bn from "utils/bemnames";
import cx from "classnames";
import { getSortUserName } from "utils/helpers";
import ToolTip from "react-portal-tooltip";
import { injectIntl } from "react-intl";
import { SendMailIcon, AttachIcon } from "components/CustomIcons";
import OutsideClickHandler from "react-outside-click-handler";
import { get, isFunction } from "lodash";
import Spinner from "components/Spinner";
import Attachment from "./attachment";

const bem = bn.create("chatbox-input");

class ChatInput extends React.Component {
  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }
  _calculateCursorPositionAndSplitString = () => {
    const { inputValue } = this.props;
    const textarea = this.inputRef;
    const mentions = inputValue.mentions;
    const cursorPosition = textarea.selectionEnd;
    let offset = 0;
    mentions.map((mention) => {
      if (mention.plainTextIndex < cursorPosition) {
        offset += mention.id.length + 5; // here 5 is due to @[]() character in mention string
        return mention;
      }
      return mention;
    });
    const splitIndex = cursorPosition + offset;
    const start = inputValue.value.substring(0, splitIndex);
    const end = inputValue.value.substring(splitIndex);
    return { start, end, splitIndex, cursorPosition };
  };

  _onSelectEmoji = (emoji) => {
    const { inputValue, setInputValue } = this.props;
    const textarea = this.inputRef;
    const {
      start,
      end,
      cursorPosition,
    } = this._calculateCursorPositionAndSplitString();
    const text = start + emoji.native + end;
    setInputValue({ ...inputValue, value: text });
    setTimeout(() => {
      textarea.focus();
      textarea.selectionEnd = cursorPosition + emoji.native.length;
    });
  };

  renderEmojiPicker() {
    const { toggleEmojiPicker, isEmojiPickerOpen } = this.props;
    return (
      <OutsideClickHandler
        onOutsideClick={() => {
          if (isEmojiPickerOpen) {
            toggleEmojiPicker();
          }
        }}
      >
        <div className={bem.e("picker")}>
          <Picker title="" emoji="" onSelect={this._onSelectEmoji} />
        </div>
      </OutsideClickHandler>
    );
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.clearInput !== prevProps.clearInput &&
      this.props.clearInput &&
      this.props.inputValue
    ) {
      this.props.setInputValue("");
    }
  }
  render() {
    const {
      toggleEmojiPicker,
      isEmojiPickerOpen,
      setInputValue,
      inputValue,
      users,
      submitOnEnter,
      emojiPosition,
      mentionPosition,
      onInputChange,
      isAllowMentions,
      disabled,
      isUploading,
    } = this.props;

    let showEmojiPicker = true;
    if (typeof this.props.showEmojiPicker !== "undefined") {
      showEmojiPicker = this.props.showEmojiPicker;
    }

    let toolTipStyle = {
      style: {
        padding: 0,
        transition: "none",
        zIndex: 1000,
      },
      arrowStyle: {
        borderColor: false,
      },
    };
    let mentionInput = JSON.parse(JSON.stringify(mentionInputStyle));
    if (mentionPosition !== "top") {
      mentionInput.suggestions.list.bottom = "23px";
      if (mentionPosition === "one")
        mentionInput.suggestions.list.height = "100px";
      delete mentionInput.suggestions.list.top;
    }
    const mentions = isAllowMentions
      ? users.map((user) => ({
          id: user.id,
          name: user.name,
          display: user.name,
          avatar: user.avatar_url,
        }))
      : [];
    const attachments = get(inputValue, "attachments", []);
    const inputDisabled = disabled || isUploading;
    return (
      <div className={bem.b()}>
        <div className={bem.e("input")}>
          {showEmojiPicker && (
            <div className={bem.e("button-action")}>
              <div
                className={bem.e("input__button-mention")}
                data-emoji
                ref={(ref) => (this.emojiButton = ref)}
                onClick={toggleEmojiPicker}
              >
                <FaSmileO size={20} />
              </div>
              <label className={bem.e("attach-icon")}>
                {isUploading ? (
                  <Spinner size={13} isLoading={isUploading} />
                ) : (
                  <>
                    <input
                      type="file"
                      accept="image/*,.mp3,.wav,.doc,.docx,.pdf,.xls,.xlsx,.ppt,.pptx,.txt,.csv"
                      value=""
                      multiple
                      onChange={this.props.onUploadAttachment}
                    />
                    <AttachIcon width={24} height={12.57} />
                  </>
                )}
              </label>
            </div>
          )}
          <div className={bem.e("input__field")}>
            {attachments.map((attachment, index) => {
              return (
                <Attachment
                  key={index}
                  file={attachment}
                  onDelete={() => {
                    attachments.splice(index, 1);
                    setInputValue({
                      ...inputValue,
                      attachments,
                    });
                  }}
                  actions={["trash"]}
                />
              );
            })}
            <MentionsInput
              inputRef={(ref) => (this.inputRef = ref)}
              placeholder={
                this.props.chatInputPlaceHolder ||
                this.props.intl.formatMessage({
                  id: "chat system > placeholder comment input",
                })
              }
              disabled={inputDisabled}
              value={inputValue.value || ""}
              style={mentionInput}
              className={bem.e("mentionInput")}
              onKeyDown={(e) => {
                if (submitOnEnter) {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    this.props.onSend();
                    return false;
                  }
                }
              }}
              onChange={(event, newValue, newPlainTextValue, mentions) => {
                setInputValue({
                  value: newValue,
                  plainValue: newPlainTextValue,
                  mentions,
                  attachments,
                });
                if (isFunction(onInputChange)) {
                  onInputChange(newValue);
                }
              }}
            >
              <Mention
                trigger="@"
                data={mentions}
                renderSuggestion={(entry) => {
                  return (
                    <div className={bem.e("mention__list")}>
                      {entry.avatar ? (
                        <img
                          src={entry.avatar}
                          alt={entry.display}
                          className={bem.e("mention__avatar")}
                        />
                      ) : (
                        <div className={cx("message-avatar-text-noti")}>
                          {getSortUserName(entry.display)}
                        </div>
                      )}

                      <div className={bem.e("mention__name")}>
                        {entry.display}
                      </div>
                    </div>
                  );
                }}
                style={mentionStyle}
                className={bem.e("mention")}
                appendSpaceOnAdd
              />
            </MentionsInput>
          </div>
          <div className={bem.e("input__button")} onClick={this.props.onSend}>
            <SendMailIcon size={20} className="cr-chatbox" />
          </div>
        </div>
        {this.emojiButton && (
          <ToolTip
            position={emojiPosition || "top"}
            active={isEmojiPickerOpen}
            parent={this.emojiButton}
            style={toolTipStyle}
          >
            {this.renderEmojiPicker()}
          </ToolTip>
        )}
      </div>
    );
  }
}

ChatInput.propTypes = {
  setInputValue: PropTypes.func,
  inputValue: PropTypes.any,
  toggleEmojiPicker: PropTypes.func,
  users: PropTypes.array,
  onSend: PropTypes.func,
  submitOnEnter: PropTypes.bool,
  isAllowMentions: PropTypes.bool,
  disabled: PropTypes.bool,
};
ChatInput.defaultProps = {
  isAllowMentions: true,
  disabled: false,
};
export default injectIntl(ChatInput);
