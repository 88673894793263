import * as React from "react";
import bn from "utils/bemnames";
import { Col, Row, Label } from "reactstrap";
import { get, find, first, isEmpty, filter } from "lodash";
import AutocompleteInput from "components/AutocompleteInput";
import TextInputFormatted from "components/TextInputFormatted";
import TextInput from "components/TextInput";
import { Asterisk, SectionFields } from "components/Elements";
import { PhoneNumberFormat } from "utils/config";
import EditClientModal from "components/EditClientModal";
import {
  autoSuggestClients,
  autoSuggestContacts,
  autoSuggestAgencies,
} from "store/actions/process";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
const bem = bn.create("order-client-details");
const OrderClientDetails = (props) => {
  const {
    isView,
    valueOrEmpty,
    client_name,
    intl,
    template,
    user,
    client_id,
    contract_category,
    agency_name,
    contract_client_contact,
    contract_client_nickname,
    contract_client_email,
    contract_client_phone,
    contact_id,
    order_title,
    isDigital,
  } = props;
  if (isView) {
    return (
      <div className={bem.b()}>
        <SectionFields className={bem.e("section-client-fields")}>
          <Row>
            <Col className={bem.e("col-view-field")}>
              <Label>{client_name.title}</Label>
              <span className={bem.e("order-view-value")}>
                {valueOrEmpty(client_name)}
              </span>
            </Col>
            {agency_name && (
              <Col className={bem.e("col-view-field")}>
                <Label>{agency_name.title}</Label>
                <span className={bem.e("order-view-value")}>
                  {valueOrEmpty(agency_name)}
                </span>
              </Col>
            )}
            {order_title && (
              <Col className={bem.e("col-view-field")}>
                <Label>{order_title.title}</Label>
                <span className={bem.e("order-view-value")}>
                  {valueOrEmpty(order_title)}
                </span>
              </Col>
            )}
          </Row>
          <Row>
            <Col className={bem.e("col-view-field")}>
              <Label>{contract_client_contact.title}</Label>
              <span className={bem.e("order-view-value")}>
                {valueOrEmpty(contract_client_contact)}
              </span>
            </Col>

            <Col className={bem.e("col-view-field")}>
              <Label>{contract_client_nickname.title}</Label>
              <span className={bem.e("order-view-value")}>
                {valueOrEmpty(contract_client_nickname)}
              </span>
            </Col>
          </Row>
          <Row>
            <Col
              className={classnames({ [bem.e("col-view-field")]: !isDigital })}
            >
              <Label>{contract_client_email.title}</Label>
              <span className={bem.e("order-view-value")}>
                {valueOrEmpty(contract_client_email)}
              </span>
            </Col>
            <Col
              className={classnames({ [bem.e("col-view-field")]: !isDigital })}
            >
              <Label>{contract_client_phone.title}</Label>
              <span className={bem.e("order-view-value")}>
                {valueOrEmpty(contract_client_phone)}
              </span>
            </Col>
          </Row>
        </SectionFields>
      </div>
    );
  }
  const {
    validationState,
    bindedValueChanged,
    setIsFieldsChanged,
    onSelectContact,
    onSelectAgency,
    onValueChanged,
    process,
    isEnableEnterprise,
    isWoTrafficEnabled,
    setShouldBlockNavigation,
    isSpecOrder,
  } = props;
  const [customers, setCustomers] = React.useState([]);
  const [agencies, setAgencies] = React.useState([]);
  const [contacts, setContacts] = React.useState([]);
  const [isOpenEditClientModal, setIsOpenEditClientModal] = React.useState(
    false
  );

  const [isContactsDropdownOpen, setIsContactsDropdownOpen] = React.useState(
    false
  );
  const isShowEditClientDetails = get(process, `data.fields.client_id.value`);
  const selectedContact = get(process, "data.fields.contact_id.value");
  const clientId = process.data.fields[client_id.field]
    ? process.data.fields[client_id.field].value
    : "";
  const contactName = process.data.fields[contract_client_contact.field]
    ? process.data.fields[contract_client_contact.field].value
    : "";
  const contactId = process.data.fields[contact_id.field]
    ? process.data.fields[contact_id.field].value
    : "";

  React.useEffect(() => {
    if (clientId && !isContactsDropdownOpen) {
      autoSuggestContacts({
        client_id: clientId,
        q: "",
        template_key: template.key,
        cb: (items) => {
          setContacts(items);
        },
      });
    }
  }, [clientId, isContactsDropdownOpen]);

  React.useEffect(() => {
    if (clientId) {
      autoSuggestContacts({
        client_id: clientId,
        q: "",
        template_key: template.key,
        cb: (items) => {
          setContacts(items);
          if (get(items, "length", 0) > 0) {
            if (clientId && contactName && !contactId) {
              const contact = find(
                items,
                (item) => item.contract_client_contact === contactName
              );
              onSelectContact(contact);
            }
          }
        },
      });
    }
  }, []);

  return (
    <div className={bem.b()}>
      <SectionFields className={bem.e("section-client-fields")}>
        <Row>
          <Col xs={6} className={bem.e("col-field")}>
            <AutocompleteInput
              label={client_name.title}
              isRequired={client_name.mandatory}
              error={
                validationState[client_name.field] &&
                validationState[client_name.field].validation_error
              }
              value={
                process.data.fields[client_name.field]
                  ? process.data.fields[client_name.field].value
                  : ""
              }
              inputProps={{
                placeholder: intl.formatMessage({
                  id: "process > enter company",
                }),
                onPaste: () => {
                  //  Resetting the contact field when advertiser is changed
                  onSelectContact({
                    contract_client_contact: "",
                    contract_client_email: "",
                    contract_client_nickname: "",
                    contract_client_phone: "",
                    contact_id: "",
                  });
                  bindedValueChanged(client_id, process, template, user, {
                    value: "",
                  });
                  if (agency_name) {
                    bindedValueChanged(agency_name, process, template, user, {
                      value: "",
                    });
                  }
                  setShouldBlockNavigation(true);
                },
                disabled: isWoTrafficEnabled && !isSpecOrder,
              }}
              items={customers}
              getItemValue={(item) => item.client_name}
              renderMenu={(children) => {
                return (
                  <div className={children.length > 0 ? "menu" : ""}>
                    {children}
                  </div>
                );
              }}
              renderItem={(item, isHighlighted) => (
                <div
                  className={`item ${isHighlighted ? "item-highlighted" : ""}`}
                  key={item.id}
                >
                  {item.client_name}
                </div>
              )}
              onSelect={(value, item) => {
                setShouldBlockNavigation(true);
                bindedValueChanged(client_name, process, template, user, {
                  value,
                });
                bindedValueChanged(client_id, process, template, user, {
                  value: item.id,
                });
                autoSuggestContacts({
                  client_id: item.id,
                  q: "",
                  template_key: template.key,
                  cb: (items) => {
                    setContacts(items);
                    if (get(items, "length", 0) > 0) {
                      let primaryKey = "primary_production";
                      if (template.key === "digital") {
                        primaryKey = "primary_digital";
                      } else if (template.key === "promotion") {
                        primaryKey = "primary_promotion";
                      }
                      const contact =
                        find(items, (item) => item[primaryKey]) || first(items);
                      onSelectContact(contact);
                    } else {
                      //  Resetting the contact field when advertiser is changed
                      onSelectContact({
                        contract_client_contact: "",
                        contract_client_email: "",
                        contract_client_nickname: "",
                        contract_client_phone: "",
                        contact_id: "",
                      });
                    }
                  },
                });
                bindedValueChanged(contract_category, process, template, user, {
                  value: get(item, "contract_category", ""),
                });
                if (agency_name) {
                  bindedValueChanged(agency_name, process, template, user, {
                    value: get(item, "agency", ""),
                  });
                }

                setIsFieldsChanged(true);
              }}
              onChange={(e, value) => {
                // clear
                onSelectContact({
                  contract_client_contact: "",
                  contract_client_email: "",
                  contract_client_nickname: "",
                  contract_client_phone: "",
                  contact_id: "",
                });
                bindedValueChanged(client_id, process, template, user, {
                  value: "",
                });
                if (agency_name) {
                  bindedValueChanged(agency_name, process, template, user, {
                    value: "",
                  });
                }
                setTimeout(() => {
                  autoSuggestClients({
                    q: value,
                    template_key: template.key,
                    cb: (items) => {
                      setContacts([]);
                      setCustomers(items);
                    },
                  });
                }, 200);
                bindedValueChanged(client_name, process, template, user, e);
                setIsFieldsChanged(true);
                setShouldBlockNavigation(true);
              }}
            />
          </Col>
          <Col xs={6} className={bem.e("col-field")}>
            {agency_name && (
              <AutocompleteInput
                label={agency_name.title}
                isRequired={agency_name.mandatory}
                error={
                  validationState[agency_name.field] &&
                  validationState[agency_name.field].validation_error
                }
                value={
                  process.data.fields[agency_name.field]
                    ? process.data.fields[agency_name.field].value
                    : ""
                }
                inputProps={{
                  placeholder: intl.formatMessage({
                    id: "process > enter company",
                  }),
                  disabled: isShowEditClientDetails,
                }}
                items={agencies}
                getItemValue={(item) => item.agency_name}
                renderMenu={(children) => {
                  return (
                    <div className={children.length > 0 ? "menu" : ""}>
                      {children}
                    </div>
                  );
                }}
                renderItem={(item, isHighlighted) => (
                  <div
                    className={`item ${
                      isHighlighted ? "item-highlighted" : ""
                    }`}
                    key={item.id}
                  >
                    {item.agency_name}
                  </div>
                )}
                onSelect={(value, item) => {
                  onSelectAgency(item);
                  setIsFieldsChanged(true);
                  setShouldBlockNavigation(true);
                }}
                onChange={(e, value) => {
                  setTimeout(() => {
                    autoSuggestAgencies({
                      q: value,
                      template_key: template.key,
                      cb: (items) => {
                        setAgencies(items);
                      },
                    });
                  }, 500);
                  bindedValueChanged(agency_name, process, template, user, e);
                  setIsFieldsChanged(true);
                  setShouldBlockNavigation(true);
                }}
              />
            )}
            {order_title && (
              <TextInput
                label={
                  <span>
                    {order_title.title}
                    {order_title.mandatory && <Asterisk>*</Asterisk>}
                  </span>
                }
                value={
                  process.data.fields[order_title.field]
                    ? process.data.fields[order_title.field].value
                    : ""
                }
                placeholder={intl.formatMessage({
                  id: "process > placeholder enter nickname",
                })}
                onChange={(event) => {
                  onValueChanged(order_title, get(event, "target.value", ""));
                  setShouldBlockNavigation(true);
                }}
                error={
                  validationState[order_title.field] &&
                  validationState[order_title.field].validation_error
                }
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col className={bem.e("col-field")}>
            <AutocompleteInput
              label={contract_client_contact.title}
              isRequired={contract_client_contact.mandatory}
              error={
                validationState[contract_client_contact.field] &&
                validationState[contract_client_contact.field].validation_error
              }
              value={
                process.data.fields[contract_client_contact.field]
                  ? process.data.fields[contract_client_contact.field].value
                  : ""
              }
              items={contacts}
              getItemValue={(item) => item.contract_client_contact}
              renderMenu={(children) => {
                return (
                  <div className={children.length > 0 ? "menu" : ""}>
                    {children}
                  </div>
                );
              }}
              isShowDropdown
              inputProps={{
                placeholder: isEnableEnterprise
                  ? intl.formatMessage({
                      id: "process > placeholder enter name",
                    })
                  : intl.formatMessage({
                      id: "process > placeholder enter contact",
                    }),
              }}
              renderItem={(item, isHighlighted) => (
                <div
                  className={`item ${isHighlighted ? "item-highlighted" : ""}`}
                  key={item.id}
                >
                  {item.contract_client_contact}
                </div>
              )}
              onSelect={(value, item) => {
                onSelectContact(item);
                setShouldBlockNavigation(true);
              }}
              onChange={(e, value) => {
                setShouldBlockNavigation(true);
                bindedValueChanged(
                  contract_client_contact,
                  process,
                  template,
                  user,
                  e
                );
                if (get(process, "data.fields.client_id.value")) {
                  setTimeout(() => {
                    autoSuggestContacts({
                      client_id: process.data.fields[client_id.field].value,
                      q: value,
                      template_key: template.key,
                      cb: (items) => {
                        setContacts(items);
                      },
                    });

                    onValueChanged(contract_client_email, "");
                    onValueChanged(contract_client_nickname, "");
                    onValueChanged(contract_client_phone, "");
                    onValueChanged(contact_id, "");
                  }, 500);
                }
                setIsFieldsChanged(true);
              }}
              isOpen={isContactsDropdownOpen}
              setIsOpen={setIsContactsDropdownOpen}
            />
          </Col>
          <Col className={bem.e("col-field")}>
            <TextInput
              label={
                <span>
                  {contract_client_nickname.title}
                  {contract_client_nickname.mandatory && <Asterisk>*</Asterisk>}
                </span>
              }
              value={
                process.data.fields[contract_client_nickname.field]
                  ? process.data.fields[contract_client_nickname.field].value
                  : ""
              }
              placeholder={intl.formatMessage({
                id: "process > placeholder enter nickname",
              })}
              onChange={(event) => {
                setShouldBlockNavigation(true);
                onValueChanged(
                  contract_client_nickname,
                  get(event, "target.value", "")
                );
              }}
              disabled={!isEmpty(selectedContact)}
              error={
                validationState[contract_client_nickname.field] &&
                validationState[contract_client_nickname.field].validation_error
              }
            />
          </Col>
        </Row>
        <Row>
          <Col className={bem.e("col-field")}>
            <TextInput
              label={
                <span>
                  {contract_client_email.title}
                  {contract_client_email.mandatory && <Asterisk>*</Asterisk>}
                </span>
              }
              value={
                process.data.fields[contract_client_email.field]
                  ? process.data.fields[contract_client_email.field].value
                  : ""
              }
              placeholder={intl.formatMessage({
                id: "process > placeholder enter email",
              })}
              onChange={(event) => {
                setShouldBlockNavigation(true);
                onValueChanged(
                  contract_client_email,
                  get(event, "target.value", "")
                );
              }}
              disabled={!isEmpty(selectedContact)}
              error={
                validationState[contract_client_email.field] &&
                validationState[contract_client_email.field].validation_error
              }
            />
          </Col>
          <Col className={bem.e("col-field")}>
            <TextInputFormatted
              label={<span>{contract_client_phone.title}</span>}
              value={
                process.data.fields[contract_client_phone.field]
                  ? process.data.fields[contract_client_phone.field].value
                  : ""
              }
              placeholder={intl.formatMessage({
                id: "process > placeholder enter phone",
              })}
              format={PhoneNumberFormat}
              onChange={(value) => {
                setShouldBlockNavigation(true);
                onValueChanged(contract_client_phone, value);
              }}
              disabled={!isEmpty(selectedContact)}
            />
            <div className="alert-danger">
              {validationState[contract_client_phone.field] &&
                validationState[contract_client_phone.field].validation_error}
            </div>
          </Col>
        </Row>
        {isShowEditClientDetails && (
          <div
            className={bem.e("edit-client-link")}
            onClick={() => setIsOpenEditClientModal(true)}
          >
            <FormattedMessage id="process > edit client details" />
          </div>
        )}
        <EditClientModal
          isOpen={isOpenEditClientModal}
          onToggle={() => {
            setIsOpenEditClientModal(!isOpenEditClientModal);
          }}
          client_id={get(process, `data.fields.client_id.value`)}
          onSubmit={(values) => {
            let templateKey = get(template, "key", "");
            let primaryKey = "is_production";
            if (templateKey === "digital") {
              primaryKey = "is_digital";
            } else if (templateKey === "promotion") {
              primaryKey = "is_promotion";
            }
            const contacts = filter(values.contacts, (contact) => {
              if (contact[primaryKey]) {
                contact.contact_id = contact.id;
                return contact;
              }
            });
            setShouldBlockNavigation(true);
            setContacts(contacts);

            bindedValueChanged(client_name, process, template, user, {
              value: values.client_name,
            });
            if (agency_name) {
              bindedValueChanged(agency_name, process, template, user, {
                value: values.agency,
              });
            }
            let contactId = process.data.fields[contact_id.field]
              ? process.data.fields[contact_id.field].value
              : "";
            let primaryContact = null;

            if (contactId) {
              primaryContact = find(
                values.contacts,
                (item) => item.id === contactId
              );
            } else {
              primaryContact = find(values.contacts, (item) => item.is_primary);
            }

            if (!primaryContact) {
              primaryContact = first(values.contacts);
            }

            if (primaryContact) {
              onSelectContact({
                contract_client_contact:
                  primaryContact.contract_client_contact || "",
                contract_client_email:
                  primaryContact.contract_client_email || "",
                contract_client_nickname:
                  primaryContact.contract_client_nickname || "",
                contract_client_phone:
                  primaryContact.contract_client_phone || "",
                contact_id: primaryContact.id || "",
              });
            }
            setTimeout(() => {
              setIsOpenEditClientModal(false);
            }, 1000);
          }}
        />
      </SectionFields>
    </div>
  );
};
export default OrderClientDetails;
