import * as React from "react";
import { compose, withState, lifecycle, withHandlers } from "recompose";
import { connect } from "react-redux";
import { MdImportantDevices } from "react-icons/lib/md";
import {
  getChannels,
  deleteChannel,
  cloneChannel,
  updateOrderChannels,
  updateChannel,
  addChannelLocal,
  deleteChannelLocal,
  addChannel,
  setOrderChannels,
} from "store/actions/channels";
import { getWorkflows } from "store/actions/workflows";
import { getCurrentServer } from "store/actions/servers";

import {
  get,
  trim,
  map,
  pick,
  startsWith,
  omit,
  forEach,
  isEqual,
  forOwn,
  uniqBy,
  orderBy,
} from "lodash";
import ToastManager from "components/ToastManager";
import View from "./view";
import { injectIntl } from "react-intl";
export default injectIntl(
  compose(
    connect(
      ({ auth, servers, channels, workflows }) => {
        // Get digital stations of current server
        let stations = [];
        const entities = get(
          servers,
          `currentServer.data.entities.digital`,
          {}
        );
        forOwn(entities, (entityStations) => {
          stations = [...stations, ...entityStations];
        });
        stations = uniqBy(stations, "id")
          .map((item) => ({
            ...item,
            station_key: item.key,
          }))
          .filter((station) => {
            return (
              station.key &&
              auth.info.stations.find((i) => i.station_key === station.key)
            );
          });

        return {
          user: auth.user,
          stations: orderBy(stations, ["name"], ["asc"]),
          channels: get(channels, "channels.data.data", []),
          workflows: get(workflows, "workflows.data.data", [])
            .filter((item) => item._source.is_published)
            .map((item) => ({
              ...item._source,
            })),
          isDirtyWorkflows: get(workflows, "workflows.is_dirty", false),
          isDirtyChannels: get(channels, "channels.is_dirty", false),
          loading:
            get(channels, "channels.loading", false) ||
            get(channels, "updateChannel.loading", false) ||
            get(channels, "addChannel.loading", false) ||
            get(channels, "cloneChannel.loading", false) ||
            get(channels, "deleteChannel.loading", false) ||
            get(channels, "cancelChannel.loading", false),
          isFetchChannels:
            get(channels, "channels.is_dirty", false) ||
            !get(channels, "channels.data.page"),
          isFetchWorkflows:
            get(workflows, "workflows.is_dirty", false) ||
            !get(workflows, "workflows.data.page"),
        };
      },
      {
        getChannels,
        deleteChannel,
        cloneChannel,
        updateOrderChannels,
        updateChannel,
        addChannelLocal,
        deleteChannelLocal,
        getWorkflows,
        addChannel,
        getCurrentServer,
        setOrderChannels,
      }
    ),
    withState("currentPublishChannel", "setCurrentPublishChannel", null),
    withState(
      "isOpenChannelPreviewModal",
      "setIsOpenChannelPreviewModal",
      false
    ),
    withState("shouldBlockNavigation", "setShouldBlockNavigation", false),
    withState("currentPreviewChannel", "setCurrentPreviewChannel", null),
    withState("currentOpenForm", "setCurrentOpenForm", null),
    withState("isViewModeForms", "setIsViewModeForms", {}),
    withState("isUnsavedChanges", "setIsUnsavedChanges", ({ channels }) => {
      return map(channels, (item) => ({
        is_hidden: item._source.is_hidden,
        id: item._source.id,
        is_dirty: false,
      }));
    }),
    withState("isOpenNewForm", "setIsOpenNewForm", false),
    withState("currentChannelDelete", "setCurrentChannelDelete", null),
    withState("isOpenConfirmationModal", "setIsOpenConfirmationModal", false),
    withState(
      "isOpenConfirmationUnsavedModal",
      "setIsOpenConfirmationUnsavedModal",
      false
    ),
    withState(
      "isOpenConfirmationActionsUnsavedModalNewChannel",
      "setIsOpenConfirmationActionsUnsavedModalNewChannel",
      false
    ),
    withHandlers({
      onUpdateOrderChannels: ({
        updateOrderChannels,
        channels,
        addChannel,
      }) => (newList) => {
        let data;
        if (newList) {
          data = newList.map((channel, index) => ({
            order: index,
            id: channel._id,
            name: channel._source.name,
          }));
          const hasUnsavedNew = newList.find(
            (c) => c._source.id.indexOf("_new_") === 0
          );
          if (hasUnsavedNew) {
            const newData = {
              ...omit(hasUnsavedNew._source, ["id"]),
              is_draft: true,
              order: newList.length,
              is_hidden: true,
            };
            addChannel(newData);
            return;
          }
        } else {
          data = channels.map((channel, index) => ({
            order: index,
            id: channel._id,
            name: channel._source.name,
          }));
        }
        updateOrderChannels(data);
      },
      onHandledChannelForm: ({ setCurrentOpenForm }) => (action, data) => {
        setCurrentOpenForm(data.id);
      },
    }),
    withHandlers({
      onGetChannels: ({ getChannels, currentStation }) => () => {
        let params = {};
        if (currentStation) {
          params.station_id = currentStation.key;
        }
        getChannels(params);
      },
      onSaveHiddenChannel: ({ updateChannel }) => (channel, checked) => {
        const data = {
          ...pick(channel, ["name", "id"]),
          is_hidden: checked ? false : true,
          action: "hidden",
        };
        updateChannel(channel.id, data);
      },
      onCloneChannel: ({ cloneChannel, channels, onUpdateOrderChannels }) => (
        channel
      ) => {
        const orderCurrentChannel = get(channels, "length", 0);
        let channelsCloned = channels.filter((item) =>
          startsWith(
            get(item, "_source.name", ""),
            `${get(channel, "name", "")}`
          )
        );
        let channelName = trim(
          `${channel.name} copy ${
            channelsCloned.length > 1 ? channelsCloned.length : ""
          }`
        );
        const value = {
          name: channelName,
          fields: get(channel, "fields", []),
          channel_managers: get(channel, "channel_managers", []),
          stations: get(channel, "stations", []),
          is_hidden: true,
          order: orderCurrentChannel + 1,
          media_type: get(channel, "media_type"),
          action: "clone",
          channel_icon: get(channel, "channel_icon", "default"),
          station_id: get(channel, "station_id", ""),
        };
        cloneChannel(value, get(channel, "id"), () => {
          onUpdateOrderChannels();
        });
      },
      onDeleteChannel: ({
        deleteChannel,
        intl,
        currentChannelDelete,
        setIsOpenConfirmationModal,
        setCurrentChannelDelete,
        setCurrentOpenForm,
      }) => () => {
        if (currentChannelDelete) {
          setIsOpenConfirmationModal(false);
          deleteChannel(currentChannelDelete.id, (status) => {
            setCurrentChannelDelete(null);
            setCurrentOpenForm(null); //close all
            ToastManager.show({
              title: <MdImportantDevices />,
              message: intl.formatMessage({
                id: `channel manager > ${
                  status ? "deleted successfully" : "delete error"
                }`,
              }),
              autoDismiss: 1.5,
              level: status ? "success" : "error",
            });
          });
        }
      },
    }),
    lifecycle({
      componentDidMount() {
        const { currentStation } = this.props;
        let params = {};
        if (currentStation) {
          params.station_id = currentStation.key;
        }
        this.props.getChannels(params);
        if (this.props.isFetchWorkflows) {
          this.props.getWorkflows({});
        }
      },
      componentDidUpdate(prevProps) {
        const { currentStation } = this.props;
        if (
          this.props.isDirtyChannels !== prevProps.isDirtyChannels &&
          this.props.isDirtyChannels
        ) {
          let params = {};
          if (currentStation) {
            params.station_id = currentStation.key;
          }
          this.props.getChannels(params);
        }
        if (
          this.props.isDirtyWorkflows !== prevProps.isDirtyWorkflows &&
          this.props.isDirtyWorkflows
        ) {
          this.props.getWorkflows({});
        }
        if (!isEqual(this.props.channels, prevProps.channels)) {
          const channels = this.props.channels;
          let dataView = {};
          forEach(channels, (item) => {
            let isView = item._source.id.indexOf("_new_") === 0 ? false : true; // default edit for new and view for channel saved.
            dataView[item._source.id] = isView;
          });
          this.props.setIsViewModeForms(dataView);
        }
      },
    })
  )(View)
);
