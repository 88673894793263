import { connect } from "react-redux";
import View from "./view";
import { injectIntl } from "react-intl";
import { compose, withHandlers, withState } from "recompose";
import {
  assignVoTeamProcess,
  updateFinalScript,
} from "store/actions/processes";
import {
  getTeamMembers,
  getTeamMembersForAssignment,
} from "store/actions/hubs";
import get from "lodash/get";

const Enhanced = compose(
  connect(
    (state) => ({
      info: state.auth.info,
      members: get(state, "hubs.membersAssignment.data", []),
      isLoading:
        get(state, "hubs.membersAssignment.loading", false) ||
        get(state, "processes.updateFinalScript.loading", false),
    }),
    {
      assignVoTeamProcess,
      getTeamMembers,
      updateFinalScript,
      getTeamMembersForAssignment,
    }
  ),
  withState("selectedUsers", "setSelectedUsers", []),
  withState("isShowSuccessModal", "setIsShowSuccessModal", false),
  withHandlers({
    onSubmit: ({
      setIsShowSuccessModal,
      onClose,
      assignVoTeamProcess,
      process,
      selectedUsers,
      getTeamMembers,
    }) => () => {
      assignVoTeamProcess(
        {
          users: selectedUsers,
          process_id: process._id,
        },
        () => {
          setIsShowSuccessModal(true);
          // recall api to get user capacity on Production queue list
          getTeamMembers({
            sorted: [{ id: "name", desc: false }],
            all_members: true,
          });
          setTimeout(() => {
            onClose();
          }, 1200);
        }
      );
    },
    onGetTeamMembers: ({ getTeamMembersForAssignment }) => (stations) => {
      getTeamMembersForAssignment({
        sorted: [{ id: "name", desc: false }],
        stations,
      });
    },
  })
)(View);
export default injectIntl(Enhanced);
