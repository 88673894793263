import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import bn from "utils/bemnames";
import { replace, get } from "lodash";
import { CloseIcon } from "components/CustomIcons";
const bem = bn.create("enlarge-asset-modal");
function EnlargeAssetModal(props) {
  const { onToggle, isOpen, asset } = props;
  const urlImage = replace(get(asset, "path"), /^https:\/\//i, "http://");
  return (
    <Modal isOpen={isOpen} className={bem.b()}>
      <ModalHeader toggle={onToggle} className={bem.e("header")}>
        <button className={bem.e("close")} onClick={onToggle}>
          <CloseIcon color={"#657894"} />
        </button>
      </ModalHeader>
      <ModalBody className={bem.e("body")}>
        <img
          className={bem.e("image")}
          src={urlImage}
          alt={get(asset, "name")}
        />
      </ModalBody>
    </Modal>
  );
}
EnlargeAssetModal.defaultProps = {
  isOpen: false,
  onToggle: () => {},
};
EnlargeAssetModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};
export default EnlargeAssetModal;
