import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import {
  Modal,
  ModalBody,
  Button,
  ModalHeader,
  FormGroup,
  Form,
  Col,
  Row,
} from "reactstrap";
import { Asterisk } from "components/Elements";
import classnames from "classnames";
import bn from "utils/bemnames";
import * as Yup from "yup";
import { FormattedMessage } from "react-intl";
import TextInput from "components/TextInput";
import { CloseIcon } from "components/CustomIcons";
import Spinner from "components/Spinner";
import { TAG_TYPE_FORMAT } from "components/SelectTags";
const bem = bn.create("unrated-market-modal");

const TagFormModal = ({
  isOpen,
  onToggle,
  onFormSubmit,
  intl,
  isLoading,
  type,
  serverCountry,
}) => {
  const initialValues =
    type === TAG_TYPE_FORMAT
      ? {
          name: "",
        }
      : {
          city: "",
          state: "",
        };
  return (
    <Modal isOpen={isOpen} className={classnames(bem.b())}>
      <ModalHeader toggle={onToggle}>
        {type === TAG_TYPE_FORMAT ? (
          <FormattedMessage id="station > new format" />
        ) : (
          <FormattedMessage id="station > new unrated market" />
        )}

        <button className={bem.e("close")} onClick={onToggle}>
          <CloseIcon color={"#657894"} />
        </button>
      </ModalHeader>
      <ModalBody className={bem.e("body")}>
        <Formik
          initialValues={initialValues}
          validationSchema={
            type === TAG_TYPE_FORMAT
              ? Yup.object().shape({
                  name: Yup.string().required(
                    intl.formatMessage({
                      id: "validate > name is required",
                    })
                  ),
                })
              : Yup.object().shape({
                  city: Yup.string().required(
                    intl.formatMessage({
                      id: "validate > city is required",
                    })
                  ),
                  state: Yup.string().required(
                    intl.formatMessage({
                      id:
                        serverCountry === "CA"
                          ? "validate > province is required"
                          : "validate > state is required",
                    })
                  ),
                })
          }
          onSubmit={onFormSubmit}
        >
          {(formProps) => {
            const errors = formProps.errors;
            const touched = formProps.touched;
            const values = formProps.values;
            return (
              <Form onSubmit={formProps.handleSubmit}>
                <div className={bem.e("content-form")}>
                  <div className={bem.e("form-padding")}>
                    {type === TAG_TYPE_FORMAT ? (
                      <Row>
                        <Col xs={12} className={bem.e("col-reset")}>
                          <FormGroup>
                            <TextInput
                              label={
                                <span>
                                  <FormattedMessage id="station > format" />
                                  <Asterisk>*</Asterisk>
                                </span>
                              }
                              type="text"
                              name="name"
                              placeholder={intl.formatMessage({
                                id: "station > enter name",
                              })}
                              value={values.name}
                              onChange={formProps.handleChange}
                              onBlur={formProps.handleBlur}
                              error={touched.name && errors.name}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        <Col xs={12} className={bem.e("col-reset")}>
                          <FormGroup>
                            <TextInput
                              label={
                                <span>
                                  <FormattedMessage id="station > city" />
                                  <Asterisk>*</Asterisk>
                                </span>
                              }
                              type="text"
                              name="city"
                              placeholder={intl.formatMessage({
                                id: "station > enter city",
                              })}
                              value={values.city}
                              onChange={formProps.handleChange}
                              onBlur={formProps.handleBlur}
                              error={touched.city && errors.city}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} className={bem.e("col-reset")}>
                          <FormGroup>
                            <TextInput
                              label={
                                <span>
                                  <FormattedMessage
                                    id={
                                      serverCountry === "CA"
                                        ? "station > enter province"
                                        : "station > enter state"
                                    }
                                  />
                                  <Asterisk>*</Asterisk>
                                </span>
                              }
                              type="text"
                              name="state"
                              placeholder={intl.formatMessage({
                                id:
                                  serverCountry === "CA"
                                    ? "station > enter province"
                                    : "station > enter state",
                              })}
                              value={values.state}
                              onChange={formProps.handleChange}
                              onBlur={formProps.handleBlur}
                              error={touched.state && errors.state}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    )}
                  </div>
                </div>

                <div
                  className={classnames(
                    bem.e("form-buttons"),
                    bem.e("form-padding")
                  )}
                >
                  <Button color="blue" type="submit" className="btn btn-radius">
                    <FormattedMessage id={`station > button add and select`} />
                  </Button>
                  <Button
                    color="blue"
                    type="button"
                    outline
                    onClick={onToggle}
                    className="btn btn-radius"
                  >
                    <FormattedMessage id={`station > button cancel`} />
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </ModalBody>
      <Spinner isLoading={isLoading} />
    </Modal>
  );
};

TagFormModal.propTypes = {
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
};

TagFormModal.defaultProps = {
  onToggle: () => {},
};

export default TagFormModal;
