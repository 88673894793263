import TagFormModal from "./view";
import { injectIntl } from "react-intl";
import { compose, withHandlers } from "recompose";
import { addUnratedMarket } from "store/actions/tags";
import { connect } from "react-redux";
import { find, get, lowerCase } from "lodash";
import { TAG_TYPE_FORMAT } from "components/SelectTags";

export default injectIntl(
  compose(
    connect(
      (state) => {
        return {
          tagTypes: get(state, "auth.info.tags.tag_types", []),
          tags: get(state, "auth.info.tags.tags", []),
          isLoading: get(state, "tags.unratedMarket.loading"),
          serverCountry: get(state, "auth.info.server_country", "US"),
        };
      },
      {
        addUnratedMarket,
      }
    ),
    withHandlers({
      onFormSubmit: ({
        addUnratedMarket,
        onSubmit,
        tagTypes,
        tags,
        onToggle,
        type,
      }) => (values) => {
        const tagType = find(tagTypes, (item) => item.name === "group");
        const rootTag = find(tags, (item) => lowerCase(item.title) === lowerCase(type));
        addUnratedMarket(
          {
            name:
              type === TAG_TYPE_FORMAT
                ? values.name
                : `${values.city}, ${values.state}`,
            is_default: true,
            active: true,
            order: get(rootTag, "children.length", 0),
            tag_type_id: get(tagType, "id"),
            parent_id: get(rootTag, "id"),
            is_resource: true,
          },
          (response) => {
            onToggle();
            onSubmit(response);
          }
        );
      },
    })
  )(TagFormModal)
);
