import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { Label } from 'reactstrap';

const InputMinute = props => {
  const { name, value, onChange, bem, minLabel, noPlaceHolder, minuteReadonly, useWrapper } = props;

  const [data, setData] = useState(value);

  useEffect(() => {
    if(value === ''){
      setData('');
      return;
    }
    setData(value);
  }, [value]);

  const validateTime = e => {
    const re = /^[0-9\b]+$/;

    let value = e.target.value;

    if (value === '00') {
      setData('00');
    } else if (value !== '' && re.test(value)) {
      const iValue = parseInt(value) || 0;

      if (iValue > 59) value = 59;

      setData(value);
    } else if (value === '') {
      setData('');
    }
  };

  const handleOnBlur = e => {
    let value = e.target.value;

    const iValue = value === '' ? value : parseInt(value);

    let changeData = iValue;

    if (iValue !== '' && iValue < 10){
      changeData = `0${iValue}`;
    } 

    setData(changeData);

    onChange(iValue);
  };


  if(!useWrapper){
    return (
      !minuteReadonly ? <input
        autoComplete="off"
        className={classnames(bem.e('minute'), bem.e('input'))}
        placeholder={noPlaceHolder ? "" : "00"}
        type="text"
        name={name}
        value={data}
        maxLength="2"
        onChange={validateTime}
        onBlur={handleOnBlur}
      /> : <div className={bem.e("time-text")}>{data}</div>
    )
  }

  return (
    <div className="minute-container">
      {minLabel && (<Label>
        <span>{minLabel}</span>
      </Label>)}
      <div className="minute-input-wrapper">
          <input
          autoComplete="off"
          className={classnames(bem.e('minute'), bem.e('input'))}
          placeholder={noPlaceHolder ? "" : "00"}
          type="text"
          name={name}
          value={data}
          maxLength="2"
          onChange={validateTime}
          onBlur={handleOnBlur}
        />
      </div>
    </div>
  );
};

export default InputMinute;
