import React from "react";
import bn from "utils/bemnames";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
const bem = bn.create("schedule-action-statement");

export default function ActionStatement(props) {
  const { message, onClick, buttonText } = props;
  return (
    <div className={bem.b()}>
      <span className={bem.e("text")}>{message}</span>
      <span onClick={onClick} className={bem.e("undo")}>
        {buttonText}
      </span>
    </div>
  );
}
ActionStatement.propTypes = {
  message: PropTypes.any,
  onClick: PropTypes.func,
  buttonText: PropTypes.any,
};
ActionStatement.defaultProps = {
  message: "",
  onClick: () => {},
  buttonText: (
    <FormattedMessage id="process > promotion > liner > schedule > undo" />
  ),
};
