export const FETCH_ITEMS_AVAILABILITY_REQUEST =
  "FETCH_ITEMS_AVAILABILITY_REQUEST";
export const FETCH_ITEMS_AVAILABILITY_SUCCESS =
  "FETCH_ITEMS_AVAILABILITY_SUCCESS";
export const FETCH_ITEMS_AVAILABILITY_ERROR = "FETCH_ITEMS_AVAILABILITY_ERROR";

export const CHECK_SCHEDULING_AVAILABILITY_REQUEST =
  "CHECK_SCHEDULING_AVAILABILITY_REQUEST";
export const CHECK_SCHEDULING_AVAILABILITY_SUCCESS =
  "CHECK_SCHEDULING_AVAILABILITY_SUCCESS";
export const CHECK_SCHEDULING_AVAILABILITY_ERROR =
  "CHECK_SCHEDULING_AVAILABILITY_ERROR";

export const FETCH_CLOCKS_DATA_REQUEST = "FETCH_CLOCKS_DATA_REQUEST";
export const FETCH_CLOCKS_DATA_SUCCESS = "FETCH_CLOCKS_DATA_SUCCESS";
export const FETCH_CLOCKS_DATA_ERROR = "FETCH_CLOCKS_DATA_ERROR";

export const PROGRAMMINGS_FIND_INVENTORY_REQUEST =
  "PROGRAMMINGS_FIND_INVENTORY_REQUEST";
export const PROGRAMMINGS_FIND_INVENTORY_SUCCESS =
  "PROGRAMMINGS_FIND_INVENTORY_SUCCESS";
export const PROGRAMMINGS_FIND_INVENTORY_ERROR =
  "PROGRAMMINGS_FIND_INVENTORY_ERROR";

export const PROGRAMMINGS_SAVE_INVENTORY_REQUEST =
  "PROGRAMMINGS_SAVE_INVENTORY_REQUEST";
export const PROGRAMMINGS_SAVE_INVENTORY_SUCCESS =
  "PROGRAMMINGS_SAVE_INVENTORY_SUCCESS";
export const PROGRAMMINGS_SAVE_INVENTORY_ERROR =
  "PROGRAMMINGS_SAVE_INVENTORY_ERROR";

export const PROGRAMMINGS_RELEASE_INVENTORY_REQUEST =
  "PROGRAMMINGS_RELEASE_INVENTORY_REQUEST";
export const PROGRAMMINGS_RELEASE_INVENTORY_SUCCESS =
  "PROGRAMMINGS_RELEASE_INVENTORY_SUCCESS";
export const PROGRAMMINGS_RELEASE_INVENTORY_ERROR =
  "PROGRAMMINGS_RELEASE_INVENTORY_ERROR";

export const PROGRAMMINGS_SET_SCHEDULING_AVAILABILITY_REQUEST =
  "PROGRAMMINGS_SET_SCHEDULING_AVAILABILITY_REQUEST";
export const PROGRAMMINGS_SET_SCHEDULING_AVAILABILITY_SUCCESS =
  "PROGRAMMINGS_SET_SCHEDULING_AVAILABILITY_SUCCESS";
export const PROGRAMMINGS_SET_SCHEDULING_AVAILABILITY_ERROR =
  "PROGRAMMINGS_SET_SCHEDULING_AVAILABILITY_ERROR";

export const SET_CLOCKS_DATA = "SET_CLOCKS_DATA";

export const PROGRAMMINGS_GET_SCHEDULES_REQUEST =
  "PROGRAMMINGS_GET_SCHEDULES_REQUEST";
export const PROGRAMMINGS_GET_SCHEDULES_SUCCESS =
  "PROGRAMMINGS_GET_SCHEDULES_SUCCESS";
export const PROGRAMMINGS_GET_SCHEDULES_ERROR =
  "PROGRAMMINGS_GET_SCHEDULES_ERROR";

export const PROGRAMMINGS_SEARCH_REQUEST = "PROGRAMMINGS_SEARCH_REQUEST";
export const PROGRAMMINGS_SEARCH_SUCCESS = "PROGRAMMINGS_SEARCH_SUCCESS";
export const PROGRAMMINGS_SEARCH_ERROR = "PROGRAMMINGS_SEARCH_ERROR";

export const PROGRAMMINGS_SWAP_SCHEDULE_REQUEST =
  "PROGRAMMINGS_SWAP_SCHEDULE_REQUEST";
export const PROGRAMMINGS_SWAP_SCHEDULE_SUCCESS =
  "PROGRAMMINGS_SWAP_SCHEDULE_SUCCESS";
export const PROGRAMMINGS_SWAP_SCHEDULE_ERROR =
  "PROGRAMMINGS_SWAP_SCHEDULE_ERROR";
export const PROGRAMMINGS_GET_SWAPPABLE_SLOTS_REQUEST =
  "PROGRAMMINGS_GET_SWAPPABLE_SLOTS_REQUEST";
export const PROGRAMMINGS_GET_SWAPPABLE_SLOTS_SUCCESS =
  "PROGRAMMINGS_GET_SWAPPABLE_SLOTS_SUCCESS";
export const PROGRAMMINGS_GET_SWAPPABLE_SLOTS_ERROR =
  "PROGRAMMINGS_GET_SWAPPABLE_SLOTS_ERROR";

export const PROGRAMMINGS_GET_SCHEDULES_ITEMS_LOG_REQUEST =
  "PROGRAMMINGS_GET_SCHEDULES_ITEMS_LOG_REQUEST";
export const PROGRAMMINGS_GET_SCHEDULES_ITEMS_LOG_SUCCESS =
  "PROGRAMMINGS_GET_SCHEDULES_ITEMS_LOG_SUCCESS";
export const PROGRAMMINGS_GET_SCHEDULES_ITEMS_LOG_ERROR =
  "PROGRAMMINGS_GET_SCHEDULES_ITEMS_LOG_ERROR";

export const ADD_PROGRAMMING_ELEMENT_REQUEST =
  "ADD_PROGRAMMING_ELEMENT_REQUEST";
export const ADD_PROGRAMMING_ELEMENT_SUCCESS =
  "ADD_PROGRAMMING_ELEMENT_SUCCESS";
export const ADD_PROGRAMMING_ELEMENT_ERROR = "ADD_PROGRAMMING_ELEMENT_ERROR";

export const PROGRAMMINGS_GET_ASSOCIATE_ELEMENTS_REQUEST =
  "PROGRAMMINGS_GET_ASSOCIATE_ELEMENTS_REQUEST";
export const PROGRAMMINGS_GET_ASSOCIATE_ELEMENTS_SUCCESS =
  "PROGRAMMINGS_GET_ASSOCIATE_ELEMENTS_SUCCESS";
export const PROGRAMMINGS_GET_ASSOCIATE_ELEMENTS_ERROR =
  "PROGRAMMINGS_GET_ASSOCIATE_ELEMENTS_ERROR";

export const FORGET_ASSOCIATE_ELEMENTS = "FORGET_ASSOCIATE_ELEMENTS";
export const PROGRAMMINGS_SET_ASSOCIATE_ELEMENTS_SUCCESS =
  "PROGRAMMINGS_SET_ASSOCIATE_ELEMENTS_SUCCESS";

export const GET_QUICK_VIEW_REQUEST = "GET_QUICK_VIEW_REQUEST";
export const GET_QUICK_VIEW_REQUEST_SUCCESS = "GET_QUICK_VIEW_REQUEST_SUCCESS";
export const GET_QUICK_VIEW_REQUEST_ERROR = "GET_QUICK_VIEW_REQUEST_ERROR";

export const END_CONTEST_EARLY_REQUEST = "END_CONTEST_EARLY_REQUEST";
export const END_CONTEST_EARLY_SUCCESS = "END_CONTEST_EARLY_SUCCESS";
export const END_CONTEST_EARLY_ERROR = "END_CONTEST_EARLY_ERROR";

export const PROGRAMMINGS_DELETE_SLOT_REQUEST =
  "PROGRAMMINGS_DELETE_SLOT_REQUEST";
export const PROGRAMMINGS_DELETE_SLOT_SUCCESS =
  "PROGRAMMINGS_DELETE_SLOT_SUCCESS";
export const PROGRAMMINGS_DELETE_SLOT_ERROR = "PROGRAMMINGS_DELETE_SLOT_ERROR";

export const PROGRAMMINGS_UNDO_DELETE_SLOT_REQUEST =
  "PROGRAMMINGS_UNDO_DELETE_SLOT_REQUEST";
export const PROGRAMMINGS_UNDO_DELETE_SLOT_SUCCESS =
  "PROGRAMMINGS_UNDO_DELETE_SLOT_SUCCESS";
export const PROGRAMMINGS_UNDO_DELETE_SLOT_ERROR =
  "PROGRAMMINGS_UNDO_DELETE_SLOT_ERROR";

export const PROGRAMMINGS_ORDERS_LIST_REQUEST =
  "PROGRAMMINGS_ORDERS_LIST_REQUEST";
export const PROGRAMMINGS_ORDERS_LIST_SUCCESS =
  "PROGRAMMINGS_ORDERS_LIST_SUCCESS";
export const PROGRAMMINGS_ORDERS_LIST_ERROR = "PROGRAMMINGS_ORDERS_LIST_ERROR";

export const SET_ORDERS_LIST = "SET_ORDERS_LIST";

export const PROGRAMMINGS_GET_FILL_SETTINGS_REQUEST =
  "PROGRAMMINGS_GET_FILL_SETTINGS_REQUEST";
export const PROGRAMMINGS_GET_FILL_SETTINGS_SUCCESS =
  "PROGRAMMINGS_GET_FILL_SETTINGS_SUCCESS";
export const PROGRAMMINGS_GET_FILL_SETTINGS_ERROR =
  "PROGRAMMINGS_GET_FILL_SETTINGS_ERROR";

export const PROGRAMMINGS_UPDATE_FILL_SETTINGS_REQUEST =
  "PROGRAMMINGS_UPDATE_FILL_SETTINGS_REQUEST";
export const PROGRAMMINGS_UPDATE_FILL_SETTINGS_SUCCESS =
  "PROGRAMMINGS_UPDATE_FILL_SETTINGS_SUCCESS";
export const PROGRAMMINGS_UPDATE_FILL_SETTINGS_ERROR =
  "PROGRAMMINGS_UPDATE_FILL_SETTINGS_ERROR";

export const PROGRAMMINGS_FILL_SCHEDULING_MANUALLY_REQUEST =
  "PROGRAMMINGS_FILL_SCHEDULING_MANUALLY_REQUEST";
export const PROGRAMMINGS_FILL_SCHEDULING_MANUALLY_SUCCESS =
  "PROGRAMMINGS_FILL_SCHEDULING_MANUALLY_SUCCESS";
export const PROGRAMMINGS_FILL_SCHEDULING_MANUALLY_ERROR =
  "PROGRAMMINGS_FILL_SCHEDULING_MANUALLY_ERROR";

export const PROGRAMMINGS_FILL_SCHEDULING_UNDO_REQUEST =
  "PROGRAMMINGS_FILL_SCHEDULING_UNDO_REQUEST";
export const PROGRAMMINGS_FILL_SCHEDULING_UNDO_SUCCESS =
  "PROGRAMMINGS_FILL_SCHEDULING_UNDO_SUCCESS";
export const PROGRAMMINGS_FILL_SCHEDULING_UNDO_ERROR =
  "PROGRAMMINGS_FILL_SCHEDULING_UNDO_ERROR";

export const PROGRAMMINGS_RELEASE_RESERVED_SLOTS_REQUEST =
  "PROGRAMMINGS_RELEASE_RESERVED_SLOTS_REQUEST";
export const PROGRAMMINGS_RELEASE_RESERVED_SLOTS_SUCCESS =
  "PROGRAMMINGS_RELEASE_RESERVED_SLOTS_SUCCESS";
export const PROGRAMMINGS_RELEASE_RESERVED_SLOTS_ERROR =
  "PROGRAMMINGS_RELEASE_RESERVED_SLOTS_ERROR";

export const CHECK_SCHEDULING_AVAILABILITY_RESET =
  "CHECK_SCHEDULING_AVAILABILITY_RESET";
