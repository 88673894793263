import React from "react";
import { FormattedMessage } from "react-intl";
const categories = [
  {
    label: (
      <FormattedMessage id="process > option label arts and entertainment" />
    ),
    value: "Arts & Entertainment",
  },
  {
    label: <FormattedMessage id="process > option label attorneys" />,
    value: "Attorneys",
  },
  {
    label: <FormattedMessage id="process > option label automotive" />,
    value: "Automotive",
  },
  {
    label: <FormattedMessage id="process > option label community" />,
    value: "Community",
  },
  {
    label: <FormattedMessage id="process > option label education" />,
    value: "Education",
  },
  {
    label: <FormattedMessage id="process > option label finance" />,
    value: "Finance",
  },
  {
    label: <FormattedMessage id="process > option label food and beverage" />,
    value: "Food & Beverage",
  },
  {
    label: <FormattedMessage id="process > option label health and medical" />,
    value: "Health & Medical",
  },
  {
    label: <FormattedMessage id="process > option label home and garden" />,
    value: "Home & Garden",
  },
  {
    label: <FormattedMessage id="process > option label marine and boating" />,
    value: "Marine & Boating",
  },
  {
    label: <FormattedMessage id="process > option label media" />,
    value: "Media",
  },
  {
    label: <FormattedMessage id="process > option label pets and animals" />,
    value: "Pets & Animals",
  },
  {
    label: <FormattedMessage id="process > option label professionals" />,
    value: "Professionals",
  },
  {
    label: <FormattedMessage id="process > option label real estate" />,
    value: "Real Estate",
  },
  {
    label: <FormattedMessage id="process > option label religion" />,
    value: "Religion",
  },
  {
    label: <FormattedMessage id="process > option label services" />,
    value: "Services",
  },
  {
    label: <FormattedMessage id="process > option label shopping" />,
    value: "Shopping",
  },
  {
    label: (
      <FormattedMessage id="process > option label sports and recreation" />
    ),
    value: "Sports & Recreation",
  },
  {
    label: <FormattedMessage id="process > option label travel" />,
    value: "Travel",
  },
];
export default categories;
