import React from "react";
import bn from "utils/bemnames";
import { get, find } from "lodash";
import ChannelMedia from "./ChannelMedia";
import ReviewHistory from "components/ReviewHistory";

const bem = bn.create("review-media");
const ReviewMedia = ({
  process,
  step,
  template,
  user,
  event,
  isView,
  saveFormValues,
  ...rest
}) => {
  const channels = get(process, "data.fields.marketing_channels.value", []);
  const forMarketingChannel = get(
    process,
    "data.fields.for_marketing_channel.value.id"
  );
  const channelMedia = find(
    channels,
    (channel) => channel.id === forMarketingChannel
  );
  const isShowReviewHistory = !rest.isClientReview;
  return (
    <div className={bem.b()}>
      <div className={bem.e("channels")}>
        <ChannelMedia
          process={process}
          step={step}
          template={template}
          user={user}
          channel={channelMedia}
          saveFormValues={saveFormValues}
          isView={isView}
          onButtonClick={rest.onButtonClick}
          valueChanged={rest.valueChanged}
          setCurrentMedia={rest.setCurrentMedia}
          currentMedia={rest.currentMedia}
          currentAsset={rest.currentAsset}
          setCurrentAsset={rest.setCurrentAsset}
          onAutoSubmit={rest.onAutoSubmit}
          isRejectConfirmationModalOpen={rest.isRejectConfirmationModalOpen}
          setIsRejectConfirmationModalOpen={
            rest.setIsRejectConfirmationModalOpen
          }
          buttonClicked={rest.buttonClicked}
          isClientReview={rest.isClientReview}
          isClientReviewed={rest.isClientReviewed}
          isShowHelperTooltip={rest.isShowHelperTooltip}
          mainSlickRef={rest.mainSlickRef}
        />
      </div>
      {isShowReviewHistory && <ReviewHistory process={process} />}
    </div>
  );
};
export default ReviewMedia;
