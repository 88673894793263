import {
  INIT_NEW_PROCESS,
  SAVE_FORM_VALUES_REQUEST,
  PROCESS_VALUE_CHANGED,
  SAVE_FORM_VALUES_SUCCESS,
  SAVE_FORM_VALUES_ERROR,
  FETCH_PROCESS_REQUEST,
  FETCH_PROCESS_SUCCESS,
  FETCH_PROCESS_ERROR,
  SET_ACTIVE_TAB,
  DELETE_PROCESS_REQUEST,
  DELETE_PROCESS_SUCCESS,
  DELETE_PROCESS_ERROR,
  FETCH_PROCESSES_REQUEST,
  FETCH_PROCESSES_SUCCESS,
  FETCH_PROCESSES_ERROR,
  FORGET_PRCESSES,
  FORGET_PROCESS,
  SET_TAB_TO_EDIT,
  RESET_TAB,
  UPDATE_PROCESS_INCOMING,
  UPDATE_PROCESSES_INCOMING,
  FETCH_PROCESSES_INCOMING_REQUEST,
  FETCH_PROCESSES_INCOMING_SUCCESS,
  FETCH_REVIEW_PROCESS_REQUEST,
  FETCH_REVIEW_PROCESS_SUCCESS,
  FETCH_REVIEW_PROCESS_ERROR,
  SET_EXTRA_DATA_REVIEW_PROCESS,
  ASSIGNMENT_PROCESS_REQUEST,
  ASSIGNMENT_PROCESS_SUCCESS,
  ASSIGNMENT_PROCESS_ERROR,
  AUTOMATE_DUBBING_REQUEST,
  AUTOMATE_DUBBING_SUCCESS,
  AUTOMATE_DUBBING_ERROR,
  AUTOMATE_DUBBING_PROGRESS,
  RESET_PROCESS,
  FORGET_PROCESSES_FILTER_SUCCESS,
  ARCHIVE_PROCESS_REQUEST,
  ARCHIVE_PROCESS_SUCCESS,
  ARCHIVE_PROCESS_ERROR,
  ASSIGN_TEAM_PROCESS_REQUEST,
  ASSIGN_TEAM_PROCESS_SUCCESS,
  ASSIGN_TEAM_PROCESS_ERROR,
  FETCH_PROCESSES_BY_IDS_SUCCESS,
  REMOVE_PROCESS_FROM_LIST,
  SET_DIRTY_PROCESS_INCOMING_SUCCESS,
  ADD_ROTATION_REQUEST,
  ADD_ROTATION_SUCCESS,
  ADD_ROTATION_ERROR,
  SET_ACTIVE_FILTER_PROCESSES,
  SEND_BACK_PROCESS_REQUEST,
  SEND_BACK_PROCESS_SUCCESS,
  SEND_BACK_PROCESS_ERROR,
  COPY_ORDER_REQUEST,
  COPY_ORDER_SUCCESS,
  COPY_ORDER_ERROR,
  ASSIGN_VO_TEAM_PROCESS_REQUEST,
  ASSIGN_VO_TEAM_PROCESS_SUCCESS,
  ASSIGN_VO_TEAM_PROCESS_ERROR,
  UPDATE_FINAL_SCRIPT_REQUEST,
  UPDATE_FINAL_SCRIPT_SUCCESS,
  UPDATE_FINAL_SCRIPT_ERROR,
  UNARCHIVE_PROCESS_REQUEST,
  UNARCHIVE_PROCESS_SUCCESS,
  UNARCHIVE_PROCESS_ERROR,
  CONVERT_SPEC_TO_SOLD_REQUEST,
  CONVERT_SPEC_TO_SOLD_SUCCESS,
  CONVERT_SPEC_TO_SOLD_ERROR,
  UNARCHIVE_PROCESS_FROM_PUSHER,
  ARCHIVED_PROCESS_FROM_PUSHER,
  CHECK_AUTO_DUBBING_REQUEST,
  CHECK_AUTO_DUBBING_SUCCESS,
  CHECK_AUTO_DUBBING_ERROR,
} from "store/types/processes";
import {
  SET_MESSAGES,
  EDIT_MESSAGE_SUCCESS,
  DELETE_MESSAGE_SUCCESS,
  DELETE_MESSAGES_SUCCESS,
} from "store/types/messages";
import {
  get,
  concat,
  uniq,
  omit,
  uniqBy,
  map,
  filter,
  includes,
  find,
  forOwn,
  isEmpty,
} from "lodash";
import { LOGOUT_USER, AUTH_USER } from "store/types/auth";
import { pageSize } from "utils/config";
const initialState = {
  processes: {
    // This object contains all templates list
    // this is mainly required in templates listing page
    // where we show all templates list and loading icon if srevers are loading
    data: [],
    error: null,
    loading: false,
  },
  process: {
    // This object contains particular template object with id mapping
    // this is mainly required in template details page
    // where we show particular template detail and loading icon if srever details is loading
    // below is example object
    // "<id>": {
    data: { fields: {} },
    error: null,
    loading: null,
    spotSwitching: false,
    is_dirty: false,
    // }
  },
  deleteProcess: {
    // This object will be useful to show loading icon and status when user want to delete template
    data: { fields: [] },
    error: null,
    loading: false,
  },
  itemsAvailability: {
    data: null,
    error: null,
    loading: false,
  },
  extraReviewProcess: {
    data: null,
  },
  dubbingProcesses: {
    data: {},
  },
  assignTeamProcess: {
    error: null,
    loading: false,
  },
  addRotation: {
    loading: null,
    error: null,
  },
  activeFilter: "",
  sendBackProgress: {
    error: null,
    loading: false,
  },
  copyOrder: {
    error: null,
    loading: false,
  },
  updateFinalScript: {
    error: null,
    loading: false,
  },
  unarchiveOrder: {
    error: null,
    loading: false,
  },
  convertSpecToSold: {
    error: null,
    loading: false,
  },
  archiveOrder: {
    error: null,
    loading: false,
  },
  autoDubbingEnabled: {
    data: false,
    error: null,
    loading: false,
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVE_FILTER_PROCESSES:
      return {
        ...state,
        activeFilter: action.payload,
      };
    case FETCH_PROCESSES_REQUEST: {
      let data = [];
      if (
        // case: first search will reset data or change search text
        (get(action, "payload.search") &&
          get(state, `processes.filter_${action.payload.filter}.search`) !==
            get(action, "payload.search")) ||
        // case: empty search (not first load)
        (get(action, "payload.search") === "" &&
          get(state, `processes.filter_${action.payload.filter}.search`)) ||
        // case: sort when list loaded
        get(action, "payload.sortTotally")
      ) {
        data = [];
        // case: other
      } else {
        data = get(state, `processes.filter_${action.payload.filter}.data`, []);
      }
      return {
        ...state,
        processes: {
          ...state.processes,
          ["filter_" + action.payload.filter]: {
            data,
            pages: state.processes["filter_" + action.payload.filter]
              ? state.processes["filter_" + action.payload.filter].pages
              : null,
            page: state.processes["filter_" + action.payload.filter]
              ? state.processes["filter_" + action.payload.filter].page
              : 1,
            rpp: state.processes["filter_" + action.payload.filter]
              ? state.processes["filter_" + action.payload.filter].rpp
              : pageSize,
            error: null,
            loading: true,
          },
        },
      };
    }
    case FETCH_PROCESSES_SUCCESS: {
      const page = parseInt(get(action, "payload.page", 1));
      let filterProcessesData = [];
      if (page === 1) {
        filterProcessesData = action.payload.data;
      } else {
        const processesUpdated = map(
          state.processes["filter_" + action.payload.filter].data,
          (process) => {
            const existsProcess = find(
              action.payload.data,
              (i) => i._id === process._id
            );
            if (existsProcess) return existsProcess;
            return process;
          }
        );
        filterProcessesData = uniqBy(
          [...processesUpdated, ...action.payload.data],
          "_id"
        );
      }

      return {
        ...state,
        process: {
          ...get(state, "process"),
          lastEditTab: [],
          activeTab: null,
          is_dirty: true,
        },
        processes: {
          ...state.processes,
          ["filter_" + action.payload.filter]: {
            ...action.payload,
            data: filterProcessesData,
            error: null,
            loading: false,
          },
        },
      };
    }
    case FETCH_PROCESSES_ERROR: {
      return {
        ...state,
        processes: {
          ...state.processes,
          ["filter_" + action.payload.filter]: {
            data: [],
            error: action.error,
            loading: false,
          },
        },
      };
    }
    case FORGET_PRCESSES: {
      let new_processes = { ...state.processes };
      Object.keys(new_processes).map((k) => {
        if (k.indexOf("filter_") === 0) new_processes[k].is_dirty = true;
        return true;
      });
      return {
        ...state,
        processes: new_processes,
      };
    }
    case FORGET_PROCESSES_FILTER_SUCCESS: {
      let newForgetProcesses = { ...state.processes };
      Object.keys(newForgetProcesses).forEach((k) => {
        if (
          k.indexOf("filter_") === 0 &&
          k === `filter_${action.payload.filter}`
        ) {
          // PRODUCTION - Search word not clearing between Queues => https://tasks.getventive.com/projects/4E7B3-1E8
          delete newForgetProcesses[k];
          // new_processes[k].is_dirty = true;
          // new_processes[k].page = 1; // reset page
        }
      });
      return {
        ...state,
        processes: newForgetProcesses,
      };
    }
    case FETCH_PROCESSES_INCOMING_REQUEST: {
      return {
        ...state,
        processes: {
          ...state.processes,
          ["filter_" + action.payload.filter]: {
            ...get(state, `processes.filter_${action.payload.filter}`),
            current_dirty: null,
          },
        },
      };
    }
    case FETCH_PROCESSES_INCOMING_SUCCESS: {
      let newProcesses = {...state.processes};
      const processData = get(action, "payload.data.0"); // array length always 1
      forOwn(state.processes, (_, key) => {
        if (
          key.indexOf("filter_") === 0 &&
          key === `filter_${action.payload.filter}`
        ) {
          if (processData) {
            // update data
            newProcesses[key] = {
              ...newProcesses[key],
              data: map(newProcesses[key].data, (item) => {
                if (item._id === processData._id) {
                  return processData;
                }
                return item;
              }),
            };
          } else {
            newProcesses[key] = {
              ...newProcesses[key],
              data: filter(
                newProcesses[key].data,
                (item) => item._id !== action.payload.process_id
              ),
            };
          }
        }
      });

      return {
        ...state,
        processes: newProcesses,
        // only refresh process data if user is open the same process
        process:
          action.payload.process_id &&
          action.payload.process_id ===
            get(state, "process.data.fields.key.value")
            ? {
                ...state.process,
                is_dirty: true,
              }
            : state.process,
      };
    }
    case UPDATE_PROCESSES_INCOMING: {
      let new_processes = state.processes;
      forOwn(state.processes, (value, key) => {
        if (key.indexOf("filter_") === 0) {
          let processKey = get(action, "payload.message.process_key");
          new_processes[key].is_dirty = true;
          new_processes[key].current_dirty = processKey;
        }
      });

      return {
        ...state,
        processes: new_processes,
      };
    }
    case INIT_NEW_PROCESS:
      return {
        ...state,
        process: action.payload,
      };
    case PROCESS_VALUE_CHANGED:
      if (action.payload.value === null) {
        let new_state = { ...state };
        delete new_state.process.data.fields[action.payload.item.field];
        return new_state;
      }
      let field = {};
      field = { ...state.process.data.fields[action.payload.item.field] };

      let history = {
        time: new Date(),
        user: action.payload.user.name,
        value: action.payload.value,
      };
      if (!field || field.history === undefined) {
        field = {
          history: [history],
        };
      } else if (field.is_dirty) {
        field.history[field.history.length - 1].time = new Date();
        field.history[field.history.length - 1].value = action.payload.value;
      } else field.history.push(history);
      field.is_dirty = true;
      field.value = action.payload.value;
      if (action.payload.item.field === "next_users" && !action.payload.value) {
        field.is_dirty = true;
        field.value = [action.payload.user.id];
      }
      let processValueChangedFields = {
        ...state.process.data.fields,
        [action.payload.item.field]: {
          ...state.process.data.fields[action.payload.item.field],
          ...field,
        },
      };
      if (action.payload.item.field === "spot_info") {
        //spot_info_fields_dirty to process data. To implement auto update data on process incoming from pusher. We only update the fields is not dirty
        const changingFields = get(action, "payload.item.changing", []);
        const currentSpotInfoFieldsDirty = get(
          state,
          "process.data.fields.spot_info_fields_dirty.value",
          []
        );
        const spotInfoDirty = changingFields.map((item, i) => {
          if (currentSpotInfoFieldsDirty && currentSpotInfoFieldsDirty[i]) {
            return uniq([...item, ...currentSpotInfoFieldsDirty[i]]);
          }
          return item;
        });
        processValueChangedFields = {
          ...processValueChangedFields,
          spot_info_fields_dirty: {
            value: spotInfoDirty,
          },
        };
      }
      return {
        ...state,
        process: {
          ...state.process,
          data: {
            fields: processValueChangedFields,
            loaded_fields: {
              ...state.process.data.loaded_fields,
            },
          },
        },
      };
    case SAVE_FORM_VALUES_SUCCESS:
      let fields = { ...state.process.data.fields };
      if (
        !action.payload.chat_message ||
        action.payload.recordingFeedback ||
        (action.payload.chat_message &&
          isEmpty(get(action.payload.process, "key", "")))
      ) {
        Object.keys(action.payload).map((field) => {
          if (field !== "messages" || action.payload.recordingFeedback) {
            if (!fields[field]) fields[field] = {};
            fields[field].value = action.payload[field];
            fields[field].is_dirty = false;
          }
          return true;
        });
      }

      return {
        ...state,
        process: {
          ...state.process,
          data: {
            ...state.process.data,
            fields: {
              ...fields,
              // reset spot info fields dirty
              spot_info_fields_dirty: {
                value: [],
                is_dirty: true,
              },
            },
            loaded_fields: {
              ...fields,
            },
          },
          loading: false,
          saving: false,
          error: null,
        },
        processes: omit(state.processes, ["filter_my_action_items"]),
      };
    case SAVE_FORM_VALUES_REQUEST: {
      if (
        action.payload.chat_message &&
        !action.payload.recordingFeedback &&
        !isEmpty(get(action.payload.process, "key", ""))
      ) {
        return state;
      }

      return {
        ...state,
        process: {
          ...state.process,
          loading: true,
          saving: true,
          error: null,
        },
      };
    }
    case SAVE_FORM_VALUES_ERROR: {
      return {
        ...state,
        process: {
          ...state.process,
          loading: false,
          saving: false,
          error: action.payload,
        },
      };
    }
    case SET_ACTIVE_TAB: {
      return {
        ...state,
        process: {
          ...state.process,
          activeTab: action.payload,
        },
      };
    }
    case SET_TAB_TO_EDIT: {
      return {
        ...state,
        process: {
          ...state.process,
          activeTab: action.payload,
          lastEditTab: uniq(
            concat(get(state, "process.lastEditTab", []), [action.payload])
          ),
        },
      };
    }
    case RESET_TAB: {
      return {
        ...state,
        process: {
          ...state.process,
          activeTab: null,
          lastEditTab: [],
        },
      };
    }
    case UPDATE_PROCESS_INCOMING:
      let processFields = get(state, "process.data.fields", {});
      let processLoadedFields = get(state, "process.data.loaded_fields", {});
      // console.log("PROCESS INCOMING DATA: ", get(action, "payload.data"));
      forOwn(get(action, "payload.data"), (fieldValue, key) => {
        // not update the dirty field
        if (key === "spot_info") {
          // TODO check update spot fields
          const spotInfoFieldsDirty = get(
            processFields,
            "spot_info_fields_dirty.value",
            []
          );
          const currentSpotInfo = get(processFields, "spot_info.value", []);
          let newSpotInfoValue = get(fieldValue, "value", []).map(
            (spotItem, i) => {
              const currentSpotItem = find(
                currentSpotInfo,
                (item) => item.uuid === spotItem.uuid
              );
              if (
                spotInfoFieldsDirty &&
                spotInfoFieldsDirty[i] &&
                currentSpotItem
              ) {
                const spotItemFieldsDirty = spotInfoFieldsDirty[i];
                let newSpotItemData = spotItem;
                forOwn(spotItem, (v, k) => {
                  if (spotItemFieldsDirty.includes(k)) {
                    newSpotItemData[k] = currentSpotItem[k];
                  }
                });
                return newSpotItemData;
              }
              return spotItem;
            }
          );
          processFields[key] = {
            ...processFields[key],
            value: newSpotInfoValue,
          };
          processLoadedFields[key] = {
            ...processFields[key],
            value: newSpotInfoValue,
          };
        } else if (!processFields[key] || !processFields[key].is_dirty) {
          processFields[key] = {
            ...processFields[key],
            ...fieldValue,
          };
          processLoadedFields[key] = {
            ...processFields[key],
            ...fieldValue,
          };
        }
      });
      return {
        ...state,
        process: {
          ...state.process,
          data: {
            ...state.process.data,
            fields: processFields,
            loaded_fields: processLoadedFields,
          },
        },
      };
    case EDIT_MESSAGE_SUCCESS:
      const editMessages = get(
        state,
        "process.data.fields.messages.value",
        []
      ).map((message) => {
        if (message._id === action.payload.id) {
          return {
            ...message,
            _source: {
              ...message._source,
              chat: action.payload.message,
            },
          };
        }
        return message;
      });
      return {
        ...state,
        process: {
          ...state.process,
          data: {
            ...state.process.data,
            fields: {
              ...state.process.data.fields,
              messages: {
                value: editMessages,
              },
            },
            loaded_fields: {
              ...state.process.data.loaded_fields,
              messages: {
                value: get(
                  state,
                  "process.data.loaded_fields.messages.value",
                  []
                ).map((message) => {
                  if (message._id === action.payload.id) {
                    return {
                      ...message,
                      _source: {
                        ...message._source,
                        chat: action.payload.message,
                      },
                    };
                  }
                  return message;
                }),
              },
            },
          },
        },
      };
    case DELETE_MESSAGE_SUCCESS:
      const deleteMessages = get(
        state,
        "process.data.fields.messages.value",
        []
      ).filter((message) => message._id !== action.payload.id);
      return {
        ...state,
        process: {
          ...state.process,
          data: {
            ...state.process.data,
            fields: {
              ...state.process.data.fields,
              messages: {
                value: deleteMessages,
              },
            },
            loaded_fields: {
              ...state.process.data.loaded_fields,
              messages: {
                value: get(
                  state,
                  "process.data.loaded_fields.messages.value",
                  []
                ).filter((message) => message._id !== action.payload.id),
              },
            },
          },
        },
      };
    case DELETE_MESSAGES_SUCCESS:
      return {
        ...state,
        process: {
          ...state.process,
          data: {
            ...state.process.data,
            fields: {
              ...state.process.data.fields,
              messages: {
                value: get(
                  state,
                  "process.data.fields.messages.value",
                  []
                ).filter(
                  (message) => !includes(action.payload.ids, message._id)
                ),
              },
            },
            loaded_fields: {
              ...state.process.data.loaded_fields,
              messages: {
                value: get(
                  state,
                  "process.data.loaded_fields.messages.value",
                  []
                ).filter(
                  (message) => !includes(action.payload.ids, message._id)
                ),
              },
            },
          },
        },
      };
    case FETCH_PROCESS_SUCCESS:
      return {
        ...state,
        process: {
          ...state.process,
          key: action.payload.data.key ? action.payload.data.key.value : false,
          //activeTab: action.payload.data.process_step ? action.payload.data.process_step.value : false,
          data: {
            ...state.process.data,
            fields: {
              // ...state.process.data.fields,
              ...action.payload.data,
            },
            loaded_fields: {
              ...action.payload.data,
            },
          },
          loading: false,
          error: null,
          spotSwitching: false,
        },
        processes: action.payload.forgetProcesses
          ? omit(state.processes, ["filter_my_action_items"])
          : state.processes,
      };
    case FETCH_PROCESS_REQUEST: {
      let newState = {};
      if (action.payload.spotSwitching) {
        newState = {
          ...state,
          process: {
            ...state.process,
            data: {
              fields: {
                ...get(state, "process.data.fields", {}),
                key: {
                  value: action.payload.process_id,
                },
              },
              loaded_fields: {
                ...get(state, "process.data.loaded_fields", {}),
                key: {
                  value: action.payload.process_id,
                },
              },
            },
            loading: true,
            error: null,
            spotSwitching: false,
            is_dirty: false,
          },
        };
      } else {
        newState = {
          ...state,
          process: {
            ...state.process,
            data: {
              fields: {},
              loaded_fields: {},
            },
            loading: true,
            error: null,
            spotSwitching: false,
            is_dirty: false,
          },
        };
      }
      return newState;
    }
    case FETCH_PROCESS_ERROR:
      return {
        ...state,
        process: {
          ...state.process,
          loading: false,
          error: action.payload,
          spotSwitching: false,
        },
      };
    // review process
    case FETCH_REVIEW_PROCESS_SUCCESS:
      return {
        ...state,
        process: {
          ...state.process,
          key: action.payload.data.key ? action.payload.data.key.value : false,
          data: {
            ...state.process.data,
            fields: {
              ...action.payload.data,
            },
            loaded_fields: {
              ...action.payload.data,
            },
          },
          loading: false,
          error: null,
        },
      };
    case FETCH_REVIEW_PROCESS_REQUEST: {
      let newState = {};
      if (action.payload.spotSwitching) {
        newState = {
          ...state,
          process: {
            ...state.process,
            data: {
              fields: {
                ...get(state, "process.data.fields", {}),
                key: {
                  value: action.payload.process_id,
                },
              },
              loaded_fields: {
                ...get(state, "process.data.loaded_fields", {}),
                key: {
                  value: action.payload.process_id,
                },
              },
            },
            loading: true,
            error: null,
            is_dirty: false,
          },
        };
      } else {
        newState = {
          ...state,
          process: {
            ...state.process,
            data: {
              fields: {},
              loaded_fields: {},
            },
            loading: true,
            error: null,
          },
        };
      }
      return newState;
    }
    case FETCH_REVIEW_PROCESS_ERROR:
      return {
        ...state,
        process: {
          ...state.process,
          loading: false,
          error: action.payload,
        },
      };
    // DELETE Process Actions
    case DELETE_PROCESS_REQUEST: {
      return {
        ...state,
        process: {
          ...state.process,
          loading: true,
          error: null,
        },
      };
    }
    case DELETE_PROCESS_SUCCESS: {
      let ind = state.processes.data.findIndex(
        (s) => s._id === get(action, "payload.id")
      );
      const nextProcessId = get(action, "payload.nextProcessId", "");
      const processKey = get(state, "process.data.fields.key.value", "");
      return {
        ...state,
        processes: {
          data: [
            ...state.processes.data.slice(0, ind),
            ...state.processes.data.slice(ind + 1),
          ],
        },
        process: get(action, "payload.spot_only", false)
          ? {
              ...state.process,
              data:
                /**
                 * If next process is the same to current process. Remove deleted spot from spot_info and spots attribute
                 */
                nextProcessId && processKey === nextProcessId
                  ? {
                      fields: { ...state.process.data.fields },
                    }
                  : { fields: {} },
              loading: false,
              error: null,
            }
          : {
              data: { fields: {} },
              loading: false,
              error: null,
            },
      };
    }
    case DELETE_PROCESS_ERROR: {
      return {
        ...state,
        process: {
          ...state.process,
          loading: false,
          error: action.payload,
        },
      };
    }
    // ARCHIVE Process Actions
    case ARCHIVE_PROCESS_REQUEST: {
      return {
        ...state,
        archiveOrder: {
          loading: true,
          error: null,
        },
      };
    }
    case ARCHIVED_PROCESS_FROM_PUSHER:
    case ARCHIVE_PROCESS_SUCCESS: {
      let newProcesses = Object.assign(
        {
          refreshed_at: Date.now(),
        },
        {...state.processes}
      );
      forOwn(state.processes, (filterData, key) => {
        if (key.indexOf("filter_") === 0) {
          newProcesses[key] = {
            ...filterData,
            data:
              key === "filter_produced_spots"
                ? filter(filterData.data, (item) => item._id !== action.payload)
                : map(filterData.data, (item) => {
                    if (item._id === action.payload) {
                      return {
                        ...item,
                        _source: {
                          ...item._source,
                          is_archived: true,
                        },
                      };
                    }
                    return item;
                  }),
            is_dirty: [
              "filter_archived_spots",
              "filter_produced_spots",
              "filter_expiring_spots",
              "filter_production_queue",
              "filter_my_upcoming_items",
              "filter_my_action_items",
            ].includes(key),
          };
        }
      });
      if (get(state, "process.key", "") === action.payload) {
        return {
          ...state,
          process: {
            ...state.process,
            data: {
              ...get(state, "process.data", {}),
              fields: {
                ...get(state, "process.data.fields", {}),
                is_archived: {
                  value: true,
                },
              },
            },
          },
          archiveOrder: {
            loading: false,
            error: null,
          },
          processes: newProcesses,
        };
      }
      return {
        ...state,
        archiveOrder: {
          loading: false,
          error: null,
        },
        processes: newProcesses,
      };
    }
    case ARCHIVE_PROCESS_ERROR: {
      return {
        ...state,
        archiveOrder: {
          loading: false,
          error: action.payload,
        },
      };
    }
    // assignment Process Actions
    case ASSIGNMENT_PROCESS_REQUEST: {
      return {
        ...state,
        assignmentProcess: {
          loading: true,
          error: null,
        },
      };
    }
    case ASSIGNMENT_PROCESS_SUCCESS: {
      return {
        ...state,
        processes: {
          ...state.processes,
          filter_continuity_assignment: {
            ...get(state, "processes.filter_continuity_assignment", {}),
            data: map(
              get(state, "processes.filter_continuity_assignment.data", []),
              (item) => {
                if (item._id === action.payload.key) {
                  return {
                    ...item,
                    _source: action.payload,
                  };
                }
                return item;
              }
            ),
          },
        },
        assignmentProcess: {
          loading: false,
          error: null,
        },
      };
    }
    case ASSIGNMENT_PROCESS_ERROR: {
      return {
        ...state,
        assignmentProcess: {
          loading: false,
          error: action.payload,
        },
      };
    }
    // dubbing automation
    case AUTOMATE_DUBBING_REQUEST: {
      return {
        ...state,
        dubbingProcesses: {
          data: {
            ...get(state, "dubbingProcesses.data", {}),
            ...action.payload,
          },
        },
      };
    }
    case AUTOMATE_DUBBING_PROGRESS: {
      return {
        ...state,
        dubbingProcesses: {
          data: {
            ...get(state, "dubbingProcesses.data", {}),
            ...action.payload,
          },
        },
      };
    }

    case AUTOMATE_DUBBING_SUCCESS: {
      let fields = {};
      Object.keys(omit(action.payload.process, "order_viewed")).forEach(
        (key) => {
          fields[key] = { value: action.payload.process[key] };
        }
      );
      return {
        ...state,
        dubbingProcesses: {
          data: {
            ...get(state, "dubbingProcesses.data", {}),
            ...action.payload.dubbingProgress,
          },
        },
        process: {
          ...state.process,
          data: {
            ...state.process.data,
            fields: {
              ...state.process.data.fields,
              ...fields,
            },
          },
        },
        processes: {
          ...state.processes,
          filter_dubbing_queue: {
            ...get(state, "processes.filter_dubbing_queue", {}),
            data: map(
              get(state, "processes.filter_dubbing_queue.data", []),
              (item) => {
                if (item._id === action.payload.process.key) {
                  return {
                    ...item,
                    _source: action.payload.process,
                  };
                }
                return item;
              }
            ),
          },
        },
      };
    }

    case AUTOMATE_DUBBING_ERROR: {
      return {
        ...state,
        dubbingProcesses: {
          data: {
            ...get(state, "dubbingProcesses.data", {}),
            ...action.payload,
          },
        },
      };
    }

    case FORGET_PROCESS: {
      return {
        ...state,
        process: {
          ...state.process,
          is_dirty: true,
        },
      };
    }
    case SET_EXTRA_DATA_REVIEW_PROCESS: {
      return {
        ...state,
        extraReviewProcess: {
          data: action.payload,
        },
      };
    }
    case SET_MESSAGES: {
      // update review messages to current process. We can validate change request should be added the messages
      const messages = get(state, "process.data.fields.messages.value", []);
      const mTypes = ["voice_feedback"];
      const newReviewMessages = filter(action.payload.messages, (item) => {
        return (
          item.message.process_key === state.process.key &&
          includes(mTypes, item.message.m_type) &&
          !find(messages, (i) => i._id === item.m_id)
        );
      }).map((item) => ({
        _id: item.m_id,
        _source: item.message,
      }));
      if (newReviewMessages.length > 0) {
        return {
          ...state,
          process: {
            ...state.process,
            data: {
              ...state.process.data,
              fields: {
                ...state.process.data.fields,
                messages: {
                  value: [...messages, ...newReviewMessages],
                },
              },
            },
          },
        };
      }
      return state;
    }
    case SEND_BACK_PROCESS_REQUEST: {
      return {
        ...state,
        sendBackProgress: {
          error: null,
          loading: true,
        },
      };
    }
    case SEND_BACK_PROCESS_SUCCESS: {
      return {
        ...state,
        sendBackProgress: {
          error: null,
          loading: false,
        },
      };
    }
    case SEND_BACK_PROCESS_ERROR: {
      return {
        ...state,
        sendBackProgress: {
          error: action.payload,
          loading: false,
        },
      };
    }
    case RESET_PROCESS: {
      return {
        ...state,
        process: {
          data: {
            fields: {
              template_key: {
                value: "",
                is_dirty: true,
              },
            },
            loaded_fields: {},
          },
          error: null,
          loading: false,
        },
      };
    }
    case ASSIGN_TEAM_PROCESS_REQUEST: {
      return {
        ...state,
        assignTeamProcess: {
          loading: true,
          error: null,
        },
      };
    }
    case ASSIGN_TEAM_PROCESS_SUCCESS: {
      return {
        ...state,
        assignTeamProcess: {
          loading: false,
          error: null,
        },
      };
    }
    case ASSIGN_TEAM_PROCESS_ERROR: {
      return {
        ...state,
        assignTeamProcess: {
          loading: false,
          error: action.payload,
        },
      };
    }
    case ADD_ROTATION_REQUEST: {
      return {
        ...state,
        addRotation: {
          loading: true,
          error: null,
        },
      };
    }
    case ADD_ROTATION_SUCCESS: {
      return {
        ...state,
        addRotation: {
          loading: false,
          error: null,
        },
      };
    }
    case ADD_ROTATION_ERROR: {
      return {
        ...state,
        addRotation: {
          loading: false,
          error: action.payload,
        },
      };
    }
    // copy order
    case COPY_ORDER_REQUEST: {
      return {
        ...state,
        copyOrder: {
          loading: true,
          error: null,
        },
      };
    }
    case COPY_ORDER_SUCCESS: {
      return {
        ...state,
        copyOrder: {
          loading: false,
          error: null,
        },
      };
    }
    case COPY_ORDER_ERROR: {
      return {
        ...state,
        copyOrder: {
          loading: false,
          error: action.payload,
        },
      };
    }
    case FETCH_PROCESSES_BY_IDS_SUCCESS: {
      let new_processes = state.processes;
      forOwn(state.processes, (filterData, key) => {
        if (key.indexOf("filter_") === 0) {
          new_processes[key] = {
            ...filterData,
            data: map(filterData.data, (item) => {
              const existsProcess = find(
                action.payload || [],
                (i) => i.key === item._id
              );
              if (existsProcess)
                return {
                  ...item,
                  _source: existsProcess,
                };
              return item;
            }),
          };
        }
      });
      return {
        ...state,
        processes: {
          ...new_processes,
          refreshed_at: Date.now(), // add new random key to effect the updates in redux storage
        },
      };
    }
    case REMOVE_PROCESS_FROM_LIST:
      let new_processes = state.processes;
      forOwn(state.processes, (filterData, key) => {
        if (key.indexOf("filter_") === 0) {
          new_processes[key] = {
            ...filterData,
            data: filter(filterData.data, (item) => {
              return item._id !== action.payload.process_key;
            }),
          };
        }
      });
      return {
        ...state,
        processes: new_processes,
      };
    case SET_DIRTY_PROCESS_INCOMING_SUCCESS:
      let newProcesses = {...state.processes};
      forOwn(state.processes, (_, key) => {
        if (key.indexOf("filter_") === 0) {
          newProcesses[key].current_dirty = get(action, "payload.process_key");
        }
      });
      return {
        ...state,
        processes: newProcesses,
      };
    case ASSIGN_VO_TEAM_PROCESS_REQUEST: {
      return {
        ...state,
        assignTeamProcess: {
          loading: true,
          error: null,
        },
      };
    }
    case ASSIGN_VO_TEAM_PROCESS_SUCCESS: {
      return {
        ...state,
        assignTeamProcess: {
          loading: false,
          error: null,
        },
      };
    }
    case ASSIGN_VO_TEAM_PROCESS_ERROR: {
      return {
        ...state,
        assignTeamProcess: {
          loading: false,
          error: action.payload,
        },
      };
    }
    // final script
    case UPDATE_FINAL_SCRIPT_REQUEST: {
      return {
        ...state,
        updateFinalScript: {
          loading: true,
          error: null,
        },
      };
    }
    case UPDATE_FINAL_SCRIPT_SUCCESS: {
      return {
        ...state,
        updateFinalScript: {
          loading: false,
          error: null,
        },
      };
    }
    case UPDATE_FINAL_SCRIPT_ERROR: {
      return {
        ...state,
        updateFinalScript: {
          loading: false,
          error: action.payload,
        },
      };
    }
    // unarchive
    case UNARCHIVE_PROCESS_REQUEST: {
      return {
        ...state,
        unarchiveOrder: {
          loading: true,
          error: null,
        },
      };
    }
    case UNARCHIVE_PROCESS_FROM_PUSHER:
    case UNARCHIVE_PROCESS_SUCCESS: {
      let newProcesses = Object.assign(
        {
          refreshed_at: Date.now(),
        },
        {...state.processes}
      );
      forOwn(state.processes, (filterData, key) => {
        if (key.indexOf("filter_") === 0) {
          newProcesses[key] = {
            ...filterData,
            data:
              key === "filter_archived_spots"
                ? filter(filterData.data, (item) => item._id !== action.payload)
                : map(filterData.data, (item) => {
                    if (item._id === action.payload) {
                      return {
                        ...item,
                        _source: {
                          ...item._source,
                          is_archived: false,
                        },
                      };
                    }
                    return item;
                  }),
            is_dirty: [
              "filter_archived_spots",
              "filter_produced_spots",
              "filter_expiring_spots",
              "filter_production_queue",
              "filter_my_upcoming_items",
              "filter_my_action_items",
            ].includes(key),
          };
        }
      });
      if (get(state, "process.key", "") === action.payload) {
        return {
          ...state,
          process: {
            ...state.process,
            data: {
              ...get(state, "process.data", {}),
              fields: {
                ...get(state, "process.data.fields", {}),
                is_archived: {
                  value: false,
                },
              },
            },
          },
          unarchiveOrder: {
            loading: false,
            error: null,
          },
          processes: newProcesses,
        };
      }
      return {
        ...state,
        unarchiveOrder: {
          loading: false,
          error: null,
        },
        processes: newProcesses,
      };
    }
    case UNARCHIVE_PROCESS_ERROR: {
      return {
        ...state,
        unarchiveOrder: {
          loading: false,
          error: action.payload,
        },
      };
    }
    // convert spec to sold
    case CONVERT_SPEC_TO_SOLD_REQUEST: {
      return {
        ...state,
        convertSpecToSold: {
          loading: true,
          error: null,
        },
      };
    }
    case CONVERT_SPEC_TO_SOLD_SUCCESS: {
      return {
        ...state,
        convertSpecToSold: {
          loading: false,
          error: null,
        },
      };
    }
    case CONVERT_SPEC_TO_SOLD_ERROR: {
      return {
        ...state,
        convertSpecToSold: {
          loading: false,
          error: action.payload,
        },
      };
    }
    // auto dubbing
    case CHECK_AUTO_DUBBING_REQUEST: {
      return {
        ...state,
        autoDubbingEnabled: {
          data: false,
          loading: true,
          error: null,
        },
      };
    }
    case CHECK_AUTO_DUBBING_SUCCESS: {
      return {
        ...state,
        autoDubbingEnabled: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    }
    case CHECK_AUTO_DUBBING_ERROR: {
      return {
        ...state,
        autoDubbingEnabled: {
          data: false,
          loading: false,
          error: action.payload,
        },
      };
    }
    case LOGOUT_USER:
      return initialState;
    case AUTH_USER:
      return initialState;
    default:
      return state;
  }
}
