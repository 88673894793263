import * as Types from "store/types/servers";
import { LOGOUT_USER } from "store/types/auth";
import { get, pick, uniqBy } from "lodash";
import { getDateFormatByCountry } from 'utils/dates';

const initialState = {
  allServers: {
    data: [],
    error: null,
    loading: false
  },
  servers: {
    // This object contains all servers list
    // this is mainly required in servers listing page
    // where we show all servers list and loading icon if srevers are loading
    data: [],
    error: null,
    loading: false,
    is_dirty: false
  },
  server: {
    // This object contains particular server object with id mapping
    // this is mainly required in server details page
    // where we show particular server detail and loading icon if srever details is loading
    // below is example object
    // "<id>": {
    //   data: null,
    //   error: null,
    //   loading: null
    // }
  },
  addServer: {
    // This object will be useful to show loading icon and status when user want to create new server
    is_added: false,
    data: null,
    error: null,
    loading: false
  },
  updateServer: {
    // This object will be useful to show loading icon and status when user want to update server
    data: null,
    error: null,
    loading: false
  },
  deleteServer: {
    // This object will be useful to show loading icon and status when user want to delete server
    is_deleted: false,
    data: null,
    error: null,
    loading: false
  },
  productsSettings: {
    // This object contains all products settings
  },
  currentServer: {
    data: null,
    error: null,
    loading: false
  },
  materialGroups: {

  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    // Current server actions
    case Types.FETCH_CURRENT_SERVER_REQUEST: {
      return {
        ...state,
        currentServer: { ...state.currentServer, error: null, loading: true }
      };
    }
    case Types.FETCH_CURRENT_SERVER_SUCCESS: {
      return {
        ...state,
        currentServer: {
          data: {
            ...action.payload,
            date_format: getDateFormatByCountry(action.payload.country)
          },
          error: null,
          loading: false
        }
      };
    }
    case Types.FETCH_CURRENT_SERVER_ERROR: {
      return {
        ...state,
        currentServer: {
          data: null,
          error: action.payload,
          loading: false
        }
      };
    }

    // All Servers Actions

    case Types.FETCH_ALL_SERVERS_REQUEST: {
      return {
        ...state,
        allServers: { data: [], error: null, loading: true }
      };
    }
    case Types.FETCH_ALL_SERVERS_SUCCESS: {
      return {
        ...state,
        allServers: {
          data: action.payload,
          error: null,
          loading: false
        }
      };
    }
    case Types.FETCH_ALL_SERVERS_ERROR: {
      return {
        ...state,
        allServers: {
          data: null,
          error: action.payload,
          loading: false
        }
      };
    }

    // Servers Actions

    case Types.FETCH_SERVERS_REQUEST: {
      return {
        ...state,
        servers: {
          data: get(state, "servers.data"),
          error: null,
          loading: true,
          is_dirty: false
        }
      };
    }
    case Types.FETCH_SERVERS_SUCCESS: {
      return {
        ...state,
        servers: {
          data: action.payload,
          error: null,
          loading: false,
          is_dirty: false
        }
      };
    }
    case Types.FETCH_SERVERS_ERROR: {
      return {
        ...state,
        servers: {
          data: null,
          error: action.payload,
          loading: false,
          is_dirty: false
        }
      };
    }

    case Types.FETCH_MORE_SERVERS_REQUEST: {
      return {
        ...state,
        servers: {
           ...state.servers,
           lazyLoad: true
        },
      };
    }
    case Types.FETCH_MORE_SERVERS_SUCCESS: {

      const { data: newData, ...rest } = action.payload;

      return {
        ...state,
        
        servers: {
          ...state.servers,
          data: {
            data: uniqBy(
              [...get(state, 'servers.data.data', []), ...newData],
              'id'
            ),
            ...rest,
          },
          lazyLoad: false,
        },
      };
    }
    case Types.FETCH_MORE_SERVERS_ERROR: {
      return {
        ...state,
        servers: {
           ...state.servers,
           lazyLoad: false,
        },
      };
    }

    // Particular Server Actions
    case Types.FETCH_SERVER_REQUEST: {
      const existingServers = get(state, "server");
      const { id } = action.payload;
      return {
        ...state,
        server: {
          ...existingServers,
          [id]: {
            ...(existingServers[id] || {}),
            loading: true,
            error: null
          }
        }
      };
    }
    case Types.FETCH_SERVER_SUCCESS: {
      const existingServers = get(state, "server");
      const { id, data } = action.payload;
      return {
        ...state,
        server: {
          ...existingServers,
          [id]: {
            loading: false,
            error: null,
            data
          }
        }
      };
    }
    case Types.FETCH_SERVER_ERROR: {
      const existingServers = get(state, "server");
      const { id, error } = action.payload;
      return {
        ...state,
        server: {
          ...existingServers,
          [id]: {
            loading: false,
            error
          }
        }
      };
    }
//security group for servers
    case Types.FETCH_SECURITY_POLICY_SERVER_REQUEST: {
        const existingServers = get(state, "server");
        const { id } = action.payload;
        return {
            ...state,
            server: {
                ...existingServers,
                [id]: {
                    ...(existingServers[id] || {}),
                    loading: true,
                    error: null
                }
            }
        };
    }

    case Types.FETCH_SECURITY_POLICY_SERVER_SUCCESS: {
        const existingServers = get(state, "server");
        const { id, data } = action.payload;
        return {
            ...state,
            server: {
                ...existingServers,
                [id]: {
                    loading: false,
                    error: null,
                    data: {
                        ...get(existingServers, `${id}.data`, {}),
                        ...data
                    }
                }
            }
        };
    }

    case Types.FETCH_SECURITY_POLICY_SERVER_ERROR: {
        const existingServers = get(state, "server");
        const { id, error } = action.payload;
        return {
            ...state,
            server: {
                ...existingServers,
                [id]: {
                    ...(existingServers[id] || {}),
                    loading: false,
                    error
                }
            }
        };
    }



    //UPDATING SECURITY POLICY
    case Types.UPDATE_SECURITY_POLICY_SERVER_REQUEST: {
        return {
            ...state,
            updateServer: {
                loading: true,
                error: null
            }
        };
    }


    case Types.UPDATE_SECURITY_POLICY_SERVER_SUCCESS: {
        const existingServers = get(state, "server");
        const { id, data } = action.payload;
        return {
            ...state,
            server: {
                ...existingServers,
                [id]: {
                    loading: false,
                    error: null,
                    data: {
                        ...get(existingServers, `${id}.data`, {}),
                        ...pick(data, [
                            "domain_filter",
                            "password_complexity_pattern",
                            "replace_password_days",
                            "reuse_password_days",
                            "system_idle_timeout"//
                        ])
                    }
                }
            },
            updateServer: {
                loading: false,
                error: null
            }
        };
    }

    case Types.UPDATE_SECURITY_POLICY_SERVER_ERROR: {
        const { error } = action.payload;
        return {
            ...state,
            updateServer: {
                loading: false,
                error
            }
        };
    }

    // Add Server Actions
    case Types.ADD_SERVER_REQUEST: {
      return {
        ...state,
        addServer: {
          is_added: false,
          loading: true,
          error: null
        }
      };
    }
    case Types.ADD_SERVER_SUCCESS: {
      return {
        ...state,
        servers: {
          ...get(state, "servers"),
          is_dirty: true
        },
        addServer: {
          is_added: true,
          data: action.payload,
          loading: false,
          error: null
        }
      };
    }
    case Types.ADD_SERVER_ERROR: {
      return {
        ...state,
        addServer: {
          is_added: false,
          loading: false,
          error: action.payload
        }
      };
    }

    // Update Server Actions
    case Types.UPDATE_SERVER_REQUEST: {
      return {
        ...state,
        updateServer: {
          loading: true,
          error: null
        }
      };
    }
    case Types.UPDATE_SERVER_SUCCESS: {
      return {
        ...state,
        // servers: {
        //   ...get(state, "servers"),
        //   is_dirty: true
        // },
        updateServer: {
          data: action.payload,
          loading: false,
          error: null
        }
      };
    }
    case Types.UPDATE_SERVER_ERROR: {
      return {
        ...state,
        updateServer: {
          loading: false,
          error: action.payload
        }
      };
    }

    // DELETE Server Actions
    case Types.DELETE_SERVER_REQUEST: {
      return {
        ...state,
        deleteServer: {
          is_deleted: false,
          loading: true,
          error: null
        }
      };
    }
    case Types.DELETE_SERVER_SUCCESS: {
      return {
        ...state,
        servers: {
          ...get(state, "servers"),
          is_dirty: true
        },
        deleteServer: {
          is_deleted: true,
          loading: false,
          error: null
        }
      };
    }
    case Types.DELETE_SERVER_ERROR: {
      return {
        ...state,
        deleteServer: {
          is_deleted: false,
          loading: false,
          error: action.payload
        }
      };
    }
      
    // Fetch products settings
    case Types.FETCH_PRODUCTS_SETTINGS_REQUEST: {
      const existingSettings = get(state, "productsSettings");
      const { id } = action.payload;
      return {
        ...state,
        productsSettings: {
          ...existingSettings,
          [id]: {
            ...(existingSettings[id] || {}),
            loading: true,
            error: null,
            is_dirty: false,
          }
        }
      };
    }
    case Types.FETCH_PRODUCTS_SETTINGS_SUCCESS: {
      const existingSettings = get(state, "productsSettings");
      const { id, data } = action.payload;
      return {
        ...state,
        productsSettings: {
          ...existingSettings,
          [id]: {
            loading: false,
            error: null,
            is_dirty: false,
            data
          }
        }
      };
    }
    case Types.FETCH_PRODUCTS_SETTINGS_ERROR: {
      const existingSettings = get(state, "productsSettings");
      const { id, error } = action.payload;
      return {
        ...state,
        productsSettings: {
          ...existingSettings,
          [id]: {
            loading: false,
            is_dirty: false,
            error
          }
        }
      };
    }
      // Update product settings
    case Types.UPDATE_PRODUCTS_SETTINGS_REQUEST: {

      const serverId = action.payload;
      const existingSettings = get(state, "productsSettings");

      return {
        ...state,
        productsSettings: {
          ...existingSettings,
          [serverId]: {
            loading: true,
            error: null,
            is_dirty: false,
            data: get(existingSettings, `${[serverId]}.data`, {}),
          }
        }
      };
    }
    case Types.UPDATE_PRODUCTS_SETTINGS_SUCCESS: {
      const { id: serverId} = action.payload;
      const existingSettings = get(state, "productsSettings");

      return {
        ...state,
        productsSettings: {
          ...existingSettings,
          [serverId]: {
            loading: false,
            error: null,
            is_dirty: true,
            data: get(existingSettings, `${[serverId]}.data`, {}),
          }
        }
      };
    }
    case Types.UPDATE_PRODUCTS_SETTINGS_ERROR: {

      const { id: serverId} = action.payload;
      const existingSettings = get(state, "productsSettings");

      return {
        ...state,
        productsSettings: {
          ...existingSettings,
          [serverId]: {
            loading: false,
            error: true,
            is_dirty: true,
            data: get(existingSettings, `${[serverId]}.data`, {}),
          }
        }
      };
    }
    // Fetch material groups
    case Types.FETCH_MATERIAL_GROUPS_REQUEST: {
      const existingMaterialGroups = get(state, "materialGroups");
      const { id } = action.payload;
      return {
        ...state,
        materialGroups: {
          ...existingMaterialGroups,
          [id]: {
            ...(existingMaterialGroups[id] || {}),
            loading: true,
            error: null,
            is_dirty: false,
          }
        }
      };
    }
    case Types.FETCH_MATERIAL_GROUPS_SUCCESS: {
      const existingMaterialGroups = get(state, "materialGroups");
      const { id, data } = action.payload;
      return {
        ...state,
        materialGroups: {
          ...existingMaterialGroups,
          [id]: {
            loading: false,
            error: null,
            is_dirty: false,
            data
          }
        }
      };
    }
    case Types.FETCH_MATERIAL_GROUPS_ERROR: {
      const existingMaterialGroups = get(state, "materialGroups");
      const { id, error } = action.payload;
      return {
        ...state,
        materialGroups: {
          ...existingMaterialGroups,
          [id]: {
            loading: false,
            is_dirty: false,
            error
          }
        }
      };
    }
      // Update material groups
    case Types.UPDATE_MATERIAL_GROUPS_REQUEST: {

      const serverId = action.payload;
      const existingMaterialGroups = get(state, "materialGroups");

      return {
        ...state,
        materialGroups: {
          ...existingMaterialGroups,
          [serverId]: {
            loading: true,
            error: null,
            is_dirty: false,
            data: get(existingMaterialGroups, `${[serverId]}.data`, {}),
          }
        }
      };
    }
    case Types.UPDATE_MATERIAL_GROUPS_SUCCESS: {
      const { id: serverId} = action.payload;
      const existingMaterialGroups = get(state, "materialGroups");

      return {
        ...state,
        materialGroups: {
          ...existingMaterialGroups,
          [serverId]: {
            loading: false,
            error: null,
            is_dirty: true,
            data: get(existingMaterialGroups, `${[serverId]}.data`, {}),
          }
        }
      };
    }
    case Types.UPDATE_MATERIAL_GROUPS_ERROR: {

      const { id: serverId} = action.payload;
      const existingMaterialGroups = get(state, "materialGroups");

      return {
        ...state,
        materialGroups: {
          ...existingMaterialGroups,
          [serverId]: {
            loading: false,
            error: true,
            is_dirty: true,
            data: get(existingMaterialGroups, `${[serverId]}.data`, {}),
          }
        }
      };
    }
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
}
