import steps from "./steps";
const ProductionProvided = {
  _index: "templates",
  _type: "template",
  _id: "production_provided",
  _score: 1,
  _source: {
    key: "production_provided",
    templates_name: "Production Provided",
    templates_description: "Track the process of a basic commercial production",
    priviliges: {
      initiate: ["initiate_commercial_production"],
      delete: ["delete_commercial_production"],
      view: ["view_commercial_production", "station_manager"],
      edit: ["edit_commercial_production"],
    },
    workflow: {
      title: "Production provided workflow",
    },
    steps: steps,
    active: true,
    is_allow_create_custom_workflow: false,
  },
};
export default ProductionProvided;
