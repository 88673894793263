import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter, Button, Row, Col } from "reactstrap";
import bn from "utils/bemnames";
import { FormattedMessage } from "react-intl";
import CustomMultiSelectDropdown from "components/CustomMultiSelectDropdown";
const bem = bn.create("copy-select-stations-modal");
function CopySelectStationsModal(props) {
  const [values, setValues] = useState([]);
  const { title, stations, onToggle } = props;
  return (
    <Modal isOpen={props.isOpen} className={bem.b()} toggle={onToggle}>
      <ModalBody className={bem.e("body")}>
        <Row>
          <Col xs={12}>
            <div className={bem.e("title")}>{title}</div>
            <CustomMultiSelectDropdown
              name="stations"
              placeholder={props.intl.formatMessage({
                id: "process > placeholder select station",
              })}
              value={values}
              onChange={(selectedValues) => {
                setValues(selectedValues);
              }}
              isMulti={true}
              isStation
              options={stations.map((station) => {
                return {
                  label: station.call_letters,
                  value: station.key,
                  data: {
                    firstLabel: station.call_letters,
                    lastLabel: station.name,
                  },
                };
              })}
              multiText={
                <FormattedMessage id="process > promotion > liner > scheduling > multiple stations" />
              }
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={values.length === 0}
          onClick={() => {
            if (values.length === 0) return;
            props.onSubmit(values);
          }}
          className={"btn-blue btn-radius"}
        >
          <FormattedMessage id="process > button copy" />
        </Button>
        <Button
          onClick={props.onCancel}
          color="blue"
          className={"btn-radius"}
          outline
        >
          <FormattedMessage id="process > button cancel" />
        </Button>
      </ModalFooter>
    </Modal>
  );
}

CopySelectStationsModal.defaultProps = {
  isOpen: false,
  onSubmit: () => {},
  title: "",
};

export default CopySelectStationsModal;
