import React from "react";
import EnterpriseSelectTeam from "./enterpriseSelectTeam";
import BasicSelectTeam from "./basicSelectTeam";
import { get } from "lodash";
const SelectTeam = (props) => {
  const { process } = props;
  const teamProductionManager = get(
    process,
    "data.fields.team_production_manager.value",
    ""
  );
  if (teamProductionManager) {
    return <EnterpriseSelectTeam {...props} />;
  }
  return <BasicSelectTeam {...props} />;
};

export default SelectTeam;
