import { FormattedMessage } from "react-intl";
import React from "react";
import { get, delay } from "lodash";
import history from "components/History";
import { checkUserRedirectToCurrentTasks } from "utils/helpers";

const airCheck = {
  process_step_index: 9,
  key: "air_check",
  title: <FormattedMessage id="process > title aircheck" />,
  caption_color: "#CC1EE6",
  description: (
    <FormattedMessage id="process > description upload aircheck recording" />
  ),
  isCollapsible: true,
  step_roles: [1, 2, 3],
  step_viewers_roles: [1, 2, 3, 4, 7],
  step_editors_roles: [1, 2, 3, 4],
  step_viewers_privileges: {
    or: ["submit_production_air_check", "edit_all_production_orders"],
  },
  step_editors_privileges: {
    or: ["submit_production_air_check", "edit_all_production_orders"],
  },
  is_check_hidden_tab: true,
  status: "initial",
  className: "air-check",
  is_hidden: function(user, process, step) {
    const spotInfo = get(process, "data.fields.spot_info.value", []).find(
      (spot) => spot.key === process.key
    );
    return (
      !get(spotInfo, "aircheck_requires_approval", false) ||
      (process.data.fields.order_sold && !process.data.fields.order_sold.value)
    );
  },
  workflow: {
    title: "air check",
    icon: "ApprovalIcon",
  },
  is_approval_step: true,
  fields: [
    {
      field: "aircheck_path",
      component: "file_upload",
      mandatory: true,
      props: {
        extensions: ["mp3", "wav", "aiff", "flac", "aac", "alac", "m4a", "mp4"],
      },
      validation: function(step, item, process, template, user, value) {
        return value !== "" ? true : "Please upload the aircheck recording";
      },
    },
    {
      text: <FormattedMessage id="process > button submit" />,
      key: "save_button",
      component: "button",
      props: { color: "blue" },
      container: "bottom_buttons",
      class: null,
      on_click: {
        validate: true,
        save: true,
        go_to: function(_this, step, item, process, template, user, extraData) {
          const return_to_current_tasks = checkUserRedirectToCurrentTasks({
            ...user,
            roles_string: extraData.roles_string,
          });
          if (!return_to_current_tasks) return;
          // set delay when after success message
          delay(() => {
            if (_this.props.setSidebarActive) {
              _this.props.setSidebarActive(`/processes/my_action_items`);
            }
            history.replace(`/processes/my_action_items`);
          }, 4000);
        },
      },
      fields: [
        {
          key: "air_check_status",
          // "value": "submitted"
        },
        {
          key: "process_step",
          value: "done",
        },
      ],
      confirmation_dialog_props: {
        title: (
          <FormattedMessage id="process > description submit the aircheck" />
        ),
        confirmTitle: <FormattedMessage id="process > button yes" />,
        cancelTitle: <FormattedMessage id="process > button no" />,
      },
      success_dialog_props: {
        title: <FormattedMessage id="process > title aircheck submitted" />,
        state: "success",
      },
    },
  ],
};
export default airCheck;
