import React from "react";
import bn from "utils/bemnames";
import {
  Navbar,
  Nav,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "recompose";
import {
  MdPersonPin,
  MdSettingsApplications,
  MdExitToApp,
} from "react-icons/lib/md";
import { FaUserSecret } from "react-icons/lib/fa";
import history from "components/History";
import URL from "utils/urls";
import { dismissNotification } from "utils/helpers";
import Avatar from "components/Avatar";
import { UserCard } from "components/Card";
import Notifications from "components/Notifications";
import {
  logoutUser,
  stopImpersonate,
  impersonateUser,
  setIsSidebarOpen,
} from "store/actions/auth";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import {
  HamburgerIcon,
  PromoSuiteLogo,
  LogoutIcon,
  NotificationsIcon,
} from "components/CustomIcons";
import { get, isObject } from "lodash";
import { adminTabs } from "utils/config";

const bem = bn.create("header");

class Header extends React.Component {
  state = {
    isOpenNotificationPopover: false,
    isOpenUserCardPopover: false,
  };

  toggleNotificationPopover = (e) => {
    if (dismissNotification(e)) {
      this.setState({
        isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
      });
    }
  };

  toggleUserCardPopover = () => {
    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
    });
  };

  render() {
    const { auth, isShowDisplaySetting } = this.props;
    const authIsGodAdmin = get(this.props, "auth.user.is_god_admin", false);
    const authIsAdmin = get(this.props, "auth.user.is_admin", false);
    const isSidebarOpen = get(this.props, "auth.is_sidebar_open", false);
    const isShowMenu = document.location.href.indexOf("jock-console") === -1;
    const authIsSupportAdmin = get(
      this.props,
      "auth.user.is_support_admin",
      false
    );

    if (!auth.authenticated) {
      return null;
    }

    let totalNotifications = get(this.props.notificationsData, "data.total", 0);

    if (isObject(totalNotifications)) {
      totalNotifications = get(
        this.props.notificationsData,
        "data.total.value",
        0
      );
    }

    return (
      <Navbar light expand className={bem.b()}>
        {auth.authenticated && isShowMenu && (
          <Nav navbar>
            <button
              className={classnames(bem.e("nav-toggle"), {
                [bem.e("nav-toggle-open")]: isSidebarOpen,
              })}
              onClick={() => this.props.setIsSidebarOpen(!isSidebarOpen)}
            >
              <HamburgerIcon />
            </button>
          </Nav>
        )}

        {authIsGodAdmin ? (
          <Link
            to={URL.ADMINS({ tab: adminTabs.TAB_UNKNOWN })}
            className={classnames(bem.e("site-logo"))}
          >
            <PromoSuiteLogo />
          </Link>
        ) : (
          <Link to={URL.HOME()} className={classnames(bem.e("site-logo"))}>
            <PromoSuiteLogo />
          </Link>
        )}

        {auth.user && auth.authenticated && (
          <Nav navbar className={bem.e("nav-right")}>
            <NavItem className="d-inline-flex nav-notifications">
              <NavLink
                id="Popover1"
                className={bem.e(
                  "position-relative",
                  bem.e("notification-popover")
                )}
              >
                <div className="d-flex can-click">
                  {totalNotifications === 0 ? (
                    <NotificationsIcon
                      className="can-click"
                      onClick={this.toggleNotificationPopover}
                    />
                  ) : (
                    <span className={bem.e("notification-active-icon")}>
                      <NotificationsIcon
                        className="can-click animated swing infinite"
                        onClick={this.toggleNotificationPopover}
                      />
                      {totalNotifications > 0 && (
                        <span
                          className={classnames(bem.e("notification-total"), {
                            [bem.e("notification-total-small")]:
                              totalNotifications > 99,
                          })}
                        >
                          {totalNotifications > 99 ? "99+" : totalNotifications}
                        </span>
                      )}
                    </span>
                  )}
                  <div
                    onClick={this.toggleNotificationPopover}
                    className={bem.e("link-title")}
                  >
                    <FormattedMessage id="header > notifications" />
                  </div>
                </div>
              </NavLink>
              {this.state.isOpenNotificationPopover && (
                <Notifications
                  toggleNotificationPopover={this.toggleNotificationPopover}
                  user={auth.user}
                />
              )}
            </NavItem>
            {isShowDisplaySetting && (
              <NavLink className="header-display-settings">
                <div
                  className="d-flex can-click"
                  onClick={this.props.showDisplaySettingsModal}
                >
                  <div className={bem.e("link-title")}>
                    <FormattedMessage id="jock console > display settings" />
                  </div>
                </div>
              </NavLink>
            )}
            <NavItem className="d-inline-flex">
              {auth.original_impersonating_admin_state ? (
                <NavLink
                  className={classnames(
                    "position-relative",
                    bem.e("btn-impersonating")
                  )}
                  onClick={() => {
                    this.props.stopImpersonate();
                  }}
                >
                  <div className={bem.e("link-title")}>
                    <FormattedMessage id="header > stop impersonating" />
                  </div>
                </NavLink>
              ) : (
                <NavLink className="position-relative">
                  <div
                    className="d-flex can-click"
                    onClick={() => {
                      history.push({
                        pathname: URL.LOGOUT(),
                        isReminder: true,
                      });
                    }}
                  >
                    <LogoutIcon className="can-click" />
                    <div className={bem.e("link-title")}>
                      <FormattedMessage id="header > logout" />
                    </div>
                  </div>
                </NavLink>
              )}
            </NavItem>
            <NavItem>
              <NavLink id="Popover2" className={bem.e("nav-link-user-avatar")}>
                <Avatar
                  src={auth.user.avatar_url}
                  onClick={this.toggleUserCardPopover}
                  name={auth.user.name}
                  className="can-click avatar-small"
                />
              </NavLink>
              <Popover
                placement="bottom-end"
                isOpen={this.state.isOpenUserCardPopover}
                toggle={this.toggleUserCardPopover}
                target="Popover2"
                className="p-0 shadow"
                style={{ minWidth: 250 }}
              >
                <PopoverBody className="p-0 border-light">
                  <UserCard
                    avatar={auth.user.avatar_url}
                    title={auth.user.name}
                    subtitle={auth.user.email}
                    isShowBackground={true}
                    text=" - "
                    className={bem.e("popover-header-card")}
                  >
                    <ListGroup flush>
                      <ListGroupItem
                        tag="button"
                        action
                        className="border-light"
                        onClick={() => {
                          history.push({
                            pathname: URL.MY_PROFILE(),
                            isReminder: true,
                          });
                          this.toggleUserCardPopover();
                        }}
                      >
                        <MdPersonPin />{" "}
                        <FormattedMessage id="header > profile" />
                      </ListGroupItem>
                      <ListGroupItem
                        tag="button"
                        action
                        className="border-light"
                        onClick={() => {
                          history.push({
                            pathname: URL.SETTINGS(),
                            isReminder: true,
                          });
                          this.toggleUserCardPopover();
                        }}
                      >
                        <MdSettingsApplications />{" "}
                        <FormattedMessage id="header > settings" />
                      </ListGroupItem>
                      <ListGroupItem
                        tag="button"
                        action
                        className="border-light"
                        onClick={() => {
                          history.push({
                            pathname: URL.LOGOUT(),
                            isReminder: true,
                          });
                          this.toggleUserCardPopover();
                        }}
                      >
                        <MdExitToApp />{" "}
                        <FormattedMessage id="header > signout" />
                      </ListGroupItem>

                      {(auth.original_impersonating_admin_state ||
                        authIsGodAdmin) &&
                      auth.impersonation_history &&
                      auth.impersonation_history.length > 0 ? (
                        <>
                          <ListGroupItem
                            tag="button"
                            action
                            className="border-light"
                            style={{ cursor: "default" }}
                            onClick={() => {
                              this.toggleUserCardPopover();
                            }}
                          >
                            <FormattedMessage id="notifications > recent impersonations" />
                          </ListGroupItem>
                        </>
                      ) : (
                        <>
                          {authIsGodAdmin ||
                          authIsAdmin ||
                          authIsSupportAdmin ? (
                            <>
                              <ListGroupItem
                                tag="button"
                                action
                                className="border-light"
                                style={{ cursor: "default" }}
                                onClick={() => {
                                  this.toggleUserCardPopover();
                                }}
                              >
                                <FormattedMessage id="notifications > no recent impersonations" />
                              </ListGroupItem>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                      {(auth.original_impersonating_admin_state ||
                        authIsGodAdmin) &&
                        auth.impersonation_history &&
                        auth.impersonation_history.map((u) => (
                          <ListGroupItem
                            key={"imp_" + u.id}
                            tag="button"
                            action
                            className="border-light"
                            onClick={() => {
                              this.props.impersonateUser(u.id, auth);
                              this.toggleUserCardPopover();
                            }}
                          >
                            <Avatar
                              src={u.avatar_url}
                              name={u.name}
                              className="avatar-small avatar-impersonal"
                              size={24}
                            />
                            {` `}
                            {u.name}
                            <div className="btn btn-blue btn-impersonal">
                              <FaUserSecret style={{ width: "34px" }} />
                            </div>
                          </ListGroupItem>
                        ))}
                    </ListGroup>
                  </UserCard>
                </PopoverBody>
              </Popover>
            </NavItem>
          </Nav>
        )}
      </Navbar>
    );
  }
}

export default compose(
  connect(
    (state) => ({
      auth: state.auth,
      notificationsData: get(state, "messages.notifications"),
    }),
    {
      logoutUser,
      impersonateUser,
      stopImpersonate,
      setIsSidebarOpen,
    }
  )
)(Header);
