import React, { useEffect } from "react";
import bn from "utils/bemnames";
import { ArrowRightIcon } from "components/CustomIcons";
import { useRef } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Row, Col } from "reactstrap";
import classnames from "classnames";
import { values as getValues, isEqual } from "lodash";
import StationSelectors from "components/StationsPicker/StationSelectors";
import AddedStationsList from "components/StationsPicker/AddedStationsList";
import ButtonCircle from "components/ButtonCircle";
import ConfirmationModal from "components/ConfirmationModal";

const HEADER_HEIGHT = 95;

const bem = bn.create("hub-assign-stations");
function ContentAssignStations(props) {
  const {
    stations,
    selectedTags,
    setSelectedTags,
    updateSelectedTags,
    selectedStations,
    setSelectedStations,
    updateSelectedStations,
    addedStations,
    setAddedStations,
    isLoading,
    onSubmit,
    onCancel,
    isOpenSuccessModal,
    setIsOpenSuccessModal,
  } = props;
  const contentHeight = useRef(0);
  const tabs = {
    market: {
      allowStationsSelect: true,
      tabId: "market",
    },
    format: {
      allowStationsSelect: true,
      tabId: "format",
    },
    search: {
      tabId: "search",
    },
  };
  useEffect(() => {
    const sidebar = document.getElementById("right-sidebar-hub-stations");
    if (sidebar) {
      contentHeight.current = sidebar.offsetHeight - HEADER_HEIGHT;
    }
  }, []);
  return (
    <div
      style={{
        height: contentHeight.current,
      }}
      className={classnames(bem.b(), "scroll-bar-style")}
    >
      <div className={bem.e("hub-assign-description")}>
        <FormattedMessage id="hub manager > hub assign stations description" />
      </div>
      <div className={classnames(bem.e("section-container"))}>
        <div className={bem.e("content-view")}>
          <Row>
            <Col xs={6} md={6} className={bem.e("col1")}>
              <StationSelectors
                bem={bem}
                tabs={tabs}
                stations={stations}
                selectedTags={selectedTags}
                setSelectedTags={setSelectedTags}
                updateSelectedTags={updateSelectedTags}
                selectedStations={selectedStations}
                setSelectedStations={setSelectedStations}
                updateSelectedStations={updateSelectedStations}
              />
            </Col>

            <Col xs={6} md={6} className={bem.e("col2")}>
              <div className="add-stations-btn">
                <ButtonCircle
                  width="52px"
                  height="52px"
                  background="#795AFA"
                  disabled={isEqual(selectedStations, addedStations)}
                  boxShadow="0px 0px 10px rgba(0, 0, 0, 0.102)"
                  onClick={() => {
                    setAddedStations({
                      ...addedStations,
                      ...selectedStations,
                    });
                  }}
                >
                  <ArrowRightIcon color="#fff" width="14px" height="16px" />
                </ButtonCircle>
              </div>
              <div className={bem.e("stations-container")}>
                <div className="table-container">
                  <AddedStationsList
                    addedStations={addedStations}
                    setAddedStations={setAddedStations}
                    isView={false}
                    showHeader={true}
                    showTrashIcon={true}
                    NoStationsSelectedComp={
                      <div className="no-stations-selected">
                        <FormattedMessage id="hub manager > no stations selected" />
                      </div>
                    }
                  />
                </div>
                <div className="buttons-container">
                  <div className={bem.e("form-buttons")}>
                    <Button
                      color="blue"
                      type="submit"
                      className="btn btn-radius"
                      disabled={
                        getValues(addedStations).length === 0 || isLoading
                      }
                      onClick={onSubmit}
                    >
                      <FormattedMessage
                        id={`hub manager > button ${
                          !isLoading ? "save hub" : "saving"
                        }`}
                      />
                    </Button>
                    <Button
                      type="button"
                      outline
                      disabled={getValues(addedStations).length === 0}
                      className={classnames(
                        bem.e("button-clear"),
                        "btn-radius"
                      )}
                      color="blue"
                      onClick={(e) => setAddedStations({})}
                    >
                      <FormattedMessage id="hub manager > button clear" />
                    </Button>
                    <Button
                      type="button"
                      outline
                      className={classnames(
                        bem.e("button-cancel"),
                        "btn-radius"
                      )}
                      color="blue"
                      onClick={onCancel}
                    >
                      <FormattedMessage id="hub manager > button cancel" />
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <ConfirmationModal
        isOpen={isOpenSuccessModal}
        onToggle={() => setIsOpenSuccessModal(false)}
        onCancel={() => setIsOpenSuccessModal(false)}
        state="success"
        title={<FormattedMessage id={`hub manager > saved successfully`} />}
      />
    </div>
  );
}
ContentAssignStations.defaultProps = {};
ContentAssignStations.propTypes = {};

export default ContentAssignStations;
