/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import bn from "utils/bemnames";
import useOutsideClick from "utils/useOutsideClick";
import { map, get } from "lodash";
import { NewWindowIcon } from "components/CustomIcons";
const bem = bn.create("roles-manager");
const ListUsers = (props) => {
  const { role } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const selectorRef = React.useRef(null);
  useOutsideClick(selectorRef, () => {
    setIsOpen(false);
  });
  const users = get(role, "users", []);
  const onClick = (user) => {
    const { location } = window;
    setIsOpen(false);
    const url = `${location.origin}/admins/users?user=${user.id}`;
    window.open(url, "_blank").focus();
  };
  if (users.length.length === 0) return;
  return (
    <div className={bem.e("section-users")} ref={selectorRef}>
      <span className={bem.e("total-users")} onClick={() => setIsOpen(!isOpen)}>
        ({users.length})
      </span>
      {isOpen && (
        <div className={bem.e("users")}>
          <div className={bem.e("users-title")}>{role.name || ""}</div>
          {map(users, (user, index) => (
            <div className={bem.e("user-item")} key={index}>
              {user.name}
              <a
                href="#"
                onClick={() => onClick(user)}
                target="_blank"
                className={bem.e("user-link")}
              >
                <NewWindowIcon />
              </a>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export default ListUsers;
