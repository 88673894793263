import "babel-polyfill";
import React from "react";
import "react-table/react-table.css";
import "react-chat-elements/dist/main.css";
import "emoji-mart/css/emoji-mart.css";
import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import "rc-checkbox/assets/index.css";
import "react-datepicker/dist/react-datepicker.css";
import "draft-js/dist/Draft.css";
import "react-quill/dist/quill.snow.css";
import { STATE_LOGIN, STATE_SIGNUP } from "./components/AuthForm";
import GAListener from "./components/GAListener";
import { LayoutRoute, MainLayout } from "./components/Layout";
import { get, isEqual, findIndex } from "lodash";
import IdleTimer from "react-idle-timer";
// pages
import Dashboard from "./pages/Dashboard";
import AuthPage from "./pages/Auth";
import Logout from "./pages/Logout";
import AdminLoginPage from "./pages/AdminLoginPage";
import AddUpdateStationPage from "./pages/AddUpdateStation";

import Processes from "./pages/Processes";
import Template from "./pages/Template";
import Templates from "./pages/Templates";
import AdminImport from "./pages/AdminImport";
// admins pages
import Admins from "./pages/Admins";
// form manager
import FormManagerPage from "./pages/FormManager";
import SystemTemplates from "./pages/SystemTemplates";
// workflow
import WorkflowManager from "./pages/WorkflowManager";
// station settings
import StationSettingsPage from "./pages/StationSettings";
// production settings
import ProductionSettingsPage from "./pages/ProductionSettings";
// enterprise settings
import EnterpriseSettingsPage from "./pages/EnterpriseSettings";

//prizes pages
import Prizes from "./pages/Prizes";
import { AddPrizesPage } from "./pages/Prize/AddPrizes";
import { ViewPrizesPage } from "./pages/Prize/ViewPrizes";
import { UpdatePrizesPage } from "./pages/Prize/UpdatePrizes";
// winners
import Winners from "./pages/Winners";
import Winner from "./pages/Winner";
// client manager
import ClientManager from "./pages/ClientManager";
import AddUpdateClientManager from "./pages/AddUpdateClientManager";
import Resources from "./pages/Resources";
import { AddResourcesPage } from "./pages/Resource/AddResources";
import { ViewResourcesPage } from "./pages/Resource/ViewResources";
import { UpdateResourcesPage } from "./pages/Resource/UpdateResources";

import { AddProcessPage } from "./pages/Process/AddProcess";
import { ViewProcessPage } from "./pages/Process/ViewProcess";
import { UpdateProcessPage } from "./pages/Process/UpdateProcess";
import Listener from "listener";
import UploadFile from "./pages/UploadFile";
import JockConsole from "./pages/JockConsole";
import JockConsoleManager from "./pages/JockConsoleManager";

// import TestPage from "./pages/TestPage";

import MyProfile from "./pages/MyProfile";
import Settings from "./pages/Settings";
import Help from "./pages/Help";
import Messages from "./pages/Messages";
import Audit from "./pages/Audit";
import ClockManagerPage from "./pages/ClockManager";
import ClockCreatorPage from "./pages/ClockCreator";
// client review
import ClientReviewPage from "./pages/ClientReview";
// schedule
import Schedule from "./pages/Schedule";
// Promotion 1099 reports
import PromotionReports from "./pages/PromotionReports";
import ToastManager, { Toast } from "./components/ToastManager";
// hub manager
import HubManagerPage from "./pages/HubManager";
// team management
import TeamManagementPage from "./pages/TeamManagement";
// home mobile
import HomeMobile from "pages/HomeMobile";
// user profile
import UserProfile from "pages/UserProfile";

import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import componentQueries from "react-component-queries";
import { Router, Redirect, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import {
  setIsNotification,
  // userInfo,
  setLanguage,
  logoutUser,
} from "store/actions/auth";
// import { getCurrentServer } from "store/actions/servers";
import { getNotifications } from "store/actions/messages";
import store from "./store";
import "./styles/reduction.css";
import history from "components/History";
import URL from "./utils/urls";
import { getTranslates } from "utils/helpers";
import { IntlProvider, addLocaleData } from "react-intl";
import { language as defaultLangCode, languageList } from "./utils/config";
import ScrollToTop from "utils/scrollToTop";
import en from "react-intl/locale-data/en";
import es from "react-intl/locale-data/es";

addLocaleData([...en, ...es]);

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split("/").pop()}`;
};

class Routes extends React.Component {
  constructor() {
    super();
    this.state = {
      requestFrom: null,
    };
    this.system_idle_timeout = null;
  }

  renderAuthenticatedRoutes(limitAccess) {
    const baseProps = this.props;
    const authenticated = get(baseProps, "auth.authenticated");
    const isAdminLoginRequest =
      document.location.href.indexOf("admin/login") > -1;

    if (limitAccess) {
      return (
        <Switch>
          <LayoutRoute
            path={URL.MY_PROFILE({ path: true })}
            layout={MainLayout}
            component={MyProfile}
          />
          <Redirect to={URL.MY_PROFILE({ path: true })} />
        </Switch>
      );
    }
    return (
      <Switch>
        {!authenticated && !isAdminLoginRequest && (
          <>
            <Redirect
              to={{
                pathname: URL.LOGIN({ path: true }),
                state: {
                  requestFrom:
                    window.location.pathname +
                    (window.location.search != undefined
                      ? window.location.search
                      : ""),
                },
              }}
            />
          </>
        )}
        <LayoutRoute
          exact
          path={URL.TEMPLATE({ path: true })}
          layout={MainLayout}
          component={Template}
        />
        <LayoutRoute
          exact
          path={URL.PRIZES({ path: true })}
          layout={MainLayout}
          component={Prizes}
        />
        <LayoutRoute
          exact
          path={URL.UPDATE_PRIZES({ path: true })}
          layout={MainLayout}
          component={UpdatePrizesPage}
        />
        <LayoutRoute
          exact
          path={URL.VIEW_PRIZES({ path: true })}
          layout={MainLayout}
          component={ViewPrizesPage}
        />
        <LayoutRoute
          exact
          path={URL.ADD_PRIZES({ path: true })}
          layout={MainLayout}
          component={AddPrizesPage}
        />
        <LayoutRoute
          exact
          path={URL.RESOURCES({ path: true })}
          layout={MainLayout}
          component={Resources}
        />
        <LayoutRoute
          exact
          path={URL.UPDATE_RESOURCES({ path: true })}
          layout={MainLayout}
          component={UpdateResourcesPage}
        />
        <LayoutRoute
          exact
          path={URL.VIEW_RESOURCES({ path: true })}
          layout={MainLayout}
          component={ViewResourcesPage}
        />
        <LayoutRoute
          exact
          path={URL.ADD_RESOURCES({ path: true })}
          layout={MainLayout}
          component={AddResourcesPage}
        />
        <LayoutRoute
          exact
          path={URL.PROCESSES({ path: true })}
          layout={MainLayout}
          component={Processes}
        />
        <LayoutRoute
          exact
          path={URL.ADMIN({ path: true })}
          layout={MainLayout}
          component={AdminImport}
        />
        <LayoutRoute
          exact
          path={URL.PROCESSES({ filter: "active" })}
          layout={MainLayout}
          component={Processes}
        />
        <LayoutRoute
          exact
          path={URL.ADD_STATION({ path: true })}
          layout={MainLayout}
          component={AddUpdateStationPage}
        />
        <LayoutRoute
          exact
          path={URL.TEMPLATES({ path: true })}
          layout={MainLayout}
          component={Templates}
        />
        <LayoutRoute
          exact
          path={URL.TEMPLATE({ path: true })}
          layout={MainLayout}
          component={Template}
        />
        <LayoutRoute
          exact
          path={URL.UPDATE_TEMPLATES({ path: true })}
          layout={MainLayout}
          component={Template}
        />
        <LayoutRoute
          exact
          path={URL.UPDATE_PROCESS({ path: true })}
          layout={MainLayout}
          component={UpdateProcessPage}
        />
        <LayoutRoute
          exact
          path={URL.VIEW_PROCESS({ path: true })}
          layout={MainLayout}
          component={ViewProcessPage}
        />
        <LayoutRoute
          exact
          path={URL.ADD_PROCESS({ path: true })}
          layout={MainLayout}
          component={AddProcessPage}
        />
        <LayoutRoute
          path={URL.ADMINS_TAB_ADD({ path: true })}
          layout={MainLayout}
          component={Admins}
        />
        <LayoutRoute
          exact
          path={URL.UPLOAD_FILE({ path: true })}
          layout={MainLayout}
          component={UploadFile}
        />
        <LayoutRoute
          path={URL.MY_PROFILE({ path: true })}
          layout={MainLayout}
          component={MyProfile}
        />
        <LayoutRoute
          path={URL.SETTINGS({ path: true })}
          layout={MainLayout}
          component={Settings}
        />
        <LayoutRoute
          path={URL.HELP({ path: true })}
          layout={MainLayout}
          component={Help}
        />
        <LayoutRoute
          path={URL.MESSAGES({ path: true })}
          layout={MainLayout}
          component={Messages}
        />
        <LayoutRoute
          path={URL.AUDIT({ path: true })}
          layout={MainLayout}
          component={Audit}
        />
        <LayoutRoute
          path={URL.ADMINS({ path: true })}
          layout={MainLayout}
          component={Admins}
        />
        <LayoutRoute
          path={URL.DASHBOARD({ path: true })}
          layout={MainLayout}
          component={Dashboard}
        />
        <LayoutRoute
          path={URL.FORM_MANAGER({ path: true })}
          layout={MainLayout}
          component={FormManagerPage}
        />
        <LayoutRoute
          path={URL.SYSTEM_TEMPLATES({ path: true })}
          layout={MainLayout}
          component={SystemTemplates}
        />
        <LayoutRoute
          path={URL.STATION_SETTINGS({ path: true })}
          layout={MainLayout}
          component={StationSettingsPage}
        />

        <LayoutRoute
          path={URL.WORKFLOW_MANAGER({ path: true })}
          layout={MainLayout}
          component={WorkflowManager}
        />
        <LayoutRoute
          path={URL.HOME_MOBILE({ path: true })}
          layout={MainLayout}
          componentProps={{
            isShowSidebar: false,
          }}
          component={HomeMobile}
        />
        <LayoutRoute
          exact
          path={URL.CLOCK_MANAGER({ path: true })}
          layout={MainLayout}
          component={ClockManagerPage}
        />
        <LayoutRoute
          exact
          path={URL.CLOCK_CREATOR({ path: true })}
          layout={MainLayout}
          component={ClockCreatorPage}
        />
        <LayoutRoute
          exact
          path={URL.CLOCK_EDITOR({ path: true })}
          layout={MainLayout}
          component={ClockCreatorPage}
        />
        {/* client manager */}

        <LayoutRoute
          exact
          path={URL.CLIENT_MANAGER({ path: true })}
          layout={MainLayout}
          component={ClientManager}
        />
        <LayoutRoute
          exact
          path={URL.ADD_CLIENT_MANAGER({ path: true })}
          layout={MainLayout}
          component={AddUpdateClientManager}
        />
        <LayoutRoute
          exact
          path={URL.CLIENT_MANAGER_FORM({ path: true })}
          layout={MainLayout}
          component={AddUpdateClientManager}
        />
        <LayoutRoute
          exact
          path={URL.SCHEDULE()}
          layout={MainLayout}
          component={Schedule}
        />
        <LayoutRoute
          exact
          path={URL.JOCK_CONSOLE_MANAGER()}
          layout={MainLayout}
          component={JockConsoleManager}
        />
        <LayoutRoute
          exact
          path={URL.JOCK_CONSOLE()}
          layout={MainLayout}
          isShowSidebar
          componentProps={{
            isShowSidebar: false,
          }}
          component={JockConsole}
        />
        {/* winners */}
        <LayoutRoute
          exact
          path={URL.WINNERS({ path: true })}
          layout={MainLayout}
          component={Winners}
        />
        {/* promotion reports */}
        <LayoutRoute
          exact
          path={URL.PROMOTION_REPORTS({ path: true })}
          layout={MainLayout}
          component={PromotionReports}
        />
        {/* production settings */}
        <LayoutRoute
          exact
          path={URL.PRODUCTION_SETTINGS({ path: true })}
          layout={MainLayout}
          component={ProductionSettingsPage}
        />
        <LayoutRoute
          exact
          path={URL.WINNER({ path: true })}
          layout={MainLayout}
          component={Winner}
        />
        {/* Logout */}
        <LayoutRoute
          exact
          path={URL.LOGOUT()}
          layout={MainLayout}
          component={Logout}
        />
        {/* enterprise settings */}
        <LayoutRoute
          exact
          path={URL.ENTERPRISE_SETTINGS({ path: true })}
          layout={MainLayout}
          component={EnterpriseSettingsPage}
        />
        {/* hub manager */}
        <LayoutRoute
          exact
          path={URL.HUB_MANAGER({ path: true })}
          layout={MainLayout}
          component={HubManagerPage}
        />
        {/* team management */}
        <LayoutRoute
          exact
          path={URL.TEAM_MANAGEMENT({ path: true })}
          layout={MainLayout}
          component={TeamManagementPage}
        />
        {/* user profile */}
        <LayoutRoute
          exact
          path={URL.USER({ path: true })}
          layout={MainLayout}
          component={UserProfile}
        />
      </Switch>
    );
  }

  onAction = (e) => {
    if (this.system_idle_timeout !== null) {
      localStorage.setItem(
        "_expiredTime",
        Date.now() + this.system_idle_timeout * 60 * 1000
      );
    }
  };

  onActive = (e) => {};

  onIdle = () => {
    if (this.props.auth.authenticated) {
      this.props.logoutUser();
      this.idleTimer.reset();
    }
  };

  render() {
    const baseProps = this.props;
    const locale =
      findIndex(
        languageList,
        (item) => item.value === get(this.props, "auth.user.lang")
      ) !== -1
        ? get(this.props, "auth.user.lang")
        : defaultLangCode;
    const messages = get(this.props, "auth.info.translates")
      ? get(this.props, "auth.info.translates")
      : getTranslates(locale);

    const system_idle_timeout =
      parseInt(get(this.props, "auth.user.system_idle_timeout", 360)) > 0
        ? parseInt(get(this.props, "auth.user.system_idle_timeout", 360))
        : parseInt(
            get(this.props, "auth.info.server_system_idle_timeout", 360)
          ) > 0 //
        ? parseInt(get(this.props, "auth.info.server_system_idle_timeout", 360))
        : 360;

    if (this.props.auth.authenticated && system_idle_timeout > 0) {
      const expiredTime = localStorage.getItem("_expiredTime");
      if (expiredTime && expiredTime <= Date.now()) {
        this.system_idle_timeout = null;
        this.props.logoutUser();
      } else {
        this.system_idle_timeout = system_idle_timeout;
        localStorage.setItem(
          "_expiredTime",
          Date.now() + system_idle_timeout * 60 * 1000
        );
      }
    }

    const isAuthenticated = get(baseProps, "auth.authenticated");
    const userPrivileges = get(baseProps, "auth.user.privileges");
    const noPrivileges = get(userPrivileges, "length", 0) === 0;
    const limitAccess =
      isAuthenticated &&
      noPrivileges &&
      !get(this.props, "auth.user.is_god_admin", false);

    return (
      <IntlProvider locale={locale} messages={messages}>
        <Router basename={getBasename()} history={history}>
          <GAListener>
            <ScrollToTop />
            <Switch>
              <LayoutRoute
                exact
                path={URL.HOME({ path: true })}
                layout={MainLayout}
                render={(props) => {
                  if (!baseProps.auth.authenticated) {
                    return <Redirect to={URL.LOGIN({ path: true })} />;
                  }
                  if (
                    (this.props.auth.info.app_flags &&
                      this.props.auth.info.app_flags.indexOf("SERVERS_LIST") >
                        -1) ||
                    get(this.props, "auth.user.is_god_admin", false)
                  ) {
                    return (
                      <Redirect to={URL.ADMINS({ tab: "broadcasting" })} />
                    );
                  }
                  if (limitAccess) {
                    return <Redirect to={URL.MY_PROFILE({ path: true })} />;
                  }
                  // return <Dashboard {...props} />;
                  return <Processes filter="my_action_items" />;
                }}
              />
              <LayoutRoute
                exact
                path={URL.ADMIN_LOGIN({ path: true })}
                layout={MainLayout}
                render={(props) => <AdminLoginPage {...props} />}
              />
              <LayoutRoute
                exact
                path={URL.LOGIN({ path: true })}
                layout={MainLayout}
                render={(props) => (
                  <AuthPage {...props} authState={STATE_LOGIN} />
                )}
              />
              <LayoutRoute
                exact
                path={URL.SIGN_UP({ path: true })}
                layout={MainLayout}
                render={(props) => (
                  <AuthPage {...props} authState={STATE_SIGNUP} />
                )}
              />
              {/* public page */}
              <Route
                exact
                path={URL.CLIENT_REVIEW({ path: true })}
                component={ClientReviewPage}
              />
              {/* <LayoutRoute
                exact
                path={URL.CALENDAR({ path: true })}
                layout={MainLayout}
                component={Calendar}
              /> */}
              {this.renderAuthenticatedRoutes(limitAccess)}
              <Redirect
                to={
                  limitAccess
                    ? URL.MY_PROFILE({ path: true })
                    : URL.HOME({ path: true })
                }
              />
            </Switch>
            {this.props.auth.authenticated && system_idle_timeout > 0 && (
              <IdleTimer
                ref={(ref) => {
                  this.idleTimer = ref;
                }}
                element={document}
                onActive={this.onActive}
                onIdle={this.onIdle.bind(this)}
                onAction={this.onAction}
                debounce={250}
                timeout={1000 * 60 * system_idle_timeout}
              />
            )}
          </GAListener>
        </Router>
      </IntlProvider>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: "xs" };
  }

  if (576 < width && width < 767) {
    return { breakpoint: "sm" };
  }

  if (768 < width && width < 991) {
    return { breakpoint: "md" };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: "lg" };
  }

  if (width > 1200) {
    return { breakpoint: "xl" };
  }

  return { breakpoint: "xs" };
};

const RoutesWithQuery = compose(
  connect(
    ({ auth, language }) => ({ auth, language }),
    (dispatch) => ({
      setIsNotification: (status) => dispatch(setIsNotification(status)),
      // userInfo: () => dispatch(userInfo()),
      setLanguage: () => dispatch(setLanguage()),
      logoutUser: () => dispatch(logoutUser()),
      getNotifications: () => dispatch(getNotifications()),
      // getCurrentServer: () => dispatch(getCurrentServer()),
    })
  ),
  componentQueries(query),
  lifecycle({
    componentDidMount() {
      const isClientReview =
        document.location.pathname.split("/")[1] === "review";
      const isNotification = localStorage.getItem("notify");
      try {
        Notification.requestPermission()
          .then((permission) => {
            if (permission === "granted") {
              if (isNotification === null) {
                this.props.setIsNotification(true);
              }
            } else {
              if (!isNotification) {
                this.props.setIsNotification(false);
              }
            }
          })
          .catch((err) => {
            this.props.setIsNotification(false);
          });
      } catch (error) {
        if (error instanceof TypeError) {
          Notification.requestPermission(() => {
            if (isNotification === null) {
              this.props.setIsNotification(true);
            } else {
              this.props.setIsNotification(false);
            }
          });
        } else {
          this.props.setIsNotification(false);
        }
      }
      const authenticated = get(this.props, "auth.authenticated");
      if (authenticated && !isClientReview) {
        // this.props.userInfo();
        this.props.getNotifications();
        // this.props.getCurrentServer();
      }
      // refresh translates
      const locale =
        findIndex(
          languageList,
          (item) => item.value === get(this.props, "auth.user.lang")
        ) !== -1
          ? get(this.props, "auth.user.lang")
          : defaultLangCode;
      const messages = get(this.props, "auth.info.translates");
      const staticMessages = getTranslates(locale);
      if (!isEqual(messages, staticMessages)) {
        this.props.setLanguage(locale);
      }
    },
  })
)(Routes);

class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        {/* <PersistGate loading={null} persistor={persistor}> */}
        <React.Fragment>
          <Listener store={store} />
          <RoutesWithQuery />
          <Toast ref={(ref) => ToastManager.registerToast(ref)} />
        </React.Fragment>
        {/* </PersistGate> */}
      </Provider>
    );
  }
}

export default App;
