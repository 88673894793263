import AddedStationsList from './view';
import { injectIntl } from 'react-intl';
import { compose, withHandlers } from 'recompose';
import {connect} from 'react-redux'
import { get, omit } from 'lodash';

export default injectIntl(
	compose(
		connect((state) => ({
			auth: get(state, 'auth', {})
		}), {}),
		withHandlers({
			removeStation: ({setAddedStations, addedStations}) => (station) => {
				setAddedStations(omit(addedStations, station.key));
			}
		})
	)(AddedStationsList)
);