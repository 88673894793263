import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody } from "reactstrap";
import classnames from "classnames";
import { get, includes } from "lodash";
import bn from "utils/bemnames";
import { Calendar, momentLocalizer, Views } from "components/CalendarEvents";
import moment from "moment";
import Spinner from "components/Spinner";
import PopoverEventDetail from "components/PopoverEventDetail";
const bem = bn.create("calendar-events-modal");
moment.locale("en-us", {
  week: {
    dow: 1
  }
});
const localizer = momentLocalizer(moment);

class CalendarEventsModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      popoverEvent: null,
      dayChosen: new Date()
    };
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setPopoverEvent = popoverEvent => {
    this.setState({ popoverEvent });
  };
  handleClickOutside = event => {
    // hide popover detail
    if (!includes(get(event, "toElement.className"), "ps-rbc-event-inside")) {
      this.setPopoverEvent(null);
    }
  };

  render() {
    const {
      isOpen,
      onToggle,
      className,
      stations,
      onChangeStation,
      setDay,
      onChangeType,
      currentType,
      events,
      day,
      isLoading
    } = this.props;
    const Event = React.memo(props => {
      return (
        <>
          <div className="ps-rbc-event-content-wrapper ps-rbc-event-inside">
            <div className="ps-rbc-event-content ps-rbc-event-inside">
              <h3 className="event-title ps-rbc-event-inside">
                {get(props, "title")}
              </h3>
              <span className="event-subtitle ps-rbc-event-inside">
                {get(props, "event.subtitle")}
              </span>
            </div>
          </div>
          <PopoverEventDetail
            event={props.event}
            localizer={localizer}
            active={this.state.popoverEvent}
          />
        </>
      );
    });
    return (
      <Modal
        isOpen={isOpen}
        toggle={onToggle}
        size={"lg"}
        className={classnames(className, bem.b())}
        scrollable="true"
      >
        <ModalBody className={bem.e("body")}>
          <Calendar
            events={events}
            localizer={localizer}
            defaultView={Views.WEEK}
            views={["week"]}
            className={classnames("ps-calendar-events", {
              [`ps-calendar-events-${day}`]: !!day
            })}
            components={{
              event: Event
            }}
            onSelectEvent={(event, e) => {
              if (this.state.popoverEvent === event.id) {
                this.setPopoverEvent(null);
              } else {
                this.setPopoverEvent(event.id);
              }
            }}
            toolbarStations={stations}
            toolbarDays={[1, 2, 3, 4, 5, 6, 7]}
            onChangeStation={onChangeStation}
            onChangeDay={setDay}
            onChangeType={onChangeType}
            currentType={currentType}
            currentModeDay={day}
            startAccessor="start"
            endAccessor="end"
            date={this.state.dayChosen}
            onNavigate={(focusDate, flipUnit, prevOrNext) => {
              if (prevOrNext === "NEXT") {
                const nextMonthFirstDay = moment(this.state.dayChosen)
                  .add(1, "months")
                  .startOf("month")
                  .toDate();

                this.setState({
                  dayChosen: nextMonthFirstDay
                });
                this.props.onChangeMonth(nextMonthFirstDay);
              } else if (prevOrNext === "PREV") {
                const prevMonthFirstDay = moment(this.state.dayChosen)
                  .subtract(1, "months")
                  .startOf("month")
                  .toDate();
                this.setState({
                  dayChosen: prevMonthFirstDay
                });
                this.props.onChangeMonth(prevMonthFirstDay);
              }
            }}
          />
          <Spinner isLoading={isLoading} />
        </ModalBody>
      </Modal>
    );
  }
}

CalendarEventsModal.propTypes = {
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
  onChangeDay: PropTypes.func,
  onChangeStation: PropTypes.func
};

CalendarEventsModal.defaultProps = {
  onToggle: () => {},
  onChangeDay: () => {},
  onChangeStation: () => {}
};

export default CalendarEventsModal;
