import * as Types from "store/types/roles";
import { LOGOUT_USER } from "store/types/auth";

const initialState = {
  roles: {
    data: [],
    error: null,
    loading: false,
    is_dirty: false,
  },
  cloneRole: {
    error: null,
    loading: false,
  },
  activateRole: {
    error: null,
    loading: false,
  },
  deleteRole: {
    error: null,
    loading: false,
  },
  updateRole: {
    error: null,
    loading: false,
  },
  rolesProductionTeam: {
    data: [],
    error:null,
    loading: false
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_ROLES_REQUEST: {
      return {
        ...state,
        roles: { ...state.roles, error: null, loading: true },
      };
    }
    case Types.FETCH_ROLES_SUCCESS: {
      return {
        ...state,
        roles: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    }
    case Types.FETCH_ROLES_ERROR: {
      return {
        ...state,
        roles: {
          data: null,
          error: action.payload,
          loading: false,
        },
      };
    }
    case Types.UPDATE_ORDER_ROLES_SUCCESS: {
      return {
        ...state,
        roles: {
          data: action.payload,
          error: action.payload,
          loading: false,
        },
      };
    }
    case Types.CLONE_ROLE_REQUEST: {
      return {
        ...state,
        cloneRole: {
          error: null,
          loading: true,
        },
      };
    }
    case Types.CLONE_ROLE_SUCCESS: {
      return {
        ...state,
        cloneRole: {
          error: null,
          loading: false,
        },
      };
    }
    case Types.CLONE_ROLE_ERROR: {
      return {
        ...state,
        cloneRole: {
          error: action.payload,
          loading: false,
        },
      };
    }
    case Types.UPDATE_ROLE_ACTIVATE_REQUEST: {
      return {
        ...state,
        activateRole: {
          error: null,
          loading: true,
        },
      };
    }
    case Types.UPDATE_ROLE_ACTIVATE_SUCCESS: {
      return {
        ...state,
        activateRole: {
          error: null,
          loading: false,
        },
      };
    }
    case Types.UPDATE_ROLE_ACTIVATE_ERROR: {
      return {
        ...state,
        activateRole: {
          error: action.payload,
          loading: false,
        },
      };
    }
    // delete role
    case Types.DELETE_ROLE_REQUEST: {
      return {
        ...state,
        deleteRole: {
          error: null,
          loading: true,
        },
      };
    }
    case Types.DELETE_ROLE_SUCCESS: {
      return {
        ...state,
        deleteRole: {
          error: null,
          loading: false,
        },
      };
    }
    case Types.DELETE_ROLE_ERROR: {
      return {
        ...state,
        deleteRole: {
          error: action.payload,
          loading: false,
        },
      };
    }
    // update role
    case Types.UPDATE_ROLE_REQUEST: {
      return {
        ...state,
        updateRole: {
          error: null,
          loading: true,
        },
      };
    }
    case Types.UPDATE_ROLE_SUCCESS: {
      return {
        ...state,
        updateRole: {
          error: null,
          loading: false,
        },
      };
    }
    case Types.UPDATE_ROLE_ERROR: {
      return {
        ...state,
        updateRole: {
          error: action.payload,
          loading: false,
        },
      };
    }
    case Types.FETCH_ROLES_PRODUCTION_TEAM_REQUEST: {
      return {
        ...state,
        rolesProductionTeam: { ...state.roles, error: null, loading: true },
      };
    }
    case Types.FETCH_ROLES_PRODUCTION_TEAM_SUCCESS: {
      return {
        ...state,
        rolesProductionTeam: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    }
    case Types.FETCH_ROLES_PRODUCTION_TEAM_ERROR: {
      return {
        ...state,
        rolesProductionTeam: {
          data: null,
          error: action.payload,
          loading: false,
        },
      };
    }
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
}
