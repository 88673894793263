import React, { useRef } from "react";
import Select, { components } from "react-select";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import { findIndex, map } from "lodash";
import bn from "utils/bemnames";
import { Button } from "reactstrap";
import classnames from "classnames";
import {
  CloseSmallIcon,
  CaretIcon,
  CloseTagDropdownIcon,
  PlusIconSmaller,
} from "components/CustomIcons";
import { get } from "lodash";
export const TAG_TYPE_ALL = "all";
export const TAG_TYPE_LOCATION = "location";
export const TAG_TYPE_EXCEPT_LOCATION_FORMAT = "except_location_format";
export const TAG_TYPE_EXCEPT_OTHER = "except_other";
export const TAG_TYPE_FORMAT = "format";
export const TAG_TYPE_DMAs = "DMAs";
const bem = bn.create("select-tags");
function View(props) {
  const {
    tags,
    isMulti,
    checked,
    closeMenuOnSelect,
    allOption,
    onChange,
    disabled,
    isInputSearch,
    currentSelected,
    setCurrentSelected,
    isMenuOpen,
    setIsMenuOpen,
    isShowPopularTags,
    createLabel,
  } = props;
  if (disabled) {
    return (
      <div className={bem.b()}>
        <div className={bem.e("view-tags")}>
          {map(checked, (item, index) => (
            <span key={index} className={bem.e("tag-view")}>
              {item.label}
            </span>
          ))}
        </div>
      </div>
    );
  }
  let configComponents = {};
  const selectRef = useRef(null);
  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <CaretIcon />
        </components.DropdownIndicator>
      )
    );
  };
  configComponents.DropdownIndicator = DropdownIndicator;
  const ClearIndicator = (props) => {
    return (
      components.ClearIndicator && (
        <components.ClearIndicator {...props}>
          <CloseSmallIcon />
        </components.ClearIndicator>
      )
    );
  };
  configComponents.ClearIndicator = ClearIndicator;
  const MultiValueRemove = (props) => {
    return (
      components.MultiValueRemove && (
        <components.MultiValueRemove {...props}>
          <CloseTagDropdownIcon />
        </components.MultiValueRemove>
      )
    );
  };
  configComponents.MultiValueRemove = MultiValueRemove;
  if (props.allowNew) {
    const Menu = (menuProps) => {
      return (
        <components.Menu {...menuProps}>
          {menuProps.children}
          <div className={bem.e("menu-new")} onClick={props.onCreateOption}>
            <PlusIconSmaller />
            <span className={bem.e("new-label")}>
              {createLabel ? (
                createLabel
              ) : (
                <FormattedMessage id="select tags > add new" />
              )}
            </span>
          </div>
        </components.Menu>
      );
    };
    configComponents.Menu = Menu;
  }

  if (isInputSearch) {
    return (
      <div className={bem.b()}>
        <div className={bem.e("react-input-select-container")}>
          <Select
            ref={selectRef}
            options={tags}
            value={currentSelected}
            components={configComponents}
            placeholder={
              props.placeholder
                ? props.placeholder
                : props.intl.formatMessage(
                    {
                      id: "select tags > placeholder",
                    },
                    {
                      name: props.name,
                    }
                  )
            }
            className={classnames("react-select-container", {
              "react-select-menu-open": isMenuOpen,
            })}
            onMenuOpen={() => setIsMenuOpen(true)}
            onMenuClose={() => setIsMenuOpen(false)}
            classNamePrefix="react-select"
            closeMenuOnSelect={closeMenuOnSelect}
            onChange={(selected) => {
              return setCurrentSelected(selected);
            }}
          />
          <Button
            type="button"
            className={classnames(
              "btn-radius",
              "btn-blue",
              bem.e("button-add")
            )}
            onClick={() => {
              if (!currentSelected) return;
              const isSelected =
                findIndex(
                  checked,
                  (item) => item.value === currentSelected.value
                ) !== -1;
              if (!isSelected) {
                checked.push(currentSelected);
              }
              setCurrentSelected(null);
              return onChange(checked);
            }}
          >
            <FormattedMessage id="select tags > button add" />
          </Button>
        </div>
        <div className={bem.e("selected-tags")}>
          <h4 className={bem.e("selected-title")}>
            <FormattedMessage id="select tags > selected tags" />
          </h4>
          {map(checked, (item, index) => (
            <span key={index} className={bem.e("tag-view")}>
              <span
                className={bem.e("remove-tag")}
                onClick={() => {
                  checked.splice(index, 1);
                  onChange(checked);
                }}
              >
                <CloseSmallIcon />
              </span>
              {item.label}
            </span>
          ))}
        </div>
        {isShowPopularTags && (
          <div className={bem.e("view-most")}>
            <FormattedMessage id="select tags > view most popular tags" />
          </div>
        )}
      </div>
    );
  }
  return (
    <div className={bem.b()}>
      <Select
        ref={selectRef}
        options={tags}
        components={configComponents}
        placeholder={
          props.placeholder
            ? props.placeholder
            : props.intl.formatMessage(
                {
                  id: "select tags > placeholder",
                },
                {
                  name: props.name,
                }
              )
        }
        className={classnames("react-select-container", {
          "react-select-menu-open": isMenuOpen,
        })}
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => setIsMenuOpen(false)}
        classNamePrefix="react-select"
        isMulti={isMulti}
        value={checked}
        closeMenuOnSelect={closeMenuOnSelect}
        onChange={(selected) => {
          if (
            (selected.length > 0 &&
              selected[selected.length - 1].value === allOption.value) ||
            selected.length === tags.length
          ) {
            selectRef.current.blur(); // close dropdown
            return onChange(
              tags.filter((item) => item.value !== allOption.value)
            );
          }
          if (!isMulti && get(selected, "value") === allOption.value) {
            selectRef.current.blur(); // close dropdown
            return onChange(allOption);
          }
          return onChange(selected);
        }}
      />
      {props.error && <div className="text-danger">{props.error}</div>}
    </div>
  );
}
View.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  isMulti: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
  type: PropTypes.oneOf([
    TAG_TYPE_ALL,
    TAG_TYPE_LOCATION,
    TAG_TYPE_FORMAT,
    TAG_TYPE_DMAs,
    TAG_TYPE_EXCEPT_LOCATION_FORMAT,
    TAG_TYPE_EXCEPT_OTHER,
  ]),
  isInputSearch: PropTypes.bool,
  onCreateOption: PropTypes.func,
};

View.defaultProps = {
  allowSelectAll: false,
  allOption: {
    label: "Select All",
    value: "*",
  },
  name: "tags",
  isMulti: true,
  closeMenuOnSelect: true,
  type: TAG_TYPE_ALL,
  isInputSearch: false,
  isShowPopularTags: true,
  onCreateOption: () => {},
};
export default injectIntl(View);
