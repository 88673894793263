import View from "./view";
import { get, findIndex, isFunction } from "lodash";
import { compose, withHandlers, withState } from "recompose";
import { labelToNameField } from "utils/helpers";
export default compose(
  withState("currentMedia", "setCurrentMedia", null),
  withState("currentAsset", "setCurrentAsset", null),
  withState(
    "isRejectConfirmationModalOpen",
    "setIsRejectConfirmationModalOpen",
    false
  ),
  withHandlers({
    onAutoSubmit: ({ buttonClicked, template, user, step }) => (
      process,
      cb
    ) => {
      const item = step.fields.find((f) => f.key === "save_button");
      buttonClicked(
        step,
        {
          ...item,
          is_submit_all_feedback: false,
        },
        process,
        template,
        user,
        null,
        () => {
          if (isFunction(cb)) cb();
        },
        true
      );
    },
  }),
  withHandlers({
    onButtonClick: ({
      process,
      valueChanged,
      template,
      user,
      step,
      onAutoSubmit,
    }) => (channel, field, action = "approved", cb) => {
      const marketingChannelsField = step.fields.find(
        (f) => f.field === "marketing_channels"
      );
      let channels = get(process, "data.fields.marketing_channels.value", []);
      channels = channels.map((item) => {
        let { fields } = item;
        if (channel.id === item.id) {
          // map comments, review media status to fields key
          fields = fields.map((itemField) => {
            if (
              itemField.label === field.label &&
              itemField.key === field.key
            ) {
              let newValueHistory = [];
              let value_arr = [];
              const valueHistory = get(itemField, "value_history", []);
              value_arr = itemField.value_arr.map((valueItem) => {
                let status = get(valueItem, "review_media_status", null);
                if (valueItem.path === get(field, "value.path")) {
                  status = action;
                }

                const valueHistoryIndex = findIndex(
                  get(itemField, "value_history", []),
                  (valueHistoryItem) => valueHistoryItem.path === valueItem.path
                );
                if (valueHistoryIndex === -1) {
                  newValueHistory.push({
                    ...valueItem,
                    review_media_status: status,
                    time: new Date(),
                  });
                } else {
                  newValueHistory.push({
                    ...valueHistory[valueHistoryIndex],
                    comments: valueItem.comments || [],
                    review_media_status: status,
                    time: new Date(),
                  });
                }
                return {
                  ...valueItem,
                  review_media_status: status,
                };
              });
              return {
                ...itemField,
                value_arr,
                value_history: newValueHistory,
              };
            }
            return itemField;
          });
          // map review media status to field value
          const fieldName = labelToNameField(field.label);
          let fieldValue = channel[fieldName];
          if (fieldValue) {
            fieldValue = fieldValue.map((fieldValueItem) => {
              // assign new comment to current asset
              let status = get(fieldValueItem, "review_media_status", null);
              if (fieldValueItem.path === get(field, "value.path")) {
                status = action;
              }
              return { ...fieldValueItem, review_media_status: status };
            });
          }
          return {
            ...item,
            fields,
            [fieldName]: fieldValue,
          };
        }
        return item;
      });

      valueChanged(marketingChannelsField, process, template, user, {
        value: channels,
      });
      // Approve/Reject will auto save
      process.data.fields.marketing_channels = {
        value: channels,
        is_dirty: true,
      };
      onAutoSubmit(process, cb);
    },
  })
)(View);
