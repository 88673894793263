import React, { useState } from "react";
import { Row, Col, Label } from "reactstrap";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { get, map, orderBy, filter } from "lodash";
import { ShadowBox, SectionTitle, SectionFields } from "components/Elements";
import {
  DownIcon,
  UpIcon,
  ApproveIcon,
  TrashIcon,
} from "components/CustomIcons";
import bn from "utils/bemnames";
import FileView from "components/FileView";
import PreviewMediaModal from "components/FormBuilder/PreviewMediaModal";
import moment from "moment";
const bem = bn.create("review-history");
const ReviewHistory = (props) => {
  const { process, isView, dateFormatByServer } = props;
  const [currentAsset, setCurrentAsset] = useState({});
  const [isOpenPreviewAssetModal, setIsOpenPreviewAssetModal] = useState(false);
  const [isOpenDetails, setIsOpenDetails] = useState(false);
  const mediaReviewHistory = get(
    process,
    "data.fields.media_review_history.value",
    []
  );
  if (mediaReviewHistory.length === 0) return null;
  // check old data
  const isNewVersion = !!get(mediaReviewHistory, "0.0.key");
  if (!isNewVersion) return null;
  return (
    <ShadowBox className={bem.b()}>
      <SectionTitle
        className={bem.e("review-history-title")}
        onClick={() => setIsOpenDetails(!isOpenDetails)}
      >
        <div className={bem.e("box-head-title")}>
          <FormattedMessage id="process > title revision history" />
        </div>
        <span className={bem.e("box-action")}>
          {isOpenDetails ? <UpIcon /> : <DownIcon />}
        </span>
      </SectionTitle>
      {isOpenDetails && (
        <React.Fragment>
          <SectionFields
            className={classnames(bem.e("section-fields"), {
              [bem.e("review-history-fields-view")]: isView,
              [bem.e("review-history-fields")]: !isView,
            })}
          >
            {map(mediaReviewHistory, (version, index) => {
              return (
                <div key={index} className={bem.e("review-version")}>
                  <div className={bem.e("version-title")}>
                    <FormattedMessage id="process > title revision" />{" "}
                    {index + 1}
                  </div>
                  {filter(
                    version,
                    (field) => get(field, "value_arr.length", 0) > 0
                  ).map((field, index2) => {
                    const valueHistory = orderBy(
                      get(field, "value_arr", []),
                      ["time"],
                      ["desc"]
                    );
                    return (
                      <div
                        className={bem.e("review-history-field")}
                        key={`${index}-${index2}`}
                      >
                        <Label className={bem.e("field-name")}>
                          {field.label}
                        </Label>
                        <div className={bem.e("fields-status")}>
                          {map(valueHistory, (file, index) => (
                            <div
                              key={index}
                              className={bem.e("fields-status-item")}
                            >
                              <Row>
                                <Col xs={7} className={bem.e("reset-col")}>
                                  <div
                                    className={bem.e("fields-status-content")}
                                  >
                                    {get(file, "review_media_status") ===
                                      "approved" && (
                                      <span className={bem.e("status-icon")}>
                                        <ApproveIcon color="#73A703" />
                                      </span>
                                    )}
                                    {get(file, "review_media_status") ===
                                      "rejected" && (
                                      <span className={bem.e("status-icon")}>
                                        <TrashIcon color="#E40061" />
                                      </span>
                                    )}
                                    <FileView
                                      file={file}
                                      isOverrideViewAction={true}
                                      onClickViewAction={() => {
                                        setIsOpenPreviewAssetModal(true);
                                        setCurrentAsset({
                                          media: field,
                                          asset: file,
                                        });
                                      }}
                                      actions={["view", "download"]}
                                    />
                                  </div>
                                </Col>
                                <Col xs={5} className={bem.e("reset-col")}>
                                  <div className={bem.e("timer")}>
                                    {file.user.name ? (
                                      <div>
                                        <FormattedMessage id="process > by person" />{" "}
                                        {file.user.name}
                                      </div>
                                    ) : null}
                                    <div>
                                      {moment(file.time * 1000).format(
                                        `${dateFormatByServer} [at] h:mm A z`
                                      )}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          ))}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </SectionFields>
        </React.Fragment>
      )}
      <PreviewMediaModal
        {...currentAsset}
        isOpen={isOpenPreviewAssetModal}
        onToggle={() => setIsOpenPreviewAssetModal(!isOpenPreviewAssetModal)}
      />
    </ShadowBox>
  );
};

export default ReviewHistory;
