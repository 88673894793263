import * as React from "react";
import bn from "utils/bemnames";
import Spinner from "components/Spinner";
import { get } from "lodash";
import * as Yup from "yup";
import { Formik } from "formik";
import ToastManager from "components/ToastManager";
import { Form, Button, Row, Col, FormGroup, Label } from "reactstrap";
import { FormattedMessage } from "react-intl";
import CopyStations from "../CopyStations";
import classNames from "classnames";
import Checkbox from "components/Checkbox";
import DropdownLength from "components/DropdownLength";
import { orderLengthOptions } from "utils/config";
import TextInputFormatted from "components/TextInputFormatted";

const bem = bn.create("production-settings-form");

const SettingsForm = (props) => {
  const {
    loading,
    onFormSubmit,
    intl,
    currentServer,
    productionSettings,
  } = props;
  const formikRef = React.useRef(null);

  const initialValues = {
    station_id: props.currentStation.key,
    settings_copy_to_stations: get(
      productionSettings,
      "settings_copy_to_stations",
      []
    ),
    script_approval: Boolean(get(productionSettings, "script_approval", false)),
    writer_sends_script_to_client: Boolean(
      get(productionSettings, "writer_sends_script_to_client", false)
    ),
    recording_approval: Boolean(
      get(productionSettings, "recording_approval", false)
    ),
    producer_sends_spot_to_client: Boolean(
      get(productionSettings, "producer_sends_spot_to_client", false)
    ),
    custom_spot_length: Boolean(
      get(productionSettings, "custom_spot_length", false)
    ),
    default_spot_length: get(productionSettings, "default_spot_length", false),
    default_words_per_min: get(
      productionSettings,
      "default_words_per_min",
      "180"
    ),
  };
  if (loading) return null;
  const form = (
    <div className={bem.b()}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        ref={formikRef}
        validationSchema={Yup.object().shape({
          script_approval: Yup.bool(),
          recording_requires_approval: Yup.bool(),
          default_words_per_min: Yup.string().nullable(),
          default_spot_length: Yup.string()
            .required(
              intl.formatMessage({ id: "validate > spot length is required" })
            )
            .nullable(),
          custom_spot_length: Yup.bool(),
          writer_sends_script_to_client: Yup.bool(),
          producer_sends_spot_to_client: Yup.bool(),
          settings_copy_to_stations: Yup.array().nullable(),
        })}
        onSubmit={onFormSubmit}
      >
        {(formProps) => {
          const { values, touched, errors } = formProps;
          return (
            <React.Fragment>
              <Form onSubmit={formProps.handleSubmit}>
                <div
                  className={classNames(
                    bem.e("content-wrapper"),
                    bem.e("content-order-settings-wrapper")
                  )}
                >
                  <div className={bem.e("section-title")}>
                    <FormattedMessage id="production settings > default settings" />
                  </div>
                  <div className={bem.e("form-padding")}>
                    <Row>
                      <Col className={bem.e("col-reset")} xs={6}>
                        <Label>
                          <FormattedMessage id="production settings > script approvals" />
                        </Label>
                        <FormGroup className={bem.e("group-checkboxes")}>
                          <Checkbox
                            checked={values.script_approval}
                            onChange={() => {
                              const value = !values.script_approval;
                              formProps.setFieldValue("script_approval", value);
                              if (!value) {
                                formProps.setFieldValue(
                                  "writer_sends_script_to_client",
                                  false
                                );
                              }
                              props.setShouldBlockNavigation(true);
                            }}
                            text={intl.formatMessage({
                              id:
                                "production settings > script review required",
                            })}
                          />
                          <Checkbox
                            checked={values.writer_sends_script_to_client}
                            onChange={() => {
                              formProps.setFieldValue(
                                "writer_sends_script_to_client",
                                !values.writer_sends_script_to_client
                              );
                              props.setShouldBlockNavigation(true);
                            }}
                            disabled={!values.script_approval}
                            text={intl.formatMessage({
                              id:
                                "production settings > writer sends script to Client",
                            })}
                          />
                        </FormGroup>
                      </Col>
                      <Col className={bem.e("col-reset")} xs={6}>
                        <Label>
                          <FormattedMessage id="production settings > spot approvals" />
                        </Label>
                        <FormGroup className={bem.e("group-checkboxes")}>
                          <Checkbox
                            checked={values.recording_approval}
                            onChange={() => {
                              const value = !values.recording_approval;
                              formProps.setFieldValue(
                                "recording_approval",
                                value
                              );
                              if (!value) {
                                formProps.setFieldValue(
                                  "producer_sends_spot_to_client",
                                  false
                                );
                              }
                              props.setShouldBlockNavigation(true);
                            }}
                            text={intl.formatMessage({
                              id: "production settings > spot review required",
                            })}
                          />
                          <Checkbox
                            checked={values.producer_sends_spot_to_client}
                            onChange={() => {
                              formProps.setFieldValue(
                                "producer_sends_spot_to_client",
                                !values.producer_sends_spot_to_client
                              );
                              props.setShouldBlockNavigation(true);
                            }}
                            disabled={!values.recording_approval}
                            text={intl.formatMessage({
                              id:
                                "production settings > producer sends spot to Client",
                            })}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className={bem.e("col-reset")} xs={6}>
                        <FormGroup>
                          <DropdownLength
                            // isClearable={true}
                            name="default_spot_length"
                            options={orderLengthOptions}
                            label={
                              <FormattedMessage id="production settings > default spot length" />
                            }
                            placeholder={intl.formatMessage({
                              id: "process > placeholder select",
                            })}
                            value={
                              values.default_spot_length
                                ? {
                                    value: values.default_spot_length,
                                    label: values.default_spot_length,
                                  }
                                : ""
                            }
                            isCustom={values.custom_spot_length}
                            onChange={(value, isCustom) => {
                              formProps.setFieldValue(
                                "default_spot_length",
                                value
                              );
                              formProps.setFieldValue(
                                "custom_spot_length",
                                isCustom
                              );
                              props.setShouldBlockNavigation(true);
                            }}
                            error={
                              touched.default_spot_length &&
                              errors.default_spot_length
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col className={bem.e("col-reset")} xs={6}>
                        <FormGroup>
                          <Label>
                            <FormattedMessage id="production settings > default words per minute" />
                          </Label>
                          <TextInputFormatted
                            placeholder={intl.formatMessage({
                              id: "production settings > enter number",
                            })}
                            className={bem.e("input-number")}
                            value={values.default_words_per_min}
                            onChange={(value) => {
                              formProps.setFieldValue(
                                "default_words_per_min",
                                value
                              );
                              props.setShouldBlockNavigation(true);
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <CopyStations
                    formProps={formProps}
                    intl={intl}
                    stations={props.stations}
                    currentServer={currentServer}
                    currentStation={props.currentStation}
                    setShouldBlockNavigation={props.setShouldBlockNavigation}
                  />
                </div>

                <div className="bottom-actions-sticky">
                  <div className={"buttons"}>
                    <Button
                      color="blue"
                      type="submit"
                      className="btn btn-radius"
                      disabled={loading}
                      id="btn-submit-settings"
                      onClick={() => {
                        formProps.validateForm(values).then((onfulfilled) => {
                          if (onfulfilled && onfulfilled.isCancelled) return;
                          if (onfulfilled && Object.keys(onfulfilled).length) {
                            ToastManager.show({
                              title: intl.formatMessage({
                                id: "toast > title not saved",
                              }),
                              message: intl.formatMessage({
                                id:
                                  "toast > message error please correct the hilighted fields",
                              }),
                              level: "error",
                            });
                          }
                        });
                      }}
                    >
                      <FormattedMessage id={`station settings > button save`} />
                    </Button>
                    <Button
                      color="blue"
                      outline
                      className="btn btn-radius"
                      disabled={loading}
                      onClick={() => {
                        formProps.resetForm(initialValues);
                      }}
                    >
                      <FormattedMessage
                        id={`station settings > button cancel`}
                      />
                    </Button>
                  </div>
                </div>
              </Form>
              <Spinner isLoading={loading} />
            </React.Fragment>
          );
        }}
      </Formik>
    </div>
  );
  return form;
};
export default SettingsForm;
