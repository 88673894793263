import * as Types from "../types/users";
import { ADD_STATION_SUCCESS } from "../types/stations";
import { LOGOUT_USER } from "../types/auth";
import { get, pick, uniqBy, find } from "lodash";
import * as StationsTypes from "../types/stations";

const initialState = {
  users: {
    // This object contains all users list
    // this is mainly required in users listing page
    // where we show all users list and loading icon if users are loading
    data: [],
    error: null,
    loading: false,
  },
  admins: {
    // This object contains all admins list
    // this is mainly required in admins listing page
    // where we show all admins list and loading icon if admins are loading
    data: [],
    error: null,
    loading: false,
  },
  user: {
    // This object contains particular user object with id mapping
    // this is mainly required in user details page
    // where we show particular user detail and loading icon if user details is loading
    // below is example object
    // "<id>": {
    //   data: null,
    //   error: null,
    //   loading: null
    // }
  },
  addUser: {
    // This object will be useful to show loading icon and status when user want to create new station
    data: null,
    error: null,
    loading: false,
  },
  updateUser: {
    // This object will be useful to show loading icon and status when user want to update station
    data: null,
    error: null,
    loading: false,
  },
  updateUsers: {
    data: null,
    error: null,
    loading: false,
  },
  deleteUser: {
    // This object will be useful to show loading icon and status when user want to delete station
    data: null,
    error: null,
    loading: false,
  },
  inviteUsers: {
    error: null,
    loading: false,
  },
  inviteUser: {
    error: null,
    loading: false,
  },
  bulkUpload: {
    error: null,
    loading: false,
  },
  activeUsers: {
    error: null,
    loading: false,
  },
  inviteAdmins: {
    error: null,
    loading: false,
  },
  activeAdmins: {
    error: null,
    loading: false,
  },
  isNewUser: false,
  unlockUser: {
    data: null,
    error: null,
    loading: false,
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    // admins actions
    case Types.FETCH_ADMINS_REQUEST: {
      return {
        ...state,
        admins: {
          data: get(state, "admins.data"),
          error: null,
          loading: true,
          is_dirty: false,
        },
      };
    }
    case Types.FETCH_ADMINS_SUCCESS: {
      return {
        ...state,
        admins: {
          data: action.payload,
          error: null,
          loading: false,
          is_dirty: false,
        },
      };
    }
    case Types.FETCH_ADMINS_ERROR: {
      return {
        ...state,
        admins: {
          data: null,
          error: action.payload,
          loading: false,
          is_dirty: false,
        },
      };
    }
    // users Actions

    case Types.FETCH_USERS_REQUEST: {
      return {
        ...state,
        users: {
          data: get(state, "users.data"),
          error: null,
          loading: true,
          is_dirty: false,
        },
      };
    }
    case Types.FETCH_USERS_SUCCESS: {
      return {
        ...state,
        users: {
          data: action.payload,
          error: null,
          loading: false,
          is_dirty: false,
        },
      };
    }
    case Types.FETCH_USERS_ERROR: {
      return {
        ...state,
        users: {
          data: null,
          error: action.payload,
          loading: false,
          is_dirty: false,
        },
      };
    }

    case Types.FETCH_MORE_USERS_REQUEST: {
      return {
        ...state,
        users: {
          ...state.users,
          lazyLoad: true,
        },
      };
    }
    case Types.FETCH_MORE_USERS_SUCCESS: {
      const { data: newData, ...rest } = action.payload;

      return {
        ...state,

        users: {
          ...state.users,
          data: {
            data: uniqBy(
              [...get(state, "users.data.data", []), ...newData],
              "id"
            ),
            ...rest,
          },
          lazyLoad: false,
        },
      };
    }
    case Types.FETCH_MORE_USERS_ERROR: {
      return {
        ...state,
        users: {
          ...state.users,
          lazyLoad: false,
        },
      };
    }

    case Types.FETCH_MORE_ADMINS_REQUEST: {
      return {
        ...state,
        admins: {
          ...state.admins,
          lazyLoad: true,
        },
      };
    }
    case Types.FETCH_MORE_ADMINS_SUCCESS: {
      const { data: newData, ...rest } = action.payload;

      return {
        ...state,

        admins: {
          ...state.admins,
          data: {
            data: uniqBy(
              [...get(state, "admins.data.data", []), ...newData],
              "id"
            ),
            ...rest,
          },
          lazyLoad: false,
        },
      };
    }
    case Types.FETCH_MORE_ADMINS_ERROR: {
      return {
        ...state,
        users: {
          ...state.users,
          lazyLoad: false,
        },
      };
    }

    // Particular User Actions
    case Types.FETCH_USER_REQUEST: {
      const existingUsers = get(state, "user");
      const { id } = action.payload;
      return {
        ...state,
        user: {
          ...existingUsers,
          [id]: {
            ...(existingUsers[id] || {}),
            loading: true,
            error: null,
          },
        },
      };
    }
    case Types.FETCH_USER_SUCCESS: {
      const existingUsers = get(state, "user");
      const { id, data } = action.payload;
      return {
        ...state,
        user: {
          ...existingUsers,
          [id]: {
            loading: false,
            error: null,
            data,
          },
        },
      };
    }
    case Types.FETCH_USER_ERROR: {
      const existingUsers = get(state, "user");
      const { id, error } = action.payload;
      return {
        ...state,
        user: {
          ...existingUsers,
          [id]: {
            loading: false,
            error,
          },
        },
      };
    }
    // set user
    case Types.SET_NEW_USER_REQUEST: {
      return state;
    }
    case Types.SET_NEW_USER_SUCCESS: {
      let newUserId = action.payload.id;
      let isForce = action.payload.isForce;
      let newUserData = {
        ...get(state, `user.${newUserId}.data`, {}),
        ...action.payload.data,
      };
      return {
        ...state,
        user: {
          ...get(state, "user"),
          [newUserId]: {
            loading: false,
            error: null,
            data: isForce ? action.payload.data : newUserData,
          },
        },
      };
    }
    case Types.SET_NEW_USER_ERROR: {
      return state;
    }
    // Fetch security policy
    case Types.FETCH_SECURITY_POLICY_USER_REQUEST: {
      const existingUsers = get(state, "user");
      const { id } = action.payload;
      return {
        ...state,
        user: {
          ...existingUsers,
          [id]: {
            ...(existingUsers[id] || {}),
            loading: true,
            error: null,
          },
        },
      };
    }
    case Types.FETCH_SECURITY_POLICY_USER_SUCCESS: {
      const existingUsers = get(state, "user");
      const { id, data } = action.payload;
      return {
        ...state,
        user: {
          ...existingUsers,
          [id]: {
            loading: false,
            error: null,
            data: {
              ...get(existingUsers, `${id}.data`, {}),
              ...data,
            },
          },
        },
      };
    }
    case Types.FETCH_SECURITY_POLICY_USER_ERROR: {
      const existingUsers = get(state, "user");
      const { id, error } = action.payload;
      return {
        ...state,
        user: {
          ...existingUsers,
          [id]: {
            ...(existingUsers[id] || {}),
            loading: false,
            error,
          },
        },
      };
    }
    // Update security policy user
    case Types.UPDATE_SECURITY_POLICY_USER_REQUEST: {
      return {
        ...state,
        updateUser: {
          loading: true,
          error: null,
        },
      };
    }
    case Types.UPDATE_SECURITY_POLICY_USER_SUCCESS: {
      const existingUsers = get(state, "user");
      const { id, data } = action.payload;
      return {
        ...state,
        user: {
          ...existingUsers,
          [id]: {
            loading: false,
            error: null,
            data: {
              ...get(existingUsers, `${id}.data`, {}),
              ...pick(data, [
                "domain_filter",
                "password_complexity_pattern",
                "replace_password_days",
                "reuse_password_days",
                "system_idle_timeout",
              ]),
            },
          },
        },
        updateUser: {
          loading: false,
          error: null,
        },
      };
    }
    case Types.UPDATE_SECURITY_POLICY_USER_ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        updateUser: {
          loading: false,
          error,
        },
      };
    }
    // Update security policy users
    case Types.UPDATE_SECURITY_POLICY_USERS_REQUEST: {
      return {
        ...state,
        updateUsers: {
          loading: true,
          error: null,
        },
      };
    }
    case Types.UPDATE_SECURITY_POLICY_USERS_SUCCESS: {
      return {
        ...state,
        updateUsers: {
          loading: false,
          error: null,
        },
      };
    }
    case Types.UPDATE_SECURITY_POLICY_USERS_ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        updateUsers: {
          loading: false,
          error,
        },
      };
    }
    // Fetch admin rights
    case Types.FETCH_ADMIN_RIGHTS_USER_REQUEST: {
      const existingUsers = get(state, "user");
      const { id } = action.payload;
      return {
        ...state,
        user: {
          ...existingUsers,
          [id]: {
            ...(existingUsers[id] || {}),
            loading: true,
            error: null,
          },
        },
      };
    }
    case Types.FETCH_ADMIN_RIGHTS_USER_SUCCESS: {
      const existingUsers = get(state, "user");
      const { id } = action.payload;
      return {
        ...state,
        user: {
          ...existingUsers,
          [id]: {
            loading: false,
            error: null,
            data: {
              ...get(existingUsers, `${id}.data`, {}),
              admin_stations: get(action, "payload.data.stations"),
              is_god_admin: get(action, "payload.data.is_god_admin"),
              broadcasting_group_admin: get(
                action,
                "payload.data.broadcasting_group_admin"
              ),
            },
          },
        },
      };
    }
    case Types.FETCH_ADMIN_RIGHTS_USER_ERROR: {
      const existingUsers = get(state, "user");
      const { id, error } = action.payload;
      return {
        ...state,
        user: {
          ...existingUsers,
          [id]: {
            ...(existingUsers[id] || {}),
            loading: false,
            error,
          },
        },
      };
    }
    // Update admin rights
    case Types.UPDATE_ADMIN_RIGHTS_USER_REQUEST: {
      return {
        ...state,
        updateUser: {
          loading: true,
          error: null,
        },
      };
    }
    case Types.UPDATE_ADMIN_RIGHTS_USER_SUCCESS: {
      const existingUsers = get(state, "user", {});
      const { id } = action.payload;
      return {
        ...state,
        user: {
          ...existingUsers,
          [id]: {
            loading: false,
            error: null,
            data: {
              ...get(existingUsers, `${id}.data`, {}),
              ...get(action, "payload.data", {}),
            },
          },
        },
        users: {
          ...state.users,
          is_dirty: true,
        },
        admins: {
          ...state.admins,
          is_dirty: true,
        },
        updateUser: {
          loading: false,
          error: null,
        },
      };
    }
    case Types.UPDATE_ADMIN_RIGHTS_USER_ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        updateUser: {
          loading: false,
          error,
        },
      };
    }
    // Update admin rights multiple users
    // case Types.UPDATE_ADMIN_RIGHTS_USERS_REQUEST: {
    //   return {
    //     ...state,
    //     updateUsers: {
    //       loading: true,
    //       error: null
    //     }
    //   };
    // }
    // case Types.UPDATE_ADMIN_RIGHTS_USERS_SUCCESS: {
    //   return {
    //     ...state,
    //     updateUsers: {
    //       loading: false,
    //       error: null
    //     }
    //   };
    // }
    // case Types.UPDATE_ADMIN_RIGHTS_USERS_ERROR: {
    //   const { error } = action.payload;
    //   return {
    //     ...state,
    //     updateUsers: {
    //       loading: false,
    //       error
    //     }
    //   };
    // }

    // Update privileges multiple users
    case Types.UPDATE_PRIVILEGES_USERS_REQUEST: {
      return {
        ...state,
        updateUsers: {
          loading: true,
          error: null,
        },
      };
    }
    case Types.UPDATE_PRIVILEGES_USERS_SUCCESS: {
      return {
        ...state,
        updateUsers: {
          loading: false,
          error: null,
        },
      };
    }
    case Types.UPDATE_PRIVILEGES_USERS_ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        updateUsers: {
          loading: false,
          error,
        },
      };
    }

    // Add User Actions
    case Types.ADD_USER_REQUEST: {
      return {
        ...state,
        addUser: {
          loading: true,
          error: null,
        },
      };
    }
    case Types.ADD_USER_SUCCESS: {
      return {
        ...state,
        users: {
          ...state.users,
          is_dirty: true,
        },
        admins: {
          ...state.admins,
          is_dirty: true,
        },
        addUser: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    }
    case Types.ADD_USER_ERROR: {
      return {
        ...state,
        addUser: {
          loading: false,
          error: action.payload,
        },
      };
    }

    // Update User Actions
    case Types.UPDATE_USER_REQUEST: {
      return {
        ...state,
        updateUser: {
          loading: true,
          error: null,
        },
      };
    }
    case Types.UPDATE_USER_SUCCESS: {
      return {
        ...state,
        users: {
          ...get(state, "users"),
          is_dirty: true,
        },
        admins: {
          ...state.admins,
          is_dirty: true,
        },
        updateUser: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    }
    case Types.UPDATE_USER_ERROR: {
      return {
        ...state,
        updateUser: {
          loading: false,
          error: action.payload,
        },
      };
    }
    // Update assignment
    case Types.UPDATE_ASSIGNMENT_USER_REQUEST: {
      return {
        ...state,
        updateUser: {
          loading: true,
          error: null,
        },
      };
    }
    case Types.UPDATE_ASSIGNMENT_USER_SUCCESS: {
      return {
        ...state,
        users: {
          ...get(state, "users"),
          is_dirty: true,
        },
        admins: {
          ...state.admins,
          is_dirty: true,
        },
        updateUser: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    }
    case Types.UPDATE_ASSIGNMENT_USER_ERROR: {
      return {
        ...state,
        updateUser: {
          loading: false,
          error: action.payload,
        },
      };
    }

    case Types.SET_IS_NEW_USER: {
      return {
        ...state,
        isNewUser: action.payload,
      };
    }

    // DELETE User Actions
    case Types.DELETE_USER_REQUEST: {
      return {
        ...state,
        deleteUser: {
          loading: true,
          error: null,
        },
      };
    }
    case Types.DELETE_USER_SUCCESS: {
      return {
        ...state,
        users: {
          ...state.users,
          is_dirty: true,
        },
        admins: {
          ...state.admins,
          is_dirty: true,
        },
        deleteUser: {
          loading: false,
          error: null,
        },
      };
    }
    case ADD_STATION_SUCCESS: {
      return {
        ...state,
        users: {
          ...state.users,
          is_dirty: true,
        },
      };
    }
    case Types.DELETE_USER_ERROR: {
      return {
        ...state,
        deleteUser: {
          loading: false,
          error: action.payload,
        },
      };
    }
    // Active users
    case Types.ACTIVE_USERS_REQUEST: {
      return {
        ...state,
        activeUsers: {
          loading: true,
          error: null,
        },
      };
    }
    case Types.ACTIVE_USERS_SUCCESS: {
      return {
        ...state,
        // update effect users
        users: {
          ...get(state, "users"),
          data: {
            ...get(state, "users.data", {}),
            data: get(state, "users.data.data", []).map((user) => {
              const updatedUser = find(
                action.payload,
                (item) => item.id === user.id
              );
              if (updatedUser) return updatedUser;
              return user;
            }),
          },
        },
        activeUsers: {
          loading: false,
          error: null,
        },
      };
    }
    case Types.ACTIVE_USERS_ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        activeUsers: {
          loading: false,
          error,
        },
      };
    }
    // Invite users
    case Types.INVITATION_USERS_REQUEST: {
      return {
        ...state,
        inviteUsers: {
          loading: true,
          error: null,
        },
      };
    }
    case Types.INVITATION_USERS_SUCCESS: {
      return {
        ...state,
        // update effect users
        users: {
          ...get(state, "users"),
          data: {
            ...get(state, "users.data", {}),
            data: get(state, "users.data.data", []).map((user) => {
              const updatedUser = find(
                action.payload,
                (item) => item.id === user.id
              );
              if (updatedUser) return updatedUser;
              return user;
            }),
          },
        },
        inviteUsers: {
          loading: false,
          error: null,
        },
      };
    }
    case Types.INVITATION_USERS_ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        inviteUsers: {
          loading: false,
          error,
        },
      };
    }
    // Active admins
    case Types.ACTIVE_ADMINS_REQUEST: {
      return {
        ...state,
        activeAdmins: {
          loading: true,
          error: null,
        },
      };
    }
    case Types.ACTIVE_ADMINS_SUCCESS: {
      return {
        ...state,
        // update effect admins
        admins: {
          ...get(state, "admins"),
          data: {
            ...get(state, "admins.data", {}),
            data: get(state, "admins.data.data", []).map((user) => {
              const updatedUser = find(
                action.payload,
                (item) => item.id === user.id
              );
              if (updatedUser) return updatedUser;
              return user;
            }),
          },
        },
        activeAdmins: {
          loading: false,
          error: null,
        },
      };
    }
    case Types.ACTIVE_ADMINS_ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        activeAdmins: {
          loading: false,
          error,
        },
      };
    }
    // Invite admins
    case Types.INVITATION_ADMINS_REQUEST: {
      return {
        ...state,
        inviteAdmins: {
          loading: true,
          error: null,
        },
      };
    }
    case Types.INVITATION_ADMINS_SUCCESS: {
      return {
        ...state,
        // update effect admins
        admins: {
          ...get(state, "admins"),
          data: {
            ...get(state, "admins.data", {}),
            data: get(state, "admins.data.data", []).map((user) => {
              const updatedUser = find(
                action.payload,
                (item) => item.id === user.id
              );
              if (updatedUser) return updatedUser;
              return user;
            }),
          },
        },
        inviteAdmins: {
          loading: false,
          error: null,
        },
      };
    }
    case Types.INVITATION_ADMINS_ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        inviteAdmins: {
          loading: false,
          error,
        },
      };
    }
    // Invite user
    case Types.INVITATION_USER_REQUEST: {
      return {
        ...state,
        inviteUser: {
          loading: true,
          error: null,
        },
      };
    }
    case Types.INVITATION_USER_SUCCESS: {
      return {
        ...state,
        inviteUser: {
          loading: false,
          error: null,
        },
      };
    }
    case Types.INVITATION_USER_ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        inviteUser: {
          loading: false,
          error,
        },
      };
    }
    // Bulk upload
    case Types.BULK_UPLOAD_USERS_REQUEST: {
      return {
        ...state,
        bulkUpload: {
          loading: true,
          error: null,
        },
      };
    }
    case Types.BULK_UPLOAD_USERS_SUCCESS: {
      return {
        ...state,
        bulkUpload: {
          loading: false,
          error: null,
        },
      };
    }
    case Types.BULK_UPLOAD_USERS_ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        bulkUpload: {
          loading: false,
          error,
        },
      };
    }
    case Types.UNLOCK_USER_REQUEST: {
      return {
        ...state,
        unlockUser: {
          loading: true,
          error: null,
        },
      };
    }
    case Types.UNLOCK_USER_SUCCESS: {
      return {
        ...state,
        users: {
          ...state.users,
          is_dirty: true,
        },
        admins: {
          ...state.admins,
          is_dirty: true,
        },
        unlockUser: {
          loading: false,
          error: null,
        },
      };
    }
    case Types.UNLOCK_USER_ERROR: {
      return {
        ...state,
        unlockUser: {
          loading: false,
          error: action.payload,
        },
      };
    }
    case LOGOUT_USER:
      return initialState;
    case StationsTypes.UPDATE_STATION_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          is_dirty: true,
        },
        admins: {
          ...state.admins,
          is_dirty: true,
        },
      };
    default:
      return state;
  }
}
