import View from "./view";
import React, { useState } from "react";
import { injectIntl } from "react-intl";
function DropdownLocation(props) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [newLocationName, setNewLocationName] = useState("");
  return (
    <View
      {...props}
      {...Object.assign(
        {},
        {
          newLocationName,
          setNewLocationName,
          isMenuOpen,
          setIsMenuOpen,
        }
      )}
    />
  );
}

export default injectIntl(DropdownLocation);
