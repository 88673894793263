import AddProcessPage from "./view";
import get from "lodash/get";
import { connect } from "react-redux";
import { omit } from "lodash";
import { compose, lifecycle, withHandlers } from "recompose";
import { getProcess, initNewProcess } from "store/actions/processes";
import { dismissNotifications } from "store/actions/messages";
import { getTemplate } from "store/actions/template";
import { uuidv4, getRoleIdByName } from "utils/helpers";
import { DEFAULT_WORDS_PER_MIN } from "../TextareaEvaluator";
const preparePage = (props, force = false) => {
  let process_id = get(props, "process_id");
  let template_id = get(props, "template_id");

  const user = get(props, "auth.user", {});
  template_id = (template_id + ".").split(".")[0];
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const fromQueue = urlParams.get("source");
  // if only a template id was passed, on creation
  // if (
  //   !props.template_state.loading &&
  //   (!props.template || props.template.key !== template_id)
  // ) {
  //   props.getTemplate(template_id, props.auth.info);
  // }
  if (!process_id) {
    const isProductionNeeded =
      get(process, "data.fields.contract_status.value") === "Production Needed";
    const isPromotionContest = template_id === "contest";
    const isPromotionPE = template_id === "liner";

    let fields = {
      template_key: {
        is_dirty: true,
        value: template_id,
      },
      role_sales_person: {
        is_dirty: true,
        value: {
          id: props.auth.user.id,
          name: props.auth.user.name,
          role:
            template_id === "digital"
              ? getRoleIdByName(
                  "Digital Account Executive",
                  props.auth.info.roles
                )
              : getRoleIdByName("Sales Assistant", props.auth.info.roles),
        },
      },
    };
    if (isProductionNeeded) {
      fields = {
        ...fields,
        recording_requires_approval: {
          is_dirty: true,
          value: true,
        },
      };
    }
    if (isPromotionContest) {
      fields = {
        ...fields,
        scheduling_type: {
          is_dirty: true,
          value: get(props, "defaultSchedulingType", "simple"),
        }, // default schedule
        days_of_week: {
          is_dirty: true,
          value: [2, 3, 4, 5, 6],
        },
        jock_instructions: {
          is_dirty: true,
          value: get(props, `jockInstructionsText.${user.id}`, ""),
        },
        theme: {
          is_dirty: true,
          value: "standard_giveaway",
        },
        rule_number_of_days: {
          is_dirty: true,
          value: 30,
        },
      };
    }
    if (isPromotionPE || isPromotionContest) {
      fields = omit(fields, ["role_sales_person"]);
    }
    if (template_id === "production_provided") {
      fields = {
        ...fields,
        assign_team_status: {
          is_dirty: true,
          value: "completed",
        },
      };
    }
    const contractStatus = get(props, "history_state.contract_status")
      ? get(props, "history_state.contract_status")
      : template_id === "production_provided"
      ? "Production Complete"
      : template_id === "basic_production"
      ? "Production Needed"
      : "";
    if (contractStatus) {
      fields = {
        ...fields,
        order_sold: {
          is_dirty: true,
          value: true, //Sold type of order by default
        },
        contract_status: {
          is_dirty: true,
          value: contractStatus,
        },
        order_stations: {
          is_dirty: true,
          value: [],
        },
        spot_info: {
          is_dirty: true,
          value: [
            contractStatus === "Production Complete"
              ? {
                  title: "",
                  isci: "",
                  recording_path: "",
                  recording_path_b: "",
                  provided_audio: "",
                  recording_audit: "",
                  spot_type: "Standard",
                  material_group_name: "",
                  material_group_id: "",
                  cart_number: "",
                  uuid: uuidv4(),
                  default_words_per_min: DEFAULT_WORDS_PER_MIN,
                  default_words_per_min_b: DEFAULT_WORDS_PER_MIN,
                  length1: "00",
                  length2: "00",
                  order_length: "",
                  order_length_custom: false,
                  due_date: "",
                  role_producer: "",
                  role_dubber: "",
                  contract_status: "Production Complete",
                  template_key: "production_provided",
                }
              : {
                  title: "",
                  isci: "",
                  script_requires_approval: false,
                  recording_requires_approval: true,
                  aircheck_requires_approval: false,
                  writer_sends_script_to_client: false,
                  producer_sends_spot_to_client: false,
                  spot_type: "Standard",
                  material_group_name: "",
                  material_group_id: "",
                  script: "",
                  script_part_b: "",
                  draft_script: "",
                  draft_script_part_b: "",
                  script_type: "draft",
                  contract_status: get(props, "history_state.contract_status"),
                  length1: "00",
                  length2: "00",
                  order_length: "",
                  order_length_custom: false,
                  due_date: "",
                  is_upload_script_file: false,
                  files: [],
                  uuid: uuidv4(),
                  default_words_per_min: DEFAULT_WORDS_PER_MIN,
                  default_words_per_min_b: DEFAULT_WORDS_PER_MIN,
                  template_key: "basic_production",
                  role_producer: "",
                  role_dubber: "",
                },
          ],
        },
        spots: {
          is_dirty: true,
          value: [],
        },
        spot_info_fields_dirty: {
          value: [],
          is_dirty: true,
        },
      };
    }
    props.initNewProcess({
      data: {
        fields,
      },
      error: null,
      loading: null,
    });
  }
  const loaded_process_key =
    props.process.key ||
    (props.process.data.fields && props.process.data.fields.key
      ? props.process.data.fields.key.value
      : false);

  if (process_id) {
    if (process_id === loaded_process_key && !force) {
      props.dismissNotifications({ process_id });
    } else {
      let params = {
        template_id,
        process_id,
        viewing_order: true,
        spotSwitching: get(props, "history_state.spotSwitching", false),
      };
      if (fromQueue) {
        params = { ...params, source: fromQueue };
      }
      props.getProcess(params);
    }
  }
};

export default compose(
  connect(
    (state, props) => {
      const process_id = get(props, "process_id");
      const process = state.processes.process;
      const template_id = get(props, "template_id");
      const ui_hint = (template_id + ".").split(".")[1];
      const historyState = get(props, "location.state", {});

      return {
        match_process_id: process_id,
        ui_hint,
        process,
        template: state.templates.template.data,
        template_state: state.templates.template,
        auth: state.auth,
        history_state: historyState,
        isLoading:
          get(state, "templates.template.loading", false) ||
          get(state, "processes.process.loading", false) ||
          get(state, "processes.addRotation.loading", false) ||
          get(state, "processes.unarchiveOrder.loading", false) ||
          get(state, "processes.archiveOrder.loading", false) ||
          get(state, "processes.convertSpecToSold.loading", false),
        defaultSchedulingType: get(state, "auth.scheduling_type", "simple"),
        jockInstructionsText: get(
          state,
          "auth.promotions_contest_jock_introductions_text",
          {}
        ),
      };
    },
    {
      initNewProcess,
      getProcess,
      getTemplate,
      dismissNotifications,
    }
  ),
  withHandlers({
    onPreparePage: (props) => () => {
      preparePage(props, true);
    },
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (
        this.props.match_process_id !== prevProps.match_process_id || // case switch process id
        (this.props.template_id !== prevProps.template_id &&
          !this.props.match_process_id) || // case : create Production needed => click to create new Production provided
        get(this.props, "process.is_dirty", false)
      ) {
        preparePage(this.props, get(this.props, "process.is_dirty"));
      }
      // if only a template id was passed, on creation
      if (
        !this.props.template_state.loading &&
        this.props.template_id &&
        this.props.template_id !== prevProps.template_id
      ) {
        this.props.getTemplate(this.props.template_id, this.props.auth.info);
      }
    },
    componentDidMount() {
      preparePage(this.props);
      this.props.getTemplate(this.props.template_id, this.props.auth.info);
    },
  })
)(AddProcessPage);
