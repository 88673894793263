import {
  FETCH_WORKFLOWS_REQUEST,
  FETCH_WORKFLOWS_SUCCESS,
  FETCH_WORKFLOWS_ERROR,
  ADD_WORKFLOW_REQUEST,
  ADD_WORKFLOW_ERROR,
  ADD_WORKFLOW_SUCCESS,
  UPDATE_WORKFLOW_REQUEST,
  UPDATE_WORKFLOW_SUCCESS,
  UPDATE_WORKFLOW_ERROR,
  FORGET_WORKFLOWS,
  DELETE_WORKFLOW_SUCCESS,
  DELETE_WORKFLOW_REQUEST,
  DELETE_WORKFLOW_ERROR
} from "../types/workflows";
import { LOGOUT_USER } from "../types/auth";
const initialState = {
  workflows: {
    data: [],
    error: null,
    loading: false,
    is_dirty: true
  },
  workflow: {
    data: {},
    error: null,
    loading: null
  },
  addWorkflow: {
    error: null,
    loading: false
  },
  updateWorkflow: {
    error: null,
    loading: false
  },
  deleteWorkflow: {
    error: null,
    loading: false
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_WORKFLOWS_REQUEST: {
      return {
        ...state,
        workflows: {
          ...state.workflows,
          is_dirty: false,
          loading: true,
          error: null
        }
      };
    }
    case FETCH_WORKFLOWS_SUCCESS: {
      return {
        ...state,
        workflows: {
          data: action.payload,
          is_dirty: false,
          loading: false,
          error: null
        }
      };
    }
    case FETCH_WORKFLOWS_ERROR: {
      return {
        ...state,
        workflows: {
          ...state.workflows,
          is_dirty: false,
          loading: false,
          error: action.payload.error
        }
      };
    }
    // Update
    case UPDATE_WORKFLOW_REQUEST: {
      return {
        ...state,
        updateWorkflow: {
          loading: true,
          error: null
        }
      };
    }
    case UPDATE_WORKFLOW_SUCCESS: {
      return {
        ...state,
        updateWorkflow: {
          loading: false,
          error: null
        }
      };
    }
    case UPDATE_WORKFLOW_ERROR: {
      return {
        ...state,
        updateWorkflow: {
          loading: false,
          error: action.payload
        }
      };
    }
    // Add
    case ADD_WORKFLOW_REQUEST: {
      return {
        ...state,
        addWorkflow: {
          loading: true,
          error: null
        }
      };
    }
    case ADD_WORKFLOW_SUCCESS: {
      return {
        ...state,
        workflows: {
          is_dirty: true,
          loading: false,
          error: null
        },
        addWorkflow: {
          loading: false,
          error: null
        }
      };
    }
    case ADD_WORKFLOW_ERROR: {
      return {
        ...state,
        addWorkflow: {
          loading: false,
          error: action.payload
        }
      };
    }
    case FORGET_WORKFLOWS: {
      return {
        ...state,
        workflows: {
          ...state.workflows,
          is_dirty: true
        }
      };
    }
    // delete channel Actions
    case DELETE_WORKFLOW_REQUEST: {
      return {
        ...state,
        deleteWorkflow: {
          loading: true,
          error: null
        }
      };
    }
    case DELETE_WORKFLOW_SUCCESS: {
      return {
        ...state,
        workflows: {
          is_dirty: true,
          loading: false,
          error: null
        },
        deleteWorkflow: {
          loading: false,
          error: null
        }
      };
    }
    case DELETE_WORKFLOW_ERROR: {
      return {
        ...state,
        deleteWorkflow: {
          loading: false,
          error: action.payload
        }
      };
    }
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
}
