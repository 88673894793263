import React from "react";
import bn from "utils/bemnames";
import HeaderMobile from "components/HeaderMobile";
import { UserCard } from "components/Card";
import { get } from "lodash";
import { FormattedMessage } from "react-intl";
import { useMemo } from "react";
import { validatePrivileges } from "utils/helpers";
import { PRIVILEGES } from "utils/constants";
import history from "components/History";
import URL from "utils/urls";
import { Button } from "reactstrap";
import { AddIcon, LogoutIcon } from "components/CustomIcons";
import useWindowDimensions from "utils/useWindowDimensions";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";

const bem = bn.create("page-home-mobile");

const HomeMobile = (props) => {
  const { auth, isEnableProductionNeeded, isEnableProductionProvided } = props;
  const privileges = get(props, "auth.user.privileges", []) || [];
  const links = useMemo(() => {
    const items = [];
    if (isEnableProductionNeeded) {
      items.push({
        onClick: () => {
          history.push({
            pathname: URL.ADD_PROCESS({
              template_id: "basic_production",
            }),
            state: {
              contract_status: "Production Needed",
            },
            isReminder: true,
          });
        },
        title: <FormattedMessage id="menu > new production needed" />,
      });
    }
    if (isEnableProductionProvided) {
      items.push({
        onClick: () => {
          history.push({
            pathname: URL.ADD_PROCESS({
              template_id: "production_provided",
            }),
            state: {
              contract_status: "Production Complete",
            },
            isReminder: true,
          });
        },
        title: <FormattedMessage id="menu > new production provided" />,
      });
    }
    return items;
  }, [isEnableProductionNeeded, isEnableProductionProvided]);
  const isAllowCreateOrder = useMemo(() => {
    return validatePrivileges({
      requires: {
        or: [PRIVILEGES.ADD_PRODUCTION, PRIVILEGES.EDIT_ALL_PRODUCTION_ORDERS],
      },
      privileges,
      user: auth.user,
    });
  });
  const { width: widthScreen } = useWindowDimensions();
  const isMobile = useMemo(() => {
    return widthScreen < 480;
  }, [widthScreen]);

  if (!isMobile) {
    return <Redirect to={URL.HOME()} />;
  }
  return (
    <div className={bem.b()}>
      <HeaderMobile />
      <div className={bem.e("page-container")}>
        <UserCard
          className={bem.e("user-card")}
          title={get(auth, "user.name")}
          avatar={get(auth, "user.avatar_url")}
          subtitle={get(auth, "user.title")}
          avatarSize={64}
          isShowTitle={false}
          isShowSubtitle={false}
        />
        <div className={bem.e("content")}>
          <div className={bem.e("box-container")}>
            <Link className={bem.e("btn-desktop")} to={URL.HOME()}>
              <FormattedMessage id="home mobile > view desktop site" />
            </Link>
            {isAllowCreateOrder ? (
              <div className={bem.e("box-actions")}>
                <div className={bem.e("title")}>
                  <FormattedMessage id="home mobile > create a new order" />
                </div>
                {links.map((item, index) => (
                  <Button
                    color="primary"
                    className="btn-radius"
                    key={`button-mobile-${index}`}
                    onClick={item.onClick}
                  >
                    <AddIcon color="#fff" />
                    {item.title}
                  </Button>
                ))}
              </div>
            ) : (
              <div className={bem.e("box-message")}>
                <div className={bem.e("title")}>
                  <FormattedMessage id="home mobile > this mobile version is only for order entry" />
                </div>
                <div className={bem.e("title")}>
                  <FormattedMessage id="home mobile > contact your admin if you need order entry privileges" />
                </div>
              </div>
            )}
          </div>

          <Button
            outline
            color="primary"
            className="btn-radius btn-logout"
            onClick={() => {
              history.push({
                pathname: URL.LOGOUT(),
                isReminder: true,
              });
            }}
          >
            <LogoutIcon color="#795AFA" className="can-click" />
            <FormattedMessage id="home mobile > log out" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default HomeMobile;
